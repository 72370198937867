import {
  Paper,
  Divider,
  Tabs,
  Tab,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { AppButton } from "../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import DeploymentListTable from "../../components/common/Tables/DeploymentListTable";
import {
  deploymentColumnHidden,
  deploymentColumnOrder,
  deploymentColumns,
  deploymentDefaultColumnWidths,
  deploymentTableColumnExtensions,
} from "../../constants/deploymentConstants";
import {
  clusterColumns,
  clusterDefaultColumnWidths,
  clusterTableColumnExtensions,
  clusterColumnOrder,
  clusterDefaultSorting,
  clusterColumnHidden,
} from "../../constants/clusterConstants";
import CustomFilter from "../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../utils/common";
import { useRef } from "react";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import {
  fetchAllDeployment,
  addDeploymentFilters,
  addDeploymentSort,
  addDeploymentHidden,
  clearDeployment,
  addLastSelectClougConfiguration,
} from "../../redux/actions/deploymentProcessAction";
import {
  fetchAllCluster,
  addClusterFilters,
  addClusterSort,
  clearCluster,
  addClusterHidden,
  add_cloud_config,
} from "../../redux/actions/clusterActions";
import { featchAllCloudConfigurationActions } from "../../redux/actions/organizationAction";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import ClusterListTable from "../../components/common/Tables/ClusterListTable";
import { fetchCurrentORGDetails } from "../../utils/organization/organizationUtils";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import {
  featchAllClusterDetails,
  getAllClusterDeployments,
} from "../../utils/clusterutils/clusterUtils";
import useDebounce from "../../components/common/UseDebounce";
import SearchBar from "../../components/common/SearchBar";

const HostingList = (props) => {
  const {
    fetchAllDeployment,
    fetchAllCluster,
    addDeploymentFilters,
    addDeploymentSort,
    addDeploymentHidden,
    deploymentFilters,
    deploymentSort,
    deploymentHidden,
    deploymentList,
    deleteDeploymentStatus,
    loading,
    _totalcount,
    _totalcountcluster,
    hostActiveTab,
    alterActiveTab,
    clearDeployment,
    clusterFilters,
    clusterSort,
    clusterHidden,
    clusterList,
    cloudConfig,
    add_cloud_config,
    addLastSelectClougConfiguration,
    lastSelectedCloudConfig,
    featchAllCloudConfigurationActions,
    data,
    clusterLoading,
    addClusterHidden,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [currentPageDeployment, setCurrentPageDeployment] = useState(1);
  const [currentPageCluster, setCurrentPageCluster] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [deploymentRows, setDeploymentRows] = useState([]);
  const [clusterRows, setClusterRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);

  const [clusterTableLoading, setClusterTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: deploymentColumns,
    widths: deploymentDefaultColumnWidths,
    extentions: deploymentTableColumnExtensions,
    orders: deploymentColumnOrder,
    hides: [],
  });

  const [clusterTableData, setClusterTableData] = useState({
    columns: clusterColumns,
    widths: clusterDefaultColumnWidths,
    extentions: clusterTableColumnExtensions,
    orders: clusterColumnOrder,
    hides: [],
  });
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [cloudConfigList, setCloudConfigList] = useState();
  const [selectCloudConfig, setSelectCloudConfig] = useState(
    localStorage.getItem("CloudConfiguration")
  );
  const [cloudConfigRows, setCloudConfigRows] = useState([]);
  const stylesInput = manageScreenStyles();

  useEffect(() => {
    featchAllCloudConfigurationActions();
  }, []);

  useEffect(() => {
    setCloudConfigRows(
      data && data?.length > 0 ? data?.map((item) => item) : []
    );
  }, [data]);

  useEffect(() => {
    checkScreenPermission("Host", ["List All", "List Assigned"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Host");
      if (_status === 200) {
        let servData = getTableData(
          data,
          deploymentColumns,
          deploymentDefaultColumnWidths,
          deploymentTableColumnExtensions,
          deploymentColumnOrder,
          deploymentHidden
        );
        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID" &&
              col.name !== "appname"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        setTableData(servData);
        if (
          JSON.stringify(deploymentHidden) ===
          JSON.stringify(deploymentColumnHidden)
        ) {
          addDeploymentHidden(servData.hides);
        }
        console.log('check hid', servData.hides)
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchFields();
  }, []);

  const [cloudProvider, setCloudProvider] = useState();

  useEffect(() => {
    const fetchCloudConfigs = async () => {
      setIsLoader(true);
      const { data, _msg, _status } = await fetchCurrentORGDetails();
      setCloudProvider(data?.cloud_configuration?.cloud_provider);
      let configs = [];
      if (_status === 200) {
        data?.cloudconfigurations?.map((item) => {
          configs.push(item.configurationname);
        });
        setCloudConfigList(configs);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    };

    fetchCloudConfigs();
  }, []);

  const handleConfigChange = async (event) => {
    const value = event.target.value;
    localStorage.setItem("CloudConfiguration", value?._id);
    add_cloud_config({ cloudConfig: value?._id });
    // const {data,_status,_msg}= await getAllClusterDeployments(value?._id);
    // if(_status!== 200){
    //   enqueueSnackbar(`${_msg}`,{variant:"error"})
    // }
  };

  useEffect(() => {
    checkScreenPermission("Cluster", ["List All", "List Assigned"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Cluster");
      if (_status === 200) {
        let servData = getTableData(
          clusterList.data,
          clusterColumns,
          clusterDefaultColumnWidths,
          clusterTableColumnExtensions,
          clusterColumnOrder,
          clusterHidden
        );
        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        setClusterTableData(servData);
        // setGUItableData(servData);
        if (
          JSON.stringify(clusterHidden) === JSON.stringify(clusterColumnHidden)
        ) {
          addClusterHidden(servData.hides);
        }
        setClusterTableLoading(false);
      } else {
        setClusterTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function createData(deploymentData) {
    const {
      _id,
      name,
      status,
      createdon,
      isFavourite,
      createdby,
      isSubscribe,
    } = deploymentData;
    return {
      ...deploymentData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      status: status,
      api: 0,
      isFavourite: isFavourite,
      isSubscribe: isSubscribe,
      statuses: statuses,
    };
  }

  useEffect(async () => {
    const parsedFilters = arrayToFilter(deploymentFilters);
    const parsedSort = arrayToSort(deploymentSort);

    fetchAllDeployment(
      currentPageDeployment,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageDeployment, deploymentSort]);

  useEffect(async () => {
    if(deleteDeploymentStatus === true ){
      const parsedFilters = arrayToFilter(deploymentFilters);
      const parsedSort = arrayToSort(deploymentSort);

      fetchAllDeployment(
        currentPageDeployment,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteDeploymentStatus]);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(clusterFilters);
    const parsedSort = arrayToSort(clusterSort);
    const clusterDetails = cloudConfig ? cloudConfig : lastSelectedCloudConfig;

    if (
      localStorage.getItem("CloudConfiguration") !== null &&
      hostActiveTab === "Clusters"
    ) {
      fetchAllCluster(
        currentPageCluster,
        searchInput,
        localStorage.getItem("CloudConfiguration")
      );
    }

    setClusterRows(
      clusterList?.data && clusterList.data?.length > 0
        ? clusterList?.data?.map((item) => {
          item["configName"] = cloudConfig;
          const rowData = createData(item);
          setIsLoader(false);
          return rowData;
        })
        : []
    );
  }, [
    currentPageCluster,
    clusterSort,
    cloudConfig,
    searchInput,
    hostActiveTab,
    localStorage.getItem("CloudConfiguration"),
  ]);

  useEffect(() => {
    setClusterRows(
      clusterList.data && clusterList.data?.length > 0
        ? clusterList.data?.map((item) => {
          item["configName"] = cloudConfig;
          const rowData = createData(item);
          setIsLoader(false);
          return rowData;
        })
        : []
    );
  }, [clusterList]);

  const styles = listStyles();
  const mystyles = manageScreenStyles();

  const history = useHistory();
  useEffect(() => {
    setDeploymentRows(
      deploymentList.data && deploymentList.data?.length > 0
        ? deploymentList.data?.map((item) => {
          const rowData = createData(item);
          return rowData;
        })
        : []
    );
  }, [deploymentList.data]);

  useEffect(() => {
    setSelectCloudConfig(localStorage.getItem("CloudConfiguration"));
  }, [localStorage.getItem("CloudConfiguration")]);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (hostActiveTab === "Deployments") {
      if (searchInput) {
        fetchAllDeployment(
          0,
          JSON.stringify({
            deploymentname: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        fetchAllDeployment(1);
      }
    } else if (hostActiveTab === "Clusters") {
      if (searchInput) {
        fetchAllCluster(
          currentPageCluster,
          JSON.stringify({
            clustername: `${searchInput}`,
          })
        );
      } else {
        fetchAllCluster(1);
      }
    }
  };
  const handleSubmitFilter = () => {
    if (hostActiveTab === "Deployments") {
      const parsedFilters = arrayToFilter(deploymentFilters);
      const parsedSort = arrayToSort(deploymentSort);
      fetchAllDeployment(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (hostActiveTab === "Clusters") {
      const parsedFilters = arrayToFilter(clusterFilters);
      const parsedSort = arrayToSort(clusterSort);
      fetchAllCluster(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFilters = () => {
    fetchAllDeployment(1, JSON.stringify({}));
    fetchAllCluster(1, JSON.stringify({}));
  };

  const handleChangeTab = (event, newValue) => {
    alterActiveTab("hostActiveTab", newValue);
  };

  useEffect(() => {
    return () => {
      clearDeployment();
    };
  }, []);

  const activeTabBredCrumbsName = () => {
    if (hostActiveTab === "Deployments") {
      return "Deployment List";
    } else if (hostActiveTab === "Clusters") {
      return "Cluster List";
    }
  };

  useEffect(async () => {
    if (selectCloudConfig && hostActiveTab === "Clusters") {
      featchAllClusterDetails(selectCloudConfig);
    }
    setIsLoader(false);
  }, [
    cloudConfig,
    selectCloudConfig,
    lastSelectedCloudConfig,
    hostActiveTab,
    localStorage.getItem("CloudConfiguration"),
  ]);

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Host", path: "/host" },
            { title: activeTabBredCrumbsName() },
          ]}
        />

        <div className={styles.topContentItems}>
          <div className={styles.topContentItem}>
            <Tabs
              value={hostActiveTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              className={styles.documentsListAccessTab}
            >
              <Tab
                label={`Deployments ${_totalcount ? ` (${_totalcount})` : ""}`}
                value={"Deployments"}
                disabled={
                  !checkCompPermission("Microservice", [
                    "List All",
                    "List Assigned",
                  ])
                }
              />

              <Tab
                label={`Clusters ${_totalcountcluster ? ` (${_totalcountcluster})` : ""
                  }`}
                value={"Clusters"}
                disabled={!checkCompPermission("Microservice", ["List All"])}
              />
            </Tabs>
          </div>
          <div className={styles.topContentItem2}>
            <SearchBar
              handleSearch={handleSearch}
              PlaceHolderLabel={
                hostActiveTab === "Deployments"
                  ? "Search Name"
                  : "Search Cluster Name"
              }
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
            {hostActiveTab === "Deployments" && (
              <>
                <Divider orientation="vertical" className={styles.MuiDivider} />
                <CustomFilter
                  columns={tableData.columns}
                  filters={deploymentFilters}
                  setFilters={addDeploymentFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
                <Divider orientation="vertical" className={styles.MuiDivider} />
                <AppButton
                  buttonName="Host"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  disabled={
                    !checkCompPermission("ServiceGroup", ["Create Microservice"])
                  }
                  className="btnsmall"
                  onClick={() => {
                    history.push("/host/create-deployment");
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Table */}
      <Paper className={styles.pageContent}>
        {hostActiveTab === "Deployments" && (
          <>
            {tableLoading ? (
              <Loader />
            ) : (
              <DeploymentListTable
                columns={tableData.columns}
                rows={deploymentRows}
                defaultColumnWidths={tableData.widths}
                tableColumnExtensions={tableData.extentions}
                columnOrder={tableData.orders}
                loading={deploymentList?.loading}
                currentPage={currentPageDeployment}
                setCurrentPage={setCurrentPageDeployment}
                isCustomHeight={true}
                sort={deploymentSort}
                windowDimensions={windowDimensions}
                setSort={addDeploymentSort}
                hidden={deploymentHidden}
                setHidden={addDeploymentHidden}
                noDataMsg="No Deployments Found"

              />
            )}
          </>
        )}

        {hostActiveTab === "Clusters" && (
          <>
            {tableLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                spacing={12}
                style={{
                  marginTop: "0rem",
                  marginBottom: "4rem",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Grid
                  xs={3}
                  style={{
                    marginBottom: "30px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      width: "65%",
                      paddingTop: "15px",
                      font: "normal normal 600 14px/19px Nunito;",
                      fontWeight: "bold",
                      letterSpacing: 0,
                      color: "#000000",
                      width: "100%",
                      opacity: 1,
                    }}
                  >
                    Cloud Configuration{"    "}
                  </label>
                  <Select
                    value={cloudConfigRows.find(
                      (abc) =>
                        abc._id === localStorage.getItem("CloudConfiguration")
                    )}
                    defaultValue={cloudConfigRows.find(
                      (abc) =>
                        abc._id === localStorage.getItem("CloudConfiguration")
                    )}
                    onChange={handleConfigChange}
                    className={`${stylesInput.select2}`}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {cloudConfigRows?.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item?.configurationname}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                {/* <p style={{ paddingLeft: "15px" }}>
                  <b>Last Selected Cloud Configuration:</b>{" "}
                  {localStorage.getItem("CloudConfiguration")}
                </p> */}
                {/* {clusterLoading ? (
                  <Loader />
                ) : ( */}
                <Grid style={{ width: "100%", height: "20%", zIndex: "100" }}>
                  <ClusterListTable
                    columns={clusterTableData.columns}
                    rows={clusterRows}
                    cloudConfig={cloudConfig}
                    defaultColumnWidths={clusterDefaultColumnWidths}
                    tableColumnExtensions={clusterTableColumnExtensions}
                    columnOrder={clusterTableData.orders}
                    loading={clusterLoading}
                    currentPage={currentPageCluster}
                    setCurrentPage={setCurrentPageCluster}
                    isCustomHeight={true}
                    sort={clusterSort}
                    windowDimensions={windowDimensions}
                    setSort={addClusterSort}
                    hidden={clusterHidden}
                    setHidden={addClusterHidden}
                    noDataMsg="No Cluster Found"
                  />
                </Grid>
                {/* )} */}
              </Grid>
            )}
          </>
        )}
      </Paper>
      {clusterRows.length !== 0 && hostActiveTab === "Clusters" && (
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(_totalcountcluster / 10)}
              page={currentPageCluster}
              onChange={(e, val) => setCurrentPageCluster(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      {deploymentRows.length !== 0 && hostActiveTab === "Deployments" && (
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(_totalcount / 10)}
              page={currentPageDeployment}
              onChange={(e, val) => setCurrentPageDeployment(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllDeployment,
  addDeploymentFilters,
  addDeploymentSort,
  addDeploymentHidden,
  addClusterHidden,
  clearDeployment,
  alterActiveTab,
  fetchAllCluster,
  addClusterFilters,
  addClusterSort,
  clearCluster,
  add_cloud_config,
  addLastSelectClougConfiguration,
  featchAllCloudConfigurationActions,
};

const mapStateToProps = (state) => {
  const { deploymentFilters, deploymentSort, deploymentHidden } =
    state.deploymentProcessReducer;
  const { clusterFilters, clusterSort, clusterHidden } = state.clusterReducer;
  const { cloudConfig } = state.clusterReducer.cloudConfig;
  const { clusterList } = state.clusterReducer;
  const { loading } = clusterList;
  const { deploymentList } = state.deploymentProcessReducer;
  const { userInfo } = state.userDetails;
  const { hostActiveTab } = state.activeTabReducer;
  const { data } = state?.organizationReducer?.cloudConfigList;
  const { deleteDeploymentStatus } = deploymentList;

  return {
    deploymentFilters,
    deploymentSort,
    deploymentHidden,
    deploymentList,
    deleteDeploymentStatus,
    clusterList,
    _totalcount: state.deploymentProcessReducer.deploymentList?._totalcount,
    _totalcountcluster: state.clusterReducer.clusterList?._totalcount,
    userInfo,
    hostActiveTab,
    clusterFilters,
    clusterSort,
    clusterHidden,
    cloudConfig,
    data,
    clusterLoading: loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HostingList);
