import { ApiHelper } from "./helper/ApiHelper";
import { CONTAINER_MNGT } from "./helper/config";

const apiHelper = new ApiHelper();

export class ContainerServices {
  getContainersList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== "{}"
        ? `&filters=${filter}`
        : `&filters={"draft" : {"$ne": true}}`) +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");

    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getContainerForBuildStatus(versionId) {


    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers?filters={ "containername" : "${versionId}" }`
    return apiHelper.get(uri);
  }


  getContainerStatusByName(versionId) {

    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers?filters={ "containername" : "${versionId}" }`;
    return apiHelper.get(uri);
  }

  getContainersforCodeEditor() {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers`;
    return apiHelper.get(uri);
  }
  getContainerDetailsByID(conID) {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers/${conID}`;
    return apiHelper.get(uri);
  }
  createContainer(containerData) {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers`;
    return apiHelper.post(uri, containerData);
  }
  editContainerByID(conID, EditcontainerData) {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers/${conID}`;
    return apiHelper.put(uri, EditcontainerData);
  }
  DeleteContainer(conID) {
    const uri = `${CONTAINER_MNGT}/api/v1.0.0/dockercontainers/${conID}`;
    return apiHelper.delete(uri);
  }
  StartContainer(conID) {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers/${conID}/run`;
    return apiHelper.get(uri);
  }
  StopContainer(conID) {
    const uri = `${CONTAINER_MNGT}/api/container/dockercontainers/${conID}/stop`;
    return apiHelper.get(uri);
  }
}
