import React, { useState } from "react";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import StatusDropdown from "../../../atoms/rightSide/statusDropdown/StatusDropdown";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import useGetData from "../../../../hooks/useGetData";
import Dropdown from "../../../atoms/dropdown/Dropdown";
import DisabledButton from "../../../atoms/rightSide/appearance/disabled/DisabledButton";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";
import Styles from "../../../../styles/commonStyles/MenuRightBar";
import IconPopup from "./helper/IconPopup";

const appearanceOptions = [
  {
    name: "contained",
    value: "contained",
  },
  {
    name: "outlined",
    value: "outlined",
  },
];

function ButtonAppearances() {
  const dispatch = useDispatch();
  const classes = Styles();
  const { currentPage, currentComponent, NormalHandleChange } = useGetData();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: value ? value : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name={"fontSize"}
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <label>Button Type</label>
        <Dropdown
          width="100%"
          value={currentComponent?.btnType}
          onChange={(e) => handleChange("btnType", e.target.value)}
          arr={appearanceOptions}
          disabled={false}
          label=""
          name="btnType"
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <DisabledButton />
      </div>
      <div className="field">
        <TrueFalseButton
          label={"Icon"}
          leftButton="No"
          rightButton="Yes"
          name="icon"
          value={currentComponent?.icon}
        />
        <div style={{ marginTop: 20 }}>
          <div className="field">
            <TrueFalseButton
              leftButton={
                !currentComponent?.alignment ? (
                  <img src="/img/Left.-white.svg" alt="" />
                ) : (
                  <img src="/img/Left.svg" alt="" />
                )
              }
              rightButton={
                currentComponent?.alignment ? (
                  <img src="/img/Right.svg" alt="" />
                ) : (
                  <img src="/img/Right.-Black.svg" alt="" />
                )
              }
              name="alignment"
              value={currentComponent?.alignment}
            />
          </div>
        </div>

        {/*  */}
        {currentComponent?.icon && (
          <div className={classes.menu}>
            <div className="field">
              <div className="logoBox" onClick={handleClick}>
                <div className="title">
                  <img
                    src={
                      currentComponent?.logoUrl
                        ? currentComponent?.logoUrl
                        : currentComponent?.logo
                        ? currentComponent?.logo
                        : "/img/image.png"
                    }
                    alt=""
                    height={20}
                    width={40}
                  />
                  <label htmlFor="">
                    {currentComponent?.logoTitle
                      ? currentComponent?.logoTitle
                      : "Icon"}
                  </label>
                </div>
              </div>
              <IconPopup
                isOptionsOpen={open}
                optionsEl={anchorEl}
                setOptionsEl={setAnchorEl}
                currentPage={currentPage}
                currentComponent={currentComponent}
              />
            </div>
          </div>
        )}
        {/*  */}
      </div>
      <div className="field">
        <CustomColorPicker name="status" />
      </div>
    </div>
  );
}

export default ButtonAppearances;
