import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    documentDropperWrapper: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem",

        "& .documentDropperClass": {
            width: "16rem",
            minHeight: "100px",
            padding: "1rem 3rem",
            border: "2px solid rgba(0, 40, 85, 1)",
            borderRadius: 15,

            "& .MuiDropzoneArea-textContainer": {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "center",
                justifyContent: "space-around",

                "& .documentDropperTextClass": {
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 0.8)",
                },

                "& .MuiDropzoneArea-text": {
                    marginTop: 12,
                    marginBottom: 12
                }
            },
        },
    },
    previewChip: {
        marginTop: 10,
        "& svg": {
            color: theme.palette.primary.main,
        },
    }
}))

const CustomDropperIcon = () => (
    <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
)

const FileDropperWithChip = (props) => {
    const styles = useStyles();
    const [isLoader, setIsLoader] = useState(false);

    return (
        <div className={styles.documentDropperWrapper}>
            <Backdrop
                className={styles.backdrop}
                open={isLoader}
                // onClick={() => setIsLoader(false)}
                style={{ zIndex: 9999 }}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <DropzoneArea
                Icon={CustomDropperIcon}
                showPreviews={true}
                acceptedFiles={props.acceptedFiles}
                filesLimit={props.filesLimit}
                maxFileSize={9000000000}
                dropzoneText={`Drag and drop or click to browse here.`}
                dropzoneClass="documentDropperClass"
                dropzoneParagraphClass="documentDropperTextClass"
                showAlerts={['error']}
                showPreviewsInDropzone={false}
                clearOnUnmount={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewChipProps={{ classes: { root: styles.previewChip } }}
                previewText=""
                onDrop={() => setIsLoader(true)}
                onChange={(files) => {
                    setIsLoader(false)
                    props.handleDropFile([]);
                    props.handleDropFile(files);
                }}
            />
        </div>
    )
}

export default FileDropperWithChip
