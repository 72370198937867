import { useEffect, useState } from "react";
import componentStyles from "../../../styles/commonStyles/Components";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import {
  editComponentById,
  editPage,
  editUiApplication,
} from "../../../../redux/actions/uiApplicationAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AppButton } from "../button/AppButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Loader from "../loader/Loader";
import TableFilter from "./TableFilter";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { FilterList } from "@material-ui/icons";

const descendingComparator = (a, b, orderBy) =>
  b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0;

function SortableTableHead(props) {
  const { order, orderBy, onRequestSort, columns, currentComponent } = props;
  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        {columns.map((col, index) => (
          <TableCell
            style={{
              zIndex: index === 0 && 100,
              borderRight: index === 0 && "2px solid #fff",
            }}
            key={index}
            width={col?.width ? col?.width : 0}
            sortDirection={orderBy === col?.name ? order : false}
          >
            <TableSortLabel
              active={orderBy === col?.name}
              direction={orderBy === col?.name ? order : "asc"}
              onClick={createSortHandler(col?.name)}
              style={{
                fontSize: currentComponent?.fontSize
                  ? currentComponent?.fontSize
                  : 16,
              }}
            >
              {col?.title}
            </TableSortLabel>
          </TableCell>
        ))}
        {(currentComponent?.edit || currentComponent?.delete) && (
          <TableCell
            style={{
              textAlign: "center",
              fontSize: currentComponent?.fontSize
                ? currentComponent?.fontSize
                : 16,
            }}
          >
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomTable = ({ id }) => {
  const { currentPage, getComponent } = useGetData();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentComponent = getComponent(id);
  const styles = componentStyles(currentComponent);
  const [tableRows, setTableRows] = useState([]);
  const [invalidData, setInvalidData] = useState(true);
  const [page, setPage] = useState(1);
  const [filtersEl, setFiltersEl] = useState(null);
  const isFiltesrOpen = Boolean(filtersEl);
  const [filter, setFilter] = useState({
    name: "",
    value: "",
  });
  const currentProject = useSelector((state) => state.uiApplicationReducer);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const apiService = new UiApplicationService();
  const { handleTrigger } = useTriggers();
  const params = useParams();
  const viewType = params.view !== undefined ? params.view + "/" : "";
  const [allComponentNames, setAllComponentNames] = useState([]);

  useEffect(() => {
    updateCurrentProjectComponents();
    if (currentComponent["onInit"]) {
      handleTrigger({
        action: currentComponent?.["onInit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const itemsPerPage = currentComponent?.itemsPerPage?.dataValue
    ? parseInt(currentComponent?.itemsPerPage?.dataValue)
    : 10;

  const columns = currentComponent?.columns?.filter((column) => !column.isHide);

  useEffect(() => {
    if (Array.isArray(currentComponent?.data?.dataValue)) {
      setTableRows(currentComponent?.data?.dataValue);
      setInvalidData(false);
    } else {
      setTableRows([]);
      setInvalidData(true);
    }
  }, [currentComponent?.data?.dataValue]);

  const getComponentFromAllPages = (componentId) => {
    for (const page of allComponentNames) {
      const res = page?.components.find((comp) => comp?._id === componentId);
      if (res) return res;
    }
  };

  const updateCurrentProjectComponents = async () => {
    const resp = await apiService.fetchAllComponentNames(currentProject?._id);
    setAllComponentNames(resp?.data?.data?.[0]?.pages);
    resp?.data?.data?.[0]?.pages.forEach((page) => {
      currentProject?.pages.forEach((projectPage) => {
        if (page.url === projectPage?.url && page.url !== currentPage?.url) {
          dispatch(
            editPage({
              pageid: projectPage?.id,
              data: { ...projectPage, components: page?.components },
            })
          );
        }
      });
    });
  };

  const handleRowEditOrDetails = async (row, action) => {
    const reachComponent = await getComponentFromAllPages(
      currentComponent[action]
    );
    const pages = currentProject.pages.map((page) => {
      return {
        ...page,
        components: page.components.map((component) => {
          if (
            (component?._id === reachComponent?._id &&
              typeof component === "object") ||
            (typeof component === "string" && component === reachComponent?._id)
          ) {
            let url = "";
            if (currentPage?.url !== page?.url)
              url = `/ui-applications/${viewType}${currentProject.id}/${
                page.url
              }${params?.device ? "/" + params?.device : ""}`;
            const updatedComponent = {
              ...component,
              submitTo: currentComponent?.id,
              data: {
                ...component.data,
                dataValue: row,
              },
            };
            updateComponent(
              page?.name,
              reachComponent?._id,
              { ...updatedComponent },
              url
            );
            return { ...updatedComponent };
          } else {
            return component;
          }
        }),
      };
    });
    dispatch(editUiApplication({ data: { pages: pages } }));
  };

  const fetchFullReachComponent = async (componentId, pageName, pageUrl) => {
    const resp = await apiService.fetchAllComponentByID(
      currentProject?.id,
      pageName,
      componentId
    );

    dispatch(
      editComponentById({
        pageUrl: pageUrl,
        compId: componentId,
        data: resp?.data?.data,
      })
    );
    return resp;
  };

  const handleCreate = async (action) => {
    const reachComponent = await getComponentFromAllPages(
      currentComponent[action]
    );
    let componentsArr = [];
    for (const page of currentProject?.pages) {
      for (const comp of page?.components) {
        if (
          (comp?._id === reachComponent?._id && typeof comp === "object") ||
          (typeof comp === "string" && comp === reachComponent?._id)
        ) {
          let result = await fetchFullReachComponent(
            reachComponent._id,
            page?.name,
            page?.url
          );
          componentsArr.push(result);
        }
      }
    }
    let columnData = componentsArr[0]?.data?.data?.columns;
    const pages = currentProject.pages.map((page) => {
      return {
        ...page,
        components: page.components.map((component) => {
          if (
            (component?._id === reachComponent?._id &&
              typeof component === "object") ||
            (typeof component === "string" && component === reachComponent?._id)
          ) {
            let url = "";
            if (currentPage.url !== page.url) {
              url = `/ui-applications/${viewType}${currentProject.id}/${
                page.url
              }${params?.device ? "/" + params?.device : ""}`;
            }
            const formData = {};
            columnData.forEach((item) => (formData[item?.name] = ""));
            const updatedComponent = {
              ...component,
              submitTo: currentComponent?.id,
              data: {
                ...component?.data,
                dataValue: formData,
              },
            };
            updateComponent(
              page?.name,
              reachComponent?._id,
              { ...updatedComponent },
              url
            );
            return { ...updatedComponent };
          } else return component;
        }),
      };
    });

    dispatch(editUiApplication({ data: { pages: pages } }));
  };

  const updateComponent = async (pageName, componentId, data, url) => {
    try {
      const resp = await apiService.editComponent(
        currentProject?.id,
        pageName,
        componentId,
        data
      );
      if (resp?.status === 201 && url !== "") history.push(url);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (filter?.name && filter?.value) {
      const filteredData = currentComponent?.data?.dataValue?.filter((row) =>
        row?.[filter?.name].toString().includes(filter?.value)
      );
      setTableRows(filteredData);
    } else setTableRows(currentComponent?.data?.dataValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const titleText = currentComponent?.title?.dataValue;

  const isImgLink = (url) => {
    try {
      if (typeof url !== "string") return false;
      return (
        url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !==
        null
      );
    } catch (err) {
      console.log("err:", err);
    }
  };

  function isValidURL(string) {
    try {
      var res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    } catch (err) {
      console.log("err:", err);
    }
  }

  const handleTest = (row, col, currentComponent) => {
    try {
      let val = row?.[col?.name]?.toString();
      if (isImgLink(val) || val?.startsWith("data:")) {
        return <img src={val} alt="none" style={{ height: 30, width: 30 }} />;
      } else if (isValidURL(val)) {
        return (
          <a
            style={{
              fontSize: currentComponent?.fontSize
                ? currentComponent?.fontSize
                : 16,
            }}
            href={val}
            target="_blank"
            rel="noreferrer noopener"
          >
            {val}
          </a>
        );
      } else {
        return val;
      }
    } catch (err) {
      console.log("error while loading data", err);
    }
  };

  return (
    <>
      {!invalidData ? (
        <>
          <div className={styles.header}>
            <h1
              className="title"
              style={{
                fontFamily: currentComponent?.fontFamily,
                fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
                fontStyle: currentComponent?.style?.italic
                  ? "italic"
                  : undefined,
                textDecoration: currentComponent?.style?.underline
                  ? "underline"
                  : undefined,
                fontSize: currentComponent?.fontSize
                  ? currentComponent?.fontSize
                  : 16,
              }}
            >
              {titleText && typeof titleText === "string" ? titleText : "Title"}
            </h1>
            <div
              style={{
                position: "absolute",
                right: 15,
                top: 3,
                display: "flex",
              }}
            >
              {currentComponent?.filter && (
                <IconButton
                  color="inherit"
                  edge="start"
                  style={{ margin: 0, padding: 5 }}
                  onClick={(e) => setFiltersEl(e.currentTarget)}
                >
                  <FilterList opacity={0.5} />
                </IconButton>
              )}
              <TableFilter
                columns={columns}
                styles={styles}
                filter={filter}
                setFilter={setFilter}
                filtersEl={filtersEl}
                isFiltesrOpen={isFiltesrOpen}
                setFiltersEl={setFiltersEl}
              />
              {currentComponent?.add && (
                <AppButton
                  buttonName="New"
                  variant="outlined"
                  style={{
                    marginLeft: 15,
                    fontSize: currentComponent?.fontSize
                      ? currentComponent?.fontSize
                      : 16,
                  }}
                  startIcon={<AddOutlinedIcon />}
                  className="btnsmall"
                  onClick={() => handleCreate("onAdd")}
                />
              )}
            </div>
          </div>
          {currentComponent?.loading?.dataValue === "true" && <Loader />}
          <TableContainer className={styles.table}>
            {columns?.length && tableRows?.length && (
              <Table stickyHeader>
                <SortableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  currentComponent={currentComponent}
                />
                <TableBody>
                  {tableRows?.length &&
                    columns?.length &&
                    stableSort(tableRows, getComparator(order, orderBy))
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowEditOrDetails(row, "onRowSelect");
                          }}
                        >
                          {columns?.map((col, colindex) => (
                            <TableCell
                              style={{
                                position: colindex === 0 && "sticky",
                                left: colindex === 0 && 0,
                                backgroundColor: colindex === 0 && "#ffffff",
                                fontSize: currentComponent?.fontSize
                                  ? currentComponent?.fontSize
                                  : 16,
                                borderRight:
                                  colindex === 0 && "1px solid #EFF1F2",
                              }}
                              key={colindex}
                              width={col?.width ? col?.width : 0}
                            >
                              {handleTest(row, col, currentComponent)}
                            </TableCell>
                          ))}
                          {(currentComponent?.edit ||
                            currentComponent?.delete) && (
                            <TableCell className="actionColumn">
                              <div className="icons">
                                {currentComponent?.edit && (
                                  <IconButton
                                    color="inherit"
                                    edge="start"
                                    style={{ margin: 0, padding: 5 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRowEditOrDetails(row, "onEdit");
                                    }}
                                  >
                                    <img src="/img/edit.svg" alt="edit" />
                                  </IconButton>
                                )}
                                {currentComponent?.delete && (
                                  <IconButton
                                    color="inherit"
                                    edge="start"
                                    style={{ margin: 0, padding: 5 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleTrigger({
                                        action: currentComponent?.["onDelete"],
                                        id: row._id ? row._id : row.id,
                                        data: row,
                                      });
                                      handleTrigger({
                                        action: currentComponent?.["onInit"],
                                        isUpdateState: true,
                                        componentId: currentComponent?.id,
                                      });
                                    }}
                                  >
                                    <img
                                      src="/img/delete-bin-7-line.svg"
                                      alt="Delete"
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <div className={styles.footer}>
            <h3
              style={{
                fontSize: currentComponent?.fontSize
                  ? currentComponent?.fontSize
                  : 16,
              }}
              className={"itemsCount"}
            >
              {tableRows?.length} items
            </h3>
            <Pagination
              count={Math.ceil(tableRows?.length / itemsPerPage)}
              shape="rounded"
              className={"pagination"}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: NavigateBeforeIcon,
                    next: NavigateNextIcon,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </>
      ) : (
        <div
          id="invalid-custom-table"
          style={{ background: "#FFFFFF", height: "100%" }}
        >
          <h1 style={{ margin: 0 }}>Invalid data</h1>
        </div>
      )}
    </>
  );
};

export default CustomTable;
