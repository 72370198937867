/* eslint-disable */
import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    "& button.btngiant": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal bold 14px/19px Nunito",
      padding: "19px 38px 18px 38px",
      minHeight: "56px",
      minWidth: "120px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        minWidth: "126px",
        border: "1px solid #A8A8A8",
        borderRadius: "8px",
        opacity: 1,
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },

    "& button.btnlarge": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal bold 14px/19px Nunito",
      padding: "15px 28px 14px 28px",
      minWidth: "100px",
      minHeight: "48px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "8px",
        opacity: 1,
        minWidth: "106px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.btnmedium": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal 500 14px/19px Nunito",
      letterSpacing: "0.02em",
      padding: "10.86px 28px 10.14px 28px",
      minHeight: "40px",
      minWidth: "120px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "126px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.btnsmall": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal 400 14px/19px Nunito",
      letterSpacing: "0.02em",
      padding: "6px 20px 6px 20px",
      minHeight: "32px",
      minWidth: "120px",
      "&.MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      },
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "120px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.btntiny": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal 500 12px/16px Nunito",
      letterSpacing: "0.02em",
      padding: "5px 34px",
      minHeight: "24px",
      minWidth: "100px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "106px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.secondaryButton": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
    "& button.MuiButton-outlined": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
  },
}));

export const AppButton = (p) => {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <Button
        className={p.className}
        variant={p.variant}
        color={p.color}
        style={p.style}
        size={p.size}
        startIcon={p.startIcon}
        endIcon={p.endIcon}
        disabled={p.disabled}
        onClick={p.onClick}
      >
        {p.buttonName}
      </Button>
    </div>
  );
};
