import { TeamAPIServices } from "../../services/teamServices";
const TeamAPIService = new TeamAPIServices();

export const getTeamList = async () => {
  try {
    const res_data = await TeamAPIService.getTeamList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchUsers = async (filter) => {
  try {
    const res_data = await TeamAPIService.fetchUsers(filter);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getTeamById = async (userID) => {
  try {
    const res_data = await TeamAPIService.getTeamById(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const createTeam = async (teamData) => {
  try {
    const res_data = await TeamAPIService.createTeam(teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editTeam = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.editTeam(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const LockTeam = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.LockTeam(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const UnLockTeam = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.UnLockTeam(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const BLockTeam = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.BLockTeam(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const UnBLockTeam = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.UnBLockTeam(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const AddTeamMember = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.AddTeamMember(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const RemoveTeamMember = async (teamId, teamData) => {
  try {
    const res_data = await TeamAPIService.RemoveTeamMember(teamId, teamData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
