import {
  ADD_CANVAS_APPLICATION_DATA,
  ADD_TEMPLATE_DATA,
  ADD_ELEMENTS_TO_LIST,
  EDIT_ELEMENT_FROM_LIST,
  REPLACE_ELEMENT_LIST,
  ADD_ANIMATIONS,
  REMOVE_ANIMATIONS,
  ALTER_SIMULATE_STATUS,
  ADD_REMOVE_DEBUG_POINTS,
  ADD_PROCESS_OUTPUT,
  ADD_CONTAINER_DATA_FOR_TEMPLATE,
  ALTER_FLOW_MODE,
  ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION,
  ADD_INPUT_OUTPUT_DATA_FOR_NODES,
  ALTER_DEBUG_DATA,
  ADD_DEBUG_ANIMATIONS,
  REMOVE_ALL_DEBUG_POINTS
} from "../actionTypes";
import { ApplicationServices } from "../../services/applicationServices";

const ApplicationService = new ApplicationServices();

const add_data = (payload) => {
  return {
    type: ADD_CANVAS_APPLICATION_DATA,
    payload: payload,
  };
};

export const add_canvas_application_data = (id) => async (dispatch) => {
  try {
    dispatch(
      add_data({
        loading: true,
      })
    );
    const res_data = await ApplicationService.getApplicationByID(id);
    const { _msg, _status, data } = res_data.data;
    if (data) {
      dispatch(
        add_data({
          loading: false,
          data: data,
          error: false,
        })
      );
    } else {
      dispatch(
        add_data({
          loading: false,
          data: [],
          error: false,
        })
      );
    }
  } catch (error) {
    dispatch(
      add_data({
        loading: false,
        data: [],
        error: true,
      })
    );
  }
};

export const addTemplateData = (data) => {
  return {
    type: ADD_TEMPLATE_DATA,
    payload: data,
  };
};

export const addElementToList = (data) => {
  return {
    type: ADD_ELEMENTS_TO_LIST,
    payload: data,
  };
};

export const editElementFromList = (data) => {
  return {
    type: EDIT_ELEMENT_FROM_LIST,
    payload: data,
  };
};

export const replaceElementList = (data) => {
  return {
    type: REPLACE_ELEMENT_LIST,
    payload: data,
  };
};

export const addAnimations = (color) => {
  return {
    type: ADD_ANIMATIONS,
    payload: {
      color: color
    },
  };
};

export const removeAnimations = () => {
  return {
    type: REMOVE_ANIMATIONS,
    payload: {},
  };
};

export const alterSimulateStatus = (flag) => {
  return {
    type: ALTER_SIMULATE_STATUS,
    payload: {
      flag: flag,
    },
  };
};

export const addRemoveDebugPoints = (data_obj) => {
  // const {add , id} = obj;
  return {
    type: ADD_REMOVE_DEBUG_POINTS,
    payload: data_obj
  }
}

export const addProcessOutput = (data_obj) => {
  return {
    type: ADD_PROCESS_OUTPUT,
    payload: data_obj
  }
}

export const addContainerDataForTemplate = (data_obj) => {
  return {
    type: ADD_CONTAINER_DATA_FOR_TEMPLATE,
    payload: data_obj
  }
}

export const alterFlowMode = (mode) => {
  return {
    type: ALTER_FLOW_MODE,
    payload: mode
  }
}

export const addOutfieldForCallNodeRecommendation = (data_arr) => {
  return {
    type: ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION,
    payload: data_arr ? data_arr : []
  }
}

export const addInputOutputDataForNodes = (data_obj, edit, replace) => {
  return {
    type: ADD_INPUT_OUTPUT_DATA_FOR_NODES,
    payload: {
      data: data_obj,
      edit: edit,
      replace: replace
    }
  }
}

export const alterDebugData = (data_obj) => {
  return {
    type: ALTER_DEBUG_DATA,
    payload: data_obj
  }
}

export const addDebugAnimation = () => {
  return {
    type: ADD_DEBUG_ANIMATIONS,
    payload: {}
  }
}

export const removeAllDebugPoints = () => {
  return {
    type: REMOVE_ALL_DEBUG_POINTS,
    payload: {}
  }
}
