import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import FileDropperWithLayout from "../../FileDropperWithLayout";
import useCheckPermission from "../../hooks/useCheckPermission";
import { DropzoneArea } from "material-ui-dropzone";
import AddIcon from "@material-ui/icons/Add";

const CustomFieldGroupForTestExecutionEdit = ({
  section,
  deployment,
  envData,
  envs,
  testExecutionData,
  handleDeleteClick,
  error,
  handleChange,
  handleAddEnv,
  handleDeleteEnv,
  handleEnvChange,
  setEnvironmentData,
  handleUpload,
  setIsLoader,
  fileDetails,
  droppedDocument,
  userInfo,
  isEditMode,
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();
  var duration =
    testExecutionData?.results?.timings?.completed -
    testExecutionData?.results?.timings?.started;
  const UploadTestScriptFile = () => {
    return (
      <>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                File
              </label>
              <div
                className={`${styles.documentDropperWrapper2} ${
                  error.droppedDocument === undefined &&
                  droppedDocument.length === 0 &&
                  styles.ErrordocumentDropperWrapper2
                }`}
              >
                <DropzoneArea
                  name="droppedDocument"
                  className={styles.documentDropperClass}
                  showPreviews={false}
                  filesLimit={1}
                  dropzoneText="Drag and drop or click to browse here"
                  dropzoneClass="documentDropperClass"
                  dropzoneParagraphClass="documentDropperTextClass"
                  get
                  maxFileSize={9000000000}
                  showAlerts={["error"]}
                  showPreviewsInDropzone={false}
                  onDrop={() => setIsLoader(true)}
                  onChange={(files) => {
                    setIsLoader(false);
                    handleUpload(files);
                  }}
                />
              </div>

              {error.droppedDocument === undefined &&
                droppedDocument.length === 0 && (
                  <p className={styles.errorMsg}>{"File is required"}</p>
                )}

              {fileDetails?.filename && (
                <div className={styles.doc_container}>
                  <div className={styles.group1}>
                    <text className={styles.label}>File Name</text>
                    <input
                      className={styles.input2}
                      value={fileDetails["filename"]}
                      placeholder="Enter File Name"
                      disabled={true}
                      style={{ width: "400px" }}
                      //   onChange={handleImageNameChange}
                    ></input>
                  </div>
                  <span
                    style={{
                      marginLeft: 23,
                      marginTop: 25,
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={handleDeleteClick}
                  >
                    <img
                      src="/images/deleteLine.svg"
                      height={18}
                      width={18}
                      color="#000000"
                      alt="icon"
                    />
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const EnvironmentVariable = (field) => {
    return (
      <>
        {/* <Grid item xs={3}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Environments
                          </label>
                          <Autocomplete
                            options={envData ? envData : []}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            // value={selectedVersion ? selectedVersion : ""}
                            className={`${styles.searchInput3}`}
                            onChange={(e, input) => {
                              if (input) {
                                setEnvironmentData(input);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Search environment"
                              />
                            )}
                          />
                        </Grid> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TableContainer>
              <Table
                className={styles.customTable}
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ paddingLeft: 20, width: "2%" }}
                    ></TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {envs.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ border: "0.5px solid #D5D5D5" }}
                    >
                      <TableCell scope="row">
                        <Checkbox
                          checked={envs[index].enabled}
                          onChange={(e) =>
                            handleEnvChange(e, row.id, "enabled")
                          }
                          name="memorytype"
                          color="primary"
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <input
                          type="text"
                          placeholder="Enter key"
                          name={row.id}
                          className={styles.input2}
                          value={envs[index].key}
                          onChange={(e) => handleEnvChange(e, row.id, "key")}
                          autoComplete="off"
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          placeholder="Enter Value"
                          name={row.id}
                          className={styles.input2}
                          value={envs[index].value}
                          onChange={(e) => handleEnvChange(e, row.id, "value")}
                          autoComplete="off"
                          disabled={true}
                        />
                      </TableCell>
                      {/* <TableCell align="center">
                                        <IconButton
                                          color="inherit"
                                          aria-label="delete"
                                          edge="start"
                                          onClick={() =>
                                            handleDeleteEnv(row.id)
                                          }
                                        >
                                          <img
                                            src="/images/deleteLine.svg"
                                            alt="card"
                                          />
                                        </IconButton>
                                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div
                              style={{
                                backgroundColor: "#fff",
                                padding: "5px 0",
                                borderLeft: "1px solid #E2E2E3",
                                borderRight: "1px solid #E2E2E3",
                                borderBottom: "1px solid #E2E2E3",
                              }}
                            >
                              <IconButton
                                color="inherit"
                                aria-label="edit"
                                edge="start"
                                onClick={handleAddEnv}
                                className={styles.actionIcon}
                              >
                                <AddIcon />
                              </IconButton>
                            </div> */}
          </Grid>
        </Grid>
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Name") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {/* {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )} */}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  field.dataType === "date" && testExecutionData[field.datakey]
                    ? moment
                        .unix(testExecutionData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : testExecutionData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else if (field.name === "Executed By") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Run By
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={testExecutionData.createdby}
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Executed On") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Run On
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData.createdon
                    ? moment
                        .unix(testExecutionData.createdon)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : testExecutionData.createdon
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Run Deployment") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData.deploymentname
                    ? testExecutionData.deploymentname
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Run Script") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData?.collection
                    ? testExecutionData?.collection
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Run Data") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData?.TestData ? testExecutionData?.TestData : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Run Start") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData?.results?.timings?.started
                    ? moment(
                        testExecutionData?.results?.timings?.started
                      ).format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                    : testExecutionData?.results?.timings?.started
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Run End") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData?.results?.timings?.completed
                    ? moment(
                        testExecutionData?.results?.timings?.completed
                      ).format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                    : testExecutionData?.results?.timings?.completed
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Total Run Time") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Run Duration(ms)
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  testExecutionData?.results?.timings?.completed && testExecutionData?.results?.timings?.completed -
                  testExecutionData?.results?.timings?.started
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Test Average Response") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :
                  testExecutionData?.results?.timings?.responseAverage &&
                  testExecutionData?.results?.timings?.responseAverage
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.datakey === "totaltests") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                // value={"Avsavs"}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :
                  testExecutionData?.results?.stats?.tests?.total
                    ? testExecutionData?.results?.stats?.tests?.total
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Total Iterations") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                // value={"Avsavs"}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :
                  testExecutionData?.results?.stats?.iterations?.total
                    ? testExecutionData?.results?.stats?.iterations?.total
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Executed Assertions") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                // value={"Avsavs"}
                value={
                  testExecutionData?.results?.stats?.assertions?.total -
                  testExecutionData?.skippedtests
                    ? testExecutionData?.results?.stats?.assertions?.total -
                      testExecutionData?.skippedtests
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Failed Assesertions") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                // value={"Avsavs"}
                value={testExecutionData?.results?.stats?.assertions?.failed}
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.datakey === "passedtests") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :
                  testExecutionData?.results?.stats?.tests?.total -
                  testExecutionData?.results?.stats?.tests?.failed
                    ? testExecutionData?.results?.stats?.tests?.total -
                      testExecutionData?.results?.stats?.tests?.failed
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.datakey === "failedtests") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :

                  testExecutionData?.results?.stats?.tests?.failed
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Skipped Tests") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  // testExecutionData?.results?.timings?.started -
                  // testExecutionData?.results?.timings?.completed
                  //   ? moment
                  //       .unix(
                  //         testExecutionData?.results?.timings?.started -
                  //           testExecutionData?.results?.timings?.completed
                  //       )
                  //       .tz(userInfo.timeZone)
                  //       .format(
                  //         userInfo.time === "h:mm"
                  //           ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //           : `${userInfo.dateFormat} ${userInfo.time}`
                  //       )
                  //   :

                  testExecutionData?.skippedtests
                    ? testExecutionData?.skippedtests
                    : ""
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Pass Percentage") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  ((testExecutionData?.results?.stats?.assertions?.total -
                    testExecutionData?.skippedtests -
                    testExecutionData?.results?.stats?.assertions?.failed) /
                    (testExecutionData?.results?.stats?.assertions?.total -
                      testExecutionData?.skippedtests)) *
                  100
                    ? ((testExecutionData?.results?.stats?.assertions?.total -
                        testExecutionData?.skippedtests -
                        testExecutionData?.results?.stats?.assertions?.failed) /
                        (testExecutionData?.results?.stats?.assertions?.total -
                          testExecutionData?.skippedtests)) *
                      100
                    : "0"
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {/* {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )} */}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  field.dataType === "date" && testExecutionData[field.datakey]
                    ? moment
                        .unix(testExecutionData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : testExecutionData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
                disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }

      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={testExecutionData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea2} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
              disabled={true}
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                testExecutionData[field.datakey]
                  ? testExecutionData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${
                error[field.datakey]?.isError && styles.error
              }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg} style={{ paddingTop: 5 }}>
                {error[field.datakey].msg}
              </p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                testExecutionData[field.datakey]
                  ? testExecutionData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  testExecutionData[`${field.datakey}_unit`]
                    ? testExecutionData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                }`}
                value={testExecutionData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                testExecutionData[field.datakey]
                  ? testExecutionData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Test Run Details" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "flex" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "File":
                          return UploadTestScriptFile();

                        case "Env Table":
                          return EnvironmentVariable();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForTestExecutionEdit);
