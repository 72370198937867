import React, { useEffect, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import {
  editNodeData,
  getJSONObjFromKeyValueArray,
  getKeyValueFromStringJSON
} from "../../utils/orchestration/orchestrationUtils";
import Editor from "../common/Editor";
import inputStyles from "./SimulateModal/styles";
import InputTable from "../common/Tables/InputTable";
import { AppButton } from "../common/Button";
import { fetchNodeDetails } from "../../utils/orchestration/orchestrationUtils";

const temp_data = [
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

function EndNodeMapping(props) {
  const [activeInputType, setActiveInputType] = useState("text");
  const [inputList, setInputList] = useState(temp_data);
  const [inputEditorValue, setInputEditorValue] = useState("");
  const [loading, setLoading] = useState(false);
  const descriptionElementRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = inputStyles();

  const { isOpen, name, nodeid, templateid } = props;

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const fetchEndNodeData = async () => {
      const {_msg, _status,data} = await fetchNodeDetails(templateid, nodeid);
      if(_status===200){
        if(data.output){
          setInputList(getKeyValueFromStringJSON(JSON.parse(data.output)));
          setInputEditorValue(JSON.stringify(JSON.parse(data.output), null, '\t'))
        }else{
          setInputList(temp_data)
        }
      }
  }

  useEffect(() => {
    fetchEndNodeData();
  },[])

  const handleInputTypeChange = (val) => {
    setActiveInputType(val);
  };

  //handle change in the input table
  const handleInputTableChange = (val) => {
    setInputList(val);
  };

  const handleSubmitClick = async () => {
    var output_str = "";
    setLoading(true);
    if (activeInputType === "text") {
      var temp_obj = getJSONObjFromKeyValueArray(inputList);
      output_str = JSON.stringify(temp_obj);
    } else if (activeInputType === "editor") {
      output_str = inputEditorValue;
    }
    const req_data = {
      output: output_str,
    };

    const { _msg, _status } = await editNodeData(
      nodeid,
      templateid,
      req_data
    );
    if (_status === 201) {
      setLoading(false);
      enqueueSnackbar(_msg, { variant: "success" });
      props.onEditEndClose();
    } else {
      setLoading(false);
      enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => props.onCloseClick()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1} style={{ padding: 0 }}>
            {/* <img src="/images/Call.png" alt="Icon" height={34} width={34} /> */}
            <p
              className={classes.heading}
            >{`End Node Ouput Mapping - ${name}`}</p>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className={classes.container}>
            <div className={classes.iconContainer}>
              {/* style={{ marginLeft: "84%" }}> */}
              <IconButton
                color="inherit"
                aria-label="textfield"
                edge="start"
                className={`${activeInputType === "text" ? "toggleActive" : "toggleInactive"
                  } ${classes.toggleicons}`}
                onClick={() => handleInputTypeChange("text")}
              >
                <img src="/images/orchestration/lineicon.svg" alt="text" />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="editor"
                edge="start"
                className={`${activeInputType === "editor" ? "toggleActive" : "toggleInactive"
                  } ${classes.toggleicons}`}
                onClick={() => handleInputTypeChange("editor")}
              >
                <img src="/images/orchestration/editoricon.svg" alt="editor" />
              </IconButton>
            </div>
            <div className={classes.line5}>
              {/* <p className={classes.label}>Input</p> */}
              {activeInputType === "editor" && (
                <div className={classes.outputbody}>
                  <Editor
                    onChange={(val) => setInputEditorValue(val)}
                    value={inputEditorValue}
                  />
                </div>
              )}
              {activeInputType === "text" && (
                <InputTable
                  envs={inputList}
                  setEnvs={(val) => handleInputTableChange(val)}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton onClick={() => props.onEditEndClose()}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnmedium" />
          <AppButton onClick={handleSubmitClick}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnmedium" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { process_data } = state.orchestration;
  const { templateid } = process_data;
  return {
    templateid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndNodeMapping);
