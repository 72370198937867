import {
  FilledInput,
  InputAdornment,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../components/common/Button";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import listScreenStyles from "../../screens/CommonStyles/listScreenStyles";
import {
  uiApplicationColumns,
  uiApplicationDefaultColumnWidths,
  uiApplicationTableColumnExtensions,
  uiApplicationColumnOrder,
  uiApplicationColumnHidden,
} from "../utils/constants/UiApplicationConstant";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import {
  add_uiApplication_filters,
  add_uiApplication_sort,
  add_uiApplication_HiddenCols,
  get_uiApplication_data,
} from "../../redux/actions/uiApplicationAction";
import DevExpressTable from "../../components/common/Tables/DevExpressTable";
import CustomFilter from "../../components/common/CustomFilter";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { arrayToFilter, arrayToSort } from "../../utils/common";
const UiListScreen = (props) => {
  const {
    //   action
    get_uiApplication_data,
    add_uiApplication_filters,
    add_uiApplication_sort,
    add_uiApplication_HiddenCols,
    // reducer
    data,
    loading,
    count,
    uiApplicationFilter,
    uiApplicationSort,
    uiApplicationHiddenCols,
    deleteAppStatus
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [currentFavouriteUiAppsPage, setCurrentFavouriteUiAppsPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const styles = listScreenStyles();
  const history = useHistory();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  //   Permission Check
  useEffect(
    () => checkScreenPermission("UIApplication", ["List All", "List Assigned"]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const parsedFilters = arrayToFilter(uiApplicationFilter);
    const parsedSort = arrayToSort(uiApplicationSort);
    get_uiApplication_data(
      currentPage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, uiApplicationSort]);

  useEffect(() => {
    if(deleteAppStatus === true) {  
      const parsedFilters = arrayToFilter(uiApplicationFilter);
      const parsedSort = arrayToSort(uiApplicationSort);
      get_uiApplication_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAppStatus]);

  const handlePageChange = (event, value) => setCurrentPage(value);

  function createData(uiAppData) {
    const {
      _id,
      description,
      name,
      status,
      userid,
      createdon,
      createdby,
      updatedon,
      updatedby,
      services,
      pages,
    } = uiAppData;
    return {
      ...uiAppData,
      id: _id,
      name,
      description,
      status,
      userid,
      createdon: createdon ? createdon : null,
      createdby: createdby,
      updatedon: updatedon ? updatedon : null,
      updatedby: updatedby,
      containerName: services && services?.length && services[0]?.containerName,
      landingPage: pages ? pages : [],
    };
  }

  useEffect(() => {
    !isFavouriteClick
      ? setRows(
        data && data?.length > 0
          ? data.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      )
      : setRows(
        data && data?.length > 0
          ? data
            ?.filter((r) => r?.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    const id = setTimeout(
      () =>
        isInitialMount.current
          ? (isInitialMount.current = false)
          : handleSearch(),
      500
    );
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleSearch = () => {
    searchInput
      ? get_uiApplication_data(
        0,
        JSON.stringify({
          name: { $regex: searchInput, $options: "i" },
        })
      )
      : get_uiApplication_data(0);
  };
  // filter
  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(uiApplicationFilter);
    const parsedSort = arrayToSort(uiApplicationSort);
    get_uiApplication_data(0, parsedFilters, JSON.stringify(parsedSort));
  };
  const handleClearFilters = () =>
    get_uiApplication_data("", 0, JSON.stringify());

  const handleToggleFavouriteProjects = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(uiApplicationFilter);
      const parsedSort = arrayToSort(uiApplicationSort);
      get_uiApplication_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );

      setRows(
        data && data?.length > 0
          ? data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPage(1);
    } else {
      get_uiApplication_data();
      setIsFavouriteClick(true);
      setCurrentFavouriteUiAppsPage(1);
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[{ title: "UI Application", path: "/ui-applications" }]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("uiApplication.uiApplication")}
                {count ? ` (${count})` : ""}
              </p>
            </div>
            <div
              className={styles.topContentItem}
              style={{
                justifyContent: "flex-end",
              }}
            >
              <FilledInput
                id="filled-adornment-password"
                name="filterfn"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.search}
                placeholder="Search"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={uiApplicationColumns}
                filters={uiApplicationFilter}
                setFilters={add_uiApplication_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <Tooltip title="Favourite" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: "0px 10px" }}
                  onClick={() => handleToggleFavouriteProjects()}
                >
                  {isFavouriteClick ? (
                    <img
                      src="/images/heartFilled.svg"
                      alt="card"
                      style={{ position: "absolute" }}
                    />
                  ) : (
                    <img
                      src="/images/heartLine.svg"
                      alt="card"
                      style={{ position: "absolute" }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="create"
                variant="contained"
                color="primary"
                startIcon={
                  <AddIcon style={{ width: "16px", height: "16px" }} />
                }
                className="btnsmall"
                onClick={() => history.push("/ui-applications/create")}
              // disabled={!checkCompPermission('UI Application', ['Create'])}
              />
            </div>
          </div>
        </div>

        {/* Table */}

        <Paper className={styles.pageContent}>
          <DevExpressTable
            columns={uiApplicationColumns}
            rows={isFavouriteClick ? (
              rows?.length > 0
                ? rows?.slice(
                  (currentFavouriteUiAppsPage - 1) * 10,
                  currentFavouriteUiAppsPage * 10
                )
                : []
            ) : rows}
            defaultColumnWidths={uiApplicationDefaultColumnWidths}
            tableColumnExtensions={uiApplicationTableColumnExtensions}
            columnOrder={uiApplicationColumnOrder}
            defaultHiddenColumnNames={uiApplicationColumnHidden}
            loading={loading}
            currentPage={isFavouriteClick ? currentFavouriteUiAppsPage : currentPage}
            setCurrentPage={setCurrentPage}
            isCustomHeight={true}
            sort={uiApplicationSort}
            compFilter={uiApplicationFilter}
            setSort={add_uiApplication_sort}
            hidden={uiApplicationHiddenCols}
            setHidden={add_uiApplication_HiddenCols}
          />
        </Paper>
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            {isFavouriteClick ?
              <Pagination
                count={Math.ceil(rows?.length / 10)}
                page={currentFavouriteUiAppsPage}
                onChange={(e, val) => setCurrentFavouriteUiAppsPage(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
              :
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            }
          </div>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  get_uiApplication_data,
  add_uiApplication_filters,
  add_uiApplication_sort,
  add_uiApplication_HiddenCols,
};

const mapStateToProps = (state) => {
  const {
    uiApplication_list,
    uiApplicationFilter,
    uiApplicationSort,
    uiApplicationHiddenCols,
  } = state.uiApplicationReducer;
  const { data, error, loading, count, deleteAppStatus } = uiApplication_list;

  return {
    data,
    error,
    loading,
    count,
    deleteAppStatus,
    uiApplicationFilter,
    uiApplicationSort,
    uiApplicationHiddenCols,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UiListScreen);
