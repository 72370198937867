import {
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Divider,
  Grid,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../../components/common/Breadcrumbs';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import listStyles from '../../CommonStyles/listScreenStyles';
import { useHistory } from 'react-router-dom';
import useCheckPermission from '../../../components/common/hooks/useCheckPermission';
import { StatusProfiles } from '../../../services/statusProfiles';
import { alterActiveTab } from "../../../redux/actions/activeTabActions"
import { connect } from 'react-redux';
const _StatusProfiles = new StatusProfiles();

const StatusProfileListScreen = (props) => {
  const { alterActiveTab } = props
  const styles = listStyles();
  const histroy = useHistory();
  const [open, setOpen] = useState(false);
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [statusProfileList, setStatusProfileList] = useState([]);
  const [resourceData, setresourceData] = useState('');
  const [count, setCount] = useState(null);
  const [resourceName, setresourceName] = useState('');
  const [flag, setflag] = useState(false);


  useEffect(() => {
    alterActiveTab("Status Profile", "Status Profile")
  }, [])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addNewStatus = (objectType) => {
    histroy.push(`/status-profile/edit/${objectType}`);
    sessionStorage.setItem('objectType', objectType);
  };

  useEffect(() => {
    const statusProfileObjectList = async () => {
      try {
        const res_data = await _StatusProfiles.fetchResourcesStatus();
        setCount(res_data.data ? res_data.data._totalcount : null);
        setStatusProfileList(res_data?.data?.data);
      } catch (err) {
        //
      }
    };
    statusProfileObjectList();
  }, []);

  // getting particular resource data
  useEffect(() => {
    const getStatusById = async () => {
      try {
        const res_data = await _StatusProfiles.fetchStatusResourceByID(
          resourceName
        );
        const { _status, data } = res_data.data;

        if (_status === 200) {
          setresourceData(data);
        }
      } catch (error) {
        //
      }
    };
    if (resourceName) {
      getStatusById();
    }
  }, [resourceName]);

  useEffect(() => {
    checkScreenPermission('StatusProfile', ['List All', 'List Assigned'])
  }, []);
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[{ title: 'Status Profile', path: '/status-profile' }]}
          />
          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: '80%' }}>
              <p className={styles.title}>
                {'Status Profile'} {count !== null ? ` (${count})` : ''}
              </p>
            </div>
          </div>
        </div>
        {/* Table */}
        <Paper className={styles.pageContent}>
          <TableContainer>
            <Table
              stickyHeader
              className={styles.tableTest}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow
                  classes={{ head: styles.head }}
                // className={styles.rowBody}
                >
                  <TableCell className={styles.tableCell}>
                    Object Type
                  </TableCell>
                  <TableCell className={styles.tableCell}>Statuses</TableCell>
                  <TableCell className={styles.tableCell}>
                    <Grid container justify="flex-end">
                      <Grid item className={styles.action}>
                        Action
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusProfileList.length !== 0 &&
                  statusProfileList.map((row) => (
                    <TableRow key={row.name} classes={{ head: styles.head }}>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() =>
                          histroy.push(`/status-profile/edit/${row.name}`)
                        }
                        className={styles.rowCell}
                      >
                        {row.label}
                      </TableCell>
                      <TableCell className={styles.rowCell}>
                        <Grid
                          container
                          alignItems="center"
                          style={{ maxHeight: '1.2rem' }}
                        >
                          <Grid
                            item
                            container
                            xs
                            alignItems="center"
                            className={styles.statusesContainer}
                          >
                            <Grid item>
                              <span
                                style={{ fontSize: 14 }}
                              >{`${row.status.length} statuses`}</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="inherit"
                                aria-label="edit"
                                edge="start"
                                onClick={() => {
                                  setresourceName(row.name);
                                  setflag(!flag);
                                  handleClickOpen();
                                }}
                                className={styles.iconHover}
                              // style={{marginLeft:0.1}}
                              >
                                <ArrowDropDownIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={styles.rowCell}
                        onClick={() =>
                          histroy.push(`/status-profile/edit/${row.name}`)
                        }
                      >
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="center"
                          style={{ maxHeight: '1.4rem' }}
                        >
                          <Grid
                            item
                            className={styles.statusesContainer}
                            style={{ marginRight: '18%' }}
                          >
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                color="inherit"
                                aria-label="delete"
                                edge="start"
                                className={styles.iconHover}
                                onClick={() =>
                                  histroy.push(`/status-profile/edit/${row.name}`)
                                }
                              >
                                <img
                                  src="/images/editLine.svg"
                                  alt="card"
                                  style={
                                    {
                                      // padding: 8,
                                    }
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Typography>{resourceName}</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              <div>
                {resourceData.status &&
                  resourceData.status.map((val, i) => (
                    <Paper
                      variant="outlined"
                      square
                      key={i}
                      style={{ marginBottom: 10 }}
                    >
                      <div className={styles.completedStatus}>
                        <span
                          className={styles.predefinedColorDots}
                          style={{ backgroundColor: val.color }}
                        ></span>
                        <p
                          style={{
                            color: val.color ? val.color : '#000000',
                            fontWeight: 400,
                          }}
                        >
                          {val.status}
                        </p>
                      </div>
                    </Paper>
                  ))}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className={styles.statusModalCloseButton}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  alterActiveTab
}
const mapStateToProps = (state) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(StatusProfileListScreen);
