import React from "react";
import { Grid } from "@material-ui/core";
import Input from "../../components/atoms/input/Input";
import FormWrapper from "../form/FormWrapper";
import TextArea from "../../components/atoms/textArea/TextArea";
import Dropdown from "../../components/atoms/dropdown/Dropdown";
import Columns from "../../components/atoms/rightSide/settings/Columns";
import MultipleSelect from "../../components/atoms/dropdown/select/MultipleSelect";
import Toggle from "../../components/atoms/toggle/Toggle";
import UploadImage from "../../components/atoms/uploadImage/UploadImage";
import UploadFile from "../../components/atoms/uploadfile/UploadFile";
import Select from "../../components/atoms/dropdown/select/Select";
import DatePicker from "../../components/atoms/DatePicker/DatePicker";
import CircularProgress from "@material-ui/core/CircularProgress";

export const whichCompToRender = (
  col,
  index,
  selectedComponent,
  handleChangeQuery,
  fieldArrQuery,
  detailColumns,
  multiSelect,
  handleChangeMultipleSelect,
  selectedImage,
  selectedFile,
  handleImage,
  handleFile,
  uploadError,
  uploadInPrgrs,
  err,
  classes
) => {
  switch (col?.type) {
    case "text":
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <Input
            name={col.name}
            label={col.title}
            placeholder={col.placeholder ? col.placeholder : ""}
            disabled={col?.isEditable ? false : true}
            style={
              col.isEditable
                ? undefined
                : { background: "#f4f4f4 0% 0% no-repeat padding-box " }
            }
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, index)}
            error={err[col?.name]}
            isRequired={col?.isRequired}
            selectedComponent={selectedComponent}
          />
        </FormWrapper>
      );
    case "textarea":
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <TextArea
            name={col?.name}
            rows={col?.rows}
            disabled={col?.isEditable ? false : true}
            style={
              col.isEditable
                ? undefined
                : { background: "#f4f4f4 0% 0% no-repeat padding-box " }
            }
            placeholder={col.placeholder ? col.placeholder : ""}
            label={col?.title}
            value={fieldArrQuery[col.name]}
            selectedComponent={selectedComponent}
            onChange={(event) => handleChangeQuery(event, index)}
            error={err[col?.name]}
            isRequired={col?.isRequired}
          />
        </FormWrapper>
      );
    case "select":
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label
            style={{
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            {col.title}
          </label>
          <Select
            arr={selectedComponent?.columns
              .find((c) => c.id === col.id)
              .options.filter((filtered) => filtered.type === "select")}
            disabled={col?.isEditable ? false : true}
            style={
              col.isEditable
                ? undefined
                : {
                    background: "#f4f4f4 0% 0% no-repeat padding-box",
                    marginTop: 0,
                  }
            }
            width="auto"
            name={col.name}
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, index)}
            error={err[col?.name]}
            selectedComponent={selectedComponent}
          />
        </FormWrapper>
      );
    case "multiselect":
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label>{col?.title}</label>
          <MultipleSelect
            arr={selectedComponent?.columns
              .find((c) => c.id === col.id)
              .options.filter((filtered) => filtered.type === "multiselect")}
            // value={fieldArrQuery[col?.name]}
            disabled={col?.isEditable ? false : true}
            style={
              col.isEditable
                ? undefined
                : { background: "#f4f4f4 0% 0% no-repeat padding-box " }
            }
            value={multiSelect}
            name={col?.name}
            onChange={(event) => handleChangeMultipleSelect(event, col)}
            selectedComponent={selectedComponent}
          />
        </FormWrapper>
      );
    case "image":
      if (
        typeof fieldArrQuery[col?.name] === "boolean" ||
        !fieldArrQuery[col?.name]?.includes("blob")
      ) {
        fieldArrQuery[col?.name] = "";
      }
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label
            style={{
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
            className={classes.fieldHeading}
          >
            {col?.title}
            {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          {uploadInPrgrs ? (
            <div>
              <CircularProgress size={30} color="primary" />
            </div>
          ) : (
            <>
              <UploadImage
                edit={col?.isEditable}
                handleImage={handleImage}
                col={col}
                selectedImage={selectedImage}
                uploadError={uploadError}
              />
              {err[col?.name] && (
                <p className={classes.errorMsg}>{`${
                  col?.title ? col?.title : "Field"
                } is required`}</p>
              )}
            </>
          )}
        </FormWrapper>
      );
    case "file":
      if (
        typeof fieldArrQuery[col?.name] === "boolean" ||
        !fieldArrQuery[col?.name]?.includes("blob")
      ) {
        fieldArrQuery[col?.name] = "";
      }
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label
            style={{
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
            className={classes.fieldHeading}
          >
            {col?.title}
            {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          {uploadInPrgrs ? (
            <div>
              <CircularProgress size={30} color="primary" />
            </div>
          ) : (
            <>
              <UploadFile
                handleFile={handleFile}
                col={col}
                selectedFile={selectedFile}
                selectedComponent={selectedComponent}
                uploadError={uploadError}
              />
              {err[col?.name] && (
                <p className={classes.errorMsg}>{`${
                  col?.title ? col?.title : "Field"
                } is required`}</p>
              )}
            </>
          )}
        </FormWrapper>
      );
    case "toggle":
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label
            style={{
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            {col?.title}
          </label>
          <Toggle
            name={col?.name}
            checked={
              typeof fieldArrQuery[col.name] !== "boolean" ||
              typeof fieldArrQuery[col.name] !== "string"
                ? false
                : fieldArrQuery[col.name]
            }
            onChange={(event) => handleChangeQuery(event, col)}
          />
        </FormWrapper>
      );
    case "datepicker":
      return (
        <FormWrapper>
          <label
            className={classes.fieldHeading}
            style={{
              fontWeight: selectedComponent?.style?.bold ? "bold" : undefined,
              fontStyle: selectedComponent?.style?.italic
                ? "italic"
                : undefined,
              textDecoration: selectedComponent?.style?.underline
                ? "underline"
                : undefined,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            {col?.title}
            {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          <DatePicker
            selectedDate={
              fieldArrQuery[col?.name] ? fieldArrQuery[col?.name] : new Date()
            }
            selectedComponent={selectedComponent}
            name={col?.name}
            handleChangeDate={(event) => handleChangeQuery(event, col)}
          />
          {err[col?.name] && (
            <p
              style={{
                fontSize: selectedComponent?.fontSize
                  ? selectedComponent?.fontSize
                  : 16,
              }}
              className={classes.errorMsg}
            >{`${col?.title ? col?.title : "Field"} is required`}</p>
          )}
        </FormWrapper>
      );
    default:
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <Input
            name={col.name}
            label={col.title}
            selectedComponent={selectedComponent}
            disabled={col?.isEditable ? false : true}
            style={
              col.isEditable
                ? undefined
                : { background: "#f4f4f4 0% 0% no-repeat padding-box " }
            }
            placeholder={col.placeholder ? col.placeholder : ""}
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, index)}
          />
        </FormWrapper>
      );
      break;
  }
};
