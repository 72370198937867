import { scroller } from "react-scroll";

export const TabsCommon = (entity, errors, tabbedView) => {
  let tempEntity = [...entity];
  let errorInTab;
  let countSectionErros = {};
  if (Object.keys(errors).length !== 0) {
    for (const key in errors) {
      if (errors[key].isError) {
        if (errorInTab === undefined) {
          if (errors[key].section === undefined) {
            errorInTab = 0;
          } else {
            errorInTab = errors[key].section;
          }
        } else if (errors[key].section < errorInTab) {
          errorInTab = errors[key].section;
        }
        if (tempEntity[errors[key].section] != undefined) {
          tempEntity[errors[key].section]["isError"] = true;
          if (countSectionErros[errors[key].section]) {
            countSectionErros = {
              ...countSectionErros,
              [errors[key].section]: countSectionErros[errors[key].section] + 1,
            };
          } else {
            countSectionErros = {
              ...countSectionErros,
              [errors[key].section]: 1,
            };
          }
        }
      } else {
        if (
          countSectionErros[errors[key].section] < 0 ||
          countSectionErros[errors[key].section] == undefined
        ) {
          if (tempEntity[errors[key].section] !== undefined) {
            tempEntity[errors[key].section]["isError"] = false;
          }
        }
      }
    }
  } else {
    tempEntity.map((entity) => {
      entity.isError = false;
      return entity;
    });
  }

  if (!tabbedView) {
    scroller.scrollTo(errorInTab, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -160,
    });
  }

  return { tempEntity, errorInTab };
};
