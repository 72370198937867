import { CodeEditorAPIService } from "../../services/codeEditorService";
const codeEditorSchemaService = new CodeEditorAPIService();

export const fetchAllFilesAndFolderTree = async (serviceId, versionId) => {
  try {
    const res_data = await codeEditorSchemaService.getFoldersFileTree(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const buildDraftVersion = async (serviceId, versionId) => {
  try {
    const res_data = await codeEditorSchemaService.buildDraftVersion(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const handleRun = async (serviceId, versionId, contName) => {
  try {
    const res_data = await codeEditorSchemaService.handleRun(
      serviceId,
      versionId,
      contName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const handleStop = async (serviceId, versionId, contName) => {
  try {
    const res_data = await codeEditorSchemaService.handleStop(
      serviceId,
      versionId,
      contName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchFileContent = async (fileContentIds) => {
  try {
    const res_data = await codeEditorSchemaService.getFileContent(
      fileContentIds
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _errorcode: err?.response?.data?._errorcode,
    };
  }
};

export const deleteFile = async (serviceId, versionId, fileData) => {
  try {
    const res_data = await codeEditorSchemaService.deleteFile(
      serviceId,
      versionId,
      fileData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const removeLock = async (serviceId, versionId) => {
  try {
    const res_data = await codeEditorSchemaService.removeLock(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const handleDownloadFolder = async (serviceId, versionId) => {
  try {
    const res_data = await codeEditorSchemaService.handleDownloadFolder(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateFileContent = async (serviceId, versionId, fileData) => {
  try {
    const res_data = await codeEditorSchemaService.updateFileContent(
      serviceId,
      versionId,
      fileData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _errorcode: err?.response?.data?._errorcode,
    };
  }
};

export const publishVerison = async (publishContent) => {
  try {
    const res_data = await codeEditorSchemaService.publishVerison(
      publishContent
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const copyVersion = async (copyVersionContent) => {
  try {
    const res_data = await codeEditorSchemaService.copyVersion(
      copyVersionContent
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDraft = async (serviceId, versionId) => {
  try {
    const res_data = await codeEditorSchemaService.deleteDraft(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const createFolderbyId = async (serviceId, versionId, pathData) => {
 
  try {
    const res_data = await codeEditorSchemaService.createFolderbyId(
      serviceId,
      versionId,
      pathData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteFolderById = async (serviceId, versionId, pathData) => {
  try {
    const res_data = await codeEditorSchemaService.deleteFolderById(
      serviceId,
      versionId,
      pathData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const renameFolderById = async (serviceId, versionId, pathData) => {
  try {
    const res_data = await codeEditorSchemaService.renameFolderById(
      serviceId,
      versionId,
      pathData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getContainerLogs = async (imgId) => {
  try {
    const res_data = await codeEditorSchemaService.getContainerLogs(imgId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const lockFileByPath = async (serviceId, versionId, filePath) => {
  try {
    const res_data = await codeEditorSchemaService.lockFile(
      serviceId,
      versionId,
      filePath
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const unLockFileByPaths = async (serviceId, versionId, filePaths) => {
  try {
    const res_data = await codeEditorSchemaService.unLockFile(
      serviceId,
      versionId,
      filePaths
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const fetchFileTreeByFolder = async (fileTreeDetails) => {
  try {
    const res_data = await codeEditorSchemaService.getFilesByFolder(
      fileTreeDetails
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};