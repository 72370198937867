import { AccessMaintananceServices } from "../../services/accessMaintainanceService";

const accessMaintenanceServices = new AccessMaintananceServices()

export const fetchAllResourceTypeListData = async () => {
    try {
        const res_data = await accessMaintenanceServices.getAllResourceTypes();
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const fetchRoleById = async (roleId) => {
    try {
        const res_data = await accessMaintenanceServices.getRoleById(roleId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const addRole = async (roleData) => {
    try {
        const res_data = await accessMaintenanceServices.addRole(roleData);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const editRole = async (roleId, roleData) => {
    try {
        const res_data = await accessMaintenanceServices.editRole(roleId, roleData);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const deleteRole = async (roleId) => {
    try {
        const res_data = await accessMaintenanceServices.deleteRole(roleId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};