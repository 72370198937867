/* eslint-disable */
import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import {
  handleFormValidation,
  handleFormValidationPro,
} from "../../../utils/common";

import { AppButton } from "../../../components/common/ButtonOld";

// files
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageScreenStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import ConfirmationModal from "../../../components/common/modals/ConfirmationModal";

// Redux
import { connect } from "react-redux";
import { addTag } from "../../../redux/actions/dataStandardAction";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

const CreateGlossary = (props) => {
  const { addTag } = props;

  const styles = manageScreenStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // Input States
  const [name, setName] = useState("");
  const [bKey, setBKey] = useState("");
  const [bSource, setBSource] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);

  const { checkScreenPermission } = useCheckPermission()

  useEffect(() => {
    checkScreenPermission('GlossaryTag', ['Create'])
  }, [])

  const [error, setError] = useState({
    name: false,
    key: false,
    // description: false,
  });

  const addAnother = true;
  // Confirmation Modal State
  const [openConfirmBox, setOpenConfirmBox] = useState(false);

  const handleSubmit = async (e, addAnother) => {
    e.preventDefault();
    const createGlossaryData = {
      name: name,
      key: bKey,
      source: bSource,
      description: description,
    };
    const { formIsValid, errors } = handleFormValidation(
      createGlossaryData,
      error
    );
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status } = await addTag(createGlossaryData, history);
      setLoading(false);
      if (_status === 201) {
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        setLoading(false);
        if (addAnother) {
          history.push("/glossary");
          history.push("/create-glossary");
        } else {
          history.push("/glossary");
        }
      } else if (_status === 409) {
        enqueueSnackbar(
          _msg ? _msg : "An entry with the same name already exists.",
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(
          _msg ? _msg : "Something went wrong. Please contact Help desk.",
          {
            variant: "error",
          }
        );
      }
    } else {
     
      setError(errors);
    }
  };

  return (
    <main className={styles.main}>
      {loading && <Loader />}
      <ConfirmationModal
        open={openConfirmBox}
        handleClose={() => setOpenConfirmBox(false)}
        handleSubmit={() => history.push("/glossary")}
      />
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Glossary", path: "/glossary" },
            { title: "Create Glossary", path: "" },
          ]}
        />
      </div>
      <div style={{ top: "30px" }} className={styles.wrapper}>
        <div className={styles.box} style={{ display: "block" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <h2 className={styles.title}>
              Create Glossary
            </h2>
            <div className={`${styles.submitbuttonwrapper}`}>
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                disabled={false}
                style={{ minWidth: "99px", marginLeft: "15px" }}
                className={`${styles.capitalizeBtn} btnsmall`}
                onClick={() => {
                  if (name || bKey || bSource || description) {
                    setOpenConfirmBox(true);
                  } else {
                    history.push("/glossary");
                  }
                }}
              />
              <AppButton
                type="submit"
                style={{ minWidth: "99px", marginLeft: "15px" }}
                className={`${styles.createAddAnotherBtn} btnsmall`}
                buttonName="Create and Add Another"
                variant="outlined"
                // disabled={disabled}
                onClick={(e) => handleSubmit(e, addAnother)}
              />
              <AppButton
                type="submit"
                buttonName="Create"
                variant="contained"
                style={{ minWidth: "99px", marginLeft: "15px" }}
                className="btnsmall"
                // disabled={disabled}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </Grid>
          <hr className={styles.glossaryHr} />
          <Grid container spacing={3} style={{ paddingLeft: 18, marginTop: -5 }}>
            <Grid
              component="form"
              xs={7}
              spacing={3}
              container
              style={{ marginTop: 0 }}
            >
              <Grid item xs={4} sm={6} style={{ position: "relative" }}>
                <label className={styles.fieldHeading}>
                  Glossary Name <span className={styles.red}>*</span>
                </label>
                <input
                  autoFocus
                  type="text"
                  className={`${styles.input} ${error.name && styles.error}`}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError({ ...error, name: false })
                  }}
                  placeholder={"Enter glossary name"}
                  maxLength={30}
                />
                {error.name && (
                  <p className={styles.errorMsg}>{"Name is required"}</p>
                )}
              </Grid>
              <Grid item xs={4} sm={6} style={{ position: "relative" }}>
                <label className={styles.fieldHeading}>
                  Business Key <span className={styles.red}>*</span>
                </label>
                <input
                  className={`${styles.input} ${error.key && styles.error}`}
                  onChange={(e) => {
                    setBKey(e.target.value);
                    setError({ ...error, key: false })
                  }}
                  placeholder={"Enter business key"}
                  maxLength={30}
                />
                {error.key && (
                  <p className={styles.errorMsg}>
                    {"Business Key is required"}
                  </p>
                )}
              </Grid>
              <Grid item xs={4} sm={6}>
                <label className={styles.fieldHeading}>Business Source</label>
                <input
                  className={`${styles.input} ${error.source && styles.error}`}
                  onChange={(e) => {
                    setBSource(e.target.value)
                  }}
                  placeholder={"Enter business source"}
                  maxLength={60}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: "auto" }}>
              <label className={styles.fieldHeading}>
                Glossary Description 
                {/* <span className={styles.red}>*</span> */}
              </label>
              <textarea
                // className={`${styles.textarea} ${error.description && styles.error }`}
                className={`${styles.textarea}`}
                onChange={(e) => {
                  setDescription(e.target.value);
                  // setError({...error, description: false})
                }}
                style={{ resize: "vertical", maxHeight: "300px" }}
                placeholder={"Enter field description"}
                maxLength={300}
              />
              {/* {error.description && (
                <p className={styles.errorMsg}>{"Description is required"}</p>
              )} */}
            </Grid>
          </Grid>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addTag,
};

const mapStateToProps = (state) => {
  const { tags } = state.dataStandardReducer;
  return { tags };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGlossary);
