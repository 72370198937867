import { Grid, TextField, MenuItem, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ErrorStyles from "../../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import useTriggers from "../../../../hooks/useTriggers";

// removing selectProps temporarily
const Select = (props) => {
  const { selectedComponent, label, placeHolder } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      "& label.Mui-focused": {
        color: selectedComponent?.status,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: selectedComponent?.status,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: selectedComponent?.status,
        },
        "&:hover fieldset": {
          borderColor: selectedComponent?.status,
        },
        // '&.Mui-focused fieldset': {
        //   borderColor: selectedComponent?.status,
        // },
      },
    },
  }));
  const styles = useStyles();
  const classes = ErrorStyles();
  const { handleTrigger } = useTriggers();

  return (
    <Grid
      container
      direction={
        props?.selectedComponent?.direction === false ? "row" : "column"
      }
      spacing={1}
      onClick={() => {
        if (selectedComponent?.["onClick"]) {
          handleTrigger({
            action: selectedComponent?.["onClick"],
            isUpdateState: true,
            componentId: selectedComponent?.id,
            data: [],
          });
        }
      }}
    >
      {label && (
        <Grid
          item
          container
          xs={props?.selectedComponent?.direction === false ? 3 : 12}
          justifyContent={
            props?.selectedComponent?.alignment === false
              ? "flex-start"
              : "flex-end"
          }
          alignItems="center"
        >
          <Grid
            item
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: selectedComponent?.style?.bold ? "bold" : undefined,
              fontStyle: selectedComponent?.style?.italic
                ? "italic"
                : undefined,
              textDecoration: selectedComponent?.style?.underline
                ? "underline"
                : undefined,
              fontFamily: selectedComponent?.fontFamily,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            {label}
            {props?.selectedComponent?.isRequired === true && (
              <span className={classes.red}>*</span>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={props?.selectedComponent?.direction === false ? 9 : 12}>
        <TextField
          select
          fullWidth
          size="small"
          error={props?.error}
          label={placeHolder?.length > 0 ? placeHolder : undefined}
          disabled={props?.disabled}
          name={props?.name}
          value={props?.value}
          onChange={props?.onChange}
          variant="outlined"
          InputProps={{
            style: {
              width: props.width,
              height: "38px",
              padding: "0px",
              background: "#F4F4F4 0% 0% no-repeat padding-box !important",
              fontWeight: 400,
              outline: "none",
              borderColor: "red",
              fontFamily: selectedComponent?.fontFamily,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            },
          }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              transformOrigin: { vertical: "top", horizontal: "center" },
              // getContentAnchorEl: null,
            },
          }}
          InputLabelProps={{
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              color: props?.selectedComponent?.status,
              marginTop: 5,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            },
          }}
          className={styles.select}
          classes={{ root: styles.root }}
        >
          {props?.arr?.map((option, index) => (
            <MenuItem
              style={{
                fontSize: selectedComponent?.fontSize
                  ? selectedComponent?.fontSize
                  : 16,
              }}
              key={index}
              value={option.name}
            >
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default Select;
