import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import devExpressStyles from "../styles/DevExpressTableStyles";
import { deleteTemplateByID_ } from "../../../utils/orchestration/orchestrationUtils";
import { removeMicroserviceFromApplication_ } from "../../../utils/orchestration/applicationUtils";
import {
  deleteTemplateByID,
  deleteMicroserviceFromApplication,
} from "../../../redux/actions/applicationAction";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import CloudConfigurationModal from "../modals/CloudConfigurationModal";

const ProcessActionCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { configuartionname } = restProps.row;
  const { handleEditCloudConfig } = restProps;
  const [cloudConfigModal, setCloudConfigModal] = useState(false);

  const handleDeleteTemplate = async (templateid) => {
    if (isEditMode) {
      const { msg, status } = await deleteTemplateByID_(templateid);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteTemplateByID(templateid));
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <CloudConfigurationModal
        openModal={cloudConfigModal}
        // handleSubmitCloudData={handleSubmitCloudData}
        setOpenModal={(e) => setCloudConfigModal(e)}
      />

      <div style={{ left: -20, position: "relative" }}>
        <Tooltip title="Edit Config">
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={() => setCloudConfigModal(true)}
          >
            <img src="/images/editLine.svg" alt="icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Config">
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => handleDeleteTemplate(templateid)}
          >
            <img src="/images/deleteLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};
const EndPoint = ({ ...restProps }) => {
  const { enqueueSnackbar } = useSnackbar();

  const port = restProps?.row?.Port;
  return restProps?.row?.serdetails?.status?.loadBalancer?.ingress ? (
    <>
      <Tooltip
        title={`http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`}
        placement="bottom-start"
      >
        <a
          href={`http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`}
          target="_blank"
        >
          http://
          {
            restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
              ?.hostname
          }
          :{port}
        </a>
      </Tooltip>{" "}
      &nbsp;
      <img
        src="/images/ContentCopyIcon.svg"
        alt="card"
        onClick={() => {
          navigator.clipboard.writeText(
            `http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`
          );
          enqueueSnackbar("Copied", { variant: "success" });
        }}
      />
    </>
  ) : (
    ""
  );
};

// const CopyButton = ({ ...restProps }) => {
//   const { enqueueSnackbar } = useSnackbar();

//   const port = restProps?.row?.Port;
//   return restProps?.row?.serdetails?.status?.loadBalancer?.ingress ? (
//     <>
//       <img
//         src="/images/ContentCopyIcon.svg"
//         alt="card"
//         onClick={() => {
//           navigator.clipboard.writeText(
//             `http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`
//           );
//           enqueueSnackbar("Copied", { variant: "success" });
//         }}
//       />
//     </>
//   ) : (
//     ""
//   );
// };

const Cell = (props) => {
  const { column } = props;
  if (column.name === "action") {
    return <ProcessActionCell {...props} />;
  } else if (column.name === "endpoint") {
    return (
      <VirtualTable.Cell {...props}>
        <EndPoint {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} />;
  }
};

const NoDataFound = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        textAlign: "center",
        top: "calc(50% - 4rem)",
        left: "calc(50% - 5rem)",
        maxWidth: "11rem",
      }}
    >
      <img src="/images/notFound.svg" alt="notfound" />
      <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
    </div>
  );
};

const TestSelectedDeploymentTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [defaultHiddenColumnNames] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.height ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />
          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 560
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            columnExtensions={[
              { columnName: "action", togglingEnabled: false },
            ]}
          />
          <Toolbar />
          <ColumnChooser />
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default TestSelectedDeploymentTable;
