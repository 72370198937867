import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../../components/common/stuff/Loader";
import DeploymentContainerTablePreview from "../../../components/Deployment/Tables/DeploymentContainerTablePreview";
import DeploymentProcessPreview from "../../../components/Deployment/Tables/DeploymentProcessPreview";
import { addDeployContainer } from "../../../redux/actions/deploymentProcessAction";
import { fetchVersionById } from "../../../utils/project/microserviceUtils";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";

const PreviewList = ({ container, process, addDeployContainer }) => {
  const [isLoader, setIsLoader] = useState();
  const styles = manageScreenStyles();
  const [isContainer, setIsContainer] = useState();
  const [isProcess, setIsProcess] = useState();

  // useEffect(() => {
  //   const filterContainer = container.filter((item) => item.select)
  //   const filterProcess = process.filter((item) => item.select)
  //   setIsContainer(
  //     filterContainer
  //   );
  //   setIsProcess(
  //     filterProcess
  //   );
  // }, [container, process]);

  useEffect(() => {
    setIsLoader(true);
    const getContainer = async () => {
      const serviceId = "61c406b38e7b5b509c79b020";
      const versionId = "61c406c98e7b5b509c79b021";
      const { _msg, _status, data } = await fetchVersionById(
        serviceId,
        versionId
      );
      if (_status === 200) {
        setIsLoader(false);
        addDeployContainer(data?.docker.Instances);
      }
      setIsLoader(false);
    };

    getContainer();
  }, []);

  return <div>{isLoader && <Loader />}</div>;
};

const mapDispatchToProps = {
  addDeployContainer,
};

const mapStateToProps = (state) => {
  const { container, process } =
    state.deploymentProcessReducer.dependenciesData;
  return {
    container,
    process,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewList);
