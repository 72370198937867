const newServiceGroupColumns = [
  // { name: 'id', title: 'Service Group ID' },
  { name: "groupName", title: "Group Name", for: "newservicegroup" },
  // { name: "createdby", title: "Created By", for: "newservicegroup" },
  { name: "createdon", title: "Created On", for: "newservicegroup" },
  //   { name: "assignedTo", title: "Assign To", for: "servicegroup" },
  { name: "status", title: "Status", for: "newservicegroup" },
  // {
  //   name: "action",
  //   title: "Action",
  //   route: "/servicegroups/edit",
  //   for: "newservicegroup",
  // },
];

const newServiceGroupDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "groupName", weightage: 2.5 },
  // { columnName: "createdby", weightage: 2 },
  { columnName: "createdon", weightage: 4 },
  { columnName: "status", weightage: 4 },
  // { columnName: "action", weightage: 2.5 },
];

const newServiceGroupTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "groupName", align: "left" },
  // { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  //   { columnName: "assignedTo", align: "center" },
  { columnName: "status", align: "left" },
  // { columnName: "action", align: "center", sortingEnabled: false },
];

const newServiceGroupColumnOrder = [
  // 'id',
  "groupName",
  // "createdby",
  "createdon",
  //   "assignedTo",
  "status",
  // "action",
];

const newServiceGroupDefaultSorting = [];

export {
  newServiceGroupColumns,
  newServiceGroupDefaultColumnWidths,
  newServiceGroupTableColumnExtensions,
  newServiceGroupColumnOrder,
  newServiceGroupDefaultSorting,
};
