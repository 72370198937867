import {
  ADD_TEST_SCRIPT_LIST,
  DELETE_SCRIPT_BY_ID,
  SCRIPT_FILTERS,
  ALTER_SUBSCRIBE_SCRIPT_BY_ID,
  ALTER_FAVOURITE_SCRIPT_BY_ID,
  SCRIPT_HIDDEN
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  testScriptList: {
    data: [],
    error: false,
    loading: false,
  },
  testScriptFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  testScriptSort: [{ columnName: "createdon", direction: "desc" }],
  testScriptHidden: ["name", "type", "createdby", "createdon", "action"],
  loading: false,
};

const testScriptReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEST_SCRIPT_LIST: {
      return {
        ...state,
        testScriptList: {
          ...state.testScriptList,
          ...action.payload,
          deleteScriptStatus: false,
        },
      };
    }
    case SCRIPT_HIDDEN: {
      return {
          ...state,
          testScriptHidden: [
              ...action.payload
          ]
      }
  }

    case DELETE_SCRIPT_BY_ID: {
      return {
        ...state,
        testScriptList: {
          ...state.testScriptList,
          data: state.testScriptList.data.filter(
            (serv) => serv._id !== action.payload.ScriptId
          ),
          _totalcount: state.testScriptList._totalcount - 1,
          deleteScriptStatus: true,

        },
      };
    }

    case SCRIPT_FILTERS: {
      return {
        ...state,
        testScriptFilters: [...action.payload],
      };
    }

    case ALTER_SUBSCRIBE_SCRIPT_BY_ID: {
      return {
        ...state,
        testScriptList: {
          ...state.testScriptList,
          data:
            state.testScriptList.data.length !== 0 &&
            state.testScriptList.data.map((proj) => {
              if (proj._id === action.payload.ScriptId) {
                return { ...proj, isSubscribe: !proj.isSubscribe };
              } else {
                return proj;
              }
            }),
        },
      };
    }

    case ALTER_FAVOURITE_SCRIPT_BY_ID: {
      return {
        ...state,
        testScriptList: {
          ...state.testScriptList,
          data:
            state.testScriptList.data.length !== 0 &&
            state.testScriptList.data.map((serv) => {
              if (serv._id === action.payload.ScriptId) {
                return { ...serv, isFavourite: !serv.isFavourite };
              } else {
                return serv;
              }
            }),
        },
      };
    }

    default:
      return state;
  }
};

export default testScriptReducer;
