import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import layoutStyles from "../../../styles/commonStyles/Layout";
import { useDispatch } from "react-redux";
import {
  setActionOpen,
  setGlobalCss,
} from "../../../../redux/actions/uiApplicationAction";
import { useState } from "react";
import GlobalCssModal from "../../atoms/customModal/GlobalCssModal";
import { useEffect } from "react";
import useGetData from "../../../hooks/useGetData";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";

const Sidemenu = (props) => {
  const styles = layoutStyles();
  const dispatch = useDispatch();
  const { currentProject } = useGetData();
  const [globalCssModal, setGlobalCssModal] = useState(false);
  const theme = useTheme();

  const [css, setCss] = useState({
    fontFamily: "Nunito",
    fontSize: 10,
    fontStyle: {
      bold: false,
      italic: false,
      underline: false,
    },
    color: theme.palette.primary.main,
  });

  useEffect(() => {
    if (JSON.stringify(currentProject?.globalCss) !== "{}")
      setCss(currentProject?.globalCss);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTab = (tab) => {
    props.setActiveTab(tab);
    if (currentProject.actionOpen)
      dispatch(setActionOpen({ actionOpen: false }));
  };

  return (
    <div className={styles.sidemenu}>
      <Tooltip title="Pages Tab">
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => changeTab("pages")}
        >
          <img
            src={`/img/${
              props.activeTab !== "pages" ? "public_img_" : ""
            }file-line.svg`}
            alt="Files"
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Components Tab">
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => changeTab("components")}
        >
          <img
            src={`/img/components${
              props.activeTab !== "components" ? "2" : ""
            }.svg`}
            alt="Tools"
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Global Css Tab">
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => setGlobalCssModal(true)}
        >
          <PaletteOutlinedIcon />
        </IconButton>
      </Tooltip>
      {globalCssModal && (
        <GlobalCssModal
          open={globalCssModal}
          css={css}
          setCss={setCss}
          handleClose={() => setGlobalCssModal(false)}
          handleSave={() => {
            dispatch(setGlobalCss(css));
            setGlobalCssModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Sidemenu;
