import React, { useState } from "react";
import Select from "../../atoms/dropdown/select/Select";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";

const SelectComponent = ({ id }) => {
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const { handleTrigger } = useTriggers();
  const { currentComponent, currentPage, getComponent } = useGetData();
  const selectedComponent = getComponent(id);

  const handleChange = (event) => {
    let components = [];
    if (selectedComponent?.tabValue === false) {
      components = currentPage.components.map((component) => {
        if (component.id === selectedComponent.id) {
          return {
            ...component,
            accessValueData: {
              ...component?.accessValueData,
              dataValue: event.target.value,
              dataInput: event.target.value,
            },
          };
        } else {
          return component;
        }
      });
    } else {
      components = currentPage.components.map((component) => {
        if (component.id === selectedComponent.id) {
          return {
            ...component,
            jsValueData: {
              ...component?.jsValueData,
              dataValue: event.target.value,
            },
          };
        } else {
          return component;
        }
      });
    }
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  let modifiedArray = [];
  if (
    Array.isArray(selectedComponent?.data?.dataValue) &&
    selectedComponent?.tabValue === true
  ) {
    modifiedArray = selectedComponent?.data?.dataValue?.map((v) => {
      return {
        title: v.title,
        name: v.value,
      };
    });
  } else {
    modifiedArray = selectedComponent?.options;
  }

  const placeHolderText = selectedComponent?.placeHolder?.dataValue;
  const labelText = selectedComponent?.label?.dataValue;
  if (flag === true) {
    handleTrigger({
      action: selectedComponent?.["onChange"],
      isUpdateState: true,
      componentId: selectedComponent?.id,
      data: selectedComponent?.accessValueData?.dataValue,
    });
    setFlag(false);
  }
  return (
    <div
      style={{
        padding: "5px 10px 0px 10px",
        fontSize: selectedComponent?.fontSize
          ? selectedComponent?.fontSize
          : 16,
      }}
    >
      <Select
        arr={modifiedArray}
        disabled={selectedComponent?.disabled?.dataValue === "true"}
        width="auto"
        label={labelText && typeof labelText === "string" ? labelText : "Label"}
        name={"selectedValue"}
        value={
          selectedComponent?.tabValue === true
            ? selectedComponent?.jsValueData?.dataValue
            : selectedComponent?.accessValueData?.dataValue
        }
        selectedComponent={selectedComponent}
        onChange={(event) => {
          event.stopPropagation();
          handleChange(event);
          if (selectedComponent["onChange"]) {
            setFlag(true);
          }
        }}
        placeHolder={
          placeHolderText && typeof placeHolderText === "string"
            ? placeHolderText
            : ""
        }
        isRequired={selectedComponent?.isRequired}
      />
    </div>
  );
};

export default SelectComponent;
