const deploymentColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "deploymentname", title: "Name", for: "deployment" },
  { name: "clustername", title: "Cluster", for: "deployment" },
  { name: "Status", title: "Status", for: "deployment" },
  { name: "createdby", title: "Hosted By", for: "deployment" },
  { name: "createdon", title: "Hosted On", for: "deployment" },
  { name: "action", title: "Action", route: "/host/edit", for: "deployment" },
];

const deploymentDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "deploymentname", weightage: 2.5, width: 200 },
  { columnName: "clustername", weightage: 2.5, width: 200 },
  { columnName: "Status", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const deploymentTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "deploymentname", align: "left" },
  { columnName: "clustername", align: "left" },
  { columnName: "Status", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const deploymentColumnOrder = [
  // 'id',
  "deploymentname",
  "clustername",
  "Status",
  "createdby",
  "createdon",
  "action",
];

const deploymentColumnHidden = [
  // 'id',
  "deploymentname",
  "clustername",
  "Status",
  "createdby",
  "action",
];

const deploymentDefaultSorting = [];

export {
  deploymentColumns,
  deploymentDefaultColumnWidths,
  deploymentTableColumnExtensions,
  deploymentColumnOrder,
  deploymentDefaultSorting,
  deploymentColumnHidden,
};
