import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppButton } from "../../atoms/button/AppButton";
import Styles from "../../../styles/commonStyles/PagesModel";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const PagesModel = (props) => {
  const classes = Styles();
  const history = useHistory();

  const [pages, setpages] = useState([]);

  useEffect(() => {
    if (props?.pageData) {
      try {
        setpages(props?.pageData ? props?.pageData : []);
      } catch (error) {
        // console.log("parse Error", error);
      }
    }
  }, [props?.pageData]);

  return (
      <Dialog onClose={props.handleClose} open={props.open} maxWidth="md">
        <DialogTitle>{props?.uiAppName}</DialogTitle>
        <DialogContent dividers>
          <div className={classes.modelBody}>
            <span>{pages?.length} Pages</span>
            <div>
              <TableContainer component={Paper} className={classes.pagesTable}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Page</TableCell>
                      <TableCell>Page URL</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(pages) &&
                      pages.length > 0 &&
                      pages?.map((page) => (
                        <TableRow key={page?.name}>
                          <TableCell>{page?.name}</TableCell>
                          <TableCell>{page?.url}</TableCell>
                          <TableCell>
                            <div style={{ cursor: "pointer" }}>
                              <img
                                src="/img/gotoCanvas-ui-app-list.svg"
                                alt="gotoCanvas"
                                onClick={() =>
                                  history.push(
                                    `/ui-applications/${props?.uiAppId}/${page?.url}`
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            buttonName="Done"
            variant="contained"
            color="primary"
            className="btnsmall"
            onClick={props.handleClose}
          />
        </DialogActions>
      </Dialog>
  );
};

export default PagesModel;
