import React from 'react';
import { makeStyles, IconButton } from "@material-ui/core"
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
} from 'react-flow-renderer';
import { connect } from "react-redux";
import { useSnackbar } from "notistack"
import { replaceElementList } from "../../../redux/actions/orchestrationAction"
import { deleteRelation_ } from "../../../utils/orchestration/orchestrationUtils"

import "../../../screens/Orchestration/orchestration.css"
import { DeleteModal } from '../../common/modals/DeleteModal';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    icon: {
        borderWidth: 0
    },
    edgeBody: {
        position: "fixed"
    },
    edgeButton: {
        zIndex: 100
    },
    container: {
    }
}))

const foreignObjectSize = 40;

function ButtonEdge(props) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        arrowHeadType = "arrowclosed",
        markerEndId,
    } = props;
    const { elements, templateid } = props;
    const styles = useStyles();
    const [deleteModal, setDeleteModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    // console.log("marker end",markerEnd)
    // console.log("Edge  path", edgePath);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const onEdgeClick = async (id) => {
        const edgeData = elements && elements.find((item) => item.id === id);
        const { source, target } = edgeData;
        const req_data = {
            nodeid: target
        }
        const { _msg, _status } = await deleteRelation_(templateid, source, req_data);
        if (_status === 200) {
            var newElementList = elements.filter((item) => item.id !== id);
            props.replaceElementList(newElementList);
            enqueueSnackbar(_msg, { variant: "success" });
        } else {
            enqueueSnackbar(_msg, { variant: "error" })
        }
        // alert(`remove ${id}`);
    };
    return (
        <>
            <path
                id={id}
                style={{ markerWidth: 40, markerHeight: 50 }}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className={styles.icon}
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body className={styles.edgeBody}>
                    <IconButton
                        className={styles.edgeButton}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModal(true)
                        }}
                    >
                        <img src="/images/deleteLine.svg" alt="delete" height={15} width={15} />
                    </IconButton>
                </body>
            </foreignObject>
            {deleteModal && (
                <DeleteModal
                    name={"connector"}
                    open={deleteModal}
                    handleClose={() => setDeleteModal(false)}
                    handleDelete={() => onEdgeClick(id)}
                />
            )}
        </>
    );
}

const mapDispatchToProps = {
    replaceElementList
}

const mapStateToProps = (state) => {
    const { elements, process_data } = state.orchestration;
    const { templateid } = process_data;
    return {
        elements: elements,
        templateid: templateid,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ButtonEdge)