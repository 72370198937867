import React from "react";
import Dropdown from "../../../atoms/dropdown/Dropdown";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import TrueFalseButton2 from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton2";
import useGetData from "../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";

function TextAppearances() {
  const { currentPage, currentComponent, NormalHandleChange } = useGetData();
  const dispatch = useDispatch();
  const textTypeOptions = [
    {
      name: "subtitle",
      value: "subtitle",
    },
    {
      name: "paragraph",
      value: "paragraph",
    },
    {
      name: "label",
      value: "label",
    },
  ];

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: value ? value : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name={"fontSize"}
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <label>Type</label>
        <Dropdown
          width="100%"
          value={currentComponent?.txtType}
          onChange={(e) => handleChange("txtType", e.target.value)}
          arr={textTypeOptions}
          disabled={false}
          label=""
          name="txtType"
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <TrueFalseButton
          name="direction"
          leftButton="Horizontal"
          rightButton="Vertical"
          value={!currentComponent?.direction}
        />
      </div>
      <div className="field">
        <TrueFalseButton2
          name="alignment"
          value={currentComponent?.alignment}
        />
      </div>
      <div className="field">
        <CustomColorPicker name="status" />
      </div>
    </div>
  );
}

export default TextAppearances;
