import React, { useState, useEffect } from "react";
import {
  Grid,
  AccordionDetails,
  Typography,
  AccordionSummary,
  Accordion,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/ButtonOld";

import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { handleFormValidationPro, initError } from "../../../utils/common";

import { fetchObjectByID_ } from "../../../utils/ontology/ontologyUtils";

// Redux
import { connect } from "react-redux";
import { postField } from "../../../redux/actions/dataStandardAction";

// Entity Schema
// import glossaryCreateEntity from "../EntitySchemaGlossaryField";
import CustomFieldGroupsForGlossaryFieldEdit from "../../../components/common/entitySchema/dataStandards/CustomFieldGroupsForGlossaryFieldEdit";
import { ArrowDropDownRounded } from "@material-ui/icons";

import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  getFieldById,
  editFieldById,
} from "../../../utils/dataStandards/fieldsUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";

const EditField = (props) => {
  const { postField, fields } = props;
  const addAnother = true;
  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialMount, setInitialMount] = useState(true);
  const [initialFieldName, setInitialFieldName] = useState("");
  const [isClear, setIsClear] = useState(false);
  const [glossaryCreateEntity, setGlossaryCreateEntity] = useState({});

  const [fieldData, setFieldData] = useState({
    name: "",
    type: "",
    description: "",
    rules: {
      maxLength: "",
      minLength: "",
      pattern: "",
      contentEncoding: "",
      contentMediaType: "",
      minimum: "",
      maximum: "",
      multipleOf: "",
      exclusiveMinimum: "",
      exclusiveMaximum: "",
    },
  });
  const [error, setError] = useState({
    name: { isError: false },
    description: { isError: false },
    type: { isError: false },
    rules: {
      minLength: { isError: false },
      maxLength: { isError: false },
      pattern: { isError: false },
      contentEncoding: { isError: false },
      contentMediaType: { isError: false },
      minimum: { isError: false },
      maximum: { isError: false },
      multipleOf: { isError: false },
      exclusiveMinimum: { isError: false },
      exclusiveMaximum: { isError: false },
    },
  });
  const [isTwoTypeFields, setIsTwoTypeFields] = useState(0);
  var flag = 0;

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(async () => {
    checkScreenPermission("GlossaryField", ["Edit"]);
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryField",
        "edit"
      );
      if (_status === 200) {
        setGlossaryCreateEntity(data);
        if (data) {
          for (
            let i = 0;
            i < data.sections[0].fieldgroups[0].fields.length;
            i++
          ) {
            if (data.sections[0].fieldgroups[0].fields[i].datakey === "type") {
              flag++;
            }
          }

          setIsTwoTypeFields(flag);
        }
        if (data) {
          const projData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.uiElementType === "Currency") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.datakey) {
                  return (projData[field.datakey] = "");
                }
              });
            });
          });

          setFieldData(projData);
          const errData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  if (field.uiElementType === "Currency") {
                    errData[`${field.datakey}_unit`] = initError(field);
                    errData[`${field.datakey}_value`] = initError(field);
                    return true;
                  }
                  if (field.uiElementType === "Amount") {
                    errData[`${field.datakey}_unit`] = initError(field);
                    errData[`${field.datakey}_value`] = initError(field);
                    return true;
                  }
                  return (errData[field.datakey] = initError(field));
                }
              });
            });
          });
          setError(errData);
          await getFieldDetails();
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    await fetchObjectDetails();
  }, []);

  // useEffect(() => {
  //   if (isTwoTypeFields === 1) {
  //
  //     enqueueSnackbar(
  //       "Field Type 'TYPE' is not present in Ontology Field. Defaulting to text Field Type.",
  //       { variant: "warning", autoHideDuration: 9000 }
  //     );
  //   }
  // }, [isTwoTypeFields]);

  useEffect(() => {
    let clearFlag = false;
    for (const [key, value] of Object.entries(fieldData.rules)) {
      if (value) clearFlag = true;
    }
    clearFlag ? setIsClear(true) : setIsClear(false);
  }, [fieldData]);

  useEffect(() => {
    if (!initialMount) clearRulesHandler();
    if (error.type) setError({ ...error, type: false });
  }, [fieldData.type]);

  // useEffect(() => {
  //   if (fieldData.rules.pattern) {
  //     setError({
  //       ...error,
  //       rules: {
  //         ...error.rules,
  //         pattern: fieldData.rules.pattern.length < 3 ? true : false,
  //       },
  //     });
  //   }
  // }, [fieldData.rules.pattern]);

  const getFieldDetails = async () => {
    const { _msg, _status, data } = await getFieldById(_id);
    if (_status === 200) {
      setFieldData({ ...fieldData, ...data });
      setInitialFieldName(data.name);
      setLoading(false);
      setInitialMount(false);
    } else {
      setLoading(false);
      if (_msg === "Invalid Json") {
        if (data) {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (Object.keys(error).includes(name)) {
      setError({
        ...error,
        [name]: {
          ...error[name],
          isError: false,
          msg: "",
        },
      });
    }
    setFieldData({
      ...fieldData,
      [name]: value,
    });
  };

  const clearRulesHandler = () => {
    setFieldData({
      ...fieldData,
      rules: {
        maxLength: "",
        minLength: "",
        pattern: "",
        contentEncoding: "",
        contentMediaType: "",
        minimum: "",
        maximum: "",
        multipleOf: "",
        exclusiveMinimum: "",
        exclusiveMaximum: "",
      },
    });
    setError({
      ...error,
      rules: {
        minLength: false,
        maxLength: false,
        pattern: false,
        contentEncoding: false,
        contentMediaType: false,
        minimum: false,
        maximum: false,
        multipleOf: false,
        exclusiveMinimum: false,
        exclusiveMaximum: false,
      },
    });
  };

  const handleSubmit = async (e, addAnother) => {
    let rulesInstance = {},
      flag = 0;
    for (const [key, value] of Object.entries(fieldData.rules)) {
      if (value) {
        rulesInstance[key] = value;
        flag = 1;
      }
    }
    const fieldDataInstance =
      flag === 0
        ? {
            name: fieldData.name,
            description: fieldData.description,
            status: fieldData.status,
            type: fieldData.type,
            createdby: fieldData?.createdby,
            updatedby: fieldData?.updatedby ? fieldData.updatedby : "updatedby",
          }
        : {
            name: fieldData.name,
            description: fieldData.description,
            rules: rulesInstance,
            status: fieldData.status,
            type: fieldData.type,
            createdby: fieldData?.createdby,
            updatedby: fieldData?.updatedby ? fieldData.updatedby : "updatedby",
          };
    const { formIsValid, errors } = handleFormValidationPro(
      fieldDataInstance,
      error
    );
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status, data } = await editFieldById(
        _id,
        fieldDataInstance
      );
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        setLoading(false);
        // history.push("/data-standards?datastandards=true");
        history.push(`/details-field/${_id}`);
      } else {
        setLoading(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            data
              ? data[0]
                ? data[0]
                : "Something went wrong. Please contact Help desk."
              : _msg
              ? _msg
              : "Something went wrong. Please contact Help desk.",
            {
              variant: "error",
            }
          );
        }
      }
    } else {
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });

      setError(errors);
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Data Dictionary",
              path: "/data-standards?datastandards=true",
            },
            { title: `Edit ${initialFieldName}` },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{`Edit ${initialFieldName}`}</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginTop: "26px",
            }}
          >
            <AppButton
              className={`btnsmall`}
              style={{
                padding: "6px 10px",
                minWidth: "99px",
              }}
              variant="contained"
              onClick={handleSubmit}
              buttonName="Save"
              disabled={!checkCompPermission("GlossaryField", ["Edit"])}
            />
            <AppButton
              style={{
                padding: "6px 10px",
                minWidth: "99px",
                marginLeft: "10px",
              }}
              className={`btnsmall`}
              variant="outlined"
              onClick={() => history.push("/data-standards?datastandards=true")}
              buttonName="Cancel"
            />
          </div>
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {/* With Entity Schema */}
        {glossaryCreateEntity?.sections &&
          glossaryCreateEntity?.sections.map((section) => {
            if (section.name === "Glossary_Field_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldEdit
                    section={section}
                    isEditMode={true}
                    fieldData={fieldData}
                    error={error}
                    isClear={isClear}
                    isTwoTypeFields={isTwoTypeFields}
                    clearRulesHandler={clearRulesHandler}
                    setFieldData={setFieldData}
                    handleChange={handleChange}
                  />
                  <Grid
                    container
                    // className={styles.editDetails}
                    style={{ marginTop: "27px" }}
                    spacing={3}
                  >
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Created On</label>
                      <input
                        value={moment
                          .unix(fieldData.createdon)
                          .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Created On :&nbsp;</label>
                      <span>
                        {moment
                          .unix(fieldData.createdon)
                          .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                      </span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Created By</label>
                      <input
                        value={fieldData.createdby || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Created By :&nbsp;</label>
                      <span>{fieldData.createdby || " "}</span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Updated On</label>
                      <input
                        value={fieldData.updatedon
                          ? moment
                              .unix(fieldData.updatedon)
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Updated On :&nbsp;</label>
                      <span>
                        {" "}
                        {fieldData.updatedon
                          ? moment
                              .unix(fieldData.updatedon)
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : " "}
                      </span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Updated By</label>
                      <input
                        value={fieldData.updatedby || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Updated By :&nbsp;</label>
                      <span>{fieldData.updatedby || " "}</span> */}
                    </Grid>
                  </Grid>
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldEdit
                      section={section}
                      isEditMode={true}
                      fieldData={fieldData}
                      isClear={isClear}
                      clearRulesHandler={clearRulesHandler}
                      error={error}
                      setFieldData={setFieldData}
                      handleChange={handleChange}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  postField,
};

const mapStateToProps = (state) => {
  const { fields } = state.dataStandardReducer;
  return { fields };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditField);
