import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

const Dropdown = (props) => {
  return (
    <TextField
      select
      fullWidth
      size="small"
      disabled={props?.disabled}
      name={props?.name}
      value={props?.value}
      onChange={props?.onChange}
      InputProps={{
        style: {
          width: props.width,
          height: '38px',
          padding: '10px',
          background: '#F4F4F4 0% 0% no-repeat padding-box !important',
          border: '1px solid #D8D8D8',
          borderRadius: 5,
          color: '#262626',
          fontWeight: 400,
          outline: 'none',
        },
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          getContentAnchorEl: null,
        },
      }}
    >
      {props?.arr?.map((option, index) => (
        <MenuItem
          key={index}
          value={option.name!==null?option.name : option?.serviceName}
          sx={{}}
        >
          {option.name!==null? option.name : option?.serviceName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Dropdown;