import { TestDataService } from "../../services/testDataService";
import {
  ADD_TEST_DATA_LIST,
  DELETE_DATA_BY_ID,
  DATA_FILTERS,
  ALTER_SUBSCRIBE_DATA_BY_ID,
  ALTER_FAVOURITE_DATA_BY_ID,
  DATA_HIDDEN,
} from "../actionTypes";
const dataService = new TestDataService();

const add_test_data_list = (payload) => ({
  type: ADD_TEST_DATA_LIST,
  payload: payload,
});

export const addDataHidden = (dataHidden) => {
  return {
    type: DATA_HIDDEN,
    payload: dataHidden
  }
};

export const fetchAllTestDataActions =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();
    try {
      dispatch(
        add_test_data_list({
          loading: true,
        })
      );
      const res_data = await dataService.getAllTestData(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_test_data_list({
            loading: false,
            data:
              data.length !== 0 &&
              data.map((item) => {
                const checkFav =
                  userFavourites.filter(
                    (fav) => fav.resourceinstance === item._id
                  ).length !== 0;
                let tempProj = item;

                if (checkFav) {
                  tempProj["isFavourite"] = true;
                } else {
                  tempProj["isFavourite"] = false;
                }
                if (item?.test_data_type === "Test Data") {
                  const checkSubscribe =
                    userSubscriptions.filter(
                      (sub) =>
                        sub.name.split("_")[0] === "testdata" &&
                        sub.name.split("_")[1] === item._id
                    ).length !== 0;
                  if (checkSubscribe) {
                    tempProj["isSubscribe"] = true;
                  } else {
                    tempProj["isSubscribe"] = false;
                  }
                } else {
                  const checkSubscribe =
                    userSubscriptions.filter(
                      (sub) =>
                        sub.name.split("_")[0] === "config" &&
                        sub.name.split("_")[1] === item._id
                    ).length !== 0;
                  if (checkSubscribe) {
                    tempProj["isSubscribe"] = true;
                  } else {
                    tempProj["isSubscribe"] = false;
                  }
                }
                return tempProj;
              }),
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_test_data_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_test_data_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const deleteDataById = (TestDataId) => async (dispatch) => {
  dispatch({
    type: DELETE_DATA_BY_ID,
    payload: {
      TestDataId,
    },
  });
};

export const addDataFilters = (testDataFilters) => {
  return {
    type: DATA_FILTERS,
    payload: testDataFilters,
  };
};

export const alterSubscribeDataById = (TestDataId) => {
  return {
    type: ALTER_SUBSCRIBE_DATA_BY_ID,
    payload: {
      TestDataId,
    },
  };
};

export const alterFavouriteDataById = (TestDataId) => {
  return {
    type: ALTER_FAVOURITE_DATA_BY_ID,
    payload: {
      TestDataId,
    },
  };
};
