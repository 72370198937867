import {
  ADD_DATA_MODEL_LIST,
  ALTER_SUBSCRIBE_DATA_MODEL_BY_ID,
  ADD_SINGLE_DATA_MODEL_SUBSCRIPTION,
  REMOVE_SINGLE_DATA_MODEL_SUBSCRIPTION,
  BLOCK_UNBLOCK_DATA_MODEL,
  DATA_MODEL_FILTERS,
  SET_DATA_MODEL_SORT,
  DATA_MODEL_HIDDEN,
  DELETE_DATA_MODEL_BY_ID,
  BLOCK_UNBLOCK_DATAMODEL,
  ADD_DATA_MODEL_FILES,
  ADD_AI_MODEL_DETAILS,
  DELETE_DATA_MODEL_FILE,
  ADD_AI_MODEL_FILES,
  CLEAR_AI_MODEL
} from "../actionTypes";
import { useParams } from "react-router-dom";
import { DataModelService } from "../../services/dataModelService";
import {
  addDataModelDocument,
  deleteDataModelDocument,
  editDataModelById,
  getDataModelById,
} from "../../utils/dataModels/dataModelutils";

const dataModelService = new DataModelService();

const add_datamodels_data = (payload) => {
  return {
    type: ADD_DATA_MODEL_LIST,
    payload: payload,
  };
};

export const clearAIModelFile =()=>{
  return {
    type:CLEAR_AI_MODEL ,

  }
}
export const addAIModelFile= (resourceId,singleFileUpload)=>async(dispatch)=>{
  const { _msg, _status, data } = await addDataModelDocument(
    resourceId,
    singleFileUpload
  );
  let tempArr = [];
  data?.filesdetails?.map((item) => {
    item["resourceId"] = data?._id;
    tempArr.push(item);
  });
 
  const payload = data;
  dispatch({
    type: ADD_AI_MODEL_FILES,
    payload: payload,
  });
}

export const getAIModelDetails = (_id) => async (dispatch) => {
  const { data } = await getDataModelById(_id);
  let tempArr = [];
  data?.filesdetails?.map((item) => {
    item["resourceId"] = data?._id;
    tempArr.push(item);
  });
  const payload = data;
  dispatch({
    type: ADD_AI_MODEL_DETAILS,
    payload: payload,
  });
};

export const deleteAIModelFileByID = (_id, filename) => async (dispatch) => {
  dispatch({ type: DELETE_DATA_MODEL_FILE, payload: _id, filename });
};
export const getDataModelDetails = (_id) => async (dispatch) => {
  const { data } = await getDataModelById(_id);

  const payload = data?.filesdetails;
  dispatch({
    type: ADD_DATA_MODEL_FILES,
    payload: payload,
  });
};

export const fetchAllDataModels =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();

    try {
      const { data } = await dataModelService.getAllDataModals(
        currentPage,
        filter,
        sort
      );

      let payload = data;
      if (data && data.data != null) {
        let dataModels = data.data.map((item) => {
          const checkSubscribe =
            userSubscriptions.filter(
              (sub) =>
                sub.name.split("_")[0] === "datamodel" &&
                sub.name.split("_")[1] === item._id
            ).length !== 0;
          if (checkSubscribe) {
            return { ...item, isSubscribe: true };
          } else {
            return { ...item, isSubscribe: false };
          }
        });
        dispatch({
          type: ADD_DATA_MODEL_LIST,
          payload: { ...payload, data: dataModels },
        });
      } else {
        dispatch({
          type: ADD_DATA_MODEL_LIST,
          payload: [],
        });
      }
    } catch (error) {
      // dispatch(
      //     add_datamodels_data({
      //         loading: false,
      //         data: [],
      //         error: true,
      //     })
      // );
    }
  };

// filter
export const addDataModelFilters = (dataModelsFilters) => {
  return {
    type: DATA_MODEL_FILTERS,
    payload: dataModelsFilters,
  };
};

export const setDataModelSort = (dataModelsSort) => {
  return {
    type: SET_DATA_MODEL_SORT,
    payload: dataModelsSort,
  };
};

// export const setDataModelSort = (value) => async (dispatch) => {
//   try {
//     dispatch({ type: SET_DATA_MODEL_SORT, payload: value });
//   } catch (error) {
//
//   }
// };

export const addDataModelHidden = (DataModelHidden) => {
  return {
    type: DATA_MODEL_HIDDEN,
    payload: DataModelHidden,
  };
};

//delete
export const deleteDataModelId = (dataModelId) => async (dispatch) => {
  dispatch({
    type: DELETE_DATA_MODEL_BY_ID,
    payload: {
      dataModelId,
    },
  });
};

// block and ublock

export const block_unblock_datamodel = (dataModelID, status) => {
  return {
    type: BLOCK_UNBLOCK_DATAMODEL,
    payload: {
      dataModelID,
      status,
    },
  };
};

//subscribe datamodel
export const alterSubscibeDataModelById = (dataModelId) => {
  return {
    type: ALTER_SUBSCRIBE_DATA_MODEL_BY_ID,
    payload: {
      dataModelId,
    },
  };
};

export const addSingleDataModelSubscription = (subData) => {
  return {
    type: ADD_SINGLE_DATA_MODEL_SUBSCRIPTION,
    payload: subData,
  };
};

export const removeSingleDataModelSubscription = (resourceinstance) => {
  return {
    type: REMOVE_SINGLE_DATA_MODEL_SUBSCRIPTION,
    payload: resourceinstance,
  };
};

export const block_unblock_dataModels = (dataModelID, status) => {
  return {
    type: BLOCK_UNBLOCK_DATA_MODEL,
    payload: {
      dataModelID,
      status,
    },
  };
};
