import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Handle } from "react-flow-renderer";
import { connect } from "react-redux"

const useStyles = makeStyles({
    container: {
        // marginTop: "6rem",
        display: "flex",
        flexDirection: "column"
        // border: "1px solid black"
    },
    table: {
        "& .MuiTableCell-root": {
            padding: 0,
            paddingLeft: "1rem",
            height: "2rem",
            font: "normal normal normal 12px/16px Nunito",
            border: "1px solid #E0E0E0"
            // fontSize: 12,
            // fontFamily: "Nunito",
            // fontWeight: 500
        }
    },
    header: {
        height: "2rem",
        width: "18rem",
        background: "#F8F8F8 0% 0% no-repeat padding-box",
        border: "1px solid #D9D9D9",
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5
    },
    node_text: {
        fontFamily: "Nunito",
        fontSize: 12,
        color: '#000000',
        marginLeft: 8,
        // fontWeight: 500
    },
    head_cell: {
        background: "#E0F2FF 0% 0% no-repeat padding-box",
        border: "1px solid #E0E0E0",
    },
    container_: {
        // maxHeight: "10rem",
        minWidth: "10rem",
        maxWidth: "20rem",
        width: "18rem"
    },
    nodehandle: {
        width: "8px!important",
        height: "8px!important",
        background: "transparent!important",
        border: "1px solid lightgray!important",
        borderRadius: "4px!important",
    }
})

function ProcessDataNode(props) {
    const [data_list, setDataList] = useState([]);
    const [inputFieldGroup, setInputFieldGroup] = useState({
        name: "",
        type: ""
    })
    const styles = useStyles();

    const { node_name, nodeid } = props.data;
    const { data_flow_nodes_io_details } = props;

    useEffect(() => {
        var nodeDetails = data_flow_nodes_io_details.find((item) => item.type==="process_data_node");
        if(nodeDetails && nodeDetails.servicetype==="rest"){
            var temp_data_list = []
            data_flow_nodes_io_details.length > 0 && data_flow_nodes_io_details.forEach((node_) => {
                if (node_.type === "call_data_node") {
                    node_?.inputFields?.fields?.forEach((field_) => {
                        if (field_?.value === "") {
                            var findDuplicate = temp_data_list.filter((item) => item.name === field_.name);
                            if (findDuplicate.length === 0) {
                                temp_data_list.push(field_)
                            }
                        } else if (field_?.value !== "") {
                            var detailArr = field_.value.split(".");
                            if (detailArr.length > 0 && detailArr[0] === "${templateInput") {
                                var findDuplicate_ = temp_data_list.filter((item) => item.name === field_.name);
                                if (findDuplicate_.length === 0) {
                                    temp_data_list.push(field_)
                                }
                            }
                        }
                    })
                }
            })
            setDataList(temp_data_list)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }else if(nodeDetails && nodeDetails.servicetype!=="rest"){
            setDataList(() => nodeDetails?.inputFields?.fields ? nodeDetails?.inputFields?.fields : []) 
        }
    }, [data_flow_nodes_io_details, nodeid])

    var top_position = 0;
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src="/images/Start - process@3x.png" height={25} width={25} alt="call node" />
                <text className={styles.node_text}>{`Start Node-${node_name}`}</text>
            </div>
            <TableContainer component={Paper}
                className={styles.container_}>
                <Table className={styles.table} aria-label="simple label">
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.head_cell} align="left">Input</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data_list.map((item, index) => {
                            if (index === 0) {
                                top_position = 5
                            } else {
                                top_position = top_position + 2.1
                            }
                            const handleObj = {
                                nodeid: nodeid,
                                node_type: "process_node",
                                field_name: item.name,
                                node_name: node_name,
                                fieldgroupname: inputFieldGroup["name"],
                                fieldgrouptype: inputFieldGroup["type"]
                            }
                            return (
                                <div key={index}>
                                    <TableRow key={item.name}>
                                        <TableCell align="left" style={{ width: "18rem" }}>{item.name}</TableCell>
                                    </TableRow>
                                    <Handle type="source"
                                        position="right"
                                        id={JSON.stringify(handleObj)}
                                        // id={"a"}
                                        style={{ top: `${top_position}rem`, right: "1%" }}
                                        className={styles.nodehandle} />
                                </div>
                            )
                        })}
                        {/* <Handle type="source"
                            position="right"
                            id="a"
                            style={{  borderRadius: 0, background: '#FBBD08' }} /> */}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapDispatchToProps = {}

const mapStateToProps = (state) => {
    const { data_flow_nodes_io_details } = state.orchestration;
    return {
        data_flow_nodes_io_details
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDataNode)