import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import Styles from "../../../styles/commonStyles/Components";
import useTriggers from "../../../hooks/useTriggers";
import MenuIcon from "@mui/icons-material/Menu";
import useGetData from "../../../hooks/useGetData";
import Close from "@material-ui/icons/Close";

export default function CustomMenu({ id, setIsMenuOpen }) {
  const classes = Styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentProject, currentPage, getComponent } = useGetData();
  const currentComponent = getComponent(id);
  const params = useParams();
  const [menuHeaderTitleWidth, setMenuHeaderWidth] = useState("150px");
  const [menuListH, setMenuListH] = useState(1);
  const [viewMenu, setViewMenu] = useState(false);
  const viewType = params.view !== undefined ? params.view + "/" : "";
  const { handleTrigger } = useTriggers();
  const uiAppsList = useSelector(
    (state) => state.uiApplicationListReducer.data
  );
  const isDeviceMobile = params?.device === "MOBILE";
  const isDeviceTablet = params?.device === "TABLET";
  const isDeviceWeb = params?.device === "WEB";
  const isHamburger =
    isDeviceMobile ||
    isDeviceTablet ||
    currentComponent?.isTypeHamburgerWebView;
  const isWebOrUndefined = isDeviceWeb || params?.device === undefined;
  const toggleMenu = (toggle) => {
    setViewMenu(toggle);
    setIsMenuOpen(toggle);
  };
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) toggleMenu(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    if (currentComponent?.onInit)
      handleTrigger({
        action: currentComponent?.onInit,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.querySelectorAll(".react-grid-item")?.forEach((comp) => {
      if (comp.getAttribute("data-id") === currentComponent.id)
        setMenuListH(+comp.style.height.slice(0, -2) - 62);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComponent.h]);

  useEffect(() => {
    setMenuHeaderWidth(
      `${
        isDeviceMobile
          ? "75%"
          : isDeviceTablet
          ? "83%"
          : isWebOrUndefined &&
            currentComponent?.logoPosition &&
            currentComponent?.isTypeHamburgerWebView
          ? "95%"
          : isWebOrUndefined &&
            currentComponent?.logoPosition &&
            !currentComponent?.isTypeHamburgerWebView
          ? "98%"
          : parseInt(currentComponent?.menuWidth - 52)
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentComponent?.logoPosition,
    params?.device,
    currentComponent?.menuWidth,
    currentComponent?.isTypeHamburgerWebView,
  ]);

  const modifiedArray =
    Array.isArray(currentComponent?.data?.dataValue) &&
    !currentComponent?.tabValue
      ? currentComponent?.data?.dataValue?.map((v) => ({
          title: v.title,
          name: v.value,
        }))
      : currentComponent?.options?.filter((f) => !f?.hide);

  const css = {
    color: currentComponent?.status,
    fontFamily: currentComponent?.fontFamily,
    fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
    fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
    fontSize: currentComponent?.fontSize ? currentComponent?.fontSize : 16,
    textDecoration: currentComponent?.style?.underline
      ? "underline"
      : undefined,
  };

  const handleClick = (item) => {
    if (item?.urlType === "page") {
      if (item?.page) {
        toggleMenu(false);
        item?.linkedUiApp?.id === currentProject.id
          ? history.push(
              `/ui-applications/${viewType}${currentProject?.id}/${
                currentProject?.pages.find((p) => p.name === item?.page)?.url
              }${params?.device ? "/" + params?.device : ""}`
            )
          : (window.location.href = `/ui-applications/${viewType}${
              item?.linkedUiApp?.id
            }/${
              uiAppsList
                .find((app) => app._id === item.linkedUiApp?.id)
                ?.pages.find((p) => p.name === item?.page)?.url
            }${params?.device ? "/" + params?.device : ""}`);
      }
    } else if (item?.link && typeof item?.link === "string") {
      toggleMenu(false);
      window.open(item?.link, "_blank");
    }
  };

  const handleOnSubmenuExpand = (expanded, menu, submenu) => {
    if (viewType === "") {
      const components = currentPage.components.map((component) => {
        if (component.id === currentComponent.id) {
          return {
            ...component,
            options: component?.options?.map((item) =>
              item?.id === menu?.id
                ? {
                    ...item,
                    children: item?.children?.map((ch) =>
                      ch?.id === submenu?.id
                        ? { ...ch, isExpanded: expanded }
                        : { ...ch }
                    ),
                  }
                : { ...item }
            ),
          };
        } else {
          return component;
        }
      });
      dispatch(
        editComponent({
          pageid: currentPage?.id,
          data: components,
        })
      );
    }
  };

  const handleOnMenuExpand = (expanded, menu) => {
    if (viewType === "") {
      const components = currentPage.components.map((component) => {
        if (component.id === currentComponent.id) {
          return {
            ...component,
            options: component?.options?.map((item) => {
              if (item?.id === menu?.id) {
                return {
                  ...item,
                  isExpanded: expanded,
                };
              } else {
                return {
                  ...item,
                };
              }
            }),
          };
        } else {
          return component;
        }
      });
      dispatch(
        editComponent({
          pageid: currentPage?.id,
          data: components,
        })
      );
    }
  };
  return (
    <>
      <div ref={ref} className={classes.menu}>
        {!currentComponent?.logoHidden && (
          <div
            className={classes.menuHeader}
            style={{
              width: isWebOrUndefined
                ? currentComponent?.logoPosition
                  ? "100%"
                  : currentComponent?.menuWidth
                  ? currentComponent?.menuWidth
                  : 237
                : isDeviceMobile
                ? 399
                : 623,
              backgroundColor: currentComponent?.status,
            }}
          >
            <div
              onClick={() => {
                if (currentComponent?.logoUrl) {
                  toggleMenu(false);
                  window.location.href = currentComponent?.logoUrl;
                }
              }}
              style={{ margin: "0px 1px 0px 10px", display: "flex" }}
            >
              <img
                src={
                  currentComponent?.logo
                    ? currentComponent?.logo
                    : "/img/image.png"
                }
                alt="tempraory Image"
                style={{
                  maxHeight: 30,
                  maxWidth: 50,
                  width: "100%",
                  filter: currentComponent?.logo ? "" : "invert(1)",
                  height: "-webkit-fill-available",
                }}
              />
            </div>
            <div
              className={classes.logoTitle}
              style={{
                width: +menuHeaderTitleWidth,
                paddingLeft: isWebOrUndefined ? 10 : 0,
                textAlign: currentComponent?.isTitlePositionCenter
                  ? "center"
                  : "left",
              }}
            >
              <Tooltip
                title={
                  currentComponent?.logoTitle ? currentComponent?.logoTitle : ""
                }
              >
                <span
                  style={{
                    ...css,
                    color: "white",
                  }}
                >
                  {currentComponent?.logoTitle
                    ? currentComponent?.logoTitle
                    : ""}
                </span>
              </Tooltip>
            </div>
            {isHamburger ? (
              <div style={{ padding: "0px 10px", display: "flex" }}>
                {viewMenu ? (
                  <Close
                    style={{ transform: "scale(1.2085)" }}
                    onClick={() => toggleMenu(false)}
                  />
                ) : (
                  <MenuIcon
                    style={{ transform: "scale(1.2085)" }}
                    onClick={() => toggleMenu(true)}
                  />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        {/* MENUS */}
        <div
          style={{
            width: isWebOrUndefined
              ? currentComponent?.logoPosition
                ? "100%"
                : currentComponent?.menuWidth
                ? currentComponent?.menuWidth
                : 237
              : isDeviceMobile
              ? 399
              : 623,
            display: "flex",
          }}
        >
          <div
            className={classes.menuList}
            style={
              isHamburger
                ? {
                    transform: viewMenu
                      ? "translate(0%,0%)"
                      : "translate(0%, -100%)",
                    opacity: viewMenu ? 1 : 0,
                    userSelect: viewMenu ? "all" : "none",
                    pointerEvents: viewMenu ? "all" : "none",
                    marginLeft: "auto",
                    height: menuListH,
                  }
                : { height: menuListH }
            }
          >
            {modifiedArray?.map((item, index) => (
              <Accordion
                defaultExpanded={item?.isExpanded}
                key={index}
                style={{
                  width: currentComponent?.menuWidth
                    ? currentComponent?.menuWidth
                    : 237,
                }}
                onChange={(_e, expanded) => handleOnMenuExpand(expanded, item)}
              >
                <div>
                  <AccordionSummary
                    expandIcon={
                      item.children.length !== 0 && <ExpandMoreIcon />
                    }
                  >
                    <div>
                      <span
                        onClick={() => handleClick(item)}
                        style={{ ...css }}
                      >
                        {item?.title}
                      </span>
                    </div>
                  </AccordionSummary>
                </div>
                {item.children.length !== 0 ? (
                  <AccordionDetails>
                    <div
                      style={{
                        width: currentComponent?.menuWidth
                          ? currentComponent?.menuWidth - 27
                          : 210,
                      }}
                    >
                      {item?.children
                        ?.filter((f) => !f?.hide)
                        .map((sub, index) => {
                          return (
                            <div className={classes.subMenu} key={index}>
                              <Accordion
                                style={{ backgroundColor: "white" }}
                                onChange={(_e, expanded) =>
                                  handleOnSubmenuExpand(expanded, item, sub)
                                }
                                defaultExpanded={sub?.isExpanded}
                              >
                                <AccordionSummary
                                  style={{ opacity: 1 }}
                                  expandIcon={
                                    sub?.submenuChildren.length !== 0 && (
                                      <ExpandMoreIcon />
                                    )
                                  }
                                >
                                  <div>
                                    <span
                                      onClick={() => handleClick(sub)}
                                      style={{ ...css }}
                                    >
                                      {sub?.title}
                                    </span>
                                  </div>
                                </AccordionSummary>
                                {sub?.submenuChildren.length !== 0 ? (
                                  <>
                                    <AccordionDetails
                                      style={{ flexDirection: "column" }}
                                    >
                                      {sub?.submenuChildren &&
                                        sub?.submenuChildren
                                          .filter((f) => f?.hide !== true)
                                          .map((subChild, index) => (
                                            <div
                                              key={index}
                                              className={classes.subMenu}
                                            >
                                              <span
                                                onClick={() =>
                                                  handleClick(subChild)
                                                }
                                                className={classes.menuHeading}
                                                style={{ ...css }}
                                              >
                                                {subChild?.title}
                                              </span>
                                            </div>
                                          ))}
                                    </AccordionDetails>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Accordion>
                            </div>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                ) : (
                  <></>
                )}
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
