const pageColumns = [
    { name: 'name', title: 'Name', for: 'page' },
    { name: 'description', title: 'Description', for: 'page' },
    { name: 'uiApplication', title: 'UI Application', for: 'page' },
    { name: 'createdOn', title: 'Created On', for: 'page' },
    { name: 'createdBy', title: 'Created By', for: 'page' },
  ];
  
  const pageDefaultColumnWidths = [
    { columnName: 'name', weightage: 2.5, width: 200 },
    { columnName: 'description', weightage: 2.5, width: 200 },
    { columnName: 'uiApplication', weightage: 2.5, width: 200 },
    { columnName: 'createdOn', weightage: 3, width: 200 },
    { columnName: 'createdBy', weightage: 3, width: 200 },
  ]
  
  const pageTableColumnExtensions = [
    { columnName: 'name', align: 'left' },
    { columnName: 'description', align: 'left' },
    { columnName: 'uiApplication', align: 'left' },
    { columnName: 'createdOn', align: 'left' },
    { columnName: 'createdBy', align: 'left' },
     ];
  
  const pageColumnOrder = [
    'name',
    'description',
    'uiApplication',
    'createdOn',
    'createdBy',
  ];
  
  const pageColumnHidden = [
    'name',
    'description',
    'uiApplication',
    'createdOn',
    'createdBy',
  ];
  
  const pageDefaultSorting = [];
  
  export {
    pageColumns,
    pageDefaultColumnWidths,
    pageTableColumnExtensions,
    pageColumnOrder,
    pageDefaultSorting,
    pageColumnHidden,
  };
  