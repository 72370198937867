import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const Favicon = ({ children, currentOrgData }) => {
  useEffect(() => {
    document.getElementById('myFavicon').href = currentOrgData?.data?.favicon
      ? currentOrgData?.data?.favicon
      : '/Kailash_favicon.svg';
    // for apple devices
    document.getElementById('myAppleFavicon').href = currentOrgData?.data?.favicon
      ? currentOrgData?.data?.favicon
      : '/Kailash_favicon.svg';
    const tabTitle = document.getElementById('tabTitle');
    tabTitle.innerHTML = currentOrgData?.data?.title
      ? currentOrgData?.data?.title
      : '';
  });

  return { ...children };
};

const mapDispatchtoProps = {};
const mapStateToProps = (state) => {
  const { currentOrgData } = state.organizationReducer;
  return {
    currentOrgData,
  };
};
export default connect(mapStateToProps, mapDispatchtoProps)(Favicon);
