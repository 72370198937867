import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";
const apiHelper = new ApiHelper();

export class DataStandardService {
  fetchAllFields(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/fields?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  fetchAllFieldGroups(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  postField(fieldData) {
    const uri = `${BASE_URL}/api/v1.0.0/fields`;
    return apiHelper.post(uri, fieldData);
  }
  postFieldGroup(fieldGroupData) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups`;
    return apiHelper.post(uri, fieldGroupData);
  }
  editField(FieldID, fieldData) {
    const uri = `${BASE_URL}/api/v1.0.0/fields/${FieldID}`;
    return apiHelper.put(uri, fieldData);
  }
  editFieldGroup(FieldGroupID, fieldGroupData) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups/${FieldGroupID}`;
    return apiHelper.put(uri, fieldGroupData);
  }
  getFieldById(FieldID) {
    const uri = `${BASE_URL}/api/v1.0.0/fields/${FieldID}`;
    return apiHelper.get(uri);
  }
  getFieldGroupById(FieldGroupID) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups/${FieldGroupID}`;
    return apiHelper.get(uri);
  }

  blockField(id) {
    const uri = `${BASE_URL}/api/v1.0.0/field/${id}/block`;
    return apiHelper.put(uri, {});
  }
  unblockField(id) {
    const uri = `${BASE_URL}/api/v1.0.0/field/${id}/unblock`;
    return apiHelper.put(uri, {});
  }

  blockFieldGroup(id) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups/${id}/block`;
    return apiHelper.put(uri, {});
  }
  unblockFieldGroup(id) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups/${id}/unblock`;
    return apiHelper.put(uri, {});
  }

  postFieldsBulkUpload(file) {
    const uri = `${BASE_URL}/api/v1.0.0/fields/bulkfieldloaders`;
    return apiHelper.postFormData(uri, file);
  }

  // Glossary API Integration
  fetchAllTags() {
    const uri = `${BASE_URL}/api/v1.0.0/tags`;
    return apiHelper.get(uri);
  }
  fetchAllTagsByFilter(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filter=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/tags?${queryParam}&size=10`;
   
    return apiHelper.get(uri);
  }
  fetchTagById(id) {
    const uri = `${BASE_URL}/api/v1.0.0/tags/${id}`;
    return apiHelper.get(uri);
  }
  addTag(tagData) {
    const uri = `${BASE_URL}/api/v1.0.0/tags/single`;
    return apiHelper.post(uri, tagData);
  }
  editTagById(id, tagData) {
    const uri = `${BASE_URL}/api/v1.0.0/tags/${id}`;
    return apiHelper.put(uri, tagData);
  }
  deleteTag(id) {
    const uri = `${BASE_URL}/api/v1.0.0/tags/${id}`;
    return apiHelper.delete(uri);
  }
  fetchTagResources(id) {
    const queryParam = `filter={"tag":"${id}"}`;
    const uri = `${BASE_URL}/api/v1.0.0/resourcetags?${queryParam}`;
    return apiHelper.get(uri);
  }

  deleteFieldgroup(id) {
    const uri = `${BASE_URL}/api/v1.0.0/fieldgroups/${id}`;
    return apiHelper.delete(uri);
  }

  deleteField(id) {
    const uri = `${BASE_URL}/api/v1.0.0/fields/${id}`;
    return apiHelper.delete(uri);
  }

  // getApplicationList() {
  //   const uri = `${APPLICATION}/api/v1.0.0/apps`;
  //   return apiHelper.get(uri);
  // }49.205.218.104:6001/api/v1.0.0/fields/60bf058da8b118ad6ee8e7b8

  // getUserDetailsByID(userID) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
  //   return apiHelper.get(uri);
  // }
  // createUser(userData) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users`;
  //   return apiHelper.post(uri, userData);
  // }
  // editUserByID(userID, EdituserData) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
  //   return apiHelper.put(uri, EdituserData);
  // }
  // fetchRoles() {
  //   const uri = `${BASE_URL}/api/v1.0.0/roles`;
  //   return apiHelper.get(uri);
  // }
  // deleteUser(userID) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
  //   return apiHelper.delete(uri);
  // }
  // getUserTeamDetails(userID) {
  //   const uri = `${BASE_URL}/api/v1.0.0/teams?filters={"users":"${userID}"}`;
  //   return apiHelper.get(uri);
  // }
  // getUserProjectDetails(userID) {
  //   const uri = `${BASE_URL}/api/v1.0.0/projects?filter={"teams":"${userID}"}`;
  //   return apiHelper.get(uri);
  // }
  // blockUser(userID, blockData) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/block`;
  //   return apiHelper.put(uri, blockData);
  // }
  // unBlockUser(userID, unblockData) {
  //   const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/unblock`;
  //   return apiHelper.put(uri, unblockData);
  // }
}
