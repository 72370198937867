import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { deletePage } from "../../../redux/actions/uiApplicationAction";
import Styles from "../../styles/commonStyles/PagesTable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { AppButton } from "../atoms/button/AppButton";
import moment from "moment";

export default function PagesTable(props) {
	const { setDeleteModalOpen,setOnDeletePage } = props;
	const classes = Styles();
	const history = useHistory();
	const [pages, setpages] = useState();
    useEffect(() => {
        if (props.currentProj.pages) {
          try {
            setpages(props.currentProj.pages ? props.currentProj.pages : []);
          } catch (error) {
            // console.log("parse Error", error);
          }
        }
      }, [props.currentProj.pages]);

	const { userInfo } = useSelector((state) => state.userDetails);

	return (
		<>
			{/* {!props?.editMode && <div
				style={{
					display: "flex",
					paddingTop: 20,
					justifyContent: "flex-end",
				}}>
				<AppButton
					color="primary"
					buttonName="Create"
					variant="contained"
					disabled={false}
					style={{ margin: "unset" }}
					className="btnsmall"
					onClick={() =>
						history.push(`/ui-applications/${props?.projectId[0]}/${pages && pages[0]?.url}`)
					}
				/>
			</div>} */}
			<TableContainer className={classes.table}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ paddingLeft: "2rem" }}>Page</TableCell>
							<TableCell>Page URL</TableCell>
							<TableCell>Created On</TableCell>
							<TableCell>Created By</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{pages &&
							pages.length > 0 &&
							pages?.map((page, index) => {
								return (
									<TableRow key={index}>
										<TableCell style={{ paddingLeft: "2rem" }}>{page?.name}</TableCell>
										<TableCell>{page?.url}</TableCell>
										<TableCell>
											{userInfo?.timeZone
												? moment
														.unix(page?.createdon)
														.tz(userInfo.timeZone)
														.format(
															userInfo.time === "h:mm"
																? `${userInfo.dateFormat}, ${userInfo.time} A`
																: `${userInfo.dateFormat} ${userInfo.time}`
														)
												: moment.unix(page?.createdon).format("DD/MM/YYYY, hh:mm:ss A")}
										</TableCell>
										<TableCell>{page?.createdby}</TableCell>
										<TableCell>
											<IconButton
												style={{ cursor: "pointer" }}
												disabled={props?.editMode}
												onClick={() =>
													history.push(`/ui-applications/${props?.currentProj?._id}/${page?.url}`)
												}>
												{!props?.editMode && <img src="/img/gotoCanvas-ui-app-list.svg" alt="delete" />}
												{props?.editMode && <img src="/img/gotoCanvas-ui-app-list-disabled.svg" alt="delete" />}
											</IconButton>
											<IconButton
												style={{ cursor: "pointer" }}
												disabled={props?.editMode}
												onClick={(e) =>{
													e.stopPropagation();
													setOnDeletePage(page);
													setDeleteModalOpen(true);
													// dispatch(
													// 	deletePage({
													// 		name: page?.name,
													// 	})
													// )
												}}>
												{!props?.editMode && <img src="/images/deleteLine.svg" alt="delete" />}
												{props?.editMode && <img src="/images/deleteLine-disabled.svg" alt="delete" />}
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}