import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DeploymentApplicationProcessTable from "../../../components/Deployment/Tables/DeploymentApplicationProcessTable";
import DeploymentContainerTable from "../../../components/Deployment/Tables/DeploymentContainerTable";

import manageScreenStyles from "../../CommonStyles/manageScreenStyles";

const CreateDeploymentDependencies = ({ deployment_type }) => {
  const styles = manageScreenStyles();

  return (
    <div>
      <Accordion className={styles.fieldPanel} defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownRounded className={styles.accordianIcon} />}
        >
          <Typography className={styles.sectionHeading}>Deployment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DeploymentContainerTable />
        </AccordionDetails>
      </Accordion>
      {deployment_type === "Application" && (
        <Accordion className={styles.fieldPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownRounded className={styles.accordianIcon} />
            }
          >
            <Typography className={styles.sectionHeading}>Process</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DeploymentApplicationProcessTable />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { deployment_type } = state.deploymentProcessReducer.appSelectionData;
  return {
    deployment_type,
  };
};

export default connect(mapStateToProps)(CreateDeploymentDependencies);
