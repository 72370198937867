import { useState } from "react";
import { useDispatch } from "react-redux";
import AddOutlined from "@material-ui/icons/AddOutlined";
import { Tooltip } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { DeleteModal } from "../../../../../../components/common/modals/DeleteModal";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import Styles from "../../../../../styles/commonStyles/MenuRightBar";
import useGetData from "../../../../../hooks/useGetData";
import MenuPopup from "./MenuPopup";

export default function MenuHelper() {
  const classes = Styles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent, currentProject } = useGetData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [deletemenuData, setDeleteMenuData] = useState();
  const [deleteSubmenuData, setDeleteSubMenuData] = useState();
  const [deleteSubMenuModal, setDeleteSubMenuModal] = useState(false);
  const [deleteSubmenuChildData, setDeleteSubMenuChildData] = useState();
  const [deleteSubMenuChildModal, setDeleteSubMenuChildModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [menuType, setMenuType] = useState();
  const [menuData, setMenuData] = useState();
  const [submenuData, setSubmenuData] = useState();
  const open = Boolean(anchorEl);

  const handleAddMenu = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: [
            ...component.options,
            {
              id: uuid(),
              title: `Menu Level 1.${component.options.length + 1}`,
              link: "",
              hide: false,
              isExpanded: false,
              urlType: "page",
              children: [],
            },
          ],
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleHidemenu = (e, menu) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component?.options?.map((item) => {
            if (item?.id === menu?.id) {
              return {
                ...item,
                hide: !item?.hide,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handeDelete = (e, menu) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component.options.filter((c) => c.id !== menu.id),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
    setDeleteMenuModal(false);
  };

  // Submenu
  const handleAddSubMenu = (e, item) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component?.options?.map((menu) => {
            if (menu.id === item?.id) {
              return {
                ...menu,
                children: [
                  ...menu.children,
                  {
                    id: uuid(),
                    title: `Menu Level 2.${menu.children.length + 1}`,
                    hide: false,
                    link: "",
                    isExpanded: false,
                    urlType: "page",
                    submenuChildren: [],
                  },
                ],
              };
            } else {
              return {
                ...menu,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleHideSubmenu = (e, menu, submenu) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component?.options?.map((item) => {
            if (item?.id === menu?.id) {
              return {
                ...item,
                children: item?.children?.map((ch) => {
                  if (ch?.id === submenu?.id) {
                    return {
                      ...ch,
                      hide: !ch?.hide,
                    };
                  } else {
                    return {
                      ...ch,
                    };
                  }
                }),
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handeSubmenuDelete = (e, item, submenu) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component?.options?.map((menu) => {
            if (menu.id === item?.id) {
              return {
                ...menu,
                children: menu.children.filter((c) => c.id !== submenu.id),
              };
            } else {
              return {
                ...menu,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
    setDeleteSubMenuModal(false);
  };

  const handleAddSubmenuChild = (e, item, submenu) => {
    const components = currentPage?.components.map((component) => {
      if (component?.id === currentComponent?.id) {
        return {
          ...component,
          options: component?.options?.map((menu) => {
            if (menu?.id === item?.id) {
              return {
                ...menu,
                children: menu?.children.map((tempSubmenu) => {
                  if (submenu?.id === tempSubmenu?.id) {
                    return {
                      ...tempSubmenu,
                      submenuChildren: [
                        ...tempSubmenu?.submenuChildren,
                        {
                          id: uuid(),
                          title: `Menu Level 3.${
                            tempSubmenu?.submenuChildren.length + 1
                          }`,
                          link: "",
                          urlType: "page",
                        },
                      ],
                    };
                  } else {
                    return {
                      ...tempSubmenu,
                    };
                  }
                }),
              };
            } else {
              return {
                ...menu,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  const handleHideSubmenuChild = (e, item, submenu, submenuChild) => {
    const components = currentPage?.components.map((component) => {
      if (component?.id === currentComponent?.id) {
        return {
          ...component,
          options: component?.options?.map((menu) => {
            if (menu?.id === item?.id) {
              return {
                ...menu,
                children: menu?.children.map((tempSubmenu) => {
                  if (submenu?.id === tempSubmenu?.id) {
                    return {
                      ...tempSubmenu,
                      submenuChildren: tempSubmenu?.submenuChildren.map(
                        (tempsubmenuChild) => {
                          if (submenuChild?.id === tempsubmenuChild?.id) {
                            return {
                              ...tempsubmenuChild,
                              hide: !tempsubmenuChild?.hide,
                            };
                          } else {
                            return {
                              ...tempsubmenuChild,
                            };
                          }
                        }
                      ),
                    };
                  } else {
                    return {
                      ...tempSubmenu,
                    };
                  }
                }),
              };
            } else {
              return {
                ...menu,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  const handleDeleteSubmenuChild = (e, item, submenu, submenuChild) => {
    const components = currentPage?.components.map((component) => {
      if (component?.id === currentComponent?.id) {
        return {
          ...component,
          options: component?.options?.map((menu) => {
            if (menu?.id === item?.id) {
              return {
                ...menu,
                children: menu?.children.map((tempSubmenu) => {
                  if (submenu?.id === tempSubmenu?.id) {
                    return {
                      ...tempSubmenu,
                      submenuChildren: tempSubmenu?.submenuChildren.filter(
                        (tempsubmenuChild) =>
                          submenuChild?.id !== tempsubmenuChild?.id
                      ),
                    };
                  } else {
                    return {
                      ...tempSubmenu,
                    };
                  }
                }),
              };
            } else {
              return {
                ...menu,
              };
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
    setDeleteSubMenuChildModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuDeleteModalOpen = (e, item) => {
    e.stopPropagation();
    setDeleteMenuModal(true);
    setDeleteMenuData(item);
  };
  const menuDeleteModalClose = () => {
    setDeleteMenuModal(false);
  };

  const SubmenuDeleteModalOpen = (e, item, sub) => {
    e.stopPropagation();
    setDeleteSubMenuModal(true);
    setDeleteMenuData(item);
    setDeleteSubMenuData(sub);
  };

  const submenuChildDeleteModalOpen = (e, menu, submenu, submenuChild) => {
    setDeleteSubMenuChildModal(true);
    setDeleteMenuData(menu);
    setDeleteSubMenuData(submenu);
    setDeleteSubMenuChildData(submenuChild);
  };

  const SubmenuDeleteModalClose = () => setDeleteSubMenuModal(true);

  return (
    <div className={classes.menu}>
      {currentComponent?.options?.map((item) => {
        return (
          <div key={item?.id}>
            <div
              className="logoBox"
              onClick={(e) => {
                handleClick(e);
                setModalData(item);
                setMenuType("menu");
              }}
            >
              <div
                className="title"
                style={{ justifyContent: "start", paddingLeft: "5px" }}
              >
                <Tooltip title={item?.title}>
                  <label style={{ width: 115 }} className="titleLabel">
                    {item?.title}
                  </label>
                </Tooltip>
              </div>
              <div className="icon">
                <img
                  src={item?.hide ? "/img/eye-slash.svg" : "/img/blue-eye.svg"}
                  alt=""
                  style={{ height: 25, width: 20 }}
                  onClick={(e) => handleHidemenu(e, item)}
                />
                <img
                  src="/img/deleteBlue.svg"
                  alt=""
                  onClick={(e) => menuDeleteModalOpen(e, item)}
                />
              </div>
            </div>
            {deleteMenuModal && (
              <DeleteModal
                name={deletemenuData?.title}
                open={deleteMenuModal}
                handleClose={menuDeleteModalClose}
                handleDelete={(e) => handeDelete(e, deletemenuData)}
              />
            )}
            {anchorEl && (
              <MenuPopup
                isOptionsOpen={open}
                optionsEl={anchorEl}
                setOptionsEl={setAnchorEl}
                item={modalData}
                setModalData={setModalData}
                menuType={menuType}
                menuData={menuData}
                submenu={submenuData}
              />
            )}
            {item?.children?.map((sub, index) => {
              return (
                <div key={index * 23}>
                  <div
                    className="submenu"
                    onClick={(e) => {
                      handleClick(e);
                      setMenuData(item);
                      setModalData(sub);
                      setMenuType("submenu");
                    }}
                  >
                    <div
                      className="title"
                      style={{ justifyContent: "start", paddingLeft: "5px" }}
                    >
                      <Tooltip title={sub?.title}>
                        <label className="titleLabel" style={{ width: 110 }}>
                          {sub?.title}
                        </label>
                      </Tooltip>
                    </div>
                    <div className="submenuIcon">
                      <img
                        src={`/img/${sub?.hide ? "eye-slash" : "blue-eye"}.svg`}
                        alt="eye"
                        style={{ height: 25, width: 20 }}
                        onClick={(e) => handleHideSubmenu(e, item, sub)}
                      />
                      <img
                        src="/img/deleteBlue.svg"
                        alt=""
                        onClick={(e) => SubmenuDeleteModalOpen(e, item, sub)}
                      />
                    </div>
                    {deleteSubMenuModal && (
                      <DeleteModal
                        name={deleteSubmenuData?.title}
                        open={deleteSubMenuModal}
                        handleClose={SubmenuDeleteModalClose}
                        handleDelete={(e) =>
                          handeSubmenuDelete(
                            e,
                            deletemenuData,
                            deleteSubmenuData
                          )
                        }
                      />
                    )}
                  </div>
                  {sub?.submenuChildren &&
                    sub?.submenuChildren.map((submenuChild, i) => (
                      <div key={i} className="submenuchild">
                        <div
                          onClick={(e) => {
                            handleClick(e);
                            setMenuData(item);
                            setModalData(submenuChild);
                            setSubmenuData(sub);
                            setMenuType("submenuChild");
                          }}
                          style={{
                            justifyContent: "start",
                            paddingLeft: "5px",
                          }}
                          className="title"
                        >
                          <Tooltip title={submenuChild?.title}>
                            <label
                              className="titleLabel"
                              style={{ width: 100 }}
                            >
                              {submenuChild?.title}
                            </label>
                          </Tooltip>
                        </div>
                        <div className="submenuchildIcon">
                          <img
                            src={
                              submenuChild?.hide
                                ? "/img/eye-slash.svg"
                                : "/img/blue-eye.svg"
                            }
                            alt=""
                            style={{ height: 25, width: 20 }}
                            onClick={(e) =>
                              handleHideSubmenuChild(e, item, sub, submenuChild)
                            }
                          />
                          <img
                            src="/img/deleteBlue.svg"
                            alt=""
                            onClick={(e) =>
                              submenuChildDeleteModalOpen(
                                e,
                                item,
                                sub,
                                submenuChild
                              )
                            }
                          />
                        </div>
                        {deleteSubMenuChildModal && (
                          <DeleteModal
                            ///child
                            name={deleteSubmenuChildData?.title}
                            open={deleteSubMenuChildModal}
                            handleClose={() =>
                              setDeleteSubMenuChildModal(false)
                            }
                            handleDelete={(e) =>
                              handleDeleteSubmenuChild(
                                e,
                                deletemenuData,
                                deleteSubmenuData,
                                deleteSubmenuChildData
                              )
                            }
                          />
                        )}
                      </div>
                    ))}
                  <div
                    className={classes.addMenuButton}
                    style={{ marginLeft: 40 }}
                    onClick={(e) => handleAddSubmenuChild(e, item, sub)}
                  >
                    <AddOutlined /> Menu Level 3
                  </div>
                </div>
              );
            })}
            <div
              className={classes.addMenuButton}
              style={{ marginLeft: 20 }}
              onClick={(e) => handleAddSubMenu(e, item)}
            >
              <AddOutlined /> Menu Level 2
            </div>
          </div>
        );
      })}
      <div className={classes.addMenuButton} onClick={handleAddMenu}>
        <AddOutlined /> Menu Level 1
      </div>
    </div>
  );
}
