import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  topContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  topContentItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "50%",
  },
  search: {
    width: 300,
    height: 32,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  red: {
    color: "#E22626",
  },
  input: {
    width: "15rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    "&::before": {
      borderBottom: "unset",
    },
  },
  textarea: {
    width: "25rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    marginTop: "5px",
    resize: "vertical",
    minHeight: "2.2rem",
    maxHeight: "13.2rem",
    padding: "10px 15px",
    outline: "none",
    font: "normal normal normal 12px/16px Nunito;",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  MuiDivider: {
    width: "2px",
    height: "30px",
    color: "#535353",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  action: {
    "& .MuiDialogActions-root": {
      justifyContent: "space-between !important",
      border: "1px solid",
    },
  },
  importModal: {},
  filters: {
    padding: 0,
    "& .filtersWrap": {
      padding: 10,
      display: "flex",

      "& .field": {
        margin: 5,

        "& label": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#333333DE",
        },
      },
    },
  },
  FilterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 120,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D0D0D0",
    borderRadius: "3px",
    opacity: 1,
    padding: "0 5px",
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
    height: 32,
  },
  selectedContainer: {
    width: "85%",
    marginLeft: 5,
    display: "flex",
    overflow: "auto",
    alignItems: "Center",
    justifyContent: "flex-start",
    "&::-webkit-scrollbar": {
      height: "5px !important",
    },
  },
}));
