import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import listStyles from "../../CommonStyles/listScreenStyles";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Autocomplete } from "@material-ui/lab";
import {
  FetchDeploymentById,
  reDeploy,
} from "../../../utils/deployment/deploymentUtils";
import { getImageList } from "../../../utils/orchestration/applicationUtils";
import { additionalDeployment } from "../../../utils/deployment/deploymentUtils";
import Loader from "../../../components/common/stuff/Loader";

const EditContainer2 = (props) => {
  const { deploymentId, ContainerName } = props.match.params;
  const styles = listStyles();
  const style = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const volumeTypeArray = ["AWSEBS", "PVC"];
  const fileSystems = ["ext4"];

  const [isDependency, setIsDependency] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState();
  const [isEditMode, setIsEditMode] = useState(true);
  const [showFileSystemType, setShowFileSystemType] = useState();
  const [replicas, setReplicas] = useState();
  const [restart, setRestart] = useState();
  const [envs, setEnvs] = useState([]);
  const [volume, setVolume] = useState();
  const [volumeType, setVolumeType] = useState();
  const [deployment, setDeployment] = useState();
  const [fileSystemType, setFileSystemType] = useState();
  const [sizing, setSizing] = useState({
    limits: { cpu: "", memory: "" },
    requests: { cpu: "", memory: "" },
  });
  const [port, setPort] = useState();
  const [imageID, setImageID] = useState();
  const [image, setImage] = useState();
  const [imagesList, setImagesList] = useState();
  const [submitData, setSubmitData] = useState({
    container_name: ContainerName,
  });
  const [deletedContainer, setDeletedContainer] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [error, setError] = useState({
    limits: { cpu: false, memory: false },
    requests: { cpu: false, memory: false },
  });
  const [volError, setVolError] = useState({
    Name: false,
    Path: false,
    VolumeID: false,
    VolumeType: false,
    FileSystemType: false,
  });

  const handleCancelEditContainer = () => {
    histroy.push(`/host/container/detail/${deploymentId}/${ContainerName}`);
  };

  const handleVolumeChange = (event) => {
    const fieldName = event.target.name;
    const fieldVal = event.target.value;
    setVolume({ ...volume, [fieldName]: fieldVal });
  };

  const handleVolumeType = (event) => {
    const val = event.target.value;
    setVolumeType(val);
    if (val === "PVC") {
      setVolume({ ...volume, VolumeType: "persistentVolumeClaim" });
      setShowFileSystemType(false);
    } else {
      setShowFileSystemType(true);
      setVolume({ ...volume, VolumeType: "awsElasticBlockStore" });
    }
  };

  const handleFileSystemType = (event) => {
    const val = event.target.value;
    setFileSystemType(val);
    setVolume({ ...volume, FileSystemType: val });
  };

  const handleSubmit = async () => {
    let environment = {};
    let formIsValid = true;
    let volError = {};
    if(sizing.requests.cpu ==="" && sizing.limits.cpu === ""){
      let validData = submitData;
      delete validData.resources
    } 
    envs.map((row) => {
      if (row?.key !== "" && row?.value !== "") {
        environment[row.key] = row.value;
      }
    });
    if (
      volume?.Name ||
      volume?.Path ||
      volume?.VolumeID ||
      volume?.VolumeType
    ) {
      if (!volume?.Name) {
        volError["Name"] = true;
        formIsValid = false;
      }
      if (!volume?.Path) {
        volError["Path"] = true;
        formIsValid = false;
      }

      if (!volume?.VolumeID) {
        volError["VolumeID"] = true;
        formIsValid = false;
      }

      if (!volume?.VolumeType) {
        volError["VolumeType"] = true;
        formIsValid = false;
      } else {
        if (volume?.VolumeType === "AWSEBS") {
          if (!volume?.FileSystemType) {
            volError["FileSystemType"] = true;
            // setVolError({...volError,FileSystemType:{isError:false}})
            formIsValid = false;
          }
        }
      }
      setVolError(volError);
    }

    let serializeVolumeData = [
      {
        name: volume?.Name,
        path: volume?.Path,
        filesystemtype: volume?.FileSystemType,
        volumeid: volume?.VolumeID,
        volumetype: volume?.VolumeType,
      },
    ];

    if (volume?.VolumeType === "PVC") {
      delete serializeVolumeData[0]["filesystemtype"];
    }

    let payload = {
      ...submitData,
      environment: environment,
      imageid: selectedImage?._id,
      volume: serializeVolumeData,
    };

    if (!volume) {
      delete payload["volume"];
    }
    delete payload["Volumes"];

    if (deletedContainer) {
      let payload = {
        deployments: [
          {
            ...submitData,
            environment: environment,
            imageid: selectedImage?._id,
            image: `${selectedImage?.imagename}:${selectedImage?.imagetag}`,
            deploymentId: deploymentId,
            volume: serializeVolumeData,
          },
        ],
      };

      if (!volume) {
        delete payload["deployments"][0]["volume"];
      }

      if (formIsValid) {
        const { data, _status, _msg } = await additionalDeployment(
          payload,
          deploymentId,
          ContainerName
        );
        if (_status === 200) {
          enqueueSnackbar(
            _msg ? _msg : "Deleted Container redeployed successfully",
            {
              variant: "success",
            }
          );
          histroy.push(`/host`);
        } else {
          enqueueSnackbar(_msg ? _msg : "Container cannot be redeployed!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Please enter all volume details", {
          variant: "error",
        });
      }
    } else {
      if (formIsValid) {
        const { data, _status, _msg } = await reDeploy(
          payload,
          deploymentId,
          ContainerName
        );
        if (_status === 200) {
          enqueueSnackbar(_msg ? _msg : "Container redeployed successfully", {
            variant: "success",
          });
          histroy.push(`/host/edit/${deploymentId}`);
        } else {
          enqueueSnackbar(_msg ? _msg : "Container cannot be redeployed!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Please enter all volume details", {
          variant: "error",
        });
      }
    }
  };

  const handleReplicaChange = (e) => {
    const val = e.target.value;
    setReplicas(val);
  };

  const handleRestartCheckbox = (e) => {
    if (e.target.checked === false) {
      setRestart("");
    } else {
      setRestart("Always");
    }
  };

  const handleEnvChange = (values, index) => {
    let arr = [...envs];
    arr[index] = {
      ...values,
    };
    setEnvs([...arr]);
  };

  const handleAddEnv = () => {
    let arr = [...envs];
    arr.push({
      id: envs.length,
      key: "",
      value: "",
    });
    setEnvs([...arr]);
  };

  if (envs.length === 0) {
    handleAddEnv();
  }

  const handleDeleteEnv = (index) => {
    let arr = [...envs];
    arr = arr.filter((row, idx) => idx !== index);
    setEnvs([...arr]);
  };

  const handlePortChange = (e) => {
    const val = e.target.value;
    setPort(val);
  };

  const handleChangeSwitchMinimum = (e) => {
    setSizing({
      ...sizing,
      requests: { ...sizing?.requests, [e.target.name]: e.target.checked },
    });
    if (sizing?.requests?.memorytype) {
      setSizing({
        ...sizing,
        requests: {
          ...sizing?.requests,
          memory: `${sizing?.requests?.memory.replace("Mi", "") * 1024}`,
          [e.target.name]: e.target.checked,
        },
      });
    } else {
      setSizing({
        ...sizing,
        requests: {
          ...sizing?.requests,
          memory: `${sizing?.requests?.memory.replace("Mi", "") / 1024}`,
          [e.target.name]: e.target.checked,
        },
      });
    }
  };

  const handleChangeSwitchMaximum = (e) => {
    setSizing({
      ...sizing,
      limits: { ...sizing.limits, [e.target.name]: e.target.checked },
    });
    if (sizing?.limits?.memorytype) {
      setSizing({
        ...sizing,
        limits: {
          ...sizing?.limits,
          memory: `${sizing?.limits?.memory.replace("Mi", "") * 1024}`,
          [e.target.name]: e.target.checked,
        },
      });
    } else {
      setSizing({
        ...sizing,
        limits: {
          ...sizing?.limits,
          memory: `${sizing?.limits?.memory.replace("Mi", "") / 1024}`,
          [e.target.name]: e.target.checked,
        },
      });
    }
  };

  // useEffect(() => {
  //   deployments?.map((item) => {
  //     if (item.container_name === ContainerName) {
  //       if (item.dependencies === true) {
  //         setIsDependency(true);
  //       }
  //       // setVolume(item?.Volumes);
  //       setSizing(item?.resources);
  //     }
  //   });
  // }, [deployments]);

  useEffect(() => {
    setIsLoader(true);
    const getDeployment = async () => {
      const { data, _status } = await FetchDeploymentById(deploymentId);
      if (_status === 200) {
        setIsLoader(false);

        setDeployment(data);

        const CheckDependencies = data?.deployments?.find(
          (item) => item.container_name === ContainerName
        );
        if (CheckDependencies?.dependencies) {
          setIsDependency(true);
        }

        const deploymentDetails = data?.deploymentDetails?.find(
          (item) => item.ContainerName === ContainerName
        );

        setSelectedContainer(deploymentDetails);
        if (deploymentDetails?.Status === "deleted") {
          setDeletedContainer(true);
        }

        if (deploymentDetails?.Volumes) {
          if (
            deploymentDetails?.Volumes[0]?.VolumeType === "awsElasticBlockStore"
          ) {
            setVolumeType("AWSEBS");
            setShowFileSystemType(true);
            setFileSystemType("ext4");
          } else {
            setShowFileSystemType(false);
            setVolumeType("PVC");
          }
          if (deploymentDetails?.Volumes) {
            setVolume(deploymentDetails?.Volumes[0]);
          }
        }

        setReplicas(deploymentDetails?.Replicas);

        const serializeSizingData = {
          limits: {
            cpu: deploymentDetails?.Resources?.Limits?.CPU,
            memory: deploymentDetails?.Resources?.Limits?.Memory.replace(
              "Mi",
              ""
            ),
          },
          requests: {
            cpu: deploymentDetails?.Resources?.Requests?.CPU,
            memory: deploymentDetails?.Resources?.Requests?.Memory.replace(
              "Mi",
              ""
            ),
          },
        };

        setSizing(serializeSizingData);

        setImageID(deploymentDetails?.ImageURL);
        let arr = [];
        let environment = deploymentDetails?.Environment || {};

        Object.keys(environment).map((key, index) => {
          arr.push({
            id: index,
            key: key,
            value: environment[key],
          });
        });
        setPort(deploymentDetails?.Port);
        setEnvs([...arr]);
        setRestart(deploymentDetails?.RestartPolicy);
      } else {
        enqueueSnackbar(
          "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    };

    getDeployment();
  }, []);

  useEffect(() => {
    imagesList?.map((myitem) => {
      if (
        `${myitem.imagename}:${myitem.imagetag}` ===
        selectedContainer?.ImageName
      ) {
        setSelectedImage(myitem);
      }
    });
  }, [selectedContainer, imagesList]);

  useEffect(() => {
    let sizingData;
  

    if (sizing?.limits?.memorytype) {
      sizingData = {
        ...sizing,
        limits: {
          ...sizing?.limits,
          memory: `${sizing?.limits?.memory * 1024}Mi`,
        },
      };
    } else {
      sizingData = {
        ...sizing,
        limits: { ...sizing?.limits, memory: `${sizing?.limits?.memory}Mi` },
      };
    }

    if (sizing?.requests?.memorytype) {
      sizingData = {
        ...sizingData,
        requests: {
          ...sizing?.requests,
          memory: `${sizing?.requests?.memory * 1024}Mi`,
        },
      };
    } else {
      sizingData = {
        ...sizingData,
        requests: {
          ...sizing?.requests,
          memory: `${sizing?.requests?.memory}Mi`,
        },
      };
    }

    setSubmitData({
      ...submitData,
      Volumes: volume,
      replicas: parseInt(replicas),
      restart: restart,
      resources: sizingData,
      environment: envs,
      port: port?.toString(),
      image: selectedImage
        ? `${selectedImage?.imagename}:${selectedImage?.imagetag}`
        : image,
    });
  }, [volume, replicas, restart, sizing, envs, port, imageID, image]);

  useEffect(async () => {
    const fetchImageList = async () => {
      const { _status, _msg, data } = await getImageList();
      if (_status === 200) {
        setImagesList(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchImageList();
  }, []);

  const EnvironmentVariable = (field) => {
    return (
      <div>
        <TableContainer>
          <Table
            className={styles.customTable}
            style={{ backgroundColor: "#fff" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell align="left">Value</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {envs.map((row, index) => {
                if (
                  row.key.includes("pwd") ||
                  row.key.includes("password") ||
                  row.key.includes("credentials") || row.key.includes("pass")
                ) {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <input
                          type="text"
                          placeholder="Key"
                          name={row.id}
                          className={styles.input}
                          value={row.key}
                          onChange={(e) =>
                            handleEnvChange(
                              {
                                ...row,
                                key: e.target.value,
                              },
                              index
                            )
                          }
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="password"
                          placeholder="Value"
                          name={row.id}
                          className={styles.input}
                          value={row.value}
                          onChange={(e) =>
                            handleEnvChange(
                              { ...row, value: e.target.value },
                              index
                            )
                          }
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={() => handleDeleteEnv(index)}
                          className={styles.actionIcon}
                        >
                          <img src="/images/deleteLine.svg" alt="card" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <input
                          type="text"
                          placeholder="Key"
                          name={row.id}
                          className={styles.input}
                          value={row.key}
                          onChange={(e) =>
                            handleEnvChange(
                              {
                                ...row,
                                key: e.target.value,
                              },
                              index
                            )
                          }
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          placeholder="Value"
                          name={row.id}
                          className={styles.input}
                          value={row.value}
                          onChange={(e) =>
                            handleEnvChange(
                              { ...row, value: e.target.value },
                              index
                            )
                          }
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={() => handleDeleteEnv(index)}
                          className={styles.actionIcon}
                        >
                          <img src="/images/deleteLine.svg" alt="card" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
            {envs.length === 0 && (
              <div className={styles.nodata}>
                <text>No Data Available</text>
              </div>
            )}
          </Table>
        </TableContainer>
        <div
          style={{
            backgroundColor: "#fff",
            paddingLeft: "2rem",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            onClick={handleAddEnv}
            className={styles.actionIcon}
          >
            <AddIcon />
          </IconButton>
        </div>
        {/* )} */}
      </div>
    );
  };

  return (
    <>
      {isLoader && <Loader />}
      <main className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Deployment List", path: "/host" },
              {
                title: deployment?.deploymentname,
                path: `/host/details/${deploymentId}`,
              },
              { title: "Edit " + ContainerName },
            ]}
          />

          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Edit {ContainerName}</h2>
            <Grid
              // xs={ 2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "flex-end",
              }}
            >
              <AppButton
                color="primary"
                buttonName="Cancel"
                variant="contained"
                className="btnsmall"
                onClick={handleCancelEditContainer}
              />
              &nbsp;&nbsp;
              <AppButton
                color="primary"
                buttonName="Redeploy"
                variant="contained"
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
        <Paper className={styles.pageContent}>
          <div>
            <Grid
              container
              spacing={4}
              // xs={12}
              style={{ marginTop: "0rem", width: "100%", display: "flex" }}
            >
              {" "}
              <Grid item xs={2} sm={4}>
                <>
                  <label htmlFor="" className={style.fieldHeading}>
                    Container Name{" "}
                  </label>
                  <input
                    type="text"
                    name="container_name"
                    className={`${style.input2}`}
                    value={
                      selectedContainer?.ContainerName
                        ? selectedContainer?.ContainerName
                        : ""
                    }
                    disabled={true}
                  />
                </>
              </Grid>
              <Grid item xs={2} sm={4}>
                <>
                  <label htmlFor="" className={style.fieldHeading}>
                    Port{" "}
                  </label>
                  <input
                    type="text"
                    name="port"
                    className={isDependency ? style.input : style.input2}
                    value={port}
                    onChange={handlePortChange}
                    disabled={!isDependency}
                  />
                </>
              </Grid>
              <Grid item xs={2} sm={3}>
                <>
                  <label htmlFor="" className={style.fieldHeading}>
                    Replica{" "}
                  </label>
                  <input
                    type="text"
                    name="replicas"
                    className={`${style.input}`}
                    value={replicas}
                    //   autoComplete="off"
                    onChange={handleReplicaChange}
                  />
                </>
              </Grid>
              <Grid item xs={1} sm={1}>
                <>
                  <label htmlFor="" className={style.fieldHeading}>
                    Restart{" "}
                  </label>
                  <br />
                  <Checkbox
                    color="primary"
                    name="restart"
                    checked={restart === "Always" ? true : false}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    onChange={handleRestartCheckbox}
                  />
                </>
              </Grid>
              {isDependency && (
                <Grid item xs={2} sm={4}>
                  <>
                    <label htmlFor="" className={style.fieldHeading}>
                      Image ID{" "}
                    </label>
                    <Autocomplete
                      style={{ width: "100%" }}
                      options={selectedContainer ? imagesList : []}
                      getOptionLabel={(option) => option?.imagename}
                      value={
                        selectedContainer
                          ? imagesList?.find(
                              (item) =>
                                `${item.imagename}:${item.imagetag}` ===
                                selectedContainer?.ImageName
                            )
                          : ""
                      }
                      disabled={!isDependency}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Image"
                        />
                      )}
                    />
                  </>
                </Grid>
              )}
              {!isDependency && (
                <Grid item xs={2} sm={4}>
                  <>
                    <label htmlFor="" className={style.fieldHeading}>
                      Image ID{" "}
                    </label>

                    <input
                      type="text"
                      name="selectedImage"
                      className={`${style.input2}`}
                      value={
                        selectedImage?.imagename ? selectedImage?.imagename : ""
                      }
                      disabled={true}
                    />
                  </>
                </Grid>
              )}
            </Grid>
          </div>
          <Grid item xs={12} sm={12}>
            <Accordion className={style.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={style.accordianIcon} />
                }
              >
                <Typography className={style.sectionHeading}>
                  Environment Variables
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{EnvironmentVariable()}</AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion className={style.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={style.accordianIcon} />
                }
              >
                <Typography className={style.sectionHeading}>Volume</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={4}
                  style={{
                    marginTop: "0rem",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {" "}
                  <Grid item xs={2} sm={4}>
                    <>
                      <label htmlFor="" className={style.fieldHeading}>
                        Name{" "}
                      </label>
                      <input
                        type="text"
                        name="Name"
                        className={`${style.input} ${
                          volError?.Name && style.error
                        }`}
                        disabled={!isEditMode}
                        value={volume?.Name}
                        autoComplete="off"
                        onChange={handleVolumeChange}
                      />
                      {volError?.Name && (
                        <p
                          style={{ marginTop: "-8px" }}
                          className={styles.errorMsg}
                        >
                          Please enter Name
                        </p>
                      )}
                    </>
                  </Grid>
                  <Grid item xs={2} sm={4}>
                    <>
                      <label htmlFor="" className={style.fieldHeading}>
                        Mount path{" "}
                      </label>
                      <input
                        type="text"
                        name="Path"
                        className={`${style.input} ${
                          volError?.Path && style.error
                        }`}
                        disabled={!isEditMode}
                        value={volume?.Path}
                        autoComplete="off"
                        onChange={handleVolumeChange}
                      />
                      {volError?.Path && (
                        <p
                          style={{ marginTop: "-8px" }}
                          className={styles.errorMsg}
                        >
                          Please enter Mount path
                        </p>
                      )}
                    </>
                  </Grid>
                  {showFileSystemType && (
                    <Grid item xs={2} sm={4}>
                      <>
                        <label htmlFor="" className={style.fieldHeading}>
                          File System Type{" "}
                        </label>
                        <Select
                          name="filesystemtype"
                          className={`${style.select} ${
                            volError?.FileSystemType && style.error
                          }`}
                          disabled={!isEditMode}
                          onChange={handleFileSystemType}
                          value={fileSystemType ? fileSystemType : ""}
                        >
                          {fileSystems?.map((item) => {
                            return (
                              <MenuItem
                                name="filesystemtype"
                                value={item}
                                key={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {volError?.FileSystemType && (
                          <p
                            style={{ marginTop: "-8px" }}
                            className={styles.errorMsg}
                          >
                            Please select File System Type
                          </p>
                        )}
                      </>
                    </Grid>
                  )}
                  <Grid item xs={2} sm={4}>
                    <>
                      <label htmlFor="" className={style.fieldHeading}>
                        Identifier{" "}
                      </label>
                      <input
                        type="text"
                        name="VolumeID"
                        className={`${style.input} ${
                          volError?.VolumeID && style.error
                        }`}
                        disabled={!isEditMode}
                        value={volume?.VolumeID}
                        autoComplete="off"
                        onChange={handleVolumeChange}
                      />
                      {volError?.VolumeID && (
                        <p
                          style={{ marginTop: "-8px" }}
                          className={styles.errorMsg}
                        >
                          Please enter Identifier
                        </p>
                      )}
                    </>
                  </Grid>
                  <Grid item xs={2} sm={4}>
                    <>
                      <label htmlFor="" className={style.fieldHeading}>
                        Type{" "}
                      </label>
                      <Select
                        name="volumeType"
                        className={`${style.select} ${
                          volError?.VolumeType && style.error
                        }`}
                        disabled={!isEditMode}
                        onChange={handleVolumeType}
                        value={volumeType ? volumeType : ""}
                      >
                        {volumeTypeArray?.map((item) => {
                          return (
                            <MenuItem
                              name="filesystemtype"
                              value={item}
                              key={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {volError?.VolumeType && (
                        <p
                          style={{ marginTop: "-4px" }}
                          className={styles.errorMsg}
                        >
                          Please select Volume Type
                        </p>
                      )}
                    </>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion className={style.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={style.accordianIcon} />
                }
              >
                <Typography className={style.sectionHeading}>Sizing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary>
                        <Typography
                          className={style.fieldHeading}
                          style={{ marginLeft: "20px" }}
                        >
                          {" "}
                          {"   "}RAM
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          sm={12}
                          xs={12}
                          spacing={3}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Grid item xs={6}>
                            <div>
                              <label htmlFor="" className={style.fieldHeading}>
                                Minimum
                              </label>
                              <input
                                type="text"
                                placeholder="Min"
                                name="RAMmin"
                                className={styles.input}
                                value={
                                  sizing?.requests?.memorytype
                                    ? sizing?.requests?.memory?.replace(
                                        "Mi",
                                        ""
                                      )
                                    : sizing?.requests?.memory?.replace(
                                        "Mi",
                                        ""
                                      )
                                }
                                onChange={(e) =>
                                  sizing?.requests?.memoryType
                                    ? setSizing({
                                        ...sizing,
                                        requests: {
                                          ...sizing?.requests,
                                          memory: `${e.target.value / 1024}`,
                                        },
                                      })
                                    : setSizing({
                                        ...sizing,
                                        requests: {
                                          ...sizing?.requests,
                                          memory: `${e.target.value}`,
                                        },
                                      })
                                }
                                autoComplete="off"
                              />

                              <span style={{ marginLeft: 20 }}>
                                MB
                                <Switch
                                  name="memorytype"
                                  checked={sizing?.requests?.memorytype}
                                  onChange={handleChangeSwitchMinimum}
                                  color="default"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />{" "}
                                GB
                              </span>
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div>
                              <label htmlFor="" className={style.fieldHeading}>
                                Maximum
                              </label>
                              <input
                                type="text"
                                placeholder="Max"
                                name="RAMmax"
                                className={styles.input}
                                value={
                                  sizing?.limits?.memorytype
                                    ? sizing?.limits?.memory?.replace("Mi", "")
                                    : sizing?.limits?.memory?.replace("Mi", "")
                                }
                                onChange={(e) =>
                                  sizing?.limits?.memoryType
                                    ? setSizing({
                                        ...sizing,
                                        limits: {
                                          ...sizing?.limits,
                                          memory: `${e.target.value / 1024}`,
                                        },
                                      })
                                    : setSizing({
                                        ...sizing,
                                        limits: {
                                          ...sizing?.limits,
                                          memory: `${e.target.value}`,
                                        },
                                      })
                                }
                                autoComplete="off"
                              />

                              <span style={{ marginLeft: 20 }}>
                                MB
                                <Switch
                                  name="memorytype"
                                  checked={sizing?.limits?.memorytype}
                                  onChange={handleChangeSwitchMaximum}
                                  color="default"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />{" "}
                                GB
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary>
                        <Typography
                          className={style.sectionHeading}
                          style={{ marginLeft: "20px" }}
                        >
                          CPU
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <div>
                              <label htmlFor="" className={style.fieldHeading}>
                                Minimum
                              </label>

                              <input
                                type="text"
                                placeholder="Min"
                                name="cpu"
                                className={styles.input}
                                value={
                                  sizing?.requests?.cpu
                                }
                                onChange={(e) => {
                                  setSizing({
                                    ...sizing,
                                    requests: {
                                      ...sizing.requests,
                                      cpu: `${e.target.value}`,
                                    },
                                  });
                                  // if (
                                  //   e.target.value < 9 &&
                                  //   e.target.value > 2
                                  // ) {
                                  //   setError({
                                  //     ...error,
                                  //     requests: {
                                  //       ...error.requests,
                                  //       cpu: false,
                                  //     },
                                  //   });
                                    
                                  // } else {
                                  //   setError({
                                  //     ...error,
                                  //     requests: {
                                  //       ...error.requests,
                                  //       cpu: true,
                                  //     },
                                  //   });
                                  // }
                                }}
                                autoComplete="off"
                              />
                            </div>
                            {error?.requests?.cpu && (
                              <p className={styles.errorMsg}>
                                Invalid CPU value.Value should be between 3-8
                              </p>
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            <div>
                              <label htmlFor="" className={style.fieldHeading}>
                                Maximum
                              </label>

                              <input
                                type="text"
                                placeholder="Max"
                                name="CPUmax"
                                className={styles.input}
                                value={
                                  sizing?.limits?.cpu
                                }
                                onChange={(e) => {
                                  
                                  setSizing({
                                    ...sizing,
                                    limits: {
                                      ...sizing.limits,
                                      cpu: `${e.target.value}`,
                                    },
                                  });
                                  // if (
                                  //   e.target.value < 9 &&
                                  //   e.target.value > 2
                                  // ) {
                                  //   setError({
                                  //     ...error,
                                  //     limits: {
                                  //       ...error.limits,
                                  //       cpu: false,
                                  //     },
                                  //   });

                                  // } else {
                                  //   setError({
                                  //     ...error,
                                  //     limits: {
                                  //       ...error.limits,
                                  //       cpu: true,
                                  //     },
                                  //   });
                                  // }
                                }}
                                autoComplete="off"
                              />
                            </div>
                            {error?.limits?.cpu && (
                              <p className={styles.errorMsg}>
                                Invalid CPU value.Value should be between 3-8
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Paper>
      </main>
    </>
  );
};

export default EditContainer2;
