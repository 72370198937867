import { GET_PROJECTS, DELETE_PROJECT_BY_ID, NEW_PROJECT_DATA, PROJECT_FILTERS, PROJECT_SORT, ALTER_FAVOURITE_PROJECT_BY_ID, ALTER_SUBSCRIBE_PROJECT_BY_ID, CLEAR_PROJECTS, PROJECT_HIDDEN, CLEAR_SERVICEGROUP_EDITMODE, CHANGE_SERVICEGROUP_MODE } from '../actionTypes';
import { v4 as uuidv4 } from "uuid";

const initialState = {
    projectsList: {
        data: [],
        error: false,
        loading: false
    },
    newProjectData: {
        name: "",
        description: "",
        languages: [],
        teams: [],
        status: '',
        newServicegroupsList: []
    },
    projectFilters: [
        { id: uuidv4(), column: '', operator: '', value: '' }
    ],
    projectSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    projectHidden: ["name", "createdby", "createdon", "status", "action"],

    serviceGroupMode : "",
    selectedServiceGroup: {}

}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECTS: {
            return {
                ...state,
                projectsList: {
                    ...state.projectsList,
                    ...action.payload,
                    deleteProjectStatus: false,
                }
            }
        }
        case NEW_PROJECT_DATA: {
            return {
                ...state,
                newProjectData: {
                    ...state.newProjectData,
                    ...action.payload
                }
            }
        }
        case PROJECT_FILTERS: {
            return {
                ...state,
                projectFilters: [
                    ...action.payload
                ]
            }
        }
        case PROJECT_SORT: {
            return {
                ...state,
                projectSort: [
                    ...action.payload
                ]
            }
        }
        case PROJECT_HIDDEN: {
            return {
                ...state,
                projectHidden: [
                    ...action.payload
                ]
            }
        }
        case ALTER_FAVOURITE_PROJECT_BY_ID: {
            return {
                ...state,
                projectsList: {
                    ...state.projectsList,
                    data: {
                        ...state.projectsList.data,
                        data: state.projectsList.data.data.length !== 0 && state.projectsList.data.data.map(proj => {
                            if (proj._id === action.payload.projectId) {
                                return { ...proj, isFavourite: !proj.isFavourite }
                            } else {
                                return proj
                            }
                        })
                    }
                }
            }
        }

        case ALTER_SUBSCRIBE_PROJECT_BY_ID: {
            return {
                ...state,
                projectsList: {
                    ...state.projectsList,
                    data: {
                        ...state.projectsList.data,
                        data: state.projectsList.data.data.length !== 0 && state.projectsList.data.data.map(proj => {
                            if (proj._id === action.payload.projectId) {
                                return { ...proj, isSubscribe: !proj.isSubscribe }
                            } else {
                                return proj
                            }
                        })
                    }
                }
            }
        }

        case DELETE_PROJECT_BY_ID: {
            return {
                ...state,
                projectsList: {
                    ...state.projectsList,
                    data: {
                        ...state.projectsList.data,
                        data: state.projectsList.data.data.filter(proj => proj._id !== action.payload.projectId)
                    },
                    _totalcount: state.projectsList._totalcount - 1,
                    deleteProjectStatus: true,

                }
            }
        }
        case CLEAR_PROJECTS: {
            return {
                ...state,
                projectsList: {}
            }
        }
        case CHANGE_SERVICEGROUP_MODE: {
            return {
                ...state,
                serviceGroupMode: action.payload.value,
                selectedServiceGroup: {...action.payload}
            }
        }
        // case CLEAR_SERVICEGROUP_EDITMODE: {
        //     return {
        //         ...state,
        //         serviceGroupMode: ''
        //     }
        // }
       
        default:
            return state;
    }
}

export default projectReducer;