import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import FileDropperWithLayout from "../../FileDropperWithLayout";
import useCheckPermission from "../../hooks/useCheckPermission";
import { DropzoneArea } from "material-ui-dropzone";
import TestSelectedDeploymentTable from "../../Tables/TestSelectedDeploymentTable";
import AddIcon from "@material-ui/icons/Add";
import { Checkbox } from "@material-ui/core";
import {
  SelectedTestDeploymentColumns,
  testDeploymentDefaultColumnWidths,
  testDeploymentTableColumnExtensions,
} from "../../../../constants/TestDeploymentTableConstant";

const CustomFieldGroupForTestExecutionCreate = ({
  section,
  payloadData,
  error,
  handleChange,
  handleUpload,
  setIsLoader,
  fileDetails,
  droppedDocument,
  handleDeleteClick,
  deployment,
  testData,
  scriptData,
  selectedDeployment,
  handleDeployment,
  envs,
  handleAddEnv,
  handleDeleteEnv,
  handleEnvChange,
  envData,
  setSelectedScript,
  setSelectedData,
  setEnvironmentData,
  testRerunData,
  tabbedView,
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();

  const [envOpen, setEnvOpen] = useState(false);
  const [deployOpen, setDeployOpen] = useState(false);
  const [dataOpen, setDataOpen] = useState(false);
  const [scriptOpen, setScriptOpen] = useState(false);

  const closeDropDown = () => {
    setEnvOpen(false);
    setDeployOpen(false);
    setDataOpen(false);
    setScriptOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const EnvironmentVariable = (field) => {
    return (
      <>
        <Grid item xs={3}>
          <label htmlFor="" className={styles.fieldHeading}>
            Environments
          </label>
          <Autocomplete
            open={envOpen}
            onOpen={() => setEnvOpen(true)}
            onClose={() => setEnvOpen(false)}
            options={envData ? envData : []}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            // value={selectedVersion ? selectedVersion : ""}
            className={`${styles.searchInput3}`}
            onChange={(e, input) => {
              setEnvironmentData(input);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search environment"
              />
            )}
          />
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: 80 }}>
          <Grid item xs={12} sm={12}>
            <TableContainer>
              <Table
                className={styles.customTable}
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingLeft: 20 }}></TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {envs.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ border: "0.5px solid #D5D5D5" }}
                    >
                      <TableCell scope="row">
                        <Checkbox
                          checked={envs[index].enabled}
                          onChange={(e) =>
                            handleEnvChange(e, row.id, "enabled")
                          }
                          name="memorytype"
                          color="primary"
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <input
                          type="text"
                          placeholder="Enter key"
                          name={row.id}
                          className={styles.input}
                          value={envs[index].key}
                          onChange={(e) => handleEnvChange(e, row.id, "key")}
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          placeholder="Enter Value"
                          name={row.id}
                          className={styles.input}
                          value={envs[index].value}
                          onChange={(e) => handleEnvChange(e, row.id, "value")}
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={() => handleDeleteEnv(row.id)}
                        >
                          <img src="/images/deleteLine.svg" alt="card" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "5px 0",
                borderLeft: "1px solid #E2E2E3",
                borderRight: "1px solid #E2E2E3",
                borderBottom: "1px solid #E2E2E3",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                onClick={handleAddEnv}
                className={styles.actionIcon}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const DeploymentField = (field) => {
    return (
      <>
        <Grid item xs={3}>
          <label htmlFor="" className={styles.fieldHeading}>
            {field?.fieldLabel}{" "}
            {field?.required === "TRUE" && (
              <span className={styles.red}>*</span>
            )}
          </label>
          <Autocomplete
            open={deployOpen}
            onOpen={() => setDeployOpen(true)}
            onClose={() => setDeployOpen(false)}
            options={deployment}
            getOptionLabel={(option) =>
              option.deploymentname ? option.deploymentname : ""
            }
            value={deployment?.find((item) => item?._id === payloadData?.deployment)}
            className={`${styles.searchInput3} ${
              error.deployment?.isError && styles.error
            }`}
            onChange={(e, input) => {
              handleDeployment(input);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search Deployment"
              />
            )}
          />
        </Grid>

        {error[field.datakey]?.isError && (
          <p className={styles.errorMsg} style={{ paddingTop: 5 }}>
            {error[field.datakey].msg}
          </p>
        )}

        <TestSelectedDeploymentTable
          columns={SelectedTestDeploymentColumns}
          rows={
            selectedDeployment?.deploymentDetails
              ? selectedDeployment?.deploymentDetails
              : []
          }
          isCustomHeight={true}
          defaultColumnWidths={testDeploymentDefaultColumnWidths}
          tableColumnExtensions={testDeploymentTableColumnExtensions}
        />
      </>
    );
  };

  const TestDataField = (field) => {
    return (
      <>
        <label htmlFor="" className={styles.fieldHeading}>
          {field?.fieldLabel}{" "}
          {field?.required === "TRUE" && <span className={styles.red}>*</span>}
        </label>
        <Autocomplete
          open={dataOpen}
          onOpen={() => setDataOpen(true)}
          onClose={() => setDataOpen(false)}
          options={testData}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          value={testData?.find((item) => item?._id === payloadData?.testdata)}
          className={`${styles.searchInput3} ${
            error.testdata?.isError && styles.error
          }`}
          onChange={(e, input) => {
            setSelectedData(input);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Search Data"
            />
          )}
        />
        {error[field.datakey]?.isError && (
          <p className={styles.errorMsg} style={{ paddingTop: 5 }}>
            {error[field.datakey].msg}
          </p>
        )}
      </>
    );
  };

  const ScriptField = (field) => {
    return (
      <>
        <label htmlFor="" className={styles.fieldHeading}>
          {field?.fieldLabel}{" "}
          {field?.required === "TRUE" && <span className={styles.red}>*</span>}
        </label>
        <Autocomplete
          open={scriptOpen}
          onOpen={() => setScriptOpen(true)}
          onClose={() => setScriptOpen(false)}
          options={scriptData ? scriptData : []}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          value={scriptData?.find((item) => item?._id === payloadData?.script)}
          className={`${styles.searchInput3} ${
            error.script?.isError && styles.error
          }`}
          onChange={(e, input) => {
            setSelectedScript(input);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Search Script"
            />
          )}
        />

        {error[field.datakey]?.isError && (
          <p className={styles.errorMsg} style={{ paddingTop: 5 }}>
            {error[field.datakey].msg}
          </p>
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Name") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  testRerunData?.name
                    ? testRerunData?.name
                    : payloadData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        if (field.name === "Executed By") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  testRerunData?.createdby
                    ? testRerunData?.created
                    : payloadData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${
                error[field.datakey]?.isError && styles.error
              }`}
              value={
                field.dataType === "date" && payloadData[field.datakey]
                  ? moment
                      .unix(payloadData[field.datakey])
                      .tz(userInfo.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                  : payloadData[field.datakey]
              }
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={payloadData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                payloadData[field.datakey] ? payloadData[field.datakey] : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${
                error[field.datakey]?.isError && styles.error
              }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg} style={{ paddingTop: 5 }}>
                {error[field.datakey].msg}
              </p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                payloadData[field.datakey] ? payloadData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  payloadData[`${field.datakey}_unit`]
                    ? payloadData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                }`}
                value={payloadData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                payloadData[field.datakey] ? payloadData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Deployment":
                          return (
                            <Grid item sm={12}>
                              {DeploymentField(field)}
                            </Grid>
                          );
                        case "Script":
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {ScriptField(field)}
                            </Grid>
                          );
                        case "Test Data":
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {TestDataField(field)}
                            </Grid>
                          );
                        case "Env Table":
                          return (
                            <Grid item xs={12} sm={12} key={field._id}>
                              {EnvironmentVariable(field)}
                            </Grid>
                          );
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;
  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForTestExecutionCreate);
