import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    fontContainer:{
        "& #font-picker":{
            border:`1px solid ${theme.palette.primary.main}`,
            background:'#fff',
            borderRadius:5,
            zIndex:999
        },
        "& .dropdown-button":{
            background:'none !important',
            borderRadius:8
        }
    }
}));
