import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";
const apiHelper = new ApiHelper();

export class VendorServices {
  getVendorList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/vendors?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getVendorDetailsByID(vendorID) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors/${vendorID}`;
    return apiHelper.get(uri);
  }
  createVendor(createVendorData) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors`;
    return apiHelper.post(uri, createVendorData);
  }
  editVendorByID(vendorID, EditVendorData) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors/${vendorID}`;
    return apiHelper.put(uri, EditVendorData);
  }
  blockVendor(vendorID, blockdata) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors/${vendorID}/block`;
    return apiHelper.post(uri, blockdata);
  }
  UnblockVendor(vendorID, blockdata) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors/${vendorID}/unblock`;
    return apiHelper.post(uri, blockdata);
  }
  getVendorTeamDetails(vendorID) {
    const uri = `${BASE_URL}/api/v1.0.0/vendors/teams/${vendorID}`;
    return apiHelper.get(uri);
  }
  getVendorProjectDetails(vendorID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${vendorID}/assigned/project`;
    return apiHelper.get(uri);
  }
  getVendorEntity() {
    const uri = `${BASE_URL}/api/v1.0.0/ontology/resources/Vendor`;
    return apiHelper.get(uri);
  }
}
