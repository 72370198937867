import {
  ADD_RUNTIME_ENV_DATA,
  RUNTIMEENV_FILTER,
  DELETE_RUNTIMEENV,
  RUNTIMEENV_SORT,
  RUNTIMEENV_HIDDEN_COULMNS,
  CLEAR_RUNTIME_DATA,
  START_OR_STOP_RUNTIMEENV,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  runtimeEnv_list: {
    data: [],
    error: false,
    loading: false,
  },
  EnvFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  runtimeEnvSort: [{ columnName: "createdon", direction: "desc" }],
  ENVHiddenCols: [
    "name",
    "EnvVariableState",
    "createdby",
    "createdon",
    "runtimeenvactions",
  ],
};

export default function runtimeEnvReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RUNTIME_ENV_DATA: {
      return {
        ...state,
        runtimeEnv_list: {
          ...state.runtimeEnv_list,
          ...action.payload,
          deleteRuntimeEnvStatus: false,
        },
      };
    }
    case RUNTIMEENV_FILTER: {
      return {
        ...state,
        EnvFilters: [...action.payload],
      };
    }
    case RUNTIMEENV_SORT: {
      return {
        ...state,
        runtimeEnvSort: [...action.payload],
      };
    }
    case DELETE_RUNTIMEENV: {
      return {
        ...state,
        runtimeEnv_list: {
          ...state.runtimeEnv_list,
          data:
            state.runtimeEnv_list.data &&
            state.runtimeEnv_list.data.filter(
              (env) => env._id !== action.payload.EnvID
            ),
          deleteRuntimeEnvStatus: true,
        },
      };
    }
    case RUNTIMEENV_HIDDEN_COULMNS: {
      return {
        ...state,
        ENVHiddenCols: [...action.payload],
      };
    }
    case CLEAR_RUNTIME_DATA: {
      return {
        ...state,
        runtimeEnv_list: {},
      };
    }
    case START_OR_STOP_RUNTIMEENV: {
      return {
        ...state,
        runtimeEnv_list: {
          ...state.runtimeEnv_list,
          data:
            state.runtimeEnv_list.data &&
            state.runtimeEnv_list.data.map((item) => {
              if (item._id === action.payload.EnvID) {
                return {
                  ...item,
                  state: action.payload.state,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    default:
      return state;
  }
}
