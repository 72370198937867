import React, { useState } from "react";
import customFilterStyles from "./styles/customFilterStyles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Badge,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import { AppButton } from "./Button";
import { v4 as uuidv4 } from "uuid";
import { Cancel } from "@material-ui/icons";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {
  DateRangePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@material-ui/pickers";
import { handleFormValidationForFilters } from "../../utils/common";

const initError = (id) => ({
  id: id,
  column: {
    isError: false,
    msg: "",
  },
  operator: {
    isError: false,
    msg: "",
  },
  value: {
    isError: false,
    msg: "",
  },
});

const operators = [
  {
    title: "Contains",
    exp: "con",
  },
  {
    title: "Does not contain",
    exp: "notcon",
  },
  {
    title: "Starts with",
    exp: "start",
  },
  {
    title: "Ends with",
    exp: "end",
  },
  {
    title: "Equals",
    exp: "eq",
  },
  {
    title: "Does not Equals",
    exp: "noteq",
  },
];
const operatorNumber = [
  {
    title: "Equals",
    exp: "eq",
  },
  {
    title: "Not Equals",
    exp: "noteq",
  },
  {
    title: "Less Than",
    exp: "less",
  },
  {
    title: "Greater Than",
    exp: "greater",
  },
  {
    title: "Less Than Equal To",
    exp: "lessequal",
  },
  {
    title: "Greater Than Equal To",
    exp: "greaterequal",
  },
  {
    title: "Between",
    exp: "between",
  },
];

const operatorDate = [
  {
    title: "Equals",
    exp: "eqdate",
  },
  {
    title: "Not Equals",
    exp: "noteqdate",
  },
  {
    title: "Less Than",
    exp: "less",
  },
  {
    title: "Greater Than",
    exp: "greater",
  },
  {
    title: "Less Than Equal To",
    exp: "lessequal",
  },
  {
    title: "Greater Than Equal To",
    exp: "greaterequal",
  },
  {
    title: "Between",
    exp: "between",
  },
];

const CustomFilter = (props) => {
  const filterStyles = customFilterStyles();
  const [filterEl, setFilterEl] = useState(null);
  const isFilterOpen = Boolean(filterEl);
  const { filters, setFilters, handleSubmitFilter } = props;
  const [error, setError] = useState([initError(filters[0]?.id)]);

  const handleFilterChange = (event, id, column) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return { ...e, [column]: { ...e[column], isError: false } };
      } else {
        return e;
      }
    });

    setError(tempError);

    const newFilter = filters.map((filter) => {
      if (filter.id === id) {
        return { ...filter, [column]: event.target.value };
      } else {
        return filter;
      }
    });

    setFilters(newFilter);
  };

  const handleAddFilter = () => {
    const id = uuidv4();
    setFilters([...filters, { id: id, column: "", operator: "", value: "" }]);
    setError([...error, initError(id)]);
  };

  const handleDeleteFilter = (filterToDelete) => {
    if (filters.length > 1) {
      setFilters(filters.filter((filter) => filter.id !== filterToDelete));
      setError(error.filter((err) => err.id !== filterToDelete));
    }
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <div className={filterStyles.Filter}>
        <Badge
          badgeContent={
            filters &&
            filters.length > 0 &&
            filters[0].column && (
              <Cancel
                className={filterStyles.cancelIcon}
                onClick={() => {
                  const id = uuidv4();
                  setError([initError(id)]);
                  setFilters([{ id: id, column: "", operator: "", value: "" }]);
                  props.handleClearFilters();
                }}
              />
            )
          }
        >
          <div
            className={filterStyles.FilterBox}
            onClick={(e) => setFilterEl(e.currentTarget)}
          >
            <img src="/images/teams/filter-icon.svg" alt="" />
            <span>Filter</span>
            <span style={{ marginTop: 5 }}>
              <KeyboardArrowDownIcon />
            </span>
          </div>
        </Badge>
        <Popover
          disableEnforceFocus
          open={isFilterOpen}
          anchorEl={filterEl}
          className={filterStyles.filterPopUp}
          onClose={() => setFilterEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={filterStyles.filterWrap}>
            <div className={filterStyles.filterHeader}>Filters</div>
            <div className={filterStyles.filterContent}>
              {filters &&
                filters?.length > 0 &&
                filters?.map((filter, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={4} sm={4}>
                      {index === 0 && (
                        <label htmlFor="" className={filterStyles.fieldHeading}>
                          Columns
                        </label>
                      )}
                      <Select
                        id="custom-columns-operator-dropdown"
                        value={filter.column}
                        onChange={(e) =>
                          handleFilterChange(e, filter.id, "column")
                        }
                        displayEmpty
                        // disabled
                        defaultValue={props?.columns[0]?.name}
                        name={filter.id}
                        className={`${filterStyles.select} ${
                          error[index]?.column.isError && filterStyles.error
                        }`}
                        style={{ paddingRight: 0 }}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {props.columns
                          .filter(
                            (c) =>
                              c.title !== "Action" &&
                              c.title !== "Actions" &&
                              c.name !== "actions" &&
                              c.name !== "icons" &&
                              c.name !== "vendorProject" &&
                              c.name !== "projectAssigned" &&
                              c.name !== "userteams" &&
                              c.name !== "teamMember" &&
                              c.name !== "canvas"
                          )
                          .map((column, index) => (
                            <MenuItem
                              value={column?.name}
                              key={index}
                              disabled={
                                filters.filter((f) => f.column === column.name)
                                  .length !== 0
                                  ? true
                                  : false
                              }
                            >
                              {column?.title}
                            </MenuItem>
                          ))}
                      </Select>
                      {error[index]?.column.isError && (
                        <p className={filterStyles.errorMsg}>
                          {error[index]?.column.msg}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {index === 0 && (
                        <label htmlFor="" className={filterStyles.fieldHeading}>
                          Operators
                        </label>
                      )}
                      <Select
                        value={filter.operator}
                        onChange={(e) =>
                          handleFilterChange(e, filter.id, "operator")
                        }
                        defaultValue={operators[0].exp}
                        name={filter.id}
                        className={`${filterStyles.select} ${
                          error[index]?.operator.isError && filterStyles.error
                        }`}
                        style={{ paddingRight: 0 }}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {filter.column === "Created On" ||
                        filter.column === "CreatedOn" ||
                        filter.column === "createdOn" ||
                        filter.column === "created On" ||
                        filter.column === "createdon" ||
                        filter.column === "Updated On" ||
                        filter.column === "UpdatedOn" ||
                        filter.column === "updatedOn" ||
                        filter.column === "updated On" ||
                        filter.column === "updatedon"
                          ? operatorDate.map((op, index) => (
                              <MenuItem value={op.exp} key={index}>
                                {op.title}
                              </MenuItem>
                            ))
                          : filter.column === "number"
                          ? operatorNumber.map((op, index) => (
                              <MenuItem value={op.exp} key={index}>
                                {op.title}
                              </MenuItem>
                            ))
                          : operators.map((op, index) => (
                              <MenuItem value={op.exp} key={index}>
                                {op.title}
                              </MenuItem>
                            ))}
                      </Select>
                      {error[index]?.operator.isError && (
                        <p className={filterStyles.errorMsg}>
                          {error[index]?.operator.msg}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      {index === 0 && (
                        <label htmlFor="" className={filterStyles.fieldHeading}>
                          Value
                        </label>
                      )}
                      {filter.column === "Created On" ||
                      filter.column === "CreatedOn" ||
                      filter.column === "createdOn" ||
                      filter.column === "created On" ||
                      filter.column === "createdon" ||
                      filter.column === "Updated On" ||
                      filter.column === "UpdatedOn" ||
                      filter.column === "updatedOn" ||
                      filter.column === "updated On" ||
                      filter.column === "updatedon" ? (
                        filter.operator === "between" ? (
                          <DateRangePicker
                            calendars={1}
                            format="dd/MM/yyyy"
                            value={
                              filter.value
                                ? filter.value.length === 2
                                  ? filter.value
                                  : [null, null]
                                : [null, null]
                            }
                            onChange={(date) =>
                              handleFilterChange(
                                { target: { value: date } },
                                filter.id,
                                "value"
                              )
                            }
                            renderInput={(startProps, endProps) => (
                              <>
                                <div style={{ display: "flex" }}>
                                  <input
                                    ref={startProps.inputRef}
                                    {...startProps.inputProps}
                                    className={`${filterStyles.input} ${
                                      error[index]?.value.isError &&
                                      filterStyles.error
                                    }`}
                                  />
                                  <input
                                    ref={endProps.inputRef}
                                    {...endProps.inputProps}
                                    className={`${filterStyles.input} ${
                                      error[index]?.value.isError &&
                                      filterStyles.error
                                    }`}
                                  />
                                </div>
                                {error[index]?.value.isError && (
                                  <p className={filterStyles.errorMsg}>
                                    {error[index]?.value.msg}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        ) : (
                          <DesktopDatePicker
                            value={filter.value ? filter.value : null}
                            onChange={(date) =>
                              handleFilterChange(
                                { target: { value: [date] } },
                                filter.id,
                                "value"
                              )
                            }
                            inputFormat={"dd/MM/yyyy"}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    className={`${filterStyles.input} ${
                                      error[index]?.value.isError &&
                                      filterStyles.error
                                    }`}
                                  />
                                  {InputProps?.endAdornment}
                                </div>
                                {error[index]?.value.isError && (
                                  <p className={filterStyles.errorMsg}>
                                    {error[index]?.value.msg}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        )
                      ) : filter.column === "number" ? (
                        <>
                          {filter.operator === "between" ? (
                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="number"
                                  name="value"
                                  className={`${filterStyles.input} ${
                                    error[index]?.value.isError &&
                                    filterStyles.error
                                  }`}
                                  value={filter.value}
                                  onChange={(e) =>
                                    handleFilterChange(e, filter.id, "value")
                                  }
                                  autoComplete="off"
                                />

                                <input
                                  type="number"
                                  name="value2"
                                  className={`${filterStyles.input} ${
                                    error[index]?.value.isError &&
                                    filterStyles.error
                                  }`}
                                  value={filter.value2}
                                  onChange={(e) =>
                                    handleFilterChange(e, filter.id, "value2")
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type="number"
                                name="value"
                                className={`${filterStyles.input} ${
                                  error[index]?.value.isError &&
                                  filterStyles.error
                                }`}
                                value={filter.value}
                                onChange={(e) =>
                                  handleFilterChange(e, filter.id, "value")
                                }
                                autoComplete="off"
                              />
                              {error[index]?.value.isError && (
                                <p className={filterStyles.errorMsg}>
                                  {error[index]?.value.msg}
                                </p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name="value"
                            className={`${filterStyles.input} ${
                              error[index]?.value.isError && filterStyles.error
                            }`}
                            value={filter.value}
                            onChange={(e) =>
                              handleFilterChange(e, filter.id, "value")
                            }
                            autoComplete="off"
                          />
                          {error[index]?.value.isError && (
                            <p className={filterStyles.errorMsg}>
                              {error[index]?.value.msg}
                            </p>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        style={{ marginTop: index === 0 ? 20 : 0 }}
                        className={filterStyles.actionIcon}
                        onClick={() => handleDeleteFilter(filter.id)}
                      >
                        <Cancel className={filterStyles.cancelIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              {filters.length !== props.columns.length - 1 && (
                <div className={filterStyles.addBtn} onClick={handleAddFilter}>
                  +ADD
                </div>
              )}
            </div>
            <div className={filterStyles.filterFooter}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const id = uuidv4();
                  setError([initError(id)]);
                  setFilters([{ id: id, column: "", operator: "", value: "" }]);
                  props.handleClearFilters();
                }}
              >
                Clear Filters
              </span>
              <div className={filterStyles.actionButtons}>
                <AppButton
                  buttonName="Cancel"
                  variant="outlined"
                  disabled={false}
                  className="btnsmall"
                  onClick={() => setFilterEl(null)}
                />
                <AppButton
                  buttonName="Apply"
                  variant="contained"
                  disabled={false}
                  className="btnsmall"
                  onClick={() => {
                    const { formIsValid, errors } =
                      handleFormValidationForFilters(filters, error);
                    if (formIsValid) {
                      setFilterEl(null);
                      handleSubmitFilter();
                    } else {
                      setError(errors);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </LocalizationProvider>
  );
};

export default CustomFilter;
