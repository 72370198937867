import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    // flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  section1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 34,
    width: 34,
  },
  heading: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
  },
  heading1: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
    marginTop: 40,
  },
  heading2: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
  },
  condition: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    width: "900px"
  },
  pixel_14: {
    font: 'normal normal normal 14px/16px Nunito',
    paddingBottom: 13
  },
  condition_body: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15,
    marginLeft: 10
  },
  condition_line1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  condition_name: {
    display: "flex",
    flexDirection: "row",
  },
  cross: {
    fontSize: 20,
    fontWeight: "bold",
  },
  line2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: "0.5rem",
    marginRight: 20,
    width: 800,
    // marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {

    height: 38,
    width: 355,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  value_1: {
    height: 38,
    width: 420,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  expression: {
    marginTop: 20,
  },
  expression_body: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    marginTop: 14
  },
  codeIcon: {
    height: 29,
    width: 42,
    background: "#002855 0% 0% no-repeat padding-box",
    color: "white",
    marginLeft: 20,
    padding: 7,
    paddingLeft: 12,
  },
  codeIcon1: {
    height: 29,
    width: 42,
    backgroundColor: "#E6E6E6",
    color: "white",
    marginLeft: 20,
    padding: 7,
    paddingLeft: 12,
  },
  cancel: {
    height: 40,
    width: 100,
    borderWidth: 1,
    borderColor: "#002855",
    borderRadius: 10,
    color: "#002855",
    textAlign: "center",
    marginRight: 20,
    // backgroundColor: "white",
  },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: "#002855",
  },
  cancel_icon: {
    color: "#DB2828",
    fontWeight: "bold",
    fontSize: 16,
  },
  add_icon: {
    textAlign: "end",
    // height: 50,
    marginTop: 30,
    // position: "absolute",
    // bottom: 50,
    // right: 40,
    flexDirection: "row",
    alignItems: "center",
  },
  toggleicons: {
    margin: "0px 0px",
    cursor: "pointer",
    height: 29,
    width: 42,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      padding: 1,
      borderRadius: 1,
      color: "#fff",
    },
    "&.toggleActive": {
      // background: "#002855 0% 0% no-repeat padding-box",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      // padding: 5,
      margin: "0 0px",
      opacity: 1,
      "& img": {
        filter: "brightness(0) invert(1) ",
      },
    },
    "&.toggleInactive": {
      backgroundColor: "white",
      "& img": {
        filter: "brightness(1) invert(0) ",
        height: 29,
        width: 42,
      },
    }
  },
  condition_container: {
    // marginBottom: 80,
    height: 400,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'blue',
  },
  FilterBox: {
    display: "flex", flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 120,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D0D0D0",
    borderRadius: "3px",
    opacity: 1,
    padding: "0 5px",
    cursor: 'pointer',
    marginLeft: 20,
    marginRight: 20
  },
  filterPopUp: {
    marginTop: 5
  },
  filterWrap: {
    height: 400,
    width: 700,
    zIndex: 99999
  },
  filterHeader: {
    padding: '10px 20px',
    backgroundColor: theme.palette.background.default,
    font: 'normal normal 600 16px/22px Nunito',
    color: '#000000',
    width: '100%',
    margin: 0
  },
  filterContent: {
    padding: 20,
    maxHeight: 290,
    overflow: 'auto',

    "& .MuiGrid-container:first-child button": {
      marginTop: 15
    },
    "& .MuiGrid-container:not(:first-child) button": {
      marginTop: -5
    }
  },
  addBtn: {
    font: 'normal normal 600 14px/19px Nunito',
    color: '#002855',
    marginTop: 20,
    cursor: 'pointer',
    display: 'inline-block'
  },
  filterFooter: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '15px 20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #535353',

    "& span": {
      font: 'normal normal 600 16px/22px Nunito',
      cursor: 'pointer'
    }
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldHeading: {
    font: 'normal normal 500 16px/22px Nunito',
    letterSpacing: 0,
    color: '#000000',
    width: '100%',
    marginBottom: 5
  },
  input: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    padding: '0 15px',
    height: '35px',
    marginTop: 5,

    "&::before": {
      borderBottom: 'unset'
    },
  },
  select: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    height: '35px',
    marginTop: 5,

    "&::before": {
      borderBottom: 'unset'
    },

    "& .MuiSelect-select": {
      paddingLeft: 15
    }
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  },
  name: {
    display: "flex",
    flexDirection: "column"
  },
  outputbody: {
    // height: 100,
    width: 750,
    borderWidth: 1,
    // borderColor: "black",
    marginTop: 5,
    border: "1px solid #DEDEDF"
  },
}))
