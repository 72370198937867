import { Container, Divider, IconButton, Paper, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Icons
import AddIcon from "@material-ui/icons/Add";

// files
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import { useTranslation } from "react-i18next";

//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import Pagination from "@material-ui/lab/Pagination";
import { useSnackbar } from "notistack";

import {
  glossaryColumns,
  glossaryDefaultColumnWidths,
  glossaryTableColumnExtensions,
  glossaryColumnOrder,
  glossaryDefaultSorting,
} from "../../../constants/glossary/glossaryList";

// Redux
import { connect } from "react-redux";
import {
  fetchAllTags,
  addGlossaryFilters,
  clearGlossaryFilters,
  setGlossarySort,
  clearGlossaryListScreen,
} from "../../../redux/actions/dataStandardAction";

// Filter and Search
import CustomFilter from "../../../components/common/CustomFilter";
import { v4 as uuidv4 } from "uuid";
import {
  arrayToFilter,
  arrayToSort,
  getWindowDimensions,
  getColumnWidth,
} from "../../../utils/common";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const GlossaryListScreen = (props) => {
  const {
    tags,
    tagById,
    fetchAllTags,
    glossaryFilters,
    addGlossaryFilters,
    clearGlossaryFilters,
    glossarySort,
    setGlossarySort,
    alterActiveTab,
    deleteTagStatus,
    clearGlossaryListScreen,
  } = props;
  const history = useHistory();
  const styles = listScreenStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [listView, setListView] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [filters, setFilters] = useState(glossaryFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState([
    "updatedOn",
    "updatedBy",
  ]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [rows, setRows] = useState([]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  const [isFavouriteClick, setIsFavouriteClick] = useState(false);

  const [currentFavouritePage, setCurrentFavouritePage] = useState(1);

  function createData(tagData) {
    const {
      isFavourite,
    } = tagData;
    return {
      ...tagData,
      isFavourite: isFavourite,
    };
  }

  useEffect(() => {
    if (!isFavouriteClick) {
      setRows(
        tags?.data && tags?.data?.length > 0
          ? tags?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setRows(
        tags?.data && tags?.data?.length > 0
          ? tags?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [tags]);

  const handleToggleFavouriteTags = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(glossaryFilters);
      const parsedSort = arrayToSort(glossarySort);
      fetchAllTags(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort),
        10
      );
      setRows(
        tags?.data && tags?.data?.length > 0
          ? tags?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPage(1);
    } else {
      fetchAllTags();
      setRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavouritePage(1);
    }
  };

  useEffect(() => {
    checkScreenPermission("GlossaryTag", ["List All", "List Assigned"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [totalPageCount, setTotalPageCount] = useState(
    Math.ceil(tags.totalCount / 10)
  );
  useEffect(() => {
    if (tags.totalCount) setTotalPageCount(Math.ceil(tags.totalCount / 10));
  }, [tags.totalCount]);

  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(glossaryFilters);
    const parsedSort = arrayToSort(glossarySort);
    alterActiveTab("Glossary", "Glossary");
    fetchAllTags(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, glossarySort]);

  useEffect(() => {
    if(deleteTagStatus){
      const parsedFilters = arrayToFilter(glossaryFilters);
      const parsedSort = arrayToSort(glossarySort);
      alterActiveTab("Glossary", "Glossary");
      fetchAllTags(currentPage, parsedFilters, JSON.stringify(parsedSort));
    }
  }, [deleteTagStatus]);

  // const rows =
  //   tags?.data.map((item) => ({
  //     ...item,
  //   })) || [];

  const handleSearch = () => {
    if (searchInput) {
      const parsedSort = arrayToSort(glossarySort);
      fetchAllTags(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } }),
        parsedSort
      );
    } else {
      handleSubmitFilter();
    }
  };

  const handleSubmitFilter = () => {
    addGlossaryFilters(filters);
    const parsedSort = arrayToSort(glossarySort);
    const parsedFilters = arrayToFilter(filters);
    fetchAllTags(currentPage, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    clearGlossaryFilters();
    fetchAllTags(currentPage);
  };

  // useEffect(() => {
  //   return () => {
  //     clearGlossaryListScreen()
  //   }
  // }, [])
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Glossary", path: "/glossary" },
              { title: "Glossary List" },
            ]}
          />
          <div className={styles.topContentItems}>
            <div className={styles.glossaryTopContent}>
              <p className={styles.title}>
                Glossary List{" "}
                {tags?.data?.length > 0 ? `(${tags?.data?.length})` : ""}
              </p>
              {/* <p className={styles.title}>{t("glossary.glossaryList")}</p> */}
            </div>
            <div className={styles.glossaryTopContentBtns}>
              <AppButton
                buttonName="Create Glossary"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("GlossaryTag", ["Create"])}
                className="btnsmall"
                onClick={() => history.push("/create-glossary")}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={glossaryColumns}
                filters={filters}
                setFilters={(data) => setFilters(data)}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <Tooltip title="Favourite" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: "0px 10px" , marginTop:"-5px"}}
                  onClick={() => handleToggleFavouriteTags()}
                >
                  {isFavouriteClick ? (
                    <img src="/images/heartFilled.svg" alt="card" />
                  ) : (
                    <img src="/images/heartLine.svg" alt="card" />
                  )}
                </IconButton>
              </Tooltip>
              {/* <FilledInput
                id="filled-adornment-password"
                name="filterfn"
                onChange={(e) => debouceOnchange(e.target.value)}
                className={`${styles.input} ${styles.glossaryInput}`}
                autoComplete="off"
                placeholder="Search Name"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              /> */}
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
            </div>
          </div>
        </div>
        {/* Table */}
        {listView && (
          <Paper className={styles.pageContent}>
            <ApplicationListTable
              columns={glossaryColumns}
              rows={isFavouriteClick ? (
                rows?.length > 0
                  ? rows?.slice(
                    (currentFavouritePage - 1) * 10,
                    currentFavouritePage * 10
                  )
                  : []
              ) : (rows)}
              // defaultColumnWidths={glossaryDefaultColumnWidths}
              defaultColumnWidths={getColumnWidth(
                glossaryDefaultColumnWidths,
                windowDimensions
              )}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              tableColumnExtensions={glossaryTableColumnExtensions}
              columnOrder={glossaryColumnOrder}
              defaultSorting={glossaryDefaultSorting}
              loading={tags.loading}
              height={windowDimensions.height - 260}
              isCustomHeight={true}
              hideColumnChooser={true}
              sort={glossarySort}
              setSort={setGlossarySort}
            />
          </Paper>
        )}
        {tags.data.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={totalPageCount}
                page={currentPage}
                onChange={(e, val) => setCurrentPage(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllTags,
  addGlossaryFilters,
  clearGlossaryFilters,
  setGlossarySort,
  clearGlossaryListScreen,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { tags, tagById, glossaryFilters, glossarySort } =
    state.dataStandardReducer;
  const { deleteTagStatus } = tags;
  return { tags, tagById, glossaryFilters, glossarySort, deleteTagStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryListScreen);
