import { makeStyles } from "@material-ui/core/styles";

const displayFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export default makeStyles((theme) => ({
  menu: {
    "& .logoBox": {
      border: "1px solid #002855",
      width: "100%",
      height: 38,
      borderRadius: 5,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& label": { cursor: "pointer" },
    },
    "& .title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      width: 171,
      cursor: "pointer",
    },
    "& .titleLabel": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      margin: "0px 5px",
      textOverflow: "ellipsis",
    },
    "& .icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: 2,
      width: "45%",
    },
    "& .submenu": {
      border: "1px solid #002855",
      width: "90%",
      height: 38,
      borderRadius: 5,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      marginLeft: 20,
      cursor: "pointer",
      "& label": { cursor: "pointer" },
      "& .submenuIcon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "40%",
        paddingRight: 2,
        cursor: "pointer",
      },
    },
    "& .submenuchild": {
      border: "1px solid #002855",
      width: "80%",
      height: 38,
      borderRadius: 5,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      marginLeft: 40,
      cursor: "pointer",
      "& label": {
        cursor: "pointer",
      },

      "& .submenuchildIcon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "32.5%",
        paddingRight: 2,
        cursor: "pointer",
      },
    },
  },
  menuViewType: {
    fontWeight: 600,
    border: "1px solid black",
    padding: "0px 2px",
    fontSize: 12,
    marginTop: 5,
    height: 40,
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  addMenuButton: {
    ...displayFlex,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: 5,
    marginBottom: 5,
    marginTop: 5,
    justifyContent: "center",
    padding: 5,
    cursor: "pointer",
    border: "1px dashed",
  },
  LogoPopup: {
    "& .field": {
      display: "flex",
      flexDirection: "column",
      marginBottom: 10,

      "& label": {
        font: "normal normal 600 12px/16px Nunito",
        color: "#333333DE",
        textTransform: "capitalize",
      },

      "& input": {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #D8D8D8",
        borderRadius: 5,
        padding: 10,
        marginTop: 3,
        outline: "none",
        width: "100%",
      },
      "& .jsDataInput": {
        display: "flex",
      },
      "& .editorInput": {
        border: "1px solid #D8D8D8",
        position: "relative",
        borderRadius: 5,
        maxHeight: 250,
        minHeight: 40,
        overflow: "hidden",

        "& .CodeMirror": {
          height: "auto",
          padding: 4,
          borderRadius: 5,
          "& .CodeMirror-scroll .CodeMirror-sizer": {
            minHeight: "100% !important",
          },
        },
      },
      "& .inputWithIcon": {
        "& .icon": {
          background: "#F5F5F5 0% 0% no-repeat padding-box",
          border: "1px solid #D8D8D8",
          borderRadius: "0px 5px 5px 0px",
          height: 40,
          "& button": { padding: 8 },
        },
      },
    },
    "& .wrapper": {
      height: 600,
      width: 250,
      padding: 10,
    },
    "& .logoTitleBox": {
      ...displayFlex,
      "& .logoTitle": {
        font: "normal normal normal 14px/19px Nunito",
        fontSize: 18,
        fontWeight: 500,
        color: "black",
      },
      "& img": {
        height: 12,
        width: 12,
        cursor: "pointer",
      },
    },
    "& hr": { border: "1px solid #F0F0F0" },
    "& .uploadLogoWrapper": {
      marginTop: 10,
      "& .headerTypes": {
        ...displayFlex,
        color: "#333333DE",
        font: "normal normal bold 12px/16px Nunito",
        paddingRight: 10,
      },
    },
    "& .uploadLogo": {
      ...displayFlex,
      border: "1px solid #DFDFDF",
      borderRadius: 5,
      height: 38,
      width: "100%",
      "& .chooseFile": {
        marginLeft: "5px",
        width: "80%",
        "& .fileUrl": {
          overflow: "hidden",
          width: 100,
        },
      },
      "& .upload": {
        ...displayFlex,
        width: "20%",
        justifyContent: "center",
      },
    },
    "& .infoBoxTop": {
      ...displayFlex,
      marginTop: 15,
      background: "#f0f0f0",
      border: "1px solid #d8d8d8",
      borderRadius: "5px 5px 0 0",
      height: 38,
      width: "100%",
      padding: "0 10px",
      "& .infoTitle": {
        ...displayFlex,
        font: "normal normal 600 13px/18px Nunito",
        color: "#262626",
        width: "6rem",
      },
    },
    "& .infoBoxBottom": {
      ...displayFlex,
      padding: "5px 10px",
      display: "block",
      height: "14rem",
      width: "100%",
      background: "#f0f0f0",
      borderRadius: "0 0 5px 5px",
      "& .LeftBox": {
        display: "flex",
        // border: "1px solid",
        marginBottom: 5,
        "& .titleBox": {
          width: 85,
        },
        "& .title": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#262626",
          // border: "1px solid ",
          width: 100,
        },
      },
      "& .rightBox rightBox2 rightBox3 rightBox4": {
        marginLeft: 10,
      },
      "& .rightBox span": {
        display: "flex",
        justifyContent: "flex-start",
      },
      "& .rightBox2 span": {
        display: "flex",
        justifyContent: "flex-start",
      },
      "& .rightBox3 span": {
        justifyContent: "flex-start",
      },
      "& .rightBox4 span": {
        display: "flex",
        justifyContent: "flex-start",
      },
      "& .span1": {
        color: "#8C8C8C",
        font: "normal normal 600 10px/14px Nunito",
      },
      "& .span2": {
        // paddingLeft: 5,
        color: "#262626",
        font: "normal normal normal 12px/16px Nunito",
        fontWeight: 600,
      },
    },
  },
}));
