import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import listStyles from "../../CommonStyles/listScreenStyles";
import { fetchDeploymentById } from "../../../redux/actions/deploymentProcessAction";
import { ArrowDropDownRounded } from "@material-ui/icons";
import InputTable from "../../../components/common/Tables/InputTable";
import InputTableWithDisable from "../../../components/common/Tables/InputTableWithDisable";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { Autocomplete } from "@material-ui/lab";
import {
  fetchAITaskList,
  fetchAllServices,
  fetchFilteredServiceList,
  fetchAllUiServices,
  fetchVersionListData,
  fetchVersionById,
} from "../../../utils/project/microserviceUtils";
import { fetchAllAiTaskList } from "../../../redux/actions/aiTaskActions";
import { reDeploy } from "../../../utils/deployment/deploymentUtils";

const EditContainer = (props) => {
  const {
    data,
    fetchDeploymentById,
    deploymentname,
    deploymenttype,
    deployments,
    deploymentDetails,
  } = props;
  const { deploymentId, ContainerName } = props.match.params;
  // const [serviceType, setServiceType] = useState();
  const [deploymentRows, setDeploymentRows] = useState();

  const [isEditMode, setIsEditMode] = useState(true);
  const [myEnvs, setMyEnvs] = useState();
  const [newEnvs, setnewEnvs] = useState();
  const styles = listStyles();
  const style = manageStyles();
  const histroy = useHistory();
  const [submitData, setSubmitData] = useState();
  // const [myKey, setMyKey] = useState();
  // const [myValue, setMyValue] = useState();
  // const [envFormData, setEnvFormData] = useState({});
  const [volumeData, setVolumeData] = useState({});
  const [newContainerData, setNewContainerData] = useState();
  const [restartPolicy, setRestartPolicy] = useState({});
  const [sizingData, setSizingData] = useState({
    limits: { cpu: "", memory: "" },
    requests: { cpu: "", memory: "" },
  });
  const [FTPData, setFTPData] = useState();
  const fileSystems = ["ext4"];
  const [envData, setEnvData] = useState();
  const [envs, setEnvs] = useState([
    { id: uuidv4(), key: "", value: "", enabled: true },
  ]);
  const [port, setPort] = useState();
  const [selectedService, setSelectedService] = useState();
  // const [versionList, setVersionList] = useState();
  const [checkboxTicking, setCheckboxTicking] = useState();
  const [checkBoxValue, setCheckBoxValue] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [replicas, setReplicas] = useState();
  const [prefilledCheck, setPrefilledCheck] = useState();
  const [volume, setVolume] = useState();
  const [resource, setResource] = useState();
  const [volumeFileSystemType, setVolumeFileSystemType] = useState();
  const [volumeType, setVolumeType] = useState();
  const [showFileSystemType, setShowFileSystemType] = useState();
  const volumeTypeArray = ["AWSEBS", "PVC"];
  const [thisContainer, setThisContainer] = useState();
  const [isDependency, setIsDependency] = useState();
  const [imageId, setImageId] = useState();
  useEffect(() => {
    deployments?.map((item) => {
      if (item?.container_name === ContainerName) {
        setVolume(item);
        setResource(item);
      }
    });
    data?.data?.deploymentDetails?.map((item) => {
      if (item.ContainerName === ContainerName) {
        setThisContainer(item);
      }
    });
  }, []);

  useEffect(() => {
    deployments?.map((item) => {
      if (item.container_name === ContainerName) {
        if (item.dependencies === true) {
          setIsDependency(true);
        }
      }
    });
  }, [deployments]);
  const handleRestartCheckbox = (e) => {
    if (e.target.checked === false) {
      setCheckboxTicking("Never");
      setCheckBoxValue(false);
      setSubmitData({ ...submitData, restart: "Never" });
    } else {
      setCheckboxTicking("Always");
      setCheckBoxValue(true);
      setSubmitData({ ...submitData, restart: "Always" });
    }
  };

  const handleContainerDetailsEdit = (event) => {
    event.preventDefault();
    const contFieldName = event.target.getAttribute("name");
    const contFieldValue = event.target.value;
    const newNewContainerData = { ...newContainerData };
    newNewContainerData[contFieldName] = contFieldValue;
    setNewContainerData(newNewContainerData);
  };

  const handleFTPChange = (event) => {
    event.preventDefault();
    const newFTP = { ...FTPData };
    newFTP[event.target.name] = event.target.value;
    setFTPData(event.target.value);

    volumeData["filesystemtype"] = event.target.value;
  };

  const handleVolumeChange = (event) => {
    event.preventDefault();
    const contFieldName = event.target.getAttribute("name");
    const contFieldValue = event.target.value;
    const newNewContainerData = { ...volumeData };

    newNewContainerData[contFieldName] = contFieldValue;

    setVolumeData(newNewContainerData);
    setSubmitData({ ...submitData, Volumes: volumeData });
  };

  const handleAddEnv = (id) => {
    setEnvs(...envs, { id: uuidv4(), "": "", enabled: true });
  };

  useEffect(() => {
    fetchDeploymentById(deploymentId);
  }, [deploymentId]);

  let selectedContainerDetails;
  let envkeyVal = [];
  data?.map((item) => {
    if (item.ContainerName === ContainerName) {
      selectedContainerDetails = item;
      Object.entries(item?.Environment).map((k, v) => {
        envkeyVal.push({ id: uuidv4(), key: k[0], value: k[1] });
      });
    }
  });

  useEffect(() => {
    deployments?.map((item) => {
      if (selectedContainerDetails?.ContainerName === item?.container_name) {
        setVolumeData(item?.Volumes);
        setSizingData(item?.resources);
        setReplicas(item?.replicas);
        setRestartPolicy(item?.restart);
        if (restartPolicy === "Always") {
          setPrefilledCheck(true);
          setCheckBoxValue(true);
        } else {
          setPrefilledCheck(false);
          setCheckBoxValue(false);
        }

        setSubmitData({
          ...submitData,
          image: item?.image,
          restart: item?.restart,
          port: item?.port,
          environment: item?.environment,
          replicas: item?.replicas,
          container_name: item?.container_name,
          imageid: item?.imageid,
          Volumes: item?.Volumes,
        });
      }
    });
  }, [deployments]);

  useEffect(() => {
    setMyEnvs(envkeyVal);
    setEnvData(envkeyVal);
    setEnvs(envkeyVal);
  }, [data]);

  useEffect(() => {
    setDeploymentRows(
      data?.length > 0 && data ? data?.map((item) => item) : []
    );
  }, [data]);

  const handleSave = () => {
    histroy.push(`/host/details/${deploymentId}`);
  };

  useEffect(() => {
    setMyEnvs([...envkeyVal, newEnvs]);
  }, [newEnvs]);

  const handleEnvChange = (event, id, column) => {
    var newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [event.target.name]: event.target.value };
      } else {
        return env;
      }
    });

    setEnvs(newEnvs);
  };

  envkeyVal.map((item) => {});

  const handleCancelEditContainer = () => {
    histroy.push(`/host/container/detail/${deploymentId}/${ContainerName}`);
  };

  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  const EnvironmentVariable = (field) => {
    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: 80 }}>
          <Grid item xs={12} sm={12}>
            <TableContainer>
              <Table
                className={styles.customTable}
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingLeft: 20 }}></TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                    <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {envs?.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ border: "0.5px solid #D5D5D5" }}
                    >
                      <TableCell scope="row"></TableCell>
                      <TableCell scope="row">
                        <input
                          type="text"
                          placeholder="Enter key"
                          name={row.id}
                          className={styles.input}
                          value={envs[index].key}
                          onChange={(e) => handleEnvChange(e, row.id, "key")}
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          placeholder="Enter Value"
                          name={row.id}
                          className={styles.input}
                          value={envs[index].value}
                          onChange={(e) => handleEnvChange(e, row.id, "value")}
                          autoComplete="off"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={() => handleDeleteEnv(row.id)}
                        >
                          <img src="/images/deleteLine.svg" alt="card" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "5px 38px",
                borderLeft: "1px solid #E2E2E3",
                borderRight: "1px solid #E2E2E3",
                borderBottom: "1px solid #E2E2E3",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                onClick={handleAddEnv}
                className={styles.actionIcon}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    const fetchServicesList = async () => {
      const { data, _msg, _status } = await fetchFilteredServiceList();
      if (_status === 200) {
        data?.map((item) => {
          if (item.name === selectedContainerDetails?.ContainerName) {
            setSelectedService(item._id);
          }
        });
        setSelectedService;
      }
    };
    fetchServicesList();

    const fetchAllUIServiceList = async () => {
      const { data, _msg, _status } = await fetchAllUiServices();
      if (_status === 200) {
        data?.map((item) => {
          if (item.name === selectedContainerDetails?.ContainerName) {
            setSelectedService(item?._id);
          }
        });
      }
    };
    fetchAllUIServiceList();

    const fetchAITaskList = async () => {
      const { data, _msg, _status } = await fetchAllAiTaskList();
      if (_status === 200) {
        data?.map((item) => {
          if (item.name === selectedContainerDetails?.ContainerName) {
            setSelectedService(item?._id);
          }
        });
      }
    };
    fetchAITaskList();

    const fetchApplicationList = async () => {
      const { _msg, _status, data } = await fetchAllApplicationsList();
      if (_status === 200) {
      }

      // };
      fetchApplicationList();
    };
  }, [selectedContainerDetails]);

  const handleVersionChange = (e) => {
    const value = e.target.value;
    newContainerData["version"] = value;
  };

  const handleReplicas = (e) => {
    const value = e.target.value;
    setReplicas(value);
    setSubmitData({ ...submitData, replicas: parseInt(value) });
  };

  useEffect(() => {
    const object = Object.assign({}, ...envs);

    envs.map((item) => {
      return;
    });
    setSubmitData({
      ...submitData,
      ...newContainerData,
      environment: {
        ...object,
      },
      Volumes: {
        ...volumeData,
      },
      resources: {
        ...sizingData,
      },
    });
  }, [newContainerData, envs, replicas, checkBoxValue]);

  const handleSubmit = async () => {
    const { data, _status, _msg } = await reDeploy(
      submitData,
      deploymentId,
      ContainerName
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Container redeployed successfully", {
        variant: "success",
      });
      histroy.push(`/host`);
    } else {
      enqueueSnackbar(_msg ? _msg : "Container cannot be redeployed!", {
        variant: "error",
      });
    }
  };

  const handleSizeChange = (event) => {
    event.preventDefault();
    const contFieldName = event.target.getAttribute("name");
    const contFieldValue = event.target.value;
    const newNewContainerData = { ...sizingData };

    newNewContainerData[contFieldName] = contFieldValue;

    setSizingData(newNewContainerData);
    setSubmitData({ ...submitData, sizing: sizingData });
  };
  const handleVolumeFileSystemType = (event) => {
    const value = event.target.value;
    setVolumeFileSystemType(value);
  };

  const handleVolumeTypeChange = (event) => {
    const value = event.target.value;
    setVolumeType(value);
    if (event.target.value === "PVC") {
      setShowFileSystemType(false);
    } else {
      setShowFileSystemType(true);
    }
  };

  const handleChangePort = (e) => {
    const val = e.target.value;
    setPort(val);
  };
  const handleChangeImageId = (e) => {
    const val = e.target.value;
    setImageId(val);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////Main return

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Deployment List", path: "/host" },
            { title: deploymentname, path: `/host/details/${deploymentId}` },
            { title: "Edit " + ContainerName },
          ]}
        />

        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Edit {ContainerName}</h2>
          <Grid
            xs={isEditMode ? 3 : 2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignContent: "flex-end",
              // rowGap:"15px"
            }}
          >
            <AppButton
              color="primary"
              buttonName="Cancel"
              variant="contained"
              // style={{ margin: "unset" }}
              className="btnsmall"
              // style={{ padding: "3px" }}
              onClick={handleCancelEditContainer}
            />
            <AppButton
              color="primary"
              buttonName="Save"
              variant="contained"
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </div>
      <Paper className={styles.pageContent}>
        <div>
          <Grid
            container
            spacing={4}
            // xs={12}
            style={{ marginTop: "0rem", width: "100%", display: "flex" }}
          >
            {" "}
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Container Name{" "}
                </label>
                <input
                  type="text"
                  name="container_name"
                  className={`${style.input2}`}
                  value={
                    thisContainer?.ContainerName
                      ? thisContainer?.ContainerName
                      : selectedContainerDetails?.ContainerName
                  }
                  autoComplete="off"
                  onChange={handleContainerDetailsEdit}
                  disabled={true}
                />
                {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
              </>
            </Grid>
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Port{" "}
                </label>
                <input
                  type="text"
                  name="port"
                  className={isDependency ? style.input : style.input2}
                  value={port ? port : selectedContainerDetails?.Port}
                  autoComplete="off"
                  onChange={handleChangePort}
                  // disabled={isDependency ? false : true}
                />
                {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
              </>
            </Grid>
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Replica{" "}
                </label>
                <input
                  type="text"
                  name="replicas"
                  className={`${style.input}`}
                  value={
                    thisContainer?.Replicas ? thisContainer?.Replicas : replicas
                  }
                  autoComplete="off"
                  onChange={handleReplicas}
                />
                {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
              </>
            </Grid>
            <Grid item xs={1} sm={1}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Restart{" "}
                </label>
                <br />
                <Checkbox
                  color="primary"
                  name="restart"
                  checked={
                    checkBoxValue
                      ? checkBoxValue
                      : thisContainer?.RestartPolicy === "Always"
                      ? true
                      : false
                  }
                  onChange={handleRestartCheckbox}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />

                {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
              </>
            </Grid>
            <Grid item xs={2} sm={5}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Image ID{" "}
                </label>
                <input
                  type="text"
                  name="imageid"
                  className={isDependency ? style.input : style.input2}
                  value={imageId ? imageId : selectedContainerDetails?.ImageURL}
                  autoComplete="off"
                  onChange={handleChangeImageId}
                  disabled={isDependency ? false : true}
                />
                {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
              </>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>
                Environment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{EnvironmentVariable()}</AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>Volume</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {deployments &&
                deployments.map((item) => {
                  if (item.container_name === ContainerName) {
                    return (
                      <Grid
                        container
                        spacing={4}
                        // xs={12}
                        style={{
                          marginTop: "0rem",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        {" "}
                        <Grid item xs={2} sm={4}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Name{" "}
                            </label>
                            <input
                              type="text"
                              name="name"
                              className={`${style.input}`}
                              disabled={!isEditMode}
                              value={
                                volumeData?.name
                                  ? volumeData?.name
                                  : volume?.Volumes?.name
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                            {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
                          </>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Mount path{" "}
                            </label>
                            <input
                              type="text"
                              name="mountpath"
                              className={`${style.input}`}
                              disabled={!isEditMode}
                              value={
                                volumeData?.mountpath
                                  ? volumeData?.mountpath
                                  : volume?.Volumes?.mountpath
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                            {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
                          </>
                        </Grid>
                        {showFileSystemType && (
                          <Grid item xs={2} sm={4}>
                            <>
                              <label htmlFor="" className={style.fieldHeading}>
                                File System Type{" "}
                              </label>
                              <Select
                                name="filesystemtype"
                                className={`${style.select}`}
                                disabled={!isEditMode}
                                onChange={handleVolumeFileSystemType}
                                // value={ volumeData?.filesystemtype ? volumeData?.filesystemtype : fileSystems.filter((item)=>(item === volume?.Volumes?.filesystemtype)) }
                                value={volumeFileSystemType}
                                // defaultValue={fileSystems.find((item)=>(item === volume?.Volumes?.filesystemtype))}
                              >
                                {fileSystems?.map((item) => {
                                  return (
                                    <MenuItem
                                      name="filesystemtype"
                                      value={item}
                                      key={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </>
                          </Grid>
                        )}
                        <Grid item xs={2} sm={4}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Identifier{" "}
                            </label>
                            <input
                              type="text"
                              name="identifier"
                              className={`${style.input}`}
                              disabled={!isEditMode}
                              // value={volume?.Volumes?.identifier ? volume?.Volumes?.identifier : ""}
                              value={
                                volumeData?.identifier
                                  ? volumeData?.identifier
                                  : volume?.Volumes?.identifier
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                            {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
                          </>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                          {/* <>
                    <label htmlFor="" className={style.fieldHeading}>
                      Type{" "}
                    </label>
                    <input
                      type="text"
                      name="volumetype"
                      className={`${style.input}`}
                      disabled={!isEditMode}
                      value={volumeData?.volumetype ? volumeData?.volumetype : volume?.Volumes?.volumetype }
                      autoComplete="off"
                      onChange={handleVolumeChange}
                    />
                  </> */}
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Type{" "}
                            </label>
                            <Select
                              name="volumeType"
                              className={`${style.select}`}
                              disabled={!isEditMode}
                              onChange={handleVolumeTypeChange}
                              value={volumeType}
                              // value={ volumeData?.filesystemtype ? volumeData?.filesystemtype : fileSystems.filter((item)=>(item === volume?.Volumes?.filesystemtype)) }
                              defaultValue={fileSystems.find(
                                (item) =>
                                  item === volume?.Volumes?.filesystemtype
                              )}
                            >
                              {volumeTypeArray?.map((item) => {
                                return (
                                  <MenuItem
                                    name="filesystemtype"
                                    value={item}
                                    key={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </>
                          {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>Sizing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {deployments &&
                deployments.map((item) => {
                  if (item.container_name === ContainerName) {
                    return (
                      <Grid container spacing={3}>
                        <Grid item sm={12}>
                          <Accordion defaultExpanded>
                            <AccordionSummary>
                              <Typography> {"   "}RAM</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                <Grid item xs={8}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      Minimum
                                    </label>

                                    <Grid container>
                                      <Grid item xs={4}>
                                        <input
                                          type="text"
                                          placeholder="Min"
                                          name="RAMmin"
                                          className={
                                            // disabled ? styles.input2 :
                                            styles.input
                                          }
                                          value={
                                            sizingData?.requests?.memory
                                              ? sizingData?.requests?.memory
                                              : item?.resources?.requests
                                                  ?.memory
                                          }
                                          onChange={(e) =>
                                            setSizingData({
                                              ...sizingData,
                                              requests: {
                                                ...sizingData.requests,
                                                memory: e.target.value,
                                              },
                                            })
                                          }
                                          autoComplete="off"
                                          // disabled={disabled}
                                        />
                                      </Grid>
                                      <Grid item xs={8}>
                                        <span style={{ marginLeft: 20 }}>
                                          MB
                                          <Switch
                                            // checked={sizing?.requests?.memorytype}
                                            // onChange={handleChangeSwitchMinimum}
                                            name="memorytype"
                                            color="default"
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />{" "}
                                          GB
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* {error?.requests?.memory && (
                              <p className={styles.errorMsg}>Invalid value</p>
                            )} */}
                                </Grid>

                                <Grid item xs={8}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      Maximum
                                    </label>

                                    <Grid container>
                                      <Grid item xs={4}>
                                        <input
                                          type="text"
                                          placeholder="Max"
                                          name="RAMmax"
                                          className={
                                            // disabled ? styles.input2 :
                                            styles.input
                                          }
                                          value={
                                            sizingData?.limits?.memory
                                              ? sizingData?.limits?.memory
                                              : item?.resources?.limits?.memory
                                          }
                                          // value={item?.resources?.limits?.memory ? item?.resources?.limits?.memory : "" }
                                          // onChange={handleSizeChange}
                                          onChange={(e) =>
                                            setSizingData({
                                              ...sizingData,
                                              limits: {
                                                ...sizingData.limits,
                                                memory: e.target.value,
                                              },
                                            })
                                          }
                                          // value={item?.key ? item?.key : ""}
                                          // onChange={(e) => {
                                          //   handleEnvChange(e, item.id, "key");
                                          // }}
                                          autoComplete="off"
                                          // disabled={disabled}
                                        />
                                      </Grid>
                                      <Grid item xs={8}>
                                        <span style={{ marginLeft: 20 }}>
                                          MB
                                          <Switch
                                            // checked={sizing?.limits?.memorytype}
                                            // onChange={handleChangeSwitchMaximum}
                                            name="memorytype"
                                            color="default"
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />{" "}
                                          GB
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* {error?.limits?.memory && (
                              <p className={styles.errorMsg}>Invalid value</p>
                            )} */}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item sm={12}>
                          <Accordion defaultExpanded>
                            <AccordionSummary>
                              <Typography>CPU</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      Minimum
                                    </label>

                                    <input
                                      type="text"
                                      placeholder="Min"
                                      name="cpu"
                                      className={
                                        // disabled ? styles.input2 :
                                        styles.input
                                      }
                                      value={
                                        sizingData?.requests?.cpu
                                          ? sizingData?.requests?.cpu
                                          : item?.resources?.requests?.cpu
                                      }
                                      // onChange={(e=>setSizingData({...sizingData,{...sizingData.limits.cpu:e.target.value}))}
                                      onChange={(e) =>
                                        setSizingData({
                                          ...sizingData,
                                          requests: {
                                            ...sizingData.requests,
                                            cpu: e.target.value,
                                          },
                                        })
                                      }
                                      autoComplete="off"
                                      // disabled={disabled}
                                    />
                                  </div>
                                  {/* {error?.requests?.cpu && (
                              <p className={styles.errorMsg}>
                                {error?.requests?.cpu?.msg}
                              </p>
                            )} */}
                                </Grid>

                                <Grid item xs={6}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      Maximum
                                    </label>

                                    <input
                                      type="text"
                                      placeholder="Max"
                                      name="CPUmax"
                                      className={
                                        // disabled ? styles.input2 :
                                        styles.input
                                      }
                                      value={
                                        sizingData?.limits?.cpu
                                          ? sizingData?.limits?.cpu
                                          : item?.resources?.limits?.cpu
                                      }
                                      onChange={(e) =>
                                        setSizingData({
                                          ...sizingData,
                                          limits: {
                                            ...sizingData.limits,
                                            cpu: e.target.value,
                                          },
                                        })
                                      }
                                      // value={item?.key ? item?.key : ""}
                                      // onChange={(e) => {
                                      //   handleEnvChange(e, item.id, "key");
                                      // }}
                                      autoComplete="off"
                                      // disabled={disabled}
                                    />
                                  </div>
                                  {/* {error?.limits?.cpu && (
                              <p className={styles.errorMsg}>
                                {error?.limits?.cpu?.msg}
                              </p>
                            )} */}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    );
                    //     <TableContainer style={{ padding: "1rem" }}>
                    //   <Table
                    //     className={styles.customTable}
                    //     style={{ backgroundColor: "#fff" }}
                    //   >
                    //     <TableHead>
                    //       <TableRow>
                    //         {/* <TableCell padding="checkbox"></TableCell> */}
                    //         <TableCell align="center">Parameter</TableCell>
                    //         <TableCell align="center">Minimum</TableCell>
                    //         <TableCell align="center">Maximum</TableCell>
                    //       </TableRow>
                    //     </TableHead>
                    //     <TableBody>
                    //       <TableRow>
                    //         <TableCell component="th" scope="row" align="center">
                    //           <label className={style.fieldHeading}>CPU</label>
                    //         </TableCell>
                    //         <TableCell align="center">
                    //           <input
                    //             type="text"
                    //             placeholder="Min"
                    //             name="cpu"
                    //             className={
                    //               // disabled ? styles.input2 :
                    //               styles.input
                    //             }
                    //             value={sizingData?.requests?.cpu ? sizingData?.requests?.cpu : item?.resources?.requests?.cpu }
                    //             // onChange={(e=>setSizingData({...sizingData,{...sizingData.limits.cpu:e.target.value}))}
                    //             onChange={e=>setSizingData({...sizingData, requests:{...sizingData.requests,cpu:e.target.value}})}
                    //             autoComplete="off"
                    //             // disabled={disabled}
                    //           />
                    //         </TableCell>
                    //         <TableCell align="center">
                    //           <input
                    //             type="text"
                    //             placeholder="Max"
                    //             name="CPUmax"
                    //             className={
                    //               // disabled ? styles.input2 :
                    //               styles.input
                    //             }
                    //             value={sizingData?.limits?.cpu ? sizingData?.limits?.cpu : item?.resources?.limits?.cpu }

                    //             onChange={e=>setSizingData({...sizingData, limits:{...sizingData.limits,cpu:e.target.value}})}
                    //             // value={item?.key ? item?.key : ""}
                    //             // onChange={(e) => {
                    //             //   handleEnvChange(e, item.id, "key");
                    //             // }}
                    //             autoComplete="off"
                    //             // disabled={disabled}
                    //           />
                    //         </TableCell>
                    //       </TableRow>
                    //       <TableRow>
                    //         <TableCell component="th" scope="row" align="center">
                    //           <label className={style.fieldHeading}>RAM</label>
                    //         </TableCell>
                    //         <Grid xs="12" style={{display:"flex", justifyContent:"space-between"}}>

                    //         <TableCell align="center" style={{ display:"flex",flexDirection:"row" ,marginTop:"14px"}}>

                    //           <input
                    //             type="text"
                    //             placeholder="Min"
                    //             name="RAMmin"
                    //             className={
                    //               // disabled ? styles.input2 :
                    //               styles.input
                    //             }
                    //             // value={item?.resources?.requests?.memory ? item?.resources?.requests?.memory : "" }
                    //             value ={sizingData?.requests?.memory ? sizingData?.requests?.memory : item?.resources?.requests?.memory }
                    //             onChange={e=>setSizingData({...sizingData, requests:{...sizingData.requests,memory:e.target.value}})}

                    //             // value={item?.value ? item?.value : ""}
                    //             // onChange={(e) => handleEnvChange(e, item.id, "value")}
                    //             autoComplete="off"
                    //             // disabled={disabled}
                    //           />
                    //                             {/* <Grid item xs={2}> */}
                    //               <span style={{display:"flex"}}>
                    //                 MB
                    //                 <Switch
                    //                   // checked={sizing?.requests?.memorytype}
                    //                   // onChange={handleChangeSwitchMinimum}
                    //                   name="memorytype"
                    //                   color="default"
                    //                   inputProps={{
                    //                     "aria-label": "primary checkbox",
                    //                   }}
                    //                 />{" "}
                    //                 GB
                    //               </span>
                    //             {/* </Grid> */}

                    //         </TableCell>
                    //         <TableCell align="center" style={{display:"flex",flexDirection:"row" ,marginTop:"14px"}}>
                    //           <input
                    //             type="text"
                    //             placeholder="Max"
                    //             name="RAMmax"
                    //             className={
                    //               // disabled ? styles.input2 :
                    //               styles.input
                    //             }
                    //             value ={sizingData?.limits?.memory ? sizingData?.limits?.memory : item?.resources?.limits?.memory }
                    //             // value={item?.resources?.limits?.memory ? item?.resources?.limits?.memory : "" }
                    //             // onChange={handleSizeChange}
                    //             onChange={e=>setSizingData({...sizingData, limits:{...sizingData.limits,memory:e.target.value}})}

                    //             // value={item?.key ? item?.key : ""}
                    //             // onChange={(e) => {
                    //             //   handleEnvChange(e, item.id, "key");
                    //             // }}
                    //             autoComplete="off"
                    //             // disabled={disabled}
                    //           />
                    //                         {/* <Grid item xs={2}> */}
                    //               <span style={{display:"flex"}} >
                    //                 MB
                    //                 <Switch
                    //                   // checked={sizing?.requests?.memorytype}
                    //                   // onChange={handleChangeSwitchMinimum}
                    //                   name="memorytype"
                    //                   color="default"
                    //                   inputProps={{
                    //                     "aria-label": "primary checkbox",
                    //                   }}
                    //                 />{" "}
                    //                 GB
                    //               </span>
                    //             {/* </Grid> */}
                    //         </TableCell>
                    //          </Grid>
                    //       </TableRow>

                    //     </TableBody>
                    //   </Table>
                    // </TableContainer>
                  }
                })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
    </main>
  );
};

const mapDispatchToProps = {
  fetchDeploymentById,
};
const mapStateToProps = (state) => {
  const { deploymentDetails, deployments } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { deploymentname, deploymenttype } =
    state.deploymentProcessReducer.deploymentDetailsList.data;

  return {
    data: deploymentDetails,
    deploymentname,
    deploymenttype,
    deployments,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
