import React from 'react';
import { makeStyles, IconButton } from "@material-ui/core"
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
} from 'react-flow-renderer';
import { connect } from "react-redux";
import { useSnackbar } from "notistack"
import { addInputOutputDataForNodes } from "../../../redux/actions/orchestrationAction"
// import { deleteRelation_ } from "../../../utils/orchestration/orchestrationUtils"

import "../../../screens/Orchestration/orchestration.css"

const useStyles = makeStyles((theme) => ({
    icon: {
        borderWidth: 0
    },
    edgeButton: {
    },
    container: {
    }
}))

const foreignObjectSize = 40;

function DataFlowButtonEdge(props) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        arrowHeadType="arrowclosed",
        markerEndId,
        sourceHandleId,
        targetHandleId
    } = props;
    const { elements, templateid } = props;
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar()
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const onEdgeClick = async (evt, id) => {
        evt.stopPropagation();

        const { nodeid, node_type, field_name, node_name } = JSON.parse(targetHandleId);
        var newDataFlow = elements.map((ele) => {
            if (ele.id === nodeid) {
                var newFields = ele?.inputFields?.fields.map((field_) => {
                    if (field_.name === field_name) {
                        return {
                            ...field_,
                            value: ""
                        }
                    } else {
                        return field_
                    }
                })
                return {
                    ...ele,
                    inputFields: {
                        ...ele.inputFields,
                        fields: newFields
                    }
                }
            } else {
                return ele
            }
        })
        var newElementList = newDataFlow.filter((item) => item.id !== id);
        props.addInputOutputDataForNodes(newElementList, false, true);
    };
    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className={styles.icon}
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>
                    <IconButton
                        className={styles.edgeButton}
                        onClick={(event) => onEdgeClick(event, id)}
                    >
                        <img src="/images/deleteLine.svg" alt="delete" height={15} width={15} />
                    </IconButton>
                </body>
            </foreignObject>
        </>
    );
}

const mapDispatchToProps = {
    addInputOutputDataForNodes
}

const mapStateToProps = (state) => {
    const { data_flow_nodes_io_details, process_data } = state.orchestration;
    const { templateid } = process_data;
    return {
        elements: data_flow_nodes_io_details,
        templateid: templateid,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DataFlowButtonEdge)