import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  tableColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D8D8D8",
    borderRadius: 5,
    marginBottom: 5,
    marginTop: 2,
  },

  accordionRoot: {
    minHeight: '2.4rem',
    maxHeight: '2.4rem',
    // backgroundColor: '#a5a5a5',
    '&.Mui-expanded': {
      minHeight: '100%',
      maxHeight: '100%',
      margin: '1.4px !important'
    },
  },
  summaryRoot: {
    // border: '1px solid red !important',
    minHeight: "30px !important",
    height: "1rem",
    paddingTop: "1rem !important",
    paddingLeft: "7px !important",
    paddingRight: "6px !important",
  },
  // .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded
  options: {
    marginTop: 10,
  },
}));
