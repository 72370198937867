/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { addDocument } from "./project/documentUtils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import moment from "moment";

export const arrayToJson = (arr) => {
  var finalObj = {};
  arr &&
    arr.length > 0 &&
    arr.forEach((obj) => {
      if (obj.key !== "" && obj.value !== "") {
        finalObj[obj.key] = obj.value;
      }
    });
  return finalObj;
};

export const jsonToArray = (json) => {
  var finalArr = [];
  for (var obj in json) {
    var key = obj;
    var value = json[obj];
    finalArr.push({
      id: uuidv4(),
      key: key,
      value: value,
    });
  }
  return finalArr;
};

function dateReplacer(key, value) {
  if (key === "createdon" || key === "updatedon") {
    const keys = Object.keys(value);
    const values = Object.values(value);
    if (keys.length === 2 && values.length === 2) {
      return {
        [keys[0]]: parseInt(values[0]),
        [keys[1]]: parseInt(values[1]),
      };
    } else if (keys.length === 1 && values.length === 1) {
      if (keys[0] === "$not") {
        return {
          [keys[0]]: {
            $gte: parseInt(values[0].$gte),
            $lte: parseInt(values[0].$lte),
          },
        };
      } else {
        return { [keys[0]]: parseInt(values[0]) };
      }
    } else {
      return parseInt(value);
    }
  }
  return value;
}

export const arrayToFilter = (arr) => {
  var finalObj = {};
  arr &&
    arr.length > 0 &&
    arr.forEach((obj) => {
      if (obj.column !== "" && obj.operator !== "" && obj.value !== "") {
        if (obj.column === "containerState") {
          if (
            obj.value == "created" ||
            obj.value == "Created" ||
            obj.value == "create"
          ) {
            finalObj["state"] = {
              $regex: "created",
            };
          }
        } else if (obj.column === "containerState") {
          if (
            obj.value == "started" ||
            obj.value == "Started" ||
            obj.value == "start"
          ) {
            finalObj["state"] = {
              $regex: "running",
            };
          }
        } else if (obj.column === "containerState") {
          if (
            obj.value == "stopped" ||
            obj.value == "Stopped" ||
            obj.value == "stop"
          ) {
            finalObj["state"] = {
              $regex: "exited",
            };
          }
        } else if (obj.column === "EnvVariableState") {
          if (
            obj.value == "started" ||
            obj.value == "Started" ||
            obj.value == "start"
          ) {
            finalObj["state"] = {
              $regex: "up",
            };
          }
        }
        if (obj.column === "EnvVariableState") {
          if (
            obj.value == "stopped" ||
            obj.value == "Stopped" ||
            obj.value == "stop"
          ) {
            finalObj["state"] = {
              $regex: "down",
            };
          }
        }
        if (obj.column === "EnvVariableState") {
          if (
            obj.value == "created" ||
            obj.value == "Created" ||
            obj.value == "create"
          ) {
            finalObj["state"] = {
              $regex: "created",
            };
          }
        }
        if (obj.operator === "con") {
          finalObj[obj.column] = {
            $regex: obj.value,
          };
        } else if (obj.operator === "notcon") {
          finalObj[obj.column] = {
            $not: {
              $regex: obj.value,
            },
          };
        } else if (obj.operator === "start") {
          finalObj[obj.column] = {
            $regex: `^${obj.value}`,
          };
        } else if (obj.operator === "end") {
          finalObj[obj.column] = {
            $regex: `${obj.value}$`,
          };
        } else if (obj.operator === "eq") {
          finalObj[obj.column] = obj.value;
        } else if (obj.operator === "noteq") {
          finalObj[obj.column] = {
            $ne: obj.value,
          };
        } else if (obj.operator === "eqdate") {
          finalObj[obj.column] = {
            $gte: moment(obj.value[0]).startOf("day").format("X"),
            $lte: moment(obj.value[0]).endOf("day").format("X"),
          };
        } else if (obj.operator === "noteqdate") {
          finalObj[obj.column] = {
            $not: {
              $gte: moment(obj.value[0]).startOf("day").format("X"),
              $lte: moment(obj.value[0]).endOf("day").format("X"),
            },
          };
        } else if (obj.operator === "less") {
          if (obj.column === "number") {
            finalObj[obj.column] = {
              $lt: obj.value,
            };
          } else {
            finalObj[obj.column] = {
              $lt: moment(obj.value[0]).startOf("day").format("X"),
            };
          }
        } else if (obj.operator === "greater") {
          if (obj.column === "number") {
            finalObj[obj.column] = {
              $gt: obj.value,
            };
          } else {
            finalObj[obj.column] = {
              $gt: moment(obj.value[0]).endOf("day").format("X"),
            };
          }
        } else if (obj.operator === "lessequal") {
          if (obj.column === "number") {
            finalObj[obj.column] = {
              $lte: obj.value,
            };
          } else {
            finalObj[obj.column] = {
              $lte: moment(obj.value[0]).endOf("day").format("X"),
            };
          }
        } else if (obj.operator === "greaterequal") {
          if (obj.column === "number") {
            finalObj[obj.column] = {
              $gte: obj.value,
            };
          } else {
            finalObj[obj.column] = {
              $gte: moment(obj.value[0]).startOf("day").format("X"),
            };
          }
        } else if (obj.operator === "between") {
          if (obj.column === "number") {
            finalObj[obj.column] = {
              $gte: obj.value,
              $lte: obj?.value2,
            };
          } else {
            finalObj[obj.column] = {
              $gte: moment(obj.value[0]).startOf("day").format("X"),
              $lte: moment(obj.value[1]).endOf("day").format("X"),
            };
          }
        }
        if (obj?.resourcetype) {
          finalObj["resourcetype"] = obj?.resourcetype;
        }
        if (obj?.resourceid) {
          finalObj["resourceid"] = obj?.resourceid;
        }
      }
    });
  return JSON.stringify(finalObj, dateReplacer);
};
export const arrayToFilter2 = (arr) => {
  var finalFilter = [];
  arr &&
    arr.length > 0 &&
    arr.forEach((obj) => {
      if (obj.column !== "" && obj.operator !== "" && obj.value !== "") {
        switch (obj.operator) {
          case "con":
            finalFilter.push({
              key: obj.column,
              operator: "CONTAINS",
              value: obj.value,
            });
            break;
          case "notcon":
            finalFilter.push({
              key: obj.column,
              operator: "DOESNOTCONTAIN",
              value: obj.value,
            });
            break;
          case "start":
            finalFilter.push({
              key: obj.column,
              operator: "STARTSWITH",
              value: obj.value,
            });
            break;
          case "end":
            finalFilter.push({
              key: obj.column,
              operator: "ENDSWITH",
              value: obj.value,
            });
            break;
          case "eq":
            finalFilter.push({
              key: obj.column,
              operator: "EQUALS",
              value: obj.value,
            });
            break;
          case "noteq":
            finalFilter.push({
              key: obj.column,
              operator: "DOESNOTEQUALS",
              value: obj.value,
            });
            break;
          // Date
          case "eqdate":
            finalFilter.push({
              key: "createdOn",
              operator: "EQUALS",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "noteqdate":
            finalFilter.push({
              key: "createdOn",
              operator: "DOESNOTEQUALS",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "less":
            finalFilter.push({
              key: "createdOn",
              operator: "LESSTHAN",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "lessequal":
            finalFilter.push({
              key: "createdOn",
              operator: "LTE",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "greater":
            finalFilter.push({
              key: "createdOn",
              operator: "GREATERTHAN",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "greaterequal":
            finalFilter.push({
              key: "createdOn",
              operator: "GTE",
              start: moment(obj.value[0]).startOf("day").format("X"),
            });
            break;
          case "between":
            finalFilter.push({
              key: "createdOn",
              operator: "BETWEEN",
              start: parseInt(moment(obj.value[0]).startOf("day").format("X")),
              end: parseInt(moment(obj.value[0]).endOf("day").format("X")),
            });
            break;
          default:
            break;
        }
      }
    });
  return finalFilter;
};

export const arrayToSort = (arr) => {
  if (
    arr[0]?.columnName === "containerState" ||
    arr[0]?.columnName === "EnvVariableState"
  ) {
    return { ["state"]: arr[0]?.direction === "asc" ? 1 : -1 };
  } else {
    return { [arr[0]?.columnName]: arr[0]?.direction === "asc" ? 1 : -1 };
  }
};

export const arrayToSort2 = (arr) => {
  return `${[arr[0].columnName]},${arr[0].direction}`;
};

export const handleUploadDocuments = (resourceType, rosourceId, files) => {
  files.length !== 0 &&
    files.map(async (doc) => {
      var formData = new FormData();
      formData.append(
        "meta",
        JSON.stringify({
          resourcetype: resourceType,
          resourceid: rosourceId,
          name: doc.docType.name,
          version: doc.version,
          documenttype: doc.docType._id,
          docName: doc.name,
          status: doc.status,
        })
      );
      formData.append("file", doc.file);
      const { _msg, _status, data } = await addDocument(formData);
    });
};

export const handleFormValidation = (data, error) => {
  let errors = {};
  let formIsValid = true;
  for (const obj in error) {
    if (data[obj]?.length === 0 || typeof data[obj] === "string" && data[obj]?.trim().length === 0 ) {
      errors[obj] = true;
      formIsValid = false;
    } 
  }
  return { formIsValid, errors };
};

export const initError = (field, index) => ({
  isError: false,
  fieldLabel: field.fieldLabel,
  minLength: field.rules?.minLength ? field.rules?.minLength : 1,
  maxLength: field.rules?.maxLength ? field.rules?.maxLength : 300,
  pattern: field.rules?.pattern,
  dataType: field.dataType ? field.dataType : "string",
  minimum: field.rules?.minimum ? field.rules?.minimum : 1,
  maximum: field.rules?.maximum ? field.rules?.maximum : 300,
  exclusiveMinimum: field.rules?.exclusiveMinimum,
  exclusiveMaximum: field.rules?.exclusiveMaximum,
  multipleOf: field.rules?.multipleOf,
  msg: "",
  section: index
});

export const handleFormValidationPro = (data, error) => {
  let errors = {};
  let formIsValid = true;
  for (var obj in error) {
    if (error[obj]?.dataType === "string") {
      let re;
      if (error[obj]?.pattern) {
        re = new RegExp(error[obj]?.pattern);
      }
      if (data[obj]?.length === 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is required`,
        };
        formIsValid = false;
      } else if (
        (typeof data[obj] === "string" && data[obj]?.trim().length === 0) ||
        (error[obj]?.pattern && !re.test(data[obj]))
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `Invalid ${error[obj].fieldLabel}`,
        };
        formIsValid = false;
      } else if (data[obj]?.length < error[obj]?.minLength) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than ${error[obj]?.minLength} characters`,
        };
        formIsValid = false;
      } else if (data[obj]?.length > error[obj]?.maxLength) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than ${error[obj]?.maxLength} characters`,
        };
        formIsValid = false;
      } else if (data[obj] === undefined) {
        errors[obj] = { ...error[obj], isError: true, msg: `` };
        formIsValid = false;
      } else {
        errors[obj] = { ...error[obj], isError: false, msg: `` };
      }
    } else if (error[obj]?.dataType === "object") {
      let re;
      if (error[obj]?.pattern) {
        re = new RegExp(error[obj]?.pattern);
      }
      if (data[obj]?.length === 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is required`,
        };
        formIsValid = false;
      } else if (
        (typeof data[obj] === "object" && data[obj]?.trim().length === 0) ||
        (error[obj]?.pattern && !re.test(data[obj]))
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `Invalid ${error[obj].fieldLabel}`,
        };
        formIsValid = false;
      } else if (data[obj]?.length < error[obj]?.minLength) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than ${error[obj]?.minLength} characters`,
        };
        formIsValid = false;
      } else if (data[obj]?.length > error[obj]?.maxLength) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than ${error[obj]?.maxLength} characters`,
        };
        formIsValid = false;
      } else if (data[obj] === undefined) {
        errors[obj] = { ...error[obj], isError: true, msg: `` };
        formIsValid = false;
      }
      else {
        errors[obj] = { ...error[obj], isError: false, msg: `` };
      }
    } else if (error[obj]?.dataType === "integer") {
      if (data[obj]?.length === 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is required`,
        };
        formIsValid = false;
      } else if (isNaN(data[obj]) || data[obj] != parseInt(data[obj], 10)) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is not valid Integer`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMinimum === "true" &&
        parseInt(data[obj]) <= parseInt(error[obj]?.minimum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than ${error[obj]?.minimum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMinimum === "false" &&
        parseInt(data[obj]) < parseInt(error[obj]?.minimum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than or equal to ${error[obj]?.minimum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMaximum === "true" &&
        parseInt(data[obj]) >= parseInt(error[obj]?.maximum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than ${error[obj]?.maximum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMaximum === "false" &&
        parseInt(data[obj]) > parseInt(error[obj]?.maximum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than or equal to ${error[obj]?.maximum}`,
        };
        formIsValid = false;
      } else if (parseInt(data[obj]) % parseInt(error[obj]?.multipleOf) !== 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is not multiple of ${error[obj]?.multipleOf}`,
        };
        formIsValid = false;
      } else {
        errors[obj] = { ...error[obj], isError: false, msg: `` };
      }
    } else if (error[obj]?.dataType === "number") {
      if (data[obj]?.length === 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is required`,
        };
        formIsValid = false;
      } else if (isNaN(data[obj])) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is not valid Number`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMinimum === "true" &&
        parseInt(data[obj]) <= parseInt(error[obj]?.minimum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than ${error[obj]?.minimum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMinimum === "false" &&
        parseInt(data[obj]) < parseInt(error[obj]?.minimum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be greater than or equal to ${error[obj]?.minimum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMaximum === "true" &&
        parseInt(data[obj]) >= parseInt(error[obj]?.maximum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than ${error[obj]?.maximum}`,
        };
        formIsValid = false;
      } else if (
        error[obj]?.exclusiveMaximum === "false" &&
        parseInt(data[obj]) > parseInt(error[obj]?.maximum)
      ) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is must be less than or equal to ${error[obj]?.maximum}`,
        };
        formIsValid = false;
      } else if (parseInt(data[obj]) % parseInt(error[obj]?.multipleOf) !== 0) {
        errors[obj] = {
          ...error[obj],
          isError: true,
          msg: `${error[obj].fieldLabel} is not multiple of ${error[obj]?.multipleOf}`,
        };
        formIsValid = false;
      } else {
        errors[obj] = { ...error[obj], isError: false, msg: `` };
      }
    }
  }
  return { formIsValid, errors };
};

export const handleFormValidationForDocument = (files, error) => {
  let errors = error;
  let formIsValid = true;

  for (var file in files) {
    if (files[file].name.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            name: { ...e.name, isError: true, msg: "File Name is required" },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].name.length < 1) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            name: {
              ...e.name,
              isError: true,
              msg: "File Name is must be greater than 1 characters",
            },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].name.length > 100) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            name: {
              ...e.name,
              isError: true,
              msg: "File Name is must be less than 100 characters",
            },
          };
        } else {
          return e;
        }
      });
    } else if (
      !/^([A-Za-z0-9])([a-zA-Z 0-9_@./#&+-]*)$/.test(files[file].name)
    ) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            name: {
              ...e.name,
              isError: true,
              msg: "File Name should be alphanumeric with special character",
            },
          };
        } else {
          return e;
        }
      });
    }

    if (files[file].version.length === 0) {
      formIsValid = false;
      errors = errors?.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            version: {
              ...e.version,
              isError: true,
              msg: "Version is required",
            },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].version.length < 1) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            version: {
              ...e.version,
              isError: true,
              msg: "Version is must be greater than 1 characters",
            },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].version.length > 20) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            version: {
              ...e.version,
              isError: true,
              msg: "Version is must be less than 20 characters",
            },
          };
        } else {
          return e;
        }
      });
    } else if (
      !/^([A-Za-z0-9])([a-zA-Z 0-9_@./#&+-]*)$/.test(files[file].version)
    ) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            version: {
              ...e.version,
              isError: true,
              msg: "Version should be alphanumeric with special character",
            },
          };
        } else {
          return e;
        }
      });
    }

    if (files[file].docType.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            docType: {
              ...e.docType,
              isError: true,
              msg: "Document Type is required",
            },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].docType.length < 2) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            docType: {
              ...e.docType,
              isError: true,
              msg: "Document Type is must be greater than 2 characters",
            },
          };
        } else {
          return e;
        }
      });
    } else if (files[file].docType.length > 50) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            docType: {
              ...e.docType,
              isError: true,
              msg: "Document Type is must be less than 50 characters",
            },
          };
        } else {
          return e;
        }
      });
    }

    if (files[file]?.status?.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            status: {
              ...e.status,
              isError: true,
              msg: "Status is required",
            },
          };
        } else {
          return e;
        }
      });
    }
  }
  return { formIsValid, errors };
};

export const handleFormValidationForFilters = (files, error) => {
  let errors = error;
  let formIsValid = true;

  for (var file in files) {
    if (files[file].column.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            column: { ...e.column, isError: true, msg: "Column is required" },
          };
        } else {
          return e;
        }
      });
    }

    if (files[file].operator.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            operator: {
              ...e.operator,
              isError: true,
              msg: "Operator is required",
            },
          };
        } else {
          return e;
        }
      });
    }

    if (files[file].value.length === 0) {
      formIsValid = false;
      errors = errors.map((e) => {
        if (e.id === error[file].id) {
          return {
            ...e,
            value: { ...e.value, isError: true, msg: "Value is required" },
          };
        } else {
          return e;
        }
      });
    }
  }
  return { formIsValid, errors };
};

export const handleFormValidationForChart = (data, error) => {
  let errors = {};
  let formIsValid = true;

  for (var obj in error) {
    if (data[obj]?.length === 0) {
      errors[obj] = {
        ...error[obj],
        isError: true,
        msg: `${error[obj].fieldLabel} is required`,
      };
      formIsValid = false;
    } else {
      errors[obj] = { ...error[obj], isError: false, msg: `` };
    }
  }

  let aggregates = []
  if(data?.chartType === "stack_bar"){
    data?.aggregates.map((item)=>{
      if(!item.groupBy){
        aggregates.push({id: item.id, isError: false, type:'groupBy', msg:'Group by required'})
        formIsValid = false;
      }

      if(!item.field){
        aggregates.push({id: item.id, isError: false, type:'field', msg:'Field required'})
        formIsValid = false;
      }
    })
    errors['aggregates'] = aggregates
  }

  return { formIsValid, errors };
};

export const getBase64 = (file) => {
  if (file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return reader.result;
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
};

export const convertEditorToFile = (editor) => {
  const convertedFileData = draftToHtml(
    convertToRaw(editor.getCurrentContent())
  );
  var blobFile = new Blob([convertedFileData], {
    type: "text/plain;charset=utf-8",
  });
  return blobFile;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getColumnWidth = (columns, windowDimensions, customWidth) => {
  var finalColumns = [];
  columns &&
    columns.length > 0 &&
    columns.forEach((col) => {
      finalColumns.push({
        columnName: col.columnName,
        weightage: col.weightage ? col.weightage : 3,
        width:
          Math.ceil(
            (customWidth ? windowDimensions.width - customWidth : windowDimensions.width) / (columns.length < 5 ? columns.length : 5)
          ) -
          (col.weightage ? col.weightage : 3) * 10,
      });
    });

  return finalColumns;
};

export const getTableData = (
  data,
  defaultColumns,
  defaultWidths,
  defaultExtentions,
  defaultOrder,
  defaultHidden,
  forType
) => {
  const tableData = {
    columns: [],
    widths: [],
    extentions: [],
    orders: [],
    hides: [],
  };

  data?.sections?.map((section) => {
    return section?.fieldgroups?.map((fieldgroup) => {
      return fieldgroup?.fields?.map((field) => {
        if (
          field.uiElementType !== "custom" &&
          field.uiElementType !== "Custom"
        ) {
          if (field.datakey) {
            if (!defaultColumns.find((c) => c.name === field.datakey)) {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tableData["columns"].push({
                  name: `${field.datakey}_value`,
                  title: field.fieldLabel,
                  for: forType,
                });
              } else {
                tableData["columns"].push({
                  name: field.datakey,
                  title: field.fieldLabel,
                  for: forType,
                });
              }
            }
            if (!defaultWidths.find((c) => c.columnName === field.datakey)) {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tableData["widths"].push({
                  columnName: `${field.datakey}_value`,
                  weightage: 3,
                  width: 200,
                });
              } else {
                tableData["widths"].push({
                  columnName: field.datakey,
                  weightage: 3,
                  width: 200,
                });
              }
            }
            if (
              !defaultExtentions.find((c) => c.columnName === field.datakey)
            ) {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tableData["extentions"].push({
                  columnName: `${field.datakey}_value`,
                  align: "left",
                });
              } else {
                tableData["extentions"].push({
                  columnName: field.datakey,
                  align: "left",
                });
              }
            }
            if (!defaultOrder.find((c) => c === field.datakey)) {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tableData["orders"].push(`${field.datakey}_value`);
              } else {
                tableData["orders"].push(field.datakey);
              }
            }
            if (!defaultHidden.find((c) => c === field.datakey)) {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tableData["hides"].push(`${field.datakey}_value`);
              } else {
                tableData["hides"].push(field.datakey);
              }
            }
          }
        }
      });
    });
  });

  tableData.columns.unshift(...defaultColumns);
  tableData.widths.unshift(...defaultWidths);
  tableData.extentions.unshift(...defaultExtentions);
  tableData.orders.unshift(...defaultOrder);
  tableData.orders.push("action");
  tableData.orders.push("actions");
  tableData.orders.push("languageactions");
  tableData.orders.push("boilerplateactions");
  tableData.orders.push("chassisactions");
  tableData.orders.push("dockerimageaction");
  tableData.orders.push("containeractions");
  tableData.orders.push("runtimeenvactions");
  tableData.orders.push("vendorActions");
  tableData.orders.push("userActions");
  tableData.orders.push("teamActions");
  tableData.orders.push("organizationAction");

  return tableData;
};

export const getInsideTableHeight = (rows) => {
  switch (rows.length) {
    case 1:
      return 104;
    case 2:
      return 152; // 48
    case 3:
      return 198; // 46
    case 4:
      return 256; // 48
    case 5:
      return 302; // 46
    case 6:
      return 355; // 48
    case 7:
      return 406; // 46
    case 8:
      return 454; // 48
    case 9:
      return 500; // 46
    case 10:
      return 548; // 48
    default:
      return 260;
  }
};
