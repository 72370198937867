import { WEBSOCKET } from "./helper/config"

export const RteInActivityWS = (envId, uid) => {
    return new WebSocket(`${WEBSOCKET}/api/container/logs/environments/${envId}/status?uid=${uid}`)
}


export const ContainerInActivityWS = (containerId, uid) => {
    return new WebSocket(`${WEBSOCKET}/api/container/logs/dockercontainers/${containerId}/status?uid=${uid}`)
}
