import React, { useState } from 'react'
import {

    IconButton,
    InputAdornment,
    FilledInput,
   
  } from "@material-ui/core";
import useDebounce from './UseDebounce';
import listScreenStyles from '../../screens/CommonStyles/listScreenStyles';


  function SearchBar(props) {
    const {ActiveTab,handleSearch,searchInput,setSearchInput,PlaceHolderLabel}=props
    const debouceOnchange = useDebounce((e) => setSearchInput(e), 500) 
    const styles = listScreenStyles();
  return (
    <FilledInput
                name="searchInput"
                onChange={(e) => debouceOnchange(e.target.value)}
                className={styles.input}
                placeholder={PlaceHolderLabel}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
  )
}


export default SearchBar