import { ApiHelper } from "./helper/ApiHelper";
import { MICROSERVICE, REPOSITORY, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class MicroServicesAPIServices {
  getAllServices() {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices`;
    return apiHelper.get(uri);
  }

  getFilteredServicesList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== "{}" && filter !== undefined
        ? `&filters=${filter}`
        : '&filters={"ai_task" : {"$ne": true}, "service_category" : {"$ne": "Only GUI"}, "serviceType": "Internal" }') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?${queryParam}&size=10`;

    return apiHelper.get(uri);
  }

  fetchAllUiServices() {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?&filters={"ai_task" : {"$ne": true}, "service_category" : {"$ne": "Only API"} , "serviceType": "Internal"}`;
    return apiHelper.get(uri);
  }

  getServiceById(serviceId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}`;
    return apiHelper.get(uri);
  }
  addService(serviceData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices`;
    return apiHelper.post(uri, serviceData);
  }
  editService(serviceId, serviceData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}`;
    return apiHelper.put(uri, serviceData);
  }
  deleteService(serviceId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}`;
    return apiHelper.delete(uri);
  }
  getServicesListByProject(projectId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?filters={"projectID":"${projectId}"}`;
    return apiHelper.get(uri);
  }
  getServicesListByServiceGroup(groupId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?filters={"servicegroupID":"${groupId}"}`;
    return apiHelper.get(uri);
  }
  getAllVersions(serviceId, currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  addVersion(serviceId, versionData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions`;
    return apiHelper.post(uri, versionData);
  }
  getVersionById(serviceId, versionId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.get(uri);
  }
  editVersion(serviceId, versionId, versionData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.put(uri, versionData);
  }
  editBuildCommands(serviceId, versionId, buildCommands) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/dockers/commands/buildcommands`;
    return apiHelper.put(uri, buildCommands);
  }
  editEnvironmentForVersion(serviceId, versionId, envData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/envs`;
    return apiHelper.put(uri, envData);
  }
  editRunCommands(serviceId, versionId, runCommands) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/dockers/commands/runcommands`;
    return apiHelper.put(uri, runCommands);
  }
  deleteVersion(serviceId, versionId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.delete(uri);
  }
  getAllAPIs(serviceId, versionId, currentPage, sort) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/apis?page=${currentPage}&sort=${sort}&size=10`;
    return apiHelper.get(uri);
  }
  getAPIById(serviceId, versionId, apiId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.get(uri);
  }
  addAPI(serviceId, versionId, apiData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/apis`;
    return apiHelper.post(uri, apiData);
  }
  editAPI(serviceId, versionId, apiId, apiData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.put(uri, apiData);
  }
  deleteAPI(serviceId, versionId, apiId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.delete(uri);
  }
  getAllBoilerPlates() {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates`;
    return apiHelper.get(uri);
  }
  getAllRuntimeEnvironment() {
    const uri = `${CONTAINER}/api/container/environments`;
    return apiHelper.get(uri);
  }
  addRuntimeEnvironment(serviceId, versionId, environmentData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.post(uri, environmentData);
  }
  getRepositoryDetailsByMicroServiceVersion(serviceId, versionId) {
    const uri = `${REPOSITORY}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.get(uri);
  }
  uploadLocalSourceCode(souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories`;
    return apiHelper.post(uri, souceCodeData);
  }
  reUploadLocalSourceCode(repoId, souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories/${repoId}`;
    return apiHelper.put(uri, souceCodeData);
  }
  uploadSourceCodeByGit(souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories/_download`;
    return apiHelper.post(uri, souceCodeData);
  }

  reUploadSourceCodeByGit(repoId, souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories/edit/${repoId}`;
    return apiHelper.put(uri, souceCodeData);
  }

  createDockerFile(serviceId, versionId) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/build`;
    return apiHelper.get(uri);
  }
  runContainer(serviceId, versionId, containerData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/Containers`;
    return apiHelper.post(uri, containerData);
  }
  simulateAPI(simulateData) {
    const uri = `${MICROSERVICE}/api/simulation/simulate/microservice`;
    return apiHelper.post(uri, simulateData);
  }
  simulateAPIForUI(simulateData) {
    const uri = `${MICROSERVICE}/api/simulation/simulate/url`;
    return apiHelper.post(uri, simulateData);
  }
  copyService(serviceData) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/copy`;
    return apiHelper.post(uri, serviceData);
  }
}