import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router";

export default function BreadCrumbs(props) {
  const histroy = useHistory();

  const useStyles = makeStyles((theme) => ({
    bread: {
      paddingTop: 16,
      paddingBottom: 8,
      cursor: "pointer",
      font: "normal normal normal 14px/28px Nunito !important",

      "& ol li a": {
        color: "#2185D0",
      },

      "& ol li:last-child": {
        color: "#1B1C1D",
        fontWeight: 500,
      },
    },
  }));

  function handleClick(event, path) {
    event.preventDefault();
    histroy.push(path);
  }

  const classes = useStyles();
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.bread}
      >
        {props.items.map(
          (
            item,
            index
          ) =>
            item?.path ? (
              <Link
                color="inherit"
                // href="/"
                // deleted item
                onClick={(e) => handleClick(e, item.path)}
                key={index}
              >
                {item.title}
              </Link>
            ) : (
              <div key={index}>{item.title}</div>
            )
        )}
      </Breadcrumbs>
    </React.Fragment>
  );
}
