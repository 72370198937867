import {
  UPDATE_ACTIVE_PATH,
  UPDATE_EDIT_KEY,
  UPDATE_LOACKED_PATHS,
  UPDATE_LOGS,
  UPDATE_TREE,
  UPDATE_UN_LOACKED_PATHS,
} from "../actionTypes";

import { CodeEditorAPIService } from "../../services/codeEditorService";
const codeEditorSchemaService = new CodeEditorAPIService();

export const updateTreeData = (data) => {
  return {
    type: UPDATE_TREE,
    payload: data,
  };
};

export const updateActiveEditorPath = (path) => {
  return {
    type: UPDATE_ACTIVE_PATH,
    payload: path,
  };
};

export const updateIsEdit = (isEdit) => {
  return {
    type: UPDATE_EDIT_KEY,
    payload: isEdit,
  };
};

export const updateLogs = (log) => {
  return {
    type: UPDATE_LOGS,
    payload: log,
  };
};

export const updateLockedFiles = (path) => {
  return {
    type: UPDATE_LOACKED_PATHS,
    payload: path.filepath,
  };
};

export const updateUnLockedFiles = (path) => {
  return {
    type: UPDATE_UN_LOACKED_PATHS,
    payload: path.filepath,
  };
};

export const codeEditorLockFile =
  (serviceId, versionId, path) => async (dispatch, getState) => {
    const {
      codeEditorReducer: { lockedPaths, activePath },
    } = getState();

    if (lockedPaths !== activePath.path_name) {
      try {
        const { data } = await codeEditorSchemaService.lockFile(
          serviceId,
          versionId,
          path
        );

        if (data) {
          dispatch(updateLockedFiles(path));
        } else {
          dispatch(
            updateLockedFiles({
              loading: false,
              data: [],
              error: false,
            })
          );
        }
      } catch (error) {
        dispatch(
          updateLockedFiles({
            loading: false,
            data: [],
            error: true,
          })
        );
      }
    }
  };

export const codeEditorUnLockFile =
  (serviceId, versionId) => async (dispatch, getState) => {
    const {
      codeEditorReducer: { lockedPaths, activePath },
    } = getState();

    if (lockedPaths) {
      try {
        const { data } = await codeEditorSchemaService.unLockFile(
          serviceId,
          versionId,
          { files: [lockedPaths] }
        );

        if (data) {
          dispatch(updateUnLockedFiles(path));
        } else {
          dispatch(
            updateUnLockedFiles({
              loading: false,
              data: [],
              error: false,
            })
          );
        }
      } catch (error) {
        dispatch(
          updateUnLockedFiles({
            loading: false,
            data: [],
            error: true,
          })
        );
      }
    }
  };
