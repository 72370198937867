import {
  Dialog,
  Tabs,
  Tab,
  DialogContent,
  DialogActions,
  Grid,
  Backdrop,
  CircularProgress,
  Switch,
  FormControlLabel,
  LinearProgress,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { AppButton } from "../Button";
import FileDropperWithChip from "../FileDropperWithChip";
import manageStyles from "../../../screens/CommonStyles/manageScreenStyles";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  reUploadLocalSourceCode,
  reUploadSourceCodeByGit,
  uploadLocalSourceCode,
  uploadSourceCodeByGit,
} from "../../../utils/project/microserviceUtils";
import { handleFormValidation } from "../../../utils/common";
import { connect } from "react-redux";
import { getVersionList } from "../../../redux/actions/serviceAction";
import ConfirmReuploadDeleteImage from "./ConfirmReuploadDeleteImage";
import {
  DeleteContainer,
  StopContainer,
} from "../../../utils/containerMangement/containerUtils";
import { DeleteDockerImage } from "../../../utils/containerMangement/imageUtils";
import { useSelector } from "react-redux";
import BuildIcon from "@material-ui/icons/Build";
import { useHistory, useLocation } from "react-router-dom";

const UploadSourceCodeInVersion = (props) => {
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const CreatedVersionID = params.get("versionId");

  const s = useLocation().search;


  const pathname = useLocation().pathname;
  const isAiTaskFromUrl = pathname.includes("aitask") || pathname.includes("ai-task-versions");

  const { versionDetails, setCreateVersion, isCreateMode, isAiTask } = props;

  const { enqueueSnackbar } = useSnackbar();
  const styles = manageStyles();
  const [uploadTab, setUploadTab] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [localSourceCodeUpload, setLocalSourceCodeUpload] = useState({
    microserviceID: props.serviceId,
    versionID: props.versionID,
    file: {},
  });
  const [localError, setLocalError] = useState({
    files: false,
  });
  const [gitError, setGitError] = useState({
    Url: false,
  });

  const [confirmReuploadDeleteImage, setConfirmReuploadDeleteImage] =
    useState(false);

  const [uploadSourceCodeType, setUploadSourceCodeType] = useState();

  const [gitSouceCodeUpload, setGitSouceCodeUpload] = useState({
    microserviceID: props.serviceId,
    versionID: props.versionID,
    branch: "",
    isPublic: true,
    Url: "",
    token: "",
  });

  useEffect(() => {
    if (CreatedVersionID) {
      setLocalSourceCodeUpload({
        ...localSourceCodeUpload,
        versionID: CreatedVersionID,
      });
    } else {
      setLocalSourceCodeUpload({
        ...localSourceCodeUpload,
        versionID: props.versionID,
      });
    }
  }, [props.versionID, CreatedVersionID]);

  const handleUploadSourceTab = (event, newValue) => {
    setUploadTab(newValue);
  };

  const confirmReupload = (uploadType) => {
    setUploadSourceCodeType(uploadType);
    if (versionDetails?.docker?.imagedetails?.ImageID) {
      if (uploadType === "local") {
        if (localSourceCodeUpload.file) {
          setConfirmReuploadDeleteImage(true);
        } else {
          setLocalError({ files: true });
        }
      } else {
        const { formIsValid, errors } = handleFormValidation(
          gitSouceCodeUpload,
          gitError
        );
        if (formIsValid) {
          setConfirmReuploadDeleteImage(true);
        } else {
          setGitError(errors);
        }
      }
    } else {
      if (uploadType === "local") {
        handleLocalUploadSourceCode();
      } else {
        handleGitUploadSourceCode();
      }
    }
  };

  const handleLocalUploadSourceCode = async () => {
    if (localSourceCodeUpload.file) {
      var formData = new FormData();
      if (props.isUpload) {
        formData.append("file", localSourceCodeUpload.file);
        setIsLoader(true);
        const { _msg, _status, data } = await reUploadLocalSourceCode(
          props.repoId,
          formData
        );
        if (_status === 201) {
          enqueueSnackbar(_msg, { variant: "success" });
          setIsLoader(false);
          props.setOpenUploadSourceCodeModal(false);
          setLocalSourceCodeUpload({
            ...localSourceCodeUpload,
            versionID: "",
            file: {},
          });
          props.getVersionList(props.serviceId, 0);
          props.handleSubmit(data);
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
          setIsLoader(false);
        }
      } else {
        formData.append(
          "meta",
          JSON.stringify({
            microserviceID: localSourceCodeUpload.microserviceID,
            versionID: localSourceCodeUpload.versionID,
          })
        );
        formData.append("file", localSourceCodeUpload.file);
        setIsLoader(true);
        const { _msg, _status, data } = await uploadLocalSourceCode(formData);
        if (_status === 201) {
          enqueueSnackbar(_msg, { variant: "success" });
          setIsLoader(false);
          if (isTabbedApproach && setCreateVersion) {
            setCreateVersion(false);
          }
          setLocalSourceCodeUpload({
            ...localSourceCodeUpload,
            versionID: "",
            file: {},
          });
          props.setOpenUploadSourceCodeModal(false);
          props.getVersionList(props.serviceId, 0);
          props.handleSubmit(data);
          if (CreatedVersionID) {
            history.push(
              `/versions/details/${props.serviceId}/${CreatedVersionID}`
            );
          } else {

            if (isAiTask || isAiTaskFromUrl) {
              history.push({
                pathname: `/aitask/edit/${props.serviceId}`,
                state: "version",
              });
            }
            else {
              history.push({
                pathname: `/services/edit/${props.serviceId}`,
                state: "version",
              });
            }
          }
          // if (isTabbedApproach) {

          // } else {

          // }
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
          setIsLoader(false);
        }
      }
    } else {
      setLocalError({ files: true });
    }
  };

  const handleGitUploadSourceCode = async () => {
    const { formIsValid, errors } = handleFormValidation(
      gitSouceCodeUpload,
      gitError
    );
    if (formIsValid) {
      props.setOpenUploadSourceCodeModal(false);
      const newSourceCodeData = {
        microserviceID: gitSouceCodeUpload.microserviceID,
        versionID: gitSouceCodeUpload.versionID,
        repositoryDetails: {
          branch: gitSouceCodeUpload.branch,
          Url: gitSouceCodeUpload.Url,
          token: gitSouceCodeUpload.token,
        },
      };

      setIsLoader(true);

      if (props.isUpload) {
        setIsLoader(true);
        const { _msg, _status, data } = await reUploadSourceCodeByGit(
          props.repoId,
          newSourceCodeData
        );
        if (_status === 201) {
          props.setOpenUploadSourceCodeModal(false);
          props.getVersionList(props.serviceId, 0);
          props.handleSubmit(data);
        } else {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "error" });
        }
      } else {
        const { _msg, _status, data } = await uploadSourceCodeByGit(
          newSourceCodeData
        );
        if (_status === 200) {
          enqueueSnackbar(_msg, { variant: "success" });
          setIsLoader(false);
          props.setOpenUploadSourceCodeModal(false);
          props.getVersionList(props.serviceId, 0);
          props.handleSubmit(data);
          if (CreatedVersionID) {
            history.push(
              `/versions/edit/${props.serviceId}/${CreatedVersionID}`
            );
          }
          if (isTabbedApproach) {
            history.push({
              pathname: `/services/edit/${props.serviceId}`,
              state: "version",
            });
          }
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
          setIsLoader(false);
        }
      }
    } else {
      setGitError(errors);
    }
  };
  return (
    <>
      <Backdrop
        className={styles.backdrop}
        open={isLoader}
        // onClick={() => setIsLoader(false)}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <ConfirmReuploadDeleteImage
        open={confirmReuploadDeleteImage}
        handleSubmit={() => {
          setIsLoader(true);
          if (versionDetails?.docker?.Instances?.length > 0) {
            const handleStopDockerContainer = async () => {
              setConfirmReuploadDeleteImage(false);
              const { _status } = await StopContainer(
                versionDetails?.docker?.Instances[0]?.containerid
              );

              const handleDeleteContainer = async () => {
                const { _status } = await DeleteContainer(
                  versionDetails?.docker?.Instances[0]?.containerid
                );

                const handleDeleteDockerImage = async () => {
                  const { _status, _msg } = await DeleteDockerImage(
                    versionDetails?.docker?.imagedetails?.ImageID
                  );
                  if (_status === 400) {
                    enqueueSnackbar(_msg, { variant: "error" });
                    setIsLoader(false);
                  } else {
                    if (uploadSourceCodeType === "local") {
                      handleLocalUploadSourceCode();
                    } else {
                      handleGitUploadSourceCode();
                    }
                    props.getVersionList(props.serviceId, 0);
                  }

                  // setIsLoader(false);
                };
                handleDeleteDockerImage();
              };

              handleDeleteContainer();
            };

            handleStopDockerContainer();
          } else {
            setConfirmReuploadDeleteImage(false);

            const handleDeleteDockerImage = async () => {
              setIsLoader(true);
              const { _status, _msg } = await DeleteDockerImage(
                versionDetails?.docker?.imagedetails?.ImageID
              );

              if (_status === 400) {
                enqueueSnackbar(_msg, { variant: "error" });
                setIsLoader(false);
              } else {
                if (uploadSourceCodeType === "local") {
                  handleLocalUploadSourceCode();
                } else {
                  handleGitUploadSourceCode();
                }
                props.getVersionList(props.serviceId, 0);
                // setIsLoader(false);
              }
            };
            handleDeleteDockerImage();
          }
        }}
        handleClose={() => setConfirmReuploadDeleteImage(false)}
      />

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={props.openUploadSourceCodeModal}
      // onClose={() => props.setOpenUploadSourceCodeModal(false)}
      >
        <Tabs
          value={uploadTab}
          onChange={handleUploadSourceTab}
          indicatorColor="primary"
          textColor="primary"
          className={styles.documentTab}
        >
          <Tab label="Local" />
          <Tab label="Git" />
        </Tabs>
        {uploadTab === 0 && (
          <>
            <DialogContent
              className={styles.modalContent}
              style={{ minHeight: "15rem" }}
            >
              <FileDropperWithChip
                filesLimit={1}
                handleDropFile={(data) => {
                  setLocalSourceCodeUpload({
                    ...localSourceCodeUpload,
                    file: data[0],
                  });
                  setLocalError({ files: false });
                }}
                acceptedFiles={[".zip"]}
              />
              <p className={styles.fieldHeading}>{`Supports only .zip`}</p>
              {localError.files && !localSourceCodeUpload.file && (
                <p className={styles.errorMsg}>{"File is Required"}</p>
              )}
            </DialogContent>
            <DialogActions className={styles.head}>
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                disabled={false}
                className="btnsmall"
                onClick={() => {
                  if (CreatedVersionID) {
                    history.push(
                      `/versions/details/${props.serviceId}/${CreatedVersionID}`
                    );
                  } else if (isTabbedApproach) {
                    if (isCreateMode) {
                      setCreateVersion(false);

                      if (isAiTask) {
                        history.push({
                          pathname: `/aitask/edit/${props.serviceId}`,
                          state: "version",
                        });
                      } else {
                        history.push({
                          pathname: `/services/edit/${props.serviceId}`,
                          state: "version",
                        });
                      }
                    }
                    props.setOpenUploadSourceCodeModal(false);
                  } else {
                    if (isAiTask || isAiTaskFromUrl) {
                      history.push({
                        pathname: `/aitask/edit/${props.serviceId}`,
                        state: "version",
                      });
                    } else {
                      history.push({
                        pathname: `/services/edit/${props.serviceId}`,
                        state: "version",
                      });
                    }
                    props.setOpenUploadSourceCodeModal(false);
                  }
                }}
              />
              <AppButton
                buttonName="Submit"
                variant="contained"
                disabled={false}
                className="btnsmall"
                // onClick={handleLocalUploadSourceCode}
                onClick={() => confirmReupload("local")}
              />
            </DialogActions>
          </>
        )}
        {uploadTab === 1 && (
          <>
            <DialogContent
              className={styles.modalContent}
              style={{ minHeight: "15rem" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Url <span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="Url"
                    className={`${styles.input} ${gitError.Url && styles.error
                      }`}
                    value={gitSouceCodeUpload.Url ? gitSouceCodeUpload.Url : ""}
                    onChange={(e) => {
                      setGitSouceCodeUpload({
                        ...gitSouceCodeUpload,
                        Url: e.target.value,
                      });
                      setGitError({ ...gitError, Url: false });
                    }}
                    autoComplete="off"
                  />
                  {gitError.Url && (
                    <p className={styles.errorMsg}>{"Url is Required"}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Branch
                  </label>
                  <input
                    type="text"
                    name="branch"
                    className={`${styles.input}`}
                    value={
                      gitSouceCodeUpload.branch ? gitSouceCodeUpload.branch : ""
                    }
                    onChange={(e) =>
                      setGitSouceCodeUpload({
                        ...gitSouceCodeUpload,
                        branch: e.target.value,
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Repo Type
                  </label>
                  <div>
                    <FormControlLabel
                      value={gitSouceCodeUpload.isPublic}
                      control={
                        <Switch
                          checked={gitSouceCodeUpload.isPublic}
                          onChange={() => {
                            setGitSouceCodeUpload({
                              ...gitSouceCodeUpload,
                              isPublic: !gitSouceCodeUpload.isPublic,
                            });
                            if (gitSouceCodeUpload.isPublic) {
                              setGitError({ ...gitError, token: false });
                            } else {
                              const tempError = gitError;
                              delete tempError["token"];
                              setGitError(tempError);
                            }
                          }}
                          color="primary"
                        />
                      }
                      style={{ marginLeft: 0 }}
                      label="Private"
                      labelPlacement="start"
                    />
                    <span
                      style={{
                        marginLeft: 10,
                        font: "normal normal 400 1rem Nunito",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setGitSouceCodeUpload({
                          ...gitSouceCodeUpload,
                          isPublic: !gitSouceCodeUpload.isPublic,
                        });
                        if (gitSouceCodeUpload.isPublic) {
                          setGitError({ ...gitError, token: false });
                        } else {
                          const tempError = gitError;
                          delete tempError["token"];
                          setGitError(tempError);
                        }
                      }}
                    >
                      Public
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Token{" "}
                    {!gitSouceCodeUpload.isPublic && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="token"
                    className={`${styles.input} ${gitError.token && styles.error
                      }`}
                    value={
                      gitSouceCodeUpload.token ? gitSouceCodeUpload.token : ""
                    }
                    onChange={(e) => {
                      if (!gitSouceCodeUpload.isPublic) {
                        setGitSouceCodeUpload({
                          ...gitSouceCodeUpload,
                          token: e.target.value,
                        });
                        setGitError({ ...gitError, token: false });
                      }
                    }}
                    autoComplete="off"
                  />
                  {gitError.token && (
                    <p className={styles.errorMsg}>{"Token is Required"}</p>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={styles.head}>
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                disabled={false}
                className="btnsmall"
                onClick={() => {
                  if (CreatedVersionID) {
                    history.push(
                      `/versions/details/${props.serviceId}/${CreatedVersionID}`
                    );
                  } else if (isTabbedApproach) {
                    if (isCreateMode) {
                      if (
                        history.location.pathname ==
                        `/aitask/edit/${props.serviceId}`
                      ) {
                        history.push({
                          pathname: `/aitask/edit/${props.serviceId}`,
                          state: "version",
                        });
                      } else {
                        history.push({
                          pathname: `/services/edit/${props.serviceId}`,
                          state: "version",
                        });
                      }
                    }
                    props.setOpenUploadSourceCodeModal(false);
                  } else {
                    props.setOpenUploadSourceCodeModal(false);
                  }
                }}
              />
              <AppButton
                buttonName="Submit"
                variant="contained"
                startIcon={
                  isLoader ? (
                    <CircularProgress
                      color="white"
                      style={{ height: "14px", width: "14px" }}
                    />
                  ) : (
                    <BuildIcon />
                  )
                }
                disabled={false}
                className="btnsmall"
                onClick={() => confirmReupload("git")}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  getVersionList,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadSourceCodeInVersion);
