/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../services/userProfile";
import User from "./Assets/profile.svg";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import Styles from "../../screens/CommonStyles/UserProfile";
import { addUserNotification } from "../../redux/actions/userPersonalAction";
import Loader from "../../components/common/stuff/Loader";
import { fetchProjectById } from "../../utils/project/projectUtils";
import { fetchServiceById } from "../../utils/project/microserviceUtils";
import { getApplicationDataByID } from "../../utils/orchestration/applicationUtils";
import { UiApplicationService } from "../../UiApplication/services/UiApplicationService";

const userProfile = new UserProfile();
const apiService = new UiApplicationService();

const Notifications = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = userDetails;
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const styles = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const { userid } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await userProfile.fetchAllNotifications(userid, currentPage);
      if (resp?.data?.data !== null) {
        setNotifications(resp?.data ? resp?.data : []);
        dispatch(addUserNotification(resp?.data ? resp?.data : {}));
      } else {
        enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "No notifications found",
          {
            variant: "error",
          }
        );
        dispatch(addUserNotification({}));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar("No notifications found", {
        variant: "error",
      });
      dispatch(addUserNotification({}));
    }
  };

  useEffect(() => {
    fetchData();
  }, [userid, currentPage]);

  const handleMarkedAsRead = async (id) => {
    const data = await userProfile.markAsRead(id, {
      status: "seen",
    });
    if (data?.status < 210) {
      fetchData();
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleNavigation = async (item) => {
    // Project
    if (item?.groupname?.split("_")[0] === "project") {
      handleMarkedAsRead(item._id);
      const { _status } = await fetchProjectById(
        item?.groupname?.split("_")[1]
      );
      if (_status < 210) {
        history.push(`/projects/details/${item?.groupname?.split("_")[1]}`);
      } else {
        enqueueSnackbar("Project doesn't exist", { variant: "error" });
      }
    }
    // Service
    else if (item?.groupname?.split("_")[0] === "service") {
      handleMarkedAsRead(item._id);
      const { _status } = await fetchServiceById(
        item?.groupname?.split("_")[1]
      );
      if (_status < 210) {
        history.push(`/services/details/${item?.groupname?.split("_")[1]}`);
      } else {
        enqueueSnackbar("Service doesn't exist", { variant: "error" });
      }
    }
    // Application
    else if (item?.groupname?.split("_")[0] === "application") {
      handleMarkedAsRead(item._id);
      const { _status } = await getApplicationDataByID(
        item?.groupname?.split("_")[1]
      );
      if (_status < 210) {
        history.push(`/application/details/${item?.groupname?.split("_")[1]}`);
      } else {
        enqueueSnackbar("Application doesn't exist", {
          variant: "error",
        });
      }
    }
    // UI Application
    else if (item?.groupname?.split("_")[0] === "uiApplication") {
      handleMarkedAsRead(item._id);
      const resp = await apiService.getApplicationById(
        item?.groupname?.split("_")[1]
      );
      if (resp?.status < 210) {
        history.push(`/ui-applications/edit/${item?.groupname?.split("_")[1]}`);
      } else {
        enqueueSnackbar("UI Application doesn't exist", {
          variant: "error",
        });
      }
    }
  };

  const DateCell = (date) => {
    let time = moment.unix(date).fromNow();
    return time;
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ paddingBottom: 100, width: "100%" }}>
        {notifications?.data &&
          notifications?.data.length > 0 &&
          notifications?.data?.map((item) => {
            return (
              <div
                className={styles.notification}
                style={{
                  backgroundColor:
                    item?.status === "unseen" ? "#F5FAFF" : "#FFFFFF",
                }}
                onClick={() => handleNavigation(item)}
              >
                <div className="profilepic">
                  <img src={User} alt="" height={32} width={32} />

                  <div>
                    <span className="message"> {item?.message}</span>
                    {item?.updatedby && <span> by {item?.updatedby}</span>}
                    <span className="date">
                      {"  "}
                      {DateCell(
                        item?.updatedon ? item?.updatedon : item?.createdon
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <span
                    className="markAsRead"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkedAsRead(item?._id);
                    }}
                  >
                    {item?.status === "unseen" && <b>Mark as Read</b>}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      {notifications?.data?.length === 0 && (
        <div
          style={{
            display: "flex",
            // border: "5px solid red",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <h2>No Notifications found</h2>
        </div>
      )}
      <div className={styles.footer}>
        <div style={{ width: "fit-content", marginLeft: "auto" }}>
          <Pagination
            count={Math.ceil(notifications._totalcount / 10)}
            page={currentPage}
            onChange={handleChangePage}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default Notifications;
