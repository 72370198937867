import useGetData from "../../../../../hooks/useGetData";
import StatusStyles from "./TrueFalseButtonStyles";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useTheme } from "@material-ui/styles";

const TrueFalseButton = ({ leftButton, rightButton, value, name }) => {
  const styles = StatusStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentPage, currentComponent } = useGetData();

  const handleChange = (data) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: data,
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <div className={styles.loadingbtnContainer}>
      <button
        className={styles.loadingbtn}
        onClick={() => handleChange(false)}
        style={
          value
            ? {
                backgroundColor: theme.palette.primary.main,
                color: "#FFFFFF",
                textTransform: "capitalize",
              }
            : { textTransform: "capitalize" }
        }
      >
        {leftButton}
      </button>
      <button
        style={
          !value
            ? {
                backgroundColor: theme.palette.primary.main,
                color: "#FFFFFF",
                textTransform: "capitalize",
              }
            : { textTransform: "capitalize" }
        }
        className={styles.loadingbtn}
        onClick={() => handleChange(true)}
      >
        {rightButton}
      </button>
    </div>
  );
};

export default TrueFalseButton;
