import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AppButton } from '../button/AppButton';
import CloseIcon from "@material-ui/icons/Close";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CustomFont from '../fontDropdown/CustomFont';
import FontStyleGroup from '../../molecules/buttonGroup/FontStyleGroup';
import GlobalCssModalStyles from '../../../styles/commonStyles/GlobalCssModal'
import { ChromePicker } from 'react-color';

const GlobalCssModal = ({ open, css, setCss, handleClose, handleSave, createScreen }) => {
    const styles = GlobalCssModalStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle className={styles.modalTitle}>Global CSS</DialogTitle>
            <DialogContent className={styles.modalContent}>
                <div className="field">
                    <label style={{ marginBottom: 2 }}>Font Family</label>
                    <CustomFont
                        value={css?.fontFamily}
                        onChange={(nextFont) => setCss({ ...css, fontFamily: nextFont?.family })}
                    />
                </div>
                <div className="field">
                    <label>Font Size</label>
                    <input
                        type="number"
                        name={'fontSize'}
                        value={css?.fontSize}
                        autoComplete="off"
                        onChange={(e) => setCss({ ...css, fontSize: parseInt(e.target.value) })}
                    />
                </div>
                <div className="field">
                    <label>Style</label>
                    <FontStyleGroup fStyle={css?.fontStyle} handleChange={(fontStyle) => setCss({ ...css, fontStyle: fontStyle })} />
                </div>
                <div className="field">
                    <label>Color</label>
                    <ChromePicker
                        color={css?.color}
                        onChange={(updatedColor) => setCss({ ...css, color: updatedColor?.hex })}
                    />
                </div>
                {!createScreen && <p className="note">Note : Any changes made to the global CSS will not be reflected on the existing components.</p>}
            </DialogContent>
            <DialogActions className={styles.modalFooter}>
                <AppButton
                    buttonName="Close"
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: 15 }}
                    startIcon={<CloseIcon />}
                    className="btnsmall"
                    onClick={handleClose}
                />
                <AppButton
                    buttonName="Save"
                    variant="contained"
                    style={{ marginLeft: 15 }}
                    startIcon={<SaveOutlinedIcon />}
                    className="btnsmall"
                    onClick={handleSave}
                />
            </DialogActions>
        </Dialog>
    )
}

export default GlobalCssModal