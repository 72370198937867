import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    dndflow: {
        display: 'flex',
        // flexDirection: 'column',
        height: '77vh',
        borderWidth: 2,
        borderColor: 'black',
        // marginTop: 2,
    },
    reactflowWrapper: {
        flexGrow: 1,
        height: '100%',
        /* width: 100%; */
        // zIndex : 100
    },
    controls: {
        top: 20,
        right: 30,
        bottom: 'unset',
        left: 'unset',
        display: 'flex'
    },
    call: {
        backgroundColor: 'red'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'blue',
    },

}));
