import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import layoutStyles from "../../../styles/commonStyles/Layout";
import useGetData from "../../../hooks/useGetData";
// Table
import TableSettings from "../../organisms/rightSidebar/Table/TableSettings";
import TableAppearances from "../../organisms/rightSidebar/Table/TableAppearances";
import TableTriggers from "../../organisms/rightSidebar/Table/TableTriggers";
// form
import FormSettings from "../../organisms/rightSidebar/Form/FormSettings";
import FormAppearances from "../../organisms/rightSidebar/Form/FormAppearances";
import FormTriggers from "../../organisms/rightSidebar/Form/FormTriggers";
// link
import LinkSettings from "../../organisms/rightSidebar/Link/LinkSettings";
import LinkAppearances from "../../organisms/rightSidebar/Link/LinkAppearances";
import LinkTriggers from "../../organisms/rightSidebar/Link/LinkTriggers";
// Detail
import DetailSettings from "../../organisms/rightSidebar/detail/DetailSettings";
import DetailAppearances from "../../organisms/rightSidebar/detail/DetailAppearance";
import DetailTriggers from "../../organisms/rightSidebar/detail/DetailTriggers";
// Text
import TextSettings from "../../organisms/rightSidebar/Text/TextSettings";
import TextAppearances from "../../organisms/rightSidebar/Text/TextAppearances";
import TextTriggers from "../../organisms/rightSidebar/Text/TextTriggers";
// heading
import HeadingSettings from "../../organisms/rightSidebar/Heading/HeadingSettings";
import HeadingAppearances from "../../organisms/rightSidebar/Heading/HeadingAppearances";
import HeadingTriggers from "../../organisms/rightSidebar/Heading/HeadingTriggers";
// menu
import MenuSettings from "../../organisms/rightSidebar/Menu/MenuSettings";
import MenuAppearances from "../../organisms/rightSidebar/Menu/MenuAppearances";
import MenuTriggers from "../../organisms/rightSidebar/Menu/MenuTriggers";
// toggle
import ToggleSettings from "../../organisms/rightSidebar/Toggle/ToggleSettings";
import ToggleAppearances from "../../organisms/rightSidebar/Toggle/ToggleAppearances";
import ToggleTriggers from "../../organisms/rightSidebar/Toggle/ToggleTriggers";
// DatePicker
import DatePickerSettings from "../../organisms/rightSidebar/DatePicker/DatePickerSettings";
import DatePickerAppearances from "../../organisms/rightSidebar/DatePicker/DatePickerAppearances";
import DatePickerTriggers from "../../organisms/rightSidebar/DatePicker/DatePickerTriggers";
// Button
import ButtonAppearances from "../../organisms/rightSidebar/Button/ButtonAppearances";
import ButtonSettings from "../../organisms/rightSidebar/Button/ButtonSettings";
import ButtonTriggers from "../../organisms/rightSidebar/Button/ButtonTriggers";
// TextArea
import TextAreaSettings from "../../organisms/rightSidebar/textArea/TextAreaSettings";
import TextAreaAppearance from "../../organisms/rightSidebar/textArea/TextAreaAppearance";
import TextAreaTiggers from "../../organisms/rightSidebar/textArea/TextAreaTriggers";

// Select
import SelectSettings from "../../organisms/rightSidebar/select/SelectSettings";
import SelectAppearances from "../../organisms/rightSidebar/select/SelectAppearances";
import SelectTriggers from "../../organisms/rightSidebar/select/SelectTriggers";
// Input
import InputSettings from "../../organisms/rightSidebar/Input/InputSettings";
import InputAppearance from "../../organisms/rightSidebar/Input/InputAppearance";
import InputTiggers from "../../organisms/rightSidebar/Input/InputTriggers";
// checkbox
import CheckboxAppearance from "../../organisms/rightSidebar/Checkbox/CheckboxApperance";
import CheckboxSettings from "../../organisms/rightSidebar/Checkbox/CheckboxSettings";
import CheckboxTiggers from "../../organisms/rightSidebar/Checkbox/CheckboxTriggers";
import CustomComponentSettings from "../../organisms/rightSidebar/CustomComponent/CustomComponentSettings";
import CustomComponentAppearances from "../../organisms/rightSidebar/CustomComponent/CustomComponentAppearances";
import CustomComponentTriggers from "../../organisms/rightSidebar/CustomComponent/CustomComponentTriggers";
//Image
import ImageSettings from "../../organisms/rightSidebar/image/ImageSettings";
import ImageAppearances from "../../organisms/rightSidebar/image/ImageAppearances";
import ImageTriggers from "../../organisms/rightSidebar/image/ImageTriggers";
//Radio
import RadioSettings from "../../organisms/rightSidebar/Radio/RadioSettings";
import RadioAppearances from "../../organisms/rightSidebar/Radio/RadioAppearances";
import RadioTriggers from "../../organisms/rightSidebar/Radio/RadioTriggers";
import TreeSettings from "../../organisms/rightSidebar/Tree/TreeSettings";
import TreeAppearances from "../../organisms/rightSidebar/Tree/TreeAppearances";
import TreeTriggers from "../../organisms/rightSidebar/Tree/TreeTriggers";
import { useDispatch } from "react-redux";
import { editUiApplication } from "../../../../redux/actions/uiApplicationAction";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { useSnackbar } from "notistack";
import { AppButton } from "../../atoms/button/AppButton";

const Rightbar = () => {
  const styles = layoutStyles();
  const { currentComponent, currentProject, currentPage } = useGetData();
  const [tab, setTab] = useState("setting");
  const [isEdit, setIsEdit] = useState(false);
  const [compName, setCompName] = useState(currentComponent?.name);
  const [nameError, setNameError] = useState(false);
  const dispatch = useDispatch();
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    setCompName(currentComponent?.name);
    setNameError(false);
    setIsEdit(false);
  }, [currentComponent]);

  const handleSaveName = () => {
    let error = false;
    const pages = currentProject.pages.map((page) => {
      return {
        ...page,
        components: page.components.map((component) => {
          let tempComp = {};
          if (
            component.name === compName &&
            component.name !== currentComponent.name
          ) {
            error = true;
            tempComp = component;
          } else {
            if (component.id === currentComponent.id) {
              tempComp = {
                ...component,
                name: compName,
              };
            } else {
              tempComp = component;
            }
          }
          setNameError(error);
          return tempComp;
        }),
      };
    });
    if (!error) {
      dispatch(editUiApplication({ data: { pages: pages } }));
      setIsEdit(false);
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const apiService = new UiApplicationService();

  const handleEditComponent = async () => {
    const types = ["Menu", "Link", "Button"];
    try {
      const resp = await apiService?.editComponent(
        currentProject?.id,
        currentPage?.name,
        currentComponent?._id,
        currentComponent
      );
      if (
        types.includes(currentComponent?.type) &&
        currentProject?.linkedUiApplications !== undefined
      ) {
        const _uiAppResp = await apiService?.editApplication(
          currentProject?._id,
          currentProject
        );
      }
      if (resp?.status < 210) {
        enqueueSnackbar(resp?.data?._msg, {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?._msg, {
        variant: "success",
      });
    }
  };
  return (
    <div className={styles.rightbar}>
      {currentComponent?.id ? (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className={"rightTab"}
          >
            <Tab
              icon={
                <Tooltip title="Settings">
                  <img src="/img/settings-3-line.svg" alt="Setting" />
                </Tooltip>
              }
              value="setting"
            />
            <Tab
              icon={
                <Tooltip title="Appearance">
                  <img src="/img/brush-line.svg" alt="Design" />
                </Tooltip>
              }
              value="design"
            />
            <Tab
              icon={
                <Tooltip title="Triggers">
                  <img src="/img/flashlight-line.svg" alt="Trigger" />
                </Tooltip>
              }
              value="trigger"
            />
          </Tabs>

          <div className="compHeading">
            <div className="compTitle">
              {!isEdit ? (
                <p>{currentComponent?.name}</p>
              ) : (
                <div style={{ marginTop: 3 }}>
                  <input
                    type="text"
                    name="name"
                    style={{
                      border: nameError
                        ? "1px solid #E22626"
                        : "1px solid #D8D8D8",
                    }}
                    value={compName}
                    onChange={(e) => setCompName(e.target.value)}
                  />
                  {nameError && (
                    <p className="nameError">Component Name already exist</p>
                  )}
                </div>
              )}
              {!isEdit ? (
                <Tooltip title="Edit Component Name">
                  <IconButton
                    color="inherit"
                    edge="start"
                    style={{ margin: 0, padding: 5 }}
                    onClick={() => setIsEdit(true)}
                  >
                    <img src="/img/edit.svg" alt="edit" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Save Component Name">
                  <IconButton
                    color="inherit"
                    edge="start"
                    style={{ margin: 0, padding: 5 }}
                    onClick={() => handleSaveName()}
                  >
                    <img src="/img/Save2.svg" alt="save" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          {tab === "setting" && (
            <>
              <p className="tabType">Settings</p>
              <div className="settingTab">
                {currentComponent?.type === "Table" && <TableSettings />}
                {currentComponent?.type === "Details" && <DetailSettings />}
                {currentComponent?.type === "Form" && <FormSettings />}
                {currentComponent?.type === "Link" && <LinkSettings />}
                {currentComponent?.type === "Button" && <ButtonSettings />}
                {currentComponent?.type === "Text" && <TextSettings />}
                {currentComponent?.type === "Heading" && <HeadingSettings />}
                {currentComponent?.type === "Menu" && <MenuSettings />}
                {currentComponent?.type === "Toggle" && <ToggleSettings />}
                {currentComponent?.type === "Radio" && <RadioSettings />}
                {currentComponent?.type === "Tree" && <TreeSettings />}
                {currentComponent?.type === "DatePicker" && (
                  <DatePickerSettings />
                )}
                {currentComponent?.type === "TextArea" && <TextAreaSettings />}
                {currentComponent?.type === "Select" && <SelectSettings />}
                {currentComponent?.type === "Input" && <InputSettings />}
                {currentComponent?.type === "CheckBox" && <CheckboxSettings />}
                {currentComponent?.type === "Custom Component" && (
                  <CustomComponentSettings />
                )}
                {currentComponent?.type === "Image" && <ImageSettings />}
              </div>
            </>
          )}

          {tab === "design" && (
            <>
              <p className="tabType">Appearance</p>
              <div className="settingTab">
                {currentComponent?.type === "Table" && <TableAppearances />}
                {currentComponent?.type === "Form" && <FormAppearances />}
                {currentComponent?.type === "Details" && <DetailAppearances />}
                {currentComponent?.type === "Link" && <LinkAppearances />}
                {currentComponent?.type === "Button" && <ButtonAppearances />}
                {currentComponent?.type === "Text" && <TextAppearances />}
                {currentComponent?.type === "Heading" && <HeadingAppearances />}
                {currentComponent?.type === "Menu" && <MenuAppearances />}
                {currentComponent?.type === "Toggle" && <ToggleAppearances />}
                {currentComponent?.type === "Radio" && <RadioAppearances />}
                {currentComponent?.type === "Tree" && <TreeAppearances />}
                {currentComponent?.type === "DatePicker" && (
                  <DatePickerAppearances />
                )}
                {currentComponent?.type === "TextArea" && (
                  <TextAreaAppearance />
                )}
                {currentComponent?.type === "Select" && <SelectAppearances />}
                {currentComponent?.type === "CheckBox" && (
                  <CheckboxAppearance />
                )}
                {currentComponent?.type === "Input" && <InputAppearance />}
                {currentComponent?.type === "Custom Component" && (
                  <CustomComponentAppearances />
                )}
                {currentComponent?.type === "Image" && <ImageAppearances />}
              </div>
            </>
          )}
          {tab === "trigger" && (
            <>
              <p className="tabType">Trigger</p>
              <div className="settingTab">
                {currentComponent?.type === "Table" && <TableTriggers />}
                {currentComponent?.type === "Form" && <FormTriggers />}
                {currentComponent?.type === "Details" && <DetailTriggers />}
                {currentComponent?.type === "Link" && <LinkTriggers />}
                {currentComponent?.type === "Button" && <ButtonTriggers />}
                {currentComponent?.type === "Text" && <TextTriggers />}
                {currentComponent?.type === "Heading" && <HeadingTriggers />}
                {currentComponent?.type === "Menu" && <MenuTriggers />}
                {currentComponent?.type === "Toggle" && <ToggleTriggers />}
                {currentComponent?.type === "Radio" && <RadioTriggers />}
                {currentComponent?.type === "Tree" && <TreeTriggers />}
                {currentComponent?.type === "DatePicker" && (
                  <DatePickerTriggers />
                )}
                {currentComponent?.type === "TextArea" && <TextAreaTiggers />}
                {currentComponent?.type === "Select" && <SelectTriggers />}
                {currentComponent?.type === "Input" && <InputTiggers />}
                {currentComponent?.type === "CheckBox" && <CheckboxTiggers />}
                {currentComponent?.type === "Custom Component" && (
                  <CustomComponentTriggers />
                )}
                {currentComponent?.type === "Image" && <ImageTriggers />}
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppButton
              color="primary"
              buttonName="Save"
              variant="contained"
              className="btnsmall"
              style={{ width: 185 }}
              onClick={handleEditComponent}
            />
          </div>
        </>
      ) : (
        <Grid container style={{ padding: 14 }}>
          <Typography variant="body2" color="initial">
            Component settings, apperance and trigers will be shown here
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default Rightbar;
