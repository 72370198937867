import { ApiHelper } from "./helper/ApiHelper";
import { EXECUTION, TEST } from "./helper/config";

const apiHelper = new ApiHelper();

export class TestDataService {
  getAllTestData(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${EXECUTION}/api/v1.0.0/testdata?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  deleteTestData(TestDataId) {
    const uri = `${EXECUTION}/api/v1.0.0/testdata/${TestDataId}`;
    return apiHelper.delete(uri);
  }

  addTestEnvironmentFile(envData) {
    const uri = `${TEST}/api/v1.0.0/environments`;
    return apiHelper.post(uri, envData);
  }

  fetchTestDataById(testDataID) {
    const uri = `${TEST}/api/v1.0.0/testdata/${testDataID}`;
    return apiHelper.get(uri);
  }

  fetchTestEnvironmentDataById(envID) {
    const uri = `${TEST}/api/v1.0.0/environments/${envID}`;
    return apiHelper.get(uri);
  }

  updateTestDataByID(id, testData) {
    const uri = `${TEST}/api/v1.0.0/testdata/${id}`;
    return apiHelper.put(uri, testData);
  }

  updateTestEnvDataByID(id, envData) {
    const uri = `${TEST}/api/v1.0.0/environments/${id}`;
    return apiHelper.put(uri, envData);
  }
}
