import {
  Grid,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
  Button,
  makeStyles,
  Tabs,
  Tab,
  Box,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
// files
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import {
  fetchCurrentORGDetails,
  getOntologyResource,
  updateOntologyResource,
} from "../../../utils/organization/organizationUtils";
import { useSnackbar } from "notistack";
import { AppButton } from "../../../components/common/Button";
import moment from "moment";
import { connect } from "react-redux";
import {
  add_current_organization_data,
  featchAllCloudConfigurationActions,
} from "../../../redux/actions/organizationAction";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import CloudConfigDevExpress from "../../../components/common/Tables/CloudConfigDevExpress";
import {
  cloudConfigColumns,
  cloudConfigDefaultColumnWidth,
  cloudConfigTableColumnExtensions,
  cloudConfigColumnOrder,
  cloudConfigColumnHidden,
} from "../../../constants/Organization/cloudConfigConstants";
import listStyles from "../../CommonStyles/listScreenStyles";
import { Pagination } from "@material-ui/lab";
import { get } from "immutable";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";

export const useStyles = makeStyles((theme) => ({
  button: {
    "& button.btnsmall": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 0",
      textTransform: "capitalize !important",

      font: "normal normal 400 14px/19px Nunito",
      letterSpacing: "0.02em",
      padding: "6px 20px 6px 20px",
      minHeight: "32px",
      minWidth: "120px",
      "&.MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      },
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "120px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.btntiny": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal 500 12px/16px Nunito",
      letterSpacing: "0.02em",
      padding: "5px 34px",
      minHeight: "24px",
      minWidth: "100px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "106px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.secondaryButton": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
    "& button.MuiButton-outlined": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <>
      {value === index && (
        <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
          <div style={{ padding: "0px 0px 0" }}>{children}</div>
        </Paper>
      )}
    </>
  );
};

const OrganizationDetailsScreen = (props) => {
  const { alterActiveTab, tabbedView } = props;
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [organizationData, setOrganizationData] = useState("");
  const [address, setAddress] = useState("");
  const { userInfo, featchAllCloudConfigurationActions, _totalcount, data } =
    props;
  const [currentPageCloudConfig, setCurrentPageCloudConfig] = useState(1);
  const devStyles = listStyles();
  const [cloudConfigRows, setCloudConfigRows] = useState([]);
  const classes = useStyles();
  const [updateFound, setUpdateFound] = useState();

  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // alterActiveTab("Organization", "Organization");
    const getOntology = async () => {
      const { _msg, _status, data } = await getOntologyResource();
      if (_status === 200) {
        setUpdateFound(data);
      }
    };
    getOntology();
  }, []);

  useEffect(() => {
    featchAllCloudConfigurationActions(currentPageCloudConfig);
  }, [currentPageCloudConfig]);

  useEffect(() => {
    setCloudConfigRows(
      data && data?.length > 0
        ? data.map((item) => {
            item["isDetailMode"] = true;
            return item;
          })
        : []
    );
  }, [data]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Organization", ["Read"]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchCurrentORGDetails();
      if (_status === 200) {
        setOrganizationData(() => (data ? data : []));
        setAddress(data ? data.address : "");
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchData();
  }, []);

  let myArr = address.split(",");

  const handleEdit = () => {
    if (organizationData?.status === "inactive") {
      enqueueSnackbar(
        "Cannot edit a Blocked Organization. Kindly unblock first.",
        {
          variant: "error",
        }
      );
    } else {
      histroy.push(`/edit-organization`);
    }
  };

  const handleUpdate = async () => {
    const { _msg, _status, data } = await updateOntologyResource();
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "info" });
      setUpdateFound("");
    } else if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      setUpdateFound("");
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please contact help desk",
        { variant: "error" }
      );
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={tabbedView ? styles.topContent2 : styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Organizations", path: "/organization-deatils" },
              {
                title: `${organizationData.name ? organizationData.name : ""}`,
              },
            ]}
          />

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <h2 className={styles.title}>{organizationData.name}</h2>
            {tabbedView ? (
              <TabbedNavigation
                // schemaLength={languageEntitySchema?.sections?.length}
                isEditMode={false}
                activeTab={activeTab}
                handleEdit={handleEdit}
                removeCancel={true}
              />
            ) : (
              <AppButton
                color="primary"
                buttonName="Edit"
                variant="contained"
                // startIcon={<img src="/images/edit.svg" alt="" />}
                disabled={!checkCompPermission("Organization", ["Edit"])}
                style={{ padding: "6px 15px" }}
                className="btnsmall"
                onClick={handleEdit}
              />
            )}
          </Grid>
          <div className={styles.border_line} />
        </div>
        {tabbedView ? (
          <div className={styles.wrapper2} style={{ marginTop: 10 }}>
            <>
              <Tabs
                value={activeTab}
                onChange={handleActiveTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
                variant={"scrollable"}
                style={{ top: 0 }}
              >
                <Tab label="Organization Details" />
                <Tab label="Logo" />
                <Tab label="Settings" />
                <Tab label="Cloud Configuration" />
              </Tabs>

              <TabPanel value={activeTab} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Organization Name{" "}
                          <span className={styles.red}>*</span>
                        </label>
                        <input
                          name="name"
                          type="text"
                          className={styles.input2}
                          value={organizationData.name}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Username <span className={styles.red}>*</span>
                        </label>
                        <input
                          type="text"
                          className={styles.input2}
                          value={organizationData.username}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>Industry</label>
                        <input
                          name="name"
                          type="text"
                          className={styles.input2}
                          value={organizationData.industry}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Created By
                        </label>
                        <input
                          name="name"
                          type="text"
                          className={styles.input2}
                          value={organizationData.createdby}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Created on
                        </label>
                        <input
                          type="text"
                          className={styles.input2}
                          value={
                            userInfo?.timeZone
                              ? moment
                                  .unix(organizationData.createdon)
                                  .tz(userInfo.timeZone)
                                  .format(
                                    userInfo.time === "h:mm"
                                      ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                      : `${userInfo.dateFormat} ${userInfo.time}`
                                  )
                              : moment
                                  .unix(organizationData.createdon)
                                  .format("DD/MM/YYYY, hh:mm A")
                          }
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Updated By
                        </label>
                        <input
                          type="text"
                          className={styles.input2}
                          value={organizationData.updatedby}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Updated On
                        </label>
                        <input
                          value={
                            userInfo?.timeZone
                              ? moment
                                  .unix(organizationData.updatedon)
                                  .tz(userInfo.timeZone)
                                  .format(
                                    userInfo.time === "h:mm"
                                      ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                      : `${userInfo.dateFormat} ${userInfo.time}`
                                  )
                              : moment
                                  .unix(organizationData.updatedon)
                                  .format("DD/MM/YYYY, hh:mm A")
                          }
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <label className={styles.fieldHeading}>
                          Description
                        </label>
                        <textarea
                          name="description"
                          value={organizationData.description}
                          className={styles.textarea2}
                          disabled
                          rows="8"
                        ></textarea>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <h4>Contact details</h4>

                  <Grid
                    container
                    spacing={3}
                    style={{ margin: "0 1.5px 0 1.5px" }}
                  >
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>
                        Email <span className={styles.red}>*</span>
                      </label>
                      <input
                        value={organizationData.email}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>
                        Phone Number <span className={styles.red}>*</span>
                      </label>
                      <input
                        value={organizationData.contactNumber}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>Street</label>
                      <input
                        value={myArr[0] ? myArr[0] : ""}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>City</label>
                      <input
                        value={myArr[1] ? myArr[1] : ""}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>State</label>
                      <input
                        value={myArr[2] ? myArr[2] : ""}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>Country</label>
                      <input
                        value={myArr[3] ? myArr[3] : ""}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
                <div style={{ marginTop: 15 }}>
                  <Accordion className={styles.fieldPanel} defaultExpanded>
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.fieldHeading2}>
                        Contact details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} style={{ marginTop: 15 }}>
                        <Grid item xs={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <label className={styles.fieldHeading}>
                                Email <span className={styles.red}>*</span>
                              </label>
                              <input
                                value={organizationData.email}
                                type="text"
                                className={styles.input2}
                                autoComplete="off"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <label className={styles.fieldHeading}>
                                Phone Number{" "}
                                <span className={styles.red}>*</span>
                              </label>
                              <input
                                value={organizationData.contactNumber}
                                type="text"
                                className={styles.input2}
                                autoComplete="off"
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <label className={styles.fieldHeading}>Street</label>
                          <input
                            value={myArr[0] ? myArr[0] : ""}
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <label className={styles.fieldHeading}>City</label>
                          <input
                            value={myArr[1] ? myArr[1] : ""}
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <label className={styles.fieldHeading}>State</label>
                          <input
                            value={myArr[2] ? myArr[2] : ""}
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <label className={styles.fieldHeading}>Country</label>
                          <input
                            value={myArr[3] ? myArr[3] : ""}
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </TabPanel>

              <TabPanel value={activeTab} index={1}>
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: 10, marginBottom: 70 }}
                >
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>Page Title</label>
                    <input
                      name="name"
                      type="text"
                      className={styles.input2}
                      value={organizationData.title}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4}>
                    <div>
                      <label className={styles.fieldHeading}>Logo</label>
                    </div>
                    <div
                      className={styles.iconwrapper}
                      style={{ marginTop: 20 }}
                    >
                      {organizationData.logo && (
                        <img
                          variant="square"
                          src={organizationData.logo}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <label className={styles.fieldHeading}>Favicon</label>
                    </div>
                    <div
                      className={styles.iconwrapper}
                      style={{ marginTop: 20 }}
                    >
                      {organizationData.favicon && (
                        <img
                          variant="square"
                          src={organizationData.favicon}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={activeTab} index={2}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Grid container spacing={3}>
                      <Grid item xs={4} style={{ paddingTop: "23px" }}>
                        <label className={styles.fieldHeading}>Ontology</label>
                      </Grid>
                      <div
                        className={classes.button}
                        style={{ paddingTop: "20px" }}
                      >
                        {updateFound === "updates found" ? (
                          <Tooltip title="Download Available" arrow>
                            <Button
                              variant="contained"
                              className="btnsmall"
                              onClick={handleUpdate}
                            >
                              Update
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Download Available" arrow>
                            <Button
                              variant="contained"
                              className="btnsmall"
                              onClick={handleUpdate}
                              disabled={true}
                            >
                              Update
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} style={{ marginTop: "60px" }}>
                    <label className={styles.fieldHeading}>
                      Container inactivity (mins)
                    </label>
                    <Grid
                      xs={10}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <input
                        name="containerinactivity"
                        type="number"
                        maxLength={2}
                        className={`${styles.input2}`}
                        defaultValue={organizationData?.containerinactivity}
                        // onChange={handleChange}
                        disabled
                      />
                      {/* <p>&nbsp;&nbsp;mins</p> */}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={activeTab} index={3}>
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                  <CloudConfigDevExpress
                    columns={cloudConfigColumns}
                    rows={cloudConfigRows}
                    defaultColumnWidths={cloudConfigDefaultColumnWidth}
                    tableColumnExtensions={cloudConfigTableColumnExtensions}
                    columnOrder={cloudConfigColumnOrder}
                    loading={loading}
                    currentPage={currentPageCloudConfig}
                    setCurrentPage={setCurrentPageCloudConfig}
                    isCustomHeight={true}
                    // sort={testExecutionSort}
                    // windowDimensions={windowDimensions}
                    // setSort={addExecutionSort}
                    hidden={cloudConfigColumnHidden}
                    // setHidden={addServiceHidden}
                    noDataMsg="No Cloud Configuration Found"
                    setSelectedData={(row) => setSelectedData(row)}
                    setShowEditConfig={(e) =>
                      setIsCloudConfigurationEditMode(e)
                    }
                    isEditMode={true}
                  />
                  <div
                    className={devStyles.customPagination}
                    style={{ paddingTop: "1rem" }}
                  >
                    {cloudConfigRows.length !== 0 && (
                      <Pagination
                        count={Math.ceil(_totalcount / 10)}
                        page={currentPageCloudConfig}
                        onChange={(e, val) => setCurrentPageCloudConfig(val)}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />
                    )}
                  </div>
                </div>
              </TabPanel>
            </>
          </div>
        ) : (
          <>
            <div className={styles.wrapper3} style={{ marginTop: 85 }}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Organization Name <span className={styles.red}>*</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className={styles.input2}
                        value={organizationData.name}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Username <span className={styles.red}>*</span>
                      </label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={organizationData.username}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Industry</label>
                      <input
                        name="name"
                        type="text"
                        className={styles.input2}
                        value={organizationData.industry}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Created By</label>
                      <input
                        name="name"
                        type="text"
                        className={styles.input2}
                        value={organizationData.createdby}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Created on</label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={
                          userInfo?.timeZone
                            ? moment
                                .unix(organizationData.createdon)
                                .tz(userInfo.timeZone)
                                .format(
                                  userInfo.time === "h:mm"
                                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                    : `${userInfo.dateFormat} ${userInfo.time}`
                                )
                            : moment
                                .unix(organizationData.createdon)
                                .format("DD/MM/YYYY, hh:mm A")
                        }
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Updated By</label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={organizationData.updatedby}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <label htmlFor="" className={styles.fieldHeading}>
                        Updated On
                      </label>
                      <input
                        value={
                          userInfo?.timeZone
                            ? moment
                                .unix(organizationData.updatedon)
                                .tz(userInfo.timeZone)
                                .format(
                                  userInfo.time === "h:mm"
                                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                    : `${userInfo.dateFormat} ${userInfo.time}`
                                )
                            : moment
                                .unix(organizationData.updatedon)
                                .format("DD/MM/YYYY, hh:mm A")
                        }
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Email <span className={styles.red}>*</span>
                      </label>
                      <input
                        value={organizationData.email}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Phone Number <span className={styles.red}>*</span>
                      </label>
                      <input
                        value={organizationData.contactNumber}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <label className={styles.fieldHeading}>Description</label>
                      <textarea
                        name="description"
                        value={organizationData.description}
                        className={styles.textarea2}
                        disabled
                        rows="8"
                      ></textarea>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ marginTop: 15 }}>
                <Accordion className={styles.fieldPanel} defaultExpanded>
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.fieldHeading2}>
                      Contact details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} style={{ marginTop: 15 }}>
                      <Grid item xs={9}>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <label className={styles.fieldHeading}>
                              Email <span className={styles.red}>*</span>
                            </label>
                            <input
                              value={organizationData.email}
                              type="text"
                              className={styles.input2}
                              autoComplete="off"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <label className={styles.fieldHeading}>
                              Phone Number <span className={styles.red}>*</span>
                            </label>
                            <input
                              value={organizationData.contactNumber}
                              type="text"
                              className={styles.input2}
                              autoComplete="off"
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>Street</label>
                        <input
                          value={myArr[0] ? myArr[0] : ""}
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>City</label>
                        <input
                          value={myArr[1] ? myArr[1] : ""}
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>State</label>
                        <input
                          value={myArr[2] ? myArr[2] : ""}
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>Country</label>
                        <input
                          value={myArr[3] ? myArr[3] : ""}
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className={styles.wrapper} style={{ marginTop: 15 }}>
              <Accordion className={styles.fieldPanel} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownRounded className={styles.accordianIcon} />
                  }
                >
                  <Typography className={styles.fieldHeading2}>Logo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 10, marginBottom: 70 }}
                  >
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>Page Title</label>
                      <input
                        name="name"
                        type="text"
                        className={styles.input2}
                        value={organizationData.title}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                      <div>
                        <label className={styles.fieldHeading}>Logo</label>
                      </div>
                      <div
                        className={styles.iconwrapper}
                        style={{ marginTop: 20 }}
                      >
                        {organizationData.logo && (
                          <img
                            variant="square"
                            src={organizationData.logo}
                            alt=""
                            style={{ maxHeight: 100, maxWidth: 100 }}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <label className={styles.fieldHeading}>Favicon</label>
                      </div>
                      <div
                        className={styles.iconwrapper}
                        style={{ marginTop: 20 }}
                      >
                        {organizationData.favicon && (
                          <img
                            variant="square"
                            src={organizationData.favicon}
                            alt=""
                            style={{ maxHeight: 100, maxWidth: 100 }}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={styles.wrapper}>
              <Accordion className={styles.fieldPanel} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownRounded className={styles.accordianIcon} />
                  }
                >
                  <Typography className={styles.fieldHeading2}>
                    Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <Grid container spacing={3}>
                          <Grid item xs={4} style={{ paddingTop: "23px" }}>
                            <label className={styles.fieldHeading}>
                              Ontology
                            </label>
                          </Grid>
                          <div
                            className={classes.button}
                            style={{ paddingTop: "20px" }}
                          >
                            {updateFound === "updates found" ? (
                              <Tooltip title="Download Available" arrow>
                                <Button
                                  variant="contained"
                                  className="btnsmall"
                                  onClick={handleUpdate}
                                >
                                  Update
                                </Button>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Download Available" arrow>
                                <Button
                                  variant="contained"
                                  className="btnsmall"
                                  onClick={handleUpdate}
                                  disabled={true}
                                >
                                  Update
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "60px" }}>
                        <label className={styles.fieldHeading}>
                          Container inactivity (mins)
                        </label>
                        <Grid
                          xs={10}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            name="containerinactivity"
                            type="number"
                            maxLength={2}
                            className={`${styles.input2}`}
                            defaultValue={organizationData?.containerinactivity}
                            // onChange={handleChange}
                            disabled
                          />
                          {/* <p>&nbsp;&nbsp;mins</p> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={styles.wrapper}>
              <Accordion className={styles.fieldPanel} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownRounded className={styles.accordianIcon} />
                  }
                >
                  <Typography className={styles.fieldHeading2}>
                    Cloud Configurations
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <CloudConfigDevExpress
                      columns={cloudConfigColumns}
                      rows={cloudConfigRows}
                      defaultColumnWidths={cloudConfigDefaultColumnWidth}
                      tableColumnExtensions={cloudConfigTableColumnExtensions}
                      columnOrder={cloudConfigColumnOrder}
                      loading={loading}
                      currentPage={currentPageCloudConfig}
                      setCurrentPage={setCurrentPageCloudConfig}
                      isCustomHeight={true}
                      // sort={testExecutionSort}
                      // windowDimensions={windowDimensions}
                      // setSort={addExecutionSort}
                      hidden={cloudConfigColumnHidden}
                      // setHidden={addServiceHidden}
                      noDataMsg="No Cloud Configuration Found"
                      setSelectedData={(row) => setSelectedData(row)}
                      setShowEditConfig={(e) =>
                        setIsCloudConfigurationEditMode(e)
                      }
                      isEditMode={true}
                    />
                    <div
                      className={devStyles.customPagination}
                      style={{ paddingTop: "1rem" }}
                    >
                      {cloudConfigRows.length !== 0 && (
                        <Pagination
                          count={Math.ceil(_totalcount / 10)}
                          page={currentPageCloudConfig}
                          onChange={(e, val) => setCurrentPageCloudConfig(val)}
                          shape="rounded"
                          showFirstButton
                          showLastButton
                        />
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </>
        )}
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  add_current_organization_data,
  featchAllCloudConfigurationActions,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  const { data, loading, _totalcount } =
    state?.organizationReducer?.cloudConfigList;
  return {
    userInfo,
    data,
    loading,
    _totalcount,
    tabbedView,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationDetailsScreen);
