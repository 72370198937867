import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../common/styles/InputTableStyle";
import AddEnvironmentServicesModal from "../../Orchestration/Application/AddEnvironmentServicesModal";
import StorageIcon from "@material-ui/icons/Storage";
import AddVolumnDeploymentModal from "../AddVolumnDeploymentModal";
import { connect } from "react-redux";
import {
  addEnvironmentDeployment,
  restartContainerCheck,
} from "../../../redux/actions/deploymentProcessAction";
import LayersIcon from "@material-ui/icons/Layers";
import Loader from "../../../components/common/stuff/Loader";

const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function DeploymentContainerTablePreview({ container, deployment_type }) {
  const tStyles = tempStyles();
  const styles = useStyles();
  const [filterContainer, setFilterContainer] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    const filteredContainers = container?.filter((item) => item.select);
    setFilterContainer(filteredContainers);
    setTableLoading(false);
  }, []);

  const handleRestartChecked = (id, checked) => {
    restartContainerCheck({ id: id, checked: checked });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        <Paper>
          {tableLoading ? (
            <Loader />
          ) : (
            <TableContainer>
              <Table className={styles.customTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Container Name</TableCell>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Port</TableCell>
                    <TableCell align="left">Restart</TableCell>
                    <TableCell align="left">Replica</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterContainer &&
                    filterContainer.length > 0 &&
                    filterContainer.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "18rem" }}
                        >
                          {row?.container_name}
                        </TableCell>

                        <TableCell style={{ width: "18rem" }}>
                          {deployment_type === "Application"
                            ? row?.image
                            : row?.image}
                        </TableCell>

                        <TableCell style={{ width: "18rem" }}>
                          {deployment_type === "Application"
                            ? row?.port
                            : row?.port}
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={
                              row?.restart && row?.restart === "always"
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell component="th" style={{ width: "4rem" }}>
                          {row?.replicas ? row?.replicas : 1}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                {filterContainer?.length === 0 && (
                  <div className={styles.nodata}>
                    <text>No Data Available</text>
                  </div>
                )}
              </Table>
            </TableContainer>
          )}
        </Paper>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  addEnvironmentDeployment,
  restartContainerCheck,
};

const mapStateToProps = (state) => {
  const { container } = state.deploymentProcessReducer.dependenciesData;
  const { deployment_type } = state.deploymentProcessReducer.appSelectionData;

  return {
    container,
    deployment_type,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentContainerTablePreview);
