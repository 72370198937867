import React from 'react';
import ShowStatus from '../../../atoms/rightSide/appearance/showStatus/ShowStatus';

function CustomComponentAppearances() {
  return (
    <div className="field">
      <div style={{ marginTop: 5 }}>
        <ShowStatus
          label="Show Loading"
          fstBtn="Yes"
          sndBtn="No"
        />
      </div>
    </div>
  );
}

export default CustomComponentAppearances;
