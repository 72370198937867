import {
    ADD_STANDARD_DOCUMENTS_DATA,STANDARD_DOCUMENTS_FILTERS,STANDARD_DOCUMENTS_SORT,CLEAR_STANDARD_DOCUMENTS} from "../actionTypes";

import { DocumentServices, } from "../../services/documentServices";

  const documentService = new DocumentServices();

  function  add_standard_document(payload){
    return{
        type:ADD_STANDARD_DOCUMENTS_DATA,
        payload:payload,
    };
}

export function add_standard_document_action(currentPage, filters, sort){
    return function(dispatch){
        dispatch(add_standard_document({
            loading:true,
        })
        );
        documentService.getAllDocuments(currentPage, filters, sort)
        .then((res)=>{
            if(res.status === 200){
                const {data, _totalcount } = res.data;
                if(data) {
                    dispatch(
                        add_standard_document({
                            loading:false,
                            data:data,
                            error:false,
                            _totalcount:_totalcount,
                        })
                    );
                }
                else{
                    dispatch(
                        add_standard_document({
                            loading:false,
                            data:[],
                            error:false,
                            _totalcount:_totalcount,
                        })
                    )
                }
            }
        })
        .catch((err)=>{
            dispatch(
                add_standard_document({
                    loading:false,
                    data:[],
                    error:true,
                })
            );
        });
    }
};
export const add_standard_documents_filter = (standard_documents_filters) => {
    return {
      type: STANDARD_DOCUMENTS_FILTERS,
      payload: standard_documents_filters
    }
  };

  export const add_standard_documents_sort = (standard_documents_sort) => {
    return {
      type: STANDARD_DOCUMENTS_SORT,
      payload: standard_documents_sort
    }
  };

  export const clearStandardDocuments = () => {
    return {
        type: CLEAR_STANDARD_DOCUMENTS
    }
  };