import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { AppButton } from "../button/AppButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

function CustomModal({ children, isModalOpen, setIsModalOpen, title, handleSave , description}) {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return null;
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isModalOpen}
        onClose={handleClose}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 25px 0 25px' }}>
          <div>
          <h1 style={{ margin: 0, fontSize: 18 }}>{title}</h1>
          <p  style={{ margin : 0}}>{description}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <AppButton
              buttonName="Close"
              variant="outlined"
              color="primary"
              style={{ marginLeft: 15 }}
              startIcon={<CloseIcon />}
              className="btnsmall"
              onClick={handleClose}
            />
            <AppButton
              buttonName="Save"
              variant="contained"
              style={{ marginLeft: 15 }}
              startIcon={<SaveOutlinedIcon />}
              className="btnsmall"
              onClick={handleSave}
            />
          </div>
        </div>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
}
export default CustomModal;
