import { useState } from "react";
import DisabledButton from "../../../atoms/rightSide/appearance/disabled/DisabledButton";
import useGetData from "../../../../hooks/useGetData";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";

function InputAppearance() {
  const {
    currentComponent,
    currentPage,
    globalAcessModeChange,
    NormalHandleChange,
  } = useGetData();
  const [accessModeErr, setAccessModeErr] = useState("");
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: value ? value : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name="fontSize"
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Placeholder"
          name="placeHolder"
          value={currentComponent?.placeHolder}
          onChange={(value) =>
            globalAcessModeChange(value, "placeHolder", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <DisabledButton />
      </div>
      <div className="field">
        <CustomColorPicker name="status" />
      </div>
    </div>
  );
}

export default InputAppearance;
