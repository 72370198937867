import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    paddingTop: "3.7rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  wrapper: {
    marginTop: "9.375rem",
    position: "relative",
    margin: "20px 30px 0px 30px",

    "& .MuiAccordionSummary-root": {
      backgroundColor: "#fafafa",
    },
    "& .MuiCollapse-root": {
      backgroundColor: "#fafafa",
    },
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: "#fafafa",
  },
  topContentItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  topContentItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  topContentItem2: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  title: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    opacity: 1,
    margin: 0,
  },
  input: {
    width: "95%",
    height: 38,
    border: "1px solid #cfd0d1",
    borderRadius: 5,
    paddingLeft: 10,
    outline: "none",
  },
  red: {
    color: "#E22626",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  fieldHeading: {
    font: "normal normal 600 14px Nunito",
    letterSpacing: 0,
    color: "#333333DE",
    width: "100%",
    opacity: 1,
    lineHeight: 3,
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  border_line: {
    marginTop: 10,
    border: "1px solid #002855",
    opacity: 0.4,
    width: "100%",
  },
  generalInfo: {
    padding: "15px 0 0px 0",
    font: "normal normal bold 16px/22px Nunito",
  },
  textarea: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.9)",
    font: "normal normal normal 12px/16px Nunito",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "80%",
    resize: "none",
    padding: "10px 15px",
    "&:active, &:focus-visible": {
      border: "1px solid #DFDFDF",
      "&::placeholder": {
        color: "#707070 !important",
      },
    },
    "&:placeholder-shown": {
      "&.emptyField": {
        border: `1px solid #E22626`,
        "&::placeholder": {
          color: `#E22626`,
          opacity: 1,
        },
      },
    },
  },
  textAreaDisableMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box !important",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  sectionHeading: {
    font: "normal normal 600 16px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  searchwrapper: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    width: "22rem",
    "& div": {
      backgroundColor: "#fff !important",
      "& .MuiIconButton-label": {
        marginTop: 15,
      },
      "& .MuiIconButton-root": {
        backgroundColor : "transparent"  
      },
    },
  },
  buttons: {
    float: "right",
    // width: 250,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "Center",
  },
  button2: {
    float: "right",
    // width: 150,
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "right",
  },
  documentDropperWrapper: {
    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  doc_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "25vw",
  },
  value: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: 5,
    width: '100%',
    height: 30,
    marginTop: 5,
  },
}));