import React, { useState, useEffect } from "react";
import { Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../components/common/stuff/Loader";
import CreateDeploymentDependencies from "../ManageDependencies/CreateDeploymentDependencies";
import PreviewList from "../ManagePreviewScreen/PreviewList";
import { connect, useDispatch } from "react-redux";
import AppSelectionStep from "../ManageAppSelection/AppSelectionStep";
import { useSnackbar } from "notistack";
import CreateDeploymentHosting from "../ManageHosting/CreateDeploymentHosting";
import PreviewCluster from "../ManagePreview/PreviewCluster";
import {
  addAppSelectData,
  addDeployContainer,
  addDeploymentProcess,
  addDeploymentResponse,
  setDeploymentErrors,
} from "../../../redux/actions/deploymentProcessAction";
import { ADD_APP_SELECTION_DATA } from "../../../redux/actionTypes";
import {
  clearDeploymentData,
  addDeploymentName,
} from "../../../redux/actions/deploymentProcessAction";
import { CreateDeploymentService } from "./CreateDeploymentService";
import DeploymentLiveState from "../DeploymentLiveStatus/DeploymentLiveStatus";
import {
  getApplicationDataByID,
  getApplicationTemplatesByID,
  getGroupServiceBundleWithImageDetails,
  getGroupServicesWithDependenciesDetails,
  getImageList,
} from "../../../utils/orchestration/applicationUtils";
import { fetchVersionById } from "../../../utils/project/microserviceUtils";
import { getRuntimeEnvDetailsByID } from "../../../utils/containerMangement/runtimeEnvUtils";
import { imageDetails } from "../../../utils/containerMangement/imageUtils";
import { ValidateDependencies } from "./DependenciesValidation";
import {
  uiApplicationImageValidation,
  versionValidation,
} from "../ManageAppSelection/VersionValidation";
import { v4 as uuidv4 } from "uuid";
import { getDeploymentsUiApplicationsById } from "../../../UiApplication/services/UiApplicationApiUtils";

const CreateDeploymentProcess = (props) => {
  const {
    addAppSelectData,
    clustername,
    configurationname,
    clearDeploymentData,
    deploymentData,
    addDeploymentResponse,
    deploymenttype,
    deployment_app_name,
    deployment_app_version,
    addDeployContainer,
    versionDetails,
    addDeploymentProcess,
    setDeploymentErrors,
    appSelectionData,
    addDeploymentName,
  } = props;
  const styles = manageStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const histroy = useHistory();
  // const [appSelectionData, setAppSelectionData] = useState();
  const [appSelectionProcessValid, setAppSelectionProcessValid] =
    useState(false);
  const [selectedClusterValid, setSelectedClusterValid] = useState();
  const [error, setError] = useState({
    name: { isError: true },
  });
  const [clickedNext, setClickedNext] = useState(false);
  const [clusterError, setClusterError] = useState(false);
  const [cloudError, setCloudError] = useState(false);
  const [deploymentName, setDeploymentName] = useState(
    deployment_app_name?.imagename
  );
  const stepItems = [
    "Hosting",
    "App Selection",
    "Dependencies",
    "Preview",
    "Deploy",
  ];

  const search = useLocation().search;
  useEffect(() => {
    return () => {
      clearDeploymentData();
    };
  }, []);

  useEffect(() => {
    addDeploymentName({ deploymentName });
  }, [deploymentName]);
  useEffect(() => {
    localStorage.setItem(
      "persistHost",
      JSON.stringify({
        deploymenttype: 1,
        deployment_app_name: { name: "dummy" },
        deployment_app_version: "dummy",
      })
    );
  }, []);

  useEffect(() => {
    if (appSelectionData?.deploymenttype === "Application") {
      setDeploymentName(appSelectionData?.deployment_app_name?.name);
    }
  }, [appSelectionData]);
  const createDeployment = async () => {
    setIsLoader(true);
    const validData = await CreateDeploymentService(deploymentData);
    setIsLoader(false);
    if (validData._status === 200) {
      addDeploymentResponse(validData?.data);
      enqueueSnackbar(validData._msg, { variant: "success" });
      setActiveStep((prev) => prev + 1);
    } else {
      enqueueSnackbar(validData._msg, { variant: "error" });
    }
  };

  const getSuccessContainer = async () => {
    setIsLoader(true);
    if (deploymenttype === "Application") {
      const fetchImageList = async () => {
        const { _status, _msg, data } = await getImageList();
        if (_status === 200) {
          return data;
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      };

      const getApplicationData = async () => {
        const { _msg, _status, data } = await getApplicationDataByID(
          deployment_app_name?._id
        );
        if (_status === 200) {
          let imageList = await fetchImageList();
          let containers = data?.deployment?.services;

          const mainServicesList = Promise.all(
            data?.microservices.map((item) => {
              return fetchVersionById(item.mid, item.vid);
            })
          );

          const mainServiceImgId = await Promise.all([mainServicesList]);
          // const versionData = await fetchVersionById(
          //   data?.microservices[0].mid,
          //   data?.microservices[0].vid
          // );

          // const mainServiceImgId =
          //   versionData?.data?.docker?.imagedetails?.ImageID;

          containers &&
            containers?.map((item) => {
              const imageFilter = imageList?.filter(
                (i) => i._id === item.imageid
              );
              if (imageFilter?.length > 0) {
                item["image"] = imageFilter[0]?.buildoptions?.Tags[0];
              }

              item["restart"] = "always";
              item["uid"] = uuidv4();

              const checkIsDependencies = mainServiceImgId[0].map(
                (micronService) => {
                  if (
                    micronService?.data?.docker?.imagedetails?.ImageID ===
                    item.imageid
                  ) {
                    item["select"] = true;
                    item["port"] = micronService?.data?.port;
                    item["uid"] = uuidv4();
                  }
                  return micronService?.data?.docker?.imagedetails?.ImageID;
                }
              );

              const foundDependencies = checkIsDependencies.filter(
                (dep) => dep === item.imageid
              );
              if (foundDependencies?.length === 0) {
                item["dependencies"] = true;
              }
            });

          addDeployContainer(containers);

          setIsLoader(false);
        }
      };

      const getProcessData = async () => {
        const { _msg, _status, data } = await getApplicationTemplatesByID(
          deployment_app_name?._id
        );
        if (_status === 200) {
          addDeploymentProcess(data);
        }
      };

      getProcessData();
      getApplicationData();
    } else if (appSelectionData?.deploymenttype === "UI Application") {
      // const serviceBundleGroup = new Promise((resolve, reject) => {
      //   if (
      //     appSelectionData?.deployment_app_name?.backendApplication.length > 0
      //   ) {
      //     let allServiceBundleIds = [];
      //     appSelectionData?.deployment_app_name?.backendApplication.map(
      //       (item) => {
      //         allServiceBundleIds.push(item?.applicationId);
      //       }
      //     );

      //     const getServiceBundle = async () => {
      //       const { data, _status } =
      //         await getGroupServiceBundleWithImageDetails(allServiceBundleIds);
      //       if (_status === 200) {
      //         let allContainers = [];

      //         data.map((item) => {
      //           // allContainers = [...allContainers, ...item?.deployment?.services]
      //           item?.deployment?.services?.map((container) => {
      //             allContainers = [
      //               ...allContainers,
      //               {
      //                 ...container,
      //                 id: item._id,
      //                 runtimeenvid: item.runtime_env,
      //               },
      //             ];
      //           });
      //         });

      //         allContainers.map((item) => {
      //           item["type"] = "service_bundle";
      //           item["uid"] = uuidv4();
      //         });

      //         resolve(allContainers);
      //       }
      //     };
      //     getServiceBundle();
      //   } else {
      //     resolve([]);
      //   }
      // });

      // const serviceGroup = new Promise((resolve, reject) => {
      //   if (
      //     appSelectionData?.deployment_app_name?.services &&
      //     appSelectionData?.deployment_app_name?.services.length > 0
      //   ) {
      //     let servicesId = [];
      //     appSelectionData?.deployment_app_name?.services.map((item) => {
      //       servicesId.push({ vid: item.versionID, mid: item.serviceID });
      //     });
      //     const getServicesGroupDetails = async () => {
      //       const { data, _status } =
      //         await getGroupServicesWithDependenciesDetails(servicesId);
      //       if (_status === 200) {
      //         let allContainers = [];

      //         data.map((item) => {
      //           // allContainers = [...allContainers, ...item?.rtedetails?.services]
      //           if (item?.docker?.Instances) {
      //             let tempImage = {};
      //             tempImage["image"] = item?.docker?.imagedetails?.image;
      //             tempImage["imageid"] = item?.docker?.imagedetails?.ImageID;
      //             tempImage["port"] = item?.port;
      //             tempImage["environment"] = item?.environmentVariables;
      //             tempImage["container_name"] =
      //               item?.docker?.Instances[0]?.containername;
      //             tempImage["select"] = true;
      //             tempImage["restart"] = "always";
      //             allContainers = [
      //               ...allContainers,
      //               { id: item._id, ...tempImage },
      //             ];
      //           }
      //           item?.rtedetails?.services?.map((container) => {
      //             container["dependency"] = true;
      //             allContainers = [
      //               ...allContainers,
      //               { ...container, id: item._id },
      //             ];
      //           });
      //         });

      //         allContainers.map((item) => {
      //           item["type"] = "service";
      //           item["uid"] = uuidv4();
      //         });

      //         resolve(allContainers);
      //       }
      //     };

      //     getServicesGroupDetails();
      //   } else {
      //     resolve([]);
      //   }
      // });

      // Promise.allSettled([serviceBundleGroup, serviceGroup]).then((values) => {
      //   let allContainers = [];
      //   if (appSelectionData?.deployment_app_name?.imageid) {
      //     const uiApp = appSelectionData?.deployment_app_name;
      //     allContainers = [
      //       ...allContainers,
      //       {
      //         imageid: uiApp.imageid,
      //         image: uiApp.imagename,
      //         container_name: uiApp.name,
      //         select: true,
      //         port: "80",
      //         restart: "always",
      //         type: "ui",
      //         imagetag: uiApp.imagetag,
      //         uid: uuidv4(),
      //       },
      //     ];
      //   }
      //   values.map((item) => {
      //     item?.value?.map((container) => {
      //       if (container?.dependency) {
      //         container["dependencies"] = true;
      //         container["restart"] = "always";
      //         return item;
      //       } else {
      //         container["restart"] = "always";
      //         container["select"] = true;
      //         return item;
      //       }
      //     });
      //     allContainers = [...allContainers, ...item.value];
      //   });
      //   addDeployContainer(allContainers);
      //   setIsLoader(false);
      // });
      let allContainers = [];
      const fetchUiDeploymentsById = async () => {
        setIsLoader(true);
        const { data, _status, _msg } = await getDeploymentsUiApplicationsById(
          appSelectionData?.deployment_app_name?._id
        );
        if (_status === 200) {
          setIsLoader(false);
          // props.addUIAppDeploymentData(data)
          const {
            services,
            servicebundles,
            parentUIAPPData,
            linkeduiapplications,
          } = data;

          allContainers = [
            ...allContainers,
            {
              imageid: parentUIAPPData.imageid,
              image: `${parentUIAPPData.imagename}:${parentUIAPPData.imagetag}`,
              container_name: parentUIAPPData.name,
              appid: parentUIAPPData.appid,
              select: true,
              port: "80",
              restart: "always",
              type: "main_ui",
              imagetag: parentUIAPPData.imagetag,
              uid: uuidv4(),
            },
          ];

          if (linkeduiapplications?.length > 0) {
            linkeduiapplications.map((item) => {
              allContainers = [
                ...allContainers,
                {
                  imageid: item.imageid,
                  image: `${item.imagename}:${item.imagetag}`,
                  container_name: item.name,
                  select: true,
                  port: "80",
                  restart: "always",
                  type: "ui",
                  imagetag: item.imagetag,
                  uid: uuidv4(),
                  appid: item.appid
                },
              ];
            });
          }

          if (services?.length > 0) {
            services.map((item) => {
              if (item?.docker?.Instances) {
                let tempImage = {};
                tempImage["image"] = item?.docker?.imagedetails?.image;
                tempImage["imageid"] = item?.docker?.imagedetails?.ImageID;
                tempImage["port"] = item?.port;
                tempImage["environment"] = item?.environmentVariables;
                tempImage["container_name"] =
                  item?.docker?.Instances[0]?.containername;
                tempImage["select"] = true;
                tempImage["restart"] = "always";
                tempImage["type"] = "service";
                allContainers = [
                  ...allContainers,
                  { id: item._id, ...tempImage, uid: uuidv4() },
                ];
              }

              item?.rtedetails?.services?.map((container) => {
                container["dependencies"] = true;
                allContainers = [
                  ...allContainers,
                  { ...container, id: item._id, uid: uuidv4(), type: "service", restart: 'always' },
                ];
              });
            });
          }

          if (servicebundles?.length > 0) {
            servicebundles.map((item) => {
              item?.deployment?.services?.map((container) => {
                allContainers = [
                  ...allContainers,
                  {
                    ...container,
                    id: item._id,
                    runtimeenvid: item.runtime_env,
                    dependencies: container?.dependency,
                    uid: uuidv4(),
                    type: "service_bundle",
                    restart: 'always'
                  },
                ];
              });
            });
          }

          addDeployContainer(allContainers);
        } else {
          setIsLoader(false);
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      };

      fetchUiDeploymentsById();
    } else {
      const getContainer = async () => {
        const serviceId = deployment_app_name?._id;
        const versionId = versionDetails._id;
        const { _msg, _status, data } = await fetchVersionById(
          serviceId,
          versionId
        );
        if (_status === 200) {
          let instanceData = data?.docker.Instances
            ? data?.docker.Instances
            : [];
          const envID = data?.docker?.environmentID;

          if (instanceData && instanceData.length > 0) {
            instanceData?.map((item) => {
              item["image"] = data?.docker?.imagedetails?.image;
              item["imageid"] = data?.docker?.imagedetails?.ImageID;
              item["port"] = data?.port;
              item["environment"] = data?.environmentVariables;
              item["container_name"] = item?.containername;
              item["select"] = true;
              item["restart"] = "always";
              item["uid"] = uuidv4();
              delete item?.containername;
            });
          } else {
            let imageDetails = {};
            imageDetails["image"] = data?.docker?.imagedetails?.image;
            imageDetails["imageid"] = data?.docker?.imagedetails?.ImageID;
            imageDetails["port"] = data?.port;
            imageDetails["environment"] = data?.environmentVariables;
            imageDetails["container_name"] = data?.microservice_name;
            imageDetails["select"] = true;
            imageDetails["restart"] = "always";
            imageDetails["uid"] = uuidv4();
            instanceData.push(imageDetails);
          }

          if (envID) {
            const envData = await getRuntimeEnvDetailsByID(envID);
            let dependenciesData = envData?.data?.services;
            dependenciesData?.map((item) => {
              item["dependencies"] = true;
              item["restart"] = "always";
              item["uid"] = uuidv4();
            });

            if (instanceData) {
              if (envData?.data?.services) {
                instanceData = [...instanceData, ...envData?.data?.services];
              } else {
                instanceData = [...instanceData];
              }
            } else {
              instanceData = [...envData?.data?.services];
            }
          }

          addDeployContainer(instanceData);
          setIsLoader(false);
        }
        setIsLoader(false);
      };

      getContainer();
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setClickedNext(true);

      if (configurationname === undefined) {
        setCloudError(true);
      } else {
        setCloudError(false);
        if (clustername === undefined || clustername === "") {
          setClusterError(true);
        } else {
          setClusterError(false);
        }
      }

      if (selectedClusterValid) {
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 1) {
      setClickedNext(true);

      if (appSelectionData.deploymenttype === "Application") {
        if (appSelectionProcessValid) {
          addAppSelectData(appSelectionData);
          const localData = JSON.parse(localStorage.getItem("persistHost"));
          if (deploymenttype === "Application") {
            if (
              deploymenttype != localData.deploymenttype ||
              deployment_app_name?.name != localData?.deployment_app_name?.name
            ) {
              getSuccessContainer();
              localStorage.setItem(
                "persistHost",
                JSON.stringify(appSelectionData)
              );
            }
          } else {
            if (
              deploymenttype != localData.deploymenttype ||
              deployment_app_name?.name !=
              localData?.deployment_app_name?.name ||
              localData.deployment_app_version != deployment_app_version
            ) {
              getSuccessContainer();
              localStorage.setItem(
                "persistHost",
                JSON.stringify(appSelectionData)
              );
            }
          }

          setActiveStep((prev) => prev + 1);
          // setAppSelectionProcessValid(false);
          setClickedNext(false);
        }
      } else if (deploymenttype === "UI Application") {
        const isUiImageValid = await uiApplicationImageValidation(
          appSelectionData?.deployment_app_name
        );
        if (isUiImageValid) {
          const localData = JSON.parse(localStorage.getItem("persistHost"));

          if (
            deploymenttype != localData.deploymenttype ||
            deployment_app_name?.name != localData?.deployment_app_name?.name
          ) {
            let tempData = {
              ...appSelectionData,
              deployment_app_name: {
                id: appSelectionData?.deployment_app_name?.id,
                imageid: appSelectionData?.deployment_app_name?.id,
                imagename: appSelectionData?.deployment_app_name?.imagename,
                imagetag: appSelectionData?.deployment_app_name?.imagetag,
                _id: appSelectionData?.deployment_app_name?._id,
              },
            };
            getSuccessContainer();
            localStorage.setItem("persistHost", JSON.stringify(tempData));
          }

          setActiveStep((prev) => prev + 1);
          setClickedNext(false);
        } else {
          enqueueSnackbar(
            "Build doesn’t exist for the selected Ui Applicaiton. Please select a built Ui Applicaiton to proceed",
            {
              variant: "error",
            }
          );
        }
      } else {
        const { versionIsValid } = await versionValidation(
          versionDetails,
          appSelectionData
        );

        if (appSelectionData.deployment_app_version && !versionIsValid) {
          enqueueSnackbar(
            "Build doesn’t exist for the selected version. Please select a built version to proceed",
            {
              variant: "error",
            }
          );
        }

        if (appSelectionProcessValid && versionIsValid) {
          addAppSelectData(appSelectionData);
          const localData = JSON.parse(localStorage.getItem("persistHost"));
          if (deploymenttype === "Application") {
            if (
              deploymenttype != localData.deploymenttype ||
              deployment_app_name?.name != localData?.deployment_app_name?.name
            ) {
              getSuccessContainer();
              localStorage.setItem(
                "persistHost",
                JSON.stringify(appSelectionData)
              );
            }
          } else {
            if (
              deploymenttype != localData.deploymenttype ||
              deployment_app_name?.name !=
              localData?.deployment_app_name?.name ||
              localData.deployment_app_version != deployment_app_version
            ) {
              getSuccessContainer();
              localStorage.setItem(
                "persistHost",
                JSON.stringify(appSelectionData)
              );
            }
          }

          setActiveStep((prev) => prev + 1);
          // setAppSelectionProcessValid(false);
          setClickedNext(false);
        }
      }
    } else if (activeStep === 2) {
      if (deploymentData?.dependenciesData?.container) {
        const filterSelectedContainer =
          deploymentData?.dependenciesData?.container.filter(
            (item) => item.select
          );

        if (filterSelectedContainer?.length <= 0) {
          enqueueSnackbar("Please select at least one container to continue", {
            variant: "error",
          });
        } else {
          const { isValid, withErrorsContainer } = ValidateDependencies(
            deploymentData?.dependenciesData?.container
          );
          if (!isValid) {
            setDeploymentErrors(withErrorsContainer);
          } else {
            if (deploymenttype === "Application") {
              if (deploymentData?.dependenciesData?.process) {
                if (deploymentData?.dependenciesData?.process.length > 0) {
                  setActiveStep((prev) => prev + 1);
                } else {
                  enqueueSnackbar("No processes to deploy", {
                    variant: "error",
                  });
                }
              } else {
                enqueueSnackbar("No processes to deploy", {
                  variant: "error",
                });
              }
            } else {
              setActiveStep((prev) => prev + 1);
            }
          }
        }
      } else {
        enqueueSnackbar("Please select at least one container to continue", {
          variant: "error",
        });
      }
    } else if (activeStep === 3) {
      //create deployment

      createDeployment();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CreateDeploymentHosting
            clickedNext={clickedNext}
            setClickedNext={setClickedNext}
            selectedClusterValid={selectedClusterValid}
            setClusterError={setClusterError}
            clusterError={clusterError}
            setCloudError={setCloudError}
            cloudError={cloudError}
            setSelectedClusterValid={setSelectedClusterValid}
          />
        );
      case 1:
        return (
          <AppSelectionStep
            appSelectionData={appSelectionData}
            setAppSelectionProcessValid={setAppSelectionProcessValid}
            appSelectionProcessValid={appSelectionProcessValid}
            error={error}
            setError={setError}
            clickedNext={clickedNext}
            setClickedNext={setClickedNext}
          />
        );
      case 2:
        return <CreateDeploymentDependencies />;
      case 3:
        return (
          <PreviewCluster
            deploymentName={deploymentName}
            setDeploymentName={setDeploymentName}
            error={error}
            setError={setError}
          />
        );

      case 4:
        return <DeploymentLiveState />;
      default:
        return null;
    }
  }

  const handleFinish = () => {
    histroy.push(`/host`);
    clearDeploymentData();
  };

  const handleClose = () => {
    histroy.push(`/host`);
    histroy.push();
    clearDeploymentData();
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <Stepper
          activeStep={activeStep}
          style={{ backgroundColor: "transparent" }}
        >
          {stepItems.map((item) => (
            <Step>
              <StepLabel>{item}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{stepItems[activeStep]}</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {activeStep < 4 ? (
              <>
                {activeStep > 0 && (
                  <AppButton
                    color="primary"
                    buttonName="Previous"
                    variant="contained"
                    disabled={false}
                    className="btnsmall"
                    onClick={handlePrev}
                  />
                )}
                <AppButton
                  color="primary"
                  buttonName={activeStep === 3 ? "Deploy" : "Next"}
                  variant="contained"
                  disabled={false}
                  className="btnsmall"
                  onClick={handleNext}
                />

                <AppButton
                  color="primary"
                  buttonName={activeStep === 4 ? "Finish" : "Close"}
                  variant="outlined"
                  disabled={false}
                  className="btnsmall"
                  onClick={handleClose}
                />
              </>
            ) : (
              <AppButton
                color="primary"
                buttonName="Finish"
                variant="contained"
                disabled={false}
                className="btnsmall"
                onClick={handleFinish}
              />
            )}
          </div>
        </Grid>
      </div>

      <div className={styles.wrapper} style={{ paddingTop: "2.5rem" }}>
        {getStepContent(activeStep)}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addAppSelectData,
  clearDeploymentData,
  addDeploymentResponse,
  addDeployContainer,
  addDeploymentProcess,
  setDeploymentErrors,
  addDeploymentName,
};

const mapStateToProps = (state) => {
  const { serviceList } = state.serviceReducer;
  const { appSelectionData } = state.deploymentProcessReducer;
  const deploymentData = state.deploymentProcessReducer;
  const { GUIdata } = state.serviceGUIReducer;
  const { clustername, configurationname } =
    state.deploymentProcessReducer.hostingData;
  const {
    deploymenttype,
    deployment_app_name,
    deployment_app_version,
    versionDetails,
  } = state.deploymentProcessReducer.appSelectionData;
  return {
    serviceList,
    GUIdata,
    appSelectionData,
    clustername,
    configurationname,
    deploymentData,
    deploymenttype,
    deployment_app_name,
    deployment_app_version,
    versionDetails,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDeploymentProcess);
