import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  FilledInput,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Pagination from "@material-ui/lab/Pagination";
import BreadCrumbs from "../../../../components/common/Breadcrumbs";
import { useHistory } from "react-router";
import { AppButton } from "../../../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import CustomFilter from "../../../../components/common/CustomFilter";
import OntologyTable from "../../../../components/common/Tables/OntologyTable";
import Loader from "../../../../components/common/stuff/Loader";
// import Ontology from "../../../../components/common/Tables/ApplicationListTable";
import {
  applicationColumns,
  applicationDefaultColumnWidths,
  applicationTableColumnExtensions,
  applicationColumnOrder,
  applicationColumnHidden,
  filterapplicationColumns,
} from "../../../../constants/Application/ApplicationListConstants";
import { fetchStatusResourceByName } from "../../../../utils/status/statusUtils";
import listStyles from "../../../CommonStyles/listScreenStyles";
import {
  arrayToFilter,
  arrayToSort,
  getWindowDimensions,
  getTableData,
} from "../../../../utils/common";
import { connect } from "react-redux";
import {
  add_application_data,
  clearAllApplicationData,
  addApplicationFilters,
  addApplicationSort,
  addApplicationHidden,
} from "../../../../redux/actions/applicationAction";
import { fetchResourceByName } from "../../../../utils/entitySchema/entitySchemaUtils";
// import NotFountContent from "../../../Errors/NotFoundContent";
import useCheckPermission from "../../../../components/common//hooks/useCheckPermission";
import SearchBar from "../../../../components/common/SearchBar";
import { alterActiveTab } from "../../../../redux/actions/activeTabActions";

// function createData(app_data) {
//   const { _id, description, name, state, createdby, createdon, isFavourite, deployment, isSubscribe,updatedby,updatedon } = app_data;
//   var deploymentStatus = "Not Added";
//   if (deployment) {
//     if (Object.keys(deployment).length > 0) {
//       deploymentStatus = "Added"
//     }
//   }
//   return {
//     _id,
//     name,
//     state,
//     createdby,
//     createdon,
//     updatedon,
//     updatedby,
//     description,
//     isFavourite,
//     isSubscribe,
//     deployment: deploymentStatus,

//   };
// }

const ApplicationListScreen = (props) => {
  const {
    data,
    loading,
    _totalcount,
    applicationSort,
    applicationFilters,
    add_application_data,
    addApplicationFilters,
    addApplicationSort,
    addApplicationHidden,
    applicationHidden,
    clearAllApplicationData,
    alterActiveTab,
    deleteApplicationStatus,
  } = props;
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: applicationColumns,
    widths: applicationDefaultColumnWidths,
    extentions: applicationTableColumnExtensions,
    orders: applicationColumnOrder,
    hides: [],
  });
  const [statuses, setStatuses] = useState([]);
  const [currentFavServiceBundlesPage, setCurrentFavServiceBundlesPage] = useState(1);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  const histroy = useHistory();
  const classes = listStyles();
  const { enqueueSnackbar } = useSnackbar();

  // const fetchStatusesByResource = async () => {
  //   const { _msg, _status, data } = await fetchStatusResourceByName(
  //     "ServiceBundle"
  //   );
  //   if (_status === 200) {
  //     setStatuses(data ? data.status : []);
  //   } else {
  //     enqueueSnackbar(_msg ? _msg : "Something went wrong", {
  //       variant: "error",
  //     });
  //   }
  // };

  function createData(app_data) {
    const {
      _id,
      description,
      name,
      state,
      createdby,
      createdon,
      isFavourite,
      deployment,
      isSubscribe,
      updatedby,
      updatedon,
    } = app_data;
    var deploymentStatus = "Not Added";
    if (deployment) {
      if (Object.keys(deployment).length > 0) {
        deploymentStatus = "Added";
      }
    }
    return {
      _id,
      name,
      state,
      createdby,
      createdon,
      updatedon,
      updatedby,
      description,
      isFavourite,
      isSubscribe,
      deployment: deploymentStatus,
      statuses: statuses,
    };
  }

  useEffect(() => {
    alterActiveTab("Service Bundle", "Service Bundle");
  }, []);

  useEffect(() => {
    checkScreenPermission("ServiceBundle", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "ServiceBundle"
      );
      if (_status === 200) {
        const projData = getTableData(
          data,
          applicationColumns,
          applicationDefaultColumnWidths,
          applicationTableColumnExtensions,
          applicationColumnOrder,
          applicationHidden,
          "application_list"
        );
        // console.log("In application list ", projData)
        setTableData(projData);
        if (
          JSON.stringify(applicationHidden) ===
          JSON.stringify(applicationColumnHidden)
        ) {
          addApplicationHidden(projData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(applicationFilters);
    const parsedSort = arrayToSort(applicationSort);
    // if (checkCompPermission("StatusProfile", ["List All"])) {
    //   await fetchStatusesByResource();
    // }
    add_application_data(
      currentPage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPage, applicationSort]);

  useEffect(async () => {
    if(deleteApplicationStatus === true){
      const parsedFilters = arrayToFilter(applicationFilters);
      const parsedSort = arrayToSort(applicationSort);
      // if (checkCompPermission("StatusProfile", ["List All"])) {
      //   await fetchStatusesByResource();
      // }
      add_application_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteApplicationStatus]);

  useEffect(() => {
    if (!isFavouriteClick) {
      setRows(
        data && data.length > 0
          ? data.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setRows(
        data && data.length > 0
          ? data
            .filter((r) => r.isFavourite === true)
            .map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [data]);
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleSearch = (e) => {
    if (searchInput) {
      add_application_data(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_application_data(1);
    }
  };

  const handleSubmitFilter = () => {
    // props.addFieldFilters(filters);
    const parsedFilters = arrayToFilter(applicationFilters);
    const parsedSort = arrayToSort(applicationSort);
    add_application_data(null, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_application_data(1, JSON.stringify({}));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleToggleFavouriteApplication = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(applicationFilters);
      const parsedSort = arrayToSort(applicationSort);
      add_application_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
      setRows(
        data && data.length > 0
          ? data.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPage(1)
    } else {
      add_application_data();
      //setRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavServiceBundlesPage(1)
    }
  };

  useEffect(() => {
    return () => {
      clearAllApplicationData();
    };
  }, []);

  return (
    <main className={classes.main}>
      <div className={classes.topContent}>
        <BreadCrumbs
          items={[
            { title: "Service Bundles", path: "/service-bundle" },
            { title: "Service Bundle List " },
          ]}
        />
        {/* Header */}
        <div className={classes.topContentItems}>
          <div className={classes.topContentItem}>
            <p className={classes.title}>{`Service Bundles ${_totalcount ? "".concat("(", _totalcount, ")") : ""
              }`}</p>
          </div>
          <div className={classes.topContentItem2}>
            <SearchBar
              PlaceHolderLabel={"Search Bundle Name"}
              handleSearch={handleSearch}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
            <Divider orientation="vertical" className={classes.MuiDivider} />
            <CustomFilter
              columns={applicationColumns}
              filters={applicationFilters}
              setFilters={addApplicationFilters}
              handleSubmitFilter={handleSubmitFilter}
              handleClearFilters={handleClearFilters}
            />
            <Divider orientation="vertical" className={classes.MuiDivider} />
            <Tooltip title="Favourite" arrow>
              <IconButton
                color="inherit"
                aria-label="open cardview"
                edge="start"
                style={{ margin: "0px 10px" }}
                onClick={() => handleToggleFavouriteApplication()}
              >
                {isFavouriteClick ? (
                  <img src="/images/heartFilled.svg" alt="card" />
                ) : (
                  <img src="/images/heartLine.svg" alt="card" />
                )}
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" className={classes.MuiDivider} />
            <AppButton
              buttonName="create"
              variant="contained"
              startIcon={<AddIcon style={{ width: "16px", height: "16px" }} />}
              disabled={!checkCompPermission("ServiceBundle", ["Create"])}
              className="btnsmall"
              onClick={() => histroy.push("/create-application")}
            />
          </div>
        </div>
      </div>
      <Paper className={classes.pageContent}>
        {tableLoading ? (
          <Loader />
        ) : (
          <OntologyTable
            width={windowDimensions.width}
            columns={tableData.columns}
            rows={isFavouriteClick ? (
              rows?.length > 0
                ? rows?.slice(
                  (currentFavServiceBundlesPage - 1) * 10,
                  currentFavServiceBundlesPage * 10
                )
                : []
            ) : rows}
            defaultColumnWidths={tableData.widths}
            tableColumnExtensions={tableData.extentions}
            columnOrder={tableData.orders}
            defaultHiddenColumnNames={tableData.hides}
            loading={loading}
            currentPage={isFavouriteClick ? currentFavServiceBundlesPage : currentPage}
            setCurrentPage={setCurrentPage}
            isCustomHeight={true}
            sort={applicationSort}
            setSort={addApplicationSort}
            noDataMsg="No Service Bundle Found"
            hidden={applicationHidden}
            setHidden={addApplicationHidden}
          />
        )}
      </Paper>
      <div className={classes.footer}>
        <div className={classes.customPagination}>
          {isFavouriteClick ?
            <Pagination
              count={Math.ceil(rows?.length / 10)}
              page={currentFavServiceBundlesPage}
              onChange={(e, val) => setCurrentFavServiceBundlesPage(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
            :
            <Pagination
              count={Math.ceil(_totalcount / 10)}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          }
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_application_data,
  addApplicationFilters,
  addApplicationSort,
  addApplicationHidden,
  clearAllApplicationData,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    application_list,
    applicationFilters,
    applicationSort,
    applicationHidden,
  } = state.applicationReducer;
  const { data, error, loading, _totalcount, deleteApplicationStatus} = application_list;
  return {
    data,
    error,
    loading,
    _totalcount,
    applicationSort,
    applicationFilters,
    applicationHidden,
    deleteApplicationStatus
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationListScreen);
