import React from "react";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import DatepickerStyles from "./DatepickerStyles";
import CreateCustomerStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";

function DatePicker({
  selectedDate,
  handleChangeDate,
  selectedComponent,
  name,
  label,
  error,
  isRequired,
  inSettings,
  ...otherProps
}) {
  const styles = DatepickerStyles();
  const classes = CreateCustomerStyles();
  // const [selectedDate, setSelectedDate] = React.useState(new Date());
  // const handleChangeDate = (event) => {
  //   setSelectedDate(event.target.value);
  // };

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DesktopDatePicker
        calendars={1}
        format="dd/MM/yyyy"
        value={selectedDate ? selectedDate : ""}
        onChange={(date) =>
          handleChangeDate({ target: { value: date, name: name } })
        }
        disabled={
          selectedComponent?.disabled?.dataValue === "true" ? true : false
        }
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <input
                ref={inputRef}
                {...inputProps}
                className={`${styles.input} ${error && classes.error}`}
                name={name}
                style={{
                  border: `1px solid ${selectedComponent?.status}`,
                  borderRadius: 5,
                  fontSize:
                    selectedComponent?.fontSize && !inSettings
                      ? selectedComponent?.fontSize
                      : "",
                  marginTop: 0,
                  borderColor: inSettings
                    ? "#D8D8D8"
                    : selectedComponent?.status,
                }}
                {...otherProps}
              />
              {InputProps?.endAdornment}
            </div>
          </>
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
