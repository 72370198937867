import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";

import { AppButton } from "../Button";

const useStyles = makeStyles(() => ({
  modal: {
    height: 176,
    width: 480,
    padding: "0",
    color: "#000000",
    textAlign: "left",
  },
  wrapper: {
    borderBottom: "2px solid #B6B6B622",
    "& > h2": {
      margin: 0,
      padding: "10px 0 20px 30px",
      font: "normal normal bold 18px / 24px Nunito",
    },
    "& + p": {
      padding: "5px 0 0 30px",
      font: "normal normal normal 14px/19px Nunito",
    },
  },
}));

const ConfirmationModal = (props) => {
  const { open, handleClose, handleSubmit } = props;
  const styles = useStyles();

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent className={styles.modal}>
          <div className={styles.wrapper}>
            <h2>Are you sure you want to cancel?</h2>
          </div>
          <p>All progress done will be lost.</p>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#0000000B", padding: "15px 0" }}>
          <AppButton onClick={handleClose} className="btnsmall" buttonName="No" variant="outlined" />
          <AppButton onClick={handleSubmit} className="btnsmall" buttonName="Yes" style={{ margin: "0 20px 0 10px" }} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationModal