import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { ArrowDropDownRounded } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { handleFormValidationPro, initError } from '../../../utils/common'
import EditLanguageEntity from '../../../components/common/entitySchema/applicationGovernance/language/EditLanguageEntity'
import Loader from '../../../components/common/stuff/Loader'
import { fetchResourceByPermission } from '../../../utils/entitySchema/entitySchemaUtils'
import { fetchAllImages } from '../../../utils/containerMangement/containerUtils'
import {
  fetchLanguageDetailsByID,
  EditLanguageByID,
} from '../../../utils/applicationGovernance/launguageUtils'
import useCheckPermission from '../../../components/common/hooks/useCheckPermission'
import { TabsCommon } from '../../../components/Tabbed/TabsCommon'
import TabbedNavigation from '../../../components/Tabbed/TabbedNavigation'
import SectionTabs from '../../../components/Tabbed/SectionTabs'
import AccordionNavigation from '../../../components/common/Accordion/AccordionNavigation'

const EditLaunguageScreen = (props) => {
  const styles = manageStyles()
  const histroy = useHistory()
  const [languageEntitySchema, setlanguageEntitySchema] = useState()
  const [isLoader, setIsLoader] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [imageList, setImageList] = useState([])
  const [LaungID] = useState(props.match.params._id)
  const [icon, setIcon] = useState('')
  const [iconname, setIconName] = useState('')
  const [uploadModal, setUploadModal] = useState(true)
  const [languageData, setlanguageData] = useState({
    buildcommands: [''],
    runcommands: '',
  })
  const [error, setError] = useState({})

  // Accessing TabbedView
  const { tabbedView } = props
  const [activeTab, setActiveTab] = useState(0)
  const handleActiveTab = (e, newValue) => setActiveTab(newValue)
  const handleCancel = () => histroy.goBack()
  const customdropzoneImage = () => <img src="/images/dropzone.svg" alt="" />
  const { checkScreenPermission } = useCheckPermission()
  useEffect(() => checkScreenPermission('Language', ['Edit']), [])
  useEffect(() => {
    console.log('languageData :>> ', languageData)
    setIcon(languageData?.icon)
  }, [languageData])

  useEffect(() => {
    setIsLoader(true)
    const fetchlanguageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission('Language', 'edit')
      if (_status === 200) {
        setlanguageEntitySchema(data)
        fetchLanguageData()
        const langData = {}
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === 'Currency') {
                langData[`${field.datakey}_unit`] = ''
                langData[`${field.datakey}`] = ''
                return true
              }
              if (field.uiElementType === 'Amount') {
                langData[`${field.datakey}_unit`] = ''
                langData[`${field.datakey}`] = ''
                return true
              }
              if (field.datakey) {
                return (langData[field.datakey] = '')
              }
            })
          })
        })
        setlanguageData({ ...languageData, ...langData })
        const errData = {}
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === 'TRUE') {
                if (field.uiElementType === 'Currency') {
                  errData[`${field.datakey}_unit`] = initError(field, index)
                  errData[`${field.datakey}_value`] = initError(field, index)
                  return true
                }
                if (field.uiElementType === 'Amount') {
                  errData[`${field.datakey}_unit`] = initError(field, index)
                  errData[`${field.datakey}_value`] = initError(field, index)
                  return true
                }
                return (errData[field.datakey] = initError(field, index))
              }
            })
          })
        })
        delete errData['dockerCommands']

        setError(errData)
        setIsLoader(false)
      } else {
        setIsLoader(false)

        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }

    const fetchLanguageData = async () => {
      setIsLoader(true)
      const { _msg, _status, data } = await fetchLanguageDetailsByID(LaungID)
      if (_status === 200) {
        setlanguageData((prevData) => ({ ...prevData, ...data }))
        if (data?.icon?.length === 0) {
          setUploadModal(false)
        }
        setIsLoader(false)
      } else {
        setIsLoader(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }

    fetchlanguageEntitySchema()
  }, [])

  const handleChange = (event) => {
    const value = event.target.value
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: '',
        },
      })
    }
    setlanguageData({
      ...languageData,
      [event.target.name]: value,
    })
  }

  var handleUpload = (files) => {
    if (files && files.length > 0) {
      var file = files[0]
      setIconName(files[0].name)
      const reader = new FileReader()
      reader.onload = (event) => {
        setIcon(event.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDeleteClick = () => {
    setIcon('')
    setIconName('')
  }

  const deleteBuildCommand = (index) => {
    languageData.buildcommands.splice(index, 1)
    setlanguageData({
      ...languageData,
      buildcommands: languageData.buildcommands,
    })
  }

  {
  }
  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(languageData, error)
    if (formIsValid) {
      setIsLoader(true)

      delete languageData['createdby']
      delete languageData['createdon']
      delete languageData['updatedby']
      delete languageData['updatedon']
      delete languageData['_id']
      delete languageData['status']
      delete languageData['dockerdetails.dockerdetails']
      var langData = {
        ...languageData,
        dockerdetails: {
          imageid: languageData.dockerdetails._id,
        },
        icon: icon ? icon : uploadModal ? languageData.icon : '',
      }

      if (langData.buildcommands[0] === '') {
        // delete langData.buildcommands;
        langData.buildcommands[0] = ' '
      } else if (langData.buildcommands[0] !== ' ') {
        langData.buildcommands[0] = langData.buildcommands[0].trim()
      }
      if (langData.runcommands === '') {
        // delete langData.runcommands;
        langData.runcommands = ' '
      } else if (langData.runcommands !== ' ') {
        langData.runcommands = langData.runcommands.trim()
      }

      setIsLoader(true)
      const { _msg, _status, data } = await EditLanguageByID(LaungID, langData)
      if (_status < 210) {
        enqueueSnackbar(_msg ? _msg : 'Something went wrong', {
          variant: 'success',
        })

        setIsLoader(false)

        histroy.push(`/language-details/${LaungID}`)
      } else {
        setIsLoader(false)
        if (_msg === 'Invalid Json') {
          data.map((item) =>
            enqueueSnackbar(item ? item : 'Something went wrong', {
              variant: 'error',
            }),
          )
        } else {
          enqueueSnackbar(
            _msg ? _msg : 'Unable to process your request, please try after sometime',
            { variant: 'error' },
          )
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        languageEntitySchema ? languageEntitySchema.sections : [],
        errors,
        tabbedView,
      )
      setActiveTab(errorInTab)
      setlanguageEntitySchema({
        ...languageEntitySchema,
        sections: tempEntity,
      })
      setError(errors)
      enqueueSnackbar('Please check the mandatory input fields', {
        variant: 'error',
      })
    }
  }

  const rejectedfile = () => {
    enqueueSnackbar('Maximum file size allowed 30kb', {
      variant: 'error',
    })
  }

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: 'Application Governance',
                path: '/application-governance',
              },
              {
                title: 'Language',
                path: '/application-governance',
              },
              {
                title: languageData?.name ? `Edit ${languageData?.name}` : 'Edit',
              },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>
              {languageData.name ? `Edit ${languageData.name}` : 'Edit'}
            </h2>
            {tabbedView ? (
              <TabbedNavigation
                schemaLength={languageEntitySchema?.sections?.length}
                isEditMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                isEditMode={false}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={languageEntitySchema ? languageEntitySchema.sections : []}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: '10px' }}>
                <div style={{ padding: '0px 0px 0' }}>
                  <EditLanguageEntity
                    section={languageEntitySchema?.sections?.[activeTab]}
                    languageData={languageData}
                    error={error}
                    setlanguageData={setlanguageData}
                    handleChange={handleChange}
                    icon={icon}
                    iconname={iconname}
                    handleDeleteClick={handleDeleteClick}
                    customdropzoneImage={customdropzoneImage}
                    handleUpload={handleUpload}
                    imageList={imageList}
                    uploadModal={uploadModal}
                    setUploadModal={setUploadModal}
                    rejectedfile={rejectedfile}
                    deleteBuildCommand={deleteBuildCommand}
                  />
                </div>
              </Paper>
            </>
          ) : (
            languageEntitySchema?.sections &&
            languageEntitySchema?.sections.map((section) => {
              if (section.name === 'Language_Header_section') {
                return (
                  <div key={section._id} style={{ padding: '0px 5px 0' }}>
                    <EditLanguageEntity
                      section={section}
                      languageData={languageData}
                      error={error}
                      setlanguageData={setlanguageData}
                      handleChange={handleChange}
                      icon={icon}
                      iconname={iconname}
                      handleDeleteClick={handleDeleteClick}
                      customdropzoneImage={customdropzoneImage}
                      handleUpload={handleUpload}
                      imageList={imageList}
                    />
                  </div>
                )
              } else {
                return (
                  <Accordion
                    style={{
                      margin: 0,
                      top: section['section Label'] === 'Simulate Section' && !icon ? '-25px' : '',
                    }}
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownRounded className={styles.accordianIcon} />}
                    >
                      <Typography className={styles.sectionHeading}>
                        {section['section Label']}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <EditLanguageEntity
                        section={section}
                        languageData={languageData}
                        error={error}
                        handleChange={handleChange}
                        setlanguageData={setlanguageData}
                        icon={icon}
                        iconname={iconname}
                        handleDeleteClick={handleDeleteClick}
                        customdropzoneImage={customdropzoneImage}
                        handleUpload={handleUpload}
                        imageList={imageList}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        rejectedfile={rejectedfile}
                        deleteBuildCommand={deleteBuildCommand}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              }
            })
          )}
        </div>
      </form>
    </main>
  )
}

const mapDispatchToProps = {}

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo
  return { tabbedView }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLaunguageScreen)
