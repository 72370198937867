import { useState, useEffect, useRef } from "react";
import {
  Container,
  Divider,

} from "@material-ui/core";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import CustomFilter from "../../components/common/CustomFilter";
import AddIcon from "@material-ui/icons/Add";
import {
  roleColumnOrder,
  roleColumns,
  roleDefaultColumnWidths,
  roleDefaultRows,
  roleDefaultSorting,
  roleTableColumnExtensions,
} from "../../constants/roles";
import DevExpressTable from "../../components/common/Tables/DevExpressTable";
//redux
import { connect } from "react-redux";
import {
  add_all_roles_data,
  addRoleFilters,
  addRoleSort,
} from "../../redux/actions/accessmaintainanceAction";
import {
  arrayToFilter,
  arrayToSort,
  getColumnWidth,
  getWindowDimensions,
} from "../../utils/common";
import { AppButton } from "../../components/common/Button";
import { Pagination } from "@material-ui/lab";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import SearchBar from "../../components/common/SearchBar";

const AccessMaintenance = (props) => {
  const { alterActiveTab } = props;
  const styles = listStyles();
  const histroy = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState(null);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    alterActiveTab("Roles Management", "Roles Management");
    checkScreenPermission("Role", ["List All", "List Assigned"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Api call
  const {
    data,
    add_all_roles_data,
    loading,
    _totalcount,
    roleFilters,
    roleSort,
    addRoleFilters,
    addRoleSort,
    orgDate,
    deleteRoleStatus
  } = props;

  useEffect(() => {
    const parsedFilters = arrayToFilter(roleFilters);
    const parsedSort = arrayToSort(roleSort);
    add_all_roles_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, roleSort]);

  useEffect(() => {
    if(deleteRoleStatus === true){ 
      const parsedFilters = arrayToFilter(roleFilters);
      const parsedSort = arrayToSort(roleSort);
      add_all_roles_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
    }
  }, [deleteRoleStatus]);

  function createData(allRoles_data) {
    const { _id, name, numUsers, createdby, claims } = allRoles_data;
    return {
      id: _id,
      name: name,
      numUsers: numUsers,
      createdby: createdby,
      createdon: allRoles_data["createdon"]
        ? allRoles_data["createdon"]
        : orgDate,
      claims: claims,
    };
  }

  const roleRows =
    data.length > 0
      ? data.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      add_all_roles_data(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_all_roles_data(0);
    }
  };

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(roleFilters);
    const parsedSort = arrayToSort(roleSort);
    add_all_roles_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_all_roles_data(0, JSON.stringify({}));
  };

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Role Management", path: "/role-management" },
            { title: "Roles List" },
          ]}
        />

        <div className={styles.topContentItems}>
          <div className={styles.topContentItem} style={{ width: "80%" }}>
            <p className={styles.title}>
              Role Management {_totalcount && ` (${_totalcount})`}
            </p>
          </div>
          <div className={styles.topContentItem2}>

            <SearchBar
              handleSearch={handleSearch}
              PlaceHolderLabel="Search Role Name"
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
            <Divider orientation="vertical" className={styles.MuiDivider} />
            <CustomFilter
              columns={roleColumns}
              filters={roleFilters}
              setFilters={addRoleFilters}
              handleSubmitFilter={handleSubmitFilter}
              handleClearFilters={handleClearFilters}
            />
            <Divider orientation="vertical" className={styles.MuiDivider} />
            <AppButton
              buttonName="Add Role"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={!checkCompPermission("Role", ["Create"])}
              style={{ minWidth: 150 }}
              className="btnsmall"
              onClick={() => histroy.push("/role-management/add")}
            />
          </div>
        </div>
      </div>
      <div className={styles.pageContent}>
        <DevExpressTable
          columns={roleColumns}
          rows={roleRows}
          defaultColumnWidths={getColumnWidth(
            roleDefaultColumnWidths,
            windowDimensions
          )}
          tableColumnExtensions={roleTableColumnExtensions}
          columnOrder={roleColumnOrder}
          defaultSorting={roleDefaultSorting}
          loading={loading}
          isCustomHeight={true}
          totalCount={10}
          height={windowDimensions.height - 260}
          noDataMsg="No Role Found"
          sort={roleSort}
          setSort={addRoleSort}
          hideColumnChooser={true}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.customPagination}>
          <Pagination
            count={Math.ceil(_totalcount / 10)}
            page={currentPage}
            onChange={(e, val) => setCurrentPage(val)}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_all_roles_data,
  addRoleFilters,
  addRoleSort,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { all_roles_list, roleFilters, roleSort } =
    state.accessmaintainanceReducer;
  const { data, error, loading, _totalcount, deleteRoleStatus } = all_roles_list;
  const { currentOrgData } = state.organizationReducer;
  return {
    data,
    error,
    loading,
    _totalcount,
    roleFilters,
    roleSort,
    orgDate: currentOrgData?.data?.createdon,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessMaintenance);
