import { useState } from "react";
import Bottombar from "../molecules/project/Bottombar";
import Playgroud from "../molecules/project/Playgroud";
import Rightbar from "../molecules/project/Rightbar";
import Componentbar from "../molecules/project/Componentbar";
import TopHeader from "../molecules/project/TopHeader";
import layoutStyles from "../../styles/commonStyles/Layout";
import Sidemenu from "../molecules/project/Sidemenu";
import Action from "../molecules/action/Action";
import ActionBar from "../molecules/action/ActionBar";
import Pages from "../molecules/action/Pages";
import useGetData from "../../hooks/useGetData";
import PreviewOutput from "../molecules/project/PreviewOutput";
import PreviewTopHeader from "../molecules/project/PreviewTopHeader";
import { useParams } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Tooltip } from "@material-ui/core";

const Layout = () => {
  const styles = layoutStyles();
  const params = useParams();
  const { currentProject } = useGetData();
  const [activeTab, setActiveTab] = useState("pages");
  const [expandCanvas, setExpandCanvas] = useState(false);
  const isPublish = params.view === "publish";
  const isPreview = params.view === "preview";

  return (
    <>
      {!isPublish ? (
        <>
          {!isPreview ? (
            <div className={styles.layout}>
              <TopHeader />
              <Sidemenu activeTab={activeTab} setActiveTab={setActiveTab} />
              {!currentProject?.actionOpen ? (
                activeTab === "components" ? (
                  <Componentbar expandCanvas={expandCanvas} />
                ) : (
                  <Pages expandCanvas={expandCanvas} />
                )
              ) : (
                <ActionBar />
              )}
              {!currentProject?.actionOpen ? (
                <div
                  onClick={() => setExpandCanvas(!expandCanvas)}
                  className={styles.arrow}
                  style={{
                    transform: `translate(-${expandCanvas ? 240 : 0}px, 0px)`,
                    transition: "all 0.5s ease-in-out",
                  }}
                >
                  {expandCanvas ? (
                    <Tooltip
                      title={`Expand ${
                        activeTab === "components" ? "Components" : "Pages"
                      } Tab`}
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`Collapse ${
                        activeTab === "components" ? "Components" : "Pages"
                      } Tab`}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </Tooltip>
                  )}
                </div>
              ) : (
                <></>
              )}
              {currentProject?.actionOpen ? (
                <Action />
              ) : (
                <Playgroud expandCanvas={expandCanvas} />
              )}
              <Rightbar />
              {!currentProject?.actionOpen && (
                <Bottombar expandCanvas={expandCanvas} />
              )}
            </div>
          ) : (
            <div className={styles.layout}>
              <PreviewTopHeader />
              <PreviewOutput isPublish={isPublish} />
            </div>
          )}
        </>
      ) : (
        <div className={styles.output}>
          <PreviewOutput isPublish={isPublish} />
        </div>
      )}
    </>
  );
};

export default Layout;
