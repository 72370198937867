import { Divider, Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// files
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import {
  teamsColumns,
  teamsDefaultColumnWidths,
  teamsTableColumnExtensions,
  teamsColumnOrder,
  teamsColumnHidden,
} from "../../../constants/Teams/TeamsConstant";
import { useTranslation } from "react-i18next";
import CustomFilter from "../../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort2,
  arrayToSort,
} from "../../../utils/common";
// redux
import { connect } from "react-redux";
import {
  add_teams_data,
  add_team_filters,
  add_teams_sort,
  clear_teams_data,
  add_teams_hidden_cols,
} from "../../../redux/actions/teamActions";
import AddIcon from "@material-ui/icons/Add";
import Pagination from "@material-ui/lab/Pagination";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SearchBar from "../../../components/common/SearchBar";

const TeamsList = (props) => {
  const {
    data,
    add_teams_data,
    loading,
    add_team_filters,
    teamFilter,
    add_teams_sort,
    teamsSort,
    _totalcount,
    clear_teams_data,
    add_teams_hidden_cols,
    teamsHiddenCols,
  } = props;
  const styles = listScreenStyles();
  const histroy = useHistory();
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: teamsColumns,
    widths: teamsDefaultColumnWidths,
    extentions: teamsTableColumnExtensions,
    orders: teamsColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Team", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Team");
      if (_status === 200) {
        const teamData = getTableData(
          data,
          teamsColumns,
          teamsDefaultColumnWidths,
          teamsTableColumnExtensions,
          teamsColumnOrder,
          teamsHiddenCols
        );
        setTableData(teamData);
        if (
          JSON.stringify(teamsHiddenCols) === JSON.stringify(teamsColumnHidden)
        ) {
          add_teams_hidden_cols(teamData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  // Api call

  useEffect(() => {
    const parsedFilters = arrayToFilter(teamFilter);
    const parsedSort = arrayToSort(teamsSort);
    add_teams_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, teamsSort]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function createData(teams_data) {
    const {
      _id,
      type,
      name,
      users,
      createdon,
      createdby,
      block_status,
      lock_status,
      vendorName,
    } = teams_data;
    return {
      ...teams_data,
      _id,
      type,
      name,
      users,
      createdby,
      createdon: createdon,
      lock_status,
      block_status,
      vendorid: vendorName,
    };
  }

  const rows =
    data?.length > 0
      ? data.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];

  // filter
  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(teamFilter);
    const parsedSort = arrayToSort2(teamsSort);
    add_teams_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_teams_data(1, JSON.stringify({}));
  };

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);
  const handleSearch = () => {
    if (searchInput) {
      add_teams_data(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_teams_data(1);
    }
  };

  // code changes done here..... clear reducer

  useEffect(() => {
    return () => {
      clear_teams_data();
    };
  }, []);
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Teams", path: "/teams" },
              { title: "Teams List" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("teams.teams")} {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div
              className={styles.topContentItem}
              style={{ justifyContent: "flex-end" }}
            >
              <div className={styles.internalExtenalIconArea}>
                Internal{" "}
                <span
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#A333C8",
                  }}
                ></span>
                &nbsp;&nbsp;
                External
                <span
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#00B5AD",
                  }}
                ></span>
              </div>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Team Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData?.columns}
                filters={teamFilter}
                setFilters={add_team_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="Create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("Team", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/create-team")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {tableLoading ? (
            <Loader />
          ) : (
            <ApplicationListTable
              columns={tableData.columns}
              rows={rows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              defaultHiddenColumnNames={tableData.hides}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isCustomHeight={true}
              sort={teamsSort}
              setSort={add_teams_sort}
              hidden={teamsHiddenCols}
              setHidden={add_teams_hidden_cols}
            />
          )}
        </Paper>
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_teams_data,
  add_team_filters,
  add_teams_sort,
  clear_teams_data,
  add_teams_hidden_cols,
};

const mapStateToProps = (state) => {
  const { teams_list, teamFilter, teamsSort, teamsHiddenCols } =
    state.teamsReducer;
  const { data, error, loading, _totalcount } = teams_list;
  return {
    data,
    error,
    loading,
    teamFilter,
    teamsSort,
    _totalcount,
    teamsHiddenCols,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamsList);
