const serviceGUIColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "Service Name", for: "uisimul" },
  { name: "createdby", title: "Created By", for: "uisimul" },
  { name: "createdon", title: "Created On", for: "uisimul" },
  { name: "status", title: "Status", for: "uisimul" },
  {
    name: "action",
    title: "Action",
    route: "/services/edit",
    for: "uisimul",
  },
];

const serviceGUIDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage:3, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage:2, width: 200 },
  { columnName: "status", weightage:3, width: 200 },
  { columnName: "action", weightage: 3, width: 200 },
  {columnName:"description",weightage:3,width:200},
  {columnName:"imagename",weightage:3,width:200},
  {columnName:"serviceType",weightage:3,width:200},
  {columnName:"service_category",weightage:3,width:200},
  { columnName: "updatedby", weightage: 2, width: 200 },
  { columnName: "updatedon", weightage:2, width: 200 },
];

const serviceGUITableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const serviceGUIColumnOrder = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
  "description",
  "imagename",
  "serviceType",
  "service_category",
  "updatedby",
  "updatedon"
];

const serviceGUIColumnHidden = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
];

const serviceGUIDefaultSorting = [];

export {
  serviceGUIColumns,
  serviceGUIDefaultColumnWidths,
  serviceGUITableColumnExtensions,
  serviceGUIColumnOrder,
  serviceGUIDefaultSorting,
  serviceGUIColumnHidden,
};
