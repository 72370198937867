import { useEffect, useState } from "react";
import {
  IconButton,
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { addAuthData } from "../../redux/actions/authAction";
import CustomButton from "../../components/common/Button/CustomButton";
import useStyles from "./commonStyles";
import Eye from "./Assets/eye.svg";
import EyeSlash from "./Assets/eye-slash.svg";
import { forceLoginService, login } from "../../utils/authentication/authenticationUtils";
import { validateEmail } from "../../utils/common";
import { userDetailAction } from "../../redux/actions/userProfileAction";
import {
  addUserFavouite,
  addUserSubscription,
} from "../../redux/actions/userPersonalAction";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { add_current_organization_data_action } from "../../redux/actions/organizationAction";
import { useLocation } from "react-router-dom";
import { CheckJwtExpiry } from "../../components/common/Auth/CheckJwtExpiry";
import { ForceLoginModal } from "../../components/common/modals/ForceLoginModal";

function LoginScreen(props) {
  const [toggleShow, setToggleShow] = useState(false);
  const [forceLoginModalShow, setForceLoginModalShow] = useState(false);
  const [tempToken, setTempToken] = useState();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorObj, setErrorObj] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const authReducer = useSelector((state) => state.authReducer);
  const { accesstoken } = authReducer;

  const { state } = useLocation();
  useEffect(() => {
    const isValid = CheckJwtExpiry(accesstoken);
    if (isValid){
      state?.from
        ? history.push(state?.from?.pathname)
        : history.push("/dashboard");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextChange = (key, value) => {
    setErrorObj((prevProps) => ({
      ...prevProps,
      [key]: false,
    }));
    setFormData((prevProps) => ({
      ...prevProps,
      [key]: value,
    }));
  };

  const handleSubmitClick = async (isFrom) => {
    var errorFlag = false;
    var tempObj = {};
    for (var obj in formData) {
      if (formData[obj] === "") {
        errorFlag = true;
        tempObj = {
          ...tempObj,
          [obj]: true,
        };
      }
    }
    setErrorObj((prevProps) => ({
      ...prevProps,
      ...tempObj,
    }));
    const emailValid = validateEmail(formData.email);
    if (!emailValid) {
      errorFlag = true;
      setErrorObj((prevState) => ({
        ...prevState,
        email: true,
      }));
    }
    if (!errorFlag) {
      setLoading(true);
      const req_data = {
        email: formData.email,
        password: formData.password,
      };
      //
      if(isFrom === "force_login"){
        var { _msg, _status, data } = await forceLoginService(tempToken)
      } else {
        var { _msg, _status, data } = await login(req_data);
      }

      if (_status === 200 || _status === 201) {
        var authdata = {};
        let userId = "";
        setLoading(false);

        if (data.length === 2) {
          if (data[0]?.accesstoken && data[1]?.refreshtoken) {
            authdata = {
              ...authdata,
              accesstoken: data[0]?.accesstoken,
              refreshtoken: data[1]?.refreshtoken,
              userid: data[0]?.userid,
              token_expired_msg: ""
            };
            await props.addAuthData(authdata);

            // user Detail
            await props.userDetailAction();

            // userfav
            await props.addUserFavouite(userId);

            // usersubscription
            await props.addUserSubscription(userId);

            // Org Data
            await props.add_current_organization_data_action();

            if (state?.from) {
              history.push(state?.from?.pathname);
            } else {
              history.push("/dashboard");
            }
          } else {
            history.push({
              pathname: "/verify-otp",
              state: {
                email: formData.email,
              },
            });
          }
        } else {
          const { accessToken, refreshToken, userDetails, userActions } = data;
          if (accessToken && refreshToken) {
            const decryptData = jwt_decode(accessToken);
            userId = userDetails?.id;
            authdata = {
              ...authdata,
              accesstoken: accessToken,
              refreshtoken: refreshToken,
              userid: userId,
              userActions: decryptData?.actions,
              token_expired_msg: ""
            };

            // authdata
            await props.addAuthData(authdata);

            // user Detail
            await props.userDetailAction();

            // userfav
            await props.addUserFavouite(userId);

            // usersubscription
            await props.addUserSubscription(userId);

            // Org Data
            await props.add_current_organization_data_action();

            if (state?.from) {
              history.push(state?.from?.pathname);
            } else {
              history.push("/dashboard");
            }
          } else {
            history.push({
              pathname: "/verify-otp",
              state: {
                email: formData.email,
              },
            });
          }
          // enqueueSnackbar(_msg, { variant: "success" });
        }
      } else {
        if(_status === 409){
          setForceLoginModalShow(true)
          setTempToken(data?.token)
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
        setLoading(false);
      }
    }
  };

  const handleForgetPassword = () => {
    history.push("/forget-password");
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmitClick();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className={styles.container}>
      <ForceLoginModal open={forceLoginModalShow} handleClose={()=> setForceLoginModalShow(false)} handleConfirm = { ()=> {handleSubmitClick("force_login"); setForceLoginModalShow(false)}} />
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <text
        className={styles.big_text}
        style={{ alignSelf: "center", marginTop: 64 }}
      >
        Login
      </text>
      <div className={styles.email}>
        <label className={styles.label}>
          Email ID
          <text className={styles.star}> *</text>
        </label>
        <input
          type="email"
          placeholder="Please enter email"
          className={styles.password_text}
          style={
            errorObj.email
              ? {
                  borderColor: "red",
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                }
              : { WebkitBoxShadow: "0 0 0 1000px white inset" }
          }
          value={formData.email}
          id={"134984"}
          onChange={(e) => handleTextChange("email", e.target.value)}
        ></input>
        {errorObj.email && (
          <text className={styles.error_text}>Invalid Email</text>
        )}
      </div>
      <div className={styles.password} style={{ position: "relative" }}>
        <label className={styles.label}>
          Password
          <text className={styles.star}> *</text>
        </label>
        <Grid container alignItems="center">
          <Grid item>
            <input
              type={toggleShow ? "text" : "password"}
              className={styles.password_text}
              style={
                errorObj.password
                  ? {
                      borderColor: "red",
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    }
                  : { WebkitBoxShadow: "0 0 0 1000px white inset" }
              }
              value={formData.password}
              onChange={(e) => handleTextChange("password", e.target.value)}
            />
          </Grid>
          <Grid item>
            <span
              onClick={() => setToggleShow((prev) => !prev)}
              className={styles.eye + " eye-password"}
              style={{ position: "absolute" }}
            >
              {toggleShow ? (
                <img src={Eye} alt="Eye" className={styles.icon} />
              ) : (
                <img src={EyeSlash} alt="eye" className={styles.icon} />
              )}
            </span>
          </Grid>
        </Grid>
        {state?.isExpired && (
          <text className={styles.error_text}>{props.authReducer?.token_expired_msg ? props.authReducer?.token_expired_msg : ""}</text>
        )}
        {errorObj.password && (
          <text className={styles.error_text}>Invalid Password</text>
        )}
      </div>
      <IconButton
        style={{ marginTop: 37, alignSelf: "center", background: "none" }}
        onClick={handleSubmitClick}
        disableRipple
      >
        <CustomButton type="long" label="Login" />
      </IconButton>
      <div style={{ alignSelf: "center", marginTop: 40 }}>
        <span className={styles.smalltext}>
          <text style={{ color: "#666666" }}>Forgot Password</text>
        </span>
        <Typography
          onClick={handleForgetPassword}
          component={Link}
          style={{
            color: "#031CCA",
            fontWeight: "300",
            marginLeft: 16,
            fontSize: 14,
          }}
        >
          Click Here
        </Typography>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  addAuthData,
  addUserFavouite,
  addUserSubscription,
  userDetailAction,
  add_current_organization_data_action,
};

const mapStateToProps = (state) => {
  const authReducer =  state.authReducer
  return {authReducer};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
