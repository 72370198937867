import { Popover } from '@material-ui/core'
import CustomAutocomplete from '../autocomplete/CustomAutocomplete'
import Input from '../input/Input'

const TableFilter = ({ styles, columns, filter, setFilter, filtersEl, setFiltersEl, isFiltesrOpen }) => {
    return (
        <Popover
            open={isFiltesrOpen}
            anchorEl={filtersEl}
            onClose={() => setFiltersEl(null)}
            className={styles.filters}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="filtersWrap">
                <div className="field">
                    <label>Column</label>
                    <CustomAutocomplete
                        options={columns.filter(f => f.name !== "uiApplication")}
                        optionLabel={(option) => option?.name ? option?.name : ""}
                        value={columns?.find((col) => col?.name === filter?.name)}
                        style={{ width: 200 }}
                        onChange={(input) => setFilter({ ...filter, name: input.name })}
                        />
                </div>
                <div className="field" style={{display: 'flex',  flexDirection: 'column'}}>
                        <label>Value</label>
                    <input
                    style={{marginTop: "10px", height: 40 , border: "1px solid #DFDFDF", borderRadius: '5px' }}
                        name="value"
                        value={filter.value}
                        onChange={(e) => setFilter({ ...filter, value: e.target.value })}
                         />
                </div>
            </div>
        </Popover>
    )
}

export default TableFilter
