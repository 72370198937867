import {
  USER_HELP_IMAGE,
  USER_VISIBLE_FAIL,
  USER_VISIBLE_REQUEST,
  USER_VISIBLE_RESET,
  USER_VISIBLE_SUCCESS,
} from "../../constants/UserProfile/user";

// change appearance
export const appearnceReducer = (
  state = { appear: false,image:{}},
  action
) => {
  switch (action.type) {
    case USER_VISIBLE_REQUEST:
      return {appear: action.payload };
    case USER_VISIBLE_RESET:
      return {};
    case USER_HELP_IMAGE:
      return{
        ...state,image:action.payload
      }
    default:
      return state;
  }
};
