import { GET_PROJECTS_DOCUMENTS,PROJECT_DOCUMENT_FILTERS, PROJECT__DOCUMENT_SORT,CLEAR_PROJECT_DATA} from '../actionTypes';
import { ADD_SERVICE_LIST__DOCUMENT_DATA,SERVICE_DOCUMENT_DATA_FILTERS, SERVICE_DOCUMENT_DATA_SORT,CLEAR_SERVICE_DATA } from '../actionTypes';
import { v4 as uuidv4 } from "uuid";

const initialState = {
    projectsListDocumentsData: {
        data: [],
        error: false,
        loading: false
    },
    projectsListDocumentFilters: [
        { id: uuidv4(), column: '', operator: '', value: '' }
    ],
    projectListDocumentSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    servicesListDocumentsData: {
        data: [],
        error: false,
        loading: false
    },
    serviceFiltersDocumentsData: [
        { id: uuidv4(), column: '', operator: '', value: '' }
    ],
    serviceSortDocumentsData: [
        { columnName: 'createdon', direction: 'desc' }
    ],
  }

export default function  documentationReducer (state = initialState, action){
    switch (action.type) {
        case GET_PROJECTS_DOCUMENTS: {
            return {
                ...state,
                projectsListDocumentsData: {
                    ...state.projectsListDocumentsData,
                    ...action.payload
                }
            }
        }
        case PROJECT_DOCUMENT_FILTERS: {
            return {
                ...state,
                projectsListDocumentFilters: [
                    ...action.payload
                ]
            }
        }
        case PROJECT__DOCUMENT_SORT: {
            return {
                ...state,
                projectListDocumentSort: [
                    ...action.payload
                ]
            }
        }
        case CLEAR_PROJECT_DATA:{
            return{
                ...state,
                projectsListDocumentsData:{}
            }
        }
      
        case ADD_SERVICE_LIST__DOCUMENT_DATA: {
            return {
                ...state,
                servicesListDocumentsData: {
                    ...state.servicesListDocumentsData,
                    ...action.payload
                }
            }
        }
        case SERVICE_DOCUMENT_DATA_FILTERS: {
            return {
                ...state,
                serviceFiltersDocumentsData: [
                    ...action.payload
                ]
            }
        }
        case SERVICE_DOCUMENT_DATA_SORT: {
            return {
                ...state,
                serviceSortDocumentsData: [
                    ...action.payload,
                ]
            }
        }
        case CLEAR_SERVICE_DATA:{
            return{
                ...state,
                servicesListDocumentsData:{}

            }
        }
       
        default:
            return state;
    }

    
}