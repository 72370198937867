import React, { useEffect, useState } from "react";
import { ButtonGroup as BtnGroup, Grid, Typography } from "@material-ui/core";
import ButtonGroupStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import useGetData from "../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import FormatBoldOutlinedIcon from "@material-ui/icons/FormatBoldOutlined";
import FormatItalicOutlinedIcon from "@material-ui/icons/FormatItalicOutlined";
import FormatUnderlinedOutlinedIcon from "@material-ui/icons/FormatUnderlinedOutlined";

const ButtonGroup = () => {
  const styles = ButtonGroupStyles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent } = useGetData();
  const theme = useTheme();

  const handleTab = (key) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          style: {
            ...component?.style,
            [key]: !currentComponent?.style[key],
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <Grid container className={styles.mainContainer}>
      <Grid
        item
        xs={4}
        className={styles.item}
        style={{
          backgroundColor: currentComponent?.style?.bold
            ? theme.palette.primary.main
            : "#fff",
          color: currentComponent?.style?.bold
            ? "#fff"
            : theme.palette.primary.main,
        }}
        onClick={() => handleTab("bold")}
      >
        <Typography align="center" className={styles.typo}>
          <FormatBoldOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={styles.item}
        onClick={() => handleTab("italic")}
        style={{
          backgroundColor: currentComponent?.style?.italic
            ? theme.palette.primary.main
            : "#fff",
          color: currentComponent?.style?.italic
            ? "#fff"
            : theme.palette.primary.main,
        }}
      >
        <Typography align="center" className={styles.typo}>
          <FormatItalicOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={styles.item}
        onClick={() => handleTab("underline")}
        style={{
          backgroundColor: currentComponent?.style?.underline
            ? theme.palette.primary.main
            : "#fff",
          color: currentComponent?.style?.underline
            ? "#fff"
            : theme.palette.primary.main,
        }}
      >
        <Typography align="center" className={styles.typo}>
          <FormatUnderlinedOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ButtonGroup;
