import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import TablePopup from "./popups/TablePopup";
import FormPopup from "./popups/FormPopup";
import { IconButton, Tooltip } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import DetailsPopup from "./popups/DetailsPopup";
import useGetData from "../../../../hooks/useGetData";
import TreePopup from "./popups/TreePopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Columns = ({ columns }) => {
  const { currentPage, currentComponent } = useGetData();
  const [optionsEl, setOptionsEl] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState({
    id: "",
    name: "",
    title: "",
    isHide: false,
    isDelete: false,
    isDiscardedFromPayload: false,
    width: "",
    isRequired: false,
  });
  const [detailFieldOption, setDetailFieldOption] = useState([]);
  useEffect(() => {
    if (currentComponent?.columns && selectedColumn?.id) {
      setSelectedColumn(
        currentComponent?.columns?.find(
          (comp) => comp?.id === selectedColumn?.id
        )
      );
    }
  }, [currentComponent?.columns]);

  useEffect(() => {
    if (currentComponent?.type === "Details") {
      const columns = currentComponent?.columns;
      const detailOption = columns?.map((col) => col?.name);
      setDetailFieldOption(detailOption);
    }
  }, [currentComponent?.columns]);

  const isOptionsOpen = Boolean(optionsEl);
  const dispatch = useDispatch();

  const handleHide = (column) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col.id === column?.id) {
              return {
                ...col,
                isHide: !col.isHide,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleDelete = (column) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col.id === column?.id) {
              return {
                ...col,
                isDelete: !col?.isDelete,
                isHide: true,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const detailColumnDelete = (column) => {
    const components = currentPage.components.map((component) => {
      if (component?.name === currentComponent?.name) {
        columns = component?.columns?.filter((col) => col?.id !== column?.id);
        return {
          ...component,
          columns: columns,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleAddColumn = () => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        const col = component?.columns[0];
        let column = {};
        switch (component?.type) {
          case "Form":
            column = {
              id: uuid(),
              name: col?.name,
              isHide: false,
              isDelete: false,
              isRequired: false,
              isDiscardedFromPayload: false,
              placeholder: "",
              type: "text",
              title: col?.title,
              width: "",
              rows: 2,
              options: [],
            };
            break;
          case "Details":
            column = {
              id: uuid(),
              name: "",
              isHide: false,
              type: "text",
              title: "",
              isEditable: false,
              width: "",
              options: [],
            };
            break;
          case "Tree":
            column = {
              id: uuid(),
              name: "",
              isHide: false,
              type: "text",
              title: "",
              isEditable: false,
              width: "",
              options: [],
            };
            break;

          default:
            column = {
              id: uuid(),
              name: col.name,
              isHide: false,
              isDelete: false,
              title: col.title,
              width: "",
            };
        }
        setSelectedColumn(column);
        return {
          ...component,
          columns: [...component?.columns, column],
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              if (e.target.name === "name") {
                const foundColumn = component?.columns?.find(
                  (column) => column?.name === e.target.value
                );
                return {
                  ...col,
                  name: foundColumn?.name,
                  title: foundColumn?.title,
                  width: foundColumn?.width,
                };
              } else {
                return {
                  ...col,
                  [e.target.name]: e.target.value,
                };
              }
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleTreeColumnsChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                [e.target.name]: e.target.value,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleChangeDetail = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              if (e.target.name === "name") {
                return {
                  ...col,
                  name: e.target.value,
                  title:
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1),
                };
              } else {
                return {
                  ...col,
                  [e.target.name]: e.target.value,
                };
              }
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  const handleChangeForm = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                [e.target.name]: e.target.value,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleChangeForPopOver = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                type: e.target.value,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const typeArr = [
    { name: "text" },
    { name: "textarea" },
    { name: "toggle" },
    { name: "datepicker" },
    { name: "select" },
    { name: "multiselect" },
    { name: "file" },
    { name: "image" },
    // { name: "string" },
    { name: "number" },
  ];

  const onDragEnd = (result) => {
    var source_obj = {};
    var target_obj = {};
    var new_cols = currentComponent.columns;
    if (result.source && result.destination) {
      var source_index = result.source.index;
      var target_index = result.destination.index;
      source_obj = currentComponent.columns[source_index];
      target_obj = currentComponent.columns[target_index];
      new_cols[source_index] = target_obj;
      new_cols[target_index] = source_obj;
      const components = currentPage.components.map((component) => {
        if (component.name === currentComponent.name) {
          return {
            ...component,
            columns: new_cols,
          };
        } else {
          return component;
        }
      });
      dispatch(
        editComponent({
          pageid: currentPage.id,
          data: components,
        })
      );
    }
  };

  // form Related
  const handleChangeDiscardFromPayload = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                isDiscardedFromPayload: e.target.checked,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  const handleChangeRequired = (event) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                isRequired: event.target.checked,
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {columns.map((column, index) => (
                <Draggable
                  key={column?.id}
                  draggableId={column?.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {!column?.isDelete && (
                        <div
                          className="tableColumn"
                          onClick={(e) => {
                            setSelectedColumn(column);
                            setOptionsEl(e.currentTarget);
                          }}
                        >
                          <div className="text">
                            <IconButton
                              color="inherit"
                              edge="start"
                              style={{ margin: 0, padding: "6px 12px" }}
                              onClick={(e) => {
                                setSelectedColumn(column);
                                setOptionsEl(e.currentTarget);
                              }}
                            >
                              <img src="/img/three-dots.svg" alt="Three Dots" />
                            </IconButton>
                            <Tooltip title={column?.title}>
                              <h2 className="wrapTitleText">{`${column?.title}`}</h2>
                            </Tooltip>
                          </div>
                          <div className="icons">
                            <IconButton
                              color="inherit"
                              edge="start"
                              style={{ margin: 0, padding: 5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleHide(column);
                              }}
                            >
                              {column?.isHide ? (
                                <img
                                  src="/img/eye-slash.svg"
                                  style={{ height: 20, opacity: 0.5 }}
                                  alt="Eye"
                                />
                              ) : (
                                <img src="/img/eye-line.svg" alt="Eye" />
                              )}
                            </IconButton>
                            <IconButton
                              color="inherit"
                              edge="start"
                              style={{ margin: 0, padding: 5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                currentComponent.type === "Details"
                                  ? detailColumnDelete(column)
                                  : handleDelete(column);
                              }}
                            >
                              <img
                                src="/img/delete-bin-7-line.svg"
                                alt="Delete"
                              />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {currentComponent?.type !== "Details" ? (
        <div
          className="tableColumn"
          style={{ justifyContent: "center", padding: 5, cursor: "pointer" }}
          onClick={handleAddColumn}
        >
          <AddOutlinedIcon />
        </div>
      ) : currentComponent?.type === "Details" &&
        Object.keys(currentComponent?.data?.dataValue).length !==
          currentComponent?.columns?.length ? (
        <div
          className="tableColumn"
          style={{ justifyContent: "center", padding: 5, cursor: "pointer" }}
          onClick={handleAddColumn}
        >
          <AddOutlinedIcon />
        </div>
      ) : null}

      {currentComponent.type === "Table" && (
        <TablePopup
          isOptionsOpen={isOptionsOpen}
          optionsEl={optionsEl}
          columns={Object.keys(
            currentComponent?.data?.dataValue?.length
              ? currentComponent?.data?.dataValue[0]
              : []
          )}
          setOptionsEl={setOptionsEl}
          selectedColumn={selectedColumn}
          handleHide={handleHide}
          handleDelete={handleDelete}
          handleChange={handleChange}
        />
      )}

      {currentComponent.type === "Form" &&
        currentComponent?.data?.dataValue && (
          <FormPopup
            isOptionsOpen={isOptionsOpen}
            optionsEl={optionsEl}
            columns={typeArr}
            setOptionsEl={setOptionsEl}
            selectedColumn={selectedColumn}
            handleHide={handleHide}
            handleDelete={handleDelete}
            handleChange={handleChangeForm}
            handleChangeForPopOver={handleChangeForPopOver}
            handleChangeRequired={handleChangeRequired}
            handleChangeDiscardFromPayload={handleChangeDiscardFromPayload}
            options={
              Array.isArray(currentComponent?.data?.dataValue)
                ? currentComponent?.data?.dataValue.length > 0
                  ? Object.keys(currentComponent?.data?.dataValue[0])
                  : []
                : Object.keys(currentComponent?.data?.dataValue)
            }
          />
        )}
      {currentComponent.type === "Details" &&
        currentComponent?.data?.dataValue && (
          <DetailsPopup
            isOptionsOpen={isOptionsOpen}
            optionsEl={optionsEl}
            columns={typeArr}
            fieldData={Object.keys(currentComponent?.data?.dataValue)}
            setOptionsEl={setOptionsEl}
            detailFieldOption={detailFieldOption}
            selectedColumn={selectedColumn}
            handleHide={handleHide}
            handleDelete={detailColumnDelete}
            handleChange={handleChangeDetail}
            handleChangeForPopOver={handleChangeForPopOver}
            handleChangeRequired={handleChangeRequired}
          />
        )}
      {currentComponent.type === "Tree" && (
        <TreePopup
          isOptionsOpen={isOptionsOpen}
          optionsEl={optionsEl}
          columns={Object.keys(
            currentComponent?.data.dataValue.length
              ? currentComponent?.data?.dataValue[0]
              : []
          )}
          setOptionsEl={setOptionsEl}
          selectedColumn={selectedColumn}
          handleHide={handleHide}
          handleDelete={handleDelete}
          handleChange={handleTreeColumnsChange}
        />
      )}
    </>
  );
};

export default Columns;
