import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store";
import ScrollToTop from "./components/common/ScrollToTop";
import Header from "./components/common/Header";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import routes from "./routes";
import Helpdesk from "./components/common/Helpdesk";
import PrivateRoute from "./PrivateRoute";
import { connect } from "react-redux";
import Favicon from "./components/HOC/Favicon";

const App = (props) => {
  const { userInfo } = props;
  const theme = createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "div[role=presentation] .MuiPopover-paper.MuiPaper-elevation8 ul .MuiCheckbox-root":
            {
              color: `${userInfo.color ? userInfo.color : "#002855"}`,
            },
        },
      },
    },
    palette: {
      common: {
        lightBlack: "#707070",
        red: "#DB2828",
        orange: "#F2711C",
        yellow: "#FBBD08",
        olive: "#B5CC18",
        green: "#21BA45",
        teal: "#00B5AD",
        blue: "#2185D0",
        violet: "#6435C9",
        purple: "#A333C8",
        pink: "#E03997",
        brown: "#A5673F",
        grey: "#767676",
        black: "#1B1C1D",
      },
      primary: {
        main: `${
          localStorage.getItem("primaryColor")
            ? localStorage.getItem("primaryColor")
            : "#002855"
        }`,
      },
      secondary: {
        light: "#707070",
        main: "#d32f2f",
        dark: "#d32f2f",
        contrastText: "#ffffff",
      },
      background: { default: "#fafafa" },
    },
    typography: {
      useNextVariants: true,
      fontFamily: ["Nunito"],
      tab: {
        fontSize: "0.86rem",
        color: "#1B1C1D",
        textTransform: "none",
        minWidth: 16,
        marginRight: 36,
        opacity: 0.6,
        lineHeight: 0,
      },
      // <--- New typography variants --->
      heading: {
        font: "normal normal bold 21px/28px Nunito",
      },
      subHeading: {
        font: "normal normal 600 16px/22px Nunito",
      },
      body: {
        font: "normal normal normal 14px/19px Nunito",
      },
      information: {
        font: "normal normal normal 12px/16px Nunito",
      },
      links: {
        font: "normal normal 300 14px/16px Nunito",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Helpdesk>
          <Favicon>
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <ScrollToTop />
              <Header />
              <Switch>
                {routes.map((route, index) => {
                  if (route.isPrivate) {
                    return (
                      <PrivateRoute
                        path={route.path}
                        exact
                        component={(props) =>
                          route.component({
                            ...props,
                            title: route.title,
                            path: route.path,
                          })
                        }
                        key={index}
                      />
                    );
                  } else {
                    return (
                      <Route
                        path={route.path}
                        exact
                        component={(props) =>
                          route.component({
                            ...props,
                            title: route.title,
                            path: route.path,
                          })
                        }
                        key={index}
                      />
                    );
                  }
                })}
              </Switch>
            </PersistGate>
          </Favicon>
        </Helpdesk>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  return {
    userInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
