import * as React from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useGetData from "../../../hooks/useGetData";
import Loader from "../loader/Loader";
import useTriggers from "../../../hooks/useTriggers";
import { useState, useEffect } from "react";
import componentStyles from "../../../styles/commonStyles/Components";

function Children(props) {
  const { row, currentComponent, columns } = props;
  const [open, setOpen] = React.useState(false);

  const { handleTrigger } = useTriggers();

  const handleExpandRow = () => {
    setOpen(!open);
    if (currentComponent?.onExpandRow) {
      handleTrigger({
        action: currentComponent?.onExpandRow,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };
  const handleRowSelect = (e) => {
    e.stopPropagation();
    if (currentComponent?.onRowSelect) {
      handleTrigger({
        action: currentComponent?.onRowSelect,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };
  return (
    <React.Fragment>
      <TableRow onClick={handleRowSelect}>
        <TableCell>
          {row?.children && (
            <IconButton size="small" onClick={handleExpandRow}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {columns &&
          columns.length > 0 &&
          columns?.map((col, colindex) => (
            <TableCell
              style={{
                fontSize: currentComponent?.fontSize
                  ? currentComponent?.fontSize
                  : 16,
              }}
              key={colindex}
              width={col?.width ? col?.width : 0}
            >
              {row?.[col?.name]}
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                <TableBody>
                  {row &&
                    row.length > 0 &&
                    row?.children?.map((child, index) => (
                      <Children row={child} columns={columns} />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CustomTree({ id }) {
  const classes = componentStyles();

  const { getComponent } = useGetData();

  const currentComponent = getComponent(id);

  const [tableRows, setTableRows] = useState([]);
  const [invalidData, setInvalidData] = useState(true);

  const columns = currentComponent?.columns?.filter((column) => !column.isHide);

  // Generate Column And rows
  useEffect(() => {
    if (Array.isArray(currentComponent?.data?.dataValue)) {
      setTableRows(currentComponent?.data?.dataValue);
      setInvalidData(false);
    } else {
      setTableRows([]);
      setInvalidData(true);
    }
  }, [currentComponent?.data?.dataValue]);

  const titleText = currentComponent?.title?.dataValue;

  return (
    <>
      {!invalidData ? (
        <>
          <div className={classes.header}>
            <h1
              className="title"
              style={{
                fontFamily: currentComponent?.fontFamily,
                fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
                fontStyle: currentComponent?.style?.italic
                  ? "italic"
                  : undefined,
                textDecoration: currentComponent?.style?.underline
                  ? "underline"
                  : undefined,
              }}
            >
              {titleText && typeof titleText === "string" ? titleText : "Tree"}
            </h1>
          </div>
          {currentComponent?.loading?.dataValue === "true" && <Loader />}
          <TableContainer className={classes.tree}>
            {columns?.length && tableRows?.length && (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ backgroundColor: currentComponent?.status }}
                    />
                    {columns.map((col, index) => (
                      <TableCell
                        key={index}
                        width={col?.width ? col?.width : 0}
                        style={{
                          backgroundColor: currentComponent?.status,
                          fontSize: currentComponent?.fontSize
                            ? currentComponent?.fontSize
                            : 16,
                        }}
                      >
                        {col?.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows?.length &&
                    columns?.length &&
                    tableRows.map((row, index) => (
                      <Children
                        key={index}
                        columns={columns}
                        row={row}
                        currentComponent={currentComponent}
                      />
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </>
      ) : (
        <div style={{ background: "#FFFFFF", height: "100%" }}>
          <h1 style={{ margin: 0 }}>Invalid data</h1>
        </div>
      )}
    </>
  );
}
