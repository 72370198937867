import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { VendorServices } from "../../../services/vendorServices";
import { getVendorTeamDetails } from "../../../utils/vendor/vendorUtils";
import { useSnackbar } from "notistack";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import VendorDetailsEntity from "../../../components/common/entitySchema/vendors/VendorDetailsEntity";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const vendorService = new VendorServices();

const VendorDetails = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const isEditMode =
    props.match.path.split("/")[1] === "vendor-details" ? true : false;
  const [vendorID] = useState(props.match.params._id);
  const [vendorTeamDetails, setVendorTeamDetails] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [vendorEntitySchema, setVendorEntitySchema] = useState([]);
  const [vendorDetails, setVendorDetails] = useState("");
  // Entity
  const [vendorData, setVendorData] = useState({});
  const [error, setError] = useState({});
  // Accessing TabbedView

  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/vendors");
  };
  const handleChange = (event) => {
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Vendor", ["Read"]);
  }, []);

  const fetchVendorDetails = async () => {
    try {
      setLoading(true);
      const res_data = await vendorService.getVendorDetailsByID(vendorID);
      const { _status, data } = res_data.data;
      if (_status === 200) {
        setVendorDetails(data);
        setVendorData((prevData) => ({ ...prevData, ...data }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

    }
  };
  useEffect(() => {
    const fetchProjectEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Vendor",
        "read"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setVendorEntitySchema(entity);
        fetchVendorDetails();
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        setVendorData({ ...vendorData, ...projData });
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                return (errData[field.datakey] = {
                  isError: false,
                  fieldLabel: field.fieldLabel,
                  minLength: field.rules?.minLength,
                  maxLength: field.rules?.maxLength,
                  pattern: field.rules?.pattern,
                  msg: "",
                });
              }
            });
          });
        });
        delete errData["serviceGroups"];
        setError(errData);
      } else {

        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
      }
    };

    fetchProjectEntitySchema();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getVendorTeamDetails(vendorID);
      if (_status < 210) {
        setVendorTeamDetails(data ? data : []);
      } else {

      }
    };
    fetchData();
  }, [vendorID]);

  const handleEdit = () => {
    if (vendorDetails.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked Vendor. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      histroy.push(`/edit-vendor/${vendorID}`);
    }
  };
  return (
    <main className={styles.main}>
      {loading && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Vendors", path: "/vendors" },
            { title: `${vendorDetails?.name ? vendorDetails?.name : ""}` },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h2 className={styles.title}>{vendorDetails?.name}</h2>
          {tabbedView ? (
            <TabbedNavigation
              isEditMode={false}
              isClose={true}
              activeTab={activeTab}
              handleEdit={handleEdit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              handleEdit={handleEdit}
              isClose={true}

              handleCancel={handleCancel}
              disabled={!checkCompPermission("Vendor", ["Edit"])}
              isEditMode={true}
            />
          )}
        </Grid>
        <div className={styles.border_line} />
      </div>

      {/* main rendering */}
      <div className={styles.wrapper} style={{top: "6.5rem"}}>
        {tabbedView ? (<div style={{  marginTop: "-20px" }}>
          <SectionTabs
            entity={
              vendorEntitySchema ? vendorEntitySchema : []
            }
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
          <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
            <div style={{ padding: "0px 0px 0" }}>
              <VendorDetailsEntity
                section={vendorEntitySchema?.[activeTab]}
                vendorData={vendorData}
                error={error}
                isEditMode={isEditMode}
                setError={setError}
                setVenderData={setVendorData}
                handleChange={handleChange}
                vendorTeamDetails={vendorTeamDetails}
              />
            </div>
          </Paper>
        </div>) : (vendorEntitySchema &&
          vendorEntitySchema.map((section) => {
            if (section.name === "Vendor_Header_section") {
              return (
                <div key={section._id} style={{ padding: "15px 5px 0" }}>
                  <VendorDetailsEntity
                    section={section}
                    vendorData={vendorData}
                    error={error}
                    isEditMode={isEditMode}
                    setError={setError}
                    setVenderData={setVendorData}
                    handleChange={handleChange}
                    vendorTeamDetails={vendorTeamDetails}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded={true}
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VendorDetailsEntity
                      section={section}
                      vendorData={vendorData}
                      error={error}
                      isEditMode={isEditMode}
                      setError={setError}
                      setVenderData={setVendorData}
                      handleChange={handleChange}
                      vendorTeamDetails={vendorTeamDetails}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          }))}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(VendorDetails);
