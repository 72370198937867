export const ISSUE_DETAILS_REQUEST = "ISSUE_DETAILS_REQUEST";
export const ISSUE_DETAILS_SUCCESS = "ISSUE_DETAILS_SUCCESS";
export const ISSUE_DETAILS_FAIL = "ISSUE_DETAILS_FAIL";
export const ISSUE_FILTERS = "ISSUE_FILTERS ";
export const ISSUE_SORT = "ISSUE_SORT";
export const ISSUE_HIDDEN = "ISSUE_HIDDEN";

// create a new issue
export const NEW_ISSUE_REQUEST = "NEW_ISSUE_REQUEST";
export const NEW_ISSUE_SUCCESS = "NEW_ISSUE_SUCCESS";
export const NEW_ISSUE_FAIL = "NEW_ISSUE_FAIL";