const executionColumns = [
    { name: "name", title: "Execution", for: "service" },
    { name: "deploymentname", title: "Deployment", for: "service" },
    { name: "status", title: "Status", for: "service" },
    { name: "passpercentage", title: "Pass %", for: "service" },
    { name: "action", title: "Action", for: "service" },

  ];
  
  const executionDefaultColumnWidths = [
    { columnName: "name", weightage: 2.5, width: 200 },
    { columnName: "deploymentname", weightage: 2.5, width: 200 },
    { columnName: "status", weightage: 2.5, width: 200 },
    { columnName: "passpercentage", weightage: 2, width: 200 },
    { columnName: "action", weightage: 2.5, width: 200 },
  ];
  
  const executionTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "deploymentname", align: "left" },
    { columnName: "status", align: "left" },
    { columnName: "passpercentage", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
  ];
  
  const executionColumnOrder = [
    "name",
    "deploymentname",
    "status",
    "passpercentage",
    "action"
  ];
  
  const executionColumnHidden = [
    "name",
    "deploymentname",
    "status",
    "passpercentage",
    "action"
  ];
  
  const executionDefaultSorting = [];
  
  export {
    executionColumns,
    executionDefaultColumnWidths,
    executionTableColumnExtensions,
    executionColumnOrder,
    executionDefaultSorting,
    executionColumnHidden
  };
  
