import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const useCheckPermission = () => {
  const { userActions } = useSelector((state) => state.authReducer);
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const checkScreenPermission = (resource, permissions, errMsg) => {
    const resourceObj = userActions && userActions[resource];
    if (!resourceObj?.permissions?.some((r) => permissions.indexOf(r) >= 0)) {
      enqueueSnackbar(
        errMsg
          ? errMsg
          : `You do not have permission to access ${permissions} ${resource}. Please contact your Admin`,
        { variant: "error" }
      );
      histroy.push("/dashboard");
    }
    // let isPermission = false;
    // dummyRoles.map(role => {
    //     const resourceData = role.claims.filter(claim => claim.resourceString === resource)
    //     if (resourceData.length && !isPermission) {
    //         isPermission = resourceData[0].permissions.some(r => permissions.indexOf(r) >= 0)
    //     }
    // });
    // if (!isPermission) {
    //     enqueueSnackbar(errMsg ? errMsg :
    //         `You do not have permission to access ${permissions} ${resource}. Please contact your Admin`,
    //         { variant: "error" });
    //     histroy.push('/dashboard')
    // }
  };

  const checkCompPermission = (resource, permissions) => {
    const resourceObj = userActions && userActions[resource];
    if (resourceObj) {
      return resourceObj.permissions.some((r) => permissions.indexOf(r) >= 0);
    } else {
      return false;
    }
    // let isPermission = false;
    // dummyRoles.map(role => {
    //     const resourceData = role.claims.filter(claim => claim.resourceString === resource)
    //     if (resourceData.length && !isPermission) {
    //         isPermission = resourceData[0].permissions.some(r => permissions.indexOf(r) >= 0)
    //     }
    // });
    // return isPermission
  };

  return { checkScreenPermission, checkCompPermission };
};

export default useCheckPermission;
