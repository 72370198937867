import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import devExpressStyles from "../styles/DevExpressTableStyles";
import { deleteDeploymentByContainer } from "../../../redux/actions/deploymentProcessAction";
import { deleteDeploymentContainer } from "../../../utils/deployment/deploymentUtils";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../modals/DeleteModal";
import Loader from "../stuff/Loader";
import InfoIcon from "@material-ui/icons/Info";
import {
  downloadPostManCollection,
  DownloadPostManCollectionForServiceBundle,
} from "../../../utils/test/testExecutionUtils";
import moment from "moment-timezone";
import useCheckPermission from "../hooks/useCheckPermission";
import ViewAPIModal from "../modals/ViewAPIModal";
import { fetchAPIListData } from "../../../utils/project/microserviceUtils";

const DefaultActionCell = ({ ...restProps }) => {
  const histroy = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedContainer, setDeletedContainer] = useState();
  const [serviceType, setServiceType] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (restProps?.row?.Type === "external") {
      setServiceType(true);
    } else {
      setServiceType(false);
    }
  }, [restProps?.row?.Type]);
  useEffect(() => {
    if (restProps?.row?.Status === "deleted") {
      setDeletedContainer(true);
    }
  });
  const handleDelete = async ({ row }) => {
    setIsLoader(true);
    setDeleteModalOpen(false);
    const { _msg, _status, data } = await deleteDeploymentContainer(
      restProps?.row.DeploymentID,
      restProps?.row.ContainerName
    );
    if (_status === 200) {
      setIsLoader(false);
      setDeleteModalOpen(false);
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(
        deleteDeploymentByContainer(
          restProps.row.DeploymentID,
          restProps.row.ContainerName
        )
      );
    } else {
      setIsLoader(false);
      setDeleteModalOpen(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleNavigateToEdit = () => {
    histroy.push(
      `/host/container/edit2/${restProps.row.DeploymentID}/${restProps.row.ContainerName}`
    );
  };

  function exportToJson(objectData, serializerData) {
    let filename;
    if (restProps.row?.main?.deploymenttype === "Service Bundle") {
      filename = `${serializerData?.ServiceBundleName}_collection`;
    } else {
      filename = `${serializerData?.Version}_collection`;
    }
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const handleDownloadPostmanCollection = async () => {
    if (restProps.row?.main?.deploymenttype === "Service Bundle") {
      const serializerDataServiceBundle = {
        Protocol: "http",
        Host: `${
          restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname
            ? restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
                ?.hostname
            : "localhost"
        }`,
        Port: restProps.row?.Port.toString(),
        ServiceBundleName: restProps.row?.main?.deploymentname,
        ApplicationId: restProps.row?.main?.deptypeid,
      };

      const { res_data } = await DownloadPostManCollectionForServiceBundle(
        serializerDataServiceBundle
      );

      if (res_data?.status === 200) {
        exportToJson(res_data?.data, serializerDataServiceBundle);
      } else {
        enqueueSnackbar(res_data?._msg, { variant: "error" });
      }
    } else {
      const serializerData = {
        Protocol: "http",
        ServiceType:
          restProps.row.ServiceType.charAt(0).toUpperCase() +
          restProps.row.ServiceType.slice(1),
        Host: `${
          restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname
            ? restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
                ?.hostname
            : "localhost"
        }`,
        Port: restProps.row?.Port.toString(),
        Microservice: restProps.row?.ServiceName,
        Version: restProps.row?.ImageName,
        Mid: restProps.row?.Microserviceid,
        Vid: restProps.row.Versionid,
      };

      const { res_data } = await downloadPostManCollection(serializerData);

      if (res_data?.status === 200) {
        exportToJson(res_data?.data, serializerData);
      } else {
        enqueueSnackbar(res_data?._msg, { variant: "error" });
      }
    }
  };

  return isLoader ? (
    <Loader />
  ) : (
    <>
      <div style={{ left: -10, position: "relative" }}>
        {deleteModalOpen && (
          <DeleteModal
            name={restProps.row.ContainerName}
            row={restProps.row}
            open={deleteModalOpen}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        )}
        {serviceType ? (
          <>
            <Tooltip title="Edit" arrow>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                className={styles.actionIcon}
                onClick={handleNavigateToEdit}
              >
                {/* <ViewIcon /> */}
                <img src="/images/editLine.svg" alt="card" />
              </IconButton>
            </Tooltip>

            {/* {!deletedContainer && ( */}
            <Tooltip title="Delete" arrow>
              <IconButton
                color="inherit"
                aria-label="delete"
                edge="start"
                className={styles.actionIcon}
                onClick={handleDeleteModalClickOpen}
                disabled={deletedContainer}
              >
                <img src="/images/deleteLine.svg" alt="card" />
              </IconButton>
            </Tooltip>
            {/* )} */}
          </>
        ) : (
          <> </>
        )}
      </div>
    </>
  );
};

// const DeployingCell = ({ ...restProps }) => {
//   console.log("kknknknkn", restProps, restProps.row.main.deploymenttype);

//   if (
//     restProps?.row?.ContainerName ==
//     restProps?.row?.main?.deployments[0]?.container_name
//   ) {
//     return <>{restProps?.row?.main?.deploymenttype}</>;
//   } else if (
//     restProps?.row?.main?.deploymenttype === "UI Application" &&
//     restProps?.row?.ContainerName ==
//       restProps?.row?.main?.uiappdetails?.container_name
//   ) {
//     return <>{restProps?.row?.main?.deploymenttype}</>;
//   } else return <></>;
// };

const EndPoint = ({ ...restProps }) => {
  const port = restProps.row.Port;
  return restProps?.row?.serdetails?.status?.loadBalancer?.ingress ? (
    <>
      <Tooltip
        title={`http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`}
        placement="bottom-start"
      >
        <a
          href={`http://${restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${port}`}
          target="_blank"
        >
          http://
          {
            restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
              ?.hostname
          }
          :{port}
        </a>
      </Tooltip>
    </>
  ) : (
    ""
  );
};

const Creation = ({ ...restProps }) => {
  let createdTime = null;
  if (restProps?.row?.depdetails?.metadata?.creationTimestamp) {
    createdTime = moment(
      restProps?.row?.depdetails?.metadata?.creationTimestamp
    ).fromNow();
  }

  return createdTime;
};

const StatusCheck = ({ ...restProps }) => {
  return restProps?.row?.Status === "Delete In Progress"
    ? "Delete In Progress"
    : !restProps?.row?.depdetails
    ? "Deleted"
    : restProps?.row?.depdetails?.status?.readyReplicas === undefined
    ? `0/${restProps?.row?.depdetails?.status?.replicas}`
    : `${restProps?.row?.depdetails?.status?.readyReplicas}/${restProps?.row?.depdetails?.status?.replicas}`;
};

const APICell = ({ ...restProps }) => {
  const [APIModal, setAPIModal] = useState(false);
  const [apiData, setApiData] = useState();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  useEffect(() => {
    const getApiDetails = async (restProps) => {
      const { _status, data, _msg } = await fetchAPIListData(
        restProps.row.Microserviceid,
        restProps.row.Versionid
      );
      setApiData(data);
    };
    getApiDetails(restProps);
  }, []);

  const handleClose = () => {
    setAPIModal(false);
  };

  function exportToJson(objectData, serializerData) {
    let filename;
    if (restProps.row?.main?.deploymenttype === "Service Bundle") {
      filename = `${serializerData?.ServiceBundleName}_collection`;
    } else {
      filename = `${serializerData?.Version}_collection`;
    }
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const handleDownloadPostmanCollection = async () => {
    if (restProps.row?.main?.deploymenttype === "Service Bundle") {
      const serializerDataServiceBundle = {
        Protocol: "http",
        Host: `${
          restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname
            ? restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
                ?.hostname
            : "localhost"
        }`,
        Port: restProps.row?.Port.toString(),
        ServiceBundleName: restProps.row?.main?.deploymentname,
        ApplicationId: restProps.row?.main?.deptypeid,
      };

      const { res_data } = await DownloadPostManCollectionForServiceBundle(
        serializerDataServiceBundle
      );

      if (res_data?.status === 200) {
        exportToJson(res_data?.data, serializerDataServiceBundle);
      } else {
        enqueueSnackbar(res_data?._msg, { variant: "error" });
      }
    } else {
      const serializerData = {
        Protocol: "http",
        ServiceType: "Internal",
        Host: `${
          restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname
            ? restProps?.row?.serdetails?.status?.loadBalancer?.ingress[0]
                ?.hostname
            : "localhost"
        }`,
        Port: restProps.row?.Port.toString(),
        Microservice: restProps.row?.ServiceName,
        Version: restProps.row?.ImageName,
        Mid: restProps.row?.Microserviceid,
        Vid: restProps.row.Versionid,
      };

      const { res_data } = await downloadPostManCollection(serializerData);

      if (res_data?.status === 200) {
        exportToJson(res_data?.data, serializerData);
      } else {
        enqueueSnackbar(res_data?._msg, { variant: "error" });
      }
    }
  };
  return (
    <div style={{  display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"}}>
      {restProps.row?.Status !== "deleted" &&
        restProps.row?.serdetails?.status?.loadBalancer?.ingress?.length >
          0 && (
          <>
            <Tooltip title="APIs" arrow>
              <IconButton
                color="inherit"
                aria-label="apis"
                edge="start"
                className={styles.actionIcon}
                onClick={() => setAPIModal(true)}
              >
                {/* <ViewIcon /> */}
                <img src="/images/application_list.svg" alt="card" />
              </IconButton>
            </Tooltip>
            <ViewAPIModal
              open={APIModal}
              handleClose={handleClose}
              setApiData={setApiData}
              restProps={restProps}
              apiData={apiData}
            />

            <Tooltip title="Download Postman Collection" arrow>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                className={styles.actionIcon}
                onClick={handleDownloadPostmanCollection}
              >
                {/* <ViewIcon /> */}
                <img src="/images/postman-icon.svg" alt="card" />
              </IconButton>
            </Tooltip>
          </>
        )}
    </div>
  );
};

const Cell = (props) => {
  const history = useHistory();
  const { column } = props;

  const handleNavigateToDetails = () => {
    history.push(
      `/host/container/detail/${props.row.DeploymentID}/${props.row.ContainerName}`
    );
  };

  if (column.name === "action") {
    return (
      <VirtualTable.Cell {...props}>
        <DefaultActionCell {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "created") {
    return (
      <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
        <Creation {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "Status") {
    return (
      <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatusCheck {...props} />
          &nbsp;&nbsp;&nbsp;
          <Tooltip
            title={props?.row?.depdetails?.status?.conditions?.map((item) => (
              <>
                {item?.message}
                <br />
              </>
            ))}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </VirtualTable.Cell>
    );
  } else if (column.name === "endPoint") {
    return (
      <VirtualTable.Cell {...props}>
        <EndPoint {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "API") {
    return (
      <VirtualTable.Cell {...props}>
        <APICell {...props} />
        
           
              {/* <>
              aadarsh
              </> */}
            
      </VirtualTable.Cell>
    );
  }
  //  else if (column.name === "Deploying") {
  //   return (
  //     <VirtualTable.Cell
  //       {...props}
  //       onClick={() =>
  //         history.push(`/services/details/${props.row.Microserviceid}`)
  //       }
  //     >
  //       <DeployingCell {...props} />
  //     </VirtualTable.Cell>
  //   );
  // }
  else {
    return (
      <VirtualTable.Cell
        {...props}
        onClick={handleNavigateToDetails}
      ></VirtualTable.Cell>
    );
  }
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const DeploymentDetailsListTable = (props) => {
  console.log("lknklnknknk", props);
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(
      props.defaultColumnWidths,
      windowDimensions,
      props.isCustomWidth
    );
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 360
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default DeploymentDetailsListTable;
