import React from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  TableBody,
  Tab,
  Tabs,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Table,
} from "@material-ui/core";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import listStyles from "../../../../screens/CommonStyles/listScreenStyles";

import { connect } from "react-redux";

import FieldRulesSection from "./FieldRulesSection";
import { useSnackbar } from "notistack";
import useCheckPermission from "../../hooks/useCheckPermission";

const CustomFieldGroupsForGlossaryFieldEdit = ({
  section,
  isEditMode,
  fieldData,
  setFieldData,
  error,
  handleChange,
  isClear,
  isTwoTypeFields,
  clearRulesHandler,

  tab,
  handleChangeTab,
  servicesCount,
  apiCount,
  fieldGroupsCount,
  servicesCountArray,
  dataStyle,
}) => {
  const styles = manageStyles();
  const listStyle = listStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission()

  const DropDownSection = ({ field }) => {
    return (
      <Grid item xs={3} sm={3}>
        <label htmlFor="" className={styles.fieldHeading}>
          {field?.fieldLabel}{" "}
          {field?.required === "TRUE" && <span className={styles.red}>*</span>}
        </label>
        <Select
          onChange={(e) => {
            setFieldData({
              ...fieldData,
              [field.datakey]: e.target.value,
            });
          }}
          displayEmpty
          value={fieldData[field.datakey]}
          className={`${styles.select} ${error[field.datakey]?.isError && styles.error
            } ${(!isEditMode || field.editable === "FALSE") && styles.editMode}`}
          disabled={!isEditMode || field.editable === "FALSE"}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {JSON.parse(field?.values)?.map((item) => (
            <MenuItem value={item} className={styles.fieldHeading2}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {error[field.datakey]?.isError && (
          <p className={styles.errorMsg} style={{ marginTop: "3px" }}>
            <span style={{ textTransform: "capitalize" }}>
              {field.fieldLabel}
            </span>{" "}
            is required
          </p>
        )}
      </Grid>
    );
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Glossary_Field_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.uiElementType) {
                        case "Dropdown":
                          return <DropDownSection field={field} />;
                        case "DropDown":
                          return <DropDownSection field={field} />;
                        case "Rules":
                          return (
                            <FieldRulesSection
                              field={field}
                              setFieldData={setFieldData}
                              fieldData={fieldData}
                              isClear={isClear}
                              isTwoTypeFields={isTwoTypeFields}
                              clearRulesHandler={clearRulesHandler}
                              error={error}
                              isEditMode={isEditMode}
                            />
                          );
                        case "custom":
                          return field.name === "Field Rules" ? (
                            <FieldRulesSection
                              field={field}
                              setFieldData={setFieldData}
                              fieldData={fieldData}
                              isClear={isClear}
                              isTwoTypeFields={isTwoTypeFields}
                              clearRulesHandler={clearRulesHandler}
                              error={error}
                              isEditMode={isEditMode}
                            />
                          ) : field.name === "Field Assigned Elements" &&
                            !isEditMode ? (
                            <div
                              style={{ padding: "20px 15px", width: "100%" }}
                            >
                              {/* <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{
                                    font: "normal normal 600 16px/22px Nunito",
                                    marginBottom: "17px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <label
                                    htmlFor=""
                                    className={styles.modalElement}
                                  >
                                    Assigned Data Elements
                                  </label>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={9}
                                  className={styles.modaltab}
                                >
                                  {
                                    <Tabs
                                      value={tab}
                                      onChange={handleChangeTab}
                                      indicatorColor="primary"
                                      textColor="primary"
                                      className={
                                        listStyle.dataStandardsAccessTab
                                      }
                                    >
                                      <Tab
                                        label={`Services (${servicesCount})`}
                                        className={`${tab === 0 ? "toggleActive" : ""
                                          } ${listStyle.toggletab}`}
                                      />
                                      <Tab
                                        label={`APIs (${apiCount})`}
                                        className={`${tab === 1 ? "toggleActive" : ""
                                          } ${listStyle.toggletab}`}
                                      />
                                      <Tab
                                        label={`Payloads (${fieldGroupsCount})`}
                                        className={`${tab === 2 ? "toggleActive" : ""
                                          } ${listStyle.toggletab}`}
                                      />
                                    </Tabs>
                                  }
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={3}
                                style={{ marginTop: "20px" }}
                              >
                                {tab === 0 && (
                                  <TableContainer
                                    className={styles.modalTableContainer}
                                    style={{ height: "auto" }}
                                  >
                                    <Table
                                      stickyHeader
                                      className={styles.modalTable}
                                    >
                                      <TableHead className={dataStyle.bgColor}>
                                        <TableRow>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            Service Name
                                          </TableCell>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            Service Description
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {servicesCount && servicesCountArray ? (
                                          servicesCountArray?.map(
                                            (microItem) => (
                                              <TableRow>
                                                <TableCell>
                                                  {microItem.name}
                                                </TableCell>
                                                <TableCell>
                                                  {microItem.description}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )
                                        ) : (
                                          <TableRow>
                                            <TableCell>
                                              No data assigned
                                            </TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                                {tab === 1 && (
                                  <TableContainer
                                    style={{ height: "auto" }}
                                    className={styles.modalTableContainer}
                                  >
                                    <Table
                                      stickyHeader
                                      className={styles.modalTable}
                                    >
                                      <TableHead className={dataStyle.bgColor}>
                                        <TableRow>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            API Name
                                          </TableCell>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            Path URL
                                          </TableCell>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            API Method
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {apiCount ? (
                                          fieldData?.apidetails?.map((item) => (
                                            <TableRow>
                                              <TableCell>
                                                {item?.name}
                                              </TableCell>
                                              <TableCell>
                                                {item?.path}
                                              </TableCell>
                                              <TableCell>
                                                {item?.method}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        ) : (
                                          <TableRow>
                                            <TableCell>
                                              No data assigned
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                                {tab === 2 && (
                                  <TableContainer
                                    style={{ height: "auto" }}
                                    className={styles.modalTableContainer}
                                  >
                                    <Table
                                      stickyHeader
                                      className={styles.modalTable}
                                    >
                                      <TableHead className={dataStyle.bgColor}>
                                        <TableRow>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            Payload Name
                                          </TableCell>
                                          <TableCell
                                            className={`${dataStyle.bgColor}`}
                                          >
                                            Type
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {fieldGroupsCount ? (
                                          fieldData?.fieldgroups?.map(
                                            (item) => (
                                              <TableRow>
                                                <TableCell>
                                                  {item.name}
                                                </TableCell>
                                                <TableCell>
                                                  {item.type}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )
                                        ) : (
                                          <TableRow>
                                            <TableCell>
                                              No data assigned
                                            </TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </Grid> */}
                            </div>
                          ) : field.name === "Field Status" ? (
                            <Grid item xs={3} sm={3}>
                              <FormControl>
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    className={
                                      listStyle.dataStandardFormControlLabel
                                    }
                                    value="top"
                                    control={
                                      <Switch
                                        checked={fieldData.status === "active"}
                                        color="primary"
                                        onChange={(e) => {
                                          if (!checkCompPermission("GlossaryField", ["Block Or Unblock"])) {
                                            enqueueSnackbar(
                                              `You do not have permission to access Block Or Unblock in GlossaryField. Please contact your Admin.`,
                                              { variant: "error" }
                                            );
                                          } else {
                                            setFieldData({
                                              ...fieldData,
                                              status:
                                                fieldData.status === "inactive"
                                                  ? "active"
                                                  : "inactive",
                                            });
                                          }
                                        }}
                                        disabled={
                                          !isEditMode ||
                                          field.editable === "FALSE"
                                        }
                                      />
                                    }
                                    label={field?.fieldLabel || "In Use"}
                                    labelPlacement="top"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          ) : (
                            <></>
                          );
                        default:
                          return field.name === "Created on" ||
                            field.datakey === "createdon" ||
                            field.name === "Created by" ||
                            field.datakey === "createdby" ||
                            field.name === "Updated on" ||
                            field.datakey === "updatedon" ||
                            field.name === "Updated by" ||
                            field.datakey === "updatedby" ||
                            field.name ===
                            (isTwoTypeFields > 1 ? "Field Type" : " ") ? (
                            <></>
                          ) : field.name === "Field Status" ? (
                            <Grid item xs={3} sm={3}>
                              <FormControl>
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    className={
                                      listStyle.dataStandardFormControlLabel
                                    }
                                    value="top"
                                    control={
                                      <Switch
                                        checked={fieldData.status === "active"}
                                        color="primary"
                                        onChange={(e) => {
                                          if (!checkCompPermission("GlossaryField", ["Block Or Unblock"])) {
                                            enqueueSnackbar(
                                              `You do not have permission to access Block Or Unblock in GlossaryField. Please contact your Admin.`,
                                              { variant: "error" }
                                            );
                                          } else {
                                            setFieldData({
                                              ...fieldData,
                                              status:
                                                fieldData.status === "inactive"
                                                  ? "active"
                                                  : "inactive",
                                            });
                                          }
                                        }}
                                        disabled={
                                          !isEditMode ||
                                          field.editable === "FALSE"
                                        }
                                      />
                                    }
                                    label={field?.fieldLabel || "In Use"}
                                    labelPlacement="top"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          ) : (
                            <Grid item xs={3} sm={3} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <input
                                name={field.datakey}
                                value={fieldData[field.datakey]}
                                onChange={handleChange}
                                placeholder={field.placeholder || " "}
                                className={`${styles.input} ${!isEditMode && styles.editMode
                                  } ${error[field.datakey]?.isError && styles.error
                                  } ${field.editable === "FALSE" && styles.editMode
                                  }`}
                                disabled={
                                  !isEditMode || field.editable === "FALSE"
                                }
                                autoComplete="off"
                                type="text"
                              />
                              {error[field.datakey]?.isError && (
                                <p className={styles.errorMsg}>
                                  {error[field.datakey]?.isError.msg ? (
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {error[field.datakey]?.isError.msg}
                                    </span>
                                  ) : (
                                    <>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {field.fieldLabel}
                                      </span>{" "}
                                      is required
                                    </>
                                  )}
                                </p>
                              )}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.uiElementType) {
                              case "Dropdown":
                                return <DropDownSection field={field} />;
                              case "DropDown":
                                return <DropDownSection field={field} />;
                              case "Rules":
                                return (
                                  <FieldRulesSection
                                    field={field}
                                    setFieldData={setFieldData}
                                    fieldData={fieldData}
                                    isClear={isClear}
                                    isTwoTypeFields={isTwoTypeFields}
                                    clearRulesHandler={clearRulesHandler}
                                    isEditMode={isEditMode}
                                    error={error}
                                  />
                                );
                              case "custom":
                                return field.name === "Field Rules" ? (
                                  <FieldRulesSection
                                    field={field}
                                    setFieldData={setFieldData}
                                    fieldData={fieldData}
                                    isClear={isClear}
                                    isTwoTypeFields={isTwoTypeFields}
                                    clearRulesHandler={clearRulesHandler}
                                    error={error}
                                    isEditMode={isEditMode}
                                  />
                                ) : field.name === "Field Assigned Elements" &&
                                  !isEditMode ? (
                                  <div
                                    style={{
                                      padding: "20px 15px",
                                      width: "100%",
                                    }}
                                  >
                                    {/* <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          font: "normal normal 600 16px/22px Nunito",
                                          marginBottom: "17px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <label
                                          htmlFor=""
                                          className={styles.modalElement}
                                        >
                                          Assigned Data Elements
                                        </label>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={9}
                                        className={styles.modaltab}
                                      >
                                        {
                                          <Tabs
                                            value={tab}
                                            onChange={handleChangeTab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            className={
                                              listStyle.dataStandardsAccessTab
                                            }
                                          >
                                            <Tab
                                              label={`Services (${servicesCount})`}
                                              className={`${tab === 0 ? "toggleActive" : ""
                                                } ${listStyle.toggletab}`}
                                            />
                                            <Tab
                                              label={`APIs (${apiCount})`}
                                              className={`${tab === 1 ? "toggleActive" : ""
                                                } ${listStyle.toggletab}`}
                                            />
                                            <Tab
                                              label={`Payloads (${fieldGroupsCount})`}
                                              className={`${tab === 2 ? "toggleActive" : ""
                                                } ${listStyle.toggletab}`}
                                            />
                                          </Tabs>
                                        }
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ marginTop: "20px" }}
                                    >
                                      {tab === 0 && (
                                        <TableContainer
                                          className={styles.modalTableContainer}
                                          style={{ height: "auto" }}
                                        >
                                          <Table
                                            stickyHeader
                                            className={styles.modalTable}
                                          >
                                            <TableHead
                                              className={dataStyle.bgColor}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  Service Name
                                                </TableCell>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  Service Description
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {servicesCount &&
                                                servicesCountArray ? (
                                                servicesCountArray?.map(
                                                  (microItem) => (
                                                    <TableRow>
                                                      <TableCell>
                                                        {microItem.name}
                                                      </TableCell>
                                                      <TableCell>
                                                        {microItem.description}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )
                                              ) : (
                                                <TableRow>
                                                  <TableCell>
                                                    No data assigned
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                </TableRow>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      )}
                                      {tab === 1 && (
                                        <TableContainer
                                          style={{ height: "auto" }}
                                          className={styles.modalTableContainer}
                                        >
                                          <Table
                                            stickyHeader
                                            className={styles.modalTable}
                                          >
                                            <TableHead
                                              className={dataStyle.bgColor}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  API Name
                                                </TableCell>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  Path URL
                                                </TableCell>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  API Method
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {apiCount ? (
                                                fieldData?.apidetails?.map(
                                                  (item) => (
                                                    <TableRow>
                                                      <TableCell>
                                                        {item?.name}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item?.path}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item?.method}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )
                                              ) : (
                                                <TableRow>
                                                  <TableCell>
                                                    No data assigned
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                  <TableCell></TableCell>
                                                </TableRow>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      )}
                                      {tab === 2 && (
                                        <TableContainer
                                          style={{ height: "auto" }}
                                          className={styles.modalTableContainer}
                                        >
                                          <Table
                                            stickyHeader
                                            className={styles.modalTable}
                                          >
                                            <TableHead
                                              className={dataStyle.bgColor}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  Payload Name
                                                </TableCell>
                                                <TableCell
                                                  className={`${dataStyle.bgColor}`}
                                                >
                                                  Type
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {fieldGroupsCount ? (
                                                fieldData?.fieldgroups?.map(
                                                  (item) => (
                                                    <TableRow>
                                                      <TableCell>
                                                        {item.name}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.type}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )
                                              ) : (
                                                <TableRow>
                                                  <TableCell>
                                                    No data assigned
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                </TableRow>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      )}
                                    </Grid> */}
                                  </div>
                                ) : field.name === "Field Status" ? (
                                  <Grid item xs={3} sm={3}>
                                    <FormControl>
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          className={
                                            listStyle.dataStandardFormControlLabel
                                          }
                                          value="top"
                                          control={
                                            <Switch
                                              checked={
                                                fieldData.status === "active"
                                              }
                                              color="primary"
                                              onChange={(e) => {
                                                if (!checkCompPermission("GlossaryField", ["Block Or Unblock"])) {
                                                  enqueueSnackbar(
                                                    `You do not have permission to access Block Or Unblock in GlossaryField. Please contact your Admin.`,
                                                    { variant: "error" }
                                                  );
                                                } else {
                                                  setFieldData({
                                                    ...fieldData,
                                                    status:
                                                      fieldData.status === "inactive"
                                                        ? "active"
                                                        : "inactive",
                                                  });
                                                }
                                              }}
                                              disabled={
                                                !isEditMode ||
                                                field.editable === "FALSE"
                                              }
                                            />
                                          }
                                          label={field?.fieldLabel || "In Use"}
                                          labelPlacement="top"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid>
                                ) : (
                                  <></>
                                );
                              default:
                                return field.name === "Created on" ||
                                  field.datakey === "createdon" ||
                                  field.name === "Created by" ||
                                  field.datakey === "createdby" ||
                                  field.name === "Updated on" ||
                                  field.datakey === "updatedon" ||
                                  field.name === "Updated by" ||
                                  field.datakey === "updatedby" ||
                                  field.name ===
                                  (isTwoTypeFields > 1
                                    ? "Field Type"
                                    : " ") ? (
                                  <></>
                                ) : field.name === "Field Status" ? (
                                  <Grid item xs={3} sm={3}>
                                    <FormControl>
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          className={
                                            listStyle.dataStandardFormControlLabel
                                          }
                                          value="top"
                                          control={
                                            <Switch
                                              checked={
                                                fieldData.status === "active"
                                              }
                                              color="primary"
                                              onChange={(e) => {
                                                if (!checkCompPermission("GlossaryField", ["Block Or Unblock"])) {
                                                  enqueueSnackbar(
                                                    `You do not have permission to access Block Or Unblock in GlossaryField. Please contact your Admin.`,
                                                    { variant: "error" }
                                                  );
                                                } else {
                                                  setFieldData({
                                                    ...fieldData,
                                                    status:
                                                      fieldData.status === "inactive"
                                                        ? "active"
                                                        : "inactive",
                                                  });
                                                }
                                              }}
                                              disabled={
                                                !isEditMode ||
                                                field.editable === "FALSE"
                                              }
                                            />
                                          }
                                          label={field?.fieldLabel || "In Use"}
                                          labelPlacement="top"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid>
                                ) : (
                                  <Grid item xs={3} sm={3} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      name={field.datakey}
                                      value={fieldData[field.datakey]}
                                      onChange={handleChange}
                                      placeholder={field.placeholder || " "}
                                      className={`${styles.input} ${!isEditMode && styles.editMode
                                        } ${error[field.datakey]?.isError &&
                                        styles.error
                                        } ${field.editable === "FALSE" &&
                                        styles.editMode
                                        }`}
                                      disabled={
                                        !isEditMode ||
                                        field.editable === "FALSE"
                                      }
                                      autoComplete="off"
                                      type="text"
                                    />
                                    {error[field.datakey]?.isError && (
                                      <p className={styles.errorMsg}>
                                        {error[field.datakey]?.isError.msg ? (
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {error[field.datakey]?.isError.msg}
                                          </span>
                                        ) : (
                                          <>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {field.fieldLabel}
                                            </span>{" "}
                                            is required
                                          </>
                                        )}
                                      </p>
                                    )}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}{" "}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={fieldData[field.datakey]}
                                  onChange={handleChange}
                                  placeholder={field.placeholder || " "}
                                  className={`${styles.textarea} ${!isEditMode && styles.editMode
                                    } ${error[field.datakey]?.isError && styles.error
                                    } ${field.editable === "FALSE" &&
                                    styles.editMode
                                    }`}
                                  disabled={
                                    !isEditMode || field.editable === "FALSE"
                                  }
                                  rows="7"
                                />
                                {error[field.datakey]?.isError && (
                                  <p className={styles.errorMsg}>
                                    {error[field.datakey]?.isError.msg ? (
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {error[field.datakey]?.isError.msg}
                                      </span>
                                    ) : (
                                      <>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {field.fieldLabel}
                                        </span>{" "}
                                        is required
                                      </>
                                    )}
                                  </p>
                                )}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForGlossaryFieldEdit);
