import React, { useEffect } from "react";
import { useState } from "react";
import layoutStyles from "../../../styles/commonStyles/Layout";
import Search from "../../atoms/search/Search";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import ActionStyles from "./ActionStyles";
import { AppButton } from "../../atoms/button/AppButton";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";
import {
  addAction,
  deleteAction,
  editUiApplication,
} from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";
import { DeleteModal } from "../../../../components/common/modals/DeleteModal";
import { UiApplicationService } from "../../../services/UiApplicationService";
const ActionBar = () => {
  const apiService = new UiApplicationService();
  const styles = layoutStyles();
  const classes = ActionStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentProject } = useGetData();
  const [searchAction, setSearchAction] = useState("");
  const [actionLength, setActionLength] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteActionName, setDeleteActionName] = useState();
  const [deleteActionId, setDeleteActionId] = useState();
  const [currentAddedAction, setCurrentAddedAction] = useState([]);

  const handleOnClickDeleteAction = async (actionId) => {
    try {
      const resp = await apiService.deleteActionById(
        currentProject?.id,
        actionId
      );
      if (resp?.status === 200) {
        statusSnackBar(resp?.data?._msg, "success");
      }
    } catch (err) {
      statusSnackBar(err?.response?.data?._msg, "error");
    }
  };

  const statusSnackBar = (msg, statusVariant) => {
    enqueueSnackbar(msg, {
      variant: statusVariant,
    });
  };

  const generateUniqueActionName = (name, num) => {
    if (currentProject?.actions?.some((ele) => ele?.name === `${name}${num}`)) {
      return generateUniqueActionName(name, +num + 1);
    } else {
      return `${name}${num}`;
    }
  };

  const handleAddAction = async () => {
    const newActionReqData = {
      service: {},
      api: {},
      application: {},
      process: {},
      config: { headers: [], queryParams: [], pathParams: [], body: [] },
      name: generateUniqueActionName("action", +actionLength + 1),
      actionType: "service",
      data: {},
      isActive: false,
      modifyResults: "return {{data}}",
    };
    try {
      const resp = await apiService.addAction(
        currentProject?.id,
        newActionReqData
      );
      if (resp?.status === 201) {
        dispatch(
          addAction({
            data: resp?.data?.data,
          })
        );
        setCurrentAddedAction([resp?.data?.data]);
        statusSnackBar(resp?.data?._msg, "success");
      }
    } catch (err) {
      statusSnackBar(err?.response?.data?._msg, "error");
    }
  };

  useEffect(() => {
    if (currentAddedAction?.length === 1) handleClick(currentAddedAction[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddedAction]);

  const handleActionActivation = async (activeAction, action) => {
    try {
      const activeActionResp = await apiService.updateAction(
        currentProject?.id,
        activeAction._id,
        { ...activeAction, isActive: false }
      );
      const actionResp = await apiService.updateAction(
        currentProject?.id,
        action._id,
        { ...action, isActive: true }
      );
    } catch (err) {
      statusSnackBar(err?.response?.data?._msg, "error");
    }
  };

  const handleClick = (val) => {
    const activeAction = currentProject?.actions.find(
      (action) => action.isActive
    );
    const actions = currentProject?.actions.map((action) => {
      if (action._id === val._id) {
        return {
          ...action,
          isActive: true,
        };
      } else {
        return {
          ...action,
          isActive: false,
        };
      }
    });
    handleActionActivation(activeAction, val);
    dispatch(editUiApplication({ data: { actions: actions } }));
  };

  useEffect(() => {
    setActionLength(
      currentProject?.actions ? currentProject?.actions?.length : 1
    );
  }, [currentProject?.actions]);

  const handleClickOpen = (e, value, actionId) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
    setDeleteActionName(value);
    setDeleteActionId(actionId);
  };

  const handleClose = () => setOpenDeleteModal(false);

  const handleDeleteAction = (e) => {
    e.stopPropagation();
    handleOnClickDeleteAction(deleteActionId);
    dispatch(deleteAction({ actionId: deleteActionId }));
    setOpenDeleteModal(false);
  };

  return (
    <div
      className={styles.leftbar}
      style={{ borderRight: "1px solid #F0F0F0" }}
    >
      {/* <Grid container direction="column" className="pages"> */}
      <Grid className="pages">
        <Grid item style={{ margin: 8 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <p
                className={"title"}
              >{`Actions (${currentProject?.actions.length})`}</p>
            </Grid>
            <Grid item>
              <AppButton
                buttonName="Add"
                variant="outlined"
                startIcon={<AddOutlinedIcon />}
                className="btnsmall"
                style={{ minWidth: "70px" }}
                onClick={() => handleAddAction()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="search">
          <Search
            value={searchAction}
            onChange={(e) => setSearchAction(e.target.value)}
            placeholder={"Search action"}
          />
        </Grid>
        {currentProject?.actions
          .filter((action) => (action?.name).includes(searchAction))
          .map((action, index) => (
            <Grid item key={index}>
              <Grid
                container
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className={
                  action.isActive ? classes.active : classes.actionItem
                }
                onClick={() => handleClick(action)}
              >
                <Grid item>
                  <h2 className={classes.actionItem} style={{ marginLeft: 10 }}>
                    {action?.name}
                  </h2>
                </Grid>
                <Grid item>
                  <Tooltip title="Delete Action">
                    <IconButton
                      onClick={(e) =>
                        handleClickOpen(e, action?.name, action?._id)
                      }
                      disabled={action?.isActive === true}
                    >
                      <img src="/img/delete-bin-7-line.svg" alt="Delete" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {openDeleteModal && (
        <DeleteModal
          name={deleteActionName}
          open={openDeleteModal}
          handleClose={handleClose}
          handleDelete={handleDeleteAction}
        />
      )}
    </div>
  );
};

export default ActionBar;
