import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Paper,
} from "@material-ui/core";
import React, { useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useEffect } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import {
  fetchFieldGroupDetails,
  fetchFieldGroupListData,
} from "../../../utils/project/fieldgroupUtils";
import { useSnackbar } from "notistack";
import { addAPI } from "../../../utils/project/microserviceUtils";
import {
  arrayToJson,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForApiCreate from "../../../components/common/entitySchema/api/CustomFieldGroupsForApiCreate";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { tabbedActiveTabAction } from "../../../redux/actions/serviceAction";

const AddAPI = (props) => {
  const { setCreateAPI } = props;
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );
  const { tabbedView } = useSelector((state) => state.userInfoReducer);
  const mid = isTabbedApproach ? props.serviceId : props.match.params.serviceId;
  const vid = isTabbedApproach ? props.versionId : props.match.params.versionId;
  const [serviceId] = useState(mid);
  const [versionId] = useState(vid);
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [fieldGroupDetailsForInput, setFieldGroupDetailsForInput] = useState(
    {}
  );
  const [fieldGroupDetailsForOutput, setFieldGroupDetailsForOutput] = useState(
    {}
  );
  const [headers, setHeaders] = useState([
    { id: uuidv4(), key: "", value: "" },
  ]);
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState(false);
  const [apiEntitySchema, setApiEntitySchema] = useState([]);
  const [filteredSchema, setFilteredSchema] = useState([]);

  const [apiData, setApiData] = useState({
    name: "",
    description: "",
    method: "POST",
    path: "",
    headers: {},
    input: "",
    output: "",
    queryParameters: [""],
    pathParameters: [""],
    type: "rest",
    topic: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
    type: { isError: false },
  });
  const handleCancelAccordion = () => {

    histroy.push(`/services/edit/${serviceId}`);
  };
  const search = useLocation().search;



  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission('Version', ['Create API'])

    const fetchAPIEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "API",
        "create"
      );
      setIsLoader(true);
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setApiEntitySchema(entity);
        // setFilteredSchema(entity);
        setIsLoader(false);
        const apData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (apData[field.datakey] = "");
              }
            });
          });
        });

        setApiData((prevData) => ({
          ...prevData,
          ...apData,
          type: "rest",
          method: "POST",
        }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["parameter"];
        delete errData["topic"];
        delete errData["sys_broker"];

        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const getFieldGroups = async () => {
      const { _msg, _status, data } = await fetchFieldGroupListData();
      if (_status === 200) {
        setFieldGroups(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchAPIEntitySchema()
    if (checkCompPermission('GlossaryFieldGroup', ['List All'])) {
      getFieldGroups();
    }
  }, []);

  const getFieldGroupDetailsForInput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {
      setFieldGroupDetailsForInput(data);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const getFieldGroupDetailsForOutput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {
      setFieldGroupDetailsForOutput(data);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (checkCompPermission("GlossaryFieldGroup", ["Read"])) {
      getFieldGroupDetailsForInput(apiData.input);
    }
  }, [apiData.input]);

  useEffect(() => {
    if (checkCompPermission("GlossaryFieldGroup", ["Read"])) {
      getFieldGroupDetailsForOutput(apiData.output);
    }
  }, [apiData.output]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "type") {
      if (event.target.value === "rest") {
        const tempError = error;
        delete tempError["topic"];
        delete tempError["sys_broker"];
        tempError["path"] = {
          isError: false,
          fieldLabel: "Path",
          dataType: "string",
          minLength: 1,
          maxLength: 300,
          pattern: "",
          msg: "",
        };
        tempError["method"] = {
          isError: false,
          fieldLabel: "Method",
          dataType: "string",
          minLength: 1,
          maxLength: 300,
          pattern: "",
          msg: "",
        };
        setError(tempError);
      } else {
        const tempError = error;
        delete tempError["path"];
        delete tempError["method"];
        tempError["topic"] = {
          isError: false,
          fieldLabel: "Topic",
          dataType: "string",
          minLength: 1,
          maxLength: 300,
          pattern: "",
          msg: "",
        };
        if (props.location?.state?.serviceType === "External") {
          tempError["sys_broker"] = {
            isError: false,
            fieldLabel: "Broker",
            dataType: "string",
            minLength: 1,
            maxLength: 300,
            pattern: "",
            msg: "",
          };
        }
        setError(tempError);
      }
    }
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setApiData({
      ...apiData,
      [event.target.name]: value,
    });
  };

  const deleteQueryParameter = (index) => {
    apiData.queryParameters.splice(index, 1);
    setApiData({ ...apiData, queryParameters: apiData.queryParameters });
  };

  const deletePathParameter = (index) => {
    apiData.pathParameters.splice(index, 1);
    setApiData({ ...apiData, pathParameters: apiData.pathParameters });
  };

  const handleSubmit = async () => {
    // not being able to create a newe api due to extra fields coming in 
    // if(Object.keys(apiData).includes('broker_address'))  
    // {

    //   delete apiData.broker_address
    //   delete error.broker_address
    // }
    // if(Object.keys(apiData).includes('broker_method'))  {

    //   delete apiData.broker_method
    //   delete error.broker_method
    // }
    const { formIsValid, errors } = handleFormValidationPro(apiData, error);
    if (formIsValid) {
      const newApiData = {
        ...apiData,
        headers: arrayToJson(headers),
        input: { fieldGroupid: apiData.input },
        output: { fieldGroupid: apiData.output },
        queryParameters: apiData.queryParameters[0]
          ? apiData.queryParameters
          : [],
        pathParameters: apiData.pathParameters[0] ? apiData.pathParameters : [],
      };
      if (apiData.type !== "rest") {
        newApiData["topic"] = apiData.topic;
        delete newApiData["method"];
        delete newApiData["path"];
        delete newApiData["pathParameters"];
        delete newApiData["queryParameters"];
        delete newApiData["input"];
        delete newApiData["output"];
        delete newApiData["headers"];

        if (props.location?.state?.serviceType === "External") {
          newApiData["config"] = {};
          for (const data in apiData) {
            if (
              apiData.type === "kafka" &&
              data.split("_")[0] === "sys" &&
              data.split("_")[1] === "kafka" &&
              apiData[data]?.length !== 0
            ) {
              if (data === "sys_kafka_partition") {
                newApiData["config"][data] = parseInt(apiData[data]);
              } else {
                newApiData["config"][data] = apiData[data];
              }
            } else if (
              apiData.type === "rabbitmq" &&
              data.split("_")[0] === "sys" &&
              data.split("_")[1] === "rabbitmq" &&
              apiData[data]?.length !== 0
            ) {
              newApiData["config"][data] = apiData[data];
            } else if (
              apiData.type === "mqtt" &&
              data.split("_")[0] === "sys" &&
              data.split("_")[1] === "mqtt" &&
              apiData[data]?.length !== 0
            ) {
              newApiData["config"][data] = apiData[data];
            }
          }
          if (Object.keys(newApiData.config).length === 0) {
            delete newApiData["config"];
          }
        }
      } else {
        delete newApiData["topic"];
        delete newApiData["config"];
      }

      setIsLoader(true);
      const { _msg, _status, data } = await addAPI(
        serviceId,
        versionId,
        newApiData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        if (isTabbedApproach) {
          setCreateAPI(false);
        }
        setIsLoader(false);
        histroy.push(`/services/details/${serviceId}`);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        filteredSchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      // setApiEntitySchema(tempEntity);
      setFilteredSchema(tempEntity);
      setError(errors);
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  useEffect(() => {
    setFilteredSchema(apiEntitySchema);
    if (apiData.type === "rest") {
      const filterSections = apiEntitySchema.filter(
        (section) =>
          section.name !== "sys_kafka_config" &&
          section.name !== "sys_rabbitmq_config" &&
          section.name !== "sys_mqtt_config"
      );
      setFilteredSchema(filterSections);
    } else if (apiData.type === "kafka") {
      const filterSections = apiEntitySchema.filter(
        (section) =>
          section.name !== "API_Parameters_Section" &&
          section.name !== "sys_rabbitmq_config" &&
          section.name !== "sys_mqtt_config"
      );
      setFilteredSchema(filterSections);
    } else if (apiData.type === "rabbitmq") {
      const filterSections = apiEntitySchema.filter(
        (section) =>
          section.name !== "API_Parameters_Section" &&
          section.name !== "sys_kafka_config" &&
          section.name !== "sys_mqtt_config"
      );
      setFilteredSchema(filterSections);
    } else if (apiData.type === "mqtt") {
      const filterSections = apiEntitySchema.filter(
        (section) =>
          section.name !== "API_Parameters_Section" &&
          section.name !== "sys_kafka_config" &&
          section.name !== "sys_rabbitmq_config"
      );
      setFilteredSchema(filterSections);
    } else {
      const filterSections = apiEntitySchema;
    }
  }, [apiData.type, apiEntitySchema]);
  const handleCancel = () => {
    setCreateAPI(false);
    dispatch(tabbedActiveTabAction(5));
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        {isTabbedApproach ? (
          <div className={styles.wrapper}>
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{
                borderBottom: "2px solid #afafaf",
                marginTop: isTabbedApproach ? "-200px" : "",
              }}
            >
              <h2 className={styles.heading}>Create API</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TabbedNavigation
                  isEditMode={true}
                  handleSave={handleSubmit}
                  handleCancel={handleCancel}
                  isCreateMode={true}
                />
              </div>
            </Grid>
          </div>
        ) : (
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                {
                  title: "Service",
                  path: "/services",
                },
                {
                  title: props.location?.state?.serviceName,
                  path: `/services/details/${serviceId}`,
                },
                { title: "Create API" },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>Create API</h2>
              <AccordionNavigation
                isCreateMode={true}
                disabled={false}
                isEditMode={false}
                handleSave={handleSubmit}
                handleCancel={handleCancelAccordion}
              />
              {/* <AppButton
                color="primary"
                buttonName="Add"
                variant="contained"
                disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={handleSubmit}
              /> */}
            </Grid>
          </div>
        )}
        {isTabbedApproach ? (
          // <div className={styles.wrapper}>
          <div style={{ padding: "0px 20px" }}>
            <SectionTabs
              entity={filteredSchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: "15px 20px" }}>
              <CustomFieldGroupsForApiCreate
                section={
                  filteredSchema.length !== 0
                    ? filteredSchema[activeTab]
                    : apiEntitySchema[activeTab]
                }
                apiData={apiData}
                error={error}
                tab={tab}
                tabbedView={isTabbedApproach}
                fieldGroups={fieldGroups}
                fieldGroupDetailsForInput={fieldGroupDetailsForInput}
                fieldGroupDetailsForOutput={fieldGroupDetailsForOutput}
                headers={headers}
                setHeaders={setHeaders}
                deletePathParameter={deletePathParameter}
                deleteQueryParameter={deleteQueryParameter}
                handleTabChange={handleTabChange}
                setError={setError}
                setApiData={setApiData}
                handleChange={handleChange}
              />
            </Paper>
          </div>
        ) : (
          <div className={styles.wrapper}>
            {apiEntitySchema &&
              filteredSchema.length !== 0 &&
              filteredSchema.map((section) => {
                if (section.name === "API_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupsForApiCreate
                        section={section}
                        apiData={apiData}
                        error={error}
                        setError={setError}
                        setApiData={setApiData}
                        handleChange={handleChange}
                        serviceType={props.location?.state?.serviceType}
                      />
                    </div>
                  );
                } else {
                  return (
                    <>
                      {/* {((apiData.type === "rest" && section.name === "API_Parameters_Section") ||
                      (apiData.type === "kafka" && section.name === "sys_kafka_config" && props.location?.state?.serviceType === "External") ||
                      (apiData.type === "rabbitmq" && section.name === "sys_rabbitmq_config" && props.location?.state?.serviceType === "External") ||
                      (apiData.type === "mqtt" && section.name === "sys_mqtt_config" && props.location?.state?.serviceType === "External")) && */}
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupsForApiCreate
                            section={section}
                            apiData={apiData}
                            error={error}
                            tab={tab}
                            fieldGroups={fieldGroups}
                            fieldGroupDetailsForInput={
                              fieldGroupDetailsForInput
                            }
                            fieldGroupDetailsForOutput={
                              fieldGroupDetailsForOutput
                            }
                            headers={headers}
                            setHeaders={setHeaders}
                            deletePathParameter={deletePathParameter}
                            deleteQueryParameter={deleteQueryParameter}
                            handleTabChange={handleTabChange}
                            setError={setError}
                            setApiData={setApiData}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                }
              })}
          </div>
        )}
      </form>
    </main>
  );
};

export default AddAPI;
