/* eslint-disable */
import {
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  FilledInput,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listStyles from "../../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import OntologyTable from "../../../components/common/Tables/OntologyTable";
import {
  objectColumnOrder,
  objectColumns,
  objectDefaultColumnWidths,
  objectDefaultSorting,
  objectTableColumnExtensions,
} from "../../../constants/Ontology/ObjectsConstant";
import { connect } from "react-redux";
import {
  addObjectFilters,
  getOntologyObjects,
  addOntologySort,
  clearOntologyObjectsData
} from "../../../redux/actions/ontologyAction";
import CustomFilter from "../../../components/common/CustomFilter";
import { arrayToFilter, arrayToSort, getWindowDimensions, getColumnWidth } from "../../../utils/common";
// import { useTranslation } from "react-i18next";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

function createData(item) {
  const { _id, name, predefined, sections, predefined_sections, updatedon, label } = item;
  return {
    _id: _id,
    name: name,
    label: label,
    predefined: predefined ? predefined : "FALSE",
    sections: sections ? sections.length : 0,
    predefined_sections: predefined_sections ? predefined_sections.length : 0,
    updatedon: updatedon
  };
}

const ObjectListScreen = (props) => {
  const { data,
    getOntologyObjects,
    loading,
    addObjectFilters,
    objectFilters,
    objectSort,
    addOntologySort,
    clearOntologyObjectsData,
    _totalcount
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [hides, setHides] = useState(["predefined_sections"])
  const styles = listStyles();

  const { checkScreenPermission } = useCheckPermission()


  useEffect(() => {
    checkScreenPermission('OntologyResource', ['List All'])
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const parsedFilters = arrayToFilter(objectFilters);
    const parsedSort = arrayToSort(objectSort);
    getOntologyObjects(
      currentPage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPage, objectSort]);

  useEffect(() => {
    setRows(data && data.length > 0 ? data.map((item) => {
      const rowData = createData(item);
      return rowData;
    }) : [])
  }, [data])

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
    // props.getOntologyFields();
  }, [searchInput]);

  const handleSearch = (e) => {
    if (searchInput) {
      getOntologyObjects(0, JSON.stringify({ name: { $regex: searchInput, $options: "i" } }))
    } else {
      getOntologyObjects(1);
    }
  };

  const handleSubmitFilter = () => {
    // props.addFieldFilters(filters);
    const parsedFilters = arrayToFilter(objectFilters);
    const parsedSort = arrayToSort(objectSort)
    getOntologyObjects(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    getOntologyObjects(1, JSON.stringify({}))
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // code changes done here .... clear reducer implemented

  useEffect(() => {
    return () => {
      clearOntologyObjectsData()
    }
  }, [])

  return (
    <main>
      <div className={styles.main}>

        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Ontology", path: "/ontology/objects" },
              { title: "Objects" },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <p className={styles.title}>{`Objects ${_totalcount ? "".concat("(", _totalcount, ")") : ""}`}</p>
            </div>
            <div className={styles.topContentItem2}>
              <FilledInput autoComplete="off"
                name="searchInput"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.input}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSubmitFilter}
                      onMouseDown={handleSubmitFilter}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={objectColumns}
                filters={objectFilters}
                setFilters={addObjectFilters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </div>
        </div>
        <Paper className={styles.pageContent}>
          <OntologyTable
            columns={objectColumns}
            rows={rows}
            defaultColumnWidths={objectDefaultColumnWidths}
            tableColumnExtensions={objectTableColumnExtensions}
            columnOrder={objectColumnOrder}
            defaultSorting={objectDefaultSorting}
            loading={loading}
            hidden={hides}
            setHidden={setHides}
            totalCount={rows.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isCustomHeight={true}
            isCustomPaging={true}
            sort={objectSort}
            setSort={(sort) => addOntologySort("object", sort)}
            noDataMsg="No Object Found! "
          />
        </Paper>
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(_totalcount / 10)}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addObjectFilters,
  getOntologyObjects,
  addOntologySort,
  clearOntologyObjectsData
};

const mapStateToProps = (state) => {
  const { objects, objectFilters, objectSort } = state.ontologyReducer;
  const { data, loading, error, _totalcount } = objects;
  return {
    data,
    loading,
    error,
    objectFilters,
    objectSort,
    _totalcount
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectListScreen);
