import {TRACK_VISITED_ROUTES,DELETE_VISITED_ROUTES} from '../actionTypes'

export  function routeVisited(data) {
  return {
    type: TRACK_VISITED_ROUTES,
    payload: data,
  };
}

export function deleteVisitedRoute() {
  return {
    type: DELETE_VISITED_ROUTES,
  };
}
