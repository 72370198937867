import React from 'react';
import { FilledInput } from '@material-ui/core';
import SearchStyles from './SearchStyles';

const Search = (props) => {
  const classes = SearchStyles();
  return (
    <div className={classes.container}>
      <FilledInput
        name="searchInput"
        value={props.value}
        onChange={props.onChange}
        className={classes.input}
        placeholder={props.placeholder}
        autoComplete="off"
      />
    </div>
  );
};

export default Search;
