import React from "react";
import settingStyles from "../../../../../styles/commonStyles/Setting";
import { IconButton, Popover } from "@material-ui/core";
import Input from "../../../input/Input";
import CloseIcon from "@material-ui/icons/Close";
import Dropdown from "../../../dropdown/Dropdown";
import CustomAutocomplete from "../../../autocomplete/CustomAutocomplete";

const TreePopup = ({
  isOptionsOpen,
  optionsEl,
  setOptionsEl,
  selectedColumn,
  handleHide,
  handleDelete,
  handleChange,
  columns,
}) => {
  const styles = settingStyles();
  return (
    <Popover
      open={isOptionsOpen}
      anchorEl={optionsEl}
      onClose={() => setOptionsEl(null)}
      className={styles.options}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <div className="optionsWrap">
        <div className="header">
          <h3>{selectedColumn?.title}</h3>
          <div className="icons">
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => handleHide(selectedColumn)}
            >
              {selectedColumn?.isHide ? (
                <img
                  src="/img/eye-slash.svg"
                  style={{ height: 20, opacity: 0.5 }}
                  alt="Eye"
                />
              ) : (
                <img src="/img/eye-line.svg" alt="Eye" />
              )}
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => {
                setOptionsEl(null);
                handleDelete(selectedColumn);
              }}
            >
              <img src="/img/delete-bin-7-line.svg" alt="Delete" />
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => setOptionsEl(null)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="body">
          <label>{"Field Name"}</label>
          {/* <CustomAutocomplete
            options={columns}
            optionLabel={(option) => (option ? option : "")}
            value={selectedColumn?.name}
            onChange={(input) =>
              handleChange({ target: { name: "name", value: input } })
            }
          /> */}
          <Input
            name={"name"}
            label={"Name"}
            placeholder=""
            value={selectedColumn?.name}
            onChange={handleChange}
          />
          <Input
            name={"title"}
            label={"Title"}
            placeholder=""
            value={selectedColumn?.title}
            onChange={handleChange}
          />
          <Input
            name={"width"}
            label={"Width"}
            placeholder=""
            value={selectedColumn?.width}
            onChange={handleChange}
          />
        </div>
      </div>
    </Popover>
  );
};

export default TreePopup;
