/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import processNodeStyle from "./styles";
import { connect } from "react-redux";
import {
  addTemplateData,
  addInputOutputDataForNodes,
} from "../../../redux/actions/orchestrationAction";
import { useSnackbar } from "notistack";
import { OrchestrationServices } from "../../../services/orchestrationServices";
import { AppButton } from "../../common/Button";
import {
  fetchNodeDetails,
  editNodeData,
} from "../../../utils/orchestration/orchestrationUtils";
import { fetchAllFieldGroups } from "../../../utils/dataStandards/fieldsUtils";
import ProcessModalTree from "./ProcessModalTree";
import { getFieldGroupDetails_ } from "../../../utils/orchestration/applicationUtils";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";

const OrchestrationService = new OrchestrationServices();

function ProcessNodeModal(props) {
  const [data_list, setDataList] = useState([]);
  const [nodeData, setNodeData] = useState({
    name: "",
    endpoint: "",
    method: "GET",
    serviceType: "rest",
    topic: "",
    externalBroker: "True",
    enable_message_mapping: "True",
    broker: "",
  });
  const [isLoader, setIsLoader] = useState(false);
  const [errorObj, setErrorObj] = useState({
    name: false,
    endpoint: false,
    method: false,
    topic: false,
    fieldgroup: false,
    broker: false,
  });

  const [fieldGroupList, setFieldGroupList] = useState([]);
  const [fieldGroupData, setFieldGroupData] = useState({});
  const [apiEntitySchema, setApiEntitySchema] = useState({});
  const [fieldGroupInputValue, setFieldGroupInputValue] = useState("");
  const [processFieldGroupData, setProcessFieldGroupData] = useState({
    fieldgroupname: "",
    fields: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const classes = processNodeStyle();

  const descriptionElementRef = React.useRef(null);
  const {
    isOpen,
    applicationID,
    start_node_obj,
    end_node_obj,
    nodeid,
    edit,
    templateid,
    data_flow_nodes_io_details,
  } = props;

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const fetchProcessData = async (tempList) => {
    if (edit) {
      const { _msg, _status, data } = await fetchNodeDetails(
        templateid,
        nodeid
      );
      if (_status === 200) {
        const {
          templatename,
          method,
          endpoint,
          servicetype,
          topic,
          enable_message_mapping,
          broker,
          externalBroker,
          inputfieldgroup,
          fieldliststring,
          sys_rabbitmq_username,
          sys_rabbitmq_password,
          sys_kafka_username,
          sys_kafka_password,
          sys_kafka_partition,
          sys_kafka_mechanism,
          sys_mqtt_username,
          sys_mqtt_password,
        } = data;
        var tempbroker = broker;
        if (broker && servicetype === "rabbitmq") {
          var tempBrokerList = broker.split("@");
          if (tempBrokerList.length === 2) {
            tempbroker = tempBrokerList[1];
          }
        }
        setNodeData(() => ({
          name: templatename,
          endpoint: endpoint ? endpoint : "",
          method: method ? method : "",
          topic: topic ? topic : "",
          serviceType: servicetype ? servicetype : "rest",
          broker: tempbroker ? tempbroker : "",
          enable_message_mapping: enable_message_mapping ? "True" : "False",
          externalBroker: externalBroker ? "True" : "False",
          sys_rabbitmq_username: sys_rabbitmq_username
            ? sys_rabbitmq_username
            : "",
          sys_rabbitmq_password: sys_rabbitmq_password
            ? sys_rabbitmq_password
            : "",
          sys_mqtt_username: sys_mqtt_username ? sys_mqtt_username : "",
          sys_mqtt_password: sys_mqtt_password ? sys_mqtt_password : "",
          sys_kafka_username: sys_kafka_username ? sys_kafka_username : "",
          sys_kafka_password: sys_kafka_password ? sys_kafka_password : "",
          sys_kafka_partition: sys_kafka_partition ? sys_kafka_partition : "",
          sys_kafka_mechanism: sys_kafka_mechanism ? sys_kafka_mechanism : "",
        }));

        var tempFieldGroup = tempList.find(
          (item) => item._id === inputfieldgroup
        );
        if (tempFieldGroup) {
          setFieldGroupData(tempFieldGroup);
        }
        if (fieldliststring) {
          try {
            var parsedData = JSON.parse(fieldliststring);
            setProcessFieldGroupData(parsedData);
            setDataList(parsedData?.fields ? parsedData?.fields : []);
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  const fetchFieldGroupList = async () => {
    const { _msg, _status, data } = await fetchAllFieldGroups();
    if (_status === 200) {
      setFieldGroupList(data ? data : []);
      return data;
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      return [];
    }
  };

  const fetchAPIEntitySchema = async () => {
    const { _msg, _status, data } = await fetchResourceByPermission(
      "API",
      "create"
    );
    if (_status === 200) {
      setApiEntitySchema(data);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  useEffect(async () => {
    var tempList = await fetchFieldGroupList();
    await fetchProcessData(tempList);
    await fetchAPIEntitySchema();
  }, []);

  useEffect(() => {
    var id = 0;
    if (nodeData.serviceType === "rest") {
      var temp_data_list = [];
      data_flow_nodes_io_details.length > 0 &&
        data_flow_nodes_io_details.forEach((node_) => {
          if (node_.type === "call_data_node") {
            node_?.inputFields?.fields?.forEach((field_) => {
              if (field_?.value === "") {
                var findDuplicate = temp_data_list.filter(
                  (item) => item.name === field_.name
                );
                if (findDuplicate.length === 0) {
                  temp_data_list.push({ ...field_, id: id++ });
                }
              } else if (field_?.value !== "") {
                var detailArr = field_.value?.split(".");
                if (
                  detailArr.length > 0 &&
                  detailArr[0] === "${templateInput"
                ) {
                  var findDuplicate_ = temp_data_list.filter(
                    (item) => item.name === field_.name
                  );
                  if (findDuplicate_.length === 0) {
                    temp_data_list.push({
                      id: id++,
                      ...field_,
                    });
                  }
                }
              }
            });
          }
        });
      setDataList(temp_data_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_flow_nodes_io_details, nodeid, nodeData.serviceType]);

  const handleTextChange = (key, value) => {
    setNodeData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setErrorObj((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleClose = () => {
    const obj = { name: nodeData.name };
    props.onCloseClick(obj);
  };

  const checkNameForm = (nameStr) => {
    // var nameStr_ = nameStr.replace(/\s/g, '');
    var nameStr_ = nameStr.replace(/[^a-zA-Z0-9_ ]/g, "");
    return nameStr_;
  };

  const handleNameChange = (val) => {
    var filteredName = checkNameForm(val);
    handleTextChange("name", filteredName);
  };

  const handleFieldGroupSelect = async (newVal) => {
    if (newVal && newVal._id) {
      const { _msg, _status, data } = await getFieldGroupDetails_(newVal._id);
      if (_status === 200) {
        var tempArr = data.fields.filter(
          (item) => item.type !== "array" && item.type !== "object"
        );
        setProcessFieldGroupData({
          fieldgroupname: data.fieldgroupname,
          status: data.status,
          type: data.type,
          fields: tempArr.map((item, index) => ({ ...item, id: index + 1 })),
        });
        setFieldGroupData(newVal);
        setDataList(() =>
          tempArr.map((item, index) => ({ ...item, id: index + 1 }))
        );
      }
    }
  };

  const handleCheckBoxChange = (name, val) => {
    var tempText = val ? "True" : "False";
    setNodeData((prevState) => ({
      ...prevState,
      [name]: tempText,
    }));
  };

  const handleSubmit = async () => {
    var errorFlag = false;
    var tempError = {};
    var requiredFields = [];
    var req_data = {};
    if (nodeData.serviceType === "rest") {
      requiredFields = [...requiredFields, "name", "endpoint", "method"];
    } else if (
      nodeData.serviceType === "rabbitmq" ||
      nodeData.serviceType === "kafka" ||
      nodeData.serviceType === "mqtt"
    ) {
      requiredFields = ["name", "topic"];
    }
    for (var obj of requiredFields) {
      if (nodeData[obj] === "") {
        errorFlag = true;
        tempError[obj] = true;
      }
    }
    let config = {};
    if (nodeData.serviceType === "kafka") {
      config["sys_kafka_username"] = nodeData?.sys_kafka_username;
      config["sys_kafka_password"] = nodeData?.sys_kafka_password;
      config["sys_kafka_partition"] = parseInt(nodeData?.sys_kafka_partition);
      config["sys_kafka_mechanism"] = nodeData?.sys_kafka_mechanism;
    } else if (nodeData.serviceType === "rabbitmq") {
      config["sys_rabbitmq_username"] = nodeData?.sys_rabbitmq_username;
      config["sys_rabbitmq_password"] = nodeData?.sys_rabbitmq_password;
    } else if (nodeData.serviceType === "mqtt") {
      config["sys_mqtt_username"] = nodeData?.sys_mqtt_username;
      config["sys_mqtt_password"] = nodeData?.sys_mqtt_password;
    }
    setErrorObj((prevState) => ({
      ...prevState,
      ...tempError,
    }));
    if (!errorFlag) {
      setIsLoader(true);
      if (edit) {
        req_data =
          nodeData.serviceType === "rest"
            ? {
                templatename: nodeData.name,
                servicetype: "rest",
                endpoint: nodeData.endpoint,
                method: nodeData.method,
              }
            : {
                ...nodeData,
                templatename: nodeData.name,
                servicetype: nodeData.serviceType,
                topic: nodeData.topic,
                externalBroker:
                  nodeData.externalBroker === "True" ? true : false,
                broker:
                  nodeData.serviceType === "rabbitmq"
                    ? `amqp://${nodeData?.sys_rabbitmq_username}:${nodeData?.sys_rabbitmq_password}@${nodeData.broker}`
                    : nodeData.broker,
                enable_message_mapping:
                  nodeData.enable_message_mapping === "True" ? true : false,
                inputfieldgroup: fieldGroupData?._id ? fieldGroupData?._id : "",
                fieldliststring: JSON.stringify(
                  processFieldGroupData ? processFieldGroupData : {}
                ),
              };
        if (nodeData.externalBroker === "True") {
          req_data["config"] = JSON.stringify(config);
        }
        const { _msg, data, _status } = await editNodeData(
          nodeid,
          templateid,
          req_data
        );
        if (_status === 201) {
          setIsLoader(false);
          props.onEditProcessSubmit({
            nodeid: data.nodeid,
            name: nodeData.name,
            servicetype: nodeData.serviceType,
            topic: nodeData.topic,
          });
          props.addInputOutputDataForNodes(
            {
              node_name: nodeData.name,
              id: data.nodeid,
              data: {
                node_name: nodeData.name,
                nodeid: data.startnodeid,
              },
              inputFields: processFieldGroupData,
              servicetype: nodeData.serviceType,
            },
            true,
            false
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
          setIsLoader(false);
        }
      } else {
        const start_pos = start_node_obj["position"];
        const end_pos = end_node_obj["position"];
        try {
          req_data = {
            posX: start_pos["x"] ? start_pos["x"] : 0,
            posY: start_pos["y"] ? start_pos["y"] : 0,
            end_pos_x: end_pos["x"] ? end_pos["x"] : 0,
            end_pos_y: end_pos["y"] ? end_pos["y"] : 0,
          };
          req_data =
            nodeData.serviceType === "rest"
              ? {
                  ...req_data,
                  templatename: nodeData.name,
                  endpoint: nodeData.endpoint,
                  method: nodeData.method,
                  servicetype: "rest",
                }
              : {
                  ...req_data,
                  ...nodeData,
                  templatename: nodeData.name,
                  servicetype: nodeData.serviceType,
                  topic: nodeData.topic,
                  externalBroker:
                    nodeData.externalBroker === "True" ? true : false,
                  broker:
                    nodeData.serviceType === "rabbitmq"
                      ? `amqp://${nodeData?.sys_rabbitmq_username}:${nodeData?.sys_rabbitmq_password}@${nodeData.broker}`
                      : nodeData.broker,
                  enable_message_mapping:
                    nodeData.enable_message_mapping === "True" ? true : false,
                  inputfieldgroup: fieldGroupData._id ? fieldGroupData._id : "",
                  fieldliststring: JSON.stringify(
                    processFieldGroupData ? processFieldGroupData : {}
                  ),
                };
          if (nodeData.externalBroker === "True") {
            req_data["config"] = JSON.stringify(config);
          }
          const res_data = await OrchestrationService.createTemplate(
            applicationID,
            req_data
          );
          const { _msg, _status, data } = res_data.data;
          if (data) {
            setIsLoader(false);
            props.addTemplateData({
              ...data,
              applicationID,
              templatename: nodeData.name,
            });
            props.addInputOutputDataForNodes({
              node_name: nodeData.name,
              type: "process_data_node",
              position: {
                x: start_pos["x"] ? start_pos["x"] : 0,
                y: start_pos["y"] ? start_pos["y"] : 0,
              },
              id: data.startnodeid,
              data: {
                node_name: nodeData.name,
                nodeid: data.startnodeid,
              },
              inputFields: processFieldGroupData,
              servicetype: nodeData.serviceType,
            });
            props.onSubmitClick({
              ...data,
              nodeid: data.nodeid,
              name: nodeData.name,
              servicetype: nodeData.serviceType,
              topic: nodeData.topic,
            });
          }
        } catch (error) {
          const { _msg, _status, data } = error.response.data;
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    }
  };

  const renderConfigFields = (serviceType) => {
    const foundSection = apiEntitySchema?.sections?.filter(
      (sections) => sections.name === `sys_${serviceType.toLowerCase()}_config`
    );
    if (foundSection && foundSection?.length !== 0) {
      return foundSection[0].fieldgroups.map((fieldgroups) =>
        fieldgroups.fields.map((field, index) => {
          switch (field.uiElementType) {
            case "TextField":
              return (
                <div className={classes.name} style={{ margin: 0 }}>
                  <p className={classes.label}>{field.fieldLabel} </p>
                  <input
                    className={classes.value}
                    type={
                      field.fieldLabel === "Password" ||
                      field.fieldLabel === "Partition"
                        ? field.fieldLabel === "Password"
                          ? "password"
                          : "number"
                        : "text"
                    }
                    name={field.name}
                    value={nodeData[field.name]}
                    onChange={(e) =>
                      handleTextChange(field.name, e.target.value)
                    }
                  />
                </div>
              );
            case "Dropdown":
              return (
                <div className={classes.name} style={{ margin: 0 }}>
                  <p className={classes.label}>{field.fieldLabel} </p>
                  <Select
                    name={field.datakey}
                    value={nodeData[field.name] ? nodeData[field.name] : ""}
                    onChange={(e) =>
                      handleTextChange(field.name, e.target.value)
                    }
                    className={`${classes.select}`}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {typeof field?.values === "string"
                      ? JSON.parse(field?.values).map((value, index) => (
                          <MenuItem value={value} key={index}>
                            {value}
                          </MenuItem>
                        ))
                      : field?.values.map((value, index) => (
                          <MenuItem value={value} key={index}>
                            {value}
                          </MenuItem>
                        ))}
                  </Select>
                </div>
              );
            default:
              return <></>;
          }
        })
      );
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        // onEnter={handleEnter}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <Backdrop
          className={classes.backdrop}
          open={isLoader}
          onClick={() => setIsLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1}>
            <img
              src="/images/Start - process.png"
              alt="Icon"
              height={34}
              width={34}
            />
            <p className={classes.heading}>Aggregated API Node</p>
          </div>
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{ height: "25rem", width: "51rem" }}
        >
          <div className={classes.line2}>
            <div className={classes.name}>
              <p className={classes.label}>
                Aggregated API Name <text className={classes.star}>*</text>
              </p>
              <input
                style={errorObj.name ? { borderColor: "red" } : {}}
                className={classes.value}
                type={"text"}
                name="name"
                value={nodeData.name}
                onChange={(e) => handleNameChange(e.target.value)}
              />
              {errorObj.name ? (
                <text className={classes.error_text}>
                  Invalid Template Name
                </text>
              ) : (
                ""
              )}
            </div>
            <div style={{ marginLeft: "0.7rem" }}>
              <p className={classes.label}>
                Service Type <text className={classes.star}>*</text>
              </p>
              <FormControl className={classes.formControl}>
                <Select
                  value={nodeData.serviceType}
                  onChange={(e) =>
                    handleTextChange("serviceType", e.target.value)
                  }
                  className={classes.select}
                >
                  {/* <option value="">None</option> */}
                  <MenuItem value={"rest"}>REST</MenuItem>
                  <MenuItem value={"rabbitmq"}>RabbitMq</MenuItem>
                  <MenuItem value={"kafka"}>Kafka</MenuItem>
                  <MenuItem value={"mqtt"}>Mqtt</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.line3}>
            {(nodeData.serviceType === "kafka" ||
              nodeData.serviceType === "rabbitmq" ||
              nodeData.serviceType === "mqtt") && (
              <div
                className={classes.name}
                style={{
                  width: "250px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.label}>
                  External Broker<text className={classes.star}>*</text>
                </p>
                <Checkbox
                  checked={nodeData.externalBroker === "True" ? true : false}
                  color="primary"
                  onChange={(e) =>
                    handleCheckBoxChange("externalBroker", e.target.checked)
                  }
                  style={{ marginLeft: "4.5rem" }}
                />
                {/* <RadioGroup aria-label="required" name="required" value={nodeData.externalBroker} onChange={(e) => handleTextChange("externalBroker", e.target.value)} style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel value={"True"} control={<Radio color="primary" />} label="True" />
                <FormControlLabel value={"False"} control={<Radio color="primary" />} label="False" style={{ marginLeft: "2rem" }} />
              </RadioGroup> */}
              </div>
            )}
            {(nodeData.serviceType === "kafka" ||
              nodeData.serviceType === "rabbitmq" ||
              nodeData.serviceType === "mqtt") &&
              nodeData.externalBroker === "True" && (
                <div className={classes.name} style={{ margin: 0 }}>
                  <p className={classes.label}>Broker</p>
                  <input
                    className={classes.value}
                    type={"text"}
                    name="name"
                    value={nodeData.broker}
                    onChange={(e) => handleTextChange("broker", e.target.value)}
                  />
                  {/* {errorObj.broker ? <text className={classes.error_text}>Invalid Broker</text> : ""} */}
                </div>
              )}
            {/* {(nodeData.serviceType === "kafka" || nodeData.serviceType === "rabbitmq") && <div>
              {edit && <div className={classes.tree}>
                <p className={classes.label}>Process Input Fields</p>
                <ProcessModalTree children={data_list}
                  templatename={nodeData.name ? nodeData.name : "Process Input List"} />
              </div>}
            </div>} */}
            {nodeData.serviceType === "rest" && (
              <div className={classes.endpoint}>
                <p className={classes.label}>
                  End Point <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.endpoint ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type="text"
                  name="endpoint"
                  value={nodeData.endpoint}
                  onChange={(e) => handleTextChange("endpoint", e.target.value)}
                />
                {errorObj.endpoint ? (
                  <text className={classes.error_text}>Invalid Endpoint</text>
                ) : (
                  ""
                )}
              </div>
            )}
            {nodeData.serviceType === "rest" && (
              <div style={{ marginLeft: "3rem" }}>
                <p className={classes.label}>
                  Method <text className={classes.star}>*</text>
                </p>
                <FormControl className={classes.formControl}>
                  <Select
                    value={nodeData.method}
                    onChange={(e) => handleTextChange("method", e.target.value)}
                    className={classes.select}
                    inputProps={{ "aria-label": "age" }}
                    style={errorObj.method ? { borderColor: "red" } : {}}
                  >
                    {/* <option value="">None</option> */}
                    <MenuItem value={"GET"}>GET</MenuItem>
                    <MenuItem value={"POST"}>POST</MenuItem>
                    <MenuItem value={"PUT"}>PUT</MenuItem>
                    <MenuItem value={"DELETE"}>DELETE</MenuItem>
                  </Select>
                </FormControl>
                {errorObj.method ? (
                  <text className={classes.error_text}>Invalid Method</text>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {(nodeData.serviceType === "kafka" ||
            nodeData.serviceType === "rabbitmq" ||
            nodeData.serviceType === "mqtt") &&
            nodeData.externalBroker === "True" && (
              <div
                className={classes.line2}
                style={{ marginTop: 5, flexWrap: "wrap" }}
              >
                {renderConfigFields(nodeData.serviceType)}
              </div>
            )}
          {(nodeData.serviceType === "kafka" ||
            nodeData.serviceType === "rabbitmq" ||
            nodeData.serviceType === "mqtt") && (
            <div className={classes.line3}>
              <div
                className={classes.name}
                style={{
                  width: "250px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.label}>
                  Enable Message Mapping <text className={classes.star}>*</text>
                </p>
                {/* <RadioGroup aria-label="required" name="required" value={nodeData.enable_message_mapping} onChange={(e) => handleTextChange("enable_message_mapping", e.target.value)} style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel value={"True"} control={<Radio color="primary" />} label="True" />
                <FormControlLabel value={"False"} control={<Radio color="primary" />} label="False" style={{ marginLeft: "2rem" }} />
              </RadioGroup> */}
                <Checkbox
                  checked={
                    nodeData.enable_message_mapping === "True" ? true : false
                  }
                  color="primary"
                  onChange={(e) =>
                    handleCheckBoxChange(
                      "enable_message_mapping",
                      e.target.checked
                    )
                  }
                  style={{ marginLeft: "1rem" }}
                />
              </div>
              {nodeData.enable_message_mapping === "True" && (
                <div style={{ marginLeft: "0.5rem" }}>
                  <p className={classes.label}>Input Field Group </p>
                  <Autocomplete
                    value={fieldGroupData}
                    inputValue={fieldGroupInputValue}
                    style={errorObj.fieldgroup ? { borderColor: "red" } : {}}
                    options={fieldGroupList}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    // getOptionSelected={(opt, val) => val === "" ? false : true}
                    className={classes.searchInput3}
                    onChange={(event, newValue) =>
                      handleFieldGroupSelect(newValue)
                    }
                    onInputChange={(event, newVal) =>
                      setFieldGroupInputValue(newVal)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Field Group"
                      />
                    )}
                  />
                  {/* {errorObj.api_selected ? <text className={classes.error_text}>Invalid API Name</text> : ""} */}
                </div>
              )}
            </div>
          )}
          <div className={classes.line3}>
            {(nodeData.serviceType === "kafka" ||
              nodeData.serviceType === "rabbitmq" ||
              nodeData.serviceType === "mqtt") && (
              <div className={classes.name}>
                <p className={classes.label}>
                  Topic <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.topic ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type={"text"}
                  name="name"
                  value={nodeData.topic}
                  onChange={(e) => handleTextChange("topic", e.target.value)}
                />
                {errorObj.topic ? (
                  <text className={classes.error_text}>Invalid Topic</text>
                ) : (
                  ""
                )}
              </div>
            )}
            {edit && (
              <div
                className={classes.tree}
                style={
                  nodeData.serviceType === "rest"
                    ? { marginLeft: 0, marginTop: 0 }
                    : { marginLeft: "1rem", marginTop: 0 }
                }
              >
                <p className={classes.label}>Aggregated Input Fields</p>
                <ProcessModalTree
                  children={data_list}
                  templatename={
                    nodeData.name ? nodeData.name : "Aggregated API Input List"
                  }
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleClose}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          <AppButton
            onClick={handleSubmit}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addTemplateData,
  addInputOutputDataForNodes,
};

const mapStateToProps = (state) => {
  const { process_data, data_flow_nodes_io_details } = state.orchestration;
  const { templateid } = process_data;
  return {
    templateid,
    data_flow_nodes_io_details,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessNodeModal);
