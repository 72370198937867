import React from "react";

function Error404() {
  return (
    <div style={{ marginTop: 200 }}>
      <h1>Page Not Found</h1>
    </div>
  );
}

export default Error404;