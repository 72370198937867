const launguageColumns = [
  { name: "icons", title: "Icon" },
  {
    name: "name",
    title: "Language Name",
    route: "/language-details",
    for: "language",
  },
  { name: "status", title: "Status", for: "language" },
  { name: "createdon", title: "Created On", for: "language" },
  { name: "languageactions", title: "Action", route: "/edit-language" },
];

const launguageDefaultColumnWidths = [
  { columnName: "icons", weightage: 0, width: 100 },
  { columnName: "name", weightage: 2, width: 100 },
  { columnName: "status", weightage: 2, width: 100 },
  { columnName: "createdon", weightage: 1.5, width: 100 },
  { columnName: "languageactions", weightage: 1, width: 100 },
];

const launguageTableColumnExtensions = [
  { columnName: "icons", align: "left", sortingEnabled: false },
  { columnName: "name", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "languageactions", align: "center", sortingEnabled: false },
];

const launguageColumnOrder = ["icons", "name", "status", "createdon"];

const languageColumnHidden = [
  "icons",
  "name",
  "status",
  "createdon",
  "languageactions",
];

const launguageDefaultSorting = [];

export {
  launguageColumns,
  launguageDefaultColumnWidths,
  launguageTableColumnExtensions,
  launguageColumnOrder,
  launguageDefaultSorting,
  languageColumnHidden,
};
