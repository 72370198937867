import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    // flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  section1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 34,
    width: 34,
  },
  heading: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
  },
  cross: {
    fontSize: 20,
    fontWeight: "bold",
  },
  line2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    height: "12rem",
    width: "24rem",
    // marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {
    height: 38,
    width: 355,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  cancel: {
    height: 40,
    width: 100,
    borderWidth: 1,
    borderColor: "#002855",
    borderRadius: 10,
    color: "#002855",
    textAlign: "center",
    marginRight: 20,
    // backgroundColor: "white",
  },
  name: { display: "flex", flexDirection: "column" },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: "#002855",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'blue',
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  }
}));
