const userColumns = [
  { name: "name", title: "User name", route: "/user-details", for: "user" },
  { name: "userteams", title: "Teams", for: "user" },
  { name: "email", title: "Email", for: "user" },
  { name: "projectAssigned", title: "Projects Assigned", for: "user" },
  { name: "userActions", title: "Action", route: "/edit-user" },
];

const userDefaultColumnWidths = [
  { columnName: "name", weightage: 2, width: 200 },
  { columnName: "userteams", weightage: 2, width: 200 },
  { columnName: "email", weightage: 2, width: 200 },
  { columnName: "projectAssigned", weightage: 2, width: 200 },
  { columnName: "userActions", weightage: 2, width: 200 },
];

const userTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "userteams", align: "left" },
  { columnName: "email", align: "left" },
  { columnName: "projectAssigned", align: "left" },
  { columnName: "userActions", align: "center", sortingEnabled: false },
];

const userColumnOrder = ["name", "userteams", "email", "projectAssigned"];

const userDefaultSorting = [{ columnName: "username", direction: "asc" }];

const userColumnHidden = [
  "name",
  "userteams",
  "email",
  "projectAssigned",
  "userActions",
];

export {
  userColumns,
  userDefaultColumnWidths,
  userTableColumnExtensions,
  userColumnOrder,
  userDefaultSorting,
  userColumnHidden,
};
