import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();

export class DashboardServices {
    getAllDatasets() {
        const uri = `${BASE_URL}/api/v1.0.0/datasets`;
        return apiHelper.get(uri);
    }
    getDatasetsFields(dataset) {
        const uri = `${BASE_URL}/api/v1.0.0/datasets/${dataset}/fields`;
        return apiHelper.get(uri);
    }
    createChart(chartData) {
        const uri = `${BASE_URL}/api/v1.0.0/graphs`;
        return apiHelper.post(uri, chartData);
    }
    editChart(chartId, chartData) {
        const uri = `${BASE_URL}/api/v1.0.0/graphs/${chartId}`;
        return apiHelper.put(uri, chartData);
    }
    getAllCharts() {
        const uri = `${BASE_URL}/api/v1.0.0/graphs`;
        return apiHelper.get(uri);
    }
    executeGraph(graphId) {
        const uri = `${BASE_URL}/api/v1.0.0/graphs/${graphId}/execute`;
        return apiHelper.get(uri);
    }
    deleteGraph(graphId) {
        const uri = `${BASE_URL}/api/v1.0.0/graphs/${graphId}`;
        return apiHelper.delete(uri);
    }
}
