import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Tooltip,
  Button,
  Paper,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import Loader from "../../../components/common/stuff/Loader";
import { connect, useSelector } from "react-redux";
import { handleFormValidationPro, initError } from "../../../utils/common";
import {
  FetchDeploymentById,
} from "../../../utils/deployment/deploymentUtils";
import {
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import {
  getAllTestDataList,
  getAllTestEnvironment,
  getAllTestScriptList,
  getTestEnvData,
} from "../../../utils/project/testUtils";
import { TestExecute } from "../../../utils/test/testExecutionUtils";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { v4 as uuidv4 } from "uuid";
import { fetchTestExecutionById } from "../../../utils/test/testExecutionUtils";
import CustomFieldGroupForTestExecutionRerun from "../../../components/common/entitySchema/test/CustomFieldGroupForTestExecutionRerun";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const ReRunTestExecution = (props) => {
  const { testExecutionDetails } = props;

  const [testExecutionID] = useState(props.match.params.testExecutionID);

  const styles = manageScreenStyles();
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({});
  const [testScriptEntitySchema, setTestScriptEntitySchema] = useState([]);
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    filename: "",
  });
  const [deployment, setDeployment] = useState();
  const [scriptData, setScriptData] = useState();
  const [selectedDeployment, setSelectedDeployment] = useState([]);
  const [selectedScript, setSelectedScript] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [selectedEnvFile, setSelectedEnvFile] = useState();

  const [testData, setTestData] = useState();
  const [envs, setEnvs] = useState([
    { id: uuidv4(), key: "", value: "", enabled: true },
  ]);
  const [envData, setEnvData] = useState();
  const history = useHistory();
  const [testRerunData, setTestRerunData] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [payloadData, setPayloadData] = useState({
    description: "",
    name: "",
    script: "",
    testdata: "",
    deployment: "",
  });

  // Tabbed View
  const { tabbedView } = props;

  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    const getExecutionById = async () => {
      const { data, _status, _msg } = await fetchTestExecutionById(
        testExecutionID
      );

      if (_status === 200) {
        setTestRerunData(data);
        handleDeployment(data?.deploymentid);
        // settestRerunData(data);

        let envKeyVal = [];

        data?.environments?.map((item) => {
          envKeyVal.push(item);
        });

        setEnvs(envKeyVal);
      }
    };

    const handleDeployment = async (deploymentId) => {
      setIsLoader(true);
      const { data, _status } = await FetchDeploymentById(deploymentId);
      if (_status === 200) {
        setSelectedDeployment(data);
        setIsLoader(false);
      } else {
      }
    };

    getExecutionById();
  }, []);

  useEffect(() => {
    const fetchTestScriptEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "TestExecute",
        "create"
      );
      if (_status === 200) {
        const entity = [];

        data?.sections?.map((section) => {
          if (section.name !== "Test Run Details") {
            section?.fieldgroups?.map((fieldgroup) => {
              if (fieldgroup?.fields?.length !== 0) {
                entity.push(section);
              }
            });
          }
        });
        setTestScriptEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        // setProjectData(prevData => ({ ...prevData, ...projData, ...newProjectData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        setError({
          name: {
            isError: false,
            msg: "", dataType: 'string', minimum: 1, minLength: 1, section: 0, fieldLabel: "Name"
          }
        });
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const getAllTestData = async () => {
      setIsLoader(true);
      const { data, _status } = await getAllTestDataList();
      if (_status === 200) {
        setIsLoader(false);
        setTestData(data);
        data?.map((item) => {
          if (testExecutionDetails?.TestData === item?.name) {
            setSelectedData(item);
          } else {
            setIsLoader(false);
          }
        });
      }
    };

    const getAllTestScripts = async () => {
      setIsLoader(true);
      const { data, _status } = await getAllTestScriptList();
      if (_status === 200) {
        setScriptData(data);

        data?.map((item) => {
          if (testExecutionDetails?.collection === item?.name) {
            setSelectedScript(item);

            setIsLoader(false);
          } else {
            setIsLoader(false);
          }
        });
      }
    };

    const getAllTestEnvironmentList = async () => {
      setIsLoader(true);
      const { data, _status } = await getAllTestEnvironment();
      if (_status === 200) {
        setEnvData(data);
      }
    };

    getAllTestScripts();
    getAllTestEnvironmentList();
    getAllTestData();
    fetchTestScriptEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    setPayloadData({
      ...payloadData,
      [event.target.name]: value,
    });
  };

  const handleUpload = (files) => {
    setFileDetails({ filename: files ? (files[0] ? files[0].name : "") : "" });

    setDroppedDocument(files);
    setError({ ...error, droppedDocument: false });
  };
  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(payloadData, error);
    const { tempEntity, errorInTab } = TabsCommon(
      testScriptEntitySchema,
      errors,
      tabbedView
    );

    setActiveTab(errorInTab === undefined ? 0 : errorInTab);
    setTestScriptEntitySchema(tempEntity);
    if (!formIsValid) {
      setError(errors);
    }
    if (formIsValid) {

      let foundEndPoint;
      selectedDeployment?.deploymentDetails?.map((item) => {
        if (item?.serdetails?.status?.loadBalancer !== undefined && Object.keys(item?.serdetails?.status?.loadBalancer).length !== 0) {
          var endpoint = `http://${item?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${item?.Port}`;
        }
        if (endpoint) {
          envs?.map((item) => {
            if (item.value.includes(endpoint)) {
              foundEndPoint = true;
            }
          });
        }
      });
      if (foundEndPoint) {
        setIsLoader(true);
        let testExecuteData = {
          collectionid: selectedScript?._id,
          inputdataid: selectedData?._id,
          environmentid: selectedEnvFile,
          deploymentid: selectedDeployment?._id,
          deploymentname: selectedDeployment?.deploymentname,
          name: payloadData?.name,
          environments: envs,
        };

        const { _msg, _status, data } = await TestExecute(testExecuteData);
        if (_status === 200) {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "success" });
          history.push("/test");
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
          }
        }
      } else {
        setActiveTab(3)
        enqueueSnackbar(
          "Please provide one of the Deployment endpoints in the environment variables for testing the Deployment.",
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleDeleteClick = () => {
    setDroppedDocument([]);
    setFileDetails({
      filename: "",
    });
  };

  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "", enabled: true }]);
  };

  const handleEnvChange = (event, id, column) => {
    if (column === "enabled") {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.checked };
        } else {
          return env;
        }
      });
    } else {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.value };
        } else {
          return env;
        }
      });
    }

    setEnvs(newEnvs);
  };
  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  const setEnvironmentData = async (e) => {
    setSelectedEnvFile(e._id);
    setIsLoader(true);
    const { data, _status } = await getTestEnvData(e._id);

    if (_status === 200) {
      let customizeData = data.map((item) => {
        item["id"] = uuidv4();
        return item;
      });
      setEnvs(customizeData);
      setIsLoader(false);
    } else {
    }
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Test", path: "/test" },
            {
              title: "Execution",
              path: `/test-execute/details/${testExecutionID}`,
            },
            {
              title: isEditMode ? `Rerun ${testRerunData.name}` : "Test Rerun",
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {isEditMode ? `ReRun ${testRerunData.name}` : "ReRun Test"}
          </h2>
          {tabbedView ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              &nbsp;&nbsp;
              {!isEditMode ? (
                <AppButton
                  color="primary"
                  style={{ margin: "unset" }}

                  buttonName="Execute"
                  variant="contained"
                  className="btnsmall"
                  onClick={handleSubmit}
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  style={{ margin: "unset" }}

                  className="btnsmall"
                  onClick={handleSubmit}
                />
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AppButton
                color="primary"
                style={{ margin: "unset", marginLeft: "-1px" }}

                buttonName="Cancel"
                variant="outlined"
                className="btnsmall"
                onClick={handleCancel}
              />
            </div>

          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >


              <AppButton
                className={`btnsmall`}
                style={{ marginRight: "10px" }}
                variant="contained"
                onClick={handleSubmit}
                buttonName="Execute"
              />
              <AppButton
                style={{ padding: "0", marginLeft: "10px" }}
                className={`btnsmall`}
                variant="outlined"
                onClick={(e) => {
                  history.push(`/test-execute/details/${testExecutionID}`);
                }}
                buttonName="Cancel"
              />
            </div>
          )}

          {/* ) : ( */}

          {/* )} */}
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {tabbedView && (
          <SectionTabs
            entity={testScriptEntitySchema}
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
        )}
        {tabbedView ? (
          <Paper style={{ padding: 10, margin: "10px" }}>
            <div style={{ padding: "0px 0px 0" }}>
              <CustomFieldGroupForTestExecutionRerun
                section={testScriptEntitySchema?.[activeTab]}
                payloadData={payloadData}
                handleChange={handleChange}
                error={error}
                testRerunData={testRerunData}
                handleUpload={handleUpload}
                setIsLoader={setIsLoader}
                setSelectedDeployment={setSelectedDeployment}
                setSelectedScript={setSelectedScript}
                setSelectedData={setSelectedData}
                droppedDocument={droppedDocument}
                fileDetails={fileDetails}
                handleDeleteClick={handleDeleteClick}
                deployment={deployment}
                testData={testData}
                scriptData={scriptData}
                selectedDeployment={selectedDeployment}
                envs={envs}
                handleAddEnv={handleAddEnv}
                handleDeleteEnv={handleDeleteEnv}
                handleEnvChange={handleEnvChange}
                envData={envData}
                selectedData={selectedData}
                selectedScript={selectedScript}
                setEnvironmentData={setEnvironmentData}
                setDeployment={setDeployment}
              />
            </div>
          </Paper>
        ) : (
          testScriptEntitySchema.length !== 0 &&
          testScriptEntitySchema.map((section) => {
            if (section.name === "Test_Execute_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForTestExecutionRerun
                    section={section}
                    payloadData={payloadData}
                    handleChange={handleChange}
                    error={error}
                    testRerunData={testRerunData}
                    handleUpload={handleUpload}
                    setIsLoader={setIsLoader}
                    setSelectedDeployment={setSelectedDeployment}
                    setSelectedScript={setSelectedScript}
                    setSelectedData={setSelectedData}
                    droppedDocument={droppedDocument}
                    fileDetails={fileDetails}
                    handleDeleteClick={handleDeleteClick}
                    deployment={deployment}

                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingLeft: 5 }}>
                    <CustomFieldGroupForTestExecutionRerun
                      section={section}
                      payloadData={payloadData}
                      handleChange={handleChange}
                      error={error}
                      handleUpload={handleUpload}
                      setIsLoader={setIsLoader}
                      droppedDocument={droppedDocument}
                      fileDetails={fileDetails}
                      handleDeleteClick={handleDeleteClick}
                      deployment={deployment}
                      testData={testData}
                      testRerunData={testRerunData}
                      scriptData={scriptData}
                      selectedDeployment={selectedDeployment}
                      setSelectedDeployment={setSelectedDeployment}
                      envs={envs}
                      handleAddEnv={handleAddEnv}
                      handleDeleteEnv={handleDeleteEnv}
                      handleEnvChange={handleEnvChange}
                      envData={envData}
                      setSelectedData={setSelectedData}
                      selectedData={selectedData}
                      setSelectedScript={setSelectedScript}
                      selectedScript={selectedScript}
                      setEnvironmentData={setEnvironmentData}
                      setDeployment={setDeployment}

                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
        { }
      </div>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { testExecutionDetails } = state.testExecutionReducer;
  const { tabbedView } = state.userDetails.userInfo;

  return { testExecutionDetails, tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReRunTestExecution);
