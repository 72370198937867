import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import { Tooltip } from "@material-ui/core"
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EndNodeMapping from "../EndNodeMapping";
import NodeInputOutputModal from "../NodeInputOutputModal/NodeInputOutputModal";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: 40,
    width: 186,
    border: "0.1px solid lightgray",
  },
  body: {
    marginLeft: 12,
    lineHeight: "100%",
    display: 'flex',
    flexDirection: "column",
    marginTop: 3,
    width: "7rem"
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "100%",
    alignSelf: "flex-start",
  },
  line2: {
    fontSize: 10,
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "40%",
    lineHeight: 2.3,
  },
  icon_container: {
    display: "flex",
    flexDirection: "column",
  },
  doc_icon: {
    height: 12,
    width: 12,
    color: 'inherit',
    opacity: 0.7
    // background: "#A8A8A8 0% 0% no-repeat padding-box",
  },
  nodehandleRed: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid red!important",
    borderRadius: "7.5px!important",
  }
});

function EndNode(props) {
  const [showEndNodeModal, setEndNodeModal] = useState(false)
  const [showNodeOutputModal, setShowNodeOutputModal] = useState(false);

  const { name, endnodeid } = props.data;
  const { simulate_status,debug_status,debug_context_data,template_context_data } = props;

  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleShowOutputModal = () => {
    if (debug_status) {
      const debug_points_arr = Object.keys(debug_context_data?.context);
      if (debug_points_arr.includes(`${name}_end`)) {
        setShowNodeOutputModal(true)
      } else {
        enqueueSnackbar("Node is yet to be executed in the flow", { variant: "info" });
      }
    } else if (simulate_status) {
      const node_details = template_context_data?.nodes?.find((node_) => node_.name === `${name}_end`);
      if (node_details) {
        setShowNodeOutputModal(true)
      } else {
        enqueueSnackbar("Node was not executed in the simulation flow", { variant: "info" });
      }
    }
  }

  return (
    <div className={styles.container}>
      {/* <Handle type="target" position="left" style={{ borderRadius: 0 }} /> */}
      <span className={styles.nodeIcon}>
        <img src="/images/End - process.png" alt={"end node"} height={38} width={39} />
      </span>
      <div className={styles.body}>
        <text className={styles.heading}>End</text>
        {/* <text className={styles.line2}>{name}</text> */}
      </div>
      <div className={styles.icon_container}>
        {!simulate_status &&
          <span className={styles.editIcon}
            onClick={() => setEndNodeModal(true)}>
            <img src="/images/edit_2x.png" alt={"edit"} height={12} width={10} />
          </span>}
        {simulate_status && <span color={"#A8A8A8"}
          onClick={handleShowOutputModal}>
          <DescriptionOutlinedIcon className={styles.doc_icon} color="inherit" />
        </span>}
      </div>
      <Tooltip title="Target">
        <Handle
          type="target"
          position="top"
          id="a"
          style={{ left: "50%" }}
          className={styles.nodehandleRed}
        ></Handle>
      </Tooltip>
      {showEndNodeModal && <EndNodeMapping isOpen={showEndNodeModal}
        onEditEndClose={() => setEndNodeModal(false)}
        name={name}
        nodeid={endnodeid} />}
      {showNodeOutputModal && <NodeInputOutputModal isOpen={showNodeOutputModal}
        node_name={`${name}_end`}
        onSubmit={() => setShowNodeOutputModal(false)} />}
    </div>
  );
}

const mapDispatchToProps = {}

const mapStateToProps = (state) => {
  const { debug_points, simulate_status, debug_data, process_output } = state.orchestration;
  const { debug_status, debug_context_data } = debug_data;
  return {
    debug_points,
    simulate_status,
    debug_status,
    debug_context_data,
    template_context_data: process_output?.template_context_data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndNode)
