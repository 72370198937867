import { Paper, Divider } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
// files
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import {
  containerColumns,
  containerDefaultColumnWidths,
  containerTableColumnExtensions,
  containerColumnOrder,
  containerColumnHidden,
} from "../../../constants/ContainerManagement/containerListTableConstant";
import { useTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import {
  add_containers_data,
  add_container_filters,
  add_container_sort,
  add_containers_hidden_cols,
  clear_container_data,
} from "../../../redux/actions/containerAction";
import CustomFilter from "../../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort2,
  arrayToSort,
} from "../../../utils/common";
import Pagination from "@material-ui/lab/Pagination";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const ContainerListScreen = (props) => {
  const {
    data,
    add_containers_data,
    loading,
    add_container_filters,
    containerFilter,
    add_container_sort,
    containerSort,
    clear_container_data,
    _totalcount,
    add_containers_hidden_cols,
    containerHiddenCols,
    alterActiveTab,
    deleteContainerStatus,
  } = props;
  const styles = listScreenStyles();
  const histroy = useHistory();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: containerColumns,
    widths: containerDefaultColumnWidths,
    extentions: containerTableColumnExtensions,
    orders: containerColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    alterActiveTab("Containers", "Containers");
  }, []);

  useEffect(() => {
    checkScreenPermission("Container", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Container");
      if (_status === 200) {
        const projData = getTableData(
          data,
          containerColumns,
          containerDefaultColumnWidths,
          containerTableColumnExtensions,
          containerColumnOrder,
          containerHiddenCols,
          "container"
        );
        setTableData(projData);
        if (
          JSON.stringify(containerHiddenCols) ===
          JSON.stringify(containerColumnHidden)
        ) {
          add_containers_hidden_cols(projData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  // clear reducer.... code changes done here

  useEffect(() => {
    return () => {
      clear_container_data();
    };
  }, []);

  // Api call
  useEffect(() => {
    const parsedFilters = arrayToFilter(containerFilter);
    const parsedSort = arrayToSort(containerSort);
    add_containers_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, containerSort]);

  useEffect(() => {
    if(deleteContainerStatus === true){  
      const parsedFilters = arrayToFilter(containerFilter);
      const parsedSort = arrayToSort(containerSort);
      add_containers_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
    }
  }, [deleteContainerStatus]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function createData(containers_data) {
    const {
      _id,
      containername,
      state,
      createdby,
      createdon,
      updatedby,
      updatedon,
    } = containers_data;
    return {
      ...containers_data,
      _id,
      containername,
      state: state ? state.charAt(0).toUpperCase() + state.slice(1) : "",
      createdby,
      createdon: createdon,
      updatedby,
      updatedon,
    };
  }

  const rows =
    data?.length > 0
      ? data?.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];

  // filter

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(containerFilter);
    const parsedSort = arrayToSort2(containerSort);
    add_containers_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_containers_data(1, JSON.stringify({}));
  };

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      add_containers_data(
        1,
        JSON.stringify({
          containername: { $regex: searchInput, $options: "i" },
        })
      );
    } else {
      add_containers_data(1);
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Containers", path: "/containers" },
              { title: "Containers list" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("containers.containers")}{" "}
                {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Container Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData.columns}
                filters={containerFilter}
                setFilters={add_container_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="Create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("Container", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/create-container")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {tableLoading ? (
            <Loader />
          ) : (
            <ApplicationListTable
              columns={tableData.columns}
              rows={rows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              defaultHiddenColumnNames={tableData.hides}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isCustomHeight={true}
              sort={containerSort}
              setSort={add_container_sort}
              hidden={containerHiddenCols}
              setHidden={add_containers_hidden_cols}
            />
          )}
        </Paper>
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_containers_data,
  add_container_filters,
  add_container_sort,
  add_containers_hidden_cols,
  clear_container_data,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    containers_list,
    containerFilter,
    containerSort,
    containerHiddenCols,
  } = state.containerReducer;
  const { data, error, loading, _totalcount, deleteContainerStatus } = containers_list;
  return {
    data,
    error,
    loading,
    containerFilter,
    containerSort,
    _totalcount,
    containerHiddenCols,
    deleteContainerStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerListScreen);
