import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();

export class UserProfile {
  fetchDetails(userid) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userid}`;
    return apiHelper.get(uri);
  }
  editDetails(userid,val){
    const uri = `${BASE_URL}/api/v1.0.0/users/${userid}`;
    return apiHelper.put(uri,val);
  }
  change2FA(userid,val){
    const uri = `${BASE_URL}/api/v1.0.0/users/twofactorauthentication/${userid}`;
    return apiHelper.put(uri,val);
  }
  fetchAllNotifications(userid,page){
    const uri = `${BASE_URL}/api/v1.0.0/notifications/users/${userid}?page=${page}&size=10`;
    return apiHelper.get(uri);
  }
  markAsRead(notificationId,status){
    const uri = `${BASE_URL}/api/v1.0.0/notifications/${notificationId}`;
    return apiHelper.put(uri,status);
  }
}