import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  Filter: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: 20,
    },
  },
  FilterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 120,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D0D0D0",
    borderRadius: "3px",
    opacity: 1,
    padding: "0 5px",
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
    height: 32,
  },
  filterPopUp: {
    marginTop: 5,
  },
  filterWrap: {
    height: 400,
    width: 700,
    zIndex: 99999,
  },
  filterHeader: {
    padding: "10px 20px",
    backgroundColor: theme.palette.background.default,
    font: "normal normal 600 16px/22px Nunito",
    color: "#000000",
    width: "100%",
    margin: 0,
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  filterContent: {
    padding: 20,
    maxHeight: 290,
    overflow: "auto",

    // "& .MuiGrid-container:first-child button": {
    //     marginTop: 20
    // },
    "& .MuiGrid-container:not(:first-child) button": {
      marginTop: -5,
    },
    "& .MuiPickersDateRangePickerInput-root": {
      display: 'block'
    }
  },
  cancelIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  rotateMode: {
    transform: "rotate(180deg)",
  },
  sortApplied: {
    color: theme.palette.primary.main,
  },
  defaultSortMode: {
    color: "#919191",
  },
  addBtn: {
    font: "normal normal 600 14px/19px Nunito",
    color: "#002855",
    marginTop: 20,
    cursor: "pointer",
    display: "inline-block",
  },
  filterFooter: {
    position: "absolute",
    left: 0,
    bottom: 0,
    padding: "15px 20px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #535353",

    // "& span": {
    //     font: 'normal normal 600 16px/22px Nunito',
    //     cursor: 'pointer'
    // }
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionIcon: {
    "& svg": {
      transition: ".5s",
    },
  },
  fieldHeading: {
    font: "normal normal 500 16px/22px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    marginBottom: 5,
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    padding: "0 15px",
    height: "35px",
    marginTop: 5,

    "&::before": {
      borderBottom: "unset",
    },

    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: 5,

    "&::before": {
      borderBottom: "unset",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
}));
