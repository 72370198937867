import { TestScriptService } from "../../services/testScriptService";

const script = new TestScriptService()

export const deleteScript = async (ScriptId) => {
    try {
        const res_data = await script.deleteScript(ScriptId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};