export const cloudConfigColumns = [
    { name: "configurationname", title: "Config Name", for: "process_list" },
    { name: "cloudprovider", title: "Cloud Provider", for: "process_list" },
    { name: "region", title: "Region", for: "process_list" },
    { name: "action", title: "Action", for: "process_list" }
  ];
  
  export const cloudConfigDefaultColumnWidth = [
    { columnName: "configurationname", width: 200 },
    { columnName: "cloudprovider", width: 200 },
    { columnName: "region", width: 200 },
    { columnName: "action", width: 200 }
  ];
  
  export const cloudConfigTableColumnExtensions = [
    { columnName: "configurationname", align: "left" },
    { columnName: "cloudprovider", align: "left" },
    { columnName: "region", align: "left" },
    { columnName: "action", align: "center" }
  ];
  
  export const cloudConfigColumnOrder = [
    "configurationname",
    "cloudprovider",
    "region",
    "action"
  ];

  export const cloudConfigColumnHidden = [];
  
  export const cloudConfigDefaultSorting = [ ];
  
  