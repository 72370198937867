import { VendorServices } from "../../services/vendorServices";
const VendorService = new VendorServices();

export const getVendorList = async () => {
  try {
    const res_data = await VendorService.getVendorList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createVendor = async (createVendorData) => {
  try {
    const res_data = await VendorService.createVendor(createVendorData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchVendorDetails = async (vendorID) => {
  try {
    const res_data = await VendorService.getVendorDetailsByID(vendorID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editVendor = async (vendorID, EditVendorData) => {
  try {
    const res_data = await VendorService.editVendorByID(
      vendorID,
      EditVendorData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const blockVendor = async (vendorID, blockdata) => {
  try {
    const res_data = await VendorService.blockVendor(vendorID, blockdata);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const UnblockVendor = async (vendorID, blockdata) => {
  try {
    const res_data = await VendorService.UnblockVendor(vendorID, blockdata);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getVendorTeamDetails = async (vendorID) => {
  try {
    const res_data = await VendorService.getVendorTeamDetails(vendorID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getVendorProjectDetails = async (vendorID) => {
  try {
    const res_data = await VendorService.getVendorProjectDetails(vendorID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getVendorEntity = async () => {
  try {
    const res_data = await VendorService.getVendorEntity();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
