import { ApiHelper } from "./helper/ApiHelper";
import { PROJECTS } from "./helper/config";

const apiHelper = new ApiHelper();

export class ServiceGroupsAPIServices {
  getServiceGroupById(projectId, groupId) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/servicegroups/${groupId}`;
    return apiHelper.get(uri);
  }
  getServiceInfoById(serviceId) {
    const uri = `${PROJECTS}/api/v1.0.0/services/${serviceId}/entities`;
    return apiHelper.get(uri);
  }
  getEntititesInfoByVersionId(versionId) {
    const uri = `${PROJECTS}/api/v1.0.0/versions/${versionId}/entities`;
    return apiHelper.get(uri);
  }
  addServiceGroupInProject(projectId, serviceGroupData) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/servicegroups`;
    return apiHelper.post(uri, serviceGroupData);
  }
  addServiceGroupInServiceGroup(projectId, groupId, serviceGroupData) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/servicegroups/${groupId}/servicegroups`;
    return apiHelper.post(uri, serviceGroupData);
  }
  editServiceGroup(projectId, groupId, serviceGroupData) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/servicegroups/${groupId}`;
    return apiHelper.put(uri, serviceGroupData);
  }
  deleteServiceGroup(projectId, groupId) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/servicegroups/${groupId}`;
    return apiHelper.delete(uri);
  }
  getServiceGroupHierarchy(groupId) {
    const uri = `${PROJECTS}/api/v1.0.0/hierarchy/servicegroup/${groupId}`;
    return apiHelper.get(uri);
  }
  getProjectHierarchy(projectId) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}/_detail`;
    return apiHelper.get(uri);
  }
}
