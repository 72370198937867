import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useCheckPermission from "../hooks/useCheckPermission";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import devExpressStyles from "../styles/DevExpressTableStyles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const DefaultActionCell = ({ ...restProps }) => {
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const { userInfo } = useSelector((state) => state.userDetails);

  const handleSubscribeResource = async (resource, resourceinstance) => {
    let resourceType = resource;
    const { _msg, _status, data } = await addSubscriptionInResource(
      userInfo.email,
      userData.userid,
      resourceType,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      // dispatch(alterSubscribeAITaskById(resourceinstance));
      // dispatch(addSingleUserSubscription(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };
  const handleRemoveSubscribeResource = async (resource, resourceinstance) => {
    let resourceType = resource;
    const { _msg, _status, data } = await deleteUserSubscriptionInResource(
      userData.userid,
      resourceType,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      // dispatch(alterSubscribeAITaskById(resourceinstance));
      // dispatch(removeSingleUserSubscription(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  return (
    <div className={styles.actionIcons}>
      <Tooltip
        title={restProps.row?.isSubscribe ? "Unsubscribe" : "Subscribe"}
        arrow
      >
        <IconButton
          color="inherit"
          aria-label="subscribe"
          edge="start"
          className={styles.actionIcon}
          onClick={() => {
            handleSubscribeResource(restProps.row.id);
          }}
        >
          {restProps.row?.isSubscribe ? (
            <img src="/images/Bell_Active.svg" alt="card" />
          ) : (
            <img src="/images/Bell_Inactive.svg" alt="card" />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Cell = (props) => {
  const history = useHistory();
  const { column } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleNavigateToDetails = () => {
    // history.push(`/host/edit/${props.row.id}`);
    history.push(
      `/cluster/details/${props.row.clustername}/${
        props.row.configName
          ? props.row.configName
          : localStorage.getItem("CloudConfiguration")
      }`
    );
  };

  if (column.name === "createdon" || column.name === "updatedon") {
    return (
      <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
        <DateCell {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
  }
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const ClusterListTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();
  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 5) {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
    // }
    // props.setHidden(hideCols);
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 320
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default ClusterListTable;
