import {
  GET_PROJECTS_DOCUMENTS,
  PROJECT_DOCUMENT_FILTERS,
  PROJECT__DOCUMENT_SORT,
  CLEAR_PROJECT_DATA
} from "../actionTypes";
import { CommonServices } from "../../services/commonServices";
import {
  ADD_SERVICE_LIST__DOCUMENT_DATA,
  SERVICE_DOCUMENT_DATA_FILTERS,
  SERVICE_DOCUMENT_DATA_SORT,
  CLEAR_SERVICE_DATA
} from "../actionTypes";
// import { MicroServicesAPIServices } from "../../services/microservices";

const commonServices = new CommonServices();
// const microserviceAPIServices = new MicroServicesAPIServices();

const add_proj_data = (payload) => ({
  type: GET_PROJECTS_DOCUMENTS,
  payload: payload,
});

export const getProjectListDocumentData = (currentPage, filter, sort, size) => async(dispatch)=>{
  try {
    dispatch(
      add_proj_data({
        loading: true,
      })
    );
    const res_data = await commonServices.getProjectsList(
      currentPage,
      filter,
      sort,
      size
    );
    const { data } = res_data;
    if(data){
      dispatch(
        add_proj_data({
          loading: false,
          data: data,
          error: false,
          _totalcount: data._totalcount
        })
      );
    }else {
      dispatch(
        add_proj_data({
          loading: false,
          data: [],
          error: false,
          _totalcount: data._totalcount,
        })
      );
    }
   
}
catch (error) {
  dispatch(
    add_proj_data({
      loading: false,
      data: [],
      error: true,
    })
  );
}
}

export const addProjectDocumentListDataFilters = (projectFilters) => {
  return {
    type: PROJECT_DOCUMENT_FILTERS,
    payload: projectFilters,
  };
};

export const addProjectDocumentListDataSort = (projectSort) => {
  return {
    type: PROJECT__DOCUMENT_SORT,
    payload: projectSort,
  };
};

export const clear_project_documents_data =()=>{
  return{
    type: CLEAR_PROJECT_DATA
  }
}

const add_ser_doc_data = (payload) => {
  return {
    type: ADD_SERVICE_LIST__DOCUMENT_DATA,
    payload: payload,
  };
};

export const getServicesDocumentListData =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites },
    } = getState();

    try {
      dispatch(
        add_ser_doc_data({
          loading: true,
        })
      );
      const { data } = await commonServices.getServicesList(
        currentPage,
        filter,
        sort
      );
      if (data) {
        dispatch(
          add_ser_doc_data({
            loading: false,
            data: {
              data:
                data.data.length !== 0 &&
                data.data.map((serv) => {
                  const checkFav =
                    userFavourites.filter(
                      (fav) => fav.resourceinstance === serv._id
                    ).length !== 0;
                  if (checkFav) {
                    return {
                      ...serv,
                      isFavourite: true,
                    };
                  } else {
                    return {
                      ...serv,
                      isFavourite: false,
                    };
                  }
                }),
            },
            error: false,
            _totalcount: data._totalcount,
          })
        );
      } else {
        dispatch(
          add_ser_doc_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_ser_doc_data({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addServiceDocumentListFilters = (serviceFilters) => {
  return {
    type: SERVICE_DOCUMENT_DATA_FILTERS,
    payload: serviceFilters,
  };
};


export const addServiceDocumentListSort = (serviceSort) => {
  return {
    type: SERVICE_DOCUMENT_DATA_SORT,
    payload: serviceSort,
  };
};

export const clear_Service_Documents_Data=()=>{
  return{
    type:CLEAR_SERVICE_DATA
  }
}








