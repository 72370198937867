import React from "react";
import { Doughnut } from "react-chartjs-2";

const PodsChart = ({ node }) => {
  return (
    <div>
      <Doughnut
        key={node?.Name}
        data={{
          labels: ["Allocatable", "Used"],
          datasets: [
            {
              data: [
                node?.Capacity?.Pods - node?.Usage?.Pods,
                node?.Usage?.Pods,
              ],
              backgroundColor: ["#808080", "#d45605"],
            },
          ],
          borderColor: ["#f2f2f2", "#17A5BF"],
        }}
        options={{
          cutout: 80,
          responsive: true,
          plugins: {
            legend: { position: "bottom" },
            title: {
              display: false,
              text: "Pods",
            },
          },
        }}
        plugins={[
          {
            beforeDraw: function (chart, args, options) {
              var width = chart.width;
              var height = chart.height;
              var ctx = chart.ctx;

              ctx.restore();
              ctx.font = "normal normal 600 20px/22px Nunito";
              ctx.textBaseline = "middle";

              var text = `${node?.Capacity?.Pods} Pods`;
              var textX = Math.round((width - ctx.measureText(text).width) / 2);
              var textY = height / 2.15;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
      />
    </div>
  );
};

export default PodsChart;
