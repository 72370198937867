import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Divider,
  Popover,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listStyles from "../../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import {
  fieldsColumns,
  fieldsDefaultColumnWidths,
  fieldsTableColumnExtensions,
  fieldsColumnOrder,
  fieldsDefaultSorting,
  fieldColumnHidden,
} from "../../../constants/DataStandard/Fields";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import { getTableData } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";

import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";

import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import { AppButton } from "../../../components/common/Button";
import BulkUploadModal from "../../../components/DataStandards/BulkUploadModal";
import { useSnackbar } from "notistack";
import { fetchLanguagesListData } from "../../../utils/applicationGovernance/launguageUtils";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";

import {
  arrayToFilter,
  arrayToSort,
  getWindowDimensions,
  getColumnWidth,
} from "../../../utils/common";
import { v4 as uuidv4 } from "uuid";
// Redux
import { connect } from "react-redux";
import {
  fetchAllFieldGroups,
  fetchAllFields,
  setTab,
  setCurrentFieldPage,
  setCurrentFieldGroupPage,
  setFieldSort,
  setFieldGroupSort,
  addFieldGroupFilters,
  addFieldGroupHidden,
  addFieldFilters,
  clearFieldFilters,
  addFieldHidden,
  clearDataStandardListScreen,
} from "../../../redux/actions/dataStandardAction";
import CustomFilter from "../../../components/common/CustomFilter";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import {
  fieldGroupsColumnHidden,
  fieldGroupsColumnOrder,
  fieldGroupsColumns,
  fieldGroupsDefaultColumnWidths,
  fieldGroupsDefaultSorting,
  fieldGroupsTableColumnExtensions,
} from "../../../constants/DataStandard/FieldGroups";
import SearchBar from "../../../components/common/SearchBar";

const DataStandardListScreen = (props) => {
  const {
    fields,
    fetchAllFields,
    tab,
    setTab,
    loading,
    fieldSort,
    fieldGroupSort,
    _totalcount,
    setFieldGroupSort,
    setFieldSort,
    addFieldFilters,
    addFieldGroupFilters,
    dataStandardsActiveTab,
    clearFieldFilters,
    fetchAllFieldGroups,
    fieldFilters,
    getAITasksList,
    fieldGroupFilters,
    alterActiveTab,
    addFieldGroupHidden,
    addFieldHidden,
    fieldHidden,
    clearDataStandardListScreen,
    fieldGroupHidden,
    fieldGroups,
    deleteFieldStatus,
    deleteFieldGroupStatus,
  } = props;

  const styles = listStyles();
  const history = useHistory();
  const [buttonEl, setButtonEl] = useState(null);
  const isButtonOpen = Boolean(buttonEl);
  const [bulkUploadModal, setbulkUploadModal] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [currentFieldPage, setCurrentFieldPage] = useState(1);
  const [filters, setFilters] = useState(fieldFilters);
  const [tableLoadingFieldGroup, setTableLoadingfieldGroup] = useState(true);
  const [currentFieldsRows, setCurrentFieldsRows] = useState([]);
  const [fieldgroupRows, setFieldgroupRows] = useState([]);
  const [currentPageFieldGroup, setCurrentPageFieldGroup] = useState(1);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);

  const [totalPageCount, setTotalPageCount] = useState(
    Math.ceil(fields.totalCount / 10)
  );
  const [totalPageCountFieldGroup, setTotalPageCountFieldGroup] = useState(
    Math.ceil(fieldGroups._totalCount / 10)
  );

  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: fieldsColumns,
    widths: fieldsDefaultColumnWidths,
    extentions: fieldsTableColumnExtensions,
    orders: fieldsColumnOrder,
    hides: [],
  });
  const [fieldGrouptableData, setFieldGroupTableData] = useState({
    columns: fieldGroupsColumns,
    widths: fieldGroupsDefaultColumnWidths,
    extentions: fieldGroupsTableColumnExtensions,
    orders: fieldGroupsColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("GlossaryField", ["List All", "List Assigned"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "GlossaryField"
      );
      if (_status === 200) {
        const fieldData = getTableData(
          data,
          fieldsColumns,
          fieldsDefaultColumnWidths,
          fieldsTableColumnExtensions,
          fieldsColumnOrder,
          fieldColumnHidden,
          "field"
        );
        setTableData(fieldData);
        if (JSON.stringify(fieldHidden) === JSON.stringify(fieldColumnHidden)) {
          addFieldHidden(fieldData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchFieldGroupFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "GlossaryFieldGroup"
      );
      if (_status === 200) {
        let fieldGData = getTableData(
          data,
          fieldGroupsColumns,
          fieldGroupsDefaultColumnWidths,
          fieldGroupsTableColumnExtensions,
          fieldGroupsColumnOrder,
          fieldGroupHidden,
          "fieldgroup"
        );
        setFieldGroupTableData(fieldGData);
        if (
          JSON.stringify(fieldGroupHidden) ===
          JSON.stringify(fieldGroupsColumnHidden)
        ) {
          addFieldGroupHidden(fieldGData.hides);
        }
        setTableLoadingfieldGroup(false);
      } else {
        setTableLoadingfieldGroup(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchFields();
    fetchFieldGroupFields();
  }, []);

  useEffect(() => {
    if (fields.totalCount) setTotalPageCount(Math.ceil(fields.totalCount / 10));
  }, [fields.totalCount]);

  useEffect(() => {
    if (fieldGroups._totalCount)
      setTotalPageCountFieldGroup(Math.ceil(fieldGroups._totalCount / 10));
  }, [fieldGroups._totalCount]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldFilters);
    const parsedSortField = arrayToSort(fieldSort);
    fetchAllFields(
      currentFieldPage,
      parsedFilters,
      JSON.stringify(parsedSortField)
    );
  }, [fieldSort, currentFieldPage]);

  useEffect(() => {
    if(deleteFieldStatus === true) {
      const parsedFilters = arrayToFilter(fieldFilters);
      const parsedSortField = arrayToSort(fieldSort);
      fetchAllFields(
        currentFieldPage,
        parsedFilters,
        JSON.stringify(parsedSortField)
      );
    }
  }, [deleteFieldStatus]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldGroupFilters);
    const parsedSort = arrayToSort(fieldGroupSort);
    fetchAllFieldGroups(
      currentPageFieldGroup,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageFieldGroup, fieldGroupSort]);

  useEffect(() => {
    if(deleteFieldGroupStatus === true) {
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(
        currentPageFieldGroup,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteFieldGroupStatus]);

  const handleSubmitFilter = () => {
    addFieldFilters(filters);
    const parsedFilters = arrayToFilter(filters);
    const parsedSort = arrayToSort(fieldSort);
    fetchAllFields(0, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleSubmitFilterF = () => {
    if (dataStandardsActiveTab === "fieldgroups") {
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFiltersF = () => {
    if (dataStandardsActiveTab === "fieldgroups") {
      fetchAllFieldGroups(1, JSON.stringify({}));
    }
  };

  const handleClearFilters = () => {
    const parsedSort = arrayToSort(fieldSort);
    clearFieldFilters();
    fetchAllFields(0, JSON.stringify({}), JSON.stringify(parsedSort));
  };

  const createData = (data) => {
    const { name, type, status, createdby, createdon } = data;
    return {
      ...data,
      name,
      type,
      status,
      createdon,
      createdby,
    };
  };

  //code changes done here..... clear reducers
  useEffect(() => {
    return () => {
      clearDataStandardListScreen();
    };
  }, []);

  useEffect(() => {
    setCurrentFieldsRows(
      fields?.data?.map((item) => {
        const row_data = createData(item);
        return row_data;
      }) || []
    );
  }, [fields]);

  useEffect(() => {
    setFieldgroupRows(
      fieldGroups?.data?.data && fieldGroups?.data?.data?.length > 0
        ? fieldGroups?.data?.data?.map((item) => ({
          ...item,
          createdon: item.createdon,
          updatedon: item.updatedon,
          updatedby: item.updatedby,
        }))
        : []
    );
  }, [fieldGroups]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);
  const handleSearch = () => {
    if (dataStandardsActiveTab === "datadictionary") {
      if (searchInput) {
        fetchAllFields(
          0,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        const parsedFilters = arrayToFilter(filters);
        fetchAllFields(1, parsedFilters);
      }
    } else if (dataStandardsActiveTab === "fieldgroups") {
      if (searchInput) {
        fetchAllFieldGroups(
          0,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        fetchAllFieldGroups(1);
      }
    }
  };
  const handleChangeTab = (event, newValue) => {
    alterActiveTab("dataStandardsActiveTab", newValue);
  };

  const activeTabBredCrumbsName = () => {
    if (dataStandardsActiveTab === "datadictionary") {
      return "Data Dictionary Lists";
    } else if (dataStandardsActiveTab === "fieldgroups") {
      return "Payload Lists";
    }
  };

  return (
    <main className={styles.main}>
      <Container maxWidth="xl">
        <div className={styles.topContent} style={{ paddingBottom: "20px" }}>
          {/* <BreadCrumbs items={[{ title: "Data Standards" }]} /> */}
          <BreadCrumbs
            items={[
              {
                title: "Data Standards",
                path: "/data-standards?datastandards=true",
              },
              { title: activeTabBredCrumbsName() },
            ]}
          />
          <div className={styles.topContentItems} style={{ marginTop: "15px" }}>
            <Grid item xs={6}>
              <Tabs
                value={dataStandardsActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={`Data Dictionary List ${fields.totalCount ? `(${fields.totalCount})` : ""
                    }`}
                  value={"datadictionary"}
                />
                <Tab
                  label={`Payloads ${fieldGroups?.data?._totalcount > 0
                    ? ` (${fieldGroups?.data?._totalcount})`
                    : ""
                    }`}
                  value={"fieldgroups"}
                // disabled={!checkCompPermission('GlossaryFieldGroup', ['ListllA', 'ListAssigned'])}
                />
              </Tabs>
            </Grid>

            {/* <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                Data Standards List{" "}
                {fields.totalCount ? `(${fields.totalCount})` : " "}
              </p>
            </div> */}
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel={dataStandardsActiveTab !== "fieldgroups" ? "Search Name" : "Search Payload Name"}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              {dataStandardsActiveTab === "datadictionary" && (
                <CustomFilter
                  // columns={fieldsColumns}
                  columns={tableData.columns}
                  filters={filters}
                  setFilters={(data) => setFilters(data)}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}

              {dataStandardsActiveTab === "fieldgroups" && (
                <CustomFilter
                  columns={fieldGrouptableData.columns}
                  filters={fieldGroupFilters}
                  setFilters={addFieldGroupFilters}
                  handleSubmitFilter={handleSubmitFilterF}
                  handleClearFilters={handleClearFiltersF}
                />
              )}

              <Divider orientation="vertical" className={styles.MuiDivider} />
              {dataStandardsActiveTab === "datadictionary" && (
                <AppButton
                  buttonName="Bulk Upload"
                  variant="outlined"
                  startIcon={
                    <img
                      src="/images/bulkupload.svg"
                      alt="card"
                      style={{ filter: "brightness(0)" }}
                    />
                  }
                  disabled={
                    !checkCompPermission("GlossaryField", ["Bulk Upload"])
                  }
                  className="btnsmall"
                  style={{
                    width: "157px",
                    paddingLeft: "22px",
                    paddingRight: "22px",
                  }}
                  onClick={() => setbulkUploadModal(true)}
                />
              )}
              <BulkUploadModal
                bulkUploadModal={bulkUploadModal}
                setbulkUploadModal={setbulkUploadModal}
              />

              {dataStandardsActiveTab === "datadictionary" && (
                <AppButton
                  style={{
                    padding: "8px 9px 7px 18px",
                    marginLeft: "25px",
                    color: "white",
                  }}
                  className={`btnsmall`}
                  disabled={!checkCompPermission("GlossaryField", ["Create"])}
                  onClick={() => history.push("create-fields")}
                  startIcon={
                    <AddIcon style={{ height: "18px", width: "18px" }} />
                  }
                  buttonName={
                    <span style={{ paddingRight: "12px", color: "white" }}>
                      Create
                    </span>
                  }
                />
              )}

              {dataStandardsActiveTab === "fieldgroups" && (
                <AppButton
                  buttonName="create"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  disabled={
                    !checkCompPermission("GlossaryFieldGroup", ["Create"])
                  }
                  className="btnsmall"
                  onClick={() => {
                    history.push("/fieldgroups/add?datastandards=true");
                  }}
                />
              )}

              <Popover
                open={isButtonOpen}
                anchorEl={buttonEl}
                className={styles.customMenuButtonPopup}
                onClose={() => setButtonEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className={styles.customMenuButtonWrap}>
                  <div
                    className="menuItem"
                    onClick={() => history.push("/create-fields")}
                  >
                    <PersonAddOutlined /> <h3>Create Field</h3>
                  </div>
                  <div
                    className="menuItem"
                    onClick={() => history.push("/create-field-groups")}
                  >
                    <img
                      src="/images/stacklist.svg"
                      style={{ background: "white", borderRadius: "50%" }}
                      alt="card"
                    />{" "}
                    <h3>Create Field Group</h3>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>

        <div className={styles.pageContent} style={{ margin: "20px 0 0 0" }}>
          {dataStandardsActiveTab === "datadictionary" &&
            (tableLoading ? (
              <Loader />
            ) : (
              <ApplicationListTable
                // columns={fieldsColumns}
                columns={tableData.columns}
                rows={currentFieldsRows}
                defaultColumnWidths={tableData.widths}
                tableColumnExtensions={tableData.extentions}
                columnOrder={tableData.orders}
                defaultHiddenColumnNames={tableData.hides}
                loading={loading}
                currentPage={currentFieldPage}
                setCurrentPage={setCurrentFieldPage}
                isCustomHeight={true}
                sort={fieldSort}
                setSort={setFieldSort}
                hidden={fieldHidden}
                setHidden={addFieldHidden}
                noDataMsg="No Fields Found"
              />
            ))}

          {dataStandardsActiveTab === "fieldgroups" && (
            <>
              {tableLoadingFieldGroup ? (
                <Loader />
              ) : (
                <ApplicationListTable
                  columns={fieldGrouptableData.columns}
                  rows={fieldgroupRows}
                  defaultColumnWidths={fieldGrouptableData.widths}
                  tableColumnExtensions={fieldGrouptableData.extentions}
                  columnOrder={fieldGrouptableData.orders}
                  defaultSorting={fieldGroupsDefaultSorting}
                  sort={fieldGroupSort}
                  setSort={setFieldGroupSort}
                  currentPage={currentPageFieldGroup}
                  setCurrentPage={setCurrentPageFieldGroup}
                  isCustomHeight={true}
                  hidden={fieldGroupHidden}
                  setHidden={addFieldGroupHidden}
                  noDataMsg="No Field Groups Found"
                />
              )}
            </>
          )}
        </div>

        {currentFieldsRows.length !== 0 &&
          dataStandardsActiveTab === "datadictionary" && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={totalPageCount}
                  page={currentFieldPage}
                  onChange={(e, val) => setCurrentFieldPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}

        {fieldgroupRows.length !== 0 &&
          dataStandardsActiveTab === "fieldgroups" && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={
                    isFavouriteClick
                      ? Math.ceil(fieldgroupRows.length / 10)
                      : Math.ceil(fieldGroups?.data?._totalcount / 10)
                  }
                  page={currentPageFieldGroup}
                  onChange={(e, val) => setCurrentPageFieldGroup(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
      </Container>
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllFieldGroups,
  fetchAllFields,
  setTab,
  setCurrentFieldPage,
  alterActiveTab,
  setCurrentFieldGroupPage,
  setFieldSort,
  setFieldGroupSort,
  addFieldFilters,
  addFieldGroupHidden,
  clearFieldFilters,
  addFieldHidden,
  addFieldGroupFilters,
  clearDataStandardListScreen,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    tab,
    fields,
    currentFieldPage,
    currentFieldGroupPage,
    fieldSort,
    loading,
    fieldFilters,
    fieldHidden,
    fieldGroupHidden,
    fieldGroups,
    fieldGroupSort,
    fieldGroupFilters,
  } = state.dataStandardReducer;
  const { dataStandardsActiveTab } = state.activeTabReducer;
  const { deleteFieldStatus } = fields;
  const { deleteFieldGroupStatus } = fieldGroups;

  return {
    tab,
    fields,
    fieldGroups,
    currentFieldPage,
    currentFieldGroupPage,
    fieldSort,
    fieldGroupSort,
    loading,
    fieldGroupFilters,
    dataStandardsActiveTab,
    fieldFilters,
    fieldHidden,
    fieldGroupHidden,
    addFieldGroupHidden,
    fieldGroups,
    deleteFieldStatus,
    deleteFieldGroupStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataStandardListScreen);
