import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { IssueDetails } from "../../services/issueDetails";
import Loader from "../../components/common/stuff/Loader";
import { useSnackbar } from "notistack";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import { handleFormValidationPro, initError } from "../../utils/common";
import CreateHelpDeskEntity from "../../components/common/entitySchema/helpDesk/CreateHelpDeskEntity";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { getStatusInfo } from "../../redux/actions/statusProfileAction";
import SectionTabs from "../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../components/Tabbed/TabsCommon";

const _IssueDetails = new IssueDetails();

const CreateTicket = ({ history, tabbedView }) => {
  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  let [files, setfiles] = useState([]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  let [photoIndex, setphotoIndex] = useState(0);
  const [loading, setloading] = useState(false);
  const [random, setRandom] = useState(Math.random());
  // entity
  const [helpDeskEntitySchema, setHelpDeskEntitySchema] = useState([]);
  const [helpDeskData, setHelpDeskData] = useState({});
  const [foundData, setFoundData] = useState(false);
  const dispatch = useDispatch();
  const histroy = useHistory();
  const viewedReducer = useSelector((state) => state.viewedReducer);
  const activeTabReducer = useSelector((state) => state.activeTabReducer);
  const statusDetailsReducer = useSelector((state) => state.statusDetails);
  const defaultStatus = statusDetailsReducer?.status?.status?.find(
    (item) => item.default === true
  );

  // Tabbed View
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    history.push("/helpdesk");
  };

  useEffect(() => {
    dispatch(getStatusInfo("Helpdesk"));
  }, []);
  const { recentVisited } = viewedReducer;
  const { helpdesk_default } = activeTabReducer;

  const [issueSelected, setissueSelected] = useState(
    // recentVisited[0] && (recentVisited[0].resourceName === "Helpdesk" ? "" : recentVisited[0].childName)
    helpdesk_default
  );

  const [error, setError] = useState({
    description: false,
    category: false,
    images: false,
  });

  /* eslint-disable */
  useEffect(() => {
    history.location.state !== undefined &&
      fetch(history.location?.state?.img)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "capture.png", { type: "image/png" });
          setfiles([file]);
        });
  }, []);

  const handleImageChange = (event, image) => {
    const newDoc = files.map((e) => {
      if (e.name === image.name) {
        const renameFile = new File([e], event.target.value);
        return renameFile;
      } else {
        return e;
      }
    });
    setfiles(newDoc);
  };

  const handleDeleteImage = (imageToDelete, index) => () => {
    let modifiedFiles = files.filter((img) => img.name !== imageToDelete.name);
    setfiles(modifiedFiles);
    setRandom(Math.random());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { formIsValid, errors } = handleFormValidationPro(
      helpDeskData,
      error
    );
    if (formIsValid) {
      try {
        setloading(true);
        var formData = new FormData();
        formData.append("meta", JSON.stringify(helpDeskData));
        for (let i in files) {
          formData.append("file", files[i]);
        }
        const { data } = await _IssueDetails.createIssuue(formData);
        if (data._status === 200 || 201) {
          setloading(false);
          enqueueSnackbar(data._msg ? "Ticket created successfully" : "", {
            variant: "success",
          });
          histroy.push("/helpdesk");
        }
      } catch (error) {
        setloading(false);
        const { _msg } = error.response.data;
        if (_msg) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        helpDeskEntitySchema ? helpDeskEntitySchema : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setHelpDeskEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleImg = (newFiles) => {
    let tempFiles = [...files];
    newFiles.map((file) => {
      if (files.filter((f) => f.name === file.name).length === 0) {
        tempFiles.push(file);
      }
    });
    if (tempFiles.length !== 0) {
      setfiles(tempFiles);
    }
  };

  useEffect(() => {
    const fetchHelpDeskEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Helpdesk",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        //
        setHelpDeskEntitySchema(entity);
        const projData = {};
        data?.sections?.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        delete projData["uploadimage"];
        //
        setHelpDeskData({ ...helpDeskData, ...projData });
        setFoundData(true);
        const errData = {};
        data?.sections?.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["uploadimage"];

        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchHelpDeskEntitySchema();
  }, []);
  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setHelpDeskData({
      ...helpDeskData,
      status: defaultStatus?.status,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    if (issueSelected) {
      setHelpDeskData({
        ...helpDeskData,
        category: issueSelected,
        status: defaultStatus?.status,
        issue: "",
      });
    }
  }, [foundData]);

  useEffect(() => {
    checkScreenPermission("Helpdesk", ["Create"]);
  }, []);
  return (
    <main className={styles.main}>
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Help Desk", path: "/helpdesk" },
              { title: "Create Ticket" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            {loading && <Loader />}
            <h2 className={styles.heading}>Create Ticket</h2>
            {tabbedView ? (
              <TabbedNavigation
                isCreateMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AppButton
                color="primary"
                buttonName="Send"
                variant="contained"
                // disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                disabled={!checkCompPermission("Helpdesk", ["Create"])}
                onClick={handleSubmit}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={helpDeskEntitySchema}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <CreateHelpDeskEntity
                    section={helpDeskEntitySchema?.[activeTab]}
                    helpDeskData={helpDeskData}
                    setHelpDeskData={setHelpDeskData}
                    error={error}
                    setError={setError}
                    handleChange={handleChange}
                    handleImg={handleImg}
                    random={random}
                  />
                </div>
                {activeTab == 1 && files.length > 0 && (
                  <Grid item xs={8} style={{ marginTop: 20 }}>
                    <Grid item container xs>
                      <Grid item container xs>
                        {files.length > 0 &&
                          files.map((image, index) => (
                            <Grid container style={{ marginBottom: 23 }}>
                              <Grid item xs={4}>
                                <Avatar
                                  alt="img"
                                  variant="circle"
                                  src={URL.createObjectURL(image)}
                                  className={styles.avatar}
                                  onClick={() => {
                                    setViewerIsOpen(true);
                                    setphotoIndex(index);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "auto",
                                    marginRight: 16,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={7}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  File Name
                                </label>
                                <input
                                  type="text"
                                  className={styles.input}
                                  name="name"
                                  value={image.name.replace(
                                    /[&\/\\#,+()$~%_'":*?<>{}]/g,
                                    ""
                                  )}
                                  onChange={(e) => handleImageChange(e, image)}
                                  autoComplete="off"
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  color="inherit"
                                  aria-label="delete"
                                  edge="start"
                                  onClick={handleDeleteImage(image, index)}
                                  className={styles.actionIcon}
                                >
                                  <img
                                    src="/images/deleteLine.svg"
                                    alt="card"
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </>
          ) : (
            <div>
              {helpDeskEntitySchema &&
                helpDeskEntitySchema.map((section) => {
                  if (section.name === "Helpdesk_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "15px 5px 0" }}>
                        <CreateHelpDeskEntity
                          section={section}
                          helpDeskData={helpDeskData}
                          setHelpDeskData={setHelpDeskData}
                          error={error}
                          setError={setError}
                          handleChange={handleChange}
                          handleImg={handleImg}
                          random={random}
                        />
                      </div>
                    );
                  } else if (section.name === "Helpdesk_Image_Section") {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container lg>
                            <Grid item xs={4}>
                              <CreateHelpDeskEntity
                                section={section}
                                helpDeskData={helpDeskData}
                                setHelpDeskData={setHelpDeskData}
                                error={error}
                                setError={setError}
                                handleChange={handleChange}
                                handleImg={handleImg}
                                random={random}
                              />
                            </Grid>
                            {files.length > 0 && (
                              <Grid item xs={8} style={{ marginTop: 20 }}>
                                <Grid item container xs>
                                  <Grid item container xs>
                                    {files.length > 0 &&
                                      files.map((image, index) => (
                                        <Grid
                                          container
                                          style={{ marginBottom: 23 }}
                                        >
                                          <Grid item xs={4}>
                                            <Avatar
                                              alt="img"
                                              variant="circle"
                                              src={URL.createObjectURL(image)}
                                              className={styles.avatar}
                                              onClick={() => {
                                                setViewerIsOpen(true);
                                                setphotoIndex(index);
                                              }}
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginRight: 16,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={7}>
                                            <label
                                              htmlFor=""
                                              className={styles.fieldHeading}
                                            >
                                              File Name
                                            </label>
                                            <input
                                              type="text"
                                              className={styles.input}
                                              name="name"
                                              value={image.name.replace(
                                                /[&\/\\#,+()$~%_'":*?<>{}]/g,
                                                ""
                                              )}
                                              onChange={(e) =>
                                                handleImageChange(e, image)
                                              }
                                              autoComplete="off"
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <IconButton
                                              color="inherit"
                                              aria-label="delete"
                                              edge="start"
                                              onClick={handleDeleteImage(
                                                image,
                                                index
                                              )}
                                              className={styles.actionIcon}
                                            >
                                              <img
                                                src="/images/deleteLine.svg"
                                                alt="card"
                                              />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CreateHelpDeskEntity
                            section={section}
                            helpDeskData={helpDeskData}
                            setHelpDeskData={setHelpDeskData}
                            error={error}
                            setError={setError}
                            handleChange={handleChange}
                            handleImg={handleImg}
                            random={random}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </form>
      {viewerIsOpen && (
        <Lightbox
          wrapperClassName={styles.testModal}
          imagePadding={75}
          enableZoom={true}
          onCloseRequest={() => setViewerIsOpen(false)}
          mainSrc={URL.createObjectURL(files[photoIndex])}
          nextSrc={URL.createObjectURL(files[(photoIndex + 1) % files.length])}
          prevSrc={URL.createObjectURL(
            files[(photoIndex + files.length - 1) % files.length]
          )}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + files.length - 1) % files.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % files.length)
          }
        />
      )}
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  // const { status } = state.statusDetailsReducer;

  const { tabbedView } = state.userDetails.userInfo;
  return {
    // status,
    tabbedView,
  };
};

// export default CreateTicket;
export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
