const clusterPodsColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "Name", for: "clusterPods" },
  { name: "applicationname", title: "Application Name", for: "clusterPods" },
  { name: "namespace", title: "Namespace", for: "clusterPods" },
  { name: "phase", title: "Status", for: "clusterPods" },
  { name: "node", title: "Node", for: "clusterPods" },
  { name: "creationTimestamp", title: "Created", for: "clusterPods" },
  { name: "restart", title: "Restart", for: "clusterPods" },
  { name: "labels", title: "Labels", for: "clusterPods" },
];

const clusterPodsDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: -4.5, width: 200 },
  { columnName: "namespace", weightage: -4.5, width: 200 },
  { columnName: "phase", weightage: 4.5, width: 200 },
  { columnName: "node", weightage: 1.5, width: 200 },
  { columnName: "creationTimestamp", weightage: -1.5, width: 200 },
  { columnName: "applicationname", weightage: -3.5, width: 200 },
  { columnName: "restart", weightage: 2.5, width: 200 },
  { columnName: "labels", weightage: 2.5, width: 200 },
];

const clusterPodsTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "namespace", align: "left" },
  { columnName: "phase", align: "left" },
  { columnName: "node", align: "left" },
  { columnName: "created", align: "left" },
  { columnName: "applicationname", align: "left" },
  { columnName: "restart", align: "left" },
  { columnName: "labels", align: "left" },
];

const clusterPodsColumnOrder = [
  // 'id',
  "name",
  "namespace",
  "phase",
  "node",
  "created",
  "applicationname",
  "restart",
  "labels",
];

const clusterPodsColumnHidden = [
  // 'id',
  // "name",
  // "namespace",
  // "pods",
  // "creationTimestamp",
  "restart",
  "labels",
  "namespace",
  "node",
];

const clusterPodsDefaultSorting = [];

export {
  clusterPodsColumns,
  clusterPodsDefaultColumnWidths,
  clusterPodsTableColumnExtensions,
  clusterPodsColumnOrder,
  clusterPodsDefaultSorting,
  clusterPodsColumnHidden,
};
