import React from "react";
import Editor from "react-simple-code-editor";
import { makeStyles } from "@material-ui/core"
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another

const useStyles = makeStyles((theme) => ({
    textarea: {
        border: "1px solid black"
    }
}))

export default function PrismEditor(props) {
    const styles = useStyles();
    // const [code, setCode] = React.useState(
    //     `function add(a, b) {\n  return a + b;\n}`
    // );
    const { code, setCode } = props;
    return (
        <Editor
            value={code ? code : ""}
            onValueChange={(val) => setCode(val)}
            highlight={(c) => highlight(c, languages.js)}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                minHeight: "10rem",
                // border: "1px soild gray"
            }}
            textareaClassName={styles.textarea}
        />
    );
}