import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Checkbox,
} from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import manageStyles from '../../../../screens/CommonStyles/manageScreenStyles'
import CreateCustomerStyles from '../../../../screens/CommonStyles/CreateAndDetailsScreenStyle'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers'
import AddIcon from '@material-ui/icons/Add'

const UserDetailsEntitySchemaHelper = ({
  section,
  userData,
  setUserData,
  userteamDetails,
  handleChange,
  isEditMode,
  userInfo,
  error,
  setError,
  open,
  handleClickOpen,
  handleClose,
  handleAddRolestoUser,
  handleDelete,
  rolesList,
  addRoleToUser,
  tabbedView,
  setAddRoleToUser,
  setRolesList,
}) => {
  const styles = manageStyles()
  const classes = CreateCustomerStyles()

  const userRolesField = () => {
    const handleRoleCheck = (id) => {
      let tempRoles = rolesList?.map((item) => {
        if (item?._id === id) {
          return {
            ...item,
            checked: !item.checked,
          }
        } else {
          return item
        }
      })
      setRolesList(tempRoles)
    }
    console.log('sjkvskv', error)

    return (
      <>
        <div>
          <div style={{ marginTop: 10 }} className={styles.addTeamMember}>
            <div>
              <Typography style={{ height: 35 }} className={classes.fieldHeading}>
                Assign Roles <span className={styles.red}>*</span>
              </Typography>
            </div>
            <div
              style={{ marginTop: -18 }}
              className={styles.addTeamModal}
              onClick={handleClickOpen}
            >
              <AddIcon />
              <span>Add</span>
            </div>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                style={{
                  padding: '0 25px',
                  backgroundColor: '#F9FAFD',
                }}
              >
                <div className={styles.assignProject}>
                  <div>
                    <span
                      style={{
                        font: 'normal normal 600 14px/19px Nunito',
                      }}
                    >
                      Add Role
                    </span>
                  </div>
                </div>
              </DialogTitle>

              <DialogContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></div>
                <div style={{ height: 400, width: 550 }}>
                  <Grid container>
                    {rolesList &&
                      rolesList?.map((member, _id) => (
                        <Grid item xs={12} key={_id}>
                          <Paper className={styles.selectMemebrModal}>
                            <div className={styles.teamWrapper2}>
                              <Checkbox
                                color="primary"
                                value={member._id}
                                checked={member?.checked}
                                onChange={(e) => handleRoleCheck(e.target.value)}
                              />

                              <div className={styles.memberDeatils2}>
                                <Typography className={styles.teamMemberName}>
                                  {member.name}
                                </Typography>
                                <Typography className={styles.teamMemberDesgination}>
                                  {member.email}
                                </Typography>
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </DialogContent>

              <DialogActions
                style={{
                  backgroundColor: '#F9FAFD',
                  justifyContent: 'space-between',
                }}
              >
                <div></div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: 250,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    className={styles.cancel}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleAddRolestoUser}
                    className={styles.submitMember}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Add Role
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: 50,
            marginTop: 50,
          }}
        >
          {/* <Grid container spaceing={1} style={{ marginTop: 10 }}> */}
          {userData &&
            userData?.roles?.length > 0 &&
            userData?.roles.map((item) => {
              return (
                // <Grid item xs={12} sm={6} md={4} lg={3}>
                //   <>
                <div
                  style={{
                    marginTop: 35,
                    height: 30,
                    width: 250,
                    margin: '10px',
                    border: '1px solid gray',
                    borderRadius: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 30px',
                    font: 'normal normal 600 16px/22px Nunito Sans',
                  }}
                >
                  <div>
                    <span>{item.name}</span>
                  </div>
                  <div
                    style={{ marginTop: 6, cursor: 'pointer' }}
                    onClick={() => handleDelete(item._id)}
                  >
                    <img src="/images/users/cancel.png" alt="" height="18px" width="18px" />
                  </div>
                </div>
                //   </>
                // </Grid>
              )
            })}
          {/* </Grid> */}
        </div>
      </>
    )
  }

  const userTeamsField = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: 50,
          }}
        >
          {userteamDetails &&
            userteamDetails.map((item) => {
              return (
                <div
                  style={{
                    width: 230,
                    height: 60,
                    background: '#F5F5F5',
                    border: '1px solid #CBCBCB',
                    borderRadius: 5,
                    // textAlign: "center",
                    alignSelf: 'center',
                    margin: '10px',
                  }}
                >
                  <p
                    style={{
                      margin: '5px 0 5px 10px',
                      font: 'normal normal 600 14px/19px Nunito',
                    }}
                  >
                    {item.name}
                  </p>
                  <p style={{ margin: '0 0 0 10px' }}>{item.type}</p>
                </div>
              )
            })}
        </div>
      </>
    )
  }

  const generateField = (field) => {
    switch (field.uiElementType) {
      case 'TextField':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <input
              type={field?.fieldLabel === 'Contact Number' ? 'number' : 'text'}
              name={field.datakey}
              className={`${styles.input} ${!isEditMode && styles.editMode} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === 'FALSE' && styles.editMode}`}
              value={
                field.dataType === 'date' && userData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                        .unix(userData[field.datakey])
                        .tz(userInfo?.timeZone)
                        .format(
                          userInfo.time === 'h:mm'
                            ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                            : `${userInfo?.dateFormat} ${userInfo?.time}`,
                        )
                    : moment.unix(userData[field.datakey]).format('DD/MM/YYYY, hh:mm:ss A')
                  : userData[field.datakey]
              }
              autoComplete="off"
              disabled={!isEditMode || field.editable === 'FALSE'}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        )
      case 'TextArea':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <textarea
              name={field.datakey}
              value={userData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === 'FALSE' && styles.editMode}`}
              disabled={!isEditMode || field.editable === 'FALSE'}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        )
      case 'Dropdown':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <Select
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ''}
              onChange={handleChange}
              disabled={!isEditMode || field.editable === 'FALSE'}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === 'FALSE' && styles.editMode}`}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      case 'Date':
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <DesktopDatePicker
              value={userData[field.datakey] ? userData[field.datakey] : null}
              onChange={(date) => handleChange({ target: { name: field.datakey, value: date } })}
              inputFormat={'dd/MM/yyyy'}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      name={field.datakey}
                      className={`${styles.input} ${!isEditMode && styles.editMode} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === 'FALSE' && styles.editMode}`}
                      value={
                        field.dataType === 'date' && userData[field.datakey]
                          ? userInfo?.timeZone
                            ? moment
                                .unix(userData[field.datakey])
                                .tz(userInfo?.timeZone)
                                .format(
                                  userInfo.time === 'h:mm'
                                    ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                                    : `${userInfo?.dateFormat} ${userInfo?.time}`,
                                )
                            : moment.unix(userData[field.datakey]).format('DD/MM/YYYY, hh:mm:ss A')
                          : userData[field.datakey]
                      }
                      autoComplete="off"
                      disabled={!isEditMode || field.editable === 'FALSE'}
                      onChange={handleChange}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${
                        !isEditMode && styles.editModeDatePicker
                      }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        )
      case 'Currency':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={userData[`${field.datakey}_unit`] ? userData[`${field.datakey}_unit`] : ''}
                onChange={handleChange}
                disabled={!isEditMode || field.editable === 'FALSE'}
                className={`${styles.select} ${!isEditMode && styles.editMode} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>{error[`${field.datakey}_unit`].msg}</p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === 'FALSE' && styles.editMode}`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === 'FALSE'}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>{error[`${field.datakey}_value`].msg}</p>
              )}
            </Grid>
          </Grid>
        )
      case 'Amount':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={userData[`${field.datakey}_unit`] ? userData[`${field.datakey}_unit`] : ''}
                onChange={handleChange}
                disabled={!isEditMode || field.editable === 'FALSE'}
                className={`${styles.select} ${!isEditMode && styles.editMode} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>{error[`${field.datakey}_unit`].msg}</p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === 'FALSE' && styles.editMode}`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === 'FALSE'}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>{error[`${field.datakey}_value`].msg}</p>
              )}
            </Grid>
          </Grid>
        )
      case 'RadioButton':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <RadioGroup
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ''}
              onChange={handleChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={
                    <Radio disabled={!isEditMode || field.editable === 'FALSE'} color="primary" />
                  }
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === 'User_Header_section' && index !== 0 && (
                <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>
              )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === 'TextArea').length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: '0rem', display: 'flex' }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case 'Roles':
                          return (
                            <div style={{ marginBottom: tabbedView ? 40 : 0 }}>
                              {userRolesField()}
                            </div>
                          )
                        case 'Teams':
                          return <>{userTeamsField()}</>
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          )
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid container spacing={3} style={{ marginTop: '0rem' }} key={fieldgroup._id}>
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== 'TextArea')
                          .map((field) => {
                            switch (field.name) {
                              case 'Roles':
                                return (
                                  <div style={{ marginBottom: tabbedView ? 20 : 0 }}>
                                    {userRolesField()}
                                  </div>
                                )
                              case 'Teams':
                                return <>{userTeamsField()}</>
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    <label htmlFor="" className={styles.fieldHeading}>
                                      {field?.fieldLabel}
                                      {field?.required === 'TRUE' && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      name={field.datakey}
                                      className={`${styles.input} ${
                                        field.editable === 'FALSE' && styles.editMode
                                      }`}
                                      value={userData[field.datakey]}
                                      autoComplete="off"
                                      disabled={field.editable === 'FALSE'}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                )
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === 'TextArea')
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label htmlFor="" className={styles.fieldHeading}>
                                  {field?.fieldLabel}
                                  {field?.required === 'TRUE' && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={userData[field.datakey]}
                                  // onChange={handleChange}
                                  className={`${styles.textarea} ${
                                    field.editable === 'FALSE' && styles.editMode
                                  }`}
                                  disabled={field.editable === 'FALSE'}
                                  rows="7"
                                ></textarea>
                              </Grid>
                            )
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          )
        })}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails
  const { tabbedView } = state.userDetails.userInfo

  return {
    userInfo,
    tabbedView,
  }
}

export default connect(mapStateToProps)(UserDetailsEntitySchemaHelper)
