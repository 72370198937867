const clusterDeploymentColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "Name", for: "clusterDeployment" },
  { name: "namespace", title: "Namespace", for: "clusterDeployment" },
  { name: "pods", title: "Pods", for: "clusterDeployment" },
  { name: "creationTimestamp", title: "Created", for: "clusterDeployment" },
  {
    name: "deploymentname",
    title: "Application Name",
    for: "clusterDeployment",
  },
  { name: "image", title: "Image", for: "clusterDeployment" },
];

const clusterDeploymentDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: -6.5, width: 200 },
  { columnName: "namespace", weightage: -8.5, width: 200 },
  { columnName: "pods", weightage: 2.5, width: 200 },
  { columnName: "creationTimestamp", weightage: -3.5, width: 200 },
  { columnName: "deploymentname", weightage: 1.5, width: 200 },
  { columnName: "image", weightage: 1.5, width: 200 },
];

const clusterDeploymentTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "namespace", align: "left" },
  { columnName: "pods", align: "left" },
  { columnName: "creationTimestamp", align: "left" },
  { columnName: "deploymentname", align: "left" },
  { columnName: "image", align: "left" },
];

const clusterDeploymentColumnOrder = [
  // 'id',
  "name",
  "namespace",
  "pods",
  "creationTimestamp",
  "deploymentname",
  "image",
];

const clusterDeploymentColumnHidden = [
  // 'id',
  // "name",
  // "namespace",
  // "pods",
  // "creationTimestamp",
  "deploymentname",
  "image",
];

const clusterDeploymentDefaultSorting = [];

export {
  clusterDeploymentColumns,
  clusterDeploymentDefaultColumnWidths,
  clusterDeploymentTableColumnExtensions,
  clusterDeploymentColumnOrder,
  clusterDeploymentDefaultSorting,
  clusterDeploymentColumnHidden,
};
