import React from 'react';
import {
    IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from "@material-ui/core";
import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import { useSnackbar } from 'notistack';
import useCheckPermission from './hooks/useCheckPermission';
import { getValue } from "../../utils/dashboard/dashboardUtils";

const BarLineLegend = ({ chart, styles }) => (
    <>
        {chart?.chartData.map((dataset, dataindex) =>
            <div className={styles.legends} key={dataindex}>
                <h5 className={styles.legendTitle} style={{ marginRight: 'auto', marginBottom: 5, marginTop: 5 }}>
                    {chart?.graphDetails?.fields.find(field => field.queryField === chart?.graphDetails?.aggregates[dataindex].field)?.label}
                </h5>
                <TableContainer className={styles.legendTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Name</TableCell>
                                {Object.keys(dataset).map((key, index) =>
                                    <TableCell align="center" key={index}>{getValue(key)}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Color</TableCell>
                                {Object.keys(dataset).map((key, index) =>
                                    <TableCell align="center" key={index}>
                                        <div className={styles.legendColor} style={{ backgroundColor: chart.data.datasets[dataindex].backgroundColor }} />
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Value</TableCell>
                                {Object.values(dataset).map((value, index) =>
                                    <TableCell align="center" key={index}>{value}</TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )}
    </>
)

const PieDonutLegend = ({ chart, styles }) => (
    <>
        <h5 className={styles.legendTitle}>Total {chart?.graphDetails?.graphTitle} - {chart?.options.totalCount}</h5>
        {chart?.chartData.map((dataset, dataindex) =>
            <div className={styles.legends} key={dataindex}>
                <h5 className={styles.legendTitle} style={{ marginRight: 'auto', marginBottom: 5, marginTop: 5 }}>
                    {chart?.graphDetails?.fields.find(field => field.queryField === chart?.graphDetails?.aggregates[dataindex].field)?.label}
                </h5>
                <TableContainer className={styles.legendTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Name</TableCell>
                                {Object.keys(dataset).map((key, index) =>
                                    <TableCell align="center" key={index}>{getValue(key)}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Color</TableCell>
                                {Object.keys(dataset).map((key, index) =>
                                    <TableCell align="center" key={index}>
                                        <div className={styles.legendColor} style={{ backgroundColor: chart.data.datasets[dataindex].backgroundColor[index] }} />
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Value</TableCell>
                                {Object.values(dataset).map((value, index) =>
                                    <TableCell align="center" key={index}>{value}</TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )}
    </>
)

const StackLengend = ({ chart, styles }) => {
    return <>
        {chart?.chartData.map((dataset, dataindex) =>
            <div className={styles.legends} key={dataindex}>
                <h5 className={styles.legendTitle} style={{ marginRight: 'auto', marginBottom: 5 }}>
                    {chart?.graphDetails?.fields.find(field => field.queryField === chart?.graphDetails?.aggregates[dataindex].field)?.label}
                </h5>
                <TableContainer className={styles.legendTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Name</TableCell>
                                {Object.keys(dataset).map((key, index) =>
                                    <TableCell align="center" key={index}>{getValue(key)}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Value</TableCell>
                                {Object.values(dataset).map((value, index) =>
                                    <TableCell align="center" key={index}>
                                        {Object.keys(value).map((key, keyindex) =>
                                            <div className={styles.legendValue} key={keyindex}>
                                                <div className={styles.legendColor}
                                                    style={{
                                                        backgroundColor: chart.data.bgColor[dataindex].find(color => color.label === key).color,
                                                        width: 10,
                                                        marginRight: 10
                                                    }}
                                                />
                                                <h5 className={styles.legendPara}>{getValue(key)} : {value[key]}</h5>
                                            </div>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )}
    </>
}

const CustomChart = ({ chart, styles, setEditData, setIsEditChartModalOpen, handleDeleteChart, setMaximizeChart }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { checkCompPermission } = useCheckPermission()
    const getChart = (chart) => {
        switch (chart?.graphDetails?.chartType) {
            case "bar":
                return <>
                    <Bar
                        data={chart?.data}
                        options={chart?.options} />
                    {chart?.graphDetails?.showLegends && <BarLineLegend chart={chart} styles={styles} />}
                </>
            case "stack_bar":
                return <>
                    <Bar
                        data={chart?.data}
                        options={chart?.options} />
                    {chart?.graphDetails?.showLegends && <StackLengend chart={chart} styles={styles} />}
                </>
            case "line":
                return <>
                    <Line
                        data={chart?.data}
                        options={chart?.options} />
                    {chart?.graphDetails?.showLegends && <BarLineLegend chart={chart} styles={styles} />}
                </>
            case "pie":
                return <>
                    <div className={styles.donughtChart}>
                        <Pie
                            data={chart?.data}
                            options={chart?.options} />
                    </div>
                    {chart?.graphDetails?.showLegends && <PieDonutLegend chart={chart} styles={styles} />}
                </>
            case "donut":
                return <>
                    <div className={styles.donughtChart}>
                        <Doughnut
                            data={chart?.data}
                            options={chart?.options}
                            plugins={[{
                                beforeDraw: function (chart, args, options) {
                                    var width = chart.width
                                    var height = chart.height
                                    var ctx = chart.ctx;

                                    ctx.restore();
                                    ctx.font = "normal normal 600 20px/22px Nunito";
                                    ctx.textBaseline = "middle";

                                    var text = 'Total - ' + chart?.options.totalCount
                                    var textX = Math.round((width - ctx.measureText(text).width) / 2)
                                    var textY = height / 2;

                                    ctx.fillText(text, textX, textY);
                                    ctx.save();
                                }
                            }]}
                        />
                    </div>
                    {chart?.graphDetails?.showLegends && <PieDonutLegend chart={chart} styles={styles} />}
                </>
            default:
                return <></>
        }
    }

    return (
        <div className={styles.chartCard}>
            <div className={styles.chartCardHeader}>
                <h5 className={styles.legendTitle}>{chart?.graphDetails?.graphTitle}</h5>
                <div className={styles.chartCartActionIcons}>
                    {(!chart?.error && chart?.options.totalCount !== 0) && <Tooltip title="Maximize Chart" arrow>
                        <IconButton
                            color="inherit"
                            aria-label="edit"
                            edge="start"
                            className={styles.chartCartActionIcon}
                            onClick={() => {
                                setMaximizeChart({
                                    component: getChart(chart),
                                    chart: chart,
                                    isOpen: true,
                                })
                            }}
                        >
                            <img src="/images/maximize.svg" alt="maximize" />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title="Edit Chart" arrow>
                        <IconButton
                            color="inherit"
                            aria-label="edit"
                            edge="start"
                            className={styles.chartCartActionIcon}
                            onClick={() => {
                                if (checkCompPermission('Dashboard', ['Enable Dashboard'])) {
                                    setEditData(chart?.graphDetails)
                                    setIsEditChartModalOpen(true)
                                } else {
                                    enqueueSnackbar(
                                        `You do not have permission to access EnableDashboard Dashboard. Please contact your Admin.`,
                                        { variant: "error" }
                                    );
                                }
                            }}
                        >
                            <img src="/images/editLine.svg" alt="card" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Chart" arrow>
                        <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            className={styles.chartCartActionIcon}
                            onClick={() => {
                                if (checkCompPermission('Dashboard', ['Enable Dashboard'])) {
                                    handleDeleteChart(chart?.graphDetails?._id)
                                } else {
                                    enqueueSnackbar(
                                        `You do not have permission to access EnableDashboard Dashboard. Please contact your Admin.`,
                                        { variant: "error" }
                                    );
                                }
                            }}
                        >
                            <img src="/images/deleteLine.svg" alt="delete" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.chartCardContent}>
                {(!chart?.error && chart?.options.totalCount !== 0 && Object.keys(chart?.chartData[0]).length !== 0 ) && getChart(chart)}
                {chart?.error && <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <h5 className={styles.legendTitle} style={{ fontSize: 15 }}>{chart.data}</h5>
                    <p className={styles.legendPara}>{chart._msg}</p>
                </div>}
                {(!chart?.error && chart?.options.totalCount === 0) || (Object.keys(chart?.chartData[0]).length === 0) && <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <h5 className={styles.legendTitle} style={{ fontSize: 15 }}>{'No data returned for this graph. Please try changing the query details'}</h5>
                </div>}
            </div>
        </div>
    )
}

export default CustomChart
