import React, { useEffect, useState } from "react";
import {
  Grid
} from "@material-ui/core";
import Loader from "../../components/common/stuff/Loader";
import dashboardStyles from "../CommonStyles/dashboardStyles";
import AddIcon from "@material-ui/icons/Add";
import AddChartModal from "../../components/common/modals/AddChartModal";
import { AppButton } from "../../components/common/Button";
import { deleteGraph, executeGraph, generateChartData, getUserCharts } from "../../utils/dashboard/dashboardUtils";
import { useSnackbar } from "notistack";
import EditChartModal from "../../components/common/modals/EditChartModal";
import CustomChart from "../../components/common/CustomChart";
import MaximizeChartModal from "../../components/common/modals/MaximizeChartModal";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { DeleteModal } from "../../components/common/modals/DeleteModal";

const Dashboard = () => {
  const styles = dashboardStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [charts, setCharts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddChartModalOpen, setIsAddChartModalOpen] = useState(false)
  const [isEditChartModalOpen, setIsEditChartModalOpen] = useState(false)
  const [maximizeChart, setMaximizeChart] = useState({ isOpen: false, chart: null })
  const [editData, setEditData] = useState({})

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedGraphId, setSelectedGraphId] = useState()


  const { checkCompPermission } = useCheckPermission()

  useEffect(() => {
    const fetchCharts = async () => {
      setIsLoading(true)
      const { _msg, _status, data } = await getUserCharts();
      if (_status === 200) {
        for (var i = 0; i < data?.length; i++) {
          const executeGraphData = await fetchExecuteGraph(data[i]?._id)
          if (executeGraphData?.graphDetails?._id) {
            setCharts(prevCharts => [...prevCharts, generateChartData(executeGraphData)])
          } else {
            setCharts(prevCharts => [...prevCharts, { ...executeGraphData, graphDetails: data[i], error: true }])
          }
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)

        enqueueSnackbar(_msg ? _msg : 'Failed to load Dashboard, try again after sometime', { variant: "error" });
      }
    };
    fetchCharts()
  }, [])

  const fetchExecuteGraph = async (graphId) => {
    const { _msg, _status, data } = await executeGraph(graphId);
    if (_status === 200) {
      return data
    } else {

      return { _msg, data }
    }
  };

  const handleCreateChart = async (chartData) => {
    const executeGraphData = await fetchExecuteGraph(chartData?._id)
    if (executeGraphData?.graphDetails?._id) {
      setCharts(prevCharts => [...prevCharts, generateChartData(executeGraphData)])
    } else {
      setCharts(prevCharts => [...prevCharts, { ...executeGraphData, graphDetails: chartData, error: true }])
    }
  }

  const handleEditChart = async (chartData) => {
    const executeGraphData = await fetchExecuteGraph(chartData?._id)
    if (executeGraphData?.graphDetails?._id) {
      setCharts(prevCharts => prevCharts.map(chart => {
        if (chart?.graphDetails?._id === executeGraphData?.graphDetails?._id) {
          return generateChartData(executeGraphData)
        } else {
          return chart
        }
      }))
    } else {
      setCharts(prevCharts => prevCharts.map(chart => {
        if (chart.graphDetails._id === chartData?._id) {
          return { ...executeGraphData, graphDetails: chartData, error: true }
        } else {
          return chart
        }
      }))
    }
  }

  const handleDeleteConfirm = (graphId) => {
    setShowDeleteModal(true)
    setSelectedGraphId(graphId)
  }

  const handleDeleteChart = async () => {
    setShowDeleteModal(false)
    setIsLoading(true)
    const { _msg, _status } = await deleteGraph(selectedGraphId);
    if (_status === 201) {
      enqueueSnackbar(_msg ? _msg : "Graph deleted successfully", {
        variant: "success",
      });
      setCharts(prevCharts => prevCharts.filter(chart => chart.graphDetails?._id !== selectedGraphId))
      setIsLoading(false)

    } else {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setIsLoading(false)

    }
  }

  return (
    <main className={styles.main}>
      <DeleteModal open={showDeleteModal} handleDelete={handleDeleteChart} handleClose={() => setShowDeleteModal(false)} />
      {isLoading && <Loader />}
      <div className={styles.topContent}>
        <div className={styles.topContentItems}>
          <p className={styles.title}>Dashboard</p>
          <AppButton
            color="primary"
            startIcon={
              <AddIcon style={{ width: "16px", height: "16px" }} />
            }
            buttonName="Create Chart"
            variant="contained"
            disabled={!checkCompPermission('Dashboard', ['Enable Dashboard'])}
            style={{ margin: 'unset' }}
            className="btnsmall"
            onClick={() => setIsAddChartModalOpen(true)}
          />
        </div>
      </div>
      {isLoading ? <Loader /> : <div className={styles.wrapper}>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={6}>
            {charts.length !== 0 && charts.filter((c, index) => index % 2 === 0).map((chart, index) => (
              <CustomChart
                chart={chart}
                key={index}
                styles={styles}
                setEditData={setEditData}
                setIsEditChartModalOpen={setIsEditChartModalOpen}
                handleDeleteChart={handleDeleteConfirm}
                setMaximizeChart={setMaximizeChart}
              />
            ))}
          </Grid>
          <Grid item sm={6} xs={6}>
            {charts.length !== 0 && charts.filter((c, index) => index % 2 === 1).map((chart, index) => (
              <CustomChart
                chart={chart}
                key={index}
                styles={styles}
                setEditData={setEditData}
                setIsEditChartModalOpen={setIsEditChartModalOpen}
                handleDeleteChart={handleDeleteConfirm}
                setMaximizeChart={setMaximizeChart}
              />
            ))}
          </Grid>
        </Grid>
      </div>}
      {maximizeChart.isOpen && <MaximizeChartModal
        maximizeChart={maximizeChart}
        setMaximizeChart={setMaximizeChart}
      />}
      {isAddChartModalOpen && <AddChartModal
        isAddChartModalOpen={isAddChartModalOpen}
        setIsAddChartModalOpen={setIsAddChartModalOpen}
        handleCreateChart={handleCreateChart}
      />}
      {isEditChartModalOpen && <EditChartModal
        isEditChartModalOpen={isEditChartModalOpen}
        setIsEditChartModalOpen={setIsEditChartModalOpen}
        handleEditChart={handleEditChart}
        editData={editData}
      />}
    </main>
  );
};

export default Dashboard;