import { ADD_ROLES_DATA, DELETE_ROLE, ROLE_FILTERS, ROLE_SORT } from "../actionTypes";
import { AccessMaintananceServices } from "../../services/accessMaintainanceService";

const AccessMaintananceService = new AccessMaintananceServices();

function add_all_roles_list_data(payload) {
  return {
    type: ADD_ROLES_DATA,
    payload: payload,
  };
}

export function add_all_roles_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_all_roles_list_data({
        loading: true,
      })
    );
    AccessMaintananceService.getAllRoles(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_all_roles_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount
              })
            );
          } else {
            dispatch(
              add_all_roles_list_data({
                loading: false,
                data: [],
                error: false,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_all_roles_list_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      });
  };
}

export const addRoleFilters = (roleFilters) => {
  return {
    type: ROLE_FILTERS,
    payload: roleFilters
  }
};

export const addRoleSort = (roleSort) => {
  return {
    type: ROLE_SORT,
    payload: roleSort
  }
};

export const deleteRoleById = (roleId) => {
  return {
    type: DELETE_ROLE,
    payload: {
      roleId
    }
  }
};