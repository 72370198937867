import React, { useEffect, useState } from "react";
import TextAreaAdvance from "../../atoms/textArea/TextAreaAdvance";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import useTriggers from "../../../hooks/useTriggers";

const TextAreaComponent = ({ id, project }) => {
  const params = useParams();
  const { handleTrigger } = useTriggers();
  const dispatch = useDispatch();
  const [textArea, setTextArea] = useState("");
  const [label, setLabel] = useState("Textarea");
  const [flag, setFlag] = useState(false);
  const currentPage = project.pages.find((page) => page.url === params?.page);

  const selectedComponent =
    currentPage.components.length &&
    currentPage.components.find((comp) => comp.id === id);

  const handleChangeTextArea = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          data: {
            ...component.data,
            dataValue: e.target.value,
            dataInput: e.target.value,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: project.id,
        pageid: currentPage.id,
        data: components,
      })
    );
    if (selectedComponent["onChange"]) {
      setFlag(true);
    }
  };

  const handleClick = () => {
    if (selectedComponent["onClick"])
      handleTrigger({
        action: selectedComponent?.["onClick"],
        isUpdateState: true,
        componentId: selectedComponent?.id,
        data: [],
      });
  };

  useEffect(() => {
    if (flag === true) {
      if (selectedComponent["onChange"]) {
        handleTrigger({
          action: selectedComponent?.["onChange"],
          isUpdateState: true,
          componentId: selectedComponent?.id,
          data: [],
        });
        setFlag(false);
      }
    }
  }, [flag]);
  const labelText = selectedComponent?.label?.dataValue;
  const placeHolderText = selectedComponent?.placeHolder?.dataValue;
  return (
    <div style={{ padding: "5px 10px 0px 10px" }} onClick={() => handleClick()}>
      <TextAreaAdvance
        name="textArea"
        rows={selectedComponent?.rows}
        placeholder={
          placeHolderText && typeof placeHolderText === "string"
            ? placeHolderText
            : "Label"
        }
        label={labelText && typeof labelText === "string" ? labelText : "Label"}
        value={selectedComponent?.data?.dataValue}
        onChange={(event) => handleChangeTextArea(event)}
        selectedComponent={selectedComponent}
      />
    </div>
  );
};

export default TextAreaComponent;
