const copyServiceColumns = [
  { name: "name", title: "Service Name", for: "copyservice" },
  { name: "versionTag", title: "Version", for: "copyservice" },
  { name: "imagename", title: "Image", for: "copyservice" },
];

const copyServiceDefaultColumnWidths = [
  { columnName: "name", weightage: 4, width: 200 },
  { columnName: "versionTag", weightage: 4, width: 200 },
  { columnName: "imagename", weightage: 4, width: 200 },
];

const copyServiceTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "versionTag", align: "left" },
  { columnName: "imagename", align: "left" },
];

const copyServiceColumnOrder = [
  "name",
  "versionTag",
  "imagename"
];

const copyServiceDefaultSorting = [];

export {
  copyServiceColumns,
  copyServiceDefaultColumnWidths,
  copyServiceTableColumnExtensions,
  copyServiceColumnOrder,
  copyServiceDefaultSorting,
};
