import { Divider, Grid, Paper, IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import CustomerCard from "../../../components/Customer/CustomerCard";
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import CustomFilter from "../../../components/common/CustomFilter";
import {
  vendorColumns,
  vendorDefaultColumnWidths,
  vendorTableColumnExtensions,
  vendorColumnOrder,
  vendorColumnHidden,
} from "../../../constants/vendor/vendorList";
import { useTranslation } from "react-i18next";
import {
  arrayToFilter,
  arrayToSort2,
  arrayToSort,
} from "../../../utils/common";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import {
  add_vendorlist_data,
  add_vendor_filters,
  add_vendor_sort,
  addVendorHiddenCols,
  clearVendorDetails,
} from "../../../redux/actions/vendorAction";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { ALTER_ACTIVE_TAB } from "../../../redux/actionTypes";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const VendorList = (props) => {
  const {
    data,
    add_vendorlist_data,
    loading,
    add_vendor_filters,
    vendorFilter,
    add_vendor_sort,
    vendorSort,
    vendorHiddenCols,
    addVendorHiddenCols,
    _totalcount,
    clearVendorDetails,
    alterActiveTab,
  } = props;
  const [listView, setListView] = useState(true);
  const [cardView, setCardView] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const styles = listScreenStyles();
  const histroy = useHistory();
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: vendorColumns,
    widths: vendorDefaultColumnWidths,
    extentions: vendorTableColumnExtensions,
    orders: vendorColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [rows, setRows] = useState([]);

  const [isFavouriteClick, setIsFavouriteClick] = useState(false);

  const [currentFavouritePage, setCurrentFavouritePage] = useState(1);

  useEffect(() => {
    if (!isFavouriteClick) {
      setRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setRows(
        data?.data && data?.data?.length > 0
          ? data?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [data]);

  const handleToggleFavouriteTags = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(vendorFilter);
      const parsedSort = arrayToSort(vendorSort);
      add_vendorlist_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort),
        10
      );
      setRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPage(1);
    } else {
      add_vendorlist_data();
      setRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavouritePage(1);
    }
  };
  
  useEffect(() => {
    alterActiveTab("Vendors", "Vendors");
  }, []);

  useEffect(() => {
    checkScreenPermission("Vendor", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Vendor");
      if (_status === 200) {
        const tempData = getTableData(
          data,
          vendorColumns,
          vendorDefaultColumnWidths,
          vendorTableColumnExtensions,
          vendorColumnOrder,
          vendorHiddenCols,
          "vendor"
        );
        setTableData(tempData);
        if (
          JSON.stringify(vendorHiddenCols) ===
          JSON.stringify(vendorColumnHidden)
        ) {
          addVendorHiddenCols(tempData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  useEffect(() => {
    return () => {
      clearVendorDetails();
    };
  }, []);

  // Api call
  useEffect(() => {
    const parsedFilters = arrayToFilter(vendorFilter);
    const parsedSort = arrayToSort(vendorSort);
    add_vendorlist_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, vendorSort]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function createData(vendor_data) {
    const { _id, description, name, createdon, status, userid, isFavourite } = vendor_data;
    return {
      ...vendor_data,
      _id,
      name,
      createdon: createdon,
      description,
      status,
      userid,
      isFavourite: isFavourite,
    };
  }

  // const rows =
  //   data?.length > 0
  //     ? data.map((item) => {
  //       const row_data = createData(item);
  //       return row_data;
  //     })
  //     : [];

  // filter
  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(vendorFilter);
    const parsedSort = arrayToSort2(vendorSort);
    add_vendorlist_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_vendorlist_data(1, JSON.stringify({}));
  };
  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      add_vendorlist_data(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_vendorlist_data(1);
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Vendors", path: "/vendors" },
              { title: "Vendors List" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("vendor.vendors")} {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div
              className={styles.topContentItem}
              style={{
                justifyContent: "flex-end",
              }}
            >
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Vendor Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <Tooltip title="Favourite" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: "0px 10px" }}
                  onClick={() => handleToggleFavouriteTags()}
                >
                  {isFavouriteClick ? (
                    <img src="/images/heartFilled.svg" alt="card" />
                  ) : (
                    <img src="/images/heartLine.svg" alt="card" />
                  )}
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData?.columns}
                filters={vendorFilter}
                setFilters={add_vendor_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <IconButton
                color="inherit"
                aria-label="open cardview"
                edge="start"
                onClick={() => {
                  setCardView(true);
                  setListView(false);
                }}
                className={`${cardView ? "toggleActive" : ""} ${styles.toggleicons
                  }`}
              >
                <img src="/images/cardIconBlack.svg" alt="card" />
              </IconButton>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <IconButton
                color="inherit"
                aria-label="open cardview"
                edge="start"
                onClick={() => {
                  setCardView(false);
                  setListView(true);
                }}
                className={`${listView ? "toggleActive" : ""} ${styles.toggleicons
                  }`}
              >
                <img src="/images/listBlack.svg" alt="card" />
              </IconButton>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("Vendor", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/create-vendor")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        {listView && (
          <Paper className={styles.pageContent}>
            {tableLoading ? (
              <Loader />
            ) : (
              <ApplicationListTable
                columns={tableData.columns}
                rows={isFavouriteClick ? (
                  rows?.length > 0
                    ? rows?.slice(
                      (currentFavouritePage - 1) * 10,
                      currentFavouritePage * 10
                    )
                    : []
                ) : (rows)}
                defaultColumnWidths={tableData.widths}
                tableColumnExtensions={tableData.extentions}
                columnOrder={tableData.orders}
                defaultHiddenColumnNames={tableData.hides}
                loading={loading}
                currentPage={isFavouriteClick ? currentFavouritePage : currentPage}
                setCurrentPage={setCurrentPage}
                isCustomHeight={true}
                sort={vendorSort}
                setSort={add_vendor_sort}
                hidden={vendorHiddenCols}
                setHidden={addVendorHiddenCols}
              />
            )}
          </Paper>
        )}
        {cardView && (
          <div
            style={{
              paddingTop: "7rem",
              paddingLeft: 28,
              paddingRight: 28,
            }}
          >
            <Grid container spacing={3}>
              {rows?.map((row, index) => (
                <CustomerCard row={row} key={index} module="vendor" />
              ))}
            </Grid>
          </div>
        )}
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_vendorlist_data,
  add_vendor_filters,
  add_vendor_sort,
  addVendorHiddenCols,
  clearVendorDetails,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { vendor_list, vendorFilter, vendorSort, vendorHiddenCols } =
    state.vendorReducer;
  const { data, error, loading, _totalcount } = vendor_list;
  return {
    data,
    error,
    loading,
    vendorFilter,
    vendorSort,
    _totalcount,
    vendorHiddenCols,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
