import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ModalStyles from "../../styles/commonStyles/TemplateModal";
import { useSnackbar } from "notistack";
import { handleFormValidation } from "../../../utils/common";
import { UiApplicationService } from "../../services/UiApplicationService";
import moment from "moment";
import { useSelector } from "react-redux";
import useGetData from "../../hooks/useGetData";

export default function CreateTemplateModal(props) {
  const styles = ModalStyles();
  const { currentProject, getSelectedPage } = useGetData();
  const selectedPage = getSelectedPage(props?.pageData?.id);

  // validation
  const [templatedata, setTemplateData] = React.useState({
    name: "",
    url: "",
    description: "",
  });
  const [error, setError] = React.useState({
    name: false,
    url: false,
  });

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setError({
      ...error,
      [name]: false,
    });
    setTemplateData({
      ...templatedata,
      [name]: value,
    });
  };

  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const uiApplicationService = new UiApplicationService();
  const { userInfo } = useSelector((state) => state.userDetails);

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidation(templatedata, error);
    if (formIsValid) {
      try {
        setLoading(true);
        const selectedPageComponents =
          await uiApplicationService.fetchAllComponents(
            currentProject?.id,
            props.pageData.name
          );
        const req_body = {
          name: templatedata.name.trim(),
          url: templatedata.url.trim(),
          description: templatedata.description,
          body: selectedPageComponents?.data?.data,
          projectName: currentProject?.name,
          projectId: currentProject?.id,
        };
        const resp = await uiApplicationService.createTemplate(req_body);
        if (resp?.status < 210) {
          enqueueSnackbar("Templates created Successfully", {
            variant: "success",
          });
          props?.setModal({ ...props?.modal, open: true });
        }
        props?.handleClose();
        setLoading(false);
      } catch (err) {
        enqueueSnackbar(
          `${err?.response?.data?._status} : ${
            err?.response?.data?._msg ??
            "Unable to process your request, please try after sometime"
          }`,
          { variant: "error" }
        );
      }
    } else setError(errors);
  };
  return (
    <div>
      <Dialog
        onClose={props?.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props?.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={props?.handleClose}>
          Add Page As Template
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: "30rem" }}>
            <div>
              <label className={styles.fieldHeading}>
                Page Template name<span className={styles.red}>*</span>
              </label>
              <br />
              <input
                name="name"
                type="text"
                placeholder="Enter template Name"
                className={`${styles.input} ${error.name && styles.error}`}
                onChange={handleChange}
              />
              {error.name && (
                <p className={styles.errorMsg}>{"Template Name is required"}</p>
              )}
            </div>
            <br />
            <div>
              <label className={styles.fieldHeading}>
                Page Template Url<span className={styles.red}>*</span>
              </label>
              <br />
              <input
                name="url"
                type="text"
                placeholder="Enter template Url"
                className={`${styles.input} ${error.url && styles.error}`}
                onChange={handleChange}
              />
              {error.name && (
                <p className={styles.errorMsg}>{"Template Url is required"}</p>
              )}
            </div>
            <br />
            <div>
              <label className={styles.fieldHeading}>
                Page Template Description
              </label>
              <textarea
                name="description"
                onChange={handleChange}
                className={styles.textarea}
                placeholder="Enter Template Description"
                rows="5"
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props?.handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Add Page Template
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
