import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useGetData from "../../../../hooks/useGetData";
import { MenuItem, Select } from "@material-ui/core";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { editUiApplication } from "../../../../../redux/actions/uiApplicationAction";

function HeadingSettings() {
  const { currentComponent, currentProject, globalAcessModeChange } =
    useGetData();
  const dispatch = useDispatch();
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const [accessModeErr, setAccessModeErr] = useState("");
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <AccessDataInput
          label="Heading"
          name="headingValue"
          value={currentComponent?.headingValue}
          onChange={(value) =>
            globalAcessModeChange(value, "headingValue", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
    </div>
  );
}

export default HeadingSettings;
