import {
  ADD_CONTAINERS_DATA,
  CONTAINER_FILTERS,
  DELETE_CONTAINER,
  CONTAINERS_SORT,
  START_OR_STOP_CONTAINER,
  CONTAINERS_HIDDEN_COULMNS,
  CLEAR_CONTAINER_DATA,
} from "../actionTypes";
import { ContainerServices } from "../../services/containerServices";

const ContainerService = new ContainerServices();

function add_containers_list_data(payload) {
  return {
    type: ADD_CONTAINERS_DATA,
    payload: payload,
  };
}

export function add_containers_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_containers_list_data({
        loading: true,
      })
    );
    ContainerService.getContainersList(currentPage, filter, sort)
      .then((res) => {
        console.log("kjlekjf", res);
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            console.log("gyhuh", data);
            dispatch(
              add_containers_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_containers_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch(() => {
        dispatch(
          add_containers_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_container_filters = (containerFilter) => {
  return {
    type: CONTAINER_FILTERS,
    payload: containerFilter,
  };
};

export const add_container_sort = (containerSort) => {
  return {
    type: CONTAINERS_SORT,
    payload: containerSort,
  };
};

export const delete_container = (conID) => {
  return {
    type: DELETE_CONTAINER,
    payload: {
      conID,
    },
  };
};
export const start_or_stop_container = (conID, state) => {
  return {
    type: START_OR_STOP_CONTAINER,
    payload: {
      conID,
      state,
    },
  };
};

export const add_containers_hidden_cols = (containerHiddenCols) => {
  return {
    type: CONTAINERS_HIDDEN_COULMNS,
    payload: containerHiddenCols,
  };
};
export const clear_container_data = () => {
  return {
    type: CLEAR_CONTAINER_DATA,
  };
};
