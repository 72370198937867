import { ApiHelper } from "./helper/ApiHelper";
import { REPOSITORY } from "./helper/config";

const apiHelper = new ApiHelper();
export class chassisTemplateServices {
  getAllChassisTemplates(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${REPOSITORY}/api/repo/repositories/templates?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getChassisTemplateDetailsByID(TemplateID) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/${TemplateID}`;
    return apiHelper.get(uri);
  }
  createChassisTemplate(TemplateData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates`;
    return apiHelper.post(uri, TemplateData);
  }
  CreateChassisTemplateFromGithub(TemplateData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/_download`;
    return apiHelper.post(uri, TemplateData);
  }
  updateDescription(TemplateID, descriptionData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/editmeta/${TemplateID}`;
    return apiHelper.put(uri, descriptionData);
  }
  updateBuildFile(TemplateID, buildFileData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/${TemplateID}`;
    return apiHelper.put(uri, buildFileData);
  }
  updateChassisTemplateGitUploadFile(TemplateID, gitData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/edit/${TemplateID}`
    return apiHelper.put(uri, gitData);
  }
  updateBuildCommand(TemplateID, buildCmdData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/dockers/${TemplateID}/commands/buildcommands`;
    return apiHelper.put(uri, buildCmdData);
  }
  updateRunCommand(TemplateID, runCmdData) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/dockers/${TemplateID}/commands/runcommands`;
    return apiHelper.put(uri, runCmdData);
  }
  DeleteChassisTemplate(TemplateID) {
    const uri = `${REPOSITORY}/api/repo/repositories/templates/${TemplateID}`;
    return apiHelper.delete(uri);
  }
}
