import React, { useState } from "react";
import {
  Paper,
  Grid,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { useHistory } from "react-router-dom";
import VendorProject from "../common/modals/VendorProject";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { blockVendor, UnblockVendor } from "../../utils/vendor/vendorUtils";
import BlockModal from "../common/modals/BlockModal";
import UnBlockModal from "../common/modals/UnBlockModal";
import { useSnackbar } from "notistack";
import { block_unblock_vendor } from "../../redux/actions/vendorAction";
import useCheckPermission from "../common/hooks/useCheckPermission";

const cardStyles = makeStyles((theme) => ({
  singlecard: {
    padding: "1px 5px 15px",
    borderRadius: 10,
  },
  cardline1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#535353",
    marginTop: 10,
    marginLeft: 15,
    marginRight: 10,
  },
  projecttitle: {
    marginTop: 10,
    marginLeft: 10,
    fontSize: 16,
  },
  cardDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #D4D4D4",
    color: "#535353",
    marginTop: 20,
    height: 62,
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
  },
  AssignModalCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },
  cardicons: {
    display: "flex",
    alignItems: "center",
    color: "#535353",
  },
  MuiDivider2: {
    width: "1px",
    height: "30px",
  },
  head: {
    backgroundColor: "#F5F7FA !important",
    fontWeight: "500",
  },
  modalheader: {
    display: "flex",
    justifyContent: "space-between",
  },
  dropdowns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  formControl: {
    width: 204,
    height: 55,
    backgroundColor: "#fff",
    borderRadius: 5,
  },
}));

function CustomerCard(props) {
  const histroy = useHistory();
  const styles = cardStyles();

  const { row, module, userInfo } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Vendor project Modal
  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    if (checkCompPermission("Vendor", ["Block Or Unblock"])) {
      setBlockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to block Or unblock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async (props) => {
    const blockData = {};
    const { _msg, _status, data } = await blockVendor(row._id, blockData);
    if (_status === 200) {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_vendor(row._id, "inactive"));
    } else {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  const handleUnBlock = async () => {
    const blockData = {};
    const { _msg, _status, data } = await UnblockVendor(row._id, blockData);
    if (_status === 200) {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_vendor(row._id, "active"));
    } else {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };

  const { checkCompPermission } = useCheckPermission();

  const handleDetailsNavigation = () => {
    if (checkCompPermission("Vendor", ["Read"])) {
      histroy.push(`/vendor-details/${row._id}`);
    } else {
      enqueueSnackbar(
        `You do not have permission to access read. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Vendor", ["Edit"])) {
      if (row.status === "inactive") {
        enqueueSnackbar("Cannot edit a Blocked Vendor. Kindly unblock first.", {
          variant: "error",
        });
      } else {
        histroy.push(`/edit-vendor/${row._id}`);
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper className={styles.singlecard}>
        <div className={styles.cardline1}>
          <div
            style={{
              cursor: "pointer",
              width: "11rem",
              lineBreak: "anywhere",
            }}
            onClick={handleDetailsNavigation}
          >
            {row.name}
          </div>
          <div className={styles.cardicons}>
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              onClick={handleEdit}
              className={styles.actionIcon}
            >
              <img src="/images/governance/edit.svg" alt="edit" />
            </IconButton>

            <div style={{ padding: "4px" }}>
              {row.status === "active" && (
                <div className={styles.actions}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    onClick={handleBlockModalClickOpen}
                    className={styles.actionIcon}
                  >
                    <img src="/images/block.svg" alt="lock" />
                  </IconButton>
                  {blockModalOpen && (
                    <BlockModal
                      row={row}
                      open={blockModalOpen}
                      handleClose={handleBlockModalClose}
                      handleBlock={handleBlock}
                    />
                  )}
                </div>
              )}
              {row.status === "inactive" && (
                <div className={styles.actions}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    onClick={handleBlockModalClickOpen}
                    className={styles.actionIcon}
                  >
                    <img src="/images/teams/block.svg" alt="lock" />
                  </IconButton>
                  {blockModalOpen && (
                    <UnBlockModal
                      row={row}
                      open={blockModalOpen}
                      handleClose={handleBlockModalClose}
                      handleBlock={handleUnBlock}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.cardDetails}>
          <div className={styles.AssignModalCard} style={{ cursor: "pointer" }}>
            <img
              src="/images/application_list.svg"
              alt="icon"
              onClick={handleModalClickOpen}
            />
            <div style={{ paddingTop: 8 }}>Assigned to</div>
            {ModalOpen && (
              <VendorProject
                id={row.userid}
                open={ModalOpen}
                handleClose={handleModalClose}
              />
            )}
          </div>
          <Divider orientation="vertical" className={styles.MuiDivider2} />
          <div>
            <img
              src="/images/calender.svg"
              alt="card"
              style={{ height: 22, width: 25 }}
              onClick={() => histroy.push(`/vendor-details/${row._id}`)}
            />
            <br />
            {moment
              .unix(row?.createdon)
              .tz(userInfo?.timeZone)
              .format(
                userInfo?.time === "h:mm"
                  ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                  : `${userInfo?.dateFormat} ${userInfo?.time}`
              )}
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomerCard);
