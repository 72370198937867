const deploymentColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "ContainerName", title: "Container Name", for: "deployment" },
  { name: "endPoint", title: "EndPoint", for: "deployment" },
  { name: "Status", title: "Status", for: "deployment" },
  { name: "created", title: "Created", for: "deployment" },
  { name: "API", title: "APIs", for: "deployment" },
  { name: "action", title: "Action", route: "/host/edit", for: "deployment" },
  { name: "Port", title: "Port", for: "deployment" },
  { name: "Replicas", title: "Replica", for: "deployment" },
  { name: "ClusterName", title: "Cluster", for: "deployment" },
  { name: "ImageName", title: "Image", for: "deployment" },
  { name: "Namespace", title: "Namespace", for: "deployment" },
];

const deploymentDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "ContainerName", weightage: 4, width: 200 },
  { columnName: "endPoint", weightage: 1, width: 200 },
  { columnName: "API", weightage: 8, width: 200 },
  { columnName: "Status", weightage: 8, width: 200 },
  { columnName: "created", weightage: 4, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
  { columnName: "Port", weightage: 3, width: 200 },
  { columnName: "Replicas", weightage: 1.5, width: 200 },
  { columnName: "ClusterName", weightage: 1.5, width: 200 },
  { columnName: "ImageName", weightage: 1.5, width: 200 },
  { columnName: "Namespace", weightage: 1.5, width: 200 },
];

const deploymentTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "ContainerName", align: "left" },
  { columnName: "endPoint", align: "center" },
  { columnName: "API", align: "center" },
  { columnName: "Status", align: "center" },
  { columnName: "created", align: "center" },
  { columnName: "action", align: "left", sortingEnabled: false },
  { columnName: "Port", align: "center" },
  { columnName: "Replicas", align: "center" },
  { columnName: "ClusterName", align: "center" },
  { columnName: "ImageName", align: "center" },
  { columnName: "Namespace", align: "center" },
];

const deploymentColumnOrder = [
  // 'id',
  "ContainerName",
  "endPoint",
  "API",
  "Status",
  "created",
  "Port",
  "Replicas",
  "ClusterName",
  "ImageName",
  "Namespace",
  "action",
];

const deploymentColumnHidden = [
  // 'id',
  // "ContainerName",
  // "Port",
  // "Status",
  // "ServiceName",
  // "action",
  "Port",
  "Replicas",
  "ClusterName",
  "ImageName",
  "Namespace",
];

const deploymentDefaultSorting = [];

export {
  deploymentColumns,
  deploymentDefaultColumnWidths,
  deploymentTableColumnExtensions,
  deploymentColumnOrder,
  deploymentDefaultSorting,
  deploymentColumnHidden,
};
