import { chassisTemplateServices } from "../../services/chassisTemplateService";
const chassisTemplateService = new chassisTemplateServices();

export const fetchChassisTemplateListData = async () => {
  try {
    const res_data = await chassisTemplateService.getAllChassisTemplates();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchChassisTemplateDetailsByID = async (templateID) => {
  try {
    const res_data = await chassisTemplateService.getChassisTemplateDetailsByID(
      templateID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const CreateLChassisTemplate = async (TemplateData) => {
  try {
    const res_data = await chassisTemplateService.createChassisTemplate(
      TemplateData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const CreateChassisTemplateFromGithub = async (TemplateData) => {
  try {
    const res_data =
      await chassisTemplateService.CreateChassisTemplateFromGithub(
        TemplateData
      );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateDescription = async (TemplateID, descriptionData) => {
  try {
    const res_data = await chassisTemplateService.updateDescription(
      TemplateID,
      descriptionData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateBuildFile = async (TemplateID, buildFileData) => {
  try {
    const res_data = await chassisTemplateService.updateBuildFile(
      TemplateID,
      buildFileData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateChassisTemplateGitUploadFile = async (id, gitData) => {
  try {
    const res_data = await chassisTemplateService.updateChassisTemplateGitUploadFile(id, gitData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const DeleteChassisTemplate = async (TemplateID) => {
  try {
    const res_data = await chassisTemplateService.DeleteChassisTemplate(
      TemplateID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateBuildCommand = async (TemplateID, buildCmdData) => {
  try {
    const res_data = await chassisTemplateService.updateBuildCommand(
      TemplateID,
      buildCmdData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateRunCommand = async (TemplateID, runCmdData) => {
  try {
    const res_data = await chassisTemplateService.updateRunCommand(
      TemplateID,
      runCmdData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
