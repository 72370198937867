const sectionColumns = [
    { name: "name", title: "Section Name", for: "ontology_section_list" },
    { name: "sectionLabel", title: "Section Label", for: "ontology_section_list" },
    { name: "predefined", title: "Predefined", for: "ontology_section_list" },
    { name: "updatedby", title: "Updated By", for: "ontology_section_list" },
    { name: "updatedon", title: "Updated On", for: "ontology_section_list" },
    { name: "action", title: "Actions", for: "ontology_section_list" },
];

const sectionDefaultColumnWidths = [
    { columnName: "name", weightage: 1.5, width: 200 },
    { columnName: "sectionLabel", weightage: 1.5, width: 200 },
    { columnName: "predefined", weightage: 1.5, width: 200 },
    { columnName: "updatedby", weightage: 1.5, width: 200 },
    { columnName: "updatedon", weightage: 2, width: 200 },
    { columnName: "action", weightage: 2, width: 200 },
];

const sectionTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "sectionLabel", align: "left" },
    { columnName: "predefined", align: "left" },
    { columnName: "updatedby", align: "left" },
    { columnName: "updatedon", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
];

const sectionColumnOrder = [
    "name",
    "sectionLabel",
    "predefined",
    "updatedby",
    "updatedon",
    "action",
];

const sectionDefaultSorting = []

export {
    sectionColumns,
    sectionDefaultColumnWidths,
    sectionTableColumnExtensions,
    sectionColumnOrder,
    sectionDefaultSorting,
};
