import { boilerPlateServices } from "../../services/boilerPlateServices";
const boilerPlateService = new boilerPlateServices();

export const fetchBoilerPlateListData = async () => {
  try {
    const res_data = await boilerPlateService.getAllBoilerPlates();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateBoilerPlateGitUploadFile = async (id, gitData) => {
  try {
    const res_data = await boilerPlateService.updateBoilerPlateGitUploadFile(id, gitData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const fetchBoilerPlateDetailsByID = async (bPlateID) => {
  try {
    const res_data = await boilerPlateService.getBoilerPlatesDetailsByID(
      bPlateID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const CreateBoilerPlate = async (bPlateData) => {
  try {
    const res_data = await boilerPlateService.createBoilerPlate(bPlateData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const CreateBoilerPlateFromGithub = async (bPlateData) => {
  try {
    const res_data = await boilerPlateService.CreateBoilerPlateFromGithub(
      bPlateData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateBuildFile = async (bPlateID, bPlateData) => {
  try {
    const res_data = await boilerPlateService.updateBuildFile(
      bPlateID,
      bPlateData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateDescription = async (bPlateID, description) => {
  try {
    const res_data = await boilerPlateService.updateDescription(
      bPlateID,
      description
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateBuildCommand = async (bPlateID, buildCmdData) => {
  try {
    const res_data = await boilerPlateService.updateBuildCommand(
      bPlateID,
      buildCmdData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const updateRunCommand = async (bPlateID, runCmdData) => {
  try {
    const res_data = await boilerPlateService.updateRunCommand(
      bPlateID,
      runCmdData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const DeleteBoilerPlates = async (bPlateID) => {
  try {
    const res_data = await boilerPlateService.DeleteBoilerPlates(bPlateID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const DownloadBoilerPlate = async (bPlateID) => {
  try {
    const res_data = await boilerPlateService.DownloadBoilerPlate(bPlateID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
