import { useState, useEffect } from "react";
import Styles from "../../../../../styles/commonStyles/MenuRightBar";
import { Grid, Popover } from "@material-ui/core";
import {
  addLinkedUiApplicationId,
  editComponent,
} from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../atoms/input/Input";
import useGetData from "../../../../../hooks/useGetData";
import StatusStyles from "../../../../atoms/rightSide/appearance/showStatus/ShowStatusStyles";
import Dropdown from "../../../../atoms/dropdown/Dropdown";
import { useTheme } from "@material-ui/core";
import JSDataInput from "../../../../atoms/rightSide/settings/JSDataInput";
import CustomAutocomplete from "../../../../atoms/autocomplete/CustomAutocomplete";

const MenuPopup = ({
  isOptionsOpen,
  optionsEl,
  setOptionsEl,
  item,
  setModalData,
  menuType,
  menuData,
  submenu,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = Styles();
  const foundApps = useSelector((state) => state.uiApplicationListReducer.data);
  const styles = StatusStyles();
  const { currentPage, currentComponent, currentProject } = useGetData();
  const [updatedPages, setUpdatedPages] = useState([]);
  const [applicationsNew, setApplicationsNew] = useState([]);

  const handleTitleChange = (event, value) => {
    setModalData({ ...item, [event.target.name]: event.target.value });
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id && menuType === "menu") {
        return {
          ...component,
          options: component?.options?.map((v) => {
            if (v?.id === value?.id) {
              return {
                ...v,
                [event?.target?.name]: event?.target?.value,
              };
            } else {
              return v;
            }
          }),
        };
      } else if (
        component.id === currentComponent.id &&
        menuType === "submenu"
      ) {
        return {
          ...component,
          options: component.options.map((menu) => {
            if (menu?.id === menuData?.id) {
              return {
                ...menu,
                children: menu?.children?.map((c) => {
                  if (c?.id === item?.id) {
                    return {
                      ...c,
                      [event?.target?.name]: event?.target?.value,
                    };
                  } else {
                    return {
                      ...c,
                    };
                  }
                }),
              };
            } else {
              return menu;
            }
          }),
        };
      } else if (
        component.id === currentComponent.id &&
        menuType === "submenuChild"
      ) {
        return {
          ...component,
          options: component.options.map((menu) => {
            if (menu?.id === menuData?.id) {
              return {
                ...menu,
                children: menu?.children?.map((c) => {
                  if (c?.id === submenu?.id) {
                    return {
                      ...c,
                      submenuChildren: c?.submenuChildren.map((sChild) => {
                        if (sChild?.id === item?.id) {
                          return {
                            ...sChild,
                            [event?.target?.name]: event?.target?.value,
                          };
                        } else {
                          return {
                            ...sChild,
                          };
                        }
                      }),
                    };
                  } else {
                    return {
                      ...c,
                    };
                  }
                }),
              };
            } else {
              return menu;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleUrlChange = (name, value) => {
    setModalData({ ...item, [name]: value ? value : "" });
    const components = currentPage.components.map((component) => {
      if (component?.id === currentComponent?.id && menuType === "menu") {
        return {
          ...component,
          options: component?.options?.map((v) => {
            if (v?.id === item?.id) {
              return {
                ...v,
                [name]: value ? value : "",
              };
            } else {
              return v;
            }
          }),
        };
      } else if (
        component?.id === currentComponent?.id &&
        menuType === "submenu"
      ) {
        return {
          ...component,
          options: component.options.map((menu) => {
            if (menu?.id === menuData?.id) {
              return {
                ...menu,
                children: menu?.children?.map((c) => {
                  if (c?.id === item?.id) {
                    return {
                      ...c,
                      [name]: value ? value : "",
                    };
                  } else {
                    return { ...c };
                  }
                }),
              };
            } else {
              return menu;
            }
          }),
        };
      } else if (
        component?.id === currentComponent?.id &&
        menuType === "submenuChild"
      ) {
        return {
          ...component,
          options: component.options.map((menu) => {
            if (menu?.id === menuData?.id) {
              return {
                ...menu,
                children: menu?.children?.map((c) => {
                  if (c?.id === submenu?.id) {
                    return {
                      ...c,
                      submenuChildren: c?.submenuChildren.map((sChild) => {
                        if (sChild?.id === item?.id) {
                          return {
                            ...sChild,
                            [name]: value ? value : "",
                          };
                        } else {
                          return { ...sChild };
                        }
                      }),
                    };
                  } else {
                    return { ...c };
                  }
                }),
              };
            } else {
              return menu;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
    if (name === "linkedUiApp") {
      dispatch(addLinkedUiApplicationId(item?.id, value?.id));
    }
  };

  useEffect(() => {
    setUpdatedPages(
      foundApps
        .find((app) => app._id === item.linkedUiApp?.id)
        ?.pages.map((page) => ({
          name: page?.name,
          id: page?.id,
          url: page?.url,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.linkedUiApp]);

  const setMappedData = async () => {
    const mappedData = foundApps?.map((v) => ({
      id: v?._id,
      name: v?.name,
      previewMode: v?.previewMode,
    }));
    setApplicationsNew(mappedData);
  };

  useEffect(() => {
    setMappedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.pages, currentComponent]);

  return (
    <Popover
      open={isOptionsOpen}
      anchorEl={optionsEl}
      onClose={() => setOptionsEl(null)}
      className={classes.LogoPopup}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <div style={{ padding: 10 }}>
        <Grid container direction="column">
          <Grid item>
            <Input
              name={"title"}
              label={"Title"}
              placeholder=""
              value={item?.title}
              onChange={(e) => handleTitleChange(e, item)}
            />
          </Grid>
          <br />
          <Grid item>
            <label>{"URL"}</label>
            <div className={styles.loadingbtnContainer}>
              <button
                className={styles.loadingbtn}
                onClick={() => handleUrlChange("urlType", "page")}
                style={
                  item.urlType === "page"
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                      }
                    : { textTransform: "capitalize" }
                }
              >
                <img
                  className={styles.btnIcon}
                  src={
                    item?.urlType === "page"
                      ? "/img/Page.svg"
                      : "/img/Page_Dark.svg"
                  }
                  alt="Pages"
                />
              </button>
              <button
                style={
                  item.urlType === "link"
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                      }
                    : { textTransform: "capitalize" }
                }
                className={styles.loadingbtn}
                onClick={() => handleUrlChange("urlType", "link")}
              >
                <img
                  className={styles.btnIcon}
                  src={
                    item?.urlType === "page"
                      ? "/img/Link_Dark.svg"
                      : "/img/Link_Light.svg"
                  }
                  alt={"General"}
                />
              </button>
            </div>

            {item.urlType === "page" && (
              <div style={{ marginTop: 5 }}>
                <label>UI Application</label>
                <CustomAutocomplete
                  options={applicationsNew}
                  optionLabel={(option) => (option ? option?.name : "")}
                  value={item?.linkedUiApp}
                  onChange={(input) =>
                    handleUrlChange("linkedUiApp", { ...input })
                  }
                />
                <label>Page</label>
                <Dropdown
                  width="100%"
                  value={item?.page}
                  onChange={(e) => handleUrlChange("page", e.target.value)}
                  arr={Array?.isArray(updatedPages) ? updatedPages : []}
                  disabled={false}
                  label=""
                  name="page"
                />
              </div>
            )}
            {item.urlType === "link" && (
              <div className="field" style={{ marginTop: 3, width: 230 }}>
                <JSDataInput
                  value={item?.link}
                  onChange={(value) => handleUrlChange("link", value)}
                  label=""
                  error=""
                  accessMode={true}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default MenuPopup;
