import React, { useEffect, useState} from "react";
import { Grid, Tabs, Tab, Typography } from "@material-ui/core";
import listStyles from "../../screens/CommonStyles/listScreenStyles";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./Profile";
import General from "./General";
import Apperance from "./Apperance";
import Notifications from "./Notifications";
import { useSelector, useDispatch, connect } from "react-redux";
import { changeTabByUser } from "../../redux/actions/userProfileAction";
import { AppButton } from "../../components/common/Button";
import {
  changeVisibility,
} from "../../redux/actions/uiAction";
import { USER_VISIBLE_RESET } from "../../constants/UserProfile/user";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "4.1em",
    paddingLeft: "2em",
    paddingRight: "2em",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
  },
  tabContainer: {
    borderBottom: "1px solid #E2E2E3",
  },
  tab: {
    ...theme.typography.fontFamily,
    ...theme.typography.tab,
  },
  header: {
    fontSize: 12,
    color: "#1B1C1D",
  },
  settingsHead: {
    ...theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 600,
    color: "#1B1C1D",
  },
  rootTab: {
    // minHeight:'58px'
  },
}));

const tabArray = [
  { label: "Profile", index: 0 },
  { label: "General", index: 1, link: "/General" },
  { label: "Appearance", index: 2, link: "/Appearance" },
  { label: "Notifications", index: 3, link: "/Notifications" },
];

const Settings = ({userinactivity}) => {
  const styles = listStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const appearnce = useSelector((state) => state.appearnce);
  const { appear } = appearnce;
  const [tab, setTab] = useState(0);
  const history = useHistory();
  
  const [inactivityTimeCheck, setInactivityTimeCheck] = useState()

  const { enqueueSnackbar } = useSnackbar();

  const selectTab = useSelector((state) => state.selectTab);
  const [flagVisible, setflagVisible] = useState(appear);
  const { userTab } = selectTab;

  // const userDetails = useSelector((state) => state.userDetails);
  // const { success } = userDetails;

  const handleChangeTab = (event, newValue) => {
    dispatch(changeTabByUser(newValue));
  };

  useEffect(() => {
    if(userinactivity){
    setInactivityTimeCheck(userinactivity)
    }
    else
    {
      setInactivityTimeCheck(20)
    }
  }, [userinactivity])

  const handleClick = (val) => {
    // setflagVisible(!flagVisible);
    if (val === true) {
      setflagVisible(true);
      dispatch(changeVisibility(val));
    } else if (val === false) {
      dispatch({ type: USER_VISIBLE_RESET });
    }

    if(val === "save"){
       if(inactivityTimeCheck >= 5 && inactivityTimeCheck <= 180 ){
      dispatch(changeVisibility(val));
        setflagVisible(false);

        } else {
          enqueueSnackbar('Inactivity period should be between 5 minutes and 180 minutes', {
            variant: 'error',
          });
        }
    }
  };

  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item container direction="column" className={styles.topContent} >
        <Grid item container direction="column" style={{ marginTop: "2.8em" }}>
          <Grid item container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className={classes.settingsHead}>Settings</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {userTab === 0 || userTab === 1 ? (
                <Grid item container md justify="flex-end" >
                  {!flagVisible ? (
                    <Grid item container md justify="flex-end" style={{position:'absolute'}}>
                      <Grid item>
                        <AppButton
                          color="primary"
                          buttonName="Edit"
                          variant="contained"
                          // startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          onClick={() => {
                            
                            handleClick(true);
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <AppButton
                          color="primary"
                          buttonName="Close"
                          variant="outlined"
                          // startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          onClick={() => history?.push("/dashboard")}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container md justify="flex-end" style={{position:'absolute'}}>
                      <Grid item>
                        <AppButton
                          color="primary"
                          buttonName="Save"
                          variant="contained"
                          // startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          onClick={() => {
                            handleClick("save");
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <AppButton
                          color="primary"
                          buttonName="Cancel"
                          variant="outlined"
                          // startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          onClick={() => {
                            setflagVisible(false);
                            handleClick(false);
                          }}
                        />
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid item container md justify="flex-end"></Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Tabs
              value={userTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              className={classes.tabContainer}
            >
              {tabArray.map((option, i) => (
                <Tab
                  disableRipple
                  key={i}
                  label={option.label}
                  className={classes.tab}
                  // style={{ color: tab === i ? '#002855' : 'red' }}
                  classes={{ root: classes.rootTab }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
      {userTab === 0 ? (
        <Grid item container style={{ marginTop: "10rem" }}>
          <Profile />
        </Grid>
      ) : userTab === 1 ? (
        <Grid item container style={{ marginTop: "10rem" }}>
          <General  setInactivityTimeCheck={setInactivityTimeCheck} />
        </Grid>
      ) : userTab === 2 ? (
        <Grid
          item
          container
          md
          style={{ marginLeft: "-2em", marginTop: "8.8rem" }}
        >
          <Apperance tab={tab} changeTab={setTab} />
        </Grid>
      ) : userTab === 3 ? (
        <Grid item container style={{ marginTop: "11rem", marginLeft: "1em" }}>
          <Notifications />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
const { userinactivity } = state.userDetails.userInfo
return {
  userinactivity
}
}
export default connect(mapStateToProps) (Settings);
