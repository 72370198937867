import React from 'react'

const AddNewDocument = () => {
    return (
        <div>
            ggnbnnb
        </div>
    )
}

export default AddNewDocument
