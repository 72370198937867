import React from 'react'
import { AppButton } from '../Button'
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const AccordionNavigation = ({ disabled, handleSave, handleCancel, isEditMode, handleEdit, isCreateMode, isClose }) => {

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}
        >

            {isEditMode ?
                <AppButton
                    color="primary"
                    buttonName="Edit"
                    variant="contained"
                    disabled={disabled}
                    style={{ margin: "unset" }}
                    className="btnsmall"
                    onClick={handleEdit}
                /> : <AppButton
                    color="primary"
                    buttonName={isCreateMode ? "Create" : "Save"}
                    variant="contained"
                    disabled={disabled}
                    style={{ margin: "unset" }}
                    className="btnsmall"
                    onClick={handleSave}
                />}
            &nbsp;&nbsp;

            <AppButton
                color="primary"
                buttonName={isClose ? "Close" : "Cancel"}
                variant="outlined"
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={handleCancel}
            />

        </div>
    )
}

export default AccordionNavigation