import { WEBSOCKET } from "../../../services/helper/config"

export const ConsoleWebSocket = (containerId, uid) => {
    return new WebSocket(`${WEBSOCKET}/api/container/logs/dockercontainers/${containerId}/logs?uid=${uid}&since=current`)
}

export const GetLockStatus = (serviceId, versionId, uid) => {
    return new WebSocket(`${WEBSOCKET}/api/repo/logs/microservices/${serviceId}/versions/${versionId}/UpdateMessage?uid=${uid}`)
}

export const GetDraftContainerStatusWS = (containerId, uid) => {
    return new WebSocket(`${WEBSOCKET}/api/container/logs/dockercontainers/draft/${containerId}/status?uid=${uid}`)
}