import { ALTER_ACTIVE_TAB } from "../actionTypes";

const initialState = {
  application_governance: "language",
  documentsActiveTab:"projects",
  serviceActiveTab: "services",
  appGovActiveTab:"",
  testActiveTab:"Execution",
  ontologyActiveTab:"object",
  helpdesk_default: ""
};

export default function activeTabReducer(state = initialState, action) {
  // switch (action.type) {
  //   case ALTER_ACTIVE_TAB: {
  //     const { module_name, active_tab } = action.payload;
  //     return {
  //       ...state,
  //       [module_name]: active_tab,
  //     };
  //   }
  //   default:
  //     return state;
  // }

  if(action.type === ALTER_ACTIVE_TAB ){
    const { module_name, active_tab, helpdesk_default } = action.payload;
        return {
          ...state,
          [module_name]: active_tab,
          helpdesk_default: helpdesk_default
        };
  }
  else{
    return state
  }
}
