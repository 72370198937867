/* eslint-disable */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Backdrop, CircularProgress, Tooltip } from "@material-ui/core";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  Background,
  updateEdge,
  useStoreState,
  ControlButton,
} from "react-flow-renderer";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import {
  add_canvas_application_data,
  addElementToList,
  replaceElementList,
  addAnimations,
  removeAnimations,
  alterSimulateStatus,
  addTemplateData,
  addInputOutputDataForNodes,
  alterFlowMode,
  alterDebugData,
} from "../../redux/actions/orchestrationAction";
import {
  addNodeRelation,
  fetchTemplateFlow,
  editRelation_,
  updateNodePositions_,
  editNodeData,
  fetchNodeDetails,
} from "../../utils/orchestration/orchestrationUtils";
import "./orchestration.css";

import ProcessNodeModal from "../../components/Orchestration/ProcessNodeModal/ProcessNodeModal";
import CallNodeModal from "../../components/Orchestration/CallNodeModal/CallNodeModal";
import SwitchNodeModal from "../../components/Orchestration/SwitchNodeModal/SwitchNodeModal";
import JoinNodeModal from "../../components/Orchestration/JoinNodeModal/JoinNodeModal";
import SimulateModal from "../../components/Orchestration/SimulateModal/SimulateModal";
import Sidebar from "../../components/Orchestration/SideBar";
import Heading from "../../components/Orchestration/Heading";
import StartNode from "../../components/Orchestration/Nodes/StartNode.jsx";
import EndNode from "../../components/Orchestration/Nodes/EndNode.jsx";
import CallNode from "../../components/Orchestration/Nodes/CallNode.jsx";
import SwitchNode from "../../components/Orchestration/Nodes/SwitchNode.jsx";
import JoinNode from "../../components/Orchestration/Nodes/JoinNode.jsx";
import ButtonEdge from "../../components/Orchestration/Nodes/ButtonEdge";
import LabelButtonEdge from "../../components/Orchestration/Nodes/LabelButtonEdge";
import SwitchNodeConditionModal from "../../components/Orchestration/SwitchNodeConditionModal";

import orchestrationStyles from "./styles";
import OrchestrationDataFlow from "./OrchestrationDataFlow";
import SaveIcon from "@material-ui/icons/Save";
import { AppButton } from "../../components/common/Button";
// import { setElements } from "react-flow-renderer/dist/store/actions";

const nodeTypes = {
  start_node: StartNode,
  end_node: EndNode,
  join_node: JoinNode,
  switch_node: SwitchNode,
  call_node: CallNode,
};

const edgeTypes = {
  buttonEdge: ButtonEdge,
  labelButtonEdge: LabelButtonEdge,
};

let id = 0;
const getId = () => `dndnode_${id++}`;

function OrchestrationMainScreen(props) {
  const search = useLocation().search;
  const isFromApplication = new URLSearchParams(search).get("app_bundle");
  const appBundleId = new URLSearchParams(search).get("id");
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showProcessModal, setProcessModal] = useState(false);
  const [showCallModal, setCallModal] = useState(false);
  const [showSwitchModal, setSwitchModal] = useState(false);
  const [showJoinModal, setJoinModal] = useState(false);
  const [showSimulateModal, setSimulateModal] = useState(false);
  const [showShowSwitchListModal, setSwitchListModal] = useState(false);
  const [modal_data, setModalData] = useState({});
  const [switch_condition_obj, set_switch_condition_obj] = useState({
    source: "",
    target: "",
    conditionList: [],
  });
  const [loading, setLoading] = useState(false);
  const [flowStateNodesValue,setFlowStateNodesValue] = useState();

  const styles = orchestrationStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const fromMyApplication = history?.state?.state?.isFromApplication;
  const { source } = location.state;
  const handleBackToApplication = () => {
    history.replace("/app-bundle/add");
  };

  const {
    application_data,
    add_canvas_application_data,
    addElementToList,
    replaceElementList,
    elements,
    templateid,
    flow_mode,
    data_flow_details
  } = props;

  const applicationID = props.match.params._id;
  // const abc = props.match.params;
  const loadNodeEdgeList = async (templateid) => {
    setLoading(true);
    const { _msg, _status, data } = await fetchTemplateFlow(templateid);
    if (_status === 200) {
      setLoading(false);
      var start_node_details = data.filter(
        (item_) => item_.type === "start_node"
      );
      if (start_node_details.length === 1) {
        var dataFlowDetails = start_node_details[0]?.data_flow_nodes_io_details;
        if (dataFlowDetails) {
          var newDataFlowDetails = JSON.parse(dataFlowDetails);
          newDataFlowDetails =
            newDataFlowDetails &&
            newDataFlowDetails.length > 0 &&
            newDataFlowDetails.map((item_) => {
              var nodeposition = item_.position;
              if (nodeposition) {
                return {
                  ...item_,
                  position: {
                    x: parseFloat(nodeposition["x"]),
                    y: parseFloat(nodeposition["y"]),
                  },
                };
              } else {
                return item_;
              }
            });
        }
        // console.log("data flow final", start_node_details, dataFlowDetails, newDataFlowDetails)
      }
      props.addInputOutputDataForNodes(
        newDataFlowDetails ? newDataFlowDetails : [],
        false,
        true
      );
      var process_node_data =
        data &&
        data.map((item) => {
          if (item.type === "start_node") {
            if (item.data_flow_nodes_io_details) {
              var new_item = item;
              delete new_item["data_flow_nodes_io_details"];
              return new_item;
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
      replaceElementList(process_node_data);
    } else {
      setLoading(false);
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  //useEffect calls
  useEffect(async () => {
    setLoading(true);
    await add_canvas_application_data(applicationID);
    if (source === "create_template") {
      replaceElementList([]);
      props.alterFlowMode("process-flow");
      props.addTemplateData({
        applicationID: "",
        templateid: "",
        templatename: "",
      });
      props.addInputOutputDataForNodes([], false, true);
      props.alterSimulateStatus(false);
      props.alterDebugData({
        debug_status: false,
        debug_instanceID: "",
        body: "",
        debug_context_data: {},
      });
    } else if (source === "load_process") {
      const { applicationid, templateid, templatename } = location.state;
      props.alterFlowMode("process-flow");
      props.addTemplateData({
        applicationID: applicationid,
        templateid: templateid,
        templatename: templatename,
      });
      await loadNodeEdgeList(templateid);
      props.alterSimulateStatus(false);
      props.alterDebugData({
        debug_status: false,
        debug_instanceID: "",
        body: "",
        debug_context_data: {},
      });
      // props.addInputOutputDataForNodes([], false, true)
    }
    setLoading(false);
  }, [applicationID]);

  // const onConnect = (params) => setElements((els) => addEdge(params, els));
  const onConnect = async (params) => {
    const { source, target, sourceHandle, targetHandle } = params;
    const source_data = elements.filter((item) => item.id === source);
    const { type, data } = source_data[0];
    const { nodeid } = data;

    if (type === "switch_node") {
      const { _msg, _status, data } = await fetchNodeDetails(templateid,nodeid);
      if(_status === 200){
        const {conditions } = data;
        const condition_name_list = JSON?.parse(conditions)?.map(item => item?.name)
        set_switch_condition_obj({
          ...params,
          source: source,
          target: target,
          conditionList: condition_name_list,
        });
      }
      setSwitchListModal(true);
    } else {
      const req_data = {
        relationname: "next",
        nodeid: target,
        source_node_handle: sourceHandle,
        target_node_handle: targetHandle,
      };
      setLoading(true);
      const { _msg, _status, data } = await addNodeRelation(
        templateid,
        source,
        req_data
      );
      if (_status === 201) {
        setLoading(false);
        const { relationid } = data;
        const edge_data = {
          ...params,
          arrowHeadType: "arrowclosed",
          type: "buttonEdge",
          relationid: relationid,
        };
        const new_list = addEdge(edge_data, elements);
        replaceElementList(new_list);
      } else {
        setLoading(false);
        var del_edge_list = elements.filter((item) => {
          if (!item.source && !item.target) {
            return item;
          } else if (
            item.source !== edge_data.source &&
            item.target !== edge_data.target
          ) {
            return item;
          }
        });
        replaceElementList(del_edge_list);
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  const onEdgeUpdate = async (oldEdge, newConnection) => {
    const { relationid } = oldEdge;
    const { source, target, sourceHandle, targetHandle, relationname } =
      newConnection;
    const req_data = {
      fromnodeid: source,
      tonodeid: target,
      // source_node_handle: sourceHandle,
      // target_node_handle: targetHandle,
      // relationname: relationname ? relationname : "next"
    };
    const { _msg, _status, data } = await editRelation_(
      templateid,
      relationid,
      req_data
    );
    if (_status === 200 || _status === 201) {
      var tempNewConnection = {
        ...oldEdge,
        ...newConnection,
        id: "".concat(
          "reactflow__edge-",
          source,
          sourceHandle,
          "-",
          target,
          targetHandle
        ),
      };
      // var newElementList = updateEdge(oldEdge, tempNewConnection, elements);
      var newElementList = elements.map((item) =>
        item.id === oldEdge.id ? tempNewConnection : item
      );
      replaceElementList(newElementList);
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  // const onElementsRemove = (elementsToRemove) =>
  //   setElements((els) => removeElements(elementsToRemove, els));
  const onElementsRemove = (elementsToRemove) => {
    const new_list = removeElements(elementsToRemove, elements);
    replaceElementList(new_list);
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const checkStartNodeExists = () => {
    var temp_arr = [];
    temp_arr =
      elements.length > 0
        ? elements.filter((item) => item.type === "start_node")
        : [];
    if (temp_arr.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  //handle close of process modal
  const handleProcessModalClose = () => {
    setProcessModal(false);
    setModalData({});
  };

  //handle submit of process modal
  const handleProcessModalSubmit = (process_modal_data) => {
    const { start_node_obj, end_node_obj } = modal_data;
    const { endnodeid, startnodeid, templateid, name, servicetype, topic } =
      process_modal_data;
    const new_start_obj = {
      ...start_node_obj,
      id: startnodeid,
      data: {
        name,
        startnodeid,
        servicetype: servicetype ? servicetype : "rest",
        topic: topic ? topic : "",
      },
    };
    const new_end_obj = {
      ...end_node_obj,
      id: endnodeid,
      data: { name, endnodeid },
    };
    setProcessModal(false);
    addElementToList(new_start_obj);
    addElementToList(new_end_obj);
    setModalData({});
  };

  //handle clsoe of call node modal
  const handleCallModalClose = () => {
    setCallModal(false);
    setModalData({});
  };

  const handleCallModalSubmit = (call_data) => {
    const { call_node_obj } = modal_data;
    const {
      name,
      nodeid,
      input_field_details,
      output_field_details,
      inputPayload,
      servicetype,
    } = call_data;
    const new_call_node = {
      ...call_node_obj,
      id: nodeid,
      input_field_details: input_field_details,
      output_field_details: output_field_details,
      inputPayload: inputPayload,
      data: { name, nodeid, servicetype: servicetype ? servicetype : "rest" },
    };
    setCallModal(false);
    addElementToList(new_call_node);
    setModalData({});
  };

  //handle close of switch node
  const handleSwitchModalClose = () => {
    setSwitchModal(false);
    setModalData({});
  };

  const handleSwitchModalSubmit = (switch_data) => {
    const { switch_node_obj } = modal_data;
    const { nodeid, name, conditionList } = switch_data;
    const new_switch_obj = {
      ...switch_node_obj,
      id: nodeid,
      data: { name, nodeid, conditionList },
    };
    setSwitchModal(false);
    addElementToList(new_switch_obj);
    setModalData({});
  };

  //handle close of join node
  const handleJoinModalClose = () => {
    setJoinModal(false);
    setModalData({});
  };
  //handle submit of join
  const handleJoinModalSubmit = (join_data) => {
    const { join_node_obj } = modal_data;
    const { name, nodeid } = join_data;
    const new_join_obj = {
      ...join_node_obj,
      id: nodeid,
      data: { name, nodeid },
    };
    setJoinModal(false);
    addElementToList(new_join_obj);
    setModalData({});
  };

  //handle simulate submit
  const handleSimulateSubmit = (color) => {
    // props.alterSimulateStatus(true);
    // props.addAnimations(color);
    // setSimulateModal(false);
    return;
  };

  const handleSwitchListModalClose = () => {
    set_switch_condition_obj({
      source: "",
      target: "",
      conditionList: [],
    });
    setSwitchListModal(false);
  };

  const handleSwitchListModalSubmit = async (con_data) => {
    setSwitchListModal(false);
    setLoading(true);
    // const { source, target } = con_data;
    const {
      condition,
      conditionList,
      source,
      target,
      sourceHandle,
      targetHandle,
    } = con_data;
    const req_data = {
      relationname: condition,
      nodeid: target,
      source_node_handle: sourceHandle,
      target_node_handle: targetHandle,
    };
    const { _msg, _status, data } = await addNodeRelation(
      templateid,
      source,
      req_data
    );
    if (_status === 201) {
      const { relationid } = data;
      setLoading(false);
      const edge_data = {
        source: source,
        target: target,
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        label: condition ? condition : "",
        labelStyle: {
          font: "normal normal 600 14px/28px Nunito",
          color: "#002855",
        },
        arrowHeadType: "arrowclosed",
        type: "labelButtonEdge",
        data: { condition: condition },
        relationid: relationid,
      };

      const new_list = addEdge(edge_data, elements);
      replaceElementList(new_list);
      set_switch_condition_obj({
        source: "",
        target: "",
        conditionList: [],
      });
    } else {
      setLoading(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  //handle droping event
  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    if (type === "process_node") {
      const end_position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top + 400,
      });
      const start_node_obj = {
        id: getId(),
        type: "start_node",
        position: position,
        data: { text: "A custom node" },
      };
      const end_node_obj = {
        id: getId(),
        type: "end_node",
        position: end_position,
        data: { text: "A custom node" },
      };
      setModalData({
        start_node_obj,
        end_node_obj,
      });
      setProcessModal(true);
      // setElements((es) => es.concat(start_node_obj));
      // setElements((es) => es.concat(end_node_obj));
    } else {
      const new_node_obj = {
        id: getId(),
        type,
        position: position,
        data: { text: "A custom node" },
      };
      var startNodeExists = checkStartNodeExists();
      if (type === "call_node") {
        setModalData({
          call_node_obj: new_node_obj,
        });
        if (startNodeExists) {
          setCallModal(true);
        } else {
          enqueueSnackbar(
            "Cannot Create a Call Node Before Aggregated API Node. Try Creating Aggregated API Node First",
            { variant: "warning" }
          );
        }
      } else if (type === "switch_node") {
        setModalData({
          switch_node_obj: new_node_obj,
        });
        if (startNodeExists) {
          setSwitchModal(true);
        } else {
          enqueueSnackbar(
            "Cannot Create a Switch Node Before Aggregated API Node. Try Creating Aggregated API Node First",
            { variant: "warning" }
          );
        }
      } else if (type === "join_node") {
        setModalData({
          join_node_obj: new_node_obj,
        });
        if (startNodeExists) {
          setJoinModal(true);
        } else {
          enqueueSnackbar(
            "Cannot Create a Join Node Before Aggregated API Node. Try Creating  Aggregated API Node First",
            { variant: "warning" }
          );
        }
      }
      // setElements((es) => es.concat(new_node_obj));
    }
  };

  const ControlHelper = () => {
    const flowStateNodes = useStoreState((store) => store.nodes);
    const { enqueueSnackbar } = useSnackbar();
    const styles = orchestrationStyles();

    useMemo(()=>{
      setFlowStateNodesValue(flowStateNodes);
    },[flowStateNodes])

    const handleSaveDataFLow = async () => {
      const process_node_data = elements.filter((item) => item.type === "start_node");
      if (process_node_data.length === 1) {
          const { id } = process_node_data[0];
          const newDataFlow = data_flow_details?.length > 0 && data_flow_details?.map((item_) => {
              var flowStateNodeDetails = flowStateNodes.filter((node_) => node_.id === item_.id);
              if (flowStateNodeDetails.length === 1) {
                  var newPosition = {
                      x: flowStateNodeDetails[0]?.__rf.position.x.toFixed(2),
                      y: flowStateNodeDetails[0]?.__rf.position.y.toFixed(2)
                  }
                  return {
                      ...item_,
                      position: newPosition
                  }
              } else {
                  return item_
              }
          })
          const req_data = {
              data_flow_nodes_io_details: JSON.stringify(newDataFlow)
          }
          setLoading(true)
          const { _msg, data, _status } = await editNodeData(id, templateid, req_data);
          if (_status === 201) {
              // enqueueSnackbar("Data Flow Successfully Saved", { variant: "success" })
              setLoading(false)
          } else {
              setLoading(false)
              enqueueSnackbar(_msg, { variant: "error" })
          }
      }
    }

    const handleSaveProcessLocation = async () => {
      handleSaveDataFLow()
      var finalList = [];
      var newElements = [];
      flowStateNodes &&
        flowStateNodes.length > 0 &&
        flowStateNodes.forEach((item) => {
          var xPos = item.__rf.position.x.toFixed(2);
          var yPos = item.__rf.position.y.toFixed(2);
          finalList.push({
            id: item.id,
            posX: xPos,
            posY: yPos,
          });
          var currentNode =
            elements.length > 0 &&
            elements.find((node_) => node_.id === item.id);
          if (currentNode) {
            newElements.push({
              ...currentNode,
              position: item.__rf.position,
            });
          }
        });
      elements.length > 0 &&
        elements.forEach((edge_) => {
          if (edge_?.target && edge_?.source) {
            newElements.push(edge_);
          }
        });
      if (finalList.length > 0) {
        setLoading(true);
        const { _msg, _status, data } = await updateNodePositions_(
          applicationID,
          templateid,
          finalList
        );
        if (_status === 201) {
          replaceElementList(newElements);
          enqueueSnackbar(_msg, { variant: "success" });
          setLoading(false);
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to save Aggregated API as per your request, please try after sometime",
            { variant: "error" }
          );
          setLoading(false);
        }
      }
    };
    
    return (
      <Controls className={styles.controls}>
        <ControlButton onClick={handleSaveProcessLocation}>
          <Tooltip title="Save Aggregated APIs Flow">
            <SaveIcon />
          </Tooltip>
        </ControlButton>
      </Controls>
    );
  };

  const autoSaveProcessDataFlow = () => {
    const flowStateNodes = flowStateNodesValue;

    const handleSaveDataFLow = async () => {
      const process_node_data = elements.filter((item) => item.type === "start_node");
      if (process_node_data.length === 1) {
          const { id } = process_node_data[0];
          const newDataFlow = data_flow_details?.length > 0 && data_flow_details?.map((item_) => {
              var flowStateNodeDetails = flowStateNodes?.filter((node_) => node_.id === item_.id);
              if (flowStateNodeDetails?.length === 1) {
                  var newPosition = {
                      x: flowStateNodeDetails[0]?.__rf.position.x.toFixed(2),
                      y: flowStateNodeDetails[0]?.__rf.position.y.toFixed(2)
                  }
                  return {
                      ...item_,
                      position: newPosition
                  }
              } else {
                  return item_
              }
          })
          const req_data = {
              data_flow_nodes_io_details: JSON.stringify(newDataFlow)
          }
          setLoading(true)
          const { _msg, data, _status } = await editNodeData(id, templateid, req_data);
          if (_status === 201) {
              setLoading(false)
          } else {
              setLoading(false)
              enqueueSnackbar(_msg, { variant: "error" })
          }
      }
    }

    const handleSaveProcessLocation = async () => {
      handleSaveDataFLow()
      var finalList = [];
      var newElements = [];
      flowStateNodes &&
        flowStateNodes.length > 0 &&
        flowStateNodes.forEach((item) => {
          var xPos = item.__rf.position.x.toFixed(2);
          var yPos = item.__rf.position.y.toFixed(2);
          finalList.push({
            id: item.id,
            posX: xPos,
            posY: yPos,
          });
          var currentNode =
            elements.length > 0 &&
            elements.find((node_) => node_.id === item.id);
          if (currentNode) {
            newElements.push({
              ...currentNode,
              position: item.__rf.position,
            });
          }
        });
      elements.length > 0 &&
        elements.forEach((edge_) => {
          if (edge_?.target && edge_?.source) {
            newElements.push(edge_);
          }
        });
      if (finalList.length > 0) {
        setLoading(true);
        const { _msg, _status, data } = await updateNodePositions_(
          applicationID,
          templateid,
          finalList
        );
        if (_status === 201) {
          replaceElementList(newElements);
          setLoading(false);
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to save Aggregated API your request, please try after sometime",
            { variant: "error" }
          );
          setLoading(false);
        }
      }
    };
    handleSaveProcessLocation()
  };

  useMemo(()=>{
     autoSaveProcessDataFlow()
  },[flow_mode])

  // Simulate Modal
  return (
    <div>
      <Heading
        simulateClick={() => setSimulateModal(true)}
        source={source}
        applicationID={applicationID}
        isFromApplication={isFromApplication}
        appBundleId={appBundleId}
        fromMyApplication={fromMyApplication}
      />
      <div className={styles.dndflow}>
        {flow_mode === "process-flow" && <Sidebar />}
        {flow_mode === "process-flow" && (
          <ReactFlowProvider>
            <div className={styles.reactflowWrapper} ref={reactFlowWrapper}>
              <ReactFlow
                elements={elements}
                onConnect={onConnect}
                onEdgeUpdate={onEdgeUpdate}
                onElementsRemove={onElementsRemove}
                onLoad={onLoad}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
              >
                <Background variant="lines" gap={12} size={1} />
                <ControlHelper />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        )}
        {flow_mode === "data-flow" && <OrchestrationDataFlow />}
      </div>
      {
        <Backdrop
          className={styles.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      {showProcessModal && (
        <ProcessNodeModal
          isOpen={showProcessModal}
          onCloseClick={(obj) => handleProcessModalClose(obj)}
          onSubmitClick={(obj) => handleProcessModalSubmit(obj)}
          applicationID={applicationID}
          start_node_obj={modal_data["start_node_obj"]}
          end_node_obj={modal_data["end_node_obj"]}
        />
      )}
      {showCallModal && (
        <CallNodeModal
          isOpen={showCallModal}
          onCloseClick={() => handleCallModalClose()}
          onSubmitClick={(obj) => handleCallModalSubmit(obj)}
          call_node_obj={modal_data["call_node_obj"]}
        />
      )}
      {showSwitchModal && (
        <SwitchNodeModal
          isOpen={showSwitchModal}
          onCloseClick={(obj) => handleSwitchModalClose(obj)}
          onSubmitClick={(obj) => handleSwitchModalSubmit(obj)}
          switch_node_obj={modal_data["switch_node_obj"]}
        />
      )}
      {showJoinModal && (
        <JoinNodeModal
          isOpen={showJoinModal}
          onCloseClick={() => handleJoinModalClose()}
          onSubmitClick={(obj) => handleJoinModalSubmit(obj)}
          join_node_obj={modal_data["join_node_obj"]}
        />
      )}
      {showSimulateModal && (
        <SimulateModal
          isOpen={showSimulateModal}
          onCloseClick={() => setSimulateModal(false)}
          onSubmitClick={() => handleSimulateSubmit()}
        />
      )}
      {showShowSwitchListModal && (
        <SwitchNodeConditionModal
          isOpen={showShowSwitchListModal}
          onCloseClick={handleSwitchListModalClose}
          onSubmitClick={handleSwitchListModalSubmit}
          switch_condition_obj={switch_condition_obj}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = {
  add_canvas_application_data,
  addElementToList,
  replaceElementList,
  addAnimations,
  removeAnimations,
  alterSimulateStatus,
  addTemplateData,
  addInputOutputDataForNodes,
  alterFlowMode,
  alterDebugData,
};

const mapStateToProps = (state) => {
  const { application_data, elements, process_data, flow_mode,data_flow_nodes_io_details } =
    state.orchestration;
  const { error, loading, data } = application_data;
  const { templateid } = process_data;
  return {
    data_flow_details: data_flow_nodes_io_details,
    application_data: data,
    elements: elements,
    templateid: templateid,
    flow_mode: flow_mode,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrchestrationMainScreen);
