import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TestExecutionFilterListTable from "../../../components/common/Tables/TestExecutionFilterListTable";
import {
  executionFilterColumns,
  executionFilterDefaultColumnWidths,
  executionFilterTableColumnExtensions,
  executionFilterColumnOrder,
  executionFilterDefaultSorting,
  executionFilterColumnHidden,
} from "../../../constants/testExecutionFilterConstants";
import {
  fetchAllTestExecutionFilter,
  addTestExecutionHistoryHidden,
} from "../../../redux/actions/testExecutionAction";
import { getWindowDimensions } from "../../../utils/common";
import listStyles from "../../CommonStyles/listScreenStyles";
import { Pagination } from "@material-ui/lab";
import Loader from "../../../components/common/stuff/Loader";
const TestExecutionHistoryListTable = (props) => {
  const {
    fetchAllTestExecutionFilter,
    data,
    error,
    loading,
    _totalcount,
    deploymentId,
    testExecutionHistoryHidden,
    addTestExecutionHistoryHidden,
  } = props;
  const styles = listStyles();
  const deploymentName = props.deploymentName;
  const [testExecutionFilterRows, setTestExecutionFilterRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    fetchAllTestExecutionFilter(currentPage, deploymentId);
  }, [deploymentId, currentPage]);

  useEffect(() => {
    data.map((item) => {
      const duration =
        item?.results?.timings?.completed - item?.results?.timings?.started;
      const durationms = ((duration % 600) / 10).toFixed(0);
      item["runduration"] = durationms;
    });
    setTestExecutionFilterRows(
      data && data?.length > 0 ? data?.map((item) => item) : []
    );
  }, [data]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TestExecutionFilterListTable
            columns={executionFilterColumns}
            rows={testExecutionFilterRows}
            defaultColumnWidths={executionFilterDefaultColumnWidths}
            tableColumnExtensions={executionFilterTableColumnExtensions}
            columnOrder={executionFilterColumnOrder}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            windowDimensions={windowDimensions}
            hidden={testExecutionHistoryHidden}
            setHidden={addTestExecutionHistoryHidden}
            isCustomHeight={true}
            noDataMsg="No Execution Found"
          />
        </>
      )}

      <div style={{ marginTop: "100px" }}>
        {testExecutionFilterRows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={(e, val) => setCurrentPage(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchAllTestExecutionFilter,
  addTestExecutionHistoryHidden,
};

const mapStateToProps = (state) => {
  const { data, error, loading, _totalcount } =
    state?.testExecutionReducer?.testExecutionListFilter;
  const { testExecutionHistoryHidden } = state?.testExecutionReducer;
  return {
    data,
    error,
    loading,
    _totalcount,
    testExecutionHistoryHidden,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestExecutionHistoryListTable);
