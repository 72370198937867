import { IconButton, Tooltip } from "@material-ui/core";
import layoutStyles from "../../../styles/commonStyles/Layout";
import { AppButton } from "../../atoms/button/AppButton";
import { useDispatch } from "react-redux";
import { setPreviewMode } from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";
import BreadCrumbs from "../projectSetup/BreadCrumbs";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
const PreviewTopHeader = () => {
  const styles = layoutStyles();
  const { currentProject, currentPage } = useGetData();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const HandlePreviewMode = (mode) => {
    dispatch(
      setPreviewMode({
        mode,
      })
    );
    history.push(
      `/ui-applications/preview/${currentProject?.id}/${currentPage?.url}/${mode}`
    );
  };
  useEffect(() => {
    if (currentProject.previewMode !== params?.device)
      dispatch(setPreviewMode({ mode: params?.device }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.previewTopHeader}>
      <div className={"headerContent"}>
        <BreadCrumbs
          items={[
            { title: "UI Applications", path: "/ui-applications" },
            {
              title: currentProject?.name,
              path: `/ui-applications/${currentProject.id}/${currentPage.url}`,
            },
            { title: "Preview" },
          ]}
        />
      </div>
      <div className="headerContent">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="398px × 656px">
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: "0 12px" }}
              className={`${params?.device === "MOBILE" ? "activeMode" : ""}`}
              onClick={() => HandlePreviewMode("MOBILE")}
            >
              <img
                src="/img/mobile.svg"
                alt="mobile"
                height="24px"
                width="24px"
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="624px × 656px">
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: "0 12px" }}
              className={`${params?.device === "TABLET" ? "activeMode" : ""}`}
              onClick={() => HandlePreviewMode("TABLET")}
            >
              <img
                src="/img/tablet.svg"
                alt="Upload"
                height="24px"
                width="24px"
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="1590px × 656px">
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: "0 12px" }}
              className={`${params?.device === "WEB" ? "activeMode" : ""}`}
              onClick={() => HandlePreviewMode("WEB")}
            >
              <img src="/img/web.svg" alt="Upload" height="24px" width="24px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={"headerContent"}>
        <Tooltip title="Publish Mode">
          <IconButton
            color="inherit"
            edge="start"
            style={{ margin: "0 12px" }}
            onClick={() =>
              window.open(
                `/ui-applications/publish/${currentProject.id}/${
                  currentProject?.pages?.find(
                    (page) => page.islandingpage === true
                  )?.url
                }/${params?.device ? params?.device : "WEB"}`,
                "_blank"
              )
            }
          >
            <img src="/img/upload-line.svg" alt="Upload" />
          </IconButton>
        </Tooltip>
        <AppButton
          buttonName="Canvas"
          variant="outlined"
          startIcon={<img src="/img/canvas.svg" alt="Canvas" />}
          className="btnsmall"
          onClick={() =>
            history.push(
              `/ui-applications/${currentProject?.id}/${currentPage?.url}`
            )
          }
        />
      </div>
    </div>
  );
};

export default PreviewTopHeader;
