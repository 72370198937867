import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import FileDropperWithLayout from "../../FileDropperWithLayout";
import AddAiTaskVersion from "../../../../screens/Services/Version/AddAiTaskVersion";
import EditAiTaskVersion from "../../../../screens/Services/Version/EditAiTaskVersion";
import { AppButton } from "../../Button";
import AddIcon from "@material-ui/icons/Add";
import useCheckPermission from "../../hooks/useCheckPermission";

const CustomFieldGroupForAiTaskCreate = ({
  section,
  serviceData,
  setServiceData,
  error,
  handleChange,
  languages,
  userInfo,
  boilerplates,
  selectedProject,
  boilerPlateChecked,
  setBoilerPlateChecked,
  setError,
  projects,
  selectedServiceGroup,
  setSelectedProject,
  setSelectedServiceGroup,
  selectedDocuments,
  setSelectedDocuments,
  serviceGroups,
  setProjectSearchInput,
  setServiceGroupSearchInput,
  setDocumentsError,
  documentsError,
  tabbedView,
  createVersion,
  setCreateVersion,
  detailVersion,
  detailAPI,
  editAPI,
  handleAddVersionInCreate,
  serviceId,
  createAPI, setCreateAPI,
  version, apiVersions,
  selectedVersion,
  statuses,
  setSelectedLanguage,
  selectedLanguage
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();
  const [languageOpen, setLanguageOpen] = useState(false);
  const [boilerplateOpen, setBoilerplateOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [serviceGroupOpen, setServiceGroupOpen] = useState(false);
  const histroy = useHistory();
  const [versionOpen, setVersionOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const closeDropDown = () => {
    setLanguageOpen(false);
    setBoilerplateOpen(false);
    setProjectOpen(false);
    setServiceGroupOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const governanceSection = () => {
    return (
      <div style={{ padding: tabbedView ? "10px" : "" }}>
        <Typography variant="h6" className={styles.fieldText}>
          Language <span className={styles.red}>*</span>
        </Typography>
        <Autocomplete
          open={languageOpen}
          onOpen={() => setLanguageOpen(true)}
          onClose={() => setLanguageOpen(false)}
          options={languages}
          value={selectedLanguage}
          getOptionLabel={(option) => option.name}
          disabled={!selectedProject._id ? true : false}
          className={`${styles.searchInput2} ${error.languageID?.isError &&
            !serviceData.languageID &&
            styles.errorAuto
            }`}
          onChange={(e, input) => {
            if (input) {
              setSelectedLanguage(input)
              setServiceData({
                ...serviceData,
                languageID: input._id,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Search Language"
            />
          )}
        />
        {error.languageID?.isError && !serviceData.languageID && (
          <p className={styles.errorMsg}>{"Language is not selected"}</p>
        )}
      </div>
    );
  };


  const statusSection = () => {
    return (
      <div style={{ padding: tabbedView ? "10px" : "" }}>

        <>
          <Autocomplete
            open={statusOpen}
            onOpen={() => setStatusOpen(true)}
            onClose={() => setStatusOpen(false)}
            options={statuses}
            getOptionLabel={(option) => option.status}
            className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
              }`}
            onChange={(e, input) => {
              if (input) {
                setServiceData({
                  ...serviceData,
                  status: input.status,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search Status"
                placeholder="Search Status"
              />
            )}
          />
          {error.status?.isError && (
            <p className={styles.errorMsg}>{"Status is Required"}</p>
          )}
        </>


        {serviceData?.status ? (
          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
            <div
              style={{
                backgroundColor: statuses?.find(
                  (s) => s.status === serviceData?.status
                )?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                position: "relative",
                right: 10,
              }}
            ></div>
            <Typography
              variant="h6"
              className={styles.fieldText}
              style={{
                color: statuses && statuses.find((s) => s.status === serviceData?.status)
                  ?.color,
              }}
            >
              {statuses && statuses
                .find((s) => s.status === serviceData?.status)
                ?.status.charAt(0)
                .toUpperCase() +
                statuses
                  .find((s) => s.status === serviceData?.status)
                  ?.status.slice(1)}
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" className={styles.fieldText}>
            Status is not set
          </Typography>
        )}
      </div>
    );
  };


  const handleAddVersion = async (e) => {
    await handleAddVersionInCreate();

    //   if (!tabbedView) {
    //     histroy.push({
    //       pathname: `/ai-task-versions/add/${serviceId}`,
    //       state: { serviceName: serviceData.name,serviceType: serviceData?.serviceType },
    //     })
    //   }
    //   else{
    //     // if(serviceCreated){

    //       setCreateVersion(true);
    //     // }
    //   } 



  };



  const versionSection = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ margin: "10px 0 10px", padding: tabbedView ? "10px" : "" }}
        >
          {tabbedView
            ?
            <>
              {!createVersion && !detailVersion && (
                <AppButton
                  color="primary"
                  buttonName="Create Version"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={!checkCompPermission("Microservice", ["Create Version"])}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleAddVersion}
                />
              )
              }</>
            :
            <>
              {(
                <AppButton
                  color="primary"
                  buttonName="Create Version"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={!checkCompPermission("Microservice", ["Create Version"])}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleAddVersion}
                />
              )}
            </>
          }
        </Grid>
        {createVersion ?
          (
            <AddAiTaskVersion
              serviceName={serviceData.name}
              serviceId={serviceId}
              setCreateVersion={setCreateVersion}
              tabbedView={tabbedView}
              createVersion={createVersion}
              serviceType={serviceData.serviceType}
            />
          )
          : detailVersion ?
            <EditAiTaskVersion
              serviceName={serviceData.name}
              tabbedView={tabbedView}
              serviceId={serviceId}
              versionId={selectedVersionId}
              isEditMode={editVersion}
            />
            :
            (
              // <DevExpressTable 
              //   columns={versionTableData.columns}
              //   serviceData={serviceData}
              //   rows={versions.map(version => ({ ...version, serviceType: serviceData.serviceType }))}
              //   defaultColumnWidths={versionTableData.widths}
              //   tableColumnExtensions={versionTableData.extentions}
              //   columnOrder={versionTableData.orders}
              //   defaultHiddenColumnNames={versionTableData.hides}
              //   loading={versionsList.loading}
              //   isCustomHeight={false}
              //   isVersionData={true}
              //   sort={versionSort}
              //   setSort={addVersionSort}
              //   hidden={versionHidden}
              //   setHidden={addVersionHidden}
              //   noDataMsg="No Version Found"
              // />
              <></>
            )}
        {/* {!createVersion && versions.length !== 0 && (
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(versionsList?.data?._totalcount / 10)}
              page={currentVersionPage}
              onChange={(e, val) => setCurrentVersionPage(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        )} */}
      </>
    );
  };
  const handleAddAPI = () => {
    setCreateAPI(true);
  };

  const apiSection = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ margin: "10px 0 10px", padding: tabbedView ? "10px" : "" }}
        >
          {!createAPI && <Grid item xs={3}>
            <label htmlFor="" className={styles.fieldHeading}>
              Version
            </label>
            <Autocomplete
              open={versionOpen}
              onOpen={() => setVersionOpen(true)}
              onClose={() => setVersionOpen(false)}
              options={apiVersions}
              getOptionLabel={(option) =>
                option.versionTag ? option.versionTag : ""
              }
              value={selectedVersion ? selectedVersion : ""}
              className={`${styles.searchInput3}`}
              // onChange={(e, input) => {
              //   if (input) {
              //     const foundVersion = versions.find((v) => v.id === input._id);
              //     setSelectedVersion(foundVersion ? foundVersion : {});
              //   }
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Search Version"
                />
              )}
            />
          </Grid>
          }

          {tabbedView ?
            <>
              {!createAPI && (
                <AppButton
                  color="primary"
                  buttonName="Create API"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={!selectedVersion?._id ? true : false || !checkCompPermission('Version', ['Create API'])}
                  className="btnsmall"
                  onClick={() => setCreateAPI(true)}
                />
              )}
            </>
            :
            <>
              {(
                <AppButton
                  color="primary"
                  buttonName="Create API"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={!selectedVersion?._id ? true : false || !checkCompPermission('Version', ['Create API'])}
                  className="btnsmall"
                  onClick={() =>
                    histroy.push({
                      pathname: `/ai-task-api/add/${serviceId}/${selectedVersion.id}`,
                      state: { serviceName: serviceData.name },
                    })
                  }
                />
              )}
            </>

          }
        </Grid>

        {createAPI ?
          (
            <AddAiTaskApi
              createAPI={createAPI}
              setCreateAPI={setCreateAPI}
              serviceId={serviceId}
              versionId={selectedVersion?._id}
              serviceType={serviceData?.serviceType}
              serviceName={serviceData.name}
              handleCloseAPI={handleCloseAPI}
            />
          )
          : (
            // <DevExpressTable
            //   columns={apiTableData.columns}
            //   rows={apis}
            //   defaultColumnWidths={apiTableData.widths}
            //   tableColumnExtensions={apiTableData.extentions}
            //   columnOrder={apiTableData.orders}
            //   defaultHiddenColumnNames={apiTableData.hides}
            //   loading={false}
            //   isCustomHeight={false}
            //   sort={apiSort}
            //   setSort={addAPISort}
            //   hidden={apiHidden}
            //   setHidden={addAPIHidden}
            //   noDataMsg="No API Found"
            // />
            <></>
          )}
        {/* {apis.length !== 0 && (
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(apisList?._totalcount / 10)}
              page={currentAPIPage}
              onChange={(e, val) => setCurrentAPIPage(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        )} */}
      </>
    );
  };

  const boilerPlateSection = () => {
    return (
      <>
        <FormControlLabel
          value="Initalize Boiler plate :"
          control={
            <Switch
              checked={boilerPlateChecked}
              onChange={(e, val) => {
                setBoilerPlateChecked(!boilerPlateChecked);
                if (val) {
                  setError({
                    ...error,
                    boilerplateID: false,
                    versionTag: false,
                    versionDescription: false,
                  });
                } else {
                  const tempError = error;
                  delete tempError["boilerplateID"];
                  delete tempError["versionTag"];
                  delete tempError["versionDescription"];
                  setError(tempError);
                }
              }}
              color="primary"
            />
          }
          style={{ marginLeft: 0 }}
          label="Initalize Boiler plate :"
          labelPlacement="start"
        />
        {boilerPlateChecked && (
          <>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              <Grid item xs={3} sm={3}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Boiler Plate <span className={styles.red}>*</span>
                </label>
                <Autocomplete
                  open={boilerplateOpen}
                  onOpen={() => setBoilerplateOpen(true)}
                  onClose={() => setBoilerplateOpen(false)}
                  options={boilerplates}
                  getOptionLabel={(option) => option.BoilerplateName}
                  className={`${styles.searchInput3} ${error.boilerplateID &&
                    !serviceData.boilerplateID &&
                    styles.error
                    }`}
                  onChange={(e, input) => {
                    if (input) {
                      setServiceData({
                        ...serviceData,
                        boilerplateID: input._id,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Search Boilerplate"
                    />
                  )}
                />
                {error.boilerplateID && !serviceData.boilerplateID && (
                  <p className={styles.errorMsg}>
                    {"Boilterplate is not selected"}
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={3} sm={3}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Version <span className={styles.red}>*</span>
                </label>
                <input
                  type="text"
                  name="versionTag"
                  className={`${styles.input} ${error.versionTag && styles.error
                    }`}
                  value={serviceData.versionTag}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {error.versionTag && (
                  <p className={styles.errorMsg}>{"Version Tag is Required"}</p>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <label
                  htmlFor="projectDescription"
                  className={styles.fieldHeading}
                >
                  Version Description <span className={styles.red}>*</span>
                </label>
                <textarea
                  name="versionDescription"
                  value={serviceData.versionDescription}
                  onChange={handleChange}
                  className={`${styles.textarea} ${error.versionDescription && styles.error
                    }`}
                  rows="8"
                ></textarea>
                {error.versionDescription && (
                  <p className={styles.errorMsg}>
                    {"Version Description is Required"}
                  </p>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "AITask ProjectId") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Project <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={projectOpen}
                onOpen={() => setProjectOpen(true)}
                onClose={() => setProjectOpen(false)}
                options={projects}
                value={selectedProject}
                getOptionLabel={(option) => option?.name}
                className={`${styles.searchInput3} ${error.projectID?.isError &&
                  !selectedProject?._id &&
                  styles.error
                  }`}

                onChange={(e, input) => {
                  if (input) {
                    setSelectedProject(input);
                    setServiceData({
                      ...serviceData,
                      projectID: input?._id,
                    });
                  } else {
                    setSelectedProject({});
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      projectID: "",
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setProjectSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Project"
                  />
                )}
              />
              {error.projectID?.isError && !selectedProject?._id && (
                <p className={styles.errorMsg}>{"Project is Required"}</p>
              )}
            </>
          );
        } else if (field.name === "AITask ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Service Group <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={serviceGroupOpen}
                onOpen={() => setServiceGroupOpen(true)}
                onClose={() => setServiceGroupOpen(false)}
                options={serviceGroups}
                value={selectedServiceGroup}
                getOptionLabel={(option) => option.name}
                disabled={!selectedProject?._id ? true : false}
                className={`${styles.searchInput3} ${error.servicegroupID.isError &&
                  !selectedServiceGroup?._id &&
                  styles.error
                  }`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedServiceGroup(input);
                    setServiceData({
                      ...serviceData,
                      servicegroupID: input._id,
                    });
                  } else {
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setServiceGroupSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Service Group"
                  />
                )}
              />
              {error.servicegroupID.isError && !selectedServiceGroup._id && (
                <p className={styles.errorMsg}>{"Service Group is Required"}</p>
              )}
            </>
          );
        } else if (field.name === "AITask Image Name" && serviceData.serviceType === "External") {
          return (
            <>

            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                  }`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? moment
                      .unix(serviceData[field.datakey])
                      .tz(userInfo.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={serviceData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error
                }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${error[field.datakey]?.isError && styles.error
                }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                        }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  }`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };
  const documentsSection = () => {
    return (
      <div style={{ padding: "0px 20px" }}>

        <FileDropperWithLayout
          filesLimit={20}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={(data) => setSelectedDocuments(data)}
          documentsError={documentsError}
          setDocumentsError={setDocumentsError}
        />
      </div>
    );
  };
  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "AITask_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "AITask Languageid":
                          return governanceSection();
                        case "Microservice InitializeboilerplateID":
                          return boilerPlateSection();
                        case "AITask Status":
                          return statusSection();
                        case "Version Table":
                          return versionSection();
                        case "API Table":
                          return apiSection();
                        case "Documentation Table":
                          return documentsSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "AITask Languageid":
                                return governanceSection();
                              case "Microservice InitializeboilerplateID":
                                return boilerPlateSection();
                              case "Version Table":
                                return versionSection();
                              case "API Table":
                                return apiSection();
                              case "Documentation Table":
                                return documentsSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForAiTaskCreate);
