const envColumns = [
  {
    name: "name",
    title: "Environment Name",
    route: "environment-details",
    for: "environmentvaribales",
  },
  { name: "EnvVariableState", title: "State", for: "environmentvaribales" },
  { name: "createdby", title: "Created By", for: "environmentvaribales" },
  { name: "createdon", title: "Created On", for: "environmentvaribales" },
  { name: "runtimeenvactions", title: "Action" },
];

const envDefaultColumnWidths = [
  { columnName: "name", weightage: 2, width: 200 },
  { columnName: "EnvVariableState", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "runtimeenvactions", weightage: 2, width: 200 },
];

const envTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "EnvVariableState", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "runtimeenvactions", align: "center", sortingEnabled: false },
];

const envColumnOrder = [
  "name",
  "EnvVariableState",
  "createdby",
  "createdon",
  // "runtimeenvactions",
];

const envDefaultSorting = [{ columnName: "name", direction: "asc" }];

const envColumnHidden = [
  "name",
  "EnvVariableState",
  "createdby",
  "createdon",
  "runtimeenvactions",
];

export {
  envColumns,
  envDefaultColumnWidths,
  envTableColumnExtensions,
  envColumnOrder,
  envDefaultSorting,
  envColumnHidden,
};