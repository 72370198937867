import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();
export class launguageServices {
  getAllLanguagesList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filter=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getLanguageDetailsByID(LaungID) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}`;
    return apiHelper.get(uri);
  }
  getLanguageDependencyByID(LaungID) {
    const uri = `${BASE_URL}/api/v1.0.0/languages/${LaungID}/projects`;
    return apiHelper.get(uri);
  }
  CreateLaunguage(laungData) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages`;
    return apiHelper.post(uri, laungData);
  }
  EditLanguageByID(LaungID, laungData) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}`;
    return apiHelper.put(uri, laungData);
  }
  EditLanguageDockerImage(LaungID, dockerData) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}//dockerdetails`;
    return apiHelper.put(uri, dockerData);
  }
  DeleteLanguage(LaungID) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}`;
    return apiHelper.delete(uri);
  }
  BlockLanguage(LaungID, blockData) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}/block`;
    return apiHelper.update(uri, blockData);
  }
  UnBlockLanguage(LaungID, blockData) {
    const uri = `${BASE_URL}/api/v1.0.0/allowedLanguages/${LaungID}/unblock`;
    return apiHelper.update(uri, blockData);
  }
}
