import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import CustomModal from "../../../atoms/customModal/CustomModal";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import useGetData from "../../../../hooks/useGetData";
import { SelectHelper } from "./helper/SelectHelper";
import { Grid } from "@material-ui/core";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { MenuItem, Select } from "@material-ui/core";
function SelectSettings() {
  const {
    currentPage,
    currentProject,
    currentComponent,
    getDataValue,
    executeJavascript,
    globalAcessModeChange,
    NormalHandleChange,
  } = useGetData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [accessModeErr, setAccessModeErr] = useState("");
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  // js mode
  const handleChangeJSmode = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        switch (currentComponent.tabValue) {
          case false:
            return {
              ...component,
              accessValueData: {
                dataInput: value,
                dataValue: dataValue,
              },
            };
          case true:
            return {
              ...component,
              jsValueData: {
                dataInput: value,
                dataValue: dataValue,
              },
            };
          default:
            return component;
        }
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChangeJSmodeForOptions = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          data: {
            dataInput: value,
            dataValue: dataValue,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const changeTab = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          tabValue: !component?.tabValue,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const customArr = currentComponent?.data;
  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      {isModalOpen ? <CustomModal setIsModalOpen={setIsModalOpen} /> : null}
      <div className="field">
        {/* <DataInput
          value={
            currentComponent.tabValue === false
              ? currentComponent?.accessValue
              : currentComponent?.jsValue
          }
          onChange={handleChange}
          label="Value"
          name="value"
        /> */}
        <JSDataInput
          value={
            currentComponent.tabValue === false
              ? currentComponent?.accessValueData
              : currentComponent?.jsValueData
          }
          onChange={handleChangeJSmode}
          label="Value"
          title={`${currentComponent.name} : Data`}
          error={error}
        />
      </div>

      <div className="field">
        <AccessDataInput
          label="Label"
          name="label"
          value={currentComponent?.label}
          onChange={(value) =>
            globalAcessModeChange(value, "label", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <FormControlLabel
        label={<span style={{ fontSize: 13 }}>Required field</span>}
        control={
          <Checkbox
            checked={currentComponent?.isRequired}
            onChange={(event) =>
              NormalHandleChange("isRequired", event?.target?.checked)
            }
            size="small"
            color="primary"
          />
        }
      />
      <div className="field">
        <TrueFalseButton
          name="direction"
          leftButton="Horizontal"
          rightButton="Vertical"
          value={!currentComponent?.direction}
        />
      </div>
      <div className="field">
        <TrueFalseButton
          leftButton={
            !currentComponent?.alignment ? (
              <img src="/img/Left.-white.svg" alt="" />
            ) : (
              <img src="/img/Left.svg" alt="" />
            )
          }
          rightButton={
            currentComponent?.alignment ? (
              <img src="/img/Right.svg" alt="" />
            ) : (
              <img src="/img/Right.-Black.svg" alt="" />
            )
          }
          name="alignment"
          value={!currentComponent?.alignment}
        />
      </div>
      {currentComponent?.type === "Select" && (
        <div className="field">
          <div className="tableColumns">
            <Grid container justifyContent="space-between">
              <Grid item>
                <label>Options</label>
              </Grid>
              <Grid item>
                <label
                // onClick={() => changeTab()}
                >
                  JS
                </label>
              </Grid>
            </Grid>
            {currentComponent?.tabValue === false ? (
              <SelectHelper customArr={customArr} />
            ) : (
              // <DataInput
              //   value={currentComponent?.dataInput}
              //   onChange={handleChange}
              //   label=""
              //   name="dataInput"
              // />
              <JSDataInput
                value={currentComponent?.data}
                onChange={handleChangeJSmodeForOptions}
                label=""
                title={`${currentComponent.name} : Data`}
                error={error}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectSettings;
