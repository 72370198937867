// import { CommonServices, } from "../../services/commonServices";
import { DocumentServices } from "../../services/documentServices";

// const CommonService = new CommonServices();
const documentService = new DocumentServices();

export const fetchDocumentListData = async (currentPage, filter, sort) => {
  try {
    const res_data = await documentService.getAllDocuments(
      currentPage,
      filter,
      sort
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchDocumentById = async (docId) => {
  try {
    const res_data = await documentService.getDocumentById(docId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addDocument = async (docData) => {
  try {
    const res_data = await documentService.addDocument(docData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editDocument = async (docId, docData) => {
  try {
    const res_data = await documentService.editDocument(docId, docData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editDocumentMeta = async (docId, docData) => {
  try {
    const res_data = await documentService.editDocumentMeta(docId, docData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDocument = async (docId) => {
  try {
    const res_data = await documentService.deleteDocument(docId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchDocumentTypeListData = async () => {
  try {
    const res_data = await documentService.getAllDocumentTypes();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addDocumentType = async (docTypeData) => {
  try {
    const res_data = await documentService.addDocumentType(docTypeData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editDocumentType = async (doctypeId, docTypeData) => {
  try {
    const res_data = await documentService.editDocumentType(
      doctypeId,
      docTypeData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDocumentType = async (doctypeId) => {
  try {
    const res_data = await documentService.deleteDocumentType(doctypeId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDocumentsByID = async (documentId) => {
  try {
    const res_data = await documentService.deleteDocumentByID(documentId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteAllDocumentsByProjectResourceID = async (resourceID) => {
  try {
    const res_data = await documentService.deleteDocumentsByProjectResourceID(
      resourceID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDocumentsByServiceGroupResourceID = async (resourceID) => {
  try {
    const res_data =
      await documentService.deleteDocumentsByServiceGroupResourceID(resourceID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const deleteDocumentsByServiceResourceID = async (resourceID) => {
  try {
    const res_data = await documentService.deleteDocumentsByServiceResourceID(
      resourceID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
