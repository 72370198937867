import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import manageScreenStyles from "../../screens/CommonStyles/manageScreenStyles";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

const SectionTabs = ({ entity, activeTab, handleActiveTab }) => {
  const styles = manageScreenStyles();

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleActiveTab}
        indicatorColor="primary"
        textColor="primary"
        className={styles.documentsListAccessTab}
        variant={"scrollable"}
      >
        {entity &&
          entity.length !== 0 &&
          entity.map((section) => {
            return (
              <Tab
                label={
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {section["section Label"]}{" "}
                    {section["isError"] && (
                      <PriorityHighIcon
                        style={{
                          color: "#e23f27",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      />
                    )}
                  </div>
                }
              />
            );
          })}
      </Tabs>
    </div>
  );
};

export default SectionTabs;
