import React, { useState ,useEffect} from 'react';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import CustomButton from '../../components/common/Button/CustomButton';
import useStyles from './commonStyles';
import { verifyEmailCodeLogin ,resendCode, forceLoginService} from '../../utils/authentication/authenticationUtils';
import { userDetailAction } from '../../redux/actions/userProfileAction';
import {
  addUserFavouite,
  addUserSubscription,
} from '../../redux/actions/userPersonalAction';
import jwt_decode from "jwt-decode";

import { addAuthData } from '../../redux/actions/authAction';
import { add_current_organization_data_action } from '../../redux/actions/organizationAction';
import { ForceLoginModal } from '../../components/common/modals/ForceLoginModal';

function VerifyOTPScreen(props) {
  const [otp, setOtp] = useState('');
  const [forceLoginModalShow, setForceLoginModalShow] = useState(false);
  const [tempToken, setTempToken] = useState();
  const [errorObj, setErrorObj] = useState({
    otp: false,
  });
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [disableBtn, setDisableBtn] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const handleOTPChange = (value) => {
    setErrorObj(() => ({ otp: false }));
    setOtp(value);
  };

  const handleButtonClick = async (isFrom) => {
    var errorFlag = false;
    if (otp === '') {
      errorFlag = true;
      setErrorObj(() => ({ otp: true }));
    }
    if (!errorFlag) {
      setLoading(true);
      const { email } = location.state;
      const req_data = {
        email: email,
        code: otp,
      };
      if(isFrom === "force_login"){
        var { _msg, _status, data } = await forceLoginService(tempToken)
      } else {
        var { _msg, _status, data } = await verifyEmailCodeLogin(req_data);
      }
      if (_status === 200 || _status === 201) {
        var authdata = {};
        let userId = '';
        setLoading(false);
        const {accessToken, refreshToken,userDetails, userActions} = data;
        if (accessToken && refreshToken) {
          const decryptData = jwt_decode(accessToken);
          userId = userDetails?.id;
          authdata = {
            ...authdata,
            accesstoken: accessToken,
            refreshtoken: refreshToken,
            userid: userId,
            userActions : decryptData?.actions,
            token_expired_msg: ""
          };

          // authdata
          props.addAuthData(authdata);

          // user Detail
          props.userDetailAction();

          // userfav
          props.addUserFavouite(userId);

          // usersubscription
          props.addUserSubscription(userId);

          // Org Data
          props.add_current_organization_data_action();

          history.push('/dashboard');
        } else {
          setLoading(false);
          enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: 'error' });
        }
      } else {
        if(_status === 409){
          setForceLoginModalShow(true)
          setTempToken(data?.token)
        } else {
          enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: 'error' });
        }
        setLoading(false);
      }
    }
    }
  
  const handleResendCode = async() => {
    try {
      setDisableBtn(true)
      const { _msg, _status } = await resendCode(history.location.state);
      if(_status===200||201){
        setDisableBtn(false)
        enqueueSnackbar(_msg ? _msg : 'Otp sent successfully', {
        variant: 'success',
      });
      }
    } catch (error) {
      setDisableBtn(false)
      const { _msg, _status, data } = error.response.data;
      if (_msg) {
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
      }
    }
  };
  // useEffect(()=>{
  //   console.log(history.location.state.email)
  // },[history])

  const handleCloseModal = (isFrom) => {
    setForceLoginModalShow(false)
    if(isFrom === "timeout"){
      history.push("/")
    }
  }
  return (
    <div className={styles.container}>
      <ForceLoginModal open={forceLoginModalShow} handleClose={handleCloseModal} handleConfirm = { ()=> {handleButtonClick("force_login"); setForceLoginModalShow(false)}} />

      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <text
        className={styles.big_text}
        style={{ alignSelf: 'center', marginTop: 65, fontSize: 21 }}
      >
        Login to your account
      </text>
      <text className={styles.subtitle} style={{ alignSelf: 'center' }}>
        Enter 6-digit verification code
      </text>
      {/* <text
        className={styles.label}
        style={{ textAlign: "center", marginTop: 15 }}
      >
        We've emailed your code
      </text> */}
      {/* <text
        className={styles.label}
        style={{ textAlign: "center", marginTop: 5 }}
      >
        You'll receive an email containing 6-digit verification code
      </text> */}
      <div className={styles.email} style={{ marginTop: 22.5 }}>
        <label
          className={styles.smalltext}
          style={{
            marginLeft: 0,
            font: 'normal normal normal 14px/19px Nunito',
            color: '#000000',
          }}
        >
          Type code to confirm
        </label>
        <input
          className={styles.otp_text}
          style={
            errorObj.otp
              ? { marginTop: 5, borderColor: 'red' }
              : { marginTop: 5 }
          }
          value={otp}
          onChange={(e) => handleOTPChange(e.target.value)}
        ></input>
        {errorObj.otp && <text className={styles.error_text}>Invalid OTP</text>}
      </div>
      <div style={{ marginTop: 27, alignSelf: 'center' }}>
        <CustomButton
          type="long"
          label="Verify Code"
          customStyles={{
            height: 40,
            width: 264,
            font: 'normal normal normal 14px/19px Nunito',
            cursor: 'pointer',
          }}
          onClick={handleButtonClick}
        />
      </div>
      <div style={{ alignSelf: 'center', marginTop: 33 }}>
        <text className={styles.resend_code}>
          Didn't receive the verification code yet?
          <Button
            className={styles.resendBtn}
            onClick={handleResendCode}
            disabled={disableBtn===true}
          >
            {' '}
            Resend Code
          </Button>
        </text>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  addAuthData,
  addUserFavouite,
  addUserSubscription,
  userDetailAction,
  add_current_organization_data_action,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPScreen);