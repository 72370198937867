import { Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import styles from "./styles";

const CustomTag = ({ title, onRemove }) => {
  const classes = styles();
  return (
    <div className={classes.tagContainer}>
      <img src="/img/file-line.svg" alt="fileLine" />
      <Tooltip title={title}>
        <span className={classes.titleSpan}>{title}</span>
      </Tooltip>
      <Cancel className={classes.cancelIcon} onClick={onRemove} />
    </div>
  );
};

export default CustomTag;
