import {
  ADD_APP_SELECTION_DATA,
  ADD_DEPLOYMENT_LIST,
  DEPLOYMENT_FILTERS,
  DEPLOYMENT_SORT,
  DEPLOYMENT_HIDDEN,
  ADD_DEPLOY_CONTAINER,
  ADD_ENVIRONMENT_DEPLOYMENT,
  RESTART_CONTAINER_DEPLOYMENT,
  ADD_DEPLOYMENT_PROCESS,
  SELECT_CONTAINER_DEPLOYMENT,
  DELETE_DEPLOYMENT_BY_ID,
  SELECT_PROCESS_DEPLOYMENT,
  EDIT_CONTAINER_DEPLOYMENT,
  ADD_SELECTED_CLUSTER,
  ADD_VOLUMN_DEPLOYMENT,
  CLEAR_DEPLOYMENT,
  CLEAR_DEPLOYMENT_DATA,
  ADD_DEPLOYMENT_RESPONSE,
  ADD_DEPLOYMENT_DETAILS_LIST,
  DELETE_DEPLOYMENT_BY_CONTAINER,
  SET_DEPLOY_ERROR,
  ADD_SIZING_DEPLOYMENT,
  REMOVE_VERSION,
  ADD_CLOUD_CONFIG_DETAILS,
  ADD_CLUSTER_LIST_DEPLOYMENT,
  ADD_DEPLOYMENT_NAME,
  ADD_CHART_VALUES,
  SHOW_CHART,
  ADD_CLUSTER_VALIDITY,
  REMOVE_CLUSTER_VALIDITY,
  ADD_DEPLOYMENT_DETAILS_HIDDEN,
  ADD_NODES_USAGE_DATA,
  ADD_NODES_LIST,
} from "../actionTypes";

import { v4 as uuidv4 } from "uuid";

const initialState = {
  deploymentResponse: {},
  appSelectionData: {
    error: false,
    loading: false,
  },
  dependenciesData: {
    data: [],
    error: false,
    loading: false,
    container: [],
    process: [],
  },
  hostingData: {
    data: [],
    error: false,
    loading: false,
    showChart: false,
    chart: {},
    clusterValidity: false,
  },
  deploymentDetailsList: {
    data: [],
    error: false,
    loading: false,
  },
  deploymentList: {
    data: [],
    error: false,
    loading: false,
  },
  deplyomentName: {},
  deploymentFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  deploymentSort: [{ columnName: "createdon", direction: "desc" }],
  deploymentHidden: [
    "deploymentname",
    "clustername",
    "Status",
    "createdby",
    "action",
  ],
  deploymentContainerHidden: [
    "Port",
    "Replicas",
    "ClusterName",
    "ImageName",
    "Namespace",
  ],

  deploymentDetailsHidden: [
    "Port",
    "Replicas",
    "ClusterName",
    "ImageName",
    "Namespace",
  ],
  nodesUsage: []
};

const deploymentProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DEPLOYMENT_DATA:
      return {
        ...state,
        deploymentResponse: initialState.deploymentResponse,
        appSelectionData: initialState.appSelectionData,
        dependenciesData: initialState.dependenciesData,
        hostingData: initialState.hostingData,
        deploymentDetailsList: initialState.deploymentDetailsList,
        deploymentList: initialState.deploymentList,
        deplyomentName: initialState.deplyomentName,
        deploymentFilters: initialState.deploymentFilters,
        deploymentSort: initialState.deploymentSort,
        deploymentContainerHidden: initialState.deploymentContainerHidden,
        deploymentDetailsHidden: initialState.deploymentDetailsHidden,
        nodesUsage: [],
      };

    case ADD_APP_SELECTION_DATA: {
      return {
        ...state,
        appSelectionData: { ...state.appSelectionData, ...action.payload },
      };
    }

    case ADD_SELECTED_CLUSTER: {
      return {
        ...state,
        hostingData: { ...state.hostingData, ...action.payload },
      };
    }
    case ADD_CLOUD_CONFIG_DETAILS: {
      return {
        ...state,
        hostingData: {
          ...state.hostingData,
          clusterDetails: { ...action.payload },
        },
      };
    }
    case ADD_DEPLOYMENT_LIST: {
      return {
        ...state,
        deploymentList: {
          ...state.deploymentList,
          ...action.payload,
          deleteDeploymentStatus: false,
        },
      };
    }
    // case REMOVE_VERSION: {
    //   delete state.appSelectionData.deployment_app_version;
    //   delete state.appSelectionData.versionDetails;
    // }
    case ADD_DEPLOYMENT_DETAILS_LIST: {
      return {
        ...state,
        deploymentDetailsList: {
          ...state.deploymentDetailsList,
          ...action.payload,
        },
      };
    }

    case DEPLOYMENT_FILTERS: {
      return {
        ...state,
        deploymentFilters: [...action.payload],
      };
    }
    case DEPLOYMENT_SORT: {
      return {
        ...state,
        deploymentSort: [...action.payload],
      };
    }
    case DEPLOYMENT_HIDDEN: {
      return {
        ...state,
        deploymentHidden: [...action.payload],
      };
    }
    case ADD_DEPLOY_CONTAINER: {
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: action.payload,
        },
      };
    }

    case SET_DEPLOY_ERROR: {
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            action.payload.map((errorItem) => {
              if (errorItem.uid === item.uid) {
                if (errorItem.error) {
                  item["error"] = errorItem.error;
                }
              }
            });
            return item;
          }),
        },
      };
    }

    case ADD_ENVIRONMENT_DEPLOYMENT: {
      const { id, environment, deployment_type } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              return { ...item, environment: environment };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case RESTART_CONTAINER_DEPLOYMENT: {
      const { id, checked } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              if (checked) {
                return { ...item, restart: "always" };
              } else {
                delete item["restart"];
                return { ...item };
              }
            } else {
              return item;
            }
          }),
        },
      };
    }

    case SELECT_CONTAINER_DEPLOYMENT: {
      const { id, checked } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              if (checked) {
                return { ...item, select: true };
              } else {
                delete item["select"];
                return { ...item };
              }
            } else {
              return item;
            }
          }),
        },
      };
    }

    case SELECT_PROCESS_DEPLOYMENT: {
      const { id, checked } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          process: state.dependenciesData.process.map((item) => {
            if (item.templateid === id) {
              if (checked) {
                return { ...item, select: true };
              } else {
                delete item["select"];
                return { ...item };
              }
            } else {
              return item;
            }
          }),
        },
      };
    }

    case EDIT_CONTAINER_DEPLOYMENT: {
      const { value, id, name } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              item[name] = value;
              return { ...item };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case ADD_DEPLOYMENT_PROCESS: {
      state.dependenciesData.process = action.payload;
      return {
        ...state,
      };
    }

    case DELETE_DEPLOYMENT_BY_ID: {
      return {
        ...state,
        deploymentList: {
          ...state.deploymentList,
          data: state.deploymentList.data.map(
            (serv) => {
              if(serv._id === action.payload.DeploymentId){
                serv['Status'] = 'Delete In Progress'
              }
              return serv
            }
          ),
          _totalcount: state.deploymentList._totalcount - 1,
          deleteDeploymentStatus: true,
        },
      };
    }

    case DELETE_DEPLOYMENT_BY_CONTAINER: {
      return {
        ...state,
        deploymentDetailsList: {
          ...state.deploymentDetailsList,
          data: {
            ...state.deploymentDetailsList.data,
            deploymentDetails:
              state.deploymentDetailsList.data.deploymentDetails.filter(
                (serv) => serv.ContainerName !== action.payload.ContainerName
              ),
          },
          _totalcount: state.deploymentDetailsList._totalcount - 1,
        },
      };
    }

    case ADD_VOLUMN_DEPLOYMENT: {
      const { id, volumnData } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              return { ...item, volume: [volumnData] };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case ADD_DEPLOYMENT_RESPONSE: {
      state.deploymentResponse = action.payload;
      return { ...state };
    }

    case ADD_SIZING_DEPLOYMENT: {
      const { id, sizingData } = action.payload;
      return {
        ...state,
        dependenciesData: {
          ...state.dependenciesData,
          container: state.dependenciesData.container.map((item) => {
            if (item.uid === id) {
              return { ...item, resources: sizingData };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case ADD_CLUSTER_LIST_DEPLOYMENT: {
      return {
        ...state,
        hostingData: { ...state.hostingData, clusterList: action.payload },
      };
    }
    case ADD_DEPLOYMENT_NAME: {
      return { ...state, deploymentName: action.payload };
    }

    case ADD_CHART_VALUES: {
      return {
        ...state,
        hostingData: { ...state.hostingData, chart: action.payload },
      };
    }

    case SHOW_CHART: {
      return {
        ...state,
        hostingData: { ...state.hostingData, showChart: true },
      };
    }

    case ADD_CLUSTER_VALIDITY: {
      return {
        ...state,
        hostingData: { ...state.hostingData, clusterValidity: true },
      };
    }
    case REMOVE_CLUSTER_VALIDITY: {
      return {
        ...state,
        hostingData: { ...state.hostingData, clusterValidity: false },
      };
    }

    case ADD_DEPLOYMENT_DETAILS_HIDDEN: {
      return {
        ...state,
        deploymentDetailsHidden: [...action.payload],
      };
    }

    case ADD_NODES_LIST: {
      return {
        ...state,
        nodesUsage: action.payload,
      };
    }

    case ADD_NODES_USAGE_DATA: {
      return {
        ...state,
        nodesUsage: state.nodesUsage?.map((node)=> {
          if(node.Name === action.payload?.Name){
            node = {...node, ...action.payload}
            return node
          } else {
            return node
          }
        }),
      };
    }

    default:
      return state;
  }
};
export default deploymentProcessReducer;
