import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  DialogContent,
  DialogActions,
  Checkbox,
  Paper,
  Button,
  IconButton,
  FilledInput,
  InputAdornment,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import manageStyles from '../../../../screens/CommonStyles/manageScreenStyles'
import { connect } from 'react-redux'
import CreateCustomerStyles from '../../../../screens/CommonStyles/CreateAndDetailsScreenStyle'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers'
import CustomChips from '../../Chips'

const UserCreateEntitySchemaHelper = ({
  section,
  userData,
  error,
  setUserData,
  handleChange,
  rolesList,
  setRolesList,
  setClickedRole,
  clickedRole,
  handleSearch,
  SearchInput,
  setSearchInput,
  tabbedView,
}) => {
  const styles = manageStyles()
  const classes = CreateCustomerStyles()
  const [open, setOpen] = React.useState(false)

  const userRolesField = (field) => {
    const handleClickOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    const handleSelectedRoleDelete = (roleToDelete) => () => {
      const temp_roles = rolesList?.map((item) => {
        if (item._id === roleToDelete._id) {
          return {
            ...item,
            checked: false,
          }
        } else {
          return item
        }
      })
      setRolesList(temp_roles)
    }

    const handleRoleCheck = (role) => {
      const temp_roles = rolesList?.map((item) => {
        if (item._id === role._id) {
          return {
            ...item,
            checked: !item.checked,
          }
        } else {
          return item
        }
      })
      setRolesList(temp_roles)
    }

    return (
      <>
        <div className={classes.box}>
          <div className={classes.addTeamMember}>
            <div>
              <Typography style={{ height: 35 }} className={classes.fieldHeading}>
                Assign Roles <span className={styles.red}>*</span>
              </Typography>
            </div>
            <div
              style={{ marginTop: -18 }}
              className={classes.addTeamModal}
              onClick={handleClickOpen}
            >
              <AddIcon />
              <span>Add</span>
            </div>
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              style={{
                padding: '0 25px',
                backgroundColor: '#F9FAFD',
              }}
            >
              <div className={classes.assignProject}>
                <div>
                  <h5>Select Roles</h5>
                </div>
                <div>
                  <FilledInput
                    name="filterfn"
                    className={classes.input}
                    style={{ width: '100%' }}
                    placeholder="Search"
                    value={SearchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch} onMouseDown={handleSearch}>
                          <img src="/images/search.svg" alt="card" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div style={{ height: 308, width: 500 }}>
                <Grid container>
                  {rolesList &&
                    rolesList.map((role, index) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Paper
                          className={classes.selectMemebrModal}
                          onClick={() => setClickedRole(role)}
                          style={{ width: 500 }}
                        >
                          <div className={classes.teamWrapper2}>
                            <Checkbox
                              color="primary"
                              checked={role.checked}
                              onChange={() => handleRoleCheck(role)}
                              name={role.name}
                            />
                            <div className={classes.memberDeatils2}>
                              <Typography
                                className={classes.teamMemberName}
                                style={{
                                  paddingLeft: '3rem',
                                  font: 'normal normal 600 14px/19px Nunito',
                                }}
                              >
                                {role.name}
                              </Typography>
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </DialogContent>

            <DialogActions
              style={{
                backgroundColor: '#F9FAFD',
                justifyContent: 'space-between',
              }}
            >
              <div></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: 230,
                }}
              >
                <Button onClick={handleClose} className={classes.cancel}>
                  Cancel
                </Button>
                <Button onClick={handleClose} className={classes.submitMember}>
                  Add Roles
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <Grid container spacing={3} style={{ marginTop: 15 }}>
          <Grid item>
            <CustomChips
              chipsData={rolesList.filter((item) => item.checked)}
              handleDelete={handleRoleCheck}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const generateField = (field) => {
    switch (field.uiElementType) {
      case 'Textfield':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <input
              type={field?.fieldLabel === 'Contact Number' ? 'number' : 'text'}
              name={field.datakey}
              className={`${styles.input} ${error[field.datakey]?.isError && styles.error}`}
              value={userData[field.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        )
      case 'TextField':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <input
              type={field?.fieldLabel === 'Contact Number' ? 'number' : 'text'}
              name={field.datakey}
              className={`${styles.input} ${error[field.datakey]?.isError && styles.error}`}
              value={userData[field.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        )
      case 'TextArea':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <textarea
              name={field.datakey}
              value={userData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error} `}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        )
      case 'Dropdown':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <Select
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ''}
              onChange={handleChange}
              className={`${styles.select} ${error[field.datakey]?.isError && styles.error}`}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      case 'Date':
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <DesktopDatePicker
              value={userData[field.datakey] ? userData[field.datakey] : null}
              onChange={(date) => handleChange({ target: { name: field.datakey, value: date } })}
              inputFormat={'dd/MM/yyyy'}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === 'FALSE' && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>{InputProps?.endAdornment}</span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        )
      case 'Currency':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={userData[`${field.datakey}_unit`] ? userData[`${field.datakey}_unit`] : ''}
                onChange={handleChange}
                className={`${styles.select} ${error[field.datakey]?.isError && styles.error}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[field.datakey]?.isError && styles.error} ${
                  field.editable === 'FALSE' && styles.editMode
                }`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </Grid>
          </Grid>
        )
      case 'Amount':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={userData[`${field.datakey}_unit`] ? userData[`${field.datakey}_unit`] : ''}
                onChange={handleChange}
                className={`${styles.select} ${error[field.datakey]?.isError && styles.error}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[field.datakey]?.isError && styles.error} ${
                  field.editable === 'FALSE' && styles.editMode
                }`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </Grid>
          </Grid>
        )
      case 'RadioButton':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}
              {field?.required === 'TRUE' && <span className={styles.red}>*</span>}
            </label>
            <RadioGroup
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ''}
              onChange={handleChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === 'User_Header_section' && index !== 0 && (
                <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>
              )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === 'TextArea').length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: '0rem', display: 'flex' }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case 'Roles':
                          return (
                            <Grid item xs={12} sm={12} key={field._id}>
                              {userRolesField(field)}
                            </Grid>
                          )
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          )
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid container spacing={3} style={{ marginTop: '0rem' }} key={fieldgroup._id}>
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== 'TextArea')
                          .map((field) => {
                            switch (field.name) {
                              case 'User_Roles':
                                return userRolesField(field)
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    <label htmlFor="" className={styles.fieldHeading}>
                                      {field?.fieldLabel}
                                      {field?.required === 'TRUE' && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      name={field.datakey}
                                      className={`${styles.input} ${
                                        field.editable === 'FALSE' && styles.editMode
                                      }`}
                                      value={userData[field.datakey]}
                                      autoComplete="off"
                                      disabled={field.editable === 'FALSE'}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                )
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === 'TextArea')
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label htmlFor="" className={styles.fieldHeading}>
                                  {field?.fieldLabel}
                                  {field?.required === 'TRUE' && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={userData[field.datakey]}
                                  // onChange={handleChange}
                                  className={`${styles.textarea} ${
                                    field.editable === 'FALSE' && styles.editMode
                                  }`}
                                  disabled={field.editable === 'FALSE'}
                                  rows="7"
                                ></textarea>
                              </Grid>
                            )
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          )
        })}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo
  return { tabbedView }
}
export default connect(mapStateToProps)(UserCreateEntitySchemaHelper)
