import React from "react";
import { Paper, Tabs, Tab, IconButton, Tooltip } from "@material-ui/core";
import Editor from "../../common/Editor";
import inputStyles from "./InputStyles";
import InputTable from "../../common/Tables/InputTable";
import OutputTree from "./OutputTree";

export default function CallNodeInput(props) {
  const {
    activetab,
    activeInputType,
    inputList,
    headerList,
    changeActivetab,
    changeActiveInputType,
    setHeaderList,
    setInputList,
    editorValue,
    outputFieldGroupDetails
  } = props;
  const classes = inputStyles();

  const handleTabChange = (event, val) => {
    changeActivetab(val);
  };

  const handleInputTypeChange = (val) => {
    changeActiveInputType(val);
  };

  return (
    <div className={classes.container}>
      <div className={classes.tab_container}>
        <Paper className={classes.root}>
          <Tabs
            value={activetab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          // centered
          >
            <Tab label="Header" value="header" />
            <Tab label="Body" value="body" />
            <Tab label="Output" value="output" />
          </Tabs>
        </Paper>
        {activetab === "body" && (
          <div className={classes.iconContainer}>
            <Tooltip title="Table Mode">
              <IconButton
                color="inherit"
                aria-label="textfield"
                edge="start"
                className={`${activeInputType === "text" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons}`}
                onClick={() => handleInputTypeChange("text")}
              >
                <img src="/images/orchestration/lineicon.svg" alt="text" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editor Mode">
              <IconButton
                color="inherit"
                aria-label="editor"
                edge="start"
                className={`${activeInputType === "editor" ? "toggleActive" : "toggleInactive"
                  } ${classes.toggleicons}`}
                onClick={() => handleInputTypeChange("editor")}
              >
                <img src="/images/orchestration/editoricon.svg" alt="editor" />
              </IconButton>
            </Tooltip>

          </div>
        )}
      </div>
      <div className={classes.line5}>
        {/* <p className={classes.label}>Input</p> */}
        {activetab === "body" && activeInputType === "editor" && (
          <div className={classes.outputbody}>
            <Editor
              onChange={(val) => props.onEditorValueChange(val)}
              value={editorValue}
            />
          </div>
        )}
        {activetab === "body" && activeInputType === "text" && (
          <InputTable envs={inputList} setEnvs={setInputList} />
        )}
        {activetab === "header" && (
          <InputTable envs={headerList} setEnvs={setHeaderList} />
        )}
        {activetab === "output" && (
          <div className={classes.output}>
            <OutputTree outputFieldGroupDetails={outputFieldGroupDetails} />
          </div>
        )}
      </div>
    </div>
  );
}
