import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  label: {
    font: "normal normal 600 14px/19px Nunito",
  },
  status: {
    marginTop: 5,
    border: "1px solid #DFDFDF",
    borderRadius: 5,
    "& .MuiInputBase-root": {
      width: "100%",
      height: 38,
    },
  },
  statusText: {
    font: "normal normal 600 14px/19px Nunito",
  },
  menuItem: {
    width: 170,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  colorBox: {
    height: 20,
    width: 20,
    border: "0.5px solid #A8A8A873",
    borderRadius: 2,
  },
}));
