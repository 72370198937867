import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import InputTable from "../../Tables/InputTable";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import FieldGroupTree from "../../FieldGroupTree";

const CustomFieldGroupsForApiCreate = ({
  section,
  apiData,
  setApiData,
  headers,
  setHeaders,
  error,
  handleChange,
  userInfo,
  tab,
  handleTabChange,
  fieldGroups,
  fieldGroupDetailsForInput,
  deletePathParameter,
  deleteQueryParameter,
  fieldGroupDetailsForOutput,
  serviceType,
}) => {
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const [payloadOpen, setPayloadOpen] = useState(false);
  const [payload2Open, setPayload2Open] = useState(false);

  const closeDropDown = () => {
    setPayloadOpen(false);
    setPayload2Open(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const styles = manageStyles();
  const parameterSection = () => {
    return (
      <div style={{ height: "25rem", width: "100%" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className={styles.apiTab}
          style={{ bottom: 15 }}
        >
          <Tab label="Input" />
          <Tab label="Output" />
          <Tab label="Headers" />
          <Tab label="Query Parameters" />
          <Tab label="Path Parameters" />
        </Tabs>
        {tab === 0 && (
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Input
              </label>
              <Autocomplete
                className={styles.searchInput3}
                open={payloadOpen}
                onOpen={() => setPayloadOpen(true)}
                onClose={() => setPayloadOpen(false)}
                getOptionLabel={(option) => option.name}
                options={fieldGroups.filter(
                  (fieldGroup) => fieldGroup?.status === "active"
                )}
                value={
                  fieldGroupDetailsForInput ? fieldGroupDetailsForInput : {}
                }
                onChange={(e, input) =>
                  setApiData({ ...apiData, input: input ? input?._id : "" })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Search Payload"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FieldGroupTree data={fieldGroupDetailsForInput} />
            </Grid>
          </Grid>
        )}
        {tab === 1 && (
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Output
              </label>
              <Autocomplete
                open={payload2Open}
                onOpen={() => setPayload2Open(true)}
                onClose={() => setPayload2Open(false)}
                options={fieldGroups}
                getOptionLabel={(option) => option.name}
                value={
                  fieldGroupDetailsForOutput ? fieldGroupDetailsForOutput : {}
                }
                className={`${styles.searchInput3}`}
                onChange={(e, output) => {
                  if (output) {
                    setApiData({ ...apiData, output: output._id });
                  } else {
                    setApiData({ ...apiData, output: "" });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Search Payload"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FieldGroupTree data={fieldGroupDetailsForOutput} />
            </Grid>
          </Grid>
        )}
        {tab === 2 && (
          <InputTable envs={headers} setEnvs={(env) => setHeaders(env)} />
        )}

        {tab === 3 && (
          <div>
            <Grid container spacing={1} style={{ marginTop: "0rem" }}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Query Parameters
                </label>
                <IconButton
                  color="inherit"
                  aria-label="edit"
                  edge="start"
                  onClick={() =>
                    setApiData({
                      ...apiData,
                      queryParameters: [...apiData.queryParameters, ""],
                    })
                  }
                  className={styles.actionIcon2}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              {apiData.queryParameters.map((query, index) => (
                <Grid item xs={4} sm={4} key={index}>
                  <Grid
                    key={index}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item xs={10} sm={10}>
                      <input
                        type="text"
                        name={`${index}_query`}
                        className={styles.input}
                        value={apiData.queryParameters[index]}
                        onChange={(e) =>
                          setApiData({
                            ...apiData,
                            query: [
                              ...apiData.queryParameters,
                              (apiData.queryParameters[index] = e.target.value),
                            ],
                          })
                        }
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() => deleteQueryParameter(index)}
                        className={styles.actionIcon2}
                        style={{ marginLeft: -5 }}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        {tab === 4 && (
          <div>
            <Grid container spacing={1} style={{ marginTop: "0rem" }}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Path Parameters
                </label>
                <IconButton
                  color="inherit"
                  aria-label="edit"
                  edge="start"
                  onClick={() =>
                    setApiData({
                      ...apiData,
                      pathParameters: [...apiData.pathParameters, ""],
                    })
                  }
                  className={styles.actionIcon2}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              {apiData.pathParameters.map((path, index) => (
                <Grid item xs={4} sm={4} key={index}>
                  <Grid
                    key={index}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item xs={10} sm={10}>
                      <input
                        type="text"
                        name={`${index}_path`}
                        className={styles.input}
                        value={apiData.pathParameters[index]}
                        onChange={(e) =>
                          setApiData({
                            ...apiData,
                            pathParam: [
                              ...apiData.pathParameters,
                              (apiData.pathParameters[index] = e.target.value),
                            ],
                          })
                        }
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() => deletePathParameter(index)}
                        className={styles.actionIcon2}
                        style={{ marginLeft: -5 }}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "API Path") {
          return (
            <>
              {apiData.type === "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else if (field.name === "Topic") {
          return (
            <>
              {apiData.type !== "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else if (field.name === "broker_address") {
          return (
            <>
              {apiData.type !== "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else if (field.name === "sys_broker") {
          return (
            <>
              {apiData.type !== "rest" && serviceType === "External" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type={
                  field.fieldLabel === "Password" ||
                  field.fieldLabel === "Partition"
                    ? field.fieldLabel === "Password"
                      ? "password"
                      : "number"
                    : "text"
                }
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  field.dataType === "date" && apiData[field.datakey]
                    ? moment
                        .unix(apiData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : apiData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={apiData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        if (field.name === "API Type") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {JSON.parse(field?.values)?.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else if (field.name === "API Method") {
          return (
            <>
              {apiData.type === "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <Select
                    name={field.datakey}
                    value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                    onChange={handleChange}
                    className={`${styles.select} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {JSON.parse(field?.values)?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else if (field.name === "broker_method") {
          return (
            <>
              {apiData.type !== "rest" ? (
                <>
                <label htmlFor="" className={styles.fieldHeading}>
                  {field?.fieldLabel}{" "}
                  {field?.required === "TRUE" && (
                    <span className={styles.red}>*</span>
                  )}
                </label>
                <Select
                  name={field.datakey}
                  value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                  onChange={handleChange}
                  className={`${styles.select} ${
                    error[field.datakey]?.isError && styles.error
                  }`}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {typeof field?.values === "string"
                    ? JSON.parse(field?.values)?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))
                    : field?.values?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))}
                </Select>
                {error[field.datakey]?.isError && (
                  <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                )}
              </>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values)?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={apiData[field.datakey] ? apiData[field.datakey] : null}
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  apiData[`${field.datakey}_unit`]
                    ? apiData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values)?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                }`}
                value={apiData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={apiData[field.datakey] ? apiData[field.datakey] : ""}
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values)?.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values?.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div style={{ width: "100%" }} key={fieldgroup._id}>
              {section.name === "API_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Parameters":
                          if (apiData?.type === "rest") {
                            return parameterSection();
                          }

                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Parameters":
                                return parameterSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForApiCreate);
