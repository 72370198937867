import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  options: {
    // marginRight: 280,

    // "& .MuiPopover-paper": {
    //     left: '770px !important'
    // },

    "& .optionsWrap": {
      width: 230,
      "& .header": {
        padding: 5,
        paddingLeft: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #F0F0F0",
        width: '100%',
        marginTop: 0,

        "& h3": {
          margin: 0,
          font: "normal normal 600 16px/16px Nunito",
        },

        "& .icons": {
          display: 'flex',

          "& button": {
            width: 35
          }
        },
      },
      "& .body": {
        padding: 10,

        "& label": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#333333DE",
          textTransform: "capitalize",
        },

        "& input": {
          marginBottom: 10,
        },
      },
    },
  },
  autocomplete: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },

    "& input": {
      marginBottom: "0 !important",
    },

    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "4px !important",
      paddingBottom: "4px !important",

      "& .MuiAutocomplete-tag": {
        height: 30,
        background: "#E4EFFC 0% 0% no-repeat padding-box",
        color: "#002855",
        font: "normal normal bold 13px/18px Nunito",

        "& svg": {
          color: theme.palette.primary.main,
        },
      },

      "& fieldset": {
        borderWidth: 0,
      },
    },
  },
  autocomplete2: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    marginTop: "5px",
    marginBottom: 5,
    padding: 3,

    "& .MuiInput-underline": {
      padding: "0",
      "&::before": {
        borderBottom: "unset !important",
      },
    },

    "& input": {
      marginBottom: "0 !important",
    },

    "& .MuiAutocomplete-inputRoot": {

      "& input": {
        border: "unset !important",
        padding: "7px 10px !important",
        marginTop: "unset !important",
        fontSize: 14
      },

      "& .MuiAutocomplete-tag": {
        height: 30,
        background: "#E4EFFC 0% 0% no-repeat padding-box",
        color: "#002855",
        font: "normal normal bold 13px/18px Nunito",

        "& svg": {
          color: theme.palette.primary.main,
        },
      },

      "& fieldset": {
        borderWidth: 0,
      },
    },
  },
}));
