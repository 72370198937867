import {
    ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT, ADD_ALL_SERVICE_GROUP_LIST_DATA, DELETE_SERVICE_GROUP_BY_ID,
    NEW_SERVICEGROUP_DATA, SERVICEGROUP_SORT, CLEAR_SERVICEGROUP_DATA, SERVICEGROUP_HIDDEN,
    DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID, ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA
} from '../actionTypes'

import { CommonServices } from '../../services/commonServices'
import { SERVICEGROUPFILTERS } from "../actionTypes"
const commonServices = new CommonServices();

const add_ser_group_data_by_project = (payload) => {
    return {
        type: ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT,
        payload: payload,
    };
}

const add_all_ser_group_data = (payload) => {
    return {
        type: ADD_ALL_SERVICE_GROUP_LIST_DATA,
        payload: payload,
    };
}

export const getServiceGroupsListByProject = (projectId, currentPage, filter, sort) => async (dispatch) => {
    try {
        dispatch(add_ser_group_data_by_project({
            loading: true
        }))
        const { data } = await commonServices.getServiceGroupListfromProjectID(projectId, currentPage, filter, sort);
        if (data) {
            dispatch(
                add_ser_group_data_by_project({
                    loading: false,
                    data: data,
                    error: false,
                    _totalcount: data._totalcount
                })
            );
        } else {
            dispatch(
                add_ser_group_data_by_project({
                    loading: false,
                    data: [],
                    error: false,
                })
            );
        }
    } catch (error) {
        dispatch(
            add_ser_group_data_by_project({
                loading: false,
                data: [],
                error: true,
            })
        );
    }
}



export const getAllServiceGroupsList = (currentPage, filter,sort) => async (dispatch) => {
    try {
        dispatch(add_all_ser_group_data({
            loading: true
        }))
        const { data } = await commonServices.getServiceGroupList(currentPage, filter, sort);
        if (data) {
            dispatch(
                add_all_ser_group_data({
                    loading: false,
                    data: data,
                    error: false,
                    _totalcount: data._totalcount
                })
            );
        } else {
            dispatch(
                add_all_ser_group_data({
                    loading: false,
                    data: [],
                    error: false,
                })
            );
        }
    } catch (error) {
        dispatch(
            add_all_ser_group_data({
                loading: false,
                data: [],
                error: true,
            })
        );
    }
}

export const addMicroserviceInServicegroupListData = (microserviceListData) => {
    return {
        type: ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA,
        payload: microserviceListData
    }
};

export const deleteMicroserviceInServiceGroupById = (serviceId) => {
    return {
        type: DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID,
        payload: {
            serviceId
        }
    }
};

export const addNewServiceGroupData = (servicegroupData) => {
    return {
        type: NEW_SERVICEGROUP_DATA,
        payload: servicegroupData
    }
};

export const deleteServiceGroupById = (groupId) => {
    return {
        type: DELETE_SERVICE_GROUP_BY_ID,
        payload: {
            groupId
        }
    }
};


export const addServiceGroupSort = (serviceGroupSort) => {
    return {
        type: SERVICEGROUP_SORT,
        payload: serviceGroupSort
    }
};


export const addServiceGroupHidden = (serviceGroupHidden) => {
    return {
        type: SERVICEGROUP_HIDDEN,
        payload: serviceGroupHidden
    }
};

export const addserviceGroupFilters = (serviceGroupFilters) => {
    return {
        type: SERVICEGROUPFILTERS,
        payload: serviceGroupFilters
    }
};

export const serviceGroupClearData = () => {
    return {
        type: CLEAR_SERVICEGROUP_DATA
    }
}
