import React, { useEffect, useState } from 'react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import styles from './styles';
import { useDispatch } from 'react-redux';
import { editComponent } from '../../../../../../../redux/actions/uiApplicationAction';
import { v4 as uuid } from 'uuid';
import Input from '../../../../input/Input';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionActions,
  Grid,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import useGetData from '../../../../../../hooks/useGetData';

export const SelectBuilder = ({ selectedColumn }) => {
  const classes = styles();
  const { currentPage, currentComponent } = useGetData()
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [optionsArray, setOptionsArray] = useState([]);
  const [customArray, setCustomArray] = useState([]);

  const handleAddColumn = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent?.id) {
        return {
          ...component,
          columns: component.columns.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                options: [
                  ...col.options,
                  {
                    id: uuid(),
                    title: `option${col.options.length + 1}`,
                    // name:
                    //   selectedColumn?.type === 'multiselect'
                    //     ? [`value${col.options.length + 1}`]
                    //     : `value${col.options.length + 1}`,
                    name: `value${col.options.length + 1}`,
                    type: selectedColumn?.type,
                  },
                ],
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleChangePanel =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (event, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          columns: component.columns.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                options: col.options.map((v) => {
                  if (v?.id === value?.id) {
                    return {
                      ...v,
                      [event.target.name]: event.target.value,
                    };
                  } else {
                    return v;
                  }
                }),
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handeDelete = (e, collProp) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          columns: component.columns.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                options: col.options.filter((c) => c.id !== collProp.id),
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  useEffect(() => {
    let filteredArr1 = selectedColumn?.options?.filter(
      (filtered) => filtered.type === 'select'
    );
    let filteredArr2 = selectedColumn?.options?.filter(
      (filtered) => filtered.type === 'multiselect'
    );

    switch (selectedColumn?.type) {
      case 'select':
        setOptionsArray(filteredArr1);
        break;
      case 'multiselect':
        setOptionsArray(filteredArr2);
        break;
      default:
        break;
    }
  }, [selectedColumn?.type, selectedColumn]);

  useEffect(() => {
    // console.log(customArray.length, 'customeArray');
    // if (customArray.length) {
    //   const components = currentPage.components.map((component) => {
    //     if (component.id === currentComponent.id) {
    //       return {
    //         ...component,
    //         columns: component.columns.map((col) => {
    //           if (col?.id === selectedColumn?.id) {
    //             return {
    //               ...col,
    //               options: [...col.options, ...customArray],
    //             };
    //           } else {
    //             return col;
    //           }
    //         }),
    //       };
    //     } else {
    //       return component;
    //     }
    //   });
    //   dispatch(
    //     editComponent({
    //      pageid: currentPage.id,,
    //       data: components,
    //     })
    //   );
    // }
  }, [customArray]);
  return (
    <div>
      <label>Options</label>
      <div>
        {optionsArray.map((col) => (
          <Accordion
            expanded={expanded === col?.id}
            onChange={handleChangePanel(col?.id)}
            elevation={1}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              //   expandIcon={
              //     !expanded == col?.title && (
              //       <ChevronRightIcon
              //         style={{ marginBottom: 5 }}
              //         fontSize="small"
              //       />
              //     )
              //   }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{ root: classes.summaryRoot }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    sx={{ width: '100%', flexShrink: 0, fontSize: 12 }}
                  >
                    {col?.title}
                  </Typography>
                </Grid>
                <Grid item >
                  {/* <DeleteIcon
                    fontSize="small"
                    style={{ height: 18 }}
                    onClick={(e) => handeDelete(e, col)}
                  /> */}
                  <img src="/img/delete-bin-7-line.svg" alt="Delete" style={{ height: 18 }} onClick={(e) => handeDelete(e, col)} />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Input
                name={'title'}
                label={'Title'}
                placeholder=""
                value={col?.title}
                onChange={(e) => handleChange(e, col)}
              />
              <Input
                name={'name'}
                label={'Value'}
                placeholder=""
                value={col?.name}
                onChange={(e) => handleChange(e, col)}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div
        className={classes.tableColumn}
        style={{
          justifyContent: 'center',
          padding: 5,
          cursor: 'pointer',
        }}
        onClick={handleAddColumn}
      >
        <AddOutlinedIcon />
      </div>
    </div>
  );
};
