import { MicroServicesAPIServices } from "../../services/microservices";

const microservice = new MicroServicesAPIServices();

export const fetchServiceById = async (serviceId) => {
  try {
    const res_data = await microservice.getServiceById(serviceId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchFilteredServiceList = async () => {
  try {
    const res_data = await microservice.getFilteredServicesList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAllServices = async () => {
  try {
    const res_data = await microservice.getAllServices();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAllUiServices = async () => {
  try {
    const res_data = await microservice.fetchAllUiServices();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAiTaskById = async (serviceId) => {
  try {
    const res_data = await microservice.getServiceById(serviceId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addAiTask = async (serviceData) => {
  try {
    const res_data = await microservice.addService(serviceData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addMicroService = async (serviceData) => {
  try {
    const res_data = await microservice.addService(serviceData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editMicroService = async (serviceId, serviceData) => {
  try {
    const res_data = await microservice.editService(serviceId, serviceData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteMicroService = async (serviceId) => {
  try {
    const res_data = await microservice.deleteService(serviceId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchVersionListData = async (serviceId, filter) => {
  try {
    const res_data = await microservice.getAllVersions(
      serviceId,
      0,
      filter,
      JSON.stringify({ createdon: -1 })
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchVersionById = async (serviceId, versionId) => {
  try {
    const res_data = await microservice.getVersionById(serviceId, versionId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addVersion = async (serviceId, versionData) => {
  try {
    const res_data = await microservice.addVersion(serviceId, versionData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editVersion = async (serviceId, versionId, versionData) => {
  try {
    const res_data = await microservice.editVersion(
      serviceId,
      versionId,
      versionData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editEnvironmentForVersion = async (serviceId, versionId, envs) => {
  try {
    const res_data = await microservice.editEnvironmentForVersion(
      serviceId,
      versionId,
      envs
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editBuildCommands = async (
  serviceId,
  versionId,
  buildCommands
) => {
  try {
    const res_data = await microservice.editBuildCommands(
      serviceId,
      versionId,
      buildCommands
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editRunCommands = async (serviceId, versionId, versionData) => {
  try {
    const res_data = await microservice.editRunCommands(
      serviceId,
      versionId,
      versionData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteVersion = async (serviceId, versionId) => {
  try {
    const res_data = await microservice.deleteVersion(serviceId, versionId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAPIListData = async (serviceId, versionId) => {
  try {
    const res_data = await microservice.getAllAPIs(serviceId, versionId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAPIById = async (serviceId, versionId, apiId) => {
  try {
    const res_data = await microservice.getAPIById(serviceId, versionId, apiId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addAPI = async (serviceId, versionId, apiData) => {
  try {
    const res_data = await microservice.addAPI(serviceId, versionId, apiData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editAPI = async (serviceId, versionId, apiId, apiData) => {
  try {
    const res_data = await microservice.editAPI(
      serviceId,
      versionId,
      apiId,
      apiData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteAPI = async (serviceId, versionId, apiId) => {
  try {
    const res_data = await microservice.deleteAPI(serviceId, versionId, apiId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchAllBoilerPlates = async (serviceId) => {
  try {
    const res_data = await microservice.getAllBoilerPlates(serviceId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status } = err.response?.data
      ? err.response.data
      : { _msg: "Error Occured", _status: 404 };
    // const { _msg, _status } = err.response.data;
    return {
      _msg,
      _status,
    };
  }
};

export const fetchAllRuntimeEnvironments = async () => {
  try {
    const res_data = await microservice.getAllRuntimeEnvironment();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addRuntimeEnvironment = async (
  serviceId,
  versionId,
  environmentData
) => {
  try {
    const res_data = await microservice.addRuntimeEnvironment(
      serviceId,
      versionId,
      environmentData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getRepositoryDetailsByMicroServiceVersion = async (
  serviceId,
  versionId
) => {
  try {
    const res_data =
      await microservice.getRepositoryDetailsByMicroServiceVersion(
        serviceId,
        versionId
      );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const uploadLocalSourceCode = async (souceCodeData) => {
  try {
    const res_data = await microservice.uploadLocalSourceCode(souceCodeData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const reUploadLocalSourceCode = async (repoId, souceCodeData) => {
  try {
    const res_data = await microservice.reUploadLocalSourceCode(
      repoId,
      souceCodeData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const uploadSourceCodeByGit = async (souceCodeData) => {
  try {
    const res_data = await microservice.uploadSourceCodeByGit(souceCodeData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const reUploadSourceCodeByGit = async (repoId, souceCodeData) => {
  try {
    const res_data = await microservice.reUploadSourceCodeByGit(
      repoId,
      souceCodeData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createDockerFile = async (serviceId, versionId) => {
  try {
    const res_data = await microservice.createDockerFile(serviceId, versionId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const runContainer = async (serviceId, versionId, containerData) => {
  try {
    const res_data = await microservice.runContainer(
      serviceId,
      versionId,
      containerData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const simutateAPI = async (simulateData) => {
  try {
    const res_data = await microservice.simulateAPI(simulateData);
    const { _status, _msg, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const copyService = async (serviceData) => {
  try {
    const res_data = await microservice.copyService(serviceData);
    const { _status, _msg, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const simulateAPIForUI = async (simulateData) => {
  try {
    const res_data = await microservice.simulateAPIForUI(simulateData);
    const { _status, _msg, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
