import {
  //
  ADD_PROJECT_DOCUMENTS_DATA,
  PROJECT_DOCUMENTS_FILTERS,
  PROJECT_DOCUMENTS_SORT,
  DELETE_PROJECT_DOCUMENTS_BY_DOCUMENTS_ID,

  //
  ADD_SERVICE_GROUPS_DOCUMENTS_DATA,
  SERVICE_GROUPS_DOCUMENTS_FILTERS,
  SERVICE_GROUP_DOCUMENTS_SORT,
  DELETE_SERVICE_GROUP_DOCUMENTS_BY_DOCUMENTS_ID,
  //
  ADD_SERVICE_DOCUMENTS_DATA,
  SERVICE_DOCUMENTS_FILTERS,
  SERVICE_DOCUMENTS_SORT,
  DELETE_SERVICE_DOCUMENTS_BY_DOCUMENTS_ID,
} from "../actionTypes";

import { DocumentServices } from "../../services/documentServices";

const documentService = new DocumentServices();

function add_projDdocument_list_data(payload) {
  return {
    type: ADD_PROJECT_DOCUMENTS_DATA,
    payload: payload,
  };
}

//projectaction
export function add_projDocument_Data(currentPage, filters, sort) {
  return function (dispatch) {
    dispatch(
      add_projDdocument_list_data({
        loading: true,
      })
    );
    documentService
      .getAllDocuments(currentPage, filters, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_projDdocument_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_projDdocument_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_projDdocument_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_project_documents_filter = (projectDocumentFilter) => {
  return {
    type: PROJECT_DOCUMENTS_FILTERS,
    payload: projectDocumentFilter,
  };
};

export const add_project_documents_sort = (projectDocumentSort) => {
  return {
    type: PROJECT_DOCUMENTS_SORT,
    payload: projectDocumentSort,
  };
};
export const delete_single_project_documents_by_id = (projectDocumentsId) => {
  return {
    type: DELETE_PROJECT_DOCUMENTS_BY_DOCUMENTS_ID,
    payload: {
      projectDocumentsId,
    },
  };
};
//servicegroupaction

function add_service_group_documents_data(payload) {
  return {
    type: ADD_SERVICE_GROUPS_DOCUMENTS_DATA,
    payload: payload,
  };
}

export function add_serviceGroup_documents_data(currentPage, filters, sort) {
  return function (dispatch) {
    dispatch(
      add_service_group_documents_data({
        loading: true,
      })
    );
    documentService
      .getAllDocuments(currentPage, filters, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_service_group_documents_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_service_group_documents_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_service_group_documents_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}
export const add_service_group_documents_filter = (
  serviceGroupsDocumentsFilters
) => {
  return {
    type: SERVICE_GROUPS_DOCUMENTS_FILTERS,
    payload: serviceGroupsDocumentsFilters,
  };
};

export const service_group_document_sort = (serviceGroupDocumentSort) => {
  return {
    type: SERVICE_GROUP_DOCUMENTS_SORT,
    payload: serviceGroupDocumentSort,
  };
};

export const delete_single_service_group_documents_by_id = (
  serviceGroupDocumentsId
) => {
  return {
    type: DELETE_SERVICE_GROUP_DOCUMENTS_BY_DOCUMENTS_ID,
    payload: {
      serviceGroupDocumentsId,
    },
  };
};

// servicesection

function add_service_documents_data(payload) {
  return {
    type: ADD_SERVICE_DOCUMENTS_DATA,
    payload: payload,
  };
}

export function add_service_document_data(currentPage, filters, sort) {
  return function (dispatch) {
    dispatch(
      add_service_documents_data({
        loading: true,
      })
    );
    documentService
      .getAllDocuments(currentPage, filters, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_service_documents_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_service_documents_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_service_documents_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_service_documents_filter = (serviceDocumentsFilters) => {
  return {
    type: SERVICE_DOCUMENTS_FILTERS,
    payload: serviceDocumentsFilters,
  };
};

export const add_service_documents_sort = (serviceDocumentsSort) => {
  return {
    type: SERVICE_DOCUMENTS_SORT,
    payload: serviceDocumentsSort,
  };
};

export const delete_single_service_documents_by_id = (serviceDocumentsId) => {
  return {
    type: DELETE_SERVICE_DOCUMENTS_BY_DOCUMENTS_ID,
    payload: {
      serviceDocumentsId,
    },
  };
};
