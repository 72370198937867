import React, { useState } from "react";
import QueryParams from "../../../atoms/rightSide/QueryParams/QueryParams";
import UrlGroupButton from "../../../atoms/rightSide/appearance/urlGroupButtons/UrlGroupButtons";
import useGetData from "../../../../hooks/useGetData";
import {
  addLinkedUiApplicationId,
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { MenuItem, Select } from "@material-ui/core";
function ButtonSettings() {
  const {
    currentProject,
    currentPage,
    currentComponent,
    globalAcessModeChange,
  } = useGetData();
  const dispatch = useDispatch();
  const { globalHandleChange } = useGetData();
  const [error, setError] = useState("");
  const [accessModeErr, setAccessModeErr] = useState("");
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const handleChange = (name, value) => {
    const components = currentPage?.components.map((component) => {
      if (component.id === currentComponent?.id) {
        return {
          ...component,
          [name]: value ? value : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
    if (name === "uiApplication") {
      dispatch(addLinkedUiApplicationId(currentComponent?._id, value?.id));
    }
  };
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <AccessDataInput
          label="Text"
          name="btnText"
          value={currentComponent?.btnText}
          onChange={(value) =>
            globalAcessModeChange(value, "btnText", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <div className="field">
        <UrlGroupButton
          label={"URL"}
          fstIconUrl={
            currentComponent?.urlType === "page"
              ? "/img/Page.svg"
              : "/img/Page_Dark.svg"
          }
          fstAltTxt="Pages"
          sndIconUrl={
            currentComponent?.urlType === "page"
              ? "/img/Link_Dark.svg"
              : "/img/Link_Light.svg"
          }
          sndAltTxt="General"
          currentComponent={currentComponent}
          currentProject={currentProject}
          handleChange={(name, value) => handleChange(name, value)}
        />
      </div>
      <div className="field" style={{ marginBottom: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className="" style={{ margin: 0, marginBottom: 5 }}>
            <label>Query Params</label>
          </p>
          <p
            className="inputmode"
            style={{
              margin: 0,
              marginBottom: 5,
              color: "#AFAFAF",
              cursor: "pointer",
            }}
            // onClick={() =>
            //   handleChange(
            //     "queryParamsJSMode",
            //     !currentComponent?.queryParamsJSMode
            //   )
            // }
          >
            JS
          </p>
        </div>
      </div>
      {!currentComponent?.queryParamsJSMode ? (
        <QueryParams
          currentComponent={currentComponent}
          handleChange={handleChange}
        />
      ) : (
        <div className="field">
          <JSDataInput
            value={currentComponent.jsQueryParams}
            onChange={(value) =>
              globalHandleChange(value, "jsQueryParams", setError)
            }
            name={"jsQueryParams"}
            title={`${currentComponent.name} : Query Params`}
            error={error}
          />
        </div>
      )}
    </div>
  );
}

export default ButtonSettings;
