const chassisTemplateColumns = [
  {
    name: "ChassisTemplate",
    title: "Chassis Template Name",
    route: "/chassistemplate-details",
    for: "chassistemplate",
  },
  { name: "language", title: "Language", for: "chassistemplate" },
  { name: "createdby", title: "Created By", for: "chassistemplate" },
  { name: "createdon", title: "Created On", for: "chassistemplate" },
  { name: "chassisactions", title: "Action", route: "/edit-chassistemplate" },
];

const chassisTemplateDefaultColumnWidths = [
  { columnName: "ChassisTemplate", weightage: 2, width: 200 },
  { columnName: "language", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 1, width: 100 },
  { columnName: "createdon", weightage: 1, width: 100 },
  { columnName: "chassisactions", weightage: 2, width: 200 },
];

const chassisTemplateTableColumnExtensions = [
  { columnName: "ChassisTemplate", align: "left" },
  { columnName: "language", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "chassisactions", align: "center", sortingEnabled: false },
];

const chassisTemplateColumnOrder = [
  "ChassisTemplate",
  "language",
  "createdby",
  "createdon",
];

const chassisTemplateColumnHidden = [
  "ChassisTemplate",
  "language",
  "createdby",
  "createdon",
  "chassisactions",
];

const chassisTemplateDefaultSorting = [];

export {
  chassisTemplateColumns,
  chassisTemplateDefaultColumnWidths,
  chassisTemplateTableColumnExtensions,
  chassisTemplateColumnOrder,
  chassisTemplateDefaultSorting,
  chassisTemplateColumnHidden,
};
