import { ApiHelper } from "./helper/ApiHelper";
import { CONTAINER_MNGT } from "./helper/config";

const apiHelper = new ApiHelper();
export class dockerImageServices {
  getdockerImageList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== "{}"
        ? `&filters=${filter}`
        : `&filters={"buildtype" : {"$ne": "draft"}}`) +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");

    const uri = `${CONTAINER_MNGT}/api/container/dockerimages?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getdockerImageDetailsByID(imgID) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/${imgID}`;
    return apiHelper.get(uri);
  }
  createdockerImage(dockerImageData) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/file`;
    return apiHelper.postFormData(uri, dockerImageData);
  }
  editdockerImageByID(imgID, EditdockerImageData) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/${imgID}`;
    return apiHelper.put(uri, EditdockerImageData);
  }
  DeleteDockerImage(imgID) {
    const uri = `${CONTAINER_MNGT}/api/v1.0.0/dockerimages/${imgID}`;
    return apiHelper.delete(uri);
  }
  getDockerImageBuildStatus(serviceId, versionId) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages?filters={"imagename":"${serviceId}", "imagetag": "${versionId}"}`;
    return apiHelper.get(uri);
  }

  uploadLargeDockerImage(dockerImageMeta) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/largefile`;
    return apiHelper.post(uri, dockerImageMeta);
  }

  startDockerFileUpload(id, filename) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/${id}/file/${filename}/start`;
    return apiHelper.get(uri);
  }
  uploadChunkforDockerImage(chunkFormData) {
    const uri = `${CONTAINER_MNGT}/api/container/dockerimages/largefile/chunks`;
    return apiHelper.post(uri, chunkFormData);
  }
  getDockerImageById(id) {
    const url = `${CONTAINER_MNGT}/api/v1.0.0/dockerimage/${id}/entities`
    return apiHelper.get(url);

  }
}
