import { ADD_API_LIST_DATA, ADD_SERVICE_GUI_LIST_DATA, ADD_SERVICE_LIST_DATA, ADD_VERSION_LIST_DATA,
    ALTER_FAVOURITE_SERVICE_BY_ID, ALTER_SUBSCRIBE_SERVICE_BY_ID, API_HIDDEN, 
    API_SORT, CLEAR_API, CLEAR_SERVICES, CLEAR_VERSION, CLOSE_API, CLOSE_VERSION, DELETE_API_BY_ID, DELETE_SERVICE_BY_ID, DELETE_VERSION_BY_ID, 
    EDIT_VERSION,SET_SELECTED_MICROSERVICEID,
    SELECT_API_CONTAINER, SERVICE_FILTERS, SERVICE_HIDDEN, SERVICE_SORT, SET_SELECTED_APIID, SET_SELECTED_VERSIONID, VERSION_HIDDEN, VERSION_LOADING, VERSION_SORT, EDIT_API,TABBED_ACTIVE_TAB } from '../actionTypes'
import { CommonServices } from '../../services/commonServices'
import { MicroServicesAPIServices } from '../../services/microservices';
import { type } from '../../constants/DataStandard/ModalConstants';

const commonServices = new CommonServices();
const microserviceAPIServices = new MicroServicesAPIServices();

const add_ser_data = (payload) => {
   return {
       type: ADD_SERVICE_LIST_DATA,
       payload: payload,
   };
}
const add_ser_gui_data = (payload) => {
   return {
       type: ADD_SERVICE_GUI_LIST_DATA,
       payload: payload,
   };
}

export const tabbedActiveTabAction = (payload)=>{
    return {
        type:"TABBED_ACTIVE_TAB",
        payload:payload

    }
}


const add_vers_data = (payload) => {
   return {
       type: ADD_VERSION_LIST_DATA,
       payload: payload,
   };
}

const add_api_data = (payload) => {
   return {
       type: ADD_API_LIST_DATA,
       payload: payload,
   };
}


export const getProjectServicesGroupList = (currentPage, filter, sort) => async (dispatch, getState) => {
   const { userPersonal: { userFavourites, userSubscriptions } } = getState()

   try {
       dispatch(add_ser_data({
           loading: true
       }))
       const { data } = await commonServices.getProjectServicesGroupList(currentPage, filter, sort);
       if (data) {
           dispatch(
               add_ser_data({
                   loading: false,
                   data: {
                       data: data.data.length !== 0 && data.data.map(serv => {
                           const checkFav = userFavourites.filter(fav => fav.resourceinstance === serv._id).length !== 0
                           let tempProj = serv;
                           if (checkFav) {
                               tempProj['isFavourite'] = true
                           } else {
                               tempProj['isFavourite'] = false
                           }
                           const checkSubscribe = userSubscriptions.filter(sub =>
                               sub.name.split('_')[0] === "service" &&
                               sub.name.split('_')[1] === serv._id).length !== 0
                           if (checkSubscribe) {
                               tempProj['isSubscribe'] = true
                           } else {
                               tempProj['isSubscribe'] = false
                           }
                           return tempProj
                       })
                   },
                   error: false,
                   _totalcount: data._totalcount
               })
           );
       } else {
           dispatch(
               add_ser_data({
                   loading: false,
                   data: [],
                   error: false,
               })
           );
       }
   } catch (error) {
       dispatch(
           add_ser_data({
               loading: false,
               data: [],
               error: true,
           })
       );
   }
}

export const getServicesList = (currentPage, filter, sort) => async (dispatch, getState) => {
   const { userPersonal: { userFavourites, userSubscriptions } } = getState()

   try {
       dispatch(add_ser_data({
           loading: true
       }))
       const { data } = await commonServices.getServicesList(currentPage, filter, sort);
       if (data) {
           dispatch(
               add_ser_data({
                   loading: false,
                   data: {
                       data: data.data.length !== 0 && data.data.map(serv => {
                           const checkFav = userFavourites.filter(fav => fav.resourceinstance === serv._id).length !== 0
                           let tempProj = serv;
                           if (checkFav) {
                               tempProj['isFavourite'] = true
                           } else {
                               tempProj['isFavourite'] = false
                           }
                           const checkSubscribe = userSubscriptions.filter(sub =>
                               sub.name.split('_')[0] === "service" &&
                               sub.name.split('_')[1] === serv._id).length !== 0
                           if (checkSubscribe) {
                               tempProj['isSubscribe'] = true
                           } else {
                               tempProj['isSubscribe'] = false
                           }
                           return tempProj
                       })
                   },
                   error: false,
                   _totalcount: data._totalcount
               })
           );
       } else {
           dispatch(
               add_ser_data({
                   loading: false,
                   data: [],
                   error: false,
               })
           );
       }
   } catch (error) {
       dispatch(
           add_ser_data({
               loading: false,
               data: [],
               error: true,
           })
       );
   }
}

export const getServiceGUIList = (currentPage, filter, sort) => async (dispatch, getState) => {
   const { userPersonal: { userFavourites, userSubscriptions } } = getState()

   try {
       dispatch(add_ser_gui_data({
           loading: true
       }))
       const { data } = await commonServices.getServiceGUIList(currentPage, filter, sort);
       if (data) {
           dispatch(
               add_ser_gui_data({
                   loading: false,
                   data: {
                       data: data.data.length !== 0 && data.data.map(serv => {
                           const checkFav = userFavourites.filter(fav => fav.resourceinstance === serv._id).length !== 0
                           let tempProj = serv;
                           if (checkFav) {
                               tempProj['isFavourite'] = true
                           } else {
                               tempProj['isFavourite'] = false
                           }
                           const checkSubscribe = userSubscriptions.filter(sub =>
                               sub.name.split('_')[0] === "service" &&
                               sub.name.split('_')[1] === serv._id).length !== 0
                           if (checkSubscribe) {
                               tempProj['isSubscribe'] = true
                           } else {
                               tempProj['isSubscribe'] = false
                           }
                           return tempProj
                       })
                   },
                   error: false,
                   _totalcount: data._totalcount
               })
           );
       } else {
           dispatch(
               add_ser_gui_data({
                   loading: false,
                   data: [],
                   error: false,
               })
           );
       }
   } catch (error) {
       dispatch(
           add_ser_gui_data({
               loading: false,
               data: [],
               error: true,
           })
       );
   }
}




export const addServiceFilters = (serviceFilters) => {
   return {
       type: SERVICE_FILTERS,
       payload: serviceFilters
   }
};

export const deleteServiceById = (serviceId) => {
   return {
       type: DELETE_SERVICE_BY_ID,
       payload: {
           serviceId
       }
   }
};

export const addServiceSort = (serviceSort) => {
   return {
       type: SERVICE_SORT,
       payload: serviceSort
   }
};

export const addServiceHidden = (serviceHidden) => {
   return {
       type: SERVICE_HIDDEN,
       payload: serviceHidden
   }
};

export const alterFavouriteServiceById = (serviceId) => {
   return {
       type: ALTER_FAVOURITE_SERVICE_BY_ID,
       payload: {
           serviceId
       }
   }
};

export const alterSubscibeServiceById = (serviceId) => {
   return {
       type: ALTER_SUBSCRIBE_SERVICE_BY_ID,
       payload: {
           serviceId
       }
   }
};

export const clearServices = () => {
   return {
       type: CLEAR_SERVICES
   }
};

export const getVersionList = (serviceId, currentPage, sort) => async (dispatch) => {
   try {
       dispatch(add_vers_data({
           loading: true
       }))
       const res_data = await microserviceAPIServices.getAllVersions(serviceId, currentPage, JSON.stringify({}), sort);
       const { data } = res_data;

       if (data) {
           dispatch(
               add_vers_data({
                   loading: false,
                   data: data,
                   error: false,
                   _totalcount: data._totalcount
               })
           );
       } else {
           dispatch(
               add_vers_data({
                   loading: false,
                   data: [],
                   error: false,
               })
           );
       }
   } catch (error) {
       dispatch(
           add_vers_data({
               loading: false,
               data: [],
               error: true,
           })
       );
   }
}

export const deleteVersionById = (versionId) => {
   return {
       type: DELETE_VERSION_BY_ID,
       payload: {
           versionId
       }
   }
};

export const addVersionSort = (versionSort) => {
   return {
       type: VERSION_SORT,
       payload: versionSort
   }
};

export const addVersionHidden = (versionHidden) => {
   return {
       type: VERSION_HIDDEN,
       payload: versionHidden
   }
};

export const toggleVersionLoading = (loading) => {
   return {
       type: VERSION_LOADING,
       payload: loading
   }
};

export const clearVersions = () => {
   return {
       type: CLEAR_VERSION
   }
};

export const getAPIList = (serviceId, versionId, currentPage, sort) => async (dispatch) => {
   try {
       dispatch(add_api_data({
           loading: true
       }))
       const res_data = await microserviceAPIServices.getAllAPIs(serviceId, versionId, currentPage, sort);
       const { data} = res_data;
       if (data) {
           dispatch(
               add_api_data({
                   loading: false,
                   data: data?.data ? data?.data : [],
                   error: false,
                   _totalcount: res_data?.data?._totalcount
               })
           );
       } else {
           dispatch(
               add_api_data({
                   loading: false,
                   data: [],
                   error: false,
               })
           );
       }
   } catch (error) {
       dispatch(
           add_api_data({
               loading: false,
               data: [],
               error: true,
           })
       );
   }
}

export const deleteAPIById = (apiId) => {
   return {
       type: DELETE_API_BY_ID,
       payload: {
           apiId
       }
   }
};

export const addAPISort = (apiSort) => {
   return {
       type: API_SORT,
       payload: apiSort
   }
};

export const addAPIHidden = (apiHidden) => {
   return {
       type: API_HIDDEN,
       payload: apiHidden
   }
};

export const clearAPIs = () => {
   return {
       type: CLEAR_API
   }
};
export const editVersionTabbed = (id) => {
   return {
     type: EDIT_VERSION,
     payload: id,
   };
 };
 
 export const editAPITabbed = (id) => {
   return {
     type: EDIT_API,
     payload: id,
   };
 };
 
 // export const detailVersionTabbed = ()=>{
 //     return {
 //         type: DETAIL_VERSION
 //     }
 // }
 
 export const setSelectedVersionId = (id) => {
   return {
     type: SET_SELECTED_VERSIONID,
     payload: id,
   };
 };
 
 export const setSelectedAPIId = (id) => {
   return {
     type: SET_SELECTED_APIID,
     payload: id,
   };
 };
 
 export const closeVersion = () => {
   return {
     type: CLOSE_VERSION,
   };
 };
 
 export const closeAPI = () => {
   return {
     type: CLOSE_API,
   };
 };
 export const setSelectedMicroService=(id)=>{
   return {
       type:SET_SELECTED_MICROSERVICEID,
       payload:id
   }
 }

export const selectApiInstance = (apiId, selectedInstance) => {
   return {
       type: SELECT_API_CONTAINER,
       payload: {
           apiId,
           selectedInstance
       }
   }
};
