import { makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles({
  notFound: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DEDEDF",
    borderRadius: 4,
    padding: 30,
    textAlign: "center",
    marginTop: 5,

    "& h3": {
      font: "normal normal normal 16px/22px Nunito",
      color: "#B5B5B5",
    },
  },
});

const NotFoundContent = ({ message }) => {
  const styles = useStyles();

  return (
    <div className={styles.notFound}>
      <img src="/images/notFound.svg" alt="notfound" />
      <h3>{message}</h3>
    </div>
  );
};

export default NotFoundContent;
