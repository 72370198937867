import {
  Grid,
  Radio,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  FilledInput,
  InputAdornment,
  DialogContent,
  IconButton,
  CircularProgress,
  DialogActions,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import EditTeamEntity from "../../../components/common/entitySchema/teams/EditTeamEntity";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import {
  getTeamById,
  RemoveTeamMember,
  AddTeamMember,
} from "../../../utils/teams/teamsUtils";
import AddIcon from "@material-ui/icons/Add";
import { fetchUsers } from "../../../utils/teams/teamsUtils";
import createDetailsStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { useHistory } from "react-router-dom";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const EditTeam = (props) => {
  const styles = createDetailsStyles();
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [TeamID] = useState(props.match.params._id);
  const [userDetails, setUserDetails] = useState([]);
  const [SearchInput, setSearchInput] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // validation
  const [languageEntitySchema, setlanguageEntitySchema] = useState({});
  const [TeamsData, setTeamsData] = useState({});
  const [error, setError] = useState("");
  const history = useHistory();

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Team", ["Edit"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchlanguageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Team",
        "edit"
      );
      if (_status === 200) {
        setlanguageEntitySchema(data);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });
        setTeamsData({ ...TeamsData, ...tempData });
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchlanguageEntitySchema();
  }, []);

  const fetchTeamsData = async (allMembers) => {
    setIsLoader(true);

    const { _msg, _status, data } = await getTeamById(TeamID);
    if (_status === 200) {
      setTeamsData(data ? data : []);
      setSelectedUsers(data?.users);
      setUserDetails(
        allMembers
          ? allMembers
            ?.filter((u) => u.status === "active")
            ?.map((item) => ({
              ...item,
              checked: data?.users.some((u) => u?._id === item?._id),
            }))
          : []
      );
      setIsLoader(false);
    } else {
      setIsLoader(true);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // users data
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async (filter) => {
    setIsLoader(true);
    const { _msg, _status, data } = await fetchUsers(filter);
    if (_status === 200) {
      await fetchTeamsData(data);
      setIsLoader(false);
    } else {
      setIsLoader(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // serach user
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [SearchInput]);

  const handleSearch = () => {
    if (SearchInput) {
      fetchUserData(JSON.stringify({ name: { $regex: SearchInput } }));
    } else {
      fetchUserData();
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setTeamsData({
      ...TeamsData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = () => {
    enqueueSnackbar("User updated successfully", {
      variant: "success",
    });
    history.goBack();
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (checkCompPermission("Team", ["Add User"])) {
      setOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to add user. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUserToTeam = async () => {
    setIsLoader(true);
    const tempUsers = selectedUsers.filter(
      (user) => !TeamsData.users.some((u) => u._id === user._id)
    );
    for (var i = 0; tempUsers?.length > 0 && i < tempUsers?.length; i++) {
      const teamData = {
        users: [tempUsers[i]._id],
      };
      const { _msg, _status, data } = await AddTeamMember(TeamID, teamData);
      if (_status === 200) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
      } else if (_status === 409) {
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    }
    setOpen(false);
    setIsLoader(false);
  };
  const handleDelete = async (_id) => {
    if (checkCompPermission("Team", ["Remove User"])) {
      const teamData = {
        users: [_id],
      };
      setIsLoader(true);
      const { _msg, _status, data } = await RemoveTeamMember(TeamID, teamData);
      if (_status === 200) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        handleMembers({
          ...userDetails.find((user) => user._id === _id),
          checked: true,
        });
        setIsLoader(false);
        // window.location.replace(`/edit-team/${TeamID}`);
      } else if (_status === 409) {
        handleMembers({
          ...userDetails.find((user) => user._id === _id),
          checked: true,
        });
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to remove user. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleMembers = (user) => {
    if (user?.checked) {
      setSelectedUsers(selectedUsers.filter((u) => u._id !== user._id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    setUserDetails((users) =>
      users?.map((u) => {
        if (u._id === user._id) {
          return { ...u, checked: !user?.checked };
        } else {
          return u;
        }
      })
    );
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Teams", path: "/teams" },
              { title: TeamsData?.name ? `Edit ${TeamsData?.name}` : "" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <h2 className={styles.title}>
              {TeamsData?.name ? `Edit ${TeamsData?.name}` : ""}
            </h2>
            {tabbedView ? (
              <TabbedNavigation
                // schemaLength={languageEntitySchema?.sections?.length}
                isCreateMode={false}
                isEditMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                handleCancel={() => history.goBack()}
                handleSave={handleSubmit}
              />
            )}
          </Grid>
          <div className={styles.border_line} />
        </div>

        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={
                  languageEntitySchema ? languageEntitySchema?.sections : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  {languageEntitySchema?.sections &&
                    languageEntitySchema?.sections.map((section) => {
                      if (section.name === "Team_Header_section") {
                        return (
                          <EditTeamEntity
                            section={
                              languageEntitySchema?.sections?.[activeTab]
                            }
                            TeamsData={TeamsData}
                            error={error}
                            handleChange={handleChange}
                            open={open}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            isLoader={isLoader}
                            userDetails={userDetails}
                            handleDelete={handleDelete}
                            handleAddUserToTeam={handleAddUserToTeam}
                          />
                        );
                      } else if (
                        section.name === "Team_Users" &&
                        activeTab == 1
                      ) {
                        return (
                          <div>
                            <div className={styles.addTeamMember}>
                              <div>
                                <h4>Add Team member</h4>
                              </div>
                              <div>
                                <div
                                  className={styles.addTeamModal}
                                  onClick={handleClickOpen}
                                >
                                  <AddIcon />
                                  <span>Add</span>
                                </div>

                                <Dialog open={open} onClose={handleClose}>
                                  <DialogTitle
                                    style={{
                                      padding: "0 25px",
                                      backgroundColor: "#F9FAFD",
                                    }}
                                  >
                                    <div className={styles.assignProject}>
                                      <div>
                                        <span
                                          style={{
                                            font: "normal normal 600 14px/19px Nunito",
                                          }}
                                        >
                                          Add Team member
                                        </span>
                                      </div>
                                      <div>
                                        <FilledInput
                                          name="filterfn"
                                          className={styles.input}
                                          style={{
                                            width: "100%",
                                            padding: "0px 12px 10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          // placeholder="Search"
                                          onChange={(e) =>
                                            setSearchInput(e.target.value)
                                          }
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              style={{ paddingTop: 15 }}
                                            >
                                              <IconButton
                                                onClick={handleSearch}
                                                onMouseDown={handleSearch}
                                              >
                                                <img
                                                  src="/images/search.svg"
                                                  alt="card"
                                                />
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </DialogTitle>

                                  <DialogContent>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {isLoader && (
                                        <CircularProgress
                                          color="primary"
                                          size={35}
                                        />
                                      )}
                                    </div>
                                    <div style={{ height: 400, width: 550 }}>
                                      <Grid container>
                                        {userDetails &&
                                          userDetails?.map((member, _id) => (
                                            <Grid item xs={12} key={_id}>
                                              <Paper
                                                className={
                                                  styles.selectMemebrModal
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.teamWrapper2
                                                  }
                                                >
                                                  <Checkbox
                                                    color="primary"
                                                    checked={member.checked}
                                                    onChange={(e) =>
                                                      handleMembers(member)
                                                    }
                                                  />

                                                  <div
                                                    className={
                                                      styles.memberDeatils2
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        styles.teamMemberName
                                                      }
                                                    >
                                                      {member.name}
                                                    </Typography>
                                                    <Typography
                                                      className={
                                                        styles.teamMemberDesgination
                                                      }
                                                    >
                                                      {member.email}
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </Paper>
                                            </Grid>
                                          ))}
                                      </Grid>
                                    </div>
                                  </DialogContent>

                                  <DialogActions
                                    style={{
                                      backgroundColor: "#F9FAFD",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div></div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: 250,
                                      }}
                                    >
                                      <Button
                                        onClick={handleClose}
                                        className={styles.cancel}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={handleAddUserToTeam}
                                        className={styles.submitMember}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Add Team members
                                      </Button>
                                    </div>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>

                            <div style={{ margin: "15px 0 100px 0" }}>
                              <Grid container spacing={3}>
                                {selectedUsers &&
                                  selectedUsers?.map((item) => {
                                    return (
                                      <Grid item xs={3} sm={3}>
                                        <div
                                          style={{
                                            border: "1px solid #CBCBCB",
                                            borderRadius: 5,
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={
                                                item.image
                                                  ? item.image
                                                  : "/images/defaultprofilepic.svg"
                                              }
                                              alt="profile"
                                              height="50px"
                                              width="50px"
                                              style={{ borderRadius: "50%" }}
                                            />
                                          </div>

                                          <div
                                            style={{
                                              marginLeft: 15,
                                              lineBreak: "anywhere",
                                            }}
                                          >
                                            <Typography
                                              className={styles.teamMemberName}
                                            >
                                              {item.name}
                                            </Typography>
                                            <Typography
                                              className={
                                                styles.teamMemberDesgination
                                              }
                                              style={{ width: "11rem" }}
                                            >
                                              {item.email}
                                            </Typography>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: 5,
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDelete(item._id)
                                            }
                                          >
                                            <img
                                              src="/images/deleteLine.svg"
                                              alt=""
                                              height="20px"
                                              width="20px"
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </Paper>
            </>
          ) : (
            <>
              {languageEntitySchema?.sections &&
                languageEntitySchema?.sections.map((section) => {
                  if (section.name === "Team_Header_section") {
                    return (
                      <div key={section._id} style={{ padding: "15px 5px 0" }}>
                        <EditTeamEntity
                          section={section}
                          TeamsData={TeamsData}
                          error={error}
                          handleChange={handleChange}
                          open={open}
                          handleClickOpen={handleClickOpen}
                          handleClose={handleClose}
                          isLoader={isLoader}
                          userDetails={userDetails}
                          handleDelete={handleDelete}
                          handleAddUserToTeam={handleAddUserToTeam}
                        />
                      </div>
                    );
                  } else if (section.name === "Team_Users") {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={styles.addTeamMember}>
                            <div>
                              <h4>Add Team member</h4>
                            </div>
                            <div>
                              <div
                                className={styles.addTeamModal}
                                onClick={handleClickOpen}
                              >
                                <AddIcon />
                                <span>Add</span>
                              </div>

                              <Dialog open={open} onClose={handleClose}>
                                <DialogTitle
                                  style={{
                                    padding: "0 25px",
                                    backgroundColor: "#F9FAFD",
                                  }}
                                >
                                  <div className={styles.assignProject}>
                                    <div>
                                      <span
                                        style={{
                                          font: "normal normal 600 14px/19px Nunito",
                                        }}
                                      >
                                        Add Team member
                                      </span>
                                    </div>
                                    <div>
                                      <FilledInput
                                        name="filterfn"
                                        className={styles.input}
                                        style={{
                                          width: "100%",
                                          padding: "0px 12px 10px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        // placeholder="Search"
                                        onChange={(e) =>
                                          setSearchInput(e.target.value)
                                        }
                                        endAdornment={
                                          <InputAdornment
                                            position="end"
                                            style={{ paddingTop: 15 }}
                                          >
                                            <IconButton
                                              onClick={handleSearch}
                                              onMouseDown={handleSearch}
                                            >
                                              <img
                                                src="/images/search.svg"
                                                alt="card"
                                              />
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                    </div>
                                  </div>
                                </DialogTitle>

                                <DialogContent>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {isLoader && (
                                      <CircularProgress
                                        color="primary"
                                        size={35}
                                      />
                                    )}
                                  </div>
                                  <div style={{ height: 400, width: 550 }}>
                                    <Grid container>
                                      {userDetails &&
                                        userDetails?.map((member, _id) => (
                                          <Grid item xs={12} key={_id}>
                                            <Paper
                                              className={
                                                styles.selectMemebrModal
                                              }
                                            >
                                              <div
                                                className={styles.teamWrapper2}
                                              >
                                                <Checkbox
                                                  color="primary"
                                                  checked={member.checked}
                                                  onChange={(e) =>
                                                    handleMembers(member)
                                                  }
                                                />

                                                <div
                                                  className={
                                                    styles.memberDeatils2
                                                  }
                                                >
                                                  <Typography
                                                    className={
                                                      styles.teamMemberName
                                                    }
                                                  >
                                                    {member.name}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      styles.teamMemberDesgination
                                                    }
                                                  >
                                                    {member.email}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </Paper>
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </div>
                                </DialogContent>

                                <DialogActions
                                  style={{
                                    backgroundColor: "#F9FAFD",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div></div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      width: 250,
                                    }}
                                  >
                                    <Button
                                      onClick={handleClose}
                                      className={styles.cancel}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleAddUserToTeam}
                                      className={styles.submitMember}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Add Team members
                                    </Button>
                                  </div>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>

                          <div style={{ margin: "15px 0 100px 0" }}>
                            <Grid container spacing={3}>
                              {selectedUsers &&
                                selectedUsers?.map((item) => {
                                  return (
                                    <Grid item xs={3} sm={3}>
                                      <div
                                        style={{
                                          border: "1px solid #CBCBCB",
                                          borderRadius: 5,
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <div>
                                          <img
                                            src={
                                              item.image
                                                ? item.image
                                                : "/images/defaultprofilepic.svg"
                                            }
                                            alt="profile"
                                            height="50px"
                                            width="50px"
                                            style={{ borderRadius: "50%" }}
                                          />
                                        </div>

                                        <div
                                          style={{
                                            marginLeft: 15,
                                            lineBreak: "anywhere",
                                          }}
                                        >
                                          <Typography
                                            className={styles.teamMemberName}
                                          >
                                            {item.name}
                                          </Typography>
                                          <Typography
                                            className={
                                              styles.teamMemberDesgination
                                            }
                                            style={{ width: "11rem" }}
                                          >
                                            {item.email}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: 5,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleDelete(item._id)}
                                        >
                                          <img
                                            src="/images/deleteLine.svg"
                                            alt=""
                                            height="20px"
                                            width="20px"
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <EditTeamEntity
                            section={section}
                            TeamsData={TeamsData}
                            error={error}
                            handleChange={handleChange}
                            open={open}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            isLoader={isLoader}
                            userDetails={userDetails}
                            handleDelete={handleDelete}
                            handleAddUserToTeam={handleAddUserToTeam}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </>
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
