export const PayloadValidator = (payload, fieldTable) => {
    const dataTypes = ['string', 'integer', 'number', 'object', 'array', 'boolean']
    const primitiveDataTypes = ['string', 'integer', 'number', 'boolean']

    let isValid = {valid: true, msg: ''}
    const payloadCheck = (data) => {
        data.map((item) => {
            if(!("name" in item)){
                isValid['valid'] = false
                isValid['msg'] = 'Name is missing in the uploaded Json.'
                return {isValid}
            } else if(!("type" in item)){
                isValid['valid'] = false
                isValid['msg'] = `${item.name} type is missing`
                return {isValid}
            } else if(!dataTypes.includes(item.type)){
                isValid['valid'] = false
                isValid['msg'] = `${item.name} (${item.type}) type is invalid`
                return {isValid}
            } else if(primitiveDataTypes.includes(item.type)){
                if("children" in item){
                isValid['valid'] = false
                isValid['msg'] = `${item.name} (${item.type}) can't hold children`
                return {isValid}
                }
            }
            const findId = fieldTable.find(pdata => pdata.name === item.name && pdata.type === item.type)
            if(findId){
                item['id'] = findId?._id
            }
            if(item?.children){
                payloadCheck(item.children)
            }
        })
        return data
    }

    const serializeData = payloadCheck(payload)

    return {isValid, serializeData}

}