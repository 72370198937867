const dataColumns = [
  { name: "name", title: "Name", for: "data" },
  { name: "test_data_type", title: "Type", for: "data" },
  { name: "createdby", title: "Created By", for: "data" },
  { name: "createdon", title: "Created On", for: "data" },
  { name: "action", title: "Action", route: "/services/edit", for: "data" },
];

const dataDefaultColumnWidths = [
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "test_data_type", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const dataTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "test_data_type", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const dataColumnOrder = [
  "name",
  "test_data_type",
  "createdby",
  "createdon",
  "action",
];

const dataColumnHidden = [
  "name",
  "test_data_type",
  "createdby",
  "createdon",
  "action",
];

const dataDefaultSorting = [];

export {
  dataColumns,
  dataDefaultColumnWidths,
  dataTableColumnExtensions,
  dataColumnOrder,
  dataDefaultSorting,
  dataColumnHidden,
};
