import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
 
  label: {
    font: "normal normal 600 12px/16px Nunito",
    letterSpacing: 0,
    color: "#000000CC",
  },
}));