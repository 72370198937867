import { isValid } from "date-fns";
import { imageDetails } from "../../../utils/containerMangement/imageUtils";

export const versionValidation = async (versionData, appSelectionData) => {
  let versionIsValid = false;
  const checkImageBuildStatus = async (imgId) => {
    const { data, _msg, _status } = await imageDetails(imgId);
    if (_status === 200) {
      if (data?.state === "build success") {
        versionIsValid = true;
      } else {
        versionIsValid = false;
      }
      return versionIsValid;
    }
  };

  await checkImageBuildStatus(versionData?.docker?.imagedetails?.ImageID);

  return { versionIsValid };
};

export const uiApplicationImageValidation = async (uiData) => {
  if (uiData?.imageid) {
    const { data, _status } = await imageDetails(uiData?.imageid);
    if (_status === 200) {
      if (data?.state === "build success") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
