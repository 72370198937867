import React, { useState, useEffect } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import Loader from "../../../components/common/stuff/Loader";
import { useSnackbar } from "notistack";
import { initError } from "../../../utils/common";
import CustomFieldGroupForAppSelection from "./CustomFieldGroupForAppSelection";
import { getServiceGUIList } from "../../../redux/actions/serviceGUIAction";
import { connect } from "react-redux";
import {
  fetchVersionListData,
  fetchAllUiServices,
  fetchFilteredServiceList,
} from "../../../utils/project/microserviceUtils";
import { fetchAllAiTaskList } from "../../../redux/actions/aiTaskActions";
import { fetchAllApplicationsList } from "../../../utils/orchestration/applicationUtils";
import {
  addAppSelectData,
  clearDeploymentData,
} from "../../../redux/actions/deploymentProcessAction";
import { getAllUiApplications } from "../../../UiApplication/services/UiApplicationApiUtils";

const AppSelectionStep = (props) => {
  const { appSelectionData, appSelectionProcessValid } = props;
  console.log("ioioioi", appSelectionData);
  const styles = manageStyles();
  const [isLoader, setIsLoader] = useState(false);
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const [appEntitySchema, setAppEntitySchema] = useState([]);

  const [appData, setAppData] = useState(appSelectionData);
  const [appSelect, setAppSelect] = useState();
  const [appNameList, setAppNameList] = useState();
  const [selectedAppName, setSelectedAppName] = useState();
  const [selectedAppId, setSelectedAppId] = useState();
  const [versionData, setVersionData] = useState();
  const [selectedVersionTag, setSelectedVersionTag] = useState();
  const [submitData, setSubmitData] = useState();
  const [hideVersion, setHideVersion] = useState();

  useEffect(() => {
    const fetchServiceEntitySchema = async () => {
      checkScreenPermission("Host", ["Create"]);
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Host",
        "create"
      );

      if (_status === 200) {
        setIsLoader(false);
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setAppEntitySchema(entity);
        const appData = {};

        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                appData[`${field.datakey}_unit`] = "";
                appData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                appData[`${field.datakey}_unit`] = "";
                appData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (appData[field.datakey] = "");
              }
              if (field.datakey === "upload_type") {
                if (field.value === "Upload") {
                  setUploadType(true);
                } else {
                  setUploadType(false);
                }
              }
            });
          });
        });

        setAppData((prevData) => ({ ...prevData, ...appData }));

        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });

        props.setError(errData);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchServiceEntitySchema();
  }, []);

  useEffect(() => {
    if (appData.deploymenttype === "Service") {
      const fetchServicesList = async () => {
        setIsLoader(true);
        const { data, _msg, _status } = await fetchFilteredServiceList();
        if (_status === 200) {
          setAppNameList(data);
          setIsLoader(false);
        }
      };
      fetchServicesList();
    } else if (appData.deploymenttype === "UI Service") {
      const fetchAllUIServiceList = async () => {
        setIsLoader(true);
        const { data, _msg, _status } = await fetchAllUiServices();
        if (_status === 200) {
          setAppNameList(data);
          setIsLoader(false);
        }
      };
      fetchAllUIServiceList();
    } else if (appData.deploymenttype === "AI Task") {
      const fetchAITaskList = async () => {
        setIsLoader(true);
        const { data, _msg, _status } = await fetchAllAiTaskList();
        if (_status === 200) {
          setAppNameList(data);
          setIsLoader(false);
        }
      };
      fetchAITaskList();
    } else if (appData.deploymenttype === "Application") {
      setVersionData();
      const fetchApplicationList = async () => {
        setIsLoader(true);
        const { _msg, _status, data } = await fetchAllApplicationsList();
        if (data._status === 200) {
          setAppNameList(data?.data);
          setIsLoader(false);
        }
      };
      fetchApplicationList();
    } else if (appData.deploymenttype === "UI Application") {
      const fetchUIApplicationList = async () => {
        setIsLoader(true);
        const { _msg, _status, data } = await getAllUiApplications();
        if (_status === 200) {
          setAppNameList(data);
          setIsLoader(false);
        }
      };
      fetchUIApplicationList();
    }
  }, [appData?.deploymenttype]);

  useEffect(() => {
    if (
      appData.deploymenttype === "Application" ||
      appData.deploymenttype === "UI Application"
    ) {
      setHideVersion(true);
      setSubmitData({ ...submitData, deployment_app_version: {} });
    } else {
      setHideVersion(false);
    }
  }, [appSelectionData]);

  useEffect(() => {
    versionData?.map((item) => {
      if (item?.docker?.Instances?.length !== 0) {
        props.setAppSelectionProcessValid(true);
      }
    });
  }, [props.clickedNext]);

  useEffect(() => {
    if (
      appSelectionData.deploymenttype === "Application" ||
      appSelectionData.deploymenttype === "UI Application"
    ) {
      if (appSelectionData.deployment_app_name !== undefined) {
        props.setAppSelectionProcessValid(true);
      }
    }
  }, [props.clickedNext, appSelectionData.deployment_app_name]);

  useEffect(() => {
    props.setClickedNext(false);
    if (appSelectionData?.deploymenttype === undefined) {
      props.setAppSelectionProcessValid(false);
      props.setError({ ...props.error, deploymenttype: { isError: true } });
    } else if (
      appSelectionData?.deploymenttype !== "Application" ||
      appSelectionData?.deploymenttype !== "UI Application"
    ) {
      if (appSelectionData?.deployment_app_name === undefined) {
        props.setError({
          ...props.error,
          deploymenttype: { isError: false },
          deployment_app_name: { isError: true },
          appname: { isError: true },
          deployment_app_version: { isError: false },
        });
      } else {
        if (appSelectionData?.deployment_app_version === undefined) {
          props.setError({
            ...props.error,
            deployment_app_name: { isError: false },
            appname: { isError: false },
            deployment_app_version: { isError: true },
          });
        } else {
          props.setError({
            ...props.error,
            deploymenttype: { isError: false },
            appname: { isError: false },
            deployment_app_name: { isError: false },
            deployment_app_version: { isError: false },
          });
          props.setAppSelectionProcessValid(true);
        }
      }
    } else {
      if (appSelectionData?.deployment_app_name === undefined) {
        props.setError({
          ...props.error,
          deploymenttype: { isError: false },
          deployment_app_name: { isError: true },
          appname: { isError: true },
        });
      } else {
        props.setError({
          ...props.error,
          deploymenttype: { isError: false },
          appname: { isError: false },
          deployment_app_name: { isError: false },
          deployment_app_version: { isError: false },
        });
        props.setAppSelectionProcessValid(true);
      }
    }
  }, [props.clickedNext]);

  const handleAppNameChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      props.setError({
        ...props.error,
        [event.target.name]: {
          ...props.error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    setSelectedAppName((prevData) => ({
      ...prevData,
      [event.target.name]: value,
    }));
    setSubmitData({ ...submitData, [event.target.name]: value.name });
  };

  useEffect(() => {
    if (
      appSelectionData.deploymenttype !== "Application" ||
      appSelectionData.deploymenttype !== "UI Application"
    ) {
      const fetchVersionList = async (serviceId) => {
        if (
          appSelectionData.deploymenttype === "Service" ||
          appSelectionData.deploymenttype === "UI Service" ||
          appSelectionData.deploymenttype === "AI Task"
        ) {
          const { data, _status, _msg } = await fetchVersionListData(serviceId);
          setVersionData(data);
        }
      };
      if (selectedAppId) {
        fetchVersionList(selectedAppId);
      }
    }
  }, [selectedAppId]);

  return (
    <div>
      {isLoader && <Loader />}

      {appEntitySchema &&
        appEntitySchema.map((section) => {
          if (section.name === "Deployment_App_Selection_Section") {
            // {
            //   appEntitySchema.map((section) => {});
            // }

            return (
              <div>
                <CustomFieldGroupForAppSelection
                  section={section}
                  appSelectionProcessValid={appSelectionProcessValid}
                  setAppSelectionProcessValid={
                    props.setAppSelectionProcessValid
                  }
                  appData={appData}
                  setAppData={setAppData}
                  appNameList={appNameList}
                  setAppNameList={setAppNameList}
                  selectedAppName={selectedAppName}
                  setSelectedAppName={setSelectedAppName}
                  selectedAppId={selectedAppId}
                  setSelectedAppId={setSelectedAppId}
                  handleAppNameChange={handleAppNameChange}
                  versionData={versionData}
                  setVersionData={setVersionData}
                  error={props.error}
                  setError={props.setError}
                  setIsLoader={setIsLoader}
                  hideVersion={hideVersion}
                  setHideVersion={setHideVersion}
                  submitData={submitData}
                  setSubmitData={setSubmitData}
                  setAppSelect={setAppSelect}
                  setSelectedVersionTag={setSelectedVersionTag}
                  selectedVersionTag={selectedVersionTag}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

const mapDispatchToProps = {
  // getServicesList,
  getServiceGUIList,
  addAppSelectData,
  clearDeploymentData,
};

const mapStateToProps = (state) => {
  const { serviceList } = state.serviceReducer;
  const { GUIdata } = state.serviceGUIReducer;
  const { appSelectionData } = state.deploymentProcessReducer;

  return {
    serviceList,
    GUIdata,
    appSelectionData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSelectionStep);
