/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";

import BreadCrumbs from "../common/Breadcrumbs";
import {
  IconButton,
  CircularProgress,
  Button,
  Backdrop,
  Grid,
  Chip,
} from "@material-ui/core";
import { PlayArrowOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import {
  removeAnimations,
  alterSimulateStatus,
  addContainerDataForTemplate,
  alterFlowMode,
  alterDebugData,
  addDebugAnimation,
  removeAllDebugPoints,
  addProcessOutput,
  addAnimations,
} from "../../redux/actions/orchestrationAction";

import {
  runApplicationDeplyment,
  stopApplicationDeployment,
} from "../../utils/orchestration/applicationUtils";
import {
  debugTemplate,
  getInstanceContextSimulate,
} from "../../utils/orchestration/orchestrationUtils";
import useCheckPermission from "../common/hooks/useCheckPermission";
import { useHistory, useLocation } from "react-router-dom";
import { AppButton } from "../common/Button";
import CountTimer from "../common/CountTimer";
import { RteInActivityWS } from "../../services/containerInactivity";
// import { useStoreState } from "react-flow-renderer"
//

import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "4rem",
  },
  topContent: {
    // position: "fixed",
    zIndex: 100,
    width: "100%",
    backgroundColor: "#fafafa",
  },
  topContentItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    paddingLeft: "1.5rem",
    paddingRight: "1.3rem",
    boxShadow: "0px 1px 2px #00000029",
  },
  title: {
    fontSize: "1.5em",
    color: "#161616",
    marginTop: 0,
    fontFamily: "Nunito",
    // width: "13.7rem"
  },
  leftSideMenu: {
    display: "flex",
    flexDirection: "row",
    // border: "1px solid blue",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "0.5rem",
    alignItems: "center",
  },
  save: {
    // marginRight: "3rem",
    textTransform: "none",
    fontSize: "1em",
    fontFamily: "Nunito",
    color: theme.palette.primary.main,
    height: "1.8rem",
  },
  start_stop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 0,
    marginLeft: "1rem",
    // border: "1px solid black"
    // marginLeft: "1rem"
  },
  button1: {
    background: "#CBFCD0 0% 0% no-repeat padding-box",
    height: 20,
    width: 60,
    borderRadius: 70,
    textAlign: "center",
  },
  button11: {
    height: 20,
    background: "#FDD6D7 0% 0% no-repeat padding-box",
    borderRadius: 70,
    textAlign: "center",
  },
  smallText: {
    fontSize: 14,
    color: "black",
    font: "font: normal normal normal 12px/16px Nunito",
    textTransform: "none",
    padding: 2,
  },
  stop: {
    fontSize: 14,
    fontFamily: "Nunito",
    whiteSpace: "nowrap",
  },
  menuText: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#002855",
  },
  simulate: {
    textTransform: "none",
    fontSize: 14,
    fontFamily: "Nunito",
    color: theme.palette.primary.main,
    height: "1.8rem",
  },
  buttonContainer: {
    marginTop: 0,
    // marginLeft: "3rem",
    display: "flex",
    flexDirection: "row",
  },
  filledButton: {
    backgroundColor: theme.palette.primary.main,
    height: "1.8rem",
    color: "#FFFFFF",
    width: "8rem",
    "& .MuiButton-label": {
      font: "normal normal normal 14px/19px Nunito",
      textTransform: "none",
    },
    // minWidth: "7rem"
  },
  outlineButton: {
    borderColor: theme.palette.primary.main,
    height: "1.8rem",
    width: "8rem",
    "& .MuiButton-label": {
      font: "normal normal normal 14px/19px Nunito",
      textTransform: "none",
    },
  },
  button2: {
    "& .MuiIconButton-root": {
      borderRadius: 0,
      padding: 5,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  // minWidth: "7rem"
}));

function Heading(props) {
  const [running, setRunning] = useState(false);
  const [containerEndTime, setContainerEndTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [processName, setProcessName] = useState("");
  const [cameFromApplication, setCameFromApplication] = useState();
  const search = useLocation().search;

  const {
    simulate_status,
    applicationID,
    flow_mode,
    debug_status,
    debug_instanceID,
    templateid,
    debug_points,
    applicationName,
    templatename,
    process_flow_elements,
    simulateInstanceID,
    appBundleId,
    fromMyApplication,
    application_data,
    userInfo,
    // elements
  } = props;
  const isourApplication = new URLSearchParams(search).get("app_bundle");
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();
  const history = useHistory();
  useEffect(() => {
    setRunning(props.container_state);
  }, [props.container_state]);
  useEffect(() => {
    setProcessName(templatename ? templatename : "Create Aggregated API");
  }, [templatename]);

  var kafkaRabbitProcess = false;

  const startNodeObj = process_flow_elements.find(
    (item) => item.type === "start_node"
  );
  if (startNodeObj) {
    var servicetype = startNodeObj["data"].servicetype
      ? startNodeObj["data"].servicetype
      : "rest";
    if (servicetype !== "rest") {
      kafkaRabbitProcess = true;
    }
  }

  const ws = useRef(null);

  useEffect(() => {
    if (application_data?.data?.runtime_env) {
      ws.current = RteInActivityWS(
        application_data?.data?.runtime_env,
        userInfo?._id
      );
      ws.current.onopen = function (e) { };
      ws.current.onmessage = function (event) {
        const socketData = JSON.parse(event.data);
        if (socketData?.state === "down") {
          setRunning(false);
          setContainerEndTime(0);
        } else if (socketData?.state === "up") {
          setRunning(true);
          setContainerEndTime(socketData?.endtime - 1);
        }
      };

      return () => {
        ws.current.close();
      };
    }
  }, [application_data?.data?.runtime_env]);

  const handleCancelClick = () => {
    props.removeAnimations();
    props.alterSimulateStatus(false);
    props.alterDebugData({
      debug_status: false,
      debug_instanceID: "",
      body: "",
      debug_context_data: {},
    });
    props.removeAllDebugPoints();
  };

  const handleRefreshClick = async () => {
    setPageLoading(true);
    if (simulateInstanceID) {
      const { _msg, _status, data } = await getInstanceContextSimulate(
        applicationID,
        templateid,
        simulateInstanceID
      );
      setPageLoading(false);
      if (_status === 200) {
        props.addProcessOutput({
          instanceid: simulateInstanceID,
          template_context_data: data,
        });
        enqueueSnackbar(_msg, { variant: "success" });
        props.addAnimations();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar("Simulate Instance ID is not defined", {
        variant: "error",
      });
    }
  };

  const handleRunContainer = async () => {
    if (checkCompPermission("ServiceBundle", ["Start"])) {
      setLoading(true);
      const { _msg, _status, data } = await runApplicationDeplyment(
        applicationID
      );
      if (_status === 200) {
        const { _id } = data.data;
        setLoading(false);
        setRunning(true);
        setContainerEndTime(data?.data?.endtime);
        const temp_obj = {
          applicationID: applicationID,
          instanceid: _id,
          running: true,
        };
        props.addContainerDataForTemplate(temp_obj);
        enqueueSnackbar(_msg, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to start application container. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleStopContainer = async () => {
    if (checkCompPermission("ServiceBundle", ["Stop"])) {
      setLoading(true);
      const { _msg, _status } = await stopApplicationDeployment(applicationID);
      if (_status === 200) {
        setContainerEndTime(0);
        setLoading(false);
        setRunning(false);
        props.addContainerDataForTemplate({});
        enqueueSnackbar(_msg, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to stop application container. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleProcessFlowClick = () => {
    props.alterFlowMode("process-flow");
  };

  const handleDataFlowClick = () => {
    props.alterFlowMode("data-flow");
  };

  const handleDebugNext = async () => {
    setPageLoading(true);
    const req_data = {
      applicationid: applicationID,
      templateid: templateid,
      input: {
        queryParams: {},
        pathParams: {},
        headers: {
          "Content-Type": "application/json",
        },
        payload: {
          payload: {},
          debug: {},
        },
      },
    };
    const { _msg, _status, data } = await debugTemplate(
      req_data,
      debug_instanceID
    );
    if (_status === 200) {
      setPageLoading(false);
      var final_debug_data = {};
      if (data) {
        const { StatusCode, body } = data;
        const template_body = StatusCode === 200 ? JSON.parse(body) : {};

        if (Object.keys(template_body).length > 0 && template_body["data"]) {
          final_debug_data = {
            body: body,
            debug_instanceID: template_body["instanceid"],
            debug_context_data: template_body["data"]["Output"]
              ? template_body["data"]["Output"]
              : {},
          };
        }
        props.alterDebugData(final_debug_data);
        enqueueSnackbar(
          template_body["_msg"]
            ? template_body["_msg"]
            : "Debug Point Executed Successfully",
          { variant: "success" }
        );
        props.addDebugAnimation();
      }
    } else {
      setPageLoading(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleSimulateDebugClick = () => {
    var type = debug_points.length === 0 ? "Simulate" : "Debug";
    if (checkCompPermission("ServiceBundle", ["Manage Process"])) {
      props.simulateClick();
    } else {
      enqueueSnackbar(
        `You do not have permission to ${type.toLowerCase()} the process. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleBackToApplication = () => {
    history.push(`/app-bundle/edit/${applicationID}?tab=servicebundle`);
  };

  return (
    <main>
      <div>
        {pageLoading && (
          <Backdrop
            className={styles.backdrop}
            open={pageLoading}
            onClick={() => setPageLoading(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div className={styles.main}>
          <div className={styles.topContent} style={{ marginLeft: "1.5rem" }}>
            <BreadCrumbs
              items={[
                {
                  title: fromMyApplication ? "Application" : "Service Bundle",
                  path: fromMyApplication
                    ? `/app-bundle/edit/${appBundleId}?tab=servicebundle`
                    : "/service-bundle",
                },
                {
                  title: `${applicationName ? applicationName : ""}`,
                  path: fromMyApplication
                    ? `/application/details/${applicationID}?app_bundle=true&id=${appBundleId}&tab=servicebundle`
                    : `/application/details/${applicationID}`,
                },
                { title: `${processName}` },
              ]}
            />
          </div>
        </div>
        <div className={styles.topContentItems}>
          <div style={{ whiteSpace: "nowrap" }}>
            <p className={styles.title}>{`${processName}`}</p>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "1.5rem",
              alignItems: "center",
              width: "13rem",
            }}
          >
            {running && (
              <div className={styles.start_stop}>
                <div
                  className={styles.button1}
                  style={{ color: "#CBFCD0" }}
                  // onClick={() => setRunning(false)}
                >
                  <text className={styles.smallText}>Running</text>
                </div>
                <div className={styles.button2}>
                  <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={handleStopContainer}
                  >
                    <img src="/images/stopProcess.svg" alt="icon" />
                    <text className={styles.stop}>Stop</text>
                  </IconButton>
                </div>
                <div style={{ width: 50, height: 50 }}>
                  <CountTimer containerEndTime={containerEndTime} />
                </div>
              </div>
            )}
            {!running && (
              <div className={styles.start_stop}>
                <div
                  className={styles.button11}
                  // onClick={() => setRunning(true)}
                >
                  <text className={styles.smallText}>Not Running</text>
                </div>
                <div className={styles.button2}>
                  <IconButton
                    color="inherit"
                    aria-label="add an alarm"
                    onClick={handleRunContainer}
                  >
                    <img src="/images/runProcess.svg" alt="icon" />
                    <text className={styles.stop}>Run Module</text>
                  </IconButton>
                </div>
               
              </div>
            )}
            {loading && (
              <div style={{ color: "#002855" }}>
                <CircularProgress size={20} />
              </div>
            )}
          </div> */}

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              className={
                flow_mode === "process-flow"
                  ? styles.filledButton
                  : styles.outlineButton
              }
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                width: "170px",
              }}
              onClick={handleProcessFlowClick}
            >
              Aggregated API Flow
            </Button>
            <Button
              variant="outlined"
              className={
                flow_mode === "data-flow"
                  ? styles.filledButton
                  : styles.outlineButton
              }
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={handleDataFlowClick}
            >
              Data Flow
            </Button>
          </div>
          <div className={styles.leftSideMenu}>
            {/* <div style={{ width: fromMyApplication ? "0%" : "40%" }}>
              {!debug_status && (
                <Button
                  variant="text"
                  className={styles.save}
                  onClick={() => handleDebugNext()}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img src="/images/debug_next.svg" alt="debug" />
                  Debug Next
                </Button>
              )}
              {kafkaRabbitProcess &&
                debug_points.length === 0 &&
                simulate_status && (
                  <Button
                    variant="text"
                    className={styles.save}
                    onClick={() => handleRefreshClick()}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <img src="/images/debug_next.svg" alt="debug" />
                    Refresh
                  </Button>
                )}
            </div> */}
            {/* <div style={{ width: "17%", marginLeft: "1rem" }}>
              {(!simulate_status || !debug_status) && (
                <Button variant="text" className={styles.save}
                  onClick={handleSaveClick}>
                  Save
                </Button>
              )}
            </div> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid
                xs={7}
                style={
                  {
                    // display: "flex",
                    // flexDirection: "row",
                    // width: "70%",
                    // alignItems: "center",
                    // marginBottom: "15px",
                    // justifyContent: "space-between",
                  }
                }
              >
                {fromMyApplication && (
                  <AppButton
                    color="primary"
                    buttonName="Back to Application"
                    variant="contained"
                    className="btnsmall"
                    onClick={handleBackToApplication}
                    style={{ width: "200px" }}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "1.5rem",
                    alignItems: "center",
                    width: "25rem",
                  }}
                >
                  {running && (
                    <div className={styles.start_stop}>

                      {debug_status && (
                        <Button
                          variant="text"
                          className={styles.save}
                          onClick={() => handleDebugNext()}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <img src="/images/debug_next.svg" alt="debug" />
                          Debug Next
                        </Button>
                      )}
                      {kafkaRabbitProcess &&
                        debug_points.length === 0 &&
                        simulate_status && (
                          <Button
                            variant="text"
                            className={styles.save}
                            onClick={() => handleRefreshClick()}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <img src="/images/debug_next.svg" alt="debug" />
                            Refresh
                          </Button>
                        )}

                      <div className={styles.button2}>
                        <IconButton
                          color="secondary"
                          aria-label="add an alarm"
                          onClick={handleStopContainer}
                        >
                          <img src="/images/stopProcess.svg" alt="icon" />
                          <text className={styles.stop}>Stop</text>
                        </IconButton>
                      </div>
                    </div>
                  )}

                  {!running && (
                    <div className={styles.start_stop}>
                      <div className={styles.button2}>
                        <IconButton
                          color="inherit"
                          aria-label="add an alarm"
                          onClick={handleRunContainer}
                        >
                          <img src="/images/runProcess.svg" alt="icon" />
                          <text className={styles.stop}>Run</text>
                        </IconButton>
                      </div>
                    </div>
                  )}

                  {running && !simulate_status && !debug_status && (
                    <Button
                      variant="text"
                      className={styles.simulate}
                      onClick={handleSimulateDebugClick}
                      style={{ marginLeft: "0.5rem" }}
                    >
                      <PlayArrowOutlined
                        color="inherit"
                        onClick={handleSimulateDebugClick}
                      />
                      {debug_points.length === 0 ? "Simulate" : "Debug"}
                    </Button>
                  )}

                  {(simulate_status || debug_status) && (
                    <Button
                      variant="text"
                      className={styles.simulate}
                      onClick={handleCancelClick}
                    >
                      <CancelIcon color="inherit" />
                      Cancel
                    </Button>
                  )}

                  <div
                    style={{
                      minWidth: 40,
                      height: 40,
                      marginLeft: 8,
                      paddingTop: 8,
                    }}
                  >
                    <CountTimer containerEndTime={containerEndTime} />
                  </div>

                  {loading && (
                    <div style={{ color: "#002855", marginLeft: 5 }}>
                      <CircularProgress size={20} />
                    </div>
                  )}
                </div>
              </Grid>

              {/* {simulate_status && (
                <Button variant="text" className={styles.simulate}>
                  <PlayArrowOutlined color="inherit" />
                  Re-Simulate
                </Button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const mapDispatchToProps = {
  removeAnimations,
  alterSimulateStatus,
  addContainerDataForTemplate,
  alterFlowMode,
  addDebugAnimation,
  alterDebugData,
  removeAllDebugPoints,
  addProcessOutput,
  addAnimations,
};

const mapStateToProps = (state_) => {
  const {
    simulate_status,
    application_data,
    flow_mode,
    debug_data,
    process_data,
    debug_points,
    data_flow_nodes_io_details,
    elements,
    process_output,
  } = state_.orchestration;
  const { templateid, templatename } = process_data;
  const { _id, state, name } = application_data.data;
  const { debug_status, debug_instanceID } = debug_data;
  const { userInfo } = state_.userDetails;
  return {
    simulate_status: simulate_status,
    applicationID: _id,
    flow_mode: flow_mode,
    debug_status: debug_status,
    debug_instanceID: debug_instanceID,
    templateid: templateid,
    debug_points: debug_points,
    container_state: state === "running" ? true : false,
    templatename: templatename,
    applicationName: name,
    process_flow_elements: elements,
    elements: data_flow_nodes_io_details,
    simulateInstanceID: process_output.instanceid,
    application_data,
    userInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Heading);
