import {
    Grid, Typography, Paper, FilledInput, Checkbox, InputAdornment, IconButton,
    Divider, Select, MenuItem, RadioGroup, FormControlLabel, Radio, CircularProgress
} from '@material-ui/core';
import React from 'react';
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { AppButton } from '../../Button';
import { useHistory } from 'react-router-dom';
import AddIcon from "@material-ui/icons/Add";
import CustomChips from '../../Chips';
import FileDropperWithLayout from '../../FileDropperWithLayout';
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import moment from 'moment';
import useCheckPermission from '../../hooks/useCheckPermission';
import { copyServiceColumnOrder, copyServiceColumns, copyServiceDefaultColumnWidths, copyServiceDefaultSorting, copyServiceTableColumnExtensions } from '../../../../constants/copyServiceConstant';
import DevExpressTable from '../../Tables/DevExpressTable';

const CustomFieldGroupsForServiceGroupCreate = ({ section, serviceGroupData, teamLoader, teamSearchInput,
    error, handleChange, handleTeamCheck, clickedTeam, handleTeamSearch, selectedTeams, teams, userInfo,
    setClickedTeam, setTeamSearchInput, handleSelectedTeamDelete, setCopyServiceModal, handleNewServiceGroupClick,
    selectedDocuments, setSelectedDocuments, level, documentsError, newServiceListData,
    setDocumentsError
}) => {

    const styles = manageStyles();
    const { checkCompPermission } = useCheckPermission()

    const serviceGroupTableSection = () => {
        return (
            <><Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ margin: '20px 0' }}
            >
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <AppButton
                        color="primary"
                        buttonName="Add Service"
                        variant="contained"
                        startIcon={<AddIcon />}
                        style={{ margin: 'unset', marginLeft: '20px' }}
                        className="btnsmall"
                        disabled={!checkCompPermission('Microservice', ['Copy Service'])}
                        onClick={() => setCopyServiceModal(true)}
                    />
                    {(level >= 1 && level <= 3) && <Divider orientation="vertical" style={{ height: 35, marginLeft: 20, width: 2 }} />}
                    {/* {(level >= 1 && level <= 3) && <AppButton
                        color="primary"
                        buttonName="Create Service Group"
                        variant="contained"
                        startIcon={<AddIcon />}
                        disabled={!checkCompPermission('Project', ['CreateServiceGroup'])}
                        style={{ margin: 'unset', marginLeft: '20px' }}
                        className="btnsmall"
                        onClick={() => handleNewServiceGroupClick('new')}
                    />} */}
                </Grid>
            </Grid>
                <DevExpressTable
                    columns={copyServiceColumns}
                    rows={newServiceListData}
                    defaultColumnWidths={copyServiceDefaultColumnWidths}
                    tableColumnExtensions={copyServiceTableColumnExtensions}
                    columnOrder={copyServiceColumnOrder}
                    defaultSorting={copyServiceDefaultSorting}
                    hideColumnChooser={true}
                    totalCount={newServiceListData.length}
                    noDataMsg="No Service Added"
                    isCustomWidth={100}
                />
            </>)
    }

    const teamsSection = () => {
        return (
            <><FilledInput
                name="searchInput"
                value={teamSearchInput}
                onChange={(e) => {
                    setTeamSearchInput(e.target.value)
                    handleTeamSearch(e.target.value)
                }}
                className={`${styles.input} ${styles.searchInput2} ${styles.customSearchInput} ${error.teams && selectedTeams.length === 0 && styles.errorAuto}`}
                placeholder="Search"
                autoComplete='off'
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTeamSearch}
                            onMouseDown={handleTeamSearch}
                            edge="end"
                        >
                            <img src="/images/search.svg" alt="card" />
                        </IconButton>
                    </InputAdornment>
                }
            />
                {(error.teams?.isError && selectedTeams.length === 0) && <p className={styles.errorMsg}>{'Teams is not selected'}</p>}

                <CustomChips
                    chipsData={selectedTeams}
                    handleDelete={handleSelectedTeamDelete}
                    isEditMode={!checkCompPermission('ServiceGroup', ['Assign Or Dismiss Team'])}
                />

                {teamLoader && <CircularProgress color="primary" />}

                <Grid container spacing={5}>
                    <Grid item xs={6} sm={6} style={{ borderRight: '1px solid rgba(0, 40, 85, .4)' }}>
                        <div className={styles.section}
                            style={{ opacity: 1, maxHeight: '25rem', overflow: 'auto', padding: '0 1rem 1rem 0' }}>
                            <Grid container spacing={3}>
                                {teams.map((team, index) => (
                                    <Grid item xs={12} sm={12} key={index}>
                                        <Paper className={styles.paper} onClick={() => setClickedTeam(team)} style={{ cursor: 'pointer' }}>
                                            <div className={styles.spaceBetweenText}>
                                                <Typography variant="body1">{team.name}</Typography>
                                                <div className={styles.technologyCheck}>
                                                    <Typography variant="body1">{team.org}</Typography>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selectedTeams.find(item => item._id === team._id) ? true : false}
                                                        className={styles.teamCheckbox}
                                                        onChange={(e) => handleTeamCheck(team)}
                                                        name={team.name}
                                                        disabled={!checkCompPermission('ServiceGroup', ['Assign Or Dismiss Team'])}
                                                    />
                                                </div>
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <div className={styles.section}>
                            <Grid container spacing={3}>
                                {clickedTeam?.users?.map((member, index) => (
                                    <Grid item xs={6} sm={6} key={index}>
                                        <Paper className={styles.paper}>
                                            <div className={styles.teamWrapper}>
                                                {member?.image ?
                                                    <img src={member?.image} className={styles.memberProfile} alt="profile" /> :
                                                    <img src="/images/users/default.svg" alt="profile" />}
                                                <div className={styles.teamMember}>
                                                    <Typography variant="body1" className={styles.teamMemberName}>{member?.name}</Typography>
                                                    <Typography variant="body2" className={styles.teamMemberDesgination}>{member?.desgination}</Typography>
                                                </div>
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </>)
    }

    const generateField = (field) => {
        switch (field.uiElementType) {
            case "TextField":
                return <>
                    <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                    <input
                        type="text"
                        name={field.datakey}
                        className={`${styles.input} ${error[field.datakey]?.isError && styles.error}`}
                        value={field.dataType === "date" && serviceGroupData[field.datakey] ?
                            moment.unix(serviceGroupData[field.datakey]).tz(userInfo.timeZone).format(userInfo.time === 'h:mm' ? `${userInfo.dateFormat}, ${userInfo.time} A` : `${userInfo.dateFormat} ${userInfo.time}`) :
                            serviceGroupData[field.datakey]}
                        autoComplete="off"
                        onChange={handleChange}
                    />
                    {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                </>
            case "TextArea":
                return <>
                    <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                    <textarea
                        name={field.datakey}
                        value={serviceGroupData[field.datakey]}
                        onChange={handleChange}
                        className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error}`}
                        rows="7"></textarea>
                    {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                </>
            case "Dropdown":
                return <>
                    <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                    <Select
                        name={field.datakey}
                        value={serviceGroupData[field.datakey] ? serviceGroupData[field.datakey] : ""}
                        onChange={handleChange}
                        className={`${styles.select} ${error[field.datakey]?.isError && styles.error}`}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        {typeof field?.values === "string" ? JSON.parse(field?.values).map((value, index) => (
                            <MenuItem value={value} key={index}>
                                {value}
                            </MenuItem>
                        )) : field?.values.map((value, index) => (
                            <MenuItem value={value} key={index}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                </>
            case "Date":
                return <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                    <DesktopDatePicker
                        value={serviceGroupData[field.datakey] ? serviceGroupData[field.datakey] : null}
                        onChange={(date) => handleChange({ target: { name: field.datakey, value: date } })}
                        inputFormat={'dd/MM/yyyy'}
                        renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                        }) => (
                            <>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input
                                        ref={inputRef}
                                        {...inputProps}
                                        className={`${styles.input} ${error[field.datakey]?.isError && styles.error}`}
                                    />
                                    <span className={`${styles.datePickerIcon}`}>{InputProps?.endAdornment}</span>
                                </div>
                                {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                            </>
                        )}
                    />
                </LocalizationProvider>
            case "Currency":
            case "Amount":
                return <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                        <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} Unit {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                        <Select
                            name={`${field.datakey}_unit`}
                            value={serviceGroupData[`${field.datakey}_unit`] ? serviceGroupData[`${field.datakey}_unit`] : ""}
                            onChange={handleChange}
                            className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error}`}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {typeof field?.values === "string" ? JSON.parse(field?.values).map((value, index) => (
                                <MenuItem value={value} key={index}>
                                    {value}
                                </MenuItem>
                            )) : field?.values.map((value, index) => (
                                <MenuItem value={value} key={index}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                        {error[`${field.datakey}_unit`]?.isError && <p className={styles.errorMsg}>{error[`${field.datakey}_unit`].msg}</p>}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                        <input
                            type="text"
                            name={`${field.datakey}_value`}
                            className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error}`}
                            value={serviceGroupData[`${field.datakey}_value`]}
                            autoComplete="off"
                            onChange={handleChange}
                        />
                        {error[`${field.datakey}_value`]?.isError && <p className={styles.errorMsg}>{error[`${field.datakey}_value`].msg}</p>}
                    </Grid>
                </Grid>
            case "RadioButton":
                return <>
                    <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                    <RadioGroup
                        name={field.datakey}
                        value={serviceGroupData[field.datakey] ? serviceGroupData[field.datakey] : ""}
                        onChange={handleChange}
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        {typeof field?.values === "string" ? JSON.parse(field?.values).map((value, index) => (
                            <FormControlLabel
                                value={value}
                                key={index}
                                control={
                                    <Radio color="primary" />}
                                label={value} />
                        )) : field?.values.map((value, index) => (
                            <FormControlLabel
                                value={value}
                                key={index}
                                control={
                                    <Radio color="primary" />}
                                label={value} />
                        ))}
                    </RadioGroup>
                    {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                </>
            default:
                return <></>
        }
    }

    const documentsSection = () => {
        return (checkCompPermission('ServiceGroup', ['Create Document']) ? <FileDropperWithLayout
            filesLimit={20}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={(data) => setSelectedDocuments(data)}
            documentsError={documentsError}
            setDocumentsError={setDocumentsError}
        /> : <p className={styles.fieldHeading} style={{ color: "red" }}>You do not have permission to Create Document. Please contact your Admin.</p>)
    };

    return (
        <div>
            {section && section?.fieldgroups.map(fieldgroup => {
                return (
                    <div className={styles.fieldGroup} key={fieldgroup._id}>
                        {section.name === 'ServiceGroup_Header_Section' && !section.fieldgroups[0]._id === fieldgroup._id && <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>}
                        {fieldgroup && fieldgroup.fields.filter(f => f.uiElementType === "TextArea").length === 0 ?
                            (
                                // layout with TextArea
                                <Grid container spacing={3} style={{ marginTop: '0rem', display: 'block' }} key={fieldgroup._id}>
                                    {fieldgroup && fieldgroup?.fields.map(field => {
                                        switch (field.name) {
                                            case "Service Group Team":
                                                return teamsSection()
                                            case "Service Group Service":
                                                return serviceGroupTableSection()
                                            case "Service Group Documentation":
                                                return documentsSection();
                                            default:
                                                return (
                                                    <Grid item xs={3} sm={3} key={field._id}>
                                                        {generateField(field)}
                                                    </Grid>
                                                )
                                        }
                                    })}
                                </Grid>
                            ) : (
                                // layout without TextArea
                                <Grid container spacing={3} style={{ marginTop: '0rem' }} key={fieldgroup._id}>
                                    <Grid item xs={9} sm={9}>
                                        <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                                            {fieldgroup && fieldgroup?.fields.filter(f => f.uiElementType !== "TextArea").map(field => {
                                                switch (field.uiElementType) {
                                                    case "Service Group Team":
                                                        return teamsSection()
                                                    case "Service Group Service":
                                                        return serviceGroupTableSection()
                                                    case "Service Group Documentation":
                                                        return documentsSection();
                                                    default:
                                                        return (
                                                            <Grid item xs={4} sm={4} key={field._id}>
                                                                {generateField(field)}
                                                            </Grid>
                                                        )
                                                }
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <Grid container spacing={3} style={{ marginTop: '0rem' }}>
                                            {fieldgroup && fieldgroup?.fields.filter(f => f.uiElementType === "TextArea").map(field => {
                                                return (
                                                    <Grid item xs={12} sm={12} key={field._id}>
                                                        {generateField(field)}
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </div>
                )
            })}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { userInfo } = state.userDetails;

    return {
        userInfo
    };
}

export default connect(mapStateToProps)(CustomFieldGroupsForServiceGroupCreate)