import { RuntimeEnvServices } from "../../services/runtimeEnvServices";

const RunTimeEnvService = new RuntimeEnvServices();

export const createRunTimeEnv = async (data_obj) => {
  try {
    const res_data = await RunTimeEnvService.createRuntimeEnv(data_obj);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getRuntimeEnvDetailsByID = async (envID) => {
  try {
    const res_data = await RunTimeEnvService.getRuntimeEnvDetailsByID(envID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const DeleteRuntimeEnv = async (envID) => {
  try {
    const res_data = await RunTimeEnvService.DeleteRuntimeEnv(envID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const editRuntimeEnvByID = async (envID, envData) => {
  try {
    const res_data = await RunTimeEnvService.editRuntimeEnvByID(envID, envData);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const StartRuntimeEnv = async (envID) => {
  try {
    const res_data = await RunTimeEnvService.StartRuntimeEnv(envID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const StopRuntimeEnv = async (envID) => {
  try {
    const res_data = await RunTimeEnvService.StopRuntimeEnv(envID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
