import {
  ADD_USER_DATA,
  USERS_FILTER,
  DELETE_USER,
  USER_SORT,
  BLOCK_UNBLOCK_USER,
  CLEAR_USER_DATA,
  USER_HIDDEN_COULMNS,ALTER_VIEW
} from "../actionTypes";
import { userServices } from "../../services/userServices";

const userService = new userServices();

function add_user_list_data(payload) {
  return {
    type: ADD_USER_DATA,
    payload: payload,
  };
}

export function add_users_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_user_list_data({
        loading: true,
      })
    );
    userService
      .getUserList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_user_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_user_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch(() => {
        dispatch(
          add_user_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_user_filters = (userFilters) => {
  return {
    type: USERS_FILTER,
    payload: userFilters,
  };
};

export const add_user_sort = (userSort) => {
  return {
    type: USER_SORT,
    payload: userSort,
  };
};

export const delete_user = (userID) => {
  return {
    type: DELETE_USER,
    payload: {
      userID,
    },
  };
};

export const block_unblock_user = (userID, status) => {
  return {
    type: BLOCK_UNBLOCK_USER,
    payload: {
      userID,
      status,
    },
  };
};

export const clear_user_data = () => {
  return {
    type: CLEAR_USER_DATA,
  };
};

export const add_user_hidden_cols = (userHiddenCols) => {
  return {
    type: USER_HIDDEN_COULMNS,
    payload: userHiddenCols,
  };
};

export const alterView = (val) => {
  return {
    type: ALTER_VIEW,
    payload:val,
  };
};
