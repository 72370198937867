import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import Styles from "../../../../styles/commonStyles/MenuRightBar";
import useGetData from "../../../../hooks/useGetData";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import { Grid, Tooltip } from "@material-ui/core";
import { Switch } from "@mui/material";
import MenuHelper from "./helper/MenuHelper";
import LogoPopup from "./helper/LogoPopup";

function MenuSettings() {
  const dispatch = useDispatch();
  const classes = Styles();
  const { currentPage, currentComponent, executeJavascript, getDataValue } =
    useGetData();
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const open = Boolean(anchorEl);
  const [isTypeHamburger, setIsTypeHamburger] = useState(false);
  const selectedTypeCss = { color: "black", fontWeight: 800, fontSize: 14 };

  useEffect(() => {
    setIsTypeHamburger(
      currentComponent?.isTypeHamburgerWebView !== undefined
        ? currentComponent?.isTypeHamburgerWebView
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComponent]);

  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError({ ...error, data: "" });
      } else {
        dataValue = getDataValue(value);
        setError({ ...error, data: "" });
      }
    } catch (error) {
      setError({ ...error, data: error?.message });
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          data: {
            dataInput: value,
            dataValue: dataValue,
          },
        };
      }
      return component;
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const changeTab = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          tabValue: !component?.tabValue,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleHidemenu = (e) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          logoHidden: !currentComponent?.logoHidden,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChangeMenuType = (e) => {
    setIsTypeHamburger(!isTypeHamburger);
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          isTypeHamburgerWebView: !currentComponent?.isTypeHamburgerWebView,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleOnMenuWidthChange = (event) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            menuWidth: parseInt(event?.target?.value),
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  return (
    <div className={classes.menu} style={{ marginBottom: 50 }}>
      <div className="field">
        <label htmlFor="">Logo</label>
        <div className="logoBox" onClick={handleClick}>
          <div className="title">
            <img
              src={
                currentComponent?.logo
                  ? currentComponent?.logo
                  : "/img/image.png"
              }
              alt=""
              style={{
                height: "1.75rem",
                width: "2.25rem",
                marginLeft: 5,
              }}
            />
            <Tooltip
              title={
                currentComponent?.logoTitle
                  ? currentComponent?.logoTitle
                  : "No Title"
              }
            >
              <label className="titleLabel" style={{ width: 115 }} htmlFor="">
                {currentComponent?.logoTitle
                  ? currentComponent?.logoTitle
                  : "No Title"}
              </label>
            </Tooltip>
          </div>
          <div className="icon" style={{ width: "auto" }}>
            <img
              src={
                currentComponent?.logoHidden
                  ? "/img/eye-slash.svg"
                  : "/img/blue-eye.svg"
              }
              alt=""
              onClick={handleHidemenu}
              style={{ height: 25, width: 20 }}
            />
          </div>
        </div>
        <LogoPopup
          isOptionsOpen={open}
          optionsEl={anchorEl}
          setOptionsEl={setAnchorEl}
          currentPage={currentPage}
          currentComponent={currentComponent}
        />
      </div>
      <div className="field">
        <label htmlFor="menuType">Type</label>
        <div className={classes.menuViewType}>
          <span style={!isTypeHamburger ? selectedTypeCss : {}}>Left Dock</span>
          <Switch
            checked={isTypeHamburger}
            onClick={handleChangeMenuType}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span style={isTypeHamburger ? selectedTypeCss : {}}>Hamburger</span>
        </div>
      </div>
      <div className="field">
        <label htmlFor="menuType">
          Menu Width<span style={{ textTransform: "lowercase" }}>(px)</span>
        </label>
        <input
          type="number"
          value={currentComponent?.menuWidth}
          onChange={handleOnMenuWidthChange}
          autoComplete="off"
        />
      </div>
      {currentComponent?.type === "Menu" && (
        <div className="field">
          <div className="tableColumns">
            <Grid container justifyContent="space-between">
              <Grid item>
                <label>Menu</label>
              </Grid>
              <Grid item>
                <label
                  // onClick={changeTab}
                  style={
                    currentComponent?.tabValue
                      ? { cursor: "pointer" }
                      : {
                          backgroundColor: "#ccd4dd",
                          color: "#002855",
                          padding: "1px 5px",
                          cursor: "pointer",
                        }
                  }
                >
                  JS
                </label>
              </Grid>
            </Grid>
            {currentComponent?.tabValue ? (
              <>
                <MenuHelper />
              </>
            ) : (
              <div className="field" style={{ marginTop: 5 }}>
                <JSDataInput
                  value={currentComponent?.data}
                  onChange={handleChange}
                  title={`${currentComponent.name} : Data`}
                  error={error.data}
                  mode="javascript"
                  height={250}
                  lineNumbers={true}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuSettings;
