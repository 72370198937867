import React, { useState, useEffect } from "react";
import {
  Paper,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import Editor from "../../common/Editor";
import inputStyles from "./styles";
import InputTable from "../../common/Tables/InputTable";
import JSONToTable from "../../common/JSONToTable";
import { getJSONObjFromKeyValueArray } from "../../../utils/orchestration/orchestrationUtils";
import { simutateAPI } from "../../../utils/project/microserviceUtils";
import { AppButton } from "../../common/Button";
import { selectApiInstance } from "../../../redux/actions/serviceAction";
import { getRuntimeEnvDetailsByID } from "../../../utils/containerMangement/runtimeEnvUtils";
import { fetchFieldGroupDetails } from "../../../utils/project/fieldgroupUtils";
import useCheckPermission from "../../common/hooks/useCheckPermission";
import { jsonToArray } from "../../../utils/common";
import CountTimer from "../../common/CountTimer";
import { ContainerInActivityWS } from "../../../services/containerInactivity";
import {
  StartContainer,
  StopContainer,
} from "../../../utils/containerMangement/containerUtils";

const temp_data = [
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

const temp_header_data = [
  { id: uuidv4(), key: "Content-Type", value: "application/json" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  line1: {
    fontSize: "14px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    width: "750px",
    height: "400px",
    marginTop: "0.5rem",
  },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
}));

function SimulateModalForMicroservice(props) {
  const [dataObj, setDataObj] = useState({
    topic: "",
    servicetype: "rest",
    containerName: "",
    message: "",
  });
  const [errorObj, setErrorObj] = useState({
    topic: false,
    message: false,
    containerName: false,
  });

  const [containerEndTime, setContainerEndTime] = useState(0);

  const [activetab, setActiveTab] = useState("header");
  const [activeInputType, setActiveInputType] = useState("text");
  const [activeOutputType, setActiveOutputtype] = useState("text");
  const [running, setRunning] = useState();

  const [headerList, setHeaderList] = useState(temp_header_data);
  const [queryParamsList, setQueryParamsList] = useState(temp_data);
  const [pathParamsList, setPathParamsList] = useState(temp_data);
  const [selectedInstanceError, setSelectedInstanceError] = useState(false);

  const [inputList, setInputList] = useState(temp_data);
  const [inputEditorValue, setInputEditorValue] = useState("{}");

  // const [outputList, setOutputList] = useState(temp_data);
  const [outputEditorValue, setOutputEditorValue] = useState("");
  const [microserviceOutputBody, setMicroserviceOutputBody] = useState({});
  const [containers, setContainers] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const classes = inputStyles();
  const classes2 = useStyles();

  const { checkCompPermission } = useCheckPermission();

  const { isOpen, selectApiInstance } = props;

  const fetchruntimeENVdata = async (envID) => {
    const { _msg, _status, data } = await getRuntimeEnvDetailsByID(envID);
    if (_status === 200) {
      return data;
    } else {
      return false;
    }
  };

  const getFieldGroupDetailsForInput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {
      setInputList(
        data?.children
          .filter((field) => field.status === "active" || field.ftype === "fieldgroup")
          .map((field) => ({
            id: uuidv4(),
            key: field.name,
            value: "",
          }))
      );
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (props?.dependency?.Instances) {
      const ws = ContainerInActivityWS(
        props.dependency?.Instances[0]?.containerid,
        props.userInfo._id
      );
      ws.onopen = function (event) {};
      ws.onmessage = function (event) {
        const socketData = JSON.parse(event.data);
        if (socketData?.state === "exited") {
          setContainerEndTime(0);
          setRunning(false);
        } else if (socketData?.state === "running") {
          setContainerEndTime(socketData?.endtime);
          setRunning(true);
        }
      };

      return () => {
        ws.close();
      };
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (props?.dependency?.servicetype !== "rest") {
      const envData = await fetchruntimeENVdata(
        props?.dependency?.environmentID
      );
      setContainers(envData.services ? envData.services : []);
    }
    if (props?.dependency?.input?.fieldGroupid) {
      if (checkCompPermission("GlossaryFieldGroup", ["Read"])) {
        getFieldGroupDetailsForInput(props?.dependency?.input?.fieldGroupid);
      }
    }
    if (props?.dependency?.servicetype) {
      setDataObj((prevState) => ({
        ...prevState,
        servicetype: props?.dependency?.servicetype,
        topic: props?.dependency?.topic,
      }));
    }
    if (props?.dependency?.pathParameters) {
      const pathParams = props?.dependency?.pathParameters.map((item) => ({
        id: uuidv4(),
        key: item,
        value: "",
      }));
      setPathParamsList(pathParams);
    }
    if (props?.dependency?.queryParameters) {
      const queryParams = props?.dependency?.queryParameters.map((item) => ({
        id: uuidv4(),
        key: item,
        value: "",
      }));
      setQueryParamsList(queryParams);
    }

    let headerValues = jsonToArray(props?.dependency?.headers);
    if (headerValues.length) {
      setHeaderList(headerValues);
    }
  }, []);

  const handleTextChange = (key, val) => {
    setDataObj((prevState) => ({
      ...prevState,
      [key]: val,
    }));
    setErrorObj((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleTabChange = (event, val) => {
    setActiveTab(val);
  };

  const handleInputTypeChange = (val) => {
    setActiveInputType(val);
  };

  //handle change in the input table
  const handleInputTableChange = (val) => {
    setInputList(val);
  };

  const handleHeaderTableChange = (val) => {
    setHeaderList(val);
  };

  const handleQueryParamsListChange = (val) => {
    setQueryParamsList(val);
  };

  const handlePathParamsListChange = (val) => {
    setPathParamsList(val);
  };

  const handleSubmitClick = async () => {
    if (dataObj.servicetype === "rest") {
      var req_data = {};
      const headerObj = getJSONObjFromKeyValueArray(headerList);
      const inputObj = getJSONObjFromKeyValueArray(inputList);
      const pathParamsObj = getJSONObjFromKeyValueArray(pathParamsList);
      const queryParamsObj = getJSONObjFromKeyValueArray(queryParamsList);
      var tempInputEditorValue = {};
      try {
        tempInputEditorValue = JSON.parse(inputEditorValue);
        // if (
        //   props.dependency.Instances &&
        //   props.dependency.Instances[0]?.containerid
        // ) {
          if ((props.dependency.Instances && props.dependency.Instances[0]?.containerid) || props.dependency?.serviceType === "External") {
          setIsLoader(true);
          // For microservice
          req_data = {
            mid: props.dependency.mid,
            vid: props.dependency.vid,
            instanceid: props.dependency.Instances && props.dependency.Instances[0]?.containerid,
            apiId: props.dependency.apiId,
            servicetype: dataObj.servicetype,
            input: {
              headers: headerObj,
              payload:
                activeInputType === "text" ? inputObj : tempInputEditorValue,
              queryParams: queryParamsObj,
              pathParams: pathParamsObj,
            },
          };
          const { _msg, _status, data } = await simutateAPI(req_data);

          if (_status === 200) {
            setIsLoader(false);
            enqueueSnackbar(_msg, { variant: "success" });
            if (typeof data?.body === "string") {
              setMicroserviceOutputBody({ result: data?.body });
            } else {
              setMicroserviceOutputBody(data?.body);
            }
          } else {
            setIsLoader(false);
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
            // props.onSubmitClick("red");
          }
        } else {
          enqueueSnackbar("No such Running Container", { variant: "error" });
        }
      } catch (err) {
        enqueueSnackbar("Input data not in proper JSON format! Please check.", {
          variant: "error",
        });
        setIsLoader(false)
      }
    } else {
      var errorFlag = false;
      var tempError = {};
      var tempData = dataObj;
      if (props.dependency?.serviceType === "External") {
        delete tempData["containerName"];
      }
      for (var obj in tempData) {
        if (tempData[obj] === "") {
          tempError[obj] = true;
          errorFlag = true;
        }
      }
      setErrorObj((prevState) => ({
        ...prevState,
        ...tempError,
      }));
      if (!errorFlag) {
        req_data = {
          mid: props.dependency.mid,
          vid: props.dependency.vid,
          apiId: props.dependency.apiId,
          servicetype: dataObj.servicetype,
          topic: dataObj.topic,
          containerName: dataObj.containerName,
          message: dataObj.message,
        };
        const { _msg, _status, data } = await simutateAPI(req_data);

        if (_status === 200) {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "success" });
          if (typeof data?.body === "string") {
            setMicroserviceOutputBody({ result: data?.body });
          } else {
            setMicroserviceOutputBody(data?.body);
          }
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
          // props.onSubmitClick("red");
        }
      }
    }
  };

  const handleRunContainer = async () => {
    setIsLoader(true);
    const { _msg, _status, data } = await StartContainer(
      props.dependency.Instances[0]?.containerid
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setRunning(true);
      setIsLoader(false);
      setContainerEndTime(data?.endtime);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setIsLoader(false);
    }
  };

  const handleStopContainer = async () => {
    setIsLoader(true);
    const { _msg, _status, data } = await StopContainer(
      props.dependency.Instances[0]?.containerid
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setRunning(false);
      setIsLoader(false);
      setContainerEndTime(0);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setIsLoader(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => props.onCloseClick()}
        scroll={"paper"}
        // aria-labelledby="scroll-dialog-title"
        // aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="lg"
      >
        <Backdrop
          className={classes.backdrop}
          open={isLoader}
          onClick={() => setIsLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle className={classes2.header} id="scroll-dialog-title">
            <p className={classes2.heading}>Simulate Microservice API</p>
        </DialogTitle>
        <DialogContent dividers={true} style={{ width: 800 }}>
          {props.dependency?.serviceType !== "External" &&
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                Container :{" "}<b>
                {props?.dependency?.Instances &&
                  props.dependency?.Instances[0]?.containername}
                  </b>
              </p>
            </div>
            <div style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}>
              {running ? (
                <div className={classes.start_stop}>
                  <div className={classes.button2}>
                    <IconButton
                      color="secondary"
                      aria-label="add an alarm"
                      onClick={handleStopContainer}
                    >
                      <img src="/images/stopProcess.svg" alt="icon" />
                      <text className={classes.stop}>Stop</text>
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div className={classes.start_stop}>
                  <div className={classes.button2}>
                    <IconButton
                      color="inherit"
                      aria-label="add an alarm"
                      onClick={handleRunContainer}
                    >
                      <img src="/images/runProcess.svg" alt="icon" />
                      <text className={classes.stop}>Run</text>
                    </IconButton>
                  </div>
                </div>
              )}

              {props?.dependency?.Instances && (
                <div style={{ minWidth: 40, marginLeft: 5, marginTop: 0 }}>
                  <CountTimer containerEndTime={containerEndTime} />
                </div>
              )}
            </div>
          </div>
          }
          {dataObj.servicetype === "rest" && (
            <div className={classes.container}>
              <div className={classes.tab_container}>
                <Paper className={classes.root}>
                  <Tabs
                    value={activetab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // centered
                  >
                    <Tab label="Header" value="header" />
                    <Tab label="Query Params" value="query_params" />
                    <Tab label="Path Params" value="path_params" />
                    <Tab label="Body" value="body" />
                    <Tab label="Output" value="output" />
                  </Tabs>
                </Paper>

                {/* {props.dependency.Instances && (
                <div className={classes.containerInput}>
                  <label className={classes.fieldHeading}>Container</label>
                  <Select
                    value={props.dependency.selectedInstance}
                    onChange={(e) => {
                      selectApiInstance(props.dependency.apiId, e.target.value)
                      setSelectedInstanceError(false)
                    }}
                    name="selectedInstance"
                    className={`${classes.select} ${selectedInstanceError ? classes.error : ''}`}
                    style={{ marginTop: 0 }}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {props.dependency.Instances.map((instance) => (
                      <MenuItem value={instance} key={instance.Instanceid}>
                        {instance.containername}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedInstanceError && <p className={classes.errorMsg}>
                    {'Container is required'}
                  </p>}
                </div>
              )} */}
                {activetab === "body" && (
                  <div className={classes.iconContainer}>
                    <IconButton
                      color="inherit"
                      aria-label="textfield"
                      edge="start"
                      className={`${
                        activeInputType === "text"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => handleInputTypeChange("text")}
                    >
                      <img
                        src="/images/orchestration/lineicon.svg"
                        alt="text"
                      />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      aria-label="editor"
                      edge="start"
                      className={`${
                        activeInputType === "editor"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => handleInputTypeChange("editor")}
                    >
                      <img
                        src="/images/orchestration/editoricon.svg"
                        alt="editor"
                      />
                    </IconButton>
                  </div>
                )}
                {activetab === "output" && (
                  <div className={classes.iconContainer}>
                    <IconButton
                      color="inherit"
                      aria-label="textfield"
                      edge="start"
                      className={`${
                        activeOutputType === "text"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => setActiveOutputtype("text")}
                    >
                      <img
                        src="/images/orchestration/lineicon.svg"
                        alt="text"
                      />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      aria-label="editor"
                      edge="start"
                      className={`${
                        activeOutputType === "editor"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => setActiveOutputtype("editor")}
                    >
                      <img
                        src="/images/orchestration/editoricon.svg"
                        alt="editor"
                      />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className={classes.line5}>
                {/* <p className={classes.label}>Input</p> */}
                {activetab === "body" && activeInputType === "editor" && (
                  <div className={classes.outputbody}>
                    <Editor
                      onChange={(val) => setInputEditorValue(val)}
                      value={inputEditorValue}
                    />
                  </div>
                )}
                {activetab === "body" && activeInputType === "text" && (
                  <InputTable
                    envs={inputList}
                    setEnvs={(val) => handleInputTableChange(val)}
                  />
                )}
                {activetab === "header" && (
                  <InputTable
                    envs={headerList}
                    setEnvs={(val) => handleHeaderTableChange(val)}
                  />
                )}
                {activetab === "query_params" && (
                  <InputTable
                    envs={queryParamsList}
                    setEnvs={(val) => handleQueryParamsListChange(val)}
                  />
                )}
                {activetab === "path_params" && (
                  <InputTable
                    envs={pathParamsList}
                    setEnvs={(val) => handlePathParamsListChange(val)}
                  />
                )}
                {activetab === "output" && activeOutputType === "editor" && (
                  <div className={classes.outputbody}>
                    <Editor
                      onChange={(val) => setOutputEditorValue(val)}
                      value={JSON.stringify(microserviceOutputBody, null, "\t")}
                    />
                  </div>
                )}
                {activetab === "output" && activeOutputType === "text" && (
                  <JSONToTable jsonBody={microserviceOutputBody} />
                )}
              </div>
            </div>
          )}
          {dataObj.servicetype !== "rest" && (
            <div className={classes.container}>
              <div className={classes.line2}>
                <div>
                  <p className={classes.label}>
                    Service Type <text className={classes.star}>*</text>
                  </p>
                  <FormControl className={classes.formControl}>
                    <Select
                      style={{ width: "21.2rem", marginTop: 0 }}
                      value={dataObj.servicetype}
                      onChange={(e) =>
                        handleTextChange("servicetype", e.target.value)
                      }
                      className={classes.select}
                      disabled
                    >
                      {/* <option value="">None</option> */}
                      <MenuItem value={"rest"}>REST</MenuItem>
                      <MenuItem value={"rabbitmq"}>RabbitMq</MenuItem>
                      <MenuItem value={"kafka"}>Kafka</MenuItem>
                      <MenuItem value={"mqtt"}>Mqtt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {props.dependency?.serviceType === "Internal" && (
                  <div className={classes.name}>
                    <p className={classes.label}>
                      Container <text className={classes.star}>*</text>
                    </p>
                    <FormControl className={classes.formControl}>
                      <Select
                        style={{ width: "21.2rem", marginTop: 0 }}
                        value={dataObj.containerName}
                        onChange={(e) =>
                          handleTextChange("containerName", e.target.value)
                        }
                        className={classes.select}
                      >
                        {containers.map((container) => (
                          <MenuItem value={container?.container_name}>
                            {container?.container_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className={classes.line2}>
                <div className={classes.name}>
                  <p className={classes.label}>
                    Topic <text className={classes.star}>*</text>
                  </p>
                  <input
                    style={{
                      color: "rgba(0, 0, 0, 0.38)",
                      borderColor: errorObj.topic ? "red" : "",
                    }}
                    className={classes.value}
                    type={"text"}
                    name="topic"
                    value={dataObj.topic}
                    disabled
                    onChange={(e) => handleTextChange("topic", e.target.value)}
                  />
                  {errorObj.topic ? (
                    <text className={classes.error_text}>Invalid Topic</text>
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.name}>
                  <p className={classes.label}>
                    Message <text className={classes.star}>*</text>
                  </p>
                  <input
                    style={errorObj.message ? { borderColor: "red" } : {}}
                    className={classes.value}
                    type={"text"}
                    name="message"
                    value={dataObj.message}
                    onChange={(e) =>
                      handleTextChange("message", e.target.value)
                    }
                  />
                  {errorObj.message ? (
                    <text className={classes.error_text}>Invalid Message</text>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={() => props.onCloseClick()}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
          />
          <AppButton
            onClick={handleSubmitClick}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  selectApiInstance,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  return { userInfo };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulateModalForMicroservice);
