const templateColumns = [
    { name: 'name', title: 'Name', for: 'template' },
    { name: 'description', title: 'Description', for: 'template' },
    { name: 'uiApplication', title: 'UI Application', for: 'template' },
    { name: 'createdon', title: 'Created On', for: 'template' },
    // { name: 'createdby', title: 'Created By', for: 'template' },
    {
      name: 'action',
      title: 'Action',
      for: 'template',
    },
  ];
  
  const templateDefaultColumnWidths = [
    { columnName: 'name', weightage: 2.5, width: 250 },
    { columnName: 'description', weightage: 2.5, width: 250 },
    { columnName: 'uiApplication', weightage: 2.5, width: 250 },
    { columnName: 'createdon', weightage: 2.5, width: 250 },
    // { columnName: 'createdby', weightage: 2.5, width: 250 },
    { columnName: 'action', weightage: 2.5, width: 250 },
  ];
  
  const templateTableColumnExtensions = [
    { columnName: 'name', align: 'left' },
    { columnName: 'description', align: 'left' },
    { columnName: 'uiApplication', align: 'left' },
    { columnName: 'createdon', align: 'left' },
    // { columnName: 'createdby', align: 'left' },
    { columnName: 'action', align: 'center', sortingEnabled: false },
  ];
  
  const templateColumnOrder = [
    'name',
    'description',
    'uiApplication',
    'createdon',
    // 'createdby',
    'action',
  ];
  
  const templateColumnHidden = [
    'name',
    'description',
    'uiApplication',
    'createdon',
    // 'createdby',
    'action',
  ];
  
  const templateDefaultSorting = [];
  
  export {
    templateColumns,
    templateDefaultColumnWidths,
    templateTableColumnExtensions,
    templateColumnOrder,
    templateDefaultSorting,
    templateColumnHidden,
  };
  