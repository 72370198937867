import React from 'react'
import Input from '../../components/atoms/input/Input'
import FormWrapper from './FormWrapper'
import TextArea from '../../components/atoms/textArea/TextArea'
import MultipleSelect from '../../components/atoms/dropdown/select/MultipleSelect'
import Select from '../../components/atoms/dropdown/select/Select'
import Toggle from '../../components/atoms/toggle/Toggle'
import UploadImage from '../../components/atoms/uploadImage/UploadImage'
import UploadFile from '../../components/atoms/uploadfile/UploadFile'
import { v4 as uuid } from 'uuid'
import DatePicker from '../../components/atoms/DatePicker/DatePicker'
import { Grid } from '@material-ui/core'

export const whichCompToRender = (
  col,
  index,
  selectedComponent,
  handleChangeQuery,
  fieldArrQuery,
  error,
  classes,
) => {
  const labelCss = {
    fontSize: selectedComponent?.fontSize ? selectedComponent?.fontSize : 16,
    fontWeight: selectedComponent?.style?.bold ? 'bold' : '',
    fontStyle: selectedComponent?.style?.italic ? 'italic' : '',
    textDecoration: selectedComponent?.style?.underline ? 'underline' : '',
  }
  switch (col?.type) {
    case 'text':
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <Input
            name={col.name}
            label={col.title}
            placeholder={col.placeholder ? col.placeholder : ''}
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            selectedComponent={selectedComponent}
            isRequired={col?.isRequired}
            disabled={col?.disabled?.dataValue === 'true'}
            type={'text'}
          />
        </FormWrapper>
      )
    case 'textarea':
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <TextArea
            name={col?.name}
            rows={col?.rows}
            placeholder={col.placeholder ? col.placeholder : ''}
            label={col?.title}
            value={fieldArrQuery[col.name]}
            selectedComponent={selectedComponent}
            onChange={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            isRequired={col?.isRequired}
            disabled={col?.disabled?.dataValue === 'true'}
          />
        </FormWrapper>
      )
    case 'select':
      const columns = selectedComponent?.columns?.find((v) => v?.id === col?.id)
      if (!columns?.options?.length) fieldArrQuery[col?.name] = ''
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label className={classes.fieldHeading} style={{ ...labelCss }}>
            {col?.title} {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          <Select
            arr={selectedComponent?.columns
              .find((c) => c.id === col.id)
              .options.filter((filtered) => filtered.type === 'select')}
            width="auto"
            name={col?.name}
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            disabled={col?.disabled?.dataValue === 'true'}
            selectedComponent={selectedComponent}
          />
          {error[col?.name] && (
            <p
              style={{ fontSize: selectedComponent?.fontSize ? selectedComponent?.fontSize : 16 }}
              className={classes.errorMsg}
            >{`${col?.title ? col?.title : 'Field'} is required`}</p>
          )}
        </FormWrapper>
      )
    case 'multiselect':
      let cols = selectedComponent?.columns?.find((v) => v?.id === col?.id)
      if (!cols?.options?.length) {
        fieldArrQuery[col?.name] = ''
      }
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label className={classes.fieldHeading} style={{ ...labelCss }}>
            {col?.title} {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          <MultipleSelect
            arr={selectedComponent?.columns
              ?.find((c) => c.id === col.id)
              .options.filter((filtered) => filtered.type === 'multiselect')}
            value={Array.isArray(fieldArrQuery[col?.name]) ? fieldArrQuery[col?.name] : []}
            name={col?.name}
            onChange={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            disabled={col?.disabled?.dataValue === 'true'}
            selectedComponent={selectedComponent}
          />
          {error[col?.name] && (
            <p
              style={{ fontSize: selectedComponent?.fontSize ? selectedComponent?.fontSize : 16 }}
              className={classes.errorMsg}
            >{`${col?.title ? col?.title : 'Field'} is required`}</p>
          )}
        </FormWrapper>
      )
    case 'toggle':
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <label className={classes.fieldHeading} style={{ ...labelCss }}>
            {col?.title} {col?.isRequired === true && <span className={classes.red}>*</span>}
          </label>
          <Toggle
            name={col?.name}
            checked={
              typeof fieldArrQuery[col.name] !== 'boolean' ||
              typeof fieldArrQuery[col.name] !== 'string'
                ? false
                : fieldArrQuery[col.name]
            }
            onChange={(event) => handleChangeQuery(event, col)}
            disabled={col?.disabled?.dataValue === 'true'}
          />
        </FormWrapper>
      )
    case 'image':
      if (
        typeof fieldArrQuery[col?.name] === 'boolean' ||
        !fieldArrQuery[col?.name]?.includes('blob')
      ) {
        fieldArrQuery[col?.name] = ''
      }
      return (
        <FormWrapper>
          <Grid item xs={12}>
            <label style={{ ...labelCss }} className={classes.fieldHeading}>
              {col?.title} {col?.isRequired === true && <span className={classes.red}>*</span>}
            </label>
            <UploadImage
              handleImage={(event) => handleChangeQuery(event, col)}
              col={col}
              selectedImage={fieldArrQuery[col?.name]}
              disabled={col?.disabled?.dataValue === 'true'}
            />
            {error[col?.name] && (
              <p
                style={{ fontSize: selectedComponent?.fontSize ? selectedComponent?.fontSize : 16 }}
                className={classes.errorMsg}
              >{`${col?.title ? col?.title : 'Field'} is required`}</p>
            )}
          </Grid>
        </FormWrapper>
      )
    case 'file':
      if (
        typeof fieldArrQuery[col?.name] === 'boolean' ||
        !fieldArrQuery[col?.name]?.includes('blob')
      ) {
        fieldArrQuery[col?.name] = ''
      }
      return (
        <FormWrapper>
          <label style={{ ...labelCss }} className={classes.fieldHeading}>
            {col?.title} {col?.isRequired && <span className={classes.red}>*</span>}
          </label>
          <UploadFile
            handleFile={(event) => handleChangeQuery(event, col)}
            col={col}
            selectedFile={fieldArrQuery[col?.name]}
            error={error[col?.name]}
            disabled={col?.disabled?.dataValue === 'true'}
            selectedComponent={selectedComponent}
          />
          {error[col?.name] && (
            <p
              style={{ fontSize: selectedComponent?.fontSize ? selectedComponent?.fontSize : 16 }}
              className={classes.errorMsg}
            >{`${col?.title ? col?.title : 'Field'} is required`}</p>
          )}
        </FormWrapper>
      )
    case 'datepicker':
      return (
        <FormWrapper>
          <label className={classes.fieldHeading} style={{ ...labelCss }}>
            {col?.title} {col?.isRequired && <span className={classes.red}>*</span>}
          </label>
          <DatePicker
            selectedDate={fieldArrQuery[col?.name] ? fieldArrQuery[col?.name] : new Date()}
            name={col?.name}
            label={col?.title}
            handleChangeDate={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            isRequired={col?.isRequired}
            disabled={col?.disabled?.dataValue === 'true'}
            selectedComponent={selectedComponent}
          />
          {error[col?.name] && (
            <p className={classes.errorMsg}>{`${col?.title ? col?.title : 'Field'} is required`}</p>
          )}
        </FormWrapper>
      )
    default:
      return (
        <FormWrapper selectedComponent={selectedComponent}>
          <Input
            selectedComponent={selectedComponent}
            name={col.name}
            label={col.title}
            placeholder={col.placeholder ? col.placeholder : ''}
            value={fieldArrQuery[col.name]}
            onChange={(event) => handleChangeQuery(event, col)}
            error={error[col?.name]}
            disabled={col?.disabled?.dataValue === 'true'}
            type={col?.type}
            isRequired={col?.isRequired}
          />
        </FormWrapper>
      )
  }
}

export const updateColumns = (selectedComponent, currentPage, id) => {
  const cols = Object.keys(
    selectedComponent?.data?.dataValue[0]
      ? selectedComponent?.data?.dataValue[0]
      : selectedComponent?.data?.dataValue,
  ).map((col) => ({
    id: uuid(),
    name: col,
    isHide: false,
    isDelete: false,
    placeholder: '',
    type: 'text',
    title: col.charAt(0).toUpperCase() + col.slice(1),
    width: '',
    rows: 2,
    options: [],
    // isEditable: true,
    isRequired: false,
  }))
  const components = currentPage.components.map((component) => {
    if (component.id === id) {
      return {
        ...component,
        columns: cols,
      }
    } else {
      return component
    }
  })
  return {
    components,
    cols,
  }
}
