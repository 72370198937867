import React, { useState } from "react";
import { AppButton } from "../common/ButtonOld";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
// import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
// import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import {
  FileCopyOutlined,
  DeleteOutlineRounded,
  ErrorOutlined,
  GetAppOutlined,
} from "@material-ui/icons";

import { postFieldsBulkUpload } from "../../utils/dataStandards/fieldsUtils";

export const useStyles = makeStyles((theme) => ({
  customModal: {
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },

  file: {
    display: "flex",
    marginTop: "12px",
    marginLeft: "10px",

    "& span": {
      marginLeft: "10px",
    },
  },

  fileDelete: {
    display: "flex",
    marginTop: "12px",
    justifyContent: "flex-end",
    marginRight: "10px",
  },

  modalTitle: {
    textAlign: "center",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: "0px",
    color: "#222725",
    opacity: "1",
  },
  modalHeading: {
    textAlign: "center",
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: "0px",
    color: "#212624",
  },

  modalFileContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 6px #00000014",
    borderRadius: "6px",
    opacity: "1",
    marginTop: "20px",
    width: "553px",
    height: "50px",
    justifyContent: "space-between",
  },
  modalSample: {
    marginTop: "20px",
    width: "553px",
    height: "168px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #21212114",
    border: "1px solid #F0F0F0",
    borderRadius: "6px",
    opacity: "1",
  },
  modalIcon: {
    //background: "#002855 0% 0% no-repeat padding-box",
    // marginTop: "20px",
    marginLeft: "16px",
    opacity: "1",
    width: "16px",
    height: "16px",
  },
  modalBlue: {
    // marginTop: "20px",
    marginLeft: "10px",
    textAlign: "center",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: `${theme.palette.primary.main}`,
    opacity: "1",
  },

  // modalSampleImage: {
  //   background:
  //     "transparent url('img/Image 9.png')' 0% 0% no-repeat padding-box",
  //   opacity: "0.7",
  //   height: "40px",
  // },
  modalSampleDetail: {
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 6px #00000014",
    borderRadius: "0px 0px 6px 6px",
    textAlign: "left",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",

    "& .MuiGrid-item p": {
      marginLeft: "18px",
    },
  },
  modalButton: {
    marginTop: "5px",
    marginLeft: "100px",
    textAlign: "end",
    textTransform: "capitalize",
    width: "162px",
    height: "31px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    border: "1px solid #002855",
    borderRadius: "4px",
    opacity: "1",
    font: "normal normal 600 12px/16px Nunito",
  },
  modelFooter: {
    textAlign: "center",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",
    paddingBottom: "15px",

    "& .MuiGrid-item Button": {
      width: "266px",
      height: "40px",
      textTransform: "capitalize",
      font: "normal normal 600 12px/16px Nunito",
    },
  },
  modalDropzone: {
    "& .documentDropperClass": {
      paddingTop: "3.5rem",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 10,
      textAlign: "center",
      font: "normal normal 600 16px/22px Nunito",
      color: `${theme.palette.primary.main}`,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiDropzoneArea-icon": {
          color: `${theme.palette.primary.main}`,
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          color: `${theme.palette.primary.main}`,
          font: "normal normal 600 16px/22px Nunito",
        },
      },
    },
  },
}));

const CustomDropperIcon = () => (
  <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
);

const BulkUploadModal = (props) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [droppedDocuments, setDroppedDocuments] = useState([]);

  const uploadHandler = () => {
    if (droppedDocuments.length < 1) {
      enqueueSnackbar("No file uploaded!", {
        variant: "error",
      });
    } else {
      postBulkUpload(droppedDocuments[0]);
    }
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/BulkUploadTemplate.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const postBulkUpload = async (doc) => {
    setLoading(true);

    var formData = new FormData();
    // formData.append(
    //   "meta",
    //   JSON.stringify({ name: doc[0].name, docType: doc[0].file.type })
    // );
    const renameFile = new File([doc.file], doc.name);
    formData.append("file", renameFile);

    const { _msg, _status, data } = await postFieldsBulkUpload(formData);
    if (_status === 200 || _status === 201) {
      enqueueSnackbar(_msg ? _msg : "Fields uploaded successfully", {
        variant: "success",
      });
    } else {
      if (_msg === "Invalid Json") {
        if (data) {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      scroll="paper"
      className={styles.customModal}
      open={props.bulkUploadModal}
      onClose={() => {
        props.setbulkUploadModal(false);
      }}
      maxWidth={"md"}
    >
      <DialogTitle>
        <div className={styles.modalTitle}>Bulk Upload</div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.modalHeading}>
              Create multiple fields from CSV or Excel file
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={styles.modalDropzone}>
            {/* <DropzoneArea /> */}
            <DropzoneArea
              Icon={CustomDropperIcon}
              showPreviews={false}
              filesLimit={1}
              dropzoneText="Drop your file here or browse"
              dropzoneClass="documentDropperClass"
              dropzoneParagraphClass="documentDropperTextClass"
              showAlerts={["error"]}
              showPreviewsInDropzone={false}
              onChange={(files) => {
                const temFiles = files.map((file) => ({
                  id: uuidv4(),
                  name: file.name,
                  version: "",
                  file,
                }));
                setDroppedDocuments(temFiles);
              }}
            />
          </Grid>
        </Grid>
        {droppedDocuments.map((item) => (
          <Grid container className={styles.modalFileContainer}>
            <Grid item sm={8} xs={8}>
              <div className={styles.file}>
                <FileCopyOutlined /> <span>{item.name}</span>
              </div>
            </Grid>
            <Grid item sm={3} xs={3}>
              <div
                style={{ cursor: "pointer" }}
                className={styles.fileDelete}
                onClick={() => setDroppedDocuments([])}
              >
                <DeleteOutlineRounded />
              </div>
            </Grid>
          </Grid>
        ))}
        <Grid container className={styles.modalSample}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlined color="primary" className={styles.modalIcon} />
              <label className={styles.modalBlue}>Sample format</label>
            </Grid>
            <Grid item xs={6}>
              <AppButton
                variant="outlined"
                startIcon={<GetAppOutlined />}
                className={`btnsmall`}
                style={{
                  marginLeft: "100px",
                  padding: "0 10px",
                  font: "normal normal 600 12px/16px Nunito",
                }}
                buttonName="Download Sample"
                onClick={handleDownloadTemplate}
              />
            </Grid>
          </div>
          <Grid container className={styles.modalSampleDetail}>
            <Grid item xs={12}>
              <p style={{ height: "40px" }}>Upload .csv file</p>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.modelFooter}>
        <Grid container>
          <Grid item xs={6}>
            <AppButton
              variant="outlined"
              color="primary"
              onClick={() => props.setbulkUploadModal(false)}
              buttonName="Cancel"
              className="btnsmall"
              style={{ margin: "0" }}
            />
          </Grid>
          <Grid item xs={6}>
            <AppButton
              variant="contained"
              color="primary"
              onClick={uploadHandler}
              buttonName="Upload and Create Fields"
              className="btnsmall"
              style={{ margin: "0" }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
