import { Grid, Divider, IconButton, Tooltip } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { useDispatch, useSelector } from 'react-redux'
import { AppButton } from '../../../components/common/Button'
import { v4 as uuidv4 } from 'uuid'
import { StatusProfiles } from '../../../services/statusProfiles'
import useTheme from '@material-ui/core/styles/useTheme'
import { getStatusInfo } from '../../../redux/actions/statusProfileAction'
import Loader from '../../../components/common/stuff/Loader'
import { useSnackbar } from 'notistack'
import useCheckPermission from '../../../components/common/hooks/useCheckPermission'
import { useParams } from 'react-router-dom'
import { fetchDependencies, fetchStatusResourceById } from '../../../utils/status/statusUtils'
import { getIssueDetails } from '../../../redux/actions/helpDesk'
import DependencyModal from '../../../components/common/modals/DependencyModal'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const _StatusProfiles = new StatusProfiles()

const EditStatusProfile = (props) => {
  const styles = manageStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { _id } = useParams()
  const { checkScreenPermission, checkCompPermission } = useCheckPermission()
  const statusDetails = useSelector((state) => state.statusDetails)
  const { loading, status } = statusDetails
  const issueList = useSelector((state) => state.issueAll?.issues?.data)

  const setStatus = useSelector((state) => state.setStatus)
  const { success: successStatus } = setStatus

  const [customcolor, setCustomColor] = useState('')
  let [statusInput, setStatusInput] = useState('')
  /* eslint-disable */
  const [statusCardData, setStatusCardData] = useState([])
  const [statusId, setStatusId] = useState(props.match.params._id)
  const [colorPicker, setColorPicker] = useState('#rrggbb')
  const [colorActive, setcolorActive] = useState('')
  const [requestFlag, setrequestFlag] = useState(false)
  const [flag, setflag] = useState(false)
  const [editedVal, seteditedVal] = useState(null)
  const [errorInput, seterrorInput] = useState(false)
  const [errorFlag, seterrorFlag] = useState(null)
  const [defaultState, setDefaultState] = useState(false)
  const [isUsed, setIsUsed] = useState(false)
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false)
  const [dataDependency, setDataDependency] = useState()
  const [inputDisableFlag, setInputDisableFlag] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [statusDataToDelete, setStatusDataToDelete] = useState()
  useEffect(async () => {
    dispatch(getStatusInfo(statusId))
  }, [statusId, successStatus, requestFlag])

  useEffect(() => {
    if (statusId === 'Helpdesk') {
      dispatch(getIssueDetails())
    }
  }, [])
  const [isStatusDeleteMsg, setStatusDeleteMsg] = useState()

  const handleRemoveStatus = async (val) => {
    try {
      const config = { name: status.name, status: val.status }
      const { data } = await _StatusProfiles.deleteResourceByName(status.name, config)
      if (data) {
        dispatch(getStatusInfo(statusId))
        setStatusInput('')
        setcolorActive('')
        enqueueSnackbar('Removed Status Profile successfully from the Resource Type', {
          variant: 'success',
        })
      }
    } catch (error) {
      const { _msg } = error.response.data
      setStatusDeleteMsg(_msg)
      enqueueSnackbar(
        _msg ? (_msg.length > 100 ? `${_msg.substring(0, 100)}...` : _msg) : 'Something went wrong',
        {
          variant: 'error',
        },
      )
    }
  }
  const [isLoader, setLoader] = useState(false)

  const featchDependenciesData = async (val) => {
    setLoader(true)
    const sName = status.name
    const config = { name: status.name, status: val.status }
    const { _msg, _status, data } = await fetchDependencies(status.name, config)
    setDataDependency(
      data.length > 0
        ? data.map((item) => {
            if (sName === 'Helpdesk') {
              item['title'] = item.issueid
            } else {
              item['title'] = item.name
            }
            item['expanded'] = false
            return item
          })
        : [],
    )
    setDependencyModalOpen(true)
    if (_status === '200') {
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const activeColor = useRef(null)

  const handleSubmit = () => {}
  const circleColorPicker = [
    '#F82329',
    '#34BC41',
    '#00B5AD',
    '#2185D0',
    '#B5CC18',
    '#FBBD08',
    '#F2711C',
    '#6435C9',
    '#E03997',
    '#767676',
  ]
  const handleColorPickerChange = (e) => {
    setColorPicker(e.target.value)
    setCustomColor(e.target.value)
  }
  const handleSelectCircleColor = (color, index) => {
    setCustomColor(color)
    setcolorActive(index)
  }
  const handleAddStatus = async () => {
    if (statusInput === '') {
      seterrorInput(true)
    } else {
      try {
        let colorData = {
          id: uuidv4(),
          title: statusInput,
          color: customcolor,
          isDefault: statusCardData.length ? false : true,
        }
        setStatusCardData([...statusCardData, colorData])
        const config = {
          status: statusInput,
          color: customcolor,
        }
        const { data } = await _StatusProfiles.postResourceByName(
          status.name ? status.name : '',
          config,
        )
        if (data) {
          setStatusInput('')
          setcolorActive('')
          enqueueSnackbar(data._msg ? data._msg : 'Successfully Added', {
            variant: 'success',
          })
          dispatch(getStatusInfo(statusId))
        }
      } catch (error) {
        const { _msg, _status, data } = error.response.data
        if (_msg) {
          enqueueSnackbar(_msg ? _msg : '', {
            variant: 'error',
          })
        }
      }
    }
  }
  const handleClickDefault = async (val) => {
    const config = { resourcetype: status.name, status: val.status }
    const { data } = await _StatusProfiles.makeStatusDefault(config)
    if (data) {
      dispatch(getStatusInfo(statusId))
    }
  }
  const handleEditStatus = (val) => {
    let count = 0
    issueList?.map((item) => {
      if (val?.status === item?.status) count = count + 1
    })
    if (count > 0) {
      setIsUsed(true)
    } else {
      setIsUsed(false)
    }
    if (val?.default) {
      setDefaultState(true)
    } else {
      setDefaultState(false)
    }
    seteditedVal(val)
    setflag(true)
    setStatusInput(val.status)
    const foundColor = circleColorPicker.filter((color, i) => color === val.color)
    circleColorPicker.map((color, index) => {
      if (color === val.color) {
        setcolorActive(index)
        setColorPicker('#rrggbb')
      } else if (foundColor.length === 0) {
        setColorPicker(val.color)
        setcolorActive('')
      }
    })
  }
  // after editing and saving
  const handleSaveEditedStatus = async () => {
    if (isUsed) {
      enqueueSnackbar('State is used', { variant: 'error' })
    } else {
      setInputDisableFlag(true)
      if (statusInput === '') {
        seterrorInput(true)
      } else {
        try {
          const config = {
            status: statusInput,
            color: customcolor ? customcolor : editedVal.color,
          }
          const { data } = await _StatusProfiles.editStatus(
            _id,
            editedVal && editedVal.status,
            config,
          )
          if (data) {
            setflag(false)
            setStatusInput('')
            setcolorActive('')
            setCustomColor('')
            enqueueSnackbar(data._msg ? data._msg : 'Successfully updated', {
              variant: 'success',
            })
            dispatch(getStatusInfo(statusId))
          }
        } catch (error) {
          const { _msg, _status, data } = error.response.data
          if (_msg && statusInput === '') {
            seterrorInput(true)
          } else {
            enqueueSnackbar(_msg ? _msg : 'Something went wrong', {
              variant: 'error',
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    if (successStatus) {
      setStatusInput('')
      setCustomColor('')
      setColorPicker('#rrggbb')
      setcolorActive('')
    }
  }, [successStatus])
  useEffect(() => {
    if (statusInput !== '') {
      seterrorInput(false)
    }
  }, [statusInput])

  const handleChangeStatusInput = (e) => {
    setStatusInput(e.target.value.replace(/^\s+/, ''))
  }

  useEffect(() => {
    if (statusInput.length === 0) {
      setflag(false)
      setcolorActive('')
    }
  }, [statusInput, flag])

  useEffect(() => {
    checkScreenPermission('StatusProfile', ['Edit'])
  }, [])

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true)
  }

  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false)
  }

  const handleDeleteStatusModal = () => {
    setShowDeleteModal(true)
  }

  return (
    <main className={styles.main}>
      <form onSubmit={handleSubmit}>
        {isLoader && <Loader />}
        <div className={styles.topContent}>
          {dependencyModalOpen && (
            <DependencyModal
              // name={restProps.row.name}
              modalTitle={dataDependency.length > 0 ? 'Dependencies' : 'No Dependencies'}
              treeDataArr={dataDependency}
              // row={restProps.row}
              open={dependencyModalOpen}
              handleClose={handleDependencyModalClose}
              // handleDelete={handleDelete}
            />
          )}
          <BreadCrumbs
            items={[
              { title: 'Status Profile', path: '/status-profile' },
              {
                title: `Edit ${status ? status.name : ''} Status`,
                path: '/status-profile',
              },
            ]}
          />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ borderBottom: '2px solid rgb(175, 175, 175)' }}
          >
            <h2 className={styles.heading}>{`${status ? status.name : ''} - Add status`}</h2>
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid container spacing={3} style={{ marginTop: '0rem' }}>
            <Grid item xs={6} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Object Type
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={status && status.name}
                    className={styles.input}
                    style={{ backgroundColor: '#F5F5F5', marginTop: 10 }}
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={styles.fieldItem} style={{marginBottom: '.8rem'}}>
            <h3 className={styles.fieldHeading} style={{ fontSize: 16 }}>
              Status list
            </h3>
            <Divider style={{ height: 2, backgroundColor: 'rgb(175,175,175)' }} />
          </div>
          <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
            <Grid item xs={6} sm={6}>
              <Grid item xs={6} sm={6}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Status Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={statusInput}
                  onChange={handleChangeStatusInput}
                  className={styles.input}
                  autoComplete="off"
                  disabled={inputDisableFlag === false ? true : false}
                />
                {errorInput && statusInput === '' && (
                  <p className={styles.errorMsg}>{'Enter valid Status'}</p>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <h3 className={styles.fieldHeading}>Choose Color</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={styles.circleColorPicker} style={{ display: 'flex' }}>
                    {circleColorPicker.map((color, index) => {
                      return (
                        <div className={colorActive === index ? styles.active : styles.inactive}>
                          <div
                            className={styles.innerCircleColorPicker}
                            style={{
                              backgroundColor: color,
                              cursor: 'pointer',
                            }}
                            key={index}
                            ref={activeColor}
                            id="circleColors"
                            onClick={() => handleSelectCircleColor(color, index)}
                          ></div>
                        </div>
                      )
                    })}
                  </div>
                  <div style={{ display: 'flex', marginLeft: '16px' }}>
                    <p style={{ width: '6rem' }}>Custom Color</p>
                    <input
                      type="color"
                      className={styles.inputTypeColorElement}
                      value={colorPicker}
                      onChange={(e) => {
                        handleColorPickerChange(e)
                      }}
                    />
                  </div>
                </div>
                {flag === false ? (
                  <AppButton
                    buttonName="Add"
                    variant="contained"
                    className={styles.addStatusColorButton}
                    onClick={handleAddStatus}
                    disabled={!checkCompPermission('StatusProfile', ['Create'])}
                    color="primary"
                  />
                ) : (
                  <AppButton
                    buttonName="Save"
                    variant="contained"
                    // disabled={false}
                    className={styles.addStatusColorButton}
                    onClick={handleSaveEditedStatus}
                    style={{
                      textTransform: 'none',
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                    }}
                    disabled={!checkCompPermission('StatusProfile', ['Edit'])}
                  />
                )}
              </Grid>
            </Grid>
            <Divider orientation="vertical" className={styles.MuiCenterDivider} />
            {status ? (
              <Grid item xs={6} sm={6}>
                <div
                  style={{
                    height: '400px',
                    position: 'relative',
                    overflow: 'scroll',
                  }}
                >
                  {status.status &&
                    status.status.map((data, i) => {
                      return (
                        <div key={i} style={{ marginTop: 12, marginLeft: 35 }}>
                          <div style={{ display: 'flex', marginLeft: '25px' }}>
                            <div className={styles.newStatusAddBox}>
                              <div
                                style={{
                                  width: '100%',
                                  padding: '0 12px',
                                  color: data.color,
                                }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item lg={8} md={8} sm={8}>
                                    <Grid
                                      container
                                      alignItems="center"
                                      onClick={() => {
                                        handleEditStatus(data)
                                        setInputDisableFlag(data.Editable)
                                      }}
                                    >
                                      <Grid item>
                                        <span
                                          className={styles.predefinedColorDots}
                                          style={{
                                            backgroundColor: data.color,
                                          }}
                                        ></span>
                                      </Grid>
                                      <Grid item>
                                        <p
                                          style={{
                                            color: data.color,
                                            marginTop: 10.8,
                                          }}
                                        >
                                          {data.status}
                                        </p>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item lg={4} md={4} sm={4}>
                                    <Grid container justifyContent="flex-end">
                                      <Grid item onClick={() => handleClickDefault(data)}>
                                        <p>
                                          {data.default ? (
                                            <span style={{ color: '2185D0' }}>Default</span>
                                          ) : (
                                            <span
                                              className={styles.markAsDefault}
                                              style={{ color: 'grey' }}
                                            >
                                              Mark as default
                                            </span>
                                          )}
                                        </p>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            {data && data.Editable === false ? null : (
                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  color="inherit"
                                  aria-label="delete"
                                  edge="start"
                                  className={styles.actionIcons}
                                  style={{ marginLeft: 12 }}
                                  onClick={() => {
                                    setShowDeleteModal(true)
                                    setStatusDataToDelete(data)
                                  }}
                                >
                                  <img
                                    src="/images/deleteLine.svg"
                                    alt="card"
                                    style={{ filter: 'brightness(0.1)' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {data && data.Editable === false ? null : (
                              <Tooltip title="Dependencies" arrow>
                                <IconButton
                                  color="inherit"
                                  aria-label="dependencies"
                                  edge="start"
                                  className={styles.actionIcons}
                                  style={{ marginLeft: 8 }}
                                  onClick={() => featchDependenciesData(data)}
                                >
                                  <img src="/images/info_icon.svg" alt="dependencies" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </Grid>
            ) : (
              <Grid item xs={6} sm={6}>
                <div style={{ marginLeft: 215, marginTop: 50 }}>
                  <img src="/images/statusProfile.png" alt="kailash" style={{ marginLeft: 29 }} />
                  <h4>No status added yet</h4>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </form>
      {showDeleteModal && (
        <div>
          <Dialog onClose={() => setShowDeleteModal(false)} open={showDeleteModal} maxWidth="lg">
            <DialogContent>
              <div
                style={{
                  height: 200,
                  width: 450,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <img
                    src="/images/governance/delete.svg"
                    alt="lock"
                    style={{ height: 75, width: 75 }}
                  />
                </div>
                <div style={{ marginTop: 30 }}>
                  <p style={{ textAlign: 'center' }}>
                    Are you sure want to delete the Status name{' '}
                    <b>"{statusDataToDelete?.status}"</b>
                    <br />
                    This process cannot be undone.
                  </p>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#F9FAFD' }}>
              <AppButton
                onClick={() => setShowDeleteModal(false)}
                className={`btnsmall`}
                buttonName="Cancel"
                variant="outlined"
              />
              <AppButton
                onClick={() => {
                  handleRemoveStatus(statusDataToDelete)
                  setShowDeleteModal(false)
                }}
                className={`btnsmall`}
                buttonName={'Yes, Delete'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
            </DialogActions>
          </Dialog>
        </div>
      )}
    </main>
  )
}

export default EditStatusProfile
