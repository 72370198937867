import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import DocumentDeleteModal from "../modals/DeleteModal";
import { useSnackbar } from "notistack";
import useCheckPermission from "../hooks/useCheckPermission";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import devExpressStyles from "../styles/DevExpressTableStyles";
import { deleteDeploymentById } from "../../../redux/actions/deploymentProcessAction";
import { deleteDeployment } from "../../../utils/deployment/deploymentUtils";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ViewIcon from "@material-ui/icons/Visibility";
import DeleteModal from "../modals/DeleteModal";
import Loader from "../stuff/Loader";
import {
  deleteCloudConfiguration,
  editCloudConfig,
} from "../../../utils/organization/organizationUtils";
import {
  deleteCloudConfigById,
  featchAllCloudConfigurationActions,
  updateCloudConfigById,
} from "../../../redux/actions/organizationAction";
import CloudConfigurationModal from "../modals/CloudConfigurationModal";

const DefaultActionCell = ({ ...restProps }) => {
  const histroy = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const { userInfo } = useSelector((state) => state.userDetails);
  const { checkCompPermission } = useCheckPermission();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isCloudConfigurationEditMode, setIsCloudConfigurationEditMode] =
    useState(false);
  const [selectedData, setSelectedData] = useState();

  const [isLoader, setIsLoader] = useState(false);
  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async ({ row }) => {
    setIsLoader(true);
    setDeleteModalOpen(false);
    const { _msg, _status, data } = await deleteCloudConfiguration(
      restProps?.row._id
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(deleteCloudConfigById(restProps.row._id));
      setDeleteModalOpen(false);
      setIsLoader(false);
    } else {
      setIsLoader(false);
      setDeleteModalOpen(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleEdit = () => {
    setEditModalOpen(true);
    setSelectedData(restProps.row);
  };

  const handleSubmitCloudData = async (cloudConfigData, action, id) => {
    setIsLoader(true);
    if (action === "update") {
      const { data, _status, _msg } = await editCloudConfig(
        id,
        cloudConfigData
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        setIsLoader(false);
        // dispatch(featchAllCloudConfigurationActions(1))
        dispatch(updateCloudConfigById(cloudConfigData));
      } else {
        enqueueSnackbar(_msg, {
          variant: "error",
        });
        setIsLoader(false);
      }
    }
  };

  return isLoader ? (
    <Loader />
  ) : (
    <>
      <CloudConfigurationModal
        openModal={editModalOpen}
        handleSubmitCloudData={handleSubmitCloudData}
        // setOpenModal={() => {setCloudConfigModal(false); setIsCloudConfigurationEditMode(false); setSelectedData(null);}}
        setOpenModal={() => {
          setEditModalOpen(false);
          setSelectedData(null);
        }}
        AWSRegionList={restProps?.row.AWSRegionList}
        isEditMode={editModalOpen && true}
        cloudData={selectedData}
      />

      <div className={styles.actionIcons}>
        {deleteModalOpen && (
          <DeleteModal
            name={restProps.row.configurationname}
            row={restProps.row}
            open={deleteModalOpen}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        )}
        <Tooltip title="Edit" arrow>
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={`${styles.actionIcon} ${styles.editMode}`}
            onClick={handleEdit}
            disabled={restProps.row.isDetailMode}
          >
            {/* <ViewIcon />sn */}
            <img
              style={{ opacity: restProps.row.isDetailMode && 0.3 }}
              src="/images/editLine.svg"
              alt="card"
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={handleDeleteModalClickOpen}
            disabled={restProps.row.isDetailMode}
          >
            <img
              style={{ opacity: restProps.row.isDetailMode && 0.3 }}
              src="/images/deleteLine.svg"
              alt="card"
            />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Cell = (props) => {
  const { column } = props;
  if (column.name === "createdon" || column.name === "updatedon") {
    return (
      <VirtualTable.Cell {...props}>
        <DateCell {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "action") {
    return (
      <VirtualTable.Cell {...props}>
        <DefaultActionCell {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} />;
  }
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const CloudConfigDevExpress = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 5) {
    setHiddenColumnNames(hideCols);
    //   props.setHidden(hideCols);
    // }
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 400
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default CloudConfigDevExpress;
