import {
  ADD_DATA_MODEL_FILES,
  ADD_DATA_MODEL_LIST,
  ALTER_SUBSCRIBE_DATA_MODEL_BY_ID,
  BLOCK_UNBLOCK_DATAMODEL,
  BLOCK_UNBLOCK_DATA_MODEL,
  DATA_MODEL_FILTERS,
  DATA_MODEL_HIDDEN,
  DELETE_DATA_MODEL_BY_ID,
  SET_DATA_MODEL_SORT,
  ADD_AI_MODEL_DETAILS,
  DELETE_DATA_MODEL_FILE,
  ADD_AI_MODEL_FILES,
  CLEAR_AI_MODEL
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  aiModelDetails:{},
  dataModelsFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  tab: 0,
  currentdataModelsPage: 1,
  currentFieldPage: 1,
  dataModelsSort: [{ columnName: "createdon", direction: "desc" }],
  dataModelsHidden: ["name", "state", "createdby", "createdon", "actions"],
  dataModels: { data: [], _msg: null },
  filedetails: { data: [], msg: null },
  loading: false,
};

const dataModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AI_MODEL_DETAILS:{
     return { 
      ...state,
      aiModelDetails:action.payload,
     }
    }
    case ADD_AI_MODEL_FILES:{
      return {
       ...state,
       aiModelDetails:{...state.aiModelDetails,filesdetails:action?.payload?.filesdetails} 
      }
    }
    case DELETE_DATA_MODEL_FILE:{
      return {
       ...state,
       aiModelDetails:{...state.aiModelDetails,filesdetails:state.aiModelDetails?.filesdetails.filter((item)=>item.filename !== action.filename)},

      }
     }
    case ADD_DATA_MODEL_LIST:
      return {
        ...state,
        dataModels: { ...state.dataModels, data: action.payload, deleteDataModelStatus: false, },
        loading: false,
      };
    case BLOCK_UNBLOCK_DATA_MODEL: {
     
      return {
        ...state,
        dataModels: {
          ...state.dataModels,
          data:
            state.dataModels.data &&
            state.dataModels.data.map((item) => {
              if (item._id === action.payload.dataModelID) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case ADD_DATA_MODEL_FILES: {
      return {
        ...state,
        filedetails: [...action.payload],
      };
    }
    case DATA_MODEL_HIDDEN: {
      return {
        ...state,
        dataModelsHidden: [...action.payload],
      };
    }
    case ALTER_SUBSCRIBE_DATA_MODEL_BY_ID: {
      return {
        ...state,
        dataModels: {
          ...state.dataModels,
          data: {
            ...state.dataModels.data,
            data:
              state.dataModels.data.data.length !== 0 &&
              state.dataModels.data.data.map((proj) => {
                if (proj._id === action.payload.dataModelId) {
                  return { ...proj, isSubscribe: !proj.isSubscribe };
                } else {
                  return proj;
                }
              }),
          },
        },
      };
    }

    case BLOCK_UNBLOCK_DATAMODEL: {
      return {
        ...state,
        dataModels: {
          ...state.dataModels,
          data: {
            ...state.dataModels.data,
            data:
              state.dataModels.data &&
              state.dataModels.data.data.map((item) => {
                if (item._id === action.payload.dataModelID) {
                  return {
                    ...item,
                    state: action.payload.status,
                  };
                } else {
                  return item;
                }
              }),
          },
        },
      };
    }

    case DATA_MODEL_FILTERS: {
      return {
        ...state,
        dataModelsFilters: [...action.payload],
      };
    }

    case SET_DATA_MODEL_SORT: {
      return {
        ...state,
        dataModelsSort: [...action.payload],
      };
    }

    case DELETE_DATA_MODEL_BY_ID: {
      return {
        ...state,
        dataModels: {
          ...state.dataModels,
          data: {
            ...state.dataModels.data,
            data: state.dataModels.data.data.filter(
              (serv) => serv._id !== action.payload.dataModelId
            ),
          _totalcount: state.dataModels.data._totalcount - 1,

          },
          deleteDataModelStatus: true,
        },
      };
    }
    case CLEAR_AI_MODEL:{
      return {
        ...state,
        aiModelDetails:{},

      }
    }

    default:
      return state;
  }
};

export default dataModelReducer;
