import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import listStyles from "../../CommonStyles/listScreenStyles";
import {
  addDeployContainer,
  fetchDeploymentById,
} from "../../../redux/actions/deploymentProcessAction";
import { ArrowDropDownRounded, VolumeDown } from "@material-ui/icons";

import { v4 as uuidv4 } from "uuid";
import {
  getApplicationDataByID,
  getImageList,
} from "../../../utils/orchestration/applicationUtils";
import { fetchVersionById } from "../../../utils/project/microserviceUtils";

const DetailContainer = (props) => {
  const {
    data,
    fetchDeploymentById,
    deploymentname,
    deploymenttype,
    deployments,
    deptypeid,
    deploymentDetailsList,
  } = props;

  const { deploymentId, ContainerName } = props.match.params;
  const [serviceType, setServiceType] = useState();
  const [deploymentRows, setDeploymentRows] = useState();
  const [containerDetails, setContainerDetails] = useState();
  const [containerName, setContainerName] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [serviceEditable, setServiceEditable] = useState(false);
  const [myEnvs, setMyEnvs] = useState();
  const [newEnvs, setnewEnvs] = useState();
  const styles = listStyles();
  const style = manageStyles();
  const histroy = useHistory();
  const [submitData, setSubmitData] = useState();
  const [myKey, setMyKey] = useState();
  const [myValue, setMyValue] = useState();
  const [envFormData, setEnvFormData] = useState({});
  const [volumeData, setVolumeData] = useState({});
  const [newContainerData, setNewContainerData] = useState({});
  const [restartPolicy, setRestartPolicy] = useState({});
  const [sizingData, setSizingData] = useState({});
  const [FTPData, setFTPData] = useState();
  const fileSystems = ["ext4"];
  const [envData, setEnvData] = useState();
  const [envs, setEnvs] = useState([
    { id: uuidv4(), key: "", value: "", enabled: true },
  ]);
  const [volume, setVolume] = useState();
  const [showFileSystemType, setShowFileSystemType] = useState();
  const [containers, setContainers] = useState();
  const [isDependency, setIsDependency] = useState();
  const [replicas, setReplicas] = useState(1);
  const [sizing, setSizing] = useState({
    limits: { cpu: "", memory: "" },
    requests: { cpu: "", memory: "" },
  });
  useEffect(() => {});
  const handleRestartToggle = (event) => {
    event.preventDefault();

    let newRestartPolicy;
    if (event.target.checked !== false) {
      newRestartPolicy = "always";
    } else {
      newRestartPolicy = "";
    }
    setRestartPolicy(newRestartPolicy);
    newContainerData["RestartPolicy"] = restartPolicy;
    let restartStatus;
    if (newContainerData.RestartPolicy) {
      if (newContainerData.RestartPolicy === "always") {
        restartStatus = true;
      } else {
        restartStatus = false;
      }
    } else {
      if (selectedContainerDetails?.RestartPolicy === "always") {
        restartStatus = true;
      } else {
        restartStatus = false;
      }
    }
  };

  const handleContainerDetailsEdit = (event) => {
    event.preventDefault();
    const contFieldName = event.target.getAttribute("name");
    const contFieldValue = event.target.value;
    const newNewContainerData = { ...newContainerData };
    newNewContainerData[contFieldName] = contFieldValue;
    setNewContainerData(newNewContainerData);
  };

  const handleFTPChange = (event) => {
    event.preventDefault();
    const newFTP = { ...FTPData };
    newFTP[event.target.name] = event.target.value;
    setFTPData(event.target.value);

    volumeData["filesystemtype"] = event.target.value;
  };

  const handleVolumeChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newVolumeData = { ...volumeData };
    newVolumeData[fieldName] = fieldValue;
    newVolumeData["filesystemtype"] = FTPData;
    setVolumeData(newVolumeData);
  };

  // deploymentDetailsList?.map((item)=>{
  // })

  useEffect(() => {
    deployments?.map((item) => {
      if (item.container_name === ContainerName) {
        if (item.dependencies === true) {
          setIsDependency(true);
        }
        setReplicas(item?.replicas);
      }
    });
  }, [deployments]);

  useEffect(() => {
    fetchDeploymentById(deploymentId);
  }, [deploymentId]);

  let envkeyVal = [];
  let selectedContainerDetails;

  data?.map((item) => {
    if (item.ContainerName === ContainerName) {
      selectedContainerDetails = item;
      Object.entries(item?.Environment).map((k, v) => {
        envkeyVal.push({ id: uuidv4(), key: k[0], value: k[1] });
      });
    }
  });
  useEffect(() => {
    deployments?.map((item) => {
      if (item?.container_name === ContainerName) {
        setVolume(item);
      }
    });
  }, []);

  useEffect(() => {
    data?.map((item) => {
      if (item.ContainerName == ContainerName) {
        if (item?.Type === "internal") {
          setServiceEditable(false);
        } else {
          setServiceEditable(true);
        }
      }
    });
  }, [data]);

  useEffect(() => {
    setMyEnvs(envkeyVal);
    setEnvData(envkeyVal);
    setEnvs(envkeyVal);
  }, [data]);

  useEffect(() => {
    setDeploymentRows(
      data?.length > 0 && data ? data?.map((item) => item) : []
    );
  }, [data]);

  const handleSave = () => {
    histroy.push(`/host/details/${deploymentId}`);
  };

  const handleContainerDetailsChange = (e) => {
    let val = e.target.value;
    setContainerName(...containerName, ...val);
  };
  const handlekeyChange = (e, column) => {
    setMyKey(([e.target.name] = e.target.value));
  };

  const handleValChange = (e, column) => {
    setMyValue(([e.target.name] = e.target.value));
  };

  const handleAddNewEnvs = () => {
    let newKeyVal = { id: uuidv4(), key: myKey, value: myValue };
    setnewEnvs({ id: uuidv4(), key: myKey, value: myValue });
    envkeyVal.push(newEnvs);
    setMyEnvs(envkeyVal);
    newKeyVal = {};
  };

  useEffect(() => {
    setMyEnvs([...envkeyVal, newEnvs]);
  }, [newEnvs]);

  const handleCancelEditContainer = () => {
    histroy.push(`/host/details/${deploymentId}`);
  };

  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "", enabled: true }]);
  };
  if (envs.length === 0) {
    handleAddEnv();
  }
  const handleEnvChange = (event, id, column) => {
    if (column === "enabled") {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.checked };
        } else {
          return env;
        }
      });
    } else {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.value };
        } else {
          return env;
        }
      });
    }

    setEnvs(newEnvs);
  };

  const EnvironmentVariable = (field) => {
    return (
      <div>
        <TableContainer>
          <Table
            className={styles.customTable}
            style={{ backgroundColor: "#fff" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell align="left">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {envs.map((row, index) => {
                if(row.key.includes("pwd")  || row.key.includes("password") || row.key.includes("credentials") || row.key.includes("pass")){
                return <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <input
                      type="text"
                      placeholder="Key"
                      name={row.id}
                      className={styles.input2}
                      value={row.key}
                      onChange={(e) =>
                        handleEnvChange(
                          {
                            ...row,
                            key: e.target.value,
                          },
                          index
                        )
                      }
                      autoComplete="off"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="password"
                      placeholder="Value"
                      name={row.id}
                      className={styles.input2}
                      value={row.value}
                      onChange={(e) =>
                        handleEnvChange(
                          { ...row, value: e.target.value },
                          index
                        )
                      }
                      autoComplete="off"
                      disabled={true}
                    />
                  </TableCell>
           
                </TableRow>
                }
              })}
            </TableBody>
            {envs.length === 0 && (
              <div className={styles.nodata}>
                <text>No Data Available</text>
              </div>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  };
  const handleEditContainer = () => {
    histroy.push(`/host/container/edit2/${deploymentId}/${ContainerName}`);
  };

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Deployment List", path: "/host" },
            { title: deploymentname, path: `/host/details/${deploymentId}` },
            { title: "Edit " + ContainerName },
          ]}
        />

        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {isEditMode ? ` Edit ${ContainerName}` : `${ContainerName}`}
          </h2>
          {serviceEditable && (
            <AppButton
              color="primary"
              buttonName="Edit"
              variant="contained"
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleEditContainer}
            />
          )}
        </Grid>
      </div>
      <Paper className={styles.pageContent}>
        <div>
          <Grid
            container
            spacing={4}
            // xs={12}
            style={{ marginTop: "0rem", width: "100%", display: "flex" }}
          >
            {" "}
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Container Name{" "}
                </label>
                <input
                  type="text"
                  name="container_name"
                  className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                  value={
                    newContainerData.container_name
                      ? newContainerData.container_name
                      : selectedContainerDetails?.ContainerName
                  }
                  autoComplete="off"
                  disabled={!isEditMode}
                  onChange={handleContainerDetailsEdit}
                />
              </>
            </Grid>
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Port{" "}
                </label>
                <input
                  type="text"
                  name="port"
                  className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                  disabled={!isEditMode}
                  value={
                    newContainerData.port
                      ? newContainerData.port
                      : selectedContainerDetails?.Port
                  }
                  autoComplete="off"
                  onChange={handleContainerDetailsEdit}
                />
              </>
            </Grid>
            <Grid item xs={2} sm={3}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Replica{" "}
                </label>
                <input
                  type="text"
                  name="replicas"
                  className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                  disabled={!isEditMode}
                  value={replicas}
                  autoComplete="off"
                  onChange={handleContainerDetailsEdit}
                />
              </>
            </Grid>
            <Grid item xs={1} sm={1}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Restart{" "}
                </label>
                <br />
                <Checkbox
                  color="primary"
                  name="restart"
                  checked={
                    newContainerData.RestartPolicy
                      ? newContainerData.RestartPolicy
                      : selectedContainerDetails?.RestartPolicy &&
                        selectedContainerDetails?.RestartPolicy === "Always"
                      ? true
                      : false
                  }
                  onChange={handleRestartToggle}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  className={`${isEditMode} ? ${style.Checkbox} : ${style.Checkbox2}`}
                  disabled={!isEditMode}
                />
              </>
            </Grid>
            <Grid item xs={2} sm={5}>
              <>
                <label htmlFor="" className={style.fieldHeading}>
                  Image ID{" "}
                </label>
                <input
                  type="text"
                  name="image"
                  className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                  disabled={!isEditMode}
                  value={
                    newContainerData.image
                      ? newContainerData.image
                      : selectedContainerDetails?.ImageName
                  }
                  autoComplete="off"
                  onChange={handleContainerDetailsEdit}
                />
              </>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>
                Environment Variables
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{EnvironmentVariable()}</AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>Volume</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {deployments &&
                deployments.map((item) => {
                  if (item.container_name === ContainerName) {
                    return (
                      <Grid
                        container
                        spacing={4}
                        // xs={12}
                        style={{
                          marginTop: "0rem",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        {" "}
                        <Grid item xs={2} sm={4}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Name{" "}
                            </label>
                            <input
                              type="text"
                              name="name"
                              className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                              disabled={!isEditMode}
                              value={
                                volume?.Volumes?.name
                                  ? volume?.Volumes?.name
                                  : ""
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                          </>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Mount path{" "}
                            </label>
                            <input
                              type="text"
                              name="path"
                              className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                              disabled={!isEditMode}
                              value={
                                volume?.Volumes?.mountpath
                                  ? volume?.Volumes?.mountpath
                                  : ""
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                          </>
                        </Grid>
                        <Grid item xs={2} sm={3}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              File System Type{" "}
                            </label>
                            <Select
                              name="filesystemtype"
                              className={`${isEditMode} ? ${style.select} : ${style.select3}`}
                              disabled={!isEditMode}
                              onChange={handleFTPChange}
                              value={FTPData}
                            >
                              {fileSystems?.map((item) => {
                                return (
                                  <MenuItem
                                    name="filesystemtype"
                                    value={item}
                                    key={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Identifier{" "}
                            </label>
                            <input
                              type="text"
                              name="volumeid"
                              className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                              disabled={!isEditMode}
                              value={
                                volume?.Volumes?.identifier
                                  ? volume?.Volumes?.identifier
                                  : ""
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                          </>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <>
                            <label htmlFor="" className={style.fieldHeading}>
                              Volume Type{" "}
                            </label>
                            <input
                              type="text"
                              name="volumetype"
                              className={`${isEditMode} ? ${style.input} : ${style.input2}`}
                              disabled={!isEditMode}
                              value={
                                volume?.Volumes?.volumetype
                                  ? volume?.Volumes?.volumetype
                                  : ""
                              }
                              autoComplete="off"
                              onChange={handleVolumeChange}
                            />
                          </>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion className={style.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={style.accordianIcon} />
              }
            >
              <Typography className={style.sectionHeading}>Sizing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {deployments &&
                deployments.map((item) => {
                  if (item.container_name === ContainerName) {
                    return (
                      <Grid container spacing={3}>
                        <Grid item sm={12}>
                          <Accordion defaultExpanded>
                            <AccordionSummary>
                              <Typography
                                className={style.sectionHeading}
                                style={{ marginLeft: "20px" }}
                              >
                                {" "}
                                {"   "}RAM
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                sm={12}
                                xs={12}
                                spacing={3}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Grid item xs={8}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={style.fieldHeading}
                                    >
                                      Minimum
                                    </label>

                                    <input
                                      disabled
                                      type="text"
                                      placeholder="Min"
                                      name="RAMmin"
                                      className={styles.input2}
                                      value={
                                        sizing?.requests?.memory
                                          ? sizing?.requests?.memory
                                          : item?.resources?.requests?.memory
                                      }
                                      onChange={(e) =>
                                        setSizing({
                                          ...sizing,
                                          requests: {
                                            ...sizing?.requests,
                                            memory: e.target.value,
                                          },
                                        })
                                      }
                                      autoComplete="off"
                                    />

                                    <span style={{ marginLeft: 20 }}>
                                      MB
                                      <Switch
                                        disabled
                                        name="memorytype"
                                        color="default"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />{" "}
                                      GB
                                    </span>
                                  </div>
                                </Grid>

                                <Grid item xs={8}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={style.fieldHeading}
                                    >
                                      Maximum
                                    </label>

                                    <input
                                      disabled
                                      type="text"
                                      placeholder="Max"
                                      name="RAMmax"
                                      className={styles.input2}
                                      value={
                                        sizing?.limits?.memory
                                          ? sizing?.limits?.memory
                                          : item?.resources?.limits?.memory
                                      }
                                      onChange={(e) =>
                                        setSizing({
                                          ...sizing,
                                          limits: {
                                            ...sizing.limits,
                                            memory: e.target.value,
                                          },
                                        })
                                      }
                                      autoComplete="off"
                                    />

                                    <span style={{ marginLeft: 20 }}>
                                      MB
                                      <Switch
                                        disabled
                                        name="memorytype"
                                        color="default"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />{" "}
                                      GB
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item sm={12}>
                          <Accordion defaultExpanded>
                            <AccordionSummary>
                              <Typography
                                className={style.sectionHeading}
                                style={{ marginLeft: "20px" }}
                              >
                                CPU
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={style.fieldHeading}
                                    >
                                      Minimum
                                    </label>

                                    <input
                                      disabled
                                      type="text"
                                      placeholder="Min"
                                      name="cpu"
                                      className={styles.input2}
                                      value={
                                        sizing?.requests?.cpu
                                          ? sizing?.requests?.cpu
                                          : item?.resources?.requests?.cpu
                                      }
                                      onChange={(e) =>
                                        setSizing({
                                          ...sizing,
                                          requests: {
                                            ...sizing.requests,
                                            cpu: e.target.value,
                                          },
                                        })
                                      }
                                      autoComplete="off"
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={6}>
                                  <div>
                                    <label
                                      htmlFor=""
                                      className={style.fieldHeading}
                                    >
                                      Maximum
                                    </label>

                                    <input
                                      disabled
                                      type="text"
                                      placeholder="Max"
                                      name="CPUmax"
                                      className={styles.input2}
                                      value={
                                        sizing?.limits?.cpu
                                          ? sizing?.limits?.cpu
                                          : item?.resources?.limits?.cpu
                                      }
                                      onChange={(e) =>
                                        setSizing({
                                          ...sizing,
                                          limits: {
                                            ...sizing.limits,
                                            cpu: e.target.value,
                                          },
                                        })
                                      }
                                      autoComplete="off"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
    </main>
  );
};

const mapDispatchToProps = {
  fetchDeploymentById,
};
const mapStateToProps = (state) => {
  const { deploymentDetails, deployments, deptypeid } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { deploymentname, deploymenttype } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { deploymentDetailsList } = state.deploymentProcessReducer;

  return {
    data: deploymentDetails,
    deploymentname,
    deploymenttype,
    deployments,
    deptypeid,
    deploymentDetailsList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailContainer);
