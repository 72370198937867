import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import RuntimeENVDetailsEntity from "../../../components/common/entitySchema/containerManagement/runtimeENV/RuntimeENVDetailsEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { getRuntimeEnvDetailsByID } from "../../../utils/containerMangement/runtimeEnvUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EnvDetailsScreen = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const [envID] = useState(props.match.params._id);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [runtimeENVEntitySchema, setruntimeENVEntitySchema] = useState();
  const [runtimeENVdata, setruntimeENVdata] = useState("");
  const [envList, setEnvList] = useState({});

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("RuntimeEnvironment", ["Read"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchruntimeENVEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "RuntimeEnvironment",
        "read"
      );
      if (_status === 200) {
        setruntimeENVEntitySchema(data);
        fetchruntimeENVdata();
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setruntimeENVdata({ ...runtimeENVdata, ...tempData });
        setLoading(false);
      } else {
        setLoading(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchruntimeENVdata = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getRuntimeEnvDetailsByID(envID);
      if (_status === 200) {
        var tempServices = data?.services?.map((item) => ({
          ...item,
          id: uuidv4(),
        }));
        setruntimeENVdata(() => (data ? data : []));
        setEnvList(tempServices);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchruntimeENVEntitySchema();
  }, []);

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/runtime-environments");
  };
  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Runtime Environments",
              path: "/runtime-environments",
            },
            {
              title: `${runtimeENVdata?.name ? runtimeENVdata?.name : ""}`,
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}

        >
          <h2 className={styles.title}>{runtimeENVdata.name}</h2>
          {tabbedView ? (
            <TabbedNavigation
              isClose={true}
              schemaLength={runtimeENVEntitySchema?.length}
              isEditMode={false}
              activeTab={activeTab}
              handleEdit={() => histroy.push(`/edit-environment/${envID}`)}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              isClose={true}
              isEditMode={true}
              handleEdit={() => histroy.push(`/edit-environment/${envID}`)}
              handleCancel={handleCancel}
              disabled={!checkCompPermission("RuntimeEnvironment", ["Edit"])}
            />

          )}
        </Grid>
        {/* <div className={styles.border_line} /> */}
      </div>

      <div
        className={styles.wrapper}
        style={tabbedView ? { top: "6.5rem" } : { top: "7rem" }}
      >
        {tabbedView ? (
          <>
            <SectionTabs
              entity={
                runtimeENVEntitySchema ? runtimeENVEntitySchema.sections : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <RuntimeENVDetailsEntity
                  section={runtimeENVEntitySchema?.sections?.[activeTab]}
                  runtimeENVdata={runtimeENVdata}
                  envList={envList}
                  setEnvList={setEnvList}
                />
              </div>
            </Paper>
          </>
        ) : (
          runtimeENVEntitySchema?.sections &&
          runtimeENVEntitySchema?.sections.map((section) => {
            if (section.name === "Environment_Header_section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <RuntimeENVDetailsEntity
                    section={section}
                    runtimeENVdata={runtimeENVdata}
                    envList={envList}
                    setEnvList={setEnvList}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RuntimeENVDetailsEntity
                      section={section}
                      runtimeENVdata={runtimeENVdata}
                      envList={envList}
                      setEnvList={setEnvList}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(EnvDetailsScreen);
