const boilerPlateColumns = [
  {
    name: "BoilerplateName",
    title: "Boiler plate Name",
    route: "boilerplate-details",
    for: "boilerplate",
  },
  { name: "languagename", title: "Language", for: "boilerplate" },
  { name: "createdby", title: "Created By", for: "boilerplate" },
  { name: "createdon", title: "Created On", for: "boilerplate" },
  {
    name: "boilerplateactions",
    title: "Action",
    route: "/edit-boilerplate",
  },
];

const boilerPlateDefaultColumnWidths = [
  { columnName: "BoilerplateName", weightage: 2, width: 200 },
  { columnName: "languagename", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 1, width: 100 },
  { columnName: "createdon", weightage: 1, width: 100 },
  { columnName: "boilerplateactions", weightage: 2, width: 200 },
];

const boilerPlateTableColumnExtensions = [
  { columnName: "BoilerplateName", align: "left" },
  { columnName: "languagename", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "boilerplateactions", align: "center", sortingEnabled: false },
];

const boilerPlateColumnOrder = [
  "BoilerplateName",
  "languagename",
  "createdby",
  "createdon",
];

const boilerplateColumnHidden = [
  "BoilerplateName",
  "languagename",
  "createdby",
  "createdon",
  "boilerplateactions",
];

const boilerPlateDefaultSorting = [];

export {
  boilerPlateColumns,
  boilerPlateDefaultColumnWidths,
  boilerPlateTableColumnExtensions,
  boilerPlateColumnOrder,
  boilerPlateDefaultSorting,
  boilerplateColumnHidden,
};
