import React from 'react'

const CustomTheme = ({color,btnColor, tabbedView}) => {
    if(tabbedView){
        return (
            <svg xmlns="http://www.w3.org/2000/svg"  width="900" height="515" viewBox="0 0 900 515">
            <defs>
                <filter id="Rectangle_8978" x="0" y="0" width="900" height="515" filterUnits="userSpaceOnUse">
                    <feOffset dy="4" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="8" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="clip-path">
                    <rect width="47" height="253" fill="none" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect width="47" height="261" fill="none" />
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="270" height="253" fill="none" />
                </clipPath>
                <clipPath id="clip-path-4">
                    <rect width="173" height="253" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_23562" data-name="Group 23562" transform="translate(-383 -237)">
                <g transform="matrix(1, 0, 0, 1, 383, 237)" filter="url(#Rectangle_8978)">
                    <rect id="Rectangle_8978-2" data-name="Rectangle 8978" width="852" height="467" transform="translate(24 20)" fill="#f2f2f2" />
                </g>
                <rect id="Rectangle_8979" data-name="Rectangle 8979" width="852" height="39" transform="translate(407 257)" fill={color} />
                <g id="Rectangle_8980" data-name="Rectangle 8980" transform="translate(437 370)" fill="#fff" stroke="#e8e8e8" strokeWidth="1">
                    <rect width="775" height="324" stroke="none" />
                    <rect x="0.5" y="0.5" width="774" height="323" fill="none" />
                </g>
                <path id="Path_38227" data-name="Path 38227" d="M0,0H775.2V27.464H0Z" transform="translate(436.749 370.455)" fill={color} />
                <rect id="Rectangle_8982" data-name="Rectangle 8982" width="80" height="10" rx="5" transform="translate(442 314)" fill="#d0d0d0" />
               
               

                
               
                <rect id="Rectangle_8983" data-name="Rectangle 8983" width="152" height="19" rx="9.5" transform="translate(442 334)" fill="#d0d0d0" />
                <rect id="Rectangle_8983" data-name="Rectangle 8983" width="152" height="19" rx="9.5" transform="translate(602 334)" fill="#d0d0d0" />
                <rect id="Rectangle_8983" data-name="Rectangle 8983" width="152" height="19" rx="9.5" transform="translate(762 334)" fill="#d0d0d0" />
                <rect id="Rectangle_8983" data-name="Rectangle 8983" width="152" height="19" rx="9.5" transform="translate(922 334)" fill="#d0d0d0" />



                <rect id="Rectangle_8985" data-name="Rectangle 8985" width="94" height="29" rx="5" transform="translate(1118 324)" fill={color} />
                <text id="Header" transform="translate(802 282)" fill="#fff" fontSize="18" fontFamily="Nunito-Bold, Nunito" fontWeight="700"><tspan x="0" y="0">Tabbed</tspan></text>
                <text id="Button" transform="translate(1146 343)" fill="#fff" fontSize="12" fontFamily="Nunito-Regular, Nunito"><tspan x="0" y="0">Button</tspan></text>
            </g>
        </svg> 
        )
    } else {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="900" height="515" viewBox="0 0 900 515">
            <defs>
                <filter id="Rectangle_8978" x="0" y="0" width="900" height="515" filterUnits="userSpaceOnUse">
                    <feOffset dy="4" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="8" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="clip-path">
                    <rect width="47" height="253" fill="none" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect width="47" height="261" fill="none" />
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="270" height="253" fill="none" />
                </clipPath>
                <clipPath id="clip-path-4">
                    <rect width="173" height="253" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_23562" data-name="Group 23562" transform="translate(-383 -237)">
                <g transform="matrix(1, 0, 0, 1, 383, 237)" filter="url(#Rectangle_8978)">
                    <rect id="Rectangle_8978-2" data-name="Rectangle 8978" width="852" height="467" transform="translate(24 20)" fill="#f2f2f2" />
                </g>
                <rect id="Rectangle_8979" data-name="Rectangle 8979" width="852" height="39" transform="translate(407 257)" fill={color} />
                <g id="Rectangle_8980" data-name="Rectangle 8980" transform="translate(437 370)" fill="#fff" stroke="#e8e8e8" strokeWidth="1">
                    <rect width="775" height="324" stroke="none" />
                    <rect x="0.5" y="0.5" width="774" height="323" fill="none" />
                </g>
                <path id="Path_38227" data-name="Path 38227" d="M0,0H775.2V27.464H0Z" transform="translate(436.749 370.455)" fill={color} />
                <rect id="Rectangle_8982" data-name="Rectangle 8982" width="80" height="10" rx="5" transform="translate(442 314)" fill="#d0d0d0" />
                <g id="Repeat_Grid_22" data-name="Repeat Grid 22" transform="translate(482 415)" clipPath="url(#clip-path)">
                    <g transform="translate(-387 -385)">
                        <rect id="Rectangle_8984" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -358)">
                        <rect id="Rectangle_8984-2" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -331)">
                        <rect id="Rectangle_8984-3" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -304)">
                        <rect id="Rectangle_8984-4" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -277)">
                        <rect id="Rectangle_8984-5" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -250)">
                        <rect id="Rectangle_8984-6" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -223)">
                        <rect id="Rectangle_8984-7" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -196)">
                        <rect id="Rectangle_8984-8" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -169)">
                        <rect id="Rectangle_8984-9" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -142)">
                        <rect id="Rectangle_8984-10" data-name="Rectangle 8984" width="45" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                </g>
                <g id="Repeat_Grid_25" data-name="Repeat Grid 25" transform="translate(1153 415)" clip-path="url(#clip-path-2)">
                    <g transform="translate(-387 -385)">
                        <rect id="Rectangle_8984-11" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -358)">
                        <rect id="Rectangle_8984-12" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -331)">
                        <rect id="Rectangle_8984-13" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -304)">
                        <rect id="Rectangle_8984-14" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -277)">
                        <rect id="Rectangle_8984-15" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -250)">
                        <rect id="Rectangle_8984-16" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -223)">
                        <rect id="Rectangle_8984-17" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -196)">
                        <rect id="Rectangle_8984-18" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -169)">
                        <rect id="Rectangle_8984-19" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -142)">
                        <rect id="Rectangle_8984-20" data-name="Rectangle 8984" width="24" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                </g>
                <g id="Repeat_Grid_24" data-name="Repeat Grid 24" transform="translate(825 415)" clipPath="url(#clip-path-3)">
                    <g transform="translate(-387 -385)">
                        <rect id="Rectangle_8984-21" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -385)">
                        <rect id="Rectangle_8984-22" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -358)">
                        <rect id="Rectangle_8984-23" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -358)">
                        <rect id="Rectangle_8984-24" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -331)">
                        <rect id="Rectangle_8984-25" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -331)">
                        <rect id="Rectangle_8984-26" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -304)">
                        <rect id="Rectangle_8984-27" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -304)">
                        <rect id="Rectangle_8984-28" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -277)">
                        <rect id="Rectangle_8984-29" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -277)">
                        <rect id="Rectangle_8984-30" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -250)">
                        <rect id="Rectangle_8984-31" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -250)">
                        <rect id="Rectangle_8984-32" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -223)">
                        <rect id="Rectangle_8984-33" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -223)">
                        <rect id="Rectangle_8984-34" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -196)">
                        <rect id="Rectangle_8984-35" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -196)">
                        <rect id="Rectangle_8984-36" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -169)">
                        <rect id="Rectangle_8984-37" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -169)">
                        <rect id="Rectangle_8984-38" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -142)">
                        <rect id="Rectangle_8984-39" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-225 -142)">
                        <rect id="Rectangle_8984-40" data-name="Rectangle 8984" width="93" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                </g>
                <g id="Repeat_Grid_23" data-name="Repeat Grid 23" transform="translate(570 415)" clipPath="url(#clip-path-4)">
                    <g transform="translate(-387 -385)">
                        <rect id="Rectangle_8984-41" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -358)">
                        <rect id="Rectangle_8984-42" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -331)">
                        <rect id="Rectangle_8984-43" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -304)">
                        <rect id="Rectangle_8984-44" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -277)">
                        <rect id="Rectangle_8984-45" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -250)">
                        <rect id="Rectangle_8984-46" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -223)">
                        <rect id="Rectangle_8984-47" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -196)">
                        <rect id="Rectangle_8984-48" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -169)">
                        <rect id="Rectangle_8984-49" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                    <g transform="translate(-387 -142)">
                        <rect id="Rectangle_8984-50" data-name="Rectangle 8984" width="171" height="11" rx="5.5" transform="translate(387 385)" fill="#e6e6e6" />
                    </g>
                </g>
                <rect id="Rectangle_8983" data-name="Rectangle 8983" width="152" height="19" rx="9.5" transform="translate(442 334)" fill="#d0d0d0" />
                <rect id="Rectangle_8985" data-name="Rectangle 8985" width="94" height="29" rx="5" transform="translate(1118 324)" fill={color} />
                <text id="Header" transform="translate(802 282)" fill="#fff" fontSize="18" fontFamily="Nunito-Bold, Nunito" fontWeight="700"><tspan x="0" y="0">Header</tspan></text>
                <text id="Button" transform="translate(1146 343)" fill="#fff" fontSize="12" fontFamily="Nunito-Regular, Nunito"><tspan x="0" y="0">Button</tspan></text>
            </g>
        </svg>
    )
}
}

export default CustomTheme
