import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    // height : 600,
    width: "100%",
    // border : "1px solid black",
    marginTop: "1rem",
    marginLeft: "1rem",
  },
  root: {
    // flexGrow: 1,
    // marginTop : '6rem',
    margin: "1px solid black",
    width: "60%",
    height: 35,
    "& div": {
      "& div": {
        height: 35,
        "& button": {
          minHeight: 0,
          fontSize: "14px",
          textTransform: "none",
          "& span ": {
            "& .MuiTab-root": {
              textTransform: "lowercase",
              fontSize: "14px",
            },
          },
        },
      },
    },
  },
  tab_container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginRight: 5
    // marginLeft: "11rem",
  },
  toggleicons: {
    margin: "0px 0px",
    cursor: "pointer",
    height: 29,
    width: 42,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      padding: 1,
      borderRadius: 1,
      color: "#fff",
    },
    "&.toggleActive": {
      // background: "#002855 0% 0% no-repeat padding-box",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      // padding: 5,
      margin: "0 0px",
      opacity: 1,
      "& img": {
        filter: "brightness(0) invert(1) ",
      },
    },
    "&.toggleInactive": {
      backgroundColor: "white",
      "& img": {
        filter: "brightness(1) invert(0) ",
        height: 29,
        width: 42,
      },
    }
  },
  line5: {
    // marginLeft: 20,
    // marginRight: 20,
    marginTop: 25,
  },
  outputbody: {
    // height: 100,
    // width: 600,
    borderWidth: 2,
    borderColor: "black",
    marginTop: 5,
    "& .CodeMirror-scroll": {
      // height: "10rem",
      marginRight: 0,
      marginBottom: 0,
    },
    // flex: 1,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  output: {
    height: "10rem",
  },
}))