import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import layoutStyles from "../../../styles/commonStyles/Layout";
import Search from "../../atoms/search/Search";
import { v4 as uuid } from "uuid";
import {
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  Popover,
  Typography,
  Tooltip,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { AppButton } from "../../atoms/button/AppButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addPage,
  deletePage,
  editPage,
  removeLinkedUiApplicationId,
} from "../../../../redux/actions/uiApplicationAction";
import { useSnackbar } from "notistack";
import TextFieldCustom from "../../atoms/TextField/TextFieldCustom";
import TextArea from "../../atoms/textArea/TextArea";
import useGetData from "../../../hooks/useGetData";
import ImportTemplateModal from "../../organisms/ImportTemplateModal";
import CreateTemplateModal from "../../organisms/CreateTemplateModal";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { setUiApplicationListData } from "../../../../redux/actions/uiApplicationListAction";

const Pages = ({ expandCanvas }) => {
  const {
    currentProject,
    currentPage,
    currentComponent,
    updatePageForLinking,
  } = useGetData();
  const uiAppsList = useSelector(
    (state) => state.uiApplicationListReducer.data
  );
  const { enqueueSnackbar } = useSnackbar();
  const styles = layoutStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [oldPageName, setOldPageName] = useState("");
  const [onDeletePage, setOnDeletePage] = useState(null);
  const [pageSearch, setPageSearch] = useState("");
  const [pageData, setPageData] = useState({
    id: "",
    name: "",
    url: "",
    description: "",
    islandingpage: false,
  });

  const [modal, setModal] = useState({ open: true, type: "Add" });
  const [error, setError] = useState({
    url: "",
    name: "",
    landingPageErr: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setError({
      ...error,
      [name]:
        !Boolean(value?.match("^[a-zA-Z0-9]+$")) &&
        name !== "description" &&
        value !== ""
          ? `Invalid ${name === "url" ? "Url" : "Name"}`
          : "",
    });
    setPageData({
      ...pageData,
      [name]: value,
    });
  };
  const handleLandingPage = ({ target: { name, checked } }) => {
    setPageData({
      ...pageData,
      [name]: checked,
    });
  };

  const apiService = new UiApplicationService();

  const handleClick = (page) =>
    history.push(`/ui-applications/${currentProject?.id}/${page.url}`);

  const handlePage = (action) => {
    setAnchorEl(null);
    const invalidUrl = !Boolean(pageData?.url?.match("^[a-zA-Z0-9]+$"));
    const foundName =
      modal.type === "Add"
        ? currentProject.pages.some((page) => page.name === pageData.name)
        : currentProject.pages.some(
            (page) => page.id !== pageData.id && page.name === pageData.name
          );
    const foundUrl =
      modal.type === "Add"
        ? currentProject.pages.some((page) => page.url === pageData.url)
        : currentProject.pages.some(
            (page) => page.id !== pageData.id && page.url === pageData.url
          );

    let tempError = { ...error };
    if (!pageData.name) {
      tempError = {
        ...tempError,
        name: "Page Name is required",
      };
    } else if (foundName) {
      tempError = {
        ...tempError,
        name: "Page Name already exists",
      };
    }
    if (!pageData.url) {
      tempError = {
        ...tempError,
        url: "Page Url is required",
      };
    } else if (foundUrl) {
      tempError = {
        ...tempError,
        url: "Page Url already exists",
      };
    } else if (invalidUrl) {
      tempError = {
        ...tempError,
        url: "Invalid Url",
      };
    }
    setError(tempError);
    if (
      pageData.name &&
      pageData.url &&
      !foundName &&
      !foundUrl &&
      !error.url &&
      !error.name
    )
      action();
  };

  const checkLandingPage = () =>
    !pageData.islandingpage
      ? currentProject?.pages.some(
          (page) => page.id !== pageData.id && page.islandingpage
        )
      : true;

  const deactivateOldLandingPage = async () => {
    try {
      const { components, ...oldLandingPage } = currentProject?.pages.find(
        (page) => page?.islandingpage
      );
      const resp = await apiService?.editPage(currentProject?.id, {
        newdetails: { ...oldLandingPage, islandingpage: false },
        oldpagename: oldLandingPage?.name,
      });
      dispatch(
        editPage({
          pageid: oldLandingPage.id,
          data: {
            name: oldLandingPage.name,
            url: oldLandingPage.url,
            description: oldLandingPage.description,
            islandingpage: false,
          },
        })
      );
    } catch (err) {
      enqueueSnackbar(err?.response?.data?._msg, { variant: "error" });
    }
  };

  const updateUiAppListData = (updatedPages) => {
    const updatedUiAppList = uiAppsList.map((uiApp) =>
      currentProject?._id === uiApp?._id
        ? { ...uiApp, pages: updatedPages }
        : uiApp
    );
    dispatch(setUiApplicationListData({ data: updatedUiAppList }));
  };
  const handleAdd = async () => {
    let PageToAdd = {
      id: uuid(),
      name: pageData.name.trim(),
      url: pageData.url.trim(),
      description: pageData.description,
      islandingpage: pageData.islandingpage,
      components: [],
      projectName: currentProject?.name,
      projectId: currentProject?.id,
    };
    if (pageData?.islandingpage) deactivateOldLandingPage();
    const resp = await apiService?.addNewPage(currentProject?.id, PageToAdd);
    resp?.status < 210
      ? dispatch(addPage({ data: PageToAdd }))
      : enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "failed to add page",
          { variant: "error" }
        );
    setModal({
      ...modal,
      open: true,
    });
    setPageData({
      ...pageData,
      islandingpage: false,
    });
    const pagesToModify = [...currentProject?.pages, PageToAdd];
    if (pagesToModify?.length) updateUiAppListData([...pagesToModify]);
    updatePageForLinking(pagesToModify);
  };

  const handleEdit = async () => {
    if (checkLandingPage()) {
      setError({
        ...error,
        landingPageErr: "",
      });
      try {
        if (
          pageData.islandingpage &&
          pageData.id !==
            currentProject?.pages.find((page) => page?.islandingpage).id
        )
          deactivateOldLandingPage();
        const reqData = {
          name: pageData.name.trim(),
          url: pageData.url.trim(),
          description: pageData.description,
          islandingpage: pageData.islandingpage,
        };
        const resp = await apiService?.editPage(currentProject?.id, {
          newdetails: reqData,
          oldpagename: oldPageName,
        });
        enqueueSnackbar(resp?.data?._msg, { variant: "success" });
        dispatch(
          editPage({
            pageid: pageData.id,
            data: reqData,
          })
        );
        const pagesToModify = currentProject.pages.map((page) =>
          pageData.id === page.id ? { ...page, ...pageData } : page
        );
        if (pagesToModify?.length) updateUiAppListData([...pagesToModify]);
        setModal({
          ...modal,
          open: true,
        });
        setPageData({
          ...pageData,
          islandingpage: false,
        });
      } catch (err) {
        enqueueSnackbar(err?.response?.data?._msg, { variant: "error" });
      }
    } else {
      setError({
        ...error,
        landingPageErr: "One Landing page is required",
      });
    }
  };
  const handleDeletePage = async () => {
    if (onDeletePage?.islandingpage) {
      enqueueSnackbar("Landing Page can not be deleted", { variant: "error" });
    } else {
      const pageComps = await apiService?.fetchAllComponents(
        currentProject?.id,
        onDeletePage?.name
      );
      const resp = await apiService?.deletePage(
        currentProject?.id,
        onDeletePage?.name
      );
      if (resp?.status < 210) {
        pageComps?.data?.data.forEach((comp) => {
          if (comp.type === "Button" || comp.type === "Link")
            dispatch(removeLinkedUiApplicationId(comp?._id));
          else if (comp.type === "Menu") {
            comp?.options.forEach((menuLevel1) => {
              if (menuLevel1.linkedUiApp?.id !== undefined) {
                dispatch(removeLinkedUiApplicationId(menuLevel1?.id));
              }
              menuLevel1?.children?.forEach((menuLevel2) => {
                if (menuLevel2.linkedUiApp?.id !== undefined) {
                  dispatch(removeLinkedUiApplicationId(menuLevel2?.id));
                }
                menuLevel2?.submenuChildren?.forEach((menuLevel3) => {
                  if (menuLevel3.linkedUiApp?.id !== undefined) {
                    dispatch(removeLinkedUiApplicationId(menuLevel3?.id));
                  }
                });
              });
            });
          }
        });
        dispatch(deletePage({ name: onDeletePage?.name }));
        setOnDeletePage(null);
        setDeleteModalOpen(false);
        if (currentProject?.linkedUiApplications !== undefined) {
          const _uiAppResp = await apiService?.editApplication(
            currentProject?._id,
            {
              ...currentProject,
              ["pages"]: currentProject.pages.filter(
                (page) => onDeletePage?.name !== page?.name
              ),
            }
          );
        }
      } else {
        enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "Failed to delete page",
          { variant: "error" }
        );
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopOver = (event) => setAnchorEl(event.currentTarget);
  const handleClosepopOver = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : "";
  const [templateModal, setTemplateModal] = useState(false);
  const handleTemplateModalOpen = () => {
    setTemplateModal(true);
    setAnchorEl(null);
  };
  const handleTemplateModalClose = () => setTemplateModal(false);

  return (
    <div
      className={styles.leftbar}
      style={{
        borderRight: "1px solid #E8E8E8",
        transform: !expandCanvas ? "translate(0%,0%)" : "translate(-121%, 0%)",
      }}
    >
      {modal.open ? (
        <Grid className="pages">
          <Grid item style={{ margin: 8 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <p className={"title"}>
                  Pages ({currentProject?.pages.length})
                </p>
              </Grid>
              <Grid item>
                <AppButton
                  buttonName="Add"
                  startIcon={<AddOutlinedIcon />}
                  className="btnsmall"
                  style={{ minWidth: "70px" }}
                  aria-describedby={id}
                  variant="outlined"
                  onClick={handleOpenPopOver}
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosepopOver}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    style={{
                      padding: "10px 75px 5px 10px",
                      cursor: "pointer",
                      border: "1px solid #D8D8D8",
                    }}
                    onClick={() => setModal({ open: false, type: "Add" })}
                  >
                    Blank Page
                  </Typography>
                  <Typography
                    style={{
                      padding: "10px 75px 5px 10px",
                      cursor: "pointer",
                      border: "1px solid #D8D8D8",
                    }}
                    onClick={handleTemplateModalOpen}
                  >
                    Copy from
                  </Typography>
                </Popover>
              </Grid>
            </Grid>
            {templateModal && (
              <ImportTemplateModal
                open={templateModal}
                handleClose={handleTemplateModalClose}
              />
            )}
          </Grid>
          <Grid item className="search">
            <Search
              value={pageSearch}
              onChange={(e) => setPageSearch(e.target.value)}
              placeholder={"Search Pages"}
            />
          </Grid>

          {currentProject?.pages
            .filter((page) => page?.name.includes(pageSearch))
            .map((page) => (
              <Grid item key={page?.name}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => handleClick(page)}
                  className={`listItem ${
                    page?.url === params?.page ? "activeItem" : ""
                  }`}
                  style={{ cursor: "pointer", height: "auto" }}
                >
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {page?.islandingpage && (
                        <Tooltip title="Home Page">
                          <HomeOutlined />
                        </Tooltip>
                      )}
                      <Tooltip title={page?.name}>
                        <h2
                          style={{
                            width: page.islandingpage ? "100px" : "120px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {page?.name}
                        </h2>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Edit Page">
                      <IconButton
                        style={{ padding: 6 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal({ open: false, type: "Edit" });
                          setOldPageName(page?.name);
                          setPageData({ ...page });
                        }}
                        disabled={page?.url === params?.page}
                      >
                        <img src="/img/edit.svg" alt="Edit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Page">
                      <IconButton
                        style={{ padding: 6 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOnDeletePage(page);
                          setDeleteModalOpen(true);
                        }}
                        disabled={page?.url === params?.page}
                      >
                        <img src="/img/delete-bin-7-line.svg" alt="Delete" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid item>
          <div className={styles.addPage}>
            <div style={{ marginBottom: 5 }} className="topContent">
              <span style={{ fontWeight: "bold", color: "#212121" }}>
                {modal.type === "Edit" ? "Edit Page" : "New Page"}
              </span>
              <span>
                {modal.type === "Edit" && (
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#002853",
                      cursor: "pointer",
                    }}
                    onClick={handleTemplateModalOpen}
                  >
                    Add as Template
                  </span>
                )}
              </span>
              {templateModal && (
                <CreateTemplateModal
                  open={templateModal}
                  handleClose={handleTemplateModalClose}
                  modal={modal}
                  setModal={setModal}
                  pageData={pageData}
                />
              )}
            </div>
            <div>
              <span>Page Name</span>
              <TextFieldCustom
                value={modal?.type === "Edit" ? pageData?.name : null}
                onChange={handleChange}
                disabled={undefined}
                name="name"
              />
              {error?.name && <p className="errorMsg">{error?.name}</p>}
            </div>
            <div>
              <span>Page URL</span>
              <div className="urlField">
                <div className="slash">/</div>
                <TextFieldCustom
                  value={modal?.type === "Edit" ? pageData.url : undefined}
                  onChange={handleChange}
                  disabled={currentPage?.id === pageData?.id}
                  name="url"
                />
              </div>
              {error?.url && <p className="errorMsg">{error?.url}</p>}
            </div>
            <div>
              <span>Page Description</span>
              <TextArea
                value={
                  modal?.type === "Edit" ? pageData.description : undefined
                }
                onChange={handleChange}
                disabled={undefined}
                name="description"
                rows={2}
              />
            </div>
            <FormControlLabel
              label={<span style={{ fontSize: 12 }}>Landing Page</span>}
              control={
                <Checkbox
                  checked={
                    modal?.type === "Edit" ? pageData.islandingpage : undefined
                  }
                  name="islandingpage"
                  color="primary"
                  onChange={handleLandingPage}
                  size="small"
                />
              }
            />
            {error?.landingPageErr && (
              <p className="errorMsg">{error?.landingPageErr}</p>
            )}
            <div>
              <div className={styles.buttons}>
                <Button
                  color="primary"
                  onClick={() => {
                    setModal({ ...modal, open: true });
                    setPageData({
                      id: "",
                      name: "",
                      url: "",
                      islandingpage: false,
                    });
                    setError({
                      url: "",
                      name: "",
                      landingPageErr: "",
                    });
                    setAnchorEl(null);
                  }}
                >
                  Cancel
                </Button>
                {modal?.type === "Edit" ? (
                  <Button
                    variant="outlined"
                    onClick={() => handlePage(handleEdit)}
                  >
                    Save
                  </Button>
                ) : modal?.type === "Add" ? (
                  <Button
                    variant="outlined"
                    onClick={() => handlePage(handleAdd)}
                  >
                    Add Page
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Grid>
      )}
      {deleteModalOpen && (
        <div>
          <Dialog
            onClose={() => setDeleteModalOpen(false)}
            open={deleteModalOpen}
            maxWidth="lg"
          >
            <DialogContent>
              <div
                style={{
                  height: 200,
                  width: 450,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/images/governance/delete.svg"
                    alt="lock"
                    style={{ height: 75, width: 75 }}
                  />
                </div>
                <div style={{ marginTop: 30 }}>
                  <p style={{ textAlign: "center" }}>
                    All changes made so far to <b>{onDeletePage?.name}</b> will
                    be lost, <br />
                    This process cannot be undone.
                  </p>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
              <AppButton
                onClick={() => setDeleteModalOpen(false)}
                className={`btnsmall`}
                buttonName="Cancel"
                variant="outlined"
              />
              <AppButton
                onClick={handleDeletePage}
                className={`btnsmall`}
                buttonName={"Yes, Delete"}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              />
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Pages;
