import React from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";

import useStyles from "../styles/InputTableStyle";

export default function InputTableWithDisable(props) {
  const styles = useStyles();
  const { envs } = props;

  const handleEnvChange = (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event.target.value };
      } else {
        return env;
      }
    });
    props.setEnvs(newEnvs);
  };

  const handleAddEnv = (id) => {
    props.setEnvs([...envs, { id: uuidv4(), key: "", value: "" }]);
  };

  const handleDeleteEnv = (envToDelete) => {
    if (envs?.length > 1) {
      props.setEnvs((prevEnvs) =>
        prevEnvs.filter(
          (env) => env.id !== envToDelete && prevEnvs.length !== 1
        )
      );
    }
  };
  return (
    <div>
      <TableContainer>
        <Table
          className={styles.customTable}
          style={{ backgroundColor: "#fff" }}
        >
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              <TableCell>Key</TableCell>
              <TableCell align="left">Value</TableCell>
              {/* <TableCell align="right">Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {envs &&
              envs?.length > 0 &&
              envs?.map((row, index) => (
                <TableRow key={index}>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="#002855"
                      disabled={!props.isEditMode}
                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                      // checked={rowCount > 0 && numSelected === rowCount}
                      // onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    <input
                      type="text"
                      placeholder="Enter key"
                      name={row.id}
                      disabled={!props.isEditMode}
                      className={`${styles.input} ${
                        !props.isEditMode && styles.editMode
                      }`}
                      value={envs[index].key}
                      onChange={(e) => handleEnvChange(e, row.id, "key")}
                      autoComplete="off"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="text"
                      placeholder="Enter Value"
                      name={row.id}
                      disabled={!props.isEditMode}
                      className={`${styles.input} ${
                        !props.isEditMode && styles.editMode
                      }`}
                      value={envs[index].value}
                      onChange={(e) => handleEnvChange(e, row.id, "value")}
                      autoComplete="off"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {envs?.length === 0 && (
            <h1 className={styles.nodata}>No Data Available</h1>
          )}
        </Table>
      </TableContainer>
      <div
        style={{
          backgroundColor: "#fff",
          paddingLeft: 10,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="edit"
          edge="start"
          disabled={!props.isEditMode}
          onClick={handleAddEnv}
          className={styles.actionIcon}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
