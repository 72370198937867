import React, { useState, useEffect } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  Paper,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/ButtonOld";
import { useHistory, useLocation } from "react-router-dom";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";
import dataStandardStyles from "../../DataStandard/dataStandardSpecificStyles";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { useSnackbar } from "notistack";
import {
  getFieldList,
  fetchAllFieldGroups,
} from "../../../utils/dataStandards/fieldsUtils";

// Redux
import { connect } from "react-redux";
import { postFieldGroup } from "../../../redux/actions/dataStandardAction";

// Entity Schema
import CustomFieldGroupsForGlossaryFieldCreate from "../../../components/common/entitySchema/fieldGroups/CustomFieldGroupsForGlossaryFieldCreate";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";

const CreateFieldGroupsService = (props) => {
  const styles = manageScreenStyles();
  const listStyle = listStyles();
  const dataStyles = dataStandardStyles();
  const { postFieldGroup } = props;

  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [openFieldModal, setOpenFieldModal] = useState(false);
  const [openFieldGroupModal, setOpenFieldGroupModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const addAnother = true;
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [fieldGroupData, setFieldGroupData] = useState({
    name: "",
    description: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
    description: { isError: false },
  });

  const [treeData, setTreeData] = useState([]);

  const handleChangeTab = (event, newValue) => setTab(newValue);

  // Constants For Table
  const [fieldGroupTable, setFieldGroupTable] = useState([]);
  const [fieldGroupTableOrg, setFieldGroupTableOrg] = useState([]);
  const [fieldTable, setFieldTable] = useState([]);
  const [fieldTableOrg, setFieldTableOrg] = useState([]);

  const [isAllFieldGroups, setIsAllFieldGroups] = useState(true);
  const [isAllFields, setIsAllFields] = useState(true);

  const [modalItem, setModalItem] = useState({});
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  // Fetching current data of Fields and Field Groups
  useEffect(() => {
    checkScreenPermission("GlossaryFieldGroup", ["Create"]);
    if (checkCompPermission("GlossaryField", ["List All"])) {
      dataStandardFieldsList();
    }
    if (checkCompPermission("GlossaryFieldGroup", ["List All"])) {
      dataStandardFieldGroups();
    }
  }, []);

  const search = useLocation().search;
  const serviceCheck = new URLSearchParams(search).get("service");
  const dataStandardCheck = new URLSearchParams(search).get("datastandards");

  const dataStandardFieldGroups = async () => {
    const { _msg, _status, data } = await fetchAllFieldGroups();
    if (_status === 200) {
      const fieldGroupValues =
        (data?.length &&
          data.map((item) => {
            item.checked = false;
            // item.createdon = item.createdon && moment(item.createdon).format("DD MMM YYYY");
            return item;
          })) ||
        [];
      setFieldGroupTable(fieldGroupValues);
      setFieldGroupTableOrg(fieldGroupValues);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };
  const dataStandardFieldsList = async () => {
    const response = await getFieldList();
    if (response?._status === 200) {
      const fieldValues =
        response?.data.length &&
        response?.data.map((item) => {
          item.checked = false;
          return item;
        });
      setFieldTable(fieldValues ? fieldValues : []);
      setFieldTableOrg(fieldValues ? fieldValues : []);
    } else {
      enqueueSnackbar(response?._msg, { variant: "error" });
    }
  };

  // Field Group Checkbox
  const handleFieldGroupCheck = (event, id) => {
    const newFieldGroupTable = fieldGroupTableOrg?.map((fieldGroup) => {
      if (fieldGroup?._id === event.target.name || fieldGroup?._id === id) {
        return { ...fieldGroup, checked: !fieldGroup.checked };
      } else {
        return fieldGroup;
      }
    });
    const newFieldGroupTableDisplay = fieldGroupTable?.map((fieldGroup) => {
      if (fieldGroup?._id === event.target.name || fieldGroup?._id === id) {
        return { ...fieldGroup, checked: !fieldGroup.checked };
      } else {
        return fieldGroup;
      }
    });
    setFieldGroupTable(newFieldGroupTableDisplay);
    setFieldGroupTableOrg(newFieldGroupTable);
  };

  const handleAllFieldGroupCheck = (e, value) => {
    const newFieldGroupTable = fieldGroupTableOrg?.map((fieldGroup) => {
      return { ...fieldGroup, checked: value };
    });
    const newFieldGroupTableDisplay = fieldGroupTable?.map((fieldGroup) => {
      return { ...fieldGroup, checked: value };
    });
    setFieldGroupTable(newFieldGroupTableDisplay);
    setFieldGroupTableOrg(newFieldGroupTable);
  };

  // Field Checkbox
  const handleFieldCheck = (event, id) => {
    const newFieldTable = fieldTableOrg?.map((field) => {
      if (field?._id === event.target.name || field?._id === id) {
        return { ...field, checked: !field.checked };
      } else {
        return field;
      }
    });
    const newFieldTableDisplay = fieldTable?.map((field) => {
      if (field?._id === event.target.name || field?._id === id) {
        return { ...field, checked: !field.checked };
      } else {
        return field;
      }
    });
    setFieldTable(newFieldTableDisplay);
    setFieldTableOrg(newFieldTable);
  };

  const handleAllFieldCheck = (e, value) => {
    const newFieldTable = fieldTableOrg?.map((field) => {
      return { ...field, checked: value };
    });
    const newFieldTableDisplay = fieldTable?.map((field) => {
      return { ...field, checked: value };
    });
    setFieldTable(newFieldTableDisplay);
    setFieldTableOrg(newFieldTable);
  };

  // Count for Selected Fields and Field Groups
  const fieldCount = fieldTableOrg.length
    ? fieldTableOrg?.filter((res) => res.checked === true).length
    : 0;
  const fieldGroupCount = fieldGroupTableOrg.length
    ? fieldGroupTableOrg?.filter((res) => res.checked === true).length
    : 0;

  // Handle Creation of Field Group and Submit
  const handleCreate = async (e, addAnother) => {
    const fieldsData = fieldTableOrg?.filter((res) => res.checked === true);
    const fieldsGroupData = fieldGroupTableOrg?.filter(
      (res) => res.checked === true
    );
    delete fieldGroupData?.status;
    delete fieldGroupData?.updatedby;
    delete fieldGroupData?.updatedon;
    delete fieldGroupData?.createdby;
    delete fieldGroupData?.createdon;
    const fieldGroup = {
      payload: treeData,
      ...fieldGroupData,
      fields: [
        ...fieldsData?.map(({ _id }) => {
          return { type: "field", id: _id };
        }),
        ...fieldsGroupData?.map(({ _id }) => {
          return { type: "fieldgroup", id: _id };
        }),
      ],
    };
    var serializerData = treeData[0]

    function recursiveFunc(data){
      data.children.map((item)=>{
          console.log(item.name)
          if(item.type === 'object' || item.type === 'array'){
              recursiveFunc(item)
          } else {
              item['ftype'] = 'field'
              if(item?._id){
                item['id'] = item._id
              }
              delete item['type']
          }
      })
      return data
    }

    const readyData = recursiveFunc(serializerData)

    const { formIsValid, errors } = handleFormValidationPro(fieldGroup, error);
    if (formIsValid) {
      setLoading(true);
      const response = await postFieldGroup({...readyData, name: fieldGroup.name, description: fieldGroup.description});
      const { _msg, _status } = response;
      if (_status === 201) {
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        if (addAnother) {
          if (serviceCheck) {
            history.push("/services?service=true");
            history.push("/fieldgroups/add?service=true");
          } else if (dataStandardCheck) {
            history.push("/data-standards?datastandards=true");
            history.push("/fieldgroups/add?datastandards=true");
          } else {
            history.push("/aitask-workbench");
            history.push("/fieldgroups/add");
          }
        } else {
          if (serviceCheck) {
            history.push("/services?service=true");
          } else if (dataStandardCheck) {
            history.push("/data-standards?datastandards=true");
          } else {
            history.push("/aitask-workbench");
          }
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    } else {
      //    const { tempEntity, errorInTab } = TabsCommon(
      //   glossaryCreateEntity ? glossaryCreateEntity?.sections : [],
      //   errors,
      //   tabbedView
      // );
      // setActiveTab(errorInTab);
      // setGlossaryCreateEntity({
      //   ...glossaryCreateEntity,
      //   sections: tempEntity,
      // });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  const handleSearch = () => {
    const searchValue = searchInput?.toLowerCase();
    if (searchInput) {
      const filterValues = fieldTableOrg.filter(({ name }) =>
        name?.toLowerCase().includes(searchValue)
      );
      const filterValues2 = fieldGroupTableOrg.filter(({ name }) =>
        name?.toLowerCase().includes(searchValue)
      );
      setFieldTable(filterValues);
      setFieldGroupTable(filterValues2);
    } else {
      setFieldTable(fieldTableOrg);
      setFieldGroupTable(fieldGroupTableOrg);
    }
    // if (tab === 0) {
    //   if (searchInput) {
    //     const filterValues = fieldTableOrg.filter(({ name }) =>
    //       name?.toLowerCase().includes(searchValue)
    //     );
    //     setFieldTable(filterValues);
    //   } else {
    //     setFieldTable(fieldTableOrg);
    //   }
    // }
    // if (tab === 1) {
    //   if (searchInput) {
    //     const filterValues = fieldGroupTableOrg.filter(({ name }) =>
    //       name?.toLowerCase().includes(searchValue)
    //     );
    //     setFieldGroupTable(filterValues);
    //   } else {
    //     setFieldGroupTable(fieldGroupTableOrg);
    //   }
    // }
  };

  const [glossaryCreateEntity, setGlossaryCreateEntity] = useState({});

  useEffect(() => {
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryFieldGroup",
        "create"
      );
      if (_status === 200) {
        setGlossaryCreateEntity(data);
        if (data) {
          const projData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.uiElementType === "Currency") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.datakey) {
                  return (projData[field.datakey] = "");
                }
              });
            });
          });
          setFieldGroupData((prevData) => ({ ...prevData, ...projData }));
          const errData = {};
          data.sections.map((section, index) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  if (field.uiElementType === "Currency") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  if (field.uiElementType === "Amount") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  return (errData[field.datakey] = initError(field, index));
                }
              });
            });
          });
          setError(errData);
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    fetchObjectDetails();
  }, []);

  const handleChange = (event) => {
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setFieldGroupData({
      ...fieldGroupData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Payloads",
              path: serviceCheck
                ? "/services?service=true"
                : dataStandardCheck
                  ? "/data-standards?datastandards=true"
                  : "/aitask-workbench",
            },
            { title: "Create Payload" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Create Payload</h2>
          {/* {tabbedView ? (
            <TabbedNavigation
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleCreate}
              handleCancel={handleCancel}
            />
          ) : ( */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AppButton
              style={{ padding: "0" }}
              className={`btnsmall`}
              variant="outlined"
              onClick={() => history.goBack()}
              buttonName="Cancel"
            />
            <AppButton
              style={{ marginLeft: "12px" }}
              className={`btnsmall `}
              variant="outlined"
              onClick={(e) => {
                handleCreate(e, addAnother);
              }}
              buttonName="Create and Add Another"
            />
            <AppButton
              className={`btnsmall `}
              style={{ marginLeft: "10px" }}
              variant="contained"
              onClick={handleCreate}
              buttonName="Create"
            />
          </div>
          {/* )} */}
        </Grid>
      </div>

      {/* With Entity Schema */}
      <div className={styles.wrapper} style={{ top: "6.5rem" }}>
        {/* {tabbedView ? (
          <>
            <SectionTabs
              entity={glossaryCreateEntity ? glossaryCreateEntity.sections : []}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CustomFieldGroupsForGlossaryFieldCreate
                  section={glossaryCreateEntity?.sections?.[activeTab]}
                  isEditMode={true}
                  fieldGroupData={fieldGroupData}
                  error={error}
                  setFieldData={setFieldGroupData}
                  handleChange={handleChange}
                  tab={tab}
                  handleChangeTab={handleChangeTab}
                  listStyle={listStyle}
                  fieldTable={fieldTable}
                  fieldGroupTable={fieldGroupTable}
                  setSearchInput={setSearchInput}
                  handleSearch={handleSearch}
                  handleAllFieldCheck={handleAllFieldCheck}
                  isAllFields={isAllFields}
                  setIsAllFields={setIsAllFields}
                  handleFieldCheck={handleFieldCheck}
                  dataStyles={dataStyles}
                  setModalItem={setModalItem}
                  setOpenFieldModal={setOpenFieldModal}
                  isAllFieldGroups={isAllFieldGroups}
                  handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                  setIsAllFieldGroups={setIsAllFieldGroups}
                  handleFieldGroupCheck={handleFieldGroupCheck}
                  setOpenFieldGroupModal={setOpenFieldGroupModal}
                  fieldCount={fieldCount}
                  fieldGroupCount={fieldGroupCount}
                  fieldTableOrg={fieldTableOrg}
                  fieldGroupTableOrg={fieldGroupTableOrg}
                  modalItem={modalItem}
                  openFieldModal={openFieldModal}
                  openFieldGroupModal={openFieldGroupModal}
                />
              </div>
            </Paper>
          </>
        ) : (
         
        )} */}
        {glossaryCreateEntity?.sections &&
          glossaryCreateEntity?.sections.map((section) => {
            if (section.name === "Glossary_Fieldgroup_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldCreate
                    section={section}
                    isEditMode={true}
                    fieldGroupData={fieldGroupData}
                    error={error}
                    setFieldData={setFieldGroupData}
                    handleChange={handleChange}
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    listStyle={listStyle}
                    fieldTable={fieldTable}
                    fieldGroupTable={fieldGroupTable}
                    setSearchInput={setSearchInput}
                    handleSearch={handleSearch}
                    handleAllFieldCheck={handleAllFieldCheck}
                    isAllFields={isAllFields}
                    setIsAllFields={setIsAllFields}
                    handleFieldCheck={handleFieldCheck}
                    dataStyles={dataStyles}
                    setModalItem={setModalItem}
                    setOpenFieldModal={setOpenFieldModal}
                    isAllFieldGroups={isAllFieldGroups}
                    handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                    setIsAllFieldGroups={setIsAllFieldGroups}
                    handleFieldGroupCheck={handleFieldGroupCheck}
                    setOpenFieldGroupModal={setOpenFieldGroupModal}
                    fieldCount={fieldCount}
                    fieldGroupCount={fieldGroupCount}
                    fieldTableOrg={fieldTableOrg}
                    fieldGroupTableOrg={fieldGroupTableOrg}
                    modalItem={modalItem}
                    openFieldModal={openFieldModal}
                    openFieldGroupModal={openFieldGroupModal}
                    treeData={treeData}
                    setTreeData={setTreeData}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]} {section["section Label"]==='Add Field Table Section'&&  <span style={{color:'#E22626'}}>*</span>}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldCreate
                      section={section}
                      isEditMode={true}
                      fieldGroupData={fieldGroupData}
                      error={error}
                      setFieldData={setFieldGroupData}
                      handleChange={handleChange}
                      tab={tab}
                      handleChangeTab={handleChangeTab}
                      listStyle={listStyle}
                      fieldTable={fieldTable}
                      fieldGroupTable={fieldGroupTable}
                      setSearchInput={setSearchInput}
                      handleSearch={handleSearch}
                      handleAllFieldCheck={handleAllFieldCheck}
                      isAllFields={isAllFields}
                      setIsAllFields={setIsAllFields}
                      handleFieldCheck={handleFieldCheck}
                      dataStyles={dataStyles}
                      setModalItem={setModalItem}
                      setOpenFieldModal={setOpenFieldModal}
                      isAllFieldGroups={isAllFieldGroups}
                      handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                      setIsAllFieldGroups={setIsAllFieldGroups}
                      handleFieldGroupCheck={handleFieldGroupCheck}
                      setOpenFieldGroupModal={setOpenFieldGroupModal}
                      fieldCount={fieldCount}
                      fieldGroupCount={fieldGroupCount}
                      fieldTableOrg={fieldTableOrg}
                      fieldGroupTableOrg={fieldGroupTableOrg}
                      modalItem={modalItem}
                      openFieldModal={openFieldModal}
                      openFieldGroupModal={openFieldGroupModal}
                      treeData={treeData}
                      setTreeData={setTreeData}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  postFieldGroup,
};

const mapStateToProps = (state) => {
  // const { tabbedView } = state.userDetails.userInfo;
  // return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFieldGroupsService);
