import { CommonServices } from "../../services/commonServices";
import { ServiceGroupsAPIServices } from "../../services/serviceGroups";

const commonService = new CommonServices();
const serviceGroupApiService = new ServiceGroupsAPIServices();

export const fetchServiceGroupListData = async (
  projectId,
  currentPage,
  filters
) => {
  try {
    const res_data = await commonService.getServiceGroupListfromProjectID(
      projectId,
      currentPage,
      filters
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchServiceGroupById = async (projectId, groupId) => {
  try {
    const res_data = await serviceGroupApiService.getServiceGroupById(
      projectId,
      groupId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchServiceInfoByServiceId = async (serviceId) => {
  try {
    const res_data = await serviceGroupApiService.getServiceInfoById(serviceId);
    const { _msg, _status, data } = res_data?.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchEntitiesByVersionId = async (versionId) => {
  try {
    const res_data = await serviceGroupApiService.getEntititesInfoByVersionId(
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const addServiceGroupInProject = async (projectId, serviceGroupData) => {
  try {
    const res_data = await serviceGroupApiService.addServiceGroupInProject(
      projectId,
      serviceGroupData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const addServiceGroupInServiceGroup = async (
  projectId,
  groupId,
  serviceGroupData
) => {
  try {
    const res_data = await serviceGroupApiService.addServiceGroupInServiceGroup(
      projectId,
      groupId,
      serviceGroupData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editServiceGroup = async (
  projectId,
  groupId,
  serviceGroupData
) => {
  try {
    const res_data = await serviceGroupApiService.editServiceGroup(
      projectId,
      groupId,
      serviceGroupData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteServiceGroup = async (projectId, groupId) => {
  try {
    const res_data = await serviceGroupApiService.deleteServiceGroup(
      projectId,
      groupId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};
