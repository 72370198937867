import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";

export function DiscardModal(props) {
  const { open, handleClose, handleDelete } = props;

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          <div
            style={{
              height: 200,
              width: 450,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src="/images/governance/delete.svg"
                alt="lock"
                style={{ height: 75, width: 75 }}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p>
                Are you sure you want to <b>delete</b>, unpublished changes will be lost ?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName="Cancel"
            variant="outlined"
          />
          <AppButton
            onClick={handleDelete}
            className={`btnsmall`}
            buttonName={"Yes, Delete"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}



