import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
import PublishIcon from "@material-ui/icons/Publish";
import { TextField } from "@material-ui/core";
import manageScreenStyles from "../../../screens/CommonStyles/manageScreenStyles";

const CustomStyles = makeStyles((theme) => ({
  cancel: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    border: "1px solid #052855",
    borderRadius: 5,
    opacity: 1,
    color: "#052855",
  },
  submitMember: {
    background: "#052855 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      background: "#052855 0% 0% no-repeat padding-box",
    },
  },
}));

export default function PublishSourceCodeModel(props) {
  const { open, handleClose, overRideVersion, createNewVersion, validationError } = props;
  const [isNewVersion, setIsNewVersion] = useState(false);
  const styles = CustomStyles();
  const stylesInput = manageScreenStyles();

  const [versionData, serVersionData] = useState({})

  const handleChange = (e) => {
    serVersionData( {...versionData,[e.target.name]: e.target.value})
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          {isNewVersion ? (
            <div style={{padding:'20px 10px 20px 10px'}}>
              <div>
                <label htmlFor="" className={stylesInput.fieldHeading}>
                  Version Tag <span className={stylesInput.red}>*</span>
                </label>
                <input
                  type="text"
                  name="version_tag"
                  className={`${stylesInput.input} ${validationError?.version_tag && stylesInput.error}`}
                  onChange={handleChange}
                />
                {validationError?.version_tag && (
                  <p className={stylesInput.errorMsg}>{validationError?.version_tag}</p>
                )}
              </div>

              <div style={{paddingTop:'20px'}}>
                <label htmlFor="" className={stylesInput.fieldHeading}>
                  Port <span className={stylesInput.red}>*</span>
                </label>
                <input
                  type="text"
                  name="port"
                  className={`${stylesInput.input} ${validationError?.port && stylesInput.error}`}
                  onChange={handleChange}
                />
                  {validationError?.port && (
                  <p className={stylesInput.errorMsg}>{validationError?.port}</p>
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <PublishIcon
                    style={{ color: "gray", height: "76px", width: "76px" }}
                  />
                </div>
                <div style={{ marginTop: 30 }}>
                  <p>Where would you like to publish ?</p>
                </div>
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName="Cancel"
            variant="outlined"
          />
          {!isNewVersion && <AppButton
            onClick={overRideVersion}
            className={`btnsmall`}
            buttonName={"Override existing"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          /> }
          
          {isNewVersion ? <AppButton
            onClick={() => createNewVersion(versionData)}
            className={`btnsmall`}
            buttonName={"Create new version"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />: 
          
          <AppButton
          onClick={() => setIsNewVersion(true)}
          className={`btnsmall`}
          buttonName={"Create new version"}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        />
        }
          

         
        </DialogActions>
      </Dialog>
    </div>
  );
}
