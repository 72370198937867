const clusterColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "clustername", title: "Cluster Name", for: "service" },
  { name: "cloudprovider", title: "Cloud Provider", for: "service" },
  { name: "deployments", title: "Deployments", for: "service" },
  { name: "services", title: "Services", for: "service" },
  { name: "pods", title: "Pods", for: "service" },
  { name: "replicaset", title: "Replica Sets", for: "service" },
  { name: "nodes", title: "Nodes", for: "service" },
];

const clusterDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "clustername", weightage: 5, width: 200 },
  { columnName: "cloudprovider", weightage: 5, width: 200 },
  { columnName: "deployments", weightage: 7, width: 200 },
  { columnName: "services", weightage: 7, width: 200 },
  { columnName: "nodes", weightage: 7, width: 200 },
  { columnName: "pods", weightage: 7, width: 200 },
  { columnName: "replicaset", weightage: 7, width: 200 },
];

const clusterTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "clustername", align: "left" },
  { columnName: "cloudprovider", align: "center" },
  { columnName: "deployments", align: "center" },
  { columnName: "services", align: "center" },
  { columnName: "nodes", align: "center" },
  { columnName: "pods", align: "center" },
  { columnName: "replicaset", align: "center" },
];

const clusterColumnOrder = [
  // 'id',
  "clustername",
  "cloudprovider",
  "deployments",
  "services",
  "nodes",
  "pods",
  "replicaset",
];

const clusterColumnHidden = [
  // 'id',

  "pods",
  "replicaset",
];

const clusterDefaultSorting = [];

export {
  clusterColumns,
  clusterDefaultColumnWidths,
  clusterTableColumnExtensions,
  clusterColumnOrder,
  clusterDefaultSorting,
  clusterColumnHidden,
};
