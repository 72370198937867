const SelectedTestDeploymentColumns = [
  { name: "ServiceName", title: "Container name", for: "test_deploy" },
  { name: "endpoint", title: "EndPoint", for: "test_deploy" },
  { name: "copyButton", title: "  ", for: "copyButton" },
];

const testDeploymentDefaultColumnWidths = [
  { columnName: "ServiceName", weightage: 9.5, width: 200 },
  { columnName: "endpoint", weightage: 0.5, width: 200 },
  { columnName: "copyButton", weightage: 1.5, width: 200 },
];

const testDeploymentTableColumnExtensions = [
  { columnName: "ServiceName", align: "left" },
  { columnName: "endpoint", align: "left" },
  { columnName: "copyButton", align: "left" },
];

const testDeploymentColumnOrder = ["ServiceName", "endpoint", "copyButton"];

const testDeploymentColumnHidden = [];

const testDeploymentDefaultSorting = [];

export {
  SelectedTestDeploymentColumns,
  testDeploymentDefaultColumnWidths,
  testDeploymentTableColumnExtensions,
  testDeploymentColumnOrder,
  testDeploymentDefaultSorting,
  testDeploymentColumnHidden,
};
