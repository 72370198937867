import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  input: {
    height: '2.2rem',
    border: '1px solid #DEDEDF',
    borderRadius: 5,
    margin: '0px 8px 0px',
    paddingBottom: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box !important',
    outline: 'none',

    "&::before": {
      borderBottom: 'unset !important'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
}));
