import { TestScriptService } from "../../services/testScriptService";
import {
  ADD_TEST_SCRIPT_LIST,
  ALTER_SUBSCRIBE_SCRIPT_BY_ID,
  DELETE_SCRIPT_BY_ID,
  SCRIPT_FILTERS,
  ALTER_FAVOURITE_SCRIPT_BY_ID,
  SCRIPT_HIDDEN
} from "../actionTypes";
const script = new TestScriptService();

const add_test_script_list = (payload) => ({
  type: ADD_TEST_SCRIPT_LIST,
  payload: payload,
});

export const addScriptHidden = (scriptHidden) => {
  return {
    type: SCRIPT_HIDDEN,
    payload: scriptHidden
  }
};

export const fetchAllTestScript =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();

    try {
      dispatch(
        add_test_script_list({
          loading: true,
        })
      );
      const res_data = await script.getAllTestScript(currentPage, filter, sort);
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_test_script_list({
            loading: false,
            data:
              data.length !== 0 &&
              data.map((item) => {
                const checkFav =
                  userFavourites.filter(
                    (fav) => fav.resourceinstance === item._id
                  ).length !== 0;
                let tempProj = item;

                if (checkFav) {
                  tempProj["isFavourite"] = true;
                } else {
                  tempProj["isFavourite"] = false;
                }
                const checkSubscribe =
                  userSubscriptions.filter(
                    (sub) =>
                      sub.name.split("_")[0] === "script" &&
                      sub.name.split("_")[1] === item._id
                  ).length !== 0;
                if (checkSubscribe) {
                  tempProj["isSubscribe"] = true;
                } else {
                  tempProj["isSubscribe"] = false;
                }
                return tempProj;
              }),
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_test_script_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_test_script_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const deleteScriptById = (ScriptId) => async (dispatch) => {
  dispatch({
    type: DELETE_SCRIPT_BY_ID,
    payload: {
      ScriptId,
    },
  });
};

export const addScriptFilters = (testScriptFilters) => {
  return {
    type: SCRIPT_FILTERS,
    payload: testScriptFilters,
  };
};

export const alterSubscribeScriptById = (ScriptId) => {
  return {
    type: ALTER_SUBSCRIBE_SCRIPT_BY_ID,
    payload: {
      ScriptId,
    },
  };
};

export const alterFavouriteScriptById = (ScriptId) => {
  return {
    type: ALTER_FAVOURITE_SCRIPT_BY_ID,
    payload: {
      ScriptId,
    },
  };
};
