import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Grid,
  IconButton,
  InputAdornment,
  FilledInput,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Checkbox,
  Table,
  FormControlLabel,
  TextField,
  Icon,
  Popover,
  MenuList,
  MenuItem,
  ListItem,
  List,
  ListItemText,
  Tooltip,
  Avatar,
  Typography,
} from "@material-ui/core";
import FieldDetailModal from "../../../DataStandards/FieldDetailModal";
import FieldGroupDetailModal from "../../../DataStandards/FieldGroupDetailModal";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import CloseIcon from "@material-ui/icons/Close";
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
import DraggableTree from "../../../Ontology/DraggableTree";
import { Autocomplete } from "@material-ui/lab";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { getFieldGroupById } from "../../../../utils/dataStandards/fieldsUtils";
import { CreateNewFieldGroupModal } from "../../modals/CreateNewFieldGroupModal";
import { useSnackbar } from "notistack";
import CreateFieldModal from "../../../../screens/DataStandard/CreateScreens/CreateFieldModal";
import { DropzoneArea } from "material-ui-dropzone";
import { AppButton } from "../../Button";
import { PayloadValidator } from "./PayloadUploadDataValidator";
import WarningIcon from '@material-ui/icons/Warning';
import { downloadPayloadFile } from "./DownloadSample";
import './style.css'

const AddFieldTableSection = ({
  styles,
  tab,
  handleChangeTab,
  listStyle,
  fieldTable,
  fieldGroupTable,
  setSearchInput,
  handleSearch,
  handleAllFieldCheck,
  isAllFields,
  setIsAllFields,
  handleFieldCheck,
  dataStyles,
  setModalItem,
  setOpenFieldModal,
  isAllFieldGroups,
  handleAllFieldGroupCheck,
  setIsAllFieldGroups,
  handleFieldGroupCheck,
  setOpenFieldGroupModal,
  fieldCount,
  fieldGroupCount,
  fieldTableOrg,
  fieldGroupTableOrg,
  modalItem,
  openFieldModal,
  openFieldGroupModal,
  treeData,
  setTreeData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedPath, setSelectedPath] = useState();

  const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);
  const [showCreateNameModal, setShowCreateNameModal] = useState(false);

  const changeType = (rowInfo, current) => {
    setTreeData(
      changeNodeAtPath({
        treeData: treeData,
        path: selectedPath.path,
        newNode: {
          ...selectedPath.node,
          children: current === 'array' || current === 'object' ? selectedPath?.node?.children : undefined,
          type: current,
          name: selectedPath.treeIndex !== 0 ? (current === 'array' || current === 'object' ? selectedPath?.node?.name : "") : "root",
          isEdit: selectedPath.treeIndex !== 0 && true,
        },
        getNodeKey,
      })
    );
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const cancelCreation = (rowInfo) => {
    setTreeData(
      changeNodeAtPath({
        treeData: treeData,
        path: rowInfo.path,
        newNode: {
          children: rowInfo.node.children,
          ...rowInfo.node,
          name: "",
          isEdit: false,
        },
        getNodeKey,
      })
    );
  };

  const canDrag = ({ node }) => {
    if (node.name === "root") {
      return false;
    } else {
      return true;
    }
  };

  const canDrop = ({ nextParent }) => {
    if (nextParent?.type === "object" || nextParent?.type === "array") {
      return true;
    } else {
      return false;
    }
  };

  const handleUpload = (files) => {
    if (files.length > 0) {
      function handleFileSelect(f) {
        let reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function (theFile) {
          return function (e) {
            function isJsonString(str) {
              try {
                JSON.parse(str);
              } catch (e) {
                return false;
              }
              return true;
            }
            const isValidJson = isJsonString(e.target.result);
            if (isValidJson) {
              // setFileDetails({
              //   filename: files ? (files[0] ? files[0].name : "") : "",
              // });

              // setDroppedDocument(files);
              const payload = JSON.parse(e.target.result)
              
              if(Array.isArray(payload)){
                const {isValid, serializeData} = PayloadValidator(payload, fieldTable)
                if(isValid.valid){
                  setTreeData(serializeData)
                } else {
                  enqueueSnackbar(isValid.msg, { variant: "error" });
                }
              } else {
                enqueueSnackbar("Invalid payload Json file", { variant: "error" });
              }
              
            } else {
              enqueueSnackbar("Invalid Json file", { variant: "error" });
            }
          };
        })(f);

        // Read in the image file as a data URL.
        if (f) {
          reader.readAsText(f);
        }
      }

      handleFileSelect(files[0]);
    }
  }

  return (
    <>
      <CreateFieldModal
        open={showCreateFieldModal}
        handleClose={() => setShowCreateFieldModal(false)}
        handleConfirm={(e) => {
          setTreeData(
            changeNodeAtPath({
              treeData: treeData,
              path: selectedPath.path,
              newNode: {
                children: selectedPath.node.children,
                name: e.name,
                type: e.type,
                id: e._id,
              },
              getNodeKey,
            })
          );
          setShowCreateFieldModal(false);
        }}
        selectedPath={selectedPath}
      />

      <CreateNewFieldGroupModal
        open={showCreateNameModal}
        varType={selectedPath?.node.type}
        handleClose={() => setShowCreateNameModal(false)}
        handleConfirm={(e) => {
          setTreeData(
            changeNodeAtPath({
              treeData: treeData,
              path: selectedPath.path,
              newNode: {
                ...selectedPath.node,
                children: selectedPath.node.children,
                name: e.name,
                isEdit: false,
              },
              getNodeKey,
            })
          );
          setShowCreateNameModal(false);
        }}
      />

      <Grid
        item
        xs={12}
        sm={6}
        style={{
          borderradius: "5px",
          opacity: "1",
          marginBottom: "100px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {treeData?.length === 0 && (
              <div className={`${styles.documentDropperWrapper2}`}>
                <DropzoneArea
                  name="droppedDocument"
                  className={styles.documentDropperClass}
                  showPreviews={false}
                  filesLimit={1}
                  dropzoneText="Drag and drop or click to browse here (Json Schema)"
                  dropzoneClass="documentDropperClass"
                  dropzoneParagraphClass="documentDropperTextClass"
                  get
                  acceptedFiles={[".json"]}
                  showAlerts={["error"]}
                  showPreviewsInDropzone={false}
                  // onDrop={() => setIsLoader(true)}
                  onChange={(files) => {
                    // setIsLoader(false);
                    handleUpload(files);
                  }}
                />
                <p style={{ marginLeft:'140px' }}>[OR]</p>
                <div style={{ display:'flex' }}>
                <div style={{marginRight:'10px'}}>
                  <AppButton
                    color="primary"
                    buttonName="Add Nodes Manually"
                    variant="contained"
                    onClick={() =>
                      setTreeData([
                        {
                          name: "root",
                          type: "object",
                        },
                      ])
                    }
                  />
                </div>
                <div>
                <AppButton
                    color="primary"
                    buttonName="Download Sample"
                    variant="outlined"
                    onClick={downloadPayloadFile}
                  />
                </div>
                </div>
                
              </div>
            )}
            {treeData?.length > 0 && (
              <SortableTree
                treeData={treeData}
                onChange={(treeData) => setTreeData(treeData)}
                isVirtualized={false}
                canDrag={canDrag}
                canDrop={canDrop}
                generateNodeProps={(rowInfo) => ({
                  title: rowInfo?.node.isEdit ? (
                    <>
                      <Autocomplete
                        className={`${styles.searchInput3}`}
                        value={
                          rowInfo?.node.type === "object"
                            ? fieldGroupTable?.find(
                                (item) => item.name === rowInfo?.node.name
                              )
                            : fieldTable?.find(
                                (item) => item.name === rowInfo?.node.name
                              )
                        }
                        style={{ width: "180px" }}
                        fullWidth
                        options={
                          rowInfo?.node.type === "object" ||
                          rowInfo?.node.type === "array"
                            ? fieldGroupTable?.filter(
                                (item) => item.type === rowInfo?.node?.type
                              )
                            : fieldTable?.filter(
                                (item) => item.type === rowInfo?.node?.type
                              )
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(e, input) => {
                          if (input) {
                            const isFound = rowInfo?.parentNode?.children.find(
                              (item) => item?.name === input?.name
                            );
                            if (!isFound) {
                              if (
                                rowInfo?.node.type === "object" ||
                                rowInfo?.node.type === "array"
                              ) {
                                const getFieldGroupDetails = async () => {
                                  const { _msg, _status, data } =
                                    await getFieldGroupById(input?._id);
                                  if (_status === 200) {
                                    setTreeData(
                                      changeNodeAtPath({
                                        treeData: treeData,
                                        path: rowInfo.path,
                                        newNode: {
                                          ...rowInfo.node,
                                          children: data.children,
                                          type: input.type,
                                          name: input.name,
                                          isEdit: false,
                                          ftype: "fieldgroup",
                                        },
                                        expandParent: true,
                                        getNodeKey,
                                      })
                                    );
                                  }
                                };

                                getFieldGroupDetails();
                              } else {
                                setTreeData(
                                  changeNodeAtPath({
                                    treeData: treeData,
                                    path: rowInfo.path,
                                    newNode: {
                                      ...rowInfo.node,
                                      name: input.name,
                                      isEdit: false,
                                      id: input?._id,
                                      ftype: "field",
                                    },
                                    getNodeKey,
                                  })
                                );
                              }
                            } else {
                              enqueueSnackbar("Duplicate key not allowed", {
                                variant: "error",
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoFocus
                            {...params}
                            variant="standard"
                            placeholder="Search Fields"
                            // onBlur={() => cancelCreation(rowInfo)}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <span
                      onClick={() =>
                        setTreeData(
                          changeNodeAtPath({
                            treeData: treeData,
                            path: rowInfo.path,
                            newNode: {
                              children: rowInfo.node.children,
                              ...rowInfo.node,
                              isEdit: true,
                            },
                            getNodeKey,
                          })
                        )
                      }
                    >
                      {rowInfo?.parentNode === null
                        ? "root"
                        : rowInfo?.node?.name}
                        &nbsp; 
                        {rowInfo?.node?.type !=="array" && rowInfo?.node?.type !=="object" && !rowInfo?.node?.id && !rowInfo?.node?._id && <WarningIcon  style={{color: "orange", fontSize:"14px"}} />}
                    </span>
                  ),
                  buttons: [
                    (rowInfo.node.type === "object" ||
                      rowInfo.node.type === "array") &&
                      !rowInfo.node.isEdit && (
                        <Tooltip title="Add node">
                          <IconButton
                            size="small"
                            style={{ color: "green" }}
                            onClick={() => {
                              setSelectedPath(rowInfo);
                              // setShowCreateFieldModal(true)
                              setTreeData(
                                addNodeUnderParent({
                                  treeData: treeData,
                                  parentKey:
                                    rowInfo.path[rowInfo.path.length - 1],
                                  newNode: {
                                    title: "",
                                    isEdit: true,
                                    type: "string",
                                  },
                                  expandParent: true,
                                  getNodeKey,
                                  addAsFirstChild: treeData.addAsFirstChild,
                                }).treeData
                              );
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      ),

                    rowInfo.node.isEdit && (
                      <Tooltip title="Add new field">
                        <IconButton
                          size="small"
                          style={{ color: "#FFBF00" }}
                          onClick={() => {
                            if (
                              rowInfo.node.type === "object" ||
                              rowInfo.node.type === "array"
                            ) {
                              setShowCreateNameModal(true);
                            } else {
                              setShowCreateFieldModal(true);
                            }
                            setSelectedPath(rowInfo);
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                    <>
                      {rowInfo.parentNode !== null && (
                        <Tooltip title="Remove">
                          <IconButton
                            style={{ color: "red" }}
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setTreeData(
                                removeNodeAtPath({
                                  treeData: treeData,
                                  path: rowInfo.path,
                                  getNodeKey,
                                })
                              );
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>,
                    <>
                      <Tooltip title="Click to change Type">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            handleClick(e);
                            setSelectedPath(rowInfo);
                          }}
                        >
                          <Avatar
                            style={{
                              width: "22px",
                              height: "22px",
                              backgroundColor: "rgb(77, 208, 225)",
                              fontSize: "18px",
                              marginTop: 1,
                            }}
                          >
                            {rowInfo?.node?.type &&
                              rowInfo?.node?.type[0].toUpperCase()}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <List dense={true}>
                          {selectedPath?.parentNode !== null && (
                            <>
                              <ListItem
                                button
                                onClick={() => changeType(rowInfo, "string")}
                              >
                                <ListItemText primary="String" />
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => changeType(rowInfo, "integer")}
                              >
                                <ListItemText primary="Integer" />
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => changeType(rowInfo, "number")}
                              >
                                <ListItemText primary="Number" />
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => changeType(rowInfo, "boolean")}
                              >
                                <ListItemText primary="Boolean" />
                              </ListItem>
                            </>
                          )}
                          <ListItem
                            button
                            onClick={() => changeType(rowInfo, "object")}
                          >
                            <ListItemText primary="Object" />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => changeType(rowInfo, "array")}
                          >
                            <ListItemText primary="Array" />
                          </ListItem>
                        </List>
                      </Popover>
                    </>,
                  ],
                })}
                // className={classes.draggableTree}
                // canDrag={this.handleDrag}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <FieldDetailModal
        item={modalItem}
        openFieldModal={openFieldModal}
        setOpenFieldModal={setOpenFieldModal}
      />
      <FieldGroupDetailModal
        item={modalItem}
        openFieldGroupModal={openFieldGroupModal}
        setOpenFieldGroupModal={setOpenFieldGroupModal}
      />
    </>
  );
};

export default AddFieldTableSection;
