import {
  GET_ONTOLOGY_FIELDS,
  GET_ONTOLOGY_FIELDGROUPS,
  GET_ONTOLOGY_SECTIONS,
  GET_ONTOLOGY_OBJECTS,
  ADD_ONTOLOGY_FIELDS_FILTER,
  ADD_ONTOLOGY_FIELDGROUP_FILTER,
  ADD_ONTOLOGY_SECTION_FILTER,
  ADD_ONTOLOGY_OBJECT_FILTER,
  ADD_ONTOLOGY_SORT,
  DELETE_ONTOLOGY,
  CLEAR_ONTOLOGY_OBJECTS_DATA,
  CLEAR_ONTOLOGY_FIELDGROUP_DATA,
  CLEAR_ONTOLOGY_FIELDS_DATA,
  CLEAR_ONTOLOGY_SECTIONS_DATA,
  OBJECT_COLUMN_HIDDEN,
  SECTION_COLUMN_HIDDEN,
  FIELDGROUP_COLUMN_HIDDEN,
  FIELD_COLUMN_HIDDEN,
} from "../actionTypes";
import { OntologyServices } from "../../services/ontologyServices";

const OntologyService = new OntologyServices();

const add_ontology_fields = (payload) => ({
  type: GET_ONTOLOGY_FIELDS,
  payload: payload,
});

export const getOntologyFields =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_ontology_fields({
          loading: true,
        })
      );
      const res_data = await OntologyService.getFieldsList(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_ontology_fields({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_ontology_fields({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_ontology_fields({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

const add_ontology_fieldgroups = (payload) => ({
  type: GET_ONTOLOGY_FIELDGROUPS,
  payload: payload,
});

export const getOntologyFieldGroups =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_ontology_fieldgroups({
          loading: true,
        })
      );
      const res_data = await OntologyService.getFieldGroupList(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_ontology_fieldgroups({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount,
          })
        );
      } else {
        dispatch(
          add_ontology_fieldgroups({
            loading: false,
            data: [],
            error: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_ontology_fieldgroups({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

const add_ontology_sections = (payload) => ({
  type: GET_ONTOLOGY_SECTIONS,
  payload: payload,
});

export const getOntologySections =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_ontology_sections({
          loading: true,
        })
      );
      const res_data = await OntologyService.getSectionList(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_ontology_sections({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_ontology_sections({
            loading: false,
            data: [],
            error: true,
          })
        );
      }
    } catch (err) {
      dispatch(
        add_ontology_sections({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

const add_ontology_objects = (payload) => ({
  type: GET_ONTOLOGY_OBJECTS,
  payload: payload,
});

export const getOntologyObjects =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_ontology_objects({
          loading: true,
        })
      );
      const res_data = await OntologyService.getObjectList(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_ontology_objects({
            loading: false,
            error: false,
            data: data ? data : [],
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_ontology_objects({
            loading: false,
            data: [],
            error: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_ontology_objects({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addFieldFilters = (fieldFilter) => {
  return {
    type: ADD_ONTOLOGY_FIELDS_FILTER,
    payload: fieldFilter,
  };
};

export const addFieldgroupFilters = (fieldgroupFilter) => {
  return {
    type: ADD_ONTOLOGY_FIELDGROUP_FILTER,
    payload: fieldgroupFilter,
  };
};

export const addSectionFilters = (sectionFilters) => {
  return {
    type: ADD_ONTOLOGY_SECTION_FILTER,
    payload: sectionFilters,
  };
};

export const addObjectFilters = (objectFilters) => {
  return {
    type: ADD_ONTOLOGY_OBJECT_FILTER,
    payload: objectFilters,
  };
};

export const addOntologySort = (type, ontologySort) => {
  return {
    type: ADD_ONTOLOGY_SORT,
    payload: {
      active: type,
      sort: ontologySort,
    },
  };
};

export const deleteOntology = (tabtype, id) => {
  return {
    type: DELETE_ONTOLOGY,
    payload: {
      type: tabtype,
      id: id,
    },
  };
};

export const clearOntologyObjectsData = () => {
  return {
    type: CLEAR_ONTOLOGY_OBJECTS_DATA,
  };
};

export const clearOntologyFieldGroupData = () => {
  return {
    type: CLEAR_ONTOLOGY_FIELDGROUP_DATA,
  };
};

export const clearOntologyFieldData = () => {
  return {
    type: CLEAR_ONTOLOGY_FIELDS_DATA,
  };
};

export const clearOntologySectionsData = () => {
  return {
    type: CLEAR_ONTOLOGY_SECTIONS_DATA,
  };
};

export const addObjectColumnHidden = (objectHidden) => {
  return {
    type: OBJECT_COLUMN_HIDDEN,
    payload: objectHidden,
  };
};
export const addSectionColumnHidden = (sectionHidden) => {
  return {
    type: SECTION_COLUMN_HIDDEN,
    payload: sectionHidden,
  };
};
export const addFieldGroupColumnHidden = (fieldGroupHidden) => {
  return {
    type: FIELDGROUP_COLUMN_HIDDEN,
    payload: fieldGroupHidden,
  };
};
export const addFieldColumnHidden = (fieldHidden) => {
  return {
    type: FIELD_COLUMN_HIDDEN,
    payload: fieldHidden,
  };
};
