/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddEnvironmentServicesModal from "../../Orchestration/Application/AddEnvironmentServicesModal";
import AddVolumnDeploymentModal from "../AddVolumnDeploymentModal";
import { connect } from "react-redux";
import {
  addEnvironmentDeployment,
  restartContainerCheck,
  selectContainerCheck,
  editContainerDeployment,
} from "../../../redux/actions/deploymentProcessAction";
import LayersIcon from "@material-ui/icons/Layers";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import AddSizingDeploymentModal from "../AddSizingDeploymentModal";

const useStyles = makeStyles((theme) => ({
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",
      "& tr": {
        color: "#FFFFFF",
      },
      "& th": {
        color: "#FFFFFF",
        lineHeight: "2.5rem",
        paddingLeft: "12px",
        padding: "0px",
      },
      "& th:last-child": {
        textAlign: "left",
      },
    },
    "& tbody": {
      marginLeft: "30px",
      "& tr .MuiTableCell-root": {
        padding: "3px 12px",
      },
      "& tr": {
        "& td:last-child": {
          textAlign: "center",
        },
      },
    },
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    padding: "0 15px",
    "&::before": {
      borderBottom: "unset",
    },
  },
  input2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    "&::before": {
      borderBottom: "unset",
    },
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  searchInput3: {
    height: 38,
    width: 355,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  nodata: {
    height: "6rem",
    position: "sticky",
    left: "45%",
    top: "3rem",
    paddingTop: "1rem",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 10px/12px Nunito;",
    margin: 0,
    paddingTop: 2,
  },
}));

const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function DeploymentContainerTable({
  container,
  addEnvironmentDeployment,
  restartContainerCheck,
  deployment_type,
  selectContainerCheck,
  editContainerDeployment,
}) {
  const tStyles = tempStyles();
  const styles = useStyles();

  const [selectedData, setSelectedData] = useState({});
  const [showModal, setModal] = useState(false);
  const [showVolumnModal, setVolumnModal] = useState(false);
  const [showSizingModal, setSizingModal] = useState(false);

  const handleModalIconOpen = (data) => {
    if (data) {
      setSelectedData(data);
      setModal(true);
    }
  };

  const handleModalVolumnOpen = (data) => {
    setSelectedData(data);
    setVolumnModal(true);
  };

  const handleModalSizingOpen = (data) => {
    setSelectedData(data);
    setSizingModal(true);
  };

  const handleModalClose = (data_obj) => {
    setModal(false);
    addEnvironmentDeployment({ ...data_obj, deployment_type: deployment_type });
    // if (data_obj) {
    //   const { id, environment } = data_obj;
    //   const new_obj =
    //     applicationDeployment.length > 0 &&
    //     applicationDeployment.map((item, index) => {
    //       if (item.id === id) {
    //         return { ...item, environment: environment };
    //       } else {
    //         return item;
    //       }
    //     });
    //   setApplicationDeployment(new_obj);
    //   setModal(false);
    // }
  };

  const handleContainerChange = (value, id, name) => {
    editContainerDeployment({
      id: id,
      deployment_type: deployment_type,
      value: value,
      name: name,
    });
  };

  const handleSelectChecked = (id, checked) => {
    selectContainerCheck({
      id: id,
      checked: checked,
      deployment_type: deployment_type,
    });
  };

  const handleRestartChecked = (id, checked) => {
    restartContainerCheck({
      id: id,
      checked: checked,
      deployment_type: deployment_type,
    });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        {/* {loading && <Loader />} */}
        <Paper>
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Container Name</TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Port</TableCell>
                  <TableCell align="left">Replicas</TableCell>
                  <TableCell align="center">Environment</TableCell>
                  <TableCell align="left">Restart</TableCell>
                  <TableCell align="left">Volume</TableCell>
                  <TableCell align="left">Sizing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {container &&
                  container.length > 0 &&
                  container.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{ paddingRight: 0 }}
                        size="small"
                        padding="checkbox"
                      >
                        {row?.dependencies && (
                          <Checkbox
                            color="primary"
                            checked={row?.select && row?.select ? true : false}
                            onChange={(e) =>
                              handleSelectChecked(row?.uid, e.target.checked)
                            }
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "12rem" }}
                      >
                        <input
                          type="text"
                          name={"container_name"}
                          className={
                            row?.error?.container_name?.isError
                              ? `${styles.input} ${styles.error}`
                              : styles.input
                          }
                          value={row?.container_name}
                          onChange={(e) =>
                            handleContainerChange(
                              e.target.value,
                              row?.uid,
                              "container_name"
                            )
                          }
                          autoComplete="off"
                        />

                        {row?.error?.container_name?.isError && (
                          <p className={styles.errorMsg}>
                            {row?.error?.container_name?.msg}
                          </p>
                        )}
                      </TableCell>
                      <TableCell style={{ width: "4rem" }}>
                        {deployment_type === "Application"
                          ? row?.image
                          : row?.image}
                      </TableCell>
                      <TableCell component="th" style={{ width: "5rem" }}>
                        {row?.dependencies ? (
                          <>
                            <input
                              type="number"
                              name="port"
                              className={
                                row?.error?.port?.isError
                                  ? `${styles.input} ${styles.error}`
                                  : styles.input
                              }
                              value={row?.port}
                              onChange={(e) =>
                                handleContainerChange(
                                  e.target.value,
                                  row?.uid,
                                  "port"
                                )
                              }
                              autoComplete="off"
                            />
                            {row?.error?.port?.isError && (
                              <p className={styles.errorMsg}>
                                {row?.error?.port?.msg}
                              </p>
                            )}
                          </>
                        ) : (
                          row?.port
                        )}
                      </TableCell>
                      <TableCell component="th" style={{ width: "2rem" }}>
                        <input
                          type="number"
                          name={"replicas"}
                          defaultValue={1}
                          className={
                            row?.error?.replicas?.isError
                              ? `${styles.input} ${styles.error}`
                              : styles.input
                          }
                          value={row?.replicas}
                          onChange={(e) =>
                            handleContainerChange(
                              parseInt(e.target.value),
                              row?.uid,
                              "replicas"
                            )
                          }
                          autoComplete="off"
                        />
                        {row?.error?.replicas?.isError && (
                          <p className={styles.errorMsg}>
                            {row?.error?.replicas?.msg}
                          </p>
                        )}
                      </TableCell>
                      <TableCell align="center" style={{ width: "4rem" }}>
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          className={styles.actionIcon}
                          onClick={() => handleModalIconOpen(row)}
                        >
                          <img src="/images/application_list.svg" alt="card" />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={
                            row?.restart && row?.restart === "always"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRestartChecked(row?.uid, e.target.checked)
                          }
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "2rem" }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="sizing"
                          edge="start"
                          className={styles.actionIcon}
                          onClick={() => handleModalVolumnOpen(row)}
                        >
                          <LayersIcon style={{ color: "gray" }} />
                        </IconButton>
                      </TableCell>

                      <TableCell component="th" style={{ width: "2rem" }}>
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          className={styles.actionIcon}
                          onClick={() => handleModalSizingOpen(row)}
                        >
                          <PhotoSizeSelectSmallIcon style={{ color: "gray" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {container?.length === 0 && (
                <div className={styles.nodata}>
                  <text>No Data Available</text>
                </div>
              )}
            </Table>
          </TableContainer>
        </Paper>
        <AddVolumnDeploymentModal
          volumn={selectedData["volume"]}
          isOpen={showVolumnModal}
          id={selectedData.uid}
          deployment_type={deployment_type}
          handleCloseVolumn={() => setVolumnModal(false)}
        />
        <AddSizingDeploymentModal
          isOpen={showSizingModal}
          resources={selectedData["resources"]}
          id={selectedData.uid}
          handleCloseSizing={() => setSizingModal(false)}
        />

        {showModal && (
          <AddEnvironmentServicesModal
            isOpen={showModal}
            id={selectedData.uid}
            envs={selectedData["environment"]}
            setEnvs={(data_obj) => handleModalClose(data_obj)}
          />
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  addEnvironmentDeployment,
  restartContainerCheck,
  selectContainerCheck,
  editContainerDeployment,
};

const mapStateToProps = (state) => {
  const { container } = state.deploymentProcessReducer.dependenciesData;
  const { deployment_type } = state.deploymentProcessReducer.appSelectionData;

  return {
    container,
    deployment_type,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentContainerTable);
