import React, { useEffect } from "react";
import codemirror from "codemirror";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { IconButton } from "@material-ui/core";
import { useState } from "react";
import CustomEditor from "./CustomEditor";
import useGetData from "../../../hooks/useGetData";
import { useSelector } from "react-redux";
import "./hint.css";
require("codemirror/mode/xml/xml.js");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/javascript-hint");

const EditorInput = ({
  name,
  value,
  onChange,
  title,
  error,
  mode,
  accessMode,
  isLint = false,
}) => {
  const { currentAction, currentComponent } = useGetData();
  const [codeVal, setCodeVal] = useState("");
  const [valToShow, setValToShow] = useState([]);
  const uiApplication = useSelector((state) => state.uiApplicationReducer);
  const { actions } = uiApplication;
  const options = {
    lineNumbers: false,
    mode: mode,
    extraKeys: { "Ctrl-Space": "autocomplete" },
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    var orig = codemirror.hint.javascript;
    codemirror.hint.javascript = function (cm) {
      var inner = orig(cm) || {
        from: cm.getCursor(),
        to: cm.getCursor(),
        list: [],
      };
      let tempArr = [];
      if (codeVal.includes("data")) {
        if (Array.isArray(currentComponent?.[name]?.dataValue)) {
          currentComponent?.[name]?.dataValue.map((v, i) => {
            tempArr.push(`[${i}]`);
          });
        } else if (
          Object.keys(
            currentComponent?.[name]?.dataValue
              ? currentComponent?.[name]?.dataValue
              : {}
          ).length > 0
        ) {
          Object.keys(currentComponent?.[name]?.dataValue).map((v) => {
            tempArr.push(v);
          });
        }
      } else {
        for (let i = 0; i < actions.length; i++) {
          let v = `actions.${actions[i]?.name}.data`;
          tempArr.push(v);
        }
      }
      inner.list = tempArr;
      return inner;
    };
    return () => {
      codemirror.hint.javascript = function (cm) {
        var inner = orig(cm) || {
          from: cm.getCursor(),
          to: cm.getCursor(),
          list: [],
        };
        inner.list = [];
        return inner;
      };
    };
  }, [codeVal]);
  return (
    <>
      <div className="jsDataInput">
        <div
          className={`editorInput ${error ? "errorInput" : ""}`}
          style={{ width: "100%" }}
        >
          {isModalOpen ? (
            <CustomEditor
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              onChange={(value) => onChange(value)}
              mode={mode}
              isLint={isLint}
              value={value}
              title={title}
              error={error}
              name={name}
            />
          ) : null}
          <CodeMirror
            value={value}
            options={options}
            autoCursor={false}
            onChange={(editor, data, value) => {
              if (value.includes("{{")) {
                editor.showHint({ completeSingle: false });
              }
              let val = value;
              if (val === "{{") {
                val = value + "}}";
              }
              if (val.includes(".[")) {
                val = val.replace(".[", "[");
              }
              onChange(val);
              setCodeVal(val);
            }}
          />
        </div>
        <div>
          {accessMode ? null : (
            <div className="inputWithIcon">
              <div className="icon">
                <IconButton
                  color="inherit"
                  edge="start"
                  style={{ margin: 0 }}
                  onClick={() => setIsModalOpen(true)}
                >
                  <img src="/img/code-s-slash-line.svg" alt="Code" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditorInput;