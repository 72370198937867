/* eslint-disable */
import {
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  FilledInput,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import listStyles from "../../CommonStyles/listScreenStyles";
import CustomFilter from "../../../components/common/CustomFilter";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import {
  projectColumnOrder,
  projectColumns,
  projectDefaultColumnWidths,
  projectTableColumnExtensions,
} from "../../../constants/Documents/documentProjectConstants";
import {
  serviceColumnOrder,
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
} from "../../../constants/Documents/documentServicesConstant";
import {
  serviceGroupColumns,
  serviceGroupDefaultColumnWidths,
  serviceGroupTableColumnExtensions,
  serviceGroupColumnOrder,
} from "../../../constants/Documents/documentServiceGroupListConstants";
import { connect } from "react-redux";
import {
  getServicesDocumentListData,
  addServiceDocumentListFilters,
  addServiceDocumentListSort,
  clear_Service_Documents_Data,
} from "../../../redux/actions/documentationAction";
import {
  getAllServiceGroupsList,
  addserviceGroupFilters,
  addServiceGroupSort,
  serviceGroupClearData,
} from "../../../redux/actions/serviceGroupAction";
import { documentColumns } from "../../../constants/documentConstant";
import CreateDocumentsCard from "./CreateDocumentsCard";
import DevExpressTable from "../../../components/common/Tables/DevExpressTable";
import { arrayToFilter } from "../../../utils/common";
import { useRef } from "react";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import DocumentTableWithDropper from "../../../components/common/Tables/DocumentTableWithDropper";
import {
  getProjectListDocumentData,
  addProjectDocumentListDataFilters,
  addProjectDocumentListDataSort,
  clear_project_documents_data,
} from "../../../redux/actions/documentationAction";
import {
  add_standard_documents_filter,
  add_standard_document_action,
  add_standard_documents_sort,
  clearStandardDocuments,
} from "../../../redux/actions/standardDocumentAction";
import {
  arrayToSort,
  getWindowDimensions,
  getColumnWidth,
} from "../../../utils/common";
import { Pagination } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../components/common/UseDebounce";
import SearchBar from "../../../components/common/SearchBar";

const DocumentsList = (props) => {
  const {
    getProjectListDocumentData,
    addProjectDocumentListDataFilters,
    addProjectDocumentListDataSort,
    projectsListDocumentsData,
    projectsListDocumentFilters,
    projectListDocumentSort,
    project_totalcount,
    clear_project_documents_data,

    //this sorting is not working
    getServicesDocumentListData,
    addServiceDocumentListFilters,
    addServiceDocumentListSort,
    servicesListDocumentsData,
    serviceFiltersDocumentsData,
    serviceSortDocumentsData,
    getAllServiceGroupsList,
    service_totalcount,
    clear_Service_Documents_Data,

    allServiceGroupList,
    addserviceGroupFilters,
    serviceGroupFilters,
    serviceGroupSort,
    addServiceGroupSort,
    serviceGroup_totalcount,
    serviceGroupClearData,

    standard_doc_total_count,
    standard_documents_list,
    add_standard_document_action,
    add_standard_documents_filter,
    standard_documents_filters,
    add_standard_documents_sort,
    standard_documents_sort,
    clearStandardDocuments,

    documentsActiveTab,
  } = props;

  const [isDocumentTypeClicked] = useState(false);
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [currentPageServiceGroup, setCurrentPageServiceGroup] = useState(1);
  const [currentPageStandardDocuments, setCurrentPageStandardDocuments] =
    useState(1);
  const [statuses, setStatuses] = useState([]);
  const [serviceStatuses, setServiceStatuses] = useState([]);
  const [serviceGroupStatuses, setServiceGroupStatuses] = useState([]);
  const [currentPageService, setCurrentPageService] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [isImportButtonClicked, setIsImportButtonClicked] = useState(false);
  const styles = listStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const dispatch = useDispatch();
  const { userActions } = useSelector((state) => state.authReducer);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Project table data
  function createProjectData(projectData) {
    const { _id, name, status, createdon, createdby, description } =
      projectData;
    return {
      id: _id,
      name: name,
      createdby: createdby,
      // status: statuses?.length !==0 && statuses.find((s) => s._id === status),
      description: description,
      createdon: createdon,
    };
  }

  const projectRows =
    projectsListDocumentsData?.data?.data &&
      projectsListDocumentsData?.data?.data?.length > 0
      ? projectsListDocumentsData?.data?.data?.map((item) => {
        const rowData = createProjectData(item);
        return rowData;
      })
      : [];

  useEffect(async () => {
    const parsedFilters = arrayToFilter(projectsListDocumentFilters);
    const parsedSort = arrayToSort(projectListDocumentSort);
    await // fetchStatusesByResource();
      getProjectListDocumentData(
        currentPageProject,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
  }, [currentPageProject, projectListDocumentSort]);

  // useEffect(() => {
  //   if (documentsActiveTab === "projects") {
  //     checkScreenPermission(("Project" && "Document"), ["List All", "List Assigned"]);
  //   } else if (documentsActiveTab === "serviceGroups") {
  //     checkScreenPermission(("ServiceGroup"&&"Document"), ["List All", "List Assigned"]);
  //   } else if (documentsActiveTab === "service") {
  //     checkScreenPermission(("Microservice" && "Document"), ["List All", "List Assigned"]);
  //   } else if (documentsActiveTab === "standardDocuments") {
  //     checkScreenPermission("Document", ["List All", "List Assigned"]);
  //   }
  // }, []);

  useEffect(() => {
    checkScreenPermission("Document", ["List All", "List Assigned"]);
  }, []);

  // useEffect(() => {
  //   if (documentsActiveTab === "serviceGroups") {
  //     checkScreenPermission("ServiceGroup", ["List All", "List Assigned"]);
  //   }
  //   if (documentsActiveTab === "projects") {
  //     checkScreenPermission("Project", ["List All", "List Assigned"]);
  //   }
  // }, []);

  // const fetchStatusesByResource = async () => {
  //   const { _msg, _status, data } = await fetchStatusResourceByName("Project");
  //   if (_status === 200) {
  //     setStatuses(data ? data.status : []);
  //     console.log("hey hi", data);
  //   } else {
  //     console.log("error : ", _msg);
  //     enqueueSnackbar(_msg ? _msg : "Something went wrong", {
  //       variant: "error",
  //     });
  //   }
  // };

  // service table data
  function createServiceData(serviceData) {
    const { _id, name, status, createdon, createdby, description } =
      serviceData;
    return {
      id: _id,
      name: name,
      "Created By": createdby,
      createdon: createdon,
      // status: serviceStatuses && serviceStatuses.find((s) => s._id === status),
      description: description,
      // api: 0,
    };
  }

  // const fetchStatusesByResources = async () => {
  //   const { _msg, _status, data } = await fetchStatusResourceByName("Service");
  //   if (_status === 200) {
  //     setServiceStatuses(data ? data.status : []);
  //   } else {
  //     console.log("error : ", _msg);
  //     enqueueSnackbar(_msg ? _msg : "Something went wrong", {
  //       variant: "error",
  //     });
  //   }
  // };
  const serviceRows =
    servicesListDocumentsData?.data?.data &&
      servicesListDocumentsData?.data?.data?.length > 0
      ? servicesListDocumentsData?.data?.data?.map((item) => {
        const rowData = createServiceData(item);
        return rowData;
      })
      : [];

  useEffect(async () => {
    const parsedFilters = arrayToFilter(serviceFiltersDocumentsData);
    const parsedSort = arrayToSort(serviceSortDocumentsData);
    await // fetchStatusesByResources();
      getServicesDocumentListData(
        currentPageService,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
  }, [currentPageService, serviceSortDocumentsData]);

  // service group table
  function createServiceGroupData(serviceGroupData) {
    const {
      _id,
      name,
      createdon,
      status,
      createdby,
      assignedto,
      projectid,
      description,
    } = serviceGroupData;
    return {
      id: _id,
      name: name,
      createdBy: createdby,
      createdon: createdon,
      // status: serviceGroupStatuses?.length !==0 && serviceGroupStatuses.find((s) => s._id === status),
      description: description,
      assignedTo: assignedto,
      action: projectid,
    };
  }

  // const fetchServiceGroupStatusesByResources = async () => {
  //   const { _msg, _status, data } = await fetchStatusResourceByName(
  //     "ServiceGroup"
  //   );
  //   if (_status === 200) {
  //     setServiceGroupStatuses(data ? data.status : []);
  //   } else {
  //     console.log("error : ", _msg);
  //     enqueueSnackbar(_msg ? _msg : "Something went wrong", {
  //       variant: "error",
  //     });
  //   }
  // };

  const serviceGroupRows =
    allServiceGroupList?.data?.data &&
      allServiceGroupList?.data?.data?.length > 0
      ? allServiceGroupList?.data?.data?.map((item) => {
        const rowData = createServiceGroupData(item);
        return rowData;
      })
      : [];

  useEffect(async () => {
    const parsedFilters = arrayToFilter(serviceGroupFilters);
    const parsedSort = arrayToSort(serviceGroupSort);
    await // fetchServiceGroupStatusesByResources();
      getAllServiceGroupsList(
        currentPageServiceGroup,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
  }, [currentPageServiceGroup, serviceGroupSort]);

  // document table data
  function createDocumentTableData(docData) {
    const {
      _id,
      filename,
      docName,
      name,
      resourcetype,
      standard,
      version,
      createdon,
      status,
    } = docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      resourceid: standard,
      createdon: createdon,
      importedFrom: "",
      status: status
    };
  }
  const fetchDocuments = () => {
    const parsedFilters = arrayToFilter([
      ...standard_documents_filters,
      { resourcetype: "organization" },
      { resourceid: "standard" },
    ]);
    const parsedSort = arrayToSort(standard_documents_sort);
    add_standard_document_action(
      currentPageStandardDocuments,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
    setIsImportButtonClicked(false);
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPageStandardDocuments, standard_documents_sort]);

  const standardDocumentsRows =
    standard_documents_list?.data && standard_documents_list?.data?.length > 0
      ? standard_documents_list?.data.map((item) => {
        const rowData = createDocumentTableData(item);
        return rowData;
      })
      : [];

  useEffect(() => {
    return () => {
      clear_project_documents_data();
    };
  }, []);

  useEffect(() => {
    return () => {
      clearStandardDocuments();
    };
  }, []);

  useEffect(() => {
    return () => {
      serviceGroupClearData();
    };
  }, []);

  useEffect(() => {
    return () => {
      clear_Service_Documents_Data();
    };
  }, []);

  //--------------------------------------------------function for import button standard documents----------------------------------------------------------------------
  const handleImportButtonOpen = () => {
    setIsImportButtonClicked(true);
  };

  const handleImportButtonClose = () => {
    setIsImportButtonClicked(false);
  };
  // --------------------------------------------------function for managing the pagination-----------------------------------------------------------------------------
  const handleProjectListPage = (event, value) => {
    setCurrentPageProject(value);
  };
  const handleServiceListPage = (event, value) => {
    setCurrentPageService(value);
  };
  const getAllServiceGroupsListPage = (event, value) => {
    setCurrentPageServiceGroup(value);
  };

  const handleStandardDocumentsPage = (event, value) => {
    setCurrentPageStandardDocuments(value);
  };

  const handleChangeTab = (event, newValue) => {
    props.alterActiveTab("documentsActiveTab", newValue);
  };

  // -------------------------------------------search function for all the tabs-----------------------------------------------------------------------

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (documentsActiveTab === "projects") {
      if (searchInput) {
        getProjectListDocumentData(
          0,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        getProjectListDocumentData(1);
      }
    }
    if (documentsActiveTab === "serviceGroups") {
      if (searchInput) {
        getAllServiceGroupsList(
          0,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        getAllServiceGroupsList(1);
      }
    }
    if (documentsActiveTab === "service") {
      getServicesDocumentListData(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      getServicesDocumentListData(1);
    }
    if (searchInput) {
    }
    if (documentsActiveTab === "standardDocuments") {
      if (searchInput) {
        add_standard_document_action(
          0,
          JSON.stringify({
            resourcetype: "organization",
            resourceid: "standard",
            filename: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        add_standard_document_action(
          1,
          JSON.stringify({
            resourcetype: "organization",
            resourceid: "standard",
          })
        );
      }
    }
  };

  //--------------------------------------submit filter function for all the tabs-----------------------------------------------------------

  const handleSubmitFilter = () => {
    if (documentsActiveTab === "projects") {
      const parsedFilters = arrayToFilter(projectsListDocumentFilters);
      const parsedSort = arrayToSort(projectListDocumentSort);
      getProjectListDocumentData(1, parsedFilters, JSON.stringify(parsedSort));
    }
    if (documentsActiveTab === "serviceGroups") {
      const parsedFilters = arrayToFilter(serviceGroupFilters);
      const parsedSort = arrayToSort(serviceGroupSort);
      getAllServiceGroupsList(1, parsedFilters, JSON.stringify(parsedSort));
    }
    if (documentsActiveTab === "service") {
      const parsedFilters = arrayToFilter(serviceFiltersDocumentsData);
      const parsedSort = arrayToSort(serviceSortDocumentsData);
      getServicesDocumentListData(1, parsedFilters, JSON.stringify(parsedSort));
    }
    if (documentsActiveTab === "standardDocuments") {
      const parsedFilters = arrayToFilter([
        ...standard_documents_filters,
        { resourcetype: "organization" },
        { resourceid: "standard" },
      ]);
      const parsedSort = arrayToSort(standard_documents_sort);
      add_standard_document_action(
        1,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  };
  // -------------------------------------------clear filters code for all the tabs--------------------------------------------------

  const handleClearFilters = () => {
    if (documentsActiveTab === "projects") {
      getProjectListDocumentData(1, JSON.stringify({}));
    }
    if (documentsActiveTab === "serviceGroups") {
      getAllServiceGroupsList(1, JSON.stringify({}));
    }
    if (documentsActiveTab === "service") {
      getServicesDocumentListData(1, JSON.stringify({}));
    }
    if (documentsActiveTab === "standardDocuments") {
      add_standard_document_action(
        1,
        JSON.stringify({ resourcetype: "organization", resourceid: "standard" })
      );
    }
  };

  const debouceOnchange = useDebounce((e) => setSearchInput(e), 500);

  //------------------------------------------ Begining of html----------------------------------------------
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            style={{ marginLeft: 12 }}
            items={[
              // { title: "Document List", path: "/" },
              {
                title: "Documents",
                path: "/documents",
              },
              {
                title:
                  documentsActiveTab === "projects"
                    ? "Project"
                    : documentsActiveTab === "serviceGroups"
                      ? "Service Group"
                      : documentsActiveTab === "service"
                        ? "Service"
                        : "Standard Document",
              },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <Tabs
                value={documentsActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
              >
                <Tab
                  label={`Project ${project_totalcount &&
                    checkCompPermission("Project", ["List All", "List Assigned"])
                    ? ` (${project_totalcount})`
                    : ""
                    }`}
                  value={"projects"}
                  disabled={
                    !checkCompPermission("Project", ["List All", "List Assigned"])
                  }
                />
                <Tab
                  label={`Service Group ${checkCompPermission("ServiceGroup", [
                    "List All",
                    "List Assigned",
                  ]) && serviceGroup_totalcount
                    ? ` (${serviceGroup_totalcount})`
                    : ""
                    }`}
                  value={"serviceGroups"}
                  disabled={
                    !checkCompPermission("ServiceGroup", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />
                <Tab
                  label={`Service ${service_totalcount &&
                    checkCompPermission("Microservice", [
                      "List All",
                      "List Assigned",
                    ])
                    ? ` (${service_totalcount})`
                    : ""
                    }`}
                  value={"service"}
                  disabled={
                    !checkCompPermission("Microservice", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />

                <Tab
                  label={`Standard Document ${standard_doc_total_count &&
                    checkCompPermission("Document", ["List All", "List Assigned"])
                    ? ` (${standard_doc_total_count})`
                    : ""
                    }`}
                  value={"standardDocuments"}
                  disabled={
                    !checkCompPermission("Document", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />
              </Tabs>
            </div>

            <div
              className={styles.topContentItem}
              style={{ justifyContent: "flex-end" }}
            >
              <SearchBar
                PlaceHolderLabel={
                  documentsActiveTab === "projects"
                    ? "Search Project Name"
                    : documentsActiveTab === "serviceGroups"
                      ? "Search Service Group Name"
                      : documentsActiveTab === "service"
                        ? "Search Service Name"
                        : "Search Document Name"
                }
                handleSearch={handleSearch}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider
                orientation="vertical"
                className={styles.MuiDivider}
                onClick={handleSubmitFilter}
              />

              {documentsActiveTab === "projects" && (
                <CustomFilter
                  columns={projectColumns}
                  filters={projectsListDocumentFilters}
                  setFilters={addProjectDocumentListDataFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}

              {documentsActiveTab === "serviceGroups" && (
                <CustomFilter
                  columns={serviceGroupColumns}
                  filters={serviceGroupFilters}
                  setFilters={addserviceGroupFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}

              {documentsActiveTab === "service" && (
                <CustomFilter
                  columns={serviceColumns}
                  filters={serviceFiltersDocumentsData}
                  setFilters={addServiceDocumentListFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {documentsActiveTab === "standardDocuments" && (
                <CustomFilter
                  columns={documentColumns}
                  filters={standard_documents_filters}
                  setFilters={add_standard_documents_filter}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              <Divider orientation="vertical" className={styles.MuiDivider} />

              {documentsActiveTab === "standardDocuments" &&
                (!isImportButtonClicked ? (
                  <>
                    <Tooltip title="Import" arrow>
                      <IconButton
                        color="inherit"
                        aria-label="open dropper"
                        edge="start"
                        className={styles.importIconColor}
                        style={{ margin: "0px 10px" }}
                      // onClick={handleImportButtonOpen}
                      >
                        <img
                          src="/images/Upload.svg"
                          alt="card"
                          className={styles.imageIcon}
                          style={{ opacity: 0.6 }}
                          onClick={(e) => {
                            if (checkCompPermission("Document", ["Create"])) {
                              handleImportButtonOpen();
                            } else {
                              enqueueSnackbar(
                                `You do not have permission to Create Standard Document. 
                  Please contact your Admin.`,
                                { variant: "error" }
                              );
                            }
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Close" arrow>
                    <IconButton
                      color="inherit"
                      aria-label="close"
                      edge="start"
                      className={styles.importIconColor}
                      style={{ margin: "0px 10px" }}
                      onClick={handleImportButtonClose}
                    >
                      <img src="/images/closeImg.svg" alt="import" />
                    </IconButton>
                  </Tooltip>
                ))}

              {documentsActiveTab === "standardDocuments" && (
                <Divider orientation="vertical" className={styles.MuiDivider} />
              )}

              <AppButton
                style={{
                  paddingLeft: "22px",
                  paddingRight: "22px",
                  width: "157px",
                }}
                disabled={!checkCompPermission("DocumentType", ["List All"])}
                buttonName="Document Type"
                variant="contained"
                className="btnsmall"
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
          {isDocumentTypeClicked ? <CreateDocumentsCard /> : ""}
        </div>

        {/* table for project list */}
        {documentsActiveTab === "projects" && (
          <Paper className={styles.pageContent}>
            <DevExpressTable
              columns={projectColumns}
              rows={projectRows}
              defaultColumnWidths={getColumnWidth(
                projectDefaultColumnWidths,
                windowDimensions
              )}
              tableColumnExtensions={projectTableColumnExtensions}
              totalCount={projectsListDocumentsData._totalcount}
              columnOrder={projectColumnOrder}
              loading={projectsListDocumentsData.loading}
              sort={projectListDocumentSort}
              setSort={addProjectDocumentListDataSort}
              height={windowDimensions.height - 260}
              isCustomHeight={true}
              hideColumnChooser={true}
              noDataMsg="No Project Found"
            />
          </Paper>
        )}

        {/* Table for service-groups */}
        {documentsActiveTab === "serviceGroups" && (
          <Paper className={styles.pageContent}>
            <DevExpressTable
              columns={serviceGroupColumns}
              rows={serviceGroupRows}
              defaultColumnWidths={getColumnWidth(
                serviceGroupDefaultColumnWidths,
                windowDimensions
              )}
              totalCount={allServiceGroupList._totalcount}
              // defaultColumnWidths={serviceGroupDefaultColumnWidths}
              tableColumnExtensions={serviceGroupTableColumnExtensions}
              columnOrder={serviceGroupColumnOrder}
              setSort={addServiceGroupSort}
              sort={serviceGroupSort}
              loading={allServiceGroupList.loading}
              height={windowDimensions.height - 260}
              isCustomHeight={true}
              hideColumnChooser={true}
              noDataMsg="No Service Group Found"
            />
          </Paper>
        )}
        {/* </TabPanel> */}

        {/* Table for services */}

        {documentsActiveTab === "service" && (
          <Paper className={styles.pageContent}>
            <DevExpressTable
              columns={serviceColumns}
              rows={serviceRows}
              defaultColumnWidths={getColumnWidth(
                serviceDefaultColumnWidths,
                windowDimensions
              )}
              // defaultColumnWidths={serviceDefaultColumnWidths}
              tableColumnExtensions={serviceTableColumnExtensions}
              columnOrder={serviceColumnOrder}
              totalCount={servicesListDocumentsData._totalcount}
              loading={servicesListDocumentsData.loading}
              sort={serviceSortDocumentsData}
              setSort={addServiceDocumentListSort}
              height={windowDimensions.height - 260}
              isCustomHeight={true}
              hideColumnChooser={true}
              noDataMsg="No Service Found"
            />
          </Paper>
        )}

        {documentsActiveTab === "standardDocuments" && (
          <Paper className={styles.pageContent}>
            <DocumentTableWithDropper
              resourceId="standard"
              resourceType={"organization"}
              isEditMode={isImportButtonClicked}
              sort={standard_documents_sort}
              setSort={add_standard_documents_sort}
              loading={standard_documents_list.loading}
              totalCount={standard_documents_list._totalcount}
              rows={standardDocumentsRows}
              fetchDocuments={fetchDocuments}
              noDataMsg="No Standard Documents Found"
              isDocumentModule={true}
            />
          </Paper>
        )}

        {projectRows.length !== 0 && documentsActiveTab === "projects" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(projectsListDocumentsData._totalcount / 10)}
                page={currentPageProject}
                onChange={handleProjectListPage}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}

        {serviceGroupRows.length !== 0 &&
          documentsActiveTab === "serviceGroups" && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(allServiceGroupList._totalcount / 10)}
                  page={currentPageServiceGroup}
                  onChange={getAllServiceGroupsListPage}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}

        {serviceRows.length !== 0 && documentsActiveTab === "service" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(servicesListDocumentsData._totalcount / 10)}
                page={currentPageService}
                onChange={handleServiceListPage}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        {standardDocumentsRows.length !== 0 &&
          documentsActiveTab === "standardDocuments" && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(standard_documents_list._totalcount / 10)}
                  page={currentPageStandardDocuments}
                  onChange={handleStandardDocumentsPage}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
      </div>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent>
          <CreateDocumentsCard open={open} setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectListDocumentData,
  addProjectDocumentListDataFilters,
  addProjectDocumentListDataSort,

  getServicesDocumentListData,
  addServiceDocumentListFilters,
  addServiceDocumentListSort,

  getAllServiceGroupsList,
  addserviceGroupFilters,
  addServiceGroupSort,
  serviceGroupClearData,
  clear_Service_Documents_Data,

  add_standard_document_action,
  add_standard_documents_filter,
  add_standard_documents_sort,
  clearStandardDocuments,
  clear_project_documents_data,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    projectsListDocumentsData,
    projectsListDocumentFilters,
    projectListDocumentSort,
  } = state.documentationReducer;
  const { _totalcount: project_totalcount } = projectsListDocumentsData;

  const {
    servicesListDocumentsData,
    serviceFiltersDocumentsData,
    serviceSortDocumentsData,
  } = state.documentationReducer;
  const { _totalcount: service_totalcount } = servicesListDocumentsData;

  const { allServiceGroupList, serviceGroupFilters, serviceGroupSort } =
    state.serviceGroupReducer;
  const { _totalcount: serviceGroup_totalcount } = allServiceGroupList;

  const {
    standard_documents_list,
    standard_documents_filters,
    standard_documents_sort,
  } = state.standardDocumentReducer;

  const { _totalcount: standard_doc_total_count, loading } =
    standard_documents_list;

  const { documentsActiveTab } = state.activeTabReducer;
  const { userInfo } = state.userDetails;

  return {
    //project reducer data
    projectsListDocumentsData,
    projectsListDocumentFilters,
    projectListDocumentSort,
    project_totalcount,

    //service reducer data
    servicesListDocumentsData,
    serviceFiltersDocumentsData,
    service_totalcount,
    serviceSortDocumentsData,

    //service-group reducer data
    allServiceGroupList,
    serviceGroupSort,
    serviceGroupFilters,
    serviceGroup_totalcount,

    //standard-document reducer data
    standard_documents_list,
    standard_doc_total_count,
    standard_documents_filters,
    standard_documents_sort,
    loading,

    //tab reducer data
    documentsActiveTab,

    //userInfo reducer data
    userInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);
