import TriggerAction from "../../../atoms/rightSide/trigger/TriggerAction";
import ShowCondition from "../../../atoms/rightSide/trigger/ShowCondition";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";
import { useDispatch } from "react-redux";

function ButtonTriggers() {
  const dispatch = useDispatch();
  const { currentProject, currentPage, currentComponent } = useGetData();

  const onChange = (name, action) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: action ? action : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <TriggerAction
          label="On Click"
          value={currentProject?.actions.find(
            (action) => action._id === currentComponent?.onClick
          )}
          project={currentProject}
          onChange={(e, action) => onChange("onClick", action?._id)}
        />
      </div>
      <div className="field">
        <TriggerAction
          label="On Init"
          value={currentProject?.actions.find(
            (action) => action._id === currentComponent?.onInit
          )}
          project={currentProject}
          onChange={(e, action) => onChange("onInit", action?._id)}
        />
      </div>

      <ShowCondition
        project={currentProject}
        currentPage={currentPage}
        selectedComponent={currentComponent}
      />
    </div>
  );
}

export default ButtonTriggers;
