import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";

import devExpressStyles from "../styles/DevExpressTableStyles";

import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";

const ActionCell = ({ ...restProps }) => {
  const styles = devExpressStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div style={{ left: -20, position: "relative" }}>
        <Tooltip title="Edit Config">
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={() => setCloudConfigModal(true)}
          >
            <img src="/images/editLine.svg" alt="icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Config">
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => handleDeleteTemplate(templateid)}
          >
            <img src="/images/deleteLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

const DateCell = ({ ...restProps }) => {
  return (
    <div
      style={{
        margin: "15px",
        padding: "0px",
        border: "1px solid black",
        backgroundColor: "none",
      }}
    >
      {moment(restProps?.row?.creationTimestamp).startOf("day").fromNow()}
    </div>
  );
};
const ClusterIPColumn = ({ ...restProps }) => {
  return restProps?.row?.spec?.clusterIP;
};
const ExternalIP = ({ ...restProps }) => {
  return restProps?.row?.status?.loadBalancer?.ingress ? (
    <>
      <Tooltip
        title={`http://${restProps?.row?.status?.loadBalancer?.ingress[0].hostname}:${restProps?.row?.spec?.ports[0].port}`}
        placement="bottom-start"
      >
        <a
          href={`http://${restProps?.row?.status?.loadBalancer?.ingress[0].hostname}:${restProps?.row?.spec?.ports[0].port}`}
          target="_blank"
        >
          http://{restProps?.row?.status?.loadBalancer?.ingress[0].hostname}:
          {restProps?.row?.spec?.ports[0].port}
        </a>
      </Tooltip>
    </>
  ) : (
    ""
  );
};

const NameSpace = ({ ...restProps }) => {
  return restProps?.row?.metadata?.namespace;
};
const CreationDays = ({ ...restProps }) => {
  return restProps?.row?.metadata?.creationTimestamp;
};

const Cell = (props) => {
  const { column } = props;
  if (column.name === "nond") {
    return <DateCell {...props} />;
  } else if (column.name === "clusterip" && column.for === "clusterService") {
    return (
      <VirtualTable.Cell {...props}>
        <ClusterIPColumn {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "external" && column.for === "clusterService") {
    return (
      <VirtualTable.Cell {...props}>
        <ExternalIP {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "namespace" && column.for === "clusterService") {
    return (
      <VirtualTable.Cell {...props}>
        <NameSpace {...props} />
      </VirtualTable.Cell>
    );
  } else if (
    column.name === "creationTimestamp" &&
    column.for === "clusterService"
  ) {
    return (
      <VirtualTable.Cell {...props}>
        <CreationDays {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} />;
  }
};

const NoDataFound = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        textAlign: "center",
        top: "calc(50% - 4rem)",
        left: "calc(50% - 5rem)",
        maxWidth: "11rem",
      }}
    >
      <img src="/images/notFound.svg" alt="notfound" />
      <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
    </div>
  );
};

const NewDevExpressTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 4) {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
    // }
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable2} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 260
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default NewDevExpressTable;
