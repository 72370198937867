import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { editUiApplication } from "../../redux/actions/uiApplicationAction";
import { UiApplicationService } from "../services/UiApplicationService";
import Loader from "../components/atoms/loader/Loader";
import Layout from "../components/organisms/Layout";
import useGetData from "../hooks/useGetData";

const Project = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const apiService = new UiApplicationService();
  const { currentProject, currentPage } = useGetData();
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchCall(), []);

  const fetchActions = async (uiAppID) =>
    await apiService.fetchAllActions(uiAppID);

  const fetchApplication = async (uiAppID) =>
    await apiService.getApplicationById(uiAppID);

  const fetchCall = async () => {
    try {
      setLoading(true);
      const uiAppId = props?.match?.params?.projectId;
      const [{ data }, actionsList] = await Promise.all([
        fetchApplication(uiAppId),
        fetchActions(uiAppId),
      ]);
      dispatch(
        editUiApplication({
          data: {
            ...data.data,
            id: data?.data?._id,
            pages: data?.data?.pages,
            actions: actionsList?.data?.data.reverse(),
            globalCss:
              typeof data?.data?.globalCss === "string"
                ? JSON.parse(data?.data?.globalCss)
                : data?.data?.globalCss,
          },
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Ui Application Get Failed", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : currentProject ? (
        currentPage ? (
          <Layout />
        ) : (
          <h1 style={{ marginTop: 100 }}>Page not found</h1>
        )
      ) : (
        <h1 style={{ marginTop: 100 }}>Project not found</h1>
      )}
    </div>
  );
};

export default Project;
