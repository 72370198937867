import {
    GET_ONTOLOGY_FIELDS,
    GET_ONTOLOGY_FIELDGROUPS,
    GET_ONTOLOGY_SECTIONS,
    GET_ONTOLOGY_OBJECTS,
    ADD_ONTOLOGY_FIELDS_FILTER,
    ADD_ONTOLOGY_FIELDGROUP_FILTER,
    ADD_ONTOLOGY_SECTION_FILTER,
    ADD_ONTOLOGY_OBJECT_FILTER,
    ADD_ONTOLOGY_SORT,
    DELETE_ONTOLOGY,
    CLEAR_ONTOLOGY_OBJECTS_DATA,
    CLEAR_ONTOLOGY_FIELDGROUP_DATA,
    CLEAR_ONTOLOGY_FIELDS_DATA,
    CLEAR_ONTOLOGY_SECTIONS_DATA,
    OBJECT_COLUMN_HIDDEN,
    SECTION_COLUMN_HIDDEN,
    FIELDGROUP_COLUMN_HIDDEN,
    FIELD_COLUMN_HIDDEN
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    fields: {
        data: [],
        error: false,
        loading: false,
        _totalcount: 0
    },
    fieldgroups: {
        data: [],
        error: false,
        loading: false,
        _totalcount: 0
    },
    sections: {
        data: [],
        error: false,
        loading: false,
        _totalcount: 0
    },
    objects: {
        data: [],
        error: false,
        loading: false,
        _totalcount: 0
    },
    objectsHidden:[],
    sectionHidden:[],
    fieldGroupsHidden:[],
    fieldsHidden:[],
    fieldsFilters: [
        { id: uuidv4(), column: "", operator: "", value: "" }
    ],
    fieldgroupFilters: [
        { id: uuidv4(), column: "", operator: "", value: "" }
    ],
    sectionFilters: [
        { id: uuidv4(), column: "", operator: "", value: "" }
    ],
    objectFilters: [
        { id: uuidv4(), column: "", operator: "", value: "" }
    ],
    objectSort: [
        { columnName: "name", direction: "desc" }
    ],
    sectionSort: [
        { columnName: "name", direction: "desc" }
    ],
    fieldgroupSort: [
        { columnName: "name", direction: "desc" }
    ],
    fieldSort: [
        { columnName: "name", direction: "desc" }
    ],
}

const ontologyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ONTOLOGY_FIELDS: {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...action.payload
                }
            }
        }
        case GET_ONTOLOGY_FIELDGROUPS: {
            return {
                ...state,
                fieldgroups: {
                    ...state.fieldgroups,
                    ...action.payload
                }
            }
        }
        case GET_ONTOLOGY_SECTIONS: {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    ...action.payload
                }
            }
        }
        case GET_ONTOLOGY_OBJECTS: {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    ...action.payload
                }
            }
        }
        case ADD_ONTOLOGY_FIELDS_FILTER: {
            return {
                ...state,
                fieldsFilters: action.payload
            }
        }
        case ADD_ONTOLOGY_FIELDGROUP_FILTER: {
            return {
                ...state,
                fieldgroupFilters: action.payload
            }
        }
        case ADD_ONTOLOGY_SECTION_FILTER: {
            return {
                ...state,
                sectionFilters: action.payload
            }
        }
        case ADD_ONTOLOGY_OBJECT_FILTER: {
            return {
                ...state,
                objectFilters: action.payload
            }
        }
        case ADD_ONTOLOGY_SORT: {
            const { active, sort } = action.payload;
            if (active === "object") {
                return {
                    ...state,
                    objectSort: [...sort]
                }
            } else if (active === "section") {
                return {
                    ...state,
                    sectionSort: [...sort]
                }
            } else if (active === "fieldgroup") {
                return {
                    ...state,
                    fieldgroupSort: [...sort]
                }
            } else if (active === "field") {
                return {
                    ...state,
                    fieldSort: [...sort]
                }
            } else {
                return state
            }
        }
        case DELETE_ONTOLOGY: {
            const { type, id } = action.payload;
            if (type === "field") {
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        data: state.fields.data.filter((item) => item._id !== id),
          _totalcount: state.fields._totalcount - 1,
                        
                    }
                }
            } else if (type === "fieldgroup") {
                return {
                    ...state,
                    fieldgroups: {
                        ...state.fieldgroups,
                        data: state.fieldgroups.data.filter((item) => item._id !== id),
                        _totalcount: state.fieldgroups._totalcount - 1,
                    }
                }
            } else if (type === "section") {
                return {
                    ...state,
                    sections: {
                        ...state.sections,
                        data: state.sections.data.filter((item) => item._id !== id),
                        _totalcount: state.sections._totalcount - 1,
                    }
                }
            } else {
                return state
            }
        }
        case CLEAR_ONTOLOGY_OBJECTS_DATA:{
            return{
                ...state,
                objects:{}
            }
        }
        case CLEAR_ONTOLOGY_FIELDGROUP_DATA:{
            return{
                ...state,
                fieldgroups:{}
            }
        }
        case CLEAR_ONTOLOGY_FIELDS_DATA:{
            return{
                ...state,
                fields:{}
            }
        }
        case CLEAR_ONTOLOGY_SECTIONS_DATA:{
            return{
                ...state,
                sections:{}
            }
        }
        case OBJECT_COLUMN_HIDDEN:{
            return{
                ...state,
                objectsHidden:[...action.payload]
            }
        }
        case SECTION_COLUMN_HIDDEN:{
            return{
                ...state,
                sectionHidden:[...action.payload]
            }
        }
        case FIELDGROUP_COLUMN_HIDDEN:{
            return{
                ...state,
                fieldGroupsHidden:[...action.payload]
            }
        }
        case FIELD_COLUMN_HIDDEN:{
            return{
                ...state,
                fieldsHidden:[...action.payload]
            }
        }
        default:
            return state
    }
}

export default ontologyReducer