/* eslint-disable */
import {
  Paper,
  Grid,
  Divider,
  Container,
  IconButton,
  InputAdornment,
  FilledInput,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";

import {
  getProjectList,
  addProjectFilters,
  addProjectSort,
  addProjectHidden,
  clearProjects,
} from "../../../redux/actions/projectAction";

import ProjectsCard from "../../../components/Projects/ProjectsCard";
import DevExpressTable from "../../../components/common/Tables/DevExpressTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  projectColumnHidden,
  projectColumnOrder,
  projectColumns,
  projectDefaultColumnWidths,
  projectTableColumnExtensions,
} from "../../../constants/projectsConstant";
import { connect } from "react-redux";
import CustomFilter from "../../../components/common/CustomFilter";

import { useTranslation } from "react-i18next";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../../utils/common";
import { useRef } from "react";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import { useSnackbar } from "notistack";
import NotFoundContent from "../../Errors/NotFoundContent";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import useDebounce from "../../../components/common/UseDebounce";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const ProjectsList = (props) => {
  const {
    data,
    getProjectList,
    loading,
    addProjectFilters,
    projectFilters,
    projectSort,
    addProjectSort,
    addProjectHidden,
    projectHidden,
    _totalcount,
    clearProjects,
    alterActiveTab,
    deleteProjectStatus,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [listView, setListView] = useState(true);
  const [cardView, setCardView] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: projectColumns,
    widths: projectDefaultColumnWidths,
    extentions: projectTableColumnExtensions,
    orders: projectColumnOrder,
    hides: [],
  });

  const [currentFavouritePage, setCurrentFavouritePage] = useState(1);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    alterActiveTab("Project", "Project");
  }, []);

  useEffect(() => {
    checkScreenPermission("Project", ["List All", "List Assigned"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Project");
      if (_status === 200) {
        const projData = getTableData(
          data,
          projectColumns,
          projectDefaultColumnWidths,
          projectTableColumnExtensions,
          projectColumnOrder,
          projectHidden,
          "project"
        );
        setTableData(projData);
        if (
          JSON.stringify(projectHidden) === JSON.stringify(projectColumnHidden)
        ) {
          addProjectHidden(projData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    const parsedFilters = arrayToFilter(projectFilters);
    const parsedSort = arrayToSort(projectSort);
    if (checkCompPermission("StatusProfile", ["List All"])) {
      await fetchStatusesByResource();
    }
    getProjectList(currentPage, parsedFilters, JSON.stringify(parsedSort), 10);
  }, [currentPage, projectSort]);

  useEffect(async () => {
    if(deleteProjectStatus === true) {
      const parsedFilters = arrayToFilter(projectFilters);
      const parsedSort = arrayToSort(projectSort);
      if (checkCompPermission("StatusProfile", ["List All"])) {
        await fetchStatusesByResource();
      }
      getProjectList(currentPage, parsedFilters, JSON.stringify(parsedSort), 10);
    }
  }, [deleteProjectStatus]);

  const fetchStatusesByResource = async () => {
    const { _msg, _status, data } = await fetchStatusResourceByName("Project");
    if (_status === 200) {
      setStatuses(data ? data.status : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  function createData(projectData) {
    const {
      _id,
      name,
      status,
      createdby,
      createdon,
      isFavourite,
      isSubscribe,
    } = projectData;
    return {
      ...projectData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      status: status,
      isFavourite: isFavourite,
      isSubscribe: isSubscribe,
      statuses: statuses,
    };
  }

  useEffect(() => {
    if (!isFavouriteClick) {
      setRows(
        data?.data && data?.data?.length > 0
          ? data.data.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setRows(
        data?.data && data?.data?.length > 0
          ? data?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [data]);

  const styles = listStyles();

  const histroy = useHistory();

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      getProjectList(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      getProjectList(1);
    }
  };

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(projectFilters);
    const parsedSort = arrayToSort(projectSort);
    getProjectList(1, parsedFilters, JSON.stringify(parsedSort), 10);
  };

  const handleClearFilters = () => {
    getProjectList(1, JSON.stringify({}));
  };

  const handleToggleFavouriteProjects = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(projectFilters);
      const parsedSort = arrayToSort(projectSort);
      getProjectList(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort),
        10
      );
      setRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPage(1);
    } else {
      getProjectList();
      setRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavouritePage(1);
    }
  };

  useEffect(() => {
    return () => {
      clearProjects();
    };
  }, []);

  const debouceOnchange = useDebounce((e) => setSearchInput(e), 500);

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Projects", path: "/projects" },
              { title: "Project List" },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("header.projectlist")}{" "}
                {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                PlaceHolderLabel={"Search Project Name"}
                handleSearch={handleSearch}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider
                orientation="vertical"
                className={styles.MuiDivider}
                onClick={handleSubmitFilter}
              />
              <CustomFilter
                columns={tableData.columns}
                filters={projectFilters}
                setFilters={addProjectFilters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />

              <Tooltip title="Favourite" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: "0px 10px" }}
                  onClick={() => handleToggleFavouriteProjects()}
                >
                  {isFavouriteClick ? (
                    <img src="/images/heartFilled.svg" alt="card" />
                  ) : (
                    <img src="/images/heartLine.svg" alt="card" />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Card View" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: 0 }}
                  onClick={() => {
                    setCardView(true);
                    setListView(false);
                  }}
                  className={`${cardView ? "toggleActive" : ""} ${styles.toggleicons
                    }`}
                >
                  <img src="/images/cardIconBlack.svg" alt="card" />
                </IconButton>
              </Tooltip>

              <Tooltip title="List View" arrow>
                <IconButton
                  color="inherit"
                  aria-label="open cardview"
                  edge="start"
                  style={{ margin: "0 20px" }}
                  onClick={() => {
                    setCardView(false);
                    setListView(true);
                  }}
                  className={`${listView ? "toggleActive" : ""} ${styles.toggleicons
                    }`}
                >
                  <img src="/images/listBlack.svg" alt="card" />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="create"
                variant="contained"
                startIcon={
                  <AddIcon style={{ width: "16px", height: "16px" }} />
                }
                disabled={!checkCompPermission("Project", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/projects/add")}
              />
            </div>
          </div>
        </div>

        {listView && (
          <Paper className={styles.pageContent}>
            {tableLoading ? (
              <Loader />
            ) : (
              <DevExpressTable
                width={windowDimensions.width}
                columns={tableData.columns}
                rows={isFavouriteClick ? (
                  rows?.length > 0
                    ? rows?.slice(
                      (currentFavouritePage - 1) * 10,
                      currentFavouritePage * 10
                    )
                    : []
                ) : (rows)}
                defaultColumnWidths={tableData.widths}
                tableColumnExtensions={tableData.extentions}
                columnOrder={tableData.orders}
                defaultHiddenColumnNames={tableData.hides}
                loading={loading}
                currentPage={isFavouriteClick ? currentFavouritePage : currentPage}
                setCurrentPage={setCurrentPage}
                isCustomHeight={true}
                sort={projectSort}
                setSort={addProjectSort}
                hidden={projectHidden}
                setHidden={addProjectHidden}
                noDataMsg="No Project Found"
              />
            )}
          </Paper>
        )}
        {cardView &&
          (rows.length === 0 ? (
            <div style={{ margin: "7rem 30px 0 30px" }}>
              <NotFoundContent message={"No Project Found"} />
            </div>
          ) : (
            <div
              className={styles.pageContent}
              style={{ paddingBottom: "5rem" }}
            >
              <Grid container spacing={3}>
                {rows?.map((row, index) => (
                  <ProjectsCard row={row} key={index} />
                ))}
              </Grid>
            </div>
          ))}
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {isFavouriteClick ? <Pagination
                count={Math.ceil(rows.length / 10)}
                page={currentFavouritePage}
                onChange={(e, val) => setCurrentFavouritePage(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
                : <Pagination
                  count={Math.ceil(_totalcount / 10)}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />}
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addProjectFilters,
  addProjectSort,
  addProjectHidden,
  clearProjects,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { projectsList, projectFilters, projectSort, projectHidden } =
    state.projectReducer;
  const { data, error, loading, deleteProjectStatus } = projectsList;
  return {
    data,
    error,
    loading,
    projectFilters,
    projectSort,
    projectHidden,
    _totalcount: projectsList?._totalcount,
    deleteProjectStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
