import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../components/common/styles/InputTableStyle";
import { connect } from "react-redux";
import { fetchAllClusterPods } from "../../redux/actions/clusterActions";
import { fetchClusterReplicaSets } from "../../utils/clusterutils/clusterUtils";
import { add_cluster_replica_sets_list } from "../../redux/actions/clusterActions";
import listStyles from "../CommonStyles/listScreenStyles";
import { Pagination } from "@material-ui/lab";
import Loader from "../../components/common/stuff/Loader";

const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function ClusterReplicaSetsPreview({
  add_cluster_replica_sets_list,
  clusterName,
  configName,
}) {
  const tStyles = tempStyles();
  const styles = listStyles();
  const [currentPageCluster, setCurrentPageCluster] = useState(1);
  const [filterContainer, setFilterContainer] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    const fetchAllReplicaSets = async () => {
      const { data, _status, _msg } = await fetchClusterReplicaSets(
        configName,
        clusterName
      );
      if (_status === 200) {
        add_cluster_replica_sets_list(data);
        setFilterContainer(data);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };
    fetchAllReplicaSets();
  }, [currentPageCluster]);

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        <Paper>
          {tableLoading ? (
            <Loader />
          ) : (
            <TableContainer>
              <Table className={styles.customTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Namespace</TableCell>
                    <TableCell align="left">Pods</TableCell>
                    <TableCell align="left">Labels</TableCell>
                    <TableCell align="left">Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterContainer &&
                    filterContainer.length > 0 &&
                    filterContainer.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "5rem", height: "3rem" }}
                        >
                          {row?.metadata?.name}
                        </TableCell>
                        <TableCell style={{ width: "8rem" }}>
                          {row?.metadata?.namespace}
                        </TableCell>
                        <TableCell padding="checkbox">1/1</TableCell>
                        <TableCell component="th" style={{ width: "4rem" }}>
                          {row?.metadata?.labels?.app}
                        </TableCell>
                        <TableCell component="th" style={{ width: "4rem" }}>
                          {row?.metadata?.creationTimestamp}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                {filterContainer?.length === 0 && (
                  <div className={styles.nodata}>
                    <text>No Data Available</text>
                  </div>
                )}
              </Table>
            </TableContainer>
          )}
        </Paper>
        <div style={{ marginTop: "25px" }}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(filterContainer?.length / 10)}
              page={currentPageCluster}
              onChange={(e, val) => setCurrentPageCluster(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  add_cluster_replica_sets_list,
};

const mapStateToProps = (state) => {
  const { container } = state.deploymentProcessReducer.dependenciesData;
  const { deployment_type } = state.deploymentProcessReducer.appSelectionData;
  const { data } = state.clusterReducer.clusterPodsList;
  // const {clustername} =state.deploymentProcessReducer.hostingData;
  return {
    container,
    deployment_type,
    data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterReplicaSetsPreview);
