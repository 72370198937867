import {
  EDIT_UI_APPLICAION,
  UIAPPLICATION_LIST_DATA,
  UIAPPLICATION_FILTER,
  UIAPPLICATION_UPDATE,
  UIAPPLICATION_SORT,
  UIAPPLICATION_HIDDEN_COULMNS,
  DELETE_UI_APPLICAION,
  TEMPLATE_LIST_DATA,
  TEMPLATE_FILTER,
  TEMPLATE_SORT,
  TEMPLATE_HIDDEN_COULMNS,
  DELETE_TEMPLATE,
  SELECTED_TEMPLATE,
  SELECTED_PAGE,
  ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID,
  ALTER_FAVOURITE_UI_APPLICATION_BY_ID,
  SET_GLOBAL_CSS,
  LINK_UIAPPLICATION_ID,
  UNLINK_UIAPPLICATION_ID,
} from "../uiActionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  uiApplication_list: {
    data: [],
    error: false,
    loading: false,
  },
  uiApplicationFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  uiApplicationSort: [{ columnName: "createdon", direction: "desc" }],
  uiApplicationHiddenCols: ["updatedby", "updatedon", "createdby"],
  //   template
  template_list: {
    templateData: [],
    templateError: false,
    templateLoading: false,
  },
  templateFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  templateSort: [{ columnName: "createdon", direction: "desc" }],
  templateHiddenCols: ["createdby"],
  singlePage: [],
};

export default function uiApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case UIAPPLICATION_LIST_DATA: {
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          ...action.payload,
          deleteAppStatus: false,
        },
      };
    }
    case EDIT_UI_APPLICAION: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case LINK_UIAPPLICATION_ID: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case UNLINK_UIAPPLICATION_ID: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case UIAPPLICATION_FILTER: {
      return {
        ...state,
        uiApplicationFilter: [...action.payload],
      };
    }
    case UIAPPLICATION_SORT: {
      return {
        ...state,
        uiApplicationSort: [...action.payload],
      };
    }
    case UIAPPLICATION_HIDDEN_COULMNS: {
      return {
        ...state,
        uiApplicationHiddenCols: [...action.payload],
      };
    }
    case ALTER_FAVOURITE_UI_APPLICATION_BY_ID: {
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          data:
            state.uiApplication_list.data.length !== 0 &&
            state.uiApplication_list.data.map((proj) => {
              if (proj?._id === action.payload.uiAppid) {
                return { ...proj, isFavourite: !proj.isFavourite };
              } else {
                return proj;
              }
            }),
        },
      };
    }

    case ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID: {
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          data:
            state.uiApplication_list.data.length !== 0 &&
            state.uiApplication_list.data.map((proj) => {
              if (proj?._id === action.payload.uiAppid) {
                return { ...proj, isSubscribe: !proj.isSubscribe };
              } else {
                return proj;
              }
            }),
        },
      };
    }
    case DELETE_UI_APPLICAION: {
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          data: state.uiApplication_list.data.filter(
            (item) => item?._id !== action.payload
          ),
          count: state.uiApplication_list.count - 1,
          deleteAppStatus: true,
        },
      };
    }
    // Template
    case TEMPLATE_LIST_DATA: {
      return {
        ...state,
        template_list: {
          ...state.template_list,
          ...action.payload,
        },
      };
    }
    case TEMPLATE_FILTER: {
      return {
        ...state,
        templateFilter: [...action.payload],
      };
    }
    case TEMPLATE_SORT: {
      return {
        ...state,
        templateSort: [...action.payload],
      };
    }
    case TEMPLATE_HIDDEN_COULMNS: {
      return {
        ...state,
        templateHiddenCols: [...action.payload],
      };
    }
    case DELETE_TEMPLATE: {
      return {
        ...state,
        template_list: {
          ...state.template_list,
          templateData: state.template_list.templateData.filter(
            (item) => item?._id !== action.payload
          ),
        },
      };
    }
    case SELECTED_TEMPLATE: {
      const flag = false;
      return {
        ...state,
        template_list: {
          ...state.template_list,
          templateData: state.template_list.templateData.map((item) => {
            if (item?._id === action.payload.templateId) {
              return {
                ...item,
                templateSelected: item?.templateSelected
                  ? !item?.templateSelected
                  : !flag,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
      };
    }
    case SELECTED_PAGE: {
      const flag = false;
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          data:
            state.uiApplication_list.data.length !== 0 &&
            state.uiApplication_list.data.map((proj) => {
              if (proj?._id === action.payload.uiAppId) {
                const tempPages = proj?.pages.map((page) => {
                  if (page.id === action.payload.pageId) {
                    return {
                      ...page,
                      selectedPage: page?.selectedPage
                        ? !page?.selectedPage
                        : !flag,
                    };
                  } else {
                    return {
                      ...page,
                    };
                  }
                });
                return {
                  ...proj,
                  pages: tempPages,
                };
              } else {
                return {
                  ...proj,
                };
              }
            }),
        },
      };
    }
    case SET_GLOBAL_CSS: {
      return {
        ...state,
        globalCss: {
          ...state?.globalCss,
          ...action.payload?.data,
        },
      };
    }
    default:
      return state;
  }
}
