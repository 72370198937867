import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../utils/common";
import CustomFieldGroupsForClusterCreate from "../../components/common/entitySchema/cluster/CustomFieldGroupForClusterCreate";
import { ArrowDropDownRounded } from "@material-ui/icons";

const ClusterAdd = (props) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [error, setError] = useState({
    name: { isError: false },
  });
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
  });
  const [appData, setAppData] = useState({
    type: "",
    app_name: "",
    version: "",
  });

  useEffect(() => {
    const fetchServiceEntitySchema = async () => {
      // checkScreenPermission("Cluster", ["Create"]);

      const { _msg, _status, data } = await fetchResourceByPermission(
        "Cluster",
        "Create"
      );

      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setServiceEntitySchema(entity);
        const servData = {};

        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
              if (field.datakey === "upload_type") {
                if (field.value === "Upload") {
                  setUploadType(true);
                } else {
                  setUploadType(false);
                }
              }
            });
          });
        });

        setServiceData((prevData) => ({ ...prevData, ...servData }));

        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });

        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchServiceEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setAppData({
      ...appData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const { _msg, _status, data } = await addCluster(newClusterData);
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);
      histroy.push("/cluster");
    } else {
      setIsLoader(false);
      enqueueSnackbar(_msg ? _msg : "Cant Save", { variant: "error" });
    }
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Cluster", path: "/cluster" },
              { title: "Create Cluster" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create Cluster</h2>
            <AppButton
              color="primary"
              buttonName="Create"
              variant="contained"
              disabled={false}
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleSubmit}
            />
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {serviceEntitySchema &&
            serviceEntitySchema.map((section) => {
              if (section.name === "Cluster_Hosting_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <CustomFieldGroupsForClusterCreate
                      section={section}
                      serviceData={serviceData}
                      error={error}
                      setIsLoader={setIsLoader}
                      handleChange={handleChange}
                    />
                  </div>
                );
              } else {
                return (
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                        <span className={styles.red}>*</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomFieldGroupsForClusterCreate
                        section={section}
                        serviceData={serviceData}
                        error={error}
                        handleChange={handleChange}
                        setIsLoader={setIsLoader}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })}
        </div>
      </form>
    </main>
  );
};

export default ClusterAdd;
