import React from "react";
import { connect } from "react-redux";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import Header from "../../components/common/Header";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";

const Develop = (props) => {
  const styles = manageScreenStyles();

  const { recentVisited } = props;

 
  return (
    <>
      <Header />
      <main className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: recentVisited[0].childName,
                path: recentVisited[0].routeName,
              },
            ]}
          />
          <h1>500 - Something went wrong</h1>
        </div>
        <div className={styles.wrapper}></div>
      </main>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { recentVisited } = state.viewedReducer;

  return {
    recentVisited,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Develop);
