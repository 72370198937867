import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux"
import { useSnackbar } from "notistack";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwitchNodeModal from "../SwitchNodeModal/SwitchNodeModal";
import { editElementFromList, addRemoveDebugPoints, replaceElementList } from "../../../redux/actions/orchestrationAction"
import NodeInputOutputModal from "../NodeInputOutputModal/NodeInputOutputModal";
import { deleteNode_ } from "../../../utils/orchestration/orchestrationUtils"
import { DeleteModal } from "../../common/modals/DeleteModal";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: 40,
    width: 186,
    border: "0.1px solid lightgray",
  },
  body: {
    marginLeft: 12,
    lineHeight: "100%",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    // marginTop: 3,
    width: "7rem"
  },
  line1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 3,
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "100%",
    alignSelf: "flex-start",
  },
  line2: {
    fontSize: 10,
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "40%",
    lineHeight: 2.3,
  },
  icon_container: {
    display: "flex",
    flexDirection: "column",
  },
  doc_icon: {
    height: 12,
    width: 12,
    color: 'inherit',
    opacity: 0.7
    // background: "#A8A8A8 0% 0% no-repeat padding-box",
  },
  deleteIcon: {
    position: "relative",
    top: -22,
    left: -25,
    // border: "1px solid #A8A8A8",
    // backgroundColor: "white",
    // borderRadius: 15,
    height: 20,
    width: 20,
    padding: 2,
  },
  nodehandleGreen: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid #32CD32!important",
    borderRadius: "7.5px!important",
  },
  nodehandleRed: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid red!important",
    borderRadius: "7.5px!important",
  }
});

function SwitchNode(props) {
  const [showSwitchModal, setSwitchModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showNodeOutputModal, setShowNodeOutputModal] = useState(false);
  const styles = useStyles();
  const { name, nodeid } = props.data;
  const { debug_points, simulate_status, debug_status, debug_context_data, elements, templateid } = props;
  const { enqueueSnackbar } = useSnackbar();

  const isNodeDebugPoint = () => debug_points.includes(name);

  const handleSwitchSubmit = (obj) => {
    props.editElementFromList(obj);
    setSwitchModal(false)
  }

  const handleDebugAddRemove = (flag) => {
    if (flag) {
      enqueueSnackbar("Node Successfully Added as a Debug Point", { variant: "info" })
    } else {
      enqueueSnackbar("Node Successfully Removed from Debug Point", { variant: "info" })
    }
    props.addRemoveDebugPoints({
      id: nodeid,
      add: flag,
      name: name
    })
  }


  const handleShowOutputModal = () => {
    if (debug_status) {
      const debug_points_arr = Object.keys(debug_context_data?.context);
      if (debug_points_arr.includes(name)) {
        setShowNodeOutputModal(true)
      } else {
        enqueueSnackbar("Node is yet to be executed in the flow", { variant: "info" });
      }
    } else if (simulate_status) {
      setShowNodeOutputModal(true)
    }
  }

  const activeNode = () => {
    var activeFlag = false;
    if (debug_context_data) {
      const { executionHaultedAt } = debug_context_data;
      if (executionHaultedAt) {
        if (executionHaultedAt[0] === name) {
          activeFlag = true
        }
      }
    }
    return activeFlag
  }

  const handleDelete = async () => {
    const { _msg, _status, data } = await deleteNode_(templateid, nodeid);
    if (_status === 200) {
      // eslint-disable-next-line array-callback-return
      var newElementList = elements.filter((item) => {
        if (item.source) {
          if (item.source !== nodeid && item.target !== nodeid) {
            return item
          }
        } else {
          if (item.id !== nodeid) {
            return item
          }
        }
      })
      props.replaceElementList(newElementList)
      enqueueSnackbar(_msg, { variant: "success" })
    } else {
      enqueueSnackbar(_msg, { variant: "error" })
    }
  }

  return (
    <div className={styles.container}
      style={activeNode() ? { border: ".1px solid blue" } : {}}>
      {/* <Handle type="target" position="left" style={{ borderRadius: 0 }} /> */}
      <span className={styles.nodeIcon}>
        <img src="/images/Switch.png" alt={"start node"} height={38} width={39} />
      </span>
      <div className={styles.body}>
        <text className={styles.heading}>{name ? name : ""}</text>
        {/* <text className={styles.line2}>{name}</text> */}
      </div>
      <div className={styles.icon_container}>
        {!isNodeDebugPoint() && <span onClick={() => handleDebugAddRemove(true)}>
          <img src="/images/debug_empty.png" alt={"debug"} height={9} width={9} />
        </span>}
        {isNodeDebugPoint() && <span onClick={() => handleDebugAddRemove(false)}>
          <img src="/images/debug_filled.png" alt={"debug"} height={9} width={9} />
        </span>}
        {(!simulate_status && !debug_status) && <span onClick={() => setSwitchModal(true)}>
          <img src="/images/edit_2x.png" alt={"edit"} height={14} width={10} />
        </span>}
        {(simulate_status || debug_status) && <span color={"#A8A8A8"}
          onClick={handleShowOutputModal}>
          <DescriptionOutlinedIcon className={styles.doc_icon} color="inherit" />
        </span>}
        <span className={styles.deleteIcon}
          onClick={() => setDeleteModal(true)}>
          <img src="/images/deleteLine.svg" height={14} width={14} alt="delete" />
        </span>
      </div>
      <Tooltip title="Target">
        <Handle
          type="target"
          position="top"
          id="a"
          style={{ left: "50%" }}
          className={styles.nodehandleRed}
        />
      </Tooltip>
      <Tooltip title="Target">
        <Handle
          type="target"
          position="left"
          id="b"
          style={{ top: "50%" }}
          className={styles.nodehandleRed}
        />
      </Tooltip>
      <Tooltip title="Source">
        <Handle
          type="source"
          position="bottom"
          id="c"
          style={{ left: "50%" }}
          className={styles.nodehandleGreen}
        />
      </Tooltip>
      <Tooltip title="Source">
        <Handle
          type="source"
          position="right"
          id="d"
          style={{ top: "50%" }}
          className={styles.nodehandleGreen}
        />
      </Tooltip>

      {showSwitchModal && <SwitchNodeModal edit={true}
        nodeid={nodeid}
        onEditSwitchClose={() => setSwitchModal(false)}
        onEditSwitchSubmit={(obj) => handleSwitchSubmit(obj)}
        isOpen={showSwitchModal} />
      }
      {showNodeOutputModal && <NodeInputOutputModal node_name={name}
        isOpen={showNodeOutputModal}
        onSubmit={() => setShowNodeOutputModal(false)} />}
      {deleteModal && (
        <DeleteModal
          name={name}
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = {
  editElementFromList,
  addRemoveDebugPoints,
  replaceElementList
}

const mapStateToProps = (state) => {
  const { debug_points, simulate_status, debug_data, process_data, elements } = state.orchestration;
  const { debug_status, debug_context_data } = debug_data;
  const { templateid } = process_data;
  return {
    debug_points,
    simulate_status,
    debug_status,
    debug_context_data,
    templateid,
    elements
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SwitchNode)