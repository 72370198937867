import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  name: {
    display: "flex",
    flexDirection: "column"
  },
  container: {
    backgroundColor: "white",
    borderRadius: 10,
  },
  line1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    paddingTop: 0,
    paddingBottom: 0,
  },
  section1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // minWidth: 800,
  image: {
    height: 34,
    width: 34,
  },
  heading: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
  },
    fontWeight: "bold",
    marginLeft: 15,
  },
  cross: {
    fontSize: 20,
    fontWeight: "bold",
  },
  line2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    // marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {
    height: 38,
    width: "21.2rem",
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "1rem"
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    borderWidth: 1,
    borderColor: "black",
  },
  line3: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 6,
  },
  line4: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginTop: 7,
    marginRight: 20,
  },
  icon: {
    position: "relative",
    left: 150,
  },
  line5: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 25,
  },
  outputbody: {
    // height: 100,
    width: 750,
    borderWidth: 2,
    borderColor: "black",
    marginTop: 5,
    // flex: 1,
  },
  line6: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    bottom: "1rem",
    right: "22rem",
    height: 60,
    backgroundColor: "#0000000B",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: 30,
    zIndex: 10,
  },
  searchInput3: {
    height: 38,
    width: "21.2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  dropdown: {
    width: "21.2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    paddingLeft: "1rem",

    "&::before": {
      borderBottom: "unset",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'blue',
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  },
  select : {
    width: "21.2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },

    "& .MuiInputBase-input" : {
      paddingLeft: 15,
    }
   
  }
}));
