export const UIAPPLICATION_LIST_DATA = "UIAPPLICATION_LIST_DATA";
export const UIAPPLICATION_FILTER = "UIAPPLICATION_FILTER";
export const UIAPPLICATION_UPDATE = "UIAPPLICATION_UPDATE";
export const UIAPPLICATION_SORT = "UIAPPLICATION_SORT";
export const UIAPPLICATION_HIDDEN_COULMNS = "UIAPPLICATION_HIDDEN_COULMNS";
export const EDIT_UI_APPLICAION = "EDIT_UI_APPLICAION";
export const DELETE_UI_APPLICAION = "DELETE_UI_APPLICAION";
export const ALTER_FAVOURITE_UI_APPLICATION_BY_ID =
  "ALTER_FAVOURITE_UI_APPLICATION_BY_ID";
export const ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID =
  "ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID";
export const TEMPLATE_LIST_DATA = "TEMPLATE_LIST_DATA";
export const TEMPLATE_FILTER = "TEMPLATE_FILTER";
export const TEMPLATE_SORT = "TEMPLATE_SORT";
export const TEMPLATE_HIDDEN_COULMNS = "TEMPLATE_HIDDEN_COULMNS";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const SELECTED_TEMPLATE = "SELECTED_TEMPLATE";
export const SELECTED_PAGE = "SELECTED_PAGE";
export const SET_GLOBAL_CSS = "SET_GLOBAL_CSS";
export const SET_UIAPPLICATION_LIST_DATA = "SET_UIAPPLICATION_LIST_DATA";
export const LINK_UIAPPLICATION_ID = "LINK_UIAPPLICATION_ID";
export const UNLINK_UIAPPLICATION_ID = "UNLINK_UIAPPLICATION_ID";
