import React, { useEffect, useState } from 'react';
import AddOutlined from '@material-ui/icons/AddOutlined';
import styles from '../../../../atoms/rightSide/settings/popups/helper/styles';
import { useDispatch } from 'react-redux';
import { editComponent } from '../../../../../../redux/actions/uiApplicationAction';
import { v4 as uuid } from 'uuid';
import Input from '../../../../atoms/input/Input';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionActions,
  Grid,
  IconButton,
} from '@material-ui/core';
import useGetData from '../../../../../hooks/useGetData';

export const SelectHelper = ({
  customArr,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent } = useGetData();
  const [expanded, setExpanded] = React.useState(false);
  const [optionsArray, setOptionsArray] = useState([]);

  const handleAddColumn = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: [
            ...component.options,
            {
              id: uuid(),
              title: `option${component.options.length + 1}`,
              name: `value${component.options.length + 1}`,
            },
          ],
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component.options.map((v) => {
            if (v?.id === value?.id) {
              return {
                ...v,
                [event.target.name]: event.target.value,
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handeDelete = (e, collProp) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          options: component.options.filter((c) => c.id !== collProp.id),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  useEffect(() => {
    setOptionsArray(currentComponent?.options);
  }, [currentComponent?.options]);

  // useEffect(() => {
  //   if (Array.isArray(customArr)) {
  //     setOptionsArray(customArr);
  //   }
  // }, [customArr]);

  return (
    <div>
      {/* <label>Options</label> */}
      <div>
        {optionsArray.map((col) => (
          <Accordion
            expanded={expanded === col?.id}
            onChange={handleChangePanel(col?.id)}
            elevation={1}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              //   expandIcon={
              //     !expanded == col?.title && (
              //       <ChevronRightIcon
              //         style={{ marginBottom: 5 }}
              //         fontSize="small"
              //       />
              //     )
              //   }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{ root: classes.summaryRoot }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                  // sx={{ width: '100%', flexShrink: 0, fontSize: 12 }}
                  >
                    {col?.title}
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <DeleteIcon
                    fontSize="small"
                    style={{ height: 18 }}
                    onClick={(e) => handeDelete(e, col)}
                  /> */}
                  <img
                    src="/img/delete-bin-7-line.svg"
                    alt="Delete"
                    style={{ height: 18 }}
                    onClick={(e) => handeDelete(e, col)}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item>
                  <Input
                    name={'title'}
                    label={'Title'}
                    placeholder=""
                    value={col?.title}
                    onChange={(e) => handleChange(e, col)}
                  />
                </Grid>
                <Grid item>
                  <Input
                    name={'name'}
                    label={'Value'}
                    placeholder=""
                    value={col?.name}
                    onChange={(e) => handleChange(e, col)}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div
        className={classes.tableColumn}
        style={{
          justifyContent: 'center',
          padding: 5,
          cursor: 'pointer',
        }}
        onClick={handleAddColumn}
      >
        <AddOutlined />
      </div>
    </div>
  );
};

// "[{\"id\":\"be28ada4-5f9e-418f-bc92-13a561a1c707\",\"title\":\"option1\",\"name\":\"value1\"},{\"id\":\"344ffa5f-f514-4053-aa93-d47ec3958721\",\"title\":\"option2\",\"name\":\"value2\"}]",
