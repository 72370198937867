/* eslint-disable */
import {
  Grid,
  InputAdornment,
  IconButton,
  FilledInput,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useRef } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { connect } from "react-redux";
import { getProjectList } from "../../../redux/actions/projectAction";
import { fetchProjectById } from "../../../utils/project/projectUtils";
import DocumentTableWithDropper from "../../../components/common/Tables/DocumentTableWithDropper";
import { documentColumns } from "../../../constants/documentConstant";
import CustomFilter from "../../../components/common/CustomFilter";
import { useSnackbar } from "notistack";
import Loader from "../../../components/common/stuff/Loader";
import { arrayToFilter } from "../../../utils/common";
import {
  add_projDocument_Data,
  add_project_documents_filter,
  add_project_documents_sort,
} from "../../../redux/actions/documentAction";
import { arrayToSort } from "../../../utils/common";
import { Pagination } from "@material-ui/lab";
import { v4 as uuidv4 } from "uuid";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";

const ProjectDetailsList = (props) => {
  const {
    project_document_list,
    add_projDocument_Data,
    add_project_documents_filter,
    add_project_documents_sort,
    projectDocumentFilter,
    projectDocumentSort,
  } = props;

  const [projectId] = useState(props.match.params._id);
  const [currentPageProjectDocumentList, setcurrentPageProjectDocumentList] =
    useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [projectData, setProjectData] = useState({});
  const [isImportButtonClicked, setIsImportButtonClicked] = useState(
    props.location?.state?.forUpload
  );
  const styles = manageStyles();
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [statuses, setStatuses] = useState([]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      const { _msg, _status, data } = await fetchProjectById(projectId);
      if (_status === 200) {
        setProjectData(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchProjectDetails();
  }, [projectId]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  useEffect(()=> {
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Document"
      );
      if (_status === 200) {

        setStatuses(data ? data.status : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  },[]);

  function createDocumentTableData(docData) {
    const { _id, filename, docName, name, resourcetype, version, createdon, status } =
      docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
      action: projectId,
      status: status,
      statuses: statuses,
    };
  }

  const fetchDocuments = async () => {
    const parsedFilters = arrayToFilter([
      ...projectDocumentFilter,
      { resourcetype: "project" },
      { resourceid: projectId },
    ]);
    const parsedSort = arrayToSort(projectDocumentSort);
    add_projDocument_Data(
      currentPageProjectDocumentList,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
    // setIsImportButtonClicked(false)
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPageProjectDocumentList, projectDocumentSort]);

  const projectDocumentRows =
    project_document_list.data && project_document_list.data.length > 0
      ? project_document_list.data.map((item) => {
          const rowData = createDocumentTableData(item);
          return rowData;
        })
      : [];

  const handleProjectDocumentListPagination = (event, value) => {
    setcurrentPageProjectDocumentList(value);
  };

  const handleImportButtonOpen = () => {
    setIsImportButtonClicked(true);
  };

  const handleImportButtonClose = () => {
    setIsImportButtonClicked(false);
  };

  // useEffect(() => {
  //   checkScreenPermission()

  // }, [])

  const handleSearch = () => {
    if (searchInput) {
      add_projDocument_Data(
        0,
        JSON.stringify({
          resourcetype: "project",
          resourceid: projectId,
          filename: { $regex: searchInput, $options: "i" },
        })
      );
    } else {
      add_projDocument_Data(
        1,
        JSON.stringify({ resourcetype: "project", resourceid: projectId })
      );
    }
  };

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter([
      ...projectDocumentFilter,
      { resourcetype: "project" },
      { resourceid: projectId },
    ]);
    const parsedSort = arrayToSort(projectDocumentSort);
    add_projDocument_Data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilter = () => {
    add_project_documents_filter([
      { id: uuidv4(), column: "", operator: "", value: "" },
    ]);
    add_projDocument_Data(
      1,
      JSON.stringify({ resourcetype: "project", resourceid: projectId })
    );
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Documents", path: "/documents" },
            { title: "Project", path: "/documents" },
            { title: projectData.name ? projectData.name : "Project" },
          ]}
        />

        <div className={styles.topContentItems}>
          <Grid container direction="row">
            <h2 className={styles.heading}>
              {projectData.name ? projectData.name : "Project"}
            </h2>
            <div
              className={styles.topContentItem2}
              style={{ display: "flex", right: 32, position: "absolute" }}
            >
              <FilledInput
                style={{ height: "35px" }}
                id="filled-adornment-password"
                name="searchInput"
                autoComplete="off"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.childDocumentSearchBox}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Divider
                orientation="vertical"
                style={{ marginTop: "5px", marginLeft: "3px" }}
                className={styles.MuiDivider}
              />

              <CustomFilter
                columns={documentColumns}
                filters={projectDocumentFilter}
                setFilters={add_project_documents_filter}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilter}
              />
              <Divider
                orientation="vertical"
                className={styles.MuiDivider}
                style={{ marginTop: "5px", marginLeft: "1px" }}
              />
              {!isImportButtonClicked ? (
                <AppButton
                  disabled={!checkCompPermission("Document", ["Create"])}
                  buttonName="Import"
                  variant="contained"
                  startIcon={<img src="/images/importIcon.svg" alt="import" />}
                  className="btnsmall"
                  onClick={handleImportButtonOpen}
                />
              ) : (
                <AppButton
                  buttonName="Close"
                  startIcon={<img src="/images/closeIcon.svg" alt="import" />}
                  variant="contained"
                  disabled={false}
                  className="btnsmall"
                  onClick={handleImportButtonClose}
                />
              )}
            </div>
          </Grid>
        </div>
      </div>
      <div className={styles.wrapper} style={{ marginBottom: "0rem" }}>
        <DocumentTableWithDropper
          resourceId={projectId}
          resourceType={"project"}
          isEditMode={isImportButtonClicked}
          sort={projectDocumentSort}
          setSort={add_project_documents_sort}
          rows={projectDocumentRows}
          fetchDocuments={fetchDocuments}
          isDocumentModule={true}
        />
      </div>
      {projectDocumentRows.length !== 0 && (
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(project_document_list._totalcount / 10)}
              page={currentPageProjectDocumentList}
              onChange={handleProjectDocumentListPagination}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  add_projDocument_Data,
  add_project_documents_filter,
  add_project_documents_sort,
};

const mapStateToProps = (state) => {
  const { project_document_list, projectDocumentFilter, projectDocumentSort } =
    state.allDocumentsReducer;
  const { userInfo } = state.userDetails;
  return {
    project_document_list,
    projectDocumentFilter,
    projectDocumentSort,
    // projectDetailsListTotalCount,
    userInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsList);
