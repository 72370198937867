const serviceColumns = [
  { name: "microservicename", title: "Microservice Name", for: "service_list" },
  { name: "versionTag", title: "Version Tag", for: "service_list" },
];

const serviceDefaultColumnWidths = [
  { columnName: "microservicename", weightage: 7, width: 200 },
  { columnName: "versionTag", weightage: 7, width: 200 },
];

const serviceTableColumnExtensions = [
  { columnName: "microservicename", align: "left" },
  { columnName: "versionTag", align: "left" },
];

const serviceColumnOrder = ["microservicename", "versionTag"];

const serviceDefaultSorting = [{ columnName: "microservicename", direction: "asc" }];

export {
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
  serviceColumnOrder,
  serviceDefaultSorting,
};
