import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { addMicroService } from "../../../utils/project/microserviceUtils";
import { fetchBoilerPlateListData } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { useSnackbar } from "notistack";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { getProjectList } from "../../../redux/actions/projectAction";
import { fetchServiceGroupListData } from "../../../utils/project/servicegroupUtils";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
  getTableData,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import Loader from "../../../components/common/stuff/Loader";
import CustomFieldGroupsForServiceCreate from "../../../components/common/entitySchema/service/CustomFieldGroupsForServiceCreate";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useLocation } from "react-router-dom";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SaveIcon from "@material-ui/icons/Save";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import {
  versionColumnHidden,
  versionColumnOrder,
  versionColumns,
  versionDefaultColumnWidths,
  versionTableColumnExtensions,
} from "../../../constants/versionsConstant";
import {
  apiColumnHidden,
  apiColumnOrder,
  apiColumns,
  apiDefaultColumnWidths,
  apiTableColumnExtensions,
} from "../../../constants/apisConstant";
import {
  getVersionList,
  getAPIList,
  clearVersions,
  clearAPIs,
  addVersionHidden,
  addVersionSort,
  addAPISort,
  addAPIHidden,
  toggleVersionLoading,
} from "../../../redux/actions/serviceAction";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const AddService = (props) => {
  const {
    tabbedView,
    detailAPI,
    detailVersion,
    versionsList,
    apisList,
    versionHidden,
    versionSort,
    apiSort,
    apiHidden,
    addAPIHidden,
    addVersionSort,
    addVersionHidden,
    serviceActiveTab,
  } = props;
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [boilerPlateChecked, setBoilerPlateChecked] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [projectSearchInput, setProjectSearchInput] = useState("");
  const [serviceGroupSearchInput, setServiceGroupSearchInput] = useState("");


  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [versions, setVersions] = useState([]);
  const [apiVersions, setApiVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [apis, setApis] = useState([]);
  const [formValidity, setFormValidity] = useState(false);
  const [serviceCreated, setServiceCreated] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [serviceData, setServiceData] = useState({
    name: "",
    imagename: "",
    description: "",
    versionTag: "",
    versionDescription: "",
    status: "",
    languageID: "",
    languageName: "",
    initializeboilerplateID: false,
    serviceType: "Internal",
    boilerplateID: "",
    projectID: "",
    servicegroupID: "",
    service_category:
      props.history.location.state?.data == "services"
        ? "Only API"
        : "Only GUI",
    port: "",
  });
  const [versionTableLoading, setVersionTableLoading] = useState(false);
  const [versionTableData, setVersionTableData] = useState({
    columns: versionColumns,
    widths: versionDefaultColumnWidths,
    extentions: versionTableColumnExtensions,
    orders: versionColumnOrder,
    hides: [],
  });
  const [apiTableLoading, setApiTableLoading] = useState(false);
  const [apiTableData, setApiTableData] = useState({
    columns: apiColumns,
    widths: apiDefaultColumnWidths,
    extentions: apiTableColumnExtensions,
    orders: apiColumnOrder,
    hides: [],
  });
  const [error, setError] = useState({
    name: { isError: false },
    projectID: { isError: false },
    servicegroupID: { isError: false },
    languageID: { isError: false },
  });
  const [createAPI, setCreateAPI] = useState(false);
  const [createVersion, setCreateVersion] = useState(false);

  const { data, getProjectList } = props;
  const [serviceId, setServiceId] = useState();
  const projects = data?.data && data?.data?.length > 0 ? data?.data : [];
  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (selectedProject._id) {
      fetchServiceGroupsFromProject();
      fetchLanguagesList();
    }
  }, [selectedProject]);


  useEffect(() => {
    const fetchVersionFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Version");
      if (_status === 200) {
        let versData = getTableData(
          data,
          versionColumns,
          versionDefaultColumnWidths,
          versionTableColumnExtensions,
          versionColumnOrder,
          versionHidden
        );
        setVersionTableData(versData);
        if (
          JSON.stringify(versionHidden) === JSON.stringify(versionColumnHidden)
        ) {
          addVersionHidden(versData.hides);
        }
        setVersionTableLoading(false);
      } else {
        setVersionTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchApiFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("API");
      if (_status === 200) {
        let apData = getTableData(
          data,
          apiColumns,
          apiDefaultColumnWidths,
          apiTableColumnExtensions,
          apiColumnOrder,
          apiHidden
        );
        const tempApData = {
          ...apData,
          columns: apData.columns.filter((col) => col.title !== "Password"),
          hides: apData.hides.filter((h) => h.split("_")[2] !== "password"),
        };
        setApiTableData(tempApData);
        if (JSON.stringify(apiHidden) === JSON.stringify(apiColumnHidden)) {
          addAPIHidden(tempApData.hides);
        }
        setApiTableLoading(false);
      } else {
        setApiTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchVersionFields();
    fetchApiFields();
  }, []);

  const fetchServiceGroupsFromProject = async (filters) => {
    const { _msg, _status, data } = await fetchServiceGroupListData(
      selectedProject._id,
      0,
      filters
    );
    setServiceGroups([]);
    if (_status === 200) {
      if (data) {
        setServiceGroups(data);
      } else {
        enqueueSnackbar(
          "No Service Group under this project. Please select another project",
          { variant: "info" }
        );
        setServiceGroups([]);
      }
    } else if (_status === 404) {
      enqueueSnackbar(_msg, { variant: "info" });
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const fetchLanguagesList = async () => {
    const { _msg, _status, data } = await fetchLanguagesByProject(
      selectedProject._id
    );
    if (_status === 200) {
      setLanguages(data ? data : []);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("ServiceGroup", ["Create Microservice"]);
    setIsLoader(true);
    const fetchServiceEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Microservice",
        "create"
      );
      if (_status === 200) {
        setIsLoader(false);
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            // if (fieldgroup?.fields?.length !== 0) {
            entity.push(section);
            // }
          })
        );
        setServiceEntitySchema(entity);
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });
        setServiceData((prevData) => ({
          ...prevData,
          serviceType: "Internal",
        }));
        const errData = {};
        data.sections.map(async (section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field.index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["boilerplateID"];
        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchBoilerPlateListData();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Microservice"
      );
      if (_status === 200) {
        setStatuses(data ? data.status : []);
        // setServiceData({
        //   ...serviceData,
        //   status: data?.status.find((s) => s.default === true)?.status,
        //   serviceType: "Internal",
        // });
        setServiceData((prevData) => ({
          ...prevData,
          service_category: props.history.location.state?.data == "services"
            ? "Only API"
            : "Only GUI",
          status: data?.status.find((s) => s.default === true)?.status,
          serviceType: "Internal",
        }));
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchServiceEntitySchema();
    if (checkCompPermission("Boilerplate", ["List All"])) {
      fetchBoilerPlates();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  useEffect(() => {
    const tempError = error;
    const tempServiceData = serviceData;
    if (serviceData.serviceType === "External") {
      delete tempError["imagename"];
      delete tempServiceData["imagename"];
    } else {
      tempError["languageID"] = initError({
        datakey: "languageID",
        editable: "TRUE",
        errorMessage: "",
        fieldLabel: "Microservice Languageid",
        name: "Microservice Languageid",
        pattern: "",
        predefined: "TRUE",
        required: "TRUE",
      });
      tempError["imagename"] = initError({
        datakey: "imagename",
        editable: "FALSE",
        errorMessage: "",
        fieldLabel: "Microservice Image Name",
        name: "Image Name",
        pattern: "",
        predefined: "TRUE",
        required: "TRUE",
      });
      tempServiceData["languageID"] = "";
      tempServiceData["imagename"] = "";
    }
    setError(tempError);
    setServiceData(tempServiceData);
  }, [serviceData.serviceType]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };

  useEffect(() => {
    handleProjectSearch();
  }, [projectSearchInput]);

  const handleProjectSearch = () => {
    if (projectSearchInput) {
      getProjectList(
        0,
        JSON.stringify({
          name: { $regex: projectSearchInput, $options: "i" },
        })
      );
    } else {
      getProjectList(0);
    }
  };

  useEffect(() => {
    if (projectSearchInput) {
      handleServiceGroupSearch();
    }
  }, [serviceGroupSearchInput]);

  const handleServiceGroupSearch = () => {
    if (serviceGroupSearchInput) {
      fetchServiceGroupsFromProject(
        JSON.stringify({
          name: { $regex: serviceGroupSearchInput, $options: "i" },
        })
      );
    } else {
      fetchServiceGroupsFromProject();
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const newServiceData = {
        ...serviceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
          port: serviceData?.port,
        };
      }
      setIsLoader(true);
      const { _msg, _status, data } = await addMicroService(newServiceData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);
        histroy.push(`/services`);
        const microserviceID = data?._id;
        return microserviceID;
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    }
  };

  const handleAddVersionInCreate = async () => {
    let serviceStatus;
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);

    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
      serviceStatus = false;
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      setFormValidity(true);
      const newServiceData = {
        ...serviceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
          port: serviceData?.port,
        };
      }
      setIsLoader(true);
      const { _msg, _status, data } = await addMicroService(newServiceData);

      if (_status === 201) {
        setServiceId(data?._id);
        serviceStatus = true;
        setServiceCreated(false);
        serviceStatus = true;
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);

        if (!tabbedView) {

          histroy.push({
            pathname: `/versions/add/${data?._id}`,
            state: { serviceName: data?.name },
          });
        } else {

          // histroy.push(`/versions/add/${data?._id}`);
          if (serviceStatus) {
            setCreateVersion(true);
          }
        }
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
          return;
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      setFormValidity(false);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const fetchStatusesProfileByResource = async (resource, callback) => {
    const { _msg, _status, data } = await fetchStatusResourceByName(resource);
    if (_status === 200) {
      callback(data);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // if (checkCompPermission("StatusProfile", ["List All"])) {
  //   fetchStatusesProfileByResource("Microservice", (data) =>
  //     setStatuses(data ? data.status : [])
  //   );
  // }

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {

    histroy.push("/services?service=true");
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: serviceActiveTab === "uiSimulator"
                  ? "UI Service"
                  : "Service",
                path: "/services?service=true",
              },
              { title: "Create Service" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create Service</h2>
            {tabbedView ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >

                {(!createVersion || (createVersion && activeTab !== 4)) && <TabbedNavigation
                  handleCancel={handleCancel}
                  handleSave={handleSubmit}
                  isCreateMode={true}
                  schemaLength={serviceEntitySchema?.length}
                  activeTab={activeTab}
                />}

              </div>
            ) : (
              <div>

                <AccordionNavigation
                  isCreateMode={true}
                  disabled={false}
                  handleSave={handleSubmit}
                  handleCancel={handleCancel}
                  isEditMode={false}
                />
              </div>
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView && (
            <SectionTabs
              entity={serviceEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
          )}
          {tabbedView ? (
            <Paper style={{ padding: tabbedView ? "10px" : "0px" }}>
              <div>
                <CustomFieldGroupsForServiceCreate
                  section={serviceEntitySchema[activeTab]}
                  serviceId={serviceId}
                  serviceCreated={serviceCreated}
                  setServiceCreated={setServiceCreated}
                  statuses={statuses}
                  serviceData={serviceData}
                  formValidity={formValidity}
                  error={error}
                  languages={languages}
                  histroy={histroy}
                  boilerplates={boilerplates}
                  selectedProject={selectedProject}
                  boilerPlateChecked={boilerPlateChecked}
                  projects={projects}
                  serviceGroups={serviceGroups}
                  selectedServiceGroup={selectedServiceGroup}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setSelectedProject={setSelectedProject}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  setSelectedServiceGroup={setSelectedServiceGroup}
                  setError={setError}
                  setProjectSearchInput={setProjectSearchInput}
                  setServiceGroupSearchInput={setServiceGroupSearchInput}
                  setBoilerPlateChecked={setBoilerPlateChecked}
                  setServiceData={setServiceData}
                  handleChange={handleChange}
                  documentsError={documentsError}
                  setDocumentsError={setDocumentsError}
                  createAPI={createAPI}
                  setCreateAPI={setCreateAPI}
                  createVersion={createVersion}
                  setCreateVersion={setCreateVersion}
                  versionTableLoading={versionTableLoading}
                  versionTableData={versionTableData}
                  apiTableData={apiTableData}
                  apiTableLoading={apiTableLoading}
                  versions={versions}
                  apiVersions={apiVersions}
                  selectedVersion={selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  apis={apis}
                  setApis={setApis}
                  versionsList={versionsList}
                  apisList={apisList}
                  versionHidden={versionHidden}
                  versionSort={versionSort}
                  apiSort={apiSort}
                  apiHidden={apiHidden}
                  addAPIHidden={addAPIHidden}
                  addAPISort={addAPISort}
                  addVersionSort={addVersionSort}
                  addVersionHidden={addVersionHidden}
                  handleAddVersionInCreate={handleAddVersionInCreate}
                />
              </div>
            </Paper>
          ) : (
            serviceEntitySchema &&
            serviceEntitySchema.map((section, index) => {
              if (section.name === "Microservice_Header_Section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <CustomFieldGroupsForServiceCreate
                      section={section}
                      serviceId={serviceId}
                      serviceData={serviceData}
                      histroy={histroy}
                      statuses={statuses}
                      serviceCreated={serviceCreated}
                      setServiceCreated={setServiceCreated}
                      error={error}
                      projectSearchInput={projectSearchInput}
                      serviceGroupSearchInput={serviceGroupSearchInput}
                      selectedProject={selectedProject}
                      projects={projects}
                      serviceGroups={serviceGroups}
                      selectedServiceGroup={selectedServiceGroup}
                      setServiceData={setServiceData}
                      handleChange={handleChange}
                      setProjectSearchInput={setProjectSearchInput}
                      setServiceGroupSearchInput={setServiceGroupSearchInput}
                      setSelectedProject={setSelectedProject}
                      setSelectedServiceGroup={setSelectedServiceGroup}
                      versionTableLoading={versionTableLoading}
                      versionTableData={versionTableData}
                      apiTableData={apiTableData}
                      apiTableLoading={apiTableLoading}
                      versions={versions}
                      apiVersions={apiVersions}
                      selectedVersion={selectedVersion}
                      setSelectedVersion={setSelectedVersion}
                      apis={apis}
                      setApis={setApis}
                      versionsList={versionsList}
                      apisList={apisList}
                      versionHidden={versionHidden}
                      versionSort={versionSort}
                      apiSort={apiSort}
                      apiHidden={apiHidden}
                      addAPIHidden={addAPIHidden}
                      addAPISort={addAPISort}
                      addVersionSort={addVersionSort}
                      addVersionHidden={addVersionHidden}
                      handleAddVersionInCreate={handleAddVersionInCreate}
                    />
                  </div>
                );
              } else if (
                serviceData.serviceType === "External" &&
                section.name === "Microservice_Boilerplate_Section"
              ) {
                return <></>;
              } else {
                return (
                  <Element name={index}>
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForServiceCreate
                          section={section}
                          serviceId={serviceId}
                          serviceData={serviceData}
                          error={error}
                          serviceCreated={serviceCreated}
                          setServiceCreated={setServiceCreated}
                          languages={languages}
                          statuses={statuses}
                          histroy={histroy}
                          boilerplates={boilerplates}
                          selectedProject={selectedProject}
                          boilerPlateChecked={boilerPlateChecked}
                          projects={projects}
                          serviceGroups={serviceGroups}
                          selectedServiceGroup={selectedServiceGroup}
                          selectedDocuments={selectedDocuments}
                          setSelectedDocuments={setSelectedDocuments}
                          setSelectedProject={setSelectedProject}
                          setSelectedServiceGroup={setSelectedServiceGroup}
                          setError={setError}
                          setProjectSearchInput={setProjectSearchInput}
                          setServiceGroupSearchInput={
                            setServiceGroupSearchInput
                          }
                          setBoilerPlateChecked={setBoilerPlateChecked}
                          setServiceData={setServiceData}
                          handleChange={handleChange}
                          documentsError={documentsError}
                          setDocumentsError={setDocumentsError}
                          createAPI={createAPI}
                          setCreateAPI={setCreateAPI}
                          createVersion={createVersion}
                          setCreateVersion={setCreateVersion}
                          versionTableLoading={versionTableLoading}
                          versionTableData={versionTableData}
                          apiTableData={apiTableData}
                          apiTableLoading={apiTableLoading}
                          versions={versions}
                          apiVersions={apiVersions}
                          selectedVersion={selectedVersion}
                          setSelectedVersion={setSelectedVersion}
                          apis={apis}
                          setApis={setApis}
                          versionsList={versionsList}
                          apisList={apisList}
                          versionHidden={versionHidden}
                          versionSort={versionSort}
                          apiSort={apiSort}
                          apiHidden={apiHidden}
                          addAPIHidden={addAPIHidden}
                          addAPISort={addAPISort}
                          addVersionSort={addVersionSort}
                          addVersionHidden={addVersionHidden}
                          handleAddVersionInCreate={handleAddVersionInCreate}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Element>
                );
              }
            })
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addAPIHidden,
  addAPISort,
  addVersionSort,
  addVersionHidden,
};

const mapStateToProps = (state) => {
  const { projectsList } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;
  const { data, error, loading, _totalcount } = projectsList;
  const { serviceActiveTab } = state.activeTabReducer;
  const {
    detailVersion,
    detailAPI,
    versionsList,
    apisList,
    versionHidden,
    versionSort,
    apiSort,
    apiHidden,
  } = state.serviceReducer;
  return {
    data,
    error,
    loading,
    _totalcount,
    tabbedView,
    detailVersion,
    detailAPI,
    versionsList,
    apisList,
    versionHidden,
    versionSort,
    apiSort,
    apiHidden,
    serviceActiveTab,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
