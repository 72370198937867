// const baseURL = process.env.REACT_APP_AWS_BASE_URL;
// const baseURL = "http://49.205.218.104:6001";
const orgUrl = process.env.REACT_APP_ORG_BASE_URL;
const repoUrl = process.env.REACT_APP_AWS_BASE_URL;
const baseURL = process.env.REACT_APP_AWS_BASE_URL;
const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
const socketURL = process.env.SOCKET_URL;
// const baseURL = "http://49.205.218.104:6001";

let currentOrigin2 = ""
if (process.env.NODE_ENV === 'production') {
    currentOrigin2 = window?.location?.origin
} else {
    currentOrigin2 = process.env.REACT_APP_AWS_BASE_URL
}
export const WEBSOCKET_URL = `${currentOrigin2.replace('http','ws')}`;

export const BASE_URL = `${baseURL}`;
export const SOCKET_URL = `${socketURL}`;
export const ORG_URL = `${orgUrl}`;
export const BASE_URL2 = `${baseURL}`;
export const CONTAINER_MNGT = `${baseURL}`;
export const BASE_URL_2 = `${baseURL}`;
export const BASE_URL_3 = `${baseURL}`;
export const BASE_URL_4 = `${baseURL}`;
export const BASE_URL_5 = `${baseURL}`;
export const BASE_URL3 = `${baseURL}`;
export const BASE_URL18 = `${baseURL}`;
export const BASE_URL15 = `${baseURL}`;
export const BASE_URL45 = `${baseURL}`;
export const BASE_URL44 = `${baseURL}`;
export const MICROSERVICE = `${baseURL}`;
export const APPLICATION = `${baseURL}`;
export const CONTAINER = `${baseURL}`;
export const SIMULATION = `${baseURL}`;
export const TEMPLATE = `${baseURL}`;
export const PROJECTS = `${baseURL}`;
export const ENTITY_SCHEMA = `${baseURL}`;
export const TEAMS = `${baseURL}`;
export const LANGUAGES = `${baseURL}`;
export const GLOSSARY = `${baseURL}`;
// export const REPOSITORY = `${baseURL}:7015`
export const REPOSITORY = `${repoUrl}`;
export const DOCUMENT = `${baseURL}`;
export const STATUSPROFILE = `${baseURL}`;
export const ISSUEDETAILS = `${baseURL}`;
export const USERPROFILE = `${baseURL}`;
export const MODE = "development";
export const RELEASE_VERSION = `${releaseVersion}`;
export const DATAMODEL = `${baseURL}`;
export const DEPLOYMENT = `${baseURL}`;
export const CLUSTER = `${baseURL}`;
export const TEST = `${baseURL}`;
export const EXECUTION = `${baseURL}`;
export const SERVICE_BUNDLE = `${baseURL}`;
export const ORGANIZATION_CONFIG = `${baseURL}`;

const currentOrigin = window.location.origin;
export const WEBSOCKET = `${currentOrigin.replace("http", "ws")}`;
