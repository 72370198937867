import StatusStyles from "./ShowStatusStyles";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import { useState } from "react";
import JSDataInput from "../../settings/JSDataInput";
import useGetData from "../../../../../hooks/useGetData";
import { useTheme } from "@material-ui/core";

const ShowStatus = ({
  fstBtn,
  sndBtn,
  label,
}) => {
  const dispatch = useDispatch();
  const styles = StatusStyles();
  const theme = useTheme()
  const [jsMode, setJsMode] = useState(false);
  const { currentPage, currentComponent, getDataValue, executeJavascript } = useGetData()
  const [error, setError] = useState("");

  const handleChange = (value) => {
    let dataValue = []
    try {
      if (value.includes('return')) {
        dataValue = JSON.stringify(executeJavascript(value));
        setError("")
      } else {
        dataValue = getDataValue(value)
        setError("")
      }
    } catch (error) {
      setError(error?.message)
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          loading: {
            dataInput: value,
            dataValue: dataValue
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p className="" style={{ margin: 0, marginBottom: 5 }}><label>{label}</label></p>
        <p
          className="inputmode"
          style={{ margin: 0, marginBottom: 5, color: "#AFAFAF", cursor: 'pointer' }}
          onClick={() => setJsMode(!jsMode)}
        >Js Mode</p>
      </div>
      {!jsMode ? <div className={styles.loadingbtnContainer}>
        <button
          className={styles.loadingbtn}
          onClick={() => handleChange(true.toString())}
          style={
            currentComponent?.loading?.dataValue === "true"
              ? {
                backgroundColor: theme.palette.primary.main,
                color: "#FFFFFF",
                textTransform: "capitalize",
              }
              : { textTransform: "capitalize" }
          }
        >
          {fstBtn}
        </button>
        <button
          style={
            currentComponent?.loading?.dataValue === "false"
              ? {
                backgroundColor: theme.palette.primary.main,
                color: "#FFFFFF",
                textTransform: "capitalize",
              }
              : { textTransform: "capitalize" }
          }
          className={styles.loadingbtn}
          onClick={() => handleChange(false.toString())}
        >
          {sndBtn}
        </button>
      </div> :
        <JSDataInput
          value={currentComponent.loading}
          onChange={handleChange}
          name={'loading'}
          title={`${currentComponent.name} : Loading`}
          error={error}
        />}
    </>
  );
};

export default ShowStatus;
