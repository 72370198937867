const aiTaskApiColumns = [
    // { name: 'id', title: 'API ID' },
    { name: "name", title: "API Name", for: "aitask_api" },
    { name: "type", title: "Type", for: "aitask_api" },
    { name: "createdby", title: "Created By", for: "aitask_api" },
    { name: "createdon", title: "Created On", for: "aitask_api" },
    { name: "action", title: "Action", route: "/ai-task-api/edit", for: "aitask_api" },
  ];
  
  const aiTaskApiDefaultColumnWidths = [
    // { columnName: 'id', width: 280 },
    { columnName: "name", weightage: 2.5, width: 200 },
    { columnName: "type", weightage: 5, width: 200 },
    { columnName: "createdby", weightage: 2, width: 200 },
    { columnName: "createdon", weightage: 2, width: 200 },
    { columnName: "action", weightage: 2.5, width: 200 },
  ];
  
  const aiTaskApiTableColumnExtensions = [
    // { columnName: 'id', align: 'left' },
    { columnName: "name", align: "left" },
    { columnName: "type", align: "left" },
    { columnName: "createdby", align: "left" },
    { columnName: "createdon", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
  ];
  
  const aiTaskApiColumnOrder = [
    // 'id',
    "name",
    "type",
    "createdby",
    "createdon",
    // "action",
  ];
  
  const aiTaskApiColumnHidden = [
    "name",
    "type",
    "createdby",
    "createdon",
    "action",
  ];
  
  const aiTaskApiDefaultSorting = [];
  
  export {
    aiTaskApiColumns,
    aiTaskApiDefaultColumnWidths,
    aiTaskApiTableColumnExtensions,
    aiTaskApiColumnOrder,
    aiTaskApiDefaultSorting,
    aiTaskApiColumnHidden
  };
  
