import { ApiHelper } from "./helper/ApiHelper";
import { APPLICATION, TEMPLATE, MICROSERVICE, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class ApplicationServices {
  
  getApplicationList(currentPage, filter, sort) {
    const queryParam = (`page=${currentPage}&size=10`) +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${APPLICATION}/api/v1.0.0/apps?${queryParam}`;
    return apiHelper.get(uri);
  }
  createApplication(data) {
    const uri = `${APPLICATION}/api/v1.0.0/apps`;
    return apiHelper.post(uri, data);
  }
  getApplicationByID(id) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${id}`;
    return apiHelper.get(uri);
  }
  editApplicationByID(id, req_data) {
    // /api/v1.0.0/apps/60fea6d3a1ad35205575f8a2/microservice
    const uri = `${APPLICATION}/api/v1.0.0/apps/${id}`;
    return apiHelper.put(uri, req_data)
  }
  deleteApplicationByID(id) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${id}`;
    return apiHelper.delete(uri)
  }
  getTemplatesByApplicationID(id) {
    const uri = `${TEMPLATE}/api/v1.0.0/applications/${id}/templates`;
    return apiHelper.get(uri);
  }
  fetchServiceDetails(mid, vid) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${mid}/versions/${vid}`;
    return apiHelper.get(uri);
  }
  fetchAllImgaes() {
    const uri = `${CONTAINER}/api/container/dockerimages?filters={"draft" : {"$ne": true}}`;
    return apiHelper.get(uri);
  }
  addDeployment(req_data, applicationID) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${applicationID}`;
    return apiHelper.post(uri, req_data);
  }
  runDeployment(applicationID) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${applicationID}/run`;
    return apiHelper.get(uri)
  }
  stopDeployment(applicationID) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${applicationID}/stop`;
    return apiHelper.get(uri)
  }
  addMicroservicesToApplication(id, req_data) {
    // /api/v1.0.0/apps/60fea6d3a1ad35205575f8a2/microservice
    const uri = `${APPLICATION}/api/v1.0.0/apps/${id}/microservice`;
    return apiHelper.put(uri, req_data)
  }
  removeMicroserviceFromApplication(mid, applicationID) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/${applicationID}/microservice/${mid}`;
    return apiHelper.delete(uri)
  }
  getDeploymentDetails(req_data) {
    const uri = `${APPLICATION}/api/v1.0.0/microservices/defaultenvironments`;
    return apiHelper.post(uri, req_data)
  }
  getGroupServiceBundleWithImageDetails(req_data) {
    const uri = `${APPLICATION}/api/v1.0.0/apps/group`;
    return apiHelper.post(uri, req_data)
  }
  getGroupServicesWithDependenciesDetails(req_data) {
    const uri = `${APPLICATION}/api/v1.0.0/microservices/versions/rte/group`;
    return apiHelper.post(uri, req_data)
  }
}
