import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    border: '1px solid #DFDFDF',
    borderRadius: 3,
    cursor:'pointer'
  },
  item: {
    width: '100%',
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.2,
    },
    borderRadius: 1,
  },
  typo:{
    paddingTop:5
  }
}));