import { v4 as uuidv4 } from "uuid";

export const getWidth = (data) => {
  switch (data) {
    case "Table":
      return 8;
    case "Details":
      return 4;
    case "Text":
      return 3;
    case "Button":
      return 3;
    case "Form":
      return 4;
    case "Heading":
      return 3;
    case "Link":
      return 3;
    case "Input":
      return 4;
    case "DatePicker":
      return 4;
    case "Select":
      return 4;
    case "TextArea":
      return 4;
    case "Toggle":
      return 2.3;
    case "CheckBox":
      return 2;
    case "Radio":
      return 5;
    case "Menu":
      return 20;
    case "Image":
      return 3;
    case "Tree":
      return 8;
    case "Custom Component":
      return 4;
    default:
      break;
  }
};
export const getHeight = (data) => {
  switch (data) {
    case "Table":
      return 25;
    case "Details":
      return 25;
    case "Text":
      return 4;
    case "Button":
      return 3.3;
    case "Form":
      return 25;
    case "Heading":
      return 3;
    case "Link":
      return 3;
    case "Input":
      return 4.3;
    case "DatePicker":
      return 4;
    case "Select":
      return 4.3;
    case "Toggle":
      return 3.3;
    case "TextArea":
      return 4.3;
    case "CheckBox":
      return 3.5;
    case "Radio":
      return 3.5;
    case "Menu":
      return 14;
    case "Image":
      return 8;
    case "Tree":
      return 25;
    case "Custom Component":
      return 14;
    default:
      break;
  }
};

export const componentBuilder = (
  data,
  name,
  uniqueId,
  layoutItem,
  activeAction,
  globalCss,
  currentProject,
  compId,
  currentPageName
) => {
  const commonObj = {
    id: uniqueId,
    _id: compId,
    i: uniqueId,
    name: name,
    x: layoutItem?.x,
    y: layoutItem?.y,
    w: getWidth(data) * 50,
    h: getHeight(data),
    resizeHandles: ["s", "w", "e", "n", "sw", "nw", "se", "ne"],
    isResizable: true,
    isBounded: true,
    useCSSTransforms: true,
    type: data,
    isSelected: false,
    condition: { dataInput: "true", dataValue: "true" },
    columns: [],
    rowHeight: 1,
    pagename: currentPageName,
    uiappid: currentProject?.id,
    fontSize: globalCss?.fontSize,
  };
  const commonTrigger = {
    onInit: "",
    onsubmit: "",
    onClick: "",
  };
  const commonAlignMent = {
    direction: false,
    alignment: false,
  };
  const commonDisabled = {
    disabled: {
      dataInput: "false",
      dataValue: "false",
    },
  };
  switch (data) {
    case "Table":
      let columns = [];
      if (activeAction?.data && activeAction?.data?.length) {
        columns = Object.keys(activeAction?.data[0]).map((col) => ({
          id: uuidv4(),
          name: col,
          isHide: false,
          isDelete: false,
          title: col.charAt(0).toUpperCase() + col.slice(1),
          width: "",
        }));
      }
      return {
        ...commonObj,
        minH: 16,
        minW: 5 * 50,
        title: { dataInput: "Title", dataValue: "Title" },
        itemsPerPage: { dataInput: "10", dataValue: "10" },
        loading: { dataInput: "false", dataValue: "false" },
        btnText: "Submit",
        data: {
          dataInput: `{{actions.${activeAction?.name}.data}}`,
          dataValue: activeAction?.data,
        },
        columns: columns,
        status: globalCss?.color,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        onInit: "",
        onEdit: "",
        onDelete: "",
        onRowSelect: "",
        onAdd: "",
        borderWidth: 1,
        filter: false,
      };
    case "Details":
      let detailColumns = [];
      if (activeAction?.data && activeAction?.data?.length) {
        detailColumns = Object.keys(activeAction?.data[0]).map((col) => ({
          id: uuidv4(),
          name: col,
          isHide: false,
          type: "text",
          isRequired: true,
          title: col.charAt(0).toUpperCase() + col.slice(1),
          width: "",
          isEditable: false,
          options: [],
        }));
      }
      return {
        ...commonObj,
        ...commonTrigger,
        minH: 16,
        minW: 5 * 50,
        title: { dataInput: "User", dataValue: "User" },
        itemsPerPage: 1,
        loading: { dataInput: "false", dataValue: "false" },
        btnText: "Submit",
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        data: {
          dataInput: `{{actions.${activeAction?.name}.data}}`,
          dataValue: activeAction?.data,
        },
        columns: detailColumns,
      };
    case "Text":
      return {
        ...commonObj,
        direction: false,
        alignment: "left",
        minW: 2 * 50,
        minH: 4,
        txtValue: { dataInput: "Text", dataValue: "Text" },
        txtType: "paragraph",
        status: globalCss?.color,
        disabled: false,
        onInit: "",
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Button":
      return {
        ...commonObj,
        ...commonDisabled,
        ...commonAlignMent,
        minH: 3.3,
        minW: 2 * 50,
        btnText: { dataInput: "Button", dataValue: "Button" },
        link: { dataInput: "", dataValue: "" },
        acion: "",
        queryParams: [{ id: uuidv4(), key: "", value: "" }],
        queryParamsJSMode: false,
        jsQueryParams: { dataInput: "", dataValue: "" },
        status: globalCss?.color,
        btnType: "contained",
        urlType: "page",
        fontFamily: globalCss?.fontFamily,
        icon: false,
        onClick: "",
        onEdit: "",
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        uiApplication: {
          id: currentProject?.id,
          name: currentProject?.name,
          previewMode: currentProject?.previewMode,
        },
      };
    case "Form":
      let cols = [];
      if (activeAction?.data && activeAction?.data?.length) {
        cols = Object.keys(activeAction?.data).map((col) => ({
          id: uuidv4(),
          name: col,
          isHide: false,
          isDelete: false,
          isRequired: true,
          placeholder: "",
          type: "text",
          title: col.charAt(0).toUpperCase() + col.slice(1),
          width: "",
          rows: 2,
          options: [],
          isEditable: true,
        }));
      }
      return {
        ...commonObj,
        ...commonTrigger,
        minH: 16,
        minW: 3 * 50,
        title: { dataInput: "User", dataValue: "User" },
        itemsPerPage: 1,
        loading: { dataInput: "false", dataValue: "false" },
        btnText: "Submit",
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        submitTo: null,
        data: {
          dataInput: `{{actions.${activeAction?.name}.data}}`,
          dataValue: activeAction?.data,
        },
        columns: cols,
      };
    case "Heading":
      return {
        ...commonObj,
        direction: false,
        alignment: "left",
        minW: 2 * 50,
        minH: 3,
        headingValue: { dataInput: "Heading", dataValue: "Heading" },
        status: globalCss?.color,
        disabled: false,
        fontFamily: globalCss?.fontFamily,
        onInit: "",
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Link":
      return {
        ...commonObj,
        ...commonDisabled,
        minW: 2 * 50,
        minH: 3,
        linkText: { dataInput: "Text Link", dataValue: "Text Link" },
        link: { dataInput: "", dataValue: "" },
        action: "",
        queryParams: [{ id: uuidv4(), key: "", value: "" }],
        queryParamsJSMode: false,
        jsQueryParams: { dataInput: "", dataValue: "" },
        direction: false,
        alignment: "left",
        urlType: "page",
        status: globalCss?.color,
        color: globalCss?.color,
        fontFamily: globalCss?.fontFamily,
        onInit: "",
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        uiApplication: {
          id: currentProject?.id,
          name: currentProject?.name,
          previewMode: currentProject?.previewMode,
        },
      };
    case "Input":
      return {
        ...commonObj,
        ...commonAlignMent,
        ...commonDisabled,
        minH: 4.3,
        minW: 3 * 50,
        data: { dataInput: "", dataValue: "" },
        label: { dataInput: "Label", dataValue: "Label" },
        placeHolder: { dataInput: "", dataValue: "" },
        status: globalCss?.color,
        onChange: "",
        onEnter: "",
        onClick: "",
        isRequired: true,
        fontFamily: globalCss?.fontFamily,
        textType: "text",
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "DatePicker":
      return {
        ...commonObj,
        ...commonAlignMent,
        ...commonDisabled,
        minH: 4,
        minW: 3 * 50,
        value: new Date(),
        label: { dataInput: "Label", dataValue: "Label" },
        placeHolder: "",
        status: globalCss?.color,
        fontFamily: globalCss?.fontFamily,
        onChange: "",
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Select":
      return {
        ...commonObj,
        ...commonAlignMent,
        ...commonDisabled,
        accessValue: "",
        minH: 4.3,
        minW: 3 * 50,
        label: { dataInput: "Label", dataValue: "Label" },
        options: [
          {
            id: uuidv4(),
            title: "option1",
            name: "option1",
          },
          {
            id: uuidv4(),
            title: "option2",
            name: "option2",
          },
        ],
        data: { dataInput: ``, dataValue: activeAction?.data },
        accessValueData: { dataInput: ``, dataValue: null },
        jsValueData: { dataInput: ``, dataValue: null },
        tabValue: false,
        jsValue: "",
        placeHolder: { dataInput: "", dataValue: "" },
        status: globalCss?.color,
        onChange: "",
        onEnter: "",
        onClick: "",
        isRequired: true,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Toggle":
      return {
        ...commonObj,
        ...commonDisabled,
        minH: 3.3,
        minW: 2 * 50,
        data: { dataInput: "", dataValue: false },
        setValue: "",
        resetValue: "",
        status: globalCss?.color,
        onChange: "",
        onInit: "",
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "TextArea":
      return {
        ...commonObj,
        ...commonAlignMent,
        ...commonDisabled,
        minH: 4.3,
        minW: 3 * 50,
        data: { dataInput: "", dataValue: "" },
        label: { dataInput: "Label", dataValue: "Label" },
        placeHolder: { dataInput: "", dataValue: "" },
        status: globalCss?.color,
        rows: 1,
        onChange: "",
        onEnter: "",
        onClick: "",
        isRequired: true,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "CheckBox":
      return {
        ...commonObj,
        ...commonDisabled,
        minH: 3.5,
        minW: 2 * 50,
        data: { dataInput: "", dataValue: "false" },
        text: { dataInput: "CheckBox", dataValue: "CheckBox" },
        status: globalCss?.color,
        onClick: "",
        onInit: "",
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Radio":
      return {
        ...commonObj,
        ...commonDisabled,
        minH: 3.5,
        minW: 3 * 50,
        data: { dataInput: "", dataValue: "" },
        label: "",
        options: [
          {
            id: uuidv4(),
            title: `Option 1`,
            value: `value 1`,
          },
          {
            id: uuidv4(),
            title: `Option 2`,
            value: `value 2`,
          },
        ],
        status: globalCss?.color,
        onChange: "",
        onInit: "",
        tabValue: false,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
      };
    case "Menu":
      return {
        ...commonObj,
        minW: 78,
        minH: 8,
        menuWidth: 237,
        isTypeHamburgerWebView: false,
        isTitlePositionCenter: false,
        data: {
          dataInput: `return  [
            {
              title: "Menu Level 1.1",
              link: "home",
              children: [
                {
                  title: "Menu Level 2.1",
                  link: "",
                  submenuChildren: [
                    {
                      title: "Menu Level 3.1",
                      link: "",
                    },
                  ],
                },
              ],
            },
          ]`,
          dataValue: [
            {
              title: "Menu Level 1.1",
              link: "home",
              children: [
                {
                  title: "Menu Level 2.1",
                  link: "",
                  submenuChildren: [
                    {
                      title: "Menu Level 3.1",
                      link: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
        tabValue: true,
        options: [
          {
            id: uuidv4(),
            title: "Menu Level 1.1",
            link: "home",
            hide: false,
            urlType: "page",
            linkedUiApp: {},
            isExpanded: false,
            children: [
              {
                id: uuidv4(),
                title: "Menu Level 2.1",
                link: "",
                hide: false,
                urlType: "page",
                isExpanded: false,
                linkedUiApp: {},
                submenuChildren: [
                  {
                    id: uuidv4(),
                    title: "Menu Level 3.1",
                    link: "",
                    hide: false,
                    urlType: "page",
                    linkedUiApp: {},
                  },
                ],
              },
            ],
          },
        ],
        logoHidden: false,
        logoPosition: false,
        status: globalCss?.color,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        onInit: "",
        uiApplication: {
          id: currentProject?.id,
          name: currentProject?.name,
          previewMode: currentProject?.previewMode,
        },
      };
    case "Image":
      return {
        ...commonObj,
        minH: 4,
        minW: 2 * 50,
        file: "",
        link: "",
        imgUrl: {
          dataInput: "",
          dataValue: "/img/Image.svg",
        },
        tabletImgUrl: {
          dataInput: "",
          dataValue: "/img/Image.svg",
        },
        mobileImgUrl: {
          dataInput: "",
          dataValue: "/img/Image.svg",
        },
        imgHeight: "",
        imgWidth: "",
        webImgUpload: true,
        tabletImgUpload: true,
        mobileImgUpload: true,
        imgRatio: 0.28,
      };
    case "Tree":
      let treeColumns = [];
      if (activeAction?.data && activeAction?.data?.length) {
        treeColumns = Object.keys(activeAction?.data[0]).map((col) => ({
          id: uuidv4(),
          name: col,
          isHide: false,
          isDelete: false,
          title: col.charAt(0).toUpperCase() + col.slice(1),
          width: "",
        }));
      }
      return {
        ...commonObj,
        minH: 10,
        minW: 3 * 50,
        data: {
          dataInput: `{{actions.${activeAction?.name}.data}}`,
          dataValue: activeAction?.data,
        },
        title: { dataInput: "", dataValue: "" },
        columns: treeColumns,
        loading: { dataInput: "false", dataValue: "false" },
        status: globalCss?.color,
        fontFamily: globalCss?.fontFamily,
        style: {
          bold: globalCss?.fontStyle?.bold,
          italic: globalCss?.fontStyle?.italic,
          underline: globalCss?.fontStyle?.underline,
        },
        onExpandRow: "",
        onRowSelect: "",
      };
    case "Custom Component":
      return {
        ...commonObj,
        code: `<!DOCTYPE html>
<html>
<head>
  <script src="https://unpkg.com/react@17/umd/react.development.js" crossorigin></script>
  <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js" crossorigin></script>
  <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
</head>
<body>
  <div id="root"></div>

  <script type="text/babel">
    function Demo() {
        const data = LCF.getData()
        return <div>
        <h1>Hello World!</h1>
        <button onClick={() => clickMe(data)}>Click</button>
        </div>;
    }

    const clickMe = (data) => {
        console.log('click : ', data)
    }

    ReactDOM.render(<Demo />, document.getElementById('root'))
  </script>
</body>
</html>`,
        onEvent: "",
        actionArguements: "",
        loading: { dataInput: "false", dataValue: "false" },
        data: {
          dataInput: `{{actions.${activeAction?.name}.data}}`,
          dataValue: activeAction?.data,
        },
      };
    default:
      return {
        id: uniqueId,
        i: uniqueId,
        x: layoutItem.x,
        y: layoutItem.y,
        w: getWidth(data),
        h: getHeight(data),
        minH: 2,
        minW: 1 * 50,
        resizeHandles: ["s", "w", "e", "n", "sw", "nw", "se", "ne"],
        isResizable: true,
        type: data,
        title: "User",
        itemsPerPage: 1,
        loading: { dataInput: "false", dataValue: "false" },
        btnText: "Submit",
        isSelected: false,
        columns: [],
        dataInput: `{{actions.${activeAction?.name}.data}}`,
        data: activeAction?.data,
      };
  }
};
