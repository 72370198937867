import axios from "axios";
import { store } from '../../../redux/store';

let userId = '';
let baseUrl = "";
let organization="";

if (process.env.NODE_ENV === 'production') {
    baseUrl = window?.location?.origin
} else {
    baseUrl = process.env.REACT_APP_AWS_BASE_URL
}

const listener = () => {
  let state = store.getState();
  const { userid } = state.authReducer;
  const {currentOrgData}=state.organizationReducer;
  userId = userid;
  organization=currentOrgData?.data?._id
};
store.subscribe(listener);
export class UiApplicationApiHelper {
  async get(uri, headers) {
    const response = await axios.get(uri, {
      headers: {
        ...headers,
        userId: userId,
        baseUrl,
        organization
      },
    });
    return response;
  }
  async post(uri, data, headers) {
    const response = await axios.post(uri, data, {
      headers: {
        ...headers,
        userId: userId,
        baseUrl,
        organization
      },
    });
    return response;
  }
  async delete(uri, headers) {
    const response = await axios.delete(uri, {
      headers: {
        ...headers,
        userId: userId,
        baseUrl,
        organization
      },
    });
    return response;
  }
  async put(uri, data, headers) {
    const response = await axios.put(uri, data, {
      headers: {
        ...headers,
        userId: userId,
        baseUrl,
        organization
      },
    });
    return response;
  }
}