import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { selectProcessCheck } from "../../../redux/actions/deploymentProcessAction";
import Loader from "../../../components/common/stuff/Loader";

const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
}));

const newStyles = makeStyles((theme) => ({
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",
      "& tr": {
        color: "#FFFFFF",
      },
      "& th": {
        color: "#FFFFFF",
        lineHeight: "2.5rem",
        paddingLeft: "30px",
        padding: "0px",
      },
      "& th:last-child": {
        textAlign: "left",
      },
    },
    "& tbody": {
      marginLeft: "30px",

      "& tr": {
        "& td:last-child": {
          textAlign: "center",
        },
      },
    },
  },

  nodata: {
    height: "6rem",
    position: "sticky",
    left: "45%",
    top: "3rem",
    paddingTop: "1rem",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
}));

function DeploymentProcessPreview({ process, selectProcessCheck }) {
  const tStyles = tempStyles();
  const styles = newStyles();
  const [filterProcess, setFilterProcess] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    const filteredContainers = process?.filter((item)=> item.select)
    setFilterProcess(filteredContainers)
    setTableLoading(false)
  }, [])

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        <Paper>
        {tableLoading ? (
                <Loader />
              ) : (
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Process name</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>Endpoint</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterProcess &&
                  filterProcess.length > 0 &&
                  filterProcess.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                        padding="checkbox"
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                      >
                        {row?.method}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                      >
                        {row?.endpoint}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {filterProcess?.length === 0 && (
                <div className={styles.nodata}>
                  <text>No Data Available</text>
                </div>
              )}
            </Table>
          </TableContainer>
          )}
        </Paper>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  selectProcessCheck,
};

const mapStateToProps = (state) => {
  const { process } = state.deploymentProcessReducer.dependenciesData;
  return {
    process,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentProcessPreview);
