import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory, useLocation } from "react-router-dom";
import AssignModal from "../AssignModal";
import { useSnackbar } from "notistack";

// Modal
import LockUnlockModal from "../modals/LockUnblockModal";
import LockModal from "../modals/LockModal";
import UnlockModal from "../modals/UnLockModal";
import BlockModal from "../modals/BlockModal";
import UnBlockModal from "../modals/UnBlockModal";
import DeleteModal from "../modals/DeleteModal";
import TeamMembersModal from "../../Teams/TeamMembersModal";
import VendorProject from "../modals/VendorProject";
import Loader from "../stuff/Loader";
// Utils
import {
  DeleteLanguage,
  BlockLanguage,
  UnBlockLanguage,
  fetchLanguageDependencyByID,
} from "../../../utils/applicationGovernance/launguageUtils";
import { DeleteBoilerPlates } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { DeleteChassisTemplate } from "../../../utils/applicationGovernance/chassisTemplateUtils";
import {
  DeleteDockerImage,
  getDockerImageById,
} from "../../../utils/containerMangement/imageUtils";
import {
  DeleteContainer,
  StartContainer,
  StopContainer,
} from "../../../utils/containerMangement/containerUtils";
import {
  DeleteRuntimeEnv,
  StartRuntimeEnv,
  StopRuntimeEnv,
} from "../../../utils/containerMangement/runtimeEnvUtils";
import { deleteOrganization } from "../../../utils/organization/organizationUtils";
import {
  getUserTeamDetails,
  deleteUser,
  blockUser,
  unBlockUser,
  deleteFavouriteInResource,
  addFavouriteInResource,
} from "../../../utils/users/userUtils";
import {
  LockTeam,
  UnLockTeam,
  BLockTeam,
  UnBLockTeam,
} from "../../../utils/teams/teamsUtils";
import { blockVendor, UnblockVendor } from "../../../utils/vendor/vendorUtils";
import { BASE_URL } from "../../../services/helper/config";

//glossary
import GlossaryDetailModal from "../../../screens/Glossery/Modal/GlossaryDetailModal";

// State refresh
import { useDispatch, useSelector } from "react-redux";
import {
  delete_language,
  delete_boilerplate,
  delete_chassisTemplate,
  block_unblock_language,
} from "../../../redux/actions/appGovernanceAction";
import { delete_dockerImage } from "../../../redux/actions/dockerImageAction";
import {
  delete_container,
  start_or_stop_container,
} from "../../../redux/actions/containerAction";
import {
  delete_runtimeEnv,
  start_or_stop_runtimeEnv,
} from "../../../redux/actions/runtimeEnvAction";
import {
  delete_user,
  block_unblock_user,
} from "../../../redux/actions/userInfoActions";
import {
  blockField,
  unblockField,
  blockFieldGroup,
  unblockFieldGroup,
  deleteFieldGroup,
  deleteField,
  fetchAllFieldGroups,
} from "../../../utils/dataStandards/fieldsUtils";
import {
  blockUnblockFieldByID,
  blockUnblockPayloadByID,
  deletefield,
  deleteFieldgroup,
  deleteGlossaryById,
  deleteTagUtil,
  alterFavouriteGlossaryById,
} from "../../../redux/actions/dataStandardAction";
import moment from "moment";
import {
  alterFavouriteVendorById,
  block_unblock_vendor,
} from "../../../redux/actions/vendorAction";
import {
  lock_unlock_team,
  block_unblock_team,
} from "../../../redux/actions/teamActions";
import { active_inactive_org } from "../../../redux/actions/organizationAction";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import DependencyModal from "../../../components/common/modals/DependencyModal";

import {
  addSingleFavourite,
  removeSingleFavourite,
} from "../../../redux/actions/userPersonalAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E2E2E3",
    borderRadius: "unset",
    boxShadow: "unset",
  },
  myDevTable: {
    // "& table": {
    //     tableLayout: 'auto'
    // },
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      // background: "#F9FAFB 0% 0% no-repeat padding-box",
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        font: "normal normal normal 16px/22px Nunito",
        textTransform: "capitalize",
        fontWeight: "500",
        padding: "12px 20px 12px 30px !important",

        "& span": {
          font: "normal normal 5000 16px/22px Nunito Sans",
          color: "#FFFFFF",
        },

        "& svg": {
          color: "#FFFFFF !important",
        },

        "& > div:last-child div": {
          backgroundColor: "#FFFFFF !important",
        },

        "&:nth-last-child(2) .MuiTableSortLabel-root": {
          position: "relative",
          right: 30,
        },
      },
    },
    "& tbody": {
      "& .MuiTableRow-root": {
        height: 47,
      },
      "& tr td": {
        padding: "8px 20px 8px 30px !important",
        font: "normal normal normal 14px/19px Nunito",
        color: "#000000",
      },
      "& tr:hover": {
        background: "#F9FAFB 0% 0% no-repeat padding-box",
        cursor: "pointer",

        "& td span": {
          visibility: "visible",
        },
      },
    },

    // For Toolbar
    "& div > div:first-child": {
      borderBottom: "unset",
      minHeight: 0,

      "& > button": {
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 501,
        color: "#FFFFFF",
        background: theme.palette.primary.main,
        borderRadius: 0,
        padding: "13.5px 15px 13.5px",
      },
    },

    // For pagination
    "& div div:last-child": {
      "& div:first-child span": {
        fontSize: 14,
      },

      "& div:last-child span": {
        fontSize: 14,
      },

      "& div:last-child .MuiButton-text": {
        padding: "10px 13px",
        lineHeight: 1,
        margin: "0 10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 10px #5757571A",
        border: "0.5px solid #707070",
        borderRadius: 5,
      },

      "& td span": {
        visibility: "visible",
      },
    },
  },
  process_text: {
    textAlign: "right",
    textDecoration: "underline",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#031CCA",
    opacity: 0.6,
    alignItems: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  icon_style1: {
    marginRight: 10,
  },
  icon_style2: {
    marginLeft: 10,
  },
  selectedRowsText: {
    fontSize: 14,
    position: "absolute",
    bottom: 25,
    left: 20,
  },
  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
  actionIcons: {
    // border: "1px solid",
    width: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    // Team Lock Modal
    alertImg: {
      width: 76,
      height: 76,
    },
    cancel: {
      background: "#F9FAFD 0% 0% no-repeat padding-box",
      border: "1px solid #052855",
      borderRadius: 5,
      opacity: 1,
      color: "#052855",
    },
    submitMember: {
      background: "#052855 0% 0% no-repeat padding-box",
      borderRadius: 5,
      opacity: 1,
      color: "#FFFFFF",
      "&:hover": {
        background: "#052855 0% 0% no-repeat padding-box",
      },
    },
    userImg: {
      height: "50px !important",
      width: 50,
      margin: 0,
      padding: 0,
    },
    userName: {},
  },
  ActionIconBox: {
    width: 100,
    marginLeft: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  teamMember: {
    margin: 0,
    padding: 0,
    color: "#031CCA",
    textDecoration: "underline",
    font: "normal normal normal 14px/19px Nunito",
  },
  actionIcon: {
    display: "flex",
    padding: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    "& span": {
      visibility: "visible",
    },
  },

  launguageactionIcons: {
    // border: "1px solid",
    width: 80,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  builtenv: {
    "& .MuiTableCell-root": {
      borderBottom: "0 !important",
      padding: "0 30px 0 0 !important",
      // margin: "0 !importatnt",
    },
  },
  showblock: {
    "& span": {
      visibility: "visible",
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const DocumentNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        onClick={() =>
          history.push(`${restProps.column.route}/${restProps.row.id}`)
        }
      >
        <text className={styles.application_name}>{restProps.row.name}</text>
      </div>
    </VirtualTable.Cell>
  );
};
const AssignModalCell = ({ ...restProps }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const { id, name } = restProps.row;

  const handleCloseClick = () => {
    setOpen(false);
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actions} style={{ paddingLeft: 10 }}>
        <span onClick={() => setOpen(true)}>
          <img src="/images/application_list.svg" alt="icon" />
        </span>
        {open && (
          <AssignModal
            open={open}
            onCloseClick={handleCloseClick}
            id={id}
            name={name}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};
const UserProjectAssignModalCell = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actions} style={{ paddingLeft: 10 }}>
        <span onClick={handleModalClickOpen}>
          <img src="/images/application_list.svg" alt="icon" />
        </span>
        {ModalOpen && (
          <AssignModal
            open={ModalOpen}
            onCloseClick={handleModalClose}
            _id={restProps.row._id}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const EditAction = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        <div
          className={styles.actions}
          onClick={() =>
            history.push(`${restProps.column.route}/${restProps.row._id}`)
          }
        >
          <span>
            <img src="/images/teams/edit.svg" alt="edit" />
          </span>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

// Teams
const TeamNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {restProps?.row?.type === "external" && (
          <div
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#00B5AD",
              borderRadius: 3,
            }}
          ></div>
        )}
        {restProps?.row?.type === "internal" && (
          <div
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#A333C8",
              borderRadius: 3,
            }}
          ></div>
        )}
        <div style={{ paddingLeft: 10 }}>
          <text className={styles.application_name}>
            {restProps?.row?.name}
          </text>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

const TeamMemberCell = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps?.row?.users && (
        <div>
          <div className={styles.actions}>
            <span onClick={handleModalClickOpen} style={{ color: "#2185D0" }}>
              <span>{restProps?.row?.users?.length}</span> {"Members"}
            </span>
            {ModalOpen && (
              <TeamMembersModal
                row={restProps?.row}
                open={ModalOpen}
                handleClose={handleModalClose}
              />
            )}
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const ActionIconsCell = ({ ...restProps }) => {
  const search = useLocation().search;
  const serviceCheck = new URLSearchParams(search).get("service");
  const dataStandardCheck = new URLSearchParams(search).get("datastandards");
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);

  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [dependencyData, setDependencyData] = useState([]);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleLockModalClickOpen = () => {
    setLockModalOpen(true);
  };
  const handleLockModalClose = () => {
    setLockModalOpen(false);
  };
  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };
  const miscError = "Something went wrong. Please contact help desk.";

  const handleBlock = async () => {
    const { _msg, _status } =
      restProps.column.for === "field"
        ? await blockField(restProps.row._id)
        : await blockFieldGroup(restProps.row._id);
    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 201 ? { variant: "success" } : { variant: "error" }
    );
    if (_status === 201) {
      if (restProps.column.for === "field") {
        dispatch(blockUnblockFieldByID(restProps.row._id, "inactive"));
      } else {
        dispatch(blockUnblockPayloadByID(restProps.row._id, "inactive"));
      }
    }
    setBlockModalOpen(false);
  };

  const handleUnblock = async () => {
    const { _msg, _status } =
      restProps.column.for === "field"
        ? await unblockField(restProps.row._id)
        : await unblockFieldGroup(restProps.row._id);
    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 201 ? { variant: "success" } : { variant: "error" }
    );
    if (_status === 201) {
      if (restProps.column.for === "field") {
        dispatch(blockUnblockFieldByID(restProps.row._id, "active"));
      } else {
        dispatch(blockUnblockPayloadByID(restProps.row._id, "active"));
      }
    }
    setBlockModalOpen(false);
  };

  const [isLoader, setIsLoader] = useState(false);
  const handleGlossaryDelete = async (_id) => {
    setDeleteModalOpen(false);
    setIsLoader(true);
    const { _msg, _status } = await deleteTagUtil(restProps?.row?._id);
    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 200 ? { variant: "success" } : { variant: "error" }
    );
    if (_status === 200) {
      dispatch(deleteGlossaryById(restProps?.row?._id));
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }
  };

  const handleDelete = async () => {
    if (restProps.row.status === "inactive") {
      setDeleteModalOpen(false);
      enqueueSnackbar("Cannot delete a Blocked payload. Kindly unblock first", {
        variant: "error",
      });
    } else {
      const { _msg, _status, data } = await deleteFieldGroup(restProps.row._id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDeleteModalOpen(false);
        dispatch(deleteFieldgroup(restProps.row._id));
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setDeleteModalOpen(false);
      }
    }
  };

  const { checkCompPermission } = useCheckPermission();

  const handleEdit = () => {
    if (restProps.row.status === "active") {
      if (restProps.column.for === "field") {
        if (!checkCompPermission("GlossaryField", ["Edit"])) {
          enqueueSnackbar(
            `You do not have permission to access Edit in GlossaryField. Please contact your Admin.`,
            { variant: "error" }
          );
        } else {
          history.push(`${restProps.column.route}/${restProps.row._id}`);
        }
      } else if (restProps.column.for === "fieldgroup") {
        if (!checkCompPermission("GlossaryFieldGroup", ["Edit"])) {
          enqueueSnackbar(
            `You do not have permission to access Edit in GlossaryFieldGroup. Please contact your Admin.`,
            { variant: "error" }
          );
        } else {
          if (serviceCheck) {
            history.push(
              `${restProps.column.route}/${restProps.row._id}?service=true`
            );
          } else if (dataStandardCheck) {
            history.push(
              `${restProps.column.route}/${restProps.row._id}?datastandards=true`
            );
          } else {
            history.push(`${restProps.column.route}/${restProps.row._id}`);
          }
        }
      }
    } else {
      if (restProps.column.for === "fieldgroup") {
        enqueueSnackbar(
          `Cannot edit a Blocked payload. Kindly unblock first.`,
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(
          `Cannot edit a Blocked ${restProps.column.for}. Kindly unblock first.`,
          { variant: "error" }
        );
      }
    }
  };
  //Fetch dependencies
  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    const { _msg, _status, data } = await fetchAllFieldGroups();
    const filterData = data?.filter((item) =>
      item?.children?.some((subItem) => subItem?._id === restProps?.row?._id)
    );

    if (_status === 200) {
      const dataDictionaryData =
        filterData !== null
          ? filterData?.map((d) => {
              return {
                title: d.name,
                expanded: false,
              };
            })
          : [];
      const tempData = [
        {
          title:
            dataDictionaryData?.length === 0 ? "No Payloads found" : "Payloads",
          expanded: true,
          children: [...dataDictionaryData],
        },
      ];

      setDependencyData(tempData);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  // close dependency modal
  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };

  const handleDeleteField = async () => {
    if (restProps.row.status === "inactive") {
      setDeleteModalOpen(false);
      enqueueSnackbar("Cannot delete a Blocked payload. Kindly unblock first", {
        variant: "error",
      });
    } else {
      const { _msg, _status, data } = await deleteField(restProps.row._id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDeleteModalOpen(false);
        dispatch(deletefield(restProps.row._id));
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setDeleteModalOpen(false);
      }
    }
  };

  const handleAddFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    const favoriteData = {
      resourcetype: resourceType,
      resourceinstance: resourceinstance,
    };
    const { _msg, _status, data } = await addFavouriteInResource(
      userData.userid,
      favoriteData
    );

    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "glossary") {
        dispatch(alterFavouriteGlossaryById(resourceinstance));
      }
      dispatch(addSingleFavourite(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDeleteFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    const { _msg, _status, data } = await deleteFavouriteInResource(
      userData.userid,
      resourceType,
      resourceinstance
    );

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "glossary") {
        dispatch(alterFavouriteGlossaryById(resourceinstance));
      }
      dispatch(removeSingleFavourite(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  return (
    <>
      {" "}
      {isLoader && <Loader />}
      <VirtualTable.Cell {...restProps}>
        {restProps.column.for === "fieldgroup" ||
        restProps.column.for === "field" ? (
          <div
            className={styles.ActionIconBox}
            style={
              restProps.column.for === "field"
                ? {
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }
                : {}
            }
            // style={{ width: "4rem", marginLeft: "1rem" }}
          >
            <Tooltip title="Edit" arrow>
              <div className={styles.actionIcon} onClick={handleEdit}>
                <span>
                  <img src="/images/editLine.svg" alt="edit" />
                </span>
              </div>
            </Tooltip>
            <Tooltip
              title={restProps.row.status === "active" ? "Block" : "Unblock"}
              arrow
            >
              <div className={styles.actionIcon}>
                <span
                  onClick={() => {
                    if (restProps.column.for === "field") {
                      if (
                        !checkCompPermission("GlossaryField", [
                          "Block Or Unblock",
                        ])
                      ) {
                        enqueueSnackbar(
                          `You do not have permission to access Block Or Unblock in GlossaryField. Please contact your Admin.`,
                          { variant: "error" }
                        );
                      } else {
                        handleBlockModalClickOpen();
                      }
                    } else if (restProps.column.for === "fieldgroup") {
                      if (
                        !checkCompPermission("GlossaryFieldGroup", [
                          "Block Or Unblock",
                        ])
                      ) {
                        enqueueSnackbar(
                          `You do not have permission to access Block Or Unblock in GlossaryFieldGroup. Please contact your Admin.`,
                          { variant: "error" }
                        );
                      } else {
                        handleBlockModalClickOpen();
                      }
                    }
                  }}
                >
                  <img
                    src="/images/teams/block.svg"
                    alt="block"
                    style={
                      restProps.row.status === "active"
                        ? { filter: "grayscale(100)" }
                        : { opacity: "1" }
                    }
                  />
                </span>
                {blockModalOpen && restProps.row.status === "active" && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
                {blockModalOpen && restProps.row.status !== "active" && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnblock}
                  />
                )}
              </div>
            </Tooltip>
            {/* Delete */}
            {restProps.column.for === "field" && (
              <>
                <Tooltip title="Delete" arrow>
                  <div className={styles.actionIcon}>
                    <span
                      onClick={() => {
                        if (
                          !checkCompPermission("GlossaryFieldGroup", ["Delete"])
                        ) {
                          enqueueSnackbar(
                            `You do not have permission to access Edit in GlossaryFieldGroup. Please contact your Admin.`,
                            { variant: "error" }
                          );
                        } else {
                          handleDeleteModalClickOpen();
                        }
                      }}
                    >
                      <img src="/images/governance/delete.svg" alt="lock" />
                    </span>
                  </div>
                </Tooltip>
                {deleteModalOpen && (
                  <DeleteModal
                    name={restProps.row.name}
                    row={restProps.row}
                    open={deleteModalOpen}
                    handleClose={handleDeleteModalClose}
                    handleDelete={handleDeleteField}
                  />
                )}
                <Tooltip title="Dependencies" arrow>
                  <div className={styles.actionIcon}>
                    <span onClick={handleDependencyModalClickOpen}>
                      <img src="/images/info_icon.svg" alt="dependencies" />
                    </span>
                  </div>
                </Tooltip>
                {dependencyModalOpen && (
                  <DependencyModal
                    // name={restProps.row.name}
                    modalTitle={
                      dependencyData.length !== 0
                        ? "Dependencies"
                        : "No Dependencies"
                    }
                    treeDataArr={dependencyData}
                    row={restProps.row}
                    open={dependencyModalOpen}
                    handleClose={handleDependencyModalClose}
                    // handleDelete={handleDelete}
                  />
                )}
              </>
            )}
            {restProps.column.for === "fieldgroup" && (
              <>
                <Tooltip title="Delete" arrow>
                  <div className={styles.actionIcon}>
                    <span
                      onClick={() => {
                        if (
                          !checkCompPermission("GlossaryFieldGroup", ["Delete"])
                        ) {
                          enqueueSnackbar(
                            `You do not have permission to access Edit in GlossaryFieldGroup. Please contact your Admin.`,
                            { variant: "error" }
                          );
                        } else {
                          handleDeleteModalClickOpen();
                        }
                      }}
                    >
                      <img src="/images/governance/delete.svg" alt="lock" />
                    </span>
                  </div>
                </Tooltip>
                {deleteModalOpen && (
                  <DeleteModal
                    name={restProps.row.name}
                    row={restProps.row}
                    open={deleteModalOpen}
                    handleClose={handleDeleteModalClose}
                    handleDelete={handleDelete}
                  />
                )}
              </>
            )}
          </div>
        ) : restProps.column.for === "glossary" ? (
          <div
            className={styles.actionIcons}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "-20px",
            }}
          >
            <Tooltip
              title={restProps.row?.isFavourite ? "Unfavourite" : "Favourite"}
              arrow
            >
              <div
                className={styles.actions}
                style={{ marginRight: "35px", marginLeft: "-10px" }}
                onClick={() => {
                  if (restProps.row?.isFavourite) {
                    if (restProps.column.for === "glossary") {
                      handleDeleteFavouite("glossary", restProps.row._id);
                    } else {
                      handleDeleteFavouite(
                        restProps.column.for,
                        restProps.row._id
                      );
                    }
                  } else {
                    if (restProps.column.for === "glossary") {
                      handleAddFavouite("glossary", restProps.row._id);
                    } else {
                      handleAddFavouite(
                        restProps.column.for,
                        restProps.row._id
                      );
                    }
                  }
                }}
              >
                <span>
                  {restProps.row?.isFavourite ? (
                    <img src="/images/heartFilled.svg" alt="card" />
                  ) : (
                    <img src="/images/heartLine.svg" alt="card" />
                  )}
                </span>
              </div>
            </Tooltip>
            <Tooltip title="Edit" arrow>
              <div
                className={styles.actions}
                style={{ marginRight: "22px", marginLeft: "-10px" }}
                onClick={() => {
                  if (!checkCompPermission("GlossaryTag", ["Edit"])) {
                    enqueueSnackbar(
                      `You do not have permission to access Edit in GlossaryTag. Please contact your Admin.`,
                      { variant: "error" }
                    );
                  } else {
                    history.push(`/edit-glossary/${restProps.row._id}`);
                  }
                }}
              >
                <span>
                  <img src="/images/editLine.svg" alt="edit" />
                </span>
              </div>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <div className={styles.actions}>
                <span
                  onClick={() => {
                    if (!checkCompPermission("GlossaryTag", ["Delete"]))
                      enqueueSnackbar(
                        `You do not have permission to access Delete in GlossaryTag . Please contact your Admin.`,
                        { variant: "error" }
                      );
                    else handleDeleteModalClickOpen();
                  }}
                >
                  <img
                    src="/images/governance/delete.svg"
                    alt="delete"
                    style={{ filter: "grayscale(100)" }}
                  />
                </span>
              </div>
            </Tooltip>

            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleGlossaryDelete}
              />
            )}
          </div>
        ) : (
          <div className={styles.actionIcons}>
            <div
              className={styles.actions}
              onClick={() =>
                history.push(`${restProps.column.route}/${restProps.row._id}`)
              }
            >
              <span>
                <img src="/images/teams/edit.svg" alt="edit" />
              </span>
            </div>
            <div className={styles.actions}>
              <span onClick={handleLockModalClickOpen}>
                <img src="/images/teams/lock.svg" alt="lock" />
              </span>
              {lockModalOpen && (
                <LockUnlockModal
                  row={restProps.row}
                  open={lockModalOpen}
                  handleClose={handleLockModalClose}
                />
              )}
            </div>
            <div className={styles.actions}>
              <span onClick={handleBlockModalClickOpen}>
                <img src="/images/teams/block.svg" alt="block" />
              </span>
              {blockModalOpen && (
                <BlockModal
                  row={restProps.row}
                  open={blockModalOpen}
                  handleClose={handleBlockModalClose}
                />
              )}
            </div>
          </div>
        )}
      </VirtualTable.Cell>
    </>
  );
};
const TeamActionIconsCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { checkCompPermission } = useCheckPermission();

  const handleLockModalClickOpen = () => {
    if (checkCompPermission("Team", ["Lock Or Unlock"])) {
      setLockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to lock or unlock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleLockModalClose = () => {
    setLockModalOpen(false);
  };
  //  Lock
  const handlelock = async () => {
    const lockData = {};
    const { _msg, _status, data } = await LockTeam(restProps.row._id, lockData);
    if (_status === 423 || _status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setLockModalOpen(false);
      dispatch(lock_unlock_team(restProps.row._id, "true"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setLockModalOpen(false);
    }
  };

  const handleUnlock = async () => {
    const lockData = {};
    const { _msg, _status, data } = await UnLockTeam(
      restProps.row._id,
      lockData
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setLockModalOpen(false);
      dispatch(lock_unlock_team(restProps.row._id, "false"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setLockModalOpen(false);
    }
  };

  // BLOCK

  const handleBlockModalClickOpen = () => {
    if (checkCompPermission("Team", ["Block Or Unblock"])) {
      setBlockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to block or unblock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };
  const handleBlock = async () => {
    const blockdata = {};
    const { _msg, _status, data } = await BLockTeam(
      restProps.row._id,
      blockdata
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_team(restProps.row._id, "true"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };

  const handleUnBlock = async () => {
    const blockdata = {};
    const { _msg, _status, data } = await UnBLockTeam(
      restProps.row._id,
      blockdata
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_team(restProps.row._id, "false"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Team", ["Edit"])) {
      if (
        restProps.row.lock_status === "true" ||
        restProps.row.block_status === "true"
      ) {
        enqueueSnackbar(
          "Cannot edit a Blocked or Locked Team. Kindly unblock or unlock first.",
          {
            variant: "error",
          }
        );
      } else {
        history.push(`${restProps.column.route}/${restProps.row._id}`);
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span style={{ marginTop: 5, marginRight: 10 }}>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        {/* LOCK */}
        <div style={{ padding: "4px" }}>
          {restProps.row.lock_status === "false" && (
            <Tooltip title="Lock" arrow>
              <div>
                <span onClick={handleLockModalClickOpen}>
                  <img src="/images/lock.svg" alt="" />
                </span>
                {lockModalOpen && (
                  <LockModal
                    row={restProps.row}
                    open={lockModalOpen}
                    handleClose={handleLockModalClose}
                    handlelock={handlelock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.lock_status === "true" && (
            <Tooltip title="Unlock" arrow>
              <div>
                <span onClick={handleLockModalClickOpen}>
                  <img src="/images/teams/lock.svg" alt="" />
                </span>
                {lockModalOpen && (
                  <UnlockModal
                    row={restProps.row}
                    open={lockModalOpen}
                    handleClose={handleLockModalClose}
                    handleUnlock={handleUnlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* BLOCK */}
        <div style={{ padding: "4px" }}>
          {restProps.row.block_status === "false" && (
            <Tooltip title="Block" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="block" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.block_status === "true" && (
            <Tooltip title="Unblock" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="block" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

// USERS
const UserNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() =>
          history.push(`${restProps.column.route}/${restProps.row._id}`)
        }
      >
        {/* <div>
          <img
            src="/images/user.png"
            alt="user"
            style={{ height: 27, width: 27 }}
          />
          <span
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#A333C8",
            }}
          ></span>
        </div> */}
        <div style={{ paddingLeft: 10 }}>
          <text className={styles.application_name}>{restProps.row.name}</text>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

const TeamsCell = ({ ...restProps }) => {
  const [userteamDetails, setUserteamDetails] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getUserTeamDetails(
        restProps.row._id
      );
      if (_status === 200) {
        setUserteamDetails(() => (data ? data : []));
      } else {
      }
    };
    fetchData();
  }, [restProps.row._id]);
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {userteamDetails &&
          userteamDetails?.length > 0 &&
          userteamDetails?.map((item) => {
            return (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.type === "external" && (
                  <div
                    style={{
                      height: 14,
                      width: 14,
                      backgroundColor: "#00B5AD",
                      borderRadius: 3,
                    }}
                  ></div>
                )}
                {item.type === "internal" && (
                  <div
                    style={{
                      height: 14,
                      width: 14,
                      backgroundColor: "#A333C8",
                      borderRadius: 3,
                    }}
                  ></div>
                )}
                <div style={{ padding: "0 5px" }}>{item.name}</div>
              </span>
            );
          })}
      </div>
    </VirtualTable.Cell>
  );
};

// APPLICATION GOVERNANCE
const IconCell = ({ ...restProps }) => {
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {restProps.row.icon && (
          <div>
            <Avatar
              src={restProps.row.icon}
              alt="icon"
              className={styles.small}
            />
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const BuiltEnvironment = ({ ...restProps }) => {
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps && (
        <div>
          <span className={styles.application_name}>
            {restProps.row.dockerdetails.imageid}
          </span>
        </div>
      )}
    </VirtualTable.Cell>
  );
};
// launguage
const LanguageStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
      // onClick={() => histroy.push(`/launguage-details/${restProps.row._id}`)}
      >
        {restProps.row.status === "active" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: " center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
      <div>
        {restProps.row.status === "inactive" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: " center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const LaunguageActions = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { checkCompPermission } = useCheckPermission();

  //Dependencies Modal
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [dependencyData, setDependencyData] = useState([]);

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    const { _msg, _status, data } = await fetchLanguageDependencyByID(
      restProps?.row?._id
    );
    if (_status === 200) {
      const projectsData =
        data !== null
          ? data?.map((d) => {
              return {
                title: d.name,
                expanded: false,
              };
            })
          : [];
      const tempData = [
        {
          title: projectsData?.length === 0 ? "No Projects found" : "Projects",
          expanded: true,
          children: [...projectsData],
        },
      ];

      setDependencyData(tempData);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    if (checkCompPermission("Language", ["Block Or Unblock"])) {
      setBlockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to block or unblock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async (props) => {
    const blockData = {};
    const { _msg, _status, data } = await BlockLanguage(
      restProps?.row?._id,
      blockData
    );
    if (_status === 201) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_language(restProps?.row?._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  const handleUnBlock = async () => {
    const blockData = {};
    const { _msg, _status, data } = await UnBlockLanguage(
      restProps?.row?._id,
      blockData
    );
    if (_status === 201) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_language(restProps?.row?._id, "active"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("Language", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (restProps?.row?.status === "inactive") {
      setDeleteModalOpen(false);
      enqueueSnackbar(
        "Cannot delete a blocked language. Kindly unblock first",
        {
          variant: "error",
        }
      );
    } else {
      const { _msg, _status } = await DeleteLanguage(restProps?.row?._id);
      if (_status === 200) {
        enqueueSnackbar("Language deleted successfully", {
          variant: "success",
        });
        setDeleteModalOpen(false);
        dispatch(delete_language(restProps?.row?._id));
      } else {
        enqueueSnackbar(
          _msg ? _msg : "Language cannot be deleted as it is in use",
          {
            variant: "error",
          }
        );
        setDeleteModalOpen(false);
      }
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Language", ["Edit"])) {
      if (restProps?.row?.status === "inactive") {
        enqueueSnackbar(
          "Cannot edit a Blocked Language. Kindly unblock first.",
          {
            variant: "error",
          }
        );
      } else {
        history.push(`${restProps?.column?.route}/${restProps?.row?._id}`);
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actionIcon} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        {/* Block */}
        <div className={styles.actions}>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div className={styles.actionIcon}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div className={styles.actionIcon}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actionIcon}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
          </div>
        </Tooltip>
        {deleteModalOpen && (
          <DeleteModal
            name={restProps.row.name}
            row={restProps.row}
            open={deleteModalOpen}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        )}

        {/* Dependencies */}
        <Tooltip title="Dependencies" arrow>
          <div className={styles.actionIcon}>
            <span onClick={handleDependencyModalClickOpen}>
              <img src="/images/info_icon.svg" alt="dependencies" />
            </span>
          </div>
        </Tooltip>
        {dependencyModalOpen && (
          <DependencyModal
            // name={restProps.row.name}
            modalTitle={
              dependencyData.length !== 0 ? "Dependencies" : "No Dependencies"
            }
            treeDataArr={dependencyData}
            row={restProps.row}
            open={dependencyModalOpen}
            handleClose={handleDependencyModalClose}
            // handleDelete={handleDelete}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};
const BoilerplateActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const { checkCompPermission } = useCheckPermission();

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("Boilerplate", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteBoilerPlates(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_boilerplate(restProps.row._id));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setDeleteModalOpen(false);
    }
  };

  const handleDownload = async () => {
    if (checkCompPermission("Boilerplate", ["Download"])) {
      setIsLoader(true);
      let response = await fetch(
        `${BASE_URL}/api/repo/repositories/boilerplates/${restProps.row._id}/_download`,
        {
          headers: {
            organization: "60ba3575e1bc3c06e220ab5a",
            token: userData?.accesstoken,
          },
        }
      );
      if (response.status === 200) {
        setIsLoader(false);
        setIsDownload(true);
        let blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${restProps.row.filename}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownload(false);
      } else {
        setIsLoader(false);
        setIsDownload(false);
        enqueueSnackbar("Unable to download, please try after sometime...", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to download. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Boilerplate", ["Edit"])) {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actions}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
          </div>
        </Tooltip>
      </div>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.BoilerplateName}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </VirtualTable.Cell>
  );
};
const ChassisTemplateActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const { checkCompPermission } = useCheckPermission();

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("Template", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteChassisTemplate(
      restProps.row._id
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_chassisTemplate(restProps.row._id));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setDeleteModalOpen(false);
    }
  };

  const handleDownload = async () => {
    if (checkCompPermission("Template", ["Download"])) {
      setIsLoader(true);
      let response = await fetch(
        `${BASE_URL}/api/repo/repositories/templates/${restProps.row._id}/_download`,
        {
          headers: {
            organization: "60ba3575e1bc3c06e220ab5a",
            token: userData?.accesstoken,
          },
        }
      );
      if (response.status === 200) {
        setIsLoader(false);
        setIsDownload(true);
        let blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${restProps.row.filename}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownload(false);
      } else {
        setIsLoader(false);
        setIsDownload(false);
        enqueueSnackbar("Unable to download, please try after sometime...", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to download. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Template", ["Edit"])) {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actions}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
          </div>
        </Tooltip>
      </div>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.ChassisTemplate}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </VirtualTable.Cell>
  );
};

const DockerimageActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const { checkCompPermission } = useCheckPermission();

  const handleDownload = async () => {
    if (checkCompPermission("DockerImage", ["Download"])) {
      if (restProps.row.buildtype === "internal") {
        enqueueSnackbar("Cannot Download System Images", { variant: "error" });
      } else {
        setIsLoader(true);
        let response = await fetch(
          `${BASE_URL}/api/container/dockerimages/${restProps.row._id}/_download`,
          {
            headers: {
              organization: "60ba3575e1bc3c06e220ab5a",
              token: userData?.accesstoken,
            },
          }
        );
        if (response.status === 200) {
          setIsLoader(false);
          setIsDownload(true);
          enqueueSnackbar("Docker file is downloading, please wait...", {
            variant: "success",
          });
          let blob = await response.blob();
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${restProps.row.imagename}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setIsDownload(false);
          enqueueSnackbar("Docker file is download successfully", {
            variant: "success",
          });
        } else {
          setIsLoader(false);
          setIsDownload(false);
          enqueueSnackbar("Unable to download, please try after sometime...", {
            variant: "error",
          });
        }
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to download. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [dependencyData, setDependencyData] = useState([]);

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("DockerImage", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteDockerImage(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_dockerImage(restProps.row._id));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("DockerImage", ["Edit"])) {
      history.push(`edit-image/${restProps.row._id}`);
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    const { _msg, _status, data } = await getDockerImageById(
      restProps?.row?._id
    );
    const Lang = data?.["Languages"];
    const ServiceBundles = data?.["ServiceBundles"];
    const UIApplications = data?.["UIApplications"];
    if (_status === 200) {
      const language =
        Lang !== null
          ? Lang?.map((item) => {
              return {
                title: item.name,
                expanded: true,
              };
            })
          : [];

      const serviceBundle =
        ServiceBundles !== null
          ? ServiceBundles?.map((item) => {
              return {
                title: item.name,
                expanded: true,
              };
            })
          : [];

      const uiApplications =
        UIApplications !== null
          ? UIApplications?.map((item) => {
              return {
                title: item.name,
                expanded: true,
              };
            })
          : [];

      const tempData = [
        {
          title: language?.length === 0 ? "No Languages found" : "Languages",
          expanded: true,
          children: [...language],
        },
        {
          title:
            serviceBundle?.length === 0
              ? "No Service Bundles Found"
              : "Service Bundles",
          expanded: true,
          children: [...serviceBundle],
        },
        {
          title:
            uiApplications?.length === 0
              ? "No UI Application found"
              : "UIApplications",
          expanded: true,
          children: [...uiApplications],
        },
      ];

      setDependencyData(tempData);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };

  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.actionIcons}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actionIcon}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div className={styles.actionIcon} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div className={styles.actionIcon}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Dependencies" arrow>
          <div className={styles.actionIcon}>
            <span onClick={handleDependencyModalClickOpen}>
              <img src="/images/info_icon.svg" alt="dependencies" />
            </span>
          </div>
        </Tooltip>
        {dependencyModalOpen && (
          <DependencyModal
            // name={restProps.row.name}
            modalTitle={
              dependencyData.length !== 0 ? "Dependencies" : "No Dependencies"
            }
            treeDataArr={dependencyData}
            row={restProps.row}
            open={dependencyModalOpen}
            handleClose={handleDependencyModalClose}
            // handleDelete={handleDelete}
          />
        )}
      </div>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.imagename}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </VirtualTable.Cell>
  );
};

const ContainerStatusCell = ({ ...restProps }) => {
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps.row.state === "Created" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#fbbd08",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#fbbd08", paddingLeft: 5 }}>
              {restProps.row.state}
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "Running" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              <span>Started</span>
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "Exited" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              <span>Stopped</span>
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "Creating" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#f58d42",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#f58d42", paddingLeft: 5 }}>
              <span>Creating</span>
            </span>
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const ContainersActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isLoader, setisLoader] = useState(false);
  const { checkCompPermission } = useCheckPermission();

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("Container", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteContainer(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_container(restProps.row._id));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setDeleteModalOpen(false);
    }
  };

  // Start or Stop

  const handleStartContainer = async () => {
    if (checkCompPermission("Container", ["Start"])) {
      setisLoader(true);
      const { _msg, _status, data } = await StartContainer(restProps.row._id);
      if (_status === 200) {
        setisLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(start_or_stop_container(restProps.row._id, "running"));
      } else {
        setisLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to start container. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleStopContainer = async () => {
    if (checkCompPermission("Container", ["Stop"])) {
      setisLoader(true);
      const { _msg, _status, data } = await StopContainer(
        restProps.row._id,
        "exited"
      );
      if (_status === 200) {
        setisLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(start_or_stop_container(restProps.row._id, "exited"));
      } else {
        setisLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to stop container. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Container", ["Edit"])) {
      history.push(`edit-container/${restProps.row._id}`);
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
          </div>
        </Tooltip>
        {deleteModalOpen && (
          <DeleteModal
            name={restProps.row.containername}
            row={restProps.row}
            open={deleteModalOpen}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        )}
        {restProps.row.state === "Created" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Create failed" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Exited" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Running" && (
          <Tooltip title="Stop" arrow>
            <div className={styles.actions} onClick={handleStopContainer}>
              <span>
                <img src="/images/governance/stop.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Creating" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} style={{ cursor: "not-allowed" }}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const RuntimeEnvStatusCell = ({ ...restProps }) => {
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps.row.state === "Created" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#fbbd08",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#fbbd08", paddingLeft: 5 }}>
              {restProps.row.state.charAt(0).toUpperCase() +
                restProps.row.state.slice(1)}
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "Up" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              <span>Started</span>
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "Down" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              <span>Stopped</span>
            </span>
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const RuntimeEnvActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isLoader, setisLoader] = useState(false);
  const { checkCompPermission } = useCheckPermission();

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("RuntimeEnvironment", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteRuntimeEnv(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_runtimeEnv(restProps.row._id));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };
  const handleStartRuntimeEnv = async () => {
    if (checkCompPermission("RuntimeEnvironment", ["Start"])) {
      setisLoader(true);
      const { _msg, _status, data } = await StartRuntimeEnv(restProps.row._id);
      if (_status === 200) {
        setisLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(start_or_stop_runtimeEnv(restProps.row._id, data?.state));
      } else {
        setisLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to start environment variable. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleStopRuntimeEnv = async () => {
    if (checkCompPermission("RuntimeEnvironment", ["Stop"])) {
      setisLoader(true);
      const { _msg, _status, data } = await StopRuntimeEnv(restProps.row._id);
      if (_status === 200) {
        setisLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(start_or_stop_runtimeEnv(restProps.row._id, data?.state));
      } else {
        setisLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to stop environment variable. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("RuntimeEnvironment", ["Edit"])) {
      history.push(`edit-environment/${restProps.row._id}`);
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
          </div>
        </Tooltip>
        {restProps.row.state === "Created" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartRuntimeEnv}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Down" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartRuntimeEnv}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Execute failed" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartRuntimeEnv}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Up" && (
          <Tooltip title="Stop" arrow>
            <div className={styles.actions} onClick={handleStopRuntimeEnv}>
              <span>
                <img src="/images/governance/stop.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.name}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </VirtualTable.Cell>
  );
};
const UserActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { checkCompPermission } = useCheckPermission();
  const { userInfo } = useSelector((state) => state.userDetails);

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    if (checkCompPermission("User", ["Delete"])) {
      setDeleteModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to delete. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    if (userInfo._id === restProps.row._id) {
      enqueueSnackbar("You cannot delete currently logged in user", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    } else {
      const { _msg, _status, data } = await deleteUser(restProps.row._id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDeleteModalOpen(false);
        dispatch(delete_user(restProps.row._id));
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
        setDeleteModalOpen(false);
      }
    }
  };

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    if (checkCompPermission("User", ["Block Or Unblock"])) {
      setBlockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to block or unblock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async () => {
    const blockData = {};
    const { _msg, _status, data } = await blockUser(
      restProps.row._id,
      blockData
    );
    if (_status < 210) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_user(restProps.row._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  const handleUnBlock = async () => {
    const blockData = {};
    const { _msg, _status, data } = await unBlockUser(
      restProps.row._id,
      blockData
    );
    if (_status < 210) {
      setBlockModalOpen(false);
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      dispatch(block_unblock_user(restProps.row._id, "active"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("User", ["Edit"])) {
      if (restProps.row.status === "inactive") {
        enqueueSnackbar("Cannot edit a Blocked User. Kindly unblock first.", {
          variant: "error",
        });
      } else {
        history.push(`${restProps.column.route}/${restProps.row._id}`);
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Block */}

        <div>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}

          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
          </div>
        </Tooltip>
      </div>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.name}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </VirtualTable.Cell>
  );
};
const TeamActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await deleteUser(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      window.location.replace("/teams");
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async () => {
    const { _msg, _status, data } = await blockUser(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  const handleUnBlock = async () => {
    const { _msg, _status, data } = await unBlockUser(restProps.row._id);
    if (_status === 200) {
      setBlockModalOpen(false);

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        {/* edit */}
        <div
          className={styles.actions}
          onClick={() => history.push(`edit-user/${restProps.row._id}`)}
        >
          <span>
            <img src="/images/governance/edit.svg" alt="edit" />
          </span>
        </div>

        {/* Block */}
        {restProps.row.status === "active" && (
          <div
            className={`${styles.actions} ${
              restProps.row.status === "inactive" && styles.showblock
            } `}
          >
            <span onClick={handleBlockModalClickOpen}>
              <img src="/images/teams/block.svg" alt="lock" />
            </span>
            {blockModalOpen && (
              <BlockModal
                row={restProps.row}
                open={blockModalOpen}
                handleClose={handleBlockModalClose}
                handleBlock={handleBlock}
              />
            )}
          </div>
        )}

        {restProps.row.status === "inactive" && (
          <div className={styles.actions}>
            <span onClick={handleBlockModalClickOpen}>
              <img src="/images/teams/block.svg" alt="lock" />
            </span>
            {blockModalOpen && (
              <UnBlockModal
                row={restProps.row}
                open={blockModalOpen}
                handleClose={handleBlockModalClose}
                handleBlock={handleUnBlock}
              />
            )}
          </div>
        )}
        {/* Delete */}
        <div className={styles.actions}>
          <span onClick={handleDeleteModalClickOpen}>
            <img src="/images/governance/delete.svg" alt="lock" />
          </span>
          {deleteModalOpen && (
            <DeleteModal
              name={restProps.row.name}
              row={restProps.row}
              open={deleteModalOpen}
              handleClose={handleDeleteModalClose}
              handleDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

const VendorStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps.row.status === "active" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        </div>
      )}
      {restProps.row.status === "inactive" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const VendorProjectModal = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actions} style={{ paddingLeft: 10 }}>
        <span onClick={handleModalClickOpen}>
          <img src="/images/application_list.svg" alt="icon" />
        </span>
        {ModalOpen && (
          <VendorProject
            id={restProps.row.userid}
            open={ModalOpen}
            handleClose={handleModalClose}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const VendorActions = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { checkCompPermission } = useCheckPermission();
  const userData = useSelector((state) => state.authReducer);

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleAddFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    const favoriteData = {
      resourcetype: resourceType,
      resourceinstance: resourceinstance,
    };
    const { _msg, _status, data } = await addFavouriteInResource(
      userData.userid,
      favoriteData
    );

    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.name === "vendorActions") {
        dispatch(alterFavouriteVendorById(resourceinstance));
      }
      dispatch(addSingleFavourite(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDeleteFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    const { _msg, _status, data } = await deleteFavouriteInResource(
      userData.userid,
      resourceType,
      resourceinstance
    );

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.name === "vendorActions") {
        dispatch(alterFavouriteVendorById(resourceinstance));
      }
      dispatch(removeSingleFavourite(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };
  const handleBlockModalClickOpen = () => {
    if (checkCompPermission("Vendor", ["Create"])) {
      setBlockModalOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to block Or unblock. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async (props) => {
    const blockData = {};
    const { _msg, _status, data } = await blockVendor(
      restProps.row._id,
      blockData
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_vendor(restProps.row._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  const handleUnBlock = async () => {
    const blockData = {};
    const { _msg, _status, data } = await UnblockVendor(
      restProps.row._id,
      blockData
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_vendor(restProps.row._id, "active"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (checkCompPermission("Vendor", ["Edit"])) {
      if (restProps.row.status === "inactive") {
        enqueueSnackbar("Cannot edit a Blocked Vendor. Kindly unblock first.", {
          variant: "error",
        });
      } else {
        history.push(`${restProps.column.route}/${restProps.row._id}`);
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to edit. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        className={styles.launguageactionIcons}
        style={{
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip
          title={restProps.row?.isFavourite ? "Unfavourite" : "Favourite"}
          arrow
        >
          <div
            className={styles.actions}
            style={{ marginRight: "20px" }}
            onClick={() => {
              if (restProps.row?.isFavourite) {
                if (restProps.column.name === "vendorActions") {
                  handleDeleteFavouite("vendor", restProps.row._id);
                } else {
                  handleDeleteFavouite(restProps.column.for, restProps.row._id);
                }
              } else {
                if (restProps.column.name === "vendorActions") {
                  handleAddFavouite("vendor", restProps.row._id);
                } else {
                  handleAddFavouite(restProps.column.for, restProps.row._id);
                }
              }
            }}
          >
            <span>
              {restProps.row?.isFavourite ? (
                <img src="/images/heartFilled.svg" alt="card" />
              ) : (
                <img src="/images/heartLine.svg" alt="card" />
              )}
            </span>
          </div>
        </Tooltip>

        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={handleEdit}
            style={{ marginRight: "14px" }}
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        <div style={{ padding: "4px" }}>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </VirtualTable.Cell>
  );
};
const OrganizationActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await deleteOrganization(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(active_inactive_org(restProps.row._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (restProps.row.status === "inactive") {
      enqueueSnackbar(
        "Cannot edit a Blocked Organization. Kindly unblock first.",
        {
          variant: "error",
        }
      );
    } else {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.launguageactionIcons}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          {/* Delete */}
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

const OrgStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{ padding: "4px" }}
        onClick={() =>
          histroy.push(`/organization-deatils/${restProps.row._id}`)
        }
      >
        {restProps.row.status === "active" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
      <div
        style={{ padding: "4px" }}
        onClick={() =>
          histroy.push(`/organization-deatils/${restProps.row._id}`)
        }
      >
        {restProps.row.status === "inactive" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const NoDataFound = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        textAlign: "center",
        top: "calc(50% - 4rem)",
        left: "calc(50% - 4rem)",
        maxWidth: "11rem",
      }}
    >
      <img src="/images/notFound.svg" alt="notfound" />
      <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
    </div>
  );
};

const UpdateByCell = ({ ...restProps }) => {
  return restProps.row.updatedby ? <>{restProps.row.updatedby}</> : <></>;
};

const UpdatedOnCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state.userDetails);
  return (
    <>
      {restProps.row?.updatedon ? (
        <>
          {userInfo?.timeZone && restProps.row?.updatedon
            ? moment
                .unix(restProps.row?.updatedon)
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row?.updatedon)
                .format("YYYY-DD-MM, hh:mm A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CreatedOnCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state.userDetails);
  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("YYYY-DD-MM, hh:mm A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CreatedOn = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state.userDetails);
  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("YYYY-DD-MM, hh:mm A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CreatedBy = ({ ...restProps }) => {
  // const { userInfo } = useSelector((state) => state.userDetails);
  return (
    <>{restProps.row.createdby ? <>{restProps.row.createdby}</> : <></>}</>
  );
};

const LanguageNameCell = ({ ...restProps }) => {
  return <>{restProps.row.language}</>;
};

const Cell = (props) => {
  const search = useLocation().search;
  const serviceCheck = new URLSearchParams(search).get("service");
  const dataStandardCheck = new URLSearchParams(search).get("datastandards");
  const [openModal, setOpenModal] = useState(false);
  const { column } = props;
  const histroy = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();
  var handleNavigateToDetails = () => {
    // Application Governance
    if (
      column.for === "language" &&
      checkCompPermission("Language", ["Read"])
    ) {
      histroy.push(`/language-details/${props.row._id}`);
    } else if (
      column.for === "boilerplate" &&
      checkCompPermission("Boilerplate", ["Read"])
    ) {
      histroy.push(`/boilerplate-details/${props.row._id}`);
    } else if (
      column.for === "chassistemplate" &&
      checkCompPermission("Template", ["Read"])
    ) {
      histroy.push(`/chassistemplate-details/${props.row._id}`);
    }
    // Container management
    else if (
      column.for === "dockerImage" &&
      checkCompPermission("DockerImage", ["Read"])
    ) {
      histroy.push(`/image-details/${props.row._id}`);
    } else if (
      column.for === "container" &&
      checkCompPermission("Container", ["Read"])
    ) {
      histroy.push(`/container-details/${props.row._id}`);
    } else if (
      column.for === "environmentvaribales" &&
      checkCompPermission("RuntimeEnvironment", ["Read"])
    ) {
      histroy.push(`/environment-details/${props.row._id}`);
    }
    // Container management
    else if (
      column.for === "vendor" &&
      checkCompPermission("Vendor", ["Read"])
    ) {
      histroy.push(`/vendor-details/${props.row._id}`);
    } else if (column.for === "user" && checkCompPermission("User", ["Read"])) {
      histroy.push(`/user-details/${props.row._id}`);
    } else if (
      column.for === "teams" &&
      checkCompPermission("Team", ["Read"])
    ) {
      histroy.push(`/team-details/${props.row._id}`);
    } else if (
      column.for === "field" &&
      checkCompPermission("GlossaryField", ["Read"])
    ) {
      histroy.push(`/details-field/${props.row._id}`);
    } else if (
      column.for === "fieldgroup" &&
      checkCompPermission("GlossaryFieldGroup", ["Read"])
    ) {
      if (props.row.status === "inactive") {
        if (serviceCheck) {
          histroy.push(`/fieldgroups/details/${props.row._id}?service=true`);
        } else if (dataStandardCheck) {
          histroy.push(
            `/fieldgroups/details/${props.row._id}?datastandards=true`
          );
        } else {
          histroy.push(`/fieldgroups/details/${props.row._id}`);
        }
        enqueueSnackbar("Cannot edit a Blocked payload. Kindly unblock first", {
          variant: "error",
        });
      } else {
        if (serviceCheck) {
          histroy.push(`/fieldgroups/details/${props.row._id}?service=true`);
        } else if (dataStandardCheck) {
          histroy.push(
            `/fieldgroups/details/${props.row._id}?datastandards=true`
          );
        } else {
          histroy.push(`/fieldgroups/details/${props.row._id}`);
        }
      }
    } else if (
      column.for === "glossary" &&
      checkCompPermission("GlossaryTag", ["Read"])
    ) {
      histroy.push(`glossary-details/${props.row._id}`);
    } else {
      enqueueSnackbar(
        `You do not have permission to access Read. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };
  if (props.column.for === "language") {
    if (column.name === "builtenvironment") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <BuiltEnvironment {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "status") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <LanguageStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "chassistemplate") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "boilerplate") {
    if (props.column.name === "languagename") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <LanguageNameCell {...props} />
        </VirtualTable.Cell>
      );
    }

    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "dockerImage") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "container") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "containerState") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <ContainerStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "environmentvaribales") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "updatedby") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <UpdateByCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "EnvVariableState") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <RuntimeEnvStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "teams") {
    if (column.name === "name") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <TeamNameCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "teamMember") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <TeamMemberCell {...props} />
        </VirtualTable.Cell>
      );
    }
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
  } else if (props.column.for === "vendor") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <VendorStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "vendorProject") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <VendorProjectModal {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
    // USERS
  } else if (props.column.for === "user") {
    if (column.name === "username") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <UserNameCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "userteams") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <TeamsCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "projectAssigned") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <UserProjectAssignModalCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <CreatedOn {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "updatedon") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <UpdatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "organization") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        >
          <OrgStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        />
      );
    }
  } else if (column.name === "name2") {
    return <DocumentNameCell {...props} />;
  } else if (column.name === "actions") {
    return <ActionIconsCell {...props} />;
  } else if (column.name === "teamActions") {
    return <TeamActionIconsCell {...props} />;
  } else if (column.name === "teams") {
    return <TeamsCell {...props} />;
  } else if (column.name === "edit") {
    return <EditAction {...props} />;
  } else if (column.name === "languageactions") {
    return <LaunguageActions {...props} />;
  } else if (column.name === "boilerplateactions") {
    return <BoilerplateActions {...props} />;
  } else if (column.name === "chassisactions") {
    return <ChassisTemplateActions {...props} />;
  } else if (column.name === "icons") {
    return <IconCell {...props} />;
  } else if (column.name === "dockerimageaction") {
    return <DockerimageActions {...props} />;
  } else if (column.name === "containeractions") {
    return <ContainersActions {...props} />;
  } else if (column.name === "runtimeenvactions") {
    return <RuntimeEnvActions {...props} />;
  } else if (column.name === "userActions") {
    return <UserActions {...props} />;
  } else if (column.name === "vendorActions") {
    return <VendorActions {...props} />;
  } else if (column.name === "organizationAction") {
    return <OrganizationActions {...props} />;
  } else if (props.column.for === "fieldgroup") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "field" || props.column.for === "project") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={handleNavigateToDetails}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (props.column.for === "glossary") {
    if (column.name === "created By") {
      return (
        <>
          <VirtualTable.Cell onClick={handleNavigateToDetails} {...props}>
            <CreatedBy {...props} />
          </VirtualTable.Cell>
        </>
      );
    }
    if (column.name === "createdon" || column.name === "createdon") {
      return (
        <>
          <VirtualTable.Cell onClick={handleNavigateToDetails} {...props}>
            <CreatedOnCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell onClick={handleNavigateToDetails} {...props} />
          <GlossaryDetailModal
            row={props.row}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </>
      );
    }
  }

  return <VirtualTable.Cell {...props} />;
};

const ApplicationListTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 5) {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
    // }
  };
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <div className={styles.myDevTable} ref={devExpressTable}>
        <Grid rows={props.rows} columns={props.columns} hidden={props.hidden}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 280
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg="No Data" />}
          />

          <TableColumnResizing
            // defaultColumnWidths={true}
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />
          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "dockerimageaction", togglingEnabled: false },
                { columnName: "runtimeenvactions", togglingEnabled: false },
                { columnName: "containeractions", togglingEnabled: false },
                { columnName: "userActions", togglingEnabled: false },
                { columnName: "teamActions", togglingEnabled: false },
                { columnName: "vendorActions", togglingEnabled: false },
                { columnName: "organizationAction", togglingEnabled: false },
                { columnName: "languageactions", togglingEnabled: false },
                { columnName: "boilerplateactions", togglingEnabled: false },
                { columnName: "chassisactions", togglingEnabled: false },
                { columnName: "microservicename", togglingEnabled: false },
                { columnName: "versionTag", togglingEnabled: false },
                { columnName: "actions", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default ApplicationListTable;
