import {
  ADD_DOCKER_IMAGE_DATA,
  DOCKER_IMAGE_FILTERS,
  DELETE_DOCKER_IMAGE,
  DOCKER_IMAGE_SORT,
  DOCKER_IMAGE_HIDDEN_COULMNS,
  CLEAR_DOCKER_IMAGE_DATA,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  dockerImage_list: {
    data: [],
    error: false,
    loading: false,
  },
  dockerImageFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  dockerImageSort: [{ columnName: "createdon", direction: "desc" }],
  dockerHiddenCols: [
    "imagename",
    "state",
    "imagetag",
    "createdon",
    "dockerimageaction",
  ],
};

export default function dockerImageReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DOCKER_IMAGE_DATA: {
      return {
        ...state,
        dockerImage_list: {
          ...state.dockerImage_list,
          ...action.payload,
          deleteDockerImageStatus: false,
        },
      };
    }
    case DOCKER_IMAGE_FILTERS: {
      return {
        ...state,
        dockerImageFilters: [...action.payload],
      };
    }
    case DOCKER_IMAGE_SORT: {
      return {
        ...state,
        dockerImageSort: [...action.payload],
      };
    }
    case DELETE_DOCKER_IMAGE: {
      return {
        ...state,
        dockerImage_list: {
          ...state.dockerImage_list,
          data:
            state.dockerImage_list.data &&
            state.dockerImage_list.data.filter(
              (dock) => dock._id !== action.payload.dockerID
            ),
          _totalcount: state.dockerImage_list._totalcount - 1,
          deleteDockerImageStatus: true,
        },
      };
    }
    case DOCKER_IMAGE_HIDDEN_COULMNS: {
      return {
        ...state,
        dockerHiddenCols: [...action.payload],
      };
    }
    case CLEAR_DOCKER_IMAGE_DATA: {
      return {
        ...state,
        dockerImage_list: {},
      };
    }
    default:
      return state;
  }
}
