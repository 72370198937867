import React, { useState } from "react";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import useGetData from "../../../../hooks/useGetData";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { editUiApplication } from "../../../../../redux/actions/uiApplicationAction";
import { MenuItem, Select } from "@material-ui/core";
import { useDispatch } from "react-redux";

function TextAreaSettings() {
  const {
    currentComponent,
    currentProject,
    globalHandleChange,
    globalAcessModeChange,
    NormalHandleChange,
  } = useGetData();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [accessModeErr, setAccessModeErr] = useState("");
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <JSDataInput
          value={currentComponent?.data}
          onChange={(value) => globalHandleChange(value, "data", setError)}
          label="Value"
          title={`${currentComponent.name} : Data`}
          error={error}
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Label"
          name="label"
          value={currentComponent?.label}
          onChange={(value) =>
            globalAcessModeChange(value, "label", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <FormControlLabel
        label={<span style={{ fontSize: 13 }}>Required field</span>}
        control={
          <Checkbox
            checked={currentComponent?.isRequired}
            onChange={(event) =>
              NormalHandleChange("isRequired", event?.target?.checked)
            }
            size="small"
            color="primary"
          />
        }
      />
      <div className="field">
        <TrueFalseButton
          name="direction"
          leftButton="Horizontal"
          rightButton="Vertical"
          value={!currentComponent?.direction}
        />
      </div>
      <div className="field">
        <TrueFalseButton
          leftButton={
            !currentComponent?.alignment ? (
              <img src="/img/Left.-white.svg" alt="" />
            ) : (
              <img src="/img/Left.svg" alt="" />
            )
          }
          rightButton={
            currentComponent?.alignment ? (
              <img src="/img/Right.svg" alt="" />
            ) : (
              <img src="/img/Right.-Black.svg" alt="" />
            )
          }
          name="alignment"
          value={!currentComponent?.alignment}
        />
      </div>
    </div>
  );
}

export default TextAreaSettings;
