const teamsColumns = [
  { name: "name", title: "Team Name", route: "/team-details", for: "teams" },
  { name: "teamMember", title: "Team Members", for: "teams" },
  { name: "createdby", title: "Created By", for: "teams" },
  { name: "createdon", title: "Created On", for: "teams" },
  { name: "teamActions", title: "Action", route: "/edit-team" },
];

const teamsDefaultColumnWidths = [
  { columnName: "name", weightage: 2, width: 200 },
  { columnName: "teamMember", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "teamActions", weightage: 2, width: 200 },
];

const teamsTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "teamMember", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "teamActions", align: "center", sortingEnabled: false },
];

const teamsColumnOrder = ["name", "teamMember", "createdby", "createdon"];

const teamsDefaultSorting = [{ columnName: "name", direction: "asc" }];

const teamsColumnHidden = [
  "name",
  "teamMember",
  "createdby",
  "createdon",
  "teamActions",
];

export {
  teamsColumns,
  teamsDefaultColumnWidths,
  teamsTableColumnExtensions,
  teamsColumnOrder,
  teamsDefaultSorting,
  teamsColumnHidden,
};
