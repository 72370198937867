import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import React, { useState, useEffect, useRef } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import DevExpressTable from "../../Tables/DevExpressTable";
import { AppButton } from "../../Button";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DocumentTableWithDropper from "../../Tables/DocumentTableWithDropper";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import LanguageChips from "../../LanguageChip";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import Loader from "../../stuff/Loader";
import useCheckPermission from "../../hooks/useCheckPermission";
import AddVersion from "../../../../screens/Services/Version/AddVersion";
import EditVersion from "../../../../screens/Services/Version/EditVersion";
import AddAPI from "../../../../screens/Services/API/AddAPI";
import EditAPI from "../../../../screens/Services/API/EditAPI";
import { downloadPostManCollection } from "../../../../utils/test/testExecutionUtils";
import { useSnackbar } from "notistack";

const CustomFieldGroupsForServiceEdit = ({
  section,
  isEditMode,
  serviceData,
  setServiceData,
  statuses,
  error,
  handleChange,
  documents,
  languages,
  languageDetails,
  setLanguageDetails,
  fetchDocuments,
  userInfo,
  boilerplates,
  serviceId,
  versions,
  versionsList,
  currentVersionPage,
  setCurrentVersionPage,
  selectedVersion,
  apis,
  apisList,
  currentAPIPage,
  setCurrentAPIPage,
  currentPageServiceDocumentList,
  handleServiceDocumentListPagination,
  service_documents_list,
  versionTableLoading,
  versionTableData,
  versionHidden,
  addVersionHidden,
  versionSort,
  addVersionSort,
  apiTableData,
  apiTableLoading,
  apiSort,
  addAPISort,
  apiHidden,
  addAPIHidden,
  setSelectedVersion,
  apiVersions,
  isFromApplication,
  appBundleId,
  setCreateVersion,
  createVersion,
  createAPI,
  setCreateAPI,
  detailAPI,
  editAPI,
  detailVersion,
  editVersion,
  selectedVersionId,
  tabbedView,
  selectedAPIId,
  setError,
}) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { checkCompPermission } = useCheckPermission();
  const [fromAppBundle, setFromAppBundle] = useState(isFromApplication);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [versionOpen, setVersionOpen] = useState(false);
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const { enqueueSnackbar } = useSnackbar();

  const closeDropDown = () => {
    setLanguageOpen(false);
    setStatusOpen(false);
    setVersionOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const statusSection = () => {
    return (
      <>
        {isEditMode && (
          <>
            <Autocomplete
              open={statusOpen}
              onOpen={() => setStatusOpen(true)}
              onClose={() => setStatusOpen(false)}
              options={statuses}
              getOptionLabel={(option) => option.status}
              className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
                }`}
              onChange={(e, input) => {
                if (input) {
                  setServiceData({
                    ...serviceData,
                    status: input.status,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Search Status"
                  placeholder="Search Status"
                />
              )}
            />
            {error.status?.isError && (
              <p className={styles.errorMsg}>{"Status is Required"}</p>
            )}
          </>
        )}

        {serviceData?.status ? (
          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
            <div
              style={{
                backgroundColor: statuses.find(
                  (s) => s.status === serviceData?.status
                )?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                position: "relative",
                right: 10,
              }}
            ></div>
            <Typography
              variant="h6"
              className={styles.fieldText}
              style={{
                color: statuses.find((s) => s.status === serviceData?.status)
                  ?.color,
              }}
            >
              {statuses
                .find((s) => s.status === serviceData?.status)
                ?.status.charAt(0)
                .toUpperCase() +
                statuses
                  .find((s) => s.status === serviceData?.status)
                  ?.status.slice(1)}
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" className={styles.fieldText}>
            Status is not set
          </Typography>
        )}
      </>
    );
  };
  const handleDeleteLanguage = () => {
    setLanguageDetails({})
    setError({ ...error, languageID: { ...error.languageID, isError: true } })
  }
  const governanceSection = () => {
    return (
      <>
        <Typography variant="h6" className={styles.fieldText}>
          Language <span className={styles.red}>*</span>
        </Typography>

        {isEditMode && (
          <Autocomplete
            open={languageOpen}
            onOpen={() => setLanguageOpen(true)}
            onClose={() => setLanguageOpen(false)}
            options={languages}
            getOptionLabel={(option) => option.name}
            className={`${styles.searchInput2} ${error.languageID?.isError && styles.errorAuto
              }`}
            disabled={!isEditMode}
            onChange={(e, input) => {
              if (input) {
                setError({ ...error, languageID: { ...error.languageID, isError: false } })
                setLanguageDetails(input);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                className={`${!isEditMode && styles.editMode}`}
                placeholder="Search Language"
              />
            )}
          />
        )}
        {error.languageID?.isError && (
          <p className={styles.errorMsg}>{"Language is not selected"}</p>
        )}
        {languageDetails?.name && (
          <LanguageChips
            chipsData={[
              { name: languageDetails?.name, icon: languageDetails?.icon },
            ]}
            isEditMode={!isEditMode}
            handleDelete={handleDeleteLanguage}
          />
        )}
      </>
    );
  };

  const boilerPlateSection = () => {
    return (
      <p className={styles.fieldHeading} style={{ marginTop: 10 }}>
        {serviceData.initializeboilerplateID
          ? `Boiler Plate : ${boilerplates.find(
            (boiler) => boiler._id === serviceData.boilerplateID
          )?.BoilerplateName
          }`
          : "Boiler Plate is not initiated"}
      </p>
    );
  };

  const handleAddVersion = (e) => {
    isFromApplication ? setFromAppBundle(true) : setFromAppBundle(false);
    if (!isTabbedApproach) {
      histroy?.push({
        pathname: `/versions/add/${serviceId}`,
        state: {
          serviceName: serviceData.name,
          serviceType: serviceData.serviceType,
        },
        search: `app_bundle=true&id=${appBundleId}`,
      });
    }
    else {
      setCreateAPI(false);
      setCreateVersion(true);

    }

  };

  const versionSection = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ margin: "10px 0 10px" }}
        >
          {isEditMode && !createVersion && !detailVersion && !editVersion && (
            <AppButton
              color="primary"
              buttonName="Create Version"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={!checkCompPermission("Microservice", ["Create Version"])}
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={
                isTabbedApproach
                  ? handleAddVersion
                  : () => histroy.push({
                    pathname: `/versions/add/${serviceId}`,
                    state: {
                      serviceName: serviceData.name,
                      serviceType: serviceData.serviceType,
                    },
                  })
              }
            />
          )}
        </Grid>



        {checkCompPermission("Version", ["List All", "List Assigned"]) ? (
          <>
            {versionTableLoading ? (
              <Loader />
            ) : createVersion ? (
              <AddVersion
                // executeScroll={executeScroll}
                tabbedView={isTabbedApproach}
                serviceId={serviceId}
                createVersion={createVersion}
                setCreateVersion={setCreateVersion}
                serviceType={serviceData?.serviceType}
                serviceName={serviceData.name}
                fromAppBundle={fromAppBundle}
                setFromAppBundle={setFromAppBundle}
              />
            ) : detailVersion ? (
              <EditVersion
                tabbedView={tabbedView}
                isEditMode={editVersion}
                versionId={selectedVersionId}
                serviceId={serviceId}
              />
            ) : (
              // <div style={{height:"230px"}}>
              <DevExpressTable
                isEditMode={isEditMode}
                columns={versionTableData.columns}
                rows={versions.map((version) => ({
                  ...version,
                  serviceType: serviceData.serviceType,
                }))}
                defaultColumnWidths={versionTableData.widths}
                tableColumnExtensions={versionTableData.extentions}
                columnOrder={versionTableData.orders}
                defaultHiddenColumnNames={versionTableData.hides}
                isVersionData={true}
                loading={versionsList.loading}
                isCustomHeight={false}
                sort={versionSort}
                setSort={addVersionSort}
                hidden={versionHidden}
                setHidden={addVersionHidden}
                noDataMsg="No Version Found"
              />
              // </div>
            )}
            {versions.length !== 0 && !createVersion && !detailVersion && (
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(versionsList?.data?._totalcount / 10)}
                  page={currentVersionPage}
                  onChange={(e, val) => setCurrentVersionPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            )}
          </>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Version List All. Please contact your
            Admin.
          </p>
        )}
      </>
    );
  };

  const handleAddAPI = () => {
    if (isTabbedApproach) {
      setCreateVersion(false);
      setCreateAPI(true);
    } else {
      histroy.push(`/ai-task-api/add/${serviceId}`);
    }
  }

  const apiSection = () => {
    function exportToJson(objectData) {
      let filename = `${serviceData?.name}_${selectedVersion?.versionTag}_collection`;
      let contentType = "application/json;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob(
          [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
          { type: contentType }
        );
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement("a");
        a.download = filename;
        a.href =
          "data:" +
          contentType +
          "," +
          encodeURIComponent(JSON.stringify(objectData));
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }

    const handleDownloadPostmanCollection = async () => {
      const serializerData = {
        Protocol: window.location.protocol.slice(0, -1),
        ServiceType: serviceData.serviceType,
        Host: window.location.host,
        Port: selectedVersion?.port,
        Microservice: serviceData?.name,
        Version: selectedVersion?.versionTag,
        Mid: selectedVersion?.microserviceID,
        Vid: selectedVersion?._id,
      };

      const { res_data } = await downloadPostManCollection(serializerData);

      if (res_data?.status === 200) {
        exportToJson(res_data?.data);
      } else {
        enqueueSnackbar(res_data?._msg, { variant: "error" });
      }
    };

    return (
      <>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ margin: isTabbedApproach ? "40px 0 10px" : "" }}

        >

          {!createAPI && !detailAPI && (
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Version
                  </label>
                  <Autocomplete
                    open={versionOpen}
                    onOpen={() => setVersionOpen(true)}
                    onClose={() => setVersionOpen(false)}
                    options={apiVersions}
                    getOptionLabel={(option) =>
                      option.versionTag ? option.versionTag : ""
                    }
                    value={selectedVersion ? selectedVersion : ""}
                    className={`${styles.searchInput3}`}
                    onChange={(e, input) => {
                      if (input) {
                        const foundVersion = versions.find(
                          (v) => v.id === input._id
                        );
                        setSelectedVersion(foundVersion ? foundVersion : {});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search Version"
                      />
                    )}
                  />
                </div>
                <div>
                  <br />
                  <Tooltip
                    title="Download Postman collection"
                    arrow
                    placement="top"
                  >
                    <IconButton
                      onClick={handleDownloadPostmanCollection}
                      disabled={!selectedVersion || apis.length === 0}
                    >
                      <img
                        src="/images/postman-icon.svg"
                        alt="download postman collection"
                        style={{
                          opacity:
                            !selectedVersion || (apis.length === 0 && 0.5),
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          )}

          {isEditMode && !createAPI && !detailAPI && (
            <AppButton
              color="primary"
              buttonName={`Create API`}
              variant="contained"
              startIcon={<AddIcon />}
              disabled={
                !selectedVersion?._id
                  ? true
                  : false || !checkCompPermission("Version", ["Create API"])
              }
              className="btnsmall"
              onClick={
                isTabbedApproach
                  ? handleAddAPI
                  : () => histroy.push({
                    pathname: `/apis/add/${serviceId}/${selectedVersion.id}`,
                    state: {
                      serviceName: serviceData.name,
                      serviceType: serviceData.serviceType,
                    },
                  })
              }
            />
          )}
        </Grid>

        {checkCompPermission("API", ["List All", "List Assigned"]) ? (
          <>
            {apiTableLoading ? (
              <Loader />
            ) : createAPI ? (
              <AddAPI
                createAPI={createAPI}
                setCreateAPI={setCreateAPI}
                versionId={selectedVersion?._id}
                serviceId={serviceId}
                serviceType={serviceData?.serviceType}
                serviceName={serviceData.name}
                fromAppBundle={fromAppBundle}
                setFromAppBundle={setFromAppBundle}
              />
            ) : detailAPI ? (
              <EditAPI
                fromAppBundle={fromAppBundle}
                setFromAppBundle={setFromAppBundle}
                tabbedView={tabbedView}
                isEditMode={editAPI}
                versionId={selectedVersion?._id}
                apiId={selectedAPIId}
                serviceId={serviceId}
              />
            ) : (
              <DevExpressTable
                isEditMode={isEditMode}
                columns={apiTableData.columns}
                rows={apis}
                defaultColumnWidths={apiTableData.widths}
                tableColumnExtensions={apiTableData.extentions}
                columnOrder={apiTableData.orders}
                defaultHiddenColumnNames={apiTableData.hides}
                loading={false}
                isCustomHeight={false}
                sort={apiSort}
                setSort={addAPISort}
                hidden={apiHidden}
                setHidden={addAPIHidden}
                noDataMsg="No API Found"
              />
            )}
            {apis.length !== 0 && !createAPI && !detailAPI && !editAPI && (
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(apisList?._totalcount / 10)}
                  page={currentAPIPage}
                  onChange={(e, val) => setCurrentAPIPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            )}
          </>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Api List All. Please contact your
            Admin.
          </p>
        )}
      </>
    );
  };

  const documentSection = () => {
    return (
      <>
        {checkCompPermission("Document", ["List All", "List Assigned"]) ? (
          <div style={{ marginTop: 15 }}>
            <DocumentTableWithDropper
              resourceId={serviceId}
              resourceType={"microservice"}
              isEditMode={
                isEditMode &&
                checkCompPermission("Microservice", ["Create Document"])
              }
              rows={documents}
              fetchDocuments={fetchDocuments}
              isDocumentModule={false}
            />

            {documents.length !== 0 && (
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(service_documents_list._totalcount / 10)}
                  page={currentPageServiceDocumentList}
                  onChange={handleServiceDocumentListPagination}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            )}
          </div>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Document List All, List Assigned. Please
            contact your Admin.
          </p>
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Microservice ProjectId") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${styles.editMode}`}
                value={
                  serviceData.projectDetails.length !== 0
                    ? serviceData.projectDetails[0]?.name
                    : ""
                }
                autoComplete="off"
                disabled
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else if (field.name === "Microservice ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${styles.editMode}`}
                value={
                  serviceData.servicegroupdetails.length !== 0
                    ? serviceData.servicegroupdetails[0]?.name
                    : ""
                }
                autoComplete="off"
                disabled
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? userInfo?.timeZone
                      ? moment
                        .unix(serviceData[field.datakey])
                        .tz(userInfo?.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                            : `${userInfo?.dateFormat} ${userInfo?.time}`
                        )
                      : moment
                        .unix(serviceData[field.datakey])
                        .format("DD/MM/YYYY, hh:mm:ss A")
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={serviceData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={!isEditMode || field.editable === "FALSE"}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}

              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Microservice Languageid":
                          return governanceSection();
                        case "Microservice InitializeboilerplateID":
                          return (
                            serviceData.serviceType === "Internal" &&
                            boilerPlateSection()
                          );
                        case "Microservice Status":
                          return statusSection();
                        case "Version Table":
                          return versionSection();
                        case "API Table":
                          return apiSection();
                        case "Microservice Documentation Table":
                          return documentSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Microservice Languageid":
                                return governanceSection();
                              case "Microservice InitializeboilerplateID":
                                return (
                                  serviceData.serviceType === "Internal" &&
                                  boilerPlateSection()
                                );
                              case "Microservice Status":
                                return statusSection();
                              case "Version Table":
                                return versionSection();
                              case "API Table":
                                return apiSection();
                              case "Microservice Documentation Table":
                                return documentSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userInfoReducer;
  const {
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
  } = state.serviceReducer;
  return {
    userInfo,
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
    tabbedView,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForServiceEdit);
