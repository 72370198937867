import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../Button";
import InputTableWithDisable from "../../Tables/InputTableWithDisable";
import { Autocomplete } from "@material-ui/lab";
import LanguageChips from "../../LanguageChip";

const CustomFieldGroupsForAiTaskVersionEdit = ({
  section,
  versionData,
  setVersionData,
  isEditMode,
  runtimeEnvs,
  selectedRuntimeEnv,
  setSelectedRuntimeEnv,
  error,
  handleChange,
  userInfo,
  envs,
  setEnvs,
  deleteRunCommand,
  deleteBuildCommand,
  repositoryData,
  setOpenUploadSourceCodeModal,
  dataModelsList,
}) => {
  const styles = manageStyles();

  const [modelCardData, setModelCardData] = useState([]);

  useEffect(() => {
    if (versionData.datamodels) {
      setModelCardData(versionData.datamodels);
    }
  }, [versionData]);

  const handleDataModelsDelete = (e) => {
    const findDataModel = versionData?.datamodels.filter(
      (item) => item._id !== e._id
    );
    setVersionData({ ...versionData, datamodels: findDataModel });
  };

  const environmentVariablesSection = () => {
    return (
      <>
        <div style={{ margin: "5px 0 5px 0" }}>
          <label className={styles.fieldHeading2}></label>
        </div>
        <InputTableWithDisable
          isEditMode={false}
          envs={envs}
          setEnvs={(env) => setEnvs(env)}
        />
      </>
    );
  };

  const runtimeEnvironmentSection = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={3}>
            <Autocomplete
              options={runtimeEnvs}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              value={selectedRuntimeEnv ? selectedRuntimeEnv : {}}
              disabled={!isEditMode}
              className={`${styles.searchInput3}`}
              onChange={(e, env) => {
                if (env) {
                  setSelectedRuntimeEnv(env);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Search Runtime Environment"
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const dockerCommandSection = () => {
    return (
      <>
        <Grid container spacing={3} style={{ minHeight: "20rem" }}>
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Build Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                disabled={!isEditMode}
                onClick={() =>
                  setVersionData({
                    ...versionData,
                    buildcommands: [...versionData.buildcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {versionData.buildcommands.map((buildcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    key={index}
                    name={`${index}_build`}
                    className={`${styles.input} ${
                      !isEditMode && styles.editMode
                    }`}
                    disabled={!isEditMode}
                    value={versionData.buildcommands[index]}
                    onChange={(e) =>
                      setVersionData({
                        ...versionData,
                        buildcommand: [
                          ...versionData.buildcommands,
                          (versionData.buildcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    disabled={!isEditMode}
                    onClick={() => deleteBuildCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Run Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                disabled={!isEditMode}
                onClick={() =>
                  setVersionData({
                    ...versionData,
                    runcommands: [...versionData.runcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {versionData.runcommands.map((runcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    name={`${index}_run`}
                    className={`${styles.input} ${
                      !isEditMode && styles.editMode
                    }`}
                    disabled={!isEditMode}
                    value={versionData.runcommands[index]}
                    onChange={(e) =>
                      setVersionData({
                        ...versionData,
                        runcommand: [
                          ...versionData.runcommands,
                          (versionData.runcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    disabled={!isEditMode}
                    onClick={() => deleteRunCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };

  const sourceCodeSection = () => {
    return (
      <>
        <p className={styles.fieldHeading} style={{ marginTop: 10 }}>
          {repositoryData?._id
            ? `Source Code : ${repositoryData?.filename}`
            : "Source code is not uploaded"}
        </p>
            <AppButton
            color="primary"
            buttonName={repositoryData?._id ? "Re Upload" : "Upload"}
            variant="contained"
            startIcon={
              repositoryData?._id ? (
                <img src="/images/reuploadLight.svg" alt="card" />
              ) : (
                <img src="/images/bulkupload.svg" alt="card" />
              )
            }
            disabled={false}
            style={{ padding: "6px 15px", marginLeft: 0 }}
            className="btnsmall"
            onClick={() => setOpenUploadSourceCodeModal(true)}
          />
       </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "AITask Data Model") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                AI Model
              </label>
              <Autocomplete
                options={dataModelsList ? dataModelsList : []}
                disabled={!isEditMode || field.editable === "FALSE"}
                getOptionLabel={(option) => option?.name}
                className={`${styles.searchInput3} ${
                  error.projectID?.isError &&
                  !selectedProject?._id &&
                  styles.error
                }`}
                onChange={(e, input) => {
                  if (input) {
                    let versionModelData = [...versionData.datamodels];

                    const findDataModel =
                      versionData?.datamodels &&
                      versionData?.datamodels.find(
                        (item) => item._id == input._id
                      );

                    if (!findDataModel) {
                      const currentData = { name: input.name, _id: input._id };
                      versionModelData.push(currentData);
                      setVersionData({
                        ...versionData,
                        datamodels: versionModelData,
                      });
                    }
                    // setSelectedProject(input);
                    // setServiceData({
                    //     ...serviceData,
                    //     projectID: input?._id,
                    // });
                  }
                  // else {
                  //     setSelectedProject({});
                  //     setSelectedServiceGroup({})
                  //     setServiceData({ ...serviceData, projectID: "", servicegroupID: "" });
                  // }
                }}
                // onInputChange={(event, newInputValue) => {
                //     setProjectSearchInput(newInputValue);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Model"
                  />
                )}
              />
              {error.projectID?.isError && !selectedProject?._id && (
                <p className={styles.errorMsg}>{"Project is Required"}</p>
              )}

              <LanguageChips
                chipsData={modelCardData ? modelCardData : []}
                isEditMode={!isEditMode}
                handleDelete={handleDataModelsDelete}
              />

              {/* {languageDetails?.name && ( */}
              {/* <LanguageChips
            chipsData={[
              { name: languageDetails?.name, icon: languageDetails?.icon },
            ]}
            isEditMode={!isEditMode}
            handleDelete={() => setLanguageDetails({})}
          /> */}
              {/* )} */}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  field.dataType === "date" && versionData[field.datakey]
                    ? userInfo?.timeZone
                      ? moment
                          .unix(versionData[field.datakey])
                          .tz(userInfo?.timeZone)
                          .format(
                            userInfo.time === "h:mm"
                              ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                              : `${userInfo?.dateFormat} ${userInfo?.time}`
                          )
                      : moment
                          .unix(versionData[field.datakey])
                          .format("DD/MM/YYYY, hh:mm:ss A")
                    : versionData[field.datakey]
                }
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={versionData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${
                !isEditMode && styles.editMode
              } ${error[field.datakey]?.isError && styles.error} ${
                field.editable === "FALSE" && styles.editMode
              }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                versionData[field.datakey] ? versionData[field.datakey] : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                versionData[field.datakey] ? versionData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={!isEditMode || field.editable === "FALSE"}
                      className={`${styles.input} ${
                        !isEditMode && styles.editMode
                      } ${error[field.datakey]?.isError && styles.error} ${
                        field.editable === "FALSE" && styles.editMode
                      }`}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${
                        !isEditMode && styles.editModeDatePicker
                      }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  versionData[`${field.datakey}_unit`]
                    ? versionData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${
                  !isEditMode && styles.editMode
                } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${
                  field.editable === "FALSE" && styles.editMode
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={versionData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                versionData[field.datakey] ? versionData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={
                        <Radio
                          disabled={!isEditMode || field.editable === "FALSE"}
                          color="primary"
                        />
                      }
                      label={value}
                    />
                  ))
                : field?.values.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={
                        <Radio
                          disabled={!isEditMode || field.editable === "FALSE"}
                          color="primary"
                        />
                      }
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "AITask_Version_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "AITask Version Environmentvariables":
                          return environmentVariablesSection();
                        case "AITask Version Runtime Environment":
                          return runtimeEnvironmentSection();
                        case "AITask Version Docker":
                          return dockerCommandSection();
                        case "AITask Version SourceCode":
                          return sourceCodeSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "AITask Version Environment Variables":
                                return environmentVariablesSection();
                              case "AITask Version Runtime Environment":
                                return runtimeEnvironmentSection();
                              case "AITask Version Docker Commands":
                                return dockerCommandSection();
                              case "AITask Version SourceCode":
                                return sourceCodeSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { dataModelsList } = state.aiTaskReducer;

  return {
    userInfo,
    dataModelsList,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForAiTaskVersionEdit);
