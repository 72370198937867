import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import CustomFieldGroupForTestDataCreate from "../../../components/common/entitySchema/test/CustomFieldGroupForTestDataCreate";
import CustomFieldGroupForTestDataEdit from "../../../components/common/entitySchema/test/CustomFieldGroupForTestDataEdit";
import CustomFieldGroupForTestScriptCreate from "../../../components/common/entitySchema/test/CustomFieldGroupForTestScriptCreate";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import Loader from "../../../components/common/stuff/Loader";
import { handleFormValidationPro, initError } from "../../../utils/common";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import { addTestData } from "../../../utils/project/testUtils";
import {
  addTestEnvironmentFile,
  fetchTestDataById,
  updateTestDataByID,
  updateTestEnvDataByID,
} from "../../../utils/test/testDataUtils";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EditTestDataScreen = (props) => {
  const styles = manageScreenStyles();
  const style = listScreenStyles();

  const isEditMode = props.match.params.action === "edit" ? true : false;
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({});
  const [testScriptEntitySchema, setTestScriptEntitySchema] = useState([]);
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    file: "",
  });

  const [testDataID] = useState(props.match.params.testDataID);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [payloadData, setPayloadData] = useState({
    description: "",
    name: "",
    file: "",
    test_data_type: "",
  });

  // Tabbed View
  const { tabbedView } = props;

  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchTestScriptEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "TestData",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setTestScriptEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        // setProjectData(prevData => ({ ...prevData, ...projData, ...newProjectData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        fetchTestDataDetails();
        delete errData['file']

        setError({ ...errData, filename: { dataType: 'string', fieldLabel: "File", isError: false, minLength: 1, minimum: 1, msg: '', section: 1 } });
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchTestDataDetails = async () => {
      setIsLoader(true);

      const { data, _msg, _status } = await fetchTestDataById(testDataID);
      if (_status === 200) {

        setPayloadData(data);
        setIsLoader(false);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    };

    fetchTestScriptEntitySchema();
  }, [isEditMode]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    setPayloadData({
      ...payloadData,
      [event.target.name]: value,
    });
  };

  const handleUpload = (files) => {
    if (files.length > 0) {
      const findExtName = files[0] ? files[0].name : "";

      const ext = findExtName.split(".");
      const extensitonName = ext[ext?.length - 1];

      if (extensitonName === "json") {
        function handleFileSelect(f) {
          let reader = new FileReader();

          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              function isJsonString(str) {
                try {
                  JSON.parse(str);
                } catch (e) {
                  return false;
                }
                return true;
              }
              const isValidJson = isJsonString(e.target.result);
              if (isValidJson) {
                setFileDetails({
                  filename: files ? (files[0] ? files[0].name : "") : "",
                });
                setPayloadData({
                  ...payloadData,
                  file: files ? (files[0] ? files[0].name : "") : "",
                  filename: files ? (files[0] ? files[0].name : "") : "",
                });
                setDroppedDocument(files);
              } else {
                enqueueSnackbar("Invalid Json file", { variant: "error" });
              }
            };
          })(f);

          // Read in the image file as a data URL.
          if (f) {
            reader.readAsText(f);
          }
        }

        handleFileSelect(files[0]);
      } else {
        setFileDetails({
          filename: files ? (files[0] ? files[0].name : "") : "",
        });
        setPayloadData({
          ...payloadData,
          file: files ? (files[0] ? files[0].name : "") : "",
        });
        setDroppedDocument(files);
      }
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(payloadData, error);
    if (formIsValid) {
      setIsLoader(true);
      let testFormData = new FormData();
      let meta = {
        name: payloadData.name,
        description: payloadData.description,
      };
      if (droppedDocument.length > 0) {
        testFormData.append("file", droppedDocument[0]);
      }
      testFormData.append("meta", JSON.stringify(meta));
      if (payloadData?.test_data_type === "Test Data") {
        var { _msg, _status, data } = await updateTestDataByID(
          testDataID,
          testFormData
        );
      } else if (payloadData?.test_data_type === "Configuration") {
        var { _msg, _status, data } = await updateTestEnvDataByID(
          testDataID,
          testFormData
        );
      }
      if (_status === 200 || _status === 201) {
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        history.push(`/test-data/details/${testDataID}`);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        testScriptEntitySchema ? testScriptEntitySchema : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setTestScriptEntitySchema(tempEntity);

      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleDeleteClick = () => {
    setDroppedDocument([]);
    setFileDetails({
      filename: "",
    });
    setPayloadData({ ...payloadData, filename: "" });
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Test", path: "/test" },
            {
              title: "Data",
              path: "/test",
            },
            {
              title: isEditMode ? `Edit ${payloadData.name}` : payloadData.name,
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {isEditMode ? `Edit ${payloadData.name}` : payloadData?.name}
          </h2>
          {tabbedView ? (
            <TabbedNavigation
              schemaLength={testScriptEntitySchema?.length}
              isEditMode={isEditMode}
              isClose={isEditMode ? false : true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleEdit={() => history.push(`/test-data/edit/${testDataID}`)}
              handleCancel={handleCancel}
            />
          ) : (
            <>
              <AccordionNavigation
                isClose={isEditMode ? false : true}

                isEditMode={!isEditMode ? true : false}
                handleEdit={() => history.push(`/test-data/edit/${testDataID}`)}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />

            </>
          )}
          { }
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {tabbedView ? (
          <>
            <SectionTabs
              entity={testScriptEntitySchema ? testScriptEntitySchema : []}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CustomFieldGroupForTestDataEdit
                  section={testScriptEntitySchema?.[activeTab]}
                  payloadData={payloadData}
                  handleChange={handleChange}
                  error={error}
                  handleUpload={handleUpload}
                  setIsLoader={setIsLoader}
                  droppedDocument={droppedDocument}
                  fileDetails={fileDetails}
                  handleDeleteClick={handleDeleteClick}
                  isEditMode={isEditMode}
                />
              </div>
            </Paper>
          </>
        ) : (
          testScriptEntitySchema.length !== 0 &&
          testScriptEntitySchema.map((section) => {
            if (section.name === "Test_Data_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForTestDataEdit
                    section={section}
                    payloadData={payloadData}
                    handleChange={handleChange}
                    error={error}
                    handleUpload={handleUpload}
                    setIsLoader={setIsLoader}
                    droppedDocument={droppedDocument}
                    fileDetails={fileDetails}
                    handleDeleteClick={handleDeleteClick}
                    isEditMode={isEditMode}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingLeft: 5 }}>
                    <CustomFieldGroupForTestDataEdit
                      section={section}
                      payloadData={payloadData}
                      handleChange={handleChange}
                      error={error}
                      handleUpload={handleUpload}
                      setIsLoader={setIsLoader}
                      droppedDocument={droppedDocument}
                      fileDetails={fileDetails}
                      handleDeleteClick={handleDeleteClick}
                      isEditMode={isEditMode}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return {
    tabbedView,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTestDataScreen);
