import {
  Grid,
  Typography,
  Paper,
  FilledInput,
  Checkbox,
  InputAdornment,
  IconButton,
  Divider,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  TextField,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { AppButton } from "../../Button";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CustomChips from "../../Chips";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { connect } from "react-redux";
import DocumentTableWithDropper from "../../Tables/DocumentTableWithDropper";
import useCheckPermission from "../../hooks/useCheckPermission";
import DevExpressTable from "../../Tables/DevExpressTable";
import {
  serviceColumnOrder,
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceDefaultSorting,
  serviceTableColumnExtensions,
} from "../../../../constants/servicesConstant";
import { getWindowDimensions } from "../../../../utils/common";

const CustomFieldGroupsForServiceGroupEdit = ({
  section,
  serviceGroupData,
  setServiceGroupData,
  isEditMode,
  error,
  handleChange,
  handleTeamCheck,
  clickedTeam,
  handleTeamSearch,
  selectedTeams,
  teams,
  userInfo,
  setClickedTeam,
  setTeamSearchInput,
  handleSelectedTeamDelete,
  setCopyServiceModal,
  handleNewServiceGroupClick,
  level,
  teamSearchInput,
  teamLoader,
  serviceStatuses,
  allMicroserviceList,
  serviceGroupTeams,
  teamTab,
  handleChangeTeamTab,
  statuses,
  documents,
  fetchDocuments,
  parentServiceGroupId,
  serviceGroupCurrentPage,
  handleServicegroupDocumentListPagination,
  service_groups_documents_list,
}) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { checkCompPermission } = useCheckPermission();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [statusOpen, setStatusOpen] = useState(false);

  const closeDropDown = () => {
    setStatusOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const serviceOrder = serviceColumnOrder.splice(4, 1, "action");
  const servicesData =
    allMicroserviceList &&
    allMicroserviceList.map((service) => ({
      ...service,
      id: service._id,
      status: service?.status,
      statuses: serviceStatuses,
    }));

  const serviceGroupTableSection = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ margin: "20px 0" }}
        >
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <AppButton
              color="primary"
              buttonName="Add Service"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={!checkCompPermission("Microservice", ["Copy Service"])}
              style={{ margin: "unset", marginLeft: "20px" }}
              className="btnsmall"
              onClick={() => setCopyServiceModal(true)}
            />
            {level >= 1 && level <= 3 && (
              <Divider
                orientation="vertical"
                style={{ height: 35, marginLeft: 20, width: 2 }}
              />
            )}
            {/* {level >= 1 && level <= 3 && (
              <AppButton
                color="primary"
                buttonName="Create Service Group"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={
                  !checkCompPermission("Project", ["CreateServiceGroup"])
                }
                style={{ margin: "unset", marginLeft: "20px" }}
                className="btnsmall"
                onClick={() => handleNewServiceGroupClick("new")}
              />
            )} */}
          </Grid>
        </Grid>
        {checkCompPermission("Microservice", ["List All", "List Assigned"]) && (
          <DevExpressTable
            columns={serviceColumns}
            rows={servicesData}
            defaultColumnWidths={serviceDefaultColumnWidths}
            tableColumnExtensions={serviceTableColumnExtensions}
            columnOrder={serviceOrder}
            defaultSorting={serviceDefaultSorting}
            // loading={loading}
            // currentPage={currentPageService}
            // setCurrentPage={setCurrentPageService}
            isCustomHeight={false}
            hideColumnChooser={true}
            // sort={serviceSort}
            windowDimensions={windowDimensions}
            // setSort={addServiceSort}
            // hidden={serviceHidden}
            // setHidden={addServiceHidden}
            noDataMsg="No Services Found"
            isCustomWidth={100}
          />
        )}
      </>
    );
  };

  const documentSection = () => {
    return (
      <>
        {checkCompPermission("Document", ["List All", "List Assigned"]) ? (
          <div style={{ marginTop: 15 }}>
            <DocumentTableWithDropper
              isEditMode={
                isEditMode &&
                checkCompPermission("ServiceGroup", ["Create Document"])
              }
              rows={documents}
              fetchDocuments={fetchDocuments}
              resourceId={parentServiceGroupId}
              resourceType={"servicegroup"}
              isDocumentModule={false}
            />
            {documents.length !== 0 && (
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(
                    service_groups_documents_list._totalcount / 10
                  )}
                  page={serviceGroupCurrentPage}
                  onChange={handleServicegroupDocumentListPagination}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            )}
          </div>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Document List All, List Assigned. Please
            contact your Admin.
          </p>
        )}
      </>
    );
  };

  const teamsSection = () => {
    return (
      <>
        {isEditMode ? (
          <>
            <FilledInput
              name="searchInput"
              value={teamSearchInput}
              onChange={(e) => {
                setTeamSearchInput(e.target.value);
                handleTeamSearch(e.target.value);
              }}
              className={`${styles.input} ${styles.searchInput2} ${styles.customSearchInput}`}
              placeholder="Search"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTeamSearch}
                    onMouseDown={handleTeamSearch}
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />

            <CustomChips
              chipsData={selectedTeams}
              handleDelete={handleSelectedTeamDelete}
              isEditMode={
                !checkCompPermission("ServiceGroup", ["Assign Or Dismiss Team"])
              }
            />

            {teamLoader && <CircularProgress color="primary" />}

            <Grid container spacing={5}>
              <Grid
                item
                xs={6}
                sm={6}
                style={{ borderRight: "1px solid rgba(0, 40, 85, .4)" }}
              >
                <div
                  className={styles.section}
                  style={{
                    opacity: 1,
                    maxHeight: "25rem",
                    minHeight: "20rem",
                    overflow: "auto",
                    padding: "0 1rem 0 0",
                  }}
                >
                  <Grid container spacing={3}>
                    {teams.length > 0 &&
                      teams
                        .filter((team) => team.block_status === "false")
                        .map((team, index) => (
                          <Grid item xs={12} sm={12} key={index}>
                            <Paper
                              className={styles.paper}
                              onClick={() => setClickedTeam(team)}
                              style={{ cursor: "pointer" }}
                            >
                              <div className={styles.spaceBetweenText}>
                                <Typography variant="body1">
                                  {team.name}
                                </Typography>
                                <div className={styles.technologyCheck}>
                                  <Typography variant="body1">
                                    {team.org}
                                  </Typography>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      selectedTeams.find(
                                        (item) => item._id === team._id
                                      )
                                        ? true
                                        : false
                                    }
                                    className={styles.teamCheckbox}
                                    onChange={(e) => handleTeamCheck(team)}
                                    name={team.name}
                                    disabled={
                                      !checkCompPermission("ServiceGroup", [
                                        "Assign Or Dismiss Team",
                                      ])
                                    }
                                  />
                                </div>
                              </div>
                            </Paper>
                          </Grid>
                        ))}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className={styles.section}>
                  <Grid container spacing={3}>
                    {clickedTeam?.users.map((member, index) => (
                      <Grid item xs={6} sm={6} key={index}>
                        <Paper className={styles.paper}>
                          <div className={styles.teamWrapper}>
                            {member?.image ? (
                              <img
                                src={member?.image}
                                className={styles.memberProfile}
                                alt="profile"
                              />
                            ) : (
                              <img
                                src="/images/users/default.svg"
                                alt="profile"
                              />
                            )}
                            <div className={styles.teamMember}>
                              <Typography
                                variant="body1"
                                className={styles.teamMemberName}
                              >
                                {member?.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                className={styles.teamMemberDesgination}
                              >
                                {member?.desgination}
                              </Typography>
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {serviceGroupTeams.length === 0 && (
              <Typography variant="h6" className={styles.fieldText}>
                Teams is not selected
              </Typography>
            )}
            <Tabs
              value={teamTab}
              onChange={handleChangeTeamTab}
              indicatorColor="primary"
              textColor="primary"
              className={styles.teamTab}
            >
              {serviceGroupTeams.map((team, index) => (
                <Tab label={team.name} key={index} />
              ))}
            </Tabs>
            {serviceGroupTeams.map(
              (team, index) =>
                teamTab === index && (
                  <div className={styles.teamChips} key={index}>
                    <Grid container spacing={3}>
                      {team?.users?.map((data, index) => (
                        <Grid item xs={3} sm={3} key={index}>
                          <Paper
                            className={styles.teamMemberPaper}
                            onClick={() =>
                              histroy.push(`/user-details/${data}`)
                            }
                          >
                            {data?.image ? (
                              <img
                                src={data?.image}
                                className={styles.memberProfile}
                                alt="profile"
                              />
                            ) : (
                              <img
                                src="/images/users/default.svg"
                                alt="profile"
                              />
                            )}
                            <Typography
                              variant="body1"
                              className={styles.teamMemberName}
                            >
                              {data?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.teamMemberDesgination}
                            >
                              {data?.desgination}
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )
            )}
          </>
        )}
      </>
    );
  };

  const statusSection = () => {
    return (
      <>
        {isEditMode && (
          <>
            <Autocomplete
              open={statusOpen}
              onOpen={() => setStatusOpen(true)}
              onClose={() => setStatusOpen(false)}
              options={statuses}
              getOptionLabel={(option) => option.status}
              className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
                }`}
              onChange={(e, input) => {
                if (input) {
                  setServiceGroupData({
                    ...serviceGroupData,
                    status: input.status,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Search Status"
                  placeholder="Search Status"
                />
              )}
            />
            {error.status?.isError && (
              <p className={styles.errorMsg}>{"Status is Required"}</p>
            )}
          </>
        )}

        {serviceGroupData?.status ? (
          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
            <div
              style={{
                backgroundColor: statuses.find(
                  (s) => s.status === serviceGroupData?.status
                )?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                position: "relative",
                right: 10,
              }}
            ></div>
            <Typography
              variant="h6"
              className={styles.fieldText}
              style={{
                color: statuses.find(
                  (s) => s.status === serviceGroupData?.status
                )?.color,
              }}
            >
              {statuses
                .find((s) => s.status === serviceGroupData?.status)
                ?.status.charAt(0)
                .toUpperCase() +
                statuses
                  .find((s) => s.status === serviceGroupData?.status)
                  ?.status.slice(1)}
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" className={styles.fieldText}>
            Status is not set
          </Typography>
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              value={
                field.dataType === "date" && serviceGroupData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                      .unix(serviceGroupData[field.datakey])
                      .tz(userInfo?.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                          : `${userInfo?.dateFormat} ${userInfo?.time}`
                      )
                    : moment
                      .unix(serviceGroupData[field.datakey])
                      .format("DD/MM/YYYY, hh:mm:ss A")
                  : serviceGroupData[field.datakey]
              }
              autoComplete="off"
              disabled={!isEditMode || field.editable === "FALSE"}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={serviceGroupData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                serviceGroupData[field.datakey]
                  ? serviceGroupData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                serviceGroupData[field.datakey]
                  ? serviceGroupData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={!isEditMode || field.editable === "FALSE"}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceGroupData[`${field.datakey}_unit`]
                    ? serviceGroupData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={serviceGroupData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceGroupData[field.datakey]
                  ? serviceGroupData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "ServiceGroup_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Service Group Team":
                          return teamsSection();
                        case "Service Group Service":
                          return serviceGroupTableSection();
                        case "Service Group Status":
                          return statusSection();
                        case "Service Group Documentation":
                          return documentSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "-2.5rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.uiElementType) {
                              case "Service Group Team":
                                return teamsSection();
                              case "Service Group Service":
                                return serviceGroupTableSection();
                              case "Service Group Documentation":
                                return documentSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForServiceGroupEdit);