import { Divider, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import {
  addSectionFilters,
  getOntologySections,
  addOntologySort,
  clearOntologySectionsData,
  getOntologyObjects,
  addObjectFilters,
  clearOntologyObjectsData,
  addFieldgroupFilters,
  getOntologyFieldGroups,
  clearOntologyFieldGroupData,
  addFieldFilters,
  getOntologyFields,
  clearOntologyFieldData,
  addObjectColumnHidden,
  addSectionColumnHidden,
  addFieldGroupColumnHidden,
  addFieldColumnHidden,
} from "../../../redux/actions/ontologyAction";
import {
  objectColumnOrder,
  objectColumns,
  objectDefaultColumnWidths,
  objectDefaultSorting,
  objectTableColumnExtensions,
} from "../../../constants/Ontology/ObjectsConstant";
import {
  arrayToFilter,
  arrayToSort,
  getWindowDimensions,
} from "../../../utils/common";
import CustomFilter from "../../../components/common/CustomFilter";
import OntologyTable from "../../../components/common/Tables/OntologyTable";
import Loader from "../../../components/common/stuff/Loader";
import { Pagination } from "@material-ui/lab";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import {
  sectionColumns,
  sectionDefaultColumnWidths,
  sectionTableColumnExtensions,
  sectionColumnOrder,
  sectionDefaultSorting,
} from "../../../constants/Ontology/SectionConstant";
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router-dom";
import {
  fieldgroupColumns,
  fieldgroupDefaultColumnWidths,
  fieldgroupTableColumnExtensions,
  fieldgroupColumnOrder,
  fieldgroupDefaultSorting,
} from "../../../constants/Ontology/FieldGroupConstant";
import {
  fieldColumns,
  fieldDefaultColumnWidths,
  fieldTableColumnExtensions,
  fieldColumnOrder,
  fieldDefaultSorting,
} from "../../../constants/Ontology/FieldConstant";
import SearchBar from "../../../components/common/SearchBar";

function createDataObject(item) {
  const {
    _id,
    name,
    predefined,
    sections,
    predefined_sections,
    updatedon,
    label,
  } = item;
  return {
    _id: _id,
    name: name,
    label: label,
    predefined: predefined ? predefined : "FALSE",
    sections: sections ? sections.length : 0,
    predefined_sections: predefined_sections ? predefined_sections.length : 0,
    updatedon: updatedon,
  };
}

function createDataFieldGroup(item) {
  const { _id, fields, name, predefined, predefined_fields, updatedon } = item;
  return {
    _id: _id,
    name: name,
    predefined: predefined ? predefined : "FALSE",
    num_fields: fields ? fields.length : 0,
    num_predefined_fields: predefined_fields ? predefined_fields.length : 0,
    updatedon: updatedon,
  };
}

function createDataSection(item) {
  const { _id, name, predefined, updatedby, updatedon } = item;
  return {
    _id: _id,
    name: name,
    sectionLabel: item["section Label"],
    predefined: predefined ? predefined : "FALSE",
    updatedby: updatedby,
    updatedon: updatedon,
  };
}

function createDataField(item) {
  const {
    _id,
    name,
    fieldLabel,
    dataType,
    datakey,
    defaultValue,
    editable,
    errorMessage,
    placeholder,
    predefined,
    required,
    uiElementType,
    updatedon,
  } = item;
  return {
    _id,
    name,
    fieldLabel,
    uiElementType,
    dataType,
    updatedon: updatedon ? updatedon : "",
    predefined: predefined ? predefined : "FALSE",
  };
}
const OntologyListScreen = (props) => {
  const {
    alterActiveTab,
    ontologyActiveTab,
    getOntologyObjects,
    objectFilters,
    objectSort,
    addObjectFilters,
    loadingObject,
    errorObject,
    _totalcountObject,
    dataObject,
    addOntologySort,
    clearOntologyObjectsData,
    sectionFilters,
    sectionSort,
    addSectionFilters,
    getOntologySections,
    clearOntologySectionsData,
    loadingSection,
    _totalcountSection,
    errorsection,
    dataSection,
    fieldgroupFilters,
    fieldgroupSort,
    addFieldgroupFilters,
    loadingFieldGroup,
    dataFieldGroup,
    errorFieldGroup,
    _totalcountFieldGroup,
    getOntologyFieldGroups,
    clearOntologyFieldGroupData,
    fieldsFilters,
    fieldSort,
    addFieldFilters,
    loadingField,
    dataField,
    errorField,
    _totalcountField,
    getOntologyFields,
    clearOntologyFieldData,
    addObjectColumnHidden,
    addSectionColumnHidden,
    addFieldGroupColumnHidden,
    addFieldColumnHidden,
    objectsHidden,
    sectionHidden,
    fieldGroupsHidden,
    fieldsHidden,
  } = props;

  const [searchInput, setSearchInput] = useState("");
  const styles = listScreenStyles();
  const [rowsObject, setRowsObject] = useState([]);
  const [hidesObject, setHidesObject] = useState(["predefined_sections"]);
  const [hidesSection, setHidesSection] = useState(["updatedby"]);
  const [hidesFieldGroup, setHidesFieldGroup] = useState([
    "num_predefined_fields",
  ]);
  const [hidesField, setHidesField] = useState(["updatedon", "dataType"]);
  const [currentPageObject, setCurrentPageObject] = useState(1);
  const [currentPageSection, setCurrentPageSection] = useState(1);
  const [currentPageFieldGroup, setCurrentPageFieldGroup] = useState(1);
  const [currentPageField, setCurrentPageField] = useState(1);
  const isInitialMount = useRef(true);
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [rowsSection, setRowsSection] = useState([]);
  const history = useHistory();
  const [rowsFieldGroup, setRowsFieldGroup] = useState([]);
  const [rowsField, setRowsField] = useState([]);

  useEffect(() => {
    checkScreenPermission("OntologyResource", ["List All"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChangeTab = (event, newValue) => {
    alterActiveTab("ontologyActiveTab", newValue);
  };

  useEffect(() => {
    const parsedFilters = arrayToFilter(objectFilters);
    const parsedSort = arrayToSort(objectSort);
    getOntologyObjects(
      currentPageObject,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageObject, objectSort]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(sectionFilters);
    const parsedSort = arrayToSort(sectionSort);
    getOntologySections(
      currentPageSection,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageSection, sectionSort]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldgroupFilters);
    const parsedSort = arrayToSort(fieldgroupSort);
    getOntologyFieldGroups(
      currentPageFieldGroup,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageFieldGroup, fieldgroupSort]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldsFilters);
    const parsedSort = arrayToSort(fieldSort);
    getOntologyFields(
      currentPageField,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageField, fieldSort]);

  const activeTabBredCrumbsName = () => {
    if (ontologyActiveTab === "object") {
      return "Objects List";
    } else if (ontologyActiveTab === "sections") {
      return "Sections List";
    } else if (ontologyActiveTab === "fieldgroup") {
      return "Field Groups List";
    } else if (ontologyActiveTab === "fields") {
      return "Fields List";
    }
  };

  const handleSearch = () => {
    if (ontologyActiveTab === "object") {
      if (searchInput) {
        getOntologyObjects(
          1,
          JSON.stringify({
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getOntologyObjects(1);
      }
    } else if (ontologyActiveTab === "sections") {
      if (searchInput) {
        getOntologySections(
          1,
          JSON.stringify({
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getOntologySections(1);
      }
    } else if (ontologyActiveTab === "fieldgroup") {
      if (searchInput) {
        getOntologyFieldGroups(
          1,
          JSON.stringify({
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getOntologyFieldGroups(1);
      }
    } else if (ontologyActiveTab === "fields") {
      if (searchInput) {
        getOntologyFields(
          1,
          JSON.stringify({
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getOntologyFields(1);
      }
    }
  };
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSubmitFilter = () => {
    if (ontologyActiveTab === "object") {
      const parsedFilters = arrayToFilter(objectFilters);
      const parsedSort = arrayToSort(objectSort);
      getOntologyObjects(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (ontologyActiveTab === "sections") {
      const parsedFilters = arrayToFilter(sectionFilters);
      const parsedSort = arrayToSort(sectionSort);
      getOntologySections(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (ontologyActiveTab === "fieldgroup") {
      const parsedFilters = arrayToFilter(fieldgroupFilters);
      const parsedSort = arrayToSort(fieldgroupSort);
      getOntologyFieldGroups(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (ontologyActiveTab === "fields") {
      const parsedFilters = arrayToFilter(fieldsFilters);
      const parsedSort = arrayToSort(fieldSort);
      getOntologyFields(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };
  const handleClearFilters = () => {
    if (ontologyActiveTab === "object") {
      getOntologyObjects(1, JSON.stringify({}));
    } else if (ontologyActiveTab === "sections") {
      getOntologySections(1, JSON.stringify({}));
    } else if (ontologyActiveTab === "fieldgroup") {
      getOntologyFieldGroups(1, JSON.stringify({}));
    } else if (ontologyActiveTab === "fields") {
      getOntologyFields(1, JSON.stringify({}));
    }
  };

  useEffect(() => {
    setRowsObject(
      dataObject && dataObject.length > 0
        ? dataObject.map((item) => {
          const rowData = createDataObject(item);
          return rowData;
        })
        : []
    );
  }, [dataObject]);

  useEffect(() => {
    setRowsSection(
      dataSection && dataSection.length > 0
        ? dataSection.map((item) => {
          const rowData = createDataSection(item);
          return rowData;
        })
        : []
    );
  }, [dataSection]);

  useEffect(() => {
    setRowsFieldGroup(
      dataFieldGroup && dataFieldGroup.length > 0
        ? dataFieldGroup.map((item) => {
          const rowData = createDataFieldGroup(item);
          return rowData;
        })
        : []
    );
  }, [dataFieldGroup]);

  useEffect(() => {
    setRowsField(
      dataField && dataField.length > 0
        ? dataField.map((item) => {
          const rowData = createDataField(item);
          return rowData;
        })
        : []
    );
  }, [dataField]);

  useEffect(() => {
    if (ontologyActiveTab === "object") {
      return () => {
        clearOntologyObjectsData();
      };
    } else if (ontologyActiveTab === "sections") {
      return () => {
        clearOntologySectionsData();
      };
    } else if (ontologyActiveTab === "fieldgroup") {
      return () => {
        clearOntologyFieldGroupData();
      };
    } else if (ontologyActiveTab === "fields") {
      return () => {
        clearOntologyFieldData();
      };
    }
  }, []);

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Ontology", path: "/ontology" },
              { title: activeTabBredCrumbsName() },
            ]}
          />
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <Tabs
                value={ontologyActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
              >
                <Tab
                  label={`Objects
   ${_totalcountObject ? `(${_totalcountObject})` : ``}`}
                  value={"object"}
                />
                <Tab
                  label={`Sections
   ${_totalcountSection ? `(${_totalcountSection})` : ``}`}
                  value={"sections"}
                />
                <Tab
                  label={`Field Groups
   ${_totalcountFieldGroup ? `(${_totalcountFieldGroup})` : ``}`}
                  value={"fieldgroup"}
                />
                <Tab
                  label={`Fields
   ${_totalcountField ? `(${_totalcountField})` : ``}`}
                  value={"fields"}
                />
              </Tabs>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel={
                  ontologyActiveTab === "fieldgroup"
                    ? "Search Field Group Name"
                    : ontologyActiveTab === "fields"
                      ? "Search Field Name"
                      : "Search Name"
                }
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              {ontologyActiveTab === "object" && (
                <CustomFilter
                  columns={objectColumns}
                  filters={objectFilters}
                  setFilters={addObjectFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {ontologyActiveTab === "sections" && (
                <CustomFilter
                  columns={sectionColumns}
                  filters={sectionFilters}
                  setFilters={addSectionFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}

              {ontologyActiveTab === "fieldgroup" && (
                <CustomFilter
                  columns={fieldgroupColumns}
                  filters={fieldgroupFilters}
                  setFilters={addFieldgroupFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {ontologyActiveTab === "fields" && (
                <CustomFilter
                  columns={fieldColumns}
                  filters={fieldsFilters}
                  setFilters={addFieldFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {ontologyActiveTab === "sections" && (
                <>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={
                      !checkCompPermission("OntologySection", ["Create"])
                    }
                    className="btnsmall"
                    onClick={() => history.push("/ontology/sections/add")}
                  />
                </>
              )}
              {ontologyActiveTab === "fieldgroup" && (
                <>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={
                      !checkCompPermission("OntologyFieldGroup", ["Create"])
                    }
                    className="btnsmall"
                    onClick={() => history.push("/ontology/fieldgroups/add")}
                  />
                </>
              )}
              {ontologyActiveTab === "fields" && (
                <>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={!checkCompPermission("OntologyField", ["Create"])}
                    className="btnsmall"
                    onClick={() => history.push("/ontology/fields/add")}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Paper className={styles.pageContent}>
          {ontologyActiveTab === "object" && (
            <>
              <OntologyTable
                columns={objectColumns}
                rows={rowsObject}
                defaultColumnWidths={objectDefaultColumnWidths}
                tableColumnExtensions={objectTableColumnExtensions}
                columnOrder={objectColumnOrder}
                defaultSorting={objectDefaultSorting}
                loading={loadingObject}
                hidden={objectsHidden}
                setHidden={addObjectColumnHidden}
                totalCount={_totalcountObject}
                currentPage={currentPageObject}
                setCurrentPage={setCurrentPageObject}
                isCustomHeight={true}
                isCustomPaging={true}
                sort={objectSort}
                setSort={(sort) => addOntologySort("object", sort)}
                noDataMsg="No Object Found! "
              />
            </>
          )}
          {ontologyActiveTab === "sections" && (
            <>
              <OntologyTable
                columns={sectionColumns}
                rows={rowsSection}
                defaultColumnWidths={sectionDefaultColumnWidths}
                tableColumnExtensions={sectionTableColumnExtensions}
                columnOrder={sectionColumnOrder}
                defaultSorting={sectionDefaultSorting}
                loading={loadingSection}
                hidden={sectionHidden}
                setHidden={addSectionColumnHidden}
                totalCount={_totalcountSection}
                currentPage={currentPageSection}
                setCurrentPage={setCurrentPageSection}
                isCustomHeight={true}
                isCustomPaging={true}
                sort={sectionSort}
                setSort={(sort) => addOntologySort("section", sort)}
                noDataMsg="No Sections Found"
              />
            </>
          )}
          {ontologyActiveTab === "fieldgroup" && (
            <>
              <OntologyTable
                columns={fieldgroupColumns}
                rows={rowsFieldGroup}
                defaultColumnWidths={fieldgroupDefaultColumnWidths}
                tableColumnExtensions={fieldgroupTableColumnExtensions}
                columnOrder={fieldgroupColumnOrder}
                defaultSorting={fieldgroupDefaultSorting}
                loading={loadingFieldGroup}
                hidden={fieldGroupsHidden}
                setHidden={addFieldGroupColumnHidden}
                totalCount={_totalcountFieldGroup}
                currentPage={currentPageFieldGroup}
                setCurrentPage={setCurrentPageFieldGroup}
                isCustomHeight={true}
                isCustomPaging={true}
                sort={fieldgroupSort}
                setSort={(sort) => addOntologySort("fieldgroup", sort)}
                noDataMsg="No Field Group Found"
              />
            </>
          )}
          {ontologyActiveTab === "fields" && (
            <>
              <OntologyTable
                columns={fieldColumns}
                rows={rowsField}
                defaultColumnWidths={fieldDefaultColumnWidths}
                tableColumnExtensions={fieldTableColumnExtensions}
                columnOrder={fieldColumnOrder}
                defaultSorting={fieldDefaultSorting}
                loading={loadingField}
                hidden={fieldsHidden}
                setHidden={addFieldColumnHidden}
                totalCount={_totalcountField}
                currentPage={currentPageField}
                setCurrentPage={setCurrentPageField}
                isCustomHeight={true}
                isCustomPaging={true}
                sort={fieldSort}
                setSort={(sort) => addOntologySort("field", sort)}
                noDataMsg="No Field Found"
              />
            </>
          )}
        </Paper>
        {rowsObject.length !== 0 && ontologyActiveTab === "object" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcountObject / 10)}
                page={currentPageObject}
                onChange={(e, val) => setCurrentPageObject(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        {rowsSection.length !== 0 && ontologyActiveTab === "sections" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcountSection / 10)}
                page={currentPageSection}
                onChange={(e, val) => setCurrentPageSection(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        {rowsFieldGroup.length !== 0 && ontologyActiveTab === "fieldgroup" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcountFieldGroup / 10)}
                page={currentPageFieldGroup}
                onChange={(e, val) => setCurrentPageFieldGroup(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        {rowsField.length !== 0 && ontologyActiveTab === "fields" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcountFieldGroup / 10)}
                page={currentPageField}
                onChange={(e, val) => setCurrentPageField(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  alterActiveTab,
  getOntologyObjects,
  addObjectFilters,
  addOntologySort,
  clearOntologyObjectsData,
  addSectionFilters,
  getOntologySections,
  clearOntologySectionsData,
  addFieldgroupFilters,
  getOntologyFieldGroups,
  clearOntologyFieldGroupData,
  addFieldFilters,
  getOntologyFields,
  clearOntologyFieldData,
  addObjectColumnHidden,
  addSectionColumnHidden,
  addFieldGroupColumnHidden,
  addFieldColumnHidden,
};

const mapStateToProps = (state) => {
  const { ontologyActiveTab } = state.activeTabReducer;
  const {
    objects,
    objectFilters,
    objectSort,
    objectsHidden,
    sectionHidden,
    fieldGroupsHidden,
    fieldsHidden,
  } = state.ontologyReducer;
  const { sections, sectionFilters, sectionSort } = state.ontologyReducer;
  const { fieldgroups, fieldgroupFilters, fieldgroupSort } =
    state.ontologyReducer;
  const { fields, fieldsFilters, fieldSort } = state.ontologyReducer;
  return {
    objectsHidden,
    sectionHidden,
    fieldGroupsHidden,
    fieldsHidden,
    ontologyActiveTab,
    objectFilters,
    objectSort,
    loadingObject: objects.loading,
    dataObject: objects.data,
    errorObject: objects.error,
    _totalcountObject: objects._totalcount,
    sectionFilters,
    sectionSort,
    loadingSection: sections.loading,
    dataSection: sections.data,
    errorsection: sections.error,
    _totalcountSection: sections._totalcount,
    fieldgroupFilters,
    fieldgroupSort,
    loadingFieldGroup: fieldgroups.loading,
    dataFieldGroup: fieldgroups.data,
    errorFieldGroup: fieldgroups.error,
    _totalcountFieldGroup: fieldgroups._totalcount,
    fieldsFilters,
    fieldSort,
    loadingField: fields.loading,
    dataField: fields.data,
    errorField: fields.error,
    _totalcountField: fields._totalcount,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OntologyListScreen);
