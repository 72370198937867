import layoutStyles from "../../../styles/commonStyles/Layout";
import { v4 as uuidv4 } from "uuid";
import CustomTable from "../../atoms/cutom-components/CustomTable";
import {
  deleteComponent,
  editComponent,
  editUiApplication,
  removeLinkedUiApplicationId,
} from "../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import CustomComponent from "../../atoms/cutom-components/CustomComponent";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../node_modules/react-resizable/css/styles.css";
import Form from "../../atoms/form/Form";
import { componentBuilder } from "../../../utils/PlaygroundHelper";
import CustomToggle from "../../atoms/cutom-components/CustomToggle";
import CustomLink from "../../atoms/cutom-components/CustomLink";
import CustomButton from "../../atoms/cutom-components/CustomButton";
import CustomMenu from "../../atoms/cutom-components/CustomMenu";
import CustomDetails from "../../atoms/cutom-components/CustomDetails";
import CustomDatePicker from "../../atoms/cutom-components/CustomDatePicker";
import useGetData from "../../../hooks/useGetData";
import TextAreaComponent from "../TextAreaComp/TextAreaComponent";
import CustomText from "../../atoms/cutom-components/CustomText";
import SelectComponent from "../SelectComp/SelectComponent";
import InputComponent from "../InputComp/InputComponent";
import CheckboxComponent from "../CheckboxComp/CheckboxComponent";
import CustomImage from "../../atoms/cutom-components/CustomImage";
import CustomHeading from "../../atoms/cutom-components/CustomHeading";
import CustomRadio from "../../atoms/cutom-components/CustomRadio";
import CustomTree from "../../atoms/cutom-components/CustomTree";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { useSnackbar } from "notistack";
import { setUiApplicationListData } from "../../../../redux/actions/uiApplicationListAction";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../atoms/loader/Loader";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Playgroud = ({ expandCanvas }) => {
  const styles = layoutStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [OnLayoutFunc, setOnLayoutFunc] = useState(false);
  const apiService = new UiApplicationService();
  const params = useParams();
  const { currentProject, currentPage, currentAction, currentComponent } =
    useGetData();
  let layouts = { lg: currentPage?.components };
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const compReqApps = ["Menu", "Button", "Link"];
  const resize_reposition_comps = [
    "Text",
    "Button",
    "Heading",
    "Link",
    "Input",
    "DatePicker",
    "Select",
    "Toggle",
    "TextArea",
    "Radio",
    "Form",
    "Details",
    "CheckBox",
    "Image",
  ];
  const fetchUiApplicationForDropDown = async () => {
    const resp = await apiService.getApplicationsforPage();
    dispatch(setUiApplicationListData(resp?.data));
  };

  useEffect(() => {
    if (firstRender) setTimeout(() => setFirstRender(false), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAllComponents = async () => {
      setLoading(true);
      const resp = await apiService.fetchAllComponents(
        currentProject?.id,
        currentPage?.name
      );
      const compArr = resp?.data?.data ? resp?.data?.data : [];
      if (resp.status < 210 && compArr.length) {
        dispatch(
          editComponent({
            pageid: currentPage.id,
            data: [...compArr].reverse(),
          })
        );
        compArr.some((comp) => compReqApps.includes(comp?.type))
          ? fetchUiApplicationForDropDown()
          : "";
      }
      setLoading(false);
    };
    fetchAllComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page]);

  useEffect(() => {
    setOnLayoutFunc(false);
    rePositionImageComps();
    setOnLayoutFunc(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.canvasImageMode]);

  const rePositionImageComps = () => {
    const components =
      currentPage?.components &&
      currentPage?.components?.map((comp) => {
        if (resize_reposition_comps.includes(comp?.type)) {
          let coordinates = {};
          if (currentProject?.canvasImageMode === "Mobile") {
            coordinates = {
              x: comp?.moblie_X !== undefined ? comp?.moblie_X : comp?.x,
              y: comp?.moblie_Y !== undefined ? comp?.moblie_Y : comp?.y,
              w: comp?.moblie_W !== undefined ? comp?.moblie_W : comp?.w,
              h: comp?.moblie_H !== undefined ? comp?.moblie_H : comp?.h,
            };
          } else if (currentProject?.canvasImageMode === "Tablet") {
            coordinates = {
              x: comp?.tablet_X !== undefined ? comp?.tablet_X : comp?.x,
              y: comp?.tablet_Y !== undefined ? comp?.tablet_Y : comp?.y,
              w: comp?.tablet_W !== undefined ? comp?.tablet_W : comp?.w,
              h: comp?.tablet_H !== undefined ? comp?.tablet_H : comp?.h,
            };
          } else {
            coordinates = {
              x: comp?.web_X !== undefined ? comp?.web_X : comp?.x,
              y: comp?.web_Y !== undefined ? comp?.web_Y : comp?.y,
              w: comp?.web_W !== undefined ? comp?.web_W : comp?.w,
              h: comp?.web_H !== undefined ? comp?.web_H : comp?.h,
            };
          }
          return {
            ...comp,
            ...coordinates,
          };
        } else return comp;
      });
    layouts = { lg: [...components] };
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const getComponent = (type, id) => {
    switch (type) {
      case "Table":
        return <CustomTable id={id} />;
      case "Details":
        return <CustomDetails id={id} project={currentProject} />;
      case "Text":
        return <CustomText id={id} project={currentProject} type={type} />;
      case "Button":
        return <CustomButton id={id} />;
      case "Form":
        return <Form id={id} project={currentProject} />;
      case "TextArea":
        return <TextAreaComponent id={id} project={currentProject} />;
      case "Select":
        return <SelectComponent id={id} project={currentProject} />;
      case "Heading":
        return <CustomHeading id={id} />;
      case "Link":
        return <CustomLink id={id} />;
      case "Input":
        return <InputComponent id={id} project={currentProject} type={type} />;
      case "Custom Component":
        return <CustomComponent id={id} />;
      case "Menu":
        return <CustomMenu setIsMenuOpen={setIsMenuOpen} id={id} />;
      case "Toggle":
        return <CustomToggle id={id} />;
      case "DatePicker":
        return (
          <CustomDatePicker id={id} project={currentProject} type={type} />
        );
      case "Image":
        return <CustomImage id={id} />;
      case "CheckBox":
        return <CheckboxComponent id={id} />;
      case "Radio":
        return <CustomRadio id={id} />;
      case "Tree":
        return <CustomTree id={id} />;
      default:
        break;
    }
  };

  const handleDeleteComponent = async (e, clickedComp) => {
    if (currentComponent?._id === clickedComp?._id) {
      e.stopPropagation();
      const resp = await apiService?.deleteComponent(
        currentProject?.id,
        currentPage?.name,
        currentComponent?._id
      );
      if (resp?.status < 210) {
        if (
          currentComponent.type === "Button" ||
          currentComponent.type === "Link"
        )
          dispatch(removeLinkedUiApplicationId(currentComponent?._id));
        else if (currentComponent.type === "Menu") {
          currentComponent?.options.forEach((menuLevel1) => {
            if (menuLevel1.linkedUiApp?.id !== undefined) {
              dispatch(removeLinkedUiApplicationId(menuLevel1?.id));
            }
            menuLevel1?.children?.forEach((menuLevel2) => {
              if (menuLevel2.linkedUiApp?.id !== undefined) {
                dispatch(removeLinkedUiApplicationId(menuLevel2?.id));
              }
              menuLevel2?.submenuChildren?.forEach((menuLevel3) => {
                if (menuLevel3.linkedUiApp?.id !== undefined) {
                  dispatch(removeLinkedUiApplicationId(menuLevel3?.id));
                }
              });
            });
          });
        }
        if (
          compReqApps.includes(currentComponent?.type) &&
          currentProject?.linkedUiApplications !== undefined
        ) {
          const _uiAppResp = await apiService?.editApplication(
            currentProject?._id,
            currentProject
          );
        }
        dispatch(
          deleteComponent({
            pageid: currentPage?.id,
            component: currentComponent?._id,
          })
        );
        enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "Failed to delete component",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "Failed to delete component",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(`Please select the component to delete`, {
        variant: "error",
      });
    }
  };

  const itemCallback = (layout, oldItem, newItem, placeholder, e, element) =>
    onLayoutChange(layout);

  const onLayoutChange = (layout) => {
    if (layout.length > 0 && OnLayoutFunc) {
      rePositionImageComps();
      const components =
        currentPage?.components &&
        currentPage?.components?.map((comp) => {
          const foundComp = !firstRender
            ? layout.find((l) => l?.i === comp?.id)
            : layouts.lg.find((l) => l?.i === comp?.id);
          let imgRatio = 0;
          if (comp?.type === "Image") {
            const Images = document.querySelectorAll("#Image");
            Images?.forEach((ImageComp) => {
              if (
                ImageComp.getAttribute("data-id") === comp?.id &&
                comp?.type === "Image"
              )
                imgRatio = ImageComp?.clientHeight / ImageComp?.clientWidth;
            });
          }
          if (resize_reposition_comps.includes(comp?.type)) {
            let coordinates = {};
            if (currentProject?.canvasImageMode === "Tablet") {
              coordinates = {
                tablet_X: foundComp?.x,
                tablet_Y: foundComp?.y,
                tablet_W: foundComp?.w,
                tablet_H: foundComp?.h,
              };
            } else if (currentProject?.canvasImageMode === "Mobile") {
              coordinates = {
                moblie_X: foundComp?.x,
                moblie_Y: foundComp?.y,
                moblie_W: foundComp?.w,
                moblie_H: foundComp?.h,
              };
            } else {
              coordinates = {
                web_X: foundComp?.x,
                web_Y: foundComp?.y,
                web_W: foundComp?.w,
                web_H: foundComp?.h,
              };
            }
            return foundComp
              ? comp?.type === "Image"
                ? {
                    ...comp,
                    ...coordinates,
                    x: foundComp?.x,
                    y: foundComp?.y,
                    w: foundComp?.w,
                    h: foundComp?.h,
                    imgRatio: imgRatio,
                  }
                : {
                    ...comp,
                    ...coordinates,
                    x: foundComp?.x,
                    y: foundComp?.y,
                    w: foundComp?.w,
                    h: foundComp?.h,
                  }
              : comp;
          }
          return foundComp
            ? {
                ...comp,
                x: foundComp?.x,
                y: foundComp?.y,
                w: foundComp?.w,
                h: foundComp?.h,
              }
            : comp;
        });
      dispatch(
        editComponent({
          pageid: currentPage?.id,
          data: components,
        })
      );
    }
  };

  const onDrop = async (layout, layoutItem, _event) => {
    let data = _event?.dataTransfer.getData("component");
    const allComponents = [];
    currentProject?.pages?.forEach((page) =>
      page?.components?.forEach((comp) => allComponents.push(comp))
    );
    const name = `${data.toLowerCase()}${Math.floor(Math.random() * 999 + 1)}`;
    if (
      compReqApps.includes(data) &&
      currentPage?.components !== undefined &&
      !currentPage?.components.some((comp) => compReqApps.includes(comp?.type))
    )
      fetchUiApplicationForDropDown();
    const addNewComp = componentBuilder(
      data,
      name,
      uuidv4(),
      layoutItem,
      currentAction,
      currentProject.globalCss,
      currentProject
    );

    try {
      const resp = await apiService?.addNewComponent(
        currentProject?.id,
        currentPage?.name,
        addNewComp
      );
      if (resp.status < 210) {
        const components = [
          ...currentPage?.components,
          {
            ...addNewComp,
            _id: resp?.data?.data?._id,
            pagename: currentPage?.name,
          },
        ];
        dispatch(
          editComponent({
            pageid: currentPage?.id,
            data: components,
          })
        );
      } else {
        enqueueSnackbar("Failed to add Component", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?._msg ?? "Failed to add Component", {
        variant: "error",
      });
    }
  };

  const handleClickComponent = (id) => {
    if (id !== currentComponent?.id) {
      const components = currentPage?.components.map((component) => ({
        ...component,
        isSelected: component?.id === id,
      }));
      dispatch(
        editComponent({
          pageid: currentPage?.id,
          data: components,
        })
      );
    }
  };

  return (
    <div
      className={styles?.playgroud}
      style={{
        transform: `translate(-${expandCanvas ? 240 : 0}px, 0px)`,
        width: `calc(100vw - ${!expandCanvas ? 529 : 290}px)`,
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      {loading && <Loader />}
      <ResponsiveReactGridLayout
        className="layout"
        layouts={layouts}
        margin={[10, 14]}
        compactType={null}
        breakpoints={{
          lg: "100%",
          md: "100%",
          sm: "100%",
          xs: "100%",
          xxs: "100%",
        }}
        onLayoutChange={onLayoutChange}
        verticalCompact={false}
        onDragStop={itemCallback}
        onResizeStop={itemCallback}
        onDrop={onDrop}
        isDroppable={true}
        containerPadding={[1, 1]}
        allowOverlap={true}
        cols={{ lg: 600, md: 500, sm: 300, xs: 200, xxs: 100 }}
        preventCollision={false}
        rowHeight={1}
      >
        {currentPage?.components &&
          currentPage?.components.map((comp) => (
            <div
              className={`draggable ${comp?.type !== "Table" && "comp"} ${
                comp?.isSelected && "active"
              }`}
              data-id={comp?.id}
              key={comp?.i}
              id={comp?.type}
              style={{
                border: `${
                  comp?.type === "Table" && comp?.border
                    ? (comp?.borderWidth ? comp?.borderWidth.toString() : "1") +
                      "px solid " +
                      comp?.status
                    : "1px solid #F5F5F5"
                }`,
                zIndex: comp?.type === "Menu" && isMenuOpen ? 1 : "",
              }}
              onClick={() => handleClickComponent(comp?.id)}
            >
              <div
                className={`${styles?.compHeader} ${
                  currentComponent && comp?.id === currentComponent?.id
                    ? "currentComp"
                    : "notCurrentComp"
                }`}
                style={{ width: "100%" }}
              >
                <h1 className={styles?.compTitle}>
                  {comp?.type ? comp?.type : ""}
                </h1>
                <Tooltip title={`Delete ${comp?.type}`}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    style={{ marginLeft: 0, marginTop: 2 }}
                    onClick={(e) => handleDeleteComponent(e, comp)}
                  >
                    <img src="/img/delete-bin-7-line.svg" alt="Delete" />
                  </IconButton>
                </Tooltip>
              </div>
              {comp?.condition?.dataValue !== "false" &&
                getComponent(comp?.type, comp?.id)}
            </div>
          ))}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default Playgroud;
