import React, { useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableSelection,
  PagingPanel,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { ProjectServices } from "../../../services/projectServices";
import { getProjectList } from "../../../redux/actions/projectAction";
import { ServiceGroupsAPIServices } from "../../../services/serviceGroups";
import { getServicesList } from "../../../redux/actions/serviceAction";
import { deleteProject } from "../../../utils/project/projectUtils";
import { deleteServiceGroup } from "../../../utils/project/servicegroupUtils";
import {
  deleteMicroService,
  deleteVersion,
  deleteAPI,
} from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E2E2E3",
    borderRadius: "unset",
    boxShadow: "unset",
  },
  myDevTable: {
    // "& table": {
    //     tableLayout: 'auto'
    // },
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      // background: "#F9FAFB 0% 0% no-repeat padding-box",
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        // font: 'normal normal 600 16px/22px Nunito Sans',
        textTransform: "capitalize",
        fontWeight: "500",
        padding: "15px 20px 15px 30px !important",

        "& span": {
          font: "normal normal 500 16px/22px Nunito Sans",
          color: "#FFFFFF",
        },

        "& svg": {
          color: "#FFFFFF !important",
        },

        "& > div:last-child div": {
          backgroundColor: "#FFFFFF !important",
        },

        "&:nth-last-child(2) .MuiTableSortLabel-root": {
          position: "relative",
          right: 30,
        },
      },
    },
    "& tbody": {
      "& tr td": {
        padding: "8px 20px 8px 30px !important",
        font: "normal normal normal 14px/19px Nunito",
        color: "#000000",
      },
      "& tr:hover": {
        background: "#F9FAFB 0% 0% no-repeat padding-box",
        cursor: "pointer",

        "& td span": {
          visibility: "visible",
        },
      },
    },

    // For Toolbar
    "& div > div:first-child": {
      borderBottom: "unset",
      minHeight: 0,

      "& > button": {
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 501,
        color: "#FFFFFF",
        background: "#002855",
        borderRadius: 0,
        padding: "15px 15px 16px",
      },
    },

    // For pagination
    "& div div:last-child": {
      "& div:first-child span": {
        fontSize: 14,
      },

      "& div:last-child span": {
        fontSize: 14,
      },

      "& div:last-child .MuiButton-text": {
        padding: "10px 13px",
        lineHeight: 1,
        margin: "0 10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 10px #5757571A",
        border: "0.5px solid #707070",
        borderRadius: 5,
      },

      "& td span": {
        visibility: "visible",
      },
    },
  },

  actionIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    position: "relative",
    left: 35,
  },
  actionIcon: {
    padding: 8,
    margin: "0 10px",

    "& span": {
      visibility: "hidden",
    },
  },
  selectedRowsText: {
    fontSize: 14,
    position: "absolute",
    bottom: 25,
    left: 20,
  },
  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
}));

const DefaultActionCell = ({ ...restProps }) => {
  const histroy = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteItem = async ({ row, value }) => {
    if (row.projectName) {
      const { _msg, _status, data } = await deleteProject(row.id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        getProjectList();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (row.groupName) {
      const { _msg, _status, data } = await deleteServiceGroup(
        restProps.value,
        row.id
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        getProjectList();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (row.serviceName) {
      const { _msg, _status, data } = await deleteMicroService(row.id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        getProjectList();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (row.versionTag) {
      const { _msg, _status, data } = await deleteVersion(
        restProps.value,
        row.id
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        getProjectList();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (row.apiName) {
      const { _msg, _status, data } = await deleteAPI(
        restProps.value,
        restProps.row.version,
        row.id
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        getProjectList();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        <IconButton
          color="inherit"
          aria-label="delete"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleDeleteItem(restProps)}
        >
          <img src="/images/deleteLine.svg" alt="card" />
        </IconButton>
      </div>
    </VirtualTable.Cell>
  );
};

const StatusCell = ({ ...restProps }) => {
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {restProps.row.status && (
          <div
            style={{
              backgroundColor: restProps.row.status,
              height: 15,
              width: 15,
              borderRadius: "50%",
              position: "relative",
              right: 10,
            }}
          ></div>
        )}
        {restProps.row.status && (
          <span style={{ color: restProps.row.status }}>
            {restProps.row.status?.status}
          </span>
        )}
      </div>
    </VirtualTable.Cell>
  );
};
const NameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        onClick={() =>
          history.push(`${restProps.column.route}/${restProps.row._id}`)
        }
      >
        <text className={styles.application_name}>{restProps.row.name}</text>
      </div>
    </VirtualTable.Cell>
  );
};
const Cell = (props) => {
  const histroy = useHistory();
  const { column } = props;
  // Action buttons
  if (column.name === "action") {
    return <DefaultActionCell {...props} />;
  } else if (column.name === "status") {
    return <StatusCell {...props} />;
  }

  // // Go to details screen
  // if (props.row.projectName) {
  //     return <VirtualTable.Cell {...props} onClick={() => histroy.push(`/documents/project-details/${props.row.id}`)} />;
  // } else if (props.row.groupName) {
  //     return <VirtualTable.Cell {...props} onClick={() => histroy.push(`/documents/servicegroups-details/${props.row.action}/${props.row.id}`)} />;
  // } else if (props.row.serviceName) {
  //     return <VirtualTable.Cell {...props} onClick={() => histroy.push(`/documents/service-details${props.row.id}`)} />;
  // } else if (props.row.versionTag) {
  //     return <VirtualTable.Cell {...props} onClick={() => histroy.push(`/versions/details/${props.row.action}/${props.row.id}`)} />;
  // } else if (props.row.apiName) {
  //     return <VirtualTable.Cell {...props} onClick={() => histroy.push(`/api/details/${props.row.action}/${props.row.version}/${props.row.id}`)} />;
  // }
  else if (column.name === "name") {
    return <NameCell {...props} />;
  }
  return <VirtualTable.Cell {...props} />;
};

const DataModelFileTable = (props) => {
  const [pageSize, setPageSize] = useState(
    props.pageSize ? props.pageSize : 10
  );
  const [pageSizes] = useState([2, 5, 10, 15, 0]);
  const [selection, setSelection] = useState([]);
  const [defaultHiddenColumnNames] = useState(["id"]);

  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <div className={styles.myDevTable}>
        <Grid rows={props.rows} columns={props.columns}>
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          {/* <IntegratedPaging /> */}
          <PagingState
            currentPage={props.currentPage}
            onCurrentPageChange={props.setCurrentPage}
            pageSize={pageSize}
          />
          <CustomPaging totalCount={props.totalCount} />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState defaultSorting={props.defaultSorting} />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={props.height ? props.height : 370}
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
          />

          <TableColumnResizing
            defaultColumnWidths={props.defaultColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            columnExtensions={[
              { columnName: "action", togglingEnabled: false },
            ]}
          />
          <Toolbar />
          <ColumnChooser />
          {/* <TableSelection showSelectAll /> */}
          <PagingPanel pageSizes={pageSizes} className="myPagingPanel" />
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
        {/* <Typography varient="body2" className={styles.selectedRowsText}>
                    Selected Rows: {selection.length}
                </Typography> */}
      </div>
    </Paper>
  );
};

export default DataModelFileTable;
