import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../components/common/styles/InputTableStyle";
import { connect } from "react-redux";
import {
  addClusterServiceHidden,
  addClusterServiceSort,
  fetchAllClusterPods,
} from "../../redux/actions/clusterActions";
import { fetchClusterServices } from "../../utils/clusterutils/clusterUtils";
import {
  add_cluster_replica_sets_list,
  add_cluster_services_list,
} from "../../redux/actions/clusterActions";
import listStyles from "../CommonStyles/listScreenStyles";
import { Pagination } from "@material-ui/lab";
import Loader from "../../components/common/stuff/Loader";
import ClusterServiceListTable from "../../components/common/Tables/ClusterServiceListTable";
import {
  clusterServiceColumns,
  clusterServiceDefaultColumnWidths,
  clusterServiceTableColumnExtensions,
  clusterServiceColumnOrder,
  clusterServiceDefaultSorting,
  clusterServiceColumnHidden,
} from "../../constants/clusterServiceConstants";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../utils/common";
import moment from "moment";
import NewDevExpressTable from "../../components/common/Tables/ClusterRelatedTable";
const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "47px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function ClusterServicesPreview({
  container,
  add_cluster_services_list,
  datainput,
  clusterName,
  loading,
  configName,
  clusterServiceSort,
  addClusterServiceSort,
  addClusterServiceHidden,
  clusterServiceHidden,
}) {
  const [tableData, setTableData] = useState({
    columns: clusterServiceColumns,
    widths: clusterServiceDefaultColumnWidths,
    extentions: clusterServiceTableColumnExtensions,
    orders: clusterServiceColumnOrder,
    hides: [],
  });
  const tStyles = tempStyles();
  // const styles = useStyles();
  const styles = listStyles();
  const [filterContainer, setFilterContainer] = useState();
  const [currentPageCluster, setCurrentPageCluster] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalCountServices, setTotalCountServices] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchAllClusterServices = async () => {
      const { data, _status, _msg, _totalcount } = await fetchClusterServices(
        configName,
        clusterName,
        currentPageCluster
      );
      setTotalCountServices(_totalcount);
      if (_status === 200) {
        let serviceData = [];
        data?.map((item) => {
          item.metadata["creationTimestamp"] = moment(
            item?.metadata?.creationTimestamp
          ).fromNow();
        });

        setFilterContainer(data ? data : []);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchAllClusterServices();
  }, [currentPageCluster, datainput]);

  return (
    <div style={{margin:"-14px -16px" }} >
      <div className={tStyles.container}>
        <Paper>
          {tableLoading ? (
            <Loader />
          ) : (
            // <ClusterServiceListTable
            //   columns={tableData.columns}
            //   rows={filterContainer ? filterContainer : []}
            //   defaultColumnWidths={clusterServiceDefaultColumnWidths}
            //   tableColumnExtensions={tableData.extentions}
            //   columnOrder={tableData.orders}
            //   loading={loading}
            //   currentPage={currentPageCluster}
            //   setCurrentPage={setCurrentPageCluster}
            //   sort={clusterServiceSort}
            //   setSort={addClusterServiceSort}
            //   windowDimensions={windowDimensions}
            //   hidden={clusterServiceHidden}
            //   setHidden={addClusterServiceHidden}
            //   noDataMsg="No Services Found"

            // />
            <NewDevExpressTable
              columns={clusterServiceColumns}
              rows={filterContainer ? filterContainer : []}
              windowDimensions={windowDimensions}
              defaultColumnWidths={clusterServiceDefaultColumnWidths}
              tableColumnExtensions={clusterServiceTableColumnExtensions}
              // columnOrder={clusterDeploymentColumnOrder}
              hidden={clusterServiceHidden}
              noDataMsg="No Pods Found"
              // addClusterPodsHidden={addClusterPodsHidden}
              setHidden={addClusterServiceHidden}
            />
          )}
        </Paper>
        {totalCountServices > 0 ? (
          <div style={{ marginTop: "25px" }}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(totalCountServices / 10)}
                page={currentPageCluster}
                onChange={(e, val) => setCurrentPageCluster(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  fetchAllClusterPods,
  add_cluster_services_list,
  addClusterServiceSort,
  addClusterServiceHidden,
};

const mapStateToProps = (state) => {
  const { container } = state.deploymentProcessReducer.dependenciesData;
  const { deployment_type } = state.deploymentProcessReducer.appSelectionData;
  const { data, loading } = state.clusterReducer.clusterServiceList;
  const { clusterServiceSort, clusterServiceHidden } = state.clusterReducer;
  return {
    container,
    deployment_type,
    datainput: data,
    loading,
    clusterServiceSort,
    clusterServiceHidden,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterServicesPreview);
