import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// pass array which atLeast has a field called name
const MultipleSelect = ({
  name,
  value,
  onChange,
  arr,
  error,
  selectedComponent,
  ...otherProps
}) => {
  return (
    // <div>
    <FormControl style={{ width: "100%", maxHeight: "38px" }} size="small">
      <Select
        id="demo-multiple-checkbox"
        error={error}
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        name={name}
        sx={{ marginTop: 0.6 }}
        variant="outlined"
        {...otherProps}
      >
        {arr.map((val) => (
          <MenuItem
            key={val?.name}
            value={val?.title}
            style={{
              height: "2rem",
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            <Checkbox checked={value.indexOf(val?.title) > -1} />
            <ListItemText
              style={{
                fontSize: selectedComponent?.fontSize
                  ? selectedComponent?.fontSize
                  : 16,
              }}
              primary={val?.title}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    // </div>
  );
};

export default MultipleSelect;
