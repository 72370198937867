import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";
const apiHelper = new ApiHelper();

export class userServices {
  getUserList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/users?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getUserDetailsByID(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
    return apiHelper.get(uri);
  }
  createUser(userData) {
    const uri = `${BASE_URL}/api/v1.0.0/users`;
    return apiHelper.post(uri, userData);
  }
  editUserByID(userID, EdituserData) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
    return apiHelper.put(uri, EdituserData);
  }
  fetchRoles(filter) {
    const queryParam = filter !== undefined ? `&filters=${filter}` : "";
    const uri = `${BASE_URL}/api/v1.0.0/roles?${queryParam}`;
    return apiHelper.get(uri);
  }
  deleteUser(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}`;
    return apiHelper.delete(uri);
  }
  getUserTeamDetails(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/users/${userID}`;
    return apiHelper.get(uri);
  }
  getUserProjectDetails(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/assigned/Project`;
    return apiHelper.get(uri);
  }
  blockUser(userID, blockData) {
   
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/block`;
    return apiHelper.put(uri, blockData);
  }
  unBlockUser(userID, blockData) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/unblock`;
    return apiHelper.put(uri, blockData);
  }
  // profile
  getUserDetails(userid, headers) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userid}`;
    return apiHelper.get(uri, headers);
  }
  getAllFavourite(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/favourites`;
    return apiHelper.get(uri);
  }
  getFavouriteByResource(userID, resource) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/favourites?filters={"resourcetype":"${resource}"}`;
    return apiHelper.get(uri);
  }
  addFavouriteInResource(userID, favouriteData) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/favourites`;
    return apiHelper.post(uri, favouriteData);
  }
  deleteFavouriteInResource(userID, resource, resourceinstance) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/favourites/resourcetype/${resource}/resourceinstance/${resourceinstance}`;
    return apiHelper.delete(uri);
  }
  getSubscribedResources(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/notifications/users/${userID}/groups`;
    return apiHelper.get(uri);
  }
  addSubscriptionInResource(email, userID, resource, resourceinstance) {
    const uri = `${BASE_URL}/api/v1.0.0/notifications/group/${resource}_${resourceinstance}/add`;
    return apiHelper.post(uri, {
      email: email,
      userid: userID,
    });
  }
  deleteUserSubscriptionInResource(userID, resource, resourceinstance) {
    const uri = `${BASE_URL}/api/v1.0.0/notifications/group/${resource}_${resourceinstance}/remove/${userID}`;
    return apiHelper.delete(uri);
  }
  AddRolesToUser(userID, body) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/roles/_add`;
    return apiHelper.post(uri, body);
  }
  RemoveRolesFromUser(userID, body) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/roles/_remove`;
    return apiHelper.post(uri, body);
  }
}
