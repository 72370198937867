import { launguageServices } from "../../services/launguageServices";
const launguageService = new launguageServices();

export const fetchLanguagesListData = async () => {
  try {
    const res_data = await launguageService.getAllLanguagesList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchLanguageDetailsByID = async (LaungID) => {
  try {
    const res_data = await launguageService.getLanguageDetailsByID(LaungID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchLanguageDependencyByID = async (LaungID) => {
  try {
    const res_data = await launguageService.getLanguageDependencyByID(LaungID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const CreateLaunguage = async (laungData) => {
  try {
    const res_data = await launguageService.CreateLaunguage(laungData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const EditLanguageByID = async (LaungID, laungData) => {
  try {
    const res_data = await launguageService.EditLanguageByID(
      LaungID,
      laungData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const EditLanguageDockerImage = async (LaungID, dockerData) => {
  try {
    const res_data = await launguageService.EditLanguageDockerImage(
      LaungID,
      dockerData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const DeleteLanguage = async (LaungID) => {
  try {
    const res_data = await launguageService.DeleteLanguage(LaungID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const BlockLanguage = async (LaungID, blockData) => {
  try {
    const res_data = await launguageService.BlockLanguage(LaungID, blockData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const UnBlockLanguage = async (LaungID, blockData) => {
  try {
    const res_data = await launguageService.UnBlockLanguage(LaungID, blockData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};