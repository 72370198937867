import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { IconButton } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";
import { connect } from "react-redux";

const CodeGround = ({
  data,
  onCodeChange,
  languageType,
  currentFileName,
  activePath,
  isExecutableFile,
}) => {
  const styles = manageScreenStyles();

  return (
    <div>
      {isExecutableFile ? (
        <div
          style={{
            height: "78vh",
            borderStyle: "solid",
            borderWidth: "1px",
            paddingTop: "50px",
            borderColor: "#E8E8E8",
            margin: "auto",
            borderRadius: "5px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <p>
            The file is not displayed in the editor because it is either binary
            or unsupported text encoding
          </p>
        </div>
      ) : (
        <>
          {currentFileName && activePath.type === "file" ? (
            <div
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#B8B8B8",
                borderRadius: "5px",
              }}
            >
              <Editor
                height="78vh" // By default, it fully fits with its parent
                language={languageType ? languageType : ""}
                onChange={onCodeChange}
                value={data}
                borderWidth="1px"
              />
            </div>
          ) : (
            <div
              style={{
                height: "78vh",
                borderStyle: "solid",
                borderWidth: "1px",
                paddingTop: "50px",
                borderColor: "#E8E8E8",
                margin: "auto",
                borderRadius: "5px",
                textAlign: "center",
                backgroundColor: "white",
              }}
            >
              <InsertEmoticonIcon />
              <p>Select file to continue editing.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { activePath } = state.codeEditorReducer;
  return {
    activePath,
  };
};

export default connect(mapStateToProps)(CodeGround);
