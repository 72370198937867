import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Table,
} from "@material-ui/core";
import { getVendorProjectDetails } from "../../../utils/vendor/vendorUtils";
import { AppButton } from "../Button";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    lineHeight: "inherit",
    "& thead th": {
      fontWeight: "500",
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: 12,
    },
    "& tbody td": {
      fontWeight: "300",
      padding: 10,
    },
    "& tbody tr:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  head: {
    borderBottom: "1px solid #E5E5E5",
  },
  footer: {
    paddingRight: 20,
  },
  no_data_text: {
    color: "blue",
    position: "relative",
    left: 250,
  },
  no_data: {
    display: "flex",
    alignItems: "center",
    height: 100,
  },
  loader: {
    position: "relative",
    left: 250,
    top: 10,
  },
  processTable: {
    height: 400,
  },
  select: {
    width: 248,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
}));

function VendorProject(props) {
  const [projectDetails, setprojectDetails] = useState();
  const classes = useStyles();
  const { open, handleClose, id } = props;
  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getVendorProjectDetails(id);
      if (_status === 200) {
        setprojectDetails(() => (data ? data : []));
      } else {
       
      }
    };
    fetchData();
  }, []);

 
  return (
    <div>
      <Dialog
        style={{ height: 600 }}
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle className={classes.head}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // border: "1px solid",
            }}
          >
            <div>
              <text style={{ font: "normal normal 600 16px/22px Nunito Sans" }}>
                Projects Assigned
              </text>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.processTable}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                style={{ width: "60%" }}
                size="medium"
              >
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell
                      style={{
                        width: 170,
                        paddingLeft: 35,
                        font: "normal normal normal 16px/22px Nunito Sans",
                      }}
                    >
                      Project Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectDetails &&
                    projectDetails.map((item) => {
                      return (
                        <div>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ paddingLeft: 35, width: 650 }}
                            >
                              {item.name}
                            </TableCell>
                          </TableRow>
                        </div>
                      );
                    })}

                  {projectDetails === undefined && (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "10px 0",
                        font: "normal normal normal 16px/22px Nunito Sans",
                      }}
                    >
                      No Data Available
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <AppButton
            buttonName="Close"
            variant="contained"
            className="btnsmall"
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VendorProject;
