import {
  ADD_TEST_EXECUTION_LIST,
  DELETE_SCRIPT_BY_ID,
  EXECUTION_FILTERS,
  EXECUTION_SORT,
  ADD_TEST_EXECUTION_DETAILS,
  ADD_TEST_EXECUTION_LIST_FILTER,
  DELETE_EXECUTION_BY_ID,
  EXECTUION_HIDDEN,
  ADD_TEST_EXECUTION_HISTORY_HIDDEN
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  testExecutionList: {
    data: [],
    error: false,
    loading: false,
  },
  testExecutionListFilter: {
    data: [],
    error: false,
    loading: false,
  },
  testExecutionDetails: {},
  testExecutionFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  testExecutionSort: [{ columnName: "createdon", direction: "desc" }],
  testExecutionHidden: [
    "name",
    "deploymentname",
    "status",
    "passpercentage",
    "action"
  ],

  testExecutionHistoryHidden: ["createdon",
    "createdby",
    "collection",
    "runduration"],
  loading: false,
};

const testExecutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEST_EXECUTION_LIST_FILTER: {
      return {
        ...state,
        testExecutionListFilter: {
          ...state.testExecutionListFilter,
          ...action.payload,
        },
      };
    }

    case EXECTUION_HIDDEN: {
      return {
        ...state,
        testExecutionHidden: [
          ...action.payload
        ]
      }
    }

    case DELETE_EXECUTION_BY_ID: {
      return {
        ...state,
        testExecutionList: {
          ...state.testExecutionList,
          data: state.testExecutionList.data.filter(
            (serv) => serv._id !== action.payload.ExecutionId
          ),
          _totalcount: state.testExecutionList._totalcount - 1,
          deleteTestExecutionStatus: true,
        },
      };
    }

    case ADD_TEST_EXECUTION_LIST: {
      return {
        ...state,
        testExecutionList: {
          ...state.testExecutionList,
          ...action.payload,
          deleteTestExecutionStatus: false,
        },
      };
    }

    // case DELETE_SCRIPT_BY_ID: {
    //   return {
    //     ...state,
    //     testExecutionList: {
    //       ...state.testExecutionList,
    //       data: state.testExecutionList.data.filter(
    //         (serv) => serv._id !== action.payload.ScriptId
    //       ),
    //       _totalcount: state.testExecutionList._totalcount - 1,

    //     },
    //   };
    // }

    case EXECUTION_FILTERS: {
      return {
        ...state,
        testExecutionFilters: [...action.payload],
      };
    }

    case EXECUTION_SORT: {
      return {
        ...state,
        testExecutionSort: [...action.payload],
      };
    }

    case ADD_TEST_EXECUTION_DETAILS: {
      return {
        ...state,
        testExecutionDetails: { ...action.payload },
      };
    }

    case ADD_TEST_EXECUTION_HISTORY_HIDDEN: {
      return {
        ...state,
        testExecutionHistoryHidden: [...action.payload],
      };
    }
    default:
      return state;
  }
};
export default testExecutionReducer;
