import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import CreateCustomerStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import moment from "moment";
const UserDetailsEntitySchemaHelper = ({
  section,
  userData,
  userteamDetails,
  userInfo,
  tabbedView
}) => {
  const styles = manageStyles();
  const classes = CreateCustomerStyles();

  const userRolesField = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: 50,
            marginTop: 50,
          }}
        >
          {userData &&
            userData?.roles?.length > 0 &&
            userData?.roles.map((item) => {
              return (
                
                <span
                  // className={classes.roles}
                  style={{
                    marginTop: 35,
                    marginRight: 15,
                    margin: "10px",
                    padding: "5px 30px",
                    color: "#000000",
                    width: 230,
                    height: 30,
                    font: "normal normal 600 14px/20px Nunito Sans",
                    border: "1px solid #707070",
                    borderRadius: 15,
                    textAlign: "center",
                    padding: 5
                  }}
                >
                  <span>{item.name}</span>
                </span>
              );
            })}
        </div>
      </>
    );
  };

  const userTeamsField = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: 50,
          }}
        >
          {userteamDetails &&
            userteamDetails.map((item) => {
              return (
                <div
                  style={{
                    width: 230,
                    height: 60,
                    background: "#F5F5F5",
                    border: "1px solid #CBCBCB",
                    borderRadius: 5,
                    // textAlign: "center",
                    alignSelf: "center",
                    margin: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "5px 0 5px 10px",
                      font: "normal normal 600 14px/19px Nunito",
                    }}
                  >
                    {item.name}
                  </p>
                  <p style={{ margin: "0 0 0 10px" }}>{item.type}</p>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              className={styles.input2}
              value={
                field.dataType === "date" && userData[field.datakey]
                  ? moment
                    .unix(userData[field.datakey])
                    .tz(userInfo?.timeZone)
                    .format(
                      userInfo?.time === "h:mm"
                        ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                        : `${userInfo?.dateFormat} ${userInfo?.time}`
                    )
                  : userData[field.datakey]
              }
              disabled
              autoComplete="off"
            />
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={userData[field.datakey]}
              className={`${styles.textarea} ${styles.editMode}`}
              rows="7"
            ></textarea>
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ""}
              className={`${styles.select} ${field.editable === "FALSE" && styles.editMode
                }`}
              inputProps={{ "aria-label": "Without label" }}
              disabled={true}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              disabled={true}
              value={userData[field.datakey] ? userData[field.datakey] : null}
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${field.editable === "FALSE" && styles.editMode
                        }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  userData[`${field.datakey}_unit`]
                    ? userData[`${field.datakey}_unit`]
                    : ""
                }
                className={`${styles.select} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Value{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${field.editable === "FALSE" && styles.editMode
                  }`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  userData[`${field.datakey}_unit`]
                    ? userData[`${field.datakey}_unit`]
                    : ""
                }
                className={`${styles.select} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                disabled={true}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Value{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${field.editable === "FALSE" && styles.editMode
                  }`}
                value={userData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={true}
              />
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={userData[field.datakey] ? userData[field.datakey] : ""}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "User_Header_section" && index !== 0 && (
                <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>
              )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "flex" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Roles":
                          return <div style={{ marginBottom: tabbedView ? 25 : 0, marginLeft: tabbedView ? 10 : "" }}>{userRolesField()}</div>;
                        case "Teams":
                          return <>{userTeamsField()}</>;
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Roles":
                                return <>{userRolesField()}</>;
                              case "Teams":
                                return <>{userTeamsField()}</>;
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      name={field.datakey}
                                      className={`${styles.input} ${field.editable === "FALSE" &&
                                        styles.editMode
                                        }`}
                                      value={userData[field.datakey]}
                                      autoComplete="off"
                                      disabled={field.editable === "FALSE"}
                                    // onChange={handleChange}
                                    />
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}{" "}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={userData[field.datakey]}
                                  // onChange={handleChange}
                                  className={`${styles.textarea} ${field.editable === "FALSE" &&
                                    styles.editMode
                                    }`}
                                  disabled={field.editable === "FALSE"}
                                  rows="7"
                                ></textarea>
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView
  };
};

export default connect(mapStateToProps)(UserDetailsEntitySchemaHelper);
