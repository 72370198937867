import React, { useState } from "react";
import layoutStyles from "../../../atoms/rightSide/trigger/ShowConditionStyles";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import JSDataInput from "../settings/JSDataInput";
import useGetData from "../../../../hooks/useGetData";
import DataInput from "../settings/DataInput";

const ShowCondition = () => {
  const styles = layoutStyles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent, getDataValue, executeJavascript } =
    useGetData();
  const [error, setError] = useState("");

  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = JSON.stringify(executeJavascript(value));
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent?.id) {
        return {
          ...component,
          condition: {
            dataInput: value,
            dataValue: dataValue,
          },
        };
      } else {
        return component;
      }
    });

    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <>
      <div className={styles.showCondition}>
          <div className="scText">
            <p className="scheading">show condition</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p className="" style={{ margin: 0, marginBottom: 5 }}>
                Condition
              </p>
              <p className="inputmode" style={{ margin: 0, marginBottom: 5 }}>
                JS Mode
              </p>
            </div>
            <p className="helptxt">
              Return false mode to hide the element based on condition
            </p>
          </div>
           <div className="field">
           <JSDataInput
              value={currentComponent?.condition}
              onChange={handleChange}
              name={"condition"}
              title={`${currentComponent?.name} : Condition`}
              error={error}
            />
           </div>
          </div>
  
    </>
  );
};

export default ShowCondition;
