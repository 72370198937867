import {
  ADD_VENDOR_LISTSCREEN_DATA,
  VENDORS_FILTER,
  VENDOR_SORT,
  BLOCK_UNBLOCK_VENDOR,
  VENDOR_HIDDEN_COULMNS,
  CLEAR_VENDOR_DATA,
  ALTER_FAVOURITE_VENDOR_BY_ID,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  vendor_list: {
    data: [],
    error: false,
    loading: false,
  },
  vendorFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  vendorSort: [{ columnName: "createdon", direction: "desc" }],
  vendorHiddenCols: [
    "name",
    "createdon",
    "status",
    "vendorProject",
    "vendorActions",
  ],
};

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_VENDOR_LISTSCREEN_DATA: {
      return {
        ...state,
        vendor_list: {
          ...state.vendor_list,
          ...action.payload,
        },
      };
    }
    case VENDORS_FILTER: {
      return {
        ...state,
        vendorFilter: [...action.payload],
      };
    }
    case VENDOR_SORT: {
      return {
        ...state,
        vendorSort: [...action.payload],
      };
    }
    case VENDOR_HIDDEN_COULMNS: {
      return {
        ...state,
        vendorHiddenCols: [...action.payload],
      };
    }
    case BLOCK_UNBLOCK_VENDOR: {
      return {
        ...state,
        vendor_list: {
          ...state.vendor_list,
          data:
            state.vendor_list.data &&
            state.vendor_list.data.map((item) => {
              if (item._id === action.payload.vendorID) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case CLEAR_VENDOR_DATA: {
      return {
        ...state,
        vendor_list: {},
      };
    }
    case ALTER_FAVOURITE_VENDOR_BY_ID: {
      return {
        ...state,
        vendor_list: {
          ...state.vendor_list,
          data: {
            ...state.vendor_list.data,
            data:
              state.vendor_list.data.data.length !== 0 &&
              state.vendor_list.data.data.map((vendor) => {
                if (vendor._id === action.payload.vendorId) {
                  return { ...vendor, isFavourite: !vendor.isFavourite };
                } else {
                  return vendor;
                }
              }),
          },
        },
      };
    }
    default:
      return state;
  }
}
