import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
import { DialogTitle, makeStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  helperText: {
    fontSize: "16px",
  },
}));

export function ForceLoginModal(props) {
  const { handleConfirm, open, handleClose } = props;
  const classes = useStyles();

  const [timer, setTimer] = useState(60);

  useEffect(() => {
    setTimer(60)
  }, [open])
  

  useEffect(() => {
    if(open){
      let tokenTimer = setInterval(() => {
        if(timer > 0){
            setTimer(timer-1);
        } else {
            handleClose('timeout')
        }
      }, 1000);
      return () => clearInterval(tokenTimer);
    }

  });

  return (
    <div>
      <Dialog
        BackdropProps={{ style: { backgroundColor: "rgb(0,0,0,.2)" } }}
        onClose={handleClose}
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="force-login-dialog" className={classes.header}>
          <p className={classes.heading}>Active Session Found</p>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              height: 200,
              width: 450,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <WarningIcon style={{ height: 75, width: 75 }} />
            </div>
            <div style={{ marginTop: 30 }}>
              <p className={classes.helperText}>
                Another login found for this user. If you continue, they'll
                be disconnected. Do you want to continue anyway?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName={`Cancel (0:${timer < 10 ? "0" : ""}${timer})`}
            variant="outlined"
          />
          <AppButton
            onClick={handleConfirm}
            className={`btnsmall`}
            buttonName={"Continue "}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
