import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { addAiTask } from "../../../utils/project/microserviceUtils";
import { fetchBoilerPlateListData } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { useSnackbar } from "notistack";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect, useSelector } from "react-redux";
import { getProjectList } from "../../../redux/actions/projectAction";
import { fetchServiceGroupListData } from "../../../utils/project/servicegroupUtils";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import CustomFieldGroupForAiTaskCreate from "../../../components/common/entitySchema/aiTask/CustomFieldGroupForAiTaskCreate";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SaveIcon from "@material-ui/icons/Save";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { Element } from "react-scroll";

const AddAiTaskScreen = (props) => {
  const {
    tabbedView,
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
  } = props;
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [boilerPlateChecked, setBoilerPlateChecked] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [projectSearchInput, setProjectSearchInput] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [serviceGroupSearchInput, setServiceGroupSearchInput] = useState("");
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [createVersion, setCreateVersion] = useState(false);
  const [createAPI, setCreateAPI] = useState(false);
  const [serviceId, setServiceId] = useState();

  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const [version, setVersion] = useState([]);
  const [apiVersions, setApiVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [statuses, setStatuses] = useState();
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const [serviceData, setServiceData] = useState({
    name: "",
    imagename: "",
    description: "",
    versionTag: "",
    versionDescription: "",
    status: "",
    languageID: "",
    initializeboilerplateID: false,
    boilerplateID: "",
    projectID: "",
    servicegroupID: "",
    datamodels: [],
  });
  const [error, setError] = useState({
    name: { isError: false },
    projectID: { isError: false },
    servicegroupID: { isError: false },
    languageID: { isError: false },
  });

  const { data, getProjectList } = props;
  const projects = data?.data && data?.data?.length > 0 ? data?.data : [];
  const search = useLocation().search;

  useEffect(() => {
    if (selectedProject._id) {
      fetchServiceGroupsFromProject();
      fetchLanguagesList();
    }
  }, [selectedProject]);

  useEffect(() => {
    const fetchStatusesByResource = async (resource, callback) => {
      const { _msg, _status, data } = await fetchStatusResourceByName(resource);
      if (_status === 200) {
        callback(data);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchStatusesByResource("AITask", (data) =>
      setStatuses(data ? data.status : [])
    );
  }, []);

  const fetchServiceGroupsFromProject = async (filters) => {
    const { _msg, _status, data } = await fetchServiceGroupListData(
      selectedProject._id,
      0,
      filters
    );
    setServiceGroups([]);
    if (_status === 200) {
      if (data) {
        setServiceGroups(data);
      } else {
        enqueueSnackbar(
          "No Service Group under this project. Please select another project",
          { variant: "info" }
        );
        setServiceGroups([]);
      }
    } else if (_status === 404) {
      enqueueSnackbar(_msg, { variant: "info" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const fetchLanguagesList = async () => {
    const { _msg, _status, data } = await fetchLanguagesByProject(
      selectedProject._id
    );
    if (_status === 200) {
      setLanguages(data ? data : []);
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const fetchStatusesByResource = async (resource, callback) => {
    const { _msg, _status, data } = await fetchStatusResourceByName(resource);
    if (_status === 200) {
      callback(data);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const { checkScreenPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("ServiceGroup", ["Create Microservice"]);
    const fetchServiceEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITask",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        console.log("jsbsknaknkaas", entity)
        setServiceEntitySchema(entity);
        setIsLoader(false);
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });
        setServiceData((prevData) => ({
          ...prevData,
          ...servData,
          serviceType: "Internal",
        }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["boilerplateID"];
        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        setIsErrorLoaded(true);
        setError(errData);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchBoilerPlateListData();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    };
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName("AITask");
      if (_status === 200) {
        setServiceData({
          ...serviceData,
          status: data?.status.find((s) => s.default === true)?.status,
          serviceType: "Internal",
        });
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchServiceEntitySchema();
    fetchBoilerPlates();
    fetchStatusesByResource();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };

  useEffect(() => {
    handleProjectSearch();
  }, [projectSearchInput]);

  const handleProjectSearch = () => {
    if (projectSearchInput) {
      getProjectList(
        0,
        JSON.stringify({ name: { $regex: projectSearchInput, $options: "i" } })
      );
    } else {
      getProjectList(0);
    }
  };

  useEffect(() => {
    if (projectSearchInput) {
      handleServiceGroupSearch();
    }
  }, [serviceGroupSearchInput]);

  const handleServiceGroupSearch = () => {
    if (serviceGroupSearchInput) {
      fetchServiceGroupsFromProject(
        JSON.stringify({
          name: { $regex: serviceGroupSearchInput, $options: "i" },
        })
      );
    } else {
      fetchServiceGroupsFromProject();
    }
  };

  const handleSubmit = async () => {
    let tempServiceData = { ...serviceData };
    if (serviceData?.serviceType === "External") {
      delete tempServiceData["imagename"];
    }

    const { formIsValid, errors } = handleFormValidationPro(
      tempServiceData,
      error
    );
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        isTabbedApproach
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const newServiceData = {
        ...tempServiceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      newServiceData["ai_task"] = true;
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
        };
      }
      setIsLoader(true);

      const { _msg, _status, data } = await addAiTask(newServiceData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);

        histroy.push("/aitask-workbench");
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      setError(errors);
    }
  };

  const handleAddVersionInCreate = async () => {
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        isTabbedApproach
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const newServiceData = {
        ...serviceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      newServiceData["ai_task"] = true;
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
        };
      }
      setIsLoader(true);

      const { _msg, _status, data } = await addAiTask(newServiceData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        setServiceId(data?._id);
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);
        if (!isTabbedApproach) {
          histroy.push({
            pathname: `/ai-task-versions/add/${data?._id}`,
            state: { serviceName: data?.name, serviceId: data?._id },
          });
        } else {
          setCreateVersion(true);
        }
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      setError(errors);
      enqueueSnackbar("Please fill the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleActiveTab = (e, newValue) => {
    // if (createVersion) {
    //   histroy.push(`/aitask/edit/${serviceId}`);
    // }
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {

    histroy.push(`/aitask-workbench`);
  };

  useEffect(() => {
    if (serviceData?.serviceType === "External") {
      let tempErr = { ...error };
      delete tempErr["imagename"];
      setError(tempErr);
    } else {
      setError({
        ...error,
        imagename: {
          dataType: "string",
          isError: false,
          pattern: "^[_a-z0-9.-]{1,50}$",
          section: 0,
          minLength: 1,
          minimum: 1,
          fieldLabel: "Image Name",
        },
      });
    }
  }, [serviceData?.serviceType, setIsErrorLoaded]);

  return (
    <main className={styles.main}>
      {isTabbedApproach ? (
        <>
          {isLoader && <Loader />}
          <form onSubmit={handleSubmit}>
            <div className={styles.topContent}>
              <BreadCrumbs
                items={[
                  {
                    title: "AI Task",
                    path: "/aitask-workbench",
                  },
                  { title: "Create AI Task" },
                ]}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ borderBottom: "2px solid #afafaf" }}
              >
                <h2 className={styles.heading}>Create AI Task</h2>
                {((!createVersion && !createAPI) || (createVersion && activeTab !== 3) || (createAPI && activeTab !== 4)) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TabbedNavigation
                      handleCancel={handleCancel}
                      isCreateMode={true}
                      handleSave={handleSubmit}
                    />
                    {/* {activeTab > 0 ? (
                    <Tooltip title="Previous" arrow>
                      <Button
                        aria-label="previous"
                        color="primary"
                        variant="outlined"
                        startIcon={
                          <>
                            <NavigateBeforeIcon
                              style={{ width: "25px", height: "23px" }}
                            />
                          </>
                        }
                        className="btnsmall"
                        onClick={handlePrevious}
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  &nbsp;&nbsp;
                  <>
                    <Tooltip title="Save" arrow>
                      <Button
                        aria-label="previous"
                        color="primary"
                        variant="outlined"
                        startIcon={
                          <SaveIcon style={{ width: "25px", height: "23px" }} />
                        }
                        className="btnsmall"
                        onClick={handleSubmit}
                      />
                    </Tooltip>
                  </>
                  &nbsp;&nbsp;
                  <Tooltip title="Cancel" arrow>
                    <Button
                      aria-label="Cancel"
                      color="primary"
                      variant="outlined"
                      startIcon={
                        <HighlightOffIcon
                          style={{ width: "25px", height: "23px" }}
                        />
                      }
                      className="btnsmall"
                      onClick={()=>histroy.push("/aitask-workbench")}
                    />
                  </Tooltip>
                  &nbsp;&nbsp;
                  {activeTab !== serviceEntitySchema.length - 1 ? (
                    <Tooltip title="Next" arrow>
                      <Button
                        aria-label="Next"
                        color="primary"
                        variant="outlined"
                        startIcon={
                          <>
                            <NavigateNextIcon
                              style={{ width: "25px", height: "23px" }}
                            />
                          </>
                        }
                        className="btnsmall"
                        onClick={handleNext}
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )} */}
                  </div>
                )}
              </Grid>
            </div>
            <div className={styles.wrapper}>
              {/* <Tabs
                value={activeTab}
                onChange={handleActiveTab}
                indicatorColor="primary"
                textColor="primary"
                className={style.documentsListAccessTab}
              >
                {serviceEntitySchema.length !== 0 &&
                  serviceEntitySchema.map((section) => {
                    return <Tab label={<>{section["section Label"]} </>} />;
                  })}
              </Tabs> */}
              <SectionTabs
                entity={serviceEntitySchema}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: "10px" }}>
                <div style={{ padding: "20px 20px" }}>
                  <CustomFieldGroupForAiTaskCreate
                    tabbedView={isTabbedApproach}
                    statuses={statuses}
                    createVersion={createVersion}
                    setCreateVersion={setCreateVersion}
                    createAPI={createAPI}
                    setCreateAPI={setCreateAPI}
                    handleAddVersionInCreate={handleAddVersionInCreate}
                    detailVersion={detailVersion}
                    detailAPI={detailAPI}
                    editAPI={editAPI}
                    serviceId={serviceId}
                    version={version}
                    selectedVersion={selectedVersion}
                    apiVersions={apiVersions}
                    section={serviceEntitySchema[activeTab]}
                    serviceData={serviceData}
                    error={error}
                    languages={languages}
                    boilerplates={boilerplates}
                    selectedProject={selectedProject}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    boilerPlateChecked={boilerPlateChecked}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                    setError={setError}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setBoilerPlateChecked={setBoilerPlateChecked}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                  />
                </div>
              </Paper>
            </div>
          </form>
        </>
      ) : (
        <>
          {isLoader && <Loader />}
          <form onSubmit={handleSubmit}>
            <div className={styles.topContent}>
              <BreadCrumbs
                items={[
                  {
                    title: "AI Task",
                    path: "/aitask-workbench",
                  },
                  { title: "Create AI Task" },
                ]}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ borderBottom: "2px solid #afafaf" }}
              >
                <h2 className={styles.heading}>Create AI Task</h2>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >

                  <AccordionNavigation
                    isCreateMode={true}
                    disabled={false}
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    isEditMode={false}
                  />
                  {/* <AppButton
                    color="primary"
                    buttonName="Create"
                    variant="contained"
                    disabled={false}
                    style={{ margin: "unset" }}
                    className="btnsmall"
                    onClick={handleSubmit}
                  /> */}
                </div>
              </Grid>
            </div>
            <div className={styles.wrapper}>
              {serviceEntitySchema &&
                serviceEntitySchema.map((section, index) => {
                  {
                  }

                  if (section.name === "AITask_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <CustomFieldGroupForAiTaskCreate
                          section={section}
                          statuses={statuses}
                          createVersion={createVersion}
                          setCreateVersion={setCreateVersion}
                          createAPI={createAPI}
                          setCreateAPI={setCreateAPI}
                          handleAddVersionInCreate={handleAddVersionInCreate}
                          detailVersion={detailVersion}
                          detailAPI={detailAPI}
                          editAPI={editAPI}
                          version={version}
                          selectedVersion={selectedVersion}
                          apiVersions={apiVersions}
                          selectedLanguage={selectedLanguage}
                          setSelectedLanguage={setSelectedLanguage}
                          tabbedView={isTabbedApproach}
                          serviceId={serviceId}
                          serviceData={serviceData}
                          error={error}
                          projectSearchInput={projectSearchInput}
                          serviceGroupSearchInput={serviceGroupSearchInput}
                          selectedProject={selectedProject}
                          projects={projects}
                          serviceGroups={serviceGroups}
                          selectedServiceGroup={selectedServiceGroup}
                          setServiceData={setServiceData}
                          handleChange={handleChange}
                          setProjectSearchInput={setProjectSearchInput}
                          setServiceGroupSearchInput={
                            setServiceGroupSearchInput
                          }
                          setSelectedProject={setSelectedProject}
                          setSelectedServiceGroup={setSelectedServiceGroup}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Element name={index}>
                        <Accordion
                          className={styles.fieldPanel}
                          defaultExpanded={true}
                          key={section._id}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ArrowDropDownRounded
                                className={styles.accordianIcon}
                              />
                            }
                          >
                            <Typography className={styles.sectionHeading}>
                              {section["section Label"]}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomFieldGroupForAiTaskCreate
                              section={section}
                              createVersion={createVersion}
                              statuses={statuses}
                              setCreateVersion={setCreateVersion}
                              createAPI={createAPI}
                              setCreateAPI={setCreateAPI}
                              handleAddVersionInCreate={
                                handleAddVersionInCreate
                              }
                              detailVersion={detailVersion}
                              detailAPI={detailAPI}
                              editAPI={editAPI}
                              version={version}
                              selectedVersion={selectedVersion}
                              apiVersions={apiVersions}
                              serviceId={serviceId}
                              serviceData={serviceData}
                              tabbedView={isTabbedApproach}
                              error={error}
                              selectedLanguage={selectedLanguage}
                              setSelectedLanguage={setSelectedLanguage}
                              languages={languages}
                              boilerplates={boilerplates}
                              selectedProject={selectedProject}
                              boilerPlateChecked={boilerPlateChecked}
                              projects={projects}
                              serviceGroups={serviceGroups}
                              selectedServiceGroup={selectedServiceGroup}
                              selectedDocuments={selectedDocuments}
                              setSelectedDocuments={setSelectedDocuments}
                              setSelectedProject={setSelectedProject}
                              setSelectedServiceGroup={setSelectedServiceGroup}
                              setError={setError}
                              setProjectSearchInput={setProjectSearchInput}
                              setServiceGroupSearchInput={
                                setServiceGroupSearchInput
                              }
                              setBoilerPlateChecked={setBoilerPlateChecked}
                              setServiceData={setServiceData}
                              handleChange={handleChange}
                              documentsError={documentsError}
                              setDocumentsError={setDocumentsError}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Element>
                    );
                  }
                })}
            </div>
          </form>
        </>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
};

const mapStateToProps = (state) => {
  const { projectsList } = state.projectReducer;
  const {
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
  } = state.aiTaskReducer;
  const { data, error, loading, _totalcount } = projectsList;
  const { tabbedView } = state.userInfoReducer;
  return {
    data,
    error,
    loading,
    _totalcount,
    tabbedView,
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAiTaskScreen);
