import { ApiHelper } from "./helper/ApiHelper";
import { TEST } from "./helper/config";

const apiHelper = new ApiHelper();

export class TestAPIServices {
  addService(testData) {
    const uri = `${TEST}/api/v1.0.0/testdata`;
    return apiHelper.post(uri, testData);
  }

  addTestScript(scriptData) {
    const uri = `${TEST}/api/v1.0.0/scripts`;
    return apiHelper.post(uri, scriptData);
  }

  fetchTestScriptById(id) {
    const uri = `${TEST}/api/v1.0.0/scripts/${id}`;
    return apiHelper.get(uri);
  }

  updateTestScriptById(id, payload) {
    const uri = `${TEST}/api/v1.0.0/scripts/${id}`;
    return apiHelper.put(uri, payload);
  }

  getAllTestDataList() {
    const uri = `${TEST}/api/v1.0.0/testdata?filters={"type":"testdata"}`;
    return apiHelper.get(uri);
  }

  getAllTestScriptList() {
    const uri = `${TEST}/api/v1.0.0/scripts`;
    return apiHelper.get(uri);
  }

  getAllTestEnvironment() {
    const uri = `${TEST}/api/v1.0.0/environments?filters={"type":"config"}`;
    return apiHelper.get(uri);
  }

  getTestEnvData(envID) {
    const uri = `${TEST}/api/v1.0.0/environments/data/${envID}`;
    return apiHelper.get(uri);
  }
}
