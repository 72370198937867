import React, { useEffect, useState } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";
import {
  getFieldGroupById,
  editFieldGroupById,
} from "../../../utils/dataStandards/fieldsUtils";
import { useSnackbar } from "notistack";
import {
  getFieldList,
  fetchAllFieldGroups,
} from "../../../utils/dataStandards/fieldsUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { AppButton } from "../../../components/common/Button";
import useDataStyles from "../../DataStandard/dataStandardSpecificStyles";
import { connect } from "react-redux";

// Entity Schema
import CustomFieldGroupsForGlossaryFieldEdit from "../../../components/common/entitySchema/fieldGroups/CustomFieldGroupsForGlossaryFieldEdit";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import listScreenStyles from "../../CommonStyles/listScreenStyles";

const EditFieldGroupService = (props) => {
  const styles = manageScreenStyles();
  const style = listScreenStyles();

  const listStyle = listStyles();
  const dataStyles = useDataStyles();
  const history = useHistory();
  const [openFieldModal, setOpenFieldModal] = useState(false);
  const [openFieldGroupModal, setOpenFieldGroupModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = useParams();
  const [searchInput, setSearchInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState({
    name: "",
    status: "",
    type: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
    status: { isError: false },
    type: { isError: false },
    description: { isError: false },
  });

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const search = useLocation().search;
  const serviceCheck = new URLSearchParams(search).get("service");
  const dataStandardCheck = new URLSearchParams(search).get("datastandards");

  const [tab, setTab] = useState(0);

  const [isAllFieldGroups, setIsAllFieldGroups] = useState(true);
  const [isAllFields, setIsAllFields] = useState(true);

  const [modalItem, setModalItem] = useState({});
  const [treeData, setTreeData] = useState([]);

  const [fieldGroupTable, setFieldGroupTable] = useState([]);
  const [fieldGroupTableOrg, setFieldGroupTableOrg] = useState([]);
  const [fieldTable, setFieldTable] = useState([]);
  const [fieldTableOrg, setFieldTableOrg] = useState([]);

  const dataStandardFieldGroups = async (selectedFieldGroups) => {
    const { _msg, _status, data } = await fetchAllFieldGroups();
    if (_status === 200) {
      const fieldGroupValues =
        (data?.length &&
          data.map((item) => {
            if (item._id === fieldsData._id) return {};
            const checkItem =
              selectedFieldGroups?.length &&
              selectedFieldGroups.findIndex(
                (selectedItem) => item["_id"] === selectedItem["_id"]
              );
            item.checked = checkItem !== -1;
            return item;
          })) ||
        [];
      setFieldGroupTable(fieldGroupValues);
      setFieldGroupTableOrg(fieldGroupValues);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const dataStandardFieldsList = async (selectedFields) => {
    const { _msg, _status, data } = await getFieldList();
    if (_status === 200) {
      const fieldValues =
        data.length &&
        data.map((item) => {
          const checkItem =
            selectedFields?.length &&
            selectedFields.findIndex(
              (selectedItem) => item["_id"] === selectedItem["_id"]
            );
          item.checked = checkItem !== -1;
          return item;
        });
      setFieldTable(fieldValues);
      setFieldTableOrg(fieldValues);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // Checkboxes
  const handleFieldCheck = (event, id) => {
    const newFieldTable = fieldTableOrg?.map((field) => {
      if (field?._id === event.target.name || field?._id === id) {
        return { ...field, checked: !field.checked };
      } else {
        return field;
      }
    });
    const newFieldTableDisplay = fieldTable?.map((field) => {
      if (field?._id === event.target.name || field?._id === id) {
        return { ...field, checked: !field.checked };
      } else {
        return field;
      }
    });
    setFieldTable(newFieldTableDisplay);
    setFieldTableOrg(newFieldTable);
  };
  const handleAllFieldCheck = (e, value) => {
    const newFieldTable = fieldTableOrg?.map((field) => {
      return { ...field, checked: value };
    });
    const newFieldTableDisplay = fieldTable?.map((field) => {
      return { ...field, checked: value };
    });
    setFieldTable(newFieldTableDisplay);
    setFieldTableOrg(newFieldTable);
  };

  const handleFieldGroupCheck = (event, id) => {
    const newFieldGroupTable = fieldGroupTableOrg?.map((fieldGroup) => {
      if (fieldGroup?._id === event.target.name || fieldGroup?._id === id) {
        return { ...fieldGroup, checked: !fieldGroup.checked };
      } else {
        return fieldGroup;
      }
    });
    const newFieldGroupTableDisplay = fieldGroupTable?.map((fieldGroup) => {
      if (fieldGroup?._id === event.target.name || fieldGroup?._id === id) {
        return { ...fieldGroup, checked: !fieldGroup.checked };
      } else {
        return fieldGroup;
      }
    });
    setFieldGroupTable(newFieldGroupTableDisplay);
    setFieldGroupTableOrg(newFieldGroupTable);
  };
  const handleAllFieldGroupCheck = (e, value) => {
    const newFieldGroupTable = fieldGroupTableOrg?.map((fieldGroup) => {
      return { ...fieldGroup, checked: value };
    });
    const newFieldGroupTableDisplay = fieldGroupTable?.map((fieldGroup) => {
      return { ...fieldGroup, checked: value };
    });
    setFieldGroupTable(newFieldGroupTableDisplay);
    setFieldGroupTableOrg(newFieldGroupTable);
  };

  const fieldCount = fieldTableOrg?.length
    ? fieldTableOrg?.filter((res) => res?.checked === true).length
    : 0;
  const fieldGroupCount = fieldGroupTableOrg?.length
    ? fieldGroupTableOrg?.filter((res) => res?.checked === true).length
    : 0;

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("GlossaryFieldGroup", ["Edit"]);
    getFieldGroupDetails();
  }, []);

  const getFieldGroupDetails = async () => {
    const { _msg, _status, data } = await getFieldGroupById(_id);
    if (_status === 200) {
      let tempArr = []
      tempArr.push({...data, expanded: true})
      setFieldsData(data);
      setTreeData(tempArr)
      if (checkCompPermission("GlossaryField", ["List All"])) {
        await dataStandardFieldsList(data?.fields);
      }
      if (checkCompPermission("GlossaryFieldGroup", ["List All"])) {
        await dataStandardFieldGroups(data?.fields);
      }
      setLoading(false);
    } else {
      setLoading(false);
      if (_msg === "Invalid Json") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [name]: {
          ...error[name],
          isError: false,
          msg: "",
        },
      });
    }
    setFieldsData({
      ...fieldsData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const fieldCheckedData = fieldTableOrg?.filter(
      (res) => res?.checked === true
    );
    const fieldGroupCheckedData = fieldGroupTableOrg?.filter(
      (res) => res?.checked === true
    );
    delete fieldsData.updatedby;
    delete fieldsData.updatedon;
    delete fieldsData.createdby;
    delete fieldsData.createdon;
    delete fieldsData._id;
    const submitData = {
      ...fieldsData,
      fields: [
        ...fieldCheckedData?.map(({ _id }) => {
          return { type: "field", id: _id };
        }),
        ...fieldGroupCheckedData?.map(({ _id }) => {
          return { type: "fieldgroup", id: _id };
        }),
      ],
    };

    var serializerData = treeData[0]

    function recursiveFunc(data){
      data.children.map((item)=>{
          console.log(item.name)
          if(item.type === 'object' || item.type === 'array'){
              recursiveFunc(item)
          } else {
              item['ftype'] = 'field'
              if(item?._id){
                item['id'] = item._id
              }
              delete item['type']
          }
      })
      return data
    }

    const readyData = recursiveFunc(serializerData)

    const { formIsValid, errors } = handleFormValidationPro(submitData, error);
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status, data } = await editFieldGroupById(_id, {...readyData, name:submitData.name, description:submitData.description});
      if (_status === 201 || _status === 200) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
          autoHideDuration: 3000,
        });
        // if (serviceCheck) {
        //   history.push("/services?service=true");
        // } else if (dataStandardCheck) {
        //   history.push("/data-standards?datastandards=true");
        // } else {
        //   history.push("/aitask-workbench");
        // }
        history.push(`/fieldgroups/details/${_id}`);
      } else {
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
      setLoading(false);
    } else {
      //  const { tempEntity, errorInTab } = TabsCommon(
      //   glossaryEditEntity ? glossaryEditEntity?.sections : [],
      //   errors,
      //   tabbedView
      // );
      // setActiveTab(errorInTab);
      // setGlossaryEditEntity({
      //   ...glossaryEditEntity,
      //   sections: tempEntity,
      // });
      setLoading(false);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
      setError(errors);
    }
  };

  const handleSearch = () => {
    const searchValue = searchInput?.toLowerCase();
    if (tab === 0) {
      if (searchInput) {
        const filterValues = fieldTableOrg.filter(({ name }) =>
          name?.toLowerCase().includes(searchValue)
        );
        setFieldTable(filterValues);
      } else {
        setFieldTable(fieldTableOrg);
      }
    }
    if (tab === 1) {
      if (searchInput) {
        const filterValues = fieldGroupTableOrg.filter(({ name }) =>
          name?.toLowerCase().includes(searchValue)
        );
        setFieldGroupTable(filterValues);
      } else {
        setFieldGroupTable(fieldGroupTableOrg);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  const [glossaryEditEntity, setGlossaryEditEntity] = useState({});

  useEffect(() => {
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryFieldGroup",
        "edit"
      );
      if (_status === 200) {
        setGlossaryEditEntity(data);
        if (data) {
          const fgData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.uiElementType === "Currency") {
                  fgData[`${field.datakey}_unit`] = "";
                  fgData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  fgData[`${field.datakey}_unit`] = "";
                  fgData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.datakey) {
                  return (fgData[field.datakey] = "");
                }
              });
            });
          });
          setFieldsData((prevData) => ({ ...prevData, ...fgData }));
          const errData = {};
          data.sections.map((section, index) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  if (field.uiElementType === "Currency") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  if (field.uiElementType === "Amount") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  return (errData[field.datakey] = initError(field, index));
                }
              });
            });
          });
          setError(errData);
          await getFieldGroupDetails();
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    fetchObjectDetails();
  }, []);
  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Payloads",
              path: serviceCheck
                ? "/services?service=true"
                : dataStandardCheck
                  ? "/data-standards?datastandards=true"
                  : "/aitask-workbench",
            },
            { title: `Edit ${fieldsData.name}` },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{`Edit ${fieldsData.name}`}</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AppButton
              className={`btnsmall`}
              // style={{ marginLeft: "20px" }}
              variant="contained"
              disabled={!checkCompPermission("GlossaryFieldGroup", ["Edit"])}
              onClick={handleSubmit}
              buttonName="Save"
            />
            <AppButton
              style={{ padding: "0", marginLeft: "20px" }}
              className={`btnsmall`}
              variant="outlined"
              onClick={() => {
                if (serviceCheck) {
                  history.push("/services?service=true");
                } else if (dataStandardCheck) {
                  history.push("/data-standards?datastandards=true");
                } else {
                  history.push("/aitask-workbench");
                }
              }}
              buttonName="Cancel"
            />
          </div>

        </Grid>
      </div>
      {/* With Entity Schema */}
      <div className={styles.wrapper} style={{ top: "6.5rem" }}>
        {glossaryEditEntity?.sections &&
          glossaryEditEntity?.sections.map((section) => {
            if (section.name === "Glossary_Fieldgroup_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldEdit
                    section={section}
                    isEditMode={true}
                    fieldGroupData={fieldsData}
                    setFieldGroupData={setFieldsData}
                    error={error}
                    handleChange={handleChange}
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    listStyle={listStyle}
                    fieldTable={fieldTable}
                    fieldGroupTable={fieldGroupTable}
                    setSearchInput={setSearchInput}
                    handleSearch={handleSearch}
                    handleAllFieldCheck={handleAllFieldCheck}
                    isAllFields={isAllFields}
                    setIsAllFields={setIsAllFields}
                    handleFieldCheck={handleFieldCheck}
                    dataStyles={dataStyles}
                    setModalItem={setModalItem}
                    setOpenFieldModal={setOpenFieldModal}
                    isAllFieldGroups={isAllFieldGroups}
                    handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                    setIsAllFieldGroups={setIsAllFieldGroups}
                    handleFieldGroupCheck={handleFieldGroupCheck}
                    setOpenFieldGroupModal={setOpenFieldGroupModal}
                    fieldCount={fieldCount}
                    fieldGroupCount={fieldGroupCount}
                    fieldTableOrg={fieldTableOrg}
                    fieldGroupTableOrg={fieldGroupTableOrg}
                    modalItem={modalItem}
                    openFieldModal={openFieldModal}
                    openFieldGroupModal={openFieldGroupModal}
                    treeData={treeData}
                    setTreeData={setTreeData}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]} {section["section Label"]==='Add Field Table Section'&&  <span style={{color:'#E22626'}}>*</span>}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldEdit
                      section={section}
                      isEditMode={true}
                      fieldGroupData={fieldsData}
                      setFieldGroupData={setFieldsData}
                      error={error}
                      handleChange={handleChange}
                      tab={tab}
                      handleChangeTab={handleChangeTab}
                      listStyle={listStyle}
                      fieldTable={fieldTable}
                      fieldGroupTable={fieldGroupTable}
                      setSearchInput={setSearchInput}
                      handleSearch={handleSearch}
                      handleAllFieldCheck={handleAllFieldCheck}
                      isAllFields={isAllFields}
                      setIsAllFields={setIsAllFields}
                      handleFieldCheck={handleFieldCheck}
                      dataStyles={dataStyles}
                      setModalItem={setModalItem}
                      setOpenFieldModal={setOpenFieldModal}
                      isAllFieldGroups={isAllFieldGroups}
                      handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                      setIsAllFieldGroups={setIsAllFieldGroups}
                      handleFieldGroupCheck={handleFieldGroupCheck}
                      setOpenFieldGroupModal={setOpenFieldGroupModal}
                      fieldCount={fieldCount}
                      fieldGroupCount={fieldGroupCount}
                      fieldTableOrg={fieldTableOrg}
                      fieldGroupTableOrg={fieldGroupTableOrg}
                      modalItem={modalItem}
                      openFieldModal={openFieldModal}
                      openFieldGroupModal={openFieldGroupModal}
                      treeData={treeData}
                      setTreeData={setTreeData}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  // const { tabbedView } = state.userDetails.userInfo;
  // return { tabbedView };
};

export default connect(mapStateToProps)(EditFieldGroupService);
