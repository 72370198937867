import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  table: {
    padding: "30px 0 50px 0",

    "& .MuiTable-root": {
      border: "1px solid #e0e0e0",
    },
    "& .MuiTableCell-head ": {
      backgroundColor: theme.palette.primary.main,
      padding: "14px 20px 14px 0px",
      color: "#FFFFFF",

      "& tr th": {
        color: "#FFFFFF",
        font: "normal normal normal 16px/22px Nunito",
        textTransform: "capitalize",
        fontWeight: "500",

        "& span": {
          font: "normal normal 5000 16px/22px Nunito Sans",
          color: "#FFFFFF",
        },

        "& svg": {
          color: "#FFFFFF !important",
        },

        "& > div:last-child div": {
          backgroundColor: "#FFFFFF !important",
        },

        "&:nth-last-child(2) .MuiTableSortLabel-root": {
          position: "relative",
          right: 30,
        },
      },
    },
    "& tbody": {
      "& .MuiTableRow-root": {
        height: 47,
        borderBottom: "1px solid #e0e0e0",
      },
      "& .MuiTableCell-root": {
        padding: 0,
      },
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito",
        color: "#000000",
      },
      "& tr:hover": {
        background: "#e0e0e0 0% 0% no-repeat padding-box",
        cursor: "pointer",

        "& td span": {
          visibility: "visible",
        },
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px 15px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #e0e0e0",

    "& .itemsCount": {
      font: "normal normal normal 14px/18px Nunito",
      color: "#262626",
    },

    "& .pagination": {
      padding: 2,
      overflow: "hidden",
      border: "1px solid #F0F0F0",
      borderRadius: 3,

      "& .Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "#FFFFFF",
      },
    },
  },
}));
