const dataModelColumns = [
    { name: "name", title: " AI Model Name", for: "fieldgroup" },
    { name: "state", title: "In Use", for: "fieldgroup" },
    { name: "createdby", title: "Created By", for: "fieldgroup" },
    { name: "createdon", title: "Created On", for: "fieldgroup" },
    { name: "actions", title: "Action", route: "/dataModel/edit", for: "fieldgroup" },
  ];
  
  const dataModelDefaultColumnWidths = [
    { columnName: "name", weightage: 2.5, width: 200 },
    { columnName: "state", weightage: 3, width: 200 },
    { columnName: "createdby", weightage: 2, width: 200 },
    { columnName: "createdon", weightage: 2, width: 200 },
    { columnName: "actions", weightage: 2.5, width: 200 },
  ];
  
  const dataModelTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "state", align: "left" },
    { columnName: "createdby", align: "left" },
    { columnName: "createdon", align: "left" },
    { columnName: "actions", align: "center", sortingEnabled: false },
  ];
  
  const dataModelColumnOrder = [
    "name",
    "state",
    "createdby",
    "createdon",
    "description",
    "updatedby",
    "updatedon",
    "actions",
  ];
  
  const dataModelColumnHidden = ["name",  "state", "createdby", "createdon", "actions"];
  
  const dataModelDefaultSorting = [];
  
  export {
    dataModelColumns,
    dataModelDefaultColumnWidths,
    dataModelTableColumnExtensions,
    dataModelColumnOrder,
    dataModelDefaultSorting,
    dataModelColumnHidden
  };
  