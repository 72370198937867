import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actionContainer: {
    backgroundColor: '#FFFFFF',
    height: 'calc(100vh - 50px) !important',
    overflow: 'auto',
  },

  text: {
    fontSize: '14px !important',
    color: '#333333DE !important',
  },
  item: {
    marginBottom: '0.5rem !important',
  },
  tab: {
    minWidth: '10px',
    marginLeft: '3px',
    fontSize: '0.8rem !important',
  },
  select: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    height: '35px',
    marginTop: 5,
    outline: 'none',

    '&::before': {
      borderBottom: 'unset',
    },

    '& .MuiSelect-select': {
      paddingLeft: 15,
    },
  },
  results: {
    // width: 'calc(100vw - 540px)',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#fff !important',
    },
  },
  listItem: {
    color: '#022953',
    padding: '2px 10px',
    cursor: 'pointer',
  },
  active: {
    background: '#DEE9F8 0% 0% no-repeat padding-box',
  },
  actionItem: {
    font: 'normal normal 600 14px/19px Nunito',
    margin: 0,
  },

  // check
  name: {
    minWidth: '100%',
  },
  endpoint: { display: 'flex', flexDirection: 'column' },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  value: {
    height: '2.3rem',
    width: '100%',
    border: '1px solid #DEDEDF',
    borderRadius: 5,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    paddingLeft: '1rem',
  },
  searchInput3: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    height: '35px',
    marginTop: '5px',
    marginBottom: 5,

    '& .MuiInput-underline': {
      padding: '0 15px',
      '&::before': {
        borderBottom: 'unset !important',
      },
    },
  },
  select: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    height: '38px',
    font: 'normal normal normal 12px/16px Nunito',

    '&::before': {
      borderBottom: 'unset !important',
    },

    '& .MuiSelect-select': {
      paddingLeft: 15,
    },
  },
  red: {
    color: '#E22626',
  },
  // Accordion
  fieldPanel: {
    marginBottom: '1rem',
    marginTop: '1rem',
    boxShadow: 'unset',
    border: 'unset',
    backgroundColor: 'transparent',
    minWidth: '100%',
    '&:before': {
      backgroundColor: 'unset',
    },

    '& .MuiAccordionSummary-root': {
      minHeight: 48,
      padding: 0,
      borderBottom: '2px solid #afafaf',

      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },

      '& .MuiIconButton-root': {
        padding: '0px 12px',

        '& .MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },

    '& .MuiAccordionDetails-root': {
      display: 'block',
    },
  },

  accordianIcon: {
    fontSize: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#000000',
  },
  sectionHeading: {
    font: 'normal normal 600 16px/19px Nunito;',
    letterSpacing: 0,
    color: '#000000',
    width: '100%',
  },
  compHeading: {
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FDFDFD 0% 0% no-repeat padding-box',
    // border: '1px solid #F0F0F0',
  },
  compTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DFDFDF',
    borderRadius: '5px',
    padding: '0 15px',
    height: '40px',
    outline: 'none',
    marginTop: 5,

    '&::before': {
      borderBottom: 'unset',
    },

    '& .MuiInput-underline::before': {
      borderBottom: 'unset',
    },
  },
}));
