import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import DevExpressTable from "../../Tables/DevExpressTable";
import { AppButton } from "../../Button";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
// import DocumentTableWithDropper from "../../Tables/DocumentTableWithDropper";
import moment from "moment";
import { connect } from "react-redux";
import LanguageChips from "../../LanguageChip";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import Loader from "../../stuff/Loader";
import useCheckPermission from "../../hooks/useCheckPermission";
import DataModelEditDropper from "../../Tables/DataModelEditDropper";

const CustomFieldGroupForDataModeEdit = ({
  section,
  isEditMode,
  serviceData,
  setServiceData,
  statuses,
  error,
  handleChange,
  documents,
  languages,
  languageDetails,
  setLanguageDetails,
  fetchDocuments,
  userInfo,
  boilerplates,
  dataModelId,
  versions,
  versionsList,
  currentVersionPage,
  setCurrentVersionPage,
  selectedVersion,
  apis,
  apisList,
  currentAPIPage,
  setCurrentAPIPage,
  currentPageServiceDocumentList,
  handleServiceDocumentListPagination,
  service_documents_list,
  versionTableLoading,
  versionTableData,
  versionHidden,
  addVersionHidden,
  versionSort,
  addVersionSort,
  apiTableData,
  apiTableLoading,
  apiSort,
  addAPISort,
  apiHidden,
  addAPIHidden,
  setSelectedVersion,
  apiVersions,
  filesArray,
  tabbedView,
  aiModelDetails
}) => {

  const styles = manageStyles();
  const histroy = useHistory();
  const { checkCompPermission } = useCheckPermission();

  const [fileDetailsRows, setFileDetailsRows] = useState([]);

  const [statusOpen, setStatusOpen] = useState(false);

  const closeDropDown = () => {
    setStatusOpen(false);
  };

  useEffect(() => {

    window.addEventListener("scroll", closeDropDown);
  }, []);

  // Tabbed View
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/aitask-workbench");
  };


  //   return (
  //     <>
  //       {isEditMode && (
  //         <>
  //           <Autocomplete
  //             open={statusOpen}
  //             onOpen={() => setStatusOpen(true)}
  //             onClose={() => setStatusOpen(false)}
  //             options={statuses}
  //             getOptionLabel={(option) => option.status}
  //             className={`${styles.searchInput2} ${
  //               error.status?.isError && styles.errorAuto
  //             }`}
  //             onChange={(e, input) => {
  //               if (input) {
  //                 setServiceData({
  //                   ...serviceData,
  //                   status: input.status,
  //                 });
  //               }
  //             }}
  //             renderInput={(params) => (
  //               <TextField
  //                 {...params}
  //                 variant="standard"
  //                 label="Search Status"
  //                 placeholder="Search Status"
  //               />
  //             )}
  //           />
  //           {error.status?.isError && (
  //             <p className={styles.errorMsg}>{"Status is Required"}</p>
  //           )}
  //         </>
  //       )}

  //       {serviceData?.status ? (
  //         <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
  //           <div
  //             style={{
  //               backgroundColor: statuses.find(
  //                 (s) => s.status === serviceData?.status
  //               )?.color,
  //               height: 15,
  //               width: 15,
  //               borderRadius: "50%",
  //               position: "relative",
  //               right: 10,
  //             }}
  //           ></div>
  //           <Typography
  //             variant="h6"
  //             className={styles.fieldText}
  //             style={{
  //               color: statuses.find((s) => s.status === serviceData?.status)
  //                 ?.color,
  //             }}
  //           >
  //             {statuses
  //               .find((s) => s.status === serviceData?.status)
  //               ?.status.charAt(0)
  //               .toUpperCase() +
  //               statuses
  //                 .find((s) => s.status === serviceData?.status)
  //                 ?.status.slice(1)}
  //           </Typography>
  //         </div>
  //       ) : (
  //         <Typography variant="h6" className={styles.fieldText}>
  //           Status is not set
  //         </Typography>
  //       )}
  //     </>
  //   );
  // };

  const documentSection = () => {
    return (
      <>
        {checkCompPermission("AIModel", ["List All", "List Assigned"]) ? (
          <div style={tabbedView ? { padding: 10 } : { marginTop: 15 }}>
            <DataModelEditDropper
              resourceId={dataModelId}
              resourceType={"microservice"}
              isEditMode={tabbedView ? !isEditMode : isEditMode}
              rows={filesArray}
              fetchDocuments={fetchDocuments}
              isDocumentModule={false}
            />
          </div>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Document List All, List Assigned. Please
            contact your Admin.
          </p>
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Microservice ProjectId") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${styles.editMode}`}
                value={
                  serviceData.projectDetails.length !== 0
                    ? serviceData.projectDetails[0]?.name
                    : ""
                }
                autoComplete="off"
                disabled
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else if (field.name === "Microservice ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${styles.editMode}`}
                value={
                  serviceData.servicegroupdetails.length !== 0
                    ? serviceData.servicegroupdetails[0]?.name
                    : ""
                }
                autoComplete="off"
                disabled
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? userInfo?.timeZone
                      ? moment
                        .unix(serviceData[field.datakey])
                        .tz(userInfo?.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                            : `${userInfo?.dateFormat} ${userInfo?.time}`
                        )
                      : moment
                        .unix(serviceData[field.datakey])
                        .format("DD/MM/YYYY, hh:mm:ss A")
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                disabled={isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={serviceData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              disabled={isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={!isEditMode || field.editable === "FALSE"}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Microservice Languageid":
                          return governanceSection();
                        case "Microservice InitializeboilerplateID":
                          return boilerPlateSection();
                        case "Microservice Status":
                          return statusSection();
                        case "Version Table":
                          return versionSection();
                        case "API Table":
                          return apiSection();
                        case "AIModel File":
                          return documentSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Microservice Languageid":
                                return governanceSection();
                              case "Microservice InitializeboilerplateID":
                                return boilerPlateSection();
                              case "Microservice Status":
                                return statusSection();
                              case "Version Table":
                                return versionSection();
                              case "API Table":
                                return apiSection();
                              case "AIModel File":
                                return documentSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;
  const { aiModelDetails } = state.dataModelReducer;
  return {
    userInfo,
    tabbedView,
    aiModelDetails
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForDataModeEdit);
