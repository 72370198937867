import React, { useState } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/common/Button/CustomButton";
import useStyles from "./commonStyles";

import { insertPassword_ } from "../../utils/authentication/authenticationUtils";
import { useEffect } from "react";

export default function SignupScreen(props) {
  const [dataObj, setDataObj] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    code: ''
  });
  const [errorObj, setErrorObj] = useState({
    email: false,
    password: false,
    confirmPassword: false
  });
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    // get data from params 
    const base64Data = params.get("data")
    const decodedData = atob(base64Data);
    const dataObj = JSON.parse(decodedData);

    // setDataObj((prevState) => ({
    //   ...prevState,
    //   email: params.get("mail") ? params.get('mail') : "",
    //   code: params.get("code") ? params.get('code') : "",
    //   userID: params.get("userid") ? params.get('userid') : "",
    //   username: params.get("username") ? params.get('username') : "",
    //   companyName: params.get("organizationname") ? params.get('organizationname') : "",
    // }))
    console.log(dataObj)
    // new code does not need userID,username, companyName @RUSHI 23 sept 2024
    setDataObj((prevState) => ({
      ...prevState,
      email: dataObj.email,
      code: dataObj.code,
    }))
  }, []);

  const handleSignupClick = async () => {
    var errorFlag = false;
    var tempError = {};
    for (var obj in dataObj) {
      if (dataObj[obj] === "") {
        errorFlag = true;
        tempError = { ...tempError, [obj]: true };
      }
    }
    if (dataObj.password !== dataObj.confirmPassword) {
      errorFlag = true;
      setErrorObj((prevState) => ({ ...prevState, confirmPassword: true }));
    }
    setErrorObj((prevState) => ({
      ...prevState,
      ...tempError,
    }));
    if (!errorFlag) {
      setLoading(true);
      const req_data = {
        password: dataObj.password,
      };
      // @RUSHI 23 sept 2024
      // Update payload for insertPassword_ API
      let finalPayload = {
        ...req_data,
        email: dataObj.email,
        code: dataObj.code,
      }
      let userDetails = {
        language: 'en',
        name: dataObj.email
      }

      try {
        const { _msg, _status } = await insertPassword_(finalPayload, userDetails);
        if (_status === 200) {
          enqueueSnackbar(_msg, { variant: "success" });
          history.push("/");
        } else {
          enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar('An unexpected error occurred. Please try again later.', { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTextChange = (key, value) => {
    setErrorObj((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setDataObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className={styles.container}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.welcome_message} style={{ marginTop: "2rem" }}>
        <text className={styles.big_text}>{`Hey ${dataObj.username},`}</text>
        <text className={styles.big_text}>
          {`Welcome to Your ${dataObj.companyName}`}
        </text>
      </div>
      <div className={styles.email}>
        <label className={styles.label}>
          Email ID<text className={styles.star}> *</text>
        </label>
        <input
          className={styles.email_text}
          style={errorObj.email ? { borderColor: "red" } : {}}
          value={dataObj.email}
          onChange={(e) => handleTextChange("email", e.target.value)}
          disabled={true}
        ></input>
        {errorObj.email && (
          <text className={styles.error_text}>Invalid Email</text>
        )}
      </div>
      <text
        className={styles.big_text}
        style={{ marginTop: 35, alignSelf: "center", marginRight: "19rem" }}
      >
        Set your password
      </text>
      <div className={styles.password}>
        <label className={styles.label}>
          Password <text className={styles.star}> *</text>
        </label>
        <input
          className={styles.password_text}
          style={errorObj.password ? { borderColor: "red" } : {}}
          value={dataObj.password}
          onChange={(e) => handleTextChange("password", e.target.value)}
          type="password"
        />
        {errorObj.password && (
          <text className={styles.error_text}>Invalid Password</text>
        )}
      </div>
      <div className={styles.password}>
        <label className={styles.label}>
          Confirm Password <text className={styles.star}> *</text>
        </label>
        <input
          className={styles.password_text}
          style={errorObj.confirmPassword ? { borderColor: "red" } : {}}
          value={dataObj.confirmPassword}
          onChange={(e) =>
            handleTextChange("confirmPassword", e.target.value)
          }
          type="password"
        />
        {errorObj.confirmPassword && (
          <text className={styles.error_text}>
            Password donot match. Please, try again
          </text>
        )}
      </div>
      <div style={{ marginTop: 37, alignSelf: "center" }}>
        <CustomButton type="long" label="Next" onClick={handleSignupClick} />
      </div>
    </div>
  );
}
