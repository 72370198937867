import { IconButton, Tooltip } from "@material-ui/core";
import layoutStyles from "../../../styles/commonStyles/Layout";
import { AppButton } from "../../atoms/button/AppButton";
import { useHistory, useLocation } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import BreadCrumbs from "../projectSetup/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { useSnackbar } from "notistack";
import moment from "moment";
import { editUiApplication } from "../../../../redux/actions/uiApplicationAction";

const TopHeader = () => {
  const styles = layoutStyles();
  const { currentProject, currentPage } = useGetData();
  const history = useHistory();
  const dispatch = useDispatch();
  const apiService = new UiApplicationService();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.userDetails);

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  const handleSave = async () => {
    try {
      const projectData = {
        _id: currentProject._id,
        updatedon: moment().unix(),
        updatedby: userInfo?.name,
        actions: JSON.stringify(currentProject.actions),
        pages: JSON.stringify(currentProject.pages),
        globalCss: JSON.stringify(currentProject?.globalCss),
        previewMode: currentProject?.previewMode,
        actionOpen: currentProject?.actionOpen,
      };
      var { data } = await apiService.editApplication(
        currentProject?._id,
        projectData
      );
      enqueueSnackbar("Ui Application Save Succesfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Ui Application Save Failed", {
        variant: "error",
      });
    }
  };

  const handleOnClickPreviewMode = async () => {
    const data = {
      ...currentProject,
      canvasImageMode: "Web",
    };
    dispatch(editUiApplication({ data }));
    history.push(
      `/ui-applications/preview/${currentProject?.id}/${currentPage?.url}/${currentProject.previewMode}`
    );
    try {
      const resp = await apiService.editApplication(currentProject?._id, {
        ...data,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div className={styles.topheader}>
      <div className={"headerContent"}>
        <BreadCrumbs
          items={[
            { title: "UI Applications", path: "/ui-applications" },
            {
              title: currentProject?.name,
              path: `/ui-applications/edit/${currentProject.id}`,
            },
            { title: "Builder" },
          ]}
        />
      </div>
      <div className={"headerContent"}>
        <h1>{currentProject?.name}</h1>
      </div>
      <div className={"headerContent"}>
        <Tooltip title="Please save the components before publishing">
          <IconButton
            color="inherit"
            edge="start"
            style={{ margin: "0 12px" }}
            onClick={() =>
              window.open(
                `/ui-applications/publish/${currentProject.id}/${currentProject?.pages?.find(
                  (page) => page?.islandingpage === true
                )?.url
                }`,
                "_blank"
              )
            }
          >
            <img src="/img/upload-line.svg" alt="Upload" />
          </IconButton>
        </Tooltip>
        <AppButton
          buttonName="Preview"
          variant="outlined"
          startIcon={<img src="/img/Preview.svg" alt="Eye" />}
          className="btnsmall"
          style={{ minWidth: 50 }}
          onClick={handleOnClickPreviewMode}
        />
      </div>
    </div>
  );
};

export default TopHeader;
