import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DetailStyles from "../../../styles/commonStyles/Components";
import Loader from "../loader/Loader";
import { useDispatch } from "react-redux";
import { AppButton } from "../button/AppButton";
import { whichCompToRender } from "../../../utils/details/detailsHelper";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";
import CreateCustomerStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import { handleFormValidation } from "../../../../utils/common";

const CustomDetails = ({ project, id }) => {
  const styles = DetailStyles();
  const classes = CreateCustomerStyles();
  const { getComponent } = useGetData();
  const currentComponent = getComponent(id);
  const { handleTrigger } = useTriggers();
  const [detailColumns, setDetailColumns] = useState([]);

  /*eslint-disable */
  const [err, setErr] = useState({});
  const [invalidData, setInvalidData] = useState(false);
  const [fieldArrQuery, setFieldArrQuery] = useState(null);
  const [multiSelect, setMultiSelect] = React.useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [uploadInPrgrs, setUploadInPrgrs] = useState(false);
  /*eslint-disable */
  const columns = currentComponent?.columns?.filter((column) => !column.isHide);

  useEffect(() => {
    if (currentComponent["onInit"]) {
      handleTrigger({
        action: currentComponent?.["onInit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  }, []);

  useEffect(() => {
    if (
      currentComponent?.data?.dataValue &&
      typeof (currentComponent?.data?.dataValue === "object")
    ) {
      if (
        Array?.isArray(currentComponent?.data?.dataValue) ||
        Object?.keys(currentComponent?.data?.dataValue).length
      ) {
        setDetailColumns(columns);
        setInvalidData(false);
      } else {
        setDetailColumns([]);
        setInvalidData(true);
      }
    }
  }, [currentComponent?.columns, currentComponent?.data?.dataValue]);

  useEffect(() => {
    try {
      let obj = {};
      detailColumns?.forEach((item) => {
        if (Array.isArray(currentComponent?.data?.dataValue)) {
          obj[item?.name] = currentComponent?.data?.dataValue[0][item?.name];
        } else {
          obj[item?.name] = currentComponent?.data?.dataValue[item?.name];
        }
      });
      setFieldArrQuery(obj);
    } catch (error) {
      console.log(error);
    }
  }, [detailColumns]);
  // query
  const handleChangeQuery = (event, col) => {
    let values = { ...fieldArrQuery };
    if (col?.type === "toggle") {
      values[event.target.name] = event.target.checked;
    } else {
      values[event.target.name] = event.target.value;
    }

    setFieldArrQuery(values);
    setErr({
      ...err,
      [event.target.name]: false,
    });
  };

  const handleChangeMultipleSelect = (event, col) => {
    const {
      target: { value, name },
    } = event;
    if (Object.keys(fieldArrQuery).find((val) => val === name)) {
      setMultiSelect(typeof value === "string" ? value.split(",") : value);
      let values = { ...fieldArrQuery };
      values[name] = typeof value === "string" ? value.split(",") : value;

      setFieldArrQuery(values);
    }
  };

  const hadleEditForm = () => {
    currentComponent.columns.map((v) => {
      if (v?.isRequired === false) {
        delete err[v?.name];
      }
    });
    const { formIsValid, errors } = handleFormValidation(fieldArrQuery, err);
    if (formIsValid) {
      handleTrigger({
        action: currentComponent?.["onsubmit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: fieldArrQuery,
      });
    } else {
      setErr({ ...err, ...errors });
    }
  };

  const handleImage = (event, col) => {
    setUploadInPrgrs(true);
    if (!event.target.files || event.target.files.length === 0) {
      setUploadInPrgrs(false);
      return;
    }

    if (event.target.files.length !== 0) {
      const docUrl = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(docUrl);
      let values = { ...fieldArrQuery };
      values[event.target.name] = docUrl;
      setFieldArrQuery(values);
      setUploadInPrgrs(false);
    }
  };

  const handleFile = (event, col) => {
    setUploadInPrgrs(true);
    if (!event.target.files || event.target.files.length === 0) {
      setUploadInPrgrs(false);
      return;
    }

    if (event.target.files.length !== 0) {
      const docUrl = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(docUrl);
      let values = { ...fieldArrQuery };
      values[event.target.name] = docUrl;
      setFieldArrQuery(values);
      setUploadInPrgrs(false);
    }
  };

  const handleClickApiCall = () => {
    if (currentComponent["onClick"]) {
      handleTrigger({
        action: currentComponent?.["onClick"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };

  const checkIsEditable = () => {
    const isEditable = currentComponent?.columns?.some(
      (col) => col.isEditable === true
    );
    return isEditable;
  };

  useEffect(() => {
    if (currentComponent?.columns?.length) {
      let tempVal = {};
      currentComponent.columns.map((v) => {
        tempVal[v?.name] = false;
      });
      setErr({ ...err, ...tempVal });
    }
  }, [currentComponent?.columns]);
  const titleText = currentComponent?.title?.dataValue;
  return (
    <>
      <div className={styles.detailContainer} onClick={handleClickApiCall}>
        <div className={styles.titleContainer}>
          <h1
            style={{
              fontFamily: currentComponent?.fontFamily,
              fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
              fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
              // fontSize: currentComponent?.fontSize
              //   ? currentComponent?.fontSize
              //   : 16,
              textDecoration: currentComponent?.style?.underline
                ? "underline"
                : undefined,
            }}
          >
            {titleText && typeof titleText === "string" ? titleText : "Title"}
          </h1>
          <div
            style={{
              marginTop: 4,
              fontSize: currentComponent?.fontSize
                ? currentComponent?.fontSize
                : 16,
            }}
          >
            {checkIsEditable() && (
              <AppButton
                buttonName="Save"
                variant="outlined"
                className="btnsmall"
                onClick={() => hadleEditForm()}
              />
            )}
          </div>
        </div>
        {detailColumns?.length > 0 ? (
          <Grid container>
            {currentComponent?.loading?.dataValue === "true" && <Loader />}
            {detailColumns?.map((col, index) =>
              whichCompToRender(
                col,
                index,
                currentComponent,
                handleChangeQuery,
                fieldArrQuery,
                detailColumns,
                multiSelect,
                handleChangeMultipleSelect,
                selectedImage,
                selectedFile,
                handleImage,
                handleFile,
                uploadError,
                classes,
                err
              )
            )}
          </Grid>
        ) : (
          <div style={{ background: "#FFFFFF" }}>
            <h1 style={{ margin: 0 }}>Invalid data</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDetails;
