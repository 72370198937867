import {
  ADD_ROLES_DATA,
  DELETE_ROLE,
  ROLE_SORT,
  ROLE_FILTERS,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  all_roles_list: {
    data: [],
    error: false,
    loading: false,
  },
  roleFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  roleSort: [{ columnName: "createdon", direction: "desc" }],
};

export default function accessmaintainanceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADD_ROLES_DATA: {
      return {
        ...state,
        all_roles_list: {
          ...state.all_roles_list,
          ...action.payload,
          deleteRoleStatus: false,
        },
      };
    }
    case ROLE_FILTERS: {
      return {
        ...state,
        roleFilters: [...action.payload],
      };
    }
    case ROLE_SORT: {
      return {
        ...state,
        roleSort: [...action.payload],
      };
    }
    case DELETE_ROLE: {
      return {
        ...state,
        all_roles_list: {
          ...state.all_roles_list,
          data: state.all_roles_list.data.filter(
            (role) => role._id !== action.payload.roleId
          ),
          _totalcount: state.all_roles_list._totalcount - 1,
          deleteRoleStatus: true,
        },
      };
    }
    default:
      return state;
  }
}
