import { CHANGE_STATUS_FAIL, CHANGE_STATUS_REQUEST, CHANGE_STATUS_SUCCESS, DELETE_STATUS_FAIL, DELETE_STATUS_REQUEST, DELETE_STATUS_SUCCESS, STATUS_DETAILS_FAIL, STATUS_DETAILS_REQUEST, STATUS_DETAILS_SUCCESS } from "../../constants/UserProfile/user"
import { userServices } from "../../services/userServices";
import { BASE_URL } from '../../services/helper/config';
import axios from 'axios'
// import { StatusProfiles } from "../../../services/statusProfiles";
import { StatusProfiles } from '../../services/statusProfiles'
const _StatusProfiles = new StatusProfiles();

// action for the status data
export const getStatusInfo = (name) => async dispatch => {
    try {
        dispatch({
            type: STATUS_DETAILS_REQUEST
        })
        const{ data:{data}}= await _StatusProfiles.fetchStatusResourceByID(name);
        dispatch({ type: STATUS_DETAILS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: STATUS_DETAILS_FAIL,
            payload: error
        })
    }
}

// action for the changing the color of the entire theme

// export const setNewStatus = ({ name, status, color, defaultS }) => async dispatch => {
//     try {
//         dispatch({
//             type: CHANGE_STATUS_REQUEST
//         })
//         const config = {
//             status,
//             color,
//             default: defaultS
//         }
//         const data = await _StatusProfiles.postResourceByName(name, config)
//         dispatch({ type: CHANGE_STATUS_SUCCESS, payload: data})
//     } catch (error) {
//         dispatch({
//             type: CHANGE_STATUS_FAIL,
//             payload: error
//         })
//     }
// }
// action for the changing the color of the entire theme
export const removeStatus = ({ name, status }) => async dispatch => {
    try {
        dispatch({
            type: DELETE_STATUS_REQUEST
        })
        const config = {
            name,
            status
        }
        const data = await _StatusProfiles.deleteResourceByName(name, config)
        dispatch({ type: DELETE_STATUS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: DELETE_STATUS_FAIL,
            payload: error
        })
    }
}
