const fieldColumns = [
    { name: "name", title: "Field Name", for: "ontology_fields_list" },
    { name: "fieldLabel", title: "Field Label", for: "ontology_fields_list" },
    { name: "uiElementType", title: "UI Element Type", for: "ontology_fields_list" },
    { name: "dataType", title: "Data Type", for: "ontology_fields_list" },
    { name: "predefined", title: "Predefined", for: "ontology_fields_list" },
    { name: "updatedon", title: "Updated On", for: "ontology_fields_list" },
    { name: "action", title: "Action", for: "ontology_fields_list" },
];

const fieldDefaultColumnWidths = [
    { columnName: "name", weightage: 1.5, width: 200 },
    { columnName: "fieldLabel", weightage: 1.5, width: 200 },
    { columnName: "uiElementType", weightage: 1.5, width: 200 },
    { columnName: "dataType", weightage: 1.5, width: 200 },
    { columnName: "predefined", weightage: 3, width: 200 },
    { columnName: "updatedon", weightage: 1.5, width: 200 },
    { columnName: "action", weightage: 1.5, width: 200 },
];

const fieldTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "fieldLabel", align: "left" },
    { columnName: "uiElementType", align: "left" },
    { columnName: "dataType", align: "left" },
    { columnName: "predefined", align: "left" },
    { columnName: "updatedon", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
];

const fieldColumnOrder = [
    "name",
    "fieldLabel",
    "uiElementType",
    "dataType",
    "predefined",
    "updatedon",
    "action",
];

const fieldDefaultSorting = []

export {
    fieldColumns,
    fieldDefaultColumnWidths,
    fieldTableColumnExtensions,
    fieldColumnOrder,
    fieldDefaultSorting,
};
