import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Paper,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
// import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import BreadCrumbs from "../../../../components/common/Breadcrumbs";
import useStyles from "../../../CommonStyles/manageScreenStyles";
import {
  add_service_data,
  add_application_data,
  emptyServiceCreateApplication,
  setApplicationDeployment,
} from "../../../../redux/actions/applicationAction";
import { connect } from "react-redux";
import { AppButton } from "../../../../components/common/Button";
import { fetchResourceByPermission } from "../../../../utils/entitySchema/entitySchemaUtils";
import CreateApplicationEntityHelper from "../../../../components/common/entitySchema/application/CreateApplicationEntityHelper";
import { handleFormValidationPro, initError } from "../../../../utils/common";
import {
  createApplication_,
  getApplicationDeploymentDetails,
  getImageList,
} from "../../../../utils/orchestration/applicationUtils";
import useCheckPermission from "../../../../components/common/hooks/useCheckPermission";
import { v4 as uuidv4 } from "uuid";
import TabbedNavigation from "../../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../../components/Tabbed/TabsCommon";

const CreateServiceBundleTabbed = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false);
  const [applicationData, setApplicationdata] = useState({});
  const [error, setError] = useState({
    name: { isError: false },
  });
  const [searchInput, setSearchInput] = useState("");
  const [applicationEntitySchema, setApplicationEntitySchema] = useState([]);
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [imagesList, setImagesList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const isInitialMount = useRef(false);

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  const [activeTab, setActiveTab] = useState(0);

  const {
    data,
    add_service_data,
    create_application,
    add_application_data,
    emptyServiceCreateApplication,
    loading,
    tabbedView,
  } = props;
  useEffect(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        if (checkCompPermission("Microservice", ["List All"])) {
          handleSearch();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchInput]
  );

  const fetchImageList = async () => {
    const { _status, _msg, data } = await getImageList();
    if (_status === 200) {
      setImagesList(data);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    fetchImageList();
  }, []);

  useEffect(() => {
    const fetchApplicationEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "ServiceBundle",
        "create"
      );
      if (_status === 200) {
        setIsLoader(false);
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setApplicationEntitySchema(entity);
        const appData = {};
        const tempError = {};
        data?.sections?.forEach((section) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup.fields.forEach((field) => {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                appData[`${field.datakey}_unit`] = "";
                appData[`${field.datakey}_value`] = "";
              } else if (field.datakey) {
                appData[field.datakey] = "";
              }
            });
          });
        });
        data?.sections?.forEach((section, index) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (field.required === "TRUE") {
                if (
                  field.uiElementType === "Currency" ||
                  field.uiElementType === "Amount"
                ) {
                  tempError[`${field.datakey}_unit`] = initError(field, index);
                  tempError[`${field.datakey}_value`] = initError(field, index);
                } else {
                  tempError[field.datakey] = initError(field, index);
                }
              }
            });
          });
        });
        delete tempError["microservices"];
        delete appData["microservices"];
        setApplicationdata({ ...applicationData, ...appData });
        setError({ ...error, ...tempError });
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    checkScreenPermission("ServiceBundle", ["Create"]);
    fetchApplicationEntitySchema();
    emptyServiceCreateApplication();
  }, []);

  function createData(service_data) {
    const {
      _id,
      description,
      name,
      imagename,
      os,
      projectDetails,
      servicegroupdetails,
      serviceType,
    } = service_data;
    const projectName =
      projectDetails.length > 0 ? projectDetails[0]["name"] : "";
    const servicegroupName =
      servicegroupdetails.length > 0 ? servicegroupdetails[0]["name"] : "";
    const created_on = service_data["Created On"];
    return {
      _id,
      description,
      name,
      projectName,
      servicegroupName,
      created_on,
      imagename: imagename ? imagename : "",
      os: os ? os : "",
      serviceType,
    };
  }

  const rows =
    data.length > 0
      ? data.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    if (event.target.name === "name" && value.includes("_")) {
      let tempValue = value
        .split("")
        .filter((item) => item !== "_")
        .join("");
      setApplicationdata({
        ...applicationData,
        [event.target.name]: tempValue,
      });
    } else {
      setApplicationdata({
        ...applicationData,
        [event.target.name]: value,
      });
    }
  };

  const handleSearch = () => {
    if (searchInput) {
      add_service_data(
        JSON.stringify({
          name: { $regex: searchInput, $options: "i" },
          // ai_task: { $ne: true },
          // service_category: { $ne: "Only GUI" },
        })
      );
    } else {
      add_service_data();
    }
  };

  const getDeploymentDetails = async () => {
    const reqData = create_application;
    if (reqData && reqData?.length > 0) {
      const { _status, data } = await getApplicationDeploymentDetails(reqData);
      var depArr = [];
      data?.forEach((obj) => {
        var temp1 = depArr.filter((d) => d.imageid === obj.imageid);
        if (temp1.length === 0) {
          depArr.push({ ...obj, type: "defaultEnv" });
        }
      });
      if (_status === 200) {
        var new_data =
          depArr && depArr.length > 0
            ? depArr.map((item) => {
              var container_name = "";
              var environment = {};
              var restart = false;
              const imgArr = item?.image?.split(":");
              const deploymentAddedArr =
                applicationData?.deployment?.services?.filter(
                  (obj) => obj.imageid === item?.imageid
                );
              if (deploymentAddedArr && deploymentAddedArr.length >= 1) {
                container_name = deploymentAddedArr[0]?.container_name
                  ? deploymentAddedArr[0]?.container_name
                  : "";
                environment = deploymentAddedArr[0]?.environment
                  ? deploymentAddedArr[0]?.environment
                  : {};
                restart = deploymentAddedArr[0]?.restart
                  ? deploymentAddedArr[0]?.restart
                  : false;
              } else if (imgArr?.length === 2) {
                container_name = "".concat(
                  applicationData?.name?.replace(/ /g, "_"),
                  imgArr[0]
                );
                environment = item.environment ? item.environment : {};
              } else {
                container_name = "".concat(
                  applicationData?.name?.replace(/ /g, "_"),
                  item?.image
                );
                environment = item?.environment ? item?.environment : {};
              }
              var tempObj = {
                ...item,
                id: uuidv4(),
                container_name: container_name,
                imagename: imgArr?.length === 2 ? imgArr[0] : "",
                imagetag: imgArr?.length === 2 ? imgArr[1] : "",
                environment: environment,
                rowInput: item?.image ? item?.image : "",
              };
              if (restart) {
                tempObj["restart"] = "always";
              }
              return tempObj;
            })
            : [];
        props.setApplicationDeployment(new_data);
        return new_data;
      } else {
        return [];
      }
    }
  };

  const handleCreateApplication = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      applicationData,
      error
    );

    const { tempEntity, errorInTab } = TabsCommon(
      applicationEntitySchema,
      errors,
      tabbedView
    );
    if (errorInTab !== undefined) {
      setActiveTab(errorInTab);
    }

    setApplicationEntitySchema(tempEntity);

    var errorFlag = false;
    if (create_application.length === 0) {
      if (errorInTab === undefined) {
        setActiveTab(1);
      }
      enqueueSnackbar(
        "Please select atleast one service and its version to proceed",
        { variant: "warning" }
      );
      errorFlag = true;
      let tempEntityApplication = applicationEntitySchema;
      tempEntityApplication[1]["isError"] = true;
      setApplicationEntitySchema(tempEntityApplication);
    } else {
      let tempEntityApplication = applicationEntitySchema;
      tempEntityApplication[1]["isError"] = false;
      setApplicationEntitySchema(tempEntityApplication);
    }
    if (formIsValid && !errorFlag) {
      const deployments = await getDeploymentDetails();
      var deploymentBody = [];
      deploymentBody =
        deployments.length > 0
          ? deployments.map((item) => {
            return {
              container_name: item.container_name,
              imageid: item.imageid,
              // image: "".concat(item.imagename ? item.imagename : "", ":", item.imagetag ? item.imagetag : ""),
              environment: item.environment,
              restart: true,
            };
            // if (item.restart) {
            //   return {
            //     container_name: item.container_name,
            //     imageid: item.imageid,
            //     // image: "".concat(item.imagename ? item.imagename : "", ":", item.imagetag ? item.imagetag : ""),
            //     environment: item.environment,
            //     restart: item.restart

            //   }
            // } else {
            //   return {
            //     container_name: item.container_name,
            //     imageid: item.imageid,
            //     // image: "".concat(item.imagename ? item.imagename : "", ":", item.imagetag ? item.imagetag : ""),
            //     environment: item.environment,

            //   }
            // }
          })
          : [];
      //Env url changing for deployment table
      const deploymentBody_updated = deploymentBody?.map((env_row) => {
        const env_obj = env_row?.environment;
        Object?.keys(env_obj)?.forEach((key) => {
          env_obj[key] = env_obj[key]?.replace(
            "//",
            `//${applicationData?.name?.replace(/\s/g, "_")}`
          );
        });
        return {
          ...env_row,
          environment: env_obj,
        };
      });

      var appData = {
        ...applicationData,
        microservices: create_application,
        deployment: {
          services: deploymentBody_updated,
        },
      };
      delete appData["services"];
      setIsLoader(true);
      const { data, _msg, _status } = await createApplication_(appData);
      if (_status === 201) {
        setIsLoader(false);
        add_application_data();
        emptyServiceCreateApplication();
        enqueueSnackbar(_msg, { variant: "success" });

        history.push(`/service-bundle`);
      } else {
        setIsLoader(false);
        // emptyServiceCreateApplication();
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      setError(errors);
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    history.push(`/service-bundle`);
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={isLoader}
        onClick={() => setIsLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Service Bundle",
              path: "/service-bundle",
            },
            { title: "Create Service Bundle" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Create Service Bundle</h2>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >

            &nbsp; &nbsp;
            <TabbedNavigation
              isCreateMode={true}
              handleSave={handleCreateApplication}
              handleCancel={handleCancel}
            />
          </div>
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {tabbedView ? (
          <>
            <SectionTabs
              entity={applicationEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20 }}>
              <div style={{ padding: "0px 5px 0" }}>
                <CreateApplicationEntityHelper
                  section={applicationEntitySchema[activeTab]}
                  applicationData={applicationData}
                  error={error}
                  setApplicationdata={setApplicationdata}
                  handleChange={handleChange}
                  rows={rows}
                  loading={loading}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  handleSearch={handleSearch}
                  imagesList={imagesList}
                  applicationName={applicationData?.name}
                />
              </div>
            </Paper>
          </>
        ) : (
          <>
            {applicationEntitySchema.length !== 0 &&
              applicationEntitySchema.map((section) => {
                if (section.name === "Service_Bundle_Header section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CreateApplicationEntityHelper
                        section={section}
                        applicationData={applicationData}
                        error={error}
                        setApplicationdata={setApplicationdata}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CreateApplicationEntityHelper
                          section={section}
                          applicationData={applicationData}
                          error={error}
                          setApplicationdata={setApplicationdata}
                          handleChange={handleChange}
                          rows={rows}
                          loading={loading}
                          searchInput={searchInput}
                          setSearchInput={setSearchInput}
                          handleSearch={handleSearch}
                          imagesList={imagesList}
                          applicationName={applicationData?.name}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
          </>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_service_data,
  add_application_data,
  emptyServiceCreateApplication,
  setApplicationDeployment,
};

const mapStateToProps = (state) => {
  const { services_list, create_application } = state.applicationReducer;
  const { data, error, loading } = services_list;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    data,
    error,
    loading,
    create_application,
    tabbedView,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateServiceBundleTabbed);
