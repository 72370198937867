import { Switch } from "@material-ui/core";

const Toggle = (props) => {
  return (
    <div >
      <Switch
        checked={props?.value}
        disabled={props?.disabled}
        onChange={props?.onChange}
        name={props?.name}
      />
    </div>
  );
};

export default Toggle;
