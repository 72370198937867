/* eslint-disable */
import React from "react";
import Button from "@material-ui/core/Button";
import buttonStyles from "../../../styles/commonStyles/Button";

export const AppButton = (p) => {
  const classes = buttonStyles();
  return (
    <div className={classes.button}>
      <Button
        className={p.className}
        variant={p.variant}
        color={p.color}
        style={p.style}
        size={p.size}
        startIcon={p.startIcon}
        endIcon={p.endIcon}
        disabled={p.disabled}
        onClick={p.onClick}
      >
        {p.buttonName}
      </Button>
    </div>
  );
};
