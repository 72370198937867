// @ts-nocheck
export const handleValidation = (data, error) => {
  let formIsValid = true;
  let errors = {};

  for (var obj in error) {
    if (data[obj]?.length === 0) {
      errors[obj] = true;
      formIsValid = false;
    } else if (
      typeof data[obj] === "string" &&
      data[obj]?.trim().length === 0
    ) {
      errors[obj] = false;
      formIsValid = true;
    }
  }

  return { formIsValid, errors };
};
