import React from "react";
import inputStyles from "./InputStyles";
import CreateCustomerStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";

const Input = ({
  name,
  label,
  value,
  onChange,
  disabled,
  inlineStyle,
  error,
  isRequired,
  selectedComponent,
  ...otherProps
}) => {
  const styles = inputStyles();
  const classes = CreateCustomerStyles();
  return (
    <div>
      <label
        style={{
          fontSize: selectedComponent?.fontSize
            ? selectedComponent?.fontSize
            : 16,
        }}
        className={classes.fieldHeading}
      >
        {label} {isRequired === true && <span className={classes.red}>*</span>}
      </label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        autoComplete="off"
        {...otherProps}
        className={`${styles.input} ${disabled && styles?.editMode} ${
          error && classes.error
        }`}
        style={{
          fontSize: selectedComponent?.fontSize
            ? selectedComponent?.fontSize
            : 16,
        }}
        disabled={disabled}
      />
      {error && (
        <p
          style={{
            fontSize: selectedComponent?.fontSize
              ? selectedComponent?.fontSize
              : 16,
          }}
          className={classes.errorMsg}
        >{`${label ? label : "Field"} is required`}</p>
      )}
    </div>
  );
};

export default Input;
