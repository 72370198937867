import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_RETAIN,
  USER_TAB_FAIL,
  USER_TAB_REQUEST,
  USER_TAB_SUCCESS,
  USER_VISIBLE_RESET,
} from "../../constants/UserProfile/user";
import { userServices } from "../../services/userServices";
import { BASE_URL } from "../../services/helper/config";
import axios from "axios";
import { UserProfile } from "../../services/userProfile";
const _UserProfile = new UserProfile();

const userService = new userServices();

// action for tabs change
export const changeTabByUser = (val) => async (dispatch) => {
  try {
    dispatch({
      type: USER_TAB_REQUEST,
    });
    dispatch({ type: USER_TAB_SUCCESS, payload: val });
  } catch (error) {
    dispatch({
      type: USER_TAB_FAIL,
      payload: error,
    });
  }
};
// action for user details
export const userDetailAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    const {
      authReducer: { userid },
    } = getState();
    const {
      data: { data },
    } = await _UserProfile.fetchDetails(userid);
    localStorage.setItem("primaryColor", data?.color ? data?.color : "#002855");
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    // dispatch({ type: USER_VISIBLE_RESET });
    dispatch({ type: USER_RETAIN });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error,
    });
  }
};
// action for the user edit
export const editUserDetails = (val) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_EDIT_REQUEST,
    });
    const {
      authReducer: { userid },
    } = getState();
    const {
      data: { data },
    } = await _UserProfile.editDetails(userid, val);
    dispatch({ type: USER_EDIT_SUCCESS, payload: data });
    dispatch({ type: USER_VISIBLE_RESET });
    await dispatch(userDetailAction());
  } catch (error) {
    dispatch({
      type: USER_EDIT_FAIL,
      payload: error,
    });
    dispatch({ type: USER_VISIBLE_RESET });
  }
};
