import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { add_service_create_application, setApplicationDeployment } from "../../../../redux/actions/applicationAction";
import { getApplicationDeploymentDetails } from '../../../../utils/orchestration/applicationUtils';
import useStyles from './Styles';
import useCheckPermission from '../../../common/hooks/useCheckPermission';

function VersionTable(props) {
  const { open, versions, service_id, isEditMode, applicationName, serviceType } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  const VersionRow = ({ item, isEditMode, applicationName }) => {
    const { _id, versionTag, createdon, createdby, port, docker, image_status } = item;
    const [checked, setChecked] = useState(false);

    const { create_application, applicationDeployment } = useSelector((state) => state.applicationReducer);
    const dispatch = useDispatch();

    useEffect(() => {
      var tempArr = create_application.filter((ele) => ele.mid === service_id && ele.vid === _id);
      if (tempArr.length === 1) {
        setChecked(true)
      } else {
        setChecked(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApplicationDeployment = async (svList, value) => {
      var updatedDeployment = applicationDeployment.filter((item) => item.type !== "defaultEnv");
      var oldDefaultEnv = applicationDeployment.filter((item) => item.type === "defaultEnv");
      if (value === 'true') {
        const { _status, data } = await getApplicationDeploymentDetails(svList);
        if (_status === 200) {
          data.forEach((item) => {
            var dataObj = oldDefaultEnv.find((dep) => dep.imageid === item.imageid);
            if (dataObj) {
              updatedDeployment.push(dataObj)
            } else {
              var container_name = "";
              var environment = {};
              const imgArr = item?.image?.split(":");
              if (imgArr?.length === 2) {
                container_name = "".concat(applicationName?.replace(/ /g, "_"), imgArr[0]);
                environment = item.environment ? item.environment : {};
              } else {
                container_name = "".concat(applicationName?.replace(/ /g, "_"), item.image);
                environment = item.environment ? item.environment : {};
              }

              if (!updatedDeployment?.some(e => e.imageid === item.imageid)) {
                updatedDeployment.push({
                  id: uuidv4(),
                  container_name: container_name,
                  imagename: imgArr?.length === 2 ? imgArr[0] : "",
                  imagetag: imgArr?.length === 2 ? imgArr[1] : "",
                  environment: environment,
                  rowInput: item.image ? item.image : "",
                  imageid: item.imageid ? item.imageid : ""
                })
              }
            }
          })
          dispatch(setApplicationDeployment(updatedDeployment));
        }
      } else if (value === 'false') {
        const { _status, data } = await getApplicationDeploymentDetails(svList);
        if (_status === 200) {
          var deployInfoIndex = applicationDeployment?.find((dep) => dep?.imageid === data[0]?.imageid);
          var newUpdatedDeployment = updatedDeployment?.filter((dep) => dep?.imageid !== deployInfoIndex?.imageid)
          if (newUpdatedDeployment) {
            dispatch(setApplicationDeployment(newUpdatedDeployment));
          }
        }

      }

    }

    const handleCheckBoxChange = async (event) => {
      // if(image_status === "build success"){
      // var flag = event.target.checked;
      // var duplicateService = create_application.filter((ele) => ele.mid === service_id);
      //   if (flag === true) {
      //     if(duplicateService.length>0){
      //       enqueueSnackbar("Cannot use two versions of same microservice",{variant : "info"})
      //     }else if(duplicateService.length===0){
      //       const obj = {
      //         mid: service_id,
      //         vid: _id,
      //         checked: true
      //       };
      //       if(isEditMode){
      //         await handleApplicationDeployment([...create_application, {mid : service_id, vid : _id}])
      //       }
      //       dispatch(add_service_create_application(obj))
      //       setChecked(flag)
      //     }
      //   } else if (flag === false) {
      //     const obj = {
      //       mid: service_id,
      //       vid: _id,
      //       checked: false
      //     }
      //     if(isEditMode){
      //       await handleApplicationDeployment(create_application.filter((item) => item.mid!==service_id, item.vid!==_id))
      //     }
      //     dispatch(add_service_create_application(obj))
      //     setChecked(flag)
      //   }
      // } else {
      //     enqueueSnackbar('Cannot select failed builds', { variant: "error" });
      // }
      if (serviceType === 'Internal') {
        if (image_status === "build success") {
          var flag = event.target.checked;
          var duplicateService = create_application.filter((ele) => ele.mid === service_id);
          if (flag === true) {
            if (duplicateService.length > 0) {
              enqueueSnackbar("Cannot use two versions of same microservice", { variant: "info" })
            } else if (duplicateService.length === 0) {
              const obj = {
                mid: service_id,
                vid: _id,
                checked: true
              };
              if (isEditMode) {
                await handleApplicationDeployment([...create_application, { mid: service_id, vid: _id }], 'true')
              }
              dispatch(add_service_create_application(obj))
              setChecked(flag)
            }
          } else if (flag === false) {
            const obj = {
              mid: service_id,
              vid: _id,
              checked: false
            }
            if (isEditMode) {
              await handleApplicationDeployment([{ mid: service_id, vid: _id }], 'false')
            }
            dispatch(add_service_create_application(obj))
            setChecked(flag)
          }
        } else {
          enqueueSnackbar('Cannot select failed builds', { variant: "error" });
        }
      } else if (serviceType === "External") {
        var flag = event.target.checked;
        var duplicateService = create_application.filter((ele) => ele.mid === service_id);
        if (flag === true) {
          if (duplicateService.length > 0) {
            enqueueSnackbar("Cannot use two versions of same microservice", { variant: "info" })
          } else if (duplicateService.length === 0) {
            const obj = {
              mid: service_id,
              vid: _id,
              checked: true
            };
            if (isEditMode) {
              await handleApplicationDeployment([...create_application, { mid: service_id, vid: _id }])
            }
            dispatch(add_service_create_application(obj))
            setChecked(flag)
          }
        } else if (flag === false) {
          const obj = {
            mid: service_id,
            vid: _id,
            checked: false
          }
          if (isEditMode) {
            await handleApplicationDeployment(create_application.filter((item) => item.mid !== service_id, item.vid !== _id))
          }
          dispatch(add_service_create_application(obj))
          setChecked(flag)
        }
      } else {
        enqueueSnackbar('Cannot select failed builds', { variant: "error" });
      }
    }
    // console.log('changes')
    // const createdBy = item["Created On"];
    return (
      <TableRow key={_id}>
        <TableCell component="th" scope="row" align="right">
          {/* <Checkbox size="small"
            color="primary"
            checked={checked}
            onChange={(event) => handleCheckBoxChange(event)} /> */}
          <input
            type="checkbox"
            checked={checked}
            onChange={(event) => handleCheckBoxChange(event)} />
        </TableCell>
        <TableCell>{versionTag}</TableCell>
        <TableCell>{serviceType === "External" ? "-" : port}</TableCell>
        <TableCell>{serviceType === "External" ? '-' : [image_status === "build success" ? "Success" : [image_status === "build failed" ? "Failed" : "Not Built"]]}</TableCell>
        <TableCell>{createdon ? moment.unix(createdon).format("DD/MM/YYYY, hh:mm:ss A") : ""}</TableCell>
        <TableCell>{createdby ? createdby : ""}</TableCell>
      </TableRow>
    )
  }
  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={6} align="left">
        <Collapse in={open}>
          <Box margin={1}>
            <Table size="small" aria-label="purchases">
              <TableHead className={styles.head}>
                <TableRow>
                  <TableCell style={{ width: "1rem", color: 'white' }} align="left"></TableCell>
                  <TableCell style={{ width: "10rem", color: 'white' }}>Version Tag</TableCell>
                  <TableCell style={{ width: "10rem", color: "white" }}>PORT</TableCell>
                  <TableCell style={{ width: "10rem", color: "white" }}>Build Status</TableCell>
                  <TableCell style={{ width: "10rem", color: "white" }}>Created On</TableCell>
                  <TableCell style={{ width: "10rem", color: "white" }}>Created By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkCompPermission("Version", ["List All"]) && versions && versions.length > 0 && versions.map((item, index) => {
                  return (
                    <VersionRow item={item} key={index} isEditMode={isEditMode} applicationName={applicationName} />
                  )
                })}
                {checkCompPermission("Version", ["List All"]) && versions && versions.length === 0 && <div className={styles.nodata}><text>{"No Version Available"}</text></div>}
                {!checkCompPermission("Version", ["List All"]) && <div className={styles.nodata}><text style={{ color: "red" }}>{"You donot have permission to view version list."}</text></div>}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

// const mapDisoatchToProps = {
//   add_service_create_application,
//   setApplicationDeployment
// }

// const mapStateToProps = (state) => {
//   const { create_application,applicationDeployment } = state.applicationReducer;
//   return {
//     create_application,
//     applicationDeployment
//   }
// }

// export default connect(mapStateToProps, mapDisoatchToProps)(VersionTable)
export default VersionTable;