const versionColumns = [
  // { name: 'id', title: 'Version ID' },
  { name: "versionTag", title: "Version Tag", for: "version" },
  { name: "port", title: "Port", for: "version" },
  { name: "fileName", title: "File Name", for: "version" },
  { name: "imageStatus", title: "Image Status", for: "version" },
  { name: "action", title: "Action", route: "/versions/edit", for: "version" },
  { name: "createdon", title: "Created On", for: "version" },
  { name: "updatedon", title: "Updated On", for: "version" },
];

const versionDefaultColumnWidths = [
  // { columnName: 'id', width: 280 },
  { columnName: "versionTag", weightage: 2.5, width: 200 },
  { columnName: "port", weightage: 4, width: 200 },
  { columnName: "fileName", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 100 },
  { columnName: "updateddon", weightage: 1.5, width: 100 },
  { columnName: "imageStatus", weightage: 3, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const versionTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "versionTag", align: "left" },
  { columnName: "port", align: "left" },
  { columnName: "fileName", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "updateddon", align: "left" },
  { columnName: "imageStatus", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const versionColumnOrder = [
  "versionTag",
  "port",
  "fileName",
  "imageStatus",
  // "action",
];

const versionColumnHidden = [
  "versionTag",
  "port",
  "fileName",
  "imageStatus",
  "action",
];

const versionDefaultSorting = [];

export {
  versionColumns,
  versionDefaultColumnWidths,
  versionTableColumnExtensions,
  versionColumnOrder,
  versionDefaultSorting,
  versionColumnHidden
};
