import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import useStyles from "../../components/common/styles/InputTableStyle"
import useStyles from "../../../components/common/styles/InputTableStyle";
import { connect } from "react-redux";

const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function DeploymentLiveStatus({
  deploymentname,
  _id,
  deploymentDetails,
  deployments,
  Status,
}) {
  const tStyles = tempStyles();
  const styles = useStyles();
  const [filterContainer, setFilterContainer] = useState();
  const [imageId, setImageId] = useState();

  useEffect(() => {
    deployments.map((item) => {
      setImageId(item?.itemid);
    });
  }, []);

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        <Paper>
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Container Name</TableCell>
                  <TableCell align="left">Image URL</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deploymentDetails.map((item) => {})}
                {deploymentDetails &&
                  deploymentDetails.map((item) => {
                    return (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "5rem", height: "3rem" }}
                        >
                          {item.ContainerName}
                        </TableCell>
                        <TableCell style={{ width: "8rem" }}>
                          {item.ImageURL}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "8rem", alignContent: "flex-start" }}
                        >
                          {item.Status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { container } = state.deploymentProcessReducer.dependenciesData;
  const { deploymentname, _id, deploymentDetails, deployments } =
    state.deploymentProcessReducer.deploymentResponse;
  const { Status } =
    state.deploymentProcessReducer.deploymentResponse.deploymentDetails[0];
  return {
    container,
    deploymentname,
    _id,
    deploymentDetails,
    deployments,
    Status,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentLiveStatus);
