import React, { useState } from "react";
import { Tooltip } from "@material-ui/core"
import { Handle } from "react-flow-renderer";
import { makeStyles } from "@material-ui/core/styles";
import ProcessNodeModal from "../ProcessNodeModal/ProcessNodeModal";
import { connect } from "react-redux"
import { editElementFromList } from "../../../redux/actions/orchestrationAction"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: 40,
    width: 186,
    border: "0.1px solid lightgray",
  },
  body: {
    marginLeft: 12,
    lineHeight: "100%",
  },
  line1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 3,
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "100%",
    alignSelf: "flex-start",
  },
  line2: {
    fontSize: 10,
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "40%",
    lineHeight: 2.3,
  },
  editIcon: {
    position: "absolute",
    right: 10,
    top: 5,
  },
  nodehandleGreen: {
    width: "15px",
    height: "15px",
    background: "transparent",
    border: "1px solid #32CD32",
    borderRadius: "7.5px",
  },
});

function StartNode(props) {
  const { name, startnodeid } = props.data;
  const [showModal, setModal] = useState(false)

  const styles = useStyles();
  // const { enqueueSnackbar } = useSnackbar();

  const handleProcessSubmit = (obj) => {
    props.editElementFromList(obj);
    setModal(false)
  }

  return (
    <div className={styles.container}>
      {/* <Handle type="target" position="left" style={{ borderRadius: 0 }} /> */}
      <span className={styles.nodeIcon}>
        <img src="/images/Start - process.png" alt={"start node"} height={38} width={39} />
      </span>
      <div className={styles.body}>
        <div className={styles.line1}>
          <text className={styles.heading}>Start</text>
          <span className={styles.editIcon}
            onClick={() => setModal(true)}>
            <img src="/images/edit_2x.png" alt={"edit"} height={12} width={10} />
          </span>
        </div>
        {/* <text className={styles.line2}>{name}</text> */}
      </div>
      <Tooltip title="Source">
        <Handle
          type="source"
          position="bottom"
          id="a"
          className={styles.nodehandleGreen}
        // style={{
        //   left: "50%",
        //   borderRadius: 0,
        //   background: "#00B5AD",
        // }}
        ></Handle>
      </Tooltip>
      {showModal && <ProcessNodeModal edit={true}
        nodeid={startnodeid}
        onCloseClick={() => setModal(false)}
        onEditProcessSubmit={(obj) => handleProcessSubmit(obj)}
        isOpen={showModal}
      />}
    </div>
  );
}

const mapDispatchToProps = {
  editElementFromList
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StartNode)