import React from "react";
import { Doughnut } from "react-chartjs-2";

const CpuChart = ({node}) => {
  return (
    <div>
      <Doughnut
        key={node?.Name}
        data={{
          labels: ["Allocatable", "Used"],
          datasets: [
            {
              data: [
                Math.round(
                  node?.Capacity?.CPU * 1000 -
                    (node?.Capacity?.CPU * 1000 -
                      node?.Allocatable?.CPU +
                      node?.Usage?.CPU / 1000000)
                ),
                Math.round(
                  node?.Capacity?.CPU * 1000 -
                    node?.Allocatable?.CPU +
                    node?.Usage?.CPU / 1000000
                ),
              ],
              backgroundColor: ["#808080", "#b09900"],
            },
          ],
          borderColor: ["#16C818", "#17A5BF"],
        }}
        options={{
          cutout: 80,
          responsive: true,
          plugins: {
            legend: { position: "bottom" },
            title: {
              display: false,
              text: "CPU",
            },
          },
        }}
        plugins={[
          {
            beforeDraw: function (chart, args, options) {
              var width = chart.width;
              var height = chart.height;
              var ctx = chart.ctx;

              ctx.restore();
              ctx.font = "normal normal 600 20px/22px Nunito";
              ctx.textBaseline = "middle";

              var text = `${
                node?.Capacity?.CPU ? node?.Capacity?.CPU : ""
              } CPU`;
              var textX = Math.round((width - ctx.measureText(text).width) / 2);
              var textY = height / 2.15;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
      />
    </div>
  );
};

export default CpuChart;
