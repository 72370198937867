import {
  ADD_TEST_DATA_LIST,
  ALTER_SUBSCRIBE_DATA_BY_ID,
  DATA_FILTERS,
  DELETE_DATA_BY_ID,
  ALTER_FAVOURITE_DATA_BY_ID,
  DATA_HIDDEN
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  testDataList: {
    data: [],
    error: false,
    loading: false,
  },
  testDataFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  testDataSort: [{ columnName: "createdon", direction: "desc" }],
  testDataHidden: [ 
  "name",
  "test_data_type",
  "createdby",
  "createdon",
  "action"
],
  loading: false,
};

const testDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEST_DATA_LIST: {
      return {
        ...state,
        testDataList: {
          ...state.testDataList,
          ...action.payload,
          deleteTestDataStatus: false,
        },
      };
    }
    case DATA_HIDDEN: {
      return {
          ...state,
          testDataHidden: [
              ...action.payload
          ]
      }
  }
    case DELETE_DATA_BY_ID: {
      return {
        ...state,
        testDataList: {
          ...state.testDataList,
          data: state.testDataList.data.filter(
            (serv) => serv._id !== action.payload.TestDataId
          ),
          _totalcount: state.testDataList._totalcount - 1,
          deleteTestDataStatus: true,
        },
      };
    }

    case DATA_FILTERS: {
      return {
        ...state,
        testDataFilters: [...action.payload],
      };
    }

    case ALTER_SUBSCRIBE_DATA_BY_ID: {
      return {
        ...state,
        testDataList: {
          ...state.testDataList,
          data:
            state.testDataList.data.length !== 0 &&
            state.testDataList.data.map((proj) => {
              if (proj._id === action.payload.TestDataId) {
                return { ...proj, isSubscribe: !proj.isSubscribe };
              } else {
                return proj;
              }
            }),
        },
      };
    }

    case ALTER_FAVOURITE_DATA_BY_ID: {
      return {
        ...state,
        testDataList: {
          ...state.testDataList,
          data:
            state.testDataList.data.length !== 0 &&
            state.testDataList.data.map((serv) => {
              if (serv._id === action.payload.TestDataId) {
                return { ...serv, isFavourite: !serv.isFavourite };
              } else {
                return serv;
              }
            }),
        },
      };
    }

    default:
      return state;
  }
};
export default testDataReducer;
