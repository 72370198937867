import {
  ADD_APPLICATION_LIST_DATA,
  ADD_SERVICE_LIST_DATA,
  ADD_VERSION_FOR_SERVICE,
  ADD_SERVICE_TO_CREATE_APPLICATION,
  EMPTY_SERVICE_TO_CREATE_APPLICATION,
  ADD_APPLICATION_FILTER,
  ADD_APPLICATION_SORT,
  DELETE_APPLICATION_BY_ID,
  POPULATE_SERVICE_TO_EDIT_APPLICATION,
  ALTER_FAVOURITE_APPLICATION_BY_ID,
  DELETE_TEMPLATE_BY_ID,
  ADD_TEMPLATE_LIST,
  ADD_APPLICATION_DETAILS_DATA,
  DELETE_MICORSERVICE_FROM_APPLICATION,
  ADD_OPEN_TO_APPLICATION_SERVICE_TABLE,
  CLEAR_APPLICATION_DATA,
  APPLICATION_HIDDEN,
  ALTER_SUBSCRIBE_APPLICATION_BY_ID,
  SET_APPLICATION_DEPLOYMENT
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  application_details: {
    _id: "",
    services: []
  },
  application_list: {
    data: [],
    error: false,
    loading: false,
  },
  applicationFilters: [
    { id: uuidv4(), column: "", operator: "", value: "" }
  ],
  applicationSort: [
    { columnName: "createdon", direction: "desc" }
  ],
  services_list: {
    data: [],
    error: false,
    loading: false,
  },
  create_application: [],
  templateList: [],
  applicationHidden: [
    "name",
    "createdon",
    "deployment",
    "orchestration",
    "action"
  ],
  applicationDeployment : [ ]
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APPLICATION_LIST_DATA: {
      return {
        ...state,
        application_list: {
          ...state.application_list,
          ...action.payload,
          deleteApplicationStatus: false,
        },
      };
    }
    case ADD_APPLICATION_FILTER: {
      return {
        ...state,
        applicationFilters: [...action.payload]
      }
    }
    case ADD_APPLICATION_SORT: {
      return {
        ...state,
        applicationSort: [...action.payload]
      }
    }
    case DELETE_APPLICATION_BY_ID: {
      return {
        ...state,
        application_list: {
          ...state.application_list,
          data: state.application_list.data.filter((item) => item._id !== action.payload.applicationID),
          _totalcount: state.application_list._totalcount - 1,
          deleteApplicationStatus: true,
        }
      }
    }
    case ADD_SERVICE_LIST_DATA: {
      return {
        ...state,
        services_list: {
          ...state.services_list,
          ...action.payload,
        },
      };
    }
    case ADD_VERSION_FOR_SERVICE: {
      const { data, serviceID } = action.payload;
      const new_service_list = state.services_list.data.map((item, index) => {
        if (item._id === serviceID) {
          return {
            ...item,
            versions: data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        services_list: {
          ...state.services_list,
          data: new_service_list,
        },
      };
    }
    case ADD_SERVICE_TO_CREATE_APPLICATION: {
      const data = action.payload.data;
      const { mid, vid, checked } = data;
      if (!checked) {
        var new_state = [];
        state.create_application.forEach((item) => {
          if (item.mid !== mid) {
            new_state.push(item);
          }
        });
        return {
          ...state,
          create_application: new_state,
        };
      } else if (checked) {
        return {
          ...state,
          create_application: [
            ...state.create_application,
            {
              mid,
              vid,
            },
          ],
        };
      }
      return {
        ...state,
        create_application: [...state.create_application, data],
      };
    }
    case EMPTY_SERVICE_TO_CREATE_APPLICATION: {
      return {
        ...state,
        create_application: []
      }
    }
    case POPULATE_SERVICE_TO_EDIT_APPLICATION: {
      return {
        ...state,
        create_application: action.payload
      }
    }
    case ALTER_FAVOURITE_APPLICATION_BY_ID: {
      return {
        ...state,
        application_list: {
          ...state.application_list,
          data: state.application_list?.data.length > 0 && state.application_list.data.map((item) => {
            if (item._id === action.payload.applicationID) {
              return { ...item, isFavourite: !item.isFavourite }
            } else {
              return item
            }
          })
        }
      }
    }
    case ALTER_SUBSCRIBE_APPLICATION_BY_ID : {
      return {
        ...state,
        application_list : {
          ...state.application_list,
          data : state.application_list?.data?.length > 0 && state.application_list.data.map((item) => {
            if(item._id === action.payload.applicationID){
              return {
                ...item, isSubscribe : !item.isSubscribe
              }
            }else{
              return item
            }
          })
        }
      }
    }
    case ADD_TEMPLATE_LIST: {
      return {
        ...state,
        templateList: action.payload.templateList
      }
    }
    case DELETE_TEMPLATE_BY_ID: {
      return {
        ...state,
        templateList: state.templateList.filter((item) => item.templateid !== action.payload.templateid)
      }
    }
    case ADD_APPLICATION_DETAILS_DATA: {
      return {
        ...state,
        application_details: action.payload
      }
    }
    case DELETE_MICORSERVICE_FROM_APPLICATION: {
      return {
        ...state,
        application_details: {
          ...state.application_details,
          services: state.application_details.services.filter((item) => item.mid !== action.payload.mid)
        }
      }
    }
    case ADD_OPEN_TO_APPLICATION_SERVICE_TABLE: {
      const { mid, flag } = action.payload;
      const new_service_list = state.services_list?.data?.map((item) => {
        if (item._id === mid) {
          return {
            ...item,
            open: flag
          }
        } else {
          return item
        }
      });
      return {
        ...state,
        services_list: {
          ...state.services_list,
          data: new_service_list
        }
      }
    }
    case APPLICATION_HIDDEN: {
      return {
        ...state,
        applicationHidden: [
          ...action.payload
        ]
      }
    }
    case CLEAR_APPLICATION_DATA:{
      return{
          ...state,
          application_list:{}
      }
    }
    case SET_APPLICATION_DEPLOYMENT : {
      return {
        ...state,
        applicationDeployment : action.payload
      }
    }
    default:
      return state;
  }
}