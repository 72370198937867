/* eslint-disable */
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router";

export default function BreadCrumbs(props) {
  const histroy = useHistory();

  const useStyles = makeStyles((theme) => ({
    bread: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      font: "normal normal normal 14px/19px Nunito",

      "& ol li a": {
        // color: '#888888',
        color: "#2185D0",
      },

      // "& ol li:hover": {
      //   opacity: 1,
      // },

      // "& ol li a:hover": {
      //   textDecoration: 'none',
      // },

      "& ol li:last-child": {
        color: "#1B1C1D",
        fontWeight: 500,
      },
    },
  }));

  function handleClick(event, path, item) {
    event.preventDefault();
    if (item.isCallback) {
      item.callback()
    }
    histroy.push(path);
  }

  const classes = useStyles();
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.bread}
      >
        {props.items.map((item, index) =>
          item.path ? (
            <Link
              color="inherit"
              href="/"
              onClick={(e) => handleClick(e, item.path, item)}
              key={index}
            >
              {item.title}
            </Link>
          ) : (
            <div key={index}>{item.title}</div>
          )
        )}
      </Breadcrumbs>
    </React.Fragment>
  );
}
