import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { ArrowDropDownRounded } from "@material-ui/icons";
import ClusterDeploymentPreview from "./ClusterDeploymentPreview";
import ClusterPodsPreview from "./ClusterPodsPreview";
import { fetchClusterReplicaSets } from "../../utils/clusterutils/clusterUtils";
import ClusterReplicaSetsPreview from "./ClusterReplicaSetsPreview";
import ClusterServicesPreview from "./ClusterServicesPreview";
import { makeStyles } from "@material-ui/styles";
import listStyles from "../CommonStyles/listScreenStyles";
import ClusterDeploymentDevExpTable from "../DevExpressScreen/ClusterDeploymentDevExpTable";
import ClusterPodsDevExpTable from "../DevExpressScreen/ClusterPodsDevExpTable";
import ClusterReplicaSetDevExpTable from "../DevExpressScreen/ClusterReplicaSetDevExpTable";

const ClusterDetails = (props) => {
  const { cloudConfig } = props;
  const styles = manageStyles();
  const mystyles = listStyles();
  const histroy = useHistory();
  const clusterName = props.match.params.clusterName;
  const configName = props.match.params.configName;

  const handleClose = () => {
    histroy.push(`/host`);
  };

  return (
    <main style={{ marginTop: "-3.4%" }}>
      <div className={mystyles.pageContent}>
        <BreadCrumbs
          items={[
            { title: "Cluster List", path: "/host" },
            { title: clusterName },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{clusterName}</h2>
          <AppButton
            color="primary"
            buttonName="Close"
            variant="contained"
            style={{ margin: "unset" }}
            className="btnsmall"
            onClick={handleClose}
          />
        </Grid>
        <Grid>
          <Grid item xs={12} sm={12}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.sectionHeading}>
                  Deployments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ClusterDeploymentDevExpTable
                  clusterName={clusterName}
                  configName={configName}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.sectionHeading}>Pods</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ClusterPodsDevExpTable
                  clusterName={clusterName}
                  configName={configName}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.sectionHeading}>
                  Replica Sets
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <ClusterReplicaSetsPreview clusterName={clusterName} configName={configName}/> */}
                <ClusterReplicaSetDevExpTable
                  clusterName={clusterName}
                  configName={configName}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.sectionHeading}>
                  Services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ClusterServicesPreview
                  clusterName={clusterName}
                  configName={configName}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { cloudConfig } = state.clusterReducer.cloudConfig;

  return {
    cloudConfig,
  };
};
export default connect(mapStateToProps)(ClusterDetails);
