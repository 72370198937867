import { GET_PROJECTS, DELETE_PROJECT_BY_ID, NEW_PROJECT_DATA, PROJECT_FILTERS, PROJECT_SORT, ALTER_FAVOURITE_PROJECT_BY_ID, ALTER_SUBSCRIBE_PROJECT_BY_ID, CLEAR_PROJECTS, PROJECT_HIDDEN, CLEAR_SERVICEGROUP_EDITMODE, CHANGE_SERVICEGROUP_MODE } from "../actionTypes";
import { CommonServices } from "../../services/commonServices";

const commonServices = new CommonServices();

const add_proj_data = (payload) => ({
  type: GET_PROJECTS,
  payload: payload,
});

export const getProjectList = (currentPage, filter, sort, size) => async (dispatch, getState) => {
  const { userPersonal: { userFavourites, userSubscriptions } } = getState()

  try {
    dispatch(
      add_proj_data({
        loading: true,
      })
    );
    const res_data = await commonServices.getProjectsList(currentPage, filter, sort, size);
    const { data } = res_data;
    if (data) {
      dispatch(
        add_proj_data({
          loading: false,
          data: {
            data: data.data.length !== 0 && data.data.map(proj => {
              const checkFav = userFavourites.filter(fav => fav.resourceinstance === proj._id).length !== 0
              let tempProj = proj;
              if (checkFav) {
                tempProj['isFavourite'] = true
              } else {
                tempProj['isFavourite'] = false
              }
              const checkSubscribe = userSubscriptions.filter(sub =>
                sub.name.split('_')[0] === "project" &&
                sub.name.split('_')[1] === proj._id).length !== 0
              if (checkSubscribe) {
                tempProj['isSubscribe'] = true
              } else {
                tempProj['isSubscribe'] = false
              }
              return tempProj
            })
          },
          error: false,
          _totalcount: data._totalcount
        })
      );
    } else {
      dispatch(
        add_proj_data({
          loading: false,
          data: [],
          error: false,
        })
      );
    }
  } catch (error) {
    dispatch(
      add_proj_data({
        loading: false,
        data: [],
        error: true,
      })
    );
  }
};

export const addNewProjectData = (projectData) => {
  return {
    type: NEW_PROJECT_DATA,
    payload: projectData
  }
};

export const addProjectFilters = (projectFilters) => {
  return {
    type: PROJECT_FILTERS,
    payload: projectFilters
  }
};

export const addProjectSort = (projectSort) => {
  return {
    type: PROJECT_SORT,
    payload: projectSort
  }
};

export const addProjectHidden = (projectHidden) => {
  return {
    type: PROJECT_HIDDEN,
    payload: projectHidden
  }
};

export const deleteProjectById = (projectId) => {
  return {
    type: DELETE_PROJECT_BY_ID,
    payload: {
      projectId
    }
  }
};

export const alterFavouriteProjectById = (projectId) => {
  return {
    type: ALTER_FAVOURITE_PROJECT_BY_ID,
    payload: {
      projectId
    }
  }
};

export const alterSubscibeProjectById = (projectId) => {
  return {
    type: ALTER_SUBSCRIBE_PROJECT_BY_ID,
    payload: {
      projectId
    }
  }
};

export const clearProjects = () => {
  return {
      type: CLEAR_PROJECTS
  }
};

export const clearServiceGroupEdit = () => {
  return {
      type: CLEAR_SERVICEGROUP_EDITMODE
  }
};

export const updateServiceGroupMode = (data) => {
  return {
      type: CHANGE_SERVICEGROUP_MODE,
      payload: data
  }
};

