import { Grid, TextField, Backdrop, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { AppButton } from '../../../components/common/Button'
import { createSection_ } from '../../../utils/ontology/ontologyUtils'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import CustomChips from '../../../components/common/Chips'
import { getOntologyFieldGroups } from '../../../redux/actions/ontologyAction'

function CreateSectionScreen(props) {
  // const [fieldList, setFieldList] = useState([])
  const [sectionData, setSectionData] = useState({
    name: '',
    label: '',
    description: '',
  })
  const [error, setError] = useState({
    name: false,
    label: false,
    fieldfg_arr: false,
  })
  const [loading, setLoading] = useState(false)
  const [selectedFG, setSelectedFG] = useState([])

  const styles = manageStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  useEffect(() => {
    props.getOntologyFieldGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = (key, value) => {
    setSectionData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (Object.keys(error).includes(key)) {
      setError((prevState) => ({
        ...prevState,
        [key]: false,
      }))
    }
  }

  const handleFieldSelected = (e, input) => {
    if (input) {
      setError((prevState) => ({
        ...prevState,
        fieldfg_arr: false,
      }))
      var tempFields =
        selectedFG.length > 0 ? selectedFG.filter((item) => item._id === input?._id) : []
      if (tempFields.length === 0) {
        setSelectedFG((prevState) => [...prevState, input])
      }
      //
    }
  }

  const handleChipDelete = (data) => {
    setSelectedFG((prevState) => {
      var tempFields = prevState.length > 0 && prevState.filter((item) => item._id !== data._id)
      return tempFields
    })
  }

  const handleSubmit = async () => {
    var errorFlag = false
    var tempError = {}
    for (var obj in sectionData) {
      if (sectionData[obj] === '') {
        if (Object.keys(error).includes(obj)) {
          errorFlag = true
          tempError[obj] = true
        }
      }
    }
    if (selectedFG.length === 0) {
      errorFlag = true
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        fieldfg_arr: true,
      }))
    } else {
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        fieldfg_arr: false,
      }))
    }
    if (!errorFlag) {
      setLoading(true)
      const req_data = {
        name: sectionData.name,
        'section Label': sectionData.label,
        description: sectionData.description,
        fieldgroups: selectedFG.map((item) => item._id),
      }
      //
      const { _msg, _status } = await createSection_(req_data)
      if (_status === 201) {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'success' })
        history.push('/ontology')
      } else {
        setLoading(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }
  }

  return (
    <main className={styles.main}>
      <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: 'Ontology', path: '/ontology' },
              { title: 'Sections', path: '/ontology' },
              { title: 'Add Section' },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>{'Section'}</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: 'unset' }}
                className="btnmedium"
                onClick={handleSubmit}
              />
              <AppButton
                color="primary"
                buttonName="Cancel"
                variant="outlined"
                className="btnmedium"
                onClick={() => history.goBack()}
              />
            </div>
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid
            container
            spacing={3}
            style={{ marginTop: '2rem', borderBottom: '2px solid rgb(175,175,175)' }}
          >
            <Grid item xs={9} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Section Name<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={styles.input}
                    style={error.name ? { border: '1px solid red' } : {}}
                    value={sectionData.field_name}
                    onChange={(e) => handleTextChange('name', e.target.value)}
                    autoComplete="off"
                  />
                  {error.name && <text className={styles.errorMsg}>Invalid Section Name</text>}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Section Label<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={styles.input}
                    value={sectionData.field_label}
                    onChange={(e) => handleTextChange('label', e.target.value)}
                    autoComplete="off"
                  />
                  {error.label && <text className={styles.errorMsg}>Invalid Section Label</text>}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="projectDescription" className={styles.fieldHeading}>
                Section Description
              </label>
              <textarea
                name="description"
                value={sectionData.field_description}
                style={
                  error.description
                    ? { border: '1px solid red', height: '7.8rem' }
                    : { height: '7.8rem' }
                }
                onChange={(e) => handleTextChange('description', e.target.value)}
                className={styles.textarea}
                rows="8"
              ></textarea>
              {/* {error.description && (
                <text className={styles.errorMsg}>
                  Invalid Section Description
                </text>
              )} */}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingTop: '25px' }}>
            <Grid spacing={2} container xs={9} sm={9}>
              <Grid
                item
                xs={4}
                sm={4}
                style={{
                  marginLeft: 10,
                  height: '22rem',
                  paddingBottom: '2rem',
                }}
              >
                <label htmlFor="" className={styles.fieldHeading}>
                  Field Groups<span className={styles.red}>*</span>
                </label>
                <Autocomplete
                  style={error.fieldfg_arr ? { border: '1px solid red' } : {}}
                  options={props.data}
                  getOptionLabel={(option) => option.name}
                  className={styles.searchInput3}
                  onChange={handleFieldSelected}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" placeholder="Select Field Groups" />
                  )}
                />
                {error.fieldfg_arr && (
                  <text className={styles.errorMsg}>No Field Groups Selected</text>
                )}
                {selectedFG.length > 0 && (
                  <CustomChips chipsData={selectedFG} handleDelete={handleChipDelete} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </form>
    </main>
  )
}

const mapDispatchToProps = {
  getOntologyFieldGroups,
}

const mapStateToProps = (state) => {
  const { fieldgroups } = state.ontologyReducer
  const { data, error, loading } = fieldgroups
  return {
    data: data ? data : [],
    error,
    loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSectionScreen)
