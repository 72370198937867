import { OntologyServices } from "../../services/ontologyServices";
const OntologyService = new OntologyServices();

export const createField_ = async (req_data) => {
  try {
    const res_data = await OntologyService.createField(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const createFieldGroup_ = async (req_data) => {
  try {
    const res_data = await OntologyService.createFieldGroup(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.resposne.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const createSection_ = async (req_data) => {
  try {
    const res_data = await OntologyService.createSection(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchFieldByID_ = async (fieldID) => {
  try {
    const res_data = await OntologyService.fetchFieldByID(fieldID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchFieldGroupByID_ = async (fieldID) => {
  try {
    const res_data = await OntologyService.fetchFieldGroupByID(fieldID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchSectionByID_ = async (sectionID) => {
  try {
    const res_data = await OntologyService.fetchSectionByID(sectionID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchObjectByID_ = async (objectID) => {
  try {
    const res_data = await OntologyService.fetchObjectByID(objectID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editFieldByID_ = async (fieldID, req_data) => {
  try {
    const res_data = await OntologyService.editFieldByID(fieldID, req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = await err.response?.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editFieldGroupByID_ = async (fieldGroupID, req_data) => {
  try {
    const res_data = await OntologyService.editFieldGroupByID(
      fieldGroupID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editSectionByID_ = async (sectionID, req_data) => {
  try {
    const res_data = await OntologyService.editSectionByID(sectionID, req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editObjectByID_ = async (objectID, req_data) => {
  try {
    const res_data = await OntologyService.editObjectByID(objectID, req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteFieldByID_ = async (fieldID) => {
  try {
    const res_data = await OntologyService.deleteFieldByID(fieldID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteFieldgroupByID_ = async (fieldgroupID) => {
  try {
    const res_data = await OntologyService.deleteFieldGroupByID(fieldgroupID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteSectionByID_ = async (sectionID) => {
  try {
    const res_data = await OntologyService.deleteSectionByID(sectionID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};
