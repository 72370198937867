import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  section1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "45rem",
  },
  image: {
    height: 34,
    width: 34,
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "2.3rem",
    marginTop: 5,
    // margin: '0 15px',

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  heading: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 15,
  },
  container: {
    height: 450,
    width: "100%",
    // border : "1px solid black",
    // marginTop: "1rem",
    // marginLeft: "1rem",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  containerInput: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    bottom: 10,
    width: '25%'
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  root: {
    // flexGrow: 1,
    // marginTop : '6rem',
    margin: "1px solid black",
    width: "60%",
    height: 35,
    "& div ": {
      "& div": {
        height: 35,
        "& button": {
          minHeight: 0,
          fontSize: "14px",
          textTransform: "none",
          "& span ": {
            "& .MuiTab-root": {
              textTransform: "lowercase",
              fontSize: "14px",
              minWidth: "62px"
            },
          },
        },
      },
    },
    "& .MuiTab-root": {
      minWidth: "62px"
    }
  },
  tab_container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: 'center',

    "& .MuiPaper-elevation1": {
      boxShadow: 'unset'
    }
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: "11rem",
  },
  toggleicons: {
    margin: "0px 0px",
    cursor: "pointer",
    height: 29,
    width: 42,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      padding: 1,
      borderRadius: 1,
      color: "#fff",
    },
    "&.toggleActive": {
      // background: "#002855 0% 0% no-repeat padding-box",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      // padding: 5,
      margin: "0 0px",
      opacity: 1,
      "& img": {
        filter: "brightness(0) invert(1) ",
      },
    },
    "&.toggleInactive": {
      backgroundColor: "white",
      "& img": {
        filter: "brightness(1) invert(0) ",
        height: 29,
        width: 42,
      },
    }
  },
  line5: {
    // marginLeft: 20,
    // marginRight: 20,
    marginTop: 25,
  },
  outputbody: {
    // height: 100,
    // width: 750,
    borderWidth: 2,
    borderColor: "black",
    marginTop: 5,
    "& .CodeMirror-scroll": {
      height: "100%",
      marginRight: 0,
      marginBottom: 0,
    },
    // flex: 1,
  },
  line2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 10,
    // marginRight: 20,
    // marginTop: 10,
  },
  name: { marginRight: 10, display: "flex", flexDirection: 'column' },
  value: {
    height: "2.3rem",
    width: "21.2rem",
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "1rem"
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  output: {
    height: "10rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'blue',
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  },
  start_stop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 0,
    marginLeft: "1rem",
  },
  stop: {
    fontSize: 14,
    fontFamily: "Nunito",
    whiteSpace: "nowrap",
  },
  button2: {
    "& .MuiIconButton-root": {
      borderRadius: 0,
      padding: 5,
    },
  },
}));
