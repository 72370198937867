import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";
import { Grid } from "@material-ui/core";
import { SelectHelper } from "./helper/SelectHelper";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import { MenuItem, Select } from "@material-ui/core";
function RadioSettings() {
  const dispatch = useDispatch();
  const {
    currentProject,
    currentPage,
    currentComponent,
    executeJavascript,
    getDataValue,
  } = useGetData();
  const [error, setError] = useState({ data: "", title: "" });
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  const handleChange = (value, key) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError({ ...error, data: "" });
      } else {
        dataValue = getDataValue(value);
        setError({ ...error, data: "" });
      }
    } catch (error) {
      setError({ ...error, data: error?.message });
    }
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [key]: {
              dataInput: value,
              dataValue: dataValue,
            },
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const changeTab = () => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            tabValue: !component?.tabValue,
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <JSDataInput
          value={currentComponent?.data}
          onChange={(value) => handleChange(value, "data")}
          label="Value"
          title={`${currentComponent.name} : Value`}
          error={error.data}
        />
      </div>
      {currentComponent?.type === "Radio" && (
        <div className="field">
          <div className="tableColumns">
            <Grid container justifyContent="space-between">
              <Grid item>
                <label>Options</label>
              </Grid>
              <Grid item>
                <label
                  // onClick={changeTab}
                  style={{
                    cursor: "pointer",
                    font: "normal normal 800 12px/16px Nunito",
                  }}
                >
                  JS
                </label>
              </Grid>
            </Grid>
            {!currentComponent?.tabValue ? (
              <SelectHelper
                currentPage={currentPage}
                selectedComponent={currentComponent}
                project={currentProject}
                customArr={currentComponent?.options}
              />
            ) : (
              <div className="field">
                <JSDataInput
                  value={currentComponent?.disabled}
                  onChange={handleChange}
                  name={"Options"}
                  title={`${currentComponent.name} : Options`}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default RadioSettings;
