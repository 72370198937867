import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  uploadbtn: {
    backgroundColor: '#f4f4f4',
    backgroundOrigin: 'padding-box',
    borderRadius: '5px',
    color: '#303645',
    width: '90px',
    height: '40px',
    border: 'none',
    font: 'normal normal 400 14px/19px Nunito',
    letterSpacing: '0.02em',
    padding: '6px 16px 6px 16px',
    '&:hover': {
      opacity: 0.3,
    },
    '&:active': {
      opacity: 0.4,
    },
    '&:focus': {
      border: '1px solid #A8A8A8',
      borderRadius: '7px',
      opacity: 1,
    },
  },
  editMode: {
    background: '#f5f5f5 0% 0% no-repeat padding-box !important',
    paddingBottom: 2,
    borderBottom: '1px solid #DFDFDF',
  },
}));
