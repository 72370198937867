import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationTemplatesByID,
  deleteApplicationById_,
} from "../../../utils/orchestration/applicationUtils";
import {
  deleteApplicationByID,
  alterFavouriteApplicationByID,
  alterSubscibeApplicationById,
} from "../../../redux/actions/applicationAction";
import {
  addFavouriteInResource,
  deleteFavouriteInResource,
  addSubscriptionInResource,
  deleteUserSubscriptionInResource,
} from "../../../utils/users/userUtils";
import {
  addSingleFavourite,
  removeSingleFavourite,
  addSingleUserSubscription,
  removeSingleUserSubscription,
} from "../../../redux/actions/userPersonalAction";
import { deleteOntology } from "../../../redux/actions/ontologyAction";
import {
  deleteFieldByID_,
  deleteFieldgroupByID_,
  deleteSectionByID_,
} from "../../../utils/ontology/ontologyUtils";
import { useSnackbar } from "notistack";
import devExpressStyles from "../styles/DevExpressTableStyles";
import ProcessDialog from "../../Orchestration/Application/ProcessDialog";
import OntologyDeleteModal from "../modals/OntologyDeleteModal";
import moment from "moment";
import { APPLICATION } from "../../../services/helper/config";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import useCheckPermission from "../hooks/useCheckPermission";
import Loader from "../stuff/Loader";
import { DeleteModal } from "../modals/DeleteModal";
import DependencyModal from "../modals/DependencyModal";
import { DownloadPostManCollectionForServiceBundle } from "../../../utils/test/testExecutionUtils";

const CreateProcessCell = ({ ...restProps }) => {
  const [open, setOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const history = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector((state) => state.authReducer);
  const { _id, name } = restProps.row;

  const { checkCompPermission } = useCheckPermission();

  const handleCloseClick = () => {
    setOpen(false);
  };

  const downloadImageHelper = async () => {
    if (checkCompPermission("ServiceBundle", ["Download"])) {
      setIsLoader(true);
      let response = await fetch(
        `${APPLICATION}/api/container/environments/${_id}/_download`,
        {
          headers: {
            organization: "60ba3575e1bc3c06e220ab5a",
            token: userData?.accesstoken,
          },
        }
      );

      if (response.status === 200) {
        setIsLoader(false);
        setIsDownload(true);
        let blob = await response.blob();
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        a.href = url;
        a.download = `${name}.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownload(false);
      } else {
        setIsLoader(false);
        setIsDownload(false);
        enqueueSnackbar("Unable to download, please try after sometime...", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to download application image. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleCreateTemplate = (route, id, name) => {
    if (checkCompPermission("ServiceBundle", ["Manage Process"])) {
      const _id = id;
      history.push({
        pathname: `application/edit/${route}/${_id}`,
        state: {
          source: "create_template",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to create process. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.actionIcons} style={{ marginLeft: "1rem" }}>
        <div
          className={styles.actionIcon}
          onClick={() =>
            handleCreateTemplate(
              restProps.column.route,
              restProps.row._id,
              restProps.row.name
            )
          }
        >
          <Tooltip title="Create Aggregated API">
            <img src="/images/newProcess.svg" alt="icon" />
          </Tooltip>
        </div>
        <div
          className={styles.actionIcon}
          onClick={() => {
            if (checkCompPermission("ServiceBundle", ["Manage Process"])) {
              setOpen(true);
            } else {
              enqueueSnackbar(
                `You do not have permission to view process list. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <Tooltip title="View All Aggregated APIs">
            <img src="/images/application_list.svg" alt="icon" />
          </Tooltip>
        </div>
        <div className={styles.actionIcon}>
          {isDownload && <CircularProgress color="primary" size={25} />}
          {!isDownload && (
            <Tooltip title="Download Service Bundle Image">
              <span onClick={downloadImageHelper}>
                <img src="/images/Download_icon.svg" alt="icon" />
              </span>
            </Tooltip>
          )}
        </div>
        {open && (
          <ProcessDialog
            open={open}
            onCloseClick={handleCloseClick}
            _id={_id}
            name={name}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const ApplicationActionCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = restProps.row;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.authReducer);
  const { userInfo } = useSelector((state) => state.userDetails);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [dependencyData, setDependencyData] = useState([]);
  const { checkCompPermission } = useCheckPermission();
  const [isLoader, setIsLoader] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    if (restProps.column.for === "application_list") {
      const { _msg, _status, data } = await getApplicationTemplatesByID(
        restProps?.row?._id
      );
      if (_status === 200) {
        const aggregatedApis =
          data !== null
            ? data?.map((d) => {
                return {
                  title: d.name,
                  expanded: false,
                };
              })
            : [];
        const tempData = [
          {
            title:
              aggregatedApis?.length === 0
                ? "No Aggregated APIs found"
                : "Aggregated APIs",
            expanded: true,
            children: [...aggregatedApis],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    }
  };
  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };

  const handleAddFavouite = async (resource, resourceinstance) => {
    const favoriteData = {
      resourcetype: resource,
      resourceinstance: resourceinstance,
    };

    const { _msg, _status, data } = await addFavouriteInResource(
      userData.userid,
      favoriteData
    );
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "application_list") {
        dispatch(alterFavouriteApplicationByID(resourceinstance));
      }
      dispatch(addSingleFavourite(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDeleteFavouite = async (resource, resourceinstance) => {
    const { _msg, _status } = await deleteFavouriteInResource(
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "application_list") {
        dispatch(alterFavouriteApplicationByID(resourceinstance));
      }
      dispatch(removeSingleFavourite(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDeleteApplication = async () => {
    handleDeleteModalClose();
    setIsLoader(true);
    if (checkCompPermission("ServiceBundle", ["Delete"])) {
      const { _msg, _status } = await deleteApplicationById_(_id);
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        dispatch(deleteApplicationByID(_id));
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
        setIsLoader(false);
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to  delete application. Please contact your Admin.`,
        { variant: "error" }
      );
      setIsLoader(false);
    }
  };

  const handleEditApplication = (id) => {
    if (checkCompPermission("ServiceBundle", ["Edit"])) {
      history.push({
        pathname: `application/edit/${id}`,
        state: {
          source: "edit_icon",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to access edit application. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleSubscribeResource = async (resource, resourceinstance) => {
    const { _msg, _status, data } = await addSubscriptionInResource(
      userInfo.email,
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (resource === "application") {
        dispatch(alterSubscibeApplicationById(resourceinstance));
      }
      dispatch(addSingleUserSubscription(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleRemoveSubscribeResource = async (resource, resourceinstance) => {
    const { _msg, _status, data } = await deleteUserSubscriptionInResource(
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (resource === "application") {
        dispatch(alterSubscibeApplicationById(resourceinstance));
      }
      dispatch(removeSingleUserSubscription(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  function exportToJson(objectData) {
    let filename = `${restProps.row.name}_collection`;
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const handleDownloadPostmanCollection = async () => {
    setIsLoader(true);
    const serializerData = {
      Protocol: window.location.protocol.slice(0, -1),
      Host: window.location.host,
      Port: "5003",
      ServiceBundleName: restProps.row.name,
      ApplicationId: restProps.row._id,
    };

    const { res_data } = await DownloadPostManCollectionForServiceBundle(
      serializerData
    );
    setIsLoader(false);

    if (res_data?.status === 200) {
      exportToJson(res_data?.data);
    } else {
      enqueueSnackbar(res_data?._msg, { variant: "error" });
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        {isLoader && <Loader />}
        {/* {isLoader && <CircularProgress color="primary" />} */}
        {deleteModalOpen && (
          <DeleteModal
            name={restProps.row.name}
            row={restProps.row}
            open={deleteModalOpen}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDeleteApplication}
          />
        )}
        {dependencyModalOpen && (
          <DependencyModal
            modalTitle={
              dependencyData?.length !== 0 ? "Dependencies" : "No Dependencies"
            }
            row={restProps.row}
            open={dependencyModalOpen}
            handleClose={handleDependencyModalClose}
            treeDataArr={dependencyData}
            // handleDelete={handleDeleteApplication}
          />
        )}
        <IconButton
          color="inherit"
          aria-label="favorite"
          edge="start"
          className={styles.actionIcon}
          onClick={() => {
            if (restProps.row?.isFavourite) {
              handleDeleteFavouite("application", restProps.row._id);
            } else {
              handleAddFavouite("application", restProps.row._id);
            }
          }}
        >
          {restProps.row?.isFavourite ? (
            <Tooltip title="Unfavourite">
              <img src="/images/heartFilled.svg" alt="card" />
            </Tooltip>
          ) : (
            <Tooltip title="Favourite">
              <img src="/images/heartLine.svg" alt="card" />
            </Tooltip>
          )}
        </IconButton>
        <Tooltip
          title={restProps.row?.isSubscribe ? "Unsubscribe" : "Subscribe"}
          arrow
        >
          <IconButton
            color="inherit"
            aria-label="subscribe"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (restProps.row?.isSubscribe) {
                handleRemoveSubscribeResource("application", restProps.row._id);
              } else {
                handleSubscribeResource("application", restProps.row._id);
              }
            }}
          >
            {restProps.row?.isSubscribe ? (
              <img src="/images/Bell_Active.svg" alt="card" />
            ) : (
              <img src="/images/Bell_Inactive.svg" alt="card" />
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          color="inherit"
          aria-label="edit"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleEditApplication(restProps.row._id)}
        >
          <Tooltip title="Edit">
            <img src="/images/editLine.svg" alt="card" />
          </Tooltip>
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="delete"
          edge="start"
          className={styles.actionIcon}
          onClick={handleDeleteModalClickOpen}
        >
          <Tooltip title="Delete">
            <img src="/images/deleteLine.svg" alt="card" />
          </Tooltip>
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="dependencies"
          edge="start"
          className={styles.actionIcon}
          onClick={handleDependencyModalClickOpen}
        >
          <Tooltip title="Dependencies">
            <img src="/images/info_icon.svg" alt="card" />
          </Tooltip>
        </IconButton>
        <Tooltip title="Download Postman collection">
          <IconButton
            color="inherit"
            aria-label="dependencies"
            edge="start"
            className={styles.actionIcon}
            onClick={handleDownloadPostmanCollection}
          >
            <img
              src="/images/postman-icon.svg"
              alt="download postman collection"
            />
          </IconButton>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

//ontology actions
const OntologyObjectAction = ({ ...restProps }) => {
  const history = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  const handleObjectEdit = (name) => {
    if (checkCompPermission("OntologyResource", ["Edit"])) {
      history.push({
        pathname: `/ontology/objects/${name}`,
        state: {
          source: "edit_icon",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to edit object. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons} style={{ marginLeft: 10 }}>
        <IconButton
          color="inherit"
          aria-label="favorite"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleObjectEdit(restProps.row.name)}
        >
          <img src="/images/edit_2x.png" alt="icon" height={18} width={16} />
        </IconButton>
        {/* <text className={styles.process_text}>{"Create New Process"}</text> */}
      </div>
    </VirtualTable.Cell>
  );
};

const OntologySectionAction = ({ ...restProps }) => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const styles = devExpressStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  const handleDeleteSection = async () => {
    const { _msg, _status } = await deleteSectionByID_(restProps.row._id);
    if (_status === 200) {
      dispatch(deleteOntology("section", restProps.row._id));
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const handleSectionEdit = (id) => {
    if (checkCompPermission("OntologySection", ["Edit"])) {
      history.push({
        pathname: `/ontology/sections/${id}`,
        state: {
          source: "edit_icon",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to edit section. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        <IconButton
          color="inherit"
          aria-label="favorite"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleSectionEdit(restProps.row._id)}
        >
          <img src="/images/edit_2x.png" alt="icon" height={18} width={16} />
        </IconButton>
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (checkCompPermission("OntologySection", ["Delete"])) {
                setDeleteModal(true);
              } else {
                enqueueSnackbar(
                  `You do not have permission to delete section. Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            <img
              src="/images/deleteLine.svg"
              alt="icon"
              height={18}
              width={16}
            />
          </IconButton>
        </Tooltip>
      </div>
      {showDeleteModal && (
        <OntologyDeleteModal
          id={restProps.row._id}
          type="section"
          isOpen={showDeleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => handleDeleteSection()}
        />
      )}
    </VirtualTable.Cell>
  );
};

const OntologyFieldGroupAction = ({ ...restProps }) => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const styles = devExpressStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  const handleDeleteFieldgroup = async () => {
    const { _msg, _status, data } = await deleteFieldgroupByID_(
      restProps.row._id
    );
    if (_status === 200) {
      dispatch(deleteOntology("fieldgroup", restProps.row._id));
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const handleEditFieldgroup = (id) => {
    if (checkCompPermission("OntologyFieldGroup", ["Edit"])) {
      history.push({
        pathname: `/ontology/fieldgroups/${id}`,
        state: {
          source: "edit_icon",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to edit fieldgroup. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        <IconButton
          color="inherit"
          aria-label="favorite"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleEditFieldgroup(restProps.row._id)}
        >
          <img src="/images/edit_2x.png" alt="icon" height={18} width={16} />
        </IconButton>
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (checkCompPermission("OntologyFieldGroup", ["Delete"])) {
                setDeleteModal(true);
              } else {
                enqueueSnackbar(
                  `You do not have permission to delete fieldgroup. Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            <img
              src="/images/deleteLine.svg"
              alt="icon"
              height={18}
              width={16}
            />
          </IconButton>
        </Tooltip>
      </div>
      {showDeleteModal && (
        <OntologyDeleteModal
          id={restProps.row._id}
          type="fieldgroup"
          isOpen={showDeleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => handleDeleteFieldgroup()}
        />
      )}
    </VirtualTable.Cell>
  );
};

const OntologyFieldAction = ({ ...restProps }) => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const styles = devExpressStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  const handleDeleteField = async () => {
    const { _msg, _status, data } = await deleteFieldByID_(restProps.row._id);
    if (_status === 200) {
      dispatch(deleteOntology("field", restProps.row._id));
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      setDeleteModal(false);
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const handleFieldEdit = (id) => {
    if (checkCompPermission("OntologyField", ["Edit"])) {
      history.push({
        pathname: `/ontology/fields/${id}`,
        state: {
          source: "edit_icon",
        },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to edit field. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={devExpressStyles.actionIcons}>
        <IconButton
          color="inherit"
          aria-label="favorite"
          edge="start"
          className={styles.actionIcon}
          onClick={() => handleFieldEdit(restProps.row._id)}
        >
          <img src="/images/edit_2x.png" alt="icon" height={18} width={16} />
        </IconButton>
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (checkCompPermission("OntologyField", ["Delete"])) {
                setDeleteModal(true);
              } else {
                enqueueSnackbar(
                  `You do not have permission to delete field. Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            <img
              src="/images/deleteLine.svg"
              alt="icon"
              height={18}
              width={16}
            />
          </IconButton>
        </Tooltip>
      </div>
      {showDeleteModal && (
        <OntologyDeleteModal
          id={restProps.row._id}
          type="field"
          isOpen={showDeleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => handleDeleteField()}
        />
      )}
    </VirtualTable.Cell>
  );
};

const DateCell = ({ columnName, ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);
  if (restProps.row.createdon || restProps.row.updatedon) {
    if (restProps.row.updatedon) {
      return (
        <>
          {userInfo?.timeZone
            ? moment
                .unix(restProps.row.updatedon ? restProps.row.updatedon : "")
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row.updatedon ? restProps.row.updatedon : "")
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      );
    } else if (restProps.row.createdon) {
      return (
        <>
          {userInfo?.timeZone
            ? moment
                .unix(restProps.row.createdon)
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row.createdon ? restProps.row.createdon : "")
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      );
    }
  } else {
    return <></>;
  }
};

// const ServiceBundleStatusCell = ({ ...restProps }) => {
//   // Here 'state' in the API response is showing the Status for service bundle
//   const status =
//     restProps.row.statuses &&
//     restProps.row.statuses.find(
//       (s) => s.status?.toLowerCase() === restProps.row.state?.toLowerCase()
//     );

//   return (
//     <VirtualTable.Cell {...restProps}>
//       <div
//         style={{ display: "flex", justifyContent: "left", marginLeft: "10px" }}
//       >
//         {status && (
//           <div
//             style={{
//               backgroundColor: status?.color,
//               height: 15,
//               width: 15,
//               borderRadius: "50%",
//               position: "relative",
//               right: 10,
//             }}
//           ></div>
//         )}
//         {status && (
//           <span style={{ color: status?.color }}>
//             {/* {status?.status?.charAt(0).toUpperCase() + status?.status?.slice(1)} */}
//             {status?.status}
//           </span>
//         )}
//       </div>
//     </VirtualTable.Cell>
//   );
// };

const ServiceBundleStatusCell = ({ ...restProps }) => {
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps.row.state === "created" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#fbbd08",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#fbbd08", paddingLeft: 5 }}>
              {restProps.row.state.charAt(0).toUpperCase() +
                restProps.row.state.slice(1)}
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "running" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              <span>Started</span>
            </span>
          </div>
        </div>
      )}
      {restProps.row.state === "exited" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.state && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              <span>Stopped</span>
            </span>
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const Cell = (props) => {
  const history = useHistory();
  const { column } = props;

  const { checkCompPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const styles = devExpressStyles();

  if (column.for === "ontology_fields_list") {
    if (column.name === "action") {
      return <OntologyFieldAction {...props} />;
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyField", ["Read"])) {
              history.push(`/ontology/fields/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access field details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <DateCell {...props} columnName={column.name} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyField", ["Read"])) {
              history.push(`/ontology/fields/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access field details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        />
      );
    }
  } else if (column.for === "ontology_field_group_list") {
    if (column.name === "action") {
      return <OntologyFieldGroupAction {...props} />;
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyFieldGroup", ["Read"])) {
              history.push(`/ontology/fieldgroups/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access field group details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <DateCell {...props} columnName={column.name} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyFieldGroup", ["Read"])) {
              history.push(`/ontology/fieldgroups/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access field group details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        />
      );
    }
  } else if (column.for === "ontology_section_list") {
    if (column.name === "action") {
      return <OntologySectionAction {...props} />;
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologySection", ["Read"])) {
              history.push(`/ontology/sections/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access section details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <DateCell {...props} columnName={column.name} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologySection", ["Read"])) {
              history.push(`/ontology/sections/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access section details. Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        />
      );
    }
  } else if (column.for === "ontology_objects_list") {
    if (column.name === "action") {
      return <OntologyObjectAction {...props} />;
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyResource", ["Read"])) {
              history.push(`/ontology/objects/${props.row.name}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access Object Details
                                . Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <DateCell {...props} columnName={column.name} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("OntologyResource", ["Read"])) {
              history.push(`/ontology/objects/${props.row.name}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access Object Details
                                . Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        />
      );
    }
  } else if (column.for === "application_list") {
    if (column.name === "orchestration") {
      return <CreateProcessCell {...props} />;
    } else if (column.name === "action") {
      return <ApplicationActionCell {...props} />;
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("Application", ["Read"])) {
              history.push(`/application/details/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access Application Details
                                . Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <DateCell {...props} columnName={column.name} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "state") {
      return (
        <VirtualTable.Cell
          className={styles.builtenvi}
          {...props}
          onClick={() => {
            if (checkCompPermission("Application", ["Read"])) {
              history.push(`/application/details/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access Application Details
                                  . Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        >
          <ServiceBundleStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => {
            if (checkCompPermission("Application", ["Read"])) {
              history.push(`/application/details/${props.row._id}`);
            } else {
              enqueueSnackbar(
                `You do not have permission to access Application Details
                                . Please contact your Admin.`,
                { variant: "error" }
              );
            }
          }}
        />
      );
    }
  } else {
    return <VirtualTable.Cell {...props} />;
  }
};

const NoDataFound = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        textAlign: "center",
        top: "calc(50% - 4rem)",
        left: "calc(50% - 5rem)",
        maxWidth: "11rem",
      }}
    >
      <img src="/images/notFound.svg" alt="notfound" />
      <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
    </div>
  );
};

const OntologyTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);
  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 260
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />
          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default OntologyTable;
