const applicationColumns = [
  { name: "name", title: "Bundle Name", route: "/application-details", for: "application_list" },
  { name: "createdon", title: "Created On", for: "application_list" },
  { name: "deployment", title: "Deployment Status", for: "application_list" },
  { name: "state", title: "Status", for: "application_list" },
  { name: "orchestration", title: "Orchestration", route: "orchestration", for: "application_list" },
  { name: "action", title: "Action", for: "application_list" }
];


const filterapplicationColumns = [
  { name: "name", title: "Bundle Name", route: "/application-details", for: "application_list" },
  { name: "createdon", title: "Created On", for: "application_list" },
  { name: "state", title: "Status", for: "application_list" },
  { name: "createdby", title: "Created By", for: "application_list" },
  { name: "servicebundledescription", title: "Service Bundle Description", for: "application_list" },
  { name: "updatedby", title: "Updated By", for: "application_list" },
  { name: "updatedon", title: "Updated On", for: "application_list" },


];

const applicationDefaultColumnWidths = [
  { columnName: "name", weightage: 3, width: 200 },
  { columnName: "createdon", weightage: 3, width: 200 },
  { columnName: "state", weightage: 3, width: 200 },
  { columnName: "deployment", weightage: 3, width: 200 },
  { columnName: "orchestration", weightage: 3, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 }
];

const applicationTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "deployment", align: "left" },
  { columnName: "state", align: "left" },
  { columnName: "orchestration", align: "center", sortingEnabled: false },
  { columnName: "action", align: "center", sortingEnabled: false }
];

const applicationColumnOrder = [
  "name",
  "state",
  "deployment",
  "createdon",
  "orchestration",
];

const applicationColumnHidden = [
  "name",
  "createdon",
  "deployment",
  "orchestration",
  "action"
]

export {
  applicationColumns,
  applicationDefaultColumnWidths,
  applicationTableColumnExtensions,
  applicationColumnOrder,
  applicationColumnHidden,
  filterapplicationColumns
};