import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useEffect } from "react";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  arrayToJson,
  handleFormValidationPro,
  initError,
  jsonToArray,
} from "../../../utils/common";
import {
  addRuntimeEnvironment,
  editBuildCommands,
  editRunCommands,
  editVersion,
  fetchAllRuntimeEnvironments,
  fetchVersionById,
  getRepositoryDetailsByMicroServiceVersion,
} from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import UploadSourceCodeInVersion from "../../../components/common/modals/UploadSourceCodeInVersion";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForAiTaskVersionEdit from "../../../components/common/entitySchema/aiTaskVersion/CustomFieldGroupsForAiTaskVersionEdit";
import { connect, useSelector } from "react-redux";
import {
  closeAITaskVersion,
  editAITaskVersionTabbed,
  getDataModelsListForAiTask,
} from "../../../redux/actions/aiTaskActions";
import { useLocation } from "react-router-dom";
import listScreenStyles from "../../CommonStyles/listScreenStyles";

import {
  closeVersion,
  editVersionTabbed,
} from "../../../redux/actions/serviceAction";
import { useDispatch } from "react-redux";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EditAiTaskVersion = (props) => {
  const { fromAppBundle, tabbedView } = props;
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const isEditMode = isTabbedApproach
    ? props.isEditMode
    : props?.match?.params?.action === "edit"
      ? true
      : false;
  const mid = isTabbedApproach
    ? props.serviceId
    : props?.match?.params?.serviceId;
  const vid = isTabbedApproach
    ? props.versionId
    : props?.match?.params?.versionId;
  const [serviceId] = useState(mid);
  const [versionId] = useState(vid);
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const [isLoader, setIsLoader] = useState(false);
  const [openUploadSourceCodeModal, setOpenUploadSourceCodeModal] =
    useState(false);
  const [repositoryData, setRepositoryData] = useState({});
  const [versionEntitySchema, setVersionEntitySchema] = useState([]);
  const [runtimeEnvs, setRuntimeEnvs] = useState([]);
  const [selectedRuntimeEnv, setSelectedRuntimeEnv] = useState({});
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const [versionData, setVersionData] = useState({
    _id: "",
    versionTag: "",
    description: "",
    port: "",
    apirequired: "",
    createdon: "",
    updatedby: "",
    microservice_name: "",
    microserviceType: props.location?.state?.serviceType,
    environmentVariables: [],
    buildcommands: [""],
    runcommands: [""],
  });
  const [error, setError] = useState({
    versionTag: { isError: false },
    port: { isError: false },
  });

  useEffect(() => {
    props.getDataModelsListForAiTask();
  }, []);

  useEffect(() => {
    const fetchVersionEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITaskVersion",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setVersionEntitySchema(entity);
        fetchVersionDetails();
        const verData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (verData[field.datakey] = "");
              }
            });
          });
        });
        if (versionData?.microserviceType === "External") {
          delete verData["port"];
          delete verData["apirequired"];
        }

        setVersionData((prevData) => ({ ...prevData, ...verData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["dockerCommands"];
        delete errData["envs"];

        if (versionData?.microserviceType === "External") {
          delete errData["port"];
          delete errData["apirequired"];
        }
        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchVersionEntitySchema();
    fetchRepositoryDetails();
  }, []);

  useEffect(() => {
    if (versionData.microserviceType === "External") {
      let fieldsarray;
      let tempEntity = versionEntitySchema.filter(
        (item) => item.name === "AITask_Version_Header_Section"
      );
      tempEntity[0];
      //   let fieldsArray = {...tempEntity.map((item) =>
      //   item.fieldgroups.map((item) =>
      //   item.fields
      //   )
      // )}
      let tempFields = tempEntity[0].fieldgroups[0].fields.filter(
        (item) =>
          item.name !== "AITask Version Port" &&
          item.name !== "AITask Data Model" &&
          item.name !== "AITask Version Add Api"
      );
      tempEntity[0].fieldgroups[0].fields = tempFields;
      setVersionEntitySchema(tempEntity);
    }
  }, [versionData.microserviceType]);

  const fetchRuntimeEnvs = async (envId) => {
    const { _msg, _status, data } = await fetchAllRuntimeEnvironments();
    if (_status === 200) {
      setRuntimeEnvs(data ? data : []);
      setSelectedRuntimeEnv(data?.find((env) => env._id === envId));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const fetchVersionDetails = async () => {
    setIsLoader(true);
    const { _msg, _status, data } = await fetchVersionById(
      serviceId,
      versionId
    );
    if (_status === 200) {
      setVersionData((prevData) => ({
        ...prevData,
        ...data,
        buildcommands: data.docker.buildcommands,
        runcommands: data.docker.runcommands,
        apirequired: data.apirequired ? "True" : "False",
      }));
      setEnvs(
        jsonToArray(data.environmentVariables).length !== 0
          ? jsonToArray(data.environmentVariables)
          : [{ id: uuidv4(), key: "", value: "" }]
      );
      fetchRuntimeEnvs(data?.docker?.environmentID);
      setIsLoader(false);
    } else {
      setIsLoader(false);

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const fetchRepositoryDetails = async () => {
    const { _msg, _status, data } =
      await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      setRepositoryData(data ? data : {});
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setVersionData({
      ...versionData,
      [event.target.name]: value,
    });
  };

  const deleteBuildCommand = (index) => {
    versionData.buildcommands.splice(index, 1);
    setVersionData({
      ...versionData,
      buildcommands: versionData.buildcommands,
    });
  };

  const deleteRunCommand = (index) => {
    versionData.runcommands.splice(index, 1);
    setVersionData({ ...versionData, runcommands: versionData.runcommands });
  };

  const handleSubmit = async () => {
    let tempError = error;
    delete tempError["apirequired"];
    delete tempError["port"];
    setError(tempError);

    let currentVersionData = versionData;
    if (versionData?.apirequired === "True") {
      currentVersionData["apirequired"] = true;
    } else {
      currentVersionData["apirequired"] = false;
    }
    let dataModelsOnlyId = [];
    if (versionData?.microserviceType === "External") {
      delete currentVersionData["apirequired"];
      // delete versionData['buildcommands']
      delete currentVersionData["docker"];
      delete currentVersionData["environmentVariables"];
      delete currentVersionData["port"];
      delete currentVersionData["sourceCode"];
      delete currentVersionData["runtimeEnv"];
      delete currentVersionData["environmentvariables"];
      delete currentVersionData["dockerCommands"];
      delete currentVersionData["datamodels"];

      // delete versionData['runcommands']
    }
    if (versionData?.datamodels) {
      versionData?.datamodels?.map((item) => {
        dataModelsOnlyId.push(item._id);
      });

      currentVersionData["datamodels"] = dataModelsOnlyId;
    }

    const { formIsValid, errors } = handleFormValidationPro(
      currentVersionData,
      error
    );
    if (formIsValid) {
      const newVersionData = {
        ...currentVersionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: versionData.buildcommands[0]
            ? versionData.buildcommands
            : [],
          runcommands: versionData.runcommands[0]
            ? versionData.runcommands
            : [],
        },
      };
      if (newVersionData.docker.runcommands.length === 0) {
        delete newVersionData.runcommands;
      }

      setIsLoader(true);
      const { _msg, _status, data } = await editVersion(
        serviceId,
        versionId,
        newVersionData
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        fetchRepositoryDetails();
        setIsLoader(false);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }

      if (selectedRuntimeEnv?._id) {
        setIsLoader(true);
        const addEnvResp = await addRuntimeEnvironment(serviceId, versionId, {
          environmentID: selectedRuntimeEnv?._id,
        });
        if (addEnvResp._status === 200) {
          enqueueSnackbar(addEnvResp._msg, { variant: "success" });
          fetchRepositoryDetails();
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (addEnvResp._msg === "Invalid Json") {
            addEnvResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              addEnvResp._msg ? addEnvResp._msg : "Something went wrong",
              {
                variant: "error",
              }
            );
          }
        }
      }

      if (versionData.buildcommands.length !== 0) {
        setIsLoader(true);
        const editBuildResp = await editBuildCommands(
          serviceId,
          versionId,
          versionData.buildcommands[0] ? versionData.buildcommands : []
        );
        if (editBuildResp._status === 200) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (editBuildResp._msg === "Invalid Json") {
            editBuildResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              editBuildResp._msg ? editBuildResp._msg : "Something went wrong",
              {
                variant: "error",
              }
            );
          }
        }
      }

      if (versionData.runcommands.length !== 0) {
        setIsLoader(true);
        const editRunResp = await editRunCommands(
          serviceId,
          versionId,
          versionData.runcommands[0] ? versionData.runcommands : []
        );
        if (editRunResp._status === 200) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (editRunResp._msg === "Invalid Json") {
            editRunResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              editRunResp._msg ? editRunResp._msg : "Something went wrong",
              {
                variant: "error",
              }
            );
          }
        }
      }

      fetchVersionDetails();
      if (isTabbedApproach) {
        // histroy.push(`/aitask-versions/details/${serviceId}/${versionId}`);
        dispatch(editAITaskVersionTabbed(versionId));
      } else {
        histroy.push(`/aitask-versions/details/${serviceId}/${versionId}`);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        versionEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);
      setError(errors);
    }
  };
  const search = useLocation().search;

  const serviceName = new URLSearchParams(search).get("name");

  useEffect(() => {
    return () => {
      if (isTabbedApproach) {
        dispatch(closeVersion());
      }
    };
  }, []);
  const handleCancel = () => {
    dispatch(closeAITaskVersion());
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  const handleEdit = () => {
    dispatch(editAITaskVersionTabbed(versionId));
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      {isTabbedApproach ? (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{
              borderBottom: "2px solid #afafaf",
              marginTop: "-100px",
              // padding: "0px20px",
            }}
          >
            {/* <Tabs
            value={activeTab}
            onChange={handleActiveTab}
            indicatorColor="primary"
            textColor="primary"
            className={style.documentsListAccessTab}
          >
            {versionEntitySchema.length !== 0 &&
              versionEntitySchema.map((section) => {
                return <Tab label={<>{section["section Label"]} </>} />;
              })}
          </Tabs> */}
            <h2 className={styles.heading}>
              {isEditMode
                ? `Edit ${versionData.versionTag}`
                : versionData.versionTag}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabbedNavigation
                isClose={isEditMode ? false : true}
                isEditMode={isEditMode}
                handleEdit={handleEdit}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            </div>
          </Grid>
          <div style={{ padding: "20px" }}>
            <SectionTabs
              entity={versionEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />

            <CustomFieldGroupsForAiTaskVersionEdit
              section={versionEntitySchema[activeTab]}
              versionData={versionData}
              error={error}
              envs={envs}
              repositoryData={repositoryData}
              isEditMode={isEditMode}
              runtimeEnvs={runtimeEnvs}
              selectedRuntimeEnv={selectedRuntimeEnv}
              setEnvs={setEnvs}
              setError={setError}
              setSelectedRuntimeEnv={setSelectedRuntimeEnv}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              deleteBuildCommand={deleteBuildCommand}
              deleteRunCommand={deleteRunCommand}
              setVersionData={setVersionData}
              handleChange={handleChange}
            />
            <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              handleSubmit={(data) => setRepositoryData(data)}
              versionID={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
            />
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                {
                  title: "AI Task",
                  path: "/aitask-workbench",
                },
                {
                  title: versionData?.microservice_name,
                  path: `/aitask/details/${serviceId}`,
                },
                {
                  title: isEditMode
                    ? `Edit ${versionData.versionTag}`
                    : versionData.versionTag,
                },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>
                {isEditMode
                  ? `Edit ${versionData.versionTag}`
                  : versionData.versionTag}
              </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >

                {!isEditMode ? (
                  <AccordionNavigation
                    disabled={false}
                    isClose={isEditMode ? false : true}
                    isEditMode={true}
                    handleCancel={() => histroy.goBack()}
                    handleEdit={() =>
                      histroy.push(
                        `/aitask-versions/edit/${serviceId}/${versionId}`
                      )
                    }
                  />
                ) : (
                  <AccordionNavigation
                    handleSave={handleSubmit}
                    isClose={isEditMode ? false : true}
                    disabled={false}
                    isEditMode={false}
                    handleCancel={() => histroy.goBack()}
                  />
                )}
              </div>
            </Grid>
          </div>
          <div className={styles.wrapper}>
            {versionEntitySchema &&
              versionEntitySchema.map((section) => {
                if (section.name === "AITask_Version_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupsForAiTaskVersionEdit
                        section={section}
                        versionData={versionData}
                        error={error}
                        isEditMode={isEditMode}
                        setError={setError}
                        setVersionData={setVersionData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else if (versionData?.microserviceType === "External") {
                  return <></>;
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForAiTaskVersionEdit
                          section={section}
                          versionData={versionData}
                          error={error}
                          envs={envs}
                          repositoryData={repositoryData}
                          isEditMode={isEditMode}
                          runtimeEnvs={runtimeEnvs}
                          selectedRuntimeEnv={selectedRuntimeEnv}
                          setEnvs={setEnvs}
                          setError={setError}
                          setSelectedRuntimeEnv={setSelectedRuntimeEnv}
                          setOpenUploadSourceCodeModal={
                            setOpenUploadSourceCodeModal
                          }
                          deleteBuildCommand={deleteBuildCommand}
                          deleteRunCommand={deleteRunCommand}
                          setVersionData={setVersionData}
                          handleChange={handleChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              handleSubmit={(data) => setRepositoryData(data)}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              versionID={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
              versionDetails={versionData}
            />
          </div>
        </form>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  getDataModelsListForAiTask,
  editVersionTabbed,
};

export default connect(null, mapDispatchToProps)(EditAiTaskVersion);
