import React, { useEffect, useState } from "react";
import {
  Grid,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  Paper,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";
import { getFieldGroupById } from "../../../utils/dataStandards/fieldsUtils";
import { useSnackbar } from "notistack";
import {
  getFieldList,
  fetchAllFieldGroups,
} from "../../../utils/dataStandards/fieldsUtils";
import { AppButton } from "../../../components/common/Button";
import useDataStyles from "../../DataStandard/dataStandardSpecificStyles";

// Entity Schema
import { fetchObjectByID_ } from "../../../utils/ontology/ontologyUtils";
import CustomFieldGroupsForGlossaryFieldEdit from "../../../components/common/entitySchema/fieldGroups/CustomFieldGroupsForGlossaryFieldEdit";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { connect } from "react-redux";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const DetailsFieldGroupService = (props) => {
  const styles = manageScreenStyles();
  const listStyle = listStyles();
  const dataStyles = useDataStyles();
  const history = useHistory();
  const [openFieldModal, setOpenFieldModal] = useState(false);
  const [payloadData, setPayloadData] = useState([])
  const [openFieldGroupModal, setOpenFieldGroupModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = useParams();
  const [fieldsData, setFieldsData] = useState({
    name: "",
    status: "",
    type: "",
  });
  const [error, setError] = useState({
    name: false,
    status: false,
    type: false,
    description: false,
  });
  const { status } = fieldsData;

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [tab, setTab] = useState(0);

  const [isAllFieldGroups, setIsAllFieldGroups] = useState(true);
  const [isAllFields, setIsAllFields] = useState(true);

  const [modalItem, setModalItem] = useState({});
  const [loading, setLoading] = useState(false);

  const [fieldGroupTable, setFieldGroupTable] = useState([]);
  const [fieldGroupTableOrg, setFieldGroupTableOrg] = useState([]);
  const [fieldTable, setFieldTable] = useState([]);
  const [fieldTableOrg, setFieldTableOrg] = useState([]);

  const dataStandardFieldGroups = async (selectedFieldGroups) => {
    const { _msg, _status, data } = await fetchAllFieldGroups();
    if (_status === 200) {
      const fieldGroupValues =
        (data?.length &&
          data.map((item) => {
            const checkItem =
              selectedFieldGroups?.length &&
              selectedFieldGroups.findIndex(
                (selectedItem) => item["_id"] === selectedItem["_id"]
              );
            item.checked = checkItem !== -1;
            return item;
          })) ||
        [];
      setFieldGroupTable(fieldGroupValues);
      setFieldGroupTableOrg(fieldGroupValues);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const dataStandardFieldsList = async (selectedFields) => {
    const { _msg, _status, data } = await getFieldList();
    if (_status === 200) {
      const fieldValues =
        data.length &&
        data.map((item) => {
          const checkItem =
            selectedFields?.length &&
            selectedFields.findIndex(
              (selectedItem) => item["_id"] === selectedItem["_id"]
            );
          item.checked = checkItem !== -1;
          return item;
        });
      setFieldTable(fieldValues);
      setFieldTableOrg(fieldValues);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // Checkboxes
  const handleFieldCheck = (event, id) => {
    const newFieldTable = fieldTable.map((field) => {
      if (field._id === event.target.name || field._id === id) {
        return { ...field, checked: !field.checked };
      } else {
        return field;
      }
    });
    setFieldTable(newFieldTable);
  };
  const handleAllFieldCheck = (e, value) => {
    const newFieldTable = fieldTable.map((field) => {
      return { ...field, checked: value };
    });
    setFieldTable(newFieldTable);
  };

  const handleFieldGroupCheck = (event, id) => {
    const newFieldGroupTable = fieldGroupTable.map((fieldGroup) => {
      if (fieldGroup._id === event.target.name || fieldGroup._id === id) {
        return { ...fieldGroup, checked: !fieldGroup.checked };
      } else {
        return fieldGroup;
      }
    });
    setFieldGroupTable(newFieldGroupTable);
  };
  const handleAllFieldGroupCheck = (e, value) => {
    const newFieldGroupTable = fieldGroupTable.map((fieldGroup) => {
      return { ...fieldGroup, checked: value };
    });
    setFieldGroupTable(newFieldGroupTable);
  };

  const fieldCount = fieldTable.filter((res) => res.checked === true).length;
  const fieldGroupCount = fieldGroupTable.filter(
    (res) => res.checked === true
  ).length;

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("GlossaryFieldGroup", ["Read"]);
    getFieldGroupDetails();
  }, []);

  const getFieldGroupDetails = async () => {
    const { _msg, _status, data } = await getFieldGroupById(_id);
    if (_status === 200) {
      setFieldsData(data);
      if (checkCompPermission("GlossaryField", ["List All"])) {
        // dataStandardFieldsList(data?.fields);
        let tempArr = []
        tempArr.push({...data, expanded: true})
        setPayloadData(tempArr)
      }
      if (checkCompPermission("GlossaryFieldGroup", ["List All"])) {
        dataStandardFieldGroups(data?.fields);
      }
      setLoading(false);
    } else {
      setLoading(false);
      if (_msg === "Invalid Json") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const [glossaryEditEntity, setGlossaryEditEntity] = useState({});

  useEffect(async () => {
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryFieldGroup",
        "read"
      );
      if (_status === 200) {
        setGlossaryEditEntity(data);
        if (data) {
          const fgData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.datakey) {
                  return (fgData[field.datakey] = "");
                }
              });
            });
          });
          setFieldsData(fgData);
          const errData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  return (errData[field.datakey] = false);
                }
              });
            });
          });
          setError(errData);
          await getFieldGroupDetails();
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    await fetchObjectDetails();
  }, []);

  const search = useLocation().search;
  const serviceCheck = new URLSearchParams(search).get("service");
  const dataStandardCheck = new URLSearchParams(search).get("datastandards");

  // Tabbed View
  // const { tabbedView } = false;
  // const [activeTab, setActiveTab] = useState(0);

  // const handleActiveTab = (e, newValue) => {
  //   setActiveTab(newValue);
  // };

  // const handleNext = () => {
  //   setActiveTab((prev) => prev + 1);
  // };

  // const handlePrevious = () => {
  //   setActiveTab((prev) => prev - 1);
  // };

  // const handleCancel = () => {
  //   history.push("/data-standards");
  // };

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Payloads",
              path: serviceCheck
                ? "/services?service=true"
                : dataStandardCheck
                  ? "/data-standards?datastandards=true"
                  : "/aitask-workbench",
            },
            { title: fieldsData.name || "Payload Details" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {fieldsData.name || "Payload Details"}
          </h2>
          {/* {tabbedView ? (
            <TabbedNavigation
              isEditMode={false}
              activeTab={activeTab}
              handleEdit={() => {
                status !== "inactive" &&
                  history.push(
                    serviceCheck
                      ? `/fieldgroups/edit/${_id}?service=true`
                      : dataStandardCheck
                      ? `/fieldgroups/edit/${_id}?datastandards=true`
                      : `/fieldgroups/edit/${_id}`
                  );
              }}
              handleCancel={handleCancel}
            />
          ) : ( */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {status !== "inactive" && (
              <AppButton
                color="primary"
                buttonName="Edit"
                variant="contained"
                // startIcon={<img src="/images/edit.svg" alt="card" />}
                disabled={
                  !checkCompPermission("GlossaryFieldGroup", ["Edit"])
                }
                style={{ padding: "6px 15px" }}
                className="btnsmall"
                onClick={() =>
                  history.push(
                    serviceCheck
                      ? `/fieldgroups/edit/${_id}?service=true`
                      : dataStandardCheck
                        ? `/fieldgroups/edit/${_id}?datastandards=true`
                        : `/fieldgroups/edit/${_id}`
                  )
                }
              />
            )}
            <AppButton
              style={{ padding: "0", marginLeft: "20px" }}
              className={`btnsmall`}
              variant="outlined"
              onClick={() => {
                if (serviceCheck) {
                  history.push("/services?service=true");
                } else if (dataStandardCheck) {
                  history.push("/data-standards?datastandards=true");
                } else {
                  history.push("/aitask-workbench");
                }
              }}
              buttonName="Cancel"
            />
          </div>
          {/* )} */}
        </Grid>
      </div>

      {/* With Entity Schema */}
      <div className={styles.wrapper} style={{ top: "6.5rem" }}>
        {glossaryEditEntity?.sections &&
          glossaryEditEntity?.sections.map((section) => {
            if (section.name === "Glossary_Fieldgroup_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldEdit
                    section={section}
                    isEditMode={false}
                    fieldGroupData={fieldsData}
                    setFieldGroupData={setFieldsData}
                    error={error}
                    handleChange={() => { }}
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    listStyle={listStyle}
                    fieldTable={fieldTable}
                    fieldGroupTable={fieldGroupTable}
                    handleSearch={() => { }}
                    handleAllFieldCheck={handleAllFieldCheck}
                    isAllFields={isAllFields}
                    setIsAllFields={setIsAllFields}
                    handleFieldCheck={handleFieldCheck}
                    dataStyles={dataStyles}
                    setModalItem={setModalItem}
                    setOpenFieldModal={setOpenFieldModal}
                    isAllFieldGroups={isAllFieldGroups}
                    handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                    setIsAllFieldGroups={setIsAllFieldGroups}
                    handleFieldGroupCheck={handleFieldGroupCheck}
                    setOpenFieldGroupModal={setOpenFieldGroupModal}
                    fieldCount={fieldCount}
                    fieldGroupCount={fieldGroupCount}
                    fieldTableOrg={fieldTableOrg}
                    fieldGroupTableOrg={fieldGroupTableOrg}
                    modalItem={modalItem}
                    openFieldModal={openFieldModal}
                    openFieldGroupModal={openFieldGroupModal}
                    payloadData={payloadData}
                    setPayloadData={setPayloadData}
                  />
                </div>
              );
            } else if (
              section.name === "Glossary_Fieldgroup_AddFieldTable_Section"
            ) {
              return <></>;
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldEdit
                      section={section}
                      isEditMode={false}
                      fieldGroupData={fieldsData}
                      setFieldGroupData={setFieldsData}
                      error={error}
                      handleChange={() => { }}
                      tab={tab}
                      handleChangeTab={handleChangeTab}
                      listStyle={listStyle}
                      fieldTable={fieldTable}
                      fieldGroupTable={fieldGroupTable}
                      handleSearch={() => { }}
                      loading={loading}
                      handleAllFieldCheck={handleAllFieldCheck}
                      isAllFields={isAllFields}
                      setIsAllFields={setIsAllFields}
                      handleFieldCheck={handleFieldCheck}
                      dataStyles={dataStyles}
                      setModalItem={setModalItem}
                      setOpenFieldModal={setOpenFieldModal}
                      isAllFieldGroups={isAllFieldGroups}
                      handleAllFieldGroupCheck={handleAllFieldGroupCheck}
                      setIsAllFieldGroups={setIsAllFieldGroups}
                      handleFieldGroupCheck={handleFieldGroupCheck}
                      setOpenFieldGroupModal={setOpenFieldGroupModal}
                      fieldCount={fieldCount}
                      fieldGroupCount={fieldGroupCount}
                      fieldTableOrg={fieldTableOrg}
                      fieldGroupTableOrg={fieldGroupTableOrg}
                      modalItem={modalItem}
                      openFieldModal={openFieldModal}
                      openFieldGroupModal={openFieldGroupModal}
                      payloadData={payloadData}
                    setPayloadData={setPayloadData}

                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  // const { tabbedView } = state.userDetails.userInfo;
  // return { tabbedView };
};

export default connect(mapStateToProps)(DetailsFieldGroupService);
