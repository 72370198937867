import React from "react";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import TrueFalseButton2 from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton2";
import useGetData from "../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";

function HeadingAppearances() {
  const { currentPage, currentComponent, NormalHandleChange } = useGetData();
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? { ...component, [name]: value ? value : "" }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name={"fontSize"}
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <TrueFalseButton
          name="direction"
          leftButton="Horizontal"
          rightButton="Vertical"
          value={!currentComponent?.direction}
        />
      </div>
      <div className="field">
        <TrueFalseButton2
          name="alignment"
          value={currentComponent?.alignment}
        />
      </div>

      <div className="field">
        <CustomColorPicker name="status" />
      </div>
    </div>
  );
}

export default HeadingAppearances;
