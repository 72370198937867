import { useState } from "react";
import CustomEditor from "../../customEditor/CustomEditor";
import EditorInput from "../../customEditor/EditorInput";

const JSDataInput = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {isModalOpen ? (
        <CustomEditor
          name={props?.name}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          value={props?.value?.dataInput || ""}
          onChange={(value) => props?.onChange(value)}
          mode="javascript"
          title={props.title}
          error={props.error}
        />
      ) : null}

      <label>{props.label}</label>
      <EditorInput
        name={props?.name}
        value={props?.value?.dataInput || ""}
        onChange={(value) => props?.onChange(value)}
        title={props?.title}
        error={props.error}
        mode="javascript"
        height={props?.height}
        lineNumbers={props?.lineNumbers}
        accessMode={props.accessMode}
      />
      {props?.error && <p className="errorMsg">{props?.error}</p>}
    </>
  );
};

export default JSDataInput;
