import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
// import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import manageStyles from "../../CommonStyles/manageScreenStyles";

const CustomFieldGroupForDeploymentEdit = ({
  section,
  serviceData,
  setServiceData,
  error,
  deploymentname,
  DetailDeployment,
  setDescription,
  description,
  userInfo,
}) => {
  console.log('lknklnenfefe',section);
  const styles = manageStyles();
  const handleChange = (e) => {
    const value = e.target.value;
    setDescription(value);
  };

  const [projectOpen, setProjectOpen] = useState(false);
  const [mid, setMid] = useState();


  const [serviceGroupOpen, setServiceGroupOpen] = useState(false);

  const closeDropDown = () => {
    setProjectOpen(false);
    setServiceGroupOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Depask ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Name <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={projectOpen}
                onOpen={() => setProjectOpen(true)}
                onClose={() => setProjectOpen(false)}
                // options={projects}
                getOptionLabel={(option) => option?.name}
                className={`${styles.searchInput3}
                            //  ${
                              error.projectID?.isError &&
                              !selectedProject?._id &&
                              styles.error
                            }`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Project"
                  />
                )}
              />
              {/* {error.projectID?.isError && !selectedProject?._id && (
                            <p className={styles.errorMsg}>{"Project is Required"}</p>
                        )} */}
            </>
          );
        } else if (field.name === "AITask ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Service Group <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={serviceGroupOpen}
                onOpen={() => setServiceGroupOpen(true)}
                onClose={() => setServiceGroupOpen(false)}
                options={serviceGroups}
                getOptionLabel={(option) => option.name}
                disabled={!selectedProject?._id ? true : false}
                className={`${styles.searchInput3} ${
                  error.servicegroupID.isError &&
                  !selectedServiceGroup?._id &&
                  styles.error
                }`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedServiceGroup(input);
                    setServiceData({
                      ...serviceData,
                      servicegroupID: input._id,
                    });
                  } else {
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setServiceGroupSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Service Group"
                  />
                )}
              />
              {error.servicegroupID.isError && !selectedServiceGroup._id && (
                <p className={styles.errorMsg}>{"Service Group is Required"}</p>
              )}
            </>
          );
        } else if (field.name === "Deployment_Name") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={deploymentname}
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployment_Cluster") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={DetailDeployment.clustername}
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed On") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={
                  DetailDeployment.createdon
                    ? moment
                        .unix(DetailDeployment.createdon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : DetailDeployment.createdon
                }
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed_Description") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={description ? description : DetailDeployment.description}
                autoComplete="off"
                onChange={handleChange}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed By") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={DetailDeployment.createdby}
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed_NameSpace") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={DetailDeployment.namespace}
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed_Updated_By") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={
                  DetailDeployment.updatedby ? DetailDeployment.updatedby : ""
                }
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed_Updated_On") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={
                  DetailDeployment.updatedon
                    ? moment
                        .unix(DetailDeployment.updatedon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : DetailDeployment.updatedon
                }
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        } else if (field.name === "Deployed_Description") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                value={description ? description : DetailDeployment.description}
                autoComplete="off"
                onChange={handleChange}
                // disabled={true}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field?.datakey}
                className={`${styles.input2} ${
                  error[field?.datakey]?.isError && styles.error
                }`}
                // value={}
                autoComplete="off"
                // onChange={handleChange}
                disabled={true}
              />
              {/* {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>} */}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              defaultValue={DetailDeployment.description}
              onChange={handleChange}
              className={`${styles.textarea} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };


  const containerName =
  DetailDeployment?.deploymenttype != "UI Application"
    ? DetailDeployment?.deployments?.filter((dep)=>dep.dependencies == undefined)[0].container_name
    : DetailDeployment?.uiappdetails?.container_name;


    useEffect(() => {
      if(DetailDeployment?.deploymenttype == "UI Application" || DetailDeployment?.deploymenttype == 'Service Bundle'){
        setMid(DetailDeployment.deptypeid)
      }else{
        DetailDeployment?.deploymentDetails?.map((item) => {
          if (
            item.ContainerName ==
            DetailDeployment?.deployments?.filter((dep)=>dep.dependencies == undefined)[0].container_name
          )
          setMid(item.Microserviceid);
      })}
});

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Deployment_Details_Heaader_Se" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "flex" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "AITask Languageid":
                          return governanceSection();
                        case "Microservice InitializeboilerplateID":
                          return boilerPlateSection();
                        case "Documentation Table":
                          return documentsSection();
                        default:
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                            
                    }})}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "AITask Languageid":
                                return governanceSection();
                              case "Microservice InitializeboilerplateID":
                                return boilerPlateSection();
                              case "Documentation Table":
                                return documentsSection();
                              default:
                                // return (
                                //   <Grid item xs={4} sm={4} key={field._id}>
                                //     {generateField(field)}
                                //   </Grid>
                                  
                                // );
                                if (field.name == "Deployed By") {
                              return (
                                <>
                                  <Grid item xs={4} sm={4}>
                                    <Grid>
                                      <label
                                        htmlFor=""
                                        className={styles.fieldHeading}
                                      >
                                        Type
                                      </label>
                                      <input
                                        type="text"
                                        // name="service Bundle"
                                        className={styles.input2}
                                        value={
                                          DetailDeployment.deploymenttype
                                        }
                                        autoComplete="off"
                                        // onChange={handleChange}
                                        disabled={true}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={4} sm={4}>
                                    <Grid>
                                      <label
                                        htmlFor=""
                                        className={styles.fieldHeading}
                                      >
                                        Name
                                      </label>
                                      <span
                                            onClick={() =>
                                              // history.push("/dashboard")
                                              {
                                                let linkTo = "";
                                                switch (
                                                  DetailDeployment?.deploymenttype
                                                ) {
                                                  case "UI Application":
                                                    linkTo =  `/ui-applications/details/${mid}`
                                                    break;
                                                  case "AI Task":
                                                    linkTo =  `/aitask/details/${mid}`

                                              
                                                    break;

                                                  case "Service":
                                                    linkTo =  `/services/details/${mid}`
                                                    // history.push(
                                                    //   `/services/details/${mid}`
                                                    // );
                                                    break;

                                                  case "Service Bundle":
                                                    linkTo =  `/application/details/${mid}`
                                                    // history.push(
                                                    //   `/application/details/${mid}`
                                                    // );
                                                    break;

                                                  case "UI Service":
                                                    linkTo =  `/services/details/${mid}`
                                                    // history.push(
                                                    //   `/services/details/${mid}`
                                                    // );
                                                    break;
                                                }
                                                const win = window.open(linkTo, "_blank");
                                                win.focus();
                                              }
                                            }
                                          >
                                            
                                            <input
                                              className={styles.input2}
                                              type="text"
                                              value={containerName}
                                              autoComplete="off"
                                              // onChange={}
                                              disabled={true}
                                              style={{cursor:"pointer", color:'blue', textDecoration: 'underline'}}

                                            />
                                           
                                          </span>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    {generateField(field)}
                                  </Grid>
                                </>
                          );
                      }else
                      return (
                        <Grid item xs={4} sm={4} key={field._id}>
                          {generateField(field)}
                        </Grid>
                      );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForDeploymentEdit);
