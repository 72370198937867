import TriggerAction from "../../../atoms/rightSide/trigger/TriggerAction";
import ShowCondition from "../../../atoms/rightSide/trigger/ShowCondition";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";

function DetailTriggers() {
  const dispatch = useDispatch();
  const { currentProject, currentPage, currentComponent } = useGetData();

  const onChange = (name, action) => {
    let components = [];
    components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: action ? action : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <TriggerAction
          label="On Init"
          value={currentProject?.actions.find(
            (action) => action.id === currentComponent?.onInit
          )}
          onChange={(e, action) => onChange("onInit", action?._id)}
        />
      </div>
      <div className="field">
        <TriggerAction
          label="On Submit"
          id="submit"
          value={currentProject?.actions.find(
            (action) => action?._id === currentComponent?.onsubmit
          )}
          onChange={(e, action) => onChange("onsubmit", action?._id)}
        />
      </div>

      <div className="field">
        <TriggerAction
          label="On Click"
          id="click"
          value={currentProject?.actions.find(
            (action) => action?._id === currentComponent?.onClick
          )}
          onChange={(e, action) => onChange("onClick", action?._id)}
        />
      </div>

      <div style={{ borderTop: "1px solid #F0F0F0", padding: 0 }}>
        <ShowCondition />
      </div>
    </div>
  );
}

export default DetailTriggers;
