import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import {
  getProjectList,
  addNewProjectData,
} from "../../../redux/actions/projectAction";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import TeamDetailsEntity from "../../../components/common/entitySchema/teams/TeamDetailsEntity";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { getTeamById } from "../../../utils/teams/teamsUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import CreateTeamEntity from "../../../components/common/entitySchema/teams/CreateTeamEntity";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const TeamDetails = (props) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const [TeamsData, setTeamsData] = useState({});

  const [languageEntitySchema, setlanguageEntitySchema] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [TeamID] = useState(props.match.params._id);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/teams");
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Team", ["Read"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchlanguageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Team",
        "read"
      );
      if (_status === 200) {
        setlanguageEntitySchema(data);
        fetchTeamsData();
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setTeamsData({ ...TeamsData, ...tempData });
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchTeamsData = async () => {
      setIsLoader(true);

      const { _msg, _status, data } = await getTeamById(TeamID);
      if (_status === 200) {
        setTeamsData(data ? data : []);
        setIsLoader(false);
      } else {
        setIsLoader(true);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchlanguageEntitySchema();
  }, []);

  // ----------------------------------------------------------------------------------

  const handleEdit = () => {
    if (TeamsData.lock_status === "true" || TeamsData.block_status === "true") {
      enqueueSnackbar(
        "Cannot edit a Blocked or Locked Team. Kindly unblock or unlock first.",
        {
          variant: "error",
        }
      );
    } else {
      histroy.push(`/edit-team/${TeamID}`);
    }
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Teams", path: "/teams" },
              { title: `${TeamsData?.name ? TeamsData?.name : ""}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>{TeamsData?.name}</h2>
            {tabbedView ? (
              <TabbedNavigation
                // schemaLength={languageEntitySchema?.sections?.length}
                isCreateMode={false}
                activeTab={activeTab}
                // handleSave={handleSubmit}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                isClose={true}
              />
            ) : (
              // <AppButton
              //   color="primary"
              //   buttonName="Edit"
              //   variant="contained"
              //   startIcon={<img src="/images/edit.svg" alt="" />}
              //   disabled={!checkCompPermission("Team", ["Edit"])}
              //   style={{ margin: "unset" }}
              //   className="btnsmall"
              //   onClick={handleEdit}
              // />

              <AccordionNavigation
                isEditMode={true}
                handleCancel={handleCancel}
                isClose={true}
                handleEdit={handleEdit}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              {" "}
              <SectionTabs
                entity={
                  languageEntitySchema ? languageEntitySchema?.sections : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  {/* <CreateTeamEntity
                    section={languageEntitySchema?.sections?.[activeTab]}
                    teamData={teamData}
                    error={error}
                    setteamData={setteamData}
                    handleChange={handleChange}
                    vendorData={vendorData}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    isLoader={isLoader}
                    userDetails={userDetails}
                    handleAddMember={handleAddMember}
                    handleDelete={handleDelete}
                    users={users}
                  /> */}

                  <TeamDetailsEntity
                    section={languageEntitySchema?.sections?.[activeTab]}
                    TeamsData={TeamsData}
                  />
                  {languageEntitySchema?.sections &&
                    languageEntitySchema?.sections.map((section) => {
                      if (section.name === "Team_Users" && activeTab == 1) {
                        return (
                          <Grid container spacing={3}>
                            {TeamsData?.users &&
                              TeamsData?.users?.map((item) => {
                                return (
                                  <Grid item xs={3} sm={3}>
                                    <div
                                      style={{
                                        border: "1px solid #CBCBCB",
                                        borderRadius: 5,
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "10px",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={
                                            item.image
                                              ? item.image
                                              : "/images/defaultprofilepic.svg"
                                          }
                                          alt="profile"
                                          height="50px"
                                          width="50px"
                                          style={{ borderRadius: "50%" }}
                                        />
                                      </div>

                                      <div
                                        className={styles.memberDeatils}
                                        style={{
                                          marginLeft: 15,
                                          lineBreak: "anywhere",
                                        }}
                                      >
                                        <Typography
                                          className={styles.teamMemberName}
                                        >
                                          {item.name}
                                        </Typography>
                                        <Typography
                                          className={
                                            styles.teamMemberDesgination
                                          }
                                        >
                                          {item.email}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        );
                      }
                    })}
                </div>
              </Paper>
            </>
          ) : (
            <>
              {languageEntitySchema?.sections &&
                languageEntitySchema?.sections.map((section) => {
                  if (section.name === "Team_Header_section") {
                    return (
                      <div key={section._id} style={{ padding: "20px 5px 0" }}>
                        <TeamDetailsEntity
                          section={section}
                          TeamsData={TeamsData}
                        />
                      </div>
                    );
                  } else if (section.name === "Team_Users") {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            padding: "0px !important",
                          }}
                        >
                          <div
                            style={{
                              margin: "15px 0 100px 0",
                              padding: "0 !important",
                            }}
                          >
                            <Grid container spacing={3}>
                              {TeamsData?.users &&
                                TeamsData?.users?.map((item) => {
                                  return (
                                    <Grid item xs={3} sm={3}>
                                      <div
                                        style={{
                                          border: "1px solid #CBCBCB",
                                          borderRadius: 5,
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <div>
                                          <img
                                            src={
                                              item.image
                                                ? item.image
                                                : "/images/defaultprofilepic.svg"
                                            }
                                            alt="profile"
                                            height="50px"
                                            width="50px"
                                            style={{ borderRadius: "50%" }}
                                          />
                                        </div>

                                        <div
                                          className={styles.memberDeatils}
                                          style={{
                                            marginLeft: 15,
                                            lineBreak: "anywhere",
                                          }}
                                        >
                                          <Typography
                                            className={styles.teamMemberName}
                                          >
                                            {item.name}
                                          </Typography>
                                          <Typography
                                            className={
                                              styles.teamMemberDesgination
                                            }
                                          >
                                            {item.email}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TeamDetailsEntity
                            section={section}
                            TeamsData={TeamsData}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </>
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addNewProjectData,
};

const mapStateToProps = (state) => {
  const { newProjectData } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    newProjectData,
    tabbedView,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
