import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Button,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import {
  editMicroService,
  fetchAllBoilerPlates,
  fetchServiceById,
  fetchVersionListData,
  getRepositoryDetailsByMicroServiceVersion,
} from "../../../utils/project/microserviceUtils";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect, useSelector } from "react-redux";
import {
  getVersionList,
  getAPIList,
  clearVersions,
  clearAPIs,
  addVersionHidden,
  addVersionSort,
  addAPISort,
  addAPIHidden,
  toggleVersionLoading,
  tabbedActiveTabAction,
  closeVersion,
  closeAPI,
} from "../../../redux/actions/serviceAction";
import {
  getTableData,
  getWindowDimensions,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import { fetchLanguageDetailsByID } from "../../../utils/applicationGovernance/launguageUtils";
import Loader from "../../../components/common/stuff/Loader";
import { imageDetails } from "../../../utils/containerMangement/imageUtils";
import {
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
} from "../../../redux/actions/documentAction";
import { arrayToFilter } from "../../../utils/common";
import { arrayToSort, arrayToSort2 } from "../../../utils/common";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForServiceEdit from "../../../components/common/entitySchema/service/CustomFieldGroupsForServiceEdit";
import {
  versionColumnHidden,
  versionColumnOrder,
  versionColumns,
  versionDefaultColumnWidths,
  versionTableColumnExtensions,
} from "../../../constants/versionsConstant";
import {
  apiColumnHidden,
  apiColumnOrder,
  apiColumns,
  apiDefaultColumnWidths,
  apiTableColumnExtensions,
} from "../../../constants/apisConstant";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const EditService = (props) => {
  const {
    service_documents_list,
    add_service_document_data,
    serviceDocumentsFilters,
    serviceDocumentSort,
    versionHidden,
    addVersionHidden,
    addVersionSort,
    versionSort,
    getVersionList,
    getAPIList,
    versionsList,
    apisList,
    clearVersions,
    clearAPIs,
    addAPIHidden,
    addAPISort,
    apiSort,
    apiHidden,
    toggleVersionLoading,
    tabbedView,
    editVersion,
    detailVersion,
    detailAPI,
    editAPI,
    serviceActiveTab,
    tabbedActiveTab,
    tabbedActiveTabAction,
    closeVersion,
    closeAPI,
  } = props;
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const [currentPageServiceDocumentList, setcurrentPageServiceDocumentList] =
    useState(1);
  const [serviceId] = useState(props.match.params.serviceId);
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [languages, setLanguages] = useState([]);
  const [languageDetails, setLanguageDetails] = useState({});
  const [versions, setVersions] = useState([]);
  const [apiVersions, setApiVersions] = useState([]);
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [currentVersionPage, setCurrentVersionPage] = useState(1);
  const [currentAPIPage, setCurrentAPIPage] = useState(1);
  const [createVersion, setCreateVersion] = useState(false);
  const [createAPI, setCreateAPI] = useState(false);
  const [apis, setApis] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [comingFromDetailsPage, setcomingFromDetailsPage] = useState(false);

  const [serviceData, setServiceData] = useState({
    _id: "",
    name: "",
    description: "",
    status: "",
    imagename: "",
    languageID: "",
    initializeboilerplateID: false,
    boilerplateID: "",
    createdon: "",
    servicegroupID: "",
    projectID: "",
    projectDetails: {},
    servicegroupdetails: {},
  });
  const [error, setError] = useState({
    name: { isError: false },
    languageID: { isError: false },
  });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { state } = useLocation();

  useEffect(() => {
    if (state === 'version') {
      setActiveTab(4)
      tabbedActiveTabAction(4);
    }
  }, [state])


  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [versionTableLoading, setVersionTableLoading] = useState(true);
  const [versionTableData, setVersionTableData] = useState({
    columns: versionColumns,
    widths: versionDefaultColumnWidths,
    extentions: versionTableColumnExtensions,
    orders: versionColumnOrder,
    hides: [],
  });
  const [apiTableLoading, setApiTableLoading] = useState(true);
  const [apiTableData, setApiTableData] = useState({
    columns: apiColumns,
    widths: apiDefaultColumnWidths,
    extentions: apiTableColumnExtensions,
    orders: apiColumnOrder,
    hides: [],
  });


  useEffect(() => {
    const fetchVersionFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Version");
      if (_status === 200) {
        let versData = getTableData(
          data,
          versionColumns,
          versionDefaultColumnWidths,
          versionTableColumnExtensions,
          versionColumnOrder,
          versionHidden
        );
        setVersionTableData(versData);
        if (
          JSON.stringify(versionHidden) === JSON.stringify(versionColumnHidden)
        ) {
          addVersionHidden(versData.hides);
        }
        setVersionTableLoading(false);
      } else {
        setVersionTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchApiFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("API");
      if (_status === 200) {
        let apData = getTableData(
          data,
          apiColumns,
          apiDefaultColumnWidths,
          apiTableColumnExtensions,
          apiColumnOrder,
          apiHidden
        );
        const tempApData = {
          ...apData,
          columns: apData.columns.filter((col) => col.title !== "Password"),
          hides: apData.hides.filter((h) => h.split("_")[2] !== "password"),
        };
        setApiTableData(tempApData);
        if (JSON.stringify(apiHidden) === JSON.stringify(apiColumnHidden)) {
          addAPIHidden(tempApData.hides);
        }
        setApiTableLoading(false);
      } else {
        setApiTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchVersionFields();
    fetchApiFields();
  }, []);

  function createVersionTableData(versionData) {
    const {
      _id,
      versionTag,
      port,
      status,
      fileName,
      isUpload,
      repoId,
      imagename,
      environmentID,
    } = versionData;
    return {
      ...versionData,
      id: _id,
      versionTag: versionTag,
      port: port,
      fileName: fileName,
      imageStatus: status,
      action: serviceId,
      ImageID: versionData?.docker?.imagedetails?.ImageID,
      Instances: versionData?.docker?.Instances,
      isUpload: isUpload,
      repoId: repoId,
      imagename: imagename,
      environmentID: environmentID,
      serviceType: serviceData?.serviceType,
    };
  }

  function createDocumentTableData(docData) {
    const {
      _id,
      docName,
      filename,
      name,
      resourcetype,
      version,
      createdon,
      documenttype,
      status
    } = docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
      action: serviceId,
      status: status,
      statuses: statuses,
    };
  }

  function createApiTableData(apiData) {
    const { _id, name, type, createdon, createdby, selectedInstance, topic } =
      apiData;
    return {
      ...apiData,
      id: _id,
      name: name,
      type: type,
      topic: topic,
      createdby: createdby,
      createdon: createdon,
      action: serviceId,
      version: selectedVersion?.id,
      versionStatus: selectedVersion?.imageStatus,
      Instances: selectedVersion?.Instances,
      selectedInstance: selectedInstance,
      environmentID: selectedVersion?.environmentID,
      serviceType: serviceData?.serviceType,
    };
  }

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission("Microservice", ["Edit"]);
    } else {
      checkScreenPermission("Microservice", ["Read"]);
    }
    const fetchServiceEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Microservice",
        isEditMode ? "edit" : "read"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceEntitySchema(entity);
        fetchServiceDetails();
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });
        setServiceData((prevData) => ({ ...prevData, ...servData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        delete errData["boilerplateID"];
        delete errData["status"];
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchServiceDetails = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchServiceById(serviceId);
      if (_status === 200) {
        setServiceData((prevData) => ({ ...prevData, ...data }));
        if (data.languageID) {
          if (checkCompPermission("Language", ["Read"])) {
            fetchLanguageDetails(data.languageID);
          }
          if (checkCompPermission("Language", ["List All"])) {
            fetchLanguagesList(data.projectID);
          }
          setIsLoader(false);
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    };

    const fetchLanguagesList = async (projectID) => {
      const { _msg, _status, data } = await fetchLanguagesByProject(projectID);
      if (_status === 200) {
        setLanguages(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchAllBoilerPlates();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchStatusesByResource = async (resource, callback) => {
      const { _msg, _status, data } = await fetchStatusResourceByName(resource);
      if (_status === 200) {
        callback(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource("Microservice", (data) =>
        setStatuses(data ? data.status : [])
      );
    }

    fetchServiceEntitySchema();
    // fetchServiceDetails();
    if (checkCompPermission("Boilerplate", ["List All"])) {
      fetchBoilerPlates();
    }
    fetchAPIVersions(JSON.stringify({}));
    // fetchVersionsList();
  }, [isEditMode, serviceId]);

  useEffect(() => {
    if (serviceData.serviceType === "External") {
      let tempEntity = serviceEntitySchema.filter(
        (item) => item.name !== "Microservice_Boilerplate_Section"
      );
      setServiceEntitySchema(tempEntity);
    }
  }, [serviceData.serviceType]);

  const fetchAPIVersions = async (filter) => {
    const { _msg, _status, data } = await fetchVersionListData(
      serviceId,
      filter
    );
    if (_status === 200) {
      setApiVersions(data ? data : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchAPIVersions();
  }, [createVersion, versions]);

  const fetchDockerImageDetails = async (imageId) => {
    const { _msg, _status, data } = await imageDetails(imageId);
    if (_status === 200) {
      return data;
    } else {
      return {};
    }
  };

  const fetchRepositoryDetails = async (versionId) => {
    const { _msg, _status, data } =
      await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      return data;
    } else {
      return false;
    }
  };

  // useEffect(()=>{
  //   fetchAPIVersions();
  // },[createVersion])

  useEffect(() => {
    const parsedSort = arrayToSort(versionSort);
    getVersionList(serviceId, currentVersionPage, JSON.stringify(parsedSort));
  }, [
    serviceId,
    currentVersionPage,
    versionSort,
    createVersion,
    detailVersion,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const tempVersions = [];
    toggleVersionLoading(true);
    for (
      var i = 0;
      versionsList?.data?.data?.length > 0 &&
      i < versionsList?.data?.data?.length;
      i++
    ) {
      let imageData = {};
      if (
        checkCompPermission("DockerImage", ["Read"]) &&
        versionsList.data.data[i]?.docker?.imagedetails
      ) {
        imageData = await fetchDockerImageDetails(
          versionsList?.data?.data[i]?.docker?.imagedetails?.ImageID
        );
      }
      if (checkCompPermission("RuntimeEnvironment", ["Read"])) {
        const repoData = await fetchRepositoryDetails(
          versionsList?.data?.data[i]?._id
        );
        if (repoData) {
          imageData["filename"] = repoData?.filename;
          imageData["isUpload"] = true;
          imageData["repoId"] = repoData?._id;
        } else {
          imageData["isUpload"] = false;
        }
      }

      tempVersions.push(
        createVersionTableData({
          ...versionsList?.data?.data[i],
          status: imageData?.state,
          fileName: imageData?.filename,
          isUpload: imageData?.isUpload,
          repoId: imageData?.repoId,
          imagename: imageData?.imagename,
          environmentID: versionsList.data.data[i]?.docker?.environmentID,
        })
      );
    }
    toggleVersionLoading(false);
    setVersions(tempVersions);
    setSelectedVersion(tempVersions[0]);
  }, [serviceId, versionsList?.data?.data]);

  useEffect(() => {
    return () => {
      clearVersions();
      clearAPIs();
    };
  }, []);

  const fetchLanguageDetails = async (languageId) => {
    const { _msg, _status, data } = await fetchLanguageDetailsByID(languageId);
    if (_status === 200) {
      setLanguageDetails(data ? data : {});
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPageServiceDocumentList, serviceDocumentSort]);
  const fetchDocuments = () => {
    if (checkCompPermission("Document", ["List All", "List Assigned"])) {
      const parsedFilters = arrayToFilter([
        ...serviceDocumentsFilters,
        { resourcetype: "microservice" },
        { resourceid: serviceId },
      ]);
      const parsedSort = arrayToSort2(serviceDocumentSort);
      add_service_document_data(
        currentPageServiceDocumentList,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  };

  useEffect(() => {
    setActiveTab(tabbedActiveTab);
    // return () => {
    //   tabbedActiveTabAction(0)
    // }
  }, [tabbedActiveTab]);

  const documents =
    service_documents_list.data && service_documents_list.data.length > 0
      ? service_documents_list.data.map((item) => {
        const rowData = createDocumentTableData(item);
        return rowData;
      })
      : [];

  const handleServiceDocumentListPagination = (event, value) => {
    setcurrentPageServiceDocumentList(value);
  };

  useEffect(() => {
    if (selectedVersion?.id) {
      const parsedSort = arrayToSort(apiSort);
      getAPIList(
        serviceId,
        selectedVersion.id,
        currentAPIPage,
        JSON.stringify(parsedSort)
      );
    }
  }, [selectedVersion, currentAPIPage, apiSort, createAPI, detailAPI]);

  useEffect(async () => {
    const rows =
      apisList?.data && apisList.data?.length > 0
        ? apisList?.data?.map((item) => {
          const rowData = createApiTableData(item);
          return rowData;
        })
        : [];
    setApis(rows);
  }, [apisList]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };
  useEffect(() => {
    if (serviceData?.serviceType === "External") {
      let tempError = error;
      let tempServiceData = serviceData;
      delete tempServiceData["imagename"];
      delete tempError["imagename"];
      setError(tempError);
      setServiceData(tempServiceData);
    }
  }, [serviceData.serviceType]);

  useEffect(() => {
    return () => {
      if (isTabbedApproach) {

        closeVersion();
        closeAPI()
      }
    }
  }, [])
  const handleSubmit = async () => {
    if (isTabbedApproach) {
      closeVersion();
      closeAPI();
      setCreateVersion(false);
    }
    let newServiceData;
    if (languageDetails !== {}) {
      newServiceData = {
        ...serviceData,
        languageID: languageDetails?._id,
        languageName: languageDetails?.name,
        initializeboilerplateID: serviceData.boilerplateID ? true : false,
      };
    } else {
      newServiceData = {
        ...serviceData,
        languageID: "",
        languageName: "",
        initializeboilerplateID: serviceData.boilerplateID ? true : false,
      };
      setError({
        name: { isError: false },
        languageID: { isError: false },
      });
    }
    const { formIsValid, errors } = handleFormValidationPro(
      newServiceData,
      error
    );

    if (formIsValid) {
      delete newServiceData["_id"];
      delete newServiceData["createdby"];
      delete newServiceData["createdon"];
      delete newServiceData["updatedby"];
      delete newServiceData["updatedon"];
      delete newServiceData["projectID"];
      delete newServiceData["projectDetails"];
      delete newServiceData["servicegroupdetails"];
      delete newServiceData["servicegroupID"];
      delete newServiceData["servicegroupid"];
      // setIsLoader(true);
      const { _msg, _status, data } = await editMicroService(
        serviceId,
        newServiceData
      );
      if (_status === 200) {
        if (isTabbedApproach) {
          setCreateVersion(false);
          setCreateAPI(false);
        }
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        // setServiceData(data);
        if (checkCompPermission("Language", ["Read"]) && data.languageID) {
          fetchLanguageDetails(data.languageID);
        }

        histroy.push(`/services/details/${serviceId}`);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      if (isTabbedApproach) {
        setCreateVersion(false);
        setCreateAPI(false);
      }
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  console.log("ajvasshvjhvjvh", createVersion)

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };
  useEffect(() => {
    if (isEditMode) {
      setcomingFromDetailsPage(false);
    } else {
      setcomingFromDetailsPage(true);
    }
  }, []);
  useEffect(() => {
    if (serviceData.serviceType === "External") {
      let tempEntity = serviceEntitySchema;
      tempEntity.map((section) => {
        if (section.name === "Microservice_Header_Section") {
          section.fieldgroups.map((fieldGroup) => {
            fieldGroup.fields.map((field) => {
              if (field.name === "Image Name") fieldGroup.fields.splice(4, 1);
            });
          });
        }
      });
    }
  }, [serviceData.serviceType, serviceEntitySchema]);

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {

    if (!isEditMode) {
      histroy.push(`/services`);
    } else {
      comingFromDetailsPage
        ? histroy.push(`/services/details/${serviceId}`)
        : histroy.push(`/services`);
    }
    tabbedActiveTabAction(0);
  };

  const handleGoList = () => {
    histroy.push(`/services`);
    tabbedActiveTabAction(0);
  };

  // console.log(
  //   "serviceId-",
  //   serviceId,
  //   "-----",
  //   "id-",
  //   versionsList?.data?.data[i]?._id
  const handleEdit = () => {

    histroy.push(`/services/edit/${serviceId}`);
  };
  // );

  return (
    <main className={styles.main}>
      {/* {isLoader && <Loader />} */}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: serviceActiveTab === "uiSimulator"
                  ? "UI Service"
                  : "Service",
                path: "/services?service=true",
              },
              {
                title: isEditMode
                  ? `Edit ${serviceData.name}`
                  : serviceData.name,
              },
            ]}
          />

          {isTabbedApproach ? (
            <Grid container direction="row" justify="space-between">
              <h2 className={styles.heading}>
                {isEditMode ? `Edit ${serviceData.name}` : serviceData.name}
              </h2>
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // ,width:`${(activeTab === serviceEntitySchema.length -1 || activeTab === 0 )} ? "15%" : "10%"`
                  }}
                >
                  {((createVersion || editVersion) && (createAPI || editAPI) && (activeTab === 5 || activeTab === 4)) ? <></> :
                    (((!createVersion && !createAPI) && (!editAPI && !editVersion)) || ((createVersion || editVersion) && activeTab !== 4) || ((createAPI || editAPI) && activeTab !== 5)) && <TabbedNavigation
                      isEditMode={isEditMode}
                      handleEdit={handleEdit}
                      isClose={isEditMode ? false : true}
                      handleSave={handleSubmit}
                      // handleCancel={()=> isEditMode ? histroy.push(`/services/details/${serviceId}`):histroy.push(`/services`) }
                      handleCancel={handleCancel}
                    />}
                </div>
              }
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>
                {isEditMode ? `Edit ${serviceData.name}` : serviceData.name}
              </h2>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &nbsp;&nbsp;
                {!isEditMode ? (
                  <AccordionNavigation
                    handleEdit={handleEdit}
                    disabled={!checkCompPermission("Microservice", ["Edit"])}
                    handleCancel={handleCancel}
                    isClose={isEditMode ? false : true}
                    isEditMode={true}
                  />
                ) : (
                  <AccordionNavigation
                    disabled={!checkCompPermission("Microservice", ["Edit"])}
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    isEditMode={false}
                    isClose={isEditMode ? false : true}
                  />
                )}
              </div>
            </Grid>
          )}
        </div>

        <div className={styles.wrapper}>
          {isTabbedApproach ? (
            <Grid style={{ marginTop: "10px", borderTop: "2px solid #afafaf" }}>
              <SectionTabs
                entity={serviceEntitySchema}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />

              <Paper style={{ padding: "15px 20px" }}>
                <div style={{ padding: "20px 20px" }}>
                  <CustomFieldGroupsForServiceEdit
                    section={serviceEntitySchema[activeTab]}
                    isEditMode={isEditMode}
                    serviceData={serviceData}
                    error={error}
                    setError={setError}
                    serviceId={serviceId}
                    statuses={statuses}
                    versions={versions}
                    versionsList={versionsList}
                    currentVersionPage={currentVersionPage}
                    createVersion={createVersion}
                    // setCreateVersion={setCreateVersion}
                    setCreateVersion={setCreateVersion}
                    editVersion={editVersion}
                    detailVersion={detailVersion}
                    createAPI={createAPI}
                    setCreateAPI={setCreateAPI}
                    editAPI={editAPI}
                    apis={apis}
                    apisList={apisList}
                    currentAPIPage={currentAPIPage}
                    selectedVersion={selectedVersion}
                    languages={languages}
                    boilerplates={boilerplates}
                    languageDetails={languageDetails}
                    documents={documents}
                    windowDimensions={windowDimensions}
                    versionTableLoading={versionTableLoading}
                    versionTableData={versionTableData}
                    versionHidden={versionHidden}
                    addVersionHidden={addVersionHidden}
                    versionSort={versionSort}
                    addVersionSort={addVersionSort}
                    apiTableData={apiTableData}
                    apiTableLoading={apiTableLoading}
                    apiHidden={apiHidden}
                    addAPIHidden={addAPIHidden}
                    apiSort={apiSort}
                    addAPISort={addAPISort}
                    apiVersions={apiVersions}
                    fetchDocuments={fetchDocuments}
                    setServiceData={setServiceData}
                    setCurrentVersionPage={setCurrentVersionPage}
                    setLanguageDetails={setLanguageDetails}
                    setCurrentAPIPage={setCurrentAPIPage}
                    handleChange={handleChange}
                    setSelectedVersion={setSelectedVersion}
                    handleServiceDocumentListPagination={
                      handleServiceDocumentListPagination
                    }
                    setcurrentPageServiceDocumentList={
                      setcurrentPageServiceDocumentList
                    }
                    currentPageServiceDocumentList={
                      currentPageServiceDocumentList
                    }
                    service_documents_list={service_documents_list}
                    appBundleId={appBundleId}
                  />
                </div>
              </Paper>
            </Grid>
          ) : (
            serviceEntitySchema &&
            serviceEntitySchema.map((section, index) => {
              if (section.name === "Microservice_Header_Section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <CustomFieldGroupsForServiceEdit
                      section={section}
                      isEditMode={isEditMode}
                      setCreateVersion={setCreateVersion}
                      serviceData={serviceData}
                      error={error}
                      setError={setError}
                      setServiceData={setServiceData}
                      handleChange={handleChange}
                    />
                  </div>
                );
              } else if (
                serviceData.serviceType === "External" &&
                section.name === "Microservice_Boilerplate_Section"
              ) {
                return <></>;
              } else {
                return (
                  <Element name={index}>
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForServiceEdit
                          section={section}
                          isEditMode={isEditMode}
                          serviceData={serviceData}
                          error={error}
                          setError={setError}
                          serviceId={serviceId}
                          statuses={statuses}
                          versions={versions}
                          versionsList={versionsList}
                          currentVersionPage={currentVersionPage}
                          apis={apis}
                          apisList={apisList}
                          currentAPIPage={currentAPIPage}
                          createVersion={createVersion}
                          setCreateAPI={setCreateAPI}
                          setCreateVersion={setCreateVersion}
                          selectedVersion={selectedVersion}
                          languages={languages}
                          boilerplates={boilerplates}
                          languageDetails={languageDetails}
                          documents={documents}
                          windowDimensions={windowDimensions}
                          versionTableLoading={versionTableLoading}
                          versionTableData={versionTableData}
                          versionHidden={versionHidden}
                          addVersionHidden={addVersionHidden}
                          versionSort={versionSort}
                          addVersionSort={addVersionSort}
                          apiTableData={apiTableData}
                          apiTableLoading={apiTableLoading}
                          apiHidden={apiHidden}
                          addAPIHidden={addAPIHidden}
                          apiSort={apiSort}
                          addAPISort={addAPISort}
                          apiVersions={apiVersions}
                          fetchDocuments={fetchDocuments}
                          setServiceData={setServiceData}
                          setCurrentVersionPage={setCurrentVersionPage}
                          setLanguageDetails={setLanguageDetails}
                          setCurrentAPIPage={setCurrentAPIPage}
                          handleChange={handleChange}
                          setSelectedVersion={setSelectedVersion}
                          handleServiceDocumentListPagination={
                            handleServiceDocumentListPagination
                          }
                          setcurrentPageServiceDocumentList={
                            setcurrentPageServiceDocumentList
                          }
                          currentPageServiceDocumentList={
                            currentPageServiceDocumentList
                          }
                          service_documents_list={service_documents_list}
                          appBundleId={appBundleId}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Element>
                );
              }
            })
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getVersionList,
  getAPIList,
  clearVersions,
  clearAPIs,
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
  addVersionHidden,
  addVersionSort,
  addAPISort,
  addAPIHidden,
  toggleVersionLoading,
  tabbedActiveTabAction,
  closeVersion,
  closeAPI,
};

const mapStateToProps = (state) => {
  const {
    versionsList,
    apisList,
    versionHidden,
    versionSort,
    apiSort,
    apiHidden,
    editVersion,
    detailVersion,
    detailAPI,
    editAPI,
    tabbedActiveTab,
  } = state.serviceReducer;
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;
  const {
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
  } = state.allDocumentsReducer;
  const { serviceActiveTab } = state.activeTabReducer;

  return {
    versionsList,
    apisList,
    userInfo,
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
    versionHidden,
    versionSort,
    apiHidden,
    apiSort,
    tabbedView,
    editVersion,
    detailVersion,
    detailAPI,
    editAPI,
    serviceActiveTab,
    tabbedActiveTab,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditService);
