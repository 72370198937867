import { Divider, Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
// files
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import {
  envColumns,
  envDefaultColumnWidths,
  envTableColumnExtensions,
  envColumnOrder,
  envColumnHidden,
} from "../../../constants/ContainerManagement/envListTableConstant";
import { useTranslation } from "react-i18next";
import {
  arrayToFilter,
  arrayToSort2,
  arrayToSort,
} from "../../../utils/common";

// redux
import { connect } from "react-redux";
import {
  add_runtimeEnv_data,
  add_runtimeEnv_filters,
  add_runtimeEnv_sort,
  add_runtimeENV_hidden_cols,
  clear_runtime_env_data,
} from "../../../redux/actions/runtimeEnvAction";
import CustomFilter from "../../../components/common/CustomFilter";
import Pagination from "@material-ui/lab/Pagination";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const EnvListScreen = (props) => {
  const {
    data,
    add_runtimeEnv_data,
    loading,
    add_runtimeEnv_filters,
    EnvFilters,
    add_runtimeEnv_sort,
    runtimeEnvSort,
    clear_runtime_env_data,
    _totalcount,
    add_runtimeENV_hidden_cols,
    ENVHiddenCols,
    alterActiveTab,
    deleteRuntimeEnvStatus,
  } = props;
  const styles = listScreenStyles();
  const histroy = useHistory();
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: envColumns,
    widths: envDefaultColumnWidths,
    extentions: envTableColumnExtensions,
    orders: envColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    alterActiveTab("Runtime Environment", "Runtime Environment");
  }, []);

  useEffect(() => {
    checkScreenPermission("RuntimeEnvironment", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "RuntimeEnvironment"
      );
      if (_status === 200) {
        const projData = getTableData(
          data,
          envColumns,
          envDefaultColumnWidths,
          envTableColumnExtensions,
          envColumnOrder,
          envColumnHidden,
          "environmentvaribales"
        );
        setTableData(projData);
        if (JSON.stringify(ENVHiddenCols) === JSON.stringify(envColumnHidden)) {
          add_runtimeENV_hidden_cols(projData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  // Api call
  useEffect(() => {
    const parsedFilters = arrayToFilter(EnvFilters);
    const parsedSort = arrayToSort(runtimeEnvSort);
    add_runtimeEnv_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, runtimeEnvSort]);

  useEffect(() => {
    if(deleteRuntimeEnvStatus === true){ 
      const parsedFilters = arrayToFilter(EnvFilters);
      const parsedSort = arrayToSort(runtimeEnvSort);
      add_runtimeEnv_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
    }
  }, [deleteRuntimeEnvStatus]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function createData(runtimeEnv_data) {
    const { _id, name, state, createdby, createdon, updatedby, updatedon } =
      runtimeEnv_data;
    return {
      ...runtimeEnv_data,
      _id,
      name,
      state: state ? state.charAt(0).toUpperCase() + state.slice(1) : "",
      createdby,
      createdon: createdon,
      updatedby,
      updatedon,
    };
  }

  const rows =
    data?.length > 0
      ? data?.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];
  // filter

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(EnvFilters);
    const parsedSort = arrayToSort2(runtimeEnvSort);
    add_runtimeEnv_data(0, parsedFilters, JSON.stringify(parsedSort));
  };
  const handleClearFilters = () => {
    add_runtimeEnv_data(0, JSON.stringify({}));
  };

  // clear reducer .... code changes done here

  useEffect(() => {
    return () => {
      clear_runtime_env_data();
    };
  }, []);

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      add_runtimeEnv_data(
        1,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_runtimeEnv_data(1);
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Runtime Environments",
                path: "/runtime-environments",
              },
              { title: "Runtime  Environments List" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("runtimeEnvs.runtimeEnvs")}{" "}
                {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Environment Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData.columns}
                filters={EnvFilters}
                setFilters={add_runtimeEnv_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="Create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={
                  !checkCompPermission("RuntimeEnvironment", ["Create"])
                }
                className="btnsmall"
                onClick={() => histroy.push("/create-environment")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {tableLoading ? (
            <Loader />
          ) : (
            <ApplicationListTable
              columns={tableData.columns}
              rows={rows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              defaultHiddenColumnNames={tableData.hides}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isCustomHeight={true}
              sort={runtimeEnvSort}
              setSort={add_runtimeEnv_sort}
              hidden={ENVHiddenCols}
              setHidden={add_runtimeENV_hidden_cols}
            />
          )}
        </Paper>
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_runtimeEnv_data,
  add_runtimeEnv_filters,
  add_runtimeEnv_sort,
  add_runtimeENV_hidden_cols,
  clear_runtime_env_data,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { runtimeEnv_list, EnvFilters, runtimeEnvSort, ENVHiddenCols } =
    state.runtimeEnvReducer;
  const { data, error, loading, _totalcount, deleteRuntimeEnvStatus } = runtimeEnv_list;
  return {
    data,
    error,
    loading,
    EnvFilters,
    runtimeEnvSort,
    _totalcount,
    ENVHiddenCols,
    deleteRuntimeEnvStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvListScreen);
