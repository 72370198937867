import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { alpha, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const useStyles = makeStyles({
    tree: {
    }
})

const CustomTree = (props) => {
    const styles = useStyles();
    let id = 0;

    const treeData = {
        id: 'root',
        name: props.parentData.name,
        children: props?.childData?.map(g1 => {
            id++
            return {
                id: id.toString(),
                name: g1.name,
                children: [
                    ...g1?.microservices?.map(s1 => {
                        id++
                        return {
                            id: id.toString(),
                            name: s1.name,
                        }
                    }),
                    ...g1?.servicegroups?.map(g2 => {
                        id++
                        return {
                            id: id.toString(),
                            name: g2.name,
                            children: [
                                ...g2?.microservices.map(s2 => {
                                    id++
                                    return {
                                        id: id.toString(),
                                        name: s2.name,
                                    }
                                }),
                                ...g2.servicegroups.map(g3 => {
                                    id++
                                    return {
                                        id: id.toString(),
                                        name: g3.name,
                                        children: [
                                            ...g3?.microservices.map(s3 => {
                                                id++
                                                return {
                                                    id: id.toString(),
                                                    name: s3.name,
                                                }
                                            }),
                                            ...g3.servicegroups.map(g4 => {
                                                id++
                                                return {
                                                    id: id.toString(),
                                                    name: g4.name,
                                                    children: [
                                                        ...g4?.microservices.map(s4 => {
                                                            id++
                                                            return {
                                                                id: id.toString(),
                                                                name: s4.name,
                                                            }
                                                        }),
                                                    ]
                                                }
                                            })
                                        ]
                                    }
                                })
                            ]
                        }
                    })
                ]
            }
        }),
    };

    const StyledTreeItem = withStyles((theme) => ({
        label: {
            fontSize: 14,
            color: '#000',
            padding: '3px 10px'
        },
        iconContainer: {
            // '& .close': {
            //     opacity: 0.3,
            // },
        },
        selected: {
            // background: 'red'
        },
        group: {
            paddingLeft: 15,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }))((props) => <TreeItem {...props} />);

    const renderTree = (nodes) => (
        <StyledTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    )

    return (
        <TreeView
            className={styles.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root', ...Array.apply(null, { length: 100 }).map((value, index) => index.toString())]}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {renderTree(treeData)}
        </TreeView>
    )
}

export default CustomTree
