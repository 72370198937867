const documentColumns = [
  // { name: 'id', title: 'Document ID' },
  { name: "docName", title: "Document Name", for: "document" },
  { name: "name", title: "Document type", for: "document" },
  // { name: "linkedTo", title: "Linked To", for: "document" },
  { name: "versionNumber", title: "Version Number", for: "document" },
  { name: "createdon", title: "Created On", for: "document" },
  { name: "status", title: "Status", for: "document" },
  // { name: 'importedFrom', title: 'Imported From', for: 'document' },
  { name: "action", title: "Action", route: "/edit", for: "document" },
];

const documentDefaultColumnWidths = [
  // { columnName: 'id', width: 230 },
  { columnName: "docName", weightage: 2.5},
  { columnName: "name", weightage: 10},
  // { columnName: "linkedTo", width: 180 },
  { columnName: "versionNumber", weightage: 10 },
  { columnName: "createdon", weightage: 2.5 },
  { columnName: "status", weightage: 10 },
  // { columnName: 'importedFrom', width: 180 },
  { columnName: "action", weightage: 5 },
];

const documentTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "docName", align: "left" },
  { columnName: "name", align: "left" },
  // { columnName: "linkedTo", align: "left" },
  { columnName: "versionNumber", align: "center" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  // { columnName: 'importedFrom', align: 'left' },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const documentColumnOrder = [
  // 'id',
  "docName",
  "name",
  // "linkedTo",
  "versionNumber",
  "createdon",
  "status",
  // 'importedFrom',
  "action",
];

const documentDefaultSorting = [];

// const documentRows = [
//   {
//     id: 12345,
//     documentName: "Doc 1",
//     // linkedTo: "Service 1",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
//   {
//     id: 12345,
//     documentName: "Doc 2",
//     // linkedTo: "Service 2",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
//   {
//     id: 12346,
//     documentName: "Doc 3",
//     // linkedTo: "Service 1",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
//   {
//     id: 12347,
//     documentName: "Doc 4",
//     // linkedTo: "Service 3",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
//   {
//     id: 12348,
//     documentName: "Doc 5",
//     // linkedTo: "Service Group 1",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
//   {
//     id: 12349,
//     documentName: "Doc 6",
//     // linkedTo: "Service 1",
//     versionNumber: "1.0.0",
//     updatedOn: "02/04/2021",
//     importedFrom: "Gitlab",
//   },
// ];
export {
  documentColumns,
  documentDefaultColumnWidths,
  documentTableColumnExtensions,
  documentColumnOrder,
  documentDefaultSorting,
  // documentRows,
};
