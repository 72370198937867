import {
    ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT, ADD_ALL_SERVICE_GROUP_LIST_DATA,
    DELETE_SERVICE_GROUP_BY_ID, NEW_SERVICEGROUP_DATA, SERVICEGROUP_SORT, SERVICEGROUPFILTERS,
    CLEAR_SERVICEGROUP_DATA, SERVICEGROUP_HIDDEN, ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA, DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID
} from '../actionTypes';
import { v4 as uuidv4 } from "uuid";

const initialState = {
    servicesGroupListByProject: {
        data: [],
        error: false,
        loading: false
    },
    allServiceGroupList: {
        data: [],
        error: false,
        loading: false
    },
    allMicroserviceList: [],
    newServiceGroupData: {
        name: "",
        description: "",
        teams: [],
        documents: [],
        newServiceGroupsServicesList: []
    },
    serviceGroupSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    serviceGroupFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
    serviceGroupHidden: ["name", "createdby", "createdon", "status", "action"]
}

const serviceGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT: {
            return {
                ...state,
                servicesGroupListByProject: {
                    ...state.servicesGroupListByProject,
                    ...action.payload
                }
            }
        }
        case ADD_ALL_SERVICE_GROUP_LIST_DATA: {
            return {
                ...state,
                allServiceGroupList: {
                    ...state.allServiceGroupList,
                    ...action.payload
                }
            }
        }
        case ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA: {
            return {
                ...state,
                allMicroserviceList: action.payload
            }
        }
        case DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID: {
            return {
                ...state,
                allMicroserviceList: state.allMicroserviceList.filter(serv => serv._id !== action.payload.serviceId)
            }
        }
        case NEW_SERVICEGROUP_DATA: {
            return {
                ...state,
                newServiceGroupData: {
                    ...state.newServiceGroupData,
                    ...action.payload
                }
            }
        }
        case SERVICEGROUP_SORT: {
            return {
                ...state,
                serviceGroupSort: [
                    ...action.payload
                ]
            }
        }
        case SERVICEGROUP_HIDDEN: {
            return {
                ...state,
                serviceGroupHidden: [
                    ...action.payload
                ]
            }
        }
        case DELETE_SERVICE_GROUP_BY_ID: {
            return {
                ...state,
                servicesGroupListByProject: {
                    ...state.servicesGroupListByProject,
                    data: {
                        ...state.servicesGroupListByProject.data,
                        data: state.servicesGroupListByProject.data.data.filter(serv => serv._id !== action.payload.groupId)
                    },
          _totalcount: state.servicesGroupListByProject._totalcount - 1,

                }
            }
        }

        case SERVICEGROUPFILTERS: {
            return {
                ...state,
                serviceGroupFilters: [...action.payload]
            }
        }

        case CLEAR_SERVICEGROUP_DATA: {
            return {
                ...state,
                allServiceGroupList: {}

            }
        }
        default:
            return state;
    }
}

export default serviceGroupReducer;