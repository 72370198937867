import layoutStyles from "../../../styles/commonStyles/Layout";
import { Tooltip } from "@material-ui/core";

const components = [
  [
    "Table",
    "Details",
    "Heading",
    "Button",
    "DatePicker",
    "Toggle",
    "Radio",
    "Image",
    "Custom Component",
  ],
  [
    "Form",
    "Text",
    "Link",
    "Input",
    "Select",
    "TextArea",
    "CheckBox",
    "Menu",
    "Tree",
  ],
];

const Componentbar = ({ expandCanvas }) => {
  const styles = layoutStyles();
  const dragStart = (event, component) =>
    event.dataTransfer.setData("component", component);

  return (
    <div
      style={{
        transform: !expandCanvas ? "translate(0%,0%)" : "translate(-121%, 0%)",
      }}
      className={styles.leftbar}
    >
      {components.map((item, index) => (
        <div className={"components"} key={index}>
          {item.map((component, compindex) => (
            <div
              key={compindex}
              draggable="true"
              onDragStart={(e) => dragStart(e, component)}
              style={{ cursor: "pointer" }}
            >
              <img src={`/img/${component}.svg`} alt={component} />
              <Tooltip title={`Drag & Drop to add ${component}`}>
                <h2>{component}</h2>
              </Tooltip>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Componentbar;
