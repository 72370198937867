import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  FilledInput,
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";
import { AppButton } from "./common/Button";
import { useRef } from "react";
import { getServicesList } from "../redux/actions/serviceAction";
import { connect } from "react-redux";
import { fetchVersionListData } from "../utils/project/microserviceUtils";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: "15px",

    "& h1": {
      font: "normal normal 600 16px/22px Nunito",
      color: "#000000",
      width: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "2rem",
    },
    "& .MuiFormControlLabel-label": {
      font: "normal normal 600 16px/22px Nunito",
      color: "#000000",
    },
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",

    "&::before": {
      borderBottom: "unset",
    },
  },
  customSearchInput: {
    border: "unset",
    borderBottom: "1px solid #707070",
    background: "unset",
    borderRadius: 0,

    "& input": {
      padding: 0,
    },
  },
  searchInput: {
    width: "50%",
  },
  paper: {
    // margin: '10px 0',
    padding: "8px 15px",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    borderRadius: "10px",
    margin: "0 0 1rem",
  },
  selectedPaper: {
    backgroundColor: "#F3F3F3",
  },
  services: {
    minHeight: "15rem",
    maxHeight: "15rem",
    overflow: "auto",
    padding: "1rem .5rem",
  },
  serviceName: {
    font: "normal normal normal 14px/19px Nunito",

    "& input": {
      border: "none",
      background: "transparent",
      padding: 8,
      outline: "none",
    },

    "& button": {
      marginLeft: ".5rem",
      padding: 8,
    },
  },
  versionInput: {
    width: "80%",
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    // marginBottom: 5
  },
  modalFooter: {
    padding: "8px 16px 20px",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
}));

const CopyServiceModal = (props) => {
  const { data, getServicesList } = props;
  const styles = useStyles();
  const [searchInput, setSearchInput] = useState("");
  const [selectedService, setSelectedService] = useState({});
  const [versions, setVersions] = useState([]);
  const [versionError, setVersionError] = useState(false);
  const [imagenameError, setImagenameError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getServicesList(0);
  }, []);

  const services =
    data?.data && data?.data?.length > 0
      ? data.data.map((item) => {
          return item;
        })
      : [];

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  useEffect(() => {
    const fetchVersionsList = async () => {
      const { _msg, _status, data } = await fetchVersionListData(
        selectedService._id
      );
      if (_status === 200) {
        setVersions(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    if (selectedService._id) {
      fetchVersionsList();
    }
  }, [selectedService._id]);

  const handleSearch = () => {
    if (searchInput) {
      getServicesList(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      getServicesList(1);
    }
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={props.copyServiceModal}
      onClose={() => props.setCopyServiceModal(false)}
    >
      <DialogTitle>
        <FilledInput
          name="searchInput"
          onChange={(e) => setSearchInput(e.target.value)}
          className={`${styles.input} ${styles.searchInput} ${styles.customSearchInput}`}
          placeholder="Search"
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSearch}
                onMouseDown={handleSearch}
                edge="end"
              >
                <img src="/images/search.svg" alt="card" />
              </IconButton>
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent className={styles.modalContent}>
        <div className={styles.services}>
          {services.map((service, index) => (
            <div key={index}>
              <Paper
                className={`${styles.paper} ${
                  service._id === selectedService._id && styles.selectedPaper
                }`}
                onClick={() => setSelectedService(service)}
                style={{ cursor: "pointer" }}
              >
                <div className={styles.spaceBetweenText}>
                  <Typography variant="body1" className={styles.serviceName}>
                    {service._id === selectedService._id ? (
                      <input
                        type="text"
                        value={selectedService.name}
                        onChange={(e) =>
                          setSelectedService({
                            ...selectedService,
                            name: e.target.value,
                          })
                        }
                        tabIndex={0}
                        autoComplete="off"
                        name="name"
                      />
                    ) : (
                      service.name
                    )}
                    <IconButton
                      color="inherit"
                      aria-label="edit"
                      edge="start"
                      className={styles.actionIcon}
                      onClick={() => {}}
                    >
                      <img src="/images/editLine.svg" alt="card" />
                    </IconButton>
                  </Typography>
                </div>
              </Paper>
            </div>
          ))}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <label htmlFor="" className={styles.fieldHeading}>
              Version
            </label>
            <Autocomplete
              options={versions}
              getOptionLabel={(option) =>
                option.versionTag ? option.versionTag : ""
              }
              value={selectedService ? selectedService : ""}
              className={`${styles.searchInput3} ${
                versionError && styles.error
              }`}
              onChange={(e, input) => {
                if (input) {
                  setSelectedService({
                    ...selectedService,
                    versionID: input?._id,
                    versionTag: input?.versionTag,
                  });
                  setVersionError(false);
                } else {
                  setSelectedService({});
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Version"
                />
              )}
            />
            {versionError && (
              <p className={styles.errorMsg}>{"Version is Required"}</p>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <label htmlFor="" className={styles.fieldHeading}>
              Imagename
            </label>
            <input
              type="text"
              name={"imagename"}
              className={`${styles.input} ${imagenameError && styles.error}`}
              value={selectedService?.imagename}
              autoComplete="off"
              onChange={(e) => {
                setSelectedService({
                  ...selectedService,
                  imagename: e.target.value,
                });
                setImagenameError(false);
              }}
            />
            {imagenameError && (
              <p className={styles.errorMsg}>{"Imagename is Required"}</p>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.modalFooter}>
        <AppButton
          buttonName="Cancel"
          variant="outlined"
          disabled={false}
          style={{ marginTop: "1rem" }}
          className="btnsmall"
          onClick={() => props.setCopyServiceModal(false)}
        />
        <AppButton
          buttonName="Submit"
          variant="contained"
          disabled={false}
          style={{ marginTop: "1rem" }}
          className="btnsmall"
          onClick={() => {
            if (selectedService.versionID && selectedService.imagename) {
              props.handleSubmit({
                microserviceID: selectedService._id,
                versionID: selectedService?.versionID,
                versionTag: selectedService?.versionTag,
                imagename: selectedService?.imagename,
                name: selectedService?.name,
                type: "service",
              });
              setSelectedService({});
              props.setCopyServiceModal(false);
            } else {
              if (!selectedService.versionID) {
                setVersionError(true);
              }
              if (!selectedService.imagename) {
                setImagenameError(true);
              }
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  getServicesList,
};

const mapStateToProps = (state) => {
  const { servicesList } = state.serviceReducer;
  const { data, error, loading } = servicesList;
  return {
    data,
    error,
    loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyServiceModal);
