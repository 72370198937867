import React from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");
const Response = ({ data }) => {
  const options = {
    lineNumbers: true,
    mode: "javascript",
    readOnly: true,
  };

  return (
    <div style={{ minWidth: "100%", overflow: "auto" }}>
      <CodeMirror
        value={JSON.stringify(data, null, "\t")}
        options={options}
        autoCursor={false}
      />
    </div>
  );
};

export default Response;
