import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../components/common/styles/InputTableStyle"
import { connect } from "react-redux";
import { fetchAllClusterDeployment } from "../../redux/actions/clusterActions";
import { Pagination } from "@material-ui/lab";
// import {fetchClusterDeployment} from "../../utils/clusterutils/clusterUtils";
import {add_cluster_deployment_list} from "../../redux/actions/clusterActions";
import listStyles from "../CommonStyles/listScreenStyles";
import Loader from "../../components/common/stuff/Loader";
import { fetchClusterDeploymentDetails } from "../../utils/clusterutils/clusterUtils";


const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  footer: {
    bottom: 0,
    left: 0,
    padding: 20,
    width: '100%',
    textAlign: 'right',
    zIndex: 999,
    backgroundColor: '#fafafa',
  },
}));

function ClusterDeploymentPreview({
  fetchAllClusterDeployment,
  add_cluster_deployment_list,
  data,
  clusterName
}) {
  const tStyles = tempStyles();
  const styles = listStyles();
  const [tableLoading, setTableLoading] = useState(true);
  const [filterContainer, setFilterContainer] = useState();
  const [currentPageCluster, setCurrentPageCluster] = useState(1);
  const length = filterContainer?.length

// useEffect(async() => {
//   await fetchAllClusterDeployment()
// }, [])

// useEffect(() => {
// setFilterContainer( data && data?.length > 0
//   ? data?.map((item) => item )
//   : []
//   );
// }, [])

useEffect(()=>{
  const fetchAllClusterDeployment = async(cloudConfig,clusterName, currentPageCluster)=>{
      const {data,_status,_msg}= await fetchClusterDeployments(cloudConfig,clusterName, currentPageCluster);
      if(_status=== 200){
          setFilterContainer(data);
          add_cluster_deployment_list(data);
        setTableLoading(false) 
      }
      else {
        setTableLoading(false)
      }
  }
  fetchAllClusterDeployment(cloudConfig,clusterName, currentPageCluster);

},[]) 

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        <Paper>
        {tableLoading ? (
                <Loader />
              ) : (
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Namespace</TableCell>
                  <TableCell align="left">Pods</TableCell>
                  <TableCell align="left">Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterContainer &&
                  filterContainer.length > 0 &&
                  filterContainer.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "10rem" }}
                      >
                      {row?.metadata?.name}
                      </TableCell>
                      <TableCell style={{ width: "10rem" }}>
                      {row?.metadata?.namespace}
                      </TableCell>
                      <TableCell component="th" style={{ width: "10rem" }}>

                      </TableCell>
                      <TableCell component="th" style={{ width: "10rem" }}>
                      {row?.metadata?.creationTimestamp}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {filterContainer?.length === 0 && (
                <div className={styles.nodata}>
                  <text>No Data Available</text>
                </div>
              )}
            </Table>
          </TableContainer>
              )}
        </Paper>
        <div style={{marginTop: "25px" }}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(length / 10)}
                page={currentPageCluster}
                onChange={(e, val) =>  setCurrentPageCluster(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  add_cluster_deployment_list,
};

const mapStateToProps = (state) => {
  const {data} = state.clusterReducer.clusterDeploymentList;

  return {
data
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (ClusterDeploymentPreview);