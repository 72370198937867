import { ApiHelper } from "./helper/ApiHelper";
import { EXECUTION, REPOSITORY, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class TestExecutionService {

  deleteExecution(ExecutionId) {
    const uri = `${EXECUTION}/api/v1.0.0/test/executions/${ExecutionId}`;
    return apiHelper.delete(uri);
  }

  getAllTestExecution(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${EXECUTION}/api/v1.0.0/test/executions?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  getAllTestExecutionFilter(currentPage, filter, sort, deploymentId) {
    const queryParam =
      `page=${currentPage}` +
      `&filters={"deploymentid":"${filter}"}` +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${EXECUTION}/api/v1.0.0/test/executions?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  fetchTestExecutionById(id) {
    const uri = `${EXECUTION}/api/v1.0.0/test/executions/${id}`;
    return apiHelper.get(uri);
  }

  fetchHTMLFileById(id) {
    const uri = `${EXECUTION}/api/v1.0.0/test/executions/${id}/output`;
    return apiHelper.get(uri);
  }

  TestExecute(executeData) {
    const uri = `${EXECUTION}/api/v1.0.0/test/execute`;
    return apiHelper.post(uri, executeData);
  }

  DownloadPostManCollection(data) {
    const uri = `${EXECUTION}/api/v1.0.0/test/postmancollections`;
    return apiHelper.post(uri, data);
  }

  DownloadPostManCollectionForServiceBundle(data) {
    const uri = `${EXECUTION}/api/v1.0.0/test/serviceBundle/postmancollections`;
    return apiHelper.post(uri, data);
  }

}
