import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import { VendorServices } from "../../../services/vendorServices";
// const VendorService = new VendorServices();
const CustomStyles = makeStyles((theme) => ({
  cancel: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    border: "1px solid #052855",
    borderRadius: 5,
    opacity: 1,
    color: "#052855",
  },
  submitMember: {
    background: "#052855 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      background: "#052855 0% 0% no-repeat padding-box",
    },
  },
}));

export default function LockUnlockModal(props) {
  const { row, open, handleClose, handleBlock } = props;
  const styles = CustomStyles();
  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          <div
            style={{
              height: 170,
              width: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img src="/images/teams/lock2.svg" alt="lock" />
            </div>
            <div style={{ marginTop: 30 }}>
              <p>
                Are you sure you want to <b>Lock</b> the {row.name} ?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <Button onClick={handleClose} className={styles.cancel}>
            Cancel
          </Button>
          <Button onClick={handleBlock} className={styles.submitMember}>
            Yes, Lock it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
