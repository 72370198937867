import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React from "react";
// import { add_service_data } from "../../../../redux/actions/applicationAction"
import useStyles from "../../../../screens/Orchestration/Application/CreateApplicationScreen/Styles";
import manageScreenStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import useCheckPermission from "../../hooks/useCheckPermission";

import ServiceVersionSelectTable from "../../../../screens/Orchestration/Application/CreateApplicationScreen/ServiceVersionSelectTable";
import ApplicationDeploymentTable from "../../../Orchestration/Application/ApplicationDeploymentTable";

const CreateApplicationEntityHelper = React.memo((props) => {
  const {
    section,
    applicationData,
    error,
    handleChange,
    rows,
    loading,
    setSearchInput,
    handleSearch,
    imagesList,
    applicationName,
  } = props;
  const styles = manageScreenStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const { checkCompPermission } = useCheckPermission();

  const handleSearchChange = (val) => {
    setSearchInput(val);
    setPage(1);
  };
  const createApplicationServiceVersionListField = () => {
    if (checkCompPermission("Microservice", ["List All"])) {
      return (
        <>
          <div className={classes.searchwrapper}>
            <FilledInput
              name="searchInput"
              onChange={(e) => handleSearchChange(e.target.value)}
              className={classes.input}
              style={{ padding: 0, paddingBottom: "1rem" }}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="center">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearch}
                    onMouseDown={handleSearch}
                  // style={{ paddingBottom: "" }}
                  // edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
            <ServiceVersionSelectTable
              rows={rows}
              loading={loading}
              page={page}
              applicationName={applicationName}
              setPage={setPage}
              isEditMode={true}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <text className={classes.fieldHeading} style={{ color: "red" }}>
            You donot have permission to view microservice list
          </text>
        </div>
      );
    }
  };

  const applicationDeploymentTableFunction = () => {
    return (
      <div>
        <ApplicationDeploymentTable
          // applicationID={applicationData?._id}
          createMode={"create"}
          applicationName={applicationName}
          disabled={false}
          imagesList={imagesList}
        />
      </div>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                }`}
              value={applicationData[field.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={applicationData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error
                }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${error[field.datakey]?.isError && styles.error
                }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                        }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  applicationData[`${field.datakey}_unit`]
                    ? applicationData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  }`}
                value={applicationData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  applicationData[`${field.datakey}_unit`]
                    ? applicationData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  }`}
                value={applicationData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Service_Bundle_Header section" &&
                index !== 0 && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Service_Bundle_ServiceVersionList_Table_Field":
                          return (
                            <>{createApplicationServiceVersionListField()}</>
                          );
                        case "Service_Bundle_Deployment_Table_Field":
                          return <>{applicationDeploymentTableFunction()}</>;
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Service_Bundle_ServiceVersionList_Table_Field":
                                return (
                                  <>
                                    {createApplicationServiceVersionListField()}
                                  </>
                                );
                              case "Service_Bundle_Deployment_Table_Field":
                                return (
                                  <>{applicationDeploymentTableFunction()}</>
                                );
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      name={field.datakey}
                                      className={`${styles.input} ${error[field.datakey]?.isError &&
                                        styles.error
                                        } ${field.editable === "FALSE" &&
                                        styles.editMode
                                        }`}
                                      value={applicationData[field.datakey]}
                                      autoComplete="off"
                                      disabled={field.editable === "FALSE"}
                                      onChange={handleChange}
                                    />
                                    {error[field.datakey]?.isError && (
                                      <p className={styles.errorMsg}>
                                        {error[field.datakey].msg}
                                      </p>
                                    )}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}{" "}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={applicationData[field.datakey]}
                                  onChange={handleChange}
                                  className={`${styles.textarea} ${error[field.datakey]?.isError &&
                                    styles.error
                                    } ${field.editable === "FALSE" &&
                                    styles.editMode
                                    }`}
                                  disabled={field.editable === "FALSE"}
                                  rows="7"
                                ></textarea>
                                {error[field.datakey]?.isError && (
                                  <p className={styles.errorMsg}>
                                    {error[field.datakey].msg}
                                  </p>
                                )}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
});

export default CreateApplicationEntityHelper;
