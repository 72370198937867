import React, { useState } from "react";
import { data } from "./EntityData";
import Styles from "../ActionStyles";
import {
  MenuItem,
  Select,
  Grid,
  Tabs,
  Tab,
  Divider,
  Paper,
} from "@material-ui/core";
import TextFieldCustom from "../../../atoms/TextField/TextFieldCustom";
import useGetData from "../../../../hooks/useGetData";
import { methods } from "../data";
import ActionDropdown from "../../../atoms/dropdown/ActionDropdown";
import { useDispatch } from "react-redux";
import { editAction } from "../../../../../redux/actions/uiApplicationAction";
import ActionTabs from "./ActionTabs";
import Response from "../Response";

const RenderConfigFields = (
  serviceType,
  identifier,
  actionObj,
  handleChangeCreateField
) => {
  const classes = Styles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [resultTab, setResultTab] = useState(0);
  const { currentAction } = useGetData();
  const foundSection = data?.sections?.filter(
    (sections) => sections.name === `sys_${serviceType?.toLowerCase()}_config`
  );

  const onChange = (e) => {
    switch (currentAction?.actionType) {
      case "service":
        dispatch(
          editAction({
            id: currentAction?._id,
            data: {
              ...currentAction,
              config: {
                ...currentAction?.config,
                [e.target.name]: e.target.value,
              },
            },
          })
        );
        break;
      case "application":
        dispatch(
          editAction({
            id: currentAction?._id,
            data: {
              ...currentAction,
              applicationConfig: {
                ...currentAction?.applicationConfig,
                [e.target.name]: e.target.value,
              },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  // if (foundSection && foundSection?.length !== 0) {
  //   return foundSection[0].fieldgroups.map((fieldgroups) =>
  //     fieldgroups.fields.map((field, index) => {
  //       switch (field?.uiElementType) {
  //         case 'TextField':
  //           return (
  //             <Grid item xs={6}>
  //               <div className={classes.name}>
  //                 <p className={classes.label}>
  //                   {field.fieldLabel} {<span className={classes.red}>*</span>}
  //                 </p>
  //                 <input
  //                   className={classes.value}
  //                   type={
  //                     field.fieldLabel === 'Password' ||
  //                     field.fieldLabel === 'Partition'
  //                       ? field.fieldLabel === 'Password'
  //                         ? 'password'
  //                         : 'number'
  //                       : 'text'
  //                   }
  //                   name={field.name}
  //                   value={currentAction?.config[field?.name]}
  //                   onChange={(e) => onChange(e)}
  //                   autoComplete="off"
  //                 />
  //               </div>
  //             </Grid>
  //           );
  //         case 'Dropdown':
  //           return (
  //             <Grid item xs={6}>
  //               <div className={classes.name}>
  //                 <p className={classes.label}>
  //                   {field.fieldLabel} {<span className={classes.red}>*</span>}
  //                 </p>
  //                 <Select
  //                   name={field.name}
  //                   value={currentAction?.config[field?.name]}
  //                   onChange={(e) => onChange(e)}
  //                   className={`${classes.select}`}
  //                   inputProps={{ 'aria-label': 'Without label' }}
  //                   MenuProps={{
  //                     anchorOrigin: {
  //                       vertical: 'bottom',
  //                       horizontal: 'left',
  //                     },
  //                     transformOrigin: {
  //                       vertical: 'top',
  //                       horizontal: 'left',
  //                     },
  //                     getContentAnchorEl: null,
  //                   }}
  //                 >
  //                   {typeof field?.values === 'string'
  //                     ? JSON.parse(field?.values).map((value, index) => (
  //                         <MenuItem value={value} key={index}>
  //                           {value}
  //                         </MenuItem>
  //                       ))
  //                     : field?.values.map((value, index) => (
  //                         <MenuItem value={value} key={index}>
  //                           {value}
  //                         </MenuItem>
  //                       ))}
  //                 </Select>
  //               </div>
  //             </Grid>
  //           );
  //         default:
  //           return <></>;
  //       }
  //     })
  //   );
  // } else {
  //   return (
  //     <Grid item container spacing={1} >
  //       <Paper style={{ minWidth: '100%',padding:10,marginTop:'1rem' }}>
  //         <Grid container>
  //           <Grid item xs={4}>
  //             <div className={classes.name}>
  //               <p className={classes.label}>Container Name</p>
  //               <p>
  //                 {currentAction.service.containerName
  //                   ? currentAction.service.containerName
  //                   : ''}
  //               </p>
  //               {/* <TextFieldCustom
  //               width="100%"
  //               value={
  //                 currentAction.service.containerName
  //                   ? currentAction.service.containerName
  //                   : ''
  //               }
  //               disabled={true}
  //               name=""
  //             /> */}
  //             </div>
  //           </Grid>
  //           <Grid item xs={4}>
  //             <div className={classes.name}>
  //               <p className={classes.label}>Method</p>
  //               <p>
  //                 {currentAction?.api?.method ? currentAction?.api?.method : ''}
  //               </p>
  //               {/* <ActionDropdown
  //               width="100%"
  //               value={
  //                 currentAction?.api?.method ? currentAction?.api?.method : ''
  //               }
  //               //   onChange={handleChangeMethod}
  //               // onChange={()=>console.log("Hello")}
  //               arr={methods}
  //               disabled={true}
  //             /> */}
  //             </div>
  //           </Grid>
  //           <Grid item xs={4}>
  //             <div className={classes.name}>
  //               <p className={classes.label}>Endpoint</p>
  //               <p>
  //                 {currentAction?.api?.path ? currentAction?.api?.path : ''}
  //               </p>
  //               {/* <TextFieldCustom
  //               width="100%"
  //               value={currentAction?.api?.path ? currentAction?.api?.path : ''}
  //               disabled={true}
  //               name=""
  //             /> */}
  //             </div>
  //           </Grid>
  //         </Grid>
  //       </Paper>
  //       <Grid container>
  //         <Grid item xs={12}>
  //           <ActionTabs value={value} setValue={setValue} />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <div className={classes.name}>
  //             <p className={classes.label}>Modify Results</p>
  //             <TextFieldCustom
  //               width="100%"
  //               value={currentAction.modifyResults}
  //               //   onChange={(e) => handleChangeModifyResults(e.target.value)}
  //               onChange={() => console.log('object')}
  //               disabled={false}
  //               name=""
  //             />
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  // new
  const getValue = () => {
    switch (identifier) {
      case "service":
        return currentAction?.api;
      case "application":
        return currentAction?.process;
      default:
        return "";
    }
  };

  return (
    serviceType === "rest" && (
      <Grid item container spacing={1}>
        <Paper style={{ minWidth: "100%", padding: 10, marginTop: "1rem" }}>
          <Grid container>
            {identifier === "service" && (
              <Grid item xs={4}>
                <div className={classes.name}>
                  <p className={classes.label}>Container Name</p>
                  <p>
                    {currentAction.service.containerName
                      ? currentAction.service.containerName
                      : ""}
                  </p>
                </div>
              </Grid>
            )}
            <Grid item xs={4}>
              <div className={classes.name}>
                <p className={classes.label}>Method</p>
                <p>{getValue()?.method}</p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.name}>
                <p className={classes.label}>Endpoint</p>
                <p>{getValue()?.endpoint}</p>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Grid container>
          <Grid item xs={12}>
            <ActionTabs value={value} setValue={setValue} />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default RenderConfigFields;