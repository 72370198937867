import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Grid,
  FilledInput,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Tabs,
  Tab,
  CircularProgress,
} from "@material-ui/core";
import { AppButton } from "../../../components/common/ButtonOld";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";

// Redux
import { connect } from "react-redux";
import {
  fetchTagById,
  fetchTagResources,
  deleteTag,
} from "../../../redux/actions/dataStandardAction";
import { useSnackbar } from "notistack";

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    marginTop: "20px",
    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item p": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#000000",
      opacity: "1",
    },
  },

  modalDsTitle: {
    marginTop: "20px",
    font: "normal normal 600 14px/19px Nunito",
    color: "#000000",
    "& label": {
      opacity: "0.5",
    },
    "& p": {
      color: "#000000",
      opacity: "1",
    },
  },

  modalDsContainer: {
    background: "#F3F3F3 0% 0% no-repeat padding-box",
    borderRadius: "7px",
    opacity: "0.5",
    height: "74px",
    alignItems: "center",
    paddingLeft: "20px",

    "& .MuiGrid-item label": {
      font: "normal normal normal 16px/22px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
    "& .MuiGrid-item span": {
      font: "normal normal 600 16px/22px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
  },

  modalData: {
    margin: "30px 0 30px 0",
    textAlign: "center",
    background: "#F3F3F399 0% 0% no-repeat padding-box",
    borderBottom: "1px solid #F7F7F7",
    // opacity: "0.5",
    height: "41px",

    "& .MuiGrid-item": {
      marginTop: "10px",
    },

    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.4",
      height: "19px",
    },

    "& .MuiGrid-item span": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.8",
      height: "19px",
    },
  },

  modalElement: {
    font: "normal normal 600 16px/22px Nunito",
    marginBottom: "200px",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  },

  modaltab: {
    marginTop: "5px",
    width: "35rem",
    textAlign: "center",
    font: "normal normal bold 14px/18px Nunito",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
    textTransform: "capitalize",
    "& .MuiTab-root": {
      textTransform: "capitalize",
    },
    "& .Mui-selected": {
      font: "normal normal 600 14px/19px Nunito",
      borderRadius: "5px",
    },
  },

  modalTitle: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: "0px",
    color: "#222725",
    opacity: "1",

    "& .MuiButton-root": {
      textTransform: "capitalize",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000014",
      border: "1px solid #002855",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",
    },
  },

  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
  customModal: {
    // maxWidth: "796px",
    margin: "50px 35px 0 48px",
    // display: "flex",
    // justifyContent: "center",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },

  modalTableContainer: {
    height: "300px",
    marginLeft: "12px",
    border: "1px solid #DEDFDF",
  },

  modelFooter: {
    height: "64px",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",

    "& .MuiButton-root": {
      width: "110px",
      height: "40px",
      font: "normal normal 600 14px/19px Nunito",
      textTransform: "capitalize",
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      opacity: "1",
    },
  },

  modalTable: {
    "& thead": {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",

      "& th": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        textTransform: "capitalize",
        color: "#000000",
        opacity: "1",
      },

      "& tr": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        color: "#000000",
      },
    },
    "& tbody": {
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito Sans;",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.8",
      },
    },
  },
}));

const FieldDetailResources = (props) => {
  const { tagResources, fetchTagResources, fetchTagById, deleteTag } = props;
  const currentTag = props.row;
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = props;
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const styles = useStyles();
  const manageStyle = manageScreenStyles();
  const listListStyle = listStyles();
  const [loading, setLoading] = useState(false);
  const [currentTagResources, setCurrentTagResources] = useState([]);

  useEffect(() => {
    fetchTagResourcesById();
  }, []);

  const fetchTagResourcesById = async () => {
    setLoading(true);
    const { _msg, _status, data } = await fetchTagResources(_id);
    if (_status === 200 || _status === 201) {
      setCurrentTagResources(data);
    } else {
      // if (_msg === "Invalid Json") {
      //   data.map((item) =>
      //     enqueueSnackbar(item ? item : "Something went wrong", {
      //       variant: "error",
      //     })
      //   );
      // } else {
      //   enqueueSnackbar(_msg ? _msg : "Something went wrong", {
      //     variant: "error",
      //   });
      // }
    }
    setLoading(false);
  };

  const handleChangeTab = (event, newValue) => setTab(newValue);

  return (
    <div>
      <Grid style={{ marginTop: 25 }}>
        {/* <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}> */}
        <label htmlFor="" className={styles.modalElement}>
          {"Glossary Related Data Elements"}
        </label>
        {/* </Grid> */}
        <Grid item xs={3} sm={8} className={styles.modaltab}>
          {
            <Tabs value={tab} indicatorColor="primary" textColor="primary">
              <Tab
                onClick={(e) => handleChangeTab(e, 0)}
                label={`Services (${tagResources.services?.length || 0})`}
              />
              <Tab
                onClick={(e) => handleChangeTab(e, 1)}
                label={`Projects (${tagResources.projects?.length || 0})`}
              />
              <Tab
                onClick={(e) => handleChangeTab(e, 2)}
                label={`Payloads (${tagResources.fieldGroups?.length || 0})`}
              />
            </Tabs>
          }
        </Grid>
        <Grid item xs={3} sm={4}>
          {/* <FilledInput
            id="filled-adornment-password"
            name="filterfn"
            // onChange={handleSearch}
            className={listListStyle.input}
            style={{ margin: "14px auto 0 auto" }}
            placeholder={
              tab === 0
                ? "Search Services"
                : tab === 1
                ? "Search Projects"
                : "Search Payloads"
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="/images/search.svg" alt="card" />
                </IconButton>
              </InputAdornment>
            }
          /> */}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <TableContainer
          className={styles.modalTableContainer}
          style={{ height: "auto", marginBottom: 17 }}
        >
          <Table stickyHeader className={styles.modalTable}>
            <TableHead>
              <TableRow>
                {tab === 0 ? (
                  <>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Service Group</TableCell>
                  </>
                ) : tab === 1 ? (
                  <>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Description</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>Payload Name</TableCell>
                    <TableCell>Type</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody style={{ position: "relative" }}>
              {tab === 0 ? (
                !tagResources.services ? (
                  <TableRow>
                    <TableCell>No Data</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  tagResources?.services?.map((item) => (
                    <TableRow>
                      <TableCell>{item.name || " "}</TableCell>
                      <TableCell>{item.group || " "}</TableCell>
                    </TableRow>
                  ))
                )
              ) : tab === 1 ? (
                !tagResources.projects ? (
                  <TableRow>
                    <TableCell>No Data</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  tagResources?.projects?.map((item) => (
                    <TableRow>
                      <TableCell>{item.name || " "}</TableCell>
                      <TableCell>{item.description || " "}</TableCell>
                    </TableRow>
                  ))
                )
              ) : !tagResources.fieldGroups ? (
                <TableRow>
                  <TableCell>No Data</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ) : (
                tagResources?.fieldGroups?.map((item) => (
                  <TableRow>
                    <TableCell>{item.name || " "}</TableCell>
                    <TableCell>{item.type || " "}</TableCell>
                  </TableRow>
                ))
              )}
              {tagResources.loading && (
                <CircularProgress className={styles.loader} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  fetchTagById,
  deleteTag,
  fetchTagResources,
};

const mapStateToProps = (state) => {
  const { tagResources } = state.dataStandardReducer;
  return { tagResources };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldDetailResources);
