import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { AppButton } from "../Button";
import { runContainer } from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: theme.palette.background.default,
    color: "#000000",
    width: "100%",
    margin: 0,
    padding: "10px 24px",

    "& h2": {
      font: "normal normal 600 16px/22px Nunito",
    },
  },
  modalContent: {
    padding: "15px 24px 48px",
  },
  modalLabel: {
    font: "normal normal 600 14px/16px Nunito",
    color: "#333333DE",
    marginTop: 10,
  },
  input: {
    width: "100%",
    height: 37,
    // backgroundColor: "#fff",
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
}));

const ContainerModal = (props) => {
  const styles = useStyles();
  const [containerName, setContainerName] = useState(props.containerName);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const {
    containerRunning,
    setContainerRunning,
    setVersionContainerId,
    versionContainerId,
  } = props;
  const handleSubmitContainer = async () => {
    setIsLoader(true);
    const { _msg, _status, data } = await runContainer(props.mid, props.vid, {
      containername: containerName,
    });
    if (_status === 200) {
      setVersionContainerId(data?.data?._id);
      enqueueSnackbar(_msg, { variant: "success" });
      setContainerName("");
      props.setOpenModal(false);
      setIsLoader(false);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      setIsLoader(false);
      setContainerRunning(false);
    }
  };

  return (
    <>
      <Backdrop
        className={styles.backdrop}
        open={isLoader}
        onClick={() => setIsLoader(false)}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        // onEntering={getData}
        maxWidth={"xs"}
        fullWidth={true}
        open={props.openModal}
        onClose={() => props.setOpenModal(false)}
      >
        <DialogTitle className={styles.modalHeader}>New Container</DialogTitle>
        <DialogContent className={styles.modalContent}>
          <label htmlFor="" className={styles.modalLabel}>
            Container Name
          </label>
          <input
            type="text"
            name="containerName"
            className={styles.input}
            style={{ margin: 0 }}
            value={containerName}
            onChange={(e) => setContainerName(e.target.value)}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions className={styles.modalFooter}>
          <AppButton
            buttonName="Cancel"
            variant="outlined"
            disabled={false}
            className="btnsmall"
            onClick={() => props.setOpenModal(false)}
          />
          <AppButton
            buttonName="Add Container"
            variant="contained"
            disabled={false}
            className="btnsmall"
            onClick={() => handleSubmitContainer()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContainerModal;
