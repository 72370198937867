import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import FileDropperWithLayout from "../../FileDropperWithLayout";
import useCheckPermission from "../../hooks/useCheckPermission";
import { DropzoneArea } from "material-ui-dropzone";
import FileDropperWithLayoutForDataModel from "../../FileDropperWithLayoutForDataModel";

const CustomFieldGroupsForDataModelCreate = ({
  section,
  serviceData,
  setServiceData,
  error,
  handleChange,
  languages,
  userInfo,
  boilerplates,
  selectedProject,
  boilerPlateChecked,
  setBoilerPlateChecked,
  setError,
  projects,
  AIModelFileError,
  setAIModelFileError,
  selectedServiceGroup,
  setSelectedProject,
  setSelectedServiceGroup,
  selectedDocuments,
  setSelectedDocuments,
  serviceGroups,
  setProjectSearchInput,
  setServiceGroupSearchInput,
  setDocumentsError,
  documentsError,
  uploadTab,
  setUploadTab,
  handleUploadSourceTab,
  droppedDocument,
  setDroppedDocument,
  setIsLoader,
  handleUpload,
  BoilerPlateData,
  imageDetails,
  handleImageNameChange,
  handleDeleteClick,
  fileDetails,
  tabbedView,
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();
  const [languageOpen, setLanguageOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [serviceGroupOpen, setServiceGroupOpen] = useState(false);

  const closeDropDown = () => {
    setLanguageOpen(false);
    setProjectOpen(false);
    setServiceGroupOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  // const UploadBoilerPlateFile = () => {
  //   return (
  //     <>
  //       <Grid container spacing={3}>
  //         <Grid item xs={8}>
  //           {uploadTab === "Upload" && (
  //             <Grid container spacing={3}>
  //               <Grid item xs={6}>
  //                 <div
  //                   className={`${styles.documentDropperWrapper2}
  //                       // {
  //                       //   error.droppedDocument === undefined &&
  //                       //   droppedDocument.length === 0 &&
  //                       //   styles.ErrordocumentDropperWrapper2
  //                       // }
  //                       `}
  //                 >
  //                   <DropzoneArea
  //                     name="droppedDocument"
  //                     className={styles.documentDropperClass}
  //                     showPreviews={false}
  //                     filesLimit={100}
  //                     dropzoneText="Drag and drop or click to browse here"
  //                     dropzoneClass="documentDropperClass"
  //                     dropzoneParagraphClass="documentDropperTextClass"
  //                     get
  //                     maxFileSize={9000000000}
  //                     //   showAlerts={["error"]}
  //                     showPreviewsInDropzone={false}
  //                     onDrop={() => setIsLoader(true)}
  //                     onChange={(files) => {
  //                       setIsLoader(false);
  //                       handleUpload(files);
  //                     }}
  //                   />
  //                 </div>

  //                 {droppedDocument.length > 0 && (
  //                   <div className={styles.doc_container}>
  //                     <div className={styles.group1}>
  //                       <text className={styles.label}>File Name</text>
  //                       {fileDetails?.map((item) => (
  //                         <div className={styles.doc_container}>
  //                           <p>{item.name}</p>
  //                           <span
  //                             style={{
  //                               marginLeft: 23,
  //                               marginTop: 25,
  //                               color: "black",
  //                             }}
  //                             onClick={handleDeleteClick}
  //                           >
  //                             <img
  //                               src="/images/deleteLine.svg"
  //                               height={18}
  //                               width={18}
  //                               color="#000000"
  //                               alt="icon"
  //                             />
  //                           </span>
  //                         </div>
  //                       ))}

  //                       {/* {error.droppedDocument === undefined &&
  //                       droppedDocument.length === 0 && (
  //                         <p className={styles.errorMsg}>{"File is required"}</p>
  //                       )} */}
  //                     </div>
  //                   </div>
  //                 )}
  //               </Grid>
  //             </Grid>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </>
  //   );
  // };

  // const governanceSection = () => {
  //   return (
  //     <>
  //       <Typography variant="h6" className={styles.fieldText}>
  //         Language <span className={styles.red}>*</span>
  //       </Typography>
  //       <Autocomplete
  //         open={languageOpen}
  //         onOpen={() => setLanguageOpen(true)}
  //         onClose={() => setLanguageOpen(false)}
  //         options={languages}
  //         getOptionLabel={(option) => option.name}
  //         disabled={!selectedProject._id ? true : false}
  //         className={`${styles.searchInput2} ${
  //           error.languageID?.isError &&
  //           !serviceData.languageID &&
  //           styles.errorAuto
  //         }`}
  //         onChange={(e, input) => {
  //           if (input) {
  //             setServiceData({
  //               ...serviceData,
  //               languageID: input._id,
  //             });
  //           }
  //         }}
  //         renderInput={(params) => (
  //           <TextField
  //             {...params}
  //             variant="standard"
  //             placeholder="Search Language"
  //           />
  //         )}
  //       />
  //       {error.languageID?.isError && !serviceData.languageID && (
  //         <p className={styles.errorMsg}>{"Language is not selected"}</p>
  //       )}
  //     </>
  //   );
  // };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Microservice ProjectId") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Project <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={projectOpen}
                onOpen={() => setProjectOpen(true)}
                onClose={() => setProjectOpen(false)}
                options={projects}
                getOptionLabel={(option) => option?.name}
                className={`${styles.searchInput3} ${
                  error.projectID?.isError &&
                  !selectedProject?._id &&
                  styles.error
                }`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedProject(input);
                    setServiceData({
                      ...serviceData,
                      projectID: input?._id,
                    });
                  } else {
                    setSelectedProject({});
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      projectID: "",
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setProjectSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Project"
                  />
                )}
              />
              {error.projectID?.isError && !selectedProject?._id && (
                <p className={styles.errorMsg}>{"Project is Required"}</p>
              )}
            </>
          );
        } else if (field.name === "Microservice ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Service Group <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={serviceGroupOpen}
                onOpen={() => setServiceGroupOpen(true)}
                onClose={() => setServiceGroupOpen(false)}
                options={serviceGroups}
                getOptionLabel={(option) => option.name}
                disabled={!selectedProject?._id ? true : false}
                className={`${styles.searchInput3} ${
                  error.servicegroupID.isError &&
                  !selectedServiceGroup?._id &&
                  styles.error
                }`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedServiceGroup(input);
                    setServiceData({
                      ...serviceData,
                      servicegroupID: input._id,
                    });
                  } else {
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setServiceGroupSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Service Group"
                  />
                )}
              />
              {error.servicegroupID.isError && !selectedServiceGroup._id && (
                <p className={styles.errorMsg}>{"Service Group is Required"}</p>
              )}
            </>
          );
        } else if (field.name === "Data Model Name") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? moment
                        .unix(serviceData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.(
                !field.datakey.replace(/\s/g, "").length
              ) && (
                <p className={styles.errorMsg}>
                  string only contains whitespace (ie. spaces, tabs or line
                  breaks
                </p>
              )}
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? moment
                        .unix(serviceData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={serviceData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${
                error[field.datakey]?.isError && styles.error
              }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  serviceData[`${field.datakey}_unit`]
                    ? serviceData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                }`}
                value={serviceData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                serviceData[field.datakey] ? serviceData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };
  const documentsSection = () => {
    return checkCompPermission("AIModel", ["Create"]) ? (
      tabbedView ? (
        <div style={{ marginLeft: 10, marginTop: 0 }}>
          <FileDropperWithLayoutForDataModel
            filesLimit={20}
            error={error}
            setError={setError}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={(data) => setSelectedDocuments(data)}
            documentsError={documentsError}
            setDocumentsError={setDocumentsError}
            AIModelFileError={AIModelFileError}
            setAIModelFileError={setAIModelFileError}
          />
        </div>
      ) : (
        <FileDropperWithLayoutForDataModel
          filesLimit={20}
            setError={setError}
            error={error}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={(data) => setSelectedDocuments(data)}
          documentsError={documentsError}
           AIModelFileError={AIModelFileError}
        setAIModelFileError={setAIModelFileError}
          setDocumentsError={setDocumentsError}
        />
      )
    ) : (
      <p className={styles.fieldHeading} style={{ color: "red" }}>
        You do not have permission to Create Document. Please contact your
        Admin.
      </p>
    );
  };
  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView
                      ? { display: "block" }
                      : { marginTop: "0rem", display: "block" }
                  }
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Microservice Languageid":
                          return governanceSection();
                        case "AIModel File":
                          return documentsSection();

                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Microservice Languageid":
                                return governanceSection();
                              case "AIModel File":
                                return documentsSection();
                              case "Microservice Documentation Table":
                                return documentsSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForDataModelCreate);
