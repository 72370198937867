import { OrchestrationServices } from "../../services/orchestrationServices";
import { v4 as uuidv4 } from "uuid";
const OrchestrationService = new OrchestrationServices();

export const fetchNodeDetails = async (templateID, nodeID) => {
  try {
    const res_data = await OrchestrationService.fetchNodeFromTemplate(
      templateID,
      nodeID
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 200) {
      return {
        data,
        _status,
        _msg,
      };
    }
  } catch (err) {
    const { _msg, data, _status } = err.response.data;
    return {
      _msg,
      data,
      _status,
    };
  }
};

export const addNodeData = async (templateID, req_data) => {
  try {
    const res_data = await OrchestrationService.addNode(templateID, req_data);
    const { _msg, _status, data } = res_data.data;
    if (_status === 201) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editNodeData = async (nodeID, templateID, req_data) => {
  try {
    const res_data = await OrchestrationService.editNodeDetails(
      templateID,
      nodeID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 201) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _msg, data, _status } = err.response.data;
    return {
      _msg,
      data,
      _status,
    };
  }
};

export const addNodeRelation = async (templateID, sourceNodeID, req_data) => {
  try {
    const res_data = await OrchestrationService.addRelation(
      templateID,
      sourceNodeID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 201) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (error) {
    const { _msg, _status, data } = error?.response?.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const debugSimulateTemplate = async (req_data) => {
  try {
    const res_data = await OrchestrationService.simulateTemplate(req_data);
    const { _status, _msg, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const getAllNodesOfTemplate = async (templateID) => {
  try {
    const res_data = await OrchestrationService.fetchAllNodesOfTemplate(
      templateID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _status, _msg, data } = err.response.data;
    return {
      _status,
      _msg,
      data,
    };
  }
};

export const debugTemplate = async (req_data, instanceID) => {
  try {
    const res_data = await OrchestrationService.debugTemplate(
      req_data,
      instanceID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const getKeyValueFromStringJSON = (data_obj) => {
  var temp_arr = [];
  if (data_obj && Object.keys(data_obj).length > 0) {
    for (var obj in data_obj) {
      var key = obj;
      var value = data_obj[obj];
      temp_arr.push({
        id: uuidv4(),
        key: key,
        value: typeof (value !== "string") ? value.toString() : value,
      });
    }
    return temp_arr;
  } else {
    return temp_arr;
  }
};

export const getJSONObjFromKeyValueArray = (data_arr) => {
  var data_obj = {};
  data_arr &&
    data_arr.length > 0 &&
    data_arr.forEach((obj) => {
      if (obj.key !== "" && obj.value !== "") {
        data_obj[obj.key] = obj.value;
      }
    });
  return data_obj;
};

export const getJSONObjFromKeyValueArrayGen = (data_arr) => {
  var data_obj = {};
  data_arr &&
    data_arr.length > 0 &&
    data_arr.forEach((obj) => {
      if (obj.key !== "") {
        data_obj[obj.key] = obj.value;
      }
    });
  return data_obj;
};

export const getJSONObjFromKeyValueArrayPro = (data_arr) => {
  var data_obj = {};
  data_arr &&
    data_arr.length > 0 &&
    data_arr.forEach((obj) => {
      if (obj.key !== "") {
        var tempval = obj.value ? obj.value : "";
        data_obj[obj.key] = tempval;
      }
    });
  return data_obj;
};

export const getJSONObjFromKeyValueArrayForSync = (data_arr) => {
  var data_obj = {};
  data_arr &&
    data_arr.length > 0 &&
    data_arr?.forEach((obj) => {
      if (obj.key !== "") {
        if (obj.value === "") {
          data_obj[obj.key] = " ";
        } else {
          data_obj[obj.key] = obj.value;
        }
      }
    });
  return data_obj;
};

export const getCanvasNodeList = (data_arr) => {
  var final_list = [];
  // console.log("before", data_arr)
  data_arr &&
    data_arr.length > 0 &&
    data_arr.forEach((item) => {
      if (item.type === "StartNode" && item.start_pos_x) {
        const { start_pos_x, start_pos_y, templatename, nodeid } = item;
        const start_node_obj = {
          type: "start_node",
          position: {
            x: parseFloat(start_pos_x),
            y: parseFloat(start_pos_y),
          },
          id: nodeid,
          data: {
            name: templatename,
            startnodeid: nodeid,
          },
        };
        final_list.push(start_node_obj);
        // final_list.push(end_node_obj);
      } else if (item.type === "EndNode") {
        const start_obj =
          final_list.length > 0 &&
          final_list.find((d) => d.type === "start_node");
        if (Object.keys(start_obj).length > 0) {
          var { position, data } = start_obj;
          const end_node_obj = {
            type: "end_node",
            position: {
              x: position["x"],
              y: position["y"] + 400,
            },
            id: item.nodeid,
            data: {
              name: data["name"],
              endnodeid: item.nodeid,
            },
          };
          final_list.push(end_node_obj);
        }
      } else if (item.type === "CallNode" && item.pos_x && item.pos_y) {
        const { nodeid, name, pos_x, pos_y } = item;
        const call_node_obj = {
          type: "call_node",
          position: {
            x: parseFloat(pos_x),
            y: parseFloat(pos_y),
          },
          id: nodeid,
          data: {
            name: name,
            nodeid: nodeid,
          },
        };
        final_list.push(call_node_obj);
      } else if (item.type === "SwitchNode" && item.pos_x && item.pos_y) {
        const { nodeid, pos_x, pos_y, name } = item;
        const switch_node_obj = {
          type: "switch_node",
          position: {
            x: parseFloat(pos_x),
            y: parseFloat(pos_y),
          },
          id: nodeid,
          data: {
            name: name,
            nodeid: nodeid,
          },
        };
        final_list.push(switch_node_obj);
      } else if (item.type === "JoinNode" && item.pos_x && item.pos_y) {
        const { nodeid, pos_x, pos_y, name } = item;
        const join_node_obj = {
          type: "join_node",
          position: {
            x: parseFloat(pos_x),
            y: parseFloat(pos_y),
          },
          id: nodeid,
          data: {
            name: name,
            nodeid: nodeid,
          },
        };
        final_list.push(join_node_obj);
      }
    });
  return final_list;
};

export const getInstanceContextSimulate = async (
  applicationID,
  templateID,
  instanceID
) => {
  try {
    const res_data = await OrchestrationService.getInstanceContext(
      applicationID,
      templateID,
      instanceID
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 200) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _msg, _status, data } = err.respone.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchTemplateFlow = async (templateID) => {
  try {
    var elements = [];
    const res_data = await OrchestrationService.getTemplateFlow(templateID);
    const { _msg, _status, data } = res_data.data;
    const { nodes, relations } = data;
    nodes &&
      nodes.length > 0 &&
      nodes.forEach((item) => {
        if (item.type === "StartNode" && item.posX && item.posY) {
          const {
            posX,
            posY,
            templatename,
            nodeid,
            data_flow_nodes_io_details,
            servicetype,
            topic,
          } = item;
          const start_node_obj = {
            type: "start_node",
            position: {
              x: parseFloat(posX),
              y: parseFloat(posY),
            },
            id: nodeid,
            data: {
              name: templatename,
              startnodeid: nodeid,
              servicetype: servicetype ? servicetype : "rest",
              topic: topic ? topic : "",
            },
            data_flow_nodes_io_details: data_flow_nodes_io_details
              ? data_flow_nodes_io_details
              : "",
          };
          elements.push(start_node_obj);
          // final_list.push(end_node_obj);
        } else if (item.type === "EndNode") {
          const start_obj =
            elements.length > 0 &&
            elements.find((d) => d.type === "start_node");
          if (Object.keys(start_obj).length > 0) {
            var { position, data } = start_obj;
            const end_node_obj = {
              type: "end_node",
              position: {
                x: item.posX
                  ? parseFloat(item.posX)
                  : parseFloat(position["x"]),
                y: item.posY
                  ? parseFloat(item.posY)
                  : parseFloat(position["y"] + 500),
              },
              id: item.nodeid,
              data: {
                name: data["name"],
                endnodeid: item.nodeid,
              },
            };
            elements.push(end_node_obj);
          }
        } else if (item.type === "CallNode" && item.posX && item.posY) {
          const { nodeid, name, pos_x, pos_y, posX, posY } = item;
          const call_node_obj = {
            type: "call_node",
            position: {
              x: posX ? parseFloat(posX) : parseFloat(pos_x),
              y: posY ? parseFloat(posY) : parseFloat(pos_y),
            },
            id: nodeid,
            data: {
              name: name,
              nodeid: nodeid,
            },
          };
          elements.push(call_node_obj);
        } else if (item.type === "SwitchNode" && item.posX && item.posY) {
          const { nodeid, pos_x, pos_y, name, posX, posY } = item;
          const switch_node_obj = {
            type: "switch_node",
            position: {
              x: posX ? parseFloat(posX) : parseFloat(pos_x),
              y: posY ? parseFloat(posY) : parseFloat(pos_y),
            },
            id: nodeid,
            data: {
              name: name,
              nodeid: nodeid,
            },
          };
          elements.push(switch_node_obj);
        } else if (item.type === "JoinNode" && item.posX && item.posY) {
          const { nodeid, pos_x, pos_y, name, posX, posY } = item;
          const join_node_obj = {
            type: "join_node",
            position: {
              x: posX ? parseFloat(posX) : parseFloat(pos_x),
              y: posY ? parseFloat(posY) : parseFloat(pos_y),
            },
            id: nodeid,
            data: {
              name: name,
              nodeid: nodeid,
            },
          };
          elements.push(join_node_obj);
        }
      });
    relations &&
      relations.length > 0 &&
      relations.forEach((item) => {
        const {
          fromNodeID,
          relationid,
          relationname,
          source_node_handle,
          target_node_handle,
          toNodeID,
        } = item;
        if (relationname === "next") {
          const edgeObj = {
            arrorHeadType: "arrowclosed",
            id: "".concat(
              "reactflow__edge-",
              fromNodeID,
              source_node_handle,
              "-",
              toNodeID,
              target_node_handle
            ),
            source: fromNodeID,
            sourceHandle: source_node_handle,
            target: toNodeID,
            targetHandle: target_node_handle,
            type: "buttonEdge",
            relationid: relationid,
          };
          elements.push(edgeObj);
        } else if (relationname !== "next") {
          const edgeObj = {
            arrowHeadType: "arrowclosed",
            id: "".concat(
              "reactflow__edge-",
              fromNodeID,
              source_node_handle,
              "-",
              toNodeID,
              target_node_handle
            ),
            label: relationname,
            labelStyle: {
              font: "normal normal 600 14px/28px Nunito",
              color: "#002855",
            },
            source: fromNodeID,
            sourceHandle: source_node_handle,
            target: toNodeID,
            targetHandle: target_node_handle,
            type: "labelButtonEdge",
            data: { condition: relationname },
            relationid: relationid,
          };
          elements.push(edgeObj);
        }
      });
    // console.log("in orchestration utils", elements)
    return {
      _msg,
      _status,
      data: elements,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteRelation_ = async (templateID, startNodeID, req_data) => {
  try {
    const res_data = await OrchestrationService.deleteRelation(
      templateID,
      startNodeID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 200) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _status, _msg, data } = err.response.data;
    return {
      _status,
      _msg,
      data,
    };
  }
};

export const editRelation_ = async (templateID, sourceNodeID, req_data) => {
  try {
    const res_data = await OrchestrationService.editRelation(
      templateID,
      sourceNodeID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    if (_status === 200 || _status === 201) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _msg, data, _status } = err.response.data;
    return {
      _msg,
      data,
      _status,
    };
  }
};

export const deleteNode_ = async (templateid, nodeid) => {
  try {
    const res_data = await OrchestrationService.deleteNode(templateid, nodeid);
    const { _msg, _status, data } = res_data.data;
    if (_status === 200) {
      return {
        _msg,
        _status,
        data,
      };
    }
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const getContainerNameForApplication_ = async (
  applicationID,
  imageid
) => {
  var containerName = "";
  try {
    const res_data = await OrchestrationService.getContainerNameForApplication(
      applicationID
    );
    const { _msg, _status, data } = res_data.data;
    if (data && data.length === 1) {
      const { services } = data[0];
      const containerArr =
        services && services.length > 0
          ? services.filter((item) => item.imageid === imageid)
          : [];
      if (containerArr.length === 1) {
        containerName = containerArr[0]?.container_name;
      }
    }
    return {
      _status,
      _msg,
      data: {
        containerName: containerName,
      },
    };
  } catch (err) {
    const { _status, _msg, data } = err.response.data;
    return {
      _status,
      _msg,
      data,
    };
  }
};

export const deleteTemplateByID_ = async (templateID) => {
  try {
    const res_data = await OrchestrationService.deleteTemplateByID(templateID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const updateNodePositions_ = async (
  applicationID,
  templateID,
  req_data
) => {
  try {
    const res_data = await OrchestrationService.updateNodePositions(
      applicationID,
      templateID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const switchNodeExpressionHelper = (conditionStr) => {
  var operator = "";
  var value = "";
  var format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
  conditionStr.length > 0 &&
    conditionStr.split("").forEach((str_) => {
      if (str_.match(format)) {
        operator = operator.concat(str_);
      }
    });
  value = conditionStr.slice(operator.length);
  return {
    operator,
    value,
  };
};
