const dataModelFileColumns = [
    // { name: 'id', title: 'Document ID' },
    { name: "filename", title: "File Name", for: "dataModelFile" },
    { name: "uploadedby", title: "Uploaded By", for: "dataModelFile" },
    // { name: "linkedTo", title: "Linked To", for: "dataModelFile" },
    { name: "uploadedon", title: "Uploaded On", for: "dataModelFile" },
    // { name: "createdon", title: "Created On", for: "dataModelFile" },
    // { name: 'importedFrom', title: 'Imported From', for: 'dataModelFile' },
    {name:"status",title:"Upload Status",for:"dataModelFile"},
    { name: "action", title: "Action", route: "/dataModels/edit", for: "dataModelFile" },
  ];
  
  const dataModelFileDefaultColumnWidths = [
    // { columnName: 'id', width: 230 },
    { columnName: "filename", weightage: 2.5 },
    { columnName: "uploadedby", weightage: 2.5 },
    // { columnName: "linkedTo", width: 180 },
    { columnName: "uploadedon", weightage: 2.5 },
    {columnName:"status",weightage:2.5},
    // { columnName: "createdon", weightage: 2.5 },
    // { columnName: 'importedFrom', width: 180 },
    { columnName: "action", weightage: 5 },
  ];
  
  const dataModelFileTableColumnExtensions = [
    // { columnName: 'id', align: 'left' },
    { columnName: "filename", align: "left" },
    { columnName: "uploadedby", align: "left" },
    // { columnName: "linkedTo", align: "left" },
    { columnName: "uploadedon", align: "center" },
    {columnName:"status",align:"center"},
    // { columnName: "createdon", align: "left" },
    // { columnName: 'importedFrom', align: 'left' },
    { columnName: "action", align: "center", sortingEnabled: false },
  ];
  
  const dataModelFileColumnOrder = [
    // 'id',
    "filename",
    "uploadedby",
    // "linkedTo",
    "uploadedon",
    "status",
    // "createdon",
    // 'importedFrom',
    "action",
  ];
  
  const dataModelFileDefaultSorting = [];
  
  // const dataModelFileRows = [
  //   {
  //     id: 12345,
  //     dataModelFileName: "Doc 1",
  //     // linkedTo: "Service 1",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  //   {
  //     id: 12345,
  //     dataModelFileName: "Doc 2",
  //     // linkedTo: "Service 2",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  //   {
  //     id: 12346,
  //     dataModelFileName: "Doc 3",
  //     // linkedTo: "Service 1",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  //   {
  //     id: 12347,
  //     dataModelFileName: "Doc 4",
  //     // linkedTo: "Service 3",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  //   {
  //     id: 12348,
  //     dataModelFileName: "Doc 5",
  //     // linkedTo: "Service Group 1",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  //   {
  //     id: 12349,
  //     dataModelFileName: "Doc 6",
  //     // linkedTo: "Service 1",
  //     versionNumber: "1.0.0",
  //     updatedOn: "02/04/2021",
  //     importedFrom: "Gitlab",
  //   },
  // ];
  export {
    dataModelFileColumns,
    dataModelFileDefaultColumnWidths,
    dataModelFileTableColumnExtensions,
    dataModelFileColumnOrder,
    dataModelFileDefaultSorting,
    // dataModelFileRows,
  };
  