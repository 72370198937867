import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
  } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
const ClusterEdit = (props) => {
    const styles = manageStyles();
    const histroy = useHistory();
    const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);

  return(
    <main className={styles.main}>
    {isLoader && <Loader />}
    {/* <form onSubmit={handleSubmit}> */}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Cluster", path: "/cluster" },
            {title: 'Edit Cluster'}
            // { title: isEditMode ? `Edit ${serviceData.name}` : serviceData.name },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
          Edit Cluster
            {/* {isEditMode ? `Edit ${serviceData.name}` : serviceData.name} */}
          </h2>
          {/* {!isEditMode ? (
            <AppButton
              color="primary"
              buttonName="Edit"
              variant="contained"
              startIcon={<img src="/images/edit.svg" alt="card" />}
              disabled={!checkCompPermission('Microservice', ['Edit'])}
              style={{ padding: "6px 15px" }}
              className="btnsmall"
              onClick={() => histroy.push(`/services/edit/${serviceId}`)}
            />
          ) : ( */}
            <AppButton
              color="primary"
              buttonName="Save"
              variant="contained"
            //   disabled={!checkCompPermission('Microservice', ['Edit'])}
              style={{ margin: "unset" }}
              className="btnsmall"
            //   onClick={handleSubmit}
            />
          {/* )} */}
        </Grid>
      </div>
      {/* </form> */}
      </main>
  )
}

export default (ClusterEdit);
