import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import LanguageDetailsEntity from "../../../components/common/entitySchema/applicationGovernance/language/LanguageDetailsEntity";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { fetchLanguageDetailsByID } from "../../../utils/applicationGovernance/launguageUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const CreateLaunguageScreen = (props) => {
  const styles = manageStyles();
  const [uploadModal, setUploadModal] = useState(true);

  const histroy = useHistory();
  const [languageData, setlanguageData] = useState({});
  const [error, setError] = useState({
    name: { isError: false },
    languages: { isError: false },
  });
  const [languageEntitySchema, setlanguageEntitySchema] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [LaungID] = useState(props.match.params._id);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/application-governance");
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Language", ["Read"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchlanguageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Language",
        "read"
      );
      if (_status === 200) {
        setlanguageEntitySchema(data);
        fetchLanguageData();
        const langData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.datakey) {
                return (langData[field.datakey] = "");
              }
            });
          });
        });

        setlanguageData({ ...languageData, ...langData });
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchLanguageData = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchLanguageDetailsByID(LaungID);
      if (_status === 200) {
        setlanguageData(() => (data ? data : []));
        if (data?.icon?.length == 0) {
          setUploadModal(false);
        }
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchlanguageEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setlanguageData({
      ...languageData,
      [event.target.name]: value,
    });
  };

  // ----------------------------------------------------------------------------------

  const handleEdit = () => {
    if (languageData.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked Language. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      histroy.push(`/edit-language/${LaungID}`);
    }
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Application Governance",
                path: "/application-governance",
              },
              {
                title: "Language",
                path: "/application-governance",
              },
              { title: `${languageData?.name ? languageData?.name : ""}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>{languageData?.name}</h2>
            {tabbedView ? (
              <>
                <TabbedNavigation
                  schemaLength={languageEntitySchema?.sections?.length}
                  isEditMode={false}
                  activeTab={activeTab}
                  handleEdit={handleEdit}
                  handleCancel={handleCancel}
                  isClose={true}
                />
              </>
            ) : (
              <AccordionNavigation disabled={!checkCompPermission("Language", ["Edit"])} handleEdit={handleEdit} isEditMode={true} handleCancel={handleCancel}
                isClose={true}

              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={
                  languageEntitySchema ? languageEntitySchema.sections : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <LanguageDetailsEntity
                    section={languageEntitySchema?.sections?.[activeTab]}
                    languageData={languageData}
                    error={error}
                    uploadModal={uploadModal}
                    handleChange={handleChange}
                  />
                </div>
              </Paper>
            </>
          ) : (
            languageEntitySchema?.sections &&
            languageEntitySchema?.sections.map((section) => {
              if (section.name === "Language_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <LanguageDetailsEntity
                      section={section}
                      languageData={languageData}
                    />
                  </div>
                );
              } else {
                return (
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LanguageDetailsEntity
                        section={section}
                        languageData={languageData}
                        error={error}
                        uploadModal={uploadModal}
                        handleChange={handleChange}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLaunguageScreen);
