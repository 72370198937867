import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import applicationReducer from "./applicationReducer";
import serviceReducer from "./serviceReducer";
import projectReducer from "./projectReducer";
import orchestration from "./orchestration";
import vendorReducer from "./vendorReducer";
import containerReducer from "./containersReducer";
import dockerImageReducer from "./dockerImageReducer";
import runtimeEnvReducer from "./runtimeEnvReducer";
import userInfoReducer from "./userInfoReducer";
import accessmaintainanceReducer from "./accessmaintainanceReducer";
import viewedReducer from "./recentlyVisitedReducer";
import activeTabReducer from "./activeTabReducer";
import serviceGroupReducer from "./serviceGroupReducer";
import {
  selectTabReducer,
  userDetailsReducer,
  userEditReducer,
} from "./userProfile";
import { setStatusReducer, statusDetailsReducer } from "./statusProfile";
import teamsReducer from "./teamReducer";
import appGovernanceReducer from "./appGovernanceReducer";
import authReducer from "./authReducer";
import organizationReducer from "./organizationReducer";
import dataStandardReducer from "./dataStandardReducer";
import ontologyReducer from "./ontologyReducer";
import { issueAllReducer } from "./issueDetailsReducer";
import { appearnceReducer } from "./uiReducer";
import allDocumentsReducer from "./documentReducer";
import standardDocumentReducer from "./standardDocumentReducer";
import documentationReducer from "./documentationReducer";
import userPersonalReducer from "./userPersonalReducer";
import aiTaskReducer from "./aiTaskReducer";
import dataModelReducer from "./dataModelsReducer";
import codeEditorReducer from "./codeEditorReducer";
import serviceGUIReducer from "./serviceGUIReducer";
import deploymentProcessReducer from "./DeploymentProcessReducer";
import clusterReducer from "./clusterReducer";
import testExecutionReducer from "./testExecutionReducer";
import testScriptReducer from "./testScriptReducer";
import testDataReducer from "./testDataReducer";
import uiApplicationReducer from "./uiApplicationReducer";
import appBundleReducer from "./appBundleReducer";
import uiApplicationListReducer from "./uiApplicationListReducer";
const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "applicationReducer",
    "projectReducer",
    "serviceReducer",
    "serviceGroupReducer",
    "orchestration",
    "vendorReducer",
    "containerReducer",
    "dockerImageReducer",
    "runtimeEnvReducer",
    "accessmaintainanceReducer",
    "appGovernanceReducer",
    "teamsReducer",
    "dataStandardReducer",
    "ontologyReducer",
    "statusDetails",
    "projectDocument",
    "allDocumentsReducer",
    "documentationReducer",
    "standardDocumentReducer",
    "userEdit",
    "appearnce",
    "issueAll",
    "setStatus",
    "selectTab",
    "userInfoReducer",
    "aiTaskReducer",
    "dataModelReducer",
    "codeEditorReducer",
    "deploymentProcessReducer",
    "clusterReducer",
    "testExecutionReducer",
    "testScriptReducer",
    "testDataReducer",
    "appBundleReducer"
  ],
};

const rootReducer = combineReducers({
  applicationReducer: applicationReducer,
  serviceReducer: serviceReducer,
  serviceGroupReducer: serviceGroupReducer,
  projectReducer: projectReducer,
  orchestration: orchestration,
  vendorReducer: vendorReducer,
  containerReducer: containerReducer,
  dockerImageReducer: dockerImageReducer,
  runtimeEnvReducer: runtimeEnvReducer,
  userInfoReducer: userInfoReducer,
  accessmaintainanceReducer: accessmaintainanceReducer,
  appGovernanceReducer: appGovernanceReducer,
  viewedReducer: viewedReducer,
  activeTabReducer: activeTabReducer,
  selectTab: selectTabReducer,
  appearnce: appearnceReducer,
  userEdit: userEditReducer,
  userDetails: userDetailsReducer,
  userPersonal: userPersonalReducer,
  teamsReducer: teamsReducer,
  authReducer: authReducer,
  organizationReducer: organizationReducer,
  dataStandardReducer: dataStandardReducer,
  ontologyReducer: ontologyReducer,
  statusDetails: statusDetailsReducer,
  setStatus: setStatusReducer,
  issueAll: issueAllReducer,
  allDocumentsReducer: allDocumentsReducer,
  standardDocumentReducer: standardDocumentReducer,
  documentationReducer: documentationReducer,
  aiTaskReducer: aiTaskReducer,
  serviceGUIReducer: serviceGUIReducer,
  dataModelReducer: dataModelReducer,
  codeEditorReducer: codeEditorReducer,
  deploymentProcessReducer: deploymentProcessReducer,
  clusterReducer: clusterReducer,
  testExecutionReducer: testExecutionReducer,
  testScriptReducer: testScriptReducer,
  testDataReducer: testDataReducer,
  uiApplicationReducer: uiApplicationReducer,
  uiApplicationListReducer: uiApplicationListReducer,
  appBundleReducer:appBundleReducer,
  // statusDetails: statusDetailsReducer,
  // setStatus: setStatusReducer,
  // issueAll: issueAllReducer,
  // allDocumentsReducer: allDocumentsReducer,
  // standardDocumentReducer: standardDocumentReducer,
  // documentationReducer: documentationReducer
});

export default persistReducer(rootPersistConfig, rootReducer);
