import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
import React, {useState, useEffect} from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import InputTable from "../../Tables/InputTable";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import FieldGroupTree from "../../FieldGroupTree";

const CustomFieldGroupForAiTaskApiCreate = ({
  section,
  apiData,
  setApiData,
  headers,
  setHeaders,
  error,
  handleChange,
  userInfo,
  tab,
  handleTabChange,
  fieldGroups,
  fieldGroupDetailsForInput,
  deletePathParameter,
  deleteQueryParameter,
  fieldGroupDetailsForOutput,
  functionList,
  isApiRequired,
}) => {
  const styles = manageStyles();

  const [payloadOpen,setPayloadOpen] = useState(false);
  const [payload2Open,setPayload2Open] = useState(false);
  const [functionOpen,setFunctionOpen] = useState(false);

  const closeDropDown = () =>{
    setPayloadOpen(false);
    setPayload2Open(false);
    setFunctionOpen(false);
  }

  useEffect(()=>{
    window.addEventListener('scroll',closeDropDown);
  },[])

  const parameterSection = () => {
    return (
      <div style={{ height: "25rem" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className={styles.apiTab}
          style={{ bottom: 15 }}
        >
          <Tab label="Input" />
          <Tab label="Output" />
          {isApiRequired && <Tab label="Headers" />}
          {isApiRequired && <Tab label="Query Parameters" />}
          {isApiRequired && <Tab label="Path Parameters" />}
        </Tabs>
        {tab === 0 && (
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Input
              </label>
              <Autocomplete
                open={payloadOpen}
                onOpen={()=> setPayloadOpen(true)}
                onClose={()=> setPayloadOpen(false)}
                options={fieldGroups}
                getOptionLabel={(option) => option.name}
                value={
                  fieldGroupDetailsForInput ? fieldGroupDetailsForInput : {}
                }
                className={`${styles.searchInput3}`}
                onChange={(e, input) => {
                 
                  if (input) {
                    setApiData({ ...apiData, input: input._id });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Search Payload"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FieldGroupTree data={fieldGroupDetailsForInput} />
            </Grid>
          </Grid>
        )}
        {tab === 1 && (
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Output
              </label>
              <Autocomplete
                open={payload2Open}
                onOpen={()=> setPayload2Open(true)}
                onClose={()=> setPayload2Open(false)}
                options={fieldGroups}
                getOptionLabel={(option) => option.name}
                value={
                  fieldGroupDetailsForOutput ? fieldGroupDetailsForOutput : {}
                }
                className={`${styles.searchInput3}`}
                onChange={(e, output) => {
                 
                  if (output) {
                    setApiData({ ...apiData, output: output._id });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Search Payload"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FieldGroupTree data={fieldGroupDetailsForOutput} />
            </Grid>
          </Grid>
        )}
        {tab === 2 && (
          <InputTable envs={headers} setEnvs={(env) => setHeaders(env)} />
        )}

        {tab === 3 && (
          <div>
            <Grid container spacing={1} style={{ marginTop: "0rem" }}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Query Parameters
                </label>
                <IconButton
                  color="inherit"
                  aria-label="edit"
                  edge="start"
                  onClick={() =>
                    setApiData({
                      ...apiData,
                      queryParameters: [...apiData.queryParameters, ""],
                    })
                  }
                  className={styles.actionIcon2}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              {apiData.queryParameters.map((query, index) => (
                <Grid item xs={4} sm={4} key={index}>
                  <Grid
                    key={index}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item xs={10} sm={10}>
                      <input
                        type="text"
                        name={`${index}_query`}
                        className={styles.input}
                        value={apiData.queryParameters[index]}
                        onChange={(e) =>
                          setApiData({
                            ...apiData,
                            query: [
                              ...apiData.queryParameters,
                              (apiData.queryParameters[index] = e.target.value),
                            ],
                          })
                        }
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() => deleteQueryParameter(index)}
                        className={styles.actionIcon2}
                        style={{ marginLeft: -5 }}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        {tab === 4 && (
          <div>
            <Grid container spacing={1} style={{ marginTop: "0rem" }}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Path Parameters
                </label>
                <IconButton
                  color="inherit"
                  aria-label="edit"
                  edge="start"
                  onClick={() =>
                    setApiData({
                      ...apiData,
                      pathParameters: [...apiData.pathParameters, ""],
                    })
                  }
                  className={styles.actionIcon2}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              {apiData.pathParameters.map((path, index) => (
                <Grid item xs={4} sm={4} key={index}>
                  <Grid
                    key={index}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item xs={10} sm={10}>
                      <input
                        type="text"
                        name={`${index}_path`}
                        className={styles.input}
                        value={apiData.pathParameters[index]}
                        onChange={(e) =>
                          setApiData({
                            ...apiData,
                            pathParam: [
                              ...apiData.pathParameters,
                              (apiData.pathParameters[index] = e.target.value),
                            ],
                          })
                        }
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() => deletePathParameter(index)}
                        className={styles.actionIcon2}
                        style={{ marginLeft: -5 }}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    );
  };
  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "AI Task API Path") {
          return (
            <>
              {apiData.type === "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else if (field.name === "AI Task Topic") {
          return (
            <>
              {apiData.type !== "rest" ? (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    {field?.fieldLabel}{" "}
                    {field?.required === "TRUE" && (
                      <span className={styles.red}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name={field.datakey}
                    className={`${styles.input} ${
                      error[field.datakey]?.isError && styles.error
                    }`}
                    value={
                      field.dataType === "date" && apiData[field.datakey]
                        ? moment
                            .unix(apiData[field.datakey])
                            .tz(userInfo.timeZone)
                            .format(
                              userInfo.time === "h:mm"
                                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                : `${userInfo.dateFormat} ${userInfo.time}`
                            )
                        : apiData[field.datakey]
                    }
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  field.dataType === "date" && apiData[field.datakey]
                    ? moment
                        .unix(apiData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : apiData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={apiData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        
        if (field.name === "AI Task API Method") {

          if (!isApiRequired) {
            return (
              <>
                {apiData.type === "rest" ? (
                  <>
                    <label htmlFor="" className={styles.fieldHeading}>
                      {field?.fieldLabel}{" "}
                      {field?.required === "TRUE" && (
                        <span className={styles.red}>*</span>
                      )}
                    </label>
                    <Select
                      name={field.datakey}
                      value={
                        apiData[field.datakey] ? apiData[field.datakey] : ""
                      }
                      onChange={handleChange}
                      className={`${styles.select} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {/* {JSON.parse(field?.values)?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                        
                      ))} */}

                      <MenuItem value={'POST'}>POST</MenuItem>
                      <MenuItem value={'GET'}>GET</MenuItem>
                      <MenuItem value={'PUT'}>PUT</MenuItem>
                      <MenuItem value={'DELETE'}>DELETE</MenuItem>
                    </Select>
                    {error[field.datakey]?.isError && (
                      <p className={styles.errorMsg}>
                        {error[field.datakey].msg}
                      </p>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          } else {
            return (
              <>
                {apiData.type === "rest" ? (
                  <>
                    <label htmlFor="" className={styles.fieldHeading}>
                      {field?.fieldLabel}{" "}
                      {field?.required === "TRUE" && (
                        <span className={styles.red}>*</span>
                      )}
                    </label>
                    <Select
                      name={field.datakey}
                      value={
                        apiData[field.datakey] ? apiData[field.datakey] : ""
                      }
                      onChange={handleChange}
                      className={`${styles.select} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {JSON.parse(field?.values)?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {error[field.datakey]?.isError && (
                      <p className={styles.errorMsg}>
                        {error[field.datakey].msg}
                      </p>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          }
        } else if (field.name === "AI Task API Function") {
         
          if (!isApiRequired) {
            return <></>;
          } else {
       
            return (
              <>
                <label htmlFor="" className={styles.fieldHeading}>
                  Function <span className={styles.red}>*</span>
                </label>
                <Autocomplete
                  open={functionOpen}
                  onOpen={()=> setFunctionOpen(true)}
                  onClose={()=> setFunctionOpen(false)}
                  options={functionList && functionList.length > 0 ? functionList : []}
                  getOptionLabel={(option) =>
                    option?.cls_name
                      ? option.cls_name + "." + option.func_name
                      : option.func_name
                  }
                  className={`${styles.searchInput3} ${
                    error.function?.isError &&
                    !apiData?.funciton?._id &&
                    styles.error
                  }`}
                  onChange={(e, input) => {
                    if (input) {
                      setApiData({
                        ...apiData,
                        function: input,
                        path: "/" + input?.func_name,
                      });
                      // setProjectSearchInput({...ProjectSearchInput,input});

                      // setSelectedProject(input);
                      // setServiceData({
                      //     ...serviceData,
                      //     projectID: input?._id,
                      // });
                    } else {
                      setApiData({
                        ...apiData,
                        function: '',
                        path: '',
                      });

                    }
                    // else {
                    //     setSelectedProject({});
                    //     setSelectedServiceGroup({})
                    //     setServiceData({ ...serviceData, projectID: "", servicegroupID: "" });
                    // }
                  }}
                  // onInputChange={(event, newInputValue) => {
                  //     setProjectSearchInput(newInputValue);
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Function"
                    />
                  )}
                />
                 {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
                {/* {apiData.type === "http" ? <>
                            <label htmlFor="" className={styles.fieldHeading}>{field?.fieldLabel} {field?.required === "TRUE" && <span className={styles.red}>*</span>}</label>
                            <input
                                type="text"
                                name={field.datakey}
                                className={`${styles.input} ${error[field.datakey]?.isError && styles.error}`}
                                value={field.dataType === "date" && apiData[field.datakey] ?
                                    moment.unix(apiData[field.datakey]).tz(userInfo.timeZone).format(userInfo.time === 'h:mm' ? `${userInfo.dateFormat}, ${userInfo.time} A` : `${userInfo.dateFormat} ${userInfo.time}`) :
                                    apiData[field.datakey]}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                            {error[field.datakey]?.isError && <p className={styles.errorMsg}>{error[field.datakey].msg}</p>}
                        </> : <></>} */}
              </>
            );
          }
        } else if (field.name === "AI Task API Type") {
          if (!isApiRequired) {
            return (
              <>
                <label htmlFor="" className={styles.fieldHeading}>
                  {field?.fieldLabel}{" "}
                  {field?.required === "TRUE" && (
                    <span className={styles.red}>*</span>
                  )}
                </label>
                <Select
                  name={field.datakey}
                  value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                  onChange={handleChange}
                  className={`${styles.select} ${
                    error[field.datakey]?.isError && styles.error
                  }`}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {typeof field?.values === "string"
                    ? JSON.parse(field?.values)?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))
                    : field?.values?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))}
                </Select>
                {error[field.datakey]?.isError && (
                  <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                )}
              </>
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={apiData[field.datakey] ? apiData[field.datakey] : ""}
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values)?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={apiData[field.datakey] ? apiData[field.datakey] : null}
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  apiData[`${field.datakey}_unit`]
                    ? apiData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values)?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))
                  : field?.values?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                }`}
                value={apiData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={apiData[field.datakey] ? apiData[field.datakey] : ""}
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values)?.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))
                : field?.values?.map((value, index) => (
                    <FormControlLabel
                      value={value}
                      key={index}
                      control={<Radio color="primary" />}
                      label={value}
                    />
                  ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "AITask_API_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Parameters":
                          return parameterSection();
                        default:
                          if (field.name === "AI Task API Function") {
                           
                            if (isApiRequired) {
                              return (
                                <Grid item xs={4} sm={4} key={field._id}>
                                  {generateField(field)}
                                </Grid>
                              );
                            } else {
                              return <></>;
                            }
                          } else if (field.name === "AI Task API Path") {
                            return (
                              <>
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    key={field._id}
                                  ></Grid>

                                <Grid item xs={4} sm={4} key={field._id}>
                                  {generateField(field)}
                                </Grid>
                              </>
                            );
                          } else if (field.name === "AI Task API Type") {
                            if (isApiRequired) {
                              return <></>;
                            } else {
                              return (
                                <>
                                {isApiRequired && <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    key={field._id}
                                  ></Grid>}
                                  
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    key={field._id}
                                  >
                                    {generateField(field)}
                                  </Grid>
                                </>
                              );}
                          } else if(field.name === "AI Task Topic"){
                            if (isApiRequired) {
                              return <></>;
                            }
                          } else {
                            return (
                              <Grid item xs={4} sm={4} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          }
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                 
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                  
                            switch (field.name) {
                              
                              case "Parameters":
                                return parameterSection();
                              default:
                                if (field.name === "AI Task API Function") {
                                  if (isApiRequired) {
                                    return (
                                      <Grid item xs={4} sm={4} key={field._id}>
                                        {generateField(field)}
                                      </Grid>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                } else if (field.name === "AI Task API Path") {
                                  return (
                                    <>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          key={field._id}
                                        ></Grid>

                                      <Grid item xs={4} sm={4} key={field._id}>
                                        {generateField(field)}
                                      </Grid>
                                    </>
                                  );
                                } else if (field.name === "AI Task API Type") {
                                  if (isApiRequired) {
                                    return <></>;
                                  } else {
                                    return (
                                      <>
                                      {isApiRequired && <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          key={field._id}
                                        ></Grid>}
                                        
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          key={field._id}
                                        >
                                          {generateField(field)}
                                        </Grid>
                                      </>
                                    );}
                                } else if(field.name === "AI Task Topic"){
                                  if (isApiRequired) {
                                    return <></>;
                                  }
                                } else {
                                  return (
                                    <Grid item xs={4} sm={4} key={field._id}>
                                      {generateField(field)}
                                    </Grid>
                                  );
                                }
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { functionList } = state.aiTaskReducer;

  return {
    userInfo,
    functionList,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupForAiTaskApiCreate);
