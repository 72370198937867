import {
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import manageStyles from "../../screens/CommonStyles/manageScreenStyles";
import { AppButton } from "./Button";
import { v4 as uuidv4 } from "uuid";
import { fetchDocumentTypeListData } from "../../utils/project/documentUtils";
import { useSnackbar } from "notistack";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertEditorToFile, handleFormValidation } from "../../utils/common";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";

const CustomDropperIcon = () => (
  <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
);

const initDocError = (id) => ({
  id: id,
  name: {
    isError: false,
    msg: "",
  },
  version: {
    isError: false,
    msg: "",
  },
  docType: {
    isError: false,
    msg: "",
  },
  status: {
    isError: false,
    msg: "",
  },
});

const FileDropperWithLayout = ({
  selectedDocuments,
  setSelectedDocuments,
  documentsError,
  setDocumentsError,
}) => {
  const styles = manageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [documentTab, setDocumentTab] = useState("Upload");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [random, setRandom] = useState(Math.random());
  const [customFile, setCustomFile] = useState({
    name: "",
    version: "",
    docType: "",
    file: "",
    status: "",
  });
  const [customFileError, setCustomFileError] = useState({
    name: false,
    version: false,
    docType: false,
    file: false,
    status: false,
  });
  const [statuses, setStatuses] = useState([]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (checkCompPermission("DocumentType", ["List All"])) {
      fetchAllDocumentTypes();
    }
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Document"
      );
      if (_status === 200) {

        setStatuses(data ? data.status : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  const handleChangeDocumentTab = (event, newValue) => {
    setDocumentTab(newValue);
    setCustomFileError({
      name: false,
      version: false,
      docType: false,
      file: false,
      status: false,
    });
    setCustomFile({
      name: "",
      version: "",
      docType: "",
      file: "",
      status: "",
    });
    const tempError = documentsError.map((e) => {
      return {
        ...e,
        docType: { ...e["docType"], isError: false },
        name: { ...e["name"], isError: false },
        version: { ...e["version"], isError: false },
        status: { ...e['status'], isError: false },
      };
    });
    setDocumentsError(tempError);
    setSelectedDocuments([]);
  };

  const handleDeleteDocument = (documentToDelete) => () => {
    setSelectedDocuments((documents) =>
      documents.filter((doc) => doc.name !== documentToDelete.name)
    );
    setRandom(Math.random());
  };

  const fetchAllDocumentTypes = async () => {
    const { _msg, _status, data } = await fetchDocumentTypeListData();
    if (_status === 200) {
      if (_status === 200) {
        setDocumentTypes(data ? data : []);
      }
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleSelectStatus = (status, id) => {
    const tempError = documentsError.map((e) => {
      if (e.id === id) {
        return { ...e, status: { ...e["status"], isError: false } };
      } else {
        return e;
      }
    });
    setDocumentsError(tempError);
    const newDoc = selectedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, status: status };
      } else {
        return doc;
      }
    });
    setSelectedDocuments(newDoc);
  };

  const handleDocChange = (event, id, item) => {
    const tempError = documentsError.map((e) => {
      if (e.id === id) {
        return {
          ...e,
          [event.target.name]: { ...e[event.target.name], isError: false },
        };
      } else {
        return e;
      }
    });

    setDocumentsError(tempError);
    const newDoc = selectedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, [event.target.name]: event.target.value };
      } else {
        return doc;
      }
    });
    setSelectedDocuments(newDoc);
  };

  const handleCustomDocChange = (event, id) => {
    const value = event.target.value;
    setCustomFileError({
      ...customFileError,
      [event.target.name]: false,
    });
    setCustomFile({
      ...customFile,
      [event.target.name]: value,
    });
  };

  const handleSelectDocumentType = (docType, id) => {
    const tempError = documentsError.map((e) => {
      if (e.id === id) {
        return { ...e, docType: { ...e["docType"], isError: false } };
      } else {
        return e;
      }
    });
    setDocumentsError(tempError);
    const newDoc = selectedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, docType: docType };
      } else {
        return doc;
      }
    });
    setSelectedDocuments(newDoc);
  };
  const saveCustomFile = () => {
    const convertedFile = convertEditorToFile(editorState);
    const fileData = {
      name: customFile.name,
      version: customFile.version,
      docType: customFile.docType,
      file: convertedFile,
      status: customFile.status?.status
    };
    const { formIsValid, errors } = handleFormValidation(
      fileData,
      customFileError
    );
    if (formIsValid) {
      setSelectedDocuments([
        {
          ...fileData,
          id: uuidv4(),
          name: fileData.name + ".html",
          file: new File([fileData.file], fileData.name + ".html"),
        },
      ]);
      setCustomFile({ name: "", version: "", docType: {}, file: null, status: {} });
      enqueueSnackbar(`${fileData.name} save successfully`, {
        variant: "success",
      });
    } else {
      setCustomFileError(errors);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Backdrop
        className={styles.backdrop}
        open={isLoader}
        onClick={() => setIsLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <RadioGroup
        value={documentTab}
        onChange={handleChangeDocumentTab}
        indicatorColor="primary"
        textColor="primary"
        color="primary"
        className={styles.documentTab}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel
          value="Upload"
          control={<Radio color="primary" />}
          label="Upload"
        />
        <FormControlLabel
          value="Text Editor"
          control={<Radio color="primary" />}
          label="Text Editor"
        />
      </RadioGroup>
      {documentTab === "Upload" && (
        <div>
          <div className={styles.documentDropperWrapper}>
            {checkCompPermission("DocumentType", ["List All"]) ? (
              <DropzoneArea
                key={random}
                Icon={CustomDropperIcon}
                showPreviews={false}
                filesLimit={5}
                dropzoneText="Drag and drop or click to browse here"
                dropzoneClass="documentDropperClass"
                dropzoneParagraphClass="documentDropperTextClass"
                showAlerts={["error"]}
                maxFileSize={90000000}
                showPreviewsInDropzone={false}
                onChange={(files) => {
                  let tempFiles = [...selectedDocuments];
                  let tempErrors = documentsError;
                  files.map((file) => {
                    if (
                      selectedDocuments.filter((f) => f.name === file.name)
                        .length === 0
                    ) {
                      const id = uuidv4();
                      tempFiles.push({
                        id: id,
                        name: file.name,
                        version: "",
                        docType: "",
                        file,
                        status: "",
                      });
                      tempErrors.push(initDocError(id));
                    }
                  });

                  if (tempFiles.length !== 0) {
                    setSelectedDocuments(tempFiles);
                    setDocumentsError(tempErrors);
                  }
                }}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <div>
                  <DropzoneArea
                    key={random}
                    Icon={CustomDropperIcon}
                    showPreviews={false}
                    filesLimit={5}
                    dropzoneText="Drag and drop or click to browse here"
                    dropzoneClass="documentDropperClass"
                    dropzoneParagraphClass="documentDropperTextClass"
                    showAlerts={["error"]}
                    maxFileSize={90000000}
                    showPreviewsInDropzone={false}
                    onChange={(files) => {
                      let tempFiles = [...selectedDocuments];
                      let tempErrors = documentsError;
                      files.map((file) => {
                        if (
                          selectedDocuments.filter((f) => f.name === file.name)
                            .length === 0
                        ) {
                          const id = uuidv4();
                          tempFiles.push({
                            id: id,
                            name: file.name,
                            version: "",
                            docType: "",
                            file,
                            status: "",
                          });
                          tempErrors.push(initDocError(id));
                        }
                      });

                      if (tempFiles.length !== 0) {
                        setSelectedDocuments(tempFiles);
                        setDocumentsError(tempErrors);
                      }
                    }}
                  />
                </div>
                <span style={{ marginTop: "136px", marginLeft: "25px" }}>
                  <p className={styles.fieldHeading} style={{ color: "red" }}>
                    You do not have permission to Document Type List All. Please
                    contact your Admin.
                  </p>
                </span>
              </div>
            )}
          </div>

          {selectedDocuments.length !== 0 && (
            <div className={styles.droppedDocuments}>
              {selectedDocuments.map((item, index) => (
                <Grid
                  container
                  spacing={3}
                  style={{
                    borderBottom:
                      selectedDocuments.length !== index + 1
                        ? "1px solid rgba(0, 40, 85, .4)"
                        : "unset",
                    marginBottom: 20,
                    paddingBottom: 10,
                  }}
                  key={index}
                >
                  <Grid item xs={4} sm={4}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // marginTop: 20,
                        }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          className={styles.actionIcon}
                        >
                          <img
                            src="/images/Doc_Icon.svg"
                            alt="doc"
                            style={{ filter: "brightness(.3)", height: 25 }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Document Name <span className={styles.red}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`${styles.input} ${documentsError[index].name.isError && styles.error
                            }`}
                          name="name"
                          value={item.name}
                          onChange={(e) => handleDocChange(e, item.id)}
                          autoComplete="off"
                        />
                        {documentsError[index].name.isError && (
                          <p className={styles.errorMsg}>
                            {documentsError[index].name.msg}
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Version Number <span className={styles.red}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`${styles.input} ${documentsError[index].version.isError && styles.error
                        }`}
                      name="version"
                      value={item.version}
                      onChange={(e) => handleDocChange(e, item.id)}
                      autoComplete="off"
                    />
                    {documentsError[index].version.isError && (
                      <p className={styles.errorMsg}>
                        {documentsError[index].version.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={4} sm={4}>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Document Type <span className={styles.red}>*</span>
                        </label>
                        <Autocomplete
                          options={documentTypes}
                          getOptionLabel={(option) => option.name}
                          className={`${styles.searchInput3} ${documentsError[index].docType.isError &&
                            styles.error
                            }`}
                          onChange={(e, input) => {
                            handleSelectDocumentType(input, item.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                            // placeholder="Select Document Type"
                            />
                          )}
                        />
                        {documentsError[index].docType.isError && (
                          <p className={styles.errorMsg}>
                            {documentsError[index].docType.msg}
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Status <span className={styles.red}>*</span>
                        </label>
                        <Autocomplete
                          options={statuses}
                          getOptionLabel={(option) => option.status}
                          className={`${styles.searchInput3} ${
                            documentsError[index].status.isError &&
                            styles.error
                          }`}
                          onChange={(e, input) => {
                            handleSelectStatus(input, item.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              // placeholder="Select Document Type"
                            />
                          )}
                        />
                        {documentsError[index].status.isError && (
                          <p className={styles.errorMsg}>
                            {documentsError[index].status.msg}
                          </p>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // marginTop: 20,
                        }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={handleDeleteDocument(item)}
                          className={styles.actionIcon}
                        >
                          <img src="/images/deleteLine.svg" alt="card" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        </div>
      )}
      {documentTab === "Text Editor" && (
        <div className={styles.documentEditorWrapper}>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(data) => setEditorState(data)}
          />
          {customFileError.file && (
            <p className={styles.errorMsg}>{"Document content is Required"}</p>
          )}
          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Name <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.name && styles.error
                  }`}
                name="name"
                value={customFile.name}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.name && (
                <p className={styles.errorMsg}>{"Document Name is Required"}</p>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <label htmlFor="" className={styles.fieldHeading}>
                Version Number <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.version && styles.error
                  }`}
                name="version"
                value={customFile.version}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.version && (
                <p className={styles.errorMsg}>{"Version is required"}</p>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Type <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                options={documentTypes}
                getOptionLabel={(option) => option.name}
                className={`${styles.searchInput3} ${customFileError.docType && styles.error
                  }`}
                onChange={(e, input) => {
                  setCustomFile({ ...customFile, docType: input });
                  setCustomFileError({ ...customFileError, docType: false });
                }}
                value={customFile.docType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={customFile.docType}
                    variant="standard"
                  // placeholder="Select Document Type"
                  />
                )}
              />
              {customFileError.docType && (
                <p className={styles.errorMsg}>{"Document type is required"}</p>
              )}
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Status <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                options={statuses}
                getOptionLabel={(option) => option.status}
                className={`${styles.searchInput3} ${
                  customFileError.status && styles.error
                }`}
                onChange={(e, input) => {
                  setCustomFile({ ...customFile, status: input });
                  setCustomFileError({ ...customFileError, status: false });
                }}
                value={customFile.status}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={customFile.status}
                    variant="standard"
                    // placeholder="Select Document Type"
                  />
                )}
              />
              {customFileError.status && (
                <p className={styles.errorMsg}>{"Status is required"}</p>
              )}
            </Grid>
            <Grid item xs={2} sm={2} className={styles.createDocumentBtn}>
              <AppButton
                color="primary"
                buttonName="Save"
                variant="contained"
                disabled={false}
                style={{ margin: "unset", marginRight: 10 }}
                className="btnmedium"
                onClick={() => saveCustomFile()}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default FileDropperWithLayout;
