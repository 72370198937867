import {
  ADD_CONTAINERS_DATA,
  CONTAINER_FILTERS,
  DELETE_CONTAINER,
  CONTAINERS_SORT,
  START_OR_STOP_CONTAINER,
  CONTAINERS_HIDDEN_COULMNS,
  CLEAR_CONTAINER_DATA,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  containers_list: {
    data: [],
    error: false,
    loading: false,
  },
  containerFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  containerSort: [{ columnName: "createdon", direction: "desc" }],
  containerHiddenCols: [
    "containername",
    "containerState",
    "createdby",
    "createdon",
    "containeractions",
  ],
};

export default function containerReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CONTAINERS_DATA: {
      return {
        ...state,
        containers_list: {
          ...state.containers_list,
          ...action.payload,
          deleteContainerStatus: false,
        },
      };
    }
    case CONTAINER_FILTERS: {
      return {
        ...state,
        containerFilter: [...action.payload],
      };
    }
    case CONTAINERS_SORT: {
      return {
        ...state,
        containerSort: [...action.payload],
      };
    }
    case DELETE_CONTAINER: {
      return {
        ...state,
        containers_list: {
          ...state.containers_list,
          data:
            state.containers_list.data &&
            state.containers_list.data.filter(
              (con) => con._id !== action.payload.conID
            ),
          deleteContainerStatus: true,
        },
      };
    }
    case START_OR_STOP_CONTAINER: {
      return {
        ...state,
        containers_list: {
          ...state.containers_list,
          data:
            state.containers_list.data &&
            state.containers_list.data.map((item) => {
              if (item._id === action.payload.conID) {
                return {
                  ...item,
                  state: action.payload.state,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case CONTAINERS_HIDDEN_COULMNS: {
      return {
        ...state,
        containerHiddenCols: [...action.payload],
      };
    }

    case CLEAR_CONTAINER_DATA: {
      return {
        ...state,
        containers_list: {},
      };
    }
    default:
      return state;
  }
}
