import React from "react";
import { JsonToTable } from "react-json-to-table";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    // height : 200,
    width: "30rem",
    minWidth: "10rem",
    marginTop: "2rem",
    marginLeft: "3rem",
  },
});

function JSONToTable(props) {
  const { jsonBody } = props;
  const styles = useStyles();

  // const myJson = {
  //   Analyst: { name: "Jack", email: "jack@xyz.com" },
  //   "Loaded by": "Jills",
  //   "Load id": 34,
  //   "git id": "xxqaygqertqsg98qhpughqerjjjjjjjjjjjjjjjjjjjj",
  //   "Analysis Id": "7asdlnagsd98gfaqsgf",
  //   "Load Date": "July 12, 2018",
  //   "Data Source": "Study XY123-456",
  //   "Jira Ticket": "Foo-1",
  //   "Confluence URL": "http://myserver/wxyz",
  //   "Study sponsors": [
  //     { name: "john", email: "john@@xyz.com" },
  //     { name: "jane", email: "jane@@xyz.com" },
  //   ],
  // };

  return (
    <div className={styles.container}>
      <JsonToTable json={jsonBody} />
    </div>
  );
}

export default JSONToTable;
