import useGetData from "../../../../../hooks/useGetData";
import StatusStyles from "./TrueFalseButtonStyles";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useTheme } from "@material-ui/styles";

const TrueFalseButton = ({ label, value, name }) => {
	const styles = StatusStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { currentPage, currentComponent } = useGetData();

	const handleChange = (data) => {
		const components = currentPage.components.map((component) => {
			if (component.id === currentComponent.id) {
				return {
					...component,
					[name]: data,
				};
			} else {
				return component;
			}
		});
		dispatch(
			editComponent({
				pageid: currentPage.id,
				data: components,
			})
		);
	};
	return (
		<>
			<label>{label}</label>
			<div className={styles.loadingbtnContainer}>
				<button
					className={styles.loadingbtn}
					onClick={() => handleChange("left")}
					style={
						value === "left"
							? {
									backgroundColor: theme.palette.primary.main,
									color: "#FFFFFF",
									textTransform: "capitalize",
							  }
							: { textTransform: "capitalize" }
					}>
					{value === "left" ? (
						<img src="/img/left-selected.svg" alt="" />
					) : (
						<img src="/img/left-unselect.svg" alt="" />
					)}
				</button>
				<button
					className={styles.loadingbtn}
					onClick={() => handleChange("center")}
					style={
						value === "center"
							? {
									backgroundColor: theme.palette.primary.main,
									color: "#FFFFFF",
									textTransform: "capitalize",
							  }
							: { textTransform: "capitalize" }
					}>
					{value === "center" ? (
						<img src="/img/center-selected.svg" alt="" />
					) : (
						<img src="/img/center-unselect.svg" alt="" />
					)}
				</button>
				<button
					style={
						value === "right"
							? {
									backgroundColor: theme.palette.primary.main,
									color: "#FFFFFF",
									textTransform: "capitalize",
							  }
							: { textTransform: "capitalize" }
					}
					className={styles.loadingbtn}
					onClick={() => handleChange("right")}>
					{value === "right" ? (
						<img src="/img/right-selected.svg" alt="" />
					) : (
						<img src="/img/right-unselect.svg" alt="" />
					)}
				</button>
			</div>
		</>
	);
};

export default TrueFalseButton;
