import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import useStyles from "../../../../screens/Orchestration/Application/ApplicationDetailScreen/Styles";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import ServiceVersionSelectTable from "../../../../screens/Orchestration/Application/CreateApplicationScreen/ServiceVersionSelectTable";
import DefaultPagination from "../../Tables/DefaultPagination";
import { Pagination } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import ApplicationDeploymentTable from "../../../Orchestration/Application/ApplicationDeploymentTable";
import {
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
  serviceColumnOrder,
  serviceDefaultSorting,
} from "../../../../constants/Application/ApplicationDetailsServices";
import {
  processColumns,
  processDefaultColumnWidth,
  processTableColumnExtensions,
  processColumnOrder,
  processDefaultSorting,
} from "../../../../constants/Application/ApplicationDetailsProcess";
import { getColumnWidth } from "../../../../utils/common";
import useCheckPermission from "../../hooks/useCheckPermission";
import { AppButton } from "../../Button";
import AddIcon from "@material-ui/icons/Add";

const EditApplicationEntityHelper = ({
  section,
  isEditMode,
  applicationData,
  error,
  handleChange,
  userInfo,
  setSearchInput,
  handleSearch,
  rows,
  loading,
  services,
  applicationID,
  applicationName,
  templateList,
  currentPageProcessTable,
  setCurrentPageProcessTable,
  currentPageServiceTable,
  setCurrentPageServiceTable,
  windowDimensions,
  imagesList,
}) => {
  const styles = manageStyles();
  const classes = useStyles();
  const histroy = useHistory();
  const [page, setPage] = React.useState(1);
  const { checkCompPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  const handleSearchChange = (val) => {
    setSearchInput(val);
    setPage(1);
  };

  const applicationServiceListTable = () => {
    return (
      <>
        <DefaultPagination
          columns={serviceColumns}
          rows={
            services.length > 0
              ? services.slice(
                (currentPageServiceTable - 1) * 5,
                currentPageServiceTable * 5
              )
              : []
          }
          defaultColumnWidths={getColumnWidth(
            serviceDefaultColumnWidths,
            windowDimensions
          )}
          tableColumnExtensions={serviceTableColumnExtensions}
          columnOrder={serviceColumnOrder}
          defaultSorting={serviceDefaultSorting}
          totalCount={services ? services.length : 0}
          // height={300}
          noDataMsg="No Service/Version Found for Service Bundle! "
          // pageSize={5}
          isCustomPaging={true}
        />
        {services.length !== 0 && (
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(services.length / 5)}
              page={currentPageServiceTable}
              onChange={(e, val) => setCurrentPageServiceTable(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </>
    );
  };

  const applicationServiceVersionListTable = () => {
    if (checkCompPermission("Microservice", ["List All"])) {
      return (
        <>
          {isEditMode && (
            <div style={{ marginBottom: "4rem" }}>
              <div className={classes.searchwrapper}>
                <FilledInput
                  name="searchInput"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  className={classes.input}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="center">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSearch}
                        onMouseDown={handleSearch}
                      // style={{ paddingBottom: 10 }}
                      // edge="end"
                      >
                        <img src="/images/search.svg" alt="card" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                <ServiceVersionSelectTable
                  rows={rows}
                  page={page}
                  setPage={setPage}
                  loading={loading}
                  isEditMode={isEditMode}
                  applicationName={applicationName}
                />
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div>
          <text className={styles.fieldHeading} style={{ color: "red" }}>
            You donot have permission to view microservice list
          </text>
        </div>
      );
    }
  };

  const applicationDeploymentTable = () => {
    return (
      <div>
        <ApplicationDeploymentTable
          applicationID={applicationID}
          applicationName={applicationName}
          disabled={!isEditMode}
          imagesList={imagesList}
        />
      </div>
    );
  };

  const processListTable = () => {
    if (checkCompPermission("ServiceBundle", ["Manage Process"])) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 className={classes.heading}>{""}</h2>
            {isEditMode && (
              <AppButton
                color="primary"
                buttonName={`Create Aggregated API`}
                variant="contained"
                startIcon={<AddIcon />}
                disabled={
                  !checkCompPermission("ServiceBundle", ["Manage Process"])
                }
                className="btnsmall"
                onClick={() => {
                  if (isEditMode) {
                    if (
                      checkCompPermission("ServiceBundle", ["Manage Process"])
                    ) {
                      histroy.replace({
                        pathname: `orchestration/${applicationID}`,
                        state: {
                          source: "create_template",
                        },
                      });
                    }
                  } else {
                    enqueueSnackbar(
                      `You do not have permission to  create template. Please contact your Admin.`,
                      { variant: "error" }
                    );
                  }
                }}
              />
            )}
          </div>
          <DefaultPagination
            columns={processColumns}
            // rows={templateList}
            rows={
              templateList.length > 0
                ? templateList.slice(
                  (currentPageProcessTable - 1) * 5,
                  currentPageProcessTable * 5
                )
                : []
            }
            defaultColumnWidths={processDefaultColumnWidth}
            tableColumnExtensions={processTableColumnExtensions}
            columnOrder={processColumnOrder}
            defaultSorting={processDefaultSorting}
            totalCount={templateList.length}
            // height={300}
            noDataMsg="No Aggregated APIs Found! "
            pageSize={5}
            isCustomPaging={true}
          />
          {templateList.length !== 0 && (
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(templateList.length / 5)}
                page={currentPageProcessTable}
                onChange={(e, val) => setCurrentPageProcessTable(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          )}
        </>
      );
    } else {
      return (
        <div>
          <text className={styles.fieldHeading} style={{ color: "red" }}>
            You donot have permission to view Aggregated APIs list
          </text>
        </div>
      );
    }
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              value={
                field.dataType === "date" && applicationData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                      .unix(applicationData[field.datakey])
                      .tz(userInfo?.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                          : `${userInfo?.dateFormat} ${userInfo?.time}`
                      )
                    : moment
                      .unix(applicationData[field.datakey])
                      .format("DD/MM/YYYY, hh:mm:ss A")
                  : applicationData[field.datakey]
              }
              autoComplete="off"
              disabled={!isEditMode || field.editable === "FALSE"}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={applicationData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      name={field.datakey}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                      value={
                        field.dataType === "date" &&
                          applicationData[field.datakey]
                          ? userInfo?.timeZone
                            ? moment
                              .unix(applicationData[field.datakey])
                              .tz(userInfo?.timeZone)
                              .format(
                                userInfo.time === "h:mm"
                                  ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                                  : `${userInfo?.dateFormat} ${userInfo?.time}`
                              )
                            : moment
                              .unix(applicationData[field.datakey])
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : applicationData[field.datakey]
                      }
                      autoComplete="off"
                      disabled={!isEditMode || field.editable === "FALSE"}
                      onChange={handleChange}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  applicationData[`${field.datakey}_unit`]
                    ? applicationData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={applicationData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  applicationData[`${field.datakey}_unit`]
                    ? applicationData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={applicationData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                applicationData[field.datakey]
                  ? applicationData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={
                    <Radio
                      disabled={!isEditMode || field.editable === "FALSE"}
                      color="primary"
                    />
                  }
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Service_Bundle_Header section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Service_Bundle_ServiceVersionList_Table_Field":
                          return <>{applicationServiceVersionListTable()}</>;
                        case "Service_Bundle_ServiceList_Field":
                          return <>{applicationServiceListTable()}</>;
                        case "Service_Bundle_Deployment_Table_Field":
                          return <>{applicationDeploymentTable()}</>;
                        case "Service_Bundle_Process_List_Table_Field\n":
                          return <>{processListTable()}</>;
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Service_Bundle_ServiceVersionList_Table_Field":
                                return (
                                  <>{applicationServiceVersionListTable()}</>
                                );
                              case "Service_Bundle_ServiceList_Field":
                                return <>{applicationServiceListTable()}</>;
                              case "Service_Bundle_Deployment_Table_Field":
                                return <>{applicationDeploymentTable()}</>;
                              case "Service_Bundle_Process_List_Table_Field\n":
                                return <>{processListTable()}</>;
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(EditApplicationEntityHelper);
