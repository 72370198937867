import React from "react";
import { TextField } from "@material-ui/core";

const TextFieldCustom = (props) => {
  return (
    <TextField
      fullWidth
      name={props.name}
      variant="outlined"
      size="small"
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      autoComplete="off"
      InputProps={{
        style: {
          width: props.width,
          height: "38px",
          padding: "0px",
          background: "#F4F4F4 0% 0% no-repeat padding-box !important",
          outline: "none",
        },
      }}
    />
  );
};

export default TextFieldCustom;
