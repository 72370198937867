import React, { useEffect, useState } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { initError } from "../../../utils/common";
import { ArrowDropDownRounded } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import DeploymentProcessPreview from "../../../components/Deployment/Tables/DeploymentProcessPreview";
import DeploymentContainerTablePreview from "../../../components/Deployment/Tables/DeploymentContainerTablePreview";
import ClusterDeploymentDevExpTable from "../../DevExpressScreen/ClusterDeploymentDevExpTable";
import ExistingDeployments from "./ExistingDeployments";
import { addDeploymentName } from "../../../redux/actions/deploymentProcessAction";

const PreviewCluster = (props) => {
  const {
    configurationname,
    clustername,
    deployment_app_name,
    deploymentName,
    setDeploymentName,
    addDeploymentName,
  } = props;

  const styles = manageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [isProcess, setIsProcess] = useState();
  const [previewEntitySchema, setPreviewEntitySchema] = useState([]);
  const [previewData, setPreviewData] = useState({});
  const [error, setError] = useState();

  const handleDeploymentNameChange = (e) => {
    const value = e.target.value;
    setDeploymentName(() => value);
  };

  useEffect(() => {
    if (props.deployment_type === "Application") {
      setIsProcess(true);
    } else {
      setIsProcess(false);
    }
    setDeploymentName(deployment_app_name?.imagename);
  }, []);

  useEffect(() => {
    const fetchHostingEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Cluster",
        "Create"
      );

      if (_status === 200) {
        setIsLoader(false);
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setPreviewEntitySchema(entity);
        const servData = {};

        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
              if (field.datakey === "upload_type") {
                if (field.value === "Upload") {
                  setUploadType(true);
                } else {
                  setUploadType(false);
                }
              }
            });
          });
        });

        setPreviewData((prevData) => ({ ...prevData, ...servData }));

        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });

        setError(errData);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchHostingEntitySchema();
  }, []);

  return (
    <main>
      {isLoader && <Loader />}
      <div style={{ marginTop: "20px" }}>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid xs={3}>
            <label htmlFor="" className={styles.fieldHeading}>
              Deployment Name
            </label>
            <input
              value={deploymentName}
              onChange={handleDeploymentNameChange}
              className={`${styles.input}`}
            />
          </Grid>
          <Grid xs={3}>
            <label htmlFor="" className={styles.fieldHeading}>
              Cloud Configuration
            </label>
            <input
              disabled
              value={
                configurationname?.configurationname
                  ? configurationname?.configurationname
                  : ""
              }
              className={`${styles.input2}`}
            />
          </Grid>

          <Grid xs={4}>
            <div style={{ paddingLeft: 25 }}>
              <label htmlFor="" className={styles.fieldHeading}>
                Description
              </label>
              <textarea
                // name={field.datakey}
                // value={testExecutionData[field.datakey]}
                // onChange={handleChange}
                className={`${styles.textarea}`}
                rows="7"
                // disabled={true}
              ></textarea>
            </div>
          </Grid>
          <Grid xs={3}>
            <div>
              <label htmlFor="" className={styles.fieldHeading}>
                Cluster Name
              </label>
              <input
                disabled
                value={props.clustername ? props.clustername : ""}
                className={`${styles.input2}`}
              />
            </div>
          </Grid>
        </Grid>

        <Accordion className={styles.fieldPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownRounded className={styles.accordianIcon} />
            }
          >
            <Typography className={styles.sectionHeading}>
              To Be Deployed
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DeploymentContainerTablePreview />
          </AccordionDetails>
        </Accordion>

        <Accordion className={styles.fieldPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownRounded className={styles.accordianIcon} />
            }
          >
            <Typography className={styles.sectionHeading}>
              Existing Deployments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExistingDeployments
              clusterName={clustername}
              configName={configurationname}
            />
          </AccordionDetails>
        </Accordion>
        {/* {isProcess ? (
          <Accordion className={styles.fieldPanel} defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRounded className={styles.accordianIcon} />
              }
            >
              <Typography className={styles.sectionHeading}>Process</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DeploymentProcessPreview />
            </AccordionDetails>
          </Accordion>
        ) : (
          <> </>
        )} */}
      </div>
    </main>
  );
};

const mapDispatchToProps = { addDeploymentName };

const mapStateToProps = (state) => {
  const { deployment_type, deployment_app_name } =
    state.deploymentProcessReducer.appSelectionData;
  const { clustername, configurationname } =
    state.deploymentProcessReducer.hostingData;

  return {
    deployment_type,
    clustername,
    configurationname,
    deployment_app_name,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCluster);
