import React from "react";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import useGetData from "../../../../hooks/useGetData";

function ImageAppearances() {
  const { currentProject, currentPage, currentComponent } = useGetData();
  const dispatch = useDispatch();
  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: value ? value : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>{"Height (px)"}</label>
        <input
          type="number"
          name={"imgHeight"}
          value={currentComponent?.imgHeight}
          autoComplete="off"
          onChange={(e) => handleChange("imgHeight", e.target.value)}
        />
      </div>
      <div className="field">
        <label>Width (px)</label>
        <input
          type="number"
          name={"imgWidth"}
          value={currentComponent?.imgWidth}
          autoComplete="off"
          onChange={(e) => handleChange("imgWidth", e.target.value)}
        />
      </div>
    </div>
  );
}

export default ImageAppearances;
