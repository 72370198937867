import {
  ADD_SERVICE_BUNDLE_LIST,
  SERVICE_BUNDLE_FILTERS,
  SERVICE_BUNDLE_SORT,
  ADD_SERVICE_BUNDLE_SERVICE_LIST,
  ADD_APP_BUNDLE_DETAILS,
  ADD_SERVICE_VERSION_FOR_APP_BUNDLE,
  ADD_SERVICE_LIST_DATA_FOR_APP_BUNDLE,
  ADD_SERVICE_TO_CREATE_APP_BUNDLE,
  ADD_SERVICEBUNDLE_TO_APPLICATION,
  ADD_APP_BUNDLE_ALL_DETAILS,
  ADD_UIPAGES_LIST_DATA,
  CLEAR_APP_BUNDLE_DATA,
  ADD_APP_BUNDLE_LIST,
  ADD_UI_APPLICATION_LIST_DATA,
  ADD_UI_APPLICATION_TO_CREATE_APP_BUNDLE,
  DELETE_APP_BUNDLE_BY_ID,
  ADD_AI_TASK_TO_CREATE_APP_BUNDLE,
  ADD_AI_TASK_LIST_DATA_FOR_APP_BUNDLE,
  ADD_AI_TASK_VERSION_FOR_APP_BUNDLE,
  APPLICATION_BUNDLE_HIDDEN
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  appBundleList: {
    data: [],
    error: false,
    loading: false,
  },
  appBundleDetails: { name: "", description: "" },
  serviceBundleList: {
    data: [],
    error: false,
    loading: false,
  },
  serviceBundleServiceList: {
    data: [],
    error: false,
    loading: false,
  },
  serviceBundleFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  serviceBundleSort: [{ columnName: "createdon", direction: "desc" }],
  serviceBundleHidden:["description","updatedby","updatedon"],
  services_list: {
    data: [],
    error: false,
    loading: false,
  },
  ai_task_list: {
    data: [],
    error: false,
    loading: false,
  },
  selectedServices: [],
  selectedAiTask: [],
  selectedUIApplication: [],
  backendApplication: [],
  uiApplication_list: {
    data: [],
    error: false,
    loading: false,
  },
};

const serviceBundleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APP_BUNDLE_LIST: {
      return {
        ...state,
        appBundleList: {
          ...state.appBundleList,
          ...action.payload,
        },
      };
    }
    
    case APPLICATION_BUNDLE_HIDDEN: {
      return {
          ...state,
          serviceBundleHidden: [
              ...action.payload
          ]
      }
  }

    case ADD_SERVICE_BUNDLE_SERVICE_LIST: {
      return {
        ...state,
        serviceBundleServiceList: {
          ...state.serviceBundleServiceList,
          data: action.payload,
        },
      };
    }

    // case ADD_SERVICEBUNDLE_TO_APPLICATION:
    //   return {
    //     // returning a copy of orignal state
    //     ...state, //copying the original state
    //     backendApplication: [...state.backendApplication, action.payload], //new todos array
    //   };

    case ADD_SERVICE_BUNDLE_LIST: {
      return {
        ...state,
        serviceBundleList: {
          ...state.serviceBundleList,
          ...action.payload,
        },
      };
    }
    case DELETE_APP_BUNDLE_BY_ID: {
      return {
        ...state,
        appBundleList: {
          ...state.appBundleList,
          data: state.appBundleList.data.filter(
            (serv) => serv._id !== action.payload.AppBundleId
          ),
          _totalcount: state.appBundleList._totalcount - 1,
        },
      };
    }

    case SERVICE_BUNDLE_FILTERS: {
      return {
        ...state,
        serviceBundleFilters: [...action.payload],
      };
    }

    case SERVICE_BUNDLE_SORT: {
      return {
        ...state,
        serviceBundleSort: [...action.payload],
      };
    }

    case ADD_APP_BUNDLE_DETAILS: {
      return {
        ...state,
        appBundleDetails: {
          ...state.appBundleDetails,
          [action.payload.target.name]: action.payload.target.value,
        },
      };
    }

    case ADD_SERVICE_VERSION_FOR_APP_BUNDLE: {
      const { data, serviceID } = action.payload;
      const new_service_list = state.services_list.data.map((item, index) => {
        if (item._id === serviceID) {
          return {
            ...item,
            versions: data,
          };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        services_list: {
          ...state.services_list,
          data: new_service_list,
        },
      };
    }

    case ADD_AI_TASK_VERSION_FOR_APP_BUNDLE: {
      const { data, serviceID } = action.payload;
      const new_service_list = state.ai_task_list.data.map((item, index) => {
        if (item._id === serviceID) {
          return {
            ...item,
            versions: data,
          };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        ai_task_list: {
          ...state.ai_task_list,
          data: new_service_list,
        },
      };
    }

    case ADD_SERVICE_LIST_DATA_FOR_APP_BUNDLE: {
      return {
        ...state,
        services_list: {
          ...state.services_list,
          ...action.payload,
        },
      };
    }

    case ADD_AI_TASK_LIST_DATA_FOR_APP_BUNDLE: {
      return {
        ...state,
        ai_task_list: {
          ...state.ai_task_list,
          ...action.payload,
        },
      };
    }

    case ADD_SERVICE_TO_CREATE_APP_BUNDLE: {
      const data = action.payload.data;
      const { serviceID, versionID, checked } = data;
      if (!checked) {
        var new_state = [];
        state.selectedServices.forEach((item) => {
          if (item.serviceID !== serviceID) {
            new_state.push(item);
          }
        });
        return {
          ...state,
          selectedServices: new_state,
        };
      } else if (checked) {
        return {
          ...state,
          selectedServices: [
            ...state.selectedServices,
            {
              serviceID,
              versionID,
            },
          ],
        };
      }
      return {
        ...state,
        selectedServices: [...state.selectedServices, data],
      };
    }

    case ADD_AI_TASK_TO_CREATE_APP_BUNDLE: {
      const data = action.payload.data;
      const { serviceID, versionID, checked } = data;
      if (!checked) {
        var new_state = [];
        state.selectedAiTask.forEach((item) => {
          if (item.serviceID !== serviceID) {
            new_state.push(item);
          }
        });
        return {
          ...state,
          selectedAiTask: new_state,
        };
      } else if (checked) {
        return {
          ...state,
          selectedAiTask: [
            ...state.selectedAiTask,
            {
              serviceID,
              versionID,
            },
          ],
        };
      }
      return {
        ...state,
        selectedAiTask: [...state.selectedAiTask, data],
      };
    }

    case ADD_UI_APPLICATION_TO_CREATE_APP_BUNDLE: {
      const data = action.payload.data;
      const { _id, checked } = data;
      if (!checked) {
        var new_state = [];
        state.selectedUIApplication.forEach((item) => {
          if (item._id !== _id) {
            new_state.push(item);
          }
        });
        return {
          ...state,
          selectedUIApplication: new_state,
        };
      } else if (checked) {
        return {
          ...state,
          selectedUIApplication: [
            ...state.selectedUIApplication,
            {
              _id,
            },
          ],
        };
      }
      return {
        ...state,
        selectedUIApplication: [...state.selectedUIApplication, data],
      };
    }

    case ADD_SERVICEBUNDLE_TO_APPLICATION: {
      const data = action.payload.data;
      const { applicationId, name, checked } = data;

      if (!checked) {
        var new_state = [];

        state.backendApplication.forEach((item) => {
          if (item.applicationId !== applicationId) {
            new_state.push(item);
          }
        });
        return {
          ...state,
          backendApplication: new_state,
        };
      } else if (checked) {
        return {
          ...state,
          backendApplication: [
            ...state.backendApplication,
            {
              applicationId,
              name,
            },
          ],
        };
      }
      return {
        ...state,
        backendApplication: [...state.backendApplication, data],
      };
    }

    case ADD_APP_BUNDLE_ALL_DETAILS: {
      const { name, description, services, _id, uiApplications, aiTask,backendApplication} =
        action.payload;
      state.appBundleDetails = { ...action.payload };
      state.selectedServices = services ? services : [];
      state.selectedUIApplication = uiApplications ? uiApplications : [];
      state.selectedAiTask = aiTask ? aiTask : [];
      state.backendApplication= backendApplication ? backendApplication :[];

      return {
        ...state,
      };
    }

    case ADD_UI_APPLICATION_LIST_DATA: {
      return {
        ...state,
        uiApplication_list: {
          ...state.uiApplication_list,
          ...action.payload,
        },
      };
    }

    case CLEAR_APP_BUNDLE_DATA: {
      return {
        ...state,
        appBundleDetails: { name: "", description: "" },
        selectedServices: [],
        backendApplication: [],
      };
    }

    default:
      return state;
  }
};

export default serviceBundleReducer;
