import axios from "axios";
import jwt_decode from "jwt-decode";
import { USER_RESET } from "../../../constants/UserProfile/user";
import { addAccessToken, addAuthData } from "../../../redux/actions/authAction";
import { deleteVisitedRoute } from "../../../redux/actions/recentlyVisitedAction";
import { addUserNotification } from "../../../redux/actions/userPersonalAction";
import { store } from "../../../redux/store";
import { BASE_URL } from "../../../services/helper/config";

export const CheckJwtExpiry = (accesstoken) => {
  if (accesstoken) {
    const decryptDataAccess = jwt_decode(accesstoken);

    const currentDateTime = Math.round(new Date().getTime() / 1000);
    if (currentDateTime < decryptDataAccess?.exp) {
      return true;
    } else {
      store.dispatch(deleteVisitedRoute());
      store.dispatch(addUserNotification({}));
      store.dispatch(
        addAuthData({
          accesstoken: "",
          refreshtoken: "",
          userid: "",
          token_expired_msg: accesstoken ? "Session expired. Please Login again to continue." : ""
        })
      );

      store.dispatch({ type: USER_RESET });
      return false;
    }
  } else {
    store.dispatch(
      addAuthData({
        accesstoken: "",
        refreshtoken: "",
        userid: "",
        token_expired_msg: ""
      })
    );
    return false;
  }
};
