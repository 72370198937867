import { StatusProfiles } from "../../services/statusProfiles";
const statusProfileService = new StatusProfiles();

export const fetchStatusResourceById = async (resourceId) => {
    try {
        const res_data = await statusProfileService.fetchStatusResourceByID(resourceId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const fetchStatusResourceByName = async (resourceName) => {
    try {
        const res_data = await statusProfileService.fetchStatusResourceByName(resourceName);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const fetchDependencies = async (name, body) => {
    try {
        const res_data = await statusProfileService.getDependency(name, body);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};
