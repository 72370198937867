/* eslint-disable */
import {
  Paper,
  Divider,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { AppButton } from "../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import {
  getServicesList,
  addServiceFilters,
  addServiceSort,
  addServiceHidden,
  clearServices,
} from "../../redux/actions/serviceAction";
import {
  addServiceGUISort,
  addServiceGUIHidden,
  addServiceGUIFilters,
  getServiceGUIList,
} from "../../redux/actions/serviceGUIAction";
import { connect } from "react-redux";
import DevExpressTable from "../../components/common/Tables/DevExpressTable";
import {
  serviceColumnHidden,
  serviceColumnOrder,
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
} from "../../constants/servicesConstant";
import {
  serviceGUIColumnHidden,
  serviceGUIColumnOrder,
  serviceGUIColumns,
  serviceGUIDefaultColumnWidths,
  serviceGUITableColumnExtensions,
} from "../../constants/GUIConstants";
import CustomFilter from "../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../utils/common";
import { useRef } from "react";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import {
  fetchAllFieldGroups,
  setFieldGroupSort,
  addFieldGroupFilters,
  addFieldGroupHidden,
} from "../../redux/actions/dataStandardAction";
import {
  fieldGroupsColumnHidden,
  fieldGroupsColumnOrder,
  fieldGroupsColumns,
  fieldGroupsDefaultColumnWidths,
  fieldGroupsDefaultSorting,
  fieldGroupsTableColumnExtensions,
} from "../../constants/DataStandard/FieldGroups";
import ApplicationListTable from "../../components/common/Tables/ApplicationListTable";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import SearchBar from "../../components/common/SearchBar";

const ServicesList = (props) => {
  const {
    data,
    getServicesList,
    getServiceGUIList,
    loading,
    _totalcount,
    _totalcountGUI,
    addServiceFilters,
    addServiceGUIFilters,
    addFieldGroupFilters,
    serviceFilters,
    serviceGUIFilters,
    serviceSort,
    serviceGUISort,
    addServiceSort,
    addServiceGUISort,
    serviceHidden,
    serviceGUIHidden,
    addServiceHidden,
    addServiceGUIHidden,
    serviceActiveTab,
    alterActiveTab,
    fieldGroups,
    fieldGroupFilters,
    fetchAllFieldGroups,
    GUIdata,
    fieldGroupSort,
    setFieldGroupSort,
    clearServices,
    fieldGroupHidden,
    addFieldGroupHidden,
    deleteServiceStatus,
    deleteServiceGUIStatus,
    deleteFieldGroupStatus,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [currentPageService, setCurrentPageService] = useState(1);
  const [currentPageServiceGUI, setCurrentPageServiceGUI] = useState(1);

  const [currentPageFieldGroup, setCurrentPageFieldGroup] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [servicesRows, setServicesRows] = useState([]);
  const [servicesGUIRows, setServicesGUIRows] = useState([]);

  const [fieldgroupRows, setFieldgroupRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [uiFavouriteClick, setUiFavouriteClick] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [tableLoadingFieldGroup, setTableLoadingfieldGroup] = useState(true);
  const [tableData, setTableData] = useState({
    columns: serviceColumns,
    widths: serviceDefaultColumnWidths,
    extentions: serviceTableColumnExtensions,
    orders: serviceColumnOrder,
    hides: [],
  });
  const [GUItableData, setGUItableData] = useState({
    columns: serviceGUIColumns,
    widths: serviceGUIDefaultColumnWidths,
    extentions: serviceGUITableColumnExtensions,
    orders: serviceGUIColumnOrder,
    hides: [],
  });
  const [fieldGrouptableData, setFieldGroupTableData] = useState({
    columns: fieldGroupsColumns,
    widths: fieldGroupsDefaultColumnWidths,
    extentions: fieldGroupsTableColumnExtensions,
    orders: fieldGroupsColumnOrder,
    hides: [],
  });

  const [currentFavouriteServicesPage, setCurrentFavouriteServicesPage] = useState(1);
  const [currentFavouriteServicesGUIPage, setCurrentFavouriteServicesGUiPage] = useState(1);


  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (serviceActiveTab === "services") {
      checkScreenPermission("Microservice", ["List All", "List Assigned"]);
    } else if (serviceActiveTab === "uiSimulator") {
      checkScreenPermission("Microservice", ["List All", "List Assigned"]);
    } else if (serviceActiveTab === "fieldgroups") {
      checkScreenPermission("GlossaryFieldGroup", ["List All", "List Assigned"]);
    }
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Microservice");
      if (_status === 200) {
        let servData = getTableData(
          data,
          serviceColumns,
          serviceDefaultColumnWidths,
          serviceTableColumnExtensions,
          serviceColumnOrder,
          serviceHidden,
          "service"
        );

        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        setTableData(servData);
        setGUItableData(servData);
        if (
          JSON.stringify(serviceHidden) === JSON.stringify(serviceColumnHidden)
        ) {
          addServiceHidden(servData.hides);
          addServiceGUIHidden(servData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchGUIFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Microservice");
      if (_status === 200) {
        let servData = getTableData(
          data,
          serviceGUIColumns,
          serviceGUIDefaultColumnWidths,
          serviceGUITableColumnExtensions,
          serviceGUIColumnOrder,
          serviceGUIHidden,
          "uisimul"
        );

        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        // setTableData(servData);
        // setGUItableData(servData);

        if (
          JSON.stringify(serviceGUIHidden) ===
          JSON.stringify(serviceGUIColumnHidden)
        ) {
          // addServiceHidden(servData.hides);
          addServiceGUIHidden(servData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchFieldGroupFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "GlossaryFieldGroup"
      );
      if (_status === 200) {
        let fieldGData = getTableData(
          data,
          fieldGroupsColumns,
          fieldGroupsDefaultColumnWidths,
          fieldGroupsTableColumnExtensions,
          fieldGroupsColumnOrder,
          fieldGroupHidden,
          "fieldgroup"
        );
        setFieldGroupTableData(fieldGData);
        if (
          JSON.stringify(fieldGroupHidden) ===
          JSON.stringify(fieldGroupsColumnHidden)
        ) {
          addFieldGroupHidden(fieldGData.hides);
        }
        setTableLoadingfieldGroup(false);
      } else {
        setTableLoadingfieldGroup(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchFields();
    // fetchGUIFields();

    fetchFieldGroupFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStatusesByResource = async () => {
    const { _msg, _status, data } = await fetchStatusResourceByName(
      "Microservice"
    );
    if (_status === 200) {
      setStatuses(data ? data.status : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  function createData(serviceData) {
    const {
      _id,
      name,
      status,
      createdon,
      isFavourite,
      createdby,
      isSubscribe,
    } = serviceData;
    return {
      ...serviceData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      status: status,
      api: 0,
      isFavourite: isFavourite,
      isSubscribe: isSubscribe,
      statuses: statuses,
    };
  }

  useEffect(async () => {
    const parsedFilters = arrayToFilter(serviceFilters);
    const parsedSort = arrayToSort(serviceSort);
    if (checkCompPermission("StatusProfile", ["List All"])) {
      await fetchStatusesByResource();
    }
    getServicesList(
      currentPageService,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageService, serviceSort]);

  useEffect(async () => {
    if(deleteServiceStatus === true) {
      const parsedFilters = arrayToFilter(serviceFilters);
      const parsedSort = arrayToSort(serviceSort);
      if (checkCompPermission("StatusProfile", ["List All"])) {
        await fetchStatusesByResource();
      }
      getServicesList(
        currentPageService,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteServiceStatus]);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(serviceGUIFilters);
    const parsedSort = arrayToSort(serviceSort);
    if (checkCompPermission("StatusProfile", ["List All"])) {
      await fetchStatusesByResource();
    }
    getServiceGUIList(
      currentPageServiceGUI,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageServiceGUI, serviceSort]);

  useEffect(async () => {
    if(deleteServiceGUIStatus === true){
      const parsedFilters = arrayToFilter(serviceGUIFilters);
      const parsedSort = arrayToSort(serviceSort);
      if (checkCompPermission("StatusProfile", ["List All"])) {
        await fetchStatusesByResource();
      }
      getServiceGUIList(
        currentPageServiceGUI,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteServiceGUIStatus]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldGroupFilters);
    const parsedSort = arrayToSort(fieldGroupSort);
    fetchAllFieldGroups(
      currentPageFieldGroup,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageFieldGroup, fieldGroupSort]);

  useEffect(() => {
    if(deleteFieldGroupStatus === true) {  
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(
        currentPageFieldGroup,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteFieldGroupStatus]);

  const styles = listStyles();

  const histroy = useHistory();

  useEffect(() => {
    if (!isFavouriteClick) {
      setServicesRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setServicesRows(
        data?.data && data?.data?.length > 0
          ? data?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    if (!uiFavouriteClick) {
      setServicesGUIRows(
        GUIdata?.data && GUIdata?.data?.length > 0
          ? GUIdata?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setServicesGUIRows(
        GUIdata?.data && GUIdata?.data?.length > 0
          ? GUIdata?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [GUIdata]);

  useEffect(() => {
    setFieldgroupRows(
      fieldGroups?.data?.data && fieldGroups?.data?.data?.length > 0
        ? fieldGroups?.data?.data?.map((item) => ({
          ...item,
          createdon: item.createdon,
          updatedon: item.updatedon,
          updatedby: item.updatedby,
        }))
        : []
    );
  }, [fieldGroups]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (serviceActiveTab === "services") {
      if (searchInput) {
        getServicesList(
          1,
          JSON.stringify({
            ai_task: { $ne: true },
            service_category: { $ne: "Only GUI" },
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getServicesList(1);
      }
    } else if (serviceActiveTab === "uiSimulator") {
      if (searchInput) {
        getServiceGUIList(
          1,
          JSON.stringify({
            ai_task: { $ne: true },
            service_category: { $ne: "Only API" },
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getServiceGUIList(1);
      }
    } else if (serviceActiveTab === "fieldgroups") {
      if (searchInput) {
        fetchAllFieldGroups(
          1,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        fetchAllFieldGroups(1);
      }
    }
  };

  const handleSubmitFilter = () => {
    if (serviceActiveTab === "services") {
      const parsedFilters = arrayToFilter([
        ...serviceFilters,
        { column: "service_category", value: "Only GUI", operator: "noteq" },
        { column: "ai_task", value: true, operator: "noteq" },
      ]);
      const parsedSort = arrayToSort(serviceSort);
      getServicesList(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (serviceActiveTab === "uiSimulator") {
      const parsedFilters = arrayToFilter([
        ...serviceGUIFilters,
        { column: "service_category", value: "Only API", operator: "noteq" },
        { id: "tempId2", column: "ai_task", value: true, operator: "noteq" },
      ]);
      const parsedSort = arrayToSort(serviceGUISort);
      getServiceGUIList(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (serviceActiveTab === "fieldgroups") {
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFilters = () => {
    if (serviceActiveTab === "services") {
      getServicesList(1, JSON.stringify({}));
    } else if (serviceActiveTab === "uiSimulator") {
      getServiceGUIList(1, JSON.stringify({}));
    } else if (serviceActiveTab === "fieldgroups") {
      fetchAllFieldGroups(1, JSON.stringify({}));
    }
  };

  const handleToggleFavouriteService = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(serviceFilters);
      const parsedSort = arrayToSort(serviceSort);
      getServicesList(
        currentPageService,
        parsedFilters,
        JSON.stringify(parsedSort),
        10
      );
      setServicesRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPageService(1);
    } else {
      const parsedFilters = arrayToFilter(serviceFilters);
      const parsedSort = arrayToSort(serviceSort);
      getServicesList(undefined, parsedFilters, JSON.stringify(parsedSort));
      setServicesRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavouriteServicesPage(1);
    }
  };
  const handleToggleUiFavouriteService = () => {
    if (uiFavouriteClick) {
      const parsedFilters = arrayToFilter(serviceGUIFilters);
      const parsedSort = arrayToSort(serviceSort);
      getServiceGUIList(
        currentPageServiceGUI,
        parsedFilters,
        JSON.stringify(parsedSort)
      );

      setServicesGUIRows(
        GUIdata?.data && GUIdata?.data.length > 0
          ? GUIdata?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );

      setUiFavouriteClick(false);
      setCurrentPageServiceGUI(1)
    } else {
      getServiceGUIList();
      setServicesGUIRows((prev) => prev.filter((r) => r.isFavourite === true));
      setUiFavouriteClick(true);
      setCurrentFavouriteServicesGUiPage(1);
    }
  };

  const handleChangeTab = (event, newValue) => {
    alterActiveTab("serviceActiveTab", newValue);
  };

  useEffect(() => {
    return () => {
      clearServices();
    };
  }, []);

  const activeTabBredCrumbsName = () => {
    if (serviceActiveTab === "services") {
      return "Service List";
    } else if (serviceActiveTab === "uiSimulator") {
      return "UI Services List";
    } else if (serviceActiveTab === "fieldgroups") {
      return "Payload List";
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Service", path: "/services?service=true" },
              { title: activeTabBredCrumbsName() },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <Tabs
                value={serviceActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
              >
                <Tab
                  label={`Services ${_totalcount ? ` (${_totalcount})` : ""}`}
                  value={"services"}
                  disabled={
                    !checkCompPermission("Microservice", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />

                {/* <Tab
                  label={`UI Simulator ${_totalcount ? ` (${_totalcount})` : ""}`}
                  value={"uiSimulator"}
                  disabled={!checkCompPermission('Microservice', ['List All', 'List Assigned'])}
                /> */}
                <Tab
                  label={`UI Services ${_totalcountGUI ? ` (${_totalcountGUI})` : ""
                    }`}
                  value={"uiSimulator"}
                  disabled={
                    !checkCompPermission("Microservice", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />
                <Tab
                  label={`Payloads ${fieldGroups?.data?._totalcount
                    ? ` (${fieldGroups?.data?._totalcount})`
                    : ""
                    }`}
                  value={"fieldgroups"}
                  disabled={
                    !checkCompPermission("GlossaryFieldGroup", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />
              </Tabs>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                PlaceHolderLabel={
                  serviceActiveTab === "fieldgroups"
                    ? "Search Payload Name"
                    : "Search Service Name"
                }
                handleSearch={handleSearch}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              {serviceActiveTab === "services" && (
                <CustomFilter
                  columns={tableData.columns}
                  filters={serviceFilters}
                  setFilters={addServiceFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {serviceActiveTab === "uiSimulator" && (
                <CustomFilter
                  columns={GUItableData.columns}
                  filters={serviceGUIFilters}
                  setFilters={addServiceGUIFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {serviceActiveTab === "fieldgroups" && (
                <CustomFilter
                  columns={fieldGroupsColumns}
                  filters={fieldGroupFilters}
                  setFilters={addFieldGroupFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {serviceActiveTab === "services" && (
                <>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />

                  <Tooltip title="Favourite" arrow>
                    <IconButton
                      color="inherit"
                      aria-label="open cardview"
                      edge="start"
                      style={{ margin: "0px 10px" }}
                      onClick={handleToggleFavouriteService}
                    >
                      {isFavouriteClick ? (
                        <img src="/images/heartFilled.svg" alt="card" />
                      ) : (
                        <img src="/images/heartLine.svg" alt="card" />
                      )}
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <Divider orientation="vertical" className={styles.MuiDivider} />

              {serviceActiveTab === "services" && (
                <AppButton
                  buttonName="create"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  disabled={
                    !checkCompPermission("ServiceGroup", ["Create Microservice"])
                  }
                  className="btnsmall"
                  onClick={() => {
                    histroy.push("/services/add", {
                      data: "services",
                    });
                  }}
                />
              )}

              {serviceActiveTab === "uiSimulator" && (
                <>
                  <>
                    <Tooltip title="Favourite" arrow>
                      <IconButton
                        color="inherit"
                        aria-label="open cardview"
                        edge="start"
                        style={{ margin: "0px 10px" }}
                        onClick={handleToggleUiFavouriteService}
                      >
                        {uiFavouriteClick ? (
                          <img src="/images/heartFilled.svg" alt="card" />
                        ) : (
                          <img src="/images/heartLine.svg" alt="card" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Divider
                      orientation="vertical"
                      className={styles.MuiDivider}
                    />
                  </>
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={
                      <AddIcon style={{ width: "16px", height: "16px" }} />
                    }
                    disabled={
                      !checkCompPermission("ServiceGroup", [
                        "Create Microservice",
                      ])
                    }
                    className="btnsmall"
                    onClick={() => {
                      histroy.push("/services/add", {
                        data: "uiSimulator",
                      });
                    }}
                  />
                </>
              )}

              {serviceActiveTab === "fieldgroups" && (
                <AppButton
                  buttonName="create"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  disabled={
                    !checkCompPermission("GlossaryFieldGroup", ["Create"])
                  }
                  className="btnsmall"
                  onClick={() => {
                    histroy.push("/fieldgroups/add?service=true");
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {serviceActiveTab === "services" && (
            <>
              {tableLoading ? (
                <Loader />
              ) : (
                <DevExpressTable
                  columns={tableData.columns}
                  rows={isFavouriteClick ? (
                    servicesRows?.length > 0
                      ? servicesRows?.slice(
                        (currentFavouriteServicesPage - 1) * 10,
                        currentFavouriteServicesPage * 10
                      )
                      : []
                  ) : servicesRows}
                  defaultColumnWidths={tableData.widths}
                  tableColumnExtensions={tableData.extentions}
                  columnOrder={tableData.orders}
                  loading={loading}
                  currentPage={isFavouriteClick ? currentFavouriteServicesPage : currentPageService}
                  setCurrentPage={setCurrentPageService}
                  isCustomHeight={true}
                  sort={serviceSort}
                  windowDimensions={windowDimensions}
                  setSort={addServiceSort}
                  hidden={serviceHidden}
                  setHidden={addServiceHidden}
                  noDataMsg="No Services Found"
                />
              )}
            </>
          )}
          {serviceActiveTab === "uiSimulator" && (
            <>
              {tableLoading ? (
                <Loader />
              ) : (
                <DevExpressTable
                  columns={GUItableData.columns}
                  rows={uiFavouriteClick ? (
                    servicesGUIRows?.length > 0
                      ? servicesGUIRows?.slice(
                        (currentFavouriteServicesGUIPage - 1) * 10,
                        currentFavouriteServicesGUIPage * 10
                      )
                      : []
                  ) : (servicesGUIRows)}
                  defaultColumnWidths={GUItableData.widths}
                  tableColumnExtensions={GUItableData.extentions}
                  columnOrder={GUItableData.orders}
                  loading={loading}
                  currentPage={uiFavouriteClick ? currentFavouriteServicesGUIPage : currentPageServiceGUI}
                  setCurrentPage={setCurrentPageServiceGUI}
                  isCustomHeight={true}
                  sort={serviceGUISort}
                  windowDimensions={windowDimensions}
                  setSort={addServiceGUISort}
                  hidden={serviceGUIHidden}
                  setHidden={addServiceGUIHidden}
                  noDataMsg="No UI Services Found"
                />
              )}
            </>
          )}
          {serviceActiveTab === "fieldgroups" && (
            <>
              {tableLoadingFieldGroup ? (
                <Loader />
              ) : (
                <ApplicationListTable
                  columns={fieldGrouptableData.columns}
                  rows={fieldgroupRows}
                  defaultColumnWidths={fieldGrouptableData.widths}
                  tableColumnExtensions={fieldGrouptableData.extentions}
                  columnOrder={fieldGrouptableData.orders}
                  defaultSorting={fieldGroupsDefaultSorting}
                  sort={fieldGroupSort}
                  setSort={setFieldGroupSort}
                  isCustomHeight={true}
                  hidden={fieldGroupHidden}
                  setHidden={addFieldGroupHidden}
                />
              )}
            </>
          )}
        </Paper>
        {servicesRows.length !== 0 && serviceActiveTab === "services" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {isFavouriteClick ?
                <Pagination
                  count={Math.ceil(servicesRows.length / 10)}
                  page={currentFavouriteServicesPage}
                  onChange={(e, val) => setCurrentFavouriteServicesPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
                :
                <Pagination
                  count={Math.ceil(_totalcount / 10)}
                  page={currentPageService}
                  onChange={(e, val) => setCurrentPageService(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              }
            </div>
          </div>
        )}
        {servicesGUIRows.length !== 0 && serviceActiveTab === "uiSimulator" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {uiFavouriteClick ?
                <Pagination
                  count={Math.ceil(servicesGUIRows.length / 10)}
                  page={currentFavouriteServicesGUIPage}
                  onChange={(e, val) => setCurrentFavouriteServicesGUiPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
                :
                <Pagination
                  count={Math.ceil(_totalcountGUI / 10)}
                  page={currentPageServiceGUI}
                  onChange={(e, val) => setCurrentPageServiceGUI(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              }
            </div>
          </div>
        )}

        {fieldgroupRows.length !== 0 && serviceActiveTab === "fieldgroups" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={
                  isFavouriteClick
                    ? Math.ceil(fieldgroupRows.length / 10)
                    : Math.ceil(fieldGroups?.data?._totalcount / 10)
                }
                page={currentPageFieldGroup}
                onChange={(e, val) => setCurrentPageFieldGroup(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  getServicesList,
  getServiceGUIList,
  addServiceFilters,
  addServiceGUIFilters,
  addServiceSort,
  addServiceGUISort,
  alterActiveTab,
  addServiceHidden,
  addServiceGUIHidden,
  addFieldGroupFilters,
  fetchAllFieldGroups,
  addFieldGroupHidden,
  setFieldGroupSort,
  clearServices,
};

const mapStateToProps = (state) => {
  const { servicesList, serviceFilters, serviceSort, serviceHidden } =
    state.serviceReducer;
  const { GUIdata, deleteServiceGUIStatus } = state.serviceGUIReducer.servicesGUIList;
  const { serviceGUISort, serviceGUIHidden } = state.serviceGUIReducer;
  const { userInfo } = state.userDetails;
  const { data, error, loading, deleteServiceStatus } = servicesList;
  const { serviceActiveTab } = state.activeTabReducer;
  const { fieldGroups, fieldGroupFilters, fieldGroupSort, fieldGroupHidden } =
    state.dataStandardReducer;
  const { deleteFieldGroupStatus } = fieldGroups;
  return {
    data,
    GUIdata,
    error,
    loading,
    serviceFilters,
    serviceGUIFilters: state.serviceGUIReducer.serviceGUIFilters,
    serviceSort,
    serviceGUISort,
    _totalcount: servicesList?._totalcount,
    _totalcountGUI: state.serviceGUIReducer.servicesGUIList?._totalcount,
    userInfo,
    serviceHidden,
    serviceActiveTab,
    fieldGroups,
    fieldGroupFilters,
    fieldGroupSort,
    fieldGroupHidden,
    serviceGUIHidden,
    deleteServiceStatus,
    deleteServiceGUIStatus,
    deleteFieldGroupStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
