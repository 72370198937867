import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
import { fetchFieldByID_ } from "../../../utils/ontology/ontologyUtils";
import { fetchFieldGroupByID_ } from "../../../utils/ontology/ontologyUtils";
import { fetchSectionByID_ } from "../../../utils/ontology/ontologyUtils";
import { useSnackbar } from "notistack";
import Loader from "../stuff/Loader";

export default function OntologyDeleteModal(props) {
    const [deleteString, setDeleteString] = useState("");
    const [loading, setLoading] = useState(false)
    const { id, type, isOpen, handleClose ,handleDelete} = props;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchRefrences = async () => {
            var resData = { _msg: "", _status: null, data: null };
            var refrenceArr = [];
            setLoading(true)
            if (type === "field") {
                resData = await fetchFieldByID_(id);
                refrenceArr = resData.data?.fieldGroupReferences ? resData.data?.fieldGroupReferences : []
            } else if (type === 'fieldgroup') {
                resData = await fetchFieldGroupByID_(id);
                refrenceArr = resData.data?.sectionReferences ? resData.data?.sectionReferences : [];
            } else if (type === "section") {
                resData = await fetchSectionByID_(id);
                refrenceArr = resData.data?.resourceReferences ? resData.data?.resourceReferences : [];
            }
            setLoading(false)
            if (resData._status === 200) {
                var tempString = `This ${type} is currently being used in `;
                refrenceArr.length > 0 && refrenceArr.forEach((item,index) => {
                    if(index===refrenceArr.length-1){
                        tempString = tempString.concat(`${item.name}`)
                    }else{
                        tempString = tempString.concat(`${item.name}, `)
                    }   
                })
                tempString = tempString + ".";
                setDeleteString(refrenceArr.length>0 ? tempString : "")
            } else {
                setLoading(false)
                enqueueSnackbar(resData._msg ? resData._msg : 'Unable to process your request, please try after sometime', { variant: "error" });
            }
        }
        fetchRefrences();
    }, [])

    return (
        <div>
            <Dialog onClose={handleClose} open={isOpen} maxWidth="lg">
                {loading && <Loader />}
                <DialogContent>
                    <div
                        style={{
                            height: 200,
                            width: 450,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img
                                src="/images/governance/delete.svg"
                                alt="lock"
                                style={{ height: 75, width: 75 }}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {deleteString && <p>{deleteString}</p>}
                            <p> Are you sure you want to <b>delete</b> the {type}?</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
                    <AppButton
                        onClick={handleClose}
                        className={`btnsmall`}
                        buttonName="Cancel"
                        variant="outlined"
                    />
                    <AppButton
                        onClick={handleDelete}
                        className={`btnsmall`}
                        buttonName={"Yes, Delete"}
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}