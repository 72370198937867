import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import layoutStyles from "../../../styles/commonStyles/Layout";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { setActionOpen } from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";

const Bottombar = ({ expandCanvas }) => {
  const styles = layoutStyles();
  const { currentProject } = useGetData();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        transform: `translate(-${expandCanvas ? 239 : 0}px, 0px)`,
        width: `calc(100vw - ${!expandCanvas ? 528 : 288}px)`,
      }}
      className={styles.bottombar}
    >
      <h2>Actions ({currentProject?.actions.length})</h2>
      <Tooltip title="Open Actions">
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => dispatch(setActionOpen({ actionOpen: true }))}
        >
          <img src="/img/fullscreen-line.svg" alt="Full Screen" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Bottombar;
