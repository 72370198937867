import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Tooltip,
  Tabs,
  Tab,
} from "@material-ui/core";
import React, { useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useEffect } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { fetchFieldGroupDetails, fetchFieldGroupListData } from "../../../utils/project/fieldgroupUtils";
import { useSnackbar } from "notistack";
import { addAPI, getRepositoryDetailsByMicroServiceVersion, fetchVersionById } from "../../../utils/project/microserviceUtils";
import { arrayToJson, handleFormValidationPro, initError } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupForAiTaskApiCreate from "../../../components/common/entitySchema/aiTaskApi/CustomFieldGroupForAiTaskApiCreate";
import { getFunctionListForAiTask } from "../../../redux/actions/aiTaskActions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import id from "date-fns/esm/locale/id/index.js";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";


const AddAiTaskApi = (props) => {
  const {
    handleCloseAPI,
    createAPI, setCreateAPI
  } = props;
  const isTabbedApproach = useSelector(state => state.userDetails.userInfo.tabbedView)

  const { tabbedView } = useSelector(state => state.userInfoReducer);
  const mid = isTabbedApproach ? props.serviceId : props.match.params.serviceId;
  const vid = isTabbedApproach ? props.versionId : props.match.params.versionId;
  const [serviceId] = useState(mid);
  const [versionId] = useState(vid);
  const styles = manageStyles();
  const style = listScreenStyles()
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(0);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [fieldGroupDetailsForInput, setFieldGroupDetailsForInput] = useState({});
  const [fieldGroupDetailsForOutput, setFieldGroupDetailsForOutput] = useState({});
  const [headers, setHeaders] = useState([
    { id: uuidv4(), key: "", value: "" },
  ]);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiRequired, setApiRequired] = useState(false)
  const [apiEntitySchema, setApiEntitySchema] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const search = useLocation().search;


  const [apiData, setApiData] = useState({
    name: "",
    description: "",
    method: "POST",
    path: "",
    headers: {},
    input: "",
    output: "",
    queryParameters: [""],
    pathParameters: [""],
    type: "rest",
    topic: ""
  });
  const [error, setError] = useState({
    name: { isError: false },
    type: { isError: false },
  });


  const fetchRepositoryDetails = async () => {
    const { _msg, _status, data } = await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      return data
    } else {

      return false
    }
  };

  const fetchVersionByIdDetails = async () => {
    const { _msg, _status, data } = await fetchVersionById(serviceId, versionId);
    if (_status === 200) {
      return data
    } else {

      return false
    }
  };

  useEffect(async () => {
    setIsLoader(true);
    const { foldername, path } = await fetchRepositoryDetails()

    const { apirequired } = await fetchVersionByIdDetails();
    setApiRequired(apirequired)
    setIsLoader(false);
    if (foldername) {
      props.getFunctionListForAiTask(foldername, path)

    }
  }, [])

  useEffect(() => {
    const fetchAPIEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITaskAPI",
        "create"
      );
      if (_status === 200) {
        const entity = []
        data?.sections?.map(section => (
          section?.fieldgroups?.map(fieldgroup => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section)
            }
          }))
        )

        setApiEntitySchema(entity);
        const apData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.uiElementType === "Amount") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.datakey) {
                return (apData[field.datakey] = "");
              }
            });
          });
        });

        setApiData(prevData => ({ ...prevData, ...apData, type: 'rest', method: 'POST' }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                return errData[field.datakey] = initError(field, index)
              }
            });
          });
        });
        delete errData['parameter']
        delete errData['topic']

        setError(errData);
      } else {

        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const getFieldGroups = async () => {
      const { _msg, _status, data } = await fetchFieldGroupListData();
      if (_status === 200) {
        setFieldGroups(data ? data : []);
      } else {

        enqueueSnackbar(_msg, { variant: "error" });
      }
    };

    fetchAPIEntitySchema()
    getFieldGroups();
  }, []);

  const getFieldGroupDetailsForInput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {

      setFieldGroupDetailsForInput(data);
    } else {

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const getFieldGroupDetailsForOutput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {

      setFieldGroupDetailsForOutput(data);
    } else {

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  useEffect(() => {
    getFieldGroupDetailsForInput(apiData.input)
  }, [apiData.input])

  useEffect(() => {
    getFieldGroupDetailsForOutput(apiData.output)
  }, [apiData.output])

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "type") {
      if (event.target.value === "http" || event.target.value === "rest") {
        const tempError = error;
        delete tempError['topic']
        tempError['path'] = {
          isError: false,
          fieldLabel: 'Path',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        tempError['method'] = {
          isError: false,
          fieldLabel: 'Method',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        setError(tempError)
      } else {
        const tempError = error;
        delete tempError['path']
        delete tempError['method']
        tempError['topic'] = {
          isError: false,
          fieldLabel: 'Topic',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        setError(tempError)
      }
    }
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: ""
        }
      })
    }
    setApiData({
      ...apiData,
      [event.target.name]: value,
    });
  };

  const deleteQueryParameter = (index) => {
    apiData.queryParameters.splice(index, 1);
    setApiData({ ...apiData, queryParameters: apiData.queryParameters });
  };

  const deletePathParameter = (index) => {
    apiData.pathParameters.splice(index, 1);
    setApiData({ ...apiData, pathParameters: apiData.pathParameters });
  };

  const handleSubmit = async () => {
    let currentApiData = apiData
    if (!isApiRequired) {
      currentApiData['function'] = 'dummy'
    }
    const { formIsValid, errors } = handleFormValidationPro(currentApiData, error);
    if (formIsValid) {
      if (!isApiRequired) {
        delete currentApiData['function']
      }
      const newApiData = {
        ...currentApiData,
        headers: arrayToJson(headers),
        input: { fieldGroupid: currentApiData.input },
        output: { fieldGroupid: currentApiData.output },
        queryParameters: currentApiData.queryParameters[0]
          ? currentApiData.queryParameters
          : [],
        pathParameters: apiData.queryParameters[0]
          ? currentApiData.pathParameters
          : [],
      };
      if (currentApiData.type !== "http" && currentApiData.type !== "rest") {
        newApiData['topic'] = currentApiData.topic
        delete newApiData['method']
        delete newApiData['path']
        delete newApiData['pathParameters']
        delete newApiData['queryParameters']
        delete newApiData['input']
        delete newApiData['output']
        delete newApiData['headers']
      } else {


        delete newApiData['topic']
      }


      setIsLoader(true);
      const { _msg, _status, data } = await addAPI(
        serviceId,
        versionId,
        newApiData
      );

      if (_status === 201) {

        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        if (isTabbedApproach) {

          setCreateAPI(false);
          histroy.push(`/aitask/edit/${serviceId}`);
        } else {
          histroy.push(`/aitask/edit/${serviceId}`);
        }

      } else {

        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(apiEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setApiEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("Please fill the mandatory input fields", { variant: "error" })
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      {isTabbedApproach ?
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <Grid
              container
              direction="row"
              justify="space-between"
              // style={{ borderBottom: "2px solid #afafaf" }}
              style={{ marginTop: "-150px" }}
            >
              {/* <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              indicatorColor="primary"
              textColor="primary"
              className={style.documentsListAccessTab}
            >
              {apiEntitySchema.length !== 0 &&
                apiEntitySchema.map((section) => {
                  return <Tab label={<>{section["section Label"]} </>} />;
                })}
            </Tabs> */}
              <h2 className={styles.heading}>
                Create AITask API
              </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'space-between',
                }}
              >

                {/* {activeTab > 0 ? (
              <Tooltip title="Previous" arrow>
                <Button
                  aria-label="previous"
                  color="primary"
                  variant="outlined"
                  startIcon={
                    <>
                      <NavigateBeforeIcon
                        style={{ width: "25px", height: "23px" }}
                      />
                    </>
                  }
                  className="btnsmall"
                  onClick={handlePrevious}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            &nbsp;&nbsp; */}
                {/* <Tooltip title="Save" arrow>
              <Button
                aria-label="Create"
                color="primary"
                variant="outlined"
                // disabled={!checkCompPermission("Microservice", ["Edit"])}
                startIcon={
                  <SaveIcon style={{ width: "25px", height: "23px" }} />
                }
                className="btnsmall"
                onClick={handleSubmit}
              />
            </Tooltip>
            &nbsp;&nbsp; */}

                {/* <Tooltip title="Cancel" arrow>
              <Button
                aria-label="Cancel"
                color="primary"
                variant="outlined"
                startIcon={
                  <HighlightOffIcon style={{ width: "25px", height: "23px" }} />
                }
                className="btnsmall"
                onClick={handleCloseAPI}
              />
            </Tooltip>
            {/* ):(<></>)} */}
                <TabbedNavigation isCreateMode={true} handleSave={handleSubmit} handleCancel={handleCloseAPI} />

                {/*             
            {activeTab !== apiEntitySchema.length - 1 ? (
              <Tooltip title="Next" arrow>
                <Button
                  aria-label="Next"
                  color="primary"
                  variant="outlined"
                  startIcon={
                    <>
                      <NavigateNextIcon
                        style={{ width: "25px", height: "23px" }}
                      />
                    </>
                  }
                  className="btnsmall"
                  onClick={handleNext}
                />
              </Tooltip>
            ) : (
              <></>
            )} */}

              </div>
            </Grid>
            <div style={{ borderTop: "2px solid #afafaf" }}>
              <SectionTabs entity={apiEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />

              <CustomFieldGroupForAiTaskApiCreate
                section={apiEntitySchema[activeTab]}
                apiData={apiData}
                isApiRequired={isApiRequired}
                error={error}
                tab={tab}
                fieldGroups={fieldGroups}
                fieldGroupDetailsForInput={fieldGroupDetailsForInput}
                fieldGroupDetailsForOutput={fieldGroupDetailsForOutput}
                headers={headers}
                setHeaders={setHeaders}
                deletePathParameter={deletePathParameter}
                deleteQueryParameter={deleteQueryParameter}
                handleTabChange={handleTabChange}
                setError={setError}
                setApiData={setApiData}
                handleChange={handleChange}
              />
            </div>
          </div>
        </form>
        :
        <form onSubmit={handleSubmit}>

          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                { title: "AI Task", path: "/aitask-workbench" },
                {
                  title: props.location?.state?.serviceName,
                  path: `/aitask/details/${serviceId}`,
                },
                { title: "Create AI Task API" },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>Create AI Task API</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                }}
              >

                <AccordionNavigation
                  isCreateMode={true}
                  disabled={false}
                  isEditMode={false}
                  handleCancel={() => histroy.goBack()}
                  handleSave={handleSubmit}
                />
              </div>
            </Grid>
          </div>
          <div className={styles.wrapper}>
            {apiEntitySchema &&
              apiEntitySchema.map((section) => {
                if (section.name === "AITask_API_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupForAiTaskApiCreate
                        isApiRequired={isApiRequired}
                        section={section}
                        apiData={apiData}
                        error={error}
                        setError={setError}
                        setApiData={setApiData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <>
                      {apiData.type === "rest" && <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupForAiTaskApiCreate
                            section={section}
                            apiData={apiData}
                            error={error}
                            tab={tab}
                            fieldGroups={fieldGroups}
                            fieldGroupDetailsForInput={fieldGroupDetailsForInput}
                            fieldGroupDetailsForOutput={fieldGroupDetailsForOutput}
                            headers={headers}
                            setHeaders={setHeaders}
                            deletePathParameter={deletePathParameter}
                            deleteQueryParameter={deleteQueryParameter}
                            handleTabChange={handleTabChange}
                            setError={setError}
                            setApiData={setApiData}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>}
                    </>
                  );
                }
              })}
          </div>
        </form>
      }
    </main>
  );
};


const mapDispatchToProps = {
  getFunctionListForAiTask
};

export default connect(null, mapDispatchToProps)(AddAiTaskApi);

