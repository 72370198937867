import {
  ADD_TAG,
  FETCH_ALL_TAGS,
  FETCH_TAG_BY_ID,
  EDIT_TAG_BY_ID,
  DELETE_TAG,
  GLOSSARY_FILTERS,
  CLEAR_GLOSSARY_FILTERS,
  SET_GLOSSARY_SORT,
  ADD_LOADER,
  FETCH_TAG_RESOURCES,
  ADD_TAG_RESOURCES_LOADER,
  SET_DATA_STANDARD_TAB,
  ADD_FIELD_LOADER,
  ADD_FIELD_GROUP,
  EDIT_FIELD_GROUP,
  FETCH_FIELD_GROUPS,
  FETCH_FIELDS,
  ADD_FIELD,
  EDIT_FIELD,
  SET_FIELD_PAGE,
  SET_FIELD_GROUP_PAGE,
  SET_FIELD_SORT,
  SET_FIELD_GROUP_SORT,
  FIELDGROUP_FILTERS,
  SET_FIELD_FILTERS,
  CLEAR_FIELD_FILTERS,
  FIELD_HIDDEN,
  CLEAR_DATA_STANDARD_DATA,
  CLEAR_GLOSSARY_LIST_DATA,
  FIELDGROUP_DELETE,
  FIELDGROUP_HIDDEN,
  BLOCK_UNBLOCK_FIELD,
  GLOSSARY_DELETE,
  BLOCK_UNBLOCK_PAYLOAD,
  FIELD_DELETE,
  ALTER_FAVOURITE_GLOSSARY_BY_ID,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  // Glossary Screen States
  tagResources: {
    projects: [],
    services: [],
    fieldGroups: [],
    loading: false,
  },
  tags: {
    data: [],
    loading: false,
    _msg: null,
    _totalcount: "",
  },
  tagById: {
    name: "",
    key: "",
    description: "",
    source: "",
  },
  glossaryFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  glossarySort: [{ columnName: "createdon", direction: "desc" }],
  // Data Standard Screen States
  fields: { data: [], _msg: null },
  fieldGroups: { data: [], _msg: null },
  fieldGroupFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  tab: 0,
  currentFieldGroupPage: 1,
  currentFieldPage: 1,
  fieldFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  fieldSort: [{ columnName: "createdon", direction: "desc" }],
  fieldGroupSort: [{ columnName: "createdon", direction: "desc" }],
  fieldGroupHidden: ["name", "type", "status", "createdon", "actions"],
  loading: false,
  fieldHidden: ["name", "type", "status", "createdon", "actions"],
};

const dataStandardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_TAGS:
      return {
        ...state,
        tags: {
          loading: false,
          data: action.payload.data.data ? [...action.payload.data.data] : [],
          totalCount: action.payload._totalcount,
          deleteTagStatus: false,
        },
        tagById: state.tags?.data[0],
        _msg: null,
      };
    case FETCH_TAG_BY_ID:
      return {
        ...state,
        tagById: action.payload,
      };
    case FETCH_TAG_RESOURCES:
      const projectsList = action.payload?.filter(
        (item) => item.resourcetype === "project"
      );
      const servicesList = action.payload?.filter(
        (item) => item.resourcetype === "service"
      );
      const fieldGroupsList = action.payload?.filter(
        (item) => item.resourcetype === "field"
      );
      return {
        ...state,
        tagResources: {
          projects: projectsList,
          services: servicesList,
          fieldGroups: fieldGroupsList,
          loading: false,
        },
      };
    case ADD_TAG_RESOURCES_LOADER:
      return {
        ...state,
        tagResources: { data: state.tagResources, loading: true },
      };
    case ADD_TAG:
      return {
        ...state,
        tags: {
          data: [...state.tags.data, action.payload?.data],
          _msg: action.payload._msg,
          loading: false,
        },
      };

    case EDIT_TAG_BY_ID:
      const updated = state.tags.data.map((item) =>
        item._id === action.payload?.data?._id ? action.payload : item
      );
      return {
        ...state,
        tags: { data: [...updated], _msg: action.payload._msg, loading: false },
      };
    case DELETE_TAG:
      const afterDeletion = state.tags.data.filter(
        (item) => item._id !== action.payload.data._id
      );
      return {
        ...state,
        tags: {
          data: [...afterDeletion],
          _msg: action.payload._msg,
          loading: false,
          _totalcount: state.tags._totalcount - 1,
        },
      };

    case ADD_LOADER:
      return {
        ...state,
        tags: { data: state.tags.data, loading: true },
      };
    case GLOSSARY_FILTERS:
      return state.glossaryFilters.length === 1
        ? {
            ...state,
            glossaryFilters: [...action.payload],
          }
        : {
            ...state,
            glossaryFilters: [...state.glossaryFilters, ...action.payload],
          };
    case CLEAR_GLOSSARY_FILTERS:
      return {
        ...state,
        glossaryFilters: [
          { id: uuidv4(), column: "", operator: "", value: "" },
        ],
      };
    case SET_GLOSSARY_SORT: {
      return {
        ...state,
        glossarySort: [...action.payload],
      };
    }

    // Data Standard Screen Reducers
    case SET_DATA_STANDARD_TAB:
      return {
        ...state,
        tab: action.payload,
      };

    // --> Field Groups
    case SET_FIELD_GROUP_PAGE:
      return {
        ...state,
        currentFieldGroupPage: action.payload,
      };
    case SET_FIELD_GROUP_SORT: {
      return {
        ...state,
        fieldGroupSort: [...action.payload],
      };
    }
    case ADD_FIELD_LOADER:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FIELD_GROUPS:
      return {
        ...state,
        fieldGroups: {
          ...state.fieldGroups,
          data: action.payload,
          deleteFieldGroupStatus: false,
        },
        loading: false,
      };
    case FIELDGROUP_FILTERS: {
      return {
        ...state,
        fieldGroupFilters: [...action.payload],
      };
    }
    case ADD_FIELD_GROUP:
      return {
        ...state,
        fieldGroups: {
          data: [...state.fieldGroups.data, action.payload?.data],
          _msg: action.payload._msg,
        },
      };
    case EDIT_FIELD_GROUP:
      const updatedFieldGroups = state.fieldGroups.data.map((item) =>
        item._id === action.payload?.data._id ? action.payload : item
      );
      return {
        ...state,
        fieldGroups: {
          data: [...updatedFieldGroups],
          _msg: action.payload._msg,
        },
      };
    case FIELDGROUP_HIDDEN: {
      return {
        ...state,
        fieldGroupHidden: [...action.payload],
      };
    }
    case FIELDGROUP_DELETE: {
      return {
        ...state,
        fieldGroups: {
          ...state.fieldGroups,
          data: {
            ...state.fieldGroups.data,
            data: state.fieldGroups.data.data.filter(
              (group) => group._id !== action.payload.fieldgroupId
            ),
          },
          _totalcount: state.fieldGroups._totalcount - 1,
          deleteFieldGroupStatus: true,
        },
      };
    }
    case FIELD_DELETE: {
      return {
        ...state,
        fields: {
          ...state.fields,
          data: {
            ...state.fields.data,
            data: state.fields.data.data.filter(
              (field_item) => field_item._id !== action.payload.fieldId
            ),
          },
          _totalcount: state.fields._totalcount - 1,
          deleteFieldStatus: true,
        },
      };
    }
    case GLOSSARY_DELETE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          data: state.tags.data.filter(
            (serv) => serv._id !== action.payload.glossaryId
          ),
          _totalcount: state.tags._totalcount - 1,
          deleteTagStatus: true,
        },
      };
    }

    case BLOCK_UNBLOCK_FIELD: {
      return {
        ...state,
        fields: {
          ...state.fields,
          data: state.fields.data.map((item) => {
            if (item._id === action.payload.fieldId) {
              return {
                ...item,
                status: action.payload.status,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }
    case BLOCK_UNBLOCK_PAYLOAD: {
      return {
        ...state,
        fieldGroups: {
          ...state.fieldGroups,
          data: {
            ...state.fieldGroups.data,
            data: state.fieldGroups.data.data.map((item) => {
              if (item._id === action.payload.payloadId) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
          },
        },
      };
    }

    // --> Fields

    case SET_FIELD_PAGE:
      return {
        ...state,
        currentFieldPage: action.payload,
      };
    case SET_FIELD_SORT: {
      return {
        ...state,
        fieldSort: [...action.payload],
      };
    }
    case FETCH_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          data: action.payload?.data,
          totalCount: action.payload?._totalcount,
          deleteFieldStatus: false,
        },
        loading: false,
      };
    case CLEAR_DATA_STANDARD_DATA: {
      return {
        ...state,
        fields: {
          data: [],
        },
      };
    }
    case ADD_FIELD:
      return {
        ...state,
        fields: {
          data: [...state.fields.data, action.payload?.data],
          _msg: action.payload._msg,
        },
      };
    case EDIT_FIELD:
      const updatedFields = state.fields.data.map((item) =>
        item._id === action.payload?.data._id ? action.payload : item
      );
      return {
        ...state,
        fields: { data: [...updatedFields], _msg: action.payload._msg },
      };
    case SET_FIELD_FILTERS:
      return state.fieldFilters.length === 1
        ? {
            ...state,
            fieldFilters: [...action.payload],
          }
        : {
            ...state,
            fieldFilters: [...state.fieldFilters, ...action.payload],
          };
    case CLEAR_FIELD_FILTERS:
      return {
        ...state,
        fieldFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
      };
    case FIELD_HIDDEN: {
      return {
        ...state,
        fieldHidden: [...action.payload],
      };
    }

    case ALTER_FAVOURITE_GLOSSARY_BY_ID: {
      return {
        ...state,
        tags: {
          ...state.tags,
          data:
            state.tags.data.length !== 0 &&
            state.tags.data.map((tag) => {
              if (tag._id === action.payload.glossaryId) {
                return { ...tag, isFavourite: !tag.isFavourite };
              } else {
                return tag;
              }
            }),
        },
      };
    }

    case CLEAR_GLOSSARY_LIST_DATA: {
      return {
        ...state,
        tags: {},
      };
    }
    default:
      return state;
  }
};

export default dataStandardReducer;
