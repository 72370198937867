import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CreateDetailsStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { imageDetails } from "../../../utils/containerMangement/imageUtils";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import DockerImageDetailsEntity from "../../../components/common/entitySchema/containerManagement/DockerImage/DockerImageDetailsEntity";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const ImageDetailsScreen = (props) => {
  const styles = CreateDetailsStyles();
  const histroy = useHistory();
  const [imageId] = useState(props.match.params._id);
  const [dockerImagesEntity, setDockerImagesEntity] = useState({});
  const [dockerImagesData, setDockerImagesData] = useState("");
  const [setLoader, setIsLoader] = useState(false);
  const [Disabled, setDisabled] = useState(true);

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("DockerImage", ["Read"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchDockerImagesEntity = async () => {
      let { _msg, _status, data } = await fetchResourceByPermission(
        "DockerImage",
        "read"
      );
      if (_status === 200) {
        let newData = data;
        let newDataArray = newData.sections;
        newDataArray = newDataArray.filter(
          (section) => section.name !== "DockerImage_FileUpload"
        );
        data = { ...data, sections: newDataArray };
        setDockerImagesEntity(data);
        fetchDockerImagesData();
        const dockerData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field?.editable === "TRUE") {
                setDisabled(false);
              }
              if (field.datakey) {
                return (dockerData[field.datakey] = "");
              }
            });
          });
        });

        setDockerImagesData({ ...dockerImagesData, ...dockerData });
        setIsLoader(false);
      } else {
        setIsLoader(false);


      }
    };

    const fetchDockerImagesData = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await imageDetails(imageId);
      if (_status === 200) {
        setDockerImagesData(() => (data ? data : []));
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    };

    fetchDockerImagesEntity();
  }, [imageId]);

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/docker-images");
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={setLoader}
        onClick={() => setIsLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Docker Images", path: "/docker-images" },
            {
              title: `${dockerImagesData?.imagename ? dockerImagesData?.imagename : ""
                }`,
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.title}>{dockerImagesData.imagename}</h2>
          {tabbedView ? (
            <TabbedNavigation
              isClose={true}
              isEditMode={false}
              activeTab={activeTab}
              handleEdit={() => histroy.push(`/edit-image/${imageId}`)}
              handleCancel={handleCancel}
              disabled={Disabled}
            />
          ) : (
            <AccordionNavigation
              isClose={true}
              isEditMode={true}
              handleCancel={handleCancel}
              handleEdit={() => histroy.push(`/edit-image/${imageId}`)}
              disabled={Disabled}

            />

          )}
        </Grid>
        {/* <div className={styles.border_line} /> */}
      </div>
      <div
        className={styles.wrapper}
        style={tabbedView ? { top: "6.5rem" } : { top: "7.2rem" }}
      >
        {tabbedView ? (
          <>
            <SectionTabs
              entity={dockerImagesEntity ? dockerImagesEntity.sections : []}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <DockerImageDetailsEntity
                  section={dockerImagesEntity?.sections?.[activeTab]}
                  dockerImagesData={dockerImagesData}
                />
              </div>
            </Paper>
          </>
        ) : (
          dockerImagesEntity?.sections &&
          dockerImagesEntity?.sections.map((section) => {
            if (section.name === "DockerImage_Header_section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <DockerImageDetailsEntity
                    section={section}
                    dockerImagesData={dockerImagesData}
                  />
                </div>
              );
            } else if (section.name === "DockerImage_FileUpload") {
              return "";
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DockerImageDetailsEntity
                      section={section}
                      dockerImagesData={dockerImagesData}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(ImageDetailsScreen);
