import React from "react";
import TextAreaStyles from "./TextAreaStyles";
import { Grid } from "@material-ui/core";
import useTriggers from "../../../hooks/useTriggers";
import ErrorStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";

const TextAreaAdvance = ({
  name,
  placeholder,
  label,
  rows,
  value,
  onChange,
  selectedComponent,
}) => {
  const styles = TextAreaStyles();
  const classes = ErrorStyles();
  const { handleTrigger } = useTriggers();

  const onEnter = (e) => {
    if (selectedComponent["onEnter"] && e.key == "Enter")
      handleTrigger({
        action: selectedComponent?.["onEnter"],
        isUpdateState: true,
        componentId: selectedComponent?.id,
      });
  };
  return (
    <Grid
      container
      direction={selectedComponent?.direction === false ? "row" : "column"}
      spacing={1}
    >
      <Grid
        item
        container
        xs={selectedComponent?.direction === false ? 3 : 12}
        justifyContent={
          selectedComponent?.alignment === false ? "flex-start" : "flex-end"
        }
        alignItems="center"
      >
        <Grid
          item
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: selectedComponent?.style?.bold ? "bold" : undefined,
            fontStyle: selectedComponent?.style?.italic ? "italic" : undefined,
            fontSize: selectedComponent?.fontSize
              ? selectedComponent?.fontSize
              : 16,
            textDecoration: selectedComponent?.style?.underline
              ? "underline"
              : undefined,
            fontFamily: selectedComponent?.fontFamily,
          }}
        >
          {label}
          {selectedComponent?.isRequired === true && (
            <span className={classes.red}>*</span>
          )}
        </Grid>
      </Grid>
      <Grid item xs={selectedComponent?.direction === false ? 9 : 12}>
        <textarea
          rows={parseInt(rows)}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onEnter}
          className={styles.textarea}
          style={{
            border:
              selectedComponent?.disabled?.dataValue !== "true"
                ? `1px solid ${selectedComponent?.status}`
                : undefined,
            resize: "none",
            fontSize: selectedComponent?.fontSize
              ? selectedComponent?.fontSize
              : 16,
            fontFamily: selectedComponent?.fontFamily,
          }}
          disabled={selectedComponent?.disabled?.dataValue === "true"}
        />
      </Grid>
    </Grid>
  );
};

export default TextAreaAdvance;
