import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CheckBox from "../../atoms/checkbox/Checkbox";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";

const CheckboxComponent = ({ id }) => {
  const dispatch = useDispatch();
  const { currentPage, getComponent } = useGetData();
  const { handleTrigger } = useTriggers();
  const selectedComponent = getComponent(id);
  const labelText = selectedComponent?.text?.dataValue;
  const value = selectedComponent?.data?.dataValue?.toString();

  useEffect(() => {
    if (selectedComponent["onInit"])
      handleTrigger({
        action: selectedComponent?.onInit,
        reqData: {},
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          data: {
            dataInput: e.target.checked.toString(),
            dataValue: e.target.checked,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const handleClick = () => {
    if (selectedComponent["onClick"])
      handleTrigger({
        action: selectedComponent?.onClick,
        reqData: {},
      });
  };

  return (
    <div style={{ padding: "0px 10px 0px 10px" }} onClick={handleClick}>
      <CheckBox
        label={labelText && typeof labelText === "string" ? labelText : "Label"}
        checked={value === "false" ? false : value === "true" ? true : false}
        handleChange={handleChange}
        selectedComponent={selectedComponent}
      />
    </div>
  );
};

export default CheckboxComponent;
