import React, { useEffect } from "react";
import Styles from "../../../styles/commonStyles/Components";
import useGetData from "../../../hooks/useGetData";
import useTriggers from "../../../hooks/useTriggers";
import DatePicker from "../DatePicker/DatePicker";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import { alpha } from "@material-ui/core/styles";

function CustomDatePicker({ id }) {
  const classes = Styles();
  const dispatch = useDispatch();
  const { getComponent, currentPage, currentProject } = useGetData();

  const currentComponent = getComponent(id);

  const { handleTrigger } = useTriggers();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  useEffect(() => {
    if (currentComponent?.value) {
      setSelectedDate(currentComponent?.value);
    }
  }, [currentComponent?.value]);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          value: event.target.value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage.id,
        data: components,
      })
    );

    if (currentComponent?.onChange) {
      handleTrigger({
        action: currentComponent?.onChange,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };

  const labelText = currentComponent?.label?.dataValue;

  return (
    <>
      <div
        className={classes.DatePicker}
        style={{
          display: currentComponent?.direction === true ? "block" : "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="label"
          style={{
            width: currentComponent?.direction === true ? "100%" : "25%",
            textAlign: currentComponent?.alignment === true ? "right" : "left",
            paddingRight: 4,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
            fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
            fontSize: currentComponent?.fontSize
              ? currentComponent?.fontSize
              : 16,
            textDecoration: currentComponent?.style?.underline
              ? "underline"
              : undefined,
            fontFamily: currentComponent?.fontFamily,
          }}
        >
          {labelText && typeof labelText === "string" ? labelText : "Label"}
        </div>
        <div
          style={{ width: currentComponent?.direction === true ? "" : "75%" }}
        >
          <DatePicker
            selectedDate={selectedDate}
            handleChangeDate={handleChange}
            selectedComponent={currentComponent}
            inSettings={false}
          />
        </div>
      </div>
    </>
  );
}

export default CustomDatePicker;
