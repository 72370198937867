import { DeploymentProcessService } from "../../services/deploymentProcessService";
const deploymentProcessService = new DeploymentProcessService();

export const fetchAllDeployments = async (resource) => {
  try {
    const res_data = await deploymentProcessService.getAllDeployment(resource);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editDeploymentById = async (DeploymentId, payload) => {
  try {
    const res_data = await deploymentProcessService.editDeployment(
      DeploymentId,
      payload
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getAllClusterList = async (configSelected) => {
  try {
    const res_data = await deploymentProcessService.getClusterList(
      configSelected
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDeployment = async (DeploymentId) => {
  try {
    const res_data = await deploymentProcessService.deleteDeployment(
      DeploymentId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const deleteDeploymentContainer = async (
  DeploymentId,
  ContainerName
) => {
  try {
    const res_data = await deploymentProcessService.deleteDeploymentContainer(
      DeploymentId,
      ContainerName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createDeployment = async (deploymentData) => {
  try {
    const res_data = await deploymentProcessService.createDeployment(
      deploymentData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const reDeploy = async (deploymentData, deploymentId, containerName) => {
  try {
    const res_data = await deploymentProcessService.reDeploy(
      deploymentData,
      deploymentId,
      containerName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const additionalDeployment = async (
  deploymentData,
  deploymentId,
  containerName
) => {
  try {
    const res_data = await deploymentProcessService.additionalDeployment(
      deploymentData,
      deploymentId,
      containerName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const FetchDeploymentById = async (DeploymentId) => {
  try {
    const res_data = await deploymentProcessService.getDeploymentById(
      DeploymentId
    );
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getAWSRegionList = async () => {
  try {
    const res_data = await deploymentProcessService.getAWSRegionList();
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const getNodeUsageDetails = async (configId, cluserName, nodeName) => {
  try {
    const res_data = await deploymentProcessService.getNodeDetails(configId, cluserName, nodeName);
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getNodesList = async (configId, cluserName) => {
  try {
    const res_data = await deploymentProcessService.getNodesList(configId, cluserName);
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
