import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography, Tabs, Tab, Tooltip, Button, Paper
} from "@material-ui/core";

import React, { useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useEffect } from "react";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { arrayToJson, handleFormValidationPro, initError, jsonToArray } from "../../../utils/common";
import {
  addRuntimeEnvironment,
  editBuildCommands,
  editEnvironmentForVersion,
  editRunCommands,
  editVersion,
  fetchAllRuntimeEnvironments,
  fetchVersionById,
  getRepositoryDetailsByMicroServiceVersion,
} from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import UploadSourceCodeInVersion from "../../../components/common/modals/UploadSourceCodeInVersion";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForVersionEdit from "../../../components/common/entitySchema/version/CustomFieldGroupsForVersionEdit";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useLocation } from "react-router-dom";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import {
  closeVersion,
  editVersionTabbed,
} from "../../../redux/actions/serviceAction";
import { useDispatch, useSelector } from "react-redux";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { version } from "codemirror";

const EditVersion = (props) => {

  const { fromAppBundle, tabbedView } = props;
  const isTabbedApproach = useSelector(state => state.userDetails.userInfo.tabbedView)


  const isEditMode = isTabbedApproach ? props.isEditMode : props.match.params.action === "edit" ? true : false;
  const mid = isTabbedApproach ? props.serviceId : props.match.params.serviceId;
  const vid = isTabbedApproach ? props.versionId : props.match.params.versionId;
  const [serviceId] = useState(mid);
  const [versionId] = useState(vid);
  const style = listScreenStyles();
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const [isLoader, setIsLoader] = useState(false);
  const [openUploadSourceCodeModal, setOpenUploadSourceCodeModal] = useState(false);
  const [repositoryData, setRepositoryData] = useState({})
  const [versionEntitySchema, setVersionEntitySchema] = useState([]);
  const [runtimeEnvs, setRuntimeEnvs] = useState([])
  const [selectedRuntimeEnv, setSelectedRuntimeEnv] = useState({})
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const [versionData, setVersionData] = useState({
    _id: "",
    versionTag: "",
    description: "",
    createdon: "",
    updatedby: "",
    microservice_name: "",
    microserviceType: "Internal",
    environmentVariables: {},
    buildcommands: [""],
    runcommands: [""],
  });
  const [error, setError] = useState({
    versionTag: { isError: false },
  });

  const search = useLocation().search;
  // const appBundleId = new URLSearchParams(search).get("id");

  const { checkScreenPermission, checkCompPermission } = useCheckPermission()

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission('Version', ['Edit'])
    } else {
      checkScreenPermission('Version', ['Read'])
    }
    const fetchVersionEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Version",
        isEditMode ? "edit" : "read"
      );
      if (_status === 200) {
        const entity = []
        data?.sections?.map(section => {
          if (versionData?.microserviceType === "External") {
            if (section.name !== "Version_Docker_Section" && section.name != "Version_Environmentvariables_Section" && section.name != "Version_SourceCode_Section" &&
              section.name !== "Version_Runtime_Section"
            ) {
              section?.fieldgroups?.map((fieldgroup) => {
                if (fieldgroup?.fields?.length !== 0) {
                  entity.push(section);
                }
              })
            }
          } else {
            section?.fieldgroups?.map((fieldgroup) => {
              if (fieldgroup?.fields?.length !== 0) {
                entity.push(section);
              }
            })
          }
        }
        )
        setVersionEntitySchema(entity);
        fetchVersionDetails();
        const verData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.uiElementType === "Amount") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.datakey) {
                return (verData[field.datakey] = "");
              }
            });
          });
        });
        if (versionData?.microserviceType === "External") {
          delete verData['port']
        }
        setVersionData(prevData => ({ ...prevData, ...verData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field.index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                return errData[field.datakey] = initError(field, index)
              }
            });
          });
        });
        delete errData['dockerCommands']
        delete errData['envs']
        if (versionData?.microserviceType === "External") {
          delete errData['port']
        }
        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
      }
    };

    fetchVersionEntitySchema()
    fetchRepositoryDetails()
  }, [isEditMode, versionData.microserviceType]);

  useEffect(() => {
    if (versionData.microserviceType === "External") {
      let tempEntity = versionEntitySchema.filter((item) => item.name !== "Version_SourceCode_Section");
      setVersionEntitySchema(tempEntity)
    }
  }, [versionData.microserviceType])

  const fetchRuntimeEnvs = async (envId) => {
    const { _msg, _status, data } = await fetchAllRuntimeEnvironments();
    if (_status === 200) {
      setRuntimeEnvs(data ? data : [])
      setSelectedRuntimeEnv(data.find(env => env._id === envId))
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const fetchVersionDetails = async () => {
    setIsLoader(true)
    const { _msg, _status, data } = await fetchVersionById(
      serviceId,
      versionId
    );
    if (_status === 200) {
      let tempVersionData = versionData
      if (data?.microserviceType === "External") {
        delete tempVersionData['port']
        let tempVersionError = error
        delete tempVersionError['port']
        setError(tempVersionError)
      }


      setVersionData(prevData => ({
        ...tempVersionData,
        ...data,
        buildcommands: data.docker.buildcommands,
        runcommands: data.docker.runcommands,
      }));
      setEnvs(jsonToArray(data.environmentVariables).length !== 0 ? jsonToArray(data.environmentVariables) : [{ id: uuidv4(), key: "", value: "" }]);
      if (checkCompPermission('RuntimeEnvironment', ['List All'])) {
        fetchRuntimeEnvs(data?.docker?.environmentID)
      }
      setIsLoader(false)
    } else {
      setIsLoader(false)
      enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
    }
  };

  const fetchRepositoryDetails = async () => {
    const { _msg, _status, data } = await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      setRepositoryData(data ? data : {})
    } else {
      return false
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: ""
        }
      })
    }
    setVersionData({
      ...versionData,
      [event.target.name]: value,
    });
  };

  const deleteBuildCommand = (index) => {
    versionData.buildcommands.splice(index, 1);
    setVersionData({
      ...versionData,
      buildcommands: versionData.buildcommands,
    });
  };

  const deleteRunCommand = (index) => {
    versionData.runcommands.splice(index, 1);
    setVersionData({ ...versionData, runcommands: versionData.runcommands });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(versionData, error);
    if (formIsValid) {
      const newVersionData = {
        ...versionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: versionData.buildcommands[0]
            ? versionData.buildcommands
            : [],
          runcommands: versionData.runcommands[0]
            ? versionData.runcommands
            : [],
        },
      };
      setIsLoader(true);
      const { _msg, _status, data } = await editVersion(
        serviceId,
        versionId,
        newVersionData
      );
      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        fetchRepositoryDetails()
        setIsLoader(false);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });;
        }
      }

      if (selectedRuntimeEnv?._id) {
        setIsLoader(true);
        const addEnvResp = await addRuntimeEnvironment(
          serviceId,
          versionId,
          { environmentID: selectedRuntimeEnv?._id }
        );
        if (addEnvResp._status === 200) {
          enqueueSnackbar(addEnvResp._msg, { variant: "success" });
          fetchRepositoryDetails()
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (addEnvResp._msg === "Invalid Json") {
            addEnvResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(addEnvResp._msg ? addEnvResp._msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }

      if (envs?.length !== 0) {
        setIsLoader(true);
        const editEnvResp = await editEnvironmentForVersion(serviceId, versionId,
          { environmentVariables: arrayToJson(envs) }
        );
        if (editEnvResp._status === 200) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (editEnvResp._msg === "Invalid Json") {
            editEnvResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(editEnvResp._msg ? editEnvResp._msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }

      if (versionData.buildcommands.length !== 0) {
        setIsLoader(true);
        const editBuildResp = await editBuildCommands(serviceId, versionId,
          versionData.buildcommands[0]
            ? versionData.buildcommands
            : []
        );
        if (editBuildResp._status === 200) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (editBuildResp._msg === "Invalid Json") {
            editBuildResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(editBuildResp._msg ? editBuildResp._msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }

      if (versionData.runcommands.length !== 0) {
        setIsLoader(true);
        const editRunResp = await editRunCommands(serviceId, versionId,
          versionData.runcommands[0]
            ? versionData.runcommands
            : []
        );
        if (editRunResp._status === 200) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (editRunResp._msg === "Invalid Json") {
            editRunResp.data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(editRunResp._msg ? editRunResp._msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }

      fetchVersionDetails()

      if (isTabbedApproach) {
        dispatch(closeVersion());
      } else {

        histroy.push(`/versions/details/${serviceId}/${versionId}`);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(versionEntitySchema, errors, isTabbedApproach)
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);
      setError(errors);
    }
  };

  const handleCancel = () => {
    dispatch(closeVersion());
  };
  const handleCancelAccordion = () => {
    // dispatch(closeVersion());
    histroy.push(`/services/edit/${serviceId}`);

  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  const handleEdit = () => {
    dispatch(editVersionTabbed(versionId));
  };




  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        {!isTabbedApproach ? (
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                { title: "Service", path: "/services" },
                {
                  title: versionData?.microservice_name,
                  path: `/services/details/${serviceId}`,
                },
                { title: isEditMode ? `Edit ${versionData.versionTag}` : versionData.versionTag },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>
                {isEditMode ? `Edit ${versionData.versionTag}` : versionData.versionTag}
              </h2>
              {!isEditMode ? (
                <AccordionNavigation
                  disabled={!checkCompPermission('Version', ['Edit'])}
                  isEditMode={true}
                  isClose={true}
                  handleEdit={() =>
                    histroy.push(`/versions/edit/${serviceId}/${versionId}`)}
                  handleCancel={handleCancelAccordion}

                />
              ) : (
                <AccordionNavigation
                  isEditMode={false}
                  disabled={!checkCompPermission('Version', ['Edit'])}
                  handleSave={handleSubmit}
                  handleCancel={handleCancelAccordion}
                />
              )}
            </Grid>
          </div>
        ) : <Grid
          container
          direction="row"
          justify="space-between"
          style={{
            borderBottom: "2px solid #afafaf",
            marginTop: "-80px",
            padding: "0px 0px",
          }}
        >
          {/* <Tabs
        value={activeTab}
        onChange={handleActiveTab}
        indicatorColor="primary"
        textColor="primary"
        className={style.documentsListAccessTab}
      >
        {versionEntitySchema.length !== 0 &&
          versionEntitySchema.map((section) => {
            return <Tab label={<>{section["section Label"]} </>} />;
          })}
      </Tabs> */}
          <h2 className={styles.heading}>
            {isEditMode ? `Edit ${versionData.versionTag}` : versionData.versionTag}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TabbedNavigation isEditMode={isEditMode} handleSave={handleSubmit} handleEdit={handleEdit} handleCancel={handleCancel} isClose={isEditMode ? false : true} />
          </div>
        </Grid>
        }

        {!isTabbedApproach ?
          <div className={styles.wrapper}>
            {versionEntitySchema &&
              versionEntitySchema.map((section) => {
                if (section.name === "Version_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>

                      <CustomFieldGroupsForVersionEdit
                        section={section}
                        versionData={versionData}
                        error={error}
                        isEditMode={isEditMode}
                        setError={setError}
                        setVersionData={setVersionData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else if (versionData?.microserviceType === "External") {
                  return <></>
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForVersionEdit
                          section={section}
                          versionData={versionData}
                          error={error}
                          envs={envs}
                          repositoryData={repositoryData}
                          isEditMode={isEditMode}
                          runtimeEnvs={runtimeEnvs}
                          selectedRuntimeEnv={selectedRuntimeEnv}
                          setEnvs={setEnvs}
                          setError={setError}
                          setSelectedRuntimeEnv={setSelectedRuntimeEnv}
                          setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
                          deleteBuildCommand={deleteBuildCommand}
                          deleteRunCommand={deleteRunCommand}
                          setVersionData={setVersionData}
                          handleChange={handleChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              handleSubmit={(data) => setRepositoryData(data)}
              versionID={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
              versionDetails={versionData}
            />
          </div> : <div style={{ padding: "20px 0px" }}>
            <SectionTabs entity={versionEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            <Paper style={{ padding: tabbedView ? "20px" : "0px" }}>

              <CustomFieldGroupsForVersionEdit
                section={versionEntitySchema[activeTab]}
                versionData={versionData}
                error={error}
                envs={envs}
                repositoryData={repositoryData}
                isEditMode={isEditMode}
                runtimeEnvs={runtimeEnvs}
                selectedRuntimeEnv={selectedRuntimeEnv}
                setEnvs={setEnvs}
                setError={setError}
                setSelectedRuntimeEnv={setSelectedRuntimeEnv}
                setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
                deleteBuildCommand={deleteBuildCommand}
                deleteRunCommand={deleteRunCommand}
                setVersionData={setVersionData}
                handleChange={handleChange}
              />
            </Paper>

            <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              handleSubmit={(data) => setRepositoryData(data)}
              versionID={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
            />
          </div>
        }

      </form>
    </main>
  );
};

export default EditVersion;