import { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core";
import useGetData from "../../../../../hooks/useGetData";
import CustomAutocomplete from "../../../autocomplete/CustomAutocomplete";
import Dropdown from "../../../dropdown/Dropdown";
import JSDataInput from "../../settings/JSDataInput";
import StatusStyles from "../showStatus/ShowStatusStyles";
import { useSelector } from "react-redux";

const UrlGroupButton = ({
  label,
  fstIconUrl,
  sndIconUrl,
  fstAltTxt,
  sndAltTxt,
  currentComponent,
  currentProject,
  handleChange,
}) => {
  const styles = StatusStyles();
  const theme = useTheme();
  const foundApps = useSelector((state) => state.uiApplicationListReducer.data);
  const { globalAcessModeChange } = useGetData();
  const [updatedPages, setUpdatedPages] = useState([]);
  const [applicationsNew, setApplicationsNew] = useState([]);
  const [accessModErr, setAccessModErr] = useState("");

  useEffect(() => {
    setUpdatedPages(
      foundApps
        .find((app) => app._id === currentComponent.uiApplication.id)
        ?.pages.map((page) => ({
          name: page?.name,
          id: page?.id,
          url: page?.url,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComponent?.uiApplication]);

  const setApplicationMappedData = async () => {
    const mappedData = foundApps?.map((v) => ({
      id: v?._id,
      name: v?.name,
      previewMode: v?.previewMode,
    }));
    setApplicationsNew(mappedData);
  };

  useEffect(
    () => setApplicationMappedData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProject?.pages, currentComponent?.uiApplication]
  );

  return (
    <>
      <label>{label}</label>
      <div className={styles.loadingbtnContainer}>
        <button
          className={styles.loadingbtn}
          onClick={() => handleChange("urlType", "page", null)}
          style={
            currentComponent.urlType === "page"
              ? {
                  backgroundColor: theme.palette.primary.main,
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }
              : { textTransform: "capitalize" }
          }
        >
          <img className={styles.btnIcon} src={fstIconUrl} alt={fstAltTxt} />
        </button>
        <button
          style={
            currentComponent.urlType === "link"
              ? {
                  backgroundColor: theme.palette.primary.main,
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }
              : { textTransform: "capitalize" }
          }
          className={styles.loadingbtn}
          onClick={() => handleChange("urlType", "link", null)}
        >
          <img className={styles.btnIcon} src={sndIconUrl} alt={sndAltTxt} />
        </button>
      </div>

      {currentComponent.urlType === "page" && (
        <div className={styles.body}>
          <label>UI Application</label>
          <CustomAutocomplete
            options={applicationsNew}
            optionLabel={(option) => (option ? option?.name : "")}
            value={currentComponent?.uiApplication}
            onChange={(input) => handleChange("uiApplication", { ...input })}
          />
          <label>Page</label>
          <Dropdown
            width="100%"
            value={currentComponent.page}
            onChange={(e) => handleChange("page", e.target.value)}
            arr={updatedPages}
            disabled={false}
            label=""
            name="page"
          />
        </div>
      )}
      {currentComponent.urlType === "link" && (
        <div style={{ marginTop: 5 }}>
          <JSDataInput
            value={currentComponent.link}
            onChange={(value) =>
              globalAcessModeChange(value, "link", setAccessModErr)
            }
            label=""
            error={accessModErr}
            accessMode={true}
          />
        </div>
      )}
    </>
  );
};

export default UrlGroupButton;
