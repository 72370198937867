import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";
import { STATUSPROFILE } from "./helper/config";

const apiHelper = new ApiHelper();

export class StatusProfiles {
  fetchStatusProfileObjectList() {
    const uri = `${STATUSPROFILE}/api/v1.0.0/resources/status`;
    return apiHelper.get(uri);
  }
  fetchResourcesStatus() {
    const uri = `${BASE_URL}/api/v1.0.0/resources/status`;
    return apiHelper.get(uri);
  }
  fetchStatusResourceByID(resourceId) {
    // const uri = `${BASE_URL}/api/v1.0.0/resources/${resourceId}/status`;
    const uri = `${BASE_URL}/api/v1.0.0/resources/status/${resourceId}`;
    return apiHelper.get(uri);
  }
  fetchStatusResourceByName(resourceName) {
    const uri = `${BASE_URL}/api/v1.0.0/resources/${resourceName}/status`;
    return apiHelper.get(uri);
  }
  postResourceByName(resourceName, body) {
    const uri = `${BASE_URL}/api/v1.0.0/resources/status/${resourceName}`;
    return apiHelper.post(uri, body);
  }
  deleteResourceByName(name, body) {
    const uri = `${BASE_URL}/api/v1.0.0/resources/status/${name}/_delete`;
    return apiHelper.put(uri, body);
  }
  getDependency(name, body){
    const uri = `${BASE_URL}/api/v1.0.0/resources/status/${name}/dependency`;
    return apiHelper.post(uri, body);
  }
  makeStatusDefault(body) {
    const uri = `${BASE_URL}/api/v1.0.0/resources/defaultstatus`;
    return apiHelper.post(uri, body);
  }
  editStatus(name, prevName, body) {
    const uri = `${BASE_URL}/api/v1.0.0/resources/${name}/status/${prevName}`;
    return apiHelper.put(uri, body);
  }
}
