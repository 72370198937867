import {
  Grid,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Avatar,
  Select,
  MenuItem,
  makeStyles,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ArrowDropDownRounded, PriorityHigh } from "@material-ui/icons";
// files
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import {
  fetchCurrentORGDetails,
  EditCurrentORGDetails,
  createCloudConfig,
  editCloudConfig,
  updateOntologyResource,
  getOntologyResource,
} from "../../../utils/organization/organizationUtils";
import { useSnackbar } from "notistack";
import { AppButton } from "../../../components/common/Button";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
import {
  change_logo,
  featchAllCloudConfigurationActions,
  update_inactivity_time,
} from "../../../redux/actions/organizationAction";
import { connect } from "react-redux";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import CloudConfigurationModal from "../../../components/common/modals/CloudConfigurationModal";
import AddIcon from "@material-ui/icons/Add";
import CloudConfigListTable from "../../../components/common/Tables/CloudConfigListTable";
import {
  cloudConfigColumns,
  cloudConfigDefaultColumnWidth,
  cloudConfigTableColumnExtensions,
  cloudConfigColumnOrder,
  cloudConfigColumnHidden,
} from "../../../constants/Organization/cloudConfigConstants";
import CloudConfigDevExpress from "../../../components/common/Tables/CloudConfigDevExpress";
import listStyles from "../../CommonStyles/listScreenStyles";
import manageStyles from "../../CommonStyles/manageScreenStyles";

import { Pagination } from "@material-ui/lab";
import { getAWSRegionList } from "../../../utils/deployment/deploymentUtils";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { handleFormValidationPro } from "../../../utils/common";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

export const useStyles = makeStyles((theme) => ({
  button: {
    "& button.btnsmall": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 0",
      textTransform: "capitalize !important",

      font: "normal normal 400 14px/19px Nunito",
      letterSpacing: "0.02em",
      padding: "6px 20px 6px 20px",
      minHeight: "32px",
      minWidth: "120px",
      "&.MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      },
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "120px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.btntiny": {
      backgroundColor: theme.palette.primary.main,
      backgroundOrigin: "padding-box",
      borderRadius: "5px",
      color: "#fff",
      margin: "0 0 0 30px",
      textTransform: "capitalize !important",

      font: "normal normal 500 12px/16px Nunito",
      letterSpacing: "0.02em",
      padding: "5px 34px",
      minHeight: "24px",
      minWidth: "100px",
      "&:hover": {
        opacity: 0.2,
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
      "&:focus": {
        border: "1px solid #A8A8A8",
        borderRadius: "7px",
        opacity: 1,
        minWidth: "106px",
      },
      "&.Mui-disabled": {
        opacity: "0.3 !important",
      },
    },
    "& button.secondaryButton": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
    "& button.MuiButton-outlined": {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 0.5px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.secondary.contrastText,
      fontWeight: "bolder",
    },
  },
}));
Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <>
      {value === index && (
        <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
          <div style={{ padding: "0px 0px 0" }}>{children}</div>
        </Paper>
      )}
    </>
  );
};

const EditOrganizationScreen = (props) => {
  const {
    featchAllCloudConfigurationActions,
    data,
    error,
    _totalcount,
    tabbedView,
  } = props;

  const ref = useRef(null);
  const ref1 = useRef(null);

  const [exclam, setExclam] = useState();
  const [currentPageCloudConfig, setCurrentPageCloudConfig] = useState(1);
  const [fieldError, setFieldError] = useState(false);
  const [errorz, setErrorz] = useState({});
  const styles = CreateCustomerStyles();
  const style = manageStyles();
  const devStyles = listStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [orgID] = useState(props.match.params._id);
  const [organizationData, setOrganizationData] = useState({
    cloudconfigurations: [],
  });
  const [address, setAddress] = useState("");
  const [LogoName, setLogoName] = useState("");
  const [Logo, setLogo] = useState("");
  const [faviconName, setFaviconName] = useState("");
  const [favicon, setFavicon] = useState("");
  const [uploadLogoModal, setUploadLogoModal] = useState(false);
  const [uploadFaviconModal, setUploadFaviconModal] = useState(false);

  const [cloudConfigModal, setCloudConfigModal] = useState(false);
  const [isCloudConfigurationEditMode, setIsCloudConfigurationEditMode] =
    useState(false);
  const [selectedData, setSelectedData] = useState();
  const [inactivityError, setInactivityError] = useState();
  const [updateFound, setUpdateFound] = useState();

  const { change_logo, userInfo, update_inactivity_time } = props;
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const sectionArr = [
    "Organization Details",
    "Logo",
    "Settings",
    "Cloud Configuration",
  ];

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const orgErrors = {
      name: {
        isError: false,
        section: 0,
        minLength: 1,
        maxLength: 300,
        dataType: "string",
        fieldLabel: "Organization Name",
      },
      contactNumber: {
        isError: false,
        section: 0,
        dataType: "string",
        minLength: 1,
        fieldLabel: "Phone Number",
      },
    };
    setErrorz(orgErrors);
  }, []);

  useEffect(() => {
    const getOntology = async () => {
      const { _msg, _status, data } = await getOntologyResource();
      if (_status === 200) {
        setUpdateFound(data);
      }
    };
    getOntology();
  }, []);

  useEffect(() => {
    featchAllCloudConfigurationActions(currentPageCloudConfig);
  }, [currentPageCloudConfig]);

  const [EditOrgdata, setEditOrgData] = useState({
    name: "",
    contactNumber: "",
    description: "",
    address: "",
    street: "",
    city: "",
    state: "",
    country: "",
    title: "",
  });

  const [cloudConfigRows, setCloudConfigRows] = useState([]);
  const [AWSRegionList, setAWSRegionList] = useState();

  useEffect(() => {
    const getRegionList = async () => {
      const { data, _msg, _status } = await getAWSRegionList();
      if (_status === 200) {
        setAWSRegionList(data);
      }
    };
    getRegionList();
  }, []);

  useEffect(() => {
    setCloudConfigRows(
      data && data?.length > 0
        ? data.map((item) => {
            item["AWSRegionList"] = AWSRegionList;
            return item;
          })
        : []
    );
  }, [AWSRegionList, data]);

  useEffect(() => {
    const getRegionList = async () => {
      const { data, _msg, _status } = await getAWSRegionList();
      if (_status === 200) {
        setAWSRegionList(data);
      }
    };
    getRegionList();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (event.target.name === "containerinactivity") {
      setInactivityError(false);
    }

    setOrganizationData({
      ...organizationData,
      [event.target.name]: event.target.value,
    });
    setEditOrgData({
      ...EditOrgdata,
      [event.target.name]: value,
    });
    if (Object.keys(errorz).includes(event.target.name)) {
      setErrorz({
        ...errorz,
        [event.target.name]: {
          ...errorz[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
  };

  const handleDeleteConfigRow = (configurationname) => {
    let cloudConfigurationsData = organizationData?.cloudconfigurations.filter(
      (item) => item.configurationname != configurationname
    );
    handleSubmitCloudData(cloudConfigurationsData, "delete");
  };

  const handleSubmitCloudData = async (cloudConfigData, action, id) => {
    setIsCloudConfigurationEditMode(false);

    setLoading(true);

    if (action === "add") {
      const { data, _status, _msg } = await createCloudConfig(cloudConfigData);
      if (_status === 200) {
        setLoading(false);
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        setCloudConfigModal(false);
        setSelectedData(null);
        featchAllCloudConfigurationActions(currentPageCloudConfig);
      } else {
        enqueueSnackbar(_msg, {
          variant: "error",
        });
        setLoading(false);
      }
    }

    if (action === "update") {
      const { data, _status, _msg } = await editCloudConfig(
        id,
        cloudConfigData
      );
      if (_status === 200) {
      } else {
        enqueueSnackbar(_msg, {
          variant: "error",
        });
      }
    }
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Organization", ["Edit"]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchCurrentORGDetails();
      // setEditOrgData((f) => ({ ...f, data: data }));
      // if (data.address === undefined) {
      //   setAddress(data.address);
      // }

      if (_status === 200) {
        // setOrganizationData(data);
        setOrganizationData(() => (data ? data : []));
        let myArr = data.address.split(",");
        setEditOrgData({
          // ...data,
          ...EditOrgdata,
          containerinactivity: data?.containerinactivity,
          name: data?.name,
          contactNumber: data?.contactNumber,
          description: data?.description,
          // address: data.address != undefined ? data.address : "",
          // address: data?.address,
          address: data?.address,
          street: myArr[0],
          city: myArr[1],
          state: myArr[2],
          country: myArr[3],
          title: data?.title,
        });
        setAddress(data ? data?.address : "");
        if (data?.logo?.length > 0) {
          setUploadLogoModal(true);
        }
        if (data?.favicon?.length > 0) {
          setUploadFaviconModal(true);
        }
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchData();
  }, [orgID]);

  let myArr = address.split(",");

  useEffect(() => {
    Object.entries(errorz).forEach(([key, value]) => {
      if (value["isError"]) {
        setExclam(true);
      } else {
        setExclam(false);
      }
    });
  }, [errorz]);

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      EditOrgdata,
      errorz
    );

    if (formIsValid) {
      setLoading(true);

      let FullAddress = `${EditOrgdata.street},${EditOrgdata.city},${EditOrgdata.state},${EditOrgdata.country}`;

      // setEditOrgData({ ...EditOrgdata, address: FullAddress });

      let inactivityValidation;
      if (
        EditOrgdata?.containerinactivity >= 1 &&
        EditOrgdata?.containerinactivity < 1000
      ) {
        inactivityValidation = true;
      } else {
        inactivityValidation = false;
        setInactivityError(true);
        setActiveTab(2);
      }
      const req_body = {
        name: EditOrgdata.name ? EditOrgdata.name : organizationData.name,
        description: EditOrgdata.description
          ? EditOrgdata.description
          : organizationData.description,
        industry: EditOrgdata.industry
          ? EditOrgdata.industry
          : organizationData.industry,
        //
        contactNumber: EditOrgdata.contactNumber
          ? EditOrgdata.contactNumber
          : organizationData.contactNumber,
        address: FullAddress,
        //
        title: EditOrgdata.title ? EditOrgdata.title : organizationData.title,
        logo: Logo ? Logo : uploadLogoModal ? organizationData.logo : "",
        favicon: favicon
          ? favicon
          : uploadFaviconModal
          ? organizationData.favicon
          : "",
        containerinactivity: EditOrgdata?.containerinactivity
          ? EditOrgdata?.containerinactivity
          : 15,
      };
      inactivityValidation;
      setLoading(false);

      if (inactivityValidation) {
        const { _msg, _status, data } = await EditCurrentORGDetails(req_body);
        if (_status === 200) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "success",
          });

          setLoading(false);
          change_logo(req_body.logo, req_body.favicon, req_body.title);
          update_inactivity_time(req_body?.containerinactivity);
          histroy.push("/organization-deatils");
        } else {
          setLoading(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
          }
        }
      }
    } else {
      const { errorInTab } = TabsCommon([], errors, tabbedView);
      setErrorz(errors);

      if (
        (EditOrgdata?.containerinactivity < 1 &&
          EditOrgdata?.containerinactivity >= 1000) ||
        EditOrgdata?.containerinactivity === ""
      ) {
        setInactivityError(true);
        setActiveTab(2);
      }
      setActiveTab(errorInTab);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  if (errorz?.contactNumber?.isError) {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }
  if (inactivityError) {
    ref1.current?.scrollIntoView({ behavior: "smooth" });
  }

  const handleUploadIcon = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setLogoName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogo(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteLogoClick = () => {
    setLogo("");
    setLogoName("");
  };
  const handleDeleteFaviconClick = () => {
    setFavicon("");
    setFaviconName("");
  };
  const handleUploadFavicon = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setFaviconName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setFavicon(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };
  const rejectedfile = () => {
    enqueueSnackbar("Maximum file size allowed 1MB", {
      variant: "error",
    });
  };

  const handleEditCloudConfig = () => {
    setCloudConfigModal(true);
  };

  const handleUpdate = async () => {
    const { _msg, _status, data } = await updateOntologyResource();
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "info" });
      setUpdateFound("");
    } else if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      setUpdateFound("");
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please contact help desk",
        { variant: "error" }
      );
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(cloudConfigModal || isCloudConfigurationEditMode) && (
        <CloudConfigurationModal
          openModal={cloudConfigModal}
          handleSubmitCloudData={handleSubmitCloudData}
          setOpenModal={() => {
            setCloudConfigModal(false);
            setIsCloudConfigurationEditMode(false);
            setSelectedData(null);
          }}
          isEditMode={isCloudConfigurationEditMode}
          cloudData={selectedData}
          allConfigData={
            organizationData?.cloudconfigurations
              ? organizationData?.cloudconfigurations
              : []
          }
          AWSRegionList={AWSRegionList}
        />
      )}
      <div className={tabbedView ? styles.topContent2 : styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Organizations", path: "/organization-deatils" },
            {
              title: organizationData.name
                ? `Edit ${organizationData.name}`
                : "",
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h2 className={styles.title}>
            {organizationData.name ? `Edit ${organizationData.name}` : ""}
          </h2>
          {tabbedView ? (
            <TabbedNavigation
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={() => histroy.push("organization-deatils")}
            />
          ) : (
            <AppButton
              color="primary"
              buttonName="Save"
              variant="contained"
              disabled={false}
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleSubmit}
            />
          )}
        </Grid>
        <div className={styles.border_line} />
      </div>

      {tabbedView ? (
        <div className={styles.wrapper2} style={{ marginTop: 10 }}>
          <>
            <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              indicatorColor="primary"
              textColor="primary"
              className={styles.documentsListAccessTab}
              variant={"scrollable"}
              style={{ top: 0 }}
            >
              <Tab
                label={
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {`${sectionArr[0]} `}
                    {/* {sectionError} */}
                    {/* {fieldError && (
                      <PriorityHighIcon
                        style={{
                          color: "#E22626",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      />
                    )} */}
                    {exclam && (
                      <PriorityHighIcon
                        style={{
                          color: "#E22626",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      />
                    )}
                    {/* {Object.entries(errorz).forEach(([key, value]) => {
                      value["isError"] ? <h1>Hi</h1> : <h3>Bye</h3>;
                    })} */}
                  </div>
                }
              />
              <Tab label={sectionArr[1]} />
              <Tab
                label={
                  <div>
                    {sectionArr[2]}{" "}
                    {inactivityError ? (
                      <PriorityHighIcon
                        style={{
                          color: "#E22626",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                }
              />
              <Tab label={sectionArr[3]} />
            </Tabs>
            {/* <SectionTabs
                entity={

                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              /> */}

            <TabPanel value={activeTab} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Organization Name <span className={styles.red}>*</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className={` ${styles.input}  ${
                          errorz?.name?.isError && styles.error
                        }`}
                        value={organizationData.name}
                        onChange={handleChange}
                      />

                      {errorz?.name?.isError && (
                        <p className={styles.errorMsg}>{errorz?.name?.msg}</p>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>
                        Username <span className={styles.red}>*</span>
                      </label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={organizationData.username}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Industry</label>
                      <input
                        name="industry"
                        type="text"
                        className={styles.input}
                        value={organizationData.industry}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Created By</label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={organizationData.createdby}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Created on</label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={
                          userInfo?.timeZone
                            ? moment
                                .unix(organizationData.createdon)
                                .tz(userInfo.timeZone)
                                .format(
                                  userInfo.time === "h:mm"
                                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                    : `${userInfo.dateFormat} ${userInfo.time}`
                                )
                            : moment
                                .unix(organizationData.createdon)
                                .format("DD/MM/YYYY, hh:mm A")
                        }
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className={styles.fieldHeading}>Updated By</label>
                      <input
                        type="text"
                        className={styles.input2}
                        value={organizationData.updatedby}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <label htmlFor="" className={styles.fieldHeading}>
                        Updated On
                      </label>
                      <input
                        value={
                          userInfo?.timeZone
                            ? moment
                                .unix(organizationData.updatedon)
                                .tz(userInfo.timeZone)
                                .format(
                                  userInfo.time === "h:mm"
                                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                    : `${userInfo.dateFormat} ${userInfo.time}`
                                )
                            : moment
                                .unix(organizationData.updatedon)
                                .format("DD/MM/YYYY, hh:mm A")
                        }
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <label className={styles.fieldHeading}>Description</label>
                      <textarea
                        name="description"
                        value={organizationData.description}
                        className={styles.textarea}
                        onChange={handleChange}
                        rows="8"
                      ></textarea>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div style={{ marginTop: 15 }}>
                <Accordion className={styles.fieldPanel} defaultExpanded>
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.fieldHeading2}>
                      Contact details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} style={{ marginTop: 15 }}>
                      <Grid item xs={9}>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <label className={styles.fieldHeading}>
                              Email <span className={styles.red}>*</span>
                            </label>
                            <input
                              value={organizationData.email}
                              type="text"
                              className={styles.input2}
                              autoComplete="off"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <label className={styles.fieldHeading}>
                              Phone Number <span className={styles.red}>*</span>
                            </label>
                            <input
                              name="contactNumber"
                              value={organizationData.contactNumber}
                              type="tel"
                              maxLength="10"
                              className={`${styles.input} ${
                                errorz?.contactNumber?.isError && style.error
                              } `}
                              onChange={handleChange}
                            />

                            {errorz?.contactNumber?.isError && (
                              <p className={styles.errorMsg}>
                                Phone Number is Required
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>Street</label>
                        <input
                          defaultValue={myArr[0] ? myArr[0] : ""}
                          name="street"
                          type="text"
                          className={styles.input}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>City</label>
                        <input
                          defaultValue={myArr[1] ? myArr[1] : ""}
                          name="city"
                          type="text"
                          className={styles.input}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>State</label>
                        <input
                          defaultValue={myArr[2] ? myArr[2] : ""}
                          name="state"
                          type="text"
                          className={styles.input}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <label className={styles.fieldHeading}>Country</label>
                        <input
                          defaultValue={myArr[3] ? myArr[3] : ""}
                          name="country"
                          type="text"
                          className={styles.input}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Grid
                container
                spacing={3}
                style={{ marginTop: 10, marginBottom: 70 }}
              >
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>Page Title</label>
                  <input
                    name="title"
                    type="text"
                    className={styles.input}
                    value={organizationData.title}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <div>
                    <label className={styles.fieldHeading}>Upload Logo</label>
                  </div>
                  {/* <div className={styles.iconwrapper} style={{ marginTop: 20 }}>
                    {organizationData.logo && (
                      <img
                        variant="square"
                        src={organizationData.logo}
                        alt=""
                        style={{ maxHeight: 100, maxWidth: 100 }}
                      />
                    )}
                  </div> */}
                  {uploadLogoModal ? (
                    <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: 200,
                        }}
                      >
                        <div>
                          <img
                            variant="square"
                            src={organizationData.logo}
                            alt=""
                            style={{ maxHeight: 100, maxWidth: 100 }}
                          />
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          <img
                            src="/images/container/delete.svg"
                            alt="delete"
                            onClick={() => setUploadLogoModal(false)}
                          />
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <div>
                      <div className={styles.documentDropperWrapper2}>
                        <DropzoneArea
                          Icon={customdropzoneImage}
                          acceptedFiles={["image/*"]}
                          className={styles.documentDropperClass}
                          showPreviews={false}
                          filesLimit={1}
                          dropzoneClass="documentDropperClass"
                          dropzoneParagraphClass="documentDropperTextClass"
                          get
                          maxFileSize={1000000}
                          showAlerts={false}
                          onDropRejected={rejectedfile}
                          showPreviewsInDropzone={false}
                          onChange={handleUploadIcon}
                        />
                        <p
                          className={styles.fieldHeading}
                          style={{ width: "17rem" }}
                        >{`Upload jpg, png or svg with transparent background of size max 1MB`}</p>
                        <pre
                          className={styles.fieldHeading}
                        >{`Resolution should be :
Square : 500X500px To 2000X2000px
Rectangular : Height(Min) 128Px Width (Max) 2000Px`}</pre>
                      </div>
                    </div>
                  )}
                  {Logo && Logo.length > 0 && (
                    <div
                      className={styles.doc_container}
                      style={{
                        marginTop: 25,
                        width: 350,
                        paddingBottom: 50,
                        // border: "1px solid",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Avatar src={Logo} alt=" " />
                      </div>
                      <div>
                        <input
                          className={styles.value}
                          value={LogoName}
                          placeholder="Enter Image Name"
                        ></input>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                        }}
                        onClick={handleDeleteLogoClick}
                      >
                        <img
                          src="/images/deleteLine.svg"
                          height={18}
                          width={18}
                          color="#000000"
                          alt="icon"
                        />
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <label className={styles.fieldHeading}>
                      Upload Favicon
                    </label>
                  </div>
                  {uploadFaviconModal ? (
                    <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: 200,
                        }}
                      >
                        <div>
                          <img
                            variant="square"
                            src={organizationData.favicon}
                            alt=""
                            style={{ maxHeight: 100, maxWidth: 100 }}
                          />
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          <img
                            src="/images/container/delete.svg"
                            alt="delete"
                            onClick={() => setUploadFaviconModal(false)}
                          />
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <div>
                      <div className={styles.documentDropperWrapper2}>
                        <DropzoneArea
                          Icon={customdropzoneImage}
                          acceptedFiles={["image/*"]}
                          className={styles.documentDropperClass}
                          showPreviews={false}
                          filesLimit={1}
                          dropzoneClass="documentDropperClass"
                          dropzoneParagraphClass="documentDropperTextClass"
                          get
                          maxFileSize={1000000}
                          showAlerts={false}
                          onDropRejected={rejectedfile}
                          showPreviewsInDropzone={false}
                          onChange={handleUploadFavicon}
                        />
                        <p
                        style={{ whiteSpace: "nowrap" , fontWeight: 400}}
                          className={styles.fieldHeading}
                        >{`Allowed jpg, png or svg of size max 1MB`}</p>
                      </div>
                    </div>
                  )}
                  {favicon && favicon.length > 0 && (
                    <div
                      className={styles.doc_container}
                      style={{
                        marginTop: 25,
                        width: 350,
                        paddingBottom: 50,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Avatar src={favicon} alt=" " />
                      </div>
                      <div>
                        <input
                          className={styles.value}
                          value={faviconName}
                          placeholder="Enter Image Name"
                        ></input>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                        }}
                        onClick={handleDeleteFaviconClick}
                      >
                        <img
                          src="/images/deleteLine.svg"
                          height={18}
                          width={18}
                          color="#000000"
                          alt="icon"
                        />
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} style={{ paddingTop: "23px" }}>
                      <label className={styles.fieldHeading}>Ontology</label>
                    </Grid>
                    <div
                      className={classes.button}
                      style={{ paddingTop: "20px" }}
                    >
                      {updateFound === "updates found" ? (
                        <Tooltip title="Download Available" arrow>
                          <Button
                            variant="contained"
                            className="btnsmall"
                            onClick={handleUpdate}
                          >
                            Update
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Download Available" arrow>
                          <Button
                            variant="contained"
                            className="btnsmall"
                            onClick={handleUpdate}
                            disabled={true}
                          >
                            Update
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={4} style={{ marginTop: "60px" }}>
                  <label className={styles.fieldHeading}>
                    Inactivity Period
                  </label>
                  <Grid
                    xs={12}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <input
                      name="containerinactivity"
                      type="number"
                      maxLength={2}
                      className={`${styles.input} ${
                        inactivityError && style.error
                      }`}
                      value={organizationData?.containerinactivity}
                      onChange={handleChange}
                    />
                    <p>&nbsp;&nbsp;mins</p>
                  </Grid>
                  {inactivityError && (
                    <p style={{ marginTop: "-2px" }} className={style.errorMsg}>
                      Inactivity must be between 1 minutes and 999 minutes
                    </p>
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={activeTab} index={3}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ margin: "10px 0 10px" }}
              >
                <AppButton
                  color="primary"
                  buttonName="Add Cloud Config"
                  variant="contained"
                  startIcon={<AddIcon />}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={() => setCloudConfigModal(true)}
                />
              </Grid>
              <div style={{ marginTop: 10, marginBottom: 20 }}>
                <CloudConfigDevExpress
                  columns={cloudConfigColumns}
                  rows={cloudConfigRows}
                  defaultColumnWidths={cloudConfigDefaultColumnWidth}
                  tableColumnExtensions={cloudConfigTableColumnExtensions}
                  columnOrder={cloudConfigColumnOrder}
                  loading={loading}
                  currentPage={currentPageCloudConfig}
                  setCurrentPage={setCurrentPageCloudConfig}
                  isCustomHeight={true}
                  // sort={testExecutionSort}
                  // windowDimensions={windowDimensions}
                  // setSort={addExecutionSort}
                  hidden={cloudConfigColumnHidden}
                  // setHidden={addServiceHidden}
                  noDataMsg="No Cloud Configuration Found"
                  handleEditCloudConfig={handleEditCloudConfig}
                  handleDeleteConfigRow={handleDeleteConfigRow}
                  setSelectedData={(row) => setSelectedData(row)}
                  setShowEditConfig={(e) => setIsCloudConfigurationEditMode(e)}
                  isEditMode={true}
                />
                <div
                  className={devStyles.customPagination}
                  style={{ paddingTop: "1rem" }}
                >
                  {cloudConfigRows.length !== 0 && (
                    <Pagination
                      count={Math.ceil(_totalcount / 10)}
                      page={currentPageCloudConfig}
                      onChange={(e, val) => setCurrentPageCloudConfig(val)}
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </div>
              </div>
            </TabPanel>
          </>
        </div>
      ) : (
        <>
          <div className={styles.wrapper3}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Organization Name <span className={styles.red}>*</span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      className={` ${styles.input}  ${
                        errorz?.name?.isError && styles.error
                      }`}
                      value={organizationData.name}
                      onChange={handleChange}
                    />

                    {errorz?.name?.isError && (
                      <p className={styles.errorMsg}>{errorz?.name?.msg}</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Username <span className={styles.red}>*</span>
                    </label>
                    <input
                      type="text"
                      className={styles.input2}
                      value={organizationData.username}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>Industry</label>
                    <input
                      name="industry"
                      type="text"
                      className={styles.input}
                      value={organizationData.industry}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>Created By</label>
                    <input
                      type="text"
                      className={styles.input2}
                      value={organizationData.createdby}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>Created on</label>
                    <input
                      type="text"
                      className={styles.input2}
                      value={
                        userInfo?.timeZone
                          ? moment
                              .unix(organizationData.createdon)
                              .tz(userInfo.timeZone)
                              .format(
                                userInfo.time === "h:mm"
                                  ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                  : `${userInfo.dateFormat} ${userInfo.time}`
                              )
                          : moment
                              .unix(organizationData.createdon)
                              .format("DD/MM/YYYY, hh:mm A")
                      }
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>Updated By</label>
                    <input
                      type="text"
                      className={styles.input2}
                      value={organizationData.updatedby}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Updated On
                    </label>
                    <input
                      value={
                        userInfo?.timeZone
                          ? moment
                              .unix(organizationData.updatedon)
                              .tz(userInfo.timeZone)
                              .format(
                                userInfo.time === "h:mm"
                                  ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                  : `${userInfo.dateFormat} ${userInfo.time}`
                              )
                          : moment
                              .unix(organizationData.updatedon)
                              .format("DD/MM/YYYY, hh:mm A")
                      }
                      type="text"
                      className={styles.input2}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <label className={styles.fieldHeading}>Description</label>
                    <textarea
                      name="description"
                      value={organizationData.description}
                      className={styles.textarea}
                      onChange={handleChange}
                      rows="8"
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>
              {/* <h4>Contact details</h4>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>
                    Email <span className={styles.red}>*</span>
                  </label>
                  <input
                    value={organizationData.email}
                    type="text"
                    className={styles.input2}
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>
                    Phone Number <span className={styles.red}>*</span>
                  </label>
                  <input
                    name="contactNumber"
                    defaultValue={organizationData.contactNumber}
                    type="tel"
                    maxLength="10"
                    className={`${styles.input} ${
                      errorz?.contactNumber?.isError && style.error
                    } `}
                    onChange={handleChange}
                  />

                  {errorz?.contactNumber?.isError && (
                    <p className={styles.errorMsg}>Phone Number is Required</p>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>Street</label>
                  <input
                    defaultValue={myArr[0] ? myArr[0] : ""}
                    name="street"
                    type="text"
                    className={styles.input}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>City</label>
                  <input
                    defaultValue={myArr[1] ? myArr[1] : ""}
                    name="city"
                    type="text"
                    className={styles.input}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>State</label>
                  <input
                    defaultValue={myArr[2] ? myArr[2] : ""}
                    name="state"
                    type="text"
                    className={styles.input}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className={styles.fieldHeading}>Country</label>
                  <input
                    defaultValue={myArr[3] ? myArr[3] : ""}
                    name="country"
                    type="text"
                    className={styles.input}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid> */}
            </Grid>
            {/* <div style={{ marginTop: 15 }}>
              <Accordion className={styles.fieldPanel} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownRounded className={styles.accordianIcon} />
                  }
                >
                  <Typography className={styles.fieldHeading2}>
                    Contact details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={3}
                    style={{ margin: "0 1.5px 0 1.5px" }}
                  >
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>
                        Email <span className={styles.red}>*</span>
                      </label>
                      <input
                        value={organizationData.email}
                        type="text"
                        className={styles.input2}
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>
                        Phone Number <span className={styles.red}>*</span>
                      </label>
                      <input
                        name="contactNumber"
                        value={organizationData.contactNumber}
                        type="tel"
                        maxLength="10"
                        className={`${styles.input} ${
                          errorz?.contactNumber?.isError && style.error
                        } `}
                        onChange={handleChange}
                      />

                      {errorz?.contactNumber?.isError && (
                        <p className={styles.errorMsg}>
                          Phone Number is Required
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>Street</label>
                      <input
                        defaultValue={myArr[0] ? myArr[0] : ""}
                        name="street"
                        type="text"
                        className={styles.input}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>City</label>
                      <input
                        defaultValue={myArr[1] ? myArr[1] : ""}
                        name="city"
                        type="text"
                        className={styles.input}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>State</label>
                      <input
                        defaultValue={myArr[2] ? myArr[2] : ""}
                        name="state"
                        type="text"
                        className={styles.input}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <label className={styles.fieldHeading}>Country</label>
                      <input
                        defaultValue={myArr[3] ? myArr[3] : ""}
                        name="country"
                        type="text"
                        className={styles.input}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </div>
          <div
            className={styles.wrapper}
            style={{ marginTop: 15, scrollMargin: "200px" }}
            ref={ref}
          >
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>
                  Contact details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} style={{ marginTop: 15 }}>
                  <Grid item xs={9}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Email <span className={styles.red}>*</span>
                        </label>
                        <input
                          value={organizationData.email}
                          type="text"
                          className={styles.input2}
                          autoComplete="off"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label className={styles.fieldHeading}>
                          Phone Number <span className={styles.red}>*</span>
                        </label>
                        <input
                          name="contactNumber"
                          value={organizationData.contactNumber}
                          type="tel"
                          maxLength="10"
                          className={`${styles.input} ${
                            errorz?.contactNumber?.isError && style.error
                          } `}
                          onChange={handleChange}
                        />

                        {errorz?.contactNumber?.isError && (
                          <p className={styles.errorMsg}>
                            Phone Number is Required
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>Street</label>
                    <input
                      defaultValue={myArr[0] ? myArr[0] : ""}
                      name="street"
                      type="text"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>City</label>
                    <input
                      defaultValue={myArr[1] ? myArr[1] : ""}
                      name="city"
                      type="text"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>State</label>
                    <input
                      defaultValue={myArr[2] ? myArr[2] : ""}
                      name="state"
                      type="text"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>Country</label>
                    <input
                      defaultValue={myArr[3] ? myArr[3] : ""}
                      name="country"
                      type="text"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={styles.wrapper} style={{ marginTop: 15 }}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>Logo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: 5, marginBottom: 70 }}
                >
                  <Grid item xs={3}>
                    <label className={styles.fieldHeading}>Page Title</label>
                    <input
                      name="title"
                      type="text"
                      className={styles.input}
                      value={organizationData.title}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4}>
                    <div>
                      <label className={styles.fieldHeading}>Upload Logo</label>
                    </div>
                    {uploadLogoModal ? (
                      <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: 200,
                          }}
                        >
                          <div>
                            <img
                              variant="square"
                              src={organizationData.logo}
                              alt=""
                              style={{ maxHeight: 100, maxWidth: 100 }}
                            />
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <img
                              src="/images/container/delete.svg"
                              alt="delete"
                              onClick={() => setUploadLogoModal(false)}
                            />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <div>
                        <div className={styles.documentDropperWrapper2}>
                          <DropzoneArea
                            Icon={customdropzoneImage}
                            acceptedFiles={["image/*"]}
                            className={styles.documentDropperClass}
                            showPreviews={false}
                            filesLimit={1}
                            dropzoneClass="documentDropperClass"
                            dropzoneParagraphClass="documentDropperTextClass"
                            get
                            maxFileSize={1000000}
                            showAlerts={false}
                            onDropRejected={rejectedfile}
                            showPreviewsInDropzone={false}
                            onChange={handleUploadIcon}
                          />
                          <p
                            className={styles.fieldHeading}
                            style={{ width: "17rem" }}
                          >{`Upload jpg, png or svg with transparent background of size max 1MB`}</p>
                          <pre
                            className={styles.fieldHeading}
                          >{`Resolution should be :
Square : 500X500px To 2000X2000px
Rectangular : Height(Min) 128Px Width (Max) 2000Px`}</pre>
                        </div>
                      </div>
                    )}
                    {Logo && Logo.length > 0 && (
                      <div
                        className={styles.doc_container}
                        style={{
                          marginTop: 25,
                          width: 350,
                          paddingBottom: 50,
                          // border: "1px solid",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Avatar src={Logo} alt=" " />
                        </div>
                        <div>
                          <input
                            className={styles.value}
                            value={LogoName}
                            placeholder="Enter Image Name"
                          ></input>
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                          }}
                          onClick={handleDeleteLogoClick}
                        >
                          <img
                            src="/images/deleteLine.svg"
                            height={18}
                            width={18}
                            color="#000000"
                            alt="icon"
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <label className={styles.fieldHeading}>
                        Upload Favicon
                      </label>
                    </div>
                    {uploadFaviconModal ? (
                      <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: 200,
                          }}
                        >
                          <div>
                            <img
                              variant="square"
                              src={organizationData.favicon}
                              alt=""
                              style={{ maxHeight: 100, maxWidth: 100 }}
                            />
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <img
                              src="/images/container/delete.svg"
                              alt="delete"
                              onClick={() => setUploadFaviconModal(false)}
                            />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <div>
                        <div className={styles.documentDropperWrapper2}>
                          <DropzoneArea
                            Icon={customdropzoneImage}
                            acceptedFiles={["image/*"]}
                            className={styles.documentDropperClass}
                            showPreviews={false}
                            filesLimit={1}
                            dropzoneClass="documentDropperClass"
                            dropzoneParagraphClass="documentDropperTextClass"
                            get
                            maxFileSize={1000000}
                            showAlerts={false}
                            onDropRejected={rejectedfile}
                            showPreviewsInDropzone={false}
                            onChange={handleUploadFavicon}
                          />
                          <p
                          style={{ whiteSpace: "nowrap", fontWeight: 400 }}
                            className={styles.fieldHeading}
                          >{`Allowed jpg, png or svg of size max 1MB`}</p>
                        </div>
                      </div>
                    )}
                    {favicon && favicon.length > 0 && (
                      <div
                        className={styles.doc_container}
                        style={{
                          marginTop: 25,
                          width: 350,
                          paddingBottom: 50,
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Avatar src={favicon} alt=" " />
                        </div>
                        <div>
                          <input
                            className={styles.value}
                            value={faviconName}
                            placeholder="Enter Image Name"
                          ></input>
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                          }}
                          onClick={handleDeleteFaviconClick}
                        >
                          <img
                            src="/images/deleteLine.svg"
                            height={18}
                            width={18}
                            color="#000000"
                            alt="icon"
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            className={styles.wrapper}
            ref={ref1}
            style={{ scrollMargin: "200px" }}
          >
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>
                  Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={4} style={{ paddingTop: "23px" }}>
                          <label className={styles.fieldHeading}>
                            Ontology
                          </label>
                        </Grid>
                        <div
                          className={classes.button}
                          style={{ paddingTop: "20px" }}
                        >
                          {updateFound === "updates found" ? (
                            <Tooltip title="Download Available" arrow>
                              <Button
                                variant="contained"
                                className="btnsmall"
                                onClick={handleUpdate}
                              >
                                Update
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Download Available" arrow>
                              <Button
                                variant="contained"
                                className="btnsmall"
                                onClick={handleUpdate}
                                disabled={true}
                              >
                                Update
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 10, marginBottom: 70 }}
                      >
                        <Grid item xs={4} style={{ marginTop: "60px" }}>
                          <label className={styles.fieldHeading}>
                            Inactivity Period
                          </label>
                          <Grid
                            xs={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <input
                              name="containerinactivity"
                              type="number"
                              maxLength={2}
                              className={`${styles.input} ${
                                inactivityError && style.error
                              }`}
                              value={organizationData?.containerinactivity}
                              onChange={handleChange}
                            />
                            <p>&nbsp;&nbsp;mins</p>
                          </Grid>
                          {inactivityError && (
                            <p
                              style={{ marginTop: "-2px" }}
                              className={style.errorMsg}
                            >
                              Inactivity must be between 1 minutes and 999
                              minutes
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={styles.wrapper}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>
                  Cloud Configurations
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{ margin: "10px 0 10px" }}
                >
                  <AppButton
                    color="primary"
                    buttonName="Add Cloud Config"
                    variant="contained"
                    startIcon={<AddIcon />}
                    style={{ margin: "unset" }}
                    className="btnsmall"
                    onClick={() => setCloudConfigModal(true)}
                  />
                </Grid>

                <div style={{ marginTop: 10, marginBottom: 20 }}>
                  <CloudConfigDevExpress
                    columns={cloudConfigColumns}
                    rows={cloudConfigRows}
                    defaultColumnWidths={cloudConfigDefaultColumnWidth}
                    tableColumnExtensions={cloudConfigTableColumnExtensions}
                    columnOrder={cloudConfigColumnOrder}
                    loading={loading}
                    currentPage={currentPageCloudConfig}
                    setCurrentPage={setCurrentPageCloudConfig}
                    isCustomHeight={true}
                    // sort={testExecutionSort}
                    // windowDimensions={windowDimensions}
                    // setSort={addExecutionSort}
                    hidden={cloudConfigColumnHidden}
                    // setHidden={addServiceHidden}
                    noDataMsg="No Cloud Configuration Found"
                    handleEditCloudConfig={handleEditCloudConfig}
                    handleDeleteConfigRow={handleDeleteConfigRow}
                    setSelectedData={(row) => setSelectedData(row)}
                    setShowEditConfig={(e) =>
                      setIsCloudConfigurationEditMode(e)
                    }
                    isEditMode={true}
                  />

                  <div
                    className={devStyles.customPagination}
                    style={{ paddingTop: "1rem" }}
                  >
                    {cloudConfigRows.length !== 0 && (
                      <Pagination
                        count={Math.ceil(_totalcount / 10)}
                        page={currentPageCloudConfig}
                        onChange={(e, val) => setCurrentPageCloudConfig(val)}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  change_logo,
  featchAllCloudConfigurationActions,
  update_inactivity_time,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  const { data, loading, _totalcount } =
    state?.organizationReducer?.cloudConfigList;
  return {
    userInfo,
    data,
    loading,
    _totalcount,
    tabbedView,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganizationScreen);
