import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TriggerAction from "../../../atoms/rightSide/trigger/TriggerAction";
import ShowCondition from "../../../atoms/rightSide/trigger/ShowCondition";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";
import CustomAutocomplete from "../../../atoms/autocomplete/CustomAutocomplete";
import { UiApplicationService } from "../../../../services/UiApplicationService";

const useStyles = makeStyles((theme) => ({
  label: {
    font: "normal normal 600 12px/16px Nunito",
    color: "#333333DE",
    textTransform: "capitalize",
  },
}));

function TableTriggers() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const apiService = new UiApplicationService();
  const { currentProject, currentPage, currentComponent } = useGetData();
  const [triggerOption, setTriggerOption] = useState([]);

  const onChange = (name, action) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: action ? action : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const getTriggerOption = async () => {
    let optionArray = [];
    const resp = await apiService.fetchAllComponentNames(currentProject?._id);
    resp?.data?.data?.[0]?.pages.forEach((page) => {
      page?.components?.forEach((comp) => {
        optionArray.push(comp);
      });
    });
    setTriggerOption(optionArray);
  };

  const getComponentFromAllPages = (componentId) =>
    triggerOption?.find((comp) => comp?._id === componentId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getTriggerOption(), []);

  return (
    <div>
      <div className="field">
        <TriggerAction
          label="On Init"
          value={currentProject?.actions?.find(
            (action) => action?._id === currentComponent?.onInit
          )}
          onChange={(_e, action) => onChange("onInit", action?._id)}
        />
      </div>
      <div>
        <label className={classes.label}>On Add</label>

        <CustomAutocomplete
          options={triggerOption}
          value={{
            id: getComponentFromAllPages(currentComponent?.onAdd)?.id,
            name: getComponentFromAllPages(currentComponent?.onAdd)?.name,
          }}
          optionLabel={(option) => (option ? option?.name : "")}
          onChange={(input) => onChange("onAdd", input?._id)}
        />
      </div>
      <div>
        <label className={classes.label}>On Edit</label>
        <CustomAutocomplete
          options={triggerOption}
          value={{
            id: getComponentFromAllPages(currentComponent?.onEdit)?.id,
            name: getComponentFromAllPages(currentComponent?.onEdit)?.name,
          }}
          optionLabel={(option) => (option ? option?.name : "")}
          onChange={(input) => onChange("onEdit", input?._id)}
        />
      </div>
      <div className="field">
        <TriggerAction
          label="On Delete"
          value={currentProject?.actions.find(
            (action) => action?._id === currentComponent?.onDelete
          )}
          onChange={(_e, action) => onChange("onDelete", action?._id)}
        />
      </div>
      <div>
        <label className={classes.label}>On RowSelect</label>
        <CustomAutocomplete
          options={triggerOption}
          value={{
            id: getComponentFromAllPages(currentComponent?.onRowSelect)?.id,
            name: getComponentFromAllPages(currentComponent?.onRowSelect)?.name,
          }}
          optionLabel={(option) => (option ? option?.name : "")}
          onChange={(input) => onChange("onRowSelect", input?._id)}
        />
      </div>
      <div style={{ borderTop: "1px solid #F0F0F0", padding: 0 }}>
        <ShowCondition />
      </div>
    </div>
  );
}

export default TableTriggers;
