import {
  Checkbox,
  ListItemText,
  Grid,
  Select,
  MenuItem,
  Input,
  Backdrop,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CustomChips from '../../../components/common/Chips'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import FieldRules from '../../../components/Ontology/FieldRules'
import { AppButton } from '../../../components/common/Button'
import { fetchFieldByID_, editFieldByID_ } from '../../../utils/ontology/ontologyUtils'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { useHistory, useLocation } from 'react-router-dom'
import useCheckPermission from '../../../components/common/hooks/useCheckPermission'

export default function FieldDetailsScreen(props) {
  const [mode, setMode] = useState('details')
  const [fieldData, setFieldData] = useState({
    field_name: '',
    field_label: '',
    field_description: '',
    default_value: '',
    error_message: '',
    placeholder: '',
    required: 'TRUE',
    editable: 'TRUE',
    ui_element_type: 'TextField',
    data_type: 'string',
    current_value: '',
  })
  const [values, setValues] = useState([])
  const [error, setError] = useState({
    field_name: false,
    field_label: false,
    required: false,
    editable: false,
    ui_element_type: false,
    data_type: false,
    accessPermission: false,
  })

  const [valuesError, setValuesError] = useState({
    valueText: false,
    valuesLength: false,
  })
  const [rules, setRules] = useState({
    maxLength: null,
    minLength: null,
    pattern: null,
    contentEncoding: '',
    contentMediaType: '',
    minimum: null,
    maximum: null,
    multipleOf: null,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  const [selectedPermission, setSelectedPermission] = useState([])
  const [permissionList, setpermissionList] = useState(['create', 'read', 'edit', 'delete'])
  const [predefined, setPredefined] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const fieldID = props.match.params.fieldID

  const { checkCompPermission } = useCheckPermission()

  const styles = manageStyles()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // const { source } = location.state;
    if (location?.state?.source === 'edit_icon') {
      setMode('edit')
    } else {
      setMode('details')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldID])

  useEffect(() => {
    const fetchFieldData = async () => {
      setLoading(true)
      const { _msg, _status, data } = await fetchFieldByID_(fieldID)
      if (_status === 200) {
        setLoading(false)
        const {
          dataType,
          defaultValue,
          description,
          editable,
          errorMessage,
          fieldLabel,
          name,
          placeholder,
          predefined,
          required,
          rules,
          uiElementType,
          values,
          accessPermission,
        } = data
        setFieldData((prevState) => ({
          ...prevState,
          field_name: name,
          field_label: fieldLabel,
          field_description: description,
          default_value: defaultValue,
          error_message: errorMessage,
          placeholder: placeholder,
          required: required,
          editable: editable,
          ui_element_type: uiElementType,
          data_type: dataType,
        }))
        if (typeof values === 'string' && values !== '') {
          var newValues = JSON.parse(values)
          var newValues_ =
            newValues && newValues?.length > 0
              ? newValues?.map((item) => ({
                  _id: uuidv4(),
                  name: item,
                }))
              : []
          setValues(newValues_)
        } else {
          var nvalues =
            values && values?.length > 0
              ? values?.map((item) => ({
                  _id: uuidv4(),
                  name: item,
                }))
              : []
          setValues(nvalues)
        }
        setRules((prevState) => ({
          ...prevState,
          ...rules,
        }))
        var accessArr = accessPermission?.split(',')
        accessArr = accessArr?.filter((item) => item !== '_')
        setSelectedPermission(accessArr ? accessArr : [])
        setPredefined(() => (predefined === 'TRUE' ? true : false))
      } else {
        setLoading(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }
    fetchFieldData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = (key, value) => {
    setFieldData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (Object.keys(error).includes(key)) {
      setError((prevState) => ({
        ...prevState,
        [key]: false,
      }))
    }
    if (key === 'current_value') {
      setValuesError((prevState) => ({
        ...prevState,
        valueText: false,
      }))
    }
  }

  const handleRulesChange = (key, value) => {
    setRules((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleClearAllClick_ = (e) => {
    e.preventDefault()
    var tempObj = {}
    for (var obj in rules) {
      tempObj[obj] = ''
    }
    setRules((prevState) => ({
      ...prevState,
      ...tempObj,
    }))
  }

  const handleChipDelete_ = (data_) => {
    const newChips = values.length > 0 ? values.filter((item) => item._id !== data_._id) : []
    setValues(newChips)
  }

  const handleAddNewValue = (e) => {
    e.preventDefault()
    if (mode === 'edit' && !predefined) {
      const duplicateValue = values.filter((item) => item.name === fieldData.current_value)
      if (fieldData.current_value !== '' && duplicateValue.length === 0 && mode === 'edit') {
        setValues((prevState) => [
          ...prevState,
          {
            _id: uuidv4(),
            name: fieldData.current_value,
          },
        ])
        setFieldData((prevState) => ({
          ...prevState,
          current_value: '',
        }))
      } else if (fieldData.current_value === '') {
        setValuesError((prevState) => ({ ...prevState, valueText: true }))
      }
    }
  }

  const handleChangePermission = (e) => {
    //
    setError((prevState) => ({
      ...prevState,
      accessPermission: false,
    }))
    setSelectedPermission(e.target.value)
  }

  const handleSave = async () => {
    var datakeyStr = fieldData?.field_name.toLowerCase()
    datakeyStr = datakeyStr.replace(/\s+/g, '_')
    var errorFlag = false
    var tempError = {}
    var rulesData = {}
    var permissionString = ''
    permissionList.forEach((perm_, index) => {
      if (selectedPermission.includes(perm_)) {
        permissionString = permissionString.concat(`${perm_}${index < 3 ? ',' : ''}`)
      } else {
        permissionString = permissionString.concat(`${'_'}${index < 3 ? ',' : ''}`)
      }
    })
    if (fieldData.data_type === 'string') {
      rulesData = {
        minLength: rules['minLength'],
        maxLength: rules['maxLength'],
        pattern: rules['pattern'],
        contentEncoding: rules['contentEncoding'],
        contentMediaType: rules['contentMediaType'],
      }
    } else if (fieldData.data_type === 'integer' || fieldData.data_type === 'number') {
      rulesData = {
        minimum: rules['minimum'],
        maximum: rules['maximum'],
        multipleOf: rules['multipleOf'],
        exclusiveMaximum: rules['exclusiveMaximum'],
        exclusiveMinimum: rules['exclusiveMinimum'],
      }
    }
    // console.log("rules", rulesData)
    if (predefined) {
      if (fieldData['field_label'] === '') {
        tempError = { field_label: true }
        errorFlag = true
      }
    } else {
      for (var obj in fieldData) {
        if (fieldData[obj] === '') {
          if (Object.keys(error).includes(obj)) {
            errorFlag = true
            tempError[obj] = true
          }
        }
      }
      if (selectedPermission.length === 0) {
        tempError['accessPermission'] = true
        errorFlag = true
      }
    }
    setError((prevState) => ({
      ...prevState,
      ...tempError,
    }))
    var req_data = {}
    if (!errorFlag) {
      setLoading(true)
      if (predefined) {
        req_data = {
          fieldLabel: fieldData.field_label,
          defaultValue: fieldData.default_value,
        }
      } else {
        req_data = {
          name: fieldData.field_name,
          fieldLabel: fieldData.field_label,
          dataType: fieldData.data_type,
          dataKey: datakeyStr,
          defaultValue: fieldData.default_value,
          description: fieldData.field_description,
          editable: fieldData.editable,
          errorMessage: fieldData.error_message,
          placeholder: fieldData.placeholder,
          required: fieldData.required,
          uiElementType: fieldData.ui_element_type,
          rules: rulesData,
          values:
            fieldData.field_name === 'Field Type'
              ? JSON.stringify(values.map((item) => item.name))
              : values.map((item) => item.name),
          type: fieldData.data_type,
          accessPermission: permissionString,
        }
      }
      if (fieldData.data_type === 'boolean') {
        delete req_data['rules']
      }
      //
      const { _msg, _status } = await editFieldByID_(fieldID, req_data)
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: 'success' })
        setLoading(false)
        setMode('details')
        // history.push("/ontology");
      } else {
        setLoading(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }
  }

  return (
    <main className={styles.main}>
      <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        {/* <Container maxWidth="xl"> */}
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: 'Ontology', path: '/ontology' },
              { title: 'Fields', path: '/ontology' },
              { title: `${fieldData.field_name}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>{fieldData.field_name}</h2>
            {mode === 'details' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  // startIcon={<img src="/images/edit.svg" alt="card" />}
                  disabled={!checkCompPermission('OntologyField', ['Edit'])}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={() => setMode('edit')}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
            {mode === 'edit' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  // startIcon={<img src="/images/edit.svg" alt="card" />}
                  disabled={false}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={handleSave}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid container spacing={3} style={{ marginTop: '2rem' }}>
            <Grid item xs={9} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Name<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`${styles.input} ${
                      (mode === 'details' || predefined) && styles.editMode
                    }`}
                    style={error.field_name ? { border: '1px solid red' } : {}}
                    value={fieldData.field_name}
                    onChange={(e) => handleTextChange('field_name', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details' || predefined}
                  />
                  {error.field_name && <text className={styles.errorMsg}>Invalid Field Name</text>}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Label<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.field_label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${mode === 'details' && styles.editMode}`}
                    value={fieldData.field_label}
                    onChange={(e) => handleTextChange('field_label', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details'}
                  />
                  {error.field_label && (
                    <text className={styles.errorMsg}>Invalid Field Label</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Default Value
                  </label>
                  <input
                    type="text"
                    name="serviceGroupId"
                    style={error.default_value ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${mode === 'details' && styles.editMode}`}
                    value={fieldData.default_value}
                    onChange={(e) => handleTextChange('default_value', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details'}
                  />
                  {error.default_value && (
                    <text className={styles.errorMsg}>Invalid Default Value</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Error Message
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.error_message ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${
                      (mode === 'details' || predefined) && styles.editMode
                    }`}
                    value={fieldData.error_message}
                    onChange={(e) => handleTextChange('error_message', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details' || predefined}
                  />
                  {error.error_message && (
                    <text className={styles.errorMsg}>Invalid Error Message</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Placeholder
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.placeholder ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${
                      (mode === 'details' || predefined) && styles.editMode
                    }`}
                    value={fieldData.placeholder}
                    onChange={(e) => handleTextChange('placeholder', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details' || predefined}
                  />
                  {error.placeholder && (
                    <text className={styles.errorMsg}>Invalid Placeholder</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    UI Element Type<span className={styles.red}>*</span>
                  </label>
                  <Select
                    style={error.ui_element_type ? { border: '1px solid red' } : {}}
                    displayEmpty
                    className={`${styles.select} ${
                      (mode === 'details' || predefined) && styles.editMode
                    }`}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={fieldData.ui_element_type}
                    onChange={(e) => handleTextChange('ui_element_type', e.target.value)}
                    disabled={mode === 'details' || predefined}
                  >
                    <MenuItem value={'TextField'}>{'TextField'}</MenuItem>
                    <MenuItem value={'TextArea'}>{'TextArea'}</MenuItem>
                    <MenuItem value={'Dropdown'}>{'Dropdown'}</MenuItem>
                    <MenuItem value={'RadioButton'}>{'RadioButton'}</MenuItem>
                    <MenuItem value={'Date'}>{'Date'}</MenuItem>
                    <MenuItem value={'Currency'}>{'Currency'}</MenuItem>
                    <MenuItem value={'Amount'}>{'Amount'}</MenuItem>
                  </Select>
                  {error.ui_element_type && (
                    <text className={styles.errorMsg}>Invalid UI Element Type</text>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="projectDescription" className={styles.fieldHeading}>
                Field Description
              </label>
              <textarea
                name="description"
                value={fieldData.field_description}
                style={
                  error.field_description
                    ? { border: '1px solid red', height: '7.8rem' }
                    : { height: '7.8rem' }
                }
                onChange={(e) => handleTextChange('field_description', e.target.value)}
                className={`${styles.textarea} ${
                  (mode === 'details' || predefined) && styles.editMode
                }`}
                rows="8"
                disabled={mode === 'details' || predefined}
              />
              {error.field_description && (
                <text className={styles.errorMsg}>Invalid Field Description</text>
              )}
            </Grid>
            <Grid item xs={6} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Required<span className={styles.red}>*</span>
              </label>
              <RadioGroup
                aria-label="required"
                name="required"
                value={fieldData.required}
                onChange={(e) =>
                  mode === 'edit' && !predefined && handleTextChange('required', e.target.value)
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel value="TRUE" control={<Radio color="primary" />} label="True" />
                <FormControlLabel value="FALSE" control={<Radio color="primary" />} label="False" />
              </RadioGroup>
              {error.required && <text className={styles.errorMsg}>Invalid Required Value</text>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Editable<span className={styles.red}>*</span>
              </label>
              <RadioGroup
                aria-label="Editable"
                name="editable"
                style={{ display: 'flex', flexDirection: 'row' }}
                value={fieldData.editable}
                onChange={(e) =>
                  mode === 'edit' && !predefined && handleTextChange('editable', e.target.value)
                }
              >
                <FormControlLabel value="TRUE" control={<Radio color="primary" />} label="True" />
                <FormControlLabel value="FALSE" control={<Radio color="primary" />} label="False" />
              </RadioGroup>
              {error.editable && <text className={styles.errorMsg}>Invalid Editable Value</text>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Data Type<span className={styles.red}>*</span>
              </label>
              <Select
                style={error.data_type ? { border: '1px solid red' } : {}}
                displayEmpty
                className={`${styles.select} ${
                  (mode === 'details' || predefined) && styles.editMode
                }`}
                inputProps={{ 'aria-label': 'Without label' }}
                value={fieldData.data_type}
                onChange={(e) => handleTextChange('data_type', e.target.value)}
                disabled={mode === 'details' || predefined}
              >
                <MenuItem value={'string'}>{'String'}</MenuItem>
                <MenuItem value={'boolean'}>{'Boolean'}</MenuItem>
                <MenuItem value={'integer'}>{'Integer'}</MenuItem>
                <MenuItem value={'number'}>{'Number'}</MenuItem>
              </Select>
              {error.data_type && <text className={styles.errorMsg}>Invalid Data Type</text>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <label className={styles.fieldHeading}>
                Access Permission<span className={styles.red}>*</span>
              </label>
              <Select
                className={`${styles.select} ${
                  (mode === 'details' || predefined) && styles.editMode
                }`}
                style={error.accessPermission ? { border: '1px solid red' } : {}}
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedPermission}
                onChange={handleChangePermission}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                disabled={mode === 'details' || predefined}
                // MenuProps={MenuProps}
              >
                {permissionList.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    <Checkbox checked={selectedPermission.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              {error.accessPermission && (
                <text className={styles.errorMsg}>Invalid Permission</text>
              )}
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
              <Grid container>
                <Grid item xs={6} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Values
                  </label>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <input
                      type="text"
                      name="name"
                      className={`${styles.input} ${
                        (mode === 'details' || predefined) && styles.editMode
                      }`}
                      style={
                        valuesError.valueText
                          ? { border: '1px solid red', width: '48%' }
                          : { width: '48%' }
                      }
                      value={fieldData.current_value}
                      onChange={(e) => handleTextChange('current_value', e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleAddNewValue(e)}
                      disabled={mode === 'details' || predefined}
                      autoComplete="off"
                    />
                    {mode === 'edit' && (
                      <span
                        onClick={handleAddNewValue}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <text className={styles.addNewValues} style={{ color: 'primary' }}>
                          + Add New Values
                        </text>
                      </span>
                    )}
                  </div>
                  {values.length > 0 && (
                    <CustomChips
                      chipsData={values}
                      handleDelete={handleChipDelete_}
                      isEditMode={mode === 'details'}
                    />
                  )}
                  {valuesError.valueText && (
                    <text className={styles.errorMsg}>Cannot Add Empty Value</text>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <label className={styles.fieldHeading} style={{ fontSize: 16 }}>
                Rules
              </label>
              <div style={{ marginTop: 10, borderTop: '2px solid rgb(175,175,175)' }}>
                <FieldRules
                  selectValue={fieldData.data_type}
                  rules={rules}
                  onRuleChange={(key, value) => handleRulesChange(key, value)}
                  onClearAllClick={handleClearAllClick_}
                  mode={mode}
                  predefined={predefined}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* </Container> */}
      </form>
    </main>
  )
}
