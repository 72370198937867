import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Table,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AppButton } from "../../common/Button";
import { ApplicationServices } from "../../../services/applicationServices";
const ApplicationService = new ApplicationServices();

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    lineHeight: "inherit",
    // marginTop: theme.spacing(1),
    "& thead th": {
      fontWeight: "500",
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: 12,
    },
    "& tbody td": {
      fontWeight: "300",
      // backgroundColor : 'red',
      padding: 10,
    },
    "& tbody tr:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  head: {},
  no_data_text: {
    color: "blue",
    position: "relative",
    left: 250,
  },
  no_data: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    height: 100,
  },
  loader: {
    position: "relative",
    left: 250,
    top: 10,
  },
  processTable: {
    height: 400,
  },
}));

function ProcessDialog(props) {
  const classes = useStyles();
  const [processList, setProcessList] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { open, onCloseClick, _id, name, isFromApplication } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    try {
      setLoading(true);
      const res_data = await ApplicationService.getTemplatesByApplicationID(
        _id
      );
      const { _status, data } = res_data?.data;
      setLoading(false);
      if (_status === 200) {
        // const data_list = createData(data);
        setProcessList(data ? data : []);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleIconClick = (item) => {
    const { applicationid, templateid, templatename } = item;
    if (isFromApplication) {
      history.push({
        pathname: `/application/edit/orchestration/${applicationid}?app_bundle=true&id=${_id}`,
        state: {
          source: "load_process",
          applicationid: applicationid,
          templateid: templateid,
          templatename: templatename,
          isFromApplication: isFromApplication,
        },
      });
    } else {
      history.push({
        pathname: `application/edit/orchestration/${applicationid}`,
        state: {
          source: "load_process",
          applicationid: applicationid,
          templateid: templateid,
          templatename: templatename,
        },
      });
    }
  };

  return (
    <div>
      <Dialog
        style={{ height: 600 }}
        onEntering={getData}
        open={open}
        onClose={onCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-title">
          <text>{`${name} - Aggregate API`}</text>
        </DialogTitle>
        <DialogContent>
          <div className={classes.processTable}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                style={{ width: "60%" }}
                size="medium"
              >
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell style={{ width: 170, paddingLeft: 35 }}>
                      Aggregate API Name
                    </TableCell>
                    <TableCell style={{ width: 170 }}>End Point</TableCell>
                    <TableCell style={{ width: 170 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!processList &&
                    processList.length > 0 &&
                    processList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: 35 }}
                        >
                          {row.templatename}
                        </TableCell>
                        <TableCell>{row.endpoint}</TableCell>
                        <TableCell>
                          <span onClick={() => handleIconClick(row)}>
                            <Tooltip title="load Aggregated APIs">
                              <img src="/img/gotoCanvas-ui-app-list.svg" height={20} width={20} alt="icon" />
                            </Tooltip>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  {!loading && processList.length === 0 && (
                    <div className={classes.no_data}>
                      <text className={classes.no_data_text}>
                        No Data Available
                      </text>
                    </div>
                  )}
                  {loading && <CircularProgress className={classes.loader} />}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions className={classes.head}>
          <AppButton
            onClick={onCloseClick}
            color="primary"
            buttonName="Close"
            variant="contained"
            className="btnsmall"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProcessDialog;
