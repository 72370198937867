/* eslint-disable */
import {
  Grid,
  Divider,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useSnackbar } from "notistack";
import DocumentTableWithDropper from "../../../components/common/Tables/DocumentTableWithDropper";
import { connect } from "react-redux";
import { fetchServiceById } from "../../../utils/project/microserviceUtils";
import Loader from "../../../components/common/stuff/Loader";
import { documentColumns } from "../../../constants/documentConstant";
import {
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
} from "../../../redux/actions/documentAction";
import { arrayToFilter } from "../../../utils/common";
import CustomFilter from "../../../components/common/CustomFilter";
import { useRef } from "react";
import { Pagination } from "@material-ui/lab";
import { arrayToSort } from "../../../utils/common";
import { v4 as uuidv4 } from "uuid";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";

const ServiceDetailsList = (props) => {
  const {
    service_documents_list,
    add_service_document_data,
    add_service_documents_filter,
    serviceDocumentsFilters,
    serviceDocumentSort,
    add_service_documents_sort,
  } = props;
  const [serviceId] = useState(props.match.params._id);
  const styles = manageStyles();
  const [searchInput, setSearchInput] = useState("");
  const [currentPageServiceDocumentList, setcurrentPageServiceDocumentList] =
    useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const [isImportButtonClicked, setIsImportButtonClicked] = useState(
    props.location?.state?.forUpload
  );
  const handleImportButtonOpen = () => {
    setIsImportButtonClicked(true);
  };

  const handleImportButtonClose = () => {
    setIsImportButtonClicked(false);
  };

  const handleServiceDocumentListPagination = (event, value) => {
    setcurrentPageServiceDocumentList(value);
  };
  function createDocumentTableData(docData) {
    const { _id, docName, filename, name, resourcetype, version, createdon,status } =
      docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
      action: serviceId,
      status: status,
      statuses: statuses,
    };
  }

  const [serviceData, setServiceData] = useState({});

  useEffect(() => {
    const fetchServiceDetails = async () => {
      const { _msg, _status, data } = await fetchServiceById(serviceId);
      if (_status === 200) {
        setServiceData({ ...data, createdOn: data["Created On"] });
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchServiceDetails();
  }, []);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(()=> {
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Document"
      );
      if (_status === 200) {

        setStatuses(data ? data.status : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  },[]);

  const fetchDocuments = () => {
    const parsedFilters = arrayToFilter([
      ...serviceDocumentsFilters,
      { resourcetype: "microservice" },
      { resourceid: serviceId },
    ]);
    const parsedSort = arrayToSort(serviceDocumentSort);
    add_service_document_data(
      currentPageServiceDocumentList,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
    // setIsImportButtonClicked(false)
  };
  useEffect(() => {
    fetchDocuments();
  }, [currentPageServiceDocumentList, serviceDocumentSort]);

  const serviceDocumentRows =
    service_documents_list.data && service_documents_list.data.length > 0
      ? service_documents_list.data.map((item) => {
          const rowData = createDocumentTableData(item);
          return rowData;
        })
      : [];

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);
  const handleSearch = () => {
    if (searchInput) {
      add_service_document_data(
        0,
        JSON.stringify({
          resourcetype: "microservice",
          resourceid: serviceId,
          filename: { $regex: searchInput, $options: "i" },
        })
      );
    } else {
      add_service_document_data(
        1,
        JSON.stringify({ resourcetype: "microservice", resourceid: serviceId })
      );
    }
  };

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter([
      ...serviceDocumentsFilters,
      { resourcetype: "microservice" },
      { resourceid: serviceId },
    ]);
    const parsedSort = arrayToSort(serviceDocumentSort);
    add_service_document_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilter = () => {
    add_service_documents_filter([
      { id: uuidv4(), column: "", operator: "", value: "" },
    ]);
    add_service_document_data(
      1,
      JSON.stringify({ resourcetype: "microservice", resourceid: serviceId })
    );
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Documents", path: "/documents" },
            { title: "Service", path: "/documents" },
            { title: serviceData.name ? serviceData.name : "Service" },
          ]}
        />
        <div className={styles.topContentItems}>
          <Grid container direction="row">
            <h2 className={styles.heading}>
              {serviceData.name ? serviceData.name : "Service"}
            </h2>
            <div
              className={styles.topContentItem2}
              style={{ display: "flex", right: 32, position: "absolute" }}
            >
              <FilledInput
                style={{ height: "35px" }}
                id="filled-adornment-password"
                name="searchInput"
                autoComplete="off"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.childDocumentSearchBox}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Divider
                orientation="vertical"
                style={{ marginTop: "5px", marginLeft: "3px" }}
                className={styles.MuiDivider}
              />

              <CustomFilter
                columns={documentColumns}
                filters={serviceDocumentsFilters}
                setFilters={add_service_documents_filter}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilter}
              />
              <Divider
                orientation="vertical"
                className={styles.MuiDivider}
                style={{ marginTop: "5px", marginLeft: "1px" }}
              />
              {!isImportButtonClicked ? (
                <AppButton
                  buttonName="Import"
                  variant="contained"
                  disabled={!checkCompPermission("Document", ["Create"])}
                  startIcon={<img src="/images/importIcon.svg" alt="import" />}
                  className="btnsmall"
                  onClick={handleImportButtonOpen}
                />
              ) : (
                <AppButton
                  buttonName="Close"
                  startIcon={<img src="/images/closeIcon.svg" alt="import" />}
                  variant="contained"
                  disabled={false}
                  className="btnsmall"
                  onClick={handleImportButtonClose}
                />
              )}
            </div>
          </Grid>
        </div>
      </div>
      <div className={styles.wrapper} style={{ marginBottom: "0rem" }}>
        <DocumentTableWithDropper
          resourceId={serviceId}
          resourceType={"microservice"}
          isEditMode={isImportButtonClicked}
          sort={serviceDocumentSort}
          setSort={add_service_documents_sort}
          rows={serviceDocumentRows}
          fetchDocuments={fetchDocuments}
          isDocumentModule={true}
        />
      </div>
      {serviceDocumentRows.length !== 0 && (
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(service_documents_list._totalcount / 10)}
              page={currentPageServiceDocumentList}
              onChange={handleServiceDocumentListPagination}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
};

const mapStateToProps = (state) => {
  const {
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
  } = state.allDocumentsReducer;
  const { userInfo } = state.userDetails;
  return {
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
    userInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailsList);
