import {
  ADD_ORGANIZATION_DATA,
  ORGANIZATIONS_FILTER,
  ORGANIZATIONS_SORT,
  ACTIVE_INACTIVE_ORG,
  ADD_CURRENT_ORGANIZATION_DATA,
  CHANGE_LOGO,
  ADD_CLOUD_CONFIG_LIST,
  DELETE_CLOOUD_CONFIG_BY_ID,
  UPDATE_CLOUD_CONFIG_BY_ID,
  UPDATE_INACTIVITY_TIME
} from "../actionTypes";
import { organizationServices } from "../../services/OrganizationServices";
const organizationService = new organizationServices();

function add_org_listscreen_data(payload) {
  return {
    type: ADD_ORGANIZATION_DATA,
    payload: payload,
  };
}
function add_cloud_configuration_list(payload) {
  return {
    type: ADD_CLOUD_CONFIG_LIST,
    payload: payload,
  };
}
export const deleteCloudConfigById = (cloudConfigId) => async (dispatch) => {
  dispatch({
    type: DELETE_CLOOUD_CONFIG_BY_ID,
    payload: {
      cloudConfigId,
    },
  });
};

export const updateCloudConfigById = (configData) =>  {
  return {
    type: UPDATE_CLOUD_CONFIG_BY_ID,
    payload: {
      configData,
    },
  }
};

export const featchAllCloudConfigurationActions = ( filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_cloud_configuration_list({
          loading: true,
        })
      );
      const res_data = await organizationService.featchAllCloudConfiguration(
        
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_cloud_configuration_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_cloud_configuration_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_cloud_configuration_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export function add_organization_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_org_listscreen_data({
        loading: true,
      })
    );
    organizationService
      .getOrganizationsList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_org_listscreen_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_org_listscreen_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch(() => {
        dispatch(
          add_org_listscreen_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      });
  };
}

export const add_organization_filters = (organizationFilter) => {
  return {
    type: ORGANIZATIONS_FILTER,
    payload: organizationFilter,
  };
};

export const add_organization_sort = (organizationSort) => {
  return {
    type: ORGANIZATIONS_SORT,
    payload: organizationSort,
  };
};

export const active_inactive_org = (orgID, status) => {
  return {
    type: ACTIVE_INACTIVE_ORG,
    payload: {
      orgID,
      status,
    },
  };
};

export const add_current_organization_data = (currentOrgData) => {
  return {
    type: ADD_CURRENT_ORGANIZATION_DATA,
    payload: currentOrgData,
  };
};

export function add_current_organization_data_action() {
  return function (dispatch) {
    organizationService
      .fetchCurrentORGDetails()
      .then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          if (data) {
           
            document.getElementById("myFavicon").href = data?.favicon ? data?.favicon : "/kailashlogo.ico";
            const tabTitle = document.getElementById("tabTitle");
            tabTitle.innerHTML = data?.title ? data?.title : "";
            dispatch(
              add_current_organization_data({
                data: data,
              })
            );
          } else {
            dispatch(
              add_current_organization_data({
                data: [],
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_current_organization_data({
            data: [],
          })
        );
      });
  };
}

export const change_logo = (logo, favicon, title) => {
  document.getElementById("myFavicon").href = favicon ? favicon : "/kailashlogo.ico";
  const tabTitle = document.getElementById("tabTitle");
  tabTitle.innerHTML = title ? title : "";
  return {
    type: CHANGE_LOGO,
    logo: logo,
    favicon: favicon,
    title: title,
  };
};

export const update_inactivity_time = (data) => {
  return {
    type: UPDATE_INACTIVITY_TIME,
    payload: data,
  };
};