import { USER_HELP_IMAGE, USER_VISIBLE_REQUEST, USER_VISIBLE_RESET, USER_VISIBLE_SUCCESS } from "../../constants/UserProfile/user"

// action for appearance
export const changeVisibility = (val) => async dispatch => {
    dispatch({
        type: USER_VISIBLE_REQUEST,payload:val
    })
}
export const helpImage = (val) => async dispatch => {
    dispatch({
        type: USER_HELP_IMAGE,payload:val
    })
}
