import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Checkbox } from "@material-ui/core";
import ServiceTableStyles from "../../styles/commonStyles/ServiceTable";
import { Pagination, PaginationItem } from "@material-ui/lab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { ApplicationServices } from "../../../services/applicationServices";
import moment from "moment";

function Row(props) {
  const { apps, setLoading, selectedApplications, setSelectedApplications, isEditMode} =
    props;

  const [open, setOpen] = React.useState(false);
  const [process, setProcess] = useState([]);

  const applicationServices = new ApplicationServices();

  const handleFetchProcess = async (id) => {
    setOpen(!open);
    if (!open) {
      try {
        setLoading(true);
        var resp = await applicationServices.getTemplatesByApplicationID(id);
        setProcess(
          resp?.data?.data
            ? resp?.data?.data?.map((item) => {
                return {
                  ...item,
                  checked: selectedApplications?.find(
                    (selectApp) =>
                      selectApp?.applicationId === item?.applicationid &&
                      selectApp?.process?.find(
                        (proc) => proc?.templateid === item.templateid
                      )
                  )
                    ? true
                    : false,
                };
              })
            : []
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleProcessCheck = (
    checked,
    applicationId,
    appliationName,
    proc,
    state,
    environmentID
  ) => {
    setProcess(
      process.map((item) => {
        if (item.templateid === proc?.templateid) {
          return {
            ...item,
            checked: checked,
          };
        } else {
          return {
            ...item,
          };
        }
      })
    );
    let tempApplications = selectedApplications;
    if (!checked) {
      tempApplications = tempApplications.map((ap) => {
        if (ap?.applicationId === applicationId) {
          return {
            ...ap,
            process: ap?.process.filter(
              (pr) => pr.templateid !== proc.templateid
            ),
          };
        } else {
          return ap;
        }
      });
    } else {
      if (
        tempApplications.find((ele) => ele?.applicationId === applicationId)
      ) {
        const tempProcess = proc;
        delete tempProcess["labels"];
        tempApplications.map((temp) =>
          temp?.process.push({
            ...tempProcess,
          })
        );
      } else {
        const tempProcess = proc;
        delete tempProcess["labels"];
        tempApplications.push({
          name: appliationName,
          applicationId,
          state,
          environmentID: environmentID,
          process: [
            {
              ...tempProcess,
            },
          ],
        });
      }
    }
    setSelectedApplications(
      tempApplications.filter((ele) => ele?.process && ele?.process?.length > 0)
    );
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ paddingLeft: "1rem" }}>
          <IconButton onClick={() => handleFetchProcess(apps?._id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {apps?.name}
        </TableCell>
        <TableCell>{apps?.deployment ? "Added" : "Not Added"}</TableCell>
        <TableCell>
          {moment.unix(apps?.createdon).format("DD/MM/YYYY, hh:mm A")}
        </TableCell>
        <TableCell>{apps?.description}</TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: "8px 30px" }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Process Name</TableCell>
                      <TableCell>End Point</TableCell>
                      <TableCell>Service Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {process &&
                      process.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              name={item?._id}
                              checked={item?.checked}
                              disabled={!isEditMode}
                              onChange={(e) =>
                                handleProcessCheck(
                                  e.target.checked,
                                  apps?._id,
                                  apps?.name,
                                  item,
                                  apps?.state,
                                  apps?.runtime_env
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{item?.templatename}</TableCell>
                          <TableCell>{item?.endpoint}</TableCell>
                          <TableCell>{item?.servicetype}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {process && process.length === 0 && (
                  <span
                    style={{
                      height: 50,
                      backgroundColor: "white",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    No Version Available
                  </span>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function ApplicationTable(props) {
  const classes = ServiceTableStyles();
  const {
    applications,
    selectedApplications,
    setSelectedApplications,
    setLoading,
    isEditMode
  } = props;

  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: "2rem", width: "30%" }}>
                Application Name
              </TableCell>
              <TableCell>Deployment Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications
              ?.slice((page - 1) * 5, page * 5)
              .map((item, index) => (
                <Row
                  isEditMode={isEditMode}
                  key={index}
                  apps={item}
                  selectedApplications={selectedApplications}
                  setSelectedApplications={setSelectedApplications}
                  setLoading={setLoading}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.footer}>
        <h3 className={"itemsCount"}>{applications?.length} items</h3>
        <Pagination
          count={Math.ceil(applications?.length / 5)}
          shape="rounded"
          className={"pagination"}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: NavigateBeforeIcon,
                next: NavigateNextIcon,
              }}
              {...item}
            />
          )}
        />
      </div>
    </>
  );
}
