import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    // height: "20rem",
    width: 800,
    backgroundColor: "white",
    borderRadius: 10,
  },
  line1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    paddingTop: 0,
    paddingBottom: 0,
  },
  section1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 34,
    width: 34,
  },
  heading: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: "#000000",
    fontWeight: "bold",
    marginLeft: 10,
  },
  cross: {
    fontSize: 20,
    fontWeight: "bold",
  },
  line2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  name: { marginRight: 10, display: "flex", flexDirection: 'column' },
  endpoint: {  display: "flex", flexDirection: 'column' },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {
    height: "2.3rem",
    width: "21.2rem",
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "1rem"
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    borderWidth: 1,
    borderColor: "black",
  },
  line3: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  line4: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    height: "10rem",
    display: "flex", flexDirection: 'column'
  },
  icon: {
    position: "relative",
    left: 150,
  },
  searchInput3: {
    width: "21.2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  input: {
    width: 350,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    paddingLeft: "1rem",

    "&::before": {
      borderBottom: "unset",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: "blue",
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  },
  tree: {
    marginLeft: "1.5rem",
    marginTop: "0.4rem",
    minHeight: "15rem"
  },
  select : {
    width: "21.2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  }
}));