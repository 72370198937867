import React, { useEffect } from 'react';
import codemirror from 'codemirror';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { IconButton } from '@material-ui/core';
import { useState } from 'react';
import CustomEditor from './CustomEditor';
import useGetData from '../../../hooks/useGetData';
import { useSelector } from 'react-redux';
import './hint.css';
require('codemirror/mode/xml/xml.js');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/hint/javascript-hint');

const AccessEditorInput = ({
  name,
  value,
  onChange,
  title,
  error,
  mode,
  accessMode,
}) => {
  const {  currentComponent } = useGetData();
  const [codeVal, setCodeVal] = useState('');
  const [focusedElem, setFocusedElem] = useState('')
  const uiApplication = useSelector((state) => state.uiApplicationReducer);
  const { actions } = uiApplication;
  const options = {
    lineNumbers: false,
    mode: mode,
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    var orig = codemirror.hint.javascript;
    codemirror.hint.javascript = function (cm) {
      var inner = orig(cm) || {
        from: cm.getCursor(),
        to: cm.getCursor(),
        list: [],
      };
      let tempArr = [];
      let tempDataValue=currentComponent?.[name]?.dataValue?currentComponent?.[name]?.dataValue:{}
      if (codeVal.includes('data')) {
        if (Array.isArray(currentComponent?.[name]?.dataValue)) {
          currentComponent?.[name]?.dataValue.map((v, i) => {
            tempArr.push(`[${i}]`);
          });
        } else if (
          Object.keys(tempDataValue).length > 0
        ) {
          Object.keys(tempDataValue).map((v) => {
            tempArr.push(v);
          });
        }
      } else {
        // for (let i = 0; i < actions.length; i++) {
        //   let v = `actions.${actions[i]?.actionName}.data`;
        //   tempArr.push(v);
        // }
      }
      inner.list = tempArr;
      return inner;
    };
    return ()=>{
        codemirror.hint.javascript = function (cm) {
            var inner = orig(cm) || {
                from: cm.getCursor(),
                to: cm.getCursor(),
                list: [],
            };
            inner.list = [];
            return inner;
        };
    }
  }, [codeVal]);

  return (
    <div className={`editorInput ${error ? 'errorInput' : ''}`}>
      <CodeMirror
        value={value}
        options={options}
        autoCursor={false}
        onChange={(editor, data, value) => {
          editor.showHint({ completeSingle: false });
          //   if (value.includes('{{')) {
          //     editor.showHint({ completeSingle: false });
          //   }
          let val = value;
          //   if (val === '{{') {
          //     val = value + '}}';
          //   }
          if (val.includes('.[')) {
            val = val.replace('.[', '[');
          }
          onChange(val);
          setCodeVal(val);
        }}
      />
    </div>
  );
};

export default AccessEditorInput;