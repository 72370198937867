import { ALTER_ACTIVE_TAB } from "../actionTypes";

export const alterActiveTab = (module_name, active_tab) => {
  let helpdesk = ''
  if(active_tab === "AITask"){
    helpdesk = 'AI Task'
  }else if(active_tab === "fieldgroups"){
    helpdesk = 'Payloads'
  }else if(active_tab === "language"){
    helpdesk = 'Language'
  }else if(active_tab === "boilerPlate"){
    helpdesk = 'Boiler Plate'
  }else if(active_tab === "chasisTemplate"){
    helpdesk = 'Chassis Template'
  }else if(active_tab === "datadictionary"){
    helpdesk = 'Data Dictionary'
  }else if(active_tab === "services"){
    helpdesk = 'Service'
  }else if(active_tab === "uiSimulator"){
    helpdesk = 'UI Service'
  }else if(active_tab === "Application"){
    helpdesk = 'Application'
  }else if(active_tab === "Service Bundle"){
    helpdesk = 'Service Bundle'
  } else if(active_tab === "projects"){
    helpdesk = 'Document List'
  } else if(active_tab === "serviceGroups"){
    helpdesk = 'Document List'
  } else if(active_tab === "standardDocuments"){
    helpdesk = 'Document List'
  } else if(active_tab === "service"){
    helpdesk = 'Document List'
  }
  else if(active_tab === "DataModel"){
    helpdesk = 'AI Model'
  } else if(active_tab === "Deployments"){
    helpdesk = 'Host'
  } else if(active_tab === "Clusters"){
    helpdesk = 'Cluster'
  }
  else if(active_tab === "object"){
    helpdesk = 'Object'
  }else if(active_tab === "sections"){
    helpdesk = 'Sections'
  }
  else if(active_tab === "fieldgroup"){
    helpdesk = 'Field Groups'
  }
  else if(active_tab === "fields"){
    helpdesk = 'Fields'
  } else if(active_tab === "Execution"){
    helpdesk = 'TestExecute'
  } else if(active_tab === "Scripts"){
    helpdesk = 'TestScript'
  } else if(active_tab === "Data"){
    helpdesk = 'TestData'
  } else if(active_tab === "Status Profile"){
    helpdesk = 'Status Profile'
  }else if(active_tab === "Dashboard"){
    helpdesk = 'Dashboard'
  }else if(active_tab === "Glossary"){
    helpdesk = 'Glossary'
  } else if (active_tab === "UI Application"){
  helpdesk = "UI Application"
   }else if (active_tab === "Help Desk"){
    helpdesk = ""
   }else {
    helpdesk = active_tab
  }
  return {
    type: ALTER_ACTIVE_TAB,
    payload: {
      module_name: module_name,
      active_tab: active_tab,
      helpdesk_default : helpdesk
    },
  };
};
