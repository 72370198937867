import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";
import DisabledButton from "../../../atoms/rightSide/appearance/disabled/DisabledButton";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";

function RadioAppearances() {
  const dispatch = useDispatch();
  const { currentPage, currentComponent, NormalHandleChange } = useGetData();
  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: value ? value : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  return (
    <>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name="fontSize"
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <DisabledButton />
      </div>
      <div>
        {/* <StatusDropdown
          label={"Theme"}
          status={currentComponent?.status}
          handleStatusChange={handleStatusChange}
        /> */}
        <CustomColorPicker name="status" />
      </div>
    </>
  );
}

export default RadioAppearances;
