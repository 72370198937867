import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import FieldRulesSection from "./FieldRulesSection";

const CustomFieldGroupsForGlossaryFieldCreate = ({
  section,
  isEditMode,
  projectData,
  setFieldData,
  error,
  handleChange,
  isClear,
  isTwoTypeFields,
  clearRulesHandler,
}) => {
  const styles = manageStyles();
  const DropDownSection = ({ field }) => {
    return (
      <Grid item xs={3} sm={3}>
        <label htmlFor="" className={styles.fieldHeading}>
          {field?.fieldLabel}{" "}
          {field?.required === "TRUE" && <span className={styles.red}>*</span>}
        </label>
        <Select
          onChange={(e) => {
            setFieldData({
              ...projectData,
              [field.datakey]: e.target.value,
            });
          }}
          displayEmpty
          value={projectData[field.datakey]}
          className={`${styles.select} ${error[field.datakey]?.isError && styles.error
            }`}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {JSON.parse(field?.values)?.map((item) => (
            <MenuItem value={item} className={styles.fieldHeading2}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {error[field.datakey]?.isError && (
          <p className={styles.errorMsg} style={{ marginTop: "3px" }}>
            {/* error[field.datakey]?.isError.msg ? (
              <span style={{ textTransform: "capitalize" }}>
                {error[field.datakey]?.isError.msg}
              </span>
            ) : ( */}
            <>
              <span style={{ textTransform: "capitalize" }}>
                {field.fieldLabel}
              </span>{" "}
              is required
            </>
          </p>
        )}
      </Grid>
    );
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Glossary_Field_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.uiElementType) {
                        case "Dropdown":
                          return <DropDownSection field={field} />;
                        case "DropDown":
                          return <DropDownSection field={field} />;
                        case "Rules":
                          return (
                            <FieldRulesSection
                              field={field}
                              setFieldData={setFieldData}
                              fieldData={projectData}
                              isClear={isClear}
                              isTwoTypeFields={isTwoTypeFields}
                              clearRulesHandler={clearRulesHandler}
                              error={error}
                              isEditMode={isEditMode}
                            />
                          );
                        case "custom":
                          return field.name === "Field Rules" ? (
                            <FieldRulesSection
                              field={field}
                              setFieldData={setFieldData}
                              fieldData={projectData}
                              isClear={isClear}
                              isTwoTypeFields={isTwoTypeFields}
                              clearRulesHandler={clearRulesHandler}
                              error={error}
                              isEditMode={isEditMode}
                            />
                          ) : (
                            <></>
                          );
                        default:
                          return field.name === "Created on" ||
                            field.datakey === "createdon" ||
                            field.name === "Created by" ||
                            field.datakey === "createdby" ||
                            field.name === "Updated on" ||
                            field.datakey === "updatedon" ||
                            field.name === "Updated by" ||
                            field.datakey === "updatedby" ||
                            field.name === "Field Status" ||
                            field.name ===
                            (isTwoTypeFields > 1 ? "Field Type" : " ") ? (
                            <>{/* field.name === "Field Type" ? ( */}</>
                          ) : (
                            <Grid item xs={3} sm={3} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <input
                                name={field.datakey}
                                value={projectData[field.datakey]}
                                onChange={handleChange}
                                placeholder={field.placeholder || " "}
                                className={`${styles.input} ${!isEditMode && styles.editMode
                                  } ${error[field.datakey]?.isError && styles.error
                                  } ${field.editable === "FALSE" && styles.editMode
                                  }`}
                                disabled={
                                  !isEditMode || field.editable === "FALSE"
                                }
                                autoComplete="off"
                                type="text"
                              />
                              {error[field.datakey]?.isError && (
                                <p className={styles.errorMsg}>
                                  {/*    {error[field.datakey]?.isError.msg ? (
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {error[field.datakey]?.isError.msg}
                                    </span>
                                  ) : ( */}
                                  <>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {field.fieldLabel}
                                    </span>{" "}
                                    is required
                                  </>
                                  {/* )}*/}
                                </p>
                              )}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.uiElementType) {
                              case "Dropdown":
                              case "DropDown":
                                return <DropDownSection field={field} />
                              case "Rules":
                                return (
                                  <FieldRulesSection
                                    field={field}
                                    setFieldData={setFieldData}
                                    fieldData={projectData}
                                    isClear={isClear}
                                    isTwoTypeFields={isTwoTypeFields}
                                    clearRulesHandler={clearRulesHandler}
                                    isEditMode={isEditMode}
                                    error={error}
                                  />
                                );
                              case "custom":
                                return field.name === "Field Rules" ? (
                                  <FieldRulesSection
                                    field={field}
                                    setFieldData={setFieldData}
                                    fieldData={projectData}
                                    isClear={isClear}
                                    isTwoTypeFields={isTwoTypeFields}
                                    clearRulesHandler={clearRulesHandler}
                                    error={error}
                                    isEditMode={isEditMode}
                                  />
                                ) : (
                                  <></>
                                );
                              default:
                                return field.name === "Created on" ||
                                  field.datakey === "createdon" ||
                                  field.name === "Created by" ||
                                  field.datakey === "createdby" ||
                                  field.name === "Updated on" ||
                                  field.datakey === "updatedon" ||
                                  field.name === "Updated by" ||
                                  field.datakey === "updatedby" ||
                                  field.name === "Field Status" ||
                                  field.name ===
                                  (isTwoTypeFields > 1
                                    ? "Field Type"
                                    : " ") ? (
                                  <>{/* field.name === "Field Type" ? ( */}</>
                                ) : (
                                  <Grid item xs={3} sm={3} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      name={field.datakey}
                                      value={projectData[field.datakey]}
                                      onChange={handleChange}
                                      placeholder={field.placeholder || " "}
                                      className={`${styles.input} ${!isEditMode && styles.editMode
                                        } ${error[field.datakey]?.isError &&
                                        styles.error
                                        } ${field.editable === "FALSE" &&
                                        styles.editMode
                                        }`}
                                      disabled={
                                        !isEditMode ||
                                        field.editable === "FALSE"
                                      }
                                      autoComplete="off"
                                      type="text"
                                    />
                                    {error[field.datakey]?.isError && (
                                      <p className={styles.errorMsg}>
                                        {/*     {error[field.datakey]?.isError.msg ? (
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {error[field.datakey]?.isError.msg}
                                          </span>
                                        ) : ( */}
                                        <>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {field.fieldLabel}
                                          </span>{" "}
                                          is required
                                        </>
                                        {/* )}*/}
                                      </p>
                                    )}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}{" "}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={projectData[field.datakey]}
                                  onChange={handleChange}
                                  placeholder={field.placeholder || " "}
                                  className={`${styles.textarea} ${!isEditMode && styles.editMode
                                    } ${error[field.datakey]?.isError &&
                                    styles.error
                                    } ${field.editable === "FALSE" &&
                                    styles.editMode
                                    }`}
                                  disabled={
                                    !isEditMode || field.editable === "FALSE"
                                  }
                                  rows="7"
                                />
                                {error[field.datakey]?.isError && (
                                  <p className={styles.errorMsg}>
                                    {/*    {error[field.datakey]?.isError.msg ? (
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {error[field.datakey]?.isError.msg}
                                      </span>
                                    ) : ( */}
                                    <>
                                      <span
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {field.fieldLabel}
                                      </span>{" "}
                                      is required
                                    </>
                                    {/* )}*/}
                                  </p>
                                )}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(
  CustomFieldGroupsForGlossaryFieldCreate
);
