const roleColumns = [
  // { name: "id", title: "Project ID" },
  { name: "name", title: "Role Name", for: "role" },
  { name: "numUsers", title: "No. of users", for: "role" },
  { name: "createdby", title: "Created By", for: "role" },
  { name: "createdon", title: "Created On", for: "role" },
  { name: "action", title: "Action", route: "/role-management/edit", for: "role" },
];

const roleDefaultColumnWidths = [
  // { columnName: "id", width: 250 },
  { columnName: "name", weightage: 2.5 },
  { columnName: "numUsers", weightage: 3 },
  { columnName: "createdby", weightage: 2 },
  { columnName: "createdon", weightage: 2 },
  { columnName: "action", weightage: 2.5 },
];

const roleTableColumnExtensions = [
  // { columnName: "id", align: "left" },
  { columnName: "name", align: "left" },
  { columnName: "numUsers", align: "center" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const roleColumnOrder = [
  // "id",
  "name",
  "numUsers",
  "createdby",
  "createdon",
  "action",
];

const roleDefaultSorting = [];

const roleDefaultRows = [
  {
    id: 12345,
    name: 'Super Admin 2',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12345,
    name: 'Organisation Admin',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12346,
    name: 'Project Manager / Owner',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12347,
    name: 'Architect',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12348,
    name: 'Service Group Admin',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    name: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
]

export {
  roleColumns,
  roleDefaultColumnWidths,
  roleTableColumnExtensions,
  roleColumnOrder,
  roleDefaultSorting,
  roleDefaultRows
};
