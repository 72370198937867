import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {
    overflow: 'auto',
    height:'100%',
    backgroundColor:'#fff',
    padding:5
  },
  titleContainer:{
    display:'flex',
    alignItems:'center',
    minHeight: "2.5rem",

    "& h1": {
      font: "normal normal 500 18px/22px Nunito",
      color: "#262626",
      padding:'4px 0px 0px 4px',
      margin:0
    },
  }
}));