import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React from "react";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import moment from "moment";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";

const ContainerDetailsEntity = ({
  section,
  containerdata,
  userInfo,
  myArr,
  tabbedView,
  styleExtra,
  isDownload,
  CircularProgress,
  AppButton,
  handleTodayLogsDownload,
  handleAllLogsDownload,
  isAllLogsDownload,
  styleDownloadLogs,
}) => {
  const styles = manageStyles();

  const DockerImage = () => {
    return (
      <>
        <Grid item xs={4}>
          <label className={styles.fieldHeading}>Image</label>
          <input
            type="text"
            className={styles.input2}
            value={containerdata?.image}
            autoComplete="off"
            disabled
          />
        </Grid>
      </>
    );
  };
  const Envtable = () => {
    return (
      <>
        <div style={{ padding: "15px 10px 50px 10px" }}>
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                  <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containerdata &&
                  containerdata?.config?.Env !== null &&
                  containerdata?.config?.Env?.length > 0 &&
                  containerdata?.config?.Env?.map((item) => {
                    return (
                      <TableRow style={{ border: "0.5px solid #D5D5D5" }}>
                        <TableCell scope="row">
                          <input
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                            value={myArr[0]}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            type="text"
                            className={styles.input2}
                            autoComplete="off"
                            disabled
                            value={myArr[1]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {containerdata?.config?.Env?.length === 0 ||
              (containerdata?.config?.Env === null && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px 0",
                    font: "normal normal normal 16px/22px Nunito Sans",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #DFDFDF",
                  }}
                >
                  No Data Available
                </div>
              ))}
          </TableContainer>
        </div>
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Container Name") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Container Name <span className={styles.red}>*</span>
              </label>
              <input
                className={styles.input2}
                value={containerdata.containername}
                disabled
                autoComplete="off"
              />
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                className={styles.input2}
                value={
                  field.dataType === "date" && containerdata[field.datakey]
                    ? moment
                        .unix(containerdata[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : containerdata[field.datakey]
                }
                disabled
                autoComplete="off"
              />
            </>
          );
        }
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={containerdata[field.datakey]}
              className={styles.textarea2}
              disabled
              rows="7"
            ></textarea>
          </>
        );
      case "Dropdown":
        if (field.fieldLabel === "Docker Image") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Docker Image<span className={styles.red}>*</span>
              </label>
              <input
                className={styles.input2}
                value={containerdata?.dockerdetails?.imagename}
                autoComplete="off"
                disabled
              />
            </div>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                className={styles.input2}
                // value={launguageData[field.datakey]}
                value={containerdata[field.datakey]}
                autoComplete="off"
                disabled
              />
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                containerdata[field.datakey] ? containerdata[field.datakey] : ""
              }
              // onChange={(date) =>
              //   handleChange({ target: { name: field.datakey, value: date } })
              // }
              disabled
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={styles.input2}
                    />
                    <span className={`${styles.datePickerIcon} `}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                disabled
                name={`${field.datakey}_unit`}
                value={
                  containerdata[`${field.datakey}_unit`]
                    ? containerdata[`${field.datakey}_unit`]
                    : ""
                }
                className={styles.select}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={styles.input2}
                value={containerdata[`${field.datakey}_value`]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                disabled
                name={`${field.datakey}_unit`}
                value={
                  containerdata[`${field.datakey}_unit`]
                    ? containerdata[`${field.datakey}_unit`]
                    : ""
                }
                className={styles.select}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={styles.input2}
                value={containerdata[`${field.datakey}_value`]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                containerdata[field.datakey] ? containerdata[field.datakey] : ""
              }
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {section && section.name !== "Download Container logs" ? (
        <div>
          {section?.fieldgroups.map((fieldgroup) => {
            return (
              <div className={styles.fieldGroup} key={fieldgroup._id}>
                {section.name === "Language_Header_section" &&
                  !section.fieldgroups[0]._id === fieldgroup._id && (
                    <h3 className={styles.fieldgroupHeading}>
                      {fieldgroup.name}
                    </h3>
                  )}
                {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                  // layout withoutTextArea
                  <Grid
                    container
                    spacing={3}
                    style={
                      tabbedView
                        ? { paddingTop: 8, display: "block" }
                        : { marginTop: "0rem", display: "block" }
                    }
                    key={fieldgroup._id}
                  >
                    {fieldgroup &&
                      fieldgroup?.fields.map((field) => {
                        switch (field.name) {
                          case "Container_Config":
                            return DockerImage();
                          case "Container EnvironmentVariable":
                            return Envtable();

                          default:
                            return (
                              <Grid item xs={4} sm={4} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                        }
                      })}
                  </Grid>
                ) : (
                  // layout with TextArea
                  <Grid
                    container
                    spacing={3}
                    style={tabbedView ? {} : { marginTop: "0rem" }}
                    key={fieldgroup._id}
                  >
                    <Grid item xs={9} sm={9}>
                      <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                        {fieldgroup &&
                          fieldgroup?.fields
                            .filter((f) => f.uiElementType !== "TextArea")
                            .map((field) => {
                              switch (field.name) {
                                case "Container_Config":
                                  return DockerImage();
                                case "Container EnvironmentVariable":
                                  return Envtable();
                                default:
                                  return (
                                    <Grid item xs={4} sm={4} key={field._id}>
                                      {generateField(field)}
                                    </Grid>
                                  );
                              }
                            })}
                      </Grid>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                        {fieldgroup &&
                          fieldgroup?.fields
                            .filter((f) => f.uiElementType === "TextArea")
                            .map((field) => {
                              return (
                                <Grid item xs={12} sm={12} key={field._id}>
                                  {generateField(field)}
                                </Grid>
                              );
                            })}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={styleDownloadLogs}
          style={{ marginBottom: "-80px", paddingBottom: 0 }}
        >
          <div style={{ marginRight: "-60px " }}>
            <div className={styleExtra}>Today Logs</div>
            {isDownload && <CircularProgress color="primary" size={25} />}
            {!isDownload && (
              <AppButton
                color="primary"
                buttonName="Download"
                variant="contained"
                startIcon={<img src="/images/Download_icon.svg" alt="" />}
                style={{ marginLeft: "15px" }}
                className="btnmedium"
                onClick={handleTodayLogsDownload}
              />
            )}
          </div>
          <div>
            <div className={styleExtra}>All Logs</div>
            {isAllLogsDownload && (
              <CircularProgress color="primary" size={25} />
            )}
            {!isAllLogsDownload && (
              <AppButton
                color="primary"
                buttonName="Download"
                variant="contained"
                startIcon={<img src="/images/Download_icon.svg" alt="" />}
                style={{ marginLeft: "15px" }}
                className="btnmedium"
                onClick={handleAllLogsDownload}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(ContainerDetailsEntity);
