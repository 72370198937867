import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  loadingbtnContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  loadingbtn: {
    marginTop: 5,
    width: "111px",
    height: "29px",
    // background: '#FFFFFF 0% 0% no-repeat padding-box',
    backgroundColor: "#ffffff",
    backgroundImage: "0% 0%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "padding-box",
    border: "1px solid #D8D8D8",
    borderRadius: 3,
    cursor: "pointer",
  },
  btnIcon: {
    width: "18px",
    height: "18px",
  },
}));
