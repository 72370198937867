import moment from "moment";
import { DashboardServices } from "../../services/dashboardServices";

const dashboardService = new DashboardServices()

export const getDatasets = async () => {
    try {
        const res_data = await dashboardService.getAllDatasets();
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const getDatasetsFields = async (dataset) => {
    try {
        const res_data = await dashboardService.getDatasetsFields(dataset);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const createChart = async (chartData) => {
    try {
        const res_data = await dashboardService.createChart(chartData);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const editChart = async (chartId, chartData) => {
    try {
        const res_data = await dashboardService.editChart(chartId, chartData);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const getUserCharts = async () => {
    try {
        const res_data = await dashboardService.getAllCharts();
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const executeGraph = async (graphId) => {
    try {
        const res_data = await dashboardService.executeGraph(graphId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const deleteGraph = async (graphId) => {
    try {
        const res_data = await dashboardService.deleteGraph(graphId);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const getValue = (value, userInfo) => {
    if (value.length >= 10) {
        let date = userInfo?.timeZone
            ? moment.unix(value)
                .tz(userInfo?.timeZone)
                .format(userInfo?.dateFormat)
            : moment.unix(value).format("DD/MM/YYYY")
        if (date === "Invalid date") {
            return value
        } else {
            return date;
        }
    } else {
        return value
    }
}

export const generateChartData = (chartData) => {
    let finalChartData = {}
    const colors = ['#2185D0', '#15B5AD', '#FBBD2C', '#B5CC2C', '#002855', '#C0C0C0', '#FF0000',
        '#FFFF00', '#808000', '#00FF00', '#008000', '#00FFFF', '#808080', '#008080', '#0000FF',
        '#000080', '#FF00FF', '#800080', '#003300', '#006633', '#00688B', '#006B54',
        '#007FFF', '#008080', '#008B45', '#008B8B', '#0099CC', '#00AF33', '#00B2EE', '#00C78C',
        '#00CDCD', '#00FFFF', '#800000', '#108070', '#104E8B', '#108070', '#120A8F', '#162252',
        '#1874CD', '#284942', '#28AE7B', '#2B4F81', '#2E0854', '#2C5D3F', '#2C5197', '#2E37FE',
        '#2E5090', '#2E8B57', '#2F4F2F', '#2F2F4F', '#2FAA96', '#302B54', '#303030', '#325C74',
        '#329555', '#32CD32', '#330000', '#344152', '#353F3E', '#37BC61', '#380474', '#38B0DE',
        '#397D02', '#3B8471', '#54FF9F', '#551033', '#555555', '#551A8B', '#575757', '#5959AB',
        '#5993E5', '#5C246E', '#5C3317', '#5D7B93', '#5DFC0A', '#5E2D79', '#5E2612', '#608341',
        '#660198', '#6600FF', '#687E5A', '#7A378B', '#8B0000', '#8B0A50', '#8B2323', '#8B6508',
        '#93DB70', '#990099', '#9932CC', '#9D1309', '#9CCB19', '#AA00FF', '#ABABAB', '#AF4035',
        '#B62084', '#BCE937', '#C71585', '#CC00FF', '#CD00CD', '#CD0000', '#CD1076', '#CD5B45',
        '#D02090', '#D1E231', '#D9D919', '#E18E2E', '#E31230', '#EE00EE', '#EE0000', '#EE30A7',
        '#EE9A00', '#EEEE00', '#F0A804', '#F2473F', '#F54D70', '#FCD116', '#FF0000', '#FF00FF',
        '#FF2400', '#FF6600', '#FF8600', '#FF6EC7', '#FF6666', '#FF00AA']
    let color = ""
    const labels = []
    const values = []
    let totalCount = 0;
    switch (chartData.graphDetails.chartType) {
        case "bar":
            chartData.datasets.map(dataset => {
                // labels.push(...Object.keys(dataset))
                Object.keys(dataset).forEach(value => {
                    if (labels.indexOf(value) === -1) labels.push(getValue(value));
                });
                return {}
            })
            chartData.datasets.map(dataset => {
                // values.push(labels.map(l => dataset[l] ? dataset[l] : 0))
                const tempValues = Object.values(dataset)
                values.push(labels.map((l, index) => tempValues[index] ? tempValues[index] : 0))
                return {}
            })
            finalChartData = {
                error: false,
                chartData: chartData.datasets,
                graphDetails: chartData.graphDetails,
                data: {
                    labels: labels,
                    datasets: chartData?.datasets.map((dataset, index) => ({
                        label: chartData?.graphDetails?.fields.find(field => field?.queryField === chartData?.graphDetails?.aggregates?.[index]?.field)?.label,
                        data: values[index],
                        borderRadius: 5,
                        // barPercentage: 0.4,
                        hoverBackgroundColor: colors[index],
                        // barThickness: 25,
                        // maxBarThickness: 25,
                        // minBarLength: 25,
                        backgroundColor: colors[index],
                    })),
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom',
                            display: false
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                callback: (label) => { if (label % 1 === 0) return label }
                            }
                        }
                    }
                }
            }
            return finalChartData
        case "stack_bar":
            chartData.datasets.map(dataset => {
                Object.keys(dataset).forEach(value => {
                    if (labels.indexOf(value) === -1) labels.push(getValue(value));
                });
                return {}
            })
            const datasets = []
            const bgColor = []
            chartData.datasets.map((dataset, dataindex) => {
                const keys = []
                Object.values(dataset).map(item => {
                    Object.keys(item).forEach(value => {
                        if (keys.indexOf(value) === -1) keys.push(value);
                    });
                    return {}
                })
                const tempColor = []
                keys.map((item, index) => {
                    const color = dataindex % 2 === 0 ? colors[index + 1] : colors[colors.length - (index + 1)]
                    tempColor.push({
                        label: item,
                        color: color
                    })
                    datasets.push(({
                        label: chartData?.graphDetails?.fields.find(field => field?.queryField === chartData?.graphDetails?.aggregates[dataindex]?.field)?.label + ' : ' + item,
                        data: labels.map(label => dataset[label] ? dataset[label][item] : 0),
                        borderRadius: 5,
                        // barPercentage: 0.4,
                        // stack: labels[dataindex],
                        stack: chartData?.graphDetails?.aggregates[dataindex].id,
                        // barThickness: 25,
                        // maxBarThickness: 25,
                        // minBarLength: 25,
                        backgroundColor: color,
                    }))
                    return {}
                })
                bgColor.push(tempColor)
                return {}
            })
            finalChartData = {
                error: false,
                chartData: chartData.datasets,
                graphDetails: chartData?.graphDetails,
                data: {
                    labels: labels,
                    datasets: datasets,
                    bgColor: bgColor
                },
                // data: {
                //     labels: ['organization', 'testingProj'],
                //     datasets: [
                //         {
                //             label: 'Completed',
                //             data: [1, 0],
                //             borderRadius: 5,
                //             barPercentage: 0.4,
                //             // hoverBackgroundColor: '#013478',
                //             // barThickness: 25,
                //             // maxBarThickness: 25,
                //             // minBarLength: 25,
                //             backgroundColor: '#B5CC2C',
                //         },
                //         {
                //             label: 'pending',
                //             data: [2, 1],
                //             borderRadius: 5,
                //             barPercentage: 0.4,
                //             // hoverBackgroundColor: '#013478',
                //             // barThickness: 25,
                //             // maxBarThickness: 25,
                //             // minBarLength: 25,
                //             backgroundColor: '#2185D0',
                //         },
                //         {
                //             label: 'started',
                //             data: [0, 1],
                //             borderRadius: 5,
                //             barPercentage: 0.4,
                //             // hoverBackgroundColor: '#013478',
                //             // barThickness: 25,
                //             // maxBarThickness: 25,
                //             // minBarLength: 25,
                //             backgroundColor: '#013478',
                //         },
                //     ]
                // },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom',
                            display: false
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: false,
                            },
                            ticks: {
                                callback: (label) => { if (label % 1 === 0) return label }
                            }
                        }
                    }
                }
            }
            return finalChartData
        case "pie":
            chartData.datasets.map(dataset => {
                Object.keys(dataset).forEach(value => {
                    if (labels.indexOf(value) === -1) labels.push(getValue(value));
                });
                return {}
            })
            chartData.datasets.map(dataset => {
                const tempValues = Object.values(dataset)
                values.push(labels.map((l, index) => tempValues[index] ? tempValues[index] : 0))
                return {}
            })
            color = []
            chartData.datasets.map((dataset, dataindex) => {
                return color.push(labels.map((l, index) => dataindex % 2 === 0 ? colors[index + 1] : colors[colors.length - (index + 1)]))
            })
            chartData?.datasets?.map((dataset, index) => {
                return totalCount += values[index].reduce((a, b) => a + b, 0)
            })
            finalChartData = {
                error: false,
                chartData: chartData.datasets,
                graphDetails: chartData.graphDetails,
                data: {
                    labels: labels,
                    datasets: chartData.datasets.map((dataset, index) => ({
                        label: chartData?.graphDetails?.aggregates[index]?.id,
                        data: values[index],
                        hoverOffset: 5,
                        radius: '90%',
                        backgroundColor: color[index],
                        borderColor: color[index]
                    })),
                },
                options: {
                    responsive: true,
                    totalCount: totalCount,
                    plugins: {
                        legend: {
                            position: 'right',
                            display: false
                        },
                    }
                }
            }
            return finalChartData
        case "donut":
            chartData.datasets.map(dataset => {
                Object.keys(dataset).forEach(value => {
                    if (labels.indexOf(value) === -1) labels.push(getValue(value));
                });
                return {}
            })
            chartData.datasets.map(dataset => {
                const tempValues = Object.values(dataset)
                values.push(labels.map((l, index) => tempValues[index] ? tempValues[index] : 0))
                return {}
            })
            color = []
            chartData.datasets.map((dataset, dataindex) => {
                return color.push(labels.map((l, index) => dataindex % 2 === 0 ? colors[index + 1] : colors[colors.length - (index + 1)]))
            })
            chartData?.datasets?.map((dataset, index) => {
                return totalCount += values[index].reduce((a, b) => a + b, 0)
            })
            finalChartData = {
                error: false,
                chartData: chartData.datasets,
                graphDetails: chartData.graphDetails,
                data: {
                    labels: labels,
                    datasets: chartData.datasets.map((dataset, index) => ({
                        label: chartData?.graphDetails?.aggregates[index]?.id,
                        data: values[index],
                        hoverOffset: 5,
                        cutout: '60%',
                        radius: '90%',
                        backgroundColor: color[index],
                        borderColor: color[index]
                    })),
                },
                options: {
                    responsive: true,
                    totalCount: totalCount,
                    plugins: {
                        legend: {
                            position: 'right',
                            display: false
                        },
                    },
                }
            }
            return finalChartData
        case "line":
            color = chartData.datasets.map((dataset, index) => colors[index])
            chartData.datasets.map(dataset => {
                Object.keys(dataset).forEach(value => {
                    if (labels.indexOf(value) === -1) labels.push(getValue(value));
                });
                return {}
            })
            chartData.datasets.map(dataset => {
                const tempValues = Object.values(dataset)
                values.push(labels.map((l, index) => tempValues[index] ? tempValues[index] : 0))
                return {}
            })
            finalChartData = {
                error: false,
                chartData: chartData.datasets,
                graphDetails: chartData.graphDetails,
                data: {
                    labels: labels,
                    datasets: chartData.datasets.map((dataset, index) => ({
                        label: chartData?.graphDetails?.fields.find(field => field?.queryField === chartData?.graphDetails?.aggregates?.[index]?.field)?.label,
                        data: values[index],
                        pointStyle: 'rect',
                        pointRadius: 5,
                        borderColor: color[index],
                        pointBorderColor: color[index],
                        backgroundColor: color[index],
                    })),
                },
                options: {
                    responsive: true,
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        legend: {
                            position: 'bottom',
                            display: false,
                            // align: 'start',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false
                            }
                        },
                        y: {
                            grid: {
                                display: true,
                                borderDash: [3, 4]
                            },
                            ticks: {
                                callback: (label) => { if (label % 1 === 0) return label }
                            }
                        }
                    }
                }
            }
            return finalChartData
        default:
            return finalChartData
    }
}
