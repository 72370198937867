const serviceColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "Service Name", for: "service" },
  { name: "createdby", title: "Created By", for: "service" },
  { name: "createdon", title: "Created On", for: "service" },
  { name: "status", title: "Status", for: "service" },
  { name: "action", title: "Action", route: "/services/edit", for: "service" },
];

const serviceDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "status", weightage: 2.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
  {columnName:"description",weightage:3,width:200},
  {columnName:"imagename",weightage:3,width:200},
  {columnName:"serviceType",weightage:3,width:200},
  {columnName:"service_category",weightage:3,width:200},
  { columnName: "updatedby", weightage: 2, width: 200 },
  { columnName: "updatedon", weightage:2, width: 200 },
];

const serviceTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const serviceColumnOrder = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
  "description",
  "imagename",
  "serviceType",
  "service_category",
  "updatedby",
  "updatedon"
];

const serviceColumnHidden = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
];

const serviceDefaultSorting = [];

export {
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
  serviceColumnOrder,
  serviceDefaultSorting,
  serviceColumnHidden
};
