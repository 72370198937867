/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  aside: {
    boxShadow: "0px 0px 6px #0000001A",
    border: "1px solid #E6E6E6",
    background: "#FFFFFF",
    width: "15rem",
    // zIndex : 100
  },
  processHeading: {
    background: "#F0F7FF 0% 0% no-repeat padding-box",
    borderBottom: "1px solid #E6E6E6",
    padding: 10,
    paddingLeft: "1.5rem",
  },
  nodes: {
    margin: 10,
  },
  dndnode: {
    padding: 5,
    margin: "2px 5px",
    cursor: "grab",
    display: "flex",
    alignItems: "center",
  },
  nodeIcon: {
    // height: 30,
    // width: 30,
    borderRadius: 5,
    display: "flex",
    // alignItems: 'center',
    justifyContent: "center",
    marginRight: 15,

    "& img": {
      borderRadius: 5,
    },
  },
  nodehandleGreen: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid #32CD32",
    borderRadius: "7.5px!important",
  },
  sevicesHeading: {
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    border: "1px solid #E6E6E6",
    padding: 10,
    paddingLeft: "1.5rem",
  },
  services: {
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    padding: "0 10px",
    height: "9rem",
    overflow: "auto",
  },
  service: {
    padding: 5,
    margin: 5,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "0.5rem",
  },
  serviceText: {
    marginLeft: 15,
  },
  serviceName: {
    margin: 5,
    fontSize: 14,
    maxLines: 1,
    blockOverflow: "ellipsis",
  },
  versionName: {
    margin: 0,
    fontSize: 12,
  },
  legendRow: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E6E6E6",
    height: "2.5rem",
    alignItems: "center",
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "1rem",
  },
}));

function SideBar(props) {
  const styles = useStyles();
  const { services } = props;

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside className={styles.aside}>
      <div className={styles.processHeading}>Aggregated APIs</div>
      <div className={styles.nodes}>
        <div
          className={`${styles.dndnode} ${styles.input}`}
          onDragStart={(event) => onDragStart(event, "process_node")}
          draggable
        >
          <span className={styles.nodeIcon}>
            <img src="/images/Start - process.png" />
          </span>
          Aggregated API Node
        </div>
        <div
          className={styles.dndnode}
          onDragStart={(event) => onDragStart(event, "call_node")}
          draggable
        >
          <span className={styles.nodeIcon}>
            <img src="/images/Call.png" />
          </span>
          Call Node
        </div>
        <div
          className={styles.dndnode}
          onDragStart={(event) => onDragStart(event, "switch_node")}
          draggable
        >
          <span className={styles.nodeIcon}>
            <img src="/images/Switch.png" />
          </span>
          Switch Node
        </div>
        <div
          className={styles.dndnode}
          onDragStart={(event) => onDragStart(event, "join_node")}
          draggable
        >
          <span className={styles.nodeIcon}>
            <img src="/images/Join.png" />
          </span>
          Join Node
        </div>
        {/* <div
          className={`${styles.dndnode} ${styles.output}`}
          onDragStart={(event) => onDragStart(event, "output", 'end')}
          draggable
        >
          <span className={styles.nodeIcon}><img src="/images/End - process.png" /></span>
          End Node
      </div> */}
      </div>
      <div className={styles.legendRow}>
        <div className={styles.legend}>
          <div
            className={styles.nodehandleGreen}
            style={{ borderColor: "red" }}
          ></div>
          <text
            style={{
              fontSize: 12,
              fontFamily: "Nunito",
              fontWeight: "500",
              marginLeft: 5,
            }}
          >
            Target
          </text>
        </div>
        <div className={styles.legend}>
          <div
            className={styles.nodehandleGreen}
            style={{ borderColor: "#32CD32" }}
          ></div>
          <text
            style={{
              fontSize: 12,
              fontFamily: "Nunito",
              fontWeight: "500",
              marginLeft: 5,
            }}
          >
            Source
          </text>
        </div>
      </div>
      <div className={styles.sevicesHeading}>Services</div>
      <div className={styles.services}>
        {services &&
          services.length > 0 &&
          services.map((item) => {
            return (
              <div className={styles.service}>
                <span className={styles.serviceIcon}>
                  <img
                    src="/images/Code-icon3x.png"
                    alt="service icon"
                    height={20}
                    width={20}
                  />
                </span>
                <div className={styles.serviceText}>
                  <h3 className={styles.serviceName}>
                    {item.microservicename}
                  </h3>
                  <p className={styles.versionName}>{item.versionTag}</p>
                </div>
              </div>
            );
          })}
      </div>
    </aside>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { application_data } = state.orchestration;
  const { microservices } = application_data.data;
  return {
    services: microservices,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
