import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  arrayToJson,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import {
  addVersion,
  uploadLocalSourceCode,
} from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import Loader from "../../../components/common/stuff/Loader";
import {
  closeVersion,
  getVersionList,
  tabbedActiveTabAction,
} from "../../../redux/actions/serviceAction";
import { connect, useSelector } from "react-redux";
import CustomFieldGroupsForVersionCreate from "../../../components/common/entitySchema/version/CustomFieldGroupsForVersionCreate";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { useLocation } from "react-router-dom";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
import UploadSourceCodeInVersion from "../../../components/common/modals/UploadSourceCodeInVersion";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const AddVersion = (props) => {
  const {
    tabbedView,
    fromAppBundle,
    setFromAppBundle,
    serviceType,
    serviceName,
    createVersion,
    setCreateVersion,
    getVersionList,
    executeScroll,
    tabbedActiveTabAction,
  } = props;
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const isEditMode = isTabbedApproach
    ? props.isEditMode
    : props.match.params.action === "edit"
      ? true
      : false;

  const mid = isTabbedApproach ? props.serviceId : props.match.params.serviceId;
  const sType = isTabbedApproach
    ? serviceType
    : props.location?.state?.serviceType;
  const [serviceId] = useState(mid);
  const [versionId, setVersionId] = useState();
  const [isFromCreateVersion, setIsFromCreateVersion] = useState(true);
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const [openUploadSourceCodeModal, setOpenUploadSourceCodeModal] =
    useState(false);
  const styles = manageStyles();
  const style = listScreenStyles();
  const dispatch = useDispatch();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [versionEntitySchema, setVersionEntitySchema] = useState([]);
  const [repositoryData, setRepositoryData] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [versionData, setVersionData] = useState({
    versionTag: "",
    description: "",
    microserviceType: sType,
    environmentVariables: {},
    buildcommands: [""],
    runcommands: [""],
  });
  const [error, setError] = useState({
    versionTag: { isError: false },
  });

  const search = useLocation().search;

  const { checkScreenPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("Microservice", ["Create Version"]);
    const fetchVersionEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Version",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) => {
          if (versionData?.microserviceType === "External") {
            if (
              section.name !== "Version_Docker_Section" &&
              section.name != "Version_Environmentvariables_Section" &&
              section.name != "Version_SourceCode_Section"
            ) {
              section?.fieldgroups?.map((fieldgroup) => {
                if (fieldgroup?.fields?.length !== 0) {
                  entity.push(section);
                }
              });
            }
          } else {
            section?.fieldgroups?.map((fieldgroup) => {
              if (fieldgroup?.fields?.length !== 0) {
                entity.push(section);
              }
            });
          }
        });

        setVersionEntitySchema(entity);
        const verData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (verData[field.datakey] = "");
              }
            });
          });
        });
        if (versionData?.microserviceType === "External") {
          delete verData["port"];
        }
        setVersionData((prevData) => ({ ...prevData, ...verData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["dockerCommands"];
        delete errData["envs"];
        if (versionData?.microserviceType === "External") {
          delete errData["port"];
        }
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchVersionEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setVersionData({
      ...versionData,
      [event.target.name]: value,
    });
  };

  const deleteBuildCommand = (index) => {
    versionData.buildcommands.splice(index, 1);
    setVersionData({
      ...versionData,
      buildcommands: versionData.buildcommands,
    });
  };

  const deleteRunCommand = (index) => {
    versionData.runcommands.splice(index, 1);
    setVersionData({ ...versionData, runcommands: versionData.runcommands });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(versionData, error);
    if (formIsValid) {
      const newVersionData = {
        ...versionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: versionData.buildcommands[0]
            ? versionData.buildcommands
            : [],
          runcommands: versionData.runcommands[0]
            ? versionData.runcommands
            : [],
        },
      };
      setIsLoader(true);
      const { _msg, _status, data } = await addVersion(
        serviceId,
        newVersionData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        props.getVersionList(serviceId);
        if (isTabbedApproach) {
          histroy.push(`/services/details/${serviceId}`);
          setCreateVersion(false);
        } else {

          histroy.push(`/services/details/${serviceId}`);
          // executeScroll
        }
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        versionEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);
      setError(errors);
    }
  };

  const handleSourceCodeSubmit = async (formData) => {
    const { formIsValid, errors } = handleFormValidationPro(versionData, error);
    if (formIsValid) {
      const newVersionData = {
        ...versionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: versionData.buildcommands[0]
            ? versionData.buildcommands
            : [],
          runcommands: versionData.runcommands[0]
            ? versionData.runcommands
            : [],
        },
      };
      setIsLoader(true);
      const { _msg, _status, data } = await addVersion(
        serviceId,
        newVersionData
      );
      if (_status === 201) {
        setVersionId(data?._id);
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        if (tabbedView) {
          props.getVersionList();
        } else {
          histroy.push(`/versions/add/${serviceId}?versionId=${data?._id}`);
        }
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }

      await uploadLocalSourceCode(formData);
      setOpenUploadSourceCodeModal(true);
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        versionEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);

      enqueueSnackbar("Please fill all the mandatory fields", {
        variant: "error",
      });
      setError(errors);
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    if (isTabbedApproach) {
      // histroy.push(`/services/edit/${serviceId}`);
      tabbedActiveTabAction(4);
      setCreateVersion(false);
    } else {

      histroy.push(`/services/edit/${serviceId}`);
    }
    // executeScroll();
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div>
          {isTabbedApproach ? (
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf", marginTop: "-100px" }}
            >
              <h2 className={styles.heading}>Create Version</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TabbedNavigation
                  isCreateMode={true}
                  schemaLength={versionEntitySchema.sections}
                  handleSave={handleSubmit}
                  handleCancel={handleCancel}
                />
              </div>
            </Grid>
          ) : (
            <div className={styles.topContent}>
              {" "}
              <BreadCrumbs
                items={[
                  {
                    title: "Service",
                    path: "/services",
                  },

                  {
                    title: isTabbedApproach
                      ? serviceName
                      : props.location?.state?.serviceName,
                    path: `/services/details/${serviceId}`,
                  },
                  { title: "Create Version" },
                ]}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ borderBottom: "2px solid #afafaf" }}
              >
                <h2 className={styles.heading}>Create Version</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <AccordionNavigation
                    isEditMode={false}
                    disabled={false}
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    isCreateMode={true}
                  />
                </div>
              </Grid>
            </div>
          )}
        </div>
        {isTabbedApproach ? (
          <div
            styles={
              {
                // margin: "10px" ,
                // padding: "130px",
              }
            }
          >
            <Paper style={{ padding: tabbedView ? "20px" : "0px" }}>
              <SectionTabs
                entity={versionEntitySchema ? versionEntitySchema : []}
                handleActiveTab={handleActiveTab}
                activeTab={activeTab}
              />
              <CustomFieldGroupsForVersionCreate
                section={versionEntitySchema[activeTab]}
                isEditMode={!isEditMode}
                isFromCreateVersion={isFromCreateVersion}
                setCreateVersion={setCreateVersion}
                handleSourceCodeSubmit={handleSourceCodeSubmit}
                versionId={versionId}
                repositoryData={repositoryData}
                setRepositoryData={setRepositoryData}
                serviceId={serviceId}
                openUploadSourceCodeModal={openUploadSourceCodeModal}
                setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
                versionData={versionData}
                tabbedView={isTabbedApproach}
                error={error}
                envs={envs}
                setEnvs={setEnvs}
                setError={setError}
                deleteBuildCommand={deleteBuildCommand}
                deleteRunCommand={deleteRunCommand}
                setVersionData={setVersionData}
                handleChange={handleChange}
              />
            </Paper>
          </div>
        ) : (
          <div className={styles.wrapper}>
            {versionEntitySchema &&
              versionEntitySchema.map((section) => {
                if (section.name === "Version_Header_Section") {
                  return (
                    <div
                      key={section._id}
                      style={{ padding: isTabbedApproach ? "0px 5px 0" : "" }}
                    >
                      <CustomFieldGroupsForVersionCreate
                        section={section}
                        isEditMode={!isEditMode}
                        setCreateVersion={setCreateVersion}
                        isFromCreateVersion={isFromCreateVersion}
                        serviceId={serviceId}
                        setOpenUploadSourceCodeModal={
                          setOpenUploadSourceCodeModal
                        }
                        versionId={versionId}
                        openUploadSourceCodeModal={openUploadSourceCodeModal}
                        handleSourceCodeSubmit={handleSourceCodeSubmit}
                        repositoryData={repositoryData}
                        setRepositoryData={setRepositoryData}
                        versionData={versionData}
                        tabbedView={isTabbedApproach}
                        error={error}
                        setError={setError}
                        setVersionData={setVersionData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else if (versionData?.microserviceType === "External") {
                  return <></>;
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForVersionCreate
                          section={section}
                          isEditMode={!isEditMode}
                          isFromCreateVersion={isFromCreateVersion}
                          setCreateVersion={setCreateVersion}
                          setOpenUploadSourceCodeModal={
                            setOpenUploadSourceCodeModal
                          }
                          openUploadSourceCodeModal={openUploadSourceCodeModal}
                          handleSourceCodeSubmit={handleSourceCodeSubmit}
                          repositoryData={repositoryData}
                          serviceId={serviceId}
                          versionId={versionId}
                          setRepositoryData={setRepositoryData}
                          versionData={versionData}
                          error={error}
                          envs={envs}
                          setEnvs={setEnvs}
                          setError={setError}
                          tabbedView={isTabbedApproach}
                          deleteBuildCommand={deleteBuildCommand}
                          deleteRunCommand={deleteRunCommand}
                          setVersionData={setVersionData}
                          handleChange={handleChange}
                        />
                        {/* <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              setCreateVersion={setCreateVersion}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              handleSubmit={(data) => setRepositoryData(data)}
              versionId={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
            /> */}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}

            <UploadSourceCodeInVersion
              openUploadSourceCodeModal={openUploadSourceCodeModal}
              setCreateVersion={setCreateVersion}
              setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
              onCloseClick={() => setOpenUploadSourceCodeModal(false)}
              handleSubmit={(data) => setRepositoryData(data)}
              versionId={versionId}
              serviceId={serviceId}
              isUpload={repositoryData?._id ? true : false}
              repoId={repositoryData?._id ? repositoryData._id : ""}
            />
          </div>
        )}
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getVersionList,
  tabbedActiveTabAction,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVersion);
