import React, { Component } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
// import CodeMirror from "react-codemirror2";
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");

export default class Editor extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  render() {
    const options = {
      lineNumbers: true,
      mode: "javascript",
    };
    const { value } = this.props;
    return (
      <CodeMirror
        value={value}
        options={options}
        // onBeforeChange={(editor, data, value) => {
        //  
        //   this.setState({ value });
        // }}
        onChange={(editor, data, val) => {
          this.setState({ val });
          //
          this.props.onChange(val);
        }}
        autoCursor={false}
      />
    );
  }
}
