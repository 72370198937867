import { useEffect, useState } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { useHistory } from "react-router-dom";
import { AppButton } from "../../components/common/Button";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import {
  addRole,
  fetchAllResourceTypeListData,
} from "../../utils/accessMaintenance/accessMaintenanceUtils";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import { NOT_ONTOLOGY } from "../../constants/accessMaintenance";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";

const CreateRole = () => {
  const styles = manageScreenStyles();
  const histroy = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [resourceTypesPermission, setResourceTypesPermission] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState(false);

  const [selectedResource, setSelectedResource] = useState({});
  const { checkScreenPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("Role", ["Create"]);
    const fetchResourceType = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchAllResourceTypeListData();
      if (_status === 200) {
        setResourceTypes(
          data
            ? data.map((item) => ({
                ...item,
                checked: false,
                permissions: item.permissions.map((per) => ({
                  _id: uuidv4(),
                  name: per,
                  checked: true,
                })),
              }))
            : []
        );
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchResourceType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResourceCheck = (event) => {
    let tempResource = resourceTypesPermission;
    const newResourceTypes = resourceTypes.map((resource) => {
      if (resource._id === event.target.name) {
        if (resource.checked) {
          tempResource = tempResource.filter((res) => res._id !== resource._id);
        } else {
          tempResource.push({ ...resource, checked: !resource.checked });
        }
        return { ...resource, checked: !resource.checked };
      } else {
        return resource;
      }
    });
    setResourceTypes(newResourceTypes);
    setResourceTypesPermission(tempResource);
  };

  const handlePermissionResourceCheck = (event, isCheck) => {
    const newResourceTypes = resourceTypesPermission.map((resource) => {
      if (resource._id === event.target.name) {
        return {
          ...resource,
          checked: !resource.checked,
          permissions: resource.permissions.map((per) => ({
            ...per,
            checked: isCheck,
          })),
        };
      } else {
        return resource;
      }
    });
    setResourceTypesPermission(newResourceTypes);
  };

  const handlePermissionCheck = (event, res) => {
    const newResourceTypes = resourceTypesPermission.map((resource) => {
      if (resource._id === res._id) {
        return {
          ...resource,
          checked:
            resource.permissions.filter((p) => p.checked === true).length === 1
              ? false
              : true,
          permissions: resource.permissions.map((per) => {
            if (per._id === event.target.name) {
              return { ...per, checked: !per.checked };
            } else {
              return per;
            }
          }),
        };
      } else {
        return resource;
      }
    });
    setResourceTypesPermission(newResourceTypes);
  };

  const handleFieldPermissionCheck = (checkedField, permission) => {
    const tempResource = resourceTypesPermission.map((resource) => {
      if (resource._id === selectedResource._id) {
        return {
          ...resource,
          fieldscope: resource.fieldscope.map((field) => {
            if (field._id === checkedField._id) {
              return {
                ...field,
                permissions: field.permissions.map((per) => {
                  if (per.title === permission.title) {
                    return { ...per, checked: !permission.checked };
                  } else {
                    return per;
                  }
                }),
              };
            } else {
              return field;
            }
          }),
        };
      } else {
        return resource;
      }
    });

    setResourceTypesPermission(tempResource);
  };

  const handleGetFields = async () => {
    if (
      resourceTypesPermission.find((res) => res._id === selectedResource?._id)
        ?.fieldscope?.length === undefined
    ) {
      const { _msg, _status, data } = await fetchResourceByName(
        selectedResource?.name
      );
      if (_status === 200) {
        let tempFields = [];
        data.sections.map((section) =>
          section.fieldgroups.map((fieldgroup) =>
            fieldgroup.fields.map((field) =>
              tempFields.push({
                _id: field._id,
                title: field.name,
                permissions: [
                  {
                    title: "Create",
                    checked: field?.accessPermission
                      ?.split(",")
                      .includes("create")
                      ? true
                      : false,
                    disabled: field?.accessPermission
                      ?.split(",")
                      .includes("create")
                      ? false
                      : true,
                  },
                  {
                    title: "Edit",
                    checked: field?.accessPermission
                      ?.split(",")
                      .includes("edit")
                      ? true
                      : false,
                    disabled: field?.accessPermission
                      ?.split(",")
                      .includes("edit")
                      ? false
                      : true,
                  },
                  {
                    title: "Read",
                    checked: field?.accessPermission
                      ?.split(",")
                      .includes("read")
                      ? true
                      : false,
                    disabled: field?.accessPermission
                      ?.split(",")
                      .includes("read")
                      ? false
                      : true,
                  },
                  {
                    title: "Delete",
                    checked: field?.accessPermission
                      ?.split(",")
                      .includes("delete")
                      ? true
                      : false,
                    disabled: field?.accessPermission
                      ?.split(",")
                      .includes("delete")
                      ? false
                      : true,
                  },
                ],
              })
            )
          )
        );

        const tempResource = resourceTypesPermission.map((resource) => {
          if (resource._id === selectedResource._id) {
            return { ...resource, fieldscope: tempFields };
          } else {
            return resource;
          }
        });

        setResourceTypesPermission(tempResource);
      } else {
        setOpenModal(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const getFieldScopes = (resource) => {
    const tempFieldScope = {};
    resource?.fieldscope
      ?.filter(
        (field) =>
          field.permissions.filter((p) => p.checked === true).length !== 0
      )
      .map(
        (field) =>
          (tempFieldScope[field._id] = field.permissions
            .filter((per) => per.checked === true)
            .map((p) => p.title))
      );
    return tempFieldScope;
  };

  const handleSubmit = async () => {
    if (roleName) {
      const newRoleData = {
        name: roleName,
        claims: resourceTypesPermission
          .filter(
            (res) =>
              res.permissions.filter((p) => p.checked === true).length !== 0
          )
          .map((item) => ({
            resourcetype: item._id,
            permissions: item.permissions
              .filter((per) => per.checked)
              .map((i) => i.name),
            fieldscope: getFieldScopes(item),
          })),
      };

      setIsLoader(true);
      const { _msg, _status } = await addRole(newRoleData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        histroy.push("/role-management");
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      setRoleNameError(true);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Role Management", path: "/role-management" },
            { title: "Create Role" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Role Manager</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AppButton
              buttonName="Create"
              variant="contained"
              disabled={false}
              className="btnsmall"
              onClick={handleSubmit}
            />
            <AppButton
              color="primary"
              buttonName={"Cancel"}
              variant="outlined"
              className="btnsmall"
              onClick={() => histroy.goBack()}
            />
          </div>
        </Grid>
      </div>

      <div className={styles.wrapper}>
        <Grid container spacing={3} style={{ marginTop: "1rem" }}>
          <Grid item xs={3} sm={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Resources Types
                </label>
                <div className={styles.resouceTypes}>
                  <FormGroup>
                    {resourceTypes.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={item.checked}
                            color="primary"
                            onChange={handleResourceCheck}
                            name={item._id}
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Role Name
                </label>
                <input
                  type="text"
                  name="name"
                  className={`${styles.input} ${roleNameError && styles.error}`}
                  value={roleName}
                  onChange={(e) => {
                    setRoleName(e.target.value);
                    setRoleNameError(false);
                  }}
                  autoComplete="off"
                />
                {roleNameError && (
                  <p className={styles.errorMsg}>{"Role Name is Required"}</p>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Permissions
                </label>
                <div className={styles.permissions}>
                  <FormGroup>
                    {resourceTypesPermission.map((item, index) => (
                      <div className={styles.permission} key={index}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className="permissionHead"
                        >
                          {item.permissions.filter(
                            (per) => per.checked === true
                          ).length === 0 ||
                          item.permissions.filter(
                            (per) => per.checked === false
                          ).length === 0 ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.checked}
                                  color="primary"
                                  onChange={(e) =>
                                    handlePermissionResourceCheck(
                                      e,
                                      !item.checked
                                    )
                                  }
                                  name={item._id}
                                />
                              }
                              label={item.label}
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={true}
                                  color="primary"
                                  indeterminate
                                  onChange={(e) =>
                                    handlePermissionResourceCheck(
                                      e,
                                      !item.checked
                                    )
                                  }
                                  name={item._id}
                                />
                              }
                              label={item.label}
                            />
                          )}
                          {!NOT_ONTOLOGY.includes(item.name) && (
                            <h6
                              onClick={() => {
                                setSelectedResource(item);
                                setOpenModal(true);
                              }}
                            >
                              Set Field level permissions
                            </h6>
                          )}
                        </Grid>
                        {item.permissions.map((permission, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                checked={permission.checked}
                                color="primary"
                                onChange={(e) => handlePermissionCheck(e, item)}
                                name={permission._id}
                              />
                            }
                            label={permission.name}
                          />
                        ))}
                      </div>
                    ))}
                  </FormGroup>
                </div>
                {resourceTypesPermission.length === 0 && (
                  <div className={styles.notFound}>
                    <img src="/images/notFound.svg" alt="notfound" />
                    <h3>No Permission set for this role</h3>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog
        maxWidth={"xs"}
        scroll={"paper"}
        fullWidth={true}
        open={openModal}
        onEntering={() => handleGetFields()}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle className={styles.modalHeader}>
          {selectedResource?.name} - Set field level permission
        </DialogTitle>
        <DialogContent
          className={styles.modalContent}
          style={{ height: "100vh" }}
        >
          {resourceTypesPermission
            .find((res) => res._id === selectedResource?._id)
            ?.fieldscope?.map((item, index) => (
              <div className={styles.permissionModalField} key={index}>
                <label htmlFor="" className={styles.modalLabel}>
                  {item.title}
                </label>
                <div className={styles.fieldPermission}>
                  {item.permissions.map((permission, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={permission.checked}
                          disabled={permission.disabled}
                          color="primary"
                          onChange={() =>
                            handleFieldPermissionCheck(item, permission)
                          }
                          name={permission.id}
                        />
                      }
                      label={permission.title}
                    />
                  ))}
                </div>
              </div>
            ))}
        </DialogContent>
        <DialogActions className={styles.modalFooter}>
          <AppButton
            buttonName="Cancel"
            variant="outlined"
            disabled={false}
            className="btnsmall"
            onClick={() => {
              setResourceTypesPermission((pervRes) => {
                if (pervRes._id === selectedResource._id) {
                  return { ...pervRes, fieldscope: [] };
                } else {
                  return pervRes;
                }
              });
              setOpenModal(false);
            }}
          />
          <AppButton
            buttonName="Set"
            variant="contained"
            disabled={false}
            className="btnsmall"
            onClick={() => setOpenModal(false)}
          />
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default CreateRole;
