import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../../components/common/stuff/Loader";
import DeploymentDetailsListTable from "../../../components/common/Tables/DeploymentDetailsListTable";
import {
  deploymentColumnHidden,
  deploymentColumnOrder,
  deploymentColumns,
  deploymentDefaultColumnWidths,
  deploymentTableColumnExtensions,
} from "../../../constants/deploymentDetailsConstants";
import {
  addDeploymentDetailsHidden,
  fetchDeploymentById,
} from "../../../redux/actions/deploymentProcessAction";
import listStyles from "../../CommonStyles/listScreenStyles";
import { FetchDeploymentById } from "../../../utils/deployment/deploymentUtils";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupForDeploymentDetail from "./CustomFieldGroupForDeploymentDetail";
import { ArrowDropDownRounded } from "@material-ui/icons";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import TestExecutionHistoryListTable from "./TestExecutionHistoryListTable";

const DetailDeployment = (props) => {
  const {
    fetchDeploymentById,
    data,
    error,
    loading,
    deploymentname,
    DetailDeployment,
    deploymenttype,
    deptypeid,
    deployments,
    deploymentDetailsHidden,
    addDeploymentDetailsHidden,
    appSelectionData,
  } = props;
  console.log("knklnefinef", appSelectionData);
  const styles = listStyles();
  const style = manageScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const deploymentId = props.match.params.deploymentId;
  const [tableLoading, setTableLoading] = useState(true);
  const [deploymentRows, setDeploymentRows] = useState([]);
  const [deploymentSection, setDeploymentSection] = useState();
  const [errors, setErrors] = useState({
    name: { isError: false },
  });
  const [tableData, setTableData] = useState({
    columns: deploymentColumns,
    widths: deploymentDefaultColumnWidths,
    extentions: deploymentTableColumnExtensions,
    orders: deploymentColumnOrder,
    hides: [],
  });
  const handleEdit = () => {
    histroy.push(`/host/edit/${deploymentId}`);
  };

  useEffect(() => {
    fetchDeploymentById(deploymentId);
  }, []);

  useEffect(async () => {
    const { data, _msg, _status } = await fetchResourceByName("Host");

    if (_status === 200) {
      data?.sections.map((section) => {
        if (section.name === "Deployment_Details_Heaader_Se") {
          setDeploymentSection(section);
        }
      });
    }
  }, []);

  useEffect(() => {
    const getDeployment = async () => {
      const { _msg, _status, data } = await FetchDeploymentById(deploymentId);
      if (_status === 200) {
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    getDeployment();
  }, []);

  useEffect(() => {
    setDeploymentRows(
      data?.length > 0 && data
        ? data?.map((item) => {
            const containerName = item.ContainerName;
            let dependencies;
            item["DeploymentID"] = DetailDeployment._id;
            deployments.map((item) => {
              if (item.container_name === containerName) {
                if (item.dependencies === true) {
                  dependencies = true;
                }
              }
            });
            item["main"] = DetailDeployment;
            item["dependencies"] = dependencies;
            // item["EndPoint"] = endPoint
            return item;
          })
        : []
    );
  }, [data]);

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Deployment List", path: "/host" },
            { title: "Deployment", path: "/host" },
            { title: deploymentname },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{deploymentname}</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AppButton
              // startIcon={<img src="/images/edit.svg" alt="card" />}
              color="primary"
              buttonName="Edit"
              variant="contained"
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleEdit}
            />
            <AppButton
              color="primary"
              buttonName="Close"
              variant="outlined"
              style={{ margin: "unset", marginLeft: 10 }}
              className="btnsmall"
              onClick={() => histroy?.push("/host")}
            />
          </div>
        </Grid>
      </div>
      <Paper className={styles.pageContent}>
        <Grid style={{ marginBottom: "50px" }}>
          {console.log("ytruroeew", DetailDeployment)}
          <CustomFieldGroupForDeploymentDetail
            section={deploymentSection}
            error={errors}
            setError={setErrors}
            deploymentname={deploymentname}
            DetailDeployment={DetailDeployment}
          />
        </Grid>
        <Grid>
          {tableLoading ? (
            <Loader />
          ) : (
            <DeploymentDetailsListTable
              columns={tableData.columns}
              rows={deploymentRows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              loading={loading}
              // isCustomHeight={true}
              hidden={deploymentDetailsHidden}
              setHidden={addDeploymentDetailsHidden}
              noDataMsg="No Deployments Found"
              isCustomWidth={150}
            />
          )}
        </Grid>
        <Grid style={{ paddingTop: "25px" }}>
          <Grid item xs={12} sm={12}>
            <Accordion className={style.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={style.accordianIcon} />
                }
              >
                <Typography className={style.sectionHeading}>
                  Test Execution History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ margin: "0px -18px" }}>
                  <TestExecutionHistoryListTable deploymentId={deploymentId} />
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
};

const mapDispatchToProps = {
  fetchDeploymentById,
  addDeploymentDetailsHidden,
};
const mapStateToProps = (state) => {
  const { error, loading } =
    state.deploymentProcessReducer.deploymentDetailsList;
  const { deploymentDetails } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { data } = state.deploymentProcessReducer.deploymentDetailsList;
  const { deploymentname, deploymenttype, deptypeid, deployments } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { appSelectionData } = state.deploymentProcessReducer;

  const { deploymentDetailsHidden } = state.deploymentProcessReducer;
  return {
    data: deploymentDetails,
    DetailDeployment: data,
    error,
    loading,
    deploymentname,
    deploymenttype,
    deptypeid,
    deployments,
    deploymentDetailsHidden,
    appSelectionData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailDeployment);
