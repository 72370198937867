import { UPDATE_ACTIVE_PATH ,UPDATE_EDIT_KEY, UPDATE_LOACKED_PATHS, UPDATE_LOGS, UPDATE_NEW_KEY, UPDATE_TREE, UPDATE_UN_LOACKED_PATHS} from "../actionTypes";

const initialState = {
    activePath:'',
    isEdit:false,
    logs:[],
    isNew:false,
    lockedPaths:'',
    treeData: []
}

export default function codeEditorReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACTIVE_PATH: {
            return {
                ...state,
                activePath: action.payload
            }
        }
        case UPDATE_EDIT_KEY: {
            return {
                ...state,
                isEdit:action.payload
            }
        }
        case UPDATE_LOGS: {
            return {
                ...state,
                ...logs,
                name: action.payload
            }
        }

        case UPDATE_NEW_KEY: {
            return {
                ...state,
                isNew:action.payload
            }
        }

        case UPDATE_LOACKED_PATHS: {
            return {
                ...state,
                lockedPaths:action.payload
            }
        }

        case UPDATE_UN_LOACKED_PATHS: {
            return {
                ...state,
                lockedPaths:''
            }
        }

        case UPDATE_TREE: {
            return {
                ...state,
                treeData:action.payload
            }
        }

        default:
            return state
    }
}