import { ADD_USER_AUTH_DATA } from "../actionTypes";

const initialState = {
    accesstoken: "",
    refreshtoken: "",
    userid: "",
    userAction : {}
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_USER_AUTH_DATA: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}