import React, {  useState } from "react";
import {
  Paper,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import Editor from "../../common/Editor";
import inputStyles from "./styles";
import InputTable from "../../common/Tables/InputTable";
import JSONToTable from "../../common/JSONToTable";
// import { getKeyValueFromStringJSON } from "../../../utils/orchestration/orchestrationUtils"

const temp_data = [
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

function NodeInputOutputModal(props) {
  const [activetab, setActiveTab] = useState("output");
  const [activeInputType, setActiveInputType] = useState("text");
  const [inputList, setInputList] = useState(temp_data);
  const [outputEditorValue, setOuputEditorValue] = useState("");
  const [inputEditorValue, setInputEditorValue] = useState("");
  const [activeOutputType, setActiveOutputtype] = useState("text");
  const [outputJSONValue, setOutputJSONValue] = useState({});
  const classes = inputStyles();

  const {
    isOpen,
    node_name,
    template_context_data,
    debug_context_data,
    debug_status,
    simulate_status,
  } = props;

  const handleTabChange = (event, val) => {
    setActiveTab(val);
  };

  const handleInputTypeChange = (val) => {
    setActiveInputType(val);
  };

  const handleEnter = () => {
    var outputValue = {};
    if (simulate_status) {
      const node_details = template_context_data?.nodes?.find((node_) => node_.name === node_name);
      if (node_details) {
        outputValue = node_details.context;
        setOutputJSONValue(JSON.parse(outputValue))
        setOuputEditorValue(outputValue, null,'\t')
      }
    } else if (debug_status) {
      outputValue = debug_context_data?.context[node_name]
        ? debug_context_data?.context[node_name]
        : {};
      setOutputJSONValue(() => outputValue)
      setOuputEditorValue(JSON.stringify(outputValue, null,'\t'))
    }
  };

  return (
    <div>
      <Dialog
        onEnter={handleEnter}
        open={isOpen}
        onClose={() => props.onSubmit()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1}>
            <p className={classes.heading}>{`${node_name} Input-Output`}</p>
            <IconButton
              color="inherit"
              aria-label="editor"
              edge="start"
              className={classes.closeIcon}
            >
              <img src="/images/closeIcon.svg" alt="call node" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className={classes.container}>
            <div className={classes.tab_container}>
              <Paper className={classes.root}>
                <Tabs
                  value={activetab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                // centered
                >
                  <Tab label="Output" value="output" />
                  <Tab label="Input" value="input" />
                </Tabs>
              </Paper>
              {activetab === "input" && (
                <div className={classes.iconContainer}>
                  <IconButton
                    color="inherit"
                    aria-label="textfield"
                    edge="start"
                    className={`${activeInputType === "text" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons}`}
                    onClick={() => handleInputTypeChange("text")}
                  >
                    <img src="/images/orchestration/lineicon.svg" alt="text" />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="editor"
                    edge="start"
                    className={`${activeInputType === "editor" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons}`}
                    onClick={() => handleInputTypeChange("editor")}
                  >
                    <img src="/images/orchestration/editoricon.svg" alt="editor" />
                  </IconButton>
                </div>
              )}
              {activetab === "output" && (
                <div className={classes.iconContainer}>
                  <IconButton
                    color="inherit"
                    aria-label="textfield"
                    edge="start"
                    className={`${activeOutputType === "text" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons}`}
                    onClick={() => setActiveOutputtype("text")}
                  >
                    <img src="/images/orchestration/lineicon.svg" alt="text" />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="editor"
                    edge="start"
                    className={`${activeOutputType === "editor" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons}`}
                    onClick={() => setActiveOutputtype("editor")}
                  >
                    <img src="/images/orchestration/editoricon.svg" alt="editor" />
                  </IconButton>
                </div>
              )}
            </div>
            <div className={classes.line5}>
              {/* <p className={classes.label}>Input</p> */}
              {activetab === "input" && activeInputType === "editor" && (
                <div className={classes.outputbody}>
                  <Editor
                    value={inputEditorValue}
                    onChange={(val) => setInputEditorValue(val)}
                  />
                </div>
              )}
              {activetab === "input" && activeInputType === "text" && (
                <InputTable envs={inputList} setEnvs={setInputList} />
              )}
              {activetab === "output" && activeOutputType === "editor" && (
                <div className={classes.outputbody}>
                  <Editor
                    value={outputEditorValue}
                    onChange={(val) => setOuputEditorValue(val)}
                  />
                </div>
              )}
              {activetab === "output" && activeOutputType === "text" && (
                <JSONToTable jsonBody={outputJSONValue} />
              )}
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
                    <Button
                        className={classes.cancel}
                        onClick={() => props.onCloseClick()}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.submit}
                        onClick={() => props.onSubmitClick()}
                    >
                        Submit
                    </Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { process_output, debug_data, simulate_status } = state.orchestration;
  const { template_context_data } = process_output;
  const { debug_status, debug_context_data } = debug_data;
  return {
    template_context_data: template_context_data,
    debug_context_data: debug_context_data,
    debug_status: debug_status,
    simulate_status: simulate_status,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NodeInputOutputModal);
