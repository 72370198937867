import React, { useEffect, useState } from "react";
import InputAdvance from "../../atoms/input/InputAdvance";
import { useDispatch } from "react-redux";
import useTriggers from "../../../hooks/useTriggers";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";

const InputComponent = ({ id }) => {
  const dispatch = useDispatch();
  const { handleTrigger } = useTriggers();
  const { currentPage, getComponent, currentComponent } = useGetData();
  const selectedComponent = getComponent(id);
  const [val, setVal] = useState(selectedComponent?.data?.dataValue);
  const [flag, setFlag] = useState(false);

  const handleChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          data: {
            ...component.data,
            dataValue: e.target.value,
            dataInput: e.target.value,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
    if (selectedComponent["onChange"]) {
      setFlag(true);
    }
  };

  const handleClick = () => {
    if (selectedComponent["onClick"])
      handleTrigger({
        action: currentComponent?.["onClick"],
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: [],
      });
  };
  useEffect(() => {
    if (flag === true) {
      if (selectedComponent["onChange"]) {
        handleTrigger({
          action: currentComponent?.["onChange"],
          isUpdateState: true,
          componentId: currentComponent?.id,
          data: [],
        });
        setFlag(false);
      }
    }
  }, [flag]);

  useEffect(() => {
    if (currentComponent?.onChange) {
      handleTrigger({
        action: currentComponent?.onChange,
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: currentComponent?.data?.dataValue,
      });
    }
  }, []);

  const inputLabel = selectedComponent?.label?.dataValue;
  const placeHolderText = selectedComponent?.placeHolder?.dataValue;
  return (
    <div
      style={{
        padding: "5px 10px 0px 10px",
        fontSize: selectedComponent?.fontSize
          ? selectedComponent?.fontSize
          : 16,
      }}
      onClick={handleClick}
    >
      <InputAdvance
        name="input"
        placeHolder={
          placeHolderText && typeof placeHolderText === "string"
            ? placeHolderText
            : ""
        }
        label={
          inputLabel && typeof inputLabel === "string" ? inputLabel : "Label"
        }
        value={selectedComponent?.data?.dataValue}
        onChange={handleChange}
        selectedComponent={selectedComponent}
        disabled={selectedComponent?.disabled?.dataValue === "true"}
      />
    </div>
  );
};

export default InputComponent;
