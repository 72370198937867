import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modelwrapper: {
    height: "600px !important",
    "& .MuiTypography-root-MuiDialogTitle-root": {
      font: "normal normal 900 16px/22px Nunito Sans",
    },
    "& .MuiDialogActions-root": {
      background: "red 0% 0% no-repeat padding-box !important",
      paddingRight: 20,
    },
  },
  modelBody: {
    minHeight: 400,
    width: 550,
    padding: 5,
    "& span": {
      font: "normal normal 600 16px/22px Nunito Sans",
    },
  },
  pagesTable: {
    marginTop: 10,
    color: "#FFFFFF",
    "& .MuiTableHead-root": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiTableCell-root": {
        color: "#fff !important",
      },
    },
  },
}));
