import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modelwrapper: {
    height: "200px !important",
    "& .MuiTypography-root-MuiDialogTitle-root": {
      font: "normal normal 900 16px/22px Nunito Sans",
    },
    "& .MuiDialogActions-root": {
      background: "red 0% 0% no-repeat padding-box !important",
      paddingRight: 20,
    },
  },
  modelTitle: {
    "& h2": {
      font: "normal normal bold 18px/20px Nunito"
    }
  },
  modelBody: {
    minHeight: 200,
    width: 300,
    padding: 5,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #DEDEDF',
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
    cursor: 'pointer',

    "&:hover": {
      border: "1px solid #47B0FF"
    },

    "& div": {
      font: "normal normal 600 14px/22px Nunito Sans",
      display: 'flex',
      alignItems: 'center',

      "& img": {
        marginRight: 15
      }
    },

    "& span": {
      font: "normal normal 600 12px/16px Nunito Sans;",
      color: "#000000",
      opacity: 0.5,
    }
  }
}));