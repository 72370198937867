import React from "react";
import { TextField, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import {
  setActionOpen,
  addAction,
} from "../../../../../redux/actions/uiApplicationAction";
import settingsStyles from "../../../../styles/commonStyles/Setting";
import useGetData from "../../../../hooks/useGetData";
import { UiApplicationService } from "../../../../services/UiApplicationService";
import { useSnackbar } from "notistack";
export default function TriggerAction({ label, project, id, value, onChange }) {
  const styles = settingsStyles();
  const { currentProject } = useGetData();
  const { enqueueSnackbar } = useSnackbar();
  const apiService = new UiApplicationService();

  const AddActionBtn = ({ children, ...other }) => {
    const dispatch = useDispatch();
    return (
      <Paper {...other}>
        <button
          style={{
            background: "#DEE9F8 0% 0% no-repeat padding-box",
            width: "100%",
            padding: "8px 12px",
            textAlign: "left",
            boxShadow: "0px 3px 10px #6D6D6D1A",
            border: "1px solid #DFDFDF",
            borderRadius: "5px 5px 0px 0px",
            opacity: 1,
            cursor: "pointer",
          }}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          onClick={async () => {
            dispatch(setActionOpen({ actionOpen: true }));
            const data = {
              service: {},
              api: {},
              name: `action${currentProject?.actions?.length + 1}`,
              actionType: "HTTP",
              data: {},
            };
            try {
              const resp = await apiService.addAction(currentProject?.id, data);
              if (resp?.status === 200) {
                enqueueSnackbar(resp?.data?._msg, { variant: "success" });
                dispatch(
                  addAction({
                    data: data,
                  })
                );
              }
            } catch (err) {
              enqueueSnackbar(err?.response?.data?._msg, { variant: "error" });
            }
          }}
        >
          + Add New Action
        </button>
        {children}
      </Paper>
    );
  };

  return (
    <div>
      <label>{label}</label>
      <Autocomplete
        id={id}
        options={currentProject?.actions}
        getOptionLabel={(option) => option && option.name}
        className={styles.autocomplete2}
        renderInput={(params) => <TextField {...params} variant="standard" />}
        onChange={(e, value) => onChange(e, value)}
        value={value}
        PaperComponent={AddActionBtn}
      />
    </div>
  );
}
