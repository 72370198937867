import {
    USER_FAVOURITES, ADD_SINGLE_FAVOURITE, REMOVE_SINGLE_FAVOURITE, USER_SUBSCRIPTION, ADD_SINGLE_USER_SUBSCRIPTION,
    REMOVE_SINGLE_USER_SUBSCRIPTION,USER_NOTIFICATIONS
} from '../../constants/UserProfile/user';

const initialState = {
    userFavourites: [],
    userSubscriptions: [],
    Notifications : {}
}

const userPersonalReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FAVOURITES:
            return { ...state, loading: false, userFavourites: action.payload };
        case ADD_SINGLE_FAVOURITE:
            return {
                ...state,
                userFavourites: [...state.userFavourites, action.payload],
            };
        case REMOVE_SINGLE_FAVOURITE:
            return {
                ...state,
                userFavourites: state.userFavourites.filter(
                    (fav) => fav.resourceinstance !== action.payload
                ),
            };
        case USER_SUBSCRIPTION:
            return { ...state, loading: false, userSubscriptions: action.payload };
        case ADD_SINGLE_USER_SUBSCRIPTION:
            return {
                ...state,
                userSubscriptions: [...state.userSubscriptions, action.payload],
            };
        case REMOVE_SINGLE_USER_SUBSCRIPTION:
            return {
                ...state,
                userSubscriptions: state.userSubscriptions.filter(
                    (sub) => sub.name.split("_")[1] !== action.payload
                ),
            };
        case USER_NOTIFICATIONS:
            return {
                ...state,
                Notifications : action.payload
            };
        default:
            return state;
    }
}

export default userPersonalReducer;