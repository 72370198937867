import React from "react";
import { Doughnut } from "react-chartjs-2";

const MemoryChart = ({ node }) => {
  return (
    <div>
      <Doughnut
        key={node?.Name}
        data={{
          labels: ["Allocatable", "Used"],
          datasets: [
            {
              data: [
                (
                  (node?.Capacity?.Memory -
                    (node?.Capacity?.Memory -
                      node?.Allocatable?.Memory +
                      node?.Usage?.Memory)) /
                  (1024 * 1024 * 1024)
                ).toFixed(2),
                (
                  (node?.Usage?.Memory +
                    (node?.Capacity?.Memory - node?.Allocatable?.Memory)) /
                  (1024 * 1024 * 1024)
                ).toFixed(2),
              ],
              backgroundColor: ["#808080", "#b09900"],
            },
          ],
          borderColor: ["#16C818", "#17A5BF"],
        }}
        options={{
          cutout: 80,
          responsive: true,
          plugins: {
            legend: { position: "bottom" },
            title: {
              display: false,
              text: "Memory",
            },
          },
        }}
        plugins={[
          {
            beforeDraw: function (chart, args, options) {
              var width = chart.width;
              var height = chart.height;
              var ctx = chart.ctx;

              ctx.restore();
              ctx.font = "normal normal 600 20px/22px Nunito";
              ctx.textBaseline = "middle";

              var text = `${(
                node?.Capacity?.Memory /
                (1024 * 1024 * 1024)
              ).toFixed(2)} Memory`;
              var textX = Math.round((width - ctx.measureText(text).width) / 2);
              var textY = height / 2.15;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
      />
    </div>
  );
};

export default MemoryChart;
