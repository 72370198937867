import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: 45
  },
  nodata: {
    height: "5rem",
    position: "sticky",
    left: "45%",
    top: "1rem",
    paddingTop: "1rem",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  checkbox: {
    color: theme.palette.primary.main
  }
}));
