import {
  //
  ADD_LAUNGUAGE_DATA,
  LANGUAGE_FILTERS,
  DELETE_LANGUAGE_BY_ID,
  LANGUAGE_SORT,
  BLOCK_UNBLOCK_LANGUAGE,
  CLEAR_LANGUAGE_DATA,
  LANGUAGE_HIDDEN_COULMNS,
  //
  ADD_BOILER_PLATE_DATA,
  BOILERPLATE_FILTERS,
  DELETE_BOILERPLATE_BY_ID,
  BOILERPLATE_SORT,
  CLEAR_BOILER_PLATE_DATA,
  BOILERPLATE_HIDDEN_COULMNS,
  //
  ADD_CHASSIS_TEMPLATE_DATA,
  CHASSIS_TEMPLATE_FILTERS,
  DELETE_CHASSIS_TEMPLATE_BY_ID,
  CHASSIS_TEMPLATE_SORT,
  CLEAR_CHASIS_TEMPLATE,
  CHASSIS_TEMPLATE_HIDDEN_COULMNS,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  launguage_list: {
    data: [],
    error: false,
    loading: false,
  },
  boilerPlate_list: {
    data: [],
    error: false,
    loading: false,
  },
  chassisTemplate_list: {
    data: [],
    error: false,
    loading: false,
  },
  languageFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  boilerPlateFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  TemplateFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  languageSort: [{ columnName: "createdon", direction: "desc" }],
  boilerPlateSort: [{ columnName: "createdon", direction: "desc" }],
  templateSort: [{ columnName: "createdon", direction: "desc" }],
  languageHiddenCols: [
    "icons",
    "name",
    "status",
    "createdon",
    "languageactions",
  ],
  boilerplateHiddenCols: [
    "BoilerplateName",
    "languagename",
    "createdby",
    "createdon",
    "boilerplateactions",
  ],
  templateHiddenCols: [
    "ChassisTemplate",
    "language",
    "createdby",
    "createdon",
    "chassisactions",
  ],
};

export default function appGovernanceReducer(state = initialState, action) {
  switch (action.type) {
    // LANGUAGE
    case ADD_LAUNGUAGE_DATA: {
      return {
        ...state,
        launguage_list: {
          ...state.launguage_list,
          ...action.payload,
          deleteLanguageStatus: false,
        },
      };
    }
    case LANGUAGE_FILTERS: {
      return {
        ...state,
        languageFilters: [...action.payload],
      };
    }
    case LANGUAGE_SORT: {
      return {
        ...state,
        languageSort: [...action.payload],
      };
    }
    case DELETE_LANGUAGE_BY_ID: {
      return {
        ...state,
        launguage_list: {
          ...state.launguage_list,
          data:
            state.launguage_list.data &&
            state.launguage_list.data.filter(
              (lang) => lang._id !== action.payload.LangID
            ),
          _totalcount: state.launguage_list._totalcount - 1,
          deleteLanguageStatus: true,
        },
      };
    }
    case BLOCK_UNBLOCK_LANGUAGE: {
      return {
        ...state,
        launguage_list: {
          ...state.launguage_list,
          data:
            state.launguage_list.data &&
            state.launguage_list.data.map((item) => {
              if (item._id === action.payload.languageID) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case CLEAR_LANGUAGE_DATA: {
      return {
        ...state,
        launguage_list: {},
      };
    }

    case LANGUAGE_HIDDEN_COULMNS: {
      return {
        ...state,
        languageHiddenCols: [...action.payload],
      };
    }
    // BOILERPLATE
    case ADD_BOILER_PLATE_DATA: {
      return {
        ...state,
        boilerPlate_list: {
          ...state.boilerPlate_list,
          ...action.payload,
          deleteBoilerPlateStatus: false,
        },
      };
    }
    case BOILERPLATE_FILTERS: {
      return {
        ...state,
        boilerPlateFilters: [...action.payload],
      };
    }
    case BOILERPLATE_SORT: {
      return {
        ...state,
        boilerPlateSort: [...action.payload],
      };
    }
    case DELETE_BOILERPLATE_BY_ID: {
      return {
        ...state,
        boilerPlate_list: {
          ...state.boilerPlate_list,
          data:
            state.boilerPlate_list.data &&
            state.boilerPlate_list.data.filter(
              (boiler) => boiler._id !== action.payload.bPlateID
            ),
          _totalcount: state.boilerPlate_list._totalcount - 1,
          deleteBoilerPlateStatus: true,
        },
      };
    }
    case CLEAR_BOILER_PLATE_DATA: {
      return {
        ...state,
        boilerPlate_list: {},
      };
    }

    case BOILERPLATE_HIDDEN_COULMNS: {
      return {
        ...state,
        boilerplateHiddenCols: [...action.payload],
      };
    }
    // template
    case ADD_CHASSIS_TEMPLATE_DATA: {
      return {
        ...state,
        chassisTemplate_list: {
          ...state.chassisTemplate_list,
          ...action.payload,
          deleteChassisTemplateStatus: false,
        },
      };
    }
    case CHASSIS_TEMPLATE_FILTERS: {
      return {
        ...state,
        TemplateFilters: [...action.payload],
      };
    }
    case CHASSIS_TEMPLATE_SORT: {
      return {
        ...state,
        templateSort: [...action.payload],
      };
    }
    case DELETE_CHASSIS_TEMPLATE_BY_ID: {
      return {
        ...state,
        chassisTemplate_list: {
          ...state.chassisTemplate_list,
          data:
            state.chassisTemplate_list.data &&
            state.chassisTemplate_list.data.filter(
              (temp) => temp._id !== action.payload.TemplateID
            ),
          _totalcount: state.chassisTemplate_list._totalcount - 1,
          deleteChassisTemplateStatus: true,
        },
      };
    }
    case CLEAR_CHASIS_TEMPLATE: {
      return {
        ...state,
        chassisTemplate_list: {},
      };
    }

    case CHASSIS_TEMPLATE_HIDDEN_COULMNS: {
      return {
        ...state,
        templateHiddenCols: [...action.payload],
      };
    }
    default:
      return state;
  }
}
