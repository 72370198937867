import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import CreateCustomStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import {
  fetchAllImages,
  createContainer,
} from "../../../utils/containerMangement/containerUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { AppButton } from "../../../components/common/Button";
import CreateContainerEntity from "../../../components/common/entitySchema/containerManagement/container/CreateContainerEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { connect } from "react-redux";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const tempEnvs = [{ id: uuidv4(), key: "", value: "" }];

const CreateContainerScreen = (props) => {
  const styles = CreateCustomStyles();
  const defaultStyles = manageScreenStyles();
  const histroy = useHistory();
  const [imageList, setImageList] = useState([]);
  const [envList, setEnvlist] = useState(tempEnvs);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [containerEntitySchema, setContainerEntitySchema] = useState();
  const [containerdata, setContainerData] = useState({});
  const [error, setError] = useState({});
  const [selectedImage, setSelectedImage] = useState()

  // const { checkScreenPermission } = useCheckPermission();
  // useEffect(() => {
  //   checkScreenPermission("Container", ["Create"]);
  // }, []);

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/containers");
  };

  useEffect(() => {
    setLoading(true);
    const fetchContainerEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Container",
        "create"
      );
      if (_status === 200) {
        setContainerEntitySchema(data);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });
        setContainerData({ ...containerdata, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError(errData);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          {
            variant: "error",
          }
        );
      }
    };

    fetchContainerEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });


    if (event.target.name === "containername") {
      if (value.length === 0) {
        setError({ ...error, [event.target.name]: { ...error[event.target.name], isError: true, dataType: "string", maxLength: 300, maximum: 300, minLength: 1, minimum: 1, section: 0, fieldLabel: 'Container Name' } });
      }
    } else {
      setSelectedImage(value)
    }
    setContainerData({
      ...containerdata,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    const fetchImagesList = async () => {
      const { _msg, _status, data } = await fetchAllImages();
      if (_status === 200) {
        setImageList(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          {
            variant: "error",
          }
        );
      }
    };
    fetchImagesList();
  }, []);

  const handleSubmit = async () => {
    const env_arr = [];
    envList.length > 0 &&
      envList.forEach((item) => {
        if (item.key !== "" && item.value !== "") {
          const temp_str = "".concat(item.key, "=", item.value);
          env_arr.push(temp_str);
        }
      });
    containerdata["environmentVariable"] = null;

    const { formIsValid, errors } = handleFormValidationPro(
      containerdata,
      error
    );
    if (formIsValid) {
      const req_body = {
        ...containerdata,
        containername: containerdata.containername,
        config: {
          Image: containerdata.config,
          Env: env_arr,
        },
      };
      setLoading(true);
      const { _msg, _status, data } = await createContainer(req_body);
      if (_status < 210) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        histroy.push("/containers");
      } else {
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong ", {
            variant: "error",
          });
        }
      }
      setLoading(false);
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        containerEntitySchema ? containerEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setContainerEntitySchema({
        ...containerEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Containers", path: "/containers" },
            { title: "Create Container", path: "" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}

        >
          <h2 className={styles.title}>Create Container</h2>
          {tabbedView ? (
            <TabbedNavigation
              isCreateMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              isCreateMode={true}
              isEditMode={false}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              disabled={false}
            />

          )}
        </Grid>
        {/* <div className={styles.border_line} /> */}
      </div>
      <div
        className={tabbedView ? styles.wrapper : defaultStyles.wrapper}
        style={tabbedView ? { top: "6.5rem" } : { top: "7rem" }}
      >
        {tabbedView ? (
          <>
            <SectionTabs
              entity={
                containerEntitySchema ? containerEntitySchema.sections : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CreateContainerEntity
                  section={containerEntitySchema?.sections?.[activeTab]}
                  handleChange={handleChange}
                  setContainerData={setContainerData}
                  containerdata={containerdata}
                  error={error}
                  envList={envList}
                  setEnvlist={setEnvlist}
                  imageList={imageList}
                  stylesDocker={styles}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </Paper>
          </>
        ) : (
          containerEntitySchema?.sections &&
          containerEntitySchema?.sections.map((section, index) => {
            if (section.name === "Container_Header_section") {
              return (
                <div key={section._id} style={{ padding: "75px 0px 5px 0" }}>
                  <CreateContainerEntity
                    section={section}
                    handleChange={handleChange}
                    setContainerData={setContainerData}
                    containerdata={containerdata}
                    error={error}
                    envList={envList}
                    setEnvlist={setEnvlist}
                    imageList={imageList}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </div>
              );
            } else if (section.name === "Container_Config_Section") {
              return (
                <Element name={index}>
                  <Accordion
                    className={defaultStyles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={defaultStyles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={defaultStyles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={3} sm={3}>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Image <span className={styles.red}>*</span>
                        </label>
                        <Select
                          native
                          name="config"
                          onChange={handleChange}
                          displayEmpty
                          className={`${styles.select} ${error["config"]?.isError && styles.error
                            }`}
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <option value=""></option>
                          <optgroup label="General">
                            {imageList?.length > 0 &&
                              imageList
                                ?.filter((fil) => fil?.state === "build success")
                                .map((item) => {
                                  return (
                                    <>
                                      {item.buildtype === "internal" && (
                                        <option key={item._id} value={item._id}>
                                          {item?.imagename} : {item?.imagetag}
                                        </option>
                                      )}
                                    </>
                                  );
                                })}
                          </optgroup>
                          <optgroup label="Custom build">
                            {imageList?.length > 0 &&
                              imageList
                                ?.filter((fil) => fil?.state === "build success")
                                .map((item) => {
                                  return (
                                    <>
                                      {item.buildtype === "external" && (
                                        <option key={item._id} value={item._id}>
                                          {item?.imagename} : {item?.imagetag}
                                        </option>
                                      )}
                                    </>
                                  );
                                })}
                          </optgroup>
                        </Select>
                        {error["config"]?.isError && (
                          <p className={styles.errorMsg}>
                            {"Docker Image is Required"}
                          </p>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Element>
              );
            } else {
              return (
                <Element name={index}>
                  <Accordion
                    className={defaultStyles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={defaultStyles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={defaultStyles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CreateContainerEntity
                        section={section}
                        handleChange={handleChange}
                        setContainerData={setContainerData}
                        containerdata={containerdata}
                        error={error}
                        envList={envList}
                        setEnvlist={setEnvlist}
                        imageList={imageList}
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Element>
              );
            }
          })
        )}
        { }
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(CreateContainerScreen);
