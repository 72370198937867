import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, Link } from "react-router-dom";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { deleteVisitedRoute } from "../../redux/actions/recentlyVisitedAction";
import User from "../../screens/UserProfile/Assets/profile.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Backdrop,
  Divider,
  Menu,
  MenuItem,
  Grid,
  Badge,
} from "@material-ui/core";
import { connect } from "react-redux";
import { addAuthData } from "../../redux/actions/authAction";
import TopMenuBar from "./TopMenuBar";
import { USER_RESET } from "../../constants/UserProfile/user";
import { routeVisited } from "../../redux/actions/recentlyVisitedAction";
import { changeTabByUser } from "../../redux/actions/userProfileAction";
import { UserProfile } from "../../services/userProfile";
import { addUserNotification } from "../../redux/actions/userPersonalAction";
import { useSnackbar } from "notistack";
import { logOutUser } from "../../utils/authentication/authenticationUtils";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  navbar: {
    backgroundColor: theme.palette.primary.main,
    "& > div": {
      minHeight: 60,
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    height: 35,
    width: 35,
    borderRadius: 10,
    transition: ".3s",
    backgroundColor: "transparent",
  },
  menuButtonClose: {
    marginRight: theme.spacing(2),
    height: 35,
    width: 35,
    borderRadius: 10,
    transition: ".3s",
    backgroundColor: "#FFFFFF",
    transform: "rotate(45deg)",

    "&.MuiIconButton-root:hover": {
      backgroundColor: "#FFFFFF",
    },

    "& img": {
      transform: "rotate(-45deg)",
    },
  },
  title: {
    display: "none",
    marginRight: 50,
    marginLeft: 5,
    font: "normal normal bold 21px/28px Nunito",
    color: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
      cursor: "pointer",
      display: "block",
    },
  },
  profileText: {
    ...theme.typography.fontFamily,
    fontSize: "14px",
    paddingLeft: 10,
    color: "#FFFFFF",
    cursor: "pointer",
    opacity: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuPaper: {
    width: "245px",
    height: "auto",
    marginTop: 33,
    borderRadius: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #BEBEBE",
    opacity: 1,
    backgroundColor: "#fff",
  },
  menuItem: {
    height: "3.7rem",
    color: theme.palette.common.black,
    opacity: 1,
    "&:hover": {
      opacity: 0.9,
    },
  },
  menuSpecial: {
    height: "4.5rem",
  },
  imgBox: {
    width: 47,
    height: 47,
    border: "1px solid #E3E3E3",
    background: "#f2f2f2 0% 0% no-repeat padding-box",
  },
  titleMenu: {
    ...theme.typography.fontFamily,
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.common.black,
    opacity: 1,
  },
  email: {
    ...theme.typography.fontFamily,
    fontSize: "12px",
    color: theme.palette.common.lightBlack,
    opacity: 1,
  },
  options: {
    ...theme.typography.fontFamily,
    fontSize: "14px",
  },
  imgTop: {
    display: "flex",
    width: 30,
    height: 30,
  },
  avatar: {
    width: theme.spacing(3.2),
    height: theme.spacing(3.2),
  },
  notificationIcon: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconOutlined: {
    height: 17,
    marginBottom: 2,
    color: "#fff",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  logoImg: {
    height: "1.53rem",
    marginRight: 40,
    marginLeft: 5,
    cursor: "pointer",
  },
}));

function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorProfile, setanchorProfile] = useState(null);
  const [openProfile, setopenProfile] = useState(false);
  const [arrow, setArrow] = useState(false);

  const { accesstoken, currentOrgData } = props;

  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = userDetails;
  const onTogglerHover = () => {
    setShowMenu(!showMenu);
  };
  const history = useHistory();
  const handleCloseProfile = () => {
    setanchorProfile(null);
    setopenProfile(false);
  };
  const handleClickProfile = (e) => {
    setanchorProfile(e.currentTarget);
    setopenProfile(true);
  };
  const handleMenuItemProfile = (e) => {
    // setanchor(null)
    setopenProfile(false);
  };

  const handleSignout = async () => {
    const {_status} = await logOutUser(userid)
    dispatch(deleteVisitedRoute());
    dispatch(addUserNotification({}));
    props.addAuthData({
      accesstoken: "",
      refreshtoken: "",
      userid: "",
    });

    setShowMenu(false);
    dispatch({ type: USER_RESET });
    history.push("/");
    // window.location.reload(true);

  };
  const test = () => {
    dispatch(
      routeVisited({
        resourceName: "Profile",
        childName: "Profile",
        routeName: "/settings",
      })
    );
  };

  // SOCKET_URL
  const [newNotification, setNewNotification] = useState([]);
  const { userid } = useSelector((state) => state.authReducer);

  const handleClickNotification = () => {
    history.push("/settings");
    dispatch(changeTabByUser(3));
  };

  const userProfile = new UserProfile();
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    if (userid !== "") {
      try {
        const resp = await userProfile.fetchAllNotifications(userid, 1);
        if (resp?.data?.data !== null) {
          dispatch(addUserNotification(resp?.data ? resp?.data : {}));
        } else {
          enqueueSnackbar("No notifications found", {
            variant: "error",
          });
          dispatch(addUserNotification({}));
        }
      } catch (err) {
        dispatch(addUserNotification({}));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userid, newNotification]);

  const { Notifications } = useSelector((state) => state.userPersonal);

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.navbar}
        onMouseEnter={() => showMenu && setShowMenu(true)}
      >
        <Toolbar>
          {currentOrgData?.data?.logo && (
            <img
              src={currentOrgData?.data?.logo}
              alt=""
              onClick={() => history.push("/dashboard")}
              className={classes.logoImg}
              style={{
                maxHeight: 30,
                maxWidth: 120,
              }}
            />
          )}
          {/* </Typography> */}
          {accesstoken && (
            <div>
              {showMenu ? (
                <IconButton
                  edge="start"
                  className={classes.menuButtonClose}
                  color="inherit"
                  aria-label="close drawer"
                  onMouseEnter={() => setShowMenu(false)}
                  onClick={onTogglerHover}
                >
                  <img src="/images/close.svg" alt="toggler" />
                </IconButton>
              ) : (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onMouseEnter={() => setShowMenu(true)}
                  onClick={onTogglerHover}
                >
                  <img src="/images/toggler.svg" alt="toggler" />
                </IconButton>
              )}
            </div>
          )}

          <div className={classes.grow} />
          {accesstoken && (
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                className={classes.notificationIcon}
                onClick={handleClickNotification}
              >
                <Badge
                  badgeContent={Notifications && Notifications?.unseenCount}
                  color="secondary"
                >
                  <img src="/images/notification.svg" alt="toggler" />
                </Badge>
              </IconButton>
              <Divider
                orientation="vertical"
                style={{
                  backgroundColor: "#FFF",
                  height: 20,
                  width: 1,
                  margin: "0 10px",
                }}
              />
              <IconButton
                disableRipple
                edge="end"
                color="inherit"
                aria-owns={anchorProfile ? "profile" : undefined}
                aria-haspopup={anchorProfile ? "true" : undefined}
                onClick={(event) => {
                  handleClickProfile(event);
                  setArrow(!arrow);
                }}
                className={classes.notificationIcon}
                style={{ cursor: "pointer" }}
              >
                <Grid container className={classes.imgTop} alignItems="center">
                  <Avatar
                    alt="img"
                    src={
                      userInfo && userInfo.image !== "" ? userInfo.image : User
                    }
                    className={classes.avatar}
                  />
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      className={classes.profileText}
                      variant="h6"
                      color="textSecondary"
                      noWrap
                    >
                      {/* {userInfo&&userInfo.name.length>16?`${userInfo.name.slice(0,16)}...`:`${userInfo.name}`} */}
                      {userInfo && userInfo.name && userInfo.name.length > 16
                        ? `${userInfo.name.slice(0, 16)}...`
                        : !userInfo.name
                        ? ""
                        : `${userInfo.name}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    lg
                    md
                    justify="flex-end"
                    style={{ marginLeft: 10 }}
                  >
                    {arrow ? (
                      <KeyboardArrowUpOutlinedIcon
                        fontSize="small"
                        className={classes.iconOutlined}
                      />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon
                        fontSize="small"
                        className={classes.iconOutlined}
                      />
                    )}
                  </Grid>
                </Grid>
              </IconButton>
              <Menu
                id="profile"
                anchorEl={anchorProfile}
                open={openProfile}
                onClose={() => {
                  handleCloseProfile();
                  setArrow(!arrow);
                }}
                classes={{ paper: classes.menuPaper }}
                // MenuListProps={{ onMouseLeave: handleCloseProfile }}
                elevation={1}
              >
                <MenuItem
                  component={Link}
                  to={"/settings"}
                  classes={{ root: classes.menuItem }}
                  onClick={(event) => {
                    handleMenuItemProfile(event);
                    handleCloseProfile();
                    setShowMenu(false);
                    setArrow(!arrow);
                    test();
                    dispatch(changeTabByUser(0));
                  }}
                  style={{ borderBottom: `1px solid #BEBEBE` }}
                >
                  <Grid container lg md alignItems="center">
                    <Grid item>
                      <Typography className={classes.options}>
                        Settings
                      </Typography>
                    </Grid>
                    <Grid item container lg md justify="flex-end">
                      {/* <ChevronRightOutlinedIcon fontSize="small" /> */}
                    </Grid>
                  </Grid>
                </MenuItem>
                <MenuItem
                  component={Link}
                  classes={{ root: classes.menuItem }}
                  onClick={(event) => {
                    handleMenuItemProfile(event);
                    handleCloseProfile();
                    handleSignout();
                  }}
                >
                  <Typography className={classes.options}>Sign Out</Typography>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {showMenu && (
        <TopMenuBar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          alterMenu={onTogglerHover}
        />
      )}
      {showMenu && (
        <Backdrop
          className={classes.backdrop}
          style={{ zIndex: 1000 }}
          open={true}
          onClick={() => setShowMenu(false)}
        />
      )}
    </>
  );
}

const mapDispatchToProps = {
  addAuthData,
};

const mapStateToProps = (state) => {
  const { accesstoken, userid } = state.authReducer;
  const { currentOrgData } = state.organizationReducer;
  return {
    accesstoken,
    userid,
    currentOrgData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
