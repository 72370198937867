import {
  ADD_CLUSTER_LIST,
  CLUSTER_FILTERS,
  CLUSTER_SORT,
  CLUSTER_HIDDEN,
  CLEAR_CLUSTER,
  ADD_CLUSTER_DEPLOYMENT_LIST,
  ADD_CLUSTER_PODS_LIST,
  ADD_CLUSTER_REPLICA_SETS_LIST,
  ADD_CLUSTER_SERVICES_LIST,
  CLUSTER_DEPLOYMENT_HIDDEN,
  CLUSTER_DEPLOYMENT_SORT,
  CLUSTER_PODS_HIDDEN,
  CLUSTER_PODS_SORT,
  CLUSTER_REPLICA_SET_HIDDEN,
  CLUSTER_REPLICA_SET_SORT,
  CLUSTER_SERVICES_HIDDEN,
  CLUSTSER_SERVICES_SORT,
  ADD_CLOUD_CONFIG,
  ADD_CLUSTER_PODS_HIDDEN,
  CLUSTER_SERVICE_SORT,
  CLUSTER_REPLICASET_HIDDEN,
  // CLUSTER_SERVICE_HIDDEN,
} from "../actionTypes";
import { ClusterService } from "../../services/clusterService.js";

const clusterService = new ClusterService();

const add_cluster_list = (payload) => ({
  type: ADD_CLUSTER_LIST,
  payload: payload,
});

export const add_cluster_deployment_list = (payload) => ({
  type: ADD_CLUSTER_DEPLOYMENT_LIST,
  payload: payload,
});
export const add_cluster_pods_list = (payload) => ({
  type: ADD_CLUSTER_PODS_LIST,
  payload: payload,
});

export const add_cluster_replica_sets_list = (payload) => ({
  type: ADD_CLUSTER_REPLICA_SETS_LIST,
  payload: payload,
});

export const add_cluster_services_list = (payload) => ({
  type: ADD_CLUSTER_SERVICES_LIST,
  payload: payload,
});

export const add_cloud_config = (payload) => ({
  type: ADD_CLOUD_CONFIG,
  payload: payload,
});
export const fetchAllDeploymentClusters =
  (clusterName, currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_cluster_deployment_list({
          loading: true,
        })
      );
      const res_data = await clusterService.getAllClusterDeployments(
        clusterName,
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_cluster_deployment_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_cluster_deployment_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_cluster_deployment_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const fetchAllClusterPods =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_cluster_pods_list({
          loading: true,
        })
      );
      const res_data = await clusterService.getAllClusterPods(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_cluster_pods_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_cluster_pods_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_cluster_pods_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addClusterServiceSort = (clusterServiceSort) => {
  return {
    type: CLUSTER_SERVICE_SORT,
    payload: clusterServiceSort,
  };
};

export const fetchAllCluster =
  (currentPage, filter, cloudConfig) => async (dispatch) => {
    try {
      dispatch(
        add_cluster_list({
          loading: true,
        })
      );
      const res_data = await clusterService.getAllClusterDetails(
        currentPage,
        filter,
        cloudConfig
      );

      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_cluster_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_cluster_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_cluster_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const fetchAllClusterDeployment =
  (currentPageCluster, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_cluster_deployment_list({
          loading: true,
        })
      );
      const res_data = await clusterService.getAllClusterDeployments(
        currentPageCluster,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_cluster_deployment_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_cluster_deployment_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_cluster_deployment_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addClusterFilters = (clusterFilters) => {
  return {
    type: CLUSTER_FILTERS,
    payload: clusterFilters,
  };
};

export const addClusterSort = (clusterSort) => {
  return {
    type: CLUSTER_SORT,
    payload: clusterSort,
  };
};

export const addClusterHidden = (clusterHidden) => {
  return {
    type: CLUSTER_HIDDEN,
    payload: clusterHidden,
  };
};

export const clearCluster = () => {
  return {
    type: CLEAR_CLUSTER,
  };
};

export const addClusterDeploymentHidden = (clusterDeploymentHidden) => {
  return {
    type: CLUSTER_DEPLOYMENT_HIDDEN,
    payload: clusterDeploymentHidden,
  };
};

export const addClusterDeploymentSort = (clusterDeploymentSort) => {
  return {
    type: CLUSTER_DEPLOYMENT_SORT,
    payload: clusterDeploymentSort,
  };
};

export const addClusterPodsHidden = (clusterPodsHidden) => {
  return {
    type: CLUSTER_PODS_HIDDEN,
    payload: clusterPodsHidden,
  };
};

export const addReplicaSetHidden = (clusterReplicatSetHidden) => {
  return {
    type: CLUSTER_REPLICASET_HIDDEN,
    payload: clusterReplicatSetHidden,
  };
};
export const addClusterServiceHidden = (clusterServiceHidden) => {
  return {
    type: CLUSTER_SERVICES_HIDDEN,
    payload: clusterServiceHidden,
  };
};
