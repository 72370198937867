const fieldgroupColumns = [
    { name: "name", title: "Field Group Name", for: "ontology_field_group_list" },
    { name: "predefined", title: "Predefined", for: "ontology_field_group_list" },
    { name: "num_fields", title: "No. of Fields", for: "ontology_field_group_list" },
    { name: "num_predefined_fields", title: "No. of Predefined Fields", for: "ontology_field_group_list" },
    { name: "updatedon", title: "Updated On", for: "ontology_field_group_list" },
    { name: "action", title: "Action", for: "ontology_field_group_list" },
];

const fieldgroupDefaultColumnWidths = [
    { columnName: "name", weightage: 1.5, width: 200 },
    { columnName: "predefined", weightage: 1.5, width: 200 },
    { columnName: "num_fields", weightage: 1.5, width: 200 },
    { columnName: "num_predefined_fields", weightage: 1.5, width: 200 },
    { columnName: "updatedon", weightage: 2, width: 200 },
    { columnName: "action", weightage: 2, width: 200 },
];

const fieldgroupTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "predefined", align: "left" },
    { columnName: "num_fields", align: "left" },
    { columnName: "num_predefined_fields", align: "left" },
    { columnName: "updatedon", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
];

const fieldgroupColumnOrder = [
    "name",
    "predefined",
    "num_fields",
    "num_predefined_fields",
    "updatedon",
    "action",
];

const fieldgroupDefaultSorting = []

export {
    fieldgroupColumns,
    fieldgroupDefaultColumnWidths,
    fieldgroupTableColumnExtensions,
    fieldgroupColumnOrder,
    fieldgroupDefaultSorting,
};
