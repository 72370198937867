const scriptsColumns = [
  { name: "name", title: "Name", for: "scripts" },
  { name: "type", title: "Type", for: "scripts" },
  { name: "createdby", title: "Created By", for: "scripts" },
  { name: "createdon", title: "Created On", for: "scripts" },
  { name: "action", title: "Action", route: "/services/edit", for: "scripts" },
];

const scriptsDefaultColumnWidths = [
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "type", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const scriptsTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "type", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const scriptsColumnOrder = ["name", "type", "createdby", "createdon", "action"];

const scriptsColumnHidden = [
  "name", "type", "createdby", "createdon", "action"
];

const scriptsDefaultSorting = [];

export {
  scriptsColumns,
  scriptsDefaultColumnWidths,
  scriptsTableColumnExtensions,
  scriptsColumnOrder,
  scriptsDefaultSorting,
  scriptsColumnHidden,
};
