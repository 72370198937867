import { ApiHelper } from "./helper/ApiHelper";
import { DOCUMENT } from "./helper/config";

const apiHelper = new ApiHelper();

export class DocumentServices {
  getAllDocumentTypes(selectedType) {
    const uri = `${DOCUMENT}/api/v1.0.0/documentTypes`;
    return apiHelper.get(uri);
  }

  getAllDocuments(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${DOCUMENT}/api/v1.0.0/documents?${queryParam}&size=10`;

    return apiHelper.get(uri);
  }

  // getAllStandardDocuments(currentPage, filter, sort) {
  //     const queryParam = (`page=${currentPage}`) +
  //     (filter !== undefined && filter !== {} ? `&filters=resourcetype: organization & resourceid: standard & ${filter}` : '') +
  //         // (`&filters=${JSON.stringify({ resourcetype: "organization", resourceid: "standard"})}`) +
  //         (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
  //     const uri = `${DOCUMENT}/api/v1.0.0/documents?${queryParam}&size=10`;
  //     return apiHelper.get(uri);
  // }

  getDocumentById(docId) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${docId}`;
    return apiHelper.get(uri);
  }
  addDocument(docData) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents`;
    return apiHelper.post(uri, docData);
  }
  editDocument(docId, docData) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${docId}`;
    return apiHelper.put(uri, docData);
  }
  editDocumentMeta(docId, docData) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${docId}/meta`;
    return apiHelper.put(uri, docData);
  }
  deleteDocument(docId) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${docId}`;
    return apiHelper.delete(uri);
  }
  addDocumentType(doctypeData) {
    const uri = `${DOCUMENT}/api/v1.0.0/documentTypes`;
    return apiHelper.post(uri, doctypeData);
  }
  editDocumentType(docTypeId, doctypeData) {
    const uri = `${DOCUMENT}/api/v1.0.0/documentTypes/${docTypeId}`;
    return apiHelper.put(uri, doctypeData);
  }
  deleteDocumentType(docTypeId) {
    const uri = `${DOCUMENT}/api/v1.0.0/documentTypes/${docTypeId}`;
    return apiHelper.delete(uri);
  }
  deleteDocumentByID(documentId) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${documentId}`;
    return apiHelper.delete(uri);
  }
  deleteDocumentsByProjectResourceID(resourceID) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/resourcetype/project/resourceid/${resourceID}`;
    return apiHelper.delete(uri);
  }
  deleteDocumentsByServiceGroupResourceID(resourceID) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/resourcetype/servicegroup/resourceid/${resourceID}`;
    return apiHelper.delete(uri);
  }
  deleteDocumentsByServiceResourceID(resourceID) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/resourcetype/microservice/resourceid/${resourceID}`;
    return apiHelper.delete(uri);
  }
  downloadDocumentById(fileId) {
    const uri = `${DOCUMENT}/api/v1.0.0/documents/${fileId}/_download`;
    return apiHelper.get(uri);
  }
}
