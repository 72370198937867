import {
  Grid,
  FilledInput,
  InputAdornment,
  IconButton,
  useTheme,
  Avatar,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { AppButton } from "../components/atoms/button/AppButton";
import BreadCrumbs from "../components/molecules/projectSetup/BreadCrumbs";
import ServiceTable from "../components/organisms/ServiceTable";
import ApplicationTable from "../components/organisms/ApplicationTable";
import { useState } from "react";
import { handleValidation } from "../utils/Validation";
import { v4 as uuid } from "uuid";
import CreateScreenStyle from "../styles/commonStyles/CreateScreen";
import { useSnackbar } from "notistack";
import { UiApplicationService } from "../services/UiApplicationService";
import Loader from "../components/atoms/loader/Loader";
import React, { useEffect } from "react";
import { CommonServices } from "../../services/commonServices";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { ApplicationServices } from "../../services/applicationServices";
import GlobalCssModal from "../components/atoms/customModal/GlobalCssModal";
import { DropzoneArea } from "material-ui-dropzone";

const customdropzoneImage = () => <img src="/images/dropzone.svg" alt="" />;

export default function CreateScreen() {
  const classes = CreateScreenStyle();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [appData, setAppData] = useState({
    id: uuid(),
    name: "",
    description: "",
    imagetag: "",
    imagename: "",
    icon: "",
    services: [],
  });
  const [icon, setIcon] = useState("");
  const [iconname, setIconName] = useState("");
  const [globalCssModal, setGlobalCssModal] = useState(false);
  const theme = useTheme();

  const search = useLocation().search;

  const [css, setCss] = useState({
    fontFamily: "Nunito",
    fontSize: 10,
    fontStyle: {
      bold: false,
      italic: false,
      underline: false,
    },
    color: theme.palette.primary.main,
  });

  const [error, setError] = useState({
    name: false,
    imagename: false,
    imagetag: false,
    imageNameFirstLetterLow: false,
  });

  const handleChange = (e) => {
    if (/^[a-z]/.test(e.target.value) && e.target.name === "imagename") {
      setError({
        ...error,
        [e.target.name]: false,
        imageNameFirstLetterLow: false,
      });
      setAppData({
        ...appData,
        [e.target.name]: e.target.value,
      });
    } else {
      setError({
        ...error,
        [e.target.name]: false,
      });
      setAppData({
        ...appData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkScreenPermission("UIApplication", ["Create"]), []);

  // Services
  const commonService = new CommonServices();
  const [services, setServices] = useState();
  const [selectedServices, setSelectedServices] = useState([]);

  const fetchServiceList = async (page, filter) => {
    try {
      setLoading(true);
      var resp = await commonService.getServicesList(page, filter);
      setServices(resp?.data?.data ? resp?.data?.data : []);
      setLoading(false);
    } catch (error) {
      console.log("error is : ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkCompPermission("Microservice", ["List All"])) {
      fetchServiceList(
        undefined,
        JSON.stringify({
          ai_task: { $ne: true },
          service_category: { $ne: "Only GUI" },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleServiceSearch = (e) => {
    if (e.target.value) {
      if (checkCompPermission("Microservice", ["List All"])) {
        fetchServiceList(
          0,
          JSON.stringify({
            ai_task: { $ne: true },
            service_category: { $ne: "Only GUI" },
            name: { $regex: e.target.value, $options: "i" },
          })
        );
      }
    } else {
      if (checkCompPermission("Microservice", ["List All"])) {
        fetchServiceList(0);
      }
    }
  };

  // Application
  const applicationServices = new ApplicationServices();
  const [applications, setApplications] = useState();
  const [selectedApplications, setSelectedApplications] = useState([]);

  const fetApplicationList = async (page, filter) => {
    try {
      setLoading(true);
      var resp = await applicationServices.getApplicationList(
        page,
        filter,
        JSON.stringify({
          createdon: -1,
        })
      );
      setApplications(resp?.data?.data ? resp?.data?.data : []);
      setLoading(false);
    } catch (error) {
      console.log("error is : ", error);
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (checkCompPermission("ServiceBundle", ["List All"])) {
      fetApplicationList()
    }
  }, []);

  const handleApplicationSearch = (e) => {
    if (e.target.value) {
      if (checkCompPermission("ServiceBundle", ["List All"])) {
        fetApplicationList(
          0,
          JSON.stringify({
            name: { $regex: e.target.value, $options: "i" },
          })
        );
      }
    } else {
      if (checkCompPermission("ServiceBundle", ["List All"])) {
        fetApplicationList(0);
      }
    }
  };

  // handleSubmit
  const apiService = new UiApplicationService();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleValidation(appData, error);
    if (/^[A-Z]/.test(appData.imagename)) {
      setError({
        ...error,
        imageNameFirstLetterLow: true,
      });
      return;
    }
    if (formIsValid && !error.imageNameFirstLetterLow) {
      const data = {
        name: appData.name.trim(),
        description: appData.description.trim(),
        imagename: appData.imagename.trim(),
        imagetag: appData.imagetag.trim(),
        previewMode: "WEB",
        actionOpen: false,
        services: selectedServices,
        backendApplication: selectedApplications,
        globalCss: JSON.stringify(css),
        icon: icon,
        iconName: iconname,
        linkedUiApplications: [],
      };
      const pageData = {
        id: uuid(),
        name: "Home",
        url: "home",
        islandingpage: true,
        description: "Home Page",
        components: [],
      };
      const actionDefaultData = {
        name: "action1",
        service: {},
        api: {},
        config: {
          headers: [
            {
              key: "Content-type",
              value: "application/json",
              id: uuid(),
            },
          ],
          queryParams: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
          pathParams: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
          body: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
        },
        application: {},
        process: {},
        applicationConfig: {
          headers: [
            {
              key: "Content-type",
              value: "application/json",
              id: uuid(),
            },
          ],
          queryParams: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
          pathParams: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
          body: [
            {
              key: "",
              value: "",
              id: uuid(),
            },
          ],
        },
        data: {},
        isActive: true,
        actionType: "service",
        modifyResults: "return {{data}}",
      };
      setLoading(true);
      try {
        const resp = await apiService.addApplication(data);

        const addActionResp = await apiService.addAction(
          resp?.data?.data?._id,
          actionDefaultData
        );

        const pageResponse = await apiService.addNewPage(
          resp?.data?.data?._id,
          pageData
        );

        if (resp?.status < 210) {
          enqueueSnackbar("Ui Application Created Succesfully", {
            variant: "success",
          });
          setLoading(false);
          history.push("/ui-applications");
        } else {
          setLoading(false);
          resp?.data?.data?.map((item) => {
            enqueueSnackbar(
              item
                ? item
                : "Unable to process your request, please try after sometime",
              {
                variant: "error",
              }
            );
          });
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(err?.response?.data?._msg, {
          variant: "error",
        });
      }
    } else {
      // @ts-ignore
      setLoading(false);
      setError(errors);
    }
  };

  var handleUpload = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setIconName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setIcon(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteClick = () => {
    setIcon("");
    setIconName("");
  };

  const rejectedfile = () =>
    enqueueSnackbar("Maximum file size allowed 30kb", {
      variant: "error",
    });

  const handleCancel = () => {
    history.push(`/ui-applications`);
  };

  return (
    <main className={classes.main}>
      {loading && <Loader />}
      <div className={classes.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "UI Application",
              path: "/ui-applications",
            },
            { title: "Create UI Application" },
          ]}
        />
        <Grid container alignItems="center">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <h2 className={classes.title}>Create UI Application</h2>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className={classes.buttons}>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: "unset", marginLeft: "10px" }}
                className="btnsmall"
                onClick={handleSubmit}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <AppButton
                color="primary"
                buttonName="Cancel"
                variant="outlined"
                disabled={false}
                style={{ margin: "unset" }}
                className="outlined"
                onClick={handleCancel}
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.generalInfo}>
          <span>General Information</span>
        </div>
        <div className={classes.border_line} />
      </div>
      <div className={classes.wrapper}>
        <Grid container spacing={3} style={{ marginTop: "0rem" }}>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Application Name <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="name"
                  type="text"
                  placeholder="Enter Application Name"
                  className={`${classes.input} ${error.name && classes.error}`}
                  onChange={handleChange}
                />
                {error.name && (
                  <p className={classes.errorMsg}>
                    {"Application Name is required"}
                  </p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Image Name <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="imagename"
                  type="text"
                  placeholder="Enter Image Name"
                  className={`${classes.input} ${error.imagename && classes.error
                    }`}
                  onChange={handleChange}
                />
                {error.imagename && (
                  <p className={classes.errorMsg}>{"Image Name is required"}</p>
                )}
                {error.imageNameFirstLetterLow && (
                  <p className={classes.errorMsg}>
                    {"Image Name should start with lowercase letter only"}
                  </p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Image Tag <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="imagetag"
                  type="text"
                  placeholder="Enter Image Tag"
                  className={`${classes.input} ${error.imagetag && classes.error
                    }`}
                  onChange={handleChange}
                />
                {error.imagetag && (
                  <p className={classes.errorMsg}>{"Image Tag is required"}</p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <label className={classes.fieldHeading}>
                      Application Icon
                    </label>
                    {!icon ? (
                      <div className={classes.documentDropperWrapper}>
                        <DropzoneArea
                          Icon={customdropzoneImage}
                          acceptedFiles={["image/*"]}
                          className={classes.documentDropperClass}
                          showPreviews={false}
                          filesLimit={1}
                          dropzoneText="Drag and drop or click to browse here"
                          dropzoneClass="documentDropperClass"
                          dropzoneParagraphClass="documentDropperTextClass"
                          get
                          maxFileSize={30000}
                          showAlerts={false}
                          onDropRejected={rejectedfile}
                          showPreviewsInDropzone={false}
                          onChange={handleUpload}
                        />
                        <p
                        style={{ whiteSpace: "nowrap", fontWeight: 400 }}
                          className={classes.fieldHeading}
                        >{`Allowed jpg, jpeg, png or svg of size max 30 KB`}</p>
                      </div>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Avatar src={icon} alt=" " />
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={classes.value}
                            value={iconname}
                            placeholder="Enter Image Name"
                          ></input>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="inherit"
                            aria-label="edit"
                            edge="start"
                            className={classes.actionIcon}
                            onClick={handleDeleteClick}
                          >
                            <img
                              src="/images/deleteLine.svg"
                              color="#000000"
                              alt="icon"
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label className={classes.fieldHeading}>
                  Application Description
                </label>
                <textarea
                  name="description"
                  rows={6}
                  placeholder="Enter Application description"
                  className={`${classes.textarea} ${error.description && classes.error
                    }`}
                  onChange={handleChange}
                ></textarea>
                {error.description && (
                  <p className={classes.errorMsg}>
                    {"description is required"}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 60 }}>
        <div>
          <div>
            <h2 className={classes.sectionHeading}>Service - Version</h2>
            <div className={classes.border_line} />
          </div>
          <div className={classes.searchwrapper}>
            <FilledInput
              name="searchInput"
              onChange={handleServiceSearch}
              className={classes.input}
              style={{
                backgroundColor: "#fff !important",
                padding: 0,
                paddingBottom: "1rem",
                width: "100%",
                alignItems: "center",
              }}
              placeholder="Search"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleServiceSearch}
                    onMouseDown={handleServiceSearch}
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div>
            <ServiceTable
              services={services}
              setServices={setServices}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              setLoading={setLoading}
              isEditMode={true}
            />
          </div>
        </div>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 60 }}>
        <div>
          <div>
            <h2 className={classes.sectionHeading}>Service Bundles</h2>
            <div className={classes.border_line} />
          </div>
          <div className={classes.searchwrapper}>
            <FilledInput
              name="searchInput"
              onChange={handleApplicationSearch}
              className={classes.input}
              style={{
                backgroundColor: "#fff !important",
                padding: 0,
                paddingBottom: "1rem",
                width: "100%",
                alignItems: "center",
              }}
              placeholder="Search"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleApplicationSearch}
                    onMouseDown={handleApplicationSearch}
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div>
            <ApplicationTable
              isEditMode={true}
              applications={applications}
              setApplications={setApplications}
              selectedApplications={selectedApplications}
              setSelectedApplications={setSelectedApplications}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 60 }}>
        <div>
          <div style={{ paddingBottom: 100 }}>
            <h2 className={classes.sectionHeading}>Global CSS</h2>
            <div className={classes.border_line} style={{ marginBottom: 20 }} />
            <AppButton
              color="primary"
              buttonName="Set Global CSS"
              variant="contained"
              disabled={false}
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={() => setGlobalCssModal(true)}
            />
          </div>
        </div>
      </div>
      {globalCssModal && (
        <GlobalCssModal
          open={globalCssModal}
          css={css}
          setCss={setCss}
          handleClose={() => setGlobalCssModal(false)}
          handleSave={() => setGlobalCssModal(false)}
          createScreen={true}
        />
      )}
    </main>
  );
}
