import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  AccordionDetails,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import useStyles from "../../CommonStyles/manageScreenStyles";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { add_runtimeEnv_data } from "../../../redux/actions/runtimeEnvAction";
import { createRunTimeEnv } from "../../../utils/containerMangement/runtimeEnvUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import CreateruntimeENVEntity from "../../../components/common/entitySchema/containerManagement/runtimeENV/CreateruntimeENVEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const tempEnvList = [
  {
    id: uuidv4(),
    container_name: "",
    imageid: "",
    imagename: "",
    environment: {},
  },
  {
    id: uuidv4(),
    container_name: "",
    imageid: "",
    imagename: "",
    environment: {},
  },
  {
    id: uuidv4(),
    container_name: "",
    imageid: "",
    imagename: "",
    environment: {},
  },
];

const CreateEnvScreen = (props) => {
  const styles = useStyles();
  const createStyles = CreateCustomerStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const [envList, setEnvList] = useState(tempEnvList);
  const { enqueueSnackbar } = useSnackbar();
  const [runtimeEnvdata, setRuntimeEnvData] = useState({});
  const [error, setError] = useState({});
  const [envCreateEntitySchema, setEnvCreateEntitySchema] = useState();

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("RuntimeEnvironment", ["Create"]);
  }, []);

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/runtime-environments");
  };

  useEffect(() => {
    setLoading(true);
    const fetchEnvironmentEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "RuntimeEnvironment",
        "create"
      );
      if (_status === 200) {
        setEnvCreateEntitySchema(data);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });
        setRuntimeEnvData({ ...runtimeEnvdata, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError({ ...errData, environmentVariable: { isError: false, section: 1, dataType: "string" } })
        // setError(errData)
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchEnvironmentEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    if (event.target.name === 'environmentVariable') {
      setError({ ...error, [event.target.name]: { ...error[event.target.name], isError: false, section: 1, dataType: "string", maxLength: 300, maximum: 300, minLength: 1, minimum: 1 } })
    }
    if (event.target.name === 'name') {
      if (value.length === 0) {
        setError({ ...error, [event.target.name]: { ...error[event.target.name], isError: true, maxLength: 50, maximum: 300, minLength: 1, minimum: 1, fieldLabel: 'Environment Name', section: 0, dataType: "string" } })
      }
    }
    setRuntimeEnvData({
      ...runtimeEnvdata,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    if (runtimeEnvdata?.environmentVariable?.length === 0) {
      setError({ ...error, environmentVariable: { ...error["environmentVariable"], isError: true, section: 1, dataType: "string" } })
    } else {
      setError({ ...error, environmentVariable: { ...error["environmentVariable"], isError: false, section: 1, dataType: "string" } })
    }
  }, [runtimeEnvdata["environmentVariable"]])

  const handleSubmit = async () => {
    const temp_data = [];
    envList.forEach((item) => {
      if (item.imageid !== "" && item.container_name !== "") {
        if (Object.keys(item.environment).length === 0) {
          temp_data.push({
            container_name: item.container_name,
            imageid: item.imageid,
          });
        } else {
          temp_data.push({
            container_name: item.container_name,
            imageid: item.imageid,
            environment: item.environment,
          });
        }
      }
    });
    runtimeEnvdata["environmentVariable"] = temp_data;
    if (runtimeEnvdata?.environmentVariable?.length === 0) {
      // setError({...error,environmentVariable:{...error["environmentVariable"],isError:true,section:1,dataType:"object"}})
      enqueueSnackbar("Please Enter Environment Variables", {
        variant: "error",
      });
    }
    const { formIsValid, errors } = handleFormValidationPro(
      runtimeEnvdata,
      error
    );
    if (formIsValid) {
      const req_data = {
        ...runtimeEnvdata,
        services: temp_data ? temp_data : "",
      };
      setLoading(true);
      const { _msg, _status, data } = await createRunTimeEnv(req_data);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setLoading(false);

        histroy.push("/runtime-environments");
      } else {
        setLoading(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        envCreateEntitySchema ? envCreateEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setEnvCreateEntitySchema({
        ...envCreateEntitySchema,
        sections: tempEntity,
      });
      setLoading(false);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Runtime Environments",
              path: "/runtime-environments",
            },
            { title: "Create Runtime Environment" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.title}>Create Runtime Environment</h2>
          {tabbedView ? (
            <TabbedNavigation
              schemaLength={envCreateEntitySchema?.length}
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              isCreateMode={true}
              isEditMode={false}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              disabled={false}
            />

          )}
        </Grid>
        {/* <div className={createStyles.border_line} /> */}
      </div>

      <div
        className={styles.wrapper}
        style={tabbedView ? { marginTop: "6.5rem" } : { marginTop: "7rem" }}
      >
        {tabbedView ? (
          <>
            <SectionTabs
              entity={
                envCreateEntitySchema ? envCreateEntitySchema.sections : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CreateruntimeENVEntity
                  section={envCreateEntitySchema?.sections?.[activeTab]}
                  isEditMode={true}
                  handleChange={handleChange}
                  setRuntimeEnvData={setRuntimeEnvData}
                  runtimeEnvdata={runtimeEnvdata}
                  error={error}
                  envList={envList}
                  setEnvList={setEnvList}
                />
              </div>
            </Paper>
          </>
        ) : (
          envCreateEntitySchema?.sections &&
          envCreateEntitySchema?.sections.map((section, index) => {
            if (section.name === "Environment_Header_section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CreateruntimeENVEntity
                    section={section}
                    isEditMode={true}
                    handleChange={handleChange}
                    setRuntimeEnvData={setRuntimeEnvData}
                    runtimeEnvdata={runtimeEnvdata}
                    error={error}
                    envList={envList}
                    setEnvList={setEnvList}
                  />
                </div>
              );
            } else {
              return (
                <Element name={index}>
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded className={styles.accordianIcon} />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CreateruntimeENVEntity
                        section={section}
                        isEditMode={true}
                        handleChange={handleChange}
                        setRuntimeEnvData={setRuntimeEnvData}
                        runtimeEnvdata={runtimeEnvdata}
                        error={error}
                        envList={envList}
                        setEnvList={setEnvList}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Element>

              );
            }
          })
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_runtimeEnv_data,
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnvScreen);
