import React, { useState, useEffect } from "react";
import {
  Paper,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import {
  addProcessOutput,
  alterDebugData,
  alterSimulateStatus,
  addAnimations,
  addDebugAnimation,
} from "../../../redux/actions/orchestrationAction";
import Editor from "../../common/Editor";
import inputStyles from "./styles";
import InputTable from "../../common/Tables/InputTable";
import JSONToTable from "../../common/JSONToTable";
import {
  getJSONObjFromKeyValueArray,
  debugSimulateTemplate,
  debugTemplate,
  getInstanceContextSimulate,
  fetchNodeDetails,
  getKeyValueFromStringJSON,
  getJSONObjFromKeyValueArrayGen,
  editNodeData,
  getJSONObjFromKeyValueArrayForSync,
} from "../../../utils/orchestration/orchestrationUtils";
import { AppButton } from "../../common/Button";

const temp_data = [
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

const temp_header_data = [
  { id: uuidv4(), key: "Content-Type", value: "application/json" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

function SimulateModal(props) {
  const [dataObj, setDataObj] = useState({
    topic: "",
    servicetype: "rest",
    message: "",
  });
  const [errorObj, setErrorObj] = useState({
    topic: false,
    message: false,
  });

  const [activetab, setActiveTab] = useState("header");
  const [activeInputType, setActiveInputType] = useState("text");
  const [activeOutputType, setActiveOutputtype] = useState("text");

  const [headerList, setHeaderList] = useState(temp_header_data);
  const [queryParamsList, setQueryParamsList] = useState([]);
  const [pathParamsList, setPathParamsList] = useState([]);

  const [inputList, setInputList] = useState(temp_data);
  const [inputEditorValue, setInputEditorValue] = useState("");

  // const [outputList, setOutputList] = useState(temp_data);
  const [outputEditorValue, setOutputEditorValue] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const [callNodeObjData,setCallNodeObjData] = useState({});
  
  const { enqueueSnackbar } = useSnackbar();
  const classes = inputStyles();

  const {
    isOpen,
    applicationID,
    templateid,
    outputBody,
    instanceid,
    debug_points,
    debug_obj,
    outputBodyEditor,
    debugOutputBody,
    debugOutputBodyEditor,
    data_flow_nodes_io_details,
    startNodeObj,
    callNodeObj,
  } = props;

  const [tempParams, setTempParams] = useState();
  useEffect(() => {
    const fetchParamDetails = async () => {
      const { _status, data } = await fetchNodeDetails(
        templateid,
        startNodeObj?.data?.startnodeid
      );
      if (_status < 210) {
        data?.endpoint && data?.endpoint?.split("/").length > 2
          ? setTempParams(data?.endpoint?.split("/").pop())
          : setTempParams(null);
      } else {
        setTempParams(null);
      }
    };
    fetchParamDetails();
  }, []);

  useEffect(() => {
    if (tempParams) {
      let tempPathParams =
        tempParams?.split("?")[0] && tempParams?.split("?")[0];
      setPathParamsList(
        tempPathParams && [{ id: uuidv4(), key: tempPathParams, value: "" }]
      );

      let tempQueryParams =
        tempParams?.split("?")[1] && tempParams?.split("?")[1];
      setQueryParamsList(
        tempQueryParams && [{ id: uuidv4(), key: tempQueryParams, value: "" }]
      );
    } else {
      setPathParamsList(temp_data);
      setQueryParamsList(temp_data);
    }
  }, [tempParams]);

  useEffect(() => {
    var startDataDetails = data_flow_nodes_io_details.find(
      (item) => item.type === "process_data_node"
    );
    if (startDataDetails && startDataDetails.servicetype === "rest") {
      var temp_data_list = [];
      var tempEditorVal = {};
      data_flow_nodes_io_details.length > 0 &&
        data_flow_nodes_io_details.forEach((node_) => {
          if (node_.type === "call_data_node") {
            node_?.inputFields?.fields?.forEach((field_) => {
              if (field_?.value === "") {
                var findDuplicate = temp_data_list.filter(
                  (item) => item.name === field_.name
                );
                if (findDuplicate.length === 0) {
                  temp_data_list.push({
                    id: uuidv4(),
                    key: field_.name,
                    value: "",
                  });
                  tempEditorVal[field_.name] = "";
                }
              } else if (field_?.value !== "") {
                var detailArr = field_.value?.split(".");
                if (
                  detailArr.length > 0 &&
                  detailArr[0] === "${templateInput"
                ) {
                  var findDuplicate_ = temp_data_list.filter(
                    (item) => item.name === field_.name
                  );
                  if (findDuplicate_.length === 0) {
                    temp_data_list.push({
                      id: uuidv4(),
                      key: field_.name,
                      value: "",
                    });
                    tempEditorVal[field_.name] = "";
                  }
                }
              }
            });
          }
        });
      setInputList(temp_data_list);
      setInputEditorValue(() => JSON.stringify(tempEditorVal, null, "\t"));
    } else if (startDataDetails && startDataDetails.servicetype !== "rest") {
      var tempData = startDataDetails?.inputFields?.fields
        ? startDataDetails?.inputFields?.fields?.map((item) => ({
            id: uuidv4(),
            key: item.name,
            value: "",
          }))
        : temp_data;
      setInputList(tempData);
    }

    if (startNodeObj) {
      setDataObj((prevState) => ({
        ...prevState,
        servicetype: startNodeObj["data"].servicetype
          ? startNodeObj["data"].servicetype
          : "rest",
        topic: startNodeObj["data"].topic ? startNodeObj["data"].topic : "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_flow_nodes_io_details, startNodeObj]);

  const handleTextChange = (key, val) => {
    setDataObj((prevState) => ({
      ...prevState,
      [key]: val,
    }));
    setErrorObj((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleTabChange = (event, val) => {
    setActiveTab(val);
  };

  const handleInputTypeChange = (val) => {
    setActiveInputType(val);
  };

  //handle change in the input table
  const handleInputTableChange = (val) => {
    setInputList(val);
    var changedEditorValue = getJSONObjFromKeyValueArrayForSync(val);
    var finalEditorValue = JSON.stringify(changedEditorValue, null, '\t');
    setInputEditorValue(finalEditorValue)
  };

  const handleEditorValueChange = (val) => {
    setInputEditorValue(val);
    var jsonConversion = getKeyValueFromStringJSON(JSON.parse(val));
    setInputList(jsonConversion)
  };

  const handleHeaderTableChange = (val) => {
    setHeaderList(val);
  };

  const handleQueryParamsListChange = (val) => {
    setQueryParamsList(val);
  };

  const handlePathParamsListChange = (val) => {
    setPathParamsList(val);
  };

  const fetchInstanceContext = async (contextInstanceID) => {
    if (contextInstanceID) {
      const { _msg, _status, data } = await getInstanceContextSimulate(
        applicationID,
        templateid,
        contextInstanceID
      );
      if (_status === 200) {
        props.addProcessOutput({
          instanceid: contextInstanceID,
          template_context_data: data,
        });
        enqueueSnackbar(_msg, { variant: "success" });
        props.addAnimations();
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar("Context Instance ID is not defined", {
        variant: "error",
      });
    }
  };

  const handleEditNodeData = async() => {
    let inputObj_call_node = {};
    if (activeInputType === "text") {
      inputList &&
        inputList?.length > 0 &&
        inputList?.forEach((obj) => {
          if (obj.key !== "") {
            var tempval = obj.value ? obj.value : "";
            inputObj_call_node[obj.key] = tempval;
          }
        });
        inputObj_call_node = JSON.stringify(inputObj_call_node);
    } else {
      inputObj_call_node = inputEditorValue;
    }
    const req_data_call_node = {
      ...callNodeObjData,
      input: inputObj_call_node
    }
    const { _msg, _status, data } = await editNodeData(
      callNodeObj?.id,
      templateid,
      req_data_call_node
    );
  }
  
  const handleSubmitClick = async () => {
    await handleEditNodeData()
    if (dataObj.servicetype === "rest") {
      var req_data = {};
      const headerObj = getJSONObjFromKeyValueArray(headerList);
      const inputObj = getJSONObjFromKeyValueArrayGen(inputList);
      const pathParamsObj = getJSONObjFromKeyValueArray(pathParamsList);
      const queryParamsObj = getJSONObjFromKeyValueArray(queryParamsList);
      var tempInputEditorValue = {};
      try {
        if (activeInputType === "editor") {
          tempInputEditorValue = JSON.parse(inputEditorValue);
        }
        if (debug_points.length > 0) {
          setIsLoader(true);
          req_data = {
            applicationid: applicationID,
            templateid: templateid,
            input: {
              queryParams: queryParamsObj,
              headers: headerObj,
              payload: {
                payload:
                  activeInputType === "text" ? inputObj : tempInputEditorValue,
                debug: debug_obj,
              },
              pathParams: pathParamsObj,
            },
          };
          const { _msg, _status, data } = await debugTemplate(req_data);
          if (_status === 200) {
            setIsLoader(false);
            var final_debug_data = {};
            if (data) {
              const { Status, StatusCode, body } = data;
              const template_body = StatusCode === 200 ? JSON.parse(body) : {};
              if (
                Object.keys(template_body).length > 0 &&
                template_body["data"]
              ) {
                final_debug_data = {
                  body: body,
                  debug_instanceID: template_body["instanceid"],
                  debug_status: true,
                  debug_context_data: template_body["data"]["Output"]
                    ? template_body["data"]["Output"]
                    : {},
                };
                props.alterDebugData(final_debug_data);
                enqueueSnackbar(
                  template_body["_msg"]
                    ? template_body["_msg"]
                    : "Workflow Finished",
                  { variant: "success" }
                );
                props.addDebugAnimation();
              }
            }
          } else {
            setIsLoader(false);
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
            // props.onSubmitClick("red")
          }
        } else if (debug_points.length === 0) {
          // For application
          setIsLoader(true);
          req_data = {
            applicationid: applicationID,
            templateid: templateid,
            instanceid: instanceid,
            input: {
              queryParams: queryParamsObj,
              headers: headerObj,
              payload:
                activeInputType === "text" ? inputObj : tempInputEditorValue,
              pathParams: pathParamsObj,
            },
          };
          var { _msg, _status, data } = await debugSimulateTemplate(req_data);
          if (_status === 200) {
            setIsLoader(false);
            var final_data = {};
            if (data) {
              const { Status, StatusCode, body } = data;
              const template_body = StatusCode === 200 ? JSON.parse(body) : {};
              if (
                Object.keys(template_body).length > 0 &&
                template_body["instanceid"]
              ) {
                final_data = {
                  ...data,
                  instanceid: template_body["instanceid"],
                };
                props.addProcessOutput(final_data);
                enqueueSnackbar(
                  template_body["_msg"]
                    ? template_body["_msg"]
                    : "Workflow Finished",
                  { variant: "success" }
                );
                await fetchInstanceContext(final_data["instanceid"]);
                props.alterSimulateStatus(true);
                setActiveTab("output");
              }
            }
            // props.addProcessOutput(data ? data : {});
            // enqueueSnackbar(_msg, { variant: "success" });
            // props.onSubmitClick("green")
          } else {
            setIsLoader(false);
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
            // props.onSubmitClick("red")
          }
        }
      } catch (err) {
        enqueueSnackbar("Input data not in proper JSON format! Please check.", {
          variant: "error",
        });
      }
    } else {
      var errorFlag = false;
      var tempError = {};
      for (var obj in dataObj) {
        if (dataObj[obj] === "") {
          tempError[obj] = true;
          errorFlag = true;
        }
      }
      setErrorObj((prevState) => ({
        ...prevState,
        ...tempError,
      }));
      if (!errorFlag) {
        setIsLoader(true);
        req_data = {
          applicationid: applicationID,
          templateid: templateid,
          topic: dataObj.topic,
          message: dataObj.message,
          servicetype: dataObj.servicetype,
        };
        const { _msg, _status, data } = await debugSimulateTemplate(req_data);
        if (_status === 200) {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "success" });
          if (data.instanceid) {
            await fetchInstanceContext(data.instanceid);
            props.alterSimulateStatus(true);
            setActiveTab("output");
          }
        } else {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "error" });
        }
      }
    }
  };

  const fetchData = async () => {
    // if (edit) {
    // const { _msg, _status, data } = await fetchNodeDetails(
    //   templateid,
    //   nodeid
    // );
    const { _msg, _status, data } = await fetchNodeDetails(
      templateid,
      callNodeObj?.id
    );
    setCallNodeObjData(data);
    if (_status === 200) {
      const {
        // name,
        // method,
        // endpoint,
        // baseurl,
        // headers,
        input,
        // serviceid,
        // versionid,
        // apiid,
        // topic,
        // message,
        // servicetype,
        // brokers,
        // serviceType2,
      } = data;
      setInputList(getKeyValueFromStringJSON(JSON.parse(input)));
      setInputEditorValue(JSON.stringify(JSON?.parse(input), null, "\t"));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
    // }
  };

  // useEffect(()=>[
  //   fetchData()
  // ],[])

  return (
    <div>
      <Dialog
        onEnter={fetchData}
        open={isOpen}
        onClose={() => props.onCloseClick()}
        scroll={"paper"}
        // aria-labelledby="scroll-dialog-title"
        // aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="lg"
      >
        <Backdrop
          className={classes.backdrop}
          open={isLoader}
          onClick={() => setIsLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1}>
            {/* <img src="/images/Call.png" alt="Icon" height={34} width={34} /> */}
            {debug_points.length === 0 && (
              <p className={classes.heading}>Simulate Flow Input</p>
            )}
            {debug_points.length > 0 && (
              <p className={classes.heading}>Debug Flow Input</p>
            )}
          </div>
        </DialogTitle>
        <DialogContent dividers={true} style={{ width: 800 }}>
          {dataObj.servicetype === "rest" && (
            <div className={classes.container}>
              <div className={classes.tab_container}>
                <Paper className={classes.root}>
                  <Tabs
                    value={activetab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // centered
                  >
                    <Tab label="Header" value="header" />
                    <Tab label="Query Params" value="query_params" />
                    <Tab label="Path Params" value="path_params" />
                    <Tab label="Body" value="body" />
                    <Tab label="Output" value="output" />
                  </Tabs>
                </Paper>
                {activetab === "body" && (
                  <div className={classes.iconContainer}>
                    <IconButton
                      color="inherit"
                      aria-label="textfield"
                      edge="start"
                      className={`${
                        activeInputType === "text"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => handleInputTypeChange("text")}
                    >
                      <img
                        src="/images/orchestration/lineicon.svg"
                        alt="text"
                      />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      aria-label="editor"
                      edge="start"
                      className={`${
                        activeInputType === "editor"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => handleInputTypeChange("editor")}
                    >
                      <img
                        src="/images/orchestration/editoricon.svg"
                        alt="editor"
                      />
                    </IconButton>
                  </div>
                )}
                {activetab === "output" && (
                  <div className={classes.iconContainer}>
                    <IconButton
                      color="inherit"
                      aria-label="textfield"
                      edge="start"
                      className={`${
                        activeOutputType === "text"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => setActiveOutputtype("text")}
                    >
                      <img
                        src="/images/orchestration/lineicon.svg"
                        alt="text"
                      />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      aria-label="editor"
                      edge="start"
                      className={`${
                        activeOutputType === "editor"
                          ? "toggleActive"
                          : "toggleInactive"
                      } ${classes.toggleicons}`}
                      onClick={() => setActiveOutputtype("editor")}
                    >
                      <img
                        src="/images/orchestration/editoricon.svg"
                        alt="editor"
                      />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className={classes.line5}>
                {/* <p className={classes.label}>Input</p> */}
                {activetab === "body" && activeInputType === "editor" && (
                  <div className={classes.outputbody}>
                    <Editor
                      onChange={(val) => handleEditorValueChange(val)}
                      value={inputEditorValue}
                    />
                  </div>
                )}
                {activetab === "body" && activeInputType === "text" && (
                  <InputTable
                    envs={inputList}
                    setEnvs={(val) => handleInputTableChange(val)}
                  />
                )}
                {activetab === "header" && (
                  <InputTable
                    envs={headerList}
                    setEnvs={(val) => handleHeaderTableChange(val)}
                  />
                )}
                {activetab === "query_params" && (
                  <InputTable
                    envs={queryParamsList}
                    setEnvs={(val) => handleQueryParamsListChange(val)}
                  />
                )}
                {activetab === "path_params" && (
                  <InputTable
                    envs={pathParamsList}
                    setEnvs={(val) => handlePathParamsListChange(val)}
                  />
                )}
                <>
                  {activetab === "output" && activeOutputType === "editor" && (
                    <div className={classes.outputbody}>
                      <Editor
                        onChange={(val) => setOutputEditorValue(val)}
                        value={
                          debug_points.length > 0
                            ? debugOutputBodyEditor
                            : outputBodyEditor
                        }
                      />
                    </div>
                  )}
                  {activetab === "output" && activeOutputType === "text" && (
                    <JSONToTable
                      jsonBody={
                        debug_points.length > 0 ? debugOutputBody : outputBody
                      }
                    />
                  )}
                </>
              </div>
            </div>
          )}
          {dataObj.servicetype !== "rest" && (
            <div className={classes.container}>
              <div className={classes.line2}>
                <div>
                  <p className={classes.label}>
                    Service Type <text className={classes.star}>*</text>
                  </p>
                  <FormControl className={classes.formControl}>
                    <Select
                      style={{ width: "21.2rem", marginTop: 0 }}
                      value={dataObj.servicetype}
                      onChange={(e) =>
                        handleTextChange("servicetype", e.target.value)
                      }
                      className={classes.select}
                      disabled
                    >
                      {/* <option value="">None</option> */}
                      <MenuItem value={"rest"}>REST</MenuItem>
                      <MenuItem value={"rabbitmq"}>RabbitMq</MenuItem>
                      <MenuItem value={"kafka"}>Kafka</MenuItem>
                      <MenuItem value={"mqtt"}>Mqtt</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.name}>
                  <p className={classes.label}>
                    Topic <text className={classes.star}>*</text>
                  </p>
                  <input
                    style={errorObj.topic ? { borderColor: "red" } : {}}
                    className={classes.value}
                    type={"text"}
                    name="topic"
                    value={dataObj.topic}
                    onChange={(e) => handleTextChange("topic", e.target.value)}
                  />
                  {errorObj.topic ? (
                    <text className={classes.error_text}>Invalid Topic</text>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={classes.name} style={{ margin: 10 }}>
                <p className={classes.label}>
                  Message <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.message ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type={"text"}
                  name="message"
                  value={dataObj.message}
                  onChange={(e) => handleTextChange("message", e.target.value)}
                />
                {errorObj.message ? (
                  <text className={classes.error_text}>Invalid Message</text>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={() => props.onCloseClick()}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
          />
          <AppButton
            onClick={handleSubmitClick}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addProcessOutput,
  alterDebugData,
  alterSimulateStatus,
  addAnimations,
  addDebugAnimation,
};

const mapStateToProps = (state) => {
  const {
    application_data,
    process_data,
    process_output,
    container_data,
    debug_points,
    debug_data,
    data_flow_nodes_io_details,
    elements,
  } = state.orchestration;
  const { templateid } = process_data;
  const { instanceid } = container_data;
  const { _id } = application_data.data;
  var debug_obj = {};
  debug_points.length > 0 &&
    debug_points.forEach((item) => {
      debug_obj[item] = true;
    });
  return {
    templateid: templateid,
    applicationID: _id,
    outputBody:
      process_output.body.length > 0 ? JSON.parse(process_output.body) : {},
    outputBodyEditor: process_output.body ? process_output.body : "",
    instanceid: instanceid ? instanceid : "",
    debug_points: debug_points,
    debug_obj: debug_obj,
    debugOutputBody:
      debug_data.body.length > 0 ? JSON.parse(debug_data.body) : {},
    debugOutputBodyEditor: debug_data.body ? debug_data.body : "",
    data_flow_nodes_io_details: data_flow_nodes_io_details,
    startNodeObj: elements.find((item) => item.type === "start_node"),
    callNodeObj: elements.find((item) => item.type === "call_node"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulateModal);
