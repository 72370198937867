import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import manageStyle from "../CommonStyles/UserProfile";
import { useSelector, useDispatch } from "react-redux";
import { editUserDetails } from "../../redux/actions/userProfileAction";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import { timezone, dateFormat, changeHour, changeScreen } from "./data/timezone";
import Loader from "../../components/common/stuff/Loader";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "moment-timezone";
import moment from "moment-timezone";
import Switch from "@material-ui/core/Switch";
import { UserProfile } from "../../services/userProfile";
import { useSnackbar } from "notistack";
const _UserProfile = new UserProfile();

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		[theme.breakpoints.down("md")]: {
			maxHeight: "auto",
		},
	},
	secondContainer: {
		marginTop: "3em",
		[theme.breakpoints.down("lg")]: {
			width: "auto",
			height: "auto",
		},
	},
	text: {
		...theme.typography.fontFamily,
		fontSize: "14px",
		color: "#000000",
	},
	date: {
		...theme.typography.fontFamily,
		textTransform: "none",
		fontSize: "14px",
		color: "#707070",
	},
	arrowRight: {
		color: "#BCBCBC",
		height: "1.4rem",
		paddingTop: "4px",
		marginLeft: "4px",
	},
}));

const General = ({setInactivityTimeCheck}) => {
	const classes = useStyles();
	const styles = manageStyle();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.userDetails);
	const { userInfo, success, loading: loadDetails } = userDetails;

	const [date, setdate] = useState(userInfo.dateFormat ? userInfo.dateFormat : "YYYY-MM-DD");
	const [zone, setZone] = useState(userInfo.timeZone ? userInfo.timeZone : "UTC");
	const [language, setLanguage] = useState("English");
	const [hourRadio, sethourRadio] = useState(userInfo.time ? userInfo.time : "h:mm");

	const [isLoader, setIsLoader] = useState(false);
	const [inactivityTime, setInactivityTime] = useState(20);
	const [screenRadio, setscreenRadio] = useState(
		userInfo.screenCapture ? userInfo.screenCapture : changeScreen[1].value
	);

	const [toggleFA, setToggleFA] = useState(userInfo && userInfo.twofactorauthentication);
	const userEdit = useSelector((state) => state.userEdit);
	const { loading } = userEdit;

	const appearnce = useSelector((state) => state.appearnce);
	const { appear } = appearnce;

	const handleChangeDate = (event) => {
		setdate(event.target.value);
	};

	const handleChangeZone = (event) => {
		setZone(event.target.value);
	};
	const handleChangeLanguage = (event) => {
		setLanguage(event.target.value);
	};
	const handleChangeScreenCapture = (event) => {
		setscreenRadio(event.target.value);
	};
	const handleChangeHour = (event) => {
		sethourRadio(event.target.value);
	};

	const userd = {
		name: userInfo && userInfo.name,
		contactNo: userInfo.contactNo ? userInfo.contactNo : "",
		image: userInfo.image ? userInfo.image : "",
		timeZone: zone,
		time: hourRadio,
		dateFormat: date,
		language,
		screenCapture: screenRadio,
		userinactivity: inactivityTime,
	};
	/* eslint-disable */
	useEffect(() => {
		appear === "save" && dispatch(editUserDetails(userd));
		success === true &&
			enqueueSnackbar("User updated successfully", {
				variant: "success",
			});
	}, [appear]);

	const lang = [
		{
			value: "English",
			label: "English",
		},
	];

	const handleChangeToggle = async () => {
		setToggleFA(!toggleFA);
		setIsLoader(true);
		const userd = {
			twofactorauthentication: !toggleFA,
		};
		try {
			const { data } = await _UserProfile.change2FA(userInfo && userInfo._id, userd);
			if (data) {
				setIsLoader(false);
				setToggleFA(data.data.twofactorauthentication);
				enqueueSnackbar(
					data._msg && toggleFA === true
						? "Two-Factor Authentication disabled successfully"
						: "Two-Factor Authentication enabled successfully ",
					{
						variant: "success",
					}
				);
			}
		} catch (error) {
			setIsLoader(false);
			const { _msg, _status, data } = error.response.data;
			if (_msg) {
				enqueueSnackbar(_msg ? _msg : "Unable to process your request, please try after sometime", {
					variant: "error",
				});
			}
		}
	};

	useEffect(() => {
		if (userInfo.userinactivity) {
		  userInfo && setInactivityTime(userInfo.userinactivity);
		} else {
		  setInactivityTime(20);
		}
	  }, [success]);
	

	useEffect(() => {
		if (appear === undefined) {
			userInfo.timeZone && setZone(userInfo.timeZone);
			userInfo.time && sethourRadio(userInfo.time);
			userInfo.dateFormat && setdate(userInfo.dateFormat);
			userInfo.screenCapture && setscreenRadio(userInfo.screenCapture);
		}
	}, [appear]);

	return (
		<Grid container direction="column">
			{loading && <Loader />}
			{loadDetails && <Loader />}
			{isLoader && <Loader />}
			<Grid container direction="column" className={classes.mainContainer}>
				<Grid item container alignItems="center" className={styles.titleC}>
					<Grid item lg={2} md={2}>
						<Typography className={styles.title}>Date and Time</Typography>
					</Grid>
					<Grid item container lg={10} md={10} justify="flex-end"></Grid>
				</Grid>
				<Grid item container className={styles.divider} />

				<Grid item container style={{ padding: 21, paddingBottom: 0 }} spacing={5}>
					<Grid item lg={4} md={6} sm={6}>
						<Typography className={classes.text}>Current Time</Typography>
					</Grid>
					<Grid item lg={8} md={6} sm={6}>
						<Typography className={classes.date}>
							{moment()
								.tz(zone)
								.format(hourRadio === "h:mm" ? `${date}, ${hourRadio} A` : `${date}, ${hourRadio}`)}
						</Typography>
					</Grid>
					<Grid item lg={4} md={6} sm={6}>
						<Typography className={classes.text}>Time Zone</Typography>
					</Grid>
					<Grid item lg={8} md={6} sm={6}>
						<TextField
							select
							disabled={appear === true ? false : true}
							variant="outlined"
							size="small"
							value={zone}
							onChange={handleChangeZone}
							InputProps={{
								classes: { input: classes.input1 },
								style: {
									height: "38px",
									padding: "0px",
									width: "355px",
									background: !appear ? "#F4F4F4 0% 0% no-repeat padding-box" : undefined,
								},
							}}
							SelectProps={{
								MenuProps: {
									anchorOrigin: { vertical: "bottom", horizontal: "center" },
									transformOrigin: { vertical: "top", horizontal: "center" },
									getContentAnchorEl: null,
								},
							}}>
							{timezone.map((option) => (
								<MenuItem key={option.name} value={option.value}>
									{option.value}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item lg={4} md={6} sm={6}>
						<Typography className={classes.text}>Time Format</Typography>
					</Grid>
					<Grid item lg={8} md={6} sm={6}>
						<RadioGroup value={hourRadio} onChange={handleChangeHour}>
							<Grid container>
								<Grid item>
									{changeHour.map((option, i) => (
										<FormControlLabel
											key={i}
											value={option.value}
											control={
												<Radio
													color="primary"
													size="small"
													disabled={appear === true ? false : true}
													checked={option.value === hourRadio}
												/>
											}
											label={option.name}
										/>
									))}
								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
					<Grid item lg={4} md={6} sm={6}>
						<Typography className={classes.text}>Date Format</Typography>
					</Grid>
					<Grid item lg={8} md={6} sm={6}>
						<TextField
							select
							disabled={appear === true ? false : true}
							fullWidth
							defaultValue=""
							variant="outlined"
							size="small"
							value={date}
							onChange={handleChangeDate}
							InputProps={{
								classes: { input: classes.input1 },
								style: {
									width: "26.5rem",
									height: "38px",
									padding: "0px",
									background: !appear ? "#F4F4F4 0% 0% no-repeat padding-box" : undefined,
								},
							}}
							SelectProps={{
								MenuProps: {
									anchorOrigin: { vertical: "bottom", horizontal: "center" },
									transformOrigin: { vertical: "top", horizontal: "center" },
									getContentAnchorEl: null,
								},
							}}>
							{dateFormat.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.value}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</Grid>
			{/* 2FA section */}
			<Grid container direction="column" className={classes.secondContainer}>
				<Grid item container alignItems="center" className={styles.titleC}>
					<Grid item lg={2} md={2}>
						<Typography className={styles.title}>Authentication</Typography>
					</Grid>
					<Grid item container lg={10} md={10} justify="flex-end"></Grid>
				</Grid>
				<Grid item container className={styles.divider} />
				<Grid item container md direction="column" style={{ padding: 21, paddingBottom: 0 }}>
					<Grid container alignItems="center" style={{ marginTop: "0.7rem" }}>
						<Grid item lg={4} md={4} sm={4}>
							<Grid item container md direction="column" lg={4}>
								<Grid item>
									<Typography className={classes.text}>Enable 2FA</Typography>
								</Grid>
								<Grid item style={{ marginTop: 6 }}>
									<Typography className={classes.date} style={{ fontSize: "12px" }}>
										Email OTP
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Switch
								disabled={appear === true ? false : true}
								checked={toggleFA}
								value={toggleFA}
								onChange={() => {
									handleChangeToggle();
								}}
								name="checkedA"
								inputProps={{ "aria-label": "secondary checkbox" }}
								color="primary"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* ending 2FA */}
			{/* 2 */}
			<Grid container direction="column" className={classes.secondContainer}>
				<Grid item container alignItems="center" className={styles.titleC}>
					<Grid item lg={2} md={2}>
						<Typography className={styles.title}>Language</Typography>
					</Grid>
				</Grid>
				<Grid item container className={styles.divider} />

				<Grid item container md direction="column" style={{ padding: 21 }}>
					<Grid container alignItems="center" style={{ marginTop: "1rem" }}>
						<Grid item container md direction="column" lg={4}>
							<Grid item>
								<Typography className={classes.text}>Language</Typography>
							</Grid>
							<Grid item style={{ marginTop: 6 }}>
								<Typography className={classes.date} style={{ fontSize: "12px" }}>
									Navigation, Menu and Settings
									<br />
									will appear in selected language
								</Typography>
							</Grid>
						</Grid>
						<Grid item lg={6} md={6}>
							<TextField
								select
								id="langSelector"
								disabled={appear === true ? false : true}
								fullWidth
								variant="outlined"
								size="small"
								value={language}
								onChange={handleChangeLanguage}
								InputProps={{
									classes: { input: classes.input1 },
									style: {
										height: "38px",
										padding: "0px",
										width: "355px",
										background: !appear ? "#F4F4F4 0% 0% no-repeat padding-box" : undefined,
									},
								}}
								SelectProps={{
									MenuProps: {
										anchorOrigin: { vertical: "bottom", horizontal: "center" },
										transformOrigin: { vertical: "top", horizontal: "center" },
										getContentAnchorEl: null,
									},
								}}>
								{lang.map((option) => (
									<MenuItem key={option.label} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container direction="column" className={classes.secondContainer}>
        <Grid item container alignItems="center" className={styles.titleC}>
          <Grid item lg={2} md={2}>
            <Typography className={styles.title}>Code Editor</Typography>
          </Grid>
        </Grid>
        <Grid item container className={styles.divider} />
        <Grid item container md direction="column" style={{ padding: 21 }}>
          <Grid container alignItems="center" style={{ marginTop: "1rem" }}>
            <Grid item container md direction="column" lg={4}>
              <Grid item>
                <Typography className={classes.text}>
                  Inactivity period
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: 6 }}>
                <Typography
                  className={classes.date}
                  style={{ fontSize: "12px" }}
                >
                  Time period waited to shutdown the draft container
                  <br />
                  after the user's last activity(in minutes)
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={8} md sm alignItems="center">
              {/* <Grid item lg={12}>
              <Typography className={classes.text}>Minutes</Typography>
            </Grid> */}
              <Grid item lg={12}>
                <TextField
                  disabled={appear === true ? false : true}
                  onChange={(e) => {
                    setInactivityTime(e.target.value);
                    setInactivityTimeCheck(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={inactivityTime}
                  // defaultValue={userInfo.userinactivity}
                  InputProps={{
                    inputMode: "numeric",
                    classes: { input: classes.input1 },
                    style: {
                      height: "38px",
                      padding: "0px",
                      width: "355px",
                      background: !appear
                        ? "#F4F4F4 0% 0% no-repeat padding-box"
                        : undefined,
                    },
                  }}
                  required
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

			{/* ending2 */}
			{/* 3 */}
			<Grid container direction="column" className={classes.secondContainer}>
				<Grid item container alignItems="center" className={styles.titleC}>
					<Grid item lg={2} md={2}>
						<Typography className={styles.title}>Help Desk</Typography>
					</Grid>
				</Grid>
				<Grid item container className={styles.divider} />
				<Grid item container md direction="column" style={{ padding: 21 }}>
					<Grid container alignItems="center" style={{ marginTop: "1rem" }}>
						<Grid item container md direction="column" lg={4}>
							<Grid item>
								<Typography className={classes.text}>Screen Capture</Typography>
							</Grid>
							<Grid item style={{ marginTop: 6 }}>
								<Typography className={classes.date} style={{ fontSize: "12px" }}>
									When you click on Help icon on the screen
									<br />
									Application will capture screen add it to attachments
								</Typography>
							</Grid>
						</Grid>
						<Grid item container lg={8} md sm alignItems="center">
							<Grid item lg={12}>
								<RadioGroup value={screenRadio} onChange={handleChangeScreenCapture}>
									<Grid container>
										<Grid item>
											{changeScreen.map((option, i) => (
												<FormControlLabel
													key={i}
													value={option.value}
													control={
														<Radio
															color="primary"
															size="small"
															disabled={appear === true ? false : true}
															checked={option.value === screenRadio}
														/>
													}
													label={option.name}
												/>
											))}
										</Grid>
									</Grid>
								</RadioGroup>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* ending 3 */}
		</Grid>
	);
};

export default General;