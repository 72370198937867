const executionFilterColumns = [
    { name: "name", title: "Execution ", for: "ExecutionFilters" },
    { name: "deploymentname", title: "Deployment", for: "ExecutionFilters" },
    { name: "status", title: "Status", for: "ExecutionFilters" },
    { name: "passpercentage", title: "Pass %", for: "ExecutionFilters" },
    { name: "ReRun", title: "Rerun",  for: "ExecutionFilters" },
    { name: "createdon", title: "Run On", for: "service" },
    { name: "createdby", title: "Run By", for: "service" },
    { name: "collection", title: "Script", for: "service" },
    { name: "runduration", title: "Run Duration(ms)", for: "service" },
  ];
  
  const executionFilterDefaultColumnWidths = [
    { columnName: "name", weightage: 2.5, width: 200 },
    { columnName: "deploymentname", weightage: 2.5, width: 200 },
    { columnName: "status", weightage: 2.5, width: 200 },
    { columnName: "passpercentage", weightage: 1.5, width: 200 },
    { columnName: "ReRun", weightage: 2.5, width: 200 },
    { columnName: "createdon", weightage: 1.5, width: 200 },
    { columnName: "createdby", weightage: 1.5, width: 200 },
    { columnName: "collection", weightage: 2.5, width: 200 },
    { columnName: "runduration", weightage: 1.5, width: 200 }
  ];
  
  const executionFilterTableColumnExtensions = [
    { columnName: "name", align: "left" },
    { columnName: "deploymentname", align: "left" },
    { columnName: "status", align: "left" },
    { columnName: "passpercentage", align: "left" },
    { columnName: "ReRun", align: "center", sortingEnabled: false },
    { columnName: "createdon", align: "left" },
    { columnName: "createdby", align: "left" },
    { columnName: "collection", align: "left" },
    { columnName: "runduration", align: "left" },
  ];
  
  const executionFilterColumnOrder = [
    "name",
    "deploymentname",
    "status",
    "passpercentage",
    "status",
    "ReRun",
    "createdon",
    "createdby",
    "collection",
    "runduration"
  ];
  
  const executionFilterColumnHidden = [
    "createdon",
    "createdby",
    "collection",
    "runduration"    
  ];
  
  const executionFilterDefaultSorting = [];
  
  export {
    executionFilterColumns,
    executionFilterDefaultColumnWidths,
    executionFilterTableColumnExtensions,
    executionFilterColumnOrder,
    executionFilterDefaultSorting,
    executionFilterColumnHidden
  };
  