import { TeamAPIServices } from "../../services/teamServices";

const teamServices = new TeamAPIServices()

export const fetchTeamListData = async (currentPage, filter) => {
    try {
        const res_data = await teamServices.getTeamList(currentPage, filter);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const assignDeassignTeamToProject = async (projectId, teamData, forType) => {
    try {
        const res_data = await teamServices.assignDeassignTeamToProject(projectId, teamData, forType);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const assignDeassignTeamToServiceGroup = async (projectId, groupId, teamData, forType) => {
    try {
        const res_data = await teamServices.assignDeassignTeamToServiceGroup(projectId, groupId, teamData, forType);
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};