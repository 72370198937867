import { SET_UIAPPLICATION_LIST_DATA } from "../uiActionTypes";

const initialstate = {
  data: [],
};

export default function uiApplicationListReducer(state = initialstate, action) {
  switch (action.type) {
    case SET_UIAPPLICATION_LIST_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}