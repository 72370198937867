import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import devExpressStyles from "../styles/DevExpressTableStyles";

const EndPoint = ({ ...restProps }) => {
  const port = restProps.row.Port;
  const path = restProps.row.path;
  return restProps?.row?.ingress?.ingress ? (
    <>
      <Tooltip
        title={`http://${restProps?.row?.ingress?.ingress[0]?.hostname}:${port}${path}`}
        placement="bottom-start"
      >
        <a
          href={`http://${restProps?.row?.ingress?.ingress[0]?.hostname}:${port}${path}`}
          target="_blank"
        >
          http://
          {restProps?.row?.ingress?.ingress[0]?.hostname}:{port}
          {path}
        </a>
      </Tooltip>
    </>
  ) : (
    ""
  );
};

const Cell = (props) => {
  const { column } = props;

  if (column.name === "endPoint") {
    return (
      <VirtualTable.Cell {...props}>
        <EndPoint {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} />;
  }
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const DeploymentContainerAPITable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(
      props.defaultColumnWidths,
      windowDimensions,
      props.isCustomWidth
    );
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    setHiddenColumnNames(hideCols);
    // props.setHidden(hideCols);
  };

  return (
    <>
      <p
        style={{
          font: "normal normal bold 21px/28px Nunito",
          letterSpacing: 0,
          color: "#161616",
          opacity: 1,
          margin: 0,
          marginBottom: "5px",
        }}
      >
        {"List of APIs"}
      </p>
      <Paper className={styles.paper}>
        <div
          className={`${styles.myDevTable} ${
            props.isCustomHeight ? styles.listScreenTable : ""
          }`}
          ref={devExpressTable}
        >
          <Grid rows={props.rows} columns={props.columns}>
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSelection />
            <SortingState
              sorting={props.sort}
              onSortingChange={props.setSort}
              columnExtensions={props.tableColumnExtensions}
            />
            <IntegratedSorting />
            <DragDropProvider />

            <VirtualTable
              height={
                props.isCustomHeight
                  ? windowDimensions.height - 360
                  : getInsideTableHeight(props.rows)
              }
              columnExtensions={props.tableColumnExtensions}
              cellComponent={Cell}
              noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
            />

            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
            <TableColumnReordering defaultOrder={props.columnOrder} />
            <TableHeaderRow showSortingControls />

            {!props.hideColumnChooser && (
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={handleChooseColumn}
                columnExtensions={[
                  { columnName: "action", togglingEnabled: false },
                ]}
              />
            )}
            {!props.hideColumnChooser && <Toolbar />}
            {!props.hideColumnChooser && <ColumnChooser />}
          </Grid>
          {props.loading && <CircularProgress className={styles.loader} />}
        </div>
      </Paper>
    </>
  );
};

export default DeploymentContainerAPITable;
