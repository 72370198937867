import {
    ADD_STANDARD_DOCUMENTS_DATA,STANDARD_DOCUMENTS_FILTERS,STANDARD_DOCUMENTS_SORT,CLEAR_STANDARD_DOCUMENTS
  } from "../actionTypes";
  import { v4 as uuidv4 } from "uuid";
  
  
  const initialState = {
    standard_documents_list:{
      data:[],
      error:false,
      loading:false
    },
    standard_documents_filters: [
      { id: uuidv4(), column: "", operator: "", value: "" },
      
    ],
    standard_documents_sort: [
      { columnName: "name", direction: "desc" }
    ],
  };
  
  export default function standardDocumentReducer(state = initialState, action) {
    switch (action.type) {
      case ADD_STANDARD_DOCUMENTS_DATA: {
        return {
          ...state,
          standard_documents_list: {
            ...state.standard_documents_list,
            ...action.payload,
          },
        };
      }
      
      case STANDARD_DOCUMENTS_FILTERS: {
        return {
            ...state,
            standard_documents_filters: [...action.payload]
        }
    }

    case STANDARD_DOCUMENTS_SORT: {
      return {
          ...state,
          standard_documents_sort: [...action.payload]
      }
  }
    case CLEAR_STANDARD_DOCUMENTS:{
      return{
        ...state,
        standard_documents_list:{}

      }
    }
      default:
      return state;
    }
  }
  
  