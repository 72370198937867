import { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ServiceTableStyles from "../../styles/commonStyles/ServiceTable";
import { CommonServices } from "../../../services/commonServices";
import { Pagination, PaginationItem } from "@material-ui/lab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useSnackbar } from "notistack";

function Row(props) {
  const { service, setLoading, selectedServices, setSelectedServices, isEditMode } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [versions, setVersions] = useState([]);

  const commonService = new CommonServices();

  const handleFetchVersion = async (id) => {
    setOpen(!open);
    try {
      setLoading(true);
      var resp = await commonService.getVersionListForService(id);
      setVersions(
        resp?.data?.data
          ? resp?.data?.data?.map((item) => {
              return {
                ...item,
                checked:
                  selectedServices &&
                  selectedServices.length &&
                  selectedServices?.find(
                    (serv) =>
                      serv?.versionID === item?._id && serv?.serviceID === id
                  )
                    ? true
                    : false,
              };
            })
          : []
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleVersionCheck = (
    checked,
    serviceID,
    serviceName,
    serviceType,
    ver,
    status
  ) => {
    setVersions(
      versions.map((item) => {
        if (item._id === ver?._id && status === "Success") {
          return {
            ...item,
            checked: checked,
          };
        }
        if (
          item._id === ver?._id &&
          serviceType === "External" &&
          status === "Failed"
        ) {
          return {
            ...item,
            checked: checked,
          };
        } else if (
          item._id === ver?._id &&
          serviceType === "Internal" &&
          status === "Failed"
        ) {
          enqueueSnackbar("Cannot select failed builds of Internal Services", {
            variant: "error",
          });
          return {
            ...item,
            checked: false,
          };
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      })
    );
    let tempServices = selectedServices;
    if (!checked) {
      tempServices = tempServices.filter(
        (serv) => serv.serviceID !== serviceID && serv.versionID !== ver?._id
      );
    } else {
      tempServices = tempServices.filter(
        (serv) => serv.serviceID !== serviceID
      );
      tempServices.push({
        versionName: ver?.versionTag,
        versionID: ver?._id,
        serviceName: serviceName,
        serviceID: serviceID,
        serviceType: serviceType,
        environmentID: ver?.docker?.environmentID,
        imagedetails: ver?.docker?.imagedetails,
        environmentVariables: ver?.environmentVariables,
        baseUrl: `http://${
          ver?.docker?.Instances?.[
            ver?.docker?.Instances?.length && ver?.docker?.Instances?.length - 1
          ]?.containername
        }:${ver?.port}`,
        containerName:
          ver?.docker?.Instances?.[
            ver?.docker?.Instances?.length && ver?.docker?.Instances?.length - 1
          ]?.containername,
      });
    }
    setSelectedServices(tempServices);
  };
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ paddingLeft: "1rem" }}>
          <IconButton onClick={() => handleFetchVersion(service?._id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {service?.name}
        </TableCell>
        <TableCell>{service?.imagename}</TableCell>
        <TableCell>{service?.projectDetails[0]?.name}</TableCell>
        <TableCell>{service?.servicegroupdetails[0]?.name}</TableCell>
        <TableCell>{service?.serviceType}</TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: "8px 30px" }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Version Tag</TableCell>
                      <TableCell>PORT</TableCell>
                      <TableCell>Build Status</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {versions &&
                      versions
                        ?.filter(
                          (version) => version.microserviceID === service?._id
                        )
                        .map((ver) => (
                          <TableRow key={ver?._id}>
                            <TableCell>
                              {/* <Checkbox
                                color="primary"
                                id="select-versions"
                                // name={ver?._id}
                                checked={ver?.checked}
                                onChange={(e) =>
                                  handleVersionCheck(
                                    e.target.checked,
                                    service?._id,
                                    service?.name,
                                    service?.serviceType,
                                    ver,
                                    ver?.docker.imagedetails &&
                                      ver?.docker.imagedetails.ImageID
                                      ? "Success"
                                      : "Failed"
                                  )
                                }
                              /> */}
                              <input
                                type="checkbox"
                                // color="primary"
                                id="select-versions"
                                // name={ver?._id}
                                checked={ver?.checked}
                                disabled={!isEditMode}
                                onChange={(e) =>
                                  handleVersionCheck(
                                    e.target.checked,
                                    service?._id,
                                    service?.name,
                                    service?.serviceType,
                                    ver,
                                    ver?.docker.imagedetails &&
                                      ver?.docker.imagedetails.ImageID
                                      ? "Success"
                                      : "Failed"
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>{ver?.versionTag}</TableCell>
                            <TableCell>{ver?.port}</TableCell>
                            <TableCell>
                              {service?.serviceType === "External"
                                ? "-"
                                : ver?.docker.imagedetails &&
                                  ver?.docker.imagedetails.ImageID
                                ? "Success"
                                : "Failed"}
                            </TableCell>
                            <TableCell>{ver?.createdby}</TableCell>
                            <TableCell>{ver?.description}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                {versions && versions.length === 0 && (
                  <span
                    style={{
                      height: 50,
                      backgroundColor: "white",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    No Version Available
                  </span>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function ServiceTable(props) {
  const classes = ServiceTableStyles();

  const {
    services,
    setServices,
    selectedServices,
    setSelectedServices,
    setLoading,
    isEditMode
  } = props;

  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      {isEditMode ? (
        <>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: "2rem" }}>
                    Service Name
                  </TableCell>
                  <TableCell>Image Name</TableCell>
                  <TableCell> Project Name</TableCell>
                  <TableCell> Service Group Name</TableCell>
                  <TableCell> Service Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services?.slice((page - 1) * 5, page * 5).map((item) => (
                  <Row
                    key={item._id}
                    service={item}
                    services={services}
                    setServices={setServices}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    setLoading={setLoading}
                    isEditMode={isEditMode}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.footer}>
            <h3 className={"itemsCount"}>{services?.length} items</h3>
            <Pagination
              count={Math.ceil(services?.length / 5)}
              shape="rounded"
              className={"pagination"}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: NavigateBeforeIcon,
                    next: NavigateNextIcon,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </>
      )
      : 
      (
      <>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: "2rem" }}>
                  Microservice Name
                </TableCell>
                <TableCell>Version Tag</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedServices && selectedServices?.length !== 0 ? selectedServices?.slice((page - 1) * 5, page * 5).map((item) => (
                 <TableRow key={item?.versionID}>
                  <TableCell style={{ paddingLeft: "2rem" }}>{item?.serviceName}</TableCell>
                  <TableCell>{item?.versionName}</TableCell>
               </TableRow>
              ))
              :
              <div
              style={{
                position: "absolute",
                textAlign: "center",
                marginTop: "2rem",
                left: "calc(50% - 4rem)",
                maxWidth: "11rem",
              }}
              >
                <img src="/images/notFound.svg" alt="notfound" />
                <h3 style={{ textAlign: "center" }}>No microservices found</h3>
              </div>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.footer}>
          <h3 className={"itemsCount"}>{selectedServices?.length} items</h3>
          <Pagination
            count={Math.ceil(selectedServices?.length / 5)}
            shape="rounded"
            className={"pagination"}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: NavigateBeforeIcon,
                  next: NavigateNextIcon,
                }}
                {...item}
              />
            )}
          />
        </div>
      </>
      )}
      
    </>
  );
}
