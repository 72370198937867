import { useState, useEffect } from "react";
import { Grid, Typography, IconButton, Tooltip } from "@material-ui/core";
import { AppButton } from "../../atoms/button/AppButton";
import { UiApplicationService } from "../../../services/UiApplicationService";
import ActionStyles from "./ActionStyles";
import { useDispatch } from "react-redux";
import {
  editAction,
  setActionOpen,
} from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";
import useTriggers from "../../../hooks/useTriggers";
import { editUiApplication } from "../../../../redux/actions/uiApplicationAction";
import Loader from "../../../../components/common/stuff/Loader";
import { useSnackbar } from "notistack";

const Topbar = ({ childToParent }) => {
  const { currentAction, currentProject, currentComponent } = useGetData();
  const { runApplicationSimulation, runServiceSimulation, isLoader } =
    useTriggers();
  const apiService = new UiApplicationService();
  const { enqueueSnackbar } = useSnackbar();
  const classes = ActionStyles();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [actionName, setActionName] = useState();
  const [nameError, setNameError] = useState(false);

  // here 'childToParent' props is used to pass 'actionName' changes from '<TopBar/>' to <Action.jsx/>
  // const handleUpdateActionName = async () => {
  //   try {
  //     const resp = await apiService.updateAction(
  //       currentProject?.id,
  //       currentAction?._id,
  //       { ...currentAction, name: actionName }
  //     );
  //     if (resp?.status === 201) {
  //       statusSnackBar("Action Name Updated Successfully!!!", "success");
  //     }
  //   } catch (err) {
  //     statusSnackBar(err?.response?.data?._msg, "error");
  //   }
  // };

  const statusSnackBar = (msg, statusVariant) => {
    enqueueSnackbar(msg, {
      variant: statusVariant,
    });
  };
  // This below function we were using when we were using edit 'action name' button.
  // const handleChangeActionName = () => {
  //   let error = false;
  //   const pages = currentProject.pages.map((page) => {
  //     return {
  //       ...page,
  //       components: page.components.map((component) => {
  //         let tempComp = {};
  //         if (
  //           component?.actionName === actionName &&
  //           component?.actionName !== currentComponent?.actionName
  //         ) {
  //           error = true;
  //           tempComp = component;
  //         } else {
  //           if (component?.data?.dataInput?.indexOf(currentAction?.name) >= 0) {
  //             let newActionName = component?.data?.dataInput?.replace(
  //               currentAction?.name,
  //               actionName
  //             );
  //             tempComp = {
  //               ...component,
  //               data: {
  //                 ...component?.data,
  //                 dataInput: newActionName,
  //               },
  //             };
  //           } else {
  //             tempComp = component;
  //           }
  //         }
  //         setNameError(error);
  //         return tempComp;
  //       }),
  //     };
  //   });
  //   if (!error) {
  //     handleUpdateActionName();
  //     dispatch(
  //       editAction({
  //         id: currentAction?._id,
  //         data: { name: actionName },
  //       })
  //     );
  //     dispatch(editUiApplication({ data: { pages: pages } }));
  //     setIsEdit(false);
  //   }
  // };

  const handleClose = async () => {
    if (
      JSON.stringify(currentAction?.data) === "{}" &&
      ((currentAction?.api?.type === "rest" &&
        currentAction?.api?.method === "GET") ||
        (currentAction?.process?.servicetype === "rest" &&
          currentAction?.process?.method === "GET"))
    ) {
      if (currentAction?.actionType === "service") {
        runServiceSimulation(currentAction, [], "topbar");
      } else {
        runApplicationSimulation(currentAction, [], "topbar");
      }
    }
    await apiService.editApplication(currentProject?._id, {
      actionOpen: false,
    });
    dispatch(setActionOpen({ actionOpen: false }));
  };

  useEffect(() => {
    setActionName(currentAction?.name);
    setNameError(false);
    setIsEdit(false);
  }, [currentAction]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => childToParent(actionName), [actionName]);

  const handleChangeActionName = (e) => {
    setActionName(e.target.value);
    dispatch(
      editAction({
        id: currentAction?._id,
        data: { name: e.target.value },
      })
    );
  };

  return (
    <Grid
      container
      // justifyContent="flex-end"
      alignItems="center"
    >
      {isLoader && <Loader />}
      <Grid container alignItems="center">
        <Grid item style={{ padding: 14 }} xs={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.text} style={{ marginRight: 20 }}>
              Name
            </Typography>
            <div className={classes.compHeading}>
              <div className={classes.compTitle}>
                <div style={{ marginTop: 3 }}>
                  <input
                    type="text"
                    name="name"
                    style={{
                      border: nameError
                        ? "1px solid #E22626"
                        : "1px solid #D8D8D8",
                    }}
                    value={actionName}
                    onChange={handleChangeActionName}
                    className={classes.input}
                  />
                  {nameError && (
                    <p className="nameError">Action Name already exist</p>
                  )}
                </div>
                {/* {!isEdit ? (
                  <IconButton
                    color="inherit"
                    edge="start"
                    style={{ margin: 0, padding: 5 }}
                    onClick={() => setIsEdit(true)}
                  >
                    <img src="/img/edit.svg" alt="edit" />
                  </IconButton>
                ) : (
                  <IconButton
                    color="inherit"
                    edge="start"
                    style={{ margin: 0, padding: 5 }}
                    onClick={handleChangeActionName}
                  >
                    <img src="/img/Save2.svg" alt="save" />
                  </IconButton>
                )} */}
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={6}
        >
          <Grid item>
            <AppButton
              buttonName="Run"
              variant="outlined"
              startIcon={<img src="/img/run.svg" alt="Eye" />}
              onClick={() => {
                if (currentAction?.actionType === "service") {
                  runServiceSimulation(currentAction, [], "topbar");
                } else {
                  runApplicationSimulation(currentAction, [], "topbar");
                }
              }}
              color="primary"
              style={{
                border: "1px solid #002855",
                minWidth: "6.75em",
                padding: 8,
                color: "#002855",
                maxHeight: "2.3rem",
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Close Actions">
              <IconButton
                color="inherit"
                edge="start"
                style={{ marginLeft: 8 }}
                onClick={handleClose}
              >
                <img src="/img/zoom.svg" alt="zoom" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Topbar;
