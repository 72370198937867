import { useEffect, useLayoutEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import CustomTable from "../../atoms/cutom-components/CustomTable";
import useGetData from "../../../hooks/useGetData";
import layoutStyles from "../../../styles/commonStyles/Layout";
import CustomDetails from "../../atoms/cutom-components/CustomDetails";
import CustomButton from "../../atoms/cutom-components/CustomButton";
import CustomText from "../../atoms/cutom-components/CustomText";
import Form from "../../atoms/form/Form";
import TextAreaComponent from "../TextAreaComp/TextAreaComponent";
import SelectComponent from "../SelectComp/SelectComponent";
import CustomHeading from "../../atoms/cutom-components/CustomHeading";
import CustomLink from "../../atoms/cutom-components/CustomLink";
import InputComponent from "../InputComp/InputComponent";
import CustomComponent from "../../atoms/cutom-components/CustomComponent";
import CustomMenu from "../../atoms/cutom-components/CustomMenu";
import CustomToggle from "../../atoms/cutom-components/CustomToggle";
import CustomDatePicker from "../../atoms/cutom-components/CustomDatePicker";
import CustomImage from "../../atoms/cutom-components/CustomImage";
import CustomRadio from "../../atoms/cutom-components/CustomRadio";
import CustomTree from "../../atoms/cutom-components/CustomTree";
import CheckboxComponent from "../CheckboxComp/CheckboxComponent";
import { UiApplicationService } from "../../../services/UiApplicationService";
import { useParams } from "react-router-dom";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const PreviewOutput = ({ isPublish }) => {
  const styles = layoutStyles();
  const { currentProject, currentPage } = useGetData();
  const params = useParams();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const apiService = new UiApplicationService();
  const [compPosition, setCompPosition] = useState({});
  const layouts = { lg: currentPage?.components };
  const [tempCurrentPageComponents, setTempCurrentPageComponents] = useState(
    []
  );

  const resize_reposition_comps = [
    "Text",
    "Button",
    "Heading",
    "Link",
    "Input",
    "DatePicker",
    "Select",
    "Toggle",
    "TextArea",
    "Radio",
    "Form",
    "Details",
    "CheckBox",
    "Image",
  ];

  useEffect(
    () => repositionImageComps({ ...currentPage }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.device]
  );

  useEffect(
    () =>
      repositionImageComps({
        ...currentPage,
        components: [...tempCurrentPageComponents],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tempCurrentPageComponents]
  );
  useEffect(() => {
    const fetchAllComponents = async () => {
      const resp = await apiService.fetchAllComponents(
        currentProject?.id,
        currentPage?.name
      );
      const compArr = resp?.data?.data ? resp?.data?.data : [];
      if (resp.status < 210 && compArr.length) {
        setTempCurrentPageComponents([...compArr].reverse());
        dispatch(
          editComponent({
            pageid: currentPage.id,
            data: [...compArr].reverse(),
          })
        );
      } else console.log("no components");
    };
    fetchAllComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page]);

  useLayoutEffect(() => {
    setTimeout(() => {
      const reactGridItems = document.querySelectorAll(".react-grid-item");
      if (
        Array.from(reactGridItems)?.some(
          (comp) => comp.getAttribute("id") === "Menu"
        )
      ) {
        let temp = {};
        reactGridItems?.forEach((comp) => {
          const compPos = comp.getBoundingClientRect();
          if (compPos.y < 165 && comp.getAttribute("id") !== "Menu") {
            temp = {
              ...temp,
              [comp.getAttribute("data-id")]: 164 - compPos.y,
            };
          }
        });
        setCompPosition(temp);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.device]);

  const repositionImageComps = (currentPage) => {
    setTimeout(() => {
      if (currentPage?.components?.[0]?._id !== undefined) {
        const components =
          currentPage?.components &&
          currentPage?.components?.map((comp) => {
            if (resize_reposition_comps.includes(comp?.type)) {
              let coordinates = {};
              if (params?.device.toLowerCase() === "mobile") {
                coordinates = {
                  x: comp?.moblie_X !== undefined ? comp?.moblie_X : comp?.x,
                  y: comp?.moblie_Y !== undefined ? comp?.moblie_Y : comp?.y,
                  w: comp?.moblie_W !== undefined ? comp?.moblie_W : comp?.w,
                  h: comp?.moblie_H !== undefined ? comp?.moblie_H : comp?.h,
                };
              } else if (params?.device.toLowerCase() === "tablet") {
                coordinates = {
                  x: comp?.tablet_X !== undefined ? comp?.tablet_X : comp?.x,
                  y: comp?.tablet_Y !== undefined ? comp?.tablet_Y : comp?.y,
                  w: comp?.tablet_W !== undefined ? comp?.tablet_W : comp?.w,
                  h: comp?.tablet_H !== undefined ? comp?.tablet_H : comp?.h,
                };
              } else {
                coordinates = {
                  x: comp?.web_X !== undefined ? comp?.web_X : comp?.x,
                  y: comp?.web_Y !== undefined ? comp?.web_Y : comp?.y,
                  w: comp?.web_W !== undefined ? comp?.web_W : comp?.w,
                  h: comp?.web_H !== undefined ? comp?.web_H : comp?.h,
                };
              }
              return {
                ...comp,
                ...coordinates,
              };
            } else return comp;
          });
        dispatch(
          editComponent({
            pageid: currentPage?.id,
            data: components,
          })
        );
      }
    });
  };

  const getComponent = (type, id) => {
    switch (type) {
      case "Table":
        return <CustomTable id={id} />;
      case "Details":
        return <CustomDetails id={id} project={currentProject} />;
      case "Text":
        return <CustomText id={id} project={currentProject} type={type} />;
      case "Button":
        return <CustomButton id={id} />;
      case "Form":
        return <Form id={id} project={currentProject} />;
      case "TextArea":
        return <TextAreaComponent id={id} project={currentProject} />;
      case "Select":
        return <SelectComponent id={id} project={currentProject} />;
      case "Heading":
        return <CustomHeading id={id} />;
      case "Link":
        return <CustomLink id={id} />;
      case "Input":
        return <InputComponent id={id} project={currentProject} type={type} />;
      case "Custom Component":
        return <CustomComponent id={id} />;
      case "Menu":
        return <CustomMenu setIsMenuOpen={setIsMenuOpen} id={id} />;
      case "Toggle":
        return <CustomToggle id={id} />;
      case "DatePicker":
        return (
          <CustomDatePicker id={id} project={currentProject} type={type} />
        );
      case "Image":
        return <CustomImage id={id} />;
      case "CheckBox":
        return <CheckboxComponent id={id} />;
      case "Radio":
        return <CustomRadio id={id} />;
      case "Tree":
        return <CustomTree id={id} />;
      default:
        break;
    }
  };

  useEffect(
    () => window.dispatchEvent(new Event("resize")),
    [currentProject?.previewMode]
  );

  return (
    <div className={`${styles.previewOutput} ${isPublish ? "publish" : ""}`}>
      <div
        className={
          params?.device === "MOBILE"
            ? "layout mobile-layout"
            : params?.device === "TABLET"
            ? "layout tablet-layout"
            : "layout desktop-layout"
        }
      >
        <ResponsiveReactGridLayout
          layouts={layouts}
          compactType={null}
          isDroppable={false}
          margin={[10, 14]}
          containerPadding={[20, 1]}
          allowOverlap={true}
          preventCollision={false}
          isDraggable={false}
          verticalCompact={false}
          breakpoints={{
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
            xxs: "100%",
          }}
          className={styles.webScroll}
          style={{
            height: `calc(100vh - ${params?.view === "preview" ? 112 : 2}px)`,
            overflowX: "hidden",
            overflowY: "auto",
          }}
          cols={{ lg: 600, md: 500, sm: 300, xs: 200, xxs: 100 }}
          rowHeight={1}
        >
          {currentPage?.components &&
            currentPage?.components.map((comp) => (
              <div
                className={`draggable ${comp.type !== "Table" && "comp"} ${
                  comp.isSelected && "active"
                }`}
                data-id={comp.id}
                key={comp.i}
                id={comp?.type}
                style={{
                  minWidth: `${comp?.w / 6}%`,
                  border: `${
                    comp?.type === "Table" && comp?.border
                      ? (comp?.borderWidth
                          ? comp?.borderWidth.toString()
                          : "1") +
                        "px solid " +
                        comp?.status
                      : comp?.type === "Menu"
                      ? "none"
                      : "1px solid #F5F5F5"
                  }`,
                  // top:
                  // compPosition?.[comp.id] !== undefined && params?.device
                  //   ? params?.view === "preview"
                  //     ? compPosition?.[comp.id]
                  //     : compPosition?.[comp.id] - 110
                  //   : "",
                  zIndex: comp?.type === "Menu" && isMenuOpen ? 1 : "",
                  display:
                    comp?.type === "Image" && comp?.forView
                      ? comp?.forView.toLowerCase() ===
                        params?.device.toLowerCase()
                        ? "block"
                        : "none"
                      : "",
                  left: "-21px",
                }}
              >
                {comp?.condition?.dataValue !== "false" &&
                  getComponent(comp?.type, comp?.id)}
              </div>
            ))}
        </ResponsiveReactGridLayout>
      </div>
    </div>
  );
};

export default PreviewOutput;
