import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import AssignModal from "../AssignModal";
import { useSnackbar } from "notistack";

// Modal
import LockUnlockModal from "../modals/LockUnblockModal";
import LockModal from "../modals/LockModal";
import UnlockModal from "../modals/UnLockModal";
import BlockModal from "../modals/BlockModal";
import UnBlockModal from "../modals/UnBlockModal";
import DeleteModal from "../modals/DeleteModal";
import TeamMembersModal from "../../Teams/TeamMembersModal";
import VendorProject from "../modals/VendorProject";
import Loader from "../stuff/Loader";
// Utils
import {
  DeleteLanguage,
  BlockLanguage,
  UnBlockLanguage,
} from "../../../utils/applicationGovernance/launguageUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { DeleteBoilerPlates } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { DeleteChassisTemplate } from "../../../utils/applicationGovernance/chassisTemplateUtils";
import { DeleteDockerImage } from "../../../utils/containerMangement/imageUtils";
import {
  DeleteContainer,
  StartContainer,
  StopContainer,
} from "../../../utils/containerMangement/containerUtils";
import { DeleteRuntimeEnv } from "../../../utils/containerMangement/runtimeEnvUtils";
import { deleteOrganization } from "../../../utils/organization/organizationUtils";
import {
  getUserTeamDetails,
  deleteUser,
  blockUser,
  unBlockUser,
} from "../../../utils/users/userUtils";
import {
  LockTeam,
  UnLockTeam,
  BLockTeam,
  UnBLockTeam,
} from "../../../utils/teams/teamsUtils";
import { blockVendor, UnblockVendor } from "../../../utils/vendor/vendorUtils";
import { BASE_URL } from "../../../services/helper/config";

//glossary
import GlossaryDetailModal from "../../../screens/Glossery/Modal/GlossaryDetailModal";

// State refresh
import { useDispatch, useSelector } from "react-redux";
import {
  delete_language,
  delete_boilerplate,
  delete_chassisTemplate,
  block_unblock_language,
} from "../../../redux/actions/appGovernanceAction";
import { delete_dockerImage } from "../../../redux/actions/dockerImageAction";
import {
  delete_container,
  start_or_stop_container,
} from "../../../redux/actions/containerAction";
import { delete_runtimeEnv } from "../../../redux/actions/runtimeEnvAction";
import {
  delete_user,
  block_unblock_user,
} from "../../../redux/actions/userInfoActions";
import {
  blockField,
  unblockField,
  blockFieldGroup,
  unblockFieldGroup,
  deleteFieldGroup,
} from "../../../utils/dataStandards/fieldsUtils";

import {
  deleteFieldgroup,
  deleteTagUtil,
} from "../../../redux/actions/dataStandardAction";
import moment from "moment";
import { block_unblock_vendor } from "../../../redux/actions/vendorAction";
import {
  lock_unlock_team,
  block_unblock_team,
} from "../../../redux/actions/teamActions";
import { active_inactive_org } from "../../../redux/actions/organizationAction";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";

import {
  deleteDataModelById,
  fetchAiModelDependenciesById,
  subscribeDataModel,
  unsubscribeDataModel,
} from "../../../utils/dataModels/dataModelutils";
import {
  alterSubscibeDataModelById,
  deleteDataModelId,
  addSingleDataModelSubscription,
  removeSingleDataModelSubscription,
  block_unblock_datamodel,
} from "../../../redux/actions/dataModelActions";
import {
  addSingleUserSubscription,
  removeSingleUserSubscription,
} from "../../../redux/actions/userPersonalAction";

import {
  blockDataModel,
  UnblockDataModel,
} from "../../../utils/dataModels/dataModelutils";
import DevExpressTableStyles from "../styles/DevExpressTableStyles";
import DependencyModal from "../modals/DependencyModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E2E2E3",
    borderRadius: "unset",
    boxShadow: "unset",
  },
  myDevTable: {
    // "& table": {
    //     tableLayout: 'auto'
    // },
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      // background: "#F9FAFB 0% 0% no-repeat padding-box",
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        font: "normal normal normal 18px/22px Nunito",
        textTransform: "capitalize",
        fontWeight: "500",
        padding: "11px 20px 12px 30px !important",

        "& span": {
          font: "normal normal 5000 34px/40px Nunito Sans",
          color: "#FFFFFF",
        },

        "& svg": {
          color: "#FFFFFF !important",
        },

        "& > div:last-child div": {
          backgroundColor: "#FFFFFF !important",
        },

        "&:nth-last-child(2) .MuiTableSortLabel-root": {
          position: "relative",
          right: 30,
        },
      },
    },
    "& tbody": {
      "& .MuiTableRow-root": {
        height: 47,
      },
      "& tr td": {
        padding: "4px 20px 4px 30px !important",
        font: "normal normal normal 13px/19px Nunito",
        // height: "4px",
        color: "#5c5c5c",
      },
      "& tr:hover": {
        background: "#F9FAFB 0% 0% no-repeat padding-box",
        cursor: "pointer",

        "& td span": {
          visibility: "visible",
        },
      },
    },

    // For Toolbar
    "& div > div:first-child": {
      borderBottom: "unset",
      minHeight: 0,

      "& > button": {
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 501,
        color: "#FFFFFF",
        background: theme.palette.primary.main,
        borderRadius: 0,
        padding: "13.5px 15px 13.5px",
      },
    },

    // For pagination
    "& div div:last-child": {
      "& div:first-child span": {
        fontSize: 16,
      },

      "& div:last-child span": {
        fontSize: 14,
      },

      "& div:last-child .MuiButton-text": {
        padding: "10px 13px",
        lineHeight: 1,
        margin: "0 10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 10px #5757571A",
        border: "0.5px solid #707070",
        borderRadius: 5,
      },

      "& td span": {
        visibility: "visible",
      },
    },
  },
  process_text: {
    textAlign: "right",
    textDecoration: "underline",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#031CCA",
    opacity: 0.6,
    alignItems: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  icon_style1: {
    marginRight: 10,
  },
  icon_style2: {
    marginLeft: 10,
  },
  selectedRowsText: {
    fontSize: 14,
    position: "absolute",
    bottom: 25,
    left: 20,
  },
  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
  actionIcons: {
    // border: "1px solid",
    width: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    // Team Lock Modal
    alertImg: {
      width: 76,
      height: 76,
    },
    cancel: {
      background: "#F9FAFD 0% 0% no-repeat padding-box",
      border: "1px solid #052855",
      borderRadius: 5,
      opacity: 1,
      color: "#052855",
    },
    submitMember: {
      background: "#052855 0% 0% no-repeat padding-box",
      borderRadius: 5,
      opacity: 1,
      color: "#FFFFFF",
      "&:hover": {
        background: "#052855 0% 0% no-repeat padding-box",
      },
    },
    userImg: {
      height: "50px !important",
      width: 50,
      margin: 0,
      padding: 0,
    },
    userName: {},
  },
  ActionIconBox: {
    width: 100,
    marginLeft: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  teamMember: {
    margin: 0,
    padding: 0,
    color: "#031CCA",
    textDecoration: "underline",
    font: "normal normal normal 14px/19px Nunito",
  },
  actionIcon: {
    padding: 6,
    margin: "0 5px",
  },

  launguageactionIcons: {
    // border: "1px solid",
    width: 80,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  builtenv: {
    "& .MuiTableCell-root": {
      borderBottom: "0 !important",
      padding: "0 30px 0 0 !important",
      // margin: "0 !importatnt",
    },
  },
  showblock: {
    "& span": {
      visibility: "visible",
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const DocumentNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        onClick={() =>
          history.push(`${restProps.column.route}/${restProps.row.id}`)
        }
      >
        <text className={styles.application_name}>{restProps.row.name}</text>
      </div>
    </VirtualTable.Cell>
  );
};

const UserProjectAssignModalCell = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actions} style={{ paddingLeft: 10 }}>
        <span onClick={handleModalClickOpen}>
          <img src="/images/application_list.svg" alt="icon" />
        </span>
        {ModalOpen && (
          <AssignModal
            open={ModalOpen}
            onCloseClick={handleModalClose}
            _id={restProps.row._id}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const EditAction = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actionIcons}>
        <div
          className={styles.actions}
          onClick={() =>
            history.push(`${restProps.column.route}/${restProps.row._id}`)
          }
        >
          <span>
            <img src="/images/teams/edit.svg" alt="edit" />
          </span>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

// Teams
const TeamNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() =>
          history.push(`${restProps?.column?.route}/${restProps?.row?._id}`)
        }
      >
        {restProps?.row?.type === "external" && (
          <div
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#00B5AD",
              borderRadius: 3,
            }}
          ></div>
        )}
        {restProps?.row?.type === "internal" && (
          <div
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#A333C8",
              borderRadius: 3,
            }}
          ></div>
        )}
        <div style={{ paddingLeft: 10 }}>
          <text className={styles.application_name}>
            {restProps?.row?.name}
          </text>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

const TeamMemberCell = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps?.row?.users && (
        <div>
          <div className={styles.actions}>
            <span onClick={handleModalClickOpen} style={{ color: "#2185D0" }}>
              <span>{restProps?.row?.users?.length}</span> {"Members"}
            </span>
            {ModalOpen && (
              <TeamMembersModal
                row={restProps?.row}
                open={ModalOpen}
                handleClose={handleModalClose}
              />
            )}
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const ActionIconsCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // userinfo and email
  const userData = useSelector((state) => state.authReducer);
  const { userInfo } = useSelector((state) => state.userDetails);

  const handleDeleteModalClickOpen = () => {
    // if(!checkCompPermission("DataModel",["Delete"])){
    setDeleteModalOpen(true);
    // }
    // else{
    //   enqueueSnackbar(
    //     `You do not have permission to Delete Data Model.Please contact your admin`,
    //     {
    //       variant: "error",
    //     }
    //   );

    // }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleLockModalClickOpen = () => {
    setLockModalOpen(true);
  };
  const handleLockModalClose = () => {
    setLockModalOpen(false);
  };
  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };
  const miscError = "Something went wrong. Please contact help desk.";

  const handleGlossaryDelete = async (_id) => {
    const { _msg, _status } = await deleteTagUtil(_id);
    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 200 ? { variant: "success" } : { variant: "error" }
    );
    if (_status === 200) {
      history.push("/dashboard");
      history.push("/glossary");
    }
  };

  const handleSubscribeResource = async (dataModelId) => {
    const { _msg, _status, data } = await subscribeDataModel(
      userInfo.email,
      userData.userid,
      dataModelId
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "fieldgroup") {
        // dispatch(alterSubscibeDataModelById(resourceinstance));
        dispatch(alterSubscibeDataModelById(dataModelId));
      } else if (restProps.column.for === "service") {
        // dispatch(alterFavouriteServiceById(resourceinstance))
      }
      dispatch(addSingleUserSubscription(data));
    } else {
      enqueueSnackbar(
        _msg != undefined
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleRemoveSubscribeResource = async (dataModelId) => {
    const { _msg, _status, data } = await unsubscribeDataModel(
      userData.userid,
      dataModelId
      // resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "fieldgroup") {
        // dispatch(alterSubscibeDataModelById(resourceinstance));
        dispatch(alterSubscibeDataModelById(dataModelId));
      } else if (restProps.column.for === "service") {
        // dispatch(alterFavouriteServiceById(resourceinstance))
      }
      // dispatch(removeSingleDataModelSubscription(resourceinstance));
      dispatch(removeSingleUserSubscription(dataModelId));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  //block unblock data model

  const handleBlock = async () => {
    const { _msg, _status } =
      restProps.column.for === "fieldgroup" &&
      (await blockDataModel(restProps.row._id));

    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 200 ? { variant: "success" } : { variant: "error" }
    );
    if (_status === 200) {
      dispatch(block_unblock_datamodel(restProps.row._id, "inactive"));
      // if (restProps.column.for === "field") {
      //   history.push("/dashboard");
      //   history.push("/data-standards");
      // } else {
      //   history.push("/dashboard");
      //   history.push("/services");
      // }
    }
    setBlockModalOpen(false);
  };

  const handleUnblock = async () => {
    const { _msg, _status } = await UnblockDataModel(restProps.row._id);
    enqueueSnackbar(
      _msg ? _msg : miscError,
      _status === 200 ? { variant: "success" } : { variant: "error" }
    );

    if (_status === 200) {
      dispatch(block_unblock_datamodel(restProps.row._id, "active"));
      // if (restProps.column.for === "field") {
      //   history.push("/dashboard");
      //   history.push("/data-standards");
      // } else {
      //   history.push("/dashboard");
      //   history.push("/services");
      // }
    }
    setBlockModalOpen(false);
  };

  // data model deleting

  const handleDelete = async () => {
    if (!checkScreenPermission("AIModel", ["Delete"])) {
      const { _msg, _status, data } = await deleteDataModelById(
        restProps.row._id
      );

      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDeleteModalOpen(false);
        //dispatch(deleteDataModelById(restProps.row._id));
        dispatch(deleteDataModelId(restProps.row._id));
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
        setDeleteModalOpen(false);
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to Delete Data Model.Please contact your admin`,
        {
          variant: "error",
        }
      );
    }
  };

  // Modal
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [dependencyData, setDependencyData] = useState([]);

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    if (restProps.column.for === "fieldgroup") {
      const { _msg, _status, data } = await fetchAiModelDependenciesById(
        restProps?.row?._id
      );
      const AiTask = data?.["AI Task"];
      if (_status === 200) {
        const aiTask =
          AiTask !== null
            ? AiTask.map((item) => {
                return {
                  title: item?.name,
                  expanded: true,
                };
              })
            : [];
        const tempData = [
          {
            title: aiTask?.length === 0 ? "No AI Tasks found" : "AI Tasks",
            expanded: true,
            children: [...aiTask],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    }
  };
  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };
  return (
    <>
      {dependencyModalOpen && (
        <DependencyModal
          modalTitle={
            dependencyData.length !== 0 ? "Dependencies" : "No Dependencies"
          }
          row={restProps.row}
          open={dependencyModalOpen}
          handleClose={handleDependencyModalClose}
          treeDataArr={dependencyData}
        />
      )}
      <VirtualTable.Cell {...restProps}>
        {restProps.column.for === "fieldgroup" ||
        restProps.column.for === "field" ? (
          <div
            className={styles.actionIcons}
            style={{ width: "4rem", marginLeft: "1rem" }}
          >
            {/* subscribe and Unsubscribe */}
            {restProps.column.for === "fieldgroup" && (
              <Tooltip
                title={restProps.row?.isSubscribe ? "Unsubscribe" : "Subscribe"}
                arrow
              >
                <IconButton
                  color="inherit"
                  aria-label="subscribe"
                  edge="start"
                  className={styles.actionIcon}
                  onClick={() => {
                    if (restProps.row?.isSubscribe) {
                      handleRemoveSubscribeResource(restProps.row._id);
                    } else {
                      handleSubscribeResource(restProps.row._id);
                    }
                  }}
                >
                  {restProps.row?.isSubscribe ? (
                    <img src="/images/Bell_Active.svg" alt="card" />
                  ) : (
                    <img src="/images/Bell_Inactive.svg" alt="card" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {/* edit */}
            <Tooltip title="Edit" arrow>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                className={styles.actionIcon}
                onClick={() => {
                  if (checkCompPermission("AIModel", ["Edit"])) {
                    if (restProps.row.state === "active") {
                      history.push(`datamodels/edit/${restProps.row._id}`);
                    } else {
                      enqueueSnackbar(
                        `Cannot edit a Blocked AI Model. Kindly unblock first.`,
                        {
                          variant: "error",
                        }
                      );
                    }
                  } else {
                    enqueueSnackbar(
                      `You do not have permission to Edit AI Model.Please Contact your Admin.`,
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
              >
                <img src="/images/editLine.svg" alt="card" />
              </IconButton>
            </Tooltip>
            {/* Blocl and Unblock */}
            <Tooltip
              title={restProps.row.state === "active" ? "Block" : "UnBlock"}
              arrow
            >
              <IconButton
                color="inherit"
                aria-label="block"
                edge="start"
                className={styles.actionIcon}
              >
                <span onClick={handleBlockModalClickOpen}>
                  <img
                    src="/images/teams/block.svg"
                    alt="block"
                    style={
                      restProps.row.state === "active"
                        ? { filter: "grayscale(100)", opacity: "0.8" }
                        : { opacity: "1" }
                    }
                  />
                </span>
                {blockModalOpen && restProps.row.state === "active" && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
                {blockModalOpen && restProps.row.state === "inactive" && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnblock}
                  />
                )}
              </IconButton>
            </Tooltip>
            {/* Delete */}
            {restProps.column.for === "fieldgroup" && (
              <Tooltip title="Delete" arrow>
                <IconButton
                  className={styles?.actionIcon}
                  color="inherit"
                  aria-label="delete"
                  edge="start"
                  onClick={handleDeleteModalClickOpen}
                  // disabled={checkCompPermission("DataModel",["Delete"])}
                >
                  <img src="/images/governance/delete.svg" alt="delete" />
                </IconButton>
              </Tooltip>
            )}
            {restProps.column.for === "fieldgroup" && (
              <Tooltip title="Dependencies" arrow>
                <IconButton
                  color="inherit"
                  aria-label="info"
                  edge="start"
                  className={styles.actionIcon}
                  onClick={handleDependencyModalClickOpen}
                >
                  <img src="/images/info_icon.svg" alt="card" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : restProps.column.for === "glossary" ? (
          <div
            className={styles.actionIcons}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "-20px",
            }}
          >
            <Tooltip title="Edit" arrow>
              <div
                className={styles.actions}
                style={{ marginRight: "15px", marginLeft: "-10px" }}
                onClick={() =>
                  history.push(`/edit-glossary/${restProps.row._id}`)
                }
              >
                <span>
                  <img src="/images/teams/edit.svg" alt="edit" />
                </span>
              </div>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <div className={styles.actions}>
                <span
                  onClick={() => {
                    handleGlossaryDelete(restProps.row._id);
                  }}
                >
                  <img
                    src="/images/governance/delete.svg"
                    alt="delete"
                    style={{ filter: "grayscale(100)", opacity: "0.8" }}
                  />
                </span>
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className={styles.actionIcons}>
            <div
              className={styles.actions}
              onClick={() =>
                history.push(`${restProps.column.route}/${restProps.row._id}`)
              }
            >
              <span>
                <img src="/images/teams/edit.svg" alt="edit" />
              </span>
            </div>
            <div className={styles.actions}>
              <span onClick={handleLockModalClickOpen}>
                <img src="/images/teams/lock.svg" alt="lock" />
              </span>
              {lockModalOpen && (
                <LockUnlockModal
                  row={restProps.row}
                  open={lockModalOpen}
                  handleClose={handleLockModalClose}
                />
              )}
            </div>
            <div className={styles.actions}>
              <span onClick={handleBlockModalClickOpen}>
                <img src="/images/teams/block.svg" alt="block" />
              </span>
              {blockModalOpen && (
                <BlockModal
                  row={restProps.row}
                  open={blockModalOpen}
                  handleClose={handleBlockModalClose}
                />
              )}
            </div>
          </div>
        )}
      </VirtualTable.Cell>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.name}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
const TeamActionIconsCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLockModalClickOpen = () => {
    setLockModalOpen(true);
  };
  const handleLockModalClose = () => {
    setLockModalOpen(false);
  };
  //  Lock
  const handlelock = async () => {
    const lockData = {};

    const { _msg, _status, data } = await LockTeam(restProps.row._id, lockData);
    if (_status === 423 || _status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setLockModalOpen(false);
      dispatch(lock_unlock_team(restProps.row._id, "true"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setLockModalOpen(false);
    }
  };

  const handleUnlock = async () => {
    const lockData = {};
    const { _msg, _status, data } = await UnLockTeam(
      restProps.row._id,
      lockData
    );
    if (_status === 200) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setLockModalOpen(false);
      dispatch(lock_unlock_team(restProps.row._id, "false"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setLockModalOpen(false);
    }
  };

  // BLOCK

  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleEdit = () => {
    if (
      restProps.row.lock_status === "true" ||
      restProps.row.block_status === "true"
    ) {
      enqueueSnackbar(
        "Cannot edit a Blocked or Locked Team. Kindly unblock or unlock first.",
        {
          variant: "error",
        }
      );
    } else {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span style={{ marginTop: 5, marginRight: 10 }}>
              <img src="/images/teams/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        {/* LOCK */}
        <div style={{ padding: "4px" }}>
          {restProps.row.lock_status === "false" && (
            <Tooltip title="Lock" arrow>
              <div>
                <span onClick={handleLockModalClickOpen}>
                  <img src="/images/lock.svg" alt="" />
                </span>
                {lockModalOpen && (
                  <LockModal
                    row={restProps.row}
                    open={lockModalOpen}
                    handleClose={handleLockModalClose}
                    handlelock={handlelock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.lock_status === "true" && (
            <Tooltip title="Unlock" arrow>
              <div>
                <span onClick={handleLockModalClickOpen}>
                  <img src="/images/teams/lock.svg" alt="" />
                </span>
                {lockModalOpen && (
                  <UnlockModal
                    row={restProps.row}
                    open={lockModalOpen}
                    handleClose={handleLockModalClose}
                    handleUnlock={handleUnlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* BLOCK */}
        <div style={{ padding: "4px" }}>
          {restProps.row.block_status === "false" && (
            <Tooltip title="Block" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="block" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.block_status === "true" && (
            <Tooltip title="Unblock" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="block" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

// USERS
const UserNameCell = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() =>
          history.push(`${restProps.column.route}/${restProps.row._id}`)
        }
      >
        {/* <div>
          <img
            src="/images/user.png"
            alt="user"
            style={{ height: 27, width: 27 }}
          />
          <span
            style={{
              height: 14,
              width: 14,
              backgroundColor: "#A333C8",
            }}
          ></span>
        </div> */}
        <div style={{ paddingLeft: 10 }}>
          <text className={styles.application_name}>{restProps.row.name}</text>
        </div>
      </div>
    </VirtualTable.Cell>
  );
};

const TeamsCell = ({ ...restProps }) => {
  const [userteamDetails, setUserteamDetails] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getUserTeamDetails(
        restProps.row._id
      );
      if (_status === 200) {
        setUserteamDetails(() => (data ? data : []));
      } else {
      }
    };
    fetchData();
  }, [restProps.row._id]);
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {userteamDetails &&
          userteamDetails?.length > 0 &&
          userteamDetails?.map((item) => {
            return (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.type === "external" && (
                  <div
                    style={{
                      height: 14,
                      width: 14,
                      backgroundColor: "#00B5AD",
                      borderRadius: 3,
                    }}
                  ></div>
                )}
                {item.type === "internal" && (
                  <div
                    style={{
                      height: 14,
                      width: 14,
                      backgroundColor: "#A333C8",
                      borderRadius: 3,
                    }}
                  ></div>
                )}
                <div style={{ padding: "0 5px" }}>{item.name}</div>
              </span>
            );
          })}
      </div>
    </VirtualTable.Cell>
  );
};

// APPLICATION GOVERNANCE
const IconCell = ({ ...restProps }) => {
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {restProps.row.icon && (
          <div>
            <Avatar
              src={restProps.row.icon}
              alt="icon"
              className={styles.small}
            />
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const BuiltEnvironment = ({ ...restProps }) => {
  const styles = useStyles();
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps && (
        <div>
          <span className={styles.application_name}>
            {restProps.row.dockerdetails.imageid}
          </span>
        </div>
      )}
    </VirtualTable.Cell>
  );
};
// launguage
const LanguageStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        onClick={() => histroy.push(`/launguage-details/${restProps.row._id}`)}
      >
        {restProps.row.status === "active" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: " center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
      <div>
        {restProps.row.status === "inactive" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: " center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const LaunguageActions = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleBlock = async (props) => {
    const blockData = {};
    const { _msg, _status, data } = await BlockLanguage(
      restProps?.row?._id,
      blockData
    );
    if (_status === 201) {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setBlockModalOpen(false);
      dispatch(block_unblock_language(restProps?.row?._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setBlockModalOpen(false);
    }
  };
  // UnBlock

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleEdit = () => {
    if (restProps?.row?.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked Language. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      history.push(`${restProps?.column?.route}/${restProps?.row?._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        {/* Block */}
        <div className={styles.actions}>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};
const BoilerplateActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteBoilerPlates(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_boilerplate(restProps.row._id));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setDeleteModalOpen(false);
    }
  };

  const handleDownload = async () => {
    setIsLoader(true);
    let response = await fetch(
      `${BASE_URL}/api/repo/repositories/boilerplates/${restProps.row._id}/_download`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      setIsLoader(false);
      setIsDownload(true);
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${restProps.row.filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownload(false);
    } else {
      setIsLoader(false);
      setIsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actions}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={() =>
              history.push(`${restProps.column.route}/${restProps.row._id}`)
            }
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.BoilerplateName}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};
const ChassisTemplateActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteChassisTemplate(
      restProps.row._id
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_chassisTemplate(restProps.row._id));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setDeleteModalOpen(false);
    }
  };

  const handleDownload = async () => {
    setIsLoader(true);
    let response = await fetch(
      `${BASE_URL}/api/repo/repositories/templates/${restProps.row._id}/_download`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      setIsLoader(false);
      setIsDownload(true);
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${restProps.row.filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownload(false);
    } else {
      setIsLoader(false);
      setIsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actions}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={() =>
              history.push(`${restProps.column.route}/${restProps.row._id}`)
            }
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.ChassisTemplate}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

const DockerimageActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const handleDownload = async () => {
    if (restProps.row.buildtype === "internal") {
      enqueueSnackbar("Cannot Download System Images", { variant: "error" });
    } else {
      setIsLoader(true);
      let response = await fetch(
        `${BASE_URL}/api/container/dockerimages/${restProps.row._id}/_download`,
        {
          headers: {
            organization: "60ba3575e1bc3c06e220ab5a",
            token: userData?.accesstoken,
          },
        }
      );
      if (response.status === 200) {
        setIsLoader(false);
        setIsDownload(true);
        enqueueSnackbar("Docker file is downloading, please wait...", {
          variant: "success",
        });
        let blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${restProps.row.imagename}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownload(false);
        enqueueSnackbar("Docker file is download successfully", {
          variant: "success",
        });
      } else {
        setIsLoader(false);
        setIsDownload(false);
        enqueueSnackbar("Unable to download, please try after sometime...", {
          variant: "error",
        });
      }
    }
  };

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteDockerImage(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_dockerImage(restProps.row._id));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setDeleteModalOpen(false);
    }
  };

  return (
    <VirtualTable.Cell {...restProps}>
      {isLoader && <Loader />}
      <div className={styles.ActionIconBox}>
        {isDownload && <CircularProgress color="primary" size={25} />}
        {!isDownload && (
          <Tooltip title="Download" arrow>
            <div className={styles.actions}>
              <span onClick={handleDownload}>
                <img src="/images/Download_icon.svg" alt="" />
              </span>
            </div>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={() => history.push(`edit-image/${restProps.row._id}`)}
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="delete" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.imagename}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};
const ContainersActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteContainer(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_container(restProps.row._id));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setDeleteModalOpen(false);
    }
  };

  // Start or Stop
  const handleStartContainer = async () => {
    const { _msg, _status, data } = await StartContainer(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(start_or_stop_container(restProps.row._id, "running"));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };
  const handleStopContainer = async () => {
    const { _msg, _status, data } = await StopContainer(
      restProps.row._id,
      "exited"
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(start_or_stop_container(restProps.row._id, "exited"));
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={() => history.push(`edit-container/${restProps.row._id}`)}
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.containername}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
        {restProps.row.state === "Created" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Create failed" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Exited" && (
          <Tooltip title="Start" arrow>
            <div className={styles.actions} onClick={handleStartContainer}>
              <span>
                <img src="/images/governance/start.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
        {restProps.row.state === "Running" && (
          <Tooltip title="Stop" arrow>
            <div className={styles.actions} onClick={handleStopContainer}>
              <span>
                <img src="/images/governance/stop.svg" alt="lock" />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </VirtualTable.Cell>
  );
};
const RuntimeEnvActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await DeleteRuntimeEnv(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_runtimeEnv(restProps.row._id));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.launguageactionIcons}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div
            className={styles.actions}
            onClick={() =>
              history.push(`edit-environment/${restProps.row._id}`)
            }
          >
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};
const UserActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await deleteUser(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(delete_user(restProps.row._id));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleEdit = () => {
    if (restProps.row.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked User. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.ActionIconBox}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        {/* Block */}

        <div>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}

          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>

        {/* Delete */}
        <Tooltip title="Delete" arrow>
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

const VendorStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      {restProps.row.status === "active" && (
        <div
          onClick={() => histroy.push(`/vendor-details/${restProps.row._id}`)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        </div>
      )}
      {restProps.row.status === "inactive" && (
        <div
          onClick={() => histroy.push(`/vendor-details/${restProps.row._id}`)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        </div>
      )}
    </VirtualTable.Cell>
  );
};

const VendorProjectModal = ({ ...restProps }) => {
  const styles = useStyles();

  const [ModalOpen, setModalOpen] = useState(false);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.actions} style={{ paddingLeft: 10 }}>
        <span onClick={handleModalClickOpen}>
          <img src="/images/application_list.svg" alt="icon" />
        </span>
        {ModalOpen && (
          <VendorProject
            open={ModalOpen}
            onCloseClick={handleModalClose}
            id={restProps.row.userid}
          />
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const VendorActions = ({ ...restProps }) => {
  const history = useHistory();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Block
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleBlockModalClickOpen = () => {
    setBlockModalOpen(true);
  };
  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const handleEdit = () => {
    if (restProps.row.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked Vendor. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        className={styles.launguageactionIcons}
        style={{
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>

        <div style={{ padding: "4px" }}>
          {restProps.row.status === "active" && (
            <Tooltip title="Block" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <BlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {restProps.row.status === "inactive" && (
            <Tooltip title="Unblock" arrow>
              <div className={styles.actions}>
                <span onClick={handleBlockModalClickOpen}>
                  <img src="/images/teams/block.svg" alt="lock" />
                </span>
                {blockModalOpen && (
                  <UnBlockModal
                    row={restProps.row}
                    open={blockModalOpen}
                    handleClose={handleBlockModalClose}
                    handleBlock={handleUnBlock}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </VirtualTable.Cell>
  );
};
const OrganizationActions = ({ ...restProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const { _msg, _status, data } = await deleteOrganization(restProps.row._id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setDeleteModalOpen(false);
      dispatch(active_inactive_org(restProps.row._id, "inactive"));
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = () => {
    if (restProps.row.status === "inactive") {
      enqueueSnackbar(
        "Cannot edit a Blocked Organization. Kindly unblock first.",
        {
          variant: "error",
        }
      );
    } else {
      history.push(`${restProps.column.route}/${restProps.row._id}`);
    }
  };
  return (
    <VirtualTable.Cell {...restProps}>
      <div className={styles.launguageactionIcons}>
        {/* edit */}
        <Tooltip title="Edit" arrow>
          <div className={styles.actions} onClick={handleEdit}>
            <span>
              <img src="/images/governance/edit.svg" alt="edit" />
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          {/* Delete */}
          <div className={styles.actions}>
            <span onClick={handleDeleteModalClickOpen}>
              <img src="/images/governance/delete.svg" alt="lock" />
            </span>
            {deleteModalOpen && (
              <DeleteModal
                name={restProps.row.name}
                row={restProps.row}
                open={deleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDelete}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </VirtualTable.Cell>
  );
};

const OrgStatusCell = ({ ...restProps }) => {
  const histroy = useHistory();
  return (
    <VirtualTable.Cell {...restProps}>
      <div
        style={{ padding: "4px" }}
        onClick={() =>
          histroy.push(`/organization-deatils/${restProps.row._id}`)
        }
      >
        {restProps.row.status === "active" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "#34BC41",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "#34BC41", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
      <div
        style={{ padding: "4px" }}
        onClick={() =>
          histroy.push(`/organization-deatils/${restProps.row._id}`)
        }
      >
        {restProps.row.status === "inactive" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restProps.row.status && (
              <div
                style={{
                  backgroundColor: "red",
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  position: "relative",
                  padding: "0 5px",
                }}
              ></div>
            )}
            <span style={{ color: "red", paddingLeft: 5 }}>
              {restProps.row.status.charAt(0).toUpperCase() +
                restProps.row.status.slice(1)}
            </span>
          </div>
        )}
      </div>
    </VirtualTable.Cell>
  );
};

const NoDataFound = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        textAlign: "center",
        top: "calc(50% - 4rem)",
        left: "calc(50% - 4rem)",
        maxWidth: "11rem",
      }}
    >
      <img src="/images/notFound.svg" alt="notfound" />
      <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
    </div>
  );
};

const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("YYYY-DD-MM, hh:mm A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CreatedOnCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state.userDetails);
  return (
    <>
      {(userInfo?.timeZone && restProps.row.createdon) ||
      restProps.row.updatedon
        ? moment
            .unix(restProps.row.createdon || restProps.row.updatedon)
            .tz(userInfo.timeZone)
            .format(
              userInfo.time === "h:mm"
                ? `${userInfo.dateFormat}, ${userInfo.time} A`
                : `${userInfo.dateFormat} ${userInfo.time}`
            )
        : moment.unix(restProps.row.createdon).format("DD/MM/YYYY, hh:mm:ss A")}
    </>
  );
};

const Cell = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { column } = props;
  const histroy = useHistory();
  const styles = useStyles();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  if (props.column.for === "language") {
    if (column.name === "builtenvironment") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/language-details/${props.row._id}`)}
        >
          <BuiltEnvironment {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "status") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/language-details/${props.row._id}`)}
        >
          <LanguageStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/language-details/${props.row._id}`)}
        >
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/language-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "chassistemplate") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() =>
            histroy.push(`/chassistemplate-details/${props.row._id}`)
          }
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() =>
            histroy.push(`/chassistemplate-details/${props.row._id}`)
          }
        />
      );
    }
  } else if (props.column.for === "boilerplate") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/boilerplate-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/boilerplate-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "dockerImage") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/image-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/image-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "container") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/container-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/container-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "environmentvaribales") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/environment-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/environment-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "teams") {
    if (column.name === "name") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <TeamNameCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "teamMember") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <TeamMemberCell {...props} />
        </VirtualTable.Cell>
      );
    }
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/team-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/team-details/${props.row._id}`)}
        />
      );
  } else if (props.column.for === "vendor") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <VendorStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "vendorProject") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <VendorProjectModal {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/vendor-details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/vendor-details/${props.row._id}`)}
        />
      );
    }
    // USERS
  } else if (props.column.for === "user") {
    if (column.name === "username") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/user-details/${props.row._id}`)}
        >
          <UserNameCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "userteams") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/user-details/${props.row._id}`)}
        >
          <TeamsCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "projectAssigned") {
      return (
        <VirtualTable.Cell className={styles.builtenv} {...props}>
          <UserProjectAssignModalCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/user-details/${props.row._id}`)}
        />
      );
    }
  } else if (props.column.for === "organization") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        >
          <OrgStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={() => histroy.push(`/organization-deatils/${props.row._id}`)}
        />
      );
    }
  } else if (column.name === "name2") {
    return <DocumentNameCell {...props} />;
  } else if (column.name === "actions") {
    return <ActionIconsCell {...props} />;
  } else if (column.name === "teamActions") {
    return <TeamActionIconsCell {...props} />;
  } else if (column.name === "teams") {
    return <TeamsCell {...props} />;
  } else if (column.name === "edit") {
    return <EditAction {...props} />;
  } else if (column.name === "languageactions") {
    return <LaunguageActions {...props} />;
  } else if (column.name === "boilerplateactions") {
    return <BoilerplateActions {...props} />;
  } else if (column.name === "chassisactions") {
    return <ChassisTemplateActions {...props} />;
  } else if (column.name === "icons") {
    return <IconCell {...props} />;
  } else if (column.name === "dockerimageaction") {
    return <DockerimageActions {...props} />;
  } else if (column.name === "containeractions") {
    return <ContainersActions {...props} />;
  } else if (column.name === "runtimeenvactions") {
    return <RuntimeEnvActions {...props} />;
  } else if (column.name === "userActions") {
    return <UserActions {...props} />;
  } else if (column.name === "vendorActions") {
    return <VendorActions {...props} />;
  } else if (column.name === "organizationAction") {
    return <OrganizationActions {...props} />;
  } else if (props.column.for === "fieldgroup") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/datamodels/details/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          onClick={() => histroy.push(`/datamodels/details/${props.row._id}`)}
          {...props}
        />
      );
    }
  } else if (props.column.for === "field" || props.column.for === "project") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell
          className={styles.builtenv}
          {...props}
          onClick={() => histroy.push(`/details-field/${props.row._id}`)}
        >
          <CreatedOnCell {...props} />
        </VirtualTable.Cell>
      );
    }
  } else if (props.column.for === "glossary") {
    return (
      <>
        <VirtualTable.Cell
          onClick={() => histroy.push(`glossary-details/${props.row._id}`)}
          {...props}
        />
        <GlossaryDetailModal
          row={props.row}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </>
    );
  }

  return <VirtualTable.Cell {...props} />;
};

const DataModelListTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);
  const style = DevExpressTableStyles();
  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 5) {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
    // }
  };
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <div className={style.myDevTable} ref={devExpressTable}>
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 280
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            checkCompPermission={checkCompPermission}
            noDataCellComponent={() => <NoDataFound msg="No Data" />}
          />

          <TableColumnResizing
            // defaultColumnWidths={true}
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />
          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "dockerimageaction", togglingEnabled: false },
                { columnName: "runtimeenvactions", togglingEnabled: false },
                { columnName: "containeractions", togglingEnabled: false },
                { columnName: "userActions", togglingEnabled: false },
                { columnName: "teamActions", togglingEnabled: false },
                { columnName: "vendorActions", togglingEnabled: false },
                { columnName: "organizationAction", togglingEnabled: false },
                { columnName: "languageactions", togglingEnabled: false },
                { columnName: "boilerplateactions", togglingEnabled: false },
                { columnName: "chassisactions", togglingEnabled: false },
                { columnName: "microservicename", togglingEnabled: false },
                { columnName: "versionTag", togglingEnabled: false },
                { columnName: "actions", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default DataModelListTable;
