import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Paper,
    CircularProgress,
} from "@material-ui/core";
import useStyles from "./Styles"
import Pagination from "@material-ui/lab/Pagination";

import CreateApplicationRow from "../../../../components/Orchestration/Application/CreateApplicationRow/CreateApplicationRow";
// import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

const ServiceVersionSelectTable = (props) => {
    const styles = useStyles();

    const { rows, loading, page, setPage,isEditMode, applicationName } = props;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <TableContainer component={Paper} className={styles.table}>
                <Table aria-label="collapsible table" size="small">
                    <TableHead className={styles.head}>
                        <TableRow className={styles.row_head}>
                            {/* <TableCell style={{ width: "0rem" }}></TableCell> */}
                            <TableCell style={{ width: "5rem", paddingLeft: "4rem", color: "white" }}>
                                Service Name
                            </TableCell>
                            <TableCell style={{ width: "8rem", color: "white" }}>
                                Image Name
                            </TableCell>
                            <TableCell style={{ width: "8rem", color: "white" }}>
                                Project Name
                            </TableCell>
                            <TableCell style={{ width: "8rem", color: "white" }}>
                                Service Type
                            </TableCell>
                           
                            <TableCell
                                style={{ width: "0rem", color: "white" }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: "absolute",
                                    left: "50%",
                                    marginTop: "1rem",
                                }}
                                size={30}
                            />
                        )}
                        {(rows.length > 0 
                            ? rows.slice(
                                (page-1) * 5,
                                (page-1) * 5 + 5
                            )
                            : rows
                        ).map((row) => (
                            <CreateApplicationRow key={row._id} row={row} isEditMode={isEditMode} applicationName={applicationName}/>
                        ))}
                        {rows && rows.length === 0 && <div className={styles.nodata}><text>{"No Data Available"}</text></div>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination
                                className={styles.pagination}
                                count={Math.ceil(rows.length/5)}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default ServiceVersionSelectTable;