import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  showCondition: {
    "& .scheading": {
      textAlign: "left",
      letterSpacing: 0,
      font: "normal normal 600 12px/16px Nunito",
      color: "#767676",
      textTransform: "uppercase",
    },
    "& .scText": {
      font: "normal normal 600 14px/19px Nunito",
      "& .condition": {
        color: "#333333DE",
      },
      "& .inputmode": {
        color: "#AFAFAF",
      },

      "& .helptxt": {
        color: "#8C8C8C",
      },
    },
    "& .editorInput": {
      border: "1px solid #D8D8D8",
      position: "relative",
      borderRadius: 5,
      maxHeight: 250,
      minHeight: 40,
      overflow: "auto",

      "& .CodeMirror": {
        height: "auto",
        padding: 4,
        borderRadius: 5,
        "& .CodeMirror-scroll .CodeMirror-sizer": {
          minHeight: "100% !important",
        },
      },

      "& .inputWithIcon": {
        right: 0,
        top: 0,
        position: "absolute",
        zIndex: 7,

        "& .icon": {
          position: "absolute",
          top: 0,
          right: 0,
          background: "#F5F5F5 0% 0% no-repeat padding-box",
          border: "1px solid #D8D8D8",
          borderRadius: "0px 5px 5px 0px",
          // marginTop: 4,

          "& button": {
            padding: 8,
          },
        },
      },
    },
  },
}));
