/* eslint-disable */
import {
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  FilledInput,
  Tooltip,
  Tabs,
  Tab,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { AppButton } from "../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import {
  getServicesList,
  addServiceFilters,
  addServiceSort,
  addServiceHidden,
  clearServices,
} from "../../redux/actions/serviceAction";

import { connect } from "react-redux";
import DevExpressTable from "../../components/common/Tables/DevExpressTable";
import {
  serviceColumnHidden,
  serviceColumnOrder,
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
} from "../../constants/servicesConstant";
import CustomFilter from "../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../utils/common";
import { useRef } from "react";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import {
  fetchAllFieldGroups,
  setFieldGroupSort,
  addFieldGroupFilters,
  addFieldGroupHidden,
} from "../../redux/actions/dataStandardAction";
import {
  fieldGroupsColumnHidden,
  fieldGroupsColumnOrder,
  fieldGroupsColumns,
  fieldGroupsDefaultColumnWidths,
  fieldGroupsDefaultSorting,
  fieldGroupsTableColumnExtensions,
} from "../../constants/DataStandard/FieldGroups";
import ApplicationListTable from "../../components/common/Tables/ApplicationListTable";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";

import {
  fetchAllDataModels,
  addDataModelHidden,
  addDataModelFilters,
  setDataModelSort,
} from "../../redux/actions/dataModelActions";

import {
  dataModelColumns,
  dataModelDefaultColumnWidths,
  dataModelTableColumnExtensions,
  dataModelColumnOrder,
  dataModelDefaultSorting,
  dataModelColumnHidden,
} from "../../constants/dataModelConstant";
import {
  AITaskColumnHidden,
  AITaskColumnOrder,
  AITaskColumns,
  AITaskDefaultColumnWidths,
  AITaskTableColumnExtensions,
} from "../../constants/AiTaskTableConstant";
import {
  addAITaskFilters,
  addAiTaskSort,
  getAITasksList,
} from "../../redux/actions/aiTaskActions";
import DataModelListTable from "../../components/common/Tables/DataModelListTable";
import SearchBar from "../../components/common/SearchBar";

const AiWorkbenchList = (props) => {
  const {
    data,
    aiTaskList,
    getServicesList,
    loading,
    _totalcount,
    addServiceFilters,
    addAITaskFilters,
    addFieldGroupFilters,
    addDataModelFilters,
    serviceFilters,
    serviceSort,
    aiTaskSort,
    aiTaskFilters,
    addServiceSort,
    serviceHidden,
    addServiceHidden,
    aiTaskActiveTab,
    alterActiveTab,
    fieldGroups,
    fieldGroupFilters,
    fetchAllFieldGroups,
    fieldGroupSort,
    setFieldGroupSort,
    clearServices,
    fieldGroupHidden,
    addFieldGroupHidden,
    getAITasksList,
    dataModels,
    fetchAllDataModels,
    dataModelsFilters,
    dataModelsSort,
    dataModelsHidden,
    addAiTaskSort,
    setDataModelSort,
    addDataModelHidden,
    deleteDataModelStatus,
    deleteAiTaskStatus,
    deleteFieldGroupStatus,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [currentPageService, setCurrentPageService] = useState(1);
  const [currentPageFieldGroup, setCurrentPageFieldGroup] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [servicesRows, setServicesRows] = useState([]);
  const [AiTaskRows, setAiTaskRows] = useState([]);
  const [currentDataModel, setCurrentDataModel] = useState(1);
  const [fieldgroupRows, setFieldgroupRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [isFavouriteClickAITask, setIsFavouriteClickAITask] = useState(false);

  const [currentFavouriteAiTaskPage, setCurrentFavouriteAiTaskPage] = useState(1);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [tableLoadingFieldGroup, setTableLoadingfieldGroup] = useState(true);
  const [tableLoadingDataModel, setTableLoadingDataModel] = useState(true);
  const [currentPageAiTask, setCurrentPageAiTask] = useState(1);
  const [tableData, setTableData] = useState({
    columns: serviceColumns,
    widths: serviceDefaultColumnWidths,
    extentions: serviceTableColumnExtensions,
    orders: serviceColumnOrder,
    hides: [],
  });

  const [AITaskTableData, setAITaskTableData] = useState({
    columns: AITaskColumns,
    widths: AITaskDefaultColumnWidths,
    extentions: AITaskTableColumnExtensions,
    orders: AITaskColumnOrder,
    hides: [],
  });

  const [dataModelRows, setDataModelRows] = useState([]);
  const [dataModelTableData, setDataModelTableData] = useState({
    columns: dataModelColumns,
    widths: dataModelDefaultColumnWidths,
    extentions: dataModelTableColumnExtensions,
    orders: dataModelColumnOrder,
    hide: [],
  });

  const [fieldGrouptableData, setFieldGroupTableData] = useState({
    columns: fieldGroupsColumns,
    widths: fieldGroupsDefaultColumnWidths,
    extentions: fieldGroupsTableColumnExtensions,
    orders: fieldGroupsColumnOrder,
    hides: [],
  });

  const [currentFavouriteAiTasksPage, setCurrentFavouriteAiTasksPage] = useState(1);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (aiTaskActiveTab === "services") {
      checkScreenPermission("Microservice", ["List All", "List Assigned"]);
    } else if (aiTaskActiveTab === "fieldgroups") {
      checkScreenPermission("GlossaryFieldGroup", ["List All", "List Assigned"]);
    } else if (aiTaskActiveTab === "AITask") {
      checkScreenPermission("Microservice", ["List All", "List Assigned"]);
    }

    const fetchAiTaskFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("AITask");
      if (_status === 200) {
        let servData = getTableData(
          data,
          AITaskColumns,
          AITaskDefaultColumnWidths,
          AITaskTableColumnExtensions,
          AITaskColumnOrder,
          AITaskColumnHidden,
          "AITask"
        );
        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        setAITaskTableData(servData);
        if (
          JSON.stringify(serviceHidden) === JSON.stringify(AITaskColumnHidden)
        ) {
          addServiceHidden(servData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchDataModelFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("AIModel");
      if (_status === 200) {
        let dmdata = getTableData(
          data,
          dataModelColumns,
          dataModelDefaultColumnWidths,
          dataModelTableColumnExtensions,
          dataModelColumnOrder,
          dataModelColumnHidden,
          "fieldgroup"
        );

        setDataModelTableData(dmdata);
        if (
          JSON.stringify(dataModelsHidden) ===
          JSON.stringify(dataModelColumnHidden)
        ) {
          addDataModelHidden(dmdata.hides);
        }
        setTableLoadingDataModel(false);
      } else {
        setTableLoadingDataModel(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchFieldGroupFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "GlossaryFieldGroup"
      );
      if (_status === 200) {
        let fieldGData = getTableData(
          data,
          fieldGroupsColumns,
          fieldGroupsDefaultColumnWidths,
          fieldGroupsTableColumnExtensions,
          fieldGroupsColumnOrder,
          fieldGroupHidden,
          "fieldgroup"
        );
        setFieldGroupTableData(fieldGData);
        if (
          JSON.stringify(fieldGroupHidden) ===
          JSON.stringify(fieldGroupsColumnHidden)
        ) {
          addFieldGroupHidden(fieldGData.hides);
        }
        setTableLoadingfieldGroup(false);
      } else {
        setTableLoadingfieldGroup(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchDataModelFields();
    fetchAiTaskFields();
    fetchFieldGroupFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStatusesByResource = async () => {
    const { _msg, _status, data } = await fetchStatusResourceByName("AITask");
    if (_status === 200) {
      setStatuses(data ? data.status : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  function createData(serviceData) {
    const {
      _id,
      name,
      status,
      createdon,
      isFavourite,
      createdby,
      isSubscribe,
    } = serviceData;
    return {
      ...serviceData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      status: status,
      api: 0,
      isFavourite: isFavourite,
      isSubscribe: isSubscribe,
      statuses: statuses,
    };
  }

  useEffect(async () => {
    const parsedFilters = arrayToFilter(serviceFilters);
    const parsedSort = arrayToSort(serviceSort);
    await fetchStatusesByResource();
    getServicesList(
      currentPageService,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageService, serviceSort]);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(aiTaskFilters);
    const parsedSort = arrayToSort(aiTaskSort);
    await fetchStatusesByResource();
    getAITasksList(
      currentPageAiTask,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageAiTask, aiTaskSort]);

  useEffect(async () => {
    if(deleteAiTaskStatus === true ){  
      const parsedFilters = arrayToFilter(aiTaskFilters);
      const parsedSort = arrayToSort(aiTaskSort);
      await fetchStatusesByResource();
      getAITasksList(
        currentPageAiTask,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteAiTaskStatus]);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(dataModelsFilters);
    const parsedSort = arrayToSort(dataModelsSort);
    await fetchStatusesByResource();
    fetchAllDataModels(
      currentDataModel,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentDataModel, dataModelsSort]);

  useEffect(async () => {
    if(deleteDataModelStatus === true){
      const parsedFilters = arrayToFilter(dataModelsFilters);
      const parsedSort = arrayToSort(dataModelsSort);
      await fetchStatusesByResource();
      fetchAllDataModels(
        currentDataModel,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteDataModelStatus]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldGroupFilters);
    const parsedSort = arrayToSort(fieldGroupSort);
    fetchAllFieldGroups(
      currentPageFieldGroup,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageFieldGroup, fieldGroupSort]);

  useEffect(() => {
    if(deleteFieldGroupStatus){  
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(
        currentPageFieldGroup,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteFieldGroupStatus]);

  const styles = listStyles();

  const histroy = useHistory();

  useEffect(() => {
    setAiTaskRows(
      aiTaskList?.data?.data && aiTaskList?.data?.data?.length > 0
        ? aiTaskList?.data?.data?.map((item) => {
          const rowData = createData(item);
          return rowData;
        })
        : []
    );

    setDataModelRows(
      dataModels?.data?.data && dataModels?.data?.data?.length > 0
        ? dataModels?.data?.data?.map((item) => {
          const rowData = createData(item);
          return rowData;
        })
        : []
    );
  }, [aiTaskList.data, dataModels.data]);

  useEffect(() => {
    if (!isFavouriteClick) {
      setServicesRows(
        data?.data && data?.data?.length > 0
          ? data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setServicesRows(
        data?.data && data?.data?.length > 0
          ? data?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    if (!isFavouriteClickAITask) {
      setAiTaskRows(
        aiTaskList?.data?.data && aiTaskList?.data?.data?.length > 0
          ? aiTaskList?.data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
    } else {
      setAiTaskRows(
        aiTaskList?.data?.data && aiTaskList.data?.data?.length > 0
          ? aiTaskList?.data?.data
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [aiTaskList?.data]);

  useEffect(() => {
    setFieldgroupRows(
      fieldGroups?.data?.data && fieldGroups?.data?.data?.length > 0
        ? fieldGroups?.data?.data?.map((item) => ({
          ...item,
          createdon: item.createdon,
          updatedon: item.updatedon,
          updatedby: item.updatedby,
        }))
        : []
    );
  }, [fieldGroups]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (aiTaskActiveTab === "services") {
      if (searchInput) {
        getServicesList(
          1,
          JSON.stringify({
            ai_task: { $ne: true },
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getServicesList(1);
      }
    } else if (aiTaskActiveTab === "fieldgroups") {
      if (searchInput) {
        fetchAllFieldGroups(
          1,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        fetchAllFieldGroups(1);
      }
    } else if (aiTaskActiveTab === "DataModel") {
      if (searchInput) {
        fetchAllDataModels(
          1,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        fetchAllDataModels(1);
      }
    } else if (aiTaskActiveTab === "AITask") {
      if (searchInput) {
        getAITasksList(
          1,
          JSON.stringify({
            ai_task: true,
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        getAITasksList(1);
      }
    }
  };

  const handleSubmitFilter = () => {
    if (aiTaskActiveTab === "services") {
      const parsedFilters = arrayToFilter(serviceFilters);
      const parsedSort = arrayToSort(serviceSort);
      getServicesList(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (aiTaskActiveTab === "fieldgroups") {
      const parsedFilters = arrayToFilter(fieldGroupFilters);
      const parsedSort = arrayToSort(fieldGroupSort);
      fetchAllFieldGroups(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (aiTaskActiveTab === "AITask") {
      const parsedFilters = arrayToFilter([
        ...aiTaskFilters,
        { column: "ai_task", value: true, operator: "eq" },
      ]);
      const parsedSort = arrayToSort(aiTaskSort);
      getAITasksList(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (aiTaskActiveTab === "DataModel") {
      const parsedFilters = arrayToFilter(dataModelsFilters);
      const parsedSort = arrayToSort(dataModelsSort);
      fetchAllDataModels(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFilters = () => {
    if (aiTaskActiveTab === "services") {
      getServicesList(1, JSON.stringify({}));
    } else if (aiTaskActiveTab === "fieldgroups") {
      fetchAllFieldGroups(1, JSON.stringify({}));
    } else if (aiTaskActiveTab === "AITask") {
      getAITasksList(1, JSON.stringify({}));
    } else if (aiTaskActiveTab === "DataModel") {
      fetchAllDataModels(1, JSON.stringify({}));
    }
  };

  const handleToggleFavouriteAITask = () => {
    if (isFavouriteClickAITask) {
      const parsedFilters = arrayToFilter(aiTaskFilters);
      const parsedSort = arrayToSort(aiTaskSort);
      getAITasksList(
        currentPageAiTask,
        parsedFilters,
        JSON.stringify(parsedSort),
        10
      );

      setAiTaskRows(
        aiTaskList?.data?.data && aiTaskList?.data?.data?.length > 0
          ? aiTaskList?.data?.data?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClickAITask(false);
      setCurrentPageAiTask(1)
    } else {
      const parsedFilters = arrayToFilter(aiTaskFilters);
      const parsedSort = arrayToSort(aiTaskSort);
      getAITasksList(undefined, parsedFilters, JSON.stringify(parsedSort));
      setAiTaskRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClickAITask(true);
      setCurrentFavouriteAiTasksPage(1);
    }
  };
  // git push
  const handleChangeTab = (event, newValue) => {
    alterActiveTab("aiTaskActiveTab", newValue);
  };

  useEffect(() => {
    return () => {
      clearServices();
    };
  }, []);

  const activeTabBredCrumbsName = () => {
    if (aiTaskActiveTab === "AITask") {
      return "AI Task List";
    } else if (aiTaskActiveTab === "fieldgroups") {
      return "Payload List";
    } else if (aiTaskActiveTab === "DataModel") {
      return "AI Models List";
    }
  };

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "AI Workbench", path: "/aitask-workbench" },
              { title: activeTabBredCrumbsName() },
            ]}
          />

          <Grid container>
            <Grid item xs={6}>
              <Tabs
                value={aiTaskActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={`AI Tasks ${aiTaskList._totalcount ? ` (${aiTaskList._totalcount})` : ""
                    }`}
                  value={"AITask"}
                />
                <Tab
                  label={`AI Models ${dataModels?.data?._totalcount
                      ? ` (${dataModels?.data?._totalcount})`
                      : ""
                    }`}
                  value={"DataModel"}
                  disabled={!checkCompPermission("AIModel", ["List All"])}
                />
                <Tab
                  label={`Payloads ${fieldGroups?.data?._totalcount
                      ? ` (${fieldGroups?.data?._totalcount})`
                      : ""
                    }`}
                  value={"fieldgroups"}
                  disabled={
                    !checkCompPermission("GlossaryFieldGroup", [
                      "List All",
                      "List Assigned",
                    ])
                  }
                />
              </Tabs>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.topContentItem2}>
                <SearchBar
                  PlaceHolderLabel={
                    aiTaskActiveTab === "AITask"
                      ? "Search AI Task Name"
                      : aiTaskActiveTab === "DataModel"
                        ? "Search AI Model Name"
                        : "Search Name"
                  }
                  handleSearch={handleSearch}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                />
                <Divider orientation="vertical" className={styles.MuiDivider} />
                {aiTaskActiveTab === "services" && (
                  <CustomFilter
                    columns={tableData.columns}
                    filters={serviceFilters}
                    setFilters={addServiceFilters}
                    handleSubmitFilter={handleSubmitFilter}
                    handleClearFilters={handleClearFilters}
                  />
                )}

                {aiTaskActiveTab === "AITask" && (
                  <CustomFilter
                    columns={AITaskTableData.columns}
                    filters={aiTaskFilters}
                    setFilters={addAITaskFilters}
                    handleSubmitFilter={handleSubmitFilter}
                    handleClearFilters={handleClearFilters}
                  />
                )}
                {aiTaskActiveTab === "fieldgroups" && (
                  <CustomFilter
                    columns={fieldGroupsColumns}
                    filters={fieldGroupFilters}
                    setFilters={addFieldGroupFilters}
                    handleSubmitFilter={handleSubmitFilter}
                    handleClearFilters={handleClearFilters}
                  />
                )}
                {aiTaskActiveTab === "DataModel" && (
                  <CustomFilter
                    columns={dataModelTableData.columns}
                    filters={dataModelsFilters}
                    setFilters={addDataModelFilters}
                    handleSubmitFilter={handleSubmitFilter}
                    handleClearFilters={handleClearFilters}
                  />
                )}

                {aiTaskActiveTab === "AITask" && (
                  <>
                    <Divider
                      orientation="vertical"
                      className={styles.MuiDivider}
                    />

                    <Tooltip title="Favourite" arrow>
                      <IconButton
                        color="inherit"
                        aria-label="open cardview"
                        edge="start"
                        style={{ margin: "-10px 10px" }}
                        onClick={handleToggleFavouriteAITask}
                      >
                        {isFavouriteClickAITask ? (
                          <img src="/images/heartFilled.svg" alt="card" />
                        ) : (
                          <img src="/images/heartLine.svg" alt="card" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Divider orientation="vertical" className={styles.MuiDivider} />

                {aiTaskActiveTab === "fieldgroups" && (
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={
                      <AddIcon style={{ width: "16px", height: "16px" }} />
                    }
                    disabled={
                      !checkCompPermission("GlossaryFieldGroup", ["Create"])
                    }
                    className="btnsmall"
                    onClick={() => {
                      histroy.push("/fieldgroups/add");
                    }}
                  />
                )}

                {aiTaskActiveTab === "AITask" && (
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={
                      <AddIcon style={{ width: "16px", height: "16px" }} />
                    }
                    // disabled={!checkCompPermission('Microservice', ['Create'])}
                    className="btnsmall"
                    onClick={() => {
                      histroy.push("/aitask/add");
                    }}
                  />
                )}

                {aiTaskActiveTab === "DataModel" && (
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={
                      <AddIcon style={{ width: "16px", height: "16px" }} />
                    }
                    disabled={!checkCompPermission("AIModel", ["Create"])}
                    className="btnsmall"
                    onClick={() => {
                      histroy.push("/dataModel/add");
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {aiTaskActiveTab === "DataModel" &&
            checkCompPermission("AIModel", ["List All"]) && (
              <>
                {tableLoadingDataModel ? (
                  <Loader />
                ) : (
                  <DataModelListTable
                    columns={dataModelTableData.columns}
                    rows={dataModelRows}
                    defaultColumnWidths={dataModelTableData.widths}
                    tableColumnExtensions={dataModelTableData.extentions}
                    columnOrder={dataModelTableData.orders}
                    defaultSorting={dataModelDefaultSorting}
                    sort={dataModelsSort}
                    setSort={setDataModelSort}
                    isCustomHeight={true}
                    hidden={dataModelsHidden}
                    setHidden={addDataModelHidden}
                    noDataMsg="No Data Model Found"
                  />
                )}
              </>
            )}

          {aiTaskActiveTab === "AITask" && (
            <>
              {tableLoading ? (
                <Loader />
              ) : (
                <DevExpressTable
                  columns={AITaskTableData.columns}
                  rows={isFavouriteClickAITask ? (
                    AiTaskRows?.length > 0
                      ? AiTaskRows?.slice(
                        (currentFavouriteAiTasksPage - 1) * 10,
                        currentFavouriteAiTasksPage * 10
                      )
                      : []
                  ) : AiTaskRows}
                  defaultColumnWidths={AITaskTableData.widths}
                  tableColumnExtensions={AITaskTableData.extentions}
                  columnOrder={AITaskTableData.orders}
                  loading={loading}
                  currentPage={isFavouriteClickAITask ? currentFavouriteAiTasksPage : currentPageService}
                  setCurrentPage={setCurrentPageService}
                  isCustomHeight={true}
                  sort={aiTaskSort}
                  windowDimensions={windowDimensions}
                  setSort={addAiTaskSort}
                  hidden={serviceHidden}
                  setHidden={addServiceHidden}
                  noDataMsg="No AI Task Found"
                />
              )}
            </>
          )}

          {aiTaskActiveTab === "fieldgroups" && (
            <>
              {tableLoadingFieldGroup ? (
                <Loader />
              ) : (
                <ApplicationListTable
                  columns={fieldGrouptableData.columns}
                  rows={fieldgroupRows}
                  defaultColumnWidths={fieldGrouptableData.widths}
                  tableColumnExtensions={fieldGrouptableData.extentions}
                  columnOrder={fieldGrouptableData.orders}
                  defaultSorting={fieldGroupsDefaultSorting}
                  sort={fieldGroupSort}
                  setSort={setFieldGroupSort}
                  isCustomHeight={true}
                  hidden={fieldGroupHidden}
                  setHidden={addFieldGroupHidden}
                />
              )}
            </>
          )}
        </Paper>

        {AiTaskRows.length !== 0 && aiTaskActiveTab === "AITask" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {isFavouriteClickAITask ?
                <Pagination
                  count={Math.ceil(AiTaskRows.length / 10)}
                  page={currentFavouriteAiTasksPage}
                  onChange={(e, val) => setCurrentFavouriteAiTasksPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
                :
                <Pagination
                  count={Math.ceil(aiTaskList._totalcount / 10)}
                  page={currentPageAiTask}
                  onChange={(e, val) => setCurrentPageAiTask(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              }
            </div>
          </div>
        )}

        {fieldgroupRows.length !== 0 && aiTaskActiveTab === "fieldgroups" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={
                  isFavouriteClick
                    ? Math.ceil(fieldgroupRows.length / 10)
                    : Math.ceil(fieldGroups?.data?._totalcount / 10)
                }
                page={currentPageFieldGroup}
                onChange={(e, val) => setCurrentPageFieldGroup(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}

        {dataModelRows.length !== 0 && aiTaskActiveTab === "DataModel" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={
                  isFavouriteClick
                    ? Math.ceil(dataModelRows.length / 10)
                    : Math.ceil(dataModels?.data?._totalcount / 10)
                }
                page={currentDataModel}
                onChange={(e, val) => setCurrentDataModel(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  getServicesList,
  addServiceFilters,
  addServiceSort,
  alterActiveTab,
  addServiceHidden,
  addFieldGroupFilters,
  addDataModelFilters,
  fetchAllFieldGroups,
  addFieldGroupHidden,
  setFieldGroupSort,
  clearServices,
  getAITasksList,
  fetchAllDataModels,
  addAiTaskSort,
  setDataModelSort,
  addAITaskFilters,
  addDataModelHidden,
};

const mapStateToProps = (state) => {
  const { servicesList, serviceFilters, serviceSort, serviceHidden } =
    state.serviceReducer;

  const { aiTaskList, aiTaskFilters, aiTaskSort } = state.aiTaskReducer;

  const { userInfo } = state.userDetails;
  const { data, error, loading } = servicesList;
  const { aiTaskActiveTab } = state.activeTabReducer;
  const { fieldGroups, fieldGroupFilters, fieldGroupSort, fieldGroupHidden } =
    state.dataStandardReducer;
  const { dataModels, dataModelsFilters, dataModelsSort, dataModelsHidden } =
    state.dataModelReducer;
  const { deleteDataModelStatus } = dataModels;
  const { deleteAiTaskStatus } = aiTaskList;
  const { deleteFieldGroupStatus } = fieldGroups;

  return {
    aiTaskList,
    data,
    error,
    loading,
    serviceFilters,
    serviceSort,
    aiTaskSort,
    aiTaskFilters,
    _totalcount: servicesList?._totalcount,
    userInfo,
    serviceHidden,
    aiTaskActiveTab,
    fieldGroups,
    fieldGroupFilters,
    fieldGroupSort,
    fieldGroupHidden,
    dataModels,
    dataModelsFilters,
    dataModelsSort,
    dataModelsHidden,
    deleteDataModelStatus,
    deleteAiTaskStatus,
    deleteFieldGroupStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AiWorkbenchList);
