import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";

const CreateBoilerplateEntity = ({
  section,
  BoilerPlateData,
  setBoilerPlateData,
  error,
  handleChange,
  handleUpload,
  customdropzoneImage,
  handleDeleteClick,
  uploadTab,
  setUploadTab,
  handleUploadSourceTab,
  droppedDocument,
  setDroppedDocument,
  handleImageNameChange,
  imageDetails,
  setError,
  launguageList,
  envs,
  selectedLanguage,
  setSelectedLanguage,
  handleAddEnv,
  handleEnvChange,
  handleDeleteEnv,
  setIsLoader,
  tabbedView,
}) => {
  const styles = manageStyles();
  const [language, setLanguage] = useState("");

  const deleteBuildCommand = (index) => {
    if(BoilerPlateData?.buildcommands?.length > 1 ){
      setBoilerPlateData({
        ...BoilerPlateData,
        buildcommands: [
          ...BoilerPlateData.buildcommands.slice(0,index),
          ...BoilerPlateData.buildcommands.slice(index + 1, BoilerPlateData.buildcommands.length),
        ],
      })
    }
  }
  
  const UploadBoilerPlateFile = (field) => {
    return (
      <div style={tabbedView ? { padding: "15px", paddingTop: "0px" } : {}}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <RadioGroup
              value={uploadTab}
              onChange={handleUploadSourceTab}
              indicatorColor="primary"
              textColor="primary"
              color="primary"
              className={styles.documentTab}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="Upload"
                control={<Radio color="primary" />}
                label="Upload"
              />
              {/* {field?.required === "TRUE" && uploadTab === "Upload" ? (
                <span style={{ marginRight: 5 }} className={styles.red}>
                  *
                </span>
              ) : (
                <></>
              )} */}
              <FormControlLabel
                value="URL"
                control={<Radio color="primary" />}
                label="URL"
              />
              {/* {field?.required === "TRUE" && uploadTab === "URL" ? (
                <span className={styles.red}>*</span>
              ) : (
                <></>
              )} */}
            </RadioGroup>

            {uploadTab === "Upload" && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div
                    className={`${styles.documentDropperWrapper2} ${
                      error["docker"]?.isError &&
                      droppedDocument.length === 0 &&
                      styles.ErrordocumentDropperWrapper2
                    }`}
                  >
                    <DropzoneArea
                      name="droppedDocument"
                      className={styles.documentDropperClass}
                      showPreviews={false}
                      filesLimit={1}
                      acceptedFiles={[".zip"]}
                      dropzoneText="Drag and drop or click to browse here"
                      dropzoneClass="documentDropperClass"
                      dropzoneParagraphClass="documentDropperTextClass"
                      get
                      maxFileSize={9000000000}
                      showAlerts={["error"]}
                      showPreviewsInDropzone={false}
                      onDrop={() => setIsLoader(true)}
                      onChange={(files) => {
                        setIsLoader(false);
                        handleUpload(files);
                      }}
                    />
                    <p
                      className={styles.fieldHeading}
                    >{`Allowed type ".zip" file only`}</p>
                  </div>

                  {error["docker"]?.isError && droppedDocument.length === 0 && (
                    <p className={styles.errorMsg}>{"File is required"}</p>
                  )}

                  {droppedDocument.length > 0 && (
                    <div className={styles.doc_container}>
                      <div className={styles.group1}>
                        <text className={styles.label}>File Name</text>
                        <input
                          className={styles.value}
                          value={imageDetails["imagename"]}
                          placeholder="Enter Image Name"
                          onChange={handleImageNameChange}
                        ></input>
                      </div>
                      <span
                        style={{
                          marginLeft: 23,
                          marginTop: 25,
                          color: "black",
                        }}
                        onClick={handleDeleteClick}
                      >
                        <img
                          src="/images/deleteLine.svg"
                          height={18}
                          width={18}
                          color="#000000"
                          alt="icon"
                        />
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {uploadTab === "URL" && (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Url <span className={styles.red}>*</span>
                    </label>
                    <input
                      type="text"
                      name="Url"
                      className={`${styles.input} ${
                        error["Url"]?.isError && styles.error
                      }`}
                      value={BoilerPlateData.Url ? BoilerPlateData.Url : ""}
                      // onChange={(e) => {
                      //   setBoilerPlateData((prevState) => (
                      //     {
                      //       ...prevState,
                      //       Url: e.target.value
                      //     }));
                      //   if (BoilerPlateData?.Url?.trim() !== "") {
                      //     setError({ ...error, Url: { ...Url, isError: false } })
                      //   }

                      // }}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {error["Url"]?.isError && (
                      <p className={styles.errorMsg}>{"Url is required"}</p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Branch
                    </label>
                    <input
                      type="text"
                      name="branch"
                      className={`${styles.input}`}
                      value={
                        BoilerPlateData.branch ? BoilerPlateData.branch : ""
                      }
                      onChange={(e) =>
                        setBoilerPlateData({
                          ...BoilerPlateData,
                          branch: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Repo Type
                    </label>
                    <div>
                      <FormControlLabel
                        value={BoilerPlateData.isPublic}
                        control={
                          <Switch
                            checked={BoilerPlateData.isPublic}
                            onChange={(event) => {
                              setBoilerPlateData({
                                ...BoilerPlateData,
                                isPublic: !BoilerPlateData.isPublic,
                              });
                              if (event.target.checked) {
                                let tempError = { ...error };
                                delete tempError["token"];
                                setError(tempError);
                              } else {
                                setError({
                                  ...error,
                                  token: {
                                    dataType: "string",
                                    minimum: 1,
                                    minLength: 1,
                                    isError: false,
                                    section: 1,
                                  },
                                });
                              }
                            }}
                            color="primary"
                          />
                        }
                        style={{ marginLeft: 0 }}
                        label="Private"
                        labelPlacement="start"
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          font: "normal normal 400 1rem Nunito",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBoilerPlateData({
                            ...BoilerPlateData,
                            isPublic: !BoilerPlateData.isPublic,
                          });
                          if (BoilerPlateData.isPublic) {
                            setError({ ...error, token: false });
                          } else {
                            const tempError = error;
                            delete tempError["token"];
                            setError(tempError);
                          }
                        }}
                      >
                        Public
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="" className={styles.fieldHeading}>
                      Token{" "}
                      {!BoilerPlateData.isPublic && (
                        <span className={styles.red}>*</span>
                      )}
                    </label>
                    <input
                      type="text"
                      name="token"
                      className={`${styles.input} ${
                        error["token"]?.isError && styles.error
                      }`}
                      value={BoilerPlateData.token ? BoilerPlateData.token : ""}
                      onChange={(e) => {
                        setBoilerPlateData({
                          ...BoilerPlateData,
                          token: e.target.value,
                        });
                        setError({
                          ...error,
                          token: { ...error["token"], isError: false },
                        });
                      }}
                      autoComplete="off"
                    />
                    {error["token"]?.isError && (
                      <p className={styles.errorMsg}>{"Token is required"}</p>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const BuildCommand = () => {
    return (
      <>
        <Grid
          container
          spacing={3}
          style={
            tabbedView
              ? { marginLeft: "5px", marginTop: "6px" }
              : { }
          }
        >
          <Grid item xs={6} sm={6} style={{marginTop: 6}}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <label
                htmlFor="buildCmd"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Build Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                onClick={() =>
                  setBoilerPlateData({
                    ...BoilerPlateData,
                    buildcommands: [...BoilerPlateData.buildcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {BoilerPlateData?.buildcommands?.map((buildcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    key={index}
                    name={`${index}_build`}
                    className={styles.input}
                    value={BoilerPlateData?.buildcommands[index]}
                    onChange={(e) =>
                      setBoilerPlateData({
                        ...BoilerPlateData,
                        buildcommand: [
                          ...BoilerPlateData.buildcommands,
                          (BoilerPlateData.buildcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    onClick={() => deleteBuildCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} sm={6} style={{marginTop: 6}}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="runCommand"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Run Commands
              </label>
              {/* <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() =>
                            setlanguageData({
                                ...languageData,
                                runcommands: [...languageData.runcommands, ""],
                            })
                        }
                        className={styles.actionIcon2}
                    >
                        <AddIcon />
                    </IconButton> */}
              <br />
              <br />
            </Grid>
            <Grid item xs={11} sm={11}>
              <input
                type="text"
                name="dockerruncommands"
                className={styles.input}
                value={BoilerPlateData.runcommands}
                onChange={(e) =>
                  setBoilerPlateData({
                    ...BoilerPlateData,
                    runcommands: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  // const BuildCommand = () => {
  //   return (
  //     <Grid
  //       container
  //       spacing={3}
  //       style={
  //         tabbedView
  //           ? { marginTop: "0px", marginLeft: "5px" }
  //           : { marginTop: "10px" }
  //       }
  //     > 
  //       <Grid container spacing={2}>
  //         <Grid item xs={5}>
  //           {/* <label className={styles.fieldHeading2}>Build Command</label> */}
  //           <input
  //             name="dockerbuildcommands"
  //             placeholder="Build Command"
  //             onChange={handleBuildCmdChange}
  //             className={styles.textarea}
  //             rows="4"
  //           ></input>
  //         </Grid>
  //         <Grid item xs={1} sm={1}>
  //             <IconButton
  //                   color="inherit"
  //                   aria-label="edit"
  //                   edge="start"
  //                   onClick={() =>
  //                     setBoilerPlaData({
  //                       ...languageData,
  //                       buildcommands: [...BoilerplateData.buildcommands, ""],
  //                     })
  //                   }
  //                   className={styles.actionIcon2}
  //                 >
  //                   <AddIcon />
  //             </IconButton>
  //             <IconButton
  //               color="inherit"
  //               aria-label="edit"
  //               edge="start"
  //               onClick={() => deleteBuildCommand(index)}
  //               className={styles.actionIcon2}
  //             >
  //               <img src="/images/deleteLine.svg" alt="card" />
  //             </IconButton>
  //         </Grid>
  //       </Grid>
  //       <Grid container spacing={2}>
  //         <Grid item xs={5}>
  //           {/* <label className={styles.fieldHeading2}>Build Command</label> */}
  //           <input
  //             name="dockerbuildcommands"
  //             placeholder="Build Command"
  //             onChange={handleBuildCmdChange}
  //             className={styles.textarea}
  //             rows="4"
  //           ></input>
  //         </Grid>
  //         <Grid item xs={1} sm={1}>
  //             <IconButton
  //                   color="inherit"
  //                   aria-label="edit"
  //                   edge="start"
  //                   onClick={() =>
  //                     setBoilerPlaData({
  //                       ...languageData,
  //                       buildcommands: [...BoilerplateData.buildcommands, ""],
  //                     })
  //                   }
  //                   className={styles.actionIcon2}
  //                 >
  //                   <AddIcon />
  //             </IconButton>
  //             <IconButton
  //               color="inherit"
  //               aria-label="edit"
  //               edge="start"
  //               onClick={() => deleteBuildCommand(index)}
  //               className={styles.actionIcon2}
  //             >
  //               <img src="/images/deleteLine.svg" alt="card" />
  //             </IconButton>
  //         </Grid>
  //         <Grid item xs={5}>
  //           {/* <label className={styles.fieldHeading2}>Build Command</label> */}
  //           <input
  //             name="dockerbuildcommands"
  //             placeholder="Build Command"
  //             onChange={handleBuildCmdChange}
  //             className={styles.textarea}
  //             rows="4"
  //           ></input>
  //         </Grid>
  //         <Grid item xs={1} sm={1}>
  //             <IconButton
  //                   color="inherit"
  //                   aria-label="edit"
  //                   edge="start"
  //                   onClick={() =>
  //                     setBoilerPlaData({
  //                       ...languageData,
  //                       buildcommands: [...BoilerplateData.buildcommands, ""],
  //                     })
  //                   }
  //                   className={styles.actionIcon2}
  //                 >
  //                   <AddIcon />
  //             </IconButton>
  //             <IconButton
  //               color="inherit"
  //               aria-label="edit"
  //               edge="start"
  //               onClick={() => deleteBuildCommand(index)}
  //               className={styles.actionIcon2}
  //             >
  //               <img src="/images/deleteLine.svg" alt="card" />
  //             </IconButton>
  //         </Grid>
  //       </Grid>
  //       <Grid item xs={5}>
  //         {/* <label className={styles.fieldHeading2}>Run Command</label> */}
  //         <input
  //           name="dockerruncommands"
  //           placeholder="Run Command"
  //           onChange={handleChange}
  //           className={styles.textarea}
  //           rows="4"
  //           autoComplete="off"
  //         ></input>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const RunCommand = () => {
    return (
      <Grid
        container
        spacing={3}
        style={
          tabbedView
            ? { marginTop: "10px", marginLeft: "7px" }
            : { marginTop: "10px" }
        }
      >
        <Grid item xs={6}>
          {/* <label className={styles.fieldHeading2}>Run Command</label> */}
          <textarea
            name="dockerruncommands"
            placeholder="Run Command"
            onChange={handleChange}
            className={styles.textarea}
            rows="4"
            autoComplete="off"
          ></textarea>
        </Grid>
      </Grid>
    );
  };
  const EnvironmentVariable = () => {
    return (
      <Grid
        container
        spacing={3}
        style={
          tabbedView
            ? { padding: "10px", marginBottom: "80px" }
            : { marginBottom: "80px" }
        }
      >
        <Grid item xs={12} sm={12}>
          <TableContainer>
            <Table
              className={styles.customTable}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                  <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {envs.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{ border: "0.5px solid #D5D5D5" }}
                  >
                    <TableCell scope="row">
                      <input
                        type="text"
                        placeholder="Enter key"
                        name={row.id}
                        className={styles.input}
                        value={envs[index].key}
                        onChange={(e) => handleEnvChange(e, row.id, "key")}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Value"
                        name={row.id}
                        className={styles.input}
                        value={envs[index].value}
                        onChange={(e) => handleEnvChange(e, row.id, "value")}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        onClick={() => handleDeleteEnv(row.id)}
                        className={styles.actionIcon}
                        style={{marginTop: "-10px"}}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={handleAddEnv}
                        className={styles.actionIcon}
                        style={{marginTop: "-10px"}}
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div
            style={{
              backgroundColor: "#fff",
              padding: "5px 0",
              borderLeft: "1px solid #E2E2E3",
              borderRight: "1px solid #E2E2E3",
              borderBottom: "1px solid #E2E2E3",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              onClick={handleAddEnv}
              className={styles.actionIcon}
            >
              <AddIcon />
            </IconButton>
          </div> */}
        </Grid>
      </Grid>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${
                error[field.datakey]?.isError && styles.error
              } `}
              value={BoilerPlateData[field?.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={BoilerPlateData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea}  ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        if (field.fieldLabel === "Language") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Language <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                name="language"
                options={launguageList}
                getOptionLabel={(option) => option.name}
                className={`${styles.searchInput3}`}
                value={selectedLanguage}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedLanguage(input);
                    setBoilerPlateData({
                      ...BoilerPlateData,
                      language: input.name,
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </div>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={
                  BoilerPlateData[field.datakey]
                    ? BoilerPlateData[field.datakey]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                } `}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </>
          );
        }
      case "DropDown":
        if (field.fieldLabel === "Language") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Language
              </label>
              <Autocomplete
                name={field.datakey}
                value={selectedLanguage}
                options={launguageList}
                getOptionLabel={(option) => option.name}
                className={`${styles.searchInput3}`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedLanguage(input);
                    setBoilerPlateData({
                      ...BoilerPlateData,
                      launguage: input,
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </div>
          );
        } else return;
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                BoilerPlateData[field.datakey]
                  ? BoilerPlateData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={field.editable === "FALSE"}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === "FALSE" && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  BoilerPlateData[`${field.datakey}_unit`]
                    ? BoilerPlateData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={BoilerPlateData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  BoilerPlateData[`${field.datakey}_unit`]
                    ? BoilerPlateData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={BoilerPlateData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                BoilerPlateData[field.datakey]
                  ? BoilerPlateData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "BoilerPlate_Header_section" && index !== 0 && (
                <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>
              )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Boilerplate Docker":
                          return UploadBoilerPlateFile(field);
                        case "boilerplate_buildcommands":
                          return BuildCommand();
                        // case "boilerplate_runcommands":
                        //   return RunCommand();
                        case "Boilerplate EnvironmentVariable":
                          return EnvironmentVariable();

                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView ? { paddingTop: "0px" } : { marginTop: "0rem" }
                  }
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Boilerplate Docker":
                                return UploadBoilerPlateFile(field);
                              case "boilerplate_buildcommands":
                                return BuildCommand();
                              // case "boilerplate_runcommands":
                              //   return RunCommand();
                              case "Boilerplate EnvironmentVariable":
                                return EnvironmentVariable();

                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;

  return {
    tabbedView,
  };
};

export default connect(mapStateToProps)(CreateBoilerplateEntity);
