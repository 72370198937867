import {
  ISSUE_DETAILS_FAIL,
  ISSUE_DETAILS_REQUEST,
  ISSUE_DETAILS_SUCCESS,
  ISSUE_FILTERS,
  ISSUE_SORT,
  ISSUE_HIDDEN
} from "../../constants/UserProfile/helpDesk";
import { v4 as uuidv4 } from "uuid";
// for getting all the issues
const initialState = {
  issueFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  issueSort: [{ columnName: "createdon", direction: "desc" }],
  issues: [],
  issueHidden:["issueid", "category", "createdon", "status", "action"]
};
export const issueAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case ISSUE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ISSUE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        issues: action.payload,
      };
    case ISSUE_FILTERS: {
      return {
        ...state,
        issueFilters: [...action.payload],
      };
    }
    case ISSUE_SORT: {
      return {
        ...state,
        issueSort: [...action.payload],
      };
    }
    case ISSUE_HIDDEN: {
      return {
          ...state,
          issueHidden: [
              ...action.payload
          ]
      }
  }
    case ISSUE_DETAILS_FAIL:
      return {...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
