const processColumns = [
  // { name: "templateid", title: "Template ID" },
  { name: "templatename", title: "Aggregated API Name", for: "process_list" },
  { name: "method", title: "Method", for: "process_list" },
  { name: "endpoint", title: "Endpoint", for: "process_list" },
  // { name: "type", title: "Type", for: "process_list" },
  { name: "action", title: "Action", for: "process_list" }
];

const processDefaultColumnWidth = [
  // { columnName: "templateid", width: 350 },
  { columnName: "templatename", width: 200 },
  { columnName: "method", width: 200 },
  { columnName: "endpoint", width: 200 },
  // { columnName: "type", width: 200 },
  { columnName: "action", width: 200 }
];

const processTableColumnExtensions = [
  // { columnName: "templateid", align: "left" },
  { columnName: "templatename", align: "left" },
  { columnName: "method", align: "left" },
  { columnName: "endpoint", align: "left" },
  // { columnName: "type", align: "left" },
  { columnName: "action", align: "center" }
];

const processColumnOrder = [
  // "templateid",
  "templatename",
  // "type",
  "method",
  "endpoint",
  "action"
];

const processDefaultSorting = [
  { columnName: "templatename", direction: "asc" },
];

export {
  processColumns,
  processDefaultColumnWidth,
  processTableColumnExtensions,
  processColumnOrder,
  processDefaultSorting,
};
