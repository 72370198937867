import { useEffect, useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import TemplateDetailsEntity from "../../../components/common/entitySchema/applicationGovernance/chassisTemplate/TemplateDetailsEntity";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { fetchChassisTemplateDetailsByID } from "../../../utils/applicationGovernance/chassisTemplateUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const CreateBoilerPlateScreen = (props) => {
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [BoilerPlateData, setBoilerPlateData] = useState({});
  const [error, setError] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [uploadTab, setUploadTab] = useState("Upload");
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [templateID] = useState(props.match.params._id);
  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };
  const [imageDetails] = useState({
    imagename: "",
  });

  // Entity
  const [BoilerPlateEntitySchema, setBoilerPlateEntitySchema] = useState({});

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/application-governance");
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Template", ["Read"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchBoilerPlateEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Template",
        "read"
      );
      if (_status === 200) {
        setBoilerPlateEntitySchema(data);
        fetchData();
        const bPlateData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (bPlateData[field.datakey] = "");
              }
            });
          });
        });

        setBoilerPlateData({ ...BoilerPlateData, ...bPlateData });
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                return (errData[field.datakey] = {
                  isError: false,
                  fieldLabel: field.fieldLabel,
                  minLength: field.rules?.minLength,
                  maxLength: field.rules?.maxLength,
                  pattern: field.rules?.pattern,
                  msg: "",
                });
              }
            });
          });
        });

        setError(errData);
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchData = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchChassisTemplateDetailsByID(
        templateID
      );
      if (_status === 200) {
        setBoilerPlateData(() => (data ? data : []));
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchBoilerPlateEntitySchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Application Governance",
                path: "/application-governance",
              },
              {
                title: "Chassis Template",
                path: "/application-governance",
              },
              {
                title: `${
                  BoilerPlateData?.ChassisTemplate
                    ? BoilerPlateData?.ChassisTemplate
                    : ""
                }`,
              },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>
              {BoilerPlateData.ChassisTemplate}
            </h2>
            {tabbedView ? (
              <TabbedNavigation
                isEditMode={false}
                activeTab={activeTab}
                handleEdit={() =>
                  histroy.push(`/edit-chassistemplate/${templateID}`)
                }
                handleCancel={handleCancel}
                isClose={true}
              />
            ) : (
              <AccordionNavigation
                isEditMode={true}
                handleEdit={() =>
                  histroy.push(`/edit-chassistemplate/${templateID}`)
                }
                disabled={!checkCompPermission("Template", ["Edit"])}
                isClose={true}
                handleCancel={handleCancel}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={
                  BoilerPlateEntitySchema
                    ? BoilerPlateEntitySchema.sections
                    : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <TemplateDetailsEntity
                    section={BoilerPlateEntitySchema?.sections?.[activeTab]}
                    BoilerPlateData={BoilerPlateData}
                    setBoilerPlateData={setBoilerPlateData}
                    error={error}
                    setError={setError}
                    customdropzoneImage={customdropzoneImage}
                    // latest

                    uploadTab={uploadTab}
                    setUploadTab={setUploadTab}
                    droppedDocument={droppedDocument}
                    setDroppedDocument={setDroppedDocument}
                    imageDetails={imageDetails}
                  />
                </div>
              </Paper>
            </>
          ) : (
            BoilerPlateEntitySchema?.sections &&
            BoilerPlateEntitySchema?.sections.map((section) => {
              if (section.name === "Template_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <TemplateDetailsEntity
                      section={section}
                      BoilerPlateData={BoilerPlateData}
                      setBoilerPlateData={setBoilerPlateData}
                      error={error}
                      setError={setError}
                      customdropzoneImage={customdropzoneImage}
                      // latest

                      uploadTab={uploadTab}
                      setUploadTab={setUploadTab}
                      droppedDocument={droppedDocument}
                      setDroppedDocument={setDroppedDocument}
                      imageDetails={imageDetails}
                    />
                  </div>
                );
              } else {
                return (
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TemplateDetailsEntity
                        section={section}
                        BoilerPlateData={BoilerPlateData}
                        error={error}
                        customdropzoneImage={customdropzoneImage}
                        // latest
                        uploadTab={uploadTab}
                        setUploadTab={setUploadTab}
                        droppedDocument={droppedDocument}
                        setDroppedDocument={setDroppedDocument}
                        imageDetails={imageDetails}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBoilerPlateScreen);
