import React, { useEffect, useState } from "react";
import listStyles from "../CommonStyles/listScreenStyles";
import { connect } from "react-redux";
import NewDevExpressTable from "../../components/common/Tables/ClusterRelatedTable";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  clusterDeploymentColumns,
  clusterDeploymentDefaultColumnWidths,
  clusterDeploymentTableColumnExtensions,
  clusterDeploymentColumnOrder,
  clusterDeploymentDefaultSorting,
  clusterDeploymentColumnHidden,
} from "../../constants/clusterDeploymentConstants";
import {
  addClusterDeploymentSort,
  addClusterDeploymentHidden,
  fetchAllClusterDeployment,
  fetchAllDeploymentClusters,
} from "../../redux/actions/clusterActions";
import {
  getWindowDimensions,
  getTableData,
  arrayToFilter,
  arrayToSort,
} from "../../utils/common";
import { Pagination } from "@material-ui/lab";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import { fetchClusterDeploymentDetails } from "../../utils/clusterutils/clusterUtils";
import { add_cluster_deployment_list } from "../../redux/actions/clusterActions";

const ClusterDeploymentDevExpTable = (props) => {
  const {
    clusterDeploymentList,
    clusterDeploymentSort,
    clusterDeploymentHidden,
    fetchAllClusterDeployment,
    addClusterDeploymentHidden,
    addClusterDeploymentSort,
    data,
    loading,
    error,
    fetchAllDeploymentClusters,
    clusterName,
    configName,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [statuses, setStatuses] = useState([]);
  const [clusterDeploymentRows, setClusterDeploymentRows] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [currentPageClusterDeployment, setCurrentPageClusterDeployment] =
    useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalCountdeployment, setTotalCount] = useState();
  const [currentPageDeployment, setCurrentPageDeployment] = useState(1);
  const [tableData, setTableData] = useState({
    columns: clusterDeploymentColumns,
    widths: clusterDeploymentDefaultColumnWidths,
    extentions: clusterDeploymentTableColumnExtensions,
    orders: clusterDeploymentColumnOrder,
    hides: [],
  });
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const styles = listStyles();
  //   const clusterName = props.match.params.clusterName

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getClusterDeployment = async () => {
      const { _msg, _status, data, _totalcount } =
        await fetchClusterDeploymentDetails(
          configName,
          clusterName,
          currentPageDeployment
        );
      setTotalCount(_totalcount);
      if (_status === 200) {
        let metaData = [];
        data?.map((item) => {
          const readReplicas = item?.status?.readyReplicas
            ? item?.status?.readyReplicas
            : "0";
          const replicas = item?.status?.replicas;
          item.metadata[
            "pods"
          ] = `${readReplicas?.toString()}/${replicas?.toString()}`;
          item.metadata["labels"] = item?.metadata?.labels;
          item.metadata["deploymentname"] = item?.deploymentname;
          item.metadata["creationTimestamp"] = moment(
            item?.metadata?.creationTimestamp
          ).fromNow();
          item.metadata["image"] =
            item?.spec?.template?.spec?.containers[0]?.image;
          metaData.push(item.metadata);
        });

        setClusterDeploymentRows(metaData);

        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    getClusterDeployment();
  }, [currentPageDeployment]);

  useEffect(() => {
    setMetaData(data[0]?.metadata);
  });

  useEffect(() => {
    // setClusterDeploymentRows(data?.length > 0 && data ? data?.metadata?.map((item)=>item) : [])
  }, [data]);

  return (
    <main>
      <div style={{margin:"0px -16px"}}>
        <NewDevExpressTable
          columns={clusterDeploymentColumns}
          rows={clusterDeploymentRows}
          windowDimensions={windowDimensions}
          defaultColumnWidths={clusterDeploymentDefaultColumnWidths}
          tableColumnExtensions={tableData.extentions}
          columnOrder={tableData.orders}
          hidden={clusterDeploymentHidden}
          setHidden={addClusterDeploymentHidden}
          noDataMsg="No Deployments Found"
        />
        {totalCountdeployment > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(totalCountdeployment / 10)}
                page={currentPageDeployment}
                onChange={(e, val) => setCurrentPageDeployment(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllClusterDeployment,
  addClusterDeploymentHidden,
  addClusterDeploymentSort,
  fetchAllDeploymentClusters,
};

const mapStateToProps = (state) => {
  const {
    clusterDeploymentList,
    clusterDeploymentSort,
    clusterDeploymentHidden,
  } = state.clusterReducer;
  const { data, loading, error } = clusterDeploymentList;

  return {
    // _totalcount:clusterDeploymentList?._totalcount,
    clusterDeploymentList,
    clusterDeploymentSort,
    clusterDeploymentHidden,
    loading,
    error,
    data,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterDeploymentDevExpTable);
