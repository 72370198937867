/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IconButton, Backdrop, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/common/Button/CustomButton";
import useStyles from "./commonStyles";

import { verifyCodeForgetPassword } from "../../utils/authentication/authenticationUtils";
import { useEffect } from "react";

export default function ResetPassword(props) {
    const [dataObj, setDataObj] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        otp: ""
    });
    const [errorObj, setErrorObj] = useState({
        email: false,
        password: false,
        confirmPassword: false,
        otp: false
    });
    const [loading, setLoading] = useState(false);
    const styles = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    useEffect(() => {
        setDataObj((prevState) => ({
            ...prevState,
            email: location?.state?.email,
        }))
    }, []);


    const handleResetPassword = async () => {
        var errorFlag = false;
        var tempError = {};
        for (var obj in dataObj) {
            if (dataObj[obj] === "") {
                errorFlag = true;
                tempError = { ...tempError, [obj]: true };
            }
        }
        if (dataObj.password !== dataObj.confirmPassword) {
            errorFlag = true;
            setErrorObj((prevState) => ({ ...prevState, confirmPassword: true }));
        }
        setErrorObj((prevState) => ({
            ...prevState,
            ...tempError,
        }));
        if (!errorFlag) {
            setLoading(true);
            const req_data = {
                email: dataObj.email,
                code: dataObj.otp,
                newpassword: dataObj.password,
            };
            const { _msg, _status } = await verifyCodeForgetPassword(req_data);
            if (_status === 200) {
                setLoading(false);
                enqueueSnackbar(_msg, { variant: "success" });
                history.push("/");
            } else {
                setLoading(false);
                enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
            }
        }
    };

    const handleTextChange = (key, value) => {
        setErrorObj((prevState) => ({
            ...prevState,
            [key]: false,
        }));
        setDataObj((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    return (
        <div className={styles.container} style={{marginBottom : '2rem'}}>
            <Backdrop
                className={styles.backdrop}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.line1}>
                <IconButton onClick={() => history.push("/")}>
                    <ArrowBackIosIcon fontSize="small" />
                    <text className={styles.label}>Back to Login</text>
                </IconButton>
            </div>
            <div className={styles.welcome_message} style={{ marginTop: "1rem" }}>
                <text className={styles.big_text}>Reset your password</text>
            </div>
            <div className={styles.email}>
                <label className={styles.label}>
                    Email ID<text className={styles.star}> *</text>
                </label>
                <input
                    id="username"
                    type="email"
                    className={styles.email_text}
                    style={errorObj.email ? { borderColor: "red" } : {}}
                    value={dataObj.email}
                    onChange={(e) => handleTextChange("email", e.target.value)}
                    disabled={true}
                ></input>
                {errorObj.email && (
                    <text className={styles.error_text}>Invalid Email</text>
                )}
            </div>
           
            <text
                className={styles.big_text}
                style={{ marginTop: 35, alignSelf: "center", marginRight: "19rem" }}
            >
                Set your password
            </text>
            <div className={styles.password}>
                <label className={styles.label}>
                    Password <text className={styles.star}> *</text>
                </label>
                <input
                    className={styles.password_text}
                    style={errorObj.password ? { borderColor: "red" } : {}}
                    value={dataObj.password}
                    onChange={(e) => handleTextChange("password", e.target.value)}
                    type="password"
                    id="new-password"
                    autoComplete="new-password"
                />
                {errorObj.password && (
                    <text className={styles.error_text}>Invalid Password</text>
                )}
            </div>
            <div className={styles.password}>
                <label className={styles.label}>
                    Confirm Password <text className={styles.star}> *</text>
                </label>
                <input
                    className={styles.password_text}
                    style={errorObj.confirmPassword ? { borderColor: "red" } : {}}
                    value={dataObj.confirmPassword}
                    onChange={(e) =>
                        handleTextChange("confirmPassword", e.target.value)
                    }
                    type="password"
                    id="confirm-new-password"
                    autocomplete="off"
                />
                {errorObj.confirmPassword && (
                    <text className={styles.error_text}>
                        Password donot match. Please, try again
                    </text>
                )}
            </div>
            <div className={styles.email}>
                <label className={styles.label}>
                    OTP<text className={styles.star}> *</text>
                </label>
                <input
                    id="otp"
                    type="text"
                    className={styles.password_text}
                    style={errorObj.otp ? { borderColor: "red" } : {}}
                    value={dataObj.otp}
                    onChange={(e) => handleTextChange("otp", e.target.value)}
                ></input>
                {errorObj.otp && (
                    <text className={styles.error_text}>Invalid OTP</text>
                )}
            </div>
            <div style={{ marginTop: 37, alignSelf: "center" }}>
                <CustomButton
                    type="submit"
                    label="Reset password"
                    onClick={handleResetPassword}
                />
            </div>
        </div>
    );
}
