const helpDeskColumns = [
    { name: 'issueid', title: 'Ticket ID', for: 'helpdesk' },
    // { name: 'ticketDetails', title: 'Ticket Details', for: 'helpdesk' },
    { name: 'category', title: 'Category', for: 'helpdesk' },
    // { name: 'Created By', title: 'Created By', for: 'helpdesk' },
    { name: 'createdon', title: 'Created On', for: 'helpdesk' },
    { name: 'status', title: 'Status', for: 'helpdesk' },
    { name: 'action', title: 'Action', route: '/helpdesk/edit', for: 'helpdesk' },
];

const helpDeskDefaultColumnWidths = [
    { columnName: 'issueid', weightage: 0, width: 200 },
    // { columnName: 'ticketDetails', weightage: 1, width: 200 },
    { columnName: 'category', weightage: 1.5, width: 200 },
    // { columnName: 'Created By', weightage: 2, width: 200 },
    { columnName: 'createdon', weightage: 0, width: 200 },
    { columnName: 'status', weightage: 0, width: 200 },
    { columnName: 'action', weightage: 2.5, width: 200 },
]

const helpDeskTableColumnExtensions = [
    { columnName: 'issueid', align: 'left' },
    // { columnName: 'ticketDetails', align: 'left' },
    { columnName: 'category', align: 'left' },
    // { columnName: 'Created By', align: 'left' },
    { columnName: 'createdon', align: 'left' },
    { columnName: 'status', align: 'left' },
    { columnName: 'action', align: 'center', sortingEnabled: false },
]

const helpDeskColumnOrder = [
    'issueid',
    // 'ticketDetails',
    'category',
    // 'Created By',
    'createdon',
    'status',
    // 'action'
]
const helpDeskColumnHidden = [
    // "id",
    "issueid",
    "category",
    "createdon",
    "status",
    "action",
];

const helpDeskRows = [
    {
        ticketId: '123',
        ticketDetails: 'Not able to create the project. I have tried more than 10times. It’s showing invalid message and also not about add the Users to my team',
        category: 'Projects',
        status: 'Open'
    }
]

const helpDeskDefaultSorting = []

export { helpDeskColumns, helpDeskDefaultColumnWidths, helpDeskTableColumnExtensions, helpDeskColumnOrder, helpDeskDefaultSorting, helpDeskRows ,helpDeskColumnHidden}
