const serviceGroupColumns = [
  // { name: 'id', title: 'Service Group ID' },
  {
    name: "name",
    title: "Service Group Name",
    route: "/documents/servicegroups-details",
    for: "servicegroupDocument",
  },
  { name: "createdBy", title: "Created By", for: "servicegroupDocument" },
  {
    name: "createdon",
    title: "Created On",
    for: "servicegroupDocument",
  },
  // { name: "status", title: "Status", for: "servicegroupDocument" },
  { name: "description", title: "Description", for: "servicegroupDocument" },

  
  // { name: 'assignedTo', title: 'Assigned To', for: "servicegroupDocument" },
 
  { name: "action", title: "Action", route: "/documents/servicegroup", for: "servicegroupDocument" },
];

const serviceGroupDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: 2 },
  { columnName: "createdBy", weightage: 2 },
  { columnName: "createdon", weightage: 2 },
  { columnName: "description", weightage: 2 },
  // { columnName: 'assignedTo', width: 180 },
  
  { columnName: "action", weightage: 2 },
];

const serviceGroupTableColumnExtensions = [
  { columnName: "id", align: "left" },
  { columnName: "name", align: "left" },
  { columnName: "createdBy", align: "left" },
  { columnName: "createdon", align: "center" },
  { columnName: "description", align: "left" },
  // { columnName: 'assignedTo', align: 'center' },
  
  { columnName: "action", align: "center", sortingEnabled: false },
];

const serviceGroupColumnOrder = [
  // 'id',
  "name",
  "createdBy",
  "createdon",
  "description",
  // 'assignedTo',
 
  "action",
];

const serviceGroupDefaultSorting = [];

export {
  serviceGroupColumns,
  serviceGroupDefaultColumnWidths,
  serviceGroupTableColumnExtensions,
  serviceGroupColumnOrder,
  serviceGroupDefaultSorting,
};
