import {
  Grid,
  Accordion,
  AccordionSummary, AccordionDetails, Typography, Tabs, Tab,
  Tooltip,
  Button,
} from "@material-ui/core";

import { useState } from "react";
import React, { useEffect } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";

import { ArrowDropDownRounded } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { fetchFieldGroupDetails, fetchFieldGroupListData } from "../../../utils/project/fieldgroupUtils";
import { useSnackbar } from "notistack";
import {
  editAPI,
  fetchAPIById,
  fetchServiceById,
  fetchVersionById,
  getRepositoryDetailsByMicroServiceVersion,
} from "../../../utils/project/microserviceUtils";
import { arrayToJson, handleFormValidationPro, initError, jsonToArray } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupForAiTaskApiEdit from "../../../components/common/entitySchema/aiTaskApi/CustomFieldGroupForAiTaskApiEdit";
import { connect, useDispatch } from "react-redux";
import { closeAITaskAPI, editAITaskAPITabbed, getFunctionListForAiTask } from "../../../redux/actions/aiTaskActions";
import { useLocation } from "react-router-dom";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EditAiTaskApi = (props) => {
  const { tabbedView } = props;
  // const isEditMode = props.match.params.action === "edit" ? true : false;
  const isEditMode = tabbedView
    ? props.isEditMode
    : props?.match?.params?.action === "edit"
      ? true
      : false;
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const mid = tabbedView ? props.serviceId : props.match.params.serviceId;
  const vid = tabbedView ? props.versionId : props.match.params.versionId;
  const aid = tabbedView ? props.apiId : props.match.params.apiId;
  const [serviceId] = useState(mid);
  const [versionId] = useState(vid);
  const [apiId] = useState(aid);
  const [activeTab, setActiveTab] = useState(0);
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(0);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [headers, setHeaders] = useState([
    { id: uuidv4(), key: "", value: "" },
  ]);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [serviceData, setServiceData] = useState({})
  const [apiEntitySchema, setApiEntitySchema] = useState([]);
  const [fieldGroupDetailsForInput, setFieldGroupDetailsForInput] = useState({});
  const [fieldGroupDetailsForOutput, setFieldGroupDetailsForOutput] = useState({});

  const [isApiRequired, setApiRequired] = useState(false)

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  const [apiData, setApiData] = useState({
    name: "",
    description: "",
    method: "",
    path: "",
    headers: {},
    input: "",
    output: "",
    type: "",
    queryParameters: [""],
    pathParameters: [""],
  });
  const [error, setError] = useState({
    name: { isError: false },
    type: { isError: false },
  });


  const fetchRepositoryDetails = async () => {
    const { _msg, _status, data } = await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      return data
    } else {

      return false
    }
  };


  const fetchVersionByIdDetails = async () => {
    const { _msg, _status, data } = await fetchVersionById(serviceId, versionId);
    if (_status === 200) {
      return data
    } else {

      return false
    }
  };

  useEffect(async () => {
    const { foldername, path } = await fetchRepositoryDetails()
    if (foldername) {
      props.getFunctionListForAiTask(foldername, path)

    }

    const { apirequired } = await fetchVersionByIdDetails()
    console.log("hvjvhvgcfrxylcdto", apirequired)
    setApiRequired(apirequired)

  }, [])

  useEffect(() => {
    const fetchAPIEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITaskAPI",
        "edit"
      );
      if (_status === 200) {
        const entity = []
        data?.sections?.map(section => (
          section?.fieldgroups?.map(fieldgroup => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section)
            }
          }))
        )

        setApiEntitySchema(entity);
        getAPIById();
        const apData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.uiElementType === "Amount") {
                apData[`${field.datakey}_unit`] = "";
                apData[`${field.datakey}_value`] = "";
                return true
              }
              if (field.datakey) {
                return (apData[field.datakey] = "");
              }
            });
          });
        });

        setApiData(prevData => ({ ...prevData, ...apData }));
        // setApiData(prevData => ({ ...prevData, ...apData, type: 'http', method: 'POST' }));

        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true
                }
                return errData[field.datakey] = initError(field, index)
              }
            });
          });
        });
        delete errData['parameter']
        delete errData['topic']

        setError(errData);
      } else {

        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const getFieldGroups = async () => {
      const { _msg, _status, data } = await fetchFieldGroupListData();
      if (_status === 200) {
        setFieldGroups(data ? data : []);
      } else {

        enqueueSnackbar(_msg, { variant: "error" });
      }
    };

    fetchAPIEntitySchema();
    getFieldGroups();
  }, []);

  const getAPIById = async () => {
    setIsLoader(true)
    const { _msg, _status, data } = await fetchAPIById(
      serviceId,
      versionId,
      apiId
    );
    if (_status === 200) {

      setApiData(prevData => ({ ...prevData, ...data, input: data?.input?.fieldGroupid, output: data?.output?.fieldGroupid }));
      setHeaders(jsonToArray(data.headers));
      fetchServiceDetails(data?.microserviceID)
      setIsLoader(false)
    } else {
      setIsLoader(false)

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const fetchServiceDetails = async (microserviceID) => {
    const { _msg, _status, data } = await fetchServiceById(microserviceID);
    if (_status === 200) {
      setServiceData(data);
    } else {

      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const getFieldGroupDetailsForInput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {

      setFieldGroupDetailsForInput(data);
    } else {

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const getFieldGroupDetailsForOutput = async (fieldGroupID) => {
    const { _msg, _status, data } = await fetchFieldGroupDetails(fieldGroupID);
    if (_status === 200) {

      setFieldGroupDetailsForOutput(data);
    } else {

      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  useEffect(() => {
    getFieldGroupDetailsForInput(apiData.input)
  }, [apiData.input])

  useEffect(() => {
    getFieldGroupDetailsForOutput(apiData.output)
  }, [apiData.output])

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {

    const value = event.target.value;
    if (event.target.name === "type") {
      if (event.target.value === "http") {
        const tempError = error;
        delete tempError['topic']
        tempError['path'] = {
          isError: false,
          fieldLabel: 'Path',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        tempError['method'] = {
          isError: false,
          fieldLabel: 'Method',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        setError(tempError)
      } else {
        const tempError = error;
        delete tempError['path']
        delete tempError['method']
        tempError['topic'] = {
          isError: false,
          fieldLabel: 'Topic',
          minLength: 1,
          maxLength: 300,
          pattern: '',
          msg: ""
        };
        setError(tempError)
      }
    }
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: ""
        }
      })
    }
    setApiData({
      ...apiData,
      [event.target.name]: value,
    });
  };

  const deleteQueryParameter = (index) => {
    apiData.queryParameters.splice(index, 1);
    setApiData({ ...apiData, queryParameters: apiData.queryParameters });
  };

  const deletePathParameter = (index) => {
    apiData.pathParameters.splice(index, 1);
    setApiData({ ...apiData, pathParameters: apiData.pathParameters });
  };

  const handleSubmit = async () => {

    let currentApiData = apiData
    if (!isApiRequired) {
      currentApiData['function'] = 'dummy'
    }
    const { formIsValid, errors } = handleFormValidationPro(currentApiData, error);
    if (formIsValid) {
      if (!isApiRequired) {
        delete currentApiData['function']
      }

      const newApiData = {
        ...currentApiData,
        headers: arrayToJson(headers),
        input: { fieldGroupid: currentApiData.input },
        output: { fieldGroupid: currentApiData.output },
        queryParameters: currentApiData.queryParameters,
        pathParameters: currentApiData.pathParameters,
      };
      delete newApiData["_id"];
      delete newApiData["A_mid"];
      delete newApiData["A_vid"];
      delete newApiData["createdby"];
      delete newApiData["createdon"];
      delete newApiData["updatedby"];
      delete newApiData["updatedon"];
      delete newApiData["versionID"];

      setIsLoader(true);
      const { _msg, _status, data } = await editAPI(
        serviceId,
        versionId,
        apiId,
        newApiData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        getAPIById()
        if (tabbedView) {
          dispatch(editAITaskAPITabbed(versionId));
          dispatch(closeAITaskAPI());
        } else {

          histroy.push(`/ai-task-api/details/${serviceId}/${versionId}/${apiId}`);
        }
      } else {

        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(apiEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setApiEntitySchema(tempEntity);
      setError(errors);
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  // useEffect(() => {
  //   return () => {
  //     dispatch(closeAITaskAPI())
  //   }
  // }, [])
  const handleCancel = () => {
    dispatch(closeAITaskAPI());
  };
  const handleEdit = () => {
    dispatch(editAITaskAPITabbed(versionId));
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        {tabbedView ? (
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{
              borderBottom: "2px solid #afafaf",
              marginTop: "-80px",
              padding: "0px 0px",
            }}
          >
            {/* <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              indicatorColor="primary"
              textColor="primary"
              className={style.documentsListAccessTab}
            >
              {apiEntitySchema.length !== 0 &&
                apiEntitySchema.map((section) => {
                  return <Tab label={<>{section["section Label"]} </>} />;
                })}
            </Tabs> */}
            <h2 className={styles.heading}>
              {isEditMode ? `Edit ${apiData.name}` : apiData.name}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* {activeTab > 0 ? (
                <Tooltip title="Previous" arrow>
                  <Button
                    aria-label="previous"
                    color="primary"
                    variant="outlined"
                    startIcon={
                      <>
                        <NavigateBeforeIcon
                          style={{ width: "25px", height: "23px" }}
                        />
                      </>
                    }
                    className="btnsmall"
                    onClick={handlePrevious}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
              &nbsp;&nbsp; */}
              {/* {!isEditMode ? (
                <Tooltip title="Edit" arrow>
                  <Button
                    color="primary"
                    buttonName="Edit"
                    variant="outlined"
                    startIcon={
                      <EditIcon style={{ width: "25px", height: "23px" }} />
                    }
                    disabled={!checkCompPermission("Microservice", ["Edit"])}
                    style={{ padding: "6px 15px" }}
                    className="btnsmall"
                    onClick={handleEdit}
                  />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Save" arrow>
                    <Button
                      aria-label="previous"
                      color="primary"
                      variant="outlined"
                      disabled={!checkCompPermission("Microservice", ["Edit"])}
                      startIcon={
                        <SaveIcon style={{ width: "25px", height: "23px" }} />
                      }
                      className="btnsmall"
                      onClick={handleSubmit}
                    />
                  </Tooltip>
                </>
              )}
              &nbsp;&nbsp; */}
              {/* <Tooltip title="Cancel" arrow>
                <Button
                  aria-label="Cancel"
                  color="primary"
                  variant="outlined"
                  startIcon={
                    <HighlightOffIcon
                      style={{ width: "25px", height: "23px" }}
                    />
                  }
                  className="btnsmall"
                  onClick={handleCancel}
                />
              </Tooltip>
              &nbsp;&nbsp; */}
              {/* {activeTab !== apiEntitySchema.length - 1 ? (
                <Tooltip title="Next" arrow>
                  <Button
                    aria-label="Next"
                    color="primary"
                    variant="outlined"
                    startIcon={
                      <>
                        <NavigateNextIcon
                          style={{ width: "25px", height: "23px" }}
                        />
                      </>
                    }
                    className="btnsmall"
                    onClick={handleNext}
                  />
                </Tooltip>
              ) : (
                <></>
              )} */}
              <TabbedNavigation isEditMode={isEditMode} handleSave={handleSubmit} handleEdit={handleEdit} handleCancel={handleCancel} isClose={isEditMode ? false : true} />
            </div>
          </Grid>
        ) : (<div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "AI Task", path: "/aitask-workbench" },
              {
                title: serviceData?.name,
                path: `/aitask/details/${serviceId}`,
              },
              { title: isEditMode ? `Edit ${apiData.name}` : apiData.name },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>
              {isEditMode ? `Edit ${apiData.name}` : apiData.name ? apiData.name : "API"}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",

              }}
            >

              {!isEditMode ? (
                <AccordionNavigation
                  disabled={false}
                  isClose={isEditMode ? false : true}
                  isEditMode={true}
                  handleEdit={() =>
                    histroy.push(`/ai-task-api/edit/${serviceId}/${versionId}/${apiId}`)
                  }
                  handleCancel={() => histroy.goBack()}
                />
              ) : (
                <AccordionNavigation
                  isClose={isEditMode ? false : true}

                  disabled={false}
                  isEditMode={false}
                  handleCancel={() => histroy.goBack()}
                  handleSave={handleSubmit}
                />
              )}
            </div>
          </Grid>
        </div>)}
        {
          tabbedView ? (<div style={{ padding: "0px 20px" }}>

            <SectionTabs entity={apiEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            <CustomFieldGroupForAiTaskApiEdit
              section={apiEntitySchema[activeTab]}
              apiData={apiData}
              error={error}
              isApiRequired={isApiRequired}
              tab={tab}
              isEditMode={isEditMode}
              fieldGroups={fieldGroups}
              fieldGroupDetailsForInput={fieldGroupDetailsForInput}
              fieldGroupDetailsForOutput={fieldGroupDetailsForOutput}
              headers={headers}
              setHeaders={setHeaders}
              deletePathParameter={deletePathParameter}
              deleteQueryParameter={deleteQueryParameter}
              handleTabChange={handleTabChange}
              setError={setError}
              setApiData={setApiData}
              handleChange={handleChange}
            />

          </div>

          ) : (<div className={styles.wrapper}>
            {apiEntitySchema &&
              apiEntitySchema.map((section) => {
                if (section.name === "AITask_API_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupForAiTaskApiEdit
                        section={section}
                        apiData={apiData}
                        isEditMode={isEditMode}
                        error={error}
                        setError={setError}
                        setApiData={setApiData}
                        handleChange={handleChange}
                        isApiRequired={isApiRequired}

                      />
                    </div>
                  );
                } else {
                  return (
                    <>
                      {apiData.type === "rest" && <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupForAiTaskApiEdit
                            section={section}
                            apiData={apiData}
                            error={error}
                            tab={tab}
                            isEditMode={isEditMode}
                            isApiRequired={isApiRequired}
                            fieldGroups={fieldGroups}
                            fieldGroupDetailsForInput={fieldGroupDetailsForInput}
                            fieldGroupDetailsForOutput={fieldGroupDetailsForOutput}
                            headers={headers}
                            setHeaders={setHeaders}
                            deletePathParameter={deletePathParameter}
                            deleteQueryParameter={deleteQueryParameter}
                            handleTabChange={handleTabChange}
                            setError={setError}
                            setApiData={setApiData}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>}
                    </>
                  );
                }
              })}
          </div>)
        }

      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getFunctionListForAiTask
};

export default connect(null, mapDispatchToProps)(EditAiTaskApi);

