import { ApiHelper } from "./helper/ApiHelper";
import { TEMPLATE, SIMULATION } from "./helper/config";

const apiHelper = new ApiHelper();

export class OrchestrationServices {
  createTemplate(id, data) {
    const uri = `${TEMPLATE}/api/v1.0.0/applications/${id}/templates`;
    return apiHelper.post(uri, data);
  }
  addNode(id, data) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${id}/nodes`;
    return apiHelper.post(uri, data);
  }
  deleteNode(templateid, nodeid) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateid}/nodes/${nodeid}`;
    return apiHelper.delete(uri)
  }
  addRelation(templateID, sourceNodeID, data) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/nodes/${sourceNodeID}/relations`;
    return apiHelper.post(uri, data);
  }
  editRelation(templateID, relationID, req_data) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/relations/${relationID}`;
    return apiHelper.put(uri, req_data)
  }
  fetchNodeFromTemplate(templateID, nodeID) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/nodes/${nodeID}`;
    return apiHelper.get(uri);
  }
  editNodeDetails(templateID, nodeID, data) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/nodes/${nodeID}`;
    return apiHelper.put(uri, data);
  }
  fetchAllNodesOfTemplate(templateID) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/nodes`;
    return apiHelper.get(uri)
  }
  getTemplateFlow(templateID) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/flow`;
    return apiHelper.get(uri)
  }
  simulateTemplate(data) {
    const uri = `${SIMULATION}/api/simulation/simulate/template`;
    return apiHelper.post(uri, data);
  }
  debugTemplate(data, instanceID) {
    var uri = "";
    if (instanceID) {
      uri = `${SIMULATION}/api/simulation/debug/template?instanceid="${instanceID}"`;
    } else {
      uri = `${SIMULATION}/api/simulation/debug/template`
    }
    return apiHelper.post(uri, data)
  }
  getInstanceContext(applicationID, templateID, instanceID) {
    const uri = `${TEMPLATE}/api/v1.0.0/applications/${applicationID}/templates/${templateID}/instances/${instanceID}`;
    return apiHelper.get(uri)
  }
  deleteRelation(templateID, startNodeID, req_data) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}/nodes/${startNodeID}/relations/delete`;
    return apiHelper.post(uri, req_data)
  }
  getContainerNameForApplication(applicationID) {
    const uri = `${TEMPLATE}/api/container/environments?filters={"resourcetype":"application","resourceid":"${applicationID}"}`;
    return apiHelper.get(uri)
  }
  deleteTemplateByID(templateID) {
    const uri = `${TEMPLATE}/api/v1.0.0/templates/${templateID}`;
    return apiHelper.delete(uri)
  }
  updateNodePositions(applicationID, templateId, req_data) {
    const uri = `${TEMPLATE}/api/v1.0.0/applications/${applicationID}/templates/${templateId}`;
    return apiHelper.put(uri, req_data)
  }
}
