import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import { withStyles } from "@material-ui/core"
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

const useStyles = theme => ({
    draggableTree: {
        "& .rst__rowWrapper": {
            padding: "20px 0px 15px 0px",
            "& .rst__row": {
                maxWidth: "17rem",
                paddingRight: "0.5rem"
            }
        }
    }
})

class Tree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [
                { title: 'Chicken', children: [{ title: 'Egg' }, { title: 'fingerline1' }, { title: 'fingerline3' }, { title: 'fingerline2' }, { title: 'fingerline4' }, { title: 'fingerline5' }] }
            ],
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 400, marginTop: "5rem" }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    isVirtualized={false}
                    className={classes.draggableTree}
                />
            </div>
        );
    }
}

export default withStyles(useStyles)(Tree)