import {
  ADD_DEPLOYMENT_LIST,
  ADD_DEPLOYMENT_PROCESS,
  ADD_DEPLOYMENT_RESPONSE,
  ADD_DEPLOY_CONTAINER,
  ADD_ENVIRONMENT_DEPLOYMENT,
  ADD_SIZING_DEPLOYMENT,
  ADD_VOLUMN_DEPLOYMENT,
  EDIT_CONTAINER_DEPLOYMENT,
  RESTART_CONTAINER_DEPLOYMENT,
  SELECT_CONTAINER_DEPLOYMENT,
  SELECT_PROCESS_DEPLOYMENT,
  SET_DEPLOY_ERROR,
  REMOVE_VERSION,
  ADD_CLOUD_CONFIG_DETAILS,
  ADD_LAST_SELECTED_CLOUD_CONFIG,
  ADD_CLUSTER_LIST_DEPLOYMENT,
  ADD_DEPLOYMENT_NAME,
  SHOW_CLUSTER,
  ADD_DEPLOYMENT_DETAILS_HIDDEN,
  ADD_NODES_USAGE_DATA,
  ADD_NODES_LIST,
} from "../actionTypes";
import { DeploymentProcessService } from "../../services/deploymentProcessService";
import {
  DEPLOYMENT_FILTERS,
  DEPLOYMENT_SORT,
  DEPLOYMENT_HIDDEN,
  CLEAR_DEPLOYMENT,
  ADD_APP_SELECTION_DATA,
  DELETE_DEPLOYMENT_BY_ID,
  ADD_SELECTED_CLUSTER,
  CLEAR_DEPLOYMENT_DATA,
  ADD_DEPLOYMENT_DETAILS_LIST,
  DELETE_DEPLOYMENT_BY_CONTAINER,
  ADD_CHART_VALUES,
  SHOW_CHART,
  ADD_CLUSTER_VALIDITY,
  REMOVE_CLUSTER_VALIDITY,
} from "../actionTypes";

const deploymentProcessService = new DeploymentProcessService();

export const addAppSelectData = (appSelectionData) => {
  return {
    type: ADD_APP_SELECTION_DATA,
    payload: appSelectionData,
  };
};

export const add_Cluster_Validity = () => ({
  type: ADD_CLUSTER_VALIDITY,
});

export const remove_Cluster_Validity = () => ({
  type: REMOVE_CLUSTER_VALIDITY,
});

export const addCloudClusterDetails = (cloudDetails) => ({
  type: ADD_CLOUD_CONFIG_DETAILS,
  payload: cloudDetails,
});

export const addLastSelectClougConfiguration = (lastSelectedCloudConfig) => ({
  type: ADD_LAST_SELECTED_CLOUD_CONFIG,
  payload: lastSelectedCloudConfig,
});
// export const clearVersion = () => {
//   return {
//     type: REMOVE_VERSION,
//   };
// };
export const addSelectedCluster = (payload) => ({
  type: ADD_SELECTED_CLUSTER,
  payload: payload,
});

const add_deployment_list = (payload) => ({
  type: ADD_DEPLOYMENT_LIST,
  payload: payload,
});

export const addDeploymentName = (payload) => ({
  type: ADD_DEPLOYMENT_NAME,
  payload: payload,
});

export const fetchAllDeployment =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_deployment_list({
          loading: true,
        })
      );
      const res_data = await deploymentProcessService.getAllDeployment(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_deployment_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_deployment_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_deployment_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

const add_deployment_details_list = (payload) => ({
  type: ADD_DEPLOYMENT_DETAILS_LIST,
  payload: payload,
});

export const fetchDeploymentById =
  (DeploymentId, currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_deployment_details_list({
          loading: true,
        })
      );
      const res_data = await deploymentProcessService.getDeploymentById(
        DeploymentId,
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_deployment_details_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_deployment_details_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_deployment_details_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addDeployContainer = (data) => {
  return {
    type: ADD_DEPLOY_CONTAINER,
    payload: data,
  };
};

export const addDeploymentFilters = (deploymentFilters) => {
  return {
    type: DEPLOYMENT_FILTERS,
    payload: deploymentFilters,
  };
};

export const addDeploymentSort = (deploymentSort) => {
  return {
    type: DEPLOYMENT_SORT,
    payload: deploymentSort,
  };
};

export const addDeploymentHidden = (deploymentHidden) => {
  return {
    type: DEPLOYMENT_HIDDEN,
    payload: deploymentHidden,
  };
};

export const clearDeployment = () => {
  return {
    type: CLEAR_DEPLOYMENT,
  };
};

export const clearDeploymentData = () => {
  return {
    type: CLEAR_DEPLOYMENT_DATA,
  };
};

export const addEnvironmentDeployment = (env) => {
  return {
    type: ADD_ENVIRONMENT_DEPLOYMENT,
    payload: env,
  };
};

export const restartContainerCheck = (data) => {
  return {
    type: RESTART_CONTAINER_DEPLOYMENT,
    payload: data,
  };
};

export const deleteDeploymentById = (DeploymentId) => {
  return {
    type: DELETE_DEPLOYMENT_BY_ID,
    payload: {
      DeploymentId,
    },
  };
};

export const deleteDeploymentByContainer = (DeploymentId, ContainerName) => {
  return {
    type: DELETE_DEPLOYMENT_BY_CONTAINER,
    payload: {
      DeploymentId,
      ContainerName,
    },
  };
};

export const addDeploymentProcess = (data) => {
  return {
    type: ADD_DEPLOYMENT_PROCESS,
    payload: data,
  };
};

export const selectContainerCheck = (data) => {
  return {
    type: SELECT_CONTAINER_DEPLOYMENT,
    payload: data,
  };
};

export const selectProcessCheck = (data) => {
  return {
    type: SELECT_PROCESS_DEPLOYMENT,
    payload: data,
  };
};

export const editContainerDeployment = (data) => {
  return {
    type: EDIT_CONTAINER_DEPLOYMENT,
    payload: data,
  };
};

export const addVolumnDeployment = (data) => {
  return {
    type: ADD_VOLUMN_DEPLOYMENT,
    payload: data,
  };
};

export const addDeploymentResponse = (responseData) => {
  return {
    type: ADD_DEPLOYMENT_RESPONSE,
    payload: responseData,
  };
};

export const setDeploymentErrors = (data) => {
  return {
    type: SET_DEPLOY_ERROR,
    payload: data,
  };
};

export const addSizingDeployment = (data) => {
  return {
    type: ADD_SIZING_DEPLOYMENT,
    payload: data,
  };
};

export const addClusterList = (data) => {
  return {
    type: ADD_CLUSTER_LIST_DEPLOYMENT,
    payload: data,
  };
};

export const addChartValue = (data) => {
  return {
    type: ADD_CHART_VALUES,
    payload: data,
  };
};

export const addDeploymentDetailsHidden = (data) => {
  return {
    type: ADD_DEPLOYMENT_DETAILS_HIDDEN,
    payload: data,
  };
};

export const showMyChart = () => {
  return {
    type: SHOW_CHART,
  };
};

export const addNodesListData = (payload) => ({
  type: ADD_NODES_LIST,
  payload: payload,
});

export const addNodesUsageData = (payload) => ({
  type: ADD_NODES_USAGE_DATA,
  payload: payload,
});
