import { UiApplicationService } from "./UiApplicationService";
const apiService = new UiApplicationService();

export const DeleteUiApplication = async (id) => {
  try {
    const res_data = await apiService.deleteApplication(id);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};
export const DeleteTemplate = async (id) => {
  try {
    const res_data = await apiService.deletetemplate(id);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};

export const buildUIApplication = async (applicationId) => {
  try {
    const res_data = await apiService.buildUIApplication(applicationId);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};

export const downLoadDynamicBuild = async (applicationId) => {
  try {
    const res_data = await apiService.downLoadDynamicBuild(applicationId);
    return {
      res_data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addApplication = async (data) => {
  try {
    const res_data = await apiService.addApplication(data);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};

export const getApplicationById = async (data) => {
  try {
    const res_data = await apiService.getApplicationById(data);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};

export const fetchUiApplicationById = async (applicationId) => {
  try {
    const res_data = await apiService.getApplicationById(applicationId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editAppBundleById = async (id, data) => {
  try {
    const res_data = await apiService.editApplication(id, data);
    return {
      res_data,
    };
  } catch (err) {
    return {
      err,
    };
  }
};

export const getAllUiApplications = async () => {
  try {
    const res_data = await apiService.getAllUiApplications();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

// New structure
export const addNewPage = async (id, data) => {
  try {
    const res_data = await apiService.addNewPage(id, data);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const editPage = async (id, data) => {
  try {
    const res_data = await apiService.editPage(id, data);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const deletePage = async (id, pageName) => {
  try {
    const res_data = await apiService.deletePage(id, pageName);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const addNewComponent = async (id, pageName, data) => {
  try {
    const res_data = await apiService.fetchAllComponents(id, pageName, data);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchAllComponents = async (id, pageName) => {
  try {
    const res_data = await apiService.fetchAllComponents(id, pageName);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchAllComponentByID = async (id, pageName, componentId) => {
  try {
    const res_data = await apiService.fetchAllComponentByID(
      id,
      pageName,
      componentId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getDeploymentsUiApplicationsById = async (id) => {
  try {
    const res_data = await apiService.getDeploymentsUiApplicationsById(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};