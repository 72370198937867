import { Chip } from "@material-ui/core";
import React from "react";

import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { connect } from "react-redux";

function CountTimer({containerEndTime, containerinactivity}) {
  const [timer, setTimer] = useState(0);
  const [minute, setMinute] = useState(0);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if(containerEndTime === 0){
        setMinute(0)
        setTimer(0)
        setPercentage(0)
    } else {

    const currentTime = Math.floor(Date.now() / 1000)

    setPercentage(((containerEndTime-currentTime)/ 60 * containerinactivity) * 100)

    var minutes = Math.floor((containerEndTime-currentTime) / 60)
    setMinute(minutes)

    var seconds = (containerEndTime-currentTime) - minutes * 60;

    setTimer(seconds)


}

  }, [containerEndTime])
  

  useEffect(() => {
    
    let myInterval = setInterval(() => {
    const currentTime = Math.floor(Date.now() / 1000)

      if(timer === 0){
        if(minute !== 0){
          setMinute(minute - 1)
          setTimer(59)
        }
      } else {
      setPercentage(((containerEndTime-currentTime)/ (60 * containerinactivity)) * 100)
      setTimer(timer - 1)
      }
    }, 1000)

    return ()=> {
      clearInterval(myInterval);
    };

  })
  return <div >
    <Chip label={`${minute}:${timer < 10 ? `0${timer}` : timer}`}  style={{background: percentage <= 0 ? 'gray' : (percentage > 40  ? 'green' : (percentage > 20  ? '#FF8C00' : 'red')), color:'white'}} size="small" />
    
    {/* <div style={{background:'green', borderRadius:'25px', padding:"0px 35px 0px 15px", minWidth:'55px', color:'white', }}>
      
      10:10
    </div> */}
    {/* <CircularProgressbar  value={percentage} text={`${minute}:${timer < 10 ? `0${timer}` : timer}`} 
    
    styles={buildStyles({
      // Rotation of path and trail, in number of turns (0-1)
      rotation: 0.25,
  
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: 'butt',
  
      // Text size
      textSize: '28px',
  
      // How long animation takes to go from one percentage to another, in seconds
      pathTransitionDuration: 0.5,
  
      // Can specify path transition in more detail, or remove it entirely
      // pathTransition: 'none',
  
      // Colors
      pathColor: `green`,
      textColor: '#f88',
      trailColor: '#d6d6d6',
      backgroundColor: '#3e98c7',
    })}
    /> */}
    
    {/* {minute}:{timer < 10 && '0'}{timer} */}
    
    </div>;
}

const mapStateToProps = (state) => {
  const {containerinactivity} = state.organizationReducer?.currentOrgData?.data
  return {
    containerinactivity
  }
}

export default connect(mapStateToProps) (CountTimer)