import React, { useState } from "react";
import settingStyles from "../../../../../styles/commonStyles/Setting";
import {
  IconButton,
  Popover,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Input from "../../../input/Input";
import CloseIcon from "@material-ui/icons/Close";
import ShowCondition from "../../trigger/ShowCondition";
import Dropdown from "../../../dropdown/Dropdown";
import { SelectBuilder } from "./helper/PopoverHelper";
import DisabledButton from "../../appearance/disabled/DisabledButton";
import DisableSpecific from "../../appearance/disabled/DisableSpecific";
import CustomAutocomplete from "../../../autocomplete/CustomAutocomplete";

const FormPopup = ({
  isOptionsOpen,
  optionsEl,
  columns,
  setOptionsEl,
  selectedColumn,
  handleHide,
  handleDelete,
  handleChange,
  handleChangeForPopOver,
  handleChangeDiscardFromPayload,
  handleChangeRequired,
  options,
}) => {
  const styles = settingStyles();
  const [checked, setChecked] = useState(false);

  return (
    <Popover
      open={isOptionsOpen}
      anchorEl={optionsEl}
      onClose={() => setOptionsEl(null)}
      className={styles.options}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <div className="optionsWrap">
        <div className="header">
          <h3>{selectedColumn?.title}</h3>
          <div className="icons">
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => handleHide(selectedColumn)}
            >
              {selectedColumn?.isHide ? (
                <img
                  src="/img/eye-slash.svg"
                  style={{ height: 20, opacity: 0.5 }}
                  alt="Eye"
                />
              ) : (
                <img src="/img/eye-line.svg" alt="Eye" />
              )}
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => {
                setOptionsEl(null);
                handleDelete(selectedColumn);
              }}
            >
              <img src="/img/delete-bin-7-line.svg" alt="Delete" />
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              style={{ margin: 0, padding: 5 }}
              onClick={() => setOptionsEl(null)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="body">
          {/* <Input
            name={'name'}
            label={'Field Name'}
            placeholder=""
            value={selectedColumn?.name}
            onChange={handleChange}
          /> */}
          <CustomAutocomplete
            options={options}
            optionLabel={(option) => (option ? option?.toString() : "")}
            value={selectedColumn?.name}
            onChange={(input) =>
              handleChange({ target: { name: "name", value: input } })
            }
            freeSolo={true}
            autoSelect={true}
          />
          <label>{"Type"}</label>
          <Dropdown
            arr={columns}
            disabled={false}
            width="auto"
            label={"fieldType"}
            name={"type"}
            value={selectedColumn?.type}
            onChange={handleChangeForPopOver}
          />
          <div style={{ height: 10 }} />
          <Input
            name={"title"}
            label={"Label"}
            placeholder=""
            value={selectedColumn?.title}
            onChange={handleChange}
          />
          {selectedColumn?.type === "textarea" && (
            <Input
              name={"rows"}
              label={"Lines number"}
              placeholder=""
              value={selectedColumn?.rows}
              onChange={handleChange}
            />
          )}
          {selectedColumn?.type === "text" && (
            <Input
              name={"placeholder"}
              label={"Placeholder"}
              placeholder=""
              value={selectedColumn?.placeholder}
              onChange={handleChange}
            />
          )}
          {selectedColumn?.type === "textarea" && (
            <Input
              name={"placeholder"}
              label={"Placeholder"}
              placeholder=""
              value={selectedColumn?.placeholder}
              onChange={handleChange}
            />
          )}
          {selectedColumn?.type === "select" && (
            <div>
              <SelectBuilder selectedColumn={selectedColumn} />
            </div>
          )}
          {selectedColumn?.type === "multiselect" && (
            <div>
              <SelectBuilder selectedColumn={selectedColumn} />
            </div>
          )}
          <div className="field">
            <DisableSpecific selectedColumn={selectedColumn} />
          </div>
          <FormControlLabel
            label={<span style={{ fontSize: 13 }}>Required field</span>}
            control={
              <Checkbox
                checked={selectedColumn?.isRequired}
                onChange={handleChangeRequired}
                size="small"
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={<span style={{ fontSize: 13 }}>Discard From Payload</span>}
            control={
              <Checkbox
                checked={selectedColumn?.isDiscardedFromPayload}
                onChange={handleChangeDiscardFromPayload}
                size="small"
                color="primary"
              />
            }
          />
          <div style={{ borderTop: "1px solid #F0F0F0", padding: 0 }}>
            <ShowCondition />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default FormPopup;