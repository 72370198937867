import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  main: {
    marginTop: "4rem",
    // border : '1px solid'
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  topContentItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // border : '1px solid'
  },
  topContentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // border : '1px solid'
  },
  title: {
    fontSize: 21,
    color: "#161616",
    // border : '1px solid'
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    paddingLeft: "1rem",
    // paddingBottom: "1rem",
    "&::before": {
      borderBottom: "unset",
    },
    "& .MuiFilledInput-input": {
      padding: "9px 0px 9px"
    }
  },
  wrapper: {
    position: "relative",
    top: "6.8rem",
    marginLeft: "1.5rem",
    marginRight: "1.5rem"
  },
  wrapper1: {
    position: "relative",
    top: "6rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  fieldHeading: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },

  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    // height: "80%",
    font: "normal normal normal 12px/16px Nunito",
    marginTop: "5px",
    resize: "vertical",
    minHeight: "2.2rem",
    maxHeight: "13.2rem",
    padding: "10px 15px",
    outline: "none",
  },
  searchwrapper: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    width: "30%",
  },

  searchinput: {
    width: "95%",
    height: 37,
    // backgroundColor: "#fff",
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 5px",
  },
  dropdowns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {
    height: 38,
    width: 355,
    borderWidth: 1,
    borderColor: "#DEDEDF",
    borderRadius: 5,
    backgroundColor: "white",
  },
  icon: {
    position: "relative",
    left: 150,
  },
  formControl: {
    width: 204,
    height: 55,
    backgroundColor: "#fff",
    // border: "1px solid #DEDEDF",
    borderRadius: 5,
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  submitbuttonwrapper: {
    marginTop: "2rem",
    marginBottom: "5rem",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#F5F7FA",
  },

  //   table
  myTable: {
    marginTop: "1.5rem",
    // color: "white",
    // height: 340,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
  },

  // process table
  processTable: {
    marginTop: "1rem",
    width: "100%",
    "& div": {
      "& .makeStyles-myDevTable-511 tbody tr td": {
        padding: "16px 19px 17px 30px !important"
      }
    }

    // height: 400,
  },
  pageContent: {
    boxShadow: "none",
    marginTop: "1rem",
    width: "98%",
    // height: 400,
    // position: "relative",
    // top: "8rem",
  },
  process_link: {
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#031CCA",
    opacity: 0.6,
    paddingRight: 40,
  },
  fieldPanel: {
    marginBottom: "1rem",
    marginTop: "1rem",
    boxShadow: "unset",
    border: "unset",
    backgroundColor: "transparent",

    "&:before": {
      backgroundColor: "unset",
    },

    "& .MuiAccordionSummary-root": {
      minHeight: 48,
      padding: 0,
      borderBottom: "2px solid #afafaf",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },

      "& .MuiIconButton-root": {
        padding: "0px 12px",

        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },

    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  accordianIcon: {
    fontSize: "3rem",
    paddingTop: 0,
    paddingBottom: 0,
    color: "#000000",
  },
  border: {
    borderBottom: "2px solid #afafaf",
    opacity: 0.4,
    marginBottom: "1rem",
  },
  accordion_details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'blue',
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    // paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
}));
