import {
  ADD_API_LIST_DATA,
  ADD_SERVICE_LIST_DATA,
  ADD_VERSION_LIST_DATA,
  ALTER_FAVOURITE_SERVICE_BY_ID,
  ALTER_SUBSCRIBE_SERVICE_BY_ID,
  API_HIDDEN,
  API_SORT,
  CLEAR_API,
  CLEAR_SERVICES,
  CLEAR_VERSION,
  DELETE_API_BY_ID,
  DELETE_SERVICE_BY_ID,
  DELETE_VERSION_BY_ID,
  SELECT_API_CONTAINER,
  SERVICE_GUI_FILTERS,
  SERVICE_HIDDEN,
  SERVICE_GUI_SORT,
  VERSION_HIDDEN,
  VERSION_LOADING,
  VERSION_SORT,
  ALTER_FAVOURITE_SERVICE_GUI_BY_ID,
  SERVICE_GUI_HIDDEN,
  ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID,
  DELETE_SERVICE_GUI_BY_ID,
  CLEAR_SERVICES_GUI,
  ADD_SERVICE_GUI_LIST_DATA,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  servicesGUIList: {
    data: [],
    error: false,
    loading: false,
  },

  serviceGUIFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  serviceGUISort: [{ columnName: "createdon", direction: "desc" }],
  serviceGUIHidden: ["name", "createdby", "createdon", "status", "action"],
  versionSort: [{ columnName: "createdon", direction: "desc" }],
  versionHidden: ["versionTag", "port", "fileName", "imageStatus", "action"],
  apiSort: [{ columnName: "createdon", direction: "desc" }],
  apiHidden: ["name", "type", "createdby", "createdon", "action"],
};

const serviceGUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICE_GUI_LIST_DATA: {
      return {
        ...state,
        servicesGUIList: {
          ...state.servicesGUIList,
          ...action.payload,
          deleteServiceGUIStatus: false,
        },
      };
    }
    case SERVICE_GUI_FILTERS: {
      return {
        ...state,
        serviceGUIFilters: [...action.payload],
      };
    }
    case SERVICE_GUI_SORT: {
      return {
        ...state,
        serviceGUISort: [...action.payload],
      };
    }
    case SERVICE_GUI_HIDDEN: {
      return {
        ...state,
        serviceGUIHidden: [...action.payload],
      };
    }
    case ALTER_FAVOURITE_SERVICE_GUI_BY_ID: {
      return {
        ...state,
        servicesGUIList: {
          ...state.servicesGUIList,
          GUIdata: {
            ...state.servicesGUIList.data,
            data:
              state.servicesGUIList.GUIdata.data.length !== 0 &&
              state.servicesGUIList.GUIdata.data.map((serv) => {
                if (serv._id === action.payload.serviceId) {
                  return { ...serv, isFavourite: !serv.isFavourite };
                } else {
                  return serv;
                }
              }),
          },
        },
      };
    }
    case ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID: {
      return {
        ...state,
        servicesGUIList: {
          ...state.servicesGUIList,
          GUIdata: {
            ...state.servicesGUIList.data,
            data:
              state.servicesGUIList.GUIdata.data.length !== 0 &&
              state.servicesGUIList.GUIdata.data.map((serv) => {
                if (serv._id === action.payload.serviceId) {
                  return { ...serv, isSubscribe: !serv.isSubscribe };
                } else {
                  return serv;
                }
              }),
          },
        },
      };
    }

    case DELETE_SERVICE_GUI_BY_ID: {
      return {
          ...state,
          servicesGUIList: {
              ...state.servicesGUIList,
              GUIdata: {
                  ...state.servicesGUIList.data,
                  data: state.servicesGUIList?.GUIdata?.data && state.servicesGUIList.GUIdata.data.filter(serv => serv._id !== action.payload.serviceId)
              },
              _totalcount: state.servicesGUIList?.GUIdata?.data && state.servicesGUIList._totalcount - 1,
              deleteServiceGUIStatus: true,
          }
      }
    }
    
    case CLEAR_SERVICES_GUI: {
      return {
        ...state,
        servicesGUIList: {},
      };
    }
    case ADD_VERSION_LIST_DATA: {
      return {
        ...state,
        versionsList: {
          ...state.versionsList,
          ...action.payload,
        },
      };
    }
    case VERSION_SORT: {
      return {
        ...state,
        versionSort: [...action.payload],
      };
    }
    case VERSION_HIDDEN: {
      return {
        ...state,
        versionHidden: [...action.payload],
      };
    }
    case VERSION_LOADING: {
      return {
        ...state,
        versionsList: {
          ...state.versionsList,
          loading: action.payload,
        },
      };
    }
    case DELETE_VERSION_BY_ID: {
      return {
        ...state,
        versionsList: {
          ...state.versionsList,
          data: {
            ...state.versionsList.data,
            data: state.versionsList.data.data.filter(
              (ver) => ver._id !== action.payload.versionId
            ),
          },
        },
      };
    }
    case CLEAR_VERSION: {
      return {
        ...state,
        versionsList: {},
      };
    }
    case ADD_API_LIST_DATA: {
      return {
        ...state,
        apisList: {
          ...state.apisList,
          ...action.payload,
        },
      };
    }
    case API_SORT: {
      return {
        ...state,
        apiSort: [...action.payload],
      };
    }
    case API_HIDDEN: {
      return {
        ...state,
        apiHidden: [...action.payload],
      };
    }
    case DELETE_API_BY_ID: {
      return {
        ...state,
        apisList: {
          ...state.apisList,
          data: {
            ...state.apisList.data,
            data: state?.apisList?.data?.data?.filter(
              (api) => api._id !== action.payload.apiId
            ),
          },
        },
      };
    }
    case CLEAR_API: {
      return {
        ...state,
        apisList: {},
      };
    }
    case SELECT_API_CONTAINER: {
      return {
        ...state,
        apisList: {
          ...state.apisList,
          data:  state.apisList.data.map((api) => {
              if (api._id === action.payload.apiId) {
                return {
                  ...api,
                  selectedInstance: action.payload.selectedInstance,
                };
              } else {
                return api;
              }
            }),
        },
      };
    }
    default:
      return state;
  }
};

export default serviceGUIReducer;
