import Styles from "../../../../../styles/commonStyles/MenuRightBar";
import { Popover } from "@material-ui/core";
import { Switch } from "@mui/material";
import TrueFalseButton from "../../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import {
  editComponent,
  editComponentById,
} from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";

const LogoPopup = ({
  isOptionsOpen,
  optionsEl,
  setOptionsEl,
  currentPage,
  currentComponent,
}) => {
  const styles = Styles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isTitlePosCenter, setIsTitlePosCenter] = useState(
    currentComponent?.isTitlePositionCenter
  );
  const selectedTypeCss = { color: "black", fontWeight: 800, fontSize: 14 };

  const handleUploadLogo = (e) => {
    const file = e?.target?.files[0];
    if (file.size < 500000) {
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const components = currentPage.components.map((component) =>
            component.id === currentComponent.id
              ? {
                  ...component,
                  logo: reader.result,
                }
              : component
          );
          dispatch(
            editComponent({
              pageid: currentPage?.id,
              data: components,
            })
          );
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    } else {
      enqueueSnackbar("Please upload image size less than 500kb", {
        variant: "error",
      });
    }
  };

  const handleChange = (e) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [e.target.name]: e.target.value,
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  const handleOnRemove = () =>
    dispatch(
      editComponentById({
        pageUrl: currentPage.url,
        compId: currentComponent._id,
        data: { ...currentComponent, logo: "" },
      })
    );

  const handleChangeTitlePos = () => {
    setIsTitlePosCenter(!isTitlePosCenter);
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          isTitlePositionCenter: !currentComponent?.isTitlePositionCenter,
        };
      }
      return component;
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <Popover
      open={isOptionsOpen}
      anchorEl={optionsEl}
      onClose={() => setOptionsEl(null)}
      className={styles.LogoPopup}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <div className="wrapper">
        <div className="logoTitleBox">
          <div className="logoTitle">Logo settings</div>
          <div>
            <img
              src="/images/close.svg"
              alt=""
              onClick={() => setOptionsEl(null)}
            />
          </div>
        </div>
        <hr />
        <div className="uploadLogoWrapper">
          <span>Logo</span>
          <div className="uploadLogo">
            <div className="chooseFile">
              <input
                type="file"
                style={{ width: 192 }}
                onChange={handleUploadLogo}
              />
            </div>
            {currentComponent?.logo && (
              <img
                src="/images/deleteLine.svg"
                height={18}
                width={18}
                color="#000000"
                alt="icon"
                style={{ marginRight: 5 }}
                onClick={handleOnRemove}
              />
            )}
          </div>
        </div>

        {/* Logo Guide */}
        <div className="infoBoxTop">
          <div className="infoTitle">
            <img src="/img/info.svg" alt="" />
            Logo Guide
          </div>
        </div>
        <div className="infoBoxBottom">
          <div className="LeftBox">
            <div className="titleBox">
              <span className="title">Aspect Ratio</span>
            </div>
            <div className="rightBox">
              <span>
                <span className="span1">Square</span>
                <span className="span2" style={{ paddingLeft: 5 }}>
                  1:1
                </span>
              </span>
              <span>
                <span className="span1">Rectangular</span>
                <span className="span2" style={{ paddingLeft: 5 }}>
                  Upto 2:1
                </span>
              </span>
            </div>
          </div>
          <div className="LeftBox">
            <div className="titleBox">
              <span className="title">Type</span>
            </div>
            <div className="rightBox2">
              <span className="span2">Jpg,Png</span>
            </div>
          </div>
          <div className="LeftBox">
            <div className="titleBox">
              <span className="title">Resolution</span>
            </div>
            <div className="rightBox3">
              <span>
                <span className="span1">Square</span>
                <br />
                <span className="span2">
                  500X500px To <br />
                  2000X2000px
                </span>
              </span>
              <br />
              <span>
                <span className="span1">Rectangular</span>
                <br />
                <span className="span2">
                  Height(Min) 128Px <br />
                  Width (Max) 2000Px
                </span>
              </span>
            </div>
          </div>
          <div className="LeftBox">
            <div className="titleBox">
              <span className="title">Size</span>
            </div>
            <div className="rightBox2">
              <span className="span2">Max 500Kb</span>
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="uploadLogoWrapper" style={{ margin: "20px 0px" }}>
          <span>Title</span>
          <div>
            <input
              name="logoTitle"
              type="text"
              style={{ paddingLeft: 5 }}
              className="uploadLogo"
              placeholder="No Title"
              defaultValue={currentComponent?.logoTitle}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="menuType">Title Position</label>
          <div
            className={styles.menuViewType}
            style={{ borderColor: "#D8D8D8", color: "#333333DE" }}
          >
            <span style={!isTitlePosCenter ? selectedTypeCss : {}}>Left</span>
            <Switch
              checked={isTitlePosCenter}
              onClick={handleChangeTitlePos}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span style={isTitlePosCenter ? selectedTypeCss : {}}>Center</span>
          </div>
        </div>
        <div className="uploadLogoWrapper">
          <span>Logo Position</span>
          <div>
            <TrueFalseButton
              leftButton={
                !currentComponent?.logoPosition ? (
                  <img src="/img/On-menu_active.svg" alt="" />
                ) : (
                  <img src="/img/On-menu_inactive.svg" alt="" />
                )
              }
              rightButton={
                currentComponent?.logoPosition ? (
                  <img src="/img/On-header_active.svg" alt="" />
                ) : (
                  <img src="/img/On-header_inactive.svg" alt="" />
                )
              }
              value={!currentComponent?.logoPosition}
              name="logoPosition"
            />
            <div className="headerTypes">
              <div>ON MENU</div>
              <div>ON HEADER</div>
            </div>
          </div>
        </div>
        <div className="uploadLogoWrapper" style={{ paddingBottom: 10 }}>
          <span>URL/Page Name</span>
          <div>
            <input
              name="logoUrl"
              type="text"
              className="uploadLogo"
              placeholder="URL/Page Name"
              onChange={handleChange}
              defaultValue={currentComponent?.logoUrl}
            />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default LogoPopup;
