import React from "react";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import settingStyles from "../../../styles/commonStyles/Setting";

const CustomAutocomplete = ({
  options,
  optionLabel,
  value,
  onChange,
  freeSolo,
  autoSelect,
  style = {},
  ...otherProps
}) => {
  const styles = settingStyles();
  return (
    <Autocomplete
      value={value}
      freeSolo={freeSolo}
      autoSelect={autoSelect}
      options={options}
      className={styles.autocomplete}
      style={style}
      getOptionLabel={optionLabel ? optionLabel : ""}
      onChange={(e, input) => {
        if (input) {
          onChange(input);
        } else {
          onChange("");
        }
      }}
      renderInput={(params) => <TextField {...params} variant="standard" />}
      {...otherProps}
    />
  );
};

export default CustomAutocomplete;