import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",
      "& tr": {
        color: "#FFFFFF",
      },
      "& th": {
        color: "#FFFFFF",
        lineHeight: "2.5rem",
        paddingLeft: "30px",
        padding: "0px",
      },
      "& th:last-child": {
        textAlign: "left",
      },
    },
    "& tbody": {
      marginLeft: "30px",
      "& tr .MuiTableCell-root": {
        padding: "3px 27px",
      },
      "& tr": {
        "& td:last-child": {
          textAlign: "center",
        },
      },
    },
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    padding: "0 15px",
    "&::before": {
      borderBottom: "unset",
    },
  },
  input2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    "&::before": {
      borderBottom: "unset",
    },
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  searchInput3: {
    height: 38,
    width: 355,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  nodata: {
    height: "6rem",
    position: "sticky",
    left: "45%",
    top: "3rem",
    paddingTop: "1rem",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
}));
