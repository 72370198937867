const serviceGroupColumns = [
  // { name: 'id', title: 'Service Group ID' },
  { name: "name", title: "Group Name", for: "servicegroup" },
  { name: "createdby", title: "Created By", for: "servicegroup" },
  { name: "createdon", title: "Created On", for: "servicegroup" },
  //   { name: "assignedTo", title: "Assign To", for: "servicegroup" },
  { name: "status", title: "Status", for: "servicegroup" },
  {
    name: "action",
    title: "Action",
    route: "/servicegroups/edit",
    for: "servicegroup",
  },
];

const serviceGroupDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  //   { columnName: "assignedTo", width: 180 },
  { columnName: "status", weightage: 3, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const serviceGroupTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  //   { columnName: "assignedTo", align: "center" },
  { columnName: "status", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const serviceGroupColumnOrder = [
  "name",
  "createdby",
  "createdon",
  "status",
  // "action",
];

const serviceGroupColumnHidden = [
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
];

const serviceGroupDefaultSorting = [];

export {
  serviceGroupColumns,
  serviceGroupDefaultColumnWidths,
  serviceGroupTableColumnExtensions,
  serviceGroupColumnOrder,
  serviceGroupDefaultSorting,
  serviceGroupColumnHidden
};
