import React, { useState, useEffect } from "react";
import {
  Grid,
  AccordionDetails,
  Typography,
  AccordionSummary,
  Accordion,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/ButtonOld";

import { useHistory } from "react-router-dom";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { handleFormValidationPro, initError } from "../../../utils/common";

// Redux
import { connect } from "react-redux";
import { postField } from "../../../redux/actions/dataStandardAction";

// Entity Schema
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForGlossaryFieldCreate from "../../../components/common/entitySchema/dataStandards/CustomFieldGroupsForGlossaryFieldCreate";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

const CreateField = (props) => {
  const { postField, fields } = props;
  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();
  const addAnother = true;
  const [loading, setLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [glossaryCreateEntity, setGlossaryCreateEntity] = useState({});

  const [fieldData, setFieldData] = useState({
    name: "",
    type: "",
    description: "",
    rules: {
      maxLength: "",
      minLength: "",
      pattern: "",
      contentEncoding: "",
      contentMediaType: "",
      minimum: "",
      maximum: "",
      multipleOf: "",
      exclusiveMinimum: "",
      exclusiveMaximum: "",
    },
  });
  const [error, setError] = useState({
    name: { isError: false },
    description: { isError: false },
    type: { isError: false },
    rules: {
      minLength: { isError: false },
      maxLength: { isError: false },
      pattern: { isError: false },
      contentEncoding: { isError: false },
      contentMediaType: { isError: false },
      minimum: { isError: false },
      maximum: { isError: false },
      multipleOf: { isError: false },
      exclusiveMinimum: { isError: false },
      exclusiveMaximum: { isError: false },
    },
  });
  const [isTwoTypeFields, setIsTwoTypeFields] = useState(0);
  var flag = 0;

  const { checkScreenPermission } = useCheckPermission();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    checkScreenPermission("GlossaryField", ["Create"]);
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryField",
        "create"
      );
      if (_status === 200) {
        setGlossaryCreateEntity(data);
        if (data) {
          for (
            let i = 0;
            i < data.sections[0].fieldgroups[0].fields.length;
            i++
          ) {
            if (data.sections[0].fieldgroups[0].fields[i].datakey === "type") {
              flag++;
            }
          }

          setIsTwoTypeFields(flag);
        }
        if (data) {
          const projData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.uiElementType === "Currency") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  projData[`${field.datakey}_unit`] = "";
                  projData[`${field.datakey}_value`] = "";
                  return true;
                }
                if (field.datakey) {
                  return (projData[field.datakey] = "");
                }
              });
            });
          });

          setFieldData(projData);
          const errData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  if (field.uiElementType === "Currency") {
                    errData[`${field.datakey}_unit`] = initError(field);
                    errData[`${field.datakey}_value`] = initError(field);
                    return true;
                  }
                  if (field.uiElementType === "Amount") {
                    errData[`${field.datakey}_unit`] = initError(field);
                    errData[`${field.datakey}_value`] = initError(field);
                    return true;
                  }
                  return (errData[field.datakey] = initError(field));
                }
              });
            });
          });
          setError(errData);
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    await fetchObjectDetails();
  }, []);

  // useEffect(() => {
  //   if (isTwoTypeFields === 1) {
  //
  //     enqueueSnackbar(
  //       "Field Type 'TYPE' is not present in Ontology Field. Defaulting to text Field Type.",
  //       { variant: "warning", autoHideDuration: 8000 }
  //     );
  //   }
  // }, [isTwoTypeFields]);

  useEffect(() => {
    let clearFlag = false;
    for (const [key, value] of Object.entries(fieldData.rules)) {
      if (value) clearFlag = true;
    }
    clearFlag ? setIsClear(true) : setIsClear(false);
  }, [fieldData]);

  useEffect(() => {
    clearRulesHandler();
    if (error.type) setError({ ...error, type: false });
  }, [fieldData.type]);

  // useEffect(() => {
  //   if (fieldData.rules.pattern) {
  //     setError({
  //       ...error,
  //       rules: {
  //         ...error.rules,
  //         pattern: fieldData.rules.pattern.length < 3 ? true : false,
  //       },
  //     });
  //   }
  // }, [fieldData.rules.pattern]);

  const handleChange = (event) => {
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setFieldData({
      ...fieldData,
      [event.target.name]: event.target.value,
    });
  };

  const clearRulesHandler = () => {
    setFieldData({
      ...fieldData,
      rules: {
        maxLength: "",
        minLength: "",
        pattern: "",
        contentEncoding: "",
        contentMediaType: "",
        minimum: "",
        maximum: "",
        multipleOf: "",
        exclusiveMinimum: "",
        exclusiveMaximum: "",
      },
    });
    setError({
      ...error,
      rules: {
        minLength: { isError: false },
        maxLength: { isError: false },
        pattern: { isError: false },
        contentEncoding: { isError: false },
        contentMediaType: { isError: false },
        minimum: { isError: false },
        maximum: { isError: false },
        multipleOf: { isError: false },
        exclusiveMinimum: { isError: false },
        exclusiveMaximum: { isError: false },
      },
    });
  };

  const handleSubmit = async (e, addAnother) => {
    let rulesInstance = {};
    for (const [key, value] of Object.entries(fieldData.rules)) {
      if (value) rulesInstance[key] = value;
    }
    fieldData.rules = rulesInstance;
    delete fieldData.status;
    delete fieldData.updatedby;
    delete fieldData.updatedon;
    delete fieldData.createdby;
    delete fieldData.createdon;
    if (!fieldData.rules) delete fieldData.rules;
    const { formIsValid, errors } = handleFormValidationPro(fieldData, error);
    if (formIsValid) {
      setLoading(true);
      const response = await postField(fieldData);
      const { _msg, _status, data } = response;
      if (_status === 201) {
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        setLoading(false);
        if (addAnother) {
          history.push("/data-standards");
          history.push("/create-fields");
        } else {
          history.push("/data-standards");
        }
      } else if (_status === 409) {
        setLoading(false);
        enqueueSnackbar("An entry with the same name already exists.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          data
            ? data[0]
              ? data[0]
              : "Something went wrong. Please contact Help desk."
            : _msg
            ? _msg
            : "Something went wrong. Please contact Help desk.",
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        "There are form field error(s), please correct the input values and submit",
        { variant: "error" }
      );
      setError(errors);
    }
  };

  const history = useHistory();

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Data Standards",
              path: "/data-standards",
            },
            { title: "Create Fields" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Create field</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AppButton
              style={{ padding: "0" }}
              className={`btnsmall`}
              variant="outlined"
              onClick={() => history.push("/data-standards")}
              buttonName="Cancel"
            />
            <AppButton
              style={{ marginLeft: "12px" }}
              className={`btnsmall`}
              variant="outlined"
              onClick={(e) => {
                handleSubmit(e, addAnother);
              }}
              buttonName="Create and Add Another"
            />
            <AppButton
              className={`btnsmall`}
              style={{ marginLeft: "10px" }}
              variant="contained"
              onClick={handleSubmit}
              buttonName="Create"
            />
          </div>
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {/* With Entity Schema */}
        {glossaryCreateEntity?.sections &&
          glossaryCreateEntity?.sections.map((section) => {
            if (section.name === "Glossary_Field_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldCreate
                    section={section}
                    isEditMode={true}
                    projectData={fieldData}
                    error={error}
                    isClear={isClear}
                    isTwoTypeFields={isTwoTypeFields}
                    clearRulesHandler={clearRulesHandler}
                    setFieldData={setFieldData}
                    handleChange={handleChange}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldCreate
                      section={section}
                      isEditMode={true}
                      projectData={fieldData}
                      isClear={isClear}
                      clearRulesHandler={clearRulesHandler}
                      error={error}
                      setFieldData={setFieldData}
                      handleChange={handleChange}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  postField,
};

const mapStateToProps = (state) => {
  const { fields } = state.dataStandardReducer;
  return { fields };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateField);
