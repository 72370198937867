/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { handleFormValidationPro, initError } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { v4 as uuidv4 } from "uuid";
import { fetchLanguagesListData } from "../../../utils/applicationGovernance/launguageUtils";
import TemplateEditEntity from "../../../components/common/entitySchema/applicationGovernance/chassisTemplate/TemplateEditEntity";
import {
  fetchChassisTemplateDetailsByID,
  updateDescription,
  updateBuildFile,
  updateBuildCommand,
  updateRunCommand,
  updateChassisTemplateGitUploadFile,
} from "../../../utils/applicationGovernance/chassisTemplateUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const CreateBoilerPlateScreen = (props) => {
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [BoilerPlateData, setBoilerPlateData] = useState({});
  const [error, setError] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [uploadTab, setUploadTab] = useState("Upload");
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const [templateID] = useState(props.match.params._id);
  const [BoilerPlateEntitySchema, setBoilerPlateEntitySchema] = useState({});
  const [launguageList, setlaunguageList] = useState([]);
  const [uploadModal, setUploadModal] = useState(true);
  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };
  const [imageDetails, setImageDetails] = useState({
    imagename: "",
  });
  const [gitError, setGitError] = useState({
    Url: false,
  });
  const [gitSouceCodeUpload, setGitSouceCodeUpload] = useState({
    branch: "",
    isPublic: true,
    Url: "",
    token: "",
  });

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.goBack();
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Template", ["Edit"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchBoilerPlateEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Template",
        "edit"
      );
      if (_status === 200) {
        setBoilerPlateEntitySchema(data);
        fetchData();
        const bPlateData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (bPlateData[field.datakey] = "");
              }
            });
          });
        });
        setBoilerPlateData({ ...BoilerPlateData, ...bPlateData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError(errData);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchData = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchChassisTemplateDetailsByID(
        templateID
      );
      if (_status === 200) {
        setBoilerPlateData(() => (data ? data : []));
        setEnvs(data.environmentVariable ? data?.environmentVariable : []);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchData();

    fetchBoilerPlateEntitySchema();
  }, []);

  useEffect(() => {
    if (
      BoilerPlateData &&
      BoilerPlateData?.filename &&
      BoilerPlateData.filename !== ""
    ) {
      let err = error;
      delete err.docker;
      setError(err);
    }
  }, [BoilerPlateData]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setBoilerPlateData({
      ...BoilerPlateData,
      [event.target.name]: value,
    });
  };

  // ENV
  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "" }]);
  };

  const handleEnvChange = (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event.target.value };
      } else {
        return env;
      }
    });
    setEnvs(newEnvs);
  };
  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  // TAB
  const handleUploadSourceTab = (event, newValue) => {
    setUploadTab(newValue);
    if (newValue === "URL") {
      let tempError = { ...error };
      delete tempError["docker"];
      if (BoilerPlateData.isPublic) {
        setError({
          ...tempError,
          Url: {
            dataType: "string",
            isError: false,
            minLength: 1,
            minimum: 1,
            maxLength: 350,
            maximum: 300,
            msg: "",
            fieldLabel: "Url",
            section: 1,
          },
        });
      } else {
        setError({
          ...tempError,
          Url: {
            dataType: "string",
            isError: false,
            minLength: 1,
            minimum: 1,
            maxLength: 350,
            maximum: 300,
            msg: "",
            fieldLabel: "Url",
            section: 1,
          },
          token: {
            dataType: "string",
            isError: false,
            minLength: 1,
            minimum: 1,
            maxLength: 350,
            maximum: 300,
            msg: "",
            fieldLabel: "Token",
            section: 1,
          },
        });
      }
    } else {
      let tempError = { ...error };
      delete tempError["Url"];
      delete tempError["token"];
      setError({
        ...tempError,
        docker: {
          dataType: "string",
          isError: false,
          minLength: 1,
          minimum: 1,
          maxLength: 350,
          maximum: 300,
          msg: "",
          fieldLabel: "docker",
          section: 1,
        },
      });
    }
  };

  const handleUpload = (files) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: files ? (files[0] ? files[0].name : "") : "",
      };
    });
    setBoilerPlateData({ ...BoilerPlateData, docker: files[0]?.name });
    setDroppedDocument(files);
    setError({ ...error, droppedDocument: false });
  };

  const handleImageNameChange = (e) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: e.target.value,
      };
    });
  };

  const handleDeleteClick = () => {
    setImageDetails({
      imagename: "",
    });
    setDroppedDocument([]);
  };
  const handleGitSourceUpload = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      BoilerPlateData,
      error
    );
    if (formIsValid) {
      const GitData = {
        repositoryDetails: {
          branch: BoilerPlateData.branch,
          Url: BoilerPlateData.Url,
          token: BoilerPlateData.token,
        },
        Url: BoilerPlateData.Url,
        ChassisTemplate: BoilerPlateData.ChassisTemplate,
      };
      const { _msg, _status, data } = await updateChassisTemplateGitUploadFile(
        BoilerPlateData._id,
        GitData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        histroy.push(`/chassistemplate-details/${BoilerPlateData._id}`);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        BoilerPlateEntitySchema ? BoilerPlateEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setBoilerPlateEntitySchema({
        ...BoilerPlateEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      BoilerPlateData,
      error
    );

    if (formIsValid) {
      var temp_env = [];
      envs.length > 0 &&
        envs.forEach((item) => {
          if (item.key.length !== 0 && item.value.length !== 0) {
            temp_env.push(item);
          }
        });

      const metaData = {
        ...BoilerPlateData,
        ChassisTemplate: BoilerPlateData?.chassistemplate,
        description: BoilerPlateData.description,
        environmentVariable: temp_env ? temp_env : "",
      };
      delete metaData["docker"];
      delete metaData["_id"];
      delete metaData["docker"];
      delete metaData["dockerbuildcommands"];
      delete metaData["dockerruncommands"];
      delete metaData["_id"];
      setIsLoader(true);
      // description
      const { _msg, _status, data } = await updateDescription(
        templateID,
        metaData
      );
      // Build CMD

      if (BoilerPlateData.dockerbuildcommands) {
        const { _msg, _status } = await updateBuildCommand(templateID, [
          BoilerPlateData.dockerbuildcommands,
        ]);
        if (_status !== 201) {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }

      // Run CMD

      if (BoilerPlateData.dockerruncommands) {
        const { _msg, _status } = await updateRunCommand(templateID, [
          BoilerPlateData.dockerruncommands,
        ]);
        if (_status !== 201) {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }

      // Build File
      if (droppedDocument[0]) {
        var buildFile = new FormData();
        buildFile.append("file", droppedDocument[0]);
        await updateBuildFile(templateID, buildFile);
      }
      if (_status < 210) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setIsLoader(false);

        histroy.push(`/chassistemplate-details/${templateID}`);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        BoilerPlateEntitySchema ? BoilerPlateEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setBoilerPlateEntitySchema({
        ...BoilerPlateEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form
        onSubmit={() =>
          (uploadTab === "Upload" && handleSubmit()) ||
          (uploadTab === "URL" && handleGitSourceUpload())
        }
      >
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Application Governance",
                path: "/application-governance",
              },
              {
                title: "Chassis Template",
                path: "/application-governance",
              },
              {
                title: `${
                  BoilerPlateData?.ChassisTemplate
                    ? BoilerPlateData?.ChassisTemplate
                    : ""
                }`,
              },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={
              tabbedView
                ? { borderBottom: "2px solid #afafaf", marginBottom: "0px" }
                : { borderBottom: "2px solid #afafaf" }
            }
          >
            <h2 className={styles.title}>
              {BoilerPlateData.chassistemplate
                ? `Edit ${BoilerPlateData.chassistemplate}`
                : ""}
            </h2>
            {tabbedView ? (
              <TabbedNavigation
                isEditMode={true}
                activeTab={activeTab}
                handleSave={() =>
                  (uploadTab === "Upload" && handleSubmit()) ||
                  (uploadTab === "URL" && handleGitSourceUpload())
                }
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                isEditMode={false}
                disabled={false}
                handleSave={() =>
                  (uploadTab === "Upload" && handleSubmit()) ||
                  (uploadTab === "URL" && handleGitSourceUpload())
                }
                handleCancel={handleCancel}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <div style={{ paddingTop: "14px" }}>
                <SectionTabs
                  entity={
                    BoilerPlateEntitySchema
                      ? BoilerPlateEntitySchema.sections
                      : []
                  }
                  activeTab={activeTab}
                  handleActiveTab={handleActiveTab}
                />
              </div>

              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <TemplateEditEntity
                    section={BoilerPlateEntitySchema?.sections?.[activeTab]}
                    BoilerPlateData={BoilerPlateData}
                    setBoilerPlateData={setBoilerPlateData}
                    error={error}
                    setError={setError}
                    handleChange={handleChange}
                    handleDeleteClick={handleDeleteClick}
                    customdropzoneImage={customdropzoneImage}
                    handleUpload={handleUpload}
                    uploadTab={uploadTab}
                    setUploadTab={setUploadTab}
                    handleUploadSourceTab={handleUploadSourceTab}
                    droppedDocument={droppedDocument}
                    setDroppedDocument={setDroppedDocument}
                    handleImageNameChange={handleImageNameChange}
                    imageDetails={imageDetails}
                    launguageList={launguageList}
                    gitSouceCodeUpload={gitSouceCodeUpload}
                    setGitSouceCodeUpload={setGitSouceCodeUpload}
                    uploadModal={uploadModal}
                    setUploadModal={setUploadModal}
                    gitError={gitError}
                    setGitError={setGitError}
                    setIsLoader={setIsLoader}
                    envs={envs}
                    handleAddEnv={handleAddEnv}
                    handleEnvChange={handleEnvChange}
                    handleDeleteEnv={handleDeleteEnv}
                  />
                </div>
              </Paper>
            </>
          ) : (
            BoilerPlateEntitySchema?.sections &&
            BoilerPlateEntitySchema?.sections.map((section, index) => {
              if (section.name === "Template_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <TemplateEditEntity
                      section={section}
                      BoilerPlateData={BoilerPlateData}
                      setBoilerPlateData={setBoilerPlateData}
                      error={error}
                      setError={setError}
                      handleChange={handleChange}
                      handleDeleteClick={handleDeleteClick}
                      customdropzoneImage={customdropzoneImage}
                      handleUpload={handleUpload}
                      uploadTab={uploadTab}
                      setUploadTab={setUploadTab}
                      handleUploadSourceTab={handleUploadSourceTab}
                      droppedDocument={droppedDocument}
                      setDroppedDocument={setDroppedDocument}
                      handleImageNameChange={handleImageNameChange}
                      imageDetails={imageDetails}
                      launguageList={launguageList}
                      gitSouceCodeUpload={gitSouceCodeUpload}
                      setGitSouceCodeUpload={setGitSouceCodeUpload}
                      uploadModal={uploadModal}
                      setUploadModal={setUploadModal}
                      gitError={gitError}
                      setGitError={setGitError}
                      setIsLoader={setIsLoader}
                    />
                  </div>
                );
              } else {
                return (
                  <Element name={index}>
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TemplateEditEntity
                          section={section}
                          BoilerPlateData={BoilerPlateData}
                          setBoilerPlateData={setBoilerPlateData}
                          error={error}
                          setError={setError}
                          handleChange={handleChange}
                          handleDeleteClick={handleDeleteClick}
                          customdropzoneImage={customdropzoneImage}
                          handleUpload={handleUpload}
                          uploadTab={uploadTab}
                          setUploadTab={setUploadTab}
                          handleUploadSourceTab={handleUploadSourceTab}
                          droppedDocument={droppedDocument}
                          setDroppedDocument={setDroppedDocument}
                          handleImageNameChange={handleImageNameChange}
                          imageDetails={imageDetails}
                          launguageList={launguageList}
                          envs={envs}
                          handleAddEnv={handleAddEnv}
                          handleEnvChange={handleEnvChange}
                          handleDeleteEnv={handleDeleteEnv}
                          gitSouceCodeUpload={gitSouceCodeUpload}
                          setGitSouceCodeUpload={setGitSouceCodeUpload}
                          uploadModal={uploadModal}
                          setUploadModal={setUploadModal}
                          gitError={gitError}
                          setGitError={setGitError}
                          setIsLoader={setIsLoader}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Element>
                );
              }
            })
          )}
          {}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBoilerPlateScreen);
