import {
  ADD_USER_DATA,
  USERS_FILTER,
  DELETE_USER,
  USER_SORT,
  BLOCK_UNBLOCK_USER,
  CLEAR_USER_DATA,
  USER_HIDDEN_COULMNS,
  ALTER_VIEW,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  user_list: {
    data: [],
    error: false,
    loading: false,
  },
  userFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  userSort: [{ columnName: "createdon", direction: "desc" }],
  userHiddenCols: [
    "name",
    "userteams",
    "email",
    "projectAssigned",
    "userActions",
  ],
  tabbedView:true,
};

export default function userInfoReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_DATA: {
      return {
        ...state,
        user_list: {
          ...state.user_list,
          ...action.payload,
          deleteUserStatus: false,
        },
      };
    }
    case USERS_FILTER: {
      return {
        ...state,
        userFilters: [...action.payload],
      };
    }
    case USER_SORT: {
      return {
        ...state,
        userSort: [...action.payload],
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        user_list: {
          ...state.user_list,
          data:
            state.user_list.data &&
            state.user_list.data.filter(
              (user) => user._id !== action.payload.userID
            ),
          _totalcount: state.user_list._totalcount - 1,
          deleteUserStatus: true,

        },
      };
    }
    case BLOCK_UNBLOCK_USER: {
     
      return {
        ...state,
        user_list: {
          ...state.user_list,
          data:
            state.user_list.data &&
            state.user_list.data.map((item) => {
              if (item._id === action.payload.userID) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case CLEAR_USER_DATA: {
      return {
        ...state,
        user_list: {},
      };
    }
    case USER_HIDDEN_COULMNS: {
      return {
        ...state,
        userHiddenCols: [...action.payload],
      };
    }

    case ALTER_VIEW: {
      return {
        ...state,
        tabbedView: action.payload,
      };
    }

    default:
      return state;
  }
}
