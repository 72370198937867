import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

import { getKeyValueFromStringJSON } from "../../../utils/orchestration/orchestrationUtils";
import InputTable from "../../common/Tables/InputTable";
import { AppButton } from "../../common/Button";

const temp_data = [
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), key: "", value: "" },
  { id: uuidv4(), kay: "", value: "" },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  line1: {
    fontSize: "14px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    width: "750px",
    height: "400px",
    marginTop: "0.5rem",
  },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function AddEnvironmentServicesModal(props) {
  const { isOpen } = props;
  const [envs, setEnvs] = useState(temp_data);
  const classes = useStyles();
  useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
    // }
  }, []);

 
  useEffect(() => {
    const data_arr = getKeyValueFromStringJSON(props.envs);
    if (data_arr.length > 0) {
      setEnvs(data_arr);
    } else {
      setEnvs(temp_data);
    }
  }, []);

  const descriptionElementRef = React.useRef(null);

  const handleSubmitClick = () => {
    const services = {};
    envs &&
      envs.length > 0 &&
      envs.forEach((obj) => {
        if (obj.key !== "" && obj.value !== "") {
          services[obj.key] = obj.value;
        }
      });
    props.setEnvs({ id: props.id, environment: services });
  };

  return (
    <div className={classes.container}>
      <Dialog
        // onEnter={fetchData}
        open={isOpen}
        // scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <DialogTitle className={classes.header} id="scroll-dialog-title">
          <p className={classes.heading}>Add Environment Variables</p>
        </DialogTitle>
        <DialogContent dividers={true}>
          {/* <text className={classes.line1}>Environment</text> */}
          <div className={classes.table}>
            <InputTable
              envs={envs}
              setEnvs={(data) => setEnvs(data)}
              disabled={props.disabled}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleSubmitClick}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          {!props.disabled && (
            <AppButton
              onClick={handleSubmitClick}
              color="primary"
              buttonName="Submit"
              variant="contained"
              className="btnsmall"
              style={{ margin: "unset" }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
