import React, { useState, useEffect } from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VersionTable from "./VersionTable";

import { connect } from "react-redux";
import { add_version_list_for_service } from "../../../../redux/actions/applicationAction";

import useStyles from "./Styles";

function CreateApplicationRow(props) {
  const {
    row,
    add_version_list_for_service,
    service_list,
    isEditMode,
    applicationName,
  } = props;
  const [versions, setVersions] = useState([]);
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  useEffect(() => {
    const { _id } = row;
    service_list.forEach((item) => {
      if (item._id === _id) {
        setVersions(item.versions);
      }
    });
  });

  const handleRowOpen = async (_id) => {
    if (!open) {
      await add_version_list_for_service(_id);
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };

  const {
    name,
    _id,
    projectName,
    servicegroupName,
    imagename,
    os,
    serviceType,
  } = row;
  return (
    <React.Fragment>
      <TableRow className={styles.root}>
        {/* <TableCell>
           <Checkbox size="small"
            value={name}
            onChange={handleCheckBox}
            style={{ color: "#002855" }}
          /> 
        </TableCell>  */}
        <TableCell style={{ paddingLeft: "4rem" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowOpen(_id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {name}
        </TableCell>
        <TableCell component="th" scope="row">
          {imagename}
        </TableCell>
        <TableCell>{projectName}</TableCell>
        <TableCell>{serviceType}</TableCell>
        <TableCell>{}</TableCell>
      </TableRow>
      <VersionTable
        open={open}
        versions={versions}
        service_id={row._id}
        serviceType={serviceType}
        isEditMode={isEditMode}
        applicationName={applicationName}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  add_version_list_for_service,
};

const mapStateToProps = (state, ownProps) => {
  const { data } = state.applicationReducer.services_list;
  return {
    service_list: data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateApplicationRow);
