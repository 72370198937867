import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
// import { VendorServices } from "../../../services/vendorServices";
// const VendorService = new VendorServices();

export default function BlockModal(props) {
  const { row, open, handleClose, handleBlock } = props;
  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          <div
            style={{
              height: 170,
              width: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src="/images/block.svg"
                alt="lock"
                height="76px"
                width="76px"
                style={{ opacity: "1" }}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p>
                Are you sure you want to <b>Block</b> the {row.name} ?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName="Cancel"
            variant="outlined"
          />
          <AppButton
            onClick={handleBlock}
            className={`btnsmall`}
            buttonName={"Yes, Block it"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
