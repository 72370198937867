import { dockerImageServices } from "../../services/dockerImageServices";

const DockerImageService = new dockerImageServices();

export const createImage = async (req_data) => {
  try {
    const res_data = await DockerImageService.createdockerImage(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const imageDetails = async (imageId) => {
  try {
    const res_data = await DockerImageService.getdockerImageDetailsByID(
      imageId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getDockerImageById = async (dockerImageId) => {
  try {
    const res_data = await DockerImageService.getDockerImageById(
      dockerImageId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};



export const DeleteDockerImage = async (imgID) => {
  try {
    const res_data = await DockerImageService.DeleteDockerImage(imgID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const editdockerImageByID = async (imgID, EditdockerImageData) => {
  try {
    const res_data = await DockerImageService.editdockerImageByID(
      imgID,
      EditdockerImageData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getDockerImageBuildStatus = async (serviceId, versionId) => {
  try {
    const res_data = await DockerImageService.getDockerImageBuildStatus(
      serviceId,
      versionId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const uploadLargeDockerImage = async (dockerImageMeta) => {
  try {
    const res_data = await DockerImageService.uploadLargeDockerImage(
      dockerImageMeta
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const startDockerFileUpload = async (id, filename) => {
  try {
    const res_data = await DockerImageService.startDockerFileUpload(
      id,
      filename
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const uploadChunkforDockerImage = async (chunkFormData) => {
  try {
    const res_data = await DockerImageService.uploadChunkforDockerImage(
      chunkFormData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
