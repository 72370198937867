import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  table: {
    height: "calc(100% - 90px)",
    overflow: "auto",
    padding: 0,
    borderBottom: "1px solid #D8D8D8",
    backgroundColor: "#FFFFFF",

    "& thead": {
      border: "1px solid #E2E2E3",

      "& th": {
        font: "normal normal 600 14px/19px Nunito",
        background: (props) => `${props.status} 0% 0% no-repeat padding-box`,
        color: "#FFFFFF",
        padding: 12,
        lineHeight: "18px",
        // border: 'unset'

        "& span": {
          color: "#FFFFFF !important",

          "& svg": {
            color: "#FFFFFF !important",
          },
        },
      },
    },
    "& tbody": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #DFE3E6",

      "& tr": {
        "&:hover": {
          background: "#EFF1F2 0% 0% no-repeat padding-box",
          border: "1px solid #EFF1F2",
        },

        "& td": {
          font: "normal normal normal 14px/18px Nunito",
          color: "#262626",
          padding: "10px 12px",
          lineHeight: "14px",
        },
      },
    },
    "& .actionColumn": {
      position: "sticky",
      right: 0,
      borderLeft: "1px solid #EFF1F2",
      backgroundColor: "#FFFFFF",

      "& .icons": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  header: {
    minHeight: "2.5rem",
    backgroundColor: "#FFFFFF",

    "& h1": {
      font: "normal normal 500 18px/22px Nunito",
      color: "#262626",
      position: "absolute",
      top: 0,
      left: 10,
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px 15px",
    backgroundColor: "#FFFFFF",

    "& .itemsCount": {
      font: "normal normal normal 14px/18px Nunito",
      color: "#262626",
    },

    "& .pagination": {
      padding: 2,
      overflow: "hidden",
      border: "1px solid #F0F0F0",
      borderRadius: 3,

      "& .Mui-selected": {
        backgroundColor: (props) => `${props.status} `,
        color: "#FFFFFF",
      },
    },
  },

  // Menu
  menu: {
    width: "100%",
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& .Mui-expanded": {
      margin: "0 !important",
    },
    "& hr": {
      border: "0.5px solid #F0F0F0",
      margin: "5px 0 0 0",
    },
  },
  menuHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    color: "white",
  },
  logoTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuList: {
    transition: "all 0.5s ease-in-out",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px !important",
    },
  },

  subMenu: { padding: "5px 15px" },
  filters: {
    padding: 0,
    "& .filtersWrap": {
      padding: 10,
      display: "flex",

      "& .field": {
        margin: 5,

        "& label": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#333333DE",
        },
      },
    },
  },
  // Toggle
  toggle: {},

  // DatePicker
  DatePicker: {
    padding: "5px 10px 0px 10px",
    "& .MuiInput-underline:before": { border: "none" },
    "& .MuiInput-underline:hover": { border: "none" },
    "& .MuiInput-underline:after": { border: "none" },
    // Custom
    "& .Date": {
      backgroundColor: "#fff",
      "& .MuiFormControl-root": {
        paddingLeft: "20px",
      },
    },
  },
  // Tree
  treeWrapper: {
    backgroundColor: "white",
    height: "100%",
    overflow: "auto",
    padding: "0 10px",
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& .title": {
      padding: "10px 0px",
    },
  },
  tree: {
    height: "calc(100% - 40px)",
    overflow: "auto",
    padding: 0,
    borderBottom: "1px solid #D8D8D8",
    backgroundColor: "#FFFFFF",

    "& thead": {
      border: "1px solid #E2E2E3",

      "& th": {
        font: "normal normal 600 14px/19px Nunito",
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
        color: "#FFFFFF",
        padding: 12,
        lineHeight: "18px",
        // border: 'unset'
      },
    },
    "& tbody": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #DFE3E6",

      "& tr": {
        "&:hover": {
          background: "#EFF1F2 0% 0% no-repeat padding-box",
          border: "1px solid #EFF1F2",
        },

        "& td": {
          font: "normal normal normal 14px/18px Nunito",
          color: "#262626",
          padding: "10px 12px",
          lineHeight: "14px",
        },
      },
    },
    "& .actionColumn": {
      position: "sticky",
      right: 0,
      borderLeft: "1px solid #EFF1F2",
      backgroundColor: "#FFFFFF",

      "& .icons": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  //Details
  detailContainer: {
    overflow: "auto",
    height: "100%",
    backgroundColor: "#fff",
    padding: 5,
  },
  titleContainer: {
    display: "flex",
    minHeight: "2.5rem",
    justifyContent: "space-between",

    "& h1": {
      font: "normal normal 500 18px/22px Nunito",
      color: "#262626",
      padding: "4px 0px 0px 4px",
      margin: 0,
    },
  },
}));
