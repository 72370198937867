import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  tagContainer: {
    display: "flex",
    alignItems: "Center",
    justifyContent: "space-between",
    border: "1px solid #002855",
    borderRadius: 5,
    padding: 2,
    height: 32,
    marginRight: 10,
    width: 120,
    color: "#002855",
  },
  titleSpan: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "60px",
    fontWeight: 700,
    overflow: "hidden",
  },
  cancelIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
