/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../common/styles/InputTableStyle";
import { connect } from "react-redux";
import {
  selectProcessCheck,
} from "../../../redux/actions/deploymentProcessAction";
const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",
      "& tr": {
        color: "#FFFFFF",
      },
      "& th": {
        color: "#FFFFFF",
        lineHeight: "2.5rem",
        paddingLeft: "30px",
        padding: "0px",
      },
      "& th:last-child": {
        textAlign: "left",
      },
    },
    "& tbody": {
      marginLeft: "30px",
      "& tr .MuiTableCell-root": {
        padding: "15px 27px",
      },
      "& tr": {
        "& td:last-child": {
          textAlign: "center",
        },
      },
    },
  },
}));

function DeploymentApplicationProcessTable({ process, selectProcessCheck }) {
  const tStyles = tempStyles();
  const styles = useStyles();

  const handleSelectChecked = (id, checked) => {
    selectProcessCheck({ id: id, checked: checked });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        {/* {loading && <Loader />} */}
        <Paper>
          <TableContainer>
            <Table className={tStyles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Process name</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>Endpoint</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {process &&
                  process.length > 0 &&
                  process.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                      >
                        {row?.method}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "18rem" }}
                      >
                        {row?.endpoint}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {process?.length === 0 && (
                <div className={styles.nodata}>
                  <text>No Data Available</text>
                </div>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  selectProcessCheck,
};

const mapStateToProps = (state) => {
  const { process } = state.deploymentProcessReducer.dependenciesData;
  return {
    process,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentApplicationProcessTable);
