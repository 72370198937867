import { CHANGE_STATUS_FAIL, CHANGE_STATUS_REQUEST, CHANGE_STATUS_SUCCESS, STATUS_DETAILS_FAIL, STATUS_DETAILS_REQUEST, STATUS_DETAILS_SUCCESS } from "../../constants/UserProfile/user"

// selcting colors for the entire theme
export const statusDetailsReducer = (state = { }, action) => {
    switch (action.type) {
        case STATUS_DETAILS_REQUEST:
            return { loading: true }
        case STATUS_DETAILS_SUCCESS:
            return { loading: false, success: true, status: action.payload }
        case STATUS_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default: return state
    }
}
// for setting the new status
export const setStatusReducer = (state = { }, action) => {
    switch (action.type) {
        case CHANGE_STATUS_REQUEST:
            return { loading: true }
        case CHANGE_STATUS_SUCCESS:
            return { loading: false, success: true}
        case CHANGE_STATUS_FAIL:
            return { loading: false, error: action.payload }
        default: return state
    }
}