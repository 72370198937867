const containerColumns = [
  {
    name: "containername",
    title: "Container Name",
    route: "container-details",
    for: "container",
  },
  { name: "containerState", title: "State", for: "container" },
  { name: "createdby", title: "Created By", for: "container" },
  { name: "createdon", title: "Created On", for: "container" },
  { name: "containeractions", title: "Action" },
];

const containerDefaultColumnWidths = [
  { columnName: "containername", weightage: 2, width: 200 },
  { columnName: "containerState", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "containeractions", weightage: 2, width: 200 },
];

const containerTableColumnExtensions = [
  { columnName: "containername", align: "left" },
  { columnName: "containerState", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "containeractions", align: "center", sortingEnabled: false },
];

const containerColumnOrder = [
  "containername",
  "containerState",
  "createdby",
  "createdon",
];

const containerDefaultSorting = [
  { columnName: "containername", direction: "asc" },
];

const containerColumnHidden = [
  "containername",
  "containerState",
  "createdby",
  "createdon",
  "containeractions",
];
export {
  containerColumns,
  containerDefaultColumnWidths,
  containerTableColumnExtensions,
  containerColumnOrder,
  containerDefaultSorting,
  containerColumnHidden,
};