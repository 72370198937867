import React from "react";
import tabbedStyle from "../../screens/CommonStyles/tabbedStyles";
import { AppButton } from "../common/Button";

const TabbedNavigation = ({
  handleSave,
  handleCancel,
  handleEdit,
  isEditMode,
  isCreateMode,
  isClose,
  removeCancel,
  disabled,
}) => {
  const tabStyle = tabbedStyle();
  return (
    <div
      className={tabStyle.button}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* {activeTab !== 0 && (
        <span style={{ marginLeft: 20 }}>
          <Button
            onClick={handlePrev}
            aria-label="previous"
            variant="outlined"
            startIcon={
              <>
                <NavigateBeforeIcon style={{ width: "25px", height: "23px" }} />
              </>
            }
          />
        </span>
      )} */}
      <span style={{ marginLeft: 20 }}>
        {isCreateMode || isEditMode ? (
          <AppButton
            color="primary"
            buttonName={isCreateMode ? "Create" : "Save"}
            variant="contained"
            disabled={disabled ? disabled : false}
            style={{ margin: "unset" }}
            className="btnsmall"
            onClick={handleSave}
          />
        ) : (
          <AppButton
            color="primary"
            buttonName="Edit"
            variant="contained"
            disabled={disabled ? disabled : false}
            style={{ margin: "unset" }}
            className="btnsmall"
            onClick={handleEdit}
          />
        )}
      </span>
      {!removeCancel && (
        <span style={{ marginLeft: 20 }}>
          <AppButton
            color="primary"
            buttonName={isClose ? "Close" : "Cancel"}
            variant="outlined"
            disabled={false}
            style={{ margin: "unset" }}
            className="btnsmall"
            onClick={handleCancel}
          />
        </span>
      )}

      {/* {schemaLength !== (activeTab + 1) && (
        <span style={{ marginLeft: 20 }}>
          <Button
            onClick={handleNext}
            aria-label="prev"
            variant="outlined"
            color="primary"
            startIcon={
              <>
                <NavigateNextIcon style={{ width: "25px", height: "23px" }} />
              </>
            }
          />
        </span>
      )} */}
    </div>
  );
};

export default TabbedNavigation;
