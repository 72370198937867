import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";
import EditorInput from "../../../atoms/customEditor/EditorInput";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";

function CustomComponentSettings() {
  const { currentPage, currentComponent, globalHandleChange } = useGetData();
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleCodeChange = (code) => {
    const components = currentPage?.components.map((component) => {
      if (component.id === currentComponent?.id) {
        return {
          ...component,
          code: code,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <JSDataInput
          value={currentComponent?.data}
          onChange={(value) => globalHandleChange(value, "data", setError)}
          label="Data"
          title={`${currentComponent.name} : Data`}
          error={error}
        />
      </div>
      <div className="field">
        <label>Code</label>
        <EditorInput
          isLint
          value={currentComponent?.code}
          onChange={handleCodeChange}
          title={`${currentComponent.name} : Code`}
          mode="htmlmixed"
        />
      </div>
    </div>
  );
}

export default CustomComponentSettings;