import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Backdrop,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { AppButton } from "../Button";
import { useSnackbar } from "notistack";
import CreateCustomerStyles from "../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import {
  AZURE_REGION_LIST,
  GCP_REGION_LIST,
} from "../../../screens/Organization/editOrganization/regionData";
import { handleFormValidationPro } from "../../../utils/common";
import { Autocomplete } from "@material-ui/lab";
import Eye from "../../../screens/Login2FA/Assets/eye.svg";
import EyeSlash from "../../../screens/Login2FA/Assets/eye-slash.svg";

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: theme.palette.background.default,
    color: "#000000",
    width: "100%",
    margin: 0,
    padding: "10px 24px",

    "& h2": {
      font: "normal normal 600 16px/22px Nunito",
    },
  },
  modalContent: {
    padding: "15px 24px 48px",
  },
  modalLabel: {
    font: "normal normal 600 14px/16px Nunito",
    color: "#333333DE",
    marginTop: 10,
  },
  input: {
    width: "100%",
    height: 37,
    // backgroundColor: "#fff",
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  icon: {
    height: "1.1rem",
  },
  eye: {
    cursor: "pointer",
    marginLeft: -26,
    marginTop: -5.3,
  },
}));

const CloudConfigurationModal = (props) => {
  const {
    handleSubmitCloudData,
    cloudData,
    isEditMode,
    setOpenModal,
    allConfigData,
    AWSRegionList,
  } = props;
  const [organizationData, setOrganizationData] = useState({});
  const styles2 = useStyles();
  const styles = CreateCustomerStyles();

  const [containerName, setContainerName] = useState(props.containerName);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [cloudProvider, setCloudProvider] = useState("");

  // useEffect(() => {
  //   const getAWSRegion = async () => {
  //     const {data, _status, _msg} = await getAWSRegionList()
  //     'datacloudocnfing', data)
  //   }
  //   getAWSRegion()
  // }, [])

  const [error, setError] = useState({
    configurationname: {
      dataType: "string",
      fieldLabel: "Configuration Name",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    cloudprovider: {
      dataType: "string",
      fieldLabel: "Cloud Provider",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    region: {
      dataType: "string",
      fieldLabel: "Region",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
  });

  const [toggleShow, setToggleShow] = useState(false);

  useEffect(() => {
    setCloudProvider(cloudData?.cloudprovider);
    if (cloudData) {
      setOrganizationData(cloudData);

      if (
        cloudData?.cloudprovider === "AWS" ||
        cloudData?.cloudprovider === "Integrum"
      ) {
        setError({
          ...error,
          accesskeyid: {
            dataType: "string",
            fieldLabel: "Access Key Id",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
          secretaccesskey: {
            dataType: "string",
            fieldLabel: "Secret Access Key",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
        });
      } else if (cloudData?.cloudprovider === "Azure") {
        setError({
          ...error,
          azuretenantid: {
            dataType: "string",
            fieldLabel: "Azure Tenant ID",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
          azureclientid: {
            dataType: "string",
            fieldLabel: "Azure Client ID",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
          azureclientsecret: {
            dataType: "string",
            fieldLabel: "Azure Client Secret",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
        });
      } else if (cloudData?.cloudprovider === "GCP") {
        setError({
          ...error,
          gcptoken: {
            dataType: "string",
            fieldLabel: "GCP Token",
            isError: false,
            maxLength: 300,
            maximum: 300,
            minLength: 1,
            minimum: 1,
          },
        });
      }
    }
    if (!isEditMode) {
      setCloudProvider(null);
    }
  }, [cloudData]);

  const handleChangeCloud = (event) => {
    const value = event.target.value;
    setOrganizationData({
      ...organizationData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = () => {
    let checkConfigName = organizationData?.configurationname;

    let isDuplicateError = "";

    const isFound = allConfigData?.filter(
      (item) => item.configurationname === checkConfigName
    );

    let serializeData = {
      ...organizationData,
      configurationname: organizationData?.configurationname
        ? organizationData?.configurationname?.replace(/\s+/g, " ").trim()
        : "",
      cloudprovider: cloudProvider ? cloudProvider : "",
    };

    const { formIsValid, errors } = handleFormValidationPro(
      serializeData,
      error
    );

    if (!formIsValid) {
      setError(errors);
    } else {
      let actionType = cloudData ? "update" : "add";
      handleSubmitCloudData(serializeData, actionType, organizationData?._id);
      setOpenModal(false);
    }
  };

  const handleChangeCloudProvider = (e) => {
    setCloudProvider(e.target.value);
    if (e.target.value === "AWS" || e.target.value === "Integrum") {
      setOrganizationData({
        configurationname: organizationData?.configurationname,
        secretaccesskey: "",
        region: "",
        accesskeyid: "",
      });

      setError({
        ...error,
        accesskeyid: {
          dataType: "string",
          fieldLabel: "Access Key Id",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        secretaccesskey: {
          dataType: "string",
          fieldLabel: "Secret Access Key",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
      });
    } else if (e.target.value === "Azure") {
      setOrganizationData({
        configurationname: organizationData?.configurationname,
        azuretenantid: "",
        region: "",
        azureclientid: "",
        azureclientsecret: "",
      });

      setError({
        ...error,
        azuretenantid: {
          dataType: "string",
          fieldLabel: "Azure Tenant ID",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        azureclientid: {
          dataType: "string",
          fieldLabel: "Azure Client ID",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        azureclientsecret: {
          dataType: "string",
          fieldLabel: "Azure Client Secret",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
      });
    } else if (e.target.value === "GCP") {
      setOrganizationData({
        configurationname: organizationData?.configurationname,
        gcptoken: "",
        region: "",
      });

      setError({
        ...error,
        gcptoken: {
          dataType: "string",
          fieldLabel: "GCP Token",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
      });
    }
  };
  return (
    <>
      <Backdrop
        className={styles2.backdrop}
        open={isLoader}
        onClick={() => setIsLoader(false)}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        // onEntering={getData}
        maxWidth={"md"}
        fullWidth={true}
        open={props.openModal ? props.openModal : isEditMode}
        onClose={setOpenModal}
      >
        <DialogTitle className={styles2.modalHeader}>
          {isEditMode ? "Update" : "Add"} Cloud Configuration
        </DialogTitle>
        <DialogContent className={styles2.modalContent}>
          <div className={styles2.wrapper}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 10, marginBottom: 70 }}
            >
              <Grid item xs={4}>
                <label className={styles.fieldHeading}>
                  Config Name <span className={styles.red}>*</span>
                </label>
                <input
                  name="configurationname"
                  type="text"
                  className={`${styles.input} ${
                    error?.configurationname?.isError && styles.error
                  } ${isEditMode && styles.editMode}`}
                  value={organizationData?.configurationname}
                  autoComplete="off"
                  onChange={handleChangeCloud}
                  disabled={isEditMode}
                />
                {error?.configurationname?.isError && (
                  <p className={styles.errorMsg}>
                    {error?.configurationname?.msg}
                  </p>
                )}
              </Grid>

              <Grid item xs={4}>
                <label className={styles.fieldHeading}>
                  Cloud Provider <span className={styles.red}>*</span>
                </label>
                <Select
                  onChange={handleChangeCloudProvider}
                  name="cloud_provider"
                  value={cloudProvider ? cloudProvider : ""}
                  className={`${styles.select} ${
                    error?.cloudprovider?.isError && styles.error
                  }`}
                >
                  <MenuItem value="AWS">AWS</MenuItem>
                  <MenuItem value="Azure">Azure</MenuItem>
                  <MenuItem value="GCP">GCP</MenuItem>
                  <MenuItem value="Integrum">Integrum</MenuItem>
                </Select>
                {error?.cloudprovider?.isError && (
                  <p className={styles.errorMsg}>{error?.cloudprovider?.msg}</p>
                )}
              </Grid>
              <Grid item xs={4}></Grid>

              {(cloudProvider === "AWS" || cloudProvider === "Integrum") && (
                <>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Access Key ID <span className={styles.red}>*</span>
                    </label>
                    <input
                      name="accesskeyid"
                      type="text"
                      className={`${styles.input} ${
                        error?.accesskeyid?.isError && styles.error
                      } `}
                      value={organizationData?.accesskeyid}
                      autoComplete="off"
                      onChange={handleChangeCloud}
                    />
                    {error?.accesskeyid?.isError && (
                      <p className={styles.errorMsg}>
                        {error?.accesskeyid?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Secret Access Key <span className={styles.red}>*</span>
                    </label>
                    <div>
                      <input
                        name="secretaccesskey"
                        type={toggleShow ? "text" : "password"}
                        className={`${styles.input} ${
                          error?.accesskeyid?.isError && styles.error
                        } `}
                        style={{ paddingRight: "30px" }}
                        value={organizationData?.secretaccesskey}
                        autoComplete="off"
                        onChange={handleChangeCloud}
                      />
                      <span
                        onClick={() => setToggleShow((prev) => !prev)}
                        className={`${styles2.eye} eye-password`}
                        style={{ position: "absolute", marginTop: "15px" }}
                      >
                        {toggleShow ? (
                          <img src={Eye} alt="Eye" className={styles2.icon} />
                        ) : (
                          <img
                            src={EyeSlash}
                            alt="eye"
                            className={styles2.icon}
                          />
                        )}
                      </span>
                    </div>

                    {error?.secretaccesskey?.isError && (
                      <p className={styles.errorMsg}>
                        {error?.secretaccesskey?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Region <span className={styles.red}>*</span>
                    </label>
                    <Autocomplete
                      style={{ marginTop: "10px" }}
                      options={AWSRegionList}
                      value={AWSRegionList.find(
                        (v) => v.code === organizationData?.region
                      )}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Region"
                        />
                      )}
                      className={`${styles.searchInput3} ${
                        error?.region?.isError && styles.errorAuto
                      } `}
                      onChange={(e, input) => {
                        if (input) {
                          setOrganizationData({
                            ...organizationData,
                            region: input?.code,
                          });
                        } else {
                          setOrganizationData({
                            ...organizationData,
                            region: "",
                          });
                        }
                      }}
                    />

                    {error?.region?.isError && (
                      <p className={styles.errorMsg}>{error?.region?.msg}</p>
                    )}
                  </Grid>
                </>
              )}
              {cloudProvider === "Azure" && (
                <>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Tenant ID <span className={styles.red}>*</span>
                    </label>
                    <input
                      name="azuretenantid"
                      type="text"
                      className={`${styles.input} ${
                        error?.azuretenantid?.isError && styles.error
                      } `}
                      value={organizationData?.azuretenantid}
                      autoComplete="off"
                      onChange={handleChangeCloud}
                    />
                    {error?.azuretenantid?.isError && (
                      <p className={styles.errorMsg}>
                        {error?.azuretenantid?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Client ID <span className={styles.red}>*</span>
                    </label>
                    <input
                      name="azureclientid"
                      type="text"
                      className={`${styles.input} ${
                        error?.azureclientid?.isError && styles.error
                      } `}
                      value={organizationData?.azureclientid}
                      autoComplete="off"
                      onChange={handleChangeCloud}
                    />
                    {error?.azureclientid?.isError && (
                      <p className={styles.errorMsg}>
                        {error?.azureclientid?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Client Secret <span className={styles.red}>*</span>
                    </label>

                    <div>
                      <input
                        name="azureclientsecret"
                        type={toggleShow ? "text" : "password"}
                        className={`${styles.input} ${
                          error?.azureclientsecret?.isError && styles.error
                        } `}
                        style={{ paddingRight: "30px" }}
                        value={organizationData?.azureclientsecret}
                        autoComplete="off"
                        onChange={handleChangeCloud}
                      />
                      <span
                        onClick={() => setToggleShow((prev) => !prev)}
                        className={`${styles2.eye} eye-password`}
                        style={{ position: "absolute", marginTop: "15px" }}
                      >
                        {toggleShow ? (
                          <img src={Eye} alt="Eye" className={styles2.icon} />
                        ) : (
                          <img
                            src={EyeSlash}
                            alt="eye"
                            className={styles2.icon}
                          />
                        )}
                      </span>
                    </div>

                    {error?.azureclientsecret?.isError && (
                      <p className={styles.errorMsg}>
                        {error?.azureclientsecret?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>
                      Region <span className={styles.red}>*</span>
                    </label>
                    <Autocomplete
                      style={{ marginTop: "10px" }}
                      options={AZURE_REGION_LIST}
                      value={AZURE_REGION_LIST.find(
                        (v) => v.code === organizationData?.region
                      )}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Region"
                        />
                      )}
                      className={`${styles.searchInput3} ${
                        error?.region?.isError && styles.errorAuto
                      } `}
                      onChange={(e, input) => {
                        if (input) {
                          setOrganizationData({
                            ...organizationData,
                            region: input.code,
                          });
                        } else {
                          setOrganizationData({
                            ...organizationData,
                            region: "",
                          });
                        }
                      }}
                    />
                    {error?.region?.isError && (
                      <p className={styles.errorMsg}>{error?.region?.msg}</p>
                    )}
                  </Grid>
                </>
              )}
              {cloudProvider === "GCP" && (
                <>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>GCP Token</label>
                    <div>
                      <input
                        name="gcptoken"
                        type={toggleShow ? "text" : "password"}
                        className={`${styles.input} ${
                          error?.gcptoken?.isError && styles.error
                        } `}
                        style={{ paddingRight: "30px" }}
                        value={organizationData?.gcptoken}
                        autoComplete="off"
                        onChange={handleChangeCloud}
                      />
                      <span
                        onClick={() => setToggleShow((prev) => !prev)}
                        className={`${styles2.eye} eye-password`}
                        style={{ position: "absolute", marginTop: "15px" }}
                      >
                        {toggleShow ? (
                          <img src={Eye} alt="Eye" className={styles2.icon} />
                        ) : (
                          <img
                            src={EyeSlash}
                            alt="eye"
                            className={styles2.icon}
                          />
                        )}
                      </span>
                    </div>

                    {error?.gcptoken?.isError && (
                      <p className={styles.errorMsg}>{error?.gcptoken?.msg}</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.fieldHeading}>Region</label>
                    <Autocomplete
                      style={{ marginTop: "10px" }}
                      options={GCP_REGION_LIST}
                      value={GCP_REGION_LIST.find(
                        (v) => v.code === organizationData?.region
                      )}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Region"
                        />
                      )}
                      className={`${styles.searchInput3} ${
                        error?.region?.isError && styles.errorAuto
                      } `}
                      onChange={(e, input) => {
                        if (input) {
                          setOrganizationData({
                            ...organizationData,
                            region: input?.code,
                          });
                        } else {
                          setOrganizationData({
                            ...organizationData,
                            region: "",
                          });
                        }
                      }}
                    />
                    {error?.region?.isError && (
                      <p className={styles.errorMsg}>{error?.region?.msg}</p>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={styles.modalFooter}>
          <AppButton
            buttonName="Cancel"
            variant="outlined"
            disabled={false}
            className="btnsmall"
            onClick={() => props.setOpenModal(false)}
          />
          <AppButton
            buttonName="Save"
            variant="contained"
            disabled={false}
            className="btnsmall"
            onClick={() => handleSubmit()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CloudConfigurationModal;
