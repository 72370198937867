const fieldGroupsColumns = [
  { name: "name", title: "Payload Name", for: "fieldgroup" },
  { name: "type", title: "Type", for: "fieldgroup" },
  { name: "status", title: "In Use", for: "fieldgroup" },
  // { name: "decsription", title: "Description", for: "fieldgroup" },
  // { name: "updatedOn", title: "Updated On", for: "fieldgroup" },
  // { name: "updatedBy", title: "Updated By", for: "fieldgroup" },
  { name: "createdon", title: "Created On", for: "fieldgroup" },
  { name: "createdby", title: "Created By", for: "fieldgroup" },
  { name: "actions", title: "Action", route: "/fieldgroups/edit", for: "fieldgroup" },
];

const fieldGroupsDefaultColumnWidths = [
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "type", weightage: 3, width: 200 },
  { columnName: "status", weightage: 3, width: 200 },
  // { columnName: "decsription", width: 200, width: 200 },
  // { columnName: "updatedOn", width: 220, width: 200 },
  // { columnName: "updatedBy", width: 180, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "actions", weightage: 2.5, width: 200 },
];

const fieldGroupsTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "type", align: "left" },
  { columnName: "status", align: "left" },
  // { columnName: "decsription", align: "left" },
  // { columnName: "updatedOn", align: "left" },
  // { columnName: "updatedBy", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "actions", align: "center", sortingEnabled: false },
];

const fieldGroupsColumnOrder = [
  "name",
  "type",
  "status",
  // "decsription",
  // "updatedOn",
  // "updatedBy",
  "createdon",
  "createdby",
  // "actions",
];

const fieldGroupsColumnHidden = ["name", "type", "status", "createdon", "actions"];

const fieldGroupsDefaultSorting = [];

export {
  fieldGroupsColumns,
  fieldGroupsDefaultColumnWidths,
  fieldGroupsTableColumnExtensions,
  fieldGroupsColumnOrder,
  fieldGroupsDefaultSorting,
  fieldGroupsColumnHidden
};
