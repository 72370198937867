import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import {apiList,apiListDefaultColWidth,apiListColExtension,apiListColOrder} from '../../../constants/depAPIdetailsConstants';
import { AppButton } from '../Button'
import DeploymentContainerAPITable from '../Tables/DeploymentContainerAPITable';

function ViewAPIModal(props) {
    const {handleClose,open,apiData,restProps,setApiData,loading,}=props;
    useEffect(()=>{
      let tempData =apiData;
      if(apiData){
        tempData.map((item)=>{
          item["ingress"]=restProps?.row?.serdetails?.status?.loadBalancer ? restProps?.row?.serdetails?.status?.loadBalancer : "" 
          item["Port"]= restProps.row.Port;
        })

      }
      setApiData(apiData ? tempData : []);
  
    },[restProps])
  return (
    <div>
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <DialogContent>
        <div
          style={{
            marginTop: 10,
            padding:"20px"
          }}
        >
          {/* <div style={{ marginTop: 30,width:"90%",padding:"20px 0px" }}> */}
            <DeploymentContainerAPITable
            columns = {apiList}
            rows = {apiData}
            defaultColumnWidths ={apiListDefaultColWidth}
            tableColumnExtensions  = {apiListColExtension}
            columnOrder = {apiListColOrder}
            hidden={[]}
            setHidden={[]}
            noDataMsg="No APIs Found"
            isCustomWidth={450}
            />
            
          {/* </div> */}
        </div>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
        <AppButton
          onClick={handleClose}
          className={`btnsmall`}
          buttonName="Close"
          variant="outlined"
        />

      </DialogActions>
    </Dialog>
  </div>
  )
}


const mapStateToProps = (state)=>{
  const loading = state.deploymentProcessReducer.deploymentDetailsList;
  return{
    loading
  }
}

export default connect(mapStateToProps,null)(ViewAPIModal)
