import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import useTriggers from "../../../hooks/useTriggers";

function CustomImage({ id }) {
  const { currentProject, getComponent } = useGetData();
  const { handleTrigger } = useTriggers();
  const params = useParams();
  const isCanvas = params?.view === undefined;
  const key = isCanvas
    ? currentProject?.canvasImageMode
      ? currentProject?.canvasImageMode
      : "Web"
    : params?.device[0].toUpperCase() + params?.device.toLowerCase().slice(1);
  const currentComponent = getComponent(id);
  // const [imageHeight, setImageHeight] = useState(0);
  const viewKeys = {
    Web: "imgUrl",
    Tablet: "tabletImgUrl",
    Mobile: "mobileImgUrl",
  };
  const imageUrl = currentComponent?.[viewKeys?.[key]]?.dataValue;

  // useLayoutEffect(() => {
  //   setTimeout(() => {
  //     document.querySelectorAll("#Image").forEach((comp) => {
  //       if (comp.getAttribute("data-id") === currentComponent.id)
  //         setImageHeight(
  //           +comp.style.width.slice(0, -2) * currentComponent?.imgRatio
  //         );
  //     });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [params?.device]);

  useEffect(() => {
    if (currentComponent?.onInit)
      handleTrigger({
        action: currentComponent?.onInit,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickApiCall = () => {
    if (currentComponent?.onClick)
      handleTrigger({
        action: currentComponent?.onClick,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          width: "100%",
          // height:
          //   params?.device !== "WEB" && params?.device !== undefined
          //     ? imageHeight
          //     : "-webkit-fill-available",
          height: "-webkit-fill-available",
        }}
        onClick={handleClickApiCall}
      >
        <span>
          <img
            src={imageUrl && typeof imageUrl === "string" ? imageUrl : ""}
            style={{
              overflow: "hidden",
              backgroundSize: "cover",
              // width: currentComponent?.imgWidth
              //   ? `${currentComponent?.imgWidth}px`
              //   : "100%",
              // height: currentComponent?.imgHeight
              //   ? `${currentComponent?.imgHeight}px`
              //   : "100%",
              width: "100%",
              height: "100%",
            }}
            alt="Image"
          />
        </span>
      </div>
    </>
  );
}

export default CustomImage;
