import { Grid, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState, useRef } from "react";
import {
  documentColumnOrder,
  documentColumns,
  documentDefaultColumnWidths,
  documentDefaultSorting,
  documentTableColumnExtensions,
} from "../../../constants/documentConstant";
import manageStyles from "../../../screens/CommonStyles/manageScreenStyles";
import { AppButton } from "../Button";
import DevExpressTable from "./DevExpressTable";
import { v4 as uuidv4 } from "uuid";
import {
  addDocument,
  fetchDocumentTypeListData,
} from "../../../utils/project/documentUtils";
import { useSnackbar } from "notistack";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  convertEditorToFile,
  getColumnWidth,
  getWindowDimensions,
  handleFormValidation,
  handleFormValidationForDocument,
} from "../../../utils/common";
import Loader from "../stuff/Loader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useCheckPermission from "../hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
const CustomDropperIcon = () => (
  <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
);

const initError = (id) => ({
  id: id,
  name: {
    isError: false,
    msg: "",
  },
  version: {
    isError: false,
    msg: "",
  },
  docType: {
    isError: false,
    msg: "",
  },
  status: {
    isError: false,
    msg: "",
  }
});

const DocumentTableWithDropper = ({
  resourceId,
  resourceType,
  isEditMode,
  rows,
  fetchDocuments,
  setSort,
  sort,
  isDocumentModule,
  closeImportButton,
}) => {
  const styles = manageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [documentTab, setDocumentTab] = useState("Upload");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [droppedDocuments, setDroppedDocuments] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [clearEditorState, setClearEditorState] = useState("");
  const autoC = useRef(null);
  const [customFile, setCustomFile] = useState({
    name: "",
    version: "",
    docType: "",
    file: "",
    status: "",
  });

  const [customFileError, setCustomFileError] = useState({
    name: "",
    version: "",
    docType: "",
    file: "",
    status: "",
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [error, setError] = useState([]);
  const [random, setRandom] = useState(Math.random());
  const [statuses, setStatuses] = useState([]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (checkCompPermission("DocumentType", ["List All"])) {
      fetchAllDocumentTypes();
    }
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Document"
      );
      if (_status === 200) {

        setStatuses(data ? data.status : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  const handleChangeDocumentTab = (event, newValue) => {
    setDocumentTab(newValue);
    setCustomFileError({
      name: false,
      version: false,
      docType: false,
      file: false,
      status: false,
    });
    setCustomFile({
      name: "",
      version: "",
      docType: "",
      file: "",
      status: "",
    });
    const tempError = error.map((e) => {
      return {
        ...e,
        docType: { ...e["docType"], isError: false },
        name: { ...e["name"], isError: false },
        version: { ...e["version"], isError: false },
        status: { ...e['status'], isError: false },
      };
    });
    setError(tempError);
    setDroppedDocuments([]);
  };

  const handleDeleteDocument = (documentToDelete) => () => {
    setDroppedDocuments((documents) =>
      documents.filter((doc) => doc.id !== documentToDelete.id)
    );
    setRandom(Math.random());
  };

  const fetchAllDocumentTypes = async () => {
    const { _msg, _status, data } = await fetchDocumentTypeListData();
    if (_status === 200) {
      if (_status === 200) {
        if (data && data.length) {
          let tempDocType = {
            createdby: "",
            createdon: "",
            description: "",
            name: "",
            _id: "",
          };
          data.unshift(tempDocType);
        }
        setDocumentTypes(data ? data : []);
      }
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDocChange = (event, id) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return {
          ...e,
          [event.target.name]: { ...e[event.target.name], isError: false },
        };
      } else {
        return e;
      }
    });

    setError(tempError);
    const newDoc = droppedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, [event.target.name]: event.target.value };
      } else {
        return doc;
      }
    });
    setDroppedDocuments(newDoc);
  };

  const handleCustomDocChange = (event, id) => {
    const value = event.target.value;
    setCustomFileError({
      ...customFileError,
      [event.target.name]: false,
    });
    setCustomFile({
      ...customFile,
      [event.target.name]: value,
    });
  };

  const handleSelectDocumentType = (docType, id) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return { ...e, docType: { ...e["docType"], isError: false } };
      } else {
        return e;
      }
    });
    setError(tempError);
    const newDoc = droppedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, docType: docType };
      } else {
        return doc;
      }
    });
    setDroppedDocuments(newDoc);
  };

  const handleSelectStatus = (status, id) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return { ...e, status: { ...e["status"], isError: false } };
      } else {
        return e;
      }
    });
    setError(tempError);
    const newDoc = droppedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, status: status };
      } else {
        return doc;
      }
    });
    setDroppedDocuments(newDoc);
  };

  const uploadSingleDocument = async (resType, resId, doc) => {
    var formData = new FormData();
    formData.append(
      "meta",
      JSON.stringify({
        resourcetype: resType,
        resourceid: resId,
        name: doc.docType.name,
        version: doc.version,
        documenttype: doc.docType._id,
        docName: doc.name,
        status: doc.status?.status,
      })
    );
    // const renameFile = new File([doc.file], doc.name);
    formData.append("file", doc.file);
    setIsLoader(true);
    const { _msg, _status, data } = await addDocument(formData);
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);
      setDroppedDocuments([]);
      setCustomFile({ name: "", version: "", docType: {}, file: null });
      setEditorState(EditorState.createEmpty());
      fetchDocuments();
    } else {
      setIsLoader(false);
      if (_msg === "Invalid Json" || _msg === "Invalid Payload") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const handleUploadDocument = async () => {
    const { formIsValid, errors } = handleFormValidationForDocument(
      droppedDocuments,
      error
    );
    if (formIsValid) {
      droppedDocuments.length !== 0 &&
        droppedDocuments.map((doc) => {
          uploadSingleDocument(resourceType, resourceId, doc);
        });
      setRandom(Math.random());
    } else {
      setError(errors);
    }
  };

  const saveCustomFile = () => {
    const convertedFile = convertEditorToFile(editorState);
    const fileData = {
      name: customFile.name,
      version: customFile.version,
      docType: customFile.docType,
      file: convertedFile,
      status: customFile.status,
    };
    const { formIsValid, errors } = handleFormValidation(
      fileData,
      customFileError
    );
    if (formIsValid) {
      uploadSingleDocument(resourceType, resourceId, {
        ...fileData,
        name: fileData.name + ".html",
        file: new File([fileData.file], fileData.name + ".html"),
      });
    } else {
      setCustomFileError(errors);
    }
  };

  return (
    <div>
      {isLoader && <Loader />}
      {isEditMode && (
        <RadioGroup
          value={documentTab}
          onChange={handleChangeDocumentTab}
          indicatorColor="primary"
          textColor="primary"
          color="primary"
          className={styles.documentTab}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <FormControlLabel
            value="Upload"
            control={<Radio color="primary" />}
            label="Upload"
          />
          <FormControlLabel
            value="Text Editor"
            control={<Radio color="primary" />}
            label="Text Editor"
          />
        </RadioGroup>
      )}
      {isEditMode && documentTab === "Upload" && (
        <div>
          <div className={styles.documentDropperWrapper}>
            {checkCompPermission("DocumentType", ["List All"]) ? (
              <DropzoneArea
                key={random}
                Icon={CustomDropperIcon}
                showPreviews={false}
                filesLimit={5}
                dropzoneText="Drag and drop or click to browse here."
                dropzoneClass="documentDropperClass"
                dropzoneParagraphClass="documentDropperTextClass"
                showAlerts={["error"]}
                maxFileSize={90000000}
                clearOnUnmount={true}
                showPreviewsInDropzone={false}
                onChange={(files) => {
                  let tempFiles = [...droppedDocuments];
                  let tempErrors = error;
                  files.map((file) => {
                    if (
                      droppedDocuments.filter((f) => f.name === file.name)
                        .length === 0
                    ) {
                      const id = uuidv4();
                      tempFiles.push({
                        id: id,
                        name: file.name,
                        version: "",
                        docType: "",
                        status: "",
                        file,
                      });
                      tempErrors.push(initError(id));
                    }
                  });

                  if (tempFiles.length !== 0) {
                    setDroppedDocuments(tempFiles);
                    setError(tempErrors);
                  }
                }}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <div>
                  <DropzoneArea
                    key={random}
                    Icon={CustomDropperIcon}
                    showPreviews={false}
                    filesLimit={5}
                    dropzoneText="Drag and drop or click to browse here."
                    dropzoneClass="documentDropperClass"
                    dropzoneParagraphClass="documentDropperTextClass"
                    showAlerts={["error"]}
                    maxFileSize={90000000}
                    clearOnUnmount={true}
                    showPreviewsInDropzone={false}
                    onChange={(files) => {
                      let tempFiles = [...droppedDocuments];
                      let tempErrors = error;
                      files.map((file) => {
                        if (
                          droppedDocuments.filter((f) => f.name === file.name)
                            .length === 0
                        ) {
                          const id = uuidv4();
                          tempFiles.push({
                            id: id,
                            name: file.name,
                            version: "",
                            docType: "",
                            file,
                          });
                          tempErrors.push(initError(id));
                        }
                      });

                      if (tempFiles.length !== 0) {
                        setDroppedDocuments(tempFiles);
                        setError(tempErrors);
                      }
                    }}
                  />
                </div>
                <span style={{ marginTop: "136px", marginLeft: "25px" }}>
                  <p className={styles.fieldHeading} style={{ color: "red" }}>
                    You do not have permission to Document Type List All. Please
                    contact your Admin.
                  </p>
                </span>
              </div>
            )}
          </div>

          {droppedDocuments.length !== 0 && (
            <div className={styles.droppedDocuments}>
              {droppedDocuments.map((item, index) => {
                return (
                  <Grid
                    container
                    spacing={3}
                    style={{
                      borderBottom: "1px solid rgba(0, 40, 85, .4)",
                      marginBottom: 20,
                      paddingBottom: 10,
                    }}
                    key={index}
                  >
                    <Grid item xs={4} sm={4}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // marginTop: 20,
                          }}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            className={styles.actionIcon}
                          >
                            <img
                              src="/images/Doc_Icon.svg"
                              alt="doc"
                              style={{ filter: "brightness(.3)", height: 25 }}
                            />
                          </IconButton>
                        </Grid>

                        <Grid item xs={10} sm={10}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Document Name <span className={styles.red}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`${styles.input} ${error[index].name.isError && styles.error
                              }`}
                            name="name"
                            value={item.name}
                            onChange={(e) => handleDocChange(e, item.id)}
                            autoComplete="off"
                          />
                          {error[index].name.isError && (
                            <p className={styles.errorMsg}>
                              {error[index].name.msg}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <label htmlFor="" className={styles.fieldHeading}>
                        Version Number <span className={styles.red}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`${styles.input} ${error[index].version.isError && styles.error
                          }`}
                        name="version"
                        value={item.version}
                        onChange={(e) => handleDocChange(e, item.id)}
                        autoComplete="off"
                      />
                      {error[index].version.isError && (
                        <p className={styles.errorMsg}>
                          {error[index].version.msg}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={4} sm={4}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Document Type <span className={styles.red}>*</span>
                          </label>
                          <Autocomplete
                            options={documentTypes}
                            getOptionLabel={(option) => option.name}
                            className={`${styles.searchInput3} ${error[index].docType.isError && styles.error
                              }`}
                            onChange={(e, input) => {
                              handleSelectDocumentType(input, item.id);
                            }}
                            // value={customFile.docType}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                className={`${!isEditMode && styles.editMode}`}
                              // placeholder="Select Document Type"
                              />
                            )}
                          />
                          {error[index].docType.isError && (
                            <p className={styles.errorMsg}>
                              {error[index].docType.msg}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={5} sm={5}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Status <span className={styles.red}>*</span>
                          </label>
                          <Autocomplete
                            options={statuses}
                            getOptionLabel={(option) => option.status}
                            className={`${styles.searchInput3} ${
                              error[index].status.isError && styles.error
                            }`}
                            onChange={(e, input) => {
                              handleSelectStatus(input, item.id);
                            }}
                            // value={customFile.docType}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                className={`${!isEditMode && styles.editMode}`}
                                // placeholder="Select Document Type"
                              />
                            )}
                          />
                          {error[index].status.isError && (
                            <p className={styles.errorMsg}>
                              {error[index].status.msg}
                            </p>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // marginTop: 20,
                          }}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            onClick={handleDeleteDocument(item)}
                            className={styles.actionIcon}
                          >
                            <img src="/images/deleteLine.svg" alt="card" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 20 }}
              >
                <AppButton
                  color="primary"
                  buttonName="Upload"
                  variant="contained"
                  disabled={false}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleUploadDocument}
                />
              </Grid>
            </div>
          )}
        </div>
      )}

      {isEditMode && documentTab === "Text Editor" && (
        <div className={styles.documentEditorWrapper}>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(data) => setEditorState(data)}
          />
          {customFileError.file && (
            <p className={styles.errorMsg}>{"Document content is Required"}</p>
          )}
          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Name <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.name && styles.error
                  }`}
                name="name"
                value={customFile.name}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.name && (
                <p className={styles.errorMsg}>{"Document Name is Required"}</p>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <label htmlFor="" className={styles.fieldHeading}>
                Version Number <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.version && styles.error
                  }`}
                name="version"
                value={customFile.version}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.version && (
                <p className={styles.errorMsg}>
                  {"Document Version is Required"}
                </p>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Type <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                options={documentTypes}
                getOptionLabel={(option) => option.name}
                className={`${styles.searchInput3}`}
                onChange={(e, input) => {
                  setCustomFile({ ...customFile, docType: input });
                  setCustomFileError({ ...customFileError, docType: false });
                }}
                value={customFile.docType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={customFile.docType}
                    variant="standard"
                    className={`${!isEditMode && styles.editMode} ${customFileError.docType && styles.error
                      }`}
                  // placeholder="Select Document Type"
                  />
                )}
              />
              {customFileError.docType && (
                <p className={styles.errorMsg}>{"Document Type is Required"}</p>
              )}
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Status <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                options={statuses}
                getOptionLabel={(option) => option.status}
                className={`${styles.searchInput3}`}
                onChange={(e, input) => {
                  setCustomFile({ ...customFile, status: input });
                  setCustomFileError({ ...customFileError, status: false });
                }}
                value={customFile.status}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={customFile.status}
                    variant="standard"
                    className={`${!isEditMode && styles.editMode} ${
                      customFileError.status && styles.error
                    }`}
                  />
                )}
              />
              {customFileError.docType && (
                <p className={styles.errorMsg}>{"Status is Required"}</p>
              )}
            </Grid>
            <Grid item xs={2} sm={2} className={styles.createDocumentBtn}>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={() => saveCustomFile()}
              />
            </Grid>
          </Grid>
        </div>
      )}

      <DevExpressTable
        isEditMode={isEditMode}
        columns={documentColumns}
        rows={rows}
        defaultColumnWidths={getColumnWidth(
          documentDefaultColumnWidths,
          windowDimensions
        )}
        tableColumnExtensions={documentTableColumnExtensions}
        columnOrder={documentColumnOrder}
        defaultSorting={documentDefaultSorting}
        sort={sort}
        setSort={setSort}
        isAddIcon={true}
        loading={false}
        isCustomHeight={isDocumentModule}
        noDataMsg="No Document Found"
        hideColumnChooser={true}
      />
    </div>
  );
};

export default DocumentTableWithDropper;
