import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import {
  editMicroService,
  fetchAiTaskById,
  fetchAllBoilerPlates,
  fetchVersionListData,
  getRepositoryDetailsByMicroServiceVersion,
} from "../../../utils/project/microserviceUtils";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect, useSelector } from "react-redux";
import {
  getVersionList,
  getAPIList,
  clearVersions,
  clearAPIs,
  addVersionHidden,
  addVersionSort,
  addAPISort,
  addAPIHidden,
  toggleVersionLoading,
} from "../../../redux/actions/serviceAction";
import {
  getTableData,
  getWindowDimensions,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import { fetchLanguageDetailsByID } from "../../../utils/applicationGovernance/launguageUtils";
import Loader from "../../../components/common/stuff/Loader";
import { imageDetails } from "../../../utils/containerMangement/imageUtils";
import {
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
} from "../../../redux/actions/documentAction";
import { arrayToFilter } from "../../../utils/common";
import { arrayToSort, arrayToSort2 } from "../../../utils/common";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import {
  aiTaskVersionColumns,
  aiTaskVersionDefaultColumnWidths,
  aiTaskVersionTableColumnExtensions,
  aiTaskVersionColumnOrder,
  aiTaskVersionColumnHidden,
} from "../../../constants/aiTaskVersionsConstant";
import {
  aiTaskApiColumns,
  aiTaskApiDefaultColumnWidths,
  aiTaskApiTableColumnExtensions,
  aiTaskApiColumnOrder,
  aiTaskApiColumnHidden,
} from "../../../constants/apiAiTaskConstant";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import CustomFieldGroupsForAiTask from "../../../components/common/entitySchema/aiTask/customFieldGroupsForAiTask";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import {
  closeAITaskVersion,
  tabbedAITaskActiveTab,
  closeAITaskAPI,
} from "../../../redux/actions/aiTaskActions";

const EditAiTaskScreen = (props) => {
  const {
    service_documents_list,
    add_service_document_data,
    serviceDocumentsFilters,
    serviceDocumentSort,
    versionHidden,
    addVersionHidden,
    addVersionSort,
    versionSort,
    getVersionList,
    getAPIList,
    versionsList,
    apisList,
    clearVersions,
    clearAPIs,
    addAPIHidden,
    addAPISort,
    apiSort,
    apiHidden,
    toggleVersionLoading,
    tabbedView,
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
    editVersion,
    tabbedAITaskActiveTab,
    closeAITaskVersion,
    closeAITaskAPI,
  } = props;
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const [currentPageServiceDocumentList, setcurrentPageServiceDocumentList] =
    useState(1);
  const [serviceId] = useState(props.match.params.aiTaskId);
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [languages, setLanguages] = useState([]);
  const [comingFromDetailsPage, setcomingFromDetailsPage] = useState(false);
  const [languageDetails, setLanguageDetails] = useState({});
  const [versions, setVersions] = useState([]);
  const [apiVersions, setApiVersions] = useState([]);
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [currentVersionPage, setCurrentVersionPage] = useState(1);
  const [currentAPIPage, setCurrentAPIPage] = useState(1);
  const [apis, setApis] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [serviceName, setServiceName] = useState();
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [createVersion, setCreateVersion] = useState(false);
  const [createAPI, setCreateAPI] = useState(false);
  const [serviceData, setServiceData] = useState({
    _id: "",
    name: "",
    description: "",
    status: "",
    imagename: "",
    languageID: "",
    initializeboilerplateID: false,
    boilerplateID: "",
    createdon: "",
    servicegroupID: "",
    projectID: "",
    projectDetails: {},
    servicegroupdetails: {},
  });
  const [error, setError] = useState({
    name: { isError: false },
    languageID: { isError: false },
  });
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  const search = useLocation().search;

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission("Microservice", ["Edit"]);
    } else {
      checkScreenPermission("Microservice", ["Read"]);
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [versionTableLoading, setVersionTableLoading] = useState(true);
  const [versionTableData, setVersionTableData] = useState({
    columns: aiTaskVersionColumns,
    widths: aiTaskVersionDefaultColumnWidths,
    extentions: aiTaskVersionTableColumnExtensions,
    orders: aiTaskVersionColumnOrder,
    hides: [],
  });
  const [apiTableLoading, setApiTableLoading] = useState(true);
  const [apiTableData, setApiTableData] = useState({
    columns: aiTaskApiColumns,
    widths: aiTaskApiDefaultColumnWidths,
    extentions: aiTaskApiTableColumnExtensions,
    orders: aiTaskApiColumnOrder,
    hides: [],
  });

  useEffect(() => {
    closeAITaskVersion()
    closeAITaskAPI()
  }, [])

  useEffect(() => {
    setIsLoader(true);
    const fetchVersionFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName(
        "AITaskVersion"
      );
      if (_status === 200) {
        let versData = getTableData(
          data,
          aiTaskVersionColumns,
          aiTaskVersionDefaultColumnWidths,
          aiTaskVersionTableColumnExtensions,
          aiTaskVersionColumnOrder,
          versionHidden,
          "aitask_version"
        );
        setVersionTableData(versData);
        if (
          JSON.stringify(versionHidden) ===
          JSON.stringify(aiTaskVersionColumnHidden)
        ) {
          addVersionHidden(versData.hides);
        }
        setVersionTableLoading(false);
        setIsLoader(false);
      } else {
        setVersionTableLoading(false);

        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
        setIsLoader(false);
      }
    };

    const fetchApiFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("AITaskAPI");
      if (_status === 200) {
        let apData = getTableData(
          data,
          aiTaskApiColumns,
          aiTaskApiDefaultColumnWidths,
          aiTaskApiTableColumnExtensions,
          aiTaskApiColumnOrder,
          apiHidden,
          "aitask_api"
        );

        const tempApData = {
          ...apData,
          columns: apData.columns.filter((col) => col.title !== "password"),
          hides: apData.hides.filter((h) => h.split("_")[2] !== "password"),
        };
        delete tempApData["function"];
        setApiTableData(tempApData);

        if (
          JSON.stringify(apiHidden) === JSON.stringify(aiTaskApiColumnHidden)
        ) {
          addAPIHidden(apData.hides);
        }
        setApiTableLoading(false);
      } else {
        setApiTableLoading(false);

        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchVersionFields();
    fetchApiFields();
  }, []);

  function createVersionTableData(versionData) {
    const {
      _id,
      versionTag,
      port,
      status,
      fileName,
      isUpload,
      repoId,
      imagename,
    } = versionData;
    return {
      ...versionData,
      id: _id,
      versionTag: versionTag,
      port: port,
      fileName: fileName,
      imageStatus: status,
      action: serviceId,
      ImageID: versionData?.docker?.imagedetails?.ImageID,
      Instances: versionData?.docker?.Instances,
      isUpload: isUpload,
      repoId: repoId,
      imagename: imagename,
      serviceType: serviceData?.serviceType,
    };
  }

  function createDocumentTableData(docData) {
    const { _id, docName, filename, name, resourcetype, version, createdon, status } = docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
      action: serviceId,
      status: status,
      statuses: statuses,
    };
  }

  function createApiTableData(apiData) {
    const { _id, name, type, createdon, createdby, selectedInstance, topic } =
      apiData;
    return {
      ...apiData,
      id: _id,
      name: name,
      type: type,
      createdby: createdby,
      createdon: createdon,
      action: serviceId,
      version: selectedVersion?.id,
      versionStatus: selectedVersion?.imageStatus,
      Instances: selectedVersion?.Instances,
      selectedInstance: selectedInstance,
      environmentID: selectedVersion?.environmentID,
      serviceType: serviceData?.serviceType,
    };
  }

  useEffect(() => {
    const fetchServiceEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITask",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setServiceEntitySchema(entity);
        fetchServiceDetails();
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });

        setServiceData((prevData) => ({ ...prevData, ...servData }));

        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        delete errData["boilerplateID"];
        delete errData["status"];
        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    const fetchServiceDetails = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchAiTaskById(serviceId);
      if (_status === 200) {
        setServiceName(data?.name);
        setServiceData((prevData) => ({ ...prevData, ...data }));
        if (data.languageID) {
          fetchLanguageDetails(data.languageID);
          fetchLanguagesList(data.projectID);
          setIsLoader(false);
          if (data?.serviceType === "External") {
            let tempError = error;
            delete tempError["imagename"];
            setError(tempError);
          }
        }
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
        setIsLoader(false);
      }
    };

    const fetchLanguagesList = async (projectID) => {
      const { _msg, _status, data } = await fetchLanguagesByProject(projectID);
      if (_status === 200) {
        setLanguages(data ? data : []);
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    };

    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchAllBoilerPlates();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchStatusesByResource = async (resource, callback) => {
      const { _msg, _status, data } = await fetchStatusResourceByName(resource);
      if (_status === 200) {
        callback(data);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchStatusesByResource("AITask", (data) =>
      setStatuses(data ? data.status : [])
    );
    fetchServiceEntitySchema();
    // fetchServiceDetails();
    fetchBoilerPlates();
    fetchAPIVersions(JSON.stringify({}));
    // fetchVersionsList();
  }, [isEditMode, serviceId]);

  useEffect(() => {
    if (serviceData.serviceType === "External") {
      let tempEntity = serviceEntitySchema;
      tempEntity.map((section) => {
        if (section.name === "AITask_Header_Section") {
          section.fieldgroups.map((fieldGroup) => {
            //  fieldGroup.fields.filter((item)=>item.name !=="AITask Image Name");
            fieldGroup.fields.map((field) => {
              if (field.name === "AITask Image Name")
                fieldGroup.fields.splice(4, 1);
            });
          });
        }
      });
    }
  }, [serviceData.serviceType, serviceEntitySchema]);

  const fetchAPIVersions = async (filter) => {
    const { _msg, _status, data } = await fetchVersionListData(
      serviceId,
      filter
    );
    if (_status === 200) {
      setApiVersions(data ? data : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const fetchDockerImageDetails = async (imageId) => {
    const { _msg, _status, data } = await imageDetails(imageId);
    if (_status === 200) {
      return data;
    } else {
      // enqueueSnackbar(_msg ? _msg : "Something went wrong", {
      //   variant: "error",
      // });
      return {};
    }
  };

  const fetchRepositoryDetails = async (versionId) => {
    const { _msg, _status, data } =
      await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);
    if (_status === 200) {
      return data;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const parsedSort = arrayToSort(versionSort);
    getVersionList(serviceId, currentVersionPage, JSON.stringify(parsedSort));
  }, [serviceId, createVersion, currentVersionPage, versionSort]);

  useEffect(async () => {
    const tempVersions = [];

    toggleVersionLoading(true);
    for (
      var i = 0;
      versionsList?.data?.data?.length > 0 &&
      i < versionsList?.data?.data?.length;
      i++
    ) {
      let imageData = {};
      if (versionsList.data.data[i]?.docker?.imagedetails) {
        imageData = await fetchDockerImageDetails(
          versionsList?.data?.data[i]?.docker?.imagedetails?.ImageID
        );
      }
      const repoData = await fetchRepositoryDetails(
        versionsList?.data?.data[i]?._id
      );

      if (repoData) {
        imageData["filename"] = repoData?.filename;
        imageData["isUpload"] = true;
        imageData["repoId"] = repoData?._id;
        if (repoData.draft) {
          imageData["isDraft"] = true;
        } else {
          imageData["isDraft"] = false;
        }
      } else {
        imageData["isUpload"] = false;
        imageData["isDraft"] = false;
      }

      tempVersions.push(
        createVersionTableData({
          ...versionsList?.data?.data[i],
          status: imageData?.state,
          fileName: imageData?.filename,
          isUpload: imageData?.isUpload,
          isDraft: imageData?.isDraft,
          repoId: imageData?.repoId,
          imagename: imageData?.imagename,
        })
      );
    }
    toggleVersionLoading(false);

    setVersions(tempVersions);
    setSelectedVersion(tempVersions[0]);
  }, [serviceId, versionsList?.data?.data]);

  useEffect(() => {
    return () => {
      clearVersions();
      clearAPIs();
    };
  }, []);

  const fetchLanguageDetails = async (languageId) => {
    const { _msg, _status, data } = await fetchLanguageDetailsByID(languageId);
    if (_status === 200) {
      setLanguageDetails(data ? data : {});
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPageServiceDocumentList, serviceDocumentSort]);

  const fetchDocuments = () => {
    const parsedFilters = arrayToFilter([
      ...serviceDocumentsFilters,
      { resourcetype: "microservice" },
      { resourceid: serviceId },
    ]);
    const parsedSort = arrayToSort2(serviceDocumentSort);
    add_service_document_data(
      currentPageServiceDocumentList,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  };

  const documents =
    service_documents_list.data && service_documents_list.data.length > 0
      ? service_documents_list.data.map((item) => {
        const rowData = createDocumentTableData(item);
        return rowData;
      })
      : [];

  const handleServiceDocumentListPagination = (event, value) => {
    setcurrentPageServiceDocumentList(value);
  };

  useEffect(() => {
    if (selectedVersion?.id) {
      const parsedSort = arrayToSort(apiSort);
      getAPIList(
        serviceId,
        selectedVersion.id,
        currentAPIPage,
        JSON.stringify(parsedSort)
      );
    }
  }, [selectedVersion, createAPI, currentAPIPage, apiSort]);

  useEffect(async () => {
    const rows =
      apisList?.data && apisList.data?.length > 0
        ? apisList?.data?.map((item) => {
          const rowData = createApiTableData(item);
          return rowData;
        })
        : [];
    setApis(rows);
  }, [apisList]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };

  useEffect(() => {
    if (serviceData.name !== "") {
      setError({
        ...error,
        name: {
          dataType: "string",
          isError: false,
          fieldLabel: "AI Task Name",
          minLength: 1,
          maxLength: 50,
          maximum: 300,
          minimum: 1,
          section: 0,
        },
      });
    }
    //  else {
    //   setError({
    //     ...error,
    //     name: {
    //       dataType: "string",
    //       isError: true,
    //       fieldLabel: "AI Task Name",
    //       minLength: 1,
    //       maxLength: 50,
    //       maximum: 300,
    //       minimum: 1,
    //       section: 0,
    //     },
    //   });
    // }
  }, [serviceData.name]);

  const handleSubmit = async () => {
    if (isTabbedApproach) {
      closeAITaskVersion();
      closeAITaskAPI();
      setCreateVersion(false);
      setCreateAPI(false);
    }
    let tempServiceData = { ...serviceData };
    if (serviceData?.serviceType === "External") {
      delete tempServiceData["imagename"];
    }

    const { formIsValid, errors } = handleFormValidationPro(
      tempServiceData,
      error
    );

    if (formIsValid) {
      const newServiceData = {
        ...tempServiceData,
        languageID: languageDetails._id,
        initializeboilerplateID: tempServiceData.boilerplateID ? true : false,
      };
      delete newServiceData["_id"];
      delete newServiceData["createdby"];
      delete newServiceData["createdon"];
      delete newServiceData["updatedby"];
      delete newServiceData["updatedon"];
      delete newServiceData["projectID"];
      delete newServiceData["projectDetails"];
      delete newServiceData["servicegroupdetails"];
      delete newServiceData["servicegroupID"];
      delete newServiceData["servicegroupid"];

      setIsLoader(true);
      const { _msg, _status, data } = await editMicroService(
        serviceId,
        newServiceData
      );
      if (_status === 200) {
        if (isTabbedApproach) {
          setCreateVersion(false);
          setCreateAPI(false);
        }
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        // setServiceData(data);
        if (data.languageID) {
          fetchLanguageDetails(data.languageID);
        }
        histroy.push(`/aitask/details/${serviceId}`);
      } else {
        setIsLoader(false);
        if (isTabbedApproach) {
          setCreateVersion(false);
          setCreateAPI(false);
        }
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema,
        errors,
        isTabbedApproach
      );
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);
      setError(errors);
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
    // setCreateVersion(false);
    // setCreateAPI(false);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };
  console.log("ajvasshvjhvjvh", createVersion)

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCreateVersion = () => {
    if (isTabbedApproach) {
      setCreateVersion(true);
      tabbedAITaskActiveTab(3);
    } else {
      histroy.push(`/ai-task-versions/add/${serviceId}`);
    }
  };
  const handleCloseVersion = () => {
    setCreateVersion(false);
  };
  const handleCreateAPI = () => {
    if (isTabbedApproach) {
      setCreateAPI(true);
      tabbedAITaskActiveTab(4);
    } else {
      histroy.push(`/ai-task-api/add/${serviceId}`);
    }
  };
  const handleCloseAPI = () => {
    setCreateAPI(false);
  };
  useEffect(() => {
    if (isEditMode) {
      setcomingFromDetailsPage(false);
    } else {
      setcomingFromDetailsPage(true);
    }
  }, []);

  const handleCancel = () => {
    setCreateVersion(false);
    setCreateAPI(false);

    if (!isEditMode) {
      histroy.push(`/aitask-workbench`);
    } else {
      comingFromDetailsPage
        ? histroy.push(`/aitask/details/${serviceId}`)
        : histroy.push(`/aitask-workbench`);
    }
  };
  const handleEdit = () => {
    histroy.push(`/aitask/edit/${serviceId}`);
  };

  return (
    <main className={styles.main}>
      {isTabbedApproach ? (
        <>
          {isLoader && <Loader />}
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                {
                  title: "AI Task",
                  path: "/aitask-workbench",
                },
                {
                  title: isEditMode
                    ? `Edit ${serviceData.name}`
                    : serviceData.name,
                },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>
                {isEditMode ? `Edit ${serviceData.name}` : serviceData.name}
              </h2>
              {
                <>
                  {console.log("jbasjbaskasas", createVersion, createAPI)}
                  {((createVersion || editVersion) && (createAPI || editAPI) && (activeTab === 3 || activeTab === 4)) ? <></> : (((!createVersion && !createAPI) && (!editAPI && !editVersion)) || ((createVersion || editVersion) && activeTab !== 3) || ((createAPI || editAPI) && activeTab !== 4)) && <TabbedNavigation
                    isEditMode={isEditMode}
                    handleEdit={handleEdit}
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    isClose={isEditMode ? false : true}
                  />}

                </>
              }
            </Grid>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.wrapper}>
              <Grid container direction="row" justify="space-between">
                <SectionTabs
                  entity={serviceEntitySchema}
                  activeTab={activeTab}
                  handleActiveTab={handleActiveTab}
                />

              </Grid>
              <Grid style={{ marginTop: "10px" }}>
                <Paper style={{ padding: "15px 20px" }}>
                  <div style={{ padding: "20px" }}>
                    <CustomFieldGroupsForAiTask
                      // handleVersionCreate={handleVersionCreate}
                      versionId={selectedVersionId}
                      editVersion={editVersion}
                      serviceName={serviceName}
                      handleCreateVersion={handleCreateVersion}
                      handleCloseVersion={handleCloseVersion}
                      createVersion={createVersion}
                      setCreateVersion={setCreateVersion}
                      handleCreateAPI={handleCreateAPI}
                      handleCloseAPI={handleCloseAPI}
                      createAPI={createAPI}
                      setCreateAPI={setCreateAPI}
                      section={serviceEntitySchema[activeTab]}
                      isEditMode={isEditMode}
                      serviceData={serviceData}
                      error={error}
                      serviceId={serviceId}
                      statuses={statuses}
                      versions={versions}
                      versionsList={versionsList}
                      currentVersionPage={currentVersionPage}
                      apis={apis}
                      apisList={apisList}
                      currentAPIPage={currentAPIPage}
                      selectedVersion={selectedVersion}
                      languages={languages}
                      boilerplates={boilerplates}
                      languageDetails={languageDetails}
                      documents={documents}
                      windowDimensions={windowDimensions}
                      versionTableLoading={versionTableLoading}
                      versionTableData={versionTableData}
                      versionHidden={versionHidden}
                      addVersionHidden={addVersionHidden}
                      versionSort={versionSort}
                      addVersionSort={addVersionSort}
                      apiTableData={apiTableData}
                      apiTableLoading={apiTableLoading}
                      apiHidden={apiHidden}
                      addAPIHidden={addAPIHidden}
                      apiSort={apiSort}
                      addAPISort={addAPISort}
                      apiVersions={apiVersions}
                      fetchDocuments={fetchDocuments}
                      setServiceData={setServiceData}
                      setCurrentVersionPage={setCurrentVersionPage}
                      setLanguageDetails={setLanguageDetails}
                      setCurrentAPIPage={setCurrentAPIPage}
                      handleChange={handleChange}
                      setSelectedVersion={setSelectedVersion}
                      handleServiceDocumentListPagination={
                        handleServiceDocumentListPagination
                      }
                      setcurrentPageServiceDocumentList={
                        setcurrentPageServiceDocumentList
                      }
                      currentPageServiceDocumentList={
                        currentPageServiceDocumentList
                      }
                      service_documents_list={service_documents_list}
                    />
                  </div>
                </Paper>
              </Grid>
            </div>
          </form>
        </>
      ) : (
        <>
          {isLoader && <Loader />}
          <form onSubmit={handleSubmit}>
            <div className={styles.topContent}>
              <BreadCrumbs
                items={[
                  {
                    title: "AI Task",
                    path: "/aitask-workbench",
                  },
                  {
                    title: isEditMode
                      ? `Edit ${serviceData.name}`
                      : serviceData.name,
                  },
                ]}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ borderBottom: "2px solid #afafaf" }}
              >
                <h2 className={styles.heading}>
                  {isEditMode ? `Edit ${serviceData.name}` : serviceData.name}
                </h2>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >

                  {!isEditMode ? (
                    <AccordionNavigation
                      disabled={!checkCompPermission("Microservice", ["Edit"])}
                      isEditMode={true}
                      isClose={isEditMode ? false : true}
                      handleCancel={handleCancel}
                      handleEdit={handleEdit}
                    />
                  ) : (
                    <AccordionNavigation
                      disabled={!checkCompPermission("Microservice", ["Edit"])}
                      handleSave={handleSubmit}
                      isEditMode={false}
                      // handleCancel={() => histroy.goBack()}
                      // handleCancel={() => histroy.push(`/aitask-workbench`)}
                      handleCancel={handleCancel}
                    />
                  )}
                </div>
              </Grid>
            </div>
            <div className={styles.wrapper}>
              {serviceEntitySchema &&
                serviceEntitySchema.map((section) => {
                  if (section.name === "AITask_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <CustomFieldGroupsForAiTask
                          editVersion={editVersion}
                          section={section}
                          versionId={selectedVersionId}
                          isEditMode={isEditMode}
                          serviceData={serviceData}
                          error={error}
                          setServiceData={setServiceData}
                          handleChange={handleChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupsForAiTask
                            versionId={selectedVersionId}
                            editVersion={editVersion}
                            section={section}
                            isEditMode={isEditMode}
                            serviceData={serviceData}
                            serviceName={serviceName}
                            setCreateVersion={setCreateVersion}
                            error={error}
                            serviceId={serviceId}
                            statuses={statuses}
                            versions={versions}
                            versionsList={versionsList}
                            currentVersionPage={currentVersionPage}
                            apis={apis}
                            apisList={apisList}
                            currentAPIPage={currentAPIPage}
                            selectedVersion={selectedVersion}
                            languages={languages}
                            boilerplates={boilerplates}
                            languageDetails={languageDetails}
                            documents={documents}
                            windowDimensions={windowDimensions}
                            versionTableLoading={versionTableLoading}
                            versionTableData={versionTableData}
                            versionHidden={versionHidden}
                            addVersionHidden={addVersionHidden}
                            versionSort={versionSort}
                            addVersionSort={addVersionSort}
                            apiTableData={apiTableData}
                            apiTableLoading={apiTableLoading}
                            apiHidden={apiHidden}
                            addAPIHidden={addAPIHidden}
                            apiSort={apiSort}
                            addAPISort={addAPISort}
                            apiVersions={apiVersions}
                            fetchDocuments={fetchDocuments}
                            setServiceData={setServiceData}
                            setCurrentVersionPage={setCurrentVersionPage}
                            setLanguageDetails={setLanguageDetails}
                            setCurrentAPIPage={setCurrentAPIPage}
                            handleChange={handleChange}
                            setSelectedVersion={setSelectedVersion}
                            handleServiceDocumentListPagination={
                              handleServiceDocumentListPagination
                            }
                            setcurrentPageServiceDocumentList={
                              setcurrentPageServiceDocumentList
                            }
                            currentPageServiceDocumentList={
                              currentPageServiceDocumentList
                            }
                            service_documents_list={service_documents_list}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </div>
          </form>
        </>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  getVersionList,
  getAPIList,
  clearVersions,
  clearAPIs,
  add_service_document_data,
  add_service_documents_filter,
  add_service_documents_sort,
  addVersionHidden,
  addVersionSort,
  addAPISort,
  addAPIHidden,
  toggleVersionLoading,
  tabbedAITaskActiveTab,
  closeAITaskVersion,
  closeAITaskAPI,
};

const mapStateToProps = (state) => {
  const {
    versionsList,
    apisList,
    versionHidden,
    versionSort,
    apiSort,
    apiHidden,
  } = state.serviceReducer;
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userInfoReducer;
  const {
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
  } = state.allDocumentsReducer;

  const {
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editVersion,
    editAPI,
  } = state.aiTaskReducer;

  return {
    versionsList,
    tabbedView,
    apisList,
    userInfo,
    service_documents_list,
    serviceDocumentsFilters,
    serviceDocumentSort,
    versionHidden,
    versionSort,
    apiHidden,
    apiSort,
    selectedVersionId,
    detailVersion,
    detailAPI,
    selectedAPIId,
    editAPI,
    editVersion,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAiTaskScreen);
