import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogTitle, Grid, Typography } from "@material-ui/core";

const CustomStyles = makeStyles((theme) => ({
  cancel: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    border: "1px solid #052855",
    borderRadius: 5,
    opacity: 1,
    color: "#052855",
    textTransform: "lowercase",
  },
  submitMember: {
    background: "#052855 0% 0% no-repeat padding-box",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    opacity: 1,
    textTransform: "lowercase",
    color: "#FFFFFF",
    "&:hover": {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    },
  },

  teamMemberName: {
    fontSize: 14,
    fontWeight: "600",
  },
  teamMemberDesgination: {
    fontSize: 12,
    marginTop: 5,
    opacity: 0.8,
    textAlign: "left",
  },
}));

export default function TeamMembersModal(props) {
  const { row, open, handleClose } = props;
  const styles = CustomStyles();

 

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ height: 600 }}
      scroll="paper"
    >
      <DialogTitle
        style={{
          backgroundColor: "#0000000B",
          borderBottom: "1px solid #0000000B",
        }}
      >
        <div style={{ font: " normal normal 600 16px/22px Nunito Sans" }}>
          <span>Team Members</span>
        </div>
      </DialogTitle>
      <DialogContent style={{ height: 500, width: 600 }}>
        <div>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            {row.users &&
              row.users.map((item) => {
                return (
                  <Grid item xs={6} sm={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #CBCBCB",

                        borderRadius: 5,

                        padding: "5px",
                      }}
                    >
                      <div>
                        <img
                          src={
                            item.image
                              ? item.image
                              : "/images/defaultprofilepic.svg"
                          }
                          alt="profile"
                          height="50px"
                          width="50px"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                        }}
                      >
                        <div
                          className={styles.memberDeatils}
                          style={{
                            lineBreak: "anywhere",
                          }}
                        >
                          <Typography className={styles.teamMemberName}>
                            {item.name}
                          </Typography>

                          <p className={styles.teamMemberDesgination}>
                            {item.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
        <Button
          onClick={handleClose}
          className={styles.submitMember}
          style={{ padding: "5px 20px", textTransform: "capitalize" }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
