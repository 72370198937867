import { CommonServices } from "../../services/commonServices";
import { ApplicationServices } from "../../services/applicationServices";
// import { getFieldNameListFromArray } from "../helperFunction";
import { RuntimeEnvServices } from "../../services/runtimeEnvServices";

const CommonService = new CommonServices();
const ApplicationService = new ApplicationServices();
const RuntimeService = new RuntimeEnvServices();

export const fetchAllApplicationsList = async () => {
  try {
    const res_data = await ApplicationService.getApplicationList();
    const { _msg, _status, data } = res_data;

    if (res_data.status === 200) {
      return {
        _status,
        data,
        _msg,
      };
    } else {
      return {
        _status,
        data,
        _msg,
      };
    }
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchProjectListData = async () => {
  try {
    const res_data = await CommonService.getProjectsList();
    const { _msg, _status, data } = res_data;
    if (res_data.status === 200) {
      return {
        _status,
        data,
        _msg,
      };
    } else {
      return {
        _status,
        data,
        _msg,
      };
    }
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchServiceGroupUsingProjectID = async (projectID) => {
  try {
    const res_data = await CommonService.getServiceGroupListfromProjectID(
      projectID
    );
    const { _msg, _status, data } = res_data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export async function createApplicationService(req_data) {
  try {
    const res_data = ApplicationService.createApplication(req_data);
    const { _msg, _status, data } = res_data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function getImageList() {
  try {
    const res_data = await ApplicationService.fetchAllImgaes();
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function addAppDeployment(req_data, applicationID) {
  try {
    const res_data = await ApplicationService.addDeployment(
      req_data,
      applicationID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function getImageIDFromServiceDetails(mid, vid) {
  try {
    const res_data = await ApplicationService.fetchServiceDetails(mid, vid);
    const { _msg, _status, data } = res_data.data;
    const imageID =
      data && data["docker"]
        ? data["docker"]["imagedetails"]
          ? data["docker"]["imagedetails"]["ImageID"]
            ? data["docker"]["imagedetails"]["ImageID"]
            : ""
          : ""
        : "";
    const imagename = data["docker"]
      ? data["docker"]["imagedetails"]
        ? data["docker"]["imagedetails"]["ImageID"]
          ? data["docker"]["imagedetails"]["image"]
          : ""
        : ""
      : "";
    const { environmentVariables, microservice_name, port } = data;
    return {
      _msg,
      _status,
      data: {
        imageID: imageID,
        environmentVariables: environmentVariables ? environmentVariables : {},
        microservice_name: microservice_name ? microservice_name : "",
        port: port ? port : "",
        imagename: imagename,
      },
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function runApplicationDeplyment(applicationID) {
  try {
    const res_data = await ApplicationService.runDeployment(applicationID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function stopApplicationDeployment(applicationID) {
  try {
    const res_data = await ApplicationService.stopDeployment(applicationID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function getApplicationDataByID(applicationID) {
  try {
    const res_data = await ApplicationService.getApplicationByID(applicationID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function getApplicationTemplatesByID(applicationID) {
  try {
    const res_data = await ApplicationService.getTemplatesByApplicationID(
      applicationID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

export async function getFieldGroupDetails(fieldGroupID) {
  try {
    const res_data = await CommonService.getFieldGroupDetails(fieldGroupID);
    const { _msg, _status, data } = res_data.data;
    // const fieldNameList = getFieldNameListFromArray(data.fields);
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
}

//get field group details
export const getFieldGroupDetails_ = async (id) => {
  // var temp_fields = [];
  const { _msg, _status, data } = await getFieldGroupDetails(id);
  if (_status === 200) {
    if (data) {
      const { name, status, type, fields } = data;
      // fields && fields.length > 0 && fields.forEach((item, index) => {
      //   if (item.type !== "array" && item.type !== "object" && !item.fields) {
      //     temp_fields.push({
      //       name: item.name,
      //       type: item.type,
      //       status: item.status,
      //       value: ""
      //     })
      //   }
      // })
      return {
        _msg,
        _status,
        data: {
          fieldgroupname: name,
          status: status,
          type: type,
          fields:
            fields && fields.length > 0
              ? fields.map((item) => ({
                  name: item.name,
                  type: item.type,
                  status: item.status,
                  value: "",
                  fields: item?.fields && item?.fields?.length > 0 ? item?.fields?.map((item) => ({
                    name: item?.name,
                    type: item?.type,
                    status: item?.status,
                    value: "",
                  })) : [],
                }))
              : [],
        },
      };
    } else {
      return {
        _msg,
        _status,
        data,
      };
    }
  } else {
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editApplicationByID_ = async (id, req_data) => {
  try {
    const res_data = await ApplicationService.editApplicationByID(id, req_data);
    // const { _msg, _status, data } = res_data.data;
    return {
      _msg: res_data?.data?._msg,
      _status: res_data?.data?._status,
      data: res_data?.data?.data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteApplicationById_ = async (id) => {
  try {
    const res_data = await ApplicationService.deleteApplicationByID(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const getRuntimeDetailsByID = async (id) => {
  try {
    const res_data = await RuntimeService.getRuntimeEnvDetailsByID(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const addMicroservicesToApplication_ = async (
  applicationID,
  req_data
) => {
  try {
    const res_data = await ApplicationService.addMicroservicesToApplication(
      applicationID,
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const removeMicroserviceFromApplication_ = async (
  mid,
  applicationID
) => {
  try {
    const res_data = await ApplicationService.removeMicroserviceFromApplication(
      mid,
      applicationID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const getApplicationDeploymentDetails = async (applicationID) => {
  try {
    const res_data = await ApplicationService.getDeploymentDetails(
      applicationID
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const createApplication_ = async (req_data) => {
  try {
    const res_data = await ApplicationService.createApplication(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const getGroupServiceBundleWithImageDetails = async (req_data) => {
  try {
    const res_data =
      await ApplicationService.getGroupServiceBundleWithImageDetails(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};

export const getGroupServicesWithDependenciesDetails = async (req_data) => {
  try {
    const res_data =
      await ApplicationService.getGroupServicesWithDependenciesDetails(
        req_data
      );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _status: err?.response?.data?._status,
      _msg: err?.response?.data?._msg,
      data: err?.response?.data?.data,
    };
  }
};
