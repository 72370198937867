import { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { AppButton } from "../Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { AddCircle } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { DesktopDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { Cancel } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import dashboardStyles from "../../../screens/CommonStyles/dashboardStyles";
import {
  createChart,
  getDatasets,
  getDatasetsFields,
} from "../../../utils/dashboard/dashboardUtils";
import { Autocomplete } from "@material-ui/lab";
import { handleFormValidationForChart } from "../../../utils/common";
import moment from "moment";
import { fetchAllResourceTypeListData } from "../../../utils/accessMaintenance/accessMaintenanceUtils";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";

const graphs = [
  {
    name: "Bar Graph",
    graphs: [
      {
        title: "Bar",
        name: "bar",
      },
      {
        title: "Stack Bar",
        name: "stack_bar",
      },
    ],
  },
  {
    name: "Pie and Donut",
    graphs: [
      {
        title: "Pie",
        name: "pie",
      },
      {
        title: "Donut",
        name: "donut",
      },
    ],
  },
  {
    name: "Line Graph",
    graphs: [
      {
        title: "Line",
        name: "line",
      },
    ],
  },
];

const operatorDate = [
  {
    title: "Equals",
    exp: "eqdate",
  },
  {
    title: "Not Equals",
    exp: "noteqdate",
  },
  {
    title: "Less Than",
    exp: "less",
  },
  {
    title: "Greater Than",
    exp: "greater",
  },
  {
    title: "Less Than Equal To",
    exp: "lessequal",
  },
  {
    title: "Greater Than Equal To",
    exp: "greaterequal",
  },
  {
    title: "Between",
    exp: "between",
  },
];

const functionOperators = [
  { title: "Addition", op: "add" },
  { title: "Average", op: "avg" },
  { title: "Count", op: "count" },
  { title: "Minimum", op: "min" },
  { title: "Maximum", op: "max" },
];

const filtersOperator = [
  { title: "Equals", op: "=" },
  // { title: "operatorEqEng", op: "eq" },
  { title: "Does not Equals", op: "!=" },
  // { title: "operatorNotEqEng", op: "neq" },
  // { title: "operatorNotEqAnother", op: "<>" },
  { title: "Greater Than", op: ">" },
  // { title: "operatorGtEng", op: "gt" },
  { title: "Less Than", op: "<" },
  // { title: "operatorLtEng", op: "lt" },
  { title: "Greater Than Equal To", op: ">=" },
  // { title: "operatorGtEEng", op: "gte" },
  { title: "Less Than Equal To", op: "<=" },
  // { title: "operatorLtEEng", op: "lte" },
  { title: "Contains", op: "contains" },
  // { title: "operatorStrictContains", op: "strictContains" },
  { title: "Ends with", op: "endsWith" },
  { title: "Starts with", op: "startsWith" },
  { title: "In", op: "in" },
  { title: "Not In", op: "notIn" },
  { title: "Length Equal To", op: "leneq" },
  { title: "Length not Equal To", op: "lenneq" },
  { title: "Length Greater Than", op: "lengt" },
  { title: "Length Greater Than Equal To", op: "lengte" },
  { title: "Length Greater Than", op: "lenlt" },
  { title: "Length Less Than Equal To", op: "lenlte" },
];

const initAggregate = (id) => ({
  id: id,
  field: "",
  op: "count",
});

const AddChartModal = (props) => {
  const { isAddChartModalOpen, setIsAddChartModalOpen, handleCreateChart } =
    props;
  const styles = dashboardStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isStepOne, setIsStepOne] = useState(true);
  const [chartData, setChartData] = useState({
    chartType: "",
    graphTitle: "Chart Name",
    showLegends: true,
    dataset: "",
    fields: [],
    chartBy: "",
    filters: [
      { id: uuidv4(), field: "", op: "", value: "", continueOp: "" },
      { id: uuidv4(), field: "", op: "", value: "", continueOp: "" },
    ],
    aggregates: [initAggregate(uuidv4())],
  });
  const [error, setError] = useState({
    dataset: {
      isError: false,
      fieldLabel: "Dataset",
      msg: "",
    },
    fields: {
      isError: false,
      fieldLabel: "Fields",
      msg: "",
    },
    chartBy: {
      isError: false,
      fieldLabel: "X-Axis",
      msg: "",
    },
  });
  const [datasets, setDatasets] = useState([]);
  const [datasetFields, setDatasetFields] = useState([]);

  useEffect(() => {
    const fetchDatasets = async () => {
      const { _msg, _status, data } = await fetchAllResourceTypeListData();
      if (_status === 200) {
        let tempData = [];
        data.map((item) => {
          if (item.dashboard) {
            tempData.push(item.name);
          }
        });
        setDatasets(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchDatasets();
  }, []);

  const fetchDatasetsFields = async (resource) => {
    if (
      chartData?.dataset?.length === 0 ||
      chartData?.dataset?.length < resource?.length
    ) {
      setLoading(true);
      if (
        chartData?.dataset?.length === 0 ||
        chartData?.dataset?.length < resource?.length
      ) {
        setChartData({
          ...chartData,
          ["dataset"]: resource,
        });
        setLoading(true);

        let tempResource = resource[resource?.length - 1];

        const { _msg, _status, data } = await fetchResourceByName(tempResource);

        if (_status === 200) {
          let tempData = [...datasetFields];
          data?.sections?.map((sec) => {
            sec?.fieldgroups?.map((fieldGroup) => {
              fieldGroup?.fields?.map((field) => {
                if (field?.dashboard) {
                  let serializeData = {
                    resource: data?.name,
                    dataType: field?.dataType,
                    label: `${data?.name}.${field?.fieldLabel}`,
                    queryField: `${data?.name} ${field?.datakey}`,
                  };
                  tempData = [...tempData, serializeData];
                }
              });
            });
          });

          setDatasetFields(tempData);
          setLoading(false);
        } else {
          setLoading(false);

          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      } else {
        chartData?.dataset?.length < resource?.length;
        const deletedItem = chartData?.dataset?.filter(
          (item) => !resource.includes(item)
        );
        const removeResourceItems = datasetFields.filter(
          (item) => item.resource !== deletedItem[0]
        );
        setDatasetFields(removeResourceItems);
      }
    } else {
      chartData?.dataset?.length < resource?.length;
      const deletedItem = chartData?.dataset?.filter(
        (item) => !resource.includes(item)
      );
      const removeResourceItems = datasetFields.filter(
        (item) => item.resource !== deletedItem[0]
      );
      const filteredFields = chartData.fields.filter(
        (item) => item.resource !== deletedItem[0]
      );
      setChartData({
        ...chartData,
        fields: filteredFields,
        collections: resource,
        dataset: resource,
      });
      setDatasetFields(removeResourceItems);
    }
  };

  useEffect(() => {
    const fetchDatasetsFields = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getDatasetsFields(
        chartData.dataset
      );
      if (_status === 200) {
        setDatasetFields(data ? data : []);
        setChartData({
          ...chartData,
          chartBy: "",
          fields: [],
          filters: [
            { id: uuidv4(), field: "", op: "", value: "", continueOp: "" },
            { id: uuidv4(), field: "", op: "", value: "", continueOp: "" },
          ],
          aggregates: [initAggregate(uuidv4())],
        });
        setLoading(false);
      } else {
        setLoading(false);

        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    // if (chartData.dataset) {
    //   fetchDatasetsFields();
    // }
  }, [chartData.dataset]);

  const handleAddAggregate = () => {
    const id = uuidv4();
    setChartData((prevChartData) => ({
      ...prevChartData,
      aggregates: [...prevChartData.aggregates, initAggregate(id)],
    }));
  };

  const handleRemoveAggregate = (queryToDelete) => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      aggregates: prevChartData.aggregates.filter(
        (ag) => ag.id !== queryToDelete.id
      ),
    }));
  };

  const handleChange = (name, value, id) => {
    if (Object.keys(error).includes(name)) {
      setError({
        ...error,
        [name]: {
          ...error[name],
          isError: false,
          msg: "",
        },
      });
    }
    if (name === "field" || name === "op" || name === "groupBy") {
      setChartData((prevChartData) => ({
        ...prevChartData,
        aggregates: prevChartData.aggregates.map((ag) => {
          if (ag.id === id) {
            return { ...ag, [name]: value };
          } else {
            return ag;
          }
        }),
      }));
    } else {
      setChartData({
        ...chartData,
        [name]: value,
      });
    }
  };

  const handleFilterChange = (event, id, name) => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      filters: prevChartData.filters.map((filter) => {
        if (filter.id === id) {
          return { ...filter, [name]: event.target.value };
        } else {
          return filter;
        }
      }),
    }));
  };

  const handleAddFilter = () => {
    const id = uuidv4();
    setChartData((prevChartData) => ({
      ...prevChartData,
      filters: [
        ...prevChartData.filters,
        { id: id, field: "", op: "", value: "", continueOp: "" },
      ],
    }));
  };

  const handleDeleteFilter = (filterToDelete) => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      filters: prevChartData.filters.filter(
        (filter) => filter.id !== filterToDelete
      ),
    }));
  };

  const getOperatorsByField = (fieldType) => {
    switch (fieldType) {
      case "string":
        return functionOperators.filter((operator) => operator.op === "count");
      case "interger":
      case "number":
        return functionOperators;
      case "date":
        return functionOperators.filter(
          (operator) =>
            operator.op === "count" ||
            operator.op === "max" ||
            operator.op === "min"
        );
      default:
        return functionOperators;
    }
  };

  const handleSubmit = async () => {
    if (chartData.graphTitle) {
      const { formIsValid, errors } = handleFormValidationForChart(
        chartData,
        error
      );
      if (formIsValid) {
        const reqChartData = {
          ...chartData,
          collections: chartData?.dataset,
          "X-axis": chartData.chartBy?.queryField,
          aggregates: chartData.aggregates.map((ag) => ({
            ...ag,
            field: ag.field?.queryField,
            groupBy: ag?.groupBy?.queryField,
          })),
          filters: chartData.filters
            .filter((f) => f.field && f.op && f.value)
            .map((filter) => {
              if (filter?.field?.dataType === "date") {
                return {
                  ...filter,
                  field: filter?.field?.queryField,
                  dataType: filter?.field?.dataType,
                  value: parseInt(
                    moment(filter.value).startOf("day").format("X")
                  ),
                };
              } else {
                return {
                  ...filter,
                  field: filter?.field?.queryField,
                  dataType: filter?.field?.dataType,
                };
              }
            }),
        };
        delete reqChartData["chartBy"];
        delete reqChartData["dataset"];
        setLoading(true);
        const { _msg, _status, data } = await createChart(reqChartData);
        if (_status === 201) {
          setLoading(false);
          setIsAddChartModalOpen(false);
          enqueueSnackbar(_msg ? _msg : "Chart create successfully", {
            variant: "success",
          });
          handleCreateChart(data);
        } else {
          setLoading(false);

          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      } else {
        setError(errors);
      }
    } else {
      enqueueSnackbar("Please enter Chart Name", { variant: "error" });
    }
  };

  const aggregateValidation = (data, type) => {
    const isFound = error?.aggregates?.find((item) => item.id === data && item.type === type)
    if(isFound){
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        maxWidth={"lg"}
        fullWidth={true}
        open={isAddChartModalOpen}
        scroll="paper"
        onClose={() => setIsAddChartModalOpen(false)}
      >
        <DialogTitle className={styles.head}>
          <div className={styles.dialogTitle}>
            <input
              name="graphTitle"
              placeholder="Enter Chart Name"
              className={`${styles.chartNameInput}`}
              value={chartData.graphTitle}
              onChange={(e) =>
                setChartData({ ...chartData, graphTitle: e.target.value })
              }
              autoComplete="off"
            />
            <div className={styles.steps}>
              <h5 className={isStepOne === true ? styles.currentStep : ""}>
                Step 1 : Select Graph
              </h5>
              <NavigateNextIcon fontSize="small" />
              <h5 className={isStepOne === false ? styles.currentStep : ""}>
                Step 2 : Data
              </h5>
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          className={styles.modalContent}
          style={{ height: "33.8rem" }}
        >
          {isStepOne ? (
            <div className={styles.selectGraphStep}>
              {graphs.map((item, index) => (
                <div className={styles.selectGraphContainer} key={index}>
                  <h4>{item.name}</h4>
                  <Grid container spacing={2}>
                    {item.graphs.map((graph, i) => (
                      <Grid item sm={3} xs={3} key={i}>
                        <div
                          className={`${styles.selectGraph} ${
                            graph.name === chartData.chartType
                              ? styles.selectedGraph
                              : ""
                          }`}
                          onClick={() =>
                            setChartData({
                              ...chartData,
                              chartType: graph.name,
                            })
                          }
                        >
                          <img
                            src={`/images/chart/${graph.name}.svg`}
                            alt={graph.name}
                          />
                          <h4>{graph.title}</h4>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.queryStep}>
              <div className={styles.queryContent}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    sm={5}
                    xs={5}
                    style={{ borderRight: "1px solid #D9D9D9" }}
                  >
                    <div className={styles.field}>
                      <label className={styles.fieldHeading}>
                        Select Objects <span className={styles.red}>*</span>
                      </label>
                      <Autocomplete
                        multiple
                        options={datasets}
                        //  getOptionLabel={(option) => option?.name}
                        //  value={chartData?.fields ? chartData?.fields : []}
                        className={`${styles.autocomplete} ${
                          error?.fields.isError ? styles.error : ""
                        }`}
                        onChange={(e, input) => {
                          if (input) {
                            // handleChange("dataset", input)
                            fetchDatasetsFields(input);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search Object"
                          />
                        )}
                      />
                      {/* <Select
                        name={"dataset"}
                        value={chartData.dataset}
                        onChange={(e) =>
                          handleChange("dataset", e.target.value)
                        }
                        className={`${styles.select} ${
                          error.dataset.isError ? styles.error : ""
                        }`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {datasets.map((dataset) => (
                          <MenuItem value={dataset} key={dataset}>
                            {dataset}
                          </MenuItem>
                        ))}
                      </Select> */}
                      {error?.dataset.isError && (
                        <p className={styles.errorMsg}>{error?.dataset.msg}</p>
                      )}
                    </div>
                    <div className={styles.field}>
                      <label className={styles.fieldHeading}>
                        Select Fields <span className={styles.red}>*</span>
                      </label>
                      <Autocomplete
                        multiple
                        options={datasetFields}
                        getOptionLabel={(option) => option?.label}
                        value={chartData?.fields ? chartData?.fields : []}
                        className={`${styles.autocomplete} ${
                          error?.fields.isError ? styles.error : ""
                        }`}
                        groupBy={(option) => option?.resource}
                        filterSelectedOptions
                        onChange={(e, input) => {
                          if (input) {
                            handleChange("fields", input);
                          } else {
                            handleChange("fields", []);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search Field"
                          />
                        )}
                      />
                      {error?.fields.isError && (
                        <p className={styles.errorMsg}>{error?.fields.msg}</p>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label className={styles.fieldHeading}>
                        X-Axis <span className={styles.red}>*</span>
                      </label>
                      <Autocomplete
                        options={chartData?.fields}
                        getOptionLabel={(option) => option?.label}
                        value={chartData?.chartBy ? chartData?.chartBy : ""}
                        className={`${styles.searchInput3} ${
                          error?.chartBy.isError ? styles.error : ""
                        }`}
                        onChange={(e, input) => {
                          if (input) {
                            handleChange("chartBy", input);
                          } else {
                            handleChange("chartBy", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Select X-Axis"
                          />
                        )}
                      />
                      {error?.chartBy.isError && (
                        <p className={styles.errorMsg}>{error?.chartBy.msg}</p>
                      )}
                    </div>

                    <div
                      className={styles.filterHeader}
                      style={{ marginBottom: 5 }}
                    >
                      <h5 className={styles.filterText}>Functions</h5>
                    </div>
                    {chartData.aggregates.map((aggregate, index) => (
                      <div
                        className={styles.field}
                        style={{
                          border: "1px solid #d9d9d9",
                          padding: "10px 15px",
                        }}
                      >
                        <div
                          className={styles.queryTabContent}
                          style={{ marginBottom: 5 }}
                        >
                          <span
                            className={styles.fieldHeading}
                            style={{ textAlign: "center" }}
                          >
                            Function {index + 1}
                          </span>
                          <IconButton
                            color="primary"
                            aria-label="delete"
                            edge="start"
                            style={{ padding: 0 }}
                            className={"actionIcon"}
                            onClick={() => handleRemoveAggregate(aggregate)}
                          >
                            <Cancel className={styles.cancelIcon} />
                          </IconButton>
                        </div>
                        <div className={styles.field}>
                          <label className={styles.fieldHeading}>Field</label>
                          <Autocomplete
                            options={chartData?.fields}
                            getOptionLabel={(option) => option?.label}
                            value={aggregate?.field ? aggregate?.field : ""}
                            className={`${styles.searchInput3} ${
                              aggregateValidation(aggregate?.id, 'field') ? styles.error : ""
                            }`}
                            onChange={(e, input) => {
                              if (input) {
                                handleChange("field", input, aggregate.id);
                              } else {
                                handleChange("field", "", aggregate.id);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select Field"
                              />
                            )}
                          />
                          {aggregateValidation(aggregate?.id, 'field') && <p className={styles.errorMsg}>Field required</p>}

                        </div>
                        <div className={styles.field}>
                          <label className={styles.fieldHeading}>
                            Operator <span className={styles.red}>*</span>
                          </label>
                          <Select
                            name={"op"}
                            value={aggregate?.op ? aggregate?.op : ""}
                            onChange={(e) =>
                              handleChange(
                                e.target.name,
                                e.target.value,
                                aggregate.id
                              )
                            }
                            className={`${styles.select}`}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {getOperatorsByField(
                              aggregate?.field?.dataType
                                ? aggregate?.field?.dataType
                                : chartData?.chartBy?.dataType
                            ).map((operator) => (
                              <MenuItem value={operator.op} key={operator.op}>
                                {aggregate?.field.label
                                  ? aggregate?.field.label
                                  : chartData?.chartBy?.label}{" "}
                                {operator.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        {chartData.chartType === "stack_bar" && (
                          <div className={styles.field}>
                            <label className={styles.fieldHeading}>
                              Group by <span className={styles.red}>*</span>
                            </label>
                            <Autocomplete
                              options={chartData?.fields}
                              getOptionLabel={(option) => option?.label}
                              value={
                                aggregate?.groupBy ? aggregate?.groupBy : ""
                              }
                              className={`${styles.searchInput3} ${
                                aggregateValidation(aggregate?.id, 'groupBy') ? styles.error : ""
                              }`}
                              onChange={(e, input) => {
                                if (input) {
                                  handleChange("groupBy", input, aggregate.id);
                                } else {
                                  handleChange("groupBy", "", aggregate.id);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Select Group By"
                                />
                              )}
                            />
                            {aggregateValidation(aggregate?.id, 'groupBy') && <p className={styles.errorMsg}>Group by required</p>}

                          </div>
                        )}
                      </div>
                    ))}
                    <div
                      className={styles.filterHeader}
                      style={{ marginBottom: 5, justifyContent: "center" }}
                    >
                      <IconButton
                        color="primary"
                        edge="start"
                        className={styles.addFilterBtn}
                        onClick={handleAddAggregate}
                      >
                        <AddCircle className={styles.addQueryBtn} />
                        <h5>Add Function</h5>
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item sm={7} xs={7}>
                    <div className={styles.filterHeader}>
                      <h5 className={styles.filterText}>Filters</h5>
                      <IconButton
                        color="primary"
                        edge="start"
                        className={styles.addFilterBtn}
                        onClick={handleAddFilter}
                      >
                        <AddCircle className={styles.addQueryBtn} />
                        <h5>Add</h5>
                      </IconButton>
                    </div>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                      <div className={styles.filterContent}>
                        {chartData?.filters &&
                          chartData?.filters?.length > 0 &&
                          chartData?.filters?.map((filter, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={2} sm={2}>
                                {index !== 0 && (
                                  <>
                                    <label className={styles.fieldLabel}>
                                      Operator
                                    </label>
                                    <Select
                                      value={filter.continueOp}
                                      onChange={(e) =>
                                        handleFilterChange(
                                          e,
                                          filter.id,
                                          "continueOp"
                                        )
                                      }
                                      name={filter.id}
                                      className={`${styles.select}`}
                                      style={{ paddingRight: 0 }}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      <MenuItem value="and">And</MenuItem>
                                      <MenuItem value="or">Or</MenuItem>
                                    </Select>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                <label htmlFor="" className={styles.fieldLabel}>
                                  Columns
                                </label>
                                <Select
                                  value={filter.field}
                                  onChange={(e) =>
                                    handleFilterChange(e, filter.id, "field")
                                  }
                                  displayEmpty
                                  name={filter.id}
                                  className={`${styles.select}`}
                                  style={{ paddingRight: 0 }}
                                  inputProps={{ "aria-label": "Without label" }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {chartData?.fields.map((field, index) => (
                                    <MenuItem value={field} key={index}>
                                      {field?.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                <label htmlFor="" className={styles.fieldLabel}>
                                  Operator
                                </label>
                                <Select
                                  value={filter.op}
                                  onChange={(e) =>
                                    handleFilterChange(e, filter.id, "op")
                                  }
                                  name={filter.id}
                                  className={`${styles.select}`}
                                  style={{ paddingRight: 0 }}
                                  inputProps={{ "aria-label": "Without label" }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {filtersOperator.map((op, index) => (
                                    <MenuItem value={op.op} key={index}>
                                      {op.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                <label htmlFor="" className={styles.fieldLabel}>
                                  Value
                                </label>
                                {filter?.field?.dataType === "date" ? (
                                  <DesktopDatePicker
                                    value={filter.value ? filter.value : null}
                                    onChange={(date) =>
                                      handleFilterChange(
                                        { target: { value: date } },
                                        filter.id,
                                        "value"
                                      )
                                    }
                                    inputFormat={"dd/MM/yyyy"}
                                    renderInput={({
                                      inputRef,
                                      inputProps,
                                      InputProps,
                                    }) => (
                                      <>
                                        <div className={styles.dateInput}>
                                          <input
                                            ref={inputRef}
                                            {...inputProps}
                                            className={`${styles.input}`}
                                          />
                                          {InputProps?.endAdornment}
                                        </div>
                                      </>
                                    )}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    name="value"
                                    className={`${styles.input}`}
                                    value={filter.value}
                                    onChange={(e) =>
                                      handleFilterChange(e, filter.id, "value")
                                    }
                                    autoComplete="off"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={1} sm={1}>
                                <IconButton
                                  color="primary"
                                  aria-label="delete"
                                  edge="start"
                                  style={{ marginTop: index === 0 ? 20 : 0 }}
                                  className={"actionIcon"}
                                  onClick={() => handleDeleteFilter(filter.id)}
                                >
                                  <Cancel className={styles.cancelIcon} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                      </div>
                    </LocalizationProvider>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={chartData.showLegends}
                            color="primary"
                            onChange={(e) =>
                              setChartData({
                                ...chartData,
                                showLegends: e.target.checked,
                              })
                            }
                          />
                        }
                        label={"Show Legends"}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          className={`${styles.footer} ${!isStepOne ? styles.justiSpace : ""}`}
        >
          {!isStepOne && (
            <AppButton
              buttonName="Previous"
              variant="outlined"
              disabled={false}
              className="btnsmall"
              onClick={() => setIsStepOne(true)}
            />
          )}
          <div style={{ display: "flex" }}>
            <AppButton
              buttonName="Cancel"
              variant="outlined"
              disabled={false}
              className="btnsmall"
              onClick={() => setIsAddChartModalOpen(false)}
            />
            <AppButton
              buttonName={isStepOne ? "Next" : "Create"}
              variant="contained"
              disabled={chartData.chartType ? false : true}
              className="btnsmall"
              onClick={() => (isStepOne ? setIsStepOne(false) : handleSubmit())}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddChartModal;
