import { CommonServices } from "../../services/commonServices";

const commonService = new CommonServices();

export const fetchFieldGroupListData = async () => {
  try {
    const res_data = await commonService.getFieldGroups();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchFieldGroupDetails = async (fieldGroupID) => {
  try {
    const res_data = await commonService.getFieldGroupDetails(fieldGroupID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};
