import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Checkbox,
  Paper,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import Loader from "../../../components/common/stuff/Loader";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CreateTeamEntity from "../../../components/common/entitySchema/teams/CreateTeamEntity";
import { createTeam, fetchUsers } from "../../../utils/teams/teamsUtils";
import { getVendorList } from "../../../utils/vendor/vendorUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import AddIcon from "@material-ui/icons/Add";
import createDetailsStyles from "../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const CreateLaunguageScreen = (props) => {
  const styles = manageStyles();
  const createScreenstyles = createDetailsStyles();
  const histroy = useHistory();
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState("");
  const [users, setUsers] = useState([]);
  const [vendorData, setVendorData] = useState("");
  const [error, setError] = useState({});
  const [teamData, setteamData] = useState({});
  const [languageEntitySchema, setlanguageEntitySchema] = useState({});
  const [searchInput, setSearchInput] = useState(null);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Team", ["Create"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchlanguageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Team",
        "create"
      );
      console.log("klnelkfef", data);
      if (_status === 200) {
        setlanguageEntitySchema(data);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                teamData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setteamData({ ...teamData, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError(errData);
        console.log("lknlkefe", errData);

        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchlanguageEntitySchema();
  }, []);

  const handleCancel = () => {
    histroy.push("/teams");
  };

  const handleChange = async (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === "vendorid") {
      setteamData({
        ...teamData,
        vendorid: value,
        vendorName: vendorData.find((vendor) => vendor._id === value)?.name,
      });
    } else {
      setteamData({
        ...teamData,
        [event.target.name]: value,
      });
    }
  };

  /*--------------------- */
  // Add team modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSearchInput("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  // vendor data
  useEffect(() => {
    const fetchVendorData = async () => {
      const { _msg, _status, data } = await getVendorList();
      if (_status === 200) {
        setVendorData(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchVendorData();
  }, []);

  // users data
  useEffect(() => {
    fetchUserData();
  }, []);
  const fetchUserData = async (filter) => {
    setIsLoader(true);
    const { _msg, _status, data } = await fetchUsers(filter);
    if (_status === 200) {
      setUserDetails(
        data
          ? data
              ?.filter((u) => u.status === "active")
              ?.map((item) => ({ ...item, checked: false }))
          : []
      );
      setIsLoader(false);
    } else {
      setIsLoader(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  // select user id
  let usersWithNoDuplicate = [...new Set(users)];
  const handleAddMember = (member) => {
    if (member?.checked) {
      setUsers(users.filter((u) => u._id !== member._id));
    } else {
      let data = users;
      data.push(member);
      setUsers(data);
    }
    setUserDetails((users) =>
      users?.map((u) => {
        if (u._id === member._id) {
          return { ...u, checked: !member?.checked };
        } else {
          return u;
        }
      })
    );
  };

  const handleDelete = (index, member) => {
    var newUserList = usersWithNoDuplicate;
    newUserList.splice(index, 1);
    setUsers(newUserList);
    setUserDetails((users) =>
      users?.map((u) => {
        if (u._id === member._id) {
          return { ...u, checked: member?.checked };
        } else {
          return u;
        }
      })
    );
  };

  // serach user
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      fetchUserData(JSON.stringify({ name: { $regex: searchInput } }));
    } else {
      fetchUserData();
    }
  };

  /*--------------------- */
  const handleSubmit = async () => {
    if (teamData.type === "internal") {
      delete teamData["vendorid"];
    }
    teamData["users"] = users.map((item) => item._id);
    console.log("knlekfe", teamData);
    if (
      teamData.type === "" ||
      (teamData.type === "internal" && teamData.users.length === 0)
    ) {
      enqueueSnackbar("Please select atleast one member", {
        variant: "error",
      });
    }

    console.log("kljlfjelfe", teamData, error);

    let { formIsValid, errors } = handleFormValidationPro(teamData, error);
    console.log("enfe", formIsValid);

    for (var obj in error) {
      if (error[obj].dataType === "array") {
        console.log("nkeklfneff", teamData);
        if (teamData[obj]?.length === 0) {
          error[obj] = {
            ...error[obj],
            isError: true,
            msg: `${error[obj].fieldLabel} is required`,
          };
          formIsValid = false;
        }
      }
    }
    for (var obj in error) {
      if (error[obj].isError == false) {
        delete error[obj];
      }
    }
    errors = { ...errors, ...error };
    console.log("lkjalkef", errors);

    if (formIsValid) {
      setIsLoader(true);
      var req_body = {
        ...teamData,
      };

      const { _msg, _status, data } = await createTeam(req_body);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setIsLoader(false);

        histroy.push("/teams");
      } else {
        setIsLoader(false);
        if (data && data?.length > 0) {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        languageEntitySchema ? languageEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      console.log("knlknefe", languageEntitySchema, tempEntity, errors);
      if (users.length == 0) {
        tempEntity[1]["isError"] = true;
      } else {
        tempEntity[1]["isError"] = false;
      }
      setActiveTab(errorInTab);
      setlanguageEntitySchema({
        ...languageEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  console.log("lknn8n", languageEntitySchema);

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Teams", path: "/teams" },
              { title: "Create Team", path: "" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create Team</h2>
            {tabbedView ? (
              <TabbedNavigation
                // schemaLength={languageEntitySchema?.sections?.length}
                isCreateMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                disabled={false}
                isCreateMode={true}
                handleSave={handleSubmit}
                isEditMode={false}
                handleCancel={handleCancel}
              />
              // <AppButton
              //   color="primary"
              //   buttonName="Create"
              //   variant="contained"
              //   disabled={false}
              //   style={{ margin: "unset" }}
              //   className="btnsmall"
              //   onClick={handleSubmit}
              // />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {console.log("oieonfioen", languageEntitySchema)}
          {tabbedView ? (
            <>
              <SectionTabs
                entity={
                  languageEntitySchema ? languageEntitySchema?.sections : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <CreateTeamEntity
                    section={languageEntitySchema?.sections?.[activeTab]}
                    teamData={teamData}
                    error={error}
                    setteamData={setteamData}
                    handleChange={handleChange}
                    vendorData={vendorData}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    isLoader={isLoader}
                    userDetails={userDetails}
                    handleAddMember={handleAddMember}
                    handleDelete={handleDelete}
                    users={users}
                  />
                  {languageEntitySchema?.sections &&
                    languageEntitySchema?.sections.map((section) => {
                      if (section.name === "Team_Users" && activeTab === 1)
                        return (
                          <div>
                            <div className={createScreenstyles.addTeamMember}>
                              <div>
                                <h4>
                                  Add Team member{" "}
                                  <span className={styles.red}>*</span>
                                </h4>
                              </div>
                              <div>
                                <div
                                  className={createScreenstyles.addTeamModal}
                                  onClick={handleClickOpen}
                                >
                                  <AddIcon />
                                  <span>Add</span>
                                </div>

                                <Dialog open={open} onClose={handleClose}>
                                  <DialogTitle
                                    style={{
                                      padding: "0 25px",
                                      backgroundColor: "#F9FAFD",
                                    }}
                                  >
                                    <div
                                      className={
                                        createScreenstyles.assignProject
                                      }
                                    >
                                      <div>
                                        <span
                                          style={{
                                            font: "normal normal 600 14px/19px Nunito",
                                          }}
                                        >
                                          Add Team member
                                        </span>
                                      </div>
                                      <div>
                                        <FilledInput
                                          name="filterfn"
                                          className={createScreenstyles.input}
                                          style={{
                                            width: "100%",
                                            padding: "0px 12px 10px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          // placeholder="Search"
                                          onChange={(e) =>
                                            setSearchInput(e.target.value)
                                          }
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              style={{ paddingTop: 15 }}
                                            >
                                              <IconButton
                                                onClick={handleSearch}
                                                onMouseDown={handleSearch}
                                              >
                                                <img
                                                  src="/images/search.svg"
                                                  alt="card"
                                                />
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </DialogTitle>

                                  <DialogContent>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {isLoader && (
                                        <CircularProgress
                                          color="primary"
                                          size={35}
                                        />
                                      )}
                                    </div>
                                    <div style={{ height: 400, width: 550 }}>
                                      <Grid container>
                                        {userDetails &&
                                          userDetails?.map((member, _id) => (
                                            <Grid item xs={12} key={_id}>
                                              <Paper
                                                className={
                                                  createScreenstyles.selectMemebrModal
                                                }
                                              >
                                                <div
                                                  className={
                                                    createScreenstyles.teamWrapper2
                                                  }
                                                >
                                                  <Checkbox
                                                    color="primary"
                                                    checked={member.checked}
                                                    onChange={(e) =>
                                                      handleAddMember(member)
                                                    }
                                                  />

                                                  <div
                                                    className={
                                                      createScreenstyles.memberDeatils2
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        createScreenstyles.teamMemberName
                                                      }
                                                    >
                                                      {member.name}
                                                    </Typography>
                                                    <Typography
                                                      className={
                                                        createScreenstyles.teamMemberDesgination
                                                      }
                                                    >
                                                      {member.email}
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </Paper>
                                            </Grid>
                                          ))}
                                      </Grid>
                                    </div>
                                  </DialogContent>

                                  <DialogActions
                                    style={{
                                      backgroundColor: "#F9FAFD",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div></div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: 250,
                                      }}
                                    >
                                      <Button
                                        onClick={handleClose}
                                        className={createScreenstyles.cancel}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={handleClose}
                                        className={
                                          createScreenstyles.submitMember
                                        }
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Add Team members
                                      </Button>
                                    </div>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>

                            <Grid
                              container
                              spacing={3}
                              style={{ marginTop: 10 }}
                            >
                              {users &&
                                users.map((item, index) => {
                                  return (
                                    <Grid item xs={3} sm={3}>
                                      <div
                                        className={
                                          createScreenstyles.teamMemberswrapper
                                        }
                                      >
                                        <div>
                                          <img
                                            src={
                                              item.image
                                                ? item.image
                                                : "/images/defaultprofilepic.svg"
                                            }
                                            alt="profile"
                                            height="50px"
                                            width="50px"
                                            style={{ borderRadius: "50%" }}
                                          />
                                        </div>

                                        <div
                                          style={{
                                            marginLeft: 15,
                                            width: "12rem",
                                            lineBreak: "anywhere",
                                          }}
                                        >
                                          <Typography
                                            className={
                                              createScreenstyles.teamMemberName
                                            }
                                            style={{ textAlign: "left" }}
                                          >
                                            {item.name}
                                          </Typography>
                                          <Typography
                                            className={
                                              createScreenstyles.teamMemberDesgination
                                            }
                                          >
                                            {item.email}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: 5,
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDelete(index, item)
                                          }
                                        >
                                          <img
                                            src="/images/governance/delete.svg"
                                            alt=""
                                            height="20px"
                                            width="20px"
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </div>
                        );
                    })}
                </div>
              </Paper>
            </>
          ) : (
            <div>
              {languageEntitySchema?.sections &&
                languageEntitySchema?.sections.map((section) => {
                  if (section.name === "Team_Header_section") {
                    return (
                      <div key={section._id} style={{ padding: "20px 5px 0" }}>
                        <CreateTeamEntity
                          section={section}
                          teamData={teamData}
                          error={error}
                          setteamData={setteamData}
                          handleChange={handleChange}
                          vendorData={vendorData}
                          open={open}
                          handleClickOpen={handleClickOpen}
                          handleClose={handleClose}
                          isLoader={isLoader}
                          userDetails={userDetails}
                          handleAddMember={handleAddMember}
                          handleDelete={handleDelete}
                          users={users}
                        />
                      </div>
                    );
                  } else if (section.name === "Team_Users") {
                    return (
                      <Accordion
                        className={createScreenstyles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={createScreenstyles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={createScreenstyles.addTeamMember}>
                            <div>
                              <h4>
                                Add Team member{" "}
                                <span className={styles.red}>*</span>
                              </h4>
                            </div>
                            <div>
                              <div
                                className={createScreenstyles.addTeamModal}
                                onClick={handleClickOpen}
                              >
                                <AddIcon />
                                <span>Add</span>
                              </div>

                              <Dialog open={open} onClose={handleClose}>
                                <DialogTitle
                                  style={{
                                    padding: "0 25px",
                                    backgroundColor: "#F9FAFD",
                                  }}
                                >
                                  <div
                                    className={createScreenstyles.assignProject}
                                  >
                                    <div>
                                      <span
                                        style={{
                                          font: "normal normal 600 14px/19px Nunito",
                                        }}
                                      >
                                        Add Team member
                                      </span>
                                    </div>
                                    <div>
                                      <FilledInput
                                        name="filterfn"
                                        className={createScreenstyles.input}
                                        style={{
                                          width: "100%",
                                          padding: "0px 12px 10px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        // placeholder="Search"
                                        onChange={(e) =>
                                          setSearchInput(e.target.value)
                                        }
                                        endAdornment={
                                          <InputAdornment
                                            position="end"
                                            style={{ paddingTop: 15 }}
                                          >
                                            <IconButton
                                              onClick={handleSearch}
                                              onMouseDown={handleSearch}
                                            >
                                              <img
                                                src="/images/search.svg"
                                                alt="card"
                                              />
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                    </div>
                                  </div>
                                </DialogTitle>

                                <DialogContent>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {isLoader && (
                                      <CircularProgress
                                        color="primary"
                                        size={35}
                                      />
                                    )}
                                  </div>
                                  <div style={{ height: 400, width: 550 }}>
                                    <Grid container>
                                      {userDetails &&
                                        userDetails?.map((member, _id) => (
                                          <Grid item xs={12} key={_id}>
                                            <Paper
                                              className={
                                                createScreenstyles.selectMemebrModal
                                              }
                                            >
                                              <div
                                                className={
                                                  createScreenstyles.teamWrapper2
                                                }
                                              >
                                                <Checkbox
                                                  color="primary"
                                                  checked={member.checked}
                                                  onChange={(e) =>
                                                    handleAddMember(member)
                                                  }
                                                />

                                                <div
                                                  className={
                                                    createScreenstyles.memberDeatils2
                                                  }
                                                >
                                                  <Typography
                                                    className={
                                                      createScreenstyles.teamMemberName
                                                    }
                                                  >
                                                    {member.name}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      createScreenstyles.teamMemberDesgination
                                                    }
                                                  >
                                                    {member.email}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </Paper>
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </div>
                                </DialogContent>

                                <DialogActions
                                  style={{
                                    backgroundColor: "#F9FAFD",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div></div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      width: 250,
                                    }}
                                  >
                                    <Button
                                      onClick={handleClose}
                                      className={createScreenstyles.cancel}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleClose}
                                      className={
                                        createScreenstyles.submitMember
                                      }
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Add Team members
                                    </Button>
                                  </div>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>

                          <Grid container spacing={3} style={{ marginTop: 10 }}>
                            {users &&
                              users.map((item, index) => {
                                return (
                                  <Grid item xs={3} sm={3}>
                                    <div
                                      className={
                                        createScreenstyles.teamMemberswrapper
                                      }
                                    >
                                      <div>
                                        <img
                                          src={
                                            item.image
                                              ? item.image
                                              : "/images/defaultprofilepic.svg"
                                          }
                                          alt="profile"
                                          height="50px"
                                          width="50px"
                                          style={{ borderRadius: "50%" }}
                                        />
                                      </div>

                                      <div
                                        style={{
                                          marginLeft: 15,
                                          width: "12rem",
                                          lineBreak: "anywhere",
                                        }}
                                      >
                                        <Typography
                                          className={
                                            createScreenstyles.teamMemberName
                                          }
                                          style={{ textAlign: "left" }}
                                        >
                                          {item.name}
                                        </Typography>
                                        <Typography
                                          className={
                                            createScreenstyles.teamMemberDesgination
                                          }
                                        >
                                          {item.email}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDelete(index, item)
                                        }
                                      >
                                        <img
                                          src="/images/governance/delete.svg"
                                          alt=""
                                          height="20px"
                                          width="20px"
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CreateTeamEntity
                            section={section}
                            teamData={teamData}
                            error={error}
                            handleChange={handleChange}
                            vendorData={vendorData}
                            open={open}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            isLoader={isLoader}
                            userDetails={userDetails}
                            handleAddMember={handleAddMember}
                            handleDelete={handleDelete}
                            users={users}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLaunguageScreen);
