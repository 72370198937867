import {
  EDIT_UI_APPLICAION,
  UIAPPLICATION_LIST_DATA,
  UIAPPLICATION_UPDATE,
  UIAPPLICATION_FILTER,
  UIAPPLICATION_SORT,
  UIAPPLICATION_HIDDEN_COULMNS,
  DELETE_UI_APPLICAION,
  TEMPLATE_LIST_DATA,
  TEMPLATE_FILTER,
  TEMPLATE_SORT,
  TEMPLATE_HIDDEN_COULMNS,
  DELETE_TEMPLATE,
  SELECTED_TEMPLATE,
  SELECTED_PAGE,
  ALTER_FAVOURITE_UI_APPLICATION_BY_ID,
  ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID,
  SET_GLOBAL_CSS,
  LINK_UIAPPLICATION_ID,
  UNLINK_UIAPPLICATION_ID,
} from "../uiActionTypes";
import { UiApplicationService } from "../../UiApplication/services/UiApplicationService";
const uiApplicationService = new UiApplicationService();

export const editUiApplication = (data) => async (dispatch) => {
  dispatch({
    type: EDIT_UI_APPLICAION,
    payload: data,
  });
};

export const addLinkedUiApplicationId =
  (compId, uiAppId) => async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: LINK_UIAPPLICATION_ID,
      payload: {
        data: {
          ...application,
          ["linkedUiApplications"]: updateLinkedUiApplicationArr(
            application,
            compId,
            uiAppId
          ),
        },
      },
    });
  };

const updateLinkedUiApplicationArr = (application, compId, uiAppId) => {
  if (
    application?.linkedUiApplications === undefined ||
    !Array.isArray(application?.linkedUiApplications)
  ) {
    return [{ ["linkedComps"]: [compId], ["uiAppId"]: uiAppId }];
  } else if (
    application?.linkedUiApplications.length &&
    application?.linkedUiApplications
      .map((linkeduiApp) =>
        linkeduiApp?.linkedComps.some((linkedCompId) => linkedCompId === compId)
      )
      .some((bool) => bool)
  ) {
    let flag = true;
    const res = application?.linkedUiApplications
      .map((linkedUiApp) => {
        if (
          linkedUiApp?.linkedComps.some(
            (linkedCompId) => linkedCompId === compId
          ) &&
          linkedUiApp?.uiAppId !== uiAppId
        ) {
          flag = !flag;
          return {
            ...linkedUiApp,
            ["linkedComps"]: linkedUiApp?.linkedComps.filter(
              (linkedCompId) => linkedCompId !== compId
            ),
          };
        } else if (
          !linkedUiApp?.linkedComps.some(
            (linkedCompId) => linkedCompId === compId
          ) &&
          linkedUiApp?.uiAppId === uiAppId
        ) {
          flag = !flag;
          return {
            ...linkedUiApp,
            ["linkedComps"]: [...linkedUiApp?.linkedComps, compId],
          };
        }
        return linkedUiApp;
      })
      .filter((linkedUiApp) => linkedUiApp?.linkedComps.length);
    if (flag) return res;
    return [
      ...res,
      {
        ["linkedComps"]: [compId],
        ["uiAppId"]: uiAppId,
      },
    ];
  } else if (
    application?.linkedUiApplications.length &&
    application?.linkedUiApplications.some(
      (linkedUiApp) => linkedUiApp?.uiAppId === uiAppId
    )
  ) {
    return application?.linkedUiApplications.map((linkedUiApp) =>
      linkedUiApp?.uiAppId === uiAppId
        ? {
            ...linkedUiApp,
            ["linkedComps"]: [...linkedUiApp?.linkedComps, compId],
          }
        : linkedUiApp
    );
  } else {
    return [
      ...application?.linkedUiApplications,
      {
        ["linkedComps"]: [compId],
        ["uiAppId"]: uiAppId,
      },
    ];
  }
};

export const removeLinkedUiApplicationId = (compId) => (dispatch, getState) => {
  const { uiApplicationReducer: application } = getState();
  dispatch({
    type: UNLINK_UIAPPLICATION_ID,
    payload: {
      data: {
        ...application,
        ["linkedUiApplications"]:
          application?.linkedUiApplications !== undefined &&
          application?.linkedUiApplications.length &&
          application?.linkedUiApplications
            .map((linkeduiApp) =>
              linkeduiApp?.linkedComps.some(
                (linkedCompId) => linkedCompId === compId
              )
            )
            .some((bool) => bool)
            ? application?.linkedUiApplications
                .map((linkedUiApp) => {
                  if (
                    linkedUiApp?.linkedComps.some(
                      (linkedCompId) => linkedCompId === compId
                    )
                  ) {
                    return {
                      ...linkedUiApp,
                      ["linkedComps"]: linkedUiApp?.linkedComps.filter(
                        (linkedCompId) => linkedCompId !== compId
                      ),
                    };
                  }
                  return linkedUiApp;
                })
                .filter((linkedUiApp) => linkedUiApp?.linkedComps.length)
            : application?.linkedUiApplications,
      },
    },
  });
};

export const setActionOpen =
  ({ actionOpen }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: { data: { actionOpen: actionOpen } },
    });
  };

export const addAction =
  ({ data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: { data: { actions: [...application.actions, data] } },
    });
  };

export const editAction =
  ({ id, data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          actions: application.actions.map((val) => {
            if (val._id === id) {
              return {
                ...val,
                ...data,
              };
            } else {
              return val;
            }
          }),
        },
      },
    });
  };

export const deleteAction =
  ({ actionId }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          actions: application.actions.filter((val) => val._id !== actionId),
        },
      },
    });
  };

export const addPage =
  ({ data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: { data: { pages: [...application.pages, data] } },
    });
  };

export const editPage =
  ({ pageid, data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          pages: application.pages.map((page) => {
            if (data.isLandingPage) {
              if (page.id === pageid) {
                return {
                  ...page,
                  ...data,
                };
              } else {
                return {
                  ...page,
                  isLandingPage: false,
                };
              }
            } else {
              if (page.id === pageid) {
                return {
                  ...page,
                  ...data,
                };
              } else {
                return page;
              }
            }
          }),
        },
      },
    });
  };

export const deletePage =
  ({ name }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          pages: application.pages.filter((page) => page.name !== name),
        },
      },
    });
  };

export const editComponent =
  ({ pageid, data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();

    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          pages: application.pages.map((page) => {
            if (page.id === pageid) {
              return {
                ...page,
                components: data,
              };
            } else {
              return page;
            }
          }),
        },
      },
    });
  };

export const editComponentById =
  ({ pageUrl, compId, data }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    let newPages = [];
    for (let pages of application.pages) {
      if (pages?.url === pageUrl) {
        let newComponents = [];
        for (let components of pages.components) {
          if (components?._id === compId) {
            newComponents.push(data);
          } else {
            newComponents.push(components);
          }
        }
        newPages.push({ ...pages, components: newComponents });
      } else {
        newPages.push(pages);
      }
    }
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          pages: newPages,
        },
      },
    });
  };

export const deleteComponent =
  ({ pageid, component }) =>
  async (dispatch, getState) => {
    const { uiApplicationReducer: application } = getState();
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: {
        data: {
          pages: application.pages.map((page) => {
            if (page.id === pageid) {
              return {
                ...page,
                components: page.components.filter(
                  (comp) => comp._id !== component
                ),
              };
            } else {
              return page;
            }
          }),
        },
      },
    });
  };

export const setPreviewMode =
  ({ mode }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_UI_APPLICAION,
      payload: { data: { previewMode: mode } },
    });
  };

function updateUiApplication(payload) {
  return {
    type: UIAPPLICATION_UPDATE,
    payload: payload,
  };
}

function add_uiApplication_list_data(payload) {
  return {
    type: UIAPPLICATION_LIST_DATA,
    payload: payload,
  };
}

export function get_uiApplication_data(currentPage, filter, sort) {
  return function (dispatch, getState) {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();
    dispatch(
      add_uiApplication_list_data({
        loading: true,
      })
    );
    uiApplicationService
      .getApplications(currentPage, filter, sort)
      .then((res) => {
        const { data, _totalcount } = res.data;
        if (res.data) {
          dispatch(
            add_uiApplication_list_data({
              loading: false,
              data:
                data.length !== 0 &&
                data.map((proj) => {
                  const checkFav =
                    userFavourites.filter(
                      (fav) => fav.resourceinstance === proj?._id
                    ).length !== 0;
                  let tempProj = proj;
                  if (checkFav) {
                    tempProj["isFavourite"] = true;
                  } else {
                    tempProj["isFavourite"] = false;
                  }
                  const checkSubscribe =
                    userSubscriptions.filter(
                      (sub) =>
                        sub.name.split("_")[0] === "uiApplication" &&
                        sub.name.split("_")[1] === proj?._id
                    ).length !== 0;
                  if (checkSubscribe) {
                    tempProj["isSubscribe"] = true;
                  } else {
                    tempProj["isSubscribe"] = false;
                  }
                  return tempProj;
                }),
              error: false,
              count: _totalcount,
            })
          );
        } else {
          dispatch(
            add_uiApplication_list_data({
              loading: false,
              data: [],
              error: false,
              count: _totalcount,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          add_uiApplication_list_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      });
  };
}

export function get_allPage_data() {
  return function (dispatch) {
    dispatch(
      add_uiApplication_list_data({
        loading: true,
      })
    );
    uiApplicationService
      .getApplicationsforPage()
      .then((res) => {
        const { data, count } = res.data;
        if (res.data) {
          dispatch(
            add_uiApplication_list_data({
              loading: false,
              data: data,
              error: false,
              count: count,
            })
          );
        } else {
          dispatch(
            add_uiApplication_list_data({
              loading: false,
              data: [],
              error: false,
              count: count,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          add_uiApplication_list_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      });
  };
}

export const add_uiApplication_filters = (uiApplicationFilter) => {
  return {
    type: UIAPPLICATION_FILTER,
    payload: uiApplicationFilter,
  };
};

export const add_uiApplication_sort = (uiApplicationSort) => {
  return {
    type: UIAPPLICATION_SORT,
    payload: uiApplicationSort,
  };
};

export const add_uiApplication_HiddenCols = (uiApplicationHiddenCols) => {
  return {
    type: UIAPPLICATION_HIDDEN_COULMNS,
    payload: uiApplicationHiddenCols,
  };
};
export const delete_uiApplication = (uiAppid) => {
  return {
    type: DELETE_UI_APPLICAION,
    payload: uiAppid,
  };
};
export const alterFavouriteUIApplicationById = (uiAppid) => {
  return {
    type: ALTER_FAVOURITE_UI_APPLICATION_BY_ID,
    payload: {
      uiAppid,
    },
  };
};

export const alterSubscibeUIApplicationById = (uiAppid) => {
  return {
    type: ALTER_SUBSCRIBE_UI_APPLICATION_BY_ID,
    payload: {
      uiAppid,
    },
  };
};

// Templates
function add_template_list_data(payload) {
  return {
    type: TEMPLATE_LIST_DATA,
    payload: payload,
  };
}

export function get_templates_data(searchInput, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_template_list_data({
        templateLoading: true,
      })
    );
    uiApplicationService
      .getAllTemplates(searchInput, filter, sort)
      .then((res) => {
        const { data, _totalcount } = res.data;
        if (res.data) {
          dispatch(
            add_template_list_data({
              templateLoading: false,
              templateData: data,
              templateError: false,
              templateCount: _totalcount,
            })
          );
        } else {
          dispatch(
            add_template_list_data({
              templateLoading: false,
              templateData: [],
              templateError: false,
              templateCount: _totalcount,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          add_template_list_data({
            templateLoading: false,
            templateData: [],
            templateError: false,
          })
        );
      });
  };
}

export const add_template_filters = (templateFilter) => {
  return {
    type: TEMPLATE_FILTER,
    payload: templateFilter,
  };
};

export function get_template_filter_data(filter) {
  return function (dispatch) {
    dispatch(
      add_template_list_data({
        templateLoading: true,
      })
    );
    uiApplicationService
      .getTemplatesByfilter(filter)
      .then((res) => {
        const { data, count } = res.data;
        if (res?.data) {
          dispatch(
            add_template_list_data({
              templateLoading: false,
              templateData: data,
              templateError: false,
              templateCount: count,
            })
          );
        } else {
          dispatch(
            add_template_list_data({
              templateLoading: false,
              templateData: [],
              templateError: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          add_template_list_data({
            templateLoading: false,
            templateData: [],
            templateError: false,
          })
        );
      });
  };
}

export const add_template_sort = (templateSort) => {
  return {
    type: TEMPLATE_SORT,
    payload: templateSort,
  };
};

export const add_template_HiddenCols = (templateHiddenCols) => {
  return {
    type: TEMPLATE_HIDDEN_COULMNS,
    payload: templateHiddenCols,
  };
};
export const delete_template = (templateId) => {
  return {
    type: DELETE_TEMPLATE,
    payload: templateId,
  };
};

export const selected_template = (templateId) => {
  return {
    type: SELECTED_TEMPLATE,
    payload: {
      templateId: templateId,
    },
  };
};
export const selected_page = (uiAppId, pageId) => {
  return {
    type: SELECTED_PAGE,
    payload: {
      uiAppId: uiAppId,
      pageId: pageId,
    },
  };
};

export const setGlobalCss = (globalCss) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_CSS,
    payload: { data: globalCss },
  });
};
