import React, { useState } from 'react';
import {useHistory} from 'react-router-dom'
import {
    Dialog, DialogContent, DialogActions, makeStyles, RadioGroup, Radio, FormControlLabel
} from '@material-ui/core';
import { AppButton } from "./common/Button"

export const useStyles = makeStyles(theme => ({
    modalHeader: {
        backgroundColor: theme.palette.background.default,
        color: '#000000',
        width: '100%',
        margin: 0,
        padding: '10px 24px',

        "& h2": {
            font: 'normal normal 600 16px/22px Nunito',
        }
    },
    modalContent: {
        padding: '15px 45px 48px',
        textAlign: 'center',

        "& h1": {
            font: 'normal normal 600 16px/22px Nunito',
            color: '#000000',
            width: '75%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '2rem'
        },
        "& .MuiFormControlLabel-label": {
            font: 'normal normal 600 16px/22px Nunito',
            color: '#000000'
        }
    },
}))


const HelpdeskModal = (props) => {
    const styles = useStyles()
    const history=useHistory()
    const [capture, setCapture] = useState('capture');

  
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={props.helpdeskModal}
            onClose={() => props.setHelpdeskModal(false)}
        >
            <DialogContent className={styles.modalContent}>
                <div className={styles.modalTitle}>
                    <h1>Do you want to capture the screen before reporting The issue ?</h1>
                </div>
                <RadioGroup aria-label="capture" name="capture" value={capture} onChange={(e) => setCapture(e.target.value)}>
                    <FormControlLabel value="capture" control={<Radio color="primary" />} label="Capture Screen" />
                    <FormControlLabel value="notcapture" control={<Radio color="primary" />} label="Don’t capture screen" 
                    // onClick={()=>history.push('/helpdesk/create')}
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions className={styles.modalFooter}>
                <AppButton
                    buttonName="Cancel"
                    variant="outlined"
                    disabled={false}
                    className='btnsmall'
                    onClick={() => props.setHelpdeskModal(false)}
                />
                <AppButton
                    buttonName="Proceed"
                    variant="contained"
                    disabled={false}
                    className='btnsmall'
                    onClick={() => {
                        props.setHelpdeskModal(false)
                        capture==="capture"&& props.onStartCapture()
                        capture==="notcapture" && history.push('/helpdesk/create')
                    }}
                />

            </DialogActions>
        </Dialog>
    )
}

export default HelpdeskModal
