import { getAWSRegionList } from "../../../utils/deployment/deploymentUtils"

export const AWS_REGION_LIST = [
    {
       "code":"us-east-2",
       "name":"US East (Ohio)"
    },
    {
       "code":"us-east-1",
       "name":"US East (N. Virginia)"
    },
    {
       "code":"us-west-1",
       "name":"US West (N. California)"
    },
    {
       "code":"us-west-2",
       "name":"US West (Oregon)"
    },
    {
       "code":"af-south-1",
       "name":"Africa (Cape Town)"
    },
    {
       "code":"ap-east-1",
       "name":"Asia Pacific (Hong Kong)"
    },
    {
       "code":"ap-southeast-3",
       "name":"Asia Pacific (Jakarta)"
    },
    {
       "code":"ap-south-1",
       "name":"Asia Pacific (Mumbai)"
    },
    {
       "code":"ap-northeast-3",
       "name":"Asia Pacific (Osaka)"
    },
    {
       "code":"ap-northeast-2",
       "name":"Asia Pacific (Seoul)"
    },
    {
       "code":"ap-southeast-1",
       "name":"Asia Pacific (Singapore)"
    },
    {
       "code":"ap-southeast-2",
       "name":"Asia Pacific (Sydney)"
    },
    {
       "code":"ap-northeast-1",
       "name":"Asia Pacific (Tokyo)"
    },
    {
       "code":"ca-central-1",
       "name":"Canada (Central)"
    },
    {
       "code":"eu-central-1",
       "name":"Europe (Frankfurt)"
    },
    {
       "code":"eu-west-1",
       "name":"Europe (Ireland)"
    },
    {
       "code":"eu-west-2",
       "name":"Europe (London)"
    },
    {
       "code":"eu-south-1",
       "name":"Europe (Milan)"
    },
    {
       "code":"eu-west-3",
       "name":"Europe (Paris)"
    },
    {
       "code":"eu-north-1",
       "name":"Europe (Stockholm)"
    },
    {
       "code":"me-south-1",
       "name":"Middle East (Bahrain)"
    },
    {
       "code":"sa-east-1",
       "name":"South America (São Paulo)"
    }
 ]

 export const AZURE_REGION_LIST = [ { code: 'Brazil South', name: 'Brazil South' },
 { code: 'Canada Central', name: 'Canada Central' },
 { code: 'Central US', name: 'Central US' },
 { code: 'East US', name: 'East US' },
 { code: 'East US 2', name: 'East US 2' },
 { code: 'South Central US', name: 'South Central US' },
 { code: 'US Gov Virginia', name: 'US Gov Virginia' },
 { code: 'West US 2', name: 'West US 2' },
 { code: 'West US 3', name: 'West US 3' },
 { code: 'France Central', name: 'France Central' },
 { code: 'Germany West Central', name: 'Germany West Central' },
 { code: 'North Europe', name: 'North Europe' },
 { code: 'Norway East', name: 'Norway East' },
 { code: 'UK South', name: 'UK South' },
 { code: 'West Europe', name: 'West Europe' },
 { code: 'Sweden Central', name: 'Sweden Central' },
 { code: 'Asia Pacific (Seoul)', name: 'Asia Pacific (Seoul)' },
 { code: 'South Africa North', name: 'South Africa North' },
 { code: 'Australia East', name: 'Australia East' },
 { code: 'Central India', name: 'Central India' },
 { code: 'Japan East', name: 'Japan East' },
 { code: 'Korea Central', name: 'Korea Central' },
 { code: 'Southeast Asia', name: 'Southeast Asia' },
 { code: 'East Asia', name: 'East Asia' },
 { code: 'China North 3', name: 'China North 3' } ]


 export const GCP_REGION_LIST = [ { code: 'asia-east1', name: 'Taiwan' },
 { code: 'asia-east2', name: 'Hong Kong' },
 { code: 'asia-northeast1', name: 'Tokyo' },
 { code: 'asia-northeast2', name: 'Osaka' },
 { code: 'asia-northeast3', name: 'Seoul' },
 { code: 'asia-south1', name: 'Mumbai' },
 { code: 'asia-southeast1', name: 'Singapore' },
 { code: 'australia-southeast1', name: 'Sydney' },
 { code: 'europe-north1', name: 'Finland' },
 { code: 'europe-west1', name: 'Belgium' },
 { code: 'europe-west2', name: 'London' },
 { code: 'europe-west3', name: 'Frankfurt' },
 { code: 'europe-west4', name: 'Netherlands' },
 { code: 'europe-west6', name: 'Zürich' },
 { code: 'northamerica-northeast1', name: 'Montréal' },
 { code: 'southamerica-east1', name: 'Sao Paulo' },
 { code: 'us-central1', name: 'Iowa' },
 { code: 'us-east1', name: 'South Carolina' },
 { code: 'us-east4', name: 'Northern Virginia' },
 { code: 'us-west1', name: 'Oregon' },
 { code: 'us-west2', name: 'Los Angeles' },
 { code: 'us-west3', name: 'Salt Lake City' } ]

 export const CheckRegion = async () => {
   const {data, _status, _msg} = await getAWSRegionList()
   return []
 }