import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import React from "react";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import moment from "moment";

const TemplateEditEntity = ({
  section,
  BoilerPlateData,
  setBoilerPlateData,
  error,
  handleChange,
  handleUpload,
  customdropzoneImage,
  handleDeleteClick,
  // latest
  uploadTab,
  setUploadTab,
  handleUploadSourceTab,
  droppedDocument,
  setDroppedDocument,
  handleImageNameChange,
  imageDetails,
  setError,
  launguageList,
  envs,
  handleAddEnv,
  handleEnvChange,
  handleDeleteEnv,
  gitSouceCodeUpload,
  setGitSouceCodeUpload,
  gitError,
  setGitError,
  uploadModal,
  setUploadModal,
  userInfo,
  setIsLoader,
  tabbedView,
}) => {
  const styles = manageStyles();
  const UploadBoilerPlateFile = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid
              container
              spacing={3}
              style={
                tabbedView ? { marginTop: 0, marginLeft: 2 } : { marginTop: 5 }
              }
            >
              {uploadModal ? (
                <Grid item xs={6} sm={6}>
                  <label htmlFor="" className={styles.fieldHeading2}>
                    Build File
                  </label>
                  {BoilerPlateData && (
                    <div
                      className={styles.doc_container}
                      style={{ marginTop: 5 }}
                    >
                      <div className={styles.group1}>
                        <input
                          className={styles.value}
                          value={BoilerPlateData.filename}
                          style={{ backgroundColor: "#f5f5f5" }}
                          autoComplete="off"
                          disabled
                        ></input>
                      </div>
                      <div style={{ paddingTop: 15, cursor: "pointer" }}>
                        <img
                          src="/images/container/delete.svg"
                          alt="delete"
                          onClick={() => {
                            setUploadModal(false);
                            const myObj = BoilerPlateData;
                            delete myObj.filename;
                            delete myObj.foldername;
                            delete myObj.Url;
                            delete myObj.token;
                            setBoilerPlateData(myObj);
                            setError({
                              ...error,
                              docker: {
                                dataType: "string",
                                isError: true,
                                minLength: 1,
                                minimum: 1,
                                maxLength: 50,
                                maximum: 300,
                                msg: "",
                                fieldLabel: "docker",
                                section: 1,
                              },
                              droppedDocument: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Grid>
              ) : (
                <div style={{ width: 645, marginLeft: 20 }}>
                  <div style={{}}>
                    <label className={styles.fieldHeading2}>
                      Upload File<span className={styles.red}>*</span>
                    </label>
                  </div>

                  <RadioGroup
                    value={uploadTab}
                    onChange={handleUploadSourceTab}
                    indicatorColor="primary"
                    textColor="primary"
                    color="primary"
                    className={styles.documentTab}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="Upload"
                      control={<Radio color="primary" />}
                      label="Upload"
                    />
                    <FormControlLabel
                      value="URL"
                      control={<Radio color="primary" />}
                      label="URL"
                    />
                  </RadioGroup>

                  {uploadTab === "Upload" && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div
                          className={`${styles.documentDropperWrapper2} ${
                            error["docker"]?.isError &&
                            droppedDocument.length === 0 &&
                            styles.ErrordocumentDropperWrapper2
                          }`}
                        >
                          {/* <div className={styles.documentDropperWrapper2}> */}
                          <DropzoneArea
                            Icon={customdropzoneImage}
                            name="droppedDocument"
                            className={styles.documentDropperClass}
                            showPreviews={false}
                            filesLimit={1}
                            dropzoneText="Drag and drop or click to browse here"
                            dropzoneClass="documentDropperClass"
                            dropzoneParagraphClass="documentDropperTextClass"
                            get
                            acceptedFiles={[".zip"]}
                            maxFileSize={9000000000}
                            showAlerts={["error"]}
                            showPreviewsInDropzone={false}
                            onDrop={() => setIsLoader(true)}
                            onChange={(files) => {
                              files.map((file) => {
                                let myObj = BoilerPlateData;
                                myObj["filename"] = `${file.name}`;
                                myObj["foldername"] = file.name;
                                setBoilerPlateData(myObj);
                              });
                              setError;
                              setIsLoader(false);
                              handleUpload(files);
                            }}
                          />
                          <p
                            className={styles.fieldHeading}
                          >{`Allowed type ".zip" file only`}</p>
                        </div>

                        {error["docker"]?.isError &&
                          droppedDocument.length === 0 && (
                            <p className={styles.errorMsg}>
                              {"File is required"}
                            </p>
                          )}

                        {droppedDocument.length > 0 && (
                          <div className={styles.doc_container}>
                            <div className={styles.group1}>
                              <text className={styles.label}>File Name</text>
                              <input
                                className={styles.value}
                                value={imageDetails["imagename"]}
                                placeholder="Enter Image Name"
                                onChange={handleImageNameChange}
                              ></input>
                            </div>
                            <span
                              style={{
                                marginLeft: 23,
                                marginTop: 25,
                                color: "black",
                              }}
                              onClick={handleDeleteClick}
                            >
                              <img
                                src="/images/deleteLine.svg"
                                height={18}
                                width={18}
                                color="#000000"
                                alt="icon"
                              />
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {uploadTab === "URL" && (
                    <div style={{ width: 630 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Url <span className={styles.red}>*</span>
                          </label>
                          <input
                            type="text"
                            name="Url"
                            // className={`${styles.input} ${
                            //   gitError.Url && styles.error
                            // }`}
                            className={`${styles.input} ${
                              error["Url"]?.isError && styles.error
                            }`}
                            value={
                              BoilerPlateData.Url ? BoilerPlateData.Url : ""
                            }
                            onChange={(e) => {
                              setBoilerPlateData({
                                ...BoilerPlateData,
                                Url: e.target.value,
                              });
                              // setGitError({ ...gitError, Url: false });
                              setError({
                                ...error,
                                Url: { ...error["Url"], isError: false },
                              });
                            }}
                            autoComplete="off"
                          />
                          {/* {gitError.Url && (
                            <p className={styles.errorMsg}>
                              {"Url is Required"}
                            </p>
                          )} */}
                          {error["Url"]?.isError && (
                            <p className={styles.errorMsg}>
                              {"Url is required"}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Branch
                          </label>
                          <input
                            type="text"
                            name="branch"
                            className={`${styles.input}`}
                            value={
                              BoilerPlateData.branch
                                ? BoilerPlateData.branch
                                : ""
                            }
                            onChange={(e) =>
                              setBoilerPlateData({
                                ...BoilerPlateData,
                                branch: e.target.value,
                              })
                            }
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Repo Type
                          </label>
                          <div>
                            <FormControlLabel
                              value={BoilerPlateData.isPublic}
                              control={
                                <Switch
                                  checked={BoilerPlateData.isPublic}
                                  onChange={(event) => {
                                    setBoilerPlateData({
                                      ...BoilerPlateData,
                                      isPublic: !BoilerPlateData.isPublic,
                                    });
                                    if (event.target.checked) {
                                      let tempError = { ...error };
                                      delete tempError["token"];
                                      setError(tempError);
                                    } else {
                                      setError({
                                        ...error,
                                        token: {
                                          dataType: "string",
                                          minimum: 1,
                                          minLength: 1,
                                          isError: false,
                                          section: 1,
                                        },
                                      });
                                    }
                                  }}
                                  color="primary"
                                />
                              }
                              style={{ marginLeft: 0 }}
                              label="Private"
                              labelPlacement="start"
                            />
                            <span
                              style={{
                                marginLeft: 10,
                                font: "normal normal 400 1rem Nunito",
                                cursor: "pointer",
                              }}
                              onClick={(event) => {
                                setBoilerPlateData({
                                  ...BoilerPlateData,
                                  isPublic: !BoilerPlateData.isPublic,
                                });
                                if (event.target.checked) {
                                  // setGitError({ ...gitError, token: false });
                                  setError({ ...error, token: false });
                                } else {
                                  const tempError = error;
                                  delete tempError["token"];
                                  // setGitError(tempError);
                                  setError(tempError);
                                }
                              }}
                            >
                              Public
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            Token{" "}
                            {!BoilerPlateData.isPublic && (
                              <span className={styles.red}>*</span>
                            )}
                          </label>
                          <input
                            type="text"
                            name="token"
                            // className={`${styles.input} ${
                            //   gitError.token && styles.error
                            // }`}
                            className={`${styles.input} ${
                              error["token"]?.isError && styles.error
                            }`}
                            value={
                              BoilerPlateData.token ? BoilerPlateData.token : ""
                            }
                            onChange={(e) => {
                              setBoilerPlateData({
                                ...BoilerPlateData,
                                token: e.target.value,
                              });
                              // setGitError({ ...gitError, token: false });
                              setError({
                                ...error,
                                token: { ...error["token"], isError: false },
                              });
                            }}
                            autoComplete="off"
                          />
                          {/* {gitError.token && (
                            <p className={styles.errorMsg}>
                              {"Token is Required"}
                            </p>
                          )} */}
                          {error["token"]?.isError && (
                            <p className={styles.errorMsg}>
                              {"Token is required"}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const BuildCommand = () => {
    return (
      <Grid
        container
        spacing={3}
        style={tabbedView ? { marginTop: 3, marginLeft: 2 } : { marginTop: 10 }}
      >
        <Grid item xs={6}>
          <label className={styles.fieldHeading2}>Build Command</label>
          {BoilerPlateData && (
            <textarea
              name="dockerbuildcommands"
              onChange={handleChange}
              defaultValue={BoilerPlateData?.dockerbuildcommands}
              className={styles.textarea}
              rows="4"
            ></textarea>
          )}
        </Grid>
      </Grid>
    );
  };

  const RunCommand = () => {
    return (
      <Grid
        container
        spacing={3}
        style={tabbedView ? { marginTop: 7, marginLeft: 2 } : { marginTop: 10 }}
      >
        <Grid item xs={6}>
          <label className={styles.fieldHeading2}>Run Command</label>
          {BoilerPlateData && (
            <textarea
              name="dockerruncommands"
              onChange={handleChange}
              defaultValue={BoilerPlateData?.dockerruncommands}
              className={styles.textarea}
              rows="4"
            ></textarea>
          )}
        </Grid>
      </Grid>
    );
  };
  const EnvironmentVariable = () => {
    return (
      <Grid
        container
        spacing={3}
        style={
          tabbedView ? { padding: 10 } : { marginTop: 10, marginBottom: 150 }
        }
      >
        <Grid item xs={12} sm={12}>
          <TableContainer>
            <Table
              className={styles.customTable}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: 20 }}>Key</TableCell>
                  <TableCell style={{ paddingLeft: 20 }}>Value</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {envs.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{ border: "0.5px solid #D5D5D5" }}
                  >
                    <TableCell scope="row">
                      <input
                        type="text"
                        placeholder="Enter key"
                        name={row.id}
                        className={styles.input}
                        value={envs[index].key}
                        onChange={(e) => handleEnvChange(e, row.id, "key")}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Value"
                        name={row.id}
                        className={styles.input}
                        value={envs[index].value}
                        onChange={(e) => handleEnvChange(e, row.id, "value")}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        onClick={() => handleDeleteEnv(row.id)}
                        className={styles.actionIcon}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              backgroundColor: "#fff",
              padding: "5px 0",
              borderLeft: "1px solid #E2E2E3",
              borderRight: "1px solid #E2E2E3",
              borderBottom: "1px solid #E2E2E3",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              onClick={handleAddEnv}
              // className={`${cardView ? 'actionActive' : ''} ${styles.actionIcons}`}
              className={styles.actionIcon}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input}  ${
                error[field.datakey]?.isError && styles.error
              } ${
                field.editable === "FALSE" &&
                field.fieldLabel !== "Chassis Template Name"
                  ? styles.editMode
                  : ""
              }`}
              value={
                field.dataType === "date" && BoilerPlateData[field.datakey]
                  ? moment
                      .unix(BoilerPlateData[field.datakey])
                      .tz(userInfo.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                  : BoilerPlateData[field.datakey]
              }
              disabled={
                field.editable === "FALSE" &&
                field.fieldLabel !== "Chassis Template Name"
              }
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={BoilerPlateData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea}  ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        if (field.fieldLabel === "Language") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Language
              </label>
              <input
                className={styles.input2}
                value={BoilerPlateData.launguage}
                autoComplete="off"
                disabled
              />
            </div>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={
                  BoilerPlateData[field.datakey]
                    ? BoilerPlateData[field.datakey]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                } `}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                BoilerPlateData[field.datakey]
                  ? BoilerPlateData[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={field.editable === "FALSE"}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === "FALSE" && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  BoilerPlateData[`${field.datakey}_unit`]
                    ? BoilerPlateData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={BoilerPlateData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  BoilerPlateData[`${field.datakey}_unit`]
                    ? BoilerPlateData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={BoilerPlateData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                BoilerPlateData[field.datakey]
                  ? BoilerPlateData[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup, index) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Template_Header_section" && index !== 0 && (
                <h3 className={styles.fieldgroupHeading}>{fieldgroup.name}</h3>
              )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView
                      ? { display: "block" }
                      : { marginTop: "0rem", display: "block" }
                  }
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Template File":
                          return UploadBoilerPlateFile();
                        case "Template_buildcommands":
                          return BuildCommand();
                        case "Template_runcommands":
                          return RunCommand();
                        case "Template EnvironmentVariable":
                          return EnvironmentVariable();

                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Template File":
                                return UploadBoilerPlateFile();
                              case "buildcommands":
                                return BuildCommand();
                              case "runcommands":
                                return RunCommand();
                              case "Template EnvironmentVariable":
                                return EnvironmentVariable();

                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(TemplateEditEntity);
