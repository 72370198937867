import useGetData from "../../../../hooks/useGetData";
import ShowStatus from "../../../atoms/rightSide/appearance/showStatus/ShowStatus";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";

function TreeAppearances() {
  const { currentComponent, currentPage, NormalHandleChange } = useGetData();
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: value ? value : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name="fontSize"
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <div style={{ marginTop: 5 }}>
          <ShowStatus label="Show Loading" fstBtn="Yes" sndBtn="No" />
        </div>
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <CustomColorPicker name="status" />
      </div>
    </>
  );
}

export default TreeAppearances;
