const objectColumns = [
    { name: "label", title: "Object Name", for: "ontology_objects_list" },
    { name: "predefined", title: "Predefined", for: "ontology_objects_list" },
    { name: "sections", title: "Sections", for: "ontology_objects_list" },
    { name: "predefined_sections", title: "Predefined Sections", for: "ontology_objects_list" },
    { name: "updatedon", title: "Updated On", for: "ontology_objects_list" },
    { name: "action", title: "Actions", for: "ontology_objects_list" },
];

const objectDefaultColumnWidths = [
    { columnName: "label", weightage: 1.5, width: 200 },
    { columnName: "predefined", weightage: 1.5, width: 200 },
    { columnName: "sections", weightage: 1.5, width: 200 },
    { columnName: "predefined_sections", weightage: 2, width: 200 },
    { columnName: "updatedon", weightage: 2, width: 200 },
    { columnName: "action", weightage: 2, width: 200 },
];

const objectTableColumnExtensions = [
    { columnName: "label", align: "left" },
    { columnName: "predefined", align: "left" },
    { columnName: "sections", align: "left" },
    { columnName: "predefined_sections", align: "left" },
    { columnName: "updatedon", align: "left" },
    { columnName: "action", align: "center", sortingEnabled: false },
];

const objectColumnOrder = [
    "label",
    "predefined",
    "sections",
    "predefined_sections",
    "updatedon",
    "action",
];


const objectColumnHidden = [
    // 'id',
    "label",
    "predefined",
    "sections",
    "updatedon",
    "action",
  ];
  


const objectDefaultSorting = []



export {
    objectColumns,
    objectDefaultColumnWidths,
    objectTableColumnExtensions,
    objectColumnOrder,
    objectColumnHidden,
    objectDefaultSorting,
};
