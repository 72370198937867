import {
  Grid,
  Typography,
  TextField,
  Paper,
  FilledInput,
  Checkbox,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import DevExpressTable from "../../Tables/DevExpressTable";
import { AppButton } from "../../Button";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CustomChips from "../../Chips";
import FileDropperWithLayout from "../../FileDropperWithLayout";
import {
  newServiceGroupColumns,
  newServiceGroupDefaultColumnWidths,
  newServiceGroupColumnOrder,
  newServiceGroupDefaultSorting,
  newServiceGroupTableColumnExtensions,
} from "../../../../constants/newServiceGroupConstants";
import { getColumnWidth } from "../../../../utils/common";
import LanguageChips from "../../LanguageChip";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import useCheckPermission from "../../hooks/useCheckPermission";
import { connect } from "react-redux";
import { updateServiceGroupMode } from "../../../../redux/actions/projectAction";
import AddServiceGroup from "../../../../screens/ServiceGroup/AddServiceGroup";
import AddNewServiceGroup from "../../../../screens/ServiceGroup/AddNewServiceGroup";
import ResourceDeploymentTable from "../../Tables/ResourceDeploymentTable";
import CustomTree from "../../Tree";

const CustomFieldGroupsForProjectCreate = ({
  section,
  projectData,
  setProjectData,
  error,
  handleChange,
  serviceGroups,
  handleTeamCheck,
  clickedTeam,
  handleTeamSearch,
  selectedTeams,
  teams,
  setClickedTeam,
  setTeamSearchInput,
  handleSelectedTeamDelete,
  sort,
  setSort,
  windowDimensions,
  teamSearchInput,
  languages,
  handleLanguageDelete,
  addNewProjectData,
  selectedDocuments,
  setSelectedDocuments,
  teamLoader,
  documentsError,
  setDocumentsError,
  tabbedView,
  updateServiceGroupMode,
  serviceGroupMode,
  statuses,
  handleSubmit,
  serviceGroupsHierarchy
}) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { checkCompPermission } = useCheckPermission();
  const [languageOpen, setLanguageOpen] = useState(false);

  const closeDropDown = () => {
    setLanguageOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);


  const statusSection = () => {
    return (
      <>
        <>
          <Autocomplete
            // open={statusOpen}
            // onOpen={()=> setStatusOpen(true)}
            // onClose={()=> setStatusOpen(false)}
            options={statuses}
            getOptionLabel={(option) => option.status}
            className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
              }`}
            onChange={(e, input) => {
              if (input) {

                setProjectData({
                  ...projectData,
                  status: input.status,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search Status"
                placeholder="Search Status"
              />
            )}
          />
          {error.status?.isError && (
            <p className={styles.errorMsg}>{"Status is Required"}</p>
          )}
        </>

        {projectData?.status ? (
          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
            <div
              style={{
                backgroundColor: statuses?.find(
                  (s) => s.status === projectData?.status
                )?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                position: "relative",
                right: 10,
              }}
            ></div>
            <Typography
              variant="h6"
              className={styles.fieldText}
              style={{
                color: statuses?.find((s) => s.status === projectData?.status)
                  ?.color,
              }}
            >
              {statuses
                .find((s) => s.status === projectData?.status)
                ?.status.charAt(0)
                .toUpperCase() +
                statuses
                  .find((s) => s.status === projectData?.status)
                  ?.status.slice(1)}
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" className={styles.fieldText}>
            Status is not set
          </Typography>
        )}
      </>
    );
  };

  const serviceGroupTableSection = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"

        >
          {!serviceGroupMode && <>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ margin: "10px 0" }}
            >
              {tabbedView ? (
                <AppButton
                  color="primary"
                  buttonName="Create Service Group"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={
                    !checkCompPermission("Project", ["Create Service Group"])
                  }
                  style={{ margin: "unset", marginLeft: "20px" }}
                  className="btnsmall"
                  onClick={() => updateServiceGroupMode({ value: "create" })}
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Create Service Group"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={
                    !checkCompPermission("Project", ["Create Service Group"])
                  }
                  style={{ margin: "unset", marginLeft: "20px" }}
                  className="btnsmall"
                  onClick={() => {
                    addNewProjectData({ ...projectData, teams: selectedTeams });
                    histroy.push(`/servicegroups/add`);
                  }}
                />
              )}
            </Grid>
          </>}
        </Grid>

        {serviceGroupMode === "create" && <AddNewServiceGroup />}

        {!serviceGroupMode && (
          <>
            <DevExpressTable
              isEditMode={true}
              columns={newServiceGroupColumns}
              rows={serviceGroups}
              defaultColumnWidths={getColumnWidth(
                newServiceGroupDefaultColumnWidths,
                windowDimensions
              )}
              tableColumnExtensions={newServiceGroupTableColumnExtensions}
              columnOrder={newServiceGroupColumnOrder}
              defaultSorting={newServiceGroupDefaultSorting}
              hideColumnChooser={true}
              sort={sort}
              setSort={setSort}
              isCustomPaging={true}
              noDataMsg="No Service Group Added"
              isCustomWidth={100}
            />
          </>
        )}
      </>
    );
  };

  const teamsSection = () => {
    return (
      <>
        <FilledInput
          name="teamSearchInput"
          value={teamSearchInput}
          onChange={(e) => {
            setTeamSearchInput(e.target.value);
            handleTeamSearch(e.target.value);
          }}
          className={`${styles.input} ${styles.searchInput2} ${styles.customSearchInput
            } ${error.teams?.isError &&
            selectedTeams.length === 0 &&
            styles.errorSearch
            }`}
          placeholder="Search"
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <img src="/images/search.svg" alt="card" />
              </IconButton>
            </InputAdornment>
          }
        />
        {error.teams?.isError && selectedTeams.length === 0 && (
          <p className={styles.errorMsg}>{"Teams is not selected"}</p>
        )}

        <CustomChips
          chipsData={selectedTeams}
          handleDelete={handleSelectedTeamDelete}
          isEditMode={!checkCompPermission("Project", ["Assign Or Dismiss Team"])}
        />

        {teamLoader && <CircularProgress color="primary" />}

        <Grid container spacing={5}>
          <Grid
            item
            xs={6}
            sm={6}
            style={{ borderRight: "1px solid rgba(0, 40, 85, .4)" }}
          >
            <div
              className={styles.section}
              style={{
                opacity: 1,
                maxHeight: "25rem",
                overflow: "auto",
                padding: "0 1rem 1rem 0",
              }}
            >
              <Grid container spacing={3}>
                {teams.length > 0 &&
                  teams
                    .filter((team) => team.block_status === "false")
                    .map((team, index) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Paper
                          className={styles.paper}
                          onClick={() => setClickedTeam(team)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={styles.spaceBetweenText}>
                            <Typography variant="body1">{team.name}</Typography>
                            <div className={styles.technologyCheck}>
                              <Typography variant="body1">
                                {team.org}
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={
                                  selectedTeams.find(
                                    (item) => item._id === team._id
                                  )
                                    ? true
                                    : false
                                }
                                className={styles.teamCheckbox}
                                onChange={(e) => handleTeamCheck(team)}
                                name={team.name}
                                disabled={
                                  !checkCompPermission("Project", [
                                    "Assign Or Dismiss Team",
                                  ])
                                }
                              />
                            </div>
                          </div>
                        </Paper>
                        {clickedTeam?._id === team?._id && (
                          <div className={styles.section}>
                            <Grid container spacing={3}>
                              {!clickedTeam?.users.length ? (
                                <p
                                  className={styles.fieldHeading}
                                  style={{ marginLeft: 20 }}
                                >
                                  No Members Found
                                </p>
                              ) : (
                                clickedTeam?.users.map((member, index) => (
                                  <Grid item xs={6} sm={6} key={index}>
                                    <Paper className={styles.paper}>
                                      <div className={styles.teamWrapper}>
                                        {member?.image ? (
                                          <img
                                            src={member?.image}
                                            className={styles.memberProfile}
                                            alt="profile"
                                          />
                                        ) : (
                                          <img
                                            src="/images/users/default.svg"
                                            alt="profile"
                                          />
                                        )}
                                        <div className={styles.teamMember}>
                                          <Typography
                                            variant="body1"
                                            className={styles.teamMemberName}
                                          >
                                            {member?.name}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            className={
                                              styles.teamMemberDesgination
                                            }
                                          >
                                            {member?.desgination}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                ))
                              )}
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className={styles.section}>
              <Grid container spacing={3}>
                {selectedTeams.map((item) =>
                  item?.users.map((member, index) => (
                    <Grid item xs={6} sm={6} key={index}>
                      <Paper className={styles.paper}>
                        <div className={styles.teamWrapper}>
                          {member?.image ? (
                            <img
                              src={member?.image}
                              className={styles.memberProfile}
                              alt="profile"
                            />
                          ) : (
                            <img
                              src="/images/users/default.svg"
                              alt="profile"
                            />
                          )}
                          <div className={styles.teamMember}>
                            <Typography
                              variant="body1"
                              className={styles.teamMemberName}
                            >
                              {member?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.teamMemberDesgination}
                            >
                              {member?.desgination}
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  ))
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const governanceSection = () => {
    return (
      <>
        <Typography variant="h6" className={styles.fieldText}>
          Languages <span className={styles.red}>*</span>
        </Typography>
        <Autocomplete
          open={languageOpen}
          onOpen={() => setLanguageOpen(true)}
          onClose={() => setLanguageOpen(false)}
          options={languages}
          getOptionLabel={(option) => option.name}
          className={`${styles.searchInput2} ${error.languages?.isError &&
            projectData.languages.length === 0 &&
            styles.errorAuto
            }`}
          onChange={(e, input) => {
            if (input && !projectData.languages.includes(input)) {
              setProjectData({
                ...projectData,
                languages: [...projectData.languages, input],
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              name="languages"
              placeholder="Search Language"
            />
          )}
        />
        {error.languages?.isError && projectData.languages.length === 0 && (
          <p className={styles.errorMsg}>{error.languages.msg}</p>
        )}

        <LanguageChips
          chipsData={projectData.languages ? projectData.languages : []}
          handleDelete={handleLanguageDelete}
        />
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                }`}
              value={projectData[field.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={projectData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error
                }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                projectData[field.datakey] ? projectData[field.datakey] : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${error[field.datakey]?.isError && styles.error
                }`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                projectData[field.datakey] ? projectData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                        }`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  projectData[`${field.datakey}_unit`]
                    ? projectData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values)?.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values?.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  }`}
                value={projectData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                projectData[field.datakey] ? projectData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values)?.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))
                : field?.values?.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={<Radio color="primary" />}
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const hierarchySection = () => {
    return (
      <CustomTree parentData={projectData} childData={serviceGroupsHierarchy} />
    );
  };

  const documentsSection = () => {
    return checkCompPermission("Project", ["Create Document"]) ? (
      <FileDropperWithLayout
        filesLimit={20}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={(data) => setSelectedDocuments(data)}
        documentsError={documentsError}
        setDocumentsError={setDocumentsError}
      />
    ) : (
      <p className={styles.fieldHeading} style={{ color: "red" }}>
        You do not have permission to Create Document. Please contact your
        Admin.
      </p>
    );
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Project Teams":
                          return (
                            <Grid item key={field._id}>
                              {teamsSection()}
                            </Grid>
                          );
                        case "Project Languages":
                          return (
                            <Grid item key={field._id}>
                              {governanceSection()}
                            </Grid>
                          );
                        case "Service groups table":
                          return (
                            <Grid item key={field._id}>
                              {serviceGroupTableSection()}
                            </Grid>
                          );
                        case "Project Documentation":
                          return (
                            <Grid item key={field._id}>
                              {documentsSection()}
                            </Grid>
                          );
                        case "Project Status":
                          return statusSection();
                        case "Deployment Table":
                          return (
                            <Grid item key={field._id}>
                              <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                style={{ margin: "20px 0" }}
                              >
                                <div style={{ float: 'right' }}>
                                  <AppButton
                                    color="primary"
                                    buttonName="Add Deployment"
                                    variant="contained"
                                    disabled={false}
                                    style={{ margin: "unset" }}
                                    className="btnsmall"
                                    onClick={() => handleSubmit('deployment')}
                                  />
                                </div>
                              </Grid>

                            </Grid>
                          );
                        case "Project Hierarchy Tree":
                          return hierarchySection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Project Teams":
                                return teamsSection();
                              case "Project Languages":
                                return governanceSection();
                              case "Service groups table":
                                return serviceGroupTableSection();
                              case "Project Documentation":
                                return documentsSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = {
  updateServiceGroupMode,
};

const mapStateToProps = (state) => {
  const { serviceGroupMode } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;
  return {
    tabbedView,
    serviceGroupMode,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFieldGroupsForProjectCreate);
