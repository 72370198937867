import { useEffect } from "react";
import { Typography } from "@material-ui/core";
import useGetData from "../../../hooks/useGetData";
import useTriggers from "../../../hooks/useTriggers";
function CustomHeading({ id }) {
  const { getComponent } = useGetData();
  const { handleTrigger } = useTriggers();
  const currentComponent = getComponent(id);

  useEffect(() => {
    if (currentComponent["onInit"]) {
      handleTrigger({
        action: currentComponent?.["onInit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const heading = currentComponent?.headingValue?.dataValue;
  return (
    <>
      <Typography
        style={{
          fontSize: currentComponent?.fontSize
            ? currentComponent?.fontSize
            : 16,
          color: currentComponent?.status,
          position: "absolute",
          top:
            currentComponent?.alignment !== "center" &&
            !currentComponent?.direction
              ? 0
              : "unset",
          bottom: currentComponent?.direction ? 0 : "unset",
          left: currentComponent?.alignment === "left" ? 0 : "unset",
          right: currentComponent?.alignment === "right" ? 0 : "unset",
          fontFamily: currentComponent?.fontFamily,
          fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
          fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
          textDecoration: currentComponent?.style?.underline
            ? "underline"
            : undefined,
          // align center
          display: currentComponent?.alignment === "center" ? "flex" : "unset",
          alignItems:
            currentComponent?.alignment === "center" ? "center" : "unset",
          justifyContent:
            currentComponent?.alignment === "center" ? "center" : "unset",
          height:
            currentComponent?.alignment === "center"
              ? "-webkit-fill-available"
              : "unset",
          width:
            currentComponent?.alignment === "center"
              ? "-webkit-fill-available"
              : "unset",
        }}
      >
        {heading && typeof heading === "string" ? heading : "Invalid heading"}
      </Typography>
    </>
  );
}

export default CustomHeading;
