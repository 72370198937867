import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18next";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CustomSnackbar from "./components/common/CustomSnackbar";
import { Provider } from "react-redux";
import { store } from "./redux/store";

// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn:
//       'https://e5834062272c45edac86670e7dbe31bd@o1044012.ingest.sentry.io/6013840',
//     integrations: [new Integrations.BrowserTracing()],
//     beforeBreadcrumb(breadcrumb, hint) {
//       if (breadcrumb.category === 'console') {
//         return null
//       }
//       return breadcrumb
//     },
//     tracesSampleRate: 1.0,
//     release: process.env.RELEASE,
//   })
// }

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading ... </div>}>
      <CustomSnackbar>
        <App />
      </CustomSnackbar>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
