import React, { useEffect, useState } from "react";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NewDevExpressTable from "../../components/common/Tables/ClusterRelatedTable";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  clusterReplicaSetsColumns,
  clusterReplicaSetsDefaultColumnWidths,
  clusterReplicaSetsTableColumnExtensions,
  clusterReplicaSetsColumnOrder,
  clusterReplicaSetsDefaultSorting,
  clusterReplicaSetsColumnHidden,
} from "../../constants/clusterReplicasetsConstants";
import {
  addClusterDeploymentSort,
  addClusterDeploymentHidden,
  fetchAllClusterDeployment,
  addReplicaSetHidden,
} from "../../redux/actions/clusterActions";
import { getWindowDimensions } from "../../utils/common";
import { Pagination } from "@material-ui/lab";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import {
  fetchClusterPods,
  fetchClusterReplicaSets,
} from "../../utils/clusterutils/clusterUtils";
import { add_cluster_pods_list } from "../../redux/actions/clusterActions";

const ClusterReplicaSetDevExpTable = (props) => {
  const {
    data,
    loading,
    error,
    clusterName,
    configName,
    addReplicaSetHidden,
    clusterReplicaSetHidden,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [statuses, setStatuses] = useState([]);
  const [replicaSetsRows, setReplicaSetsRows] = useState([]);
  // const [metaData,setMetaData]=useState([]);
  const [currentPageClusterPods, setCurrentPageClusterPods] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [tableData, setTableData] = useState({
    columns: clusterReplicaSetsColumns,
    widths: clusterReplicaSetsDefaultColumnWidths,
    extentions: clusterReplicaSetsTableColumnExtensions,
    orders: clusterReplicaSetsColumnOrder,
    hides: [],
  });
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const styles = listStyles();
  //   const clusterName = props.match.params.clusterName

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(async () => {
    const getClusterReplicaSets = async () => {
      const { _msg, _status, data, _totalcount } =
        await fetchClusterReplicaSets(
          configName,
          clusterName,
          currentPageClusterPods
        );
      setTotalCount(_totalcount);
      if (_status === 200) {
        let metaData = [];

        data?.map((item) => {
          const readReplicas = item?.status?.readyReplicas
            ? item?.status?.readyReplicas
            : 0;
          const replicas = item?.status?.replicas;
          item.metadata[
            "status"
          ] = `${readReplicas?.toString()}/${replicas?.toString()}`;

          item.metadata["applicationname"] = item?.deploymentname;
          item.metadata["creationTimestamp"] = moment(
            item?.metadata?.creationTimestamp
          ).fromNow();

          item.metadata["image"] = item?.spec?.template?.spec?.containers?.map(
            (item) => {
              return item?.image;
            }
          );
          item.metadata["labels"] = `app:${item?.metadata?.labels.app}`;
          metaData.push(item.metadata);
        });
        setReplicaSetsRows(metaData);
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    getClusterReplicaSets();
  }, [currentPageClusterPods]);

  return (
    <main>
      <div style={{margin:"0px -16px"}}>
        <NewDevExpressTable
          columns={clusterReplicaSetsColumns}
          rows={replicaSetsRows}
          windowDimensions={windowDimensions}
          defaultColumnWidths={clusterReplicaSetsDefaultColumnWidths}
          tableColumnExtensions={clusterReplicaSetsTableColumnExtensions}
          columnOrder={clusterReplicaSetsColumnOrder}
          hidden={clusterReplicaSetHidden}
          setHidden={addReplicaSetHidden}
          noDataMsg="No Replica Sets Found"
          defaultHeight={true}
        />
        {totalCount > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={currentPageClusterPods}
                onChange={(e, val) => setCurrentPageClusterPods(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        ) : (
          <></>
        )}{" "}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllClusterDeployment,
  addClusterDeploymentHidden,
  addClusterDeploymentSort,

  addReplicaSetHidden,
  // fetchClusterPods
};

const mapStateToProps = (state) => {
  const { clusterPodsList, clusterPodsSort, clusterReplicaSetHidden } =
    state.clusterReducer;
  const { data, loading, error } = clusterPodsList;

  return {
    clusterPodsList,
    clusterPodsSort,
    clusterReplicaSetHidden,
    loading,
    error,
    data,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterReplicaSetDevExpTable);
