import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();

export class TeamAPIServices {
  getTeamList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/teams?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  fetchUsers(filter) {
    const queryParam = filter !== undefined ? `&filters=${filter}` : "";
    const uri = `${BASE_URL}/api/v1.0.0/users?${queryParam}`;
    return apiHelper.get(uri);
  }
  getTeamListByProject(projectId) {
    const uri = `${BASE_URL}/api/v1.0.0/projects/${projectId}/teams`;
    return apiHelper.get(uri);
  }
  getTeamById(teamId) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}`;
    return apiHelper.get(uri);
  }
  assignDeassignTeamToProject(projectId, teamData, forType) {
    const uri = `${BASE_URL}/api/v1.0.0/projects/${projectId}/${forType}`;
    return apiHelper.post(uri, teamData);
  }
  assignDeassignTeamToServiceGroup(projectId, groupId, teamData, forType) {
    const uri = `${BASE_URL}/api/v1.0.0/projects/${projectId}/servicegroups/${groupId}/${forType}`;
    return apiHelper.post(uri, teamData);
  }
  createTeam(teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams`;
    return apiHelper.post(uri, teamData);
  }
  editTeam(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}`;
    return apiHelper.put(uri, teamData);
  }
  LockTeam(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/lock/lock`;
    return apiHelper.post(uri, teamData);
  }
  UnLockTeam(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/lock/unlock`;
    return apiHelper.post(uri, teamData);
  }
  BLockTeam(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/block/block`;
    return apiHelper.post(uri, teamData);
  }
  UnBLockTeam(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/block/unblock`;
    return apiHelper.post(uri, teamData);
  }
  AddTeamMember(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/users`;
    return apiHelper.post(uri, teamData);
  }
  RemoveTeamMember(teamId, teamData) {
    const uri = `${BASE_URL}/api/v1.0.0/teams/${teamId}/users/_delete`;
    return apiHelper.put(uri, teamData);
  }
}
