import React, {
  useEffect,
  useState,
  useCallback,
} from "react";
import { Backdrop, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CodeGround from "./monaco_editor/CodeGround";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PublishIcon from "@material-ui/icons/Publish";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import BuildIcon from "@material-ui/icons/Build";

import "./style.css";
import CheckIcon from "@material-ui/icons/Check";
import debounce from "lodash.debounce";

import {
  copyVersion,
  deleteDraft,
  fetchFileContent,
  publishVerison,
  updateFileContent,
  buildDraftVersion,
  handleRun,
  handleStop,
  removeLock,
  fetchFileTreeByFolder,
} from "../../utils/code_editor/CodeEditorUtils";
import { DeleteDockerImage, getDockerImageBuildStatus, imageDetails } from "../../utils/containerMangement/imageUtils";

import { DeleteContainer, getContainerDetailsByID, getContainerStatusByName, StopContainer } from "../../utils/containerMangement/containerUtils";
import PublishSourceCodeModel from "../../components/common/modals/PublishSourceCodeModel";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import {
  fetchVersionById,
  getRepositoryDetailsByMicroServiceVersion,
  fetchServiceById,
} from "../../utils/project/microserviceUtils";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { DiscardModal } from "../../components/common/modals/DiscardModal";
import { AppButton } from "../../components/common/Button";
import {
  codeEditorLockFile,
  codeEditorUnLockFile,
  updateActiveEditorPath,
  updateTreeData,
} from "../../redux/actions/codeEditorAction";
import { connect, useSelector } from "react-redux";
import CodeEditorConsole from "./CodeEditorConsole";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import { useBeforeunload } from "react-beforeunload";

import FileTree from "./fileTree/FileTree";
import { BASE_URL } from "../../services/helper/config";
import SplitPane, { Pane } from "react-split-pane";
import CodeEditorLoader from "../../components/common/stuff/CodeEditorLoader";
import Loader from "../../components/common/stuff/Loader";
import { GetDraftContainerStatusWS } from "./web_socket/ConsoleWS";
import { tabbedActiveTabAction} from "../../redux/actions/serviceAction";
import ConfirmReuploadDeleteImage from "../../components/common/modals/ConfirmReuploadDeleteImage";

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay] // will recreate if delay changes
  );
  return debouncedFn;
}

const EditorScreen = (props) => {

  const [customUserInactivity, setCustomUserInactivity] = useState(props.userinactivity * 60 * 1000);
  const styles = manageScreenStyles();
  const { serviceActiveTab,tabbedActiveTabAction } = props;
  const [serviceId] = useState(props.match.params.serviceId);
  const [versionId] = useState(props.match.params.versionId);
  const [fileTabHide, setFileTabHide] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [isExecutableFile, setIsExecutableFile] = useState(false);
  const [tempFileContent, setTempFileContent] = useState(null);
  const [load, setLoad] = useState(true);
  const [publishModel, setPublishModel] = useState(false);
  const [currentFilePath, setCurrentFilePath] = useState(null);
  const [languageType, SetLanguageType] = useState(null);
  const [isDraft, SetIsDraft] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isFileLoader, setIsFileLoader] = useState(false);

  const [isDiscardModel, setIsDiscardModel] = useState(false);
  const [isEditedCode, setIsEditedCode] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [imageId, setImageId] = useState();
  const [isBuildSuccessful, setIsBuildSuccessful] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [containerId, setContainerId] = useState();
  const [browserURL, setBrowserURL] = useState("");
  const [onEditClick, setOnEditClick] = useState(false);
  const [tempBrowserURL, setTempBrowserURL] = useState();
  const [currentFileName, setCurrentFileName] = useState();
  const [isUsedByOtherUser, setIsUsedByOtherUser] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const [fileName, setFileName] = useState(null);
  const [guiLoader, setGUILoader] = useState(true);
  const [isGUI, setIsGUI] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const userData = useSelector((state) => state.authReducer);
  const [buildClicked, setBuildClicked] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [containerName, setContainerName] = useState();
  const [confirmReuploadDeleteImage, setConfirmReuploadDeleteImage] = useState(false);
  const [building, setBuilding] = useState(false);
  const [imageFound, setImageFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [gotStatus,setGotStatus]=useState(false);
  const history = useHistory();
  const stopIcon = (
    <i>
      <img src="/images/governance/stop.svg" alt="lock" />
    </i>
  );

  useEffect(() => {
    
    return () => {
      tabbedActiveTabAction(4)
    }
  }, [])
  

  useEffect(() => {
    if(containerId){
    const ws = GetDraftContainerStatusWS(containerId, props.userInfo._id);
      ws.onopen = function (e) {};

      ws.onmessage = function (event) {
        const socketData = JSON.parse(event.data);

        if(socketData?.userid !== props.userInfo._id){
          setIsUsedByOtherUser(true)
        } else {
          setIsUsedByOtherUser(false)
        }
        if (socketData?.state === "running") {
          setIsRunning(true)
        } else {
          setIsRunning(false)
        }
      };

      return () => {
        ws.close();
      };
    }
      
  }, [containerId])
  
  
  useEffect(() => {
    if (props.userinactivity) {
      setCustomUserInactivity(props.userinactivity * 60 * 1000);
    } else {
      setCustomUserInactivity(1200000);
    }
  }, [])
  

  useEffect(() => {
    setGUILoader(true);
    const fetchServiceDetails = async () => {
      const { data } = await fetchServiceById(serviceId);
      if (data?.ai_task || data?.service_category === "Only API") {
        setIsGUI(false);
      } else {
        setIsGUI(true);
      }

      setTimeout(() => {
        setGUILoader(false);
      }, 500);
    };
    fetchServiceDetails();
  }, []);

  useEffect(() => {
    if (props.activePath.isDeleted) {
      setFileContent(null);
      setCurrentFileName(null);
      SetIsDraft(true);
    }
  }, [props.activePath]);

  useEffect(() => {
    const interval = setInterval(() => {
      onSubmitFileUpdate();
    }, 1000);
    return () => clearInterval(interval);
  }, [currentFilePath, fileContent, tempFileContent]);

  useEffect(() => {
    let lastVisitedPath = "";
    const fetchFileDetails = async (path_name) => {
      let getLanguage = path_name.split(".");
      const languageArrLen = getLanguage.length;
      getLanguage = getLanguage[languageArrLen - 1];

      if (!getLanguage[1]) {
        getLanguage = "dockerfile";
      }
      if (getLanguage === "py") {
        SetLanguageType("python");
      } else if (getLanguage === "js") {
        SetLanguageType("javascript");
      } else if (getLanguage === "go") {
        SetLanguageType("go");
      } else if (getLanguage === "java") {
        SetLanguageType("java");
      } else if (getLanguage === "dockerfile") {
        SetLanguageType("dockerfile");
      } else if (getLanguage === "html") {
        SetLanguageType("html");
      } else if (getLanguage === "css") {
        SetLanguageType("css");
      } else if (getLanguage === "jsx") {
        SetLanguageType("javascript");
      } else if (getLanguage === "json") {
        SetLanguageType("json");
      } else {
        SetLanguageType(null);
      }

      let currentFileName = "";
      if (path_name) {
        let Spliting = path_name.split("/");
        currentFileName = Spliting[Spliting.length - 1];
      }
      const FileDetailsIds = {
        microservice: serviceId,
        version: versionId,
        draft: true,
        filepath: path_name,
      };
      const { _msg, _status, data } = await fetchFileContent(FileDetailsIds);

      if (_status === 200) {
        setIsLoader(false);
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
        setIsLoader(false);
      }
      setFileContent(data);
      setCurrentFileName(currentFileName);
      setTempFileContent(data);
    };

    const fetchFilesTree = async () => {
      setIsFileLoader(true);
      const { _msg, _status, data } = await fetchFileTreeByFolder({
        microservice: serviceId,
        version: versionId,
        latestpath: "",
        tree: false,
      });
      if (_status === 200) {
        setIsLoader(false);
        lastVisitedPath = data[1]?.lastvisitpath;
        let currentFileName = "";
        if (lastVisitedPath) {
          let Spliting = lastVisitedPath.split("/");
          currentFileName = Spliting[Spliting.length - 1];
        }

        props.updateActiveEditorPath({
          path_name: lastVisitedPath,
          type: "file",
          name: currentFileName,
        });
        props.updateTreeData(data);
        setCurrentFilePath(lastVisitedPath);
        if (lastVisitedPath) {
          fetchFileDetails(lastVisitedPath);
        }
        setIsFileLoader(false);
      } else {
        enqueueSnackbar(_msg, {
          variant: "error",
        });
        setIsLoader(false);
        setIsFileLoader(false);
      }

      setLoad(false);
    };

    const checkIsDraftTrue = async () => {
      const { _msg, _status, data } =
        await getRepositoryDetailsByMicroServiceVersion(serviceId, versionId);

      setFileName(data?.filename);
      if (_status === 200) {
        SetIsDraft(data.draft);
      }
    };

    const fetchVersionDetails = async () => {
      const { _msg, _status, data } = await fetchVersionById(
        serviceId,
        versionId
      );
      if (_status === 200) {
        setIsLoader(false);
        setVersionData(data);
        setContainerId(data.draftdetails?.containerid)
        setImageId(data.draftdetails?.imageid);
        let browserUrlVersion = `${window.location.origin}:${data.port}`;
        setBrowserURL(browserUrlVersion);
      }
    };

    checkIsDraftTrue();
    fetchFilesTree();
    fetchVersionDetails();
  }, []);

  const appendTree = (data, folderData, folderPath) => {
    data?.map((item) => {
      if (item.path_name === folderPath) {
        item.children = folderData;
        item["isLoader"] = "stop";
      }
      if (item.type === "folder") {
        appendTree(item.children, folderData, folderPath);
      }
    });
  };

  const fetchFilesTree = async (folderPath) => {
    const { _msg, _status, data } = await fetchFileTreeByFolder({
      microservice: serviceId,
      version: versionId,
      latestpath: folderPath,
      tree: true,
    });

    if (_status === 200) {
      let tempData = [...props.treeData];
      appendTree(tempData, data, folderPath);
      props.updateTreeData(tempData);
    } else {
      enqueueSnackbar(_msg, {
        variant: "error",
      });
    }
  };

  const refreshFileTree = async (folderPath) => {
    setIsFileLoader(true);
    const { _msg, _status, data } = await fetchFileTreeByFolder({
      microservice: serviceId,
      version: versionId,
      latestpath: "",
      tree: false,
    });

    if (_status === 200) {
      props.updateTreeData(data);
      setIsFileLoader(false);
    } else {
      enqueueSnackbar(_msg, {
        variant: "error",
      });
      setIsFileLoader(false);
    }
  };

  const handleDownloadFile = async () => {
    const queryparma = `draft=true`;

    let response = await fetch(
      `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/_download?${queryparma}`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );

    if (response.status === 200) {
      let blob = await response.blob();

      var url = window.URL.createObjectURL(blob);

      var a = document.createElement("a");

      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const fetchFileDetails = async (path_name) => {
    const FileDetailsIds = {
      microservice: serviceId,
      version: versionId,
      draft: true,
      filepath: path_name,
    };
    if (path_name !== undefined) {
      const { _msg, _status, data, _errorcode } = await fetchFileContent(
        FileDetailsIds
      );
      if (_status === 200) {
        setIsLoader(false);
        setFileContent(data);
        setTempFileContent(data);
        setIsExecutableFile(false);
      } else {
        if (_errorcode === 510) {
          setIsExecutableFile(true);
        }
        enqueueSnackbar(_msg, { variant: "error" });
        setIsEditedCode(false);
        setFileContent("");
        setTempFileContent("");
      }
    }
  };

  const unLockFile = () => {
    props.codeEditorUnLockFile(serviceId, versionId);
  };

  const handleClick = (node) => {
    unLockFile();
    if (node.type === "file") {
      fetchFileDetails(node.path_name);
      setCurrentFileName(node.name);
      setCurrentFilePath(node.path_name);

      if (node?.path_name !== undefined) {
        let getLanguage = node?.path_name.split(".");
        const languageArrLen = getLanguage.length;
        getLanguage = getLanguage[languageArrLen - 1];

        if (!getLanguage[1]) {
          getLanguage = "dockerfile";
        }
        if (getLanguage === "py") {
          SetLanguageType("python");
        } else if (getLanguage === "js") {
          SetLanguageType("javascript");
        } else if (getLanguage === "go") {
          SetLanguageType("go");
        } else if (getLanguage === "java") {
          SetLanguageType("java");
        } else if (getLanguage === "dockerfile") {
          SetLanguageType("dockerfile");
        } else if (getLanguage === "html") {
          SetLanguageType("html");
        } else if (getLanguage === "css") {
          SetLanguageType("css");
        } else if (getLanguage === "jsx") {
          SetLanguageType("javascript");
        } else if (getLanguage === "json") {
          SetLanguageType("json");
        } else {
          SetLanguageType(null);
        }
      }
    } else {
      fetchFilesTree(node.path_name);
    }
  };

  const lockFile = async (currentFilePath) => {
    props.codeEditorLockFile(serviceId, versionId, {
      filepath: currentFilePath,
    });
  };

  const debouncedSave = useDebounce(
    (currentFilePath) => lockFile(currentFilePath),
    500
  );

  const onCodeChange = (value) => {
    setFileContent(value);
    setIsEditedCode(true);
    debouncedSave(currentFilePath);
  };

  const unlockCall = async () => {
    const { data, _msg, _status } = await removeLock(serviceId, versionId);

    if (_status === 200) {
      setIsLoader(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    event.preventDefault();

    unlockCall();
    if (!isUsedByOtherUser) {
      handleStopContainerCloseWindow();
    }
  });

  useEffect(() => {
    return () => {
      unlockCall();
      if (!isUsedByOtherUser) {
        handleStopContainerCloseWindow();
      }
    };
  }, []);

  window.onkeydown = () => {
    setCustomUserInactivity(customUserInactivity);
  };
  window.onmousemove = () => {
    setCustomUserInactivity(customUserInactivity);
  };
  let interval;
  useEffect(() => {
    interval = setTimeout(() => {
      if (!isUsedByOtherUser && customUserInactivity) {
        unlockCall();
        handleStopContainer(containerId);
      }
    }, customUserInactivity);

    return () => {
      clearTimeout(interval);
    };
  }, [currentFilePath,customUserInactivity, fileContent]);

  const onSubmitFileUpdate = async () => {
    if (props.activePath.type === "file") {
      if (currentFileName) {
        if (fileContent !== tempFileContent) {
          const formData = new FormData();
          formData.append(
            "meta",
            JSON.stringify({ filepath: props.activePath.path_name })
          );

          const blob = new Blob([fileContent], { type: "text/plain" });
          const file = new File([blob], currentFileName, {
            type: "text/plain",
          });
          formData.append("file", file);

          const { data, _status, _msg, _errorcode } = await updateFileContent(
            serviceId,
            versionId,
            formData
          );
          if (_status === 200) {
            setIsEditedCode(false);
            setTempFileContent(fileContent);
          } else {
            if (_errorcode !== 511) {
              fetchFileDetails(props.activePath.path_name);
            } else {
              setIsExecutableFile(true);
            }
            setTempFileContent("");
            setFileContent("");
            enqueueSnackbar(_msg, { variant: "error" });
            setIsEditedCode(false);
          }

          SetIsDraft(true);
        }
      }
    }
  };
  const overRideVersion = async () => {
    setIsLoader(true)
    const publishContent = {
      microservice: serviceId,
      sourceversion: versionId,
      destversion: versionId,
    };
    const { _data, _msg, _status } = await publishVerison(publishContent);

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);
      let goBackUrl = "";
      versionData?.ai_task
        ? (goBackUrl = `/aitask/details/${serviceId}`)
        : (goBackUrl = `/services/details/${serviceId}`);

      history.push(goBackUrl);
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const publishNewVersion = async (destVersionId) => {
    const publishContent = {
      microservice: serviceId,
      sourceversion: versionId,
      destversion: destVersionId,
    };
    const { _data, _msg, _status } = await publishVerison(publishContent);

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);
      history.goBack();
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setIsLoader(false);
    }
  };

  const handlePoll = async (imageId) => {
    const { _msg, _status, data } = await imageDetails(imageId);
    setBuildClicked(true);
    if (data?.state === "building") {
      setBuilding(true);
      setTimeout(() => {
        handlePoll(imageId);
        setIsPolling(true);
      }, 2000);
      setIsPolling(false);
    } else if (data?.state === "build success") {
      setBuilding(false);
      const msg = "Build is successful";
      enqueueSnackbar(msg, { variant: "success" });
      setIsBuildSuccessful(true);
      setImageId(data?._id)
      setIsLoader(false);
      setIsPolling(false);
      setBuildClicked(false);
    } else {
      const msgForuser = `Build Failed : ${_msg}`;
      setIsLoader(false);
      setBuildClicked(false);
      enqueueSnackbar(msgForuser, { variant: "error" });
    }
  };

  const checkIfBuilding = async () => {
    const { _msg, _status, data } = await getDockerImageBuildStatus(
      serviceId,
      versionId
    );
    if (_status === 200) {
      data?.map((item) => {
        if (item?.state === "building") {
          setImageId(item?._id);
          enqueueSnackbar("Build is Progress....", { variant: "info" });
          setBuilding(true);
          handlePoll(item?._id);
        } else if (item?.state === "build success") {
          setBuilding(false);
          setIsBuildSuccessful(true);
          setContainerName(versionId);
          setIsLoader(false);
        } else {
          setBuilding(false);
          // const msgForuser = `Build Failed : ${_msg}`;
          setIsLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    checkIfBuilding();
  }, []);

  let pollInterval;
  const handleBuild = async () => {
    const checkIfBuilding = async () => {
      const { _msg, _status, data } = await getDockerImageBuildStatus(
        serviceId,
        versionId
      );
      if (_status === 200) {
        data?.map((item) => {
          if (item?.state === "building") {
            setImageId(item?._id);
            enqueueSnackbar("Build is Progress....", { variant: "info" });
            setImageId(item?._id);
            setBuilding(true);
            handlePoll(item?._id);
          } else if (item?.state === "build success") {
            setBuilding(false);
            setImageId(item?._id);
            setImageFound(true);
            setContainerName(versionId);
            setIsLoader(false);
          } else {
            setBuilding(false);
            const msgForuser = `Build Failed : ${_msg}`;
            setIsLoader(false);
            setImageFound(false);
            enqueueSnackbar(msgForuser, { variant: "error" });
          }
        });
      }
    };
    await checkIfBuilding();

    setIsLoader(true);
    setContainerName(versionId);

    const { _msg, _status, data } = await buildDraftVersion(
      serviceId,
      versionId
    );
    if (!building) {
      if (_status === 201) {
        setImageId(data?.draftdetails?.imageid);

        handlePoll(data?.draftdetails?.imageid);
      } else if (_status === 500) {
        setImageId(data?.imageid);
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "error" });
      } else {
        const msgForuser = `Build Failed : ${_msg}`;
        enqueueSnackbar(msgForuser, { variant: "error" });
        setIsLoader(false);
      }
    } else {
      setBuilding(false);
      enqueueSnackbar("Build is in progress...", { variant: "info" });
      checkIfBuilding();
    }
  };

  const handleRunContainer = async () => {
    setIsLoader(true);

    const contName = { containername: containerName };
    const { _msg, _status, data } = await handleRun(
      serviceId,
      versionId,
      contName
    );

    if (_status === 200) {
      setIsLoader(false);
      setContainerId(data?.draftdetails?.containerid);

      setIsRunning(true)

      enqueueSnackbar(_msg, { variant: "success" });

      setIsLoader(false);

      if (data?.data?.state === "running") {
        setIsRunning(true);
        setIframeKey(iframeKey + 1);
      
        setIsLoader(false);
      }
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setIsLoader(false);
    }
  };

  const handleStopContainer = async () => {
    setIsLoader(true);
    const versionData = await fetchVersionById(serviceId, versionId);

    const liveContainerId = versionData?.data?.draftdetails?.containerid;
    if (liveContainerId) {
      const containerStatus = await getContainerDetailsByID(liveContainerId);
      if (containerStatus?.data?.userid === props.userInfo._id) {
        if (containerStatus?.data.state !== "exited") {
          const { _msg, _status, data } = await handleStop(
            serviceId,
            versionId
          );
          if (_status === 200) {
            setIsLoader(false);
            setIsLoader(false);
            setIsRunning(false);
            setIsUsedByOtherUser(false);
          } else {
            setIsLoader(false);
          }
        } else {
          setIsRunning(false);
        }
      } else {
        setIsLoader(false);
        // enqueueSnackbar("You can't stop container because someone started", {
        //   variant: "error",
        // });
      }
    }
  };

  const handleStopContainerCloseWindow = async () => {
    const { _msg, _status, data } = await handleStop(serviceId, versionId);
  };

  const createNewVersion = async (versionData) => {
    const validate = () => {
      let versionError = {};
      let isValid = true;
      if (!versionData.version_tag) {
        versionError["version_tag"] = "Version Tag is required";
        isValid = false;
      }

      if (!versionData.port) {
        versionError["port"] = "Port is required";
        isValid = false;
      }
      setValidationError(versionError);
      return isValid;
    };

    if (validate()) {
      setIsLoader(true);
      setPublishModel(false);
      const copyVersionContent = {
        microserviceID: serviceId,
        versionID: versionId,
        versionTag: versionData.version_tag,
        port: versionData.port,
      };

      const { data, _msg, _status } = await copyVersion(copyVersionContent);

      if (_status === 201) {
        publishNewVersion(data._id);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  const discardDraft = async () => {
    const { _data, _msg, _status } = await deleteDraft(serviceId, versionId);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);
      setIsDiscardModel(false);
      SetIsDraft(false);
      let goBackUrl = "";
      versionData?.ai_task
        ? (goBackUrl = `/aitask/details/${serviceId}`)
        : (goBackUrl = `/services/details/${serviceId}`);

      history.push(goBackUrl);
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      setIsDiscardModel(false);
      setIsLoader(false);
    }
  };



  return (
    <>
      <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={isLoader}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {isFileLoader && <Loader />}

      <ConfirmReuploadDeleteImage
        open={confirmReuploadDeleteImage}
        handleSubmit={() => {
          setIsLoader(true);
          if (versionData?.docker?.Instances?.length > 0) {
            const handleStopDockerContainer = async () => {
              setConfirmReuploadDeleteImage(false);
              const { _status } = await StopContainer(
                versionData?.docker?.Instances[0]?.containerid
              );

              const handleDeleteContainer = async () => {
                const { _status } = await DeleteContainer(
                  versionData?.docker?.Instances[0]?.containerid
                );

                const handleDeleteDockerImage = async () => {
                  const { _status } = await DeleteDockerImage(
                    versionData?.docker?.imagedetails?.ImageID
                  );
                  overRideVersion()
                };
                handleDeleteDockerImage();
              };

              handleDeleteContainer();
            };

            handleStopDockerContainer();
          } else {
            setConfirmReuploadDeleteImage(false);

            const handleDeleteDockerImage = async () => {
              setIsLoader(true);
              const { _status } = await DeleteDockerImage(
                versionData?.docker?.imagedetails?.ImageID
              );
              overRideVersion()
            };
            handleDeleteDockerImage();
        
          }
        }}
        handleClose={() => setConfirmReuploadDeleteImage(false)}
      />

        
        {guiLoader ? (
          <CodeEditorLoader />
        ) : (
          <>
            {publishModel && (
              <PublishSourceCodeModel
                open={true}
                handleClose={() => setPublishModel(false)}
                overRideVersion={() => setConfirmReuploadDeleteImage(true)}
                createNewVersion={createNewVersion}
                validationError={validationError}
              />
            )}
            {isDiscardModel && (
              <DiscardModal
                open={isDiscardModel}
                handleClose={() => setIsDiscardModel(false)}
                handleDelete={discardDraft}
              />
            )}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ paddingLeft: "15px" }}>
                <BreadCrumbs
                  items={[
                    {
                      title: versionData?.ai_task
                        ? "AI Task"
                        : [
                            serviceActiveTab === "services"
                              ? "Service"
                              : "UI Services",
                          ],
                      path: versionData?.ai_task
                        ? "/aitask-workbench"
                        : "/services",
                    },
                    {
                      title: versionData?.microservice_name,
                      path: versionData?.ai_task
                        ? `/aitask/details/${serviceId}`
                        : `/services/details/${serviceId}`,
                    },
                    { title: versionData?.versionTag + " (Draft)" },
                  ]}
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "7px" }}>
                  <p>{isEditedCode ? "Saving..." : "Saved"}</p>
                </div>

                <AppButton
                  style={{ margin: "15px" }}
                  color="primary"
                  variant="contained"
                  startIcon={
                    building ? (
                      <CircularProgress
                        color="white"
                        style={{ height: "14px", width: "14px" }}
                      />
                    ) : (
                      <BuildIcon />
                    )
                  }
                  buttonName={building ? "Building" : "Build"}
                  // disabled={isRunning ? isRunning :!gotStatus ? true : isRunning}
                  disabled={isRunning ? true:false}
                  className="btnsmall"
                  onClick={handleBuild}
                />
                <AppButton
                  style={{ margin: "15px" }}
                  color="primary"
                  variant="contained"
                  startIcon={isRunning ? stopIcon : <PlayArrowIcon />}
                  buttonName={isRunning ? "Stop" : "Run"}
                  disabled={
                   !isBuildSuccessful || (isUsedByOtherUser && isRunning) 
                  }
                  className="btnsmall"
                  onClick={isRunning ? handleStopContainer : handleRunContainer}
                />
                <AppButton
                  style={{ margin: "15px" }}
                  color="primary"
                  variant="contained"
                  className="btnsmall"
                  buttonName="Discard"
                  disabled={!isDraft || (isUsedByOtherUser && isRunning)}
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => setIsDiscardModel(true)}
                />
                <AppButton
                  style={{ margin: "15px" }}
                  color="primary"
                  variant="contained"
                  className="btnsmall"
                  buttonName="Publish"
                  disabled={!isDraft || (isUsedByOtherUser && isRunning)}
                  startIcon={<PublishIcon />}
                  onClick={() => setPublishModel(true)}
                />
              </div>
            </div>

            <Grid container spacing={1} style={{ width: "100%" }}>
              <SplitPane split="vertical" minSize={100} defaultSize={100}>
                <div
                  minSize="15%"
                  initialSize={isFullScreen ? "15%" : "15%"}
                  style={{ height: "100%" }}
                >
                  {!fileTabHide && (
                    <Grid
                      item
                      spacing={0}
                      style={{ margin: 0, width: "100%", height: "100%" }}
                    >
                      {!load && (
                        <div
                          className="file-tree"
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                            margin: "0px 0px 0px 10px",
                            borderColor: "#DEDEDE",
                            borderWidth: "1px",
                            borderStyle: "solid",
                          }}
                        >
                          <FileTree
                            versionId={versionId}
                            serviceId={serviceId}
                            fetchFilesTree={refreshFileTree}
                            handleDownload={handleDownloadFile}
                            handleClick={handleClick}
                          />
                        </div>
                      )}
                    </Grid>
                  )}
                </div>

                <div initialSize={isFullScreen ? "85%" : "55%"} minSize="30%">
                  <Grid item>
                    <div style={{ padding: "0px 5px 0px 5px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            background: "white",
                            borderColor: "#DEDEDE",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "5px",
                          }}
                        >
                          <p
                            style={{
                              padding: "0px 10px 0px 10px",
                              margin: "0px",
                            }}
                          >
                            {props.activePath?.path_name}
                          </p>
                        </div>
                        <div>
                          <Tooltip
                            title={
                              isFullScreen ? "Exit Full Screen" : "Full Screen"
                            }
                            arrow
                          >
                            <IconButton
                              size={"small"}
                              onClick={() => setIsFullScreen(!isFullScreen)}
                            >
                              {isFullScreen ? (
                                <FullscreenExitIcon />
                              ) : (
                                <FullscreenIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>

                      <CodeGround
                        data={fileContent}
                        currentFileName={currentFileName}
                        onCodeChange={onCodeChange}
                        languageType={languageType}
                        isExecutableFile={isExecutableFile}
                      />
                    </div>
                  </Grid>
                </div>

                <div
                  initialSize={isFullScreen ? "" : "30%"}
                  className={isFullScreen ? styles.guihide : ""}
                  maxSize="50%"
                >
                  {!isFullScreen && (
                    <Grid item style={{ height: "85vh" }}>
                      <SplitPane
                        split="horizontal"
                        minSize={100}
                        defaultSize={100}
                      >
                        {isGUI && (
                          <div
                            initialSize={isGUI ? "50%" : ""}
                            style={{ height: "100%" }}
                            minSize={isGUI ? "30%" : ""}
                            maxSize={isGUI ? "90%" : ""}
                          >
                            <div
                              style={{
                                background: "white",
                                height: "100%",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderColor: "#E8E8E8",
                                width: "100%",
                                top: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  color: "red",
                                  justifyContent: "space-around",
                                  paddingTop: "10px",
                                }}
                              >
                                <Tooltip title="Refresh" arrow>
                                  <IconButton
                                    onClick={() => setIframeKey(iframeKey + 1)}
                                    style={{ width: "20px", color: "black" }}
                                    size={"small"}
                                  >
                                    <RefreshIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>

                                <input
                                  type="text"
                                  disabled={!onEditClick}
                                  value={
                                    onEditClick ? tempBrowserURL : browserURL
                                  }
                                  style={{ width: "70%" }}
                                  onChange={(e) =>
                                    setTempBrowserURL(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      setBrowserURL(tempBrowserURL);
                                      setOnEditClick(false);
                                    }
                                  }}
                                />

                                {onEditClick ? (
                                  <Tooltip title="Enter" arrow>
                                    <IconButton
                                      onClick={() => {
                                        setBrowserURL(tempBrowserURL);
                                        setOnEditClick(false);
                                      }}
                                      style={{ width: "20px", color: "black" }}
                                      size={"small"}
                                    >
                                      <CheckIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Edit URL" arrow>
                                    <IconButton
                                      onClick={() => {
                                        setOnEditClick(true);
                                        setTempBrowserURL(browserURL);
                                      }}
                                      style={{ width: "20px", color: "black" }}
                                      size={"small"}
                                    >
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Pop Out" arrow>
                                  <IconButton
                                    onClick={() => window.open(browserURL)}
                                    size={"small"}
                                    style={{ color: "black" }}
                                  >
                                    <LaunchIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </div>

                              <div
                                style={{ overflow: "hidden", marginTop: "3px" }}
                              >
                                <iframe
                                  key={iframeKey}
                                  id="browserWidget"
                                  style={{
                                    display: "block",
                                    border: "none",
                                    height: "39vh",
                                    width: "100%",
                                  }}
                                  src={browserURL}
                                  title="W3Schools Free Online Web Tutorials"
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          initialSize={isGUI ? "50%" : "100%"}
                          style={{ height: "100%" }}
                          minSize={isGUI ? "20%" : ""}
                        >
                          <div
                            style={{
                              background: "#1c2323",
                              color: "white",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "#E8E8E8",
                              borderRadius: "5px",
                              marginTop: [isGUI && "10px"],
                              height: "100%",
                            }}
                          >
                            <div style={{ color: "#fff", padding: "10px" }}>
                              <CodeEditorConsole
                                containerId={containerId}
                                imageId={imageId}
                                isBuildSuccessful={isBuildSuccessful}
                                buildClicked={buildClicked}
                                isPolling={isPolling}
                                size={isGUI}
                              
                                isRunning={isRunning}
                              />
                            </div>
                          </div>
                        </div>
                      </SplitPane>
                    </Grid>
                  )}
                </div>
              </SplitPane>
            </Grid>
          </>
        )}
      </main>
    </>
  );
};

const mapDispatchToProps = {
  updateActiveEditorPath,
  codeEditorLockFile,
  codeEditorUnLockFile,
  updateTreeData,
  tabbedActiveTabAction
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { userinactivity } = state.userDetails.userInfo;
  const { activePath, treeData } = state.codeEditorReducer;
  const { serviceActiveTab } = state.activeTabReducer;
  return {
    userInfo,
    activePath,
    serviceActiveTab,
    userinactivity,
    treeData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditorScreen);
