import React, { useState } from "react";
import {
    Select, MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { AppButton } from "../common/Button";

const useStyles = makeStyles((theme) => ({
    modal: {
        "& div": {
            "& .MuiDialogTitle-root": {
                padding: "0px",
                width: 418
            }
        }
    },
    section1: {
        padding: 15,
        paddingLeft: 20
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: "8rem"
    },
    heading: {
        font: "normal normal 600 14px/19px Nunito",
        color: "#333333DE"
    },
    label: {
        font: "normal normal 600 12px/16px Nunito",
        color: '#333333DE'
    },
    select: {
        marginTop: 10,
        border: "1px solid #DEDEDF",
        borderRadius: 4,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        "& .MuiSelect-select.MuiSelect-select": {
            minHeight: "1.67em",
            paddingLeft: 15
        }
    },
    cancel: {
        height: 40,
        width: 100,
        borderWidth: 1,
        borderColor: "#002855",
        borderRadius: 10,
        color: "#002855",
        textAlign: "center",
        marginRight: 20,
        // backgroundColor: "white",
    },
    submit: {
        height: 40,
        width: 100,
        borderRadius: 10,
        color: "white",
        textAlign: "center",
        backgroundColor: "#002855",
    },
}))
function SwitchNodeConditionModal(props) {
    const [condition, setCondition] = useState("")
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar()

    const { isOpen, switch_condition_obj } = props;
    const { conditionList } = switch_condition_obj;

    const handleSubmitClick = () => {
        if (condition !== "") {
            props.onSubmitClick({
                ...switch_condition_obj,
                condition: condition
            });
        } else {
            enqueueSnackbar("Select any one condition from list")
        }
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => props.onCloseClick()}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className={styles.modal}
                maxWidth="xl"
            >
                <DialogTitle id="scroll-dialog-title">
                    <div className={styles.section1}>
                        {/* <img src="/images/Call.png" alt="Icon" height={34} width={34} /> */}
                        <text className={styles.heading}>{'Select Condition'}</text>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className={styles.container}>
                        <text className={styles.label}>Conditions</text>
                        <Select
                            value={condition}
                            onChange={(e) => setCondition(e.target.value)}
                            displayEmpty
                            className={styles.select}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {conditionList && conditionList.length > 0 && conditionList.map((item) => {
                                return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={() => props.onCloseClick()}
                        color="primary"
                        buttonName="Cancel"
                        variant="outlined"
                        className="btnsmall" />
                    <AppButton onClick={handleSubmitClick}
                        color="primary"
                        buttonName="Submit"
                        variant="contained"
                        className="btnsmall"
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default SwitchNodeConditionModal
