export const data = {
  _id: '61c3005670d48fba53ceebbd',
  label: 'Api',
  name: 'Api',
  predefined: 'TRUE',
  predefined_sections: ['61c3005670d48fba53ceeb76', '61c3005670d48fba53ceeba8'],
  sections: [
    {
      _id: '61d54535aac55fb210449afb',
      createdby: 'sadiq',
      createdon: 1641366837,
      description: '',
      fieldgroups: [
        {
          _id: '61d544edaac55fb210449af8',
          createdby: 'sadiq',
          createdon: 1641366765,
          description: '',
          fieldGroupLabel: 'Kafka Config',
          fields: [
            {
              _id: '61d543dfaac55fb210449af0',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366495,
              dataType: 'string',
              datakey: 'sys_kafka_username',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'UserName',
              name: 'sys_kafka_username',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'string',
              uiElementType: 'TextField',
              values: [],
            },
            {
              _id: '61d543f8aac55fb210449af1',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366520,
              dataType: 'string',
              datakey: 'sys_kafka_password',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Password',
              name: 'sys_kafka_password',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'string',
              uiElementType: 'TextField',
              values: [],
            },
            {
              _id: '61d54420aac55fb210449af2',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366560,
              dataType: 'integer',
              datakey: 'sys_kafka_partition',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Partition',
              name: 'sys_kafka_partition',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'integer',
              uiElementType: 'TextField',
              values: [],
            },
            {
              _id: '61d54444aac55fb210449af3',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366596,
              dataType: 'string',
              datakey: 'sys_kafka_mechanism',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Authentication Mechanism',
              name: 'sys_kafka_mechanism',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'string',
              uiElementType: 'Dropdown',
              values: ['Plain', 'Scram'],
            },
          ],
          name: 'sys_kafka_config',
        },
      ],
      name: 'sys_kafka_config',
      'section Label': 'Kafka Configurables',
    },
    {
      _id: '61d54547aac55fb210449afc',
      createdby: 'sadiq',
      createdon: 1641366855,
      description: '',
      fieldgroups: [
        {
          _id: '61d54504aac55fb210449af9',
          createdby: 'sadiq',
          createdon: 1641366788,
          description: '',
          fieldGroupLabel: 'Rabbitmq Config',
          fields: [
            {
              _id: '61d54459aac55fb210449af4',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366617,
              dataKey: 'sys_rabbitmq_username',
              dataType: 'string',
              datakey: 'sys_rabbitmq_username',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'UserName',
              name: 'sys_rabbitmq_username',
              placeholder: '',
              required: 'FALSE',
              rules: {
                contentEncoding: '',
                contentMediaType: '',
                maxLength: null,
                minLength: null,
                pattern: null,
              },
              type: 'string',
              uiElementType: 'TextField',
              updatedby: 'sadiq',
              updatedon: 1641369281,
              values: [],
            },
            {
              _id: '61d5446eaac55fb210449af5',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366638,
              dataKey: 'sys_rabbitmq_password',
              dataType: 'string',
              datakey: 'sys_rabbitmq_password',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Password',
              name: 'sys_rabbitmq_password',
              placeholder: '',
              required: 'FALSE',
              rules: {
                contentEncoding: '',
                contentMediaType: '',
                maxLength: null,
                minLength: null,
                pattern: null,
              },
              type: 'string',
              uiElementType: 'TextField',
              updatedby: 'sadiq',
              updatedon: 1641369291,
              values: [],
            },
          ],
          name: 'sys_rabbitmq_config',
        },
      ],
      name: 'sys_rabbitmq_config',
      'section Label': 'Rabbitmq Configurables',
    },
    {
      _id: '61d5455baac55fb210449afd',
      createdby: 'sadiq',
      createdon: 1641366875,
      description: '',
      fieldgroups: [
        {
          _id: '61d5451aaac55fb210449afa',
          createdby: 'sadiq',
          createdon: 1641366810,
          description: '',
          fieldGroupLabel: 'Mqtt Config',
          fields: [
            {
              _id: '61d54488aac55fb210449af6',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366664,
              dataType: 'string',
              datakey: 'sys_mqtt_username',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Username',
              name: 'sys_mqtt_username',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'string',
              uiElementType: 'TextField',
              values: [],
            },
            {
              _id: '61d544a1aac55fb210449af7',
              accessPermission: 'create,read,edit,_',
              createdby: 'sadiq',
              createdon: 1641366689,
              dataType: 'string',
              datakey: 'sys_mqtt_password',
              defaultValue: '',
              description: '',
              editable: 'TRUE',
              errorMessage: '',
              fieldLabel: 'Password',
              name: 'sys_mqtt_password',
              placeholder: '',
              required: 'FALSE',
              rules: {},
              type: 'string',
              uiElementType: 'TextField',
              values: [],
            },
          ],
          name: 'sys_mqtt_config',
        },
      ],
      name: 'sys_mqtt_config',
      'section Label': 'Mqtt Configurables',
    },
  ],
  updatedby: 'sadiq',
  updatedon: 1641366903,
};
