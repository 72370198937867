import React, { useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";

import useStyles from "../styles/InputTableStyle";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function InputTable(props) {
  const styles = useStyles();
  const { envs, disabled } = props;

  const currentProject = useSelector((state) => state.uiApplicationReducer);
  const handleEnvChange = async (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event?.target?.value };
      } else {
        return env;
      }
    });
    props.setEnvs(newEnvs);
  };

  const handleAddEnv = (id) => {
    props.setEnvs([...envs, { id: uuidv4(), key: "", value: "" }]);
  };

  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      var newEnvs = envs.filter(
        (env) => env.id !== envToDelete && envs.length !== 1
      );
      props.setEnvs(newEnvs);
      // props.setEnvs((envs) =>
      //   envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      // );
    }
  };
  return (
    <div>
      <TableContainer>
        <Table
          className={styles.customTable}
          style={{ backgroundColor: "#fff" }}
        >
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              <TableCell>Key</TableCell>
              <TableCell align="left">Value</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {envs &&
              envs?.length !== 0 &&
              envs?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <input
                      type="text"
                      placeholder="Key"
                      name={row.id}
                      className={disabled ? styles.input2 : styles.input}
                      value={envs[index].key}
                      onChange={(e) => handleEnvChange(e, row.id, "key")}
                      autoComplete="off"
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="text"
                      placeholder="Value"
                      name={row.id}
                      className={disabled ? styles.input2 : styles.input}
                      value={envs[index].value}
                      onChange={(e) => handleEnvChange(e, row.id, "value")}
                      autoComplete="off"
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="inherit"
                      aria-label="delete"
                      edge="start"
                      onClick={() => handleDeleteEnv(row.id)}
                      className={styles.actionIcon}
                      disabled={disabled}
                    >
                      <img src="/images/deleteLine.svg" alt="card" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {envs && envs?.length === 0 && (
            <div className={styles.nodata}>
              <text>No Data Available</text>
            </div>
          )}
        </Table>
      </TableContainer>
      {!disabled && (
        <div
          style={{
            backgroundColor: "#fff",
            paddingLeft: "2rem",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            onClick={handleAddEnv}
            className={styles.actionIcon}
          >
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}