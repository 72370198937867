const apiColumns = [
  // { name: 'id', title: 'API ID' },
  { name: "name", title: "API Name", for: "api" },
  { name: "type", title: "Type", for: "api" },
  { name: "createdby", title: "Created By", for: "api" },
  { name: "createdon", title: "Created On", for: "api" },
  { name: "action", title: "Action", route: "/apis/edit", for: "api" },
];

const apiDefaultColumnWidths = [
  // { columnName: 'id', width: 280 },
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "type", weightage: 5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const apiTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "type", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const apiColumnOrder = [
  // 'id',
  "name",
  "type",
  "createdby",
  "createdon",
  // "action",
];

const apiColumnHidden = [
  "name",
  "type",
  "createdby",
  "createdon",
  "action",
];

const apiDefaultSorting = [];

export {
  apiColumns,
  apiDefaultColumnWidths,
  apiTableColumnExtensions,
  apiColumnOrder,
  apiDefaultSorting,
  apiColumnHidden
};
