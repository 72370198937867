import React from "react";
import { connect } from "react-redux";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import Header from "../../components/common/Header";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";

const SomethingWentWrong = (props) => {
  const styles = manageScreenStyles();

  const { recentVisited, errorMsg } = props;
  return (
    <>
      <Header />
      <main className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: recentVisited && recentVisited[0]?.childName,
                path: recentVisited && recentVisited[0]?.routeName,
              },
            ]}
          />
          <h2>Unable to process your request, please report to helpdesk.</h2>
          <h4>{errorMsg}</h4>
        </div>
        <div className={styles.wrapper}></div>
      </main>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { recentVisited } = state.viewedReducer;

  return {
    recentVisited,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrong);
