import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import CustomFieldGroupForTestDataCreate from "../../../components/common/entitySchema/test/CustomFieldGroupForTestDataCreate";
import CustomFieldGroupForTestExecutionCreate from "../../../components/common/entitySchema/test/CustomFieldGroupForTestExecutionCreate";
import CustomFieldGroupForTestScriptCreate from "../../../components/common/entitySchema/test/CustomFieldGroupForTestScriptCreate";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import Loader from "../../../components/common/stuff/Loader";
import { handleFormValidationPro, initError } from "../../../utils/common";
import {
  fetchAllDeployments,
  FetchDeploymentById,
} from "../../../utils/deployment/deploymentUtils";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import {
  addTestData,
  getAllTestDataList,
  getAllTestEnvironment,
  getAllTestScriptList,
  getTestEnvData,
} from "../../../utils/project/testUtils";

import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { v4 as uuidv4 } from "uuid";
import { TestExecute } from "../../../utils/test/testExecutionUtils";
import { connect } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SaveIcon from "@material-ui/icons/Save";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { Element } from "react-scroll";

const AddTestExecutionScreen = (props) => {
  const styles = manageScreenStyles();
  const style = listScreenStyles();
  const { tabbedView } = props;
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({});
  const [testScriptEntitySchema, setTestScriptEntitySchema] = useState([]);
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    filename: "",
  });
  const [deployment, setDeployment] = useState();
  const [scriptData, setScriptData] = useState();
  const [selectedDeployment, setSelectedDeployment] = useState();
  const [selectedScript, setSelectedScript] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedEnvFile, setSelectedEnvFile] = useState();

  const [testData, setTestData] = useState();
  const [envs, setEnvs] = useState([
    { id: uuidv4(), key: "", value: "", enabled: true },
  ]);
  const [envData, setEnvData] = useState();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [payloadData, setPayloadData] = useState({
    description: "",
    name: "",
    script: "",
    testdata: "",
    deployment: "",
  });

  useEffect(() => {
    if (selectedDeployment) {
      setError({
        ...error,
        ["deployment"]: {
          ...error["deployment"],
          isError: false,
          msg: "",
        },
      });

      setPayloadData({
        ...payloadData,
        ["deployment"]: selectedDeployment?._id,
      });
    }
  }, [selectedDeployment]);

  useEffect(() => {
    if (selectedScript) {
      setError({
        ...error,
        ["script"]: {
          ...error["script"],
          isError: false,
          msg: "",
        },
      });

      setPayloadData({
        ...payloadData,
        ["script"]: selectedScript?._id,
      });
    } else {
      setPayloadData({
        ...payloadData,
        ["script"]: "",
      });
    }
  }, [selectedScript]);

  useEffect(() => {
    if (selectedData) {
      setError({
        ...error,
        ["testdata"]: {
          ...error["testdata"],
          isError: false,
          msg: "",
        },
      });

      setPayloadData({
        ...payloadData,
        ["testdata"]: selectedData?._id,
      });
    } else {
      setPayloadData({
        ...payloadData,
        ["testdata"]: "",
      });
    }
  }, [selectedData]);

  useEffect(() => {
    const fetchTestScriptEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "TestExecute",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) => {
          if (section.name !== "Test Run Details") {
            section?.fieldgroups?.map((fieldgroup) => {
              if (fieldgroup?.fields?.length !== 0) {
                entity.push(section);
              }
            });
          }
        });
        setTestScriptEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        // setProjectData(prevData => ({ ...prevData, ...projData, ...newProjectData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        setError({
          ...errData,
          deployment: { ...errData["deployment"], section: 1 },
          script: { ...errData["script"], section: 2 },
        });
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchAllDeploymentsList = async () => {
      const { data, _status } = await fetchAllDeployments();
      if (_status === 200) {
        setDeployment(data);
      }
    };

    const getAllTestData = async () => {
      const { data, _status } = await getAllTestDataList();
      if (_status === 200) {
        setTestData(data);
      }
    };

    const getAllTestScripts = async () => {
      const { data, _status } = await getAllTestScriptList();
      if (_status === 200) {
        setScriptData(data);
      }
    };

    const getAllTestEnvironmentList = async () => {
      const { data, _status } = await getAllTestEnvironment();
      if (_status === 200) {
        setEnvData(data);
      }
    };

    fetchAllDeploymentsList();
    getAllTestScripts();
    getAllTestEnvironmentList();
    getAllTestData();
    fetchTestScriptEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    setPayloadData({
      ...payloadData,
      [event.target.name]: value,
    });
  };

  const handleUpload = (files) => {
    setFileDetails({ filename: files ? (files[0] ? files[0].name : "") : "" });

    setDroppedDocument(files);
    setError({ ...error, droppedDocument: false });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(payloadData, error);

    const { tempEntity, errorInTab } = TabsCommon(
      testScriptEntitySchema,
      errors,
      tabbedView
    );

    setActiveTab(errorInTab === undefined ? 0 : errorInTab);
    setTestScriptEntitySchema(tempEntity);

    if (!formIsValid) {
      setError(errors);
    }
    if (formIsValid) {
      let foundEndPoint;
      selectedDeployment?.deploymentDetails?.map((item) => {
        if (
          item?.serdetails?.status?.loadBalancer !== undefined &&
          Object.keys(item?.serdetails?.status?.loadBalancer).length !== 0
        ) {
          var endpoint = `http://${item?.serdetails?.status?.loadBalancer?.ingress[0]?.hostname}:${item?.Port}`;
        }
        if (endpoint) {
          envs?.map((item) => {
            if (item.value.includes(endpoint)) {
              foundEndPoint = true;
            }
          });
        }
      });

      if (foundEndPoint) {
        setIsLoader(true);
        let testExecuteData = {
          collectionid: selectedScript?._id,
          inputdataid: selectedData?._id,
          environmentid: selectedEnvFile,
          deploymentid: selectedDeployment?._id,
          deploymentname: selectedDeployment?.deploymentname,
          name: payloadData?.name,
          environments: envs,
        };

        if (!testExecuteData?.environmentid) {
          delete testExecuteData["environmentid"];
        }

        const { _msg, _status, data } = await TestExecute(testExecuteData);
        if (_status === 200) {
          setIsLoader(false);
          enqueueSnackbar(_msg, { variant: "success" });
          history.push("/test");
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(
              _msg
                ? _msg
                : "Unable to process your request, please try after sometime",
              { variant: "error" }
            );
          }
        }
      } else {
        setActiveTab(3);
        enqueueSnackbar(
          "Please provide one of the Deployment endpoints in the environment variables for testing the Deployment.",
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleDeleteClick = () => {
    setDroppedDocument([]);
    setFileDetails({
      filename: "",
    });
  };

  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "", enabled: true }]);
  };

  const handleEnvChange = (event, id, column) => {
    if (column === "enabled") {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.checked };
        } else {
          return env;
        }
      });
    } else {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.value };
        } else {
          return env;
        }
      });
    }

    setEnvs(newEnvs);
  };
  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  const setEnvironmentData = async (e) => {
    if (e) {
      setSelectedEnvFile(e._id);
      setIsLoader(true);
      const { data, _status } = await getTestEnvData(e._id);

      if (_status === 200) {
        let customizeData = data.map((item) => {
          item["id"] = uuidv4();
          return item;
        });
        setEnvs(customizeData);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } else {
      setEnvs([{ id: uuidv4(), key: "", value: "", enabled: true }]);
      setSelectedEnvFile(null);
    }
  };

  const handleDeployment = async (selectedData) => {
    if (selectedData) {
      setIsLoader(true);
      const { data, _status } = await FetchDeploymentById(selectedData?._id);
      if (_status === 200) {
        setSelectedDeployment(data);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } else {
      setSelectedDeployment(null);
      setPayloadData({ ...payloadData, deployment: "" });
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    history.push("/test");
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Test", path: "/test" },
            { title: "Execution", path: "/test" },

            {
              title: isEditMode
                ? `Edit ${"serviceData.name"}`
                : "Create Test Run",
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {isEditMode ? `Edit ${"serviceData.name"}` : "Create Test Run"}
          </h2>
          {tabbedView ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              &nbsp;&nbsp;
              {!isEditMode ? (
                <AppButton
                  color="primary"
                  style={{ margin: "unset" }}

                  buttonName="Execute"
                  variant="contained"
                  className="btnsmall"
                  onClick={handleSubmit}
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  style={{ margin: "unset" }}

                  className="btnsmall"
                  onClick={handleSubmit}
                  disabled={!checkCompPermission("Microservice", ["Edit"])}
                />
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AppButton
                color="primary"
                style={{ margin: "unset" }}

                buttonName="Cancel"
                variant="outlined"
                className="btnsmall"
                onClick={handleCancel}
              />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isEditMode ? (
                <AppButton
                  color="primary"
                  buttonName="Execute"
                  variant="contained"
                  style={{ padding: "6px 15px" }}
                  className="btnsmall"
                  onClick={handleSubmit}
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  disabled={!checkCompPermission("Microservice", ["Edit"])}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleSubmit}
                />
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AppButton
                color="primary"
                style={{ margin: "unset" }}

                buttonName="Cancel"
                variant="outlined"
                className="btnsmall"
                onClick={handleCancel}
              />
            </div>
          )}
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {tabbedView && (
          <SectionTabs
            entity={testScriptEntitySchema}
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
        )}
        {tabbedView ? (
          <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
            <div style={{ padding: "0px 0px 0" }}>
              <CustomFieldGroupForTestExecutionCreate
                section={testScriptEntitySchema?.[activeTab]}
                payloadData={payloadData}
                handleChange={handleChange}
                error={error}
                handleUpload={handleUpload}
                setIsLoader={setIsLoader}
                droppedDocument={droppedDocument}
                fileDetails={fileDetails}
                handleDeleteClick={handleDeleteClick}
                deployment={deployment}
                testData={testData}
                scriptData={scriptData}
                selectedDeployment={selectedDeployment}
                handleDeployment={handleDeployment}
                envs={envs}
                handleAddEnv={handleAddEnv}
                handleDeleteEnv={handleDeleteEnv}
                handleEnvChange={handleEnvChange}
                envData={envData}
                setSelectedData={setSelectedData}
                setSelectedScript={setSelectedScript}
                setEnvironmentData={setEnvironmentData}
              />
            </div>
          </Paper>
        ) : (
          testScriptEntitySchema.length !== 0 &&
          testScriptEntitySchema.map((section, index) => {
            if (section.name === "Test_Execute_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForTestExecutionCreate
                    section={section}
                    payloadData={payloadData}
                    handleChange={handleChange}
                    error={error}
                    handleUpload={handleUpload}
                    setIsLoader={setIsLoader}
                    droppedDocument={droppedDocument}
                    fileDetails={fileDetails}
                    handleDeleteClick={handleDeleteClick}
                    deployment={deployment}
                  />
                </div>
              );
            } else {
              return (
                <Element name={index}>
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingLeft: 5 }}>
                      <CustomFieldGroupForTestExecutionCreate
                        section={section}
                        payloadData={payloadData}
                        handleChange={handleChange}
                        error={error}
                        handleUpload={handleUpload}
                        setIsLoader={setIsLoader}
                        droppedDocument={droppedDocument}
                        fileDetails={fileDetails}
                        handleDeleteClick={handleDeleteClick}
                        deployment={deployment}
                        testData={testData}
                        scriptData={scriptData}
                        selectedDeployment={selectedDeployment}
                        handleDeployment={handleDeployment}
                        envs={envs}
                        handleAddEnv={handleAddEnv}
                        handleDeleteEnv={handleDeleteEnv}
                        handleEnvChange={handleEnvChange}
                        envData={envData}
                        setSelectedData={setSelectedData}
                        setSelectedScript={setSelectedScript}
                        setEnvironmentData={setEnvironmentData}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Element>
              );
            }
          })
        )}
        { }
      </div>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return {
    tabbedView,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTestExecutionScreen);
