const AITaskColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "AI Task Name", for: "AITask" },
  { name: "createdby", title: "Created By", for: "AITask" },
  { name: "createdon", title: "Created On", for: "AITask" },
  { name: "status", title: "Status", for: "AITask" },
  { name: "action", title: "Action", route: "/aitask/edit", for: "AITask" },
];

const AITaskDefaultColumnWidths = [
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "status", weightage: 1.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const AITaskTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const AITaskColumnOrder = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "description",
  "imagename",
  "serviceType",
  "updatedby",
  "updatedon",

  "action",
];

const AITaskColumnHidden = [
  // 'id',
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
];

const AITaskDefaultSorting = [];

export {
  AITaskColumns,
  AITaskDefaultColumnWidths,
  AITaskTableColumnExtensions,
  AITaskColumnOrder,
  AITaskDefaultSorting,
  AITaskColumnHidden,
};
