import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory, useLocation } from "react-router-dom";
import DocumentDeleteModal, { DeleteModal } from "../modals/DeleteModal";
import DependencyModal from "../modals/DependencyModal";
import {
  alterFavouriteProjectById,
  alterSubscibeProjectById,
  deleteProjectById,
  updateServiceGroupMode,
} from "../../../redux/actions/projectAction";
import {
  alterFavouriteServiceGUIById,
  alterSubscibeServiceGUIById,
  deleteServiceGUIById,
} from "../../../redux/actions/serviceGUIAction";
import {
  deleteProject,
  fetchProjectById,
} from "../../../utils/project/projectUtils";
import {
  deleteServiceGroup,
  fetchEntitiesByVersionId,
  fetchServiceInfoByServiceId,
} from "../../../utils/project/servicegroupUtils";
import {
  deleteMicroService,
  deleteVersion,
  deleteAPI,
  createDockerFile,
} from "../../../utils/project/microserviceUtils";
import {
  deleteDocumentsByID,
  deleteAllDocumentsByProjectResourceID,
  deleteDocumentsByServiceGroupResourceID,
  deleteDocumentsByServiceResourceID,
} from "../../../utils/project/documentUtils";
import { useSnackbar } from "notistack";
import devExpressStyles from "../styles/DevExpressTableStyles";
import ContainerModal from "../../common/modals/ContainerModal";
import { BASE_URL, DOCUMENT } from "../../../services/helper/config";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import SimulateModalForMicroservice from "../../Orchestration/SimulateModal/SimulateModalForMicroservice";
import { useDispatch, useSelector } from "react-redux";
import {
  alterFavouriteServiceById,
  alterSubscibeServiceById,
  deleteAPIById,
  deleteServiceById,
  deleteVersionById,
  editAPITabbed,
  editVersionTabbed,
  getVersionList,
  setSelectedAPIId,
  setSelectedMicroService,
  setSelectedVersionId,
  tabbedActiveTabAction,
} from "../../../redux/actions/serviceAction";

import {
  delete_single_project_documents_by_id,
  delete_single_service_documents_by_id,
  delete_single_service_group_documents_by_id,
} from "../../../redux/actions/documentAction";
import {
  deleteMicroserviceInServiceGroupById,
  deleteServiceGroupById,
} from "../../../redux/actions/serviceGroupAction";
import {
  addFavouriteInResource,
  addSubscriptionInResource,
  deleteFavouriteInResource,
  deleteUserSubscriptionInResource,
} from "../../../utils/users/userUtils";
import { deleteDataModelDocument } from "../../../utils/dataModels/dataModelutils";
import UploadSourceCodeInVersion from "../modals/UploadSourceCodeInVersion";
import {
  addSingleFavourite,
  addSingleUserSubscription,
  removeSingleFavourite,
  removeSingleUserSubscription,
} from "../../../redux/actions/userPersonalAction";
import moment from "moment";
import { deleteRole } from "../../../utils/accessMaintenance/accessMaintenanceUtils";
import { deleteRoleById } from "../../../redux/actions/accessmaintainanceAction";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import useCheckPermission from "../hooks/useCheckPermission";
import PagesModel from "../../../UiApplication/components/molecules/projectSetup/PagesModel";
import {
  DeleteUiApplication,
  DeleteTemplate,
  downLoadDynamicBuild,
  buildUIApplication,
  fetchUiApplicationById,
} from "../../../UiApplication/services/UiApplicationApiUtils";
import {
  alterFavouriteUIApplicationById,
  alterSubscibeUIApplicationById,
  delete_uiApplication,
  delete_template,
  selected_template,
  selected_page,
} from "../../../redux/actions/uiApplicationAction";
import ConfirmReuploadSourceCodeModal from "../modals/ConfirmReuploadSourceCodeModal";
import {
  alterFavouriteAITaskById,
  alterSubscribeAITaskById,
  deleteAITaskById,
  editAITaskAPITabbed,
  editAITaskVersionTabbed,
  setSelectedAITaskAPIId,
  setSelectedAITaskVersionId,
  tabbedAITaskActiveTab,
} from "../../../redux/actions/aiTaskActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import Loader from "../stuff/Loader";
import DownloadModal from "../../../UiApplication/components/molecules/projectSetup/DownloadModal";
import { StartContainer } from "../../../utils/containerMangement/containerUtils";
import ConfirmReuploadDeleteImage from "../modals/ConfirmReuploadDeleteImage";
import { DeleteDockerImage } from "../../../utils/containerMangement/imageUtils";
import { deleteAIModelFileByID } from "../../../redux/actions/dataModelActions";

var IS_EDIT_MODE = false;

const DefaultActionCell = ({ ...restProps }) => {
  const search = useLocation().search;
  const { tabbedView } = useSelector((state) => state.userInfoReducer);
  const appBundleId = new URLSearchParams(search).get("id");
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const histroy = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openContainerModal, setOpenContainerModal] = useState(false);
  const [openSimulateModal, setOpenSimulateModal] = useState(false);
  const [DocumentsDeleteModal, setDocumentsDeleteModal] = useState(false);
  const [openUploadSourceCodeModal, setOpenUploadSourceCodeModal] =
    useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const { userInfo } = useSelector((state) => state.userDetails);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [confirmReupload, setConfirmReupload] = useState(false);
  const [confirmReuploadDeleteImage, setConfirmReuploadDeleteImage] =
    useState(false);
  const [isLoader, setLoader] = useState(false);
  const [containerRunning, setContainerRunning] = useState(false);
  const [versionContainerId, setVersionContainerId] = useState();
  const [dependencyData, setDependencyData] = useState([]);

  const handleDependencyModalClickOpen = async () => {
    setDependencyModalOpen(true);
    if (restProps.column.for === "project") {
      const { _msg, _status, data } = await fetchProjectById(
        restProps?.row?._id
      );
      if (_status === 200) {
        const serviceGroupData =
          data?.servicegroupdetails !== null
            ? data?.servicegroupdetails?.map((d) => {
                return {
                  title: d.name,
                  expanded: false,
                };
              })
            : [];
        const tempData = [
          {
            title:
              serviceGroupData?.length === 0
                ? "No Service Groups found"
                : "Service Groups",
            expanded: true,
            children: [...serviceGroupData],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    } else if (restProps.column.for === "service") {
      const { _msg, _status, data } = await fetchServiceInfoByServiceId(
        restProps?.row?._id
      );
      if (_status === 200) {
        const applications =
          data?.Applications !== null
            ? data?.Applications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const serviceBundles =
          data?.ServiceBundles !== null
            ? data?.ServiceBundles?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const uiApplications =
          data?.UIApplications !== null
            ? data?.UIApplications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];

        const tempData = [
          {
            title:
              serviceBundles?.length === 0
                ? "No Service Bundles found"
                : "Service Bundles",
            expanded: true,
            children: [...serviceBundles],
          },
          {
            title:
              uiApplications?.length === 0
                ? "No UI Application found"
                : "UI Applications",
            expanded: true,
            children: [...uiApplications],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    } else if (restProps.column.for === "version") {
      const { _msg, _status, data } = await fetchEntitiesByVersionId(
        restProps?.row?._id
      );
      if (_status === 200) {
        const applications =
          data?.Applications !== null
            ? data?.Applications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const serviceBundles =
          data?.ServiceBundles !== null
            ? data?.ServiceBundles?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const uiApplications =
          data?.UIApplications !== null
            ? data?.UIApplications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];

        const tempData = [
          {
            title:
              serviceBundles?.length === 0
                ? "No Service Bundles found"
                : "Service Bundles",
            expanded: true,
            children: [...serviceBundles],
          },
          {
            title:
              uiApplications?.length === 0
                ? "No UIA pplication found"
                : "UI Applications",
            expanded: true,
            children: [...uiApplications],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    } else if (restProps.column.for === "aitask_version") {
      const { _msg, _status, data } = await fetchEntitiesByVersionId(
        restProps?.row?._id
      );
      if (_status === 200) {
        const applications =
          data?.Applications !== null
            ? data?.Applications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const serviceBundles =
          data?.ServiceBundles !== null
            ? data?.ServiceBundles?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];
        const uiApplications =
          data?.UIApplications !== null
            ? data?.UIApplications?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];

        const tempData = [
          {
            title:
              serviceBundles?.length === 0
                ? "No Service Bundles found"
                : "Service Bundles",
            expanded: true,
            children: [...serviceBundles],
          },
          {
            title:
              uiApplications?.length === 0
                ? "No UI Application found"
                : "UI Applications",
            expanded: true,
            children: [...uiApplications],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    } else if (restProps.column.for === "uiApplication") {
      const { _msg, _status, data } = await fetchUiApplicationById(
        restProps?.row?._id
      );
      if (_status === 200) {
        const applicationData =
          data?.pages !== null
            ? data?.pages?.map((d) => {
                return {
                  title: d?.name,
                  expanded: true,
                };
              })
            : [];

        const tempData = [
          {
            title: applicationData?.length === 0 ? "No Pages found" : "Pages",
            expanded: true,
            children: [...applicationData],
          },
        ];
        setDependencyData(tempData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    } else if (restProps.column.for === "AITask") {
      const { _msg, _status, data } = await fetchServiceInfoByServiceId(
        restProps?.row?._id
      );
      if (_status === 200) {
        if (_status === 200) {
          const applications =
            data?.Applications !== null
              ? data?.Applications?.map((d) => {
                  return {
                    title: d?.name,
                    expanded: true,
                  };
                })
              : [];
          const serviceBundles =
            data?.ServiceBundles !== null
              ? data?.ServiceBundles?.map((d) => {
                  return {
                    title: d?.name,
                    expanded: true,
                  };
                })
              : [];
          const uiApplications =
            data?.UIApplications !== null
              ? data?.UIApplications?.map((d) => {
                  return {
                    title: d?.name,
                    expanded: true,
                  };
                })
              : [];

          const tempData = [
            {
              title:
                serviceBundles?.length === 0
                  ? "No Service Bundles found"
                  : "Service Bundles",
              expanded: true,
              children: [...serviceBundles],
            },
            {
              title:
                uiApplications?.length === 0
                  ? "No UI Application found"
                  : "UI Applications",
              expanded: true,
              children: [...uiApplications],
            },
          ];
          setDependencyData(tempData);
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    }
  };
  const handleDependencyModalClose = () => {
    setDependencyModalOpen(false);
  };
  const handleDeleteModalClickOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const { checkCompPermission } = useCheckPermission();
  const handleOpenDeleteDocumentModal = () => {
    setDocumentsDeleteModal(true);
  };

  const handleCloseDeleteDocumentModal = () => {
    setDocumentsDeleteModal(false);
  };

  // Download Logs
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isDownload, setIsDownload] = useState(false);
  const [isAllLogsDownload, setIsAllLogsDownload] = useState(false);

  const handleTodayLogsDownload = async () => {
    setAnchorEl(null);
    setIsDownload(true);
    var timestamp = Math.round(new Date().setHours(0, 0, 0, 0) / 1000);
    let response = await fetch(
      `${BASE_URL}/api/container/dockercontainers/${restProps?.row?.docker?.Instances[0].containerid}/logs?timestamp=${timestamp}`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "logs";
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownload(false);
      enqueueSnackbar("Container Logs Downloaded Successfully", {
        variant: "success",
      });
    } else {
      setIsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const handleAllLogsDownload = async () => {
    setAnchorEl(null);
    setIsAllLogsDownload(true);
    let response = await fetch(
      `${BASE_URL}/api/container/dockercontainers/${restProps?.row?.docker.Instances[0].containerid}/logs`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "AllLogs";
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsAllLogsDownload(false);
      enqueueSnackbar("Container Logs Downloaded Successfully", {
        variant: "success",
      });
    } else {
      setIsAllLogsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const handleDeleteItem = async ({ row }) => {
    setLoader(true);
    setDeleteModalOpen(false);
    if (
      restProps.column.for === "project" &&
      checkCompPermission("Project", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteProject(restProps?.row.id);
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteProjectById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "servicegroup" &&
      checkCompPermission("ServiceGroup", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteServiceGroup(
        restProps.value,
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteServiceGroupById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (restProps.column.for === "newservicegroup") {
    } else if (
      restProps.column.for === "service" &&
      checkCompPermission("Microservice", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteMicroService(
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteServiceGUIById(restProps?.row.id));
        dispatch(deleteServiceById(restProps?.row.id));
        dispatch(deleteMicroserviceInServiceGroupById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "uisimul" &&
      checkCompPermission("Microservice", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteMicroService(
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteServiceGUIById(restProps?.row.id));
        dispatch(deleteMicroserviceInServiceGroupById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "AITask" &&
      checkCompPermission("AITask", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteMicroService(
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteAITaskById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "version" &&
      checkCompPermission("Version", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteVersion(
        restProps.value,
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteVersionById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "aitask_version" &&
      checkCompPermission("AITaskVersion", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteVersion(
        restProps.value,
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteVersionById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "api" &&
      checkCompPermission("API", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteAPI(
        restProps.value,
        restProps.row.version,
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteAPIById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "aitask_api" &&
      checkCompPermission("AITaskAPI", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteAPI(
        restProps.value,
        restProps.row.version,
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteAPIById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "document" &&
      checkCompPermission("Document", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteDocumentsByID(
        restProps?.row.id
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(delete_single_project_documents_by_id(restProps?.row.id));
        dispatch(delete_single_service_documents_by_id(restProps?.row.id));
        dispatch(
          delete_single_service_group_documents_by_id(restProps?.row.id)
        );
        histroy.push("/documents/project");
        histroy.goBack();
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "role" &&
      checkCompPermission("Role", ["Delete"])
    ) {
      const { _msg, _status, data } = await deleteRole(restProps?.row.id);

      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(deleteRoleById(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (
      restProps.column.for === "uiApplication" &&
      checkCompPermission("UIApplication", ["Delete"])
    ) {
      const res = await DeleteUiApplication(restProps?.row.id);
      if (res?.res_data?.status === 200) {
        setLoader(false);
        enqueueSnackbar("Ui Application Deleted Successfully", {
          variant: "success",
        });
        dispatch(delete_uiApplication(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (restProps.column.for === "template") {
      const res = await DeleteTemplate(restProps?.row.id);
      if (res?.res_data?.status < 210) {
        setLoader(false);
        enqueueSnackbar("Ui Application Deleted Successfully", {
          variant: "success",
        });
        dispatch(delete_template(restProps?.row.id));
      } else {
        setLoader(false);
        enqueueSnackbar(
          "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else if (restProps.column.for === "dataModelFile") {
      const { _msg, _status, data } = await deleteDataModelDocument(
        restProps?.row.resourceId,
        restProps?.row.filename
      );
      if (_status === 200) {
        setLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
        dispatch(
          deleteAIModelFileByID(
            restProps?.row.resourceId,
            restProps?.row.filename
          )
        );
      } else {
        setLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      setLoader(false);
      enqueueSnackbar(
        `You do not have permission to access Delete ${
          restProps.column.for.charAt(0).toUpperCase() +
          restProps.column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleNavigateToEdit = () => {
    if (
      restProps.column.for === "project" &&
      checkCompPermission("Project", ["Edit"])
    ) {
      histroy.push(`${restProps.column.route}/${restProps.row.id}`);
    } else if (
      restProps.column.for === "service" &&
      checkCompPermission("Microservice", ["Edit"])
    ) {
      histroy.push(`${restProps.column.route}/${restProps.row.id}`);
      dispatch(tabbedActiveTabAction(0));
      dispatch(setSelectedMicroService(restProps.row.id));
    } else if (
      restProps.column.for === "uisimul" &&
      checkCompPermission("Microservice", ["Edit"])
    ) {
      histroy.push(`${restProps.column.route}/${restProps.row.id}`);
    } else if (
      restProps.column.for === "AITask" &&
      checkCompPermission("Microservice", ["Edit"])
    ) {
      histroy.push(`${restProps.column.route}/${restProps.row.id}`);
    } else if (restProps.column.for === "aitask_version") {
      if (isTabbedApproach) {
        dispatch(editAITaskVersionTabbed(restProps.row.id));
        dispatch(tabbedAITaskActiveTab(3));
      } else {
        histroy.push(
          `${restProps.column.route}/${restProps.value}/${restProps.row.id}`
        );
      }
    } else if (restProps.column.for === "aitask_api") {
      if (isTabbedApproach) {
        dispatch(editAITaskAPITabbed(restProps.row.id));
        dispatch(tabbedAITaskActiveTab(4));
      } else {
        histroy.push(
          `${restProps.column.route}/${restProps.value}/${restProps.row.version}/${restProps.row.id}`
        );
      }
    } else if (
      restProps.column.for === "servicegroup" &&
      checkCompPermission("ServiceGroup", ["Edit"])
    ) {
      if (isTabbedApproach) {
        dispatch(
          updateServiceGroupMode({
            value: "edit",
            projectId: restProps.value,
            groupId: restProps.row.id,
          })
        );
      } else {
        histroy.push(
          `${restProps.column.route}/${restProps.value}/${restProps.row.id}`
        );
      }
    } else if (
      restProps.column.for === "version" &&
      checkCompPermission("Version", ["Edit"])
    ) {
      if (isTabbedApproach) {
        dispatch(tabbedActiveTabAction(4));
        dispatch(editVersionTabbed(restProps.row.id));
      } else {
        histroy.push(
          `${restProps.column.route}/${restProps.value}/${restProps.row.id}`
        );
      }
    } else if (
      restProps.column.for === "api" &&
      checkCompPermission("API", ["Edit"])
    ) {
      if (isTabbedApproach) {
        dispatch(tabbedActiveTabAction(5));
        dispatch(editAPITabbed(restProps.row._id));
      } else {
        histroy.push(
          `${restProps.column.route}/${restProps.value}/${restProps.row.version}/${restProps.row.id}`
        );
      }
    } else if (
      restProps.column.for === "role" &&
      checkCompPermission("Role", ["Edit"])
    ) {
      histroy.push(`${restProps.column.route}/${restProps.row.id}`);
    } else if (restProps.column.for === "helpdesk") {
      histroy.push({
        pathname: `/helpdesk/edit/${restProps.row.id}`,
        state: { isHelpDesk: true },
      });
    } else if (
      restProps.column.for === "uiApplication" &&
      checkCompPermission("UIApplication", ["Edit"])
    ) {
      histroy.push(`/ui-applications/edit/${restProps?.row?.id}`);
    } else if (
      restProps.column.for === "document" &&
      checkCompPermission("Document", ["Edit"])
    ) {
      histroy.push(`/documents/edit/${restProps?.row?.id}`);
    } else {
      enqueueSnackbar(
        `You do not have permission to access Edit ${
          restProps.column.for.charAt(0).toUpperCase() +
          restProps.column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const handleDeleteDocuments = async () => {
    if (restProps.column.for === "projectDocument") {
      const { _msg, _status, data } =
        await deleteAllDocumentsByProjectResourceID(restProps.row.id);

      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDocumentsDeleteModal(false);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setDocumentsDeleteModal(false);
      }
    } else if (restProps.column.for === "servicegroupDocument") {
      const { _msg, _status, data } =
        await deleteDocumentsByServiceGroupResourceID(restProps.row.id);

      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDocumentsDeleteModal(false);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setDocumentsDeleteModal(false);
      }
    } else if (restProps.column.for === "serviceDocument") {
      const { _msg, _status, data } = await deleteDocumentsByServiceResourceID(
        restProps.row.id
      );

      if (_status === 200) {
        enqueueSnackbar(_msg, { variant: "success" });
        setDocumentsDeleteModal(false);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setDocumentsDeleteModal(false);
      }
    }
  };

  const handleCreateDockerFile = async ({ row }) => {
    const { _msg, _status, data } = await createDockerFile(row.action, row.id);
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(getVersionList(row.action, 0));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleAddFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    const favoriteData = {
      resourcetype: resourceType,
      resourceinstance: resourceinstance,
    };
    const { _msg, _status, data } = await addFavouriteInResource(
      userData.userid,
      favoriteData
    );

    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "project") {
        dispatch(alterFavouriteProjectById(resourceinstance));
      } else if (restProps.column.for === "service") {
        dispatch(alterFavouriteServiceById(resourceinstance));
        dispatch(alterFavouriteServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uisimul") {
        dispatch(alterFavouriteServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uiApplication") {
        dispatch(alterFavouriteUIApplicationById(resourceinstance));
      } else if (restProps.column.for === "AITask") {
        dispatch(alterFavouriteAITaskById(resourceinstance));
      }
      dispatch(addSingleFavourite(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };
  const handleDeleteFavouite = async (resource, resourceinstance) => {
    let resourceType = resource;
    if (resourceType === "AITask") {
      resourceType = "aitask";
    }

    const { _msg, _status, data } = await deleteFavouriteInResource(
      userData.userid,
      resourceType,
      resourceinstance
    );

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "project") {
        dispatch(alterFavouriteProjectById(resourceinstance));
      } else if (restProps.column.for === "service") {
        dispatch(alterFavouriteServiceById(resourceinstance));
        dispatch(alterFavouriteServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uisimul") {
        dispatch(alterFavouriteServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uiApplication") {
        dispatch(alterFavouriteUIApplicationById(resourceinstance));
      } else if (restProps.column.for === "AITask") {
        dispatch(alterFavouriteAITaskById(resourceinstance));
      }
      dispatch(removeSingleFavourite(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleSubscribeResource = async (resource, resourceinstance) => {
    let resourceType = resource;

    if (restProps.column.for === "uisimul") {
      resourceType = "service";
    } else if (restProps.column.for === "AITask") {
      resourceType = "service";
    }
    const { _msg, _status, data } = await addSubscriptionInResource(
      userInfo.email,
      userData.userid,
      resourceType,
      resourceinstance
    );

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "project") {
        dispatch(alterSubscibeProjectById(resourceinstance));
      } else if (restProps.column.for === "service") {
        dispatch(alterSubscibeServiceById(resourceinstance));
        dispatch(alterSubscibeServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uisimul") {
        dispatch(alterSubscibeServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uiApplication") {
        dispatch(alterSubscibeUIApplicationById(resourceinstance));
      } else if (restProps.column.for === "AITask") {
        dispatch(alterSubscribeAITaskById(resourceinstance));
      }
      dispatch(addSingleUserSubscription(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleRemoveSubscribeResource = async (resource, resourceinstance) => {
    let resourceType = resource;
    // if (resourceType === 'AITask') {
    //   resourceType = 'service';
    // }
    if (restProps.column.for === "uisimul") {
      resourceType = "service";
    } else if (restProps.column.for === "AITask") {
      resourceType = "service";
    }

    const { _msg, _status, data } = await deleteUserSubscriptionInResource(
      userData.userid,
      resourceType,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      if (restProps.column.for === "project") {
        dispatch(alterSubscibeProjectById(resourceinstance));
      } else if (restProps.column.for === "service") {
        dispatch(alterSubscibeServiceById(resourceinstance));
        dispatch(alterSubscibeServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uisimul") {
        dispatch(alterSubscibeServiceGUIById(resourceinstance));
      } else if (restProps.column.for === "uiApplication") {
        dispatch(alterSubscibeUIApplicationById(resourceinstance));
      } else if (restProps.column.for === "AITask") {
        dispatch(alterSubscribeAITaskById(resourceinstance));
      }
      dispatch(removeSingleUserSubscription(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDownloadFile = async (file) => {
    let response = await fetch(
      `${DOCUMENT}/api/v1.0.0/documents/${file.id}/_download`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();

      //
      var url = window.URL.createObjectURL(blob);

      var a = document.createElement("a");

      a.href = url;
      a.download = file.filename;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const handleSubmitContainer = async (containerid) => {
    const { _msg, _status, data } = await StartContainer(containerid);
    if (_status === 200) enqueueSnackbar(_msg, { variant: "success" });
    else
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
  };

  return (
    <div className={styles.actionIcons}>
      {deleteModalOpen && (
        <DeleteModal
          name={restProps.row.name}
          row={restProps.row}
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDeleteItem}
        />
      )}

      {dependencyModalOpen && (
        <DependencyModal
          modalTitle={
            dependencyData.length !== 0 ? "Dependencies" : "No Dependencies"
          }
          row={restProps.row}
          open={dependencyModalOpen}
          handleClose={handleDependencyModalClose}
          treeDataArr={dependencyData}
        />
      )}

      {isLoader && <Loader />}

      {(restProps.column.for === "project" ||
        restProps.column.for === "AITask" ||
        restProps.column.for === "service" ||
        restProps.column.for === "uiApplication" ||
        restProps.column.for === "uisimul") && (
        <Tooltip
          title={restProps.row?.isFavourite ? "Unfavourite" : "Favourite"}
          arrow
        >
          <IconButton
            color="inherit"
            aria-label="favorite"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (restProps.row?.isFavourite) {
                if (restProps.column.for === "uisimul") {
                  handleDeleteFavouite("service", restProps.row.id);
                } else if (restProps.column.for === "AITask") {
                  handleDeleteFavouite("aitask", restProps.row.id);
                } else {
                  handleDeleteFavouite(restProps.column.for, restProps.row.id);
                }
              } else {
                if (restProps.column.for === "uisimul") {
                  handleAddFavouite("service", restProps.row.id);
                } else if (restProps.column.for === "AITask") {
                  handleAddFavouite("aitask", restProps.row.id);
                } else {
                  handleAddFavouite(restProps.column.for, restProps.row.id);
                }
              }
            }}
          >
            {restProps.row?.isFavourite ? (
              <img src="/images/heartFilled.svg" alt="card" />
            ) : (
              <img src="/images/heartLine.svg" alt="card" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "project" ||
        restProps.column.for === "AITask" ||
        restProps.column.for === "uiApplication" ||
        restProps.column.for === "service" ||
        restProps.column.for === "uisimul") && (
        <Tooltip
          title={restProps.row?.isSubscribe ? "Unsubscribe" : "Subscribe"}
          arrow
        >
          <IconButton
            color="inherit"
            aria-label="subscribe"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (restProps.column.for === "AITask") {
                if (!restProps.row?.isSubscribe) {
                  handleSubscribeResource("aitask", restProps.row.id);
                } else if (restProps.row?.isSubscribe)
                  handleRemoveSubscribeResource(
                    restProps.column.for,
                    restProps.row.id
                  );
              } else if (restProps.column.for === "project") {
                if (!restProps.row?.isSubscribe) {
                  handleSubscribeResource("project", restProps.row.id);
                } else if (restProps.row?.isSubscribe)
                  handleRemoveSubscribeResource(
                    restProps.column.for,
                    restProps.row.id
                  );
              } else if (restProps.column.for === "uiApplication") {
                if (!restProps.row?.isSubscribe) {
                  handleSubscribeResource("uiApplication", restProps.row.id);
                } else if (restProps.row?.isSubscribe)
                  handleRemoveSubscribeResource(
                    restProps.column.for,
                    restProps.row.id
                  );
              } else if (restProps.column.for === "service") {
                if (!restProps.row?.isSubscribe) {
                  handleSubscribeResource("service", restProps.row.id);
                } else if (restProps.row?.isSubscribe)
                  handleRemoveSubscribeResource(
                    restProps.column.for,
                    restProps.row.id
                  );
              } else
                handleSubscribeResource(restProps.column.for, restProps.row.id);
            }}
          >
            {restProps.row?.isSubscribe ? (
              <img src="/images/Bell_Active.svg" alt="card" />
            ) : (
              <img src="/images/Bell_Inactive.svg" alt="card" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {restProps.column.for === "api" &&
        ((restProps.row.Instances &&
          restProps.row.versionStatus == "build success") ||
          restProps.row?.serviceType === "External") && (
          <Tooltip title="Simute API" arrow>
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              className={styles.actionIcon}
              onClick={() => {
                if (!checkCompPermission("API", ["Simulate"])) {
                  enqueueSnackbar(
                    `You do not have permission to access Simulate in Api. Please contact your Admin.`,
                    { variant: "error" }
                  );
                } else {
                  setOpenSimulateModal(true);
                }
              }}
            >
              <PlayArrowOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

      {restProps.column.for === "aitask_api" &&
        ((restProps.row.Instances &&
          restProps.row.versionStatus == "build success") ||
          restProps.row?.serviceType === "External") && (
          <Tooltip title="Simute API" arrow>
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              className={styles.actionIcon}
              onClick={() => {
                setOpenSimulateModal(true);
              }}
            >
              <PlayArrowOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      {(restProps.column.for === "version" ||
        restProps.column.for === "aitask_version") &&
        restProps.row?.serviceType === "Internal" && (
          <>
            {restProps.row.isUpload ? (
              <Tooltip title="Reupload Source Code" arrow>
                <IconButton
                  color="inherit"
                  aria-label="upload"
                  edge="start"
                  className={styles.actionIcon}
                  onClick={() => {
                    if (!checkCompPermission("Version", ["Create Repo"])) {
                      enqueueSnackbar(
                        `You do not have permission to access CreateRepo in Version. Please contact your Admin.`,
                        { variant: "error" }
                      );
                    } else {
                      if (restProps.row.isDraft) {
                        setConfirmReupload(true);
                      } else {
                        setOpenUploadSourceCodeModal(true);
                      }
                    }
                  }}
                >
                  <img src="/images/reupload.svg" alt="reupload" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Upload Source Code" arrow>
                <IconButton
                  color="inherit"
                  aria-label="upload"
                  edge="start"
                  className={styles.actionIcon}
                  onClick={() => {
                    if (!checkCompPermission("Version", ["Create Repo"])) {
                      enqueueSnackbar(
                        `You do not have permission to access CreateRepo in Version. Please contact your Admin.`,
                        { variant: "error" }
                      );
                    } else {
                      setOpenUploadSourceCodeModal(true);
                    }
                  }}
                >
                  <img src="/images/bulkuploaddark.svg" alt="debug" />
                </IconButton>
              </Tooltip>
            )}

            {(!restProps.row.ImageID ||
              restProps.row.imageStatus !== "build success") && (
              <Tooltip title="Build Microservice" arrow>
                <IconButton
                  color="inherit"
                  aria-label="edit"
                  edge="start"
                  className={styles.actionIcon}
                  onClick={() => {
                    if (!restProps.row.isUpload) {
                      enqueueSnackbar(
                        "Please upload source code to Build Microservice",
                        { variant: "error" }
                      );
                    } else {
                      if (!checkCompPermission("Version", ["Build Image"])) {
                        enqueueSnackbar(
                          `You do not have permission to access BuildImage in Microservice. Please contact your Admin.`,
                          { variant: "error" }
                        );
                      } else {
                        handleCreateDockerFile(restProps);
                      }
                    }
                  }}
                >
                  <img src="/images/debug.svg" alt="debug" />
                </IconButton>
              </Tooltip>
            )}

            {restProps.row.ImageID &&
              restProps.row.imageStatus === "build success" && (
                <Tooltip title="RunContainer" arrow>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    className={styles.actionIcon}
                    onClick={() => {
                      if (!checkCompPermission("Version", ["Run Container"])) {
                        enqueueSnackbar(
                          `You do not have permission to access RunContainer in Version. Please contact your Admin.`,
                          { variant: "error" }
                        );
                      } else {
                        if (
                          restProps.row.Instances &&
                          restProps.row.Instances.length
                        ) {
                          handleSubmitContainer(
                            restProps.row?.Instances?.[0]?.containerid
                          );
                        } else {
                          setOpenContainerModal(true);
                        }
                      }
                    }}
                  >
                    <PlayArrowOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
          </>
        )}

      <ConfirmReuploadSourceCodeModal
        open={confirmReupload}
        handleSubmit={() => {
          setOpenUploadSourceCodeModal(true);
          setConfirmReupload(false);
        }}
        handleClose={() => setConfirmReupload(false)}
      />

      {openUploadSourceCodeModal && (
        <UploadSourceCodeInVersion
          openUploadSourceCodeModal={openUploadSourceCodeModal}
          setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
          onCloseClick={() => setOpenUploadSourceCodeModal(false)}
          handleSubmit={(data) => console.log("submit success : ", data)}
          versionID={restProps.row.id}
          serviceId={restProps.row.action}
          isUpload={restProps.row.isUpload}
          repoId={restProps.row.repoId}
          versionDetails={restProps?.row}
        />
      )}

      {openSimulateModal && (
        <SimulateModalForMicroservice
          isOpen={openSimulateModal}
          onCloseClick={() => setOpenSimulateModal(false)}
          onSubmitClick={() => console.log("submit")}
          dependency={{
            ...restProps.row,
            mid: restProps.row.action,
            vid: restProps.row.version,
            apiId: restProps.row.id,
            servicetype: restProps.row.type,
            Instances: restProps.row.Instances,
            selectedInstance: restProps.row.selectedInstance,
            environmentID: restProps.row.environmentID,
            topic: restProps.row?.topic,
          }}
        />
      )}

      {openContainerModal && (
        <ContainerModal
          containerRunning={containerRunning}
          openModal={openContainerModal}
          versionContainerId={versionContainerId}
          setVersionContainerId={setVersionContainerId}
          setOpenModal={() => setOpenContainerModal(false)}
          containerName={restProps.row.imagename}
          mid={restProps.row.action}
          vid={restProps.row.id}
          setContainerRunning={setContainerRunning}
        />
      )}

      {(restProps.column.for === "project" ||
        restProps.column.for === "service" ||
        restProps.column.for === "uisimul" ||
        restProps.column.for === "AITask" ||
        restProps.column.for === "role" ||
        restProps.column.for === "uiApplication" ||
        restProps.column.for === "helpdesk" ||
        restProps.column.for === "document") && (
        <Tooltip title="Edit" arrow>
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={handleNavigateToEdit}
          >
            <img src="/images/editLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "version" ||
        restProps.column.for === "servicegroup" ||
        restProps.column.for === "aitask_version" ||
        restProps.column.for === "aitask_api" ||
        restProps.column.for === "api") &&
        (IS_EDIT_MODE ? (
          <Tooltip title="Edit" arrow>
            <IconButton
              color="inherit"
              aria-label="edit"
              edge="start"
              className={styles.actionIcon}
              onClick={handleNavigateToEdit}
            >
              <img src="/images/editLine.svg" alt="card" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            disabled={true}
          >
            <img src="/images/editLine-disabled.svg" alt="card" />
          </IconButton>
        ))}

      {(restProps.column.for === "projectDocument" ||
        restProps.column.for === "servicegroupDocument" ||
        restProps.column.for === "serviceDocument") && (
        <Tooltip title="Import" arrow>
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (
                restProps.column.for === "projectDocument" &&
                checkCompPermission("Document", ["Create"])
              ) {
                histroy.push({
                  pathname: `${restProps.column.route}/${restProps.row.id}`,
                  state: { forUpload: true },
                });
              } else if (
                restProps.column.for === "servicegroupDocument" &&
                checkCompPermission("Document", ["Create"])
              ) {
                histroy.push({
                  pathname: `${restProps.column.route}/${restProps.row.action}/${restProps.row.id}`,
                  state: { forUpload: true },
                });
              } else if (
                restProps.column.for === "serviceDocument" &&
                checkCompPermission("Document", ["Create"])
              ) {
                histroy.push({
                  pathname: `${restProps.column.route}/${restProps.row.id}`,
                  state: { forUpload: true },
                });
              } else {
                enqueueSnackbar(
                  `You do not have permission to access Create ${
                    restProps.column.for.charAt(0).toUpperCase() +
                    restProps.column.for.slice(1)
                  }. Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            <img src="/images/uploadGray.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}

      {restProps.column.for === "document" && (
        <Tooltip title="Download" arrow>
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={() => {
              if (
                restProps.column.for === "document" &&
                checkCompPermission("Document", ["Download"])
              ) {
                handleDownloadFile(restProps.row);
              } else {
                enqueueSnackbar(
                  `You do not have permission to access Download ${
                    restProps.column.for.charAt(0).toUpperCase() +
                    restProps.column.for.slice(1)
                  }. Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            <img src="/images/Download_icon.svg" alt="download" />
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "projectDocument" ||
        restProps.column.for === "servicegroupDocument" ||
        restProps.column.for === "serviceDocument") && (
        <>
          <Tooltip title="Delete" arrow>
            <div>
              <IconButton
                className={styles.actionIcon}
                onClick={() => {
                  if (
                    restProps.column.for === "projectDocument" &&
                    checkCompPermission("Document", ["Delete"])
                  ) {
                    handleOpenDeleteDocumentModal();
                  } else if (
                    restProps.column.for === "servicegroupDocument" &&
                    checkCompPermission("Document", ["Delete"])
                  ) {
                    handleOpenDeleteDocumentModal();
                  } else if (
                    restProps.column.for === "serviceDocument" &&
                    checkCompPermission("Document", ["Delete"])
                  ) {
                    handleOpenDeleteDocumentModal();
                  } else {
                    enqueueSnackbar(
                      `You do not have permission to access Delete ${
                        restProps.column.for.charAt(0).toUpperCase() +
                        restProps.column.for.slice(1)
                      }. Please contact your Admin.`,
                      { variant: "error" }
                    );
                  }
                }}
              >
                <img src="/images/deleteLine.svg" alt="card" />
              </IconButton>
            </div>
          </Tooltip>
          {DocumentsDeleteModal && (
            <DocumentDeleteModal
              name={restProps.row.name}
              row={restProps.row}
              module="document"
              open={DocumentsDeleteModal}
              handleClose={handleCloseDeleteDocumentModal}
              handleDelete={handleDeleteDocuments}
            />
          )}
        </>
      )}

      {restProps.column.for === "dataModelFile" && (
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={() => handleDeleteModalClickOpen(true)}
          >
            <img src="/images/deleteLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "project" ||
        restProps.column.for === "service" ||
        restProps.column.for === "uisimul" ||
        restProps.column.for === "AITask" ||
        // restProps.column.for === "aitask_version" ||
        // restProps.column.for === "aitask_api" ||
        restProps.column.for === "uiApplication" ||
        restProps.column.for === "template" ||
        restProps.column.for === "role" ||
        restProps.column.for === "document") && (
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={handleDeleteModalClickOpen}
          >
            <img src="/images/deleteLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "version" ||
        restProps.column.for === "servicegroup" ||
        restProps.column.for === "aitask_version" ||
        restProps.column.for === "aitask_api" ||
        restProps.column.for === "api") &&
        (IS_EDIT_MODE ? (
          <Tooltip title="Delete" arrow>
            <IconButton
              color="inherit"
              aria-label="delete"
              edge="start"
              className={styles.actionIcon}
              onClick={handleDeleteModalClickOpen}
            >
              <img src="/images/deleteLine.svg" alt="card" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            disabled={true}
          >
            <img src="/images/deleteLine-disabled.svg" alt="card" />
          </IconButton>
        ))}

      {(restProps.column.for === "project" ||
        restProps.column.for === "service" ||
        // restProps.column.for === "uisimul" ||
        restProps.column.for === "AITask" ||
        // restProps.column.for === "servicegroup" ||
        restProps.column.for === "version" ||
        restProps.column.for === "aitask_version" ||
        // restProps.column.for === "api" ||
        // restProps.column.for === "aitask_api" ||
        restProps.column.for === "uiApplication") && (
        // restProps.column.for === "template" ||
        // restProps.column.for === "role" ||
        // restProps.column.for === "document"

        <Tooltip title="Dependencies" arrow>
          <IconButton
            color="inherit"
            aria-label="info"
            edge="start"
            className={styles.actionIcon}
            onClick={handleDependencyModalClickOpen}
          >
            <img src="/images/info_icon.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}

      {(restProps.column.for === "aitask_version" ||
        restProps.column.for === "version") && (
        <>
          {restProps.row.isUpload ? (
            <Tooltip title="View" arrow>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                className={styles.actionIcon}
                onClick={() =>
                  histroy.push(
                    `/editor-screen/${restProps.row.microserviceID}/${restProps.row.id}`
                  )
                }
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="View" arrow>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                className={styles.actionIcon}
                disabled={true}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {(restProps.column.for === "version" ||
        restProps.column.for === "aitask_version") && (
        <>
          {(restProps.row.isUpload &&
            restProps.row?.status === "build success" &&
            restProps?.row?.docker?.Instances &&
            restProps?.row?.docker?.Instances[0]) ||
          containerRunning ? (
            <Tooltip title="Download Container logs" arrow>
              <IconButton
                color="inherit"
                aria-label="download"
                edge="start"
                className={styles.actionIcon}
                onClick={handleClick}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Download Container logs" arrow>
              <IconButton
                color="inherit"
                aria-label="download"
                edge="start"
                className={styles.actionIcon}
                onClick={handleClick}
                disabled={true}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <List dense={true}>
            <ListItem button onClick={handleTodayLogsDownload}>
              <ListItemText primary="Today" />
            </ListItem>
            <ListItem button onClick={handleAllLogsDownload}>
              <ListItemText primary="All" />
            </ListItem>
          </List>
        </div>
        {/* <div  style={{display:"flex", flexDirection:"column", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px", paddingRight: "5px"}}>
      <div style={{paddingBottom: "5px"}}>
      <Chip label="Today" variant="outlined" size="small" onClick={handlework} /> 
      </div>
      <Chip label="All" variant="outlined" size="small" onClick={handlework} />
      </div> */}

        {/* <AppButton
                      color="primary"
                      buttonName="Download Todays Logs"
                      variant="contained"
                      startIcon={<img src="/images/Download_icon.svg" alt="" />}
                      style={{ margin: "10px" }}
                      className="btnsmall"
                      // onClick={handleAllLogsDownload}
                    />
                    <AppButton
                      color="primary"
                      buttonName="Download All Logs"
                      variant="contained"
                      startIcon={<img src="/images/Download_icon.svg" alt="" />}
                      style={{ margin: "10px" }}
                      className="btnsmall"
                      // onClick={handleAllLogsDownload}
                    /> */}
      </Popover>
    </div>
  );
};

const DocumentStatusCell = ({ ...restProps }) => {
  // const status = restProps?.value;
  const status =
    restProps.row.statuses &&
    restProps.row.statuses.find((s) => s.status === restProps.row.status);
  return (
    <div style={{ display: "flex", justifyContent: "left" }}>
      {status && (
        <div
          style={{
            backgroundColor: status?.color,
            height: 15,
            width: 15,
            borderRadius: "50%",
            position: "relative",
            right: 10,
          }}
        ></div>
      )}
      {status && (
        <span style={{ color: status?.color }}>
          {status?.status?.charAt(0).toUpperCase() + status?.status?.slice(1)}
        </span>
      )}
    </div>
  );
};

const StatusCell = ({ ...restProps }) => {
  const status =
    restProps.row.statuses &&
    restProps.row.statuses.find((s) => s.status === restProps.row.status);

  return (
    <div style={{ display: "flex", justifyContent: "left" }}>
      {status && (
        <div
          style={{
            backgroundColor: status?.color,
            height: 15,
            width: 15,
            borderRadius: "50%",
            position: "relative",
            right: 10,
          }}
        ></div>
      )}
      {status && (
        <span style={{ color: status?.color }}>
          {status?.status?.charAt(0).toUpperCase() + status?.status?.slice(1)}
        </span>
      )}
    </div>
  );
};

const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const FunctionCell = ({ ...restProps }) => {
  return <>{restProps.row?.function?.func_name}</>;
};

const UiApplicationCanvas = ({ ...restProps }) => {
  const history = useHistory();
  const styles = devExpressStyles();
  const { checkCompPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  //Pages Model
  const [open, setOpen] = useState(false);
  const [pageData, setPageData] = useState();
  const [uiAppId, setUiAppID] = useState("");
  const [uiAppName, setUiAppName] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleOpenPagesModel = (item) => {
    setOpen(true);
    setPageData(item?.pages ? item?.pages : []);
    setUiAppID(item?.id);
    setUiAppName(item?.name);
  };
  const handleClosePagesModel = () => {
    setOpen(false);
  };

  const handleDownload = async () => {
    const resp = await downLoadDynamicBuild(
      restProps?.row?.id,
      restProps?.row?.containerName
    );
    if (resp?.res_data?.status === 200) {
      window.location.replace(resp?.res_data?.data?.downloadLink);
    } else {
      enqueueSnackbar("Unable to download the file", { variant: "error" });
    }
  };

  const userData = useSelector((state) => state.authReducer);

  const handleDownloadFile = async (restProps) => {
    let response = await fetch(
      `${BASE_URL}/api/v1.0.0/ui/applications/${restProps?.row?.id}/download`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${restProps?.row?.name}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const [uiApplicationDownloadModal, setUiApplicationDownloadModal] =
    useState(false);

  const handleBuild = async () => {
    setIsLoader(true);
    const resp = await buildUIApplication(restProps?.row?.id);
    if (resp?.res_data?.status === 200) {
      enqueueSnackbar(resp?.res_data?.data?._msg, { variant: "success" });
      // window.location.replace(resp?.res_data?.data?.downloadLink);
      setIsLoader(false);
    } else {
      enqueueSnackbar(
        resp?.err?.response?.data?._msg
          ? resp?.err?.response?.data?._msg
          : "Unable to build the application",
        { variant: "error" }
      );
      setIsLoader(false);
    }
  };

  const handleCanvas = () => {
    let landingPage = restProps?.row?.landingPage?.filter(
      (page) => page?.islandingpage === true
    );
    if (!checkCompPermission("UIApplication", ["Edit"])) {
      enqueueSnackbar(
        `You do not have permission to access UI Application in Edit. Please contact your Admin.`,
        { variant: "error" }
      );
    } else {
      landingPage?.length > 0 &&
        history.push(
          `/ui-applications/${restProps?.row?.id}/${landingPage[0]?.url}`
        );
    }
  };

  return (
    <div className={styles.actionIcons}>
      {isLoader && <Loader />}
      <Tooltip title="Canvas" arrow>
        <IconButton
          color="inherit"
          aria-label="edit"
          edge="start"
          className={styles.actionIcon}
          onClick={handleCanvas}
        >
          <img src="/img/gotoCanvas-ui-app-list.svg" alt="gotoCanvas" />
        </IconButton>
      </Tooltip>
      <Tooltip title="pages" arrow>
        <IconButton
          color="inherit"
          aria-label="edit"
          edge="start"
          className={styles.actionIcon}
          onClick={() => {
            if (!checkCompPermission("UIApplication", ["Read"])) {
              enqueueSnackbar(
                `You do not have permission to access UI Application Read. Please contact your Admin.`,
                { variant: "error" }
              );
            } else {
              handleOpenPagesModel(restProps?.row);
            }
          }}
        >
          <img src="/img/pages.svg" alt="pages" />
          <img src="/img/caret-down.svg" alt="down" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Build" arrow>
        <IconButton
          color="inherit"
          edge="start"
          className={styles.actionIcon}
          onClick={handleBuild}
        >
          <img src="/images/debug.svg" alt="debug" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Download" arrow>
        <IconButton
          color="inherit"
          edge="start"
          className={styles.actionIcon}
          onClick={() => setUiApplicationDownloadModal(true)}
        >
          <img src="/images/Download_icon.svg" alt="" />
        </IconButton>
      </Tooltip>

      {uiApplicationDownloadModal && (
        <DownloadModal
          open={uiApplicationDownloadModal}
          handleOpen={() => setUiApplicationDownloadModal(true)}
          handleClose={() => setUiApplicationDownloadModal(false)}
          applicationId={restProps?.row?.id}
          applicationName={restProps?.row?.name}
          applicationIcon={restProps?.row?.icon}
          applicationIconName={restProps?.row?.iconName}
          userData={userData}
        />
      )}

      {open && (
        <PagesModel
          uiAppId={uiAppId}
          uiAppName={uiAppName}
          pageData={pageData}
          open={open}
          handleOpen={handleOpenPagesModel}
          handleClose={handleClosePagesModel}
        />
      )}
    </div>
  );
};

const Cell = (props) => {
  const search = useLocation().search;
  const { tabbedView } = useSelector((state) => state.userInfoReducer);
  const appBundleId = new URLSearchParams(search).get("id");

  const histroy = useHistory();
  const { column } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();
  const dispatch = useDispatch();
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );
  const handleNavigateToDetails = () => {
    if (column.for === "project" && checkCompPermission("Project", ["Read"])) {
      histroy.push(`/projects/details/${props.row.id}`);
    } else if (
      column.for === "service" &&
      checkCompPermission("Microservice", ["Read"])
    ) {
      histroy.push(`/services/details/${props.row.id}`);
      dispatch(tabbedActiveTabAction(0));
    } else if (column.for === "uisimul") {
      histroy.push(`/services/details/${props.row.id}`);
    } else if (
      column.for === "AITask" &&
      checkCompPermission("Microservice", ["Read"])
    ) {
      histroy.push(`/aitask/details/${props.row.id}`);
    } else if (column.for === "aitask_version") {
      if (isTabbedApproach) {
        dispatch(setSelectedAITaskVersionId(props.row.id));
        dispatch(tabbedAITaskActiveTab(3));
      } else {
        histroy.push(
          `/aitask-versions/details/${props.row.action}/${props.row.id}`
        );
      }
    } else if (column.for === "aitask_api") {
      if (isTabbedApproach) {
        dispatch(setSelectedAITaskAPIId(props.row.id));
        dispatch(tabbedAITaskActiveTab(4));
      } else {
        histroy.push(
          `/ai-task-api/details/${props.row.action}/${props.row.version}/${props.row.id}`
        );
      }
    } else if (
      column.for === "servicegroup" &&
      checkCompPermission("ServiceGroup", ["Read"])
    ) {
      if (isTabbedApproach) {
        dispatch(
          updateServiceGroupMode({
            value: "details",
            projectId: props.row.projectid,
            groupId: props.row.id,
          })
        );
      } else {
        histroy.push(
          `/servicegroups/details/${props.row.action}/${props.row.id}`
        );
      }
    } else if (
      column.for === "version" &&
      checkCompPermission("Version", ["Read"])
    ) {
      if (isTabbedApproach) {
        dispatch(tabbedActiveTabAction(4));
        dispatch(setSelectedVersionId(props.row.id));
      } else {
        histroy.push(`/versions/details/${props.row.action}/${props.row.id}`);
      }
    } else if (column.for === "api" && checkCompPermission("API", ["Read"])) {
      if (isTabbedApproach) {
        dispatch(tabbedActiveTabAction(5));
        dispatch(setSelectedAPIId(props.row.id));
      } else {
        histroy.push(
          `/apis/details/${props.row.action}/${props.row.version}/${props.row.id}`
        );
        // dispatch(setSelectedVersionId(props.row.id))
      }
    } else if (column.for === "role" && checkCompPermission("Role", ["Read"])) {
      histroy.push(`/role-management/details/${props.row.id}`);
    } else if (column.for === "helpdesk") {
      histroy.push({
        pathname: `/helpdesk/edit/${props.row.id}`,
        state: { isHelpDesk: true },
      });
    } else if (column.for === "template") {
      dispatch(selected_template(props?.row?.id));
    } else if (column.for === "page") {
      dispatch(selected_page(props?.row?.uiApplicationId, props?.row?.id));
    } else if (
      column.for === "uiApplication" &&
      checkCompPermission("UIApplication", ["Read"])
    ) {
      histroy.push(`/ui-applications/details/${props.row.id}`);
    } else if (
      column.for === "document" &&
      checkCompPermission("Document", ["Read"])
    ) {
      histroy.push(`/documents/details/${props.row.id}`);
    } else {
      enqueueSnackbar(
        `You do not have permission to access Read ${
          column.for.charAt(0).toUpperCase() + column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  //document details screen row permission

  const handleNavigateToDocumentDetails = () => {
    if (
      column.for === "projectDocument" &&
      checkCompPermission("Document", ["List All", "List Assigned"])
    ) {
      histroy.push({
        pathname: `/documents/project/${props.row.id}`,
        state: { forUpload: false },
      });
    } else if (
      column.for === "servicegroupDocument" &&
      checkCompPermission("Document", ["List All", "List Assigned"])
    ) {
      histroy.push({
        pathname: `/documents/servicegroup/${props.row.action}/${props.row.id}`,
        state: { forUpload: false },
      });
    } else if (
      column.for === "serviceDocument" &&
      checkCompPermission("Document", ["List All", "List Assigned"])
    ) {
      histroy.push({
        pathname: `/documents/service/${props.row.id}`,
        state: { forUpload: false },
      });
    } else {
      enqueueSnackbar(
        `You do not have permission to access List All, List Assigned ${
          column.for.charAt(0).toUpperCase() + column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  const IncludeCell = ({ ...restProps }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {`${restProps.row.apirequired}`}
      </div>
    );

    //  ? (
    //   <div style={{ display: "flex", justifyContent: "center" }}>true</div>
    // ) : (
    //   <div>false</div>
    // );
  };
  const IconCell = ({ ...restProps }) => {
    return (
      <VirtualTable.Cell {...restProps}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {restProps.row.icon && (
            <div>
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={restProps.row.icon}
                alt="icon"
              />
            </div>
          )}
        </div>
      </VirtualTable.Cell>
    );
  };

  // row action
  if (column.for === "project") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "servicegroup") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "newservicegroup") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} />;
    }
  } else if (column.for === "service") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "uisimul") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  }

  //gui simul

  //AiTaskTableProps
  else if (column.for === "AITask") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "version") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "aitask_version") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "apirequired") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <IncludeCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "aitask_api") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "function") {
      return (
        <>
          {" "}
          <VirtualTable.Cell {...props}>
            <FunctionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "api") {
    if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "document") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DocumentStatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "dataModelFile") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "uploadedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} />;
    }
  } else if (column.for === "projectDocument") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "desc") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={handleNavigateToDocumentDetails}
        />
      );
    }
  } else if (column.for === "servicegroupDocument") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "createdby") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "desc") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={handleNavigateToDocumentDetails}
        />
      );
    }
  } else if (column.for === "serviceDocument") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "createdby") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDocumentDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <VirtualTable.Cell
          {...props}
          onClick={handleNavigateToDocumentDetails}
        />
      );
    }
  } else if (column.for === "helpdesk") {
    if (column.name === "action") {
      return (
        <VirtualTable.Cell {...props}>
          <DefaultActionCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "status") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <StatusCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
    }
  } else if (column.for === "role") {
    if (column.name === "action") {
      return (
        <>
          <VirtualTable.Cell {...props}>
            <DefaultActionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  } else if (column.for === "role") {
    if (column.name === "action") {
      return (
        <>
          <VirtualTable.Cell {...props}>
            <DefaultActionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  } else if (column.for === "template") {
    if (column.name === "action") {
      return (
        <>
          <VirtualTable.Cell {...props}>
            <DefaultActionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  } else if (column.for === "page") {
    if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  } else if (column.for === "uiApplication") {
    if (column.name === "icon") {
      return <IconCell {...props} />;
    } else if (column.name === "action") {
      return (
        <>
          <VirtualTable.Cell {...props}>
            <DefaultActionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else if (column.name === "canvas") {
      return (
        <>
          <VirtualTable.Cell {...props}>
            <UiApplicationCanvas {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  }
  return <VirtualTable.Cell {...props} />;
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const DevExpressTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { isEditMode } = props;

  useEffect(() => {
    IS_EDIT_MODE = isEditMode;
  }, [isEditMode]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(
      props.defaultColumnWidths,
      windowDimensions,
      props.isCustomWidth
    );
    if (props.isVersionData) {
      let alterWidth = width;
      alterWidth.map((item) => {
        if (item.columnName === "action") item.width = 330;
        if (item.columnName === "port") item.width = 150;
      });
      setColumnWidths(alterWidth);
    } else {
      setColumnWidths(width);
    }
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    setHiddenColumnNames(hideCols);
    props.setHidden(hideCols);
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 260
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default DevExpressTable;
