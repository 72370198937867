import { Paper, Divider } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// files
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import {
  imagesColumns,
  imagesDefaultColumnWidths,
  imagesTableColumnExtensions,
  imagesColumnOrder,
  imagesColumnHidden,
} from "../../../constants/ContainerManagement/ImagesListTableConstant";
import { useTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
import {
  add_dockerimage_data,
  add_dockerImage_Filters,
  add_dockerimage_sort,
  add_dockerImage_hidden_cols,
  clear_docker_image_data,
} from "../../../redux/actions/dockerImageAction";
import CustomFilter from "../../../components/common/CustomFilter";
import { arrayToFilter, arrayToSort } from "../../../utils/common";
import Pagination from "@material-ui/lab/Pagination";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const ImageListScreen = (props) => {
  const {
    data,
    add_dockerimage_data,
    loading,
    add_dockerImage_Filters,
    dockerImageFilters,
    add_dockerimage_sort,
    dockerImageSort,
    clear_docker_image_data,
    _totalcount,
    add_dockerImage_hidden_cols,
    dockerHiddenCols,
    alterActiveTab,
    deleteDockerImageStatus,
  } = props;
  const styles = listScreenStyles();
  const histroy = useHistory();
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: imagesColumns,
    widths: imagesDefaultColumnWidths,
    extentions: imagesTableColumnExtensions,
    orders: imagesColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    alterActiveTab("Docker Images", "Docker Images");
  }, []);

  useEffect(() => {
    checkScreenPermission("DockerImage", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("DockerImage");
      if (_status === 200) {
        const projData = getTableData(
          data,
          imagesColumns,
          imagesDefaultColumnWidths,
          imagesTableColumnExtensions,
          imagesColumnOrder,
          dockerHiddenCols,
          "dockerImage"
        );
        setTableData(projData);
        if (
          JSON.stringify(dockerHiddenCols) ===
          JSON.stringify(imagesColumnHidden)
        ) {
          add_dockerImage_hidden_cols(projData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  // clear reducer ..... code changes done here

  useEffect(() => {
    return () => {
      clear_docker_image_data();
    };
  }, []);

  // Api call

  useEffect(() => {
    const parsedFilters = arrayToFilter(dockerImageFilters);
    const parsedSort = arrayToSort(dockerImageSort);
    add_dockerimage_data(
      currentPage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPage, dockerImageSort]);

  useEffect(() => {
    if(deleteDockerImageStatus === true){
      const parsedFilters = arrayToFilter(dockerImageFilters);
      const parsedSort = arrayToSort(dockerImageSort);
      add_dockerimage_data(
        currentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteDockerImageStatus]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // clear reducer ..... code changes done here

  useEffect(() => {
    return () => {
      clear_docker_image_data();
    };
  }, []);

  function createData(dockerImage_data) {
    const {
      _id,
      imagename,
      state,
      imagetag,
      createdby,
      createdon,
      updatedby,
      updatedon,
      buildtype,
    } = dockerImage_data;
    return {
      ...dockerImage_data,
      _id,
      imagename,
      state: state ? state.charAt(0).toUpperCase() + state.slice(1) : "",
      imagetag,
      createdby,
      createdon: createdon,
      updatedby,
      updatedon,
      buildtype,
    };
  }

  const rows =
    data?.length > 0
      ? data?.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];

  // filter

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(dockerImageFilters);
    const parsedSort = arrayToSort(dockerImageSort);
    add_dockerimage_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_dockerimage_data(1, JSON.stringify({}));
  };

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      add_dockerimage_data(
        0,
        JSON.stringify({
          buildtype: { $ne: "draft" },
          imagename: { $regex: searchInput, $options: "i" },
        })
      );
    } else {
      add_dockerimage_data(1);
    }
  };
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Docker Images", path: "/docker-images" },
              { title: "Docker Images list" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div className={styles.topContentItem} style={{ width: "80%" }}>
              <p className={styles.title}>
                {t("dockerImage.dockerImage")}
                {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search Docker Image Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData.columns}
                filters={dockerImageFilters}
                setFilters={add_dockerImage_Filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />

              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="Create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("DockerImage", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/Create-image")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {tableLoading ? (
            <Loader />
          ) : (
            <ApplicationListTable
              columns={tableData.columns}
              rows={rows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              defaultHiddenColumnNames={tableData.hides}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isCustomHeight={true}
              sort={dockerImageSort}
              setSort={add_dockerimage_sort}
              hidden={dockerHiddenCols}
              setHidden={add_dockerImage_hidden_cols}
            />
          )}
        </Paper>
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_dockerimage_data,
  add_dockerImage_Filters,
  add_dockerimage_sort,
  add_dockerImage_hidden_cols,
  clear_docker_image_data,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    dockerImage_list,
    dockerImageFilters,
    dockerImageSort,
    dockerHiddenCols,
  } = state.dockerImageReducer;
  const { data, error, loading, _totalcount, deleteDockerImageStatus } = dockerImage_list;
  return {
    data,
    error,
    loading,
    dockerImageFilters,
    dockerImageSort,
    _totalcount,
    dockerHiddenCols,
    deleteDockerImageStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageListScreen);
