import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import useTriggers from "../../../hooks/useTriggers";

const CustomLink = ({ id }) => {
  const { currentProject, getComponent } = useGetData();
  const { handleTrigger } = useTriggers();
  const currentComponent = getComponent(id);
  const history = useHistory();
  const params = useParams();
  const uiAppsList = useSelector(
    (state) => state.uiApplicationListReducer.data
  );
  const viewType = params.view !== undefined ? params.view + "/" : "";
  useEffect(() => {
    if (currentComponent["onInit"]) {
      handleTrigger({
        action: currentComponent?.["onInit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  }, []);

  const handleClick = () => {
    const queryParams = {};
    if (currentComponent?.queryParamsJSMode) {
      if (Array.isArray(currentComponent?.jsQueryParams?.dataValue)) {
        currentComponent?.jsQueryParams?.dataValue.forEach((param) => {
          Object.keys(param).forEach((element) => {
            queryParams[element] = param[element];
          });
        });
      } else if (
        Object.keys(currentComponent?.jsQueryParams?.dataValue).length
      ) {
        Object.keys(currentComponent?.jsQueryParams?.dataValue).forEach(
          (element) => {
            queryParams[element] =
              currentComponent?.jsQueryParams?.dataValue[element];
          }
        );
      }
    } else {
      currentComponent?.queryParams?.forEach((param) => {
        queryParams[param?.key] = param?.value;
      });
    }
    const newParams = new URLSearchParams(queryParams).toString();
    if (currentComponent?.urlType === "page") {
      if (currentComponent?.page) {
        currentProject?.id === currentComponent?.uiApplication?.id
          ? history.push(
              `/ui-applications/${viewType}${currentProject?.id}/${
                currentProject?.pages.find(
                  (p) => p.name === currentComponent?.page
                )?.url
              }${newParams !== "=" ? "?" + newParams : ""}${
                params?.device ? "/" + params?.device : ""
              }`
            )
          : (window.location.href = `/ui-applications/${viewType}${
              currentComponent?.uiApplication?.id
            }/${
              uiAppsList
                .find((app) => app._id === currentComponent.uiApplication?.id)
                ?.pages.find((p) => p.name === currentComponent?.page)?.url
            }${newParams !== "=" ? "?" + newParams : ""}${
              params?.device ? "/" + params?.device : ""
            }`);
      }
    } else {
      const link = currentComponent?.link?.dataValue;
      if (link) {
        window.open(
          typeof link === "string"
            ? `${link}${newParams !== "=" ? "?" + newParams : ""}`
            : "",
          "_blank"
        );
      }
    }
  };

  const linkText = currentComponent?.linkText?.dataValue;

  return (
    <div>
      <p
        onClick={() => handleClick()}
        style={{
          //   margin: 0,
          color: currentComponent?.status,
          fontSize: currentComponent?.fontSize
            ? currentComponent?.fontSize
            : 14,
          float: currentComponent?.alignment === "left" ? "left" : "right",
          pointerEvents:
            currentComponent?.disabled?.dataValue === "true" ? "none" : "auto",
          opacity: currentComponent?.disabled?.dataValue === "true" ? 0.5 : 1,
          fontFamily: currentComponent?.fontFamily,
          fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
          fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
          textDecoration: currentComponent?.style?.underline
            ? "underline"
            : undefined,
          // align center
          display: currentComponent?.alignment === "center" ? "flex" : "unset",
          alignItems:
            currentComponent?.alignment === "center" ? "center" : "unset",
          justifyContent:
            currentComponent?.alignment === "center" ? "center" : "unset",
          height:
            currentComponent?.alignment === "center"
              ? "-webkit-fill-available"
              : "unset",
          width:
            currentComponent?.alignment === "center"
              ? "-webkit-fill-available"
              : "unset",
        }}
      >
        {linkText && typeof linkText === "string" ? linkText : "Invalid link"}
      </p>
    </div>
  );
};

export default CustomLink;
