import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL, REPOSITORY } from "./helper/config";

const apiHelper = new ApiHelper();
export class boilerPlateServices {
  getAllBoilerPlates(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getBoilerPlatesDetailsByID(bPlateID) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/${bPlateID}`;
    return apiHelper.get(uri);
  }
  createBoilerPlate(bPlateData) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates`;
    return apiHelper.post(uri, bPlateData);
  }
  updateBuildFile(bPlateID, bPlateData) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/${bPlateID}`;
    return apiHelper.put(uri, bPlateData);
  }

  updateBoilerPlateGitUploadFile(bPlateID, gitData) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/edit/${bPlateID}`
    return apiHelper.put(uri, gitData);
  }
  updateDescription(bPlateID, descriptionData) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/editmeta/${bPlateID}`;
    return apiHelper.put(uri, descriptionData);
  }
  updateBuildCommand(bPlateID, buildCmdData) {
    const uri = `${REPOSITORY}/api/repo/repositories/dockers/${bPlateID}/commands/buildcommands`;
    return apiHelper.put(uri, buildCmdData);
  }
  updateRunCommand(bPlateID, runCmdData) {
    const uri = `${REPOSITORY}/api/repo/repositories/dockers/${bPlateID}/commands/runcommands`;
    return apiHelper.put(uri, runCmdData);
  }
  DeleteBoilerPlates(bPlateID) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/${bPlateID}`;
    return apiHelper.delete(uri);
  }
  CreateBoilerPlateFromGithub(bPlateData) {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates/_download`;
    return apiHelper.post(uri, bPlateData);
  }
  DownloadBoilerPlate(bPlateID) {
    const uri = `${BASE_URL}/api/repo/repositories/boilerplates/${bPlateID}/_download`;
    return apiHelper.get(uri);
  }
}
