import { ClusterService } from "../../services/clusterService";

const cluster = new ClusterService();

export const fetchClusterList = async () => {
  try {
    const res_data = await cluster.getAllCluster();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getAllClusterDeployments = async () => {
  try {
    const res_data = await cluster.getAllClusterDeployments();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchClusterReplicaSets = async (
  cloudConfig,
  clusterName,
  currentPageClusterPods
) => {
  try {
    const res_data = await cluster.getAllClusterReplicaSets(
      cloudConfig,
      clusterName,
      currentPageClusterPods
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};

export const featchAllClusterDetails = async (cloudConfig) => {
  try {
    const res_data = await cluster.getAllClusterDetailsHost(cloudConfig);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
// export const fetchAllPods = async ()=>{
//     try {
//         const res_data = await cluster.getAllPods();
//         const { _msg, _status, data } = res_data.data;
//         return {
//             _status,
//             data,
//             _msg,
//         };
//     } catch (err) {
//         return {
//             _msg: err?.response?.data?._msg,
//             _status: err?.response?.data?._status,
//             data: err?.response?.data?.data,
//         };
//     }
// }

export const fetchClusterServices = async (
  cloudConfig,
  clusterName,
  currentPageCluster
) => {
  try {
    const res_data = await cluster.getAllClusterServices(
      cloudConfig,
      clusterName,
      currentPageCluster
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};

export const fetchClusterPods = async (
  configName,
  clusterName,
  currentPageClusterPods
) => {
  try {
    const res_data = await cluster.getAllClusterPods(
      configName,
      clusterName,
      currentPageClusterPods
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};

export const fetchClusterDeploymentDetails = async (
  configName,
  clusterName,
  currentPageDeployment
) => {
  try {
    const res_data = await cluster.getAllClusterDeployments(
      configName,
      clusterName,
      currentPageDeployment
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};

export const fetchClusterDeploymentNodes = async (
  configName,
  clusterName,
  currentPageDeployment
) => {
  try {
    const res_data = await cluster.getAllClusterNodes(
      configName,
      clusterName,
      currentPageDeployment
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};

export const getAllClusterDetails = async (
  configName,
  clusterName,
  currentPageDeployment
) => {
  try {
    const res_data = await cluster.getAllClusterDetails(
      configName,
      clusterName,
      currentPageDeployment
    );
    const { _msg, _status, data, _totalcount } = res_data.data;
    return {
      _status,
      data,
      _msg,
      _totalcount,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
      _totalcount: err?.response?.data?._totalcount,
    };
  }
};
