import React, { useEffect, useState } from "react";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NewDevExpressTable from "../../components/common/Tables/ClusterRelatedTable";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  clusterPodsColumns,
  clusterPodsDefaultColumnWidths,
  clusterPodsTableColumnExtensions,
  clusterPodsColumnOrder,
  clusterPodsDefaultSorting,
  clusterPodsColumnHidden,
} from "../../constants/clusterPodsConstants";
import {
  addClusterDeploymentSort,
  addClusterDeploymentHidden,
  fetchAllClusterDeployment,
  fetchAllDeploymentClusters,
  fetchAllClusterPods,
  add_cluster_pods_Hidden,
  addclusterpodsHidden,
  addClusterPodsHidden,
} from "../../redux/actions/clusterActions";
import {
  getWindowDimensions,
  getTableData,
  arrayToFilter,
  arrayToSort,
} from "../../utils/common";
import { Pagination } from "@material-ui/lab";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import { fetchClusterPods } from "../../utils/clusterutils/clusterUtils";
import { add_cluster_pods_list } from "../../redux/actions/clusterActions";
import { clusterDeploymentColumnOrder } from "../../constants/clusterDeploymentConstants";

const ClusterPodsDevExpTable = (props) => {
  const {
    clusterPodsList,
    clusterPodsSort,
    clusterPodsHidden,
    data,
    loading,
    error,
    fetchAllClusterPods,
    clusterName,
    configName,
    addClusterPodsHidden,
    totalCount,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [statuses, setStatuses] = useState([]);
  const [clusterPodsRows, setClusterPodsRows] = useState([]);
  // const [metaData,setMetaData]=useState([]);
  const [currentPageClusterPods, setCurrentPageClusterPods] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalCountt, setTotalCount] = useState();
  const [tableData, setTableData] = useState({
    columns: clusterPodsColumns,
    widths: clusterPodsDefaultColumnWidths,
    extentions: clusterPodsTableColumnExtensions,
    orders: clusterPodsColumnOrder,
    hides: [],
  });

  const [clusterPodsData, setClusterPodsData] = useState();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const styles = listStyles();

  useEffect(() => {
    const fetchClusterPodsFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Host");
      if (_status === 200) {
        let servData = getTableData(
          data,
          clusterPodsColumns,
          clusterPodsDefaultColumnWidths,
          clusterPodsTableColumnExtensions,
          clusterPodsColumnOrder,
          clusterPodsHidden
        );
        servData = {
          ...servData,
          columns: servData.columns.filter(
            (col) =>
              col.name !== "projectID" &&
              col.name !== "servicegroupID" &&
              col.name !== "languageID" &&
              col.name !== "boilerplateID"
          ),
          hides: servData.hides.filter(
            (hide) =>
              hide !== "projectID" &&
              hide !== "servicegroupID" &&
              hide !== "languageID" &&
              hide !== "boilerplateID"
          ),
        };
        setClusterPodsData(servData);
        if (
          JSON.stringify(clusterPodsHidden) ===
          JSON.stringify(clusterPodsColumnHidden)
        ) {
          addClusterPodsHidden(servData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(async () => {
    const getClusterPods = async () => {
      const { _msg, _status, data, _totalcount } = await fetchClusterPods(
        configName,
        clusterName,
        currentPageClusterPods
      );
      setTotalCount(_totalcount);
      if (_status === 200) {
        let metaData = [];
        data?.map((item) => {
          item.metadata["applicationname"] = item?.deploymentname;
          item.metadata["labels"] = `app:${item?.metadata?.labels?.app}`;
          item.metadata["node"] = item?.spec?.nodeName;
          item.metadata["restart"] = item?.spec?.restartPolicy;
          item.metadata["creationTimestamp"] = moment(
            item?.metadata?.creationTimestamp
          ).fromNow();
          metaData.push({ ...item.metadata, ...item.status });
        });
        setClusterPodsRows(metaData);

        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    getClusterPods();
  }, [currentPageClusterPods]);
  return (
    <main>
      <div style={{margin:"0px -16px"}}>
        <NewDevExpressTable
          columns={clusterPodsColumns}
          rows={clusterPodsRows}
          windowDimensions={windowDimensions}
          defaultColumnWidths={clusterPodsDefaultColumnWidths}
          tableColumnExtensions={clusterPodsTableColumnExtensions}
          // columnOrder={clusterDeploymentColumnOrder}
          hidden={clusterPodsHidden}
          noDataMsg="No Pods Found"
          // addClusterPodsHidden={addClusterPodsHidden}
          setHidden={addClusterPodsHidden}
        />
        {totalCountt !== 0 ? (
          <div style={{ marginTop: "20px" }}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(totalCountt / 10)}
                page={currentPageClusterPods}
                onChange={(e, val) => setCurrentPageClusterPods(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  fetchAllClusterDeployment,
  addClusterDeploymentHidden,
  addClusterDeploymentSort,
  fetchAllDeploymentClusters,
  fetchAllClusterPods,
  addClusterPodsHidden,
  // fetchClusterPods
};

const mapStateToProps = (state) => {
  const { clusterPodsList, clusterPodsSort, clusterPodsHidden } =
    state.clusterReducer;
  const { data, loading, error, totalCount } = clusterPodsList;

  return {
    clusterPodsList,
    clusterPodsSort,
    clusterPodsHidden,
    loading,
    error,
    data,
    totalCount,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterPodsDevExpTable);
