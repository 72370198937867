/* eslint-disable */
import {
  Paper,
  Divider,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import { AppButton } from "../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import {
  addServiceSort,
  clearServices,
} from "../../redux/actions/serviceAction";
import { connect } from "react-redux";
import {
  executionColumns,
  executionDefaultColumnWidths,
  executionTableColumnExtensions,
  executionColumnOrder,
  executionDefaultSorting,
  executionColumnHidden,
} from "../../constants/executionConstants";
import {
  scriptsColumns,
  scriptsDefaultColumnWidths,
  scriptsTableColumnExtensions,
  scriptsColumnOrder,
  scriptsDefaultSorting,
  scriptsColumnHidden,
} from "../../constants/scriptsConstants";
import {
  dataColumns,
  dataDefaultColumnWidths,
  dataTableColumnExtensions,
  dataColumnOrder,
  dataDefaultSorting,
  dataColumnHidden,
} from "../../constants/dataConstants";
import CustomFilter from "../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort,
  getTableData,
  getWindowDimensions,
} from "../../utils/common";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import ExecutionListTable from "../../components/common/Tables/ExecutionListTable";
import ScriptListTable from "../../components/common/Tables/ScriptListTable";
import {
  fetchAllTestExecution,
  addExecutionFilters,
  addExecutionSort,
  addExecutionHidden,
} from "../../redux/actions/testExecutionAction";
import {
  fetchAllTestScript,
  addScriptFilters,
  addScriptHidden,
} from "../../redux/actions/testScriptAction";
import {
  fetchAllTestDataActions,
  addDataFilters,
  addDataHidden,
} from "../../redux/actions/testDataActions";
import DataListTable from "../../components/common/Tables/DataListTable";
import SearchBar from "../../components/common/SearchBar";

const ServicesList = (props) => {
  const {
    addExecutionSort,
    addExecutionHidden,
    addScriptHidden,
    addDataHidden,
    deleteTestExecutionStatus,

    addExecutionFilters,
    addScriptFilters,
    addDataFilters,

    fetchAllTestExecution,
    fetchAllTestScript,
    fetchAllTestDataActions,

    scriptData,
    testDataData,
    data,

    totalCountScript,
    totalCountData,
    _totalcount,
    deleteScriptStatus,

    testActiveTab,

    testExecutionFilters,
    testExecutionSort,
    testExecutionHidden,

    testScriptFilters,
    testScriptSort,
    testScriptHidden,

    testDataFilters,
    testDataSort,
    testDataHidden,
    deleteTestDataStatus,

    loading,
    serviceSort,
    addServiceSort,
    alterActiveTab,
    clearServices,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [currentPageExecution, setCurrentPageExecution] = useState(1);
  const [currentPageScript, setCurrentPageScript] = useState(1);
  const [currentPageData, setCurrentPageData] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [executionRows, setExecutionRows] = useState([]);
  const [tableLoadingExecution, setTableLoadingExectuion] = useState(true);
  const [tableLoadingScript, setTableLoadingScript] = useState(true);
  const [tableLoadingData, setTableLoadingData] = useState(true);
  const [dataRows, setDataRows] = useState([]);
  const [scriptRows, setScriptRows] = useState([]);
  const [isFavouriteClick, setIsFavouriteClick] = useState(false);
  const [isFavouriteClickData, setIsFavouriteClickData] = useState(false);
  const [executionTableData, setExecutionTableData] = useState({
    columns: executionColumns,
    widths: executionDefaultColumnWidths,
    extentions: executionTableColumnExtensions,
    orders: executionColumnOrder,
    hides: [],
  });

  console.log("csascasnkannono")
  const [scriptsTableData, setScriptsTableData] = useState({
    columns: scriptsColumns,
    widths: scriptsDefaultColumnWidths,
    extentions: scriptsTableColumnExtensions,
    orders: scriptsColumnOrder,
    hides: [],
  });
  const [dataTableData, setDataTableData] = useState({
    columns: dataColumns,
    widths: dataDefaultColumnWidths,
    extentions: dataTableColumnExtensions,
    orders: dataColumnOrder,
    hides: [],
  });

  const [currentFavouriteScriptsPage, setCurrentFavouriteScriptsPage] = useState(1);
  const [currentFavouriteTestDataPage, setCurrentFavouriteTestDataPage] = useState(1);


  useEffect(() => {
    const fetchExecutionFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("TestExecute");
      if (_status === 200) {
        let executionData = getTableData(
          data,
          executionColumns,
          executionDefaultColumnWidths,
          executionTableColumnExtensions,
          executionColumnOrder,
          testExecutionHidden
        );
        setExecutionTableData(executionData);
        if (
          JSON.stringify(testExecutionHidden) ===
          JSON.stringify(executionColumnHidden)
        ) {
          addExecutionHidden(executionData.hides);
        }
        setTableLoadingExectuion(false);
      } else {
        setTableLoadingExectuion(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchScriptFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("TestScript");
      if (_status === 200) {
        let scriptData = getTableData(
          data,
          scriptsColumns,
          scriptsDefaultColumnWidths,
          scriptsTableColumnExtensions,
          scriptsColumnOrder,
          testScriptHidden
        );
        setScriptsTableData(scriptData);
        if (
          JSON.stringify(testScriptHidden) ===
          JSON.stringify(scriptsColumnHidden)
        ) {
          addScriptHidden(scriptData.hides);
        }
        setTableLoadingScript(false);
      } else {
        setTableLoadingScript(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchDataFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("TestData");
      if (_status === 200) {
        let TestDataData = getTableData(
          data,
          dataColumns,
          dataDefaultColumnWidths,
          dataTableColumnExtensions,
          dataColumnOrder,
          testDataHidden
        );
        setDataTableData(TestDataData);
        if (
          JSON.stringify(testDataHidden) === JSON.stringify(dataColumnHidden)
        ) {
          addDataHidden(TestDataData.hides);
        }
        setTableLoadingData(false);
      } else {
        setTableLoadingData(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchScriptFields();
    fetchExecutionFields();
    fetchDataFields();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function createData(serviceData) {
    const {
      _id,
      name,
      status,
      createdon,
      isFavourite,
      createdby,
      isSubscribe,
    } = serviceData;
    return {
      ...serviceData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      status: status,
      api: 0,
      isFavourite: isFavourite,
      isSubscribe: isSubscribe,
    };
  }

  useEffect(async () => {
    const parsedFilters = arrayToFilter(testExecutionFilters);
    const parsedSort = arrayToSort(testExecutionSort);
    fetchAllTestExecution(
      currentPageExecution,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageExecution, testExecutionSort]);

  useEffect(async () => {
    if(deleteTestExecutionStatus === true) {
      const parsedFilters = arrayToFilter(testExecutionFilters);
      const parsedSort = arrayToSort(testExecutionSort);
      fetchAllTestExecution(
        currentPageExecution,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteTestExecutionStatus]);

  useEffect(() => {
    data.map((item) => {
      const duration =
        item?.results?.timings?.completed - item?.results?.timings?.started;
      const durationms = ((duration % 600) / 10).toFixed(0);
      item["runduration"] = durationms;
    });
    setExecutionRows(data && data?.length > 0 ? data?.map((item) => item) : []);
  }, [data]);

  useEffect(() => {
    if (!isFavouriteClick) {
      setScriptRows(
        scriptData && scriptData?.length > 0
          ? scriptData?.map((item) => item)
          : []
      );
    } else {
      setScriptRows(
        scriptData && scriptData.length > 0
          ? scriptData
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [scriptData]);

  useEffect(() => {
    if (!isFavouriteClickData) {
      setDataRows(
        testDataData && testDataData?.length > 0
          ? testDataData?.map((item) => item)
          : []
      );
    } else {
      setDataRows(
        testDataData && testDataData.length > 0
          ? testDataData
            ?.filter((r) => r.isFavourite === true)
            ?.map((item) => {
              const rowData = createData(item);
              return rowData;
            })
          : []
      );
    }
  }, [testDataData]);

  const handleToggleFavouriteData = () => {
    if (isFavouriteClickData) {
      const parsedFilters = arrayToFilter(testDataFilters);
      const parsedSort = arrayToSort(testDataSort);
      fetchAllTestDataActions(
        currentPageData,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
      setDataRows(
        testDataData && testDataData?.length > 0
          ? testDataData?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClickData(false);
      setCurrentPageData(1);
    } else {
      fetchAllTestDataActions();
      setDataRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClickData(true);
      setCurrentFavouriteTestDataPage(1);
    }
  };

  const handleToggleFavouriteScript = () => {
    if (isFavouriteClick) {
      const parsedFilters = arrayToFilter(testScriptFilters);
      const parsedSort = arrayToSort(testScriptSort);
      fetchAllTestScript(currentPageScript, parsedFilters, JSON.stringify(parsedSort));
      setScriptRows(
        scriptData && scriptData?.length > 0
          ? scriptData?.map((item) => {
            const rowData = createData(item);
            return rowData;
          })
          : []
      );
      setIsFavouriteClick(false);
      setCurrentPageScript(1);
    } else {
      fetchAllTestScript();
      setScriptRows((prev) => prev.filter((r) => r.isFavourite === true));
      setIsFavouriteClick(true);
      setCurrentFavouriteScriptsPage(1);
    }
  };

  useEffect(async () => {
    const parsedFilters = arrayToFilter(testScriptFilters);
    const parsedSort = arrayToSort(testScriptSort);
    fetchAllTestScript(
      currentPageScript,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageScript, testScriptSort]);

  useEffect(async () => {
    if(deleteScriptStatus === true){
      const parsedFilters = arrayToFilter(testScriptFilters);
      const parsedSort = arrayToSort(testScriptSort);
      fetchAllTestScript(
        currentPageScript,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteScriptStatus]);

  useEffect(() => {
    const parsedFilters = arrayToFilter(testDataFilters);
    const parsedSort = arrayToSort(testDataSort);
    fetchAllTestDataActions(
      currentPageData,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageData, testDataSort]);

  useEffect(() => {
    if(deleteTestDataStatus === true){
      const parsedFilters = arrayToFilter(testDataFilters);
      const parsedSort = arrayToSort(testDataSort);
      fetchAllTestDataActions(
        currentPageData,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteTestDataStatus]);

  const styles = listStyles();

  const histroy = useHistory();

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (testActiveTab === "Execution") {
      if (searchInput) {
        fetchAllTestExecution(
          1,
          JSON.stringify({
            deploymentname: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        fetchAllTestExecution(1);
      }
    } else if (testActiveTab === "Scripts") {
      if (searchInput) {
        fetchAllTestScript(
          1,
          JSON.stringify({
            name: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        fetchAllTestScript(1);
      }
    } else if (testActiveTab === "Data") {
      if (searchInput) {
        fetchAllTestDataActions(
          1,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        fetchAllTestDataActions(1);
      }
    }
  };

  const handleSubmitFilter = () => {
    if (testActiveTab === "Execution") {
      let alterFilter = testExecutionFilters.map((item) => {
        if (item.column === "number") {
          item.value = parseInt(item.value);
          item.value2 = parseInt(item.value2);
        }
        return item;
      });
      let parsedFilters = arrayToFilter(alterFilter);
      // parsedFilters['number'] = 'passpercentage'
      const replaceString = parsedFilters.replace("number", "passpercentage");

      const parsedSort = arrayToSort(testExecutionSort);
      fetchAllTestExecution(1, replaceString, JSON.stringify(parsedSort));
    } else if (testActiveTab === "Scripts") {
      const parsedFilters = arrayToFilter(testScriptFilters);
      const parsedSort = arrayToSort(testScriptSort);
      fetchAllTestScript(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (testActiveTab === "Data") {
      const parsedFilters = arrayToFilter(testDataFilters);
      const parsedSort = arrayToSort(testDataSort);
      fetchAllTestDataActions(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFilters = () => {
    if (testActiveTab === "Execution") {
      fetchAllTestExecution(1, JSON.stringify({}));
    } else if (testActiveTab === "Scripts") {
      fetchAllTestScript(1, JSON.stringify({}));
    } else if (testActiveTab === "Data") {
      fetchAllTestDataActions(1, JSON.stringify({}));
    }
  };

  const handleChangeTab = (event, newValue) => {
    alterActiveTab("testActiveTab", newValue);
  };

  useEffect(() => {
    return () => {
      clearServices();
    };
  }, []);

  const activeTabBredCrumbsName = () => {
    if (testActiveTab === "Execution") {
      return "Executions List";
    } else if (testActiveTab === "Scripts") {
      return "Scripts List";
    } else if (testActiveTab === "Data") {
      return "Test Data List";
    }
  };

  const [customFilterExecution, setCustomFilterExecution] = useState([]);

  useEffect(() => {
    let executionColumnAlter = JSON.parse(JSON.stringify(executionColumns));
    executionColumnAlter?.map((item) => {
      if (item.name === "passpercentage") {
        item.name = "number";
      }
    });

    setCustomFilterExecution(executionColumnAlter);
  }, []);

  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Test", path: "/test" },
              { title: activeTabBredCrumbsName() },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <Tabs
                value={testActiveTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
              >
                <Tab
                  label={`Executions${_totalcount ? ` (${_totalcount})` : ""}`}
                  value={"Execution"}
                />
                <Tab
                  label={`Scripts${totalCountScript ? ` (${totalCountScript})` : ""
                    }`}
                  value={"Scripts"}
                />
                <Tab
                  label={`Test Data${totalCountData ? ` (${totalCountData})` : ""
                    }`}
                  value={"Data"}
                />
              </Tabs>
            </div>
            <div className={styles.topContentItem2}>
              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel={
                  testActiveTab === "Execution"
                    ? "Search Deployments"
                    : `Search ${testActiveTab}`
                }
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              {testActiveTab === "Execution" && (
                <CustomFilter
                  columns={customFilterExecution ? customFilterExecution : []}
                  filters={testExecutionFilters}
                  setFilters={addExecutionFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {testActiveTab === "Scripts" && (
                <CustomFilter
                  columns={scriptsTableData.columns}
                  filters={testScriptFilters}
                  setFilters={addScriptFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {testActiveTab === "Data" && (
                <CustomFilter
                  columns={dataTableData.columns}
                  filters={testDataFilters}
                  setFilters={addDataFilters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              <Divider orientation="vertical" className={styles.MuiDivider} />

              {testActiveTab === "Scripts" && (
                <>
                  <Tooltip title="Favourite" arrow>
                    <IconButton
                      color="inherit"
                      aria-label="open cardview"
                      edge="start"
                      style={{ margin: "0px 10px" }}
                      onClick={handleToggleFavouriteScript}
                    >
                      {isFavouriteClick ? (
                        <img src="/images/heartFilled.svg" alt="card" />
                      ) : (
                        <img src="/images/heartLine.svg" alt="card" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />
                </>
              )}
              {testActiveTab === "Data" && (
                <>
                  <Tooltip title="Favourite" arrow>
                    <IconButton
                      color="inherit"
                      aria-label="open cardview"
                      edge="start"
                      style={{ margin: "0px 10px" }}
                      onClick={handleToggleFavouriteData}
                    >
                      {isFavouriteClickData ? (
                        <img src="/images/heartFilled.svg" alt="card" />
                      ) : (
                        <img src="/images/heartLine.svg" alt="card" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Divider
                    orientation="vertical"
                    className={styles.MuiDivider}
                  />
                </>
              )}

              {testActiveTab === "Execution" && (
                <AppButton
                  buttonName="Run Test"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  className="btnsmall"
                  onClick={() => {
                    histroy.push("test-execute/add", {
                      data: "services",
                    });
                  }}
                />
              )}

              {testActiveTab === "Scripts" && (
                <>
                  <AppButton
                    buttonName="create"
                    variant="contained"
                    startIcon={
                      <AddIcon style={{ width: "16px", height: "16px" }} />
                    }
                    className="btnsmall"
                    onClick={() => {
                      histroy.push("test-script/add");
                    }}
                  />
                </>
              )}

              {testActiveTab === "Data" && (
                <AppButton
                  buttonName="create"
                  variant="contained"
                  startIcon={
                    <AddIcon style={{ width: "16px", height: "16px" }} />
                  }
                  className="btnsmall"
                  onClick={() => {
                    histroy.push("test-data/add");
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {testActiveTab === "Execution" && (
            <>
              {tableLoadingExecution ? (
                <Loader />
              ) : (
                <ExecutionListTable
                  columns={executionTableData?.columns}
                  rows={executionRows}
                  defaultColumnWidths={executionTableData.widths}
                  tableColumnExtensions={executionTableData.extentions}
                  columnOrder={executionTableData.orders}
                  currentPage={currentPageExecution}
                  setCurrentPage={setCurrentPageExecution}
                  isCustomHeight={true}
                  sort={testExecutionSort}
                  windowDimensions={windowDimensions}
                  setSort={addExecutionSort}
                  hidden={testExecutionHidden}
                  setHidden={addExecutionHidden}
                  noDataMsg="No Execution Found"
                />
              )}
            </>
          )}
          {testActiveTab === "Scripts" && (
            <>
              {tableLoadingScript ? (
                <Loader />
              ) : (
                <ScriptListTable
                  columns={scriptsTableData.columns}
                  rows={isFavouriteClick ? (
                    scriptRows?.length > 0
                      ? scriptRows?.slice(
                        (currentFavouriteScriptsPage - 1) * 10,
                        currentFavouriteScriptsPage * 10
                      )
                      : []
                  ) : scriptRows}
                  defaultColumnWidths={scriptsTableData.widths}
                  tableColumnExtensions={scriptsTableData.extentions}
                  columnOrder={scriptsTableData.orders}
                  loading={loading}
                  currentPage={isFavouriteClick ? currentFavouriteScriptsPage : currentPageScript}
                  setCurrentPage={setCurrentPageScript}
                  isCustomHeight={true}
                  sort={serviceSort}
                  windowDimensions={windowDimensions}
                  setSort={addServiceSort}
                  hidden={testScriptHidden}
                  setHidden={addScriptHidden}
                  noDataMsg="No Scripts Found"
                />
              )}
            </>
          )}
          {testActiveTab === "Data" && (
            <>
              {tableLoadingData ? (
                <Loader />
              ) : (
                <DataListTable
                  columns={dataTableData.columns}
                  rows={isFavouriteClickData ? (
                    dataRows?.length > 0
                      ? dataRows?.slice(
                        (currentFavouriteTestDataPage - 1) * 10,
                        currentFavouriteTestDataPage * 10
                      )
                      : []
                  ) : dataRows}
                  defaultColumnWidths={dataTableData.widths}
                  tableColumnExtensions={dataTableData.extentions}
                  columnOrder={dataTableData.orders}
                  loading={loading}
                  currentPage={isFavouriteClickData ? currentFavouriteTestDataPage : currentPageData}
                  setCurrentPage={setCurrentPageData}
                  isCustomHeight={true}
                  sort={serviceSort}
                  windowDimensions={windowDimensions}
                  setSort={addServiceSort}
                  hidden={testDataHidden}
                  setHidden={addDataHidden}
                  noDataMsg="No Data Found"
                />
              )}
            </>
          )}
        </Paper>
        {executionRows.length !== 0 && testActiveTab === "Execution" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPageExecution}
                onChange={(e, val) => setCurrentPageExecution(val)}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}

        {scriptRows.length !== 0 && testActiveTab === "Scripts" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {isFavouriteClick ?
                <Pagination
                  count={Math.ceil(scriptRows.length / 10)}
                  page={currentFavouriteScriptsPage}
                  onChange={(e, val) => setCurrentFavouriteScriptsPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
                :
                <Pagination
                  count={Math.ceil(totalCountScript / 10)}
                  page={currentPageScript}
                  onChange={(e, val) => setCurrentPageScript(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              }
            </div>
          </div>
        )}

        {dataRows.length !== 0 && testActiveTab === "Data" && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              {isFavouriteClickData ?
                <Pagination
                  count={Math.ceil(dataRows.length / 10)}
                  page={currentFavouriteTestDataPage}
                  onChange={(e, val) => setCurrentFavouriteTestDataPage(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
                :
                <Pagination
                  count={Math.ceil(totalCountData / 10)}
                  page={currentPageData}
                  onChange={(e, val) => setCurrentPageData(val)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              }
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addExecutionSort,
  addExecutionHidden,
  addScriptHidden,
  addDataHidden,
  addExecutionFilters,
  addScriptFilters,
  addDataFilters,
  fetchAllTestExecution,
  fetchAllTestScript,
  fetchAllTestDataActions,
  addServiceSort,
  alterActiveTab,
  clearServices,
};

const mapStateToProps = (state) => {
  const { testExecutionFilters, testExecutionSort, testExecutionHidden } =
    state.testExecutionReducer;
  const { testScriptFilters, testScriptSort, testScriptHidden } =
    state.testScriptReducer;
  const { testDataFilters, testDataSort, testDataHidden } =
    state.testDataReducer;
  const { data, error, loading, _totalcount, deleteTestExecutionStatus } =
    state?.testExecutionReducer?.testExecutionList;
  const { serviceSort, serviceHidden } =
    state.serviceReducer;
  const { userInfo } = state.userDetails;
  const { testActiveTab } = state.activeTabReducer;
  const { deleteScriptStatus } = state.testScriptReducer.testScriptList;
  const { deleteTestDataStatus } = state.testDataReducer.testDataList;

  return {
    testActiveTab,
    testExecutionFilters,
    testExecutionSort,
    testExecutionHidden,
    deleteTestExecutionStatus,
    testScriptFilters,
    testScriptSort,
    testScriptHidden,
    deleteScriptStatus,
    testDataFilters,
    testDataSort,
    testDataHidden,
    deleteTestDataStatus,

    scriptData: state.testScriptReducer.testScriptList.data,
    testDataData: state.testDataReducer.testDataList.data,
    data,
    totalCountScript: state.testScriptReducer.testScriptList._totalcount,
    totalCountData: state.testDataReducer.testDataList._totalcount,
    error,
    loading,
    serviceGUIFilters: state.serviceGUIReducer.serviceGUIFilters,
    serviceSort,
    _totalcount,
    _totalcountGUI: state.serviceGUIReducer.servicesGUIList?._totalcount,
    userInfo,
    serviceHidden,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
