import { createDeployment } from "../../../utils/deployment/deploymentUtils";

export const CreateDeploymentService = async (deploymentAllData) => {
  const { appSelectionData, deploymentName, dependenciesData, hostingData } =
    deploymentAllData;

  const convertNameSpace = (name) => {
    const lowerCaseName = name.toLowerCase();
    const convertName = lowerCaseName.replace(/ /g, "-");
    const newName = convertName.replace(/_/g, "-");

    return newName;
  };

  let deploymentContainerData = dependenciesData?.container;

  function checkSelectedContainer(item) {
    if (item.select) {
      delete item["error"];
      if (item.resources) {
        if (item.resources?.limits?.cpu) {
          if (!(item.resources.limits.cpu + "").endsWith("m")) {
            item.resources.limits.cpu = `${item.resources.limits.cpu * 1000}m`;
          }
        }
        if (item.resources?.requests?.cpu) {
          if (!(item.resources.requests.cpu + "").endsWith("m")) {
            item.resources.requests.cpu = `${
              item.resources.requests.cpu * 1000
            }m`;
          }
        }

        if (item.resources?.limits?.memory) {
          if (item.resources?.limits?.memorytype) {
            if (!(item.resources.limits.memory + "").endsWith("Mi")) {
              item.resources.limits.memory = `${
                item.resources.limits.memory * 1024
              }Mi`;
            }
            delete item.resources.limits["memorytype"];
          } else {
            if (!(item.resources.limits.memory + "").endsWith("Mi")) {
              item.resources.limits.memory = `${item.resources.limits.memory}Mi`;
            }
          }
        }
        if (item.resources?.requests?.memory) {
          if (item.resources?.requests?.memorytype) {
            if (!(item.resources.requests.memory + "").endsWith("Mi")) {
              item.resources.requests.memory = `${
                item.resources.requests.memory * 1024
              }Mi`;
            }

            delete item.resources.requests["memorytype"];
          } else {
            if (!(item.resources.requests.memory + "").endsWith("Mi")) {
              item.resources.requests.memory = `${item.resources.requests.memory}Mi`;
            }
          }
        }
        return item;
      } else {
        return item;
      }
    }
  }

  const selectedContainer = deploymentContainerData.filter(
    checkSelectedContainer
  );
  let deploymentSerializeData;
  if (appSelectionData?.deploymenttype === "UI Application") {
    // ui image

    const tempUiImageData = selectedContainer.filter(
      (item) => item.type === "main_ui"
    );

    // service bundle
    const tempServiceBundleData = selectedContainer.filter(
      (item) => item.type === "service_bundle"
    );

    let allIds = [];

    tempServiceBundleData.map((item) => {
      allIds.push(item.id);
    });

    let uniqueIDs = allIds.filter(function (value, index, array) {
      return allIds.indexOf(value) === index;
    });

    const serviceBundleContainers = [];

    uniqueIDs.map((item) => {
      const findedData = tempServiceBundleData.filter((ser) => ser.id === item);
      serviceBundleContainers.push({
        id: findedData[0].id,
        runtimeenvid: findedData[0].runtimeenvid,
        deployments: findedData,
      });
    });

    // service bundle
    const tempServiceData = selectedContainer.filter(
      (item) => item.type === "service"
    );

    let allServiceIds = [];

    tempServiceData.map((item) => {
      allServiceIds.push(item.id);
    });

    let serviceUniqueIDs = allServiceIds.filter(function (value, index, array) {
      return allServiceIds.indexOf(value) === index;
    });

    const serviceContainers = [];

    serviceUniqueIDs.map((item) => {
      const findedData = tempServiceData.filter((ser) => ser.id === item);
      serviceContainers.push({ id: findedData[0].id, deployments: findedData });
    });

    const linkedUiContainers = selectedContainer.filter((item) => item.type === 'ui' && item.type !== 'main_ui')


    deploymentSerializeData = {
      namespace: convertNameSpace(appSelectionData?.deployment_app_name?.name),
      configurationid: hostingData?.configurationname?._id,
      deploymentname: appSelectionData?.deployment_app_name?.name,
      deploymenttype: "UI Application",
      deptypeid: appSelectionData?.deployment_app_name?._id,
      clustername: hostingData?.clustername,
      uiappdetails: {
        container_name: tempUiImageData[0].container_name,
        environment: tempUiImageData[0].environment,
        imageid: tempUiImageData[0].imageid,
        restart: "always",
        image: `${tempUiImageData[0].image}`,
        appid: tempUiImageData[0].appid,
        port: tempUiImageData[0].port,
      },
      deployments: [],
      appdeployments: {
        linkeduiapplications: linkedUiContainers,
        services: serviceContainers,
        servicebundles: serviceBundleContainers,
      },
    };
  } else {
    deploymentSerializeData = {
      namespace:
        appSelectionData?.deploymenttype === "Application"
          ? convertNameSpace(appSelectionData?.deployment_app_name?.name)
          : convertNameSpace(
              appSelectionData?.deployment_app_name?.projectDetails[0]?.name
            ),
      configurationid: hostingData?.configurationname?._id,
      deploymentname: deploymentName?.deploymentName
        ? deploymentName?.deploymentName
        : appSelectionData?.deployment_app_name?.name,
        deploymenttype: appSelectionData?.deploymenttype === "Application" ? "Service Bundle" : appSelectionData?.deploymenttype,
      deptypeid:
        appSelectionData?.deploymenttype === "Application"
          ? appSelectionData?.deployment_app_name?._id
          : appSelectionData?.versionDetails?._id,
      runtimeenvid:
        appSelectionData?.deploymenttype === "Application"
          ? appSelectionData?.deployment_app_name?.runtime_env
          : appSelectionData?.deployment_app_version?.runtimeEnv,
      clustername: hostingData?.clustername,
      deployments: selectedContainer,
    };

    if (!deploymentSerializeData?.runtimeenvid) {
      delete deploymentSerializeData["runtimeenvid"];
    }
  }

  const { data, _status, _msg } = await createDeployment(
    deploymentSerializeData
  );
  return { _status: _status, _msg: _msg, data: data };
};
