import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import moment from "moment-timezone";
import { connect } from "react-redux";
import Loader from "../../../components/common/stuff/Loader";
import DeploymentDetailsListTable from "../../../components/common/Tables/DeploymentDetailsListTable";
import {
  deploymentColumnHidden,
  deploymentColumnOrder,
  deploymentColumns,
  deploymentDefaultColumnWidths,
  deploymentTableColumnExtensions,
} from "../../../constants/deploymentDetailsConstants";
import { fetchDeploymentById } from "../../../redux/actions/deploymentProcessAction";
import listStyles from "../../CommonStyles/listScreenStyles";
import {
  editDeploymentById,
  FetchDeploymentById,
} from "../../../utils/deployment/deploymentUtils";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupForDeploymentEdit from "./CustomFieldGroupForDeploymentEdit";

const EditDeployment = (props) => {
  const {
    fetchDeploymentById,
    data,
    error,
    loading,
    deploymentname,
    DetailDeployment,
  } = props;
  const styles = listStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const deploymentId = props.match.params.deploymentId;
  const [tableLoading, setTableLoading] = useState(true);
  const [deploymentRows, setDeploymentRows] = useState([]);
  const [endPoint, setEndPoint] = useState();
  const [endPointFilter, setEndPointFilter] = useState();

  const [deploymentSection, setDeploymentSection] = useState();
  const [errors, setErrors] = useState({
    name: { isError: false },
    description: { isError: false },
  });
  const [tableData, setTableData] = useState({
    columns: deploymentColumns,
    widths: deploymentDefaultColumnWidths,
    extentions: deploymentTableColumnExtensions,
    orders: deploymentColumnOrder,
    hides: [],
  });
  const [description, setDescription] = useState();
  const handleSubmit = async () => {
    const payload = {
      description: description,
    };

    const { _msg, _status, data } = await editDeploymentById(
      deploymentId,
      payload
    );
    if (_status === 200) {
      histroy.push(`/host/details/${deploymentId}`);
      enqueueSnackbar(_msg, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchDeploymentById(deploymentId);
  }, []);

  useEffect(async () => {
    const { data, _msg, _status } = await fetchResourceByName("Host");

    if (_status === 200) {
      data?.sections.map((section) => {
        if (section.name === "Deployment_Details_Heaader_Se") {
          setDeploymentSection(section);
        }
      });
    }
  }, []);
  useEffect(() => {
    const getDeployment = async () => {
      const { _msg, _status, data } = await FetchDeploymentById(deploymentId);
      if (_status === 200) {
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    getDeployment();
  }, []);

  // useEffect(() => {
  //   setEndPoint(deploymentRows?.map((item)=> item.serdetails.status.loadBalancer.ingress ? item.serdetails.status.loadBalancer.ingress[0].hostname : []))
  // }, [deploymentRows])

  useEffect(() => {
    setDeploymentRows(
      data?.length > 0 && data
        ? data?.map((item) => {
          item["DeploymentID"] = DetailDeployment._id;
          item["MicroserviceId"] = DetailDeployment.Microserviceid;
          return item;
        })
        : []
    );
  }, [data]);

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Deployment List", path: "/host" },
            { title: "Deployment", path: "/host" },
            { title: `Edit ${deploymentname}` },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Edit {deploymentname}</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AppButton
              color="primary"
              buttonName="Save"
              variant="contained"
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={handleSubmit}
            />
            <AppButton
              color="primary"
              buttonName="Cancel"
              variant="outlined"
              style={{ margin: "unset", marginLeft: 10 }}
              className="btnsmall"
              onClick={() => histroy.goBack()}
            />
          </div>
        </Grid>
      </div>
      <Paper className={styles.pageContent}>
        <Grid grid-xs-12 style={{ marginBottom: "50px" }}>
          <CustomFieldGroupForDeploymentEdit
            section={deploymentSection}
            error={errors}
            setError={setErrors}
            deploymentname={deploymentname}
            DetailDeployment={DetailDeployment}
            description={description}
            setDescription={setDescription}
          />
        </Grid>
        <Grid>
          {tableLoading ? (
            <Loader />
          ) : (
            <DeploymentDetailsListTable
              columns={tableData.columns}
              rows={deploymentRows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              loading={loading}
              isCustomHeight={true}
              hidden={deploymentColumnHidden}
              noDataMsg="No Deployments Found"
            />
          )}
        </Grid>
      </Paper>
    </main>
  );
};

const mapDispatchToProps = {
  fetchDeploymentById,
};
const mapStateToProps = (state) => {
  const { error, loading } =
    state.deploymentProcessReducer.deploymentDetailsList;
  const { deploymentDetails } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  const { data } = state.deploymentProcessReducer.deploymentDetailsList;
  const { deploymentname } =
    state.deploymentProcessReducer.deploymentDetailsList.data;
  return {
    data: deploymentDetails,
    DetailDeployment: data,
    error,
    loading,
    deploymentname,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDeployment);
