import {
  ADD_RUNTIME_ENV_DATA,
  RUNTIMEENV_FILTER,
  DELETE_RUNTIMEENV,
  RUNTIMEENV_SORT,
  RUNTIMEENV_HIDDEN_COULMNS,
  CLEAR_RUNTIME_DATA,
  START_OR_STOP_RUNTIMEENV,
} from "../actionTypes";
import { RuntimeEnvServices } from "../../services/runtimeEnvServices";

const RuntimeEnvService = new RuntimeEnvServices();

function add_runtimeEnv_list_data(payload) {
  return {
    type: ADD_RUNTIME_ENV_DATA,
    payload: payload,
  };
}

export function add_runtimeEnv_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_runtimeEnv_list_data({
        loading: true,
      })
    );
    RuntimeEnvService.getRuntimeEnvList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_runtimeEnv_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_runtimeEnv_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_runtimeEnv_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_runtimeEnv_filters = (EnvFilters) => {
  return {
    type: RUNTIMEENV_FILTER,
    payload: EnvFilters,
  };
};

export const add_runtimeEnv_sort = (runtimeEnvSort) => {
  return {
    type: RUNTIMEENV_SORT,
    payload: runtimeEnvSort,
  };
};
export const delete_runtimeEnv = (EnvID) => {
  return {
    type: DELETE_RUNTIMEENV,
    payload: {
      EnvID,
    },
  };
};

export const add_runtimeENV_hidden_cols = (ENVHiddenCols) => {
  return {
    type: RUNTIMEENV_HIDDEN_COULMNS,
    payload: ENVHiddenCols,
  };
};
export const clear_runtime_env_data = () => {
  return {
    type: CLEAR_RUNTIME_DATA,
  };
};

export const start_or_stop_runtimeEnv = (EnvID, state) => {
  return {
    type: START_OR_STOP_RUNTIMEENV,
    payload: {
      EnvID,
      state,
    },
  };
};
