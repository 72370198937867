import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AppButton } from "../common/Button";
import manageScreenStyles from "../../screens/CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import { addSizingDeployment } from "../../redux/actions/deploymentProcessAction";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  line1: {
    fontSize: "14px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    width: "750px",
    height: "400px",
    marginTop: "0.5rem",
  },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
}));

function AddSizingDeploymentModal(props) {
  const { isOpen, handleCloseSizing, resources, addSizingDeployment, id } =
    props;
  const [sizing, setSizing] = useState({ limits: "", requests: "" });
  const [error, setError] = useState();
  const classes = useStyles();
  const styles = manageScreenStyles();

  const [switchCheck, setSwitchCheck] = useState({
    checkedMB: true,
    checkedGB: true,
  });

  useEffect(() => {
    if (resources) {
      setSizing(resources);
    } else {
      setSizing({ limits: "", requests: "" });
    }
  }, [isOpen]);

  const handleChangeMin = (e) => {
    setSizing({
      ...sizing,
      requests: { ...sizing.requests, [e.target.name]: e.target.value },
    });
  };

  const handleChangeMax = (e) => {
    setSizing({
      ...sizing,
      limits: { ...sizing.limits, [e.target.name]: e.target.value },
    });
  };

  const Validate = () => {
    let isValid = true;
    let error = [];
    const minimum = sizing.requests;
    const maximum = sizing.limits;
    if (minimum?.memory && !(minimum?.memory > 0)) {
      isValid = false;
      error["requests"] = {...error["requests"], memory: { isError: true } };
    }
    if (maximum?.memory && !(maximum?.memory > 0)) {
      isValid = false;
      error["limits"] = {...error["limits"], memory: { isError: true } };
    }

    if (!minimum?.cpu || minimum?.cpu > 8 || minimum?.cpu < 0.5) {
      isValid = false;
      error["requests"] = {...error["requests"],
        cpu: { isError: true, msg: "cpu should be 0.5 to 8" },
      };
    }

    if (parseInt(minimum?.cpu) > parseInt(maximum.cpu)) {
      isValid = false;
      error["requests"] = { ...error["requests"],
        cpu: { isError: true, msg: "cpu must be less than maximum cpu value" },
      };
    }

    if (!maximum?.cpu || maximum?.cpu > 8 || maximum?.cpu < 0.5) {
      isValid = false;
      error["limits"] = {...error["limits"],
        cpu: { isError: true, msg: "cpu should be 0.5 to 8" },
      };
    }

    setError(error);
    return isValid;
  };

  const handleSubmit = () => {
    if (Validate()) {
      handleCloseSizing();
      addSizingDeployment({ id: id, sizingData: sizing });
    }
  };

  const handleChangeSwitchMinimum = (e) => {
    setSizing({
      ...sizing,
      requests: { ...sizing.requests, [e.target.name]: e.target.checked },
    });
  };

  const handleChangeSwitchMaximum = (e) => {
    setSizing({
      ...sizing,
      limits: { ...sizing.limits, [e.target.name]: e.target.checked },
    });
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={isOpen}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="sm"
      >
        <DialogTitle className={classes.header} id="scroll-dialog-title">
          <p className={classes.heading}>Adjust Sizing</p>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography>RAM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Minimum
                        </label>

                        <Grid container>
                          <Grid item xs={4}>
                            <input
                              type="text"
                              name="memory"
                              className={`${styles.input} ${
                                error?.requests?.memory && styles.error
                              }`}
                              autoComplete="off"
                              onChange={handleChangeMin}
                              value={sizing?.requests?.memory}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <span style={{ marginLeft: 20 }}>
                              MB
                              <Switch
                                checked={sizing?.requests?.memorytype}
                                onChange={handleChangeSwitchMinimum}
                                name="memorytype"
                                color="default"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />{" "}
                              GB
                            </span>
                          </Grid>
                        </Grid>
                      </div>
                      {error?.requests?.memory && (
                        <p className={styles.errorMsg}>Invalid value</p>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <div>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Maximum
                        </label>

                        <Grid container>
                          <Grid item xs={4}>
                            <input
                              type="text"
                              name="memory"
                              className={`${styles.input} ${
                                error?.limits?.memory && styles.error
                              }`}
                              autoComplete="off"
                              onChange={handleChangeMax}
                              value={sizing?.limits?.memory}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <span style={{ marginLeft: 20 }}>
                              MB
                              <Switch
                                checked={sizing?.limits?.memorytype}
                                onChange={handleChangeSwitchMaximum}
                                name="memorytype"
                                color="default"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />{" "}
                              GB
                            </span>
                          </Grid>
                        </Grid>
                      </div>
                      {error?.limits?.memory && (
                        <p className={styles.errorMsg}>Invalid value</p>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item sm={12}>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography>CPU</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                   
                      <div>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Minimum
                        </label>

                        <input
                          type="text"
                          name="cpu"
                          className={`${styles.input} ${
                            error?.requests?.cpu && styles.error
                          }`}
                          autoComplete="off"
                          onChange={handleChangeMin}
                          value={sizing?.requests?.cpu}
                        />
                      </div>
                      {error?.requests?.cpu && (
                        <p className={styles.errorMsg}>
                          {error?.requests?.cpu?.msg}
                        </p>
                      )}

                    </Grid>

                    <Grid item xs={6}>
                   
                      <div>
                        <label htmlFor="" className={styles.fieldHeading}>
                          Maximum
                        </label>

                        <input
                          type="text"
                          name="cpu"
                          className={`${styles.input} ${
                            error?.limits?.cpu && styles.error
                          }`}
                          autoComplete="off"
                          onChange={handleChangeMax}
                          value={sizing?.limits?.cpu}
                        />
                      </div>
                      {error?.limits?.cpu && (
                        <p className={styles.errorMsg}>
                          {error?.limits?.cpu?.msg}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleCloseSizing}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          {!props.disabled && (
            <AppButton
              onClick={handleSubmit}
              color="primary"
              buttonName="Save"
              variant="contained"
              className="btnsmall"
              style={{ margin: "unset" }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addSizingDeployment,
};

export default connect(null, mapDispatchToProps)(AddSizingDeploymentModal);
