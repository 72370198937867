import StatusStyles from "./DisabledButtonStyles";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import { useState } from "react";
import JSDataInput from "../../settings/JSDataInput";
import useGetData from "../../../../../hooks/useGetData";
import { useTheme } from "@material-ui/core";

const DisableSpecific = ({ selectedColumn }) => {
  const dispatch = useDispatch();
  const styles = StatusStyles();
  const theme = useTheme();
  const [jsMode, setJsMode] = useState(false);
  const { currentPage, currentComponent, getDataValue, executeJavascript } =
    useGetData();
  const [error, setError] = useState("");

  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = JSON.stringify(executeJavascript(value));
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          columns: component?.columns?.map((col) => {
            if (col?.id === selectedColumn?.id) {
              return {
                ...col,
                disabled: {
                  dataInput: value,
                  dataValue: dataValue,
                },
              };
            } else {
              return col;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p className="" style={{ margin: 0, marginBottom: 5 }}>
          <label>Disabled</label>
        </p>
        <p
          className="inputmode"
          style={{
            margin: 0,
            marginBottom: 5,
            color: "#AFAFAF",
            cursor: "pointer",
          }}
          onClick={() => setJsMode(!jsMode)}
        >
          Js Mode
        </p>
      </div>
      {!jsMode ? (
        <div className={styles.loadingbtnContainer}>
          <button
            className={styles.loadingbtn}
            onClick={() => handleChange(true.toString())}
            style={
              selectedColumn?.disabled?.dataValue === "true"
                ? {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                  }
                : { textTransform: "capitalize" }
            }
          >
            true
          </button>
          <button
            style={
              selectedColumn?.disabled?.dataValue === "false"
                ? {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                  }
                : { textTransform: "capitalize" }
            }
            className={styles.loadingbtn}
            onClick={() => handleChange(false.toString())}
          >
            false
          </button>
        </div>
      ) : (
        <div className="field">
          <JSDataInput
            value={selectedColumn?.disabled}
            onChange={handleChange}
            name={"loading"}
            title={`${selectedColumn?.name} : Loading`}
            error={error}
          />
        </div>
      )}
    </>
  );
};

export default DisableSpecific;
