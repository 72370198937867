import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  iconHover: {
    cursor: "pointer",
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  bgColor: {
    color: "#FFF !important",
    background: `${theme.palette.primary.main} !important`,
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },

  fieldgroupHeading: {
    font: "normal normal 600 15px/21px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    borderBottom: "2px solid #afafaf",
    margin: 0,
    padding: "1rem 0 .5rem",
  },

  fileName: {
    font: "normal normal 12px/16px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },

  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    // color: "#000000C6",

    "&::before": {
      borderBottom: "unset",
    },
    // "&::placeholder": {
    //   textAlign: "left",
    //   font: "normal normal 600 13px/18px Nunito",
    //   letterSpacing: "0px",
    //   color: "#707070",
    //   opacity: "0.6",
    // },
  },
  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    // height: "80%",
    font: "normal normal normal 12px/16px Nunito",
    marginTop: "5px",
    resize: "vertical",
    minHeight: "2.2rem",
    maxHeight: "13.2rem",
    padding: "10px 15px",
    outline: "none",
  },
  editDetails: {
    margin: "70px 0 125px 0",
    display: "flex",
    height: "40px",
    background: "#EBEBEB 0% 0% no-repeat padding-box",

    "& .MuiGrid-item": {
      marginTop: "10px",
      textAlign: "center",
    },

    "& .MuiGrid-item label": {
      font: "normal normal 600 15px/20px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item span": {
      font: "normal normal 600 15px/20px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
    },
  },
  faded: {
    opacity: 0.6,
  },
  dataTableHead: {
    // color: "#fff !important",
    // backgroundColor: theme.palette.primary.main
  },
}));
