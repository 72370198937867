/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Divider,
  IconButton,
  InputAdornment,
  FilledInput,
  Tabs,
  Tab,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listStyles from "../../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import CustomFilter from "../../../components/common/CustomFilter";
import AddIcon from "@material-ui/icons/Add";
import {
  launguageColumnOrder,
  launguageColumns,
  launguageDefaultColumnWidths,
  launguageTableColumnExtensions,
  languageColumnHidden,
} from "../../../constants/ApplicationGovernanace/LaunguagetableConstatnts";
import {
  boilerPlateColumnOrder,
  boilerPlateColumns,
  boilerPlateDefaultColumnWidths,
  boilerPlateTableColumnExtensions,
  boilerplateColumnHidden,
} from "../../../constants/ApplicationGovernanace/BoilerPlateConstatnts";
import {
  chassisTemplateColumnOrder,
  chassisTemplateColumns,
  chassisTemplateDefaultColumnWidths,
  chassisTemplateTableColumnExtensions,
  chassisTemplateColumnHidden,
} from "../../../constants/ApplicationGovernanace/ChassisTemplatetableConstatnts";
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/stuff/Loader";

// redux
import {
  // ****
  add_launguage_data,
  add_language_filters,
  add_language_sort,
  clear_language_data,
  // ****
  add_boilerPlate_data,
  add_boilerplate_filters,
  add_boilerplate_sort,
  clear_boilerPlate_data,
  add_Boilerplate_hidden_cols,
  // ****
  add_chassisTemplate_data,
  add_chassisTemplate_filters,
  add_template_sort,
  clear_chasis_template_data,
  add_language_hidden_cols,
  add_chassis_template_hidden_cols,
} from "../../../redux/actions/appGovernanceAction";
import {
  arrayToFilter,
  arrayToSort2,
  arrayToSort,
} from "../../../utils/common";
import { connect, useSelector } from "react-redux";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import { AppButton } from "../../../components/common/Button";
import Pagination from "@material-ui/lab/Pagination";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import moment from "moment";

const ApplicationGovernanceListScreen = (props) => {
  const {
    add_launguage_data,
    launguage_list,
    add_language_filters,
    languageFilters,
    add_language_sort,
    languageSort,
    clear_language_data,
    add_language_hidden_cols,
    languageHiddenCols,
    deleteLanguageStatus,

    add_boilerPlate_data,
    boilerPlate_list,
    add_boilerplate_filters,
    boilerPlateFilters,
    add_boilerplate_sort,
    boilerPlateSort,
    clear_boilerPlate_data,
    add_Boilerplate_hidden_cols,
    boilerplateHiddenCols,
    deleteBoilerPlateStatus,

    add_chassisTemplate_data,
    chassisTemplate_list,
    add_chassisTemplate_filters,
    TemplateFilters,
    add_template_sort,
    templateSort,
    clear_chasis_template_data,
    add_chassis_template_hidden_cols,
    templateHiddenCols,
    deleteChassisTemplateStatus,

    application_governance,
  } = props;
  const styles = listStyles();
  const histroy = useHistory();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const handleChangeTab = (event, newValue) => {
    props.alterActiveTab("application_governance", newValue);
  };
  const [currentPagelanguage, setCurrentPagelanguage] = useState(1);
  const [currentPageBoilerPlate, setCurrentPageBoilerPlate] = useState(1);
  const [currentPageChassisTemplate, setCurrentPageChassisTemplate] =
    useState(1);

  // *****************************
  const [LanguagetableLoading, setLanguageTableLoading] = useState(true);
  const [LanguagetableData, setLanguageTableData] = useState({
    columns: launguageColumns,
    widths: launguageDefaultColumnWidths,
    extentions: launguageTableColumnExtensions,
    orders: launguageColumnOrder,
    hides: [],
  });
  const [BoilerplatetableLoading, setBoilerplateTableLoading] = useState(true);
  const [BoilerplatetableData, setBoilerplateTableData] = useState({
    columns: boilerPlateColumns,
    widths: boilerPlateDefaultColumnWidths,
    extentions: boilerPlateTableColumnExtensions,
    orders: boilerPlateColumnOrder,
    hides: [],
  });
  const [TemplatetableLoading, setTemplateTableLoading] = useState(true);
  const [TemplatetableData, setTemplateTableData] = useState({
    columns: chassisTemplateColumns,
    widths: chassisTemplateDefaultColumnWidths,
    extentions: chassisTemplateTableColumnExtensions,
    orders: chassisTemplateColumnOrder,
    hides: [],
  });

  const { userInfo } = useSelector((state) => state.userDetails);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (application_governance === "language") {
      checkScreenPermission("Language", ["List All", "List Assigned"]);
    } else if (application_governance === "boilerPlate") {
      checkScreenPermission("Boilerplate", ["List All", "List Assigned"]);
    } else if (application_governance === "chasisTemplate") {
      checkScreenPermission("Template", ["List All", "List Assigned"]);
    }
    const fetchLanguageFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Language");
      if (_status === 200) {
        const langData = getTableData(
          data,
          launguageColumns,
          launguageDefaultColumnWidths,
          launguageTableColumnExtensions,
          launguageColumnOrder,
          languageHiddenCols,
          "language"
        );
        setLanguageTableData(langData);
        if (
          JSON.stringify(languageHiddenCols) ===
          JSON.stringify(languageColumnHidden)
        ) {
          add_language_hidden_cols(langData.hides);
        }
        setLanguageTableLoading(false);
      } else {
        setLanguageTableLoading(false);
      }
    };
    const fetchBoilerplateFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Boilerplate");
      if (_status === 200) {
        const boilerData = getTableData(
          data,
          boilerPlateColumns,
          boilerPlateDefaultColumnWidths,
          boilerPlateTableColumnExtensions,
          boilerPlateColumnOrder,
          boilerplateColumnHidden,
          'boilerplate'
        );
        // let languageIndex = boilerData.hides.indexOf("language")
        // boilerData.hides.splice(languageIndex, 1)
        let obj = boilerData.columns.find((o) => o.name === "language");
        let languageIndex = boilerData.columns.indexOf(obj);
        boilerData.columns.splice(languageIndex, 1);
        setBoilerplateTableData(boilerData);
        if (
          JSON.stringify(boilerplateHiddenCols) ===
          JSON.stringify(boilerplateColumnHidden)
        ) {
          add_Boilerplate_hidden_cols(boilerData.hides);
        }
        setBoilerplateTableLoading(false);
      } else {
        setBoilerplateTableLoading(false);
      }
    };
    const fetchChassisTemplateFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Template");
      if (_status === 200) {
        const tempData = getTableData(
          data,
          chassisTemplateColumns,
          chassisTemplateDefaultColumnWidths,
          chassisTemplateTableColumnExtensions,
          chassisTemplateColumnOrder,
          chassisTemplateColumnHidden,
          "chassistemplate"
        );
        setTemplateTableData(tempData);
        if (
          JSON.stringify(templateHiddenCols) ===
          JSON.stringify(chassisTemplateColumnHidden)
        ) {
          add_chassis_template_hidden_cols(tempData.hides);
        }
        setTemplateTableLoading(false);
      } else {
        setTemplateTableLoading(false);
      }
    };
    fetchLanguageFields();
    fetchBoilerplateFields();
    fetchChassisTemplateFields();
  }, []);

  //******************************* */
  // Api call

  useEffect(() => {
    const parsedFilters = arrayToFilter(languageFilters);
    const parsedSort = arrayToSort(languageSort);
    add_launguage_data(
      currentPagelanguage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPagelanguage, languageSort]);

  useEffect(() => {
    if(deleteLanguageStatus === true){
      const parsedFilters = arrayToFilter(languageFilters);
      const parsedSort = arrayToSort(languageSort);
      add_launguage_data(
        currentPagelanguage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteLanguageStatus]);

  const handlelanguagePageChange = (event, value) =>
    setCurrentPagelanguage(value);

  useEffect(() => {
    const parsedFilters = arrayToFilter(boilerPlateFilters);
    const parsedSort = arrayToSort(boilerPlateSort);
    add_boilerPlate_data(
      currentPageBoilerPlate,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageBoilerPlate, boilerPlateSort]);

  useEffect(() => {
    if(deleteBoilerPlateStatus === true){ 
      const parsedFilters = arrayToFilter(boilerPlateFilters);
      const parsedSort = arrayToSort(boilerPlateSort);
      add_boilerPlate_data(
        currentPageBoilerPlate,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteBoilerPlateStatus]);

  const handleBoilerPlatePageChange = (event, value) =>
    setCurrentPageBoilerPlate(value);

  useEffect(() => {
    const parsedFilters = arrayToFilter(TemplateFilters);
    const parsedSort = arrayToSort(templateSort);
    add_chassisTemplate_data(
      currentPageChassisTemplate,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPageChassisTemplate, templateSort]);

  useEffect(() => {
    if(deleteChassisTemplateStatus === true){
      const parsedFilters = arrayToFilter(TemplateFilters);
      const parsedSort = arrayToSort(templateSort);
      add_chassisTemplate_data(
        currentPageChassisTemplate,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  }, [deleteChassisTemplateStatus]);

  const handleChassisTemplatePageChange = (event, value) => {
    setCurrentPageChassisTemplate(value);
  };

  // launguage
  function createLaunguageData(launguageData) {
    const {
      _id,
      name,
      status,
      createdon,
      createdby,
      dockerdetails,
      icon,
      imagename,
    } = launguageData;
    return {
      ...launguageData,
      _id,
      name,
      status,
      createdby,
      createdon: createdon,
      dockerdetails,
      icon,
      imagename,
    };
  }

  const languageRows =
    launguage_list.data && launguage_list.data.length > 0
      ? launguage_list.data.map((item) => {
        const rowLaunguageData = createLaunguageData(item);
        rowLaunguageData["dockerdetails.dockerdetails"] =
          rowLaunguageData.dockerdetails.imagename;

        // rowLaunguageData["dockerimage"] = rowLaunguageData.dockerdetails.imagename;
        // rowLaunguageData["updatedon"] =
        //   userInfo?.timeZone && rowLaunguageData?.updatedon !== undefined
        //     ? moment
        //         .unix(rowLaunguageData?.updatedon)
        //         .tz(userInfo.timeZone)
        //         .format(
        //           userInfo.time === "h:mm"
        //             ? `${userInfo.dateFormat}, ${userInfo.time} A`
        //             : `${userInfo.dateFormat} ${userInfo.time}`
        //         )
        //     : "";

        return rowLaunguageData;
      })
      : [];

  // boiler Plate
  function createboilerPlateData(boilerPlateData) {
    const {
      _id,
      BoilerplateName,
      language,
      description,
      createdon,
      createdby,
      filename,
    } = boilerPlateData;
    return {
      ...boilerPlateData,
      _id: _id,
      BoilerplateName: BoilerplateName,
      launguagename: language,
      description: description,
      createdby: createdby,
      createdon: createdon,
      filename: filename,
    };
  }

  const boilerPlateRows =
    boilerPlate_list.data && boilerPlate_list.data.length > 0
      ? boilerPlate_list.data.map((item) => {
        const rowboilerPlateData = createboilerPlateData(item);
        // rowboilerPlateData["updatedon"] =
        //   userInfo?.timeZone && rowboilerPlateData?.updatedon !== undefined
        //     ? moment
        //         .unix(rowboilerPlateData?.updatedon)
        //         .tz(userInfo.timeZone)
        //         .format(
        //           userInfo.time === "h:mm"
        //             ? `${userInfo.dateFormat}, ${userInfo.time} A`
        //             : `${userInfo.dateFormat} ${userInfo.time}`
        //         )
        //     : "";
        return rowboilerPlateData;
      })
      : [];

  // chassis Template
  function createChassisTemplateData(TemplateData) {
    const {
      _id,
      ChassisTemplate,
      launguage,
      description,
      createdon,
      createdby,
      filename,
    } = TemplateData;
    return {
      ...TemplateData,
      _id: _id,
      ChassisTemplate: ChassisTemplate,
      launguagename: launguage,
      description: description,
      createdby: createdby,
      createdon: createdon,
      filename: filename,
    };
  }

  const chassisTemplateRows =
    chassisTemplate_list.data && chassisTemplate_list.data.length > 0
      ? chassisTemplate_list.data.map((item) => {
        const row_data = createChassisTemplateData(item);
        // row_data["updatedon"] =
        //   userInfo?.timeZone && row_data?.updatedon !== undefined
        //     ? moment
        //         .unix(row_data?.updatedon)
        //         .tz(userInfo.timeZone)
        //         .format(
        //           userInfo.time === "h:mm"
        //             ? `${userInfo.dateFormat}, ${userInfo.time} A`
        //             : `${userInfo.dateFormat} ${userInfo.time}`
        //         )
        //     : "";
        return row_data;
      })
      : [];

  //clearing reducers here.... code changes done here

  useEffect(() => {
    return () => clear_language_data();
  }, []);

  useEffect(() => {
    return () => clear_boilerPlate_data();
  }, []);

  useEffect(() => {
    return () => clear_chasis_template_data();
  }, []);

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  const handleSearch = () => {
    if (application_governance === "language") {
      if (searchInput) {
        add_launguage_data(
          0,
          JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
        );
      } else {
        add_launguage_data(1);
      }
    } else if (application_governance === "boilerPlate") {
      if (searchInput) {
        add_boilerPlate_data(
          0,
          JSON.stringify({
            BoilerplateName: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        add_boilerPlate_data(1);
      }
    } else if (application_governance === "chasisTemplate") {
      if (searchInput) {
        add_chassisTemplate_data(
          0,
          JSON.stringify({
            ChassisTemplate: { $regex: searchInput, $options: "i" },
          })
        );
      } else {
        add_chassisTemplate_data(1);
      }
    }
  };

  const handleSubmitFilter = () => {
    if (application_governance === "language") {
      const parsedFilters = arrayToFilter(languageFilters);
      const parsedSort = arrayToSort2(languageSort);
      add_launguage_data(1, parsedFilters, JSON.stringify(parsedSort));
    }
    if (application_governance === "boilerPlate") {
      const parsedFilters = arrayToFilter(boilerPlateFilters);
      const parsedSort = arrayToSort2(boilerPlateSort);
      add_boilerPlate_data(1, parsedFilters, JSON.stringify(parsedSort));
    } else if (application_governance === "chasisTemplate") {
      const parsedFilters = arrayToFilter(TemplateFilters);
      const parsedSort = arrayToSort2(templateSort);
      add_chassisTemplate_data(1, parsedFilters, JSON.stringify(parsedSort));
    }
  };

  const handleClearFilters = () => {
    if (application_governance === "language") {
      add_launguage_data(1, JSON.stringify({}));
    }
    if (application_governance === "boilerPlate") {
      add_boilerPlate_data(1, JSON.stringify({}));
    } else if (application_governance === "chasisTemplate") {
      add_chassisTemplate_data(1, JSON.stringify({}));
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Application Governance",
              path: "/application-governance",
            },
            {
              title:
                application_governance === "language"
                  ? "Language"
                  : application_governance === "boilerPlate"
                    ? "Boiler Plate"
                    : "Chassis Template",
            },
          ]}
        />

        <div className={styles.topContentItems}>
          <div className={styles.topContentItem} style={{ width: "80%" }}>
            <p className={styles.title} style={{ marginTop: 10 }}>
              {t("applicationGovernance.applicationGovernance")}
            </p>
          </div>
          <div className={styles.topContentItem2}>
            <FilledInput
              id="filled-adornment-password"
              name="filterfn"
              onChange={(e) => setSearchInput(e.target.value)}
              className={styles.search}
              placeholder={
                application_governance === "language"
                  ? "Search Language Name"
                  : application_governance === "boilerPlate"
                    ? "Search Boiler Plate Name"
                    : "Search Chassis Template Name"
              }
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearch}
                    onMouseDown={handleSearch}
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Divider orientation="vertical" className={styles.MuiDivider} />
            <CustomFilter
              columns={
                (application_governance === "language" &&
                  LanguagetableData.columns) ||
                (application_governance === "boilerPlate" &&
                  BoilerplatetableData.columns) ||
                (application_governance === "chasisTemplate" &&
                  TemplatetableData.columns)
              }
              filters={
                (application_governance === "language" && languageFilters) ||
                (application_governance === "boilerPlate" &&
                  boilerPlateFilters) ||
                (application_governance === "chasisTemplate" && TemplateFilters)
              }
              setFilters={
                (application_governance === "language" &&
                  add_language_filters) ||
                (application_governance === "boilerPlate" &&
                  add_boilerplate_filters) ||
                (application_governance === "chasisTemplate" &&
                  add_chassisTemplate_filters)
              }
              handleSubmitFilter={handleSubmitFilter}
              handleClearFilters={handleClearFilters}
            />

            <Divider
              orientation="vertical"
              className={styles.MuiDivider}
              style={{ marginRight: 10 }}
            />
            {application_governance === "language" && (
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={!checkCompPermission("Language", ["Create"])}
                startIcon={<AddIcon />}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={() => histroy.push("/create-language")}
              />
            )}
            {application_governance === "boilerPlate" && (
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={!checkCompPermission("Boilerplate", ["Create"])}
                startIcon={<AddIcon />}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={() => histroy.push("/create-boilerplate")}
              />
            )}
            {application_governance === "chasisTemplate" && (
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={!checkCompPermission("Template", ["Create"])}
                startIcon={<AddIcon />}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={() => histroy.push("/create-chassistemplate")}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.pageContent} style={{ top: "5rem" }}>
        {
          <Tabs
            value={application_governance}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            className={styles.accessTab2}
          >
            <Tab
              label={`Language ${launguage_list._totalcount
                ? ` (${launguage_list._totalcount})`
                : ""
                }`}
              value={"language"}
              disabled={
                !checkCompPermission("Language", ["List All", "List Assigned"])
              }
            />
            <Tab
              label={`Boiler Plate ${boilerPlate_list._totalcount
                ? ` (${boilerPlate_list._totalcount})`
                : ""
                } `}
              value={"boilerPlate"}
              disabled={
                !checkCompPermission("Boilerplate", ["List All", "List Assigned"])
              }
            />
            <Tab
              label={`Chassis Template  ${chassisTemplate_list._totalcount
                ? ` (${chassisTemplate_list._totalcount})`
                : ""
                }`}
              value={"chasisTemplate"}
              disabled={
                !checkCompPermission("Template", ["List All", "List Assigned"])
              }
            />
          </Tabs>
        }
        {application_governance === "language" && (
          <>
            {LanguagetableLoading ? (
              <Loader />
            ) : (
              <ApplicationListTable
                columns={LanguagetableData.columns}
                rows={languageRows}
                defaultColumnWidths={LanguagetableData.widths}
                tableColumnExtensions={LanguagetableData.extentions}
                columnOrder={LanguagetableData.orders}
                defaultHiddenColumnNames={LanguagetableData.hides}
                loading={launguage_list.loading}
                currentPage={currentPagelanguage}
                setCurrentPage={setCurrentPagelanguage}
                isCustomHeight={true}
                sort={languageSort}
                setSort={add_language_sort}
                hidden={languageHiddenCols}
                setHidden={add_language_hidden_cols}
              />
            )}
          </>
        )}
        {application_governance === "boilerPlate" && (
          <>
            {BoilerplatetableLoading ? (
              <Loader />
            ) : (
              <>
                <ApplicationListTable
                  columns={BoilerplatetableData.columns}
                  rows={boilerPlateRows}
                  defaultColumnWidths={BoilerplatetableData.widths}
                  tableColumnExtensions={BoilerplatetableData.extentions}
                  columnOrder={BoilerplatetableData.orders}
                  defaultHiddenColumnNames={BoilerplatetableData.hides}
                  loading={boilerPlate_list.loading}
                  currentPage={currentPageBoilerPlate}
                  setCurrentPage={setCurrentPageBoilerPlate}
                  isCustomHeight={true}
                  sort={boilerPlateSort}
                  setSort={add_boilerplate_sort}
                  hidden={boilerplateHiddenCols}
                  setHidden={add_Boilerplate_hidden_cols}
                />
              </>
            )}
          </>
        )}
        {application_governance === "chasisTemplate" && (
          <>
            {TemplatetableLoading ? (
              <Loader />
            ) : (
              <ApplicationListTable
                columns={TemplatetableData.columns}
                rows={chassisTemplateRows}
                defaultColumnWidths={TemplatetableData.widths}
                tableColumnExtensions={TemplatetableData.extentions}
                columnOrder={TemplatetableData.orders}
                defaultHiddenColumnNames={TemplatetableData.hides}
                loading={chassisTemplate_list.loading}
                currentPage={currentPageChassisTemplate}
                setCurrentPage={setCurrentPageChassisTemplate}
                isCustomHeight={true}
                sort={templateSort}
                setSort={add_template_sort}
                hidden={templateHiddenCols}
                setHidden={add_chassis_template_hidden_cols}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.customPagination}>
          {application_governance === "language" && (
            <Pagination
              count={Math.ceil(launguage_list._totalcount / 10)}
              page={currentPagelanguage}
              onChange={handlelanguagePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          )}
          {application_governance === "boilerPlate" && (
            <Pagination
              count={Math.ceil(boilerPlate_list._totalcount / 10)}
              page={currentPageBoilerPlate}
              onChange={handleBoilerPlatePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          )}
          {application_governance === "chasisTemplate" && (
            <Pagination
              count={Math.ceil(chassisTemplate_list._totalcount / 10)}
              page={currentPageChassisTemplate}
              onChange={handleChassisTemplatePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          )}
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_launguage_data,
  add_language_filters,
  add_language_sort,
  clear_language_data,
  add_language_hidden_cols,

  add_boilerPlate_data,
  add_boilerplate_filters,
  add_boilerplate_sort,
  clear_boilerPlate_data,
  add_Boilerplate_hidden_cols,

  add_chassisTemplate_data,
  add_chassisTemplate_filters,
  add_template_sort,
  clear_chasis_template_data,
  add_chassis_template_hidden_cols,

  alterActiveTab,
};

const mapStateToProps = (state) => {
  const {
    launguage_list,
    languageFilters,
    languageSort,
    languageHiddenCols,

    boilerPlate_list,
    boilerPlateFilters,
    boilerPlateSort,
    boilerplateHiddenCols,

    chassisTemplate_list,
    TemplateFilters,
    templateSort,
    templateHiddenCols,
  } = state.appGovernanceReducer;
  const { application_governance } = state.activeTabReducer;
  const { deleteLanguageStatus } = launguage_list;
  const { deleteBoilerPlateStatus } = boilerPlate_list;
  const { deleteChassisTemplateStatus } = chassisTemplate_list;

  return {
    launguage_list,
    languageFilters,
    languageSort,
    languageHiddenCols,
    deleteLanguageStatus,

    boilerPlate_list,
    boilerPlateFilters,
    boilerPlateSort,
    boilerplateHiddenCols,
    deleteBoilerPlateStatus,

    chassisTemplate_list,
    TemplateFilters,
    templateSort,
    templateHiddenCols,
    deleteChassisTemplateStatus,

    application_governance,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationGovernanceListScreen);
