import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from "@material-ui/core";
import React from "react";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";

const CreateDockerImageEntity = ({
  section,
  handleChange,
  error,
  DockerImagedata,
  // For Docker Image Upload
  styles1,
  activeTab,
  handleTabChange,
  DroppedDocumentError,
  droppedDocument,
  nameError,
  imageDetails,
  setnameError,
  handleUpload,
  setLoading,
  handleImageNameChange,
  setTagError,
  tagError,
  handleDeleteClick,
  setError,
  setDockerImageData,
  filesLimit,
  selectedDocuments,
  setSelectedDocuments,
  documentsError,
  setDocumentsError,
  FileDropperWithLayoutForDockerImage,
}) => {
  const styles = manageStyles();

  const dockerImageFileUpload = () => {
    return (
      // <div>
      //   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe error
      //   hic facilis voluptates excepturi deserunt odit voluptatem dolore,
      //   dolorem, nihil est? Doloremque blanditiis, quis magnam unde ab numquam
      //   nostrum, veritatis placeat laborum eius porro reprehenderit cum atque
      //   vitae ratione autem quasi tempora labore exercitationem id quia ea modi!
      //   Quod laboriosam accusamus aperiam, ipsum veritatis magnam. Explicabo ea,
      //   incidunt magnam animi accusantium, a ducimus veritatis quos aut vel
      //   placeat vitae sapiente harum at optio deleniti, doloremque similique.
      //   Vel natus qui dolore dolorem asperiores architecto blanditiis. Beatae,
      //   exercitationem? Itaque, ut. Asperiores rerum, nam, labore quam dolore
      //   quae ad repudiandae eligendi magnam officiis officia architecto
      //   temporibus dolorem veniam perferendis ratione. Voluptate dolorem
      //   excepturi culpa iusto, magnam, dolore laboriosam cum reiciendis modi ab
      //   ut maxime necessitatibus dolores. Atque fuga enim eius quidem id
      //   doloremque quas a inventore, eligendi incidunt explicabo distinctio quae
      //   officia similique consequuntur accusamus placeat ex quam non commodi
      //   nesciunt accusantium aliquam. Nesciunt reiciendis totam libero nemo
      //   doloremque, corporis expedita atque hic natus facere sequi rerum! Est
      //   aperiam cum asperiores corporis enim, quisquam praesentium illo debitis
      //   maiores nihil nisi veniam rerum adipisci tempora esse voluptate! Illum
      //   fugiat rerum soluta unde at id magni dolores quis aspernatur placeat,
      //   amet, atque corporis error, quos totam! Nam eaque, eligendi velit quam
      //   eveniet quae culpa sapiente non laboriosam dolor suscipit reiciendis
      //   tempora veniam ratione amet perferendis dignissimos, ab quod sunt modi
      //   ad temporibus id? Dignissimos blanditiis dolore velit laborum dolorum
      //   ipsam, doloremque magni facere iusto porro voluptatem culpa inventore
      //   ducimus suscipit repellendus saepe? Doloremque a sint officia,
      //   reprehenderit illo adipisci commodi quod error maiores molestias?
      //   Ducimus necessitatibus quia dolorem, earum magnam non cupiditate et
      //   minima voluptatem ipsam quam voluptatum sequi animi alias, consequatur
      //   voluptates, illum vero iure deserunt quas vel. Ullam non consequatur
      //   laboriosam voluptatum eaque placeat veritatis? Similique quia maxime,
      //   eaque voluptatem nesciunt soluta optio tempora repellat vitae aliquid
      //   alias animi provident reprehenderit repellendus expedita pariatur, earum
      //   voluptatum qui aperiam blanditiis ratione non a perferendis! Ex nesciunt
      //   corrupti officiis maiores beatae dignissimos itaque, quaerat esse ullam
      //   dolorem vero sunt impedit quidem? Cum maiores laborum hic expedita
      //   delectus mollitia veritatis quidem ipsam ea, totam libero est dolor,
      //   quam provident consectetur at? Iure numquam quas dolorum ipsum dolores
      //   doloribus cupiditate adipisci repellendus quod molestias, magni est
      //   accusamus iusto atque, nisi a quisquam repudiandae perferendis!
      //   Accusantium odit quos nam soluta exercitationem quod consectetur esse
      //   ullam ipsum facilis obcaecati eos animi itaque aspernatur ex error,
      //   placeat earum natus atque ipsa amet! Quis natus at dolorem, minus cum
      //   saepe vel inventore rem, quaerat totam deserunt laboriosam quos
      //   voluptate maiores nulla aspernatur debitis atque velit? Inventore ab
      //   odio atque odit quam ut nihil ipsa qui impedit, reprehenderit ratione
      //   asperiores, eos aspernatur repellendus obcaecati culpa possimus aliquid
      //   illum quae distinctio accusamus. Soluta aperiam distinctio incidunt
      //   ipsum mollitia?
      // </div>
      <div style={{ marginTop: 0, marginLeft: 15 }}>
        <FormControl
          component="fieldset"
          error={false}
          className={styles1.formControl}
        >
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={activeTab}
            onChange={handleTabChange}
            style={{
              display: "flex",
              flexDirection: "row",
              font: "normal normal 600 16px/22px Nunito !important",
            }}
          >
            <FormControlLabel
              value="docker_image"
              control={<Radio color="primary" size={"small"} />}
              label=" Upload Docker Image"
            />
            <FormControlLabel
              value="docker_file"
              control={<Radio color="primary" size={"small"} />}
              label=" Upload Docker File"
            />
          </RadioGroup>
        </FormControl>
        <div
          className={`${styles1.documentDropperWrapper} ${
            DroppedDocumentError &&
            droppedDocument.length === 0 &&
            styles1.ErrordocumentDropperWrapper2
          }`}
        >
          {/* <DropzoneArea
                      Icon={customdropzoneImage}
                      className={styles.documentDropperClass}
                      showPreviews={false}
                      filesLimit={1}
                      maxFileSize={500000000}
                      dropzoneText="Drag and drop or click to browse here"
                      dropzoneClass="documentDropperClass"
                      dropzoneParagraphClass="documentDropperTextClass"
                      get
                      showAlerts={true}
                      showPreviewsInDropzone={false}
                      onDrop={() => setLoading(true)}
                      onChange={(files) => {
                        setLoading(false);
                        handleUpload(files);

                      }}
                    /> */}
          <FileDropperWithLayoutForDockerImage
            nameError={nameError}
            imageDetails={imageDetails}
            setnameError={setnameError}
            error={error}
            handleChange={handleChange}
            handleUpload={handleUpload}
            setLoading={setLoading}
            handleImageNameChange={handleImageNameChange}
            activeTab={activeTab}
            setTagError={setTagError}
            tagError={tagError}
            handleDeleteClick={handleDeleteClick}
            setError={setError}
            DockerImagedata={DockerImagedata}
            setDockerImageData={setDockerImageData}
            filesLimit={filesLimit}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            documentsError={documentsError}
            setDocumentsError={setDocumentsError}
          />
        </div>
        {DroppedDocumentError && droppedDocument.length === 0 && (
          <p className={styles1.errorMsg} style={{ marginBottom: 7 }}>
            {"File is required"}
          </p>
        )}
        {/* <p className={styles.fieldHeading}>Maximum file size 500 MB</p> */}
        {/* {droppedDocument.length > 0 && (
                    <div className={classes.doc_container}>
                      <div className={classes.group1}>
                        <text className={classes.label}>
                          {activeTab === "docker_file"
                            ? `Docker File Name`
                            : `Docker Image Name`}
                          <span className={styles.red}>*</span>
                        </text>
                        <input
                          className={`${classes.value} ${
                            nameError && classes.error
                          }`}
                          value={imageDetails["imagename"]}
                          placeholder={
                            activeTab === "docker_file"
                              ? `Enter Docker File Name`
                              : `Enter Docker Image Name`
                          }
                          onChange={handleImageNameChange}
                        ></input>
                        {nameError && (
                          <p className={classes.errorMsg}>
                            {activeTab === "docker_file"
                              ? `Docker File Name is required`
                              : `Docker Image Name is required`}
                          </p>
                        )}
                      </div>
                      <div className={classes.group1}>
                        <text className={classes.label}>
                          {activeTab === "docker_file"
                            ? `Docker File Tag`
                            : `Docker Image Tag`}
                          <span className={styles.red}>*</span>
                        </text>
                        <input
                          // className={classes.value}
                          className={`${classes.value} ${
                            tagError && classes.error
                          }`}
                          type="text"
                          name="tag"
                          onChange={handleChange}
                          placeholder={
                            activeTab === "docker_file"
                              ? `Enter Docker File Tag`
                              : `Enter Docker Image Tag`
                          }
                        ></input>
                        {tagError && (
                          <p className={classes.errorMsg}>
                            {activeTab === "docker_file"
                              ? `Docker File Tag is required`
                              : `Docker Image Tag is required`}
                          </p>
                        )}
                      </div>
                      <span
                        style={{
                          marginLeft: 23,
                          marginTop: 25,
                          color: "black",
                        }}
                        onClick={handleDeleteClick}
                      >
                        <img
                          src="/images/deleteLine.svg"
                          height={18}
                          width={18}
                          color="#000000"
                          alt="icon"
                        />
                      </span>
                    </div>
                  )} */}
      </div>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === "FALSE" && styles.editMode}`}
              value={DockerImagedata[field?.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={DockerImagedata[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea}  ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                DockerImagedata[field.datakey]
                  ? DockerImagedata[field.datakey]
                  : ""
              }
              onChange={handleChange}
              className={`${styles.select} ${
                error[field.datakey]?.isError && styles.error
              } `}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                DockerImagedata[field.datakey]
                  ? DockerImagedata[field.datakey]
                  : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={field.editable === "FALSE"}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === "FALSE" && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  DockerImagedata[`${field.datakey}_unit`]
                    ? DockerImagedata[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={DockerImagedata[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  DockerImagedata[`${field.datakey}_unit`]
                    ? DockerImagedata[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={DockerImagedata[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                DockerImagedata[field.datakey]
                  ? DockerImagedata[field.datakey]
                  : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "DockerImage_Header_section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (section.name) {
                              case "DockerImage_FileUpload":
                                return dockerImageFileUpload();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            switch (section.name) {
                              case "DockerImage_FileUpload":
                                return dockerImageFileUpload();
                              default:
                                return (
                                  <Grid item xs={12} sm={12} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                // layout withoutTextArea
                <Grid container spacing={3} key={fieldgroup._id}>
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (section.name) {
                        case "DockerImage_FileUpload":
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                        default:
                          return (
                            <Grid item xs={4} sm={4} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default CreateDockerImageEntity;
