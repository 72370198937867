import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editComponent } from "../../redux/actions/uiApplicationAction";

const useGetData = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.uiApplicationReducer);
  const currentPage = currentProject?.pages?.find(
    (page) => page.url === params?.page
  );
  const currentAction = currentProject?.actions?.find(
    (action) => action.isActive
  );
  const currentComponent = currentPage?.components?.find(
    (comp) => comp?.isSelected
  );

  const getComponent = (componentId) => {
    const component =
      currentPage?.components?.length &&
      currentPage?.components?.find((comp) => comp.id === componentId);
    return component;
  };
  const getSelectedPage = (pageId) => {
    const page =
      currentProject?.pages?.length &&
      currentProject?.pages?.find((page) => page.id === pageId);
    return page;
  };

  const getDataValue = (dataInput) => {
    var curlyRegex = /\{\{([^)]+)\}\}/;
    var finalInput = curlyRegex.exec(dataInput);
    const inputs = finalInput && finalInput[1].split(".");
    if (inputs && inputs?.length && inputs[0] === "actions") {
      const action = currentProject?.actions.find(
        (action) => action.name === inputs[1]
      );
      if (action) {
        let finalData = action;
        inputs?.splice(0, 2);
        inputs &&
          inputs.forEach((item) => {
            var squareRegex = /\[([^)]+)\]/;
            var index = squareRegex.exec(item);
            if (index) {
              finalData =
                finalData?.[item.split("[")?.[0]]?.[parseInt(index[1])];
            } else {
              finalData = finalData?.[item];
            }
          });
        return finalData ? finalData : "";
      } else {
        throw new Error(`${inputs[1]} not defined`);
      }
    } else {
      return dataInput;
    }
  };

  const getAccessDataValue = (dataInput) => {
    // var curlyRegex = /\{\{([^)]+)\}\}/;
    // var finalInput = curlyRegex.exec(dataInput);
    const inputs = dataInput.split(".");
    if (inputs && inputs?.length && inputs[0] === "actions") {
      const action = currentProject?.actions.find(
        (action) => action.name === inputs[1]
      );
      if (action) {
        let finalData = action;
        inputs?.splice(0, 2);
        inputs &&
          inputs.forEach((item) => {
            var squareRegex = /\[([^)]+)\]/;
            var index = squareRegex.exec(item);
            if (index) {
              finalData =
                finalData?.[item.split("[")?.[0]]?.[parseInt(index[1])];
            } else {
              finalData = finalData?.[item];
            }
          });
        return finalData ? finalData : "";
      } else {
        throw new Error(`${inputs[1]} not defined`);
      }
    } else {
      return dataInput;
    }
  };

  const executeJavascript = (dataValue) => {
    try {
      let finalDataValue = dataValue;
      var curlyRegex = /\{\{([^)]+)\}\}/;
      var finalInput = curlyRegex.exec(dataValue);
      if (finalInput) {
        finalDataValue = dataValue.replace(
          finalInput[0],
          JSON.stringify(getDataValue(finalInput[0]))
        );
      }
      var func = new Function(finalDataValue)();
      return func;
    } catch (error) {
      throw error;
    }
  };

  const globalHandleChange = (value, key, setError) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [key]: {
            dataInput: value,
            dataValue: dataValue,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const globalAcessModeChange = (value, key, setError) => {
    let dataValue = [];
    try {
      dataValue = getAccessDataValue(value);
      setError("");
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [key]: {
            dataInput: value,
            dataValue: dataValue,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const NormalHandleChange = (key, value) => {
    const components = currentPage.components.map((component) => {
      if (component.name === currentComponent.name) {
        return {
          ...component,
          [key]: value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  const updatePageForLinking = (pagesToModify) => {
    let myComps = currentPage?.components?.map((comp) => {
      if (currentProject?.id === comp?.uiApplication?.id) {
        return {
          ...comp,
          uiApplication: {
            ...comp?.uiApplication,
            pages: pagesToModify,
          },
        };
      } else {
        return comp;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: myComps,
      })
    );
  };

  return {
    currentProject,
    currentPage,
    currentAction,
    currentComponent,
    getSelectedPage,
    updatePageForLinking,
    getComponent,
    getDataValue,
    executeJavascript,
    globalHandleChange,
    globalAcessModeChange,
    getAccessDataValue,
    NormalHandleChange,
    getSelectedPage,
  };
};

export default useGetData;
