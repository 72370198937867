import React, { useState, useEffect } from "react";
import layoutStyles from "../../../styles/commonStyles/Layout";
import {
  Divider,
  Grid,
  Typography,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Topbar from "./Topbar";
import ActionHelper from "./helper/ActionHelper";
import { useSnackbar } from "notistack";
import ApplicationHelper from "./helper/ApplicationHelper";
import { useDispatch } from "react-redux";
import {
  editAction,
  editUiApplication,
} from "../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../hooks/useGetData";
import { AppButton } from "../../atoms/button/AppButton";
import { UiApplicationService } from "../../../services/UiApplicationService";
const Action = () => {
  const styles = layoutStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentAction, currentProject, currentComponent } = useGetData();
  const apiService = new UiApplicationService();
  const [changedActionName, setChangedActionName] = useState();
  const [nameError, setNameError] = useState(false);

  const handleActionUpdate = async () => {
    try {
      const resp = await apiService.updateAction(
        currentAction?.uiappid,
        currentAction?._id,
        {
          ...currentAction,
          name: changedActionName,
        }
      );
      if (resp?.status === 201) {
        statusSnackBar(resp?.data?._msg, "success");
      }
    } catch (err) {
      statusSnackBar(err?.response?.data?._msg, "error");
    }
  };

  const handleChangeActionName = () => {
    let error = false;
    const pages = currentProject.pages.map((page) => {
      return {
        ...page,
        components: page.components.map((component) => {
          let tempComp = {};
          if (
            component?.actionName === changedActionName &&
            component?.actionName !== currentComponent?.actionName
          ) {
            error = true;
            tempComp = component;
          } else {
            if (component?.data?.dataInput?.indexOf(currentAction?.name) >= 0) {
              let newActionName = component?.data?.dataInput?.replace(
                currentAction?.name,
                changedActionName
              );
              tempComp = {
                ...component,
                data: {
                  ...component?.data,
                  dataInput: newActionName,
                },
              };
            } else {
              tempComp = component;
            }
          }
          setNameError(error);
          return tempComp;
        }),
      };
    });
    if (!error) {
      if (
        !currentProject?.actions?.some((ele) => ele?.name === changedActionName)
      ) {
        dispatch(
          editAction({
            id: currentAction?._id,
            data: { name: changedActionName },
          })
        );
        dispatch(editUiApplication({ data: { pages: pages } }));
      }

      handleActionUpdate();
    }
  };

  const statusSnackBar = (msg, statusVariant) => {
    enqueueSnackbar(msg, {
      variant: statusVariant,
    });
  };

  const handleChange = (event) => {
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          ...currentAction,
          actionType: event?.target?.name,
          data: {},
        },
      })
    );
  };

  const childToParent = (childData) => {
    setChangedActionName(childData);
  };

  useEffect(() => {
    setChangedActionName(currentAction?.name);
    setNameError(false);
  }, [currentAction]);

  return (
    <div
      className={styles.playgroud}
      style={{ backgroundColor: "#fff", width: `calc(100vw -  529px)` }}
    >
      <Grid container direction="column">
        <Grid item container style={{ paddingRight: 14 }}>
          <Topbar childToParent={childToParent} />
        </Grid>
        <Divider />
        <Grid
          item
          style={{
            paddingLeft: 14,
            paddingTop: 14,
            marginBottom: 10,
            maxWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography className={styles.sectionHeading}>
              Create an Action from
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="action"
                value={currentAction?.actionType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="service"
                  control={<Radio color="primary" />}
                  label="Service"
                  name="service"
                />
                <FormControlLabel
                  value="application"
                  control={<Radio color="primary" />}
                  label="Service Bundles"
                  name="application"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <AppButton
            buttonName="Save"
            variant="contained"
            style={{ margin: "0px 18px", minWidth: 50 }}
            startIcon={<img src="/img/Save.svg" alt="Save" />}
            className="btnsmall"
            onClick={() => {
              if (changedActionName !== currentAction?.name) {
                handleChangeActionName();
              } else {
                handleActionUpdate();
              }
            }}
          />
        </Grid>
        {currentAction?.actionType === "service" ? (
          <ActionHelper />
        ) : (
          <ApplicationHelper />
        )}
      </Grid>
    </div>
  );
};

export default Action;
function uuidv4() {
  throw new Error("Function not implemented.");
}
