import {
    Grid,
    FilledInput,
    InputAdornment,
    IconButton,
    useTheme,
    Avatar,
    Typography,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } from "@material-ui/core";
  import { useHistory, useLocation } from "react-router-dom";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useSnackbar } from "notistack";
import { DocumentServices } from "../../../services/documentServices";
import { Autocomplete } from "@material-ui/lab";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { handleValidation } from "../../../UiApplication/utils/Validation";
import CreateAndDetailsScreenStyle from "../../CommonStyles/CreateAndDetailsScreenStyle";
import Loader from "../../../UiApplication/components/atoms/loader/Loader";
import { fetchDocumentById, editDocumentMeta } from "../../../utils/project/documentUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
  
  
  export default function EditDocuement(props) {
    const classes = CreateAndDetailsScreenStyle();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const documentId = useState(props.match.params._id);
    const { checkCompPermission } = useCheckPermission();
    const styles = manageScreenStyles();
  
    // const apiService = new DocumentServices();
  
    const [documentData, setDocumentData] = useState({});
    const [loading, setLoading] = useState(false);
    const [statusOpen, setStatusOpen] = useState(false);
    const [statuses, setStatuses] = useState([]);
     
    const theme = useTheme();
    const { isEditMode } = props;
  
    const { checkScreenPermission } = useCheckPermission();

    const dispatch = useDispatch();


    const fetchStatusesByResource = async (resource, callback) => {
        const { _msg, _status, data } = await fetchStatusResourceByName(resource);
        if (_status === 200) {
          callback(data);
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
    };

    // useEffect(()=>{
      
    // },[])
  
    useEffect(() => {
      if (isEditMode) {
        checkScreenPermission("Document", ["Edit"]);
      } else {
        checkScreenPermission("Document", ["Read"]);
      }
      const getDocumentByID = async () => {
        try {
          setLoading(true);
          const resp = await fetchDocumentById(documentId[0]);
          if (resp._status === 200) {
            setDocumentData(resp?.data)
            enqueueSnackbar("Document fetched successfully", {
              variant: "success",
            });
            setLoading(false);
          } else {
            enqueueSnackbar("Unable to fetch document", {
              variant: "error",
            });
            setLoading(false);
          }
        } catch (err) {
          // console.log(err.response);
        }
      };
      getDocumentByID();

      if (checkCompPermission("StatusProfile", ["List All"])) {
        fetchStatusesByResource("Document", (data) =>
          setStatuses(data ? data.status : [])
        );
      }
    }, []);
  
    const [error, setError] = useState({
      status: false,
    });
  

    const handleSubmit = async () => {
      const { formIsValid, errors } = handleValidation(documentData?.status, error);
      if (formIsValid) {
        const data = {
          status: documentData?.status,
        };
        setLoading(true)
        const resp = await editDocumentMeta(documentData?._id, data);
        if (resp?._status === 201) {
          enqueueSnackbar("Document Updated Succesfully", {
            variant: "success",
          });
          setLoading(false);
          history.push(`/documents/details/${documentData?._id}`);
        } else {
          setLoading(false);
          enqueueSnackbar(
            "Unable to process your request, please try after sometime",
            {
              variant: "error",
            }
          );
        }
      } else {
        // @ts-ignore
        setLoading(false);
        setError(errors);
      }
    };
  
    return (
      <main className={classes.main}>
        {loading && <Loader />}
        <div className={classes.topContent}>
          <BreadCrumbs
            items={[
                { title: "Documents", path: "/documents" },
                {
                  title: isEditMode
                    ? `Edit ${documentData.docName}`
                    : documentData.docName,
                },
              ]}
          />
          <Grid container alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <h2 className={classes.title}>
                {isEditMode ? "Edit Document" : "Document Details"}
              </h2>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {isEditMode ? (
                <div className={classes.buttons}>
                    <AppButton
                        color="primary"
                        buttonName="Save"
                        variant="contained"
                        style={{ margin: "unset"}}
                        className="btnsmall"
                        onClick={handleSubmit}
                        disabled={!checkCompPermission("Document", ["Edit"])}
                    />
                    <AppButton
                        color="primary"
                        buttonName="Cancel"
                        variant="outlined"
                        disabled={false}
                        style={{ margin: "unset", marginLeft: "10px"  }}
                        className="btnsmall"
                        onClick={() => history.goBack()}
                    />  
                </div>
              ) : (
                <div className={classes.buttons}>
                  <AppButton
                    color="primary"
                    buttonName="Edit"
                    variant="contained"
                    // startIcon={<img src="/images/edit.svg" alt="card" />}
                    style={{ padding: "6px 15px"}}
                    className="btnsmall"
                    onClick={() =>
                      history.push(`/documents/edit/${documentData?._id}`)
                    }
                    // disabled={!checkCompPermission("UIApplication", ["Edit"])}
                  />
                  <AppButton
                      color="primary"
                      buttonName="Close"
                      variant="outlined"
                      disabled={false}
                      style={{ margin: "unset", marginLeft: "10px"  }}
                      className="btnsmall"
                      onClick={() => history.push('/documents')}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classes.border_line} />
        </div>
        <div className={classes.wrapper}>
           
             
              <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <label className={classes.fieldHeading}>
                    Document Name <span className={classes.red}>*</span>
                  </label>
                  <br />
                  <input
                    name="docName"
                    type="text"
                    // placeholder="Enter Application Name"
                    value={documentData?.docName}
                    className={`${classes.input} ${styles.editMode} ${error.name && classes.error}`}
                    // onChange={handleChange}
                    disabled={true}
                  />
                  {/* {error.name && (
                    <p className={classes.errorMsg}>
                      {"Application Name is required"}
                    </p>
                  )} */}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <label className={classes.fieldHeading}>
                    Version <span className={classes.red}>*</span>
                  </label>
                  <br />
                  <input
                    name="version"
                    type="text"
                    // placeholder="Enter Image Name"
                    value={documentData?.version}
                    className={`${classes.input} ${styles.editMode} ${error.name && classes.error}`}
                    // onChange={handleChange}
                    disabled={true}
                  />
                  {/* {error.imagename && (
                    <p className={classes.errorMsg}>{"I is required"}</p>
                  )} */}
                  {/* {error.imageNameFirstLetterLow && (
                    <p className={classes.errorMsg}>
                      {"Image Name should start with lowercase letter"}
                    </p>
                  )} */}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <label className={classes.fieldHeading}>
                    Document Type <span className={classes.red}>*</span>
                  </label>
                  <br />
                  <input
                    name="documenttype"
                    type="text"
                    // placeholder="Enter Image Tag"
                    value={documentData?.name}
                    className={`${classes.input} ${styles.editMode} ${error.name && classes.error}`}
                    // onChange={handleChange}
                    disabled={true}
                  />
                  {/* {error.imagetag && (
                    <p className={classes.errorMsg}>{"Image Tag is required"}</p>
                  )} */}
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={12}>
                  <Accordion 
                    className={styles.fieldPanel}
                    defaultExpanded
                    >
                    <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded
                        className={styles.accordianIcon}
                      /> }
                    >
                      <Typography className={styles.sectionHeading}>Status</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                      {isEditMode && (
                          <>
                              <Autocomplete
                              open={statusOpen}
                              onOpen={() => setStatusOpen(true)}
                              onClose={() => setStatusOpen(false)}
                              options={statuses}
                              getOptionLabel={(option) => option.status}
                              className={`${styles.searchInput2} ${!isEditMode && styles.editMode} ${
                                  error.status?.isError && styles.errorAuto
                              }`}
                              onChange={(e, input) => {
                                  if (input) {
                                  setDocumentData({
                                      ...documentData,
                                      status: input.status,
                                  });
                                  }
                              }}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  variant="standard"
                                  label="Search Status"
                                  placeholder="Search Status"
                                  />
                              )}
                              />
                              {error.status?.isError && (
                              <p className={styles.errorMsg}>{"Status is Required"}</p>
                              )}
                          </>
                          )}

                          {documentData?.status ? (
                          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
                              <div
                              style={{
                                  backgroundColor: statuses.find(
                                  (s) => s.status === documentData?.status
                                  )?.color,
                                  height: 15,
                                  width: 15,
                                  borderRadius: "50%",
                                  position: "relative",
                                  right: 10,
                              }}
                              ></div>
                              <Typography
                              variant="h6"
                              className={styles.fieldText}
                              style={{
                                  color: statuses.find((s) => s.status === documentData?.status)
                                  ?.color,
                              }}
                              >
                              {statuses
                                  .find((s) => s.status === documentData?.status)
                                  ?.status.charAt(0)
                                  .toUpperCase() +
                                  statuses
                                  .find((s) => s.status === documentData?.status)
                                  ?.status.slice(1)}
                              </Typography>
                          </div>
                          ) : (
                          <Typography variant="h6" style={{marginTop:"10px"}} className={styles.fieldText}>
                              Status is not set
                          </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
             
        </div>
      </main>
    );
  }
  