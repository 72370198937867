import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  main: {
    marginTop: "4rem",
    // border : '1px solid'
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  topContentItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // border : '1px solid'
  },
  topContentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // border : '1px solid'
  },
  title: {
    fontSize: 21,
    color: "#161616",
    // border : '1px solid'
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    paddingLeft: "1rem",
    // paddingBottom: "1rem",
    "&::before": {
      borderBottom: "unset",
    },
  },
  wrapper: {
    position: "relative",
    top: "6.8rem",
    marginLeft: "2rem"
    // border : '1px solid'
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  fieldHeading: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: 0,
    color: "#000000",
  },

  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    // height: "80%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
    outline: 'none',
  },

  //serives
  searchwrapper: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    width: "22rem",
    "& div": {
      "& .MuiIconButton-label": {
        paddingTop: "1rem"
      }
    }
  },

  searchinput: {
    width: "95%",
    height: 37,
    // backgroundColor: "#fff",
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
  },
  dropdowns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  value: {
    height: 38,
    width: 355,
    borderWidth: 1,
    borderColor: "#DEDEDF",
    borderRadius: 5,
    backgroundColor: "white",
  },
  icon: {
    position: "relative",
    left: 150,
  },
  formControl: {
    width: 204,
    height: 55,
    backgroundColor: "#fff",
    // border: "1px solid #DEDEDF",
    borderRadius: 5,
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  submitbuttonwrapper: {
    position: "fixed",
    backgroundColor: "#F5F7FA",
    zIndex: 100,
    right: "4rem",
    top: "7.5rem"
  },

  //   table
  myTable: {
    display: "flex",
    marginTop: "8rem",
    height: "23rem",
    marginLeft: "2rem",
    marginRight: "1.5rem"
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    height: 52
  },

  // process table
  processTable: {
    marginTop: "1rem",
    width: "50%",
    height: 400,
  },
  pageContent: {
    boxShadow: "none",
    marginTop: "4rem",
    // position: "relative",
    // top: "8rem",
  },
  services: {
    marginTop: 10,
  },
  project_list: {
    marginLeft: 400,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: 'blue',
  },
  service_group_list: {
    marginLeft: 10
  },
  pagination: {
    display: "flex",
    marginTop: "1rem",
    position: "absolute",
    right: 20,
  },
  error_text: {
    fontSize: 10,
    fontFamily: "Nunito",
    color: "red",
    marginTop: 5
  },
  star: {
    color: "red"
  },
  nodata: {
    height: "13rem",
    position: "sticky",
    left: "45%",
    top: "5rem",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
}));
