import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
  FilledInput,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { AppButton } from "../../../components/common/Button";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import manageScreenStyles from "../../../screens/CommonStyles/manageScreenStyles";
import listStyles from "../../../screens/CommonStyles/listScreenStyles";

// Redux
import { connect } from "react-redux";
import {
  fetchTagById,
  fetchTagResources,
  deleteTag,
} from "../../../redux/actions/dataStandardAction";

export const useStyles = makeStyles(() => ({
  modalContent: {
    marginTop: "20px",
    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item p": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#000000",
      opacity: "1",
    },
  },

  modalDsTitle: {
    marginTop: "20px",
    font: "normal normal 600 14px/19px Nunito",
    color: "#000000",
    "& label": {
      opacity: "0.5",
    },
    "& p": {
      color: "#000000",
      opacity: "1",
    },
  },

  modalDsContainer: {
    background: "#F3F3F3 0% 0% no-repeat padding-box",
    borderRadius: "7px",
    opacity: "0.5",
    height: "74px",
    alignItems: "center",
    paddingLeft: "20px",

    "& .MuiGrid-item label": {
      font: "normal normal normal 16px/22px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
    "& .MuiGrid-item span": {
      font: "normal normal 600 16px/22px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
  },

  modalData: {
    margin: "30px 0 30px 0",
    textAlign: "center",
    background: "#F3F3F399 0% 0% no-repeat padding-box",
    borderBottom: "1px solid #F7F7F7",
    // opacity: "0.5",
    height: "41px",

    "& .MuiGrid-item": {
      marginTop: "10px",
    },

    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.4",
      height: "19px",
    },

    "& .MuiGrid-item span": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.8",
      height: "19px",
    },
  },

  modalElement: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  },

  modaltab: {
    marginTop: "5px",
    width: "35rem",
    textAlign: "center",
    font: "normal normal bold 14px/18px Nunito",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
    textTransform: "capitalize",
    "& .MuiTab-root": {
      textTransform: "capitalize",
    },
    "& .Mui-selected": {
      font: "normal normal 600 14px/19px Nunito",
      borderRadius: "5px",
    },
  },

  modalTitle: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: "0px",
    color: "#222725",
    opacity: "1",

    "& .MuiButton-root": {
      textTransform: "capitalize",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000014",
      border: "1px solid #002855",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",
    },
  },

  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
  customModal: {
    maxWidth: "796px",
    margin: "0 auto",
    // display: "flex",
    // justifyContent: "center",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },

  modalTableContainer: {
    height: "300px",
    marginLeft: "12px",
    border: "1px solid #DEDFDF",
  },

  modelFooter: {
    height: "64px",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",

    "& .MuiButton-root": {
      width: "110px",
      height: "40px",
      font: "normal normal 600 14px/19px Nunito",
      textTransform: "capitalize",
      background: "#002855 0% 0% no-repeat padding-box",
      opacity: "1",
    },
  },

  modalTable: {
    "& thead": {
      background: "#002855 0% 0% no-repeat padding-box",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",

      "& th": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        textTransform: "capitalize",
        color: "#000000",
        opacity: "1",
      },

      "& tr": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        color: "#000000",
      },
    },
    "& tbody": {
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito Sans;",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.8",
      },
    },
  },
}));

const FieldDetailModal = (props) => {
  const { tagResources, fetchTagResources, fetchTagById, deleteTag } = props;
  const currentTag = props.row;
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const styles = useStyles();
  const manageStyle = manageScreenStyles();
  const listListStyle = listStyles();

  useEffect(() => {
    if (props.openModal) fetchTagResources(currentTag._id);
  }, [props.openModal]);

  const handleChangeTab = (event, newValue) => setTab(newValue);

  const handleDelete = async () => {
    await deleteTag(props.row._id);
    props.setOpenModal(false);
  };

  const handleEditClick = async () => {
    await fetchTagById(props.row._id);
    history.push("/edit-glossary");
  };

  return (
    <Dialog
      // onEntering={getData}
      className={styles.customModal}
      maxWidth="md"
      scroll="paper"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={styles.modalTitle}
        >
          Glossary details
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AppButton
              buttonName="delete"
              className="btnsmall"
              style={{ minWidth: "99px", marginLeft: "15px" }}
              startIcon={<DeleteIcon />}
              disabled={false}
              onClick={handleDelete}
            />
            <AppButton
              buttonName="Edit"
              variant="outlined"
              className="btnsmall"
              style={{ minWidth: "99px", marginLeft: "15px" }}
              startIcon={
                <img src="/images/editmodal.svg" alt={<EditOutlinedIcon />} />
              }
              disabled={false}
              onClick={handleEditClick}
            />
          </div>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3} className={styles.modalContent}>
          <Grid item xs={4}>
            <label htmlFor="">Name</label>
            <p>{currentTag.name}</p>
          </Grid>
          <Grid item xs={4}>
            <label htmlFor="">Business Key</label>
            <p>{currentTag.key}</p>
          </Grid>
          <Grid item xs={4}>
            <label htmlFor="">Business source</label>
            <p>{currentTag.source}</p>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} className={styles.modalDsTitle}>
            <label>Description</label>
            <p>{currentTag.description}</p>
          </Grid>
        </Grid>
        <Grid container className={styles.modalData}>
          <Grid item xs={6}>
            <label>
              Created by : <span>&nbsp;{currentTag["created By"]}</span>
            </label>
            <label>&nbsp;On</label> : <span>{currentTag["created On"]}</span>
          </Grid>
          <Grid item xs={6}>
            <label>
              Updated by : <span>&nbsp;{currentTag["updated By"]}</span>
            </label>
            <label>&nbsp;On</label> : <span>{currentTag["updated On"]}</span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <label htmlFor="" className={styles.modalElement}>
              {"Glossary related data elements"}
            </label>
          </Grid>
          <Grid item xs={3} sm={8} className={styles.modaltab}>
            {
              <Tabs
                value={tab}
                // onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  onClick={(e) => handleChangeTab(e, 0)}
                  label={`Services(${tagResources.services?.length || 0})`}
                />
                <Tab
                  onClick={(e) => handleChangeTab(e, 1)}
                  label={`Projects(${tagResources.projects?.length || 0})`}
                />
                <Tab
                  onClick={(e) => handleChangeTab(e, 2)}
                  label={`Payload(${tagResources.fieldGroups?.length || 0})`}
                />
              </Tabs>
            }
          </Grid>
          <Grid item xs={3} sm={4}>
            <FilledInput
              id="filled-adornment-password"
              name="filterfn"
              // onChange={handleSearch}
              className={listListStyle.input}
              placeholder={
                tab === 0
                  ? "Search Services"
                  : tab === 1
                  ? "Search Project"
                  : "Search Field groups"
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <TableContainer className={styles.modalTableContainer}>
            <Table stickyHeader className={styles.modalTable}>
              <TableHead>
                <TableRow>
                  {tab === 0 ? (
                    <>
                      <TableCell>ServiceName</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>ServiceGroup</TableCell>
                    </>
                  ) : tab === 1 ? (
                    <>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Path URL</TableCell>
                      <TableCell>Project Methods</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Field Group Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date Created</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody style={{ position: "relative" }}>
                {tab === 0 ? (
                  !tagResources.services ? (
                    <TableRow>
                      <TableCell>No Data</TableCell>
                    </TableRow>
                  ) : (
                    tagResources.services.map((item) => (
                      <TableRow>
                        <TableCell>
                          {item.name || item.resourceinstance}
                        </TableCell>
                        <TableCell>{item._id}</TableCell>
                        <TableCell>{item["created On"]}</TableCell>
                      </TableRow>
                    ))
                  )
                ) : tab === 1 ? (
                  !tagResources.projects ? (
                    <TableRow>
                      <TableCell>No Data</TableCell>
                    </TableRow>
                  ) : (
                    tagResources.projects.map((item) => (
                      <TableRow>
                        <TableCell>
                          {item.name || item.resourceinstance}
                        </TableCell>
                        <TableCell>{item._id}</TableCell>
                        <TableCell>{item["created On"]}</TableCell>
                      </TableRow>
                    ))
                  )
                ) : !tagResources.fieldGroups ? (
                  <TableRow>
                    <TableCell>No Data</TableCell>
                  </TableRow>
                ) : (
                  tagResources.fieldGroups.map((item) => (
                    <TableRow>
                      <TableCell>
                        {item.name || item.resourceinstance}
                      </TableCell>
                      <TableCell>{item._id}</TableCell>
                      <TableCell>{item["created On"]}</TableCell>
                    </TableRow>
                  ))
                )}
                {tagResources.loading && (
                  <CircularProgress className={styles.loader} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.modelFooter}>
        <AppButton
          style={{ marginRight: "5px" }}
          buttonName="Close"
          className="btnsmall"
          onClick={() => props.setOpenModal(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  fetchTagById,
  deleteTag,
  fetchTagResources,
};

const mapStateToProps = (state) => {
  const { tagResources } = state.dataStandardReducer;
  return { tagResources };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldDetailModal);
