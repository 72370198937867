import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";

const CustomStyles = makeStyles((theme) => ({
  cancel: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    border: "1px solid #052855",
    borderRadius: 5,
    opacity: 1,
    color: "#052855",
  },
  submitMember: {
    background: "#052855 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      background: "#052855 0% 0% no-repeat padding-box",
    },
  },
}));

export default function LockModal(props) {
  const { row, open, handleClose, handlelock } = props;
  // const styles = CustomStyles();
  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          <div
            style={{
              height: 170,
              width: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src="/images/lock.svg"
                alt="lock"
                height="76px"
                width="76px"
                style={{ opacity: "1" }}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p>
                Are you sure you want to <b>Lock</b> the {row.name} ?
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName="Cancel"
            variant="outlined"
          />
          <AppButton
            onClick={handlelock}
            className={`btnsmall`}
            buttonName={"Yes, Lock it"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
