import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
import SectionEditTree from "../../../components/Ontology/SectionEditTree";
import NotFoundContent from "../../../screens/Errors/NotFoundContent";

export default function DependencyModal(props) {
  const { open, handleClose, treeDataArr, modalTitle } = props;

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
        <DialogTitle id="responsive-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>
          {treeDataArr?.length !== 0 ? (
            <SectionEditTree
              treeData={treeDataArr}
              onTreeChange={() => void 0}
              disabled={true}
            />
          ) : (
            <NotFoundContent />
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName="Close"
            variant="outlined"
          />
          {/* <AppButton
            onClick={handleDelete}
            className={`btnsmall`}
            buttonName={"Yes, Delete"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          /> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
