import { useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";
import useGetData from "../../../../hooks/useGetData";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";

function ToggleSettings() {
  const dispatch = useDispatch();
  const {
    currentPage,
    currentComponent,
    currentProject,
    executeJavascript,
    getDataValue,
  } = useGetData();
  const [error, setError] = useState({ data: "", title: "" });
  const viewTypes = ["Web", "Tablet", "Mobile"];

  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };

  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError({ ...error, data: "" });
      } else {
        dataValue = getDataValue(value);
        setError({ ...error, data: "" });
      }
    } catch (error) {
      setError({ ...error, data: error?.message });
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          data: {
            dataInput: value,
            dataValue: value === currentComponent?.resetValue,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleSetResetValueChange = (value, key) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [key]: value,
          data: {
            ...component?.data,
            dataInput: value,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <JSDataInput
          value={currentComponent?.data}
          onChange={handleChange}
          label="Value"
          title={`${currentComponent?.name} : Value`}
          error={error?.data}
        />
      </div>
      <div className="field">
        <label htmlFor="setValue">Set Value</label>
        <input
          type="text"
          value={currentComponent?.setValue}
          name="setValue"
          onChange={(e) =>
            handleSetResetValueChange(e.target.value, "setValue")
          }
        />
      </div>
      <div className="field">
        <label htmlFor="resetValue">Reset Value</label>
        <input
          type="text"
          value={currentComponent?.resetValue}
          name="resetValue"
          onChange={(e) =>
            handleSetResetValueChange(e.target.value, "resetValue")
          }
        />
      </div>
    </div>
  );
}

export default ToggleSettings;
