import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Styles from "../../../styles/commonStyles/DownloadModal";
import { BASE_URL } from "../../../services/helper/config";
import { useSnackbar } from "notistack";
import { downLoadDynamicBuild } from "../../../services/UiApplicationApiUtils";
import { ApiHelper } from "../../../../services/helper/ApiHelper";
import { DeploymentProcessService } from "../../../../services/deploymentProcessService";
import { useState } from "react";
import Loader from "../../../../components/common/stuff/Loader";

const DownloadModal = (props) => {
  const classes = Styles();
  const { enqueueSnackbar } = useSnackbar();
  const apiHelper = new ApiHelper();
  const deploymentProcessService = new DeploymentProcessService();
  const [isLoader, setIsLoader] = useState(false);

  const handleDownloadAndroidApkOrIOS = async (device) => {
    setIsLoader(true);
    const URI = await fetchDeploymentEndPoint();
    const androidBody = {
      uri: URI,
      appname: props?.applicationName,
      packagename: "com.kailash." + props?.applicationName,
      icon: props?.applicationIcon,
      appIcon: props?.applicationIconName,
    };
    const iosBody = {
      url: URI,
      displayname: props?.applicationName,
      appvalue: "com.kailash." + props?.applicationName,
      icon: props?.applicationIcon,
      appIcon: props?.applicationIconName,
    };
    const response = await fetch(
      `${BASE_URL}/api/container/generatebuilds/${device}`,
      {
        method: "POST",
        headers: {
          token: props.userData?.accesstoken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(device === "ios" ? iosBody : androidBody),
      }
    ).catch((err) => console.log("err :>> ", err));
    if (response?.status === 200) {
      let blob = await response.blob();
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${props?.applicationName}.${
        device === "ios" ? "zip" : "apk"
      }`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsLoader(false);
      props.handleClose();
    } else {
      enqueueSnackbar("Unable to download the Application file", {
        variant: "error",
      });
      setIsLoader(false);
    }
  };

  const handleClick = async (device) => {
    setIsLoader(true);
    const response = await fetch(
      `${BASE_URL}/api/v1.0.0/ui/applications/${props?.applicationId}/download`,
      { headers: { token: props.userData?.accesstoken } }
    );
    if (response?.status === 200) {
      let blob = await response.blob();
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${props?.applicationName}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsLoader(false);
      props.handleClose();
    } else {
      const { _msg } = await downLoadDynamicBuild(props?.applicationId);
      enqueueSnackbar(_msg, { variant: "error" });
      setIsLoader(false);
    }
  };

  const fetchDeploymentEndPoint = async () => {
    try {
      const resp = await apiHelper.get(
        `${BASE_URL}/api/v1.0.0/k8s/deployments?&filters={"deptypeid":"${props?.applicationId}"}`
      );
      if (resp?.data?.data?.length) {
        const endPointData = await deploymentProcessService.getDeploymentById(
          resp?.data?.data[0]?._id
        );
        const parentEndPointNode =
          endPointData?.data?.data?.deploymentDetails.find(
            (depDetails) => depDetails?.Parent
          );
        return `http://${parentEndPointNode?.serdetails?.status?.loadBalancer?.ingress?.[0]?.hostname}:${parentEndPointNode?.Port}`;
      } else {
        enqueueSnackbar(
          `${props?.applicationName} UI Application is not deployed`,
          { variant: "error" }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog onClose={props.handleClose} open={props.open} maxWidth="md">
      {isLoader && <Loader />}
      <DialogTitle className={classes.modelTitle}>
        Download UI Application
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.modelBody}>
          <div className={classes.item} onClick={() => handleClick("desktop")}>
            <div>
              <img src="/img/desktop.svg" alt="Desktop" /> For Desktop
            </div>
            <span>Web</span>
          </div>
          <div
            className={classes.item}
            onClick={() => handleDownloadAndroidApkOrIOS("ios")}
          >
            <div>
              <img src="/img/ios.svg" alt="IOS" /> For iOS
            </div>
            <span>Mobile</span>
          </div>
          <div
            className={classes.item}
            onClick={() => handleDownloadAndroidApkOrIOS("android")}
          >
            <div>
              <img src="/img/android.svg" alt="Android" /> For Android
            </div>
            <span>Mobile</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;
