import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  JsModal: {
    border: "1px solid #D8D8D8",

    "& .CodeMirror": {
      height: "66vh",
    },
  },
  errorInput: {
    border: "1px solid #E22626 !important",
  },
  error: {
    font: "normal normal 600 12px/16px Nunito",
    color: "#DF0E0E",
    margin: 0,
    marginTop: 5,
  },
}));