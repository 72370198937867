import React, { useEffect, useState } from "react";
import ActionStyles from "../ActionStyles";
import {
  Divider,
  Grid,
  Tabs,
  Tab,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { editAction } from "../../../../../redux/actions/uiApplicationAction";
import Response from "../Response";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import useGetData from "../../../../hooks/useGetData";
import {
  fetchAPIListData,
  fetchVersionById,
} from "../../../../../utils/project/microserviceUtils";
import { fetchFieldGroupDetails } from "../../../../../utils/project/fieldgroupUtils";
import RenderConfigFields from "./CreateFields";
import { Autocomplete } from "@material-ui/lab";
import { getRuntimeEnvDetailsByID } from "../../../../../utils/containerMangement/runtimeEnvUtils";
// import renderConfigFields from './CreateFields';
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");
import { useSnackbar } from "notistack";
const ActionHelper = () => {
  const styles = ActionStyles();
  const dispatch = useDispatch();
  const [apiArray, setApiArray] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [actionObject, setActionObject] = useState({});
  const { currentAction, currentProject } = useGetData();
  const [containers, setContainers] = useState([]);
  const [showFieldsFlag, setShowFieldsFlag] = useState(false);

  const fetchruntimeENVdata = async (envID) => {
    const { _msg, _status, data } = await getRuntimeEnvDetailsByID(envID);
    if (_status === 200) {
      return data;
    } else {
      statusSnackBar(_msg, "error");
      return false;
    }
  };

  const statusSnackBar = (msg, statusVariant) => {
    enqueueSnackbar(msg, {
      variant: statusVariant,
    });
  };

  const handleChangeService = async (val) => {
    let containerId = "";
    let containerName = "";
    if (val?.serviceID && val?.versionID) {
      const versionData = await fetchVersionDetails(
        val?.serviceID,
        val?.versionID
      );
      containerName =
        versionData?.docker?.Instances?.[
          versionData?.docker?.Instances?.length &&
            versionData?.docker?.Instances?.length - 1
        ]?.containername;
      containerId =
        versionData?.docker?.Instances?.[
          versionData?.docker?.Instances?.length &&
            versionData?.docker?.Instances?.length - 1
        ]?.containerid;
    }
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          service: val == null ? {} : { ...val, containerName, containerId },
          api: {},
        },
      })
    );
  };
  const fetchVersionDetails = async (serviceId, versionId) => {
    const { _msg, _status, data } = await fetchVersionById(
      serviceId,
      versionId
    );
    if (_status === 200) {
      return data;
    } else {
      statusSnackBar(_msg, "error");
      return {};
    }
  };

  const handleChangeApi = async (apiObj) => {
    setShowFieldsFlag(true);
    let obj = {
      apiId: apiObj?._id,
      name: apiObj?.name,
      input: apiObj?.input,
      output: apiObj?.output,
      method: apiObj?.method,
      endpoint: apiObj?.path,
      headers: apiObj?.headers,
      pathParameters: apiObj?.pathParameters,
      queryParameters: apiObj?.queryParameters,
      type: apiObj?.type,
      microserviceID: apiObj?.microserviceID,
      versionID: apiObj?.versionID,
    };
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          api: apiObj === null ? {} : obj,
          config: {
            ...currentAction?.config,
            topic: apiObj?.topic,
            broker: apiObj?.broker,
            message: apiObj?.message,
          },
        },
      })
    );
    if (apiObj?.input?.fieldGroupid?.length > 0) {
      try {
        let { data } = await fetchFieldGroupDetails(apiObj.input.fieldGroupid);
        if (Object.keys(data).length > 0) {
          dispatch(
            editAction({
              id: currentAction?._id,
              data: {
                api: { fields: data?.fields, ...obj },
              },
            })
          );
        }
      } catch (error) {}
    }
    if (currentAction?.service?.environmentID) {
      const envData = await fetchruntimeENVdata(
        currentAction?.service?.environmentID
      );
      setContainers(envData.services ? envData.services : []);
    }
  };

  useEffect(() => {
    const getApis = async () => {
      try {
        let { data } = await fetchAPIListData(
          currentAction?.service?.serviceID,
          currentAction?.service?.versionID
        );
        if (data) {
          setApiArray(data);
        }
      } catch (error) {}
    };
    Object?.keys(currentAction?.service).length > 0 && getApis();
  }, [currentAction?.service]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (currentAction?.service?.environmentID) {
      const envData = await fetchruntimeENVdata(
        currentAction?.service?.environmentID
      );
      setContainers(envData.services ? envData.services : []);
    }
  }, []);

  const handleChangeCreateField = (e) => {
    setActionObject({
      ...actionObject,
      [e.target.name]: e.target.value,
    });
  };
  const onChange = (e) => {
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          ...currentAction,
          config: {
            ...currentAction?.config,
            [e.target.name]: e.target.value,
          },
        },
      })
    );
  };
  return (
    <Grid container style={{ padding: "0px 20px" }}>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <label htmlFor="" className={styles.fieldHeading}>
            Service {<span className={styles.red}>*</span>}
          </label>
          <Autocomplete
            id="create-action-service-dropdown"
            options={currentProject?.services}
            getOptionLabel={(option) => option.serviceName}
            value={currentAction?.service}
            className={`${styles.searchInput3}`}
            onChange={(event, newVal) => handleChangeService(newVal)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Service"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="" className={styles.fieldHeading}>
            Api {<span className={styles.red}>*</span>}
          </label>
          <Autocomplete
            id="create-action-api-dropdown"
            options={apiArray}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <>
                <span>{option?.name}</span>
                <span style={{ paddingLeft: 10, fontWeight: "bold" }}>
                  {option?.type}
                </span>
              </>
            )}
            value={currentAction?.api}
            className={`${styles.searchInput3}`}
            onChange={(event, newVal) => handleChangeApi(newVal)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Api"
              />
            )}
          />
        </Grid>
      </Grid>
      {Object?.keys(currentAction?.api)?.length > 0 &&
        currentAction?.api?.type !== "rest" && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Topic {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="topic"
                // value={nodeData[field.name]}
                value={currentAction.config["topic"]}
                // onChange={(e) => handleTextChange(field.name, e.target.value)}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Message {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="message"
                value={currentAction?.config["message"]}
                // onChange={(e) => handleTextChange(field.name, e.target.value)}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid>
            {/* <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Broker {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="broker"
                value={currentAction?.config['broker']}
                // onChange={(e) => handleTextChange(field.name, e.target.value)}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid> */}
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Runtime Environment {<span className={styles.red}>*</span>}
              </label>
              <Autocomplete
                options={containers}
                getOptionLabel={(option) => option.container_name}
                value={currentAction?.config["containerName"]}
                className={`${styles.searchInput3}`}
                onChange={(event, newVal) =>
                  onChange({ target: { name: "containerName", value: newVal } })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Container"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      {/* <Accordion
        className={styles.fieldPanel}
        defaultExpanded={true}
        // key={section._id}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownRounded className={styles.accordianIcon} />}
        >
          <Typography className={styles.sectionHeading}>
            {`${
              currentAction?.api?.type ? currentAction?.api?.type : 'Rest'
            } Configurations`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0px' }}>
          <Grid container spacing={1}>
            {RenderConfigFields(
              currentAction?.api?.type,
              actionObject,
              handleChangeCreateField
            )}
          </Grid>
        </AccordionDetails>
      </Accordion> */}
      <Grid container>
        {RenderConfigFields(
          currentAction?.api?.type,
          "service",
          actionObject,
          handleChangeCreateField
        )}
      </Grid>
    </Grid>
  );
};

export default ActionHelper;