import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    padding: "0 15px",
    height: "35px",

    "&::before": {
      borderBottom: "unset",
    },

    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
  },
}));
