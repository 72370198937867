import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import manageScreenStyles from "../../screens/CommonStyles/manageScreenStyles";
import { contentEncodingValues, contentMediaTypeValues } from "../../constants/DataStandard/Fields"

export default function FieldRules(props) {
    const { selectValue, rules, mode, predefined } = props;
    const { maxLength, minLength, multipleOf, contentEncoding, contentMediaType, minimum, maximum, pattern, exclusiveMinimum, exclusiveMaximum } = rules;
    const styles = manageScreenStyles();
    return (
        <Grid container spacing={3} style={{ marginTop: 0 }}>
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <label className={styles.fieldHeading2}>
                            Field Rules
                            <span className={styles.fieldMenu}>(optional)</span>
                        </label>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                        <button className={styles.fieldclearAll}
                            onClick={(e) => props.onClearAllClick(e)}
                            style={{ borderWidth: 0 }}
                            disabled={mode === "details"}>Clear all</button>
                    </Grid>
                </Grid>
                {selectValue !== "boolean" && <div className={styles.dropdownContent}>
                    {(selectValue === "number" || selectValue === "integer") && (<Grid container spacing={3}>
                        <Grid item xs={3} sm={3}>
                            <label className={styles.fieldHeading2}>Minimum</label>
                            <input step={1}
                                type="number"
                                value={minimum}
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Minimum"
                                onChange={(e) => props.onRuleChange("minimum", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <label className={styles.fieldHeading2}>Maximum</label>
                            <input step={1}
                                value={maximum}
                                type="number"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter MaxLength"
                                onChange={(e) => props.onRuleChange("maximum", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <label className={styles.fieldHeading2}>Multiple Of</label>
                            <input step={1}
                                value={multipleOf}
                                type="number"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter pattern"
                                onChange={(e) => props.onRuleChange("multipleOf", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                    </Grid>)}
                    {selectValue === "string" && (<Grid container spacing={3}>
                        <Grid item xs={3} sm={3}>
                            <label className={styles.fieldHeading2}>Minimum Length</label>
                            <input step={1}
                                value={minLength}
                                type="number"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Length"
                                onChange={(e) => props.onRuleChange("minLength", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <label className={styles.fieldHeading2}>Maximum Length</label>
                            <input step={1}
                                value={maxLength}
                                type="number"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Length"
                                onChange={(e) => props.onRuleChange("maxLength", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <label className={styles.fieldHeading2}>Pattern</label>
                            <input value={pattern}
                                type="text"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Pattern"
                                onChange={(e) => props.onRuleChange("pattern", e.target.value)}
                                disabled={mode === "details"}
                            />
                        </Grid>
                    </Grid>)}
                    {(selectValue === "number" || selectValue === "integer") && (<Grid container spacing={3} style={{ marginTop: 15 }}>
                        <Grid item xs={6}>
                            <label className={styles.fieldHeading2}>
                                Exclusive Minimum
                            </label>
                            <Select
                                onChange={(e) => props.onRuleChange("exclusiveMinimum", e.target.value)}
                                value={exclusiveMinimum}
                                className={`${styles.select} ${(mode === "details" || predefined) && styles.editMode}`}
                                inputProps={{ "aria-label": "Without label" }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                disabled={mode === "details"}
                            >
                                <MenuItem key={1}
                                    value={"true"}
                                    className={styles.fieldHeading2}>{"True"}</MenuItem>
                                <MenuItem key={2}
                                    value={"false"}
                                    className={styles.fieldHeading2}>{"False"}</MenuItem>
                            </Select>
                            {/* <input value={exclusiveMinimum}
                                type="text"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Exclusive Minimum"
                                onChange={(e) => props.onRuleChange("exclusiveMinimum", e.target.value)}
                                disabled={mode === "details"}
                            /> */}
                        </Grid>
                        <Grid item xs={6}>
                            <label className={styles.fieldHeading2}>
                                Exclusive Maximum
                            </label>
                            <Select
                                onChange={(e) => props.onRuleChange("exclusiveMaximum", e.target.value)}
                                value={exclusiveMaximum}
                                className={`${styles.select} ${(mode === "details" || predefined) && styles.editMode}`}
                                inputProps={{ "aria-label": "Without label" }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                disabled={mode === "details"}
                            >
                                <MenuItem key={1}
                                    value={"true"}
                                    className={styles.fieldHeading2}>{"True"}</MenuItem>
                                <MenuItem key={2}
                                    value={"false"}
                                    className={styles.fieldHeading2}>{"False"}</MenuItem>
                            </Select>
                            {/* <input value={exclusiveMaximum}
                                type="text"
                                className={`${styles.input} ${(mode === "details" || predefined) && styles.editMode}`}
                                placeholder="Enter Exclusive Maximum"
                                onChange={(e) => props.onRuleChange("exclusiveMaximum", e.target.value)}
                                disabled={mode === "details"}
                            /> */}
                        </Grid>
                    </Grid>)}
                    {selectValue === "string" && <Grid container spacing={3} style={{ marginTop: 15 }}>
                        <Grid item xs={6}>
                            <label className={styles.fieldHeading2}>
                                Content Encoding
                            </label>
                            <Select
                                onChange={(e) => props.onRuleChange("contentEncoding", e.target.value)}
                                value={contentEncoding}
                                className={`${styles.select} ${(mode === "details" || predefined) && styles.editMode}`}
                                inputProps={{ "aria-label": "Without label" }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                disabled={mode === "details"}
                            >
                                {contentEncodingValues.map((item, index) => (
                                    <MenuItem key={index}
                                        value={item}
                                        className={styles.fieldHeading2}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <label className={styles.fieldHeading2}>
                                Content MediaType
                            </label>
                            <Select
                                onChange={(e) => props.onRuleChange("contentMediaType", e.target.value)}
                                value={contentMediaType}
                                className={`${styles.select} ${(mode === "details" || predefined) && styles.editMode}`}
                                inputProps={{ "aria-label": "Without label" }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                disabled={mode === "details"}
                            >
                                {contentMediaTypeValues.map((item, index) => (
                                    <MenuItem key={index}
                                        value={item}
                                        className={styles.fieldHeading2}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>}
                </div>}
                {selectValue === "boolean" && (
                    <div className={styles.dropdownContent}>Nil</div>
                )}
            </Grid>
        </Grid>
    )
}