import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config"

const apiHelper = new ApiHelper();

export class OntologyServices {
    getFieldsList(currentPage, filter, sort) {
        const queryParam = (`page=${currentPage}&size=10`) +
            (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
            (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fields?${queryParam}`;
        return apiHelper.get(uri)
    }
    getFieldGroupList(currentPage, filter, sort) {
        const queryParam = (`page=${currentPage}&size=10`) +
            (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
            (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fieldgroups?${queryParam}`;
        return apiHelper.get(uri)
    }
    getSectionList(currentPage, filter, sort) {
        const queryParam = (`page=${currentPage}&size=10`) +
            (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
            (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
        const uri = `${BASE_URL}/api/v1.0.0/ontology/sections?${queryParam}`;
        return apiHelper.get(uri)
    }
    getObjectList(currentPage, filter, sort) {
        const queryParam = (`page=${currentPage}&size=10`) +
            (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
            (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
        const uri = `${BASE_URL}/api/v1.0.0/ontology/resources?${queryParam}`;
        return apiHelper.get(uri)
    }
    createField(req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fields`;
        return apiHelper.post(uri, req_data)
    }
    createFieldGroup(req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fieldgroups`;
        return apiHelper.post(uri, req_data)
    }
    createSection(req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/sections`;
        return apiHelper.post(uri, req_data)
    }
    editFieldByID(fieldID, req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fields/${fieldID}`;
        return apiHelper.put(uri, req_data)
    }
    editFieldGroupByID(fieldgroupID, req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fieldgroups/${fieldgroupID}`;
        return apiHelper.put(uri, req_data)
    }
    editSectionByID(sectionID, req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/sections/${sectionID}`;
        return apiHelper.put(uri, req_data)
    }
    editObjectByID(objectID, req_data) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/resources/${objectID}`;
        return apiHelper.put(uri, req_data)
    }
    fetchFieldByID(fieldID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fields/${fieldID}`;
        return apiHelper.get(uri)
    }
    fetchFieldGroupByID(fieldgroupID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fieldgroups/${fieldgroupID}`;
        return apiHelper.get(uri)
    }
    fetchSectionByID(sectionID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/sections/${sectionID}`;
        return apiHelper.get(uri)
    }
    fetchObjectByID(objectID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/resources/${objectID}`;
        return apiHelper.get(uri)
    }
    deleteFieldByID(fieldID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fields/${fieldID}`;
        return apiHelper.delete(uri)
    }
    deleteSectionByID(sectionID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/sections/${sectionID}`;
        return apiHelper.delete(uri)
    }
    deleteFieldGroupByID(fieldgroupID) {
        const uri = `${BASE_URL}/api/v1.0.0/ontology/fieldgroups/${fieldgroupID}`;
        return apiHelper.delete(uri)
    }
}