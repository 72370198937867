import React, { useEffect, useState } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Delicon from "./bin.png";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";
import {
  getFieldGroupById,
  editFieldGroupById,
} from "../../../utils/dataStandards/fieldsUtils";
import { useSnackbar } from "notistack";
import {
  getFieldList,
  fetchAllFieldGroups,
} from "../../../utils/dataStandards/fieldsUtils";
import { handleFormValidationPro } from "../../../utils/common";
import { AppButton } from "../../../components/common/Button";
import useDataStyles from "../../DataStandard/dataStandardSpecificStyles";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
// Entity Schema
import { fetchObjectByID_ } from "../../../utils/ontology/ontologyUtils";
import CustomFieldGroupsForGlossaryFieldEdit from "../../../components/common/entitySchema/fieldGroups/CustomFieldGroupsForGlossaryFieldEdit";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { getDataModelById } from "../../../utils/dataModels/dataModelutils";
import CustomFieldGroupForDataModelEdit from "../../../components/common/entitySchema/datamodels/CustomFieldGroupForDataModelEdit";
import { DropzoneArea } from "material-ui-dropzone";
import {
  clearAIModelFile,
  getAIModelDetails,
  updateDataModel,
} from "../../../redux/actions/dataModelActions";
import { editDataModelById } from "../../../utils/dataModels/dataModelutils";
import { addDataModelFilesById } from "../../../utils/dataModels/dataModelutils";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const DetailsDataModelService = (props) => {
  const dispatch = useDispatch();
  const {
    service_documents_list,
    add_service_document_data,
    serviceDocumentsFilters,
    serviceDocumentSort,
    versionHidden,
    addVersionHidden,
    addVersionSort,
    versionSort,
    getVersionList,
    getAPIList,
    versionsList,
    apisList,
    clearVersions,
    clearAPIs,
    addAPIHidden,
    addAPISort,
    apiSort,
    apiHidden,
    toggleVersionLoading,
    getAIModelDetails,
    aiModelDetails,
    clearAIModelFile,
  } = props;

  const styles = manageScreenStyles();
  const listStyle = listStyles();
  const dataStyles = useDataStyles();
  const history = useHistory();
  const [openFieldModal, setOpenFieldModal] = useState(false);
  const [openFieldGroupModal, setOpenFieldGroupModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = useParams();
  const [searchInput, setSearchInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState({});
  const [error, setError] = useState({
    name: false,
    status: false,
    type: false,
    description: false,
  });

  const [filesArray, setFilesArray] = useState([]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [tab, setTab] = useState(0);

  const [isAllFieldGroups, setIsAllFieldGroups] = useState(true);
  const [isAllFields, setIsAllFields] = useState(true);

  const [modalItem, setModalItem] = useState({});

  const [fieldGroupTable, setFieldGroupTable] = useState([]);
  const [fieldGroupTableOrg, setFieldGroupTableOrg] = useState([]);
  const [fieldTable, setFieldTable] = useState([]);
  const [fieldTableOrg, setFieldTableOrg] = useState([]);
  const [uploadTab, setUploadTab] = useState("Upload");
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState([]);
  const [myFile, setMyFile] = useState([]);

  const [nameError, setnameError] = useState(false);

  const fieldCount = fieldTableOrg?.length
    ? fieldTableOrg?.filter((res) => res?.checked === true).length
    : 0;
  const fieldGroupCount = fieldGroupTableOrg?.length
    ? fieldGroupTableOrg?.filter((res) => res?.checked === true).length
    : 0;

  useEffect(async () => {
    await getDataModel();
  }, []);

  const getDataModel = async () => {
    const { _msg, _status, data } = await getDataModelById(_id);
    await getAIModelDetails(_id);
    if (_status === 200) {
      setLoading(false);

      setFieldsData(data);
      let tempArr = [];
      data?.filesdetails?.map((item) => {
        item["resourceId"] = data?._id;
        tempArr.push(item);
      });
      setFilesArray(tempArr);
    } else {
      setLoading(false);
      if (_msg === "Invalid Json") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError({
      ...error,
      [name]: false,
    });
    setFieldsData({
      ...fieldsData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (fieldsData.state === "active") {
      history.push(`/datamodels/edit/${fieldsData._id}`);
    } else {
      enqueueSnackbar(
        `Cannot edit a Blocked Data Model. Kindly unblock first.`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSearch = () => {
    const searchValue = searchInput?.toLowerCase();
    if (tab === 0) {
      if (searchInput) {
        const filterValues = fieldTableOrg.filter(({ name }) =>
          name?.toLowerCase().includes(searchValue)
        );
        setFieldTable(filterValues);
      } else {
        setFieldTable(fieldTableOrg);
      }
    }
    if (tab === 1) {
      if (searchInput) {
        const filterValues = fieldGroupTableOrg.filter(({ name }) =>
          name?.toLowerCase().includes(searchValue)
        );
        setFieldGroupTable(filterValues);
      } else {
        setFieldGroupTable(fieldGroupTableOrg);
      }
    }
  };

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    history.push("/aitask-workbench");
    console.log("testssadandasidnadna");
    clearAIModelFile();
  };

  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  const [glossaryEditEntity, setGlossaryEditEntity] = useState({});

  useEffect(async () => {
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchObjectByID_("AIModel");
      if (_status === 200) {
        setGlossaryEditEntity(data);
        if (data) {
          const fgData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.datakey) {
                  return (fgData[field.datakey] = "");
                }
              });
            });
          });

          const errData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  return (errData[field.datakey] = false);
                }
              });
            });
          });
          setError(errData);
          await getDataModel();
        }
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
      setLoading(false);
    };
    await fetchObjectDetails();
  }, []);

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "AI Model",
              path: "/aitask-workbench",
            },
            { title: fieldsData.name },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{fieldsData.name}</h2>
          {tabbedView ? (
            <TabbedNavigation
              schemaLength={glossaryEditEntity?.length}
              isEditMode={false}
              activeTab={activeTab}
              isClose={true}
              handleEdit={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              isEditMode={true}
              isClose={true}
              handleEdit={handleSubmit}
              handleCancel={handleCancel}
            />
          )}
        </Grid>
      </div>
      {/* With Entity Schema */}
      <div className={styles.wrapper} style={{ top: "6.5rem" }}>
        {tabbedView ? (
          <>
            <SectionTabs
              entity={glossaryEditEntity ? glossaryEditEntity.sections : []}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CustomFieldGroupForDataModelEdit
                  section={glossaryEditEntity?.sections?.[activeTab]}
                  isEditMode={true}
                  serviceData={fieldsData}
                  dataModelId={fieldsData._id}
                  filesArray={filesArray}
                  setFieldGroupData={setFieldsData}
                  error={error}
                  handleChange={handleChange}
                  tab={tab}
                  handleChangeTab={handleChangeTab}
                  listStyle={listStyle}
                  fieldTable={fieldTable}
                  fieldGroupTable={fieldGroupTable}
                  setSearchInput={setSearchInput}
                  handleSearch={handleSearch}
                  isAllFields={isAllFields}
                  setIsAllFields={setIsAllFields}
                  dataStyles={dataStyles}
                  setModalItem={setModalItem}
                  setOpenFieldModal={setOpenFieldModal}
                  isAllFieldGroups={isAllFieldGroups}
                  setIsAllFieldGroups={setIsAllFieldGroups}
                  setOpenFieldGroupModal={setOpenFieldGroupModal}
                  fieldGroupTableOrg={fieldGroupTableOrg}
                  modalItem={modalItem}
                  openFieldModal={openFieldModal}
                  openFieldGroupModal={openFieldGroupModal}
                  fieldCount={fieldCount}
                  fieldGroupCount={fieldGroupCount}
                  fieldTableOrg={fieldTableOrg}
                />
              </div>
            </Paper>
          </>
        ) : (
          glossaryEditEntity?.sections &&
          glossaryEditEntity?.sections.map((section) => {
            if (section.name === "Data_Model_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForDataModelEdit
                    section={section}
                    isEditMode={true}
                    serviceData={fieldsData}
                    dataModelId={fieldsData._id}
                    filesArray={filesArray}
                    setFieldGroupData={setFieldsData}
                    error={error}
                    handleChange={handleChange}
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    listStyle={listStyle}
                    fieldTable={fieldTable}
                    fieldGroupTable={fieldGroupTable}
                    setSearchInput={setSearchInput}
                    handleSearch={handleSearch}
                    isAllFields={isAllFields}
                    setIsAllFields={setIsAllFields}
                    dataStyles={dataStyles}
                    setModalItem={setModalItem}
                    setOpenFieldModal={setOpenFieldModal}
                    isAllFieldGroups={isAllFieldGroups}
                    setIsAllFieldGroups={setIsAllFieldGroups}
                    setOpenFieldGroupModal={setOpenFieldGroupModal}
                    fieldGroupTableOrg={fieldGroupTableOrg}
                    modalItem={modalItem}
                    openFieldModal={openFieldModal}
                    openFieldGroupModal={openFieldGroupModal}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupForDataModelEdit
                      section={section}
                      dataModelId={fieldsData._id}
                      isEditMode={false}
                      serviceData={fieldsData}
                      filesArray={filesArray}
                      setFieldGroupData={setFieldsData}
                      error={error}
                      handleChange={handleChange}
                      tab={tab}
                      handleChangeTab={handleChangeTab}
                      listStyle={listStyle}
                      fieldTable={fieldTable}
                      fieldGroupTable={fieldGroupTable}
                      setSearchInput={setSearchInput}
                      handleSearch={handleSearch}
                      isAllFields={isAllFields}
                      setIsAllFields={setIsAllFields}
                      dataStyles={dataStyles}
                      setModalItem={setModalItem}
                      setOpenFieldModal={setOpenFieldModal}
                      isAllFieldGroups={isAllFieldGroups}
                      setIsAllFieldGroups={setIsAllFieldGroups}
                      setOpenFieldGroupModal={setOpenFieldGroupModal}
                      fieldCount={fieldCount}
                      fieldGroupCount={fieldGroupCount}
                      fieldTableOrg={fieldTableOrg}
                      fieldGroupTableOrg={fieldGroupTableOrg}
                      modalItem={modalItem}
                      openFieldModal={openFieldModal}
                      openFieldGroupModal={openFieldGroupModal}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
        {}
      </div>
    </main>
  );
};

const mapDispatchToProps = { getAIModelDetails, clearAIModelFile };

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;
  const { aiModelDetails } = state.dataModelReducer;

  return {
    userInfo,
    tabbedView,
    aiModelDetails,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsDataModelService);
