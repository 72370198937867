import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Handle } from "react-flow-renderer";
import { connect } from "react-redux"

const useStyles = makeStyles({
    container: {
        // marginTop: "6rem",
        display: "flex",
        flexDirection: "column"
        // border: "1px solid black"
    },
    table: {
        "& .MuiTableCell-root": {
            padding: 0,
            paddingLeft: "0.7rem",
            // paddingTop: "0.4rem",
            // paddingBottom: "0.4rem",
            height: "2rem",
            font: "normal normal normal 12px/16px Nunito",
            border: "1px solid #E0E0E0"
            // fontSize: 12,
            // fontFamily: "Nunito",
            // fontWeight: 500
        }
    },
    header: {
        height: "2rem",
        width: "18rem",
        background: "#F8F8F8 0% 0% no-repeat padding-box",
        border: "1px solid #D9D9D9",
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5
    },
    node_text: {
        fontFamily: "Nunito",
        fontSize: 12,
        color: '#000000',
        marginLeft: 8,
        // fontWeight: 500
    },
    head_cell: {
        background: "#E0F2FF 0% 0% no-repeat padding-box",
        border: "1px solid #E0E0E0",
        width: "9rem"
    },
    table_body: {
        // height: "100px"
    },
    container_: {
        // maxHeight: "10rem",
        minWidth: "10rem",
        maxWidth: "20rem",
        width: "18rem"
    },
    nodehandle: {
        width: "8px!important",
        height: "8px!important",
        background: "transparent!important",
        border: "1px solid lightgray!important",
        borderRadius: "4px!important",
    }
})

function CallDataNode(props) {
    const [data_list, setDataList] = useState([]);
    const [inputFieldGroup, setInputFieldGroup] = useState({
        name: "",
        type: ""
    });
    const [outputFieldGroup, setOutputFieldGroup] = useState({
        name: "",
        type: ""
    })
    const styles = useStyles();

    const { node_name, nodeid } = props.data;
    const { data_flow_nodes_io_details } = props;

    useEffect(() => {
        const node_details = data_flow_nodes_io_details.length > 0 && data_flow_nodes_io_details.filter((item) => item.id === nodeid);
        var inputFieldList = [];
        var outputFieldList = [];
        var tempDataList = []
        if (node_details.length > 0) {
            const { inputFields, outputFields } = node_details[0];
            if (Object.keys(inputFields).length > 0) {
                // eslint-disable-next-line no-unused-vars
                const { fieldgroupname, fields, status, type } = inputFields;
                inputFieldList = fields;
                setInputFieldGroup({
                    name: fieldgroupname,
                    type: type
                })
            }
            if (Object.keys(outputFields).length > 0) {
                // eslint-disable-next-line no-unused-vars
                const { fieldgroupname, fields, status, type } = outputFields;
                outputFieldList = fields
                setOutputFieldGroup({
                    name: fieldgroupname,
                    type: type
                })
            }
        }
        const temp_ = Math.max(inputFieldList.length, outputFieldList.length);
        for (var i = 0; i < temp_; i++) {
            var temp_obj = {};
            if (inputFieldList.length > i) {
                temp_obj = { ...temp_obj, input: inputFieldList[i] }
            }
            if (outputFieldList.length > i) {
                temp_obj = { ...temp_obj, output: outputFieldList[i] }
            }
            tempDataList.push(temp_obj)
        }

        setDataList(tempDataList)
    }, [data_flow_nodes_io_details, nodeid])

    var top_position = 5;
    return (
        <div className={styles.container}
            style={{ marginTop: "5rem" }}>
            <div className={styles.header}>
                <img src="/images/callDataNode.png" height={25} width={25} alt="call node" />
                <text className={styles.node_text}>{node_name}</text>
            </div>
            <TableContainer component={Paper}
                className={styles.container_}>
                <Table className={styles.table} aria-label="simple label">
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.head_cell} align="left">Input</TableCell>
                            <TableCell className={styles.head_cell} align="left">Output</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.table_body}>
                        {data_list.length > 0 && data_list.map((item, index) => {
                            if (index === 0) {
                                top_position = top_position + 5
                            } else {
                                top_position = top_position + 2.03
                            }
                            const inputHandleObj = {
                                nodeid: nodeid,
                                node_type: "call_node",
                                field_name: item.input ? item.input.name : "",
                                node_name: node_name,
                                inputfieldgroupname: inputFieldGroup["name"],
                                inputfieldgrouptype: inputFieldGroup["type"]
                            }
                            const outputHandleObj = {
                                nodeid: nodeid,
                                node_type: "call_node",
                                field_name: item.output ? item.output.name : "",
                                node_name: node_name,
                                outputfieldgroupname: outputFieldGroup["name"],
                                outputfieldgrouptype: outputFieldGroup["type"],
                            }
                            return (
                                <TableRow key={index}>
                                    {/* <TableCell align="left">{item.input ? item.input.name : ""}</TableCell> */}
                                    <TableCell align="left">
                                        <Tooltip title={item?.input?.value ? item?.input?.value : ""}
                                            arrow
                                        >
                                            <text>{item?.input ? item?.input?.name : ""}</text>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="left">{item.output ? item.output.name : ""}</TableCell>
                                    {item.input && <Handle type="target"
                                        position="right"
                                        id={JSON.stringify(inputHandleObj)}
                                        style={{
                                            top: `${top_position}rem`,
                                            left: ".1%",
                                        }}
                                        className={styles.nodehandle} />}
                                    {item.output && <Handle type="source"
                                        position="right"
                                        id={JSON.stringify(outputHandleObj)}
                                        style={{
                                            top: `${top_position}rem`,
                                            right: ".1%"
                                        }}
                                        className={styles.nodehandle} />}
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapDispatchToProps = {}

const mapStateToProps = (state) => {
    const { data_flow_nodes_io_details } = state.orchestration;
    return {
        data_flow_nodes_io_details
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CallDataNode)