import { DataModelService } from "../../services/dataModelService";
const dataModelSchemaService = new DataModelService();

export const fetchAllDataModels = async (resource) => {
  try {
    const res_data = await dataModelSchemaService.getAllDataModals(resource);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addDataModel = async (datamodel) => {
  try {
    const res_data = await dataModelSchemaService.addDataModel(datamodel);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const uploadFileCompleted = async (dataModelId, fileToUpload) => {
  try {
    const res_data = await dataModelSchemaService.uploadFileCompleted(
      dataModelId,
      fileToUpload
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createLargeDataModel = async (datamodel) => {
  try {
    const res_data = await dataModelSchemaService.createLargeDataModel(
      datamodel
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const startChunkUpload = async (chunkFormData) => {
  try {
    const res_data = await dataModelSchemaService.startChunkUpload(
      chunkFormData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addDataModelDocument = async (dataModelId, dataModelData) => {
  try {
    const res_data = await dataModelSchemaService.addDataModelDocument(
      dataModelId,
      dataModelData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addLargeFileToDataModel = async (dataModelId, dataModelData) => {
  try {
    const res_data = await dataModelSchemaService.addLargeFileToDataModel(
      dataModelId,
      dataModelData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const uploadChunkStart = async (dataModelId, fileName) => {
  try {
    const res_data = await dataModelSchemaService.uploadChunkStart(
      dataModelId,
      fileName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteDataModelDocument = async (
  dataModelId,
  dataModelFileName
) => {
  try {
    const res_data = await dataModelSchemaService.deleteDataModelDocument(
      dataModelId,
      dataModelFileName
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editDataModelById = async (_id, payload) => {
  try {
    const res_data = await dataModelSchemaService.editDataModel(_id, payload);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addDataModelFilesById = async (id, payload) => {
  try {
    const res_data = await dataModelSchemaService.addDataModelFile(id, payload);
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getDataModelById = async (resource) => {
  try {
    const res_data = await dataModelSchemaService.getDataModelById(resource);
    const { _msg, _status, data } = res_data.data;

    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchAiModelDependenciesById = async (dataModelId) => {
  try {
    const res_data = await dataModelSchemaService.getAiModelDependenciesById(dataModelId)
    const { _msg, _status, data } = res_data.data
    return {
      _msg, _status, data
    }
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data
    }
  }
}

export const deleteDataModelById = async (resource) => {
  try {
    const res_data = await dataModelSchemaService.deleteDataModelById(resource);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

// subscribe and unsubscribe yet to be modified

export const subscribeDataModel = async (userID, resouce, resourceinstance) => {
  try {
    const res_data = await dataModelSchemaService.subscribeDataModel(
      userID,
      resouce,
      resourceinstance
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const unsubscribeDataModel = async (
  userID,
  resouce,
  resourceinstance
) => {
  try {
    const res_data = await dataModelSchemaService.unsubscribeDataModel(
      userID,
      resouce,
      resourceinstance
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const blockDataModel = async (dataModelID) => {
  try {
    const res_data = await dataModelSchemaService.blockDataModel(
      dataModelID,
      {}
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const UnblockDataModel = async (dataModelID) => {
  try {
    const res_data = await dataModelSchemaService.UnblockDataModel(
      dataModelID,
      {}
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {

    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
