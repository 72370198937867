import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import joinNodeStyle from "./styles";
//import utils
import {
  fetchNodeDetails,
  editNodeData,
  addNodeData,
} from "../../../utils/orchestration/orchestrationUtils";
import { AppButton } from "../../common/Button";
import Loader from "../../common/stuff/Loader";

function JoinNodeModal(props) {
  const [name, setName] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const [errorObj, setErrorObj] = useState({
    name: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = joinNodeStyle();
  const { nodeid, edit, join_node_obj } = props;

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const checkNameForm = (nameStr) => {
    // var nameStr_ = nameStr.replace(/\s/g, '');
    var nameStr_ = nameStr.replace(/[^a-zA-Z0-9_ ]/g, "");
    return nameStr_;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const filteredName = checkNameForm(value);
    setName(filteredName);
    setErrorObj((prevState) => ({
      ...prevState,
      name: false,
    }));
  };

  const { isOpen, templateid } = props;
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    if (edit) {
      props.onEditJoinClose();
    } else {
      props.onCloseClick();
    }
  };

  const fetchData = async () => {
    if (edit) {
      const { _msg, _status, data } = await fetchNodeDetails(
        templateid,
        nodeid
      );
      if (_status === 200) {
        const { name } = data;
        setName(name);
        setErrorObj((prevState) => ({ ...prevState, name: false }));
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  const handleSubmit = async () => {
    var errorFlag = false;
    if (name === "") {
      setErrorObj((prevState) => ({ ...prevState, name: true }));
      errorFlag = true;
    }
    if (!errorFlag) {
      setIsLoader(true);
      if (edit) {
        const req_data = {
          name: name,
          type: "JoinNode",
        };
        const { _msg, data, _status } = await editNodeData(
          nodeid,
          templateid,
          req_data
        );
        if (_status === 201) {
          setIsLoader(false);
          props.onEditJoinSubmit({
            ...data,
            name: name,
          });
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      } else {
        const { x, y } = join_node_obj["position"];
        const req_data = {
          name: name,
          type: "JoinNode",
          posX: x,
          posY: y,
        };
        const { _msg, data, _status } = await addNodeData(templateid, req_data);
        if (_status === 201) {
          setIsLoader(false);
          props.onSubmitClick({
            ...data,
            name: name,
          });
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onEnter={fetchData}
        // scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        {isLoader && <Loader />}
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1}>
            <img src="/images/Join.png" alt="Icon" height={34} width={34} />
            <p className={classes.heading}>Join Node</p>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className={classes.line2}>
            <div className={classes.name}>
              <p className={classes.label}>
                Node Name <text className={classes.star}>*</text>
              </p>
              <input
                style={errorObj.name ? { borderColor: "red" } : {}}
                className={classes.value}
                type={"text"}
                name="name"
                value={name}
                onChange={handleChange}
                pattern="[^\s]+"
              />
              {errorObj.name ? (
                <text className={classes.error_text}>Invalid Node Name</text>
              ) : (
                ""
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleClose}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          <AppButton
            onClick={handleSubmit}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { process_data } = state.orchestration;
  const { templateid } = process_data;
  return {
    templateid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinNodeModal);