import { CommonServices } from "../../services/commonServices";
import { MicroServicesAPIServices } from "../../services/microservices";
import { ProjectServices } from "../../services/projectServices";
import { ServiceGroupsAPIServices } from "../../services/serviceGroups";

const commonService = new CommonServices();
const projectService = new ProjectServices();
const microService = new MicroServicesAPIServices();
const serviceGroup = new ServiceGroupsAPIServices();

export const fetchLanguagesListData = async () => {
  try {
    const res_data = await commonService.getLanguagesList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchDependencyDataByProject = async () => {
  try {
    const res_data = await commonService.getLanguagesList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchLanguagesByProject = async (projectId) => {
  try {
    const res_data = await commonService.getLanguagesByProject(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchTeamsByProject = async (projectId) => {
  try {
    const res_data = await commonService.getTeamsByProject(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchServicesByProject = async (projectId) => {
  try {
    const res_data = await microService.getServicesListByProject(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchServicesByServiceGroup = async (groupId) => {
  try {
    const res_data = await microService.getServicesListByServiceGroup(groupId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchServiceGroupByIdHierarchy = async (groupId) => {
  try {
    const res_data = await serviceGroup.getServiceGroupHierarchy(groupId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchProjectByIdHierarchy = async (projectId) => {
  try {
    const res_data = await serviceGroup.getProjectHierarchy(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchProjectById = async (projectId) => {
  try {
    const res_data = await projectService.getProjectById(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addProject = async (projectData) => {
  try {
    const res_data = await projectService.addProject(projectData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editProject = async (projectId, projectData) => {
  try {
    const res_data = await projectService.editProject(projectId, projectData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteProject = async (projectId) => {
  try {
    const res_data = await projectService.deleteProject(projectId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addResourceDeployment = async (resourceData) => {
  try {
    const res_data = await commonService.addResourceDeployment(resourceData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchResourceDeployment = async (resourceType, resourceId) => {
  try {
    const res_data = await commonService.getResourceDeployment(
      resourceType,
      resourceId
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
