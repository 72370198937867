import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { alpha, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const useStyles = makeStyles({
    tree: {
    }
})

const FieldGroupTree = (props) => {
    const styles = useStyles();
    let id = 0;

    const treeData = {
        id: 'root',
        name: props?.data?.name,
        children: props?.data?.children?.map(f1 => {
            id++
            return {
                id: id.toString(),
                name: f1?.name,
                children: f1.children?.map(f2 => {
                    id++
                    return {
                        id: id.toString(),
                        name: f2.name,
                        children: f2.children?.map(f3 => {
                            id++
                            return {
                                id: id.toString(),
                                name: f3.name,
                                children: f3.children?.map(f4 => {
                                    id++
                                    return {
                                        id: id.toString(),
                                        name: f4.name,
                                        children: f4.children?.map(f5 => {
                                            id++
                                            return {
                                                id: id.toString(),
                                                name: f5.name,
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    };

    const StyledTreeItem = withStyles((theme) => ({
        label: {
            fontSize: 14,
            color: '#000',
            padding: '3px 10px'
        },
        iconContainer: {
            // '& .close': {
            //     opacity: 0.3,
            // },
        },
        selected: {
            // background: 'red'
        },
        group: {
            paddingLeft: 15,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }))((props) => <TreeItem {...props} />);

    const renderTree = (nodes) => (
        <StyledTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    )

    return (
        <TreeView
            className={styles.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root', ...Array.apply(null, { length: 100 }).map((value, index) => index.toString())]}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {renderTree(treeData)}
        </TreeView>
    )
}

export default FieldGroupTree
