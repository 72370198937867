import React from "react";
import { Grid } from "@material-ui/core";

const FormWrapper = ({ selectedComponent, children }) => {
  return (
    <Grid
      item
      xs={
        selectedComponent?.itemsPerPage === ""
          ? 12
          : 12 / selectedComponent?.itemsPerPage
      }
      style={{
        padding: 5,
        marginBottom: 10,
      }}
    >
      {children}
    </Grid>
  );
};

export default FormWrapper;
