import { ApiHelper } from "./helper/ApiHelper";
import { CLUSTER, REPOSITORY, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class ClusterService {
  getAllCluster(cloudConfig, currentPage, filter, sort) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${cloudConfig}/clusters`;
    return apiHelper.get(uri);
  }

  getAllClusterDeployments(configName, clusterName, currentPageDeployment) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${configName}/clusters/${clusterName}/deployments?page=${currentPageDeployment}&size=10`;
    return apiHelper.get(uri);
  }

  getAllClusterNodes(configName, clusterName) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${configName}/clusters/${clusterName}/nodes`;
    return apiHelper.get(uri);
  }

  getAllClusterDeploymentsWithoutPagination(configName, clusterName) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${configName}/clusters/${clusterName}/deployments`;
    return apiHelper.get(uri);
  }

  getAllClusterPods(configName, clusterName, currentPageClusterPods) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${configName}/clusters/${clusterName}/pods?page=${currentPageClusterPods}&size=10`;
    return apiHelper.get(uri);
  }

  getAllClusterReplicaSets(cloudConfig, clusterName, currentPageClusterPods) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${cloudConfig}/clusters/${clusterName}/replicasets?page=${currentPageClusterPods}&size=10`;
    return apiHelper.get(uri);
  }

  getAllClusterServices(
    cloudConfig,
    clusterName,
    currentPageCluster,
    filter,
    sort
  ) {
    const queryParam =
      `page=${currentPageCluster}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${cloudConfig}/clusters/${clusterName}/services?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  getAllClusterDetails(currentPage, filter, cloudConfig) {
    const queryParam =
      `page=${currentPage}` + `&filters={"clustername": "${filter}"}`;
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${cloudConfig}/clusters/details?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getAllClusterDetailsHost(cloudConfig) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${cloudConfig}/clusters/details`;
    return apiHelper.get(uri);
  }
}
