import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  documentColumnOrder,
  documentColumns,
  documentDefaultColumnWidths,
  documentDefaultSorting,
  documentTableColumnExtensions,
} from "../../../constants/documentConstant";
import {
  dataModelFileColumns,
  dataModelFileDefaultColumnWidths,
  dataModelFileTableColumnExtensions,
  dataModelFileColumnOrder,
  dataModelFileDefaultSorting,
} from "../../../constants/dataModelFileTableConstant";
import manageStyles from "../../../screens/CommonStyles/manageScreenStyles";
import { AppButton } from "../Button";
import DevExpressTable from "./DevExpressTable";
import { v4 as uuidv4 } from "uuid";
import {
  addDocument,
  fetchDocumentTypeListData,
} from "../../../utils/project/documentUtils";
import { useSnackbar } from "notistack";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  convertEditorToFile,
  getColumnWidth,
  getWindowDimensions,
  handleFormValidation,
  handleFormValidationForDocument,
} from "../../../utils/common";
import Loader from "../stuff/Loader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useCheckPermission from "../hooks/useCheckPermission";
import {
  addDataModelDocument,
  addLargeFileToDataModel,
  uploadChunkStart,
  startChunkUpload,
  uploadFileCompleted,
} from "../../../utils/dataModels/dataModelutils";
import DataModelListTable from "./DataModelListTable";
import DataModelFileTable from "./DataModelFileTable";
import { addAIModelFile } from "../../../redux/actions/dataModelActions";
const CustomDropperIcon = () => (
  <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
);

const initError = (id) => ({
  id: id,
  name: {
    isError: false,
    msg: "",
  },
  version: {
    isError: false,
    msg: "",
  },
  docType: {
    isError: false,
    msg: "",
  },
});

const DataModelEditDropper = ({
  resourceId,
  resourceType,
  isEditMode,
  rows,
  fetchDocuments,
  setSort,
  sort,
  isDocumentModule,
  closeImportButton,
  tabbedView,
  aiModelDetails,
  addAIModelFile,
}) => {
  const styles = manageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [documentTab, setDocumentTab] = useState("Upload");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [droppedDocuments, setDroppedDocuments] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [clearEditorState, setClearEditorState] = useState("");
  const autoC = useRef(null);
  const [uploaderFiles, setUploaderFiles] = useState([]);
  const [customFile, setCustomFile] = useState({
    name: "",
    version: "",
    docType: "",
    file: "",
  });

  const [customFileError, setCustomFileError] = useState({
    name: "",
    version: "",
    docType: "",
    file: "",
  });
  const histroy = useHistory();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [error, setError] = useState([]);
  const [random, setRandom] = useState(Math.random());

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  //File Table state

  const [filesForTable, setFilesForTable] = useState({});
  const chunkSize = 1024 * 1024 * 10;

  const [counter, setCounter] = useState(1);
  // const [partno, setPartno] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  // const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  // const [fileToUploadName, setFileToUploadName] = useState();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (checkCompPermission("DocumentType", ["List All"])) {
      fetchAllDocumentTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDocumentTab = (event, newValue) => {
    setDocumentTab(newValue);
    setCustomFileError({
      name: false,
      version: false,
      docType: false,
      file: false,
    });
    setCustomFile({
      name: "",
      version: "",
      docType: "",
      file: "",
    });
    const tempError = error.map((e) => {
      return {
        ...e,
        docType: { ...e["docType"], isError: false },
        name: { ...e["name"], isError: false },
        version: { ...e["version"], isError: false },
      };
    });
    setError(tempError);
    setDroppedDocuments([]);
  };

  const handleDeleteDocument = (documentToDelete) => () => {
    //
    setUploaderFiles((uploaderFiles) =>
      uploaderFiles.filter((file) => file.name !== documentToDelete.name)
    );
    setDroppedDocuments((documents) =>
      documents.filter((doc) => doc.id !== documentToDelete.id)
    );
    setRandom(Math.random());
  };

  const fetchAllDocumentTypes = async () => {
    const { _msg, _status, data } = await fetchDocumentTypeListData();
    if (_status === 200) {
      if (_status === 200) {
        if (data && data.length) {
          let tempDocType = {
            createdby: "",
            createdon: "",
            description: "",
            name: "",
            _id: "",
          };
          data.unshift(tempDocType);
        }
        setDocumentTypes(data ? data : []);
      }
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDocChange = (event, id) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return {
          ...e,
          [event.target.name]: { ...e[event.target.name], isError: false },
        };
      } else {
        return e;
      }
    });

    setError(tempError);
    const newDoc = droppedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, [event.target.name]: event.target.value };
      } else {
        return doc;
      }
    });
    setDroppedDocuments(newDoc);
  };

  const handleCustomDocChange = (event, id) => {
    const value = event.target.value;
    setCustomFileError({
      ...customFileError,
      [event.target.name]: false,
    });
    setCustomFile({
      ...customFile,
      [event.target.name]: value,
    });
  };

  const handleSelectDocumentType = (docType, id) => {
    const tempError = error.map((e) => {
      if (e.id === id) {
        return { ...e, docType: { ...e["docType"], isError: false } };
      } else {
        return e;
      }
    });
    setError(tempError);
    const newDoc = droppedDocuments.map((doc) => {
      if (doc.id === id) {
        return { ...doc, docType: docType };
      } else {
        return doc;
      }
    });
    setDroppedDocuments(newDoc);
  };
  const uploadSingleDocument = async (resType, resId, doc) => {
    var formData = new FormData();
    formData.append(
      "meta",
      JSON.stringify({
        // resourcetype: resType,
        // resourceid: resId,
        name: doc.docType.name,
        // version: doc.version,
        // documenttype: doc.docType._id,
      })
    );
    const renameFile = new File([doc.file], doc.name);
    formData.append("file", renameFile);

    setIsLoader(true);
    const { _msg, _status, data } = await addDocument(formData);
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      setIsLoader(false);

      setDroppedDocuments([]);
      setCustomFile({ name: "", version: "", docType: {}, file: null });
      setEditorState(EditorState.createEmpty());
      fetchDocuments();
    } else {
      setIsLoader(false);
      if (_msg === "Invalid Json" || _msg === "Invalid Payload") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const handleUploadDocument = async () => {
    const sizeArr = [];
    uploaderFiles?.length !== 0 &&
      uploaderFiles.map((item) => {
        sizeArr.push(item.size);
      });

    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue;
    const fileCheckforChunking = sizeArr.reduce(reducer);

    if (fileCheckforChunking < 200000000) {
      setIsLoader(true);
      uploaderFiles.length !== 0 &&
        uploaderFiles.map(async (doc) => {
          var singleFileUpload = new FormData();
          singleFileUpload.append("file", doc);
          await addAIModelFile(resourceId, singleFileUpload);
          setIsLoader(false);
          setDroppedDocuments([]);
          // const { _msg, _status, data } = await addDataModelDocument(
          //   resourceId,
          //   singleFileUpload
          // );

          // if (_status === 201) {
          //   // enqueueSnackbar(_msg, { variant: "success" });
          //   history.go(0);
          // } else {
          //   enqueueSnackbar(_msg, { variant: "error" });
          //   setIsLoader(false);
          // }
        });
    } else {
      const filesdetails = [];
      let _totalCount;
      let fileToUpload;
      uploaderFiles.map((item) => {
        //each file upload
        const _file = item;
        fileToUpload = _file;
        setFileSize(_file.size);
        // setFileToUploadName(_file.name);
        _totalCount =
          _file.size % chunkSize == 0
            ? _file.size / chunkSize
            : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount);
        setFileToBeUpload(_file);
        // const _fileID = uuidv4() + "." + _file.name.split(".").pop();
        // setFileGuid(_fileID);

        filesdetails.push({ filename: _file.name, chunks: _totalCount });
      });

      const addFileToDataModel = async () => {
        setIsLoader(true);

        const { _msg, _status, data } = await addLargeFileToDataModel(
          resourceId,
          { filesdetails }
        );
        if (_status === 201) {
          // enqueueSnackbar(_msg, { variant: "success" });
          // histroy.go(0);
          const resourceId = data?._id;
          uploaderFiles.map((item) => {
            var fileName = item.name;
            uploadChunkStarter(resourceId, fileName, _totalCount, fileToUpload);
          });
        }
      };
      addFileToDataModel();

      const uploadChunkStarter = async (
        resourceId,
        fileName,
        _totalCount,
        fileToUpload
      ) => {
        const { _status, _msg, data } = await uploadChunkStart(
          resourceId,
          fileName
        );
        //
        //
        if (_status === 200) {
          // enqueueSnackbar(_msg, { variant: "success" });
          const dataModelId1 = data?._id;

          // setDataModelId(dataModelId1);

          fileChunkUpload(dataModelId1, fileName, _totalCount, fileToUpload);

          // fileChunkUpload();
        } else {
          setIsLoader(false);
        }
      };

      const fileChunkUpload = async (
        dataModelId1,
        fileName,
        _totalCount,
        fileToUpload
      ) => {
        setCounter(counter + 1);
        let fileForUploading;
        droppedDocuments.map((item) => {
          fileForUploading = item.file;
        });
        for (let i = 0; i < _totalCount; i++) {
          // if (counter <= chunkCount) {
          var chunk = fileForUploading.slice(beginingOfTheChunk, endOfTheChunk);
          // var chunkfile = new File([chunk], `abc${counter}`);
          var chunkFormData = new FormData();
          // var chunkFormData = {};
          let chunkmeta = {
            dmid: dataModelId1,
            filename: fileName,
            partno: i,
          };

          chunkFormData.append("meta", JSON.stringify(chunkmeta));
          chunkFormData.append("file", chunk);

          const { _msg, _status, data } = await startChunkUpload(chunkFormData);
          if (_status === 202) {
            // enqueueSnackbar(_msg, { variant: "success" });
            setBeginingOfTheChunk(endOfTheChunk);
            setEndOfTheChunk(endOfTheChunk + chunkSize);

            //setPartno(partno + 1);
          }

          // }
        }
        await uploadhasCompleted(dataModelId1, fileName, fileToUpload);
      };

      const uploadhasCompleted = async (
        dataModelId1,
        fileName,
        fileToUpload
      ) => {
        const { _msg, _status, data } = await uploadFileCompleted(
          dataModelId1,
          fileName
        );
        if (_status === 202) {
          // enqueueSnackbar(_msg, { variant: "success" });
          setIsLoader(false);
          history.go(0);
        } else if (_status === 404) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      };
      // const metaForLargeFile = { ...newServiceData, filesdetails };
      // var largeFileFormData = new FormData();
      // largeFileFormData.append(JSON.stringify(metaForLargeFile));
      // largeFileFormData.append("file",fileDetails);

      //
      //
      // const createLargeDataforDataModel = async () => {
      //   const { _msg, _status, data } = await createLargeDataModel(
      //     metaForLargeFile
      //   );
      //   if (_status === 201) {
      //
      //     enqueueSnackbar(_msg, { variant: "success" });
      //     const dataModelId1 = data?._id;
      //
      //     setDataModelId(dataModelId1);
      //     data?.filesdetails.map((item) => {
      //       const fileName = item.filename;
      //       uploadChunkStarter(dataModelId1, fileName);
      //     });
      //   } else {
      //     enqueueSnackbar(_msg, { variant: "error" });
      //   }
      // };

      // createLargeDataforDataModel();

      // const _msg= "File size exceeded 500 mb"
      // enqueueSnackbar(_msg, { variant: "error" });
    }
    // const { formIsValid, errors } = handleFormValidationForDocument(
    //   droppedDocuments,
    //   error
    // );

    // //
    // if (formIsValid) {
    // const sizeArr = [];
    // droppedDocuments.map((item) => {
    //   sizeArr.push(item.size);
    // });
    //

    setRandom(Math.random());
    // }
    // else {
    //   setError(errors);
    // }
  };

  const handlechunkerchange = (e) => {
    let files = [e.target.files];
    let filesArr = [...files[0]];
    const updatedFiles = [...uploaderFiles, ...files[0]];
    setUploaderFiles(updatedFiles);
    //

    // for (let i = 0; i < files.length; i++) {
    //   let file = files.item(i);
    //
    // }
    // const arr = Array.from(files);
    // let tempFiles = [...selectedDocuments];
    let tempFiles = [...droppedDocuments];
    let tempErrors = error;

    filesArr.map((file) => {
      if (droppedDocuments.filter((f) => f.name === file.name).length === 0) {
        const id = uuidv4();
        tempFiles.push({
          id: id,
          name: file.name,
          version: "",
          docType: "",
          size: file.size,
          file,
        });
        tempErrors.push(initError(id));
      }
    });

    if (tempFiles.length !== 0) {
      setDroppedDocuments(tempFiles);
      setError(tempErrors);
    }
  };

  return (
    <div>
      {isLoader && <Loader />}
      {isEditMode && (
        <RadioGroup
          value={documentTab}
          onChange={handleChangeDocumentTab}
          indicatorColor="primary"
          textColor="primary"
          color="primary"
          className={styles.documentTab}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <FormControlLabel
            value="Upload"
            control={<Radio color="primary" />}
            label="Upload"
            style={{ display: "none" }}
          />
        </RadioGroup>
      )}
      {isEditMode && documentTab === "Upload" && (
        <div>
          <div
            // className={styles.documentDropperWrapper}
            className="btnsmall"
          >
            {checkCompPermission("AIModel", ["Edit"]) ? (
              <div className={styles.dropzoneClass}>
                <label for="file-upload" className={styles.customFileUploader}>
                  Add File(s)
                </label>
                <input
                  id="file-upload"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handlechunkerchange}
                  multiple
                />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div>
                  <DropzoneArea
                    key={random}
                    Icon={CustomDropperIcon}
                    showPreviews={false}
                    filesLimit={5}
                    dropzoneText="Drag and drop or click to browse here."
                    dropzoneClass="documentDropperClass"
                    dropzoneParagraphClass="documentDropperTextClass"
                    showAlerts={["error"]}
                    maxFileSize={3000000000}
                    clearOnUnmount={true}
                    showPreviewsInDropzone={false}
                    onChange={(files) => {
                      let tempFiles = [...droppedDocuments];
                      let tempErrors = error;
                      files.map((file) => {
                        if (
                          droppedDocuments.filter((f) => f.name === file.name)
                            .length === 0
                        ) {
                          const id = uuidv4();
                          tempFiles.push({
                            id: id,
                            name: file.name,
                            version: "",
                            docType: "",
                            file,
                          });
                          tempErrors.push(initError(id));
                        }
                      });

                      if (tempFiles.length !== 0) {
                        setDroppedDocuments(tempFiles);
                        setError(tempErrors);
                      }
                    }}
                  />
                </div>
                <span style={{ marginTop: "136px", marginLeft: "25px" }}>
                  <p className={styles.fieldHeading} style={{ color: "red" }}>
                    You do not have permission to Document Type List All. Please
                    contact your Admin.
                  </p>
                </span>
              </div>
            )}
          </div>

          {droppedDocuments.length !== 0 && (
            <div className={styles.droppedDocuments}>
              {droppedDocuments.map((item, index) => {
                return (
                  <Grid
                    container
                    spacing={3}
                    style={{
                      borderBottom: "1px solid rgba(0, 40, 85, .4)",
                      marginBottom: 20,
                      paddingBottom: 10,
                    }}
                    key={index}
                  >
                    <Grid item xs={4} sm={4}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            className={styles.actionIcon}
                          >
                            <img
                              src="/images/Doc_Icon.svg"
                              alt="doc"
                              style={{ filter: "brightness(.3)", height: 25 }}
                            />
                          </IconButton>
                        </Grid>

                        <Grid item xs={10} sm={10}>
                          <label htmlFor="" className={styles.fieldHeading}>
                            File Name <span className={styles.red}>*</span>
                          </label>

                          <div style={{ marginTop: "30px" }}>
                            <Typography>{item.name}</Typography>
                          </div>
                          {error[index].name.isError && (
                            <p className={styles.errorMsg}>
                              {error[index].name.msg}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            onClick={handleDeleteDocument(item)}
                            className={styles.actionIcon}
                          >
                            <img src="/images/deleteLine.svg" alt="card" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 20 }}
              >
                <AppButton
                  color="primary"
                  buttonName="Upload"
                  variant="contained"
                  disabled={false}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleUploadDocument}
                />
              </Grid>
            </div>
          )}
        </div>
      )}

      {isEditMode && documentTab === "Text Editor" && (
        <div className={styles.documentEditorWrapper}>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(data) => setEditorState(data)}
          />
          {customFileError.file && (
            <p className={styles.errorMsg}>{"Document content is Required"}</p>
          )}
          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Name <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.name && styles.error
                  }`}
                name="name"
                value={customFile.name}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.name && (
                <p className={styles.errorMsg}>{"Document Name is Required"}</p>
              )}
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Version Number <span className={styles.red}>*</span>
              </label>
              <input
                type="text"
                className={`${styles.input} ${customFileError.version && styles.error
                  }`}
                name="version"
                value={customFile.version}
                onChange={handleCustomDocChange}
                autoComplete="off"
              />
              {customFileError.version && (
                <p className={styles.errorMsg}>
                  {"Document Version is Required"}
                </p>
              )}
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={styles.fieldHeading}>
                Document Type <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                options={documentTypes}
                getOptionLabel={(option) => option.name}
                className={`${styles.searchInput3}`}
                onChange={(e, input) => {
                  setCustomFile({ ...customFile, docType: input });
                  setCustomFileError({ ...customFileError, docType: false });
                }}
                value={customFile.docType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={customFile.docType}
                    variant="standard"
                    className={`${!isEditMode && styles.editMode} ${customFileError.docType && styles.error
                      }`}
                    placeholder="Select Document Type"
                  />
                )}
              />
              {customFileError.docType && (
                <p className={styles.errorMsg}>{"Document Type is Required"}</p>
              )}
            </Grid>
            <Grid item xs={3} sm={3} className={styles.createDocumentBtn}>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={() => saveCustomFile()}
              />
            </Grid>
          </Grid>
        </div>
      )}
      <DevExpressTable
        columns={dataModelFileColumns}
        rows={aiModelDetails?.filesdetails ? aiModelDetails?.filesdetails : []}
        defaultColumnWidths={getColumnWidth(
          dataModelFileDefaultColumnWidths,
          windowDimensions
        )}
        isEditMode={isEditMode}
        resourceId={resourceId}
        tableColumnExtensions={dataModelFileTableColumnExtensions}
        columnOrder={dataModelFileColumnOrder}
        defaultSorting={dataModelFileDefaultSorting}
        sort={sort}
        setSort={setSort}
        isAddIcon={true}
        loading={false}
        isCustomHeight={isDocumentModule}
        noDataMsg="No Document Found"
        hideColumnChooser={true}
      />
    </div>
  );
};

const mapDispatchToProps = {
  addAIModelFile,
};
const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  const { aiModelDetails } = state?.dataModelReducer;

  return {
    tabbedView,
    aiModelDetails,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataModelEditDropper);
