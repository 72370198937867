import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import manageStyle from "../CommonStyles/UserProfile";
import User from "./Assets/profile.svg";
import Reupload from "./Assets/re-upload.svg";
import Remove from "./Assets/remove.svg";
import disabledReupload from "./Assets/disabled-upload.svg";
import disabledRemove from "./Assets/disable-remove.svg";
import { useSelector, useDispatch } from "react-redux";
import { editUserDetails } from "../../redux/actions/userProfileAction";
import Loader from "../../components/common/stuff/Loader";
import { useSnackbar } from "notistack";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles((theme) => ({
  text: {
    ...theme.typography.fontFamily,
    fontSize: "12px",
    color: "#333333DE",
    fontWeight: 600,
  },
  container2: {
    padding: 21,
  },
  btnIcon: {
    width: "18px",
    height: "18px",
    marginTop: -2,
  },
  input1: {
    height: "18px",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const styles = manageStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadDetails, userInfo, success } = userDetails;

  const userEdit = useSelector((state) => state.userEdit);
  const { loading } = userEdit;

  const selectTab = useSelector((state) => state.selectTab);

  const { userTab } = selectTab;

  const appearnce = useSelector((state) => state.appearnce);
  const { appear } = appearnce;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [file, setFile] = useState(null);
  const [showFile, setshowFile] = useState("");
  const [uploadError, setuploadError] = useState(1);
  const [typeImg, setTypeImg] = useState(null);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangePic = async (e) => {
    const image = e.target.files[0];
    if (e.target.files[0]) {
      const base64 = await convertBase64(image);
      setFile(base64);
      setuploadError((e.target.files[0].size / (1024 * 1024)).toFixed(2));
      setTypeImg(e.target.files[0]);
    }
    // console.log(e.target.files[0], "type of image is");
  };

  const onChange = (event) => {
    let valid;
    switch (event.target.id) {
      case "phoneNumber":
        valid =
          /^[0-9\b]+$/.test(event.target.value) || event.target.value === "";
        if (valid && event.target.value.length < 13) {
          setphoneNo(event.target.value);
        }
        break;
      default:
        break;
    }
  };

  const handleRemoveImg = () => {
    setFile("");
  };
  /* eslint-disable */
  useEffect(() => {
    userInfo && setName(userInfo.name);
    userInfo && setEmail(userInfo.email);
    userInfo && userInfo.contactNo !== null && setphoneNo(userInfo.contactNo);
    userInfo && setshowFile(userInfo.image);
  }, [success]);

  const userd = {
    name: name ? name : userInfo.name,
    contactNo: phoneNo,
    image: file || file === "" ? file : showFile ? showFile : userInfo.image,
  };

  useEffect(() => {
    appear === "save" && userTab === 0 && dispatch(editUserDetails(userd));
    success === true &&
      enqueueSnackbar("User updated successfully", {
        variant: "success",
      });
  }, [appear]);

  useEffect(() => {
    if (uploadError > 1) {
      setFile("");
    }
  }, [uploadError]);

  useEffect(() => {
    if (typeImg !== null) {
      if (typeImg.type === "image/jpeg" || typeImg.type === "image/png") {
      } else {
        setFile("");
      }
    }
  }, [typeImg]);

  useEffect(() => {
    if (appear === undefined) {
      userInfo.contactNo && setphoneNo(userInfo.contactNo);
      userInfo.contactNo === "" && setphoneNo(userInfo.contactNo);
      userInfo.name && setName(userInfo.name);
      userInfo.image && setFile(userInfo.image);
    }
  }, [appear]);

  return (
    <Grid container direction="column">
      <Grid container direction="column" className={styles.mainContainer}>
        {loading && <Loader />}
        {loadDetails && <Loader />}
        <Grid item container alignItems="center" className={styles.titleC}>
          <Grid item lg={2} md={2}>
            <Typography className={styles.title}>Profile Details</Typography>
          </Grid>
          <Grid item container lg={10} md={10} justify="flex-end"></Grid>
        </Grid>
        <Grid item container className={styles.divider} />
        <Grid item container className={classes.container2}>
          <Grid item container lg={3} md={3} >
            <Grid item container md direction="column" lg={4} >
              <Grid item>
                <Typography style={{ fontSize: 14 }}>Profile Pic</Typography>
              </Grid>
              <Grid item>
                <Avatar
                  alt="img"
                  // src={file !== null ?showFile : file}
                  src={
                    file === ""
                      ? User
                      : file !== null
                      ? file
                      : showFile === ""
                      ? User
                      : showFile
                  }
                  className={styles.avatar}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              lg={8}
              md={8}
              direction="column"
              justify="flex-end"
            >
              <Grid
                item
                container
                direction="column"
                style={{ marginBottom: 12, marginLeft: -20 }}
              >
                {appear === true ? (
                  <>
                    <Grid item style={{ height: 30 }}>
                      <input
                        accept="image/png,image/jpeg"
                        className={classes.input}
                        id="uploadFile"
                        type="file"
                        style={{ display: "none" }}
                        onChange={onChangePic}
                      />
                      <label htmlFor="uploadFile">
                        <Button
                          className={styles.button}
                          // style={{ color: "#031CCA" }}
                          component="span"
                          startIcon={
                            <img
                              src={Reupload}
                              alt="Reupload"
                              className={classes.btnIcon}
                            />
                          }
                        >
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item style={{ height: 30 }}>
                      <Button
                        className={styles.button}
                        onClick={handleRemoveImg}
                        style={{ color: "#F82329" }}
                        startIcon={
                          appear === true ? (
                            <img
                              src={Remove}
                              alt="Remove"
                              className={classes.btnIcon}
                            />
                          ) : (
                            <img
                              src={disabledRemove}
                              alt="Remove"
                              className={classes.btnIcon}
                            />
                          )
                        }
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item style={{ height: 30 }}>
                      <Button
                        disabled
                        className={styles.button}
                        startIcon={
                          <img
                            src={disabledReupload}
                            alt="Reupload"
                            className={classes.btnIcon}
                          />
                        }
                      >
                        Upload
                      </Button>
                    </Grid>
                    <Grid item style={{ height: 30 }}>
                      <Button
                        disabled
                        className={styles.button}
                        startIcon={
                          <img
                            src={disabledRemove}
                            alt="Remove"
                            className={classes.btnIcon}
                          />
                        }
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm style={{ maxHeight: 33 }}>
            <Grid item lg={12}>
              <Typography className={classes.text}>Full Name</Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 3 }}>
              <TextField
                disabled={appear === true ? false : true}
                fullWidth
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{ classes: { input: classes.input1 } }}
                style={{
                  background: !appear
                    ? "#F4F4F4 0% 0% no-repeat padding-box"
                    : undefined,
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm>
            <Grid item lg={12} style={{ marginLeft: 17 }}>
              <Typography className={classes.text}>Email ID</Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 3, marginLeft: 17 }}>
              <TextField
                disabled={true}
                fullWidth
                variant="outlined"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{ classes: { input: classes.input1 } }}
                style={{ background: "#F4F4F4 0% 0% no-repeat padding-box" }}
              />
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm>
            <Grid item lg={12} style={{ marginLeft: 17 }}>
              <Typography className={classes.text}>Phone Number</Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 3, marginLeft: 17 }}>
              {/* <TextField
                disabled={appear === true ? false : true}
                fullWidth
                variant="outlined"
                size="small"
                id="phoneNumber"
                value={phoneNo}
                onChange={onChange}
                InputProps={{ classes: { input: classes.input1 } }}
                inputProps={{ inputmode: 'number', pattern: '[0-9]*' }}
                style={{
                  background: !appear
                    ? '#F4F4F4 0% 0% no-repeat padding-box'
                    : undefined,
                }}
              /> */}
              <ReactPhoneInput
                country={'sg'}
                value={phoneNo}
                onChange={phone => setphoneNo(phone)}
                disabled={appear === true ? false : true}
                // masks={{fr: '(...) ..-..-..', at: '(....) ...-....'}}
                inputStyle={{
                  background: !appear
                  ? '#F4F4F4 0% 0% no-repeat padding-box'
                  : undefined,
                  height:39
                }}
                placeholder=""
                // enableSearch
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ paddingLeft: 21 }}>
        {appear === true && (
          <Typography
            className={styles.errorUp}
            style={{
              color:
                uploadError > 1 ||
                (typeImg !== null && typeImg.type !== "image/jpeg") ||
                (typeImg !== null && typeImg.type !== "image/png")
                  ? "red"
                  : "black",
            }}
          >
            {(uploadError < 1 &&
              typeImg !== null &&
              typeImg.type === "image/jpeg") ||
            (typeImg !== null && typeImg.type === "image/png")
              ? ""
              : `Upload jpg, jpeg or png of size less than 1 MB`}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Profile;