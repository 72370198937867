/*eslint-disable */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { arrayToFilter, getTableData } from "../../../utils/common";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import {
  add_projDocument_Data,
  add_project_documents_sort,
} from "../../../redux/actions/documentAction";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getProjectList, updateServiceGroupMode } from "../../../redux/actions/projectAction";
import {
  getServiceGroupsListByProject,
  addServiceGroupSort,
  addServiceGroupHidden,
} from "../../../redux/actions/serviceGroupAction";
import {
  serviceGroupColumnHidden,
  serviceGroupColumnOrder,
  serviceGroupColumns,
  serviceGroupDefaultColumnWidths,
  serviceGroupTableColumnExtensions,
} from "../../../constants/serviceGroupConstants";
import {
  editProject,
  fetchLanguagesByProject,
  fetchLanguagesListData,
  fetchProjectById,
  fetchProjectByIdHierarchy,
  fetchServicesByProject,
  fetchTeamsByProject,
} from "../../../utils/project/projectUtils";
import {
  assignDeassignTeamToProject,
  fetchTeamListData,
} from "../../../utils/project/teamUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  arrayToSort,
  getWindowDimensions,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForProjectEdit from "../../../components/common/entitySchema/project/CustomFieldGroupsForProjectEdit";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { useLocation } from 'react-router-dom'
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EditProject = (props) => {
  const {
    project_document_list,
    add_projDocument_Data,
    projectDocumentFilter,
    projectDocumentSort,
    tabbedView,
    serviceGroupMode,
    updateServiceGroupMode
  } = props;
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const [projectId] = useState(props.match.params.projectId);
  const [languages, setLanguages] = useState([]);
  const [projectLanguages, setProjectLanguages] = useState([]);
  const [projectTeams, setProjectTeams] = useState([]);
  const [projectServices, setProjectServices] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  const [clickedTeam, setClickedTeam] = useState(
    teams === null ? [] : teams[0]
  );
  const [currentPageServiceGroup, setCurrentPageServiceGroup] = useState(1);
  const [serviceGroupsHierarchy, setServiceGroupsHierarchy] = useState([]);
  const [projectEntitySchema, setProjectEntitySchema] = useState([]);

  const [projectData, setProjectData] = useState({});
  const [currentPageProjectDocumentList, setcurrentPageProjectDocumentList] =
    useState(1);

  const [error, setError] = useState({
    name: { isError: false },
    languages: { isError: false },
  });

  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  const styles = manageStyles();
  const histroy = useHistory();
  const [teamTab, setTeamTab] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [sgStatuses, setSgStatuses] = useState([]);
  const [teamLoader, setTeamLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: serviceGroupColumns,
    widths: serviceGroupDefaultColumnWidths,
    extentions: serviceGroupTableColumnExtensions,
    orders: serviceGroupColumnOrder,
    hides: [],
  });

  const {
    getProjectList,
    getServiceGroupsListByProject,
    servicesGroupListByProject,
    serviceGroupSort,
    addServiceGroupSort,
    serviceGroupHidden,
    addServiceGroupHidden,
  } = props;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    histroy.replace({ ...histroy.location, state: { from: location?.state?.from } })
  }, [])


  useEffect(() => {
    if (location?.state?.activeCurrentTab) {
      setActiveTab(location?.state?.activeCurrentTab)
    }
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function createServiceGroupData(serviceGroupData) {
    const { _id, name, createdby, assignedto, status, createdon } =
      serviceGroupData;
    return {
      ...serviceGroupData,
      id: _id,
      name: name,
      createdby: createdby,
      createdon: createdon,
      assignedTo: assignedto,
      status: status,
      action: projectId,
      statuses: sgStatuses,
    };
  }

  function createDocumentTableData(docData) {
    const { _id, docName, filename, name, resourcetype, version, createdon, status } =
      docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
      action: projectId,
      status: status,
      statuses : statuses,
    };
  }

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsLoader(true)
    if (isEditMode) {
      checkScreenPermission("Project", ["Edit"]);
    } else {
      checkScreenPermission("Project", ["Read"]);
    }
    const fetchProjectEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Project",
        isEditMode ? "edit" : "read"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setProjectEntitySchema(entity);
        fetchProjectDetails();
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        setProjectData((prevData) => ({ ...prevData, ...projData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["documentationTable"];
        delete errData["deploymentTable"];
        delete errData["hirarchyTree"];
        delete errData["serviceGroups"];
        setError(errData);

      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchStatusesByResource = async (resource, callback) => {
      const { _msg, _status, data } = await fetchStatusResourceByName(resource);
      if (_status === 200) {
        callback(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchProjectDetails = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchProjectById(projectId);
      if (_status === 200) {
        setProjectData((prevData) => ({ ...prevData, ...data }));
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchLanguagesByProjectId = async () => {
      const { _msg, _status, data } = await fetchLanguagesByProject(projectId);
      if (_status === 200) {
        setProjectLanguages(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchServicesByProjectId = async () => {
      const { _msg, _status, data } = await fetchServicesByProject(projectId);
      if (_status === 200) {
        setProjectServices(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchLanguagesList = async () => {
      const { _msg, _status, data } = await fetchLanguagesListData();
      if (_status === 200) {
        setLanguages(
          data ? data.filter((item) => item.status === "active") : []
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchProjectEntitySchema();
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource("Project", (data) =>
        setStatuses(data ? data.status : [])
      );
      fetchStatusesByResource("ServiceGroup", (data) =>
        setSgStatuses(data ? data.status : [])
      );
    }
    fetchProjectHierarchy();
    fetchLanguagesByProjectId();
    fetchTeamByProjectId();
    fetchServicesByProjectId();
    if (checkCompPermission("Language", ["List All"])) {
      fetchLanguagesList();
    }
    if (checkCompPermission("Team", ["List All"])) {
      fetchTeamsList({});
    }
    // fetchDocuments();
  }, [isEditMode, projectId]);

  useEffect(() => {
    const fetchServiceGroupFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("ServiceGroup");
      if (_status === 200) {
        const servGData = getTableData(
          data,
          serviceGroupColumns,
          serviceGroupDefaultColumnWidths,
          serviceGroupTableColumnExtensions,
          serviceGroupColumnOrder,
          serviceGroupHidden
        );
        setTableData(servGData);
        if (
          JSON.stringify(serviceGroupHidden) ===
          JSON.stringify(serviceGroupColumnHidden)
        ) {
          addServiceGroupHidden(servGData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchServiceGroupFields();
  }, []);

  useEffect(() => {
    const parsedSort = arrayToSort(serviceGroupSort);
    getServiceGroupsListByProject(
      projectId,
      currentPageServiceGroup,
      JSON.stringify({}),
      JSON.stringify(parsedSort)
    );
  }, [currentPageServiceGroup, serviceGroupSort]);

  const fetchProjectHierarchy = async () => {
    const { _msg, _status, data } = await fetchProjectByIdHierarchy(projectId);
    if (_status === 200) {
      setServiceGroupsHierarchy(data?.servicegroups ? data?.servicegroups : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const serviceGroups =
    servicesGroupListByProject.data.data &&
      servicesGroupListByProject.data.data.length > 0
      ? servicesGroupListByProject.data.data.map((item) => {
        const rowData = createServiceGroupData(item);
        return rowData;
      })
      : [];

  const fetchTeamByProjectId = async () => {
    const { _msg, _status, data } = await fetchTeamsByProject(projectId);
    if (_status === 200) {
      setProjectTeams(data ? data : []);
      setSelectedTeams(data ? data : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      fetchTeamsList(
        JSON.stringify({ name: { $regex: `^${searchInput}`, $options: "i" } })
      );
    } else {
      fetchTeamsList();
    }
  };

  const fetchTeamsList = async (filter) => {
    setTeamLoader(true);
    const { _msg, _status, data } = await fetchTeamListData(1, filter);
    if (_status === 200) {
      setTeams(data ? data : []);
      setTeamLoader(false);
    } else {
      setTeamLoader(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const assignDeassingTeam = async (team, forType) => {
    const teamData = {
      teamid: team._id,
    };
    setIsLoader(true);
    const { _msg, _status, data } = await assignDeassignTeamToProject(
      projectId,
      teamData,
      forType
    );
    if (_status === 200) {
      if (forType === "assignteam") {
        setSelectedTeams([...selectedTeams, team]);
      } else {
        setSelectedTeams(selectedTeams.filter((item) => item._id !== team._id));
        setClickedTeam(selectedTeams.filter((item) => item._id !== team._id));
      }
      setIsLoader(false);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
    setIsLoader(false);
  };

  const handleSelectedTeamDelete = async (teamToDelete) => {
    await assignDeassingTeam(teamToDelete, "dismissteam");
    setSelectedTeams(
      selectedTeams.filter((team) => team._id !== teamToDelete._id)
    );
  };

  const handleTeamCheck = async (team) => {
    if (!selectedTeams.find((item) => item._id === team._id)) {
      await assignDeassingTeam(team, "assignteam");
    } else {
      await assignDeassingTeam(team, "dismissteam");
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPageProjectDocumentList, projectDocumentSort]);

  const fetchDocuments = () => {
    if (checkCompPermission("Document", ["List All", "List Assigned"])) {
      const parsedFilters = arrayToFilter([
        ...projectDocumentFilter,
        { resourcetype: "project" },
        { resourceid: projectId },
      ]);
      const parsedSort = arrayToSort(projectDocumentSort);
      add_projDocument_Data(
        currentPageProjectDocumentList,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  };

  const documents =
    project_document_list.data && project_document_list.data.length > 0
      ? project_document_list.data.map((item) => {
        const rowData = createDocumentTableData(item);
        return rowData;
      })
      : [];
  const handleProjectDocumentListPagination = (event, value) => {
    setcurrentPageProjectDocumentList(value);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setProjectData({
      ...projectData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(projectData, error);
    if (formIsValid) {
      const newProjectData = {
        ...projectData,
        languages: projectLanguages.map((item) => item._id),
      };
      delete newProjectData["_id"];
      delete newProjectData["createdby"];
      delete newProjectData["createdon"];
      delete newProjectData["servicegroupdetails"];
      delete newProjectData["teams"];
      delete newProjectData["updatedby"];
      delete newProjectData["updatedon"];
      setIsLoader(true);
      const { _msg, _status, data } = await editProject(
        projectId,
        newProjectData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        fetchTeamByProjectId();
        getProjectList();
        setIsLoader(false);
        histroy.push(`/projects/details/${projectId}`, { from: location?.state?.from });
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(projectEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setProjectEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("There are form field error(s), please correct the input values and submit", {
        variant: "error",
      });
    }
  };

  const handleChangeTeamTab = (event, newValue) => {
    setTeamTab(newValue);
  };

  const handleLanguageDelete = (chipToDelete) => {
    setProjectLanguages(
      projectLanguages.filter((chip) => chip._id !== chipToDelete._id)
    );
    setProjectData({
      ...projectData,
      languages: projectData.languages.filter((chip) => chip !== chipToDelete._id),
    });
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
    updateServiceGroupMode({ value: "" })
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Projects", path: "/projects" },
              {
                title: isEditMode
                  ? `Edit ${projectData.name}`
                  : projectData.name,
              },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>
              {isEditMode ? `Edit ${projectData.name}` : projectData.name}
            </h2>

            <div style={{ display: 'flex' }}>
              {tabbedView ?

                <>
                  {
                    <TabbedNavigation
                      handleNext={() => setActiveTab((prev) => prev + 1)}
                      handlePrev={() => setActiveTab((prev) => prev - 1)}
                      handleEdit={() => histroy.push(`/projects/edit/${projectId}`)}
                      handleCancel={() => location?.state?.from === 'create' ? histroy.push('/projects') : histroy.goBack()}
                      schemaLength={projectEntitySchema?.length}
                      activeTab={activeTab}
                      isClose={isEditMode ? false : true}
                      isEditMode={isEditMode}
                      handleSave={handleSubmit}
                    />
                  }
                </>
                : <>
                  <AccordionNavigation
                    isEditMode={isEditMode ? false : true}
                    isClose={isEditMode ? false : true}
                    handleCancel={() => location?.state?.from === 'create' ? histroy.push('/projects') : histroy.goBack()}
                    disabled={!checkCompPermission("Project", ["Edit"])}
                    handleEdit={() => histroy.push(`/projects/edit/${projectId}`)}
                    handleSave={handleSubmit}
                  />
                </>}
            </div>
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {/* With Entity Schema */}
          {tabbedView ? <>
            <SectionTabs
              entity={projectEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20 }}>
              <div style={{ padding: "0px 5px 0" }}>
                <CustomFieldGroupsForProjectEdit
                  section={projectEntitySchema[activeTab]}
                  isEditMode={isEditMode}
                  projectData={projectData}
                  serviceGroupsHierarchy={serviceGroupsHierarchy}
                  error={error}
                  statuses={statuses}
                  projectId={projectId}
                  serviceGroups={serviceGroups}
                  servicesGroupListByProject={servicesGroupListByProject}
                  selectedTeams={selectedTeams}
                  teams={teams}
                  tableLoading={tableLoading}
                  serviceGroupHidden={serviceGroupHidden}
                  addServiceGroupHidden={addServiceGroupHidden}
                  tableData={tableData}
                  projectTeams={projectTeams}
                  clickedTeam={clickedTeam}
                  teamTab={teamTab}
                  teamSearchInput={teamSearchInput}
                  languages={languages}
                  teamLoader={teamLoader}
                  projectLanguages={projectLanguages}
                  projectServices={projectServices}
                  documents={documents}
                  windowDimensions={windowDimensions}
                  sort={serviceGroupSort}
                  handleTeamCheck={handleTeamCheck}
                  setCurrentPageServiceGroup={setCurrentPageServiceGroup}
                  handleProjectDocumentListPagination={
                    handleProjectDocumentListPagination
                  }
                  currentPageServiceGroup={currentPageServiceGroup}
                  handleTeamSearch={handleTeamSearch}
                  handleSelectedTeamDelete={(team) =>
                    handleSelectedTeamDelete(team)
                  }
                  setSort={addServiceGroupSort}
                  handleChangeTeamTab={handleChangeTeamTab}
                  setProjectData={setProjectData}
                  setProjectLanguages={setProjectLanguages}
                  setTeamSearchInput={setTeamSearchInput}
                  handleLanguageDelete={handleLanguageDelete}
                  setClickedTeam={setClickedTeam}
                  fetchDocuments={fetchDocuments}
                  handleChange={handleChange}
                  project_document_list={project_document_list}
                />
              </div>
            </Paper>
          </> : <>
            {projectEntitySchema?.length !== 0 &&
              projectEntitySchema?.map((section) => {
                if (section.name === "Project_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupsForProjectEdit
                        section={section}
                        isEditMode={isEditMode}
                        projectData={projectData}
                        error={error}
                        setProjectData={setProjectData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForProjectEdit
                          section={section}
                          isEditMode={isEditMode}
                          projectData={projectData}
                          serviceGroupsHierarchy={serviceGroupsHierarchy}
                          error={error}
                          statuses={statuses}
                          projectId={projectId}
                          serviceGroups={serviceGroups}
                          servicesGroupListByProject={servicesGroupListByProject}
                          selectedTeams={selectedTeams}
                          teams={teams}
                          tableLoading={tableLoading}
                          serviceGroupHidden={serviceGroupHidden}
                          addServiceGroupHidden={addServiceGroupHidden}
                          tableData={tableData}
                          projectTeams={projectTeams}
                          clickedTeam={clickedTeam}
                          teamTab={teamTab}
                          teamSearchInput={teamSearchInput}
                          languages={languages}
                          teamLoader={teamLoader}
                          projectLanguages={projectLanguages}
                          projectServices={projectServices}
                          documents={documents}
                          windowDimensions={windowDimensions}
                          sort={serviceGroupSort}
                          handleTeamCheck={handleTeamCheck}
                          setCurrentPageServiceGroup={setCurrentPageServiceGroup}
                          handleProjectDocumentListPagination={
                            handleProjectDocumentListPagination
                          }
                          currentPageServiceGroup={currentPageServiceGroup}
                          handleTeamSearch={handleTeamSearch}
                          handleSelectedTeamDelete={(team) =>
                            handleSelectedTeamDelete(team)
                          }
                          setSort={addServiceGroupSort}
                          handleChangeTeamTab={handleChangeTeamTab}
                          setProjectData={setProjectData}
                          setProjectLanguages={setProjectLanguages}
                          setTeamSearchInput={setTeamSearchInput}
                          handleLanguageDelete={handleLanguageDelete}
                          setClickedTeam={setClickedTeam}
                          fetchDocuments={fetchDocuments}
                          handleChange={handleChange}
                          project_document_list={project_document_list}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}

          </>}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addServiceGroupSort,
  getServiceGroupsListByProject,
  add_projDocument_Data,
  add_project_documents_sort,
  addServiceGroupHidden,
  updateServiceGroupMode

};

const mapStateToProps = (state) => {
  const { servicesGroupListByProject, serviceGroupSort, serviceGroupHidden } =
    state.serviceGroupReducer;
  const { project_document_list, projectDocumentSort, projectDocumentFilter } =
    state.allDocumentsReducer;
  const { tabbedView } = state.userDetails.userInfo;
  const { serviceGroupMode } = state.projectReducer
  return {
    servicesGroupListByProject,
    serviceGroupSort,
    projectDocumentFilter,
    project_document_list,
    projectDocumentSort,
    serviceGroupHidden,
    tabbedView,
    serviceGroupMode
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
