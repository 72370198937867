import {
  ADD_ORGANIZATION_DATA,
  ORGANIZATIONS_FILTER,
  ORGANIZATIONS_SORT,
  ACTIVE_INACTIVE_ORG,
  ADD_CURRENT_ORGANIZATION_DATA,
  CHANGE_LOGO,
  ADD_CLOUD_CONFIG_LIST,
  DELETE_CLOOUD_CONFIG_BY_ID,
  UPDATE_CLOUD_CONFIG_BY_ID,
  UPDATE_INACTIVITY_TIME
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  org_list: {
    data: [],
    error: false,
    loading: false,
  },
  organizationFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  organizationSort: [{ columnName: "createdon", direction: "desc" }],
  currentOrgData: {
    title: "",
  },
  cloudConfigList: {
    data: [],
    error: false,
    loading: false,
  }
};

export default function organizationReducer(state = initialState, action) {
  switch (action.type) {

    case ADD_CLOUD_CONFIG_LIST: {
      return {
        ...state,
        cloudConfigList: {
          ...state.cloudConfigList,
          ...action.payload,
        },
      };
    }
    case DELETE_CLOOUD_CONFIG_BY_ID: {
      return {
        ...state,
        cloudConfigList: {
          ...state.cloudConfigList,
          data: state.cloudConfigList.data.filter(
            (serv) => serv._id !== action.payload.cloudConfigId
          ),
          _totalcount: state.cloudConfigList._totalcount - 1,

        },
      };
    }

    case UPDATE_CLOUD_CONFIG_BY_ID: {
      return {
        ...state,
        cloudConfigList: {
          ...state.cloudConfigList,
          data: state.cloudConfigList.data.map(
            (serv) => {
              if(serv._id === action.payload.configData._id){
                serv = action.payload.configData
              }
              return serv
            }
          ),
        },
      };
    }

    case ADD_ORGANIZATION_DATA: {
      return {
        ...state,
        org_list: {
          ...state.org_list,
          ...action.payload,
        },
      };
    }
    case ORGANIZATIONS_FILTER: {
      return {
        ...state,
        organizationFilter: [...action.payload],
      };
    }
    case ORGANIZATIONS_SORT: {
      return {
        ...state,
        organizationSort: [...action.payload],
      };
    }
    case ACTIVE_INACTIVE_ORG: {
     
      return {
        ...state,
        org_list: {
          ...state.org_list,
          data:
            state.org_list.data &&
            state.org_list.data.map((item) => {
              if (item._id === action.payload.orgID) {
                return {
                  ...item,
                  status: action.payload.status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case ADD_CURRENT_ORGANIZATION_DATA: {
      return {
        ...state,
        currentOrgData: { ...action.payload },
      };
    }
    case UPDATE_INACTIVITY_TIME: {
      return {
        ...state,
        currentOrgData:{data : {...state.currentOrgData.data, containerinactivity: action.payload}
}      }
    }
    case CHANGE_LOGO: {
      return {
        ...state,
        currentOrgData: {
          data: {
            ...state.currentOrgData.data,
            logo: action.logo,
            favicon: action.favicon,
            title: action.title,
          },
        },
      };
    }
    default:
      return state;
  }
}
