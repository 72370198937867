import React from "react";
import { Grid, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import AddFieldTableSection from "./AddFieldTableSection";

const CustomFieldGroupsForGlossaryFieldCreate = ({
  section,
  isEditMode,
  fieldGroupData,
  error,
  handleChange,
  tab,
  handleChangeTab,
  listStyle,
  fieldTable,
  fieldGroupTable,
  setSearchInput,
  handleSearch,
  handleAllFieldCheck,
  isAllFields,
  setIsAllFields,
  handleFieldCheck,
  dataStyles,
  setModalItem,
  setOpenFieldModal,
  isAllFieldGroups,
  handleAllFieldGroupCheck,
  setIsAllFieldGroups,
  handleFieldGroupCheck,
  setOpenFieldGroupModal,
  fieldCount,
  fieldGroupCount,
  fieldTableOrg,
  fieldGroupTableOrg,
  modalItem,
  openFieldModal,
  openFieldGroupModal,
  tabbedView,
  treeData,
  setTreeData
}) => {
  const styles = manageStyles();

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Glossary_Field_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView ? {} : { marginTop: "0rem" /*display: "block"*/ }
                  }
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.uiElementType) {
                        case "addFieldTable":
                          return (
                            <AddFieldTableSection
                              styles={styles}
                              tab={tab}
                              handleChangeTab={handleChangeTab}
                              listStyle={listStyle}
                              fieldTable={fieldTable}
                              fieldGroupTable={fieldGroupTable}
                              setSearchInput={setSearchInput}
                              handleSearch={handleSearch}
                              handleAllFieldCheck={handleAllFieldCheck}
                              isAllFields={isAllFields}
                              setIsAllFields={setIsAllFields}
                              handleFieldCheck={handleFieldCheck}
                              dataStyles={dataStyles}
                              setModalItem={setModalItem}
                              setOpenFieldModal={setOpenFieldModal}
                              isAllFieldGroups={isAllFieldGroups}
                              handleAllFieldGroupCheck={
                                handleAllFieldGroupCheck
                              }
                              setIsAllFieldGroups={setIsAllFieldGroups}
                              handleFieldGroupCheck={handleFieldGroupCheck}
                              setOpenFieldGroupModal={setOpenFieldGroupModal}
                              fieldCount={fieldCount}
                              fieldGroupCount={fieldGroupCount}
                              fieldTableOrg={fieldTableOrg}
                              fieldGroupTableOrg={fieldGroupTableOrg}
                              modalItem={modalItem}
                              openFieldModal={openFieldModal}
                              openFieldGroupModal={openFieldGroupModal}
                              treeData={treeData}
                              setTreeData={setTreeData}
                            />
                          );
                        case "Dropdown":
                          return field.name === "FieldGroup Type" ? (
                            <Grid item xs={3} sm={6} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <div
                                className={`${styles.fieldgroupradiowrapper} ${
                                  styles.toggleRadioBtn
                                } ${
                                  error[field.datakey]?.isError && styles.error
                                }`}
                                style={{
                                  width: "auto",
                                  height: "38px",
                                  alignItems: "center",
                                  paddingTop: "5px",
                                }}
                              >
                                <RadioGroup
                                  name={field.datakey}
                                  value={fieldGroupData[field.datakey]}
                                  onChange={handleChange}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <FormControlLabel
                                    value="object"
                                    control={<Radio color="primary" />}
                                    label="Object"
                                    className={`${
                                      fieldGroupData[field.datakey] === "object"
                                        ? "toggleActive"
                                        : ""
                                    } ${styles.toggleRadioBtn}`}
                                  />
                                  <FormControlLabel
                                    value="array"
                                    control={<Radio color="primary" />}
                                    label="Array"
                                    style={{ marginLeft: "auto" }}
                                    className={`${
                                      fieldGroupData[field.datakey] === "array"
                                        ? "toggleActive"
                                        : ""
                                    }`}
                                  />
                                </RadioGroup>
                              </div>
                              {error[field.datakey]?.isError && (
                                <p
                                  className={styles.errorMsg}
                                  style={{ marginTop: "4px" }}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {field.fieldLabel}
                                  </span>{" "}
                                  is required
                                </p>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          );
                        case "custom":
                          return field.name === "FieldGroup AddFieldTable" &&
                            isEditMode ? (
                            <AddFieldTableSection
                              styles={styles}
                              tab={tab}
                              handleChangeTab={handleChangeTab}
                              listStyle={listStyle}
                              fieldTable={fieldTable}
                              fieldGroupTable={fieldGroupTable}
                              setSearchInput={setSearchInput}
                              handleSearch={handleSearch}
                              handleAllFieldCheck={handleAllFieldCheck}
                              isAllFields={isAllFields}
                              setIsAllFields={setIsAllFields}
                              handleFieldCheck={handleFieldCheck}
                              dataStyles={dataStyles}
                              setModalItem={setModalItem}
                              setOpenFieldModal={setOpenFieldModal}
                              isAllFieldGroups={isAllFieldGroups}
                              handleAllFieldGroupCheck={
                                handleAllFieldGroupCheck
                              }
                              setIsAllFieldGroups={setIsAllFieldGroups}
                              handleFieldGroupCheck={handleFieldGroupCheck}
                              setOpenFieldGroupModal={setOpenFieldGroupModal}
                              fieldCount={fieldCount}
                              fieldGroupCount={fieldGroupCount}
                              fieldTableOrg={fieldTableOrg}
                              fieldGroupTableOrg={fieldGroupTableOrg}
                              modalItem={modalItem}
                              openFieldModal={openFieldModal}
                              openFieldGroupModal={openFieldGroupModal}
                              treeData={treeData}
                              setTreeData={setTreeData}
                            />
                          ) : (
                            <></>
                          );
                        default:
                          return field.name === "Created on" ||
                            field.datakey === "createdon" ||
                            field.name === "Created by" ||
                            field.datakey === "createdby" ||
                            field.name === "Updated on" ||
                            field.datakey === "updatedon" ||
                            field.name === "Updated by" ||
                            field.datakey === "updatedby" ||
                            field.name === "FieldGroup Fields" ||
                            field.name === "FieldGroup Status" ? (
                            <></>
                          ) : (
                            <Grid item xs={3} sm={3} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <input
                                name={field.datakey}
                                value={fieldGroupData[field.datakey]}
                                onChange={handleChange}
                                placeholder={field.placeholder || " "}
                                className={`${styles.input} ${
                                  !isEditMode && styles.editMode
                                } ${
                                  error[field.datakey]?.isError && styles.error
                                } ${
                                  field.editable === "FALSE" && styles.editMode
                                }`}
                                disabled={
                                  !isEditMode || field.editable === "FALSE"
                                }
                                autoComplete="off"
                                type="text"
                              />
                              {error[field.datakey]?.isError && (
                                <p className={styles.errorMsg}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {field.fieldLabel}
                                  </span>{" "}
                                  is required
                                </p>
                              )}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.uiElementType) {
                              case "addFieldTable":
                                return (
                                  <AddFieldTableSection
                                    styles={styles}
                                    tab={tab}
                                    handleChangeTab={handleChangeTab}
                                    listStyle={listStyle}
                                    fieldTable={fieldTable}
                                    fieldGroupTable={fieldGroupTable}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleAllFieldCheck={handleAllFieldCheck}
                                    isAllFields={isAllFields}
                                    setIsAllFields={setIsAllFields}
                                    handleFieldCheck={handleFieldCheck}
                                    dataStyles={dataStyles}
                                    setModalItem={setModalItem}
                                    setOpenFieldModal={setOpenFieldModal}
                                    isAllFieldGroups={isAllFieldGroups}
                                    handleAllFieldGroupCheck={
                                      handleAllFieldGroupCheck
                                    }
                                    setIsAllFieldGroups={setIsAllFieldGroups}
                                    handleFieldGroupCheck={
                                      handleFieldGroupCheck
                                    }
                                    setOpenFieldGroupModal={
                                      setOpenFieldGroupModal
                                    }
                                    fieldCount={fieldCount}
                                    fieldGroupCount={fieldGroupCount}
                                    fieldTableOrg={fieldTableOrg}
                                    fieldGroupTableOrg={fieldGroupTableOrg}
                                    modalItem={modalItem}
                                    openFieldModal={openFieldModal}
                                    openFieldGroupModal={openFieldGroupModal}
                                    treeData={treeData}
                                    setTreeData={setTreeData}
                                  />
                                );
                              case "Dropdown":
                                return field.name === "FieldGroup Type" ? (
                                  <Grid item xs={3} sm={6} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <div
                                      className={`${
                                        styles.fieldgroupradiowrapper
                                      } ${styles.toggleRadioBtn} ${
                                        error[field.datakey]?.isError &&
                                        styles.error
                                      }`}
                                      style={{
                                        width: "auto",
                                        height: "38px",
                                        alignItems: "center",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      <RadioGroup
                                        name={field.datakey}
                                        value={fieldGroupData[field.datakey]}
                                        onChange={handleChange}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          width: "100%",
                                        }}
                                      >
                                        <FormControlLabel
                                          value="object"
                                          control={<Radio color="primary" />}
                                          label="Object"
                                          className={`${
                                            fieldGroupData[field.datakey] ===
                                            "object"
                                              ? "toggleActive"
                                              : ""
                                          } ${styles.toggleRadioBtn}`}
                                        />
                                        <FormControlLabel
                                          value="array"
                                          control={<Radio color="primary" />}
                                          label="Array"
                                          style={{ marginLeft: "auto" }}
                                          className={`${
                                            fieldGroupData[field.datakey] ===
                                            "array"
                                              ? "toggleActive"
                                              : ""
                                          }`}
                                        />
                                      </RadioGroup>
                                    </div>
                                    {error[field.datakey]?.isError && (
                                      <p
                                        className={styles.errorMsg}
                                        style={{ marginTop: "4px" }}
                                      >
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {field.fieldLabel}
                                        </span>{" "}
                                        is required
                                      </p>
                                    )}
                                  </Grid>
                                ) : (
                                  <></>
                                );
                              case "custom":
                                return field.name ===
                                  "FieldGroup AddFieldTable" && isEditMode ? (
                                  <AddFieldTableSection
                                    styles={styles}
                                    tab={tab}
                                    handleChangeTab={handleChangeTab}
                                    listStyle={listStyle}
                                    fieldTable={fieldTable}
                                    fieldGroupTable={fieldGroupTable}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleAllFieldCheck={handleAllFieldCheck}
                                    isAllFields={isAllFields}
                                    setIsAllFields={setIsAllFields}
                                    handleFieldCheck={handleFieldCheck}
                                    dataStyles={dataStyles}
                                    setModalItem={setModalItem}
                                    setOpenFieldModal={setOpenFieldModal}
                                    isAllFieldGroups={isAllFieldGroups}
                                    handleAllFieldGroupCheck={
                                      handleAllFieldGroupCheck
                                    }
                                    setIsAllFieldGroups={setIsAllFieldGroups}
                                    handleFieldGroupCheck={
                                      handleFieldGroupCheck
                                    }
                                    setOpenFieldGroupModal={
                                      setOpenFieldGroupModal
                                    }
                                    fieldCount={fieldCount}
                                    fieldGroupCount={fieldGroupCount}
                                    fieldTableOrg={fieldTableOrg}
                                    fieldGroupTableOrg={fieldGroupTableOrg}
                                    modalItem={modalItem}
                                    openFieldModal={openFieldModal}
                                    openFieldGroupModal={openFieldGroupModal}
                                    treeData={treeData}
                                    setTreeData={setTreeData}
                                  />
                                ) : (
                                  <></>
                                );
                              default:
                                return field.name === "Created on" ||
                                  field.datakey === "createdon" ||
                                  field.name === "Created by" ||
                                  field.datakey === "createdby" ||
                                  field.name === "Updated on" ||
                                  field.datakey === "updatedon" ||
                                  field.name === "Updated by" ||
                                  field.datakey === "updatedby" ||
                                  field.name === "FieldGroup Fields" ||
                                  field.name === "FieldGroup Status" ? (
                                  <></>
                                ) : (
                                  <Grid item xs={3} sm={3} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      name={field.datakey}
                                      value={fieldGroupData[field.datakey]}
                                      onChange={handleChange}
                                      placeholder={field.placeholder || " "}
                                      className={`${styles.input} ${
                                        !isEditMode && styles.editMode
                                      } ${
                                        error[field.datakey]?.isError &&
                                        styles.error
                                      } ${
                                        field.editable === "FALSE" &&
                                        styles.editMode
                                      }`}
                                      disabled={
                                        !isEditMode ||
                                        field.editable === "FALSE"
                                      }
                                      autoComplete="off"
                                      type="text"
                                    />
                                    {error[field.datakey]?.isError && (
                                      <p className={styles.errorMsg}>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {field.fieldLabel}
                                        </span>{" "}
                                        is required
                                      </p>
                                    )}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}{" "}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={fieldGroupData[field.datakey]}
                                  onChange={handleChange}
                                  placeholder={field.placeholder || " "}
                                  className={`${styles.textarea} ${
                                    !isEditMode && styles.editMode
                                  } ${
                                    error[field.datakey]?.isError &&
                                    styles.error
                                  } ${
                                    field.editable === "FALSE" &&
                                    styles.editMode
                                  }`}
                                  disabled={
                                    !isEditMode || field.editable === "FALSE"
                                  }
                                  rows="7"
                                />
                                {error[field.datakey]?.isError && (
                                  <p className={styles.errorMsg}>
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {field.fieldLabel}
                                    </span>{" "}
                                    is required
                                  </p>
                                )}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(
  CustomFieldGroupsForGlossaryFieldCreate
);
