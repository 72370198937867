import React, { useState } from 'react';
import Columns from '../../../atoms/rightSide/settings/Columns';
import { useDispatch } from 'react-redux';
import { editComponent } from '../../../../../redux/actions/uiApplicationAction';
import CustomModal from '../../../atoms/customModal/CustomModal';
import useGetData from '../../../../hooks/useGetData';
import JSDataInput from '../../../atoms/rightSide/settings/JSDataInput';
import AccessDataInput from '../../../atoms/rightSide/settings/AccessDataInput';
// import DataInput from "../../../atoms/rightSide/settings/DataInput";
import { v4 as uuid } from 'uuid';

function TableSettings() {
  const {
    currentPage,
    currentComponent,
    executeJavascript,
    getDataValue,
    globalAcessModeChange,
  } = useGetData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [accessModeErr, setAccessModeErr] = useState('');

  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes('return')) {
        dataValue = executeJavascript(value);
        setError('');
      } else {
        dataValue = getDataValue(value);
        setError('');
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id && dataValue && dataValue.length) {
        const columns = Object?.keys(
          dataValue && dataValue.length && dataValue[0]
        ).map((col) => ({
          id: uuid(),
          name: col,
          isHide: false,
          isDelete: false,
          title: col.charAt(0).toUpperCase() + col.slice(1),
          width: '',
        }));
        return {
          ...component,
          data: {
            dataInput: value,
            dataValue: dataValue,
          },
          columns: columns,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      {isModalOpen ? <CustomModal setIsModalOpen={setIsModalOpen} /> : null}
      <div className="field">
        <JSDataInput
          value={currentComponent?.data}
          onChange={handleChange}
          label="Data"
          name="data"
          title={`${currentComponent.name} : Data`}
          error={error}
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Title"
          name="title"
          value={currentComponent?.title}
          onChange={(value) =>
            globalAcessModeChange(value, 'title', setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      {currentComponent?.type === 'Table' && (
        <div className="field">
          <div className="tableColumns">
            <label>Columns</label>
            <Columns columns={currentComponent?.columns} />
          </div>
        </div>
      )}
    </div>
  );
}

export default TableSettings;
