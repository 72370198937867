import { ADD_API_LIST_DATA, ADD_SERVICE_LIST_DATA, ADD_VERSION_LIST_DATA, ALTER_FAVOURITE_SERVICE_BY_ID, ALTER_SUBSCRIBE_SERVICE_BY_ID, API_HIDDEN, API_SORT, CLEAR_API, CLEAR_SERVICES, CLEAR_VERSION, CLOSE_API, CLOSE_VERSION, DELETE_API_BY_ID, DELETE_SERVICE_BY_ID, DELETE_VERSION_BY_ID, EDIT_API, EDIT_VERSION, SELECT_API_CONTAINER, SERVICE_FILTERS, SERVICE_HIDDEN, SERVICE_SORT, SET_SELECTED_APIID, SET_SELECTED_VERSIONID, VERSION_HIDDEN, VERSION_LOADING, VERSION_SORT,TABBED_ACTIVE_TAB } from '../actionTypes';
import { v4 as uuidv4 } from "uuid";

const initialState = {
    servicesList: {
        data: [],
        error: false,
        loading: false
    },
    versionsList: {
        data: [],
        error: false,
        loading: false
    },
    apisList: {
        data: [],
        error: false,
        loading: false
    },
    serviceFilters: [
        { id: uuidv4(), column: '', operator: '', value: '' }
    ],
    serviceSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    serviceHidden: ["name", "createdby", "createdon", "status", "action"],
    versionSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    versionHidden: ["versionTag", "port", "fileName", "imageStatus", "action"],
    apiSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    apiHidden: ["name", "type", "createdby", "createdon", "action"],
    selectionVersionId: null,
    editVersion: false,
    detailVersion:false,
    selectedAPIId: null,
    detailAPI:false,
    editAPI:false,
    tabbedActiveTab:0
    
}

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SERVICE_LIST_DATA: {
            return {
                ...state,
                servicesList: {
                    ...state.servicesList,
                    ...action.payload,
                    deleteServiceStatus: false,
                }
            }
        }
        case TABBED_ACTIVE_TAB:{
            return {
                ...state,
                tabbedActiveTab:action.payload
            }
        }
        case SERVICE_FILTERS: {
            return {
                ...state,
                serviceFilters: [
                    ...action.payload
                ]
            }
        }
        case SERVICE_SORT: {
            return {
                ...state,
                serviceSort: [
                    ...action.payload
                ]
            }
        }
        case SERVICE_HIDDEN: {
            return {
                ...state,
                serviceHidden: [
                    ...action.payload
                ]
            }
        }
        case ALTER_FAVOURITE_SERVICE_BY_ID: {
            return {
                ...state,
                servicesList: {
                    ...state.servicesList,
                    data: {
                        ...state.servicesList.data,
                        data: state.servicesList.data.data.length !== 0 && state.servicesList.data.data.map(serv => {
                            if (serv._id === action.payload.serviceId) {
                                return { ...serv, isFavourite: !serv.isFavourite }
                            } else {
                                return serv
                            }
                        })
                    }
                }
            }
        }
        case ALTER_SUBSCRIBE_SERVICE_BY_ID: {
            return {
                ...state,
                servicesList: {
                    ...state.servicesList,
                    data: {
                        ...state.servicesList.data,
                        data: state.servicesList.data.data.length !== 0 && state.servicesList.data.data.map(serv => {
                            if (serv._id === action.payload.serviceId) {
                                return { ...serv, isSubscribe: !serv.isSubscribe }
                            } else {
                                return serv
                            }
                        })
                    }
                }
            }
        }
        case DELETE_SERVICE_BY_ID: {
            return {
                ...state,
                servicesList: {
                    ...state.servicesList,
                    data: {
                        ...state.servicesList.data,
                        data: state.servicesList.data.data.filter(serv => serv._id !== action.payload.serviceId)
                    },
                    _totalcount: state.servicesList._totalcount - 1,
                    deleteServiceStatus: true,
                }
            }
        }
        case CLEAR_SERVICES: {
            return {
                ...state,
                servicesList: {}
            }
        }
        case ADD_VERSION_LIST_DATA: {
            return {
                ...state,
                versionsList: {
                    ...state.versionsList,
                    ...action.payload
                }
            }
        }
        case VERSION_SORT: {
            return {
                ...state,
                versionSort: [
                    ...action.payload
                ]
            }
        }
        case VERSION_HIDDEN: {
            return {
                ...state,
                versionHidden: [
                    ...action.payload
                ]
            }
        }
        case VERSION_LOADING: {
            return {
                ...state,
                versionsList: {
                    ...state.versionsList,
                    loading: action.payload
                }
            }
        }
        case DELETE_VERSION_BY_ID: {
            return {
                ...state,
                versionsList: {
                    ...state.versionsList,
                    data: {
                        ...state.versionsList.data,
                        data: state.versionsList.data.data.filter(ver => ver._id !== action.payload.versionId)
                    }
                }
            }
        }
        case CLEAR_VERSION: {
            return {
                ...state,
                versionsList: {}
            }
        }
        case ADD_API_LIST_DATA: {
            return {
                ...state,
                apisList: {
                    ...state.apisList,
                    ...action.payload
                }
            }
        }
        case API_SORT: {
            return {
                ...state,
                apiSort: [
                    ...action.payload
                ]
            }
        }
        case API_HIDDEN: {
            return {
                ...state,
                apiHidden: [
                    ...action.payload
                ]
            }
        }
        case DELETE_API_BY_ID: {
            return {
                ...state,
                apisList: {
                    ...state.apisList,
                    data: {
                        ...state.apisList.data,
                        data: state.apisList.data?.data?.filter(api => api._id !== action.payload.apiId)
                    }
                }
            }
        }
        case CLEAR_API: {
            return {
                ...state,
                apisList: {}
            }
        }
        case SELECT_API_CONTAINER: {
            return {
                ...state,
                apisList: {
                    ...state.apisList,
                    data: 
                         state.apisList.data.map(api => {
                            if (api._id === action.payload.apiId) {
                                return { ...api, selectedInstance: action.payload.selectedInstance }
                            } else {
                                return api
                            }
                        })
                    
                }
            }
        }

        case EDIT_VERSION: {
            return {
              ...state,
              selectedVersionId: action.payload,
              editVersion:!state.editVersion,
              detailVersion:true,
            };
          }
          case EDIT_API: {
              return {
                ...state,
                selectedAPIId: action.payload,
                editAPI:!state.editAPI,
                detailAPI:true,
              };
            }
      
          // case DETAIL_VERSION: {
          //   return {
          //     ...state,
          //     detailVersion: !state.detailVersion,
          //   };
          // }
          case SET_SELECTED_VERSIONID: {
            return {
              ...state,
              selectedVersionId: action.payload,
              editVersion: false,
              detailVersion:true
            };
          }
      
          case SET_SELECTED_APIID: {
              return {
                ...state,
                selectedAPIId: action.payload,
                editAPI: false,
                detailAPI:true
              };
            }
            
          
          case CLOSE_VERSION: {
              return {
                ...state,
                selectedVersionId: null,
                editVersion: false,
                detailVersion:false
              };
            }
      
            case CLOSE_API: {
              return {
                ...state,
                selectedAPIId: null,
                editAPI: false,
                detailAPI:false
              };
            }
        default:
            return state;
    }
}

export default serviceReducer;
