import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import { AppButton } from "../button/AppButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import useTriggers from "../../../hooks/useTriggers";
import { useSelector } from "react-redux";

const CustomButton = ({ id }) => {
  const history = useHistory();
  const { currentProject, getComponent } = useGetData();
  const { handleTrigger } = useTriggers();
  const currentComponent = getComponent(id);
  const params = useParams();
  const uiAppsList = useSelector(
    (state) => state.uiApplicationListReducer.data
  );
  const viewType = params.view !== undefined ? params.view + "/" : "";
  const handleClick = () => {
    if (currentComponent["onClick"]) {
      handleTrigger({
        action: currentComponent?.["onClick"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }

    const queryParams = {};
    if (currentComponent?.queryParamsJSMode) {
      if (Array.isArray(currentComponent?.jsQueryParams?.dataValue)) {
        currentComponent?.jsQueryParams?.dataValue?.forEach((param) => {
          Object.keys(param).forEach((element) => {
            queryParams[element] = param[element];
          });
        });
      } else if (
        Object.keys(currentComponent?.jsQueryParams?.dataValue).length
      ) {
        Object.keys(currentComponent?.jsQueryParams?.dataValue).forEach(
          (element) => {
            queryParams[element] =
              currentComponent?.jsQueryParams?.dataValue[element];
          }
        );
      }
    } else {
      currentComponent.queryParams.forEach((param) => {
        queryParams[param?.key] = param?.value;
      });
    }
    const newParams = new URLSearchParams(queryParams).toString();
    if (currentComponent?.urlType === "page") {
      if (currentComponent?.page) {
        if (currentComponent?.uiApplication?.id === currentProject.id) {
          history.push(
            `/ui-applications/${viewType}${
              currentComponent?.uiApplication?.id
            }/${
              currentProject?.pages?.find(
                (p) => p?.name === currentComponent?.page
              )?.url
            }${newParams !== "=" ? "?" + newParams : ""}${
              params?.device ? "/" + params?.device : ""
            }`
          );
        } else {
          window.location.href = `/ui-applications/${viewType}${
            currentComponent?.uiApplication?.id
          }/${
            uiAppsList
              .find((app) => app._id === currentComponent.uiApplication?.id)
              ?.pages.find((p) => p.name === currentComponent?.page)?.url
          }${newParams !== "=" ? "?" + newParams : ""}${
            params?.device ? "/" + params?.device : ""
          }`;
        }
      }
    } else {
      const link = currentComponent?.link?.dataValue;
      if (link) {
        window.open(
          typeof link === "string"
            ? `${link}${newParams !== "=" ? "?" + newParams : ""}`
            : "",
          "_blank"
        );
      }
    }
  };

  useEffect(() => {
    if (currentComponent["onInit"]) {
      handleTrigger({
        action: currentComponent?.["onInit"],
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonText = currentComponent?.btnText?.dataValue;

  return (
    <div>
      <AppButton
        buttonName={
          buttonText && typeof buttonText === "string" ? buttonText : ""
        }
        variant={currentComponent?.btnType}
        disabled={currentComponent?.disabled?.dataValue === "true"}
        startIcon={
          !currentComponent?.alignment && currentComponent?.icon ? (
            currentComponent?.logo ? (
              <img
                src={currentComponent?.logo}
                alt="Icon"
                style={{
                  maxHeight: 25,
                  maxWidth: 80,
                  height: "-webkit-fill-available",
                }}
              />
            ) : (
              <StarBorderIcon />
            )
          ) : (
            <></>
          )
        }
        endIcon={
          currentComponent?.alignment && currentComponent?.icon ? (
            currentComponent?.logo ? (
              <img
                src={currentComponent?.logo}
                alt="Icon"
                style={{
                  maxHeight: 25,
                  maxWidth: 80,
                  height: "-webkit-fill-available",
                }}
              />
            ) : (
              <StarBorderIcon />
            )
          ) : (
            <></>
          )
        }
        className="btnsmall"
        style={{
          width: "100%",
          backgroundColor: currentComponent?.status,
          border: `1px solid ${currentComponent?.status}`,
          fontFamily: currentComponent?.fontFamily,
          fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
          fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
          fontSize: currentComponent?.fontSize
            ? currentComponent?.fontSize
            : 16,
          textDecoration: currentComponent?.style?.underline
            ? "underline"
            : undefined,
        }}
        onClick={handleClick}
      />
    </div>
  );
};

export default CustomButton;
