import React, { useEffect, useRef, useState } from "react";
import {
  FilledInput,
  InputAdornment,
  IconButton,
  Divider,
  Paper,
} from "@material-ui/core";
import { AppButton } from "../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import listScreenStyles from "../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import CustomFilter from "../../components/common/CustomFilter";
import {
  arrayToFilter,
  arrayToSort,
  getWindowDimensions,
} from "../../utils/common";
import DevExpressTable from "../../components/common/Tables/DevExpressTable";
import { useDispatch, useSelector, connect } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  helpDeskColumnOrder,
  helpDeskColumns,
  helpDeskDefaultColumnWidths,
  helpDeskDefaultSorting,
  helpDeskTableColumnExtensions,
  helpDeskColumnHidden,
} from "../../constants/helpDesk";
import {
  addIssueFilters,
  getIssueDetails,
  addIssueSort,
  addIssueHidden,
} from "../../redux/actions/helpDesk";
import listStyles from "../CommonStyles/listScreenStyles";
import Pagination from "@material-ui/lab/Pagination";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { getTableData } from "../../utils/common";
import { fetchResourceByName } from "../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../components/common/stuff/Loader";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import useDebounce from "../../components/common/UseDebounce";
import { alterActiveTab } from "../../redux/actions/activeTabActions";

const HelpDeskList = (props) => {
  const styles = listScreenStyles();
  const styles2 = listStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [rows, setRows] = useState([]);
  const {
    issueSort,
    issueFilters,
    addIssueFilters,
    addIssueSort,
    addIssueHidden,
    issueHidden,
    alterActiveTab,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const [statuses, setStatuses] = useState([]);
  // implementing dynamic column chooser
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: helpDeskColumns,
    widths: helpDeskDefaultColumnWidths,
    extentions: helpDeskTableColumnExtensions,
    orders: helpDeskColumnOrder,
    hides: [],
  });

  // ending column chooser
  const [searchInput, setSearchInput] = useState("");
  const histroy = useHistory();
  const [hides, setHides] = useState(["ticketDetails", "Created By"]);

  /* eslint-disable */
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // helpDesk
  const issueAll = useSelector((state) => state.issueAll);
  const { loading, issues } = issueAll;

  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = userDetails;

  const isInitialMount = useRef(true);

  /* eslint-disable */
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);

  useEffect(() => {
    alterActiveTab("Help Desk", "Help Desk");
  }, []);

  const handleSearch = () => {
    if (searchInput) {
      dispatch(
        getIssueDetails(
          0,
          JSON.stringify({
            category: { $regex: searchInput, $options: "i" },
          })
        )
      );
    } else {
      dispatch(getIssueDetails(1));
    }
  };

  const handleSubmitFilter = async () => {
    const parsedFilters = arrayToFilter(issueFilters);
    const parsedSort = arrayToSort(issueSort);
    dispatch(getIssueDetails(1, parsedFilters, JSON.stringify(parsedSort)));
  };

  const handleClearFilters = () => {
    dispatch(getIssueDetails(1, JSON.stringify({})));
  };

  function createData(issueData) {
    const { _id, issue, category, createdon, status, createdby, issueid } =
      issueData;
    return {
      ...issueData,
      id: _id,
      ticketId: issueid,
      ticketDetails: issue,
      category: category,
      createdby: createdby,
      createdon: createdon,
      status: status,
      statuses: statuses,
    };
  }
  //fetching status
  const fetchStatusesByResource = async () => {
    const { _msg, _status, data } = await fetchStatusResourceByName("Helpdesk");
    if (_status === 200) {
      setStatuses(data ? data.status : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  /* eslint-disable */
  useEffect(() => {
    setRows(
      issues && issues.data && issues.data.length > 0
        ? issues &&
        issues.data.map((item) => {
          const rowData = createData(item);
          return rowData;
        })
        : []
    );
  }, [issues]);

  /* eslint-disable */
  useEffect(() => {
    checkScreenPermission("Helpdesk", ["List All", "List Assigned"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(async () => {
    const parsedFilters = arrayToFilter(issueFilters);
    const parsedSort = arrayToSort(issueSort);
    await fetchStatusesByResource();
    dispatch(
      getIssueDetails(currentPage, parsedFilters, JSON.stringify(parsedSort))
    );
  }, [currentPage, issueSort]);

  // dynamic column chooser
  useEffect(() => {
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("Helpdesk");
      if (_status === 200) {
        const issueData = getTableData(
          data,
          helpDeskColumns,
          helpDeskDefaultColumnWidths,
          helpDeskTableColumnExtensions,
          helpDeskColumnOrder,
          issueHidden,
          "helpdesk"
        );
        setTableData(issueData);
        if (
          JSON.stringify(issueHidden) === JSON.stringify(helpDeskColumnHidden)
        ) {
          addIssueHidden(issueData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchFields();
  }, []);
  const debouceOnchange = useDebounce((e) => setSearchInput(e), 500);
  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs items={[{ title: "Help Desk", path: "/helpdesk" }]} />
        <div className={styles.topContentItems}>
          <div className={styles.topContentItem} style={{ width: "80%" }}>
            <p className={styles.title}>
              {"Help Desk"}{" "}
              {issues && issues._totalcount ? ` (${issues._totalcount})` : ""}
            </p>
          </div>
          <div className={styles.topContentItem2}>
            <FilledInput
              name="searchInput"
              onChange={(e) => debouceOnchange(e.target.value)}
              className={styles.input}
              placeholder="Search Category"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearch}
                    onMouseDown={handleSearch}
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Divider orientation="vertical" className={styles.MuiDivider} />
            <CustomFilter
              columns={tableData.columns}
              filters={issueFilters}
              setFilters={addIssueFilters}
              handleSubmitFilter={handleSubmitFilter}
              handleClearFilters={handleClearFilters}
            />
            <Divider orientation="vertical" className={styles.MuiDivider} />
            <AppButton
              buttonName="Create"
              variant="contained"
              startIcon={<AddIcon />}
              className="btnsmall"
              disabled={!checkCompPermission("Helpdesk", ["Create"])}
              onClick={() => histroy.push("/helpdesk/create")}
            />
          </div>
        </div>
      </div>
      {/* Table */}
      <Paper className={styles.pageContent}>
        {tableLoading ? (
          <Loader />
        ) : (
          <DevExpressTable
            columns={tableData.columns}
            rows={rows}
            defaultColumnWidths={tableData.widths}
            tableColumnExtensions={tableData.extentions}
            columnOrder={tableData.orders}
            defaultHiddenColumnNames={tableData.hides}
            defaultSorting={helpDeskDefaultSorting}
            isCustomHeight={true}
            hidden={issueHidden}
            setHidden={addIssueHidden}
            loading={loading}
            // totalCount={_totalcount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            height={450}
            // totalCount={data._totalcount}
            pageSize={10}
            noDataMsg="No Issue Found"
            sort={issueSort}
            setSort={addIssueSort}
          />
        )}
      </Paper>
      {rows.length !== 0 && (
        <div className={styles2.footer}>
          <div className={styles2.customPagination}>
            <Pagination
              page={currentPage}
              count={
                issues &&
                issues._totalcount &&
                Math.ceil(issues._totalcount / 10)
              }
              onChange={(e, val) => setCurrentPage(val)}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </main>
  );
};
const mapDispatchToProps = {
  addIssueFilters,
  addIssueSort,
  alterActiveTab,
  addIssueHidden,
};

const mapStateToProps = (state) => {
  const { issueFilters, issueSort, issueHidden } = state.issueAll;
  return {
    issueFilters,
    issueSort,
    issueHidden,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskList);
