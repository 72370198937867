import React from "react";
import ShowStatus from "../../../atoms/rightSide/appearance/showStatus/ShowStatus";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import useGetData from "../../../../hooks/useGetData";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";

function DetailAppearances() {
  const dispatch = useDispatch();
  const { currentPage, currentComponent, NormalHandleChange } = useGetData();

  const handleChangeColumns = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          itemsPerPage: e.target.value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: value ? value : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };
  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name="fontSize"
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        />
      </div>
      <div className="field">
        <label>Columns</label>
        <input
          type="text"
          name={"title"}
          placeholder="Items per page"
          value={currentComponent?.itemsPerPage}
          autoComplete="off"
          onChange={handleChangeColumns}
        />
      </div>
      <div className="field">
        <div style={{ marginTop: 5 }}>
          <ShowStatus label="Show Loading" fstBtn="Yes" sndBtn="No" />
        </div>
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
    </div>
  );
}

export default DetailAppearances;
