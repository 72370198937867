/* eslint-disable */
import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useSnackbar } from "notistack";
import moment from "moment";

// files
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageScreenStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import manageScreenStyles2 from "../../CommonStyles/manageScreenStyles";

import ConfirmationModal from "../../../components/common/modals/ConfirmationModal";
import GlossaryDetailResources from "../Modal/GlossaryDetailResources";
import { AppButton } from "../../../components/common/ButtonOld";
import Loader from "../../../components/common/stuff/Loader";

// Redux
import { connect } from "react-redux";
import {
  editTagById,
  fetchTagById,
} from "../../../redux/actions/dataStandardAction";
import {
  handleFormValidation,
  handleFormValidationPro,
} from "../../../utils/common";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

const GlossaryDetails = (props) => {
  const inlineStyles = {
    glossaryBottomText: {
      background: "#F3F3F3 0% 0% no-repeat padding-box",
      // margin: "278px 25px 0 48px",
      padding: "10px 20px",
      marginTop: "12px",
    },
    glossaryBottomTextDetails: {
      background: "#F3F3F3 0% 0% no-repeat padding-box",
      // margin: "25px 0 48px",
      padding: "10px 20px",
      marginTop: "12px",
    },
    label: {
      font: "normal normal 600 14px/19px Nunito",
      opacity: "0.5",
    },
    span: {
      font: "normal normal 600 14px/19px Nunito",
      fontWeight: "bold",
    },
  };

  const styles = manageScreenStyles();
  const styles2 = manageScreenStyles2();
  const { editTagById, tags, isEditMode, fetchTagById, userInfo } = props;

  // Initial States
  const [initialName, setInitialName] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [initialKey, setInitialKey] = useState("");
  const [initialSource, setInitialSource] = useState("");

  const [loading, setLoading] = useState(false);
  const { _id } = useParams();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // Input States
  const [name, setName] = useState("");
  const [bKey, setBKey] = useState("");
  const [bSource, setBSource] = useState("");
  const [description, setDescription] = useState("");
  const [tagData, setTagData] = useState([]);

  // Form Validation States

  // Confirmation Modal State
  // const [openConfirmBox, setOpenConfirmBox] = useState(false);

  const [error, setError] = useState({
    name: false,
    key: false,
    // description: false,
  });
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission("GlossaryTag", ["Edit"]);
    } else {
      checkScreenPermission("GlossaryTag", ["Read"]);
    }
  }, [isEditMode]);

  useEffect(() => {
    const getTagDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchTagById(_id);
      setTagData(data ? data : []);
      if (_status === 200 && data.length !== 0) {
        setInitialName(data[0].name);
        setInitialDescription(data[0].description);
        setInitialKey(data[0].key);
        setInitialSource(data[0].source);
        setName(data[0].name);
        setDescription(data[0].description);
        setBSource(data[0].source);
        setBKey(data[0].key);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong.", {
          variant: "error",
        });
      }
    };
    getTagDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateGlossaryData = {};

    if (initialName !== name) {
      updateGlossaryData.name = name;
    }
    if (initialDescription !== description) {
      updateGlossaryData.description = description;
    }
    if (initialKey !== bKey) {
      updateGlossaryData.key = bKey;
    }
    if (initialSource !== bSource) {
      updateGlossaryData.source = bSource;
    }
    const { formIsValid, errors } = handleFormValidation(
      updateGlossaryData,
      error
    );
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status } = await editTagById(
        _id,
        updateGlossaryData,
        history
      );
      setLoading(false);
      if (_status === 200) {
        enqueueSnackbar(_msg, {
          variant: "success",
        });
        setLoading(false);
        history.push(`/glossary-details/${_id}`);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg ? _msg : "Something went wrong. Please contact Help desk.",
          {
            variant: "error",
          }
        );
      }
    } else {
      setError(errors);
    }
  };

  return (
    <main className={styles2.main}>
      {loading && <Loader />}
      {/* <ConfirmationModal
        open={openConfirmBox}
        handleClose={() => setOpenConfirmBox(false)}
        handleSubmit={() => history.push("/glossary")}
      /> */}
      <div className={styles2.topContent}>
        <BreadCrumbs
          items={[
            { title: "Glossary", path: "/glossary" },
            {
              title: isEditMode ? `Edit ${initialName}` : name,
              path: "",
            },
          ]}
        />

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles2.title}>
            {isEditMode ? `Edit ${initialName}` : name}
          </h2>
          {/* <div
              className={`${styles.submitbuttonwrapper} ${styles.submitContainer}`}
            > */}
          {isEditMode ? (
            <div style={{ display: "flex" }}>
              <AppButton
                buttonName="Save"
                style={{
                  padding: "6px 10px",
                  minWidth: "99px",
                }}
                disabled={!checkCompPermission("GlossaryTag", ["Edit"])}
                className="btnsmall "
                onClick={(e) => handleSubmit(e)}
              />
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                style={{
                  padding: "6px 10px",
                  minWidth: "99px",
                  marginLeft: "10px",
                }}
                className={`${styles.capitalizeBtn} btnsmall  `}
                onClick={() => history.push("/glossary")}
              />
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <AppButton
                color="primary"
                buttonName="Edit"
                variant="contained"
                // startIcon={<img src="/images/edit.svg" alt="card" />}
                style={{ padding: "6px 15px" }}
                className="btnsmall"
                onClick={() => history.push(`/edit-glossary/${_id}`)}
                disabled={!checkCompPermission("GlossaryTag", ["Edit"])}
              />
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                style={{
                  padding: "6px 10px",
                  minWidth: "99px",
                  marginLeft: "10px",
                }}
                className={`${styles.capitalizeBtn} btnsmall  `}
                onClick={() => history.push("/glossary")}
              />
            </div>
          )}
          {/* </div> */}
        </Grid>
      </div>
      <div className={styles.wrapper}>
        <Grid container spacing={3}>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={5}>
                <label className={styles2.fieldHeading}>
                  Glossary Name <span className={styles.red}>*</span>
                </label>
                <input
                  autoFocus
                  type="text"
                  value={name}
                  className={`${styles2.input} ${
                    !isEditMode && styles2.editMode
                  } ${error.name && styles2.error}`}
                  disabled={!isEditMode}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError({ ...error, name: false });
                  }}
                  maxLength={30}
                  placeholder={"Enter glossary name"}
                />
                {error.name && (
                  <p className={styles.errorMsg}>{"Name is required"}</p>
                )}
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>
                  Business Key <span className={styles.red}>*</span>
                </label>
                <input
                  value={bKey}
                  className={`${styles2.input} ${
                    !isEditMode && styles2.editMode
                  } ${error.key && styles2.error}`}
                  disabled={!isEditMode}
                  onChange={(e) => {
                    setBKey(e.target.value);
                    setError({ ...error, key: false });
                  }}
                  maxLength={30}
                  placeholder={"Enter business key"}
                />
                {error.key && (
                  <p className={styles.errorMsg}>
                    {"Business Key is required"}
                  </p>
                )}
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>Business Source</label>
                <input
                  value={bSource}
                  className={`${styles2.input} ${
                    !isEditMode && styles2.editMode
                  } ${error.source && styles2.error}`}
                  disabled={!isEditMode}
                  onChange={(e) => {
                    setBSource(e.target.value);
                  }}
                  maxLength={60}
                  placeholder={"Enter business source"}
                />
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>Created On</label>
                <input
                  value={userInfo?.timeZone && tagData[0]?.createdon
                    ? moment
                        .unix(tagData[0]?.createdon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : moment
                        .unix(tagData[0]?.createdon)
                        .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                  className={`${styles2.input} ${styles2.editMode}`}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>Created By</label>
                <input
                  value={(tagData.length !== 0 && tagData[0]?.createdby) || " "}
                  className={`${styles2.input} ${styles2.editMode}`}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>Updated On</label>
                <input
                  value={userInfo?.timeZone && tagData[0]?.updatedon
                    ? moment
                        .unix(tagData[0]?.updatedon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : " "}
                  className={`${styles2.input} ${styles2.editMode}`}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={5}>
                <label className={styles.fieldHeading}>Updated By</label>
                <input
                  value={(tagData.length !== 0 && tagData[0]?.updatedby) || " "}
                  className={`${styles2.input} ${styles2.editMode}`}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}>
            <label className={styles.fieldHeading}>
              Glossary Description 
              {/* <span className={styles.red}>*</span> */}
            </label>
            <textarea
              value={description}
              className={`${styles2.textarea} ${
                !isEditMode && styles2.editMode
              } ${error.description && styles.error}`}
              style={{
                resize: "vertical",
                maxHeight: "200px",
                minHeight: "150px",
              }}
              disabled={!isEditMode}
              maxLength={300}
              onChange={(e) => {
                setDescription(e.target.value);
                // setError({ ...error, description: false });
              }}
              placeholder={"Enter field description"}
            />
            {/* {error.description && (
              <p className={styles.errorMsg}>{"Description is required"}</p>
            )} */}
          </Grid>
        </Grid>
        {/* <div
          style={
            isEditMode
              ? inlineStyles.glossaryBottomText
              : inlineStyles.glossaryBottomTextDetails
          }
        >
          <Grid container>
            <Grid item xs={3}>
              <label style={inlineStyles.label}>&nbsp;Created On : </label>
              {tagData?.length !== 0 && (
                <span style={inlineStyles.span}>
                  {userInfo?.timeZone && tagData[0]?.createdon
                    ? moment
                        .unix(tagData[0]?.createdon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : moment
                        .unix(tagData[0]?.createdon)
                        .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                </span>

              )}
            </Grid>
            <Grid item xs={3}>
              <label style={inlineStyles.label}>Created By : </label>
              <span style={inlineStyles.span}>
                &nbsp;{(tagData.length !== 0 && tagData[0]?.createdby) || " "}
              </span>
            </Grid>
            <Grid item xs={3}>
              <label style={inlineStyles.label}>&nbsp;Updated On : </label>
              {tagData?.length !== 0 && (
                <span style={inlineStyles.span}>
                  {userInfo?.timeZone && tagData[0]?.updatedon
                    ? moment
                        .unix(tagData[0]?.updatedon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : " "}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <label style={inlineStyles.label}>Updated By : </label>
              <span style={inlineStyles.span}>
                &nbsp;{(tagData.length !== 0 && tagData[0]?.updatedby) || " "}
              </span>
            </Grid>
          </Grid>
        </div> */}

        {/* {!isEditMode && <GlossaryDetailResources _id={_id} />} */}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  editTagById,
  fetchTagById,
};

const mapStateToProps = (state) => {
  const { tags } = state.dataStandardReducer;
  const { userInfo } = state.userDetails;
  return { tags, userInfo };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryDetails);
