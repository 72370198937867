import React, { useState, useEffect } from "react";
import CustomModal from "../customModal/CustomModal";
import Styles from "../../../styles/commonStyles/CustomEditor";
import { UnControlled as CodeMirror } from "react-codemirror2";
import useGetData from "../../../hooks/useGetData";
import codemirror from "codemirror";
import { useSelector } from "react-redux";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/lint/css-lint.js";
import "codemirror/addon/lint/html-lint.js";
import "./hint.css";
require("codemirror/mode/javascript/javascript");
require("codemirror/addon/lint/javascript-lint");
require("codemirror/mode/xml/xml.js");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/javascript-hint");

function CustomEditor({
  name,
  setIsModalOpen,
  isModalOpen,
  value,
  isLint = false,
  onChange,
  mode,
  title,
  description,
  error,
}) {
  const classes = Styles();
  const { currentAction, currentComponent } = useGetData();
  const options = {
    lineNumbers: true,
    mode: mode ? mode : "javascript",
    lint: isLint,
    gutters: ["CodeMirror-lint-markers"],
    selfContain: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
  };
  const uiApplication = useSelector((state) => state.uiApplicationReducer);
  const { actions } = uiApplication;
  const [editorText, setEditorText] = useState(value);

  const clearHintList = () => {
    codemirror.hint.javascript = function (cm) {
      return {
        from: cm.getCursor(),
        to: cm.getCursor(),
        list: [],
      };
    };
  };
  useEffect(() => {
    let orig = codemirror?.hint?.javascript;
    codemirror.hint.javascript = function (cm) {
      let inner = orig(cm) || {
        from: cm.getCursor(),
        to: cm.getCursor(),
        list: [],
      };
      let tempArr = [];
      let tempDataValue = currentComponent?.[name]?.dataValue
        ? currentComponent?.[name]?.dataValue
        : {};
      if (editorText.includes("data")) {
        if (Array.isArray(currentComponent?.[name]?.dataValue)) {
          currentComponent?.[name]?.dataValue.map((v, i) => {
            tempArr.push(`[${i}]`);
          });
        } else if (Object?.keys(tempDataValue).length > 0) {
          Object?.keys(tempDataValue).map((v) => {
            tempArr.push(v);
          });
        }
      } else {
        for (let i = 0; i < actions.length; i++) {
          let v = `actions.${actions[i]?.name}.data`;
          tempArr.push(v);
        }
      }
      inner.list = tempArr;
      return inner;
    };
    return () => clearHintList;
  }, [editorText]);
  return (
    <>
      <CustomModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        title={title}
        description={description}
        handleSave={() => {
          clearHintList();
          onChange(editorText);
        }}
      >
        <div
          className={`${classes.JsModal} ${error ? classes.errorInput : ""}`}
        >
          <CodeMirror
            value={editorText}
            options={options}
            autoCursor={false}
            // onChange={(editor, data, value) => setEditorText(value)}
            onChange={(editor, data, val) => {
              if (val.slice(-2) === "{{" || val.slice(-2) === "}}") {
                editor.showHint({ completeSingle: false });
              }
              let newVal = val;
              if (newVal.slice(-2) === "{{") {
                newVal = val + "}}";
              }
              setEditorText(newVal);
            }}
            // onFocus={()=>editor.showHint({ completeSingle: false })}
          />
        </div>
        {error && <p className={classes.error}>{error}</p>}
      </CustomModal>
    </>
  );
}

export default CustomEditor;