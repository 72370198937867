import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { useSnackbar } from "notistack";
import { VendorServices } from "../../../services/vendorServices";
import { getVendorTeamDetails } from "../../../utils/vendor/vendorUtils";
import { editVendor } from "../../../utils/vendor/vendorUtils";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import EditVendorEntity from "../../../components/common/entitySchema/vendors/EditVendorEntity";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { handleFormValidationPro, initError } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import UserEditEntitySchemaHelper from "../../../components/common/entitySchema/users/UserEditEntitySchemaHelper";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
const vendorService = new VendorServices();

const EditvendorDetailsScreen = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const [vendorID] = useState(props.match.params._id);
  const [vendorDetails, setVendorDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorTeamDetails, setVendorTeamDetails] = useState("");
  const [vendorEntitySchema, setVendorEntitySchema] = useState([]);

  // Entity
  const [vendorData, setVendorData] = useState({
    name: "",
    ContactNumber: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
    contactNumber: { isError: false },
  });

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Vendor", ["Edit"]);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchVendorDetails = async () => {
    try {
      setLoading(true);
      const res_data = await vendorService.getVendorDetailsByID(vendorID);
      const { msg, _status, data } = res_data.data;
      if (_status === 200) {
        setVendorDetails(data);
        setVendorData((prevData) => ({ ...prevData, ...data }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getVendorTeamDetails(vendorID);
      if (_status < 210) {
        setVendorTeamDetails(data ? data : []);
      } else {
      }
    };
    fetchData();
  }, [vendorID]);
  useEffect(() => { }, [vendorTeamDetails]);
  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(vendorData, error);
    if (formIsValid) {
      setLoading(true);
      const newVendorData = {
        ...vendorData,
      };
      delete newVendorData["_id"];
      delete newVendorData["userid"];
      delete newVendorData["updatedon"];
      delete newVendorData["updatedby"];
      delete newVendorData["createdon"];
      delete newVendorData["createdby"];
      const { _msg, _status, data } = await editVendor(vendorID, newVendorData);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        setLoading(false);

        histroy.push(`/vendor-details/${vendorID}`);
      } else {
        setLoading(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        vendorEntitySchema ? vendorEntitySchema : [],
        errors,
        tabbedView
      );
      setError(errors);
      setActiveTab(errorInTab);
      setVendorEntitySchema(tempEntity);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setVendorData({
      ...vendorData,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    const fetchProjectEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Vendor",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setVendorEntitySchema(entity);
        fetchVendorDetails();
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        setVendorData({ ...vendorData, ...projData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["teams"];
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchProjectEntitySchema();
  }, []);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.goBack();
  };


  return (
    <main className={styles.main}>
      {loading && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Vendors", path: "/vendors" },
            { title: vendorDetails?.name ? `Edit ${vendorDetails?.name}` : "" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h2 className={styles.title}>
            {vendorDetails.name ? `Edit ${vendorDetails.name}` : ""}
          </h2>
          {tabbedView ? (
            <TabbedNavigation
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              isEditMode={false}
              disabled={false}
            />
          )}
        </Grid>
        <div className={styles.border_line} />
      </div>

      <div className={styles.wrapper} style={{top: "6.5rem"}}>
        {tabbedView ? (<div style={{ marginTop: "-20px" }}>
          <SectionTabs
            entity={
              vendorEntitySchema ? vendorEntitySchema : []
            }
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
          <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
            <div style={{ padding: "0px 0px 0" }}>
              <EditVendorEntity
                section={vendorEntitySchema?.[activeTab]}
                vendorData={vendorData}
                error={error}
                setError={setError}
                setVenderData={setVendorData}
                handleChange={handleChange}
                vendorTeamDetails={vendorTeamDetails}
              />
            </div>
          </Paper>
        </div>) : (vendorEntitySchema &&
          vendorEntitySchema.map((section, index) => {
            if (section.name === "Vendor_Header_section") {
              return (
                <div key={section._id} style={{ padding: "5px 5px 0" }}>
                  <EditVendorEntity
                    section={section}
                    vendorData={vendorData}
                    error={error}
                    setError={setError}
                    setVenderData={setVendorData}
                    handleChange={handleChange}
                    vendorTeamDetails={vendorTeamDetails}
                  />
                </div>
              );
            } else {
              return (
                <Element name={index}>
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded={true}
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <EditVendorEntity
                        section={section}
                        vendorData={vendorData}
                        error={error}
                        setError={setError}
                        setVenderData={setVendorData}
                        handleChange={handleChange}
                        vendorTeamDetails={vendorTeamDetails}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Element>
              );
            }
          }))}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(EditvendorDetailsScreen);
