/* eslint-disable */
import {
  Paper,
  Grid,
  Divider,
  Container,
  IconButton,
  InputAdornment,
  FilledInput,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import AddIcon from "@material-ui/icons/Add";
import listStyles from "../../CommonStyles/listScreenStyles";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import OntologyTable from "../../../components/common/Tables/OntologyTable";
import {
  fieldgroupColumns,
  fieldgroupDefaultColumnWidths,
  fieldgroupTableColumnExtensions,
  fieldgroupColumnOrder,
  fieldgroupDefaultSorting,
} from "../../../constants/Ontology/FieldGroupConstant";
import { connect } from "react-redux";
import {
  addFieldgroupFilters,
  getOntologyFieldGroups,
  addOntologySort,
  clearOntologyFieldGroupData
} from "../../../redux/actions/ontologyAction";
import CustomFilter from "../../../components/common/CustomFilter";
import { arrayToFilter, arrayToSort, getWindowDimensions, getColumnWidth } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import moment from "moment"
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

function createData(item) {
  const { _id, fields, name, predefined, predefined_fields, updatedon } = item;
  return {
    _id: _id,
    name: name,
    predefined: predefined ? predefined : "FALSE",
    num_fields: fields ? fields.length : 0,
    num_predefined_fields: predefined_fields ? predefined_fields.length : 0,
    updatedon: updatedon
  };
}

const FieldGroupListScreen = (props) => {
  const { data,
    getOntologyFieldGroups,
    error,
    loading,
    addFieldgroupFilters,
    fieldgroupFilters,
    fieldgroupSort,
    clearOntologyFieldGroupData,
    addOntologySort, _totalcount } = props;
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const { t, i18n } = useTranslation();
  const styles = listStyles();
  const histroy = useHistory();
  const [hides, setHides] = useState(["num_predefined_fields"]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission()

  useEffect(() => {
    checkScreenPermission('OntologyFieldGroup', ['List All'])
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const parsedFilters = arrayToFilter(fieldgroupFilters);
    const parsedSort = arrayToSort(fieldgroupSort);
    getOntologyFieldGroups(
      currentPage,
      parsedFilters,
      JSON.stringify(parsedSort)
    );
  }, [currentPage, fieldgroupSort]);

  useEffect(() => {
    setRows(data && data.length > 0 ? data.map((item) => {
      const rowData = createData(item);
      return rowData
    }) : [])
  }, [data])


  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
    // props.getOntologyFields();
  }, [searchInput]);

  const handleSearch = (e) => {
    if (searchInput) {
      props.getOntologyFieldGroups(0, JSON.stringify({ name: { $regex: searchInput, $options: "i" } }))
    } else {
      props.getOntologyFieldGroups();
    }
  };

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(fieldgroupFilters);
    const parsedSort = arrayToSort(fieldgroupSort)
    getOntologyFieldGroups(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    getOntologyFieldGroups(1, JSON.stringify({}));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // code changes done here.......... clear reducer

  useEffect(() => {
    return () => {
      clearOntologyFieldGroupData()
    }
  }, [])

  return (
    <main>
      <div className={styles.main}>

        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Ontology", path: "/ontology/fieldgroups" },
              { title: "Field Groups" },
            ]}
          />

          <div className={styles.topContentItems}>
            <div className={styles.topContentItem}>
              <p className={styles.title}>{`Field Groups ${_totalcount ? "".concat("(", _totalcount, ")") : ""}`}</p>
            </div>
            <div className={styles.topContentItem2}>
              <FilledInput autoComplete="off"
                name="searchInput"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.input}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={fieldgroupColumns}
                filters={fieldgroupFilters}
                setFilters={addFieldgroupFilters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <AppButton
                buttonName="create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("OntologyFieldGroup", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/ontology/fieldgroups/add")}
              />
            </div>
          </div>
        </div>
        <Paper className={styles.pageContent}>
          <OntologyTable
            columns={fieldgroupColumns}
            rows={rows}
            defaultColumnWidths={fieldgroupDefaultColumnWidths}
            tableColumnExtensions={fieldgroupTableColumnExtensions}
            columnOrder={fieldgroupColumnOrder}
            defaultSorting={fieldgroupDefaultSorting}
            loading={loading}
            hidden={hides}
            setHidden={setHides}
            totalCount={rows.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isCustomHeight={true}
            isCustomPaging={true}
            sort={fieldgroupSort}
            setSort={(sort) => addOntologySort("fieldgroup", sort)}
            noDataMsg="No Field Group Found"
          />
        </Paper>
        <div className={styles.footer}>
          <div className={styles.customPagination}>
            <Pagination
              count={Math.ceil(_totalcount / 10)}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addFieldgroupFilters,
  getOntologyFieldGroups,
  addOntologySort,
  clearOntologyFieldGroupData
};

const mapStateToProps = (state) => {
  const { fieldgroups, fieldgroupFilters, fieldgroupSort } = state.ontologyReducer;
  const { loading, data, error, _totalcount } = fieldgroups;
  return {
    loading,
    error,
    data,
    fieldgroupFilters,
    fieldgroupSort,
    _totalcount
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldGroupListScreen);
