import {
  ADD_VENDOR_LISTSCREEN_DATA,
  VENDORS_FILTER,
  VENDOR_SORT,
  BLOCK_UNBLOCK_VENDOR,
  VENDOR_HIDDEN_COULMNS,
  CLEAR_VENDOR_DATA,
  ALTER_FAVOURITE_VENDOR_BY_ID,
} from "../actionTypes";
import { VendorServices } from "../../services/vendorServices";

const vendorServices = new VendorServices();

function add_vendor_listscreen_data(payload) {
  return {
    type: ADD_VENDOR_LISTSCREEN_DATA,
    payload: payload,
  };
}

export function add_vendorlist_data(currentPage, filter, sort) {
  return function (dispatch, getState) {
    dispatch(
      add_vendor_listscreen_data({
        loading: true,
      })
    );
    const {
      userPersonal: { userFavourites },
    } = getState();
    vendorServices
      .getVendorList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            // dispatch(
            //   add_vendor_listscreen_data({
            //     loading: false,
            //     data: data,
            //     error: false,
            //     _totalcount: _totalcount,
            //   })
            // );
            dispatch(
              add_vendor_listscreen_data({
                loading: false,
                data: {
                  data:
                    data.length !== 0 &&
                    data.map((vendor) => {
                      const checkFav =
                        userFavourites.filter(
                          (fav) => fav.resourceinstance === vendor._id
                        ).length !== 0;
                      let tempVendor = vendor;
                      if (checkFav) {
                        tempVendor["isFavourite"] = true;
                      } else {
                        tempVendor["isFavourite"] = false;
                      }
                      return tempVendor;
                    }),
                },
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_vendor_listscreen_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_vendor_listscreen_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      });
  };
}
export const add_vendor_filters = (vendorFilter) => {
  return {
    type: VENDORS_FILTER,
    payload: vendorFilter,
  };
};

export const add_vendor_sort = (vendorSort) => {
  return {
    type: VENDOR_SORT,
    payload: vendorSort,
  };
};

export const block_unblock_vendor = (vendorID, status) => {
  return {
    type: BLOCK_UNBLOCK_VENDOR,
    payload: {
      vendorID,
      status,
    },
  };
};

export const addVendorHiddenCols = (vendorHiddenCols) => {
  return {
    type: VENDOR_HIDDEN_COULMNS,
    payload: vendorHiddenCols,
  };
};

export const clearVendorDetails = () => {
  return {
    type: CLEAR_VENDOR_DATA,
  };
};

// GLOSSARY FAVOURITE
export const alterFavouriteVendorById = (vendorId) => {
  console.log("hello");
  return {
    type: ALTER_FAVOURITE_VENDOR_BY_ID,
    payload: {
      vendorId,
    },
  };
};
