import React, { useState, useEffect } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import BreadCrumbs from "../../../../components/common/Breadcrumbs";
import manageScreenStyles from "../../../CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import {
  add_service_data,
  emptyServiceCreateApplication,
  populateServiceToApplication,
  addTemplateList,
  addApplicationDetailsData,
  setApplicationDeployment,
} from "../../../../redux/actions/applicationAction";
import {
  getApplicationDataByID,
  getApplicationTemplatesByID,
  editApplicationByID_,
  getImageList,
  getApplicationDeploymentDetails,
} from "../../../../utils/orchestration/applicationUtils";

import { AppButton } from "../../../../components/common/Button";
import EditApplicationEntityHelper from "../../../../components/common/entitySchema/application/EditApplicationEntityHelper";
import { fetchResourceByPermission } from "../../../../utils/entitySchema/entitySchemaUtils";
import {
  getWindowDimensions,
  handleFormValidationPro,
  initError,
} from "../../../../utils/common";
import useCheckPermission from "../../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../../components/Tabbed/TabsCommon";

const ApplicationDetailsScreen = (props) => {
  const [applicationID] = useState(props.match.params._id);
  const [applicationData, setApplicationData] = useState({
    _id: "",
    createdby: "",
    createdon: "",
    description: "",
    name: "",
    updatedby: "",
    updatedon: "",
    microservices: [],
  });

  const [activeTab, setActiveTab] = useState(0);

  function createData(service_data) {
    const {
      _id,
      description,
      name,
      // projectID,
      // servicegroupID,
      imagename,
      os,
      projectDetails,
      servicegroupdetails,
      runTimeEnv,
      serviceType,
    } = service_data;
    const projectName =
      projectDetails.length > 0 ? projectDetails[0]["name"] : "";
    const servicegroupName =
      servicegroupdetails.length > 0 ? servicegroupdetails[0]["name"] : "";
    const created_on = service_data["Created On"];
    return {
      _id,
      description,
      name,
      projectName,
      servicegroupName,
      created_on,
      runTimeEnv,
      imagename: imagename ? imagename : "",
      os: os ? os : "",
      runTimeEnv,
      serviceType,
    };
  }

  const [error, setError] = useState({});
  const [comingFromDetailsPage, setcomingFromDetailsPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [applicationEntitySchema, setApplicationEntitySchema] = useState([]);
  const [currentPageProcessTable, setCurrentPageProcessTable] = useState(1);
  const [currentPageServiceTable, setCurrentPageServiceTable] = useState(1);
  const [runTimeEnv, setRunTimeEnv] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const isEditMode = props.match.params.action === "edit" ? true : false;

  const {
    data,
    add_service_data,
    emptyServiceCreateApplication,
    create_application,
    templateList,
    tabbedView,
  } = props;

  const search = useLocation().search;
  const appBundleId = new URLSearchParams(search).get("id");

  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // const location = useLocation();
  // const isInitialMount = useRef(false);
  const { checkCompPermission } = useCheckPermission();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setcomingFromDetailsPage(false);
    } else {
      setcomingFromDetailsPage(true);
    }
  }, []);

  useEffect(() => {
    // const { source } = location.state;
    if (isEditMode) {
      var tempArr =
        applicationData?.microservices?.length > 0
          ? applicationData?.microservices.map((item) => {
            return {
              mid: item.mid,
              vid: item.vid,
            };
          })
          : [];
      props.populateServiceToApplication(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData.microservices]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(async () => {
    const fetchApplicationEntitySchema = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "ServiceBundle",
        isEditMode ? "edit" : "read"
      );
      if (_status === 200) {
        setLoading(false);
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setApplicationEntitySchema(entity);
        const appData = {};
        const tempError = {};
        data?.sections?.forEach((section) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup.fields.forEach((field) => {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                appData[`${field.datakey}_unit`] = "";
                appData[`${field.datakey}_value`] = "";
              } else if (field.datakey) {
                appData[field.datakey] = "";
              }
            });
          });
        });
        data?.sections?.forEach((section, index) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (field.required === "TRUE") {
                if (
                  field.uiElementType === "Currency" ||
                  field.uiElementType === "Amount"
                ) {
                  tempError[`${field.datakey}_unit`] = initError(field, index);
                  tempError[`${field.datakey}_value`] = initError(field, index);
                } else {
                  tempError[field.datakey] = initError(field, index);
                }
              }
            });
          });
        });
        delete tempError["services"];
        delete tempError["microservices"];
        setApplicationData({ ...applicationData, ...appData });
        setError({ ...error, ...tempError });
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchData = async () => {
      // setLoading(true);
      const { _msg, _status, data } = await getApplicationDataByID(
        applicationID
      );
      if (_status === 200) {
        setLoading(false);
        if (data) {
          setApplicationData({ ...applicationData, ...data });
          props.addApplicationDetailsData(data);
          setRunTimeEnv(data.runtime_env);
        }
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchTemplateData_ = async () => {
      // setLoading(true);
      const { _msg, _status, data } = await getApplicationTemplatesByID(
        applicationID
      );
      if (_status === 200) {
        setLoading(false);
        props.addTemplateList(
          data ? data.map((x) => ({ ...x, isEditMode: isEditMode })) : []
        );
        // setTemplateList(() => (data ? data : []));
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchImageList = async () => {
      const { _status, _msg, data } = await getImageList();
      if (_status === 200) {
        setImagesList(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    emptyServiceCreateApplication();
    await fetchApplicationEntitySchema();
    if (checkCompPermission("ServiceBundle", ["Manage Process"])) {
      await fetchTemplateData_();
    }
    await fetchImageList();
    await fetchData();
  }, [isEditMode]);
  useEffect(() => {
    const getDeploymentDetails = async () => {
      const reqData = applicationData?.microservices?.map((ser) => ({
        mid: ser.mid,
        vid: ser.vid,
      }));
      if (reqData && reqData?.length > 0) {
        const { _status, data } = await getApplicationDeploymentDetails(
          reqData
        );
        var depArr = [];
        data?.forEach((obj) => {
          var temp1 = depArr.filter((d) => d.imageid === obj.imageid);
          if (temp1.length === 0) {
            depArr.push({ ...obj, type: "defaultEnv" });
          }
        });
        applicationData?.deployment?.services?.forEach((obj) => {
          var temp1 = depArr.filter((d) => d?.imageid === obj?.imageid);
          if (temp1.length === 0 && obj.imageid) {
            var imgObj = imagesList.filter(
              (imgID) => imgID._id === obj.imageid
            );
            if (imgObj.length === 1) {
              var tempObj = {
                ...obj,
                image: "".concat(
                  imgObj[0]?.imagename,
                  ":",
                  imgObj[0]?.imagetag
                ),
                type: "applicationData",
              };
            }
            depArr.push(tempObj);
          }
        });
        if (_status === 200) {
          var new_data =
            depArr && depArr.length > 0
              ? depArr.map((item) => {
                var container_name = "";
                var environment = {};
                var restart = false;
                const imgArr = item?.image?.split(":");
                const deploymentAddedArr =
                  applicationData?.deployment?.services?.filter(
                    (obj) => obj.imageid === item?.imageid
                  );
                if (deploymentAddedArr && deploymentAddedArr.length >= 1) {
                  container_name = deploymentAddedArr[0]?.container_name
                    ? deploymentAddedArr[0]?.container_name
                    : "";
                  environment = deploymentAddedArr[0]?.environment
                    ? deploymentAddedArr[0]?.environment
                    : {};
                  restart = deploymentAddedArr[0]?.restart
                    ? deploymentAddedArr[0]?.restart
                    : false;
                } else if (imgArr?.length === 2) {
                  container_name = "".concat(
                    applicationData?.name?.replace(/ /g, "_"),
                    imgArr[0]
                  );
                  environment = item.environment ? item.environment : {};
                } else {
                  container_name = "".concat(
                    applicationData?.name?.replace(/ /g, "_"),
                    item?.image
                  );
                  environment = item?.environment ? item?.environment : {};
                }
                var tempObj = {
                  ...item,
                  id: uuidv4(),
                  container_name: container_name,
                  imagename: imgArr?.length === 2 ? imgArr[0] : "",
                  imagetag: imgArr?.length === 2 ? imgArr[1] : "",
                  environment: environment,
                  rowInput: item?.image ? item?.image : "",
                };
                if (restart) {
                  tempObj["restart"] = "always";
                }
                return tempObj;
              })
              : [];
          props.setApplicationDeployment(new_data);
        }
      }
    };
    getDeploymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData._id]);

  useEffect(
    () => {
      if (checkCompPermission("Microservice", ["List All"])) {
        handleSearch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchInput]
  );

  useEffect(() => {
    const updatedDeploymentNames = props?.applicationDeployment.map((item) => {
      return{
        ...item,
        container_name: "".concat(applicationData.name.replace(/ /g, "_"), item.imagename),
      }
    })
    props.setApplicationDeployment(updatedDeploymentNames)
  },[applicationData.name])

  const rows =
    data.length > 0
      ? data.map((item) => {
        item["runTimeEnv"] = runTimeEnv;
        const row_data = createData(item);
        return row_data;
      })
      : [];
  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === "name" && value.includes("_")) {
      let tempValue = value
        .split("")
        .filter((item) => item !== "_")
        .join("");
      setApplicationData({
        ...applicationData,
        [event.target.name]: tempValue,
      });
    } else {
      setApplicationData({
        ...applicationData,
        [event.target.name]: value,
      });
    }
  };

  const handleCancel = () => {

    if (!isEditMode) {
      history.push(`/service-bundle`);
    } else {
      comingFromDetailsPage
        ? history.push(`/application/details/${applicationID}`)
        : history.push(`/service-bundle`);
    }
  };

  const handleSearch = () => {
    if (searchInput) {
      add_service_data(
        JSON.stringify({
          name: { $regex: searchInput, $options: "i" },
          ai_task: { $ne: true },
          service_category: { $ne: "Only GUI" },
        })
      );
    } else {
      add_service_data(
        JSON.stringify({
          ai_task: { $ne: true },
          service_category: { $ne: "Only GUI" },
        })
      );
    }
  };

  const handleSave = async () => {
    var deploymentBody = [];
    deploymentBody =
      props?.applicationDeployment.length > 0 &&
      props?.applicationDeployment.map((item) => {
        if (item.restart) {
          return {
            container_name: item.container_name,
            imageid: item.imageid,
            // image: "".concat(item.imagename ? item.imagename : "", ":", item.imagetag ? item.imagetag : ""),
            environment: item.environment,
          };
        } else {
          return {
            container_name: item.container_name,
            imageid: item.imageid,
            // image: "".concat(item.imagename ? item.imagename : "", ":", item.imagetag ? item.imagetag : ""),
            environment: item.environment,
          };
        }
      });
    const { formIsValid, errors } = handleFormValidationPro(
      applicationData,
      error
    );

    const { tempEntity, errorInTab } = TabsCommon(
      applicationEntitySchema,
      errors,
      tabbedView
    );
    if (errorInTab !== undefined) {
      setActiveTab(errorInTab);
    }

    setApplicationEntitySchema(tempEntity);
    if (formIsValid) {
      var tempReqData = applicationData;
      [
        "createdby",
        "createdon",
        "updatedby",
        "updatedon",
        "_id",
        "deployment",
        "microservices",
        "orchestrationip",
        "runtime_env",
        "state",
        "deploymentedit",
      ].forEach((item) => {
        if (Object.keys(tempReqData).includes(item)) {
          delete tempReqData[item];
        }
      });
      // if (
      //   create_application.length > 0 &&
      //   props?.applicationDeployment?.length > 0
      // ) {
      //   const req_data = {
      //     ...tempReqData,
      //     microservices: create_application,
      //     deployment: {
      //       services: deploymentBody,
      //     },
      //   };
      //   setLoading(true);
      //   const { _msg, _status } = await editApplicationByID_(
      //     applicationID,
      //     req_data
      //   );
      //   if (_status === 201 || _status === 200) {
      //     setLoading(false);
      //     enqueueSnackbar(_msg, { variant: "success" });
      //     history.push("/service-bundle");
      //   } else {
      //     setLoading(false);
      //     enqueueSnackbar(_msg, { variant: "error" });
      //   }
      // } else
      if (create_application.length > 0) {
        const req_data = {
          ...tempReqData,
          microservices: create_application,
          deployment: {
            services: deploymentBody,
          },
        };
        setLoading(true);
        const { _msg, _status } = await editApplicationByID_(
          applicationID,
          req_data
        );
        if (_status === 201 || _status === 200) {
          setLoading(false);
          enqueueSnackbar(_msg, { variant: "success" });
          history.push(`/application/details/${applicationID}`);
        } else {
          setLoading(false);
          enqueueSnackbar(_msg, { variant: "error" });
        }

        let tempEntityApplication = applicationEntitySchema;
        tempEntityApplication[1]["isError"] = false;
        setApplicationEntitySchema(tempEntityApplication);
      } else {
        enqueueSnackbar("Select atleast one microservice to proceed", {
          variant: "error",
        });

        setApplicationEntitySchema((prev) => {
          prev[1]["isError"] = true;
          return prev;
        });
        setActiveTab(1);
      }
    } else {
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleEditMode = () => {
    var tempArr =
      applicationData?.microservices?.length > 0
        ? applicationData?.microservices?.map((item) => {
          return {
            mid: item.mid,
            vid: item.vid,
          };
        })
        : [];
    props.populateServiceToApplication(tempArr);
    var newTemplateList =
      templateList &&
      templateList.map((item) => ({ ...item, isEditMode: true }));
    props.addTemplateList(newTemplateList);

    history.push(`/application/edit/${applicationID}`);
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Service Bundle",
              path: "/service-bundle",
            },
            {
              title: isEditMode
                ? `Edit ${applicationData.name}`
                : applicationData.name,
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {isEditMode ? `Edit ${applicationData.name}` : applicationData.name}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TabbedNavigation
              isEditMode={isEditMode}
              isClose={isEditMode ? false : true}
              handleEdit={handleEditMode}
              disabled={!checkCompPermission("ServiceBundle", ["Edit"])}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </div>
        </Grid>
      </div>
      <div className={styles.wrapper}>
        {tabbedView ? (
          <>
            <SectionTabs
              entity={applicationEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20 }}>
              <div style={{ padding: "0px 5px 0" }}>
                <EditApplicationEntityHelper
                  section={applicationEntitySchema[activeTab]}
                  isEditMode={isEditMode ? isEditMode : false}
                  applicationData={applicationData}
                  error={error}
                  setApplicationData={setApplicationData}
                  handleChange={handleChange}
                  loading={props.loading}
                  rows={rows}
                  services={props.services ? props.services : []}
                  setSearchInput={setSearchInput}
                  handleSearch={handleSearch}
                  applicationID={applicationID}
                  applicationName={applicationData.name}
                  templateList={templateList}
                  currentPageProcessTable={currentPageProcessTable}
                  setCurrentPageProcessTable={setCurrentPageProcessTable}
                  currentPageServiceTable={currentPageServiceTable}
                  setCurrentPageServiceTable={setCurrentPageServiceTable}
                  windowDimensions={windowDimensions}
                  imagesList={imagesList}
                />
              </div>
            </Paper>
          </>
        ) : (
          <>
            {applicationEntitySchema?.length !== 0 &&
              applicationEntitySchema?.map((section) => {
                if (section.name === "Service_Bundle_Header section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0px" }}>
                      <EditApplicationEntityHelper
                        section={section}
                        isEditMode={isEditMode}
                        applicationData={applicationData}
                        error={error}
                        setApplicationData={setApplicationData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditApplicationEntityHelper
                          section={section}
                          isEditMode={isEditMode ? isEditMode : false}
                          applicationData={applicationData}
                          error={error}
                          setApplicationData={setApplicationData}
                          handleChange={handleChange}
                          loading={props.loading}
                          rows={rows}
                          services={props.services ? props.services : []}
                          setSearchInput={setSearchInput}
                          handleSearch={handleSearch}
                          applicationID={applicationID}
                          applicationName={applicationData.name}
                          templateList={templateList}
                          currentPageProcessTable={currentPageProcessTable}
                          setCurrentPageProcessTable={
                            setCurrentPageProcessTable
                          }
                          currentPageServiceTable={currentPageServiceTable}
                          setCurrentPageServiceTable={
                            setCurrentPageServiceTable
                          }
                          windowDimensions={windowDimensions}
                          imagesList={imagesList}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
          </>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_service_data,
  emptyServiceCreateApplication,
  populateServiceToApplication,
  addTemplateList,
  addApplicationDetailsData,
  setApplicationDeployment,
};

const mapStateToProps = (state) => {
  const {
    services_list,
    create_application,
    templateList,
    application_details,
    applicationDeployment,
  } = state.applicationReducer;
  const { tabbedView } = state.userDetails.userInfo;
  const { data, error, loading } = services_list;
  const { microservices } = application_details;
  return {
    data,
    error,
    loading,
    create_application,
    templateList,
    services: microservices,
    applicationDeployment,
    tabbedView,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDetailsScreen);
