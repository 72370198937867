import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import CustomFieldGroupForTestExecutionEdit from "../../../components/common/entitySchema/test/CustomFieldGroupForTestExecutionEdit";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import Loader from "../../../components/common/stuff/Loader";
import { BASE_URL } from "../../../services/helper/config";
import { handleFormValidationPro, initError } from "../../../utils/common";
import {
  fetchResourceByName,
  fetchResourceByPermission,
} from "../../../utils/entitySchema/entitySchemaUtils";
import {
  addTestScript,
  fetchTestScriptById,
  getAllTestEnvironment,
  updateTestScriptById,
} from "../../../utils/project/testUtils";

import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import { connect, useSelector } from "react-redux";
import {
  fetchHTMLFileById,
  fetchTestExecutionById,
} from "../../../utils/test/testExecutionUtils";
import {
  fetchAllDeployments,
  FetchDeploymentById,
} from "../../../utils/deployment/deploymentUtils";
import { v4 as uuidv4 } from "uuid";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import CustomFieldGroupForTestRunDetails from "../../../components/common/entitySchema/test/CustomFieldGroupForTestRunDetails";
import { add_test_execution_details } from "../../../redux/actions/testExecutionAction";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const EditTestExecutionScreen = (props) => {
  const { testExecutionDetails, add_test_execution_details, userInfo } = props;
  const { tabbedView } = props;
  const [testExecutionID] = useState(props.match.params.testExecutionID);
  const [testExecutionStatus, setTestExecutionStatus] = useState(false);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const styles = manageScreenStyles();
  const isEditMode = props.match.params.action === "edit" ? true : false;
  const isMode = props.match.params.action;
  const histroy = useHistory();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({});
  const [testExecutionEntitySchema, setTestExecutionEntitySchema] = useState(
    []
  );
  const [belowSections, setBelowSections] = useState();
  const userData = useSelector((state) => state.authReducer);

  const [droppedDocument, setDroppedDocument] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    filename: "",
  });
  const [testExecutionData, setTestExecutionData] = useState({
    description: "",
    name: "",
    type: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [HTMLFile, setHTMLFile] = useState();
  const [HTMLUrl, setHTMLUrl] = useState();
  const [testHTML, settestHTML] = useState();
  const [deployment, setDeployment] = useState();
  const [envData, setEnvData] = useState();
  const [deploymentExist, setDeploymentExist] = useState(false);

  const [envs, setEnvs] = useState([
    { id: uuidv4(), key: "", value: "", enabled: true },
  ]);

  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "", enabled: true }]);
  };

  const handleEnvChange = (event, id, column) => {
    if (column === "enabled") {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.checked };
        } else {
          return env;
        }
      });
    } else {
      var newEnvs = envs.map((env) => {
        if (env.id === id) {
          return { ...env, [column]: event.target.value };
        } else {
          return env;
        }
      });
    }

    setEnvs(newEnvs);
  };
  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  const setEnvironmentData = async (e) => {
    setSelectedEnvFile(e._id);
    setIsLoader(true);
    const { data, _status } = await getTestEnvData(e._id);

    if (_status === 200) {
      let customizeData = data.map((item) => {
        item["id"] = uuidv4();
        return item;
      });
      setEnvs(customizeData);
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    const fetchtestExecutionEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "TestExecute",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        data?.sections.map((section) => {
          if (section.name === "Test_Env_Section") {
            setBelowSections(section);
          }
        });
        entity.splice(2, 2);
        entity.push({ "section Label": "Test Report" });
        setTestExecutionEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });
        setError(errData);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchTestExecutionDetails = async () => {
      setIsLoader(true);
      const { data, _msg, _status } = await fetchTestExecutionById(
        testExecutionID
      );
      if (_status === 200) {
        setTestExecutionData(data);
        const checkDeployment = async (id) => {
          const { data, _status, _msg } = await FetchDeploymentById(id);
          if (_status === 200) {
            setDeploymentExist(true);
          } else {
            setDeploymentExist(false);
            // enqueueSnackbar("Selected Deployment is Deleted",{variant:"warning"})
          }
        };
        checkDeployment(data?.deploymentid);
        let envKeyVal = [];

        add_test_execution_details(data);
        data?.environments?.map((item) => {
          envKeyVal.push(item);
        });

        setEnvs(envKeyVal);
        setIsLoader(false);
      } else {
        enqueueSnackbar(
          _msg ? _msg : "Unable to fetch Execution, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    };

    fetchtestExecutionEntitySchema();
    fetchTestExecutionDetails();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    setTestScriptData({
      ...testScriptData,
      [event.target.name]: value,
    });
  };

  const handleUpload = (files) => {
    setFileDetails({ filename: files ? (files[0] ? files[0].name : "") : "" });

    setDroppedDocument(files);
    setError({ ...error, droppedDocument: false });
  };
  useEffect(async () => {
    const queryparma = `draft=true`;

    let response = await fetch(
      `${BASE_URL}/api/v1.0.0/test/executions/${testExecutionID}/output`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );

    if (response.status === 200) {
      let blob = await response.blob();
      const text = await blob.text();
      setHTMLUrl(text);
    } else {
      // enqueueSnackbar(
      //   "Unable to show Execution Report, please try after sometime...",
      //   {
      //     variant: "error",
      //   }
      // );
    }
  }, []);

  const handleDownloadFile = async () => {
    let response = await fetch(
      `${BASE_URL}/api/v1.0.0/test/executions/${testExecutionID}/output`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );

    if (response.status === 200) {
      enqueueSnackbar("Download in Progress...", { variant: "success" });
      let blob = await response.blob();

      var url = window.URL.createObjectURL(blob);

      var a = document.createElement("a");
      if (testExecutionData) {
        var time = moment
          .unix(testExecutionData.createdon)
          .tz(userInfo?.timeZone)
          .format(
            userInfo?.time === "h:mm"
              ? `${userInfo.dateFormat}, ${userInfo.time} A`
              : `${userInfo.dateFormat} ${userInfo.time}`
          );
      }

      var fileName = `${testExecutionData?.name}_${time}.html`;
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    histroy.push(`/test`);
  };

  const handleReRun = () => [
    history.push(`/test-execute/create/rerun/${testExecutionID}`),
  ];

  const handleDeleteClick = () => {
    setDroppedDocument([]);
    setFileDetails({
      filename: "",
    });
  };

  useEffect(() => {
    if (testExecutionData?.status === "Failed") {
      setTestExecutionStatus(false);
    } else {
      setTestExecutionStatus(true);
    }
  }, [testExecutionData]);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Test", path: "/test" },
            {
              title: "Execution",
              path: "/test",
            },
            {
              title: `${testExecutionData?.name}`,
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>{`${testExecutionData?.name}`}</h2>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >

            <AppButton
              className={`btnsmall`}
              disabled={!deploymentExist}
              variant="contained"
              onClick={handleReRun}
              buttonName="Rerun"
            />
            <AppButton
              style={{ padding: "0", marginLeft: "10px" }}
              className={`btnsmall`}
              variant="contained"
              onClick={handleClose}
              buttonName="Close"
            />

          </div>
        </Grid>
      </div>

      <div className={styles.wrapper}>
        {tabbedView ? (
          <>
            <SectionTabs
              entity={testExecutionEntitySchema}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />

            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                {activeTab === 3 ? (
                  <>
                    {!testExecutionStatus ? (
                      <>
                        <span className={styles.red}>
                          <h3 className={styles.sectionHeading3}>
                            Test Execution Failed
                          </h3>
                        </span>
                        <p>{testExecutionDetails?.error}</p>
                      </>
                    ) : (
                      <>
                        <div>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            style={{ marginBottom: "20px" }}
                          >
                            <AppButton
                              color="primary"
                              buttonName="Download"
                              variant="contained"
                              disabled={false}
                              style={{ margin: "unset" }}
                              className="btnsmall"
                              onClick={handleDownloadFile}
                            />
                          </Grid>
                        </div>
                        <Grid xs={12} height="768px">
                          <iframe
                            sandbox
                            id="inlineFrameExample"
                            title="Inline Frame Example"
                            // width="100vw"
                            // height="fit-content"
                            style={{
                              width: "90vw",
                              height: "1260px",
                              display: "flex",
                              flexDirection: "row",
                              overflow: "auto",
                            }}
                            srcDoc={HTMLUrl}
                          ></iframe>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <CustomFieldGroupForTestExecutionEdit
                      section={testExecutionEntitySchema[activeTab]}
                      deployment={deployment}
                      envData={envData}
                      testExecutionData={testExecutionData}
                      setTestExecutionData={setTestExecutionData}
                      handleChange={handleChange}
                      error={error}
                      envs={envs}
                      handleUpload={handleUpload}
                      setIsLoader={setIsLoader}
                      droppedDocument={droppedDocument}
                      fileDetails={fileDetails}
                      handleDeleteClick={handleDeleteClick}
                      isMode={isMode}
                      handleAddEnv={handleAddEnv}
                      handleDeleteEnv={handleDeleteEnv}
                      handleEnvChange={handleEnvChange}
                      setEnvironmentData={setEnvironmentData}
                    />
                  </>
                )}
              </div>
            </Paper>
          </>
        ) : (
          <>
            {testExecutionEntitySchema.length !== 0 &&
              testExecutionEntitySchema.map((section) => {
                if (section.name === "Test_Execute_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupForTestExecutionEdit
                        section={section}
                        testExecutionData={testExecutionData}
                        setTestExecutionData={setTestExecutionData}
                        handleChange={handleChange}
                        error={error}
                        handleUpload={handleUpload}
                        setIsLoader={setIsLoader}
                        isEditMode={isEditMode}
                      />
                    </div>
                  );
                } else if (section.name === "Test Run Details") {
                  return (
                    <>
                      <Accordion className={styles.fieldPanel} defaultExpanded>
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Grid
                            container
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className={styles.sectionHeading2}>
                              {section["section Label"]}
                            </Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingLeft: 5 }}>
                          {!testExecutionStatus ? (
                            <>
                              <span className={styles.red}>
                                <h3 className={styles.sectionHeading3}>
                                  Test Execution Failed
                                </h3>
                              </span>
                              <p>{testExecutionDetails?.error}</p>
                            </>
                          ) : (
                            <Grid xs={12}>
                              <CustomFieldGroupForTestExecutionEdit
                                section={section}
                                deployment={deployment}
                                envData={envData}
                                testExecutionData={testExecutionData}
                                setTestExecutionData={setTestExecutionData}
                                handleChange={handleChange}
                                error={error}
                                envs={envs}
                                handleUpload={handleUpload}
                                setIsLoader={setIsLoader}
                                droppedDocument={droppedDocument}
                                fileDetails={fileDetails}
                                handleDeleteClick={handleDeleteClick}
                                isMode={isMode}
                                handleAddEnv={handleAddEnv}
                                handleDeleteEnv={handleDeleteEnv}
                                handleEnvChange={handleEnvChange}
                                setEnvironmentData={setEnvironmentData}
                              />
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                } else if (section.name === "Test_Env_Section") {
                  return (
                    <>
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                      // key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupForTestExecutionEdit
                            section={section}
                            deployment={deployment}
                            envData={envData}
                            testExecutionData={testExecutionData}
                            setTestExecutionData={setTestExecutionData}
                            handleChange={handleChange}
                            error={error}
                            envs={envs}
                            handleUpload={handleUpload}
                            setIsLoader={setIsLoader}
                            droppedDocument={droppedDocument}
                            fileDetails={fileDetails}
                            handleDeleteClick={handleDeleteClick}
                            isMode={isMode}
                            handleAddEnv={handleAddEnv}
                            handleDeleteEnv={handleDeleteEnv}
                            handleEnvChange={handleEnvChange}
                            setEnvironmentData={setEnvironmentData}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                }
              })}
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.sectionHeading}>
                  Test Report
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!testExecutionStatus ? (
                  <>
                    <span className={styles.red}>
                      <h3 className={styles.sectionHeading3}>
                        Test Execution Failed
                      </h3>
                    </span>
                    <p>{testExecutionDetails?.error}</p>
                  </>
                ) : (
                  <>
                    <div>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        style={{ marginBottom: "20px" }}
                      >
                        <AppButton
                          color="primary"
                          buttonName="Download"
                          variant="contained"
                          disabled={false}
                          style={{ margin: "unset" }}
                          className="btnsmall"
                          onClick={handleDownloadFile}
                        />
                      </Grid>
                    </div>
                    <Grid xs={12} height="768px">
                      <iframe
                        sandbox
                        id="inlineFrameExample"
                        title="Inline Frame Example"
                        // width="100vw"
                        // height="fit-content"
                        style={{
                          width: "90vw",
                          height: "1260px",
                          display: "flex",
                          flexDirection: "row",
                          overflow: "auto",
                        }}
                        srcDoc={HTMLUrl}
                      ></iframe>
                    </Grid>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </main>
  );
};
const mapDispatchToProps = { add_test_execution_details };

const mapStateToProps = (state) => {
  const { testExecutionDetails } = state.testExecutionReducer;
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;
  return { testExecutionDetails, userInfo, tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTestExecutionScreen);
