import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import TextFieldCustom from "../../TextField/TextFieldCustom";
import ClearIcon from "@material-ui/icons/Clear";
import { v4 as uuidv4 } from "uuid";

const QueryParams = ({ currentComponent, handleChange }) => {
  const handleAddParams = () => {
    handleChange("queryParams", [
      ...currentComponent.queryParams,
      { id: uuidv4(), key: "", value: "" },
    ]);
  };

  const handleChangeParams = (id, name, value) => {
    handleChange(
      "queryParams",
      currentComponent.queryParams.map((p) => {
        if (p.id === id) {
          return { ...p, [name]: value };
        } else {
          return p;
        }
      })
    );
  };

  const handleDeleteParams = (param) => {
    handleChange(
      "queryParams",
      currentComponent.queryParams.filter((p) => p.id !== param.id)
    );
  };

  return (
    <div>
      {currentComponent.queryParams.map((param) => (
        <div key={param.id}>
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextFieldCustom
                width="85%"
                value={param.key}
                onChange={(event) =>
                  handleChangeParams(param.id, "key", event.target.value)
                }
                disabled={false}
                name={param.id}
              />
              <TextFieldCustom
                width="100%"
                value={param.value}
                onChange={(event) =>
                  handleChangeParams(param.id, "value", event.target.value)
                }
                disabled={false}
                name={param.id}
              />

              <IconButton
                onClick={() => handleDeleteParams(param)}
                style={{ marginRight: -6 }}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
      <div
        style={{
          display: "flex",

          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "1px solid #D8D8D8",
          borderRadius: 5,
          marginBottom: 5,
          marginTop: 2,
          justifyContent: "center",
          padding: 5,
          cursor: "pointer",
        }}
        onClick={handleAddParams}
      >
        <AddOutlinedIcon />
      </div>
    </div>
  );
};

export default QueryParams;
