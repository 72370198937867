export const ADD_APPLICATION_LIST_DATA = "ADD_APPLICATION_LIST_DATA";
export const APPLICATION_HIDDEN = "APPLICATION_HIDDEN";
export const ADD_APPLICATION_FILTER = "ADD_APPLICATION_FILTER";
export const ADD_APPLICATION_SORT = "ADD_APPLICATION_SORT";
export const DELETE_APPLICATION_BY_ID = "DELETE_APPLICATION_BY_ID";
export const ADD_SERVICE_TO_CREATE_APPLICATION =
  "ADD_SERVICE_TO_CREATE_APPLICATION";
export const EMPTY_SERVICE_TO_CREATE_APPLICATION =
  "EMPTY_SERVICE_TO_CREATE_APPLICATION";
export const ADD_VERSION_FOR_SERVICE = "ADD_VERSION_FOR_SERVICE";
export const POPULATE_SERVICE_TO_EDIT_APPLICATION =
  "POPULATE_SERVICE_TO_EDIT_APPLICATION";
export const ALTER_FAVOURITE_APPLICATION_BY_ID =
  "ALTER_FAVOURITE_APPLICATION_BY_ID";
export const DELETE_TEMPLATE_BY_ID = "DELETE_TEMPLATE_BY_ID";
export const ADD_TEMPLATE_LIST = "ADD_TEMPLATE_LIST";
export const ADD_APPLICATION_DETAILS_DATA = "ADD_APPLICATION_DETAILS_DATA";
export const DELETE_MICORSERVICE_FROM_APPLICATION =
  "DELETE_MICORSERVICE_FROM_APPLICATION";
export const ADD_OPEN_TO_APPLICATION_SERVICE_TABLE =
  "ADD_OPEN_TO_APPLICATION_SERVICE_TABLE";
export const CHECK_UNCHECK_APPLICATION_VERSION_TABLE =
  "CHECK_UNCHECK_APPLICATION_VERSION_TABLE";
export const ALTER_SUBSCRIBE_APPLICATION_BY_ID =
  "ALTER_SUBSCRIBE_APPLICATION_BY_ID";
export const SET_APPLICATION_DEPLOYMENT = "SET_APPLICATION_DEPLOYMENT";

export const ADD_SERVICE_LIST_DATA = "ADD_SERVICE_LIST_DATA";
export const CLEAR_SERVICES = "CLEAR_SERVICES";
export const ADD_VERSION_LIST_DATA = "ADD_VERSION_LIST_DATA";
export const ADD_API_LIST_DATA = "ADD_API_LIST_DATA";
export const API_SORT = "API_SORT";
export const API_HIDDEN = "API_HIDDEN";
export const DELETE_SERVICE_BY_ID = "DELETE_SERVICE_BY_ID";
export const VERSION_LOADING = "VERSION_LOADING";
export const DELETE_VERSION_BY_ID = "DELETE_VERSION_BY_ID";
export const VERSION_SORT = "VERSION_SORT";
export const VERSION_HIDDEN = "VERSION_HIDDEN";
export const CLEAR_VERSION = "CLEAR_VERSION";
export const DELETE_API_BY_ID = "DELETE_API_BY_ID";
export const CLEAR_API = "CLEAR_API";
export const TABBED_ACTIVE_TAB = "TABBED_ACTIVE_TAB";
export const SELECT_API_CONTAINER = "SELECT_API_CONTAINER";
export const GET_PROJECTS = "GET_PROJECTS";
export const DELETE_PROJECT_BY_ID = "DELETE_PROJECT_BY_ID";
export const ALTER_FAVOURITE_PROJECT_BY_ID = "ALTER_FAVOURITE_PROJECT_BY_ID";
export const ALTER_SUBSCRIBE_PROJECT_BY_ID = "ALTER_SUBSCRIBE_PROJECT_BY_ID";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";

export const NEW_PROJECT_DATA = "NEW_PROJECT_DATA";
export const PROJECT_FILTERS = "PROJECT_FILTERS";
export const PROJECT_SORT = "PROJECT_SORT";
export const PROJECT_HIDDEN = "PROJECT_HIDDEN";
export const SERVICE_FILTERS = "SERVICE_FILTERS";
export const SERVICE_SORT = "SERVICE_SORT";
export const SERVICE_HIDDEN = "SERVICE_HIDDEN";
export const CLEAR_SERVICEGROUP_EDITMODE = "CLEAR_SERVICEGROUP_EDITMODE";
export const CHANGE_SERVICEGROUP_MODE = "CHANGE_SERVICEGROUP_MODE";

export const ALTER_FAVOURITE_SERVICE_BY_ID = "ALTER_FAVOURITE_SERVICE_BY_ID";
export const SET_SELECTED_MICROSERVICEID = "SET_SELECTED_MICROSERVICEID";
export const EDIT_VERSION = "EDIT_VERSION";
// export const DETAIL_VERSION ="DETAIL_VERSION";
export const CLOSE_VERSION = "CLOSE_VERSION";
export const EDIT_API = "EDIT_API";
export const SET_SELECTED_APIID = "SET_SELECTED_APIID";
export const CLOSE_API = "CLOSE_API";
export const SET_SELECTED_VERSIONID = "SET_SELECTED_VERSIONID";
export const ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT =
  "ADD_SERVICE_GROUP_LIST_DATA_BY_PROJECT";
export const ADD_ALL_SERVICE_GROUP_LIST_DATA =
  "ADD_ALL_SERVICE_GROUP_LIST_DATA";
export const ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA =
  "ADD_ALL_SERVICE_IN_SERVICEGROUP_LIST_DATA";
export const DELETE_SERVICE_GROUP_BY_ID = "DELETE_SERVICE_GROUP_BY_ID";
export const DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID =
  "DELETE_MICROSERVICE_IN_SERVICEGROUP_BY_ID";
export const NEW_SERVICEGROUP_DATA = "NEW_SERVICEGROUP_DATA";
export const SERVICEGROUP_SORT = "SERVICEGROUP_SORT";
export const SERVICEGROUP_HIDDEN = "SERVICEGROUP_HIDDEN";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const ADD_CANVAS_APPLICATION_DATA = "ADD_CANVAS_APPLICATION_DATA";

export const ADD_TEMPLATE_DATA = "ADD_TEMPLATE_DATA";
export const ADD_ELEMENTS_TO_LIST = "ADD_ELEMENTS_TO_LIST";
export const EDIT_ELEMENT_FROM_LIST = "EDIT_ELEMENT_FROM_LIST";
export const REPLACE_ELEMENT_LIST = "REPLACE_ELEMENT_LIST";
export const ADD_REMOVE_DEBUG_POINTS = "ADD_REMOVE_DEBUG_POINTS";
export const ADD_ANIMATIONS = "ADD_ANIMATIONS";
export const REMOVE_ANIMATIONS = "REMOVE_ANIMATIONS";
export const ALTER_SIMULATE_STATUS = "ALTER_SIMULATE_STATUS";
export const ADD_PROCESS_OUTPUT = "ADD_PROCESS_OUTPUT";
export const ADD_CONTAINER_DATA_FOR_TEMPLATE =
  "ADD_CONTAINER_DATA_FOR_TEMPLATE";
export const ADD_APPLICATION_CONTAINER_ID = "ADD_APPLICATION_CONTAINER_ID";
export const ALTER_FLOW_MODE = "ALTER_FLOW_MODE";
export const ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION =
  "ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION";
export const ADD_INPUT_OUTPUT_DATA_FOR_NODES =
  "ADD_INPUT_OUTPUT_DATA_FOR_NODEs";

export const ALTER_DEBUG_DATA = "ALTER_DEBUG_DATA";
export const ADD_DEBUG_ANIMATIONS = "ADD_DEBUG_ANIMATIONS";
export const REMOVE_ALL_DEBUG_POINTS = "REMOVE_ALL_DEBUG_POINTS";
export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";

// Application Governance
export const ADD_LAUNGUAGE_DATA = "ADD_LAUNGUAGE_DATA";
export const LANGUAGE_FILTERS = "LANGUAGE_FILTERS";
export const DELETE_LANGUAGE_BY_ID = "DELETE_LANGUAGE_BY_ID";
export const LANGUAGE_SORT = "LANGUAGE_SORT";
export const BLOCK_UNBLOCK_LANGUAGE = "BLOCK_UNBLOCK_LANGUAGE";
export const CLEAR_LANGUAGE_DATA = "CLEAR_LANGUAGE_DATA";
export const LANGUAGE_HIDDEN_COULMNS = "LANGUAGE_HIDDEN_COULMNS";

export const ADD_BOILER_PLATE_DATA = "ADD_BOILER_PLATE_DATA";
export const BOILERPLATE_FILTERS = "BOILERPLATE_FILTERS";
export const DELETE_BOILERPLATE_BY_ID = "DELETE_BOILERPLATE_BY_ID";
export const BOILERPLATE_SORT = "BOILERPLATE_SORT";
export const CLEAR_BOILER_PLATE_DATA = "CLEAR_BOILER_PLATE_DATA";
export const BOILERPLATE_HIDDEN_COULMNS = "BOILERPLATE_HIDDEN_COULMNS";

export const ADD_CHASSIS_TEMPLATE_DATA = "ADD_CHASSIS_TEMPLATE_DATA";
export const CHASSIS_TEMPLATE_FILTERS = "CHASSIS_TEMPLATE_FILTERS";
export const DELETE_CHASSIS_TEMPLATE_BY_ID = "DELETE_CHASSIS_TEMPLATE_BY_ID";
export const CHASSIS_TEMPLATE_SORT = "CHASSIS_TEMPLATE_SORT";
export const CLEAR_CHASIS_TEMPLATE = "CLEAR_CHASIS_TEMPLATE";
export const CHASSIS_TEMPLATE_HIDDEN_COULMNS =
  "CHASSIS_TEMPLATE_HIDDEN_COULMNS";

// Container management
export const ADD_DOCKER_IMAGE_DATA = "ADD_DOCKER_IMAGE_DATA";
export const DOCKER_IMAGE_FILTERS = "DOCKER_IMAGE_FILTERS";
export const DELETE_DOCKER_IMAGE = "DELETE_DOCKER_IMAGE";
export const DOCKER_IMAGE_SORT = "DOCKER_IMAGE_SORT";
export const DOCKER_IMAGE_HIDDEN_COULMNS = "DOCKER_IMAGE_HIDDEN_COULMNS";
export const CLEAR_DOCKER_IMAGE_DATA = "CLEAR_DOCKER_IMAGE_DATA";

export const ADD_CONTAINERS_DATA = "ADD_CONTAINERS_DATA";
export const CONTAINER_FILTERS = "CONTAINER_FILTERS";
export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const CONTAINERS_SORT = "CONTAINERS_SORT";
export const START_OR_STOP_CONTAINER = "START_OR_STOP_CONTAINER";
export const CONTAINERS_HIDDEN_COULMNS = "CONTAINERS_HIDDEN_COULMNS";
export const CLEAR_CONTAINER_DATA = "CLEAR_CONTAINER_DATA";

export const ADD_RUNTIME_ENV_DATA = "ADD_RUNTIME_ENV_DATA";
export const RUNTIMEENV_FILTER = "RUNTIMEENV_FILTER";
export const DELETE_RUNTIMEENV = "DELETE_RUNTIMEENV";
export const RUNTIMEENV_SORT = "RUNTIMEENV_SORT";
export const RUNTIMEENV_HIDDEN_COULMNS = "RUNTIMEENV_HIDDEN_COULMNS";
export const CLEAR_RUNTIME_DATA = "CLEAR_RUNTIME_DATA";
export const START_OR_STOP_RUNTIMEENV = "START_OR_STOP_RUNTIMEENV";

// users
export const ADD_USER_DATA = "ADD_USER_DATA";
export const USERS_FILTER = "USERS_FILTER";
export const DELETE_USER = "DELETE_USER";
export const USER_SORT = "USER_SORT";
export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const USER_HIDDEN_COULMNS = "USER_HIDDEN_COULMNS";
export const ALTER_VIEW = "ALTER_VIEW";

// teams
export const ADD_TEAMS_DATA = "ADD_TEAMS_DATA";
export const TEAMS_FILTER = "TEAMS_FILTER";
export const TEAMS_SORT = "TEAMS_SORT";
export const LOCK_UNLOCK_TEAM = "LOCK_UNLOCK_TEAM";
export const BLOCK_UNBLOCK_TEAM = "BLOCK_UNBLOCK_TEAM";
export const CLEAR_TEAMS_DATA = "CLEAR_TEAMS_DATA";
export const TEAMS_HIDDEN_COULMNS = "TEAMS_HIDDEN_COULMNS";

// vendors
export const ADD_VENDOR_LISTSCREEN_DATA = "ADD_VENDOR_LISTSCREEN_DATA";
export const VENDORS_FILTER = "VENDORS_FILTER";
export const VENDOR_SORT = "VENDOR_SORT";
export const BLOCK_UNBLOCK_VENDOR = "BLOCK_UNBLOCK_VENDOR";
export const VENDOR_HIDDEN_COULMNS = "VENDOR_HIDDEN_COULMNS";
export const CLEAR_VENDOR_DATA = "CLEAR_VENDOR_DATA";
export const ALTER_FAVOURITE_VENDOR_BY_ID = "ALTER_FAVOURITE_VENDOR_BY_ID";

// Organization
export const ADD_ORGANIZATION_DATA = "ADD_ORGANIZATION_DATA";
export const ORGANIZATIONS_FILTER = "ORGANIZATIONS_FILTER";
export const ORGANIZATIONS_SORT = "ORGANIZATIONS_SORT";
export const ACTIVE_INACTIVE_ORG = "ACTIVE_INACTIVE_ORG";
export const ADD_CURRENT_ORGANIZATION_DATA = "ADD_CURRENT_ORGANIZATION_DATA";
export const UPDATE_INACTIVITY_TIME = "UPDATE_INACTIVITY_TIME";
export const CHANGE_LOGO = "CHANGE_LOGO";
export const ADD_CLOUD_CONFIG_LIST = "ADD_CLOUD_CONFIG_LIST";
export const DELETE_CLOOUD_CONFIG_BY_ID = "DELETE_CLOOUD_CONFIG_BY_ID";
export const UPDATE_CLOUD_CONFIG_BY_ID = "UPDATE_CLOUD_CONFIG_BY_ID";
export const ADD_ROLES_DATA = "ADD_ROLES_DATA";
export const ROLE_FILTERS = "ROLE_FILTERS";
export const ROLE_SORT = "ROLE_SORT";
export const DELETE_ROLE = "DELETE_ROLE";

// Recently visited pages(Menu)
export const TRACK_VISITED_ROUTES = "TRACK_VISITED_ROUTES";
export const DELETE_VISITED_ROUTES = "DELETE_VISITED_ROUTES";

// For maintaining active tab
export const ALTER_ACTIVE_TAB = "ALTER_ACTIVE_TAB";

//for auth data
export const ADD_USER_AUTH_DATA = "ADD_USER_AUTH_DATA";

// Glossary
export const ADD_TAG = "ADD_TAG";
export const FETCH_ALL_TAGS = "FETCH_ALL_TAGS";
export const FETCH_TAG_BY_ID = "FETCH_TAG_BY_ID";
export const EDIT_TAG_BY_ID = "EDIT_TAG_BY_ID";
export const DELETE_TAG = "DELETE_TAG";
export const GLOSSARY_FILTERS = "GLOSSARY_FILTERS";
export const CLEAR_GLOSSARY_FILTERS = "CLEAR_GLOSSARY_FILTERS";
export const ADD_LOADER = "ADD_LOADER";
export const FETCH_TAG_RESOURCES = "FETCH_TAG_RESOURCES";
export const ADD_TAG_RESOURCES_LOADER = "ADD_TAG_RESOURCES_LOADER";
export const SET_GLOSSARY_SORT = "SET_GLOSSARY_SORT";
export const CLEAR_GLOSSARY_LIST_DATA = "CLEAR_GLOSSARY_LIST_DATA";
export const GLOSSARY_DELETE = "GLOSSARY_DELETE";
export const BLOCK_UNBLOCK_FIELD = "BLOCK_UNBLOCK_FIELD";
export const BLOCK_UNBLOCK_PAYLOAD = "BLOCK_UNBLOCK_PAYLOAD";
export const ALTER_FAVOURITE_GLOSSARY_BY_ID = "ALTER_FAVOURITE_GLOSSARY_BY_ID";
// Data Standards
export const ADD_FIELD_LOADER = "ADD_FIELD_LOADER";
export const SET_DATA_STANDARD_TAB = "SET_DATA_STANDARD_TAB";
export const SET_FIELD_GROUP_PAGE = "SET_FIELD_GROUP_PAGE";
export const SET_FIELD_PAGE = "SET_FIELD_PAGE";
export const SET_FIELD_FILTERS = "SET_FIELD_FILTERS";
export const CLEAR_FIELD_FILTERS = "CLEAR_FIELD_FILTERS";

export const SET_FIELD_SORT = "SET_FIELD_SORT";
export const SET_FIELD_GROUP_SORT = "SET_FIELD_GROUP_SORT";

export const FIELD_HIDDEN = "FIELD_HIDDEN";

export const ADD_FIELD_GROUP = "ADD_FIELD_GROUP;";
export const FETCH_FIELD_GROUPS = "FETCH_FIELD_GROUPS";
export const FIELDGROUP_FILTERS = "FIELDGROUP_FILTERS";
export const FIELDGROUP_DELETE = "FIELDGROUP_DELETE";
export const FIELD_DELETE = "FIELD_DELETE";
export const EDIT_FIELD_GROUP = "EDIT_FIELD_GROUP";
export const FIELDGROUP_HIDDEN = "FIELDGROUP_HIDDEN";
export const FETCH_FIELDS = "FETCH_FIELDS";
export const ADD_FIELD = "ADD_FIELD";
export const EDIT_FIELD = "EDIT_FIELD";
export const CLEAR_DATA_STANDARD_DATA = "CLEAR_DATA_STANDARD_DATA";

//ontology
export const GET_ONTOLOGY_FIELDS = "GET_ONTOLOGY_FIELDS";
export const CLEAR_ONTOLOGY_FIELDS_DATA = "CLEAR_ONTOLOGY_FIELDS_DATA";
export const GET_ONTOLOGY_FIELDGROUPS = "GET_ONTOLOGY_FIELDGROUPS";
export const GET_ONTOLOGY_SECTIONS = "GET_ONTOLOGY_SECTIONS";
export const GET_ONTOLOGY_OBJECTS = "GET_ONTOLOGY_OBJECTS";
export const CLEAR_ONTOLOGY_OBJECTS_DATA = "CLEAR_ONTOLOGY_OBJECTS_DATA";
export const ADD_ONTOLOGY_FIELDS_FILTER = "ADD_ONTOLOGY_FIELDS_FILTER";
export const ADD_ONTOLOGY_FIELDGROUP_FILTER = "ADD_ONTOLOGY_FIELDGROUP_FILTER";
export const CLEAR_ONTOLOGY_FIELDGROUP_DATA = "CLEAR_ONTOLOGY_FIELDGROUP_DATA";
export const ADD_ONTOLOGY_SECTION_FILTER = "ADD_ONTOLOGY_SECTION_FILTER";
export const ADD_ONTOLOGY_OBJECT_FILTER = "ADD_ONTOLOGY_OBJECT_FILTER";
export const CLEAR_ONTOLOGY_SECTIONS_DATA = "CLEAR_ONTOLOGY_SECTIONS_DATA";
export const ADD_ONTOLOGY_SORT = "ADD_ONTOLOGY_SORT";
export const DELETE_ONTOLOGY = "DELETE_ONTOLOGY";
export const OBJECT_COLUMN_HIDDEN = "OBJECT_COLUMN_HIDDEN";
export const SECTION_COLUMN_HIDDEN = "SECTION_COLUMN_HIDDEN";
export const FIELDGROUP_COLUMN_HIDDEN = "FIELDGROUP_COLUMN_HIDDEN";
export const FIELD_COLUMN_HIDDEN = "FIELD_COLUMN_HIDDEN";

// child table project documents action types
export const ADD_PROJECT_DOCUMENTS_DATA = "ADD_PROJECT_DOCUMENTS_DATA";
export const PROJECT_DOCUMENTS_FILTERS = "PROJECT_DOCUMENTS_FILTERS";
export const PROJECT_DOCUMENTS_SORT = "PROJECT_DOCUMENTS_SORT";
export const DELETE_PROJECT_DOCUMENTS_BY_DOCUMENTS_ID =
  "DELETE_PROJECT_DOCUMENTS";

// child table service groups documents action types
export const ADD_SERVICE_GROUPS_DOCUMENTS_DATA =
  "ADD_SERVICE_GROUPS_DOCUMENTS_DATA";
export const SERVICE_GROUPS_DOCUMENTS_FILTERS =
  "SERVICE_GROUPS_DOCUMENTS_FILTERS";
export const SERVICE_GROUP_DOCUMENTS_SORT = "SERVICE_GROUP_DOCUMENTS_SORT";
export const DELETE_SERVICE_GROUP_DOCUMENTS_BY_DOCUMENTS_ID =
  "DELETE_SERVICE_GROUP_DOCUMENTS";

// child table service documents action types
export const ADD_SERVICE_DOCUMENTS_DATA = "ADD_SERVICE_DOCUMENTS_DATA";
export const SERVICE_DOCUMENTS_FILTERS = "SERVICE_DOCUMENTS_FILTERS";
export const SERVICE_DOCUMENTS_SORT = "SERVICE_DOCUMENTS_SORT";
export const DELETE_SERVICE_DOCUMENTS_BY_DOCUMENTS_ID =
  "DELETE_SERVICE_DOCUMENTS";

// parent table standard documents action types
export const ADD_STANDARD_DOCUMENTS_DATA = "ADD_STANDARD_DOCUMENTS_DATA";
export const STANDARD_DOCUMENTS_FILTERS = "STANDARD_DOCUMENTS_FILTERS";
export const STANDARD_DOCUMENTS_SORT = "STANDARD_DOCUMENTS_SORT";
export const DELETE_STANDARD_DOCUMENTS_BY_ID =
  "DELETE_STANDARD_DOCUMENTS_BY_ID";

export const CLEAR_STANDARD_DOCUMENTS = "CLEAR_STANDARD_DOCUMENTS";

//parent table project list action types for documentation
export const GET_PROJECTS_DOCUMENTS = "GET_PROJECTS_DOCUMENTS";
export const PROJECT_DOCUMENT_FILTERS = "PROJECT_DOCUMENT_FILTERS";
export const PROJECT__DOCUMENT_SORT = "PROJECT__DOCUMENT_SORT";
export const CLEAR_PROJECT_DATA = "CLEAR_PROJECT_DATA";

//parent table project list action types for documentation
export const SERVICEGROUPFILTERS = "SERVICEGROUPFILTERS";
export const SERVICEGROUPSORT = "SERVICEGROUPSORT";
export const CLEAR_SERVICEGROUP_DATA = "CLEAR_SERVICEGROUP_DATA";

//parent table project list action types for documentation
export const ADD_SERVICE_LIST__DOCUMENT_DATA =
  "ADD_SERVICE_LIST__DOCUMENT_DATA";
export const SERVICE_DOCUMENT_DATA_FILTERS = "SERVICE_DOCUMENT_DATA_FILTERS";
export const SERVICE_DOCUMENT_DATA_SORT = "SERVICE_DOCUMENT_DATA_SORT";
export const CLEAR_SERVICE_DATA = "CLEAR_SERVICE_DATA";

export const CLEAR_RECENT_VISITED_ROUTES = "CLEAR_RECENT_VISITED_ROUTES";

// add documents after uploading

export const ADD_DOCUMENT_AFTER_UPLOAD = "ADD_DOCUMENT_AFTER_UPLOAD";

export const ALTER_SUBSCRIBE_SERVICE_BY_ID = "ALTER_SUBSCRIBE_SERVICE_BY_ID";

export const ADD_AITASK_LISTS = "ADD_AITASK_LISTS";
export const EDIT_AITASK_VERSION = "EDIT_AITASK_VERSION";
export const EDIT_AITASK_API = "EDIT_AITASK_API";
export const SET_SELECTED_AITASK_VERSIONID = "SET_SELECTED_AITASK_VERSIONID";
export const SET_SELECTED_AITASK_APIID = "SET_SELECTED_AITASK_APIID";
export const CLOSE_AITASK_VERSION = "CLOSE_AITASK_VERSION";
export const CLOSE_AITASK_API = "CLOSE_AITASK_API";
export const SET_ONLY_VERSIONID = "SET_ONLY_VERSIONID";

export const GET_DATA_MODELS_DATA_FOR_AITASK =
  "GET_DATA_MODELS_DATA_FOR_AITASK";
export const GET_FUNCTIONS_FOR_AITASK = "GET_FUNCTIONS_FOR_AITASK";

// data model
export const ADD_DATA_MODEL_LIST = "ADD_DATA_MODEL_LIST";
export const ALTER_SUBSCRIBE_DATA_MODEL_BY_ID =
  "ALTER_SUBSCRIBE_DATA_MODEL_BY_ID";
export const ADD_SINGLE_DATA_MODEL_SUBSCRIPTION =
  "ADD_SINGLE_DATA_MODEL_SUBSCRIPTION";
export const REMOVE_SINGLE_DATA_MODEL_SUBSCRIPTION =
  "REMOVE_SINGLE_DATA_MODEL_SUBSCRIPTION";
export const BLOCK_UNBLOCK_DATA_MODEL = "BLOCK_UNBLOCK_DATA_MODEL";
export const DATA_MODEL_FILTERS = "DATA_MODEL_FILTERS";
export const SET_DATA_MODEL_SORT = "SET_DATA_MODEL_SORT";
export const DATA_MODEL_HIDDEN = "DATA_MODEL_HIDDEN";
export const DELETE_DATA_MODEL_BY_ID = "DELETE_DATA_MODEL_BY_ID";
export const BLOCK_UNBLOCK_DATAMODEL = "BLOCK_UNBLOCK_DATAMODEL";
export const ADD_DATA_MODEL_FILES = "ADD_DATA_MODEL_FILES";
export const ADD_AI_MODEL_DETAILS = "ADD_AI_MODEL_DETAILS";
export const DELETE_DATA_MODEL_FILE = "DELETE_DATA_MODEL_FILE";
export const ADD_AI_MODEL_FILES = "ADD_AI_MODEL_FILES";
export const CLEAR_AI_MODEL = "CLEAR_AI_MODEL";

//AITask
export const AITASK_SORT = "AITASK_SORT";
export const AITASK_FILTERS = "AITASK_FILTERS";
export const ALTER_FAVOURITE_AI_TASK_BY_ID = "ALTER_FAVOURITE_AI_TASK_BY_ID";
export const ALTER_SUBSCRIBE_AI_TASK_BY_ID = "ALTER_SUBSCRIBE_AI_TASK_BY_ID";
export const DELETE_AITASK_BY_ID = "DELETE_AITASK_BY_ID";
export const TABBED_AITASK_ACTIVE_TAB = "TABBED_AITASK_ACTIVE_TAB";

// Code Editor

export const UPDATE_ACTIVE_PATH = "UPDATE_ACTIVE_PATH";
export const UPDATE_EDIT_KEY = "UPDATE_EDIT_KEY";
export const CREATE_FOLDER_PATH = "CREATE_FOLDER_PATH";
export const DELETE_FOLDER_PATH = "DELETE_FOLDER_PATH";
export const RENAME_FOLDER_PATH = "RENAME_FOLDER_PATH";
export const UPDATE_LOGS = "UPDATE_LOGS";
export const UPDATE_NEW_KEY = "UPDATE_NEW_KEY";
export const UPDATE_LOACKED_PATHS = "UPDATE_LOACKED_PATHS";
export const UPDATE_UN_LOACKED_PATHS = "UPDATE_UN_LOACKED_PATHS";
export const UPDATE_TREE = "UPDATE_TREE";

//service GUI
export const ADD_SERVICE_GUI_LIST_DATA = "ADD_SERVICE_GUI_LIST_DATA";
export const SERVICE_GUI_FILTERS = "SERVICE_GUI_FILTERS";
export const SERVICE_GUI_SORT = "SERVICE_GUI_SORT";
export const SERVICE_GUI_HIDDEN = "SERVICE_GUI_HIDDEN";
export const ALTER_FAVOURITE_SERVICE_GUI_BY_ID =
  "ALTER_FAVOURITE_SERVICE_GUI_BY_ID";
export const ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID =
  "ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID";
export const DELETE_SERVICE_GUI_BY_ID = "DELETE_SERVICE_GUI_BY_ID";
export const CLEAR_SERVICES_GUI = "CLEAR_SERVICES_GUI";

//Deployment
export const ADD_DEPLOYMENT_LIST = "ADD_DEPLOYMENT_LIST";
export const ADD_APP_SELECTION_DATA = "ADD_APP_SELECTION_DATA";
export const ADD_DEPLOY_CONTAINER = "ADD_DEPLOY_CONTAINER";
export const DEPLOYMENT_FILTERS = "DEPLOYMENT_FILTERS";
export const DEPLOYMENT_HIDDEN = "DEPLOYMENT_HIDDEN";
export const DEPLOYMENT_SORT = "DEPLOYMENT_SORT";
export const CLEAR_DEPLOYMENT = "CLEAR_DEPLOYMENT";
export const CLEAR_DEPLOYMENT_DATA = "CLEAR_DEPLOYMENT_DATA";
export const ADD_ENVIRONMENT_DEPLOYMENT = "ADD_ENVIRONMENT_DEPLOYMENT";
export const RESTART_CONTAINER_DEPLOYMENT = "RESTART_CONTAINER_DEPLOYMENT";
export const DELETE_DEPLOYMENT_BY_ID = "DELETE_DEPLOYMENT_BY_ID";
export const ADD_DEPLOYMENT_PROCESS = "ADD_DEPLOYMENT_PROCESS";
export const SELECT_CONTAINER_DEPLOYMENT = "SELECT_CONTAINER_DEPLOYMENT";
export const SELECT_PROCESS_DEPLOYMENT = "SELECT_PROCESS_DEPLOYMENT";
export const EDIT_CONTAINER_DEPLOYMENT = "EDIT_CONTAINER_DEPLOYMENT";
export const ADD_SELECTED_CLUSTER = "ADD_SELECTED_CLUSTER";
export const DELETE_DEPLOYMENT_BY_CONTAINER = "DELETE_DEPLOYMENT_BY_CONTAINER";
export const ADD_VOLUMN_DEPLOYMENT = "ADD_VOLUMN_DEPLOYMENT";
export const ADD_DEPLOYMENT_DETAILS_LIST = "ADD_DEPLOYMENT_DETAILS_LIST";
export const SET_DEPLOY_ERROR = "SET_DEPLOY_ERROR";
export const REMOVE_VERSION = "REMOVE_VERSION";
export const ADD_CLOUD_CONFIG_DETAILS = "ADD_CLOUD_CONFIG_DETAILS";
export const ADD_LAST_SELECTED_CLOUD_CONFIG = "ADD_LAST_SELECTED_CLOUD_CONFIG";
export const ADD_DEPLOYMENT_NAME = "ADD_DEPLOYMENT_NAME";
export const ADD_CHART_VALUES = "ADD_CHART_VALUES";
export const SHOW_CHART = "SHOW_CHART";
export const ADD_CLUSTER_VALIDITY = "ADD_CLUSTER_VALIDITY";
export const REMOVE_CLUSTER_VALIDITY = "REMOVE_CLUSTER_VALIDITY";
export const ADD_DEPLOYMENT_DETAILS_HIDDEN = "ADD_DEPLOYMENT_DETAILS_HIDDEN";
export const ADD_NODES_USAGE_DATA = "ADD_NODES_USAGE_DATA";
export const ADD_NODES_LIST = "ADD_NODES_LIST";

// cluster

export const ADD_CLUSTER_LIST = "ADD_CLUSTER_LIST";
export const CLUSTER_FILTERS = "CLUSTER_FILTERS";
export const CLUSTER_SORT = "CLUSTER_SORT";
export const CLUSTER_HIDDEN = "CLUSTER_HIDDEN";
export const CLEAR_CLUSTER = "CLEAR_CLUSTER";
export const ADD_CLUSTER_DEPLOYMENT_LIST = "ADD_CLUSTER_DEPLOYMENT_LIST";
export const ADD_CLUSTER_PODS_LIST = "ADD_CLUSTER_PODS_LIST";
export const ADD_CLUSTER_REPLICA_SETS_LIST = "ADD_CLUSTER_REPLICA_SETS_LIST";
export const ADD_CLUSTER_SERVICES_LIST = "ADD_CLUSTER_SERVICES_LIST";
export const ADD_DEPLOYMENT_RESPONSE = "ADD_DEPLOYMENT_RESPONSE";
export const CLUSTER_DEPLOYMENT_HIDDEN = "CLUSTER_DEPLOYMENT_HIDDEN";
export const CLUSTER_DEPLOYMENT_SORT = "CLUSTER_DEPLOYMENT_SORT";
export const CLUSTER_PODS_HIDDEN = "CLUSTER_PODS_HIDDEN";
export const CLUSTER_PODS_SORT = " CLUSTER_PODS_SORT";
export const CLUSTER_REPLICA_SET_HIDDEN = "CLUSTER_REPLICA_SET_HIDDEN";
export const CLUSTER_REPLICA_SET_SORT = "CLUSTER_REPLICA_SET_SORT";
export const CLUSTER_SERVICES_HIDDEN = "CLUSTER_SERVICES_HIDDEN";
export const CLUSTSER_SERVICES_SORT = "CLUSTSER_SERVICES_SORT";
export const ADD_CLOUD_CONFIG = "ADD_CLOUD_CONFIG";
export const ADD_SIZING_DEPLOYMENT = "ADD_SIZING_DEPLOYMENT";
export const ADD_CLUSTER_LIST_DEPLOYMENT = "ADD_CLUSTER_LIST_DEPLOYMENT";
export const CLUSTER_SERVICE_SORT = "CLUSTER_SERVICE_SORT";
export const CLUSTER_REPLICASET_HIDDEN = "CLUSTER_REPLICASET_HIDDEN";
// export const CLUSTER_SERVICES_HIDDEN="CLUSTER_SERVICES_HIDDEN";

// Test Execution
export const ADD_TEST_EXECUTION_LIST = "ADD_TEST_EXECUTION_LIST";
export const EXECUTION_FILTERS = "EXECUTION_FILTERS";
export const ALTER_SUBSCRIBE_EXECUTION_BY_ID =
  "ALTER_SUBSCRIBE_EXECUTION_BY_ID";
export const EXECUTION_SORT = "EXECUTION_SORT";
export const ADD_TEST_EXECUTION_DETAILS = "ADD_TEST_EXECUTION_DETAILS";
export const ADD_TEST_EXECUTION_LIST_FILTER = "ADD_TEST_EXECUTION_LIST_FILTER";
export const DELETE_EXECUTION_BY_ID = "DELETE_EXECUTION_BY_ID";
export const EXECTUION_HIDDEN = "EXECTUION_HIDDEN";
export const ADD_TEST_EXECUTION_HISTORY_HIDDEN =
  "ADD_TEST_EXECUTION_HISTORY_HIDDEN";

// Test Script
export const ADD_TEST_SCRIPT_LIST = "ADD_TEST_SCRIPT_LIST";
export const DELETE_SCRIPT_BY_ID = "DELETE_SCRIPT_BY_ID";
export const SCRIPT_FILTERS = "SCRIPT_FILTERS";
export const ALTER_SUBSCRIBE_SCRIPT_BY_ID = "ALTER_SUBSCRIBE_SCRIPT_BY_ID";
export const ALTER_FAVOURITE_SCRIPT_BY_ID = "ALTER_FAVOURITE_SCRIPT_BY_ID";
export const SCRIPT_HIDDEN = "SCRIPT_HIDDEN";

//Test Data
export const ADD_TEST_DATA_LIST = "ADD_TEST_DATA_LIST";
export const DELETE_DATA_BY_ID = "DELETE_DATA_BY_ID";
export const DATA_FILTERS = "DATA_FILTERS";
export const ALTER_SUBSCRIBE_DATA_BY_ID = "ALTER_SUBSCRIBE_DATA_BY_ID";
export const ALTER_FAVOURITE_DATA_BY_ID = "ALTER_FAVOURITE_DATA_BY_ID";
export const DATA_HIDDEN = "DATA_HIDDEN";

//Service Bundle
export const ADD_SERVICE_BUNDLE_LIST = "ADD_SERVICE_BUNDLE_LIST";
export const SERVICE_BUNDLE_SORT = "SERVICE_BUNDLE_SORT";
export const SERVICE_BUNDLE_FILTERS = "SERVICE_BUNDLE_FILTERS";
export const ADD_SERVICE_BUNDLE_SERVICE_LIST =
  "ADD_SERVICE_BUNDLE_SERVICE_LIST";
export const ADD_APP_BUNDLE_DETAILS = "ADD_APP_BUNDLE_DETAILS";
export const ADD_SERVICE_VERSION_FOR_APP_BUNDLE =
  "ADD_SERVICE_VERSION_FOR_APP_BUNDLE";
export const ADD_SERVICE_LIST_DATA_FOR_APP_BUNDLE =
  "ADD_SERVICE_LIST_DATA_FOR_APP_BUNDLE";
export const ADD_SERVICE_TO_CREATE_APP_BUNDLE =
  "ADD_SERVICE_TO_CREATE_APP_BUNDLE";
export const ADD_APP_BUNDLE_ALL_DETAILS = "ADD_APP_BUNDLE_ALL_DETAILS";
export const ADD_SERVICEBUNDLE_TO_APPLICATION =
  "ADD_SERVICEBUNDLE_TO_APPLICATION";
export const ADD_UIPAGES_LIST_DATA = "ADD_UIPAGES_LIST_DATA";
export const CLEAR_APP_BUNDLE_DATA = "CLEAR_APP_BUNDLE_DATA";
export const ADD_APP_BUNDLE_LIST = "ADD_APP_BUNDLE_LIST";
export const ADD_UI_APPLICATION_LIST_DATA = "ADD_UI_APPLICATION_LIST_DATA";
export const ADD_UI_APPLICATION_TO_CREATE_APP_BUNDLE =
  "ADD_UI_APPLICATION_TO_CREATE_APP_BUNDLE";
export const DELETE_APP_BUNDLE_BY_ID = "DELETE_APP_BUNDLE_BY_ID";
export const ADD_AI_TASK_TO_CREATE_APP_BUNDLE =
  "ADD_AI_TASK_TO_CREATE_APP_BUNDLE";
export const ADD_AI_TASK_LIST_DATA_FOR_APP_BUNDLE =
  "ADD_AI_TASK_LIST_DATA_FOR_APP_BUNDLE";
export const ADD_AI_TASK_VERSION_FOR_APP_BUNDLE =
  "ADD_AI_TASK_VERSION_FOR_APP_BUNDLE";
export const APPLICATION_BUNDLE_HIDDEN = "APPLICATION_BUNDLE_HIDDEN";
