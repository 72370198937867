import {
  Grid,
  Typography,
  TextField,
  Tabs,
  Tab,
  Paper,
  FilledInput,
  Checkbox,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import CustomTree from "../../Tree";
import DevExpressTable from "../../Tables/DevExpressTable";
import { AppButton } from "../../Button";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CustomChips from "../../Chips";
import ResourceDeploymentTable from "../../Tables/ResourceDeploymentTable";
import DocumentTableWithDropper from "../../Tables/DocumentTableWithDropper";
import moment from "moment";
import { connect } from "react-redux";
import LanguageChips from "../../LanguageChip";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import Loader from "../../stuff/Loader";
import useCheckPermission from "../../hooks/useCheckPermission";
import AddServiceGroup from "../../../../screens/ServiceGroup/AddServiceGroup";
import { updateServiceGroupMode } from "../../../../redux/actions/projectAction";
import EditServiceGroup from "../../../../screens/ServiceGroup/EditServiceGroup";

const CustomFieldGroupsForProjectEdit = ({ section, isEditMode, projectData, setProjectData, serviceGroupsHierarchy,
  teamLoader, statuses, error, handleChange, projectId, serviceGroups, servicesGroupListByProject, currentPageServiceGroup,
  teamSearchInput, setCurrentPageServiceGroup, setSort, handleTeamCheck, clickedTeam, projectTeams, projectServices,
  teamTab, documents, handleChangeTeamTab, handleTeamSearch, selectedTeams, teams, setClickedTeam, setTeamSearchInput,
  handleSelectedTeamDelete, languages, projectLanguages, setProjectLanguages, handleLanguageDelete, fetchDocuments,
  sort, tableLoading, tableData, userInfo, windowDimensions, project_document_list, currentPageProjectDocumentList,
  handleProjectDocumentListPagination, serviceGroupHidden, addServiceGroupHidden, serviceGroupMode, updateServiceGroupMode, tabbedView
}) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { checkCompPermission } = useCheckPermission();

  const [languageOpen, setLanguageOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const closeDropDown = () => {
    setLanguageOpen(false);
    setStatusOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const hierarchySection = () => {
    return (
      <CustomTree parentData={projectData} childData={serviceGroupsHierarchy} />
    );
  };

  const statusSection = () => {
    return (
      <>
        {isEditMode && (
          <>
            <Autocomplete
              open={statusOpen}
              onOpen={() => setStatusOpen(true)}
              onClose={() => setStatusOpen(false)}
              options={statuses}
              getOptionLabel={(option) => option.status}
              className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
                }`}
              onChange={(e, input) => {
                if (input) {
                  setProjectData({
                    ...projectData,
                    status: input.status,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Search Status"
                  placeholder="Search Status"
                />
              )}
            />
            {error.status?.isError && (
              <p className={styles.errorMsg}>{"Status is Required"}</p>
            )}
          </>
        )}

        {projectData?.status ? (
          <div style={{ display: "flex", marginTop: 15, marginLeft: 16 }}>
            <div
              style={{
                backgroundColor: statuses.find(
                  (s) => s.status === projectData?.status
                )?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                position: "relative",
                right: 10,
              }}
            ></div>
            <Typography
              variant="h6"
              className={styles.fieldText}
              style={{
                color: statuses.find((s) => s.status === projectData?.status)
                  ?.color,
              }}
            >
              {statuses
                .find((s) => s.status === projectData?.status)
                ?.status.charAt(0)
                .toUpperCase() +
                statuses
                  .find((s) => s.status === projectData?.status)
                  ?.status.slice(1)}
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" className={styles.fieldText}>
            Status is not set
          </Typography>
        )}
      </>
    );
  };

  const serviceGroupTableSection = () => {
    return (
      <>
        {!serviceGroupMode && <> {isEditMode ? (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ margin: "20px 0" }}
          >
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >

              {tabbedView ?

                <AppButton
                  color="primary"
                  buttonName="Create Service Group"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={!checkCompPermission('Project', ['Create Service Group'])}
                  style={{ margin: "unset", marginLeft: "20px" }}
                  className="btnsmall"
                  onClick={() => updateServiceGroupMode({ value: 'create' })}
                />
                :
                <AppButton
                  color="primary"
                  buttonName="Create Service Group"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={
                    !checkCompPermission("Project", ["Create Service Group"])
                  }
                  style={{ margin: "unset", marginLeft: "20px" }}
                  className="btnsmall"
                  onClick={() =>
                    histroy.push({
                      pathname: `/servicegroups/add/${projectId}`,
                      state: { level: 1, projectName: projectData.name },
                    })
                  }
                />
              }
            </Grid>
          </Grid>
        ) : (
          <div style={{}}></div>
        )}
        </>}

        {serviceGroupMode === 'create' &&
          <AddServiceGroup projectId={projectId} level={1} projectName={projectData.name} />
        }

        {(serviceGroupMode === 'details' || serviceGroupMode === 'edit') &&
          <EditServiceGroup projectId={projectId} level={1} projectName={projectData.name} />
        }
        {!serviceGroupMode && <>
          {checkCompPermission('ServiceGroup', ['List All', 'List Assigned']) &&
            <>
              {tableLoading ? (
                <Loader />
              ) : (
                <DevExpressTable
                  isEditMode={isEditMode}
                  width={windowDimensions.width}
                  columns={tableData.columns}
                  rows={serviceGroups}
                  defaultColumnWidths={tableData.widths}
                  tableColumnExtensions={tableData.extentions}
                  columnOrder={tableData.orders}
                  defaultHiddenColumnNames={tableData.hides}
                  loading={serviceGroups.loading}
                  currentPage={currentPageServiceGroup}
                  setCurrentPage={setCurrentPageServiceGroup}
                  isCustomHeight={false}
                  sort={sort}
                  setSort={setSort}
                  hidden={serviceGroupHidden}
                  setHidden={addServiceGroupHidden}
                  noDataMsg="No Service Group Found"
                />
              )}

              {serviceGroups.length !== 0 && (
                <div className={styles.customPagination}>
                  <Pagination
                    count={Math.ceil(
                      servicesGroupListByProject.data._totalcount / 10
                    )}
                    page={currentPageServiceGroup}
                    onChange={(e, val) => setCurrentPageServiceGroup(val)}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </div>
              )}
            </>}
        </>}
      </>
    );
  };

  const teamsSection = () => {
    return isEditMode ? (
      <>
        <FilledInput
          name="searchInput"
          value={teamSearchInput}
          onChange={(e) => {
            setTeamSearchInput(e.target.value);
            handleTeamSearch(e.target.value);
          }}
          className={`${styles.input} ${styles.searchInput2} ${styles.customSearchInput}`}
          placeholder="Search"
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <img src="/images/search.svg" alt="card" />
              </IconButton>
            </InputAdornment>
          }
        />

        <CustomChips
          chipsData={selectedTeams}
          handleDelete={(team) => handleSelectedTeamDelete(team)}
          isEditMode={!checkCompPermission("Project", ["Assign Or Dismiss Team"])}
        />

        {teamLoader && <CircularProgress color="primary" />}

        <Grid container spacing={5}>
          <Grid
            item
            xs={6}
            sm={6}
            style={{ borderRight: "1px solid rgba(0, 40, 85, .4)" }}
          >
            <div
              className={styles.section}
              style={{
                opacity: 1,
                maxHeight: "25rem",
                overflow: "auto",
                padding: "0 1rem 1rem 0",
              }}
            >
              <Grid container spacing={3}>
                {teams.length > 0 &&
                  teams
                    .filter((team) => team.block_status === "false")
                    .map((team, index) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Paper
                          className={styles.paper}
                          onClick={() => setClickedTeam(team)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={styles.spaceBetweenText}>
                            <Typography variant="body1">{team.name}</Typography>
                            <div className={styles.technologyCheck}>
                              <Typography variant="body1">
                                {team.org}
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={
                                  selectedTeams.find(
                                    (item) => item._id === team._id
                                  )
                                    ? true
                                    : false
                                }
                                className={styles.teamCheckbox}
                                onChange={(e) => handleTeamCheck(team)}
                                name={team.name}
                                disabled={
                                  !checkCompPermission("Project", [
                                    "Assign Or Dismiss Team",
                                  ])
                                }
                              />
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                    ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className={styles.section}>
              <Grid container spacing={3}>
                {clickedTeam?.users?.map((member, index) => (
                  <Grid item xs={6} sm={6} key={index}>
                    <Paper className={styles.paper}>
                      <div className={styles.teamWrapper}>
                        {member?.image ? (
                          <img
                            src={member?.image}
                            className={styles.memberProfile}
                            alt="profile"
                          />
                        ) : (
                          <img src="/images/users/default.svg" alt="profile" />
                        )}
                        <div className={styles.teamMember}>
                          <Typography
                            variant="body1"
                            className={styles.teamMemberName}
                          >
                            {member?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.teamMemberDesgination}
                          >
                            {member?.desgination}
                          </Typography>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    ) : (
      <>
        {projectTeams.length === 0 && (
          <Typography variant="h6" className={styles.fieldText}>
            Teams is not selected
          </Typography>
        )}
        <Tabs
          value={teamTab}
          onChange={handleChangeTeamTab}
          indicatorColor="primary"
          textColor="primary"
          className={styles.teamTab}
        >
          {projectTeams.map((team, index) => (
            <Tab label={team.name} key={index} />
          ))}
        </Tabs>
        {projectTeams.map(
          (team, index) =>
            teamTab === index && (
              <div className={styles.teamChips} key={index}>
                <Grid container spacing={3}>
                  {team?.users?.map((data, index) => (
                    <Grid item xs={3} sm={3} key={index}>
                      <Paper
                        className={styles.teamMemberPaper}
                        onClick={() =>
                          histroy.push(`/user-details/${data._id}`)
                        }
                      >
                        {data?.image ? (
                          <img
                            src={data?.image}
                            className={styles.memberProfile}
                            alt="profile"
                          />
                        ) : (
                          <img src="/images/users/default.svg" alt="profile" />
                        )}
                        <Typography
                          variant="body1"
                          className={styles.teamMemberName}
                        >
                          {data?.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.teamMemberDesgination}
                        >
                          {data?.desgination}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
        )}
      </>
    );
  };

  const documentSection = () => {
    return (
      <>
        {checkCompPermission("Document", ["List All", "List Assigned"]) ? (
          <div style={{ marginTop: 15 }}>
            <DocumentTableWithDropper
              resourceId={projectId}
              resourceType={"project"}
              isEditMode={
                isEditMode && checkCompPermission("Project", ["Create Document"])
              }
              rows={documents}
              fetchDocuments={fetchDocuments}
              isDocumentModule={false}
            />

            {documents.length !== 0 && (
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(project_document_list._totalcount / 10)}
                  page={currentPageProjectDocumentList}
                  onChange={handleProjectDocumentListPagination}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            )}
          </div>
        ) : (
          <p className={styles.fieldHeading} style={{ color: "red" }}>
            You do not have permission to Document List All, List Assigned. Please
            contact your Admin.
          </p>
        )}
      </>
    );
  };

  const governanceSection = () => {
    return (
      <>
        <Typography variant="h6" className={styles.fieldText}>
          Languages <span className={styles.red}>*</span>
        </Typography>
        {isEditMode && (
          <Autocomplete
            open={languageOpen}
            onOpen={() => setLanguageOpen(true)}
            onClose={() => setLanguageOpen(false)}
            options={languages}
            getOptionLabel={(option) => option.name}
            className={`${styles.searchInput2} ${error.languages?.isError &&
              projectData.languages.length === 0 &&
              styles.errorAuto
              }`}
            onChange={(e, input) => {
              if (input && !projectLanguages.includes(input)) {
                setProjectLanguages([...projectLanguages, input]);
                setProjectData({
                  ...projectData,
                  languages: [...projectData.languages, input._id],
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search Language"
                placeholder="Search Language"
              />
            )}
          />
        )}
        {error.languages?.isError && projectData.languages.length === 0 && (
          <p className={styles.errorMsg}>{error.languages.msg}</p>
        )}

        <LanguageChips
          chipsData={projectLanguages}
          isEditMode={!isEditMode}
          handleDelete={(lang) => handleLanguageDelete(lang)}
        />
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              value={
                field.dataType === "date" && projectData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                      .unix(projectData[field.datakey])
                      .tz(userInfo?.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                          : `${userInfo?.dateFormat} ${userInfo?.time}`
                      )
                    : moment
                      .unix(projectData[field.datakey])
                      .format("DD/MM/YYYY, hh:mm:ss A")
                  : projectData[field.datakey]
              }
              autoComplete="off"
              disabled={!isEditMode || field.editable === "FALSE"}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={projectData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                projectData[field.datakey] ? projectData[field.datakey] : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                projectData[field.datakey] ? projectData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      name={field.datakey}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                      value={
                        field.dataType === "date" && projectData[field.datakey]
                          ? userInfo?.timeZone
                            ? moment
                              .unix(projectData[field.datakey])
                              .tz(userInfo?.timeZone)
                              .format(
                                userInfo.time === "h:mm"
                                  ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                                  : `${userInfo?.dateFormat} ${userInfo?.time}`
                              )
                            : moment
                              .unix(projectData[field.datakey])
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : projectData[field.datakey]
                      }
                      autoComplete="off"
                      disabled={!isEditMode || field.editable === "FALSE"}
                      onChange={handleChange}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  projectData[`${field.datakey}_unit`]
                    ? projectData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={projectData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                projectData[field.datakey] ? projectData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Project_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Project Hierarchy Tree":
                          return hierarchySection();
                        case "Project Status":
                          return statusSection();
                        case "Project Teams":
                          return teamsSection();
                        case "Project Languages":
                          return governanceSection();
                        case "Service groups table":
                          return serviceGroupTableSection();
                        case "Deployment Table":
                          return (
                            <>
                              {checkCompPermission("Project", [
                                "Manage Deployment",
                              ]) ? (
                                <ResourceDeploymentTable
                                  services={projectServices}
                                  isEditMode={isEditMode}
                                  resourceId={projectId}
                                  resourceType={"project"}
                                />
                              ) : (
                                <p
                                  className={styles.fieldHeading}
                                  style={{ color: "red" }}
                                >
                                  You do not have permission to Manage
                                  Deployment. Please contact your Admin.
                                </p>
                              )}
                            </>
                          );
                        case "Project Documentation":
                          return documentSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Project Hierarchy Tree":
                                return hierarchySection();
                              case "Project Status":
                                return statusSection();
                              case "Project Teams":
                                return teamsSection();
                              case "Project Languages":
                                return governanceSection();
                              case "Service groups table":
                                return serviceGroupTableSection();
                              case "Deployment Table":
                                return (
                                  <>
                                    {checkCompPermission("Project", [
                                      "Manage Deployment",
                                    ]) ? (
                                      <ResourceDeploymentTable
                                        services={projectServices}
                                        isEditMode={isEditMode}
                                        resourceId={projectId}
                                        resourceType={"project"}
                                      />
                                    ) : (
                                      <p
                                        className={styles.fieldHeading}
                                        style={{ color: "red" }}
                                      >
                                        You do not have permission to Manage
                                        Deployment. Please contact your Admin.
                                      </p>
                                    )}
                                  </>
                                );
                              case "Project Documentation":
                                return documentSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { serviceGroupMode } = state.projectReducer
  const { tabbedView } = state.userDetails.userInfo;
  return {
    userInfo,
    serviceGroupMode,
    tabbedView
  };
};

const mapDispatchToProps = {
  updateServiceGroupMode
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldGroupsForProjectEdit);
