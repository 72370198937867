import {
  ADD_TEAMS_DATA,
  TEAMS_FILTER,
  TEAMS_SORT,
  LOCK_UNLOCK_TEAM,
  BLOCK_UNBLOCK_TEAM,
  CLEAR_TEAMS_DATA,
  TEAMS_HIDDEN_COULMNS,
} from "../actionTypes";
import { TeamAPIServices } from "../../services/teamServices";

const teamAPIService = new TeamAPIServices();

function add_teams_list_data(payload) {
  return {
    type: ADD_TEAMS_DATA,
    payload: payload,
  };
}

export function add_teams_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_teams_list_data({
        loading: true,
      })
    );
    teamAPIService
      .getTeamList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_teams_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_teams_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_teams_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}
export const add_team_filters = (teamFilter) => {
  return {
    type: TEAMS_FILTER,
    payload: teamFilter,
  };
};

export const add_teams_sort = (teamsSort) => {
  return {
    type: TEAMS_SORT,
    payload: teamsSort,
  };
};
export const lock_unlock_team = (teamID, lock_status) => {
  return {
    type: LOCK_UNLOCK_TEAM,
    payload: {
      teamID,
      lock_status,
    },
  };
};
export const block_unblock_team = (teamID, block_status) => {
  return {
    type: BLOCK_UNBLOCK_TEAM,
    payload: {
      teamID,
      block_status,
    },
  };
};
export const clear_teams_data = () => {
  return {
    type: CLEAR_TEAMS_DATA,
  };
};

export const add_teams_hidden_cols = (teamsHiddenCols) => {
  return {
    type: TEAMS_HIDDEN_COULMNS,
    payload: teamsHiddenCols,
  };
};
