import React, { useEffect, useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useHistory, useParams } from "react-router-dom";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import listStyles from "../../../screens/CommonStyles/listScreenStyles";
import dataStyles from "../dataStandardSpecificStyles";

import { getFieldById } from "../../../utils/dataStandards/fieldsUtils";
import { useSnackbar } from "notistack";
import { AppButton } from "../../../components/common/Button";

// Entity Schema
import CustomFieldGroupsForGlossaryFieldEdit from "../../../components/common/entitySchema/dataStandards/CustomFieldGroupsForGlossaryFieldEdit";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { fetchObjectByID_ } from "../../../utils/ontology/ontologyUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { connect } from "react-redux";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";

function EditField({ userInfo }) {
  const history = useHistory();
  const dataStyle = dataStyles();
  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = useParams();
  const [selectValue, setSelectValue] = useState("");
  const [isClear, setIsClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldData, setFieldData] = useState({
    name: "",
    type: "",
    description: "",
    rules: {
      maxLength: "",
      minLength: "",
      pattern: "",
      contentEncoding: "",
      contentMediaType: "",
      minimum: "",
      maximum: "",
      multipleOf: "",
      exclusiveMinimum: "",
      exclusiveMaximum: "",
    },
  });
  const [error, setError] = useState({
    name: false,
    description: false,
    type: false,
    rules: {
      minLength: false,
      maxLength: false,
      pattern: false,
      contentEncoding: false,
      contentMediaType: false,
      minimum: false,
      maximum: false,
      multipleOf: false,
      exclusiveMinimum: false,
      exclusiveMaximum: false,
    },
  });
  const [tab, setTab] = useState(0);

  const [glossaryCreateEntity, setGlossaryCreateEntity] = useState({});

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    checkScreenPermission("GlossaryField", ["Read"]);
    const fetchObjectDetails = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "GlossaryField",
        "read"
      );
      if (_status === 200) {
        setGlossaryCreateEntity(data);

        if (data) {
          const projData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field, index) => {
                if (field.datakey) {
                  return (projData[field.datakey] = "");
                }
              });
            });
          });

          setFieldData(projData);
          const errData = {};
          data.sections.map((section) => {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  return (errData[field.datakey] = false);
                }
              });
            });
          });
          setError(errData);
          await getFieldDetails();
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
      setLoading(false);
    };
    await fetchObjectDetails();
  }, []);

  useEffect(() => {
    getFieldDetails();
  }, []);

  useEffect(() => {
    setSelectValue(fieldData.type);
  }, [fieldData.type]);

  const getFieldDetails = async () => {
    setLoading(true);
    const { _msg, _status, data } = await getFieldById(_id);
    if (_status === 200) {
      setFieldData({ ...fieldData, ...data });
      setLoading(false);
    } else {
      setLoading(false);
      if (_msg === "Invalid Json") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setError({
      ...error,
      [event.target.name]: false,
    });
    setFieldData({
      ...fieldData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (fieldData.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked field. Kindly unblock first.", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [fieldData.status]);

  var count = 0;
  var servicesCountArray = fieldData?.apidetails?.map((item) => {
    count += item?.Microservice?.length;
    return item?.Microservice;
  });
  useEffect(() => {
    servicesCountArray = fieldData?.apidetails?.map((item) => {
      count += item?.Microservice?.length;
      return item?.Microservice;
    });
    if (servicesCountArray) {
      servicesCountArray = servicesCountArray?.flat(2);
      const result = [];
      const map = new Map();
      for (const item of servicesCountArray) {
        if (!map.has(item._id)) {
          map.set(item._id, true);
          result.push(item);
        }
      }
      servicesCountArray = result;
    }
  }, [fieldData.apidetails]);

  if (servicesCountArray) {
    servicesCountArray = servicesCountArray?.flat(2);
    const result = [];
    const map = new Map();
    for (const item of servicesCountArray) {
      if (!map.has(item._id)) {
        map.set(item._id, true);
        result.push(item);
      }
    }
    servicesCountArray = result;
  }
  const servicesCount = servicesCountArray ? servicesCountArray?.length : 0;

  const apiCount = fieldData?.apidetails?.length || 0;
  const fieldGroupsCount = fieldData?.fieldgroups?.length || 0;

  return (
    <main className={styles.main}>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Data Dictionary",
              path: "/data-standards?datastandards=true",
            },
            { title: fieldData.name || "Field Details" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>
            {fieldData.name || "Field Details"}
          </h2>
          {fieldData.status === "active" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginTop: "26px",
              }}
            >
              <AppButton
                color="primary"
                buttonName="Edit"
                variant="contained"
                // startIcon={<img src="/images/edit.svg" alt="card" />}
                style={{ padding: "6px 15px" }}
                className="btnsmall"
                onClick={() => history.push(`/edit-field/${_id}`)}
                disabled={!checkCompPermission("GlossaryField", ["Edit"])}
              />
              <AppButton
                style={{
                  padding: "6px 10px",
                  minWidth: "99px",
                  marginLeft: "10px",
                }}
                className={`btnsmall`}
                variant="outlined"
                onClick={() =>
                  history.push("/data-standards?datastandards=true")
                }
                buttonName="Cancel"
              />
            </div>
          )}
        </Grid>
      </div>

      <div className={styles.wrapper}>
        {glossaryCreateEntity?.sections &&
          glossaryCreateEntity?.sections.map((section) => {
            if (section.name === "Glossary_Field_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForGlossaryFieldEdit
                    section={section}
                    isEditMode={false}
                    fieldData={fieldData}
                    isClear={isClear}
                    clearRulesHandler={() => {}}
                    error={error}
                    setFieldData={setFieldData}
                    handleChange={handleChange}
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    servicesCount={servicesCount}
                    apiCount={apiCount}
                    fieldGroupsCount={fieldGroupsCount}
                    servicesCountArray={servicesCountArray}
                    dataStyle={dataStyle}
                  />
                  <Grid
                    container
                    // className={styles.editDetails}
                    style={{ marginTop: "-27px" }}
                    spacing={3}
                  >
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Created On</label>
                      <input
                        value={moment
                          .unix(fieldData.createdon)
                          .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Created On :&nbsp;</label>
                      <span>
                        {moment
                          .unix(fieldData.createdon)
                          .format("DD/MM/YYYY, hh:mm:ss A") || " "}
                      </span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Created By</label>
                      <input
                        value={fieldData.createdby || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Created By :&nbsp;</label>
                      <span>{fieldData.createdby || " "}</span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Updated On</label>
                      <input
                        value={fieldData.updatedon
                          ? moment
                              .unix(fieldData.updatedon)
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Updated On :&nbsp;</label>
                      <span>
                        {" "}
                        {fieldData.updatedon
                          ? moment
                              .unix(fieldData.updatedon)
                              .format("DD/MM/YYYY, hh:mm:ss A")
                          : " "}
                      </span> */}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <label className={styles.fieldHeading}>Updated By</label>
                      <input
                        value={fieldData.updatedby || " "}
                        className={`${styles.input} ${styles.editMode}`}
                        disabled={true}
                        type="text"
                      />
                      {/* <label>Updated By :&nbsp;</label>
                      <span>{fieldData.updatedby || " "}</span> */}
                    </Grid>
                  </Grid>
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomFieldGroupsForGlossaryFieldEdit
                      section={section}
                      isEditMode={false}
                      fieldData={fieldData}
                      isClear={isClear}
                      clearRulesHandler={() => {}}
                      error={error}
                      setFieldData={setFieldData}
                      handleChange={handleChange}
                      tab={tab}
                      handleChangeTab={handleChangeTab}
                      servicesCount={servicesCount}
                      apiCount={apiCount}
                      fieldGroupsCount={fieldGroupsCount}
                      servicesCountArray={servicesCountArray}
                      dataStyle={dataStyle}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(EditField);
