import {
  Grid,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  Paper,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { connect } from "react-redux";
import { add_vendorlist_data } from "../../../redux/actions/vendorAction";
import { useSnackbar } from "notistack";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { createVendor } from "../../../utils/vendor/vendorUtils";
import { AppButton } from "../../../components/common/Button";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CreateVendorEntity from "../../../components/common/entitySchema/vendors/CreateVendorEntity";
import { ArrowDropDownRounded } from "@material-ui/icons";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";

const CreateVendor = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Entity
  const [vendordata, setVendorData] = useState({});
  const [vendorEntitySchema, setVendorEntitySchema] = useState([]);
  const [error, setError] = useState({
    name: { isError: false },
  });

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/vendors");
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Vendor", ["Create"]);
  }, []);

  useEffect(() => {
    const fetchProjectEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Vendor",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        //
        setVendorEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        //
        setVendorData({ ...vendordata, ...projData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["teams"];
        //
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchProjectEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setVendorData({
      ...vendordata,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(vendordata, error);
    if (formIsValid) {
      setLoading(true);
      const req_body = {
        ...vendordata,
      };
      const { _msg, _status, data } = await createVendor(req_body);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setLoading(false);

        histroy.push("/vendors");
      } else {
        setLoading(false);
        if (data && data?.length > 0) {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        vendorEntitySchema ? vendorEntitySchema : [],
        errors,
        tabbedView
      );
      setError(errors);
      setActiveTab(errorInTab);
      setVendorEntitySchema(tempEntity);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  return (
    <main className={styles.main}>
      {loading && <Loader />}
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Vendors", path: "/vendors" },
            { title: "Create Vendor", path: "" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h2 className={styles.title}>Create Vendor</h2>
          {tabbedView ? (
            <TabbedNavigation
              isCreateMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              handleSave={handleSubmit}
              disabled={false}
              handleCancel={handleCancel}
              isEditMode={false}
              isCreateMode={true}
            />
          )}
        </Grid>
        <div className={styles.border_line} />
      </div>
      <div className={styles.wrapper} style={{top: "6.5rem"}} >
        {tabbedView ? (
          <div style={{ marginTop: "-20px" }}>
            <SectionTabs
              entity={
                vendorEntitySchema ? vendorEntitySchema : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <CreateVendorEntity
                  section={vendorEntitySchema?.[activeTab]}
                  vendordata={vendordata}
                  setVendorData={setVendorData}
                  error={error}
                  setError={setError}
                  handleChange={handleChange}
                />
              </div>
            </Paper>
          </div>) : (vendorEntitySchema &&
            vendorEntitySchema.map((section) => {
              if (section.name === "Vendor_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "15px 5px 0" }}>
                    <CreateVendorEntity
                      section={section}
                      vendordata={vendordata}
                      setVendorData={setVendorData}
                      error={error}
                      setError={setError}
                      handleChange={handleChange}
                    />
                  </div>
                );
              } else {
                return (
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded={true}
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded className={styles.accordianIcon} />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CreateVendorEntity
                        section={section}
                        vendordata={vendordata}
                        setVendorData={setVendorData}
                        error={error}
                        setError={setError}
                        handleChange={handleChange}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              }
            }))}
      </div>
    </main>
  );
};
const mapDispatchToProps = {
  add_vendorlist_data,
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendor);
