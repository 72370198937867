import {
  ADD_TAG,
  FETCH_ALL_TAGS,
  FETCH_TAG_BY_ID,
  EDIT_TAG_BY_ID,
  DELETE_TAG,
  GLOSSARY_FILTERS,
  CLEAR_GLOSSARY_FILTERS,
  SET_GLOSSARY_SORT,
  ADD_LOADER,
  FETCH_TAG_RESOURCES,
  ADD_TAG_RESOURCES_LOADER,
  SET_DATA_STANDARD_TAB,
  ADD_FIELD_LOADER,
  ADD_FIELD_GROUP,
  FETCH_FIELD_GROUPS,
  EDIT_FIELD_GROUP,
  FETCH_FIELDS,
  ADD_FIELD,
  EDIT_FIELD,
  SET_FIELD_PAGE,
  SET_FIELD_GROUP_PAGE,
  SET_FIELD_SORT,
  SET_FIELD_GROUP_SORT,
  FIELDGROUP_FILTERS,
  SET_FIELD_FILTERS,
  CLEAR_FIELD_FILTERS,
  FIELD_HIDDEN,
  CLEAR_DATA_STANDARD_DATA,
  FIELDGROUP_DELETE,
  CLEAR_GLOSSARY_LIST_DATA,
  FIELDGROUP_HIDDEN,
  BLOCK_UNBLOCK_FIELD,
  BLOCK_UNBLOCK_PAYLOAD,
  GLOSSARY_DELETE,
  FIELD_DELETE,
  ALTER_FAVOURITE_GLOSSARY_BY_ID,
} from "../actionTypes";

import { DataStandardService } from "../../services/dataStandardService";

const dataStandardService = new DataStandardService();
// <<<--- GLOSSARY --->>>

const add_tag_data = (payload) => ({
  type: FETCH_ALL_TAGS,
  payload: payload,
});

//  GET REQUESTS
export const fetchAllTags =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites },
    } = getState();

    try {
      dispatch({ type: ADD_LOADER, payload: {} });
      const { data } = await dataStandardService.fetchAllTagsByFilter(
        currentPage,
        filter,
        sort
      );
      if (data) {
        dispatch(
          add_tag_data({
            loading: false,
            data: {
              data:
                data.data.length !== 0 &&
                data.data.map((tag) => {
                  const checkFav =
                    userFavourites.filter(
                      (fav) => fav.resourceinstance === tag._id
                    ).length !== 0;
                  let tempTag = tag;
                  if (checkFav) {
                    tempTag["isFavourite"] = true;
                  } else {
                    tempTag["isFavourite"] = false;
                  }
                  return tempTag;
                }),
            },
            error: false,
            _totalcount: data._totalcount,
          })
        );
      } else {
        dispatch(
          add_tag_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
      // dispatch({ type: FETCH_ALL_TAGS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const fetchTagById = (id) => async (dispatch) => {
  try {
    const { data } = await dataStandardService.fetchTagById(id);
    dispatch({ type: FETCH_TAG_BY_ID, payload: data.data });
    return data;
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const fetchTagResources = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TAG_RESOURCES_LOADER });
    const { data } = await dataStandardService.fetchTagResources(id);
    dispatch({ type: FETCH_TAG_RESOURCES, payload: data.data });
    return data;
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

// POST REQUESTS
export const addTag = (tagData, history) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOADER });
    const { data } = await dataStandardService.addTag(tagData);
    dispatch({ type: ADD_TAG, payload: data });
    return data;
  } catch (err) {
    const { _msg, _status, data } = err?.response?.data;
    return {
      _msg,
      _status,
      data,
    };
    // dispatch({ type: ADD_TAG, payload: { _msg: "Oops, couldn't add that tag. Please try again." } })
  }
};

// PUT REQUESTS
export const editTagById = (id, tagData, history) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOADER, payload: {} });
    const { data } = await dataStandardService.editTagById(id, tagData);
    // dispatch({ type: EDIT_TAG_BY_ID, payload: data });
    return data;
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

// GLOSSARY FAVOURITE
export const alterFavouriteGlossaryById = (glossaryId) => {
  return {
    type: ALTER_FAVOURITE_GLOSSARY_BY_ID,
    payload: {
      glossaryId,
    },
  };
};

// DELETE REQUESTS
export const deleteTag = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOADER, payload: {} });
    const { data } = await dataStandardService.deleteTag(id);
    dispatch({ type: DELETE_TAG, payload: data });
    return data;
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteTagUtil = async (id) => {
  try {
    const { data } = await dataStandardService.deleteTag(id);
    return data;
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

// MISC
export const setGlossarySort = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_GLOSSARY_SORT, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const addGlossaryFilters = (glossaryFilters) => async (dispatch) => {
  dispatch({
    type: GLOSSARY_FILTERS,
    payload: glossaryFilters,
  });
};

export const addFieldGroupHidden = (fieldgroupHidden) => {
  return {
    type: FIELDGROUP_HIDDEN,
    payload: fieldgroupHidden,
  };
};

export const clearGlossaryFilters = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GLOSSARY_FILTERS,
    payload: {},
  });
};

// <<<--- DATA STANDARDS --->>>
export const setTab = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_DATA_STANDARD_TAB, payload: value });
  } catch (error) {
    console.log(error);
  }
};
//    <<-- FIELD GROUPS -->>

// GET REQUESTS
export const fetchAllFieldGroups =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch({ type: ADD_FIELD_LOADER, payload: {} });
      const { data } = await dataStandardService.fetchAllFieldGroups(
        currentPage,
        filter,
        sort
      );
      dispatch({ type: FETCH_FIELD_GROUPS, payload: data });
      // dispatch({ type: SET_FIELD_GROUP_PAGE, payload: 1 });
    } catch (error) {
      console.log(error);
    }
  };
// POST REQUESTS
export const postFieldGroup = (fieldGroupData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_FIELD_LOADER, payload: {} });
    const { data } = await dataStandardService.postFieldGroup(fieldGroupData);
    // dispatch({ type: ADD_FIELD_GROUP, payload: data });
    return data;
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
// MISC
export const setCurrentFieldGroupPage = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_FIELD_GROUP_PAGE, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const setFieldGroupSort = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_FIELD_GROUP_SORT, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const addFieldGroupFilters = (fieldGroupFilters) => {
  return {
    type: FIELDGROUP_FILTERS,
    payload: fieldGroupFilters,
  };
};

export const deleteFieldgroup = (fieldgroupId) => {
  return {
    type: FIELDGROUP_DELETE,
    payload: {
      fieldgroupId,
    },
  };
};

export const deletefield = (fieldId) => {
  return {
    type: FIELD_DELETE,
    payload: {
      fieldId,
    },
  };
};

export const deleteGlossaryById = (glossaryId) => {
  return {
    type: GLOSSARY_DELETE,
    payload: {
      glossaryId,
    },
  };
};

export const blockUnblockFieldByID = (fieldId, status) => {
  return {
    type: BLOCK_UNBLOCK_FIELD,
    payload: {
      fieldId,
      status,
    },
  };
};

export const blockUnblockPayloadByID = (payloadId, status) => {
  return {
    type: BLOCK_UNBLOCK_PAYLOAD,
    payload: {
      payloadId,
      status,
    },
  };
};

//    <<-- FIELDS -->>
// GET REQUESTS
export const fetchAllFields =
  (currentPage, parsedFilter, parsedSortField) => async (dispatch) => {
    try {
      dispatch({ type: ADD_FIELD_LOADER, payload: {} });
      const { data } = await dataStandardService.fetchAllFields(
        currentPage,
        parsedFilter,
        parsedSortField
      );
      dispatch({ type: FETCH_FIELDS, payload: data });
      // dispatch({ type: SET_FIELD_PAGE, payload: 1 });
    } catch (error) {
      console.log(error);
    }
  };
// POST REQUESTS
export const postField = (fieldData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_FIELD_LOADER, payload: {} });
    const res_data = await dataStandardService.postField(fieldData);
    dispatch({ type: ADD_FIELD, payload: res_data?.data });
    return res_data?.data;
  } catch (err) {
    const { _msg, _status, data } = err?.response?.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};
// MISC
export const setCurrentFieldPage = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_FIELD_PAGE, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const setFieldSort = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_FIELD_SORT, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const addFieldFilters = (value) => async (dispatch) => {
  dispatch({ type: SET_FIELD_FILTERS, payload: value });
};

export const clearFieldFilters = () => async (dispatch) => {
  dispatch({ type: CLEAR_FIELD_FILTERS, payload: {} });
};

// Column Chooser
export const addFieldHidden = (fieldHidden) => {
  return {
    type: FIELD_HIDDEN,
    payload: fieldHidden,
  };
};
export const clearDataStandardListScreen = () => {
  return {
    type: CLEAR_DATA_STANDARD_DATA,
  };
};

export const clearGlossaryListScreen = () => {
  return {
    type: CLEAR_GLOSSARY_LIST_DATA,
  };
};
