/* eslint-disable */
import React,{useState} from "react";
import { Paper, Grid,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import useCheckPermission from "../common/hooks/useCheckPermission";
import { useSnackbar } from "notistack";
const cardStyles = makeStyles((theme) => ({
  singlecard: {
    // height:"170px",
    padding: '25px',
    borderRadius: 10,
    boxShadow: '0px 3px 6px #3C3C3C1A',
    position:"relative",
    // zIndex:-1
  },
  cardline1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: '#535353',

    "& h3": {
      font: 'normal normal bold 15px/20px Nunito',
      color: '#535353',
      margin: 0
    }
  },
  projecttitle: {
    margin: '15px 0',
    font: 'normal normal 600 16px/22px Nunito',
    color: '#535353'
  },
  cardDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #D4D4D4",
    color: '#535353',
    borderRadius: 10,
    padding: 8,
    font: 'normal normal normal 12px/16px Nunito',

    "& img": {
      height: 20
    }
  },
  cardicons: {
    "& img": {
      marginLeft: 15,
      cursor: 'pointer'
    }
  },
  MuiDivider2: {
    width: '2px',
    height: '30px',
  },
  documentTypeCardText:{
    font:"normal normal normal 14px/19px Nunito",
  },
  documentTypeReadMoreToggleText:{
    font:"normal normal normal 14px/19px Nunito",
    float:"right",
    // marginTop:"20px",
    cursor:"pointer"
  },
  documentOverlayCards:{
    position:"absolute",
    zIndex:999,
    // width:289,
    padding: '25px',
    borderRadius: 10,
    boxShadow: '0px 3px 6px #3C3C3C1A',
    background:"white"
  },
  imageIcon:{
    filter: "brightness(0.5)"
  }
}));

export default function DocumentTypeCard(props) {
  const styles = cardStyles();
  const { data } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission()
  return (
    <Grid item xs={12} sm={4} lg={3} xl={3}>
      <Paper style={{position:"relative", }}>
      <div className={isReadMore ? styles.singlecard : styles.documentOverlayCards}>
        <div className={styles.cardline1}>
          <h3>{data.name}</h3>
          <div className={styles.cardicons}>
              <img src="/images/editLine.svg" alt="card" onClick={(e) => {
                if (checkCompPermission('DocumentType', ['Edit'])) {
                  props.setDocTypeData(data)
                  props.setIsCreateDocumentsClicked(true)
                } else {
                  enqueueSnackbar(`You do not have permission to access Edit Document Type. 
                  Please contact your Admin.`, { variant: "error" });
                }
              }} />

           <img src="/images/deleteLine.svg" alt="card" className={styles.imageIcon}  onClick={(e) => {
                if (checkCompPermission('DocumentType', ['Delete'])) {
                  props.setHandleDeleteClick(data._id)
                } else {
                  enqueueSnackbar(`You do not have permission to access Delete Document Type. 
                  Please contact your Admin.`, { variant: "error" });
                }
              }} />
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography variant="body1" style={{wordBreak: "break-word"}}>
            {
              isReadMore && data.description.length > 20 ? <p className={styles.documentTypeCardText}>{data.description.slice(0,32)}...</p> : <p className={styles.documentTypeCardText}>{data.description}</p>
            }
            {
             data.description.length >50 ? <p><span className={styles.documentTypeReadMoreToggleText} onClick={toggleReadMore} > 
             {
             isReadMore 
             ? 
               "See More..." 
             : "See Less..."
             } 
             </span></p>
              :
              "" }
          
          </Typography>
        </div>
        </div>
      </Paper>
    </Grid>
  );
}
