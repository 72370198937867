import { DataStandardService } from "../../services/dataStandardService";
const dataStandardService = new DataStandardService();

export const getFieldList = async () => {
  try {
    const { data: res_data } = await dataStandardService.fetchAllFields();
    const { _msg = "", _status, data } = res_data;
    //
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    // const { _msg, _status } = err.response;
    // return {
    //   _msg,
    //   _status,
    // };
   
  }
};

// export const fetchLanguagesListData = async () => {
//   try {
//     const res_data = await launguageService.getAllLanguagesList();
//     const { _msg, _status, data } = res_data.data;
//     return {
//       _status,
//       data,
//       _msg,
//     };
//   } catch (err) {
//    
//     const { _msg, _status, data } = err.response.data;
//     return {
//       _msg,
//       _status,
//       data,
//     };
//   }
// };

export const fetchAllFieldGroups = async () => {
  try {
    const res_data = await dataStandardService.fetchAllFieldGroups();
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg = "", _status = "" } = err.response || {};
    return {
      _msg,
      _status,
    };
  }
};

export const postCreateField = async (fieldData) => {
  try {
    const res_data = await dataStandardService.postField(fieldData);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const postCreateFieldGroup = async (fieldGroupData) => {
  try {
    const res_data = await dataStandardService.postCreateFieldGroup(
      fieldGroupData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    const { _msg, _status } = err.response;
    return {
      _msg,
      _status,
    };
  }
};

export const editFieldById = async (FieldID, fieldData) => {
  try {
    const res_data = await dataStandardService.editField(FieldID, fieldData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const editFieldGroupById = async (FieldGroupID, fieldGroupData) => {
  try {
    const res_data = await dataStandardService.editFieldGroup(
      FieldGroupID,
      fieldGroupData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const getFieldById = async (FieldID) => {
  try {
    const res_data = await dataStandardService.getFieldById(FieldID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const getFieldGroupById = async (FieldGroupID) => {
  try {
    const res_data = await dataStandardService.getFieldGroupById(FieldGroupID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const blockField = async (id) => {
  try {
    const res_data = await dataStandardService.blockField(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const unblockField = async (id) => {
  try {
    const res_data = await dataStandardService.unblockField(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const blockFieldGroup = async (id) => {
  try {
    const res_data = await dataStandardService.blockFieldGroup(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const unblockFieldGroup = async (id) => {
  try {
    const res_data = await dataStandardService.unblockFieldGroup(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const postFieldsBulkUpload = async (file) => {
  try {
    const res_data = await dataStandardService.postFieldsBulkUpload(file);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteFieldGroup = async (id) => {
  try {
    const res_data = await dataStandardService.deleteFieldgroup(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

export const deleteField = async (id) => {
  try {
    const res_data = await dataStandardService.deleteField(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};