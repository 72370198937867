import { AuthenticationServices } from "../../services/authenticationService";

const AuthenticationService = new AuthenticationServices();

export const login = async (req_data) => {
  try {
    const res_data = await AuthenticationService.loginUser(req_data);
    const { _status, data, _msg } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const forgetPassword = async (req_data) => {
  try {
    const res_data = await AuthenticationService.forgetPassword(req_data);
    const { _status, _msg, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const verifyCodeForgetPassword = async (req_data) => {
  try {
    const res_data = await AuthenticationService.verifyCodeForForgetPassword(
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const verifyEmailCodeLogin = async (req_data) => {
  try {
    const res_data = await AuthenticationService.verifyEmailForLogin(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
// resend verification code
export const resendCode = async (req_data) => {
  try {
    const res_data = await AuthenticationService.resendVerificationCode(
      req_data
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const insertPassword_ = async (req_data, userDetails) => {
  try {
    const res_data = await AuthenticationService.insertPassword(
      req_data,
      userDetails
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const loginFromToken = async (req_data) => {
  try {
    const res_data = await AuthenticationService.loginFromToken(req_data);
    const { _status, data, _msg } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const logOutUser = async (userID) => {
  try {
    const res_data = await AuthenticationService.logOutUser(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const forceLoginService = async (tempToken) => {
  try {
    const res_data = await AuthenticationService.forceLogin(tempToken);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
