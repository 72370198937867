export const USER_COLOR_REQUEST = "USER_COLOR_REQUEST";
export const USER_COLOR_SUCCESS = "USER_COLOR_SUCCESS";
export const USER_COLOR_FAIL = "USER_COLOR_FAIL";
// tabs
export const USER_TAB_REQUEST = "USER_TAB_REQUEST";
export const USER_TAB_SUCCESS = "USER_TAB_SUCCESS";
export const USER_TAB_FAIL = "USER_TAB_FAIL";
// for the user details 
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_RESET="USER_RESET"
export const USER_RETAIN="USER_RETAIN"
// for the edit user
export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAIL = "USER_EDIT_FAIL";

export const USER_FAVOURITES = "USER_FAVOURITES";
export const ADD_SINGLE_FAVOURITE = "ADD_SINGLE_FAVOURITE";
export const REMOVE_SINGLE_FAVOURITE = "REMOVE_SINGLE_FAVOURITE";

export const USER_SUBSCRIPTION = "USER_SUBSCRIPTION";
export const ADD_SINGLE_USER_SUBSCRIPTION = "ADD_SINGLE_USER_SUBSCRIPTION";
export const REMOVE_SINGLE_USER_SUBSCRIPTION= "REMOVE_SINGLE_USER_SUBSCRIPTION";

// details status
export const STATUS_DETAILS_REQUEST = "STATUS_DETAILS_REQUEST";
export const STATUS_DETAILS_SUCCESS = "STATUS_DETAILS_SUCCESS";
export const STATUS_DETAILS_FAIL = "STATUS_DETAILS_FAIL";
// set status to a resource type
export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";
// delete status of a particular resource type
// set status to a resource type
export const DELETE_STATUS_REQUEST = "DELETE_STATUS_REQUEST";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAIL = "DELETE_STATUS_FAIL";
// appearance
export const USER_VISIBLE_REQUEST = "USER_VISIBLE_REQUEST";
export const USER_VISIBLE_SUCCESS = "USER_VISIBLE_SUCCESS";
export const USER_VISIBLE_RESET = "USER_VISIBLE_RESET";
export const USER_VISIBLE_FAIL = "USER_VISIBLE_FAIL";
export const USER_HELP_IMAGE = "USER_HELP_IMAGE";

// Notifications
export const USER_NOTIFICATIONS = "USER_NOTIFICATIONS"