import React, { useEffect, useState } from "react";
import Styles from "../../../../../styles/commonStyles/MenuRightBar";
import { Popover } from "@material-ui/core";
import TrueFalseButton from "../../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";

const IconPopup = ({ isOptionsOpen, optionsEl, setOptionsEl, currentPage, currentComponent }) => {
	const styles = Styles();
	const dispatch = useDispatch();

	// const [selectedFile, setselectedFile] = useState(false);
	// useEffect(() => {
	// 	if (currentComponent?.logo) {
	// 		setselectedFile(true);
	// 	}
	// }, [currentComponent?.logo]);

	const handleUploadLogo = (e) => {
		const file = e?.target?.files[0];
		if (file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				const components = currentPage.components.map((component) => {
					if (component.id === currentComponent.id) {
						return {
							...component,
							logo: reader.result,
						};
					} else {
						return component;
					}
				});
				dispatch(
					editComponent({
						pageid: currentPage?.id,
						data: components,
					})
				);
			};
			reader.onerror = function (error) {
				// console.log("Error: ", error);
			};
		}
	};

	return (
		<Popover
			open={isOptionsOpen}
			anchorEl={optionsEl}
			onClose={() => setOptionsEl(null)}
			className={styles.LogoPopup}
			anchorOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "right",
			}}
            
            >
			<div className="wrapper" style={{
                height: 400,
                width: 250,
                padding: 10,
            }}>
				<div className="logoTitleBox">
					<div className="logoTitle">Logo settings</div>
					<div>
						<img src="/images/close.svg" alt="" onClick={() => setOptionsEl(null)} />
					</div>
				</div>
				<hr />
				<div className="uploadLogoWrapper">
					<span>Logo</span>
					<div className="uploadLogo">
						<div className="chooseFile">
							{/* {selectedFile ? (
								<>
									<div className="fileUrl">
										<span>{currentComponent?.logo}</span>
									</div>
									<span onClick={()=>setselectedFile(false)}>
										<img src="/img/delete.svg" alt="" />
									</span>
								</>
							) : (
                            <input type="file" name="" id="" onChange={handleUploadLogo} />
                        )} */}
							<input type="file" name="" id="" onChange={handleUploadLogo} />
						</div>
						<div className="upload">
							<img src="/images/Upload.svg" alt="" />
						</div>
					</div>
				</div>

				{/* Logo Guide */}
				<div className="infoBoxTop">
					<div className="infoTitle">
						<img src="/img/info.svg" alt="" />
						Logo Guide
					</div>
					<div>{/* <img src="/images/close.svg" alt="" /> */}</div>
				</div>
				<div className="infoBoxBottom">
					<div className="LeftBox">
						<div className="titleBox">
							<span className="title">Aspect Ratio</span>
						</div>
						<div className="rightBox">
							<span>
								<span className="span1">Square</span>
								<span className="span2" style={{ paddingLeft: 5 }}>
									1:1
								</span>
							</span>
							<span>
								<span className="span1">Rectangular</span>
								<span className="span2" style={{ paddingLeft: 5 }}>
									Upto 2:1
								</span>
							</span>
						</div>
					</div>
					<div className="LeftBox">
						<div className="titleBox">
							<span className="title">Type</span>
						</div>
						<div className="rightBox2">
							<span className="span2">Jpg,Png</span>
						</div>
					</div>
					<div className="LeftBox">
						<div className="titleBox">
							<span className="title">Resolution</span>
						</div>
						<div className="rightBox3">
							<span>
								<span className="span1">Square</span>
								<br />
								<span className="span2">
									500X500px To <br />
									2000X2000px
								</span>
							</span>{" "}
							<br />
							<span>
								<span className="span1">Rectangular</span>
								<br />
								<span className="span2">
									Height(Min) 128Px <br />
									Width (Max) 2000Px
								</span>
							</span>
						</div>
					</div>
					<div className="LeftBox">
						<div className="titleBox">
							<span className="title">Size</span>
						</div>
						<div className="rightBox2">
							<span className="span2">Max 5Mb</span>
						</div>
					</div>
				</div>
			</div>
		</Popover>
	);
};

export default IconPopup;
