import React from "react";
// import inputType
import CheckStyles from "./CheckStyles";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";

const CheckBox = ({ label, checked, handleChange, selectedComponent }) => {
  const styles = CheckStyles();
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            style={{ color: selectedComponent?.status }}
          />
        }
        label={
          <h1
            className={styles.label}
            style={{
              fontFamily: selectedComponent?.fontFamily,
              fontWeight: selectedComponent?.style?.bold ? "bold" : undefined,
              fontStyle: selectedComponent?.style?.italic
                ? "italic"
                : undefined,
              textDecoration: selectedComponent?.style?.underline
                ? "underline"
                : undefined,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
          >
            {label}
          </h1>
        }
        labelPlacement="end"
        disabled={selectedComponent?.disabled?.dataValue === "true"}
      />
    </div>
  );
};

export default CheckBox;
