const uiApplicationColumns = [
  { name: "icon", title: "Application Icon", for: "uiApplication" },
  { name: "name", title: "Application Name", for: "uiApplication" },
  { name: "description", title: "Description", for: "uiApplication" },
  { name: "createdon", title: "Created On", for: "uiApplication" },
  { name: "createdby", title: "Created By", for: "uiApplication" },
  { name: "updatedon", title: "Updated On", for: "uiApplication" },
  { name: "updatedby", title: "Updated By", for: "uiApplication" },
  { name: "canvas", title: "Pages", for: "uiApplication" },
  {
    name: "action",
    title: "Action",
    route: "/uiApplications/edit",
    for: "uiApplication",
  },
];

const uiApplicationDefaultColumnWidths = [
  { columnName: "icon", weightage: 2.5, width: 250 },
  { columnName: "name", weightage: 2.5, width: 250 },
  { columnName: "description", weightage: 2.5, width: 250 },
  { columnName: "createdon", weightage: 2.5, width: 250 },
  { columnName: "createdby", weightage: 2.5, width: 250 },
  { columnName: "updatedon", weightage: 2.5, width: 250 },
  { columnName: "updatedby", weightage: 2.5, width: 250 },
  { columnName: "canvas", weightage: 2.5, width: 250 },
  { columnName: "action", weightage: 2.5, width: 250 },
];

const uiApplicationTableColumnExtensions = [
  { columnName: "icon", align: "center" },
  { columnName: "name", align: "left" },
  { columnName: "description", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "updatedon", align: "left" },
  { columnName: "updatedby", align: "left" },
  { columnName: "updatedon", align: "left" },
  { columnName: "canvas", align: "center", sortingEnabled: false },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const uiApplicationColumnOrder = [
  "icon",
  "name",
  "description",
  "createdon",
  "createdby",
  "updatedon",
  "updatedby",
  "canvas",
  "action",
];

const uiApplicationColumnHidden = [
  "icon",
  "name",
  "description",
  "createdon",
  "createdby",
  "updatedon",
  "updatedby",
  "canvas",
  "action",
];

const uiApplicationDefaultSorting = [];

export {
  uiApplicationColumns,
  uiApplicationDefaultColumnWidths,
  uiApplicationTableColumnExtensions,
  uiApplicationColumnOrder,
  uiApplicationDefaultSorting,
  uiApplicationColumnHidden,
};
