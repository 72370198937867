import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
// files
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useSnackbar } from "notistack";
// api and redux
import { connect } from "react-redux";
import { add_users_data } from "../../../redux/actions/userInfoActions";
import {
  getUserDetailsByID,
  editUserByID,
  getUserTeamDetails,
  fetchRoles,
  AddRolesToUser,
  RemoveRolesFromUser,
} from "../../../utils/users/userUtils";
import { AppButton } from "../../../components/common/Button";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import UserEditEntitySchemaHelper from "../../../components/common/entitySchema/users/UserEditEntitySchemaHelper";
import { handleFormValidationPro } from "../../../utils/common";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import AddIcon from "@material-ui/icons/Add";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";

const EditUserScreen = (props) => {
  const styles = manageScreenStyles();
  const histroy = useHistory();
  const [userID] = useState(props.match.params._id);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [userteamDetails, setUserteamDetails] = useState();
  const [userData, setUserData] = useState({});
  const [error, setError] = useState({});
  const [userEntitySchema, setUserEntitySchema] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [addRoleToUser, setAddRoleToUser] = useState("");

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("User", ["Edit"]);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const fetchUserDetailsEntity = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "User",
        "edit"
      );
      if (_status === 200) {
        setLoading(false);
        const entity = [];
        data?.sections?.map((section) =>
          // eslint-disable-next-line array-callback-return
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setUserEntitySchema(entity);
        const tempUserData = {};
        const tempError = {};
        data?.sections?.forEach((section) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tempUserData[`${field.datakey}_unit`] = "";
                tempUserData[`${field.datakey}_value`] = "";
              }
            });
          });
        });
        console.log("kj kasjhvsdgshc", data?.sections)

        data?.sections?.forEach((section, index) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (field.required === "TRUE") {
                tempError[field.datakey] = {
                  dataType: field.dataType ? field.dataType : "string",
                  isError: false,
                  fieldLabel: field.fieldLabel,
                  minLength: field.rules?.minLength
                    ? field.rules?.minLength
                    : 1,
                  maxLength: field.rules?.maxLength
                    ? field.rules?.maxLength
                    : 300,
                  pattern: field.rules?.pattern,
                  msg: "",
                  section: index
                };
              }
            });
          });
        });
        delete tempError["roles"];
        delete tempError["teams"];
        setUserData({ ...userData, ...tempUserData });
        setError({ ...error, ...tempError });
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getUserDetailsByID(userID);
      if (_status < 210) {
        setUserData(data);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchRolesData = async (filter) => {
      try {
        setLoading(true);
        const { data } = await fetchRoles(filter);
        setRolesList(
          data
            ? data?.map((item) => {
              return {
                ...item,
                checked: userData?.roles?.find(
                  (role) => role?._id === item?._id
                )
                  ? true
                  : false,
              };
            })
            : []
        );
        setLoading(false);
      } catch (err) { }
    };
    await fetchUserDetailsEntity();
    await fetchData();
    await fetchRolesData();
  }, [userID]);

  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getUserTeamDetails(userID);
      if (_status === 200) {
        setUserteamDetails(() => (data ? data : []));
      } else {
      }
    };
    fetchData();
  }, [userID]);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.goBack();
  };

  const handleSubmit = async () => {
    var tempReqData = { ...userData };
    [
      "color",
      "createdby",
      "createdon",
      "dateFormat",
      "image",
      "language",
      "roles",
      "setpassword",
      "status",
      "time",
      "timeZone",
      "twofactorauthentication",
      "updatedby",
      "updatedon",
      "verified",
      "_id",
    ].forEach((item) => {
      if (Object.keys(tempReqData).includes(item)) {
        delete tempReqData[item];
      }
    });
    console.log("jsvjcjsvjsvjsaau", tempReqData, error);

    const { formIsValid, errors } = handleFormValidationPro(tempReqData, error);
    if (formIsValid) {
      try {
        const reqData = {
          ...tempReqData,
        };
        setLoading(true);
        const { _msg, _status, data } = await editUserByID(userID, reqData);
        if (_status < 210) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "success",
          });

          setLoading(false);

          histroy.push(`/user-details/${userID}`);
        } else {
          setLoading(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(_msg ? _msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      } catch (err) {
        enqueueSnackbar(err, { variant: "error" });
        setLoading(false);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        userEntitySchema ? userEntitySchema : [],
        errors,
        tabbedView
      );
      console.log("jsvjcjsvjsvjsaau", errors);

      setActiveTab(errorInTab);
      setUserEntitySchema(tempEntity);
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === 'roles') {
      setError({
        ...error, [event.target.name]: {
          ...error[event.target.name], isError: true, maxLength: 300, maximum: 300, minLength: 1, minimum: 1, fieldLabel: "Roles", msg: "Roles is required", section: 1, dataType: "string"
        }
      })
    }
    setUserData({
      ...userData,
      [event.target.name]: value,
    });
  };

  // Add Roles modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (checkCompPermission("User", ["Assign Role"])) {
      setOpen(true);
    } else {
      enqueueSnackbar(
        "You do not have permission to add user. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddRolestoUser = async () => {
    let tempRole = rolesList.filter((fil) => fil?.checked === true);
    const AddRole = {
      roleIds: tempRole?.map((item) => item?._id),
    };
    setLoading(true);
    const { _msg, _status, data } = await AddRolesToUser(userID, AddRole);
    if (_status === 200) {
      setError({
        ...error, roles: { dataType: "string", fieldLabel: "Roles", isError: false, maxLength: 300, maximum: 300, minLength: 1, minimum: 1, msg: "Roles is required", section: 1 }
      })
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "success",
      });
      setOpen(false);
      window.location.replace(`/edit-user/${userID}`);
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
      setOpen(false);
    }
  };
  const handleDelete = async (_id) => {
    if (checkCompPermission("User", ["Remove Role"])) {
      const roleId = {
        roleId: _id,
      };
      const { _msg, _status, data } = await RemoveRolesFromUser(userID, roleId);
      if (_status === 200) {
        setError({
          ...error, roles: { dataType: "string", fieldLabel: "Roles", isError: true, maxLength: 300, maximum: 300, minLength: 1, minimum: 1, msg: "Roles is required", section: 1 }
        })
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        window.location.replace(`/edit-user/${userID}`);
        console.log("skbjjasjsak", userData)
        console.log("ksddbobsd", error)
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        "You do not have permission to remove user. Please contact your Admin",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Users", path: "/users" },
            { title: userData?.name ? `Edit ${userData?.name}` : "Edit " },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          // alignItems="center"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={styles.heading}>Edit {userData?.name}</h2>
          {tabbedView ? (
            <TabbedNavigation
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              isEditMode={false}
              disabled={false}
            />
          )}
        </Grid>
        {/* <div className={styles.border_line} /> */}
      </div>
      <div className={styles.wrapper}>
        {tabbedView ? (<>
          <SectionTabs
            entity={
              userEntitySchema ? userEntitySchema : []
            }
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
          <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
            <div style={{ padding: "0px 0px 0" }}>
              <UserEditEntitySchemaHelper
                section={userEntitySchema?.[activeTab]}
                userData={userData}
                setUserData={setUserData}
                handleChange={handleChange}
                isEditMode={true}
                error={error}
                setError={setError}
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                handleAddRolestoUser={handleAddRolestoUser}
                handleDelete={handleDelete}
                rolesList={rolesList}
                addRoleToUser={addRoleToUser}
                setAddRoleToUser={setAddRoleToUser}
                setRolesList={setRolesList}
                userteamDetails={userteamDetails}
              />
            </div>
          </Paper>
        </>) : (userEntitySchema?.length !== 0 &&
          userEntitySchema?.map((section) => {
            if (section.name === "User_Header_section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <UserEditEntitySchemaHelper
                    section={section}
                    userData={userData}
                    setUserData={setUserData}
                    handleChange={handleChange}
                    isEditMode={true}
                    error={error}
                    setError={setError}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    handleAddRolestoUser={handleAddRolestoUser}
                    handleDelete={handleDelete}
                    rolesList={rolesList}
                    addRoleToUser={addRoleToUser}
                    setAddRoleToUser={setAddRoleToUser}
                    setRolesList={setRolesList}

                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded={true}
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UserEditEntitySchemaHelper
                      section={section}
                      userData={userData}
                      setUserData={setUserData}
                      userteamDetails={userteamDetails}
                      handleChange={handleChange}
                      isEditMode={true}
                      error={error}
                      setError={setError}
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      handleAddRolestoUser={handleAddRolestoUser}
                      handleDelete={handleDelete}
                      rolesList={rolesList}
                      addRoleToUser={addRoleToUser}
                      setAddRoleToUser={setAddRoleToUser}
                      setRolesList={setRolesList}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          }))}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_users_data,
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserScreen);
