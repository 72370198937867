import { ApiHelper } from "./helper/ApiHelper";
import { PROJECTS, MICROSERVICE, LANGUAGES, GLOSSARY, TEAMS } from "./helper/config";

const apiHelper = new ApiHelper();

export class CommonServices {
  getProjectsList(currentPage, filter, sort, size) {
    const queryParam = (currentPage || filter || sort || size) ? ((`&page=${currentPage}`) +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '') ) : '';
    const uri = `${PROJECTS}/api/v1.0.0/projects?size=10${queryParam}`;
    return apiHelper.get(uri);
  }
  getServicesList(currentPage, filter, sort) {
    
    const queryParam = (currentPage || filter || sort) ? ('?' + (`page=${currentPage}`) +
      (filter !== '{}' && filter !== undefined  ? `&filters=${filter}` : '&filters={"ai_task" : {"$ne": true}, "service_category" : {"$ne": "Only GUI"} }') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '') +
      '&size=10') : '';
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices${queryParam}`;
 
    return apiHelper.get(uri);
  }
  getServiceGUIList(currentPage, filter, sort) {
   
    const queryParam = (`page=${currentPage}`) +
      (filter !== '{}' && filter !== undefined  ? `&filters=${filter}` : '&filters={"ai_task" : {"$ne": true}, "service_category" : {"$ne": "Only API"} }') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?${queryParam}&size=10`;
   
    return apiHelper.get(uri);
  }
  getProjectServicesGroupList(currentPage, filter, sort) {
  
    const queryParam = (`page=${currentPage}`) +
      (filter !== '{}' && filter !== undefined  ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?${queryParam}&size=10`;

    return apiHelper.get(uri);
  }
  getServiceGroupListfromProjectID(id, currentPage, filter, sort) {
    const queryParam = (`page=${currentPage}`) +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${PROJECTS}/api/v1.0.0/projects/${id}/servicegroups?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getVersionListForService(serviceID, currentPage) {
    const queryParam = (currentPage ? `?page=${currentPage}` : "");
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceID}/versions${queryParam}`;
    return apiHelper.get(uri);
  }
  getLanguagesList() {
    const uri = `${LANGUAGES}/api/v1.0.0/allowedLanguages`;
    return apiHelper.get(uri);
  }
  getLanguagesByProject(projectId) {
    const uri = `${LANGUAGES}/api/v1.0.0/projects/${projectId}/languages`;
    return apiHelper.get(uri);
  }
  getTeamsByProject(projectId) {
    const uri = `${TEAMS}/api/v1.0.0/projects/${projectId}/teams`;
    return apiHelper.get(uri);
  }
  getFieldGroups() {
    const uri = `${GLOSSARY}/api/v1.0.0/fieldgroups`;
    return apiHelper.get(uri);
  }
  getFieldGroupDetails(fieldGroupID) {
    const uri = `${GLOSSARY}/api/v1.0.0/fieldgroups/${fieldGroupID}`;
    return apiHelper.get(uri)
  }
  getAPIFromServiceIDVersionID(mid, vid) {
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${mid}/versions/${vid}/apis`;
    return apiHelper.get(uri);
  }
  
  getServiceGroupList( currentPage, filter, sort) {
    const queryParam = (`page=${currentPage}`) +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${PROJECTS}/api/v1.0.0/servicegroups?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  addResourceDeployment(resourceData) {
    const uri = `${PROJECTS}/api/container/environments`;
    return apiHelper.post(uri, resourceData)
  }
  getResourceDeployment(resourceType, resourceId) {
    const uri = `${PROJECTS}/api/container/environments?filters={"resoureType":"${resourceType}","resourceId":"${resourceId}"}`;
    return apiHelper.get(uri)
  }
  getServicesListForApplication(filter) {
    const queryParam = filter !== undefined ? `?filters=${filter}` : ''
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices${queryParam}`;
    return apiHelper.get(uri);
  }
}