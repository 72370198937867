import { Grid, TextField, Select, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { Autocomplete } from "@material-ui/lab";
import { addAppSelectData } from "../../../redux/actions/deploymentProcessAction";
import { fetchVersionListData } from "../../../utils/project/microserviceUtils";

const CustomFieldGroupForAppSelection = ({
  appSelectionProcessValid,
  setAppSelectionProcessValid,
  addAppSelectData,
  appSelectionData,
  section,
  appData,
  error,
  setError,
  setAppData,
  setIsLoader,
  projects,
  appNameList,
  setAppNameList,
  selectedAppName,
  setSelectedAppName,
  handleAppNameChange,
  versionData,
  setVersionData,
  selectedVersionTag,
  setSelectedVersionTag,
  hideVersion,
  setHideVersion,
  selectedAppId,
  setSelectedAppId,
  submitData,
  setSubmitData,
  setAppSelect,
}) => {
  console.log("nnioeeneinei", appSelectionData);
  const [deploymentSelected, setDeploymentSelected] = useState(false);
  const [appNameSelected, setAppNameSelected] = useState(false);

  const [appListOpen, setAppListOpen] = useState(false);
  const [serviceGroupOpen, setServiceGroupOpen] = useState(false);

  const closeDropDown = () => {
    setAppListOpen(false);
    setServiceGroupOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  useEffect(() => {
    if (
      appSelectionData.deploymenttype !== "Application" ||
      appSelectionData.deploymenttype !== "UI Application"
    ) {
      const fetchVersionList = async (selectedAppId) => {
        if (
          appSelectionData.deploymenttype !== "Application" ||
          appSelectionData.deploymenttype !== "UI Application"
        ) {
          const { data, _status, _msg } = await fetchVersionListData(
            selectedAppId
          );

          setVersionData(data);
        }
      };
      if (appSelectionData?.deployment_app_name?._id) {
        fetchVersionList(appSelectionData?.deployment_app_name?._id);
      }
    } else if (appSelectionData.deploymenttype === "Application") {
      setHideVersion(true);
    }
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "Please select Deployment Type",
        },
      });
    }

    if (appSelectionData?.deploymenttype === "Application") {
      setHideVersion(true);
      addAppSelectData({
        [event.target.name]: value,
        deployment_app_name: undefined,
        deployment_app_version: undefined,
        versionDetails: undefined,
      });
    }
    setDeploymentSelected(true);
    setAppData({
      [event.target.name]: value,
      deployment_app_type: "",
    });
    setAppSelectionProcessValid(false);
    setSubmitData({ ...submitData, [event.target.name]: value });
    addAppSelectData({
      [event.target.name]: value,
      deployment_app_name: undefined,
      deployment_app_version: undefined,
    });
    setSelectedAppName();
    setAppSelect(appData.deploymenttype);
  };

  const handleVersionChange = (event) => {
    const value = event.target.value;
    const versionUpdate = versionData.find((item) => item.versionTag === value);

    setSelectedVersionTag(value.versionTag);
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    } else {
      setError({
        ...error,
        [event.target.name]: {
          ...props.error[event.target.name],
          isError: true,
          msg: "",
        },
      });
    }
    setError({ ...error, deployment_app_version: { isError: false } });
    addAppSelectData({
      deployment_app_version: value,
      versionDetails: versionUpdate,
    });
    setAppSelectionProcessValid(true);
    setSubmitData({ ...submitData, [event.target.name]: value });
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        if (field.name === "Deployment_App_Name") {
          return (
            <>
              {appSelectionData.deploymenttype && (
                <>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Name <span className={styles.red}>*</span>
                  </label>
                  <Autocomplete
                    open={appListOpen}
                    onOpen={() => setAppListOpen(true)}
                    onClose={() => setAppListOpen(false)}
                    options={appNameList ? appNameList : []}
                    getOptionLabel={(option) => option?.name}
                    value={
                      appSelectionData.deployment_app_name
                        ? appSelectionData.deployment_app_name
                        : ""
                    }
                    className={`${styles.searchInput3} ${
                      error.appname?.isError && styles.error
                    }`}
                    onChange={(e, input) => {
                      if (input) {
                        setError({
                          ...error,
                          appname: { isError: false },
                        });
                        setSelectedAppName(input);
                        setAppData({
                          ...appData,
                          deployment_app_name: input,
                          deployment_app_version: undefined,
                        });

                        addAppSelectData({
                          deployment_app_name: input,
                          deployment_app_version: undefined,
                          versionDetails: undefined,
                        });
                        setSelectedAppId(input._id);
                        setSubmitData({
                          ...submitData,
                          deployment_app_name: input,
                          deployment_app_version: { undefined },
                        });
                        setAppNameSelected(true);
                        if (
                          appSelectionData.deployment_app_type === "Application"
                        ) {
                          setAppSelectionProcessValid(true);
                        }
                      } else {
                        setSelectedAppName({});
                        setSelectedAppId({});

                        setAppData({
                          ...appData,
                          deployment_app_name: "",
                          deployment_app_version: "",
                        });
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setSelectedAppName(newInputValue);
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={
                          appSelectionData?.deploymenttype === "Application"
                            ? "Select Service Bundle"
                            : `Select ${appSelectionData?.deploymenttype}`
                        }
                        value={appData.deployment_app_name}
                      />
                    )}
                  />
                </>
              )}

              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>
                  Please select{" "}
                  {appSelectionData?.deploymenttype === "Application"
                    ? "Service Bundle"
                    : `${appSelectionData?.deploymenttype}`}
                </p>
              )}
            </>
          );
        } else if (field.name === "AITask ServiceGroupid") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Service Group <span className={styles.red}>*</span>
              </label>
              <Autocomplete
                open={serviceGroupOpen}
                onOpen={() => setServiceGroupOpen(true)}
                onClose={() => setServiceGroupOpen(false)}
                options={serviceGroups}
                getOptionLabel={(option) => option.name}
                disabled={!selectedProject?._id ? true : false}
                className={`${styles.searchInput3} ${
                  error.servicegroupID.isError &&
                  !selectedServiceGroup?._id &&
                  styles.error
                }`}
                onChange={(e, input) => {
                  if (input) {
                    setSelectedServiceGroup(input);
                    setServiceData({
                      ...serviceData,
                      servicegroupID: input._id,
                    });
                  } else {
                    setSelectedServiceGroup({});
                    setServiceData({
                      ...serviceData,
                      servicegroupID: "",
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setServiceGroupSearchInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Service Group"
                  />
                )}
              />
              {error.servicegroupID.isError && !selectedServiceGroup._id && (
                <p className={styles.errorMsg}>{"Service Group is Required"}</p>
              )}
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={field.datakey}
                className={`${styles.input} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                value={
                  field.dataType === "date" && serviceData[field.datakey]
                    ? moment
                        .unix(serviceData[field.datakey])
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : serviceData[field.datakey]
                }
                autoComplete="off"
                onChange={handleChange}
              />
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "Dropdown":
        if (field.name === "Deployment_Type") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={
                  appSelectionData?.deploymenttype
                    ? appSelectionData?.deploymenttype
                    : appData[field.datakey]
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === "string"
                  ? JSON.parse(field?.values).map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value === "Application" ? "Service Bundle" : value}
                      </MenuItem>
                    ))
                  : field?.values.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
              </Select>

              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>Please select Deployment Type</p>
              )}
            </>
          );
        } else if (field.name === "Deployment_App_Version" && !hideVersion) {
          return (
            <>
              {appSelectionData.deployment_app_name &&
                !hideVersion &&
                appSelectionData.deploymenttype !== "Application" &&
                appSelectionData.deploymenttype !== "UI Application" && (
                  <>
                    <label htmlFor="" className={styles.fieldHeading}>
                      {field?.fieldLabel}{" "}
                      {field?.required === "TRUE" && (
                        <span className={styles.red}>*</span>
                      )}
                    </label>
                    <Select
                      name={field.datakey}
                      value={
                        appSelectionData?.deployment_app_version
                          ? appSelectionData?.deployment_app_version
                          : ""
                        // appSelectionData?.deployment_app_version?.versionTag ? appSelectionData?.deployment_app_version?.versionTag: selectedVersionTag
                      }
                      onChange={handleVersionChange}
                      className={`${styles.select} ${
                        error.deployment_app_version?.isError && styles.error
                      }`}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {/* {typeof field?.values === "string"
                          ? JSON.parse(field?.values).map((value, index) => (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            ))
                          : field?.values.map((value, index) => (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            ))} */}
                      {versionData?.map((item) => {
                        return (
                          <MenuItem value={item.versionTag} key={item._id}>
                            {item.versionTag}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              {!hideVersion &&
                appSelectionData?.deploymenttype !== "Application" &&
                appSelectionData?.deploymenttype !== "UI Application" &&
                error.deployment_app_version?.isError && (
                  <p className={styles.errorMsg}>
                    Please select {appSelectionData?.deploymenttype} Version
                  </p>
                )}
            </>
          );
        }

      default:
        return <></>;
    }
  };

  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();
  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Deployment_App_Selection_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{
                    marginTop: "0rem",
                    justifyContent: "space-between",
                    width: "100%",
                    display: "flex",
                  }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea

                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = {
  addAppSelectData,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { appSelectionData } = state.deploymentProcessReducer;

  return {
    userInfo,
    appSelectionData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFieldGroupForAppSelection);
