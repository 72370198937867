import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  FormControl,
  IconButton,
} from "@material-ui/core";
import React from "react";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
import { connect, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

const LanguageCreateEntity = ({
  section,
  languageData,
  error,
  handleChange,
  rejectedfile,
  handleUpload,
  icon,
  iconname,
  customdropzoneImage,
  handleDeleteClick,
  imageList,
  setlanguageData,
  tabbedView,
  dockerImage,
  setDockerImage,
  deleteBuildCommand,
}) => {
  const styles = manageStyles();
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  const DockerCommandSection = () => {
    return (
      <>
        <Grid
          container
          spacing={3}
          style={
            tabbedView
              ? { marginLeft: "5px", marginTop: "6px" }
              : { minHeight: "20rem" }
          }
        >
          <Grid item xs={6} sm={6} style={{marginTop: 6,}}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Build Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                onClick={() =>
                  setlanguageData({
                    ...languageData,
                    buildcommands: [...languageData.buildcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {languageData?.buildcommands?.map((buildcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    key={index}
                    name={`${index}_build`}
                    className={styles.input}
                    value={languageData.buildcommands[index]}
                    onChange={(e) =>
                      setlanguageData({
                        ...languageData,
                        buildcommand: [
                          ...languageData.buildcommands,
                          (languageData.buildcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    onClick={() => deleteBuildCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Run Commands
              </label>
              {/* <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() =>
                            setlanguageData({
                                ...languageData,
                                runcommands: [...languageData.runcommands, ""],
                            })
                        }
                        className={styles.actionIcon2}
                    >
                        <AddIcon />
                    </IconButton> */}
              <br />
              <br />
            </Grid>
            <Grid item xs={11} sm={11}>
              <input
                type="text"
                name="runcommands"
                className={styles.input}
                value={languageData.runcommands}
                onChange={(e) =>
                  setlanguageData({
                    ...languageData,
                    runcommands: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const UploadIcon = () => {
    return (
      <div style={{ padding: isTabbedApproach ? "10px 20px" : "" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {!icon ? (
              <div className={styles.documentDropperWrapper}>
                <DropzoneArea
                  Icon={customdropzoneImage}
                  acceptedFiles={["image/*"]}
                  className={styles.documentDropperClass}
                  showPreviews={false}
                  filesLimit={1}
                  dropzoneText="Drag and drop or click to browse here"
                  dropzoneClass="documentDropperClass"
                  dropzoneParagraphClass="documentDropperTextClass"
                  get
                  maxFileSize={30000}
                  showAlerts={false}
                  onDropRejected={rejectedfile}
                  showPreviewsInDropzone={false}
                  onChange={handleUpload}
                />
                <p
                style={{ whiteSpace: "nowrap" , fontWeight: 400}}
                  className={styles.fieldHeading}
                >{`Allowed jpg, jpeg, png or svg of size max 30 KB`}</p>
              </div>
            ) : (
              <div
                className={styles.doc_container}
                style={{ marginTop:20, width: 330 }}
              >
                <div>
                  <Avatar src={icon} alt=" " />
                </div>
                <div>
                  <input
                    className={styles.value}
                    value={iconname}
                    placeholder="Enter Image Name"
                  ></input>
                </div>
                <div
                  style={{
                    marginTop: 10,
                  }}
                  onClick={handleDeleteClick}
                >
                  <img
                    src="/images/deleteLine.svg"
                    height={18}
                    width={18}
                    color="#000000"
                    alt="icon"
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${
                error[field.datakey]?.isError && styles.error
              } ${field.editable === "FALSE" && styles.editMode}`}
              value={languageData[field?.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={languageData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea}  ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        if (field.fieldLabel === "Docker Image") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Docker Image
                <span className={styles.red}>*</span>
              </label>
              <FormControl className={styles.select}>
                <Select
                  native
                  name={field.datakey}
                  onChange={handleChange}
                  value={dockerImage}
                  className={` ${
                    error[field.datakey]?.isError && styles.error
                  }`}
                  style={{ paddingBottom: 5, fontSize: 16 }}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <option value=""></option>
                  <optgroup label="General">
                    {imageList?.length > 0 &&
                      imageList
                        ?.filter((fil) => fil?.state === "build success")
                        .map((item) => {
                          return (
                            <>
                              {item.buildtype === "internal" && (
                                <option key={item._id} value={item._id}>
                                  {item?.imagename} : {item?.imagetag}
                                </option>
                              )}
                            </>
                          );
                        })}
                  </optgroup>
                  <optgroup label="Custom build">
                    {imageList?.length > 0 &&
                      imageList
                        ?.filter((fil) => fil?.state === "build success")
                        .map((item) => {
                          return (
                            <>
                              {item.buildtype === "external" && (
                                <option key={item._id} value={item._id}>
                                  {item?.imagename} : {item?.imagetag}
                                </option>
                              )}
                            </>
                          );
                        })}
                  </optgroup>
                </Select>
                {error[field.datakey]?.isError && (
                  <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                )}
              </FormControl>
            </div>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={
                  languageData[field.datakey] ? languageData[field.datakey] : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                } `}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                languageData[field.datakey] ? languageData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={field.editable === "FALSE"}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === "FALSE" && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  languageData[`${field.datakey}_unit`]
                    ? languageData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={languageData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  languageData[`${field.datakey}_unit`]
                    ? languageData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={languageData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                languageData[field.datakey] ? languageData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} style={{marginTop: 0}} key={fieldgroup._id}>
              {section.name === "Language_Header_section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}

                  style={isTabbedApproach ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Language Icon":
                          return <UploadIcon />;
                        case "Language Docker Details":
                          return DockerCommandSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={isTabbedApproach ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Language Icon":
                                return <UploadIcon />;

                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;

  return {
    tabbedView,
  };
};

export default connect(mapStateToProps)(LanguageCreateEntity);
