import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

import { AppButton } from "../common/Button";
import manageScreenStyles from "../../screens/CommonStyles/manageScreenStyles";
import { addVolumnDeployment } from "../../redux/actions/deploymentProcessAction";
import { connect } from "react-redux";
import { handleFormValidationPro } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "white",
    // flex: 1,
  },
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  line1: {
    fontSize: "14px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    width: "750px",
    height: "400px",
    marginTop: "0.5rem",
  },
  submit: {
    height: 40,
    width: 100,
    borderRadius: 10,
    color: "white",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
}));

function AddVolumnDeploymentModal(props) {
  const {
    isOpen,
    volumn,
    addVolumnDeployment,
    id,
    deployment_type,
    handleCloseVolumn,
    cloudprovider,
  } = props;
  const [volumnData, setVolumnData] = useState();

  const [volumnTypes, setVolumnTypes] = useState();

  const [error, setError] = useState({
    name: {
      dataType: "string",
      fieldLabel: "Name",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    path: {
      dataType: "string",
      fieldLabel: "Mount path",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    filesystemtype: {
      dataType: "string",
      fieldLabel: "File System Type",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    volumeid: {
      dataType: "string",
      fieldLabel: "Identifier",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
    volumetype: {
      dataType: "string",
      fieldLabel: "Volume type",
      isError: false,
      maxLength: 300,
      maximum: 300,
      minLength: 1,
      minimum: 1,
    },
  });
  const classes = useStyles();
  const styles = manageScreenStyles();

  useEffect(() => {
    if (volumn) {
      setVolumnData(volumn[0]);
    } else {
      setVolumnData({
        name: "",
        path: "",
        filesystemtype: "",
        volumeid: "",
        volumetype: "",
      });
      setError({
        name: {
          dataType: "string",
          fieldLabel: "Name",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        path: {
          dataType: "string",
          fieldLabel: "Mount path",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        filesystemtype: {
          dataType: "string",
          fieldLabel: "File system type",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        volumeid: {
          dataType: "string",
          fieldLabel: "Identifier",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
        volumetype: {
          dataType: "string",
          fieldLabel: "Volume type",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setVolumnData({ ...volumnData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (volumnData?.volumetype === "persistentVolumeClaim") {
      let removeError = error;
      delete removeError["filesystemtype"];
      setError(removeError);
    } else {
      setError({
        ...error,
        filesystemtype: {
          dataType: "string",
          fieldLabel: "File System Type",
          isError: false,
          maxLength: 300,
          maximum: 300,
          minLength: 1,
          minimum: 1,
        },
      });
    }
  }, [volumnData]);

  const handleSubmitClick = () => {
    const { formIsValid, errors } = handleFormValidationPro(volumnData, error);
    setError(errors);
    if (formIsValid) {
      let alterData = volumnData;
      if (volumnData?.volumetype === "persistentVolumeClaim") {
        delete alterData["filesystemtype"];
      }

      addVolumnDeployment({
        id: id,
        volumnData: alterData,
        deployment_type: deployment_type,
      });
      handleCloseVolumn();
    }
  };

  useEffect(() => {
    if (cloudprovider === "AWS" || cloudprovider === "Integrum") {
      setVolumnTypes([
        { value: "awsElasticBlockStore", label: "AWS EBS" },
        { value: "persistentVolumeClaim", label: "PVC" },
      ]);
    } else if (cloudprovider === "AZURE") {
      setVolumnTypes([
        { value: "azureDisk", label: "Azure Disk" },
        { value: "persistentVolumeClaim", label: "PVC" },
      ]);
    } else if (cloudprovider === "GCP") {
      setVolumnTypes([
        { value: "gcePersistentDisk", label: "GCE PD" },
        { value: "persistentVolumeClaim", label: "PVC" },
      ]);
    }
  }, [cloudprovider]);

  return (
    <div className={classes.container}>
      <Dialog
        // onEnter={fetchData}
        open={isOpen}
        // scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="md"
      >
        <DialogTitle className={classes.header} id="scroll-dialog-title">
          <p className={classes.heading}>Attach Volume</p>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Name <span className={styles.red}>*</span>
              </label>

              <input
                type="text"
                name="name"
                className={`${styles.input} ${
                  error?.name?.isError && styles.error
                } `}
                autoComplete="off"
                value={volumnData?.name}
                onChange={handleChange}
              />
              {error?.name?.isError && (
                <p className={styles.errorMsg}>{error?.name?.msg}</p>
              )}
            </Grid>

            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Type <span className={styles.red}>*</span>
              </label>

              <Select
                name="volumetype"
                value={volumnData?.volumetype}
                onChange={handleChange}
                className={`${styles.select} ${
                  error?.volumetype?.isError && styles.error
                } `}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {volumnTypes?.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              {error?.volumetype?.isError && (
                <p className={styles.errorMsg}>{error?.volumetype?.msg}</p>
              )}
            </Grid>

            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Mount Path <span className={styles.red}>*</span>
              </label>

              <input
                type="text"
                name="path"
                className={`${styles.input} ${
                  error?.path?.isError && styles.error
                } `}
                autoComplete="off"
                value={volumnData?.path}
                onChange={handleChange}
              />
              {error?.path?.isError && (
                <p className={styles.errorMsg}>{error?.path?.msg}</p>
              )}
            </Grid>

            {volumnData?.volumetype !== "persistentVolumeClaim" && (
              <Grid item xs={6}>
                <label htmlFor="" className={styles.fieldHeading}>
                  File System Type <span className={styles.red}>*</span>
                </label>

                <Select
                  name="filesystemtype"
                  value={volumnData?.filesystemtype}
                  onChange={handleChange}
                  className={`${styles.select} ${
                    error?.filesystemtype?.isError && styles.error
                  } `}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="ext4">ext4</MenuItem>
                </Select>

                {error?.filesystemtype?.isError && (
                  <p className={styles.errorMsg}>
                    {error?.filesystemtype?.msg}
                  </p>
                )}
              </Grid>
            )}

            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Identifier <span className={styles.red}>*</span>
              </label>

              <input
                type="text"
                name="volumeid"
                className={`${styles.input} ${
                  error?.volumeid?.isError && styles.error
                } `}
                autoComplete="off"
                value={volumnData?.volumeid}
                onChange={handleChange}
              />
              {error?.volumeid?.isError && (
                <p className={styles.errorMsg}>{error?.volumeid?.msg}</p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleCloseVolumn}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          {!props.disabled && (
            <AppButton
              onClick={handleSubmitClick}
              color="primary"
              buttonName="Save"
              variant="contained"
              className="btnsmall"
              style={{ margin: "unset" }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addVolumnDeployment,
};

const mapStateToProps = (state) => {
  const { cloudprovider } =
    state.deploymentProcessReducer?.hostingData?.configurationname;
  return {
    cloudprovider,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVolumnDeploymentModal);
