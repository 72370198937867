import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppButton } from "../../../components/common/Button";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { add_users_data } from "../../../redux/actions/userInfoActions";
import { createUser, fetchRoles } from "../../../utils/users/userUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import UserCreateEntitySchemaHelper from "../../../components/common/entitySchema/users/UserCreateEntitySchemaHelper";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";

const CreateUserScreen = (props) => {
  const styles = manageScreenStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [SearchInput, setSearchInput] = useState(null);
  const [clickedRole, setClickedRole] = useState(
    rolesList === null ? [] : rolesList[0]
  );

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/users");
  };

  const { enqueueSnackbar } = useSnackbar();

  // validation
  const [userData, setUserData] = useState({});
  const [error, setError] = useState({});
  const [userEntitySchema, setUserEntitySchema] = useState([]);

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("User", ["Create"]);
  }, []);

  useEffect(() => {
    const fetchProjectEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "User",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setUserEntitySchema(entity);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setUserData({ ...userData, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchProjectEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    if (value.length === 0) {
      if (event.target.name === 'name') {
        setError({
          ...error, [event.target.name]: {
            ...error[event.target.name], isError: true, maxLength: 50, maximum: 300, minLength: 1, minimum: 1, fieldLabel: "User Name", msg: "User Name is required", section: 0, dataType: "string"
          }
        })
      } else if (event.target.name === 'roles') {
        setError({
          ...error, [event.target.name]: {
            ...error[event.target.name], isError: true, maxLength: 300, maximum: 300, minLength: 1, minimum: 1, fieldLabel: "Roles", msg: "Roles is required", section: 1, dataType: "string"
          }
        })
      } else if (event.target.name === 'email') {
        setError({
          ...error, [event.target.name]: {
            ...error[event.target.name], isError: true, maxLength: 300, maximum: 300, minLength: 1, minimum: 1, fieldLabel: "Email", msg: "Email is required", section: 0, dataType: "string"
          }
        })
      } else if (event.target.name === 'contactNo') {
        setError({
          ...error, [event.target.name]: {
            ...error[event.target.name], isError: true, maxLength: 12, maximum: 300, minLength: 1, minimum: 1, fieldLabel: "Contact Number", msg: "Contact Number is required", section: 0, dataType: "string"
          }
        })
      }
    }
    setUserData({
      ...userData,
      [event.target.name]: value,
    });
  };

  // Fetchroles
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (filter) => {
    setLoading(true);
    const { _msg, _status, data } = await fetchRoles(filter);
    if (_status === 200) {
      setLoading(false);
      setRolesList(
        data ? data.map((item) => ({ ...item, checked: false })) : []
      );
    } else {
      setLoading(false);
      enqueueSnackbar(_msg ? _msg : "Failed to fetch roles", {
        variant: "error",
      });
    }
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [SearchInput]);

  const handleSearch = () => {
    if (SearchInput) {
      fetchData(
        JSON.stringify({ name: { $regex: SearchInput, $options: "i" } })
      );
    } else {
      fetchData();
    }
  };

  const handleSubmit = async () => {
    var temp_roles = [];
    rolesList.forEach((item) => {
      if (item.checked) {
        temp_roles.push(item._id);
      }
    });
    if (temp_roles.length === 0) {
      enqueueSnackbar("Please select atleast one role", {
        variant: "error",
      });
    }
    const req_body = {
      ...userData,
      roles: temp_roles,
    };
    const { formIsValid, errors } = handleFormValidationPro(req_body, error);
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status, data } = await createUser(req_body);
      if (_status < 210) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setLoading(false);

        histroy.push("/users");
      } else {
        setLoading(false);
        if (data && data?.length > 0) {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        userEntitySchema ? userEntitySchema : [],
        errors,
        tabbedView
      );
      console.log("jsvjcjsvjsvjsaau", errors);

      setActiveTab(errorInTab);
      setUserEntitySchema(tempEntity);
      setError(errors);
    }
  };
  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Users", path: "/users" },
              { title: "Create User", path: "" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>{"Create User"}</h2>
            {tabbedView ? (
              <TabbedNavigation
                isCreateMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                handleSave={handleSubmit}
                disabled={false}
                handleCancel={handleCancel}
                isEditMode={false}
                isCreateMode={true}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (<>
            <SectionTabs
              entity={
                userEntitySchema ? userEntitySchema : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <UserCreateEntitySchemaHelper
                  section={userEntitySchema?.[activeTab]}
                  userData={userData}
                  error={error}
                  setUserData={setUserData}
                  handleChange={handleChange}
                  rolesList={rolesList}
                  setRolesList={setRolesList}
                  setClickedRole={setClickedRole}
                  clickedRole={clickedRole}
                  handleSearch={handleSearch}
                  SearchInput={SearchInput}
                  setSearchInput={setSearchInput}
                />
              </div>
            </Paper>
          </>) : (<>
            {userEntitySchema.length !== 0 &&
              userEntitySchema.map((section, index) => {
                if (section.name === "User_Header_section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0 " }}>
                      <UserCreateEntitySchemaHelper
                        section={section}
                        userData={userData}
                        error={error}
                        setUserData={setUserData}
                        handleChange={handleChange}
                        rolesList={rolesList}
                        setRolesList={setRolesList}
                        setClickedRole={setClickedRole}
                        clickedRole={clickedRole}
                        handleSearch={handleSearch}
                        SearchInput={SearchInput}
                        setSearchInput={setSearchInput}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Element name={index}>
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <UserCreateEntitySchemaHelper
                            section={section}
                            userData={userData}
                            error={error}
                            setUserData={setUserData}
                            handleChange={handleChange}
                            rolesList={rolesList}
                            setRolesList={setRolesList}
                            setClickedRole={setClickedRole}
                            clickedRole={clickedRole}
                            handleSearch={handleSearch}
                            SearchInput={SearchInput}
                            setSearchInput={setSearchInput}
                          />

                        </AccordionDetails>
                      </Accordion>
                    </Element>
                  );
                }
              })}
          </>)}
          { }
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  add_users_data,
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserScreen);
