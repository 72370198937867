import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import devExpressStyles from "../styles/DevExpressTableStyles";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../stuff/Loader";
import { AppButton } from "../Button";
import AddIcon from "@material-ui/icons/Add";
import ReplayIcon from "@material-ui/icons/Replay";
import moment from "moment";

const DefaultActionCell = ({ ...restProps }) => {
  const histroy = useHistory();
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const handleNavigateToRerun = () => {
    histroy.push(`/test-execute/create/rerun/${restProps?.row?._id}`);
  };

  return isLoader ? (
    <Loader />
  ) : (
    <>
      <div className={styles.actionIcons}>
        <Tooltip title="Rerun" arrow>
          <IconButton
            color="inherit"
            aria-label="edit"
            edge="start"
            className={styles.actionIcon}
            onClick={handleNavigateToRerun}
          >
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};
const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const DurationCell = ({ ...restProps }) => {
  if (restProps?.row?.runduration === "NaN") {
    return "---";
  } else {
    return restProps?.row?.runduration;
  }
};

const Cell = (props) => {
  const history = useHistory();
  const { column } = props;

  const handleNavigateToDetails = () => {
    history.push(`/test-execute/details/${props.row._id}`);
  };

  if (column.name === "ReRun") {
    return (
      <VirtualTable.Cell {...props}>
        <DefaultActionCell {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "runduration") {
    return (
      <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
        <DurationCell {...props} />
      </VirtualTable.Cell>
    );
  } else if (column.name === "createdon" || column.name === "updatedon") {
    return (
      <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
        <DateCell {...props} />
      </VirtualTable.Cell>
    );
  } else {
    return <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />;
  }
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const TestExecutionFilterListTable = (props) => {
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    // if (props.columns.length - hideCols.length <= 5) {
      setHiddenColumnNames(hideCols);
      props.setHidden(hideCols);
    // }
  };

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 260
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "ReRun", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default TestExecutionFilterListTable;
