import { ApiHelper } from './helper/ApiHelper';
import { BASE_URL } from './helper/config';

const apiHelper = new ApiHelper();

export class IssueDetails {
  fetchIssues(currentPage, filter, sort) {
    // const uri = `${BASE_URL}/api/v1.0.0/issuses`;
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${BASE_URL}/api/v1.0.0/issues?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  fetchIssueById(id) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}`;
    return apiHelper.get(uri);
  }

  createIssuue(val) {
    const uri = `${BASE_URL}/api/v1.0.0/issues`;
    return apiHelper.postFormData(uri, val);
  }
  replyIssue(id, val) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}/reply`;
    return apiHelper.post(uri, val);
  }
  fetchProfileImages(obj) {
    const uri = `${BASE_URL}/api/v1.0.0/users/profiles`;
    return apiHelper.post(uri, obj);
  }
  fetchScreenshotById(id, imgName) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}/screenshot/${imgName}`;
    return apiHelper.get(uri);
  }
  addScreenshots(id, val) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}`;
    return apiHelper.put(uri, val);
  }
  changeTicketStatus(id, val) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}/update`;
    return apiHelper.put(uri, val);
  }
  deleteScreenshot(id, name) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}/screenshot/${name}`;
    return apiHelper.delete(uri);
  }
  assignToSuperAdmin(id, req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/issues/${id}/trigger`;
    return apiHelper.get(uri, {
      userdetails: JSON.stringify({ name: req_data }),
    });
  }
}