import { TestExecutionService } from "../../services/testExecutionService";

const execution = new TestExecutionService();

export const fetchHTMLFileById = async (id) => {
  try {
    const res_data = await execution.fetchHTMLFileById(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const deleteExecution = async (ExecutionId) => {
  try {
      const res_data = await execution.deleteExecution(ExecutionId);
      const { _msg, _status, data } = res_data.data;
      return {
          _status,
          data,
          _msg,
      };
  } catch (err) {
      return {
          _msg: err?.response?.data?._msg,
          _status: err?.response?.data?._status,
          data: err?.response?.data?.data,
      };
  }
};

export const fetchTestExecutionById = async (id) => {
  try {
    const res_data = await execution.fetchTestExecutionById(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const TestExecute = async (executeData) => {
  try {
    const res_data = await execution.TestExecute(executeData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const downloadPostManCollection = async (reqData) => {
  try {
    const res_data = await execution.DownloadPostManCollection(reqData);
    return {
      res_data
    };
  } catch (err) {
    return {
      res_data: err?.response?.data
    };
  }
};

export const DownloadPostManCollectionForServiceBundle = async (reqData) => {
  try {
    const res_data = await execution.DownloadPostManCollectionForServiceBundle(reqData);
    return {
      res_data
    };
  } catch (err) {
    return {
      res_data: err?.response?.data
    };
  }
};

