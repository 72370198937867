import { ApiHelper } from "./helper/ApiHelper";
import { DEPLOYMENT, CLUSTER, REPOSITORY, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class DeploymentProcessService {
  getClusterList(configSelected) {
    const uri = `${CLUSTER}/api/v1.0.0/k8s/config/${configSelected}/clusters`;
    return apiHelper.get(uri);
  }
  editDeployment(DeploymentId, payload) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${DeploymentId}`;
    return apiHelper.put(uri, payload);
  }
  getAllDeployment(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  deleteDeployment(DeploymentId) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${DeploymentId}`;
    return apiHelper.delete(uri);
  }

  createDeployment(deploymentData) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments`;
    return apiHelper.post(uri, deploymentData);
  }

  reDeploy(deploymentData, deploymentId, containerName) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${deploymentId}/container`;
    return apiHelper.post(uri, deploymentData);
  }

  additionalDeployment(deploymentData,deploymentId){
   const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${deploymentId}/container/additional`;
   return apiHelper.post(uri,deploymentData);

  }

  getDeploymentById(DeploymentId) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${DeploymentId}`;
    return apiHelper.get(uri);
  }
  deleteDeploymentContainer(DeploymentId, ContainerName) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/deployments/${DeploymentId}/container/${ContainerName}`;
    return apiHelper.delete(uri);
  }

  getAWSRegionList() {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/aws/regions`;
    return apiHelper.get(uri);
  }

  getNodeDetails(configId, cluserName, nodeName) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/config/${configId}/clusters/${cluserName}/nodes/${nodeName}`;
    return apiHelper.get(uri);
  }

  getNodesList(configId, cluserName) {
    const uri = `${DEPLOYMENT}/api/v1.0.0/k8s/config/${configId}/clusters/${cluserName}/nodes`;
    return apiHelper.get(uri);
  }

}
