import { BASE_URL } from "./helper/config";
import { ApiHelper } from "./helper/ApiHelper";
import { version } from "react";
const apiHelper = new ApiHelper();

export class CodeEditorAPIService {
  getFoldersFileTree(serviceId, versionId) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/tree`;
    return apiHelper.get(uri);
  }

  getFileContent(fileContentIds) {
    const uri = `${BASE_URL}/api/repo/repositories/fetchfilecontents`;
    return apiHelper.post(uri, fileContentIds);
  }

  updateFileContent(serviceId, versionId, fileData) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.put(uri, fileData);
  }

  publishVerison(publishContent) {
    const uri = `${BASE_URL}/api/repo/repositories/publishrepositories`;
    return apiHelper.post(uri, publishContent);
  }

  copyVersion(copyVersionContent) {
    const uri = `${BASE_URL}/api/v1.0.0/microservices/version/copy`;
    return apiHelper.post(uri, copyVersionContent);
  }

  deleteDraft(serviceId, versionId) {
    const uri = `${BASE_URL}/api/repo/repositories/draft/microservices/${serviceId}/versions/${versionId}`;
    return apiHelper.delete(uri);
  }

  deleteFolderById(serviceId, versionId, data) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/deletefile`;
    return apiHelper.post(uri, data);
  }

  createFolderbyId(serviceId, versionId, data) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/createfile`;
    return apiHelper.post(uri, data);
  }

  renameFolderById(serviceId, versionId, data) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/renamefile`;
    return apiHelper.post(uri, data);
  }

  buildDraftVersion(serviceId, versionId) {
    const uri = `${BASE_URL}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/builddraft`;
    return apiHelper.get(uri);
  }

  handleRun(serviceId, versionId, contName) {
    const uri = `${BASE_URL}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/Containers/run`;
    return apiHelper.post(uri, contName);
  }
  handleStop(serviceId, versionId) {
    const uri = `${BASE_URL}/api/v1.0.0/microservices/${serviceId}/versions/${versionId}/Containers/stop`;
    return apiHelper.get(uri);
  }

  getContainerLogs(imgId) {
    const uri = `${BASE_URL}/api/container/dockerimages/${imgId}/logs`;
    return apiHelper.get(uri);
  }

  lockFile(serviceId, versionId, filePath) {
   
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/lock`;
    return apiHelper.post(uri, filePath);
  }

  unLockFile(serviceId, versionId, filePath) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/unlock`;
    return apiHelper.post(uri, filePath);
  }

  removeLock(serviceId, versionId) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/unlockfiles`;
    return apiHelper.get(uri);
  }

  handleDownloadFolder(serviceId, versionId) {
    const queryparma = `draft=true`;
    const uri = `${BASE_URL}/api/repo/repositories/microservices/${serviceId}/versions/${versionId}/_download?${queryparma}`;
    return apiHelper.get(uri);
  }

  getFilesByFolder(fileTreeDetails) {
    const uri = `${BASE_URL}/api/repo/repositories/microservices/versions/tree`;
    return apiHelper.post(uri, fileTreeDetails);
  }
}
