import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { fetchTeamListData } from "../../utils/project/teamUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import {
  getWindowDimensions,
  handleFormValidationForDocument,
  handleFormValidationPro,
  initError,
} from "../../utils/common";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getProjectList,
  addNewProjectData,
  updateServiceGroupMode,
} from "../../redux/actions/projectAction";
import CopyServiceModal from "../../components/CopyServiceModal";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import CustomFieldGroupsForNewServiceGroupCreate from "../../components/common/entitySchema/servicegroup/CustomFieldGroupsForNewServiceGroupCreate";
import Loader from "../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../components/Tabbed/TabsCommon";
import SectionTabs from "../../components/Tabbed/SectionTabs";

const AddNewServiceGroup = (props) => {
  const {
    newProjectData,
    addNewProjectData,
    updateServiceGroupMode,
    tabbedView,
  } = props;
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [serviceGroupData, setServiceGroupData] = useState({
    name: "",
    description: "",
    status: "",
    teams: [],
  });
  const [error, setError] = useState({
    name: { isError: false },
    teams: { isError: false },
  });
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(newProjectData?.teams);
  const [clickedTeam, setClickedTeam] = useState(
    teams === null ? [] : teams[0]
  );
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPageServiceGroup, setCurrentPageServiceGroup] = useState(0);
  const [copyServiceModal, setCopyServiceModal] = useState(false);
  const [newServiceListData, setNewServiceListData] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [serviceGroupEntitySchema, setServiceGroupEntitySchema] = useState([]);
  const [teamLoader, setTeamLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("Project", ["Create Service Group"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // console.log('file entity : ', entity_sg_new_create)
    // const sgData = {}
    // entity_sg_new_create.sections.map(section => {
    //     return section.fieldgroups.map(fieldgroup => {
    //         return fieldgroup.fields.map(field => {
    //             if (field.datakey) {
    //                 return sgData[field.datakey] = ""
    //             }
    //         })
    //     })
    // })
    // console.log('sgData : ', sgData)
    // setServiceGroupData(prevData => ({ ...prevData, ...sgData }))
    // const errData = {}
    // entity_sg_new_create.sections.map(section => {
    //     return section.fieldgroups.map(fieldgroup => {
    //         return fieldgroup.fields.map(field => {
    //             if (field.required === "TRUE") {
    //                 return errData[field.datakey] = false
    //             }
    //         })
    //     })
    // })
    // console.log('errData : ', errData)
    // setError(errData)
    const fetchServiceGroupEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "ServiceGroup",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceGroupEntitySchema(entity);
        const sgData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (sgData[field.datakey] = "");
              }
            });
          });
        });

        setServiceGroupData((prevData) => ({ ...prevData, ...sgData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["documentation"];
        delete errData["documentationTable"];

        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "ServiceGroup"
      );
      if (_status === 200) {
        setServiceGroupData({
          ...serviceGroupData,
          status: data?.status.find((s) => s.default === true)?.status,
        });
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchServiceGroupEntitySchema();
    if (checkCompPermission("Team", ["List All"])) {
      fetchTeamsList();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  useEffect(() => {
    // getServiceGroupsListByProject(projectId, currentPageServiceGroup + 1)
  }, [currentPageServiceGroup]);

  const fetchTeamsList = async (filter) => {
    setTeamLoader(true);
    const { _msg, _status, data } = await fetchTeamListData(0, filter);
    if (_status === 200) {
      setTeams(data ? data.map((item) => ({ ...item, checked: false })) : []);
      setTeamLoader(false);
    } else {
      setTeamLoader(false);
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setServiceGroupData({
      ...serviceGroupData,
      [event.target.name]: value,
    });
  };

  const handleTeamCheck = (team) => {
    if (!selectedTeams.find((item) => item._id === team._id)) {
      setSelectedTeams([...selectedTeams, team]);
    } else {
      setSelectedTeams(selectedTeams.filter((item) => item._id !== team._id));
    }
  };


  useEffect(() => {
    setServiceGroupData({
      ...serviceGroupData,
      teams: selectedTeams.map((item) => item._id),
    });
    addNewProjectData({ ...newProjectData, teams: selectedTeams });
  }, [selectedTeams]);

  const handleSelectedTeamDelete = (teamToDelete) => {
    setSelectedTeams(
      selectedTeams.filter((team) => team._id !== teamToDelete._id)
    );
  };

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      fetchTeamsList(
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      fetchTeamsList();
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      serviceGroupData,
      error
    );
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(serviceGroupEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setServiceGroupEntitySchema(tempEntity)

      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      var sgData = {
        ...serviceGroupData,
        id: uuidv4(),
        teams:
          selectedTeams.length > 0 ? selectedTeams.map((item) => item._id) : [],
        sgDocuments: selectedDocuments,
        services: newServiceListData,
        status: serviceGroupData.status,
        createdon: +new Date(),
      };
      if (sgData.teams.length === 0) {
        delete sgData["teams"];
      }
      addNewProjectData({
        newServicegroupsList: [...newProjectData.newServicegroupsList, sgData],
      });
      histroy.push("/projects/add");
      updateServiceGroupMode({ value: null })
    } else {
      setError(errors);
      enqueueSnackbar(
        "There are form field error(s), please correct the input values and submit",
        {
          variant: "error",
        }
      );
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={!tabbedView && styles.main}>
      <form onSubmit={handleSubmit}>
        {!tabbedView && (
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                { title: "Projects", path: "/projects" },
                { title: "Create Project", path: `/projects/add` },
                { title: "Create Service Group" },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>Create Service Group</h2>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={handleSubmit}
              />
            </Grid>
          </div>
        )}

        {tabbedView ? (
          <>
            <Grid container direction="row" justify="space-between">
              <div></div>
              <TabbedNavigation
                handleNext={() => setActiveTab((prev) => prev + 1)}
                handlePrev={() => setActiveTab((prev) => prev - 1)}
                // handleEdit={() => histroy.push(`/projects/edit/${projectId}`)}
                // handleCancel={updateServiceGroupMode({value:null})}
                handleCancel={() => updateServiceGroupMode({ value: null })}
                schemaLength={serviceGroupEntitySchema?.length}
                activeTab={activeTab}
                isCreateMode={true}
                handleSave={handleSubmit}
              />
            </Grid>

            <SectionTabs entity={serviceGroupEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />

            {/* <Tabs
                value={activeTab}
                onChange={handleActiveTab}
                indicatorColor="primary"
                textColor="primary"
                className={styles.documentsListAccessTab}
              >
                {serviceGroupEntitySchema.length !== 0 &&
                  serviceGroupEntitySchema.map((section) => {
                    return <Tab label={<>{section["section Label"]} </>} />;
                  })}
              </Tabs> */}

            <Paper style={{ padding: 20 }}>
              <div style={{ padding: "0px 5px 0" }}>
                <CustomFieldGroupsForNewServiceGroupCreate
                  section={serviceGroupEntitySchema[activeTab]}
                  serviceGroupData={serviceGroupData}
                  error={error}
                  selectedTeams={selectedTeams}
                  teams={teams}
                  clickedTeam={clickedTeam}
                  teamLoader={teamLoader}
                  selectedDocuments={selectedDocuments}
                  teamSearchInput={teamSearchInput}
                  handleTeamCheck={handleTeamCheck}
                  setCopyServiceModal={setCopyServiceModal}
                  newServiceListData={newServiceListData}
                  windowDimensions={windowDimensions}
                  documentsError={documentsError}
                  setDocumentsError={setDocumentsError}
                  handleTeamSearch={handleTeamSearch}
                  setServiceGroupData={setServiceGroupData}
                  setSelectedDocuments={setSelectedDocuments}
                  setTeamSearchInput={setTeamSearchInput}
                  setClickedTeam={setClickedTeam}
                  handleSelectedTeamDelete={handleSelectedTeamDelete}
                  handleChange={handleChange}
                />
              </div>
            </Paper>
          </>
        ) : (
          <>
            <div className={styles.wrapper}>
              {serviceGroupEntitySchema?.length !== 0 &&
                serviceGroupEntitySchema?.map((section) => {
                  if (section.name === "ServiceGroup_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <CustomFieldGroupsForNewServiceGroupCreate
                          section={section}
                          serviceGroupData={serviceGroupData}
                          error={error}
                          setProjectData={setServiceGroupData}
                          handleChange={handleChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupsForNewServiceGroupCreate
                            section={section}
                            serviceGroupData={serviceGroupData}
                            error={error}
                            selectedTeams={selectedTeams}
                            teams={teams}
                            clickedTeam={clickedTeam}
                            teamLoader={teamLoader}
                            selectedDocuments={selectedDocuments}
                            teamSearchInput={teamSearchInput}
                            handleTeamCheck={handleTeamCheck}
                            setCopyServiceModal={setCopyServiceModal}
                            newServiceListData={newServiceListData}
                            windowDimensions={windowDimensions}
                            documentsError={documentsError}
                            setDocumentsError={setDocumentsError}
                            handleTeamSearch={handleTeamSearch}
                            setServiceGroupData={setServiceGroupData}
                            setSelectedDocuments={setSelectedDocuments}
                            setTeamSearchInput={setTeamSearchInput}
                            setClickedTeam={setClickedTeam}
                            handleSelectedTeamDelete={handleSelectedTeamDelete}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </div>
          </>
        )}

        <CopyServiceModal
          copyServiceModal={copyServiceModal}
          setCopyServiceModal={setCopyServiceModal}
          handleSubmit={(data) =>
            setNewServiceListData([...newServiceListData, data])
          }
        />
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addNewProjectData,
  updateServiceGroupMode,
};

const mapStateToProps = (state) => {
  const { newProjectData } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    newProjectData,
    tabbedView,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewServiceGroup);

