/* eslint-disable */
import React from "react";
import { Paper, Grid, Divider, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import moment from "moment";
import { deleteProject } from "../../utils/project/projectUtils";
import { useSnackbar } from "notistack";
import {
  addFavouriteInResource,
  addSubscriptionInResource,
  deleteFavouriteInResource,
  deleteUserSubscriptionInResource,
} from "../../utils/users/userUtils";
import {
  alterFavouriteProjectById,
  alterSubscibeProjectById,
} from "../../redux/actions/projectAction";
import {
  addSingleFavourite,
  addSingleUserSubscription,
  removeSingleFavourite,
  removeSingleUserSubscription,
} from "../../redux/actions/userPersonalAction";
import { connect, useDispatch, useSelector } from "react-redux";
import useCheckPermission from "../common/hooks/useCheckPermission";

const cardStyles = makeStyles((theme) => ({
  singlecard: {
    // margin: 10,
    // height: 171,
    padding: "15px",
    // width: 307,
    borderRadius: 10,
    boxShadow: "0px 3px 6px #3C3C3C1A",
    // border: "1px solid red",
  },
  cardline1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#535353",

    "& h3": {
      font: "normal normal normal 14px/19px Nunito",
      color: "#535353",
      margin: 0,
    },
  },
  projecttitle: {
    marginBottom: 30,
    font: "normal normal 600 16px/22px Nunito",
    color: "#535353",
    cursor: "pointer",
  },
  cardDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #D4D4D4",
    color: "#535353",
    borderRadius: 10,
    padding: 8,
    font: "normal normal normal 12px/16px Nunito",
    cursor: "pointer",

    "& img": {
      height: 20,
    },
  },
  cardicons: {
    display: "flex",
    alignItems: "baseline",
    "& img": {
      marginLeft: 15,
      cursor: "pointer",
    },
  },
  MuiDivider2: {
    width: "2px",
    height: "30px",
  },
}));

const ProjectsCard = (props) => {
  const { userInfo } = props;
  const styles = cardStyles();
  const { row, index } = props;
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer);
  const { checkCompPermission } = useCheckPermission();

  const handleDeleteProject = async () => {
    const { _msg, _status, data } = await deleteProject(row.id);
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(deleteProjectById(row.id));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleAddFavouite = async (resource, resourceinstance) => {
    const favoriteData = {
      resourcetype: resource,
      resourceinstance: resourceinstance,
    };
    const { _msg, _status, data } = await addFavouriteInResource(
      userData.userid,
      favoriteData
    );
    if (_status === 201) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(alterFavouriteProjectById(resourceinstance));
      dispatch(addSingleFavourite(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleDeleteFavouite = async (resource, resourceinstance) => {
    const { _msg, _status, data } = await deleteFavouriteInResource(
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(alterFavouriteProjectById(resourceinstance));
      dispatch(removeSingleFavourite(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleSubscribeResource = async (resource, resourceinstance) => {
    const { _msg, _status, data } = await addSubscriptionInResource(
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(alterSubscibeProjectById(resourceinstance));
      dispatch(addSingleUserSubscription(data));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleRemoveSubscribeResource = async (resource, resourceinstance) => {
    const { _msg, _status, data } = await deleteUserSubscriptionInResource(
      userData.userid,
      resource,
      resourceinstance
    );
    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      dispatch(alterSubscibeProjectById(resourceinstance));
      dispatch(removeSingleUserSubscription(resourceinstance));
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
      <Paper className={styles.singlecard}>
        <div className={styles.cardline1}>
          <Typography
            className={styles.projecttitle}
            onClick={() => {
              if (checkCompPermission("Project", ["Read"])) {
                histroy.push(`/projects/details/${row.id}`);
              } else {
                enqueueSnackbar(
                  `You do not have permission to access Read Project. 
              Please contact your Admin.`,
                  { variant: "error" }
                );
              }
            }}
          >
            {row.name}
          </Typography>
          <div className={styles.cardicons}>
            {row?.isFavourite ? (
              <Tooltip title="Unfavourite" arrow>
                <img
                  src="/images/heartFilled.svg"
                  alt="card"
                  onClick={() => handleDeleteFavouite("project", row.id)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Favourite" arrow>
                <img
                  src="/images/heartLine.svg"
                  alt="card"
                  onClick={() => handleAddFavouite("project", row.id)}
                />
              </Tooltip>
            )}

            {row?.isSubscribe ? (
              <Tooltip title="Unsubscribe" arrow>
                <img
                  src="/images/Bell_Active.svg"
                  alt="card"
                  onClick={() =>
                    handleRemoveSubscribeResource("project", row.id)
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip title="Subscribe" arrow>
                <img
                  src="/images/Bell_Inactive.svg"
                  alt="card"
                  onClick={() => handleSubscribeResource("project", row.id)}
                />
              </Tooltip>
            )}

            <Tooltip title="Edit" arrow>
              <img
                src="/images/editLine.svg"
                alt="card"
                onClick={(e) => {
                  if (checkCompPermission("Project", ["Edit"])) {
                    histroy.push(`/projects/edit/${row.id}`);
                  } else {
                    enqueueSnackbar(
                      `You do not have permission to access Edit Project. 
                  Please contact your Admin.`,
                      { variant: "error" }
                    );
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <img
                src="/images/deleteLine.svg"
                alt="card"
                onClick={() => {
                  if (checkCompPermission("Project", ["Edit"])) {
                    handleDeleteProject();
                  } else {
                    enqueueSnackbar(
                      `You do not have permission to access Delete Project. 
                  Please contact your Admin.`,
                      { variant: "error" }
                    );
                  }
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div
          className={styles.cardDetails}
          onClick={() => histroy.push(`/projects/details/${row.id}`)}
        >
          <div>
            <img src="/images/user.svg" alt="card" />
            <br />
            {row.createdby}
          </div>
          <Divider orientation="vertical" className={styles.MuiDivider2} />
          <div>
            <img src="/images/calender.svg" alt="card" />
            <br />
            {moment
              .unix(row.createdon)
              .tz(userInfo.timeZone)
              .format(
                userInfo.time === "h:mm"
                  ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  : `${userInfo.dateFormat} ${userInfo.time}`
              )}
          </div>
          <Divider orientation="vertical" className={styles.MuiDivider2} />
          <div style={{ color: row?.status?.color }}>
            <div
              style={{
                backgroundColor: row.status?.color,
                height: 15,
                width: 15,
                borderRadius: "50%",
                margin: "auto",
                marginBottom: 5,
              }}
            ></div>
            {/* <img src="/images/progress.svg" alt="card" /> */}
            {/* <br /> */}
            {row?.status?.status}
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(ProjectsCard);
