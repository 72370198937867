const vendorColumns = [
  {
    name: "name",
    title: "Vendor Name",
    route: "/vendor-details",
    for: "vendor",
  },
  { name: "createdon", title: "Created On", for: "vendor" },
  { name: "status", title: "Status", for: "vendor" },
  {
    name: "vendorProject",
    title: "Projects Assigned",
    type: "default",
    for: "vendor",
  },
  { name: "vendorActions", title: "Action", route: "/edit-vendor" },
];

const vendorDefaultColumnWidths = [
  { columnName: "name", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 2, width: 200 },
  { columnName: "status", weightage: 2, width: 200 },
  { columnName: "vendorProject", weightage: 2, width: 200 },
  { columnName: "vendorActions", weightage: 2, width: 200 },
];

const vendorTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "vendorProject", align: "left",sortingEnabled: false },
  { columnName: "vendorActions", align: "center", sortingEnabled: false },
];

const vendorColumnOrder = [
  "name",
  "createdon",
  "status",
  "vendorProject",
  // "vendorActions",
];

const vendorDefaultSorting = [{ columnName: "name", direction: "asc" }];

const vendorColumnHidden = [
  "name",
  "createdon",
  "status",
  "vendorProject",
  "vendorActions",
];

export {
  vendorColumns,
  vendorDefaultColumnWidths,
  vendorTableColumnExtensions,
  vendorColumnOrder,
  vendorDefaultSorting,
  vendorColumnHidden,
};