const serviceColumns = [
  // { name: 'id', title: 'Service ID' },
  {
    name: "name",
    title: "Service Name",
    for: "serviceDocument",
    route: "/documents/service-details",
  },
  { name: "Created By", title: "Created By", for: "serviceDocument" },
  { name: "createdon", title: "Created On", for: "serviceDocument" },
  // { name: "status", title: "Status", for: "serviceDocument" },
  { name: "description", title: "Description", for: "serviceDocument" },
  // { name: 'api', title: 'API', for: 'serviceDocument' },
  {
    name: "action",
    title: "Action",
    route: "/documents/service",
    for: "serviceDocument",
  },
];

const serviceDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: 2.5 },
  { columnName: "Created By", weightage: 2 },
  { columnName: "createdon", weightage: 1.5},
  { columnName: "description", weightage: 1.5 },
  // { columnName: 'api', width: 200 },
  { columnName: "action", weightage: 2.5 },
];

const serviceTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "Created By", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "description", align: "left" },
  // { columnName: 'api', align: 'right' },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const serviceColumnOrder = [
  // 'id',
  "name",
  "Created By",
  "createdon",
  "description",
  // 'api',
  "action",
];

const serviceDefaultSorting = [];

const ServiceRows = [
  {
    id: 12345,
    name: "Super Admin 2",
    builtenvironment:
      "Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types",
    createdBy: "Super Admin 1",
    api: "02/04/2021",
    action: "active",
  },
];

export {
  serviceColumns,
  serviceDefaultColumnWidths,
  serviceTableColumnExtensions,
  serviceColumnOrder,
  serviceDefaultSorting,
  ServiceRows,
};
