import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { handleFormValidationPro, initError } from "../../../utils/common";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { v4 as uuidv4 } from "uuid";
import {
  CreateBoilerPlate,
  CreateBoilerPlateFromGithub,
} from "../../../utils/applicationGovernance/boilerPlateUtils";
import { fetchLanguagesListData } from "../../../utils/applicationGovernance/launguageUtils";
import CreateBoilerplateEntity from "../../../components/common/entitySchema/applicationGovernance/boilerplate/CreateBoilerplateEntity";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const CreateBoilerPlateScreen = (props) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [BoilerPlateData, setBoilerPlateData] = useState({
    buildcommands: [""],
    runcommands: "",
  });
  const [error, setError] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [uploadTab, setUploadTab] = useState("Upload");
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const history = useHistory();

  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };
  const [imageDetails, setImageDetails] = useState({
    imagename: "",
  });

  // Entity
  const [BoilerPlateEntitySchema, setBoilerPlateEntitySchema] = useState();
  const [launguageList, setlaunguageList] = useState([]);

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    history.push("/application-governance");
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Boilerplate", ["Create"]);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const fetchBoilerPlateEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Boilerplate",
        "create"
      );
      if (_status === 200) {
        setBoilerPlateEntitySchema(data);
        const bPlateData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                bPlateData[`${field.datakey}_unit`] = "";
                bPlateData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (bPlateData[field.datakey] = "");
              }
              return {};
            });
          });
        });

        setBoilerPlateData({ ...BoilerPlateData, ...bPlateData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        setError(errData);
        setIsLoader(false);
      } else {
        setIsLoader(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchLanguagesList = async () => {
      const { _msg, _status, data } = await fetchLanguagesListData(0);
      if (_status === 200) {
        setlaunguageList(
          data ? data.filter((item) => item.status === "active") : ""
        );
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchLanguagesList();
    fetchBoilerPlateEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setBoilerPlateData({
      ...BoilerPlateData,
      [event.target.name]: value,
    });
  };

  // ENV
  const handleAddEnv = (id) => {
    setEnvs([...envs, { id: uuidv4(), key: "", value: "" }]);
  };

  const handleEnvChange = (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event.target.value };
      } else {
        return env;
      }
    });
    setEnvs(newEnvs);
  };
  const handleDeleteEnv = (envToDelete) => {
    if (envs.length > 1) {
      setEnvs((envs) =>
        envs.filter((env) => env.id !== envToDelete && envs.length !== 1)
      );
    }
  };

  // TAB
  const handleUploadSourceTab = (event, newValue) => {
    setUploadTab(newValue);
    if (newValue === "URL") {
      let tempErro = { ...error };
      delete tempErro["docker"];
      setError({
        ...tempErro,
        Url: {
          dataType: "string",
          isError: false,
          minLength: 1,
          minimum: 1,
          maxLength: 350,
          maximum: 300,
          msg: "",
          fieldLabel: "Url",
          section: 1,
        },
        token: {
          dataType: "string",
          isError: false,
          minLength: 1,
          minimum: 1,
          maxLength: 350,
          maximum: 300,
          msg: "",
          fieldLabel: "Token",
          section: 1,
        },
      });
    } else {
      let tempError = { ...error };
      delete tempError["Url"];
      delete tempError["token"];
      setError({
        ...tempError,
        docker: {
          dataType: "string",
          isError: false,
          minLength: 1,
          minimum: 1,
          maxLength: 50,
          maximum: 300,
          msg: "",
          fieldLabel: "docker",
          section: 1,
        },
      });
    }
  };

  const handleUpload = (files) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: files ? (files[0] ? files[0].name : "") : "",
      };
    });
    setBoilerPlateData({ ...BoilerPlateData, docker: files[0]?.name });
    setDroppedDocument(files);
    setError({ ...error, droppedDocument: false });
  };

  const handleImageNameChange = (e) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: e.target.value,
      };
    });
  };

  const handleDeleteClick = () => {
    setImageDetails({
      imagename: "",
    });
    setDroppedDocument([]);
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      BoilerPlateData,
      error
    );

    if (formIsValid && droppedDocument.length > 0) {
      setError(droppedDocument.length > 0 && { droppedDocument: false });
      setIsLoader(true);
      var temp_env = [];
      envs.length > 0 &&
        envs.forEach((item) => {
          if (item.key.length !== 0 && item.value.length !== 0) {
            temp_env.push(item);
          }
        });

      var meta = {
        ...BoilerPlateData,
        docker: {
          buildcommands: [
            BoilerPlateData.dockerbuildcommands
              ? BoilerPlateData.dockerbuildcommands
              : "",
          ],
          runcommands: [
            BoilerPlateData.dockerruncommands
              ? BoilerPlateData.dockerruncommands
              : "",
          ],
        },
        environmentVariable: temp_env ? temp_env : "",
      };

      if (
        BoilerPlateData.dockerbuildcommands === "" ||
        BoilerPlateData.dockerruncommands === ""
      ) {
        delete meta["docker"];
      }

      var bPlateData = new FormData();
      bPlateData.append("file", droppedDocument[0]);
      bPlateData.append("meta", JSON.stringify(meta));

      const { _msg, _status, data } = await CreateBoilerPlate(bPlateData);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        setIsLoader(false);
        histroy.push("/application-governance");
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        BoilerPlateEntitySchema ? BoilerPlateEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setBoilerPlateEntitySchema({
        ...BoilerPlateEntitySchema,
        sections: tempEntity,
      });
      setError({ droppedDocument: true });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };

  const handleGitUploadSourceCode = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      BoilerPlateData,
      error
    );
    if (formIsValid) {
      var temp_env = [];
      envs.length > 0 &&
        envs.forEach((item) => {
          if (item.key.length !== 0 && item.value.length !== 0) {
            temp_env.push(item);
          }
        });
      const newSourceCodeData = {
        ...BoilerPlateData,
        docker: {
          buildcommands: [
            BoilerPlateData.dockerbuildcommands
              ? BoilerPlateData.dockerbuildcommands
              : "",
          ],
          runcommands: [
            BoilerPlateData.dockerruncommands
              ? BoilerPlateData.dockerruncommands
              : "",
          ],
        },
        environmentVariable: temp_env ? temp_env : "",
        repositoryDetails: {
          branch: BoilerPlateData.branch,
          Url: BoilerPlateData.Url,
          token: BoilerPlateData.token,
        },
      };
      if (
        BoilerPlateData.dockerbuildcommands === "" ||
        BoilerPlateData.dockerruncommands === ""
      ) {
        delete newSourceCodeData["docker"];
      }
      setIsLoader(true);
      const { _msg, _status, data } = await CreateBoilerPlateFromGithub(
        newSourceCodeData
      );
      if (_status < 202) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
        histroy.push("/application-governance");
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        BoilerPlateEntitySchema ? BoilerPlateEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setBoilerPlateEntitySchema({
        ...BoilerPlateEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
    }
  };
  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Application Governance",
                path: "/application-governance",
              },
              {
                title: "Boiler Plate",
                path: "/application-governance",
              },
              { title: "Create Boiler Plate" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create Boiler Plate</h2>
            {tabbedView ? (
              <TabbedNavigation
                isCreateMode={true}
                activeTab={activeTab}
                handleSave={() =>
                  (uploadTab === "Upload" && handleSubmit()) ||
                  (uploadTab === "URL" && handleGitUploadSourceCode())
                }
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                isCreateMode={true}
                disabled={false}
                handleSave={() =>
                  (uploadTab === "Upload" && handleSubmit()) ||
                  (uploadTab === "URL" && handleGitUploadSourceCode())
                }
                handleCancel={handleCancel}
                isEditMode={false}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={
                  BoilerPlateEntitySchema
                    ? BoilerPlateEntitySchema.sections
                    : []
                }
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <CreateBoilerplateEntity
                    section={BoilerPlateEntitySchema?.sections?.[activeTab]}
                    BoilerPlateData={BoilerPlateData}
                    setBoilerPlateData={setBoilerPlateData}
                    error={error}
                    setError={setError}
                    handleChange={handleChange}
                    handleDeleteClick={handleDeleteClick}
                    customdropzoneImage={customdropzoneImage}
                    // latest
                    handleUpload={handleUpload}
                    uploadTab={uploadTab}
                    setUploadTab={setUploadTab}
                    handleUploadSourceTab={handleUploadSourceTab}
                    droppedDocument={droppedDocument}
                    setDroppedDocument={setDroppedDocument}
                    handleImageNameChange={handleImageNameChange}
                    imageDetails={imageDetails}
                    launguageList={launguageList}
                    envs={envs}
                    handleAddEnv={handleAddEnv}
                    handleEnvChange={handleEnvChange}
                    handleDeleteEnv={handleDeleteEnv}
                    setIsLoader={setIsLoader}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                  />
                </div>
              </Paper>
            </>
          ) : (
            BoilerPlateEntitySchema?.sections &&
            BoilerPlateEntitySchema?.sections.map((section, index) => {
              if (section.name === "BoilerPlate_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <CreateBoilerplateEntity
                      section={section}
                      BoilerPlateData={BoilerPlateData}
                      setBoilerPlateData={setBoilerPlateData}
                      error={error}
                      setError={setError}
                      handleChange={handleChange}
                      handleDeleteClick={handleDeleteClick}
                      customdropzoneImage={customdropzoneImage}
                      // latest
                      handleUpload={handleUpload}
                      uploadTab={uploadTab}
                      setUploadTab={setUploadTab}
                      handleUploadSourceTab={handleUploadSourceTab}
                      droppedDocument={droppedDocument}
                      setDroppedDocument={setDroppedDocument}
                      handleImageNameChange={handleImageNameChange}
                      imageDetails={imageDetails}
                      launguageList={launguageList}
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                    />
                  </div>
                );
              } else {
                return (
                  <Element name={index}>
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                        {section["section Label"]}&nbsp;{section["section Label"] === "Boiler Plate File" 
                          ? <span className={styles.red}>*</span> : ""}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CreateBoilerplateEntity
                          section={section}
                          BoilerPlateData={BoilerPlateData}
                          setBoilerPlateData={setBoilerPlateData}
                          error={error}
                          setError={setError}
                          handleChange={handleChange}
                          handleDeleteClick={handleDeleteClick}
                          customdropzoneImage={customdropzoneImage}
                          handleUpload={handleUpload}
                          // latest
                          uploadTab={uploadTab}
                          setUploadTab={setUploadTab}
                          handleUploadSourceTab={handleUploadSourceTab}
                          droppedDocument={droppedDocument}
                          setDroppedDocument={setDroppedDocument}
                          handleImageNameChange={handleImageNameChange}
                          imageDetails={imageDetails}
                          launguageList={launguageList}
                          envs={envs}
                          handleAddEnv={handleAddEnv}
                          handleEnvChange={handleEnvChange}
                          handleDeleteEnv={handleDeleteEnv}
                          setIsLoader={setIsLoader}
                          selectedLanguage={selectedLanguage}
                          setSelectedLanguage={setSelectedLanguage}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Element>
                );
              }
            })
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBoilerPlateScreen);
