const projectColumns = [
  // { name: "id", title: "Project ID" },
  {
    name: "name",
    title: "Project Name",
    for: "projectDocument",
    route: "/documents/project-details",
  },
  { name: "createdby", title: "Created By", for: "projectDocument" },
  { name: "createdon", title: "Created On", for: "projectDocument" },
  // { name: "status", title: "Status", for: "projectDocument" },
  { name: "description", title: "Description", for: "projectDocument" },
  {
    name: "action",
    title: "Action",
    route: "/documents/project",
    for: "projectDocument",
  },
];

const projectDefaultColumnWidths = [
  // { columnName: "id", width: 250 },
  { columnName: "name", weightage: 2.5 },
  { columnName: "createdby", weightage: 2 },
  { columnName: "createdon", weightage: 1.5 },
  { columnName: "description", weightage: 1.5 },
  { columnName: "action", weightage: 2.5},
];

const projectTableColumnExtensions = [
  // { columnName: "id", align: "left" },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "description", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const projectColumnOrder = [
  // "id",
  "name",
  "createdby",
  "createdon",
  "description",
  "action",
];

const projectDefaultSorting = [];

export {
  projectColumns,
  projectDefaultColumnWidths,
  projectTableColumnExtensions,
  projectColumnOrder,
  projectDefaultSorting,
};
