import { ApiHelper } from "./helper/ApiHelper";
import {
  PROJECTS,
  MICROSERVICE,
  LANGUAGES,
  GLOSSARY,
  TEAMS,
} from "./helper/config";

const apiHelper = new ApiHelper();

export class aiTaskService {
  getAiTaskList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== "{}" && filter !== undefined
        ? `&filters=${filter}`
        : `&filters={"ai_task":${true}}`) +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?${queryParam}&size=10`;

    return apiHelper.get(uri);
  }

  fetchAllAiTaskList(){
    const uri = `${MICROSERVICE}/api/v1.0.0/microservices?filters={"ai_task":${true}, "serviceType": "Internal"}`
    return apiHelper.get(uri);
  }

  getDataModels() {
    const uri = `${MICROSERVICE}/api/repo/datamodels?filters={"state":"active"}`;
    return apiHelper.get(uri);
  }

  getFunctionsList(foldername, path) {
    const uri = `${MICROSERVICE}/api/codegen/inspect`;
    return apiHelper.post(uri, {
      folder_path: path,
      folder_name: foldername,
    });
  }
  //   getServiceGroupListfromProjectID(id, currentPage, filter, sort) {
  //     const queryParam = (`page=${currentPage}`) +
  //       (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
  //       (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
  //     const uri = `${PROJECTS}/api/v1.0.0/projects/${id}/servicegroups?${queryParam}&size=10`;
  //     return apiHelper.get(uri);
  //   }
  //   getVersionListForService(serviceID, currentPage) {
  //     const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${serviceID}/versions?page=${currentPage}`;
  //    
  //     return apiHelper.get(uri);
  //   }
  //   getLanguagesList() {
  //     const uri = `${LANGUAGES}/api/v1.0.0/allowedLanguages`;
  //     return apiHelper.get(uri);
  //   }
  //   getLanguagesByProject(projectId) {
  //     const uri = `${LANGUAGES}/api/v1.0.0/projects/${projectId}/languages`;
  //     return apiHelper.get(uri);
  //   }
  //   getTeamsByProject(projectId) {
  //     const uri = `${TEAMS}/api/v1.0.0/projects/${projectId}/teams`;
  //     return apiHelper.get(uri);
  //   }
  //   getFieldGroups() {
  //     const uri = `${GLOSSARY}/api/v1.0.0/fieldgroups`;
  //     return apiHelper.get(uri);
  //   }
  //   getFieldGroupDetails(fieldGroupID) {
  //     const uri = `${GLOSSARY}/api/v1.0.0/fieldgroups/${fieldGroupID}`;
  //     return apiHelper.get(uri)
  //   }
  //   getAPIFromServiceIDVersionID(mid, vid) {
  //     const uri = `${MICROSERVICE}/api/v1.0.0/microservices/${mid}/versions/${vid}/apis`;
  //     return apiHelper.get(uri);
  //   }
  //   getServiceGroupList(currentPage, filter) {
  //     const queryParam = (`page=${currentPage}`) + (filter !== undefined ? `&filters=${filter}` : '');
  //     const uri = `${PROJECTS}/api/v1.0.0/servicegroups?${queryParam}&size=10`;
  //     return apiHelper.get(uri)
  //   }
  //   addResourceDeployment(resourceData) {
  //     const uri = `${PROJECTS}/api/container/environments`;
  //     return apiHelper.post(uri, resourceData)
  //   }
  //   getResourceDeployment(resourceType, resourceId) {
  //     const uri = `${PROJECTS}/api/container/environments?filters={"resoureType":"${resourceType}","resourceId":"${resourceId}"}`;
  //     return apiHelper.get(uri)
  //   }
  //   getServicesListForApplication(filter) {
  //     const queryParam = filter !== undefined ? `?filters=${filter}` : ''
  //     const uri = `${MICROSERVICE}/api/v1.0.0/microservices${queryParam}`;
  //     return apiHelper.get(uri);
  //   }
}
