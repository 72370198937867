import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";

import FileDropperWithLayout from "../../../components/common/FileDropperWithLayout";
import FileListTable from "./FileListTable";

import { Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { getProjectList } from "../../../redux/actions/projectAction";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../../utils/common";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";

import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import {
  addDataModel,
  uploadChunkStart,
  createLargeDataModel,
  startChunkUpload,
  uploadFileCompleted,
} from "../../../utils/dataModels/dataModelutils";
import CustomFieldGroupsForDataModelCreate from "../../../components/common/entitySchema/datamodels/CustomFieldGroupForDataModelCreate";
import { file } from "@babel/types";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
const chunkSize = 10 * 1024 * 1024; //its 10MB, increase the number measure in mb

const CreateDataModel = (props) => {
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [uploadType, setUploadType] = useState(true);
  const [BoilerPlateData, setBoilerPlateData] = useState({});

  const [droppedDocument, setDroppedDocument] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [dataModelId, setDataModelId] = useState();
  const [myFile, setMyFile] = useState([]);
  const [fileRows, setFileRows] = useState([]);
  const [AIModelFileError, setAIModelFileError] = useState(false);

  const [nameError, setnameError] = useState(false);

  const [fileDetails, setFileDetails] = useState([]);
  const [uploadTab, setUploadTab] = useState("Upload");

  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  ///File Chunk upload states

  const [counter, setCounter] = useState(1);
  const [partno, setPartno] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  // const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  // const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [fileToUploadName, setFileToUploadName] = useState();

  useEffect(() => {
    const fetchServiceEntitySchema = async () => {
      checkScreenPermission("AIModel", ["Create"]);

      const { _msg, _status, data } = await fetchResourceByPermission(
        "AIModel",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setServiceEntitySchema(entity);
        const servData = {};

        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
              if (field.datakey === "upload_type") {
                if (field.value === "Upload") {
                  setUploadType(true);
                } else {
                  setUploadType(false);
                }
              }
            });
          });
        });

        setServiceData((prevData) => ({ ...prevData, ...servData }));

        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        setError({
          ...errData, file: {
            dataType: "object",
            isError: false,
            minLength: 1,
            minimum: 1,
            maxLength: 50,
            maximum: 300,
            msg: "",
            fieldLabel: "files",
            section: 1,
          }
        })
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    fetchServiceEntitySchema();
  }, []);

  const handleImageNameChange = (e) => {
    setFileDetails((prevState) => {
      return {
        ...prevState,
        imagename: e.target.value,
      };
    });
  };

  const handleDeleteClick = (e) => {
    var array = [...fileDetails]; // make a separate copy of the array

    var index = array.indexOf(e.target.value);

    array.splice(index, 1);
    setFileDetails(array);

    setDroppedDocument(fileDetails);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setServiceData({
      ...serviceData,
      [event.target.name]: value,
    });
  };

  // TAB
  const handleUploadSourceTab = (event, newValue) => {
    setUploadTab(newValue);
  };

  useEffect(() => {
    if (selectedDocuments.length === 0) {
      setError({ ...error, file: { ...error.file, isError: true } })

    } else {
      setError({ ...error, file: { ...error.file, isError: false } })
      let myData = serviceData;
      myData["file"] = "dasdas";
      setServiceData(myData);
    }
  }, [selectedDocuments])

  const handleSubmit = async (e) => {
    if (selectedDocuments.length === 0) {
      let myData = serviceData;
      myData["file"] = "dasdas";
      delete myData["file"];
      setServiceData(myData);
    } else {
      let myData = serviceData;
      myData["file"] = "dasdas";
      setServiceData(myData);
    }

    checkCompPermission("AIModel", ["Create"]);
    e.preventDefault();
    const filesToUpload = [];
    if (selectedDocuments.length === 0) {

    }
    // let myData = serviceData;
    // // delete myData["file"]
    // myData["file"]="dasdas";
    // setServiceData(myData);
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);

    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );

    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      let { tempEntity, errorInTab } = TabsCommon(
        serviceEntitySchema ? serviceEntitySchema : [],
        errors,
        tabbedView
      );

      if (selectedDocuments.length === 0) {
        let myEntity = tempEntity;
        myEntity[1] = { ...myEntity[1], isError: true };
        tempEntity = myEntity;
        setAIModelFileError(true)
      } else {
        let myEntity = tempEntity;
        myEntity[1] = { ...myEntity[1], isError: false };
        tempEntity = myEntity;
        setAIModelFileError(false)
      }
      setActiveTab(errorInTab);
      setServiceEntitySchema(tempEntity);

      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
      return;
    }

    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }

    let newServiceData = serviceData;
    delete newServiceData.file;

    var fileformData = new FormData();
    fileformData.append("meta", JSON.stringify(newServiceData));
    selectedDocuments.map((item) => {
      fileformData.append("file", item.file);
    });
    let fileCheckforChunking;
    if (selectedDocuments.length !== 0) {
      let sizearray = [];
      selectedDocuments?.map((item) => {
        sizearray.push(item.file.size);
      });

      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      fileCheckforChunking = sizearray?.reduce(reducer);
    } else {
      const _msg = "Please add AI Model file";
      setAIModelFileError(true)
      enqueueSnackbar(_msg, { variant: "error" });
      return;
    }

    if (parseInt(fileCheckforChunking) < 1024 * 1024 * 200) {
      setIsLoader(true);

      const { _msg, _status, data } = await addDataModel(fileformData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "DataModel",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);
        histroy.push("/aitask-workbench");
      } else {
        setError(errors);
        enqueueSnackbar(_msg, {
          variant: "error",
        });
      }
      if (_msg === "Invalid JSON") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went Wrong", {})
        );
      } else {
        setIsLoader(false);
      }
    }

    /// Chunk File Upload
    else {
      const filesdetails = [];
      let _totalCount;
      let fileToUpload;
      selectedDocuments.map((item) => {
        //each file upload
        const _file = item.file;
        fileToUpload = _file;
        setFileSize(_file.size);
        setFileToUploadName(_file.name);
        _totalCount =
          _file.size % chunkSize == 0
            ? _file.size / chunkSize
            : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount);
        setFileToBeUpload(_file);

        filesdetails.push({ filename: _file.name, chunks: _totalCount });
      });
      const metaForLargeFile = { ...newServiceData, filesdetails };

      const createLargeDataforDataModel = async () => {
        setIsLoader(true);
        const { _msg, _status, data } = await createLargeDataModel(
          metaForLargeFile
        );
        if (_status === 201) {
          const dataModelId1 = data?._id;

          setDataModelId(dataModelId1);
          data?.filesdetails.map((item) => {
            const fileName = item.filename;
            uploadChunkStarter(
              dataModelId1,
              fileName,
              _totalCount,
              fileToUpload
            );
          });
        } else {
          enqueueSnackbar(_msg, { variant: "error" });
          setIsLoader(false);
        }
      };

      createLargeDataforDataModel();
    }
  };

  const uploadChunkStarter = async (
    dataModelId1,
    fileName,
    _totalCount,
    fileToUpload
  ) => {
    const { _status, _msg, data } = await uploadChunkStart(
      dataModelId1,
      fileName
    );

    if (_status === 200) {
      const dataModelId1 = data?._id;

      setDataModelId(dataModelId1);

      fileChunkUpload(dataModelId1, fileName, _totalCount, fileToUpload);
    } else {
      setIsLoader(false);
    }
  };

  const fileChunkUpload = async (
    dataModelId1,
    fileName,
    _totalCount,
    fileToUpload
  ) => {
    setCounter(counter + 1);
    let fileForUploading;
    selectedDocuments.map((item) => {
      fileForUploading = item.file;
    });
    let chunksArr = [];
    let beginingOfTheChunk = 0;
    let endOfTheChunk = chunkSize;
    for (let i = 0; i < _totalCount; i++) {
      var chunk = fileForUploading.slice(beginingOfTheChunk, endOfTheChunk);

      var chunkFormData = new FormData();

      let chunkmeta = {
        dmid: dataModelId1,
        filename: fileName,
        partno: i,
      };
      chunkFormData.append("meta", JSON.stringify(chunkmeta));
      chunkFormData.append("file", chunk);

      const { _msg, _status, data } = await startChunkUpload(chunkFormData);
      if (_status === 202) {
        // setBeginingOfTheChunk(endOfTheChunk);
        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk = endOfTheChunk + chunkSize;
        // setEndOfTheChunk(endOfTheChunk + chunkSize);
      } else if (_status === 400) {
        enqueueSnackbar(_msg, { variant: "error" });
        setIsLoader(false);
        histroy.push("/aitask-workbench");
      } else if (_status === 404) {
        const _msg = "Data Model Deleted";
        enqueueSnackbar(_msg, { variant: "error" });
        setIsLoader(false);
        histroy.push("/aitask-workbench");
        break;
      }
    }

    await uploadhasCompleted(dataModelId1, fileName, fileToUpload);
  };

  const uploadhasCompleted = async (dataModelId1, fileName, fileToUpload) => {
    const { _msg, _status, data } = await uploadFileCompleted(
      dataModelId1,
      fileName
    );
    if (_status === 202) {
      setIsLoader(false);
      histroy.push("/aitask-workbench");
    } else {
      setIsLoader(false);

      histroy.push("/aitask-workbench");
    }
  };

  const handleUpload = (files) => {
    setFileDetails(files);
    setMyFile(files);

    setDroppedDocument(files);
    setnameError(false);
  };

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.push("/aitask-workbench");
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit} enctype="multipart/form-data">
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "AI Model", path: "/aitask-workbench" },
              { title: "Create AI Model" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create AI Model</h2>
            {tabbedView ? (
              <TabbedNavigation
                schemaLength={serviceEntitySchema?.length}
                isCreateMode={true}
                activeTab={activeTab}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                isCreateMode={true}
                isEditMode={false}
                handleSave={handleSubmit}
                handleCancel={handleCancel}
                disabled={false}
              />

            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={serviceEntitySchema ? serviceEntitySchema : []}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
                <div style={{ padding: "0px 0px 0" }}>
                  <CustomFieldGroupsForDataModelCreate
                    section={serviceEntitySchema?.[activeTab]}
                    serviceData={serviceData}
                    setError={setError}
                    error={error}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                    AIModelFileError={AIModelFileError}
                    setAIModelFileError={setAIModelFileError}
                    uploadTab={uploadTab}
                    droppedDocument={droppedDocument}
                    setDroppedDocument={setDroppedDocument}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    setSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    setIsLoader={setIsLoader}
                    handleUpload={handleUpload}
                    fileDetails={fileDetails}
                    handleUploadSourceTab={handleUploadSourceTab}
                    BoilerPlateData={BoilerPlateData}
                    handleImageNameChange={handleImageNameChange}
                    handleDeleteClick={handleDeleteClick}
                  />
                </div>
              </Paper>
            </>
          ) : (
            serviceEntitySchema &&
            serviceEntitySchema.map((section, index) => {
              if (section.name === "Data_Model_Header_Section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <CustomFieldGroupsForDataModelCreate
                      section={section}
                      serviceData={serviceData}
                      documentsError={documentsError}
                      setDocumentsError={setDocumentsError}
                      error={error}
                      AIModelFileError={AIModelFileError}
                      setAIModelFileError={setAIModelFileError}
                      setError={setError}
                      uploadTab={uploadTab}
                      droppedDocument={droppedDocument}
                      setDroppedDocument={setDroppedDocument}
                      setServiceData={setServiceData}
                      handleChange={handleChange}
                      setSelectedDocuments={setSelectedDocuments}
                      selectedDocuments={selectedDocuments}
                      setIsLoader={setIsLoader}
                      handleUpload={handleUpload}
                      fileDetails={fileDetails}
                      handleUploadSourceTab={handleUploadSourceTab}
                      BoilerPlateData={BoilerPlateData}
                      handleImageNameChange={handleImageNameChange}
                      handleDeleteClick={handleDeleteClick}
                    />
                  </div>
                );
              } else {
                return (
                  <Element name={index}>
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                          <span className={styles.red}>*</span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForDataModelCreate
                          section={section}
                          serviceData={serviceData}
                          error={error}
                          uploadTab={uploadTab}
                          AIModelFileError={AIModelFileError}
                          setAIModelFileError={setAIModelFileError}
                          droppedDocument={droppedDocument}
                          setDroppedDocument={setDroppedDocument}
                          setServiceData={setServiceData}
                          handleChange={handleChange}
                          setError={setError}
                          setSelectedDocuments={setSelectedDocuments}
                          selectedDocuments={selectedDocuments}
                          documentsError={documentsError}
                          setDocumentsError={setDocumentsError}
                          setIsLoader={setIsLoader}
                          handleUpload={handleUpload}
                          fileDetails={fileDetails}
                          handleUploadSourceTab={handleUploadSourceTab}
                          BoilerPlateData={BoilerPlateData}
                          handleImageNameChange={handleImageNameChange}
                          handleDeleteClick={handleDeleteClick}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Element>
                );
              }
            })
          )}
          { }
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
};

const mapStateToProps = (state) => {
  const { projectsList } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;
  const { userInfo } = state.userDetails;
  const { data, error, loading, _totalcount } = projectsList;
  return {
    data,
    error,
    loading,
    _totalcount,
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDataModel);
