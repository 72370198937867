import { ApiHelper } from "./helper/ApiHelper";
import { EXECUTION, REPOSITORY, CONTAINER } from "./helper/config";

const apiHelper = new ApiHelper();

export class TestScriptService {
    getAllTestScript(currentPage, filter, sort) {
        const queryParam =
          `page=${currentPage}` +
          (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
          (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
        const uri = `${EXECUTION}/api/v1.0.0/scripts?${queryParam}&size=10`;
        return apiHelper.get(uri);
      }

      deleteScript(ScriptId) {
        const uri = `${EXECUTION}/api/v1.0.0/scripts/${ScriptId}`;
        return apiHelper.delete(uri);
      }
}