import { ApiHelper } from "./helper/ApiHelper";
import { ORG_URL, BASE_URL, ORGANIZATION_CONFIG } from "./helper/config";
const apiHelper = new ApiHelper();

export class organizationServices {

  getOntologyResource() {
    const uri = `${BASE_URL}/api/v1.0.0/ontology/updates`;
    return apiHelper.get(uri);
  }

  updateOntology() {
    const uri = `${BASE_URL}/api/v1.0.0/ontology/resources`;
    return apiHelper.put(uri, []);
  }

  getOrganizationsList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${ORG_URL}/api/v1.0.0/organizations?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getOrganizationDetailsByID(orgID) {
    const uri = `${ORG_URL}/api/v1.0.0/organizations/${orgID}`;
    return apiHelper.get(uri);
  }
  createOrganization(createOrgData) {
    const uri = `${ORG_URL}/api/v1.0.0/organizations`;
    return apiHelper.post(uri, createOrgData);
  }
  editOrganizationByID(orgID, EditOrgData) {
    const uri = `${ORG_URL}/api/v1.0.0/organizations/${orgID}`;
    return apiHelper.put(uri, EditOrgData);
  }
  deleteOrganization(orgID) {
    const uri = `${ORG_URL}/api/v1.0.0/organizations/${orgID}`;
    return apiHelper.delete(uri);
  }
  fetchCurrentORGDetails() {
    const uri = `${BASE_URL}/api/v1.0.0/organization`;
    return apiHelper.get(uri);
  }
  EditCurrentORGDetails(EditOrgData) {
    const uri = `${BASE_URL}/api/v1.0.0/organization`;
    return apiHelper.put(uri, EditOrgData);
  }

  featchAllCloudConfiguration() {
    const uri = `${ORGANIZATION_CONFIG}/api/v1.0.0/cloudconfiguration`;
    return apiHelper.get(uri);
  }

  deleteCloudConfig(cloudConfigId) {
    const uri = `${ORGANIZATION_CONFIG}/api/v1.0.0/cloudconfiguration/delete/${cloudConfigId}`;
    return apiHelper.delete(uri);
  }

  createCloudConfig(configData){
    const uri = `${BASE_URL}/api/v1.0.0/cloudconfiguration/add`;
    return apiHelper.post(uri, configData);
  }

  editCloudConfig(id, configData){
    const uri = `${BASE_URL}/api/v1.0.0/cloudconfiguration/edit/${id}`;
    return apiHelper.put(uri, configData);
  }
}
