import {
  //
  ADD_LAUNGUAGE_DATA,
  LANGUAGE_FILTERS,
  DELETE_LANGUAGE_BY_ID,
  LANGUAGE_SORT,
  BLOCK_UNBLOCK_LANGUAGE,
  CLEAR_LANGUAGE_DATA,
  LANGUAGE_HIDDEN_COULMNS,
  //
  ADD_BOILER_PLATE_DATA,
  BOILERPLATE_FILTERS,
  DELETE_BOILERPLATE_BY_ID,
  BOILERPLATE_SORT,
  CLEAR_BOILER_PLATE_DATA,
  BOILERPLATE_HIDDEN_COULMNS,
  //
  ADD_CHASSIS_TEMPLATE_DATA,
  CHASSIS_TEMPLATE_FILTERS,
  DELETE_CHASSIS_TEMPLATE_BY_ID,
  CHASSIS_TEMPLATE_SORT,
  CLEAR_CHASIS_TEMPLATE,
  CHASSIS_TEMPLATE_HIDDEN_COULMNS,
} from "../actionTypes";
import { launguageServices } from "../../services/launguageServices";
import { boilerPlateServices } from "../../services/boilerPlateServices";
import { chassisTemplateServices } from "../../services/chassisTemplateService";

const launguageService = new launguageServices();
const boilerPlateService = new boilerPlateServices();
const chassisTemplateService = new chassisTemplateServices();

//action object
function add_launguage_list_data(payload) {
  return {
    type: ADD_LAUNGUAGE_DATA,
    payload: payload,
  };
}

export function add_launguage_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_launguage_list_data({
        loading: true,
      })
    );
    launguageService
      .getAllLanguagesList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_launguage_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_launguage_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_launguage_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_language_filters = (languageFilters) => {
  return {
    type: LANGUAGE_FILTERS,
    payload: languageFilters,
  };
};
export const add_language_sort = (languageSort) => {
  return {
    type: LANGUAGE_SORT,
    payload: languageSort,
  };
};

export const delete_language = (LangID) => {
  return {
    type: DELETE_LANGUAGE_BY_ID,
    payload: {
      LangID,
    },
  };
};

export const block_unblock_language = (languageID, status) => {
  return {
    type: BLOCK_UNBLOCK_LANGUAGE,
    payload: {
      languageID,
      status,
    },
  };
};

export const clear_language_data = () => {
  return {
    type: CLEAR_LANGUAGE_DATA,
  };
};
export const add_language_hidden_cols = (languageHiddenCols) => {
  return {
    type: LANGUAGE_HIDDEN_COULMNS,
    payload: languageHiddenCols,
  };
};

function add_boilerPlate_list_data(payload) {
  return {
    type: ADD_BOILER_PLATE_DATA,
    payload: payload,
  };
}

export function add_boilerPlate_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_launguage_list_data({
        loading: true,
      })
    );
    boilerPlateService
      .getAllBoilerPlates(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_boilerPlate_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_boilerPlate_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_boilerPlate_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}
export const add_boilerplate_filters = (boilerPlateFilters) => {
  return {
    type: BOILERPLATE_FILTERS,
    payload: boilerPlateFilters,
  };
};

export const add_boilerplate_sort = (boilerPlateSort) => {
  return {
    type: BOILERPLATE_SORT,
    payload: boilerPlateSort,
  };
};

export const delete_boilerplate = (bPlateID) => {
  return {
    type: DELETE_BOILERPLATE_BY_ID,
    payload: {
      bPlateID,
    },
  };
};

export const clear_boilerPlate_data = () => {
  return {
    type: CLEAR_BOILER_PLATE_DATA,
  };
};

export const add_Boilerplate_hidden_cols = (boilerplateHiddenCols) => {
  return {
    type: BOILERPLATE_HIDDEN_COULMNS,
    payload: boilerplateHiddenCols,
  };
};

function add_chassisTemplate_list_data(payload) {
  return {
    type: ADD_CHASSIS_TEMPLATE_DATA,
    payload: payload,
  };
}

export function add_chassisTemplate_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_chassisTemplate_list_data({
        loading: true,
      })
    );
    chassisTemplateService
      .getAllChassisTemplates(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_chassisTemplate_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_chassisTemplate_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_chassisTemplate_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_chassisTemplate_filters = (TemplateFilters) => {
  return {
    type: CHASSIS_TEMPLATE_FILTERS,
    payload: TemplateFilters,
  };
};
export const add_template_sort = (templateSort) => {
  return {
    type: CHASSIS_TEMPLATE_SORT,
    payload: templateSort,
  };
};
export const delete_chassisTemplate = (TemplateID) => {
  return {
    type: DELETE_CHASSIS_TEMPLATE_BY_ID,
    payload: {
      TemplateID,
    },
  };
};

export const clear_chasis_template_data = () => {
  return {
    type: CLEAR_CHASIS_TEMPLATE,
  };
};
export const add_chassis_template_hidden_cols = (templateHiddenCols) => {
  return {
    type: CHASSIS_TEMPLATE_HIDDEN_COULMNS,
    payload: templateHiddenCols,
  };
};
