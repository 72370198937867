import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Checkbox,
  Paper,
} from "@material-ui/core";
import React from "react";
import createDetailsStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";

const CreateTeamEntity = ({
  section,
  teamData,
  error,
  handleChange,
  vendorData,
  open,
  handleClickOpen,
  handleClose,
  isLoader,
  handleAddMember,
  // handleDelete,
  users,
  userDetails,
}) => {
  const styles = createDetailsStyles();

  // const AddUsers = () => {
  //   return (
  //     <>
  //       <div>
  //         <div className={styles.addTeamMember}>
  //           <div>
  //             <h4>
  //               Add Team member <span className={styles.red}>*</span>
  //             </h4>
  //           </div>
  //           <div>
  //             <div className={styles.addTeamModal} onClick={handleClickOpen}>
  //               <AddIcon />
  //               <span>Add</span>
  //             </div>

  //             <Dialog open={open} onClose={handleClose}>
  //               <DialogTitle
  //                 style={{ padding: "0 25px", backgroundColor: "#F9FAFD" }}
  //               >
  //                 <div className={styles.assignProject}>
  //                   <div>
  //                     <span
  //                       style={{ font: "normal normal 600 14px/19px Nunito" }}
  //                     >
  //                       Add Team member
  //                     </span>
  //                   </div>
  //                 </div>
  //               </DialogTitle>
  //               <DialogContent>
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                   }}
  //                 >
  //                   {isLoader && <CircularProgress color="primary" size={35} />}
  //                 </div>
  //                 <div style={{ height: 400, width: 550 }}>
  //                   <Grid container>
  //                     {userDetails &&
  //                       userDetails.map((member, _id) => (
  //                         <Grid item xs={12} key={_id}>
  //                           <Paper className={styles.selectMemebrModal}>
  //                             <div className={styles.teamWrapper2}>
  //                               <Checkbox
  //                                 color="primary"
  //                                 onChange={(e) => handleAddMember(member)}
  //                               />
  //                               <div className={styles.memberDeatils2}>
  //                                 <Typography className={styles.teamMemberName}>
  //                                   {member.name}
  //                                 </Typography>
  //                                 <Typography
  //                                   className={styles.teamMemberDesgination}
  //                                 >
  //                                   {member.email}
  //                                 </Typography>
  //                               </div>
  //                             </div>
  //                           </Paper>
  //                         </Grid>
  //                       ))}
  //                   </Grid>
  //                 </div>
  //               </DialogContent>

  //               <DialogActions
  //                 style={{
  //                   backgroundColor: "#F9FAFD",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     justifyContent: "space-around",
  //                     width: 250,
  //                   }}
  //                 >
  //                   <Button
  //                     onClick={handleClose}
  //                     className={styles.cancel}
  //                     style={{ textTransform: "capitalize" }}
  //                   >
  //                     Cancel
  //                   </Button>
  //                   <Button
  //                     onClick={handleClose}
  //                     className={styles.submitMember}
  //                     style={{ textTransform: "capitalize" }}
  //                   >
  //                     Add Team members
  //                   </Button>
  //                 </div>
  //               </DialogActions>
  //             </Dialog>
  //           </div>
  //         </div>

  //         <Grid container spacing={3} style={{ marginTop: 10 }}>
  //           {users &&
  //             users.map((item, index) => {
  //               return (
  //                 <Grid item xs={3} sm={3}>
  //                   <div className={styles.teamMemberswrapper}>
  //                     <div>
  //                       <img
  //                         src={
  //                           item.image
  //                             ? item.image
  //                             : "/images/defaultprofilepic.svg"
  //                         }
  //                         alt="profile"
  //                         height="50px"
  //                         width="50px"
  //                         style={{ borderRadius: "50%" }}
  //                       />
  //                     </div>

  //                     <div
  //                       style={{
  //                         marginLeft: 15,
  //                         width: "12rem",
  //                         lineBreak: "anywhere",
  //                       }}
  //                     >
  //                       <Typography
  //                         className={styles.teamMemberName}
  //                         style={{ textAlign: "left" }}
  //                       >
  //                         {item.name}
  //                       </Typography>
  //                       <Typography className={styles.teamMemberDesgination}>
  //                         {item.email}
  //                       </Typography>
  //                     </div>
  //                     {/* <div onClick={() => handleDelete(index)}>
  //                       <img
  //                         src="/images/governance/delete.svg"
  //                         alt=""
  //                         height="20px"
  //                         width="20px"
  //                       />
  //                     </div> */}
  //                   </div>
  //                 </Grid>
  //               );
  //             })}
  //         </Grid>
  //       </div>
  //     </>
  //   );
  // };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${
                error[field.datakey]?.isError && styles.error
              }`}
              value={teamData[field?.datakey]}
              autoComplete="off"
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={teamData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea}  ${
                error[field.datakey]?.isError && styles.error
              }`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "DropDown":
        if (field.fieldLabel === "Team Type") {
          return (
            <>
              <div>
                <label className={styles.fieldHeading}>
                  Team Type <span className={styles.red}>*</span>
                </label>
                <Select
                  name="type"
                  value={teamData[field.datakey]}
                  onChange={handleChange}
                  displayEmpty
                  className={`${styles.select}  ${
                    error.type?.isError && styles.error
                  }`}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="internal">
                    <span
                      style={{
                        height: 14,
                        width: 14,
                        backgroundColor: "#A333C8",
                        borderRadius: 3,
                        paddingRight: 5,
                      }}
                    ></span>
                    <span style={{ paddingLeft: 5 }}>Internal</span>
                  </MenuItem>
                  <MenuItem value="external">
                    <span
                      style={{
                        height: 14,
                        width: 14,
                        backgroundColor: "#00B5AD",
                        borderRadius: 3,
                      }}
                    ></span>
                    <span style={{ paddingLeft: 5 }}>External</span>
                  </MenuItem>
                </Select>
                {error[field.datakey]?.isError && (
                  <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                )}
              </div>
            </>
          );
        } else if (
          field.fieldLabel === "Vendor" &&
          teamData.type === "external"
        ) {
          return (
            <>
              <div>
                <label className={styles.fieldHeading}>
                  Select Vendor <span className={styles.red}>*</span>
                </label>
                <Select
                  name={field.datakey}
                  value={teamData[field.datakey]}
                  onChange={handleChange}
                  displayEmpty
                  className={`${styles.select}  ${
                    error[field.datakey]?.isError && styles.error
                  }`}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {vendorData?.length > 0 &&
                    vendorData?.map((item) => {
                      return <MenuItem value={item._id}>{item.name}</MenuItem>;
                    })}
                </Select>
                {error[field.datakey]?.isError && (
                  <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
                )}
              </div>
            </>
          );
        } else if (field.fieldLabel !== "Vendor") {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={teamData[field.datakey] ? teamData[field.datakey] : ""}
                onChange={handleChange}
                className={`${styles.select} ${
                  error[field.datakey]?.isError && styles.error
                } `}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values?.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )}
            </>
          );
        } else return;
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={teamData[field.datakey] ? teamData[field.datakey] : ""}
              onChange={handleChange}
              className={`${styles.select} ${
                error[field.datakey]?.isError && styles.error
              } `}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {field?.values?.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );

      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={teamData[field.datakey] ? teamData[field.datakey] : null}
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={field.editable === "FALSE"}
                      className={`${styles.input} ${
                        error[field.datakey]?.isError && styles.error
                      } ${field.editable === "FALSE" && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  teamData[`${field.datakey}_unit`]
                    ? teamData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={teamData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  teamData[`${field.datakey}_unit`]
                    ? teamData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                className={`${styles.select} ${
                  error[`${field.datakey}_unit`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${
                  error[`${field.datakey}_value`]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
                value={teamData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={teamData[field.datakey] ? teamData[field.datakey] : ""}
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Team_Header_section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout withoutTextArea
                <Grid container spacing={3} key={fieldgroup._id}>
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        // case "Team Users":
                        //   return <AddUsers />;

                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              // case "Team Users":
                              //   return <AddUsers />;

                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default CreateTeamEntity;
