import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { editElementFromList, addRemoveDebugPoints, replaceElementList } from "../../../redux/actions/orchestrationAction";
import JoinNodeModal from "../JoinNodeModal/JoinNodeModal";
import { deleteNode_ } from "../../../utils/orchestration/orchestrationUtils"
import { DeleteModal } from "../../common/modals/DeleteModal";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: 40,
    width: 186,
    border: "0.1px solid lightgray",
  },
  body: {
    marginLeft: 12,
    lineHeight: "100%",
    width: "7rem",
    alignSelf: 'center'
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Nunito-Regular",
    color: "#000000",
    opacity: "100%",
    alignSelf: "flex-start",
    marginTop: 7,
  },
  icon_container: {
    display: "flex",
    flexDirection: "column",
  },
  doc_icon: {
    height: 12,
    width: 12,
    color: 'inherit',
    opacity: 0.7
    // background: "#A8A8A8 0% 0% no-repeat padding-box",
  },
  deleteIcon: {
    position: "relative",
    top: -22,
    left: -25,
    // border: "1px solid #A8A8A8",
    // backgroundColor: "white",
    // borderRadius: 15,
    height: 20,
    width: 20,
    padding: 2,
  },
  nodehandleGreen: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid #32CD32!important",
    borderRadius: "7.5px!important",
  },
  nodehandleRed: {
    width: "15px!important",
    height: "15px!important",
    background: "transparent!important",
    border: "1px solid red!important",
    borderRadius: "7.5px!important",
  }
});

function JoinNode(props) {
  const [showJoinModal, setJoinModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar()

  const { debug_points, simulate_status, debug_status, templateid, elements } = props;
  const { name, nodeid } = props.data;

  const isNodeDebugPoint = () => debug_points.includes(name);

  const handleEditClick = () => {
    setJoinModal(true)
  }

  const handleJoinSubmit = (obj) => {
    props.editElementFromList(obj);
    setJoinModal(false)
  }

  const handleDebugAddRemove = (flag) => {
    if (flag) {
      enqueueSnackbar("Node Successfully Added as a Debug Point", { variant: "info" })
    } else {
      enqueueSnackbar("Node Successfully Removed from Debug Point", { variant: "info" })
    }
    props.addRemoveDebugPoints({
      id: nodeid,
      add: flag,
      name: name
    })
  }

  const handleDelete = async () => {
    const { _msg, _status } = await deleteNode_(templateid, nodeid);
    if (_status === 200) {
      // eslint-disable-next-line array-callback-return
      var newElementList = elements.filter((item) => {
        if (item.source) {
          if (item.source !== nodeid && item.target !== nodeid) {
            return item
          }
        } else {
          if (item.id !== nodeid) {
            return item
          }
        }
      })
      props.replaceElementList(newElementList)
      enqueueSnackbar(_msg, { variant: "success" })
    } else {
      enqueueSnackbar(_msg, { variant: "error" })
    }
  }

  return (
    <div className={styles.container}>
      {/* <Handle type="target" position="left" style={{ borderRadius: 0 }} /> */}
      <span className={styles.nodeIcon}>
        <img src="/images/Join.png" alt={"join node"} height={38} width={39} />
      </span>
      <div className={styles.body}>
        <text className={styles.heading}>{name ? name : ""}</text>
      </div>
      <div className={styles.icon_container}>
        {!isNodeDebugPoint() && <span onClick={() => handleDebugAddRemove(true)}>
          <img src="/images/debug_empty.png" alt={"debug"} height={9} width={9} />
        </span>}
        {isNodeDebugPoint() && <span onClick={() => handleDebugAddRemove(false)}>
          <img src="/images/debug_filled.png" alt={"debug"} height={9} width={9} />
        </span>}
        {(!simulate_status && !debug_status) && <span className={styles.editIcon}
          onClick={handleEditClick}>
          <img src="/images/edit_2x.png" alt={"edit"} height={14} width={10} />
        </span>}
        {(simulate_status || debug_status) && <span color={"#A8A8A8"}>
          <DescriptionOutlinedIcon className={styles.doc_icon} color="inherit" />
        </span>}
        <span className={styles.deleteIcon}
          onClick={() => setDeleteModal(true)}>
          <img src="/images/deleteLine.svg" height={14} width={14} alt="delete" />
        </span>
      </div>
      <Tooltip title="Target">
        <Handle
          type="target"
          position="top"
          id="a"
          style={{ left: "50%" }}
          className={styles.nodehandleRed}
        />
      </Tooltip>
      <Tooltip title="Target">
        <Handle
          type="target"
          position="left"
          id="b"
          style={{ top: "50%" }}
          className={styles.nodehandleRed}
        />
      </Tooltip>
      <Tooltip title="Source">
        <Handle
          type="source"
          position="bottom"
          id="c"
          style={{ left: "50%" }}
          className={styles.nodehandleGreen}
        />
      </Tooltip>
      <Tooltip title="Source">
        <Handle
          type="source"
          position="right"
          id="d"
          style={{ top: "50%" }}
          className={styles.nodehandleGreen}
        />
      </Tooltip>

      {showJoinModal && <JoinNodeModal edit={true}
        nodeid={nodeid}
        onEditJoinClose={() => setJoinModal(false)}
        onEditJoinSubmit={(obj) => handleJoinSubmit(obj)}
        isOpen={showJoinModal} />}

      {deleteModal && (
        <DeleteModal
          name={name}
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { debug_points, elements, simulate_status, debug_data, process_data } = state.orchestration;
  const { debug_status } = debug_data;
  const { templateid } = process_data;
  return {
    debug_points,
    simulate_status,
    debug_status,
    templateid,
    elements
  }
}

const mapDispatchToProps = { editElementFromList, addRemoveDebugPoints, replaceElementList }

export default connect(mapStateToProps, mapDispatchToProps)(JoinNode)