import React from "react";
import {
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  FormGroup,
  FormControl,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  Table,
  Tooltip,
  IconButton,
  Avatar,
} from "@material-ui/core";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { connect } from "react-redux";
import AddFieldTableSection from "./AddFieldTableSection";
import moment from "moment";
import useCheckPermission from "../../hooks/useCheckPermission";
import { useSnackbar } from "notistack";
import SortableTree from "react-sortable-tree";

const CustomFieldGroupsForGlossaryFieldEdit = ({
  section,
  isEditMode,
  fieldGroupData,
  setFieldGroupData,
  error,
  handleChange,
  userInfo,
  tab,
  handleChangeTab,
  listStyle,
  fieldTable,
  fieldGroupTable,
  setSearchInput,
  handleSearch,
  handleAllFieldCheck,
  isAllFields,
  setIsAllFields,
  handleFieldCheck,
  dataStyles,
  setModalItem,
  setOpenFieldModal,
  isAllFieldGroups,
  handleAllFieldGroupCheck,
  setIsAllFieldGroups,
  handleFieldGroupCheck,
  setOpenFieldGroupModal,
  fieldCount,
  fieldGroupCount,
  fieldTableOrg,
  fieldGroupTableOrg,
  modalItem,
  openFieldModal,
  openFieldGroupModal,
  tabbedView,
  payloadData,
  setPayloadData,
  treeData,
  setTreeData
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Glossary_Field_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView ? {} : { marginTop: "0rem" /*display: "block"*/ }
                  }
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.uiElementType) {
                        case "addFieldTable":
                          return !isEditMode ? (
                            <></>
                          ) : (
                            <AddFieldTableSection
                              styles={styles}
                              tab={tab}
                              handleChangeTab={handleChangeTab}
                              listStyle={listStyle}
                              fieldTable={fieldTable}
                              fieldGroupTable={fieldGroupTable}
                              setSearchInput={setSearchInput}
                              handleSearch={handleSearch}
                              handleAllFieldCheck={handleAllFieldCheck}
                              isAllFields={isAllFields}
                              setIsAllFields={setIsAllFields}
                              handleFieldCheck={handleFieldCheck}
                              dataStyles={dataStyles}
                              setModalItem={setModalItem}
                              setOpenFieldModal={setOpenFieldModal}
                              isAllFieldGroups={isAllFieldGroups}
                              handleAllFieldGroupCheck={
                                handleAllFieldGroupCheck
                              }
                              setIsAllFieldGroups={setIsAllFieldGroups}
                              handleFieldGroupCheck={handleFieldGroupCheck}
                              setOpenFieldGroupModal={setOpenFieldGroupModal}
                              fieldCount={fieldCount}
                              fieldGroupCount={fieldGroupCount}
                              fieldTableOrg={fieldTableOrg}
                              fieldGroupTableOrg={fieldGroupTableOrg}
                              modalItem={modalItem}
                              openFieldModal={openFieldModal}
                              openFieldGroupModal={openFieldGroupModal}
                            />
                          );
                        case "Dropdown":
                          return field.name === "FieldGroup Type" ? (
                            <Grid item xs={3} sm={6} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <div
                                className={`${styles.fieldgroupradiowrapper} ${
                                  styles.toggleRadioBtn
                                } ${
                                  error[field.datakey]?.isError && styles.error
                                }`}
                                style={{
                                  width: "auto",
                                  height: "38px",
                                  alignItems: "center",
                                  paddingTop: "5px",
                                }}
                              >
                                <RadioGroup
                                  name={field.datakey}
                                  value={fieldGroupData[field.datakey]}
                                  onChange={handleChange}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <FormControlLabel
                                    value="object"
                                    control={<Radio color="primary" />}
                                    label="Object"
                                    className={`${
                                      fieldGroupData[field.datakey] === "object"
                                        ? "toggleActive"
                                        : ""
                                    } ${styles.toggleRadioBtn}`}
                                  />
                                  <FormControlLabel
                                    value="array"
                                    control={<Radio color="primary" />}
                                    label="Array"
                                    style={{ marginLeft: "auto" }}
                                    className={`${
                                      fieldGroupData[field.datakey] === "array"
                                        ? "toggleActive"
                                        : ""
                                    }`}
                                  />
                                </RadioGroup>
                              </div>
                              {error[field.datakey]?.isError && (
                                <p
                                  className={styles.errorMsg}
                                  style={{ marginTop: "4px" }}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {field.fieldLabel}
                                  </span>{" "}
                                  is required
                                </p>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          );
                        case "custom":
                          return field.name === "FieldGroup Status" ? (
                            <Grid item xs={3} sm={3}>
                              {/* <Grid container spacing={3}> */}
                              <FormControl>
                                <FormGroup
                                  aria-label="position"
                                  row
                                  style={{
                                    font: "normal normal 600 16px/22px Nunito",
                                    color: "#000 !important",
                                  }}
                                >
                                  <FormControlLabel
                                    className={
                                      listStyle.dataStandardFormControlLabel
                                    }
                                    value="top"
                                    control={
                                      <Switch
                                        disabled={!isEditMode}
                                        checked={
                                          fieldGroupData[field.datakey] ===
                                          "active"
                                        }
                                        onChange={(e) => {
                                          if (
                                            !checkCompPermission(
                                              "GlossaryFieldGroup",
                                              ["Block Or Unblock"]
                                            )
                                          ) {
                                            enqueueSnackbar(
                                              `You do not have permission to access Block Or Unblock in GlossaryFieldGroup. Please contact your Admin.`,
                                              { variant: "error" }
                                            );
                                          } else {
                                            setFieldGroupData({
                                              ...fieldGroupData,
                                              status:
                                                fieldGroupData.status ===
                                                "inactive"
                                                  ? "active"
                                                  : "inactive",
                                            });
                                          }
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label={field?.fieldLabel || "In Use"}
                                    labelPlacement="top"
                                  />
                                </FormGroup>
                              </FormControl>
                              {/* </Grid> */}
                            </Grid>
                          ) : field.name === "FieldGroup AddFieldTable" &&
                            isEditMode ? (
                            <AddFieldTableSection
                              styles={styles}
                              tab={tab}
                              handleChangeTab={handleChangeTab}
                              listStyle={listStyle}
                              fieldTable={fieldTable}
                              fieldGroupTable={fieldGroupTable}
                              setSearchInput={setSearchInput}
                              handleSearch={handleSearch}
                              handleAllFieldCheck={handleAllFieldCheck}
                              isAllFields={isAllFields}
                              setIsAllFields={setIsAllFields}
                              handleFieldCheck={handleFieldCheck}
                              dataStyles={dataStyles}
                              setModalItem={setModalItem}
                              setOpenFieldModal={setOpenFieldModal}
                              isAllFieldGroups={isAllFieldGroups}
                              handleAllFieldGroupCheck={
                                handleAllFieldGroupCheck
                              }
                              setIsAllFieldGroups={setIsAllFieldGroups}
                              handleFieldGroupCheck={handleFieldGroupCheck}
                              setOpenFieldGroupModal={setOpenFieldGroupModal}
                              fieldCount={fieldCount}
                              fieldGroupCount={fieldGroupCount}
                              fieldTableOrg={fieldTableOrg}
                              fieldGroupTableOrg={fieldGroupTableOrg}
                              modalItem={modalItem}
                              openFieldModal={openFieldModal}
                              openFieldGroupModal={openFieldGroupModal}
                              treeData={treeData}
                              setTreeData={setTreeData}
                            />
                          ) : field.name === "FieldGroup Fields" &&
                            !isEditMode ? (
                            <Grid
                              container
                              spacing={3}
                              style={{ padding: "12px", height: "auto" }}
                            >
                              <Grid
                                item
                                xs={6}
                                sm={9}
                                style={{ marginTop: "25px" }}
                              >
                                <label
                                  className={styles.fieldHeading2}
                                  style={{
                                    font: "normal normal 600 16px/22px Nunito",
                                  }}
                                >
                                  {field.fieldLabel ||
                                    "Included Fields and Field Groups"}
                                </label>
                                <Grid item xs={4} sm={6}>
                                  <div
                                    className={styles.creatfieldselectedlist}
                                    style={{
                                      marginBottom: "5px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {fieldCount === 0 &&
                                    fieldGroupCount === 0 ? (
                                      <h3>0 Added</h3>
                                    ) : (
                                      <h4>
                                        {`${fieldCount} Fields and`}{" "}
                                        {`${fieldGroupCount} Payloads`}
                                      </h4>
                                    )}
                                  </div>
                                </Grid>
                                <TableContainer
                                  className={styles.modalTableContainer}
                                  style={{ marginLeft: "0px" }}
                                >
                                  <Table
                                    stickyHeader
                                    className={styles.modalTable}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Description</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {fieldTable
                                        .filter((res) => res.checked === true)
                                        .map((item) => (
                                          <TableRow>
                                            <TableCell>
                                              {item?.name
                                                ? `${item?.name?.substring(
                                                    0,
                                                    20
                                                  )}${
                                                    item?.name?.length > 20
                                                      ? "..."
                                                      : ""
                                                  }`
                                                : ""}
                                            </TableCell>
                                            <TableCell>{item?.type}</TableCell>
                                            <TableCell>
                                              {item?.description
                                                ? `${item?.description?.substring(
                                                    0,
                                                    10
                                                  )}${
                                                    item?.description?.length >
                                                    10
                                                      ? "..."
                                                      : ""
                                                  }`
                                                : ""}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      {fieldGroupTable
                                        .filter((res) => res.checked === true)
                                        .map((item) => (
                                          <TableRow>
                                            <TableCell>
                                              {" "}
                                              {item?.name
                                                ? `${item?.name?.substring(
                                                    0,
                                                    20
                                                  )}${
                                                    item?.name?.length > 20
                                                      ? "..."
                                                      : ""
                                                  }`
                                                : ""}
                                            </TableCell>
                                            <TableCell>{item?.type}</TableCell>
                                            <TableCell>{`${item?.description?.substring(
                                              0,
                                              10
                                            )}${
                                              item?.description?.length > 10
                                                ? "..."
                                                : ""
                                            }`}</TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          );
                        default:
                          return field.name === "FieldGroup Status" ? (
                            <Grid item xs={3} sm={3}>
                              {/* <Grid container spacing={3}> */}
                              <FormControl>
                                <FormGroup
                                  aria-label="position"
                                  row
                                  style={{
                                    font: "normal normal 600 16px/22px Nunito",
                                    color: "#000 !important",
                                  }}
                                >
                                  <FormControlLabel
                                    className={
                                      listStyle.dataStandardFormControlLabel
                                    }
                                    value="top"
                                    control={
                                      <Switch
                                        disabled={!isEditMode}
                                        checked={
                                          fieldGroupData[field.datakey] ===
                                          "active"
                                        }
                                        onChange={(e) => {
                                          if (
                                            !checkCompPermission(
                                              "GlossaryFieldGroup",
                                              ["Block Or Unblock"]
                                            )
                                          ) {
                                            enqueueSnackbar(
                                              `You do not have permission to access Block Or Unblock in GlossaryFieldGroup. Please contact your Admin.`,
                                              { variant: "error" }
                                            );
                                          } else {
                                            setFieldGroupData({
                                              ...fieldGroupData,
                                              status:
                                                fieldGroupData.status ===
                                                "inactive"
                                                  ? "active"
                                                  : "inactive",
                                            });
                                          }
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label={field?.fieldLabel || "In Use"}
                                    labelPlacement="top"
                                  />
                                </FormGroup>
                              </FormControl>
                              {/* </Grid> */}
                            </Grid>
                          ) : (
                            <Grid item xs={3} sm={3} key={field._id}>
                              <label htmlFor="" className={styles.fieldHeading}>
                                {field?.fieldLabel}{" "}
                                {field?.required === "TRUE" && (
                                  <span className={styles.red}>*</span>
                                )}
                              </label>
                              <input
                                name={field.datakey}
                                value={
                                  field.dataType === "date" &&
                                  fieldGroupData[
                                    field.datakey === "loadash"
                                      ? "logshash"
                                      : field.datakey === "Digest"
                                      ? "digest"
                                      : field.datakey
                                  ]
                                    ? moment
                                        .unix(fieldGroupData[field.datakey])
                                        .tz(userInfo.timeZone)
                                        .format(
                                          userInfo.time === "h:mm"
                                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                            : `${userInfo.dateFormat} ${userInfo.time}`
                                        )
                                    : fieldGroupData[
                                        field.datakey === "loadash"
                                          ? "logshash"
                                          : field.datakey === "Digest"
                                          ? "digest"
                                          : field.datakey
                                      ]
                                }
                                onChange={handleChange}
                                placeholder={field.placeholder || " "}
                                className={`${styles.input} ${
                                  !isEditMode && styles.editMode
                                } ${
                                  error[field.datakey]?.isError && styles.error
                                } ${
                                  field.editable === "FALSE" && styles.editMode
                                }`}
                                disabled={
                                  !isEditMode || field.editable === "FALSE"
                                }
                                autoComplete="off"
                                type="text"
                              />
                              {error[field.datakey]?.isError && (
                                <p className={styles.errorMsg}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {field.fieldLabel}
                                  </span>{" "}
                                  is required
                                </p>
                              )}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.uiElementType) {
                              case "addFieldTable":
                                return !isEditMode ? (
                                  <></>
                                ) : (
                                  <AddFieldTableSection
                                    styles={styles}
                                    tab={tab}
                                    handleChangeTab={handleChangeTab}
                                    listStyle={listStyle}
                                    fieldTable={fieldTable}
                                    fieldGroupTable={fieldGroupTable}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleAllFieldCheck={handleAllFieldCheck}
                                    isAllFields={isAllFields}
                                    setIsAllFields={setIsAllFields}
                                    handleFieldCheck={handleFieldCheck}
                                    dataStyles={dataStyles}
                                    setModalItem={setModalItem}
                                    setOpenFieldModal={setOpenFieldModal}
                                    isAllFieldGroups={isAllFieldGroups}
                                    handleAllFieldGroupCheck={
                                      handleAllFieldGroupCheck
                                    }
                                    setIsAllFieldGroups={setIsAllFieldGroups}
                                    handleFieldGroupCheck={
                                      handleFieldGroupCheck
                                    }
                                    setOpenFieldGroupModal={
                                      setOpenFieldGroupModal
                                    }
                                    fieldCount={fieldCount}
                                    fieldGroupCount={fieldGroupCount}
                                    fieldTableOrg={fieldTableOrg}
                                    fieldGroupTableOrg={fieldGroupTableOrg}
                                    modalItem={modalItem}
                                    openFieldModal={openFieldModal}
                                    openFieldGroupModal={openFieldGroupModal}
                                  />
                                );
                              case "Dropdown":
                                return field.name === "FieldGroup Type" ? (
                                  <Grid item xs={3} sm={6} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <div
                                      className={`${
                                        styles.fieldgroupradiowrapper
                                      } ${styles.toggleRadioBtn} ${
                                        error[field.datakey]?.isError &&
                                        styles.error
                                      } ${
                                        field.editable === "FALSE" &&
                                        styles.editMode
                                      } ${!isEditMode && styles.editMode}`}
                                      style={{
                                        width: "auto",
                                        height: "38px",
                                        alignItems: "center",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      <RadioGroup
                                        name={field.datakey}
                                        value={fieldGroupData[field.datakey]}
                                        onChange={handleChange}
                                        className={`${
                                          field.editable === "FALSE" &&
                                          styles.editMode
                                        }`}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          width: "100%",
                                        }}
                                      >
                                        <FormControlLabel
                                          value="object"
                                          control={<Radio color="primary" />}
                                          label="Object"
                                          className={`${
                                            fieldGroupData[field.datakey] ===
                                            "object"
                                              ? "toggleActive"
                                              : ""
                                          } ${styles.toggleRadioBtn}`}
                                        />
                                        <FormControlLabel
                                          value="array"
                                          control={<Radio color="primary" />}
                                          label="Array"
                                          style={{ marginLeft: "auto" }}
                                          className={`${
                                            fieldGroupData[field.datakey] ===
                                            "array"
                                              ? "toggleActive"
                                              : ""
                                          }`}
                                        />
                                      </RadioGroup>
                                    </div>
                                    {error[field.datakey]?.isError && (
                                      <p
                                        className={styles.errorMsg}
                                        style={{ marginTop: "4px" }}
                                      >
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {field.fieldLabel}
                                        </span>{" "}
                                        is required
                                      </p>
                                    )}
                                  </Grid>
                                ) : (
                                  <></>
                                );
                              case "custom":
                                return field.name === "FieldGroup Status" ? (
                                  <Grid item xs={3} sm={3}>
                                    {/* <Grid container spacing={3}> */}
                                    <FormControl>
                                      <FormGroup
                                        aria-label="position"
                                        row
                                        style={{
                                          font: "normal normal 600 16px/22px Nunito",
                                          color: "#000 !important",
                                        }}
                                      >
                                        <FormControlLabel
                                          className={
                                            listStyle.dataStandardFormControlLabel
                                          }
                                          value="top"
                                          control={
                                            <Switch
                                              disabled={!isEditMode}
                                              checked={
                                                fieldGroupData[
                                                  field.datakey
                                                ] === "active"
                                              }
                                              onChange={(e) => {
                                                if (
                                                  !checkCompPermission(
                                                    "GlossaryFieldGroup",
                                                    ["Block Or Unblock"]
                                                  )
                                                ) {
                                                  enqueueSnackbar(
                                                    `You do not have permission to access Block Or Unblock in GlossaryFieldGroup. Please contact your Admin.`,
                                                    { variant: "error" }
                                                  );
                                                } else {
                                                  setFieldGroupData({
                                                    ...fieldGroupData,
                                                    status:
                                                      fieldGroupData.status ===
                                                      "inactive"
                                                        ? "active"
                                                        : "inactive",
                                                  });
                                                }
                                              }}
                                              color="primary"
                                            />
                                          }
                                          label={field?.fieldLabel || "In Use"}
                                          labelPlacement="top"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                    {/* </Grid> */}
                                  </Grid>
                                ) : field.name === "FieldGroup AddFieldTable" &&
                                  isEditMode ? (
                                  <AddFieldTableSection
                                    styles={styles}
                                    tab={tab}
                                    handleChangeTab={handleChangeTab}
                                    listStyle={listStyle}
                                    fieldTable={fieldTable}
                                    fieldGroupTable={fieldGroupTable}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleAllFieldCheck={handleAllFieldCheck}
                                    isAllFields={isAllFields}
                                    setIsAllFields={setIsAllFields}
                                    handleFieldCheck={handleFieldCheck}
                                    dataStyles={dataStyles}
                                    setModalItem={setModalItem}
                                    setOpenFieldModal={setOpenFieldModal}
                                    isAllFieldGroups={isAllFieldGroups}
                                    handleAllFieldGroupCheck={
                                      handleAllFieldGroupCheck
                                    }
                                    setIsAllFieldGroups={setIsAllFieldGroups}
                                    handleFieldGroupCheck={
                                      handleFieldGroupCheck
                                    }
                                    setOpenFieldGroupModal={
                                      setOpenFieldGroupModal
                                    }
                                    fieldCount={fieldCount}
                                    fieldGroupCount={fieldGroupCount}
                                    fieldTableOrg={fieldTableOrg}
                                    fieldGroupTableOrg={fieldGroupTableOrg}
                                    modalItem={modalItem}
                                    openFieldModal={openFieldModal}
                                    openFieldGroupModal={openFieldGroupModal}
                                  />
                                ) : field.name === "FieldGroup Fields" &&
                                  !isEditMode ? (
                                  <Grid
                                    container
                                    spacing={3}
                                    style={{ padding: "12px", height: "auto" }}
                                  >
                                    {console.log(
                                      "sdkfjakscjfdkjfd",
                                      payloadData
                                    )}

                                    <Grid
                                      item
                                      xs={6}
                                      sm={9}
                                      style={{ marginTop: "25px" }}
                                    >
                                      <label
                                        className={styles.fieldHeading2}
                                        style={{
                                          font: "normal normal 600 16px/22px Nunito",
                                        }}
                                      >
                                        {field.fieldLabel ||
                                          "Included Fields and Field Groups"}
                                      </label>
                                      <SortableTree
                                        treeData={payloadData}
                                        onChange={(payloadData) =>
                                          setPayloadData(payloadData)
                                        }
                                        isVirtualized={false}
                                        generateNodeProps={(rowInfo) => ({
                                          title:rowInfo?.parentNode === null ? 'root' : rowInfo?.node?.name,
                                          buttons: [
                                            <Tooltip
                                              title={rowInfo?.node?.type}
                                            >
                                              <IconButton size="small">
                                                <Avatar
                                                  style={{
                                                    width: "22px",
                                                    height: "22px",
                                                    backgroundColor:
                                                      "rgb(77, 208, 225)",
                                                    fontSize: "18px",
                                                    marginTop: 1,
                                                  }}
                                                >
                                                  {rowInfo?.node?.type &&
                                                    rowInfo?.node?.type[0].toUpperCase()}
                                                </Avatar>
                                              </IconButton>
                                            </Tooltip>,
                                          ],
                                        })}
                                      />
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <></>
                                );
                              default:
                                return field.name === "FieldGroup Status" ? (
                                  <Grid item xs={3} sm={3}>
                                    <FormControl>
                                      <FormGroup
                                        aria-label="position"
                                        row
                                        style={{
                                          font: "normal normal 600 16px/22px Nunito",
                                          color: "#000 !important",
                                        }}
                                      >
                                        <FormControlLabel
                                          className={
                                            listStyle.dataStandardFormControlLabel
                                          }
                                          value="top"
                                          control={
                                            <Switch
                                              disabled={!isEditMode}
                                              checked={
                                                fieldGroupData[
                                                  field.datakey
                                                ] === "active"
                                              }
                                              onChange={(e) => {
                                                if (
                                                  !checkCompPermission(
                                                    "GlossaryFieldGroup",
                                                    ["Block Or Unblock"]
                                                  )
                                                ) {
                                                  enqueueSnackbar(
                                                    `You do not have permission to access Block Or Unblock in GlossaryFieldGroup. Please contact your Admin.`,
                                                    { variant: "error" }
                                                  );
                                                } else {
                                                  setFieldGroupData({
                                                    ...fieldGroupData,
                                                    status:
                                                      fieldGroupData.status ===
                                                      "inactive"
                                                        ? "active"
                                                        : "inactive",
                                                  });
                                                }
                                              }}
                                              color="primary"
                                            />
                                          }
                                          label={field?.fieldLabel || "In Use"}
                                          labelPlacement="top"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid>
                                ) : (
                                  <Grid item xs={3} sm={3} key={field._id}>
                                    <label
                                      htmlFor=""
                                      className={styles.fieldHeading}
                                    >
                                      {field?.fieldLabel}{" "}
                                      {field?.required === "TRUE" && (
                                        <span className={styles.red}>*</span>
                                      )}
                                    </label>
                                    <input
                                      name={field.datakey}
                                      value={
                                        field.dataType === "date" &&
                                        fieldGroupData[
                                          field.datakey === "loadash"
                                            ? "logshash"
                                            : field.datakey === "Digest"
                                            ? "digest"
                                            : field.datakey
                                        ]
                                          ? moment
                                              .unix(
                                                fieldGroupData[field.datakey]
                                              )
                                              .tz(userInfo.timeZone)
                                              .format(
                                                userInfo.time === "h:mm"
                                                  ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                                  : `${userInfo.dateFormat} ${userInfo.time}`
                                              )
                                          : fieldGroupData[
                                              field.datakey === "loadash"
                                                ? "logshash"
                                                : field.datakey === "Digest"
                                                ? "digest"
                                                : field.datakey
                                            ]
                                      }
                                      onChange={handleChange}
                                      placeholder={field.placeholder || " "}
                                      className={`${styles.input} ${
                                        !isEditMode && styles.editMode
                                      } ${
                                        error[field.datakey]?.isError &&
                                        styles.error
                                      } ${
                                        field.editable === "FALSE" &&
                                        styles.editMode
                                      }`}
                                      disabled={
                                        !isEditMode ||
                                        field.editable === "FALSE"
                                      }
                                      autoComplete="off"
                                      type="text"
                                    />
                                    {error[field.datakey]?.isError && (
                                      <p className={styles.errorMsg}>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {field.fieldLabel}
                                        </span>{" "}
                                        is required
                                      </p>
                                    )}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                <label
                                  htmlFor=""
                                  className={styles.fieldHeading}
                                >
                                  {field?.fieldLabel}
                                  {field?.required === "TRUE" && (
                                    <span className={styles.red}>*</span>
                                  )}
                                </label>
                                <textarea
                                  name={field.datakey}
                                  value={
                                    field.dataType === "date" &&
                                    fieldGroupData[
                                      field.datakey === "loadash"
                                        ? "logshash"
                                        : field.datakey === "Digest"
                                        ? "digest"
                                        : field.datakey
                                    ]
                                      ? moment
                                          .unix(fieldGroupData[field.datakey])
                                          .tz(userInfo.timeZone)
                                          .format(
                                            userInfo.time === "h:mm"
                                              ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                              : `${userInfo.dateFormat} ${userInfo.time}`
                                          )
                                      : fieldGroupData[
                                          field.datakey === "loadash"
                                            ? "logshash"
                                            : field.datakey === "Digest"
                                            ? "digest"
                                            : field.datakey
                                        ]
                                  }
                                  onChange={handleChange}
                                  placeholder={field.placeholder || " "}
                                  className={`${styles.textarea} ${
                                    !isEditMode && styles.editMode
                                  } ${
                                    error[field.datakey]?.isError &&
                                    styles.error
                                  } ${
                                    field.editable === "FALSE" &&
                                    styles.editMode
                                  }`}
                                  disabled={
                                    !isEditMode || field.editable === "FALSE"
                                  }
                                  rows="7"
                                />
                                {error[field.datakey]?.isError && (
                                  <p className={styles.errorMsg}>
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {field.fieldLabel}
                                    </span>{" "}
                                    is required
                                  </p>
                                )}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForGlossaryFieldEdit);
