import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  editComponent,
  editPage,
  editUiApplication,
} from "../../redux/actions/uiApplicationAction";
import { useSnackbar } from "notistack";
import { UiApplicationService } from "../services/UiApplicationService";
import {
  simulateAPIForUI,
  simutateAPI,
} from "../../utils/project/microserviceUtils";
import { apiResponse } from "../components/molecules/action/data";
import useGetData from "./useGetData";
import { getJSONObjFromKeyValueArray } from "../../utils/orchestration/orchestrationUtils";
import { debugSimulateTemplate } from "../../utils/orchestration/orchestrationUtils";
import { editAction } from "../../redux/actions/uiApplicationAction";

const useTriggers = () => {
  const dispatch = useDispatch();
  const { currentProject, currentPage } = useGetData();
  const params = useParams();
  const snackbar = useSnackbar();

  const apiService = new UiApplicationService();
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleTrigger = async ({ action, data }) => {
    const selectedAction = currentProject?.actions?.find(
      (item) => item?._id === action
    );
    if (selectedAction?.actionType === "service") {
      runServiceSimulation(selectedAction, data);
    } else if (selectedAction?.actionType === "application") {
      runApplicationSimulation(selectedAction, data);
    }
  };
  const getComponentDataValue = (item) => {
    if (item?.value && item?.value.includes("ui.")) {
      // take the center i.e input name with split property
      var spitValue = item?.value.split(".");
      // map the selected component with name
      var tempValue = currentProject?.pages?.map((page) => {
        return page?.components?.map((component) => {
          if (component?.name === spitValue[1]) {
            return component?.data?.dataValue;
          } else return null;
        });
      });
      // filter
      var temp2Value = tempValue.map((fil) =>
        fil.filter((item) => item !== null)
      );
      var FinalValue = "";
      FinalValue = temp2Value.filter((fil) => fil.length > 0);
      var StringValue = FinalValue && FinalValue.toString();
    }
    return StringValue;
  };

  // for application simulation
  const runApplicationSimulation = async (
    selectedAction,
    compData,
    identifier
  ) => {
    const serviceType = selectedAction?.process?.servicetype;
    if (serviceType === "rest") {
      var req_data = {};
      let pathParamsObj = {};
      let headerObj = {};
      let inputObj = {};
      let queryParamsObj = {};
      if (identifier?.length && identifier === "topbar") {
        headerObj = getJSONObjFromKeyValueArray(
          selectedAction?.applicationConfig?.headers
        );
      } else {
        headerObj = selectedAction?.applicationConfig?.headers.map((item) => {
          const StringValue = getComponentDataValue(item);
          return {
            ...item,
            value: StringValue ? StringValue : data?.[item.key],
          };
        });
        headerObj = getJSONObjFromKeyValueArray(headerObj);
      }
      if (identifier?.length && identifier === "topbar") {
        inputObj = getJSONObjFromKeyValueArray(
          selectedAction?.applicationConfig?.body
        );
      } else {
        inputObj = {...compData}
          // inputObj = selectedAction?.applicationConfig?.body.map((item) => {
          //   const StringValue = getComponentDataValue(item);
          //   return {
          //     ...item,
          //     value: StringValue ? StringValue : data?.[item.key],
          //   };
          // });
          // inputObj = getJSONObjFromKeyValueArray(inputObj);
      }
      //
      if (identifier?.length && identifier === "topbar") {
        pathParamsObj = getJSONObjFromKeyValueArray(
          selectedAction?.applicationConfig?.pathParams
        );
      } else {
        pathParamsObj = selectedAction?.applicationConfig?.pathParams.map(
          (item) => {
            const StringValue = getComponentDataValue(item);
            return {
              ...item,
              value: StringValue ? StringValue : data?.[item.key],
            };
          }
        );
        pathParamsObj = getJSONObjFromKeyValueArray(pathParamsObj);
      }
      // queryParams
      if (identifier?.length && identifier === "topbar") {
        queryParamsObj = getJSONObjFromKeyValueArray(
          selectedAction?.applicationConfig?.queryParams
        );
      } else {
        queryParamsObj = selectedAction?.applicationConfig?.queryParams.map(
          (item) => {
            const StringValue = getComponentDataValue(item);
            return {
              ...item,
              value: StringValue ? StringValue : data?.[item.key],
            };
          }
        );
        queryParamsObj = getJSONObjFromKeyValueArray(queryParamsObj);
      }
      headerObj["Content-type"] = "application/json";
      try {
        // For application
        setIsLoader(true);
        req_data = {
          applicationid: selectedAction?.application?.applicationId,
          templateid: selectedAction?.process?.templateid,
          input: {
            queryParams: queryParamsObj,
            headers: headerObj,
            payload: inputObj,
            pathParams: pathParamsObj,
          },
        };
        var { _msg, _status, data } = await debugSimulateTemplate(req_data);
        dispatch(
          editAction({
            id: selectedAction?._id,
            data: { responseData: data },
          })
        );
        if (_status === 200) {
          var finalData = {};
          if (data) {
            const { Status, StatusCode, body } = data;
            const template_body = StatusCode === 200 ? JSON.parse(body) : {};
            if (
              Object.keys(template_body).length > 0 &&
              template_body["instanceid"]
            ) {
              // Object.keys(template_body?.data?.Output).forEach((item) => {
              //   if (template_body?.data?.Output?.[item]?.result?.response) {
              //     finalData =
              //       template_body?.data?.Output?.[item]?.result?.response;
              //   }
              // });
              finalData = template_body?.data?.Output?.result?.output?.result?.response;
              dispatch(
                editAction({
                  id: selectedAction?._id,
                  data: { data: finalData },
                })
              );
              const pages = currentProject.pages.map((page) => {
                return {
                  ...page,
                  components: page.components.map((comp) => {
                    let curlyRegex = /\{\{([^)]+)\}\}/;
                    let finalInput = curlyRegex.exec(comp?.data?.dataInput);
                    let inputs = finalInput && finalInput[1].split(".");
                    if (
                      inputs &&
                      inputs.length &&
                      inputs?.[1] === selectedAction?.name
                    ) {
                      let updatedInputs = inputs.slice(3);
                      let updatedData = finalData;
                      updatedInputs?.forEach((item) => {
                        updatedData = updatedData?.[item];
                      });
                      return {
                        ...comp,
                        data: {
                          ...comp.data,
                          dataValue: updatedData
                            ? updatedData
                            : comp?.data?.dataValue,
                        },
                      };
                    } else {
                      return comp;
                    }
                  }),
                };
              });
              dispatch(editUiApplication({ data: { pages: pages } }));
            }
          }
          setIsLoader(false);
        } else {
          setIsLoader(false);
          enqueueSnackbar(_msg? _msg: "Unable to process your request, please try after sometime",            { variant: "error" });
        }
      } catch (err) {
        setIsLoader(false);
        enqueueSnackbar("Response data not in proper JSON format! Please check.",{variant: "error"});
      }
    } else {
      setIsLoader(true);
      req_data = {
        applicationid: selectedAction?.application?.applicationId,
        templateid: selectedAction?.process?.templateid,
        topic: selectedAction?.applicationConfig?.topic,
        message: selectedAction?.applicationConfig?.message,
        servicetype: serviceType,
      };
      const { _msg, _status } = await debugSimulateTemplate(req_data);
      if (_status === 200) {
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
    return isLoader;
  };

  // for service simulation
  const runServiceSimulation = async (selectedAction, data, identifier) => {
    const serviceType = selectedAction?.api?.type;  
    let finalData = [];
    if (serviceType === "rest") {
      var req_data = {};
      let pathParamsObj = {};
      let headerObj = {};
      let inputObj = data;
      let queryParamsObj = {};
      if (identifier?.length && identifier === "topbar") {
        headerObj = getJSONObjFromKeyValueArray(
          selectedAction?.config?.headers
        );
      } else {
        headerObj = selectedAction?.config?.headers.map((item) => {
          const StringValue = getComponentDataValue(item);
          return {
            ...item,
            value: StringValue ? StringValue : data?.[item.key],
          };
        });
        headerObj = getJSONObjFromKeyValueArray(headerObj);
      }
      if (identifier?.length && identifier === "topbar") {
        inputObj = getJSONObjFromKeyValueArray(selectedAction?.config?.body);
      } else {
        inputObj = {...data}
        // inputObj = selectedAction?.config?.body.map((item) => {
        //   const StringValue = getComponentDataValue(item);
        //   return {
        //     ...item,
        //     value: StringValue ? StringValue : data?.[item.key],
        //   };
        // });
        // inputObj = getJSONObjFromKeyValueArray(inputObj);
      }
      // pathParamsObj
      if (identifier?.length && identifier === "topbar") {
        pathParamsObj = getJSONObjFromKeyValueArray(
          selectedAction?.config?.pathParams
        );
      } else {
        pathParamsObj = selectedAction?.config?.pathParams.map((item) => {
          const StringValue = getComponentDataValue(item);
          return {
            ...item,
            value: StringValue ? StringValue : data?.[item.key],
          };
        });
        pathParamsObj = getJSONObjFromKeyValueArray(pathParamsObj);
      }
      // queryParams
      if (identifier?.length && identifier === "topbar") {
        queryParamsObj = getJSONObjFromKeyValueArray(
          selectedAction?.config?.queryParams
        );
      } else {
        queryParamsObj = selectedAction?.config?.queryParams?.map((item) => {
          const StringValue = getComponentDataValue(item);
          return {
            ...item,
            value: StringValue ? StringValue : data?.[item.key],
          };
        });
        queryParamsObj = getJSONObjFromKeyValueArray(queryParamsObj);
      }
      headerObj["Content-type"] = "application/json";
      try {
        setIsLoader(true);
        // For microservice
        req_data = {
          mid: selectedAction?.service?.serviceID,
          vid: selectedAction?.service?.versionID,
          instanceid: selectedAction?.service?.containerId,
          apiId: selectedAction?.api?.apiId,
          servicetype: serviceType,
          input: {
            headers: headerObj,
            payload: inputObj,
            queryParams: queryParamsObj,
            pathParams: pathParamsObj,
          },
        };
        const { _msg, _status, data } = await simutateAPI(req_data);
        const msg = JSON.parse(data?.body)?.message ? JSON.parse(data?.body)?.message :  JSON.parse(data?.body)?.detail
        if(msg !== undefined && msg) enqueueSnackbar(msg, {variant: "success"})
        dispatch(
          editAction({
            id: selectedAction?._id,
            data: { responseData: data ? data : {} },
          })
        );
        if (_status === 200) {
          setIsLoader(false);
          if (typeof data?.body === "string") {
            finalData = data?.body && JSON.parse(`${data?.body}`);
            dispatch(
              editAction({
                id: selectedAction?._id,
                data: { data: finalData },
              })
            );
            const pages = currentProject.pages.map((page) => {
              return {
                ...page,
                components: page.components.map((comp) => {
                  let curlyRegex = /\{\{([^)]+)\}\}/;
                  let finalInput = curlyRegex.exec(comp?.data?.dataInput);
                  let inputs = finalInput && finalInput[1].split(".");
                  if (
                    inputs &&
                    inputs.length &&
                    inputs?.[1] === selectedAction?.name
                  ) {
                    let updatedInputs = inputs.slice(3);
                    let updatedData = finalData;
                    updatedInputs?.forEach((item) => {
                      updatedData = updatedData?.[item];
                    });
                    return {
                      ...comp,
                      data: {
                        ...comp.data,
                        dataValue: updatedData
                          ? updatedData
                          : comp?.data?.dataValue,
                      },
                    };
                  } else {
                    return comp;
                  }
                }),
              };
            });
            dispatch(editUiApplication({ data: { pages: pages } }));
            // end testing
          }
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      } catch (err) {
        setIsLoader(false);
        console.log('err :>> ', err);
        enqueueSnackbar(
          "Response data not in proper JSON format! Please check.",
          {
            variant: "error",
          }
        );
      }
    } else {
      req_data = {
        mid: selectedAction?.service?.serviceID,
        vid: selectedAction?.service?.versionID,
        apiId: selectedAction?.api?.apiId,
        servicetype: serviceType,
        topic: selectedAction?.config?.topic,
        containerName: selectedAction?.config?.containerName?.container_name,
        message: selectedAction?.config?.message,
      };
      const { _msg, _status } = await simutateAPI(req_data);

      if (_status === 200) {
        setIsLoader(false);
        enqueueSnackbar(_msg, { variant: "success" });
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          {
            variant: "error",
          }
        );
      }
    }
    return isLoader;
  };

  return {
    handleTrigger,
    runApplicationSimulation,
    runServiceSimulation,
    isLoader,
  };
};

export default useTriggers;
