import React, { useState } from "react";
import ReactFlow, {
    addEdge,
    Controls,
    Background,
    useStoreState,
    ControlButton
} from "react-flow-renderer";
import { Backdrop, CircularProgress, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack"
import "./orchestration.css";
import ProcessDataNode from "../../components/Orchestration/Nodes/ProcessDataNode";
import CallDataNode from "../../components/Orchestration/Nodes/CallDataNode";
import { editNodeData } from "../../utils/orchestration/orchestrationUtils"
import { addInputOutputDataForNodes, editElementFromList } from "../../redux/actions/orchestrationAction";
import DataFlowButtonEdge from "../../components/Orchestration/Nodes/DataFlowButtonEdge";
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    reactFlowWrapper: {
        flexGrow: 1,
        height: "100%"
    },
    dndflow: {
        display: 'flex',
        // flexDirection: 'column',
        height: '77vh',
        borderWidth: 2,
        borderColor: 'black',
        // marginTop: 2,
    },
    controls: {
        top: 20,
        right: 30,
        bottom: 'unset',
        left: 'unset',
        display: 'flex'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'blue',
    },
}))

const nodeTypes = {
    process_data_node: ProcessDataNode,
    call_data_node: CallDataNode
}

let id = 0;
const getId = () => `dndnode_${id++}`;

const edgeTypes = {
    dataFlowButtonEdge: DataFlowButtonEdge
}

function OrchestrationDataFlow(props) {
    const [loading, setLoading] = useState(false)
    const { elements, templateid, flow_mode, process_flow_elements } = props;
    // const [, , zoom] = useStoreState((state) => state.transform);
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    // const onElementsRemove = (elementsToRemove) =>
    //     setElements((els) => removeElements(elementsToRemove, els));
    const onElementsRemove = () => console.log("remove");

    var processType = "rest";
    var startNodeObj = process_flow_elements.find((item) => item.type==="start_node");
    if(startNodeObj && startNodeObj.data?.servicetype){
        processType = startNodeObj.data?.servicetype
    }

    // const onConnect = (params) => setElements((els) => addEdge(params, els));
    const onConnect = async (params) => {
        const { source, target, sourceHandle, targetHandle } = params;
        const sourceHandleObj = JSON.parse(sourceHandle);
        const targetHandleObj = JSON.parse(targetHandle);
        const { node_type, field_name, node_name, fieldgroupname, fieldgrouptype, outputfieldgroupname, outputfieldgrouptype } = sourceHandleObj;
        // const {nodeid, node_type,field_name,node_name,outputfieldgroupname,outputfieldgrouptype} = targetHandleObj;
        const { nodeid } = targetHandleObj;
        var nodeDetails = elements.length > 0 && elements.filter((item) => item.id === nodeid);
        nodeDetails = nodeDetails[0]
        var input_payload = {};
        if (Object.keys(nodeDetails.inputFields).length > 0) {
            nodeDetails?.inputFields?.fields.forEach((item_) => {
                input_payload[item_.name] = item_.value
            })
            if (node_type === "process_node") {
                input_payload = {
                    ...input_payload,
                    [targetHandleObj['field_name']]: processType==="rest" ? "${templateInput.payload." + field_name + "}" : "${templateInput.message." + field_name + "}" 
                }
            } else {
                input_payload = {
                    ...input_payload,
                    [targetHandleObj["field_name"]]: "${" + node_name + ".result.response." + field_name + "}"
                }
            }
            const req_data = {
                input: JSON.stringify(input_payload)
            }
            var newFieldsArray = nodeDetails?.inputFields?.fields?.map((field_) => {
                if (field_.name === targetHandleObj["field_name"]) {
                    if(node_type==="process_node"){
                        return {
                            ...field_,
                            value : processType==="rest" ? "${templateInput.payload." + field_name + "}" : "${templateInput.message." + field_name + "}" 
                        }
                    }else{
                        return {
                            ...field_,
                            value: "${" + node_name + ".result.response." + field_name + "}"
                        }
                    }
                } else {
                    return field_
                }
            })
            setLoading(true)
            const { _msg, _status, data } = await editNodeData(nodeid, templateid, req_data);
            if (_status === 201) {
                setLoading(false)
                // props.editElementFromList({
                //     ...nodeDetails,
                //     inputPayload: JSON.stringify(input_payload)
                // })
                const edge_data = {
                    ...params,
                    arrowHeadType: "arrowclosed",
                    type: "dataFlowButtonEdge"
                }
                const new_list = addEdge(edge_data, elements);
                var finalDataList = new_list.map((node_) => {
                    if (node_.id === nodeid) {
                        return {
                            ...node_,
                            inputFields: {
                                ...node_.inputFields,
                                fields: newFieldsArray
                            }
                        }
                    } else {
                        return node_
                    }
                })
                props.addInputOutputDataForNodes(finalDataList, false, true)
            } else {
                setLoading(false)
                enqueueSnackbar(_msg, { variant: "error" })
            }
        }
    }

    const onEdgeUpdate = async (oldEdge, newConnection) => {
    }

    const onLoad = (reactFlowInstance) => {
        reactFlowInstance.fitView();
        reactFlowInstance.setTransform({ x: 28, y: 63, zoom: 0.9 })
    };

    const ControlHelper = () => {
        const flowStateNodes = useStoreState((store) => store.nodes);
        const { enqueueSnackbar } = useSnackbar();
        const styles = useStyles();

        const handleSaveDataFLow = async () => {
            const process_node_data = process_flow_elements.filter((item) => item.type === "start_node");
            if (process_node_data.length === 1) {
                const { id } = process_node_data[0];
                const newDataFlow = elements.length > 0 && elements.map((item_) => {
                    var flowStateNodeDetails = flowStateNodes.filter((node_) => node_.id === item_.id);
                    if (flowStateNodeDetails.length === 1) {
                        var newPosition = {
                            x: flowStateNodeDetails[0]?.__rf.position.x.toFixed(2),
                            y: flowStateNodeDetails[0]?.__rf.position.y.toFixed(2)
                        }
                        return {
                            ...item_,
                            position: newPosition
                        }
                    } else {
                        return item_
                    }
                })
                const req_data = {
                    data_flow_nodes_io_details: JSON.stringify(newDataFlow)
                }
                setLoading(true)
                const { _msg, data, _status } = await editNodeData(id, templateid, req_data);
                if (_status === 201) {
                    enqueueSnackbar("Data Flow Successfully Saved", { variant: "success" })
                    setLoading(false)
                } else {
                    setLoading(false)
                    enqueueSnackbar(_msg, { variant: "error" })
                }
            }
        }

        return (
            <Controls className={styles.controls}>
                <ControlButton onClick={handleSaveDataFLow}>
                    <Tooltip title="Save Data Flow">
                        <SaveIcon />
                    </Tooltip>
                </ControlButton>
            </Controls>
        )
    }

    return (
        <div className={styles.reactFlowWrapper}>
            {loading && <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>}
            <ReactFlow elements={elements}
                onConnect={onConnect}
                onEdgeUpdate={onEdgeUpdate}
                onElementsRemove={onElementsRemove}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultZoom={1}>
                <Background variant="lines" gap={6} size={1} />
                <ControlHelper />
                {/* <Controls className={styles.controls} /> */}
            </ReactFlow>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { application_data, elements, process_data, flow_mode, data_flow_nodes_io_details } = state.orchestration;
    const { error, loading, data } = application_data;
    const { templateid } = process_data;
    return {
        elements: data_flow_nodes_io_details,
        application_data: data,
        templateid: templateid,
        flow_mode: flow_mode,
        process_flow_elements: elements
    }
}

const mapDispatchToProps = {
    addInputOutputDataForNodes,
    editElementFromList
}

export default connect(mapStateToProps, mapDispatchToProps)(OrchestrationDataFlow)