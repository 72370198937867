import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled, Switch } from "@material-ui/core";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";
import Styles from "../../../styles/commonStyles/Components";

export default function CustomToggle({ id }) {
  const classes = Styles();
  const dispatch = useDispatch();
  const { handleTrigger } = useTriggers();
  const { getComponent, currentPage } = useGetData();
  const currentComponent = getComponent(id);
  // Appearnace
  const GreenSwitch = styled(Switch)(() => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: currentComponent?.status,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: currentComponent?.status,
    },
  }));
  // Init
  useEffect(() => {
    if (currentComponent?.onInit) {
      handleTrigger({
        action: currentComponent?.onInit,
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: currentComponent?.data?.dataInput,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentComponent?.onChange) {
      handleTrigger({
        action: currentComponent?.onChange,
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: currentComponent?.data?.dataInput,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComponent?.data?.dataInput]);

  const handleToggleOnChange = (e) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            data: {
              dataInput: e.target.checked
                ? component?.resetValue
                : component?.setValue,
              dataValue: !component?.data?.dataValue,
            },
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div className={classes.toggle}>
      <GreenSwitch
        checked={currentComponent?.data?.dataValue}
        disabled={currentComponent?.disabled?.dataValue === "true"}
        onChange={handleToggleOnChange}
      />
      <span
        style={{
          fontFamily: currentComponent?.fontFamily,
          fontWeight: currentComponent?.style?.bold ? "bold" : undefined,
          fontStyle: currentComponent?.style?.italic ? "italic" : undefined,
          fontSize: currentComponent?.fontSize
            ? currentComponent?.fontSize
            : 16,
          textDecoration: currentComponent?.style?.underline
            ? "underline"
            : undefined,
        }}
      >
        {currentComponent?.data?.dataInput}
      </span>
    </div>
  );
}
