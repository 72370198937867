import { makeStyles } from "@material-ui/core";

const tabbedStyle = makeStyles((theme) => ({
    tabbedButtonIcon: {
        borderColor: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      },
      button: {
        "& button.btnsmall": {
          backgroundColor: theme.palette.primary.main,
          backgroundOrigin: "padding-box",
          borderRadius: "5px",
          color: "#fff",
          margin: "0 0 0 30px",
          textTransform: "capitalize !important",
          font: "normal normal 400 14px/19px Nunito",
          letterSpacing: "0.02em",
          padding: "6px 20px 6px 20px",
          "&.MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
          },
        },
        "& button.MuiButton-outlined": {
          color: theme.palette.primary.main,
          border: `0.5px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.secondary.contrastText,
          fontWeight: "bolder",
        },
      },
}));


export default tabbedStyle