import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  Tooltip,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useStyles from "../../CommonStyles/manageScreenStyles";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { editRuntimeEnvByID } from "../../../utils/containerMangement/runtimeEnvUtils";
import { connect } from "react-redux";
import { handleFormValidationPro, initError } from "../../../utils/common";
import EditruntimeENVEntity from "../../../components/common/entitySchema/containerManagement/runtimeENV/EditruntimeENVEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { getRuntimeEnvDetailsByID } from "../../../utils/containerMangement/runtimeEnvUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { v4 as uuidv4 } from "uuid";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const CreateEnvScreen = (props) => {
  const styles = useStyles();
  const createStyles = CreateCustomerStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const [envID] = useState(props.match.params._id);
  const { enqueueSnackbar } = useSnackbar();
  const [runtimeEnvdata, setRuntimeEnvData] = useState({});
  const [error, setError] = useState({});
  const [envEntitySchema, setEnvEntitySchema] = useState();
  const [envList, setEnvList] = useState({});

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("RuntimeEnvironment", ["Edit"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchEnvironmentEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "RuntimeEnvironment",
        "edit"
      );
      if (_status === 200) {
        setEnvEntitySchema(data);
        fetchruntimeENVdata();
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setRuntimeEnvData({ ...runtimeEnvdata, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });

        setError(errData);
        setLoading(false);
      } else {
        setLoading(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchruntimeENVdata = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getRuntimeEnvDetailsByID(envID);
      if (_status === 200) {
        var tempServices = data?.services?.map((item) => ({
          ...item,
          id: uuidv4(),
        }));
        setRuntimeEnvData(() => (data ? data : []));
        setEnvList(tempServices);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchEnvironmentEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
      if(event.target.name ==='name' ){
      if(value.length === 0){
        setError({...error,[event.target.name]:{...error[event.target.name],isError:true,maxLength:50,maximum:300,minLength:1,minimum:1,fieldLabel: 'Environment Name',section:0,dataType:"string"}})
      }
    }
    setRuntimeEnvData({
      ...runtimeEnvdata,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const temp_data = [];
    envList.forEach((item) => {
      if (item.imageid !== "" && item.container_name !== "") {
        if (item.restart) {
          temp_data.push({
            container_name: item.container_name,
            imageid: item.imageid,
            environment: item.environment,
            restart: item.restart,
          });
        } else {
          temp_data.push({
            container_name: item.container_name,
            imageid: item.imageid,
            environment: item.environment,
          });
        }
      }
    });
    const req_data = {
      ...runtimeEnvdata,
      services: temp_data ? temp_data : [],
    };
    const { formIsValid, errors } = handleFormValidationPro(
      runtimeEnvdata,
      error
    );
    if (formIsValid) {
      setLoading(true);
      const { _msg, _status, data } = await editRuntimeEnvByID(envID, req_data);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        setLoading(false);
        histroy.push(`/environment-details/${envID}`);
      } else {
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        envEntitySchema ? envEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setEnvEntitySchema({
        ...envEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  // Tabbed View
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {
    histroy.goBack();
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "Run time Environments",
              path: "/runtime-environments",
            },
            {
              title: runtimeEnvdata?.name ? `Edit ${runtimeEnvdata?.name}` : "Edit",
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
            style={{ borderBottom: "2px solid #afafaf" }}

        >
          <h2 className={styles.title}>
            {runtimeEnvdata.name ? `Edit ${runtimeEnvdata.name}` : "Edit"}
          </h2>
          {tabbedView ? (
            <TabbedNavigation
              schemaLength={envEntitySchema?.length}
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              isEditMode={false}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              disabled={false}
            />
           
          )}

          {/* <div className={createStyles.border_line} /> */}
        </Grid>
      </div>

      <div
        className={styles.wrapper}
        style={tabbedView ? { marginTop: "6.5rem" } : { marginTop: "7rem" }}
      >
        {tabbedView ? (
          <>
            <SectionTabs
              entity={envEntitySchema ? envEntitySchema.sections : []}
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <EditruntimeENVEntity
                  section={envEntitySchema?.sections?.[activeTab]}
                  runtimeEnvdata={runtimeEnvdata}
                  handleChange={handleChange}
                  error={error}
                  envList={envList}
                  setEnvList={setEnvList}
                />
              </div>
            </Paper>
          </>
        ) : (
          envEntitySchema?.sections &&
          envEntitySchema?.sections.map((section) => {
            if (section.name === "Environment_Header_section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <EditruntimeENVEntity
                    section={section}
                    runtimeEnvdata={runtimeEnvdata}
                    handleChange={handleChange}
                    error={error}
                    envList={envList}
                    setEnvList={setEnvList}
                  />
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EditruntimeENVEntity
                      section={section}
                      runtimeEnvdata={runtimeEnvdata}
                      handleChange={handleChange}
                      error={error}
                      envList={envList}
                      setEnvList={setEnvList}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(CreateEnvScreen);
