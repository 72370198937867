import React, { useRef, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData';
import useTriggers from '../../../hooks/useTriggers';
import Loader from '../loader/Loader';

const CustomComponent = ({ id }) => {
    const iframeRef = useRef(null);
    const { getComponent, getDataValue } = useGetData()
    const { handleTrigger } = useTriggers()
    const currentComponent = getComponent(id)

    useEffect(() => {
        if (iframeRef.current) {
            var frameDoc = iframeRef.current.document;
            if (iframeRef.current.contentWindow)
                frameDoc = iframeRef.current.contentWindow.document;

            frameDoc.open();
            let actionData = {}
            try {
                actionData = JSON.stringify(getDataValue(currentComponent?.data?.dataValue))
            } catch (error) {
                // console.log('error : ', error)
            }
            let finalCode = currentComponent?.code.replace('LCF.getData()', actionData)
            iframeRef.current.contentWindow.onerror = (message) => {
                if (finalCode.indexOf("LCF.triggerEvent()") >= 0 && message === "Uncaught ReferenceError: LCF is not defined") {
                    handleTrigger({
                        action: currentComponent?.['onEvent'],
                        isUpdateState: true,
                        componentId: currentComponent?.id,
                    });
                }
            }
            frameDoc.writeln(finalCode);
            frameDoc.close();
        }
    }, [currentComponent?.code, currentComponent?.data?.dataValue]);

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {currentComponent?.loading?.dataValue === "true" && <Loader />}
            <iframe title='Custom Component' frameBorder="0" ref={iframeRef} width="100%" height="100%"></iframe>
        </div>
    )
}

export default CustomComponent
