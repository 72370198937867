import { TestExecutionService } from "../../services/testExecutionService";
import {
  ADD_TEST_EXECUTION_LIST,
  EXECUTION_FILTERS,
  EXECUTION_SORT,
  ADD_TEST_EXECUTION_DETAILS,
  ADD_TEST_EXECUTION_LIST_FILTER,
  DELETE_EXECUTION_BY_ID,
  EXECTUION_HIDDEN,
  ADD_TEST_EXECUTION_HISTORY_HIDDEN,
} from "../actionTypes";
const execution = new TestExecutionService();

export const deleteExecutionById = (ExecutionId) => async (dispatch) => {
  dispatch({
    type: DELETE_EXECUTION_BY_ID,
    payload: {
      ExecutionId,
    },
  });
};

export const addExecutionHidden = (executionHidden) => {
  return {
    type: EXECTUION_HIDDEN,
    payload: executionHidden,
  };
};

const add_test_execution_list = (payload) => ({
  type: ADD_TEST_EXECUTION_LIST,
  payload: payload,
});
const add_test_execution_list_filter = (payload) => ({
  type: ADD_TEST_EXECUTION_LIST_FILTER,
  payload: payload,
});

export const add_test_execution_details = (payload) => ({
  type: ADD_TEST_EXECUTION_DETAILS,
  payload: payload,
});

export const fetchAllTestExecutionFilter =
  (currentPage, filter, sort, deploymentId) => async (dispatch) => {
    try {
      dispatch(
        add_test_execution_list_filter({
          loading: true,
        })
      );
      const res_data = await execution.getAllTestExecutionFilter(
        currentPage,
        filter,
        sort,
        deploymentId
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_test_execution_list_filter({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_test_execution_list_filter({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_test_execution_list_filter({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const fetchAllTestExecution =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch(
        add_test_execution_list({
          loading: true,
        })
      );
      const res_data = await execution.getAllTestExecution(
        currentPage,
        filter,
        sort
      );
      const { _msg, _status, data, _totalcount } = res_data.data;
      if (data) {
        dispatch(
          add_test_execution_list({
            loading: false,
            data: data ? data : [],
            error: false,
            _totalcount: _totalcount,
          })
        );
      } else {
        dispatch(
          add_test_execution_list({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_test_execution_list({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const addExecutionFilters = (testExecutionFilters) => {
  return {
    type: EXECUTION_FILTERS,
    payload: testExecutionFilters,
  };
};

export const addExecutionSort = (testExecutionSort) => {
  return {
    type: EXECUTION_SORT,
    payload: testExecutionSort,
  };
};

export const addTestExecutionHistoryHidden = (testExecutionHistoryHidden) => {
  return {
    type: ADD_TEST_EXECUTION_HISTORY_HIDDEN,
    payload: testExecutionHistoryHidden,
  };
};
