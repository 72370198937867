import { Grid, Divider, IconButton} from "@material-ui/core";
import React, { useState, useRef } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
// import statusProfileImg from "../../../../public/images/statusProfile.png"
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import { StatusProfiles } from "../../../services/statusProfiles";
const statusProfileService = new StatusProfiles();

const CreateProfileStatus = () => {
  const styles = manageStyles();
  const histroy = useHistory();
  const [customcolor, setCustomColor] = useState("");
  const [statusInput, setStatusInput] = useState("");
  const [statusCardData, setStatusCardData] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState("Default");
  const [markDefaultStatus, setmarkDefaultStatus] =
    useState("Mark As A Default");
  const [colorPicker, setColorPicker] = useState("#rrggbb");
  const [isColorPickerBorder, setIsColorPickerBorder] = useState(true);
  const [colorActive, setcolorActive] = useState("");

  const activeColor = useRef(null);
  const handleSelectCircleColor = (color, index) => {
    setCustomColor(color);
    setcolorActive(index);
  };
  const spanRef = useRef("");

  const handleSetStatusDefault = (id) => {
    let temp = [];
    statusCardData.forEach((el) => {
      if (el.id === id) {
        temp.push({
          id: el.id,
          title: el.title,
          color: el.color,
          isDefault: true,
        });
      } else {
        temp.push({
          id: el.id,
          title: el.title,
          color: el.color,
          isDefault: false,
        });
      }
    });
    setStatusCardData(temp);
  };

  const handleAddStatus = () => {
    let colorData = {
      id: uuidv4(),
      title: statusInput,
      color: customcolor,
      isDefault: statusCardData.length ? false : true,
    };
    setStatusCardData([...statusCardData, colorData]);
  };

  const handleRemoveStatus = (id) => {
    setStatusCardData((data) => data.filter((data) => data.id !== id));
  };

  const circleColorPicker = [
    "#F82329",
    "#34BC41",
    "#00B5AD",
    "#2185D0",
    "#B5CC18",
    "#FBBD08",
    "#F2711C",
    "#6435C9",
    "#E03997",
    "#767676",
  ];

  let statusData = {
    id: "",
    title: "",
    color: "",
  };

  const handleSubmit = () => {
    //
  };
  const handleColorPickerChange = (e) => {
    setColorPicker(e.target.value);
    setCustomColor(colorPicker);
  };

  return (
    <main className={styles.main}>
      <form onSubmit={handleSubmit}>

        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Status Profile", path: "/status-profile" },
              { title: "Services", path: "/status-profile" },
              { title: "Add status" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <h2 className={styles.heading}>Services - Add status</h2>
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid item xs={6} sm={6}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Object Type
              </label>
              <input
                type="text"
                name="name"
                placeholder={sessionStorage.getItem("objectType")}
                className={styles.input}
                style={{ backgroundColor: "#F5F5F5", marginTop: 10 }}
                autoComplete="off"
                disabled
              />
            </Grid>
          </Grid>
          <h3 className={styles.fieldHeading}>Status list</h3>
          <hr className={styles.border} style={{ height: 0.5 }} />
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={12} sm={12} style={{ display: "flex" }}>
              <Grid item xs={6} sm={6}>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Status Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={statusInput}
                    onChange={(event) => {
                      setStatusInput(event.target.value);
                    }}
                    className={styles.input}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <h3 className={styles.fieldHeading}>Choose Color</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={styles.circleColorPicker}
                      style={{ display: "flex" }}
                    >
                      {circleColorPicker.map((color, index) => {
                        return (
                          <div
                            className={
                              colorActive === index
                                ? styles.active
                                : styles.inactive
                            }
                          >
                            <div
                              className={styles.innerCircleColorPicker}
                              style={{
                                backgroundColor: color,
                                cursor: "pointer",
                              }}
                              key={index}
                              ref={activeColor}
                              id="circleColors"
                              onClick={() =>
                                handleSelectCircleColor(color, index)
                              }
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <Divider orientation="vertical" className={styles.MuiDivider} /> */}
                    <div style={{ display: "flex", marginLeft: "16px" }}>
                      <p>Customcolor</p>
                      <input
                        type="color"
                        className={styles.inputTypeColorElement}
                        value={colorPicker}
                        onChange={(e) => {
                          handleColorPickerChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <AppButton
                    color="primary"
                    buttonName="Add"
                    variant="contained"
                    disabled={false}
                    className={styles.addStatusColorButton}
                    onClick={handleAddStatus}
                  />
                </Grid>
              </Grid>
              <Divider
                orientation="vertical"
                className={styles.MuiCenterDivider}
              />
              {statusCardData.length ? (
                <Grid item xs={6} sm={6}>
                  <div>
                    {statusCardData.map((data, i) => {
                      return (
                        <div
                          key={i}
                          style={{ marginTop: 12, marginLeft: 35 }}
                        >
                          <div
                            style={{ display: "flex", marginLeft: "25px" }}
                          >
                            <div
                              onClick={() => setStatusInput(data.title)}
                              className={styles.newStatusAddBox}
                            >
                              <div
                                style={{ color: data.color }}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  padding: "0 12px",
                                }}
                                onClick={() =>
                                  handleSetStatusDefault(data.id)
                                }
                              >
                                <p
                                  style={{
                                    color: data.color,
                                    display: "flex",
                                  }}
                                >
                                  <span
                                    className={styles.predefinedColorDots}
                                    style={{ backgroundColor: data.color }}
                                  ></span>
                                  {data.title}
                                </p>
                                <p>
                                  {data.isDefault ? (
                                    <span style={{ color: "blue" }}>
                                      Default
                                    </span>
                                  ) : (
                                    <span
                                      className={styles.markAsDefault}
                                      style={{ color: "grey" }}
                                    >
                                      Mark as default
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                            <IconButton
                              color="inherit"
                              aria-label="delete"
                              edge="start"
                              className={styles.actionIcons}
                              style={{
                                marginLeft: 12,
                              }}
                              onClick={() => handleRemoveStatus(data.id)}
                            >
                              <img
                                src="/images/deleteLine.svg"
                                alt="card"
                                style={{ filter: "brightness(0.1)" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              ) : (
                <Grid item xs={6} sm={6}>
                  <div style={{ marginLeft: 215, marginTop: 50 }}>
                    <img
                      src="/images/statusProfile.png"
                      alt="kailash"
                      style={{ marginLeft: 29 }}
                    />
                    <h4>No status added yet</h4>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        <div className={styles.footer}>
          <AppButton
            color="primary"
            buttonName="Submit"
            variant="contained"
            onClick={() => histroy.push(`/status-profile`)}
            disabled={false}
            style={{ marginRight: "35px" }}
            className="btnmedium"
          />
        </div>
      </form>
    </main>
  );
};

export default CreateProfileStatus;
