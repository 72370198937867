import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AppButton } from "../Button";
import {
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import manageScreenStyles from "../../../screens/CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { postCreateField } from "../../../utils/dataStandards/fieldsUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    height: 50,
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#000000",
  },
  helperText: {
    fontSize: "16px",
  },
}));

export function CreateNewFieldGroupModal(props) {
  const { handleConfirm, open, handleClose, varType } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useState({
    name: "",
    type: "",
    description: "",
  });

  const [error, setError] = useState({
    name: { isError: false },
    description: { isError: false },
    type: { isError: false }})

  const handleChange = (event) => {
    // if (Object.keys(error).includes(event.target.name)) {
    //   setError({
    //     ...error,
    //     [event.target.name]: {
    //       ...error[event.target.name],
    //       isError: false,
    //       msg: "",
    //     },
    //   });
    // }
    setFieldData({
      ...fieldData,
      [event.target.name]: event.target.value,
    });
  };

  const styles = manageScreenStyles();
  return (
    <div>
      <Dialog
        BackdropProps={{ style: { backgroundColor: "rgb(0,0,0,.2)" } }}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="force-login-dialog" className={classes.header}>
          <p className={classes.heading}>Create {varType} label</p>
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="" className={styles.fieldHeading}>
                {varType?.charAt(0).toUpperCase() + varType?.slice(1)} Name
                  <span className={styles.red}>*</span>
                </label>
                <input
                  type="text"
                  // name={field.datakey}
                  name="name"
                  className={`${styles.input}`}
                  // className={`${styles.input} ${
                  //   error[field.datakey]?.isError && styles.error
                  // }`}
                  // value={
                  //   field.dataType === "date" && serviceData[field.datakey]
                  //     ? moment
                  //         .unix(serviceData[field.datakey])
                  //         .tz(userInfo.timeZone)
                  //         .format(
                  //           userInfo.time === "h:mm"
                  //             ? `${userInfo.dateFormat}, ${userInfo.time} A`
                  //             : `${userInfo.dateFormat} ${userInfo.time}`
                  //         )
                  //     : serviceData[field.datakey]
                  // }
                  autoComplete="off"
                  onChange={handleChange}
                />
                {/* {error[field.datakey]?.isError && (
                <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
              )} */}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <AppButton
            onClick={handleClose}
            className={`btnsmall`}
            buttonName={`Cancel`}
            variant="outlined"
          />
          <AppButton
            onClick={() => handleConfirm(fieldData)}
            className={`btnsmall`}
            buttonName={"Create "}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
