import React, { useState } from "react";
import { IconButton, Grid, Select, MenuItem, Tooltip } from "@material-ui/core";
import switchNodeStyle from "./Styles";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import PrismEditor from "../../common/PrismEditor";
// import Styles from "./Styles";

function Condition(props) {
  const classes = switchNodeStyle();
  // const [queryList, setQueryList] = useState([
  //   {
  //     id: uuidv4(),
  //     node_name: "",
  //     field_name: "",
  //     operator: "",
  //     value: ""
  //   }
  // ])
  const [queryOperatorList, setQueryOperatorList] = useState([{value:"&&",name:"&& (AND)"}, {value:"||",name:"|| (OR)"}]);
  const [rowOperatorList, setRowOperatorList] = useState([
    "==",
    "!=",
    "===",
    ">=",
    ">",
    "<",
    "<=",
  ]);
  const [editorValue, setEditorValue] = useState("");
  // const [activeButton, setActiveButton] = useState("table");

  const { index, item, call_node_arr } = props;
  const {
    id,
    condition_name,
    condition,
    activeMode,
    tableRows,
    conditionEditorVal,
  } = item;
  let tempFieldName = tableRows && tableRows[0].field_name
  // console.log('tempFieldName:', tempFieldName)
  // console.log('item:', item)

  const handleRemove = (id) => {
    props.onRemoveCondition(id);
  };

  const checkNameForm = (nameStr) => {
    // var nameStr_ = nameStr.replace(/\s/g, '');
    var nameStr_ = nameStr.replace(/ [^a-zA-Z0-9 ] /g, "");
    return nameStr_
  }

  const handleChangeName = (event) => {
    const value = event.target.value;
    var filteredName = checkNameForm(value)
    props.onConditionNameChange(id, filteredName);
  };

  const handleChangeCondition = (event) => {
    const value = event.target.value;
    props.onConditionChange(id, value);
  };

  const handleCondtionEditorValueChange = (value) => {
    props.onConditionEditorValueChange(id, value);
  };

  const handleAddTableRow = (id) => {
    const data_obj = {
      id: uuidv4(),
      node_name: "",
      field_name: "",
      operator: "",
      value: "",
      queryOperator: "&&",
    };
    props.addTableRow(id, data_obj);
  };

  const handleTableRowDataChange = (row_id, field_type, value) => {
    props.onTableRowDataChange(id, row_id, field_type, value);
  };

  const getExpressionText = () => {
    if (activeMode === "text") {
      return "Text Mode";
    } else if (activeMode === "editor") {
      return "Editor Mode";
    } else if (activeMode === "table") {
      return "Table Mode";
    }
  };

  const tableRow = (item, index) => {
    const { node_name, field_name, operator, value, queryOperator } = item;
    // const fieldList = node_name ? call_node_arr.filter((x) => x.name === node_name) : [];
    const outputFieldList = call_node_arr && call_node_arr.length > 0 ? call_node_arr.filter((node_) => {
        if (node_.name === node_name) {
            return node_
        }
    }) : []
    // console.log('call_node_arr:', call_node_arr)
    // console.log('outputFieldList:', outputFieldList)
    const finalOutputList = outputFieldList[0]?.output_field_list;
    // console.log('finalOutputList:', finalOutputList)
    return (
      <Grid
        container
        spacing={1}
        key={index}
        style={{ width: 900, marginRight: 0 }}
      >
        <Grid item xs={1} sm={1}>
          {index !== 0 ? (
            <IconButton
              color="inherit"
              aria-label="delete"
              edge="start"
              className={classes.actionIcon}
              style={index === 0 ? { marginTop: 15 } : { marginTop: 0 }}
              onClick={() => props.onDeleteTableRow(item.id, id)}
            >
              <img src="/images/rowCloseSwitch.svg" alt="card" />
            </IconButton>
          ) : (
            <div style={{ width: 34 }}>&nbsp;</div>
          )}
        </Grid>
        <Grid item xs={1} sm={1}>
          {index === 0 && (
            <label htmlFor="" className={classes.label} style={{marginLeft:"-15px"}}>
              Operator
            </label>
          )}
          {index !== 0 ? (
            <Select
              value={queryOperator}
              displayEmpty
              onChange={(e) =>
                handleTableRowDataChange(
                  item.id,
                  "queryOperator",
                  e.target.value
                )
              }
              defaultValue={queryOperator}
              // name={filter.id}
              className={classes.select}
              style={{ paddingRight: 0,width:'80px', marginLeft:"-15px"}}
              title={queryOperator}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {queryOperatorList.length > 0 &&
                queryOperatorList.map((column, index) => (
                  <MenuItem value={column?.value} key={index}>
                    {column?.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <div style={{ width: 100 }}>&nbsp;</div>
          )}
        </Grid>
        <Grid item xs={2} sm={2}>
          {index === 0 && (
            <label htmlFor="" className={classes.label}>
              Node Name
            </label>
          )}
          <Select
            value={node_name}
            displayEmpty
            onChange={(e) =>
              handleTableRowDataChange(item.id, "node_name", e.target.value)
            }
            // onChange={(e) => handleTableRowDataChange(item.id, "node_name", e.target.value)}
            // disabled
            // defaultValue={props.columns[0].name}
            // name={filter.id}
            className={classes.select}
            style={{ paddingRight: 0}}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {call_node_arr.length > 0 &&
              call_node_arr.map((item, index) => (
                <MenuItem value={item.name} key={index}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4} sm={4}>
          {index === 0 && (
            <label htmlFor="" className={classes.label}>
              Field Name
            </label>
          )}
          <Select
            value={field_name}
            title={field_name}
            displayEmpty
            onChange={(e) =>
              handleTableRowDataChange(item.id, "field_name", e.target.value)
            }
            // disabled
            // defaultValue={props.columns[0].name}
            // name={filter.id}
            className={classes.select}
            style={{ paddingRight: 0 }}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value={tempFieldName} key={100}>
              {tempFieldName}
            </MenuItem>
            {tempFieldName !== "status" && <MenuItem value="status" key={101}>
              {"status"}
            </MenuItem>}
            {tempFieldName !== "result" && <MenuItem value="result" key={102}>
              {"result"}
            </MenuItem>}
            {tempFieldName !== "response" && <MenuItem value="response" key={103}>
              {"response"}
            </MenuItem>}
            {tempFieldName !== "result.response" && <MenuItem value="result.response" key={104}>
              {"result.response"}
            </MenuItem>}
            {finalOutputList?.length > 0 && finalOutputList?.map((item_, index) => (
              <MenuItem value={item_.name} key={index}>{item_.name}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          {index === 0 && (
            <label htmlFor="" className={classes.label}>
              Operators
            </label>
          )}
          <Select
            value={operator}
            title={operator}
            onChange={(e) =>
              handleTableRowDataChange(item.id, "operator", e.target.value)
            }
            // displayEmpty
            // disabled
            // defaultValue={operators[0].exp}
            // name={filter.id}
            className={classes.select}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {/* {rowOperatorList.length > 0 &&
              rowOperatorList.map((op, index) => (
                <MenuItem value={op} key={index}>
                  {op}
                </MenuItem>
              ))} */}
            <MenuItem value="==">{"== (Equals)"}</MenuItem>
            <MenuItem value="!=">{"!= (Not Equals)"}</MenuItem>
            <MenuItem value="===">{"=== (Strict Equals)"}</MenuItem>
            <MenuItem value=">=">{">= (Greater Than Equals)"}</MenuItem>
            <MenuItem value=">">{"> (Greater Than)"}</MenuItem>
            <MenuItem value="<=">{"<= (Less Than Equals)"}</MenuItem>
            <MenuItem value="<">{"< (Less Than)"}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          {index === 0 && (
            <label htmlFor="" className={classes.label}>
              Value
            </label>
          )}
          <input
            type="text"
            name="value"
            className={classes.input}
            value={value}
            onChange={(e) =>
              handleTableRowDataChange(item.id, "value", e.target.value)
            }
            autoComplete="off"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.condition}>
      <div className={classes.condition_line1}>
        <text className={classes.heading2}>{`Priority ${index + 1}`}</text>
        <IconButton style={{ borderRadius: 0, padding: 0, backgroundColor: "white" }} onClick={() => handleRemove(id)}>
          <img src="/images/closeIcon.svg" alt="text" height={15} width={15} />
          <text className={classes.pixel_14} style={{ color: "#A8A8A8" }}>
            Remove Priority
          </text>
        </IconButton>
      </div>

      <div className={classes.condition_body}>
        <text className={classes.label}>Condition Name</text>
        <div className={classes.condition_name}>
          <input
            className={classes.value_1}
            type="text"
            name="condition_name"
            value={condition_name}
            onChange={handleChangeName}
          ></input>
          <div style={{ marginLeft: 25, marginTop: 5 }}>
            <Tooltip title="Text Mode">
              <IconButton
                color="inherit"
                aria-label="textfield"
                edge="start"
                className={`${activeMode === "text" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons
                  }`}
                onClick={() => props.onActiveModeChange("text", id)}
              >
                <img src="/images/orchestration/lineicon.svg" alt="text" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editor Mode">
              <IconButton
                color="inherit"
                aria-label="editor"
                edge="start"
                className={`${activeMode === "editor" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons
                  }`}
                onClick={() => props.onActiveModeChange("editor", id)}
              >
                <img src="/images/orchestration/editoricon.svg" alt="editor" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Table Mode">
              <IconButton
                color="inherit"
                aria-label="editor"
                edge="start"
                className={`${activeMode === "table" ? "toggleActive" : "toggleInactive"} ${classes.toggleicons
                  }`}
                onClick={() => props.onActiveModeChange("table", id)}
              >
                <img src="/images/orchestration/tableicon.svg" alt="editor" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.expression}>
          <div>
            <text className={classes.label} style={{ color: "#707070" }}>
              Expression -{" "}
            </text>
            <text className={classes.label} style={{ color: "#002855" }}>
              {getExpressionText()}
            </text>
          </div>
          <div className={classes.expression_body}>
            {activeMode === "table" && tableRows.length > 0 && (
              <div>
                {tableRows.length > 0 &&
                  tableRows.map((item, index) => tableRow(item, index))}
                <IconButton style={{
                  marginLeft: "13.5%",
                  marginTop: 25,
                  display: "flex",
                  padding: 0,
                  borderRadius: 0,
                  backgroundColor: "white"
                }}
                  onClick={() => handleAddTableRow(id)}
                >
                  <img
                    src="/images/ADD@3x.png"
                    height={20}
                    width={20}
                    alt="Add Icon"
                  />
                  <text className={classes.heading2}>Add Row</text>
                </IconButton>
              </div>
            )}
            {activeMode === "text" && (
              <input
                style={{ width: 500 }}
                className={classes.value_1}
                type="text"
                value={condition}
                name="condition"
                onChange={handleChangeCondition}
              ></input>
            )}
            {activeMode === "editor" && (
              <div className={classes.outputbody}>
                <PrismEditor
                  code={conditionEditorVal}
                  setCode={handleCondtionEditorValueChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { elements } = state.orchestration;
  var call_node_arr = [{ name: "templateInput" }];
  elements.length > 0 &&
    elements.forEach((item) => {
      const { id, type, input_field_details, output_field_details } = item;
      if (type === "call_node") {
        const name = item.data.name;
        call_node_arr.push({
          id,
          name: name,
          input_field_list: input_field_details ? input_field_details : [],
          output_field_list: output_field_details ? output_field_details : [],
        });
      }
    });

  return {
    call_node_arr,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Condition);