import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import FormStyles from './FormStyles'
import Loader from '../loader/Loader'
import { whichCompToRender } from '../../../utils/form/formHelper'
import { AppButton } from '../button/AppButton'
import useGetData from '../../../hooks/useGetData'
import useTriggers from '../../../hooks/useTriggers'
import { handleFormValidation } from '../../../../utils/common'
import CreateCustomerStyles from '../../../../screens/CommonStyles/CreateAndDetailsScreenStyle'

const Form = ({ project, id }) => {
  const styles = FormStyles()
  const classes = CreateCustomerStyles()
  const { getComponent, currentProject } = useGetData()
  const currentComponent = getComponent(id)
  const { handleTrigger } = useTriggers()
  // test
  const [error, setError] = useState({})
  // test
  const [formColumns, setFormColumns] = useState([])
  /*eslint-disable */
  const [invalidData, setInvalidData] = useState(false)
  const [fieldArrQuery, setFieldArrQuery] = useState(null)

  /*eslint-disable */
  const columns = currentComponent.columns.filter(
    (column) => !column?.isHide && !column?.isDiscardedFromPayload,
  )
  useEffect(() => {
    if (
      currentComponent?.data?.dataValue &&
      typeof (currentComponent?.data?.dataValue === 'object')
    ) {
      if (
        Array?.isArray(currentComponent?.data?.dataValue) ||
        Object?.keys(currentComponent?.data?.dataValue).length
      ) {
        setFormColumns(columns)
        setInvalidData(false)
      } else {
        setFormColumns([])
        setInvalidData(true)
      }
    }
  }, [currentComponent?.columns, currentComponent?.data?.dataValue])

  useEffect(() => {
    try {
      let obj = {}
      formColumns?.forEach((item) => {
        if (Array.isArray(currentComponent?.data?.dataValue)) {
          obj[item?.name] = currentComponent?.data?.dataValue[0][item?.name]
        } else {
          obj[item?.name] = currentComponent?.data?.dataValue[item?.name]
        }
      })
      setFieldArrQuery(obj)
    } catch (error) {
      console.log('error :>> ', error)
    }
  }, [formColumns])

  // query
  const handleChangeQuery = (event, col) => {
    let values = { ...fieldArrQuery }
    if (col?.type === 'toggle') {
      values[event.target.name] = event.target.checked
      setFieldArrQuery(values)
    } else if (col?.type === 'file' || col?.type === 'image') {
      if (event.target.files.length !== 0) {
        const docUrl = URL.createObjectURL(event.target.files[0])
        let values = { ...fieldArrQuery }
        values[event.target.name] = docUrl
        setFieldArrQuery(values)
      }
    } else {
      values[event.target.name] = event?.target?.value
        ? col?.type === 'number'
          ? parseInt(event.target.value)
          : event.target.value
        : null
      setFieldArrQuery(values)
    }
    setError({
      ...error,
      [event.target.name]: false,
    })
  }

  const handleClickApiCall = (e) => {
    e.stopPropagation()
    handleTrigger({
      action: currentComponent?.['onClick'],
      isUpdateState: true,
      componentId: currentComponent?.id,
    })
  }
  const getComponentFromAllPages = (componentId) => {
    let component = {}
    currentProject?.pages?.map((page) => {
      return page?.components?.map((comp) => (comp.id === componentId ? (component = comp) : comp))
    })
    return component
  }
  const handleSubmitForm = (e) => {
    e.stopPropagation()
    currentComponent.columns.map((v) => {
      if (v?.isRequired === false) {
        delete error[v?.name]
      }
    })
    const rowData = { ...fieldArrQuery }
    currentComponent?.columns.forEach((col) => {
      if (col?.isDiscardedFromPayload) {
        delete rowData?.[col?.name]
      }
    })
    const { formIsValid, errors } = handleFormValidation(fieldArrQuery, error)
    if (formIsValid) {
      handleTrigger({
        action: currentComponent?.['onsubmit'],
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: rowData,
      })
      if (currentComponent.submitTo) {
        const tableComponent = getComponentFromAllPages(currentComponent.submitTo)
        handleTrigger({
          action: tableComponent?.['onInit'],
          isUpdateState: true,
          componentId: tableComponent?.id,
        })
      }
    } else {
      setError({ ...error, ...errors })
    }
    let emptyFormData = {}
    Object.keys(fieldArrQuery).forEach(
      (fieldKey) => (emptyFormData = { ...emptyFormData, [fieldKey]: '' }),
    )
    setFieldArrQuery(emptyFormData)
  }

  useEffect(() => {
    if (currentComponent['onInit']) {
      handleTrigger({
        action: currentComponent?.['onInit'],
        isUpdateState: true,
        componentId: currentComponent?.id,
        data: fieldArrQuery,
      })
    }
  }, [])

  useEffect(() => {
    if (currentComponent?.columns?.length) {
      let tempVal = {}
      currentComponent.columns.map((v) => {
        tempVal[v?.name] = false
      })
      setError({ ...error, ...tempVal })
    }
  }, [currentComponent?.columns])
  const titleText = currentComponent?.title?.dataValue

  return (
    <div className={styles.formContainer} onClick={(e) => handleClickApiCall(e)}>
      <div className={styles.titleContainer}>
        <h1
          style={{
            fontFamily: currentComponent?.fontFamily,
            fontWeight: currentComponent?.style?.bold ? 'bold' : undefined,
            fontStyle: currentComponent?.style?.italic ? 'italic' : undefined,
            textDecoration: currentComponent?.style?.underline ? 'underline' : undefined,
          }}
        >
          {titleText && typeof titleText === 'string' ? titleText : 'Title'}
        </h1>
      </div>
      {formColumns.length > 0 ? (
        <Grid container>
          {currentComponent?.loading?.dataValue === 'true' && <Loader />}
          {formColumns?.map((col, index) =>
            whichCompToRender(
              col,
              index,
              currentComponent,
              handleChangeQuery,
              fieldArrQuery,
              error,
              classes,
            ),
          )}

          <Grid item container justifyContent="flex-end" style={{ marginRight: 6 }}>
            <Grid item>
              <AppButton
                buttonName={currentComponent?.btnText}
                variant="outlined"
                // startIcon={<AddOutlinedIcon />}
                className="btnsmall"
                style={{ minWidth: '70px', marginBottom: '0.5rem' }}
                onClick={(e) => handleSubmitForm(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div style={{ background: '#FFFFFF' }}>
          <h1 style={{ margin: 0 }}>Invalid data</h1>
        </div>
      )}
    </div>
  )
}

export default Form
