import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import ActionStyles from "../ActionStyles";
import useGetData from "../../../../hooks/useGetData";
import InputTable from "../../../../../components/common/Tables/InputTable";
import { v4 as uuidv4 } from "uuid";
import { jsonToArray } from "../../../../../utils/common";
import { useDispatch } from "react-redux";
import { editAction } from "../../../../../redux/actions/uiApplicationAction";
import Response from "../Response";

const ActionTabs = ({ value, setValue }) => {
  const classes = ActionStyles();
  const dispatch = useDispatch();
  const { currentAction } = useGetData();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const giveConfigs = (val) => {
    if (currentAction?.actionType === "service") {
      if (currentAction?.config?.[val]?.length) {
        return currentAction?.config?.[val];
      } else {
        return [{ id: uuidv4(), key: "", value: "" }];
      }
    } else {
      if (currentAction?.applicationConfig?.[val]?.length) {
        return currentAction?.applicationConfig?.[val];
      } else {
        return [{ id: uuidv4(), key: "", value: "" }];
      }
    }
  };
  useEffect(() => {
    let tempBody = [];
    let tempQueryParams = [];
    let tempPathParams = [];
    let tempHeaders = [];
    // if (currentAction?.actionType === "service") {

    if (currentAction?.config?.headers?.length > 0) {
      tempHeaders = giveConfigs("headers");
    } else {
      tempHeaders = jsonToArray(currentAction?.api?.headers);
    }

    if (currentAction?.config?.queryParams?.length > 0) {
      tempQueryParams = giveConfigs("queryParams");
    } else {
      currentAction?.api?.queryParameters?.map((u) => {
        tempQueryParams.push({ id: uuidv4(), key: u, value: "" });
      });
    }

    if (currentAction?.config?.pathParams?.length > 0) {
      tempPathParams = giveConfigs("pathParams");
    } else {
      currentAction?.api?.pathParameters?.map((w) => {
        tempPathParams.push({ id: uuidv4(), key: w, value: "" });
      });
    }

    if (currentAction?.config?.body?.length > 0) {
      tempBody = giveConfigs("body");
    } else {
      currentAction?.api?.fields?.map((v) => {
        tempBody.push({ id: uuidv4(), key: v?.name, value: "" });
      });
    }

    if (currentAction?.actionType === "service") {
      dispatch(
        editAction({
          id: currentAction?._id,
          data: {
            ...currentAction,
            config: {
              ...currentAction?.config,
              headers: tempHeaders,
              queryParams: tempQueryParams,
              pathParams: tempPathParams,
              body: tempBody,
            },
          },
        })
      );
    } else {
      dispatch(
        editAction({
          id: currentAction?._id,
          data: {
            ...currentAction,
            applicationConfig: {
              ...currentAction?.config,
              headers: tempHeaders,
              queryParams: tempQueryParams,
              pathParams: tempPathParams,
              body: tempBody,
            },
          },
        })
      );
    }
  }, [currentAction?.api, currentAction?.process]);
  const onChange = (env, key) => {
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          ...currentAction,
          [key]: env,
        },
      })
    );
  };

  const handleEnv = (env, type) => {
    if (currentAction?.actionType === "service") {
      dispatch(
        editAction({
          id: currentAction?._id,
          data: {
            ...currentAction,
            config: {
              ...currentAction?.config,
              [type]: env,
            },
          },
        })
      );
    } else {
      dispatch(
        editAction({
          id: currentAction?._id,
          data: {
            ...currentAction,
            applicationConfig: {
              ...currentAction?.applicationConfig,
              [type]: env,
            },
          },
        })
      );
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Headers</span>}
          disableRipple
        />
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Query Params</span>}
          disableRipple
        />
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Path Params</span>}
          disableRipple
        />
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Body</span>}
          disableRipple
        />
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Output</span>}
          disableRipple
        />
        <Tab
          className={classes.tab}
          label={<span style={{ textTransform: "none" }}>Response</span>}
          disableRipple
        />
      </Tabs>
      {value === 0 ? (
        // <InputTable envs={headers} setEnvs={(env) => setHeaders(env)} />
        <InputTable
          envs={
            currentAction?.actionType === "service"
              ? currentAction?.config?.headers
              : currentAction?.applicationConfig?.headers
          }
          setEnvs={(env) => handleEnv(env, "headers")}
        />
      ) : value === 1 ? (
        // queryParams
        <InputTable
          envs={
            currentAction?.actionType === "service"
              ? currentAction?.config?.queryParams
              : currentAction?.applicationConfig?.queryParams
          }
          setEnvs={(env) => handleEnv(env, "queryParams")}
        />
      ) : value === 2 ? (
        <InputTable
          envs={
            currentAction?.actionType === "service"
              ? currentAction?.config?.pathParams
              : currentAction?.applicationConfig?.pathParams
          }
          setEnvs={(env) => handleEnv(env, "pathParams")}
        />
      ) : value === 3 ? (
        <InputTable
          envs={
            currentAction?.actionType === "service"
              ? currentAction?.config?.body
              : currentAction?.applicationConfig?.body
          }
          setEnvs={(env) => handleEnv(env, "body")}
        />
      ) : value === 4 ? (
        <Response data={currentAction?.actionType === "service" 
          ? currentAction?.data 
          : currentAction?.responseData 
          ? JSON.parse(JSON.parse(JSON.stringify(currentAction?.responseData, null, "\t"))?.body?.replace(/\\n/g, "<br />"))?.data 
          : {}} />
      ) : (
        <Response data={currentAction?.responseData} />
      )}
    </>
  );
};

export default ActionTabs;