import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@material-ui/core";
import { AppButton } from "../common/Button";

import listStyles from "../../screens/CommonStyles/listScreenStyles";
import moment from "moment";
import { connect } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item p": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
    },
  },

  modalDsTitle: {
    marginTop: "25px",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "0.5",
  },

  modalDsContainer: {
    background: "#F3F3F3 0% 0% no-repeat padding-box",
    borderRadius: "7px",
    opacity: "0.5",
    // height: "74px",
    alignItems: "center",
    padding: "10px 0 10px 20px",
    margin: "10px 10px",

    "& .MuiGrid-item label": {
      font: "normal normal normal 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
    "& .MuiGrid-item span": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
    },
    "& .MuiGrid-grid-sm-6": {
      padding: "10px 0",
    },
  },

  modalData: {
    margin: "30px 0 30px 0",
    textAlign: "center",
    background: "#F3F3F3 0% 0% no-repeat padding-box",
    opacity: "0.5",
    height: "42px",

    "& .MuiGrid-item": {
      marginTop: "10px",
    },

    "& .MuiGrid-item label": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.5",
      height: "19px",
    },

    "& .MuiGrid-item span": {
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
      height: "19px",
    },
  },

  modalElement: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  },

  modaltab: {
    marginTop: "25px",
  },

  modalTitle: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: "0px",
    color: "#222725",
    opacity: "1",

    "& .MuiButton-root": {
      textTransform: "capitalize",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000014",
      border: "1px solid #002855",
      borderRadius: "5px",
      opacity: "1",
    },
  },

  customModal: {
    // "& ::-webkit-scrollbar": {
    //   display: "none",
    // },
  },

  modalTableContainer: {
    height: "300px",
    marginLeft: "12px",
    border: "1px solid #DEDFDF",
  },

  modelFooter: {
    height: "64px",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",

    "& .MuiButton-root": {
      width: "110px",
      height: "40px",
      font: "normal normal 600 14px/19px Nunito",
      textTransform: "capitalize",
      background: "#002855 0% 0% no-repeat padding-box",
      opacity: "1",
    },
  },

  modalTable: {
    "& thead": {
      background: "#002855 0% 0% no-repeat padding-box",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",

      "& th": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        textTransform: "capitalize",
        color: "#000000",
        opacity: "1",
      },

      "& tr": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        color: "#000000",
      },
    },
    "& tbody": {
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito Sans;",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.8",
      },
    },
  },
  modalColorClass: {
    color: theme.palette.primary.main,
    opacity: 1,
    "& span": {
      color: theme.palette.primary.main,
    },
  },
}));

const FieldDetailModal = (props) => {
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const styles = useStyles();
  const listStyle = listStyles();

  const userInfo = props.userInfo;

  const _id = props.item?._id ? props.item._id : "";
  const name = props.item?.name ? props.item.name : "";
  const type = props.item?.type ? props.item.type : "";
  const rules = props.item?.rules ? props.item.rules : {};
  const fields = props.item?.fields ? props.item.fields : [];
  const status = props.item?.status ? props.item.status : "";
  const createdby = props.item?.createdby ? props.item.createdby : "";
  const createdon = props.item?.createdon ? props.item.createdon : "";
  const description = props.item?.description ? props.item.description : "";

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleEditClick = () => {
    history.push(`/edit-field/${_id}`);
  };

  return (
    <Dialog
      // onEntering={getData}
      scroll="paper"
      maxWidth="md"
      className={styles.customModal}
      open={props.openFieldModal}
      onClose={() => props.setOpenFieldModal(false)}
    >
      <div style={{ width: "880px" }}>
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={styles.modalTitle}
          >
            Field Details
            {/* <AppButton
            buttonName="Edit"
            variant="outlined"
            startIcon={<img src="/images/editmodal.svg" alt="EditIcon" />}
            disabled={false}
            onClick={handleEditClick}
          /> */}
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3} className={styles.modalContent}>
            <Grid item xs={4}>
              <label htmlFor="">Name</label>
              <p>{name}</p>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={
                      status === "active" ? (
                        <Switch color="primary" checked={true} />
                      ) : (
                        <Switch checked={false} />
                      )
                    }
                    label="In Use"
                    labelPlacement="top"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="">Type</label>
              <p>{type}</p>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.modalDsTitle}>
              <label htmlFor="">Field Rules</label>
            </Grid>
            {type === "number" || type === "integer" ? (
              <Grid container className={styles.modalDsContainer}>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    minimum :<span>&nbsp;{rules?.minimum || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    maximum :<span>&nbsp;{rules?.maximum || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    multipleOf :<span>&nbsp;{rules?.multipleOf || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    exclusiveMinimum :
                    <span>&nbsp;{rules?.exclusiveMinimum || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    exclusiveMaximum :
                    <span>&nbsp;{rules?.exclusiveMaximum || " "}</span>
                  </label>
                </Grid>
              </Grid>
            ) : type === "string" ? (
              <Grid container className={styles.modalDsContainer}>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    minLength :<span>&nbsp;{rules?.minLength || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    maxLength :<span>&nbsp;{rules?.maxLength || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    pattern :<span>&nbsp;{rules?.pattern || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    contentEncoding :
                    <span>&nbsp;{rules?.contentEncoding || " "}</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label htmlFor="">
                    contentMediaType :
                    <span>&nbsp;{rules?.contentMediaType || " "}</span>
                  </label>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={styles.modalDsContainer}>
                Nil
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.modalDsTitle}>
              <label>Description</label>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ font: "normal normal 600 14px/19px Nunito" }}
            >
              {description}
            </Grid>
          </Grid>
          <Grid container className={styles.modalData}>
            <Grid item xs={3} justify="flex-start">
              <label>Created by : </label> <span>{createdby || " "}</span>
            </Grid>
            <Grid item xs={3}>
              <label>&nbsp;On :</label>{" "}
              <span>
                {userInfo?.timeZone && createdon
                  ? moment
                      .unix(createdon)
                      .tz(userInfo.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                  : moment.unix(createdon).format("DD/MM/YYYY, hh:mm:ss A")}
              </span>
            </Grid>
            <Grid item xs={3}>
              <label>Updated by : </label>
              <span>{props?.item?.updatedby || " "}</span>
            </Grid>
            <Grid item xs={3}>
              <label>&nbsp;On</label>:{" "}
              <span>
                {" "}
                {props?.item?.updatedon
                  ? userInfo?.timeZone && props?.item?.updatedon
                    ? moment
                        .unix(props?.item?.updatedon)
                        .tz(userInfo.timeZone)
                        .format(
                          userInfo.time === "h:mm"
                            ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            : `${userInfo.dateFormat} ${userInfo.time}`
                        )
                    : moment
                        .unix(props?.item?.updatedon)
                        .format("DD/MM/YYYY, hh:mm:ss A")
                  : " "}
              </span>
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid item xs={12} sm={12}>
            <label htmlFor="" className={styles.modalElement}>
              {"Assigned Data Elements"}
            </label>
          </Grid> */}
            {/* <Grid item xs={3} sm={8} className={styles.modaltab}>
            {
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className={listStyle.dataStandardsAccessTab}
              >
                <Tab
                  label={`Services(${0})`}
                  className={`${tab === 0 ? "toggleActive" : ""} ${
                    listStyle.toggletab
                  }`}
                />
                <Tab
                  label={`API(${0})`}
                  className={`${tab === 1 ? "toggleActive" : ""} ${
                    listStyle.toggletab
                  }`}
                />
                <Tab
                  label={`Field groups (${fields ? fields.length : 0})`}
                  className={`${tab === 2 ? "toggleActive" : ""} ${
                    listStyle.toggletab
                  }`}
                />
              </Tabs>
            }
          </Grid> */}
            {/* <Grid item xs={6} sm={6} style={{ marginTop: "25px" }}> */}
            {/* <FilledInput
              id="filled-adornment-password"
              name="filterfn"
              // onChange={handleSearch}
              className={listStyle.input}
              style={{ margin: "0 0 0 15px" }}
              placeholder={
                tab === 0
                  ? "Search Services"
                  : tab === 1
                  ? "Search Api"
                  : "Search Field groups"
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            /> */}
            {/* </Grid> */}
          </Grid>
          {/* <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {tab === 0 && (
            <TableContainer className={styles.modalTableContainer}>
              <Table stickyHeader className={styles.modalTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>ServiceName</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>ServiceGroup</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {service.map((item) => (
                    <TableRow>
                      <TableCell>{item.ServiceName}</TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.ServiceGroup}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>No data</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === 1 && (
            <TableContainer className={styles.modalTableContainer}>
              <Table stickyHeader className={styles.modalTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>API Name</TableCell>
                    <TableCell>Path URL</TableCell>
                    <TableCell>API Methods</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>No data</TableCell>
                  </TableRow>
                  {Api.map((item) => (
                    <TableRow>
                      <TableCell>{item.APIName}</TableCell>
                      <TableCell>{item.pathurl}</TableCell>
                      <TableCell>{item.APImethod}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === 2 && (
            <TableContainer className={styles.modalTableContainer}>
              <Table stickyHeader className={styles.modalTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Field Name</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields?.map((item) => (
                    <TableRow>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid> */}
        </DialogContent>
        <DialogActions className={styles.modelFooter}>
          <AppButton
            style={{ marginRight: "16px" }}
            className={`btnsmall`}
            color="primary"
            onClick={() => props.setOpenFieldModal(false)}
            buttonName="Close"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(FieldDetailModal);
