import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react'
import dashboardStyles from '../../../screens/CommonStyles/dashboardStyles';
import { AppButton } from '../Button';

const MaximizeChartModal = (props) => {
    const { maximizeChart, setMaximizeChart } = props;
    const styles = dashboardStyles();

    return (
        <div className={styles.maximizeChart}>
            <Dialog
                maxWidth={"lg"}
                fullWidth={true}
                open={maximizeChart.isOpen}
                scroll="paper"
                onClose={() => setMaximizeChart({ isOpen: false })}
            >
                <DialogTitle className={styles.head} style={{ padding: '8px 24px' }}>
                    <div className={styles.dialogTitle}>
                        <h1 className={`${styles.chartLabel}`}>{maximizeChart?.chart?.graphDetails?.graphTitle}</h1>
                        <AppButton
                            buttonName="Close"
                            variant="contained"
                            disabled={false}
                            className="btnsmall"
                            startIcon={
                                <img src="/images/minimize.svg" alt="minimize" style={{ width: "16px", height: "16px" }} />
                            }
                            onClick={() => setMaximizeChart({ isOpen: false })}
                        />
                    </div>
                </DialogTitle>
                <DialogContent
                    className={styles.chartCardContent}
                    style={{
                        justifyContent: !maximizeChart.chart?.graphDetails?.showLegends ? 'center' : 'space-between',
                        // padding: '5px 20px 0'
                    }}
                >
                    {maximizeChart.component}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default MaximizeChartModal
