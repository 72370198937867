import React from "react";
import { makeStyles } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import { alpha, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const useStyles = makeStyles({
    root: {
        // marginTop: "4rem",
    },
});

const ProcessModalTree = (props) => {
    const styles = useStyles();
    const { children, templatename } = props;
    const treeData = {
        id: "root",
        name: templatename,
        children: children
        // children: [
        //     {
        //         id: "1",
        //         name: "name",
        //     },
        //     {
        //         id: "2",
        //         name: "status",
        //     },
        //     {
        //         id: "3",
        //         name: "address",
        //         children: [
        //             {
        //                 id: "4",
        //                 name: "line1",
        //             },
        //             {
        //                 id: "5",
        //                 name: "line2",
        //             },
        //             {
        //                 id: "6",
        //                 name: "city",
        //             },
        //             {
        //                 id: "7",
        //                 name: "pincode",
        //             },
        //         ],
        //     },
        //     {
        //         id: "8",
        //         name: "products",
        //         children: [
        //             {
        //                 id: "9",
        //                 name: "quantity",
        //             },
        //         ],
        //     },
        // ],
    };

    const StyledTreeItem = withStyles((theme) => ({
        label: {
            fontSize: 14,
            color: "#000",
            padding: "3px 10px",
        },
        iconContainer: {
            // '& .close': {
            //     opacity: 0.3,
            // },
        },
        selected: {
            // background: 'red'
        },
        group: {
            paddingLeft: 15,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }))((props) => <TreeItem {...props} />);

    const renderTree = (nodes) => (
        <StyledTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </StyledTreeItem>
    );

    return (
        <TreeView
            className={styles.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={["root"]}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {renderTree(treeData)}
        </TreeView>
    );
};

export default ProcessModalTree;
