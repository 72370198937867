import React, { useState } from "react";
import StatusDropdown from "../../../atoms/rightSide/statusDropdown/StatusDropdown";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import DisabledButton from "../../../atoms/rightSide/appearance/disabled/DisabledButton";
import useGetData from "../../../../hooks/useGetData";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import CustomColorPicker from "../../../atoms/colorPicker/CustomColorPicker";
import ButtonGroup from "../../../molecules/buttonGroup/ButtonGroup";
import CustomFont from "../../../atoms/fontDropdown/CustomFont";

function TextAreaAppearance() {
  const dispatch = useDispatch();
  const {
    currentPage,
    currentComponent,
    globalAcessModeChange,
    NormalHandleChange,
  } = useGetData();
  const [accessModeErr, setAccessModeErr] = useState("");

  const handleChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [e.target.name]: e.target.value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };
  const handleFontSizeChange = (name, value) => {
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [name]: value ? value : "",
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>Font Size</label>
        <input
          type="number"
          name="fontSize"
          value={currentComponent?.fontSize}
          autoComplete="off"
          onChange={(e) =>
            handleFontSizeChange("fontSize", parseInt(e.target.value))
          }
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Placeholder"
          name="placeHolder"
          value={currentComponent?.placeHolder}
          onChange={(value) =>
            globalAcessModeChange(value, "placeHolder", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <div className="field">
        <label>Rows</label>
        <input
          type="text"
          name={"rows"}
          placeholder="Number of rows"
          value={currentComponent?.rows}
          autoComplete="off"
          onChange={handleChange}
        />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Style</label>
        <ButtonGroup />
      </div>
      <div className="field">
        <label style={{ marginBottom: 2 }}>Font Family</label>
        <CustomFont
          value={currentComponent?.fontFamily}
          onChange={(nextFont) =>
            NormalHandleChange("fontFamily", nextFont?.family)
          }
        />
      </div>
      <div className="field">
        <DisabledButton />
      </div>
      <div className="field">
        {/* <StatusDropdown
          label="Theme"
          name="status"
          status={currentComponent?.status}
          handleStatusChange={handleChange}
        /> */}
        <CustomColorPicker name="status" />
      </div>
    </div>
  );
}

export default TextAreaAppearance;
