import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel } from '@material-ui/core';
import { AppButton } from '../button/AppButton';
import { ChromePicker } from 'react-color';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './styles';
import useGetData from '../../../hooks/useGetData';
import { useDispatch } from 'react-redux';
import { editComponent } from '../../../../redux/actions/uiApplicationAction';

const CustomColorPicker = ({ name }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent } = useGetData();
  const [colorShow, setColorShow] = useState(currentComponent?.status);
  const [state, setState] = React.useState({
    checkedF: false,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setColorShow('#002855');
  };

  const handleApplyFilter = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: colorShow,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  useEffect(() => {
    setColorShow(colorShow.hex ? colorShow.hex : colorShow);
  }, [colorShow]);

  return (
    <div>
      <label>Theme</label>
      <ChromePicker
        color={colorShow}
        onChange={(updatedColor) => {
          setColorShow(updatedColor);
        }}
        className={classes.colorContainer}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedF}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            onChange={handleChange}
            name="checkedF"
            color="primary"
          />
        }
        label="Use default color"
      />
      <AppButton
        buttonName="Apply"
        variant="contained"
        disabled={false}
        className="btnmedium"
        onClick={() => handleApplyFilter()}
        style={{ width: '100%', height: '40px', marginLeft: 0 }}
      />
    </div>
  );
};

export default CustomColorPicker;
