import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import ErrorBoundary from "./airbrake/ErrorBoundary";
import { CheckJwtExpiry } from "./components/common/Auth/CheckJwtExpiry";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

const ProtectedRoute = ({
  component: Component,
  userid,
  accesstoken,
  ...rest
}) => {
  const isValid = CheckJwtExpiry(accesstoken);
  return (
    <CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={true}>
      <Route
        {...rest}
        render={(props) =>
          isValid ? (
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                  isExpired: true,
                },
              }}
            />
          )
        }
      />
    </CacheBuster>
  );
};

const mapStateToProps = (state) => {
  const { userid, accesstoken } = state.authReducer;
  return {
    userid,
    accesstoken,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
