import React, { useEffect, useState, useRef } from "react";
import { Console } from "console-feed";
import { IconButton, Tooltip } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { ConsoleWebSocket } from "./web_socket/ConsoleWS";
import { connect, useSelector } from "react-redux";
import { updateLogs } from "../../redux/actions/codeEditorAction";
import { getContainerLogs } from "../../utils/code_editor/CodeEditorUtils";
import { BASE_URL } from "../../services/helper/config";
import { useSnackbar } from "notistack";

const CodeEditorConsole = ({
  containerId,
  userInfo,
  updateLogs,
  logs,
  imageId,
  isBuildSuccessful,
  isPolling,
  size,
  buildClicked,
  isRunning,
}) => {
  const [searchText, setSearchText] = useState(null);
  let [containerLogs, setContainerLogs] = useState([]);
  const [lockedFileArray, setLockedFileArray] = useState([]);
  const logsEndRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const userData = useSelector((state) => state.authReducer);
  const scrollToBottom = () => {
    logsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (containerId && isRunning) {
      const ws = ConsoleWebSocket(containerId, userInfo._id);
      ws.onopen = function (e) {};

      ws.onmessage = function (event) {
        const socketMessage = event.data;

        setContainerLogs((prev) => [
          ...prev,
          { method: "", data: [socketMessage] },
        ]);
        scrollToBottom();
      };

      return () => {
        ws.close();
      };
    }
  }, [containerId, isRunning]);

  // useEffect(() => {
  //   if (imageId) {
  //     const handleContainerLogs = async () => {
  //       const { _msg, _status, data } = await getContainerLogs(imageId);

  //       if (_status === 200) {

  //       }
  //     };
  //     handleContainerLogs(imageId);
  //   }
  // }, [imageId, isBuildSuccessful, isPolling]);

  useEffect(() => {
    if(buildClicked){
      const handleContainerLogs = async (imageId) => {
        let response = await fetch(
          `${BASE_URL}/api/container/dockerimages/${imageId}/logs`,
          {
            headers: {
              organization: "60ba3575e1bc3c06e220ab5a",
              token: userData?.accesstoken,
            },
          }
        );

        if (response.status === 200) {
          let blob = await response.blob();

          blob.text().then((text) => {
            setContainerLogs((prev) => [...prev, { method: "", data: [text] }]);
          });
          scrollToBottom();
        } else {
        }
        scrollToBottom();
      };

      handleContainerLogs(imageId);
    }
  }, [ imageId, buildClicked]);

  return (
    <div id="souce_code_console">
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <Tooltip title="Clear" arrow>
          <IconButton
            size="small"
            style={{ marginRight: "5px" }}
            onClick={() => setContainerLogs([])}
          >
            <BlockIcon fontSize="inherit" style={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
        <input
          style={{
            outline: "none",
            background: "#404a4a",
            color: "white",
            border: "none",
            borderRadius: "1px",
          }}
          type="text"
          placeholder="Filter"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div style={{ overflowY: "scroll", height: size ? "32vh" : "76vh" }}>
        <Console
          logs={containerLogs}
          searchKeywords={searchText}
          variant="dark"
        />
        <div ref={logsEndRef}></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { logs } = state.codeEditorReducer;

  return {
    userInfo,
    logs,
  };
};

const mapDisptachToProps = {
  updateLogs,
};

export default connect(mapStateToProps, mapDisptachToProps)(CodeEditorConsole);
