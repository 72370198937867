const clusterServiceColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "deploymentname", title: "Name", for: "clusterService" },
  { name: "clusterip", title: "Cluster IP", for: "clusterService" },
  { name: "external", title: "External IP", for: "clusterService" },
  { name: "creationTimestamp", title: "Created", for: "clusterService" },
  { name: "namespace", title: "Namespace", for: "clusterService" },
  // { name: "labels", title: "Labels", for: "deployment" },
];

const clusterServiceDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "deploymentname", weightage: 1, width: 200 },
  { columnName: "clusterip", weightage: 6, width: 200 },
  { columnName: "external", weightage: -2, width: 200 },
  { columnName: "creationTimestamp", weightage: 3, width: 200 },
  { columnName: "namespace", weightage: 5, width: 200 },
  // { columnName: "labels", weightage: 2, width: 200 },
];

const clusterServiceTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "deploymentname", align: "left" },
  { columnName: "clusterip", align: "left" },
  { columnName: "external", align: "center" },
  { columnName: "creationTimestamp", align: "center" },
  { columnName: "namespace", align: "left" },
  // { columnName: "labels", align: "left" },
];

const clusterServiceColumnOrder = [
  // 'id',
  "deploymentname",
  "clusterip",
  "external",
  "creationTimestamp",
  "namespace",
  // "labels",
];

const clusterServiceColumnHidden = [
  // 'id',
  // "deploymentname",
  // "clusterip",
  // "external",
  // "creationTimestamp",
  // "namespace",
  // "labels"
];

const clusterServiceDefaultSorting = [];

export {
  clusterServiceColumns,
  clusterServiceDefaultColumnWidths,
  clusterServiceTableColumnExtensions,
  clusterServiceColumnOrder,
  clusterServiceDefaultSorting,
  clusterServiceColumnHidden,
};
