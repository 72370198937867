import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import useStyles from "../../CommonStyles/manageScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { createImage } from "../../../utils/containerMangement/imageUtils";
import { connect } from "react-redux";
import CreateDockerImageEntity from "../../../components/common/entitySchema/containerManagement/DockerImage/CreateDockerImageEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { ArrowDropDownRounded } from "@material-ui/icons";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const useStyles1 = makeStyles((theme) => ({
  doc_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // border: "1px solid",
    width: 550,
  },
  group1: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    font: "normal normal normal 12px/16px Nunito",
    color: "#000000",
  },
  value: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: 5,
    width: 240,
    height: 30,
    marginTop: 5,
  },
  icon: {
    height: 34,
    width: 31,
    color: "#000000",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
}));

const CreateImageScreen = () => {
  const styles = useStyles();
  const classes = useStyles1();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("docker_file");
  const [droppedDocument, setDroppedDocument] = useState([]);
  const [DroppedDocumentError, setDroppedDocumentError] = useState(false);
  const [nameError, setnameError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [imageDetails, setImageDetails] = useState({
    imagename: "",
    imagetag: "",
  });
  const [error, setError] = useState({
    tag: false,
  });
  const [DockerImageEntitySchema, setDockerImageEntitySchema] = useState();
  const [DockerImagedata, setDockerImageData] = useState({
    tag: "",
  });
  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("DockerImage", ["Create"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchDockerImageEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "DockerImage",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );

        setDockerImageEntitySchema(entity);
        const tempData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                tempData[`${field.datakey}_unit`] = "";
                tempData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (tempData[field.datakey] = "");
              }
            });
          });
        });

        setDockerImageData({ ...DockerImagedata, ...tempData });
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });

        setError(errData);
        setLoading(false);
      } else {
        setLoading(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchDockerImageEntitySchema();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    setDockerImageData({
      ...DockerImagedata,
      [event.target.name]: value,
    });
    setTagError(false);
  };

  const handleTabChange = (e) => {
    setImageDetails({
      imagename: "",
      imagetag: "",
    });
    setDroppedDocument([]);
    setActiveTab(e.target.value);
    setnameError(false);
    setDockerImageData({ ...DockerImagedata, tag: "" });
  };

  const handleTagChange = (e) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagetag: e.target.value,
      };
    });
  };

  const handleImageNameChange = (e) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: e.target.value,
      };
    });
    setnameError(false);
  };

  const handleUpload = (files) => {
    setImageDetails((prevState) => {
      return {
        ...prevState,
        imagename: files ? (files[0] ? files[0].name : "") : "",
      };
    });
    setDroppedDocument(files);
    setnameError(false);
    setDroppedDocumentError(false);
  };

  const handleDeleteClick = () => {
    setImageDetails({
      imagename: "",
      imagetag: "",
    });
    setDroppedDocument([]);
  };

  const handleSubmit = async () => {
    DockerImagedata["Upload File"] = imageDetails.imagename;
    const { formIsValid, errors } = handleFormValidationPro(
      DockerImagedata,
      error
    );
    const { imagename } = imageDetails;
    if (imagename.length === 0) {
      setnameError(true);
    }
    if (DockerImagedata.tag === "") {
      setTagError(true);
    }
    if (droppedDocument.length === 0) {
      setDroppedDocumentError(true);
    }
    if (formIsValid && imagename && DockerImagedata.tag !== "") {
      var temp_str = "";
      if (imagename !== "" && DockerImagedata.tag !== "") {
        temp_str = "".concat(
          imagename.toLowerCase(),
          ":",
          DockerImagedata.tag.toLowerCase()
        );
      }
      const meta = {
        ...DockerImagedata,
        buildoptions: {
          Tags: [temp_str],
        },
        dockerfile: activeTab === "docker_file" ? true : false,
      };

      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("file", droppedDocument[0]);
      bodyFormData.append("meta", JSON.stringify(meta));

      const { _msg, _status, data } = await createImage(bodyFormData);
      if (_status < 210) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });

        setLoading(false);
        histroy.push("/docker-images");
      } else {
        setLoading(false);
        if (_msg === "Invalid Payload") {
          enqueueSnackbar(data ? data : "Something went wrong", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      setError(errors);
      enqueueSnackbar(
        "There are form field error(s), please correct the input values and submit",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Docker Images", path: "/docker-images" },
            { title: "Create Docker Image", path: "" },
          ]}
        />

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography
            className={styles.heading}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Create Docker Image
          </Typography>
          <AccordionNavigation
            isCreateMode={true}
            disabled={false}
            handleSave={handleSubmit}
            isEditMode={false}
            handleCancel={() => histroy.push("/docker-images")}
          />

        </Grid>
        <div className={styles.border_line} />
      </div>

      <div className={styles.wrapper} style={{ top: "7rem" }}>
        {DockerImageEntitySchema?.length !== 0 &&
          DockerImageEntitySchema?.map((section) => {
            if (section.name === "DockerImage_Header_section") {
              return (
                <div key={section._id} style={{ padding: "25px 0 100px 0" }}>
                  <CreateDockerImageEntity
                    section={section}
                    handleChange={handleChange}
                    DockerImagedata={DockerImagedata}
                    error={error}
                  />
                </div>
              );
            } else if (section.name === "DockerImage_FileUpload") {
              return (
                <div>
                  <FormControl
                    component="fieldset"
                    error={false}
                    className={styles.formControl}
                    style={{ marginTop: 10 }}
                  >
                    <RadioGroup
                      aria-label="quiz"
                      name="quiz"
                      value={activeTab}
                      onChange={handleTabChange}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        font: "normal normal 600 16px/22px Nunito !important",
                      }}
                    >
                      <FormControlLabel
                        value="docker_image"
                        control={<Radio color="primary" size={"small"} />}
                        label=" Upload Docker Image"
                      />
                      <FormControlLabel
                        value="docker_file"
                        control={<Radio color="primary" size={"small"} />}
                        label=" Upload Docker File"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    className={`${styles.documentDropperWrapper} ${DroppedDocumentError &&
                      droppedDocument.length === 0 &&
                      styles.ErrordocumentDropperWrapper2
                      }`}
                  >
                    <DropzoneArea
                      Icon={customdropzoneImage}
                      className={styles.documentDropperClass}
                      showPreviews={false}
                      filesLimit={1}
                      maxFileSize={9000000000}
                      dropzoneText="Drag and drop or click to browse here"
                      dropzoneClass="documentDropperClass"
                      dropzoneParagraphClass="documentDropperTextClass"
                      get
                      showAlerts={false}
                      showPreviewsInDropzone={false}
                      onDrop={() => setLoading(true)}
                      onChange={(files) => {
                        setLoading(false);
                        handleUpload(files);
                      }}
                    />
                  </div>
                  {DroppedDocumentError && droppedDocument.length === 0 && (
                    <p className={styles.errorMsg}>{"File is required"}</p>
                  )}
                  {droppedDocument.length > 0 && (
                    <div className={classes.doc_container}>
                      <div className={classes.group1}>
                        <text className={classes.label}>
                          {activeTab === "docker_file"
                            ? `Docker File Name`
                            : `Docker Image Name`}
                          <span className={styles.red}>*</span>
                        </text>
                        <input
                          className={`${classes.value} ${nameError && classes.error
                            }`}
                          value={imageDetails["imagename"]}
                          placeholder={
                            activeTab === "docker_file"
                              ? `Enter Docker File Name`
                              : `Enter Docker Image Name`
                          }
                          onChange={handleImageNameChange}
                        ></input>
                        {nameError && (
                          <p className={classes.errorMsg}>
                            {activeTab === "docker_file"
                              ? `Docker File Name is required`
                              : `Docker Image Name is required`}
                          </p>
                        )}
                      </div>
                      <div className={classes.group1}>
                        <text className={classes.label}>
                          {activeTab === "docker_file"
                            ? `Docker File Tag`
                            : `Docker Image Tag`}
                          <span className={styles.red}>*</span>
                        </text>
                        <input
                          // className={classes.value}
                          className={`${classes.value} ${tagError && classes.error
                            }`}
                          type="text"
                          name="tag"
                          onChange={handleChange}
                          placeholder={
                            activeTab === "docker_file"
                              ? `Enter Docker File Tag`
                              : `Enter Docker Image Tag`
                          }
                        ></input>
                        {tagError && (
                          <p className={classes.errorMsg}>
                            {activeTab === "docker_file"
                              ? `Docker File Tag is required`
                              : `Docker Image Tag is required`}
                          </p>
                        )}
                      </div>
                      <span
                        style={{
                          marginLeft: 23,
                          marginTop: 25,
                          color: "black",
                        }}
                        onClick={handleDeleteClick}
                      >
                        <img
                          src="/images/deleteLine.svg"
                          height={18}
                          width={18}
                          color="#000000"
                          alt="icon"
                        />
                      </span>
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <Accordion
                  className={styles.fieldPanel}
                  defaultExpanded
                  key={section._id}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {section["section Label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CreateDockerImageEntity
                      section={section}
                      handleChange={handleChange}
                      DockerImagedata={DockerImagedata}
                      error={error}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateImageScreen);
