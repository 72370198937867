import {
  ADD_APPLICATION_LIST_DATA,
  ADD_SERVICE_LIST_DATA,
  ADD_VERSION_FOR_SERVICE,
  ADD_SERVICE_TO_CREATE_APPLICATION,
  EMPTY_SERVICE_TO_CREATE_APPLICATION,
  ADD_APPLICATION_FILTER,
  ADD_APPLICATION_SORT,
  DELETE_APPLICATION_BY_ID,
  POPULATE_SERVICE_TO_EDIT_APPLICATION,
  ALTER_FAVOURITE_APPLICATION_BY_ID,
  DELETE_TEMPLATE_BY_ID,
  ADD_TEMPLATE_LIST,
  ADD_APPLICATION_DETAILS_DATA,
  DELETE_MICORSERVICE_FROM_APPLICATION,
  ADD_OPEN_TO_APPLICATION_SERVICE_TABLE,
  CHECK_UNCHECK_APPLICATION_VERSION_TABLE,
  APPLICATION_HIDDEN,
  CLEAR_APPLICATION_DATA,
  ALTER_SUBSCRIBE_APPLICATION_BY_ID,
  SET_APPLICATION_DEPLOYMENT,
} from "../actionTypes";
import { ApplicationServices } from "../../services/applicationServices";
import { CommonServices } from "../../services/commonServices";
import { dockerImageServices } from "../../services/dockerImageServices";
import { imageDetails } from "../../utils/containerMangement/imageUtils";

const applicationServices = new ApplicationServices();
const commonServices = new CommonServices();
const dockerImageService = new dockerImageServices();

function add_app_data(payload) {
  return {
    type: ADD_APPLICATION_LIST_DATA,
    payload: payload,
  };
}

export function add_application_data(currentPage, filter, sort) {
  return function (dispatch, getState) {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();
    dispatch(
      add_app_data({
        loading: true,
      })
    );
    applicationServices
      .getApplicationList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_app_data({
                loading: false,
                data:
                  data.length > 0 &&
                  data.map((item) => {
                    const checkFav =
                      userFavourites.filter(
                        (fav) => fav.resourceinstance === item._id
                      ).length !== 0;
                    let tempProj = item;
                    if (checkFav) {
                      tempProj["isFavourite"] = true;
                    } else {
                      tempProj["isFavourite"] = false;
                    }
                    const checkSubscribe =
                      userSubscriptions.filter(
                        (sub) =>
                          sub.name.split("_")[0] === "application" &&
                          sub.name.split("_")[1] === item._id
                      ).length !== 0;
                    if (checkSubscribe) {
                      tempProj["isSubscribe"] = true;
                    } else {
                      tempProj["isSubscribe"] = false;
                    }
                    return tempProj;
                  }),
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_app_data({
                loading: false,
                data: [],
                error: false,
              })
            );
          }
        }
      })
      .catch((err) => {
        // const { status } = err.response.data;
        dispatch(
          add_app_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const addApplicationFilters = (applicationFilter) => {
  return {
    type: ADD_APPLICATION_FILTER,
    payload: applicationFilter,
  };
};

export const addApplicationSort = (applicationSort) => {
  return {
    type: ADD_APPLICATION_SORT,
    payload: applicationSort,
  };
};

function add_ser_data(payload) {
  return {
    type: ADD_SERVICE_LIST_DATA,
    payload: payload,
  };
}

export function add_service_data(filter) {
  return function (dispatch) {
    dispatch(
      add_ser_data({
        loading: true,
      })
    );
    commonServices
      .getServicesListForApplication(filter)
      .then((res) => {
        if (res.status === 200) {
          const { _msg, _status, data } = res.data;
          if (data) {
            dispatch(
              add_ser_data({
                loading: false,
                data: data,
                error: false,
              })
            );
          } else {
            dispatch(
              add_ser_data({
                loading: false,
                data: [],
                error: false,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_ser_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

function add_version_for_service(payload) {
  return {
    type: ADD_VERSION_FOR_SERVICE,
    payload: payload,
  };
}

export function add_version_list_for_service(serviceID) {
  return function (dispatch) {
    commonServices
      .getVersionListForService(serviceID)
      .then((res) => {
        const { _msg, _status, data } = res.data;
        if (data) {
          let versionData = data;
          const getDockerimageStatus = async () => {

            const fetchImageDetails = (imageId) => {
              return new Promise((resolve, reject) => {
                const getimageDeatils = async () => {
                  const { data, _status } = await imageDetails(imageId);
                  if (_status === 200) {
                    resolve(data);
                  } else {
                    reject();
                  }
                };
                getimageDeatils();
              });
            };

            Promise.allSettled(
              versionData?.map((item) => {
                if (item?.docker?.imagedetails?.ImageID) {
                  return fetchImageDetails(item?.docker?.imagedetails?.ImageID);
                }
              })
            ).then((values) => {
              versionData?.map((item) => {
                values.map((image) => {
                  if (
                    item?.docker?.imagedetails?.ImageID === image?.value?._id
                  ) {
                    item["image_status"] = image?.value?.state;
                    return item;
                  }
                  return item;
                });
              });

              dispatch(
                add_version_for_service({
                  data: versionData,
                  serviceID: serviceID,
                })
              );
            });

          };

          getDockerimageStatus();
        } else {
          dispatch(
            add_version_for_service({
              data: [],
              serviceID: serviceID,
            })
          );
        }
      })
      .catch((err) => {});
  };
}

export function add_service_create_application(obj) {
  return {
    type: ADD_SERVICE_TO_CREATE_APPLICATION,
    payload: {
      data: obj,
    },
  };
}

export function emptyServiceCreateApplication() {
  return {
    type: EMPTY_SERVICE_TO_CREATE_APPLICATION,
    payload: {},
  };
}

export function deleteApplicationByID(appID) {
  return {
    type: DELETE_APPLICATION_BY_ID,
    payload: {
      applicationID: appID,
    },
  };
}

export function populateServiceToApplication(data) {
  return {
    type: POPULATE_SERVICE_TO_EDIT_APPLICATION,
    payload: data,
  };
}

export const alterFavouriteApplicationByID = (appID) => {
  return {
    type: ALTER_FAVOURITE_APPLICATION_BY_ID,
    payload: {
      applicationID: appID,
    },
  };
};

export const deleteTemplateByID = (templateid) => {
  return {
    type: DELETE_TEMPLATE_BY_ID,
    payload: {
      templateid: templateid,
    },
  };
};

export const addTemplateList = (data) => {
  return {
    type: ADD_TEMPLATE_LIST,
    payload: {
      templateList: data,
    },
  };
};

export const addApplicationDetailsData = (data) => {
  return {
    type: ADD_APPLICATION_DETAILS_DATA,
    payload: data,
  };
};

export const deleteMicroserviceFromApplication = (mid) => {
  return {
    type: DELETE_MICORSERVICE_FROM_APPLICATION,
    payload: {
      mid: mid,
    },
  };
};

export const openApplicationServiceTable = (data) => {
  return {
    type: ADD_OPEN_TO_APPLICATION_SERVICE_TABLE,
    payload: data,
  };
};

export const checkUncheckApplicationVersion = (data) => {
  return {
    type: CHECK_UNCHECK_APPLICATION_VERSION_TABLE,
    payload: data,
  };
};

export const addApplicationHidden = (applicationHidden) => {
  return {
    type: APPLICATION_HIDDEN,
    payload: applicationHidden,
  };
};

export const alterSubscibeApplicationById = (applicationID) => {
  return {
    type: ALTER_SUBSCRIBE_APPLICATION_BY_ID,
    payload: {
      applicationID,
    },
  };
};

export const clearAllApplicationData = () => {
  return {
    type: CLEAR_APPLICATION_DATA,
  };
};

export const setApplicationDeployment = (deploymentData) => {
  return {
    type: SET_APPLICATION_DEPLOYMENT,
    payload: deploymentData,
  };
};
