const fieldsColumns = [
  { name: "name", title: "Name", route: "/edit-field", for: "field" },
  { name: "type", title: "Type", for: "field" },
  { name: "status", title: "In Use", for: "field" },
  { name: "createdon", title: "Created On", for: "field" },
  { name: "actions", title: "Action ", route: "/edit-field", for: "field" },
];

const fieldsDefaultColumnWidths = [
  { columnName: "name", width: 200, weightage: 2.5 },
  { columnName: "type", width: 200, weightage: 3 },
  { columnName: "status", width: 150, weightage: 3 },
  { columnName: "createdon", width: 220, weightage: 2 },
  { columnName: "actions", width: 200, weightage: 4.5 },
];

const fieldsTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "type", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "actions", align: "center", sortingEnabled: false },
];

const fieldsColumnOrder = [
  "name",
  "type",
  "status",
  "createdon",
];

const fieldColumnHidden = [
  "name",
  "type",
  "status",
  "createdon",
  "actions"
]

const fieldsRows = [
  {
    id: 12345,
    name: "First Name",
    type: "String",
    // decsription: "Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types",
    createdBy: "Super Admin 1",
    createdOn: "02/04/2021",
    updatedOn: "02/04/2021",
    updatedBy: "Super Admin 1",
    status: "active",
  },
  {
    id: 12345,
    name: "First Name",
    type: "String",
    // decsription: "Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types",
    createdBy: "Super Admin 1",
    createdOn: "02/04/2021",
    updatedOn: "02/04/2021",
    updatedBy: "Super Admin 1",
    status: "active",
  },
  {
    id: 12345,
    name: "First Name",
    type: "String",
    // decsription:
    //   "Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types",
    createdBy: "Super Admin 1",
    createdOn: "02/04/2021",
    updatedOn: "02/04/2021",
    updatedBy: "Super Admin 1",
    status: "active",
  },
  {
    id: 12345,
    name: "First Name",
    type: "String",
    // decsription:
    //   "Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types",
    createdBy: "Super Admin 1",
    createdOn: "02/04/2021",
    updatedOn: "02/04/2021",
    updatedBy: "Super Admin 1",
    status: "inactive",
  },
];

const fieldsDefaultSorting = [];

const contentEncodingValues = ["binary", "base64", "quoted-printable"];

const contentMediaTypeValues = [
  "audio/aac",
  "application/x-abiword",
  "application/x-freearc",
  "video/x-msvideo",
  "application/vnd.amazon.ebook",
  "application/octet-stream",
  "image/bmp",
  "application/x-bzip",
  "application/x-bzip2",
  "application/x-cdf",
  "application/x-csh",
  "text/css",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-fontobject",
  "application/epub+zip",
  "application/gzip",
  "image/gif",
  "text/html",
  "image/vnd.microsoft.icon",
  "text/calendar",
  "application/java-archive",
  "image/jpeg",
  "application/json",
  "application/ld+json",
  "audio/midi audio/x-midi",
  "text/javascript",
  "audio/mpeg",
  "video/mp4",
  "video/mpeg",
  "application/vnd.apple.installer+xml",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "audio/ogg",
  "video/ogg",
  "application/ogg",
  "audio/opus",
  "font/otf",
  "image/png",
  "application/pdf",
  "application/x-httpd-php",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.rar",
];

export {
  fieldsColumns,
  fieldsDefaultColumnWidths,
  fieldsTableColumnExtensions,
  fieldsColumnOrder,
  fieldsRows,
  fieldsDefaultSorting,
  contentEncodingValues,
  contentMediaTypeValues,
  fieldColumnHidden,
};
