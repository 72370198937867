import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";

import Condition from "./Condition";
import switchNodeStyle from "./Styles";

//import utils
import {
  fetchNodeDetails,
  editNodeData,
  addNodeData,
  // switchNodeExpressionHelper
} from "../../../utils/orchestration/orchestrationUtils";
import { AppButton } from "../../common/Button";

function SwitchNodeModal(props) {
  const [conditionList, setConditionList] = useState([
    {
      id: uuidv4(),
      condition_name: "",
      condition: "",
      activeMode: "text",
      tableRows: [
        {
          id: uuidv4(),
          node_name: "",
          field_name: "",
          operator: "",
          value: "",
          queryOperator: "",
        },
      ],
      conditionEditorVal: "",
    },
  ]);
  const [switchDataObj, setSwitchDataObj] = useState({
    name: "",
  });

  const [errorObj, setErrorObj] = useState({
    name: false,
  });

  const [isLoader, setIsLoader] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { isOpen, templateid, switch_node_obj } = props;
  const { edit, nodeid } = props;

  const classes = switchNodeStyle();

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const handleTextValueChange = (keyvalue, value) => {
    setSwitchDataObj((prevState) => ({
      ...prevState,
      [keyvalue]: value,
    }));
    setErrorObj((prevState) => ({ ...prevState, [keyvalue]: false }));
  };

  const checkNameForm = (nameStr) => {
    // var nameStr_ = nameStr.replace(/\s/g, '');
    var nameStr_ = nameStr.replace(/[^a-zA-Z0-9_ ]/g, "");
    return nameStr_;
  };

  const handleNameChange = (val) => {
    var filteredName = checkNameForm(val);
    handleTextValueChange("name", filteredName);
  };

  //handle edit icon click functoining
  const fetchData = async () => {
    if (edit) {
      const { _msg, _status, data } = await fetchNodeDetails(
        templateid,
        nodeid
      );
      if (_status === 200) {
        const { name, conditions, tableConditionList } = data;
        const parsed_condition = JSON.parse(conditions);
        var parsedTableConditionList;
        if (tableConditionList) {
          parsedTableConditionList = JSON.parse(tableConditionList);
        }
        const con_list =
          parsed_condition.length > 0 &&
          parsed_condition.map((item) => {
            var tableRows;
            var filteredTableRow = parsedTableConditionList
              ? parsedTableConditionList?.filter(
                  (con) => con.condition_name.replace(/ /g, "%") === item.name
                )
              : [];
            if (filteredTableRow.length === 1) {
              tableRows = filteredTableRow[0].tableRows;
            } else {
              tableRows = [
                {
                  id: uuidv4(),
                  node_name: "",
                  field_name: "",
                  operator: "",
                  value: "",
                  queryOperator: "",
                },
              ];
            }
            // var conditionStr = item.condition;
            // conditionStr = conditionStr.split(" ");
            // if (conditionStr.length > 0) {
            //   conditionStr.forEach((con_, index) => {
            //     if (con_.length > 3) {
            //       var queryOp = ""
            //       if (index > 0) {
            //         queryOp = conditionStr[index - 1] === "&&" || conditionStr[index - 1] === "||" ? conditionStr[index - 1] : "";
            //       }
            //       var conItemList = con_.split(".");
            //       if (conItemList.length === 4) {
            //         var conNodename = conItemList[0].slice(2);
            //         var conFieldnameList = conItemList[3].split("}");
            //         if (conFieldnameList.length === 2) {
            //           var conFieldName = conFieldnameList[0]
            //           const { operator, value } = switchNodeExpressionHelper(conFieldnameList[1]);
            //           tableRows.push({
            //             id: uuidv4(),
            //             node_name: conNodename ? conNodename : "",
            //             field_name: conFieldName && conFieldName === "_status" ? "status" : "",
            //             operator: operator ? operator : "",
            //             value: value ? value : "",
            //             queryOperator: queryOp ? queryOp : ""
            //           })
            //         }
            //       }
            //     }
            //   })
            // }
            return {
              id: uuidv4(),
              condition_name: item.name.replace(/%/g, " "),
              condition: item.condition,
              conditionEditorVal: item.condition,
              activeMode: "text",
              tableRows: tableRows,
            };
          });
        setSwitchDataObj((prevState) => ({ ...prevState, name: name }));
        setConditionList(con_list);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const handleAddClick = () => {
    const new_condition_list = [
      ...conditionList,
      {
        id: uuidv4(),
        condition_name: "",
        condition: "",
        activeMode: "text",
        tableRows: [
          {
            id: uuidv4(),
            node_name: "",
            field_name: "",
            operator: "",
            value: "",
            queryOperator: "",
          },
        ],
        conditionEditorVal: "",
      },
    ];
    setConditionList(new_condition_list);
  };

  const handleRemoveCondition = (val) => {
    const new_condition_list = conditionList.filter((item) => {
      if (item.id !== val) {
        return item;
      }
      // eslint-disable-next-line array-callback-return
      return;
    });
    setConditionList(new_condition_list);
  };

  const handleConditionNameChange = (id, value) => {
    const new_state = conditionList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          condition_name: value?.replace(/\s/g,"_"),
        };
      } else {
        return item;
      }
    });
    setConditionList(new_state);
  };

  const convertTextToTable = (element) => {
    const data_obj = {
      id: uuidv4(),
      node_name: "",
      field_name: "",
      operator: "",
      value: "",
      queryOperator: "&&",
    };
    const condition = element.trim();
    var curlyRegex = /\{([^)]+)\}/;
    var findRegex;
    var findString;
    if (condition.includes("==")) {
      const splitCondition = condition.split("==");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = "==";
    } else if (condition.includes("!=")) {
      const splitCondition = condition.split("!=");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = "!=";
    } else if (condition.includes("===")) {
      const splitCondition = condition.split("===");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = "===";
    } else if (condition.includes(">=")) {
      const splitCondition = condition.split(">=");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = ">=";
    } else if (condition.includes(">")) {
      const splitCondition = condition.split(">");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = ">";
    } else if (condition.includes("<=")) {
      const splitCondition = condition.split("<=");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = "<=";
    } else if (condition.includes("<")) {
      const splitCondition = condition.split("<");
      data_obj["value"] = splitCondition[1];
      findRegex = curlyRegex.exec(splitCondition[0]);
      findString = findRegex.length && findRegex[1].split(".");
      data_obj["node_name"] = findString[0];
      data_obj["field_name"] = findString[findString.length - 1];
      data_obj["operator"] = "<";
    }
    return data_obj;
  };

  const handleConditionChange = (id, value) => {
    const new_state = conditionList.map((item) => {
      if (item.id === id) {
        let newValue;
        const tableConditionList = [];
        if (value.includes("&&")) {
          newValue = value.split("&&");
          newValue.length &&
            newValue.forEach((element) => {
              const data_obj = convertTextToTable(element);
              data_obj["queryOperator"] = "&&";
              tableConditionList.push(data_obj);
            });
        } else if (value.includes("||")) {
          newValue = value.split("||");
          newValue.length &&
            newValue.forEach((element) => {
              const data_obj = convertTextToTable(element);
              data_obj["queryOperator"] = "||";
              tableConditionList.push(data_obj);
            });
        } else {
          const data_obj = convertTextToTable(value);
          tableConditionList.push(data_obj);
        }
        return {
          ...item,
          condition: value,
          conditionEditorVal: value,
          tableRows: tableConditionList,
        };
      } else {
        return item;
      }
    });
    setConditionList(new_state);
  };

  // const handleConditionEditorValueChange = (id, value) => {
  //   const new_state = conditionList.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         conditionEditorVal: value,
  //         condition: value
  //       };
  //     } else {
  //       return item;
  //     }
  //   });
  //   setConditionList(new_state);
  // };

  const handleClose = () => {
    if (edit) {
      props.onEditSwitchClose();
    } else {
      props.onCloseClick();
    }
  };

  const handleSubmit = async () => {
    var errorFlag = false;
    if (switchDataObj.name === "") {
      setErrorObj((prevState) => ({ ...prevState, name: true }));
      errorFlag = true;
    }
    if (!errorFlag) {
      const con_text_list =
        conditionList.length > 0 &&
        conditionList.map((item) => item.condition_name);
      const condition_array =
        conditionList.length > 0 &&
        // eslint-disable-next-line array-callback-return
        conditionList.map((item, index) => {
          const {
            condition_name,
            condition,
            activeMode,
            tableRows,
            conditionEditorVal,
          } = item;
          if (activeMode === "text") {
            return {
              name: condition_name.replace(/ /g, "%"),
              priority: index + 1,
              condition: condition,
            };
          } else if (activeMode === "table") {
            var condition_text = "";
            tableRows.length > 0 &&
              tableRows.forEach((item, index) => {
                if (index === 0) {
                  condition_text =
                    item.field_name === "status"
                      ? condition_text.concat(
                          "${",
                          item.node_name,
                          ".result.status}",
                          item.operator,
                          item.value
                        )
                      : condition_text.concat(
                          "${",
                          item.node_name,
                          ".result.response.",
                          item.field_name,
                          "}",
                          item.operator,
                          item.value
                        );
                } else {
                  condition_text =
                    item.field_name === "status"
                      ? condition_text.concat(
                          " ",
                          item.queryOperator,
                          " ${",
                          item.node_name,
                          ".result.status}",
                          item.operator,
                          item.value
                        )
                      : condition_text.concat(
                          " ",
                          item.queryOperator,
                          " ${",
                          item.node_name,
                          ".result.response.",
                          item.field_name,
                          "}",
                          item.operator,
                          item.value
                        );
                }
              });
            // console.log(condition_text, tableRows)
            return {
              name: condition_name.replace(/ /g, "%"),
              priority: index + 1,
              condition: condition_text,
            };
          } else if (activeMode === "editor") {
            return {
              name: condition_name.replace(/ /g, "%"),
              priority: index + 1,
              condition: conditionEditorVal,
            };
          }
        });
      setIsLoader(true);
      var tableConditionList = conditionList.map((item) => {
        return {
          id: item.id,
          condition_name: item.condition_name,
          tableRows: item.tableRows,
        };
      });
      if (edit) {
        const req_data = {
          name: switchDataObj.name,
          type: "SwitchNode",
          conditions: JSON.stringify(condition_array),
          tableConditionList: JSON.stringify(tableConditionList),
        };
        // console.log(req_data);
        const { _msg, _status, data } = await editNodeData(
          nodeid,
          templateid,
          req_data
        );
        if (_status === 201) {
          setIsLoader(false);
          props.onEditSwitchSubmit({
            ...data,
            name: switchDataObj.name,
            conditionList: con_text_list,
          });
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      } else {
        const { x, y } = switch_node_obj["position"];
        const req_data = {
          name: switchDataObj.name,
          type: "SwitchNode",
          conditions: JSON.stringify(condition_array),
          tableConditionList: JSON.stringify(tableConditionList),
          posX: x,
          posY: y,
        };
        // console.log(req_data)
        const { _msg, _status, data } = await addNodeData(templateid, req_data);
        if (_status === 201) {
          setIsLoader(false);
          props.onSubmitClick({
            ...data,
            name: switchDataObj.name,
            conditionList: con_text_list,
          });
        } else {
          setIsLoader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    }
  };
  const onDragEnd = (result) => {
    var source_obj = {};
    var target_obj = {};
    var new_list = conditionList;
    if (result.source && result.destination) {
      var source_index = result.source.index;
      var target_index = result.destination.index;
      source_obj = conditionList[source_index];
      target_obj = conditionList[target_index];
      new_list[source_index] = target_obj;
      new_list[target_index] = source_obj;
      setConditionList(new_list);
    }
  };

  const handleActiveModeChange = (mode, id) => {
    const new_state = conditionList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          activeMode: mode,
        };
      } else {
        return item;
      }
    });
    setConditionList(new_state);
  };

  const handleDeleteTableRow = (row_id, con_id) => {
    const new_state =
      conditionList.length > 0 &&
      conditionList.map((item) => {
        if (item.id === con_id) {
          const new_table = item.tableRows.filter((row) => row.id !== row_id);
          return {
            ...item,
            tableRows: new_table,
          };
        } else {
          return item;
        }
      });
    setConditionList(() => new_state);
  };

  const handleTableRowAdd = (id, data_obj) => {
    const new_conditions =
      conditionList.length > 0 &&
      conditionList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            tableRows: [...item.tableRows, data_obj],
          };
        } else {
          return item;
        }
      });
    setConditionList(() => new_conditions);
  };

  const handleTableRowDataChange = (con_id, row_id, field_type, value) => {
    const new_state =
      conditionList.length > 0 &&
      conditionList.map((item) => {
        if (item.id === con_id) {
          const new_table =
            item.tableRows.length > 0 &&
            item.tableRows.map((row) => {
              if (row.id === row_id) {
                return {
                  ...row,
                  [field_type]: value,
                };
              } else {
                return row;
              }
            });
          return {
            ...item,
            tableRows: new_table,
          };
        } else {
          return item;
        }
      });
    setConditionList((prevState) => new_state);
    // console.log(conditionList)
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onEnter={fetchData}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <Backdrop
          className={classes.backdrop}
          open={isLoader}
          onClick={() => setIsLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.section1}>
            <img
              src="/images/Start - process.png"
              alt="Icon"
              height={34}
              width={34}
            />
            <text className={classes.heading}>Switch Node</text>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className={classes.line2}>
            <div className={classes.name}>
              <p className={classes.label}>
                Node Name <text className={classes.star}>*</text>
              </p>
              <input
                style={errorObj.name ? { borderColor: "red" } : {}}
                className={classes.value}
                type={"text"}
                name="nodeName"
                value={switchDataObj.name}
                onChange={(event) => handleNameChange(event.target.value)}
              />
              {errorObj.name ? (
                <text className={classes.error_text}>Invalid Node Name</text>
              ) : (
                ""
              )}
            </div>
          </div>
          <p className={classes.heading1}>Add Conditions</p>
          <div style={{ height: "400px" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {conditionList.length > 0 &&
                      conditionList.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Condition
                                  item={item}
                                  index={index}
                                  key={index}
                                  onRemoveCondition={handleRemoveCondition}
                                  onConditionNameChange={
                                    handleConditionNameChange
                                  }
                                  onConditionChange={handleConditionChange}
                                  onConditionEditorValueChange={
                                    handleConditionChange
                                  }
                                  onActiveModeChange={handleActiveModeChange}
                                  addTableRow={handleTableRowAdd}
                                  onDeleteTableRow={handleDeleteTableRow}
                                  onTableRowDataChange={
                                    handleTableRowDataChange
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className={classes.add_icon} onClick={handleAddClick}>
              <IconButton
                style={{
                  borderRadius: 0,
                  padding: 0,
                  backgroundColor: "white",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/images/ADD@3x.png"
                  height={20}
                  width={20}
                  alt="Add Icon"
                  style={{ position: "relative", top: 5 }}
                />
                <text className={classes.heading2} style={{ paddingTop: 10 }}>
                  Add Priority
                </text>
              </IconButton>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleClose}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          <AppButton
            onClick={handleSubmit}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { process_data } = state.orchestration;
  const { templateid } = process_data;
  return {
    templateid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchNodeModal);
