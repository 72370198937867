import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  // Header css
  main: {
    paddingTop: "4rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "20px 30px 20px",
    zIndex: 1000,
    width: "100%",
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 2px #00000029'
  },
  topContentItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  topContentItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  topContentItem2: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  title: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    opacity: 1,
    margin: 0,
  },
  wrapper: {
    // position: "relative",
    // top: "5.5rem",
    margin: "5.5rem 30px 1rem 30px",
  },
  addChartCard: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DEDEDE',
    height: 418,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    "& h2": {
      font: 'normal normal 600 16px/22px Nunito'
    },

    "& p": {
      font: 'normal normal normal 14px/19px Nunito',
      color: '#8C8C8C'
    }
  },
  addBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    margin: "0px 10px",
    padding: 10
  },
  head: {
    boxShadow: '0px 1px 2px #00000029'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    "& h4": {
      font: 'normal normal 600 16px/22px Nunito',
      color: '#000000',
      margin: 0
    },
    "& h5": {
      font: 'normal normal 600 16px/ 22px Nunito',
      color: '#8C8C8C',
      margin: 0
    },
  },
  steps: {
    display: 'flex',
    alignItems: 'center',

    "& svg": {
      margin: '0 10px',
      color: '#8C8C8C'
    }
  },
  currentStep: {
    color: '#2185D0 !important'
  },
  modalContent: {
    padding: '20px !important',
    "& h4": {
      font: 'normal normal 600 16px/22px Nunito',
      color: '#000000',
      margin: '0 0 8px'
    }
  },
  selectGraphStep: {
    padding: '8px 24px'
  },
  selectGraphContainer: {
    marginBottom: '1.5rem',

    "&:last-child": {
      marginBottom: 0
    }
  },
  selectGraph: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D3D3D3',
    borderRadius: 3,
    // height: 110,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'column',

    "& img": {
      height: 90
    }
  },
  selectedGraph: {
    border: '2px solid #2185D0'
  },
  queryTab: {
    boxShadow: '0px 1px 2px #00000029',
    width: '100%',
    borderRight: '1px solid #DFDFDF',

    "& button": {
      minWidth: 122,
      minHeight: 46,

      "& svg": {
        position: 'absolute',
        right: 0,
        marginBottom: '0 !important',
        height: 15,
        color: '#8C8C8C',
      },
    },
    "& span": {
      font: 'normal normal normal 14px/24px Nunito',
      textTransform: "capitalize",
      // width: 100,
    },
  },
  addQueryBtn: {
    left: '20px !important',
    right: 'unset !important',
    height: '20px !important',
    color: theme.palette.primary.main + '!important',
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  field: {
    marginBottom: 15
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    // marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    // color: "#000000C6",

    "&::before": {
      borderBottom: "unset",
    },
  },
  chartNameInput: {
    width: "30%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    outline: "none",
    font: "normal normal bold 18px/20px Nunito",

    // "&:hover": {
    //   border: "1px solid #DFDFDF",
    // },

    // "&:focus": {
    //   border: "1px solid #DFDFDF",
    // },

    "&::-webkit-input-placeholder": {
      font: "normal normal normal 16px/22px Nunito",
    }
  },
  autocomplete: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },

    "& .MuiAutocomplete-inputRoot": {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',

      "& .MuiAutocomplete-tag": {
        height: 30,
        background: '#E4EFFC 0% 0% no-repeat padding-box',
        color: '#002855',
        font: 'normal normal bold 13px/18px Nunito',

        "& svg": {
          color: theme.palette.primary.main
        }
      },

      "& fieldset": {
        borderWidth: 0,
      }
    }
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    marginTop: 5,
    marginBottom: 5,
    // font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  dateInput: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
      "& button": {
        padding: 8,
        marginTop: 8
      }
    }
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterText: {
    font: 'normal normal 600 16px/22px Nunito',
    color: '#707070',
    margin: 0
  },
  filterContent: {
    "& .MuiGrid-container:not(:first-child) .actionIcon": {
      marginTop: '20px !important',
    },
  },
  fieldLabel: {
    font: 'normal normal bold 12px/16px Nunito'
  },
  addFilterBtn: {
    padding: '6px 10px',
    borderRadius: 20,

    "& h5": {
      font: 'normal normal normal 14px/19px Nunito',
      color: '#000000',
      margin: '0 0 0 5px'
    }
  },
  footer: {
    background: '#0000000B 0% 0% no-repeat padding-box',
    padding: 15,
  },
  justiSpace: {
    justifyContent: 'space-between !important'
  },
  chartCard: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 2px #00000029',
    marginBottom: '2rem',
    // height:"700px",
    // width:"900px",
    // height: 418,

    // "& h5": {
    //   font: 'normal normal 600 16px/22px Nunito',
    //   color: '#000000',
    //   margin: 0
    // },

    // "& h6": {
    //   font: 'normal normal normal 14px/19px Nunito',
    //   color: '#262626',
    //   margin: 0
    // }
  },
  chartCardHeader: {
    padding: '4px 16px',
    borderBottom: '1px solid #DEDEDE',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chartCartActionIcon: {
    padding: 8,
    marginLeft: 5
  },
  chartCardContent: {
    padding: '20px 20px 10px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'auto',
    flexDirection: 'column',
    ['@media (min-width:768px)'] : {
      height:"450px"
    },
    ['@media (min-width:992px)'] : {
      height:"500px"
    },
    ['@media (min-width:1366px)'] : {
      height:"550px"
    },
    ['@media (min-width:1536px)'] : {
      height:"650px"
    },
    // height:"650px"
  },
  legendTable: {
    overflowX: 'auto',
    marginBottom: 14,

    "& th": {
      font: 'normal normal 600 14px/19px Nunito',
      color: '#262626',
      border: '1px solid #DEDEDE',
      padding: 8
    },
    "& td": {
      font: 'normal normal normal 14px/19px Nunito',
      color: '#262626',
      border: '1px solid #DEDEDE',
      padding: 8
    },
    // "&::-webkit-scrollbar": {
    //   height: 50,
    // }
  },
  legends: {
    width: '100%'
  },
  legendTitle: {
    font: 'normal normal 600 16px/22px Nunito',
    color: '#000000',
    margin: 0
  },
  legendPara: {
    font: 'normal normal normal 14px/19px Nunito',
    color: '#262626',
    margin: 0
  },
  legendValue: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center'
  },
  donughtChart: {
    height: '350px !important',
    width: '350px !important',
  },
  donughtLegends: {
    // minWidth: 250,
    "& h5": {
      font: 'normal normal 600 16px/22px Nunito',
      color: '#000000',
      margin: 0,
      marginTop: 10
    },

    "& h6": {
      font: 'normal normal normal 14px/19px Nunito',
      color: '#262626',
      margin: 0
    }
  },
  legendHead: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    marginTop: 10
  },
  legendContent: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  legendStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  legendColor: {
    height: 10,
    // width: 10,
    // marginRight: 10,
    borderRadius: 2,
    marginBottom: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  red: {
    color: "#E22626",
  },
  queryTabContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: '1px solid #DEDEDE'
  },
  queryTabContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  datasetField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chartLabel: {
    font: "normal normal bold 18px/20px Nunito",
  },
  maximumDonughtChart: {
    height: '500px !important',
    width: '500px !important',

    "& h5": {
      font: 'normal normal 600 16px/22px Nunito',
      color: '#000000',
      margin: 0
    },

    "& h6": {
      font: 'normal normal normal 14px/19px Nunito',
      color: '#262626',
      margin: 0
    }
  }
}));
