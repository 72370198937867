import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Select,
  TableContainer,
  Table,
  TableHead,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import useStyles from "../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import { getImageList } from "../../utils/orchestration/applicationUtils";
import AddEnvironemntServicesModal from "../Orchestration/Application/AddEnvironmentServicesModal";
// import { FormatListNumberedTwoTone } from "@material-ui/icons";

const tempStyles = makeStyles({
  container: {
    width: "100%",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
          "& .MuiTableCell-root": {
            padding: "5px",
          },
        },
      },
    },
  },
  dropdown: {
    width: 400,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
});

function DetailsRunTimeEnvTable(props) {
  const styles = useStyles();
  const tStyles = tempStyles();
  const [imagesList, setImagesList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [showModal, setModal] = useState(false);
  const { envs, disabled, editDisable } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // fetch the list of images
    const fetchData = async () => {
      const { _status, _msg, data } = await getImageList();
      if (_status === 200) {
        setImagesList(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchData();
  }, []);

  const handleContainerChange = (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event.target.value };
      } else {
        return env;
      }
    });
    props.onEnvChange(newEnvs);
  };

  const handleImageSelect = (event, id) => {
    const imagename = event.target.value;
    var image_id = "";
    imagesList.forEach((item) => {
      if (item.imagename === imagename) {
        image_id = item._id;
        return;
      }
    });
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return {
          ...env,
          imagename: imagename,
          imageid: image_id,
        };
      } else {
        return env;
      }
    });
    props.onEnvChange(newEnvs);
  };

  const handleModalIconOpen = (data) => {
    if (data) {
      setSelectedData(data);
      setModal(true);
    }
  };

  const handleModalClose = (data_obj) => {
    if (data_obj) {
      const { id, environment } = data_obj;
      const new_obj =
        envs.length > 0 &&
        envs.map((item, index) => {
          if (item.id === id) {
            return { ...item, environment: environment };
          } else {
            return item;
          }
        });
      props.onEnvChange(new_obj);
      setModal(false);
    }
  };

  return (
    <div style={{ margin: "20px 10px" }}>
      <TableContainer>
        <Table
          className={styles.customTable}
          style={{ backgroundColor: "#fff" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 20 }}>Container Name</TableCell>
              <TableCell style={{ paddingLeft: 20 }}>Image</TableCell>
              <TableCell style={{ paddingLeft: 100 }}>Environment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {envs &&
              envs?.length > 0 &&
              envs?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ width: 500 }}>
                    <input
                      type="text"
                      name={"container_name"}
                      className={disabled ? styles.input2 : styles.input}
                      value={row["container_name"]}
                      onChange={(e) =>
                        handleContainerChange(e, row.id, "container_name")
                      }
                      autoComplete="off"
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell style={{ width: 450 }}>
                    {!disabled && (
                      <Select
                        native
                        name={"imagename"}
                        onChange={(event) => handleImageSelect(event, row.id)}
                        value={row["imagename"]}
                        defaultValue={row["imagename"]}
                        className={tStyles.dropdown}
                        style={{ paddingBottom: 5, fontSize: 16 }}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <option aria-label="None" value="" />
                        <optgroup label="General">
                          {imagesList?.length > 0 &&
                            imagesList?.map((item_) => {
                              return (
                                <>
                                  {item_.buildtype === "internal" && (
                                    <option
                                      name={item_.imagename}
                                      value={item_.imagename}
                                    >
                                      {item_.imagename}
                                    </option>
                                  )}
                                </>
                              );
                            })}
                        </optgroup>
                        <optgroup label="Custom build">
                          {imagesList?.length > 0 &&
                            imagesList?.map((item_) => {
                              return (
                                <>
                                  {item_?.buildtype === "external" && (
                                    <option
                                      name={item_?.imagename}
                                      value={item_?.imagename}
                                    >
                                      {item_?.imagename}
                                    </option>
                                  )}
                                </>
                              );
                            })}
                        </optgroup>
                      </Select>
                    )}
                    {disabled && (
                      <input
                        className={disabled ? styles.input2 : styles.input}
                        value={row["image"]}
                        disabled={true}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell align="center" style={{ width: 300 }}>
                    <IconButton
                      color="inherit"
                      aria-label="delete"
                      edge="start"
                      className={styles.actionIcon}
                      onClick={() => handleModalIconOpen(row)}
                    >
                      <img src="/images/application_list.svg" alt="card" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {envs?.length === 0 && (
            <div className={styles.nodata}>
              <text>No Data Available</text>
            </div>
          )}
        </Table>
      </TableContainer>

      {showModal && (
        <AddEnvironemntServicesModal
          isOpen={showModal}
          id={selectedData.id}
          envs={selectedData["environment"]}
          setEnvs={(data_obj) => handleModalClose(data_obj)}
          disabled={editDisable}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsRunTimeEnvTable);
