import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CreateCustomerStyles from "../../CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { getContainerDetailsByID } from "../../../utils/containerMangement/containerUtils";
import EditContainerEntity from "../../../components/common/entitySchema/containerManagement/container/EditContainerEntity";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { handleFormValidationPro, initError } from "../../../utils/common";
import { editContainerByID } from "../../../utils/containerMangement/containerUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { BASE_URL } from "../../../services/helper/config";
import { useSelector } from "react-redux";

import { connect } from "react-redux";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const EditContainerScreen = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();

  const [conID] = useState(props.match.params._id);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [containerEntitySchema, setContainerEntitySchema] = useState();
  const [containerdata, setContainerData] = useState("");
  const [error, setError] = useState({});
  const [env, setEnv] = useState([]);

  const { checkScreenPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("Container", ["Edit"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchContainerEntitySchema = async () => {
      let { _msg, _status, data } = await fetchResourceByPermission(
        "Container",
        "edit"
      );
      if (_status === 200) {
        let dataNew = data;
        let dataArray = dataNew.sections;
        let dataArrayNew = {
          "section Label": "Download Container logs",
          name: "Download Container logs",
        };
        dataArray = [...dataArray, dataArrayNew];
        dataNew = { ...dataNew, sections: dataArray };
        data = { ...dataNew };

        setContainerEntitySchema(data);
        fetchContainerData();
        const tempData = {};
        data.sections.map((section) => {
          if (section["section Label"] !== "Download Container logs") {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.datakey) {
                  return (tempData[field.datakey] = "");
                }
              });
            });
          }
        });

        setContainerData({ ...containerdata, ...tempData });
        const errData = {};
        data.sections.map((section, index) => {
          if (section["section Label"] !== "Download Container logs") {
            return section.fieldgroups.map((fieldgroup) => {
              return fieldgroup.fields.map((field) => {
                if (field.required === "TRUE") {
                  if (field.uiElementType === "Currency") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  if (field.uiElementType === "Amount") {
                    errData[`${field.datakey}_unit`] = initError(field, index);
                    errData[`${field.datakey}_value`] = initError(field, index);
                    return true;
                  }
                  return (errData[field.datakey] = initError(field, index));
                }
              });
            });
          }
        });

        setError(errData);
        setLoading(false);
      } else {
        setLoading(false);

        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchContainerData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getContainerDetailsByID(conID);
      if (_status === 200) {
        setContainerData(() => (data ? data : []));
        setEnv(data?.config?.Env);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchContainerEntitySchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  const handleCancel = () => {
    histroy.goBack();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    setContainerData({
      ...containerdata,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      containerdata,
      error
    );
    if (formIsValid) {
      const req_data = {
        ...containerdata,
      };
      setLoading(true);

      const { _msg, _status, data } = await editContainerByID(conID, req_data);
      if (_status === 201) {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "success",
        });
        setLoading(false);
        histroy.push(`/container-details/${conID}`);
      } else {
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        containerEntitySchema ? containerEntitySchema?.sections : [],
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setContainerEntitySchema({
        ...containerEntitySchema,
        sections: tempEntity,
      });
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  var myArr = [];
  env?.map((item) => {
    myArr = item.split("=");
  });

  // Download Logs
  const [isDownload, setIsDownload] = useState(false);
  const [isAllLogsDownload, setIsAllLogsDownload] = useState(false);
  const userData = useSelector((state) => state.authReducer);

  const handleTodayLogsDownload = async () => {
    setIsDownload(true);
    var timestamp = Math.round(new Date().setHours(0, 0, 0, 0) / 1000);

    let response = await fetch(
      `${BASE_URL}/api/container/dockercontainers/${conID}/logs?timestamp=${timestamp}`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${containerdata?.containername}-logs`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownload(false);
    } else {
      setIsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };
  const handleAllLogsDownload = async () => {
    setIsAllLogsDownload(true);
    let response = await fetch(
      `${BASE_URL}/api/container/dockercontainers/${conID}/logs`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${containerdata?.containername}-All_logs`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsAllLogsDownload(false);
    } else {
      setIsAllLogsDownload(false);
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Containers", path: "/containers" },
            {
              title: containerdata?.containername
                ? `Edit ${containerdata?.containername}`
                : "",
            },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
          alignItems="center"
        >
          <h2 className={styles.title}>{containerdata?.containername}</h2>
          {tabbedView ? (
            <TabbedNavigation
              schemaLength={containerEntitySchema?.length}
              isEditMode={true}
              activeTab={activeTab}
              handleSave={handleSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <AccordionNavigation
              handleSave={handleSubmit}
              handleCancel={handleCancel}
              isEditMode={false}
              disabled={false}
            />
          )}
        </Grid>
        {/* <div className={styles.border_line} /> */}
      </div>

      {tabbedView ? (
        <div
          className={styles.wrapper}
          style={tabbedView ? { top: "6.5rem" } : { top: "7rem" }}
        >
          <>
            <SectionTabs
              entity={
                containerEntitySchema ? containerEntitySchema.sections : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <EditContainerEntity
                  section={containerEntitySchema?.sections?.[activeTab]}
                  containerdata={containerdata}
                  handleChange={handleChange}
                  error={error}
                  myArr={myArr}
                  styleExtra={styles.downloadLogsHeading}
                  isDownload={isDownload}
                  CircularProgress={CircularProgress}
                  AppButton={AppButton}
                  handleTodayLogsDownload={handleTodayLogsDownload}
                  handleAllLogsDownload={handleAllLogsDownload}
                  isAllLogsDownload={isAllLogsDownload}
                  styleDownloadLogs={styles.downloadLogs}
                />
              </div>
            </Paper>
          </>
        </div>
      ) : (
        <>
          <div
            className={styles.wrapper}
            style={tabbedView ? { top: "6.5rem" } : { top: "7rem" }}
          >
            {containerEntitySchema?.sections &&
              containerEntitySchema?.sections.map((section) => {
                if (section["name"] !== "Download Container logs") {
                  if (section.name === "Container_Header_section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <EditContainerEntity
                          section={section}
                          containerdata={containerdata}
                          handleChange={handleChange}
                          error={error}
                          myArr={myArr}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <EditContainerEntity
                            section={section}
                            containerdata={containerdata}
                            handleChange={handleChange}
                            error={error}
                            myArr={myArr}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                }
              })}
          </div>
          {containerdata && (
            <div className={styles.wrapper} style={{ height: 200 }}>
              <Accordion className={styles.fieldPanel} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownRounded className={styles.accordianIcon} />
                  }
                >
                  <Typography className={styles.sectionHeading}>
                    {"Download Container logs"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.downloadLogs}>
                    <div>
                      <div className={styles.downloadLogsHeading}>
                        Today Logs
                      </div>
                      {isDownload && (
                        <CircularProgress color="primary" size={25} />
                      )}
                      {!isDownload && (
                        <AppButton
                          color="primary"
                          buttonName="Download"
                          variant="contained"
                          startIcon={
                            <img src="/images/Download_icon.svg" alt="" />
                          }
                          style={{ margin: "15px" }}
                          className="btnmedium"
                          onClick={handleTodayLogsDownload}
                        />
                      )}
                    </div>
                    <div>
                      <div className={styles.downloadLogsHeading}>All Logs</div>
                      {isAllLogsDownload && (
                        <CircularProgress color="primary" size={25} />
                      )}
                      {!isAllLogsDownload && (
                        <AppButton
                          color="primary"
                          buttonName="Download"
                          variant="contained"
                          startIcon={
                            <img src="/images/Download_icon.svg" alt="" />
                          }
                          style={{ margin: "15px" }}
                          className="btnmedium"
                          onClick={handleAllLogsDownload}
                        />
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </>
      )}
    </main>
  );
};
const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContainerScreen);
