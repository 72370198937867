import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { AppButton } from '../button/AppButton';
import uploadImageStyles from './UploadImageStyles';
import {Grid} from '@material-ui/core'


function UploadImage({ handleImage, col, selectedImage ,disabled}) {
  const fileInputRef = useRef(null);
  const classes = uploadImageStyles();

  return (
      <div style={{display:'flex',alignItems:'center'}}>
        {!selectedImage && (
          <Avatar
            sx={{ bgcolor: '#dde1eb', width: 70, height: 80 }}
            variant="rounded"
          >
            <ImageIcon />
          </Avatar>
        )}
        {selectedImage && (
          <Avatar
            sx={{ width: 70, height: 80 }}
            variant="rounded"
            src={selectedImage}
          />
        )}
        {col.isEditable && (
          <>
            {/* <button
              className={classes.uploadbtn}
              style={{ marginLeft: 10 }}
              onClick={() => fileInputRef?.current?.click()}
            >
              Upload
            </button> */}
            <AppButton
              buttonName="Upload"
              variant="outlined"
              // startIcon={<AddOutlinedIcon />}
              className="btntiny"
              style={{ minWidth: '70px',marginLeft:10}}
              onClick={() => fileInputRef?.current?.click()}
              disabled={disabled}
            />
            <input
              name={col.name}
              multiple={false}
              ref={fileInputRef}
              type="file"
              hidden
              onChange={(event) => handleImage(event, col)}
            />
          </>
        )}
      </div>
  );
}

export default UploadImage;
