import {
  ADD_DOCKER_IMAGE_DATA,
  DOCKER_IMAGE_FILTERS,
  DELETE_DOCKER_IMAGE,
  DOCKER_IMAGE_SORT,
  DOCKER_IMAGE_HIDDEN_COULMNS,
  CLEAR_DOCKER_IMAGE_DATA,
} from "../actionTypes";
import { dockerImageServices } from "../../services/dockerImageServices";

const dockerImageService = new dockerImageServices();

function add_dockerImage_list_data(payload) {
  return {
    type: ADD_DOCKER_IMAGE_DATA,
    payload: payload,
  };
}

export function add_dockerimage_data(currentPage, filter, sort) {
  return function (dispatch) {
    dispatch(
      add_dockerImage_list_data({
        loading: true,
      })
    );
    dockerImageService
      .getdockerImageList(currentPage, filter, sort)
      .then((res) => {
        if (res.status === 200) {
          const { data, _totalcount } = res.data;
          if (data) {
            dispatch(
              add_dockerImage_list_data({
                loading: false,
                data: data,
                error: false,
                _totalcount: _totalcount,
              })
            );
          } else {
            dispatch(
              add_dockerImage_list_data({
                loading: false,
                data: [],
                error: false,
                _totalcount: _totalcount,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          add_dockerImage_list_data({
            loading: false,
            data: [],
            error: true,
          })
        );
      });
  };
}

export const add_dockerImage_Filters = (dockerImageFilters) => {
  return {
    type: DOCKER_IMAGE_FILTERS,
    payload: dockerImageFilters,
  };
};
export const add_dockerimage_sort = (dockerImageSort) => {
  return {
    type: DOCKER_IMAGE_SORT,
    payload: dockerImageSort,
  };
};
export const delete_dockerImage = (dockerID) => {
  return {
    type: DELETE_DOCKER_IMAGE,
    payload: {
      dockerID,
    },
  };
};

export const add_dockerImage_hidden_cols = (dockerHiddenCols) => {
  return {
    type: DOCKER_IMAGE_HIDDEN_COULMNS,
    payload: dockerHiddenCols,
  };
};
export const clear_docker_image_data = () => {
  return {
    type: CLEAR_DOCKER_IMAGE_DATA,
  };
};
