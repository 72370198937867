import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  layout: {
    height: "calc(100vh - 60px)",
    background: `#FFFFFF 0% 0% no-repeat padding-box`,
    position: "relative",
    top: 60,
    left: 0,
    minWidth: "100%",
  },
  webScroll: { "&::-webkit-scrollbar": { width: "5px !important" } },
  output: {
    display: "flex",
    justifyContent: "center",
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    width: "100vw",
    height: "100vh",
  },
  sidemenu: {
    transition: "all 0.5s ease-in-out",
    border: "1px solid #F0F0F0",
    position: "absolute",
    top: 50,
    left: 0,
    width: 50,
    height: "calc(100vh - 110px)",
    display: "flex",
    flexDirection: "column",

    "& button": {
      padding: 12,
      marginLeft: 1,
    },
  },
  topheader: {
    border: "1px solid #F0F0F0",
    height: 50,
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .headerContent": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& h1": {
        font: "normal normal 600 16px/16px Nunito",
        color: "#262626",
        textTransform: "capitalize",
        margin: 0,

        "& span": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#8C8C8C",
          textTransform: "lowercase",
        },
      },
    },
  },
  arrow: {
    position: "absolute",
    top: 54,
    left: 289,
    width: 33,
    height: 33,
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0px 5px 5px 0px",
    background: "white",
    opacity: ".7",
    border: "1px solid #ddd",

    "&:hover": { opacity: 1 },
  },

  leftbar: {
    transition: "all 0.5s ease-in-out",
    width: 240,
    height: "calc(100vh - 110px)",
    position: "absolute",
    top: 50,
    left: 50,
    display: "flex",
    justifyContent: "space-between",
    overflow: "auto",

    "& .components": {
      width: "100%",

      "& div": {
        border: "1px solid #F0F0F0",
        textAlign: "center",
        padding: 12,

        "& img": {
          height: 30,
          width: 30,
        },

        "& h2": {
          font: "normal normal 600 16px/22px Nunito",
          color: "#8C8C8C",
          textTransform: "capitalize",
          margin: 0,
        },
      },
    },

    "& .pages": {
      "& .title": {
        color: "#000000",
        font: "normal normal 800 14px/19px Nunito",
      },

      "& .search": { marginBottom: 20 },

      "& .listItem": {
        color: "#022953",
        padding: "2px 10px",
        cursor: "pointer",
        height: "50px",

        "&.activeItem": { background: "#DEE9F8 0% 0% no-repeat padding-box" },

        "& h2": {
          font: "normal normal 600 14px/19px Nunito",
          margin: 7,
          height: 20,
        },
      },
    },
  },
  compHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: -27,
    backgroundColor: "#47B0FF",
    maxHeight: "1.7rem",
    color: "#fff",

    "&.currentComp": { visibility: "visible" },

    "&.notCurrentComp": { visibility: "hidden" },
  },
  playgroud: {
    transition: "all 0.5s ease-in-out",
    "-webkit-tap-highlight-color": "transparent",
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    position: "absolute",
    top: 50,
    left: 290,
    height: "calc(100vh - 160px)",
    overflow: "auto",

    "& .draggable": {
      cursor: "pointer",
      border: "1px solid #F5F5F5",

      "&:hover": {
        border: "1px solid #47B0FF !important",

        "& .notCurrentComp": { visibility: "visible" },
      },

      "&.active": {
        border: "1px solid #47B0FF !important",

        "& .react-resizable-handle": { display: "block" },
      },

      "& .react-resizable-handle": {
        background: "transparent",
        display: "none",
      },

      "& .react-resizable-handle::after": {
        right: 0,
        bottom: 0,
        width: 7,
        height: 7,
        backgroundColor: "#47B0FF",
        borderRight: "unset",
        borderBottom: "unset",
        borderRadius: 20,
      },
    },

    "& .react-grid-layout": {
      minHeight: "calc(100vh - 160px)",
      minWidth: "100vw",
    },
    "& .react-grid-item:not(.react-grid-placeholder)": { border: "none" },
  },
  rightbar: {
    border: "1px solid #F0F0F0",
    position: "absolute",
    top: 50,
    right: 0,
    width: 240,
    height: "calc(100vh - 110px)",
    "& .saveButton": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .compHeading": {
      height: 45,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#FDFDFD 0% 0% no-repeat padding-box",
      border: "1px solid #F0F0F0",

      "& .compTitle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& p": {
          font: "normal normal normal 14px/16px Nunito",
          letterSpacing: 0,
          color: "#1B1C1D",
          margin: 0,
          width: 170,
        },

        "& input": {
          font: "normal normal normal 14px/16px Nunito",
          letterSpacing: 0,
          color: "#1B1C1D",
          border: "1px solid #D8D8D8",
          background: "transparent",
          outline: "none",
          padding: 5,
          borderRadius: 5,
        },

        "& .nameError": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#DF0E0E",
          margin: 0,
        },
      },
    },
    "& .tabType": {
      font: "normal normal 600 12px/16px Nunito",
      letterSpacing: 0,
      marginLeft: 16,
      color: "#767676",
      textTransform: "uppercase",
    },
    "& .rightTab": {
      borderBottom: "1px solid #F0F0F0",

      "& button": { minWidth: 82 },
    },

    "& .settingTab": {
      padding: "0 16px",
      overflow: "auto",
      height: "61vh",
      marginBottom: 13,

      "& .field": {
        display: "flex",
        flexDirection: "column",
        marginBottom: 10,

        "& label": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#333333DE",
          textTransform: "capitalize",
        },
        "& input": {
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "1px solid #D8D8D8",
          borderRadius: 5,
          padding: 10,
          marginTop: 3,
          outline: "none",
          width: "100%",
        },
        "& .errorInput": { border: "1px solid #E22626 !important" },
        "& .saveButton": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .tableColumns": {
          "& .tableColumn": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #D8D8D8",
            borderRadius: 5,
            marginBottom: 5,
            marginTop: 2,
            cursor: "pointer",
            "& .text": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
            "& h2": {
              font: "normal normal 600 12px/16px Nunito",
              color: "#8C8C8C",
              textTransform: "capitalize",
            },
            "& .wrapTitleText": {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "105px",
              overflow: "hidden",
            },
            "& .icons": { display: "flex" },
            "& .options": { background: "red" },
          },
        },
        "& .scheading": {
          textAlign: "left",
          letterSpacing: 0,
          font: "normal normal 600 12px/16px Nunito",
          color: "#767676",
          textTransform: "uppercase",
        },
        "& .scText": {
          font: "normal normal 600 14px/19px Nunito",
          "& .condition": { color: "#333333DE" },
          "& .inputmode": { color: "#AFAFAF" },
          "& .helptxt": { color: "#8C8C8C" },
        },
        "& .jsDataInput": { display: "flex" },
        "& .editorInput": {
          border: "1px solid #D8D8D8",
          position: "relative",
          borderRadius: 5,
          maxHeight: 250,
          minHeight: 40,
          overflow: "hidden",

          "& .CodeMirror": {
            height: "auto",
            padding: 4,
            borderRadius: 5,
            "& .CodeMirror-scroll .CodeMirror-sizer": {
              minHeight: "100% !important",
            },
          },
        },
        "& .inputWithIcon": {
          "& .icon": {
            background: "#F5F5F5 0% 0% no-repeat padding-box",
            border: "1px solid #D8D8D8",
            borderRadius: "0px 5px 5px 0px",
            height: 40,

            "& button": { padding: 8 },
          },
        },
      },
    },

    "& .errorMsg": {
      font: "normal normal 600 12px/16px Nunito",
      color: "#DF0E0E",
      margin: 0,
      marginTop: 5,
    },
  },

  bottombar: {
    transition: "all 0.5s ease-in-out",
    border: "1px solid #F0F0F0",
    position: "absolute",
    bottom: 0,
    left: 289,
    height: "auto",
    maxHeight: "5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",

    "& h2": {
      font: "normal normal normal 14px/19px Nunito",
      color: "#8C8C8C",
      textTransform: "capitalize",
    },
  },
  typeAction: { color: "#8C8C8C" },
  compTitle: {
    font: "normal normal 600 16px/22px Nunito",
    color: "#fff",
    margin: 0,
    marginTop: 1,
  },

  previewTopHeader: {
    border: "1px solid #F0F0F0",
    height: 50,
    position: "fixed",
    top: 60,
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    zIndex: 9,
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "& .headerContent": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& h1": {
        font: "normal normal 600 16px/16px Nunito",
        color: "#262626",
        textTransform: "capitalize",
        margin: 0,
        width: 150,

        "& span": {
          font: "normal normal 600 12px/16px Nunito",
          color: "#8C8C8C",
          textTransform: "lowercase",
        },
      },

      "& .activeMode .MuiTouchRipple-root": {
        border: "1px solid #D8D8D8",
      },
    },
  },
  previewMode: { padding: "10px 20px" },
  previewOutput: {
    "-webkit-tap-highlight-color": "transparent",
    background: "white 0% 0% no-repeat padding-box",
    position: "absolute",
    top: 50,
    width: "100vw",
    overflow: "auto",

    "&.publish": {
      top: 0,
      zIndex: 1200,
      width: "100%",
      height: "100%",
    },

    "& .layout": {
      border: "1px solid #D8D8D8",
      height: "auto",
      background: "#F5F5F5 0% 0% no-repeat padding-box",
      margin: "auto",
    },

    "& .desktop-layout": {
      width: "calc(100vw - 10px)",
      border: "unset",
    },

    "& .tablet-layout": {
      width: "39rem", // 620px
    },

    "& .mobile-layout": {
      width: "25rem", // 400px
    },

    "& .draggable": {
      // backgroundColor: "#fff",
      cursor: "pointer",
      border: "1px solid #F5F5F5",

      "& .currentComp": {
        visibility: "hidden",
        display: "none",
      },

      "& .notCurrentComp": {
        visibility: "hidden",
        display: "none",
      },

      "&:hover": {
        "& .notCurrentComp": {
          visibility: "hidden",
        },
      },

      "&.active": {
        "& .react-resizable-handle": {
          display: "none",
        },
      },

      "& .react-resizable-handle": {
        background: "transparent",
        display: "none",
      },

      "& .react-resizable-handle::after": {
        right: 0,
        bottom: 0,
        width: 7,
        height: 7,
        backgroundColor: "#47B0FF",
        borderRight: "unset",
        borderBottom: "unset",
        borderRadius: 20,
      },
    },

    "& .react-grid-layout": {
      minHeight: "calc(100vh - 160px)",
    },
    "& .react-grid-item:not(.react-grid-placeholder)": {
      border: "none",
    },
  },
  addPage: {
    border: "1px solid #D8D8D8",
    width: "100%",
    height: "auto",
    padding: "13px 16px",

    "& .topContent": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& span": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#212121",
    },
    "& div": {
      padding: "4px 0",
    },
    "& .urlField": {
      display: "flex",
      alignItems: "center",

      "& .slash": {
        border: "1px solid #DFDFDF",
        padding: 5,
        height: 34,
        marginTop: 4,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderRight: "unset",
        fontWeight: "bold",
      },

      "& .MuiOutlinedInput-root": {
        borderRadius: "unset",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderRight: "unset",

        "& input": {
          paddingLeft: 6,
        },
      },
    },
    "& .errorMsg": {
      color: "#DF0E0E",
      font: "normal normal 600 12px/16px Nunito;",
      margin: 0,
    },
  },
  buttons: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "Center",
    "& .MuiButton-root": {
      font: "normal normal 600 12px/17px Nunito",
    },
  },
}));
