import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import useTriggers from "../../../hooks/useTriggers";
import useGetData from "../../../hooks/useGetData";
import { editComponent } from "../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";

export default function CustomRadio({ id }) {
  const { getComponent, currentPage } = useGetData();
  const dispatch = useDispatch();

  const currentComponent = getComponent(id);

  // Settings
  const [selectedValue, setSetSelectedValue] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentComponent?.options) {
      setData(currentComponent?.options);
    }
  }, [currentComponent?.options]);

  // Appearance
  const useStyles = makeStyles((theme) => ({
    radio: {
      color: currentComponent?.status,
      "&.Mui-checked": {
        color: currentComponent?.status,
      },
    },
  }));
  const classes = useStyles();

  // Triggers
  const { handleTrigger } = useTriggers();

  const handleChange = (item) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          data: {
            ...component?.data,
            dataInput: item.value,
            dataValue: item.value,
          },
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
    if (currentComponent?.onChange) {
      handleTrigger({
        action: currentComponent?.onChange,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };

  useEffect(() => {
    if (currentComponent?.onInit) {
      handleTrigger({
        action: currentComponent?.onInit,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {data &&
          data.length > 0 &&
          data?.map((item, index) => {
            return (
              <>
                <Radio
                  key={index}
                  className={classes.radio}
                  value={item?.value}
                  checked={currentComponent?.data?.dataValue === item?.value}
                  onChange={() => handleChange(item)}
                  disabled={
                    currentComponent?.disabled?.dataValue === "true"
                      ? true
                      : false
                  }
                />
                <span
                  style={{
                    fontFamily: currentComponent?.fontFamily,
                    fontWeight: currentComponent?.style?.bold
                      ? "bold"
                      : undefined,
                    fontStyle: currentComponent?.style?.italic
                      ? "italic"
                      : undefined,
                    textDecoration: currentComponent?.style?.underline
                      ? "underline"
                      : undefined,
                    fontSize: currentComponent?.fontSize
                      ? currentComponent?.fontSize
                      : 16,
                  }}
                >
                  {item.title}
                </span>
              </>
            );
          })}
      </div>
    </>
  );
}
