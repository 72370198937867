import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import manageStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import { AppButton } from "../../Button";
import InputTableWithDisable from "../../Tables/InputTableWithDisable";
import { Autocomplete } from "@material-ui/lab";
import useCheckPermission from "../../hooks/useCheckPermission";
import InputTable from "../../Tables/InputTable";
import ConfirmReuploadDeleteImage from "../../modals/ConfirmReuploadDeleteImage";
import { DeleteDockerImage } from "../../../../utils/containerMangement/imageUtils";
import {
  DeleteContainer,
  StopContainer,
} from "../../../../utils/containerMangement/containerUtils";

const CustomFieldGroupsForVersionEdit = ({
  section,
  versionData,
  setVersionData,
  isEditMode,
  runtimeEnvs,
  selectedRuntimeEnv,
  setSelectedRuntimeEnv,
  error,
  handleChange,
  userInfo,
  envs,
  setEnvs,
  deleteRunCommand,
  deleteBuildCommand,
  repositoryData,
  setOpenUploadSourceCodeModal,
}) => {
  const styles = manageStyles();
  const { checkCompPermission } = useCheckPermission();

  const [runEnvOpen, setRunEnvOpen] = useState(false);

  const [confirmReuploadDeleteImage, setConfirmReuploadDeleteImage] =
    useState(false);
  const [isLoader, setLoader] = useState(false);

  const closeDropDown = () => {
    setRunEnvOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", closeDropDown);
  }, []);

  const environmentVariablesSection = () => {
    return (
      <>
        <div style={{ margin: "5px 0 5px 0" }}>
          <label className={styles.fieldHeading2}></label>
        </div>
        {isEditMode ? (
          <InputTable
            isEditMode={isEditMode}
            envs={envs}
            setEnvs={(env) => setEnvs(env)}
          />
        ) : (
          <InputTableWithDisable
            isEditMode={false}
            envs={envs}
            setEnvs={(env) => setEnvs(env)}
          />
        )}
      </>
    );
  };

  const runtimeEnvironmentSection = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={3}>
            <Autocomplete
              open={runEnvOpen}
              onOpen={() => setRunEnvOpen(true)}
              onClose={() => setRunEnvOpen(false)}
              options={runtimeEnvs}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              value={selectedRuntimeEnv ? selectedRuntimeEnv : {}}
              disabled={!isEditMode}
              className={`${styles.searchInput3}`}
              onChange={(e, env) => {
                if (env) {
                  setSelectedRuntimeEnv(env);
                } else {
                  setSelectedRuntimeEnv({});
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Search Runtime Environment"
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const dockerCommandSection = () => {
    return (
      <>
        <Grid container spacing={3} style={{ minHeight: "20rem" }}>
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Build Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                disabled={!isEditMode}
                onClick={() =>
                  setVersionData({
                    ...versionData,
                    buildcommands: [...versionData.buildcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {versionData.buildcommands.map((buildcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    key={index}
                    name={`${index}_build`}
                    className={`${styles.input} ${!isEditMode && styles.editMode
                      }`}
                    disabled={!isEditMode}
                    value={versionData.buildcommands[index]}
                    onChange={(e) =>
                      setVersionData({
                        ...versionData,
                        buildcommand: [
                          ...versionData.buildcommands,
                          (versionData.buildcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    disabled={!isEditMode}
                    onClick={() => deleteBuildCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Run Commands
              </label>
              <IconButton
                color="inherit"
                aria-label="edit"
                edge="start"
                disabled={!isEditMode}
                onClick={() =>
                  setVersionData({
                    ...versionData,
                    runcommands: [...versionData.runcommands, ""],
                  })
                }
                className={styles.actionIcon2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            {versionData.runcommands.map((runcommand, index) => (
              <Grid
                key={index}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={11} sm={11}>
                  <input
                    type="text"
                    name={`${index}_run`}
                    className={`${styles.input} ${!isEditMode && styles.editMode
                      }`}
                    disabled={!isEditMode}
                    value={versionData.runcommands[index]}
                    onChange={(e) =>
                      setVersionData({
                        ...versionData,
                        runcommand: [
                          ...versionData.runcommands,
                          (versionData.runcommands[index] = e.target.value),
                        ],
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="inherit"
                    aria-label="edit"
                    edge="start"
                    disabled={!isEditMode}
                    onClick={() => deleteRunCommand(index)}
                    className={styles.actionIcon2}
                  >
                    <img src="/images/deleteLine.svg" alt="card" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };

  const sourceCodeSection = () => {
    return (
      <>
        {/* <ConfirmReuploadDeleteImage
          open={confirmReuploadDeleteImage}
          handleSubmit={() => {
            if (versionData?.docker?.Instances.length > 0) {
              const handleStopDockerContainer = async () => {
                setConfirmReuploadDeleteImage(false);
                setLoader(true);
                const { _status } = await StopContainer(
                  versionData?.docker?.Instances[0]?.containerid
                );

                const handleDeleteContainer = async () => {
                  const { _status } = await DeleteContainer(
                    versionData?.docker?.Instances[0].containerid
                  );

                  const handleDeleteDockerImage = async () => {
                    const { _status } = await DeleteDockerImage(
                      versionData?.docker?.imagedetails?.ImageID
                    );
                    setOpenUploadSourceCodeModal(true);
                    setLoader(false);
                  };
                  handleDeleteDockerImage();
                };

                handleDeleteContainer();
              };

              handleStopDockerContainer();
            } else {
              setLoader(true);
              setConfirmReuploadDeleteImage(false);

              const handleDeleteDockerImage = async () => {
                if (versionData?.docker?.imagedetails?.ImageID) {
                  const { _status } = await DeleteDockerImage(
                    versionData?.docker?.imagedetails?.ImageID
                  );
                  setOpenUploadSourceCodeModal(true);
                  setLoader(false);
                }
              };
              handleDeleteDockerImage();
            }
          }}
          handleClose={() => setConfirmReuploadDeleteImage(false)}
        /> */}
        <p className={styles.fieldHeading} style={{ marginTop: 10 }}>
          {repositoryData?._id
            ? `Source Code : ${repositoryData?.filename}`
            : "Source code is not uploaded"}
        </p>
        {isEditMode && checkCompPermission("Version", ["Create Repo"]) && (
          <AppButton
            color="primary"
            buttonName={repositoryData?._id ? "Re Upload" : "Upload"}
            variant="contained"
            startIcon={
              repositoryData?._id ? (
                <img src="/images/reuploadLight.svg" alt="card" />
              ) : (
                <img src="/images/bulkupload.svg" alt="card" />
              )
            }
            disabled={false}
            style={{ padding: "6px 15px", marginLeft: 0 }}
            className="btnsmall"
            onClick={() => setOpenUploadSourceCodeModal(true)}
          />
        )}
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              value={
                field.dataType === "date" && versionData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                      .unix(versionData[field.datakey])
                      .tz(userInfo?.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                          : `${userInfo?.dateFormat} ${userInfo?.time}`
                      )
                    : moment
                      .unix(versionData[field.datakey])
                      .format("DD/MM/YYYY, hh:mm:ss A")
                  : versionData[field.datakey]
              }
              autoComplete="off"
              disabled={!isEditMode || field.editable === "FALSE"}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={versionData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${!isEditMode && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                }`}
              disabled={!isEditMode || field.editable === "FALSE"}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Dropdown":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <Select
              name={field.datakey}
              value={
                versionData[field.datakey] ? versionData[field.datakey] : ""
              }
              onChange={handleChange}
              disabled={!isEditMode || field.editable === "FALSE"}
              className={`${styles.select} ${!isEditMode && styles.editMode} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === "FALSE" && styles.editMode}`}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
                : field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                versionData[field.datakey] ? versionData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      disabled={!isEditMode || field.editable === "FALSE"}
                      className={`${styles.input} ${!isEditMode && styles.editMode
                        } ${error[field.datakey]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                        }`}
                    />
                    <span
                      className={`${styles.datePickerIcon} ${!isEditMode && styles.editModeDatePicker
                        }`}
                    >
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  versionData[`${field.datakey}_unit`]
                    ? versionData[`${field.datakey}_unit`]
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditMode || field.editable === "FALSE"}
                className={`${styles.select} ${!isEditMode && styles.editMode
                  } ${error[`${field.datakey}_unit`]?.isError && styles.error} ${field.editable === "FALSE" && styles.editMode
                  }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${!isEditMode && styles.editMode} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === "FALSE" && styles.editMode}`}
                value={versionData[`${field.datakey}_value`]}
                autoComplete="off"
                disabled={!isEditMode || field.editable === "FALSE"}
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                versionData[field.datakey] ? versionData[field.datakey] : ""
              }
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {typeof field?.values === "string"
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        disabled={!isEditMode || field.editable === "FALSE"}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Version_Header_Section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                  .length === 0 ? (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem", display: "block" }}
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Version Environmentvariables":
                          return environmentVariablesSection();
                        case "Version Runtime Environment":
                          return runtimeEnvironmentSection();
                        case "Version Docker":
                          return dockerCommandSection();
                        case "SourceCode":
                          if (versionData?.microserviceType === "External") {
                            return <></>
                          } else {

                            return sourceCodeSection();
                          }
                        default:
                          return (
                            <>
                              {field.name === "Version Port" &&
                                versionData?.microserviceType === "External" ? (
                                <></>
                              ) : (
                                <Grid item xs={3} sm={3} key={field._id}>
                                  {generateField(field)}
                                </Grid>
                              )}
                            </>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout without TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Version Environment Variables":
                                return environmentVariablesSection();
                              case "Version Runtime Environment":
                                return runtimeEnvironmentSection();
                              case "Version Docker Commands":
                                return dockerCommandSection();
                              case "Version Source Code":
                                return sourceCodeSection();
                              default:
                                return (
                                  <>
                                    {field.name === "Version Port" &&
                                      versionData?.microserviceType ===
                                      "External" ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={4} sm={4} key={field._id}>
                                        {generateField(field)}
                                      </Grid>
                                    )}
                                  </>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps)(CustomFieldGroupsForVersionEdit);
