import { UiApplicationApiHelper } from "./helper/UiApplicationApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new UiApplicationApiHelper();
export class UiApplicationService {
  customApiCall = async (uri, reqData, headers, method) => {
    switch (method) {
      case "GET":
        return apiHelper.get(uri, headers);
      case "POST":
        return apiHelper.post(uri, reqData, headers);
      case "PUT":
        return apiHelper.put(uri, reqData, headers);
      case "DELETE":
        return apiHelper.delete(uri, headers);
      default:
        break;
    }
  };

  getApplications(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/ui/applications?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getApplicationsforPage() {
    return apiHelper.get(`${BASE_URL}/api/v1.0.0/ui/applications`);
  }
  getApplicationById(id) {
    return apiHelper.get(`${BASE_URL}/api/v1.0.0/ui/applications/${id}`);
  }
  addApplication(data) {
    return apiHelper.post(`${BASE_URL}/api/v1.0.0/ui/applications`, data);
  }
  editApplication(id, data) {
    return apiHelper.put(`${BASE_URL}/api/v1.0.0/ui/applications/${id}`, data);
  }
  deleteApplication(id) {
    return apiHelper.delete(`${BASE_URL}/api/v1.0.0/ui/applications/${id}`);
  }
  buildUIApplication(applicationId) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${applicationId}/build`
    );
  }
  downLoadDynamicBuild(id) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/download`
    );
  }
  getAllTemplates(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/ui/templates?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getTemplatesByfilter(filter) {
    return apiHelper.post(`${BASE_URL}/api/v1.0.0/ui/templates/filter`, filter);
  }
  getAllTemplateById(id) {
    return apiHelper.get(`${BASE_URL}/api/v1.0.0/ui/templates/${id}`);
  }
  createTemplate(data) {
    return apiHelper.post(`${BASE_URL}/api/v1.0.0/ui/templates`, data);
  }
  deletetemplate(id) {
    return apiHelper.delete(`${BASE_URL}/api/v1.0.0/ui/templates/${id}`);
  }
  getApplicationsForAppBundle(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${BASE_URL}/api/v1.0.0/ui/applications?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getAllUiApplications() {
    const uri = `${BASE_URL}/api/v1.0.0/ui/applications`;
    return apiHelper.get(uri);
  }
  // new structure
  addNewPage(id, data) {
    return apiHelper.post(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages`,
      data
    );
  }
  editPage(id, data) {
    return apiHelper.put(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages`,
      data
    );
  }
  deletePage(id, pageName) {
    return apiHelper.delete(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}`
    );
  }

  addNewComponent(id, pageName, data) {
    return apiHelper.post(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}/components`,
      data
    );
  }
  fetchAllComponents(id, pageName) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}/components`
    );
  }
  fetchAllComponentByID(id, pageName, componentId) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}/components/${componentId}`
    );
  }

  fetchAllComponentNames(id) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/components`
    );
  }

  editComponent(id, pageName, componentId, data) {
    return apiHelper.put(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}/components/${componentId}`,
      data
    );
  }
  deleteComponent(id, pageName, componentId) {
    return apiHelper.delete(
      `${BASE_URL}/api/v1.0.0/ui/applications/${id}/pages/${pageName}/components/${componentId}`
    );
  }
  //    Actions
  fetchAllActions(uiAppId) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${uiAppId}/actions`
    );
  }
  fetchActionById(uiAppId, actionId) {
    return apiHelper.get(
      `${BASE_URL}/api/v1.0.0/ui/applications/${uiAppId}/actions/${actionId}`
    );
  }
  addAction(uiAppId, data) {
    return apiHelper.post(
      `${BASE_URL}/api/v1.0.0/ui/applications/${uiAppId}/actions`,
      data
    );
  }
  updateAction(uiAppId, actionId, data) {
    return apiHelper.put(
      `${BASE_URL}/api/v1.0.0/ui/applications/${uiAppId}/actions/${actionId}`,
      data
    );
  }
  deleteActionById(uiAppId, actionId) {
    return apiHelper.delete(
      `${BASE_URL}/api/v1.0.0/ui/applications/${uiAppId}/actions/${actionId}`
    );
  }
  getDeploymentsUiApplicationsById(id) {
    const uri = `${BASE_URL}/api/v1.0.0/ui/applications/${id}/deploymentdata`;
    return apiHelper.get(uri);
  }
}