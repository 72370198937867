import { ApiHelper } from "./helper/ApiHelper";
import { REPOSITORY, CONTAINER, DATAMODEL } from "./helper/config";

const apiHelper = new ApiHelper();

export class DataModelService {
  // getAllDataModals() {
  //   const uri = `${DATAMODEL}/api/repo/repositories/datamodels/`;
  //   return apiHelper.get(uri);
  // }

  getAllDataModals(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${DATAMODEL}/api/repo/datamodels?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }

  deleteDataModelById(dataModelId) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}`;
    return apiHelper.delete(uri);
  }

  // subscribe and unsubscribe

  // subscribeDataModel(dataModelId, resource, resourceinstance) {
  //   const uri = `${DATAMODEL}/api/v1.0.0/notifications/group/datamodel/${resource}_${resourceinstance}/add`;
  //   return apiHelper.post(uri, {
  //     dataModelId: dataModelId,
  //   });
  // }
  // unsubscribeDataModel(dataModelId, resource, resourceinstance) {
  //   const uri = `${BASE_URL}/api/v1.0.0/notifications/group/datamodel/${resource}_${resourceinstance}/remove/${dataModelId}`;
  //   return apiHelper.delete(uri);
  // }

  // subscribe and unsubscribe

  subscribeDataModel(email, userID, dataModelId) {
    const uri = `${DATAMODEL}/api/v1.0.0/notifications/group/datamodel_${dataModelId}/add`;
    return apiHelper.post(uri, {
      email: email,
      userid: userID,
    });
  }
  unsubscribeDataModel(userID, dataModelId) {
    const uri = `${DATAMODEL}/api/v1.0.0/notifications/group/datamodel_${dataModelId}/remove/${userID}`;
    return apiHelper.delete(uri);
  }
  // getSubscribedDataModel(dataModelId) {
  //   const uri = `${DATAMODEL}/api/v1.0.0/notifications/users/${dataModelId}/groups`;
  //   return apiHelper.get(uri);
  // }

  // block and unblock

  blockDataModel(dataModelID) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelID}/action/inactive`;
    return apiHelper.post(uri, {});
  }
  UnblockDataModel(dataModelID) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelID}/action/active`;
    return apiHelper.post(uri, {});
  }

  addDataModelDocument(dataModelId, dataModelData) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}/files`;
    return apiHelper.put(uri, dataModelData);
  }

  createLargeDataModel(dataModel) {
    const uri = `${DATAMODEL}/api/repo/datamodels/largefile`;
    return apiHelper.post(uri, dataModel);
  }

  startChunkUpload(chunkFormData) {
    const uri = `${DATAMODEL}/api/repo/datamodels/largefile/chunks`;
    return apiHelper.postFormData(uri, chunkFormData);
  }

  uploadChunkStart(dataModelId, fileName) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}/file/${fileName}/start`;
    return apiHelper.get(uri);
  }

  uploadFileCompleted(dataModelId, fileToUpload) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}/file/${fileToUpload}/close`;
    return apiHelper.get(uri);
  }

  deleteDataModelDocument(dataModelId, dataModelFileName) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}/file/${dataModelFileName}`;
    return apiHelper.delete(uri, dataModelFileName);
  }

  addDataModel(model) {
    const uri = `${DATAMODEL}/api/repo/datamodels`;
    return apiHelper.post(uri, model);
  }
  getDataModelById(dataModelId) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}`;
    return apiHelper.get(uri);
  }
  getAiModelDependenciesById(dataModelId) {
    const uri = `${DATAMODEL}/api/v1.0.0/aimodels/${dataModelId}/entities`;
    return apiHelper.get(uri)
  }

  editDataModel(dataModelId, dataModelData) {

    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}`;
    return apiHelper.put(uri, dataModelData);
  }

  addLargeFileToDataModel(dataModelId, dataModelData) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${dataModelId}/largefiles`;
    return apiHelper.put(uri, dataModelData);
  }

  editService(serviceId, serviceData) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${serviceId}`;
    return apiHelper.put(uri, serviceData);
  }
  deleteService(serviceId) {
    const uri = `${DATAMODEL}/api/repo/datamodels/${serviceId}`;
    return apiHelper.delete(uri);
  }
  getServicesListByProject(projectId) {
    const uri = `${DATAMODEL}/api/repo/datamodels?filters={"projectID":"${projectId}"}`;
    return apiHelper.get(uri);
  }
  getServicesListByServiceGroup(groupId) {
    const uri = `${DATAMODEL}/api/repo/datamodels?filters={"servicegroupID":"${groupId}"}`;
    return apiHelper.get(uri);
  }
  getAllVersions(serviceId, currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${DATAMODEL}/api/repo/datamodels/${serviceId}/versions?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  addVersion(serviceId, versionData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions`;
    return apiHelper.post(uri, versionData);
  }
  getVersionById(serviceId, versionId) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}`;
    return apiHelper.get(uri);
  }
  editVersion(serviceId, versionId, versionData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}`;
    return apiHelper.put(uri, versionData);
  }
  editBuildCommands(serviceId, versionId, buildCommands) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/dockers/commands/buildcommands`;
    return apiHelper.put(uri, buildCommands);
  }
  editRunCommands(serviceId, versionId, runCommands) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/dockers/commands/runcommands`;
    return apiHelper.put(uri, runCommands);
  }
  deleteVersion(serviceId, versionId) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}`;
    return apiHelper.delete(uri);
  }
  getAllAPIs(serviceId, versionId, currentPage, sort) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/apis?page=${currentPage}&sort=${sort}&size=10`;
    return apiHelper.get(uri);
  }
  getAPIById(serviceId, versionId, apiId) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.get(uri);
  }
  addAPI(serviceId, versionId, apiData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/apis`;
    return apiHelper.post(uri, apiData);
  }
  editAPI(serviceId, versionId, apiId, apiData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.put(uri, apiData);
  }
  deleteAPI(serviceId, versionId, apiId) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/apis/${apiId}`;
    return apiHelper.delete(uri);
  }
  getAllBoilerPlates() {
    const uri = `${REPOSITORY}/api/repo/repositories/boilerplates`;
    return apiHelper.get(uri);
  }
  getAllRuntimeEnvironment() {
    const uri = `${CONTAINER}/api/container/environments`;
    return apiHelper.get(uri);
  }
  addRuntimeEnvironment(serviceId, versionId, environmentData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}`;
    return apiHelper.post(uri, environmentData);
  }
  getRepositoryDetailsByDATAMODELVersion(serviceId, versionId) {
    const uri = `${REPOSITORY}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}`;
    return apiHelper.get(uri);
  }
  uploadLocalSourceCode(souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories`;
    return apiHelper.post(uri, souceCodeData);
  }
  reUploadLocalSourceCode(repoId, souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories/${repoId}`;
    return apiHelper.put(uri, souceCodeData);
  }
  uploadSourceCodeByGit(souceCodeData) {
    const uri = `${REPOSITORY}/api/repo/repositories/_download`;
    return apiHelper.post(uri, souceCodeData);
  }
  createDockerFile(serviceId, versionId) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/build`;
    return apiHelper.get(uri);
  }
  runContainer(serviceId, versionId, containerData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/${serviceId}/versions/${versionId}/Containers`;
    return apiHelper.post(uri, containerData);
  }
  simulateAPI(simulateData) {
    const uri = `${DATAMODEL}/api/simulation/simulate/DATAMODEL`;
    return apiHelper.post(uri, simulateData);
  }
  copyService(serviceData) {
    const uri = `${DATAMODEL}/api/repo/repositories/datamodels/copy`;
    return apiHelper.post(uri, serviceData);
  }
}
