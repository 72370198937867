import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Backdrop,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { v4 as uuidv4 } from "uuid";
import callNodeStyle from "./styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { addInputOutputDataForNodes } from "../../../redux/actions/orchestrationAction";
import { CommonServices } from "../../../services/commonServices";
import { AppButton } from "../../common/Button";
import { getImageIDFromServiceDetails } from "../../../utils/orchestration/applicationUtils";
import { getContainerNameForApplication_ } from "../../../utils/orchestration/orchestrationUtils";
import {
  fetchNodeDetails,
  editNodeData,
  addNodeData,
  getKeyValueFromStringJSON,
  // getJSONObjFromKeyValueArray,
  getJSONObjFromKeyValueArrayForSync
} from "../../../utils/orchestration/orchestrationUtils";
import { getFieldGroupDetails_ } from "../../../utils/orchestration/applicationUtils";
import CallNodeInput from "../CallNodeModal/CallNodeInput";
// import OutputTree from "./OutputTree";
import CustomChips from "../../common/Chips";
import { MicroServicesAPIServices } from "../../../services/microservices";

const CommonService = new CommonServices();
const MicroService = new MicroServicesAPIServices();

const temp_data = [{ id: uuidv4(), key: "", value: "" }];

var baseUrlEdit = false;

function CallNodeModal(props) {
  const {
    isOpen,
    templateid,
    services,
    call_node_obj,
    applicationID,
    deploymentServices,
  } = props;
  const { edit, nodeid } = props;
  const [serviceData, setServiceData] = useState({});
  const [apiData, setApiData] = useState({});

  const [apiList, setApiList] = useState([]);

  const [editorValue, setEditorValue] = useState("");
  const [headerList, setHeaderList] = useState(temp_data);
  const [inputList, setInputList] = useState(temp_data);
  const [inputFieldGroupDetails, setInputFieldGroupDetails] = useState({});
  const [outputFieldGroupDetails, setOutputFieldGroupDetails] = useState({
    fieldgroupname: "",
    status: false,
    type: "",
    fields: [],
  });
  const [activetab, setActivetab] = useState("header");
  const [activeInputType, setActiveInputType] = useState("text");

  const [callDataObj, setCallDataObj] = useState({
    name: "",
    endpoint: "",
    baseurl: "",
    method: "GET",
    topic: "",
    message: "",
    servicetype: "rest",
  });

  const [serviceInputValue, setServiceInputValue] = useState("");
  const [apiInputValue, setApiInputValue] = useState("");

  const [errorObj, setErrorObj] = useState({
    name: false,
    endpoint: false,
    method: false,
    service_selected: false,
    api_selected: false,
    message: false,
    topic: false,
    brokerText: false,
  });

  const [isLoader, setIsloader] = useState(false);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  // const [baseurlData, setBaseUrlData] = useState({
  //   container_name: "",
  //   port: ""
  // })
  const classes = callNodeStyle();

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const descriptionElementRef = React.useRef(null);

  const handleClose = () => {
    if (edit) {
      props.onEditCallClose();
    } else {
      props.onCloseClick();
    }
  };

  //handle edit icon click functionining
  const fetchData = async () => {
    if (edit) {
      const { _msg, _status, data } = await fetchNodeDetails(
        templateid,
        nodeid
      );
      if (_status === 200) {
        const {
          name,
          method,
          endpoint,
          baseurl,
          headers,
          input,
          serviceid,
          versionid,
          apiid,
          topic,
          message,
          servicetype,
          brokers,
          serviceType2,
        } = data;
        var tempBrokers = brokers ? brokers.split(",") : [];
        setCallDataObj((prevState) => ({
          ...prevState,
          name: name ? name : "",
          endpoint: endpoint ? endpoint : "",
          baseurl: baseurl ? baseurl : "",
          method: method ? method : "",
          topic: topic ? topic : "",
          message: message ? message : "",
          servicetype: servicetype ? servicetype : "rest",
          broker: serviceType2 === "External" ? brokers : "",
        }));
        var selectedBrokers = [];
        tempBrokers &&
          tempBrokers.length > 0 &&
          tempBrokers.forEach((item) => {
            if (servicetype === "kafka" || servicetype === "mqtt") {
              if (item.split(":").length === 2) {
                selectedBrokers.push({
                  id: uuidv4(),
                  name: item.split(":")[0],
                });
              }
            } else if (servicetype === "rabbitmq") {
              var split1 = item.split("@");
              if (split1.length === 2) {
                var split2 = split1[1].split(":");
                if (split2.length === 2) {
                  selectedBrokers.push({
                    id: uuidv4(),
                    name: split2[0],
                  });
                }
              }
            }
          });
        setSelectedBrokers(selectedBrokers);
        if (serviceid && versionid) {
          var servicedetail = services?.filter(
            (item) => item.mid === serviceid && item.vid === versionid
          );
          if (servicedetail?.length > 0) {
            handleServiceSelect(servicedetail[0], apiid);
          }
        }
        setCallDataObj((prevState) => ({
          ...prevState,
          name: name ? name : "",
          endpoint: endpoint ? endpoint : "",
          baseurl: baseurl ? baseurl : "",
          method: method ? method : "",
        }));
        setHeaderList(getKeyValueFromStringJSON(JSON?.parse(headers)));
        setInputList(getKeyValueFromStringJSON(JSON?.parse(input)));
        setEditorValue(JSON.stringify(JSON?.parse(input), null, "\t"));
        baseUrlEdit = true;
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  // //get field group details
  // const getFieldGroupDetails_ = async (id) => {
  //   const { _msg, _status, data } = await getFieldGroupDetails(id);
  //   if (_status === 200) {
  //     if (data) {
  //       return data.fields
  //     } else {
  //       return []
  //     }
  //   } else {
  //     enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
  //     return []
  //   }
  // }
  const getImageDetails = async (mid, vid) => {
    const { _status, data } = await getImageIDFromServiceDetails(mid, vid);
    if (_status === 200) {
      const { imageID } = data;
      return imageID;
    } else {
      return "";
    }
  };

  const handleServiceSelect = async (item, apiid) => {
    // const item = event.target.value;
    setIsloader(true);
    const serviceType = await getServiceDetails(item.mid);
    setServiceData({ ...item, serviceType: serviceType });
    setErrorObj((prevState) => ({ ...prevState, service_selected: false }));
    await getAPIList(item.mid, item.vid, apiid);
    const imageID = await getImageDetails(item.mid, item.vid);
    if (imageID && serviceType === "Internal") {
      const { _status, data } = await getContainerNameForApplication_(
        applicationID,
        imageID
      );
      if (_status === 200) {
        const { containerName } = data;
        const tempContainerName = containerName.replaceAll("_", ".");
        if (!baseUrlEdit) {
          setCallDataObj((prevState) => ({
            ...prevState,
            baseurl: tempContainerName
              ? "".concat(
                  "http://${env.",
                  tempContainerName,
                  ".IP}:${env.",
                  tempContainerName,
                  ".PORT}"
                )
              : "",
            endpoint: "",
            method: "GET",
          }));
        }
        baseUrlEdit = false;
      }
    } else if(serviceType === "External"){
        if (!baseUrlEdit) {
          setCallDataObj((prevState) => ({
            ...prevState,
            baseurl: "",
            endpoint: "",
            method: "GET",
          }));   
        }
        baseUrlEdit = false;
      } 
    setIsloader(false);
  };

  const getServiceDetails = async (mid) => {
    try {
      const res_data = await MicroService.getServiceById(mid);
      const { data } = res_data.data;
      if (data) {
        return data?.serviceType ? data?.serviceType : "Internal";
      } else {
        enqueueSnackbar("No Service exists.", { variant: "info" });
        return "Internal";
      }
    } catch (error) {
      const { _msg } = error.response.data;
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const getAPIList = async (mid, vid, apiid) => {
    try {
      const res_data = await CommonService.getAPIFromServiceIDVersionID(
        mid,
        vid
      );
      const { data } = res_data.data;
      if (data) {
        setApiList(data ? data : []);
        if (apiid) {
          var apidetails = data.filter((item) => item._id === apiid);
          if (apidetails?.length > 0) {
            setApiData(apidetails[0]);
          }
        }
      } else {
        enqueueSnackbar("No API exists for selected microservice.", {
          variant: "info",
        });
      }
    } catch (error) {
      const { _msg } = error.response.data;
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const handleApiSelect = async (api_data) => {
    var tempInputList = [];
    var tempEditorObj = {};
    // const api_data = event.target.value;
    const { method, path, headers, input, output, type, topic, sys_broker } =
      api_data;
    var header_list = getKeyValueFromStringJSON(headers);
    setCallDataObj((prevState) => ({
      ...prevState,
      endpoint: path ? path : "",
      method: method ? method : "",
      topic: topic ? topic : "",
      servicetype: !type || type === "http" ? "rest" : type,
      broker: sys_broker ? sys_broker : "",
    }));
    setErrorObj((prevState) => ({
      ...prevState,
      api_selected: false,
      baseurl: false,
      method: false,
      endpoint: false,
      topic: false,
    }));
    setApiData(api_data);
    // setHeaderList(header_list ? header_list : []);
    setHeaderList(
      header_list?.length !== 0
        ? header_list
        : method === "POST" || method === "PUT"
        ? [{ id: uuidv4(), key: "Content-Type", value: "application/json" }]
        : []
    );
    setIsloader(true);
    if (input && input.fieldGroupid) {
      const { _msg, _status, data } = await getFieldGroupDetails_(
        input.fieldGroupid
      );
      if (_status === 200) {
        setInputFieldGroupDetails({
          fieldgroupname: data.fieldgroupname,
          status: data.status,
          type: data.type,
          fields: data.fields.filter(
            (item) => item.type !== "array" && item.type !== "object"
          ),
        });
        data.fields &&
          data.fields.length > 0 &&
          data.fields.forEach((item) => {
            if (item.type === "array") {
              if(item?.fields?.length > 0 ) {
                const subArray = [];
                item?.fields?.forEach(subItem => {
                  if(subItem?.type === 'string'){
                    subArray.push({[subItem?.name] : ""})
                  } else if(subItem?.type === "array"){
                    subArray.push({[subItem?.name] : []})
                  } else if(subItem?.type === "object"){
                    subArray.push({[subItem?.name] : {}})
                  } else if(subItem?.type === "boolean"){
                    subArray.push({[subItem?.name] : "boolean"})
                  } else if(subItem?.type === "integer"){
                    subArray.push({[subItem?.name] : 0})
                  } 
                })
                tempEditorObj[item.name] = subArray; 
              } else {
                tempEditorObj[item.name] = [];
              } 
            } else if (item.type === "string") {
              tempEditorObj[item.name] = "";
            } else if (item.type === "object") {
              if(item?.fields?.length > 0 ) {
                const subObject = {};
                item?.fields?.forEach(subItem => {
                  if(subItem?.type === 'string'){
                    subObject[subItem?.name] = "";
                  } else if(subItem?.type === "array"){
                    subObject[subItem?.name] = [];
                  } else if(subItem?.type === "object"){
                    subObject[subItem?.name] = {};
                  } else if(subItem?.type === "boolean"){
                    subObject[subItem?.name] = "boolean"
                  } else if(subItem?.type === "integer"){
                    subObject[subItem?.name] = 0;
                  } 
                })
                tempEditorObj[item.name] = subObject; 
              } else {
                tempEditorObj[item.name] = {};
              } 
            } else if (item.type === "boolean") {
              tempEditorObj[item.name] = "boolean";
            } else if (item.type === "integer") {
              tempEditorObj[item.name] = 0;
            }
            if (item.type !== "array" && item.type !== "object") {
              tempInputList.push({
                id: uuidv4(),
                key: item.name,
                value: "",
                type: item.type,
                status: item.status,
              });
            }
          });
        setInputList(tempInputList);
        // var intialEditorValue = getJSONObjFromKeyValueArrayForSync(tempEditorList);
        var tempEditorValue = JSON.stringify(tempEditorObj, null, "\t");
        setEditorValue(tempEditorValue);
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    } else {
      setInputList(temp_data);
      setEditorValue("");
    }
    if (output && output.fieldGroupid) {
      const { _status, data } = await getFieldGroupDetails_(
        output.fieldGroupid
      );
      if (_status === 200) {
        setOutputFieldGroupDetails({
          fieldgroupname: data.fieldgroupname,
          status: data.status,
          type: data.type,
          fields: data.fields.filter(
            (item) => item.type !== "array" && item.type !== "object"
          ),
        });
      }
    }
    setIsloader(false);
  };

  const handleHeaderChange = (val) => {
    setHeaderList(val);
  };

  const handleInputChange = (val) => {
    setInputList(val);
    var changedEditorValue = getJSONObjFromKeyValueArrayForSync(val);
    var finalEditorValue = JSON.stringify(changedEditorValue, null, '\t');
    setEditorValue(finalEditorValue)
  };

  const checkNameForm = (nameStr) => {
    // var nameStr_ = nameStr.replace(/\s/g, '');
    var nameStr_ = nameStr.replace(/[^a-zA-Z0-9_ ]/g, "");
    return nameStr_;
  };

  const handleNameChange = (val) => {
    var filteredName = checkNameForm(val);
    handleTextValueChange("name", filteredName);
  };

  const handleTextValueChange = (keyval, value) => {
    setCallDataObj({
      ...callDataObj,
      [keyval]: value,
    });
    setErrorObj((prevState) => ({
      ...prevState,
      [keyval]: false,
    }));
  };

  const handleEditorValueChange = (val) => {
    setEditorValue(val);
    var jsonConversion = getKeyValueFromStringJSON(JSON.parse(val));
    setInputList(jsonConversion)
  };

  const handleFieldSelected = (e, input) => {
    if (input) {
      setErrorObj((prevState) => ({
        ...prevState,
        brokerText: false,
      }));
    }
    if (input && input.container_name) {
      setSelectedBrokers((prevState) => [
        ...prevState,
        {
          id: uuidv4(),
          name: input.container_name,
        },
      ]);
    } else if (input && typeof input === "string") {
      setSelectedBrokers((prevState) => [
        ...prevState,
        {
          id: uuidv4(),
          name: input,
        },
      ]);
    }
  };

  const handleChipDelete = (data) => {
    setSelectedBrokers((prevState) => {
      var tempBrokers =
        prevState.length > 0 && prevState.filter((item) => item.id !== data.id);
      return tempBrokers;
    });
  };

  const handleSubmit = async () => {
    var errorFlag = false;
    var tempErrorObj = {};
    var requiredFields = [];
    var req_data = {};
    if (callDataObj.servicetype === "rest") {
      requiredFields = ["name", "endpoint", "method"];
    } else {
      requiredFields = ["name", "message", "topic"];
    }

    for (var obj of requiredFields) {
      if (callDataObj[obj] === "") {
        tempErrorObj[obj] = true;
        errorFlag = true;
      }
    }
    setErrorObj((prevState) => ({
      ...prevState,
      ...tempErrorObj,
    }));

    if (
      callDataObj.servicetype === "kafka" ||
      callDataObj.servicetype === "rabbitmq" ||
      callDataObj.servicetype === "mqtt"
    ) {
      if (
        serviceData.serviceType === "Internal" &&
        selectedBrokers.length === 0
      ) {
        setErrorObj((prevState) => ({
          ...prevState,
          brokerText: true,
        }));
        errorFlag = true;
      }
    }

    if (callDataObj.servicetype === "rest") {
      if (Object.keys(serviceData).length === 0) {
        setErrorObj((prevState) => ({
          ...prevState,
          service_selected: true,
        }));
        errorFlag = true;
      }
      if (Object.keys(apiData).length === 0) {
        setErrorObj((prevState) => ({
          ...prevState,
          api_selected: true,
        }));
        errorFlag = true;
      }
    }

    if (!errorFlag) {
      var headers = {};
      var input_obj = {};
      setIsloader(true);
      if (activeInputType === "text") {
        inputList &&
          inputList.length > 0 &&
          inputList.forEach((obj) => {
            if (obj.key !== "") {
              var tempval = obj.value ? obj.value : "";
              input_obj[obj.key] = tempval;
            }
          });
        input_obj = JSON.stringify(input_obj);
      } else {
        input_obj = editorValue;
      }
      headerList &&
        headerList.length > 0 &&
        headerList.forEach((obj) => {
          if (obj.key !== "" && obj.value !== "") {
            headers[obj.key] = obj.value;
          }
        });

      if (edit) {
        req_data =
          callDataObj.servicetype === "rest"
            ? {
                name: callDataObj.name,
                method: callDataObj.method,
                type: "CallNode",
                baseurl: callDataObj.baseurl,
                endpoint: callDataObj.endpoint,
                input: input_obj,
                headers: JSON.stringify(headers),
                servicetype: "rest",
                serviceid: serviceData?.mid,
                versionid: serviceData?.vid,
                apiid: apiData?._id,
              }
            : {
                name: callDataObj.name,
                servicetype: callDataObj.servicetype,
                serviceType2: serviceData.serviceType,
                topic: callDataObj.topic,
                type: "CallNode",
                message: callDataObj.message,
                brokers:
                  serviceData.serviceType === "External"
                    ? callDataObj.servicetype === "rabbitmq"
                      ? `amqp://${apiData?.config?.sys_rabbitmq_username}:${apiData?.config?.sys_rabbitmq_password}@${callDataObj.broker}`
                      : callDataObj.broker
                    : selectedBrokers.length > 0
                    ? selectedBrokers
                        .map((item) =>
                          callDataObj.servicetype === "kafka" ||
                          callDataObj.servicetype === "mqtt"
                            ? `${item.name}:9092`
                            : `amqp://guest:guest@${item.name}:5672`
                        )
                        .join(",")
                    : "",
                serviceid: serviceData?.mid,
                versionid: serviceData?.vid,
                apiid: apiData?._id,
              };
        if (serviceData.serviceType === "External") {
          req_data["config"] = JSON.stringify(apiData?.config);
        }
        const { _msg, _status, data } = await editNodeData(
          nodeid,
          templateid,
          req_data
        );
        if (_status === 201) {
          setIsloader(false);
          props.addInputOutputDataForNodes(
            {
              node_name: callDataObj.name,
              type: "call_data_node",
              id: data.nodeid,
              inputFields: {
                ...inputFieldGroupDetails,
                fields: inputList.map((field_) => ({
                  name: field_.key,
                  type: field_.type,
                  status: field_.status,
                  value: field_.value,
                })),
              },
              outputFields: outputFieldGroupDetails,
              data: {
                node_name: callDataObj.name,
                nodeid: nodeid,
              },
            },
            true,
            false
          );

          props.onEditCallSubmit({
            ...data,
            name: callDataObj.name,
            inputPayload: input_obj,
            servicetype: callDataObj.servicetype,
          });
        } else {
          setIsloader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      } else {
        const { x, y } = call_node_obj["position"];
        req_data =
          callDataObj.servicetype === "rest"
            ? {
                name: callDataObj.name,
                method: callDataObj.method,
                type: "CallNode",
                baseurl: callDataObj.baseurl,
                endpoint: callDataObj.endpoint,
                input: input_obj,
                headers: JSON.stringify(headers),
                posX: x,
                posY: y,
                servicetype: "rest",
                serviceid: serviceData.mid,
                versionid: serviceData.vid,
                apiid: apiData._id,
              }
            : {
                name: callDataObj.name,
                type: "CallNode",
                servicetype: callDataObj.servicetype,
                serviceType2: serviceData.serviceType,
                topic: callDataObj.topic,
                message: callDataObj.message,
                brokers:
                  serviceData.serviceType === "External"
                    ? callDataObj.servicetype === "rabbitmq"
                      ? `amqp://${apiData?.config?.sys_rabbitmq_username}:${apiData?.config?.sys_rabbitmq_password}@${callDataObj.broker}`
                      : callDataObj.broker
                    : selectedBrokers.length > 0
                    ? selectedBrokers
                        .map((item) =>
                          callDataObj.servicetype === "kafka" ||
                          callDataObj.servicetype === "mqtt"
                            ? `${item.name}:9092`
                            : `amqp://guest:guest@${item.name}:5672`
                        )
                        .join(",")
                    : "",
                serviceid: serviceData.mid,
                versionid: serviceData.vid,
                apiid: apiData._id,
                posX: x,
                posY: y,
              };
        if (serviceData.serviceType === "External") {
          req_data["config"] = JSON.stringify(apiData?.config);
        }
        const { _msg, data, _status } = await addNodeData(templateid, req_data);
        if (_status === 201) {
          setIsloader(false);
          props.addInputOutputDataForNodes({
            node_name: callDataObj.name,
            type: "call_data_node",
            position: {
              x: x,
              y: y,
            },
            id: data.nodeid,
            inputFields: {
              ...inputFieldGroupDetails,
              fields: inputList.map((field_) => ({
                name: field_.key,
                type: field_.type,
                status: field_.status,
                value: field_.value,
              })),
            },
            outputFields: outputFieldGroupDetails,
            data: {
              node_name: callDataObj.name,
              nodeid: data.nodeid,
            },
          });
          props.onSubmitClick({
            ...data,
            name: callDataObj.name,
            input_field_details: inputFieldGroupDetails.fields
              ? inputFieldGroupDetails.fields
              : [],
            output_field_details: outputFieldGroupDetails.fields
              ? outputFieldGroupDetails.fields
              : [],
            inputPayload: input_obj ? input_obj : "",
            servicetype: callDataObj.servicetype,
          });
        } else {
          setIsloader(false);
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
    }
  };

  return (
    <div>
      <Dialog
        onEnter={fetchData}
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        maxWidth="xl"
      >
        <Backdrop
          className={classes.backdrop}
          open={isLoader}
          onClick={() => setIsloader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="scroll-dialog-title" style={{ padding: "0px 0px" }}>
          <div className={classes.section1} style={{ padding: "13px 20px" }}>
            <img src="/images/Call.png" alt="Icon" height={34} width={34} />
            <text
              className={classes.heading}
              style={{ fontSize: 17, paddingLeft: 10 }}
            >
              Call Node
            </text>
          </div>
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{ padding: "3px 24px", height: "30rem", width: "52rem" }}
        >
          <div className={classes.line2}>
            <div className={classes.name}>
              <p className={classes.label}>
                Name <text className={classes.star}>*</text>
              </p>
              <input
                style={errorObj.name ? { borderColor: "red" } : {}}
                className={classes.value}
                type={"text"}
                name="name"
                value={callDataObj.name}
                onChange={(event) => handleNameChange(event.target.value)}
              />
              {errorObj.name ? (
                <text className={classes.error_text}>Invalid Node Name</text>
              ) : (
                ""
              )}
            </div>
            <div className={classes.name}>
              <p className={classes.label}>
                Service <text className={classes.star}>*</text>
              </p>
              <Autocomplete
                value={serviceData}
                inputValue={serviceInputValue}
                style={errorObj.service_selected ? { borderColor: "red" } : {}}
                options={services}
                getOptionLabel={(option) =>
                  option.microservicename ? option.microservicename : ""
                }
                // getOptionSelected={(opt, val) => val === "" ? false : true}
                className={classes.searchInput3}
                onChange={(event, newValue) => {
                  handleServiceSelect(newValue);
                  setApiInputValue("");
                  }
                }
                onInputChange={(event, newVal) => setServiceInputValue(newVal)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Service"
                  />
                )}
              />
              {errorObj.service_selected ? (
                <text className={classes.error_text}>
                  Invalid Micro-Service Name
                </text>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={classes.line3}>
            <div className={classes.name}>
              <p className={classes.label}>
                API <text className={classes.star}>*</text>
              </p>
              <Autocomplete
                value={apiData}
                inputValue={apiInputValue}
                style={errorObj.api_selected ? { borderColor: "red" } : {}}
                options={apiList}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                // getOptionSelected={(opt, val) => val === "" ? false : true}
                className={classes.searchInput3}
                onChange={(event, newValue) => handleApiSelect(newValue)}
                onInputChange={(event, newVal) => setApiInputValue(newVal)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select API"
                  />
                )}
              />
              {errorObj.api_selected ? (
                <text className={classes.error_text}>Invalid API Name</text>
              ) : (
                ""
              )}
            </div>
            <div>
              <p className={classes.label}>
                Service Type <text className={classes.star}>*</text>
              </p>
              <FormControl className={classes.formControl}>
                <Select
                  style={{ marginLeft: 0 }}
                  value={callDataObj.servicetype}
                  onChange={(e) =>
                    handleTextValueChange("servicetype", e.target.value)
                  }
                  className={classes.select}
                >
                  <MenuItem value={"rest"}>REST</MenuItem>
                  <MenuItem value={"rabbitmq"}>RabbitMq</MenuItem>
                  <MenuItem value={"kafka"}>Kafka</MenuItem>
                  <MenuItem value={"mqtt"}>Mqtt</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.line4}>
            {(callDataObj.servicetype === "kafka" ||
              callDataObj.servicetype === "rabbitmq" ||
              callDataObj.servicetype === "mqtt") && (
              <div className={classes.name}>
                <p className={classes.label}>
                  Topic <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.topic ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type={"text"}
                  name="topic"
                  value={callDataObj.topic}
                  onChange={(event) =>
                    handleTextValueChange("topic", event.target.value)
                  }
                />
                {errorObj.topic ? (
                  <text className={classes.error_text}>Invalid Topic</text>
                ) : (
                  ""
                )}
              </div>
            )}
            {(callDataObj.servicetype === "kafka" ||
              callDataObj.servicetype === "rabbitmq" ||
              callDataObj.servicetype === "mqtt") && (
              <div className={classes.name}>
                <p className={classes.label}>
                  Message <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.message ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type={"text"}
                  name="message"
                  value={callDataObj.message}
                  onChange={(event) =>
                    handleTextValueChange("message", event.target.value)
                  }
                />
                {errorObj.message ? (
                  <text className={classes.error_text}>Invalid Message</text>
                ) : (
                  ""
                )}
              </div>
            )}
            {callDataObj.servicetype === "rest" && (
              <div className={classes.name}>
                <p className={classes.label}>Base URL</p>
                <input
                  className={classes.value}
                  type="text"
                  name="baseurl"
                  value={callDataObj.baseurl}
                  onChange={(event) =>
                    handleTextValueChange("baseurl", event.target.value)
                  }
                />
                {/* {errorObj.baseurl ? <text className={classes.error_text}>Invalid Endpoint</text> : ""} */}
              </div>
            )}
            {callDataObj.servicetype === "rest" && (
              <div className={classes.name}>
                <p className={classes.label}>
                  Method <text className={classes.star}>*</text>
                </p>
                <FormControl className={classes.formControl}>
                  {/* <NativeSelect style={errorObj.method ? { borderColor: "red" } : {}}
                  value={callDataObj.method}
                  onChange={(e) => handleTextValueChange("method", e.target.value)}
                  name="age"
                  className={classes.select}
                  inputProps={{ "aria-label": "age" }}
                >
                  <option value={"GET"}>GET</option>
                  <option value={"POST"}>POST</option>
                  <option value={"PUT"}>PUT</option>
                  <option value={"DELETE"}>DELETE</option>
                </NativeSelect> */}
                  <Select
                    style={errorObj.method ? { borderColor: "red" } : {}}
                    value={callDataObj.method}
                    onChange={(e) =>
                      handleTextValueChange("method", e.target.value)
                    }
                    className={classes.select}
                  >
                    <MenuItem value={"GET"}>GET</MenuItem>
                    <MenuItem value={"POST"}>POST</MenuItem>
                    <MenuItem value={"PUT"}>PUT</MenuItem>
                    <MenuItem value={"DELETE"}>DELETE</MenuItem>
                  </Select>
                </FormControl>
                {errorObj.method ? (
                  <text className={classes.error_text}>
                    Invalid Method Name
                  </text>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {(callDataObj.servicetype === "kafka" ||
            callDataObj.servicetype === "rabbitmq" ||
            callDataObj.servicetype === "mqtt") && (
            <>
              {serviceData.serviceType === "External" ? (
                <div style={{ marginLeft: 20 }}>
                  <p className={classes.label}>
                    Broker <text className={classes.star}>*</text>
                  </p>
                  <input
                    style={errorObj.message ? { borderColor: "red" } : {}}
                    className={classes.value}
                    type={"text"}
                    name="broker"
                    value={callDataObj.broker}
                    onChange={(event) =>
                      setCallDataObj({
                        ...callDataObj,
                        broker: event.target.value,
                      })
                    }
                  />
                </div>
              ) : (
                <div style={{ marginLeft: 20 }}>
                  <p className={classes.label}>
                    Brokers <text className={classes.star}>*</text>
                  </p>
                  <Autocomplete
                    style={
                      errorObj.brokerText ? { border: "1px solid red" } : {}
                    }
                    options={deploymentServices}
                    getOptionLabel={(option) => option.container_name}
                    className={classes.searchInput3}
                    onChange={handleFieldSelected}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Fields"
                      />
                    )}
                    freeSolo
                  />
                  {errorObj.brokerText && (
                    <text className={classes.error_text}>
                      No Brokers Selected
                    </text>
                  )}
                  {selectedBrokers.length > 0 && (
                    <CustomChips
                      chipsData={selectedBrokers}
                      handleDelete={handleChipDelete}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {callDataObj.servicetype === "rest" && (
            <div className={classes.line4}>
              <div className={classes.name}>
                <p className={classes.label}>
                  End Point <text className={classes.star}>*</text>
                </p>
                <input
                  style={errorObj.endpoint ? { borderColor: "red" } : {}}
                  className={classes.value}
                  type={"text"}
                  name="endpoint"
                  value={callDataObj.endpoint}
                  onChange={(event) =>
                    handleTextValueChange("endpoint", event.target.value)
                  }
                />
                {errorObj.endpoint ? (
                  <text className={classes.error_text}>Invalid Endpoint</text>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          {callDataObj.servicetype === "rest" && (
            <CallNodeInput
              activetab={activetab}
              changeActivetab={(val) => setActivetab(val)}
              activeInputType={activeInputType}
              changeActiveInputType={(val) => setActiveInputType(val)}
              inputList={inputList}
              onEditorValueChange={(val) => handleEditorValueChange(val)}
              headerList={headerList}
              setHeaderList={(val) => handleHeaderChange(val)}
              setInputList={(val) => handleInputChange(val)}
              editorValue={editorValue}
              outputFieldGroupDetails={outputFieldGroupDetails}
            />
          )}
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleClose}
            color="primary"
            buttonName="Cancel"
            variant="outlined"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
          <AppButton
            onClick={handleSubmit}
            color="primary"
            buttonName="Submit"
            variant="contained"
            className="btnsmall"
            style={{ margin: "unset" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addInputOutputDataForNodes,
};

const mapStateToProps = (state) => {
  const { process_data, application_data } = state.orchestration;
  const { microservices, _id, deployment } = application_data.data;
  const { templateid } = process_data;
  return {
    services: microservices,
    templateid,
    applicationID: _id,
    deploymentServices:
      deployment && deployment.services ? deployment.services : [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallNodeModal);
