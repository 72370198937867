import React from "react";
import SomethingWentWrong from "../screens/Errors/SomethingWentWrong";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    // this.airbrake = new Notifier({
    //   projectId: 338358,
    //   projectKey: "bc6bd47b888dbf37502c774bb6be07e6",
    // });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // this.airbrake.notify({
    //   error: error,
    //   params: { info: errorInfo },
    // });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <SomethingWentWrong errorMsg={this.state.error?.toString()} />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
