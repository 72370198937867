import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  AiOutlineFile,
  AiOutlineFolder,
  AiOutlineFolderOpen,
  AiOutlineLock
} from "react-icons/ai";
import { DiJavascript1, DiCss3Full, DiHtml5, DiReact, DiPython, DiJava, DiPhp } from "react-icons/di"; 
import { SiGoland } from "react-icons/si"; 
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai"; 

import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActiveEditorPath,
  updateTreeData,
  updateIsEdit,
} from "../../../redux/actions/codeEditorAction";
import FolderAction from "./FolderAction";
import {
  createFolderbyId,
  deleteFolderById,
  renameFolderById,
} from "../../../utils/code_editor/CodeEditorUtils";
import { useSnackbar } from "notistack";
import { TreeDeleteModal } from "../../../components/common/modals/TreeDeleteModal";
import { GetLockStatus } from "../web_socket/ConsoleWS";
import { Background } from "react-flow-renderer";
import { CircularProgress, Tooltip } from "@material-ui/core";

const FILE_ICONS = {
  js: <DiJavascript1 style={{color:"#F0DB4F"}} />,
  css: <DiCss3Full style={{color:'#264de4'}} />,
  html: <DiHtml5 style={{color:'#F06529'}} />,
  jsx: <DiReact />,
  py: <DiPython style={{color:'#4B8BBE'}} />,
  go: <SiGoland style={{color:'#29BEB0'}} />,
  java: <DiJava style={{color:'#D0A384'}} />,
  php: <DiPhp style={{color:'#474A8A'}} />
};

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 5px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 5px;
  cursor: pointer;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
  .active-path {
    background: lightgray;
  }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "auto" : "0")};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const File = ({ name, node, handleClick }) => {
  const dispatch = useDispatch();
  const activePath = useSelector((state) => state.codeEditorReducer.activePath);
  const userId = useSelector((state) => state.userDetails.userInfo._id);
  

  let ext = name.split(".")[1];

  return (
  
    <StyledFile
    className={activePath.path_name === node.path_name && "active-path"}
     onClick={() => {dispatch(updateActiveEditorPath(node)); handleClick(node)}}>

      {/* render the extension or fallback to generic file icon  */}
      {/* {FILE_ICONS[ext] || <AiOutlineFile />} */}
      {/* {node.lockedby && node.lockedby !== userId &&
        
       } */}
      {node.lockedby && node.lockedby !== userId ? <AiOutlineLock /> : FILE_ICONS[ext] || <AiOutlineFile />}
      <Tooltip title={node.lockedby && node.lockedby !== userId ? `By ${node.username}` : ''} arrow>
      <span
        
      >
       {name.substring(0, Math.min(20,name.length))}{name.length >= 20 && '..'}  
      </span>
      </Tooltip>
      {/* {node.lockedby && node.lockedby !== userId && <AiOutlineLock />} */}
    </StyledFile>
  

  );
};

const cancelFileCreation = (data) => {
  data?.map((item, index) => {
    if (item.id === "tempFile") {
      delete data[index];
    }

    if (item.type === "folder") {
      cancelFileCreation(item.children);
    }
  });
};

const commitFileCreation = (data, newName, activePath) => {
  data?.map((item, index) => {
    if (item.id === "tempFile") {
      item.name = newName;
      item.type = "file";
      item.path_name = `${activePath}/${newName}`;
      item.id = "created";
    }

    if (item.type === "folder") {
      commitFileCreation(item.children, newName, activePath);
    }
  });
};

const FileCreate = ({ name, serviceId, versionId }) => {
  let ext = name.split(".")[1];
  
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const currentTreeData = useSelector((state) => state.codeEditorReducer);


  useEffect(() => {
    dispatch(updateIsEdit(true))
  }, [])


  const createFileToServer = async (pathName) => {
    const pathData = {
      ftype: "file",
      filepath: `${currentTreeData.activePath.path_name}/${pathName}`,
    };
    const { _status, data, _msg } = await createFolderbyId(
      serviceId,
      versionId,
      pathData
    );
    if (_status === 201) {
      let tempData = [...currentTreeData.treeData];
      commitFileCreation(
        tempData,
        pathName,
        currentTreeData.activePath.path_name
      );
      dispatch(updateTreeData(tempData));
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      createFileToServer(e.target.value);
    dispatch(updateIsEdit(false))
    }

    if (e.key === "Escape") {
      let tempData = [...currentTreeData.treeData];
      cancelFileCreation(tempData);
      dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
  }
  };

  const cancelCreation = () => {
    let tempData = [...currentTreeData.treeData];
      cancelFileCreation(tempData);
      dispatch(updateTreeData(tempData));
      dispatch(updateIsEdit(false))
    }
  

  return (
    <StyledFile>
      {/* render the extension or fallback to generic file icon  */}
      {FILE_ICONS[ext] || <AiOutlineFile />}
      <span>
        <input type="text" autoFocus onKeyDown={onEnterKey} onBlur={cancelCreation} />
        {name}
      </span>
    </StyledFile>
  );
};


const cancelRename = (data) => {
  data?.map((item) => {
    if (item.type === "FolderRename") {
      item.type = 'folder'
    }

    if (item.type === "fileRename") {
      item.type = 'file'
    }

    if (item.type === "folder") {
      cancelRename(item.children);
    }
  });
};

const commitRename = (data, newName, activePath, newPath, path='') => {
  data?.map((item, index) => {
    let currentPath
    if (item.path_name === activePath) {
      if(item.type === 'FolderRename'){
      item.type = "folder";
      
      } else {
      item.type = "file";

      }
      item.name = newName;
      if(path){
        currentPath = path + "/" + newName
      } else {
        currentPath = newName
      }

      item.path_name = currentPath;
    } else {

      if(path){
        currentPath = path + "/" + item.name
      } else {
        currentPath = item.name
      }
      item.path_name = currentPath;


    }

    if (item.type === "folder") {
      commitRename(item.children, newName, activePath, newPath, currentPath);
    }

  });
};

const FileRename = ({ name, serviceId, versionId }) => {
  let ext = name.split(".")[1];
  const [newFileName, setNewFileName] = useState(false);
  const dispatch = useDispatch();
  const activePath = useSelector((state) => state.codeEditorReducer.activePath);
  const currentTreeData = useSelector((state) => state.codeEditorReducer);

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    setNewFileName(name)
    dispatch(updateIsEdit(true))
  }, [name])


  const renameFileToServer = async (pathName) => {
    let folderPath = activePath.path_name.split("/");
    let currentPath = folderPath.splice(0, folderPath.length - 1);
    let pathData = {
      ftype: "file",
      filepath: activePath.path_name,
      filepathnew: currentPath.join("/") + "/" + newFileName,
    };

    const { _status, data, _msg } = await renameFolderById(
      serviceId,
      versionId,
      pathData
    );
    if (_status === 200) {
      let tempData = [...currentTreeData.treeData];
      commitRename(
        tempData,
        pathName,
        currentTreeData.activePath.path_name,
        pathData.filepathnew
      );
      dispatch(updateTreeData(tempData));
      dispatch(updateActiveEditorPath({path_name: currentPath.join("/") + "/" + newFileName, type:"file", name:newFileName}))
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      renameFileToServer(e.target.value);
    dispatch(updateIsEdit(false))
    }

    if (e.key === "Escape") {
      let tempData = [...currentTreeData.treeData];
      cancelRename(tempData);
      dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
    }
  };

  const cancelCreation = () => {
    let tempData = [...currentTreeData.treeData];
      cancelRename(tempData);
      dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
  }
  

  return (
    <StyledFile>
      {/* render the extension or fallback to generic file icon  */}
      {FILE_ICONS[ext] || <AiOutlineFile />}
      <span>
        <input type="text" autoFocus onChange={(e) => setNewFileName(e.target.value)}  onKeyDown={onEnterKey} onBlur={cancelCreation} value={newFileName}  />
      </span>
    </StyledFile>
  );
};




const FolderRename = ({ name, children, node, serviceId, versionId }) => {
  const dispatch = useDispatch();
  const activePath = useSelector((state) => state.codeEditorReducer.activePath);
  const currentTreeData = useSelector((state) => state.codeEditorReducer);

  const [isOpen, setIsOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState(false);

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    setNewFolderName(name)
    dispatch(updateIsEdit(true))
  }, [name])


  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const renameFolderToServer = async (pathName) => {
    let folderPath = activePath.path_name.split("/");
    let currentPath = folderPath.splice(0, folderPath.length - 1);
    let pathData = {
      ftype: "folder",
      filepath: activePath.path_name,
      filepathnew: currentPath.join("/") + "/" + newFolderName,
    };

    const { _status, data, _msg } = await renameFolderById(
      serviceId,
      versionId,
      pathData
    );
    if (_status === 200) {
      let tempData = [...currentTreeData.treeData];
      commitRename(
        tempData,
        pathName,
        currentTreeData.activePath.path_name,
        pathData.filepathnew
      );
      dispatch(updateActiveEditorPath({path_name: currentPath.join("/") + "/" + newFolderName, type:"folder", name:newFolderName}))
      dispatch(updateTreeData(tempData));
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      renameFolderToServer(e.target.value);
    dispatch(updateIsEdit(false))
    }

    if (e.key === "Escape") {
      let tempData = [...currentTreeData.treeData];
      cancelRename(tempData);
      dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
    }
  };

  const cancelCreation = () => {
    let tempData = [...currentTreeData.treeData];
    cancelRename(tempData);
    dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
  }

  return (
    <StyledFolder>
      <div
        className="folder--label"
        onClick={handleToggle}
      >
        {isOpen ? <AiOutlineDown /> : <AiOutlineRight />}
        <span><input autoFocus type="text" onChange={(e) => setNewFolderName(e.target.value)}  onKeyDown={onEnterKey} onBlur={cancelCreation} value={newFolderName} /></span>
      </div>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const Folder = ({ name, children, node, handleClick }) => {
  const dispatch = useDispatch();
  const activePath = useSelector((state) => state.codeEditorReducer.activePath);
  const lastvisitpath = useSelector((state) => state.codeEditorReducer.treeData[1].lastvisitpath);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);


useEffect(() => {
  if(lastvisitpath?.startsWith(node.path_name)){
setIsOpen(true)
  }
  
}, [node.isLoader])


useEffect(() => {
  if(node.isLoader === 'stop'){
    setIsLoader(false)
  }
}, [node.isLoader])

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    dispatch(updateActiveEditorPath(node));
    if(!isOpen){
      handleClick(node)
      if(!children){
        setIsLoader(true)
      }
    }
  };

  return (
    <StyledFolder>
      <div
        className={
          activePath.path_name === node.path_name
            ? "folder--label active-path"
            : "folder--label"
        }
        onClick={handleToggle}
      >
        {isLoader ? <CircularProgress size={12} /> : <>
        {isOpen ? <AiOutlineDown /> : <AiOutlineRight />} </> }
        <span>{name.substring(0, Math.min(20,name.length))}{name.length >= 20 && '..'}</span>
      </div>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const cancelFolderCreation = (data) => {
  data?.map((item, index) => {
    if (item.id === "tempFolder") {
      delete data[index];
    }

    if (item.type === "folder") {
      cancelFolderCreation(item.children);
    }
  });
};

const commitFolderCreation = (data, newName, activePath) => {
  data?.map((item, index) => {
    if (item.id === "tempFolder") {
      item.name = newName;
      item.type = "folder";
      item.path_name = `${activePath}/${newName}`;
      item.id = "created";
    }

    if (item.type === "folder") {
      commitFolderCreation(item.children, newName, activePath);
    }
  });
};

const CreateFolder = ({ data, children, serviceId, versionId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const currentTreeData = useSelector((state) => state.codeEditorReducer);
  const [isOpen, setIsOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState();
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    dispatch(updateIsEdit(true))
  }, [])


  const createFolderToServer = async (pathName) => {
    const pathData = {
      ftype: "folder",
      filepath: `${currentTreeData.activePath.path_name}/${pathName}`,
    };
    const { _status, data, _msg } = await createFolderbyId(
      serviceId,
      versionId,
      pathData
    );
    if (_status === 201) {
      let tempData = [...currentTreeData.treeData];
      commitFolderCreation(
        tempData,
        pathName,
        currentTreeData.activePath.path_name
      );
      dispatch(updateTreeData(tempData));
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      createFolderToServer(e.target.value);
    dispatch(updateIsEdit(false))
    }

    if (e.key === "Escape") {
      let tempData = [...currentTreeData.treeData];
      cancelFolderCreation(tempData);
      dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
    }
  };

  const cancelCreation = () => {
    let tempData = [...currentTreeData.treeData];
    cancelFolderCreation(tempData);
    dispatch(updateTreeData(tempData));
    dispatch(updateIsEdit(false))
  }

  return (
    <StyledFolder>
      <div className="folder--label" onClick={handleToggle}>
        <AiOutlineRight />
        <span>
          <input
            autoFocus
            type="text"
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyDown={onEnterKey}
            onBlur={cancelCreation}
          />
        </span>
      </div>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const TreeRecursive = ({ data, serviceId, versionId, handleClick }) => {
  // loop through the data
  return data.map((item) => {
    // if its a file render <File />
    if (item.type === "file") {
      return <File name={item.name} node={item} handleClick={handleClick} />;
    }
    // if its a folder render <Folder />
    if (item.type === "folder") {
      return (
        <Folder name={item.name} node={item} handleClick={handleClick}>
          {/* Call the <TreeRecursive /> component with the current item.childrens */}
          {item.children && 
          <TreeRecursive
            data={item.children}
            serviceId={serviceId}
            versionId={versionId}
            handleClick={handleClick}
          />
        }
        </Folder>
      );
    }

    if (item.type === "newFile") {
      return (
        <FileCreate name={""} serviceId={serviceId} versionId={versionId} />
      );
    }

    if (item.type === "fileRename") {
      return <FileRename name={item.name} serviceId={serviceId} versionId={versionId} />;
    }

    if(item.type === "FolderRename"){
      return <FolderRename name={item.name} serviceId={serviceId} versionId={versionId} />;
    }

    if (item.type === "newFolder") {
      return (
        <CreateFolder
          name={item.name}
          serviceId={serviceId}
          versionId={versionId}
        />
      );
    }
  });
};
const Tree = ({ data, children, serviceId, versionId, handleClick }) => {
  const isImparative = data && !children;
  return (
    <StyledTree>
      {isImparative ? (
        <TreeRecursive
          data={data}
          serviceId={serviceId}
          versionId={versionId}
          handleClick={handleClick}
        />
      ) : (
        children
      )}
    </StyledTree>
  );
};

Tree.File = File;
Tree.Folder = Folder;

function FileTree({
  treeData,
  CreateNewFolder,
  activePath,
  updateTreeData,
  versionId,
  serviceId,
  fetchFilesTree,
  handleDownload,
  userInfo,
  updateActiveEditorPath,
  handleClick
}) {
  const { enqueueSnackbar } = useSnackbar();
  
  const [lockedFilesArray,setLockedFilesArray]= useState([]);
  


const ws = useRef(null);


  useEffect(() => {
   
    

      ws.current = GetLockStatus(serviceId, versionId, userInfo._id);


      const wsCurrent = ws.current;

      return () => wsCurrent.close();
  }, [serviceId, versionId]);


  useEffect(() => {
    if (!ws.current) return;

    const updateTreeLocks = (data, socketMessage) => {
 
      data?.map((item)=>{
        if(socketMessage.locks){
          socketMessage?.file?.map((path)=>{
            if(item.path_name === path){
              if(item.lockedby){}
              item['lockedby'] = socketMessage.userid
              item['username'] = socketMessage.username
            }
          })
        } else {
          socketMessage?.file?.map((path)=>{
            if(item.path_name === path){
              item.lockedby = ''
            }
          })
        }
        

        if (item.type === "folder") {
          updateTreeLocks(item.children, socketMessage);
        }

      })
    }

      const upDateLock = (socketMessage) => {
        let tempData = [...treeData];

        updateTreeLocks(tempData, socketMessage);

        updateTreeData(tempData);
      }

    ws.current.onmessage = event => {
      const socketMessage = event.data;
      const lockLogs = JSON.parse(socketMessage)
      upDateLock(lockLogs)
    };
}, [treeData]);

  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const newFolderCreation = (data, new_folder) => {
    data?.map((item, index) => {
      if (new_folder === "new_folder") {
        if (index === 0) {
          data.push({
            type: "newFolder",
            name: "",
            id: "tempFolder",
            children: [{ name: "" }],
            path_name: "",
          });
        }
      }

      if (item.path_name === activePath.path_name) {
        newFolderCreation(item.children, "new_folder");
      } else if (item.type === "folder") {
        newFolderCreation(item.children);
      }
    });

    if (new_folder === "new_folder") {
      if (data.length === 0) {
        data.push({
          type: "newFolder",
          name: "",
          id: "tempFolder",
          children: [{ name: "" }],
          path_name: "",
        });
      }
    }
  };

  const newFileCreation = (data, new_file) => {
    data?.map((item, index) => {
      if (new_file === "new_file") {
        if (index === 0) {
          data.push({
            type: "newFile",
            name: "",
            id: "tempFile",
            children: [{ name: "" }],
            path_name: "",
          });
        }
      }

      if (item.path_name === activePath.path_name) {
        newFileCreation(item.children, "new_file");
      } else if (item.type === "folder") {
        newFileCreation(item.children);
      }
    });

    if (new_file === "new_file") {
      if (data.length === 0) {
        data.push({
          type: "newFile",
          name: "",
          id: "tempFile",
          path_name: "",
        });
      }
    }
  };

  const recurTree = (data, checkFolder) => {
    data.map((item, index) => {
      if (index === 0) {
        if (checkFolder === "newfolder") {
          data.push({ type: "newFolder", name: "", id: "123", childrens: [] });
        }
      }

      if (item.type === "folder") {
        if (item.name === "Components") {
          recurTree(item.childrens, "newfolder");
        } else {
          recurTree(item.childrens);
        }
      }
    });
  };

  const createNewFolderAction = () => {
    let tempData = [...treeData];

    newFolderCreation(tempData);

    updateTreeData(tempData);
  };

  const CreateNewFileAction = () => {
    let tempData = [...treeData];

    newFileCreation(tempData);

    updateTreeData(tempData);
  };

  const deleteFileFolderInTree = (data) => {
    data?.map((item, index) => {
      if (item.path_name === activePath.path_name) {
        delete data.splice(index, 1);
      }
  
      if (item.type === "folder") {
        deleteFileFolderInTree(item.children);
      }
    });
  };

  const deleteFileAndFolder = async () => {
    setOpenDeleteModel(false);
    const pathData = {
      ftype: activePath.type,
      filepath: activePath.path_name,
    };
    const { data, _status, _msg } = await deleteFolderById(
      serviceId,
      versionId,
      pathData
    );

    if (_status === 200) {
      enqueueSnackbar(_msg, { variant: "success" });
      let tempData = [...treeData];
      deleteFileFolderInTree(tempData);
      updateTreeData(tempData)
      updateActiveEditorPath({isDeleted:true})
    } else {
      enqueueSnackbar(_msg, { variant: "error" });

    }
  };


  const renameFolderAndFileTree = (data, type) => {
    data?.map((item) => {
      if (item.path_name === activePath.path_name) {
        if(type === 'file'){
          item.type = 'fileRename'
        } else {
          item.type = 'FolderRename'

        }
      } 
      if (item.type === "folder") {
        renameFolderAndFileTree(item.children, type);
      }
    });

    
  };

  const RenameFolderAndFile = () => {
    let tempData = [...treeData];
    renameFolderAndFileTree(tempData, activePath.type);
    updateTreeData(tempData);
  }
  

  return (
    <div>
      <div style={{position:'relative',paddingBottom:'10px',   backgroundColor:'white'}}>
      <FolderAction
        CreateNewFolder={createNewFolderAction}
        CreateNewFile={CreateNewFileAction}
        DeleteFileFolder={() => setOpenDeleteModel(true)}
        RenameFileAndFolder = {RenameFolderAndFile}
        RefreshTree = {fetchFilesTree}
        handleDownload={handleDownload}
      />
      </div>
      <TreeDeleteModal
        open={openDeleteModel}
        handleDelete={deleteFileAndFolder}
        handleClose={() => setOpenDeleteModel(false)}
      />
      <div style={{overflow:'auto', height:'77vh'}} className="file-tree">
      <Tree data={treeData} versionId={versionId} serviceId={serviceId} handleClick={handleClick} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { treeData, activePath } = state.codeEditorReducer;
  const { userInfo } = state.userDetails;

  return {
    treeData,
    activePath,
    userInfo
  };
};

const mapDispatchToProps = {
  updateTreeData,
  updateActiveEditorPath
};

export default connect(mapStateToProps, mapDispatchToProps)(FileTree);
