import {
  Grid,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
} from "@material-ui/core";
import React from "react";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, DesktopDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import moment from "moment";
import manageStyles from "../../../../../screens/CommonStyles/manageScreenStyles";

const LanguageDetailsEntity = ({
  section,
  languageData,
  userInfo,
  tabbedView,
  uploadModal,
}) => {
  const styles = manageStyles();

  const UploadIcon = () => {
    return (
      <>
        <Grid container>
          <Grid
            container
            spacing={3}
            style={
              tabbedView
                ? { marginLeft: 10, marginBottom: 40 }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            {uploadModal ? (
              <Grid item xs={6}>
                <div className={styles.iconwrapper} style={{ marginTop: 20 }}>
                  {languageData.icon && (
                    <Avatar
                      src={languageData.icon}
                      alt="icon"
                      className={styles.icon}
                    />
                  )}
                </div>
              </Grid>
            ) : (
              <img
                src="/images/No_image_available.svg"
                style={{ paddingTop: "22px", paddingLeft: "5px", width: "10%" }}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const DockerCommandSection = () => {
    return (
      <>
        <Grid
          container
          spacing={3}
          style={
            tabbedView
              ? { marginLeft: 4, minHeight: "10rem", marginTop: 7 }
              : { minHeight: "20rem" }
          }
        >
          <Grid item xs={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Build Commands
              </label>
            </Grid>
            {languageData?.buildcommands ? (
              languageData?.buildcommands?.map((buildcommand, index) => (
                <Grid
                  key={index}
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={11} sm={11}>
                    <input
                      disabled={true}
                      type="text"
                      key={index}
                      name={`${index}_build`}
                      className={styles.input2}
                      value={languageData.buildcommands[index]}
                      onChange={(e) =>
                        setlanguageData({
                          ...languageData,
                          buildcommand: [
                            ...languageData.buildcommands,
                            (languageData.buildcommands[index] =
                              e.target.value),
                          ],
                        })
                      }
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}></Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={11} sm={11}>
                <input
                  type="text"
                  disabled
                  name={`${0}_build`}
                  className={styles.input2}
                  onChange={(e) =>
                    setlanguageData({
                      ...languageData,
                      buildcommand: [
                        ...languageData.buildcommands,
                        (languageData.buildcommands[index] = e.target.value),
                      ],
                    })
                  }
                  autoComplete="off"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid>
              <label
                htmlFor="projectDescription"
                className={styles.fieldHeading}
                style={{ width: "auto" }}
              >
                Run Commands
              </label>
              {/* <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        onClick={() =>
                            setlanguageData({
                                ...languageData,
                                runcommands: [...languageData.runcommands, ""],
                            })
                        }
                        className={styles.actionIcon2}
                    >
                        <AddIcon />
                    </IconButton> */}
            </Grid>
            <Grid item xs={11} sm={11}>
              <input
                type="text"
                disabled
                name="runcommands"
                className={styles.input2}
                value={languageData.runcommands}
                onChange={(e) =>
                  setlanguageData({
                    ...languageData,
                    runcommands: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case "TextField":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              className={styles.input2}
              // value={launguageData[field.datakey]}
              value={
                field.dataType === "date" && languageData[field.datakey]
                  ? moment
                      .unix(languageData[field.datakey])
                      .tz(userInfo.timeZone)
                      .format(
                        userInfo.time === "h:mm"
                          ? `${userInfo.dateFormat}, ${userInfo.time} A`
                          : `${userInfo.dateFormat} ${userInfo.time}`
                      )
                  : languageData[field.datakey]
              }
              disabled
              autoComplete="off"
            />
          </>
        );
      case "TextArea":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={languageData[field.datakey]}
              className={styles.textarea2}
              disabled
              rows="7"
            ></textarea>
          </>
        );
      case "Dropdown":
        if (field.fieldLabel === "Docker Image") {
          return (
            <div>
              <label htmlFor="" className={styles.fieldHeading2}>
                Docker Image<span className={styles.red}>*</span>
              </label>
              <input
                className={styles.input2}
                value={languageData?.dockerdetails?.imagename}
                autoComplete="off"
                disabled
              />
            </div>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                className={styles.input2}
                // value={launguageData[field.datakey]}
                value={languageData[field.datakey]}
                autoComplete="off"
                disabled
              />
            </>
          );
        }
      case "Date":
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                languageData[field.datakey] ? languageData[field.datakey] : ""
              }
              // onChange={(date) =>
              //   handleChange({ target: { name: field.datakey, value: date } })
              // }
              disabled
              inputFormat={"dd/MM/yyyy"}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={styles.input2}
                    />
                    <span className={`${styles.datePickerIcon} `}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "Currency":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                disabled
                name={`${field.datakey}_unit`}
                value={
                  languageData[`${field.datakey}_unit`]
                    ? languageData[`${field.datakey}_unit`]
                    : ""
                }
                className={styles.select}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={styles.input2}
                value={languageData[`${field.datakey}_value`]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
      case "Amount":
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{" "}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                disabled
                name={`${field.datakey}_unit`}
                value={
                  languageData[`${field.datakey}_unit`]
                    ? languageData[`${field.datakey}_unit`]
                    : ""
                }
                className={styles.select}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}
                {field?.required === "TRUE" && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={styles.input2}
                value={languageData[`${field.datakey}_value`]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
      case "RadioButton":
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{" "}
              {field?.required === "TRUE" && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                languageData[field.datakey] ? languageData[field.datakey] : ""
              }
              style={{ display: "flex", flexDirection: "row" }}
            >
              {field?.values.map((value, index) => (
                <FormControlLabel
                  value={value}
                  key={index}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === "Language_Header_section" &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
              fieldgroup.fields.filter((f) => f.uiElementType === "TextArea")
                .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  style={
                    tabbedView
                      ? { paddingTop: 6, paddingLeft: -4 }
                      : { marginTop: "0rem" }
                  }
                  key={fieldgroup._id}
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.name) {
                        case "Language Icon":
                          return <UploadIcon />;
                        case "Language Docker Details":
                          return DockerCommandSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={tabbedView ? {} : { marginTop: "0rem" }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== "TextArea")
                          .map((field) => {
                            switch (field.name) {
                              case "Language Icon":
                                return <UploadIcon />;

                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid container spacing={3} style={{ marginTop: "0rem" }}>
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === "TextArea")
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userDetails;
  const { tabbedView } = state.userDetails.userInfo;

  return {
    userInfo,
    tabbedView,
  };
};

export default connect(mapStateToProps)(LanguageDetailsEntity);
