import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    padding: "0 15px",
    height: "40px",
    outline: "none",

    "&::before": {
      borderBottom: "unset",
    },

    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box !important",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  icon: {
    height: "1.1rem",
  },
}));
