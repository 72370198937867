import React, { useState } from 'react';
import AccessEditorInput from '../../customEditor/AccessEditorInput';

const AccessDataInput = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <label>{props.label}</label>
      <AccessEditorInput
        name={props?.name}
        value={props?.value?.dataInput || ''}
        onChange={(value) => props?.onChange(value)}
        title={props?.title}
        error={props.error}
        mode="javascript"
        height={props?.height}
        lineNumbers={props?.lineNumbers}
        accessMode={props.accessMode}
      />
      {props?.error && <p className="errorMsg">{props?.error}</p>}
    </>
  );
};

export default AccessDataInput;
