import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  add_serviceGroup_documents_data,
  service_group_document_sort,
  add_service_group_documents_filter,
} from "../../redux/actions/documentAction";
import { addMicroserviceInServicegroupListData, getServiceGroupsListByProject } from "../../redux/actions/serviceGroupAction";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import {
  assignDeassignTeamToServiceGroup,
  fetchTeamListData,
} from "../../utils/project/teamUtils";
import {
  addServiceGroupInProject,
  addServiceGroupInServiceGroup,
  editServiceGroup,
  fetchServiceGroupById,
} from "../../utils/project/servicegroupUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  initError,
} from "../../utils/common";
import { getTeamById } from "../../utils/teams/teamsUtils";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import { arrayToFilter } from "../../utils/common";
import { arrayToSort2 } from "../../utils/common";
import Loader from "../../components/common/stuff/Loader";
import { fetchProjectById } from "../../utils/project/projectUtils";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupsForServiceGroupEdit from "../../components/common/entitySchema/servicegroup/CustomFieldGroupsForServiceGroupEdit";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import CopyServiceSubmitModal from "../../components/CopyServiceSubmitModal";
import TabbedNavigation from "../../components/Tabbed/TabbedNavigation";
import { updateServiceGroupMode } from "../../redux/actions/projectAction";
import { TabsCommon } from "../../components/Tabbed/TabsCommon";
import SectionTabs from "../../components/Tabbed/SectionTabs";

const EditServiceGroup = (props) => {


  const { updateServiceGroupMode, selectedServiceGroup, serviceGroupMode, tabbedView, getServiceGroupsListByProject } = props

  // const [level, setLevel] = useState(
  //   props.location.state?.level ? props.location.state.level : 1
  // );

  const [level, setLevel] = useState(tabbedView ? 1 : (props.location.state?.level ? props.location.state.level : 1));

  const [activeTab, setActiveTab] = useState(0);


  let isEditMode
  if (tabbedView) {
    if (serviceGroupMode === 'details') {
      isEditMode = false
    } else if (serviceGroupMode === 'edit') {
      isEditMode = true
    }
  } else {
    isEditMode = props.match.params.action === "edit" ? true : false;
  }

  // const [projectId] = useState(props.match.params.projectId);
  const [projectId] = useState(tabbedView ? selectedServiceGroup?.projectId : props.match.params.projectId);


  const [copyServiceModal, setCopyServiceModal] = useState(false);
  const [teamLoader, setTeamLoader] = useState(false);
  const [serviceGroupData, setServiceGroupData] = useState({
    _id: "",
    name: "",
    description: "",
    createdon: "",
    createdby: "",
    assignedto: "",
    assignedid: "",
    status: "",
    childservicegroups: [],
    microservicedetails: [],
  });
  const [error, setError] = useState({
    name: { isError: false },
    description: { isError: false },
  });
  const {
    service_groups_documents_list,
    add_serviceGroup_documents_data,
    serviceGroupDocumentSort,
    serviceGroupsDocumentsFilters,
    addMicroserviceInServicegroupListData,
    allMicroserviceList,
  } = props;

  // const [groupId] = useState(props.match.params._id);
  const [groupId] = useState(tabbedView ? selectedServiceGroup?.groupId : props.match.params._id);



  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [teamTab, setTeamTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  const [serviceGroupTeams, setServiceGroupTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [clickedTeam, setClickedTeam] = useState(
    teams === null ? [] : teams[0]
  );
  const [isLoader, setIsLoader] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [serviceStatuses, setServiceStatuses] = useState([]);
  const [serviceGroupEntitySchema, setServiceGroupEntitySchema] = useState([]);

  // const [parentServiceGroupId, setParentServiceGroupId] = useState(
  //   props.match.params.groupId
  // );

  const [parentServiceGroupId, setParentServiceGroupId] = useState(
    tabbedView ? selectedServiceGroup?.groupId : props.match.params.groupId
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [serviceGroupCurrentPage, setServiceGroupCurrentPage] = useState(1);
  function createDocumentTableData(docData) {
    const { _id, docName, filename, name, resourcetype, version, createdon } =
      docData;
    return {
      id: _id,
      docName: docName,
      filename: filename,
      name: name,
      versionNumber: version,
      createdon: createdon,
      linkedTo: resourcetype,
      importedFrom: "",
    };
  }

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission("ServiceGroup", ["Edit"]);
    } else {
      checkScreenPermission("ServiceGroup", ["Read"]);
    }
    const fetchServiceGroupEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "ServiceGroup",
        isEditMode ? "edit" : "read"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceGroupEntitySchema(entity);
        fetchServiceGroupData();
        const sgData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (sgData[field.datakey] = "");
              }
            });
          });
        });
        setServiceGroupData((prevData) => ({ ...serviceGroupData, ...sgData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["documentation"];
        delete errData["documentationTable"];
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    const fetchServiceGroupData = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchServiceGroupById(
        projectId,
        parentServiceGroupId
      );
      if (_status === 200) {
        setServiceGroupData((prevData) => ({ ...prevData, ...data }));
        addMicroserviceInServicegroupListData(
          data.microservicedetails ? data.microservicedetails : []
        );
        fetchProjectDetails(data?.projectid);
        const tempTeams = [];
        if (checkCompPermission("Team", ["List All"])) {
          for (var i = 0; data.teams && i < data.teams.length; i++) {
            const teamsData = await fetchTeamById(data.teams[i]._id);
            tempTeams.push(teamsData);
          }
        }
        setServiceGroupTeams(tempTeams ? tempTeams : []);
        setSelectedTeams(tempTeams ? tempTeams : []);
        setLevel(data?.level ? data?.level : 1);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchServiceGroupEntitySchema();
    if (checkCompPermission("Team", ["List All"])) {
      fetchTeamsList();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource("ServiceGroup", (data) =>
        setStatuses(data ? data.status : [])
      );
      fetchStatusesByResource("Microservice", (data) =>
        setServiceStatuses(data ? data.status : [])
      );
    }
    // fetchDocuments();
  }, [isEditMode, projectId, parentServiceGroupId]);

  const fetchTeamsList = async (filter) => {
    setTeamLoader(true);
    const { _msg, _status, data } = await fetchTeamListData(0, filter);
    if (_status === 200) {
      setTeams(data ? data : []);
      setTeamLoader(false);
    } else {
      setTeamLoader(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const fetchProjectDetails = async (projectid) => {
    setIsLoader(true);
    const { _msg, _status, data } = await fetchProjectById(projectid);
    if (_status === 200) {
      setProjectData(data);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const fetchTeamById = async (teamId) => {
    const { _msg, _status, data } = await getTeamById(teamId);
    if (_status === 200) {
      return data ? data : [];
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
      return [];
    }
  };

  const fetchStatusesByResource = async (resource, callback) => {
    const { _msg, _status, data } = await fetchStatusResourceByName(resource);
    if (_status === 200) {
      callback(data);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [serviceGroupCurrentPage, serviceGroupDocumentSort]);

  const fetchDocuments = () => {
    if (checkCompPermission("Document", ["List All", "List Assigned"])) {
      const parsedFilters = arrayToFilter([
        ...serviceGroupsDocumentsFilters,
        { resourcetype: "servicegroup" },
        { resourceid: groupId },
      ]);
      const parsedSort = arrayToSort2(serviceGroupDocumentSort);
      add_serviceGroup_documents_data(
        serviceGroupCurrentPage,
        parsedFilters,
        JSON.stringify(parsedSort)
      );
    }
  };

  const handleServicegroupDocumentListPagination = (event, value) => {
    setServiceGroupCurrentPage(value);
  };

  const documents =
    service_groups_documents_list.data &&
      service_groups_documents_list.data.length > 0
      ? service_groups_documents_list.data.map((item) => {
        const rowData = createDocumentTableData(item);
        return rowData;
      })
      : [];

  const handleChangeTeamTab = (event, newValue) => {
    setTeamTab(newValue);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setServiceGroupData({
      ...serviceGroupData,
      [event.target.name]: value,
    });
  };

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      fetchTeamsList(
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      fetchTeamsList();
    }
  };

  const assignDeassingTeam = async (team, forType) => {
    const teamData = {
      teamid: team._id,
    };
    setIsLoader(true);
    const { _msg, _status, data } = await assignDeassignTeamToServiceGroup(
      projectId,
      parentServiceGroupId,
      teamData,
      forType
    );
    if (_status === 200) {
      if (forType === "assignteam") {
        setSelectedTeams([...selectedTeams, team]);
      } else {
        setSelectedTeams(selectedTeams.filter((item) => item._id !== team._id));
      }
      setIsLoader(false);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
    setIsLoader(false);
  };

  const handleSelectedTeamDelete = async (teamToDelete) => {
    await assignDeassingTeam(teamToDelete, "dismissteam");
    setSelectedTeams(
      selectedTeams.filter((team) => team._id !== teamToDelete._id)
    );
  };

  const handleTeamCheck = async (team) => {
    if (!selectedTeams.find((item) => item._id === team._id)) {
      await assignDeassingTeam(team, "assignteam");
    } else {
      await assignDeassingTeam(team, "dismissteam");
    }
  };

  const handleNewServiceGroupClick = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      serviceGroupData,
      error
    );
    if (formIsValid) {
      const sgData = {
        ...serviceGroupData,
        teams: selectedTeams.map((item) => item._id),
        level: level,
      };
      let parentId = "";
      setIsLoader(true);
      if (parentServiceGroupId && level !== 1) {
        const { _msg, _status, data } = await addServiceGroupInServiceGroup(
          projectId,
          parentServiceGroupId,
          sgData
        );
        if (_status === 201) {

          parentId = data._id;
          enqueueSnackbar(_msg, { variant: "success" });
          handleUploadDocuments(
            "servicegroup",
            parentServiceGroupId,
            selectedDocuments
          );
          setIsLoader(false);
          // histroy.push(`/projects/details/${projectId}`)
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(_msg ? _msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      } else {
        const { _msg, _status, data } = await addServiceGroupInProject(
          projectId,
          sgData
        );
        if (_status === 201) {
          updateServiceGroupMode({ value: null })
          parentId = data._id;
          enqueueSnackbar(_msg, { variant: "success" });
          handleUploadDocuments("servicegroup", data._id, selectedDocuments);

          setIsLoader(false);
          // histroy.push(`/projects/details/${projectId}`)
        } else if (_status === 409) {
          setIsLoader(false);
          parentId = parentServiceGroupId;
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(_msg ? _msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }
      setServiceGroupData({
        ...serviceGroupData,
        name: "",
        description: "",
        teams: [],
        status: serviceGroupData.status,
      });
      setSelectedTeams([]);
      setError({
        name: false,
        description: false,
        teams: false,
      });
      setLevel(level + 1);
      setParentServiceGroupId(parentId);
      // histroy.push(`/servicegroups/details/${projectId}/${parentId}`);
    } else {
      setError(errors);
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(
      serviceGroupData,
      error
    );
    if (formIsValid) {
      const newServiceGroupData = {
        name: serviceGroupData.name,
        description: serviceGroupData.description,
        status: serviceGroupData.status,
      };
      setIsLoader(true);
      const { _msg, _status, data } = await editServiceGroup(
        projectId,
        parentServiceGroupId,
        newServiceGroupData
      );
      if (_status === 201) {
        if (tabbedView) {
          getServiceGroupsListByProject(
            projectId,
            1,
            JSON.stringify({}),
          );
          updateServiceGroupMode({ value: null })

        } else {
          histroy.push(`/projects/details/${projectId}`);
        }
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false);
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(serviceGroupEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setServiceGroupEntitySchema(tempEntity)
      setError(errors);
      enqueueSnackbar("There are form field error(s), please correct the input values and submit", {
        variant: "error",
      });
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={!tabbedView && styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        {!tabbedView &&
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                { title: "Projects", path: "/projects" },
                {
                  title: projectData?.name,
                  path: `/projects/details/${projectId}`,
                },
                {
                  title: isEditMode
                    ? `Edit ${serviceGroupData.name}`
                    : serviceGroupData.name,
                },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>
                {isEditMode
                  ? `Edit ${serviceGroupData.name}`
                  : serviceGroupData.name}
              </h2>
              {!isEditMode ? (
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  startIcon={<img src="/images/edit.svg" alt="card" />}
                  disabled={false}
                  style={{ padding: "6px 15px" }}
                  className="btnsmall"
                  onClick={() =>
                    histroy.push(
                      `/servicegroups/edit/${projectId}/${parentServiceGroupId}`
                    )
                  }
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  disabled={false}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleSubmit}
                />
              )}
            </Grid>
          </div>
        }

        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <div style={{ marginBottom: 5 }}>
            <h3 className={styles.headingChild}>{isEditMode
              ? `Edit ${serviceGroupData.name}`
              : serviceGroupData.name}</h3>
          </div>
          <TabbedNavigation
            handleNext={() => setActiveTab((prev) => prev + 1)}
            handlePrev={() => setActiveTab((prev) => prev - 1)}
            handleEdit={() => updateServiceGroupMode({ value: 'edit' })}
            handleCancel={() => updateServiceGroupMode({ value: null })}
            schemaLength={serviceGroupEntitySchema?.length}
            isEditMode={isEditMode}
            activeTab={activeTab}
            handleSave={handleSubmit}
          />
        </Grid>

        {tabbedView ? <>
          <SectionTabs entity={serviceGroupEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />

          {/* <Tabs
            value={activeTab}
            onChange={handleActiveTab}
            indicatorColor="primary"
            textColor="primary"
            className={styles.documentsListAccessTab}
          >
            {serviceGroupEntitySchema.length !== 0 &&
              serviceGroupEntitySchema.map((section) => {
                return <Tab label={<>{section["section Label"]} </>} />;
              })}
          </Tabs> */}
          <Paper style={{ padding: 20 }}>
            <div style={{ padding: "0px 5px 0" }}>
              <CustomFieldGroupsForServiceGroupEdit
                section={serviceGroupEntitySchema[activeTab]}
                serviceGroupData={serviceGroupData}
                error={error}
                selectedTeams={selectedTeams}
                teams={teams}
                level={level}
                serviceGroupTeams={serviceGroupTeams}
                teamTab={teamTab}
                statuses={statuses}
                documents={documents}
                isEditMode={isEditMode}
                parentServiceGroupId={parentServiceGroupId}
                clickedTeam={clickedTeam}
                teamLoader={teamLoader}
                serviceStatuses={serviceStatuses}
                teamSearchInput={teamSearchInput}
                selectedDocuments={selectedDocuments}
                allMicroserviceList={allMicroserviceList}
                handleTeamCheck={handleTeamCheck}
                setLevel={setLevel}
                fetchDocuments={fetchDocuments}
                service_groups_documents_list={
                  service_groups_documents_list
                }
                setServiceGroupCurrentPage={setServiceGroupCurrentPage}
                handleServicegroupDocumentListPagination={
                  handleServicegroupDocumentListPagination
                }
                serviceGroupCurrentPage={serviceGroupCurrentPage}
                handleChangeTeamTab={handleChangeTeamTab}
                setCopyServiceModal={setCopyServiceModal}
                handleNewServiceGroupClick={handleNewServiceGroupClick}
                handleTeamSearch={handleTeamSearch}
                setServiceGroupData={setServiceGroupData}
                setSelectedDocuments={setSelectedDocuments}
                setTeamSearchInput={setTeamSearchInput}
                setClickedTeam={setClickedTeam}
                handleSelectedTeamDelete={handleSelectedTeamDelete}
                handleChange={handleChange}
              />
            </div>
          </Paper>

        </> :
          <div className={styles.wrapper}>
            {serviceGroupEntitySchema?.length !== 0 &&
              serviceGroupEntitySchema?.map((section) => {
                if (section.name === "ServiceGroup_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupsForServiceGroupEdit
                        section={section}
                        isEditMode={isEditMode}
                        serviceGroupData={serviceGroupData}
                        error={error}
                        setProjectData={setServiceGroupData}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupsForServiceGroupEdit
                          section={section}
                          serviceGroupData={serviceGroupData}
                          error={error}
                          selectedTeams={selectedTeams}
                          teams={teams}
                          level={level}
                          serviceGroupTeams={serviceGroupTeams}
                          teamTab={teamTab}
                          statuses={statuses}
                          documents={documents}
                          isEditMode={isEditMode}
                          parentServiceGroupId={parentServiceGroupId}
                          clickedTeam={clickedTeam}
                          teamLoader={teamLoader}
                          serviceStatuses={serviceStatuses}
                          teamSearchInput={teamSearchInput}
                          selectedDocuments={selectedDocuments}
                          allMicroserviceList={allMicroserviceList}
                          handleTeamCheck={handleTeamCheck}
                          setLevel={setLevel}
                          fetchDocuments={fetchDocuments}
                          service_groups_documents_list={
                            service_groups_documents_list
                          }
                          setServiceGroupCurrentPage={setServiceGroupCurrentPage}
                          handleServicegroupDocumentListPagination={
                            handleServicegroupDocumentListPagination
                          }
                          serviceGroupCurrentPage={serviceGroupCurrentPage}
                          handleChangeTeamTab={handleChangeTeamTab}
                          setCopyServiceModal={setCopyServiceModal}
                          handleNewServiceGroupClick={handleNewServiceGroupClick}
                          handleTeamSearch={handleTeamSearch}
                          setServiceGroupData={setServiceGroupData}
                          setSelectedDocuments={setSelectedDocuments}
                          setTeamSearchInput={setTeamSearchInput}
                          setClickedTeam={setClickedTeam}
                          handleSelectedTeamDelete={handleSelectedTeamDelete}
                          handleChange={handleChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
          </div>
        }
        <CopyServiceSubmitModal
          copyServiceModal={copyServiceModal}
          projectId={projectId}
          groupId={parentServiceGroupId}
          setCopyServiceModal={setCopyServiceModal}
          handleSubmit={(data) =>
            addMicroserviceInServicegroupListData([
              ...allMicroserviceList,
              data,
            ])
          }
        />
      </form>
    </main>
  );
};
const mapDispatchToProps = {
  add_serviceGroup_documents_data,
  add_service_group_documents_filter,
  service_group_document_sort,
  addMicroserviceInServicegroupListData,
  updateServiceGroupMode,
  getServiceGroupsListByProject
};

const mapStateToProps = (state) => {
  const {
    service_groups_documents_list,
    serviceGroupsDocumentsFilters,
    serviceGroupDocumentSort,
  } = state.allDocumentsReducer;
  const { userInfo } = state.userDetails;
  const { allMicroserviceList } = state.serviceGroupReducer;
  const { selectedServiceGroup, serviceGroupMode } = state.projectReducer
  const { tabbedView } = state.userDetails.userInfo;

  return {
    service_groups_documents_list,
    userInfo,
    serviceGroupDocumentSort,
    serviceGroupsDocumentsFilters,
    allMicroserviceList,
    selectedServiceGroup,
    serviceGroupMode,
    tabbedView
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceGroup);
