import React, { useEffect, useState } from "react";
import inputStyles from "./InputStyles";
import { Grid } from "@material-ui/core";
import useTriggers from "../../../hooks/useTriggers";
import ErrorStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import Eye from "./Assets/eye.svg";
import EyeSlash from "./Assets/eye-slash.svg";

const InputAdvance = ({
  name,
  label,
  value,
  onChange,
  disabled,
  placeHolder,
  selectedComponent,
}) => {
  const [toggleShow, setToggleShow] = useState(false);
  const styles = inputStyles();
  const classes = ErrorStyles();
  const { handleTrigger } = useTriggers();

  const onEnter = (e) => {
    if (selectedComponent["onEnter"] && e.key == "Enter")
      handleTrigger({
        action: selectedComponent?.["onEnter"],
        isUpdateState: true,
        componentId: selectedComponent?.id,
      });
  };

  useEffect(() => {
    if (selectedComponent?.textType != "password") {
      setToggleShow(false);
    }
  }, [selectedComponent?.textType]);
  return (
    <Grid
      container
      direction={selectedComponent?.direction === false ? "row" : "column"}
      spacing={1}
    >
      <Grid
        item
        container
        xs={selectedComponent?.direction === false ? 3 : 12}
        justifyContent={
          selectedComponent?.alignment === false ? "flex-start" : "flex-end"
        }
        alignItems="center"
      >
        <Grid
          item
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: selectedComponent?.style?.bold ? "bold" : undefined,
            fontStyle: selectedComponent?.style?.italic ? "italic" : undefined,
            textDecoration: selectedComponent?.style?.underline
              ? "underline"
              : undefined,
            fontFamily: selectedComponent?.fontFamily,
          }}
          className="apply-font"
        >
          {label}
          {selectedComponent?.isRequired === true && (
            <span className={classes.red}>*</span>
          )}
        </Grid>
      </Grid>
      <Grid item xs={selectedComponent?.direction === false ? 9 : 12}>
        <label
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          <input
            name={name}
            value={value}
            onChange={onChange}
            autoComplete="off"
            className={styles.input}
            disabled={disabled}
            placeholder={placeHolder}
            onKeyDown={onEnter}
            style={{
              border:
                selectedComponent?.disabled?.dataValue !== "true"
                  ? `1px solid ${selectedComponent?.status}`
                  : undefined,
              fontFamily: selectedComponent?.fontFamily,
              fontSize: selectedComponent?.fontSize
                ? selectedComponent?.fontSize
                : 16,
            }}
            type={
              toggleShow
                ? selectedComponent?.textType === "password"
                  ? "text"
                  : "password"
                : selectedComponent?.textType
            }
          />
          {selectedComponent?.textType === "password" && (
            <span
              style={{
                marginRight: 10,
                marginTop: 8,
                position: "absolute",
                right: 0,
              }}
              onClick={() => setToggleShow((prev) => !prev)}
            >
              {toggleShow ? (
                <img src={Eye} alt="Eye" className={styles.icon} />
              ) : (
                <img src={EyeSlash} alt="eye" className={styles.icon} />
              )}
            </span>
          )}
        </label>
      </Grid>
    </Grid>
  );
};

export default InputAdvance;
