import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
} from "@material-ui/core";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/ButtonOld";
import { useHistory, useParams } from "react-router-dom";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect, useSelector } from "react-redux";
import { IssueDetails } from "../../services/issueDetails";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import Loader from "../../components/common/stuff/Loader";
import Lightbox from "react-image-lightbox";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import { initError } from "../../utils/common";
import User from "../UserProfile/Assets/profile.svg";
import { BASE_URL } from "../../services/helper/config";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import EditHelpDeskEntity from "../../components/common/entitySchema/helpDesk/EditHelpDeskEntity";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../components/common/Accordion/AccordionNavigation";
import SectionTabs from "../../components/Tabbed/SectionTabs";
const _IssueDetails = new IssueDetails();

const EditTicket = (props) => {
  const [ticketId] = useState(props.match.params.id);
  const styles = manageScreenStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  const [issueData, setissueData] = useState("");
  const [comment, setComment] = useState("");
  const [imagesObj, setImagesObj] = useState({});
  const [messageIds, setMessageIds] = useState([]);

  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  let [photoIndex, setphotoIndex] = useState(0);

  // state for making images array after getting it from the BE
  let [imgArray, setimgArray] = useState([]);
  const [random, setRandom] = useState(Math.random());

  // files for getting extra scs attached
  let [files, setfiles] = useState([]);

  // entity
  const [helpDeskEntitySchema, setHelpDeskEntitySchema] = useState([]);
  const [helpDeskData, setHelpDeskData] = useState({});

  const [uploadedScreenshotFlag, setUploadedScreenshotFlag] = useState(false);
  const [flagReplied, setflagReplied] = useState(false);
  const [isLoaderImage, setIsLoaderImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  //for the status
  const [statuses, setStatuses] = useState([]);
  //

  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = userDetails;
  const authReducer = useSelector((state) => state.authReducer);
  const { accesstoken } = authReducer;

  /* eslint-disable */
  const [error, setError] = useState({
    category: false,
    issue: false,
    images: false,
  });

  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };
  const handleImageChange = (event, image) => {
    const newDoc = files.map((e) => {
      if (e.name === image.name) {
        const renameFile = new File([e], event.target.value);
        return renameFile;
      } else {
        return e;
      }
    });
    setfiles(newDoc);
  };

  // for deleting existing screenshots
  const handleDeleteImage = async (imageToDelete) => {
    setLoading(true);
    try {
      const { data } = await _IssueDetails.deleteScreenshot(
        issueData._id,
        encodeURIComponent(imageToDelete)
      );
      if (data._status === 200 || data._status === 201) {
        setLoading(false);
        setUploadedScreenshotFlag(!uploadedScreenshotFlag);
        enqueueSnackbar(data._msg ? data._msg : "Deleted successfully", {
          variant: "success",
        });
        setRandom(Math.random());
      }
    } catch (error) {
      setRandom(Math.random());
      setLoading(false);
      const { _msg, _status, data } = error.response.data;
      if (_msg) {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  // for deleting newly uploaded scs
  const handleDeleteNewScreenshot = (imageToDelete, index) => {
    let modifiedFiles = files.filter((img) => img.name !== imageToDelete.name);
    setfiles(modifiedFiles);
    setRandom(Math.random());
  };

  const handleSubmitComment = async () => {
    if (comment === "") {
      enqueueSnackbar("Please enter a valid comment", {
        variant: "error",
      });
    } else {
      setLoading(true);
      const data = await _IssueDetails.replyIssue(id, { message: comment });
      if (data) {
        setflagReplied(true);
        setComment("");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getDataById = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await _IssueDetails.fetchIssueById(ticketId);
    setissueData(data);
    setLoading(false);
    setflagReplied(false);
    setHelpDeskData(data);
  };
  useEffect(() => {
    if (flagReplied || uploadedScreenshotFlag) {
      getDataById();
    }
  }, [flagReplied, uploadedScreenshotFlag]);

  // for sending extra scs
  const handleImg = (newFiles) => {
    let tempFiles = [...files];
    newFiles.map((file) => {
      if (files.filter((f) => f.name === file.name).length === 0) {
        tempFiles.push(file);
      }
    });
    if (tempFiles.length !== 0) {
      setfiles(tempFiles);
    }
  };

  const handleSaveButton = async (e) => {
    var formData = new FormData();
    for (let i in files) {
      formData.append("file", files[i]);
    }
    if (files.length > 0) {
      try {
        setLoading(true);
        const { data } = await _IssueDetails.addScreenshots(
          issueData._id,
          formData
        );
        const { data: ModifiedData } = await _IssueDetails.changeTicketStatus(
          issueData._id,
          helpDeskData
        );
        if (data._status === 200 || (201 && ModifiedData._status === 200)) {
          setLoading(false);
          enqueueSnackbar(data._msg ? "Ticket updated successfully" : "", {
            variant: "success",
          });
          history.push("/helpdesk");
        }
      } catch (error) {
        setLoading(false);
        const { _msg, _status, data } = error.response.data;
        if (_msg) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      try {
        setLoading(true);
        const { data, _status } = await _IssueDetails.changeTicketStatus(
          issueData._id,
          helpDeskData
        );
        if (_status === 200 || 201) {
          setLoading(false);
          enqueueSnackbar(data._msg ? "Ticket updated successfully" : "", {
            variant: "success",
          });
          history.push("/helpdesk");
        }
      } catch (error) {
        setLoading(false);
        const { _msg } = error.response.data;
        if (_msg) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    }
    // for editing ticket
  };
  // fetching screenshots from the backend
  useEffect(async () => {
    if (issueData) {
      setIsLoaderImage(true);
      let tempFiles = [];
      for (let i in issueData.screenshots) {
        let response = await fetch(
          `${BASE_URL}/api/v1.0.0/issues/${issueData._id}/screenshot/${issueData.screenshots[i]}`,
          {
            headers: {
              organization: "60ba3575e1bc3c06e220ab5a",
              token: accesstoken,
            },
          }
        );

        let val = await response.blob();
        tempFiles.push(val);
      }
      setimgArray(tempFiles);
      setIsLoaderImage(false);
    }
  }, [issueData]);

  const fetchStatusesByResource = async (resource, callback) => {
    const { _msg, _status, data } = await fetchStatusResourceByName(resource);
    if (_status === 200) {
      callback(data);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  // entity
  useEffect(() => {
    const fetchHelpDeskEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Helpdesk",
        "edit"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        // console.log("vendors entity : ", data);
        setHelpDeskEntitySchema(entity);
        getDataById();
        const projData = {};
        data?.sections?.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        delete projData["conversation"];
        delete projData["uploadimage"];
        // console.log("projData : ", projData);
        setHelpDeskData({ ...helpDeskData, ...projData });
        const errData = {};
        data?.sections?.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });
        delete errData["conversation"];
        delete errData["uploadimage"];
        // console.log("errData : ", errData);
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };

    fetchHelpDeskEntitySchema();
    fetchStatusesByResource("Helpdesk", (data) =>
      setStatuses(data ? data.status : [])
    );
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setHelpDeskData({
      ...helpDeskData,
      [event.target.name]: value,
    });
  };

  const handleAssign = async () => {
    if (issueData && issueData.assignedtosuperadmin) {
      enqueueSnackbar("Issue already assigned to super admin", {
        variant: "error",
      });
    } else {
      setLoading(true);
      try {
        const { data } = await _IssueDetails.assignToSuperAdmin(
          ticketId,
          "test"
        );
        if (data._status === 200 || data._status === 201) {
          getDataById();
          setLoading(false);
          enqueueSnackbar(
            data._msg ? data._msg : "Ticket sent to super admin successfully",
            {
              variant: "success",
            }
          );
        }
      } catch (error) {
        setLoading(false);
        const { _msg, _status, data } = error.response.data;
        if (_msg) {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    checkScreenPermission("Helpdesk", ["Edit"]);
  }, []);

  const getImages = async () => {
    let obj = {
      userIDs: messageIds,
    };
    const { data } = await _IssueDetails.fetchProfileImages(obj);
    setImagesObj(data?.data);
  };

  useEffect(() => {
    if (messageIds?.length) {
      getImages();
    }
  }, [messageIds]);
  useEffect(() => {
    if (issueData?.messages?.length) {
      const data = issueData?.messages?.map((msg) => msg?.userid);
      let uniqe = [...new Set(data)];
      setMessageIds(uniqe);
    }
  }, [issueData]);
  return (
    <main className={styles.main}>
      <form>
        {issueData === "" && <Loader />}
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Help Desk", path: "/helpdesk" },
              { title: issueData?.issueid },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            {loading && <Loader />}
            <Grid item container justify="flex-end">
              <Grid item container xs={6}>
                <h2 className={styles.heading}>
                  Edit - {issueData.issueid ? issueData.issueid : ""}
                </h2>
              </Grid>
              <Grid item container xs={6} justify="flex-end">
                <Grid item>
                  <AppButton
                    color="primary"
                    variant="contained"
                    className={`btnsmall ${styles.transformBtn}`}
                    buttonName="Transfer to Kailash Support"
                    disabled={
                      !checkCompPermission("Helpdesk", ["Forward To Admin"])
                    }
                    style={{ padding: "6px 15px", marginBottom: 5 }}
                    onClick={() => handleAssign()}
                  />
                </Grid>
                <Grid item>
                  {tabbedView ? (
                    <TabbedNavigation
                      isEditMode={true}
                      handleSave={handleSaveButton}
                      handleCancel={() => history.goBack()}
                    />
                  ) : (
                    <>
                      {/* // <AppButton
                    //   color="primary"
                    //   buttonName="Save"
                    //   variant="contained"
                    //   // disabled={false}
                    //   style={{ padding: "6px 15px", marginBottom: 5 }}
                    //   className="btnsmall"
                    //   disabled={!checkCompPermission("Helpdesk", ["Edit"])}
                    //   onClick={() => handleSaveButton()}
                    // /> */}
                      <div style={{ marginLeft: "12px" }}>
                        <AccordionNavigation
                          isEditMode={false}
                          handleSave={handleSaveButton}
                          handleCancel={() => history.goBack()}
                        />
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={styles.wrapper} style={{ marginTop: "3.5rem" }}>
          {tabbedView ? (
            <div style={{ margin: "5rem 0 0 5px" }}>
              <SectionTabs
                entity={helpDeskEntitySchema ? helpDeskEntitySchema : []}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              {helpDeskEntitySchema &&
                helpDeskEntitySchema.map((section) => {
                  if (
                    section.name === "Helpdesk_Header_Section" &&
                    activeTab == 0
                  ) {
                    return (
                      <div key={section._id} style={{ padding: "15px 5px 0" }}>
                        <EditHelpDeskEntity
                          section={section}
                          helpDeskData={helpDeskData}
                          setHelpDeskData={setHelpDeskData}
                          error={error}
                          setError={setError}
                          handleChange={handleChange}
                          handleImg={handleImg}
                          statuses={statuses}
                        />
                      </div>
                    );
                  } else if (
                    section.name === "Helpdesk_Image_Section" &&
                    activeTab == 1
                  ) {
                    return (
                      <div style={{ marginLeft: "1rem" }}>
                        <Grid container style={{ border: !"1px solid red" }}>
                          <Grid item xs={4}>
                            <EditHelpDeskEntity
                              section={section}
                              helpDeskData={helpDeskData}
                              setHelpDeskData={setHelpDeskData}
                              error={error}
                              setError={setError}
                              handleChange={handleChange}
                              handleImg={handleImg}
                              random={random}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Grid item container xs style={{ marginTop: 20 }}>
                              <Grid item container xs>
                                {issueData.screenshots &&
                                  issueData.screenshots.map((image, index) => (
                                    <Grid
                                      container
                                      style={{ marginBottom: 23 }}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                          marginLeft: "auto",
                                        }}
                                      >
                                        <Avatar
                                          alt="img"
                                          variant="circle"
                                          src={
                                            imgArray.length !== 0 &&
                                            URL.createObjectURL(imgArray[index])
                                          }
                                          className={styles.avatar}
                                          onClick={() => {
                                            setViewerIsOpen(true);
                                            setphotoIndex(index);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "auto",
                                            marginRight: 16,
                                          }}
                                        />
                                        {isLoaderImage && (
                                          <CircularProgress
                                            className={styles.fabProgress}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={7}>
                                        <label
                                          htmlFor=""
                                          className={styles.fileName}
                                        >
                                          File Name
                                        </label>
                                        <input
                                          type="text"
                                          className={styles.input}
                                          name="name"
                                          value={image}
                                          autoComplete="off"
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          color="inherit"
                                          aria-label="delete"
                                          edge="start"
                                          onClick={() =>
                                            handleDeleteImage(image)
                                          }
                                          className={styles.actionIcon}
                                        >
                                          <img
                                            src="/images/deleteLine.svg"
                                            alt="card"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  ))}

                                {files.length > 0 &&
                                  files.map((image, index) => (
                                    <Grid
                                      container
                                      style={{ marginBottom: 23 }}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "end",
                                          marginTop: 20,
                                          marginRight: 20,
                                          marginLeft: "auto",
                                        }}
                                      >
                                        <Avatar
                                          alt="img"
                                          src={URL.createObjectURL(image)}
                                          className={styles.avatar}
                                          onClick={() => {
                                            // setViewerIsOpen(true);
                                            setphotoIndex(index);
                                          }}
                                          variant="square"
                                        />
                                      </Grid>
                                      <Grid item xs={7}>
                                        <label
                                          htmlFor=""
                                          className={styles.fieldHeading}
                                        >
                                          File Name
                                        </label>
                                        <input
                                          type="text"
                                          className={styles.input}
                                          name="name"
                                          value={image.name.replace(
                                            /[&\/\\#,+()$~%_'":*?<>{}]/g,
                                            ""
                                          )}
                                          onChange={(e) =>
                                            handleImageChange(e, image)
                                          }
                                          autoComplete="off"
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          color="inherit"
                                          aria-label="delete"
                                          edge="start"
                                          onClick={() =>
                                            handleDeleteNewScreenshot(
                                              image,
                                              index
                                            )
                                          }
                                          className={styles.actionIcon}
                                        >
                                          <img
                                            src="/images/deleteLine.svg"
                                            alt="card"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (
                    section.name === "Helpdesk_Comment_Section" &&
                    activeTab == 2
                  ) {
                    return (
                      <div style={{ marginLeft: "1rem" }}>
                        <Grid container direction="column">
                          <Grid item>
                            <EditHelpDeskEntity
                              section={section}
                              helpDeskData={helpDeskData}
                              setHelpDeskData={setHelpDeskData}
                              error={error}
                              setError={setError}
                              handleChange={handleChange}
                              handleImg={handleImg}
                              comment={comment}
                              setComment={setComment}
                              handleSubmitComment={handleSubmitComment}
                            />
                            <Grid item>
                              {issueData.messages &&
                                issueData.messages.map((message, index) => (
                                  <Grid
                                    container
                                    spacing={3}
                                    style={{
                                      margin: "10px -10px",
                                      width: "80%",
                                      // marginLeft:
                                      //   index % 2 !== 0 ? 'auto' : 'unset',
                                    }}
                                    key={index}
                                  >
                                    <Grid item>
                                      <Avatar
                                        alt="img"
                                        src={
                                          imagesObj[message?.userid]
                                            ? imagesObj[message?.userid]
                                            : User
                                        }
                                        className={styles.avatarProfile}
                                      />
                                    </Grid>
                                    <Grid item sm={10} xs={10}>
                                      <span className={styles.fieldHeading}>
                                        {message.createdby}
                                      </span>
                                      <span
                                        style={{
                                          font: "normal normal normal 12px/16px Nunito",
                                          color: "#A8A8A8",
                                          marginLeft: 10,
                                        }}
                                      >
                                        {moment
                                          .unix(message.createdon)
                                          .tz(userInfo.timeZone)
                                          .format(
                                            userInfo.time === "h:mm"
                                              ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                              : `${userInfo.dateFormat} ${userInfo.time}`
                                          )}
                                      </span>
                                      <p
                                        style={{
                                          font: "normal normal normal 14px/19px Nunito",
                                          color: "#000000",
                                        }}
                                      >
                                        {message.message}
                                      </p>
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  }
                })}{" "}
            </div>
          ) : (
            <>
              {helpDeskEntitySchema &&
                helpDeskEntitySchema.map((section) => {
                  if (section.name === "Helpdesk_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "15px 5px 0" }}>
                        <EditHelpDeskEntity
                          section={section}
                          helpDeskData={helpDeskData}
                          setHelpDeskData={setHelpDeskData}
                          error={error}
                          setError={setError}
                          handleChange={handleChange}
                          handleImg={handleImg}
                          statuses={statuses}
                        />
                      </div>
                    );
                  } else if (section.name === "Helpdesk_Image_Section") {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container style={{ border: !"1px solid red" }}>
                            <Grid item xs={4}>
                              <EditHelpDeskEntity
                                section={section}
                                helpDeskData={helpDeskData}
                                setHelpDeskData={setHelpDeskData}
                                error={error}
                                setError={setError}
                                handleChange={handleChange}
                                handleImg={handleImg}
                                random={random}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <Grid item container xs style={{ marginTop: 20 }}>
                                <Grid item container xs>
                                  {issueData.screenshots &&
                                    issueData.screenshots.map(
                                      (image, index) => (
                                        <Grid
                                          container
                                          style={{ marginBottom: 23 }}
                                        >
                                          <Grid
                                            item
                                            xs={3}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              marginTop: 20,
                                              marginLeft: "auto",
                                            }}
                                          >
                                            <Avatar
                                              alt="img"
                                              variant="circle"
                                              src={
                                                imgArray.length !== 0 &&
                                                URL.createObjectURL(
                                                  imgArray[index]
                                                )
                                              }
                                              className={styles.avatar}
                                              onClick={() => {
                                                setViewerIsOpen(true);
                                                setphotoIndex(index);
                                              }}
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginRight: 16,
                                              }}
                                            />
                                            {isLoaderImage && (
                                              <CircularProgress
                                                className={styles.fabProgress}
                                              />
                                            )}
                                          </Grid>
                                          <Grid item xs={7}>
                                            <label
                                              htmlFor=""
                                              className={styles.fileName}
                                            >
                                              File Name
                                            </label>
                                            <input
                                              type="text"
                                              className={styles.input}
                                              name="name"
                                              value={image}
                                              autoComplete="off"
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <IconButton
                                              color="inherit"
                                              aria-label="delete"
                                              edge="start"
                                              onClick={() =>
                                                handleDeleteImage(image)
                                              }
                                              className={styles.actionIcon}
                                            >
                                              <img
                                                src="/images/deleteLine.svg"
                                                alt="card"
                                              />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      )
                                    )}

                                  {files.length > 0 &&
                                    files.map((image, index) => (
                                      <Grid
                                        container
                                        style={{ marginBottom: 23 }}
                                      >
                                        <Grid
                                          item
                                          xs={3}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "end",
                                            marginTop: 20,
                                            marginRight: 20,
                                            marginLeft: "auto",
                                          }}
                                        >
                                          <Avatar
                                            alt="img"
                                            src={URL.createObjectURL(image)}
                                            className={styles.avatar}
                                            onClick={() => {
                                              // setViewerIsOpen(true);
                                              setphotoIndex(index);
                                            }}
                                            variant="square"
                                          />
                                        </Grid>
                                        <Grid item xs={7}>
                                          <label
                                            htmlFor=""
                                            className={styles.fieldHeading}
                                          >
                                            File Name
                                          </label>
                                          <input
                                            type="text"
                                            className={styles.input}
                                            name="name"
                                            value={image.name.replace(
                                              /[&\/\\#,+()$~%_'":*?<>{}]/g,
                                              ""
                                            )}
                                            onChange={(e) =>
                                              handleImageChange(e, image)
                                            }
                                            autoComplete="off"
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <IconButton
                                            color="inherit"
                                            aria-label="delete"
                                            edge="start"
                                            onClick={() =>
                                              handleDeleteNewScreenshot(
                                                image,
                                                index
                                              )
                                            }
                                            className={styles.actionIcon}
                                          >
                                            <img
                                              src="/images/deleteLine.svg"
                                              alt="card"
                                            />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else if (section.name === "Helpdesk_Comment_Section") {
                    return (
                      <>
                        <Accordion
                          className={styles.fieldPanel}
                          defaultExpanded={true}
                          key={section._id}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ArrowDropDownRounded
                                className={styles.accordianIcon}
                              />
                            }
                          >
                            <Typography className={styles.sectionHeading}>
                              {section["section Label"]}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container direction="column">
                              <Grid item>
                                <EditHelpDeskEntity
                                  section={section}
                                  helpDeskData={helpDeskData}
                                  setHelpDeskData={setHelpDeskData}
                                  error={error}
                                  setError={setError}
                                  handleChange={handleChange}
                                  handleImg={handleImg}
                                  comment={comment}
                                  setComment={setComment}
                                  handleSubmitComment={handleSubmitComment}
                                />
                                <Grid item>
                                  {issueData.messages &&
                                    issueData.messages.map((message, index) => (
                                      <Grid
                                        container
                                        spacing={3}
                                        style={{
                                          margin: "10px -10px",
                                          width: "80%",
                                          // marginLeft:
                                          //   index % 2 !== 0 ? 'auto' : 'unset',
                                        }}
                                        key={index}
                                      >
                                        <Grid item>
                                          <Avatar
                                            alt="img"
                                            src={
                                              imagesObj[message?.userid]
                                                ? imagesObj[message?.userid]
                                                : User
                                            }
                                            className={styles.avatarProfile}
                                          />
                                        </Grid>
                                        <Grid item sm={10} xs={10}>
                                          <span className={styles.fieldHeading}>
                                            {message.createdby}
                                          </span>
                                          <span
                                            style={{
                                              font: "normal normal normal 12px/16px Nunito",
                                              color: "#A8A8A8",
                                              marginLeft: 10,
                                            }}
                                          >
                                            {moment
                                              .unix(message.createdon)
                                              .tz(userInfo.timeZone)
                                              .format(
                                                userInfo.time === "h:mm"
                                                  ? `${userInfo.dateFormat}, ${userInfo.time} A`
                                                  : `${userInfo.dateFormat} ${userInfo.time}`
                                              )}
                                          </span>
                                          <p
                                            style={{
                                              font: "normal normal normal 14px/19px Nunito",
                                              color: "#000000",
                                            }}
                                          >
                                            {message.message}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded={true}
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <EditHelpDeskEntity
                            section={section}
                            helpDeskData={helpDeskData}
                            setHelpDeskData={setHelpDeskData}
                            error={error}
                            setError={setError}
                            handleChange={handleChange}
                            handleImg={handleImg}
                            random={random}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </>
          )}
        </div>
      </form>
      {viewerIsOpen && (
        <Lightbox
          wrapperClassName={styles.testModal}
          imagePadding={75}
          enableZoom={true}
          onCloseRequest={() => setViewerIsOpen(false)}
          mainSrc={URL.createObjectURL(imgArray[photoIndex])}
          nextSrc={URL.createObjectURL(
            imgArray[(photoIndex + 1) % imgArray.length]
          )}
          prevSrc={URL.createObjectURL(
            imgArray[(photoIndex + imgArray.length - 1) % imgArray.length]
          )}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + imgArray.length - 1) % imgArray.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % imgArray.length)
          }
        />
      )}
    </main>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTicket);
// export default EditTicket;
