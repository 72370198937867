/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Paper,
  Checkbox,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import { connect, useSelector } from "react-redux";
import { setApplicationDeployment } from "../../../redux/actions/applicationAction";
import { useSnackbar } from "notistack";
import useStyles from "../../common/styles/InputTableStyle";
import AddEnvironmentServicesModal from "./AddEnvironmentServicesModal";
import { Tooltip } from "@mui/material";
import GetAppIcon from "@material-ui/icons/GetApp";
import { BASE_URL } from "../../../services/helper/config";
import { getApplicationDataByID } from "../../../utils/orchestration/applicationUtils";
const tempStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    // padding: 15,

    "&::before": {
      borderBottom: "unset",
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
}));

function ApplicationDeploymentTable(props) {
  const {
    applicationName,
    disabled,
    imagesList,
    applicationDeployment,
    setApplicationDeployment,
    applicationID,
    createMode,
  } = props;
  const styles = useStyles();
  const tStyles = tempStyles();
  // const [envs, setEnvs] = useState([]);
  const [showModal, setModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [containerName, setContainerName] = useState();
  const [runTimeEnvId, setRunTimeEnvId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const handleContainerChange = (val, id, column) => {
    const newDeployment = applicationDeployment.map((env) => {
      if (env.id === id) {
        return {
          ...env,
          container_name: val,
        };
      } else {
        return env;
      }
    });
    setApplicationDeployment(newDeployment);
  };

  useEffect(() => {
    setContainerName(
      applicationDeployment &&
        applicationDeployment?.map((item) => item.container_name)
    );
  }, [applicationDeployment]);

  const handleImageSelect = (newVal, id) => {
    if (newVal) {
      const newDeployment = applicationDeployment.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            container_name: item.container_name
              ? item.container_name.replace(/ /g, "_")
              : "".concat(applicationName.replace(/ /g, "_"), newVal.imagename),
            imagename: newVal.imagename,
            imagetag: newVal.imagetag,
            image: "".concat(newVal.imagename, ":", newVal.imagetag),
            imageid: newVal._id,
            rowInput: null,
          };
        } else {
          return item;
        }
      });
      setApplicationDeployment(newDeployment);
    }
  };

  const handleInputValueChange = (newVal, id) => {
    if (newVal) {
      const newDeployment = applicationDeployment.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            rowInput: newVal,
          };
        } else {
          return item;
        }
      });
      setApplicationDeployment(newDeployment);
    }
  };
  useEffect(() => {}, [imagesList]);

  const handleAddDeployment = (id) => {
    const { imagename, imagetag } = imagesList[0];
    // var tempImage = "".concat(imagename, ":", imagetag)
    setApplicationDeployment([
      ...applicationDeployment,
      {
        id: uuidv4(),
        container_name: "",
        imageid: "",
        image: "".concat(imagename, ":", imagetag),
        environment: {},
        rowInput: "",
        type: "custom",
      },
    ]);
  };

  const handleDeleteEnv = (deleteID) => {
    if (applicationDeployment.length > 1) {
      var newDeployment = applicationDeployment.filter(
        (item) => item.id !== deleteID
      );
      setApplicationDeployment(newDeployment);
    } else {
      enqueueSnackbar("Service Bundle must contain atleast one Deployment", {
        variant: "error",
      });
    }
  };

  const handleModalIconOpen = (data) => {
    if (data) {
      setSelectedData(data);
      setModal(true);
    }
  };

  const handleModalClose = (data_obj) => {
    if (data_obj) {
      const { id, environment } = data_obj;
      const new_obj =
        applicationDeployment.length > 0 &&
        applicationDeployment.map((item, index) => {
          if (item.id === id) {
            return { ...item, environment: environment };
          } else {
            return item;
          }
        });
      setApplicationDeployment(new_obj);
      setModal(false);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [stateDisable, setStateDisable] = useState();
  const handleClick = (data, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const userData = useSelector((state) => state.authReducer);
  useEffect(() => {
    const fetchData = async () => {
      const { _msg, _status, data } = await getApplicationDataByID(
        applicationID
      );
      if (_status === 200) {
        if (data) {
          setStateDisable(data?.state && data?.state);
          setRunTimeEnvId(data?.runtime_env);
          if (data?.deployment?.services.length !== 0) {
            setApplicationDeployment(data?.deployment?.services);
          } else if (
            imagesList.length !== 0 &&
            applicationDeployment.length !== 0 &&
            applicationDeployment.length > 1
          ) {
            const { imagename, imagetag } = imagesList[0];
            // var tempImage = "".concat(imagename, ":", imagetag)
            setApplicationDeployment([
              ...applicationDeployment,
              {
                id: uuidv4(),
                container_name: "",
                imageid: "",
                image: "".concat(imagename, ":", imagetag),
                environment: {},
                rowInput: "",
                type: "custom",
              },
            ]);
          }
        }
      }
    };

    if (createMode !== "create") {
      fetchData();
    } else if (createMode === "create") {
      setApplicationDeployment([]);
    }
  }, []);

  const handleTodayLogsDownload = async () => {
    setAnchorEl(null);
    var timestamp = Math.round(new Date().getTime() / 1000);
    let response = await fetch(
      `${BASE_URL}/api/container/environments/${runTimeEnvId}/dockercontainers/${selectedData}/logs?timestamp=${timestamp}`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "logs";
      document.body.appendChild(a);
      a.click();
      a.remove();
      enqueueSnackbar("Container Logs Downloaded Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const handleAllLogsDownload = async () => {
    setAnchorEl(null);
    let response = await fetch(
      `${BASE_URL}/api/container/environments/${runTimeEnvId}/dockercontainers/${selectedData}/logs`,
      {
        headers: {
          organization: "60ba3575e1bc3c06e220ab5a",
          token: userData?.accesstoken,
        },
      }
    );
    if (response.status === 200) {
      let blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "AllLogs";
      document.body.appendChild(a);
      a.click();
      a.remove();
      enqueueSnackbar("Container Logs Downloaded Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Unable to download, please try after sometime...", {
        variant: "error",
      });
    }
  };

  const PopOverComponent = (props) => {
    const { open, anchorEl, handleClose, ContainerName } = props;
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <List dense={true}>
            <ListItem button onClick={() => handleTodayLogsDownload()}>
              <ListItemText primary="Today" />
            </ListItem>
            <ListItem button onClick={() => handleAllLogsDownload()}>
              <ListItemText primary="All" />
            </ListItem>
          </List>
        </div>
      </Popover>
    );
  };
  return (
    <div style={{ marginTop: "1rem" }}>
      <div className={tStyles.container}>
        {/* {loading && <Loader />} */}
        <Paper>
          <TableContainer>
            <Table className={styles.customTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Container Name</TableCell>
                  <TableCell align="left">Image ID</TableCell>
                  {/* <TableCell align="left">Restart</TableCell> */}
                  <TableCell align="center">Environment</TableCell>
                  <TableCell style={{ width: "9rem", paddingLeft: "2.5rem" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicationDeployment &&
                  applicationDeployment?.length > 0 &&
                  applicationDeployment?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "20rem" }}
                      >
                        <input
                          type="text"
                          name={"container_name"}
                          className={styles.input}
                          value={row?.container_name}
                          onChange={(e) =>
                            handleContainerChange(
                              e.target.value,
                              row.id,
                              "container_name"
                            )
                          }
                          autoComplete="off"
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell style={{ width: "20rem" }}>
                        <Autocomplete
                          value={row}
                          inputValue={row["rowInput"]}
                          style={{ width: "100%" }}
                          options={imagesList}
                          getOptionLabel={(option) =>
                            option?.imagename && option?.imagetag
                              ? "".concat(
                                  option?.imagename,
                                  ":",
                                  option?.imagetag
                                )
                              : ""
                          }
                          // getOptionSelected={(opt, val) => val === "" ? false : true}
                          className={styles.searchInput3}
                          onChange={(event, newValue) =>
                            handleImageSelect(newValue, row.id)
                          }
                          onInputChange={(event, newVal) =>
                            handleInputValueChange(newVal, row.id)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Select Image"
                            />
                          )}
                          disabled={disabled}
                        />
                      </TableCell>

                      <TableCell align="center" style={{ width: "4rem" }}>
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          className={styles.actionIcon}
                          onClick={() => handleModalIconOpen(row)}
                          disabled={disabled}
                        >
                          {!disabled && (
                            <img
                              src="/images/application_list.svg"
                              alt="card"
                            />
                          )}
                          {disabled && (
                            <img
                              src="/images/application_list_disabled.svg"
                              alt="card"
                            />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="edit"
                          edge="start"
                          onClick={handleAddDeployment}
                          style={{ marginRight: ".1rem" }}
                          // className={`${cardView ? 'actionActive' : ''} ${styles.actionIcons}`}
                          className={styles.actionIcon}
                          disabled={disabled}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          edge="start"
                          onClick={() => handleDeleteEnv(row.id)}
                          // className={`${cardView ? 'actionActive' : ''} ${styles.actionIcons}`}
                          className={styles.actionIcon}
                          disabled={disabled}
                          style={{ marginLeft: "0.5rem" }}
                        >
                          {!disabled && (
                            <img src="/images/deleteLine.svg" alt="card" />
                          )}
                          {disabled && (
                            <img
                              src="/images/deleteLine-disabled.svg"
                              alt="card"
                            />
                          )}
                        </IconButton>
                        <Tooltip title="Download Container Logs" arrow>
                          <IconButton
                            color="inherit"
                            aria-label="download"
                            edge="start"
                            className={styles.actionIcon}
                            style={{ marginLeft: "0.5rem" }}
                            onClick={(e) => handleClick(row?.container_name, e)}
                            // onClick={handleClick}
                            disabled={stateDisable !== "running" && true}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <div
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          >
                            <List dense={true}>
                              <ListItem
                                button
                                onClick={() => handleTodayLogsDownload()}
                              >
                                <ListItemText primary="Today" />
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => handleAllLogsDownload()}
                              >
                                <ListItemText primary="All" />
                              </ListItem>
                            </List>
                          </div>
                        </Popover>

                        {/* <PopOverComponent
                          open={open}
                          ContainerName={row?.container_name}
                          handleClose={handleClose}
                          anchorEl={anchorEl}
                        /> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {applicationDeployment.length === 0 && (
                <div className={styles.nodata}>
                  <text>No Data Available</text>
                </div>
              )}
            </Table>
          </TableContainer>
        </Paper>
        {showModal && (
          <AddEnvironmentServicesModal
            isOpen={showModal}
            id={selectedData.id}
            envs={selectedData["environment"]}
            setEnvs={(data_obj) => handleModalClose(data_obj)}
          />
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setApplicationDeployment,
};

const mapStateToProps = (state) => {
  const { applicationDeployment } = state.applicationReducer;
  return {
    applicationDeployment,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDeploymentTable);
