import { ApiHelper } from "./helper/ApiHelper";
import { ENTITY_SCHEMA } from "./helper/config";

const apiHelper = new ApiHelper();

export class EntitySchemaServices {
  getResourceByPermission(resouceId, permission) {
    const uri = `${ENTITY_SCHEMA}/api/v1.0.0/ontology/resources/${resouceId}`;
    return apiHelper.post(uri, {
      permission: permission,
    });
  }
  getResourceByName(resource) {
    const uri = `${ENTITY_SCHEMA}/api/v1.0.0/ontology/resources/${resource}`;
    return apiHelper.get(uri);
  }
}
