import React from 'react';
import FontPicker from 'font-picker-react';
import styles from './styles';

const CustomFont = ({ value, onChange }) => {
  const classes = styles();
  return (
    <div className={classes.fontContainer}>
      <FontPicker
        apiKey="AIzaSyALndwHjQKL7fr0yt4usKp_aK9NgSLnjPs"
        //   activeFontFamily={this.state.activeFontFamily}
        activeFontFamily={value}
        //   onChange={(nextFont) =>
        //     this.setState({
        //       activeFontFamily: nextFont.family,
        //     })
        //   }
        onChange={onChange}
      />
    </div>
  );
};

export default CustomFont;
