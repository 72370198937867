import { IconButton, Tab, Tabs } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { AppButton } from "../../../components/common/Button";
import useStyles from "../../CommonStyles/manageScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import listStyles from "../../CommonStyles/listScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { getProjectList } from "../../../redux/actions/projectAction";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import ServicesList from "../ServicesList";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../../utils/common";
import { addMicroService } from "../../../utils/project/microserviceUtils";
import { fetchServiceGroupListData } from "../../../utils/project/servicegroupUtils";
import { fetchBoilerPlateListData } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import Loader from "../../../components/common/stuff/Loader";
import CustomFieldGroupsForServiceCreate from "../../../components/common/entitySchema/service/CustomFieldGroupsForServiceCreate";
const AddServiceTabbed = (props) => {
  const { data, getProjectList } = props;
  const history = useHistory();
  const mystyles = useStyles();
  const style = listStyles();
  const [activeTab, setActiveTab] = useState(0);
  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [boilerPlateChecked, setBoilerPlateChecked] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [projectSearchInput, setProjectSearchInput] = useState("");
  const [serviceGroupSearchInput, setServiceGroupSearchInput] = useState("");
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);

  const [serviceData, setServiceData] = useState({
    name: "",
    imagename: "",
    description: "",
    versionTag: "",
    versionDescription: "",
    status: "",
    languageID: "",
    initializeboilerplateID: false,
    boilerplateID: "",
    projectID: "",
    servicegroupID: "",
    service_category:
      props.history.location.state?.data == "services"
        ? "Only API"
        : "Only GUI",
  });
  const [error, setError] = useState({
    name: { isError: false },
    projectID: { isError: false },
    servicegroupID: { isError: false },
    languageID: { isError: false },
  });

  const projects = data?.data && data?.data?.length > 0 ? data?.data : [];

  useEffect(() => {
    if (selectedProject._id) {
      fetchServiceGroupsFromProject();
      fetchLanguagesList();
    }
  }, [selectedProject]);

  const fetchServiceGroupsFromProject = async (filters) => {
    const { _msg, _status, data } = await fetchServiceGroupListData(
      selectedProject._id,
      0,
      filters
    );
    setServiceGroups([]);
    if (_status === 200) {
      if (data) {
        setServiceGroups(data);
      } else {
        enqueueSnackbar(
          "No Service Group under this project. Please select another project",
          { variant: "info" }
        );
        setServiceGroups([]);
      }
    } else if (_status === 404) {
      enqueueSnackbar(_msg, { variant: "info" });
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const fetchLanguagesList = async () => {
    const { _msg, _status, data } = await fetchLanguagesByProject(
      selectedProject._id
    );
    if (_status === 200) {
      setLanguages(data ? data : []);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("ServiceGroup", ["Create Microservice"]);
    const fetchServiceEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Microservice",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceEntitySchema(entity);
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });
        setServiceData((prevData) => ({ ...prevData, ...servData }));
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });
        delete errData["boilerplateID"];
        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        setError(errData);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchBoilerPlateListData();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "Microservice"
      );
      if (_status === 200) {
        setServiceData({
          ...serviceData,
          status: data?.status
            ? data?.status.find((s) => s.default === true)?.status
            : data?.status?.length && data?.status[0]?.status,
        });
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchServiceEntitySchema();
    if (checkCompPermission("Boilerplate", ["List All"])) {
      fetchBoilerPlates();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };

  useEffect(() => {
    handleProjectSearch();
  }, [projectSearchInput]);

  const handleProjectSearch = () => {
    if (projectSearchInput) {
      getProjectList(
        0,
        JSON.stringify({
          name: { $regex: projectSearchInput, $options: "i" },
        })
      );
    } else {
      getProjectList(0);
    }
  };

  useEffect(() => {
    if (projectSearchInput) {
      handleServiceGroupSearch();
    }
  }, [serviceGroupSearchInput]);

  const handleServiceGroupSearch = () => {
    if (serviceGroupSearchInput) {
      fetchServiceGroupsFromProject(
        JSON.stringify({
          name: { $regex: serviceGroupSearchInput, $options: "i" },
        })
      );
    } else {
      fetchServiceGroupsFromProject();
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const newServiceData = {
        ...serviceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
        };
      }
      setIsLoader(true);
      const { _msg, _status, data } = await addMicroService(newServiceData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);
        histroy.push("/services");
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            _msg
              ? _msg
              : "Unable to process your request, please try after sometime",
            { variant: "error" }
          );
        }
      }
    } else {
      setError(errors);
      enqueueSnackbar("Please check the mandatory input fields", {
        variant: "error",
      });
    }
  };
  const handleActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };
  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  const handleSave = () => {
    history.push(`/application`);
  };
  const handleCreate = () => {
    if (activeTab === 1) {
      history.push(`/services/add`);
    } else if (activeTab === 2) {
      history.push(`/aitask/add`);
    } else if (activeTab === 4) {
      history.push(`/host/create-deployment`);
    }
  };
  const handleCancel = () => {
    history.push(`/services`);
  };
  return (
    <main className={mystyles.main}>
      <div className={mystyles.topContent}>
        <BreadCrumbs
          items={[
            { title: "Service", path: "/services" },
            { title: "Create Service" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={mystyles.heading}>Create Service</h2>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "10px 0 10px",
            }}
          >
            {activeTab !== 0 ? (
              <AppButton
                aria-label="previous"
                color="primary"
                buttonName="Previous"
                variant="contained"
                startIcon={
                  <NavigateBeforeIcon
                    style={{ width: "25px", height: "28px" }}
                  />
                }
                className="btnsmall"
              // onClick={handlePrevious}
              />
            ) : (
              <></>
            )}
            <AppButton
              aria-label="previous"
              color="primary"
              buttonName={activeTab === 4 ? "Host" : "Create"}
              variant="contained"
              startIcon={<AddIcon style={{ width: "25px", height: "28px" }} />}
              className="btnsmall"
              onClick={handleCreate}
            />
            <AppButton
              aria-label="previous"
              color="primary"
              buttonName="Cancel"
              variant="contained"
              startIcon={
                <HighlightOffIcon style={{ width: "25px", height: "28px" }} />
              }
              className="btnsmall"
              onClick={handleCancel}
            />
            <AppButton
              aria-label="previous"
              color="primary"
              buttonName="Save"
              variant="contained"
              startIcon={<SaveIcon style={{ width: "25px", height: "28px" }} />}
              className="btnsmall"
              onClick={handleSubmit}
            />
            {activeTab !== 5 ? (
              <AppButton
                aria-label="previous"
                color="primary"
                buttonName="Next"
                variant="contained"
                startIcon={
                  <NavigateNextIcon style={{ width: "25px", height: "28px" }} />
                }
                className="btnsmall"
                onClick={handleNext}
              />
            ) : (
              <></>
            )}
          </div>
        </Grid>

        <div className={mystyles.topContentItems}>
          <div className={mystyles.topContentItem}>
            <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              indicatorColor="primary"
              textColor="primary"
              className={style.documentsListAccessTab}
            >
              <Tab label={`Details`} />
              <Tab label="Governance Section" />
              <Tab label="Boiler Plate Section" />
              <Tab label="Status Section" />
              <Tab label="Version Section" />
              <Tab label="API Section" />
              <Tab label="Documentation" />
            </Tabs>
          </div>
        </div>
        {/* <div className={styles.wrapper}> */}
        {activeTab === 0 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            if (section.name === "Microservice_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForServiceCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    projectSearchInput={projectSearchInput}
                    serviceGroupSearchInput={serviceGroupSearchInput}
                    selectedProject={selectedProject}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                  />
                </div>
              );
            }
          })}
        {activeTab === 1 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            if (section.name === "Microservice_Governance_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForServiceCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    languages={languages}
                    boilerplates={boilerplates}
                    selectedProject={selectedProject}
                    boilerPlateChecked={boilerPlateChecked}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                    setError={setError}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setBoilerPlateChecked={setBoilerPlateChecked}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                  />
                </div>
              );
            }
          })}
        {activeTab === 2 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            if (section.name === "Microservice_Boilerplate_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForServiceCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    languages={languages}
                    boilerplates={boilerplates}
                    selectedProject={selectedProject}
                    boilerPlateChecked={boilerPlateChecked}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                    setError={setError}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setBoilerPlateChecked={setBoilerPlateChecked}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                  />
                </div>
              );
            }
          })}
        {activeTab === 3 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            if (section.name === "Microservice_Documentation_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForServiceCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    languages={languages}
                    boilerplates={boilerplates}
                    selectedProject={selectedProject}
                    boilerPlateChecked={boilerPlateChecked}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                    setError={setError}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setBoilerPlateChecked={setBoilerPlateChecked}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                  />
                </div>
              );
            }
          })}
      </div>
      {/* {activeTab === 0 && <ServicesList />} */}
      {/* {activeTab === 1 && <AddApplicationSelectService />}
        {activeTab === 2 && <AddApplicationSelectAiTask />} */}
      {/* </div> */}
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
};

const mapStateToProps = (state) => {
  const { projectsList } = state.projectReducer;
  const { data, error, loading, _totalcount } = projectsList;
  return {
    data,
    error,
    loading,
    _totalcount,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceTabbed);
