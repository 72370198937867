import React, { useState, useEffect } from "react";
import { AppButton } from "../../components/common/Button";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChromePicker } from "react-color";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomTheme from "./CustomTheme";
import Loader from "../../components/common/stuff/Loader";
import { useDispatch, useSelector } from "react-redux";
import manageStyle from "../CommonStyles/UserProfile";
import Switch from "@material-ui/core/Switch";

import {
  changeTabByUser,
  editUserDetails,
} from "../../redux/actions/userProfileAction";
import manageScreenStyles from "../CommonStyles/manageScreenStyles";
import CustomTab from "./CustomTab";
import { alterView } from "../../redux/actions/userInfoActions";
import CustomAccordion from "./CustomAccordion";
const useStyles = makeStyles((theme) => ({
  containerColor: {
    width: "299px",
    height: "612px",
    padding: "33px",
    background: "#F7F7F7 0% 0% no-repeat padding-box",
  },
  headerTxt: {
    ...theme.typography.fontFamily,
    fontSize: "18px",
    fontWeight: 600,
    color: "#161616",
  },
  imgTxtContainer: {
    marginTop: "24px",
    height: "1.3rem",
  },
  customColorTxt: {
    ...theme.typography.fontFamily,
    fontSize: "14px",
    marginTop: "20px",
    color: "#000000",
  },
}));

const Apperance = ({ tab, changeTab }) => {
  const classes = useStyles();
  const styles = manageStyle();

  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    checkedF: false,
  });
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, userInfo } = userDetails;
  const userEdit = useSelector((state) => state.userEdit);
  const [view, setView] = useState(userInfo && userInfo.tabbedView ? false : true);
  const { loading: loadEdit } = userEdit;
  const [colorShow, setColorShow] = useState(userInfo && userInfo.color);
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setColorShow("#002855");
  };

  useEffect(() => {
    setColorShow(colorShow.hex ? colorShow.hex : colorShow);
  }, [colorShow]);
  const userd = {
    color: colorShow,
    tabbedView: view ? false : true
  };

  const handleApplyFilter = () => {
    // dispatch(changeColorByUser(colorShow));
    dispatch(editUserDetails(userd));
    dispatch(changeTabByUser(2));
  };

  const handleChangeView = (e) => {
    const val = e.target.checked
    setView(val)
  };

  return (
    <Grid container>
      <>
        <Grid
          item
          container
          // direction="column"
          className={classes.containerColor}
        >
          {loadEdit && <Loader />}
          {loading && <Loader />}
          <Grid item>
            <Typography className={classes.headerTxt}>
              Set your theme color
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.customColorTxt}>
              Select custom color
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 6 }}>
            <ChromePicker
              color={colorShow}
              onChange={(updatedColor) => {
                setColorShow(updatedColor);
              }}
            />
          </Grid>
          <Grid item style={{ marginTop: "36px" }}>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedF}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onChange={handleChange}
                      name="checkedF"
                      color="primary"
                    />
                  }
                  label="Use default color"
                />
                <br /><br />
                <Typography className={classes.headerTxt}>View Style</Typography>
            <Typography style={{fontSize:'14px'}} variant="caption">{!view ? <span style={{fontWeight:'bold', color:'#e23f27'}}>Tabbed</span> : <>Tabbed</>} </Typography>
            <Switch
              checked={view}
              onChange={handleChangeView}
              color="default"
            />
                <Typography style={{fontSize:'14px'}} variant="caption"> {view ? <span style={{fontWeight:'bold', color:'#e23f27'}}>Accordion</span> : <>Accordion</>}  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "36px" }}>
            <AppButton
              buttonName="Apply"
              variant="contained"
              disabled={false}
              className="btnmedium"
              onClick={() => handleApplyFilter()}
              style={{ width: "243px", height: "40px", marginLeft: 0 }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={7}
          md={7}
          sm={7}
          style={{ paddingLeft: "5rem" }}
        >
          <Grid item lg={12} className={classes.imgTxtContainer}>
            <Typography
              className={classes.headerTxt}
              style={{ marginLeft: "1.2rem" }}
            >
              Preview
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "-37px" }}>
            <CustomTheme color={colorShow} tabbedView={view ? false : true} />
          </Grid>
        </Grid>
      </>
      <Grid item container className={styles.divider} />
      {/* <Grid
        item
        container
        // direction="column"
        className={classes.containerColor}
      >
        <Grid item>
          <Typography className={classes.headerTxt}>
            Set your View Style
          </Typography>

          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              width: "120%",
              alignItems: "center",
            }}
          >
            <Typography>Accordion</Typography>
            <Switch
              checked={view}
              onChange={handleChangeView}
              color="default"
            />
            <Typography>Tabbed</Typography>
          </Grid>
          {tabbedView ?  <CustomTab color={colorShow} /> : <CustomAccordion color={colorShow} /> }
          
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default Apperance;
