import {
  ADD_TEAMS_DATA,
  TEAMS_FILTER,
  TEAMS_SORT,
  LOCK_UNLOCK_TEAM,
  BLOCK_UNBLOCK_TEAM,
  CLEAR_TEAMS_DATA,
  TEAMS_HIDDEN_COULMNS,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  teams_list: {
    data: [],
    error: false,
    loading: false,
  },
  teamFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  teamsSort: [{ columnName: "createdon", direction: "desc" }],
  teamsHiddenCols: [
    "name",
    "teamMember",
    "createdby",
    "createdon",
    "teamActions",
  ],
};

export default function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TEAMS_DATA: {
      return {
        ...state,
        teams_list: {
          ...state.teams_list,
          ...action.payload,
        },
      };
    }

    case TEAMS_FILTER: {
      return {
        ...state,
        teamFilter: [...action.payload],
      };
    }
    case TEAMS_SORT: {
      return {
        ...state,
        teamsSort: [...action.payload],
      };
    }
    case LOCK_UNLOCK_TEAM: {
     
      return {
        ...state,
        teams_list: {
          ...state.teams_list,
          data:
            state.teams_list.data &&
            state.teams_list.data.map((item) => {
              if (item._id === action.payload.teamID) {
                return {
                  ...item,
                  lock_status: action.payload.lock_status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case BLOCK_UNBLOCK_TEAM: {
     
      return {
        ...state,
        teams_list: {
          ...state.teams_list,
          data:
            state.teams_list.data &&
            state.teams_list.data.map((item) => {
              if (item._id === action.payload.teamID) {
                return {
                  ...item,
                  block_status: action.payload.block_status,
                };
              } else {
                return item;
              }
            }),
        },
      };
    }
    case CLEAR_TEAMS_DATA: {
      return {
        ...state,
        teams_list: {},
      };
    }
    case TEAMS_HIDDEN_COULMNS: {
      return {
        ...state,
        teamsHiddenCols: [...action.payload],
      };
    }
    default:
      return state;
  }
}
