import {
  Grid,
  FilledInput,
  InputAdornment,
  IconButton,
  useTheme,
  Avatar,
} from "@material-ui/core";
import { AppButton } from "../components/atoms/button/AppButton";
import BreadCrumbs from "../components/molecules/projectSetup/BreadCrumbs";
import CreateScreenStyle from "../styles/commonStyles/CreateScreen";
import ServiceTable from "../components/organisms/ServiceTable";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleValidation } from "../utils/Validation";
import { useDispatch, useSelector } from "react-redux";
import PagesTable from "../components/organisms/PagesTable";
import { UiApplicationService } from "../services/UiApplicationService";
import { useSnackbar } from "notistack";
import Loader from "../components/atoms/loader/Loader";
import { CommonServices } from "../../services/commonServices";
import { ApplicationServices } from "../../services/applicationServices";
import ApplicationTable from "../components/organisms/ApplicationTable";
import moment from "moment";
import GlobalCssModal from "../components/atoms/customModal/GlobalCssModal";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import { DropzoneArea } from "material-ui-dropzone";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { deletePage } from "../../redux/actions/uiApplicationAction";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Pagination, PaginationItem } from "@material-ui/lab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NotFoundContent from "../../screens/Errors/NotFoundContent";
import ServiceTableStyles from "../../UiApplication/styles/commonStyles/ServiceTable";

const customdropzoneImage = () => {
  return <img src="/images/dropzone.svg" alt="" />;
};

export default function EditScreen(props) {
  const classes = CreateScreenStyle();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const projectId = useState(props.match.params.id);
  const { checkCompPermission } = useCheckPermission();

  const apiService = new UiApplicationService();

  const [currentProj, setcurrentProj] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [icon, setIcon] = useState("");
  const [iconname, setIconName] = useState("");

  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [globalCssModal, setGlobalCssModal] = useState(false);
  const theme = useTheme();
  const { isEditMode } = props;

  const search = useLocation().search;
  const classesforserviceBundle = ServiceTableStyles();

  const [css, setCss] = useState({
    fontFamily: "Nunito",
    fontSize: 10,
    fontStyle: {
      bold: false,
      italic: false,
      underline: false,
    },
    color: theme.palette.primary.main,
  });

  const { checkScreenPermission } = useCheckPermission();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [onDeletePage, setOnDeletePage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditMode) {
      checkScreenPermission("UIApplication", ["Edit"]);
    } else {
      checkScreenPermission("UIApplication", ["Read"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppByID_updatedPage = async () => {
    try {
      setLoading(true);
      const resp = await apiService.getApplicationById(projectId[0]);
      if (resp.status === 200) {
        setcurrentProj(resp?.data?.data);
        setSelectedServices(resp?.data?.data?.services);
        setSelectedApplications(resp?.data?.data?.backendApplication);
        if (resp?.data?.data?.globalCss !== "{}") {
          setCss(JSON.parse(resp?.data?.data?.globalCss));
        }
        setIcon(resp?.data?.data?.icon);
        setIconName(resp?.data?.data?.iconName);
        // enqueueSnackbar("Ui Application fetched successfully", {
        //   variant: "success",
        // });
        setLoading(false);
      } else {
        enqueueSnackbar("Unable to fetch ui application", {
          variant: "error",
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const getAppByID = async () => {
      try {
        setLoading(true);
        const resp = await apiService.getApplicationById(projectId[0]);
        if (resp.status === 200) {
          setcurrentProj(resp?.data?.data);
          setSelectedServices(resp?.data?.data?.services);
          setSelectedApplications(resp?.data?.data?.backendApplication);
          if (resp?.data?.data?.globalCss !== "{}") {
            setCss(JSON.parse(resp?.data?.data?.globalCss));
          }
          setIcon(resp?.data?.data?.icon);
          setIconName(resp?.data?.data?.iconName);
          setLoading(false);
        } else {
          enqueueSnackbar("Unable to fetch ui application", {
            variant: "error",
          });
          setLoading(false);
        }
      } catch (err) {
        // console.log(err.response);
      }
    };
    getAppByID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState({
    name: false,
    imagename: false,
    imagetag: false,
    imageNameFirstLetterLow: false,
  });

  const handleChange = (e) => {
    if (/^[a-z]/.test(e.target.value) && e.target.name === "imagename") {
      setError({
        ...error,
        [e.target.name]: false,
        imageNameFirstLetterLow: false,
      });
      setcurrentProj({
        ...currentProj,
        [e.target.name]: e.target.value,
      });
    } else {
      setError({
        ...error,
        [e.target.name]: false,
      });
      setcurrentProj({
        ...currentProj,
        [e.target.name]: e.target.value,
      });
    }
    // setError({
    // 	...error,
    // 	[e.target.name]: false,
    // });
    // setcurrentProj({
    // 	...currentProj,
    // 	[e.target.name]: e.target.value,
    // });
  };

  // Services
  const commonService = new CommonServices();
  const [services, setServices] = useState();

  const fetchServiceList = async (page, filter) => {
    try {
      setLoading(true);
      var resp = await commonService.getServicesList(page, filter);
      setServices(resp?.data?.data ? resp?.data?.data : []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (checkCompPermission("Microservice", ["List All"])) {
      fetchServiceList(
        undefined,
        JSON.stringify({
          ai_task: { $ne: true },
          service_category: { $ne: "Only GUI" },
        })
      );
    }
    getAppByID_updatedPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // search
  const handleServiceSearch = (e) => {
    if (e.target.value) {
      if (checkCompPermission("Microservice", ["List All"])) {
        fetchServiceList(
          0,
          JSON.stringify({
            name: { $regex: e.target.value, $options: "i" },
          })
        );
      }
    } else {
      if (checkCompPermission("Microservice", ["List All"])) {
        fetchServiceList(0);
      }
    }
  };

  // Application
  const applicationServices = new ApplicationServices();
  const [applications, setApplications] = useState();

  const fetApplicationList = async (page, filter) => {
    try {
      setLoading(true);
      var resp = await applicationServices.getApplicationList(
        page,
        filter,
        JSON.stringify({
          createdon: -1,
        })
      );
      setApplications(resp?.data?.data ? resp?.data?.data : []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (checkCompPermission("ServiceBundle", ["List All"])) {
      fetApplicationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplicationSearch = (e) => {
    if (e.target.value) {
      if (checkCompPermission("ServiceBundle", ["List All"])) {
        fetApplicationList(
          0,
          JSON.stringify({
            name: { $regex: e.target.value, $options: "i" },
          })
        );
      }
    } else {
      if (checkCompPermission("ServiceBundle", ["List All"])) {
        fetApplicationList(0);
      }
    }
  };

  const { userInfo } = useSelector((state) => state.userDetails);

  const handleSubmit = async () => {
    // console.log("currentProj", currentProj);
    const { formIsValid, errors } = handleValidation(currentProj, error);
    // console.log("formIsValid", formIsValid, "errors", errors);
    if (/^[A-Z]/.test(currentProj.imagename)) {
      setError({
        ...error,
        imageNameFirstLetterLow: true,
      });
      return;
    }
    if (formIsValid) {
      const data = {
        ...currentProj,
        name: currentProj.name,
        description: currentProj.description,
        imagename: currentProj?.imagename,
        imagetag: currentProj?.imagetag,
        services: selectedServices,
        backendApplication: selectedApplications,
        globalCss: JSON.stringify(css),
        icon: icon,
        iconName: iconname,
      };
      try {
        setLoading(true);
        const resp = await apiService.editApplication(currentProj?._id, data);
        if (resp?.status === 200) {
          enqueueSnackbar("Ui Application Updated Succesfully", {
            variant: "success",
          });
          setLoading(false);
          history.push(`/ui-applications/details/${currentProj?._id}`);
        } else {
          setLoading(false);
          enqueueSnackbar(
            "Unable to process your request, please try after sometime",
            {
              variant: "error",
            }
          );
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(
          err?.response?.data?._msg
            ? err?.response?.data?._msg
            : "Unable to process your request, please try after sometime",
          {
            variant: "error",
          }
        );
      }
    } else {
      // @ts-ignore
      setLoading(false);
      setError(errors);
    }
  };

  var handleUpload = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setIconName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setIcon(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteClick = () => {
    setIcon("");
    setIconName("");
  };

  const rejectedfile = () => {
    enqueueSnackbar("Maximum file size allowed 30kb", {
      variant: "error",
    });
  };

  const handleDeletePage = async () => {
    if (onDeletePage?.islandingpage) {
      enqueueSnackbar("Landing Page can not be deleted", { variant: "error" });
    } else {
      const resp = await apiService?.deletePage(
        currentProj?._id,
        onDeletePage?.name
      );
      if (resp?.status < 210) {
        enqueueSnackbar(resp?.data?._msg, { variant: "success" });
        dispatch(deletePage({ name: onDeletePage?.name }));
        setOnDeletePage(null);
        setDeleteModalOpen(false);
        getAppByID_updatedPage();
      } else {
        enqueueSnackbar(
          resp?.data?._msg ? resp?.data?._msg : "Failed to delete page",
          {
            variant: "error",
          }
        );
      }
    }
  };

  return (
    <main className={classes.main}>
      {loading && <Loader />}
      <div className={classes.topContent}>
        <BreadCrumbs
          items={[
            {
              title: "UI Application",
              path: "/ui-applications",
            },
            {
              title: isEditMode
                ? "Edit UI Application"
                : " UI Application Details",
              path: "",
            },
          ]}
        />
        <Grid container alignItems="center">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <h2 className={classes.title}>
              {isEditMode ? "Edit UI Application" : "UI Application Details"}
            </h2>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {isEditMode ? (
              <div className={classes.buttons}>
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={handleSubmit}
                  disabled={!checkCompPermission("UIApplication", ["Edit"])}
                />
                &nbsp;&nbsp;&nbsp;
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  disabled={false}
                  style={{ margin: "unset", marginLeft: "10px" }}
                  className="outlined"
                  onClick={() => history.push("/ui-applications")}
                />
              </div>
            ) : (
              <div className={classes.buttons}>
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  // startIcon={<img src="/images/edit.svg" alt="card" />}
                  style={{ padding: "6px 15px" }}
                  className="btnsmall"
                  onClick={() => {
                    history.push(`/ui-applications/edit/${currentProj?._id}`);
                  }}
                  disabled={!checkCompPermission("UIApplication", ["Edit"])}
                />
                &nbsp;&nbsp;&nbsp;
                <AppButton
                  color="primary"
                  buttonName="Close"
                  variant="outlined"
                  disabled={false}
                  style={{ margin: "unset", marginLeft: "10px" }}
                  className="outlined"
                  onClick={() => history.push("/ui-applications")}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <div className={classes.generalInfo}>
          <span>General Information</span>
        </div>
        <div className={classes.border_line} />
      </div>
      <div className={classes.wrapper} style={{ marginTop: "8rem" }}>
        <Grid container spacing={3} style={{ marginTop: "0rem" }}>
          <Grid item xs={9} sm={9} style={{ padding: "12px 12px 0px" }}>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Application Name <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="name"
                  type="text"
                  placeholder="Enter Application Name"
                  value={currentProj?.name}
                  className={`${classes.input} ${error.name && classes.error}`}
                  onChange={handleChange}
                  disabled={!isEditMode}
                />
                {error.name && (
                  <p className={classes.errorMsg}>
                    {"Application Name is required"}
                  </p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Image Name <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="imagename"
                  type="text"
                  placeholder="Enter Image Name"
                  value={currentProj?.imagename}
                  className={`${classes.input} ${error.name && classes.error}`}
                  onChange={handleChange}
                  disabled={!isEditMode}
                />
                {error.imagename && (
                  <p className={classes.errorMsg}>{"Image Name is required"}</p>
                )}
                {error.imageNameFirstLetterLow && (
                  <p className={classes.errorMsg}>
                    {"Image Name should start with lowercase letter"}
                  </p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <label className={classes.fieldHeading}>
                  Image Tag <span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="imagetag"
                  type="text"
                  placeholder="Enter Image Tag"
                  value={currentProj?.imagetag}
                  className={`${classes.input} ${error.name && classes.error}`}
                  onChange={handleChange}
                  disabled={!isEditMode}
                />
                {error.imagetag && (
                  <p className={classes.errorMsg}>{"Image Tag is required"}</p>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <label className={classes.fieldHeading}>
                      Application Icon
                    </label>
                    {!icon ? (
                      <div className={classes.documentDropperWrapper}>
                        <DropzoneArea
                          Icon={customdropzoneImage}
                          acceptedFiles={["image/*"]}
                          className={classes.documentDropperClass}
                          showPreviews={false}
                          filesLimit={1}
                          dropzoneText="Drag and drop or click to browse here"
                          dropzoneClass="documentDropperClass"
                          dropzoneParagraphClass="documentDropperTextClass"
                          get
                          maxFileSize={30000}
                          showAlerts={false}
                          onDropRejected={rejectedfile}
                          showPreviewsInDropzone={false}
                          onChange={handleUpload}
                          dropzoneProps={{
                            disabled: isEditMode ? false : true,
                          }}
                        />
                        <p
                          className={classes.fieldHeading}
                          style={{ whiteSpace: "nowrap" , fontWeight: 400}}
                        >{`Allowed jpg, jpeg, png or svg of size max 30 KB`}</p>
                      </div>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Avatar src={icon} alt=" " />
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={classes.value}
                            value={iconname}
                            placeholder="Enter Image Name"
                          ></input>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="inherit"
                            aria-label="edit"
                            edge="start"
                            className={classes.actionIcon}
                            onClick={handleDeleteClick}
                            disabled={!isEditMode}
                          >
                            <img
                              src="/images/deleteLine.svg"
                              color="#000000"
                              alt="icon"
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Grid container spacing={3} style={{ marginTop: "0rem" }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label className={classes.fieldHeading}>Description</label>
                <textarea
                  name="description"
                  rows={6}
                  placeholder="Enter Application description"
                  value={currentProj?.description}
                  className={`${classes.textarea} ${
                    !isEditMode && classes?.textAreaDisableMode
                  } ${error.description && classes.error}`}
                  onChange={handleChange}
                  disabled={!isEditMode}
                ></textarea>
                {error.description && (
                  <p className={classes.errorMsg}>
                    {"description is required"}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 16 }}>
        <div>
          <div>
            <h2 className={classes.sectionHeading}>Services - Version</h2>
            <div className={classes.border_line} />
          </div>
          {isEditMode && (
            <div className={classes.searchwrapper}>
              <FilledInput
                name="searchInput"
                onChange={handleServiceSearch}
                className={classes.input}
                style={{ padding: 0, paddingBottom: "1rem", width: "100%" }}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleServiceSearch}
                      onMouseDown={handleServiceSearch}
                    >
                      <img src="/img/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          )}
          <div className="table">
            <ServiceTable
              services={services}
              setServices={setServices}
              setLoading={setLoading}
              selectedServices={selectedServices}
              isEditMode={isEditMode}
              setSelectedServices={setSelectedServices}
            />
          </div>
        </div>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 40 }}>
        <div>
          <div>
            <h2 className={classes.sectionHeading}>Service Bundles</h2>
            <div className={classes.border_line} />
          </div>
          {isEditMode && (
            <div className={classes.searchwrapper}>
              <FilledInput
                name="searchInput"
                onChange={handleApplicationSearch}
                className={classes.input}
                style={{
                  backgroundColor: "#fff !important",
                  padding: 0,
                  paddingBottom: "1rem",
                  width: "100%",
                  alignItems: "center",
                }}
                placeholder="Search"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleApplicationSearch}
                      onMouseDown={handleApplicationSearch}
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          )}
          <div>
            {isEditMode ? (
              <ApplicationTable
                isEditMode={isEditMode}
                applications={applications}
                setApplications={setApplications}
                selectedApplications={selectedApplications}
                setSelectedApplications={setSelectedApplications}
                setLoading={setLoading}
              />
            ) : (
              <>
                <TableContainer className={classesforserviceBundle.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ paddingLeft: "2rem", width: "50%" }}
                        >
                          Application Name
                        </TableCell>
                        <TableCell>Deployment Status</TableCell>
                        {/* <TableCell>Created On</TableCell>
                        <TableCell>Description</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedApplications &&
                      selectedApplications?.length !== 0 ? (
                        selectedApplications
                          ?.slice((page - 1) * 5, page * 5)
                          .map((item, index) => (
                            <TableRow
                              sx={{ "& > *": { borderBottom: "unset" } }}
                              key={index}
                            >
                              <TableCell style={{ paddingLeft: "2rem" }}>
                                {item?.name}
                              </TableCell>
                              <TableCell>
                                {item?.deployment ? "Added" : "Not Added"}
                              </TableCell>
                              {/* <TableCell>
                              {moment.unix(item?.createdon).format("DD/MM/YYYY, hh:mm A")}
                            </TableCell> */}
                              {/* <TableCell>{item?.description}</TableCell> */}
                            </TableRow>
                          ))
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            textAlign: "center",
                            marginTop: "2rem",
                            left: "calc(50% - 4rem)",
                            maxWidth: "11rem",
                          }}
                        >
                          <img src="/images/notFound.svg" alt="notfound" />
                          <h3 style={{ textAlign: "center" }}>
                            No service bundle found
                          </h3>
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classesforserviceBundle.footer}>
                  <h3 className={"itemsCount"}>
                    {selectedApplications?.length} items
                  </h3>
                  <Pagination
                    count={Math.ceil(selectedApplications?.length / 5)}
                    shape="rounded"
                    className={"pagination"}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: NavigateBeforeIcon,
                          next: NavigateNextIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: 35 }}>
          <div>
            <h2 style={{ fontSize: 16 }}>Pages</h2>
            <div className={classes.border_line} />
            <PagesTable
              editMode={!isEditMode}
              setDeleteModalOpen={setDeleteModalOpen}
              setOnDeletePage={setOnDeletePage}
              currentProj={currentProj}
              projectId={projectId}
            />
          </div>
        </div>
      </div>
      <div className={classes.wrapper} style={{ marginTop: 0, top: "-15px" }}>
        <div>
          <div style={{ paddingBottom: 100 }}>
            <h2 className={classes.sectionHeading} style={{ marginTop: 0 }}>
              Global CSS
            </h2>
            <div className={classes.border_line} style={{ marginBottom: 20 }} />
            <AppButton
              color="primary"
              buttonName="Set Global CSS"
              variant="contained"
              style={{ margin: "unset" }}
              className="btnsmall"
              onClick={() => setGlobalCssModal(true)}
              disabled={
                checkCompPermission("UIApplication", ["Global CSS"])
                  ? !isEditMode
                  : true
              }
            />
          </div>
        </div>
      </div>
      {globalCssModal && (
        <GlobalCssModal
          open={globalCssModal}
          css={css}
          setCss={setCss}
          handleClose={() => setGlobalCssModal(false)}
          handleSave={() => setGlobalCssModal(false)}
        />
      )}
      {deleteModalOpen && (
        <div>
          <Dialog
            onClose={() => setDeleteModalOpen(false)}
            open={deleteModalOpen}
            maxWidth="lg"
          >
            <DialogContent>
              <div
                style={{
                  height: 200,
                  width: 450,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/images/governance/delete.svg"
                    alt="lock"
                    style={{ height: 75, width: 75 }}
                  />
                </div>
                <div style={{ marginTop: 30 }}>
                  <p style={{ textAlign: "center" }}>
                    All changes made so far to <b>{onDeletePage?.name}</b> will
                    be lost, <br />
                    This process cannot be undone.
                  </p>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
              <AppButton
                onClick={() => setDeleteModalOpen(false)}
                className={`btnsmall`}
                buttonName="Cancel"
                variant="outlined"
              />
              <AppButton
                onClick={handleDeletePage}
                className={`btnsmall`}
                buttonName={"Yes, Delete"}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              />
            </DialogActions>
          </Dialog>
        </div>
      )}
    </main>
  );
}
