import {
  //
  ADD_PROJECT_DOCUMENTS_DATA,
  PROJECT_DOCUMENTS_FILTERS,
  DELETE_PROJECT_DOCUMENTS_BY_DOCUMENTS_ID,
  PROJECT_DOCUMENTS_SORT,

  //
  ADD_SERVICE_GROUPS_DOCUMENTS_DATA,
  SERVICE_GROUPS_DOCUMENTS_FILTERS,
  SERVICE_GROUP_DOCUMENTS_SORT,
  //
  ADD_SERVICE_DOCUMENTS_DATA,
  SERVICE_DOCUMENTS_FILTERS,
  SERVICE_DOCUMENTS_SORT,

  //
  ADD_DOCUMENT_AFTER_UPLOAD,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  project_document_list: {
    data: [],
    error: false,
    loading: false,
  },
  service_groups_documents_list: {
    data: [],
    error: false,
    loading: false,
  },
  service_documents_list: {
    data: [],
    error: false,
    loading: false,
  },
  projectDocumentFilter: [
    { id: uuidv4(), column: "", operator: "", value: "" },
  ],
  serviceGroupsDocumentsFilters: [
    { id: uuidv4(), column: "", operator: "", value: "" },
  ],
  serviceDocumentsFilters: [
    { id: uuidv4(), column: "", operator: "", value: "" },
  ],
  projectDocumentSort: [{ columnName: "createdon", direction: "desc" }],
  serviceGroupDocumentSort: [{ columnName: "createdon", direction: "desc" }],
  serviceDocumentSort: [{ columnName: "createdon", direction: "desc" }],
  projectDocumentListAfterUpload: {
    data: [],
    error: false,
    loading: false,
  },
};

export default function allDocumentsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PROJECT_DOCUMENTS_DATA: {
      return {
        ...state,
        project_document_list: {
          ...state.project_document_list,
          ...action.payload,
        },
      };
    }
    case PROJECT_DOCUMENTS_FILTERS: {
      return {
        ...state,
        projectDocumentFilter: [...action.payload],
      };
    }
    case PROJECT_DOCUMENTS_SORT: {
      return {
        ...state,
        projectDocumentSort: [...action.payload],
      };
    }
    case DELETE_PROJECT_DOCUMENTS_BY_DOCUMENTS_ID: {
      return {
        ...state,
        project_document_list: {
          ...state.project_document_list,
          data: {
            ...state.project_document_list.data,
            data: state.project_document_list?.data?.data?.filter(
              (proj) => proj._id !== action.payload.projectId
            ),
          },
          _totalcount: state.project_document_list._totalcount - 1,
          
        },
      };
    }

    // Service groups

    case ADD_SERVICE_GROUPS_DOCUMENTS_DATA: {
      return {
        ...state,
        service_groups_documents_list: {
          ...state.service_groups_documents_list,
          ...action.payload,
        },
      };
    }
    case SERVICE_GROUPS_DOCUMENTS_FILTERS: {
      return {
        ...state,
        serviceGroupsDocumentsFilters: [...action.payload],
      };
    }
    case SERVICE_GROUP_DOCUMENTS_SORT: {
      return {
        ...state,
        serviceGroupDocumentSort: [...action.payload],
      };
    }
    //service
    case ADD_SERVICE_DOCUMENTS_DATA: {
      return {
        ...state,
        service_documents_list: {
          ...state.service_documents_list,
          ...action.payload,
        },
      };
    }

    case SERVICE_DOCUMENTS_FILTERS: {
      return {
        ...state,
        serviceDocumentsFilters: [...action.payload],
      };
    }
    case SERVICE_DOCUMENTS_SORT: {
      return {
        ...state,
        serviceDocumentSort: [...action.payload],
      };
    }

    case ADD_DOCUMENT_AFTER_UPLOAD: {
      return {
        ...state,
        projectDocumentListAfterUpload: [
          ...state.projectDocumentListAfterUpload,
          action.payload,
        ],
      };
    }
    default:
      return state;
  }
}
