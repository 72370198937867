import React, { useEffect, useState } from "react";
import AddOutlined from "@material-ui/icons/AddOutlined";
import styles from "../../../../atoms/rightSide/settings/popups/helper/styles";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { v4 as uuid } from "uuid";
import Input from "../../../../atoms/input/Input";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";

export const SelectHelper = ({
  currentPage,
  project,
  selectedComponent,
  customArr,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [optionsArray, setOptionsArray] = useState([]);

  const handleAddColumn = () => {
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          options: [
            ...component.options,
            {
              id: uuid(),
              title: `Option ${component.options.length + 1}`,
              value: `value ${component.options.length + 1}`,
            },
          ],
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: project.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          options: component.options.map((v) => {
            if (v?.id === value?.id) {
              return {
                ...v,
                [event.target.name]: event.target.value,
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: project.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handeDelete = (e, collProp) => {
    e.stopPropagation();
    const components = currentPage.components.map((component) => {
      if (component.id === selectedComponent.id) {
        return {
          ...component,
          options: component.options.filter((c) => c.id !== collProp.id),
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: project.id,
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  useEffect(() => {
    setOptionsArray(selectedComponent?.options);
  }, [selectedComponent?.options]);

  return (
    <div>
      <div>
        {optionsArray.map((col) => (
          <Accordion
            expanded={expanded === col?.id}
            onChange={handleChangePanel(col?.id)}
            elevation={1}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{ root: classes.summaryRoot }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography>{col?.title}</Typography>
                </Grid>
                <Grid item>
                  <img
                    src="/img/delete-bin-7-line.svg"
                    alt="Delete"
                    style={{ height: 18 }}
                    onClick={(e) => handeDelete(e, col)}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item>
                  <Input
                    name={"title"}
                    label={"Title"}
                    placeholder=""
                    value={col?.title}
                    onChange={(e) => handleChange(e, col)}
                  />
                </Grid>
                <Grid item>
                  <Input
                    name={"value"}
                    label={"Value"}
                    placeholder=""
                    value={col?.value}
                    onChange={(e) => handleChange(e, col)}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div
        className={classes.tableColumn}
        style={{
          justifyContent: "center",
          padding: 5,
          cursor: "pointer",
        }}
        onClick={handleAddColumn}
      >
        <AddOutlined />
      </div>
    </div>
  );
};
