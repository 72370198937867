import { ApiHelper } from "./helper/ApiHelper";
import { CONTAINER_MNGT } from "./helper/config";

const apiHelper = new ApiHelper();

export class RuntimeEnvServices {
  getRuntimeEnvList(currentPage, filter, sort) {
    const queryParam =
      `page=${currentPage}` +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : "") +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : "");
    const uri = `${CONTAINER_MNGT}/api/container/environments?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getRuntimeEnvDetailsByID(envID) {
    const uri = `${CONTAINER_MNGT}/api/container/environments/${envID}`;
    return apiHelper.get(uri);
  }
  createRuntimeEnv(createEnvData) {
    const uri = `${CONTAINER_MNGT}/api/container/environments`;
    return apiHelper.post(uri, createEnvData);
  }
  editRuntimeEnvByID(envID, envData) {
    const uri = `${CONTAINER_MNGT}/api/container/environments/${envID}`;
    return apiHelper.put(uri, envData);
  }
  DeleteRuntimeEnv(envID) {
    const uri = `${CONTAINER_MNGT}/api/container/environments/${envID}`;
    return apiHelper.delete(uri);
  }
  StartRuntimeEnv(envID) {
    const uri = `${CONTAINER_MNGT}/api/container/environments/${envID}/_upsync`;
    return apiHelper.get(uri);
  }
  StopRuntimeEnv(envID) {
    const uri = `${CONTAINER_MNGT}/api/container/environments/${envID}/_down`;
    return apiHelper.get(uri);
  }
}
