import { Grid, TextField, Backdrop, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { AppButton } from '../../../components/common/Button'
import { useSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomChips from '../../../components/common/Chips'
import { getOntologyFieldGroups } from '../../../redux/actions/ontologyAction'
import { fetchSectionByID_, editSectionByID_ } from '../../../utils/ontology/ontologyUtils'
import SectionEditTree from '../../../components/Ontology/SectionEditTree'
import useCheckPermission from '../../../components/common/hooks/useCheckPermission'

function SectionDetailsScreen(props) {
  const [mode, setMode] = useState('details')
  const [sectionData, setSectionData] = useState({
    name: '',
    label: '',
    description: '',
    updatedby: '',
    updatedon: '',
    predefined: false,
  })
  const [error, setError] = useState({
    name: false,
    label: false,
    fieldgroup_arr: false,
  })
  const [loading, setLoading] = useState(false)
  const [selectedFieldGroups, setSelectedFieldGroups] = useState({
    _id: '',
    title: '',
    name: '',
    expanded: true,
    children: [],
  })
  const [predefinedFieldGroups, setPredefinedFieldgroups] = useState([])
  const [allFieldGroups, setAllFieldGroups] = useState([])
  // const [expanded, setExpanded] = useState(true)
  const { checkCompPermission } = useCheckPermission()

  const styles = manageStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const location = useLocation()
  const sectionID = props.match.params.sectionID

  useEffect(() => {
    // const { source } = location.state;
    if (location?.state?.source === 'edit_icon') {
      setMode('edit')
    } else {
      setMode('details')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionID])

  useEffect(() => {
    const fetchSectionDetails = async () => {
      setLoading(true)
      const { _msg, _status, data } = await fetchSectionByID_(sectionID)
      if (_status === 200) {
        setLoading(false)
        const { name, predefined, fieldgroups, _id, description, updatedon, updatedby } = data
        const sectionLabel = data['section Label']
        setSectionData((prevState) => ({
          ...prevState,
          name: name,
          predefined: predefined && predefined === 'TRUE' ? true : false,
          label: sectionLabel ? sectionLabel : '',
          description: description,
          updatedby: updatedby ? updatedby : '',
          updatedon: updatedon ? moment.unix(updatedon).format('DD/MM/YYYY, hh:mm:ss A') : '',
        }))
        setPredefinedFieldgroups(data?.predefined_fieldgroups)
        setSelectedFieldGroups(() => {
          const fieldgrouplist =
            fieldgroups && fieldgroups.length > 0
              ? fieldgroups.map((item) => {
                  const fieldList =
                    item.fields && item.fields.length > 0
                      ? item.fields.map((field_) => {
                          return {
                            _id: field_._id,
                            name: field_.name,
                            title:
                              field_.name && field_.name.length > 28
                                ? field_.name.slice(0, 28)
                                : field_.name,
                          }
                        })
                      : []
                  return {
                    _id: item._id,
                    title: item.name && item.name.length > 28 ? item.name.slice(0, 29) : item.name,
                    name: item.name,
                    children: fieldList,
                  }
                })
              : []
          return {
            _id: sectionID,
            title: sectionLabel,
            children: fieldgrouplist,
            expanded: true,
          }
        })
      } else {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'error' })
      }
    }
    fetchSectionDetails()
    props.getOntologyFieldGroups()
    setAllFieldGroups(props.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = (key, value) => {
    setSectionData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (Object.keys(error).includes(key)) {
      setError((prevState) => ({
        ...prevState,
        [key]: false,
      }))
    }
  }

  const handleFieldSelected = (e, input) => {
    if (input) {
      setError((prevState) => ({
        ...prevState,
        field_arr: false,
      }))
      var tempFields =
        selectedFieldGroups.children.length > 0
          ? selectedFieldGroups.children.filter((item) => item._id === input?._id)
          : []
      if (tempFields.length === 0) {
        setSelectedFieldGroups((prevState) => ({
          ...prevState,
          children: [
            ...prevState.children,
            {
              _id: input._id,
              name: input.name,
              title: input.name && input.name.length > 28 ? input.name.slice(0, 29) : input.name,
            },
          ],
        }))
      }
      // console.log(input, selectedFields)
    }
  }

  const handleChipDelete = (data) => {
    if (mode === 'edit') {
      if (predefinedFieldGroups.includes(data._id)) {
        enqueueSnackbar('Predefined field group cannot be removed from a section', {
          variant: 'warning',
        })
      } else {
        setSelectedFieldGroups((prevState) => {
          var tempFields =
            prevState.children.length > 0 &&
            prevState.children.filter((item) => item._id !== data._id)
          return {
            ...prevState,
            children: tempFields,
          }
        })
      }
    }
  }

  const handleTreeChange = (treedata) => {
    // console.log("changes", treedata)
    setSelectedFieldGroups(treedata[0])
  }

  const handleSubmit = async () => {
    var errorFlag = false
    var tempError = {}
    for (var obj in sectionData) {
      if (sectionData[obj] === '') {
        if (Object.keys(error).includes(obj)) {
          errorFlag = true
          tempError[obj] = true
        }
      }
    }
    if (selectedFieldGroups.children.length === 0) {
      errorFlag = true
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        field_arr: true,
      }))
    } else {
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        fieldgroup_arr: false,
      }))
    }
    if (!errorFlag) {
      setLoading(true)
      var req_data = {}
      if (sectionData.predefined) {
        req_data = {
          'section Label': sectionData.label,
          fieldgroups:
            selectedFieldGroups.children.length > 0
              ? selectedFieldGroups.children.map((item) => item._id)
              : [],
        }
      } else {
        req_data = {
          name: sectionData.name,
          'section Label': sectionData.label,
          description: sectionData.description,
          fieldgroups:
            selectedFieldGroups.children.length > 0
              ? selectedFieldGroups.children.map((item) => item._id)
              : [],
        }
      }
      // console.log(req_data)
      const { _msg, _status } = await editSectionByID_(sectionID, req_data)
      if (_status === 201) {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'success' })
        setMode('details')
        // history.push("/ontology");
      } else {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'error' })
      }
    }
  }

  return (
    <main className={styles.main}>
      <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: 'Ontology', path: '/ontology' },
              { title: 'Sections', path: '/ontology' },
              { title: `${sectionData.name}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>{sectionData.name}</h2>
            {mode === 'details' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  // startIcon={<img src="/images/edit.svg" alt="card" />}
                  disabled={!checkCompPermission('OntologySection', ['Edit'])}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={() => setMode('edit')}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
            {mode === 'edit' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  disabled={false}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={handleSubmit}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid container spacing={3} style={{ marginTop: '2rem' }}>
            <Grid item xs={9} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Section Name<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`${styles.input} ${
                      (mode === 'details' || sectionData.predefined) && styles.editMode
                    }`}
                    style={error.name ? { border: '1px solid red' } : {}}
                    value={sectionData.name}
                    onChange={(e) => handleTextChange('name', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details' || sectionData.predefined}
                  />
                  {error.name && <text className={styles.errorMsg}>Invalid Section Name</text>}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Section Label<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${mode === 'details' && styles.editMode}`}
                    value={sectionData.label}
                    onChange={(e) => handleTextChange('label', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details'}
                  />
                  {error.label && <text className={styles.errorMsg}>Invalid Section Label </text>}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Updated By
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${styles.editMode}`}
                    value={sectionData.updatedby}
                    onChange={(e) => handleTextChange('updatedby', e.target.value)}
                    autoComplete="off"
                    disabled={true}
                  />
                  {/* {error.label && <text className={styles.errorMsg}>Invalid Updated</text>} */}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Updated On
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${styles.editMode}`}
                    value={sectionData.updatedon}
                    onChange={(e) => handleTextChange('updatedon', e.target.value)}
                    autoComplete="off"
                    disabled={true}
                  />
                  {/* {error.label && <text className={styles.errorMsg}>Invalid Updated</text>} */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="projectDescription" className={styles.fieldHeading}>
                Section Description
              </label>
              <textarea
                name="description"
                value={sectionData.description}
                style={
                  error.description
                    ? { border: '1px solid red', height: '7.8rem' }
                    : { height: '7.8rem' }
                }
                onChange={(e) => handleTextChange('description', e.target.value)}
                className={`${styles.textarea} ${
                  (mode === 'details' || sectionData.predefined) && styles.editMode
                }`}
                rows="8"
                disabled={mode === 'details' || sectionData.predefined}
              ></textarea>
              {error.field_description && (
                <text className={styles.errorMsg}>Invalid Section Description</text>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ marginTop: '1rem', borderTop: '2px solid rgb(175,175,175)' }}
          >
            <Grid item xs={8} sm={8}>
              <Grid spacing={5} container>
                <Grid item xs={5} sm={5}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Groups<span className={styles.red}>*</span>
                  </label>
                  <Autocomplete
                    style={
                      error.fieldgroup_arr
                        ? { border: '1px solid red', width: '22rem' }
                        : { maxWidth: '22rem' }
                    }
                    options={props.data}
                    getOptionLabel={(option) => option.name}
                    className={styles.searchInput3}
                    onChange={handleFieldSelected}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" placeholder="Select Field Groups" />
                    )}
                    disabled={mode === 'details'}
                  />
                  {error.field_arr && <text className={styles.errorMsg}>No Fields Selected</text>}
                  {selectedFieldGroups.children.length > 0 && (
                    <CustomChips
                      chipsData={selectedFieldGroups.children}
                      handleDelete={handleChipDelete}
                      isEditMode={mode === 'details'}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Hierarchy
              </label>
              {!loading && (
                <SectionEditTree
                  treeData={[selectedFieldGroups]}
                  onTreeChange={(treedata) => handleTreeChange(treedata)}
                  disabled={mode === 'details' ? true : false}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </form>
    </main>
  )
}

const mapDispatchToProps = {
  getOntologyFieldGroups,
}

const mapStateToProps = (state) => {
  const { fieldgroups } = state.ontologyReducer
  const { data, error, loading } = fieldgroups
  return {
    data: data ? data : [],
    error,
    loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailsScreen)
