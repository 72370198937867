import TriggerAction from "../../../atoms/rightSide/trigger/TriggerAction";
import ShowCondition from "../../../atoms/rightSide/trigger/ShowCondition";
import { useDispatch } from "react-redux";
import { editComponent } from "../../../../../redux/actions/uiApplicationAction";
import useGetData from "../../../../hooks/useGetData";

function TreeTriggers() {
  const dispatch = useDispatch();
  const { currentProject, currentPage, currentComponent } = useGetData();

  //  Action
  const onChange = (name, action) => {
    let components = [];
    components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [name]: action ? action : "",
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <TriggerAction
          label="On Expand Row"
          project={currentProject}
          value={currentProject?.actions.find(
            (action) => action?._id === currentComponent?.onExpandRow
          )}
          onChange={(e, action) => onChange("onExpandRow", action?._id)}
        />
      </div>
      <div className="field">
        <TriggerAction
          label="On Row Select"
          project={currentProject}
          value={currentProject?.actions.find(
            (action) => action?._id === currentComponent?.onRowSelect
          )}
          onChange={(e, action) => onChange("onRowSelect", action?._id)}
        />
      </div>
      <div style={{ borderTop: "1px solid #F0F0F0", padding: 0 }}>
        <ShowCondition />
      </div>
    </div>
  );
}

export default TreeTriggers;
