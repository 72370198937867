import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppButton } from "../common/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
  FilledInput,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Tabs,
  Tab,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@material-ui/core";
import {
  service,
  Api,
  fieldGroup,
  sampleContent,
} from "../../constants/DataStandard/ModalConstants";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import listStyles from "../../screens/CommonStyles/listScreenStyles";
import { getFieldGroupById } from "../../utils/dataStandards/fieldsUtils";
import { useSnackbar } from "notistack";

export const useStyles = makeStyles((theme) => ({
  // customModal: {
  //   "& div::-webkit-scrollbar": {
  //     display: "none",
  //   },
  // },

  modalTop: {
    marginTop: "20px",

    "& .MuiGrid-item label": {
      textAlign: "left",
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item p": {
      textAlign: "left",
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
    },
  },

  modelDescription: {
    "& .MuiGrid-item label": {
      textAlign: "left",
      font: "normal normal 600 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "0.5",

      "& .MuiGrid-item label": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.5",
      },
      "& .MuiGrid-item p": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "1",
      },
    },
    "& .MuiGrid-item p": {
      textAlign: "left",
      font: "normal normal normal 14px/19px Nunito",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
    },
  },
  modalHeading: {
    textAlign: "left",
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  },

  modalSelectedlist: {
    textAlign: "left",
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "0.7",
  },

  modaltab: {
    width: "35rem",
    textalign: "center",
    font: "normal normal bold 14px/19px Nunito",
    letterspacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
  },

  modalTableContainer: {
    height: "300px",
    marginLeft: "12px",
    border: "1px solid #DEDFDF",
  },

  modalTable: {
    "& thead": {
      background: "#002855 0% 0% no-repeat padding-box",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",

      "& th": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        textTransform: "capitalize",
        color: "#000000",
        opacity: "1",
      },

      "& tr": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        color: "#000000",
      },
    },
    "& tbody": {
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito Sans;",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.8",
      },
    },
  },
  modalTitle: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: "0px",
    color: "#222725",
    opacity: "1",

    "& .MuiButton-root": {
      textTransform: "capitalize",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000014",
      border: "1px solid #002855",
      borderRadius: "5px",
      opacity: "1",
    },
  },
  modalFooter: {
    height: "64px",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",

    "& .MuiButton-root": {
      width: "110px",
      font: "normal normal 600 14px/19px Nunito",
      height: "40px",
      textTransform: "capitalize",
      background: "#002855 0% 0% no-repeat padding-box",
      opacity: "1",
    },
  },
}));

const FieldGroupDetailModal = (props) => {
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const styles = useStyles();
  const listStyle = listStyles();
  const handleChangeTab = (event, newValue) => setTab(newValue);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const _id = props.item?._id ? props.item._id : "";
  // const name = props.item?.name ? props.item.name : "";
  // const type = props.item?.type ? props.item.type : "";
  // const rules = props.item?.rules ? props.item.rules : {};
  // const fields = props.item?.fields ? props.item.fields : [];
  // const status = props.item?.status ? props.item.status : "";
  // const createdby = props.item?.createdby ? props.item.createdby : "";
  // const createdon = props.item?.createdon ? props.item.createdon : "";
  // const description = props.item?.description ? props.item.description : "";

  const handleEditClick = () => history.push(`/edit-field-group/${_id}`);

  const [fieldsData, setFieldsData] = useState({ name: "" });
  const { createdby, createdon, status, type, name, description, fields } =
    fieldsData;

  useEffect(() => {
    if (props.openFieldGroupModal) getFieldGroupDetails();
  }, [props.openFieldGroupModal]);

  const getFieldGroupDetails = async () => {
    const { _msg, _status, data } = await getFieldGroupById(_id);
    if (_status === 200) {
      setFieldsData(data);
      setLoading(false);
    } else {
     
      setLoading(false);
      if (_msg === "Invalid Json") {
        data.map((item) =>
          enqueueSnackbar(item ? item : "Something went wrong", {
            variant: "error",
          })
        );
      } else {
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
      }
    }
  };

  const noOfFields = fields?.filter(
    (res) =>
      res.type === "integer" ||
      res.type === "number" ||
      res.type === "string" ||
      res.type === "boolean" ||
      res.type === "null"
  ).length;
  const noOfFieldGroups = fields?.length - noOfFields;

  return (
    <Dialog
      scroll="paper"
      maxWidth="md"
      className={styles.customModal}
      open={props.openFieldGroupModal}
      onClose={() => props.setOpenFieldGroupModal(false)}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={styles.modalTitle}
        >
          Payload Details
          {/* <AppButton
            buttonName="Edit"
            variant="outlined"
            startIcon={
              <img src="/images/editmodal.svg" alt={<EditOutlinedIcon />} />
            }
            disabled={false}
            className="btnsmall"
            onClick={handleEditClick}
          /> */}
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3} className={styles.modalTop}>
          <Grid item xs={4}>
            <label htmlFor="">Name</label>
            <p>{name}</p>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={
                    status === "active" ? (
                      <Switch color="primary" checked={true} />
                    ) : (
                      <Switch checked={false} />
                    )
                  }
                  label="In Use"
                  labelPlacement="top"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label htmlFor="">Type</label>
            <p>{type}</p>
          </Grid>
        </Grid>
        <Grid container className={styles.modelDescription}>
          <Grid item xs={12}>
            <label>Description</label>
          </Grid>
          <Grid item xs={12}>
            <p style={{ font: "normal normal 600 14px/19px Nunito" }}>
              {description}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} style={{ marginTop: "30px" }}>
            <label className={styles.modalHeading}>
              Included Fields and Payloads
            </label>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.selectedlist}>
              <h4>
                {noOfFields} Fields and {noOfFieldGroups} Payloads
              </h4>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* <FilledInput
              id="filled-adornment-password"
              name="filterfn"
              style={{ marginRight: "-12px" }}
              // onChange={handleSearch}
              className={listStyle.input}
              placeholder={"Search Field, Field group"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => console.log("on click search")}
                    onMouseDown={() => console.log("on click search")}
                    edge="end"
                  >
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            /> */}
          </Grid>
          <TableContainer className={styles.modalTableContainer}>
            <Table stickyHeader className={styles.modalTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((item) => (
                  <TableRow>
                    <TableCell>{item?.name || " "}</TableCell>
                    <TableCell>{item?.type || " "}</TableCell>
                    <TableCell>{item?.description || " "}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.modalFooter}>
        <AppButton
          style={{ marginRight: "16px" }}
          className={`btnmedium`}
          onClick={() => props.setOpenFieldGroupModal(false)}
          buttonName="close"
        />
      </DialogActions>
    </Dialog>
  );
};

export default FieldGroupDetailModal;
