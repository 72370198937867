import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    modalTitle: {
        boxShadow: '0px 1px 2px #00000029',
        marginBottom: 10,

        "& h2": {
            font: 'normal normal bold 18px/20px Nunito'
        }
    },
    modalContent: {
        "& .field": {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginBottom: 5,
            padding: 5,
            borderRadius: 5,

            "& label": {
                font: "normal normal 600 14px/18px Nunito",
                color: "#333333DE",
                textTransform: "capitalize",
            },

            "& #font-picker": {
                width: 225
            },

            "& input": {
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #D8D8D8",
                borderRadius: 5,
                padding: 10,
                marginTop: 3,
                outline: "none",
                width: 225,
            },
        },

        "& .note": {
            font: 'normal normal 600 12px/16px Nunito',
            color: '#DF0E0E',
            margin: 0,
            marginTop: 10,
        }
    },
    modalFooter: {
        background: '#0000000B 0% 0% no-repeat padding-box',
        padding: 10
    }
}))