import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();

export class AuthenticationServices {
  loginUser(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/login`;
    return apiHelper.post(uri, req_data);
  }
  forgetPassword(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/forgetpassword`;
    return apiHelper.post(uri, req_data);
  }
  verifyCodeForForgetPassword(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/verifycodeforforgetpassword`;
    return apiHelper.post(uri, req_data);
  }
  resendForgetPasswordCode(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/resendforgetpasswordcode`;
    return apiHelper.put(uri, req_data);
  }
  verifyEmailForLogin(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/verifyemail`;
    return apiHelper.put(uri, req_data);
  }
  createTokens(userID, req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/createtokens`;
    return apiHelper.put(uri, req_data);
  }
  logout(userID, req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/logout`;
    return apiHelper.delete(uri, req_data);
  }
  getAccessToken(userID, req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/createtokens`;
    return apiHelper.put(uri, req_data);
  }
  resendVerificationCode(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/users/resendverificationcode`;
    return apiHelper.put(uri, req_data, {
      userdetails: JSON.stringify({ name: req_data }),
    });
  }
  insertPassword(req_data, userDetails) {
    console.log({req_data})
    const uri = `${BASE_URL}/api/v1.0.0/users/insertpassword/`;
    return apiHelper.put(uri, req_data,{
      userdetails: JSON.stringify(userDetails)
    });
  }
  loginFromToken(req_data) {
    const uri = `${BASE_URL}/api/v1.0.0/sos/token`;
    return apiHelper.post(uri, req_data);
  }

  logOutUser(userID) {
    const uri = `${BASE_URL}/api/v1.0.0/users/${userID}/logout`;
    return apiHelper.delete(uri);
  }

  forceLogin(tempToken) {
    const uri = `${BASE_URL}/api/v1.0.0/users/forcelogin`;
    const header = { token : tempToken}
    return apiHelper.getWithCustomHeader(uri, header);
  }
}
