// Dashboard
import Dashboard from "./screens/Dashboard/Dashboard";

//project screens
import AddProjectScreen from "./screens/Projects/ManageProject/AddProjectScreen";
import EditProjectScreen from "./screens/Projects/ManageProject/EditProjectScreen";
import ProjectListScreen from "./screens/Projects/ProjectListScreen/ProjectListScreen";

//Service Group Screens
import EditServiceGroup from "./screens/ServiceGroup/EditServiceGroup";
import AddServiceGroup from "./screens/ServiceGroup/AddServiceGroup";

//Service Screens
import ServicesList from "./screens/Services/ServicesList";
import AddService from "./screens/Services/ManageService/AddServiceScreen";
import EditService from "./screens/Services/ManageService/EditServiceScreen";

// Organization Screens
import OrganizationDetailsScreen from "./screens/Organization/OrganizationDetails/OrganizationDetails";
import EditOrganizationScreen from "./screens/Organization/editOrganization/EditOrganizationScreen";

// Vendor Screens
import VendorList from "./screens/Vendor/VendorList/VendorListScreen";
import CreateVendor from "./screens/Vendor/CreateVendor/CreateVendorScreen";
import VendorDetails from "./screens/Vendor/VendorDetails/VendorDeatils";
import EditvendorDetailsScreen from "./screens/Vendor/editVendor/EditVendorDetailsScreen";

// Teams Screens
import TeamsList from "./screens/Teams/TeamsList/TeamsList";
import CreateTeam from "./screens/Teams/CreateTeam/CreateTeam";
import TeamDetails from "./screens/Teams/TeamsDetails/TeamDetails";
import EditTeam from "./screens/Teams/editTeam/EditTeam";

//Version Screens
import AddVersion from "./screens/Services/Version/AddVersion";
import EditVersion from "./screens/Services/Version/EditVersion";
import DetailsFieldGroupService from "./screens/Services/ManageFieldGroup/DetailsFieldGroupService";

// Field Group
import CreateFieldGroupsService from "./screens/Services/ManageFieldGroup/CreateFieldGroupsService";
import EditFieldGroupService from "./screens/Services/ManageFieldGroup/EditFieldGroupService";

import ApplicationListScreen from "./screens/Orchestration/Application/ApplicationListScreen/ApplicationListScreen.jsx";
import CreateApplication from "./screens/Orchestration/Application/CreateApplicationScreen/CreateApplication";
import ApplicationDetailsScreen from "./screens/Orchestration/Application/ApplicationDetailScreen/ApplicationDetailsScreen";
import OrchestrationMainScreen from "./screens/Orchestration/OrchestrationMainScreen";
import Development from "./screens/Orchestration/Development";
// import DevelopmentCall from "./components/Orchestration/CallNodeModal/DevelopmentCall";
import AddAPI from "./screens/Services/API/AddAPI";
import EditAPI from "./screens/Services/API/EditAPI";
import StatusProfileListScreen from "./screens/Status/StatusProfile/StatusProfileListScreen";
import CreateProfileStatus from "./screens/Status/StatusProfile/CreateProfileStatus";
import EditStatusProfile from "./screens/Status/StatusProfile/EditStatusProfile";
import DocumentsList from "./screens/Documents/DocumentsList/DocumentsList";

// Container Management
// Docker Image
import ImageListScreen from "./screens/ContainerManagement/DockerImage/ImageListScreen";
import CreateImageScreen from "./screens/ContainerManagement/DockerImage/CreateImageScreen";
import ImageDetailsScreen from "./screens/ContainerManagement/DockerImage/ImageDetailsScreen";
import EditImageScreen from "./screens/ContainerManagement/DockerImage/EditImageScreen";

// Containers
import ContainerListScreen from "./screens/ContainerManagement/Container/ContainerListScreen";
import CreateContainerScreen from "./screens/ContainerManagement/Container/CreateContainerScreen";
import ContainerDetailsScreen from "./screens/ContainerManagement/Container/ContainerDetailsScreen";
import EditContainerScreen from "./screens/ContainerManagement/Container/EditContainerScreen";

// Runtime Environments
import EnvListScreen from "./screens/ContainerManagement/RuntimeENV/EnvListScreen";
import CreateEnvScreen from "./screens/ContainerManagement/RuntimeENV/CreateEnvScreen";
import EnvDetailsScreen from "./screens/ContainerManagement/RuntimeENV/EnvDetailsScreen";
import EditEnvScreen from "./screens/ContainerManagement/RuntimeENV/EditEnvScreen";

//login and 2FA
import SignupScreen from "./screens/Login2FA/SignupScreen";
import LoginScreen from "./screens/Login2FA/LoginScreen";
import ForgetPassword from "./screens/Login2FA/ForgetPassword";
import VerifyOTPScreen from "./screens/Login2FA/VerifyOTPScreen";
import ResetPassword from "./screens/Login2FA/ResetPassword";

// Users
import UserListScreen from "./screens/Users/UserList/UsersList";
import CreateUserScreen from "./screens/Users/CreateUser/CreateUser";
import UserDetailsScreen from "./screens/Users/UserDetails/UserDetails";
import EditUserScreen from "./screens/Users/editUser/EditUser";

// Application Governance
import ApplicationGovernanceListScreen from "./screens/ApplicationGovernance/ListScreen/ApplicationGovernanceListScreen";
// launguage
import CreateLaunguageScreen from "./screens/ApplicationGovernance/launguage/CreateLaunguage";
import LaunguageDetailsScreen from "./screens/ApplicationGovernance/launguage/LaunguageDetailsScreen";
import EditLaunguageScreen from "./screens/ApplicationGovernance/launguage/EditLaunguageScreen";
// boilerplate
import CreateBoilerPlateScreen from "./screens/ApplicationGovernance/boilerPlate/CreateBoilerPlate";
import BoilerPlateDetailsScreen from "./screens/ApplicationGovernance/boilerPlate/BoilerPlateDetailsScreen";
import EditBoilerPlateScreen from "./screens/ApplicationGovernance/boilerPlate/EditBoilerPlateScreen";
// chassistemplate
import CreateChasisTemplateScreen from "./screens/ApplicationGovernance/chassisTemplate/CreateChasisTemplate";
import ChassisTemplateDetailsScreen from "./screens/ApplicationGovernance/chassisTemplate/ChassisTemplateDetailsScreen";
import EditChassisTemplateScreen from "./screens/ApplicationGovernance/chassisTemplate/EditChassisTemplateScreen";

//DataStandards
import DataStandardListScreen from "./screens/DataStandard/ListScreen/DataStandardListScreen";
import CreateField from "./screens/DataStandard/CreateScreens/CreateField";
import EditField from "./screens/DataStandard/EditScreen/EditField";
import FieldDetails from "./screens/DataStandard/DetailScreens/FieldDetails";

import AddNewDocument from "./screens/Documents/DocumentsList/AddNewDocument";
import ProjectDetailsList from "./screens/Documents/DocumentsList/ProjectDetailsList";
import ServiceGroupsDetails from "./screens/Documents/DocumentsList/ServiceGroupsDetails";
import ServiceDetailsList from "./screens/Documents/DocumentsList/ServiceDetailsList";

import AccessMaintenance from "./screens/AccessMaintenance/AccessMaintenance";
import CreateRole from "./screens/AccessMaintenance/CreateRole";
import EditRole from "./screens/AccessMaintenance/EditRole";

//Ontology screens
import ObjectListScreen from "./screens/Ontology/Objects/ObjectListScreen";
import ObjectDetailsScreen from "./screens/Ontology/Objects/ObjectDetailsScreen";
import FieldGroupListScreen from "./screens/Ontology/FieldGroups/FieldGroupsListScreen";
import FieldGroupDetailsScreen from "./screens/Ontology/FieldGroups/FieldGroupDetailsScreen";
import CreateFieldGroupScreen from "./screens/Ontology/FieldGroups/CreateFieldGroupScreen";
import FieldsListScreen from "./screens/Ontology/Fields/FieldsListScreen";
import CreateFieldScreen from "./screens/Ontology/Fields/CreateFieldScreen";
import FieldDetailsScreen from "./screens/Ontology/Fields/FieldDetailsScreen";
import SectionListScreen from "./screens/Ontology/Sections/SectionListScreen";
import SectionDetailsScreen from "./screens/Ontology/Sections/SectionDetailsScreen";
import CreateSectionScreen from "./screens/Ontology/Sections/CreateSectionScreen";
// user profile
import Settings from "./screens/UserProfile/Settings";

//Glossery Manager Screens
import GlosseryListScreen from "./screens/Glossery/GlossaryList/GlosseryListScreen";
import CreateGlossaryScreen from "./screens/Glossery/CreateGlossary/CreateGlossaryScreen";
import GlossaryDetails from "./screens/Glossery/GlossaryDetails/GlossaryDetails";
// Help Desk
import CreateTicket from "./screens/HelpDesk/CreateTicket";
import HelpDeskList from "./screens/HelpDesk/HelpDeskList";
import EditTicket from "./screens/HelpDesk/EditTicket";
import AddNewServiceGroup from "./screens/ServiceGroup/AddNewServiceGroup";

// dev
import Develop from "./screens/Errors/Development";

import AddAiTaskScreen from "./screens/Services/ManageAiTask/AddAiTaskScreen";
import EditAiTaskScreen from "./screens/Services/ManageAiTask/EditAiTaskScreen";
import EditAiTaskVersion from "./screens/Services/Version/EditAiTaskVersion";
import AddAiTaskVersion from "./screens/Services/Version/AddAiTaskVersion";
import EditAiTaskApi from "./screens/Services/API/EditAiTaskApi";
import AddAiTaskApi from "./screens/Services/API/AddAiTaskApi";
import CreateDataModel from "./screens/Services/ManageDataModel/CreateDataModel";
import DetailsDataModelService from "./screens/Services/ManageDataModel/DetailsDataModelService";
import EditDataModelService from "./screens/Services/ManageDataModel/EditDataModelService";
import AiWorkbenchList from "./screens/AiWorkbench/AiWorkbenchList";

//codeeditor
import EditorScreen from "./screens/CodeEditor/editorScreen";
import LoginFromSOS from "./screens/LoginFromSOS/LoginFromSOS";

//cluster
import ClusterAdd from "./screens/Cluster/ClusterAdd";
import ClusterEdit from "./screens/Cluster/ClusterEdit";
import ClusterDetails from "./screens/Cluster/ClusterDetails";
//host
import CreateDeploymentProcess from "./screens/Deployment/ManageDeployment/CreateDeploymentProcess";
import EditDeployment from "./screens/Deployment/ManageDeploymentEdit/EditDeployment";
import DetailDeployment from "./screens/Deployment/ManageDeploymentEdit/DetailDeployment";

import HostingList from "./screens/Deployment/HostingList";

//test
import TestList from "./screens/Test/TestList";
import EditTestScriptScreen from "./screens/Test/TestScript/EditTestScriptScreen";
import AddTestScriptScreen from "./screens/Test/TestScript/AddTestScriptScreen";
import AddTestDataScreen from "./screens/Test/TestData/AddTestDataScreen";
import AddTestExecutionScreen from "./screens/Test/TestExecution/AddTestExecutionScreen";
import ReRunTestExecutionScreen from "./screens/Test/Execution/ReRunTestExecutionScreen";
import EditTestExecutionScreen from "./screens/Test/Execution/EditTestExecutionScreen";
import EditTestDataScreen from "./screens/Test/TestData/EditTestDataScreen";
// Ui Application
import ListScreen from "./UiApplication/pages/ListScreen";
import CreateScreen from "./UiApplication/pages/CreateScreen";
import EditScreen from "./UiApplication/pages/EditScreen";
import Project from "./UiApplication/pages/Project";
import Error404 from "./screens/Errors/Error404";
import EditContainer from "./screens/Deployment/ManageDeploymentEdit/EditContainer";
import DetailContainer from "./screens/Deployment/ManageDeploymentEdit/DetailContainer";

//service bundle

import CreateServiceBundleTabbed from "./screens/Orchestration/Application/CreateApplicationScreen/CreateServiceBundleTabbed";
import AddServiceTabbed from "./screens/Services/ManageService/AddServiceTabbed";
import AddServiceScreen from "./screens/Services/ManageService/AddServiceScreen";
import AddAiTaskTabbedScreen from "./screens/Services/ManageAiTask/AddAiTaskTabbedScreen";
import OntologyListScreen from "./screens/Ontology/OntologyList/OntologyListScreen";
import EditContainer2 from "./screens/Deployment/ManageDeploymentEdit/EditContainer2";
import EditDocuement from "./screens/Documents/DocumentsList/EditDocument";

const routes = [
  //ontology
  {
    title: "Ontology",
    path: "/ontology",
    isPrivate: true,
    component: (props) => <OntologyListScreen {...props} />,
  },

  //test
  {
    title: "Test",
    path: "/test",
    isPrivate: true,
    component: (props) => <TestList {...props} />,
  },
  // login and 2FA
  {
    title: "Develop",
    path: "/develop",
    isPrivate: false,
    component: (props) => <Develop {...props} />,
  },
  {
    title: "Register",
    path: "/register",
    isPrivate: false,
    component: (props) => <SignupScreen {...props} />,
  },
  {
    title: "Login",
    path: "/",
    isPrivate: false,
    component: (props) => <LoginScreen {...props} />,
  },
  {
    title: "Login",
    path: "/login/from-sos-app",
    isPrivate: false,
    component: (props) => <LoginFromSOS {...props} />,
  },
  {
    title: "Forgot Password",
    path: "/forget-password",
    isPrivate: false,
    component: (props) => <ForgetPassword {...props} />,
  },
  {
    title: "Reset Password",
    path: "/reset-password",
    isPrivate: false,
    component: (props) => <ResetPassword {...props} />,
  },
  {
    title: "Verify OTP",
    path: "/verify-otp",
    isPrivate: false,
    component: (props) => <VerifyOTPScreen {...props} />,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    isPrivate: true,
    component: (props) => <Dashboard {...props} />,
  },
  // Project

  {
    title: "Project List",
    path: "/projects",
    isPrivate: true,
    component: (props) => <ProjectListScreen {...props} />,
  },
  {
    title: "Add Project",
    path: "/projects/add",
    isPrivate: true,
    component: (props) => <AddProjectScreen {...props} />,
  },
  {
    title: "Edit Project",
    path: "/projects/:action/:projectId",
    isPrivate: true,
    component: (props) => <EditProjectScreen {...props} />,
  },
  // Service group
  {
    title: "Add New Service Group",
    path: "/servicegroups/add",
    isPrivate: true,
    component: (props) => <AddNewServiceGroup {...props} />,
  },
  {
    title: "Add Service Group",
    path: "/servicegroups/add/:projectId",
    isPrivate: true,
    component: (props) => <AddServiceGroup {...props} />,
  },
  {
    title: "Add Service Group in Service Group",
    path: "/servicegroups/add/:projectId/:groupId",
    isPrivate: true,
    component: (props) => <AddServiceGroup {...props} />,
  },
  {
    title: "Edit Service Group",
    path: "/servicegroups/:action/:projectId/:groupId",
    isPrivate: true,
    component: (props) => <EditServiceGroup {...props} />,
  },
  // Services
  {
    title: "Services List",
    path: "/services",
    isPrivate: true,
    component: (props) => <ServicesList {...props} />,
  },
  {
    title: "Add Service",
    path: "/services-tabbed/add",
    isPrivate: true,
    component: (props) => <AddServiceTabbed {...props} />,
  },
  {
    title: "Add Service",
    path: "/services/add",
    isPrivate: true,
    component: (props) => <AddServiceScreen {...props} />,
  },
  {
    title: "Edit Service",
    path: "/services/:action/:serviceId",
    isPrivate: true,
    component: (props) => <EditService {...props} />,
  },
  {
    title: "Add Version",
    path: "/versions/add/:serviceId",
    isPrivate: true,
    component: (props) => <AddVersion {...props} />,
  },
  {
    title: "Edit Version",
    path: "/versions/:action/:serviceId/:versionId",
    isPrivate: true,
    component: (props) => <EditVersion {...props} />,
  },
  //AiTask Workbench
  {
    title: "AiTask Workbench List",
    path: "/aitask-workbench",
    isPrivate: true,
    component: (props) => <AiWorkbenchList {...props} />,
  },

  //
  {
    title: "CodeEditor",
    path: "/editor-screen/:serviceId/:versionId",
    isPrivate: true,
    component: (props) => <EditorScreen {...props} />,
  },

  //AI TASK
  {
    title: "Add AiTask",
    path: "/aitask/add",
    isPrivate: true,
    component: (props) => <AddAiTaskScreen {...props} />,
  },
  {
    title: "Add AiTask",
    path: "/aitask/add/tabbed",
    isPrivate: true,
    component: (props) => <AddAiTaskTabbedScreen {...props} />,
  },
  {
    title: "Edit AiTask",
    path: "/aitask/:action/:aiTaskId",
    isPrivate: true,
    component: (props) => <EditAiTaskScreen {...props} />,
  },
  //AITask Version

  {
    title: "Edit AI Task Vesion",
    path: "/aitask-versions/:action/:serviceId/:versionId",
    isPrivate: true,
    component: (props) => <EditAiTaskVersion {...props} />,
  },

  {
    title: "Add AiTask Version",
    path: "/ai-task-versions/add/:aiTaskId",
    isPrivate: true,
    component: (props) => <AddAiTaskVersion {...props} />,
  },

  //AITask API

  {
    title: "Edit AI Task API",
    path: "/ai-task-api/:action/:serviceId/:versionId/:apiId",
    isPrivate: true,
    component: (props) => <EditAiTaskApi {...props} />,
  },

  {
    title: "Add AiTask API",
    path: "/ai-task-api/add/:serviceId/:versionId",
    isPrivate: true,
    component: (props) => <AddAiTaskApi {...props} />,
  },

  //Data Models

  {
    title: "Add Data Model",
    path: "/dataModel/add",
    isPrivate: true,
    component: (props) => <CreateDataModel {...props} />,
  },

  {
    title: "Edit Data Models",
    path: "/datamodels/edit/:_id",
    isPrivate: true,
    component: (props) => <EditDataModelService {...props} />,
  },

  {
    title: "Details Data Models",
    path: "/datamodels/details/:_id",
    isPrivate: true,
    component: (props) => <DetailsDataModelService {...props} />,
  },

  // //cluster
  // {
  //   title: "Cluster List",
  //   path: "/host",
  //   isPrivate: true,
  //   component: (props) => <ClusterList {...props} />,
  // },
  {
    title: "Cluster List",
    path: "/cluster/add",
    isPrivate: true,
    component: (props) => <ClusterAdd {...props} />,
  },
  {
    title: "Cluster List",
    path: "/cluster/edit",
    isPrivate: true,
    component: (props) => <ClusterEdit {...props} />,
  },
  {
    title: "Cluster List",
    path: "/cluster/details/:clusterName/:configName",
    isPrivate: true,
    component: (props) => <ClusterDetails {...props} />,
  },

  // API
  {
    title: "Add API",
    path: "/apis/add/:serviceId/:versionId",
    isPrivate: true,
    component: (props) => <AddAPI {...props} />,
  },
  {
    title: "Edit API",
    path: "/apis/:action/:serviceId/:versionId/:apiId",
    isPrivate: true,
    component: (props) => <EditAPI {...props} />,
  },
  {
    title: "Add Field Group",
    path: "/fieldgroups/add",
    isPrivate: true,
    component: (props) => <CreateFieldGroupsService {...props} />,
  },
  {
    title: "Details Field Group",
    path: "/fieldgroups/details/:_id",
    isPrivate: true,
    component: (props) => <DetailsFieldGroupService {...props} />,
  },
  {
    title: "Edit Field Group",
    path: "/fieldgroups/edit/:_id",
    isPrivate: true,
    component: (props) => <EditFieldGroupService {...props} />,
  },
  // Application
  {
    title: "Service Bundle List",
    path: "/service-bundle",
    isPrivate: true,
    component: (props) => <ApplicationListScreen {...props} />,
  },
  {
    title: "Orchestration Details",
    path: "/application/edit/orchestration/:_id",
    isPrivate: true,
    component: (props) => <OrchestrationMainScreen {...props} />,
  },
  {
    title: "Create Application",
    path: "/create-Application",
    isPrivate: true,
    component: (props) => <CreateServiceBundleTabbed {...props} />,
  },
  {
    title: "Application Details",
    path: "/application/:action/:_id",
    isPrivate: true,
    component: (props) => <ApplicationDetailsScreen {...props} />,
  },
  {
    title: "Development",
    path: "/development",
    isPrivate: true,
    component: (props) => <Development {...props} />,
  },
  // Status Profile
  {
    title: "Status Profile",
    path: "/status-profile",
    isPrivate: true,
    component: (props) => <StatusProfileListScreen {...props} />,
  },
  {
    title: "Add Status Profile",
    path: "/status-profile/add",
    isPrivate: true,
    component: (props) => <CreateProfileStatus {...props} />,
  },
  {
    title: "Edit Status Profile",
    path: "/status-profile/edit/:_id",
    isPrivate: true,
    component: (props) => <EditStatusProfile {...props} />,
  },
  // Organization
  {
    title: "Organization Details",
    path: "/organization-deatils",
    isPrivate: true,
    component: (props) => <OrganizationDetailsScreen {...props} />,
  },
  {
    title: "Edit Organization",
    path: "/edit-organization",
    isPrivate: true,
    component: (props) => <EditOrganizationScreen {...props} />,
  },

  // Vendor
  {
    title: "Vendors List",
    path: "/vendors",
    isPrivate: true,
    component: (props) => <VendorList {...props} />,
  },
  {
    title: "Create Vendor",
    path: "/create-vendor",
    isPrivate: true,
    component: (props) => <CreateVendor {...props} />,
  },
  {
    title: "Vendor Details",
    path: "/vendor-details/:_id",
    isPrivate: true,
    component: (props) => <VendorDetails {...props} />,
  },
  {
    title: "Edit Vendor",
    path: "/edit-vendor/:_id",
    isPrivate: true,
    component: (props) => <EditvendorDetailsScreen {...props} />,
  },
  // Teams
  {
    title: "Teams",
    path: "/teams",
    isPrivate: true,
    component: (props) => <TeamsList {...props} />,
  },
  {
    title: "Add Team",
    path: "/create-team",
    isPrivate: true,
    component: (props) => <CreateTeam {...props} />,
  },
  {
    title: "Team Details",
    path: "/team-details/:_id",
    isPrivate: true,
    component: (props) => <TeamDetails {...props} />,
  },
  {
    title: "Edit Team",
    path: "/edit-team/:_id",
    isPrivate: true,
    component: (props) => <EditTeam {...props} />,
  },
  // Docker Image
  {
    title: "Docker Image List",
    path: "/docker-images",
    isPrivate: true,
    component: (props) => <ImageListScreen {...props} />,
  },
  {
    title: "Create Image",
    path: "/create-image",
    isPrivate: true,
    component: (props) => <CreateImageScreen {...props} />,
  },
  {
    title: "Image Details",
    path: "/image-details/:_id",
    isPrivate: true,
    component: (props) => <ImageDetailsScreen {...props} />,
  },
  {
    title: "Edit Image",
    path: "/edit-image/:_id",
    isPrivate: true,
    component: (props) => <EditImageScreen {...props} />,
  },
  // Containers
  {
    title: "Containers List",
    path: "/containers",
    isPrivate: true,
    component: (props) => <ContainerListScreen {...props} />,
  },
  {
    title: "Create Container",
    path: "/create-container",
    isPrivate: true,
    component: (props) => <CreateContainerScreen {...props} />,
  },
  {
    title: "Container Details",
    path: "/container-details/:_id",
    isPrivate: true,
    component: (props) => <ContainerDetailsScreen {...props} />,
  },
  {
    title: "Edit Container",
    path: "/edit-container/:_id",
    isPrivate: true,
    component: (props) => <EditContainerScreen {...props} />,
  },

  // Runtime Environments
  {
    title: "Runtime Environments List",
    path: "/runtime-environments",
    isPrivate: true,
    component: (props) => <EnvListScreen {...props} />,
  },
  {
    title: "Create Environment",
    path: "/create-environment",
    isPrivate: true,
    component: (props) => <CreateEnvScreen {...props} />,
  },
  {
    title: "Environment Details",
    path: "/environment-details/:_id",
    isPrivate: true,
    component: (props) => <EnvDetailsScreen {...props} />,
  },
  {
    title: "Edit Environment",
    path: "/edit-environment/:_id",
    isPrivate: true,
    component: (props) => <EditEnvScreen {...props} />,
  },
  // Users
  {
    title: "Users List",
    path: "/users",
    isPrivate: true,
    component: (props) => <UserListScreen {...props} />,
  },
  {
    title: "Create User",
    path: "/create-user",
    isPrivate: true,
    component: (props) => <CreateUserScreen {...props} />,
  },
  {
    title: "User Details",
    path: "/user-details/:_id",
    isPrivate: true,
    component: (props) => <UserDetailsScreen {...props} />,
  },
  {
    title: "Edit User",
    path: "/edit-user/:_id",
    isPrivate: true,
    component: (props) => <EditUserScreen {...props} />,
  },
  // Documents
  {
    title: "Documents List",
    path: "/documents",
    isPrivate: true,
    component: (props) => <DocumentsList {...props} />,
  },
  {
    title: "Add Document",
    path: "/documents/add",
    isPrivate: true,
    component: (props) => <AddNewDocument {...props} />,
  },
  {
    title: "Document Details",
    path: "/documents/details/:_id",
    isPrivate: true,
    component: (props) => <EditDocuement isEditMode={false} {...props} />,
  },
  {
    title: "Edit Document",
    path: "/documents/edit/:_id",
    isPrivate: true,
    component: (props) => <EditDocuement isEditMode={true} {...props} />,
  },
  {
    title: "Project Documents",
    path: "/documents/project/:_id",
    isPrivate: true,
    component: (props) => <ProjectDetailsList {...props} />,
  },
  {
    title: "Service Documents",
    path: "/documents/service/:_id",
    isPrivate: true,
    component: (props) => <ServiceDetailsList {...props} />,
  },
  {
    title: "Servicegroup Documents",
    path: "/documents/servicegroup/:projectId/:_id",
    isPrivate: true,
    component: (props) => <ServiceGroupsDetails {...props} />,
  },
  // Access Maintenance
  {
    title: "Role Management",
    path: "/role-management",
    isPrivate: true,
    component: (props) => <AccessMaintenance {...props} />,
  },
  {
    title: "Add Role",
    path: "/role-management/add",
    isPrivate: true,
    component: (props) => <CreateRole {...props} />,
  },
  {
    title: "Edit Role",
    path: "/role-management/:action/:roleId",
    isPrivate: true,
    component: (props) => <EditRole {...props} />,
  },
  {
    title: "Details Role",
    path: "/role-management/:action/:roleId",
    isPrivate: true,
    component: (props) => <EditRole {...props} />,
  },
  // Application Governance
  {
    title: "Application Governance List",
    path: "/application-governance",
    isPrivate: true,
    component: (props) => <ApplicationGovernanceListScreen {...props} />,
  },
  {
    title: "Create Language",
    path: "/create-language",
    isPrivate: true,
    component: (props) => <CreateLaunguageScreen {...props} />,
  },
  {
    title: "Language Details",
    path: "/language-details/:_id",
    isPrivate: true,
    component: (props) => <LaunguageDetailsScreen {...props} />,
  },
  {
    title: "Edit Languages",
    path: "/edit-language/:_id",
    isPrivate: true,
    component: (props) => <EditLaunguageScreen {...props} />,
  },
  {
    title: "Create Boilerplate",
    path: "/create-boilerplate",
    isPrivate: true,
    component: (props) => <CreateBoilerPlateScreen {...props} />,
  },
  {
    title: "Boilerplate Details",
    path: "/boilerplate-details/:_id",
    isPrivate: true,
    component: (props) => <BoilerPlateDetailsScreen {...props} />,
  },
  {
    title: "Edit Boilerplate",
    path: "/edit-boilerplate/:_id",
    isPrivate: true,
    component: (props) => <EditBoilerPlateScreen {...props} />,
  },
  {
    title: "Create Chassitemplate",
    path: "/create-chassistemplate",
    isPrivate: true,
    component: (props) => <CreateChasisTemplateScreen {...props} />,
  },
  // Data Standard
  {
    title: "Data Standards",
    path: "/data-standards",
    isPrivate: true,
    component: (props) => <DataStandardListScreen {...props} />,
  },
  {
    title: "Create Field",
    path: "/create-fields",
    isPrivate: true,
    component: (props) => <CreateField {...props} />,
  },
  {
    title: "Edit Field",
    path: "/edit-field/:_id",
    isPrivate: true,
    component: (props) => <EditField {...props} />,
  },
  {
    title: "Field Details",
    path: "/details-field/:_id",
    isPrivate: true,
    component: (props) => <FieldDetails {...props} />,
  },
  {
    title: "Chassis Template Details",
    path: "/chassistemplate-details/:_id",
    isPrivate: true,
    component: (props) => <ChassisTemplateDetailsScreen {...props} />,
  },
  {
    title: "Edit Chassis Template",
    path: "/edit-chassistemplate/:_id",
    isPrivate: true,
    component: (props) => <EditChassisTemplateScreen {...props} />,
  },
  // Ontology screens route
  {
    title: "Objects List",
    path: "/ontology/objects",
    isPrivate: true,
    component: (props) => <ObjectListScreen {...props} />,
  },
  {
    title: "Object Details",
    path: "/ontology/objects/:objectName",
    isPrivate: true,
    component: (props) => <ObjectDetailsScreen {...props} />,
  },
  {
    title: "Sections List",
    path: "/ontology/sections",
    isPrivate: true,
    component: (props) => <SectionListScreen {...props} />,
  },
  {
    title: "Add Section",
    path: "/ontology/sections/add",
    isPrivate: true,
    component: (props) => <CreateSectionScreen {...props} />,
  },
  {
    title: "Section Details",
    path: "/ontology/sections/:sectionID",
    isPrivate: true,
    component: (props) => <SectionDetailsScreen {...props} />,
  },
  {
    title: "Field Groups List",
    path: "/ontology/fieldgroups",
    isPrivate: true,
    component: (props) => <FieldGroupListScreen {...props} />,
  },
  {
    title: "Add FieldGroup",
    path: "/ontology/fieldgroups/add",
    isPrivate: true,
    component: (props) => <CreateFieldGroupScreen {...props} />,
  },
  {
    title: "Field GroupDetails",
    path: "/ontology/fieldgroups/:fieldgroupID",
    isPrivate: true,
    component: (props) => <FieldGroupDetailsScreen {...props} />,
  },
  {
    title: "Fields List",
    path: "/ontology/fields",
    isPrivate: true,
    component: (props) => <FieldsListScreen {...props} />,
  },
  {
    title: "Add Field",
    path: "/ontology/fields/add",
    isPrivate: true,
    component: (props) => <CreateFieldScreen {...props} />,
  },
  {
    title: "Field Details",
    path: "/ontology/fields/:fieldID",
    isPrivate: true,
    component: (props) => <FieldDetailsScreen {...props} />,
  },

  //Glossary
  {
    title: "Glossary List",
    path: "/glossary",
    isPrivate: true,
    component: (props) => <GlosseryListScreen {...props} />,
  },
  {
    title: "Create glossary",
    path: "/create-glossary",
    isPrivate: true,
    component: (props) => <CreateGlossaryScreen {...props} />,
  },
  {
    title: "Glossary Details",
    path: "/glossary-details/:_id",
    isPrivate: true,
    component: (props) => <GlossaryDetails isEditMode={false} {...props} />,
  },
  {
    title: "Edit glossary",
    path: "/edit-glossary/:_id",
    isPrivate: true,
    component: (props) => <GlossaryDetails isEditMode={true} {...props} />,
  },
  {
    title: "Add Field",
    path: "/ontology/fields/add",
    isPrivate: true,
    component: (props) => <CreateFieldScreen {...props} />,
  },
  {
    title: "Field Details",
    path: "/ontology/fields/:fieldID",
    isPrivate: true,
    component: (props) => <FieldDetailsScreen {...props} />,
  },
  {
    title: "User Profile",
    path: "/settings",
    isPrivate: true,
    component: (props) => <Settings {...props} />,
    //Help Desk
  },
  {
    title: "Help Desk List",
    path: "/helpdesk",
    isPrivate: true,
    component: (props) => <HelpDeskList {...props} />,
  },
  {
    title: "Create Ticket",
    path: "/helpdesk/create",
    isPrivate: true,
    component: (props) => <CreateTicket {...props} />,
  },
  {
    title: "Edit Ticket",
    path: "/helpdesk/edit/:id",
    isPrivate: true,
    component: (props) => <EditTicket {...props} />,
  },

  // Ui Application
  {
    title: "Ui Applications",
    path: "/ui-applications",
    isPrivate: true,
    component: (props) => <ListScreen {...props} />,
  },
  {
    title: "Create Ui Applications",
    path: "/ui-applications/create",
    isPrivate: true,
    component: (props) => <CreateScreen {...props} />,
  },
  {
    title: "Edit Ui Applications",
    path: "/ui-applications/edit/:id",
    isPrivate: true,
    component: (props) => <EditScreen isEditMode={true} {...props} />,
  },
  {
    title: "Ui Applications Details",
    path: "/ui-applications/details/:id",
    isPrivate: true,
    component: (props) => <EditScreen isEditMode={false} {...props} />,
  },
  {
    title: "Ui Applications Canvas",
    path: "/ui-applications/:projectId/:page",
    isPrivate: true,
    component: (props) => <Project {...props} />,
  },
  {
    title: "Ui Applications Publish",
    path: "/ui-applications/:view/:projectId/:page/:device?",
    isPrivate: true,
    component: (props) => <Project {...props} />,
  },

  {
    title: "Edit Ticket",
    path: "/helpdesk/edit/:id",
    isPrivate: true,
    component: (props) => <EditTicket {...props} />,
  },
  // Host
  {
    title: "Create Deployment",
    path: "/host/create-deployment",
    isPrivate: true,
    component: (props) => <CreateDeploymentProcess {...props} />,
  },
  {
    title: "Edit Deployment",
    path: "/host/edit/:deploymentId",
    isPrivate: true,
    component: (props) => <EditDeployment {...props} />,
  },
  {
    title: "Edit Deployment",
    path: "/host/details/:deploymentId",
    isPrivate: true,
    component: (props) => <DetailDeployment {...props} />,
  },
  {
    title: "Edit Deployment",
    path: "/host/container/edit/:deploymentId/:ContainerName",
    isPrivate: true,
    component: (props) => <EditContainer {...props} />,
  },
  {
    title: "Edit Deployment2",
    path: "/host/container/edit2/:deploymentId/:ContainerName",
    isPrivate: true,
    component: (props) => <EditContainer2 {...props} />,
  },
  {
    title: "Detail Deployment",
    path: "/host/container/detail/:deploymentId/:ContainerName",
    isPrivate: true,
    component: (props) => <DetailContainer {...props} />,
  },
  {
    title: "Hosting",
    path: "/host",
    isPrivate: true,
    component: (props) => <HostingList {...props} />,
  },
  //Test
  {
    title: "Edit Test Script",
    path: "/test-script/:action/:testScriptID",
    isPrivate: true,
    component: (props) => <EditTestScriptScreen {...props} />,
  },

  {
    title: "Edit Test Execution",
    path: "/test-execute/:action/:testExecutionID",
    isPrivate: true,
    component: (props) => <EditTestExecutionScreen {...props} />,
  },

  {
    title: "ReRun Test Execution",
    path: "/test-execute/:action/rerun/:testExecutionID",
    isPrivate: true,
    component: (props) => <ReRunTestExecutionScreen {...props} />,
  },
  {
    title: "Edit Test Data",
    path: "/test-data/:action/:testDataID",
    isPrivate: true,
    component: (props) => <EditTestDataScreen {...props} />,
  },
  {
    title: "Add Test Script",
    path: "/test-script/add",
    isPrivate: true,
    component: (props) => <AddTestScriptScreen {...props} />,
  },
  {
    title: "Add Test Data",
    path: "/test-data/add",
    isPrivate: true,
    component: (props) => <AddTestDataScreen {...props} />,
  },
  {
    title: "Add Test Execution",
    path: "/test-execute/add",
    isPrivate: true,
    component: (props) => <AddTestExecutionScreen {...props} />,
  },
  {
    title: "Not Found",
    path: "*",
    isPrivate: false,
    component: (props) => <Error404 {...props} />,
  },
];

export default routes;
