import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { BASE_URL } from "./config";
import { store } from "../../redux/store";
import { addAuthData } from "../../redux/actions/authAction";

let accessToken = "";
let refreshToken = "";
let userId = "";



const listener = () => {
  let state = store.getState();
  const { accesstoken, refreshtoken, userid } = state.authReducer;
  axios.defaults.headers.common["token"] = accesstoken;
  accessToken = accesstoken;
  refreshToken = refreshtoken;
  userId = userid;
};
store.subscribe(listener);

const refreshAuthLogic = async (failedRequest) => {
  await axios
    .put(`${BASE_URL}/api/v1.0.0/users/${userId}/createtokens`, {
      refreshtoken: refreshToken,
    })
    .then((res) => {
      let data = {
        accesstoken: res?.data?.data?.[0]?.accesstoken,
        refreshtoken: res?.data?.data?.[1]?.refreshtoken,
      };
      store.dispatch(addAuthData(data));
      return Promise.resolve();
    })
    .catch((error) => {
    });
};

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401],
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  
  if(error?.response?.status === 401){
  localStorage.clear()
  store.dispatch(addAuthData({
      accesstoken: "",
      refreshtoken: "",
      userid: "",
      token_expired_msg: "Token is no longer valid, Please Login again to continue."
    }))
  }
 
  return Promise.reject(error);
});

export class ApiHelper {
  async get(uri, headers) {
    const response = await axios.get(uri, {
      headers: headers
        ? headers
        : { organization: "60ba3575e1bc3c06e220ab5a", token: accessToken },
    });
    return response;
  }
  async post(uri, data) {
    const response = await axios.post(uri, data, {
      headers: { token: accessToken },
    });
    return response;
  }
  async delete(uri) {
    const response = await axios.delete(uri, {
      headers: { organization: "60c4b8a9bf23a55ec0e278a1", token: accessToken },
    });
    return response;
  }
  async put(uri, data, headers) {
    const response = await axios.put(uri, data, {
      headers: {
        ...headers,
        organization: "60c4b8a9bf23a55ec0e278a1",
        token: accessToken,
      },
    });
    return response;
  }

  async postFormData(uri, data) {
    const response = await axios.post(uri, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        organization: "60c4b8a9bf23a55ec0e278a1",
        token: accessToken,
      },
    });
    return response;
  }
  async update(uri, data) {
    const response = await axios.put(uri, data, {
      headers: {
        "Content-Type": "application/json",
        token: accessToken,
      },
    });
    return response;
  }
  async getWithBody(uri, data) {
    const response = await axios.get(uri, data, {
      headers: {
        organization: "60c4b8a9bf23a55ec0e278a1",
        token: accessToken,
      },
    });
    return response;
  }
  
  async getWithCustomHeader(uri, headers) {
    const response = await axios.get(uri, {
      headers: headers
        ? {...headers, organization: "60ba3575e1bc3c06e220ab5a"}
        : { organization: "60ba3575e1bc3c06e220ab5a" },
    });
    return response;
  }
}
