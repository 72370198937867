const imagesColumns = [
  {
    name: "imagename",
    title: "Docker Image Name",
    route: "/image-details",
    for: "dockerImage",
  },
  { name: "state", title: "State", for: "dockerImage" },
  { name: "imagetag", title: "Docker Image Tag", for: "dockerImage" },
  { name: "createdby", title: "Created By", for: "dockerImage" },
  { name: "createdon", title: "Created On", for: "dockerImage" },
  { name: "dockerimageaction", title: "Action" },
];

const imagesDefaultColumnWidths = [
  { columnName: "imagename", weightage: 2, width: 200 },
  { columnName: "state", weightage: 2, width: 200 },
  { columnName: "imagetag", weightage: 2, width: 200 },
  { columnName: "createdby", weightage: 1, width: 200 },
  { columnName: "createdon", weightage: 1, width: 200 },
  { columnName: "dockerimageaction", weightage: 2, width: 200 },
];

const imagesTableColumnExtensions = [
  { columnName: "imagename", align: "left" },
  { columnName: "state", align: "left" },
  { columnName: "imagetag", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "dockerimageaction", align: "center", sortingEnabled: false },
];

const imagesColumnOrder = [
  "imagename",
  "state",
  "imagetag",
  "createdby",
  "createdon",
];
const imagesColumnHidden = [
  "imagename",
  "state",
  "imagetag",
  "createdon",
  "dockerimageaction",
];

const imagesDefaultSorting = [{ columnName: "imagename", direction: "asc" }];

export {
  imagesColumns,
  imagesDefaultColumnWidths,
  imagesTableColumnExtensions,
  imagesColumnOrder,
  imagesDefaultSorting,
  imagesColumnHidden,
};
