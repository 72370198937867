export const ValidateDependencies = (container) => {
  let withErrorsContainer = container;
  const portRegex =
    /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
  var isValid = true;
  withErrorsContainer?.map((item) => {
    let error = { port: {}, replica: {}, container_name: {} };
    if (item.select) {
      if (item.dependencies) {
        if (item.port) {
          if (!portRegex.test(item.port)) {
            error.port["isError"] = true;
            error.port["msg"] = "Invalid port";
            isValid = false;
          }
        } else {
          error.port["isError"] = true;
          error.port["msg"] = "Port is required";
          isValid = false;
        }
      }
      if (item?.replica <= 0) {
        error.replica["msg"] = "Invalid number";
        error.replica["isError"] = true;
        isValid = false;
      }

      if (!item?.container_name || item?.container_name === "") {
        error.container_name["msg"] = "Container name required";
        error.container_name["isError"] = true;
        isValid = false;
      } else {
        let pattern = /^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]$/;

        if (item?.container_name.length > 50) {
          error.container_name["msg"] =
            "Container name should not be more than 50 characters";
          error.container_name["isError"] = true;
          isValid = false;
        } else if (!pattern.test(item?.container_name)) {
          error.container_name["msg"] =
            "Invalid Container name. Only lower-case letters[a-z] and numbers[0-9] are allowed. No spaces and special characters";
          error.container_name["isError"] = true;
          isValid = false;
        }
      }
    }
    item["error"] = error;
    // if (item.select) {
    //   if (!item?.port) {
    //     if (portRegex.test(item.port)) {
    //         console.log("calliksdmkasmlkfdsmlka", "Invalid port");
    //     }
    //   } else {
    //     console.log("calliksdmkasmlkfdsmlka", "port is required");
    //   }
    // }
  });

  return { isValid, withErrorsContainer };
};
