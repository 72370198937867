import { EntitySchemaServices } from "../../services/entitySchemaService";

const entitySchemaService = new EntitySchemaServices();

export const fetchResourceByPermission = async (resourceId, permission) => {
  try {
    const res_data = await entitySchemaService.getResourceByPermission(
      resourceId,
      permission
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getResourceByPermission2 = async (resouceName, permission) => {
  try {
    const res_data = await entitySchemaService.getResourceByPermission2(
      resouceName,
      permission
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchResourceByName = async (resource) => {
  try {
    const res_data = await entitySchemaService.getResourceByName(resource);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
