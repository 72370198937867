import React from "react";
import manageScreenStyles from "../../../../screens/CommonStyles/manageScreenStyles";
import { Grid, Select, MenuItem } from "@material-ui/core";
import {
  contentEncodingValues,
  contentMediaTypeValues,
} from "../../../../constants/DataStandard/Fields";
import { useSnackbar } from "notistack";

const FieldRulesSection = ({
  field,
  setFieldData,
  fieldData,
  error,
  isClear,
  isTwoTypeFields,
  clearRulesHandler,
  isEditMode,
}) => {
  const styles = manageScreenStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleRuleChange = (event) => {
    if (
      event.target.name === "exclusiveMinimum" ||
      event.target.name === "exclusiveMaximum"
    ) {
      setFieldData({
        ...fieldData,
        rules: { ...fieldData.rules, [event.target.name]: event.target.value },
      });
    } else if (
      event.target.name === "minLength" ||
      event.target.name === "maxLength" ||
      event.target.name === "minimum" ||
      event.target.name === "maximum" ||
      event.target.name === "multipleOf"
    ) {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        const eventValue = Number(event.target.value);
        setFieldData({
          ...fieldData,
          rules: { ...fieldData.rules, [event.target.name]: eventValue },
        });
      }
    } else {
      const eventValue = String(event.target.value);
      setFieldData({
        ...fieldData,
        rules: { ...fieldData.rules, [event.target.name]: eventValue },
      });
    }
  };
  return (
    <Grid item xs={12}>
      <Grid
        item
        xs={6}
        sm={6}
        key={field._id}
        style={{ maxWidth: "calc(50% - 12px)", flexBasis: "calc(50% - 12px)" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel || "Field Rules"}
              <span className={styles.fieldMenu}>
                {field?.fieldLabel || "Field Rules" ? " (optional)" : " "}
              </span>
            </label>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "end" }}>
            <label
              className={
                isClear ? styles.fieldclearAllVisible : styles.fieldclearAll
              }
              onClick={isClear ? clearRulesHandler : () => {}}
            >
              Clear all
            </label>
          </Grid>
        </Grid>

        {fieldData.type === "integer" ? (
          <div className={styles.dropdownContent}>
            <Grid container spacing={3}>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Minimum</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter min"}
                  name="minimum"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.minimum}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Maximum</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter max"}
                  name="maximum"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.maximum}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className={styles.fieldHeading2}>Multiple Of</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter multiple of"}
                  name="multipleOf"
                  onChange={handleRuleChange}
                  value={fieldData.rules.multipleOf}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 15 }}>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>
                  Exclusive Minimum
                </label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        exclusiveMinimum: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.exclusiveMinimum}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={true} className={styles.fieldHeading2}>
                    {"true"}
                  </MenuItem>
                  <MenuItem value={false} className={styles.fieldHeading2}>
                    {"false"}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>
                  Exclusive Maximum
                </label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        exclusiveMaximum: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.exclusiveMaximum}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={true} className={styles.fieldHeading2}>
                    {"true"}
                  </MenuItem>
                  <MenuItem value={false} className={styles.fieldHeading2}>
                    {"false"}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
        ) : fieldData.type === "number" ? (
          <div className={styles.dropdownContent}>
            <Grid container spacing={3}>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Minimum</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter min"}
                  name="minimum"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.minimum}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Maximum</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter max"}
                  name="maximum"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.maximum}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className={styles.fieldHeading2}>Multiple Of</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter multiple of"}
                  name="multipleOf"
                  onChange={handleRuleChange}
                  value={fieldData.rules.multipleOf}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 15 }}>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>
                  Exclusive Minimum
                </label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        exclusiveMinimum: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.exclusiveMinimum}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={true} className={styles.fieldHeading2}>
                    {"true"}
                  </MenuItem>
                  <MenuItem value={false} className={styles.fieldHeading2}>
                    {"false"}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>
                  Exclusive Maximum
                </label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        exclusiveMaximum: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.exclusiveMaximum}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={true} className={styles.fieldHeading2}>
                    {"true"}
                  </MenuItem>
                  <MenuItem value={false} className={styles.fieldHeading2}>
                    {"false"}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
        ) : fieldData.type === "string" ? (
          <div className={styles.dropdownContent}>
            <Grid container spacing={3}>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Min Length</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter length"}
                  name="minLength"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.minLength}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <label className={styles.fieldHeading2}>Max Length</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter length"}
                  name="maxLength"
                  style={{ paddingRight: "5px" }}
                  onChange={handleRuleChange}
                  value={fieldData.rules.maxLength}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className={styles.fieldHeading2}>Pattern</label>
                <input
                  type="text"
                  className={`${styles.input} ${
                    !isEditMode && styles.editMode
                  } ${error.rules?.pattern && styles.error}`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  placeholder={!isEditMode ? "" : "Enter pattern"}
                  name="pattern"
                  onChange={handleRuleChange}
                  value={fieldData.rules.pattern}
                />
                {/* {fieldData?.rules?.pattern &&
                fieldData?.rules?.pattern?.length < 3 ? (
                  <p className={styles.errorMsg}>
                    Pattern must have at least 3 characters.
                  </p>
                ) : (
                  <></>
                )} */}
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 15 }}>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>Content Encoding</label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        contentEncoding: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.contentEncoding}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {contentEncodingValues.map((item) => (
                    <MenuItem value={item} className={styles.fieldHeading2}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <label className={styles.fieldHeading2}>
                  Content Media Type
                </label>
                <Select
                  onChange={(e) => {
                    setFieldData({
                      ...fieldData,
                      rules: {
                        ...fieldData.rules,
                        contentMediaType: e.target.value,
                      },
                    });
                  }}
                  value={fieldData.rules.contentMediaType}
                  className={`${styles.select} ${
                    !isEditMode && styles.editMode
                  }`}
                  disabled={!isEditMode || field.editable === "FALSE"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {contentMediaTypeValues.map((item) => (
                    <MenuItem value={item} className={styles.fieldHeading2}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={styles.dropdownContent}>Nil</div>
        )}
      </Grid>
    </Grid>
  );
};

export default FieldRulesSection;
