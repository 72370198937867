import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";

const FolderAction = ({ CreateNewFolder, CreateNewFile, DeleteFileFolder, RenameFileAndFolder, RefreshTree, activePath, handleDownload, isEdit }) => {
  const Styles = {
    button: {
      marginRight: "5px",
    },
  };
  return (
    <div>
      <div>
        <div style={{  display:'flex',  justifyContent:'space-around' }}>
        <Tooltip title="Add Folder" arrow>
          <IconButton
            size="small"
            style={Styles.button}
            onClick={CreateNewFolder}
            disabled={activePath.type !== 'folder' || isEdit}

          >
            <CreateNewFolderIcon fontSize="inherit" />
          </IconButton>
          </Tooltip>
          <Tooltip title="Add File" arrow>
          <IconButton
            onClick={CreateNewFile}
            size="small"
            style={Styles.button}
            disabled={activePath.type !== 'folder' || isEdit}
          >
            <InsertDriveFileIcon fontSize="inherit" />
          </IconButton>
          </Tooltip>

          <Tooltip title="Rename" arrow>
          <IconButton
            onClick={RenameFileAndFolder}
            color="inherit"
            size="small"
            style={Styles.button}
            disabled={!activePath.name || !activePath?.path_name?.includes("/") || isEdit}

          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          </Tooltip>

          <Tooltip title="Delete" arrow>

          <IconButton
            onClick={DeleteFileFolder}
            color="inherit"
            size="small"
            style={Styles.button}
            disabled={!activePath.name || !activePath?.path_name?.includes("/") || isEdit}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          </Tooltip>

          <Tooltip title="Refresh" arrow>
          <IconButton onClick={RefreshTree} color="inherit" size="small" style={Styles.button}
            disabled={isEdit}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
</Tooltip>
<Tooltip title="Download" arrow>
          <IconButton color="inherit" size="small" style={Styles.button}>
            <GetAppIcon
                onClick={handleDownload}
              fontSize="inherit"
            />
          </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { treeData, activePath, isEdit } = state.codeEditorReducer;
  return {
    treeData,
    activePath,
    isEdit,
  };
};

export default connect(mapStateToProps) (FolderAction);
