import * as React from "react";
import ActionStyles from "./AddActionStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { editComponent } from "../../../../../../redux/actions/uiApplicationAction";
import { useDispatch } from "react-redux";
import useGetData from "../../../../../hooks/useGetData";

const AddAction = () => {
  const styles = ActionStyles();
  const dispatch = useDispatch();
  const { currentPage, currentComponent } = useGetData()

  const handleChange = (e) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          [e.target.name]: e.target.checked,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  }

  const handleChangeBorderWidth = (value) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          borderWidth: value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage.id,
        data: components,
      })
    );
  }

  return (
    <div>
      <div className={styles.actionsContainer}>
        <FormControlLabel
          control={<Checkbox
            size="small"
            color="primary"
            onChange={handleChange}
            checked={currentComponent.add}
            name={"add"}
          />}
          label={<span className={styles.label}>Add button</span>}
        />
        <FormControlLabel
          control={<Checkbox
            size="small"
            color="primary"
            onChange={handleChange}
            checked={currentComponent.edit}
            name={"edit"} />}
          label={<span className={styles.label}>Add Edit</span>}
        />
      </div>
      <div className={styles.actionsContainer}>
        <FormControlLabel control={<Checkbox
          size="small"
          color="primary"
          onChange={handleChange}
          name={"delete"}
          style={{ padding: '9px 6px 9px 9px' }}
          checked={currentComponent.delete}
        />} label={<span className={styles.label}>Add Delete</span>} />
        <FormControlLabel control={<Checkbox
          size="small"
          color="primary"
          onChange={handleChange}
          checked={currentComponent.filter}
          style={{ padding: '9px 6px 9px 9px' }}
          name={"filter"}
        />} label={<span className={styles.label}>Add filter</span>} />
      </div>
      <div className={styles.actionsContainer}>
        <FormControlLabel control={<Checkbox
          size="small"
          color="primary"
          onChange={handleChange}
          name={"border"}
          style={{ padding: '9px 6px 9px 9px' }}
          checked={currentComponent.border}
        />} label={<span className={styles.label}>Border</span>} />
      </div>
      {currentComponent?.border && <div className="field">
        <input
          type="number"
          name={'borderWidth'}
          value={currentComponent?.borderWidth}
          autoComplete="off"
          onChange={(e) => handleChangeBorderWidth(e.target.value)}
        />
      </div>}
    </div>
  );
};

export default AddAction;
