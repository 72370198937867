import {
  ADD_CANVAS_APPLICATION_DATA,
  ADD_TEMPLATE_DATA,
  ADD_ELEMENTS_TO_LIST,
  EDIT_ELEMENT_FROM_LIST,
  REPLACE_ELEMENT_LIST,
  ADD_ANIMATIONS,
  REMOVE_ANIMATIONS,
  ALTER_SIMULATE_STATUS,
  ADD_REMOVE_DEBUG_POINTS,
  ADD_PROCESS_OUTPUT,
  ADD_CONTAINER_DATA_FOR_TEMPLATE,
  ALTER_FLOW_MODE,
  ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION,
  ADD_INPUT_OUTPUT_DATA_FOR_NODES,
  ALTER_DEBUG_DATA,
  ADD_DEBUG_ANIMATIONS,
  REMOVE_ALL_DEBUG_POINTS
} from "../actionTypes";

const initialState = {
  process_data: {
    templatename: ""
  },
  application_data: {
    error: false,
    loading: false,
    data: {},
  },
  elements: [],
  debug_points: [],
  debug_data: {
    debug_status: false,
    debug_instanceID: "",
    body: "",
    debug_context_data: {}
  },
  container_data: {},
  simulate_status: false,
  flow_mode: "process-flow",
  process_output: {
    body: "",
    instanceid: "",
    template_context_data: {}
  },
  data_flow_nodes_io_details: [],
  call_node_recommendation_arr: ["templateInput", "payload"]
};

export default function orchestration(state = initialState, action) {
  switch (action.type) {
    case ADD_CANVAS_APPLICATION_DATA: {
      return {
        ...state,
        application_data: { ...state.application_data, ...action.payload },
      };
    }
    case ADD_TEMPLATE_DATA: {
      return {
        ...state,
        process_data: {
          ...state.process_data,
          ...action.payload,
        },
      };
    }
    case ADD_ELEMENTS_TO_LIST: {
      return {
        ...state,
        elements: [...state.elements, action.payload],
      };
    }
    case EDIT_ELEMENT_FROM_LIST: {
      const { nodeid } = action.payload;
      var new_els = state.elements.map((item) => {
        if (item.id === nodeid) {
          return {
            ...item,
            data: {...item.data, ...action.payload},
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        elements: new_els,
      };
    }
    case REPLACE_ELEMENT_LIST: {
      return {
        ...state,
        elements: action.payload,
      };
    }
    case ADD_ANIMATIONS: {
      var nodes_arr = [];
      var edges_arr = [];
      // const { template_context_data } = state.process_output;
      const nodes = state.process_output?.template_context_data?.nodes;
      state.elements.forEach((item) => {
        if (item.source && item.target) {
          edges_arr.push(item)
        } else {
          nodes_arr.push(item)
        }
      });
      nodes_arr.forEach((node) => {
        if (node.type === "start_node") {
          const { name, startnodeid } = node.data;
          const node_output = nodes && nodes.find((node_) => node_["name"] === `${name}_start`)
          if (node_output && JSON.parse(node_output.context).error === null) {
            edges_arr = edges_arr.map((edge) => {
              if (edge.source === startnodeid) {
                return {
                  ...edge,
                  animated: true,
                  style: { stroke: "green" }
                }
              } else {
                return edge
              }
            })
          }
        } else if (node.type === "call_node") {
          const { name, nodeid } = node.data;
          const node_output = nodes && nodes.find((node_) => node_["name"] === name);
          if (node_output && JSON.parse(node_output.context).error === null) {
            edges_arr = edges_arr.map((edge) => {
              if (edge.source === nodeid) {
                return {
                  ...edge,
                  animated: true,
                  style: { stroke: "green" }
                }
              } else {
                return edge
              }
            })
          }
        } else if (node.type === "switch_node") {
          const { name, nodeid } = node.data;
          const node_output = nodes && nodes.find((node_) => node_["name"] === name);
          // const { error, result } = JSON.parse(node_output.context);
          if (node_output && JSON.parse(node_output.context).error === null) {
            edges_arr = edges_arr.map((edge) => {
              const { result } = JSON.parse(node_output.context);
              if (edge.label === result?.conditionName) {
                return {
                  ...edge,
                  animated: true,
                  style: { stroke: "green" }
                }
              } else {
                return edge
              }
            })
          }
        } else if (node.type === "join_node") {
          const { name, nodeid } = node.data;
          const node_output = nodes && nodes.find((node_) => node_["name"] === name);
          // const { error, result } = JSON.parse(node_output.context);
          if (node_output && JSON.parse(node_output.context).error === null) {
            edges_arr = edges_arr.map((edge) => {
              if (edge.source === nodeid) {
                return {
                  ...edge,
                  animated: true,
                  style: { stroke: "green" }
                }
              } else {
                return edge
              }
            })
          }
        }
      })
      const new_els = [...nodes_arr, ...edges_arr]
      return {
        ...state,
        elements: new_els,
      };
    }
    case REMOVE_ANIMATIONS: {
      const new_els =
        state.elements.length > 0 &&
        state.elements.map((item) => {
          if (item.source && item.target) {
            return {
              ...item,
              animated: false,
              style: {},
            };
          } else {
            return item;
          }
        });
      return {
        ...state,
        elements: new_els,
      };
    }
    case ALTER_SIMULATE_STATUS: {
      return {
        ...state,
        simulate_status: action.payload.flag,
      };
    }
    case ADD_REMOVE_DEBUG_POINTS: {
      const { add, id, name } = action.payload;
      if (add) {
        return {
          ...state,
          debug_points: [...state.debug_points, name]
        }
      } else {
        var new_points = state.debug_points.length > 0 && state.debug_points.filter((item) => item !== name);
        return {
          ...state,
          debug_points: new_points
        }
      }
    }
    case ADD_PROCESS_OUTPUT: {
      return {
        ...state,
        process_output: {
          ...state.process_output,
          ...action.payload
        }
      }
    }
    case ADD_CONTAINER_DATA_FOR_TEMPLATE: {
      return {
        ...state,
        container_data: action.payload
      }
    }
    case ALTER_FLOW_MODE: {
      return {
        ...state,
        flow_mode: action.payload
      }
    }
    case ADD_OUTFIELD_FOR_CALL_NODE_RECOMMENDATION: {
      return {
        ...state,
        call_node_recommendation_arr: [
          ...state.call_node_recommendation_arr,
          action.payload
        ]
      }
    }
    case ADD_INPUT_OUTPUT_DATA_FOR_NODES: {
      const { data, edit, replace } = action.payload;
      const { id } = data;
      if (edit && !replace) {
        const new_io_list = state.data_flow_nodes_io_details.length > 0 ? state.data_flow_nodes_io_details.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              ...data,
              inputFields: {
                ...item.inputFields,
                fields: data?.inputFields?.fields
              }
            }
          } else {
            return item
          }
        }) : [];
        return {
          ...state,
          data_flow_nodes_io_details: new_io_list
        }
      } else if (!edit && !replace) {
        return {
          ...state,
          data_flow_nodes_io_details: [
            ...state.data_flow_nodes_io_details,
            data
          ]
        }
      } else if (!edit && replace) {
        return {
          ...state,
          data_flow_nodes_io_details: data
        }
      }
    }
    // eslint-disable-next-line no-fallthrough
    case ALTER_DEBUG_DATA: {
      return {
        ...state,
        debug_data: {
          ...state.debug_data,
          ...action.payload
        }
      }
    }
    case ADD_DEBUG_ANIMATIONS: {
      var nodes_debug_arr = [];
      var edges_debug_arr = [];
      const { context } = state.debug_data?.debug_context_data;
      var context_node_list = Object.keys(context);
      state.elements.forEach((item) => {
        if (item.source && item.target) {
          edges_debug_arr.push(item)
        } else {
          nodes_debug_arr.push(item)
        }
      });
      nodes_debug_arr.forEach((node) => {
        if (context_node_list.includes(node.data.name) || context_node_list.includes(`${node.data.name}_start`)) {
          if (node.type === "start_node") {
            const { name, startnodeid } = node.data;
            const node_output = context[`${name}_start`];
            if (node_output.error === null) {
              edges_debug_arr = edges_debug_arr.map((edge) => {
                if (edge.source === startnodeid) {
                  return {
                    ...edge,
                    animated: true,
                    style: { stroke: "green" }
                  }
                } else {
                  return edge
                }
              })
            }
          } else if (node.type === "call_node") {
            const { name, nodeid } = node.data;
            const node_output = context[name];
            if (node_output.error === null) {
              edges_debug_arr = edges_debug_arr.map((edge) => {
                if (edge.source === nodeid) {
                  return {
                    ...edge,
                    animated: true,
                    style: { stroke: "green" }
                  }
                } else {
                  return edge
                }
              })
            }
          } else if (node.type === "switch_node") {
            const { name, nodeid } = node.data;
            const node_output = context[name];
            const { result, error } = node_output;
            if (error === null) {
              edges_debug_arr = edges_debug_arr.map((edge) => {
                if (edge.label === result?.conditionName) {
                  return {
                    ...edge,
                    animated: true,
                    style: { stroke: "green" }
                  }
                } else {
                  return edge
                }
              })
            }
          } else if (node.type === "join_node") {
            const { name, nodeid } = node.data;
            const node_output = context[name];
            const { error } = node_output;
            if (error === null) {
              edges_debug_arr = edges_debug_arr.map((edge) => {
                if (edge.source === nodeid) {
                  return {
                    ...edge,
                    animated: true,
                    style: { stroke: "green" }
                  }
                } else {
                  return edge
                }
              })
            }
          }
        }
      })
      const new_els = [...nodes_debug_arr, ...edges_debug_arr]
      return {
        ...state,
        elements: new_els
      }
    }
    case REMOVE_ALL_DEBUG_POINTS: {
      return {
        ...state,
        debug_points: []
      }
    }
    default:
      return state;
  }
}
