import React, { useState, useRef, useEffect } from "react";
import {
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Tooltip
} from "@material-ui/core";
import {
    Grid,
    DragDropProvider,
    VirtualTable,
    TableHeaderRow,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    Toolbar,
    ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SortingState,
    SelectionState,
    IntegratedSorting,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"
import devExpressStyles from '../styles/DevExpressTableStyles';
import { deleteTemplateByID_ } from "../../../utils/orchestration/orchestrationUtils"
import { getApplicationDataByID, removeMicroserviceFromApplication_ } from "../../../utils/orchestration/applicationUtils"
import { deleteTemplateByID, deleteMicroserviceFromApplication } from "../../../redux/actions/applicationAction"
import { getColumnWidth, getInsideTableHeight, getWindowDimensions } from "../../../utils/common"
import GetAppIcon from '@material-ui/icons/GetApp';
import { BASE_URL } from "../../../services/helper/config";

const ServiceListAction = ({ ...restProps }) => {
    const dispatch = useDispatch();
    const { application_details } = useSelector(state => state.applicationReducer)
    const styles = devExpressStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { mid, vid } = restProps.row;

    const handleDeleteMicroservice = async () => {
        const { _msg, _status } = await removeMicroserviceFromApplication_(mid, application_details._id);
        if (_status === 200) {
            enqueueSnackbar(_msg, { variant: "success" });
            dispatch(deleteMicroserviceFromApplication(mid))
        } else {
            enqueueSnackbar(_msg, { variant: "error" })
        }
    }

    return (
        <VirtualTable.Cell {...restProps}>
            <div className={styles.actionIcons}>
                <Tooltip title="Remove Micorservice">
                    <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        className={styles.actionIcon}
                        onClick={() => handleDeleteMicroservice()}
                    >
                        <img src="/images/deleteLine.svg" alt="icon" height={18} width={16} />
                    </IconButton>
                </Tooltip>
            </div>
        </VirtualTable.Cell>
    )
}

const ProcessActionCell = ({ ...restProps }) => {
    const history = useHistory();
    const styles = devExpressStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { applicationid, templateid, templatename, isEditMode } = restProps.row;
    const [anchorEl, setAnchorEl] = useState(null);
    const search = useLocation().search;
    const appBundleId = new URLSearchParams(search).get("id");

    const handleDeleteTemplate = async (templateid) => {
        if (isEditMode) {
            const { _msg, _status } = await deleteTemplateByID_(templateid);
            if (_status === 200) {
                enqueueSnackbar(_msg, { variant: "success" });
                dispatch(deleteTemplateByID(templateid))
            } else {
                enqueueSnackbar(_msg, { variant: "error" })
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [runTimeEnvId, setRunTimeEnvId] = useState();
    const [stateDisable, setStateDisable] = useState();
    const userData = useSelector((state) => state.authReducer);

    useEffect(() => {
        const fetchData = async () => {
            const { _msg, _status, data } = await getApplicationDataByID(
                restProps?.row?.applicationid
            );
            if (_status === 200) {
                if (data) {
                    setStateDisable(data?.state);
                    setRunTimeEnvId(data?.runtime_env);
                }
            }
        };
        fetchData()
    }, [])

    const handleTodayLogsDownload = async () => {
        setAnchorEl(null);
        var timestamp = Math.round(new Date().getTime() / 1000);
        let response = await fetch(
            `${BASE_URL}/api/container/environments/${runTimeEnvId}/dockercontainers/orchestration-engine-${restProps?.row?.applicationid}/logs?timestamp=${timestamp}`,
            {
                headers: {
                    organization: "60ba3575e1bc3c06e220ab5a",
                    token: userData?.accesstoken,
                },
            }
        );
        if (response.status === 200) {
            let blob = await response.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "logs";
            document.body.appendChild(a);
            a.click();
            a.remove();
            enqueueSnackbar("Container Logs Downloaded Successfully", {
                variant: "success",
            });
        } else {
            enqueueSnackbar("Unable to download, please try after sometime...", {
                variant: "error",
            });
        }
    };

    const handleAllLogsDownload = async () => {
        setAnchorEl(null);
        let response = await fetch(
            `${BASE_URL}/api/container/environments/${runTimeEnvId}/dockercontainers/orchestration-engine-${restProps?.row?.applicationid}/logs`,
            {
                headers: {
                    organization: "60ba3575e1bc3c06e220ab5a",
                    token: userData?.accesstoken,
                },
            }
        );
        if (response.status === 200) {
            let blob = await response.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "AllLogs";
            document.body.appendChild(a);
            a.click();
            a.remove();
            enqueueSnackbar("Container Logs Downloaded Successfully", {
                variant: "success",
            });
        } else {
            enqueueSnackbar("Unable to download, please try after sometime...", {
                variant: "error",
            });
        }
    };

    return (
        <VirtualTable.Cell {...restProps}>
            <div className={styles.actionIcons}
                style={{ marginLeft: "1rem" }}>
                <Tooltip title="Load Aggregated APIs">
                    <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        className={styles.actionIcon}
                        disabled={!isEditMode}
                        onClick={() => {
                            // if (isEditMode) {

                            history.push({
                                pathname: `/application/edit/orchestration/${applicationid}`,
                                state: {
                                    source: "load_process",
                                    applicationid: applicationid,
                                    templateid: templateid,
                                    templatename: templatename
                                }
                            })
                            // }
                        }}
                    >
                        {isEditMode && <img src="/img/gotoCanvas-ui-app-list.svg" alt="icon" />}
                        {!isEditMode && <img src="/img/gotoCanvas-ui-app-list-disabled.svg" alt="icon" />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete  Aggregated APIs">
                    <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        className={styles.actionIcon}
                        disabled={!isEditMode}
                        onClick={() => handleDeleteTemplate(templateid)}
                    >
                        {isEditMode && <img src="/images/deleteLine.svg" alt="card" />}
                        {!isEditMode && <img src="/images/deleteLine-disabled.svg" alt="card" />}
                    </IconButton>
                </Tooltip>
                <Tooltip title='Download Container Logs' arrow>
                    <IconButton
                        color='inherit'
                        aria-label='download'
                        edge='start'
                        className={styles.actionIcon}
                        onClick={handleClick}
                        disabled={stateDisable !== "running" && true}
                    >
                        <GetAppIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                        <List dense={true}>
                            <ListItem
                                button
                                onClick={handleTodayLogsDownload}
                            >
                                <ListItemText primary="Today" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={handleAllLogsDownload}
                            >
                                <ListItemText primary="All" />
                            </ListItem>
                        </List>
                    </div>
                </Popover>
            </div>
        </VirtualTable.Cell>
    );
};

const Cell = (props) => {
    const history = useHistory();
    const { column } = props;
    if (column.for === "process_list") {
        if (column.name === "action") {
            return <ProcessActionCell {...props} />
        } else {
            return (
                <VirtualTable.Cell {...props} />
            );
        }
    } else {
        return <VirtualTable.Cell {...props} />
    }
}

const NoDataFound = (props) => {
    return (
        <div style={{ position: 'absolute', textAlign: 'center', top: 'calc(50% - 4rem)', left: 'calc(50% - 5rem)', maxWidth: '11rem' }}>
            <img src="/images/notFound.svg" alt="notfound" />
            <h3 style={{ textAlign: 'center' }}>{props.msg}</h3>
        </div>
    )
}

const DefaultPagination = (props) => {
    const [selection, setSelection] = useState([]);
    const [defaultHiddenColumnNames] = useState(['id']);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

    const devExpressTable = useRef(null)

    var chooseIcon = document.createElement("img");
    chooseIcon.src = "/images/columnChoose.svg"
    chooseIcon.alt = "Column Choose"


    useEffect(() => {
        const width = getColumnWidth(props.defaultColumnWidths, windowDimensions)
        setColumnWidths(width)
        const iconElement =
            devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
                .childNodes[0].childNodes[0];
        iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    }, [props]);

    const styles = devExpressStyles();

    return (
        <Paper className={styles.paper}>
            <div className={`${styles.myDevTable} ${props.height ? styles.listScreenTable : ''}`} ref={devExpressTable}>
                <Grid rows={props.rows} columns={props.columns}>
                    {/* <PagingState
                        defaultCurrentPage={0}
                        defaultPageSize={pageSize}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                    />
                    <IntegratedPaging /> */}
                    <SelectionState
                        selection={selection}
                        onSelectionChange={setSelection}
                    />
                    <IntegratedSelection />
                    <SortingState
                        sorting={props.sort}
                        onSortingChange={props.setSort}
                        columnExtensions={props.tableColumnExtensions}
                    />
                    <IntegratedSorting />
                    <DragDropProvider />
                    <VirtualTable
                        height={
                            props.isCustomHeight ? windowDimensions.height - 260 : getInsideTableHeight(props.rows)
                        }
                        columnExtensions={props.tableColumnExtensions}
                        cellComponent={Cell}
                        noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
                    />

                    {/* <VirtualTable
                        height={props.height ? props.height : 370}
                        columnExtensions={props.tableColumnExtensions}
                        cellComponent={Cell}
                        noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
                    /> */}

                    <TableColumnResizing columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableColumnReordering defaultOrder={props.columnOrder} />
                    <TableHeaderRow showSortingControls />
                    <TableColumnVisibility
                        defaultHiddenColumnNames={defaultHiddenColumnNames}
                        columnExtensions={[
                            { columnName: "action", togglingEnabled: false },
                        ]}
                    />
                    <Toolbar />
                    <ColumnChooser />
                    {/* <TableSelection showSelectAll /> */}
                    {/* <PagingPanel pageSizes={pageSizes} className="myPagingPanel" /> */}
                </Grid>
                {props.loading && <CircularProgress className={styles.loader} />}
                {/* <Typography varient="body2" className={styles.selectedRowsText}>
              Selected Rows: {selection.length}
            </Typography> */}
            </div>
        </Paper>
    );

};

export default DefaultPagination;