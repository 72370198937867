import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  arrayToJson,
  handleFormValidationPro,
  initError,
} from "../../../utils/common";
import { addVersion } from "../../../utils/project/microserviceUtils";
import { useSnackbar } from "notistack";
import { ArrowDropDownRounded } from "@material-ui/icons";
import Loader from "../../../components/common/stuff/Loader";
import {
  getVersionList,
  setSelectedVersionId,
} from "../../../redux/actions/serviceAction";
import { connect, useSelector } from "react-redux";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import CustomFieldGroupForAiTaskVersionCreate from "../../../components/common/entitySchema/aiTaskVersion/CustomFieldGroupForAiTaskVersionCreate";
import {
  getDataModelsListForAiTask,
  setOnlyVersionId,
  setSelectedAITaskVersionId,
} from "../../../redux/actions/aiTaskActions";
import { useLocation } from "react-router-dom";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
import UploadSourceCodeInVersion from "../../../components/common/modals/UploadSourceCodeInVersion";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const AddAiTaskVersion = (props) => {
  const {
    tabbedView,
    serviceType,
    handleCloseVersion,
    setCreateVersion,
    selectedVersionId,
  } = props;
  const isTabbedApproach = useSelector(
    (state) => state.userDetails.userInfo.tabbedView
  );

  // const mid = tabbedView ? props.serviceId ? props.serviceId  : props.location?.state?.serviceId : props.location?.state?.serviceId;
  const sType = isTabbedApproach
    ? serviceType
    : props.location?.state?.serviceType;
  const [serviceId] = useState(
    isTabbedApproach ? props.serviceId : props.location?.state?.serviceId
  );
  // const [serviceId] = useState(props.match.params.aiTaskId);
  const [envs, setEnvs] = useState([{ id: uuidv4(), key: "", value: "" }]);
  const styles = manageStyles();
  const style = listScreenStyles();
  const histroy = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [versionEntitySchema, setVersionEntitySchema] = useState([]);
  const search = useLocation().search;

  // const serviceName = new URLSearchParams(search).get("name");
  const serviceName = props.serviceName;
  const [activeTab, setActiveTab] = useState(0);
  const [openUploadSourceCodeModal, setOpenUploadSourceCodeModal] =
    useState(false);
  const [repositoryData, setRepositoryData] = useState({});
  const [versionId, setVersionId] = useState();
  const [isFromAITask, setIsFromAITask] = useState(true);

  // const serviceIdSplit = serviceId.split("?");

  const [versionData, setVersionData] = useState({
    versionTag: "",
    description: "",
    port: "",
    microserviceType: sType,
    environmentVariables: {},
    buildcommands: [""],
    runcommands: [""],
    datamodels: [],
    apirequired: "",
  });
  const [error, setError] = useState({
    versionTag: { isError: false },
    port: { isError: false },
  });

  useEffect(() => {
    props.getDataModelsListForAiTask();
    const fetchVersionEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITaskVersion",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) => {
          if (versionData?.microserviceType === "External") {
            if (
              section.name !== "AITask_Version_Environmentvariables_Section" &&
              section.name != "AITask_Version_SourceCode_Section" &&
              section.name != "AITask_Version_Docker_Section" &&
              section.name != "AITask_Version_Runtime_Section"
            ) {
              section?.fieldgroups?.map((fieldgroup) => {
                if (fieldgroup?.fields?.length !== 0) {
                  entity.push(section);
                }
              });
            }
          } else {
            section?.fieldgroups?.map((fieldgroup) => {
              if (fieldgroup?.fields?.length !== 0) {
                entity.push(section);
              }
            });
          }
        });

        setVersionEntitySchema(entity);
        setIsLoader(false);
        const verData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                verData[`${field.datakey}_unit`] = "";
                verData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (verData[field.datakey] = "");
              }
            });
          });
        });
        if (versionData?.microserviceType === "External") {
          delete verData["port"];
          delete verData["apirequired"];
        }

        setVersionData((prevData) => ({
          ...prevData,
          ...verData,
          apirequired: "True",
        }));

        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["dockerCommands"];
        delete errData["envs"];

        if (versionData?.microserviceType === "External") {
          delete errData["port"];
          delete errData["apirequired"];
        }

        setError(errData);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchVersionEntitySchema();
  }, [versionData?.microserviceType]);

  const handleChange = (event) => {
    let value = event.target.value;

    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setVersionData({
      ...versionData,
      [event.target.name]: value,
    });
  };

  const deleteBuildCommand = (index) => {
    versionData.buildcommands.splice(index, 1);
    setVersionData({
      ...versionData,
      buildcommands: versionData.buildcommands,
    });
  };

  const deleteRunCommand = (index) => {
    versionData.runcommands.splice(index, 1);
    setVersionData({ ...versionData, runcommands: versionData.runcommands });
  };

  const handleSubmit = async () => {
    if (versionData?.microserviceType === "External") {
      delete versionData["apirequired"];
      // delete versionData['buildcommands']
      delete versionData["docker"];
      delete versionData["environmentVariables"];
      delete versionData["port"];
      // delete versionData['runcommands']
    }
    const { formIsValid, errors } = handleFormValidationPro(versionData, error);
    if (formIsValid) {
      let currentVersionData = versionData;

      if (versionData?.apirequired === "True") {
        currentVersionData["apirequired"] = true;
      } else {
        currentVersionData["apirequired"] = false;
      }

      if (!versionData.datamodels) {
        delete currentVersionData["datamodels"];
      }

      const newVersionData = {
        ...currentVersionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: currentVersionData.buildcommands[0]
            ? currentVersionData.buildcommands
            : [],
          runcommands: currentVersionData.runcommands[0]
            ? currentVersionData.runcommands
            : [],
        },
      };

      setIsLoader(true);
      const { _msg, _status, data } = await addVersion(
        serviceId,
        newVersionData
      );
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);

        props.getVersionList();
        if (isTabbedApproach) {
          setCreateVersion(false);

          histroy.push(`/aitask/edit/${serviceId}`);
        } else {
          histroy.push(`/aitask/edit/${serviceId}`);
        }
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        versionEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);
      setError(errors);
    }
  };

  const handleAddAItaskVersion = async () => {
    if (versionData?.microserviceType === "External") {
      delete versionData["apirequired"];
      // delete versionData['buildcommands']
      delete versionData["docker"];
      delete versionData["environmentVariables"];
      delete versionData["port"];
      // delete versionData['runcommands']
    }
    const { formIsValid, errors } = handleFormValidationPro(versionData, error);
    if (formIsValid) {
      let currentVersionData = versionData;

      let versionIdValue;
      if (versionData?.apirequired === "True") {
        currentVersionData["apirequired"] = true;
      } else {
        currentVersionData["apirequired"] = false;
      }

      if (!versionData.datamodels) {
        delete currentVersionData["datamodels"];
      }

      const newVersionData = {
        ...currentVersionData,
        environmentVariables: arrayToJson(envs),
        docker: {
          buildcommands: currentVersionData.buildcommands[0]
            ? currentVersionData.buildcommands
            : [],
          runcommands: currentVersionData.runcommands[0]
            ? currentVersionData.runcommands
            : [],
        },
      };

      setIsLoader(true);
      const { _msg, _status, data } = await addVersion(
        serviceId,
        newVersionData
      );
      if (_status === 201) {
        const response = await data;
        dispatch(setOnlyVersionId(response?._id));
        setVersionId(response?._id);
        setOpenUploadSourceCodeModal(true);
        enqueueSnackbar(_msg, { variant: "success" });
        setIsLoader(false);

        props.getVersionList();

        // if(isTabbedApproach){

        //   setCreateVersion(false);
        //   histroy.push(`/aitask/edit/${serviceId}`);
        // }else{


      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      const { tempEntity, errorInTab } = TabsCommon(
        versionEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);
      setVersionEntitySchema(tempEntity);
      enqueueSnackbar("Please fill the mandatory fields", { variant: "error" });
      setError(errors);
    }
  };
  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };

  const handleCancel = () => {

    if (!isTabbedApproach) {
      histroy.push(`/aitask/edit/${serviceId}`);
    } else {
      setCreateVersion(false);
      histroy.push(`/aitask/edit/${serviceId}`);
    }
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      {isTabbedApproach ? (
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ marginTop: isTabbedApproach ? "-200px" : "" }}
            >
              <h2 className={styles.heading}>Create AITask Version</h2>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >

                <TabbedNavigation
                  isCreateMode={true}
                  handleSave={handleSubmit}
                  handleCancel={handleCancel}
                />
              </div>
              {/* </Grid> */}
            </Grid>

            {/* </Grid> */}
            {/* </Grid> */}
            <div style={{ borderTop: "2px solid #afafaf" }}>
              <SectionTabs
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
                entity={versionEntitySchema}
              />

              <CustomFieldGroupForAiTaskVersionCreate
                serviceName={serviceName}
                section={versionEntitySchema[activeTab]}
                repositoryData={repositoryData}
                setRepositoryData={setRepositoryData}
                setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
                openUploadSourceCodeModal={openUploadSourceCodeModal}
                versionData={versionData}
                setCreateVersion={setCreateVersion}
                isFromAITask={isFromAITask}
                serviceId={serviceId}
                versionId={selectedVersionId}
                error={error}
                envs={envs}
                setEnvs={setEnvs}
                setError={setError}
                deleteBuildCommand={deleteBuildCommand}
                deleteRunCommand={deleteRunCommand}
                setVersionData={setVersionData}
                tabbedView={isTabbedApproach}
                handleChange={handleChange}
                handleAddAItaskVersion={handleAddAItaskVersion}
              />
              {/* <UploadSourceCodeInVersion
                         key ={versionId}
            openUploadSourceCodeModal={openUploadSourceCodeModal}
            setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
            onCloseClick={() => setOpenUploadSourceCodeModal(false)}
            handleSubmit={(data) => setRepositoryData(data)}
            versionID={versionId}
            serviceId={serviceId}
            isUpload={repositoryData?._id ? true : false}
            repoId={repositoryData?._id ? repositoryData._id : ""}
          /> */}
            </div>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                {
                  title: "AI Task",
                  path: "/aitask-workbench",
                },
                {
                  title: props.location?.state?.serviceName,
                  path: `/aitask/edit/${serviceId}`,
                },
                { title: "Create AI Task Version" },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>Create AI Task Version</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <AccordionNavigation
                  handleSave={handleSubmit}
                  disabled={false}
                  isCreateMode={true}
                  isEditMode={false}
                  handleCancel={() => histroy.goBack()}
                />
              </div>
            </Grid>
          </div>
          <div className={styles.wrapper}>
            {versionEntitySchema &&
              versionEntitySchema.map((section) => {
                if (section.name === "AITask_Version_Header_Section") {
                  return (
                    <div key={section._id} style={{ padding: "0px 5px 0" }}>
                      <CustomFieldGroupForAiTaskVersionCreate
                        serviceName={serviceName}
                        section={section}
                        setRepositoryData={setRepositoryData}
                        isFromAITask={isFromAITask}
                        setOpenUploadSourceCodeModal={
                          setOpenUploadSourceCodeModal
                        }
                        openUploadSourceCodeModal={openUploadSourceCodeModal}
                        setCreateVersion={setCreateVersion}
                        serviceId={serviceId}
                        versionData={versionData}
                        error={error}
                        versionId={selectedVersionId}
                        setError={setError}
                        setVersionData={setVersionData}
                        tabbedView={isTabbedApproach}
                        handleChange={handleChange}
                      />
                    </div>
                  );
                } else if (versionData?.microserviceType === "External") {
                  return <></>;
                } else {
                  return (
                    <Accordion
                      className={styles.fieldPanel}
                      defaultExpanded={true}
                      key={section._id}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownRounded
                            className={styles.accordianIcon}
                          />
                        }
                      >
                        <Typography className={styles.sectionHeading}>
                          {section["section Label"]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomFieldGroupForAiTaskVersionCreate
                          serviceName={serviceName}
                          section={section}
                          setOpenUploadSourceCodeModal={
                            setOpenUploadSourceCodeModal
                          }
                          setRepositoryData={setRepositoryData}
                          openUploadSourceCodeModal={openUploadSourceCodeModal}
                          setCreateVersion={setCreateVersion}
                          handleAddAItaskVersion={handleAddAItaskVersion}
                          isFromAITask={isFromAITask}
                          versionData={versionData}
                          versionId={selectedVersionId}
                          serviceId={serviceId}
                          error={error}
                          envs={envs}
                          setEnvs={setEnvs}
                          setError={setError}
                          deleteBuildCommand={deleteBuildCommand}
                          deleteRunCommand={deleteRunCommand}
                          setVersionData={setVersionData}
                          handleChange={handleChange}
                          tabbedView={isTabbedApproach}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            {/* <UploadSourceCodeInVersion
            key ={versionId}
            openUploadSourceCodeModal={openUploadSourceCodeModal}
            handleSubmit={(data) => setRepositoryData(data)}
            setOpenUploadSourceCodeModal={setOpenUploadSourceCodeModal}
            onCloseClick={() => setOpenUploadSourceCodeModal(false)}
            versionID={versionId}
            serviceId={serviceId}
            isUpload={repositoryData?._id ? true : false}
            repoId={repositoryData?._id ? repositoryData._id : ""}
          /> */}
          </div>
        </form>
      )}
    </main>
  );
};

const mapDispatchToProps = {
  getVersionList,
  getDataModelsListForAiTask,
};

const mapStateToProps = (state) => {
  const { selectedVersionId } = state.aiTaskReducer;

  return {
    selectedVersionId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAiTaskVersion);
