import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { AppButton } from '../../../components/common/Button';
import listStyles from '../../CommonStyles/listScreenStyles';
import DocumentTypeCard from '../../../components/DocumentsList/DocumentTypeCard';
import {
  handleFormValidationPro,
  handleFormValidation,
} from '../../../utils/common';
import AddIcon from '@material-ui/icons/Add';
import {
  addDocumentType,
  deleteDocumentType,
  editDocumentType,
  fetchDocumentTypeListData,
} from '../../../utils/project/documentUtils';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../components/common/hooks/useCheckPermission';

const useStyles = makeStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    zIndex: 999,
  },
  disableTextBox: {
    background: 'grey',
  },
});

const CreateDocumentsCard = (props) => {
  const styles1 = listStyles();
  const styles = useStyles();
  const [isCreateDocumentsClicked, setIsCreateDocumentsClicked] =
    useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [handleDeleteClick, setHandleDeleteClick] = useState('');
  const [docTypeData, setDocTypeData] = useState({
    name: '',
    description: '',
  });

  const [error, setError] = useState({
    name: false,
    description: false,
  });
  const handleChange = (event) => {
   
    const value = event.target.value;
    setError({
      ...error,
      [event.target.name]: false,
    });
    setDocTypeData({
      ...docTypeData,
      [event.target.name]: value,
    });
  };

  const { checkScreenPermissionForDocType, checkCompPermission } =
    useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenCreateDocumentButton = () => {
    setDocTypeData({ name: '', description: '' });
    setIsCreateDocumentsClicked(true);
  };
  const handleCloseCreateDocumentButton = () => {
    setIsCreateDocumentsClicked(false);
    setError({
      name: false,
      description: false,
    });
  };

  useEffect(() => {
    fetchAllDocumentTypes();
  }, []);

  useEffect(async () => {
    if (handleDeleteClick) {
      const { _msg, _status, data } = await deleteDocumentType(
        handleDeleteClick
      );
      if (_status === 200) {
       
        fetchAllDocumentTypes();
        enqueueSnackbar(_msg, { variant: 'success' });
      } else {
       
        enqueueSnackbar(
          _msg
            ? _msg
            : 'Unable to process your request, please try after sometime',
          { variant: 'error' }
        );
      }
    }
  }, [handleDeleteClick]);

  const fetchAllDocumentTypes = async () => {
    const { _msg, _status, data } = await fetchDocumentTypeListData();
    if (_status === 200) {
     
      setDocumentTypes(data ? data : []);
    } else {
     
      enqueueSnackbar(_msg ? _msg : 'Something went wrong', {
        variant: 'error',
      });
    }
  };

  const handleSubmitCreate = async () => {
   
    const { formIsValid, errors } = handleFormValidation(docTypeData, error);

    if (formIsValid) {
     
      const { _msg, _status, data } = await addDocumentType(docTypeData);
      if (_status === 201) {
       
        fetchAllDocumentTypes();
        enqueueSnackbar(_msg, { variant: 'success' });
        setDocTypeData({ name: '', description: '' });
        setIsCreateDocumentsClicked(false);
      } else {
        enqueueSnackbar(data&&data.length!==0?data:_msg, {
          variant: 'error',
        });
      }
    } else {
     
      setError(errors);
    }
  };

  const handleSubmitUpdate = async () => {
    const { formIsValid, errors } = handleFormValidation(docTypeData, error);
    if (formIsValid) {
      const { _msg, _status, data } = await editDocumentType(
        docTypeData._id,
        docTypeData
      );
      if (_status === 201) {
       
        fetchAllDocumentTypes();
        enqueueSnackbar(_msg, { variant: 'success' });
        setDocTypeData({ name: '', description: '' });
        setIsCreateDocumentsClicked(false);
      } else {
        enqueueSnackbar(data&&data.length!==0?data:_msg, {
          variant: 'error',
        });
      }
    } else {
      setError(errors);
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isCreateDocumentsClicked ? (
          <div style={{ display: 'contents' }}>
            {isCreateDocumentsClicked && !docTypeData._id && (
              <h3 className={styles1.labelStyle_active}>
                <img
                  src="/images/backButton.svg"
                  className={styles1.createDocBackImg}
                  alt="card"
                  onClick={() =>
                    !isCreateDocumentsClicked
                      ? props.setOpen(false)
                      : handleCloseCreateDocumentButton()
                  }
                />
                Create Document Type
              </h3>
            )}
            {isCreateDocumentsClicked && docTypeData._id && (
              <h3 className={styles1.labelStyle_active}>
                <img
                  src="/images/backButton.svg"
                  className={styles1.createDocBackImg}
                  // disabled={!checkCompPermission('DocumentType', ['Create'])}
                  alt="card"
                  onClick={() =>
                    !isCreateDocumentsClicked
                      ? props.setOpen(false)
                      : handleCloseCreateDocumentButton()
                  }
                />
                Edit {docTypeData.name} Type
              </h3>
            )}
            <div className={styles1.createDocumentsBtnGroup}>
              <AppButton
                buttonName="Cancel"
                variant="outlined"
                disabled={false}
                className={'btnsmall'}
                onClick={() =>
                  !isCreateDocumentsClicked
                    ? props.setOpen(false)
                    : handleCloseCreateDocumentButton()
                }
              />

              {isCreateDocumentsClicked && !docTypeData._id && (
                <AppButton
                  buttonName="Create"
                  variant="contained"
                  disabled={!checkCompPermission('DocumentType', ['Create'])}
                  className="btnsmall"
                  onClick={handleSubmitCreate}
                />
              )}

              {isCreateDocumentsClicked && docTypeData._id && (
                <AppButton
                  buttonName="Update"
                  variant="contained"
                  disabled={false}
                  className="btnsmall"
                  onClick={handleSubmitUpdate}
                />
              )}
            </div>
          </div>
        ) : (
          <h3 className={styles1.labelStyle_inactive}>Documents Type List</h3>
        )}
        {!isCreateDocumentsClicked && (
          <div style={{ display: 'flex' }}>
            <AppButton
              buttonName="Close"
              variant="contained"
              disabled={false}
              className="btnsmall"
              onClick={() =>
                !isCreateDocumentsClicked
                  ? props.setOpen(false)
                  : handleCloseCreateDocumentButton()
              }
            />
            <AppButton
              buttonName="create"
              variant="contained"
              disabled={!checkCompPermission('DocumentType', ['Create'])}
              startIcon={<AddIcon />}
              className="btnsmall"
              onClick={handleOpenCreateDocumentButton}
            />
          </div>
        )}
      </div>

      {!isCreateDocumentsClicked && (
        <div
          style={{
            marginTop: '1rem',
            height: '27rem',
            overflow: 'auto',
            padding: 20,
          }}
        >
          <Grid container spacing={3}>
            {documentTypes.map((item) => (
              <DocumentTypeCard
                data={item}
                setDocTypeData={setDocTypeData}
                setIsCreateDocumentsClicked={setIsCreateDocumentsClicked}
                setHandleDeleteClick={setHandleDeleteClick}
                key={item._id}
              />
            ))}
          </Grid>
        </div>
      )}

      {isCreateDocumentsClicked && (
        <Grid
          container
          spacing={3}
          style={{ marginTop: '1rem', height: '27rem' }}
        >
          <Grid item xs={12} sm={12}>
            <Grid container spacing={4}>
              <Grid item xs={4} sm={4}>
                <label
                  htmlFor=""
                  className={styles1.headings}
                  style={{ marginLeft: 20 }}
                >
                  Title <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={docTypeData.name}
                  disabled={docTypeData._id ? true : false}
                  className={docTypeData._id ? styles.disableTextBox : null}
                  className={`${styles1.documentInput} ${
                    error.name && styles1.error
                  }`}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {error.name && (
                  <p
                    className={styles1.errorMsg}
                    style={{ paddingLeft: '22px' }}
                  >
                    {'Title is required'}
                  </p>
                )}
              </Grid>
              <Grid item xs={8} sm={8}>
                <label
                  htmlFor="projectDescription"
                  className={styles1.headings}
                >
                  Description <span style={{ color: 'red' }}>*</span>
                </label>
                <div>
                  <textarea
                    style={{ padding: '15px' }}
                    name="description"
                    placeholder="Input length must be less than or equal to 300 "
                    value={docTypeData.description}
                    className={`${styles1.textarea} ${
                      error.description && styles1.error
                    }`}
                    onChange={handleChange}
                    rows="8"
                  ></textarea>
                  {error.description && (
                    <p className={styles1.errorMsg}>
                      {'Description is required'}
                    </p>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CreateDocumentsCard;
