import { Grid, TextField, Backdrop, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { AppButton } from '../../../components/common/Button'
import { useSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import CustomChips from '../../../components/common/Chips'
import { getOntologyFields } from '../../../redux/actions/ontologyAction'
import { fetchFieldGroupByID_, editFieldGroupByID_ } from '../../../utils/ontology/ontologyUtils'
// import DraggableTree from "../../../components/Ontology/DraggableTree";
import SectionEditTree from '../../../components/Ontology/SectionEditTree'
import useCheckPermission from '../../../components/common/hooks/useCheckPermission'

function FieldGroupDetailsScreen(props) {
  const [mode, setMode] = useState('details')
  const [fgData, setfgData] = useState({
    field_name: '',
    field_label: '',
    field_description: '',
    predefined: false,
    updatedby: '',
    updatedon: '',
  })
  const [error, setError] = useState({
    field_name: false,
    field_arr: false,
  })
  const [loading, setLoading] = useState(false)
  const [selectedFields, setSelectedFields] = useState({
    children: [],
    title: '',
  })
  const [predefinedFields, setPredefinedFields] = useState([])
  const [allField, setAllFields] = useState([])
  const location = useLocation()
  const styles = manageStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const fieldgroupID = props.match.params.fieldgroupID
  const { checkCompPermission } = useCheckPermission()

  useEffect(() => {
    // const { source } = location.state;
    if (location?.state?.source === 'edit_icon') {
      setMode('edit')
    } else {
      setMode('details')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldgroupID])

  useEffect(() => {
    const fetchFieldGroupDetails = async () => {
      setLoading(true)
      const { _msg, _status, data } = await fetchFieldGroupByID_(fieldgroupID)
      if (_status === 200) {
        setLoading(false)
        const {
          name,
          predefined,
          predefined_fields,
          fields,
          description,
          fieldGroupLabel,
          updatedby,
          updatedon,
        } = data
        setfgData((prevState) => ({
          ...prevState,
          field_name: name,
          predefined: predefined && predefined === 'TRUE' ? true : false,
          field_label: fieldGroupLabel ? fieldGroupLabel : '',
          field_description: description,
          updatedby: updatedby ? updatedby : '',
          updatedon: updatedon ? moment.unix(updatedon).format('DD/MM/YYYY, hh:mm:ss A') : '',
        }))
        setSelectedFields(() => {
          const fieldList =
            fields && fields.length > 0
              ? fields.map((item) => {
                  return {
                    _id: item._id,
                    title: item.name && item.name.length > 28 ? item.name.slice(0, 29) : item.name,
                    name: item.name,
                    predefined: item.predefined && item.predefined === 'TRUE' ? true : false,
                  }
                })
              : []
          return {
            _id: fieldgroupID,
            title: name && name.length > 28 ? name.slice(0, 29) : name,
            children: fieldList,
            expanded: true,
          }
        })
        if (predefined && predefined === 'TRUE') {
          setPredefinedFields(predefined_fields)
        }
      } else {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'error' })
      }
    }
    fetchFieldGroupDetails()
    props.getOntologyFields()
    setAllFields(props.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = (key, value) => {
    setfgData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (Object.keys(error).includes(key)) {
      setError((prevState) => ({
        ...prevState,
        [key]: false,
      }))
    }
  }

  const handleFieldSelected = (e, input) => {
    if (input) {
      setError((prevState) => ({
        ...prevState,
        field_arr: false,
      }))
      var tempFields =
        selectedFields.children.length > 0
          ? selectedFields.children.filter((item) => item._id === input._id)
          : []
      if (tempFields.length === 0) {
        setSelectedFields((prevState) => ({
          ...prevState,
          children: [
            ...prevState.children,
            {
              _id: input._id,
              name: input.name,
              title: input.name && input.name.length > 28 ? input.name.slice(0, 29) : input.name,
              predefined: input.predefined && input.predefined === 'TRUE' ? true : false,
            },
          ],
        }))
      }
    }
  }

  const handleChipDelete = (data) => {
    if (mode === 'edit') {
      if (predefinedFields.includes(data._id)) {
        enqueueSnackbar('Predefined fields cannot be removed from this field group', {
          variant: 'warning',
        })
      } else {
        setSelectedFields((prevState) => {
          var tempFields =
            prevState.children.length > 0 &&
            prevState.children.filter((item) => item._id !== data._id)
          return {
            ...prevState,
            children: tempFields,
          }
        })
      }
    }
  }

  const handleTreeChange = (treedata) => {
    setSelectedFields(treedata[0])
  }

  const handleSubmit = async () => {
    var errorFlag = false
    var tempError = {}
    for (var obj in fgData) {
      if (fgData[obj] === '') {
        if (Object.keys(error).includes(obj)) {
          errorFlag = true
          tempError[obj] = true
        }
      }
    }
    if (selectedFields.children.length === 0) {
      errorFlag = true
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        field_arr: true,
      }))
    } else {
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        field_arr: false,
      }))
    }
    if (!errorFlag) {
      setLoading(true)
      var req_data = {}
      if (fgData.predefined) {
        req_data = {
          fieldGroupLabel: fgData.field_label,
          fields:
            selectedFields.children.length > 0
              ? selectedFields.children.map((item) => item._id)
              : [],
        }
      } else {
        req_data = {
          name: fgData.field_name,
          fieldGroupLabel: fgData.field_label,
          description: fgData.field_description,
          fields:
            selectedFields.children.length > 0
              ? selectedFields.children.map((item) => item._id)
              : [],
        }
      }

      const { _msg, _status } = await editFieldGroupByID_(fieldgroupID, req_data)
      if (_status === 201) {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'success' })
        setMode('details')
        // history.push("/ontology");
      } else {
        setLoading(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }
  }

  return (
    <main className={styles.main}>
      <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: 'Ontology', path: '/ontology' },
              { title: 'Field Group', path: '/ontology' },
              { title: `${fgData.field_name}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>{fgData.field_name}</h2>
            {mode === 'details' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  // startIcon={<img src="/images/edit.svg" alt="card" />}
                  disabled={!checkCompPermission('OntologyFieldGroup', ['Edit'])}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={() => setMode('edit')}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
            {mode === 'edit' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppButton
                  color="primary"
                  buttonName="Save"
                  variant="contained"
                  disabled={false}
                  style={{ margin: 'unset' }}
                  className="btnsmall"
                  onClick={handleSubmit}
                />
                <AppButton
                  color="primary"
                  buttonName="Cancel"
                  variant="outlined"
                  style={{ margin: 'unset', marginLeft: '10px' }}
                  className="btnsmall"
                  onClick={() => history.push('/ontology')}
                />
              </div>
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid container spacing={3} style={{ marginTop: '2rem' }}>
            <Grid item xs={9} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Group Name<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`${styles.input} ${
                      (mode === 'details' || fgData.predefined) && styles.editMode
                    }`}
                    style={error.field_name ? { border: '1px solid red' } : {}}
                    value={fgData.field_name}
                    onChange={(e) => handleTextChange('field_name', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details' || fgData.predefined}
                  />
                  {error.field_name && (
                    <text className={styles.errorMsg}>Invalid Field Group Name</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Group Label
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.field_label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${mode === 'details' && styles.editMode}`}
                    value={fgData.field_label}
                    onChange={(e) => handleTextChange('field_label', e.target.value)}
                    autoComplete="off"
                    disabled={mode === 'details'}
                  />
                  {error.field_label && (
                    <text className={styles.errorMsg}>Invalid Field Group </text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Updated By
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${styles.editMode}`}
                    value={fgData.updatedby}
                    onChange={(e) => handleTextChange('updatedby', e.target.value)}
                    autoComplete="off"
                    disabled={true}
                  />
                  {/* {error.label && <text className={styles.errorMsg}>Invalid Updated</text>} */}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Updated On
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.label ? { border: '1px solid red' } : {}}
                    className={`${styles.input} ${styles.editMode}`}
                    value={fgData.updatedon}
                    onChange={(e) => handleTextChange('updatedon', e.target.value)}
                    autoComplete="off"
                    disabled={true}
                  />
                  {/* {error.label && <text className={styles.errorMsg}>Invalid Updated</text>} */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="projectDescription" className={styles.fieldHeading}>
                Field Group Description
              </label>
              <textarea
                name="description"
                value={fgData.field_description}
                style={
                  error.field_description
                    ? { border: '1px solid red', height: '7.8rem' }
                    : { height: '7.8rem' }
                }
                onChange={(e) => handleTextChange('field_description', e.target.value)}
                className={`${styles.textarea} ${
                  (mode === 'details' || fgData.predefined) && styles.editMode
                }`}
                rows="8"
                disabled={mode === 'details' || fgData.predefined}
              ></textarea>
              {/* {error.field_description && (
                <text className={styles.errorMsg}>
                  Invalid Field Group Description
                </text>
              )} */}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ borderTop: '2px solid rgb(175,175,175)', marginTop: '1rem' }}
          >
            <Grid item xs={8} sm={8}>
              <Grid spacing={5} container>
                <Grid item xs={10} sm={10}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Fields<span className={styles.red}>*</span>
                  </label>
                  <Autocomplete
                    style={
                      error.field_arr
                        ? { border: '1px solid red', width: '22rem' }
                        : { maxWidth: '22rem' }
                    }
                    options={props.data}
                    getOptionLabel={(option) => option.name}
                    className={styles.searchInput3}
                    onChange={handleFieldSelected}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" placeholder="Select Fields" />
                    )}
                    disabled={mode === 'details'}
                  />
                  {error.field_arr && <text className={styles.errorMsg}>No Fields Selected</text>}
                  {selectedFields.children.length > 0 && (
                    <CustomChips
                      chipsData={selectedFields.children}
                      handleDelete={handleChipDelete}
                      isEditMode={mode === 'details'}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <label htmlFor="" className={styles.fieldHeading}>
                Hierarchy
              </label>
              {!loading && (
                <SectionEditTree
                  treeData={[selectedFields]}
                  onTreeChange={(treedata) => handleTreeChange(treedata)}
                  disabled={mode === 'details' ? true : false}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </form>
    </main>
  )
}

const mapDispatchToProps = {
  getOntologyFields,
}

const mapStateToProps = (state) => {
  const { fields } = state.ontologyReducer
  const { data, error, loading } = fields
  return {
    data: data ? data : [],
    error,
    loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldGroupDetailsScreen)
