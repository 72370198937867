import React, { useState } from 'react';
import { ButtonGroup as Grid, Typography } from '@material-ui/core';
import ButtonGroupStyles from './styles';
import { useTheme } from '@material-ui/styles';
import FormatBoldOutlinedIcon from '@material-ui/icons/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@material-ui/icons/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@material-ui/icons/FormatUnderlinedOutlined';

const FontStyleGroup = ({ fStyle, handleChange }) => {
  const styles = ButtonGroupStyles();
  const [fontStyle, setFontStyle] = useState(fStyle)
  const theme = useTheme();

  const handleTab = (key) => {
    const tempFontStyle = { ...fontStyle, [key]: !fontStyle?.[key] }
    setFontStyle(tempFontStyle)
    handleChange(tempFontStyle)
  };

  return (
    <Grid container className={styles.mainContainer} style={{ width: 225 }}>
      <Grid
        item
        xs={4}
        className={styles.item}
        style={{
          backgroundColor: fontStyle?.bold
            ? theme.palette.primary.main
            : '#fff',
          color: fontStyle?.bold
            ? '#fff'
            : theme.palette.primary.main,
        }}
        onClick={() => handleTab('bold')}
      >
        <Typography align="center" className={styles.typo}>
          <FormatBoldOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={styles.item}
        onClick={() => handleTab('italic')}
        style={{
          backgroundColor: fontStyle?.italic
            ? theme.palette.primary.main
            : '#fff',
          color: fontStyle?.italic
            ? '#fff'
            : theme.palette.primary.main,
        }}
      >
        <Typography align="center" className={styles.typo}>
          <FormatItalicOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={styles.item}
        onClick={() => handleTab('underline')}
        style={{
          backgroundColor: fontStyle?.underline
            ? theme.palette.primary.main
            : '#fff',
          color: fontStyle?.underline
            ? '#fff'
            : theme.palette.primary.main,
        }}
      >
        <Typography align="center" className={styles.typo}>
          <FormatUnderlinedOutlinedIcon fontSize="small" />
        </Typography>
      </Grid>
    </Grid >
  );
};

export default FontStyleGroup;
