import { ADD_AITASK_LISTS, AITASK_FILTERS, AITASK_SORT, ALTER_FAVOURITE_AI_TASK_BY_ID, ALTER_SUBSCRIBE_AI_TASK_BY_ID, CLOSE_AITASK_API, CLOSE_AITASK_VERSION, DELETE_AITASK_BY_ID, EDIT_AITASK_API, EDIT_AITASK_VERSION, GET_DATA_MODELS_DATA_FOR_AITASK, GET_FUNCTIONS_FOR_AITASK, SET_SELECTED_AITASK_APIID, SET_SELECTED_AITASK_VERSIONID,SET_ONLY_VERSIONID,TABBED_AITASK_ACTIVE_TAB } from '../actionTypes';
import { v4 as uuidv4 } from "uuid";

const initialState = {
    aiTaskList: {
        data: [],
        error: false,
        loading: false
    },
    dataModelsList: {
        data: [],
        error: false,
        loading: false
    },
    functionList:{
        data: [],
        error: false,
        loading: false
    },
    versionsList: {
        data: [],
        error: false,
        loading: false
    },
    apisList: {
        data: [],
        error: false,
        loading: false
    },
    aiTaskFilters: [
        { id: uuidv4(), column: '', operator: '', value: '' }
    ],
    aiTaskSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    serviceHidden: ["name", "createdby", "createdon", "status", "action"],
    versionSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    versionHidden: ["versionTag", "port", "fileName", "imageStatus", "action"],
    apiSort: [
        { columnName: 'createdon', direction: 'desc' }
    ],
    apiHidden: ["name", "type", "createdby", "createdon", "action"],
    selectedVersionId: null,
    editVersion: false,
    detailVersion:false,
    selectedAPIId: null,
    detailAPI:false,
    editAPI:false,
    tabbedAIActiveTab:0
}

const aiTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_AITASK_LISTS: {
            return {
                ...state,
                aiTaskList: {
                    ...state.aiTaskList,
                    ...action.payload,
                    deleteAiTaskStatus: false,
                }
            }
        }
        case TABBED_AITASK_ACTIVE_TAB:{
            return {
                ...state,
                tabbedAIActiveTab:action.payload
            }
        }
        case GET_DATA_MODELS_DATA_FOR_AITASK: {
            state.dataModelsList = action.payload
            return {
                ...state
            }
        }

        case GET_FUNCTIONS_FOR_AITASK: {
            state.functionList = action.payload
            return {
                ...state
            }
        }

        case AITASK_SORT: {
            return {
                ...state,
                aiTaskSort: [
                    ...action.payload
                ]
            }
        }

        case AITASK_FILTERS: {
            return {
                ...state,
                aiTaskFilters: [
                    ...action.payload
                ]
            }
        }
        
        case ALTER_FAVOURITE_AI_TASK_BY_ID: {
            return {
                ...state,
                aiTaskList: {
                    ...state.aiTaskList,
                    data: {
                        ...state.aiTaskList.data,
                        data: state.aiTaskList.data.data.length !== 0 && state.aiTaskList.data.data.map(serv => {
                            if (serv._id === action.payload.AITaskId) {
                                return { ...serv, isFavourite: !serv.isFavourite }
                            } else {
                                return serv
                            }
                        })
                    }
                }
            }
        }


        case ALTER_SUBSCRIBE_AI_TASK_BY_ID: {
            return {
                ...state,
                aiTaskList: {
                    ...state.aiTaskList,
                    data: {
                        ...state.aiTaskList.data,
                        data: state.aiTaskList.data.data.length !== 0 && state.aiTaskList.data.data.map(proj => {
                            if (proj._id === action.payload.AITaskId) {
                                return { ...proj, isSubscribe: !proj.isSubscribe }
                            } else {
                                return proj
                            }
                        })
                    }
                }
            }
        }

        case AITASK_FILTERS: {
            return {
                ...state,
                aiTaskFilters: [
                    ...action.payload
                ]
            }
        }

        case DELETE_AITASK_BY_ID: {
            return {
                ...state,
                aiTaskList: {
                    ...state.aiTaskList,
                    data: {
                        ...state.aiTaskList.data,
                        data: state.aiTaskList.data.data.filter(serv => serv._id !== action.payload.AITaskId)
                    },
                    _totalcount: state.aiTaskList._totalcount - 1,
                    deleteAiTaskStatus: true,
                    
                }
            }
        }

        // case SERVICE_SORT: {
        //     return {
        //         ...state,
        //         serviceSort: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case SERVICE_HIDDEN: {
        //     return {
        //         ...state,
        //         serviceHidden: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case ALTER_FAVOURITE_SERVICE_BY_ID: {
        //     return {
        //         ...state,
        //         servicesList: {
        //             ...state.servicesList,
        //             data: {
        //                 ...state.servicesList.data,
        //                 data: state.servicesList.data.data.length !== 0 && state.servicesList.data.data.map(serv => {
        //                     if (serv._id === action.payload.serviceId) {
        //                         return { ...serv, isFavourite: !serv.isFavourite }
        //                     } else {
        //                         return serv
        //                     }
        //                 })
        //             }
        //         }
        //     }
        // }
        // case DELETE_SERVICE_BY_ID: {
        //     return {
        //         ...state,
        //         servicesList: {
        //             ...state.servicesList,
        //             data: {
        //                 ...state.servicesList.data,
        //                 data: state.servicesList.data.data.filter(serv => serv._id !== action.payload.serviceId)
        //             }
        //         }
        //     }
        // }
        // case CLEAR_SERVICES: {
        //     return {
        //         ...state,
        //         servicesList: {}
        //     }
        // }
        // case ADD_VERSION_LIST_DATA: {
        //     return {
        //         ...state,
        //         versionsList: {
        //             ...state.versionsList,
        //             ...action.payload
        //         }
        //     }
        // }
        // case VERSION_SORT: {
        //     return {
        //         ...state,
        //         versionSort: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case VERSION_HIDDEN: {
        //     return {
        //         ...state,
        //         versionHidden: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case VERSION_LOADING: {
        //     return {
        //         ...state,
        //         versionsList: {
        //             ...state.versionsList,
        //             loading: action.payload
        //         }
        //     }
        // }
        // case DELETE_VERSION_BY_ID: {
        //     return {
        //         ...state,
        //         versionsList: {
        //             ...state.versionsList,
        //             data: {
        //                 ...state.versionsList.data,
        //                 data: state.versionsList.data.data.filter(ver => ver._id !== action.payload.versionId)
        //             }
        //         }
        //     }
        // }
        // case CLEAR_VERSION: {
        //     return {
        //         ...state,
        //         versionsList: {}
        //     }
        // }
        // case ADD_API_LIST_DATA: {
        //     return {
        //         ...state,
        //         apisList: {
        //             ...state.apisList,
        //             ...action.payload
        //         }
        //     }
        // }
        // case API_SORT: {
        //     return {
        //         ...state,
        //         apiSort: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case API_HIDDEN: {
        //     return {
        //         ...state,
        //         apiHidden: [
        //             ...action.payload
        //         ]
        //     }
        // }
        // case DELETE_API_BY_ID: {
        //     return {
        //         ...state,
        //         apisList: {
        //             ...state.apisList,
        //             data: {
        //                 ...state.apisList.data,
        //                 data: state.apisList.data.data.filter(api => api._id !== action.payload.apiId)
        //             }
        //         }
        //     }
        // }
        // case CLEAR_API: {
        //     return {
        //         ...state,
        //         apisList: {}
        //     }
        // }

        
        case EDIT_AITASK_VERSION: {
            return {
              ...state,
              selectedVersionId: action.payload,
              editVersion:!state.editVersion,
              detailVersion:true,
            };
          }
          case EDIT_AITASK_API: {
              return {
                ...state,
                selectedAPIId: action.payload,
                editAPI:!state.editAPI,
                detailAPI:true,
              };
            }
      
          // case DETAIL_VERSION: {
          //   return {
          //     ...state,
          //     detailVersion: !state.detailVersion,
          //   };
          // }
          case SET_SELECTED_AITASK_VERSIONID: {
            return {
              ...state,
              selectedVersionId: action.payload,
              editVersion: false,
              detailVersion:true
            };
          }
          case SET_ONLY_VERSIONID:{
            return {
                ...state,
                selectedVersionId:action.payload
            }
          }
      
          case SET_SELECTED_AITASK_APIID: {
              return {
                ...state,
                selectedAPIId: action.payload,
                editAPI: false,
                detailAPI:true
              };
            }
            
          
          case CLOSE_AITASK_VERSION: {
              return {
                ...state,
                selectedVersionId: null,
                editVersion: false,
                detailVersion:false
              };
            }
      
            case CLOSE_AITASK_API: {
              return {
                ...state,
                selectedAPIId: null,
                editAPI: false,
                detailAPI:false
              };
            }
        default:
            return state;
    }
}

export default aiTaskReducer;
