import { useState } from "react";
import StatusStyles from "../../../atoms/rightSide/appearance/showStatus/ShowStatusStyles";
import useGetData from "../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import { useSnackbar } from "notistack";
import { MenuItem, Select } from "@material-ui/core";

function ImageSettings() {
  const styles = StatusStyles();
  const dispatch = useDispatch();
  const { currentProject, currentPage, currentComponent, getDataValue } =
    useGetData();
  const [accessModErr, setAccessModErr] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const viewKeys = {
    Web: "imgUrl",
    Tablet: "tabletImgUrl",
    Mobile: "mobileImgUrl",
  };
  const uploadKeys = {
    Web: "webImgUpload",
    Tablet: "tabletImgUpload",
    Mobile: "mobileImgUpload",
  };

  const handleImageUpload = (key, event) => {
    if (!event.target.files || event.target.files.length === 0) return;
    if (event?.target?.files?.length !== 0) {
      if (event?.target?.files[0]?.size <= 1025000) {
        const imageUrl = URL.createObjectURL(event.target.files[0]);
        if (event?.target?.files[0]) {
          let reader = new FileReader();
          reader.readAsDataURL(event?.target?.files[0]);
          reader.onload = function () {
            const components = currentPage.components.map((component) =>
              component.id === currentComponent.id
                ? {
                    ...component,
                    [key]: {
                      dataInput: "",
                      dataValue: reader.result,
                    },
                  }
                : component
            );
            dispatch(
              editComponent({
                pageid: currentPage?.id,
                data: components,
              })
            );
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        }
      } else {
        enqueueSnackbar("Please upload image size less than 100kb", {
          variant: "error",
        });
      }
    }
  };

  const handleImage = (key, value) => {
    let dataValue = [];
    try {
      dataValue = getDataValue(value);
      setAccessModErr("");
    } catch (error) {
      setAccessModErr(error?.message);
    }
    const components = currentPage.components.map((component) =>
      component.id === currentComponent.id
        ? {
            ...component,
            [key]: {
              dataInput: value,
              dataValue: dataValue,
            },
          }
        : component
    );
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };

  return (
    <>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      {viewTypes.map((viewType) => (
        <div className="field">
          <label>For {viewType}</label>
          <div className={styles.loadingbtnContainer}>
            <TrueFalseButton
              leftButton={
                <>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 2,
                        filter: "invert(0.5)",
                      }}
                      src="/img/Right.svg"
                    />
                    Upload
                  </span>
                </>
              }
              rightButton={
                <>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{ width: 16, height: 16, marginRight: 2 }}
                      src="/img/Link.svg"
                    />
                    URL
                  </span>
                </>
              }
              value={currentComponent?.[uploadKeys[viewType]]}
              name={uploadKeys[viewType]}
            />
          </div>
          {currentComponent?.[uploadKeys[viewType]] ? (
            <input
              type="file"
              name="file"
              autoComplete="off"
              onChange={(event) => handleImageUpload(viewKeys[viewType], event)}
            />
          ) : (
            <div style={{ marginTop: 3 }}>
              <JSDataInput
                value={currentComponent?.[viewKeys?.[viewType]]}
                onChange={(value) => handleImage(viewKeys[viewType], value)}
                error={accessModErr}
                accessMode={true}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default ImageSettings;
