import React, { useState } from "react";
import Columns from "../../../atoms/rightSide/settings/Columns";
import useGetData from "../../../../hooks/useGetData";
import JSDataInput from "../../../atoms/rightSide/settings/JSDataInput";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import { MenuItem, Select } from "@material-ui/core";

function FormSettings() {
  const dispatch = useDispatch();
  const {
    currentComponent,
    globalHandleChange,
    globalAcessModeChange,
    executeJavascript,
    getDataValue,
    currentPage,
    currentProject,
  } = useGetData();
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const [error, setError] = useState("");
  const [accessModeErr, setAccessModeErr] = useState("");
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  const handleChange = (value) => {
    let dataValue = [];
    try {
      if (value.includes("return")) {
        dataValue = executeJavascript(value);
        setError("");
      } else {
        dataValue = getDataValue(value);
        setError("");
      }
    } catch (error) {
      setError(error?.message);
    }
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        const columns = Object.keys(currentComponent?.data?.dataValue)?.map(
          (col) => ({
            id: uuidv4(),
            name: col,
            isHide: false,
            isDelete: false,
            isRequired: true,
            placeholder: "",
            type: "text",
            title: col.charAt(0).toUpperCase() + col.slice(1),
            width: "",
            rows: 2,
            options: [],
            isEditable: true,
            disabled: {
              dataInput: "false",
              dataValue: "false",
            },
          })
        );
        return {
          ...component,
          data: {
            dataInput: value,
            dataValue: dataValue,
          },
          columns: !Array?.isArray(currentComponent?.data?.dataValue)
            ? columns
            : [],
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        pageid: currentPage?.id,
        data: components,
      })
    );
  };

  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <JSDataInput
          name="data"
          value={currentComponent?.data}
          onChange={handleChange}
          label="Data"
          title={`${currentComponent.name} : Data`}
          error={error}
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Title"
          name="title"
          value={currentComponent?.title}
          onChange={(value) =>
            globalAcessModeChange(value, "title", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      {currentComponent.type === "Form" && (
        <div className="field">
          <div className="tableColumns">
            <div className="field">
              <div className="tableColumns">
                <label>Structure</label>
                <Columns columns={currentComponent.columns} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormSettings;
