import {
  USER_COLOR_FAIL,
  USER_COLOR_REQUEST,
  USER_COLOR_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_TAB_FAIL,
  USER_TAB_REQUEST,
  USER_TAB_SUCCESS,
  USER_RESET,
  USER_RETAIN,
} from '../../constants/UserProfile/user';

// tabs
export const selectTabReducer = (state = { userTab: 3 }, action) => {
  switch (action.type) {
    case USER_TAB_REQUEST:
      return { loading: true };
    case USER_TAB_SUCCESS:
      return { loading: false, success: true, userTab: action.payload };
    case USER_TAB_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
//
export const userEditReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { loading: true };
    case USER_EDIT_SUCCESS:
      return { loading: false, success: true };
    case USER_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// reducer for getting user details
export const userDetailsReducer = (
  state = {
    userInfo: {
      dateFormat: 'YYYY-MM-DD',
      timeZone: 'UTC',
      time: 'h:mm',
      color: '#002855',
    },
  },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    case USER_RESET:
      return {
        ...state,
        userInfo: {
          dateFormat: 'YYYY-MM-DD',
          timeZone: 'UTC',
          time: 'h:mm',
          color: '#002855',
        },
      };
    case USER_RETAIN:
      return {
        ...state,
        success: false,
      };
    case USER_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
