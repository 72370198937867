const apiList = [
  { name: "name", title: "API Name", for: "depAPIList" },
  { name: "type", title: "Type", for: "depAPIList" },
  { name: "method", title: "Method", for: "depAPIList" },
  { name: "path", title: "Path", for: "depAPIList" },
  { name: "endPoint", title: "Endpoint", for: "depAPIList" },
];

const apiListDefaultColWidth = [
  { columnName: "name", weightage: 4, width: 200 },
  { columnName: "type", weightage: 4, width: 200 },
  { columnName: "method", weightage: 4, width: 200 },
  { columnName: "path", weightage: 4, width: 200 },
  { columnName: "endPoint", weightage: 2.5, width: 200 },
];

const apiListColExtension = [
  { columnName: "name", align: "left" },
  { columnName: "type", align: "left" },
  { columnName: "method", align: "left" },
  { columnName: "path", align: "left" },
  { columnName: "endPoint", align: "left" },
];

const apiListColOrder = ["name", "type", "method", "path", "endPoint"];

export {
  apiList,
  apiListDefaultColWidth,
  apiListColExtension,
  apiListColOrder,
};
