import { Grid, TextField, Backdrop, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../components/common/Breadcrumbs'
import manageStyles from '../../CommonStyles/manageScreenStyles'
import { AppButton } from '../../../components/common/Button'
import { createFieldGroup_ } from '../../../utils/ontology/ontologyUtils'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import CustomChips from '../../../components/common/Chips'
import { getOntologyFields } from '../../../redux/actions/ontologyAction'

function CreateFieldGroupScreen(props) {
  // const [fieldList, setFieldList] = useState([])
  const [fgData, setfgData] = useState({
    field_name: '',
    field_label: '',
    field_description: '',
  })
  const [error, setError] = useState({
    field_name: false,
    field_label: false,
    field_arr: false,
  })
  const [loading, setLoading] = useState(false)
  const [selectedFields, setSelectedFields] = useState([])

  const styles = manageStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  useEffect(() => {
    props.getOntologyFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = (key, value) => {
    setfgData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (Object.keys(error).includes(key)) {
      setError((prevState) => ({
        ...prevState,
        [key]: false,
      }))
    }
  }

  const handleFieldSelected = (e, input) => {
    if (input) {
      setError((prevState) => ({
        ...prevState,
        field_arr: false,
      }))
      var tempFields =
        selectedFields.length > 0 ? selectedFields.filter((item) => item._id === input._id) : []
      if (tempFields.length === 0) {
        setSelectedFields((prevState) => [...prevState, input])
      }
      //
    }
  }

  const handleChipDelete = (data) => {
    setSelectedFields((prevState) => {
      var tempFields = prevState.length > 0 && prevState.filter((item) => item._id !== data._id)
      return tempFields
    })
  }

  const handleSubmit = async () => {
    var errorFlag = false
    var tempError = {}
    for (var obj in fgData) {
      if (fgData[obj] === '') {
        if (Object.keys(error).includes(obj)) {
          errorFlag = true
          tempError[obj] = true
        }
      }
    }
    if (selectedFields.length === 0) {
      errorFlag = true
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        field_arr: true,
      }))
    } else {
      setError((prevState) => ({
        ...prevState,
        ...tempError,
        field_arr: false,
      }))
    }
    if (!errorFlag) {
      setLoading(true)
      const req_data = {
        name: fgData.field_name,
        fieldGroupLabel: fgData.field_label,
        description: fgData.field_description,
        fields: selectedFields.map((item) => item._id),
      }
      const { _msg, _status } = await createFieldGroup_(req_data)
      if (_status === 201) {
        setLoading(false)
        enqueueSnackbar(_msg, { variant: 'success' })
        history.push('/ontology')
      } else {
        setLoading(false)
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', {
          variant: 'error',
        })
      }
    }
  }

  return (
    <main className={styles.main}>
      <Backdrop className={styles.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: 'Ontology', path: '/ontology' },
              { title: 'Field Group', path: '/ontology' },
              { title: 'Add Field Group' },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: '2px solid #afafaf' }}
          >
            <h2 className={styles.heading}>{'Field Group'}</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <AppButton
                color="primary"
                buttonName="Create"
                variant="contained"
                disabled={false}
                style={{ margin: 'unset' }}
                className="btnsmall"
                onClick={handleSubmit}
              />
              <AppButton
                color="primary"
                buttonName="Cancel"
                variant="outlined"
                className="btnsmall"
                onClick={() => history.goBack()}
              />
            </div>
          </Grid>
        </div>
        <div className={styles.wrapper}>
          <Grid
            container
            spacing={3}
            style={{ marginTop: '2rem', borderBottom: '2px solid rgb(175,175,175)' }}
          >
            <Grid item xs={9} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Group Name<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={styles.input}
                    style={error.field_name ? { border: '1px solid red' } : {}}
                    value={fgData.field_name}
                    onChange={(e) => handleTextChange('field_name', e.target.value)}
                    autoComplete="off"
                  />
                  {error.field_name && (
                    <text className={styles.errorMsg}>Invalid Field Group Name</text>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor="" className={styles.fieldHeading}>
                    Field Group Label<span className={styles.red}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    style={error.field_label ? { border: '1px solid red' } : {}}
                    className={styles.input}
                    value={fgData.field_label}
                    onChange={(e) => handleTextChange('field_label', e.target.value)}
                    autoComplete="off"
                  />
                  {error.field_label && (
                    <text className={styles.errorMsg}>Invalid Field Group </text>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3}>
              <label htmlFor="projectDescription" className={styles.fieldHeading}>
                Field Group Description
              </label>
              <textarea
                name="description"
                value={fgData.field_description}
                style={
                  error.field_description
                    ? { border: '1px solid red', height: '7.8rem' }
                    : { height: '7.8rem' }
                }
                onChange={(e) => handleTextChange('field_description', e.target.value)}
                className={styles.textarea}
                rows="8"
              ></textarea>
              {/* {error.field_description && (
                <text className={styles.errorMsg}>
                  Invalid Field Group Description
                </text>
              )} */}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginLeft: '.1rem', paddingTop: 25 }}>
            <Grid spacing={3} container xs={9} sm={9}>
              <Grid item xs={4} sm={4}>
                <label htmlFor="" className={styles.fieldHeading}>
                  Fields<span className={styles.red}>*</span>
                </label>
                <Autocomplete
                  style={error.field_arr ? { border: '1px solid red' } : {}}
                  options={props.data}
                  getOptionLabel={(option) => option.name}
                  className={styles.searchInput3}
                  onChange={handleFieldSelected}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" placeholder="Select Fields" />
                  )}
                />
                {error.field_arr && <text className={styles.errorMsg}>No Fields Selected</text>}
                {selectedFields.length > 0 && (
                  <CustomChips chipsData={selectedFields} handleDelete={handleChipDelete} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* </Container> */}
      </form>
    </main>
  )
}

const mapDispatchToProps = {
  getOntologyFields,
}

const mapStateToProps = (state) => {
  const { fields } = state.ontologyReducer
  const { data, error, loading } = fields
  return {
    data: data ? data : [],
    error,
    loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFieldGroupScreen)
