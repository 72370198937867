import {
  ADD_API_LIST_DATA,
  ADD_SERVICE_GUI_LIST_DATA,
  ADD_VERSION_LIST_DATA,
  ALTER_FAVOURITE_SERVICE_BY_ID,
  ALTER_SUBSCRIBE_SERVICE_BY_ID,
  ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID,
  ALTER_FAVOURITE_SERVICE_GUI_BY_ID,
  DELETE_SERVICE_GUI_BY_ID,
  API_HIDDEN,
  API_SORT,
  CLEAR_API,
  CLEAR_SERVICES,
  CLEAR_VERSION,
  DELETE_API_BY_ID,
  DELETE_SERVICE_BY_ID,
  DELETE_VERSION_BY_ID,
  SELECT_API_CONTAINER,
  SERVICE_FILTERS,
  SERVICE_GUI_FILTERS,
  SERVICE_GUI_SORT,
  SERVICE_HIDDEN,
  SERVICE_SORT,
  VERSION_HIDDEN,
  VERSION_LOADING,
  VERSION_SORT,
  SERVICE_GUI_HIDDEN,
} from "../actionTypes";
import { CommonServices } from "../../services/commonServices";
import { MicroServicesAPIServices } from "../../services/microservices";

const commonServices = new CommonServices();
const microserviceAPIServices = new MicroServicesAPIServices();

const add_ser_gui_data = (payload) => {
  return {
    type: ADD_SERVICE_GUI_LIST_DATA,
    payload: payload,
  };
};

const add_vers_data = (payload) => {
  return {
    type: ADD_VERSION_LIST_DATA,
    payload: payload,
  };
};

const add_api_data = (payload) => {
  return {
    type: ADD_API_LIST_DATA,
    payload: payload,
  };
};

export const getServiceGUIList =
  (currentPage, filter, sort) => async (dispatch, getState) => {
    const {
      userPersonal: { userFavourites, userSubscriptions },
    } = getState();

    try {
      dispatch(
        add_ser_gui_data({
          loading: true,
        })
      );
      const { data } = await commonServices.getServiceGUIList(
        currentPage,
        filter,
        sort
      );
      if (data) {
        dispatch(
          add_ser_gui_data({
            loading: false,
            GUIdata: {
              data:
                data.data.length !== 0 &&
                data.data.map((serv) => {
                  const checkFav =
                    userFavourites.filter(
                      (fav) => fav.resourceinstance === serv._id
                    ).length !== 0;
                  let tempProj = serv;
                  if (checkFav) {
                    tempProj["isFavourite"] = true;
                  } else {
                    tempProj["isFavourite"] = false;
                  }
                  const checkSubscribe =
                    userSubscriptions.filter(
                      (sub) =>
                        sub.name.split("_")[0] === "service" &&
                        sub.name.split("_")[1] === serv._id
                    ).length !== 0;
                  if (checkSubscribe) {
                    tempProj["isSubscribe"] = true;
                  } else {
                    tempProj["isSubscribe"] = false;
                  }
                  return tempProj;
                }),
            },
            error: false,
            _totalcount: data._totalcount,
          })
        );
      } else {
        dispatch(
          add_ser_gui_data({
            loading: false,
            GUIdata: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_ser_gui_data({
          loading: false,
          GUIdata: [],
          error: true,
        })
      );
    }
  };

export const addServiceGUIFilters = (serviceGUIFilters) => {
  return {
    type: SERVICE_GUI_FILTERS,
    payload: serviceGUIFilters,
  };
};

export const deleteServiceGUIById = (serviceId) => {
  return {
    type: DELETE_SERVICE_GUI_BY_ID,
    payload: {
      serviceId,
    },
  };
};

export const addServiceGUISort = (serviceGUISort) => {
  return {
    type: SERVICE_GUI_SORT,
    payload: serviceGUISort,
  };
};

export const addServiceGUIHidden = (serviceHidden) => {
  return {
    type: SERVICE_GUI_HIDDEN,
    payload: serviceHidden,
  };
};

export const alterFavouriteServiceGUIById = (serviceId) => {
  return {
    type: ALTER_FAVOURITE_SERVICE_GUI_BY_ID,
    payload: {
      serviceId,
    },
  };
};

export const alterSubscibeServiceGUIById = (serviceId) => {
  return {
    type: ALTER_SUBSCRIBE_SERVICE_GUI_BY_ID,
    payload: {
      serviceId,
    },
  };
};

export const clearServicesGUI = () => {
  return {
    type: CLEAR_SERVICES,
  };
};

export const getVersionList =
  (serviceId, currentPage, sort) => async (dispatch) => {
    try {
      dispatch(
        add_vers_data({
          loading: true,
        })
      );
      const { data } = await microserviceAPIServices.getAllVersions(
        serviceId,
        currentPage,
        JSON.stringify({}),
        sort
      );
      if (data) {
        dispatch(
          add_vers_data({
            loading: false,
            data: data,
            error: false,
            _totalcount: data._totalcount,
          })
        );
      } else {
        dispatch(
          add_vers_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_vers_data({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const deleteVersionById = (versionId) => {
  return {
    type: DELETE_VERSION_BY_ID,
    payload: {
      versionId,
    },
  };
};

export const addVersionSort = (versionSort) => {
  return {
    type: VERSION_SORT,
    payload: versionSort,
  };
};

export const addVersionHidden = (versionHidden) => {
  return {
    type: VERSION_HIDDEN,
    payload: versionHidden,
  };
};

export const toggleVersionLoading = (loading) => {
  return {
    type: VERSION_LOADING,
    payload: loading,
  };
};

export const clearVersions = () => {
  return {
    type: CLEAR_VERSION,
  };
};

export const getAPIList =
  (serviceId, versionId, currentPage, sort) => async (dispatch) => {
    try {
      dispatch(
        add_api_data({
          loading: true,
        })
      );
      const { data } = await microserviceAPIServices.getAllAPIs(
        serviceId,
        versionId,
        currentPage,
        sort
      );
      if (data) {
        dispatch(
          add_api_data({
            loading: false,
            data: data,
            error: false,
            _totalcount: data._totalcount,
          })
        );
      } else {
        dispatch(
          add_api_data({
            loading: false,
            data: [],
            error: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        add_api_data({
          loading: false,
          data: [],
          error: true,
        })
      );
    }
  };

export const deleteAPIById = (apiId) => {
  return {
    type: DELETE_API_BY_ID,
    payload: {
      apiId,
    },
  };
};

export const addAPISort = (apiSort) => {
  return {
    type: API_SORT,
    payload: apiSort,
  };
};

export const addAPIHidden = (apiHidden) => {
  return {
    type: API_HIDDEN,
    payload: apiHidden,
  };
};

export const clearAPIs = () => {
  return {
    type: CLEAR_API,
  };
};

export const selectApiInstance = (apiId, selectedInstance) => {
  return {
    type: SELECT_API_CONTAINER,
    payload: {
      apiId,
      selectedInstance,
    },
  };
};
