const accessMaintenanceColumns = [
  // { name: "id", title: "Project ID" },
  { name: "roleName", title: "Role Name", for: 'access' },
  { name: "resourceTypes", title: "Resource types", for: 'access' },
  { name: "createdBy", title: "Created By", for: 'access' },
  { name: "createdon", title: "Created On", for: 'access' },
  { name: "action", title: "Action", route: "/role-management/edit", for: 'access' },
];

const accessMaintenanceDefaultColumnWidths = [
  // { columnName: "id", width: 250 },
  { columnName: "roleName", width: 250 },
  { columnName: "resourceTypes", width: 600 },
  { columnName: "createdBy", width: 230 },
  { columnName: "createdon", width: 200 },
  { columnName: "action", width: 300 },
];

const accessMaintenanceTableColumnExtensions = [
  // { columnName: "id", align: "left" },
  { columnName: "roleName", align: "left" },
  { columnName: "resourceTypes", align: "center" },
  { columnName: "createdBy", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const accessMaintenanceColumnOrder = [
  // "id",
  "roleName",
  "resourceTypes",
  "createdBy",
  "createdon",
  "action",
];

const accessMaintenanceRows = [
  {
    id: 12345,
    roleName: 'Super Admin 2',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12345,
    roleName: 'Organisation Admin',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12346,
    roleName: 'Project Manager / Owner',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12347,
    roleName: 'Architect',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12348,
    roleName: 'Service Group Admin',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
  {
    id: 12349,
    roleName: 'Team Lead',
    resourceTypes: 'Project, Service Group, Organisation, Vendor, Team, User, +25 Resource types',
    createdBy: 'Super Admin 1',
    createdOn: '02/04/2021'
  },
]

const accessMaintenanceDefaultSorting = []

const NOT_ONTOLOGY = ['StatusProfile', 'Ontology', 'Tags', 'Roles', 'Organization', 'HelpDesk', 'Documents',
  'OntologyResource', 'Dashboard', 'Document', 'DocumentVersion', 'DocumentType', 'OntologyField', 'OntologyFieldGroup',
  'OntologySection', 'Process', 'Repository', 'Role', 'GlossaryTag', 'Environmet', 'UIApplication']

export {
  accessMaintenanceRows,
  accessMaintenanceColumns,
  accessMaintenanceDefaultColumnWidths,
  accessMaintenanceTableColumnExtensions,
  accessMaintenanceColumnOrder,
  accessMaintenanceDefaultSorting,
  NOT_ONTOLOGY
};
