import {
    ADD_SINGLE_FAVOURITE,
    ADD_SINGLE_USER_SUBSCRIPTION,
    REMOVE_SINGLE_FAVOURITE,
    REMOVE_SINGLE_USER_SUBSCRIPTION,
    USER_FAVOURITES,
    USER_SUBSCRIPTION,
    USER_NOTIFICATIONS,
  } from "../../constants/UserProfile/user";
  import { userServices } from "../../services/userServices";
  
  const userService = new userServices();
  
  export const addUserFavouite = (userId) => async (dispatch) => {
    try {
      const res_data = await userService.getAllFavourite(userId);
      const { data } = res_data;
      if (data) {
        dispatch({
          type: USER_FAVOURITES,
          payload: data.data ? data.data : [],
        });
      } else {
        dispatch({
          type: USER_FAVOURITES,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: USER_FAVOURITES,
        payload: [],
      });
    }
  };
  
  export const addSingleFavourite = (favData) => {
    return {
      type: ADD_SINGLE_FAVOURITE,
      payload: favData,
    };
  };
  
  export const removeSingleFavourite = (resourceinstance) => {
    return {
      type: REMOVE_SINGLE_FAVOURITE,
      payload: resourceinstance,
    };
  };
  
  export const addUserSubscription = (userId) => async (dispatch) => {
    try {
      const res_data = await userService.getSubscribedResources(userId);
      const { data } = res_data;
      if (data) {
        dispatch({
          type: USER_SUBSCRIPTION,
          payload: data.data ? data.data : [],
        });
      } else {
        dispatch({
          type: USER_SUBSCRIPTION,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: USER_SUBSCRIPTION,
        payload: [],
      });
    }
  };
  
  export const addSingleUserSubscription = (subData) => {
    return {
      type: ADD_SINGLE_USER_SUBSCRIPTION,
      payload: subData,
    };
  };
  
  export const removeSingleUserSubscription = (resourceinstance) => {
    return {
      type: REMOVE_SINGLE_USER_SUBSCRIPTION,
      payload: resourceinstance,
    };
  };
  export const addUserNotification = (data) => ({
    type: USER_NOTIFICATIONS,
    payload: data,
  });
  
  export const handleUnseenCount = (data) => ({
    type: USER_NOTIFICATIONS,
    payload: data,
  });