import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";

const apiHelper = new ApiHelper();

export class AccessMaintananceServices {
  getAllRoles(currentPage, filter, sort) {
    const queryParam = (`page=${currentPage}`) +
      (filter !== undefined && filter !== {} ? `&filters=${filter}` : '') +
      (sort !== undefined && sort !== {} ? `&sort=${sort}` : '');
    const uri = `${BASE_URL}/api/v1.0.0/roles?${queryParam}&size=10`;
    return apiHelper.get(uri);
  }
  getAllResourceTypes() {
    const uri = `${BASE_URL}/api/v1.0.0/resourceTypes`;
    return apiHelper.get(uri);
  }
  addRole(roleData) {
    const uri = `${BASE_URL}/api/v1.0.0/roles`;
    return apiHelper.post(uri, roleData);
  }
  editRole(roleId, roleData) {
    const uri = `${BASE_URL}/api/v1.0.0/roles/${roleId}`;
    return apiHelper.put(uri, roleData);
  }
  getRoleById(roleId) {
    const uri = `${BASE_URL}/api/v1.0.0/roles/${roleId}`;
    return apiHelper.get(uri);
  }
  deleteRole(roleId) {
    const uri = `${BASE_URL}/api/v1.0.0/roles/${roleId}`;
    return apiHelper.delete(uri);
  }
}
