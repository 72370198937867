const projectColumns = [
  // { name: "id", title: "Project ID" },
  { name: "name", title: "Project Name", for: "project" },
  { name: "createdby", title: "Created By", for: "project" },
  { name: "createdon", title: "Created On", for: "project" },
  { name: "status", title: "Status", for: "project" },
  { name: "action", title: "Action", route: "/projects/edit", for: "project" },
];

const projectDefaultColumnWidths = [
  // { columnName: "id", width: 250 },
  { columnName: "name", weightage: 2.5, width: 200 },
  { columnName: "createdby", weightage: 2, width: 200 },
  { columnName: "createdon", weightage: 1.5, width: 200 },
  { columnName: "status", weightage: 1.5, width: 200 },
  { columnName: "action", weightage: 2.5, width: 200 },
];

const projectTableColumnExtensions = [
  // { columnName: "id", align: "left" },
  { columnName: "name", align: "left" },
  { columnName: "createdby", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "status", align: "left" },
  { columnName: "action", align: "center", sortingEnabled: false },
];

const projectColumnOrder = [
  // "id",
  "name",
  "createdby",
  "createdon",
  "status",
  // "action",
];

const projectColumnHidden = [
  // "id",
  "name",
  "createdby",
  "createdon",
  "status",
  "action",
];

export {
  projectColumns,
  projectDefaultColumnWidths,
  projectTableColumnExtensions,
  projectColumnOrder,
  projectColumnHidden
};
