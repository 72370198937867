import { ApiHelper } from "./helper/ApiHelper";
import { PROJECTS } from "./helper/config";
import { DOCUMENT } from "./helper/config";

const apiHelper = new ApiHelper();

export class ProjectServices {
  getProjectById(projectId) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}`;
    return apiHelper.get(uri);
  }
  addProject(projectData) {
    const uri = `${PROJECTS}/api/v1.0.0/projects`;
    return apiHelper.post(uri, projectData)
  }
  editProject(projectId, projectData) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}`;
    return apiHelper.put(uri, projectData)
  }
  deleteProject(projectId) {
    const uri = `${PROJECTS}/api/v1.0.0/projects/${projectId}`;
    return apiHelper.delete(uri)
  }
  deleteDocument(documentId) {
    const uri = `${DOCUMENT}api/v1.0.0/documents/${documentId}`;
    return apiHelper.delete(uri)
  }
}
