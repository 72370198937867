import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/Breadcrumbs";
import manageStyles from "../CommonStyles/manageScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useHistory } from "react-router";
import { assignDeassignTeamToProject, fetchTeamListData } from "../../utils/project/teamUtils";
import { useSnackbar } from "notistack";
import {
  addServiceGroupInProject,
  addServiceGroupInServiceGroup,
} from "../../utils/project/servicegroupUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import {
  handleFormValidation,
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../utils/common";
import CopyServiceModal from "../../components/CopyServiceModal";
import { copyService } from "../../utils/project/microserviceUtils";
import { fetchStatusResourceByName } from "../../utils/status/statusUtils";
import CustomFieldGroupsForServiceGroupCreate from "../../components/common/entitySchema/servicegroup/CustomFieldGroupsForServiceGroupCreate";
import Loader from "../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../components/Tabbed/TabbedNavigation";
import { connect } from "react-redux";
import { updateServiceGroupMode } from "../../redux/actions/projectAction";
import { getServiceGroupsListByProject } from "../../redux/actions/serviceGroupAction";
import SectionTabs from "../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../components/Tabbed/TabsCommon";
import { fetchTeamsByProject } from "../../utils/project/projectUtils";

const AddServiceGroup = (props) => {

  const { updateServiceGroupMode, tabbedView, getServiceGroupsListByProject } = props
  const [projectId] = useState(
    tabbedView ? props.projectId : props.match.params.projectId
  );

  const [level, setLevel] = useState(tabbedView ? 1 : (props.location.state?.level ? props.location.state.level : 1));

  const [parentServiceGroupId, setParentServiceGroupId] = useState();
  // const [parentServiceGroupId, setParentServiceGroupId] = useState(props.match.params.groupId ? props.match.params.groupId : '')

  const styles = manageStyles();
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [serviceGroupData, setServiceGroupData] = useState({
    name: "",
    description: "",
    teams: [],
    status: "",
  });
  const [error, setError] = useState({
    name: { isError: false },
    teams: { isError: false },
  });
  const [teams, setTeams] = useState([]);
  const [serviceGroupEntitySchema, setServiceGroupEntitySchema] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [clickedTeam, setClickedTeam] = useState(
    teams === null ? {} : teams[0]
  );
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [copyServiceModal, setCopyServiceModal] = useState(false);
  const [newServiceListData, setNewServiceListData] = useState([]);
  const [teamLoader, setTeamLoader] = useState(false);
  const [documentsError, setDocumentsError] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!(level >= 1 && level <= 4)) {
      enqueueSnackbar("Invalid level", { variant: "error" });
    }
  }, [level]);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    setIsLoader(true)
    checkScreenPermission("Project", ["Create Service Group"]);
    const fetchServiceGroupEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "ServiceGroup",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceGroupEntitySchema(entity);
        const sgData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                sgData[`${field.datakey}_unit`] = "";
                sgData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (sgData[field.datakey] = "");
              }
            });
          });
        });
        setServiceGroupData((prevData) => ({ ...prevData, ...sgData }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["documentation"];
        delete errData["documentationTable"];
        setError(errData);
        setIsLoader(false)
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
        setIsLoader(false)
      }
    };

    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName(
        "ServiceGroup"
      );
      if (_status === 200) {
        setServiceGroupData({
          ...serviceGroupData,
          status: data?.status.find((s) => s.default === true)
            ? data?.status.find((s) => s.default === true)?.status
            : data?.status?.length && data?.status[0]?.status,
        });
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchServiceGroupEntitySchema();
    if (checkCompPermission("Team", ["List All"])) {
      fetchTeamsList();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource();
    }
  }, []);

  const fetchTeamByProjectId = async () => {
    const { _msg, _status, data } = await fetchTeamsByProject(projectId);
    if (_status === 200) {
      // setProjectTeams(data ? data : []);
      setSelectedTeams(data ? data : []);
    } else {
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchTeamByProjectId()
  },[])


  const fetchTeamsList = async (filter) => {
    setTeamLoader(true);
    const { _msg, _status, data } = await fetchTeamListData(0, filter);
    if (_status === 200) {
      setTeams(data ? data.map((item) => ({ ...item, checked: false })) : []);
      setTeamLoader(false);
    } else {
      setTeamLoader(false);
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const assignDeassingTeam = async (team, forType) => {
    const teamData = {
      teamid: team._id,
    };
    setIsLoader(true);
    const { _msg, _status, data } = await assignDeassignTeamToProject(
      projectId,
      teamData,
      forType
    );
    if (_status === 200) {
      if (forType === "assignteam") {
        setSelectedTeams([...selectedTeams, team]);
      } else {
        setSelectedTeams(selectedTeams.filter((item) => item._id !== team._id));
        setClickedTeam(selectedTeams.filter((item) => item._id !== team._id));
      }
      setIsLoader(false);
    } else {
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
    setIsLoader(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setServiceGroupData({
      ...serviceGroupData,
      [event.target.name]: value,
    });
  };

  const handleTeamCheck = async (team) => {
    // if (!selectedTeams.includes(team)) {
    //   setSelectedTeams([...selectedTeams, team]);
    //   setServiceGroupData({
    //     ...serviceGroupData,
    //     teams: [...serviceGroupData?.teams, team],
    //   });
    // }
    if (!selectedTeams.find((item) => item._id === team._id)) {
      await assignDeassingTeam(team, "assignteam");
    } else {
      await assignDeassingTeam(team, "dismissteam");
    }
  };

  const handleCopyService = async (groupId) => {
    // copy microservice
    for (var i = 0; newServiceListData && i < newServiceListData?.length; i++) {
      const copyServiceData = {
        ...newServiceListData[i],
        projectID: projectId,
        servicegroupID: groupId,
        level: level,
      };
      const serviceResData = await copyService(copyServiceData);
      if (serviceResData._status === 201) {
        enqueueSnackbar(serviceResData._msg, { variant: "success" });
      } else {
        setIsLoader(false);
        if (serviceResData._msg === "Invalid Json") {
          serviceResData.data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(
            serviceResData._msg ? serviceResData._msg : "Something went wrong",
            { variant: "error" }
          );
        }
      }
    }
    setNewServiceListData([]);
  };

  const handleNewServiceGroupClick = async (actionType) => {
    const { formIsValid, errors } = handleFormValidationPro(
      serviceGroupData,
      error
    );
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(serviceGroupEntitySchema, errors, tabbedView)
      setActiveTab(errorInTab);
      setServiceGroupEntitySchema(tempEntity)
      setError(errors);

    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const sgData = {
        ...serviceGroupData,
        teams: selectedTeams.map((item) => item._id),
        level: level,
      };
      setIsLoader(true);
      let parentId = parentServiceGroupId;
      if (level === 1) {
        const { _msg, _status, data } = await addServiceGroupInProject(
          projectId,
          sgData
        );
        if (_status === 201) {

          updateServiceGroupMode({ value: null })
          getServiceGroupsListByProject(
            projectId,
            1,
            JSON.stringify({}),
          );

          parentId = data._id;
          enqueueSnackbar(_msg, { variant: "success" });
          handleUploadDocuments("servicegroup", data._id, selectedDocuments);

          handleCopyService(parentId);

          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(_msg ? _msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      } else {
        const { _msg, _status, data } = await addServiceGroupInServiceGroup(
          projectId,
          parentServiceGroupId,
          sgData
        );
        if (_status === 201) {

          parentId = data._id;
          enqueueSnackbar(_msg, { variant: "success" });
          handleUploadDocuments(
            "servicegroup",
            parentServiceGroupId,
            selectedDocuments
          );


          handleCopyService(parentId);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          if (_msg === "Invalid Json") {
            data.map((item) =>
              enqueueSnackbar(item ? item : "Something went wrong", {
                variant: "error",
              })
            );
          } else {
            enqueueSnackbar(_msg ? _msg : "Something went wrong", {
              variant: "error",
            });
          }
        }
      }
      setParentServiceGroupId(parentId);
      setServiceGroupData({
        ...serviceGroupData,
        name: "",
        description: "",
        teams: [],
        status: serviceGroupData.status,
      });
      setSelectedTeams([]);
      setClickedTeam({});
      setError({
        name: false,
        description: false,
        teams: false,
      });
      if (actionType === "submit") {
        histroy.push(`/projects/details/${projectId}`);
      } else {
        setLevel(level + 1);
      }
    } else {
      setError(errors);
      enqueueSnackbar("There are form field error(s), please correct the input values and submit", {
        variant: "error",
      });
    }
  };

  const handleSelectedTeamDelete = async (teamToDelete) => {
    await assignDeassingTeam(teamToDelete, "dismissteam");
    setSelectedTeams(
      selectedTeams.filter((team) => team._id !== teamToDelete._id)
    );
  };

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      fetchTeamsList(JSON.stringify({ name: { $regex: searchInut } }));
    } else {
      fetchTeamsList();
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={!tabbedView && styles.main}>
      {isLoader && <Loader />}
      <form>
        {!tabbedView &&
          <div className={styles.topContent}>
            <BreadCrumbs
              items={[
                { title: "Project List", path: "/projects" },
                {
                  title: props.location?.state?.projectName,
                  path: `/projects/details/${projectId}`,
                },
                { title: "Create Service Group" },
              ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <h2 className={styles.heading}>Create Service Group</h2>
              {level === 1 ? (
                <AppButton
                  color="primary"
                  buttonName="Create"
                  variant="contained"
                  disabled={false}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={() => handleNewServiceGroupClick("submit")}
                />
              ) : (
                <AppButton
                  color="primary"
                  buttonName="Submit"
                  variant="contained"
                  disabled={false}
                  style={{ margin: "unset" }}
                  className="btnsmall"
                  onClick={() => handleNewServiceGroupClick("submit")}
                />
              )}
            </Grid>
          </div>
        }



        {tabbedView ? (
          <>

            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ borderBottom: "2px solid #afafaf" }}
            >
              <div style={{ marginBottom: 5 }}>
                <h3 className={styles.headingChild}>Create Service Group</h3>
              </div>
              <TabbedNavigation
                handleNext={() => setActiveTab((prev) => prev + 1)}
                handlePrev={() => setActiveTab((prev) => prev - 1)}
                // handleEdit={() => histroy.push(`/projects/edit/${projectId}`)}
                // handleCancel={updateServiceGroupMode({value:null})}
                handleCancel={() =>
                  updateServiceGroupMode({ value: null })
                }
                schemaLength={serviceGroupEntitySchema?.length}
                activeTab={activeTab}
                isCreateMode={true}
                handleSave={handleNewServiceGroupClick}
              />
            </Grid>

            <SectionTabs entity={serviceGroupEntitySchema} activeTab={activeTab} handleActiveTab={handleActiveTab} />

            <Paper style={{ padding: 20 }}>
              <div style={{ padding: "0px 5px 0" }}>
                <CustomFieldGroupsForServiceGroupCreate
                  section={serviceGroupEntitySchema[activeTab]}
                  serviceGroupData={serviceGroupData}
                  error={error}
                  selectedTeams={selectedTeams}
                  teams={teams}
                  level={level}
                  clickedTeam={clickedTeam}
                  teamLoader={teamLoader}
                  teamSearchInput={teamSearchInput}
                  selectedDocuments={selectedDocuments}
                  handleTeamCheck={handleTeamCheck}
                  setLevel={setLevel}
                  setCopyServiceModal={setCopyServiceModal}
                  handleNewServiceGroupClick={
                    handleNewServiceGroupClick
                  }
                  newServiceListData={newServiceListData}
                  handleTeamSearch={handleTeamSearch}
                  setServiceGroupData={setServiceGroupData}
                  setSelectedDocuments={setSelectedDocuments}
                  setTeamSearchInput={setTeamSearchInput}
                  setClickedTeam={setClickedTeam}
                  handleSelectedTeamDelete={handleSelectedTeamDelete}
                  documentsError={documentsError}
                  setDocumentsError={setDocumentsError}
                  handleChange={handleChange}
                />
              </div>
            </Paper>
          </>
        ) : (
          <>
            <div className={styles.wrapper}>
              {serviceGroupEntitySchema?.length !== 0 &&
                serviceGroupEntitySchema?.map((section) => {
                  if (section.name === "ServiceGroup_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <CustomFieldGroupsForServiceGroupCreate
                          section={section}
                          serviceGroupData={serviceGroupData}
                          error={error}
                          setProjectData={setServiceGroupData}
                          handleChange={handleChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Accordion
                        className={styles.fieldPanel}
                        defaultExpanded
                        key={section._id}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownRounded
                              className={styles.accordianIcon}
                            />
                          }
                        >
                          <Typography className={styles.sectionHeading}>
                            {section["section Label"]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomFieldGroupsForServiceGroupCreate
                            section={section}
                            serviceGroupData={serviceGroupData}
                            error={error}
                            selectedTeams={selectedTeams}
                            teams={teams}
                            level={level}
                            clickedTeam={clickedTeam}
                            teamLoader={teamLoader}
                            teamSearchInput={teamSearchInput}
                            selectedDocuments={selectedDocuments}
                            handleTeamCheck={handleTeamCheck}
                            setLevel={setLevel}
                            setCopyServiceModal={setCopyServiceModal}
                            handleNewServiceGroupClick={
                              handleNewServiceGroupClick
                            }
                            newServiceListData={newServiceListData}
                            handleTeamSearch={handleTeamSearch}
                            setServiceGroupData={setServiceGroupData}
                            setSelectedDocuments={setSelectedDocuments}
                            setTeamSearchInput={setTeamSearchInput}
                            setClickedTeam={setClickedTeam}
                            handleSelectedTeamDelete={handleSelectedTeamDelete}
                            documentsError={documentsError}
                            setDocumentsError={setDocumentsError}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </div>
          </>
        )}

        <CopyServiceModal
          copyServiceModal={copyServiceModal}
          setCopyServiceModal={setCopyServiceModal}
          handleSubmit={(data) =>
            setNewServiceListData([...newServiceListData, data])
          }
        />
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  updateServiceGroupMode,
  getServiceGroupsListByProject
}

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return {
    tabbedView
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceGroup);
