import React from "react";
import TextAreaStyles from "./TextAreaStyles";
import CreateCustomerStyles from "../../../../screens/CommonStyles/CreateAndDetailsScreenStyle";

const TextArea = ({
  name,
  placeholder,
  label,
  rows,
  value,
  onChange,
  error,
  isRequired,
  selectedComponent,
  ...otherProps
}) => {
  const styles = TextAreaStyles();
  const classes = CreateCustomerStyles();
  return (
    <>
      <label
        style={{
          fontSize: selectedComponent?.fontSize
            ? selectedComponent?.fontSize
            : 16,
        }}
        className={classes.fieldHeading}
      >
        {label} {isRequired === true && <span className={classes.red}>*</span>}
      </label>
      <textarea
        rows={parseInt(rows)}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`${styles.textarea} ${error && classes.error}`}
        style={{
          fontSize: selectedComponent?.fontSize
            ? selectedComponent?.fontSize
            : 16,
          resize: "none",
        }}
        {...otherProps}
      />
      {error && (
        <p
          style={{
            fontSize: selectedComponent?.fontSize
              ? selectedComponent?.fontSize
              : 16,
          }}
          className={classes.errorMsg}
        >{`${label ? label : "Field"} is required`}</p>
      )}
    </>
  );
};

export default TextArea;
