import React, { useState, useEffect } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import useStyles from "../../CommonStyles/manageScreenStyles";
import listStyles from "../../CommonStyles/listScreenStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addAiTask } from "../../../utils/project/microserviceUtils";
import { fetchBoilerPlateListData } from "../../../utils/applicationGovernance/boilerPlateUtils";
import { useSnackbar } from "notistack";
import { fetchLanguagesByProject } from "../../../utils/project/projectUtils";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { getProjectList } from "../../../redux/actions/projectAction";
import { fetchServiceGroupListData } from "../../../utils/project/servicegroupUtils";
import {
  handleFormValidationPro,
  handleUploadDocuments,
  handleFormValidationForDocument,
  initError,
} from "../../../utils/common";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import CustomFieldGroupForAiTaskCreate from "../../../components/common/entitySchema/aiTask/CustomFieldGroupForAiTaskCreate";

const AddAiTaskTabbedScreen = (props) => {
  // const { } = props;
  const mystyles = useStyles();
  const style = listStyles();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const histroy = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [boilerPlateChecked, setBoilerPlateChecked] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [boilerplates, setBoilerPlates] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [projectSearchInput, setProjectSearchInput] = useState("");
  const [serviceGroupSearchInput, setServiceGroupSearchInput] = useState("");
  const [serviceEntitySchema, setServiceEntitySchema] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [serviceData, setServiceData] = useState({
    name: "",
    imagename: "",
    description: "",
    versionTag: "",
    versionDescription: "",
    status: "",
    languageID: "",
    initializeboilerplateID: false,
    boilerplateID: "",
    projectID: "",
    servicegroupID: "",
    datamodels: [],
  });
  const [error, setError] = useState({
    name: { isError: false },
    projectID: { isError: false },
    servicegroupID: { isError: false },
    languageID: { isError: false },
  });

  const { data, getProjectList } = props;

  const projects = data?.data && data?.data?.length > 0 ? data?.data : [];
  const search = useLocation().search;


  useEffect(() => {
    if (selectedProject._id) {
      fetchServiceGroupsFromProject();
      fetchLanguagesList();
    }
  }, [selectedProject]);

  const fetchServiceGroupsFromProject = async (filters) => {
    const { _msg, _status, data } = await fetchServiceGroupListData(
      selectedProject._id,
      0,
      filters
    );
    setServiceGroups([]);
    if (_status === 200) {
      if (data) {
        setServiceGroups(data);
      } else {
        enqueueSnackbar(
          "No Service Group under this project. Please select another project",
          { variant: "info" }
        );
        setServiceGroups([]);
      }
    } else if (_status === 404) {
      enqueueSnackbar(_msg, { variant: "info" });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const fetchLanguagesList = async () => {
    const { _msg, _status, data } = await fetchLanguagesByProject(
      selectedProject._id
    );
    if (_status === 200) {
      setLanguages(data ? data : []);
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const { checkScreenPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("ServiceGroup", ["Create Microservice"]);
    const fetchServiceEntitySchema = async () => {
      const { _msg, _status, data } = await fetchResourceByPermission(
        "AITask",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setServiceEntitySchema(entity);
        const servData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                servData[`${field.datakey}_unit`] = "";
                servData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (servData[field.datakey] = "");
              }
            });
          });
        });
        setServiceData((prevData) => ({
          ...prevData,
          ...servData,
          serviceType: "Internal",
        }));
        const errData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field);
                  errData[`${field.datakey}_value`] = initError(field);
                  return true;
                }
                return (errData[field.datakey] = initError(field));
              }
            });
          });
        });
        delete errData["boilerplateID"];
        delete errData["documentation"];
        delete errData["documentationTable"];
        delete errData["initializedboilerplateID"];
        delete errData["languageUsed"];
        setError(errData);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    const fetchBoilerPlates = async () => {
      const { _msg, _status, data } = await fetchBoilerPlateListData();
      if (_status === 200) {
        setBoilerPlates(data ? data : []);
      } else {
        enqueueSnackbar(_msg, { variant: "error" });
      }
    };
    const fetchStatusesByResource = async () => {
      const { _msg, _status, data } = await fetchStatusResourceByName("AITask");
      if (_status === 200) {
        setServiceData({
          ...serviceData,
          status: data?.status.find((s) => s.default === true)?.status,
          serviceType: "Internal",
        });
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    fetchServiceEntitySchema();
    fetchBoilerPlates();
    fetchStatusesByResource();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }

    if (event.target.name === "imagename") {
      setServiceData({
        ...serviceData,
        [event.target.name]: value.toLowerCase(),
      });
    } else {
      setServiceData({
        ...serviceData,
        [event.target.name]: value,
      });
    }
  };

  useEffect(() => {
    handleProjectSearch();
  }, [projectSearchInput]);

  const handleProjectSearch = () => {
    if (projectSearchInput) {
      getProjectList(
        0,
        JSON.stringify({ name: { $regex: projectSearchInput, $options: "i" } })
      );
    } else {
      getProjectList(0);
    }
  };

  useEffect(() => {
    if (projectSearchInput) {
      handleServiceGroupSearch();
    }
  }, [serviceGroupSearchInput]);

  const handleServiceGroupSearch = () => {
    if (serviceGroupSearchInput) {
      fetchServiceGroupsFromProject(
        JSON.stringify({
          name: { $regex: serviceGroupSearchInput, $options: "i" },
        })
      );
    } else {
      fetchServiceGroupsFromProject();
    }
  };

  const handleSubmit = async () => {
    const { formIsValid, errors } = handleFormValidationPro(serviceData, error);
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      const newServiceData = {
        ...serviceData,
        initializeboilerplateID: boilerPlateChecked,
        projectID: selectedProject._id,
        servicegroupID: selectedServiceGroup._id,
      };
      newServiceData["ai_task"] = true;
      if (boilerPlateChecked) {
        newServiceData["boilerplateID"] = serviceData.boilerplateID;
        newServiceData["version"] = {
          versionTag: serviceData.versionTag,
          description: serviceData.description,
        };
      }
      setIsLoader(true);

      const { _msg, _status, data } = await addAiTask(newServiceData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments(
          "microservice",
          data._id,
          selectedDocuments,
          setSelectedDocuments
        );
        setIsLoader(false);

        histroy.push("/aitask-workbench");
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      setError(errors);
    }
  };

  const handleActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };
  const handlePrevious = () => {
    setActiveTab((prev) => prev - 1);
  };
  const handleCancel = () => {
    history.push(`/aitask-workbench`);
  };

  return (
    <main className={mystyles.main}>
      <div className={mystyles.topContent}>
        <BreadCrumbs
          items={[
            { title: "AI Task", path: "/aitask-workbench" },
            { title: "Create AI Task" },
          ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ borderBottom: "2px solid #afafaf" }}
        >
          <h2 className={mystyles.heading}>Create AI Task</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "10px 0 10px",
            }}
          >
            {activeTab !== 0 ? (
              <AppButton
                aria-label="previous"
                color="primary"
                buttonName="Previous"
                variant="contained"
                startIcon={
                  <NavigateBeforeIcon
                    style={{ width: "25px", height: "28px" }}
                  />
                }
                className="btnsmall"
                onClick={handlePrevious}
              />
            ) : (
              <></>
            )}
            {/* <AppButton
              aria-label="previous"
              color="primary"
              buttonName={activeTab === 4 ? "Host" : "Create"}
              variant="contained"
              startIcon={<AddIcon style={{ width: "25px", height: "28px" }} />}
              className="btnsmall"
            //   onClick={handleCreate}
            /> */}
            <AppButton
              aria-label="previous"
              color="primary"
              buttonName="Cancel"
              variant="contained"
              startIcon={
                <HighlightOffIcon style={{ width: "25px", height: "28px" }} />
              }
              className="btnsmall"
              onClick={handleCancel}
            />
            {activeTab === 2 ? (
              <AppButton
                aria-label="previous"
                color="primary"
                buttonName="Save"
                variant="contained"
                startIcon={
                  <SaveIcon style={{ width: "25px", height: "28px" }} />
                }
                className="btnsmall"
                onClick={handleSubmit}
              />
            ) : (
              <></>
            )}
            {activeTab !== 2 ? (
              <AppButton
                aria-label="previous"
                color="primary"
                buttonName="Next"
                variant="contained"
                startIcon={
                  <NavigateNextIcon style={{ width: "25px", height: "28px" }} />
                }
                className="btnsmall"
                onClick={handleNext}
              />
            ) : (
              <></>
            )}
          </div>
        </Grid>
        <div className={mystyles.topContentItems}>
          <div className={mystyles.topContentItem}>
            <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              indicatorColor="primary"
              textColor="primary"
              style={{ paddingTop: "10px" }}
              className={style.documentsListAccessTab}
            >
              <Tab label={`Details`} />
              <Tab label="Versions" />
              <Tab label="Documentation" />
            </Tabs>
          </div>
        </div>
        {activeTab === 0 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            {
            }

            if (section.name === "AITask_Header_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForAiTaskCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    projectSearchInput={projectSearchInput}
                    serviceGroupSearchInput={serviceGroupSearchInput}
                    selectedProject={selectedProject}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                  />
                </div>
              );
            }
          })}
        {activeTab === 1 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            {
            }

            if (section.name === "AITask_Governance_Section") {
              return (
                <div
                  key={section._id}
                  style={{ padding: "20px 18px 0", paddingLeft: "15px" }}
                >
                  <CustomFieldGroupForAiTaskCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    projectSearchInput={projectSearchInput}
                    serviceGroupSearchInput={serviceGroupSearchInput}
                    selectedProject={selectedProject}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                  />
                </div>
              );
            }
          })}
        {activeTab === 2 &&
          serviceEntitySchema &&
          serviceEntitySchema.map((section) => {
            {
            }
            if (section.name === "AITask_Documentation_Section") {
              return (
                <div key={section._id} style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupForAiTaskCreate
                    section={section}
                    serviceData={serviceData}
                    error={error}
                    projectSearchInput={projectSearchInput}
                    serviceGroupSearchInput={serviceGroupSearchInput}
                    selectedProject={selectedProject}
                    projects={projects}
                    serviceGroups={serviceGroups}
                    selectedServiceGroup={selectedServiceGroup}
                    setServiceData={setServiceData}
                    handleChange={handleChange}
                    setProjectSearchInput={setProjectSearchInput}
                    setServiceGroupSearchInput={setServiceGroupSearchInput}
                    setSelectedProject={setSelectedProject}
                    setSelectedServiceGroup={setSelectedServiceGroup}
                  />
                </div>
              );
            }
          })}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
};

const mapStateToProps = (state) => {
  const { projectsList } = state.projectReducer;
  const { data, error, loading, _totalcount } = projectsList;
  return {
    data,
    error,
    loading,
    _totalcount,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAiTaskTabbedScreen);
