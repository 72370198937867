/*eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import menuBarStyles from "./styles/TopMenuBarStyle";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { routeVisited } from "../../redux/actions/recentlyVisitedAction";
import { RELEASE_VERSION } from "../../services/helper/config";
import { alterActiveTab } from "../../redux/actions/activeTabActions";
import { connect } from "react-redux";
import useCheckPermission from "../common/hooks/useCheckPermission";
import { version } from "../../../package.json";

const menuObj = [
  [
    {
      id: 1,
      label: "Dashboard",
      children: [
        {
          resourceName: "Dashboard",
          childName: "Dashboard",
          isActive: true,
          routeName: "/dashboard",
        },
      ],
    },
    {
      id: 2,
      label: "Application Governance",
      children: [
        {
          resourceName: "Language",
          childName: "Language",
          routeName: "/application-governance",
        },
        {
          resourceName: "Boilerplate",
          childName: "Boiler Plate",
          routeName: "/application-governance",
        },
        {
          resourceName: "Template",
          childName: "Chassis Template",
          routeName: "/application-governance",
        },
      ],
    },

    {
      id: 3,
      label: "Data Standards",
      children: [
        {
          resourceName: "GlossaryTag",
          childName: "Glossary",
          routeName: "/glossary",
        },
        {
          resourceName: "GlossaryField",
          childName: "Data Dictionary",
          routeName: "/data-standards?datastandards=true",
        },

        {
          resourceName: "OntologyFieldGroup",
          childName: "Payloads",
          routeName: "/data-standards?datastandards=true",
        },
      ],
    },
    {
      id: 4,
      label: "Container Management",
      children: [
        {
          resourceName: "DockerImage",
          childName: "Docker Images",
          routeName: "/docker-images",
        },
        {
          resourceName: "Container",
          childName: "Containers",
          routeName: "/containers",
        },
        {
          resourceName: "RuntimeEnvironment",
          childName: "Runtime Environment",
          routeName: "/runtime-environments",
        },
      ],
    },
  ],

  [
    // {
    //   id: 3,
    //   label: "Application",
    //   children: [
    //     {
    //       resourceName: "Application",
    //       childName: "Application",
    //       routeName: "/application",
    //     },
    //   ],
    // },

    {
      id: 3,
      label: "Service Workbench",
      children: [
        {
          resourceName: "Project",
          childName: "Project",
          routeName: "/projects",
        },
        {
          childName: "Services",
          routeName: "/services?service=true",
          resourceName: "Microservice",
        },

        {
          resourceName: "Application",
          childName: "Service Bundles",
          routeName: "/service-bundle",
        },
        {
          resourceName: "Document",
          childName: "Documents",
          routeName: "/documents",
        },
      ],
    },
    ,
    {
      id: 12,
      label: "AI Workbench",
      children: [
        {
          resourceName: "AITask",
          childName: "AI Tasks",
          routeName: "/aitask-workbench",
        },
        {
          resourceName: "AIModel",
          childName: "AI Models",
          routeName: "/aitask-workbench",
        },
      ],
    },
    {
      id: 10,
      label: "UI Workbench",
      children: [
        {
          resourceName: "UIApplication",
          childName: "UI Application",
          routeName: "/ui-applications",
        },
        {
          childName: "UI Services",
          routeName: "/services?service=true",
          resourceName: "Microservice",
        },
      ],
    },
    // {
    //   id: 5,
    //   label: "Projects",
    //   children: [
    //     {
    //       resourceName: "Project",
    //       childName: "Project",
    //       routeName: "/projects",
    //     },

    //     {
    //       resourceName: "Application",
    //       childName: "Application",
    //       routeName: "/application",
    //     },
    //   ],
    // },
    {
      id: 13,
      label: "CI/CD Workbench",
      children: [
        {
          resourceName: "Host",
          childName: "Host",
          routeName: "/host",
        },
        {
          resourceName: "TestData",
          childName: "Test & Release",
          routeName: "/test",
        },
      ],
    },
  ],
  [

    // {
    //   id: 11,
    //   label: "Organization",
    //   children: [
    //     {
    //       resourceName: "Organization",
    //       childName: "Organization",
    //       routeName: "/organization-deatils",
    //     },
    //   ],
    // },
    {
      id: 6,
      label: "Business Partners",
      children: [
        {
          resourceName: "Vendor",
          childName: "Vendors",
          routeName: "/vendors",
        },
        {
          resourceName: "User",
          childName: "Users",
          routeName: "/users",
        },
        {
          resourceName: "Team",
          childName: "Teams",
          routeName: "/teams",
        },
      ],
    },
    // {
    //   id: 7,
    //   label: "Document",
    //   children: [
    //     {
    //       resourceName: "Document",
    //       childName: "Document List",
    //       routeName: "/documents",
    //     },
    //   ],
    // },
    {
      id: 8,
      label: "Configuration",
      children: [
        {
          resourceName: "StatusProfile",
          childName: "Status Profile",
          routeName: "/status-profile",
        },
        {
          resourceName: "Role",
          childName: "Roles Management",
          routeName: "/role-management",
        },
        {
          resourceName: "Organization",
          childName: "Organization",
          routeName: "/organization-deatils",
        },
        {
          resourceName: "OntologyResource",
          childName: "Ontology",
          routeName: "/ontology",
        },
      ],
    },
    {
      id: 9,
      label: "Help  Desk",
      children: [
        {
          resourceName: "Helpdesk",
          childName: "Help  Desk",
          routeName: "/helpdesk",
        },
      ],
    },
  ],
  // [
  // {
  //   id: 13,
  //   label: "Deploy",
  //   children: [
  //     {
  //       resourceName:"Host",
  //       childName: "Host",
  //       routeName: "/host",
  //     },
  //     {
  //       resourceName:"TestData",
  //       childName: "Test & Release",
  //       routeName: "/test",
  //     },

  //   ],
  // },
  // {
  //   id: 9,
  //   label: "Ontology",
  //   children: [
  //     {
  //       resourceName: "OntologyResource",
  //       childName: "Object",
  //       routeName: "/ontology/objects",
  //     },
  //     {
  //       resourceName: "OntologySection",
  //       childName: "Sections",
  //       routeName: "/ontology/sections",
  //     },
  //     {
  //       resourceName: "OntologyFieldGroup",
  //       childName: "Payloads",
  //       routeName: "/ontology/fieldgroups",
  //     },
  //     {
  //       resourceName: "OntologyField",
  //       childName: "Fields",
  //       routeName: "/ontology/fields",
  //     },
  //   ],
  // },
  // {
  //   id: 10,
  //   label: "Help  Desk",
  //   children: [
  //     {
  //       resourceName: "Helpdesk",
  //       childName: "Help  Desk",
  //       routeName: "/helpdesk",
  //     },
  //   ],
  // },
  // ],
];
function TopMenuBar(props) {
  const [menuList] = useState(menuObj);
  const styles = menuBarStyles();
  const history = useHistory();
  const { userActions } = useSelector((state) => state.authReducer);
  const { checkCompPermission } = useCheckPermission();

  const onMenuClick = (child) => {
    const { childName, routeName, documentsActiveTab, resourceName } = child;
    props.alterMenu(!props.showMenu);

    if (childName === "AI Tasks") {
      dispatch(alterActiveTab("aiTaskActiveTab", "AITask"));
    }

    if (childName === "Ontology") {
      dispatch(alterActiveTab("ontologyActiveTab", "object"));
    }

    if (childName === "Ontology") {
      dispatch(alterActiveTab("ontologyActiveTab", "object"));
    }

    if (childName === "Payloads") {
      dispatch(alterActiveTab("dataStandardsActiveTab", "fieldgroups"));
    }

    if (childName === "Test & Release") {
      dispatch(alterActiveTab("testActiveTab", "Execution"));
    }

    if (childName === "Dashboard") {
      dispatch(alterActiveTab("Dashboard", "Dashboard"));
    }

    if (childName === "Host") {
      dispatch(alterActiveTab("hostActiveTab", "Deployments"));
    }

    if (childName === "Data Dictionary") {
      dispatch(alterActiveTab("dataStandardsActiveTab", "datadictionary"));
    }

    if (childName === "Services") {
      dispatch(alterActiveTab("serviceActiveTab", "services"));
    }

    if (childName === "AI Models") {
      dispatch(alterActiveTab("aiTaskActiveTab", "DataModel"));
    }

    if (childName === "Language") {
      dispatch(alterActiveTab("application_governance", "language"));
    } else if (childName === "Boiler Plate") {
      dispatch(alterActiveTab("application_governance", "boilerPlate"));
    } else if (childName === "Chassis Template") {
      dispatch(alterActiveTab("application_governance", "chasisTemplate"));
    }
    dispatch(routeVisited(child));

    if (childName === "UI Services") {
      dispatch(alterActiveTab("serviceActiveTab", "uiSimulator"));
    }
    if (childName === "Documents") {
      dispatch(alterActiveTab("documentsActiveTab", "projects"));
    }
    if (resourceName === "Microservice") {
      const serviceListPermission = checkCompPermission("Microservice", [
        "List All",
        "List Assigned",
      ]);
      const fieldgroupListPermission = checkCompPermission(
        "GlossaryFieldGroup",
        ["List All", "List Assigned"]
      );
      if (serviceListPermission && !fieldgroupListPermission) {
        props.alterActiveTab("serviceActiveTab", "services");
      } else if (!serviceListPermission && fieldgroupListPermission) {
        props.alterActiveTab("serviceActiveTab", "fieldgroups");
      }
    }

    if (
      resourceName === "Microservice" ||
      "ServiceGroup" ||
      "Project" ||
      "Document"
    ) {
      const projectListPermission = checkCompPermission("Project", [
        "List All",
        "List Assigned",
      ]);
      const serviceGroupListPermission = checkCompPermission("ServiceGroup", [
        "List All",
        "List Assigned",
      ]);
      const serviceListPermission = checkCompPermission("Microservice", [
        "List All",
        "List Assigned",
      ]);
      const standardDocumentPermission = checkCompPermission("Document", [
        "List All",
        "List Assigned",
      ]);

      if (
        projectListPermission &&
        !(
          serviceGroupListPermission &&
          serviceListPermission &&
          standardDocumentPermission
        )
      ) {
        props.alterActiveTab("documentsActiveTab", "projects");
      } else if (
        serviceGroupListPermission &&
        !(
          projectListPermission &&
          serviceListPermission &&
          standardDocumentPermission
        )
      ) {
        props.alterActiveTab("documentsActiveTab", "serviceGroups");
      } else if (
        serviceListPermission &&
        !(
          projectListPermission &&
          serviceGroupListPermission &&
          standardDocumentPermission
        )
      ) {
        props.alterActiveTab("documentsActiveTab", "service");
      } else if (
        standardDocumentPermission &&
        !(
          projectListPermission &&
          serviceGroupListPermission &&
          serviceListPermission
        )
      ) {
        props.alterActiveTab("documentsActiveTab", "standardDocuments");
      }
    }

    history.push(routeName);
  };

  const dispatch = useDispatch();

  const recentVisitedRoutes = useSelector(
    (state) => state?.viewedReducer?.recentVisited
  );

  let recentTrackedRoutes = recentVisitedRoutes?.reduce((filtered, item) => {
    if (
      !filtered.some(
        (filteredItem) => JSON.stringify(filteredItem) === JSON.stringify(item)
      )
    ) {
      filtered.push(item);
    }
    return filtered;
  }, []);

  let tempRecentVisitedRoutes = [];
  if (recentTrackedRoutes.length > 5) {
    tempRecentVisitedRoutes = recentTrackedRoutes.filter(
      (item, index) => index < 5
    );
  } else if (recentTrackedRoutes.length <= 5) {
    tempRecentVisitedRoutes = recentTrackedRoutes;
  }

  const recentVisitedRouteClicked = (childName, routeName) => {
    props.alterMenu(!props.showMenu);
    if (childName === "Language") {
      dispatch(alterActiveTab("application_governance", "language"));
    } else if (childName === "Boiler Plate") {
      dispatch(alterActiveTab("application_governance", "boilerPlate"));
    } else if (childName === "Chassis Template") {
      dispatch(alterActiveTab("application_governance", "chasisTemplate"));
    } else if (childName === "UI Simulator") {
      dispatch(alterActiveTab("serviceActiveTab", "uiSimulator"));
    } else if (childName === "Ontology") {
      dispatch(alterActiveTab("ontologyActiveTab", "object"));
    } else if (childName === "Services") {
      dispatch(alterActiveTab("serviceActiveTab", "services"));
    } else if (childName === "Payloads") {
      dispatch(alterActiveTab("dataStandardsActiveTab", "fieldgroups"));
    } else if (childName === "AI Models") {
      dispatch(alterActiveTab("aiTaskActiveTab", "DataModel"));
    } else if (childName === "AI Tasks") {
      dispatch(alterActiveTab("aiTaskActiveTab", "AITask"));
    } else if (childName === "Data Dictionary") {
      dispatch(alterActiveTab("dataStandardsActiveTab", "datadictionary"));
    } else if (childName === "Test & Release") {
      dispatch(alterActiveTab("testActiveTab", "Execution"));
    } else if (childName === "Host") {
      dispatch(alterActiveTab("hostActiveTab", "Deployments"));
    } else if (childName === "UI Services") {
      dispatch(alterActiveTab("serviceActiveTab", "uiSimulator"));
    } else if (childName === "Documents") {
      dispatch(alterActiveTab("documentsActiveTab", "projects"));
    }
    history.push(routeName);
  };

  return (
    <div
      className={styles.container}
      onMouseLeave={() => props.showMenu && props.setShowMenu(false)}
    >
      <Grid container spacing={0} style={{ marginTop: "0rem" }}>
        <Grid item xs={3} sm={3}>
          <div className={styles.sideBarMenuContent}>
            <p className={styles.menuItemLabel} style={{ marginLeft: 0 }}>
              Recently Visited
            </p>

            {tempRecentVisitedRoutes.length > 0 &&
              tempRecentVisitedRoutes.map((mappedData, index) => {
                const { childName, routeName, resourceName } = mappedData;
                return (
                  <div key={index}>
                    <p
                      key={index}
                      className={styles.menuItemLabel1}
                      style={{ marginLeft: 0 }}
                      onClick={() =>
                        recentVisitedRouteClicked(childName, routeName)
                      }
                    >
                      {childName}
                    </p>
                  </div>
                );
              })}
          </div>
        </Grid>
        <Grid item xs={9} sm={9}>
          <Grid container spacing={0}>
            <Grid item xs={3} sm={3}>
              <div className={styles.contTable}>
                {menuList.map((data, index) => {
                  return (
                    <div className={styles.perContWrapper} key={index}>
                      <div className={styles.perCont}>
                        {data.map((item, id) => {
                          return (
                            <div key={id}>
                              <p
                                className={
                                  item.label.length <= 9
                                    ? styles.menuItemLabel
                                    : item.label.length <= 14
                                      ? styles.menuItemMediumLengthLabels
                                      : styles.menuItemLongLengthLabels
                                }
                              >
                                {item.label}
                              </p>

                              {item.children.map((child, ind) => {
                                const { childName, resourceName } = child;
                                return (
                                  <div key={ind}>
                                    {Object.keys(userActions).find(
                                      (claim) =>
                                        ((claim === resourceName ||
                                          resourceName === "Dashboard") &&
                                          (resourceName === "Dashboard" ||
                                            (resourceName == "Organization" &&
                                              checkCompPermission(
                                                resourceName,
                                                ["Read"]
                                              )) ||
                                            checkCompPermission(resourceName, [
                                              "List All",
                                              "List Assigned",
                                            ]))) ||
                                        (claim === "GlossaryFieldGroup" &&
                                          resourceName === "Microservice" &&
                                          checkCompPermission(
                                            "GlossaryFieldGroup",
                                            ["List All", "List Assigned"]
                                          ))
                                    ) ? (
                                      <div onClick={() => onMenuClick(child)}>
                                        <p className={styles.menuItemLabel1}>
                                          {childName}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p
                                          className={
                                            styles.menuItemdisableLabel1
                                          }
                                        >
                                          {childName}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                      {index === 0 ? (
                        <div className={styles.hrBorderZeroIndex} />
                      ) : index === 1 ? (
                        <div className={styles.secondrowDivider} />
                      ) : index === 2 ? (
                        <div className={styles.hrBorderFirstIndex} />
                      ) : index === 3 ? null : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ position: "relative", top: "5rem" }}>
        <span>v{`${RELEASE_VERSION}`}</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  alterActiveTab,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuBar);
