import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import manageScreenStyles from "../../CommonStyles/manageScreenStyles";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { AppButton } from "../../../components/common/Button";
import { ArrowDropDownRounded } from "@material-ui/icons";
import {
  getUserDetailsByID,
  getUserTeamDetails,
} from "../../../utils/users/userUtils";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import UserDetailsEntitySchemaHelper from "../../../components/common/entitySchema/users/UserDetailsEntityScheamaHelper";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";
import SectionTabs from "../../../components/Tabbed/SectionTabs";

const UserDetailsScreen = (props) => {
  const styles = manageScreenStyles();
  const histroy = useHistory();
  const [userData, setUserData] = useState({});
  const [error, setError] = useState({});
  const [userID] = useState(props.match.params._id);
  const { enqueueSnackbar } = useSnackbar();
  const [userteamDetails, setUserteamDetails] = useState();
  const [loading, setLoading] = useState(false);

  const [userEntitySchema, setUserEntitySchema] = useState([]);


  // Accessing TabbedView
  const { tabbedView } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    histroy.push("/users");
  };

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();
  useEffect(() => {
    checkScreenPermission("User", ["Read"]);
  }, []);

  useEffect(() => {
    const fetchUserDetailsEntity = async () => {
      setLoading(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "User",
        "read"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setUserEntitySchema(entity);
        fetchData();
        const tempUserData = {};
        const tempError = {};
        data?.sections?.forEach((section) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (
                field.uiElementType === "Currency" ||
                field.uiElementType === "Amount"
              ) {
                tempUserData[`${field.datakey}_unit`] = "";
                tempUserData[`${field.datakey}_value`] = "";
              }
            });
          });
        });
        data?.sections?.forEach((section) => {
          section?.fieldgroups?.forEach((fieldgroup) => {
            fieldgroup?.fields?.forEach((field) => {
              if (field.required === "TRUE") {
                tempError[field.datakey] = {
                  isError: false,
                  fieldLabel: field.fieldLabel,
                  minLength: field.rules?.minLength
                    ? field.rules?.minLength
                    : 1,
                  maxLength: field.rules?.maxLength
                    ? field.rules?.maxLength
                    : 300,
                  pattern: field.rules?.pattern,
                  msg: "",
                };
              }
            });
          });
        });
        setUserData({ ...userData, ...tempUserData });
        setError({ ...error, ...tempError });
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getUserDetailsByID(userID);
      if (_status < 210) {
        setUserData({ ...userData, ...data });
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    fetchUserDetailsEntity();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { _msg, _status, data } = await getUserTeamDetails(userID);
      if (_status === 200) {
        setUserteamDetails(() => (data ? data : []));
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    fetchData();
  }, [userID]);

  const handleEdit = () => {
    if (userData?.status === "inactive") {
      enqueueSnackbar("Cannot edit a Blocked User. Kindly unblock first.", {
        variant: "error",
      });
    } else {
      histroy.push(`/edit-user/${userID}`);
    }
  };
  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Users", path: "/users" },
              { title: `${userData?.name ? userData?.name : ""}` },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>{userData?.name}</h2>
            {tabbedView ? (
              <TabbedNavigation
                isClose={true}
                isEditMode={false}
                activeTab={activeTab}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
              />
            ) : (
              <AccordionNavigation
                isClose={true}
                handleEdit={handleEdit}
                disabled={!checkCompPermission("User", ["Edit"])}
                handleCancel={handleCancel}
                isEditMode={true}
              />
            )}
          </Grid>
          {/* <div className={styles.border_line} /> */}
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (<>
            <SectionTabs
              entity={
                userEntitySchema ? userEntitySchema : []
              }
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            <Paper style={{ padding: 20, paddingTop: 10, margin: "10px" }}>
              <div style={{ padding: "0px 0px 0" }}>
                <UserDetailsEntitySchemaHelper
                  section={userEntitySchema?.[activeTab]}
                  userData={userData}
                  userteamDetails={userteamDetails}
                />
              </div>
            </Paper>
          </>) : (userEntitySchema?.length !== 0 &&
            userEntitySchema?.map((section) => {
              if (section.name === "User_Header_section") {
                return (
                  <div key={section._id} style={{ padding: "0px 5px 0" }}>
                    <UserDetailsEntitySchemaHelper
                      section={section}
                      userData={userData}
                      userteamDetails={userteamDetails}
                    />
                  </div>
                );
              } else {
                return (
                  <Accordion
                    className={styles.fieldPanel}
                    defaultExpanded={true}
                    key={section._id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownRounded
                          className={styles.accordianIcon}
                        />
                      }
                    >
                      <Typography className={styles.sectionHeading}>
                        {section["section Label"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UserDetailsEntitySchemaHelper
                        section={section}
                        userData={userData}
                        userteamDetails={userteamDetails}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              }
            }))}
        </div>
      </form>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { tabbedView } = state.userDetails.userInfo;
  return { tabbedView };
};

export default connect(mapStateToProps)(UserDetailsScreen);
