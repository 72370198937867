import { dockerImageServices } from "../../services/dockerImageServices";
import { ContainerServices } from "../../services/containerServices";

const DockerService = new dockerImageServices();
const ContainerService = new ContainerServices();

export const fetchAllImages = async () => {
  try {
    const res_data = await DockerService.getdockerImageList();
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getContainerStatusByName = async (versionId) => {
  try {
    const res_data = await ContainerService.getContainerStatusByName(versionId);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createContainer = async (req_data) => {
  try {
    const res_data = await ContainerService.createContainer(req_data);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const getContainersforCodeEditor = async () => {
  try {
    const res_data = await ContainerService.getContainersforCodeEditor();
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};


export const getContainerDetailsByID = async (conID) => {
  try {
    const res_data = await ContainerService.getContainerDetailsByID(conID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const editContainerByID = async (conID, EditcontainerData) => {
  try {
    const res_data = await ContainerService.editContainerByID(
      conID,
      EditcontainerData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const DeleteContainer = async (conID) => {
  try {
    const res_data = await ContainerService.DeleteContainer(conID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const StartContainer = async (conID) => {
  try {
    const res_data = await ContainerService.StartContainer(conID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const StopContainer = async (conID) => {
  try {
    const res_data = await ContainerService.StopContainer(conID);
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
