import { TRACK_VISITED_ROUTES,DELETE_VISITED_ROUTES} from "../actionTypes";

const recentlyVisitedData = {
  recentVisited: [],
};

export default function viewedReducer(state = recentlyVisitedData, action) {
  switch (action.type) {
    case TRACK_VISITED_ROUTES: {
      let trackRecentVisited = state.recentVisited.slice(0, 5);

      let recentTrackedRoutes = trackRecentVisited?.reduce((filtered, item) => {
        if (
          !filtered.some(
            (filteredItem) =>
              JSON.stringify(filteredItem) === JSON.stringify(item)
          )
        ) {
          filtered.push(item);
        }
        return filtered;
      }, []);

      return {
        recentVisited: [
          action.payload,
          ...recentTrackedRoutes.filter(
            (recentVisited) => recentVisited !== action.payload
          ),
        ],
      };
    }
    case DELETE_VISITED_ROUTES:{
      return {
        ...state,
        recentVisited:[]
      }

    }
    default:
      return state;
  }
 
}
