const clusterReplicaSetsColumns = [
  // { name: 'id', title: 'Service ID' },
  { name: "name", title: "Name", for: "clusterReplicaSets" },
  {
    name: "applicationname",
    title: "Application Name",
    for: "clusterReplicaSets",
  },
  { name: "namespace", title: "Namespace", for: "clusterReplicaSets" },
  { name: "status", title: "Pods", for: "clusterReplicaSets" },

  { name: "creationTimestamp", title: "Created", for: "clusterReplicaSets" },
  { name: "image", title: "Image", for: "clusterReplicaSets" },
  { name: "labels", title: "Labels", for: "clusterReplicaSets" },
];

const clusterReplicaSetsDefaultColumnWidths = [
  // { columnName: 'id', width: 250 },
  { columnName: "name", weightage: -1.5, width: 200 },
  { columnName: "namespace", weightage: -8.5, width: 200 },
  { columnName: "status", weightage: 1.5, width: 200 },
  { columnName: "creationTimestamp", weightage: -3.5, width: 200 },
  { columnName: "applicationname", weightage: -3.5, width: 200 },
  { columnName: "image", weightage: 2.5, width: 200 },
  { columnName: "labels", weightage: 2.5, width: 200 },
];

const clusterReplicaSetsTableColumnExtensions = [
  // { columnName: 'id', align: 'left' },
  { columnName: "name", align: "left" },
  { columnName: "namespace", align: "left" },
  { columnName: "status", align: "left" },

  { columnName: "created", align: "left" },
  { columnName: "applicationname", align: "left" },
  { columnName: "image", align: "left" },
  { columnName: "labels", align: "left" },
];

const clusterReplicaSetsColumnOrder = [
  // 'id',
  // "name",
  // "namespace",
  // "status",
  // "node",
  // "applicationname",
  // "image",
  // "created",
  // "labels"
];

const clusterReplicaSetsColumnHidden = [
  // 'id',
  // "name",
  // "namespace",
  // "ReplicaSets",
  // "creationTimestamp",
  "namespace",
  "image",

  "labels",
];

const clusterReplicaSetsDefaultSorting = [];

export {
  clusterReplicaSetsColumns,
  clusterReplicaSetsDefaultColumnWidths,
  clusterReplicaSetsTableColumnExtensions,
  clusterReplicaSetsColumnOrder,
  clusterReplicaSetsDefaultSorting,
  clusterReplicaSetsColumnHidden,
};
