import { ADD_AITASK_LISTS, AITASK_FILTERS, AITASK_SORT, ALTER_FAVOURITE_AI_TASK_BY_ID, ALTER_SUBSCRIBE_AI_TASK_BY_ID, DELETE_AITASK_BY_ID, GET_DATA_MODELS_DATA_FOR_AITASK, GET_FUNCTIONS_FOR_AITASK,EDIT_AITASK_VERSION,EDIT_AITASK_API,SET_SELECTED_AITASK_VERSIONID,SET_SELECTED_AITASK_APIID,CLOSE_AITASK_VERSION,CLOSE_AITASK_API, SET_ONLY_VERSIONID, TABBED_AITASK_ACTIVE_TAB} from '../actionTypes'
import { MicroServicesAPIServices } from '../../services/microservices';
import { aiTaskService } from '../../services/aiTaskService';

const aiTaskServiceApi = new aiTaskService();
const microserviceAPIServices = new MicroServicesAPIServices();

const add_ser_data = (payload) => {
    return {
        type: ADD_AITASK_LISTS,
        payload: payload,
    };
}

export const tabbedAITaskActiveTab=(payload)=>{
    return {
        type:TABBED_AITASK_ACTIVE_TAB,
        payload:payload
    }
}

const add_datamodels_data = (payload) => {
    return {
        type: GET_DATA_MODELS_DATA_FOR_AITASK,
        payload: payload,
    };
}

const add_functions_data = (payload) => {
    return {
        type: GET_FUNCTIONS_FOR_AITASK,
        payload: payload,
    };
}

export const addAiTaskSort = (aiTaskSort) => {
    return {
        type: AITASK_SORT,
        payload: aiTaskSort
    }
};

export const addAITaskFilters = (aiTaskFilters) => {
    return {
        type: AITASK_FILTERS,
        payload: aiTaskFilters
    }
};

// const add_api_data = (payload) => {
//     return {
//         type: ADD_API_LIST_DATA,
//         payload: payload,
//     };
// }

export const fetchAllAiTaskList = async () => {
    try {
        const res_data = await aiTaskServiceApi.fetchAllAiTaskList();
        const { _msg, _status, data } = res_data.data;
        return {
            _status,
            data,
            _msg,
        };
    } catch (err) {
        return {
            _msg: err?.response?.data?._msg,
            _status: err?.response?.data?._status,
            data: err?.response?.data?.data,
        };
    }
};

export const getAITasksList = (currentPage, filter, sort) => async (dispatch, getState) => {
    const { userPersonal: { userFavourites, userSubscriptions } } = getState()

    try {
        dispatch(add_ser_data({
            loading: true
        }))
        const { data } = await aiTaskServiceApi.getAiTaskList(currentPage, filter, sort);
        if (data) {
            dispatch(
                add_ser_data({
                    loading: false,
                    data: {
                        data: data.data.length !== 0 && data.data.map(serv => {
                            const checkFav = userFavourites.filter(fav => fav.resourceinstance === serv._id).length !== 0
                            let tempProj = serv;

                            if (checkFav) {
                                tempProj['isFavourite'] = true
                            } else {
                                tempProj['isFavourite'] = false
                            }
                            const checkSubscribe = userSubscriptions.filter(sub =>
                                sub.name.split('_')[0] === "service" &&
                                sub.name.split('_')[1] === serv._id).length !== 0
                            if (checkSubscribe) {
                                tempProj['isSubscribe'] = true
                            } else {
                                tempProj['isSubscribe'] = false
                            }
                            return tempProj
                        })
                    },
                    error: false,
                    _totalcount: data._totalcount
                })
            );
        } else {
            dispatch(
                add_ser_data({
                    loading: false,
                    data: [],
                    error: false,
                })
            );
        }
    } catch (error) {
        dispatch(
            add_ser_data({
                loading: false,
                data: [],
                error: true,
            })
        );
    }
}


export const getDataModelsListForAiTask = () => async (dispatch, getState) => {

    try {
        dispatch(add_datamodels_data({
            loading: true
        }))
        const { data } = await aiTaskServiceApi.getDataModels();
        if (data) {
            dispatch(
                add_datamodels_data(data.data)
            );
        } else {
            dispatch(
                add_datamodels_data({
                    loading: false,
                    data: [],
                    error: false,
                })
            );
        }
    } catch (error) {
        dispatch(
            add_datamodels_data({
                loading: false,
                data: [],
                error: true,
            })
        );
    }
}


export const getFunctionListForAiTask = (foldername, path) => async (dispatch, getState) => {


    try {
        dispatch(add_functions_data({
            loading: true
        }))

        const { data } = await aiTaskServiceApi.getFunctionsList(foldername, path);
        if (data) {
            dispatch(
                add_functions_data(data.data)
            );
        } else {
            dispatch(
                add_functions_data({
                    loading: false,
                    data: [],
                    error: false,
                })
            );
        }
    } catch (error) {

        dispatch(
            add_datamodels_data({
                loading: false,
                data: [],
                error: true,
            })
        );
    }
}

export const alterFavouriteAITaskById = (AITaskId) => {
    return {
        type: ALTER_FAVOURITE_AI_TASK_BY_ID,
        payload: {
            AITaskId
        }
    }
};

export const alterSubscribeAITaskById = (AITaskId) => {
    return {
        type: ALTER_SUBSCRIBE_AI_TASK_BY_ID,
        payload: {
            AITaskId
        }
    }
};

export const deleteAITaskById = (AITaskId) => {
    return {
        type: DELETE_AITASK_BY_ID,
        payload: {
            AITaskId
        }
    }
};


export const editAITaskVersionTabbed = (id) => {
    return {
      type: EDIT_AITASK_VERSION,
      payload: id,
    };
  };

  export const editAITaskAPITabbed = (id) => {
    return {
      type: EDIT_AITASK_API,
      payload: id,
    };
  };


  export const setSelectedAITaskVersionId = (id) => {
    return {
      type: SET_SELECTED_AITASK_VERSIONID,
      payload: id,
    };
  };

  export const setSelectedAITaskAPIId = (id) => {
    return {
      type: SET_SELECTED_AITASK_APIID,
      payload: id,
    };
  };

  export const closeAITaskVersion = () => {
    return {
      type: CLOSE_AITASK_VERSION,
    };
  };

  export const closeAITaskAPI = () => {
    return {
      type: CLOSE_AITASK_API,
    };
  };

  export const setOnlyVersionId=(id)=>{
    return {
        type:SET_ONLY_VERSIONID,
        payload:id
    }
  }
  
// export const addServiceFilters = (serviceFilters) => {
//     return {
//         type: SERVICE_FILTERS,
//         payload: serviceFilters
//     }
// };

// export const deleteServiceById = (serviceId) => {
//     return {
//         type: DELETE_SERVICE_BY_ID,
//         payload: {
//             serviceId
//         }
//     }
// };

// export const addServiceSort = (serviceSort) => {
//     return {
//         type: SERVICE_SORT,
//         payload: serviceSort
//     }
// };

// export const addServiceHidden = (serviceHidden) => {
//     return {
//         type: SERVICE_HIDDEN,
//         payload: serviceHidden
//     }
// };

// export const alterFavouriteServiceById = (serviceId) => {
//     return {
//         type: ALTER_FAVOURITE_SERVICE_BY_ID,
//         payload: {
//             serviceId
//         }
//     }
// };

// export const clearServices = () => {
//     return {
//         type: CLEAR_SERVICES
//     }
// };

// export const getVersionList = (serviceId, currentPage, sort) => async (dispatch) => {
//     try {
//         dispatch(add_vers_data({
//             loading: true
//         }))
//         const { data } = await microserviceAPIServices.getAllVersions(serviceId, currentPage, JSON.stringify({}), sort);
//         if (data) {
//             dispatch(
//                 add_vers_data({
//                     loading: false,
//                     data: data,
//                     error: false,
//                     _totalcount: data._totalcount
//                 })
//             );
//         } else {
//             dispatch(
//                 add_vers_data({
//                     loading: false,
//                     data: [],
//                     error: false,
//                 })
//             );
//         }
//     } catch (error) {
//         dispatch(
//             add_vers_data({
//                 loading: false,
//                 data: [],
//                 error: true,
//             })
//         );
//     }
// }

// export const deleteVersionById = (versionId) => {
//     return {
//         type: DELETE_VERSION_BY_ID,
//         payload: {
//             versionId
//         }
//     }
// };

// export const addVersionSort = (versionSort) => {
//     return {
//         type: VERSION_SORT,
//         payload: versionSort
//     }
// };

// export const addVersionHidden = (versionHidden) => {
//     return {
//         type: VERSION_HIDDEN,
//         payload: versionHidden
//     }
// };

// export const toggleVersionLoading = (loading) => {
//     return {
//         type: VERSION_LOADING,
//         payload: loading
//     }
// };

// export const clearVersions = () => {
//     return {
//         type: CLEAR_VERSION
//     }
// };

// export const getAPIList = (serviceId, versionId, currentPage, sort) => async (dispatch) => {
//     try {
//         dispatch(add_api_data({
//             loading: true
//         }))
//         const { data } = await microserviceAPIServices.getAllAPIs(serviceId, versionId, currentPage, sort);
//         if (data) {
//             dispatch(
//                 add_api_data({
//                     loading: false,
//                     data: data,
//                     error: false,
//                     _totalcount: data._totalcount
//                 })
//             );
//         } else {
//             dispatch(
//                 add_api_data({
//                     loading: false,
//                     data: [],
//                     error: false,
//                 })
//             );
//         }
//     } catch (error) {
//         dispatch(
//             add_api_data({
//                 loading: false,
//                 data: [],
//                 error: true,
//             })
//         );
//     }
// }

// export const deleteAPIById = (apiId) => {
//     return {
//         type: DELETE_API_BY_ID,
//         payload: {
//             apiId
//         }
//     }
// };

// export const addAPISort = (apiSort) => {
//     return {
//         type: API_SORT,
//         payload: apiSort
//     }
// };

// export const addAPIHidden = (apiHidden) => {
//     return {
//         type: API_HIDDEN,
//         payload: apiHidden
//     }
// };

// export const clearAPIs = () => {
//     return {
//         type: CLEAR_API
//     }
// };
