export const glossaryColumns = [
  { name: "name", title: "Name", route: "/glossary-details", for: "glossary" },
  { name: "key", title: "Business Key", for: "glossary" },
  { name: "createdon", title: "Created On", for: "glossary" },
  { name: "created By", title: "Created By", for: "glossary" },
  { name: "actions", title: "Actions", for: "glossary" },
];

export const glossaryDefaultColumnWidths = [
  { columnName: "name", width: 250, weightage: 2.5 },
  { columnName: "key", width: 200, weightage: 2.5 },
  { columnName: "createdon", width: 220, weightage: 3 },
  { columnName: "created By", width: 180, weightage: 2.5 },
  { columnName: "actions", width: 180, weightage: 2.5 },
];

export const glossaryTableColumnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "key", align: "left" },
  { columnName: "createdon", align: "left" },
  { columnName: "created By", align: "left" },
  { columnName: "actions", align: "left" },
];

export const glossaryColumnOrder = [
  "name",
  "key",
  "createdon",
  "created By",
  "actions",
];

export const glossaryDefaultSorting = [
  { columnName: "name", direction: "asc" },
];
