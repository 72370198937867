import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Fade,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Loader from "../../../components/common/stuff/Loader";
import {
  addCloudClusterDetails,
  addClusterList,
  fetchAllDeployment,
  addChartValue,
  showMyChart,
  add_Cluster_Validity,
  remove_Cluster_Validity,
  addNodesUsageData,
  addNodesListData,
} from "../../../redux/actions/deploymentProcessAction";
import {
  getAllClusterList,
  getNodesList,
  getNodeUsageDetails,
} from "../../../utils/deployment/deploymentUtils";
import { addSelectedCluster } from "../../../redux/actions/deploymentProcessAction";
import { featchAllCloudConfigurationActions } from "../../../redux/actions/organizationAction";

import { Doughnut } from "react-chartjs-2";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import CpuChart from "./CpuChart";
import MemoryChart from "./MemoryChart";
import PodsChart from "./PodsChart";

const CreateDeploymentHosting = (props) => {
  const {
    clusterList,
    addSelectedCluster,
    setSelectedClusterValid,
    clustername,
    configurationname,
    setClusterError,
    clusterError,
    cloudError,
    setCloudError,
    addCloudClusterDetails,
    featchAllCloudConfigurationActions,
    data,
    hostingData,
    addClusterList,
    addChartValue,
    chart,
    showMyChart,
    showChart,
    add_Cluster_Validity,
    clusterValidity,
    remove_Cluster_Validity,
    addNodesUsageData,
    nodesUsage,
    addNodesListData,
  } = props;
  const styles = manageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({
    name: { isError: false },
  });

  const [clusterListing, setClusterListing] = useState();
  const [configListing, setConfigListing] = useState([]);
  const [configSelected, setConfigSelected] = useState();
  const [clusterSelected, setClusterSelected] = useState();
  const [cloudConfigDetailsList, setCloudConfigDetailsList] = useState();
  const [selectedClusterDetails, setselectedClusterDetails] = useState();
  const [cloudConfigRows, setCloudConfigRows] = useState([]);
  const [cloudConfigId, setCloudConfigId] = useState();
  const [selectedCluster, setSelectedCluster] = useState();

  const [pods, setPods] = useState({});
  const [cloudConfigValid, setCloudConfigValid] = useState(false);
  const [awsError, setawsError] = useState();
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isChartError, setIsChartError] = useState();

  useEffect(() => {
    featchAllCloudConfigurationActions();
  }, []);

  useEffect(() => {
    setConfigListing(data && data?.length > 0 ? data?.map((item) => item) : []);
  }, [data]);

  useEffect(() => {
    setCloudConfigRows(data && data?.length > 0 && data?.map((item) => item));
  }, [data]);

  useEffect(async () => {
    if (configSelected) {
      const fetchClusterListForDropDown = async (configSelected) => {
        setIsLoader(true);
        const { data, _status, _msg } = await getAllClusterList(
          configSelected?._id
        );

        if (_status === 200) {
          setIsLoader(false);
          add_Cluster_Validity();
          setCloudConfigValid(true);
          setawsError(false);

          setClusterListing(data);
          addClusterList(data);

          setCloudConfigValid(true);
          setawsError(false);
        } else {
          setCloudConfigValid(false);
          remove_Cluster_Validity();
          setawsError(true);
          enqueueSnackbar(
            `Your authentication information is incorrect. Please check your cloud configuration`,
            {
              variant: "error",
            }
          );
          setIsLoader(false);
          setClusterListing([]);
          setSelectedClusterValid(false);
        }
      };
      fetchClusterListForDropDown(configSelected);
    }
  }, [configSelected]);

  const handleChange = (event) => {
    setIsChartLoading(true);
    const value = event.target.value;
    const clusterUpdate = clusterList.filter((item) => item === value);
    setSelectedCluster(value);
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    const getClusterDetails = async () => {
      cloudConfigDetailsList?.map((item) => {
        if (item.clustername === value) {
          setselectedClusterDetails(item);

          addCloudClusterDetails(item);
        }
      });
    };
    getClusterDetails();
    addSelectedCluster({ clustername: clusterUpdate[0] });
    showMyChart();
    setClusterSelected(clustername);
    if (selectedClusterDetails) {
    }
    setSelectedClusterValid(true);
    setClusterError(false);

    const handleNodesList = async () => {
      const { data, _status, _msg } = await getNodesList(
        hostingData?.configurationname?._id,
        value
      );
      if (_status === 200) {
        handleNodesUsageFirstTime(value, data[0]?.Name);
        addNodesListData(data);
        setIsChartError(false);
      } else {
        setIsChartError(_msg);
        addNodesListData([]);
      }
      setIsChartLoading(false);
    };

    handleNodesList();
  };

  const handleNodesUsageFirstTime = async (Clustername, nodeName) => {
    const { data, _status,_msg } = await getNodeUsageDetails(
      hostingData?.configurationname?._id,
      Clustername,
      nodeName
    );
    let serializeData;
    if (_status === 200) {
      serializeData = { ...data, Name: nodeName };
      addNodesUsageData(serializeData);
    } else {
      serializeData = { isError: true, msg: _msg, Name: nodeName };
      addNodesUsageData(serializeData);
    }
  };

  const handleNodesUsage = async (nodeName) => {
    setIsLoader(true);
    const { data, _status, _msg } = await getNodeUsageDetails(
      hostingData?.configurationname?._id,
      hostingData?.clustername,
      nodeName
    );
    if (_status === 200) {
      let serializeData = { ...data, Name: nodeName };
      addNodesUsageData(serializeData);
    } else {
      let serializeData = { isError: true, msg: _msg, Name: nodeName };
      addNodesUsageData(serializeData);
    }
    setIsLoader(false);
  };

  const handleConfigChange = (event) => {
    const value = event.target.value;
    const cloudConfigId = event.target.value;
    setCloudConfigId(cloudConfigId);
    const selectedCloudConfig = configListing.filter(
      (item) => item._id === cloudConfigId
    );

    setConfigSelected(selectedCloudConfig[0]);
    addSelectedCluster({
      configurationname: selectedCloudConfig[0],
      clustername: "",
    });
    setSelectedClusterValid(false);
    setCloudError(false);
    setClusterError(false);
  };

  return (
    <main>
      {isLoader && <Loader />}
      <div>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0rem", width: "100%", display: "flex" }}
        >
          <Grid item xs={2} sm={3}>
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                Cloud Configuration
                <span className={styles.red}>*</span>
              </label>
              <Select
                value={hostingData?.configurationname?._id}
                onChange={handleConfigChange}
                className={`${styles.select} ${
                  (awsError || cloudError) && styles.error
                }`}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {configListing?.map((item) => {
                  return (
                    <MenuItem value={item._id}>
                      {item?.configurationname}
                    </MenuItem>
                  );
                })}
              </Select>
              {awsError && (
                <p className={styles.errorMsg}>
                  Cloud Configuration is Invalid
                </p>
              )}
              {cloudError && (
                <p className={styles.errorMsg}>
                  Please select Cloud Configuration
                </p>
              )}
            </>
          </Grid>
          <Grid item xs={2} sm={3}>
            {clusterValidity && clusterList && clusterList.length > 0 && (
              <>
                <label htmlFor="" className={styles.fieldHeading}>
                  Cluster
                  <span className={styles.red}>*</span>
                </label>
                <Select
                  value={hostingData?.clustername}
                  onChange={handleChange}
                  className={`${styles.select} ${
                    (clusterError || awsError) && styles.error
                  }`}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {clusterList?.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>

                {clusterError && (
                  <p className={styles.errorMsg}>Please select Cluster Type</p>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 40,
          }}
        >
          {!isChartLoading && isChartError && (
            <Alert severity="error">{isChartError}</Alert>
          )}
          <Fade
            in={isChartLoading}
            style={{
              transitionDelay: isChartLoading ? "400ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress color="primary" />
          </Fade>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isChartLoading && <p>Loading Nodes usage...</p>}
        </div>

        {showChart && (
          <>
            {nodesUsage.map((node, index) => (
              <>
                <Accordion
                  className={styles.fieldPanel}
                  key={node?.Name}
                  defaultExpanded={index === 0 && true}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      handleNodesUsage(node?.Name);
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRounded className={styles.accordianIcon} />
                    }
                  >
                    <Typography className={styles.sectionHeading}>
                      {node?.Name}
                    </Typography>
                  </AccordionSummary>
                  {(node?.Capacity ||
                    node?.isError) && (
                      <AccordionDetails style={{ paddingLeft: 5 }}>
                        <Grid
                          style={{
                            width: "90vw",
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {node?.isError && (
                            <Alert severity="error">{node?.msg}</Alert>
                          )}
                          {node?.Capacity && (
                            <>
                              <Paper
                                key={node?.Name}
                                style={{
                                  padding: "20px",
                                  border: "2px #FFFFF",
                                  borderRadius: "0px",
                                }}
                              >
                                {node?.Capacity && <CpuChart node={node} />}

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p>
                                    Allocatable :{" "}
                                    <b>
                                      {Math.round(
                                        node?.Capacity?.CPU * 1000 -
                                          (node?.Capacity?.CPU * 1000 -
                                            node?.Allocatable?.CPU +
                                            node?.Usage?.CPU / 1000000)
                                      )}{" "}
                                      m{" "}
                                    </b>
                                  </p>
                                  <p>
                                    Used :{" "}
                                    <b>
                                      {Math.round(
                                        node?.Capacity?.CPU * 1000 -
                                          node?.Allocatable?.CPU +
                                          node?.Usage?.CPU / 1000000
                                      )}{" "}
                                      m{" "}
                                    </b>
                                  </p>
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  padding: "20px",
                                  border: "2px #FFFFF",
                                  borderRadius: "0px",
                                }}
                              >
                                {node.Capacity?.Memory && (
                                  <MemoryChart node={node} />
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p>
                                    Allocatable :{" "}
                                    <b>
                                      {(
                                        (node?.Capacity?.Memory -
                                          (node?.Capacity?.Memory -
                                            node?.Allocatable?.Memory +
                                            node?.Usage?.Memory)) /
                                        (1024 * 1024 * 1024)
                                      ).toFixed(2)}
                                      GiB{" "}
                                    </b>
                                  </p>
                                  <p>
                                    Used :{" "}
                                    <b>
                                      {(
                                        (node?.Usage?.Memory +
                                          (node?.Capacity?.Memory -
                                            node?.Allocatable?.Memory)) /
                                        (1024 * 1024 * 1024)
                                      ).toFixed(2)}{" "}
                                      GiB{" "}
                                    </b>
                                  </p>
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  padding: "20px",
                                  border: "2px #FFFFF",
                                  borderRadius: "0px",
                                }}
                              >
                                {node?.Capacity && <PodsChart node={node} />}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p>
                                    Allocatable :{" "}
                                    <b>
                                      {node?.Capacity?.Pods - node?.Usage?.Pods}{" "}
                                      pods{" "}
                                    </b>
                                  </p>
                                  <p>
                                    Used : <b>{node?.Usage?.Pods} pods </b>
                                  </p>
                                </div>
                              </Paper>
                            </>
                          )}
                        </Grid>
                      </AccordionDetails>
                    )}
                </Accordion>
              </>
            ))}
          </>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  addSelectedCluster,
  addCloudClusterDetails,
  featchAllCloudConfigurationActions,
  addClusterList,
  addChartValue,
  showMyChart,
  add_Cluster_Validity,
  remove_Cluster_Validity,
  addNodesUsageData,
  addNodesListData,
};

const mapStateToProps = (state) => {
  const { hostingData, nodesUsage } = state.deploymentProcessReducer;
  const { clustername, clusterList, chart, showChart, clusterValidity } =
    state.deploymentProcessReducer.hostingData;
  const { data, loading, error, _totalcount } =
    state?.organizationReducer?.cloudConfigList;
  return {
    clusterList,
    hostingData,
    clustername,
    chart,
    data,
    loading,
    _totalcount,
    showChart,
    clusterValidity,
    nodesUsage,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDeploymentHosting);
