import React, { useRef, useState } from "react";
import uploadImageStyles from "../uploadImage/UploadImageStyles";
function UploadFile({ selectedFile, handleFile, col, selectedComponent }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const classes = uploadImageStyles();

  return (
    <div style={{ border: "1px solid red  !important" }}>
      {/* {col.isEditable && (
          <button
            className={classes.uploadbtn}
            onClick={() => fileInputRef?.current?.click()}
          >
            Upload
          </button>
        )} */}
      <input
        onChange={(event) => handleFile(event, col)}
        name={col?.name}
        multiple={false}
        style={{
          fontSize: selectedComponent?.fontSize
            ? selectedComponent?.fontSize
            : 16,
        }}
        ref={fileInputRef}
        type="file"
        // hidden
      />

      {selectedFile && (
        <a
          style={{
            marginLeft: 10,
            fontSize: selectedComponent?.fontSize
              ? selectedComponent?.fontSize
              : 16,
          }}
          href={selectedFile}
          target="_blank"
          download
        >
          {selectedFile.length > 10 && selectedFile}
        </a>
      )}
    </div>
  );
}

export default UploadFile;
