import { userServices } from '../../services/userServices';
import { BASE_URL } from '../../services/helper/config';
import axios from 'axios';

import {
  ISSUE_DETAILS_FAIL,
  ISSUE_DETAILS_REQUEST,
  ISSUE_DETAILS_SUCCESS,
  ISSUE_FILTERS,
  ISSUE_SORT,
  ISSUE_HIDDEN,
  NEW_ISSUE_FAIL,
  NEW_ISSUE_REQUEST,
  NEW_ISSUE_SUCCESS,
} from '../../constants/UserProfile/helpDesk';
import { IssueDetails } from '../../services/issueDetails';
const _IssueDetails = new IssueDetails();

// action for getting all the issues
export const getIssueDetails =
  (currentPage, filter, sort) => async (dispatch) => {
    try {
      dispatch({
        type: ISSUE_DETAILS_REQUEST,
      });
      const { data } = await _IssueDetails.fetchIssues(
        currentPage,
        filter,
        sort
      );
      dispatch({ type: ISSUE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ISSUE_DETAILS_FAIL,
        payload: error,
      });
    }
  };
// action for createing a new issue
// createIssuue
export const raiseIssue = (val) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_ISSUE_REQUEST,
    });
    const {
      data: { data },
    } = await _IssueDetails.createIssuue(val);
   
    dispatch({ type: NEW_ISSUE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NEW_ISSUE_FAIL,
      payload: error,
    });
  }
};

export const addIssueFilters = (issueFilters) => {
  return {
    type: ISSUE_FILTERS,
    payload: issueFilters,
  };
};

export const addIssueSort = (issueSort) => {
  return {
    type: ISSUE_SORT,
    payload: issueSort,
  };
};
export const addIssueHidden = (issueHidden) => {
  return {
    type: ISSUE_HIDDEN,
    payload: issueHidden,
  };
};
