import React, { useEffect, useState } from "react";
import ActionStyles from "../ActionStyles";
import {
  Divider,
  Grid,
  Tabs,
  Tab,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { editAction } from "../../../../../redux/actions/uiApplicationAction";
import Response from "../Response";
import { applicationsArr } from "../data";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import useGetData from "../../../../hooks/useGetData";
import { Autocomplete } from "@material-ui/lab";
import RenderConfigFields from "./CreateFields";
import { getRuntimeEnvDetailsByID } from "../../../../../utils/containerMangement/runtimeEnvUtils";
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");
import { useSnackbar } from "notistack";
const ApplicationHelper = () => {
  const styles = ActionStyles();
  const dispatch = useDispatch();
  const { currentAction, currentProject } = useGetData();
  const [containers, setContainers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [applicationObj, setApplicationObj] = useState({
    currentApplication: currentProject?.backendApplication[0],
    currentProcess: currentProject?.backendApplication[0]?.process[0],
  });

  /*eslint-disable */

  const fetchruntimeENVdata = async (envID) => {
    const { _msg, _status, data } = await getRuntimeEnvDetailsByID(envID);
    if (_status === 200) {
      return data;
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
      return false;
    }
  };

  useEffect(async () => {
    if (currentAction?.application?.environmentID) {
      const envData = await fetchruntimeENVdata(
        currentAction?.application?.environmentID
      );
      setContainers(envData.services ? envData.services : []);
    }
  }, []);

  const handleChange = async (event, newVal, type) => {
    switch (type) {
      case "application":
        dispatch(
          editAction({
            id: currentAction?._id,
            data: {
              ...currentAction,
              application: newVal,
            },
          })
        );
        break;
      case "process":
        dispatch(
          editAction({
            id: currentAction?._id,
            data: {
              ...currentAction,
              process: newVal === null ? {} : newVal,
              applicationConfig: {
                ...currentAction?.applicationConfig,
                topic: newVal?.topic,
                broker: newVal?.broker,
                message: newVal?.message,
              },
            },
          })
        );
        if (currentAction?.application?.environmentID) {
          const envData = await fetchruntimeENVdata(
            currentAction?.application?.environmentID
          );
          setContainers(envData.services ? envData.services : []);
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e) => {
    dispatch(
      editAction({
        id: currentAction?._id,
        data: {
          ...currentAction,
          applicationConfig: {
            ...currentAction?.applicationConfig,
            [e.target.name]: e.target.value,
          },
        },
      })
    );
  };

  return (
    <Grid container style={{ padding: "0px 20px" }}>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <label className={styles.fieldHeading}>Service Bundles</label>
          <Autocomplete
            id="application-helper-service-bundle-dropdown"
            options={
              currentProject?.backendApplication?.length
                ? currentProject?.backendApplication
                : []
            }
            getOptionLabel={(option) => option?.name}
            value={currentAction?.application}
            className={`${styles.searchInput3}`}
            onChange={(event, newVal) =>
              handleChange(event, newVal, "application")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Service Bundle"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="" className={styles.fieldHeading}>
            Aggregated APIs
          </label>
          <Autocomplete
            id="application-helper-aggregated-apis-dropdown"
            options={
              currentAction?.application?.process?.length
                ? currentAction?.application?.process
                : []
            }
            getOptionLabel={(option) => option?.name}
            renderOption={(option) => (
              <>
                <span>{option?.name}</span>
                <span style={{ paddingLeft: 10, fontWeight: "bold" }}>
                  {option?.servicetype}
                </span>
              </>
            )}
            value={currentAction?.process}
            className={`${styles.searchInput3}`}
            onChange={(event, newVal) => handleChange(event, newVal, "process")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Aggregated APIs"
              />
            )}
            onP
          />
        </Grid>
      </Grid>
      {currentAction?.process &&
        Object.keys(currentAction?.process)?.length > 0 &&
        currentAction?.process?.servicetype !== "rest" && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Topic {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="topic"
                value={currentAction?.applicationConfig?.["topic"]}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Message {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="message"
                value={currentAction?.applicationConfig?.["message"]}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid>
            {/* <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Broker {<span className={styles.red}>*</span>}
              </label>
              <input
                className={styles.value}
                type="text"
                name="broker"
                value={currentAction?.applicationConfig['broker']}
                onChange={(e) => onChange(e)}
                autoComplete="off"
              />
            </Grid> */}
            <Grid item xs={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                Runtime Environment {<span className={styles.red}>*</span>}
              </label>
              <Autocomplete
                options={containers}
                getOptionLabel={(option) => option.container_name}
                value={currentAction?.applicationConfig?.["containerName"]}
                className={`${styles.searchInput3}`}
                onChange={(event, newVal) =>
                  onChange({ target: { name: "containerName", value: newVal } })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Container"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      {/* <Accordion
        className={styles.fieldPanel}
        defaultExpanded={true}
        // key={section._id}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownRounded className={styles.accordianIcon} />}
        >
          <Typography className={styles.sectionHeading}>
            {`${
              currentAction?.process?.type
                ? currentAction?.process?.type
                : 'Rest'
            } Configurations`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0px' }}>
          <Grid container spacing={1}>
            {RenderConfigFields(
              currentAction?.process?.type
              // actionObject,
              // handleChangeCreateField
            )}
          </Grid>
        </AccordionDetails>
      </Accordion> */}
      <Grid container spacing={1}>
        {RenderConfigFields(
          currentAction?.process?.servicetype,
          "application"
          // actionObject,
          // handleChangeCreateField
        )}
      </Grid>
    </Grid>
  );
};

export default ApplicationHelper;

// if external service ---> broker,configurations -- kafka , rabbitbq , mqtt
//