import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  Table,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getColumnWidth,
  getInsideTableHeight,
  getWindowDimensions,
} from "../../../utils/common";
import useCheckPermission from "../hooks/useCheckPermission";
import { DeleteTemplate } from "../../../UiApplication/services/UiApplicationApiUtils";
import {
  delete_template,
  selected_template,
  selected_page,
} from "../../../redux/actions/uiApplicationAction";
import devExpressStyles from "../styles/DevExpressTableStyles";

const DefaultActionCell = ({ ...restProps }) => {
  const styles = devExpressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { checkCompPermission } = useCheckPermission();

  const handleDeleteItem = async (e, { row }) => {
    if (
      restProps.column.for === "template" &&
      checkCompPermission("UIApplication", ["Delete"])
    ) {
      e.preventDefault();
      try {
        const res = await DeleteTemplate(row?._id);
        if (res?.res_data?.status < 210) {
          enqueueSnackbar(
            res?.res_data?.data?._msg ?? "Template Deleted Successfully",
            { variant: "success" }
          );
          dispatch(delete_template(row?._id));
        } else throw res;
      } catch (err) {
        enqueueSnackbar(
          `${err?.err?.response?.data?._status} : ${
            err?.err?.response?.data?._msg ??
            "Unable to process your request, please try after sometime"
          }`,
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar(
        `You do not have permission to access Delete ${
          restProps.column.for.charAt(0).toUpperCase() +
          restProps.column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };
  return (
    <div>
      {restProps.column.for === "template" && (
        <Tooltip title="Delete" arrow>
          <IconButton
            color="inherit"
            aria-label="delete"
            edge="start"
            className={styles.actionIcon}
            onClick={(e) => handleDeleteItem(e, restProps)}
          >
            <img src="/images/deleteLine.svg" alt="card" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

const DateCell = ({ ...restProps }) => {
  const { userInfo } = useSelector((state) => state?.userDetails);

  return (
    <>
      {restProps.row[restProps.column.name] ? (
        <>
          {userInfo?.timeZone && restProps.row[restProps.column.name]
            ? moment
                .unix(restProps.row[restProps.column.name])
                .tz(userInfo.timeZone)
                .format(
                  userInfo.time === "h:mm"
                    ? `${userInfo.dateFormat}, ${userInfo.time} A`
                    : `${userInfo.dateFormat} ${userInfo.time}`
                )
            : moment
                .unix(restProps.row[restProps.column.name])
                .format("DD/MM/YYYY, hh:mm:ss A")}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Cell = (props) => {
  const styles = devExpressStyles();
  const dispatch = useDispatch();
  const { column } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { uiApplication_list, template_list } = useSelector(
    (state) => state.uiApplicationReducer
  );
  const [highlightTemplateRow, setHighlightTemplateRow] = useState(false);

  const handleNavigateToDetails = () => {
    const { data } = uiApplication_list;
    const { templateData } = template_list;
    const tempPages =
      data &&
      data?.length > 0 &&
      data.map((p) =>
        Array.isArray(p?.pages) ? p?.pages : JSON.parse(p?.pages)
      );

    let singlePage = [];
    tempPages &&
      tempPages?.length > 0 &&
      tempPages?.map((temp) => {
        return temp?.map((single) => singlePage.push(single));
      });

    const selectedTemplates =
      templateData && templateData.length > 0
        ? templateData.filter((t) => t.templateSelected === true)
        : [];

    const selectedPages =
      singlePage && singlePage.length > 0
        ? singlePage.filter((t) => t.selectedPage === true)
        : [];

    // eslint-disable-next-line array-callback-return
    selectedTemplates.map((item) => {
      if (item.templateSelected === true) {
        setHighlightTemplateRow(true);
      }
    });

    if (column.for === "template") {
      dispatch(selected_template(props?.row?._id));
    } else if (column.for === "page") {
      dispatch(selected_page(props?.row?.uiApplicationId, props?.row?.id));
    } else {
      enqueueSnackbar(
        `You do not have permission to access Read ${
          column.for.charAt(0).toUpperCase() + column.for.slice(1)
        }. Please contact your Admin.`,
        { variant: "error" }
      );
    }
  };

  // row action
  if (column.for === "template") {
    if (column.name === "action") {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
            <DefaultActionCell {...props} />
          </VirtualTable.Cell>
        </>
      );
    } else if (column.name === "createdon" || column.name === "updatedon") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  } else if (column.for === "page") {
    if (column.name === "createdOn" || column.name === "updatedOn") {
      return (
        <VirtualTable.Cell {...props} onClick={handleNavigateToDetails}>
          <DateCell {...props} />
        </VirtualTable.Cell>
      );
    } else {
      return (
        <>
          <VirtualTable.Cell {...props} onClick={handleNavigateToDetails} />
        </>
      );
    }
  }

  return <VirtualTable.Cell {...props} />;
};

const NoDataFound = (props) => {
  return (
    <td style={{ borderBottom: "unset" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "calc(50% - 4rem)",
          left: "calc(50% - 4rem)",
          maxWidth: "11rem",
        }}
      >
        <img src="/images/notFound.svg" alt="notfound" />
        <h3 style={{ textAlign: "center" }}>{props.msg}</h3>
      </div>
    </td>
  );
};

const TemplateTable = (props) => {
  const [hiddenColumnNames, setHiddenColumnNames] = useState(props.hidden);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths);

  const devExpressTable = useRef(null);

  var chooseIcon = document.createElement("img");
  chooseIcon.src = "/images/columnChoose.svg";
  chooseIcon.alt = "Column Choose";

  useEffect(() => {
    const width = getColumnWidth(props.defaultColumnWidths, windowDimensions);
    setColumnWidths(width);
    const iconElement =
      devExpressTable.current?.childNodes[0].childNodes[0].childNodes[1]
        .childNodes[0].childNodes[0];
    iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const styles = devExpressStyles();

  const handleChooseColumn = (hideCols) => {
    if (props.columns.length - hideCols.length <= 5) {
      setHiddenColumnNames(hideCols);
      props.setHidden(hideCols);
    }
  };
  const [selection, setSelection] = useState([]);

  return (
    <Paper className={styles.paper}>
      <div
        className={`${styles.myDevTable} ${
          props.isCustomHeight ? styles.listScreenTable : ""
        }`}
        ref={devExpressTable}
      >
        <Grid rows={props.rows} columns={props.columns}>
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SortingState
            sorting={props.sort}
            onSortingChange={props.setSort}
            columnExtensions={props.tableColumnExtensions}
          />
          <IntegratedSorting />
          <DragDropProvider />

          <VirtualTable
            height={
              props.isCustomHeight
                ? windowDimensions.height - 260
                : getInsideTableHeight(props.rows)
            }
            columnExtensions={props.tableColumnExtensions}
            cellComponent={Cell}
            noDataCellComponent={() => <NoDataFound msg={props.noDataMsg} />}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableColumnReordering defaultOrder={props.columnOrder} />
          <TableHeaderRow showSortingControls />

          {!props.hideColumnChooser && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={handleChooseColumn}
              columnExtensions={[
                { columnName: "action", togglingEnabled: false },
              ]}
            />
          )}
          {!props.hideColumnChooser && <Toolbar />}
          {!props.hideColumnChooser && <ColumnChooser />}
        </Grid>
        {props.loading && <CircularProgress className={styles.loader} />}
      </div>
    </Paper>
  );
};

export default TemplateTable;
