import { userServices } from "../../services/userServices";
const userService = new userServices();

export const getUserList = async () => {
  try {
    const res_data = await userService.getUserList();
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const fetchRoles = async (filter) => {
  try {
    const res_data = await userService.fetchRoles(filter);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getUserDetailsByID = async (userID) => {
  try {
    const res_data = await userService.getUserDetailsByID(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const createUser = async (userData) => {
  try {
    const res_data = await userService.createUser(userData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editUserByID = async (userID, EdituserData) => {
  try {
    const res_data = await userService.editUserByID(userID, EdituserData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getUserTeamDetails = async (userID) => {
  try {
    const res_data = await userService.getUserTeamDetails(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const getUserProjectDetails = async (userID) => {
  try {
    const res_data = await userService.getUserProjectDetails(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const deleteUser = async (userID) => {
  try {
    const res_data = await userService.deleteUser(userID);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const blockUser = async (userID, blockData) => {
  try {
    const res_data = await userService.blockUser(userID, blockData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const unBlockUser = async (userID, blockData) => {
  try {
    const res_data = await userService.unBlockUser(userID, blockData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
   
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchFavouriteByResource = async (userID, resource) => {
  try {
    const res_data = await userService.getFavouriteByResource(userID, resource);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addFavouriteInResource = async (userID, favouriteData) => {
  try {
    const res_data = await userService.addFavouriteInResource(
      userID,
      favouriteData
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteFavouriteInResource = async (
  userID,
  resource,
  resourceinstance
) => {
  try {
    const res_data = await userService.deleteFavouriteInResource(
      userID,
      resource,
      resourceinstance
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addSubscriptionInResource = async (
  email,
  userID,
  resouce,
  resourceinstance
) => {
  try {
    const res_data = await userService.addSubscriptionInResource(
      email,
      userID,
      resouce,
      resourceinstance
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const deleteUserSubscriptionInResource = async (
  userID,
  resouce,
  resourceinstance
) => {
  try {
    const res_data = await userService.deleteUserSubscriptionInResource(
      userID,
      resouce,
      resourceinstance
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const AddRolesToUser = async (userID, body) => {
  try {
    const res_data = await userService.AddRolesToUser(userID, body);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
export const RemoveRolesFromUser = async (userID, body) => {
  try {
    const res_data = await userService.RemoveRolesFromUser(userID, body);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
