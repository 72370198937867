import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FilledInput,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import {
  templateColumns,
  templateDefaultColumnWidths,
  templateTableColumnExtensions,
  templateColumnOrder,
  templateColumnHidden,
} from "../../utils/constants/TemplatesConstant";
import {
  pageColumns,
  pageDefaultColumnWidths,
  pageTableColumnExtensions,
  pageColumnOrder,
  pageColumnHidden,
} from "../../utils/constants/templatePagesConstant";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import {
  add_template_filters,
  add_template_sort,
  add_template_HiddenCols,
  get_templates_data,
  get_allPage_data,
  addPage,
  selected_template,
  selected_page,
  addLinkedUiApplicationId,
} from "../../../redux/actions/uiApplicationAction";
import { arrayToSort, arrayToFilter } from "../../../utils/common";
import CustomFilter from "../../../components/common/CustomFilter";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import useGetData from "../../hooks/useGetData";
import ModalStyles from "../../styles/commonStyles/TemplateModal";
import TemplateTable from "../../../components/common/Tables/TemplateTable";
import TableFilter from "../../components/atoms/cutom-components/TableFilter";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { UiApplicationService } from "../../services/UiApplicationService";
import CustomTag from "../atoms/customTag/CustomTag";
import { useSnackbar } from "notistack";

function ImportTemplateModal(props) {
  const {
    //  template action
    add_template_filters,
    add_template_sort,
    add_template_HiddenCols,
    get_templates_data,

    // template reducer
    templateData,
    templateLoading,
    templateCount,
    templateFilter,
    templateSort,
    templateHiddenCols,

    // pages action
    uiApplicationList,
    pageLoading,
    get_allPage_data,
  } = props;

  //   template
  const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
  const handleTemplatePageChange = (event, value) =>
    setCurrentTemplatePage(value);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    selectedTemplates.forEach((template) =>
      dispatch(selected_template(template._id))
    );
    selectedPages.forEach((page) =>
      dispatch(selected_page(page.projectId, page.id))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.templateModal]);
  useEffect(() => {
    const parsedFilters = arrayToFilter(templateFilter);
    const parsedSort = arrayToSort(templateSort);
    get_templates_data(0, parsedFilters, JSON.stringify(parsedSort));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSort, currentTemplatePage]);

  function createTemplateData(templateData) {
    const { id, description, name, createdon, createdby, projectName } =
      templateData;
    return {
      ...templateData,
      id,
      name,
      uiApplication: projectName,
      description,
      createdon: createdon ? createdon : null,
      createdby: createdby,
    };
  }

  const templateRows =
    templateData?.length > 0
      ? templateData.map((item) => {
          const row_data = createTemplateData(item);
          return row_data;
        })
      : [];

  //   pages
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => get_allPage_data(), []);

  const tempPages =
    uiApplicationList &&
    uiApplicationList?.length > 0 &&
    uiApplicationList?.map((p) =>
      Array.isArray(p?.pages)
        ? p?.pages?.map((item) => ({
            ...item,
            projectId: p?._id,
            projectName: p?.name,
          }))
        : JSON.parse(p?.pages)?.map((item) => ({
            ...item,
            projectId: p?._id,
            projectName: p?.name,
          }))
    );

  let singlePage = [];
  let pagesRow = [];
  tempPages &&
    tempPages?.length > 0 &&
    tempPages?.map((temp) => {
      return temp?.map((single) => singlePage.push(single));
    });

  //   search
  const [searchInput, setSearchInput] = React.useState("");
  const isInitialMount = React.useRef(true);

  useEffect(() => {
    isInitialMount.current ? (isInitialMount.current = false) : handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleSearch = () => {
    if (searchInput) {
      get_templates_data(
        0,
        JSON.stringify({
          name: { $regex: searchInput, $options: "i" },
        })
      );
    } else {
      get_templates_data(0);
    }
  };

  function createData(singlePage) {
    const {
      id,
      name,
      createdon,
      createdby,
      projectName,
      projectId,
      description,
    } = singlePage;
    return {
      ...singlePage,
      id,
      name,
      description: description,
      uiApplication: projectName,
      uiApplicationId: projectId,
      createdOn: createdon ? createdon : null,
      createdBy: createdby,
    };
  }

  const [filterEl, setFilterEl] = useState(null);
  const [filter, setFilter] = useState({
    name: "",
    value: "",
  });
  const isFiltesrOpen = Boolean(filterEl);

  pagesRow =
    singlePage?.length > 0
      ? singlePage
          .filter((page) =>
            filter?.value
              ? page?.[filter?.name ? filter?.name : `name`]?.includes(
                  filter?.value
                )
              : page?.name?.includes(searchInput)
          )
          .map((item) => {
            const row_data = createData(item);
            return row_data;
          })
      : [];

  // General
  const styles = ModalStyles();
  const dispatch = useDispatch();
  const apiService = new UiApplicationService();
  const [currentTab, setCurrentTab] = useState("templates");
  const { currentProject, updatePageForLinking } = useGetData();

  // filter
  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(templateFilter);
    const parsedSort = arrayToSort(templateSort);
    get_templates_data(0, parsedFilters, JSON.stringify(parsedSort));
  };
  const handleClearFilters = () => get_templates_data("", 0, JSON.stringify());
  const selectedTemplates =
    templateData && templateData.length > 0
      ? templateData.filter((t) => t.templateSelected)
      : [];
  const selectedPages =
    singlePage && singlePage.length > 0
      ? singlePage.filter((t) => t.selectedPage)
      : [];

  const showErr = (type, err, data) => {
    const errStatus = err?.response?.data?._status;
    const errMsg = err?.response?.data?._msg.toLowerCase();
    // showing error message according to err msg
    // if err msg is page name exist saying page name already exist
    // if err msg is page url exist saying page url already exist for page name
    // if not both simply showing the err msg
    enqueueSnackbar(
      `${type} - ${errStatus + " : " ?? ""}${
        errMsg.includes("name")
          ? data.name + " " + errMsg ?? " "
          : errMsg.includes("url")
          ? data.url + " " + (errMsg ?? " ") + " for page name " + data.name
          : errMsg ?? "Failed to add page"
      } in Ui Application ${data.projectName}`,
      { variant: "error" }
    );
  };
  const types = ["Menu", "Link", "Button"];

  const handleSubmit = () => {
    props?.handleClose();
    let flag = 0;
    selectedTemplates &&
      selectedTemplates?.length > 0 &&
      selectedTemplates?.forEach(async (template) => {
        try {
          let templateToAdd = {
            id: uuid(),
            name: template.name,
            url: template.url,
            components: [],
            description: template.description,
            // projectId: currentProject?.id,
            projectName: currentProject?.name,
            islandingpage: false,
          };
          const resp = await apiService?.addNewPage(
            currentProject?.id,
            templateToAdd
          );
          Array?.isArray(template?.body) &&
            template?.body?.length > 0 &&
            template?.body?.forEach(async (singleComponent) => {
              singleComponent["name"] =
                singleComponent.name + Math.floor(Math.random() * 999 + 1);
              delete singleComponent["_id"];
              const componentResp = await apiService?.addNewComponent(
                currentProject?.id,
                template?.name,
                singleComponent
              );
              if (
                componentResp?.data?._status < 210 &&
                types.includes(componentResp?.data?.data?.type)
              ) {
                flag = 1;
                updateLinkedUiApplicationArr(componentResp?.data?.data);
              }
            });
          if (resp?.status < 210) {
            dispatch(addPage({ data: templateToAdd }));
            let pagesToModify = [...currentProject?.pages, templateToAdd];
            updatePageForLinking(pagesToModify);
          }
        } catch (err) {
          showErr("Template", err, template);
        }
      });

    selectedPages &&
      selectedPages.length > 0 &&
      selectedPages.map(async (page) => {
        try {
          const pageToAdd = {
            id: uuid(),
            name: page.name,
            url: page.url,
            components: [],
            description: page.description,
            islandingpage: false,
            // projectId: currentProject?.id,
            projectName: currentProject?.name,
          };
          const resp = await apiService?.addNewPage(
            currentProject?.id,
            pageToAdd
          );
          const compApiResp = await apiService?.fetchAllComponents(
            page?.projectId,
            page?.name
          );
          compApiResp?.data?.data.length &&
            compApiResp?.data?.data.forEach(async (comp) => {
              comp["name"] = comp.name + Math.floor(Math.random() * 999 + 1);
              delete comp["_id"];
              const componentResp = await apiService?.addNewComponent(
                currentProject?.id,
                page?.name,
                comp
              );
              if (
                componentResp?.data?._status < 210 &&
                types.includes(componentResp?.data?.data?.type)
              ) {
                flag = 1;
                updateLinkedUiApplicationArr(componentResp?.data?.data);
              }
            });
          const pagesToModify = [...currentProject?.pages, pageToAdd];
          updatePageForLinking(pagesToModify);
          dispatch(addPage({ data: pageToAdd }));
        } catch (err) {
          showErr("Page", err, page);
        }
      });
    if (flag) {
      const uiAppResp = apiService?.editApplication(
        currentProject?.id,
        currentProject
      );
    }
  };

  const updateLinkedUiApplicationArr = (comp) => {
    if (comp.type === "Button" || comp.type === "Link") {
      dispatch(addLinkedUiApplicationId(comp?._id, comp?.uiApplication?.id));
    }
    if (comp.type === "Menu") {
      comp?.options.forEach((menuLevel1) => {
        if (menuLevel1.linkedUiApp?.id !== undefined) {
          dispatch(
            addLinkedUiApplicationId(menuLevel1?.id, menuLevel1.linkedUiApp?.id)
          );
        }
        menuLevel1?.children?.forEach((menuLevel2) => {
          if (menuLevel2.linkedUiApp?.id !== undefined) {
            dispatch(
              addLinkedUiApplicationId(
                menuLevel2?.id,
                menuLevel2.linkedUiApp?.id
              )
            );
          }
          menuLevel2?.submenuChildren?.forEach((menuLevel3) => {
            if (menuLevel3.linkedUiApp?.id !== undefined) {
              dispatch(
                addLinkedUiApplicationId(
                  menuLevel3?.id,
                  menuLevel3.linkedUiApp?.id
                )
              );
            }
          });
        });
      });
    }
  };
  return (
    <div>
      <Dialog
        onClose={props?.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props?.open}
        maxWidth="xl"
        className={styles.importModal}
      >
        <DialogTitle id="customized-dialog-title" onClose={props?.handleClose}>
          Copy from Templates or Pages
        </DialogTitle>
        <DialogContent dividers>
          <div className={styles.topContent}>
            <div>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={currentTab === "templates"}
                      />
                    }
                    label="Templates"
                    onClick={() => setCurrentTab("templates")}
                  />
                  <FormControlLabel
                    control={
                      <Radio color="primary" checked={currentTab === "pages"} />
                    }
                    label="Pages"
                    onClick={() => setCurrentTab("pages")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div
              className={styles.topContentItem}
              style={{
                justifyContent: "flex-end",
              }}
            >
              {currentTab === "templates" && (
                <CustomFilter
                  columns={templateColumns}
                  filters={templateFilter}
                  setFilters={add_template_filters}
                  handleSubmitFilter={handleSubmitFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
              {currentTab === "pages" && (
                <div
                  className={styles.FilterBox}
                  onClick={(e) => setFilterEl(e.currentTarget)}
                >
                  <img src="/images/teams/filter-icon.svg" alt="" />
                  <span>Filter</span>
                  <span style={{ marginTop: 5 }}>
                    <KeyboardArrowDownIcon />
                  </span>
                </div>
              )}
              <TableFilter
                columns={pageColumns}
                styles={styles}
                filter={filter}
                setFilter={setFilter}
                filtersEl={filterEl}
                isFiltesrOpen={isFiltesrOpen}
                setFiltersEl={setFilterEl}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />

              <FilledInput
                id="filled-adornment-password"
                name="filterfn"
                onChange={(e) => setSearchInput(e.target.value)}
                className={styles.search}
                placeholder="Search"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <img src="/images/search.svg" alt="card" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {currentTab === "templates" && (
            <div style={{ height: 300 }}>
              <TemplateTable
                columns={templateColumns}
                rows={templateRows.slice(
                  (currentTemplatePage - 1) * 5,
                  (currentTemplatePage - 1) * 5 + 5
                )}
                defaultColumnWidths={templateDefaultColumnWidths}
                tableColumnExtensions={templateTableColumnExtensions}
                columnOrder={templateColumnOrder}
                defaultHiddenColumnNames={templateColumnHidden}
                loading={templateLoading}
                currentPage={currentTemplatePage}
                setCurrentPage={setCurrentTemplatePage}
                isCustomHeight={false}
                sort={templateSort}
                setSort={add_template_sort}
                hidden={templateHiddenCols}
                setHidden={add_template_HiddenCols}
              />
            </div>
          )}
          {currentTab === "templates" && templateRows.length !== 0 && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(templateCount / 5)}
                  page={currentTemplatePage}
                  onChange={handleTemplatePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          {currentTab === "pages" && (
            <div style={{ height: 300 }}>
              <TemplateTable
                columns={pageColumns}
                rows={pagesRow.slice(
                  (currentPage - 1) * 5,
                  (currentPage - 1) * 5 + 5
                )}
                defaultColumnWidths={pageDefaultColumnWidths}
                tableColumnExtensions={pageTableColumnExtensions}
                columnOrder={pageColumnOrder}
                defaultHiddenColumnNames={pageColumnHidden}
                loading={pageLoading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isCustomHeight={false}
              />
            </div>
          )}
          {currentTab === "pages" && pagesRow.length !== 0 && (
            <div className={styles.footer}>
              <div className={styles.customPagination}>
                <Pagination
                  count={Math.ceil(pagesRow && pagesRow?.length / 5)}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className={styles.action}>
          <div className={styles.selectedContainer}>
            {currentTab === "templates" &&
              selectedTemplates.map((item) => (
                <CustomTag
                  key={item._id}
                  title={item.name}
                  onRemove={() => dispatch(selected_template(item._id))}
                />
              ))}
            {currentTab === "pages" &&
              selectedPages.map((item) => (
                <CustomTag
                  key={item._id}
                  title={item.name}
                  onRemove={() =>
                    dispatch(selected_page(item.projectId, item.id))
                  }
                />
              ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: 250,
            }}
          >
            <Button
              autoFocus
              onClick={() => props?.handleClose()}
              varint="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              {currentTab === "templates" ? "Copy Template" : "Copy Page"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  // template
  add_template_filters,
  add_template_sort,
  add_template_HiddenCols,
  get_templates_data,
  //   page
  get_allPage_data,
};

const mapStateToProps = (state) => {
  const {
    //   template
    template_list,
    templateFilter,
    templateSort,
    templateHiddenCols,
    //   pages
    uiApplication_list,
  } = state.uiApplicationReducer;
  const { templateData, templateLoading, templateCount } = template_list;
  const { data, loading } = uiApplication_list;
  return {
    //   pages
    uiApplicationList: data,
    uiAppLoading: loading,
    //   Template
    templateData,
    templateLoading,
    templateCount,
    templateFilter,
    templateSort,
    templateHiddenCols,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportTemplateModal);
