import React from 'react';
import { makeStyles, IconButton } from "@material-ui/core"
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
} from 'react-flow-renderer';
import { connect } from "react-redux";
import { useSnackbar } from "notistack"
import { replaceElementList } from "../../../redux/actions/orchestrationAction"
import { deleteRelation_ } from "../../../utils/orchestration/orchestrationUtils"

import "../../../screens/Orchestration/orchestration.css"


const useStyles = makeStyles((theme) => ({
    icon: {
        borderWidth: 0
    },
    edgeButton: {
    },
    edgeBody: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    edgeText: {
        font: "normal normal 600 14px/28px Nunito", color: '#002855'
    }
}))

const foreignObjectSize = 20;

function LabelButtonEdge(props) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        arrowHeadType,
        markerEndId,
    } = props;
    const { elements, templateid } = props;
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar()
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const onEdgeClick = async (evt, id) => {
        evt.stopPropagation();
        const edgeData = elements && elements.find((item) => item.id === id);
        const { source, target } = edgeData;
        const req_data = {
            nodeid: target
        }
        const { _msg, _status } = await deleteRelation_(templateid, source, req_data);
        if (_status === 200) {
            var newElementList = elements.filter((item) => item.id !== id);
            props.replaceElementList(newElementList);
            enqueueSnackbar(_msg, { variant: "success" });
        } else {
            enqueueSnackbar(_msg, { variant: "error" })
        }
        // alert(`remove ${id}`);
    };
    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize + 100}
                height={foreignObjectSize + 20}
                x={edgeCenterX - foreignObjectSize / 2 - 30}
                y={edgeCenterY - foreignObjectSize / 2}
                className={styles.icon}
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body className={styles.edgeBody}>
                    <text className={styles.edgeText} title={data.condition ? data.condition : ""}>
                        {data?.condition ? `${data?.condition?.substring(0, 9)} ${data?.condition?.length > 9 ? "..." : ""}` : ""}
                    </text>
                    <IconButton
                        className={styles.edgeButton}
                        onClick={(event) => onEdgeClick(event, id)}
                    >
                        <img src="/images/deleteLine.svg" alt="delete" height={15} width={15} />
                    </IconButton>
                </body>
            </foreignObject>
        </> 
    );
}


const mapDispatchToProps = {
    replaceElementList
}

const mapStateToProps = (state) => {
    const { elements, process_data } = state.orchestration;
    const { templateid } = process_data;
    return {
        elements: elements,
        templateid: templateid,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelButtonEdge)