import {
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import manageStyles from '../../../../screens/CommonStyles/manageScreenStyles';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { AppButton } from '../../../../components/common/Button';
import { useSelector } from 'react-redux';
import useCheckPermission from '../../hooks/useCheckPermission';
import moment from 'moment';
const CustomDropperIcon = () => (
  <img src="/images/Icon metro-folder-upload.svg" alt="droppper" />
);
const CreateHelpDeskEntity = ({
  section,
  helpDeskData,
  error,
  handleChange,
  handleImg,
  random,
  comment,
  setComment,
  handleSubmitComment,
  setHelpDeskData,
  statuses,
}) => {
  const styles = manageStyles();
  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = userDetails;
  const { checkCompPermission } = useCheckPermission();
  const UploadZone = () => {
    return (
      <>
        <div
          className={styles.documentDropperWrapper}
          style={{ marginBottom: 8 }}
        >
          <label htmlFor="projectDescription" className={styles.fieldHeading}>
            Upload Images
          </label>
          <div style={{ marginTop: '0.6rem', marginBottom: 10 }}>
            <DropzoneArea
              key={random}
              Icon={CustomDropperIcon}
              showPreviews={false}
              filesLimit={10}
              dropzoneText="Drag and drop or click to browse here"
              dropzoneClass="documentDropperClass"
              dropzoneParagraphClass="documentDropperTextClass"
              showAlerts={['error']}
              showPreviewsInDropzone={false}
              acceptedFiles={['.jpg,.jpeg,.png']}
              onChange={(files) => handleImg(files)}
            />
          </div>
          <span className={styles.warningTxt}>
            Upload jpg, jpeg or png of size less than 2 MB
          </span>
        </div>
      </>
    );
  };
  const Conversation = () => {
    return (
      <Grid container style={{ marginTop: '1rem' }}>
        <Grid item sm={10} xs={10}>
          <label htmlFor="message" className={styles.fieldHeading}>
            Add your Comment
          </label>
          <textarea
            name="message"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className={`${styles.textarea}`}
            rows="8"
          ></textarea>
          <div style={{ marginTop: 20, textAlign: 'right' }}>
            <AppButton
              color="primary"
              buttonName="Send"
              variant="contained"
              disabled={!checkCompPermission('Helpdesk', ['Add Comment'])}
              style={{ padding: '6px 15px' }}
              className="btnsmall"
              onClick={handleSubmitComment}
            />
          </div>
        </Grid>
      </Grid>
    );
  };
  // for the status
  const statusSection = () => {
    return (
      <>
        <Grid item container xs direction="column">
          <Grid item>
            <Autocomplete
              options={statuses}
              getOptionLabel={(option) => option.status}
              className={`${styles.searchInput2} ${error.status?.isError && styles.errorAuto
                }`}
              style={{ width: '100%' }}
              onChange={(e, input) => {
                if (input) {

                  setHelpDeskData({
                    ...helpDeskData,
                    status: input.status,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Search Status"
                  placeholder="Search Status"
                />
              )}
            />
          </Grid>
          {error.status?.isError && (
            <p className={styles.errorMsg}>{'Status is Required'}</p>
          )}
          <Grid item style={{ position: 'absolute', marginTop: '3.6rem' }}>
            {helpDeskData?.status && statuses.length > 0 ? (
              <div style={{ display: 'flex', marginLeft: 12 }}>
                <div
                  style={{
                    backgroundColor: statuses.find(
                      (s) => s.status === helpDeskData?.status
                    )?.color,
                    height: 15,
                    width: 15,
                    borderRadius: '50%',
                    position: 'relative',
                    right: 10,
                  }}
                ></div>
                <Typography
                  variant="h6"
                  className={styles.fieldText}
                  style={{
                    color: statuses.find(
                      (s) => s.status === helpDeskData?.status
                    )?.color,
                  }}
                >
                  {statuses
                    .find((s) => s.status === helpDeskData?.status)
                    ?.status.charAt(0)
                    .toUpperCase() +
                    statuses
                      .find((s) => s.status === helpDeskData?.status)
                      ?.status.slice(1)}
                </Typography>
              </div>
            ) : (
              <Typography variant="h6" className={styles.fieldText}>
                Status is not set
              </Typography>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const generateField = (field) => {
    switch (field.uiElementType) {
      case 'TextField':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{' '}
              {field?.required === 'TRUE' && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <input
              type="text"
              name={field.datakey}
              className={`${styles.input} ${field.editable === 'FALSE' && styles.editMode
                } ${error[field.datakey]?.isError && styles.error} `}
              autoComplete="off"
              value={
                field.dataType === 'date' && helpDeskData[field.datakey]
                  ? userInfo?.timeZone
                    ? moment
                      .unix(helpDeskData[field.datakey])
                      .tz(userInfo?.timeZone)
                      .format(
                        userInfo.time === 'h:mm'
                          ? `${userInfo?.dateFormat}, ${userInfo?.time} A`
                          : `${userInfo?.dateFormat} ${userInfo?.time}`
                      )
                    : moment
                      .unix(helpDeskData[field.datakey])
                      .format('DD/MM/YYYY, hh:mm:ss A')
                  : helpDeskData[field.datakey]
              }
              disabled={field.editable === 'FALSE'}
              onChange={handleChange}
            />
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case 'TextArea':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{' '}
              {field?.required === 'TRUE' && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <textarea
              name={field.datakey}
              value={helpDeskData[field.datakey]}
              onChange={handleChange}
              className={`${styles.textarea} ${error[field.datakey]?.isError && styles.error
                } ${field.editable === 'FALSE' && styles.editMode}`}
              rows="7"
            ></textarea>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      case 'Dropdown':
        if (field.datakey === 'category') {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                disabled
                name={field.datakey}
                value={
                  helpDeskData[field.datakey] ? helpDeskData[field.datakey] : ''
                }
                onChange={handleChange}
                className={`${styles.select} ${error[field.datakey]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {typeof field?.values === 'string'
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </>
          );
        } else {
          return (
            <>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={field.datakey}
                value={
                  helpDeskData[field.datakey] ? helpDeskData[field.datakey] : ''
                }
                onChange={handleChange}
                className={`${styles.select} ${error[field.datakey]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {/* {field?.valuesArray.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))} */}
                {typeof field?.values === 'string'
                  ? JSON.parse(field?.values).map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))
                  : field?.values.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </>
          );
        }
      case 'Date':
        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{' '}
              {field?.required === 'TRUE' && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <DesktopDatePicker
              value={
                helpDeskData[field.datakey] ? helpDeskData[field.datakey] : null
              }
              onChange={(date) =>
                handleChange({ target: { name: field.datakey, value: date } })
              }
              inputFormat={'dd/MM/yyyy'}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className={`${styles.input} ${error[field.datakey]?.isError && styles.error
                        } ${field.editable === 'FALSE' && styles.editMode}`}
                    />
                    <span className={`${styles.datePickerIcon}`}>
                      {InputProps?.endAdornment}
                    </span>
                  </div>
                  {error[field.datakey]?.isError && (
                    <p className={styles.errorMsg}>
                      {error[field.datakey].msg}
                    </p>
                  )}
                </>
              )}
            />
          </LocalizationProvider>
        );
      case 'Currency':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  helpDeskData[`${field.datakey}_unit`]
                    ? helpDeskData[`${field.datakey}_unit`]
                    : ''
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                value={helpDeskData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case 'Amount':
        return (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel} Unit{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <Select
                name={`${field.datakey}_unit`}
                value={
                  helpDeskData[`${field.datakey}_unit`]
                    ? helpDeskData[`${field.datakey}_unit`]
                    : ''
                }
                onChange={handleChange}
                className={`${styles.select} ${error[`${field.datakey}_unit`]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {field?.values.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error[`${field.datakey}_unit`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_unit`].msg}
                </p>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <label htmlFor="" className={styles.fieldHeading}>
                {field?.fieldLabel}{' '}
                {field?.required === 'TRUE' && (
                  <span className={styles.red}>*</span>
                )}
              </label>
              <input
                type="text"
                name={`${field.datakey}_value`}
                className={`${styles.input} ${error[`${field.datakey}_value`]?.isError && styles.error
                  } ${field.editable === 'FALSE' && styles.editMode}`}
                value={helpDeskData[`${field.datakey}_value`]}
                autoComplete="off"
                onChange={handleChange}
              />
              {error[`${field.datakey}_value`]?.isError && (
                <p className={styles.errorMsg}>
                  {error[`${field.datakey}_value`].msg}
                </p>
              )}
            </Grid>
          </Grid>
        );
      case 'RadioButton':
        return (
          <>
            <label htmlFor="" className={styles.fieldHeading}>
              {field?.fieldLabel}{' '}
              {field?.required === 'TRUE' && (
                <span className={styles.red}>*</span>
              )}
            </label>
            <RadioGroup
              name={field.datakey}
              value={
                helpDeskData[field.datakey] ? helpDeskData[field.datakey] : ''
              }
              onChange={handleChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {typeof field?.values === 'string'
                ? JSON.parse(field?.values).map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        // disabled={!isEditMode || field.editable === 'FALSE'}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))
                : field?.values.map((value, index) => (
                  <FormControlLabel
                    value={value}
                    key={index}
                    control={
                      <Radio
                        // disabled={!isEditMode || field.editable === 'FALSE'}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
            </RadioGroup>
            {error[field.datakey]?.isError && (
              <p className={styles.errorMsg}>{error[field.datakey].msg}</p>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {section &&
        section?.fieldgroups.map((fieldgroup) => {
          return (
            <div className={styles.fieldGroup} key={fieldgroup._id}>
              {section.name === 'Helpdesk_Header_Section' &&
                !section.fieldgroups[0]._id === fieldgroup._id && (
                  <h3 className={styles.fieldgroupHeading}>
                    {fieldgroup.name}
                  </h3>
                )}
              {fieldgroup &&
                fieldgroup.fields.filter((f) => f.uiElementType === 'TextArea')
                  .length === 0 ? (
                // layout withoutTextArea
                <Grid
                  container
                  spacing={3}
                  key={fieldgroup._id}
                  alignItems="center"
                >
                  {fieldgroup &&
                    fieldgroup?.fields.map((field) => {
                      switch (field.datakey) {
                        case 'uploadimage':
                          return UploadZone();
                        case 'conversation':
                          return Conversation();
                        case 'status':
                          return statusSection();
                        default:
                          return (
                            <Grid item xs={3} sm={3} key={field._id}>
                              {generateField(field)}
                            </Grid>
                          );
                      }
                    })}
                </Grid>
              ) : (
                // layout with TextArea
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: '1rem' }}
                  key={fieldgroup._id}
                >
                  <Grid item xs={9} sm={9}>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: '0rem' }}
                      alignItems="center"
                    >
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType !== 'TextArea')
                          .map((field) => {
                            switch (field.datakey) {
                              case 'uploadimage':
                                return UploadZone();
                              case 'conversation':
                                return Conversation();
                              case 'status':
                                return statusSection();
                              default:
                                return (
                                  <Grid item xs={4} sm={4} key={field._id}>
                                    {generateField(field)}
                                  </Grid>
                                );
                            }
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: '0rem' }}
                      alignItems="center"
                    >
                      {fieldgroup &&
                        fieldgroup?.fields
                          .filter((f) => f.uiElementType === 'TextArea')
                          .map((field) => {
                            return (
                              <Grid item xs={12} sm={12} key={field._id}>
                                {generateField(field)}
                              </Grid>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default CreateHelpDeskEntity;
