import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
  },
  line1: {
    // position: "absolute",
    // marginTop: "0.2rem"
  },
  welcome_message: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  big_text: {
    fontSize: 23,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  email: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  label: {
    fontSize: 16,
    color: '#000000',
    fontFamily: 'Nunito',
    fontWeight: '600',
    opacity: 1,
  },
  email_text: {
    width: '32rem',
    height: 38,
    backgroundColor: '#EEF6FF',
    marginTop: 8,
    paddingLeft: '1rem',
  },
  password: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 22,
    alignSelf: 'center',
  },
  password_text: {
    height: 38,
    width: '32rem',
    border: '1px solid #DFDFDF',
    borderRadius: 5,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    marginTop: 8,
    paddingLeft: '0.8rem',
  },
  smalltext: {
    fontSize: 14,
    fontFamily: 'Nunito',
    fontWeight: 600,
    marginLeft: 10,
  },
  star: {
    color: 'red',
    fontSize: '12px',
    position: 'relative',
    bottom: '4px',
  },
  otp_text: {
    height: 45,
    width: 264,
    border: '1px solid #D0D0D0',
    background: '#FCFCFC 0% 0% no-repeat padding-box',
    paddingLeft: '0.8rem',
  },
  resend_code: {
    ...theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Nunito',
    color: '#363636',
  },
  error_text: {
    fontSize: 12,
    fontFamily: 'Nunito',
    fontWeight: 600,
    marginTop: 5,
    color: 'red',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: 'blue',
  },
  subtitle: {
    ...theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '2.5rem',
  },
  icon: {
    height: '1.1rem',
  },
  eye: {
    cursor: 'pointer',
    marginLeft: -26,
    marginTop: -5.3,
  },
  backBtn: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  resendBtn: {
    color: '#3D86C6',
    borderWidth: 0,
    cursor: 'pointer',
    "&:hover":{
      backgroundColor:'#fafafa'
    }
  },
}));
