import {
  ADD_CLUSTER_LIST,
  CLUSTER_FILTERS,
  CLUSTER_SORT,
  CLUSTER_HIDDEN,
  ADD_CLUSTER_DEPLOYMENT_LIST,
  ADD_CLUSTER_PODS_LIST,
  ADD_CLUSTER_REPLICA_SETS_LIST,
  ADD_CLUSTER_SERVICES_LIST,
  CLUSTER_DEPLOYMENT_HIDDEN,
  ADD_CLOUD_CONFIG,
  CLUSTER_PODS_HIDDEN,
  CLUSTER_SERVICE_SORT,
  CLUSTER_REPLICASET_HIDDEN,
  CLUSTER_SERVICES_HIDDEN,
} from "../actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  cloudConfig: {},
  clusterList: {
    data: [],
    error: false,
    loading: false,
  },
  clusterPodsList: {
    data: [],
    error: false,
    loading: false,
  },
  clusterDeploymentList: {
    data: [],
    error: false,
    loading: false,
  },
  clusterReplicaSetList: {
    data: [],
    error: false,
    loading: false,
  },
  clusterServiceList: {
    data: [],
    error: false,
    loading: false,
  },

  clusterDeploymentSort: [],
  clusterDeploymentHidden: ["deploymentname", "image"],
  clusterPodsSort: [],
  clusterPodsHidden: ["restart", "labels", "namespace", "node"],
  clusterReplicaSetSort: [],
  clusterReplicaSetHidden: ["namespace", "image", "labels"],
  clusterServiceHidden: [],
  clusterServiceSort: [{ columnName: "createdon", direction: "desc" }],
  clusterFilters: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  clusterSort: [{ columnName: "createdon", direction: "desc" }],
  clusterHidden: [
    "pods",
    "replicaset",
    // "deployment_app_name",
    // "deployment_cluster_name",
    // "deployment_type",
    // "deployment_app_version",
    // "cluster_category",
    // "cluster_type",
    // "cluster_name",
    // "cluster_vpc_name",
    // "cluster_role",
    // "cluster_access_key",
    // "cluster_secret",
    // "cluster_region",
    // "cluster_ami_type",
    // "cluster_capacity_type",
    // "cluster_instance_type",
    // "cluster_disk_size",
    // "cluster_minimum_size",
    // "cluster_maximum_size",
    // "cluster_desired_size",
    // "cluster_desired_size_radio",
    // "cluster_value_nodes",
    // "cluster_node_role_",
    // "cluster_subnet",
  ],
};

const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLOUD_CONFIG: {
      return {
        ...state,
        cloudConfig: {
          ...state.cloudConfig,
          ...action.payload,
        },
      };
    }
    case ADD_CLUSTER_LIST: {
      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          ...action.payload,
        },
      };
    }
    case CLUSTER_SERVICE_SORT: {
      return {
        ...state,
        clusterServiceSort: [...action.payload],
      };
    }
    case ADD_CLUSTER_DEPLOYMENT_LIST: {
      return {
        ...state,
        clusterDeploymentList: {
          ...state.clusterDeploymentList,
          ...action.payload,
        },
      };
    }
    case ADD_CLUSTER_PODS_LIST: {
      return {
        ...state,
        clusterPodsList: {
          ...state.clusterPodsList,
          ...action.payload,
        },
      };
    }
    case CLUSTER_PODS_HIDDEN: {
      return {
        ...state,
        clusterPodsHidden: [...action.payload],
      };
    }

    case ADD_CLUSTER_REPLICA_SETS_LIST: {
      return {
        ...state,
        clusterReplicaSetList: {
          ...state.clusterReplicaSetList,
          ...action.payload,
        },
      };
    }

    case ADD_CLUSTER_SERVICES_LIST: {
      return {
        ...state,
        clusterServiceList: {
          ...state.clusterServiceList,
          ...action.payload,
        },
      };
    }

    case CLUSTER_FILTERS: {
      return {
        ...state,
        clusterFilters: [...action.payload],
      };
    }
    case CLUSTER_SORT: {
      return {
        ...state,
        clusterSort: [...action.payload],
      };
    }
    case CLUSTER_HIDDEN: {
      return {
        ...state,
        clusterHidden: [...action.payload],
      };
    }

    case CLUSTER_DEPLOYMENT_HIDDEN: {
      return {
        ...state,
        clusterDeploymentHidden: [...action.payload],
      };
    }

    case CLUSTER_REPLICASET_HIDDEN: {
      return {
        ...state,
        clusterReplicaSetHidden: [...action.payload],
      };
    }
    case CLUSTER_SERVICES_HIDDEN: {
      return {
        ...state,
        clusterServiceHidden: [...action.payload],
      };
    }

    default:
      return state;
  }
};

export default clusterReducer;
