import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    padding: "10px",
    height: "auto",

    "&::before": {
      borderBottom: "unset",
    },

    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
  },
}));
