import React, { useState, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import useStyles from "../styles/InputTableStyle";
import AddEnvironemntServicesModal from "../../Orchestration/Application/AddEnvironmentServicesModal";
import { getImageList } from "../../../utils/orchestration/applicationUtils";
import { AppButton } from "../Button";
import {
  addResourceDeployment,
  fetchResourceDeployment,
} from "../../../utils/project/projectUtils";
import Loader from "../stuff/Loader";
import useCheckPermission from "../hooks/useCheckPermission";

const envList = [
  {
    id: uuidv4(),
    container_name: "",
    imageid: "",
    imagename: "",
    environment: {},
  },
];

const tempStyles = makeStyles((theme) => ({
  container: {
    // height : "10rem",
    width: "100%",
    marginTop: "1rem",
    marginLeft: "0rem",
    "& div": {
      "& table": {
        "& thead": {
          height: "50px",
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
        },
        "& tbody": {
          "& th:first-child": {
            paddingLeft: "1.5rem",
          },
          "& th:second-child": {
            paddingLeft: "0.5rem",
          },
        },
      },
    },
  },
  dropdown: {
    width: 250,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    padding: "0 15px",

    "&::before": {
      borderBottom: "unset !important",
    },
  },
  select: {
    width: 250,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    marginTop: 5,
    // font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  deployment_table: {
    height: "30rem",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  deployment_line1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "2rem",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    // border : '1px solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  fieldHeading: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    // marginBottom: 5
  },
  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "80%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
}));

function ResourceDeploymentTable(props) {
  const styles = useStyles();
  const tStyles = tempStyles();
  const [envs, setEnvs] = useState(envList);
  const [showModal, setModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [imagesList, setImagesList] = useState([]);
  const [resourceData, setResourceData] = useState({
    name: "",
    description: "",
    services: [],
  });
  const [loading, setLoading] = useState(false);

  const { isEditMode, resourceId, resourceType } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { checkCompPermission } = useCheckPermission();

  useEffect(() => {
    // fetch the list of images
    const fetchImageListData = async () => {
      const { _status, _msg, data } = await getImageList();
      if (_status === 200) {
        setImagesList(data);
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    const fetchEnvironmentData = async () => {
      const { _status, _msg, data } = await fetchResourceDeployment(
        resourceType,
        resourceId
      );
      if (_status === 200) {
        setResourceData(data.length !== 0 ? data[0] : {});
        if (data && data.length !== 0 && data[0].services) {
          setEnvs(
            data[0].services.map((item) => ({
              ...item,
              auto: true,
              imagename: item.image,
            }))
          );
        }
      } else {
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    };
    if (checkCompPermission("DockerImage", ["List All"])) {
      fetchImageListData();
    }
    if (checkCompPermission("RuntimeEnvironment", ["List All"])) {
      fetchEnvironmentData();
    }
  }, []);

  const handleContainerChange = (event, id, column) => {
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return { ...env, [column]: event.target.value };
      } else {
        return env;
      }
    });
    setEnvs(newEnvs);
  };

  const handleImageSelect = (event, id) => {
    const imagename = event.target.value;
    var image_id = "";
    imagesList.forEach((item) => {
      if (item.imagename === imagename) {
        image_id = item._id;
        return;
      }
    });
    const newEnvs = envs.map((env) => {
      if (env.id === id) {
        return {
          ...env,
          imagename: imagename,
          imageid: image_id,
        };
      } else {
        return env;
      }
    });
    setEnvs(newEnvs);
  };

  const handleAddEnv = (id) => {
    setEnvs([
      ...envs,
      {
        id: uuidv4(),
        container_name: "",
        imageid: "",
        imagename: "",
        environment: {},
        auto: false,
      },
    ]);
  };

  const handleDeleteEnv = (envToDelete) => {
    setEnvs((prevEnvs) =>
      prevEnvs.filter((env) => env.id !== envToDelete && prevEnvs.length !== 1)
    );
  };

  const handleModalIconOpen = (data) => {
    if (data) {
      setSelectedData(data);
      setModal(true);
    }
  };

  const handleModalClose = (data_obj) => {
    if (data_obj) {
      const { id, environment } = data_obj;
      const new_obj =
        envs.length > 0 &&
        envs.map((item, index) => {
          if (item.id === id) {
            return { ...item, environment: environment };
          } else {
            return item;
          }
        });
      //
      setEnvs(new_obj);
      setModal(false);
    }
  };

  const handleAddDeployementClick = async () => {
    setLoading(true);
    const req_data = {
      name: resourceData?.name,
      resoureType: "project",
      resourceId: resourceId,
      description: resourceData?.description,
      services: envs,
    };
    const { _msg, _status, data } = await addResourceDeployment(req_data);
    if (_status === 201 || _status === 200) {
      setLoading(false);
      enqueueSnackbar(_msg, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(
        _msg
          ? _msg
          : "Unable to process your request, please try after sometime",
        { variant: "error" }
      );
    }
  };

  return (
    <div>
      {loading && <Loader />}
      {isEditMode && (
        <div className={tStyles.deployment_line}>
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={3} sm={3}>
              <label htmlFor="" className={tStyles.fieldHeading}>
                Name
              </label>
              <input
                type="text"
                name="name"
                className={`${styles.input} ${!isEditMode && tStyles.editMode}`}
                value={resourceData?.name}
                autoComplete="off"
                style={{ width: "100%" }}
                disabled={!isEditMode}
                onChange={(e) =>
                  setResourceData({ ...resourceData, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <label
                htmlFor="projectDescription"
                className={tStyles.fieldHeading}
              >
                Description
              </label>
              <textarea
                name="description"
                value={resourceData?.description}
                onChange={(e) =>
                  setResourceData({
                    ...resourceData,
                    description: e.target.value,
                  })
                }
                className={`${tStyles.textarea} ${!isEditMode && tStyles.editMode
                  }`}
                disabled={!isEditMode}
                rows="8"
              ></textarea>
            </Grid>
            <Grid item xs={3} sm={3} style={{ textAlign: "right" }}>
              <AppButton
                color="primary"
                buttonName="Add Deployment"
                variant="contained"
                disabled={false}
                style={{ margin: "unset" }}
                className="btnsmall"
                onClick={handleAddDeployementClick}
              />
            </Grid>
          </Grid>
        </div>
      )}
      <div className={tStyles.container}>
        <TableContainer>
          <Table className={styles.customTable}>
            <TableHead>
              <TableRow>
                <TableCell>Container Name</TableCell>
                <TableCell align="left">Image ID</TableCell>
                <TableCell align="center">Environment</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {envs &&
                envs.length > 0 &&
                envs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <input
                        type="text"
                        name={"container_name"}
                        className={`${styles.input} ${!isEditMode && tStyles.editMode
                          }`}
                        value={row["container_name"]}
                        onChange={(e) =>
                          handleContainerChange(e, row.id, "container_name")
                        }
                        autoComplete="off"
                        disabled={!isEditMode}
                      />
                    </TableCell>
                    <TableCell>
                      {!row.auto && (
                        <Select
                          onChange={(event) => handleImageSelect(event, row.id)}
                          displayEmpty={false}
                          disabled={!isEditMode}
                          className={tStyles.select}
                          style={{ paddingRight: 0 }}
                          inputProps={{ "aria-label": "Without label" }}
                          value={row["imagename"]}
                          defaultValue={row["imagename"]}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {imagesList &&
                            imagesList.length > 0 &&
                            imagesList.map((item_, i) => {
                              return (
                                <MenuItem
                                  name={item_.imagename}
                                  value={item_.imagename}
                                  key={i}
                                >
                                  {item_.imagename}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                      {row.auto && (
                        <input
                          className={`${tStyles.dropdown} ${tStyles.editMode}`}
                          value={row["imagename"]}
                          disabled={true}
                        ></input>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        disabled={!isEditMode}
                        className={styles.actionIcon}
                        onClick={() => handleModalIconOpen(row)}
                      >
                        <img src="/images/application_list.svg" alt="card" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" style={{ textAlign: "left" }}>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        edge="start"
                        disabled={!isEditMode}
                        onClick={handleAddEnv}
                        className={styles.actionIcon}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        edge="start"
                        disabled={!isEditMode}
                        onClick={() => handleDeleteEnv(row.id)}
                        className={styles.actionIcon}
                      >
                        <img src="/images/deleteLine.svg" alt="card" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {envs.length === 0 && (
              <div className={styles.nodata}>
                <text>No Data Available</text>
              </div>
            )}
          </Table>
        </TableContainer>
        {showModal && (
          <AddEnvironemntServicesModal
            isOpen={showModal}
            id={selectedData.id}
            envs={selectedData["environment"]}
            setEnvs={(data_obj) => handleModalClose(data_obj)}
          />
        )}
      </div>
    </div>
  );
}

export default ResourceDeploymentTable;