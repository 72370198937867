import { TestDataService } from "../../services/testDataService";

const testDataService = new TestDataService();

export const deleteTestData = async (TestDataId) => {
  try {
    const res_data = await testDataService.deleteTestData(TestDataId);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const addTestEnvironmentFile = async (envData) => {
  try {
    const res_data = await testDataService.addTestEnvironmentFile(envData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchTestDataById = async (id) => {
  try {
    const res_data = await testDataService.fetchTestDataById(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const fetchTestEnvironmentDataById = async (id) => {
  try {
    const res_data = await testDataService.fetchTestEnvironmentDataById(id);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateTestDataByID = async (id, testData) => {
  try {
    const res_data = await testDataService.updateTestDataByID(id, testData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const updateTestEnvDataByID = async (id, envData) => {
  try {
    const res_data = await testDataService.updateTestEnvDataByID(id, envData);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
