import { useState } from "react";
import TrueFalseButton from "../../../atoms/rightSide/appearance/truefalse/TrueFalseButton";
import useGetData from "../../../../hooks/useGetData";
import AccessDataInput from "../../../atoms/rightSide/settings/AccessDataInput";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";
import {
  editComponent,
  editUiApplication,
} from "../../../../../redux/actions/uiApplicationAction";
import DatePicker from "../../../atoms/DatePicker/DatePicker";
import useTriggers from "../../../../hooks/useTriggers";
function DatePickerSettings() {
  const {
    currentComponent,
    currentPage,
    currentProject,
    globalAcessModeChange,
  } = useGetData();
  const [accessModeErr, setAccessModeErr] = useState("");
  const dispatch = useDispatch();
  const { handleTrigger } = useTriggers();
  const viewTypes = ["Web", "Tablet", "Mobile"];
  const handleChangeCanvasImageMode = (e) => {
    dispatch(
      editUiApplication({
        data: {
          ...currentProject,
          canvasImageMode: e?.target?.value,
        },
      })
    );
  };
  const handleChange = (event) => {
    const components = currentPage.components.map((component) => {
      if (component.id === currentComponent.id) {
        return {
          ...component,
          value: event.target.value,
        };
      } else {
        return component;
      }
    });
    dispatch(
      editComponent({
        projectId: currentProject.id,
        pageid: currentPage.id,
        data: components,
      })
    );

    if (currentComponent?.onChange) {
      handleTrigger({
        action: currentComponent?.onChange,
        isUpdateState: true,
        componentId: currentComponent?.id,
      });
    }
  };
  return (
    <div>
      <div className="field">
        <label>View Mode</label>
        <Select
          defaultValue="Web"
          value={
            currentProject?.canvasImageMode
              ? currentProject?.canvasImageMode
              : "Web"
          }
          onChange={handleChangeCanvasImageMode}
          style={{ width: "100%" }}
        >
          {viewTypes.map((viewType, i) => (
            <MenuItem key={i} value={viewType}>
              {viewType}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="field">
        <label htmlFor="">Value</label>
        <DatePicker
          selectedDate={new Date(currentComponent?.value)}
          handleChangeDate={handleChange}
          selectedComponent={currentComponent}
          inSettings
        />
      </div>
      <div className="field">
        <AccessDataInput
          label="Label"
          name="label"
          value={currentComponent?.label}
          onChange={(value) =>
            globalAcessModeChange(value, "label", setAccessModeErr)
          }
          error={accessModeErr}
          accessMode={true}
        />
      </div>
      <div className="field">
        <TrueFalseButton
          name="direction"
          leftButton="Horizontal"
          rightButton="Vertical"
          value={!currentComponent?.direction}
        />
      </div>
      <div className="field">
        <TrueFalseButton
          leftButton={
            !currentComponent?.alignment ? (
              <img src="/img/Left.-white.svg" alt="" />
            ) : (
              <img src="/img/Left.svg" alt="" />
            )
          }
          rightButton={
            currentComponent?.alignment ? (
              <img src="/img/Right.svg" alt="" />
            ) : (
              <img src="/img/Right.-Black.svg" alt="" />
            )
          }
          name="alignment"
          value={!currentComponent?.alignment}
        />
      </div>
    </div>
  );
}

export default DatePickerSettings;
