import { Divider, Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// files
import { AppButton } from "../../../components/common/Button";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import listScreenStyles from "../../CommonStyles/listScreenStyles";
//Dev table
import ApplicationListTable from "../../../components/common/Tables/ApplicationListTable";
import {
  userColumns,
  userDefaultColumnWidths,
  userTableColumnExtensions,
  userColumnOrder,
  userColumnHidden,
} from "../../../constants/User/UserListTable";
import CustomFilter from "../../../components/common/CustomFilter";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";

// redux
import { connect } from "react-redux";
import {
  add_users_data,
  add_user_filters,
  add_user_sort,
  clear_user_data,
  add_user_hidden_cols,
} from "../../../redux/actions/userInfoActions";
import {
  arrayToFilter,
  arrayToSort,
  arrayToSort2,
} from "../../../utils/common";
import AddIcon from "@material-ui/icons/Add";
import { getTableData } from "../../../utils/common";
import { fetchResourceByName } from "../../../utils/entitySchema/entitySchemaUtils";
import Loader from "../../../components/common/stuff/Loader";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import { alterActiveTab } from "../../../redux/actions/activeTabActions";
import SearchBar from "../../../components/common/SearchBar";

const UserListScreen = (props) => {
  const {
    data,
    add_users_data,
    loading,
    add_user_filters,
    userFilters,
    add_user_sort,
    userSort,
    _totalcount,
    clear_user_data,
    add_user_hidden_cols,
    userHiddenCols,
    deleteUserStatus,
    alterActiveTab,
  } = props;
  const styles = listScreenStyles();
  const histroy = useHistory();
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: userColumns,
    widths: userDefaultColumnWidths,
    extentions: userTableColumnExtensions,
    orders: userColumnOrder,
    hides: [],
  });

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    alterActiveTab("Users", "Users");
  }, []);

  useEffect(() => {
    checkScreenPermission("User", ["List All"]);
    const fetchFields = async () => {
      const { _msg, _status, data } = await fetchResourceByName("User");
      if (_status === 200) {
        const userData = getTableData(
          data,
          userColumns,
          userDefaultColumnWidths,
          userTableColumnExtensions,
          userColumnOrder,
          userHiddenCols,
          "user"
        );
        setTableData(userData);
        if (
          JSON.stringify(userHiddenCols) === JSON.stringify(userColumnHidden)
        ) {
          add_user_hidden_cols(userData.hides);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    };

    fetchFields();
  }, []);

  // Api call

  useEffect(() => {
    const parsedFilters = arrayToFilter(userFilters);
    const parsedSort = arrayToSort(userSort);
    // fetchStatusesByResource();
    add_users_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
  }, [currentPage, userSort]);

  useEffect(() => {
    if(deleteUserStatus === true){
      const parsedFilters = arrayToFilter(userFilters);
      const parsedSort = arrayToSort(userSort);
      // fetchStatusesByResource();
      add_users_data(currentPage, parsedFilters, JSON.stringify(parsedSort));
    }
  }, [deleteUserStatus]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function createData(user_data) {
    const { _id, name, teams, email, status } = user_data;
    return {
      ...user_data,
      _id,
      name,
      teams,
      email,
      status,
    };
  }

  const rows =
    data?.length > 0
      ? data.map((item) => {
        const row_data = createData(item);
        return row_data;
      })
      : [];
  useEffect(() => {
    return () => {
      clear_user_data();
    };
  }, []);

  // filter

  const handleSubmitFilter = () => {
    const parsedFilters = arrayToFilter(userFilters);
    const parsedSort = arrayToSort2(userSort);
    add_users_data(1, parsedFilters, JSON.stringify(parsedSort));
  };

  const handleClearFilters = () => {
    add_users_data(1, JSON.stringify({}));
  };

  // Search
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [searchInput]);
  const handleSearch = () => {
    if (searchInput) {
      add_users_data(
        0,
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      add_users_data(1);
    }
  };
  return (
    <main>
      <div className={styles.main}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              { title: "Users", path: "/users" },
              { title: "Users List" },
            ]}
          />

          {/* Header */}
          <div className={styles.topContentItems}>
            <div
              className={styles.topContentItem}
            // style={{ border: "1px solid" }}
            >
              <p className={styles.title}>
                {t("users.users")} {_totalcount ? ` (${_totalcount})` : ""}
              </p>
            </div>
            <div
              className={styles.topContentItem}
              style={{ justifyContent: "flex-end", width: "56%" }}
            >
              <div className={styles.internalExternal}>
                Internal{" "}
                <span
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#A333C8",
                  }}
                ></span>
                External
                <span
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#00B5AD",
                  }}
                ></span>
              </div>
              <Divider orientation="vertical" className={styles.MuiDivider} />

              <SearchBar
                handleSearch={handleSearch}
                PlaceHolderLabel="Search User Name"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />
              <CustomFilter
                columns={tableData.columns}
                filters={userFilters}
                setFilters={add_user_filters}
                handleSubmitFilter={handleSubmitFilter}
                handleClearFilters={handleClearFilters}
              />
              <Divider orientation="vertical" className={styles.MuiDivider} />

              <AppButton
                buttonName="Create"
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!checkCompPermission("User", ["Create"])}
                className="btnsmall"
                onClick={() => histroy.push("/create-user")}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <Paper className={styles.pageContent}>
          {tableLoading ? (
            <Loader />
          ) : (
            <ApplicationListTable
              columns={tableData.columns}
              rows={rows}
              defaultColumnWidths={tableData.widths}
              tableColumnExtensions={tableData.extentions}
              columnOrder={tableData.orders}
              defaultHiddenColumnNames={tableData.hides}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isCustomHeight={true}
              sort={userSort}
              setSort={add_user_sort}
              hidden={userHiddenCols}
              setHidden={add_user_hidden_cols}
            />
          )}
        </Paper>
        {rows.length !== 0 && (
          <div className={styles.footer}>
            <div className={styles.customPagination}>
              <Pagination
                count={Math.ceil(_totalcount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  add_users_data,
  add_user_filters,
  add_user_sort,
  clear_user_data,
  add_user_hidden_cols,
  alterActiveTab,
};

const mapStateToProps = (state) => {
  const { user_list, userFilters, userSort, userHiddenCols } =
    state.userInfoReducer;
  const { data, error, loading, _totalcount, deleteUserStatus } = user_list;
  return {
    data,
    error,
    loading,
    userFilters,
    userSort,
    _totalcount,
    userHiddenCols,
    deleteUserStatus,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserListScreen);
