import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Paper,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import manageStyles from "../../CommonStyles/manageScreenStyles";
import { AppButton } from "../../../components/common/Button";
import { useHistory } from "react-router";
import {
  getProjectList,
  addNewProjectData,
} from "../../../redux/actions/projectAction";
import { connect } from "react-redux";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  addProject,
  fetchLanguagesListData,
} from "../../../utils/project/projectUtils";
import { fetchTeamListData } from "../../../utils/project/teamUtils";
import {
  getWindowDimensions,
  handleFormValidationPro,
  handleUploadDocuments,
  initError,
  handleFormValidationForDocument,
} from "../../../utils/common";
import { addServiceGroupInProject } from "../../../utils/project/servicegroupUtils";
import { copyService } from "../../../utils/project/microserviceUtils";
import { fetchStatusResourceByName } from "../../../utils/status/statusUtils";
import CustomFieldGroupsForProjectCreate from "../../../components/common/entitySchema/project/CustomFieldGroupsForProjectCreate";
import Loader from "../../../components/common/stuff/Loader";
import { fetchResourceByPermission } from "../../../utils/entitySchema/entitySchemaUtils";
import useCheckPermission from "../../../components/common/hooks/useCheckPermission";
import TabbedNavigation from "../../../components/Tabbed/TabbedNavigation";
import ErrorIcon from "@material-ui/icons/Error";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import SectionTabs from "../../../components/Tabbed/SectionTabs";
import { TabsCommon } from "../../../components/Tabbed/TabsCommon";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import AccordionNavigation from "../../../components/common/Accordion/AccordionNavigation";

const AddProject = (props) => {
  const { newProjectData, addNewProjectData, tabbedView, serviceGroupMode } =
    props;
  const styles = manageStyles();

  const histroy = useHistory();
  const [projectData, setProjectData] = useState(newProjectData);
  const [error, setError] = useState({
    name: { isError: false },
    languages: { isError: false },
  });
  const [projectEntitySchema, setProjectEntitySchema] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(newProjectData.teams);
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  const [clickedTeam, setClickedTeam] = useState(
    teams === null ? [] : teams[0]
  );
  const [isLoader, setIsLoader] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentsError, setDocumentsError] = useState([]);
  const [sort, setSort] = useState([]);
  const [sgStatuses, setSgStatuses] = useState([]);
  const [teamLoader, setTeamLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [statuses, setStatuses] = useState([]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [activeTab, setActiveTab] = useState(0);

  const { checkScreenPermission, checkCompPermission } = useCheckPermission();

  useEffect(() => {
    checkScreenPermission("Project", ["Create"]);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createServiceGroupData(serviceGroupData) {
    const { id, name, createdon, createdby, assignedto, status } =
      serviceGroupData;
    return {
      id: id,
      groupName: name,
      status: status,
      createdBy: createdby,
      createdon: createdon,
      assignedTo: assignedto,
      statuses: sgStatuses,
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const fetchProjectEntitySchema = async () => {
      setIsLoader(true);
      const { _msg, _status, data } = await fetchResourceByPermission(
        "Project",
        "create"
      );
      if (_status === 200) {
        const entity = [];
        data?.sections?.map((section) =>
          section?.fieldgroups?.map((fieldgroup) => {
            if (fieldgroup?.fields?.length !== 0) {
              entity.push(section);
            }
          })
        );
        setProjectEntitySchema(entity);
        const projData = {};
        data.sections.map((section) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.uiElementType === "Currency") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.uiElementType === "Amount") {
                projData[`${field.datakey}_unit`] = "";
                projData[`${field.datakey}_value`] = "";
                return true;
              }
              if (field.datakey) {
                return (projData[field.datakey] = "");
              }
            });
          });
        });
        setProjectData((prevData) => ({
          ...prevData,
          ...projData,
          ...newProjectData,
        }));
        const errData = {};
        data.sections.map((section, index) => {
          return section.fieldgroups.map((fieldgroup) => {
            return fieldgroup.fields.map((field) => {
              if (field.required === "TRUE") {
                if (field.uiElementType === "Currency") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                if (field.uiElementType === "Amount") {
                  errData[`${field.datakey}_unit`] = initError(field, index);
                  errData[`${field.datakey}_value`] = initError(field, index);
                  return true;
                }
                return (errData[field.datakey] = initError(field, index));
              }
            });
          });
        });
        delete errData["serviceGroups"];
        delete errData["documentation"];
        delete errData["documentationTable"];
        setError(errData);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };

    const fetchLanguagesList = async () => {
      const { _msg, _status, data } = await fetchLanguagesListData();
      if (_status === 200) {
        setLanguages(
          data ? data.filter((item) => item.status === "active") : []
        );
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    const fetchStatusesByResource = async (resource, callback) => {
      const { _msg, _status, data } = await fetchStatusResourceByName(resource);
      if (_status === 200) {
        callback(data);
        setStatuses(data ? data.status : []);
      } else {
        enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    };
    await fetchProjectEntitySchema();
    if (checkCompPermission("Language", ["List All"])) {
      fetchLanguagesList();
    }
    if (checkCompPermission("Team", ["List All"])) {
      fetchTeamsList();
    }
    if (checkCompPermission("StatusProfile", ["List All"])) {
      fetchStatusesByResource("Project", (data) =>
        setProjectData({
          ...projectData,
          status: data?.status.find((s) => s.default === true)
            ? data?.status.find((s) => s.default === true)?.status
            : data?.status?.length && data?.status[0]?.status,
        })
      );
      fetchStatusesByResource("ServiceGroup", (data) =>
        setSgStatuses(data ? data.status : [])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serviceGroups =
    newProjectData.newServicegroupsList &&
    newProjectData.newServicegroupsList.length > 0
      ? newProjectData.newServicegroupsList.map((item) => {
          const rowData = createServiceGroupData(item);
          return rowData;
        })
      : [];

  const fetchTeamsList = async (filter) => {
    setTeamLoader(true);
    const { _msg, _status, data } = await fetchTeamListData(0, filter);
    if (_status === 200) {
      setTeams(data ? data.map((item) => ({ ...item, checked: false })) : []);
      setTeamLoader(false);
    } else {
      setTeamLoader(false);
      enqueueSnackbar(_msg ? _msg : "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (Object.keys(error).includes(event.target.name)) {
      setError({
        ...error,
        [event.target.name]: {
          ...error[event.target.name],
          isError: false,
          msg: "",
        },
      });
    }
    setProjectData({
      ...projectData,
      [event.target.name]: value,
    });
  };

  const handleLanguageDelete = (langToDelete) => {
    setProjectData({
      ...projectData,
      languages: projectData.languages.filter((chip) => chip !== langToDelete),
    });
  };

  const handleSelectedTeamDelete = (teamToDelete) => {
    setSelectedTeams(
      selectedTeams.filter((team) => team._id !== teamToDelete._id)
    );
  };

  const handleTeamCheck = (team) => {
    if (!selectedTeams.find((item) => item._id === team._id)) {
      setSelectedTeams([...selectedTeams, team]);
      setProjectData({
        ...projectData,
        teams: [...projectData.teams, team._id],
      });
    } else {
      setSelectedTeams(selectedTeams.filter((item) => item._id !== team._id));
      setProjectData({
        ...projectData,
        teams: projectData.teams.filter((item) => item._id !== team.id),
      });
    }
  };

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      fetchTeamsList(
        JSON.stringify({ name: { $regex: searchInput, $options: "i" } })
      );
    } else {
      fetchTeamsList();
    }
  };

  const handleSubmit = async (submitType) => {
    const { formIsValid, errors } = handleFormValidationPro(projectData, error);
    const validData = handleFormValidationForDocument(
      selectedDocuments,
      documentsError
    );
    const documentIsValid = validData["formIsValid"];
    const docErrors = validData["errors"];
    if (!formIsValid) {
      const { tempEntity, errorInTab } = TabsCommon(
        projectEntitySchema,
        errors,
        tabbedView
      );
      setActiveTab(errorInTab);

      setProjectEntitySchema(tempEntity);
      setError(errors);
    }
    if (!documentIsValid) {
      setDocumentsError(docErrors);
    }
    if (formIsValid && documentIsValid) {
      setIsLoader(true);
      var projData = {
        ...projectData,
        languages: projectData.languages.map((item) => item._id),
        teams:
          selectedTeams.length > 0 ? selectedTeams.map((item) => item._id) : [],
      };
      if (projData.teams.length === 0) {
        delete projData["teams"];
      }
      delete projData["documentation"];
      delete projData["serviceGroups"];
      delete projData["newServicegroupsList"];
      const { _msg, _status, data } = await addProject(projData);
      if (_status === 201) {
        enqueueSnackbar(_msg, { variant: "success" });
        handleUploadDocuments("project", data._id, selectedDocuments);

        // create servicegroup
        for (var i = 0; i < newProjectData.newServicegroupsList.length; i++) {
          const serviceGroupResData = await addServiceGroupInProject(
            data._id,
            newProjectData.newServicegroupsList[i]
          );
          if (serviceGroupResData._status === 201) {
            enqueueSnackbar(serviceGroupResData._msg, { variant: "success" });
            handleUploadDocuments(
              "servicegroup",
              serviceGroupResData?.data?._id,
              newProjectData?.newServicegroupsList[i]?.sgDocuments
            );

            // copy microservice
            for (
              var j = 0;
              newProjectData?.newServicegroupsList[i]?.services &&
              j < newProjectData?.newServicegroupsList[i]?.services?.length;
              i++
            ) {
              const copyServiceData = {
                ...newProjectData?.newServicegroupsList[i]?.services[j],
                projectID: data._id,
                servicegroupID: serviceGroupResData.data._id,
              };
              const serviceResData = await copyService(copyServiceData);
              if (serviceResData._status === 200) {
                enqueueSnackbar(serviceResData._msg, { variant: "success" });
              } else {
                setIsLoader(false);
                if (serviceResData._msg === "Invalid Json") {
                  serviceResData.data.map((item) =>
                    enqueueSnackbar(item ? item : "Something went wrong", {
                      variant: "error",
                    })
                  );
                } else {
                  enqueueSnackbar(
                    serviceResData._msg
                      ? serviceResData._msg
                      : "Something went wrong",
                    { variant: "error" }
                  );
                }
              }
            }
          } else {
            setIsLoader(false);
            if (serviceGroupResData._msg === "Invalid Json") {
              serviceGroupResData.data.map((item) =>
                enqueueSnackbar(item ? item : "Something went wrong", {
                  variant: "error",
                })
              );
            } else {
              enqueueSnackbar(
                serviceGroupResData._msg
                  ? serviceGroupResData._msg
                  : "Something went wrong",
                { variant: "error" }
              );
            }
          }
        }
        setIsLoader(false);
        getProjectList();
        addNewProjectData({
          name: "",
          description: "",
          languages: [],
          teams: [],
          newServicegroupsList: [],
        });
        if (submitType === "deployment") {
          histroy.push(`/projects/edit/${data?._id}`, {
            activeCurrentTab: 6,
            from: "create",
          });
        } else histroy.push(`/projects`);
      } else {
        setIsLoader(false);
        if (_msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        } else {
          enqueueSnackbar(_msg ? _msg : "Something went wrong", {
            variant: "error",
          });
        }
      }
    } else {
      enqueueSnackbar(
        "There are form field error(s), please correct the input values and submit",
        {
          variant: "error",
        }
      );
    }
  };

  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className={styles.topContent}>
          <BreadCrumbs
            items={[
              {
                title: "Projects",
                path: "/projects",
                isCallback: true,
                callback: () =>
                  addNewProjectData({
                    name: "",
                    description: "",
                    languages: [],
                    teams: [],
                    newServicegroupsList: [],
                  }),
              },
              { title: "Create Project" },
            ]}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ borderBottom: "2px solid #afafaf" }}
          >
            <h2 className={styles.heading}>Create Project</h2>

            {tabbedView ? (
              <>
                {!serviceGroupMode && (
                  <TabbedNavigation
                    handleNext={() => setActiveTab((prev) => prev + 1)}
                    handlePrev={() => setActiveTab((prev) => prev - 1)}
                    isCreateMode={true}
                    schemaLength={projectEntitySchema?.length}
                    activeTab={activeTab}
                    handleSave={handleSubmit}
                    handleCancel={() => {
                      histroy.push("/projects");
                      addNewProjectData({
                        name: "",
                        projectmanager: "",
                        projectbudget: "",
                        description: "",
                        languages: [],
                        teams: [],
                        newServicegroupsList: [],
                      });
                    }}
                  />
                )}
              </>
            ) : (
              <AccordionNavigation
                isCreateMode={true}
                handleCancel={() => {
                  histroy.push("/projects");
                  addNewProjectData({
                    name: "",
                    projectmanager: "",
                    projectbudget: "",
                    description: "",
                    languages: [],
                    teams: [],
                    newServicegroupsList: [],
                  });
                }}
                handleSave={handleSubmit}
                disabled={false}
                isEditMode={false}
              />
            )}
          </Grid>
        </div>
        <div className={styles.wrapper}>
          {tabbedView ? (
            <>
              <SectionTabs
                entity={projectEntitySchema}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
              />
              <Paper style={{ padding: 20 }}>
                <div style={{ padding: "0px 5px 0" }}>
                  <CustomFieldGroupsForProjectCreate
                    section={projectEntitySchema[activeTab]}
                    projectData={projectData}
                    error={error}
                    serviceGroups={serviceGroups}
                    selectedTeams={selectedTeams}
                    teams={teams}
                    clickedTeam={clickedTeam}
                    languages={languages}
                    selectedDocuments={selectedDocuments}
                    addNewProjectData={addNewProjectData}
                    sort={sort}
                    teamSearchInput={teamSearchInput}
                    teamLoader={teamLoader}
                    windowDimensions={windowDimensions}
                    documentsError={documentsError}
                    setDocumentsError={setDocumentsError}
                    handleTeamCheck={handleTeamCheck}
                    handleTeamSearch={handleTeamSearch}
                    setProjectData={setProjectData}
                    setSelectedDocuments={setSelectedDocuments}
                    setTeamSearchInput={setTeamSearchInput}
                    handleLanguageDelete={handleLanguageDelete}
                    setClickedTeam={setClickedTeam}
                    handleSelectedTeamDelete={handleSelectedTeamDelete}
                    setSort={setSort}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    statuses={statuses}
                  />
                </div>
              </Paper>
            </>
          ) : (
            <>
              {projectEntitySchema.length !== 0 &&
                projectEntitySchema.map((section, index) => {
                  if (section.name === "Project_Header_Section") {
                    return (
                      <div key={section._id} style={{ padding: "0px 5px 0" }}>
                        <CustomFieldGroupsForProjectCreate
                          section={section}
                          projectData={projectData}
                          error={error}
                          setProjectData={setProjectData}
                          handleChange={handleChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Element name={index}>
                        <Accordion
                          className={styles.fieldPanel}
                          defaultExpanded
                          key={section._id}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ArrowDropDownRounded
                                className={styles.accordianIcon}
                              />
                            }
                          >
                            <Typography className={styles.sectionHeading}>
                              {section["section Label"]}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomFieldGroupsForProjectCreate
                              section={section}
                              projectData={projectData}
                              error={error}
                              serviceGroups={serviceGroups}
                              selectedTeams={selectedTeams}
                              teams={teams}
                              clickedTeam={clickedTeam}
                              languages={languages}
                              selectedDocuments={selectedDocuments}
                              addNewProjectData={addNewProjectData}
                              sort={sort}
                              teamSearchInput={teamSearchInput}
                              teamLoader={teamLoader}
                              windowDimensions={windowDimensions}
                              documentsError={documentsError}
                              setDocumentsError={setDocumentsError}
                              handleTeamCheck={handleTeamCheck}
                              handleTeamSearch={handleTeamSearch}
                              setProjectData={setProjectData}
                              setSelectedDocuments={setSelectedDocuments}
                              setTeamSearchInput={setTeamSearchInput}
                              handleLanguageDelete={handleLanguageDelete}
                              setClickedTeam={setClickedTeam}
                              handleSelectedTeamDelete={
                                handleSelectedTeamDelete
                              }
                              setSort={setSort}
                              handleChange={handleChange}
                              statuses={statuses}
                              handleSubmit={handleSubmit}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Element>
                    );
                  }
                })}
            </>
          )}
        </div>
      </form>
    </main>
  );
};

const mapDispatchToProps = {
  getProjectList,
  addNewProjectData,
};

const mapStateToProps = (state) => {
  const { newProjectData } = state.projectReducer;
  const { tabbedView } = state.userDetails.userInfo;
  const { serviceGroupMode } = state.projectReducer;

  return {
    newProjectData,
    tabbedView,
    serviceGroupMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
