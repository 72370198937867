import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/common/stuff/Loader";
import { addAuthData } from "../../redux/actions/authAction";
import { add_current_organization_data_action } from "../../redux/actions/organizationAction";
import { addUserFavouite, addUserSubscription } from "../../redux/actions/userPersonalAction";
import { userDetailAction } from "../../redux/actions/userProfileAction";
import CreateCustomerStyles from "../../screens/CommonStyles/CreateAndDetailsScreenStyle";
import { loginFromToken } from "../../utils/authentication/authenticationUtils";
import jwt_decode from "jwt-decode";

const LoginFromSOS = (props) => {
  const {
    addAuthData,
    addUserFavouite,
    addUserSubscription,
    userDetailAction,
    add_current_organization_data_action,
  } = props;

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoader, setIsLoader] = useState(false)

  const styles = CreateCustomerStyles();
  const location = useLocation().search;
  const accesstoken = new URLSearchParams(location).get("accesstoken");
  useEffect(() => {
    setIsLoader(true)
    const loginFromSOSToken = async () => {
      const {_status, _msg, data} = await loginFromToken({token: accesstoken})
      if (_status === 201) {
        var authdata = {};
        let userId = "";
        setIsLoader(false);

        if(data.length===2){
          if(data[0]?.accesstoken && data[1]?.refreshtoken){
            authdata = {
              ...authdata,
              accesstoken : data[0]?.accesstoken,
              refreshtoken : data[1]?.refreshtoken,
              userid : data[0]?.userid
            }
            addAuthData(authdata);
  
            // user Detail
            userDetailAction();
  
            // userfav
            addUserFavouite(userId);
  
            // usersubscription
            addUserSubscription(userId);
  
            // Org Data
            add_current_organization_data_action();
  
            history.push("/dashboard");
          }

        }else{
          const {accessToken, refreshToken,userDetails, userActions} = data; 
          if (accessToken && refreshToken) {
            const decryptData = jwt_decode(accessToken);
            userId = userDetails?.id;
            authdata = {
              ...authdata,
              accesstoken: accessToken,
              refreshtoken: refreshToken,
              userid: userId,
              userActions : decryptData?.actions
            };
  
            // authdata
            addAuthData(authdata);
  
            // user Detail
            userDetailAction();
  
            // userfav
            addUserFavouite(userId);
  
            // usersubscription
            addUserSubscription(userId);
  
            // Org Data
            add_current_organization_data_action();
  
            history.push("/dashboard");
          } 
          enqueueSnackbar(_msg, { variant: "success" });
        }
      } else {
        setIsLoader(false);
        enqueueSnackbar(_msg ? _msg : 'Unable to process your request, please try after sometime', { variant: "error" });
      }

    }

    loginFromSOSToken()
  }, [accesstoken])
  

  return (
    <main className={styles.main}>
      {isLoader && <Loader />}
    </main>
  );
};

const mapDispatchToProps = {
  addAuthData,
  addUserFavouite,
  addUserSubscription,
  userDetailAction,
  add_current_organization_data_action,
};

export default connect(null, mapDispatchToProps)(LoginFromSOS);